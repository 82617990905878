import React, { useEffect, useReducer, useState } from "react";

import Header from "../../../Dashboard/Header";
import Leftpanel from "../../../Dashboard/Leftpanel";

import { Link } from "react-router-dom";
import moment from "moment";
import "../../../assets/css/appdashboard.css";
import Profitwell from "profitwell-component";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  useAppDashboardDataQuery,
  useCreateDefaultsDataMutation,
  useExtractColorDataMutation,
} from "../../../services/appDashboardApiSlice";
import {
  setAppUserRoleId,
  setAppId,
  setAppName,
} from "../../../Redux/slices/appDataSlice";
import { useDispatch, useSelector } from "react-redux";
import AmsSpinner from "../../../Components/commonIcons/AmsSpinner";
import AnalyticsCard from "./AnalyticsCard";
import Knowledgebase from "../../../Components/commonIcons/Knowledgebase";
import WhatsNew from "../../../Components/commonIcons/WhatsNew";
import ProductRoadmap from "../../../Components/commonIcons/ProductRoadmap";
import RequestAFeature from "../../../Components/commonIcons/RequestAFeature";
import Community from "../../../Components/commonIcons/Community";
import EmailSupport from "../../../Components/commonIcons/EmailSupport";
import SupportCard from "../../../Components/dashboardCards/SupportCard";
import ConnectivityCard from "../../../Components/dashboardCards/ConnectivityCard";
import DownloadCard from "./DownloadCard";
import UserDetailsCard from "../../../Components/dashboardCards/UserDetailsCard";
import TechnologyCard from "../../../Components/dashboardCards/TechnologyCard";
import OverviewCard from "../../../Components/dashboardCards/OverviewCard";
import TeamCard from "../../../Components/dashboardCards/TeamCard";
import Checklist from "../../../Components/dashboardCards/Checklist";
import PlanDetailsCard from "../../../Components/dashboardCards/PlanDetailsCard";
import ModuleTitleTooltip from "../../../Components/commonIcons/ModuleTitleTooltip";
import w2AppChecklistArr from "../../../assets/json/W2appChecklistOptions.json";
import customAppChecklistArr from "../../../assets/json/CustomAppChecklistOptions.json";
import wpAppChecklistArr from "../../../assets/json/WpChecklistOptions.json";
import wooAppChecklistArr from "../../../assets/json/WooChecklistOptions.json";
import { isJSON } from "../../../utils/commonUtils";
import { SUPPORTCARDDETAILS } from "../../../Constants/dashboardSupportSection/dashboardSupport";
import SupportSection from "../../../Components/dashboardCards/SupportSection/SupportSection";
import { dashboardEvent } from "../../../utils/datalayerEvents";

const AppDashboard = () => {
  const dispatch = useDispatch();
  const { appId, appName, appUserRoleId } = useSelector(
    (state) => state.appData
  );
  const [dashboardData, setDashboardData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      spinner: true,
      dashboardspinner: true,
      appearance_selection: true,
      connectivity_selection: false,
      dashboard_selection: false,
      onetime_addons_purchased_json: null,
      recurring_addons_purchased_json: null,
      data: [],
      appearance_active_state: [],
      auto_addons: [],
      token: "",
      protip: false,
      reportdata: [],
      activeuserspinner: true,
      android_build: "",
      android_latest: "",
      ios_build: "",
      ios_latest: "",
      stripe_customer_id: "",
      workspace_customer_id: "",
      website_technology: "",
      customer_billing_type: "",
      customer_id: "",
      android_build_version: null,
      ios_build_version: null,
      android_build_info: null,
      ios_build_info: null,
      downloadbuild: false,
      buildurl: "",
      downloadiosbuild: false,
      buildiosurl: "",
      pointcheck: 0,
      selectfilter: "7d",
      histogramspinner: true,
      intervalFilterVal: "day",
      appuser_role_id: 4,
      openUpgradePopUp: false,
      supportCardArr: [
        {
          link: "https://appmysite.com/support/",
          heading: "Knowledge base",
          text: "Find articles that answer your questions.",
          SelectedSvg: Knowledgebase,
        },
        {
          link: `${window.location.protocol}//${window.location.host}/email-support`,
          heading: "Email support",
          text: "Submit a request and get help from experts.",
          SelectedSvg: EmailSupport,
        },
        {
          link: "https://updates.appmysite.com/en/",
          heading: "What’s new",
          text: "Stay up to date with what’s keeping us busy.",
          SelectedSvg: WhatsNew,
        },
        {
          link: "https://updates.appmysite.com/roadmap/en/",
          heading: "Product roadmap",
          text: "A sneak peak into the future of mobile apps.",
          SelectedSvg: ProductRoadmap,
        },
        {
          link: "https://updates.appmysite.com/requests/en/",
          heading: "Request a feature",
          text: "Request improvements and new features.",
          SelectedSvg: RequestAFeature,
        },
        {
          link: "https://community.appmysite.com/",
          heading: "Community",
          text: "Engage with other app builders like yourself.",
          SelectedSvg: Community,
        },
      ],
      checklistTotal:
        parseInt(localStorage.getItem("website_technology")) === 2 ||
        parseInt(localStorage.getItem("website_technology")) === 4
          ? 6
          : 7,
      checklistOptions: [],
      appRightPanelExpand: true,
    }
  );

  const {
    data: appDashboardApiData,
    isError,
    isLoading,
  } = useAppDashboardDataQuery();

  const [createDefaultsData, { defaultData }] = useCreateDefaultsDataMutation();
  const [extractedColor, { extractColorData }] = useExtractColorDataMutation();
  const savedefaultPostApi = () => {
    const params = {
      default_app_id: parseInt(localStorage.getItem("Appid")),
      default_workspace_id: null,
      default_agency_id: null,
    };
    createDefaultsData(params)
      .unwrap()
      .then((response) => {})
      .catch((err) => {});
  };

  const extractColor = () => {
    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      force_update: 0,
    };
    extractedColor(params)
      .unwrap()
      .then((response) => {})
      .catch((err) => {});
  };

  const returnChecklistArr = (currPlan) => {
    switch (parseInt(localStorage.getItem("website_technology"))) {
      case 2:
        return w2AppChecklistArr;
      case 3:
        return customAppChecklistArr;
      case 4:
        return wpAppChecklistArr;
      case 5:
        return wooAppChecklistArr;
      default:
        return w2AppChecklistArr;
    }
  };

  const settingCount = (module) => {
    switch (module.name) {
      case "App icon":
        module.count = appDashboardApiData?.data?.app_icon_screen_count;
        break;
      case "Splash screen":
        module.count = appDashboardApiData?.data?.splash_screen_count;
        break;
      case "Bottom bar":
        module.count = appDashboardApiData?.data?.bottom_menu;
        break;
      case "Notification":
        module.count = appDashboardApiData?.data?.notification_screen_count;
        break;
      case "Download":
        module.count = appDashboardApiData?.data?.is_build_created;
        break;
      case "Web view":
        module.count =
          appDashboardApiData?.data?.web_view_settings_screen_count;
        break;
      case "Pages":
        module.count = appDashboardApiData?.data?.page_count;
        break;
      case "Post":
        module.count = appDashboardApiData?.data?.post_count;
        break;
      case "Install plugin":
        module.count = appDashboardApiData?.data?.plugin_screen_count;
        break;
      case "Grant access":
        module.count = appDashboardApiData?.data?.cs_ck_screen_count;
        break;
    }
  };

  useEffect(() => {
    savedefaultPostApi();
    if (parseInt(localStorage.getItem("website_technology")) !== 3) {
      extractColor();
    }
  }, []);

  useEffect(() => {
    if (
      localStorage.getItem("agencyid") &&
      localStorage.getItem("agencyid") == 1
    ) {
      document.title = "App dashboard | AppMySite";
    } else {
      document.title =
        "App dashboard |" + " " + localStorage.getItem("agencyname");
    }

    // DownloadDashboardBuildApi(buildparams, {setState:setDashboardData});

    setDashboardData({
      appRightPanelExpand:
        localStorage.getItem("appSideBarCollapse") === true ||
        localStorage.getItem("appSideBarCollapse") === "true"
          ? true
          : false,
    });
    setDashboardData({ appid: localStorage.getItem("Appid") });

    if (dashboardData.customer_billing_type == 2) {
      setTimeout(
        () => (
          <Profitwell
            authToken="6a38f3c4608428fa86da4844e532b6b6"
            user_id={dashboardData.customer_id}
          />
        ),
        3000
      );
    } else if (dashboardData.customer_billing_type == 3) {
      setTimeout(
        () => (
          <Profitwell
            authToken="894a549ee73eccb67e82477e6276abec"
            user_id={dashboardData.customer_id}
          />
        ),
        3000
      );
    }

    if (appDashboardApiData) {
      let currObj = { ...dashboardData };
      if (appDashboardApiData.code === 200) {
        if (appDashboardApiData?.data?.owner_data) {
          dashboardEvent({
            user_email: appDashboardApiData?.data?.owner_data.email,
            user_name: appDashboardApiData?.data?.owner_data.profile_name,
            user_id: appDashboardApiData?.data?.owner_data.user_id,
          });
        }
        let updatedChecklistArr = returnChecklistArr();
        updatedChecklistArr &&
          updatedChecklistArr.map((option) => {
            settingCount(option);
          });
        currObj.checklistOptions = updatedChecklistArr;
        updatedChecklistArr = [];

        currObj.dashboardspinner = false;
        currObj.appuser_role_id = appDashboardApiData.data.appuser_role_id;
        currObj.data = appDashboardApiData.data;
        currObj.website_technology =
          appDashboardApiData.data.website_technology;
        currObj.recurring_addons_purchased_json =
          appDashboardApiData.data.subscription === null ||
          appDashboardApiData.data.subscription
            .recurring_addons_purchased_json === "" ||
          appDashboardApiData.data.subscription
            .recurring_addons_purchased_json === null
            ? 0
            : JSON.parse(
                appDashboardApiData.data.subscription
                  .recurring_addons_purchased_json
              );
        currObj.onetime_addons_purchased_json =
          appDashboardApiData.data.subscription === null ||
          appDashboardApiData.data.subscription
            .onetime_addons_purchased_json === "" ||
          appDashboardApiData.data.subscription
            .onetime_addons_purchased_json === null
            ? 0
            : JSON.parse(
                appDashboardApiData.data.subscription
                  .onetime_addons_purchased_json
              );
        currObj.auto_addons =
          appDashboardApiData.data.subscription === null
            ? []
            : appDashboardApiData.data.subscription.auto_addons;
        currObj.android_build_info =
          appDashboardApiData.data.android_build_info;
        currObj.ios_build_info = appDashboardApiData.data.ios_build_info;
        currObj.android_build =
          appDashboardApiData.data.build_versions.android.build_version;
        currObj.android_build_version =
          appDashboardApiData.data.build_versions.android.build_version;
        currObj.android_latest =
          appDashboardApiData.data.build_versions.android.latest_version;
        currObj.ios_build =
          appDashboardApiData.data.build_versions.ios.build_version;
        currObj.ios_build_version =
          appDashboardApiData.data.build_versions.ios.build_version;
        currObj.ios_latest =
          appDashboardApiData.data.build_versions.ios.latest_version;
        currObj.workspace_customer_id =
          appDashboardApiData.data.workspace_customer_id;
        currObj.stripe_customer_id =
          appDashboardApiData.data?.stripe_customer_id;
        currObj.customer_billing_type =
          appDashboardApiData.data.customer_billing_type;
        currObj.customer_id = appDashboardApiData.data.customer_id;
        currObj.owner_data = appDashboardApiData.data.owner_data;
        currObj.openUpgradePopUp =
          (appDashboardApiData.data.subscription.plan_name == "Free" ||
            appDashboardApiData.data.subscription.plan_name == "free" ||
            appDashboardApiData.data.subscription.plan == "preview" ||
            appDashboardApiData.data.subscription.plan_name == "preview" ||
            appDashboardApiData.data.subscription.plan_name == "Preview") &&
          (localStorage.getItem("workspaceId") === "undefined" ||
            localStorage.getItem("workspaceId") === undefined ||
            localStorage.getItem("workspaceId") === "" ||
            localStorage.getItem("workspaceId") === null) &&
          localStorage.getItem("agencyid") &&
          localStorage.getItem("agencyid") == 1
            ? true
            : false;

        dispatch(setAppUserRoleId(appDashboardApiData.data.appuser_role_id));
        dispatch(setAppName(appDashboardApiData.data.app_name));
        localStorage.setItem(
          "appuser_role_id",
          appDashboardApiData.data.appuser_role_id
        );
      } else {
        currObj.dashboardspinner = false;
      }
      setDashboardData(currObj);
    }
  }, [appDashboardApiData]);

  const handleOpenUpgradePopUp = () => {
    setDashboardData({ openUpgradePopUp: !dashboardData.openUpgradePopUp });
  };

  const handleRightPanelExpand = (value) => {
    setDashboardData({
      appRightPanelExpand: value,
    });
  };

  return (
    <>
      <div>
        <Header customclass={"header-responsive-new"} />
        {dashboardData.customer_billing_type == 2 ? (
          <Profitwell
            authToken="6a38f3c4608428fa86da4844e532b6b6"
            user_id={dashboardData.customer_id}
          />
        ) : dashboardData.customer_billing_type == 3 ? (
          <Profitwell
            authToken="894a549ee73eccb67e82477e6276abec"
            user_id={dashboardData.customer_id}
          />
        ) : null}
        <section className="dasboard_page bottombar-module mobile-display mt-md-0">
          <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
          <div
            className={`right-panel-gap ${
              localStorage.getItem("appSideBarCollapse") !== undefined &&
              localStorage.getItem("appSideBarCollapse") !== null &&
              localStorage.getItem("appSideBarCollapse") === "true"
                ? " right-panel-gap-expand"
                : ""
            }`}
          >
            <ModuleTitleTooltip
              title={"Dashboard"}
              text={
                " Welcome to your dashboard, the central hub for managing your app and its components."
              }
            />
            {dashboardData.dashboardspinner ? (
              <div className="app-icon-section access-loader appdashboard-loader">
                <AmsSpinner spinWidth={"200px"} spinHeight={"200px"} />
              </div>
            ) : (
              <>
                <div className="app-dashboard-full">
                  <div className="app-dashboard-left">
                    <div className="dashboard-app-info">
                      <OverviewCard appData={dashboardData.data} />

                      <div className="dashboard-app-info-one-child">
                        <TechnologyCard technologyData={dashboardData.data} />

                        {/* plan details for mob workpscae app */}
                        {(dashboardData.appuser_role_id === 1 ||
                          dashboardData.appuser_role_id === 2) &&
                          localStorage.getItem("workspaceId") &&
                          window.matchMedia("(max-width: 1200px)").matches && (
                            <PlanDetailsCard
                              planTitle={"Subscription"}
                              planDescription={
                                "This app is managed by your workspace."
                              }
                              planName={
                                dashboardData.data?.subscription?.plan_name
                              }
                              plan={"workspace"}
                              gatewaySubscriptionId={""}
                              subscriptionStatus={""}
                              downgradeAtTermEnd={""}
                              webTechnology={""}
                              packageDurationId={""}
                            />
                          )}

                        {/* plan details for mob pay-per-app app */}
                        {localStorage.getItem("agencyid") &&
                          localStorage.getItem("agencyid") == 1 &&
                          (localStorage.getItem("workspaceId") ===
                            "undefined" ||
                            localStorage.getItem("workspaceId") === undefined ||
                            localStorage.getItem("workspaceId") === "" ||
                            localStorage.getItem("workspaceId") === null) &&
                          window.matchMedia("(max-width: 1200px)").matches &&
                          dashboardData.appuser_role_id !== 3 &&
                          dashboardData.appuser_role_id !== 4 && (
                            <PlanDetailsCard
                              planTitle={
                                dashboardData.data?.subscription?.plan_name ==
                                  "Free" ||
                                dashboardData.data?.subscription?.plan_name ==
                                  "free" ||
                                dashboardData.data?.subscription?.plan_name ==
                                  "preview" ||
                                dashboardData.data?.subscription?.plan_name ==
                                  "Preview"
                                  ? "Free"
                                  : dashboardData.data?.subscription?.plan
                              }
                              planDescription={
                                dashboardData.data?.subscription?.plan_name ==
                                  "Free" ||
                                dashboardData.data?.subscription?.plan_name ==
                                  "free" ||
                                dashboardData.data?.subscription?.plan ==
                                  "preview" ||
                                dashboardData.data?.subscription?.plan_name ==
                                  "preview" ||
                                dashboardData.data?.subscription?.plan_name ==
                                  "Preview"
                                  ? `You’re on the free plan. Please upgrade to
                            access advanced features.`
                                  : (dashboardData.data?.subscription
                                      ?.downgrade_at_term_end == 1 ||
                                      dashboardData.data?.subscription
                                        ?.reseller_package_id) &&
                                    dashboardData.data?.subscription
                                      ?.package_duration_id != 4
                                  ? `Your existing plan is scheduled for automated
                            cancellation on 
                            ${moment(
                              dashboardData.data?.subscription
                                ?.reseller_package_id
                                ? dashboardData.data?.subscription
                                    ?.reseller_package_expiry_date
                                : dashboardData.data?.subscription?.billing_date
                            ).format("D MMMM YYYY")}
                            .`
                                  : dashboardData.data?.subscription
                                      ?.package_duration_id != 4
                                  ? `Your existing plan is scheduled for automated
                            renewal on 
                            ${moment(dashboardData.data?.renewal_date).format(
                              "D MMMM YYYY"
                            )}
                            .`
                                  : dashboardData.data?.subscription
                                      ?.package_duration_id == 4
                                  ? `You’re on the Lifetime plan. Enjoy updates and
                            support without any recurring charges.`
                                  : ""
                              }
                              planName={
                                dashboardData.data?.subscription?.plan_name
                              }
                              plan={dashboardData.data?.subscription?.plan}
                              gatewaySubscriptionId={
                                dashboardData.data?.subscription
                                  ?.gateway_subscription_id
                              }
                              subscriptionStatus={
                                dashboardData.data?.subscription?.status
                              }
                              downgradeAtTermEnd={
                                dashboardData.data?.subscription
                                  ?.downgrade_at_term_end
                              }
                              webTechnology={parseInt(
                                dashboardData.data?.website_technology
                              )}
                              packageDurationId={
                                dashboardData.data?.subscription
                                  ?.package_duration_id
                              }
                            />
                          )}

                        <UserDetailsCard
                          userData={dashboardData.data?.owner_data}
                        />
                      </div>
                    </div>

                    {/* <div className="dashboard-app-connectivity">
                      {parseInt(localStorage.getItem("website_technology")) ===
                        4 ||
                      parseInt(localStorage.getItem("website_technology")) ===
                        5 ? (
                        <>
                          <ConnectivityCard
                            module={"Plugin"}
                            status={dashboardData.data?.plugin_screen_count}
                            link={"/app/connect/wp-plugin"}
                          />
                          <ConnectivityCard
                            module={"Access"}
                            status={dashboardData.data?.cs_ck_screen_count}
                            link={"/app/connect/wp-grant-access"}
                          />
                          <ConnectivityCard
                            module={"Troubleshoot"}
                            status={
                              dashboardData.data?.latest_connectivity
                                ?.result_json?.length || 0
                            }
                            link={"/app/connect/troubleshoot-logs"}
                          />
                        </>
                      ) : (
                        <></>
                      )}
                    </div> */}

                    <div className="app-downloads">
                      <div className="app-dashbuild-info">
                        <DownloadCard
                          componentFrom={"Android"}
                          build={dashboardData.android_build}
                          build_version={dashboardData.android_build_version}
                          build_info={dashboardData.android_build_info}
                          latest={dashboardData.android_latest}
                          downloadbuild={dashboardData.downloadbuild}
                        />
                        <DownloadCard
                          componentFrom={"iOS"}
                          build={dashboardData.ios_build}
                          build_version={dashboardData.ios_build_version}
                          build_info={dashboardData.ios_build_info}
                          latest={dashboardData.ios_latest}
                          downloadbuild={dashboardData.downloadiosbuild}
                        />
                      </div>
                      <AnalyticsCard
                        dashboardData={dashboardData}
                        setDashboardData={setDashboardData}
                      />
                    </div>

                    {(!localStorage.getItem("agencyid") ||
                      localStorage.getItem("agencyid") === "undefined" ||
                      localStorage.getItem("agencyid") === "null" ||
                      localStorage.getItem("agencyid") == 1) &&
                    window.matchMedia("(min-width: 1200px)").matches ? (
                      // <div className="the-app-support">
                      //   <h3>Support</h3>

                      //   <div className="app-support-links">
                      //     {dashboardData.supportCardArr &&
                      //       dashboardData.supportCardArr.map((eachCard) => {
                      //         return (
                      //           <SupportCard
                      //             key={eachCard.link}
                      //             link={eachCard.link}
                      //             text={eachCard.text}
                      //             heading={eachCard.heading}
                      //             SelectedSvg={eachCard.SelectedSvg}
                      //           />
                      //         );
                      //       })}
                      //   </div>
                      // </div>
                      <SupportSection
                        supportCardArr={SUPPORTCARDDETAILS}
                        planName={
                          localStorage.getItem("agencyid") &&
                          localStorage.getItem("agencyid") == 1
                            ? localStorage.getItem("supportplan") &&
                              isJSON(localStorage.getItem("supportplan"))
                              ? isJSON(localStorage.getItem("supportplan"))
                                  .plan_name
                              : "Basic"
                            : ""
                        }
                        planDesc={
                          localStorage.getItem("agencyid") &&
                          localStorage.getItem("agencyid") == 1
                            ? localStorage.getItem("supportplan") &&
                              isJSON(localStorage.getItem("supportplan"))
                              ? isJSON(localStorage.getItem("supportplan"))
                                  .plan_name === "Basic"
                                ? "Basic plan gives you access to essential support through dedicated support channels."
                                : isJSON(localStorage.getItem("supportplan"))
                                    .plan_name === "Business"
                                ? "Business plan provides swift response times and advanced troubleshooting. "
                                : isJSON(localStorage.getItem("supportplan"))
                                    .plan_name === "Enterprise"
                                ? "Enterprise plan comes with top-tier support and prompt response times. Get a dedicated Success Manager for custom enhancements."
                                : "Basic plan gives you access to essential support through dedicated support channels."
                              : "Basic plan gives you access to essential support through dedicated support channels."
                            : ""
                        }
                        expireOn={
                          localStorage.getItem("agencyid") &&
                          localStorage.getItem("agencyid") == 1
                            ? localStorage.getItem("supportplan") &&
                              isJSON(localStorage.getItem("supportplan"))
                              ? isJSON(localStorage.getItem("supportplan"))
                                  .renewal_date
                              : ""
                            : ""
                        }
                        downgradeAtTermEnd={
                          localStorage.getItem("agencyid") &&
                          localStorage.getItem("agencyid") == 1
                            ? localStorage.getItem("supportplan") &&
                              isJSON(localStorage.getItem("supportplan"))
                              ? isJSON(localStorage.getItem("supportplan"))
                                  .downgrade_at_term_end
                              : ""
                            : ""
                        }
                      />
                    ) : (
                      <></>
                    )}
                  </div>

                  <div className="app-dashboard-right">
                    {/* plan details for web workpscae app */}
                    {(dashboardData.appuser_role_id === 1 ||
                      dashboardData.appuser_role_id === 2) &&
                      localStorage.getItem("workspaceId") &&
                      window.matchMedia("(min-width: 1200px)").matches && (
                        <PlanDetailsCard
                          planTitle={"Subscription"}
                          planDescription={
                            "This app is managed by your workspace."
                          }
                          planName={dashboardData.data?.subscription?.plan_name}
                          plan={"workspace"}
                          gatewaySubscriptionId={""}
                          subscriptionStatus={""}
                          downgradeAtTermEnd={""}
                          webTechnology={""}
                          packageDurationId={""}
                        />
                      )}

                    {/* plan details for web pay-per-app app */}
                    {localStorage.getItem("agencyid") &&
                      localStorage.getItem("agencyid") == 1 &&
                      (localStorage.getItem("workspaceId") === "undefined" ||
                        localStorage.getItem("workspaceId") === undefined ||
                        localStorage.getItem("workspaceId") === "" ||
                        localStorage.getItem("workspaceId") === null) &&
                      window.matchMedia("(min-width: 1200px)").matches &&
                      dashboardData.appuser_role_id !== 3 &&
                      dashboardData.appuser_role_id !== 4 && (
                        <PlanDetailsCard
                          planTitle={
                            dashboardData.data?.subscription?.plan_name ==
                              "Free" ||
                            dashboardData.data?.subscription?.plan_name ==
                              "free" ||
                            dashboardData.data?.subscription?.plan_name ==
                              "preview" ||
                            dashboardData.data?.subscription?.plan_name ==
                              "Preview"
                              ? "Free"
                              : dashboardData.data?.subscription?.plan
                          }
                          planDescription={
                            dashboardData.data?.subscription?.plan_name ==
                              "Free" ||
                            dashboardData.data?.subscription?.plan_name ==
                              "free" ||
                            dashboardData.data?.subscription?.plan ==
                              "preview" ||
                            dashboardData.data?.subscription?.plan_name ==
                              "preview" ||
                            dashboardData.data?.subscription?.plan_name ==
                              "Preview"
                              ? `You’re on the free plan. Please upgrade to
                            access advanced features.`
                              : (dashboardData.data?.subscription
                                  ?.downgrade_at_term_end == 1 ||
                                  dashboardData.data?.subscription
                                    ?.reseller_package_id) &&
                                dashboardData.data?.subscription
                                  ?.package_duration_id != 4
                              ? `Your existing plan is scheduled for automated
                            cancellation on 
                            ${moment(
                              dashboardData.data?.subscription
                                ?.reseller_package_id
                                ? dashboardData.data?.subscription
                                    ?.reseller_package_expiry_date
                                : dashboardData.data?.subscription?.billing_date
                            ).format("D MMMM YYYY")}
                            .`
                              : dashboardData.data?.subscription
                                  ?.package_duration_id != 4
                              ? `Your existing plan is scheduled for automated
                            renewal on 
                            ${moment(dashboardData.data?.renewal_date).format(
                              "D MMMM YYYY"
                            )}
                            .`
                              : dashboardData.data?.subscription
                                  ?.package_duration_id == 4
                              ? `You’re on the Lifetime plan. Enjoy updates and
                            support without any recurring charges.`
                              : ""
                          }
                          planName={dashboardData.data?.subscription?.plan_name}
                          plan={dashboardData.data?.subscription?.plan}
                          gatewaySubscriptionId={
                            dashboardData.data?.subscription
                              ?.gateway_subscription_id
                          }
                          subscriptionStatus={
                            dashboardData.data?.subscription?.status
                          }
                          downgradeAtTermEnd={
                            dashboardData.data?.subscription
                              ?.downgrade_at_term_end
                          }
                          webTechnology={parseInt(
                            dashboardData.data?.website_technology
                          )}
                          packageDurationId={
                            dashboardData.data?.subscription
                              ?.package_duration_id
                          }
                        />
                      )}

                    <Checklist
                      dashboardData={dashboardData}
                      checklistTotal={dashboardData.checklistTotal}
                      moduleArr={dashboardData.checklistOptions}
                    />
                    {dashboardData.appuser_role_id !== 4 && (
                      <TeamCard
                        ownerData={dashboardData.data?.owner_data}
                        teamData={dashboardData.data?.management_users}
                      />
                    )}
                  </div>

                  {(!localStorage.getItem("agencyid") ||
                    localStorage.getItem("agencyid") === "undefined" ||
                    localStorage.getItem("agencyid") === "null" ||
                    localStorage.getItem("agencyid") == 1) &&
                  window.matchMedia("(max-width: 1200px)").matches ? (
                    // <div className="the-app-support">
                    //   <h3>Support</h3>

                    //   <div className="app-support-links">
                    //     {dashboardData.supportCardArr &&
                    //       dashboardData.supportCardArr.map((eachCard) => {
                    //         return (
                    //           <SupportCard
                    //             key={eachCard.link}
                    //             link={eachCard.link}
                    //             text={eachCard.text}
                    //             heading={eachCard.heading}
                    //             SelectedSvg={eachCard.SelectedSvg}
                    //           />
                    //         );
                    //       })}
                    //   </div>
                    // </div>
                    <SupportSection
                      supportCardArr={SUPPORTCARDDETAILS}
                      planName={
                        localStorage.getItem("agencyid") &&
                        localStorage.getItem("agencyid") == 1
                          ? localStorage.getItem("supportplan") &&
                            isJSON(localStorage.getItem("supportplan"))
                            ? isJSON(localStorage.getItem("supportplan"))
                                .plan_name
                            : "Basic"
                          : ""
                      }
                      planDesc={
                        localStorage.getItem("agencyid") &&
                        localStorage.getItem("agencyid") == 1
                          ? localStorage.getItem("supportplan") &&
                            isJSON(localStorage.getItem("supportplan"))
                            ? isJSON(localStorage.getItem("supportplan"))
                                .plan_name === "Basic"
                              ? "Basic plan gives you access to essential support through dedicated support channels."
                              : isJSON(localStorage.getItem("supportplan"))
                                  .plan_name === "Business"
                              ? "Business plan provides swift response times and advanced troubleshooting. "
                              : isJSON(localStorage.getItem("supportplan"))
                                  .plan_name === "Enterprise"
                              ? "Enterprise plan comes with top-tier support and prompt response times. Get a dedicated Success Manager for custom enhancements."
                              : "Basic plan gives you access to essential support through dedicated support channels."
                            : "Basic plan gives you access to essential support through dedicated support channels."
                          : ""
                      }
                      expireOn={
                        localStorage.getItem("agencyid") &&
                        localStorage.getItem("agencyid") == 1
                          ? localStorage.getItem("supportplan") &&
                            isJSON(localStorage.getItem("supportplan"))
                            ? isJSON(localStorage.getItem("supportplan"))
                                .renewal_date
                            : ""
                          : ""
                      }
                      downgradeAtTermEnd={
                        localStorage.getItem("agencyid") &&
                        localStorage.getItem("agencyid") == 1
                          ? localStorage.getItem("supportplan") &&
                            isJSON(localStorage.getItem("supportplan"))
                            ? isJSON(localStorage.getItem("supportplan"))
                                .downgrade_at_term_end
                            : ""
                          : ""
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}

            <Modal
              isOpen={dashboardData.openUpgradePopUp}
              className="protip-popup-message upgrade-protio-popup"
              centered
            >
              <ModalBody>
                <div className="protip-popup">
                  <div className="protip-popup-one">
                    <h4>Upgrade your app</h4>
                    <h6>
                      Unlock advanced features and enhance your app’s user
                      experience.
                    </h6>
                    <div className="upgrade-popuptext-div">
                      <ul className="upgrade-popuptext-first-ul">
                        <li>Ad-free browsing</li>
                        <li>iOS app builds</li>
                        <li>Accelerated app builds</li>
                        <li>Merge multiple apps</li>
                        <li>Language selection</li>
                        <li>App usage analytics</li>
                      </ul>
                      <ul>
                        <li>No AppMySite branding</li>
                        <li>Unlimited app builds</li>
                        <li>Targeted advanced notifications</li>
                        <li>In-app purchases</li>
                        <li>Customer chat</li>
                        <li>One click publish</li>
                      </ul>
                    </div>
                    {(localStorage.getItem("agencyid") == "" ||
                      localStorage.getItem("agencyid") == undefined ||
                      localStorage.getItem("agencyid") == null ||
                      localStorage.getItem("agencyid") == "undefined" ||
                      localStorage.getItem("agencyid") == "null" ||
                      localStorage.getItem("agencyid") == 1) &&
                      dashboardData.appuser_role_id !== 4 &&
                      dashboardData.appuser_role_id !== 3 && (
                        <Link
                          to={
                            parseInt(
                              localStorage.getItem("website_technology")
                            ) === 4
                              ? "/app/wordpress-pricing/" +
                                btoa(localStorage.getItem("Appid"))
                              : parseInt(
                                  localStorage.getItem("website_technology")
                                ) === 5
                              ? "/app/woocommerce-pricing/" +
                                btoa(localStorage.getItem("Appid"))
                              : parseInt(
                                  localStorage.getItem("website_technology")
                                ) === 3
                              ? "/app/custom-app-pricing/" +
                                btoa(localStorage.getItem("Appid"))
                              : parseInt(
                                  localStorage.getItem("website_technology")
                                ) === 2
                              ? "/app/web-to-app-pricing/" +
                                btoa(localStorage.getItem("Appid"))
                              : "/app/pricing"
                          }
                        >
                          Upgrade
                        </Link>
                      )}
                  </div>

                  <div className="protip-popup-two">
                    <img
                      src={
                        require("../../../assets/images/dashboard/freeupgrade.png")
                          .default
                      }
                      className="img-fluid"
                      alt="icon-preview"
                    />
                  </div>
                </div>

                <div className="crossmodal mt-1">
                  <img
                    onClick={(e) => handleOpenUpgradePopUp(e)}
                    src={
                      require("../../../assets/images/dashboard/cross.png")
                        .default
                    }
                  />
                </div>
              </ModalBody>
            </Modal>
          </div>
        </section>
      </div>
    </>
  );
};
export default AppDashboard;
