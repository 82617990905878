import React, { useEffect, useState } from "react";
import imagebackground from "../../../assets/images/dashboard/cms/uploadbackground.png";
import MenuIconField from "./MenuIconField";
import MenuLinkField from "./MenuLinkField";
import { useSelector } from "react-redux";
import EyeIcon from "../../commonIcons/EyeIcon";
import DefaultMenuIcon from "../../commonIcons/DefaulyMenuIcon";
import { accordionDataUpdatation } from "../../../utils/commonUtils";
import Tooltip from "../../commonIcons/Tooltip";
import Newfeatureicon from "../../commonIcons/Newfeatureicon";
const ItemAccordian = ({
  item,
  itemListingData,
  itemListingArrName,
  setAccordianState,
  defaultOptionData,
  is_chat_enabled,
  handleDefaultItem,
  menuType,
}) => {
  const { taxonomyData, typeData } = useSelector(
    (state) => state.userWebsiteData
  );

  const [taxonomyTypeMergeData, setTaxonomyTypeMergeData] = useState([]);

  useEffect(() => {
    if (
      (taxonomyData && taxonomyData.length && typeData && typeData.length) ||
      parseInt(localStorage.getItem("website_technology")) === 3
    ) {
      mergeItemLinkList();
    }
  }, [taxonomyData?.length, typeData?.length]);

  const mergeItemLinkList = () => {
    let currMergeArr = [];
    let res = [];

    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      currMergeArr = [
        {
          name: "Pages",
          type: "cms_page",
          post_type: "",
          rest_base: "",
          slug: "",
          is_tree: 4,
          individual_selected_item_id: "",
          temp_individual_selected_item_id: "",
        },
        {
          name: "Posts",
          type: "cms_post",
          post_type: "",
          rest_base: "",
          slug: "",
          is_tree: 5,
          individual_selected_item_id: "",
          temp_individual_selected_item_id: "",
        },
        {
          name: "Post Categories",
          type: "cms_category",
          post_type: "",
          rest_base: "",
          slug: "",
          is_tree: 6,
          individual_selected_item_id: "",
          temp_individual_selected_item_id: "",
        },
        {
          name: "Post Tags",
          type: "cms_tag",
          post_type: "",
          rest_base: "",
          slug: "",
          is_tree: 7,
          individual_selected_item_id: "",
          temp_individual_selected_item_id: "",
        },
      ];
    } else {
      for (let i = 0; i < typeData?.length; i++) {
        res = [];

        res.name = typeData[i].name;
        res.slug = typeData[i].slug;
        res.rest_base = typeData[i].rest_base;
        res.type = "post_type";
        res.post_type = typeData[i].rest_base;
        res.individual_selected_item_id = "";
        res.is_tree = 3;

        currMergeArr.push(res);

        res = [];
        res.name = typeData[i].name + " search";
        res.slug = typeData[i].slug;
        res.rest_base = typeData[i].rest_base;
        res.type = "search";
        res.post_type = typeData[i].rest_base;
        res.individual_selected_item_id = "";
        res.is_tree = 0;
        currMergeArr.push(res);

        for (let j = 0; j < typeData[i]?.taxonomies?.length; j++) {
          if (taxonomyData?.length !== 0) {
            const index = taxonomyData
              .map((object) => object.slug)
              .indexOf(typeData[i].taxonomies[j]);
            res = [];
            res.name =
              taxonomyData[index].name +
              (typeData[i]?.taxonomies?.length > 1
                ? " (" + typeData[i].name + ")"
                : "");
            res.slug = taxonomyData[index].slug;
            res.rest_base = taxonomyData[index].rest_base;
            res.type = "taxonomy";
            res.post_type = typeData[i].rest_base;
            res.individual_selected_item_id = "";

            res.is_tree = 3;

            if (typeData[i].taxonomies[j] === "product_cat") {
              res.is_tree = 1;
            } else if (typeData[i].taxonomies[j] === "post_cat") {
              res.is_tree = 2;
            }

            currMergeArr.push(res);
          }
        }
      }

      currMergeArr = currMergeArr.filter(
        (row) =>
          !row.name.toLowerCase().includes("search") &&
          !row.name.toLowerCase().includes("navigation") &&
          !row.name.toLowerCase().includes("pattern") &&
          !row.name.toLowerCase().includes("template") &&
          !row.name.toLowerCase().includes("font")
      );

      currMergeArr = currMergeArr.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }
    setTaxonomyTypeMergeData(currMergeArr);
  };

  const settingIdsandClosedItems = (menuArr, startId = 0) => {
    let currentId = startId;

    const recursTraverse = (arr) => {
      return (
        arr &&
        arr.map((item) => {
          const newItem = {
            ...item,
            id: Math.floor(Math.random() * 2000) + currentId,
            open_item: false,
            item_position: currentId,
          };
          currentId += 1;

          if (newItem.children && newItem.children.length) {
            newItem.children = recursTraverse(newItem.children);
          }

          return newItem;
        })
      );
    };

    return recursTraverse(menuArr);
  };

  const handleRowOpen = (e, itemId) => {
    let currArr = [...itemListingData];
    currArr = accordionDataUpdatation(currArr, itemId, "opening_item", "");

    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };

  const handleItemName = (e, itemId) => {
    e.preventDefault();
    let currArr = [...itemListingData];
    currArr = accordionDataUpdatation(
      currArr,
      itemId,
      "updating_item_name",
      e.target.value
    );
    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };

  const handleItemVisiblity = (value, itemId) => {
    let currArr = [...itemListingData];
    currArr = accordionDataUpdatation(
      currArr,
      itemId,
      "updating_hide_show_status",
      value
    );
    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };

  const handleCopyItem = (e, item) => {
    let currArr = [...itemListingData];

    const currObj = { ...item };
    currObj.children = [];
    currObj.item_is_default = 0;
    currArr.push(currObj);
    currArr = settingIdsandClosedItems(currArr);

    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };

  function deleteItemFromTree(menuArr, menuID) {
    return (
      menuArr &&
      menuArr
        .map((node) => {
          const newNode = { ...node };

          if (newNode.children && newNode.children.length) {
            newNode.children = deleteItemFromTree(newNode.children, menuID);
          }

          // Only allow deletion if the node has no children
          if (newNode.id === menuID) {
            return null; // Mark this node for deletion
          }

          return newNode;
        })
        .filter((node) => node !== null)
    );
  }

  const handleDeleteItem = (e, menuID) => {
    let currArr = [...itemListingData];
    currArr = deleteItemFromTree(currArr, menuID);
    currArr = settingIdsandClosedItems(currArr);
    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };

  const parentSaveIconFn = (uploadedIconName, selectedColorType, itemId) => {
    let currArr = [...itemListingData];
    currArr = accordionDataUpdatation(currArr, itemId, "updating_icon", {
      item_icon: uploadedIconName,
      item_icon_flaticon_color: selectedColorType,
    });
    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };

  const handleDefaultItemChange = (e, itemId) => {
    handleDefaultItem(e, itemId, menuType);
  };

  return (
    <>
      <div className="menu-listfirst">
        <div
          className={`menubuilder-listtype ${
            !parseInt(item.status) ? "menu-section-hidden" : ""
          }`}
        >
          <div className="menu-title-icon">
            <div className=" menu-title-icon-div">
              {!item.item_icon ? (
                <div className="menu-icon-default">
                  <DefaultMenuIcon />
                </div>
              ) : item.item_icon.indexOf("cdn-icons-png.flaticon.com") > -1 ? (
                <img
                  className="img-fluid   2"
                  src={item.item_icon}
                  alt="icon"
                  width="18px"
                />
              ) : item.item_icon_newtype === "flaticon" ? (
                <img
                  className="img-fluid "
                  src={
                    process.env.REACT_APP_Image_Path +
                    parseInt(localStorage.getItem("user_id")) +
                    "/" +
                    parseInt(localStorage.getItem("Appid")) +
                    process.env.REACT_APP_NEW_ICON_PATH +
                    item.item_icon
                  }
                  alt="icon"
                  width="18px"
                />
              ) : menuType === "primary" ? (
                <img
                  className="img-fluid "
                  src={
                    process.env.REACT_APP_Image_Path +
                    (() => {
                      switch (item.item_icon_type) {
                        case "light":
                          return process.env.REACT_APP_light_path;
                        case "bold":
                          return process.env.REACT_APP_bold_path;
                        case "regular":
                          return process.env.REACT_APP_regular_path;
                        default:
                          return process.env.REACT_APP_light_path;
                      }
                    })() +
                    item.item_icon
                  }
                  alt="icon"
                  width="18px"
                />
              ) : (
                <img
                  className="img-fluid "
                  src={
                    process.env.REACT_APP_Image_Path +
                    parseInt(localStorage.getItem("user_id")) +
                    "/" +
                    parseInt(localStorage.getItem("Appid")) +
                    process.env.REACT_APP_NEW_ICON_PATH +
                    item.item_icon
                  }
                  alt="icon"
                  width="18px"
                />
              )}
              {item.item_name}
            </div>
          </div>
          <div className="menubuilder_onoff d-flex align-items-center">
            {item.item_is_default ? (
              <div className="menu-default">
                <p className="menu-default-text">Default</p>
              </div>
            ) : (
              <div
                className="menu-eye-visible"
                onClick={() => handleItemVisiblity(item, item.id)}
              >
                <EyeIcon />
              </div>
            )}

            <p
              className="menu-accrdn-p"
              onClick={(e) => handleRowOpen(e, item.id)}
            >
              <img
                className="accordian-drop-icon"
                src={
                  item.open_item
                    ? require("../../../assets/images/dashboard/cms/accordian-open.png")
                        .default
                    : require("../../../assets/images/dashboard/cms/accordian-icon.png")
                        .default
                }
              />
            </p>
          </div>
        </div>
        {item.open_item ? (
          <div className="menubuildervalue ">
            <div className="menubuildervaluegrid">
              <div className="menu-texticon-div">
                <div className=" menu-web-name">
                  <div className=" ">
                    <label className="menu-inputtype-label">Text</label>
                    <input
                      type="text"
                      className="menu-create-input"
                      maxLength="20"
                      defaultValue={item.item_name}
                      onChange={(e) => handleItemName(e, item.id)}
                    />
                  </div>
                </div>
                <div className=" menu-web-name menu-icontype">
                  <MenuIconField
                    item={item}
                    parentSaveIconFn={parentSaveIconFn}
                    menuType={menuType}
                  />
                </div>
              </div>
              <MenuLinkField
                item={item}
                itemListingData={itemListingData}
                itemListingArrName={itemListingArrName}
                setAccordianState={setAccordianState}
                is_chat_enabled={is_chat_enabled}
                defaultOptionData={defaultOptionData}
                taxonomyTypeMergeData={taxonomyTypeMergeData}
              />

              <div className="menu-bottom-default-select-div">
                <div className="">
                  {item.item_type === "phone" ||
                  item.item_type === "email" ||
                  item.item_type === "share" ? null : (
                    <label className="menu-checkbox-container">
                      <h4 className="menu-default-check-text">
                        Set as default
                        <Tooltip
                          message={
                            "This item will appear as soon as your app is launched. Use this feature to provide quick access to your most important feature."
                          }
                        />
                        {/* <Newfeatureicon/> */}
                      </h4>
                      <input
                        type="checkbox"
                        className="menu-default-checkbox"
                        checked={item.item_is_default}
                        onChange={(e) => handleDefaultItemChange(e, item.id)}
                      />{" "}
                      <span className="menu-default-checkmark"></span>
                    </label>
                  )}
                </div>
                <div className="delete-menu-list-copy ">
                  <img
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={(e) => handleCopyItem(e, item)}
                    src={
                      require("../../../assets/images/dashboard/cms/duplicate.png")
                        .default
                    }
                    alt=""
                  />

                  {!item.children || !item.children.length ? (
                    <img
                      style={{
                        marginLeft: "20px",
                        cursor: "pointer",
                      }}
                      onClick={(e) => handleDeleteItem(e, item.id)}
                      src={
                        require("../../../assets/images/dashboard/cms/delete.png")
                          .default
                      }
                      alt=""
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      {item.children && item.children.length ? (
        item.children.map((nestedItem, nestedIndx) => {
          return (
            <ItemAccordian
              key={nestedItem.id}
              item={nestedItem}
              itemListingData={itemListingData}
              itemListingArrName={itemListingArrName}
              setAccordianState={setAccordianState}
              defaultOptionData={defaultOptionData}
              is_chat_enabled={is_chat_enabled}
              handleDefaultItem={handleDefaultItem}
              menuType={menuType}
            />
          );
        })
      ) : (
        <></>
      )}
    </>
  );
};

export default ItemAccordian;
