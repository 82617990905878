import React, { useEffect, useState } from "react";
import Expand from "../../../Components/commonIcons/Expand";
import BottomBarGridItem from "../../../Components/commonComponents/BottomBar/BottomBarGridItem";
import TileContainer from "../../../Components/commonComponents/BottomBar/TileContainer";
import BottomMenuItem from "../../../Components/commonComponents/BottomBar/BottomMenuItem";
import MoreMenu from "../../../Components/commonComponents/BottomBar/MoreMenu";
import { countBlackIcons } from "../../../utils/commonUtils";
import { Color, Solver } from "../../../Dashboard/BottomBar/Iconcolor";

const BottomPreview = ({ bottomState }) => {
  const {
    settings,
    bottom_menu_items,
    setvalue,
    enable_bottom_menu,
    showexpand,
  } = bottomState;

  const { blackIconItems, totalItems } = countBlackIcons(
    bottomState.bottom_menu_items
  );

  const [iconFilter, seticonfilter] = useState(null);
  const [selectedIconFilter, setselectediconfilter] = useState(null);
  const [gridIconFilter, setgridIconFilter] = useState(null);
  const [selectedGridIconColor, setselectedGridIconColor] = useState(null);

  const visibleItems = bottom_menu_items.filter(item => item.status)

  const handleinputcolor = (iconColor) => {
    let rgb = hexToRgb(iconColor);

    // Explicitly checking for null or undefined
    if (rgb !== undefined && iconColor) {
      const colorvalue = iconColor.match(/[\d\.]+/g).map(Number);

      rgb = [];
      rgb[0] = colorvalue[0];
      rgb[1] = colorvalue[1];
      rgb[2] = colorvalue[2];

      const alpha = colorvalue[3] !== undefined ? colorvalue[3] : 1;

      let filter = "";

      if (rgb && rgb.length === 3 && iconColor) {
        const color = new Color(rgb[0], rgb[1], rgb[2]);
        const solver = new Solver(color);
        const result = solver.solve();
        if (alpha < 1) {
          const opacityPercentage = Math.round(alpha * 100);
          filter = `${result.filter} opacity(${opacityPercentage}%)`;
        } else {
          filter = result.filter;
        }

        return filter;
      }
    }

    return "invert(32%) sepia(25%) saturate(4935%) hue-rotate(210deg) brightness(105%) contrast(96%)";
  };

  const hexToRgb = (color) => {
    if (color) {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
      const hex = `#${(
        (1 << 24) +
        (parseInt(rgba[0]) << 16) +
        (parseInt(rgba[1]) << 8) +
        parseInt(rgba[2])
      )
        .toString(16)
        .slice(1)}`;

      return hex;
    } else {
      return "#fff";
    }
  };

  useEffect(() => {
    seticonfilter(handleinputcolor(settings.bottom_menu_icon_colour));
  }, [settings.bottom_menu_icon_colour]);

  useEffect(() => {
    setselectediconfilter(
      handleinputcolor(settings.bottom_menu_selected_icon_colour)
    );
  }, [settings.bottom_menu_selected_icon_colour]);

  useEffect(() => {
    setgridIconFilter(
      handleinputcolor(settings.bottom_menu_grid_view_icon_color)
    );
  }, [settings.bottom_menu_grid_view_icon_color]);

  useEffect(() => {
    setselectedGridIconColor(
      handleinputcolor(settings.bottom_menu_grid_view_selected_icon_color)
    );
  }, [settings.bottom_menu_grid_view_selected_icon_color]);

  return (
    <div className="bottombar-right">
      <div className="bottombar-preview-right">
        <div className={`bottombar-preview-screen`}
          style={{
            backgroundImage:enable_bottom_menu ? "unset" : ""
          }}
        >
          <div className="bottombar-preview-head">
            <img
              className="img-fluid"
              src={
                require("../../../assets/images/dashboard/bottombar-head.png")
                  .default
              }
              alt="Bottom Bar Header"
            />
          </div>
          {enable_bottom_menu && (
            <div
              className="bottombartint"
              style={{
                background: showexpand
                  ? settings?.bottom_menu_grid_page_bg_color
                  : "transparent",
              }}
            >
              <div className="the-bottombar">
                {showexpand && visibleItems.length > 5 && (
                  <div
                    className="bottombar-expand"
                    style={{
                      background: settings?.bottom_menu_grid_view_bg_color,
                    }}
                  >
                    <Expand />
                    {settings?.bottom_menu_show_grid_view ? (
                      <TileContainer
                        items={visibleItems}
                        settings={settings}
                        bottomState={bottomState}
                        selectedGridIconFilter={selectedGridIconColor}
                        gridIconFilter={gridIconFilter}
                        blackIconItems={blackIconItems}
                        totalItems={totalItems}
                        showexpand={showexpand}
                      />
                    ) : (
                      <div className="bottombar-list">
                        {visibleItems
                          ?.filter((_, indx) => indx >= setvalue)
                          ?.map((item, indx) =>
                            item.status === 1 ? (
                              <BottomBarGridItem
                                key={`bar${indx}`}
                                item={item}
                                settings={settings}
                                isSelected={item.is_default}
                                selectedGridIconFilter={selectedGridIconColor}
                                gridIconFilter={gridIconFilter}
                                blackIconItems={blackIconItems}
                                totalItems={totalItems}
                              />
                            ) : null
                          )}
                      </div>
                    )}
                  </div>
                )}

                <div
                  className="bottombar-bottom"
                  style={{
                    background: settings?.bottom_menu_bg_colour,
                    borderTop: `1px solid ${settings?.bottom_menu_border_colour}`,
                  }}
                >
                  {visibleItems.length > 5 ? visibleItems.slice(0,4)
                    // ?.filter((_, indx) => indx < setvalue)
                    ?.map(
                      (item, indx) =>
                        !!item.status && (
                          <BottomMenuItem
                            key={`menu${indx}`}
                            item={item}
                            settings={settings}
                            isSelected={item.is_default}
                            iconFilter={iconFilter}
                            selectedIconFilter={selectedIconFilter}
                            blackIconItems={blackIconItems}
                            totalItems={totalItems}
                          />
                        )
                    ) : visibleItems
                    ?.map(
                      (item, indx) =>
                        !!item.status && (
                          <BottomMenuItem
                            key={`menu${indx}`}
                            item={item}
                            settings={settings}
                            isSelected={item.is_default}
                            iconFilter={iconFilter}
                            selectedIconFilter={selectedIconFilter}
                            blackIconItems={blackIconItems}
                            totalItems={totalItems}
                          />
                        )
                    )}
                  {visibleItems?.length >= 6 && (
                    <MoreMenu
                      settings={settings}
                      menuiconcolor={
                        settings?.bottom_menu_icon_colour_type_for_menu
                      }
                      iconFilter={iconFilter}
                      blackIconItems={blackIconItems}
                      totalItems={totalItems}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BottomPreview;
