import React from 'react'

const KebabMenu = ({state}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="3.683"
    height="15.259"
    viewBox="0 0 3.683 15.259"
  >
    <path
      id="Union_2682"
      data-name="Union 2682"
      d="M0,13.417a1.841,1.841,0,1,1,1.841,1.841A1.841,1.841,0,0,1,0,13.417ZM0,7.629A1.841,1.841,0,1,1,1.841,9.471,1.841,1.841,0,0,1,0,7.629ZM0,1.842A1.841,1.841,0,1,1,1.841,3.684,1.842,1.842,0,0,1,0,1.842Z"
      fill={state.iconColor}
    />
  </svg>
  )
}

export default KebabMenu