import React from "react";

const MenuHeaderCrossIcon = ({ strokeColor }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8.016"
      height="8.016"
      viewBox="0 0 8.016 8.016"
    >
      <path
        id="Union_315"
        data-name="Union 315"
        d="M3.478,3.478,0,6.955,3.478,3.478,0,0,3.478,3.478,6.956,0,3.478,3.478,6.956,6.955Z"
        transform="translate(0.53 0.53)"
        fill="none"
        fillOpacity="0.70"
        stroke={strokeColor}
        stroke-width="1.5"
      />
    </svg>
  );
};

export default MenuHeaderCrossIcon;
