import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../utils/apiEndpoints";
import { prepareAuthHeaders } from "../utils/commonUtils";

export const addonsApiSlice = createApi({
  reducerPath: "addon",
  keepUnusedDataFor: 1800,
  tagTypes: ["getAddonData"],
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.generalUrl,
    prepareHeaders: (headers) => prepareAuthHeaders(headers),
  }),
  endpoints: (builder) => ({
    addonData: builder.query({
      query: ({ plan_code, from }) =>
        `user/get_package_addons/${
          from === "workspace"
            ? btoa(null)
            : btoa(localStorage.getItem("Appid"))
        }/${
          from === "workspace"
            ? btoa(localStorage.getItem("workspaceId"))
            : btoa(null)
        }/${btoa(null)}/${btoa(plan_code)}`,
      providesTags: ["getAddonData"],
    }),
    appDetailsData: builder.query({
      query: () =>
        `user/get_app_details/${btoa(localStorage.getItem("Appid"))}`,
    }),

    stripeZohoAddonUpdationData: builder.mutation({
      query: (params) => ({
        url: "user/payment",
        method: "POST",
        body: params,
      }),
      invalidatesTags: ["getAddonData"],
    }),
    wooChangePlanSaveData: builder.mutation({
      query: (newData) => ({
        url: "woocommerce/modify-subscription",
        method: "POST",
        body: newData,
      }),
      invalidatesTags: ["getAddonData"],
    }),
    zohoDowngradeSaveData: builder.mutation({
      query: (newData) => ({
        url: "user/downgrade-plan",
        method: "POST",
        body: newData,
      }),
      invalidatesTags: ["getAddonData"],
    }),
    stripeDowngradeSaveData: builder.mutation({
      query: (newData) => ({
        url: "user/remove-recurring-addon",
        method: "POST",
        body: newData,
      }),
      invalidatesTags: ["getAddonData"],
    }),
    paddleDowngradeSaveData: builder.mutation({
      query: (newData) => ({
        url: "paddle/cancel-addon-subscription",
        method: "POST",
        body: newData,
      }),
      invalidatesTags: ["getAddonData"],
    }),
    reactivateAddonPlan: builder.mutation({
      query: (newData) => ({
        url: "user/revoke-recurring-addons-request",
        method: "POST",
        body: newData,
      }),
      // invalidatesTags: ["getAddonData"],
    }),
  }),
});

export const {
  useAddonDataQuery,
  useAppDetailsDataQuery,
  usePaddleDowngradeSaveDataMutation,
  useStripeDowngradeSaveDataMutation,
  useStripeZohoAddonUpdationDataMutation,
  useWooChangePlanSaveDataMutation,
  useZohoDowngradeSaveDataMutation,
  useReactivateAddonPlanMutation
} = addonsApiSlice;
