import React, {useState} from "react";
import "./workspacecommon.css";
import Header from "../Header";
import {createWorkspace,getWorkspace} from "../../Api/Dashboard/DashboardApi";
import { Carousel } from 'react-responsive-carousel';

export default class NewWorkspace extends React.Component {

    constructor() {
        super();
    
        this.state = 
        {
          workspaceName: "",
          nameError: false,
          submitVal: "Create",
          loader: "d-none",
          loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
          sliderStatus:true,
        };
      }
    
      componentDidMount() 
      {
        document.title = "Create Workspace | AppMySite";
        window.scrollTo(0, 0);
        document.body.classList.remove('opec3');
        document.body.classList.remove('opec4');
      }

      handleChange = (e) => {
        e.preventDefault();
        this.setState({
          workspaceName: e.target.value,
          nameError: false,
        });
      };

      handleCreate = (e) => 
      {
        e.preventDefault();
        this.setState({
          submitVal: "",
          loader: "new-loader",
        });
        if (this.state.workspaceName.trim() === "") {
          this.setState({
            nameError: true,
            submitVal: "Create",
            loader: "d-none",
          });
          return;
        }
        const param = {
          workspace_name: this.state.workspaceName,
        };
        createWorkspace(param, this);
      };
      render() 
      {
        return (
          <>
        <div className="workspace-org-module">
            <Header />

            <section>

            <div className="create-workspace-main">

                <div className="create-workspace-left">
                 <form onSubmit={(e) => this.handleCreate(e)}>
                  <div className="create-workspace-left-position">
                    <h1>Create a Workspace</h1>
                    <h2>Begin your app-building journey with a Workspace. Set up your Workspace to manage unlimited apps under a single subscription.</h2>
                    <label>Workspace name*</label>

                    <input autocomplete="off" type="text" name="websiteUrl" onChange={(e) => {this.handleChange(e);}} ></input>
                    <div className="d-flex align-items-center mt-md-1 error">
                      <div>
                        {this.state.nameError ? (
                          <div className="d-flex align-items-center mt-md-1 error">
                            <img
                              src={
                                require("../../assets/images/signup/error.png")
                                  .default
                              }
                              className="img-fluid"
                              alt="error"
                            />
                            <p>workspace name can't be empty </p>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <button   id="btncreateorgid create-workspace-btn">
                    <div className={this.state.loader}></div>
                    {this.state.submitVal}
                    </button>
                  </div>
                 </form>
                </div>
           

                <div className="create-workspace-right">
                    <div className="create-workspace-right-position">
                        <img className="img-fluid" src={ require("../../assets/images/dashboard/workspace/workspace-createpageimg.png").default } alt="create-workspace" />

                        <div className="signup-crousel">
                                <Carousel autoPlay={this.state.sliderStatus} infiniteLoop={this.state.sliderStatus} classNamePrefix="signup-carousel" showThumbs={false}>
             
                                  <div >
                                    <h2>Create unlimited apps</h2>
                                    <h3>Build and manage unlimited mobile apps in each Workspace.</h3>
                                    </div>
                                    <div className="">
                                    <h2>Cost-effective app development</h2>
                                    <h3>Maximize efficiency while minimizing costs.</h3>
                                    </div>
                                    <div className="">
                                    <h2>Simplified subscription management</h2>
                                    <h3>Easy subscription management. One workspace, one bill.</h3>
                                    </div>
                                    <div className="">
                                    <h2>Collaborate with team members</h2>
                                    <h3>Team up for app success and collaborate effectively.</h3>
                                    </div> 
                                </Carousel>
                                
                            </div>

                    </div>
                </div>
            </div>

            </section>
        </div>       
        </>
    );
  }
}