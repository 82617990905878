import React, { useState, useEffect } from "react";
// import "../../Agency/assets/css/common.css";
import "./workspacecommon.css";
import Header from "../../Dashboard/Header";
import LeftPanel from "./workspaceLeftpannel";
import { Link, useParams } from "react-router-dom";
import { errortoast } from '../../Dashboard/Toaster';
import { getWorkspaceAppsDetail, WorkspacereceivedData, getWorkspacesearchappsdata } from "../../Api/Dashboard/DashboardApi";
import moment from "moment";
import imagebackground from "../../Agency/assets/images/accountprofile.png";
import imagebackground2 from "../../Agency/assets/images/alexandergrey.jpg";
import NewSpinner from "../../Agency/NewSpinner";
import ReactPaginate from 'react-paginate';
import Select from "react-select";
import BottomScrollListener from "react-bottom-scroll-listener";

const Workspaceappslist = () => {

    const [rightpanelexpand, setRightPanelExpand] = useState(true);
    const [dataapps, setApps] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [spinner1, setSpinner1] = useState(true);
    const [searchtext, setSearchText] = useState("");
    const [offset, setoffset] = useState(0);
    const [data, setdata] = useState([[]]);
    const [currentPage, setcurrentPage] = useState(1);
    const [pageCount, setpageCount] = useState(0);
    const [stoplength, setstoplength] = useState(0);
    const [domain, setDomain] = useState('');
    const [domainstatus, setDomainStatus] = useState('');
    const [domainname, setDomainName] = useState('');
    const [searcheable, setsearcheable] = useState(false);
    const [initialAppsCount, setInitialAppsCount] = useState(0)

    let { workspaceID } = useParams("workspaceID");

    useEffect(() => {
        document.title = "Apps | AppMySite";

        if (workspaceID !== '' && workspaceID !== undefined) {
            getWorkspaceAppsDetail(workspaceID, setSpinner, dataapps, setApps, setpageCount, setSpinner1, setInitialAppsCount);
        }

    }, []);

    const handleTxtSearch = (e) => {
        setSearchText(e.target.value);
    }
    const searchdata = (e) => {
        
        if (e.keyCode == 13) {
            getWorkspacesearchappsdata(searchtext, workspaceID, setSpinner, setApps, setsearcheable);
        }
    }

    useEffect(() => {
        if(!searchtext){
            getWorkspacesearchappsdata(searchtext, workspaceID, setSpinner, setApps, setsearcheable);
        }
    },[searchtext])

    const handleRightPanelExpand = (rightpanelvalue) => {

        setRightPanelExpand(rightpanelvalue);
    }
    // bottom scroll listner
    const handleBottomScrollListner = () => {
        
        setSpinner1(true);
        const selectedPage = currentPage + 1;
        setcurrentPage(selectedPage);
        WorkspacereceivedData(selectedPage, workspaceID, setSpinner1, dataapps, setApps, setpageCount, setstoplength);


    };

    const handleAppdata = (
        user_id,
        status,
        appname,
        id,
        appshortname,
        subscription,
        store_url,
        website_type,
        website_technology,
        is_verified,
      ) => {
        //if(this.state.roledata.role === 1){
        localStorage.setItem("user_id", user_id);
        //}
        if (id !== "") 
        {
          localStorage.setItem("Appid", id);
          localStorage.setItem("Appname", appname);
          localStorage.setItem("appshortname", appshortname);
          localStorage.setItem("store_url", store_url);
          localStorage.setItem("website_type", parseInt(website_type));
          localStorage.setItem("website_type_select", parseInt(website_type));
          localStorage.setItem("website_technology", website_technology);
          localStorage.setItem("is_verified", is_verified);
          localStorage.removeItem("session");
          
          let subscriptionlength = subscription;
      
          if (subscriptionlength === null || (subscriptionlength && subscriptionlength.package_info && subscriptionlength.package_info.plan_name.indexOf("preview") !== -1)) 
          {
            localStorage.setItem("subscriptionactive", "0");
            localStorage.setItem("plan_code", btoa("preview"));
            if (
              status === "duplicate" ||
              status === "deleted" ||
              status === "Cancelled"
            ) {
              window.location.href = "/apps";
            } else {
              window.location.href = `/app/dashboard`;
            }
      
            if (status === "duplicate") 
            {
              this.setState({
                hitpopup: true,
                title: "Duplicate App Suspected",
                description:
                  "This app appears to be a duplicate as the same domain was used to create another app. Would you still like to proceed?",
                bothbutton: false,
                suspended_app_id: id,
              });
            }
            if (status === "deleted") 
            {
              errortoast("App deleted");
            }
            if (status.toLowerCase() === "cancelled") 
            {
              errortoast("App deleted");
            }
          } else {
            let subpackagename = subscriptionlength.addon_codes;
            localStorage.setItem("plan_code", btoa(subscriptionlength.plan_code));
            localStorage.setItem(
              "subscriptionactive",
              btoa(JSON.stringify(subpackagename))
            );
            if (
              status === "duplicate" ||
              status === "deleted" ||
              status === "Cancelled"
              // ||status === "inactive"
            ) {
              window.location.href = "/apps";
            } else {
              window.location.href = `/app/dashboard`;
            }
            if (status === "duplicate") {
              this.setState({
                hitpopup: true,
                title: "Duplicate App Suspected",
                description:
                  "This app appears to be a duplicate as the same domain was used to create another app. Would you still like to proceed?",
                bothbutton: false,
                suspended_app_id: id,
              });
            }
            //For appsumo client
            if (
              status === "inactive" &&
              subscription.plan_code
                .toString()
                .indexOf(process.env.REACT_APP_Lifetime_plan) !== -1
            ) {
              this.setState({
                hitpopup: true,
                title: "Your app is inactive",
                description:
                  "This app is currently inactive.  Would you like to activate it now?",
                bothbutton: true,
                appsumo_app_id: id,
              });
            }
      
            if (status === "deleted") {
              errortoast("App deleted");
            }
            if (status.toLowerCase() === "cancelled") {
              errortoast("App deleted");
            }
          }
        }
      };

    

    return (

        <BottomScrollListener onBottom={handleBottomScrollListner}>
            {(scrollRef) => (
                <>
                    <div className="workspace-org-module workspace-org-module-customer-div"  ref={scrollRef}>
                        <Header />

                        <div className="workspace-org-structure">

                            <LeftPanel rightpanelbody={handleRightPanelExpand} />

                            <div className={localStorage.getItem("worksidebaserotate") === 'true' ? "workspace_background"
                                : "workspace_background workspace_background-expand"} >



                                <div className="workspaceapplist-dashboard-head">

                                    <div className="workspace-headings">
                                        <div className="workspace-headingdiv">
                                            <div className="workspace-headingdiv-text">
                                            <h2>Apps</h2>
                                            <p class="workspace-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                                        <circle cx="9" cy="9" r="9" stroke="none" />
                                                        <circle cx="9" cy="9" r="8.25" fill="none" />
                                                    </g>
                                                    <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5" />
                                                    <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                                                </g>
                                            </svg><p class="workspace-tooltipsubheader">All apps created are listed here.</p></p>
                                            </div>
                                            <Link to={`/app/create/${(workspaceID)}`}>
                                            <img
                                    src={
                                      require("../../assets/images/dashboard/plus.png")
                                        .default
                                    }
                                    alt="plus"
                                    className=""
                                  />
                                  <p>Create</p>
                                            </Link>
                                        </div>

                                        {/* {dataapps.length > 0 || searcheable ?  */}
                                        <div className="d-flex">
                                        {initialAppsCount > 0 && <div className="workspaceinput-search">
                                        <svg id="Group_37943" data-name="Group 37943" xmlns="http://www.w3.org/2000/svg" width="16.53" height="16.53" viewBox="0 0 16.53 16.53">
                                            <g id="Ellipse_246" data-name="Ellipse 246" transform="translate(14.675) rotate(90)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                                <ellipse cx="7.337" cy="7.337" rx="7.337" ry="7.337" stroke="none" />
                                                <ellipse cx="7.337" cy="7.337" rx="6.587" ry="6.587" fill="none" />
                                            </g>
                                            <path id="Path_6064" data-name="Path 6064" d="M0,5.347V0" transform="translate(16 16) rotate(135)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                                        </svg>
                                        <input type="text" placeholder="Search" name="txtsearch" id="txtsearch" onKeyDown={(e) => searchdata(e)} onChange={(e) => handleTxtSearch(e)} value={searchtext} />
                                        {
                                            searchtext && <svg
                                            onClick={() => setSearchText('')}
                                             className="workspaceinput-close-cursor-svg" xmlns="http://www.w3.org/2000/svg" width="11.06" height="11.06" viewBox="0 0 11.06 11.06">
                                            <path id="Union_2970" data-name="Union 2970" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                        }
                                    </div>}
                                        {/* : null
                                    } */}
<div className="d-none">
            <div
              className=" workspace-advancesearch_takethere"
              
            > 
               <p className="mb-0" >
               
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                  <g id="Group_37996" data-name="Group 37996" transform="translate(-1600 -118)">
                    <line id="Line_388" data-name="Line 388" x1="2" transform="translate(1618 128)" fill="none" stroke="#bcc1ce" strokeLinejoin="round" strokeWidth="2" />
                    <line id="Line_389" data-name="Line 389" x1="14" transform="translate(1600 128)" fill="none" stroke="#bcc1ce" strokeLinejoin="round" strokeWidth="2" />
                    <circle id="Ellipse_8280" data-name="Ellipse 8280" cx="2" cy="2" r="2" transform="translate(1608 119)" fill="none" stroke="#bcc1ce" strokeLinejoin="round" strokeWidth="2" />
                    <line id="Line_390" data-name="Line 390" x1="8" transform="translate(1612 121)" fill="none" stroke="#bcc1ce" strokeLinejoin="round" strokeWidth="2" />
                    <line id="Line_391" data-name="Line 391" x1="8" transform="translate(1600 121)" fill="none" stroke="#bcc1ce" strokeLinejoin="round" strokeWidth="2" />
                    <line id="Line_392" data-name="Line 392" x1="14" transform="translate(1606 135)" fill="none" stroke="#bcc1ce" strokeLinejoin="round" strokeWidth="2" />
                    <line id="Line_393" data-name="Line 393" x1="2" transform="translate(1600 135)" fill="none" stroke="#bcc1ce" strokeLinejoin="round" strokeWidth="2" />
                    <circle id="Ellipse_8281" data-name="Ellipse 8281" cx="2" cy="2" r="2" transform="translate(1602 133)" fill="none" stroke="#bcc1ce" strokeLinejoin="round" strokeWidth="2" />
                    <circle id="Ellipse_8282" data-name="Ellipse 8282" cx="2" cy="2" r="2" transform="translate(1614 126)" fill="none" stroke="#bcc1ce" strokeLinejoin="round" strokeWidth="2" />
                  </g>
                </svg>
              <div className="filterapplyed-reddot"></div>
              </p> 
              
            </div>
<div className="workspace-filter-open workspace-filter-close">
<div className="workspace-filter-head ">
                <h4>Filter</h4>
                <p >Reset</p>
                </div>
                <div className="workspace-filter-seconddiv"> 
                <Select
                classNamePrefix="select-filter"
        placeholder="Select"
        >
            </Select> 
            <div className="workspace-filter-thirddiv">
            <input className="workspace-filter-input"/>
            <div className="workspace-filter-delete">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14.999" viewBox="0 0 15 14.999">
              <g id="Group_38004" data-name="Group 38004" transform="translate(-15 -15)">
                <g id="Group_3246" data-name="Group 3246" transform="translate(15 15.5)">
                  <path id="Rectangle_2307" data-name="Rectangle 2307" d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" transform="translate(1.5 1.999)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" strokeWidth="1"/>
                  <path id="Rectangle_2308" data-name="Rectangle 2308" d="M1,0H4A1,1,0,0,1,5,1V2A0,0,0,0,1,5,2H0A0,0,0,0,1,0,2V1A1,1,0,0,1,1,0Z" transform="translate(5)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" strokeWidth="1"/>
                  <g id="Group_3245" data-name="Group 3245" transform="translate(5.5 5.5)">
                    <line id="Line_110" data-name="Line 110" y2="5" transform="translate(4 0)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" strokeWidth="1"/>
                    <line id="Line_111" data-name="Line 111" y1="5" transform="translate(0 0)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" strokeWidth="1"/>
                  </g>
                  <line id="Line_112" data-name="Line 112" x2="15" transform="translate(0 1.999)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" strokeWidth="1"/>
                </g>
              </g>
            </svg>
            </div>
            </div>
                </div>
                <div className="workspace-and">
                    AND
                </div>
                <div className="workspace-filter-seconddiv"> 
                <Select
                classNamePrefix="select-filter"
        placeholder="Select"
        >
            </Select> 
            <div className="workspace-filter-thirddiv">
            <input className="workspace-filter-input"/>
            <div className="workspace-filter-delete">
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="14.999" viewBox="0 0 15 14.999">
              <g id="Group_38004" data-name="Group 38004" transform="translate(-15 -15)">
                <g id="Group_3246" data-name="Group 3246" transform="translate(15 15.5)">
                  <path id="Rectangle_2307" data-name="Rectangle 2307" d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" transform="translate(1.5 1.999)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" strokeWidth="1"/>
                  <path id="Rectangle_2308" data-name="Rectangle 2308" d="M1,0H4A1,1,0,0,1,5,1V2A0,0,0,0,1,5,2H0A0,0,0,0,1,0,2V1A1,1,0,0,1,1,0Z" transform="translate(5)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" strokeWidth="1"/>
                  <g id="Group_3245" data-name="Group 3245" transform="translate(5.5 5.5)">
                    <line id="Line_110" data-name="Line 110" y2="5" transform="translate(4 0)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" strokeWidth="1"/>
                    <line id="Line_111" data-name="Line 111" y1="5" transform="translate(0 0)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" strokeWidth="1"/>
                  </g>
                  <line id="Line_112" data-name="Line 112" x2="15" transform="translate(0 1.999)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" strokeWidth="1"/>
                </g>
              </g>
            </svg>
            </div>
            </div>
                </div>
                <div
                    className="workspace-add-filter"
                  >
                    <div className="add-fileld-btn">
                    <img
                      src={
                        require("../../assets/images/dashboard/plus.png")
                          .default
                      }
                      alt="plus"
                      className="img-fluid"
                    />
                    <p>
                    Add filter
                    </p>
                    </div>
                  </div>
                  <div className="workpsace-filter-btn">
                  <button className="workpsace-filter-search-btn">Search</button>
                  <div className="workpsace-filter-cancle-btn">
                    <p>Cancel</p></div>
                  </div>
</div>

            </div>
                                <Link to={`/app/create/${(workspaceID)}`}>
                                    <button className="workspace-createapp-button" style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor')}}> <img
                                                        src={
                                                          require("../../assets/images/dashboard/newwhiteplus.png")
                                                            .default
                                                        }
                                                       
                                                        alt="plus"
                                                        title="plus"
                                                      />
                                                      <p>Create app</p>
                                                      </button>
                                                      </Link>
                                                      </div>
                                    </div>

                                  
                                </div>
                                { spinner ? 
														  <div className="app-icon-section access-loader workspace-loader-div">
														  <div className="newspinner">
														  <svg xmlns="http://www.w3.org/2000/svg" 
															  xlink="http://www.w3.org/1999/xlink" 
															  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
															  width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
															  <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
															  <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
															  <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
															  style={{animationplaystate: "running", animationdelay: "0s"}}>
															  </animateTransform>
															  </circle>
															  </g>
															  <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
															  <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
															  <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
															  style={{animationplaystate: "running", animationdelay: "0s"}}>
															  </animateTransform>
															  </circle>
															  </g>
															  <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
															  <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
															  <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
															  style={{animationplaystate: "running", animationdelay: "0s"}}>
						  
															  </animateTransform>
															  </circle>
															  </g>
															  <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
															  <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
															  <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
															  style={{animationplaystate: "running", animationdelay: "0s"}}>
						  
															  </animateTransform>
															  </circle>
															  </g>
															  </svg>
														</div>
													  </div>
							:
							<>

                                    {dataapps == undefined || dataapps && dataapps.length <= 0 ?
                                        <div className="workspace-module-shadow">
                                            <div className="workspace-no-customer">
                                                <div>
                                                    <img src={require("../../Agency/assets/images/no-app.png").default} alt="no-app" />
                                                    <p>Nothing here at the moment. Come back later?</p>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    : <></>
                                    }

                                    {dataapps && dataapps.length > 0 &&
                                        <>

                                            <div className="workspace-apps-web workspace-module-shadow"  >
                                                <div className="worksapce-customer-list" >
                                                    <div className="worksapce-applist-head">
                                                        <div>
                                                            <h6>App</h6>
                                                        </div>
                                                        <div>
                                                            <h6>Domain</h6>
                                                        </div>
                                                        <div>
                                                            <h6>Type</h6>
                                                        </div>
                                                        <div>
                                                            <h6>Downloads</h6>
                                                        </div>
                                                        {/* <div>
                                                            <h6>Plan</h6>
                                                        </div> */}
                                                        <div>
                                                            <h6>Created</h6>
                                                        </div>
                                                    </div>

                                                    <div className="workspace-customer-body">

                                                        {dataapps.map((appslist, appskey) => {
                                                            if (appslist.app_icon !== '' && appslist.app_icon !== null && appslist.app_icon !== undefined) {
                                                                var appicon = JSON.parse(appslist.app_icon.create_json);
                                                            }
                                                            else {
                                                                var appicon = '';
                                                            }

                                                            if (appslist.app_download_property !== null) {
                                                                var json = JSON.parse(appslist.app_download_property.create_json);
                                                                if (json.play_store_app_downloads.default && json.play_store_app_downloads.default !== null && json.play_store_app_downloads.default !== undefined && json.play_store_app_downloads.default.numberOfInstalls == undefined && json.play_store_app_downloads.default.numberOfInstalls == null) {
                                                                    var appno = json.play_store_app_downloads.default.numberOfInstalls;
                                                                }
                                                                else {
                                                                    var appno = 0;
                                                                }


                                                            }
                                                            else {
                                                                var appno = '-';
                                                            }

                                                            return (
                                                                <Link to={"#"} onClick={(e) =>
                                                                    handleAppdata(
                                                                      appslist.subscription[0].user_id,
                                                                      appslist.subscription[0].status,
                                                                      appslist.app_name,
                                                                      appslist.id,
                                                                      appslist.app_name,
                                                                      appslist.subscription[0],
                                                                      appslist.store_url,
                                                                      appslist.website_type,
                                                                      appslist.website_technology,
                                                                      1,
                                                                    )
                                                                  }>
                                                                <div className="worksapceapp-body-row">
                                                                    <div className="workspace-app-profile workspace-app-entry">
                                                                        <div className="workspace-app-image"
                                                                            style=
                                                                            {{
                                                                                backgroundImage:
                                                                                    appicon && appicon.app_frontend_display_icon && appslist.subscription[0] && appslist.subscription[0] !== null && appslist.subscription[0] !== undefined && appslist.subscription.length > 0
                                                                                        ?
                                                                                        "url(" +
                                                                                        process.env.REACT_APP_Image_Path +
                                                                                        appslist.subscription[0].user_id +
                                                                                        "/" +
                                                                                        appslist.id +
                                                                                        process.env.REACT_APP_AppIcon_Display +
                                                                                        appicon.app_frontend_display_icon +
                                                                                        ")"
                                                                                        : "url(" + imagebackground2 + ")"
                                                                                ,
                                                                            }}
                                                                        >
                                                                        </div>
                                                                        <div>
                                                                            <h2>{appslist.app_name}</h2>
                                                                            <h3>App ID: {(appslist.id)}</h3>
                                                                        </div>
                                                                    </div>

                                                                    <div className="workspace-app-entry">
                                                                        <h4>{appslist.store_url && appslist.store_url != '' ? appslist.store_url.replace('https://', '') : ''}</h4>
                                                                    </div>

                                                                    <div className="workspace-app-entry">
                                                                        {appslist.website_technology === 2 ?
                                                                            <p class="workspace-apptype-tooltip"><img src={require("../../Agency/assets/images/webtoapp.png").default} alt="webtoapp" /><p class="workspace-apptype-tooltipsubheader">Web to App</p></p>
                                                                            :
                                                                            (appslist.website_technology === 3 ?
                                                                                <p class="workspace-apptype-tooltip"><img src={require("../../Agency/assets/images/customapp.png").default} alt="customapps" /> <p class="workspace-apptype-tooltipsubheader">Custom App</p></p>
                                                                                :
                                                                                (appslist.website_technology === 4 ?
                                                                                    <p class="workspace-apptype-tooltip"><img src={require("../../Agency/assets/images/wordpress.png").default} alt="wordpress" /><p class="workspace-apptype-tooltipsubheader">Wordpress App</p></p>
                                                                                    :
                                                                                    (appslist.website_technology === 5 ?
                                                                                        <p class="workspace-apptype-tooltip"><img src={require("../../Agency/assets/images/woocomerce.png").default} alt="woocommerce" /><p class="workspace-apptype-tooltipsubheader">WooCommerce App</p></p>
                                                                                        :
                                                                                        null
                                                                                    )
                                                                                )
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div className="workspace-app-entry">
                                                                        {/* {appslist.app_download_property !== null ? */}
                                                                            <>
                                                                                <div className="workspace-app-download-stat">
                                                                                    <img src={require("../../Agency/assets/images/android.png").default} alt="android" />
                                                                                  {
                                                                                    appslist.android_downloads ?
                                                                                     <p>{appslist.android_downloads}+</p>
                                                                                    :
                                                                                    <p>-</p>
                                                                                  }
                                                                                </div>

                                                                                <div className="workspace-app-download-stat">
                                                                                    <img src={require("../../Agency/assets/images/ios.png").default} alt="ios" />
                                                                                    <p>-</p>
                                                                                </div>
                                                                            </>
                                                                            {/* :
                                                                            '-'
                                                                        } */}

                                                                    </div>

                                                                    {/* <div className="workspace-app-entry">
                                                                        <h4>
                                                                            {
                                                                                appslist.subscription && appslist.subscription !== null &&
                                                                                    appslist.subscription !== undefined &&
                                                                                    appslist.subscription.length > 0 ?
                                                                                    appslist.subscription[0].package_info.plan_name
                                                                                    : ''
                                                                            }
                                                                        </h4>
                                                                    </div> */}

                                                                    <div className="customer-status workspace-app-entry">
                                                                        <h4>{moment(appslist.created_at).format("D MMMM YYYY, HH:mm")}</h4>
                                                                    </div>

                                                                </div>
                                                                </Link>
                                                            )

                                                        })
                                                        }

                                                    </div>
                                                    {spinner1 ?
                                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                            <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                    <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                    </animateTransform>
                                                                </circle>
                                                            </g>
                                                            <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                    <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                    </animateTransform>
                                                                </circle>
                                                            </g>
                                                            <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                    <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                    </animateTransform>
                                                                </circle>
                                                            </g>
                                                            <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                    <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                    </animateTransform>
                                                                </circle>
                                                            </g>
                                                        </svg>
                                                        :
                                                        null
                                                    }
                                                </div>
                                            </div>

                                            <div className="workspace-apps-mobile-view">

                                                {dataapps.map((appslist, appskey) => {
                                                    if (appslist.app_icon !== '' && appslist.app_icon !== null && appslist.app_icon !== undefined) {
                                                        var appicon = JSON.parse(appslist.app_icon.create_json);
                                                    }
                                                    else {
                                                        var appicon = '';
                                                    }

                                                    if (appslist.app_download_property !== null) {
                                                        var json = JSON.parse(appslist.app_download_property.create_json);
                                                        if (json.play_store_app_downloads.default && json.play_store_app_downloads.default !== null && json.play_store_app_downloads.default !== undefined && json.play_store_app_downloads.default.numberOfInstalls == undefined && json.play_store_app_downloads.default.numberOfInstalls == null) {
                                                            var appno = json.play_store_app_downloads.default.numberOfInstalls;
                                                        }
                                                        else {
                                                            var appno = 0;
                                                        }

                                                    }
                                                    else {
                                                        var appno = '-';
                                                    }
                                                    return (
                                                        <Link to={"#"}  onClick={(e) =>
                                                            handleAppdata(
                                                                appslist.subscription[0].user_id,
                                                                appslist.subscription[0].status,
                                                                appslist.app_name,
                                                                appslist.id,
                                                                appslist.app_name,
                                                                appslist.subscription[0],
                                                                appslist.store_url,
                                                                appslist.website_type,
                                                                appslist.website_technology,
                                                                1,
                                                              )
                                                          }>
                                                        <div className="workspace-apps-mobile">
                                                            <div className="workspace-module-shadow">

                                                                <div className="workspace-apps-mob-1">
                                                                    <div>
                                                                        <h2>{appslist.app_name}</h2>
                                                                        <h3>App ID: {appslist.id}</h3>
                                                                    </div>

                                                                    <div className="workspace-app-image-mobile" style={{
                                                                        backgroundImage:
                                                                            appicon && appicon.app_frontend_display_icon && appslist.subscription[0] && appslist.subscription[0] !== null && appslist.subscription[0] !== undefined && appslist.subscription.length > 0
                                                                                ?
                                                                                "url(" +
                                                                                process.env.REACT_APP_Image_Path +
                                                                                appslist.subscription[0].user_id +
                                                                                "/" +
                                                                                appslist.id +
                                                                                process.env.REACT_APP_AppIcon_Display +
                                                                                appicon.app_frontend_display_icon +
                                                                                ")"
                                                                                : "url(" + imagebackground2 + ")"
                                                                        ,
                                                                    }}></div>
                                                                </div>

                                                                <div className="workspace-apps-mob-2">
                                                                    <div>
                                                                        <h6>Domain</h6>
                                                                        <h4>{appslist.store_url && appslist.store_url != '' ? appslist.store_url.replace('https://', '') : ''}</h4>
                                                                    </div>
                                                                </div>

                                                                <div className="workspace-apps-mob-3">
                                                                    <div>
                                                                        <h6>Type</h6>
                                                                        {appslist.website_technology === 2 ?
                                                                            <img src={require("../../Agency/assets/images/webtoapp.png").default} alt="webtoapp" />
                                                                            :
                                                                            (appslist.website_technology === 3 ?
                                                                                <img src={require("../../Agency/assets/images/customapp.png").default} alt="customapps" />
                                                                                :
                                                                                (appslist.website_technology === 4 ?
                                                                                    <img src={require("../../Agency/assets/images/wordpress.png").default} alt="wordpress" />
                                                                                    :
                                                                                    (appslist.website_technology === 5 ?
                                                                                        <img src={require("../../Agency/assets/images/woocomerce.png").default} alt="woocommerce" />
                                                                                        :
                                                                                        null
                                                                                    )
                                                                                )
                                                                            )
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="workspace-apps-mob-4">
                                                                    <div>
                                                                        <h6>Downloads</h6>

                                                                        <div className="workspace-app-technology d-flex">
                                                                            

                                                                                <>
                                                                                    <div className="agency-app-download-stat">
                                                                                        <img src={require("../../Agency/assets/images/android.png").default} alt="android" />
                                                                                        {
                                                                                    appslist.android_downloads ?
                                                                                     <p>{appslist.android_downloads}+</p>
                                                                                    :
                                                                                    <p>-</p>
                                                                                  }
                                                                                    </div>

                                                                                    <div className="agency-app-download-stat">
                                                                                        <img src={require("../../Agency/assets/images/ios.png").default} alt="ios" />
                                                                                        <p>-</p>
                                                                                    </div>
                                                                                </>
                                                                                
                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="workspace-apps-mob-2">
                                                                    <div>
                                                                        <h6>Plan</h6>
                                                                        <h4>{
                                                                            appslist.subscription && appslist.subscription !== null &&
                                                                                appslist.subscription !== undefined &&
                                                                                appslist.subscription.length > 0 ?
                                                                                appslist.subscription[0].package_info.plan_name
                                                                                : ''
                                                                        }</h4>
                                                                    </div>
                                                                </div>

                                                                <div className="workspace-apps-mob-2">
                                                                    <div>
                                                                        <h6>Created</h6>
                                                                        <h4>{moment(appslist.created_at).format("D MMMM YYYY, HH:mm")}</h4>
                                                                    </div>
                                                                </div>

                                                            </div>


                                                        </div>
                                                        </Link>
                                                    )
                                                })
                                                }

                                                {spinner1 ?
                                                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                        <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                            <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                </animateTransform>
                                                            </circle>
                                                        </g>
                                                        <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                            <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                </animateTransform>
                                                            </circle>
                                                        </g>
                                                        <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                            <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                </animateTransform>
                                                            </circle>
                                                        </g>
                                                        <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                            <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                                </animateTransform>
                                                            </circle>
                                                        </g>
                                                    </svg>
                                                    :
                                                    null
                                                }



                                            </div>
                                        </>
                                    }


                            </>
                            }

                            </div>

                        </div>

                    </div>
                </>
            )}
        </BottomScrollListener>


    );

}

export default Workspaceappslist;