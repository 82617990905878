import React from "react";

const BottomBarGridItem = ({
  item,
  settings,
  isSelected,
  selectedGridIconFilter,
  gridIconFilter,
  blackIconItems,
  totalItems,
}) => {
  if (!item || item.status !== 1) return null;

  const { item_icon, item_icon_newtype, item_icon_type, item_name } = item;

  const iconSrc =
    item_icon && item_icon !== ""
      ? item_icon_newtype === "flaticon"
        ? `${process.env.REACT_APP_Image_Path}${localStorage.getItem(
            "user_id"
          )}/${localStorage.getItem("Appid")}${
            process.env.REACT_APP_NEW_ICON_PATH
          }${item_icon}`
        : `${process.env.REACT_APP_Image_Path}${
            item_icon_type === "light"
              ? process.env.REACT_APP_light_path
              : item_icon_type === "bold"
              ? process.env.REACT_APP_bold_path
              : item_icon_type === "regular"
              ? process.env.REACT_APP_regular_path
              : item_icon_type === "new"
              ? `${parseInt(localStorage.getItem("user_id"))}/${parseInt(
                  localStorage.getItem("Appid")
                )}${process.env.REACT_APP_NEW_ICON_PATH}`
              : process.env.REACT_APP_light_path
          }${item_icon}`
      : require("../../../assets/images/dashboard/create-bottom-bar.png")
          .default;

  const maxWidthHeight =
    settings?.bottom_menu_grid_view_column === 1
      ? "30px"
      : settings?.bottom_menu_grid_view_column === 2 ||
        settings?.bottom_menu_grid_view_column === 3
      ? "14px"
      : "14px";

  const filter =
    blackIconItems == totalItems
      ? isSelected
        ? selectedGridIconFilter
        : gridIconFilter
      : null;
  return (
    <div>
      {settings?.bottom_menu_grid_view_show_icon && (
        <img
          className="img-fluid"
          src={iconSrc}
          alt={item_name || "grid-item"}
          style={{
            maxWidth: maxWidthHeight,
            maxHeight: maxWidthHeight,
            filter: filter,
          }}
        />
      )}
      {settings?.bottom_menu_grid_view_show_text && (
        <p
          style={{
            color: isSelected
              ? settings?.bottom_menu_grid_view_selected_icon_text_color
              : settings?.bottom_menu_grid_view_icon_text_color,
          }}
        >
          {item_name}
        </p>
      )}
    </div>
  );
};

export default BottomBarGridItem;
