import React from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import "../../../assets/css/Icon-library.css";
import "../../Appsettings/menuBuilder.css";

// layout: {
//   name: "", // Layout name based on maximum no of column or row  (product_n*2)
//   text_alignment: "", // left/right/center
//   text_position: "", //outside_top/bottom/right/left  ||  inside_top/bottom/right/left
//   image_aspect_ratio: "4:3",
// },

export const itemSelectionPopUp = (
  handleItemTypePopUp,
  isItemTypePopUp,
  itemData,
  itemSpinner,
  breadcrumb,
  handleBreadcrumb,
  spinnerForL2,
  isL2Items,
  sortedData2,
  handleL1Selection,
  handleL2Selection,
  handleTextParentItemClick,
  handleChildTextClick,
  selectedArray,
  from
) => {
  const convertHTMLEntity = (text) => {
    const span = document.createElement("span");

    return text.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
      span.innerHTML = entity;
      return span.innerText;
    });
  };
  const chechkedSelectedValue = (value) => {
    let isCheck = 0;
    selectedArray &&
      selectedArray.map((id) => {
        if (id === value.term_id.toString()) {
          isCheck++;
        }
      });

    return isCheck > 0;
  };
  return (
    <div>
      <Modal
        toggle={(e) => handleItemTypePopUp(e, "cancel")}
        isOpen={isItemTypePopUp}
        className="category-popup homescreen-select-category modal-dialog-centered select-category-popup"
        style={{ maxWidth: "1280px", minHeight: "660px" }}
        centered
      >
        <ModalHeader
          toggle={(e) => handleItemTypePopUp(e, "cancel")}
          className="menubuilder-modal-header"
        >
          <div className="d-flex justify-content-between align-items-center">
            {from}
            {selectedArray.length > 0 && (
              <h5 className=" item-selection-menu">
                {selectedArray.length} item selected
              </h5>
            )}
          </div>
        </ModalHeader>
        <ModalBody className="menu-modal-body">
          <div className="row">
            <div className="col-md-12 ipad-col-big ipad-col-red2">
              {itemData !== null && itemData.length > 0 ? (
                <div className="row">
                  <div className={`col-md-6 p-0`}>
                    <form>
                      <div className="menu-option-list">
                        <ul>
                          {itemSpinner === true ? (
                            <Spinner animation="border" variant="primary" />
                          ) : (
                            itemData.map((value, k) => {
                              return (
                                <li className="custom-radio-btn menubuilder-radio-btn L2-level">
                                  
                                  <label
                                    for={`radioL1button${k}`}
                                    className="homescreen-selection-checkbox"
                                  >
                                    <p
                                    onClick={(e) =>
                                      value.children !== undefined &&
                                      value.children.length > 0 &&
                                      handleTextParentItemClick(e, value, k)
                                    }
                                    className={
                                      value.children !== undefined &&
                                      value.children.length > 0
                                        ? "underline-text"
                                        : value.child !== undefined &&
                                          value.child.length > 0
                                        ? "underline-text"
                                        : ""
                                    }
                                  >
                                    {convertHTMLEntity(value.name)}
                                  </p>
                                    <input
                                      type="checkbox"
                                      id={`radioL1button${k}`}
                                      onClick={(e) =>
                                        handleL1Selection(e, value)
                                      }
                                      checked={chechkedSelectedValue(value)}
                                    />

                                    <span className="home-checkmark"></span>
                                  </label>
                                </li>
                              );
                            })
                          )}
                        </ul>
                      </div>
                    </form>
                  </div>
                  <div className={`col-md-6 p-0`}>
                    <>
                      <nav class="breadcrumb" aria-label="Breadcrumb">
                        <ul className="d-flex">
                          {breadcrumb &&
                            breadcrumb.map((val, indx) => {
                              return (
                                <li className="d-inline ml-3">
                                  <p>
                                    {indx === 0 ? (
                                      <span
                                        onClick={(e) =>
                                          breadcrumb.length - 1 === indx
                                            ? ""
                                            : handleBreadcrumb(e, val, indx)
                                        }
                                      >
                                        {val}
                                      </span>
                                    ) : (
                                      <>
                                        <img
                                          src={
                                            require("../../../assets/images/dashboard/breadcrumb-arrow.png")
                                              .default
                                          }
                                        />

                                        <span
                                          onClick={(e) =>
                                            breadcrumb.length - 1 === indx
                                              ? ""
                                              : handleBreadcrumb(e, val, indx)
                                          }
                                        >
                                          {val}
                                        </span>
                                      </>
                                    )}
                                  </p>
                                </li>
                              );
                            })}
                        </ul>
                      </nav>
                      <form>
                        <div className="dynamic-list">
                          {isL2Items === true && (
                            <ul className="menu-builder-l2">
                              {sortedData2 &&
                                sortedData2.map((value, k) => {
                                  return (
                                    <li className="custom-radio-btn menubuilder-radio-btn L2-level">
                                      
                                      <label
                                        for={`l2radiobutton${k}`}
                                        className="homescreen-selection-checkbox"
                                      >
                                        <p
                                        onClick={(e) =>
                                          value.children !== undefined &&
                                          value.children.length > 0 &&
                                          handleChildTextClick(e, value, k)
                                        }
                                        className={ `l2-name ${
                                          value.children !== undefined &&
                                          value.children.length > 0
                                            ? "underline-text"
                                            : value.child !== undefined &&
                                              value.child.length > 0
                                            ? "underline-text"
                                            : ""}`
                                        }
                                      >
                                        {convertHTMLEntity(value.name)}
                                      </p>{" "}
                                        <input
                                          type="checkbox"
                                          id={`l2radiobutton${k}`}
                                          onClick={(e) =>
                                            handleL2Selection(e, value)
                                          }
                                          checked={chechkedSelectedValue(value)}
                                        />
                                        <span className="home-checkmark"></span>
                                      </label>
                                    </li>
                                  );
                                })}
                            </ul>
                          )}
                        </div>
                      </form>
                    </>
                    {/* )} */}
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-center justify-content-center no-page ">
                <div>
                  {
                    from==="Pages"?
                  
                  <img
                    className="img-fluid"
                    src={
                      require("../../../assets/images/dashboard/no-page.png")
                        .default
                    }
                  />:<img
                  src={
                    require("../../../assets/images/dashboard/no-category.png")
                      .default
                  }
                />}
                  <p>No {from==="Pages"?"pages":"categories"} to select yet</p>
                </div>
              </div>
              )}
            </div>
          </div>
        </ModalBody>

        <ModalFooter className="menu-build-bottom">
          {/* <button
            className={`menu-cancel`}
            onClick={(e) => handleMobilViewBack(e)}
          >
            Back
          </button> */}
          <button
            className={`menu-cancel`}
            onClick={(e) => handleItemTypePopUp(e, "cancel")}
          >
            Cancel
          </button>
          <div className="d-flex justify-content-between align-items-center">
            {/* {(isItemSelection === true ||
              (isAllSelection === true &&
                showL2Data === true)) && (
              <h5 className="d-none d-md-block item-selection-menu">
                1 item selected
              </h5>
            )} */}
            <button
              type="submit"
              className={`menu-build`}
              // disabled={isDisableForDoneBtn}
              onClick={(e) => handleItemTypePopUp(e, "add")}
              disabled={
                itemData === null || itemData.length === 0 || !itemData
                  ? true
                  : false
              }
            >
              Done
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export const productsLayout = (
  value,
  shape,
  openLaypoutPopUp,
  handleLayoutPopUp,
  handleLayoutChange,
  from
) => {
  return (
    <div className="">
      <Modal
        isOpen={openLaypoutPopUp}
        toggle={(e) => handleLayoutPopUp(e, "cancel")}
        className="selectcategorypopup modal-dialog-centered"
      >
        <ModalHeader toggle={(e) => handleLayoutPopUp(e, "cancel")}>
          <h2>{from} Layout</h2>
        </ModalHeader>
        <ModalBody>
          <div className="category-layouts">
            <h4>4:3 Layout</h4>
            <div className="cat-layout-section">
              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/pro1.png")
                      .default
                  }
                />
                <label for="0" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="0"
                    // value="4:3-image-bottom-text"
                    checked={
                      value.name === "product_n*2" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "4:3"
                    }
                    disabled={shape === "circular"}
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "product_n*2",
                        "left",
                        "outside_bottom",
                        "4:3"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/pro2.png")
                      .default
                  }
                />
                <label for="1" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="1"
                    // value="4:3-image-right-text"
                    checked={
                      value.name === "product_n*1" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_right" &&
                      value.image_aspect_ratio === "4:3"
                    }
                    disabled={shape === "circular"}
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "product_n*1",
                        "left",
                        "outside_right",
                        "4:3"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/pro3.png")
                      .default
                  }
                />
                <label for="2" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="2"
                    // value="4:3-image-bottom-v2-text"
                    checked={
                      value.name === "product_3" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "4:3"
                    }
                    disabled={shape === "circular"}
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "product_3",
                        "left",
                        "outside_bottom",
                        "4:3"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/pro4.png")
                      .default
                  }
                />
                <label for="3" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="3"
                    // value="4:3-product-image-slider"
                    checked={
                      value.name === "product_1*n" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "4:3"
                    }
                    disabled={shape === "circular"}
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "product_1*n",
                        "left",
                        "outside_bottom",
                        "4:3"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>
            </div>

            <h4>1:1 Layout</h4>
            <div className="cat-layout-section">
              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/pro5.png")
                      .default
                  }
                />
                <label for="4" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="4"
                    // value="1:1-image-bottom-text"
                    checked={
                      value.name === "product_n*2" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "1:1"
                    }
                    // disabled={
                    //   homescreenData[blockIndx].style
                    //     .featured_product_shape === "circular"
                    // }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "product_n*2",
                        "left",
                        "outside_bottom",
                        "1:1"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/pro6.png")
                      .default
                  }
                />
                <label for="5" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="5"
                    // value="1:1-image-right-text"
                    checked={
                      value.name === "product_n*1" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_right" &&
                      value.image_aspect_ratio === "1:1"
                    }
                    // disabled={
                    //   homescreenData[blockIndx].style
                    //     .featured_product_shape === "circular"
                    // }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "product_n*1",
                        "left",
                        "outside_right",
                        "1:1"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/pro7.png")
                      .default
                  }
                />
                <label for="6" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="6"
                    // value="1:1-image-bottom-v2-text"
                    checked={
                      value.name === "product_3" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "1:1"
                    }
                    // disabled={
                    //   homescreenData[blockIndx].style
                    //     .featured_product_shape === "circular"
                    // }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "product_3",
                        "left",
                        "outside_bottom",
                        "1:1"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/pro8.png")
                      .default
                  }
                />
                <label for="7" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="7"
                    // value="1:1-product-image-slider"
                    checked={
                      value.name === "product_1*n" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "1:1"
                    }
                    // disabled={
                    //   homescreenData[blockIndx].style
                    //     .featured_product_shape === "circular"
                    // }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "product_1*n",
                        "left",
                        "outside_bottom",
                        "1:1"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>
            </div>

            <h4>3:4 Layout</h4>
            <div className="cat-layout-section">
              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/pro9.png")
                      .default
                  }
                />
                <label for="8" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="8"
                    // value="3:4-image-bottom-text"
                    checked={
                      value.name === "product_n*2" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "3:4"
                    }
                    disabled={shape === "circular"}
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "product_n*2",
                        "left",
                        "outside_bottom",
                        "3:4"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/pro10.png")
                      .default
                  }
                />
                <label for="9" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="9"
                    // value="3:4-image-right-text"
                    checked={
                      value.name === "product_n*1" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_right" &&
                      value.image_aspect_ratio === "3:4"
                    }
                    disabled={shape === "circular"}
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "product_n*1",
                        "left",
                        "outside_right",
                        "3:4"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/pro11.png")
                      .default
                  }
                />
                <label for="10" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="10"
                    // value="3:4-image-bottom-v2-text"
                    checked={
                      value.name === "product_3" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "3:4"
                    }
                    disabled={shape === "circular"}
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "product_3",
                        "left",
                        "outside_bottom",
                        "3:4"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/pro12.png")
                      .default
                  }
                />
                <label for="11" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="11"
                    // value="3:4-product-image-slider"
                    checked={
                      value.name === "product_1*n" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "3:4"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "product_1*n",
                        "left",
                        "outside_bottom",
                        "3:4"
                      )
                    }
                    disabled={shape === "circular"}
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={(e) => handleLayoutPopUp(e, "cancel")}>
            CANCEL
          </Button>{" "}
          <Button onClick={(e) => handleLayoutPopUp(e, "add")}>DONE</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export const blogsLayout = (
  value,
  shape,
  openLaypoutPopUp,
  handleLayoutPopUp,
  handleLayoutChange,
  from
) => {
  return (
    <div className="">
      <Modal
        isOpen={openLaypoutPopUp}
        toggle={(e) => handleLayoutPopUp(e, "cancel")}
        className="selectcategorypopup modal-dialog-centered"
      >
        <ModalHeader toggle={(e) => handleLayoutPopUp(e, "cancel")}>
          <h2>{from} Layout</h2>
        </ModalHeader>
        <ModalBody>
          <div className="category-layouts">
            <h4>4:3 Layout</h4>
            <div className="cat-layout-section">
              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-layout1.png")
                      .default
                  }
                />
                <label for="0" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="0"
                    // value="4:3-image-bottom-text"
                    disabled={shape === "circular"}
                    checked={
                      value.name === "blog_n*2" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "4:3"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "blog_n*2",
                        "left",
                        "outside_bottom",
                        "4:3"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-layout2.png")
                      .default
                  }
                />
                <label for="1" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="1"
                    // value="4:3-image-right-text"
                    disabled={shape === "circular"}
                    checked={
                      value.name === "blog_n*1" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_right" &&
                      value.image_aspect_ratio === "4:3"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "blog_n*1",
                        "left",
                        "outside_right",
                        "4:3"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-layout3.png")
                      .default
                  }
                />
                <label for="2" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="2"
                    // value="4:3-image-left-text"
                    disabled={shape === "circular"}
                    checked={
                      value.name === "blog_n*1" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_left" &&
                      value.image_aspect_ratio === "4:3"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "blog_n*1",
                        "left",
                        "outside_left",
                        "4:3"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-layout4.png")
                      .default
                  }
                />
                <label for="3" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="3"
                    // value="4:3-blog-image-slider"
                    disabled={shape === "circular"}
                    checked={
                      value.name === "blog_1*n" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "4:3"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "blog_1*n",
                        "left",
                        "outside_bottom",
                        "4:3"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>
            </div>

            <h4>1:1 Layout</h4>
            <div className="cat-layout-section">
              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-layout5.png")
                      .default
                  }
                />
                <label for="4" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="4"
                    // value="1:1-image-bottom-text"
                    checked={
                      value.name === "blog_n*2" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "1:1"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "blog_n*2",
                        "left",
                        "outside_bottom",
                        "1:1"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-layout6.png")
                      .default
                  }
                />
                <label for="5" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="5"
                    // value="1:1-image-right-text"
                    checked={
                      value.name === "blog_n*1" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_right" &&
                      value.image_aspect_ratio === "1:1"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "blog_n*1",
                        "left",
                        "outside_right",
                        "1:1"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-layout7.png")
                      .default
                  }
                />
                <label for="6" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="6"
                    // value="1:1-image-left-text"
                    checked={
                      value.name === "blog_n*1" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_left" &&
                      value.image_aspect_ratio === "1:1"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "blog_n*1",
                        "left",
                        "outside_left",
                        "1:1"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-layout8.png")
                      .default
                  }
                />
                <label for="7" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="7"
                    // value="1:1-blog-image-slider"
                    checked={
                      value.name === "blog_1*n" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "1:1"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "blog_1*n",
                        "left",
                        "outside_bottom",
                        "1:1"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>
            </div>

            <h4>3:4 Layout</h4>
            <div className="cat-layout-section">
              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-layout9.png")
                      .default
                  }
                />
                <label for="8" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="8"
                    // value="3:4-image-bottom-text"
                    disabled={shape === "circular"}
                    checked={
                      value.name === "blog_n*2" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "3:4"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "blog_n*2",
                        "left",
                        "outside_bottom",
                        "3:4"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-layout10.png")
                      .default
                  }
                />
                <label for="9" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="9"
                    // value="3:4-image-right-text"
                    disabled={shape === "circular"}
                    checked={
                      value.name === "blog_n*1" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_right" &&
                      value.image_aspect_ratio === "3:4"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "blog_n*1",
                        "left",
                        "outside_right",
                        "3:4"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-layout11.png")
                      .default
                  }
                />
                <label for="10" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="10"
                    // value="3:4-image-left-text"
                    disabled={shape === "circular"}
                    checked={
                      value.name === "blog_n*1" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_left" &&
                      value.image_aspect_ratio === "3:4"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "blog_n*1",
                        "left",
                        "outside_left",
                        "3:4"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-layout12.png")
                      .default
                  }
                />
                <label for="11" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="11"
                    // value="3:4-blog-image-slider"
                    disabled={shape === "circular"}
                    checked={
                      value.name === "blog_1*n" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "3:4"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "blog_1*n",
                        "left",
                        "outside_bottom",
                        "3:4"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={(e) => handleLayoutPopUp(e, "cancel")}>
            CANCEL
          </Button>{" "}
          <Button onClick={(e) => handleLayoutPopUp(e, "add")}>DONE</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export const blogBannerLayout = (
  value,
  openBannerLayout,
  handleBannerPopUp,
  handleLayoutChange
) => {
  return (
    <div className="">
      <Modal
        isOpen={openBannerLayout}
        toggle={(e) => handleBannerPopUp(e, "cancel")}
        className="selectcategorypopup modal-dialog-centered"
      >
        <ModalHeader toggle={(e) => handleBannerPopUp(e, "cancel")}>
          <h2>Blog Banner</h2>
        </ModalHeader>
        <ModalBody>
          <div className="category-layouts">
            <h4>16:9 Banner</h4>
            <div className="cat-layout-section">
              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-banner1.png")
                      .default
                  }
                />
                <label for="0" class="w-100 h-100">
                  <input
                    type="radio"
                    name="bloglayout"
                    className="d-none"
                    id="0"
                    // value="16:9-text-on-bottom"
                    checked={
                      value.name === "" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "16:9"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "",
                        "left",
                        "outside_bottom",
                        "16:9"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-banner2.png")
                      .default
                  }
                />
                <label for="1" class="w-100 h-100">
                  <input
                    type="radio"
                    name="bloglayout"
                    className="d-none"
                    id="1"
                    // value="16:9-text-on-image"
                    checked={
                      value.name === "" &&
                      value.text_alignment === "left" &&
                      value.text_position === "inside_bottom" &&
                      value.image_aspect_ratio === "16:9"
                    }
                    onChange={(e) =>
                      handleLayoutChange(e, "", "left", "inside_bottom", "16:9")
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>
            </div>

            <h4>4:3 Banner</h4>
            <div className="cat-layout-section">
              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-banner3.png")
                      .default
                  }
                />
                <label for="2" class="w-100 h-100">
                  <input
                    type="radio"
                    name="bloglayout"
                    className="d-none"
                    id="2"
                    // value="4:3-text-on-bottom"
                    checked={
                      value.name === "" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "4:3"
                    }
                    onChange={(e) =>
                      handleLayoutChange(e, "", "left", "outside_bottom", "4:3")
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-banner4.png")
                      .default
                  }
                />
                <label for="3" class="w-100 h-100">
                  <input
                    type="radio"
                    name="bloglayout"
                    className="d-none"
                    id="3"
                    // value="4:3-text-on-image"
                    checked={
                      value.name === "" &&
                      value.text_alignment === "left" &&
                      value.text_position === "inside_bottom" &&
                      value.image_aspect_ratio === "4:3"
                    }
                    onChange={(e) =>
                      handleLayoutChange(e, "", "left", "inside_bottom", "4:3")
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>
            </div>

            <h4>1:1 Banner</h4>
            <div className="cat-layout-section">
              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-banner5.png")
                      .default
                  }
                />
                <label for="4" class="w-100 h-100">
                  <input
                    type="radio"
                    name="bloglayout"
                    className="d-none"
                    id="4"
                    value="1:1-text-on-bottom"
                    checked={
                      value.name === "" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "1:1"
                    }
                    onChange={(e) =>
                      handleLayoutChange(e, "", "left", "outside_bottom", "1:1")
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-banner6.png")
                      .default
                  }
                />
                <label for="5" class="w-100 h-100">
                  <input
                    type="radio"
                    name="bloglayout"
                    className="d-none"
                    id="5"
                    value="1:1-text-on-image"
                    checked={
                      value.name === "" &&
                      value.text_alignment === "left" &&
                      value.text_position === "inside_bottom" &&
                      value.image_aspect_ratio === "1:1"
                    }
                    onChange={(e) =>
                      handleLayoutChange(e, "", "left", "inside_bottom", "1:1")
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>
            </div>

            <h4>3:4 Banner</h4>
            <div className="cat-layout-section">
              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-banner7.png")
                      .default
                  }
                />
                <label for="7" class="w-100 h-100">
                  <input
                    type="radio"
                    name="bloglayout"
                    className="d-none"
                    id="7"
                    // value="3:4-text-on-bottom"
                    checked={
                      value.name === "" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "3:4"
                    }
                    onChange={(e) =>
                      handleLayoutChange(e, "", "left", "outside_bottom", "3:4")
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-banner8.png")
                      .default
                  }
                />
                <label for="8" class="w-100 h-100">
                  <input
                    type="radio"
                    name="bloglayout"
                    className="d-none"
                    id="8"
                    value="3:4-text-on-image"
                    checked={
                      value.name === "" &&
                      value.text_alignment === "left" &&
                      value.text_position === "inside_bottom" &&
                      value.image_aspect_ratio === "3:4"
                    }
                    onChange={(e) =>
                      handleLayoutChange(e, "", "left", "inside_bottom", "3:4")
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={(e) => handleBannerPopUp(e, "cancel")}>
            CANCEL
          </Button>{" "}
          <Button onClick={(e) => handleBannerPopUp(e, "add")}>DONE</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export const itemSelectionPopUpForNonCMSWithoutChild = (
  isShowPopUp,
  handlePopUp,
  data,
  handleValueChange,
  value,
  from
) => {
  const convertHTMLEntity = (text) => {
    const span = document.createElement("span");

    return text.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
      span.innerHTML = entity;
      return span.innerText;
    });
  };
  const chechkedSelectedValue = (ids) => {
    let isCheck = 0;
    value &&
      value.map((id) => {
        if (id === ids.toString()) {
          isCheck++;
        }
      });

    return isCheck > 0;
  };
  return (
    <div className="">
      <Modal
        isOpen={isShowPopUp}
        toggle={(e) => handlePopUp(e, "cancel")}
        className="selectcategorypopup homescreen-full-width-popup modal-dialog-centered"
      >
        <ModalHeader toggle={(e) => handlePopUp(e, "cancel")}>
          <div className="d-flex justify-content-between align-items-center">
            <h2>Select {from}</h2>

            {value.length > 0 && (
              <h5 className=" item-selection-menu">
                {value.length} item selected
              </h5>
            )}
          </div>
        </ModalHeader>

        <ModalBody toggle={(e) => handlePopUp(e, "cancel")}>
          <div className="select-parent-page ">
            {data.length > 0 && data !== undefined && data ? (
              <ul>
                <li className="custom-radio-btn menubuilder-radio-btn L2-level">
                  
                  <label
                    for={`radioL1buttonALL`}
                    className="homescreen-selection-checkbox"
                  >
                    <p style={{color: chechkedSelectedValue("all")===true?"#3064F9":""}}>All</p>
                    <input
                      type="checkbox"
                      id={`radioL1buttonALL`}
                      onClick={(e) => handleValueChange(e, "all", "all")}
                      checked={chechkedSelectedValue("all")}
                    />

                    <span className="home-checkmark"></span>
                  </label>
                </li>
                {data &&
                  data.map((row, indx) => {
                    return (
                      <li className="custom-radio-btn menubuilder-radio-btn L2-level">
                        

                        <label
                          for={`radioL1button${indx}`}
                          className="homescreen-selection-checkbox"
                        >
                          <p>
                          {convertHTMLEntity(
                          row.title !== undefined
                            ? row.title.rendered
                            : row.name
                        )}
                          </p>
                          <input
                            type="checkbox"
                            id={`radioL1button${indx}`}
                            onClick={(e) =>
                              handleValueChange(e, row.id, "notall")
                            }
                            checked={chechkedSelectedValue(row.id)}
                          />

                          <span className="home-checkmark"></span>
                        </label>
                      </li>
                    );
                  })}
              </ul>
            ) : (
              <div className="d-flex align-items-center justify-content-center no-page ">
                <div>
                  {
                    from==="Pages"?
                  
                  <img
                    className="img-fluid"
                    src={
                      require("../../../assets/images/dashboard/no-page.png")
                        .default
                    }
                  />:<img
                  src={
                    require("../../../assets/images/dashboard/no-category.png")
                      .default
                  }
                />}
                  <p>No {from.toLowerCase()} to select yet</p>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={(e) => handlePopUp(e, "cancel")}>CANCEL</Button>
          <Button
            disabled={data.length === 0 || data === undefined}
            onClick={(e) => handlePopUp(e, "add")}
          >
            DONE
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export const multiSelectionWithChildForCMS = (
  handleItemTypePopUp,
  isItemTypePopUp,
  itemData,
  itemSpinner,
  breadcrumb,
  handleBreadcrumb,
  spinnerForL2,
  isL2Items,
  sortedData2,
  handleL1Selection,
  handleL2Selection,
  handleTextParentItemClick,
  handleChildTextClick,
  selectedArray,
  from
) => {
  const convertHTMLEntity = (text) => {
    const span = document.createElement("span");

    return text.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
      span.innerHTML = entity;
      return span.innerText;
    });
  };
  const chechkedSelectedValue = (value) => {
    let isCheck = 0;
    selectedArray &&
      selectedArray.map((id) => {
        if (id === value._id) {
          isCheck++;
        }
      });

    return isCheck > 0;
  };
  return (
    <div>
      <Modal
        toggle={(e) => handleItemTypePopUp(e, "cancel")}
        isOpen={isItemTypePopUp}
        className="category-popup homescreen-select-category modal-dialog-centered"
        style={{ maxWidth: "1280px", minHeight: "660px" }}
        centered
      >
        <ModalHeader
          toggle={(e) => handleItemTypePopUp(e, "cancel")}
          className="menubuilder-modal-header"
        >
          <div className="d-flex justify-content-between align-items-center">
            {from}
            {selectedArray.length > 0 && (
              <h5 className=" item-selection-menu">
                {selectedArray.length} item selected
              </h5>
            )}
          </div>
        </ModalHeader>
        <ModalBody className="menu-modal-body">
          <div className="row">
            <div className="col-md-12 ipad-col-big ipad-col-red2">
              {itemData !== null && itemData.length > 0 && itemData ? (
                <div className="row">
                  <div className={`col-md-6 p-0`}>
                    <form>
                      <div className="menu-option-list">
                        <ul>
                          {itemSpinner === true ? (
                            <Spinner animation="border" variant="primary" />
                          ) : (
                            itemData.map((value, k) => {
                              return (
                                <li className="custom-radio-btn menubuilder-radio-btn L2-level">
                                  <span
                                    onClick={(e) =>
                                      value.child !== undefined &&
                                      value.child.length > 0 &&
                                      handleTextParentItemClick(e, value, k)
                                    }
                                    className={
                                      value.child !== undefined &&
                                      value.child.length > 0
                                        ? "underline-text"
                                        : value.child !== undefined &&
                                          value.child.length > 0
                                        ? "underline-text"
                                        : ""
                                    }
                                  >
                                    {convertHTMLEntity(
                                      value.title !== undefined
                                        ? value.title
                                        : value.name
                                    )}
                                  </span>
                                  <label
                                    for={`radioL1button${k}`}
                                    className="homescreen-selection-checkbox"
                                  >
                                    <input
                                      type="checkbox"
                                      id={`radioL1button${k}`}
                                      onClick={(e) =>
                                        handleL1Selection(e, value)
                                      }
                                      checked={chechkedSelectedValue(value)}
                                    />

                                    <span className="home-checkmark"></span>
                                  </label>
                                </li>
                              );
                            })
                          )}
                        </ul>
                      </div>
                    </form>
                  </div>
                  <div className={`col-md-6 p-0`}>
                    <>
                      <nav class="breadcrumb" aria-label="Breadcrumb">
                        <ul className="d-flex">
                          {breadcrumb &&
                            breadcrumb.map((val, indx) => {
                              return (
                                <li className="d-inline ml-3">
                                  <p>
                                    {indx === 0 ? (
                                      <span
                                        onClick={(e) =>
                                          breadcrumb.length - 1 === indx
                                            ? ""
                                            : handleBreadcrumb(e, val, indx)
                                        }
                                      >
                                        {val}
                                      </span>
                                    ) : (
                                      <>
                                        <img
                                          src={
                                            require("../../../assets/images/dashboard/breadcrumb-arrow.png")
                                              .default
                                          }
                                        />

                                        <span
                                          onClick={(e) =>
                                            breadcrumb.length - 1 === indx
                                              ? ""
                                              : handleBreadcrumb(e, val, indx)
                                          }
                                        >
                                          {val}
                                        </span>
                                      </>
                                    )}
                                  </p>
                                </li>
                              );
                            })}
                        </ul>
                      </nav>
                      <form>
                        <div className="dynamic-list">
                          {isL2Items === true && (
                            <ul className="menu-builder-l2">
                              {sortedData2 &&
                                sortedData2.map((value, k) => {
                                  return (
                                    <li className="custom-radio-btn menubuilder-radio-btn L2-level">
                                      <span
                                        onClick={(e) =>
                                          value.child !== undefined &&
                                          value.child.length > 0 &&
                                          handleChildTextClick(e, value, k)
                                        }
                                        className={
                                          value.child !== undefined &&
                                          value.child.length > 0
                                            ? "underline-text"
                                            : value.child !== undefined &&
                                              value.child.length > 0
                                            ? "underline-text"
                                            : ""
                                        }
                                      >
                                        {convertHTMLEntity(
                                          value.title !== undefined
                                            ? value.title
                                            : value.name
                                        )}
                                      </span>{" "}
                                      <label
                                        for={`l2radiobutton${k}`}
                                        className="homescreen-selection-checkbox"
                                      >
                                        <input
                                          type="checkbox"
                                          id={`l2radiobutton${k}`}
                                          onClick={(e) =>
                                            handleL2Selection(e, value)
                                          }
                                          checked={chechkedSelectedValue(value)}
                                        />
                                        <span className="home-checkmark"></span>
                                      </label>
                                    </li>
                                  );
                                })}
                            </ul>
                          )}
                        </div>
                      </form>
                    </>
                    {/* )} */}
                  </div>
                </div>
              ) : (

<div className="d-flex align-items-center justify-content-center no-page ">
                <div>
                  {
                    from==="Pages"?
                  
                  <img
                    className="img-fluid"
                    src={
                      require("../../../assets/images/dashboard/no-page.png")
                        .default
                    }
                  />:<img
                  src={
                    require("../../../assets/images/dashboard/no-category.png")
                      .default
                  }
                />}
                  <p>No {from==="Pages"?"pages":"categories"} to select yet</p>
                </div>
              </div>

       
              )}
            </div>
          </div>
        </ModalBody>

        <ModalFooter className="menu-build-bottom">
          {/* <button
            className={`menu-cancel`}
            onClick={(e) => handleMobilViewBack(e)}
          >
            Back
          </button> */}
          <button
            className={`menu-cancel`}
            onClick={(e) => handleItemTypePopUp(e, "cancel")}
          >
            Cancel
          </button>
          <div className="d-flex justify-content-between align-items-center">
            {/* {(isItemSelection === true ||
              (isAllSelection === true &&
                showL2Data === true)) && (
              <h5 className="d-none d-md-block item-selection-menu">
                1 item selected
              </h5>
            )} */}
            <button
              disabled={
                itemData === null || itemData.length === 0 || !itemData
                  ? true
                  : false
              }
              type="submit"
              className={`menu-build`}
              // disabled={isDisableForDoneBtn}
              onClick={(e) => handleItemTypePopUp(e, "add")}
            >
              Done
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export const pageSelectionForCMS = (
  isParentpopup,
  handleParentPop,
  allPage,
  handleParentChange,
  parentId,
  pageID,
  type
) => {
  const handleDash = (level) => {
    switch (level) {
      case 0:
        return <span></span>;
      case 1:
        return <span> - </span>;
      case 2:
        return <span> - - </span>;
      case 3:
        return <span> - - - </span>;
      case 4:
        return <span> - - - - </span>;
      case 5:
        return <span> - - - - - </span>;
      case 6:
        return <span> - - - - - - </span>;
      case 1:
        return <span> - - - - - - - </span>;
      case 7:
        return <span> - - - - - - - - </span>;
      case 8:
        return <span> - - - - - - - - - </span>;
      case 9:
        return <span> - - - - - - - - - - </span>;
    }
  };

  const childParentList = (newArr) => {
    return newArr.map((eachPage, indx) => {
      return (
        ((eachPage.level < 10 && pageID !== eachPage._id) ||
          eachPage.level === undefined) &&
        eachPage.deleted_at === null && (
          <li class="parent-radio-btn page-list" key={eachPage._id}>
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefaultD"
              id={`flexRadioDefault${indx}`}
              checked={eachPage._id === parentId}
              onChange={(e) => handleParentChange(e, eachPage)}
            />
            <label
              class="form-check-label form-menu-label"
              for={`flexRadioDefault${indx}`}
            >
              <div class="card-num menu-name">
                <p>
                  {handleDash(eachPage.level)}
                  {type === "Category" || type === "Tags"
                    ? eachPage.name
                    : eachPage.title}
                </p>
              </div>
            </label>
          </li>
        )
      );
    });
  };

  let newArr = [];

  function recurs(arr, check) {
    return arr.map((eachPage, indx) => {
      newArr.push(eachPage);

      if (eachPage.child !== undefined && eachPage.child.length > 0) {
        // if parent has childrens
        recurs(eachPage.child, "child");
      }

      return (
        check === "parent" &&
        indx === allPage.length - 1 &&
        childParentList(newArr)
      );
    });
  }

  return (
    <div className="">
      <Modal
        isOpen={isParentpopup}
        toggle={(e) => handleParentPop(e, "cancel")}
        className="selectcategorypopup modal-dialog-centered"
      >
        <ModalHeader toggle={(e) => handleParentPop(e, "cancel")}>
          <h2>Select {type}</h2>
        </ModalHeader>

        <ModalBody toggle={(e) => handleParentPop(e, "cancel")}>
          <div className="select-parent-page ">
            {allPage.length > 0 && allPage !== undefined && allPage ? (
              <ul>{recurs(allPage, "parent")}</ul>
            ) : (
              <div className="d-flex align-items-center justify-content-center no-page ">
                <div>
                  <img
                    className="img-fluid"
                    src={
                      require("../../../assets/images/dashboard/no-page.png")
                        .default
                    }
                  />
                  <p>No {type} to select yet</p>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={(e) => handleParentPop(e, "cancel")}>CANCEL</Button>
          <Button
            onClick={(e) => handleParentPop(e, "add")}
            disabled={
              allPage === null || allPage.length === 0 || !allPage
                ? true
                : false
            }
          >
            DONE
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export const categoryLayoutPopUp = (
  value,
  shape,
  openLaypoutPopUp,
  handleLayoutPopUp,
  handleLayoutChange,
  from
) => {
  return (
    <div className="">
      <Modal
        isOpen={openLaypoutPopUp}
        toggle={(e) => handleLayoutPopUp(e, "cancel")}
        className="selectcategorypopup modal-dialog-centered"
      >
        <ModalHeader toggle={(e) => handleLayoutPopUp(e, "cancel")}>
          <h2>{from} Layout</h2>
        </ModalHeader>
        <ModalBody>
          <div className="category-layouts">
            <h4>4:3 Layout</h4>
            <div className="cat-layout-section">
              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/cat1.png")
                      .default
                  }
                />
                <label for="0" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="0"
                    // value="4:3-image-bottom-text"
                    disabled={shape === "circular"}
                    checked={
                      value.name === "category_n*2" &&
                      value.text_alignment === "center" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "4:3"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "category_n*2",
                        "center",
                        "outside_bottom",
                        "4:3"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/cat2.png")
                      .default
                  }
                />
                <label for="1" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="1"
                    // value="4:3-image-center-text"
                    disabled={shape === "circular"}
                    checked={
                      value.name === "category_n*2" &&
                      value.text_alignment === "center" &&
                      value.text_position === "inside_center" &&
                      value.image_aspect_ratio === "4:3"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "category_n*2",
                        "center",
                        "inside_center",
                        "4:3"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/cat3.png")
                      .default
                  }
                />
                <label for="2" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="2"
                    // value="4:3-image-right-text"
                    disabled={shape === "circular"}
                    checked={
                      value.name === "category_n*1" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_right" &&
                      value.image_aspect_ratio === "4:3"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "category_n*1",
                        "left",
                        "outside_right",
                        "4:3"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/cat4.png")
                      .default
                  }
                />
                <label for="3" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="3"
                    // value="4:3-image-left-text"
                    disabled={shape === "circular"}
                    checked={
                      value.name === "category_n*1" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_left" &&
                      value.image_aspect_ratio === "4:3"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "category_n*1",
                        "left",
                        "outside_left",
                        "4:3"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>
            </div>

            <h4>1:1 Layout</h4>
            <div className="cat-layout-section">
              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/cat9.png")
                      .default
                  }
                />
                <label for="4" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="4"
                    // value="1:1-image-bottom-text"
                    checked={
                      value.name === "category_n*2" &&
                      value.text_alignment === "center" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "1:1"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "category_n*2",
                        "center",
                        "outside_bottom",
                        "1:1"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/cat10.png")
                      .default
                  }
                />
                <label for="5" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="5"
                    // value="1:1-image-center-text"
                    checked={
                      value.name === "category_n*2" &&
                      value.text_alignment === "center" &&
                      value.text_position === "inside_center" &&
                      value.image_aspect_ratio === "1:1"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "category_n*2",
                        "center",
                        "inside_center",
                        "1:1"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/cat11.png")
                      .default
                  }
                />
                <label for="6" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="6"
                    // value="1:1-image-right-text"
                    checked={
                      value.name === "category_n*1" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_right" &&
                      value.image_aspect_ratio === "1:1"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "category_n*1",
                        "left",
                        "outside_right",
                        "1:1"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/cat12.png")
                      .default
                  }
                />
                <label for="7" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="7"
                    // value="1:1-image-left-text"
                    checked={
                      value.name === "category_n*1" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_left" &&
                      value.image_aspect_ratio === "1:1"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "category_n*1",
                        "left",
                        "outside_left",
                        "1:1"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>
            </div>

            <h4>3:4 Layout</h4>
            <div className="cat-layout-section">
              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/cat5.png")
                      .default
                  }
                />
                <label for="8" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="8"
                    // value="3:4-image-bottom-text"
                    disabled={shape === "circular"}
                    checked={
                      value.name === "category_n*2" &&
                      value.text_alignment === "center" &&
                      value.text_position === "outside_bottom" &&
                      value.image_aspect_ratio === "3:4"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "category_n*2",
                        "center",
                        "outside_bottom",
                        "3:4"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/cat6.png")
                      .default
                  }
                />
                <label for="9" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="9"
                    // value="3:4-image-center-text"
                    disabled={shape === "circular"}
                    checked={
                      value.name === "category_n*2" &&
                      value.text_alignment === "center" &&
                      value.text_position === "inside_center" &&
                      value.image_aspect_ratio === "3:4"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "category_n*2",
                        "center",
                        "inside_center",
                        "3:4"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/cat7.png")
                      .default
                  }
                />
                <label for="10" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="10"
                    // value="3:4-image-right-text"
                    disabled={shape === "circular"}
                    checked={
                      value.name === "category_n*1" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_right" &&
                      value.image_aspect_ratio === "3:4"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "category_n*1",
                        "left",
                        "outside_right",
                        "3:4"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>

              <div className="category-library">
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/cat8.png")
                      .default
                  }
                />
                <label for="11" class="w-100 h-100">
                  <input
                    type="radio"
                    name="categorylayouts"
                    className="d-none"
                    id="11"
                    // value="3:4-image-left-text"
                    disabled={shape === "circular"}
                    checked={
                      value.name === "category_n*1" &&
                      value.text_alignment === "left" &&
                      value.text_position === "outside_left" &&
                      value.image_aspect_ratio === "3:4"
                    }
                    onChange={(e) =>
                      handleLayoutChange(
                        e,
                        "category_n*1",
                        "left",
                        "outside_left",
                        "3:4"
                      )
                    }
                  />
                  <span className="categoryactive"></span>
                </label>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={(e) => handleLayoutPopUp(e, "cancel")}>
            CANCEL
          </Button>{" "}
          <Button onClick={(e) => handleLayoutPopUp(e, "add")}>DONE</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
