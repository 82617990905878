import React from 'react'; 
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import {Errorsvg} from './errorsvg.jsx';
import styles from "./logs.module.css";
import moment from 'moment';

export const Error = (logdetail, setlogdetail,errorLogs=null) => {
  const parts = errorLogs._source.response.error.split(/:(?!\/\/)/);
  return (
    <>
      <Modal isOpen={logdetail} toggle={e => setlogdetail(!logdetail)} style={{maxWidth:"1280px",maxHeight:"660px"}}  centered>
        
        <ModalHeader className={styles.topsection}>
          <div className="d-flex justify-content-between alf">
          <div className="heading-container alf">
            {/* <div className={`${styles.errorbox} modal-error-box`}>
              {Errorsvg()}
            </div> */}
            {errorLogs._source.app_device_type === "Ios" ?<div className='log-popup-tech'><img src={require("../../assets/images/dashboard/apple-logs.png").default } /> <img className='log-error-exclamation' src={require("../../assets/images/dashboard/logs/error.png").default } />
                                 </div> : <div className='log-popup-tech'><img src={require("../../assets/images/dashboard/android-logs.png").default } /> <img className='log-error-exclamation' src={require("../../assets/images/dashboard/logs/error.png").default } /></div>}
            {/* {errorLogs._source.app_device_type === "Ios" ? <img src={require("../../assets/images/dashboard/logs-popup-ios.png").default } />  : <img src={require("../../assets/images/dashboard/logs-popup-headimg.png").default } />} */}
            <div className={`${styles.boxtext} modal-heading-text`}>
              <span className='log-mod-errorhead'>{errorLogs._source.response.message}</span>
              <span className='log-mod-title'>{moment(errorLogs._source.timestamp).format('D MMMM YYYY, HH:mm')}
              {/* , {moment(errorLogs._source.timestamp).format('h:mm:ss a')} */}
              </span>
              {/* <span style={{fontSize: "13px", color: "#7782A1"}}>{errorLogs._source.app_device_type}</span> */}
            </div>
            </div>
            <span className="closebutton" onClick={e => setlogdetail(!logdetail)}>&times;</span>
          </div>
        </ModalHeader>
        
        <div className="text-center mb-md-3 mb-3">
        <ModalBody className={`${styles.modaltext} modal-data`}>
          <p>
          {errorLogs._source.response.error === undefined ? 
          <span>{errorLogs._source.response.message}</span> : 
          <span 
          // className={'log-error-key'}
          
          >{errorLogs._source.response.error}</span>}
          </p> 
        </ModalBody>
        </div>
        <div className="text-center mb-md-3 mb-3">
        </div>
      </Modal>
    </>
  );
};

export const ConnectivityReports = (logdetail, setlogdetail,logsData,dataId,sortData) => {
  
  return (
    <>
      <Modal isOpen={logdetail} toggle={e => setlogdetail(!logdetail)} style={{maxWidth:"1006px",maxHeight:"592px"}}  centered>
        
        <ModalHeader className={styles.topsection}>
          <div className="border-bottom d-flex justify-content-between pb-4 alf">
          <div className="heading-container alf">
            <div className={`${styles.errorbox} modal-error-box`}>
              {Errorsvg()}
            </div>
            <div className={`${styles.boxtext} modal-heading-text`}>
              <span style={{fontSize: "16px", color: "#1B2952"}}>{logsData.hits[dataId]._source.timestamp}</span>
              <span style={{fontSize: "16px", color: "#1B2952"}}>{sortData[0].count} passed</span>
            </div>
            </div>
            <span className="closebutton" onClick={e => setlogdetail(!logdetail)}>&times;</span>
          </div>
        </ModalHeader>
        
        <div className="text-center mb-md-3 mb-3">
        <ModalBody style={{textAlign:"left"}} className={`${styles.modaltext} modal-data`}>
          <pre>{JSON.stringify(logsData.hits[dataId]._source.response, undefined, 2)}</pre>
        </ModalBody>
        </div>
        <div className="text-center d-none mb-md-3 mb-3">
        </div>
      </Modal>
    </>
  );
};