import React from "react";
import Header from "./../Header";
import Leftpanel from "./../Leftpanel";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import {Modal, ModalHeader, ModalBody } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import {
    PricingLifetimepricing,
    Makepayment,
  } from "../../Api/Dashboard/DashboardApi";
import {
    GetpackageAll,
    wooChangePlan,
    Makestrippayment,
    SendpaymentinfotoserverApi,
    DowngradeApi
  } from "../../Api/Dashboard/DashboardApi";
  import { paddleChangePlan } from "../../Api/Addons/Addonsapi";
import moment from "moment";
import { InnerFoot } from "./../Foot";
import Slider from "./.././Reusable/Slider";
import styles from "./pricing.css";
import Accordion from 'react-bootstrap/Accordion';
import flow from "@prosperstack/flow";
import Tooltipicon from "../../Components/commonIcons/Tooltip.js";

export default class CustomAppPricing extends React.PureComponent {
    constructor(props) {
        super(props);
        this.myRef = React.createRef(); // Create a ref object
        this.state  = {
  	checkedtab:'yearly',
    checkedmobtab:'preview',
    appid: this.props.match.params.appid,
    monthlydata: [],
    savemonthlydata:[],
    newmonthlydata: [],
    yearlydata: [],
    saveyearlydata:[],
    newyearlydata: [],
    lifetimedata: [],
    savelifetimedata:[],
    newlifetimedata: [],
    one_time: [],
    savemonthlydatalength:0,
    saveyearlydatalength:0,
    savelifetimedatalength:0,
    loader_remove: "d-none",
    loaderimage_remove: require("../../assets/images/signup/Loader_GIF.gif").default,
    submitval_confirmpop: "plan-upgradebtn",
    spinner: true,
    yearlyspinner:false,
    lifetimespinner:false,
    customer_type: "",
    downgradeplan:false,
    currentplanname:"",
    currentplanduration:"",
    currentplanrpice:"",
    endcycledata:"",
    paddlecurreny:"",
    currency:"$",
    downgrade_at_term_end:1,
    is_android_rebuild_required:0,
    is_ios_rebuild_required:0,
    is_deal_plan: null,
    appuser_role_id:localStorage.getItem('appuser_role_id'),
    package_duration:"",
    current_package_status: null,
    dealsData: [],
    count:0,
    openReactivatePopUp:false,
    reactiveSubmitText:"Confirm",
    reactiveLoader:"d-none",
    stripe_customer_id:"",
    subscription_id:"",
    subscription_plan:""


};
}

  componentDidMount() {
    localStorage.setItem("AgencyForThankyouPage","");
    window.scrollTo(0, 0);
    document.title = "Custom App plans |  AppMySite";
    this.stopmounting = true;
   
      const params = {
        appid: this.props.match.params.appid,
        tech : 3,
      };

      const Paddle = window.Paddle;
      localStorage.removeItem('reload');

        if (
        window.location.host.indexOf("localhost") > -1 ||
        window.location.host.indexOf("test.app") > -1 ||
        window.location.host.indexOf("stage.app") > -1 ||
        window.location.host.indexOf("dev.app") > -1
        ) {
        window.Paddle.Environment.set("sandbox");
        }
        Paddle.Setup({
        vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID),
        });
        
      GetpackageAll(this, params, () => {
       
        localStorage.setItem("Appid", atob(this.props.match.params.appid));
        
        if(this.state.customer_billing_type === 3 && this.state.yearlydata !==''&& this.state.yearlydata !== undefined)
        {
            this.state.yearlydata.map((item, i) => 
            {
                window.Paddle.Product.Prices(parseInt(item.plan_code), (prices) =>
                this.settingPaddlePrices(i,prices,'year')
                );
            });
       }
       else{
        this.setState({newyearlydata:''});
        this.setState({newyearlydata:this.state.yearlydata});
       }

        if(this.state.customer_billing_type === 3 && this.state.monthlydata !==''&&this.state.monthlydata !== undefined)
        {
            this.state.monthlydata.map((item, i) => 
            {
                window.Paddle.Product.Prices(parseInt(item.plan_code), (prices) =>
                this.settingPaddlePrices(i,prices,'month')
                );
            });
        }
        else
        {
            this.setState({newmonthlydata:''});
            this.setState({newmonthlydata:this.state.monthlydata});

        }

        if(this.state.customer_billing_type === 3)
        {
            this.state.lifetimedata.map((item, i) => 
            {
                window.Paddle.Product.Prices(parseInt(item.plan_code), (prices) =>
                this.settingPaddlePrices(i,prices,'lifetime')
                );
            });
        }
        else{
            this.setState({newlifetimedata:''});
            this.setState({newlifetimedata:this.state.lifetimedata});
        }
       

       PricingLifetimepricing(
        this,
        () => 
        {
            this.state.dealsData.map((row, i) => {
                if (row.status === "active" && (row.total_app_count === "" || row.used_app_count < row.total_app_count)) 
                {
                  this.setState({count:1});
                }
            })
        },
      );

      
       
      });
     
      
      
  }

  

  paidsubscriptionpopup = (e) =>
  {
    this.setState({
      paidsubscriptiondetected: !this.state.paidsubscriptiondetected,
    });
  }

  handleReactivePopUp = (e,from) => {
    if(from==="cancel"){
        this.setState({
            openReactivatePopUp : !this.state.openReactivatePopUp  
        })
    }else{
    this.setState({
     reactiveLoader:"d-block",
     reactiveSubmitText:""
    });
    this.previewdowngrade(e,from);
    }

  }

  previewdowngrade = (e,from) => {
    this.setState({
        loader_remove: "d-block plan-upgradebtn",
        submitval_confirmpop: "d-none",
    });
    const sendparam = {
      app_id: atob(this.props.match.params.appid),
      total: this.state.one_time[0].amount,
      plan_array: [
        {
          zoho_plan_code: this.state.one_time[0].plan_code,
          type: 1,
          amount: this.state.one_time[0].amount,
          quantity: 1,
        },
      ],
      currency: "$",
      order_type: "downgrade",
      downgrade_at_term_end:this.state.downgrade_at_term_end === 0 ? 1 : 0,
    };
    const sendtostrip = {
      app_id: parseInt(atob(this.props.match.params.appid)),
      plan_array: [
        {
          price_id: this.state.one_time[0].plan_code,
          quantity: 1,
          type: 1,
        },
      ],
      payment_method_id: "downgrade",
      order_type: 2,
      deal_id: null,
      downgrade_at_term_end:this.state.downgrade_at_term_end === 0 ? 1 : 0,
    };
    const paddleParam = {
      workspace_id:
        localStorage.getItem("workspaceId") !== "" &&
        localStorage.getItem("workspaceId") !== "undefined" &&
        localStorage.getItem("workspaceId") !== undefined &&
        localStorage.getItem("workspaceId") !== null
          ? parseInt(localStorage.getItem("workspaceId"))
          : null,
      app_id:
        localStorage.getItem("workspaceId") !== "" &&
        localStorage.getItem("workspaceId") !== "undefined" &&
        localStorage.getItem("workspaceId") !== undefined &&
        localStorage.getItem("workspaceId") !== null
          ? null
          : parseInt(localStorage.getItem("Appid")),
      deal_id: null,
      order_type: 2,
      price_id: this.state.one_time[0].plan_code,
      quantity: 1,
      type: 1,
      downgrade_at_term_end:this.state.downgrade_at_term_end === 0 ? 1 : 0,
    };

    
         if(this.state.customer_billing_type === 2)
         {
                    Makestrippayment(sendtostrip, this ,"", () =>
                        {
                            if(this.state.code == 501 || this.state.code == 204)
                            {
                                this.setState({
                                    loader_remove: "d-none",
                                    submitval_confirmpop: "plan-upgradebtn",
                                    reactiveSubmitText:"Confirm",
                                    reactiveLoader:"d-none",
                                });
                                this.setState({
                                    downgradeuser: false,
                                });
                                this.paidsubscriptionpopup();
                            }
                        }
                    )
         }
         else if(this.state.customer_billing_type === 3)
         {
             paddleChangePlan(paddleParam, this, () =>
             {
          
                 if(this.state.code == 501 || this.state.code == 204)
                 {
                     this.setState({
                         loader_remove: "d-none",
                         submitval_confirmpop: "plan-upgradebtn",
                     });
                     this.setState({
                         downgradeuser: false,
                       });
                     this.paidsubscriptionpopup();
                 }
             }
             )
         }
         else
         {

             SendpaymentinfotoserverApi(sendparam, this, () => 
             {
                 if(this.state.code == 501 || this.state.code == 204)
                 {
                     this.setState({
                         loader_remove: "d-none",
                         submitval_confirmpop: "plan-upgradebtn",
                     });
                     this.setState({
                         downgradeuser: false,
                       });
                     this.paidsubscriptionpopup();
                 }
                 else
                 {
                     const params = {
                         app_id: parseInt(atob(this.props.match.params.appid)),
                         addon_array: [],
                         type: "free",
                         downgrade_at_term_end:this.state.downgrade_at_term_end === 0 ? 1 : 0,
                         };
                         DowngradeApi(params, this);
                 }
                 
             });
         }
 };


  //Button Color
  handlechecktab = (e) => 
  {
    this.setState({checkedtab: e});
  };

  handlecheckmoobtab = (e) => 
  {
    this.setState({checkedmobtab: e});
  };

  handleDeals = () => {
    if (this.state.appid) {
      this.props.history.push(`/app/apply-deal/${this.state.appid}`);
    } else {
      this.props.history.push("/deals");
    }
  };

  seemorefunction = () => {
    var element = document.getElementById("showfaq");
    element.classList.remove("hiddenfaq");
    document.getElementById("seemorelink").classList.add("d-none");
    document.getElementById("seelesslink").classList.remove("d-none");    
  }
  
  seelessfunction = () => 
  {
    var element = document.getElementById("showfaq");
    element.classList.add("hiddenfaq"); 
    document.getElementById("seemorelink").classList.remove("d-none");
    document.getElementById("seelesslink").classList.add("d-none");    
  }  

  removepopup = () => {
    if (this.state.appuser_role_id !== 3 && this.state.appuser_role_id !== 4) {
      this.setState({
        deluser: !this.state.deluser,
      });
    }
  };

 downgradepopupdeal = () => {
    if (this.state.appuser_role_id !== 3 && this.state.appuser_role_id !== 4) 
    {
      this.setState({
        downgradeuser: !this.state.downgradeuser,
      });
    }
  };

  dlink = (
    plan_code,
    recurring_price,
    package_name,
    interval_unit,
    id
  ) => {
    if (
      this.state.appuser_role_id !== 3 &&
      this.state.appuser_role_id !== 4
    ) {
      return (
        "/checkout/" +
        plan_code +
        "/" +
        btoa(recurring_price) +
        "/" +
        btoa(plan_code) +
        "/" +
        btoa(interval_unit) +
        "/" +
        btoa(id) +
        "/" +
        btoa(null) +
        "/" +
        "1"
      );
    }
  };

  handleWooCustomer = (e, customer_type, plan_code) => 
  {
    
      this.setState({
        loader_remove: "d-block plan-upgradebtn",
      submitval_confirmpop: "d-none",
      });
      this.setState({
        tempFilter: true,
        });
    if (customer_type === 4) 
    {
      const params = {
        price_id: plan_code,
        type: 1,
        app_id: parseInt(atob(this.state.appid)), //either this or workspace_id

        return_url:
          window.location.protocol + "//" + window.location.host + "/thank-you",
      };
      wooChangePlan(this, params);
    }
  };

  newcurrencyFormater = (number, country_name) => {
    let formatedNum = new Intl.NumberFormat(country_name).format(number);
    return formatedNum;
  };
  
  settingPaddlePrices = (i,prices,type) => {

    this.setState({currency:prices.price.gross.slice(0,1)});
    if(type == 'year' && this.state.saveyearlydatalength < this.state.yearlydata.length )
    {
        var numb = prices.price.gross.replace(',','');
        numb = numb.replace(/^\D+/g, '');

        var currency = (prices.price.gross.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        currency = currency.replace("US", " ");
        let intergervalue =  parseInt(this.newcurrencyFormater((parseFloat(parseFloat(numb)/12).toFixed(2)), "en-"+ prices.country).replace ( /[^\d.]/g, '' ));
        let decimalValue = prices.price.gross.toString().indexOf(".");
        let result = '.'+prices.price.gross.toString().substring(decimalValue+1);


        this.state.yearlydata[i].paddleprice = '<h4>' +currency+(intergervalue.toLocaleString("en-US"))+'<span class="pricing-decimal">'+result+'</span></h4>';
        this.state.saveyearlydata.push(this.state.yearlydata[i]);
        this.setState({saveyearlydatalength:this.state.saveyearlydatalength+1,paddlecurreny:currency});
        if(this.state.saveyearlydatalength == this.state.yearlydata.length)
        {
            var results1 = this.state.saveyearlydata;
            this.setState({newyearlydata:''});
            this.setState({newyearlydata:results1});
        }
    }

    if(type == 'month' && this.state.savemonthlydatalength < this.state.monthlydata.length )
    {
        var currency = (prices.price.gross.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        currency = currency.replace("US", " ");
        let decimalValue = prices.price.gross.toString().indexOf(".");
        let result = '.'+prices.price.gross.toString().substring(decimalValue+1);
        let intergervalue =  parseInt(this.newcurrencyFormater((prices.price.gross.replace( /[^\d.]/g, '' )), "en-"+ prices.country).replace ( /[^\d.]/g, '' ));
  
        this.state.monthlydata[i].paddleprice = `<h4>`+currency + (intergervalue.toLocaleString("en-US"))+`<span class='pricing-decimal'>`+result+`</span></h4>`;
        this.state.monthlydata[i].paddledecimalprice = result;

        this.state.savemonthlydata.push(this.state.monthlydata[i]);
        this.setState({savemonthlydatalength:this.state.savemonthlydatalength+1,paddlecurreny:currency});
        if(this.state.savemonthlydatalength == this.state.monthlydata.length)
            {
                var results = this.state.savemonthlydata;
            this.setState({newmonthlydata:''});
            this.setState({newmonthlydata:results});
            }

    }
    
    if(type == 'lifetime' && this.state.savelifetimedatalength < this.state.lifetimedata.length)
    {
        
        var currency = (prices.price.gross.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
      currency = currency.replace("US", " ");
      let decimalValue = prices.price.gross.toString().indexOf(".");
      let result = '.'+prices.price.gross.toString().substring(decimalValue+1);
      let intergervalue =  parseInt(this.newcurrencyFormater((prices.price.gross.replace( /[^\d.]/g, '' )), "en-"+ prices.country).replace ( /[^\d.]/g, '' ));
        
        this.state.lifetimedata[i].paddleprice = `<h4>`+currency + (intergervalue.toLocaleString("en-US"))+`<span class='pricing-decimal'>`+result+`</span></h4>`;
        this.state.savelifetimedata.push(this.state.lifetimedata[i]);
        this.setState({savelifetimedatalength:this.state.savelifetimedatalength+1,paddlecurreny:currency});
        if(this.state.savelifetimedatalength == this.state.lifetimedata.length)
        {
            var results2 = this.state.savelifetimedata;
            this.setState({newlifetimedata:''});
            this.setState({newlifetimedata:results2});
        }
    }
   
    
  };


  handleAccordianToggle = (e) => {

    let accItem = document.getElementsByClassName('accordionItem');
    let accHD = document.getElementsByClassName('accordionItemHeading allflex');


    let itemClass = e.target.parentElement.className;
    for (let i = 0; i < accItem.length; i++) {
        accItem[i].className = 'accordionItem close accordion-item';
        accItem[i].children[0].children[0].src = require('../../assets/images/pricing/faqplus.webp').default;
        accItem[i].children[0].children[0].setAttribute('data-src', require('../../assets/images/pricing/faqplus.webp').default);
    }
    if (itemClass == 'accordionItem close accordion-item') {
        e.target.parentElement.className = 'accordionItem open';
        e.target.children[0].src = require('../../assets/images/pricing/faqminus.webp').default;
        e.target.children[0].setAttribute('data-src', require('../../assets/images/pricing/faqminus.webp').default);
        
    }
    else
    {
        e.target.children[0].src = require('../../assets/images/pricing/faqplus.webp').default;
        e.target.children[0].setAttribute('data-src', require('../../assets/images/pricing/faqplus.webp').default);
    }

}
  

  render() {
    return (
        
      <>
      <Header customclass={"header-responsive-new"} />
      
    <div style={{minHeight:"100vh"}}>
    {this.state.spinner ?
        (
            <div className="newspinner">
            <svg xmlns="http://www.w3.org/2000/svg" 
      xlink="http://www.w3.org/1999/xlink" 
      style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
      width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
      <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
      <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
        style={{animationplaystate: "running", animationdelay: "0s"}}>
        </animateTransform>
      </circle>
      </g>
      <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
      <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
      <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
      style={{animationplaystate: "running", animationdelay: "0s"}}>
      </animateTransform>
      </circle>
      </g>
      <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
      <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
      <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
      style={{animationplaystate: "running", animationdelay: "0s"}}>

      </animateTransform>
      </circle>
      </g>
      <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
      <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
      <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
      style={{animationplaystate: "running", animationdelay: "0s"}}>

      </animateTransform>
      </circle>
      </g>
      </svg>
          </div>
          ) 
          : ((this.state.customer_billing_type !== 3) || (this.state.monthlydata !== '' && this.state.monthlydata !== undefined && this.state.savemonthlydatalength == this.state.monthlydata.length ) 
          || ((this.state.monthlydata === '' || this.state.monthlydata === undefined) &&this.state.yearlydata !== '' && this.state.yearlydata !== undefined && this.state.saveyearlydatalength == this.state.newyearlydata.length )
          || ((this.state.monthlydata === '' || this.state.monthlydata === undefined ) && (this.state.yearlydata === '' || this.state.yearlydata === undefined) && this.state.lifetimedata !== '' && this.state.lifetimedata !== undefined && this.state.savelifetimedatalength == this.state.lifetimedata.length )) ?  
          (
            <>
          <section className="blueback teampricing paddingtop160 paddingbottom100">
          <div className="container">
            {this.state.current_package_status === 0 && 
            this.state.is_deal_plan === 0 &&
            this.state.subscription_plan !=="paddle_preview" 
            && this.state.subscription_plan !=="agency_preview"
            && this.state.subscription_plan !== process.env.REACT_APP_Lifetime_preview
            && this.state.subscription_plan !== process.env.REACT_APP_zoho_preview
            && this.state.subscription_plan !== process.env.REACT_APP_STRIPE_PREVIEW && 
            <div className="pricing-notice">
                <h3 className="pricing-notice-header">Your current plan has been discontinued.</h3>
                <p className="pricing-notice-para">Your ongoing subscription and renewals remain unaffected by the price update.</p>
            </div>}
            </div>
          {this.state.is_ios_rebuild_required === 1 || this.state.is_android_rebuild_required === 1 ?
            <div className="container">
                    <div className="price-strip">
                        <svg id="Group_3433" data-name="Group 3433" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#fff"/>
                        <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.207)" fill="none" stroke="#7782a1" stroke-width="2"/>
                        <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#7782a1" stroke-width="2"/>
                        </svg> 
                        <p>If you proceed, you will have to rebuild your app.</p>
                    </div>
                </div>
            :  null
            }
              <div className="container">
                <div>
                <h1>Custom App</h1>
                </div>    
            
                {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                <div className="discount-percent">
                    <svg xmlns="http://www.w3.org/2000/svg" width="19.246" height="16" viewBox="0 0 19.246 16">
                    <g id="_3847911_arrow_left_icon" data-name="3847911_arrow_left_icon" transform="translate(0 16) rotate(-90)">
                        <path id="Path_29096" data-name="Path 29096" d="M.466,5.531a13.393,13.393,0,0,1,5.923,5.291c.656,1.241,2.552.14,1.873-1.1A11.858,11.858,0,0,0,5.593,6.491c3.184-.375,7.024-.281,7.562,3.324a37.167,37.167,0,0,1,.328,4.846c.023,1.4-.281,2.973.468,4.214a.718.718,0,0,0,1.147.14c1.217-1.545.866-4.12.819-6.017s.047-4.261-1.007-5.946C12.8,3.658,8,3.822,4.305,4.244A22.891,22.891,0,0,0,9.2.568c.258-.258-.07-.726-.4-.515A29.761,29.761,0,0,1,.7,3.729,1.007,1.007,0,0,0,.466,5.531Z" transform="translate(0)" fill="#bcc1ce"/>
                    </g>
                    </svg>
                    <p>Save up to 29%</p>
                </div>
                : null
                }
            
                <div className="tabbed">
                    {this.state.monthlydata !== '' && this.state.monthlydata !== undefined ?
                        <input type="radio" id="tab1" name="css-tabs"  onChange={() => {this.handlechecktab('monthly')}}  defaultChecked={this.state.checkedtab == 'monthly' ? true : '' } />
                    : null
                    }
                    {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                        <input type="radio" id="tab2" name="css-tabs" onChange={() => {this.handlechecktab('yearly')}}  defaultChecked={this.state.checkedtab == 'yearly' ? true : '' } />
                    : null
                    }
                    {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                        <input type="radio" id="tab3" name="css-tabs" onChange={() => {this.handlechecktab('lifetime')}}  defaultChecked={this.state.checkedtab == 'lifetime' ? true : '' } />
                    : null
                    }
                <ul className="tabs">
                {this.state.monthlydata !== '' && this.state.monthlydata !== undefined ?
                    <li className="tab"><label for="tab1">Monthly</label></li>
                : null
                }
                {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                    <li className="tab"><label for="tab2">Yearly</label></li>
                : null
                }
                {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                    <li className="tab"><label for="tab3">Lifetime</label></li>
                : null
                }
                </ul>

                {this.state.customer_type !== 4 && this.state.count > 0 && this.state.downgrade_at_term_end !== 1  && this.state.package_duration!==4  ?
                <div className="deals-link" onClick={this.handleDeals}>
                   <img  src={require("../../assets/images/dashboard/deals.png")
                                  .default
                              }
                              className="deals"
                              alt="Goto"
                            />Apply deal
</div>
 : this.state.count > 0 ?
 <>{
     this.state.is_deal_plan===1?
     <div className="deals-link" onClick={this.handleDeals}>
     <img  src={require("../../assets/images/dashboard/deals.png")
                                  .default
                              }
                              className="deals"
                              alt="Goto"
                            />Apply deal
</div>
     : null
    }
    </>
: null
                    
}
                 
                <div className={"tab-content " + (this.state.checkedtab === 'monthly' ? '' : 'd-none')}>

                        <div className="pricingplans allflex">
                    
                    { (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'starter' ) ||
                        (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'starter' ) ||
                        (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'starter' ) && 
                        this.state.one_time !== undefined && this.state.one_time !== '' ?
                        
                        <div className="pricebox">
                            
                            <div className="price-first-half">
                                
                                <div className="borderblue"></div>
                                
                            <div className="allflex">
                                
                                <h2>Free</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>

                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                </div>
                                
                            </div>  
                            <h4>{this.state.customer_billing_type == 3 
                                ? this.state.paddlecurreny + '0'
                                : '$0'}<span className="pricing-decimal">.00</span></h4>
                            <h5></h5>
                            
                            { 
                               this.state.one_time[0] !== undefined && this.state.one_time[0].is_subscribed === 1 ? 
                               (
                                  <Link to="#" className="currentplan">
                                    Current
                                  </Link>
                                ) 
                                : this.state.is_deal_plan == 1 ?
                                (
                                    <Link to="#" onClick={(e) => this.downgradepopupdeal()}>
                                        Select
                                    </Link> 
                                )
                                : this.state.currentplanduration === 4 ?
                                (
                                    <Link to="#" className="lowerplan">
                                        Unavailable
                                    </Link> 
                                )
                                : this.state.downgrade_at_term_end === 1 ?
                                    (
                                        <Link to="#" className="lowerplan">
                                       Unavailable
                                        </Link>
                                )
                                : this.state.one_time[0] !== undefined && this.state.one_time[0].show_plan === 0 ? 
                                (
                                   <Link to="#" className="lowerplan">
                                      Unavailable
                                   </Link>
                                 )
                                : this.state.customer_type === 4 &&
                                  this.state.reseller_licence_id === null ? 
                                (
                                  <a
                                    target="_blank"
                                    href={
                                      process.env.REACT_APP_WOOCOMMERCE_URL +
                                      "my-account/my-subscriptions/"
                                    }
                                  >
                                    Select{" "}
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/Goto.png")
                                          .default
                                      }
                                      alt="Goto"
                                    />
                                  </a>
                                ) : 
                                (
                                  <Link
                                    to="#"
                                    onClick={(e) => this.removepopup()}
                                  >
                                    Select
                                  </Link>
                                )
                            }

                            <div className="pricedowngradeone">
                            {this.state.downgrade_at_term_end === 1 && this.state.endcycledata !== null? 
                            
                            'Cancellation on '  + moment(this.state.endcycledata).format("D MMMM YYYY")
                         :  this.state.downgrade_at_term_end === 1 && this.state.endcycledata === null ?
                           'Downgrade scheduled'
                         : ''}
                             {this.state.downgrade_at_term_end === 1 ? 
                            <img 
                            style={{
                                cursor:"pointer",
                            }}
                            onClick={(e) =>
                                          this.state.customer_type === 4 ? 
                                          this.handleWooCustomer(e,this.state.customer_type,this.state.one_time[0].plan_code)
                                        : this.handleReactivePopUp(e,"cancel")
                                        } 
                            src={require("../../assets/images/pricing/red-cross.png").default} alt="red-cross"/>
                            : ''}
                                      
                            </div>
                            
                            </div>
                            
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck1"/>
                                <label className="mobi-tab-label" for="chck1">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox wordpress-featurebox">
                                    
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>1 x Android app</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>1,000 downloads</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No commission on sales</p>
                                    </div>    
                                </div>
                                    
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No website required</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>In-app browser</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Customizable design</p>
                                    </div>    
                                </div>
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Push notifications</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Monetization features</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Team collaboration</p>
                                    </div>    
                                </div>    
                                    
                                </div>
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            </div>
                                </div>
                            </div>
                        </div>
                    : null
                    }

                        {
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'starter' ) ||
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'starter' ) ||
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'starter' ) 
                        ?  
                            <div className="pricebox">
                                
                                <div className="price-first-half">
                                
                                <div className="borderyellow"></div>
                                <div className="allflex">
                                    
                                    <h2>Starter</h2>
                                    
                                    <div className="platforms">
                                    <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                    
                                    <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                </div>
                                    
                                </div>  
                                
                                {this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0] !== null ?
                            <>
                                {this.state.customer_billing_type !== 3 ?
                                <>
                                <h4>
                                {
                                this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'starter' ?
                                this.state.newmonthlydata[0].package_currency.currency_symbol +
                                this.state.newmonthlydata[0].amount.replace(".00", " ")
                                :
                                this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'starter' ?
                                this.state.newmonthlydata[1].package_currency.currency_symbol +
                                this.state.newmonthlydata[1].amount.replace(".00", " ")
                                :
                                this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'starter' ?
                                this.state.newmonthlydata[2].package_currency.currency_symbol +
                                this.state.newmonthlydata[2].amount.replace(".00", " ")
                                :
                                '$ 29'
                                }
                                </h4>
                                </>
                                :
                                <>
                                {
                                  this.state.newmonthlydata[0].plan == 'starter' ?
                                  ReactHtmlParser(this.state.newmonthlydata[0].paddleprice)
                                 :
                                  this.state.newmonthlydata[1].plan == 'starter' ?
                                  ReactHtmlParser(this.state.newmonthlydata[1].paddleprice)
                                 :
                                  this.state.newmonthlydata[2].plan == 'starter' ?
                                  ReactHtmlParser(this.state.newmonthlydata[2].paddleprice)
                                 :
                                  '$ 29'
                                }
                                </>
                                } 
                                <h5>per month</h5>
                            
                            {
                            (this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'starter' && this.state.newmonthlydata[0].is_subscribed === 1) ||
                            (this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'starter' && this.state.newmonthlydata[1].is_subscribed === 1) ||
                            (this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'starter' && this.state.newmonthlydata[2].is_subscribed === 1) 
                            ? 
                                (
                                    <Link to="#" className="currentplan">
                                        Current
                                    </Link>
                                ) 
                            : 
                                (this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'starter' && this.state.newmonthlydata[0].show_plan === 0) ||
                                (this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'starter' && this.state.newmonthlydata[1].show_plan === 0) ||
                                (this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'starter' && this.state.newmonthlydata[2].show_plan === 0) 
                            ?   
                            (
                                <Link to="#"  className="lowerplan">
                                  Unavailable
                                </Link>
                            ) 
                            :   (
                                    <Link to=
                                            {
                                                this.state.customer_type === 4
                                                ? "#"
                                                : this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'starter' ?
                                                this.dlink(
                                                    this.state.newmonthlydata[0].plan_code,
                                                    parseInt(this.state.newmonthlydata[0].amount),
                                                    this.state.newmonthlydata[0].plan_name,
                                                    this.state.newmonthlydata[0].package_currency.currency_symbol,
                                                    this.state.newmonthlydata[0].plan_code
                                                )
                                                : this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'starter' ?
                                                this.dlink(
                                                    this.state.newmonthlydata[1].plan_code,
                                                    parseInt(this.state.newmonthlydata[1].amount),
                                                    this.state.newmonthlydata[1].plan_name,
                                                    this.state.newmonthlydata[1].package_currency.currency_symbol,
                                                    this.state.newmonthlydata[1].plan_code
                                                )
                                                : this.state.newmonthlydata[2] !== undefined &&  this.state.newmonthlydata[2].plan == 'starter' ?
                                                this.dlink(
                                                    this.state.newmonthlydata[2].plan_code,
                                                    parseInt(this.state.newmonthlydata[1].amount),
                                                    this.state.newmonthlydata[2].plan_name,
                                                    this.state.newmonthlydata[2].package_currency.currency_symbol,
                                                    this.state.newmonthlydata[2].plan_code
                                                )
                                                : this.state.newmonthlydata[0] !== undefined ?
                                                this.dlink(
                                                    this.state.newmonthlydata[0].plan_code,
                                                    parseInt(this.state.newmonthlydata[0].amount),
                                                    this.state.newmonthlydata[0].plan_name,
                                                    this.state.newmonthlydata[0].package_currency.currency_symbol,
                                                    this.state.newmonthlydata[0].plan_code
                                                )
                                                : null


                                            }

                                            onClick={ 
                                                this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'starter' ?
                                                (e) => this.handleWooCustomer(e,
                                                this.state.customer_type,
                                                this.state.newmonthlydata[0].plan_code,
                                                0)
                                                :  this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'starter' ?
                                                (e) => this.handleWooCustomer(e,
                                                this.state.customer_type,
                                                this.state.newmonthlydata[1].plan_code,
                                                0)
                                                :  this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'starter' ?
                                                (e) => this.handleWooCustomer(e,
                                                this.state.customer_type,
                                                this.state.newmonthlydata[2].plan_code,
                                                0)
                                                :  this.state.newmonthlydata[0] !== undefined ?
                                                (e) => this.handleWooCustomer(e,
                                                this.state.customer_type,
                                                this.state.newmonthlydata[0].plan_code,
                                                0)
                                                : null
                                            }

                                        >
                                        Select
                                    </Link>
                                )}
                            </>
                            : null
                            }

                                <div className="pricing-blank-two"></div>
                                
                                </div>
                                    
                                <div className="mobi-tab">
                                    <input type="checkbox" id="chck2"/>
                                    <label className="mobi-tab-label" for="chck2">View Features</label>
                                <div className="mobi-tab-content">
                                <div className="price-second-half">
                                
                                <div className="featurebox wordpress-featurebox">
                                    
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>1 x Android app</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Unlimited downloads</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No commission on sales</p>
                                    </div>    
                                </div>
                                    
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No website required</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>In-app browser</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Customizable design</p>
                                    </div>    
                                </div>
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Push notifications</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Monetization features</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Team collaboration</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Accelerated app builds</p>
                                    </div>    
                                </div>   

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>    
                                    
                                </div>    
                                
                                <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                                
                                <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                                
                                </div> 
                                    </div>
                                </div>
                                    
                            </div>
                        : null
                        } 

                        {
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'pro' ) ||
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'pro' ) ||
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'pro' ) 
                        ?
                        <div className="pricebox">
                            
                            <div className="price-first-half">
                            
                            <div className="borderorange"></div>
                            
                            <div className="allflex">
                                
                                <h2>Pro</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                
                                <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                            </div>
                                
                            </div>  
                            
                            {this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0] !== null ?
                         <>
                         
                         {this.state.customer_billing_type !== 3 ?
                                <>
                            <h4 class="paddle-gross" data-product={this.state.newmonthlydata[1].plan_code}>
                            {
                              this.state.newmonthlydata[0].plan == 'pro' ?
                              this.state.newmonthlydata[0].package_currency.currency_symbol +
                              this.state.newmonthlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newmonthlydata[1].plan == 'pro' ?
                              this.state.newmonthlydata[1].package_currency.currency_symbol +
                              this.state.newmonthlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newmonthlydata[2].plan == 'pro' ?
                              this.state.newmonthlydata[2].package_currency.currency_symbol +
                              this.state.newmonthlydata[2].amount.replace(".00", " ")
                             :
                              '$ 49'
                            }
                            </h4>
                            </>
                        :
                        <>
                        {
                          this.state.newmonthlydata[0].plan == 'pro' ?
                          ReactHtmlParser(this.state.newmonthlydata[0].paddleprice)
                         :
                          this.state.newmonthlydata[1].plan == 'pro' ?
                          ReactHtmlParser(this.state.newmonthlydata[1].paddleprice)
                         :
                          this.state.newmonthlydata[2].plan == 'pro' ?
                          ReactHtmlParser(this.state.newmonthlydata[2].paddleprice)
                         :
                          '$ 29'
                        }
                        </>
                        }
                            <h5>per month</h5>
                            {
                        (this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'pro' && this.state.newmonthlydata[0].is_subscribed === 1) ||
                        (this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'pro' && this.state.newmonthlydata[1].is_subscribed === 1) ||
                        (this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'pro' && this.state.newmonthlydata[2].is_subscribed === 1) 
                        ? 
                            (
                                <Link to="#" className="currentplan">
                                    Current
                                </Link>
                            ) 
                        : 
                            (this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'pro' && this.state.newmonthlydata[0].show_plan === 0) ||
                            (this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'pro' && this.state.newmonthlydata[1].show_plan === 0) ||
                            (this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'pro' && this.state.newmonthlydata[2].show_plan === 0) 
                        ?   
                        (
                            <Link to="#"  className="lowerplan">
                                Unavailable
                            </Link>
                        )
                        :   (
                                   <Link to=
                                        {
                                            this.state.customer_type === 4
                                            ? "#"
                                            : this.state.newmonthlydata[0].plan == 'pro' ?
                                              this.dlink(
                                                this.state.newmonthlydata[0].plan_code,
                                                parseInt(this.state.newmonthlydata[0].amount),
                                                this.state.newmonthlydata[0].plan_name,
                                                this.state.newmonthlydata[0].package_currency.currency_symbol,
                                                this.state.newmonthlydata[0].plan_code
                                              )
                                            : this.state.newmonthlydata[1].plan == 'pro' ?
                                              this.dlink(
                                                this.state.newmonthlydata[1].plan_code,
                                                parseInt(this.state.newmonthlydata[1].amount),
                                                this.state.newmonthlydata[1].plan_name,
                                                this.state.newmonthlydata[1].package_currency.currency_symbol,
                                                this.state.newmonthlydata[1].plan_code
                                              )
                                            : this.state.newmonthlydata[2].plan == 'pro' ?
                                              this.dlink(
                                                this.state.newmonthlydata[2].plan_code,
                                                parseInt(this.state.newmonthlydata[1].amount),
                                                this.state.newmonthlydata[2].plan_name,
                                                this.state.newmonthlydata[2].package_currency.currency_symbol,
                                                this.state.newmonthlydata[2].plan_code
                                              )
                                            :
                                             this.dlink(
                                                this.state.newmonthlydata[1].plan_code,
                                                parseInt(this.state.newmonthlydata[1].amount),
                                                this.state.newmonthlydata[1].plan_name,
                                                this.state.newmonthlydata[1].package_currency.currency_symbol,
                                                this.state.newmonthlydata[1].plan_code
                                              )


                                        }
                                        onClick={ 
                                            this.state.newmonthlydata[0].plan == 'pro' ?
                                            (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newmonthlydata[0].plan_code,
                                              1)
                                            : this.state.newmonthlydata[1].plan == 'pro' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newmonthlydata[1].plan_code,
                                              1)
                                            : this.state.newmonthlydata[2].plan == 'pro' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newmonthlydata[2].plan_code,
                                              1)
                                            :
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newmonthlydata[1].plan_code,
                                            1)
                                           }

                                    >
                                    Select
                                </Link>
                            )}
                         </>
                        : null
                        }

                            <div className="pricing-blank-two"></div>
                  
                            </div>    
                                
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck3"/>
                                <label className="mobi-tab-label" for="chck3">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox wordpress-featurebox">
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>1 x Android & iOS app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Unlimited downloads</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No commission on sales</p>
                                    </div>    
                                </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No website required</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Customizable design</p>
                                    </div>    
                                </div>
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Accelerated app builds</p>
                                    </div>    
                                </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div> 

                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                            </div>    
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            </div>
                                </div>
                            </div>
                                
                        </div>
                        : null
                        }
                        {
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'premium' ) ||
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'premium' ) ||
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'premium' ) 
                        ?
                        <div className="pricebox">
                            
                            <div className="price-first-half">
                            
                            <div className="bordergreen"></div>
                            
                            <div className="allflex">
                                
                                <h2>Premium</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                
                                <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                            </div>
                                
                            </div> 
                            
                            {this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0] !== null ?
                         <>
                          {this.state.customer_billing_type !== 3 ?
                                <>
                            <h4>
                            {
                              this.state.newmonthlydata[0].plan == 'premium' ?
                              this.state.newmonthlydata[0].package_currency.currency_symbol +
                              this.state.newmonthlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newmonthlydata[1].plan == 'premium' ?
                              this.state.newmonthlydata[1].package_currency.currency_symbol +
                              this.state.newmonthlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newmonthlydata[2].plan == 'premium' ?
                              this.state.newmonthlydata[2].package_currency.currency_symbol +
                              this.state.newmonthlydata[2].amount.replace(".00", " ")
                             :
                              '$ 99'
                            }
                            </h4>
                        </>
                        :
                        <>
                        {
                          this.state.newmonthlydata[0].plan == 'premium' ?
                          ReactHtmlParser(this.state.newmonthlydata[0].paddleprice)
                         :
                          this.state.newmonthlydata[1].plan == 'premium' ?
                          ReactHtmlParser(this.state.newmonthlydata[1].paddleprice)
                         :
                          this.state.newmonthlydata[2].plan == 'premium' ?
                          ReactHtmlParser(this.state.newmonthlydata[2].paddleprice)
                         :
                          '$ 29'
                        }
                        </>
                        }
                            <h5>per month</h5>
                        {
                        (this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'premium' && this.state.newmonthlydata[0].is_subscribed === 1) ||
                        (this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'premium' && this.state.newmonthlydata[1].is_subscribed === 1) ||
                        (this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'premium' && this.state.newmonthlydata[2].is_subscribed === 1) 
                        ? 
                            (
                                <Link to="#" className="currentplan">
                                    Current
                                </Link>
                            ) 
                        : 
                            (this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'premium' && this.state.newmonthlydata[0].show_plan === 0) ||
                            (this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'premium' && this.state.newmonthlydata[1].show_plan === 0) ||
                            (this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'premium' && this.state.newmonthlydata[2].show_plan === 0) 
                        ?   
                        (
                            <Link to="#"  className="lowerplan">
                               Unavailable
                            </Link>
                        )
                            :   (
                                <Link to=
                                     {
                                         this.state.customer_type === 4
                                         ? "#"
                                         : this.state.newmonthlydata[0].plan == 'premium' ?
                                           this.dlink(
                                             this.state.newmonthlydata[0].plan_code,
                                             parseInt(this.state.newmonthlydata[0].amount),
                                             this.state.newmonthlydata[0].plan_name,
                                             this.state.newmonthlydata[0].package_currency.currency_symbol,
                                             this.state.newmonthlydata[0].plan_code
                                           )
                                         : this.state.newmonthlydata[1].plan == 'premium' ?
                                           this.dlink(
                                             this.state.newmonthlydata[1].plan_code,
                                             parseInt(this.state.newmonthlydata[1].amount),
                                             this.state.newmonthlydata[1].plan_name,
                                             this.state.newmonthlydata[1].package_currency.currency_symbol,
                                             this.state.newmonthlydata[1].plan_code
                                           )
                                         : this.state.newmonthlydata[2].plan == 'premium' ?
                                           this.dlink(
                                             this.state.newmonthlydata[2].plan_code,
                                             parseInt(this.state.newmonthlydata[2].amount),
                                             this.state.newmonthlydata[2].plan_name,
                                             this.state.newmonthlydata[2].package_currency.currency_symbol,
                                             this.state.newmonthlydata[2].plan_code
                                           )
                                         :
                                          this.dlink(
                                             this.state.newmonthlydata[2].plan_code,
                                             parseInt(this.state.newmonthlydata[2].amount),
                                             this.state.newmonthlydata[2].plan_name,
                                             this.state.newmonthlydata[2].package_currency.currency_symbol,
                                             this.state.newmonthlydata[2].plan_code
                                           )


                                     }
                                     onClick={ 
                                        this.state.newmonthlydata[0].plan == 'premium' ?
                                        (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newmonthlydata[0].plan_code,
                                          2)
                                        : this.state.newmonthlydata[1].plan == 'premium' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newmonthlydata[1].plan_code,
                                          2)
                                        : this.state.newmonthlydata[2].plan == 'premium' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newmonthlydata[2].plan_code,
                                          2)
                                        :
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newmonthlydata[2].plan_code,
                                        2)
                                       }

                                 >
                                 Select
                             </Link>
                         )}
                         </>
                        : null
                        }

<div className="pricing-blank-two"></div>
                            
                            </div>    
                                
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck4"/>
                                <label className="mobi-tab-label" for="chck4">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox wordpress-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>1 x Android & iOS app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Unlimited downloads</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No commission on sales</p>
                                    </div>    
                                </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No website required</p>
                                </div>    
                            </div>
                            
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Advanced Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Accelerated app builds</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div> 

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Merge apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Language selection</p>
                                </div>    
                            </div>

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Offline mode</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>App analytics</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Automated distribution</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Business support</p>
                                    </div>    
                                </div>

                                
                                
                                
                            </div>    
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                                
                            </div>
                                </div>
                            </div>
                            
                        </div>
                        : null
                        }
                        
                    </div>

                </div>

                <div className={"tab-content " + (this.state.checkedtab === 'yearly' ? '' : 'd-none')}>
                     
                     <div className="pricingplans allflex">
                    
                     { (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'starter' ) ||
                        (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'starter' ) ||
                        (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'starter' ) && 
                        this.state.one_time !== undefined && this.state.one_time !== '' ?
                         
                         <div className="pricebox">
                             
                             <div className="price-first-half">
                                 
                                 <div className="borderblue"></div>
                                 
                             <div className="allflex">
                                 
                                 <h2>Free</h2>
                                 
                                 <div className="platforms">
                                 <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>

                                 <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                 </div>
                                 
                             </div>  
                             
                             <h4>{this.state.customer_billing_type == 3 
                                ? this.state.paddlecurreny + '0'
                                : '$0'}<span className="pricing-decimal">.00</span></h4>
                             <h5></h5>
                            
                             { 
                               this.state.one_time[0] !== undefined && this.state.one_time[0].is_subscribed === 1 ? 
                               (
                                  <Link to="#" className="currentplan">
                                    Current
                                  </Link>
                                )
                                : this.state.is_deal_plan == 1 ?
                                (
                                    <Link to="#" onClick={(e) => this.downgradepopupdeal()}>
                                        Select
                                    </Link> 
                                )
                                : this.state.currentplanduration === 4 ?
                                (
                                    <Link to="#" className="lowerplan">
                                        Unavailable
                                    </Link> 
                                ) 
                                : this.state.downgrade_at_term_end === 1 ?
                                (
                                    <Link to="#" className="lowerplan">
                                      Unavailable
                                    </Link>
                              )
                                : this.state.one_time[0] !== undefined && this.state.one_time[0].show_plan === 0 ? 
                                (
                                   <Link to="#" className="lowerplan">
                                      Unavailable
                                   </Link>
                                )
                                
                                : this.state.customer_type === 4 &&
                                  this.state.reseller_licence_id === null ? 
                                (
                                  <a
                                    target="_blank"
                                    href={
                                      process.env.REACT_APP_WOOCOMMERCE_URL +
                                      "my-account/my-subscriptions/"
                                    }
                                  >
                                    Select{" "}
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/Goto.png")
                                          .default
                                      }
                                      alt="Goto"
                                    />
                                  </a>
                                ) : 
                                (
                                  <Link
                                    to="#"
                                    onClick={(e) => this.removepopup()}
                                  >
                                    Select
                                  </Link>
                                )
                            }

                            <div className="pricedowngradeone">
                            {this.state.downgrade_at_term_end === 1 && this.state.endcycledata !== null? 
                            
                            'Cancellation on '  + moment(this.state.endcycledata).format("D MMMM YYYY")
                         :  this.state.downgrade_at_term_end === 1 && this.state.endcycledata === null ?
                           'Downgrade scheduled'
                         : ''}
                         {this.state.downgrade_at_term_end === 1 ? 
                        <img 
                        style={{
                            cursor:"pointer",
                        }}
                        onClick={(e) =>
                                      this.state.customer_type === 4 ? 
                                      this.handleWooCustomer(e,this.state.customer_type,this.state.one_time[0].plan_code)
                                    : this.handleReactivePopUp(e,"cancel")
                                    } 
                        src={require("../../assets/images/pricing/red-cross.png").default} alt="red-cross"/>
                        : ''}
                                      
                            </div>
                            </div>
                            
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck9"/>
                                <label className="mobi-tab-label" for="chck9">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox wordpress-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>1 x Android app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>1,000 downloads</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No commission on sales</p>
                                    </div>    
                                </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No website required</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>    
                                
                            </div>
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            </div>
                                </div>
                            </div>
                        </div>
                     : null
                    }

                        {
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'starter' ) ||
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'starter' ) ||
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'starter' ) 
                        ? 
                        <div className="pricebox">
                            
                            <div className="price-first-half">
                            
                            <div className="borderyellow"></div>
                            <div className="allflex">
                                
                                <h2>Starter</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            </div>
                                
                            </div>  
                            
                            {this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0] !== null ?
                         <>
                         {this.state.customer_billing_type !== 3 ?
                                <>
                             <h4>
                            {
                              this.state.newyearlydata[0].plan == 'starter' ?
                              this.state.newyearlydata[0].package_currency.currency_symbol +
                              this.state.newyearlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[1].plan == 'starter' ?
                              this.state.newyearlydata[1].package_currency.currency_symbol +
                              this.state.newyearlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[2].plan == 'starter' ?
                              this.state.newyearlydata[2].package_currency.currency_symbol +
                              this.state.newyearlydata[2].amount.replace(".00", " ")
                             :
                              '$ 19'
                            }
                            </h4>
                        </>
                        :
                        <>
                        {
                          this.state.newyearlydata[0].plan == 'starter' ?
                          ReactHtmlParser(this.state.newyearlydata[0].paddleprice)
                         :
                          this.state.newyearlydata[1].plan == 'starter' ?
                          ReactHtmlParser(this.state.newyearlydata[1].paddleprice)
                         :
                          this.state.newyearlydata[2].plan == 'starter' ?
                          ReactHtmlParser(this.state.newyearlydata[2].paddleprice)
                         :
                          '$ 19'
                        }
                        </>
                        }
                            <h5>per month paid yearly</h5>
                          {
                            (this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'starter' && this.state.newyearlydata[0].is_subscribed === 1) ||
                            (this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'starter' && this.state.newyearlydata[1].is_subscribed === 1) ||
                            (this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'starter' && this.state.newyearlydata[2].is_subscribed === 1) 
                            ? 
                                (
                                    <Link to="#" className="currentplan">
                                        Current
                                    </Link>
                                )
                            : 
                                (this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'starter' && this.state.newyearlydata[0].show_plan === 0) ||
                                (this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'starter' && this.state.newyearlydata[1].show_plan === 0) ||
                                (this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'starter' && this.state.newyearlydata[2].show_plan === 0) 
                            ?   
                            (
                                <Link to="#"  class="lowerplan">
                                    Unavailable
                                </Link>
                            )  
                            :   (
                                <Link to=
                                     {
                                         this.state.customer_type === 4
                                         ? "#"
                                         : this.state.newyearlydata[0].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newyearlydata[0].plan_code,
                                             parseInt(this.state.newyearlydata[0].amount),
                                             this.state.newyearlydata[0].plan_name,
                                             this.state.newyearlydata[0].package_currency.currency_symbol,
                                             this.state.newyearlydata[0].plan_code
                                           )
                                         : this.state.newyearlydata[1].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newyearlydata[1].plan_code,
                                             parseInt(this.state.newyearlydata[1].amount),
                                             this.state.newyearlydata[1].plan_name,
                                             this.state.newyearlydata[1].package_currency.currency_symbol,
                                             this.state.newyearlydata[1].plan_code
                                           )
                                         : this.state.newyearlydata[2].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newyearlydata[2].plan_code,
                                             parseInt(this.state.newyearlydata[2].amount),
                                             this.state.newyearlydata[2].plan_name,
                                             this.state.newyearlydata[2].package_currency.currency_symbol,
                                             this.state.newyearlydata[2].plan_code
                                           )
                                         :
                                          this.dlink(
                                             this.state.newyearlydata[0].plan_code,
                                             parseInt(this.state.newyearlydata[0].amount),
                                             this.state.newyearlydata[0].plan_name,
                                             this.state.newyearlydata[0].package_currency.currency_symbol,
                                             this.state.newyearlydata[0].plan_code
                                           )


                                     }
                                     onClick={ 
                                        this.state.newyearlydata[0].plan == 'starter' ?
                                        (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newyearlydata[0].plan_code,
                                          0)
                                        : this.state.newyearlydata[1].plan == 'starter' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newyearlydata[1].plan_code,
                                          0)
                                        : this.state.newyearlydata[2].plan == 'starter' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newyearlydata[2].plan_code,
                                          0)
                                        :
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newyearlydata[0].plan_code,
                                        0)
                                       }

                                 >
                                 Select
                             </Link>
                         )}
                         </>
                        : null
                        }

<div className="pricing-blank-two"></div>
                            
                            </div>
                                
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck10"/>
                                <label className="mobi-tab-label" for="chck10">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox wordpress-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>1 x Android app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Unlimited downloads</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No commission on sales</p>
                                    </div>    
                                </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No website required</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Accelerated app builds</p>
                                    </div>    
                                </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No AppMySite branding</p>
                                </div>    
                            </div>    
                                
                            </div>    
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            </div>
                                </div>
                            </div>
                                
                        </div>
                        : null
                        }

                        {
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'pro' ) ||
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'pro' ) ||
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'pro' ) 
                        ?
                        <div className="pricebox">
                            
                            <div className="price-first-half">
                            
                            <div className="borderorange"></div>
                            
                            <div className="allflex">
                                
                                <h2>Pro</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                
                                <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                            </div>
                                
                            </div>  
                            
                            {this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0] !== null ?
                         <>
                         {this.state.customer_billing_type !== 3 ?
                             <h4>
                            {
                              this.state.newyearlydata[0].plan == 'pro' ?
                              this.state.newyearlydata[0].package_currency.currency_symbol +
                              this.state.newyearlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[1].plan == 'pro' ?
                              this.state.newyearlydata[1].package_currency.currency_symbol +
                              this.state.newyearlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[2].plan == 'pro' ?
                              this.state.newyearlydata[2].package_currency.currency_symbol +
                              this.state.newyearlydata[2].amount.replace(".00", " ")
                             :
                              '$ 39'
                            }
                            </h4>
                        :
                        <>
                        {
                          this.state.newyearlydata[0].plan == 'pro' ?
                          ReactHtmlParser(this.state.newyearlydata[0].paddleprice)
                         :
                          this.state.newyearlydata[1].plan == 'pro' ?
                          ReactHtmlParser(this.state.newyearlydata[1].paddleprice)
                         :
                          this.state.newyearlydata[2].plan == 'pro' ?
                          ReactHtmlParser(this.state.newyearlydata[2].paddleprice)
                         :
                          '$ 19'
                        }
                        </>
                        }
                            <h5>per month paid yearly</h5>
                            {
                            (this.state.newyearlydata[0] !== '' && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'pro' && this.state.newyearlydata[0].is_subscribed === 1) ||
                            (this.state.newyearlydata[1] !== '' && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'pro' && this.state.newyearlydata[1].is_subscribed === 1) ||
                            (this.state.newyearlydata[2] !== '' && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'pro' && this.state.newyearlydata[2].is_subscribed === 1) 
                            ? 
                                (
                                    <Link to="#" className="currentplan">
                                        Current
                                    </Link>
                                )
                            : 
                                (this.state.newyearlydata[0] !== '' && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'pro' && this.state.newyearlydata[0].show_plan === 0) ||
                                (this.state.newyearlydata[1] !== '' && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'pro' && this.state.newyearlydata[1].show_plan === 0) ||
                                (this.state.newyearlydata[2] !== '' && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'pro' && this.state.newyearlydata[2].show_plan === 0) 
                            ?   
                            (
                                <Link to="#"  class="lowerplan">
                                Unavailable
                            </Link>
                            ) 
                            :   (
                                    <Link to=
                                         {
                                             this.state.customer_type === 4
                                             ? "#"
                                             : this.state.newyearlydata[0].plan == 'pro' ?
                                               this.dlink(
                                                 this.state.newyearlydata[0].plan_code,
                                                 parseInt(this.state.newyearlydata[0].amount),
                                                 this.state.newyearlydata[0].plan_name,
                                                 this.state.newyearlydata[0].package_currency.currency_symbol,
                                                 this.state.newyearlydata[0].plan_code
                                               )
                                             : this.state.newyearlydata[1].plan == 'pro' ?
                                               this.dlink(
                                                 this.state.newyearlydata[1].plan_code,
                                                 parseInt(this.state.newyearlydata[1].amount),
                                                 this.state.newyearlydata[1].plan_name,
                                                 this.state.newyearlydata[1].package_currency.currency_symbol,
                                                 this.state.newyearlydata[1].plan_code
                                               )
                                             : this.state.newyearlydata[2].plan == 'pro' ?
                                               this.dlink(
                                                 this.state.newyearlydata[2].plan_code,
                                                 parseInt(this.state.newyearlydata[2].amount),
                                                 this.state.newyearlydata[2].plan_name,
                                                 this.state.newyearlydata[2].package_currency.currency_symbol,
                                                 this.state.newyearlydata[2].plan_code
                                               )
                                             :
                                              this.dlink(
                                                 this.state.newyearlydata[1].plan_code,
                                                 parseInt(this.state.newyearlydata[1].amount),
                                                 this.state.newyearlydata[1].plan_name,
                                                 this.state.newyearlydata[1].package_currency.currency_symbol,
                                                 this.state.newyearlydata[1].plan_code
                                               )
    
    
                                         }
                                         onClick={ 
                                            this.state.newyearlydata[0].plan == 'pro' ?
                                            (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[0].plan_code,
                                              1)
                                            : this.state.newyearlydata[1].plan == 'pro' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[1].plan_code,
                                              1)
                                            : this.state.newyearlydata[2].plan == 'pro' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[2].plan_code,
                                              1)
                                            :
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newyearlydata[1].plan_code,
                                            1)
                                           }
    
                                     >
                                     Select
                                 </Link>
                             )}
                         </>
                        : null
                        }

<div className="pricing-blank-two"></div>
                  
                            </div>    
                                
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck11"/>
                                <label className="mobi-tab-label" for="chck11">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox wordpress-featurebox">
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>1 x Android & iOS app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Unlimited downloads</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No commission on sales</p>
                                    </div>    
                                </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No website required</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Accelerated app builds</p>
                                    </div>    
                                </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No AppMySite branding</p>
                                </div>    
                            </div> 
                            
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                            </div>    
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            </div> 
                                </div>
                            </div>
                                
                        </div>
                        : null
                        }

                        {
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'premium' ) ||
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'premium' ) ||
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'premium' ) 
                        ?
                        <div className="pricebox">
                            
                            <div className="price-first-half">
                            
                            <div className="bordergreen"></div>
                            
                            <div className="allflex">
                                
                                <h2>Premium</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                
                                <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                            </div>
                                
                            </div> 
                            
                            {this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0] !== null ?
                         <>
                         {this.state.customer_billing_type !== 3 ?
                             <h4>
                            {
                              this.state.newyearlydata[0].plan == 'premium' ?
                              this.state.newyearlydata[0].package_currency.currency_symbol +
                              this.state.newyearlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[1].plan == 'premium' ?
                              this.state.newyearlydata[1].package_currency.currency_symbol +
                              this.state.newyearlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[2].plan == 'premium' ?
                              this.state.newyearlydata[2].package_currency.currency_symbol +
                              this.state.newyearlydata[2].amount.replace(".00", " ")
                             :
                              '$ 79'
                            }
                            </h4>
                        :
                        <>
                        {
                          this.state.newyearlydata[0].plan == 'premium' ?
                          ReactHtmlParser(this.state.newyearlydata[0].paddleprice)
                         :
                          this.state.newyearlydata[1].plan == 'premium' ?
                          ReactHtmlParser(this.state.newyearlydata[1].paddleprice)
                         :
                          this.state.newyearlydata[2].plan == 'premium' ?
                          ReactHtmlParser(this.state.newyearlydata[2].paddleprice)
                         :
                          '$ 19'
                        }
                        </>
                        }
                            
                            <h5>per month paid yearly</h5>
                            {
                            (this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'premium' && this.state.newyearlydata[0].is_subscribed === 1) ||
                            (this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'premium' && this.state.newyearlydata[1].is_subscribed === 1) ||
                            (this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'premium' && this.state.newyearlydata[2].is_subscribed === 1) 
                            ? 
                                (
                                    <Link to="#" className="currentplan">
                                        Current
                                    </Link>
                                )
                            : 
                                (this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'premium' && this.state.newyearlydata[0].show_plan === 0) ||
                                (this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'premium' && this.state.newyearlydata[1].show_plan === 0) ||
                                (this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'premium' && this.state.newyearlydata[2].show_plan === 0) 
                            ?   
                            (
                                <Link to="#"  class="lowerplan">
                                    Unavailable
                                </Link>
                            ) 
                                :   (
                                    <Link to=
                                         {
                                             this.state.customer_type === 4
                                             ? "#"
                                             : this.state.newyearlydata[0].plan == 'premium' ?
                                               this.dlink(
                                                 this.state.newyearlydata[0].plan_code,
                                                 parseInt(this.state.newyearlydata[0].amount),
                                                 this.state.newyearlydata[0].plan_name,
                                                 this.state.newyearlydata[0].package_currency.currency_symbol,
                                                 this.state.newyearlydata[0].plan_code
                                               )
                                             : this.state.newyearlydata[1].plan == 'premium' ?
                                               this.dlink(
                                                 this.state.newyearlydata[1].plan_code,
                                                 parseInt(this.state.newyearlydata[1].amount),
                                                 this.state.newyearlydata[1].plan_name,
                                                 this.state.newyearlydata[1].package_currency.currency_symbol,
                                                 this.state.newyearlydata[1].plan_code
                                               )
                                             : this.state.newyearlydata[2].plan == 'premium' ?
                                               this.dlink(
                                                 this.state.newyearlydata[2].plan_code,
                                                 parseInt(this.state.newyearlydata[2].amount),
                                                 this.state.newyearlydata[2].plan_name,
                                                 this.state.newyearlydata[2].package_currency.currency_symbol,
                                                 this.state.newyearlydata[2].plan_code
                                               )
                                             :
                                              this.dlink(
                                                 this.state.newyearlydata[2].plan_code,
                                                 parseInt(this.state.newyearlydata[2].amount),
                                                 this.state.newyearlydata[2].plan_name,
                                                 this.state.newyearlydata[2].package_currency.currency_symbol,
                                                 this.state.newyearlydata[2].plan_code
                                               )
    
    
                                         }
                                         onClick={ 
                                            this.state.newyearlydata[0].plan == 'premium' ?
                                            (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[0].plan_code,
                                              2)
                                            : this.state.newyearlydata[1].plan == 'premium' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[1].plan_code,
                                              2)
                                            : this.state.newyearlydata[2].plan == 'premium' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[2].plan_code,
                                              2)
                                            :
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newyearlydata[2].plan_code,
                                            2)
                                           }
    
                                     >
                                     Select
                                 </Link>
                             )}
                         </>
                        : null
                        }

<div className="pricing-blank-two"></div>
                            
                            </div>    
                                
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck12"/>
                                <label className="mobi-tab-label" for="chck12">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox wordpress-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>1 x Android & iOS app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Unlimited downloads</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No commission on sales</p>
                                    </div>    
                                </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No website required</p>
                                </div>    
                            </div>
                            
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Advanced Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Accelerated app builds</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div> 

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Merge apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Language selection</p>
                                </div>    
                            </div>

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Offline mode</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>App analytics</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Automated distribution</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Business support</p>
                                    </div>    
                                </div>
                                
                                
                            </div>    
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                                
                            </div> 
                                </div>
                            </div>
                            
                        </div>
                        : null
                        }
                        
                    </div>
                        
                </div>
                    
                <div className={"tab-content " + (this.state.checkedtab === 'lifetime' ? '' : 'd-none')}>
                    
                    <div className="pricingplans allflex">

                    { (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'starter' ) ||
                        (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'starter' ) ||
                        (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'starter' ) && 
                        this.state.one_time !== undefined && this.state.one_time !== '' ?
                        
                        <div className="pricebox">
                            
                            <div className="price-first-half">
                                
                                <div className="borderblue"></div>
                                
                            <div className="allflex">
                                
                                <h2>Free</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>

                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                </div>
                                
                            </div>  
                            
                            <h4>{this.state.customer_billing_type == 3 
                                ? this.state.paddlecurreny + '0'
                                : '$0'}<span className="pricing-decimal">.00</span></h4>
                            <h5></h5>
                            
                            { 
                               this.state.one_time[0] !== undefined && this.state.one_time[0].is_subscribed === 1 ?  
                               (
                                  <Link to="#" class="currentplan">
                                    Current
                                  </Link>
                                )
                                : this.state.is_deal_plan == 1 ?
                                (
                                    <Link to="#"  onClick={(e) => this.downgradepopupdeal()}>
                                        Select
                                    </Link> 
                                )
                                : this.state.currentplanduration === 4 ?
                                (
                                    <Link to="#" className="lowerplan">
                                        Unavailable
                                    </Link> 
                                ) 
                                : this.state.downgrade_at_term_end === 1 ?
                                (
                                    <Link to="#" className="lowerplan">
                                       Unavailable
                                    </Link>
                                )
                                : this.state.one_time[0] !== undefined && this.state.one_time[0].show_plan === 0 ? 
                                (
                                    <Link to="#"  onClick={(e) => this.downgradepopup()}>
                                    Select
                                    </Link>
                                )
                                : this.state.customer_type === 4 &&
                                  this.state.reseller_licence_id === null ? 
                                (
                                  <a
                                    target="_blank"
                                    href={
                                      process.env.REACT_APP_WOOCOMMERCE_URL +
                                      "my-account/my-subscriptions/"
                                    }
                                  >
                                    Select{" "}
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/Goto.png")
                                          .default
                                      }
                                      alt="Goto"
                                    />
                                  </a>
                                ) : 
                                (
                                  <Link
                                    to="#"
                                    onClick={(e) => this.removepopup()}
                                  >
                                    Select
                                  </Link>
                                )
                            }

                        <div className="pricedowngradeone">
                        {this.state.downgrade_at_term_end === 1 && this.state.endcycledata !== null? 
                            
                            'Cancellation on '  + moment(this.state.endcycledata).format("D MMMM YYYY")
                         :  this.state.downgrade_at_term_end === 1 && this.state.endcycledata === null ?
                           'Downgrade scheduled'
                         : ''}
                         {this.state.downgrade_at_term_end === 1 ? 
                        <img
                        style={{
                            cursor:"pointer",
                        }}
                        onClick={(e) =>
                                      this.state.customer_type === 4 ? 
                                      this.handleWooCustomer(e,this.state.customer_type,this.state.one_time[0].plan_code)
                                    : this.handleReactivePopUp(e,"cancel")
                                    } 
                        src={require("../../assets/images/pricing/red-cross.png").default} alt="red-cross"/>
                        : ''}
                                      
                            </div>
                            </div>
                            
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck17"/>
                                <label className="mobi-tab-label" for="chck17">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox wordpress-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>1 x Android app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>1,000 downloads</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No commission on sales</p>
                                    </div>    
                                </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No website required</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>    
                                
                            </div>
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            </div>
                                </div>
                            </div>
                        </div>
                    : null
                    }  

                        {
                            (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'starter' ) ||
                            (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'starter' ) ||
                            (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'starter' ) 
                        ?
                        
                        <div className="pricebox">
                            
                            <div className="price-first-half">
                            
                            <div className="borderyellow"></div>
                            <div className="allflex">
                                
                                <h2>Starter</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            </div>
                                
                            </div>  
                            
                            {this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0] !== null ?
                         <>
                         {this.state.customer_billing_type !== 3 ?
                           <h4>
                           {
                             this.state.newlifetimedata[0].plan == 'starter' ?
                             this.state.newlifetimedata[0].package_currency.currency_symbol +
                             this.state.newlifetimedata[0].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[1].plan == 'starter' ?
                             this.state.newlifetimedata[1].package_currency.currency_symbol +
                             this.state.newlifetimedata[1].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[2].plan == 'starter' ?
                             this.state.newlifetimedata[2].package_currency.currency_symbol +
                             this.state.newlifetimedata[2].amount.replace(".00", " ")
                            :
                             '$ 79'
                           }
                           </h4>
                         :
                         <>
                         {
                           this.state.newlifetimedata[0].plan == 'starter' ?
                           ReactHtmlParser(this.state.newlifetimedata[0].paddleprice)
                          :
                           this.state.newlifetimedata[1].plan == 'starter' ?
                           ReactHtmlParser(this.state.newlifetimedata[1].paddleprice)
                          :
                           this.state.newlifetimedata[2].plan == 'starter' ?
                           ReactHtmlParser(this.state.newlifetimedata[2].paddleprice)
                          :
                           '$ 499'
                         }
                         </>
                        }
                            <h5>one time payment</h5>
                            {
                            (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'starter' && this.state.newlifetimedata[0].is_subscribed === 1) ||
                            (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'starter' && this.state.newlifetimedata[1].is_subscribed === 1) ||
                            (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'starter' && this.state.newlifetimedata[2].is_subscribed === 1) 
                            ? 
                                (
                                    <Link to="#" className="currentplan">
                                        Current
                                    </Link>
                                )
                            :   (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'starter' && this.state.newlifetimedata[0].show_plan === 0) ||
                                (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'starter' && this.state.newlifetimedata[1].show_plan === 0) ||
                                (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'starter' && this.state.newlifetimedata[2].show_plan === 0) 
                            ? 
                                (
                                    <Link to="#" className="lowerplan">
                                           Unavailable
                                    </Link>
                                ) 
                            :   (
                                <Link to=
                                     {
                                         this.state.customer_type === 4
                                         ? "#"
                                         : this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newlifetimedata[0].plan_code,
                                             parseInt(this.state.newlifetimedata[0].amount),
                                             this.state.newlifetimedata[0].plan_name,
                                             this.state.newlifetimedata[0].package_currency.currency_symbol,
                                             this.state.newlifetimedata[0].plan_code
                                           )
                                         : this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newlifetimedata[1].plan_code,
                                             parseInt(this.state.newlifetimedata[1].amount),
                                             this.state.newlifetimedata[1].plan_name,
                                             this.state.newlifetimedata[1].package_currency.currency_symbol,
                                             this.state.newlifetimedata[1].plan_code
                                           )
                                         : this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newlifetimedata[2].plan_code,
                                             parseInt(this.state.newlifetimedata[2].amount),
                                             this.state.newlifetimedata[2].plan_name,
                                             this.state.newlifetimedata[2].package_currency.currency_symbol,
                                             this.state.newlifetimedata[2].plan_code
                                           )
                                         : this.state.newlifetimedata[0] !== undefined ?
                                          this.dlink(
                                             this.state.newlifetimedata[0].plan_code,
                                             parseInt(this.state.newlifetimedata[0].amount),
                                             this.state.newlifetimedata[0].plan_name,
                                             this.state.newlifetimedata[0].package_currency.currency_symbol,
                                             this.state.newlifetimedata[0].plan_code
                                           )
                                          : null
                                     }
                                     onClick={ 
                                        this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'starter' ?
                                        (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newlifetimedata[0].plan_code,
                                          0)
                                        : this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'starter' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newlifetimedata[1].plan_code,
                                          0)
                                        : this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'starter' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newlifetimedata[2].plan_code,
                                          0)
                                        : this.state.newlifetimedata[2] !== undefined ? 
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newlifetimedata[0].plan_code,
                                        0)
                                        : null
                                       }

                                 >
                                 Select
                             </Link>
                            )}
                         </>
                        : null
                        }

<div className="pricing-blank-two"></div>
                            
                            </div>
                                
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck18"/>
                                <label className="mobi-tab-label" for="chck18">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox wordpress-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>1 x Android app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Unlimited downloads</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No commission on sales</p>
                                    </div>    
                                </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No website required</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Accelerated app builds</p>
                                    </div>    
                                </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No AppMySite branding</p>
                                </div>    
                            </div>    
                                
                            </div>    
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            </div>
                                </div>
                            </div>
                                
                        </div>
                        : null
                        }
                         {
                            (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'pro' ) ||
                            (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'pro' ) ||
                            (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'pro' ) 
                        ?
                            <div className="pricebox">
                                
                                <div className="price-first-half">
                                
                                <div className="borderorange"></div>
                                
                                <div className="allflex">
                                    
                                    <h2>Pro</h2>
                                    
                                    <div className="platforms">
                                    <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                    
                                    <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                    
                                    <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                                </div>
                                    
                                </div>  
                                
                                {this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0] !== null ?
                            <>
                             {this.state.customer_billing_type !== 3 ?
                           <h4>
                           {
                             this.state.newlifetimedata[0].plan == 'pro' ?
                             this.state.newlifetimedata[0].package_currency.currency_symbol +
                             this.state.newlifetimedata[0].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[1].plan == 'pro' ?
                             this.state.newlifetimedata[1].package_currency.currency_symbol +
                             this.state.newlifetimedata[1].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[2].plan == 'pro' ?
                             this.state.newlifetimedata[2].package_currency.currency_symbol +
                             this.state.newlifetimedata[2].amount.replace(".00", " ")
                            :
                             '$ 79'
                           }
                           </h4>
                         :
                         <>
                         {
                           this.state.newlifetimedata[0].plan == 'pro' ?
                           ReactHtmlParser(this.state.newlifetimedata[0].paddleprice)
                          :
                           this.state.newlifetimedata[1].plan == 'pro' ?
                           ReactHtmlParser(this.state.newlifetimedata[1].paddleprice)
                          :
                           this.state.newlifetimedata[2].plan == 'pro' ?
                           ReactHtmlParser(this.state.newlifetimedata[2].paddleprice)
                          :
                           '$ 499'
                         }
                         </>
                            }
                                <h5>one time payment</h5>
                                {
                                (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'pro' && this.state.newlifetimedata[0].is_subscribed === 1) ||
                                (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'pro' && this.state.newlifetimedata[1].is_subscribed === 1) ||
                                (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'pro' && this.state.newlifetimedata[2].is_subscribed === 1) 
                                ? 
                                    (
                                        <Link to="#" className="currentplan">
                                            Current
                                        </Link>
                                    )
                                :   (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'pro' && this.state.newlifetimedata[0].show_plan === 0) ||
                                    (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'pro' && this.state.newlifetimedata[1].show_plan === 0) ||
                                    (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'pro' && this.state.newlifetimedata[2].show_plan === 0) 
                                ? 
                                    (
                                        <Link to="#" className="lowerplan">
                                           Unavailable
                                        </Link>
                                    )  
                                    :   (
                                        <Link to=
                                            {
                                                this.state.customer_type === 4
                                                ? "#"
                                                : this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'pro' ?
                                                this.dlink(
                                                    this.state.newlifetimedata[0].plan_code,
                                                    parseInt(this.state.newlifetimedata[0].amount),
                                                    this.state.newlifetimedata[0].plan_name,
                                                    this.state.newlifetimedata[0].package_currency.currency_symbol,
                                                    this.state.newlifetimedata[0].plan_code
                                                )
                                                : this.state.newlifetimedata[1] !== undefined &&  this.state.newlifetimedata[1].plan == 'pro' ?
                                                this.dlink(
                                                    this.state.newlifetimedata[1].plan_code,
                                                    parseInt(this.state.newlifetimedata[1].amount),
                                                    this.state.newlifetimedata[1].plan_name,
                                                    this.state.newlifetimedata[1].package_currency.currency_symbol,
                                                    this.state.newlifetimedata[1].plan_code
                                                )
                                                : this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'pro' ?
                                                this.dlink(
                                                    this.state.newlifetimedata[2].plan_code,
                                                    parseInt(this.state.newlifetimedata[2].amount),
                                                    this.state.newlifetimedata[2].plan_name,
                                                    this.state.newlifetimedata[2].package_currency.currency_symbol,
                                                    this.state.newlifetimedata[2].plan_code
                                                )
                                                : this.state.newlifetimedata[1] !== undefined ?
                                                this.dlink(
                                                    this.state.newlifetimedata[1].plan_code,
                                                    parseInt(this.state.newlifetimedata[1].amount),
                                                    this.state.newlifetimedata[1].plan_name,
                                                    this.state.newlifetimedata[1].package_currency.currency_symbol,
                                                    this.state.newlifetimedata[1].plan_code
                                                )
                                                : null
                                            }
                                            onClick={ 
                                                this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'pro' ?
                                                (e) => this.handleWooCustomer(e,
                                                this.state.customer_type,
                                                this.state.newlifetimedata[0].plan_code,
                                                1)
                                                : this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'pro' ?
                                                (e) => this.handleWooCustomer(e,
                                                this.state.customer_type,
                                                this.state.newlifetimedata[1].plan_code,
                                                1)
                                                :  this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'pro' ?
                                                (e) => this.handleWooCustomer(e,
                                                this.state.customer_type,
                                                this.state.newlifetimedata[2].plan_code,
                                                1)
                                                : this.state.newlifetimedata[1] !== undefined ?
                                                (e) => this.handleWooCustomer(e,
                                                this.state.customer_type,
                                                this.state.newlifetimedata[1].plan_code,
                                                1)
                                                : null
                                            }
        
        
                                        >
                                        Select
                                    </Link>
                                    )}
                            </>
                            : null
                            }

<div className="pricing-blank-two"></div>
                    
                                </div>    
                                    
                                <div className="mobi-tab">
                                    <input type="checkbox" id="chck19"/>
                                    <label className="mobi-tab-label" for="chck19">View Features</label>
                                <div className="mobi-tab-content">
                                <div className="price-second-half">
                                
                                <div className="featurebox wordpress-featurebox">
                                    
                                    
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>1 x Android & iOS app</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Unlimited downloads</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No commission on sales</p>
                                    </div>    
                                </div>
                                    
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No website required</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>In-app browser</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Customizable design</p>
                                    </div>    
                                </div>
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Push notifications</p>
                                    </div>    
                                </div>
                                    
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Monetization features</p>
                                    </div>    
                                </div>
                                    
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Team collaboration</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Accelerated app builds</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No AppMySite branding</p>
                                </div>    
                            </div>
                            
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>User consent</p>
                                    </div>    
                                </div>
                                    
                                </div>    
                                
                                <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                                
                                <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                                
                                </div>
                                    </div>
                                </div>
                                    
                            </div>
                        : null
                        }
                         {
                            (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'premium' ) ||
                            (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'premium' ) ||
                            (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'premium' ) 
                        ?
                            <div className="pricebox">
                                
                                <div className="price-first-half">
                                
                                <div className="bordergreen"></div>
                                
                                <div className="allflex">
                                    
                                    <h2>Premium</h2>
                                    
                                    <div className="platforms">
                                    <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                    
                                    <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                    
                                    <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                                </div>
                                    
                                </div> 
                                
                                {this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0] !== null ?
                            <>
                            {this.state.customer_billing_type !== 3 ?
                           <h4>
                           {
                             this.state.newlifetimedata[0].plan == 'premium' ?
                             this.state.newlifetimedata[0].package_currency.currency_symbol +
                             this.state.newlifetimedata[0].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[1].plan == 'premium' ?
                             this.state.newlifetimedata[1].package_currency.currency_symbol +
                             this.state.newlifetimedata[1].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[2].plan == 'premium' ?
                             this.state.newlifetimedata[2].package_currency.currency_symbol +
                             this.state.newlifetimedata[2].amount.replace(".00", " ")
                            :
                             '$ 79'
                           }
                           </h4>
                         :
                         <>
                         {
                           this.state.newlifetimedata[0].plan == 'premium' ?
                           ReactHtmlParser(this.state.newlifetimedata[0].paddleprice)
                          :
                           this.state.newlifetimedata[1].plan == 'premium' ?
                           ReactHtmlParser(this.state.newlifetimedata[1].paddleprice)
                          :
                           this.state.newlifetimedata[2].plan == 'premium' ?
                           ReactHtmlParser(this.state.newlifetimedata[2].paddleprice)
                          :
                           '$ 499'
                         }
                         </>
                        }
                                <h5>one time payment</h5>
                            
                                {
                                (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'premium' && this.state.newlifetimedata[0].is_subscribed === 1) ||
                                (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'premium' && this.state.newlifetimedata[1].is_subscribed === 1) ||
                                (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'premium' && this.state.newlifetimedata[2].is_subscribed === 1) 
                                ? 
                                    (
                                        <Link to="#" className="currentplan">
                                            Current
                                        </Link>
                                    ) 
                                :   (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'premium' && this.state.newlifetimedata[0].show_plan === 0) ||
                                    (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'premium' && this.state.newlifetimedata[1].show_plan === 0) ||
                                    (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'premium' && this.state.newlifetimedata[2].show_plan === 0) 
                                ? 
                                    (
                                        <Link to="#" className="lowerplan">
                                           Unavailable
                                        </Link>
                                    ) 
                                    :   (
                                        <Link to=
                                            {
                                                this.state.customer_type === 4
                                                ? "#"
                                                : this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'premium' ?
                                                this.dlink(
                                                    this.state.newlifetimedata[0].plan_code,
                                                    parseInt(this.state.newlifetimedata[0].amount),
                                                    this.state.newlifetimedata[0].plan_name,
                                                    this.state.newlifetimedata[0].package_currency.currency_symbol,
                                                    this.state.newlifetimedata[0].plan_code
                                                )
                                                : this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'premium' ?
                                                this.dlink(
                                                    this.state.newlifetimedata[1].plan_code,
                                                    parseInt(this.state.newlifetimedata[1].amount),
                                                    this.state.newlifetimedata[1].plan_name,
                                                    this.state.newlifetimedata[1].package_currency.currency_symbol,
                                                    this.state.newlifetimedata[1].plan_code
                                                )
                                                : this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'premium' ?
                                                this.dlink(
                                                    this.state.newlifetimedata[2].plan_code,
                                                    parseInt(this.state.newlifetimedata[2].amount),
                                                    this.state.newlifetimedata[2].plan_name,
                                                    this.state.newlifetimedata[2].package_currency.currency_symbol,
                                                    this.state.newlifetimedata[2].plan_code
                                                )
                                                : this.state.newlifetimedata[2] !== undefined ?
                                                this.dlink(
                                                    this.state.newlifetimedata[2].plan_code,
                                                    parseInt(this.state.newlifetimedata[2].amount),
                                                    this.state.newlifetimedata[2].plan_name,
                                                    this.state.newlifetimedata[2].package_currency.currency_symbol,
                                                    this.state.newlifetimedata[2].plan_code
                                                )
                                                : null
                                            }
                                            onClick={ 
                                                this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'premium' ?
                                                (e) => this.handleWooCustomer(e,
                                                this.state.customer_type,
                                                this.state.newlifetimedata[0].plan_code,
                                                2)
                                                :this.state.newlifetimedata[1] !== undefined &&  this.state.newlifetimedata[1].plan == 'premium' ?
                                                (e) => this.handleWooCustomer(e,
                                                this.state.customer_type,
                                                this.state.newlifetimedata[1].plan_code,
                                                2)
                                                : this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'premium' ?
                                                (e) => this.handleWooCustomer(e,
                                                this.state.customer_type,
                                                this.state.newlifetimedata[2].plan_code,
                                                2)
                                                : this.state.newlifetimedata[2] !== undefined ?
                                                (e) => this.handleWooCustomer(e,
                                                this.state.customer_type,
                                                this.state.newlifetimedata[2].plan_code,
                                                2)
                                                : null
                                            }
        
                                        >
                                        Select
                                    </Link>
                                    )}
                            </>
                            : null
                            } 

<div className="pricing-blank-two"></div>
                                
                                </div>    
                                    
                                <div className="mobi-tab">
                                    <input type="checkbox" id="chck20"/>
                                    <label className="mobi-tab-label" for="chck20">View Features</label>
                                <div className="mobi-tab-content">
                                <div className="price-second-half">
                                
                                <div className="featurebox wordpress-featurebox">
                                    
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>1 x Android & iOS app</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Unlimited downloads</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No commission on sales</p>
                                    </div>    
                                </div>
                                    
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No website required</p>
                                    </div>    
                                </div>
                                
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>In-app browser</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Customizable design</p>
                                    </div>    
                                </div>
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                    
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Advanced Push notifications</p>
                                    </div>    
                                </div>
                                    
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Monetization features</p>
                                    </div>    
                                </div>
                                    
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Team collaboration</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Accelerated app builds</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div> 

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Merge apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Language selection</p>
                                </div>    
                            </div>

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Offline mode</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>App analytics</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Automated distribution</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Business support</p>
                                    </div>    
                                </div>

                                    
                                </div>    
                                
                                <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                                
                                <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                                    
                                </div>
                                    </div>
                                </div>
                                
                            </div>
                        : null
                        }
                        
                    </div>
                          
                </div>   
                   
            </div>
            
            <div className="enterprise-solution">

                <div>
                    <h3>Need help choosing the right plan for you?</h3>
                    <p>Feel free to reach out to us with any questions about our plans.</p>
                </div>
                <a href="/get-in-touch/">Get in touch</a>
            </div>
            
            <div className="enterprise-solution-mobile">

                <div>
                    <img src={ require("../../assets/images/pricing/enterprise.png") .default }alt="enterprise"/>   
                    <h3>Need help choosing the right plan for you?</h3>
                </div>
                <p>Feel free to reach out to us with any questions about our plans.</p>
                <a href="/get-in-touch/">Get in touch</a>
            </div>
            
            <div className="agencylink">
                <a className="webshow" href="#seecomparison">See full comparison <img src={ require("../../assets/images/pricing/more-arrow.png") .default }alt="arrow"/> </a>
                <a className="mobileshow" href="#seecomparisonmobile">See full comparison <img src={ require("../../assets/images/pricing/more-arrow.png") .default }alt="arrow"/></a>
            </div>    
        </div>  
          </section>


          <section className="paddingtop100 paddingbottom120 webcomparison" id="seecomparison">
        
        <div className="container">
            <h3 className="sectionheadings text-center">Compare Plans</h3>
            <h6 className="subheadings1 subheadingtop text-center">Compare our offering, plans and pricing. Pick one that suits you the best.</h6>
            
            <div className="plandetail">
                
                <div className="allflex">
                    <div className="pricemajor">

                    </div>

                    <div className="priceminor">
                        <h5>Free</h5>
                    </div>

                    <div className="priceminor">
                        <h5>Starter</h5>
                    </div>

                    <div className="priceminor">
                        <h5>Pro</h5>
                    </div>

                    <div className="priceminor">
                        <h5>Premium</h5>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android{" "}
                        <Tooltipicon message={'Build an app that works on Android devices.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS{" "}
                        <Tooltipicon message={'Build an app that works on iOS devices.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play{" "}
                        <Tooltipicon message={'Publish your app on the Google Play Store.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                    <p>Apple App Store{" "}
                    <Tooltipicon message={'Publish your app on the Apple App Store.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>



                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds{" "}
                        <Tooltipicon message={'Rebuild your app as many times as needed.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing{" "}
                        <Tooltipicon message={'No queues, no wait time— builds are processed instantly.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation{" "}
                        <Tooltipicon message={'Enjoy quicker builds with enhanced server resources.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>




                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads{" "}
                        <Tooltipicon message={'Allow unlimited app downloads from the app stores.'}/></p>
                    </div>

                    <div className="priceminor">
                        <p>1,000 downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users{" "}
                        <Tooltipicon message={'Allow unlimited users to download the app from the app stores.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications{" "}
                        <Tooltipicon message={'Send unlimited push notifications to app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views{" "}
                        <Tooltipicon message={'Your app has the ability to process unlimited page views.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages{" "}
                        <Tooltipicon message={'Add and manage unlimited pages within your content app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts{" "}
                        <Tooltipicon message={'Add and manage unlimited posts within your blog or listing app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync{" "}
                        <Tooltipicon message={'App data remains in perfect sync with your data source.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage{" "}
                        <Tooltipicon message={'Add unlimited content to your app without any storage restrictions.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                        




                <div className="pricesechead">
                    <h6>Content builder</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages{" "}
                        <Tooltipicon message={'Create, edit, and manage unlimited pages for your app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts{" "}
                        <Tooltipicon message={'Create, edit, and manage unlimited blog posts for your app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories{" "}
                        <Tooltipicon message={'Organize content by categories for easy navigation and better user experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags{" "}
                        <Tooltipicon message={'Create tags for your content for seamless search and discoverability.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Attributes{" "}
                        <Tooltipicon message={'Define key characteristics of posts for detailed classification.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain{" "}
                        <Tooltipicon message={'Link any website to the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page{" "}
                        <Tooltipicon message={'Link any web page within the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Override CSS{" "}
                        <Tooltipicon message={'Override your website’s CSS to customize mobile web views.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support{" "}
                        <Tooltipicon message={'Manage different media types within the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management{" "}
                        <Tooltipicon message={'Maintain sessions to ensure a smooth user experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management{" "}
                        <Tooltipicon message={'Keep users logged in with cookie management.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom scripts{" "}
                        <Tooltipicon message={'Embed custom scripts to enhance the app’s functionality.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login{" "}
                        <Tooltipicon message={'Allow users to remain logged into the app until they log out.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>





                <div className="pricesechead">
                                    <h6>User access</h6>
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                    <p>Access management</p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Guest browsing{" "}
                                        <Tooltipicon message={'Allow users to browse the app without logging in.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                                </div>
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Login with email{" "}
                                        <Tooltipicon message={'Enable users to log in with their email.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Login with Google{" "}
                                        <Tooltipicon message={'Enable users to log in with their Gmail account.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                                </div>
                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Login with Apple{" "}
                                        <Tooltipicon message={'Enable users to log in with their Apple account.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                                </div>
                
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                    <p>User consent{" "}
                                    <Tooltipicon message={'Add a consent screen and personalize it to meet compliance needs.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                                </div>

                




                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions{" "}
                        <Tooltipicon message={'Assign roles and set permissions for better team management.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                    <p>Team collaboration{" "}
                    <Tooltipicon message={'Foster effective collaboration among team members.'}/></p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                    <div className="priceminor">
                        <p>3 users</p>
                    </div>

                    <div className="priceminor">
                        <p>5 users</p>
                    </div>
                </div>
                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                {/* <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div> */}
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer{" "}
                        <Tooltipicon message={'Design a unique icon that represents the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer{" "}
                        <Tooltipicon message={'Create a custom launch screen for a branded user experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login screen designer{" "}
                        <Tooltipicon message={'Design and personalise your login screen to deliver a consistent brand experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                {/* <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div> */}

                <div className="allflex">
                    <div className="pricemajor">
                    <p>No AppMySite branding{" "}
                    <Tooltipicon message={'Remove AppMySite branding for a fully white-labeled app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                
                <div className="pricesechead">
                    <h6>Basic notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications{" "}
                        <Tooltipicon message={'Send unlimited notifications to app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich notifications{" "}
                        <Tooltipicon message={'Enhance notifications with multimedia.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling{" "}
                        <Tooltipicon message={'Schedule notifications to be sent at a later date and time.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting{" "}
                        <Tooltipicon message={'Target Android and iOS customers separately as needed.'}/></p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Deep links{" "}
                        <Tooltipicon message={'Embed deep links within notifications to direct users to specific app screens.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="pricesechead">
                    <h6>Advanced notifications</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications{" "}
                        <Tooltipicon message={'Send unlimited notifications to app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich notifications{" "}
                        <Tooltipicon message={'Enhance notifications with multimedia.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling{" "}
                        <Tooltipicon message={'Schedule notifications to be sent at a later date and time.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting{" "}
                        <Tooltipicon message={'Target Android and iOS customers separately as needed.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Deep links{" "}
                        <Tooltipicon message={'Embed deep links within notifications to direct users to specific app screens.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User groups{" "}
                        <Tooltipicon message={'Create target groups based on usage patterns and demographics.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User insights{" "}
                        <Tooltipicon message={'Access key usage details including activity, sessions, device information and more.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics{" "}
                        <Tooltipicon message={'Analyze user interactions to gain insights into usage patterns.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics{" "}
                        <Tooltipicon message={'Access in-depth app usage data and drive growth through targeted improvements.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>


                
                


                

                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Sales commission{" "}</p>
                    </div>

                    <div className="priceminor">
                        <p>0%</p>
                    </div>

                    <div className="priceminor">
                        <p>0%</p>
                    </div>

                    <div className="priceminor">
                        <p>0%</p>
                    </div>

                    <div className="priceminor">
                        <p>0%</p>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads{" "}
                        <Tooltipicon message={'Monetize the app with in-app advertisements.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Go ad-free{" "}
                        <Tooltipicon message={'Give your users an ad-free browsing experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                

                {/* <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div> */}






                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App visualizer{" "}
                        <Tooltipicon message={'Give your users an ad-free browsing experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps{" "}
                        <Tooltipicon message={'Combine multiple apps into a single powerful app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                    <p>Offline mode{" "}
                    <Tooltipicon message={'Allow users to access your app offline, even without an active internet connection.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support{" "}
                        <Tooltipicon message={'Enable RTL layout for languages with right-to-left scripts like Arabic and Hebrew.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat{" "}
                        <Tooltipicon message={'Offer real-time chat support to your app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads{" "}
                        <Tooltipicon message={'Track your app’s growth by monitoring download insights.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics{" "}
                        <Tooltipicon message={'Get insights into app usage trends for both Android and iOS devices.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics{" "}
                        <Tooltipicon message={'Identify top-performing content based on app usage trends.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
               
                
                
                
                <div className="pricesechead">
                                                    <h6>Distribution</h6>
                                                </div>
                                                
                                                <div className="allflex">
                                                    <div className="pricemajor">
                                                        <p>Upload to Apple Store{" "}
                                                        <Tooltipicon message={'Instantly submit your app to the Apple Store.'}/></p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <p>Manual</p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <p>Manual</p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <p>Manual</p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <p>Automated</p>
                                                    </div>
                                                </div>
                                                
                                                <div className="allflex">
                                                    <div className="pricemajor">
                                                        <p>Upload to Google Play{" "}
                                                        <Tooltipicon message={'Instantly submit your app to Google Play.'}/></p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <p>Manual</p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <p>Manual</p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <p>Manual</p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <p>Coming soon</p>
                                                    </div>
                                                </div>
                                                
                                                
                                                <div className="pricesechead">
                                                    <h6>Support</h6>
                                                </div>
                                                
                                                <div className="allflex">
                                                    <div className="pricemajor">
                                                        <p>Community{" "}
                                                        <Tooltipicon message={'Exchange insights and share experiences with other app builders.'}/></p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                    </div>
                                                </div>
                                                
                                                <div className="allflex">
                                                    <div className="pricemajor">
                                                        <p>Knowledge base{" "}
                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                    </div>
                                                </div>
                                
                                                <div className="allflex">
                                                    <div className="pricemajor">
                                                        <p>Email{" "}
                                                        <Tooltipicon message={'Receive personalized assistance from our support team via email.'}/></p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                    </div>
                                                </div>
                                
                                                <div className="allflex">
                                                    <div className="pricemajor">
                                                        <p>Live chat{" "}
                                                        <Tooltipicon message={'Get real-time support and instant resolutions through live chat.'}/></p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <p>Add-on</p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <p>Add-on</p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <p>Add-on</p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                    </div>
                                                </div>
                                
                                                <div className="allflex">
                                                    <div className="pricemajor">
                                                        <p>Phone{" "}
                                                        <Tooltipicon message={'Connect directly with our support team for prompt and personalized recommendations.'}/></p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <p>Add-on</p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <p>Add-on</p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <p>Add-on</p>
                                                    </div>
                                
                                                    <div className="priceminor">
                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                    </div>
                                                </div>

                

                
                
            </div>
            
        </div>
            </section>  
  
          <section className="mobilecomparison" id="seecomparisonmobile">
        <div className="container">
            <div className="tabbed">
                    <input type="radio" id="tab31" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('preview')}} defaultChecked={this.state.checkedmobtab == 'preview' ? true : '' }/>
                    <input type="radio" id="tab32" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('starter')}} defaultChecked={this.state.checkedmobtab == 'starter' ? true : '' } />
                    <input type="radio" id="tab33" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('pro')}} defaultChecked={this.state.checkedmobtab == 'pro' ? true : '' }/>
                    <input type="radio" id="tab34" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('premium')}} defaultChecked={this.state.checkedmobtab == 'premium' ? true : '' } />
                
                <ul className="tabs">
                    <li className="tab"><label for="tab31">Free</label></li>
                    <li className="tab"><label for="tab32">Starter</label></li>
                    <li className="tab"><label for="tab33">Pro</label></li>
                    <li className="tab"><label for="tab34">Premium</label></li>
                </ul>
                
                <div className={"tab-content " + (this.state.checkedmobtab === 'preview' ? '' : 'd-none')}>
                    
                <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android{" "}
                        <Tooltipicon message={'Build an app that works on Android devices.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS{" "}
                        <Tooltipicon message={'Build an app that works on iOS devices.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play{" "}
                        <Tooltipicon message={'Publish your app on the Google Play Store.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                    <p>Apple App Store{" "}
                    <Tooltipicon message={'Publish your app on the Apple App Store.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds{" "}
                        <Tooltipicon message={'Rebuild your app as many times as needed.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing{" "}
                        <Tooltipicon message={'No queues, no wait time— builds are processed instantly.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation{" "}
                        <Tooltipicon message={'Enjoy quicker builds with enhanced server resources.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads{" "}
                        <Tooltipicon message={'Allow unlimited app downloads from the app stores.'}/></p>
                    </div>

                    <div className="priceminor">
                        <p>1,000 downloads</p>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users{" "}
                        <Tooltipicon message={'Allow unlimited users to download the app from the app stores.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications{" "}
                        <Tooltipicon message={'Send unlimited push notifications to app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views{" "}
                        <Tooltipicon message={'Your app has the ability to process unlimited page views.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages{" "}
                        <Tooltipicon message={'Add and manage unlimited pages within your content app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts{" "}
                        <Tooltipicon message={'Add and manage unlimited posts within your blog or listing app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync{" "}
                        <Tooltipicon message={'App data remains in perfect sync with your data source.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage{" "}
                        <Tooltipicon message={'Add unlimited content to your app without any storage restrictions.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>








                <div className="pricesechead">
                    <h6>Custom content</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages{" "}
                        <Tooltipicon message={'Create, edit, and manage unlimited pages for your app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts{" "}
                        <Tooltipicon message={'Create, edit, and manage unlimited blog posts for your app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories{" "}
                        <Tooltipicon message={'Organize content by categories for easy navigation and better user experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags{" "}
                        <Tooltipicon message={'Create tags for your content for seamless search and discoverability.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Attributes{" "}
                        <Tooltipicon message={'Define key characteristics of posts for detailed classification.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain{" "}
                        <Tooltipicon message={'Link any website to the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page{" "}
                        <Tooltipicon message={'Link any web page within the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Override CSS{" "}
                        <Tooltipicon message={'Override your website’s CSS to customize mobile web views.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support{" "}
                        <Tooltipicon message={'Manage different media types within the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management{" "}
                        <Tooltipicon message={'Maintain sessions to ensure a smooth user experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management{" "}
                        <Tooltipicon message={'Keep users logged in with cookie management.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom scripts{" "}
                        <Tooltipicon message={'Embed custom scripts to enhance the app’s functionality.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login{" "}
                        <Tooltipicon message={'Allow users to remain logged into the app until they log out.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>




                <div className="pricesechead">
                                    <h6>User access</h6>
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Access management</p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Guest browsing{" "}
                                        <Tooltipicon message={'Allow users to browse the app without logging in.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Login with email{" "}
                                        <Tooltipicon message={'Enable users to log in with their email.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Login with Google{" "}
                                        <Tooltipicon message={'Enable users to log in with their Gmail account.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Login with Apple{" "}
                                        <Tooltipicon message={'Enable users to log in with their Apple account.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                                    </div>
                
                                </div>

                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>User consent{" "}
                                        <Tooltipicon message={'Add a consent screen and personalize it to meet compliance needs.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                                    </div>
                
                                </div>
                
                


                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions{" "}
                        <Tooltipicon message={'Assign roles and set permissions for better team management.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                    <p>Team collaboration{" "}
                    <Tooltipicon message={'Foster effective collaboration among team members.'}/></p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                </div>
                



                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                {/* <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div> */}
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer{" "}
                        <Tooltipicon message={'Design a unique icon that represents the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer{" "}
                        <Tooltipicon message={'Create a custom launch screen for a branded user experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login screen designer{" "}
                        <Tooltipicon message={'Design and personalise your login screen to deliver a consistent brand experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                {/* <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div> */}
                
                <div className="allflex">
                    <div className="pricemajor">
                    <p>No AppMySite branding{" "}
                    <Tooltipicon message={'Remove AppMySite branding for a fully white-labeled app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>




                
                
                
                <div className="pricesechead">
                    <h6>Basic notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications{" "}
                        <Tooltipicon message={'Send unlimited notifications to app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich notifications{" "}
                        <Tooltipicon message={'Enhance notifications with multimedia.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling{" "}
                        <Tooltipicon message={'Schedule notifications to be sent at a later date and time.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting{" "}
                        <Tooltipicon message={'Target Android and iOS customers separately as needed.'}/></p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Deep links{" "}
                        <Tooltipicon message={'Embed deep links within notifications to direct users to specific app screens.'}/></p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="pricesechead">
                    <h6>Advanced notifications</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications{" "}
                        <Tooltipicon message={'Send unlimited notifications to app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich notifications{" "}
                        <Tooltipicon message={'Enhance notifications with multimedia.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling{" "}
                        <Tooltipicon message={'Schedule notifications to be sent at a later date and time.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting{" "}
                        <Tooltipicon message={'Target Android and iOS customers separately as needed.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Deep links{" "}
                        <Tooltipicon message={'Embed deep links within notifications to direct users to specific app screens.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User groups{" "}
                        <Tooltipicon message={'Create target groups based on usage patterns and demographics.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User insights{" "}
                        <Tooltipicon message={'Access key usage details including activity, sessions, device information and more.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics{" "}
                        <Tooltipicon message={'Analyze user interactions to gain insights into usage patterns.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics{" "}
                        <Tooltipicon message={'Access in-depth app usage data and drive growth through targeted improvements.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                

                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Sales commission{" "}</p>
                    </div>

                    <div className="priceminor">
                        <p>0%</p>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads{" "}
                        <Tooltipicon message={'Monetize the app with in-app advertisements.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Go ad-free{" "}
                        <Tooltipicon message={'Give your users an ad-free browsing experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                {/* <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div> */}



                
                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App visualizer{" "}
                        <Tooltipicon message={'Give your users an ad-free browsing experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps{" "}
                        <Tooltipicon message={'Combine multiple apps into a single powerful app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                    <p>Offline mode{" "}
                    <Tooltipicon message={'Allow users to access your app offline, even without an active internet connection.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support{" "}
                        <Tooltipicon message={'Enable RTL layout for languages with right-to-left scripts like Arabic and Hebrew.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat{" "}
                        <Tooltipicon message={'Offer real-time chat support to your app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>





                 
                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads{" "}
                        <Tooltipicon message={'Track your app’s growth by monitoring download insights.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics{" "}
                        <Tooltipicon message={'Get insights into app usage trends for both Android and iOS devices.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics{" "}
                        <Tooltipicon message={'Identify top-performing content based on app usage trends.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

               

                
                
                
                <div className="pricesechead">
                                                                    <h6>Distribution</h6>
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Upload to Apple Store{" "}
                                                                        <Tooltipicon message={'Instantly submit your app to the Apple Store.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <p>Manual</p>
                                                                    </div>
                                                
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Upload to Google Play{" "}
                                                                        <Tooltipicon message={'Instantly submit your app to Google Play.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <p>Manual</p>
                                                                    </div>
                                                
                                                                </div>
                                                                
                                                                
                                                                <div className="pricesechead">
                                                                    <h6>Support</h6>
                                                                </div>
                                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Community{" "}
                                                                        <Tooltipicon message={'Exchange insights and share experiences with other app builders.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                                    </div>
                                                
                                                                </div>
                                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Knowledge base{" "}
                                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                                    </div>
                                                
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Email{" "}
                                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                                    </div>
                                                
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Live chat{" "}
                                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <p>Add-on</p>
                                                                    </div>
                                                
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Phone{" "}
                                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <p>Add-on</p>
                                                                    </div>
                                                
                                                                </div>

                


                
                
                
            </div>
                    
                </div>
                
                <div className={"tab-content " + (this.state.checkedmobtab === 'starter' ? '' : 'd-none')}>
                    
                <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android{" "}
                        <Tooltipicon message={'Build an app that works on Android devices.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS{" "}
                        <Tooltipicon message={'Build an app that works on iOS devices.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play{" "}
                        <Tooltipicon message={'Publish your app on the Google Play Store.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                    <p>Apple App Store{" "}
                    <Tooltipicon message={'Publish your app on the Apple App Store.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>




                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds{" "}
                        <Tooltipicon message={'Rebuild your app as many times as needed.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing{" "}
                        <Tooltipicon message={'No queues, no wait time— builds are processed instantly.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation{" "}
                        <Tooltipicon message={'Enjoy quicker builds with enhanced server resources.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads{" "}
                        <Tooltipicon message={'Allow unlimited app downloads from the app stores.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users{" "}
                        <Tooltipicon message={'Allow unlimited users to download the app from the app stores.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views{" "}
                        <Tooltipicon message={'Your app has the ability to process unlimited page views.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications{" "}
                        <Tooltipicon message={'Send unlimited push notifications to app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages{" "}
                        <Tooltipicon message={'Add and manage unlimited pages within your content app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts{" "}
                        <Tooltipicon message={'Add and manage unlimited posts within your blog or listing app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync{" "}
                        <Tooltipicon message={'App data remains in perfect sync with your data source.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage{" "}
                        <Tooltipicon message={'Add unlimited content to your app without any storage restrictions.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>






                <div className="pricesechead">
                    <h6>Custom content</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages{" "}
                        <Tooltipicon message={'Create, edit, and manage unlimited pages for your app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts{" "}
                        <Tooltipicon message={'Create, edit, and manage unlimited blog posts for your app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories{" "}
                        <Tooltipicon message={'Organize content by categories for easy navigation and better user experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags{" "}
                        <Tooltipicon message={'Create tags for your content for seamless search and discoverability.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Attributes{" "}
                        <Tooltipicon message={'Define key characteristics of posts for detailed classification.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain{" "}
                        <Tooltipicon message={'Link any website to the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page{" "}
                        <Tooltipicon message={'Link any web page within the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Override CSS{" "}
                        <Tooltipicon message={'Override your website’s CSS to customize mobile web views.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support{" "}
                        <Tooltipicon message={'Manage different media types within the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management{" "}
                        <Tooltipicon message={'Maintain sessions to ensure a smooth user experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management{" "}
                        <Tooltipicon message={'Keep users logged in with cookie management.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom scripts{" "}
                        <Tooltipicon message={'Embed custom scripts to enhance the app’s functionality.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login{" "}
                        <Tooltipicon message={'Allow users to remain logged into the app until they log out.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>



                
                <div className="pricesechead">
                                    <h6>User access</h6>
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Access management</p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Guest browsing{" "}
                                        <Tooltipicon message={'Allow users to browse the app without logging in.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Login with email{" "}
                                        <Tooltipicon message={'Enable users to log in with their email.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Login with Google{" "}
                                        <Tooltipicon message={'Enable users to log in with their Gmail account.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Login with Apple{" "}
                                        <Tooltipicon message={'Enable users to log in with their Apple account.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                                    </div>
                
                                </div>

                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>User consent{" "}
                                        <Tooltipicon message={'Add a consent screen and personalize it to meet compliance needs.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                                    </div>
                
                                </div>
                
                

                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions{" "}
                        <Tooltipicon message={'Assign roles and set permissions for better team management.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                    <p>Team collaboration{" "}
                    <Tooltipicon message={'Foster effective collaboration among team members.'}/></p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                </div>




                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                {/* <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div> */}
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer{" "}
                        <Tooltipicon message={'Design a unique icon that represents the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer{" "}
                        <Tooltipicon message={'Create a custom launch screen for a branded user experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login screen designer{" "}
                        <Tooltipicon message={'Design and personalise your login screen to deliver a consistent brand experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                {/* <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div> */}
                
                <div className="allflex">
                    <div className="pricemajor">
                    <p>No AppMySite branding{" "}
                    <Tooltipicon message={'Remove AppMySite branding for a fully white-labeled app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                

                
                
                
                <div className="pricesechead">
                    <h6>Basic notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications{" "}
                        <Tooltipicon message={'Send unlimited notifications to app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich notifications{" "}
                        <Tooltipicon message={'Enhance notifications with multimedia.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling{" "}
                        <Tooltipicon message={'Schedule notifications to be sent at a later date and time.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting{" "}
                        <Tooltipicon message={'Target Android and iOS customers separately as needed.'}/></p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Deep links{" "}
                        <Tooltipicon message={'Embed deep links within notifications to direct users to specific app screens.'}/></p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="pricesechead">
                    <h6>Advanced notifications</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications{" "}
                        <Tooltipicon message={'Send unlimited notifications to app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich notifications{" "}
                        <Tooltipicon message={'Enhance notifications with multimedia.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling{" "}
                        <Tooltipicon message={'Schedule notifications to be sent at a later date and time.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting{" "}
                        <Tooltipicon message={'Target Android and iOS customers separately as needed.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Deep links{" "}
                        <Tooltipicon message={'Embed deep links within notifications to direct users to specific app screens.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User groups{" "}
                        <Tooltipicon message={'Create target groups based on usage patterns and demographics.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User insights{" "}
                        <Tooltipicon message={'Access key usage details including activity, sessions, device information and more.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics{" "}
                        <Tooltipicon message={'Analyze user interactions to gain insights into usage patterns.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics{" "}
                        <Tooltipicon message={'Access in-depth app usage data and drive growth through targeted improvements.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>






                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Sales commission{" "}</p>
                    </div>

                    <div className="priceminor">
                        <p>0%</p>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads{" "}
                        <Tooltipicon message={'Monetize the app with in-app advertisements.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Go ad-free{" "}
                        <Tooltipicon message={'Give your users an ad-free browsing experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                {/* <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div> */}



                

                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App visualizer{" "}
                        <Tooltipicon message={'Give your users an ad-free browsing experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps{" "}
                        <Tooltipicon message={'Combine multiple apps into a single powerful app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                    <p>Offline mode{" "}
                    <Tooltipicon message={'Allow users to access your app offline, even without an active internet connection.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support{" "}
                        <Tooltipicon message={'Enable RTL layout for languages with right-to-left scripts like Arabic and Hebrew.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat{" "}
                        <Tooltipicon message={'Offer real-time chat support to your app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>





                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads{" "}
                        <Tooltipicon message={'Track your app’s growth by monitoring download insights.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics{" "}
                        <Tooltipicon message={'Get insights into app usage trends for both Android and iOS devices.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics{" "}
                        <Tooltipicon message={'Identify top-performing content based on app usage trends.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>





                
                
                
                <div className="pricesechead">
                                                                    <h6>Distribution</h6>
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Upload to Apple Store{" "}
                                                                        <Tooltipicon message={'Instantly submit your app to the Apple Store.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <p>Manual</p>
                                                                    </div>
                                                
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Upload to Google Play{" "}
                                                                        <Tooltipicon message={'Instantly submit your app to Google Play.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <p>Manual</p>
                                                                    </div>
                                                
                                                                </div>
                                                            
                                                            
                                                                
                                                                
                                                                <div className="pricesechead">
                                                                    <h6>Support</h6>
                                                                </div>
                                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Community{" "}
                                                                        <Tooltipicon message={'Exchange insights and share experiences with other app builders.'}/></p>
                                                                    </div>
                                                            
                                                                    <div className="priceminor">
                                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                                    </div>
                                                            
                                                                </div>
                                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Knowledge base{" "}
                                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                                    </div>
                                                            
                                                                    <div className="priceminor">
                                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                                    </div>
                                                            
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Email{" "}
                                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                                    </div>
                                                
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Live chat{" "}
                                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <p>Add-on</p>
                                                                    </div>
                                                
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Phone{" "}
                                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <p>Add-on</p>
                                                                    </div>
                                                
                                                                </div>

                


                
                
            </div>
                    
                </div>
                
                <div className={"tab-content " + (this.state.checkedmobtab === 'pro' ? '' : 'd-none')}>
                    
                <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android{" "}
                        <Tooltipicon message={'Build an app that works on Android devices.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS{" "}
                        <Tooltipicon message={'Build an app that works on iOS devices.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play{" "}
                        <Tooltipicon message={'Publish your app on the Google Play Store.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                    <p>Apple App Store{" "}
                    <Tooltipicon message={'Publish your app on the Apple App Store.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds{" "}
                        <Tooltipicon message={'Rebuild your app as many times as needed.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing{" "}
                        <Tooltipicon message={'No queues, no wait time— builds are processed instantly.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation{" "}
                        <Tooltipicon message={'Enjoy quicker builds with enhanced server resources.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads{" "}
                        <Tooltipicon message={'Allow unlimited app downloads from the app stores.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users{" "}
                        <Tooltipicon message={'Allow unlimited users to download the app from the app stores.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views{" "}
                        <Tooltipicon message={'Your app has the ability to process unlimited page views.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications{" "}
                        <Tooltipicon message={'Send unlimited push notifications to app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages{" "}
                        <Tooltipicon message={'Add and manage unlimited pages within your content app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts{" "}
                        <Tooltipicon message={'Add and manage unlimited posts within your blog or listing app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync{" "}
                        <Tooltipicon message={'App data remains in perfect sync with your data source.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage{" "}
                        <Tooltipicon message={'Add unlimited content to your app without any storage restrictions.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>




                <div className="pricesechead">
                    <h6>Custom content</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages{" "}
                        <Tooltipicon message={'Create, edit, and manage unlimited pages for your app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts{" "}
                        <Tooltipicon message={'Create, edit, and manage unlimited blog posts for your app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories{" "}
                        <Tooltipicon message={'Organize content by categories for easy navigation and better user experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags{" "}
                        <Tooltipicon message={'Create tags for your content for seamless search and discoverability.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Attributes{" "}
                        <Tooltipicon message={'Define key characteristics of posts for detailed classification.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>


                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain{" "}
                        <Tooltipicon message={'Link any website to the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page{" "}
                        <Tooltipicon message={'Link any web page within the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Override CSS{" "}
                        <Tooltipicon message={'Override your website’s CSS to customize mobile web views.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support{" "}
                        <Tooltipicon message={'Manage different media types within the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management{" "}
                        <Tooltipicon message={'Maintain sessions to ensure a smooth user experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management{" "}
                        <Tooltipicon message={'Keep users logged in with cookie management.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom scripts{" "}
                        <Tooltipicon message={'Embed custom scripts to enhance the app’s functionality.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login{" "}
                        <Tooltipicon message={'Allow users to remain logged into the app until they log out.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>





                <div className="pricesechead">
                                    <h6>User access</h6>
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Access management</p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Guest browsing{" "}
                                        <Tooltipicon message={'Allow users to browse the app without logging in.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Login with email{" "}
                                        <Tooltipicon message={'Enable users to log in with their email.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Login with Google{" "}
                                        <Tooltipicon message={'Enable users to log in with their Gmail account.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Login with Apple{" "}
                                        <Tooltipicon message={'Enable users to log in with their Apple account.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>

                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>User consent{" "}
                                        <Tooltipicon message={'Add a consent screen and personalize it to meet compliance needs.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>



                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions{" "}
                        <Tooltipicon message={'Assign roles and set permissions for better team management.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                    <p>Team collaboration{" "}
                    <Tooltipicon message={'Foster effective collaboration among team members.'}/></p>
                    </div>

                    <div className="priceminor">
                        <p>3 users</p>
                    </div>

                </div>



                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                {/* <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div> */}
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer{" "}
                        <Tooltipicon message={'Design a unique icon that represents the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer{" "}
                        <Tooltipicon message={'Create a custom launch screen for a branded user experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login screen designer{" "}
                        <Tooltipicon message={'Design and personalise your login screen to deliver a consistent brand experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                {/* <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div> */}
                
                <div className="allflex">
                    <div className="pricemajor">
                    <p>No AppMySite branding{" "}
                    <Tooltipicon message={'Remove AppMySite branding for a fully white-labeled app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                


                
                
                
                <div className="pricesechead">
                    <h6>Basic notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications{" "}
                        <Tooltipicon message={'Send unlimited notifications to app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich notifications{" "}
                        <Tooltipicon message={'Enhance notifications with multimedia.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling{" "}
                        <Tooltipicon message={'Schedule notifications to be sent at a later date and time.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting{" "}
                        <Tooltipicon message={'Target Android and iOS customers separately as needed.'}/></p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Deep links{" "}
                        <Tooltipicon message={'Embed deep links within notifications to direct users to specific app screens.'}/></p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="pricesechead">
                    <h6>Advanced notifications</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications{" "}
                        <Tooltipicon message={'Send unlimited notifications to app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich notifications{" "}
                        <Tooltipicon message={'Enhance notifications with multimedia.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling{" "}
                        <Tooltipicon message={'Schedule notifications to be sent at a later date and time.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting{" "}
                        <Tooltipicon message={'Target Android and iOS customers separately as needed.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Deep links{" "}
                        <Tooltipicon message={'Embed deep links within notifications to direct users to specific app screens.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User groups{" "}
                        <Tooltipicon message={'Create target groups based on usage patterns and demographics.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User insights{" "}
                        <Tooltipicon message={'Access key usage details including activity, sessions, device information and more.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics{" "}
                        <Tooltipicon message={'Analyze user interactions to gain insights into usage patterns.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics{" "}
                        <Tooltipicon message={'Access in-depth app usage data and drive growth through targeted improvements.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                

                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Sales commission{" "}</p>
                    </div>

                    <div className="priceminor">
                        <p>0%</p>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads{" "}
                        <Tooltipicon message={'Monetize the app with in-app advertisements.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Go ad-free{" "}
                        <Tooltipicon message={'Give your users an ad-free browsing experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                {/* <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div> */}



                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App visualizer{" "}
                        <Tooltipicon message={'Give your users an ad-free browsing experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps{" "}
                        <Tooltipicon message={'Combine multiple apps into a single powerful app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                    <p>Offline mode{" "}
                    <Tooltipicon message={'Allow users to access your app offline, even without an active internet connection.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support{" "}
                        <Tooltipicon message={'Enable RTL layout for languages with right-to-left scripts like Arabic and Hebrew.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat{" "}
                        <Tooltipicon message={'Offer real-time chat support to your app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>







                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads{" "}
                        <Tooltipicon message={'Track your app’s growth by monitoring download insights.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics{" "}
                        <Tooltipicon message={'Get insights into app usage trends for both Android and iOS devices.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics{" "}
                        <Tooltipicon message={'Identify top-performing content based on app usage trends.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                
                
                
                <div className="pricesechead">
                                                                    <h6>Distribution</h6>
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Upload to Apple Store{" "}
                                                                        <Tooltipicon message={'Instantly submit your app to the Apple Store.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <p>Manual</p>
                                                                    </div>
                                                
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Upload to Google Play{" "}
                                                                        <Tooltipicon message={'Instantly submit your app to Google Play.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <p>Manual</p>
                                                                    </div>
                                                
                                                                </div>
                                                                
                                                                
                                                                <div className="pricesechead">
                                                                    <h6>Support</h6>
                                                                </div>
                                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Community{" "}
                                                                        <Tooltipicon message={'Exchange insights and share experiences with other app builders.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                                    </div>
                                                
                                                                </div>
                                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Knowledge base{" "}
                                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                                    </div>
                                                
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Email{" "}
                                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                                    </div>
                                                
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Live chat{" "}
                                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <p>Add-on</p>
                                                                    </div>
                                                
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Phone{" "}
                                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <p>Add-on</p>
                                                                    </div>
                                                
                                                                </div>

                


                
                
                
                
            </div>
                    
                </div>
                
                <div className={"tab-content " + (this.state.checkedmobtab === 'premium' ? '' : 'd-none')}>
                    
                <div className="plandetail">
                
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android{" "}
                        <Tooltipicon message={'Build an app that works on Android devices.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS{" "}
                        <Tooltipicon message={'Build an app that works on iOS devices.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play{" "}
                        <Tooltipicon message={'Publish your app on the Google Play Store.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                    <p>Apple App Store{" "}
                    <Tooltipicon message={'Publish your app on the Apple App Store.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>




                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds{" "}
                        <Tooltipicon message={'Rebuild your app as many times as needed.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing{" "}
                        <Tooltipicon message={'No queues, no wait time— builds are processed instantly.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation{" "}
                        <Tooltipicon message={'Enjoy quicker builds with enhanced server resources.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                


                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads{" "}
                        <Tooltipicon message={'Allow unlimited app downloads from the app stores.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users{" "}
                        <Tooltipicon message={'Allow unlimited users to download the app from the app stores.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views{" "}
                        <Tooltipicon message={'Your app has the ability to process unlimited page views.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications{" "}
                        <Tooltipicon message={'Send unlimited push notifications to app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages{" "}
                        <Tooltipicon message={'Add and manage unlimited pages within your content app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts{" "}
                        <Tooltipicon message={'Add and manage unlimited posts within your blog or listing app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync{" "}
                        <Tooltipicon message={'App data remains in perfect sync with your data source.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage{" "}
                        <Tooltipicon message={'Add unlimited content to your app without any storage restrictions.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>





                <div className="pricesechead">
                    <h6>Custom content</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages{" "}
                        <Tooltipicon message={'Create, edit, and manage unlimited pages for your app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts{" "}
                        <Tooltipicon message={'Create, edit, and manage unlimited blog posts for your app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories{" "}
                        <Tooltipicon message={'Organize content by categories for easy navigation and better user experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags{" "}
                        <Tooltipicon message={'Create tags for your content for seamless search and discoverability.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Attributes{" "}
                        <Tooltipicon message={'Define key characteristics of posts for detailed classification.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain{" "}
                        <Tooltipicon message={'Link any website to the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page{" "}
                        <Tooltipicon message={'Link any web page within the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Override CSS{" "}
                        <Tooltipicon message={'Override your website’s CSS to customize mobile web views.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support{" "}
                        <Tooltipicon message={'Manage different media types within the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management{" "}
                        <Tooltipicon message={'Maintain sessions to ensure a smooth user experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management{" "}
                        <Tooltipicon message={'Keep users logged in with cookie management.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom scripts{" "}
                        <Tooltipicon message={'Embed custom scripts to enhance the app’s functionality.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login{" "}
                        <Tooltipicon message={'Allow users to remain logged into the app until they log out.'}/></p>
                    </div>


                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>



                <div className="pricesechead">
                                    <h6>User access</h6>
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Access management</p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Guest browsing{" "}
                                        <Tooltipicon message={'Allow users to browse the app without logging in.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Login with email{" "}
                                        <Tooltipicon message={'Enable users to log in with their email.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Login with Google{" "}
                                        <Tooltipicon message={'Enable users to log in with their Gmail account.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                                
                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>Login with Apple{" "}
                                        <Tooltipicon message={'Enable users to log in with their Apple account.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>

                                <div className="allflex">
                                    <div className="pricemajor">
                                        <p>User consent{" "}
                                        <Tooltipicon message={'Add a consent screen and personalize it to meet compliance needs.'}/></p>
                                    </div>
                
                                    <div className="priceminor">
                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                    </div>
                
                                </div>
                


                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions{" "}
                        <Tooltipicon message={'Assign roles and set permissions for better team management.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                    <p>Team collaboration{" "}
                    <Tooltipicon message={'Foster effective collaboration among team members.'}/></p>
                    </div>

                    <div className="priceminor">
                        <p>5 users</p>
                    </div>
                </div>
                
                



                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                {/* <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div> */}
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer{" "}
                        <Tooltipicon message={'Design a unique icon that represents the app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer{" "}
                        <Tooltipicon message={'Create a custom launch screen for a branded user experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login screen designer{" "}
                        <Tooltipicon message={'Design and personalise your login screen to deliver a consistent brand experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                {/* <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div> */}
                
                <div className="allflex">
                    <div className="pricemajor">
                    <p>No AppMySite branding{" "}
                    <Tooltipicon message={'Remove AppMySite branding for a fully white-labeled app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                



                
                
                
                <div className="pricesechead">
                    <h6>Basic notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications{" "}
                        <Tooltipicon message={'Send unlimited notifications to app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich notifications{" "}
                        <Tooltipicon message={'Enhance notifications with multimedia.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling{" "}
                        <Tooltipicon message={'Schedule notifications to be sent at a later date and time.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting{" "}
                        <Tooltipicon message={'Target Android and iOS customers separately as needed.'}/></p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Deep links{" "}
                        <Tooltipicon message={'Embed deep links within notifications to direct users to specific app screens.'}/></p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="pricesechead">
                    <h6>Advanced notifications</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications{" "}
                        <Tooltipicon message={'Send unlimited notifications to app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich notifications{" "}
                        <Tooltipicon message={'Enhance notifications with multimedia.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling{" "}
                        <Tooltipicon message={'Schedule notifications to be sent at a later date and time.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting{" "}
                        <Tooltipicon message={'Target Android and iOS customers separately as needed.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Deep links{" "}
                        <Tooltipicon message={'Embed deep links within notifications to direct users to specific app screens.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User groups{" "}
                        <Tooltipicon message={'Create target groups based on usage patterns and demographics.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User insights{" "}
                        <Tooltipicon message={'Access key usage details including activity, sessions, device information and more.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics{" "}
                        <Tooltipicon message={'Analyze user interactions to gain insights into usage patterns.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics{" "}
                        <Tooltipicon message={'Access in-depth app usage data and drive growth through targeted improvements.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>



                

                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Sales commission{" "}</p>
                    </div>

                    <div className="priceminor">
                        <p>0%</p>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads{" "}
                        <Tooltipicon message={'Monetize the app with in-app advertisements.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Go ad-free{" "}
                        <Tooltipicon message={'Give your users an ad-free browsing experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                {/* <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div> */}




                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App visualizer{" "}
                        <Tooltipicon message={'Give your users an ad-free browsing experience.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps{" "}
                        <Tooltipicon message={'Combine multiple apps into a single powerful app.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                    <p>Offline mode{" "}
                    <Tooltipicon message={'Allow users to access your app offline, even without an active internet connection.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support{" "}
                        <Tooltipicon message={'Enable RTL layout for languages with right-to-left scripts like Arabic and Hebrew.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat{" "}
                        <Tooltipicon message={'Offer real-time chat support to your app users.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>





                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads{" "}
                        <Tooltipicon message={'Track your app’s growth by monitoring download insights.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics{" "}
                        <Tooltipicon message={'Get insights into app usage trends for both Android and iOS devices.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics{" "}
                        <Tooltipicon message={'Identify top-performing content based on app usage trends.'}/></p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                
                


                
                <div className="pricesechead">
                                                                    <h6>Distribution</h6>
                                                                </div>
                                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Upload to Apple Store{" "}
                                                                        <Tooltipicon message={'Instantly submit your app to the Apple Store.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <p>Automated</p>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Upload to Google Play{" "}
                                                                        <Tooltipicon message={'Instantly submit your app to Google Play.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <p>Coming soon</p>
                                                                    </div>
                                                                </div>
                                                                
                                                                
                                                
                                                                <div className="pricesechead">
                                                                    <h6>Support</h6>
                                                                </div>
                                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Community{" "}
                                                                        <Tooltipicon message={'Exchange insights and share experiences with other app builders.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Knowledge base{" "}
                                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                                    </div>
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Email{" "}
                                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                                    </div>
                                                
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Live chat{" "}
                                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                                    </div>
                                                
                                                                </div>
                                                
                                                                <div className="allflex">
                                                                    <div className="pricemajor">
                                                                        <p>Phone{" "}
                                                                        <Tooltipicon message={'Get access to detailed guides and FAQs on your own time.'}/></p>
                                                                    </div>
                                                
                                                                    <div className="priceminor">
                                                                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                                                                    </div>
                                                
                                                                </div>

                

                
                
            </div>
                    
                </div>
                
            </div>
        </div>  
          </section>


          <section className="blueback paddingtop100 paddingbottom100 pricingfaq">
          <div className="container">
          <h3 class="sectionheadings text-center">Frequently Asked Questions</h3>

          <div class="accordionWrapper">

            <div class="accordionItem close accordion-item"  eventKey="0">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Is a website required to build an app with the Custom app solution?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>AppMySite’s Custom app solution does not require a website to build a native mobile app. You can create any kind of app from the ground up and launch it on the Google Play and Apple App Stores.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="1">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What kind of apps can be created with the Custom app solution?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>The Custom app solution enables you to create apps for all kinds of industries. You can build apps for blogs, grocery stores, education, social networking, bakery, real estate, directory listings, business portfolios, and more.</p> 
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="2">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will all the published content sync to the app automatically?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Any time you add a new page or post, it will be synced automatically to the app. Similarly, any time you update an existing page or post, the app will reflect the changes.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="3">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What types of content can be added to the app?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>With AppMySite, you can create apps with diverse content. You can add multiple posts and pages within your app and build them with images, videos, galleries, text blocks, code snippets and more.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="4">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What number of posts and pages can be published within the app?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>There is no limit to the number of pages and posts you can add within the app.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="5">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Is it possible to switch to a ‘WordPress’ or ‘Web to app’ plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>You can switch to WordPress, WooCommerce, or Web to app plans at any point. Once you switch from Custom app to another app type, your existing posts and pages will not appear in the app. All your app’s content will be synced directly from your website.</p>
                </div>
            </div>

            <div className="hiddenfaq" id="showfaq">

            <div class="accordionItem close accordion-item"  eventKey="6">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How can I get features outside my pricing plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>You can always upgrade to a higher pricing plan in order to avail features outside your subscription. Before choosing a plan, you can take a look at all the features included in every plan and take a call accordingly.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="7">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Are the prices including taxes?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>All AppMySite plans are inclusive of taxes. There are no hidden charges.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="8">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will my app continue working if I stop paying my subscription?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Your app will be downgraded to the free Preview plan when you stop paying your monthly or annual subscription. In this case, your app will stop working.</p>
                  
                <p>If you don’t want to pay on a recurring basis, there is an option to subscribe to a lifetime subscription as well.</p> 
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="9">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How many apps can I create in each plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Every ‘Custom app’ plan is available for a single mobile app. In case you wish to create multiple apps, you can purchase a separate plan for each app or choose to subscribe to an ‘Unlimited apps’ plan. This will enable you to build unlimited apps on a single plan.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="10">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will AppMySite publish my app as well?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Once you finish building your app on AppMySite, you can download your app build(s) and publish to the app store(s). Furthermore, you can refer to our Help Center for documentation and video tutorials regarding app submission to the app stores.</p>
                <p>If you require additional support, you can purchase the Upload to App Stores add-on. With this add-on, you can get AppMySite to submit your app to the app store(s).</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="11">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Does AppMySite offer app development services?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>We do not offer any form of app development services. AppMySite is a DIY platform where users can sign up and create a mobile app on their own.</p>
                
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="12">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Do you have a refund policy?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Click here to learn more about our refund policy.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="13">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Do these plans also include the cost of creating Apple and Google developer accounts?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Once you finish creating an app on AppMySite, you can submit your app to Google Play and Apple App Store via developer accounts.</p>
                
                <p>You’re required to create an Apple and Google account on your own. Your AppMySite subscription plan doesn’t include the cost of creating Apple and Google developer accounts. Visit our Help Center to learn how to create an Apple and Google developer account.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="14">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What is your cancellation policy?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>You can cancel your paid subscription and downgrade to the free plan. Once canceled, you will not be charged for any subsequent billing cycles.</p>
                <p>Your app will stop working at the end of your billing cycle.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="15">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What do I get in the Preview plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>We’d like you to try before you buy, which is what you can do with the Preview plan. You can build as many free apps as you like and preview them from within your account before you upgrade and publish.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="16">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What is a distribution license?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Distribution licenses authorize you to download your app from within your account for uploading them to the app stores.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="17">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Do I need a developer account?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Yes, depending on your plan you’ll need developer accounts on the Google Play Store and/or the Apple App Store in order to publish your apps.</p>
                <p>Once you build your apps, you’ll be able to download your APK or AAB files (for your Android apps) and an IPA file (for your iOS apps) which can then be uploaded to your developer accounts. You can also request AppMySite to publish your apps for you, by simply purchasing the Upload to App Stores add-on.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="18">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How much time does it take to publish an app?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Once an app is submitted to the relevant app store, it is subject to a review from their end. Both stores generally take 24-48 hours but could take longer depending on various factors. You can find more details on their official websites.</p>
                </div>
            </div>

            </div>

            <h5 id="seemorelink" onClick={this.seemorefunction} >See More</h5> 
                
            <h5 id="seelesslink" class="d-none" onClick={this.seelessfunction}>See Less</h5>

            </div>
            
                    <div className="haveidea allflex">
                        <h4 className="sectionheadings">Have other questions?</h4>
                        <a target="_blank" href="/get-in-touch/">Contact Us <svg width="12.894" height="11.061" viewBox="0 0 12.894 11.061">
                        <g id="Group_5530" data-name="Group 5530" transform="translate(12 0.53) rotate(90)">
                            <path id="Path_9581" data-name="Path 9581" d="M0,5,5,0l5,5" transform="translate(0 0.167)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                            <path id="Path_9586" data-name="Path 9586" d="M0,0V12" transform="translate(5)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                        </g>
                        </svg></a>
                    </div>
                </div>
            </section>
            </>
          )
        :
            <div className="newspinner">
            <svg xmlns="http://www.w3.org/2000/svg" 
    xlink="http://www.w3.org/1999/xlink" 
    style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
    width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
    <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
        style={{animationplaystate: "running", animationdelay: "0s"}}>
        </animateTransform>
    </circle>
    </g>
    <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
    style={{animationplaystate: "running", animationdelay: "0s"}}>
    </animateTransform>
    </circle>
    </g>
    <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
    style={{animationplaystate: "running", animationdelay: "0s"}}>

    </animateTransform>
    </circle>
    </g>
    <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
    <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
    style={{animationplaystate: "running", animationdelay: "0s"}}>

    </animateTransform>
    </circle>
    </g>
    </svg>
        </div>
    }
    </div>
            {this.state.spinner ?
            <></>
            :
            <>
                    {/* Remove Addon Popup */}
                    <Modal
                        isOpen={this.state.deluser}
                        centered
                        toggle={(e) => this.removepopup(e)}
                        style={{ maxWidth: "480px", maxHeight: "296px" }}
                        className="plan-cancel-popup"
                        dataclassName="addonpopup"
                    >
                        <div className="crossmodal mt-1" onClick={(e) => this.removepopup()}>
                        <img src={ require("../../assets/images/dashboard/cross.png") .default }alt="cross"/>
                </div>
                        <ModalBody className="">
                        <div>
                            <div className="d-flex">
                            
                            <div className="confirmtext">
                                <h4>Plan change confirmation</h4>
                                { this.state.customer_type === 4 ?
                                    <h6>
                                    Your current plan will change to Free Plan at the end of your billing cycle, on <span>{moment(this.state.endcycledata).format(
                                        "D MMMM YYYY")}</span>{" "}
                                    </h6>
                                :
                                <>
                                    {moment().format('YYYY-MM-DD') == moment(this.state.endcycledata).format("YYYY-MM-DD") ?
                                        <>
                                        <h6>Your plan renewal is being processed today.</h6>
                                        <h6>If you proceed, your current plan will change to Free Plan at the end of your next billing cycle.</h6>
                                        </>
                                    :   
                                        <h6>
                                        Your current plan will change to Free Plan at the end of your billing cycle, on <span>{moment(this.state.endcycledata).format(
                                            "D MMMM YYYY")}</span>{" "}
                                        </h6>
                                    }
                                </>
                                }
                            </div>
                            </div>
                            <div className="plan-cancel-popup-footer d-flex justify-content-center">
                            <div className="plan-cancelbtn" onClick={(e) => this.removepopup()}>
                                Cancel
                            </div>

                            <div
                                onClick={(e) =>
                                this.state.customer_type === 4
                                    ? this.handleWooCustomer(
                                        e,
                                        this.state.customer_type,
                                        this.state.one_time[0].plan_code
                                    )
                                    : this.previewdowngrade(e,"")
                                }
                            >
                                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                <p>
                                <div className={this.state.loader_remove}>
                                <img
                                    src={this.state.loaderimage_remove}
                                    alt="loader"
                                />
                                </div>
                                <div className={this.state.submitval_confirmpop}>Confirm</div>
                            </p>
                                </div>
                            </div>

                            </div>
                        </div>
                        </ModalBody>
                    </Modal>
                    
                    <Modal
                        isOpen={this.state.downgradeuser}
                        centered
                        toggle={(e) => this.downgradepopupdeal(e)}
                        style={{ maxWidth: "480px", maxHeight: "296px" }}
                        className="plan-cancel-popup"
                        dataclassName="addonpopup"
                    >
                        <div className="crossmodal mt-1" onClick={(e) => this.downgradepopupdeal()}>
                        <img src={ require("../../assets/images/dashboard/cross.png") .default }alt="cross"/>
                </div>
                        <ModalBody className="">
                        <div>
                            <div className="d-flex">
                            
                            <div className="confirmtext">
                                <h6>
                            Are you sure you want to downgrade to the Preview
                            plan?{" "}
                            </h6>
                            <h6>
                            Your subscription will automatically be downgraded at
                            the end of your subscription.{" "}
                            </h6>
                            </div>
                            </div>
                            <div className="plan-cancel-popup-footer d-flex justify-content-center">
                            <div className="plan-cancelbtn" onClick={(e) => this.downgradepopupdeal()}>
                                Cancel
                            </div>

                            <div
                                onClick={(e) =>
                                this.state.customer_type === 4
                                    ? this.handleWooCustomer(
                                        e,
                                        this.state.customer_type,
                                        this.state.one_time[0].plan_code
                                    )
                                    : this.previewdowngrade(e,"")
                                }
                            >
                                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                <p>
                                <div className={this.state.loader_remove}>
                                <img
                                    src={this.state.loaderimage_remove}
                                    alt="loader"
                                />
                                </div>
                                <div className={this.state.submitval_confirmpop}>Okay</div>
                            </p>
                                </div>
                            </div>

                            </div>
                        </div>
                        </ModalBody>
                    </Modal>
                    <Modal
            isOpen={this.state.paidsubscriptiondetected}
            style={{ maxWidth: "460px", maxHeight: "240px" }}
            className="generalstngs1"
            centered
            >
            <ModalHeader  toggle={this.paidsubscriptionpopup}>
            <div className="crossmodal mt-1" onClick={this.paidsubscriptionpopup}>
                <img src={require("../../assets/images/dashboard/cross.png").default} />
                </div>
                    </ModalHeader>

            <ModalBody>
                <div>
                <h4>Paid subscription detected</h4>
                {this.state.customer_type === 1 ? (
                <h6>
                    {" "}
                           You’re currently on a paid plan or have an active paid add on
                subscription. Please downgrade to the free plan, cancel any
                active paid add on subscriptions, and try again.
                </h6>
                ) : (
                <h6>
                    {" "}
                           You’re currently on a paid plan or have an active paid add on
                subscription. Please downgrade to the free plan, cancel any
                active paid add on subscriptions, and try again.
                </h6>
                )}
                {/* <h6>You cannot transfer this app while you have an active paid subscription associated to it.</h6> */}
                
                <div className="generalstngs1-footer">
                
                    <div className="generalstngs1-ok">
                    <p onClick={this.paidsubscriptionpopup}>Okay</p>
                    </div>
                </div>
                </div>
            </ModalBody>
            </Modal>

            {/* Reactivate PopUp */}
            <Modal
            isOpen={this.state.openReactivatePopUp}
            style={{ maxWidth: "460px", maxHeight: "240px" }}
            className="generalstngs2"
            centered
            >
            <ModalHeader>
                <div className="crossmodal mt-1" onClick={(e) => this.handleReactivePopUp(e,"cancel")}>
                <img src={require("../../assets/images/dashboard/cross.png").default} />
                </div>
            </ModalHeader>
            
            <ModalBody>
                <div>
                <h4>Stay on current plan?</h4>
                <h6>Are you sure you want to cancel your plan change request and retain your current plan?</h6>
                
                <div className="generalstngs2-footer">

                <div className="generalstngs2-cancel" onClick={(e) => this.handleReactivePopUp(e,"cancel")}>
                    <p>Cancel</p>
                    </div>
                
                    <div className="generalstngs2-ok" onClick={(e) => this.handleReactivePopUp(e,"done")}>
                    <div className={this.state.reactiveLoader}>
                    <div className="new-loader"></div>
                    </div>
                    <p>{this.state.reactiveSubmitText}</p>
                    </div>
                </div>
                </div>
            </ModalBody>

            </Modal>
            </>
            }
        </>
        )
    }
}
