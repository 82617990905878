import React, {useState, useEffect} from "react";
import "./../assets/css/common.css";
import Header from "../../Dashboard/Header";
import LeftPanel from "./../LeftPanel";
import { Link, useParams } from "react-router-dom";
import moment from "moment";
//import placeholder from "./../assets/images/alexandergrey.jpg";
import placeholder from "./../assets/images/accountprofile.png";
import {
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
  } from "reactstrap";
import {errortoast} from '../../Dashboard/Toaster';
import {
    getTeamsData,
	saveTeamsData,
    updateTeamsData,
    DeleteAgencyTeam,
    ChangeTeamStatus,
} from "../../Api/Agency/AgencyApi";
import NewSpinner from "../NewSpinner";
import TeamLimitReach from "./TeamLimitReach";
import emailValidator from "email-validator";


const AgencyTeams = () =>{
    
    const [spinner, setSpinner] = useState(true);
    const [addteampopup, setAddTeamPopup] = useState(false);
    const [userdelete, setUserDelete] = useState(false);
    const [mdluserdelete, setMdlUserDelete] = useState(false);
    const [useremail, setUserEmail] = useState("");
    const [roleid, setRoleID] = useState(2);
    const [defaultroleid, setDefaultRoleID] = useState(2);
    const [btnsavelabel, setBtnSaveLabel] = useState("Invite");
    const [btnloader, setBtnLoader] = useState("d-none");
    const [user_email, setuser_email] = useState("");
    const [users, setUsers] = useState([]);
    const [owner, setOwner] = useState(null);
    const [usercount, setUserCount] = useState(0);
    const [userlimit, setUserlimit] = useState(0);
    const [defaultusercount, setDefaultUserCount] = useState(1);
    const [popupmode, setPopupMode] = useState(1); //1 For Add Mode 2 For Edit Mode
    const [isadded, setIsAdded] = useState(false);
    const [isdelete, setIsDelete] = useState(false);
    const [btnupdatelabel, setBtnUpdateLabel] = useState("Save");
    const [editteampopup, setEditTeamPopup] = useState(false);
    const [edituserid, setEditUserID] = useState("");
    const [islimitreachopen, setIsLimitReachOpen] = useState(false);
    const [planname, setPlanName] = useState("preview");
    const [usertype, setUserType] = useState("");
    const [searchuser, setSearchUser] = useState("");
    const [logoimage,setLogoImage] = useState("");
    const [orgname,setOrgName] = useState("");
    const [erroremail,setErrorEmail] = useState(false);
    const [mdlcustomerdeactive, setMdlCustomerDeactive] = useState(false);
    const profilepath = process.env.REACT_APP_Image_Path + localStorage.getItem("logged_in_user_id") + process.env.REACT_APP_Profile;
    const [rightpanelexpand,setRightPanelExpand] = useState(true);
    const [mdlcustomerdeinactive, setMdlCustomerDeinactive] = useState(false);
    const [mdlcustomerActive, setMdlCustomerActive] = useState(false);
    const [btncustomerdeinactiveloader, setBtnCustomerDeinactiveLoader] = useState("d-none");
    const [btncustomerdeinactivelabel, setBtnCustomerDeinactiveLabel] = useState("Deactivate");
    const [btncustomeractiveloader, setBtnCustomerActiveLoader] = useState("d-none");
    const [btncustomeractivelabel, setBtnCustomerActiveLabel] = useState("Activate");
    const [btncustomerdeactiveloader, setBtnCustomerDeactiveLoader] = useState("d-none");
    const [btncustomerdeactivelabel, setBtnCustomerDeactiveLabel] = useState("Delete");
    const [user_role_id, setuser_role_id] = useState(null);
    const[userid,setuserid] = useState('');



    let { agencyID } = useParams("agencyID");

    useEffect(()=>{

        if(agencyID !== '' && agencyID !== null && agencyID !== undefined)
        {
            setSpinner(true);
            getTeamsData(agencyID, setUsers, setSpinner, usercount, setUserCount, setOwner, setPlanName, setLogoImage, setOrgName,setUserlimit,setuser_email,setuser_role_id);
        }

        document.title = "Team | AppMySite";

    }, [isadded, isdelete]);

    const handleAddTeam = (e) =>
    {
        setBtnSaveLabel('Invite');
        if(usercount < userlimit)
        {
            setUserEmail("");
            setDefaultRoleID(2);
            setAddTeamPopup(!addteampopup);
        }
        else
        {
            setIsLimitReachOpen(!islimitreachopen);
        }
    }

    const handleSetEmail = (e) =>{

        setUserEmail(e.target.value);
        setErrorEmail(false);
    }

    const handleSetRole = (e, roleid) =>{

        setRoleID(roleid);
        setDefaultRoleID(roleid);
    }


    const handleCloseTeamModel = (e) =>{
        setAddTeamPopup(!addteampopup);
    }

    const handleInviteNewUser = (e) =>{
        e.preventDefault();

        if(useremail === '')
        {
            //errortoast("Email is required");
            setErrorEmail(true);
            return;
        }
        else if(!emailValidator.validate(useremail.trim()))
        {
            //errortoast('Enter valid From address!');
            setErrorEmail(true);
            return;
        }

        const params = {
                        id: "",
                        agency_id: parseInt(atob(agencyID)),
                        email: useremail,
                        role_id: roleid
                    };
        
        setBtnSaveLabel("");
        setBtnLoader("d-block");
        
        let invitebtndiv = document.getElementById("btninviteid");
		invitebtndiv.classList.add("btnloader");

        saveTeamsData(params, setBtnSaveLabel, setBtnLoader, setAddTeamPopup, setIsAdded, isadded);
    }

    const handleUserType = (e) =>{

        setUserType(e.target.value);
    }

    const handleSearchUser = (e) =>{

        setSearchUser(e.target.value);
    }

    const handleEditUserPopup = (e, uemail, userid, roleid) =>{

        //setPopupMode(2);
        setEditTeamPopup(true);
        setUserEmail(uemail);
        setEditUserID(userid);
        setRoleID(roleid);

    }

    const handleDeleteUserPopup = (e) =>{

        e.preventDefault();
        setMdlCustomerDeactive(false);
        setSpinner(true);

        const params = {
                        id: userid,
                        agency_id: parseInt(atob(agencyID))
                        };


        DeleteAgencyTeam(params, setIsDelete,setSpinner, isdelete);
    }

    const handleCustomeDeactivePopup = (e,userid) =>
    {
        setuserid(userid);
        setMdlCustomerDeactive(!mdlcustomerdeactive);
    }

    const btnCancelCustomerDeactivePopup = () =>{
        setuserid('');
        setMdlCustomerDeactive(false);
    }

    const handleUpdateUser = (e) =>{

        e.preventDefault();

        const params = {
            id: edituserid,
            agency_id: parseInt(atob(agencyID)),
            email: useremail,
            role_id: roleid
        };

        setBtnUpdateLabel("");
        setBtnLoader("d-block");

        let updatebtndiv = document.getElementById("btnupdateid");
		updatebtndiv.classList.add("btnloader");

        updateTeamsData(params, setBtnUpdateLabel, setBtnLoader, setEditTeamPopup, setIsAdded, isadded);
    }

    const handleCloseEditTeamModel = (e) =>{
        setEditTeamPopup(false);
    }

    const closeLimitReachReachPopup = (e) =>{

        setIsLimitReachOpen(false);
    }

    const handleRightPanelExpand = (rightpanelvalue) => {
        setRightPanelExpand(rightpanelvalue);
    }

    const handleCustomeDeinactivePopup = (userid) =>
    {
        setuserid(userid);
        setMdlCustomerDeinactive(!mdlcustomerdeinactive);
    }

    const handleCustomerActivePopup = (userid) =>{

        setuserid(userid);
        setMdlCustomerActive(!mdlcustomerActive);

    }

    const btnCancelCustomerDeinactivePopup = () =>{
        setuserid('');
        setMdlCustomerDeinactive(false);
    }

    const btnCancelCustomerActivePopup = () =>{
        setuserid('');
        setMdlCustomerActive(false);
    }

    const btnCustomerDeinactive = (e) =>
    {
        setSpinner(true);
        const params = {
            id: userid,
            agency_id: parseInt(atob(agencyID)),
            status: 2,
          };
        
        ChangeTeamStatus(params,setMdlCustomerDeinactive,setMdlCustomerActive,setSpinner,spinner,() => 
        {
            let user_status_change_on_error = users.filter((res) => 
            {
                if (res.id === userid) {
                res.status = 2;
                }
                return res;
            });
            setUsers(user_status_change_on_error);
        });

    }

    const btnCustomerActive = (e) =>{

        setSpinner(true);
        
        const params = {
            id: userid,
            agency_id: parseInt(atob(agencyID)),
            status: 1,
          };
       
          ChangeTeamStatus(params,setMdlCustomerDeinactive,setMdlCustomerActive,setSpinner,spinner,() => 
        {
            let user_status_change_on_error = users.filter((res) => 
            {
                if (res.id === userid) {
                res.status = 1;
                }
                return res;
            });
            setUsers(user_status_change_on_error);
        });

    }
    
    return(
    <div className="org-module">
   
        <Header />
        <div className="org-structure">
        <LeftPanel rightpanelbody={handleRightPanelExpand}/>
            <div className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined && localStorage.getItem('sidebaserotate') === 'true') ? "org_background org_background-expand" : "org_background "}>

            
               
                <div className="org-team-head">

                            <div>  
                                <div className="agency-headings">
                                <h1>Team</h1>
                                <p class="agency-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                    <circle cx="9" cy="9" r="9" stroke="none"/>
                                    <circle cx="9" cy="9" r="8.25" fill="none"/>
                                    </g>
                                    <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                                    <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                </g>
                                </svg><p class="agency-tooltipsubheader">Invite team members to your app.</p></p>
                                </div>
                            </div>

                            { spinner || userlimit == 0 ? <></>
                                
                            : 
                                <>  
                                         {user_role_id !== 3 && ( 
                                        <>
                                            <div className="org-head-right">
                                                {/* <p><span>{ usercount}/{userlimit}</span> used</p> */}
                                    
                                                <div className="save-team-btn web-save-agency-team-btn" onClick={handleAddTeam}>
                                                    <img src={require("../assets/images/white-plus.png").default} alt="invite"/> 
                                                    { " Invite" }
                                                </div>
                                            </div>
                                        </>
                                    )} 
                                </>
                            }
                            </div>
                            { spinner || userlimit == 0 ? <></>
                                
                            : 
                                <>  
                                         {user_role_id !== 3 && ( 
                                        <>
                                            <div className="org-head-right">
                                                {/* <p><span>{ usercount}/{userlimit}</span> used</p> */}
                                    
                                                <div className="save-team-btn agency-mob-save-team-btn" onClick={handleAddTeam}>
                                                    <img src={require("../assets/images/white-plus.png").default} alt="invite"/> 
                                                    { " Invite" }
                                                </div>
                                            </div>
                                        </>
                                    )} 
                                </>
                            }
                            
                                <>
                                    { spinner ?
                                        <NewSpinner />
                                    :
                                    <>
                                        {userlimit == 0 ?
                                            <div className="upgrade-agency-team">
                                                <div className="team-right-box">
                                                    <div className="team-heading">
                                                    <div className="team-inner-box box-img">
                                                        <div className="team-box-wrapper">
                                                        <div className="team-inner-content">
                                                            <div className="team-content">
                                                            <div className="team-content-details">
                                                                <h3 className="team-paragraph">Build better, together</h3>
                                                                <p className="team-content-text">Manage your team in one place.</p>
                                                                <ul>
                                                                <li>Collaborate with team members.</li>
                                                                <li>Delegate responsibilities to others.</li>
                                                                <li>One-click access management.</li>
                                                                <li>Assign roles and limit visibility.</li>
                                                                </ul>
                                                                {
                                                                    localStorage.getItem("isUpgradeVisibleInAgency")!=0 && 
                                                                
                                                            <Link to={`/agency/pricing/`+agencyID}>
                                                                <button type="button" className="btn btn-primary"> Upgrade </button>
                                                            </Link>}
                                                            </div>
                                                            </div>
                                                            <div className="team-img">
                                                            <img src={require("../assets/images/team-image.png").default} alt="team-image" />
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        :
                                        <>
                                            <>  
                                                {/* {user_role_id !== 3 && ( 
                                                    <>
                                                        <div className="invite-team-mob">
                                                            <div className="save-team-btn" onClick={handleAddTeam}>
                                                                <img src={require("../assets/images/white-plus.png").default} alt="invite"/> 
                                                                { " Invite" }
                                                            </div>
                                                        </div>
                                                    </>
                                                )} */}
                                            </>
                                            <div className="org-module-shadow teams-web-view">
                                                
                                                <>

                                                <div className="agency-team-table-head">
                                                        <div>
                                                            <h6>User</h6>
                                                        </div>
                                                        <div>
                                                            <h6>Email</h6>
                                                        </div>
                                                        <div>
                                                            <h6>Role</h6>
                                                        </div>
                                                        <div>
                                                            <h6>Last login</h6>
                                                        </div>
                                                        <div>
                                                            <h6>Status</h6>
                                                        </div>
                                                    </div>
                                                    

                                                    <div className="org-team-body org-team-body-div" >  
                                                        
                                                        {/*userdata !== null && (droprole === "" || droprole === "1") && (searchsuer === "" || userdata.email.toLowerCase().indexOf(searchsuer.toLowerCase()) !== -1)*/}
                                                        
                                                        { (owner !== null && (usertype === '' || usertype === '1') && (searchuser === '' || owner.email.toLowerCase().indexOf(searchuser.toLowerCase()) !== -1) ) ?  
                                                        
                                                            <div className="team-member-detail">
                                                                <div className="member-img-name">

                                                                    { owner.profile_image === null || owner.profile_image === '' || owner.profile_image === undefined ?
                                                                        
                                                                        <div className="member-img" style={{ backgroundImage: "url(" + placeholder + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} ></div>
                                                                    :

                                                                        ( owner.profile_image.indexOf("https") !== -1 ?

                                                                            <div className="member-img" style={{ backgroundImage: "url(" + owner.profile_image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} ></div>
                                                                        :
                                                                            <div className="member-img" style={{ backgroundImage: "url(" + process.env.REACT_APP_Image_Path + owner.id + process.env.REACT_APP_Profile + owner.profile_image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} ></div>
                                                                        )
                                                                    }
                                                                    <div>
                                                                        <h2>{owner.name}</h2>
                                                                        <p>User ID: {owner.id}</p>
                                                                    </div>
                                                                </div>

                                                                <div className="team-member-email">
                                                                    <p>{owner.email}</p>
                                                                </div>

                                                                <div className="team-member-role">
                                                                    <p>Owner</p>
                                                                </div>
                                                                <div className="team-member-time">
                                                                    <h3>{ moment( owner.last_login ).format("D MMMM YYYY, HH:mm") }</h3>
                                                                    <h5>IP {owner.last_login_IP}</h5>
                                                                </div>
                                                                <div className="teams-status">    
                                                                    <div className="team-member-status">
                                                                        <h6 className="teamstatusgreen">Active</h6>
                                                                    </div>
                                                                    <div className="team-member-edit"></div>
                                                                </div> 
                                                                
                                                            </div>
                                                        :
                                                            null
                                                        }
                                                    
                                                    {/*data.length > 0 && 
                                                    data.filter((res) => droprole === "" ? res.role_id : res.role_id === parseInt(droprole) )
                                                    .filter((res) => searchsuer === "" ? res.invitation_email : res.invitation_email.toLowerCase().indexOf( searchsuer.toLowerCase() ) == -1 ) */}   

                                                    { users.length > 0 ?
                                                        users.filter((res) => usertype === "" ? res.role_id : res.role_id === parseInt(usertype) )
                                                        .filter((res) => searchuser === '' ? res.invitation_email : res.invitation_email.toLowerCase().indexOf( searchuser.toLowerCase() ) != -1 )
                                                        .map((userlist, userkey)=>{
                                                            return(
                                                            <div className="team-member-detail" key={userkey}>
                                                                <div className="member-img-name">

                                                                    { userlist.image === '' || userlist.image === null || userlist.image === undefined || userlist.image === 'null' ?
                                                                        
                                                                        <div className="member-img" style={{ backgroundImage: "url(" + placeholder + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                                                    :
                                                                        ( userlist.image.indexOf("https") !== -1 ?
                                                                            
                                                                            <div className="member-img" style={{ backgroundImage: "url(" + userlist.image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                                                        
                                                                        :

                                                                            <div className="member-img" style={{ backgroundImage: "url(" + process.env.REACT_APP_Image_Path + userlist.user_id + process.env.REACT_APP_Profile + userlist.image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                                                        )
                                                                    }

                                                                    <div>
                                                                        <h2>{ userlist.profile_name }</h2>
                                                                        {userlist.user_id != '' ?
                                                                        <p>User ID: { userlist.user_id }</p>
                                                                        : <>-</>
                                                                        }
                                                                    </div>
                                                                </div>

                                                                <div className="team-member-email">
                                                                    <p>{userlist.email ?  userlist.email :userlist.invitation_email ? userlist.invitation_email : '' }</p>
                                                                </div>

                                                                <div className="team-member-role">
                                                                    <p>
                                                                        { userlist.role_id === 2 ?
                                                                            "Admin"
                                                                        :
                                                                            (userlist.role_id === 3 ?
                                                                                "Manager"
                                                                            :
                                                                                (userlist.role_id === 4 ?
                                                                                    "User"
                                                                                :
                                                                                    null   
                                                                                )
                                                                            )
                                                                        }
                                                                    </p>
                                                                </div>

                                                                <div className="team-member-time">
                                                                    { (userlist.last_login !== '' && userlist.last_login !== null && userlist.last_login !== undefined && userlist.last_login !== 'null') ?
                                                                        <h3>{ moment( userlist.last_login ).format("D MMMM YYYY, HH:mm") }</h3>
                                                                    :
                                                                        <h3>-</h3>
                                                                    }

                                                                    { (userlist.last_login_IP !== '' && userlist.last_login_IP !== null && userlist.last_login_IP !== undefined && userlist.last_login_IP !== 'null') ?
                                                                        <h5>IP {userlist.last_login_IP}</h5>
                                                                    : (userlist.registration_IP !== '' && userlist.registration_IP !== null && userlist.registration_IP !== undefined && userlist.registration_IP !== 'null') ?
                                                                        <h5>IP {userlist.registration_IP}</h5>
                                                                    : <></>
                                                                    }
                                                                </div>

                                                                <div className="teams-status">    
                                                                    <div className="team-member-status">
                                                                        {userlist.status === 0 ?
                                                                            <h6 className="teamstatusgrey">Invited</h6>
                                                                        :
                                                                            (userlist.status === 1 ?
                                                                                <>
                                                                                <h6 className="teamstatusgreen">Active</h6>
                                                                                {user_email !== userlist.invitation_email && userlist.role_id >= user_role_id ?
                                                                                <span onClick={()=>handleCustomeDeinactivePopup(userlist.id)} className="mobileactivate">Deactivate</span>
                                                                                :   <></>
                                                                                }
                                                                                </>
                                                                            :  userlist.status === 2 ?
                                                                                <>
                                                                                <h6 className="teamstatusred">Inactive</h6>
                                                                                {user_email !== userlist.invitation_email && userlist.role_id >= user_role_id ?
                                                                                <span onClick={()=>handleCustomerActivePopup(userlist.id)} className="mobileactivate">Activate</span>
                                                                                :   <></>
                                                                                }
                                                                                </>
                                                                            :
                                                                                null
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div className="team-member-edit">
                                                                    {user_email !== userlist.invitation_email && userlist.role_id >= user_role_id ? 
                                                                    (
                                                                        <>
                                                                            { userlist.status > 0 ?
                                                                                
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16.263" height="16.263" viewBox="0 0 16.263 16.263" onClick={ (e)=>handleEditUserPopup(e, userlist.invitation_email, userlist.id, userlist.role_id) } >
                                                                                    <g id="Group_27285" data-name="Group 27285" transform="translate(-999.142 -1409.971) rotate(45)">
                                                                                        <g id="Path_82084" data-name="Path 82084" transform="translate(1713 281)" fill="#fff">
                                                                                        <path d="M 2 17.29290008544922 L -0.5 14.79290008544922 L -0.5 2 C -0.5 1.724300026893616 -0.2757000029087067 1.5 0 1.5 L 4 1.5 C 4.275700092315674 1.5 4.5 1.724300026893616 4.5 2 L 4.5 14.79290008544922 L 2 17.29290008544922 Z" stroke="none"/>
                                                                                        <path d="M 0 2 L 0 14.58578014373779 L 2 16.58577919006348 L 4 14.58578014373779 L 4 2 L 0 2 M 0 1 L 4 1 C 4.552279949188232 1 5 1.447719573974609 5 2 L 5 15 L 2 18 L -1 15 L -1 2 C -1 1.447719573974609 -0.5522799491882324 1 0 1 Z" stroke="none" fill="#7782a1"/>
                                                                                        </g>
                                                                                        <path id="Path_82085" data-name="Path 82085" d="M-4459-11434.725h6" transform="translate(6171 11721)" fill="none" stroke="#7782a1" strokeWidth="1"/>
                                                                                    </g>
                                                                                    </svg>
                                                                            :
                                                                                null
                                                                            }

                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999" onClick={ (e)=>handleCustomeDeactivePopup(e, userlist.id) }>
                                                                            <g id="Group_5465" data-name="Group 5465" transform="translate(-835.516 -682.992)">
                                                                                <g id="Rectangle_2307" data-name="Rectangle 2307" transform="translate(836.515 684.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1">
                                                                                <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none"/>
                                                                                <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none"/>
                                                                                </g>
                                                                                <g id="Rectangle_2308" data-name="Rectangle 2308" transform="translate(839.515 682.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1">
                                                                                <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none"/>
                                                                                <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none"/>
                                                                                </g>
                                                                                <g id="Group_3245" data-name="Group 3245" transform="translate(841.016 687.993)">
                                                                                <line id="Line_110" data-name="Line 110" y2="6" transform="translate(3 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1"/>
                                                                                <line id="Line_111" data-name="Line 111" y1="6" transform="translate(0 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1"/>
                                                                                </g>
                                                                                <line id="Line_112" data-name="Line 112" x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1"/>
                                                                            </g>
                                                                            </svg>
                                                                        </>
                                                                     )
                                                                     : <></>
                                                                     } 
                                                                    </div>

                                                                </div>    

                                                                

                                                            </div>
                                                            )
                                                        })
                                                    :
                                                        null
                                                    }
                                                            
                                                    </div>
                                                </>
                                                
                                            </div>

                                            <div className="agency-teams-mobile">
                                            <>
                                            { (owner !== null && (usertype === '' || usertype === '1') && (searchuser === '' || owner.email.toLowerCase().indexOf(searchuser.toLowerCase()) !== -1) ) ?  
                                                                    <div className="org-module-shadow">

                                                                        <div className="agency-teams-mob-1">
                                                                            <div>
                                                                                <h2>{ owner.name }</h2>
                                                                                <h3>User ID: { owner.id }</h3>
                                                                            </div>

                                                                            { owner.profile_image === null || owner.profile_image === '' || owner.profile_image === undefined ?
                                                                        
                                                                                <div className="member-img" style={{ backgroundImage: "url(" + placeholder + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} ></div>
                                                                            :

                                                                                ( owner.profile_image.indexOf("https") !== -1 ?

                                                                                    <div className="member-img" style={{ backgroundImage: "url(" + owner.profile_image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} ></div>
                                                                                :
                                                                                    <div className="member-img" style={{ backgroundImage: "url(" + process.env.REACT_APP_Image_Path + owner.id + process.env.REACT_APP_Profile + owner.profile_image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} ></div>
                                                                                )
                                                                            }

                                                                        </div>

                                                                        <div class="agency-teams-mob-2">
                                                                            <div>
                                                                                <h6>Email</h6>
                                                                                <h4>{owner.email ?  owner.email :owner.invitation_email ? owner.invitation_email : '' }</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div class="agency-teams-mob-2">
                                                                            <div>
                                                                                <h6>Role</h6>
                                                                                <h4>Owner</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="agency-teams-mob-3">
                                                                            <div>
                                                                                <h6>Last login</h6>
                                                                            
                                                                                    <h4>{ moment( owner.last_login ).format("D MMMM YYYY, HH:mm") }</h4>
                                                                                
                                                                                    <h5>IP {owner.last_login_IP}</h5>
                                                                                
                                                                            </div>
                                                                        </div>

                                                                        <div className="agency-teams-mob-4">
                                                                            <div>
                                                                                <h6>Status</h6>
                                                                                <h5 className="customerstatusgreen">Active</h5>
                                                                                
                                                                            </div>
                                                                        
                                                                        </div>

                                                                    </div>
                                                : null
                                            }
                                                                </>
                                            { users.length > 0 ?
                                                        users.filter((res) => usertype === "" ? res.role_id : res.role_id === parseInt(usertype) )
                                                        .filter((res) => searchuser === '' ? res.invitation_email : res.invitation_email.toLowerCase().indexOf( searchuser.toLowerCase() ) != -1 )
                                                        .map((userlist, userkey)=>{
                                                            return(
                                                                <>
                                                                    <div className="org-module-shadow">

                                                                        <div className="agency-teams-mob-1">
                                                                            <div>
                                                                                <h2>{ userlist.profile_name }</h2>
                                                                                {userlist.user_id != '' ?
                                                                                <h3>User ID: { userlist.user_id }</h3>
                                                                                : <>-</>
                                                                                }
                                                                            </div>

                                                                            { userlist.image === '' || userlist.image === null || userlist.image === undefined || userlist.image === 'null' ?
                                                                        
                                                                        <div className="member-img" style={{ backgroundImage: "url(" + placeholder + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                                                    :
                                                                        ( userlist.image.indexOf("https") !== -1 ?
                                                                            
                                                                            <div className="member-img" style={{ backgroundImage: "url(" + userlist.image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                                                        
                                                                        :

                                                                            <div className="member-img" style={{ backgroundImage: "url(" + process.env.REACT_APP_Image_Path + userlist.user_id + process.env.REACT_APP_Profile + userlist.image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                                                        )
                                                                    }

                                                                        </div>

                                                                        <div class="agency-teams-mob-2">
                                                                            <div>
                                                                                <h6>Email</h6>
                                                                                <h4>{userlist.email ?  userlist.email :userlist.invitation_email ? userlist.invitation_email : '' }</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div class="agency-teams-mob-2">
                                                                            <div>
                                                                                <h6>Role</h6>
                                                                                <h4>{ userlist.role_id === 2 ?
                                                                                        "Admin"
                                                                                    :
                                                                                        (userlist.role_id === 3 ?
                                                                                            "Manager"
                                                                                        :
                                                                                            (userlist.role_id === 4 ?
                                                                                                "User"
                                                                                            :
                                                                                                null   
                                                                                            )
                                                                                        )
                                                                                    }</h4>
                                                                            </div>
                                                                        </div>

                                                                        <div className="agency-teams-mob-3">
                                                                            <div>
                                                                                <h6>Last login</h6>
                                                                            
                                                                                { (userlist.last_login !== '' && userlist.last_login !== null && userlist.last_login !== undefined && userlist.last_login !== 'null') ?
                                                                                    <h4>{ moment( userlist.last_login ).format("D MMMM YYYY, HH:mm")}
                                                                                    </h4>
                                                                                :
                                                                                    <h4>-</h4>
                                                                                }

                                                                                { (userlist.last_login_IP !== '' && userlist.last_login_IP !== null && userlist.last_login_IP !== undefined && userlist.last_login_IP !== 'null') ?
                                                                                    <h5>IP {userlist.last_login_IP}</h5>
                                                                                : (userlist.registration_IP !== '' && userlist.registration_IP !== null && userlist.registration_IP !== undefined && userlist.registration_IP !== 'null') ?
                                                                                    <h5>IP {userlist.registration_IP}</h5>
                                                                                : <></>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className="agency-teams-mob-4">
                                                                            <div>
                                                                                <h6>Status</h6>
                                                                                {userlist.status === 0 ?
                                                                                    <h5 className="customerstatusred">Invited</h5>
                                                                                :
                                                                                    (userlist.status === 1 ?
                                                                                        <>
                                                                                        <h5 className="teamstatusgreen">Active</h5>
                                                                                        {user_email !== userlist.invitation_email && userlist.role_id >= user_role_id ?
                                                                                            <span onClick={()=>handleCustomeDeinactivePopup(userlist.id)} className="mobileactivate">Deactivate</span>
                                                                                        :   <></>
                                                                                        }
                                                                                        </>
                                                                                    :  userlist.status === 2 ?
                                                                                        <>
                                                                                        <h5 className="teamstatusred">Inactive</h5>
                                                                                        {user_email !== userlist.invitation_email && userlist.role_id >= user_role_id ?
                                                                                            <span onClick={()=>handleCustomerActivePopup(userlist.id)} className="mobileactivate">Activate</span>
                                                                                        :   <></>
                                                                                        }
                                                                                        </>
                                                                                    :
                                                                                        null
                                                                                    )
                                                                                }
                                                                            
                                                                            </div>
                                                                            <div className="team-member-edit">
                                                                            {user_email !== userlist.invitation_email && userlist.role_id >= user_role_id ? 
                                                                            (

                                                                                <>
                                                                                    { userlist.status > 0 ?
                                                                                            <span onClick={ (e)=>handleEditUserPopup(e, userlist.invitation_email, userlist.id, userlist.role_id) }>  
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16.263" height="16.263" viewBox="0 0 16.263 16.263">
                                                                                            <g id="Group_27285" data-name="Group 27285" transform="translate(-999.142 -1409.971) rotate(45)">
                                                                                                <g id="Path_82084" data-name="Path 82084" transform="translate(1713 281)" fill="#fff">
                                                                                                <path d="M 2 17.29290008544922 L -0.5 14.79290008544922 L -0.5 2 C -0.5 1.724300026893616 -0.2757000029087067 1.5 0 1.5 L 4 1.5 C 4.275700092315674 1.5 4.5 1.724300026893616 4.5 2 L 4.5 14.79290008544922 L 2 17.29290008544922 Z" stroke="none"/>
                                                                                                <path d="M 0 2 L 0 14.58578014373779 L 2 16.58577919006348 L 4 14.58578014373779 L 4 2 L 0 2 M 0 1 L 4 1 C 4.552279949188232 1 5 1.447719573974609 5 2 L 5 15 L 2 18 L -1 15 L -1 2 C -1 1.447719573974609 -0.5522799491882324 1 0 1 Z" stroke="none" fill="#7782a1"/>
                                                                                                </g>
                                                                                                <path id="Path_82085" data-name="Path 82085" d="M-4459-11434.725h6" transform="translate(6171 11721)" fill="none" stroke="#7782a1" strokeWidth="1"/>
                                                                                            </g>
                                                                                            </svg>
                                                                                            </span>
                                                                                    : <></>
                                                                                    }

                                                                                    <span onClick={ (e)=>handleCustomeDeactivePopup(e, userlist.id) }> 
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                                                                                <g id="Group_5465" data-name="Group 5465" transform="translate(-835.516 -682.992)">
                                                                                    <g id="Rectangle_2307" data-name="Rectangle 2307" transform="translate(836.515 684.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1">
                                                                                    <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none"/>
                                                                                    <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none"/>
                                                                                    </g>
                                                                                    <g id="Rectangle_2308" data-name="Rectangle 2308" transform="translate(839.515 682.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1">
                                                                                    <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none"/>
                                                                                    <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none"/>
                                                                                    </g>
                                                                                    <g id="Group_3245" data-name="Group 3245" transform="translate(841.016 687.993)">
                                                                                    <line id="Line_110" data-name="Line 110" y2="6" transform="translate(3 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1"/>
                                                                                    <line id="Line_111" data-name="Line 111" y1="6" transform="translate(0 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1"/>
                                                                                    </g>
                                                                                    <line id="Line_112" data-name="Line 112" x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1"/>
                                                                                </g>
                                                                                </svg>
                                                                                    </span>   
                                                                                </>
                                                                            )
                                                                            : <></>
                                                                            }      
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </>
                                                        )
                                                    })
                                                :
                                                    null
                                                }
                                            </div>
                                        </>
                                        }
                                    </>
                                    }
                                </>
                            
                
                <Modal isOpen={addteampopup} toggle={handleCloseTeamModel} style={{ maxWidth: "600px", maxHeight: "247px" }} className="modal-dialog agency-team-popup user-popup-mobile modal-dialog-centered" dataclass="useradd" centered >
                    <ModalHeader className="modal-xxl-content invite-user pb-0" >
                        { popupmode === 1 ? 
                            <>
                                <p className="user_modalheading">Invite New User</p>
                                <p>Once an invitation is accepted, the user will gain access to your app based on the allocated role and permissions.</p>
                            </>
                        :
                            <>
                                <p className="user_modalheading">Edit Role</p>
                                <p>Edit role for user {useremail}</p>
                            </>
                        }

                            <div className="crossmodal mt-1" onClick={handleCloseTeamModel}>
                                <img
                                    src={require("../assets/images/cross.png").default}
                                />
                            </div>
                        
                    </ModalHeader>

                    <ModalBody className="invite-user-body">
                        <div className="form-group ">
                            <p className="agency-email-label">Email*</p>
                            { erroremail ?
                                <input type="email" placeholder="Enter email address" className="w-100 errormessages" value={useremail} onChange={ handleSetEmail } />
                            :
                                <input type="email" placeholder="Enter email address" className="w-100" value={useremail} onChange={ handleSetEmail } required />
                            }
                        </div>

                        <div className=" push_notification ">
                            <div className="color_picker w-100">
                                <label className="container_1 mr-4">
                                    <div>
                                        <h5>Admin</h5>
                                        <p>Best suited for business owners and company administrators. Full access and management privileges.</p>
                                    </div>
                                    
                                    <input type="radio" name="admin" value={roleid} onClick={ (e) =>handleSetRole(e, 2) }  checked={ defaultroleid === 2  ? "true" : ""} />
                                    
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="color_picker w-100">
                                <label className="container_1 mr-4">
                                    <div>
                                        <h5>Manager</h5>
                                        <p>Ideal for those who need to manage the app without access to billing or subscription features.{" "} </p>
                                    </div>
                                    
                                    <input type="radio" name="admin" value={roleid} onClick={ (e) =>handleSetRole(e, 3) } checked={ defaultroleid === 3  ? "true" : ""} />
                                    
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            {/* <div className="color_picker w-100 ">
                                <label className="container_1 mr-4">
                                    <div>
                                        <h5>User</h5>
                                        <p>Suited for those who need to manage the app but do not require access to billing, subscription, or app management features.</p>
                                    </div>
                                    
                                    <input type="radio" name="admin" value={roleid} onClick={ (e) =>handleSetRole(e, 4) } checked={ defaultroleid === 4  ? "true" : ""} />
                                    
                                    <span className="checkmark"></span>
                                </label>
                            </div> */}
                        </div>
                    </ModalBody>

                    <div className="d-flex justify-content-between align-items-center cancel-user mt-4 agency-team-popup-footer">
                        <div className="cancel-btn mr-2 mr-md-0" onClick={handleCloseTeamModel}> Cancel </div>
                            <button type="submit" className={useremail != '' ? "next_btn" : "team-user-inactive next_btn"} form="userform"
                            style={{
                                cursor:useremail && useremail.trim() ?"":"not-allowed",
                                boxShadow:useremail && useremail.trim()?"":"unset",
                                "--custom_color5": useremail && useremail.trim()?"#134bec":"#BCC1CE",
                                "--custom_color4": "white" ,
                                "--custom_color6": useremail && useremail.trim()?"#3064f9":"#BCC1CE" ,
                            }}
                            onClick={handleInviteNewUser} id="btninviteid">
                                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className={btnloader}>
                                        <div className="new-loader"></div>
                                    </div>
                                    { btnsavelabel }
                                </div>
                            </button>
                        {/* popupmode === 1 ?
                            <button type="submit" className="next_btn" form="userform" onClick={handleInviteNewUser} id="btninviteid">
                                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className={btnloader}>
                                        <div className="new-loader"></div>
                                    </div>
                                    { btnsavelabel }
                                </div>
                            </button>
                        :
                            <button type="submit" className="next_btn" form="userform" onClick={handleUpdateUser} id="btnupdateid">
                                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className={btnloader}>
                                        <div className="new-loader"></div>
                                    </div>
                                    { btnupdatelabel }
                                </div>
                            </button>
                    */}
                    </div>
                </Modal>

                <Modal isOpen={editteampopup} toggle={handleCloseEditTeamModel} style={{ maxWidth: "600px", maxHeight: "247px" }} className="modal-dialog agency-team-popup user-popup-mobile modal-dialog-centered" dataclass="useredit" centered >
                    <ModalHeader className="modal-xxl-content invite-user pb-0" >
                        <p className="user_modalheading">Edit user role</p>
                        <p>Select from the options below to change the user role.</p>

                        <div className="crossmodal mt-1" onClick={handleCloseEditTeamModel}>
                                <img
                                    src={require("../assets/images/cross.png").default}
                                />
                            </div>
                        
                    </ModalHeader>

                    <ModalBody className="invite-user-body">
                        <div className=" push_notification ">
                            <div className="color_picker w-100">
                                <label className="container_1 mr-4">
                                    <div>
                                        <h5>Admin</h5>
                                        <p>Best suited for business owners and company administrators. Full access and management privileges.</p>
                                    </div>
                                    
                                    <input type="radio" name="admin" value={roleid} onClick={ (e) =>handleSetRole(e, 2) }  checked={ roleid === 2  ? "true" : ""} />
                                    
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="color_picker w-100">
                                <label className="container_1 mr-4">
                                    <div>
                                        <h5>Manager</h5>
                                        <p>Ideal for those who need to manage the app without access to billing or subscription features.{" "} </p>
                                    </div>
                                    
                                    <input type="radio" name="admin" value={roleid} onClick={ (e) =>handleSetRole(e, 3) } checked={ roleid === 3  ? "true" : ""} />
                                    
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            {/* <div className="color_picker w-100 ">
                                <label className="container_1 mr-4">
                                    <div>
                                        <h5>User</h5>
                                        <p>Suited for those who need to manage the app but do not require access to billing, subscription, or app management features.</p>
                                    </div>
                                    
                                    <input type="radio" name="admin" value={roleid} onClick={ (e) =>handleSetRole(e, 4) } checked={ roleid === 4  ? "true" : ""} />
                                    
                                    <span className="checkmark"></span>
                                </label>
                            </div> */}
                        </div>
                    </ModalBody>

                    <div className="d-flex justify-content-between align-items-center cancel-user mt-4 agency-team-popup-footer">
                        <div className="cancel-btn mr-2 mr-md-0" onClick={handleCloseEditTeamModel}> Cancel </div>                        
                        <button type="submit" className="next_btn" form="userform" onClick={handleUpdateUser}  id="btnupdateid">
                            <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                <div className={btnloader}>
                                    <div className="new-loader"></div>
                                </div>
                                { btnupdatelabel }
                            </div>
                        </button>
                    </div>
                </Modal>
                
                <TeamLimitReach limitreachpop={islimitreachopen} btnCrossLimitReach={setIsLimitReachOpen} plandata={planname} />

                <Modal isOpen={mdlcustomerdeinactive} style={{ maxWidth: "480px", maxHeight: "270px" }} className="deactivate-customer-popup" centered>
                                <ModalBody>
                                    <div className="crossmodal mt-1" onClick={btnCancelCustomerDeinactivePopup}>
                                        <img
                                            src={require("../../Agency/assets/images/cross.png").default}
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <h4>Deactivate user?</h4>
                                            <h5>Deactivating this user will revoke their access.</h5>                
                                        </div>

                                        <div className="deactivate-customer-footer">

                                            <div className="customer-deactivate-btn-mob" onClick={btnCustomerDeinactive} id="btnleaveorgid">
                                                <div className={btncustomerdeinactiveloader}>
                                                    <div className="new-red-loader"></div>
                                                </div>
                                                { btncustomerdeinactivelabel }
                                            </div>

                                            <div className="customer-cancel-btn" onClick={btnCancelCustomerDeinactivePopup}>
                                                Cancel
                                            </div>

                                            <div className="customer-deactivate-btn" onClick={btnCustomerDeinactive} id="btnleaveorgid">
                                                <div className={btncustomerdeinactiveloader}>
                                                    <div className="new-red-loader"></div>
                                                </div>
                                                {btncustomerdeinactiveloader == '' ? '' : btncustomerdeinactivelabel }
                                            </div>

                                        </div>
                                    </div>
                                </ModalBody>
                            </Modal>

                            <Modal isOpen={mdlcustomerActive} style={{ maxWidth: "480px", maxHeight: "270px" }} className="activate-customer-popup" centered >
                                <ModalBody>
                                    <div className="crossmodal mt-1" onClick={btnCancelCustomerActivePopup}>
                                        <img
                                            src={require("../../Agency/assets/images/cross.png").default}
                                        />
                                    </div>
                                    <div>
                                        <div className="confirm-org-body">
                                            <h4>Activate user?</h4>
                                            <h5>Activating this user will restore their access.</h5>
                                            
                                        </div>


                                        <div className="deactivate-customer-footer">

                                            <div className="customer-activate-btn-mob" onClick={btnCustomerActive} id="btnleaveorgid">
                                                <div className={btncustomeractiveloader}>
                                                    <div className="new-loader"></div>
                                                </div>
                                                {btncustomeractiveloader == ''? '' : btncustomeractivelabel }
                                            </div>

                                            <div className="activate-customer-cancel-btn" onClick={btnCancelCustomerActivePopup}>
                                                Cancel
                                            </div>

                                            <div className="customer-activate-btn" onClick={btnCustomerActive}  id="btncustomrtactiveid">
                                                <div className={btncustomeractiveloader}>
                                                    <div className="new-loader"></div>
                                                </div>
                                                {btncustomeractiveloader == ''? '' : btncustomeractivelabel }
                                            </div>

                                        </div>

                                    </div>
                                </ModalBody>
                            </Modal>
                
                <Modal isOpen={mdlcustomerdeactive} style={{ maxWidth: "480px", maxHeight: "270px" }} className="deactivate-customer-popup" centered>
                                <ModalBody>
                                    <div className="crossmodal mt-1" onClick={btnCancelCustomerDeactivePopup}>
                                        <img
                                            src={require("../assets/images/cross.png").default}
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <h4>Delete this user?</h4>
                                            <h5>Are you sure you want to delete this user? Once deleted, the user will lose access to the app.</h5>                
                                        </div>

                                        <div className="deactivate-customer-footer">

                                            <div className="customer-deactivate-btn-mob" onClick={handleDeleteUserPopup} id="btnleaveorgid">
                                                <div className={btncustomerdeactiveloader}>
                                                    <div className="new-red-loader"></div>
                                                </div>
                                                { btncustomerdeactivelabel }
                                            </div>

                                            <div className="customer-cancel-btn" onClick={btnCancelCustomerDeactivePopup}>
                                                Cancel
                                            </div>

                                            <div className="customer-deactivate-btn" onClick={handleDeleteUserPopup} id="btnleaveorgid">
                                                <div className={btncustomerdeactiveloader}>
                                                    <div className="new-red-loader"></div>
                                                </div>
                                                {btncustomerdeactiveloader == '' ? '' : btncustomerdeactivelabel }
                                            </div>

                                        </div>
                                    </div>
                                </ModalBody>
                            </Modal>
            </div>
        </div>  
    </div>

    );
    
}

export default AgencyTeams;