import { convertHTMLEntity } from "../../../utils/commonUtils";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import BottomScrollListener from "react-bottom-scroll-listener";
import { useState } from "react";
import ChildArrow from "../../commonIcons/ChildArrow";
import AmsSpinner from "../../commonIcons/AmsSpinner";
import BackArrow from "../../commonIcons/BackArrow";
import PopUpHomeIcon from "../../commonIcons/PopUpHomeIcon";

export const BottomLinkPopUp = ({
  item,
  openSingleSelectionPopUp,
  handleOpenSelectionPopUp,
  handleMainOptionsArrowClick,
  handleMainOptionChange,
  handleChildArrowClick,
  handleChildOptionChange,
  handleBottomScrollListner,
  handleHomeBtnClick,
  handleFirstLabelClick,
  handleBreadcrumb,
  handleMobBackBtn,
  defaultOptionData,
  taxonomyTypeMergeData,
  typeArray,
  paginationSpinner,
  is_chat_enabled,
  keys_data,
}) => {
  const [searchVal, setSearchVal] = useState("");

  const handlePopUpState = (buttonFrom, id) => {
    setSearchVal("");
    handleOpenSelectionPopUp(buttonFrom, id);
  };

  const handleSearchVal = (e) => {
    setSearchVal(e.target.value);
  };

  const handleReturnTitle = (res) => {
    let currTitle = "";
    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      currTitle = res.title || res.name;
    } else {
      currTitle = convertHTMLEntity(res.title?.rendered || res.name);
    }
    return currTitle;
  };

  const filterArrForDefaultOption =
    defaultOptionData &&
    defaultOptionData.filter((row) =>
      (row.item_name || row.name)
        .toLowerCase()
        .includes(searchVal.toLowerCase())
    );
  const filterArrForMergeArr =
    taxonomyTypeMergeData &&
    taxonomyTypeMergeData.filter(
      (row) =>
        row.name.toLowerCase().includes(searchVal.toLowerCase()) &&
        !row.name.toLowerCase().includes("search") &&
        !row.name.toLowerCase().includes("navigation") &&
        !row.name.toLowerCase().includes("pattern") &&
        !row.name.toLowerCase().includes("template") &&
        !row.name.toLowerCase().includes("font")
    );

  const filterArrForSubData =
    item &&
    item.temp_subData &&
    Array.isArray(item.temp_subData) &&
    item.temp_subData.filter((row) => {
      if (
        (parseInt(localStorage.getItem("website_technology")) === 3 &&
          row.status !== "draft" &&
          row.show_in_results !== 0) ||
        parseInt(localStorage.getItem("website_technology")) !== 3
      ) {
        if (
          handleReturnTitle(row).toLowerCase().includes(searchVal.toLowerCase())
        ) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    });

  return item ? (
    <div>
      <Modal
        className="Single-select-popup"
        isOpen={openSingleSelectionPopUp}
        centered
        toggle={() => handlePopUpState("cancel", item.id)}
      >
        <ModalHeader>
          <div className="single-select-header">
            <h2>Link</h2>

            <div className="mob-single-select-top">
              <div className="single-select-bredcrums">
                {!item.temp_showL1Options && (
                  <div onClick={(e) => handleHomeBtnClick(e, item.id)}>
                    <p>Home &gt;</p>
                  </div>
                )}
                {!item.temp_showL1Options && (
                  <p
                    className={
                      !item.temp_breadcrumbArr.length ||
                      !item.temp_breadcrumbArr
                        ? "single-select-bredcrumchild"
                        : ""
                    }
                    onClick={(e) =>
                      item.temp_breadcrumbArr.length
                        ? handleFirstLabelClick(e, item.id)
                        : null
                    }
                  >
                    {item.temp_module_label}{" "}
                  </p>
                )}
                {item.temp_breadcrumbArr &&
                  item.temp_breadcrumbArr.map((val, indx) => {
                    return (
                      <div
                        key={indx}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        &gt;
                        <p
                          className={
                            indx === item.temp_breadcrumbArr.length - 1
                              ? "single-select-bredcrumchild"
                              : ""
                          }
                          onClick={(e) =>
                            indx === item.temp_breadcrumbArr.length - 1
                              ? null
                              : handleBreadcrumb(e, val, indx, item.id)
                          }
                        >
                          {val.name}
                        </p>
                      </div>
                    );
                  })}
              </div>

              <div className="mob-single-select-search">
                <div onClick={(e) => handleMobBackBtn(e, item.id)}>
                  <BackArrow />
                </div>
                <div className="singleselect-serachdiv">
                  <input
                    placeholder="Search"
                    value={searchVal}
                    onChange={(e) => handleSearchVal(e)}
                  />
                  {item.temp_item_type &&
                  window.matchMedia("(max-width: 1200px)").matches ? (
                    <p
                      className={
                        item.temp_item_type
                          ? "mob-popUp-done-enable"
                          : "mob-popUp-done-disable"
                      }
                      onClick={() =>
                        item.temp_item_type
                          ? handlePopUpState("done", item.id)
                          : null
                      }
                    >
                      Done
                    </p>
                  ) : (
                    searchVal && (
                      <p
                        className="Single-selct-search-clear"
                        onClick={() => {
                          setSearchVal("");
                        }}
                      >
                        Clear
                      </p>
                    )
                  )}
                </div>
                <div
                  className="single-select-serach-crossdiv"
                  onClick={() => handlePopUpState("cancel", item.id)}
                >
                  <img
                    src={
                      require("../../../assets/images/dashboard/cross.png")
                        .default
                    }
                  />
                </div>
              </div>
            </div>

            <div
              className="publish-crossmodal single-select-web-cross"
              onClick={() => handlePopUpState("cancel", item.id)}
              style={{
                zIndex: "9",
                cursor: "pointer",
              }}
            >
              <img
                src={
                  require("../../../assets/images/dashboard/cross.png").default
                }
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <div>
              {item.temp_showL1Options ? (
                (!filterArrForDefaultOption ||
                  !filterArrForDefaultOption.length) &&
                (!filterArrForMergeArr || !filterArrForMergeArr) ? (
                  <div className="select-no-data-found 22">
                    <img
                      src={
                        require("../../../assets/images/dashboard/no-media.png")
                          .default
                      }
                    />
                    <p>No results found</p>
                  </div>
                ) : (
                  <div>
                    {!searchVal && (
                      <h2 className="single-select-body-header">
                        Default options
                      </h2>
                    )}
                    {filterArrForDefaultOption &&
                      filterArrForDefaultOption.map((res, indx) => {
                        return ((res.item_type === "product_categories" ||
                          res.item_type === "pages" ||
                          res.item_type === "product_search") &&
                          taxonomyTypeMergeData &&
                          taxonomyTypeMergeData.length > 0) ||
                          (is_chat_enabled !== 1 && res.item_type === "chat") ||
                          (parseInt(
                            localStorage.getItem("website_technology")
                          ) === 4 &&
                            (keys_data === null ||
                              keys_data.is_connected !== 1 ||
                              keys_data.authorization_type !== 2) &&
                            res.item_type === "profile") ? null : (
                          <div key={indx} className="single-select-maindiv">
                            <label
                              className="Single-select-container"
                              data={is_chat_enabled}
                              type={res}
                            >
                              {res.item_name ? res.item_name : res.name}
                              <input
                                type="radio"
                                checked={
                                  res[
                                    // parseInt(
                                    //   localStorage.getItem("website_technology")
                                    // ) === 3
                                    //   ? "type"
                                    //   :
                                    "item_type"
                                  ] === item.temp_item_type &&
                                  (!item.temp_invidual_selected_item_id ||
                                    item.temp_invidual_selected_item_id ===
                                      "all")
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  handleMainOptionChange(
                                    e,
                                    item.id,
                                    res,
                                    "default-options"
                                  )
                                }
                              />
                              <span className="Single-select-checkmark"></span>
                            </label>
                            {res.type && res.type.includes("cms_") && (
                              <div
                                className="subdata-svg-container"
                                onClick={(e) => {
                                  if (res.type && res.type.includes("cms_")) {
                                    handleMainOptionsArrowClick(
                                      e,
                                      item.id,
                                      res
                                    );
                                    setSearchVal("");
                                  }
                                }}
                              >
                                <ChildArrow
                                  strokeColor={"#bcc1ce"}
                                  opacity={"1"}
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}

                    {taxonomyTypeMergeData &&
                      taxonomyTypeMergeData.length > 0 &&
                      !searchVal && (
                        <h2 className="single-select-second-header">
                          Post & taxonomies
                        </h2>
                      )}
                    {filterArrForMergeArr &&
                      filterArrForMergeArr.map((res, k) => {
                        return (
                          <div key={k} className="single-select-maindiv">
                            <label className="Single-select-container">
                              {res.name}
                              <input
                                type="radio"
                                checked={
                                  res.type +
                                    res.slug +
                                    res.rest_base +
                                    res.post_type ===
                                    item.temp_item_type +
                                      item.temp_slug +
                                      item.temp_rest_base +
                                      item.temp_post_type &&
                                  (!item.temp_invidual_selected_item_id ||
                                    item.temp_invidual_selected_item_id ===
                                      "all")
                                    ? true
                                    : false
                                }
                                onChange={(e) =>
                                  handleMainOptionChange(
                                    e,
                                    item.id,
                                    res,
                                    "posts-taxonomies"
                                  )
                                }
                              />
                              <span className="Single-select-checkmark"></span>
                            </label>
                            {((res.type !== "search" && res.rest_base) ||
                              (res.type && res.type.includes("cms_"))) && (
                              <div
                                className="subdata-svg-container"
                                onClick={(e) => {
                                  handleMainOptionsArrowClick(e, item.id, res);
                                  setSearchVal("");
                                }}
                              >
                                <ChildArrow
                                  strokeColor={"#bcc1ce"}
                                  opacity={"1"}
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                )
              ) : item.subdropdownSpinner ? (
                <div className="single-select-popup-spin">
                  <AmsSpinner spinWidth={"200px"} spinHeight={"200px"} />
                </div>
              ) : !filterArrForSubData || filterArrForSubData.length === 0 ? (
                <div className="select-no-data-found 22">
                  <img
                    src={
                      require("../../../assets/images/dashboard/no-media.png")
                        .default
                    }
                  />
                  <p>No results found</p>
                </div>
              ) : (
                <BottomScrollListener
                  onBottom={(e) => handleBottomScrollListner(e, item)}
                  offset={100}
                >
                  {(scrollRef) => (
                    <div
                      className="scroll-div single-child-scrolldiv"
                      ref={scrollRef}
                    >
                      {filterArrForSubData &&
                        filterArrForSubData.map((res, indx) => {
                          return (
                            (res.title?.rendered || res.name || (parseInt(localStorage.getItem("website_technology")) === 3 && res.title)) &&
                            <div key={indx} className="single-select-maindiv">
                              <label className="Single-select-container">
                                {parseInt(
                                  localStorage.getItem("website_technology")
                                ) === 3
                                  ? res.title || res.name
                                  : convertHTMLEntity(
                                      res.title?.rendered || res.name
                                    )}

                                <input
                                  type="radio"
                                  checked={
                                    parseInt(
                                      localStorage.getItem("website_technology")
                                    ) === 3
                                      ? res._id ==
                                        item.temp_invidual_selected_item_id
                                      : res.id
                                      ? res.id ==
                                        item.temp_invidual_selected_item_id
                                      : res.term_id ==
                                        item.temp_invidual_selected_item_id
                                  }
                                  onChange={(e) =>
                                    handleChildOptionChange(e, res, item)
                                  }
                                />
                                <span className="Single-select-checkmark"></span>
                              </label>
                              {((parseInt(
                                localStorage.getItem("website_technology")
                              ) === 3 &&
                                res.child &&
                                res.child.length > 0) ||
                                (parseInt(
                                  localStorage.getItem("website_technology")
                                ) !== 3 &&
                                  res.children &&
                                  res.children.length > 0)) && (
                                <div
                                  className="subdata-svg-container"
                                  onClick={(e) =>
                                    handleChildArrowClick(e, res, item.id)
                                  }
                                >
                                  <ChildArrow
                                    strokeColor={"#bcc1ce"}
                                    opacity={"1"}
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })}

                      {!item.subdropdownSpinner && paginationSpinner ? (
                        <AmsSpinner spinWidth={"200px"} spinHeight={"100px"} />
                      ) : null}
                    </div>
                  )}
                </BottomScrollListener>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="Single-select-cancel-btn "
            onClick={() => handlePopUpState("cancel", item.id)}
          >
            Cancel
          </button>
          <div
            className="d-flex"
            style={{ margin: "0px", alignItems: "center" }}
          >
            <p className="single-select-selected">
              {item.temp_item_type ? "1 item selected" : ""}
            </p>
            <button
              className={
                item.temp_item_type
                  ? "Single-select-done-btn"
                  : "Single-select-done-disable"
              }
              disabled={item.temp_item_type ? false : true}
              onClick={() => handlePopUpState("done", item.id)}
            >
              Done
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  ) : (
    <></>
  );
};
