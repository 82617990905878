import React from "react";
import Tooltip from "../Tooltip";
import { Modal, ModalHeader, ModalBody, Spinner } from "reactstrap";
import { errortoast } from "../Toaster";
import {
  androidCustomBundle,
  iosCustomBundle,
  getFormData,
} from "../../Api/Custombundle/Custombundle";
class Custombundle extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      plan_code: props.plan_code,
      submitval: "Done",
      loader: "d-none",
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      modalreset: false,
      submitiosval: "Done",
      loaderios: "d-none",
      submitbuttontext: "Done",
      loaderiosimage: require("../../assets/images/signup/Loader_GIF.gif")
        .default,
      disabledsubmitbutton: false,
      disablediossubmitbutton: false,
      //Android states
      bundle_id: "",

      current_version_code: "",
      current_version_name: "",
      status: undefined,

      is_reset: 0,

      is_success: 0,
      resetbuttonandroid: 0,
      //Android states end

      //iOS states
      ios_bundle_id: "",
      iOS_current_version_code: "",
      iosstatus: undefined,
      modalandroid: false,
      modalios: false,
      is_iossuccess: 0,
      resettype: undefined,
      resetbuttonios: 0,
      is_iosreset: 0,
      is_app_already_published: "",
      is_app_already_published_ios: "",
    };
  }
  UNSAFE_componentWillReceiveProps({ plan_code }) {
    this.setState({
      plan_code: plan_code,
    });
  }
  getandroidformdatas = () => {
    const androidparams = {
      type: "android",
    };
    getFormData(androidparams, this);
  };
  getiosformdatas = () => {
    const params = {
      type: "ios",
    };
    getFormData(params, this, () => {
      this.props.storeiosdata(this.state.ios_bundle_id, this.state.iosstatus);
    });
  };
  componentDidMount() {
    this.getiosformdatas();
  }
  stopSpinner = () => {
    this.props.stoploader();
  };
  handlecheck = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handlestatus = (e) => {
    this.setState({
      status: !this.state.status,
    });
  };
  togglemodalreset = (restype) => {
    this.setState({
      modalreset: !this.state.modalreset,
      resettype: restype,
    });
  };
  handleiosstatus = (e) => {
    this.setState({
      iosstatus: !this.state.iosstatus,
    });
  };

  androidformsubmit = (e) => {
    e.preventDefault();
    // Send jks file to AWS
    if (this.state.jks_file === "") {
      errortoast("Please upload Keystore File");
    } else {
      const params = {
        app_id: parseInt(localStorage.getItem("Appid")),

        status: this.state.status === true ? 1 : 0,
        bundle_id: this.state.bundle_id.trim(),
        current_version_code: parseInt(this.state.current_version_code.trim()),
        current_version_name: this.state.current_version_name.trim(),
        is_reset: this.state.is_reset,
        is_app_already_published:
          this.state.is_app_already_published == true ? 1 : 0,
      };
      androidCustomBundle(params, this);
    }
  };

  iOSformsubmit = (e) => {
    e.preventDefault();
    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      bundle_id: this.state.ios_bundle_id.trim(),
      current_version: this.state.iOS_current_version_code.trim(),
      status: this.state.iosstatus === true ? 1 : 0,
      type: "ios",
      is_reset: this.state.is_iosreset,
      is_app_already_published:
        this.state.is_app_already_published_ios === true ? 1 : 0,
    };
    iosCustomBundle(params, this, () => {
      // this.props.callloader();
      // this.getiosformdatas()
    });
  };
  togglemodalios = (e) => {
    e.preventDefault();
    this.setState(
      {
        modalios: !this.state.modalios,
        spinner: true,
      },
      () => {
        if (this.state.modalios === true) {
          this.getiosformdatas();
        }
      }
    );
  };
  togglemodalandroid = (e) => {
    e.preventDefault();

    this.setState(
      {
        modalandroid: !this.state.modalandroid,
        spinner: true,
      },
      () => {
        if (this.state.modalandroid === true) {
          this.getandroidformdatas();
        }
      }
    );
  };
  handlereset = () => {
    if (this.state.resettype === "android") {
      this.setState({
        bundle_id: "",
        current_version_code: "",
        current_version_name: "",

        is_reset: 1,
        modalreset: false,
        is_success: 0,
        disabledsubmitbutton: false,
      });
    } else {
      this.setState({
        ios_bundle_id: "",
        iOS_current_version_code: "",

        is_iossuccess: 0,
        disablediossubmitbutton: false,
        is_iosreset: 1,
        modalreset: false,
      });
    }
  };

  handleLiveAppToggle = (e) => {
    this.setState({
      is_app_already_published: e.target.checked === true ? true : false,
    });
  };
  handleLiveAppToggleIOS = (e) => {
    this.setState({
      is_app_already_published_ios: e.target.checked === true ? true : false,
    });
  };

  render() {
    return (
      <>
        <div className="d-md-flex d-block align-items-center justify-content-end">
          {this.state.plan_code !== "preview" ? (
            <div className="app-exists d-flex  align-items-center justify-content-start pl-3 ">
              <p>Bundle ID Configuration</p>
              <div
                className="ml-3 app-exists-icon android-icon"
                onClick={this.togglemodalandroid}
              >
                <svg width="21.174" height="25.056" viewBox="0 0 21.174 25.056">
                  <g
                    id="Group_4452"
                    data-name="Group 4452"
                    transform="translate(-1791.351 -137)"
                  >
                    <path
                      id="Path_7961"
                      data-name="Path 7961"
                      d="M1.568,17.216A1.479,1.479,0,0,1,0,15.845V9.978A1.48,1.48,0,0,1,1.568,8.605,1.481,1.481,0,0,1,3.135,9.978v5.867A1.48,1.48,0,0,1,1.568,17.216Zm11.858,5.737L14.8,24.32a.432.432,0,1,1-.612.61L12.5,23.247a8,8,0,0,1-3.782,0L7.033,24.93a.432.432,0,0,1-.612-.61l1.363-1.359A5.524,5.524,0,0,1,3.93,18c0-.027,13.332,0,13.332,0A5.516,5.516,0,0,1,13.425,22.954Zm-4.79-3.389a.783.783,0,1,0,.784.783A.783.783,0,0,0,8.635,19.565Zm3.922,0a.783.783,0,1,0,.784.783A.783.783,0,0,0,12.557,19.565Zm7.05-2.349a1.48,1.48,0,0,1-1.568-1.372V9.978a1.582,1.582,0,0,1,3.135,0v5.867A1.48,1.48,0,0,1,19.607,17.216ZM3.93,7.83A2.35,2.35,0,0,1,6.275,5.483V1.372A1.48,1.48,0,0,1,7.842,0,1.479,1.479,0,0,1,9.409,1.372V5.483h2.356V1.372A1.48,1.48,0,0,1,13.333,0,1.479,1.479,0,0,1,14.9,1.372V5.483h.01A2.35,2.35,0,0,1,17.262,7.83v9.389H3.931V7.83Z"
                      transform="translate(1812.525 162.057) rotate(180)"
                      fill="#c8cedb"
                    />
                  </g>
                </svg>
              </div>
              {this.state.plan_code === "all" && (
                <div
                  className="ml-3 app-exists-icon ios-icon"
                  onClick={this.togglemodalios}
                >
                  <svg width="18.32" height="21.885" viewBox="0 0 18.32 21.885">
                    <g
                      id="Group_4452"
                      data-name="Group 4452"
                      transform="translate(-1860 -126)"
                    >
                      <path
                        id="Union_292"
                        data-name="Union 292"
                        d="M-1988.067,18.479A13.485,13.485,0,0,1-1990,11.863a7.807,7.807,0,0,1,1.486-4.919,4.541,4.541,0,0,1,3.736-1.894,8.758,8.758,0,0,1,2.327.394,8.492,8.492,0,0,0,1.815.4,6.6,6.6,0,0,0,1.881-.447,7.4,7.4,0,0,1,2.274-.447,4.809,4.809,0,0,1,2.8.854,6.785,6.785,0,0,1,1.368,1.316,8.482,8.482,0,0,0-1.5,1.552,4.683,4.683,0,0,0-.855,2.723,5.012,5.012,0,0,0,.907,2.933,4,4,0,0,0,2.078,1.657,12.689,12.689,0,0,1-1.617,3.288q-1.7,2.578-3.38,2.578a6.069,6.069,0,0,1-1.841-.42A5.822,5.822,0,0,0-1980.5,21a5.072,5.072,0,0,0-1.868.434,4.759,4.759,0,0,1-1.736.447Q-1986.107,21.885-1988.067,18.479Zm8.075-16.532A5.246,5.246,0,0,1-1976.7,0a1.111,1.111,0,0,1,.033.145,1.257,1.257,0,0,0,.033.144c0,.035,0,.079.006.131s.006.1.006.132a5.02,5.02,0,0,1-.381,1.788,5.407,5.407,0,0,1-1.224,1.815,3.716,3.716,0,0,1-1.42.947,7.34,7.34,0,0,1-1.368.223A6.006,6.006,0,0,1-1979.992,1.947Z"
                        transform="translate(3850 126)"
                        fill="#c8cedb"
                      />
                    </g>
                  </svg>
                </div>
              )}
            </div>
          ) : (
            <div className="app-exists d-flex  align-items-center justify-content-start pl-3 ">
              <p>Bundle ID Configuration</p>
              <div className="ml-3 app-exists-icon android-icon">
                <svg width="21.174" height="25.056" viewBox="0 0 21.174 25.056">
                  <g
                    id="Group_4452"
                    data-name="Group 4452"
                    transform="translate(-1791.351 -137)"
                  >
                    <path
                      id="Path_7961"
                      data-name="Path 7961"
                      d="M1.568,17.216A1.479,1.479,0,0,1,0,15.845V9.978A1.48,1.48,0,0,1,1.568,8.605,1.481,1.481,0,0,1,3.135,9.978v5.867A1.48,1.48,0,0,1,1.568,17.216Zm11.858,5.737L14.8,24.32a.432.432,0,1,1-.612.61L12.5,23.247a8,8,0,0,1-3.782,0L7.033,24.93a.432.432,0,0,1-.612-.61l1.363-1.359A5.524,5.524,0,0,1,3.93,18c0-.027,13.332,0,13.332,0A5.516,5.516,0,0,1,13.425,22.954Zm-4.79-3.389a.783.783,0,1,0,.784.783A.783.783,0,0,0,8.635,19.565Zm3.922,0a.783.783,0,1,0,.784.783A.783.783,0,0,0,12.557,19.565Zm7.05-2.349a1.48,1.48,0,0,1-1.568-1.372V9.978a1.582,1.582,0,0,1,3.135,0v5.867A1.48,1.48,0,0,1,19.607,17.216ZM3.93,7.83A2.35,2.35,0,0,1,6.275,5.483V1.372A1.48,1.48,0,0,1,7.842,0,1.479,1.479,0,0,1,9.409,1.372V5.483h2.356V1.372A1.48,1.48,0,0,1,13.333,0,1.479,1.479,0,0,1,14.9,1.372V5.483h.01A2.35,2.35,0,0,1,17.262,7.83v9.389H3.931V7.83Z"
                      transform="translate(1812.525 162.057) rotate(180)"
                      fill="#c8cedb"
                    />
                  </g>
                </svg>
              </div>

              <div className="ml-3 app-exists-icon ios-icon">
                <svg width="18.32" height="21.885" viewBox="0 0 18.32 21.885">
                  <g
                    id="Group_4452"
                    data-name="Group 4452"
                    transform="translate(-1860 -126)"
                  >
                    <path
                      id="Union_292"
                      data-name="Union 292"
                      d="M-1988.067,18.479A13.485,13.485,0,0,1-1990,11.863a7.807,7.807,0,0,1,1.486-4.919,4.541,4.541,0,0,1,3.736-1.894,8.758,8.758,0,0,1,2.327.394,8.492,8.492,0,0,0,1.815.4,6.6,6.6,0,0,0,1.881-.447,7.4,7.4,0,0,1,2.274-.447,4.809,4.809,0,0,1,2.8.854,6.785,6.785,0,0,1,1.368,1.316,8.482,8.482,0,0,0-1.5,1.552,4.683,4.683,0,0,0-.855,2.723,5.012,5.012,0,0,0,.907,2.933,4,4,0,0,0,2.078,1.657,12.689,12.689,0,0,1-1.617,3.288q-1.7,2.578-3.38,2.578a6.069,6.069,0,0,1-1.841-.42A5.822,5.822,0,0,0-1980.5,21a5.072,5.072,0,0,0-1.868.434,4.759,4.759,0,0,1-1.736.447Q-1986.107,21.885-1988.067,18.479Zm8.075-16.532A5.246,5.246,0,0,1-1976.7,0a1.111,1.111,0,0,1,.033.145,1.257,1.257,0,0,0,.033.144c0,.035,0,.079.006.131s.006.1.006.132a5.02,5.02,0,0,1-.381,1.788,5.407,5.407,0,0,1-1.224,1.815,3.716,3.716,0,0,1-1.42.947,7.34,7.34,0,0,1-1.368.223A6.006,6.006,0,0,1-1979.992,1.947Z"
                      transform="translate(3850 126)"
                      fill="#c8cedb"
                    />
                  </g>
                </svg>
              </div>
            </div>
          )}
        </div>
        {/* Android Form */}
        <Modal
          isOpen={this.state.modalandroid}
          toggle={this.togglemodalandroid}
          style={{ maxWidth: "700px", maxHeight: "900px" }}
          className={this.props.className}
          centered
          dataclass="appsexists"
        >
          <ModalHeader
            toggle={this.togglemodalandroid}
            className="modal-xxl-content"
          >
            <div className="d-flex justify-content-start align-items-start">
              <img
                src={
                  require("../../assets/images/dashboard/android-logo.png")
                    .default
                }
                alt="android-logo"
                className="img-fluid d-none d-md-flex"
              />
              <div className="app-exists-modal">
                <h3>Configure your Android Bundle ID</h3>
                <h5 className="d-none d-md-block">
                  Use this feature if you are migrating your live app or would
                  like to customize your Bundle ID.{" "}
                </h5>
              </div>
            </div>

            <div className="crossmodal mt-1" onClick={this.togglemodalandroid}>
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          {this.state.spinner ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "200px" }}
            >
             <div className="newspinner">
               <svg xmlns="http://www.w3.org/2000/svg" 
         xlink="http://www.w3.org/1999/xlink" 
         style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
         width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
         <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
           <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
           style={{animationplaystate: "running", animationdelay: "0s"}}>
           </animateTransform>
         </circle>
         </g>
         <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
         style={{animationplaystate: "running", animationdelay: "0s"}}>
         </animateTransform>
         </circle>
         </g>
         <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
         style={{animationplaystate: "running", animationdelay: "0s"}}>
 
         </animateTransform>
         </circle>
         </g>
         <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
         style={{animationplaystate: "running", animationdelay: "0s"}}>
 
         </animateTransform>
         </circle>
         </g>
         </svg>
             </div>
            </div>
          ) : (
            <>
              <ModalBody
                className="upload_files"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="d-flex align-items-center justify-content-start force-auth live-app-padding mt-0">
                  <div className="on_off d-flex  align-items-center">
                    <label className="switch" htmlFor="isliveapp">
                      <input
                        type="checkbox"
                        id="isliveapp"
                        form="iOSform"
                        checked={this.state.is_app_already_published}
                        onClick={(e) => this.handleLiveAppToggle(e)}
                      />
                      <div className="slider round"></div>
                    </label>
                  </div>
                  <Tooltip
                    firsttag={"h4"}
                    tagclass={"mb-0 mr-2 ml-3"}
                    classdata={
                      "d-flex justify-content-start align-items-center"
                    }
                    heading={"I am migrating my live app"}
                    content={"Already have a live app"}
                  />
                </div>

                <form id="androidform" onSubmit={this.androidformsubmit}>
                  <div className="form-group">
                    <Tooltip
                      firsttag={"h4"}
                      tagclass={"mb-0 mr-2 ml-0"}
                      classdata={
                        "d-flex justify-content-start align-items-center"
                      }
                      heading={"Package Name"}
                      content={
                        "The package name is a unique name that identifies your live app. Generally, the package name of an app is in the format domain.company.application ."
                      }
                    />
                    <input
                      type="text"
                      placeholder="e.g. com.appname.app"
                      readOnly={this.state.is_success !== 0 ? true : false}
                      className="form-control form-name"
                      name="bundle_id"
                      required
                      value={this.state.bundle_id}
                      onChange={(e) => this.handlecheck(e)}
                    />
                  </div>
                  <div className="d-md-flex justify-content-between">
                    <div className="form-group color_picker">
                      <div className="d-flex">
                        <Tooltip
                          firsttag={"h4"}
                          tagclass={"mb-0 mr-2 ml-0"}
                          classdata={
                            "d-flex justify-content-start align-items-center"
                          }
                          heading={"Version Name"}
                          content={
                            "The Android Version Name value represents the release version of the app code and is visible on the Google Play Store."
                          }
                        />
                      </div>
                      <input
                        type="text"
                        readOnly={this.state.is_success !== 0 ? true : false}
                        placeholder="e.g. 1.0.0"
                        className="form-control form-name"
                        name="current_version_name"
                        required
                        value={this.state.current_version_name}
                        onChange={(e) => this.handlecheck(e)}
                      />
                      {/* <div className="nxt-version"><p>Next version name: 1.0.1</p></div> */}
                    </div>
                    <div className="form-group color_picker">
                      <Tooltip
                        firsttag={"h4"}
                        tagclass={"mb-0 mr-2 ml-0"}
                        classdata={
                          "d-flex justify-content-start align-items-center"
                        }
                        heading={"Version Code"}
                        content={
                          "The Android Version Code represents the version of the app code relative to other versions and is not visible to others."
                        }
                      />

                      <input
                        type="text"
                        readOnly={this.state.is_success !== 0 ? true : false}
                        placeholder="e.g. 1"
                        className="form-control form-name"
                        name="current_version_code"
                        pattern="[0-9]+"
                        title="Version code should be number"
                        required
                        value={this.state.current_version_code}
                        onChange={(e) => this.handlecheck(e)}
                      />
                      {/* <div className="nxt-version"><p>Next version code: 2</p></div> */}
                    </div>
                  </div>
                </form>
              </ModalBody>
              <div
                className="d-block app-resubmit d-md-flex justify-content-between align-items-center upload_files  ios-modal-bottom"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="d-flex align-items-center justify-content-start force-auth mt-0">
                  <div className="on_off d-flex  align-items-center">
                    <label className="switch" htmlFor="rebuild">
                      <input
                        type="checkbox"
                        id="rebuild"
                        form="androidform"
                        defaultChecked={this.state.status}
                        onClick={(e) => this.handlestatus(e)}
                      />
                      <div className="slider round"></div>
                    </label>
                  </div>

                  <Tooltip
                    firsttag={"h4"}
                    tagclass={"mb-0 mr-2 ml-3"}
                    classdata={
                      "d-flex justify-content-start align-items-center"
                    }
                    heading={"Enable during next build"}
                    content={
                      "If disabled, your app build will be created with the standard settings"
                    }
                  />
                </div>
                {/* New Toggle */}

                <div className="d-flex justify-content-between align-items-center">
                  {this.state.resetbuttonandroid !== 0 && (
                    <p
                      className="reset-btn"
                      onClick={(e) => this.togglemodalreset("android")}
                    >
                      Reset
                    </p>
                  )}
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <button
                      type="submit"
                      className="next_btn"
                      form="androidform"
                      disabled={this.state.disabledsubmitbutton}
                      style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                    >
                      <div className="d-flex align-items-center justify-content-center h-100 w-100">
                        <div className={this.state.loader}>
                          <img src={this.state.loaderimage} alt="loader" />
                        </div>{" "}
                        <div>{this.state.submitval}</div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal>
        {/* Android Form End*/}

        {/* iOS form */}
        <Modal
          isOpen={this.state.modalios}
          toggle={this.togglemodalios}
          style={{ maxWidth: "700px", maxHeight: "850px" }}
          className={this.props.className}
          centered
          dataclass="appsexists"
        >
          <ModalHeader
            toggle={this.togglemodalios}
            className="modal-xxl-content ios-head"
          >
            <div className="d-flex justify-content-start align-items-start">
              <img
                src={
                  require("../../assets/images/dashboard/apple-logo.png")
                    .default
                }
                alt="android-logo"
                className="img-fluid d-none d-md-flex"
              />
              <div className="app-exists-modal">
                <h3>Configure your iOS Bundle ID</h3>
                <h5 className="d-none d-md-block">
                  Use this feature if you are migrating your live app or would
                  like to customize your Bundle ID.
                </h5>
              </div>
            </div>

            <div className="crossmodal mt-1" onClick={this.togglemodalios}>
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          {this.state.spinner ? (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ height: "221px" }}
            >
              <div className="newspinner">
               <svg xmlns="http://www.w3.org/2000/svg" 
         xlink="http://www.w3.org/1999/xlink" 
         style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
         width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
         <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
           <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
           style={{animationplaystate: "running", animationdelay: "0s"}}>
           </animateTransform>
         </circle>
         </g>
         <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
         style={{animationplaystate: "running", animationdelay: "0s"}}>
         </animateTransform>
         </circle>
         </g>
         <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
         style={{animationplaystate: "running", animationdelay: "0s"}}>
 
         </animateTransform>
         </circle>
         </g>
         <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
         style={{animationplaystate: "running", animationdelay: "0s"}}>
 
         </animateTransform>
         </circle>
         </g>
         </svg>
             </div>
            </div>
          ) : (
            <>
              <ModalBody
                className="upload_files"
                style={{ backgroundColor: "#fff" }}
              >
                <form id="iOSform" onSubmit={this.iOSformsubmit}>
                  <div className="d-flex  align-items-center live-app-padding">
                    <div className="on_off d-flex  align-items-center">
                      <label className="switch" htmlFor="isliveapp">
                        <input
                          type="checkbox"
                          id="isliveapp"
                          form="iOSform"
                          checked={this.state.is_app_already_published_ios}
                          onClick={(e) => this.handleLiveAppToggleIOS(e)}
                        />
                        <div className="slider round"></div>
                      </label>
                    </div>
                    <Tooltip
                      firsttag={"h4"}
                      tagclass={"mb-0 mr-2 ml-3"}
                      classdata={
                        "d-flex justify-content-start align-items-center"
                      }
                      heading={"I am migrating my live app"}
                      content={"Already have a live app"}
                    />
                  </div>
                  <div className="form-group">
                    <Tooltip
                      firsttag={"h4"}
                      tagclass={"mb-0 mr-2 ml-0"}
                      classdata={
                        "d-flex justify-content-start align-items-center"
                      }
                      heading={"Bundle ID"}
                      content={
                        "Your bundle ID is your unique identifier of your app . After the app is submitted to iTunes Connect it is registered with the bundle ID as it is unique identifier. "
                      }
                    />
                    <input
                      type="text"
                      placeholder="e.g. com.appname.app"
                      readOnly={this.state.is_iossuccess !== 0 ? true : false}
                      className="form-control form-name"
                      required
                      name="ios_bundle_id"
                      value={this.state.ios_bundle_id}
                      onChange={(e) => this.handlecheck(e)}
                    />
                  </div>
                  <div className="form-group">
                    <Tooltip
                      firsttag={"h4"}
                      tagclass={"mb-0 mr-2 ml-0"}
                      classdata={
                        "d-flex justify-content-start align-items-center"
                      }
                      heading={"Build Version"}
                      content={
                        "The iOS Build Version is shown in the App Store and that version should match the version number you enter later in App Store Connect.  "
                      }
                    />
                    <input
                      type="text"
                      placeholder="e.g. 1.0.0"
                      readOnly={this.state.is_iossuccess !== 0 ? true : false}
                      className="form-control form-name"
                      required
                      name="iOS_current_version_code"
                      value={this.state.iOS_current_version_code}
                      onChange={(e) => this.handlecheck(e)}
                    />
                  </div>
                </form>
              </ModalBody>
              <div
                className="d-block app-resubmit d-md-flex justify-content-between align-items-center upload_files  ios-modal-bottom"
                style={{ backgroundColor: "#fff" }}
              >
                <div className="d-flex align-items-center justify-content-start force-auth mt-0">
                  <div className="on_off d-flex  align-items-center">
                    <label className="switch" htmlFor="rebuild">
                      <input
                        type="checkbox"
                        id="rebuild"
                        form="iOSform"
                        defaultChecked={this.state.iosstatus}
                        onClick={(e) => this.handleiosstatus(e)}
                      />
                      <div className="slider round"></div>
                    </label>
                  </div>
                  <Tooltip
                    firsttag={"h4"}
                    tagclass={"mb-0 mr-2 ml-3"}
                    classdata={
                      "d-flex justify-content-start align-items-center"
                    }
                    heading={"Enable during next build"}
                    content={
                      "If disabled, your app build will be created with the standard settings"
                    }
                  />
                </div>

                <div className="d-flex justify-content-between align-items-center">
                  {this.state.resetbuttonios !== 0 && (
                    <p
                      className="reset-btn"
                      onClick={(e) => this.togglemodalreset("ios")}
                    >
                      Reset
                    </p>
                  )}

                  <div className="d-flex justify-content-between align-items-center w-100">
                  {this.state.disablediossubmitbutton ?
                    <button
                      type="submit"
                      className="next_btn"
                      form="iOSform"
                      disabled={true}
                    >
                      <div className="d-flex align-items-center justify-content-center h-100 w-100">
                        <div className={this.state.loaderios}>
                          <img src={this.state.loaderiosimage} alt="loader" />
                        </div>{" "}
                        <div>{this.state.submitiosval}</div>
                      </div>
                    </button>
                  :
                  <button
                  type="submit"
                  className="next_btn"
                  form="iOSform"
                  style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                >
                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    <div className={this.state.loaderios}>
                      <img src={this.state.loaderiosimage} alt="loader" />
                    </div>{" "}
                    <div>{this.state.submitiosval}</div>
                  </div>
                </button>
                  }
                  </div>
                </div>
              </div>
            </>
          )}
        </Modal>
        {/* Reset Modal */}
        <Modal
          isOpen={this.state.modalreset}
          toggle={this.togglemodalreset}
          style={{ maxWidth: "550px", maxHeight: "850px" }}
          className={this.props.className}
          centered
          dataclass="appsexists"
        >
          <ModalHeader
            toggle={this.togglemodalreset}
            className="modal-xxl-content ios-head"
          >
            <div className="d-flex justify-content-center align-items-center">
              <div className="app-exists-modal">
                <h3>Are you sure?</h3>
              </div>
            </div>
            <div className="crossmodal mt-1" onClick={this.togglemodalreset}>
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody
            className="upload_files text-center reset-body"
            style={{ backgroundColor: "#fff" }}
          >
            <p>
              Clicking on RESET will erase all the information that you've
              filled in this form.
            </p>
          </ModalBody>
          <div
            className="d-block app-resubmit d-md-flex justify-content-center align-items-center upload_files reset-bottom "
            style={{ backgroundColor: "#fff" }}
          >
            <div className="modalreset-footer">
              <div className="d-flex align-items-center justify-content-between">
                <button
                  className="next_btn"
                  onClick={(e) => this.handlereset()}
                >
                  Reset
                </button>
                <button
                  className="back_btn ml-2 ml-md-3"
                  onClick={this.togglemodalreset}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default React.memo(Custombundle);
