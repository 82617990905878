import React from "react";
import { Modal, ModalBody } from "reactstrap";
import crossIconImg from "../../../Agency/assets/images/cross.png";

const ActivateUserModal = ({
  teamState,
  btnCancelCustomerActivePopup,
  handleUserStatus,
  updateUserStatusLoading,
}) => {
  return (
    <Modal
      isOpen={teamState.openActivateUserPopup}
      className="newuser-modal-popup"
      centered
    >
      <ModalBody className="d-flex align-items-center justify-content-center">
        <div className="crossmodal mt-1" onClick={btnCancelCustomerActivePopup}>
          <img src={crossIconImg} alt="crossIcon" />
        </div>
        <div>
          <div>
            <h4 className="user-delete-modal-title">Activate user?</h4>
            <h5 className="user-delete-modal-para">
              Activating this user will restore their access.
            </h5>
          </div>

          <div className="user-new-modal-footer">
            <div
              className="usermodal-grey-btn"
              onClick={btnCancelCustomerActivePopup}
            >
              Cancel
            </div>

            <button
              className="usermodal-blue-btn"
              onClick={(e) => handleUserStatus(e, 1, "activate")}
              id="btncustomrtactiveid"
              style={{
                backgroundColor: localStorage.getItem(
                  "button_background_color"
                ),
                border:
                  "1px solid " +
                  localStorage.getItem("button_background_color"),
                color: localStorage.getItem("button_text_color"),
                "--custom_color5":
                  localStorage.getItem("agencyid") == 1
                    ? "#134bec"
                    : localStorage.getItem("button_hover_bgcolor"),
                "--custom_color4":
                  localStorage.getItem("agencyid") == 1
                    ? "white"
                    : localStorage.getItem("button_hover_txtcolor"),
                "--custom_color6":
                  localStorage.getItem("agencyid") == 1
                    ? "#3064f9"
                    : localStorage.getItem("button_hover_bgcolor"),
              }}
              disabled={updateUserStatusLoading}
            >
              {updateUserStatusLoading ? (
                <div className="new-loader"></div>
              ) : (
                "Activate"
              )}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ActivateUserModal;
