import React from "react";
import Tooltip from "../../commonIcons/Tooltip";

const WebsiteMenu = ({
  isMenuModuleEnable,
  websiteMenuListingData,
  isParentMenuEnable,
  syncWebsiteToggleVal,
  handleSyncWebsiteToggle,
  selectedMenuVal,
  handleMenuValChange,
  from,
}) => {
  return (
    <>
      {isParentMenuEnable && isMenuModuleEnable ? (
        <>
          {parseInt(localStorage.getItem("website_technology")) === 4 ||
          parseInt(localStorage.getItem("website_technology")) === 5 ? (
            <>
              <div className="secondry-menu-toggle-div">
                <>
                  <h4 className="second-menu-wordpress-sync">
                    Sync with my WordPress menu
                    <Tooltip
                      message={
                        "Enable to import and continuously sync your primary menu with a WordPress website menu."
                      }
                    />
                  </h4>

                  <label className=" common-toogle-switch " htmlFor={from}>
                              <input
                                 type="checkbox"
                                 className="common-switch-input"
                                 id={from}
                                 name={from}
                                 checked={syncWebsiteToggleVal}
                                 onChange={(e) => handleSyncWebsiteToggle(e)}
                               />
                              <div className="switch-slider round"></div>
                            </label>
                 
                </>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
      {syncWebsiteToggleVal && isParentMenuEnable ? (
        <div className="menuselect-radio-div">
          {Object.keys(websiteMenuListingData).map((key, index) => {
            return (
              <label
                class="menubuilder-radio-button menubuilder-radio-firstbtn"
                key={from + "val" + key}
                htmlFor={from + "val" + key}
              >
                <input
                  type="radio"
                  class="menubuilder-radio-button__input 1"
                  id={from + "val" + key}
                  onChange={(e) => {
                    handleMenuValChange(e, key);
                  }}
                  checked={selectedMenuVal[0] === key}
                />
                <span
                  class="menubuilder-radio-button__control"
                  style={{ cursor: "pointer" }}
                ></span>
                <span class="menubuilder-radio-button__label">
                  {key.replace("-", " ")}
                </span>
              </label>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default WebsiteMenu;
