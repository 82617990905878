import React from 'react'

const ForwardArrow = () => {
  return (
    <svg
    id="Group_41541"
    data-name="Group 41541"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <circle
      id="Ellipse_8285"
      data-name="Ellipse 8285"
      cx="10"
      cy="10"
      r="10"
      fill="#e2eaff"
    />
    <path
      id="Path_102452"
      data-name="Path 102452"
      d="M0,4,4,0,8,4"
      transform="translate(12.5 6) rotate(90)"
      fill="none"
      stroke="#3064f9"
      stroke-width="1.5"
    />
  </svg>
  )
}

export default ForwardArrow