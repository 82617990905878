import React, { useEffect, useState } from "react";
import {
  accordionDataUpdatation,
  convertHTMLEntity,
} from "../../../utils/commonUtils";
import { MenuLinkPopUp } from "./MenuLinkPopUp";
import {
  useNonCMSpostCatDataQuery,
  useNonCMSproductCatDataQuery,
  useNonCMSRestBaseDataQuery,
} from "../../../services/userWebsiteApiSlice";
import {
  useCmsCategoryDataQuery,
  useCmsPagesDataQuery,
  useCmsPostsDataQuery,
  useCmsTagsDataQuery,
} from "../../../services/cmsApiSlice";
import LinkFieldIcon from "../../commonIcons/LinkFieldIcon";
import Tooltip from "../../commonIcons/Tooltip";
const MenuLinkField = ({
  item,
  itemListingData,
  itemListingArrName,
  setAccordianState,
  is_chat_enabled,
  defaultOptionData,
  taxonomyTypeMergeData,
}) => {
  const [pageNo, setPageNo] = useState(1);
  const [paginationSpinner, setPaginationSpinner] = useState(false);
  const [linkFieldSpinner, setLinkFieldSpinner] = useState(
    item.individual_selected_item_id ? true : false
  );
  const {
    data: apiRestbaseData,
    error: restbaseApiErr,
    restbaseApiLoading,
  } = useNonCMSRestBaseDataQuery(
    {
      storeUrl: localStorage.getItem("store_url"),
      rest_base: item.temp_rest_base,
      page: pageNo,
    },
    {
      skip:
        !item.temp_rest_base ||
        (!item.subdropdownSpinner && !linkFieldSpinner && !paginationSpinner) ||
        item.temp_rest_base === "product_cat" ||
        parseInt(localStorage.getItem("website_technology")) === 3,
    }
  );

  const {
    data: apiProductCatsData,
    error: productCatsDataApiErr,
    productCatsDataApiLoading,
  } = useNonCMSproductCatDataQuery(
    { storeUrl: localStorage.getItem("store_url") },
    {
      skip:
        item.is_tree !== 1 ||
        (!item.subdropdownSpinner && !linkFieldSpinner && !paginationSpinner),
    }
  );

  const {
    data: apiPostsData,
    error: postDataApiErr,
    postDataApiLoading,
  } = useNonCMSpostCatDataQuery(
    { storeUrl: localStorage.getItem("store_url") },
    {
      skip:
        item.is_tree !== 2 ||
        (!item.subdropdownSpinner && !linkFieldSpinner && !paginationSpinner),
    }
  );

  const {
    data: apicmsPagesData,
    error: cmsPagesDataApiErr,
    cmsPagesDataApiLoading,
  } = useCmsPagesDataQuery(
    { page: pageNo, limit: 99 },
    {
      skip:
        item.is_tree !== 4 ||
        (!item.subdropdownSpinner && !linkFieldSpinner && !paginationSpinner),
    }
  );

  const {
    data: apicmsPostData,
    error: cmsPostDataApiErr,
    cmsPostDataApiLoading,
  } = useCmsPostsDataQuery(
    { page: pageNo, limit: 99 },
    {
      skip:
        item.is_tree !== 5 ||
        (!item.subdropdownSpinner && !linkFieldSpinner && !paginationSpinner),
    }
  );

  const {
    data: apicmsCategoriesData,
    error: cmsCategoriesDataApiErr,
    cmsCategoriesDataApiLoading,
  } = useCmsCategoryDataQuery(
    { page: pageNo, limit: 99 },
    {
      skip:
        item.is_tree !== 6 ||
        (!item.subdropdownSpinner && !linkFieldSpinner && !paginationSpinner),
    }
  );

  const {
    data: apicmsTagsData,
    error: cmsTagsDataApiErr,
    cmsTagsDataApiLoading,
  } = useCmsTagsDataQuery(
    { page: pageNo, limit: 99 },
    {
      skip:
        item.is_tree !== 7 ||
        (!item.subdropdownSpinner && !linkFieldSpinner && !paginationSpinner),
    }
  );

  const [openSingleSelectionPopUp, setOpenSingleSelectionPopUp] =
    useState(false);
  const [isAllowPagination, setIsAllowPagination] = useState(true);
  const [useEffectDependencyCount, setUseEffectDependencyCount] = useState(0);

  useEffect(() => {
    if (
      apiRestbaseData &&
      item.temp_rest_base &&
      item.temp_rest_base !== "product_cat" &&
      parseInt(localStorage.getItem("website_technology")) !== 3
    ) {
      settingPopUpDataFromApi(apiRestbaseData, item.id, pageNo);
    }
  }, [apiRestbaseData, useEffectDependencyCount]);

  useEffect(() => {
    if (apicmsPagesData && apicmsPagesData.code === 200 && item.is_tree === 4) {
      settingPopUpTreeDataFromApi(
        apicmsPagesData.data,
        item.id,
        pageNo,
        item.temp_individual_selected_item_id
      );
    }
  }, [apicmsPagesData, useEffectDependencyCount]);

  useEffect(() => {
    if (apicmsPostData && apicmsPostData.code === 200 && item.is_tree === 5) {
      settingPopUpDataFromApi(apicmsPostData.data, item.id, pageNo);
    }
  }, [apicmsPostData, useEffectDependencyCount]);

  useEffect(() => {
    if (
      apicmsCategoriesData &&
      apicmsCategoriesData.code === 200 &&
      item.is_tree === 6
    ) {
      settingPopUpTreeDataFromApi(
        apicmsCategoriesData.data,
        item.id,
        pageNo,
        item.temp_individual_selected_item_id
      );
    }
  }, [apicmsCategoriesData, useEffectDependencyCount]);

  useEffect(() => {
    if (apicmsTagsData && apicmsTagsData.code === 200 && item.is_tree === 7) {
      settingPopUpDataFromApi(apicmsTagsData.data, item.id, pageNo);
    }
  }, [apicmsTagsData, useEffectDependencyCount]);

  useEffect(() => {
    if (apiProductCatsData && item.is_tree === 1) {
      settingPopUpTreeDataFromApi(
        apiProductCatsData,
        item.id,
        pageNo,
        item.temp_individual_selected_item_id
      );
    }
  }, [apiProductCatsData, useEffectDependencyCount]);

  const returnSelectedModuleName = (
    module_name,
    concatedSlugRestBasePostTypeItemType
  ) => {
    let label = "";
    if (module_name === "chat") {
      label = "Chat";
    } else if (module_name === "main_menu") {
      label = "Menu";
    } else if (module_name === "settings") {
      label = "Settings";
    } else if (module_name === "web_view") {
      label = "Web view";
    } else if (module_name === "home") {
      label = "Home screen";
    } else if (module_name === "profile") {
      label = "Profile";
    } else if (module_name === "product_categories") {
      label = "Product categories";
    } else if (module_name === "product_search") {
      label = "Product search";
    } else if (module_name === "cart") {
      label = "Cart";
    } else if (module_name === "pages") {
      label = "Pages";
    } else if (module_name === "orders") {
      label = "Orders";
    } else if (
      module_name === "post_cat" ||
      module_name === "post_categories"
    ) {
      label = "Post categories";
    } else if (module_name === "cms_post") {
      label = "Posts";
    } else if (module_name === "cms_page") {
      label = "Pages";
    } else if (module_name === "cms_category") {
      label = "Post categories";
    } else if (module_name === "cms_tag") {
      label = "Post Tags";
    } else if (module_name === "email") {
      label = "Email";
    } else if (module_name === "phone") {
      label = "Phone";
    } else if (module_name === "share") {
      label = "Share";
    } else {
      taxonomyTypeMergeData &&
        taxonomyTypeMergeData.length &&
        taxonomyTypeMergeData.map((value, k) => {
          if (
            concatedSlugRestBasePostTypeItemType ===
            value.type + value.slug + value.rest_base + value.post_type
          ) {
            label = value.name;
          }
        });
    }

    return label;
  };

  const handleApiError = () => {

    function recursForMenuData(arr, menuId) {
      arr.map((currItem) => {
        if (currItem.id === menuId) {
          currItem.temp_subData = [];
          currItem.temp_breadcrumbArr = [];
          currItem.subdropdownSpinner = false;
          currItem.temp_showL1Options = false;
          currItem.temp_module_label = returnSelectedModuleName(
            currItem.temp_item_type,
            currItem.temp_item_type +
              currItem.temp_slug +
              currItem.temp_rest_base +
              currItem.temp_post_type
          );
        }

        if (currItem.children && currItem.children.length) {
          recursForMenuData(currItem.children, menuId);
        }
      });
    }
    let currArr = [...itemListingData];
    recursForMenuData(currArr, item.id);
    setLinkFieldSpinner(false);
    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };

  useEffect(() => {

    if(restbaseApiErr || productCatsDataApiErr  || cmsPagesDataApiErr || cmsPostDataApiErr || cmsCategoriesDataApiErr || cmsTagsDataApiErr){
      handleApiError();
    }

    
  },[ 
    restbaseApiErr,
    productCatsDataApiErr,
    cmsPagesDataApiErr,
    cmsPostDataApiErr,
    cmsCategoriesDataApiErr,
    cmsTagsDataApiErr
   ])


  const settingPopUpDataFromApi = (apiData, menuId, page) => {
    let currArr = [...itemListingData];
    let newData = [];

    function recursForMenuData(arr, currApiData) {
      arr.map((currItem) => {
        if (currItem.id === menuId) {
          currItem.subdropdownData =
            page && page > 1
              ? currItem.subdropdownData.concat(newData)
              : newData;
          currItem.subData =
            page && page > 1 ? currItem.subData.concat(newData) : newData;
          currItem.temp_subData =
            page && page > 1 ? currItem.temp_subData.concat(newData) : newData;

          currItem.temp_breadcrumbArr = [];
          currItem.breadcrumbArr = [];

          currItem.subdropdownSpinner = false;
          currItem.temp_showL1Options = false;

          currItem.temp_module_label = returnSelectedModuleName(
            currItem.temp_item_type,
            currItem.temp_item_type +
              currItem.temp_slug +
              currItem.temp_rest_base +
              currItem.temp_post_type
          );

          currItem.module_label = returnSelectedModuleName(
            currItem.item_type,
            currItem.item_type +
              currItem.slug +
              currItem.rest_base +
              currItem.post_type
          );
        }

        if (currItem.children && currItem.children.length) {
          recursForMenuData(currItem.children, currApiData);
        }
      });
    }
    try {
      if (apiData) {
        newData = apiData;
        setPaginationSpinner(false);
        setIsAllowPagination(
          apiData && apiData.length && apiData.length > 98 ? true : false
        );

        recursForMenuData(itemListingData, newData);
        setAccordianState({
          [itemListingArrName]: currArr,
        });
        setLinkFieldSpinner(false);
      } else {
        recursForMenuData(itemListingData, newData);
        setPaginationSpinner(false);
        setIsAllowPagination(false);
        setLinkFieldSpinner(false);

        setAccordianState({
          [itemListingArrName]: currArr,
        });
      }
    } catch (err) {
      recursForMenuData(itemListingData, newData);
      setPaginationSpinner(false);
      setIsAllowPagination(false);
      setLinkFieldSpinner(false);

      setAccordianState({
        [itemListingArrName]: currArr,
      });
    }
  };

  const settingPopUpTreeDataFromApi = (apiData, menuId, page, indiID) => {
    let newBreadcrumb = [];
    let newSubData = [];
    let parentId = "";
    let allPages = [];

    let currArr = [...itemListingData];
    let newData = [];
    function recursForMenuData(arr, currApiData) {
      arr.map((currItem) => {
        if (currItem.id === menuId) {
          if (!newBreadcrumb || newBreadcrumb.length === 0) {
            currItem.subdropdownData =
              page && page > 1
                ? currItem.subdropdownData.concat(allPages)
                : allPages;
            currItem.subData =
              page && page > 1
                ? newSubData.length === 0
                  ? currItem.subData.concat(allPages)
                  : currItem.subData.concat(newSubData)
                : newSubData.length === 0
                ? allPages
                : newSubData;
            currItem.temp_subData =
              page && page > 1
                ? newSubData.length === 0
                  ? currItem.temp_subData.concat(allPages)
                  : currItem.temp_subData.concat(newSubData)
                : newSubData.length === 0
                ? allPages
                : newSubData;
          } else {
            currItem.subdropdownData = allPages;
            currItem.subData = newSubData.length === 0 ? allPages : newSubData;
            currItem.temp_subData =
              newSubData.length === 0 ? allPages : newSubData;
          }

          currItem.temp_breadcrumbArr = newBreadcrumb;
          currItem.breadcrumbArr = newBreadcrumb;
          currItem.subdropdownSpinner = false;
          currItem.temp_showL1Options = false;

          currItem.temp_module_label = returnSelectedModuleName(
            currItem.temp_item_type,
            currItem.temp_item_type +
              currItem.temp_slug +
              currItem.temp_rest_base +
              currItem.temp_post_type
          );

          currItem.module_label = returnSelectedModuleName(
            currItem.item_type,
            currItem.item_type +
              currItem.slug +
              currItem.rest_base +
              currItem.post_type
          );
        }

        if (currItem.children && currItem.children.length) {
          recursForMenuData(currItem.children, currApiData);
        }

        // return arr;
      });
    }

    function recursForBreadcrumb(arr) {
      arr.map((currItem) => {
        let obj = {
          name: "",
          id: "",
        };

        if (currItem._id == parentId || currItem.term_id == parentId) {
          parentId = currItem.parent;
          obj.id = currItem._id
            ? currItem._id.toString()
            : currItem.term_id.toString();
          obj.name = currItem.name ? currItem.name : currItem.title;
          newBreadcrumb.push(obj);
        }
        if (parseInt(localStorage.getItem("website_technology")) === 3) {
          if (currItem.child && currItem.child.length) {
            recursForBreadcrumb(currItem.child);
          }
        } else {
          if (currItem.children && currItem.children.length) {
            recursForBreadcrumb(currItem.children);
          }
        }
      });
    }

    function recursData(arr) {
      arr.map((currItem) => {
        if (
          newBreadcrumb.length &&
          currItem.parent == newBreadcrumb[newBreadcrumb.length - 1].id
        ) {
          newSubData.push(currItem);
        }

        if (parseInt(localStorage.getItem("website_technology")) === 3) {
          if (currItem.child && currItem.child.length) {
            recursData(currItem.child);
          }
        } else {
          if (currItem.children && currItem.children.length) {
            recursData(currItem.children);
          }
        }
      });
    }

    function recurs(arr) {
      arr &&
        arr.map((currItem) => {
          recursForBreadcrumb(allPages);
          if (parseInt(localStorage.getItem("website_technology")) === 3) {
            if (currItem.child && currItem.child.length) {
              recurs(currItem.child);
            }
          } else {
            if (currItem.children && currItem.children.length) {
              recurs(currItem.children);
            }
          }
        });
    }

    try {
      if (apiData) {
        setPaginationSpinner(false);
        setIsAllowPagination(
          apiData && apiData.length && apiData.length >= 99 ? true : false
        );

        allPages = apiData;

        parentId = indiID;
        if (!page || page <= 1) {
          recurs(allPages);
        }

        newBreadcrumb.splice(0, 1);
        newBreadcrumb.reverse();
        recursData(allPages);
        recursForMenuData(currArr, allPages);
        setLinkFieldSpinner(false);
        setAccordianState({
          [itemListingArrName]: currArr,
        });
      } else {
        recursForMenuData(itemListingData, newData);
        setPaginationSpinner(false);
        setIsAllowPagination(false);
        setLinkFieldSpinner(false);

        setAccordianState({
          [itemListingArrName]: currArr,
        });
      }
    } catch (err) {
      recursForMenuData(itemListingData, allPages);
      setLinkFieldSpinner(false);

      setAccordianState({
        [itemListingArrName]: currArr,
      });
    }
  };

  const handleWebviewUrl = (e, menuId) => {
    e.preventDefault();
    let currArr = [...itemListingData];
    currArr = accordionDataUpdatation(
      currArr,
      menuId,
      "updating_web_view_url",
      e.target.value
    );
    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };

  const handleOpenSelectionPopUp = (buttonFrom, menuId) => {
    setOpenSingleSelectionPopUp(!openSingleSelectionPopUp);

    let currArr = [...itemListingData];

    function recursData(arr) {
      arr.map((eachItem) => {
        if (eachItem.id === menuId) {
          if (buttonFrom === "cancel") {
            eachItem.temp_individual_selected_item_id =
              eachItem.individual_selected_item_id;
            eachItem.temp_item_type = eachItem.item_type;
            eachItem.temp_module_label = eachItem.module_label;
            eachItem.temp_breadcrumbArr = eachItem.breadcrumbArr;
            eachItem.temp_subData = eachItem.subData;
            eachItem.temp_showL1Options = eachItem.showL1Options;
            eachItem.temp_rest_base = eachItem.rest_base;
            eachItem.temp_slug = eachItem.slug;
            eachItem.temp_post_type = eachItem.post_type;
          } else {
            if (buttonFrom === "open" || buttonFrom === "done") {
              eachItem.individual_selected_item_id =
                eachItem.temp_individual_selected_item_id;
              eachItem.item_type = eachItem.temp_item_type;
              eachItem.module_label = eachItem.temp_module_label;
              eachItem.breadcrumbArr = eachItem.temp_breadcrumbArr;
              eachItem.subData = eachItem.temp_subData;
              eachItem.showL1Options = eachItem.temp_showL1Options;
              eachItem.rest_base = eachItem.temp_rest_base;
              eachItem.slug = eachItem.temp_slug;
              eachItem.post_type = eachItem.temp_post_type;
              eachItem.item_is_default = ( eachItem.item_type === "phone" || eachItem.item_type === "email" || eachItem.item_type === "share" ) ? 0 : eachItem.item_is_default;
            }
            if (buttonFrom === "done") {
              eachItem.value = "";
            }
          }
        }

        if (eachItem.children && eachItem.children.length) {
          recursData(eachItem.children);
        }
      });
    }
    recursData(currArr);

    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };

  const handleChildArrowClick = (e, res, menuId) => {
    let currArr = JSON.parse(JSON.stringify(itemListingData));
    let newBreadcrumbArr = [];

    let title1 = "";
    let child = [];

    function recursForMenuData(arr) {
      arr.map((eachItem) => {
        if (eachItem.id === menuId) {
          newBreadcrumbArr = eachItem.temp_breadcrumbArr;

          if (parseInt(localStorage.getItem("website_technology")) === 3) {
            title1 = res.title || res.name;
            child = res.child ? res.child : [];
          } else {
            title1 = convertHTMLEntity(res.title?.rendered || res.name);
            child = res.children ? res.children : [];
          }
          newBreadcrumbArr.push({
            name: title1,
            id: res._id ? res._id : res.term_id.toString(),
          });
          eachItem.temp_subData = child;
          eachItem.temp_breadcrumbArr = newBreadcrumbArr;
        }

        if (eachItem.children && eachItem.children.length) {
          recursForMenuData(eachItem.children);
        }

        // return arr;
      });
    }

    recursForMenuData(currArr);

    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };
  const handleHomeBtnClick = (e, menuId) => {
    let currArr = [...itemListingData];

    function recursForMenuData(arr) {
      arr.map((eachItem) => {
        if (eachItem.id === menuId) {
          eachItem.temp_breadcrumbArr = [];
          eachItem.temp_showL1Options = true;
        }

        if (eachItem.children && eachItem.children.length) {
          recursForMenuData(eachItem.children);
        }

        // return arr;
      });
    }

    recursForMenuData(currArr);

    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };

  const handleFirstLabelClick = (e, menuId) => {
    let currArr = [...itemListingData];

    function recursForMenuData(arr) {
      arr.map((eachItem) => {
        if (eachItem.id === menuId) {
          eachItem.temp_breadcrumbArr = [];

          eachItem.temp_subData = eachItem.subdropdownData;
        }

        if (eachItem.children && eachItem.children.length) {
          recursForMenuData(eachItem.children);
        }

        // return arr;
      });
    }

    recursForMenuData(currArr);

    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };

  const handleBreadcrumb = (e, val, indx, menuId) => {
    function recurs(arr) {
      arr.map((eachCat) => {
        if (eachCat.parent == val.id) {
          newData.push(eachCat);
        }

        if (parseInt(localStorage.getItem("website_technology")) === 3) {
          if (eachCat.child && eachCat.child.length) {
            // if parent has childrens
            recurs(eachCat.child);
          }
        } else {
          if (eachCat.children && eachCat.children.length) {
            // if parent has childrens
            recurs(eachCat.children);
          }
        }
      });
    }

    let currArr = [...itemListingData];
    let newData = [];
    let newBreadcrumb = [];
    let breadcrumb = [];

    function recursForMenuData(arr) {
      arr.map((eachItem) => {
        if (eachItem.id === menuId) {
          breadcrumb = eachItem.temp_breadcrumbArr;

          for (let i = 0; i < breadcrumb.length; i++) {
            if (val.name == breadcrumb[i].name) {
              newBreadcrumb.push(breadcrumb[i]);
              break;
            } else {
              newBreadcrumb.push(breadcrumb[i]);
            }
          }

          eachItem.temp_breadcrumbArr = newBreadcrumb;
          recurs(eachItem.subdropdownData);

          eachItem.temp_subData = newData;
        }

        if (eachItem.children && eachItem.children.length) {
          recursForMenuData(eachItem.children);
        }

        // return arr;
      });
    }

    recursForMenuData(currArr);

    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };

  const handleMobBackBtn = (e, menuId) => {
    let currArr = [...itemListingData];
    let breadcrumb = [];
    function recursForMenuData(arr) {
      arr.map((eachItem) => {
        if (eachItem.id === menuId) {
          breadcrumb = JSON.parse(JSON.stringify(eachItem.temp_breadcrumbArr));

          if (breadcrumb && breadcrumb.length) {
            let newData = [];
            breadcrumb.splice(-1, 1);
            eachItem.temp_breadcrumbArr = breadcrumb;
            function recurs(arr) {
              arr.map((eachCat) => {
                if (eachCat.parent == breadcrumb[breadcrumb.length - 1].id) {
                  newData.push(eachCat);
                }
                if (
                  parseInt(localStorage.getItem("website_technology")) === 3
                ) {
                  if (eachCat.child && eachCat.child.length) {
                    // if parent has childrens
                    recurs(eachCat.child);
                  }
                } else {
                  if (eachCat.children && eachCat.children.length) {
                    // if parent has childrens
                    recurs(eachCat.children);
                  }
                }
              });
            }

            if (breadcrumb && breadcrumb.length) {
              recurs(eachItem.subdropdownData);
              eachItem.temp_subData = newData;
            } else {
              eachItem.temp_subData = eachItem.subdropdownData;
            }
          } else {
            if (eachItem.temp_showL1Options === false) {
              eachItem.temp_showL1Options = true;
            } else {
              handleOpenSelectionPopUp("cancel", eachItem.id);
            }
          }
        }

        if (eachItem.children && eachItem.children.length) {
          recursForMenuData(eachItem.children);
        }

        // return arr;
      });
    }

    recursForMenuData(currArr);

    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };

  const handleBottomScrollListner = (e, currItem) => {
    if (!paginationSpinner && isAllowPagination) {
      setPageNo(pageNo + 1);
      setPaginationSpinner(true);
    }
  };

  const handleMainOptionChange = (e, menuId, res, from) => {
    let currArr = [...itemListingData];

    function recursForMenuData(arr) {
      arr.map((eachItem) => {
        if (eachItem.id === menuId) {
          if (res) {
            eachItem.temp_item_type = res.item_type || res.type;
            eachItem.temp_module_label = res.item_name || res.name;
            eachItem.temp_individual_selected_item_id =
              res.temp_individual_selected_item_id
                ? res.temp_individual_selected_item_id
                : "";
            eachItem.temp_slug = res.slug ? res.slug : "";
            eachItem.temp_rest_base = res.rest_base ? res.rest_base : "";
            eachItem.temp_post_type = res.post_type ? res.post_type : "";
            eachItem.item_title = res.name ? res.name : "";
            eachItem.is_tree = res.is_tree || "";
          }
        }

        if (eachItem.children && eachItem.children.length) {
          recursForMenuData(eachItem.children, menuId, res, from);
        }

        // return arr;
      });
    }

    recursForMenuData(currArr);
    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };

  const handleMainOptionsArrowClick = (e, menuId, mergeArrItem) => {
    setPageNo(1);
    if (
      mergeArrItem.rest_base === item.temp_rest_base ||
      parseInt(localStorage.getItem("website_technology")) === 3
    ) {
      setUseEffectDependencyCount(useEffectDependencyCount + 1);
    }

    if (
      mergeArrItem.rest_base !== item.temp_rest_base ||
      parseInt(localStorage.getItem("website_technology")) === 3
    ) {
      let currArr = [...itemListingData];
      currArr = accordionDataUpdatation(
        currArr,
        menuId,
        "updating_item",
        mergeArrItem
      );
      setAccordianState({
        [itemListingArrName]: currArr,
      });
    }
  };

  const handleChildOptionChange = (e, value, currItem) => {
    let updatedParam = {};
    updatedParam.selected_ids = [];
    if (value === "") {
      updatedParam.individual_selected_item_id = "";
      updatedParam.selected_ids = [];
      updatedParam.last_item = value.selected_item_name;
      updatedParam.item_title = value.selected_item_name;
    } else {
      updatedParam.last_item = value.name
        ? value.name
        : value.title.rendered
        ? value.title.rendered
        : value.title;
      let updatedName = "";

      if (value.name) {
        updatedName = value.name;
      } else if (value.title.rendered) {
        updatedName = value.title.rendered;
      } else if (value.title) {
        updatedName = value.title;
      }

      updatedParam.last_item = updatedName;
      updatedParam.item_title = updatedName;

      updatedParam.individual_selected_item_id =
        parseInt(localStorage.getItem("website_technology")) === 3
          ? value._id
          : value.id
          ? value.id.toString()
          : value.term_id
          ? value.term_id.toString()
          : "";
      updatedParam.slug = value.slug || "";
    }

    let currArr = [...itemListingData];
    currArr = accordionDataUpdatation(
      currArr,
      currItem.id,
      "updating_item_option",
      updatedParam
    );
    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };

  const handleEmailCallShareValue = (e, menuId) => {
    let currArr = [...itemListingData];
    currArr = accordionDataUpdatation(
      currArr,
      menuId,
      "updating_email_call_share_value",
      e.target.value
    );
    setAccordianState({
      [itemListingArrName]: currArr,
    });
  };

  const returnSelectedItem = (eachItem, arr) => {
    let name = "";
    if (!eachItem || eachItem === "all") {
      name = "- All";
    } else {
      function recurs(arr1) {
        arr1 &&
          Array.isArray(arr1) &&
          arr1.map((res) => {
            if (parseInt(localStorage.getItem("website_technology")) === 3) {
              if (res._id == eachItem) {
                name = "- " + (res.title ? res.title : res.name);
              }
            } else {
              if (res.term_id) {
                if (res.term_id == eachItem) {
                  name = "- " + (res.title ? res.title.rendered : res.name);
                }
              } else {
                if (res.id == eachItem) {
                  name = "- " + (res.title ? res.title.rendered : res.name);
                }
              }
            }

            if (parseInt(localStorage.getItem("website_technology")) === 3) {
              if (res.child && res.child.length) {
                // if parent has childrens
                recurs(res.child);
              }
            } else {
              if (res.children && res.children.length) {
                // if parent has childrens
                recurs(res.children);
              }
            }
          });
      }
      recurs(arr);
    }

    if (!name) {
      name = eachItem ? `- ${eachItem}` : "";
    }

    return convertHTMLEntity(name);
  };

  return (
    <>
      <div className="menu-itemtype">
        <label className="menu-inputtype-label">Link</label>
        <div
          className="cat-parent-input-popup"
          style={{
            cursor: !linkFieldSpinner ? "pointer" : "not-allowed",
            padding: "15px 15px 15px 15px",
            marginBottom: "10px",
          }}
          onClick={() =>
            !linkFieldSpinner ? handleOpenSelectionPopUp("open", item.id) : null
          }
        >
          {linkFieldSpinner ? (
            <img
              className="search-dropdown-before"
              src={
                require("../../../assets/images/signup/menuLoader_GIF.gif")
                  .default
              }
              style={{ width: "20px", height: "20px" }}
              alt="loader"
            />
          ) : (
            <p style={{ marginBottom: "0px" }}>
              {returnSelectedModuleName(
                item.item_type,
                item.item_type + item.slug + item.rest_base + item.post_type
              )}{" "}
              {(item.item_type === "post_type" ||
                item.item_type === "taxonomy" ||
                item.item_type === "search" ||
                item.item_type.includes("cms")) &&
                returnSelectedItem(
                  item.individual_selected_item_id,
                  item.subdropdownData
                )}
            </p>
          )}
          <LinkFieldIcon />
        </div>
      </div>
      {item.item_type === "web_view" && (
        <>
          <div className="menuurltype">
            <label className="menu-inputtype-label">URL</label>
            <div className="d-flex">
              <div className="httptext">https:// </div>
              <input
                type="url"
                className="menu-create-input"
                // maxLength={100}
                value={
                  item.web_view_url
                    ? item.web_view_url.replace(/^https?:\/\//i, "")
                    : ""
                }
                onChange={(e) => handleWebviewUrl(e, item.id)}
              />
            </div>
          </div>
        </>
      )}

      {item.item_type === "email" ||
      item.item_type === "phone" ||
      item.item_type === "share" ? (
        <div className="color_picker mb-4 w-100">
          <div className="">
            <div className=" ">
              <label className="menu-inputtype-label">Value</label>
              <div className="">
                <input
                  type="text"
                  className="menu-create-input"
                  placeholder={
                    item?.item_type === "email"
                      ? "john.doe@example.com"
                      : item?.item_type === "phone"
                      ? "9876543210"
                      : "example.com"
                  }
                  // maxLength={20}
                  value={item.value}
                  onChange={(e) => handleEmailCallShareValue(e, item.id)}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
      <MenuLinkPopUp
        item={item}
        openSingleSelectionPopUp={openSingleSelectionPopUp}
        handleOpenSelectionPopUp={handleOpenSelectionPopUp}
        handleMainOptionsArrowClick={handleMainOptionsArrowClick}
        handleMainOptionChange={handleMainOptionChange}
        handleChildArrowClick={handleChildArrowClick}
        handleChildOptionChange={handleChildOptionChange}
        handleBottomScrollListner={handleBottomScrollListner}
        handleHomeBtnClick={handleHomeBtnClick}
        handleFirstLabelClick={handleFirstLabelClick}
        handleBreadcrumb={handleBreadcrumb}
        handleMobBackBtn={handleMobBackBtn}
        defaultOptionData={defaultOptionData}
        taxonomyTypeMergeData={taxonomyTypeMergeData}
        typeArray={[]}
        paginationSpinner={paginationSpinner}
        is_chat_enabled={is_chat_enabled}
      />
    </>
  );
};

export default MenuLinkField;
