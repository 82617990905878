import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../utils/apiEndpoints";

export const cmsApiSlice = createApi({
  reducerPath: "cmsData",
  keepUnusedDataFor: 1800,
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.generalUrl,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("amstoken")}`
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    cmsPagesData: builder.query({
      query: ({ page, limit }) =>
        `${apiEndPoints.cmsUrl}getPages/${btoa(
          localStorage.getItem("Appid")
        )}?page=${page ? page : 1}&limit=${limit ? limit : 20}`,
    }),
    cmsCategoryData: builder.query({
      query: ({ page, limit }) =>
        `${apiEndPoints.cmsUrl}getCategories/${btoa(
          localStorage.getItem("Appid")
        )}?page=${page ? page : 1}&limit=${limit ? limit : 20}`,
    }),
    cmsPostsData: builder.query({
      query: ({ page, limit, filterData }) =>
        `${apiEndPoints.cmsUrl}getPosts/${btoa(
          localStorage.getItem("Appid")
        )}?page=${page ? page : 1}&limit=${limit ? limit : 20}${
          filterData ? filterData : ""
        }`,
    }),
    cmsTagsData: builder.query({
      query: ({ page, limit }) =>
        `${apiEndPoints.cmsUrl}getTags/${btoa(
          localStorage.getItem("Appid")
        )}?page=${page ? page : 1}&limit=${limit ? limit : 20}`,
    }),
  }),
});

export const {
  useCmsPagesDataQuery,
  useCmsCategoryDataQuery,
  useCmsPostsDataQuery,
  useCmsTagsDataQuery,
} = cmsApiSlice;
