import React from "react";
import Header from "./../Header";
import 
  {
    GetSupportPlanApi,
    downgradeplan,
    canceldowngradeplan
  } from "../../Api/Dashboard/SupportPlanApi";
import moment from "moment";
import Accordion from 'react-bootstrap/Accordion';
import {Modal, ModalHeader, ModalBody } from "reactstrap";
import flow from "@prosperstack/flow";

export default class SupportPricing extends React.PureComponent {
    constructor(props) 
    {
        super(props);
        this.myRef = React.createRef(); // Create a ref object
        this.state  = 
        {
            monthdata:[],
            gateway_subscription_id:'',
            spinner:true,
            downgrade_at_term_end:0,
            customer_billing_type:'',
            renewal_date:'',
            planID:0,
            loader_remove: "d-none",
            submitval_confirmpop: "plan-upgradebtn",
            loaderimage_remove: require("../../assets/images/signup/Loader_GIF.gif").default,
            openReactivatePopUp:false,
            reactiveSubmitText:"Confirm",
            reactiveLoader:"d-none",
            stripe_customer_id:"",
            subscription_id:"",
        };
    }

  componentDidMount() {
   
    if(localStorage.getItem('agencyid') &&  localStorage.getItem('agencyid') == 1)
        {
            window.scrollTo(0, 0);
            document.title = "Support plans |  AppMySite";
            this.stopmounting = true;
            document.body.classList.remove('opec1');
            GetSupportPlanApi(this,()=>{});
        }
        else
        {
            window.location.href  = "/apps";
        }

    
   
      
      
  }

  
  removepopup = (planId) => 
  {
    this.setState({
        loader_remove: "d-none",
        submitval_confirmpop: "plan-upgradebtn",
    });
    if(this.state.customer_billing_type === 3)
    {
        if(planId == 0)
        {
            this.setState({
                planID:"paddle_preview",
                deluser: true,
              });
        }
        else
        {
            this.setState({
                planID:planId,
                deluser: true,
              });
        }
    }
    else
    {
        if(planId == 0)
        {
            this.setState({
                planID:process.env.REACT_APP_STRIPE_PREVIEW,
                deluser: true,
              });
        }
        else
        {
            this.setState({
                planID:planId,
                deluser: true,
              });
        }
    }
   
  };

  closepopup = () => 
  {
    this.setState({
        deluser: false,
        loader_remove: "d-none",
        submitval_confirmpop: "plan-upgradebtn",
    });
  };

    removecancel = () =>
    {
        this.setState({
            spinner: true,
        });

        if(this.state.customer_billing_type === 3)
        {
                  const params =
                  {
                      price_id:"paddle_preview",
                      payment_method_id:"123",
                      downgrade_at_term_end: 0,
                  };
                  canceldowngradeplan(params, this);
        }
        else
        {
                  const params =
                  {
                      price_id:process.env.REACT_APP_STRIPE_PREVIEW,
                      payment_method_id:"123",
                      downgrade_at_term_end: 0,
                  };
                  canceldowngradeplan(params, this);
        }
    }

    previewdowngrade = (planID) => 
    {
            this.setState({
                loader_remove: "d-block plan-upgradebtn",
                submitval_confirmpop: "d-none",
            });
            const params =
            {
                price_id:this.state.planID,
                payment_method_id:"123123",
                downgrade_at_term_end:this.state.downgrade_at_term_end == 0 ? 1 : 0,
            };
            downgradeplan(params, this);
            
                
    };


  //Button Color
  handlechecktab = (e) => 
  {
    this.setState({checkedtab: e});
  };

  handlecheckmoobtab = (e) => 
  {
    this.setState({checkedmobtab: e});
  };


  seemorefunction = () => {
    var element = document.getElementById("showfaq");
    element.classList.remove("hiddenfaq");
    document.getElementById("seemorelink").classList.add("d-none");
    document.getElementById("seelesslink").classList.remove("d-none");    
  }
  
  seelessfunction = () => 
  {
    var element = document.getElementById("showfaq");
    element.classList.add("hiddenfaq"); 
    document.getElementById("seemorelink").classList.remove("d-none");
    document.getElementById("seelesslink").classList.add("d-none");    
  }  

  
  handleReactivePopUp = (e,from) => 
 {
    if(from==="cancel"){
        this.setState({
            openReactivatePopUp : !this.state.openReactivatePopUp  
        })
    }
    else
    {
        this.setState({
        reactiveLoader:"d-block",
        reactiveSubmitText:""
        });
        this.removecancel();
    }

  }
  


  handleAccordianToggle = (e) => {
    let accItem = document.getElementsByClassName('accordionItem');
    let accHD = document.getElementsByClassName('accordionItemHeading allflex');

    let itemClass = e.target.parentElement.className;
    for (let i = 0; i < accItem.length; i++) {
        accItem[i].className = 'accordionItem close accordion-item';
        accItem[i].children[0].children[0].src = require('../../assets/images/pricing/faqplus.webp').default;
        accItem[i].children[0].children[0].setAttribute('data-src', require('../../assets/images/pricing/faqplus.webp').default);
    }
    if (itemClass == 'accordionItem close accordion-item') {
        e.target.parentElement.className = 'accordionItem open';
        e.target.children[0].src = require('../../assets/images/pricing/faqminus.webp').default;
        e.target.children[0].setAttribute('data-src', require('../../assets/images/pricing/faqminus.webp').default);
    }
    else
    {
        e.target.children[0].src = require('../../assets/images/pricing/faqplus.webp').default;
        e.target.children[0].setAttribute('data-src', require('../../assets/images/pricing/faqplus.webp').default);
    }

}
  

  render() {
   
    
    return (
        
        <>
            <Header customclass={"header-responsive-new"} />
                {this.state.spinner ?
                    (
                            <div className="newspinner" style={{minHeight: "100vh"}}>
                                <svg xmlns="http://www.w3.org/2000/svg" 
                                xlink="http://www.w3.org/1999/xlink" 
                                style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
                                width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                    <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                                    style={{animationplaystate: "running", animationdelay: "0s"}}>
                                    </animateTransform>
                                </circle>
                                </g>
                                <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                                style={{animationplaystate: "running", animationdelay: "0s"}}>
                                </animateTransform>
                                </circle>
                                </g>
                                <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                                style={{animationplaystate: "running", animationdelay: "0s"}}>

                                </animateTransform>
                                </circle>
                                </g>
                                <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                                style={{animationplaystate: "running", animationdelay: "0s"}}>

                                </animateTransform>
                                </circle>
                                </g>
                                </svg>
                            </div>
                    ) 
                :
                        <>
                            <section className="support-plan">
                                <div className="container">

                                    <h3>Support Plans</h3>
                                    <h6>Choose a support plan for improved SLAs, expert consulting, and assistance in building your app.</h6>

                                    <div className="pricingplans allflex">
                                                
                                        <div className="pricebox">
                                            
                                            <div className="price-first-half">
                                                
                                            <div className="allflex">
                                                
                                                <h2>Basic</h2>
                                                
                                                <div className="platforms">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="Group_37966" data-name="Group 37966" transform="translate(-1119 -271)">
                            <rect id="Rectangle_28987" data-name="Rectangle 28987" width="20" height="20" transform="translate(1119 271)" fill="none"/>
                            <path id="Subtraction_263" data-name="Subtraction 263" d="M15,12H1a1,1,0,0,1-1-1V3.057L8,7.63,16,3.06V11A1,1,0,0,1,15,12ZM8,6.085H8L0,1.513V1A1,1,0,0,1,1,0H15a1,1,0,0,1,1,1v.516L8,6.085Z" transform="translate(1120.999 275)" fill="#dbdee7"/>
                        </g>
                        </svg>

                                                </div>
                                                
                                            </div>  
                                            
                                            <h4>$0</h4>
                                            <h5></h5>
                                            
                                            {/* <a target="_blank" href="/sign-up/">Select</a> */}
                                            {this.state.gateway_subscription_id == '' &&  !this.state.subscription_id ?
                                                <a className="lowerplan" href="#">Current</a>
                                            : this.state.gateway_subscription_id !== '' &&  !this.state.subscription_id ? 
                                                <a className="currentplan" href="#">Unavailable</a>
                                            :this.state.downgrade_at_term_end === 1 ?
                                                <a className="currentplan" href="#">Unavailable</a>
                                            :
                                                <a href="#" onClick={(e) => this.removepopup(0)}>Select</a>
                                            }
                                            <p></p>

{/* <p className="support-cancelation">Cancellation on 18 May, 2023 <svg xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061">
  <path id="Union_2539" data-name="Union 2539" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
</svg>
</p> */}
                                            </div>
                                            
                                            <div className="mobi-tab">
                                                <input type="checkbox" id="chck1"/>
                                                <label className="mobi-tab-label" for="chck1">View Features</label>
                                            <div className="mobi-tab-content">
                                            <div className="price-second-half">
                                            
                                            <div className="support-featurebox">

                                                <div className="featureone">
                                                    <h4>Response time<p className="appdash-tooltip">
                <svg style={{position: "relative", top: "-1px", left: "-3px"}} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 18 18"><g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5"><circle cx="9" cy="9" r="9" stroke="none"></circle><circle cx="9" cy="9" r="8.25" fill="none"></circle></g><line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"></line><path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"></path></g></svg>
                <p className="appdash-tooltipsubheader">This response time is only for critical issues caused by downtime in AppMySite’s technical infrastructure.</p>
                </p></h4>
                                                    <p>Depends on volume of support queries received</p>
                                                </div>

                                                <div className="featuretwo">
                                                    <h4>Channels</h4>
                                                    <p>Knowledge base</p>
                                                    <p>Community</p>
                                                    <p>Email</p>
                                                </div>

                                                <div className="featurethree">
                                                    <h4>App Store</h4>
                                                    <p>-</p>
                                                </div>

                                                <div className="featurefour">
                                                    <h4>Support coverage</h4>
                                                    <p>-</p>
                                                </div>

                                                <div className="featurefive">
                                                    <h4>Training</h4>
                                                    <p>-</p>
                                                </div>

                                                <div className="featuresix">
                                                    <h4>Tailored enhancements</h4>
                                                    <p>-</p>
                                                </div>

                                                <div className="featureseven">
                                                    <h4>Account Manager</h4>
                                                    <p>-</p>
                                                </div>

                                            </div> 
                                            
                                            
                                            </div>
                                                </div>
                                            </div>
                                        </div>
                                    {/* 
                                        <div className="pricebox">
                                            
                                            <div className="price-first-half">
                                            
                                            <div className="allflex">
                                                
                                                <h2>Growth</h2>
                                                
                                                <div className="platforms">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="Group_37966" data-name="Group 37966" transform="translate(-1119 -271)">
                            <rect id="Rectangle_28987" data-name="Rectangle 28987" width="20" height="20" transform="translate(1119 271)" fill="none"/>
                            <path id="Subtraction_263" data-name="Subtraction 263" d="M15,12H1a1,1,0,0,1-1-1V3.057L8,7.63,16,3.06V11A1,1,0,0,1,15,12ZM8,6.085H8L0,1.513V1A1,1,0,0,1,1,0H15a1,1,0,0,1,1,1v.516L8,6.085Z" transform="translate(1120.999 275)" fill="#dbdee7"/>
                        </g>
                        </svg>
                        <svg style={{marginLeft: "10px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <g id="Group_37965" data-name="Group 37965" transform="translate(-1145 -271)">
                                                    <rect id="Rectangle_28988" data-name="Rectangle 28988" width="20" height="20" transform="translate(1145 271)" fill="none"/>
                                                    <path id="Exclusion_1" data-name="Exclusion 1" d="M3,17V13H2a2,2,0,0,1-2-2V3A2,2,0,0,1,2,1H14a2,2,0,0,1,2,2v8a2,2,0,0,1-2,2H8L3,17ZM11,6V8h2V6ZM7,6V8H9V6ZM3,6V8H5V6Z" transform="translate(1146.999 273)" fill="#dbdee7"/>
                                                </g>
                                                </svg>

                                            </div>
                                                
                                            </div>  
                                            
                                            <h4>${(parseInt(this.state.monthdata[0].amount)).toLocaleString()}.00</h4>
                                            
                                            <h5>per month</h5>
                                            
                                            {this.state.gateway_subscription_id == this.state.monthdata[0].plan_code ?
                                                <a className="lowerplan" href="#">Current</a>
                                            : this.state.monthdata[0].show_plan == 0 || this.state.downgrade_at_term_end === 1 ?
                                                 <a className="currentplan" href="#">Unavailable</a>
                                            :
                                                <a href={"/app/support-checkout/"+btoa(this.state.monthdata[0].plan_code)}>Select</a>
                                            }
                        {this.state.gateway_subscription_id == this.state.monthdata[0].plan_code && this.state.downgrade_at_term_end === 1 && this.state.renewal_date !== null ? 
                            
                           
                         :  this.state.gateway_subscription_id == this.state.monthdata[0].plan_code && this.state.downgrade_at_term_end === 1 && this.state.renewal_date === null ?
                            <p className="support-cancelation"> Downgrade scheduled</p>
                         : <p></p>}
                                            </div>
                                                
                                            <div className="mobi-tab">
                                                <input type="checkbox" id="chck2"/>
                                                <label className="mobi-tab-label" for="chck2">View Features</label>
                                            <div className="mobi-tab-content">
                                            <div className="price-second-half">
                                            
                                            <div className="support-featurebox">

                                                <div className="featureone">
                                                <h4>Response time<p className="appdash-tooltip">
                <svg style={{position: "relative", top: "-1px", left: "-3px"}} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 18 18"><g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5"><circle cx="9" cy="9" r="9" stroke="none"></circle><circle cx="9" cy="9" r="8.25" fill="none"></circle></g><line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"></line><path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"></path></g></svg>
                <p className="appdash-tooltipsubheader">This response time is only for critical issues caused by downtime in AppMySite’s technical infrastructure.</p>
                </p></h4>
                                                    <p>First response within 48 hours</p>
                                                </div>

                                                <div className="featuretwo">
                                                    <h4>Channels</h4>
                                                    <p>Knowledge base</p>
                                                    <p>Community</p>
                                                    <p>Email</p>
                                                    <p>Chat</p>
                                                </div>

                                                <div className="featurethree">
                                                    <h4>App Store</h4>
                                                    <p>General guidance</p>
                                                </div>

                                                <div className="featurefour">
                                                    <h4>Support coverage</h4>
                                                    <p>General guidance</p>
                                                </div>

                                                <div className="featurefive">
                                                    <h4>Training</h4>
                                                    <p>-</p>
                                                </div>

                                                <div className="featuresix">
                                                    <h4>Tailored enhancements</h4>
                                                    <p>-</p>
                                                </div>

                                                <div className="featureseven">
                                                    <h4>Account Manager</h4>
                                                    <p>-</p>
                                                </div>

                                            </div>    
                                            
                                            
                                            </div>  
                                                </div>
                                            </div>
                                                
                                        </div>
                                    */}
                                        <div className="pricebox">
                                            
                                            <div className="price-first-half">
                                            
                                            <div className="allflex">
                                                
                                                <h2>Business</h2>
                                                
                                                <div className="platforms">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <g id="Group_37966" data-name="Group 37966" transform="translate(-1119 -271)">
                                                    <rect id="Rectangle_28987" data-name="Rectangle 28987" width="20" height="20" transform="translate(1119 271)" fill="none"/>
                                                    <path id="Subtraction_263" data-name="Subtraction 263" d="M15,12H1a1,1,0,0,1-1-1V3.057L8,7.63,16,3.06V11A1,1,0,0,1,15,12ZM8,6.085H8L0,1.513V1A1,1,0,0,1,1,0H15a1,1,0,0,1,1,1v.516L8,6.085Z" transform="translate(1120.999 275)" fill="#dbdee7"/>
                                                </g>
                                                </svg>

                                                <svg style={{marginLeft: "10px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <g id="Group_37965" data-name="Group 37965" transform="translate(-1145 -271)">
                                                    <rect id="Rectangle_28988" data-name="Rectangle 28988" width="20" height="20" transform="translate(1145 271)" fill="none"/>
                                                    <path id="Exclusion_1" data-name="Exclusion 1" d="M3,17V13H2a2,2,0,0,1-2-2V3A2,2,0,0,1,2,1H14a2,2,0,0,1,2,2v8a2,2,0,0,1-2,2H8L3,17ZM11,6V8h2V6ZM7,6V8H9V6ZM3,6V8H5V6Z" transform="translate(1146.999 273)" fill="#dbdee7"/>
                                                </g>
                                                </svg>

                                                <svg style={{marginLeft: "10px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <g id="Group_37967" data-name="Group 37967" transform="translate(-1171 -271)">
                                                    <rect id="Rectangle_28989" data-name="Rectangle 28989" width="20" height="20" transform="translate(1171 271)" fill="none"/>
                                                    <path id="_8665646_phone_communication_icon" data-name="8665646_phone_communication_icon" d="M14.978,11.333,14.3,14.287A.911.911,0,0,1,13.4,15,13.419,13.419,0,0,1,0,1.591.91.91,0,0,1,.71.7L3.663.018A.92.92,0,0,1,4.711.549L6.074,3.728A.917.917,0,0,1,5.811,4.8L4.234,6.062a10.245,10.245,0,0,0,4.673,4.673L10.2,9.159A.911.911,0,0,1,11.267,8.9l3.179,1.363A1,1,0,0,1,14.978,11.333Z" transform="translate(1173.5 273.505)" fill="#dbdee7"/>
                                                </g>
                                                </svg>

                                            </div>
                                                
                                            </div>  
                                            
                                            <h4>${(parseInt(this.state.monthdata[0].amount)).toLocaleString()}.00</h4>
                                            
                                            <h5>per month</h5>
                                            
                                            {/* <a href={"/app/support-checkout/"+btoa(this.state.monthdata[0].plan_code)}>Select</a> */}
                                            {this.state.gateway_subscription_id == this.state.monthdata[0].plan_code ?
                                                <a className="lowerplan" href="#">Current</a>
                                            : this.state.monthdata[0].show_plan == 0 || this.state.downgrade_at_term_end === 1 ?
                                                <a className="currentplan" href="#">Unavailable</a>
                                            :
                                                <a href={"/app/support-checkout/"+btoa(this.state.monthdata[0].plan_code)}>Select</a>
                                            }
                                    {/* <p className="support-cancelation">Cancellation on 18 May, 2023 <svg xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061">
  <path id="Union_2539" data-name="Union 2539" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
</svg>
</p> */}                                    {this.state.gateway_subscription_id == this.state.monthdata[0].plan_code && this.state.downgrade_at_term_end === 1 && this.state.renewal_date !== null ? 
                            
                                                <p className="support-cancellation">Cancellation on {moment(this.state.renewal_date).format("D MMMM YYYY")} 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061" 
                                                onClick={(e)=>this.handleReactivePopUp(e,"cancel")}
                                                >
                                                    <path id="Union_2539" data-name="Union 2539" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                                    </svg>
                                                </p>
                                            : this.state.gateway_subscription_id == this.state.monthdata[0].plan_code && this.state.downgrade_at_term_end === 1 && this.state.renewal_date === null ?
                                                <p className="support-cancellation"> Downgrade scheduled</p>
                                            : <p></p>}
                                            </div>    
                                                
                                            <div className="mobi-tab">
                                                <input type="checkbox" id="chck3"/>
                                                <label className="mobi-tab-label" for="chck3">View Features</label>
                                            <div className="mobi-tab-content">
                                            <div className="price-second-half">
                                            
                                            <div className="support-featurebox">

                                                <div className="featureone">
                                                <h4>Response time<p className="appdash-tooltip">
                <svg style={{position: "relative", top: "-1px", left: "-3px"}} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 18 18"><g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5"><circle cx="9" cy="9" r="9" stroke="none"></circle><circle cx="9" cy="9" r="8.25" fill="none"></circle></g><line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"></line><path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"></path></g></svg>
                <p className="appdash-tooltipsubheader">This response time is only for critical issues caused by downtime in AppMySite’s technical infrastructure.</p>
                </p></h4>
                                                    <p>First response within 24 hours</p>
                                                </div>

                                                <div className="featuretwo">
                                                    <h4>Channels</h4>
                                                    <p>Knowledge base</p>
                                                    <p>Community</p>
                                                    <p>Email</p>
                                                    <p>Chat</p>
                                                    <p>Phone</p>
                                                </div>

                                                <div className="featurethree">
                                                    <h4>App Store</h4>
                                                    <p>Get Google play and Apple app store specialists to help you publish your app.</p>
                                                </div>

                                                <div className="featurefour">
                                                    <h4>Support coverage</h4>
                                                    <p>Technical support agents investigate issues comprehensively, with advanced troubleshooting.</p>
                                                </div>

                                                <div className="featurefive">
                                                    <h4>Training</h4>
                                                    <p>General onboarding support as needed.</p>
                                                </div>

                                                <div className="featuresix">
                                                    <h4>Tailored enhancements</h4>
                                                    <p>-</p>
                                                </div>

                                                <div className="featureseven">
                                                    <h4>Account Manager</h4>
                                                    <p>-</p>
                                                </div>

                                            </div>     
                                            
                                            
                                            </div>  
                                                </div>
                                            </div>
                                                
                                        </div>
                                        
                                        <div className="pricebox">
                                            
                                            <div className="price-first-half">
                                            
                                            <div className="allflex">
                                                
                                                <h2>Enterprise</h2>
                                                
                                                <div className="platforms">

                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <g id="Group_37966" data-name="Group 37966" transform="translate(-1119 -271)">
                                                    <rect id="Rectangle_28987" data-name="Rectangle 28987" width="20" height="20" transform="translate(1119 271)" fill="none"/>
                                                    <path id="Subtraction_263" data-name="Subtraction 263" d="M15,12H1a1,1,0,0,1-1-1V3.057L8,7.63,16,3.06V11A1,1,0,0,1,15,12ZM8,6.085H8L0,1.513V1A1,1,0,0,1,1,0H15a1,1,0,0,1,1,1v.516L8,6.085Z" transform="translate(1120.999 275)" fill="#dbdee7"/>
                                                </g>
                                                </svg>

                                                <svg style={{marginLeft: "10px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <g id="Group_37965" data-name="Group 37965" transform="translate(-1145 -271)">
                                                    <rect id="Rectangle_28988" data-name="Rectangle 28988" width="20" height="20" transform="translate(1145 271)" fill="none"/>
                                                    <path id="Exclusion_1" data-name="Exclusion 1" d="M3,17V13H2a2,2,0,0,1-2-2V3A2,2,0,0,1,2,1H14a2,2,0,0,1,2,2v8a2,2,0,0,1-2,2H8L3,17ZM11,6V8h2V6ZM7,6V8H9V6ZM3,6V8H5V6Z" transform="translate(1146.999 273)" fill="#dbdee7"/>
                                                </g>
                                                </svg>

                                                <svg style={{marginLeft: "10px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <g id="Group_37967" data-name="Group 37967" transform="translate(-1171 -271)">
                                                    <rect id="Rectangle_28989" data-name="Rectangle 28989" width="20" height="20" transform="translate(1171 271)" fill="none"/>
                                                    <path id="_8665646_phone_communication_icon" data-name="8665646_phone_communication_icon" d="M14.978,11.333,14.3,14.287A.911.911,0,0,1,13.4,15,13.419,13.419,0,0,1,0,1.591.91.91,0,0,1,.71.7L3.663.018A.92.92,0,0,1,4.711.549L6.074,3.728A.917.917,0,0,1,5.811,4.8L4.234,6.062a10.245,10.245,0,0,0,4.673,4.673L10.2,9.159A.911.911,0,0,1,11.267,8.9l3.179,1.363A1,1,0,0,1,14.978,11.333Z" transform="translate(1173.5 273.505)" fill="#dbdee7"/>
                                                </g>
                                                </svg>

                                                </div>
                                                
                                            </div> 
                                            
                                            <h4>${(parseInt(this.state.monthdata[1].amount)).toLocaleString()}.00</h4>
                                            
                                            <h5>per month</h5>
                                            
                                            {/* <a className="currentplan" target="_blank" href="/sign-up/">Unavailable</a> */}
                                            {/* <a href={"/app/support-checkout/"+btoa(this.state.monthdata[1].plan_code)}>Select</a> */}
                                            {this.state.gateway_subscription_id == this.state.monthdata[1].plan_code ?
                                                <a className="lowerplan" href="#">Current</a>
                                            : this.state.monthdata[1].show_plan == 0 || this.state.downgrade_at_term_end === 1 ?
                                                <a className="currentplan" href="#">Unavailable</a>
                                            :
                                                <a href={"/app/support-checkout/"+btoa(this.state.monthdata[1].plan_code)}>Select</a>
                                            }
                                            {/* <p className="support-cancellation">Cancellation on 18 May, 2023 <svg xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061">
  <path id="Union_2539" data-name="Union 2539" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
</svg>
</p> */}
                                            {this.state.gateway_subscription_id == this.state.monthdata[1].plan_code && this.state.downgrade_at_term_end === 1 && this.state.renewal_date !== null ? 
                            
                                                <p className="support-cancellation">Cancellation on {moment(this.state.renewal_date).format("D MMMM YYYY")} 
                                                <svg xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061" 
                                                onClick={(e)=>this.handleReactivePopUp(e,"cancel")}
                                                >
                                                    <path id="Union_2539" data-name="Union 2539" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                                    </svg>
                                                </p>
                                            : this.state.gateway_subscription_id == this.state.monthdata[1].plan_code && this.state.downgrade_at_term_end === 1 && this.state.renewal_date === null ?
                                                <p className="support-cancellation"> Downgrade scheduled</p>
                                            : <p></p>}
                                            </div>    
                                                
                                            <div className="mobi-tab">
                                                <input type="checkbox" id="chck4"/>
                                                <label className="mobi-tab-label" for="chck4">View Features</label>
                                            <div className="mobi-tab-content">
                                            <div className="price-second-half">
                                            
                                            <div className="support-featurebox">

                                                <div className="featureone">
                                                <h4>Response time<p className="appdash-tooltip">
                <svg style={{position: "relative", top: "-1px", left: "-3px"}} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 18 18"><g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5"><circle cx="9" cy="9" r="9" stroke="none"></circle><circle cx="9" cy="9" r="8.25" fill="none"></circle></g><line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"></line><path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"></path></g></svg>
                <p className="appdash-tooltipsubheader">This response time is only for critical issues caused by downtime in AppMySite’s technical infrastructure.</p>
                </p></h4>
                                                    <p>First response within 8 hours</p>
                                                </div>

                                                <div className="featuretwo">
                                                    <h4>Channels</h4>
                                                    <p>Knowledge base</p>
                                                    <p>Community</p>
                                                    <p>Email</p>
                                                    <p>Chat</p>
                                                    <p>Phone</p>
                                                </div>

                                                <div className="featurethree">
                                                    <h4>App Store</h4>
                                                    <p>Get Google play and Apple app store specialists to help you publish your app.</p>
                                                </div>

                                                <div className="featurefour">
                                                    <h4>Support coverage</h4>
                                                    <p>Top-tier experts provide comprehensive assistance, including in-depth investigations, relevant solutions, and guided assistance.</p>
                                                </div>

                                                <div className="featurefive">
                                                    <h4>Training</h4>
                                                    <p>Swift consultative guidance tailored to your app, with onboarding support as needed.</p>
                                                </div>

                                                <div className="featuresix">
                                                    <h4>Tailored enhancements</h4>
                                                    <p>Customizations are available, billed hourly, based on specific considerations.</p>
                                                </div>

                                                <div className="featureseven">
                                                    <h4>Account Manager</h4>
                                                    <p>Dedicated Success Manager</p>
                                                </div>

                                            </div>    
                                            
                                                
                                            </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                                
                                    </div>

                                    <div className="support-getintouch">
                                        <p>Have a question about our support plans?<a href="/get-in-touch/">Get in touch</a></p>
                                    </div>

                                </div>
                            </section>  
                            
                            <section className="paddingtop100 paddingbottom100 pricingfaq">
                                <div className="container">
                                <h3 class="sectionheadings text-center">Support Plans- Frequently Asked Questions</h3>

                                <div class="accordionWrapper">

                                    <div class="accordionItem close accordion-item"  eventKey="0">
                                        <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Which plan would be most suitable for me?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                                        <div class="accordionItemContent">

                                        <p>To choose the right plan, assess your specific requirements and the level of support you need to achieve your app-related goals.</p>

                                        <p>The Basic Plan is ideal for simple apps, providing general guidance suitable for beginners and small projects. The Growth Plans offer ongoing support while expanding your app's user base, which is perfect for scaling your audience. The Business Plans are designed for apps with advanced features and plugins, offering technical troubleshooting and step-by-step support for complex issues. Finally, the Enterprise Plan is recommended for large, mission-critical apps, providing top-tier support, comprehensive assistance, and performance optimization.</p>
                                        </div>
                                    </div>

                                    <div class="accordionItem close accordion-item"  eventKey="1">
                                        <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Is the support team available 24*7?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                                        <div class="accordionItemContent">
                                        <p>Our support team is available during business hours (GMT) from Monday to Friday. While we strive to provide timely assistance, our commitment is within these operating hours.</p> 

                                        <p>It's important to note that the response time mentioned in our support plans is specifically for critical issues that may arise due to downtime in AppMySite’s technical infrastructure. For non-critical inquiries, we aim to address them as promptly as possible during our regular business hours</p>
                                        </div>
                                    </div>

                                    <div class="accordionItem close accordion-item"  eventKey="2">
                                        <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What types of issues are considered "critical"?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                                        <div class="accordionItemContent">
                                        <p>Critical issues are defined as those arising from downtime in AppMySite’s technical infrastructure. These are issues that can directly impact the performance and user experience of your app. Our priority is to address and resolve such critical issues promptly to ensure that your app runs smoothly.</p>
                                        </div>
                                    </div>

                                    <div class="accordionItem close accordion-item"  eventKey="3">
                                        <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What are the different types/categories of issues covered by these support plans?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                                        <div class="accordionItemContent">
                                        <p>AppMySite classifies issues into four priority levels based on their severity:</p>

                                        <p>Priority 1- Critical: Involving production application downtime, severely affecting app availability.</p>
                                        <p>Priority 2- Urgent: Signifying major malfunctions in your application.</p>
                                        <p>Priority 3- Important: Relating to issues potentially degrading app performance or functionality.</p>
                                        <p>Priority 4- Attention: Involving issues with a moderate impact.</p>

                                        <p>Our aim is to provide timely assistance for all issues, with a primary focus on resolving critical and urgent matters promptly</p>
                                        </div>
                                    </div>

                                    <div class="accordionItem close accordion-item"  eventKey="4">
                                        <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How can I contact support for assistance?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                                        <div class="accordionItemContent">
                                        <p>You can easily contact our support team for assistance by using the email support form accessible in your account. Simply fill out the form with your inquiry, and we’ll get back to you promptly to assist with your questions or concerns.</p>
                                        <p>Depending upon your support plan, you may also access the “Chat with expert” and “Schedule a call” options under the “Help” section within your account.</p>
                                        </div>
                                    </div>

                                    <div class="accordionItem close accordion-item"  eventKey="5" style={{marginBottom: "15px"}}>
                                        <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What level of support is provided for the Basic plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                                        <div class="accordionItemContent">
                                        <p>The Basic plan offers general guidance to help you with your app-related inquiries. It’s a great starting point for beginners and smaller app projects, providing foundational support to get you started on the right track.</p>
                                        </div>
                                    </div>

                                    <div className="hiddenfaq" id="showfaq">

                                    <div class="accordionItem close accordion-item"  eventKey="6" style={{marginTop: "-15px"}}>
                                        <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What is the difference between general guidance and technical guidance?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                                        <div class="accordionItemContent">
                                        <p>General guidance typically refers to assistance that provides broad advice and recommendations related to your app, helping you understand basic concepts and principles. Technical guidance, on the other hand, goes deeper and involves detailed assistance with specific technical aspects of your app. It often includes troubleshooting, in-depth problem-solving, and guidance on complex technical issues.</p>

                                        <p>In summary, general guidance offers broad, non-technical support, while technical guidance delves into specific technical aspects and issues.</p>
                                        </div>
                                    </div>

                                    <div class="accordionItem close accordion-item"  eventKey="7">
                                        <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What does the advisory service cover?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                                        <div class="accordionItemContent">
                                        <p>The advisory service provides swift consultative guidance tailored to your app's needs. It also covers assistance related to app implementation within your enterprise’s existing infrastructure, along with onboarding support as needed.</p>

                                        <p>This service assists you in making informed decisions and optimizing your app's performance and functionality.</p>

                                        <p>Our experts offer advice, recommendations, and best practices to help you achieve your app-related goals effectively.</p>
                                        </div>
                                    </div>

                                    <div class="accordionItem close accordion-item"  eventKey="8">
                                        <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What kind of customizations are included in the tailored enhancements?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                                        <div class="accordionItemContent">
                                        <p>The "tailored enhancements" option allows for customizations that are billed hourly and are based on specific considerations. These customizations can cover a wide range of app-related improvements, modifications, and personalized adjustments based on your unique requirements, provided they align with our policies of inclusivity and commitment to creating technology that can benefit the masses.</p>

                                        <p>Please note that customization requests are also subject to resource allocation considerations. For instance, if the customization requests are extensive and resource-intensive, there might be limitations due to available manpower, time, or technical capabilities. This ensures that the team can effectively prioritize and complete customization requests while maintaining the quality of service and timely delivery for all users.</p>
                                        </div>
                                    </div>

                                    <div class="accordionItem close accordion-item"  eventKey="9" style={{marginBottom: "15px"}}>
                                        <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What is the hourly rate charged for tailored enhancements?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                                        <div class="accordionItemContent">
                                        <p>The hourly rate for tailored enhancements starts at $40 per hour. The actual cost may vary depending on the complexity of the task and the extent of customization required.</p>

                                        <p>This flexible pricing structure allows you to pay for the specific work done on your app, ensuring that you receive value for the enhancements you request. Whether it's minor adjustments or more intricate modifications, you can budget accordingly and make customizations that align with your app's unique needs.</p>
                                        </div>
                                    </div>

                                    </div>

                                    <h5 id="seemorelink" onClick={this.seemorefunction} >See More</h5> 
                
                                    <h5 id="seelesslink" class="d-none" onClick={this.seelessfunction}>See Less</h5>

                                    </div>
                                    
                                        </div>
                            </section>
                        
                            {this.state.spinner ?
                            <></>
                            :
                            <>
                                <Modal
                        isOpen={this.state.deluser}
                        centered
                        toggle={(e) => this.closepopup(e)}
                        style={{ maxWidth: "480px", maxHeight: "296px" }}
                        className="plan-cancel-popup"
                        dataclassName="addonpopup"
                    >
                        <div className="crossmodal mt-1" onClick={(e) => this.closepopup()}>
                        <img src={ require("../../assets/images/dashboard/cross.png") .default }alt="cross"/>
                </div>
                        <ModalBody className="">
                        <div>
                            <div className="d-flex">
                            
                            <div className="confirmtext">
                                <h4>Plan change confirmation</h4>
                                { this.state.customer_type === 4 ?
                                    <h6>
                                    Your current plan will change to Basic Plan at the end of your billing cycle, on <span>{moment(this.state.renewal_date).format(
                                        "D MMMM YYYY")}</span>{" "}
                                    </h6>
                                :
                                <>
                                    {moment().format('YYYY-MM-DD') == moment(this.state.renewal_date).format("YYYY-MM-DD") ?
                                        <>
                                        <h6>Your plan renewal is being processed today.</h6>
                                        <h6>If you proceed, your current plan will change to Basic Plan at the end of your next billing cycle.</h6>
                                        </>
                                    :   
                                        <h6>
                                        Your current plan will change to Basic Plan at the end of your billing cycle, on <span>{moment(this.state.renewal_date).format(
                                            "D MMMM YYYY")}</span>{" "}
                                        </h6>
                                    }
                                </>
                                }
                            </div>
                            </div>
                            <div className="plan-cancel-popup-footer d-flex justify-content-center">
                            <div className="plan-cancelbtn" onClick={(e) => this.closepopup()}>
                                Cancel
                            </div>

                            <div
                                onClick={(e) =>this.previewdowngrade(e)}
                            >
                                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                <p>
                                <div className={this.state.loader_remove}>
                                <img
                                    src={this.state.loaderimage_remove}
                                    alt="loader"
                                />
                                </div>
                                <div className={this.state.submitval_confirmpop}>Confirm</div>
                            </p>
                                </div>
                            </div>

                            </div>
                        </div>
                        </ModalBody>
                                        </Modal>

                                        {/* Reactivate PopUp */}
                                <Modal
                                isOpen={this.state.openReactivatePopUp}
                                style={{ maxWidth: "460px", maxHeight: "240px" }}
                                className="generalstngs2"
                                centered
                                >
                                <ModalHeader>
                                    <div className="crossmodal mt-1" onClick={(e) => this.handleReactivePopUp(e,"cancel")}>
                                    <img src={require("../../assets/images/dashboard/cross.png").default} />
                                    </div>
                                </ModalHeader>
                                
                                <ModalBody>
                                    <div>
                                    <h4>Stay on current plan?</h4>
                                    <h6>Are you sure you want to cancel your plan change request and retain your current plan?</h6>
                                    
                                    <div className="generalstngs2-footer">

                                    <div className="generalstngs2-cancel" onClick={(e) => this.handleReactivePopUp(e,"cancel")}>
                                        <p>Cancel</p>
                                        </div>
                                    
                                        <div className="generalstngs2-ok" onClick={(e) => this.handleReactivePopUp(e,"done")}>
                                        <div className={this.state.reactiveLoader}>
                                        <div className="new-loader"></div>
                                        </div>
                                        <p>{this.state.reactiveSubmitText}</p>
                                        </div>
                                    </div>
                                    </div>
                                </ModalBody>

                                </Modal>
                            </>
                            }
                            
                        </>
                }
        </>
        )
    }
}
