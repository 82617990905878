import React, { useState } from "react";

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <div className="accordion-title" onClick={() => setIsActive(!isActive)}>
        <div className="d-flex justify-content-between align-items-center">
          <h5>{title}</h5>{" "}
          {isActive === true ? (
            <img
              src={
                require("../../assets/images/dashboard/minimize.png").default
              }
            />
          ) : (
            <img
              src={require("../../assets/images/dashboard/expand.png").default}
            />
          )}
        </div>
      </div>
      {isActive && (
        <div className="accordion-content">
          <p>{content}</p>
        </div>
      )}
    </div>
  );
};

export default Accordion;
