import React from 'react'

const EditIcon = ({color}) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.263"
    height="16.263"
    viewBox="0 0 16.263 16.263"
  >
    <g
      id="Group_27285"
      data-name="Group 27285"
      transform="translate(-999.142 -1409.971) rotate(45)"
    >
      <g
        id="Path_82084"
        data-name="Path 82084"
        transform="translate(1713 281)"
        fill="#fff"
      >
        <path
          d="M 2 17.29290008544922 L -0.5 14.79290008544922 L -0.5 2 C -0.5 1.724300026893616 -0.2757000029087067 1.5 0 1.5 L 4 1.5 C 4.275700092315674 1.5 4.5 1.724300026893616 4.5 2 L 4.5 14.79290008544922 L 2 17.29290008544922 Z"
          stroke="none"
        />
        <path
          d="M 0 2 L 0 14.58578014373779 L 2 16.58577919006348 L 4 14.58578014373779 L 4 2 L 0 2 M 0 1 L 4 1 C 4.552279949188232 1 5 1.447719573974609 5 2 L 5 15 L 2 18 L -1 15 L -1 2 C -1 1.447719573974609 -0.5522799491882324 1 0 1 Z"
          stroke="none"
          fill={color}
        />
      </g>
      <path
        id="Path_82085"
        data-name="Path 82085"
        d="M-4459-11434.725h6"
        transform="translate(6171 11721)"
        fill="none"
        stroke={color}
        strokeWidth="1"
      />
    </g>
  </svg>
  )
}

export default EditIcon
