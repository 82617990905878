import React from "react";
import { Modal, ModalBody } from "reactstrap";
import WarningIcon from "./WarningIcon";
import "./RebuildrequiredModal.css";
const RebuildRequiredModal = ({ openPopUp, handlePopUp, handleProceedBtn }) => {
  return (
    <Modal
      isOpen={openPopUp}
      toggle={(e) => handlePopUp(e, "cancel")}
     
      className=" rebuild-required-modal rebuild-required-modal-custom"
      centered
    >
      <ModalBody>
        <div
          className=" rebuild-required-modal-cross"
          onClick={(e) => handlePopUp(e, "cancel")}
        >
          <img
            src={require("../../../assets/images/dashboard/cross.png").default}
          />
        </div>

        <div>
          <div className="confirm-org-body">
            <h4 className="rebuild-required-modal-title">
              <WarningIcon /> Change requires app rebuild
            </h4>
            <h5 className="rebuild-required-modal-subtitle">
              This change will require you to rebuild and re-publish your app
              immediately. Once republished, app users will see the update.
              Running an outdated version can result in glitches.
            </h5>

            <h5 className="rebuild-required-modal-subtitle-proceed">Are you sure you want to proceed?</h5>
          </div>
          <div className="rebuild-customer-footer ">
            <div
              className=" rebuild-required-modal-cancel-btn"
              onClick={(e) => handlePopUp(e, "cancel")}
            >
              Cancel
            </div>

            <div
              className="rebuild-required-modal-proceed-btn"
              id="btncustomrtactiveid"
              onClick={handleProceedBtn}
            >
              Proceed
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default RebuildRequiredModal;
