import React, { useState, useRef, useEffect } from "react";
import ColorPicker, { useColorPicker } from "react-best-gradient-color-picker";
import reactCSS from "reactcss";

function NewReactcolorpicker(props) {
  const [color, setColor] = useState(props.colorname);
  const [alpha, setaplha] = useState(40);
  const {
    gradientType,
    setLinear,
    setRadial,
    addPoint,
    deletePoint,
    degrees,
    setDegrees,
    setPointLeft,
    currentLeft,
    selectedPoint,
    setA,
  } = useColorPicker(color, setColor, setaplha);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [classselection, setclassselection] = useState(props.classselection);
  const [classgrey1, setclassgrey1] = useState(props.classgrey);
  const wrapperRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [valuecontrol, setvaluecontrol] = useState(props.valuecontrol);
  const [presetColors, setPresetColors] = useState(props.presetColors);

  const styles = reactCSS({
    default: {
      color: {
        width: "36px",
        height: "36px",
        border: "1px solid #C8CEDB",
        borderRadius: ".25rem",
        marginRight: "5px",
        marginLeft: "4px",
        background: props.colorname,
      },

      swatch: {
        height: "100%",
        cursor: "pointer",
        display: "flex",
        alignItems: "center",
      },
      popover: {
        position: "absolute",
        zIndex: "2",
      },
      cover: {
        position: "fixed",
        top: "0px",
        right: "0px",
        bottom: "0px",
        left: "0px",
      },
    },
  });

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
    setIsVisible(!isVisible);
  };

  // below is the same as componentDidMount and componentDidUnmount
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setIsVisible(false);
    }
  };

  const handleChange = (color) => {
    props.onChange(color);
    setColor(color);
  };

  const rgbaToHex = (color: string): string => {
    if (/^rgb/.test(color)) {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");

      // rgb to hex
      // eslint-disable-next-line no-bitwise
      let hex = `#${(
        (1 << 24) +
        (parseInt(rgba[0], 10) << 16) +
        (parseInt(rgba[1], 10) << 8) +
        parseInt(rgba[2], 10)
      )
        .toString(16)
        .slice(1)}`;

      return hex;
    }

    return color;
  };

  return (
    <>
      <div className="color_input" ref={wrapperRef}>
        <div style={styles.swatch} onClick={handleClick}>
          <div className="colorbox" style={styles.color} />
          <p className="m-0 p-0">
            {props.classgrey == 1
              ? ""
              : props.colorname !== undefined &&  props.colorname != '' && props.colorname.indexOf("linear-gradient") > -1
              ? "linear-gradient"
              : props.colorname !== undefined &&  props.colorname != '' && props.colorname.indexOf("radial-gradient") > -1
              ? "radial-gradient"
              : props.colorname !== undefined &&  props.colorname != '' && props.colorname.indexOf("rgba") > -1
              ? rgbaToHex(props.colorname)
              : props.colorname}
          </p>
        </div>
        {isVisible && (
          <div style={styles.popover} className={classselection}>
            <div />
            <div>
              <ColorPicker
                value={props.colorname}
                setA={alpha}
                onChange={handleChange}
                hideControls={valuecontrol}
                hideEyeDrop={true}
                hideColorGuide={true}
                hideAdvancedSliders={true}
                hideInputType={true}
                presets={props.presetColors}
              />
              <div className="mobileok d-md-none" onClick={handleClick}>
                <p>OK</p>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default NewReactcolorpicker;
