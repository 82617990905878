import axios from "axios";
import {errortoast, sucesstoast} from '../../Dashboard/Toaster';
const Url = process.env.REACT_APP_API_URL + "api/app/";


const Loader = state => {
    state.setState({
        submitval : "",
        loader : 'new-loader',
    })
}
// Get api
export const Bottommenuget= (state) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.get(Url + "get-chat-preference/" + btoa(localStorage.getItem("Appid")),{
      headers: headers
    }).then(res => {
        state.setState({
            code:res.data.code
        })
        if(res.data.code === 200)
        {
            
            state.setState({
                android_build_version:res.data.android_build_version,
                android_supported_version:res.data.android_supported_version,
                ios_build_version:res.data.ios_build_version,
                ios_supported_version:res.data.ios_supported_version,
                last_updated_timestamp:res.data.updated_at,
            });
            
            if(res.data.chat_data !== null){
               
                state.setState({
                    
                   // zendesk_app_id: res.data.data.filter(res => res.module === "zendesk")[0].module_data.module_account_key ,
                    zendesk_status: res.data.chat_data.filter(res => res.module === "zendesk")[0].module_status === 1 ? true : false,
                    //zendesk_client_id: res.data.data[0].module_data.module_client_id,
                    zendesk_accountkey: res.data.chat_data.filter(res => res.module === "zendesk")[0].module_data.module_account_key,
                    livechat_licence_id: res.data.chat_data.filter(res => res.module === "livechat")[0].module_data.module_licence_id,
                    livechat_group_id:res.data.chat_data.filter(res => res.module === "livechat")[0].module_data.module_group_id,
                    livechat_status: res.data.chat_data.filter(res => res.module === "livechat")[0].module_status === 1 ? true : false,
                    tawk_url: res.data.chat_data.filter(res => res.module === "tawkto")[0].module_data.module_url,
                    tawk_status: res.data.chat_data.filter(res => res.module === "tawkto")[0].module_status === 1 ? true : false,
                    custom_url: res.data.chat_data.filter(res => res.module === "custom_url")[0].module_data.module_url,
                    custom_status: res.data.chat_data.filter(res => res.module === "custom_url")[0].module_status === 1 ? true : false,
                    intercom_status:res.data.chat_data.filter(res => res.module === "intercom")[0].module_status === 1 ? true : false,
                    android_app_id: res.data.chat_data.filter(res => res.module === "intercom")[0].module_data.android_app_id,
                    android_api_key: res.data.chat_data.filter(res => res.module === "intercom")[0].module_data.android_api_key,
                    ios_app_id: res.data.chat_data.filter(res => res.module === "intercom")[0].module_data.ios_app_id,
                    ios_api_key: res.data.chat_data.filter(res => res.module === "intercom")[0].module_data.ios_api_key,
                    show_chat_on_all_screen: res.data.chat_data.filter(res => res.module === "intercom")[0].module_data.show_chat_on_all_screen === 1 ? true :  false,
                    script_status:res.data.chat_data.filter(res => res.module === "custom_script")[0].module_status === 1 ? true : false,
                    module_script:res.data.chat_data.filter(res => res.module === "custom_script")[0].module_data.module_script,
                    is_ios_rebuild_required:res.data.is_ios_rebuild_required,
                    build_status:res.data.build_status,
                    bodyspinner:false,
                    build_process_status:res.data.build_process_status === 1 ? true : false
                })
            }else{
                state.setState({
                    bodyspinner:false,
                    build_process_status:res.data.build_process_status === 1 ? true : false
                })
            }
        }else{
            state.setState({
                code:res.data.code,
                plan_code:res.data.plan_code,
                bodyspinner:false,
                addon_code:res.data.addon_code ,
                reseller_package_id:res.data.reseller_package_id ,
            }) 
        }
    })
}

// Get New Chat api
export const ChatDataget= (state) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.get(Url + "get-chat-preference/" + btoa(localStorage.getItem("Appid")),{
      headers: headers
    })
    .then(res => {
        state.setState({
            code:res.data.code
        })
        if(res.data.code === 200)
        {
            state.handlechatinputcolor(res.data.chat_settings != '' && res.data.chat_settings && res.data.chat_settings.floating_chat_icon_color_object !== undefined
                ? res.data.chat_settings.floating_chat_icon_color_object.portal_data
                : 'rgba(255,255,255,1)')
            state.setState({
                android_build_version:res.data.android_build_version,
                android_supported_version:res.data.android_supported_version,
                ios_build_version:res.data.ios_build_version,
                ios_supported_version:res.data.ios_supported_version,
                last_updated_timestamp:res.data.updated_at,
                show_floating_chat_icon: 
                    res.data.chat_settings != '' && res.data.chat_settings 
                    ? res.data.chat_settings.show_floating_chat_icon
                    : false,
                show_chat_link_in_settings:
                    res.data.chat_settings != '' && res.data.chat_settings 
                    ? res.data.chat_settings.show_chat_link_in_settings
                    : false,
                show_chat_link_in_profile: 
                res.data.chat_settings != '' && res.data.chat_settings 
                ? res.data.chat_settings.show_chat_link_in_profile : "",
                floating_chat_icon_type: res.data.chat_settings != '' && res.data.chat_settings ? res.data.chat_settings.floating_chat_icon_type : "",
                chat_link_icon_type_in_profile:  res.data.chat_settings != '' && res.data.chat_settings ? res.data.chat_settings.chat_link_icon_type_in_profile : "",
                chat_link_icon_name_in_profile:  res.data.chat_settings != '' && res.data.chat_settings && res.data.chat_settings.chat_link_icon_name_in_profile ? res.data.chat_settings.chat_link_icon_name_in_profile : "chat_bubble.png",
                floating_chat_icon_name: res.data.chat_settings != '' && res.data.chat_settings && res.data.chat_settings.floating_chat_icon_name ? res.data.chat_settings.floating_chat_icon_name : "chat_bubble.png",
                chat_link_text_in_profile: res.data.chat_settings != '' && res.data.chat_settings && res.data.chat_settings.chat_link_text_in_profile ? res.data.chat_settings.chat_link_text_in_profile : "Chat",
                chat_link_text_in_settings: res.data.chat_settings != '' && res.data.chat_settings && res.data.chat_settings.chat_link_text_in_settings ? res.data.chat_settings.chat_link_text_in_settings : "Chat With Us",
                chatbackgroundColor:
                res.data.chat_settings != '' && res.data.chat_settings && res.data.chat_settings.floating_chat_icon_bg_color_object !== undefined
                ? res.data.chat_settings.floating_chat_icon_bg_color_object.portal_data 
                : "rgba(26, 26, 26, 0.8)",
                
                chaticoncolor:
                res.data.chat_settings != '' && res.data.chat_settings && res.data.chat_settings.floating_chat_icon_color_object !== undefined
                ? res.data.chat_settings.floating_chat_icon_color_object.portal_data
                : 'rgba(255,255,255,1)',
                });
            if(res.data.chat_data !== null)
            {
                    res.data.chat_data.filter(res1 => 
                    {
    
                        if (res1.module == "chatgpt_url") 
                        {

                            state.setState
                            ({
                                chatgpt_status : res.data.chat_data.filter(res => res.module === "chatgpt_url")[0].module_status === 1 ? true : false,
                                chatgpt_url:res.data.chat_data.filter(res => res.module === "chatgpt_url")[0].module_data.module_url,
                                hubspot_url:res.data.chat_data.filter(res => res.module === "hubspot_url")[0].module_data.module_url,
                                hubspot_status : res.data.chat_data.filter(res => res.module === "hubspot_url")[0].module_status === 1 ? true : false,
                            });

                        }
                    });
               
                state.setState({
                    
                   // zendesk_app_id: res.data.data.filter(res => res.module === "zendesk")[0].module_data.module_account_key ,
                    zendesk_status: res.data.chat_data.filter(res => res.module === "zendesk")[0].module_status === 1 ? true : false,
                    //zendesk_client_id: res.data.data[0].module_data.module_client_id,
                    zendesk_accountkey: res.data.chat_data.filter(res => res.module === "zendesk")[0].module_data.module_account_key,
                    livechat_licence_id: res.data.chat_data.filter(res => res.module === "livechat")[0].module_data.module_licence_id,
                    livechat_group_id:res.data.chat_data.filter(res => res.module === "livechat")[0].module_data.module_group_id,
                    livechat_status: res.data.chat_data.filter(res => res.module === "livechat")[0].module_status === 1 ? true : false,
                    tawk_url: res.data.chat_data.filter(res => res.module === "tawkto")[0].module_data.module_url,
                    tawk_status: res.data.chat_data.filter(res => res.module === "tawkto")[0].module_status === 1 ? true : false,
                    custom_url: res.data.chat_data.filter(res => res.module === "custom_url")[0].module_data.module_url,
                    custom_status: res.data.chat_data.filter(res => res.module === "custom_url")[0].module_status === 1 ? true : false,
                    intercom_status:res.data.chat_data.filter(res => res.module === "intercom")[0].module_status === 1 ? true : false,
                    android_app_id: res.data.chat_data.filter(res => res.module === "intercom")[0].module_data.android_app_id,
                    android_api_key: res.data.chat_data.filter(res => res.module === "intercom")[0].module_data.android_api_key,
                    ios_app_id: res.data.chat_data.filter(res => res.module === "intercom")[0].module_data.ios_app_id,
                    ios_api_key: res.data.chat_data.filter(res => res.module === "intercom")[0].module_data.ios_api_key,
                    show_chat_on_all_screen: res.data.chat_data.filter(res => res.module === "intercom")[0].module_data.show_chat_on_all_screen === 1 ? true :  false,
                    script_status:res.data.chat_data.filter(res => res.module === "custom_script")[0].module_status === 1 ? true : false,
                    module_script:res.data.chat_data.filter(res => res.module === "custom_script")[0].module_data.module_script,
                    
                    is_ios_rebuild_required:res.data.is_ios_rebuild_required,
                    build_status:res.data.build_status,
                    bodyspinner:false,
                    build_process_status:res.data.build_process_status === 1 ? true : false,
                   
                })
            }else{
                state.setState({
                    bodyspinner:false,
                    build_process_status:res.data.build_process_status === 1 ? true : false
                })
            }
        }else{
            state.setState({
                code:res.data.code,
                plan_code:res.data.plan_code,
                bodyspinner:false,
                addon_code:res.data.addon_code ,
                reseller_package_id:res.data.reseller_package_id ,
            }) 
        }
    }).catch((error) => {
   })
}


//save Api
export const Savechatdata = (params,state) => {
    Loader(state);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.post(Url + "save-chat-preference",params,{
      headers: headers
        }).then(res => {
            if(res.data.code === 200){
               state.setState({
                    submitval:"Save",
                    loader : 'd-none',
                    spinner:true,
                    hit:false,
                    last_updated_timestamp:res.data.getdata.updated_at,
                })
                sucesstoast("The record has been saved.")
                
            }
       }).catch(() => {
            state.setState({
                submitval:"Save",
                loader : 'd-none',
            })
            errortoast("Oops! Something went wrong.")
       })
    }