import React from 'react'

const CreateImageIcon = () => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1.581 16.135C1.44927 16.1294 1.3228 16.0817 1.2202 15.9989C1.1176 15.9161 1.04427 15.8026 1.011 15.675C0.995813 15.6074 0.994122 15.5375 1.00602 15.4693C1.01792 15.4011 1.04318 15.3359 1.08035 15.2774C1.11751 15.219 1.16585 15.1684 1.22259 15.1287C1.27934 15.089 1.34336 15.0609 1.411 15.046C1.69107 14.9467 1.93146 14.7593 2.09621 14.512C2.26096 14.2647 2.34123 13.9707 2.325 13.674V13.102C2.31719 12.6521 2.40467 12.2057 2.58167 11.7921C2.75867 11.3785 3.0212 11.007 3.352 10.702C3.95464 10.1323 4.74693 9.80597 5.576 9.786C6.39482 9.81585 7.171 10.1587 7.74459 10.7438C8.31817 11.3289 8.64546 12.1117 8.659 12.931C8.66816 13.3541 8.59205 13.7748 8.43519 14.1679C8.27834 14.5609 8.04395 14.9184 7.746 15.219C7.08772 15.8124 6.23223 16.1396 5.346 16.137L1.581 16.135ZM7.118 8.869L14.538 2.232C14.6726 2.09515 14.833 1.98647 15.0101 1.91228C15.1871 1.83809 15.3771 1.79988 15.569 1.79988C15.7609 1.79988 15.9509 1.83809 16.1279 1.91228C16.305 1.98647 16.4654 2.09515 16.6 2.232C16.8556 2.51117 17.002 2.87316 17.0123 3.25155C17.0226 3.62994 16.8961 3.99935 16.656 4.292L9.687 11.73C9.50173 11.0905 9.17448 10.5011 8.72966 10.0057C8.28484 9.51035 7.73393 9.12178 7.118 8.869Z" fill=""/>
<path d="M9.00399 4.4L8.00399 4.678C7.49623 4.81975 7.0336 5.09007 6.66083 5.46284C6.28806 5.83562 6.01774 6.29824 5.87599 6.806L5.59798 7.806L5.31998 6.806C5.17824 6.29824 4.90792 5.83562 4.53515 5.46284C4.16238 5.09007 3.69975 4.81975 3.19199 4.678L2.19199 4.4L3.19199 4.122C3.6996 3.98123 4.16237 3.71202 4.53564 3.34033C4.90891 2.96863 5.18008 2.50701 5.32299 2L5.60099 1L5.87899 2C6.02074 2.50777 6.29106 2.97038 6.66383 3.34316C7.03661 3.71593 7.49922 3.98625 8.00699 4.128L9.00399 4.4Z" fill=""/>
<path d="M17.009 12.743L16.33 12.932C15.9845 13.0284 15.6696 13.2123 15.416 13.466C15.1623 13.7196 14.9784 14.0345 14.882 14.38L14.692 15.059L14.503 14.38C14.4066 14.0345 14.2227 13.7196 13.9691 13.466C13.7154 13.2123 13.4005 13.0284 13.055 12.932L12.377 12.743L13.055 12.554C13.4005 12.4576 13.7154 12.2737 13.9691 12.02C14.2227 11.7664 14.4066 11.4515 14.503 11.106L14.692 10.428L14.882 11.106C14.9784 11.4515 15.1623 11.7664 15.416 12.02C15.6696 12.2737 15.9845 12.4576 16.33 12.554L17.009 12.743Z" fill=""/>
</svg>
  )
}

export default CreateImageIcon