import React, {useState, useEffect} from "react";
import "./../assets/css/common.css";
import Header from "../../Dashboard/Header";
import LeftPanel from "./../LeftPanel";
import { Link, useParams } from "react-router-dom";
import {errortoast} from '../../Dashboard/Toaster';
import {getAppsDetail,receivedData,getsearchappsdata,getAgencyDomainStatus} from "../../Api/Agency/AgencyApi";
import moment from "moment";
import imagebackground from "./../assets/images/accountprofile.png";
import imagebackground2 from "./../assets/images/alexandergrey.jpg";
import NewSpinner from "./../NewSpinner";
import ReactPaginate from 'react-paginate';
import BottomScrollListener from "react-bottom-scroll-listener";

const Apps = () =>{

    const [rightpanelexpand,setRightPanelExpand] = useState(true);
    const [dataapps, setApps] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [spinner1, setSpinner1] = useState(true);
    const [searchtext, setSearchText] = useState("");
    const [offset, setoffset] = useState(0);
    const [data, setdata] = useState([[]]);
    const [currentPage, setcurrentPage] = useState(1);
    const [pageCount,setpageCount] = useState(0);
    const [stoplength,setstoplength] = useState(0);
    const [appscount,setAppsCount] = useState(0);
    const [useappscount,setUseAppsCount] = useState(0);
    const[domain,setDomain] = useState('');
    const[domainstatus,setDomainStatus] = useState('');
    const[domainname,setDomainName] = useState('');
    const [searcheable, setsearcheable] = useState(false);

    let { agencyID } = useParams("agencyID");

    useEffect(()=>{
        document.title = "Apps | AppMySite";

        if(agencyID !== '' && agencyID !== undefined)
		{
            getAppsDetail(agencyID, setSpinner, dataapps,setApps,setpageCount,setSpinner1,setAppsCount,setUseAppsCount);
            getAgencyDomainStatus(agencyID,setDomainName,setDomainStatus);
        }

    }, []);

    const handleTxtSearch = (e) =>
    {
        setSearchText(e.target.value);
    }
    const searchdata = (e) =>
    {
        if(e.keyCode == 13)
        {
            getsearchappsdata(searchtext,agencyID, setSpinner, setApps,setsearcheable);
        }
    }

    const handleRightPanelExpand = (rightpanelvalue) => {

        setRightPanelExpand(rightpanelvalue);
    }
    // bottom scroll listner
  const handleBottomScrollListner = () => {
     setSpinner1(true);
     const selectedPage = currentPage+1;
     setcurrentPage(selectedPage);
     receivedData(selectedPage,agencyID, setSpinner1, dataapps,setApps,setpageCount,setstoplength);
 
 
   };

    // const handlePageClick = (e) => {
    //     setSpinner1(true);
    //     const selectedPage = e.selected+1;
        
    //     setcurrentPage(selectedPage);
    //     receivedData(selectedPage,agencyID, setSpinner1, setApps,setpageCount);
        
    // };

    return(
       
        <BottomScrollListener onBottom={handleBottomScrollListner}>
        {(scrollRef) => (
        <>
            <div className="org-module org-module-customer-div" ref={scrollRef}>
                <Header />
                
                    <div className="org-structure">

                        <LeftPanel rightpanelbody={handleRightPanelExpand}/>
                        
                                    <div className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined &&
                                    localStorage.getItem('sidebaserotate') === 'true') ? "org_background org_background-expand" : "org_background "} >

                                        
                                            
                                                    <div className="org-customer-head">

                                                        <div className="agency-headings">
                                                        <h1>Apps</h1>
                                                        <p class="agency-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                        <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                                            <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                                            <circle cx="9" cy="9" r="9" stroke="none"/>
                                                            <circle cx="9" cy="9" r="8.25" fill="none"/>
                                                            </g>
                                                            <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                                                            <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                                        </g>
                                                        </svg><p class="agency-tooltipsubheader">All apps created are listed here.</p></p>
                                                        </div>

                                                        { dataapps.length > 0 || searcheable ?                                                        
                                                        
                                                        <div className="org-app-head">
                                                        <div>   
                                                            <h5>
                                                            {/* <span>{useappscount}/{appscount}</span>used */}
                                                            </h5>
                                                        </div>
                                                        <div className="agency-search">
                                                            <svg id="Group_37943" data-name="Group 37943" xmlns="http://www.w3.org/2000/svg" width="16.53" height="16.53" viewBox="0 0 16.53 16.53">
                                                                <g id="Ellipse_246" data-name="Ellipse 246" transform="translate(14.675) rotate(90)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                                                    <ellipse cx="7.337" cy="7.337" rx="7.337" ry="7.337" stroke="none"/>
                                                                    <ellipse cx="7.337" cy="7.337" rx="6.587" ry="6.587" fill="none"/>
                                                                </g>
                                                                <path id="Path_6064" data-name="Path 6064" d="M0,5.347V0" transform="translate(16 16) rotate(135)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                                            </svg>
                                                            <input type="text" placeholder="Search" name="txtsearch" id="txtsearch" onKeyDown={(e) => searchdata(e)} onChange={(e)=>handleTxtSearch(e)} value={searchtext} />
                                                        </div>
                                                        </div>

                                                        : null
                                                        }
                                                    </div>
                                        {spinner ?
                                            <NewSpinner />
                                        :
                                            <>

                                                    {dataapps.length <= 0 &&
                                                        <div className="org-module-shadow">
                                                            <div className="no-customer">
                                                                <div>
                                                                    <img src={require("../assets/images/no-app.png").default} alt="no-app"/>
                                                                    <p>Nothing here at the moment. Come back later?</p>
                                                                {domainstatus == 2 ?
                                                                  <a href={domainname} target="_blank">Go to my domain <svg xmlns="http://www.w3.org/2000/svg" width="11.2" height="11.2" viewBox="0 0 11.2 11.2">
                                                                  <g id="Group_9160" data-name="Group 9160" transform="translate(-695.4 -246.4)">
                                                                    <path id="Path_10239" data-name="Path 10239" d="M4,1H1A1,1,0,0,0,0,2V9a1,1,0,0,0,1,1H8A1,1,0,0,0,9,9V6" transform="translate(696 247)" fill="none" stroke="#c8cedb" stroke-width="1.2"/>
                                                                    <path id="Path_10240" data-name="Path 10240" d="M4,4V0H0" transform="translate(702 247)" fill="none" stroke="#c8cedb" stroke-width="1.2"/>
                                                                    <path id="Path_10241" data-name="Path 10241" d="M2144-23303l-6,6" transform="translate(-1438 23550)" fill="none" stroke="#c8cedb" stroke-width="1.2"/>
                                                                  </g>
                                                                </svg></a>
                                                                : <a href={`/agency/domain/`+(agencyID)}>Activate domain</a>
                                                                }
                                                                    
                                                                </div>
                                                            </div>      
                                                        </div>
                                                    }

                                                    {dataapps.length > 0 &&
                                                        <>
                                                             
                                                            <div className="agency-apps-web org-module-shadow"  >
                                                                <div className="agency-customer-list" >
                                                                    <div className="agency-applist-head">
                                                                        <div>
                                                                            <h6>App</h6>
                                                                        </div>
                                                                        <div>
                                                                            <h6>Domain</h6>
                                                                        </div>
                                                                        <div>
                                                                            <h6>Type</h6>
                                                                        </div>
                                                                        <div>
                                                                            <h6>Downloads</h6>
                                                                        </div>
                                                                        <div>
                                                                            <h6>Plan</h6>
                                                                        </div>
                                                                        <div>
                                                                            <h6>Created</h6>
                                                                        </div>
                                                                    </div>

                                                                    <div className="agency-customer-body">                                    
                                                                        
                                                                        { dataapps.map((appslist, appskey)=>{
                                                                                if(appslist.app_icon !== '' && appslist.app_icon !== null && appslist.app_icon !== undefined) 
                                                                                {
                                                                                    var appicon = JSON.parse(appslist.app_icon.create_json);
                                                                                }
                                                                                else
                                                                                {
                                                                                    var appicon = '';
                                                                                }

                                                                                if(appslist.app_download_property !== null)
                                                                                {
                                                                                    var json = JSON.parse(appslist.app_download_property.create_json);
                                                                                    if(json.play_store_app_downloads.default && json.play_store_app_downloads.default !== null && json.play_store_app_downloads.default !== undefined && json.play_store_app_downloads.default.numberOfInstalls == undefined && json.play_store_app_downloads.default.numberOfInstalls == null)
                                                                                    {
                                                                                        var appno = json.play_store_app_downloads.default.numberOfInstalls;
                                                                                    }
                                                                                    else
                                                                                    {
                                                                                        var appno = 0 ;
                                                                                    }
                                                                                    
                                                                                    
                                                                                }
                                                                                else
                                                                                {
                                                                                    var appno = '-';
                                                                                }
                                                                                
                                                                                return(
                                                                                <div className="customer-body-row">
                                                                                    <div className="customer-profile agency-app-entry">
                                                                                        <div className="agency-app-image" 
                                                                                        style=
                                                                                        {{
                                                                                            backgroundImage:
                                                                                            appicon && appicon.app_frontend_display_icon && appslist.subscription[0] && appslist.subscription[0] !== null && appslist.subscription[0] !== undefined && appslist.subscription.length > 0 
                                                                                            ?
                                                                                                "url(" +
                                                                                                    process.env.REACT_APP_Image_Path +
                                                                                                    appslist.subscription[0].user_id +
                                                                                                    "/" +
                                                                                                    appslist.id +
                                                                                                    process.env.REACT_APP_AppIcon_Display +
                                                                                                    appicon.app_frontend_display_icon +
                                                                                                ")"
                                                                                            : "url(" + imagebackground2 + ")"
                                                                                            ,
                                                                                        }}
                                                                                        >
                                                                                        </div>
                                                                                        <div>
                                                                                            <h2>{appslist.app_name}</h2>
                                                                                            <h3>App ID: {(appslist.id)}</h3>
                                                                                        </div>
                                                                                    </div>
                                                    
                                                                                    <div className="agency-app-entry">
                                                                                        <h4>{appslist.store_url && appslist.store_url != '' ? appslist.store_url.replace('https://','') : ''}</h4>
                                                                                    </div>
                                                    
                                                                                    <div className="agency-app-entry">
                                                                                        { appslist.website_technology === 2 ?
                                                                                            <p class="apptype-tooltip"><img src={require("../assets/images/webtoapp.png").default} alt="webtoapp"/><p class="apptype-tooltipsubheader">Web to App</p></p>
                                                                                        :
                                                                                            (appslist.website_technology === 3 ?
                                                                                                <p class="apptype-tooltip"><img src={require("../assets/images/customapp.png").default} alt="customapps"/> <p class="apptype-tooltipsubheader">Custom App</p></p>   
                                                                                            :
                                                                                                (appslist.website_technology === 4 ?
                                                                                                    <p class="apptype-tooltip"><img src={require("../assets/images/wordpress.png").default} alt="wordpress"/><p class="apptype-tooltipsubheader">Wordpress App</p></p>
                                                                                                :
                                                                                                    (appslist.website_technology === 5 ?
                                                                                                        <p class="apptype-tooltip"><img src={require("../assets/images/woocomerce.png").default} alt="woocommerce"/><p class="apptype-tooltipsubheader">WooCommerce App</p></p>
                                                                                                    :
                                                                                                        null
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                    
                                                                                    <div className="agency-app-entry d-flex">
                                                                                        {appslist.app_download_property !== null ?
                                                                                        <>
                                                                                            <div className="agency-app-download-stat">
                                                                                                <img src={require("../assets/images/android.png").default} alt="android"/>
                                                                                                <p>{appno}</p>
                                                                                            </div>
                                                        
                                                                                            <div className="agency-app-download-stat">
                                                                                                <img src={require("../assets/images/ios.png").default} alt="ios"/>
                                                                                                <p>-</p>
                                                                                            </div>
                                                                                        </>
                                                                                        :
                                                                                        '-'
                                                                                        }
                                                                                        
                                                                                    </div>
                                                    
                                                                                    <div className="agency-app-entry">
                                                                                        <h4>
                                                                                            {
                                                                                            appslist.subscription && appslist.subscription !== null && 
                                                                                            appslist.subscription !== undefined && 
                                                                                            appslist.subscription.length > 0 ? 
                                                                                            appslist.subscription[0].package_info.plan_name 
                                                                                            : ''
                                                                                            }
                                                                                        </h4>
                                                                                    </div>
                                                    
                                                                                    <div className="customer-status agency-app-entry">
                                                                                        <h4>{ moment( appslist.created_at ).format("D MMMM YYYY, HH:mm") }</h4>
                                                                                    </div>
                                                    
                                                                                </div>
                                                                                )

                                                                            })
                                                                        }

                                                                    </div>
                                                                    {spinner1 ?
                                                                         <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                                         <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                             <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                 <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                 </animateTransform>
                                                                             </circle>
                                                                         </g>
                                                                         <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                             <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                 <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                 </animateTransform>
                                                                             </circle>
                                                                         </g>
                                                                         <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                             <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                 <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                 </animateTransform>
                                                                             </circle>
                                                                         </g>
                                                                         <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                             <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                 <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                 </animateTransform>
                                                                             </circle>
                                                                         </g>
                                                                     </svg>
                                                                    :
                                                                        null
                                                                    }
                                                                </div>
                                                            </div>
                                                               
                                                            <div className="agency-apps-mobile-view">

                                                    { dataapps.map((appslist, appskey)=>{
                                                                        if(appslist.app_icon !== '' && appslist.app_icon !== null && appslist.app_icon !== undefined) 
                                                                        {
                                                                            var appicon = JSON.parse(appslist.app_icon.create_json);
                                                                        }
                                                                        else
                                                                        {
                                                                            var appicon = '';
                                                                        }

                                                                        if(appslist.app_download_property !== null)
                                                                        {
                                                                                  var json = JSON.parse(appslist.app_download_property.create_json);
                                                                                    if(json.play_store_app_downloads.default && json.play_store_app_downloads.default !== null && json.play_store_app_downloads.default !== undefined && json.play_store_app_downloads.default.numberOfInstalls == undefined && json.play_store_app_downloads.default.numberOfInstalls == null)
                                                                                    {
                                                                                        var appno = json.play_store_app_downloads.default.numberOfInstalls;
                                                                                    }
                                                                                    else
                                                                                    {
                                                                                        var appno = 0 ;
                                                                                    }
                                                                            
                                                                        }
                                                                        else
                                                                        {
                                                                            var appno = '-';
                                                                        }
                                                                        return(
                                                    <div className="agency-apps-mobile">
                                                    <div className="org-module-shadow">

                                                        <div className="agency-apps-mob-1">
                                                            <div>
                                                            <h2>{appslist.app_name}</h2>
                                                            <h3>App ID: {appslist.id}</h3>
                                                            </div>

                                                            <div className="agency-app-image-mobile" style={{backgroundImage:
                                                                                    appicon && appicon.app_frontend_display_icon && appslist.subscription[0] && appslist.subscription[0] !== null && appslist.subscription[0] !== undefined && appslist.subscription.length > 0 
                                                                                    ?
                                                                                        "url(" +
                                                                                            process.env.REACT_APP_Image_Path +
                                                                                            appslist.subscription[0].user_id +
                                                                                            "/" +
                                                                                            appslist.id +
                                                                                            process.env.REACT_APP_AppIcon_Display +
                                                                                            appicon.app_frontend_display_icon +
                                                                                        ")"
                                                                                    : "url(" + imagebackground2 + ")"
                                                                                    ,}}></div>
                                                        </div>

                                                        <div className="agency-apps-mob-2">
                                                            <div>
                                                                <h6>Domain</h6>
                                                                <h4>{appslist.store_url && appslist.store_url != '' ? appslist.store_url.replace('https://','') : ''}</h4>
                                                            </div>
                                                        </div>

                                                        <div className="agency-apps-mob-3">
                                                            <div>
                                                                <h6>Type</h6>
                                                                { appslist.website_technology === 2 ?
                                                                                    <img src={require("../assets/images/webtoapp.png").default} alt="webtoapp"/>
                                                                                :
                                                                                    (appslist.website_technology === 3 ?
                                                                                        <img src={require("../assets/images/customapp.png").default} alt="customapps"/>    
                                                                                    :
                                                                                        (appslist.website_technology === 4 ?
                                                                                            <img src={require("../assets/images/wordpress.png").default} alt="wordpress"/>
                                                                                        :
                                                                                            (appslist.website_technology === 5 ?
                                                                                                <img src={require("../assets/images/woocomerce.png").default} alt="woocommerce"/>
                                                                                            :
                                                                                                null
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="agency-apps-mob-4">
                                                            <div>
                                                                <h6>Downloads</h6>
                                                                
                                                                <div className="agency-app-technology d-flex">
                                                                {appslist.app_download_property !== null ?
                                                                
                                                                    <>
                                                                    <div className="agency-app-download-stat">
                                                                        <img src={require("../assets/images/android.png").default} alt="android"/>
                                                                        <p>{appno}</p>
                                                                    </div>
                                
                                                                    <div className="agency-app-download-stat">
                                                                        <img src={require("../assets/images/ios.png").default} alt="ios"/>
                                                                        <p>-</p>
                                                                    </div>
                                                                    </>
                                                                : 
                                                                    '-'
                                                                }    
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="agency-apps-mob-2">
                                                            <div>
                                                                <h6>Plan</h6>
                                                                <h4>{
                                                                                    appslist.subscription && appslist.subscription !== null && 
                                                                                    appslist.subscription !== undefined && 
                                                                                    appslist.subscription.length > 0 ? 
                                                                                    appslist.subscription[0].package_info.plan_name 
                                                                                    : ''
                                                                                    }</h4>
                                                            </div>
                                                        </div>

                                                        <div className="agency-apps-mob-2">
                                                            <div>
                                                                <h6>Created</h6>
                                                                <h4>{ moment( appslist.created_at ).format("D MMMM YYYY, HH:mm") }</h4>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    

                                                    </div>  
                                                        )
                                                    })
                                                    }  

                                                    {spinner1 ?
                                                                         <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                                         <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                             <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                 <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                 </animateTransform>
                                                                             </circle>
                                                                         </g>
                                                                         <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                             <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                 <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                 </animateTransform>
                                                                             </circle>
                                                                         </g>
                                                                         <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                             <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                 <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                 </animateTransform>
                                                                             </circle>
                                                                         </g>
                                                                         <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                             <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                 <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                 </animateTransform>
                                                                             </circle>
                                                                         </g>
                                                                     </svg>
                                                                    :
                                                                        null
                                                                    }

                                                    

                                                            </div>
                                                        </>
                                                    }

                                                   
                                            </>
                                        }
                                        
                                    </div>
                                    
                    </div>
            
            </div>
        </>
        )}
        </BottomScrollListener>
                                                            
        
    );
    
}

export default Apps;