import React, { useEffect } from "react";
import styles from "../../../Dashboard/Charts/dashboardchart.module.css";
import Histogram from "../../../Dashboard/Charts/DashboardHistogram";
import { Link } from "react-router-dom/cjs/react-router-dom";
import AmsSpinner from "../../../Components/commonIcons/AmsSpinner";
import ForwardArrow from "../../../Components/commonIcons/ForwardArrow";
import { useAnalyticsTokenDataQuery } from "../../../services/appDashboardApiSlice";
import { useAnalyticsDataQuery } from "../../../services/analyticsApiSlice";

export const UpgradeAnalytics = () => {
  return (
    <div className="dashboard-analytics">
      <h3>App traffic</h3>
      <Link to={"/app/analytics"}>
        <ForwardArrow />
      </Link>
    </div>
  );
};

const AnalyticsCard = ({ dashboardData, setDashboardData }) => {
  const returnUrl = () => {
    let url = "";
    if (parseInt(localStorage.getItem("website_technology")) === 2) {
      url = "non-wp-analytics";
    } else if (parseInt(localStorage.getItem("website_technology")) === 3) {
      url = "cms-analytics";
    } else if (parseInt(localStorage.getItem("website_technology")) === 4) {
      url = "wordpress-analytics";
    } else if (parseInt(localStorage.getItem("website_technology")) === 5) {
      url = "woocommerce-analytics";
    }
    return url;
  };
  const utmSrc = `?utm_source=app-dashboard&utm_medium=app-builder&utm_campaign=analytics-card`;

  const { data: analyticsTokenData } = useAnalyticsTokenDataQuery();

  const { data: analyticsData } = useAnalyticsDataQuery(
    {
      token: dashboardData.token,
      url: returnUrl(),
      intervalFilterVal: dashboardData.intervalFilterVal,
      selectfilter: dashboardData.selectfilter,
    },
    { skip: !dashboardData.token }
  );

  useEffect(() => {
    if (analyticsTokenData) {
      let currObj = { ...dashboardData };
      currObj.token = analyticsTokenData.token;
      setDashboardData(currObj);
    }
    if (analyticsData) {
      let currObj = { ...dashboardData };
      currObj.reportdata = analyticsData;
      currObj.histogramspinner = false;
      setDashboardData(currObj);
    }
  }, [analyticsTokenData, analyticsData]);

  const Last7Days = (checklength) => {
    var result = [];
    for (var i = 0; i < checklength; i++) {
      var d = new Date();
      d.setDate(d.getDate() - i);
      result.push(d.toDateString());
    }

    return { result };
  };

  const checkdate = (e) => {
    let newObject = [];
    let a = 0;
    let newdate = Last7Days(
      parseInt(dashboardData.selectfilter.replace("d", ""))
    );
    for (a; a < newdate.result.length; a++) {
      newObject.push(
        `${newdate.result[a].split(" ")[2]} ${newdate.result[a].split(" ")[1]}`
      );
    }
    let finalobj = [...newObject].reverse();
    return finalobj.map((resv) => resv);
  };

  const checkdata = (e) => {
    let newObject = [];
    let newdate = Last7Days(
      parseInt(dashboardData.selectfilter.replace("d", ""))
    );
    let a = 0;
    for (a; a < newdate.result.length; a++) {
      if (
        e.some(
          (e) => newdate.result[a] === new Date(e.key_as_string).toDateString()
        ) === true
      ) {
        newObject.push(
          e.filter(
            (e) =>
              newdate.result[a] === new Date(e.key_as_string).toDateString()
          )[0]
        );
      } else {
        newObject.push({ doc_count: 0 });
      }
    }
    let finalobj = [...newObject].reverse();
    return finalobj.map((resv) => resv.doc_count);
  };
  return (
    <>
      {dashboardData.data?.subscription?.plan != "premium" &&
      dashboardData.data?.is_analytics_enabled != 1 ? (
        <div className="app-graph-upgrade">
          {(localStorage.getItem("agencyid") == "" ||
            localStorage.getItem("agencyid") == undefined ||
            localStorage.getItem("agencyid") == null ||
            localStorage.getItem("agencyid") == "undefined" ||
            localStorage.getItem("agencyid") == "null" ||
            localStorage.getItem("agencyid") == 1) &&
            dashboardData.appuser_role_id !== 4 &&
            dashboardData.appuser_role_id !== 3 && (
              <Link
                to={
                  localStorage.getItem("workspaceId") === "undefined" ||
                  localStorage.getItem("workspaceId") === undefined ||
                  localStorage.getItem("workspaceId") === "" ||
                  localStorage.getItem("workspaceId") === null
                    ? dashboardData.data?.subscription.reseller_package_id ||
                      dashboardData.data?.subscription.plan_code.includes(
                        "appsumo"
                      )
                      ? "/app/addons" + utmSrc
                      : parseInt(localStorage.getItem("website_technology")) ===
                        4
                      ? "/app/wordpress-pricing/" +
                        btoa(localStorage.getItem("Appid")) +
                        utmSrc
                      : parseInt(localStorage.getItem("website_technology")) ===
                        5
                      ? "/app/woocommerce-pricing/" +
                        btoa(localStorage.getItem("Appid")) +
                        utmSrc
                      : parseInt(localStorage.getItem("website_technology")) ===
                        3
                      ? "/app/custom-app-pricing/" +
                        btoa(localStorage.getItem("Appid")) +
                        utmSrc
                      : parseInt(localStorage.getItem("website_technology")) ===
                        2
                      ? "/app/web-to-app-pricing/" +
                        btoa(localStorage.getItem("Appid")) +
                        utmSrc
                      : "/app/pricing" + utmSrc
                    : `/workspace/pricing/${btoa(
                        localStorage.getItem("workspaceId")
                      )}` + utmSrc
                }
                className="graph-link-upgrade"
              ></Link>
            )}
          {(localStorage.getItem("agencyid") == "" ||
            localStorage.getItem("agencyid") == undefined ||
            localStorage.getItem("agencyid") == null ||
            localStorage.getItem("agencyid") == "undefined" ||
            localStorage.getItem("agencyid") == "null" ||
            localStorage.getItem("agencyid") == 1) &&
            dashboardData.appuser_role_id !== 4 &&
            dashboardData.appuser_role_id !== 3 && (
              <div className="app-dashboard-graph-upgrade">
                <h3>Upgrade</h3>
                <p>to track your app analytics.</p>
              </div>
            )}

          <UpgradeAnalytics />
          <div className="app-graph-upgrade-link">
            <img
              src={
                require("../../../assets/images/dashboard/dashboard-preview-upgrade.png")
                  .default
              }
              className="analytics2k"
              alt="dashboard-preview"
            />
          </div>
        </div>
      ) : (
        <>
          {dashboardData.data?.record_analytics_data === 0 ? (
            <div className="app-graph-upgrade web-to-app-graph">
              <UpgradeAnalytics />
              <img
                src={
                  require("../../../assets/images/dashboard/analytics-disable.png")
                    .default
                }
                className="img-fluid"
                alt="dashboard-dummy"
              />
            </div>
          ) : (
            <div
              className="app-download-info analytics-dawnload-info">
              <UpgradeAnalytics />

              {dashboardData.histogramspinner === true ? (
                <div className="dashBoard-dummydata-loader">
                  <img
                    src={
                      require("../../../assets/images/dashboard/dashboard-preview-upgrade.png")
                        .default
                    }
                    className="analytics2k"
                    alt="dashboard-preview"
                  />

                  <div className="analytics-Loader">
                    <h1>
                      Loading
                      <span class="dot-one"> .</span>
                      <span class="dot-two"> .</span>
                      <span class="dot-three"> .</span>
                    </h1>
                  </div>
                </div>
              ) : dashboardData.reportdata.length == 0 ? (
                <>
                  {localStorage.getItem("workspaceId") === "undefined" ||
                  localStorage.getItem("workspaceId") === undefined ||
                  localStorage.getItem("workspaceId") === "" ||
                  localStorage.getItem("workspaceId") === null ? (
                    <Link to={"/app/analytics"}>
                      <img
                        src={
                          require("../../../assets/images/dashboard/dashboard-dummy2.png")
                            .default
                        }
                        className="img-fluid"
                        alt="dashboard-dummy"
                      />
                    </Link>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                dashboardData.reportdata.map(
                  (res) =>
                    res.item_type === "app_api_requests_histogram" &&
                    (!res.value || !res.value.length ? (
                      <div
                        className={
                          styles.adjustbargraphheight +
                          " new-dashboard-barheight"
                        }
                      >
                        <img
                          src={
                            require("../../../assets/images/dashboard/no-graph-data.png")
                              .default
                          }
                          className="img-fluid dashboard-no-graph-data"
                        />

                        <div className={styles.adjustimg}>
                          <img
                            src={
                              require("../../../assets/images/dashboard/dashboard-no-data.png")
                                .default
                            }
                            className="analytics-graph-laptop"
                            alt=" "
                          />
                        </div>
                      </div>
                    ) : (
                      res.value && (
                        <>
                          {
                            <>
                              {res.value[0].key_as_string !== undefined && (
                                <>
                                  <Histogram
                                    value={{
                                      maintainAspectRatio: true,
                                      responsive: true,
                                      labels: checkdate(res.value),
                                      datasets: [
                                        {
                                          label: "Traffic",
                                          responsive: true,
                                          maintainAspectRatio: true,
                                          data: checkdata(res.value),
                                          backgroundColor: "#A7BEFF",
                                          borderColor: "#A7BEFF",
                                          fill: true,
                                          padding: "20px",
                                        },
                                      ],
                                    }}
                                  />
                                </>
                              )}
                            </>
                          }
                        </>
                      )
                    ))
                )
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default AnalyticsCard;
