import React, { useEffect, useState } from 'react'
import "./ItemTypePopUp.css"


import {
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";
import BottomScrollListener from 'react-bottom-scroll-listener';


export const MultiSelectionPopUpForCustomApp = ({
    openPopUp,
    handlePopUp,
    data,
    handleInputClick,
    selectedArr,
    setSubData,
    subData,
    searchVal,
    setSearchVal,
    breadcrumbArr,
    setBreadcrumbArr,
    from ,
    oldBreadcrumbArr,
    oldSubData,
    checkedArr,
    handleBottomScrollListner,
    paginationSpinner,
}) => {

  const handlePopUpState = (from) => {
    setSearchVal("");
    handlePopUp(from);
  }
   
  const handleSubData = (e,subData,res) => {
    setSubData(subData);
    let newArr = [...breadcrumbArr];
    newArr.push({
     name:res[from==="page"?"title":"name"],
     id:res._id,
    });

    setBreadcrumbArr(newArr);

  }
  const handleHomeBtnClick = (e,subData) => {
    setSubData(subData);
    setBreadcrumbArr([]);
  }



  const handleBreadcrumb = (  
    e,
    val,
    indx
    ) => {
      let newData=[];
    let newBreadcrumb = [];
    let breadcrumb = [...breadcrumbArr];
    for (let i = 0; i < breadcrumb.length; i++) {
      if (val.name === breadcrumb[i].name) {
        newBreadcrumb.push(breadcrumb[i]);
        break;
      } else {
        newBreadcrumb.push(breadcrumb[i]);
      }
    }
    setBreadcrumbArr(newBreadcrumb);

    function recurs(arr) {
       arr.map((eachCat) => {
       
        if(eachCat.parent===val.id){
      
          newData.push(eachCat);
        }
  
        if (eachCat.child !== undefined && eachCat.child.length > 0) {
          // if parent has childrens
          recurs(eachCat.child);
        }
  
        // return arr;
      });
    }
    recurs(data);


    setSubData(newData);

  }

  const handleMobBackBtn =  ()  => {
    
    let breadcrumb = [...breadcrumbArr];
    if(breadcrumb && breadcrumb.length > 0){
      let newData=[];
      breadcrumb.splice(-1,1);
      setBreadcrumbArr(breadcrumb);
    function recurs(arr) {
       arr.map((eachCat) => {
       
        if(eachCat.parent===breadcrumb[breadcrumb.length - 1].id){

          newData.push(eachCat);
        }
  
        if (eachCat.child !== undefined && eachCat.child.length > 0) {
    
          recurs(eachCat.child);
        }

      });
    }

    if(breadcrumb && breadcrumb.length > 0){

      recurs(data);
      setSubData(newData);
    }else{
      setSubData(data);
    }


    }
    else{
      handlePopUpState("cancel");
    }
  
    
  }

  const handleSearchVal = (e) => {
   setSearchVal(e.target.value)
  }

  const filterArr = subData && subData.filter(row=>(row[from==="page"?"title":"name"].toLowerCase().includes(searchVal.toLowerCase())));

 const handleReturnCheckedVal = (id) => {
    let checked =  false;

    selectedArr && selectedArr.map((res)=>{
  
     if(res===id){
      checked = true;
     }
    })
    return checked;
  }
  
  return (
    <div>
   
    <Modal
    className="multi-select-popup"
    isOpen={openPopUp}
    centered
    toggle={() => handlePopUpState("cancel")}
  >
    <ModalHeader>
      <div className='multi-select-header'>
        <h2>Link</h2>

        <div className='mob-multi-select-top'>
          {
            breadcrumbArr && breadcrumbArr.length > 0 &&
          
        <div className='single-select-bredcrums'>
                  <p onClick={(e)=>handleHomeBtnClick(e,data)}>Home &gt;</p>
          {
            breadcrumbArr && breadcrumbArr.map((breadcrumb,indx)=>{
              return(
                indx === 0 ?
                <p className={indx===breadcrumbArr.length-1?'single-select-bredcrumchild':""} 
                onClick={(e) =>
                  indx===breadcrumbArr.length-1?
                  null:
                  handleBreadcrumb(
                    e,
                    breadcrumb,
                    indx
                  )
                }>{breadcrumb.name} </p>
                :<div 
                    style={{
                      display:"flex",
                      justifyContent:"center",
                      alignItems:"center",
                    }}
                >&gt;<p className={indx===breadcrumbArr.length-1?'single-select-bredcrumchild':""}  
                  onClick={(e) =>
                    indx===breadcrumbArr.length-1?
                    null :
                  handleBreadcrumb(
                    e,
                    breadcrumb,
                    indx
                  )
                }>{breadcrumb.name}</p></div>
       
              )
            })
          }
          
          

        </div> 
        }

          <div className='mob-multi-select-search'>
          <svg onClick={(e)=>handleMobBackBtn(e)} xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
          <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
          <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
          <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
          </g>
          </svg>
          <div className='singleselect-serachdiv'>
          <input placeholder='Search' value={searchVal} onChange={(e)=>handleSearchVal(e)}/>
          {(selectedArr && selectedArr.length > 0 && window.matchMedia("(max-width: 1200px)").matches)?
                <p className={(selectedArr && selectedArr.length > 0 )?"mob-popUp-done-enable" : "mob-popUp-done-disable"}
                onClick={() =>
                  (selectedArr && selectedArr.length > 0 )?
                  handlePopUpState("open")
                  :null
                }>Done</p>
                :
            searchVal.trim() && 
          
          <p className='Single-selct-search-clear' onClick={()=>{setSearchVal("")}}>Clear</p> }
          </div>
          <div
          className="multi-select-serach-crossdiv"
          onClick={() => handlePopUpState("cancel")}
        >
          <img
            src={
              require("../../assets/images/dashboard/cross.png").default
            }
          />
        </div>
        </div>
        
        </div>
        
        
        <div
          className="publish-crossmodal single-select-web-cross"
          onClick={() => handlePopUpState("cancel")}
          style={{
            zIndex: "9",
            cursor:"pointer",
          }}
        >
          <img
            src={
              require("../../assets/images/dashboard/cross.png").default
            }
          />
        </div>


      </div>
    </ModalHeader>
    <ModalBody>

      {
        ( !filterArr || filterArr.length === 0 )
        ?
        <div className='select-no-data-found'>
        <img
          src={require("../../assets/images/dashboard/no-media.png").default}
        />
        <p>No results found</p>
      </div>
        :

        <div>
                     <BottomScrollListener
                  onBottom={(e) =>
                    handleBottomScrollListner(
                      e
                    )
                  }
                  offset={100}
                >
                  {(scrollRef) => (
                    <div
                      className="scroll-div single-child-scrolldiv"
                      ref={scrollRef}
                     
                    >
          {
            filterArr && filterArr.length > 0 && filterArr.map((res,indx)=>{
              return(
                <div className='single-select-maindiv'>
                <label className="multi-select-container">{res[from==="page"?"title":"name"]}
                <input type="checkbox" 
                // checked={res._id===selectedId} 
                // checked={checkedArr[indx]}
                checked={handleReturnCheckedVal(res._id)}
                // checked={res.is_checked}
                onChange={(e)=>handleInputClick(e,res._id,indx)} 
                />
                <span className="multi-select-checkmark"></span>
                
                </label>
                {
                  res.child && res.child.length > 0 &&
                   <div className='subdata-svg-container'  onClick={(e)=>handleSubData(e,res.child,res)}>
                  <svg 
                   xmlns="http://www.w3.org/2000/svg" width="15.474" height="12.374" viewBox="0 0 15.474 12.374">
                  <g id="Group_42665" data-name="Group 42665" transform="translate(-1180.001 -431.227)">
                  <path id="Path_119037" data-name="Path 119037" d="M15743-2762h14" transform="translate(-14563 3199.5)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                  <path id="Path_119038" data-name="Path 119038" d="M0,0H8V8" transform="translate(1188.758 431.757) rotate(45)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                  </g>
                  </svg>
                  </div>
                }  
                </div>
              )
            })
          }

                    {paginationSpinner === true ? (
                        <div
                          className={`form-group allFilter`}
                          style={{ background: "none", margin: "auto" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            style={{
                              margin: "auto",
                              background: "rgb(241, 242, 243,0)",
                              display: "block",
                              shaperendering: "auto",
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                            width="200px"
                            height="100px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                          >
                            <g
                              transform="translate(20 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#e6261f"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.375s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(40 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#f7d038"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.25s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(60 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#49da9a"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.125s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(80 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#4355db"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="0s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                          </svg>
                        </div>
                      )
                    :null}

          </div>
        )}
          </BottomScrollListener>

        </div>
      }
 

    </ModalBody>
    <ModalFooter>
     
        <button className='Single-select-cancel-btn ' onClick={()=>handlePopUpState("cancel")}>Cancel</button>
        <div className='d-flex' style={{margin: "0px", alignItems: "center"}}>
          <p className='single-select-selected'>{ ( selectedArr.length===0 || !selectedArr ) ?"":`${selectedArr.length} item selected`}</p>
          <button className={` ${(selectedArr && selectedArr.length > 0 )?"Single-select-done-btn":"Single-select-done-disable"}`} 
          onClick={()=>(selectedArr && selectedArr.length > 0 )?handlePopUpState("open"):null}
          >Done</button></div>
        
    </ModalFooter>
  </Modal>
  </div>
  )
}

const countSelectedNumForAtr = (arr) => {
  return new Set(arr).size
}

export const MultiSelectionPopUpForAttribute = ({
  openPopUp,
  handlePopUp,
  data,
  handleInputClick,
  selectedArr,
  setSubData,
  subData,
  searchVal,
  setSearchVal,
  breadcrumbArr,
  setBreadcrumbArr,
  from ,
  oldBreadcrumbArr,
  oldSubData,
  checkedArr,
  handleBottomScrollListner,
  paginationSpinner,
}) => {


  const handlePopUpState = (from) => {
    setSearchVal("");
    handlePopUp(from);
  }
 
const handleSubData = (e,subData,res) => {
  setSubData(subData);
  let newArr = [...breadcrumbArr];
  newArr.push({
   name:res.attribute_name,
   id:res._id,
  });

  setBreadcrumbArr(newArr);

}
const handleHomeBtnClick = (e,subData) => {
  setSubData(subData);
  setBreadcrumbArr([]);
}



const handleBreadcrumb = (  
  e,
  val,
  indx
  ) => {
    let newData=[];
  let newBreadcrumb = [];
  let breadcrumb = [...breadcrumbArr];
  for (let i = 0; i < breadcrumb.length; i++) {
    if (val.name === breadcrumb[i].name) {
      newBreadcrumb.push(breadcrumb[i]);
      break;
    } else {
      newBreadcrumb.push(breadcrumb[i]);
    }
  }
  setBreadcrumbArr(newBreadcrumb);

  function recurs(arr) {
     arr.map((eachCat) => {
     
      if(eachCat.attribute===val.id){
    
        newData.push(eachCat);
      }

      if (eachCat.attribute_terms !== undefined && eachCat.attribute_terms.length > 0) {
        // if parent has childrens
        recurs(eachCat.attribute_terms);
      }

      // return arr;
    });
  }
  recurs(data);


  setSubData(newData);

}

const handleMobBackBtn =  ()  => {
  
  let breadcrumb = [...breadcrumbArr];
  if(breadcrumb && breadcrumb.length > 0){
    let newData=[];
    breadcrumb.splice(-1,1);
    setBreadcrumbArr(breadcrumb);
  function recurs(arr) {
     arr.map((eachCat) => {
     
      if(eachCat.attribute===breadcrumb[breadcrumb.length - 1].id){

        newData.push(eachCat);
      }

      if (eachCat.attribute_terms !== undefined && eachCat.attribute_terms.length > 0) {
  
        recurs(eachCat.attribute_terms);
      }

    });
  }

  if(breadcrumb && breadcrumb.length > 0){

    recurs(data);
    setSubData(newData);
  }else{
    setSubData(data);
  }


  }
  else{
    handlePopUpState("cancel");
  }

  
}

const handleSearchVal = (e) => {
 setSearchVal(e.target.value)
}

const filterArr = subData && subData.filter(row=>((row.attribute_name?row.attribute_name:row.attribute_term_name)?.toLowerCase()?.includes(searchVal?.toLowerCase())));


const handleReturnCheckedVal = (id) => {
  let checked =  false;

  selectedArr && selectedArr.map((res)=>{

   if(res===id){
    checked = true;
   }
  })
  return checked;
}

return (
  <div>

  <Modal
  className="multi-select-popup"
  isOpen={openPopUp}
  centered
  toggle={() => handlePopUpState("cancel")}
>
  <ModalHeader> 
    <div className='multi-select-header'>
      <h2>Link</h2>

      <div className='mob-multi-select-top'>
        {
          breadcrumbArr && breadcrumbArr.length > 0 &&
        
      <div className='single-select-bredcrums '>
                <p onClick={(e)=>handleHomeBtnClick(e,data)}>Home &gt;</p>
        {
          breadcrumbArr && breadcrumbArr.map((breadcrumb,indx)=>{
            return(
              indx === 0 ?
              <p className={indx===breadcrumbArr.length-1?'single-select-bredcrumchild':""} 
              onClick={(e) =>
                indx===breadcrumbArr.length-1?
                null:
                handleBreadcrumb(
                  e,
                  breadcrumb,
                  indx
                )
              }>{breadcrumb.name} </p>
              :<div 
                  style={{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                  }}
              >&gt;<p className={indx===breadcrumbArr.length-1?'single-select-bredcrumchild':""}  
                onClick={(e) =>
                  indx===breadcrumbArr.length-1?
                  null :
                handleBreadcrumb(
                  e,
                  breadcrumb,
                  indx
                )
              }>{breadcrumb.name}</p></div>
     
            )
          })
        }
        
        

      </div> 
      }

        <div className='mob-multi-select-search'>
        <svg onClick={(e)=>handleMobBackBtn(e)} xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
        <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
        <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
        <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
        </g>
        </svg>
        <div className='singleselect-serachdiv'>
        <input placeholder='Search' value={searchVal} onChange={(e)=>handleSearchVal(e)}/>
        {(selectedArr && countSelectedNumForAtr(selectedArr) > 0 && window.matchMedia("(max-width: 1200px)").matches)?
                <p className={(selectedArr && countSelectedNumForAtr(selectedArr) > 0 )?"mob-popUp-done-enable" : "mob-popUp-done-disable"}
                onClick={() =>
                  (selectedArr && countSelectedNumForAtr(selectedArr) > 0 )?
                  handlePopUpState("open")
                  :null
                }>Done</p>
                :
        
          searchVal.trim() && 
        
        <p className='Single-selct-search-clear' onClick={()=>{setSearchVal("")}}>Clear</p> }
        </div>
        <div
        className="multi-select-serach-crossdiv"
        onClick={() => handlePopUpState("cancel")}
      >
        <img
          src={
            require("../../assets/images/dashboard/cross.png").default
          }
        />
      </div>
      </div>
      
      </div>
      
      
      <div
        className="publish-crossmodal single-select-web-cross"
        onClick={() => handlePopUpState("cancel")}
        style={{
          zIndex: "9",
          cursor:"pointer",
        }}
      >
        <img
          src={
            require("../../assets/images/dashboard/cross.png").default
          }
        />
      </div>


    </div>
  </ModalHeader>
  <ModalBody>
      
       {
        ( !filterArr || filterArr.length === 0 )
        ?
        <div className='select-no-data-found'>
        <img
          src={require("../../assets/images/dashboard/no-media.png").default}
        />
        <p>No results found</p>
      </div>
        :
      <div>
                   <BottomScrollListener
                  onBottom={(e) =>
                    handleBottomScrollListner(
                      e
                    )
                  }
                  offset={100}
                >
                  {(scrollRef) => (
                    <div
                      className="scroll-div single-child-scrolldiv"
                      ref={scrollRef}
                     
                    >
        {
          filterArr && filterArr.length > 0 && filterArr.map((res,indx)=>{
            return(
              <div className='single-select-maindiv'>
              <label className="multi-select-container">{(res.attribute_name?res.attribute_name:res.attribute_term_name)}
              <input type="checkbox" 
           
              checked={handleReturnCheckedVal(res._id)}
              // checked={res.is_checked}
              onChange={(e)=>handleInputClick(e,res,indx)} 
              />
              <span className="multi-select-checkmark"></span>
              
              </label>
              {
                res.attribute_terms && res.attribute_terms.length > 0 &&
                 <div className='subdata-svg-container'  onClick={(e)=>handleSubData(e,res.attribute_terms,res)}>
                <svg 
                 xmlns="http://www.w3.org/2000/svg" width="15.474" height="12.374" viewBox="0 0 15.474 12.374">
                <g id="Group_42665" data-name="Group 42665" transform="translate(-1180.001 -431.227)">
                <path id="Path_119037" data-name="Path 119037" d="M15743-2762h14" transform="translate(-14563 3199.5)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                <path id="Path_119038" data-name="Path 119038" d="M0,0H8V8" transform="translate(1188.758 431.757) rotate(45)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                </g>
                </svg>
                </div>
              }  
              </div>
      
            )
          })

        }

                      {paginationSpinner === true ? (
                        <div
                          className={`form-group allFilter`}
                          style={{ background: "none", margin: "auto" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            style={{
                              margin: "auto",
                              background: "rgb(241, 242, 243,0)",
                              display: "block",
                              shaperendering: "auto",
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                            width="200px"
                            height="100px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                          >
                            <g
                              transform="translate(20 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#e6261f"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.375s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(40 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#f7d038"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.25s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(60 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#49da9a"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.125s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(80 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#4355db"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="0s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                          </svg>
                        </div>
                      )
                    :null}
          </div>
        )}
          </BottomScrollListener>
          
        

      </div>
      }

  </ModalBody>
  <ModalFooter>
   
      <button className='Single-select-cancel-btn ' onClick={()=>handlePopUpState("cancel")}>Cancel</button>
      <div className='d-flex' style={{margin: "0px", alignItems: "center"}}>
        <p className='single-select-selected'>{countSelectedNumForAtr(selectedArr) === 0 ||!selectedArr?"":`${countSelectedNumForAtr(selectedArr)} item selected`}</p>
        <button className={` ${(selectedArr && countSelectedNumForAtr(selectedArr) > 0 )?"Single-select-done-btn":"Single-select-done-disable"}`} 
       onClick={()=>(selectedArr && countSelectedNumForAtr(selectedArr) > 0 )?handlePopUpState("open"):null}
        >Done</button></div>
      
  </ModalFooter>
</Modal>
</div>
)
}
