import React from "react";
import "../assets/css/newSignup.css";
import "../assets/css/Media.css";
import { Carousel } from 'react-responsive-carousel';
import {
  SignupApi,
  Googlesignup,
  Facebooksignup,
  Countrycode,
} from "../Api/Authenticate/Api";
import { Versionapi } from "../Api/Dashboard/DashboardApi";
import { Link, Redirect } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
// import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { errortoast } from "../Dashboard/Toaster";
import { Media, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import Header from "../Dashboard/Reusable/HeaderOutsidePortal";
import emailValidator from "email-validator"; 
import {getAgencyData}  from "../Api/Agency/AgencyApi";

export default class Signup extends React.Component {

    constructor(props)
    {
        super(props);
        this.helpToggle = this.helpToggle.bind(this);
        this.state = {
            helpDropdownOpen: false,
            modalopen: false,
            progress: 0,
            count: 0,
            isSignedIn: false,
            name: "",
            email: "",
            usertype: "",
            password: "",
            passattribute: true,
            passattibutetype: "password",
            eyeimage: require("../assets/images/signup/secure.png").default,
            submitval: "Create account",
            loader: "d-none",
            loaderimage: require("../assets/images/signup/Loader_GIF.gif").default,
            nameerror: false,
            emailerror: false,
            ownererror: null,
            passworderror: false,
            captchavalue: "",
            login_ip: "",
            user_country: "",
            registerBtn: false,
            nameerrormesg: null,
            emailerrormesg: null,
            passworderrormesg: null,
            sliderStatus: true,
            signinlink: true,
            agency_favicon:"",
            agency_id:"",
            agency_logo:"",
            agency_name:"",
            agency_primary_color:"",
            agency_secondary_color:"",
            is_fb_login_enabled:0,
            is_google_login_enabled:0,
            captcha_key:"",
            captcha_secret:"",
            is_google_captcha_enabled:0,
            google_app_id:"",
            facebookid:"",
            deviceuuid: null,
            googleSigninLoader: false,
            googleSigninAgencyLoader: false
        };

    }

    componentDidMount() 
    {
        Versionapi();


      if(localStorage.getItem("agency_id") && localStorage.getItem("agency_id") == 1)
      {
        document.title = "Sign up | AppMySite";
      }
      else if(localStorage.getItem('agencyname') != undefined && localStorage.getItem('agencyname') != null && localStorage.getItem('agencyname') != 'undefined' && localStorage.getItem('agencyname') != 'null' && localStorage.getItem('agencyname') != '')
      {
        document.title = "Sign up |"+ ' ' +localStorage.getItem('agencyname');        
      }
      else if(this.state.agency_name != undefined && this.state.agency_name != null && this.state.agency_name != 'undefined' && this.state.agency_name != 'null' && this.state.agency_name != '')
      {
        document.title = "Sign up |"+' '+ this.state.agency_name;
      }
      else
      {
        document.title = "Sign up";
      }
        

        window.scrollTo(0, 0);
        if (localStorage.getItem("buildversion") === null) 
        {
            localStorage.setItem("buildversion", process.env.REACT_APP_BUILD);
        }

        if (localStorage.getItem("buildversion") !== null) 
        {
            if ( localStorage.getItem("buildversion") !== process.env.REACT_APP_BUILD ) 
            {
                window.location.reload(true);
                localStorage.setItem("buildversion", process.env.REACT_APP_BUILD);
            }
        }
        const params = {
            "domain_name": window.location.host
        }

        getAgencyData(params, this);

        
        let searchstr = (this.props).history.location.search;
		let urlParams = (new URLSearchParams(searchstr));
		
		
        //let searchquery = searchstr.replace("?","",  searchstr);
        //let queryparam = searchquery.split("&");
        //let queryparam1 = queryparam[0].split("=");
		let deviceid = urlParams.get('deviceid');

        //
        

        if(deviceid !== undefined && deviceid !== null && deviceid !== "")
        {
            localStorage.setItem('devicdeuuid',deviceid);
						
            this.setState({ deviceuuid: deviceid });
        }
    }

    helpToggle() 
    {
        this.setState((prevState) => ({
            helpDropdownOpen: !prevState.helpDropdownOpen,
        }));
    }
    

     handleSelect = (selectedIndex, e) => {
      this.setState({index:selectedIndex});
    };
    onChange = (value) => {
        this.setState({
            captchavalue: value,
        });
    };

    changepass = () => {
        this.setState({
            passattribute: !this.state.passattribute,
        });

        if (this.state.passattribute) 
        {
            this.setState({
                passattibutetype: "text",
                eyeimage: require("../assets/images/signup/securityeyeopen.png").default,
            });
        } 
        else 
        {
            this.setState({
                passattibutetype: "password",
                eyeimage: require("../assets/images/signup/securityeyeclose.png").default,
            });
        }
    };

    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
        if(e.target.name === "name" && e.target.value?.trim()){
          this.setState({
            nameerror: false,
          })
        }if(e.target.name === "email"&& e.target.value?.trim()){
            this.setState({
             emailerror: false,
           })
        }
    };
    
    registerForm = (e) => 
    {
        e.preventDefault();
        if(this.state.loader === 'd-none')
        {

            if(!this.state.name?.trim())
            {
                this.setState({
                    nameerror: true,
                    nameerrormesg: "Required"
                });
            }
            else
            {
                this.setState({
                    nameerror: false,
                    nameerrormesg: null
                });
            }

            if(!this.state.email?.trim())
            {
                this.setState({
                    emailerror: true,
                    emailerrormesg: "Required"
                });
            }
            else if(!emailValidator.validate(this.state.email?.trim()))
            {
                this.setState({
                    emailerror: true,
                    emailerrormesg: "Please enter a valid Email"
                });
            }
            else
            {
                this.setState({
                    emailerror: false,
                    emailerrormesg: null
                });
            }


            if(!this.state.password?.trim())
            {
                this.setState({
                    passworderror: true,
                    passworderrormesg: "Required"
                });
            }
            else
            {
                this.setState({
                    passworderror: false,
                    passworderrormesg: null
                });
            }

            if (((parseInt(localStorage.getItem('agency_id')) == 1) || this.state.is_google_captcha_enabled == 1) && this.state.captchavalue === "") 
            {
                errortoast("Captcha is required");
                return;

            }

            
            if(this.state.name?.trim() && this.state.email?.trim()  && this.state.password?.trim() && emailValidator.validate(this.state.email?.trim()) )
                {
                        Countrycode(this, () => {
                        const params = {
                            name: this.state.name.trim(),
                            email: this.state.email.replace(/\s/g, "").toLowerCase(),
                            owner: this.state.usertype,
                            password: btoa(this.state.password),
                            user_ip: this.state.login_ip && this.state.login_ip != '' ? this.state.login_ip : '',
                            user_country: this.state.user_country,
                            deviceuuid: this.state.deviceuuid,
                            agency_id:localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1
                        };

                        SignupApi(params, this);
                    });
                    }
        }

    };

    responseGoogle = (response) => {
        if (this.state.isSignedIn) 
        {
            if (response.profileObj !== undefined) 
            {
                Countrycode(this, () => {
                    const params = {
                        email: response.profileObj.email,
                        imageUrl: response.profileObj.imageUrl,
                        name: response.profileObj.name,
                        googleId: response.profileObj.googleId,
                        access_token: response.tokenObj.access_token,
                        id_token: response.tokenObj.id_token,
                        user_ip: this.state.login_ip,
                        user_country: this.state.user_country,
                        deviceuuid: this.state.deviceuuid,
                    };

                    Googlesignup(params, this);
                });
            }
        }
    };
    
    handlechangeNewpass = (e) => {
    
        
    
        const passwordValue = e.target.value;
        const passwordLength = passwordValue.length;
        if(e.target.value?.trim()){
            this.setState({
                passworderror:false,
                passworderrormesg:"",
            })
        }

        const weakRegExp = /[a-z]/;
        const almostRegExp = /[0-9]/;
        const strongRegExp = /[A-Z]/;
    
        var PoorPassword = false;
        var WeakPassword = false;
        var AlmostPassword = false;
        var StrongPassword = false;
    
        if (passwordLength >= 1) 
        {
            PoorPassword = true;
        }
    
        if (passwordLength < 8) 
        {
            PoorPassword = false;
        }
    
        if (weakRegExp.test(passwordValue)) 
        {
            WeakPassword = true;
        }
        else 
        {
            WeakPassword = false;
        }
    
        if (almostRegExp.test(passwordValue)) 
        {
            AlmostPassword = true;
        }
        else 
        {
            AlmostPassword = false;
        }
    
        if (strongRegExp.test(passwordValue)) 
        {
            StrongPassword = true;
        }
        else 
        {
            StrongPassword = false;
        }
    
        const arr1 = [PoorPassword, WeakPassword, AlmostPassword, StrongPassword];
        const count1 = arr1.filter(value => value === true).length;
        
        this.setState({ progress: count1 });
        
        this.setState({
            [e.target.name]: e.target.value,
        });
    };
    
    //Facebook Login
    responseFacebook = (response) => {
        if (response.picture !== undefined) 
        {
            Countrycode(this, () => {
                const params = {
                    email: response.email,
                    imageUrl: response.picture.data.url,
                    name: response.name,
                    id: response.id,
                    access_token: response.accessToken,
                    user_ip: this.state.login_ip,
                    user_country: this.state.user_country,
                    deviceuuid: this.state.deviceuuid,
                };
    
                Facebooksignup(params, this);
            });
        }
    };

    render()
    {
        let searchstr1 = (this.props).history.location.search;
        let urlParams1 = (new URLSearchParams(searchstr1));
        let deviceid1 = urlParams1.get('deviceid');
        if(deviceid1 !== undefined && deviceid1 !== null && deviceid1 !== "")
        {
            localStorage.clear();
            localStorage.setItem('devicdeuuid',deviceid1);
        }
		

        const token = localStorage.getItem("amstoken");
        if (!token || token === "null" || token === "undefined") 
        {
        } 
        else 
        {
            if(localStorage.getItem('agencyid') && localStorage.getItem('agencyid') != 1)
            {
                return <Redirect to="/apps" />;
            }
            else
            {
                return <Redirect to="/home" />;
            }
        }

        return(
            
        <>
                    {this.state.spinner ? (
                <div className="newspinner">
                  <svg xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                    width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                          style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        </animateTransform>
                      </circle>
                    </g>
                    <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                          style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        </animateTransform>
                      </circle>
                    </g>
                    <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                          style={{ animationplaystate: "running", animationdelay: "0s" }}>
  
                        </animateTransform>
                      </circle>
                    </g>
                    <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                          style={{ animationplaystate: "running", animationdelay: "0s" }}>
  
                        </animateTransform>
                      </circle>
                    </g>
                  </svg>
                </div>
              )
                :
                (
                <>
                    {localStorage.getItem("agency_id") && localStorage.getItem("agency_id") != 1 ?
                    <>
                    <Header  SignInShow={this.state.signinlink}/>
            
                    <section className=" signuppage-new">
                        <div className="">
                            <div className="new-signupmain-new">
                                <div className=" new-signup-left">
                                    <div className=" new-signup-leftdiv">                            
                                        {/*<Registerform history={this.props.history} />*/}
            
                                        <div className=" align-items-center ">
                                            <div className=" new-signupcontent">
                                                <h3 className="text-left mt-0">Create an account</h3>
                                            </div>
                                            {this.state.is_google_login_enabled == 1 || this.state.is_fb_login_enabled == 1 ?
                                            <div className=" d-grid ">
                                                <div className="socialoptionstart">Sign up with</div>
                                                <div className="new-socialoptions-inner agency-subdomain-login">
                                                {this.state.is_google_login_enabled == 1 ?
                                                    <a href={(this.state.deviceuuid === null || this.state.deviceuuid === '') ? process.env.REACT_APP_API_URL + "auth/google/" + localStorage.getItem("agency_id") : process.env.REACT_APP_API_URL + "auth/google/" + localStorage.getItem("agency_id") +"/" + this.state.deviceuuid}>
                                                         
                                                    <div onClick={() => this.setState({googleSigninAgencyLoader: true})} className="new-signup-google" >
                                                        {" "}
                                                        {this.state.googleSigninAgencyLoader ?<div className="login-loader"></div> : <div className="signup-web-google">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="81.338" height="20.46" viewBox="0 0 81.338 20.46">
                                                                <g id="Group_37899" data-name="Group 37899" transform="translate(-404.662 -416.54)">
                                                                    <text id="Google" transform="translate(435 432)" fill="#1b2952" font-size="14" font-family="Axiforma-Regular, Axiforma"><tspan x="0" y="0">Google</tspan></text>
                                                                    <g id="logo_googleg_48dp" transform="translate(404.662 416.54)">
                                                                        <path id="Shape" d="M18.821,9.456a11.761,11.761,0,0,0-.186-2.093H9v3.957h5.506a4.706,4.706,0,0,1-2.041,3.088v2.567h3.306a9.977,9.977,0,0,0,3.05-7.519Z" transform="translate(1.23 1.007)" fill="#4285f4" fill-rule="evenodd" />
                                                                        <path id="Shape-2" data-name="Shape" d="M10.1,19a9.767,9.767,0,0,0,6.771-2.479l-3.306-2.567A6.172,6.172,0,0,1,4.375,10.71H.957v2.651A10.226,10.226,0,0,0,10.1,19Z" transform="translate(0.131 1.464)" fill="#34a853" fill-rule="evenodd" />
                                                                        <path id="Shape-3" data-name="Shape" d="M4.506,11.5a6.048,6.048,0,0,1,0-3.887V4.958H1.088a10.243,10.243,0,0,0,0,9.189L4.506,11.5Z" transform="translate(0 0.678)" fill="#fbbc05" fill-rule="evenodd" />
                                                                        <path id="Shape-4" data-name="Shape" d="M10.1,4.069A5.527,5.527,0,0,1,14.01,5.6l2.934-2.934A9.832,9.832,0,0,0,10.1,0,10.226,10.226,0,0,0,.957,5.636L4.375,8.286A6.1,6.1,0,0,1,10.1,4.069Z" transform="translate(0.131)" fill="#ea4335" fill-rule="evenodd" />
                                                                        <path id="Shape-5" data-name="Shape" d="M0,0H20.46V20.46H0Z" fill="none" fill-rule="evenodd" />
                                                                    </g>
                                                                </g>
                                                            </svg>
                                                        </div>}               
                                                    </div>
                                                </a>
                                                : null
                                                    }
                                                    {this.state.is_fb_login_enabled == 1 ?
                                                    // <div className="new-facebooksignup">
                                                    //     <FacebookLogin appId={this.state.facebookid != '' && this.state.facebookid != null && this.state.facebookid != undefined ? this.state.facebookid : ""} autoLoad={false} fields="name,email,picture" callback={this.responseFacebook} render={(renderProps) => (
                                                    //         <div className="d-flex facebookicontext" onClick={renderProps.onClick}>
                                                    //             {" "}
                                                    //             {/*<svg xmlns="http://www.w3.org/2000/svg" width="10.487" height="22.748" viewBox="0 0 10.487 22.748">
                                                    //                 <path id="Path_102206" data-name="Path 102206" d="M47.877,33.115H44.766v11.4H40.054v-11.4H37.812V29.11h2.242V26.519c0-1.853.88-4.756,4.755-4.756l3.491.015v3.888H45.766a.959.959,0,0,0-1,1.092v2.357h3.522Z" transform="translate(-37.812 -21.763)" fill="#1877f2" />
                                                    //             </svg>
                                                    //             Facebook*/}
                                                    //             <svg xmlns="http://www.w3.org/2000/svg" width="90.747" height="22.748" viewBox="0 0 90.747 22.748">
                                                    //                 <g id="Group_37936" data-name="Group 37936" transform="translate(-509.591 -280.283)">
                                                    //                     <path id="Path_102206" data-name="Path 102206" d="M47.877,33.115H44.766v11.4H40.054v-11.4H37.812V29.11h2.242V26.519c0-1.853.88-4.756,4.755-4.756l3.491.015v3.888H45.766a.959.959,0,0,0-1,1.092v2.357h3.522Z" transform="translate(471.779 258.52)" fill="#1877f2"/>
                                                    //                     <text id="Facebook" transform="translate(530.338 297.46)" fill="#1b2952" font-size="14" font-family="Axiforma-Regular, Axiforma"><tspan x="0" y="0">Facebook</tspan></text>
                                                    //                 </g>
                                                    //             </svg>
                                                    //         </div>
                                                    //     )}  cssClass="fbtn" disableMobileRedirect={true} />
                                                    // </div>
                                                    <a href={(this.state.deviceuuid === null || this.state.deviceuuid === '') ? process.env.REACT_APP_API_URL + "auth/facebook/" + localStorage.getItem("agency_id") : process.env.REACT_APP_API_URL + "auth/facebook/" + localStorage.getItem("agency_id") +"/" + this.state.deviceuuid}>
                                                    <div className="new-facebooksignup">
                                                        <div className="d-flex facebookicontext">
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="90.747" height="22.748" viewBox="0 0 90.747 22.748">
                                                                <g id="Group_37936" data-name="Group 37936" transform="translate(-509.591 -280.283)">
                                                                    <path id="Path_102206" data-name="Path 102206" d="M47.877,33.115H44.766v11.4H40.054v-11.4H37.812V29.11h2.242V26.519c0-1.853.88-4.756,4.755-4.756l3.491.015v3.888H45.766a.959.959,0,0,0-1,1.092v2.357h3.522Z" transform="translate(471.779 258.52)" fill="#1877f2"/>
                                                                    <text id="Facebook" transform="translate(530.338 297.46)" fill="#1b2952" font-size="14" font-family="Axiforma-Regular, Axiforma"><tspan x="0" y="0">Facebook</tspan></text>
                                                                </g>
                                                            </svg>
                                                        </div>
                                                    </div>
                                                    </a>
                                                    : null
                                                    }
                                                </div>
                                                <div className="signup-ordiv d-flex">
                                                    <hr/>
                                                    <p className="signup-or">OR</p>
                                                    <hr/>
                                                </div>
                                            </div>
                                            : null
                                            }
                                        </div>
            
                                        <form onSubmit={this.registerForm}>
                                            <div className="form-group new-signup-form">
                                                <label>Full name*</label>
                                                <input type="text" className={`form-control ${this.state.nameerror ? "newsignup-errorinput" : "" }`} name="name" placeholder="" value={this.state.name} onChange={(e) => this.handleChange(e)} />
                                                <div className="d-flex align-items-center slider-new-error ">
                                                    <div>
                                                        {this.state.nameerror &&
                                                            <div className="d-flex align-items-center new-error">
                                                                <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                                                    <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"/>
                                                                    <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"/>
                                                                    <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"/>
                                                                </svg>
            
                                                                <p>{this.state.nameerrormesg}</p>
                                                            </div>                                            
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group new-signup-form">
                                                <label>Email*</label>
                                                <input type="email" className={`form-control ${this.state.emailerror ? "newsignup-errorinput" : "" }`} name="email" value={this.state.email} onChange={(e) => this.handleChange(e)} placeholder="" />
                                                <div className="d-flex align-items-center slider-new-error  ">
                                                    <div>
                                                        {this.state.emailerror &&                                             
                                                            <div className="d-flex align-items-center  new-error">
                                                                <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                                                    <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"/>
                                                                    <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"/>
                                                                    <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"/>
                                                                </svg>
            
                                                                <p>{this.state.emailerrormesg}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                    
                                            <div className="form-group new-signup-form">
                                                <label>Password*</label>
                                                <input type={this.state.passattibutetype} className={`form-control eye-close ${this.state.passworderror ? "newsignup-errorinput" : "" }`} name="password" value={this.state.password} onChange={(e) => this.handlechangeNewpass(e)} placeholder="" />
                                                <div className="new-signup-eye" onClick={this.changepass}>
                                                    <img src={this.state.eyeimage} className="img-fluid" alt="secure" />
                                                </div>
                                                {this.state.passworderror && 
                                                    <div className="d-flex  password-error new-error">
                                                        <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                                            <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"/>
                                                            <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"/>
                                                            <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"/>
                                                        </svg>
                                                        <p>{this.state.passworderrormesg}</p>
                                                    </div>
                                                }
                                            </div>
            
                                            { (this.state.password !== '' && this.state.password !== null && this.state.password !== undefined) &&
                                            <div className="new-signup-progressbar">
                                                <div>
                                                    
                                                
                                                        {this.state.progress == 0 ?
                                                            <>
                                                                <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                            </>
                                                        : 
                                                            this.state.progress == 1 ?
                                                                <>
                                                                    <progress id="file" max="100" value="100" className="redprogress">{" "} </progress>
                                                                    <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                    <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                    <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                </>
                                                            : 
                                                                this.state.progress == 2 ?
                                                                    <>
                                                                        <progress id="file" max="100" value="100" className="orangeprogress">{" "} </progress>
                                                                        <progress id="file" max="100" value="100" className="orangeprogress">{" "} </progress>
                                                                        <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                        <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                    </>
                                                                : 
                                                                    this.state.progress == 3 ?
                                                                        <>
                                                                            <progress id="file" max="100" value="100" className="lightgreenprogress">{" "} </progress>
                                                                            <progress id="file" max="100" value="100" className="lightgreenprogress">{" "} </progress>
                                                                            <progress id="file" max="100" value="100" className="lightgreenprogress">{" "} </progress>
                                                                            <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                        </>
                                                                    : 
                                                                        this.state.progress == 4 ?
                                                                            <>
                                                                                <progress id="file" max="100" value="100" className="greenprogress">{" "} </progress>
                                                                                <progress id="file" max="100" value="100" className="greenprogress">{" "} </progress>
                                                                                <progress id="file" max="100" value="100" className="greenprogress">{" "} </progress>
                                                                                <progress id="file" max="100" value="100" className="greenprogress">{" "} </progress>
                                                                            </>
                                                                        : 
                                                                            <>
                                                                                <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                                <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                                <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                                <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                            </>
                                                        }
                                                
                                                
                                                </div>
                                                <p>Your password must contain a minimum of 8 characters, 1 uppercase letter (A-Z), 1 lower case letter (a-z) and 1 number (1-9) </p>
                                            </div>
                                            }
                                             {this.state.is_google_captcha_enabled == 1 ?
                                            <div className="customcheck customcheck-red signup-check">
                                                <ReCAPTCHA sitekey={this.state.captcha_key} onChange={this.onChange} />
                                            </div>
                                            : null
                                            }
                                            <div>
                                                {/*<button type="submit" className={`sign-uploginbtn ${this.state.captchavalue === "" ? "darkshade" : "" }`} disabled={this.state.captchavalue === "" ? true : false} >*/}
                                                <button type="submit" className={`sign-uploginbtn`} style={{
                                                "--custom_color5": localStorage.getItem('agencyid') == '1' || localStorage.getItem('agencyid') == 1 || localStorage.getItem('agencyid') == undefined || localStorage.getItem('agencyid') == 'undefined' || localStorage.getItem('agencyid') == null || localStorage.getItem('agencyid') == 'null' ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                                "--custom_color4": localStorage.getItem('agencyid') == '1' || localStorage.getItem('agencyid') == 1 || localStorage.getItem('agencyid') == undefined || localStorage.getItem('agencyid') == 'undefined' || localStorage.getItem('agencyid') == null || localStorage.getItem('agencyid') == 'null'? "white" : localStorage.getItem('button_hover_txtcolor'),
                                                "--custom_color2": localStorage.getItem('agencyid') == '1' || localStorage.getItem('agencyid') == 1 || localStorage.getItem('agencyid') == undefined || localStorage.getItem('agencyid') == 'undefined' || localStorage.getItem('agencyid') == null || localStorage.getItem('agencyid') == 'null' ? "#3064f9" : localStorage.getItem('button_background_color'),
                                                "--custom_color1": localStorage.getItem('agencyid') == '1' || localStorage.getItem('agencyid') == 1 || localStorage.getItem('agencyid') == undefined || localStorage.getItem('agencyid') == 'undefined' || localStorage.getItem('agencyid') == null || localStorage.getItem('agencyid') == 'null' ? "white" : localStorage.getItem('button_text_color'),
                                                }}>
                                                    <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                                        <div className={this.state.loader}>
                                                            {/*<img src={this.state.loaderimage} alt="loader" />*/}
                                                            <div className="new-loader"></div>
                                                        </div>{" "}
                                                        <div>{this.state.submitval}</div>
                                                    </div>
                                                </button>
                                            </div>
                        
                                            <div className="newsignup-tc">
                                                <h6 className="">
                                                    By creating an account, you agree to our
                                                    {localStorage.getItem("agency_id") && localStorage.getItem("agency_id") == 1 ?
                                                    <>
                                                        <a href="https://www.appmysite.com/terms-conditions/" target="_blank" rel="noopener noreferrer" >
                                                            {" "}
                                                            Terms &amp; Conditions
                                                        </a>{" "}
                                                        &amp;
                                                        <a href="https://www.appmysite.com/privacy-policy/" target="_blank" rel="noopener noreferrer" >
                                                            {" "}
                                                            Privacy Policy.{" "}
                                                        </a>
                                                    </>
                                                    : <> Terms &amp; Conditions &amp; Privacy Policy.{" "} </>
                                                    }
                                                    You also agree to receiving occasional emails about our product and services on your registered email address.
                                                </h6>
                                            </div>
            
                                            {/* <div className="d-block d-md-none text-center signinoption">
                                            <p>Have an account? <a href="/sign-in">Sign In</a>
                                            </p>
                                            </div> */}
            
                                        </form>
                                    </div>                    
                                </div>
                                <div className=" new-signup-right">                
                                    <div className="">                  
                                        <div className="d-flex justify-content-center">
                                            <img src={ require("../assets/images/signup/signupimg.png").default } alt="signup img" />
                                        </div>
            
                                        <div className="signup-crousel">
                                                {/* <div className=" active">
                                                <h2>No-code app builder</h2>
                                                <h3>Create powerful apps without coding</h3>
                                                </div>
                                                <div className="">
                                                <h2>Personalize your app</h2>
                                                <h3>Customize apps to align with branding</h3>
                                                </div>
                                                <div className="">
                                                <h2>App store compliant</h2>
                                                <h3>Publish apps to Google Play & App Store</h3>
                                                </div>
                                                <div className="">
                                                <h2>Engage with audiences</h2>
                                                <h3>Keep your app users engaged and loyal</h3>
                                                </div>                    */}
                                            <Carousel autoPlay={this.state.sliderStatus} infiniteLoop={this.state.sliderStatus} classNamePrefix="signup-carousel">
                        
                                            <div >
                                                <h2>No-code app builder</h2>
                                                <h3>Create powerful apps without coding</h3>
                                                </div>
                                                <div className="">
                                                <h2>Personalize your app</h2>
                                                <h3>Customize apps to align with branding</h3>
                                                </div>
                                                <div className="">
                                                <h2>App store compliant</h2>
                                                <h3>Publish apps to Google Play & App Store</h3>
                                                </div>
                                                <div className="">
                                                <h2>Engage with audiences</h2>
                                                <h3>Keep your app users engaged and loyal</h3>
                                                </div> 
                                            </Carousel>
                                            
                                        </div>
                                        
            
                                        {/* <div className="signup-slider">
                                            <div className="crousel-select">
                                                <p ></p>
                                            </div>                   
                                        </div>    */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {Foot()} */}
                    </section>
                    </>
                    :
                    <>
                    <Header  SignInShow={this.state.signinlink}/>

                    <section className=" signuppage-new">
                        <div className="">
                            <div className="new-signupmain-new">
                                <div className=" new-signup-left">
                                    <div className=" new-signup-leftdiv">                            
                                        {/*<Registerform history={this.props.history} />*/}

                                        <div className=" align-items-center ">
                                            <div className=" new-signupcontent">
                                                <h3 className="text-left mt-0">Create an account</h3>
                                            </div>
                                            <div className=" d-grid ">
                                                <div className="socialoptionstart">Sign up with</div>
                                                <div className=" new-socialoptions-inner">
                                                    <a href={(this.state.deviceuuid === null || this.state.deviceuuid === '') ? process.env.REACT_APP_API_URL + "auth/google/" + localStorage.getItem("agency_id") : process.env.REACT_APP_API_URL + "auth/google/"+ localStorage.getItem("agency_id") + "/" + this.state.deviceuuid}>
                                                        <div className="new-signup-google" onClick={() => this.setState({googleSigninLoader: true})}>
                                                            {" "}
                                                            {this.state.googleSigninLoader ?<div className="login-loader"></div> :
                                                            <div className="signup-web-google">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="81.338" height="20.46" viewBox="0 0 81.338 20.46">
                                                                    <g id="Group_37899" data-name="Group 37899" transform="translate(-404.662 -416.54)">
                                                                        <text id="Google" transform="translate(435 432)" fill="#1b2952" font-size="14" font-family="Axiforma-Regular, Axiforma"><tspan x="0" y="0">Google</tspan></text>
                                                                        <g id="logo_googleg_48dp" transform="translate(404.662 416.54)">
                                                                            <path id="Shape" d="M18.821,9.456a11.761,11.761,0,0,0-.186-2.093H9v3.957h5.506a4.706,4.706,0,0,1-2.041,3.088v2.567h3.306a9.977,9.977,0,0,0,3.05-7.519Z" transform="translate(1.23 1.007)" fill="#4285f4" fill-rule="evenodd" />
                                                                            <path id="Shape-2" data-name="Shape" d="M10.1,19a9.767,9.767,0,0,0,6.771-2.479l-3.306-2.567A6.172,6.172,0,0,1,4.375,10.71H.957v2.651A10.226,10.226,0,0,0,10.1,19Z" transform="translate(0.131 1.464)" fill="#34a853" fill-rule="evenodd" />
                                                                            <path id="Shape-3" data-name="Shape" d="M4.506,11.5a6.048,6.048,0,0,1,0-3.887V4.958H1.088a10.243,10.243,0,0,0,0,9.189L4.506,11.5Z" transform="translate(0 0.678)" fill="#fbbc05" fill-rule="evenodd" />
                                                                            <path id="Shape-4" data-name="Shape" d="M10.1,4.069A5.527,5.527,0,0,1,14.01,5.6l2.934-2.934A9.832,9.832,0,0,0,10.1,0,10.226,10.226,0,0,0,.957,5.636L4.375,8.286A6.1,6.1,0,0,1,10.1,4.069Z" transform="translate(0.131)" fill="#ea4335" fill-rule="evenodd" />
                                                                            <path id="Shape-5" data-name="Shape" d="M0,0H20.46V20.46H0Z" fill="none" fill-rule="evenodd" />
                                                                        </g>
                                                                    </g>
                                                                </svg>
                                                            </div>}            
                                                        </div>
                                                    </a>

                                                    {/* <div className="new-facebooksignup">
                                                        <FacebookLogin appId="454532105165574" autoLoad={false} fields="name,email,picture" callback={this.responseFacebook} render={(renderProps) => (
                                                            <div className="d-flex facebookicontext" onClick={renderProps.onClick}>
                                                                {" "}

                                                                <svg xmlns="http://www.w3.org/2000/svg" width="90.747" height="22.748" viewBox="0 0 90.747 22.748">
                                                                    <g id="Group_37936" data-name="Group 37936" transform="translate(-509.591 -280.283)">
                                                                        <path id="Path_102206" data-name="Path 102206" d="M47.877,33.115H44.766v11.4H40.054v-11.4H37.812V29.11h2.242V26.519c0-1.853.88-4.756,4.755-4.756l3.491.015v3.888H45.766a.959.959,0,0,0-1,1.092v2.357h3.522Z" transform="translate(471.779 258.52)" fill="#1877f2"/>
                                                                        <text id="Facebook" transform="translate(530.338 297.46)" fill="#1b2952" font-size="14" font-family="Axiforma-Regular, Axiforma"><tspan x="0" y="0">Facebook</tspan></text>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        )}  cssClass="fbtn" disableMobileRedirect={true} />
                                                    </div> */}
                                                    
                                                    <a className="d-none" href={(this.state.deviceuuid === null || this.state.deviceuuid === '') ? process.env.REACT_APP_API_URL + "auth/facebook/" + localStorage.getItem("agency_id") : process.env.REACT_APP_API_URL + "auth/facebook/"+ localStorage.getItem("agency_id") + "/" + this.state.deviceuuid}>
                                                    <div className="new-facebooksignup">
                                                            <div className="d-flex facebookicontext">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="90.747" height="22.748" viewBox="0 0 90.747 22.748">
                                                                    <g id="Group_37936" data-name="Group 37936" transform="translate(-509.591 -280.283)">
                                                                        <path id="Path_102206" data-name="Path 102206" d="M47.877,33.115H44.766v11.4H40.054v-11.4H37.812V29.11h2.242V26.519c0-1.853.88-4.756,4.755-4.756l3.491.015v3.888H45.766a.959.959,0,0,0-1,1.092v2.357h3.522Z" transform="translate(471.779 258.52)" fill="#1877f2"/>
                                                                        <text id="Facebook" transform="translate(530.338 297.46)" fill="#1b2952" font-size="14" font-family="Axiforma-Regular, Axiforma"><tspan x="0" y="0">Facebook</tspan></text>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                    </div>
                                                    </a>
                                                </div>
                                                <div className="signup-ordiv d-flex">
                                                    <hr/>
                                                    <p className="signup-or">OR</p>
                                                    <hr/>
                                                </div>
                                            </div>
                                        </div>

                                        <form onSubmit={this.registerForm}>
                                            <div className="form-group new-signup-form">
                                                <label>Full name*</label>
                                                <input type="text" className={`form-control ${this.state.nameerror ? "newsignup-errorinput" : "" }`} name="name" placeholder="" value={this.state.name} onChange={(e) => this.handleChange(e)} />
                                                <div className="d-flex align-items-center slider-new-error ">
                                                    <div>
                                                        {this.state.nameerror &&
                                                            <div className="d-flex align-items-center new-error">
                                                                <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                                                    <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"/>
                                                                    <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"/>
                                                                    <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"/>
                                                                </svg>

                                                                <p>{this.state.nameerrormesg}</p>
                                                            </div>                                            
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group new-signup-form">
                                                <label>Email*</label>
                                                <input type="text" className={`form-control ${this.state.emailerror ? "newsignup-errorinput" : "" }`} name="email" value={this.state.email} onChange={(e) => this.handleChange(e)} placeholder="" />
                                                <div className="d-flex align-items-center slider-new-error  ">
                                                    <div>
                                                        {this.state.emailerror &&                                             
                                                            <div className="d-flex align-items-center  new-error">
                                                                <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                                                    <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"/>
                                                                    <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"/>
                                                                    <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"/>
                                                                </svg>

                                                                <p>{this.state.emailerrormesg}</p>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                    
                                            <div className="form-group new-signup-form">
                                                <label>Password*</label>
                                                <input type={this.state.passattibutetype} className={`form-control eye-close ${this.state.passworderror ? "newsignup-errorinput" : "" }`} name="password" value={this.state.password} onChange={(e) => this.handlechangeNewpass(e)} placeholder="" />
                                                <div className="new-signup-eye" onClick={this.changepass}>
                                                    <img src={this.state.eyeimage} className="img-fluid" alt="secure" />
                                                </div>
                                                {this.state.passworderror && 
                                                    <div className="d-flex  password-error new-error">
                                                        <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                                            <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"/>
                                                            <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"/>
                                                            <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"/>
                                                        </svg>
                                                        <p>{this.state.passworderrormesg}</p>
                                                    </div>
                                                }
                                            </div>

                                            { (this.state.password !== '' && this.state.password !== null && this.state.password !== undefined) &&
                                            <div className="new-signup-progressbar">
                                                <div>
                                                    
                                                
                                                        {this.state.progress == 0 ?
                                                            <>
                                                                <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                            </>
                                                        : 
                                                            this.state.progress == 1 ?
                                                                <>
                                                                    <progress id="file" max="100" value="100" className="redprogress">{" "} </progress>
                                                                    <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                    <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                    <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                </>
                                                            : 
                                                                this.state.progress == 2 ?
                                                                    <>
                                                                        <progress id="file" max="100" value="100" className="orangeprogress">{" "} </progress>
                                                                        <progress id="file" max="100" value="100" className="orangeprogress">{" "} </progress>
                                                                        <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                        <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                    </>
                                                                : 
                                                                    this.state.progress == 3 ?
                                                                        <>
                                                                            <progress id="file" max="100" value="100" className="lightgreenprogress">{" "} </progress>
                                                                            <progress id="file" max="100" value="100" className="lightgreenprogress">{" "} </progress>
                                                                            <progress id="file" max="100" value="100" className="lightgreenprogress">{" "} </progress>
                                                                            <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                        </>
                                                                    : 
                                                                        this.state.progress == 4 ?
                                                                            <>
                                                                                <progress id="file" max="100" value="100" className="greenprogress">{" "} </progress>
                                                                                <progress id="file" max="100" value="100" className="greenprogress">{" "} </progress>
                                                                                <progress id="file" max="100" value="100" className="greenprogress">{" "} </progress>
                                                                                <progress id="file" max="100" value="100" className="greenprogress">{" "} </progress>
                                                                            </>
                                                                        : 
                                                                            <>
                                                                                <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                                <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                                <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                                <progress id="file" max="100" value="0" className="">{" "} </progress>
                                                                            </>
                                                        }
                                                
                                                
                                                </div>
                                                <p>Your password must contain a minimum of 8 characters, 1 uppercase letter (A-Z), 1 lower case letter (a-z) and 1 number (1-9) </p>
                                            </div>
                                            }
                                            <div className="customcheck customcheck-red signup-check">
                                                <ReCAPTCHA sitekey="6LeOmMAUAAAAAGj7NXf5SlZA25DNAfvL1HwxaAeP" onChange={this.onChange} />
                                            </div>
                                            <div>
                                                {/*<button type="submit" className={`sign-uploginbtn ${this.state.captchavalue === "" ? "darkshade" : "" }`} disabled={this.state.captchavalue === "" ? true : false} >*/}
                                                <button type="submit" className={`sign-uploginbtn`} style={{
                                                "--custom_color5": localStorage.getItem('agencyid') == '1' || localStorage.getItem('agencyid') == 1 || localStorage.getItem('agencyid') == undefined || localStorage.getItem('agencyid') == 'undefined' || localStorage.getItem('agencyid') == null || localStorage.getItem('agencyid') == 'null' ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                                "--custom_color4": localStorage.getItem('agencyid') == '1' || localStorage.getItem('agencyid') == 1 || localStorage.getItem('agencyid') == undefined || localStorage.getItem('agencyid') == 'undefined' || localStorage.getItem('agencyid') == null || localStorage.getItem('agencyid') == 'null'? "white" : localStorage.getItem('button_hover_txtcolor'),
                                                "--custom_color2": localStorage.getItem('agencyid') == '1' || localStorage.getItem('agencyid') == 1 || localStorage.getItem('agencyid') == undefined || localStorage.getItem('agencyid') == 'undefined' || localStorage.getItem('agencyid') == null || localStorage.getItem('agencyid') == 'null' ? "#3064f9" : localStorage.getItem('button_background_color'),
                                                "--custom_color1": localStorage.getItem('agencyid') == '1' || localStorage.getItem('agencyid') == 1 || localStorage.getItem('agencyid') == undefined || localStorage.getItem('agencyid') == 'undefined' || localStorage.getItem('agencyid') == null || localStorage.getItem('agencyid') == 'null' ? "white" : localStorage.getItem('button_text_color'),
                                                }}>
                                                    <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                                        <div className={this.state.loader}>
                                                            {/*<img src={this.state.loaderimage} alt="loader" />*/}
                                                            <div className="new-loader"></div>
                                                        </div>{" "}
                                                        <div>{this.state.submitval}</div>
                                                    </div>
                                                </button>
                                            </div>
                        
                                            <div className="newsignup-tc">
                                                <h6 className="">
                                                    By creating an account, you agree to our
                                                    <a href="https://www.appmysite.com/terms-conditions/" target="_blank" rel="noopener noreferrer" >
                                                        {" "}
                                                        Terms &amp; Conditions
                                                    </a>{" "}
                                                    &amp;
                                                    <a href="https://www.appmysite.com/privacy-policy/" target="_blank" rel="noopener noreferrer" >
                                                        {" "}
                                                        Privacy Policy.{" "}
                                                    </a>
                                                    You also agree to receiving occasional emails about our product and services on your registered email address.
                                                </h6>
                                            </div>

                                            {/* <div className="d-block d-md-none text-center signinoption">
                                            <p>Have an account? <a href="/sign-in">Sign In</a>
                                            </p>
                                            </div> */}

                                        </form>
                                    </div>                    
                                </div>
                                <div className=" new-signup-right">                
                                    <div className="">                  
                                        <div className="d-flex justify-content-center">
                                            <img src={ require("../assets/images/signup/signupimg.png").default } alt="signup img" />
                                        </div>

                                        <div className="signup-crousel">
                                                {/* <div className=" active">
                                                <h2>No-code app builder</h2>
                                                <h3>Create powerful apps without coding</h3>
                                                </div>
                                                <div className="">
                                                <h2>Personalize your app</h2>
                                                <h3>Customize apps to align with branding</h3>
                                                </div>
                                                <div className="">
                                                <h2>App store compliant</h2>
                                                <h3>Publish apps to Google Play & App Store</h3>
                                                </div>
                                                <div className="">
                                                <h2>Engage with audiences</h2>
                                                <h3>Keep your app users engaged and loyal</h3>
                                                </div>                    */}
                                            <Carousel autoPlay={this.state.sliderStatus} infiniteLoop={this.state.sliderStatus} classNamePrefix="signup-carousel">
                        
                                            <div >
                                                <h2>No-code app builder</h2>
                                                <h3>Create powerful apps without coding</h3>
                                                </div>
                                                <div className="">
                                                <h2>Personalize your app</h2>
                                                <h3>Customize apps to align with branding</h3>
                                                </div>
                                                <div className="">
                                                <h2>App store compliant</h2>
                                                <h3>Publish apps to Google Play & App Store</h3>
                                                </div>
                                                <div className="">
                                                <h2>Engage with audiences</h2>
                                                <h3>Keep your app users engaged and loyal</h3>
                                                </div> 
                                            </Carousel>
                                            
                                        </div>
                                        

                                        {/* <div className="signup-slider">
                                            <div className="crousel-select">
                                                <p ></p>
                                            </div>                   
                                        </div>    */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {Foot()} */}
                    </section>
                    </>
                    }
                </>
                )
            }
        </>
        );
    }

}

