import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Modal, ModalBody } from "reactstrap";

const UpgradeModal = ({ openPopUp, handlePopUp }) => {
  return (
    <Modal
      isOpen={openPopUp}
      // isOpen={true}
      centered
      toggle={(e) => handlePopUp(e)}
     
      className="plan-cancel-popup"
      dataclassName="addonpopup"
    >
      <div className="crossmodal mt-1" onClick={(e) => handlePopUp(e)}>
        <img
          src={require("../../../assets/images/dashboard/cross.png").default}
          alt="cross"
        />
      </div>
      <ModalBody className="">
        <div>
          <div className="d-flex">
            <div className="confirmtext">
              <h4>Upgrade to a paid plan</h4>

              <h6>
              To purchase add-ons, you must be on a paid plan. Upgrade your plan and try again.
              </h6>
            </div>
          </div>
          <div className="plan-cancel-popup-footer d-flex justify-content-center">
            <div
              className="plan-cancelbtn"
              onClick={(e) => handlePopUp(e)}
             
            >
              Cancel
            </div>

            <Link
              className="addon-upgrade-btn"
              to={
                localStorage.getItem("workspaceId") !== "" &&
                localStorage.getItem("workspaceId") !== undefined &&
                localStorage.getItem("workspaceId") !== "undefined" &&
                localStorage.getItem("workspaceId") !== null
                  ? `/workspace/pricing/${btoa(
                      localStorage.getItem("workspaceId")
                    )}`
                  : parseInt(localStorage.getItem("website_technology")) === 4
                  ? "/app/wordpress-pricing/" +
                    btoa(localStorage.getItem("Appid"))
                  : parseInt(localStorage.getItem("website_technology")) === 5
                  ? "/app/woocommerce-pricing/" +
                    btoa(localStorage.getItem("Appid"))
                  : parseInt(localStorage.getItem("website_technology")) === 3
                  ? "/app/custom-app-pricing/" +
                    btoa(localStorage.getItem("Appid"))
                  : parseInt(localStorage.getItem("website_technology")) === 2
                  ? "/app/web-to-app-pricing/" +
                    btoa(localStorage.getItem("Appid"))
                  : "/app/pricing"
              }
            >
              Upgrade
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default UpgradeModal;
