import React from "react";

const LinkFieldIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="19"
      viewBox="0 0 20 19"
    >
      <g
        id="Group_42666"
        data-name="Group 42666"
        transform="translate(-1005 -1029)"
      >
        <g
          id="Rectangle_31915"
          data-name="Rectangle 31915"
          transform="translate(1005 1029)"
          fill="#fff"
          stroke="#bcc1ce"
          stroke-width="1"
        >
          <rect width="20" height="19" rx="2" stroke="none" />
          <rect x="0.5" y="0.5" width="19" height="18" rx="1.5" fill="none" />
        </g>
        <path
          id="Path_119031"
          data-name="Path 119031"
          d="M15578.713-3593.554h2"
          transform="translate(-14569.713 4628.054)"
          fill="none"
          stroke="#bcc1ce"
          stroke-width="1"
        />
        <path
          id="Path_119032"
          data-name="Path 119032"
          d="M15578.713-3593.554h2"
          transform="translate(-14569.713 4632.054)"
          fill="none"
          stroke="#bcc1ce"
          stroke-width="1"
        />
        <path
          id="Path_119033"
          data-name="Path 119033"
          d="M15578.713-3593.554h2"
          transform="translate(-14569.713 4636.054)"
          fill="none"
          stroke="#bcc1ce"
          stroke-width="1"
        />
        <path
          id="Path_119034"
          data-name="Path 119034"
          d="M15578.711-3593.554h8"
          transform="translate(-14565.713 4628.054)"
          fill="none"
          stroke="#bcc1ce"
          stroke-width="1"
        />
        <path
          id="Path_119035"
          data-name="Path 119035"
          d="M15578.711-3593.554h8"
          transform="translate(-14565.713 4632.054)"
          fill="none"
          stroke="#bcc1ce"
          stroke-width="1"
        />
        <path
          id="Path_119036"
          data-name="Path 119036"
          d="M15578.711-3593.554h8"
          transform="translate(-14565.713 4636.054)"
          fill="none"
          stroke="#bcc1ce"
          stroke-width="1"
        />
      </g>
    </svg>
  );
};

export default LinkFieldIcon;
