import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import goBack from "../../assets/images/dashboard/goBackicon.png";
import { FONTS } from "./fonts";
import Noresult from "../../assets/images/dashboard/no-media.png";
const FontSelectionModal = ({
  appFontFamily,
  setAppFontFamily,
  setOpenFontModal,
  openFontModal,
}) => {
  const [searchValue, setSearchValue] = useState("");
  return (
    <Modal
      isOpen={openFontModal}
      toggle={() => setOpenFontModal(!openFontModal)}
      centered
      dataclass="countrycode"
      className="country-dropdown-modal fontselect-modal"
    >
      <ModalHeader
        toggle={() => setOpenFontModal(!openFontModal)}
      >
        <div className=" country-search-div ">
          <div className="d-flex align-items-center w-100 ">
            <div
              className="flag-backaero-popup"
              onClick={() => {
                setOpenFontModal(!openFontModal);
                setSearchValue("");
              }}
            >
              <img src={goBack} alt="back-icon" />
            </div>

            <input
              type="text"
              className="form-control mob-flagsearch-input"
              placeholder="Search..."
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
            {searchValue.trim() && (
              <p onClick={(e) => setSearchValue("")} className="cleartext">
                Clear
              </p>
            )}
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="flag-code-selector">
        <div className=" countrycode-flag-div ">
          {FONTS.filter((font) =>
            font.name.toLowerCase().includes(searchValue.toLowerCase())
          ).length === 0 ? (
            <div className="nofont-found">
              <img src={Noresult} alt="Noresult" />

              <p className="noresult-text">No results found</p>
            </div>
          ) : (
            FONTS.filter((font) =>
              font.name.toLowerCase().includes(searchValue.toLowerCase())
            ).map((font, i) => (
              <div
                className="country-line-flag-name-div"
                key={i}
                onClick={() => {
                  setAppFontFamily(font.fontFamily);
                  setOpenFontModal(false);
                }}
              >
                <div className="mob-country-flag-name-div">
                  <p className="country-name" style={{ fontFamily: font.fontFamily }}>
                    {font.name}
                  </p>
                </div>
              </div>
            ))
          )}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default FontSelectionModal;
