import React from "react";
import Header from "./Header";
import { Link, Redirect } from "react-router-dom";
import Leftpanel from "./Leftpanel";
import "../assets/css/Subscription.css";
import { Foot } from "./Foot";
import {
  GetpackageAll,
  DowngradeApi,
  SendpaymentinfotoserverApi,
  Makestrippayment,
  workspacePlanListing,
  MakestripWorkspacePayment,
  wooChangePlan,
} from "../Api/Dashboard/DashboardApi";
import { Spinner, Modal, ModalHeader, ModalBody } from "reactstrap";
import styles from "./Appdownload/subscription.module.css";
import Dealselection from "./Billing/Dealselection";
import "../assets/css/PlanListing.css";
import Accordion from "./Reusable/Accordion";
import { paddleChangePlan } from "../Api/Addons/Addonsapi";
export default class PlanListing extends React.PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(); // Create a ref object
    this.state = {
      loader0: "d-none",
      btnVal0: "Get Started",
      loader1: "d-none",
      btnVal1: "Get Started",
      loader2: "d-none",
      btnVal2: "Get Started",
      tempFilter: false,
      loaderimage: require("../assets/images/dashboard/loaderWP.gif").default,
      monthlydata: [],
      yearlydata: [],
      lifetimedata: [],
      spinner: true,
      filter: "monthly",
      mactive: false,
      yactive: true,
      subscriptionboolen: "",
      currency: "",
      zoho_plan_code: "",
      subscription_id: "",
      appid: "",
      one_time: [],
      user_status: "",
      build_process_status: "",
      discount: 0,
      appuser_role_id: null,
      deluser: false,
      popupdata: null,
      loader_remove: "d-none",
      loaderimage_remove: require("../assets/images/dashboard/loader.gif")
        .default,
      submitval_confirmpop: "Yes",
      mobilefilter: "Preview",
      payment_methods: [],
      customer_billing_type: null,
      lifetimecheck: 0,
      // handleDealsPage: 0,
      tab_check: "Preview",
      //   planListingData: [],
      website_technology: 0,
      checker_web_technology: this.props.match.params.checker,
      customer_type: "",
      reseller_licence_id: "",
    };
  }
  componentDidMount() {
    localStorage.setItem(
      "webWorkspace_technology",
      this.state.checker_web_technology
    );
    window.scrollTo(0, 0);
    document.title = "Subscription";

    // GetpackageAll(this, params, () => {
    //   if (this.state.build_process_status > 0) {
    //     this.props.history.push("/download-app");
    //   }
    //   if (this.state.one_time.length > 0) {
    //     if (
    //       this.state.one_time[0].plan_code ===
    //       process.env.REACT_APP_Lifetime_preview
    //     ) {
    //       this.props.history.push("/download-app");
    //     }
    //   }
    //   localStorage.setItem("Appid", atob(this.props.match.params.appid));
    // });

    workspacePlanListing(this, this.state.checker_web_technology);
  }
  removepopup = () => {
    if (this.state.appuser_role_id !== 3 && this.state.appuser_role_id !== 4) {
      this.setState({
        deluser: !this.state.deluser,
      });
    }
  };

  previewdowngrade = (e) => {
    e.preventDefault();

    // const sendparam = {
    //   app_id: atob(this.props.match.params.appid),
    //   total: this.state.one_time[0].amount,
    //   plan_array: [
    //     {
    //       zoho_plan_code: this.state.one_time[0].plan_code,
    //       type: 1,
    //       amount: this.state.one_time[0].amount,
    //       quantity: 1,
    //     },
    //   ],
    //   currency: "$",
    //   order_type: "downgrade",
    // };
    // const sendtostrip = {
    //   app_id: parseInt(atob(this.props.match.params.appid)),
    //   plan_array: [
    //     {
    //       price_id: this.state.one_time[0].plan_code,
    //       quantity: 1,
    //       type: 1,
    //     },
    //   ],
    //   payment_method_id: "downgrade",
    //   order_type: 2,
    //   deal_id: null,
    // };
    this.setState({
      loader_remove: "d-block",
      submitval_confirmpop: "",
    });
    const workspaceParam = {
      workspace_id: parseInt(localStorage.getItem("workspaceId")),
      plan_array: [
        {
          price_id: this.state.one_time[0].plan_code,

          quantity: 1,
          type: 1,
        },
      ],
      payment_method_id: "downgrade",
      order_type: 2,
      deal_id: "",
      // action: this.state.payment_method_id,
    };

    const paddleParam = {
      workspace_id:
        localStorage.getItem("workspaceId") !== "" &&
        localStorage.getItem("workspaceId") !== "undefined" &&
        localStorage.getItem("workspaceId") !== undefined &&
        localStorage.getItem("workspaceId") !== null
          ? parseInt(localStorage.getItem("workspaceId"))
          : null,
      app_id:
        localStorage.getItem("workspaceId") !== "" &&
        localStorage.getItem("workspaceId") !== "undefined" &&
        localStorage.getItem("workspaceId") !== undefined &&
        localStorage.getItem("workspaceId") !== null
          ? null
          : parseInt(localStorage.getItem("Appid")),
      deal_id: null,
      order_type: 2,
      price_id: this.state.one_time[0].plan_code,
      quantity: 1,
      type: 1,
    };

    //Payment Api
    // ? //strip downgrade
    this.state.customer_billing_type === 3
      ? paddleChangePlan(paddleParam, this)
      : MakestripWorkspacePayment(workspaceParam, this);
  };

  monthly = (e) => {
    this.setState({
      filter: e,
      mobilefilter: "Preview",
    });
  };
  handlemobilefilter = (plan_name) => {
    this.setState({
      mobilefilter: plan_name,
      tab_check: plan_name,
    });
  };
  handlescroll = () => {
    const { index, selected } = this.props;
    if (index === selected) {
      this.myRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  extend = () => {
    if (
      document
        .querySelector(".moretext")
        .classList.contains(".accordion-item-new")
    ) {
      document
        .querySelector(".moretext")
        .classList.toggle("accordion-item-new");
    }
    if (document.querySelector(".see-more-btn").textContent === "See More") {
      document.querySelector(".see-more-btn").textContent = "See Less";
      document
        .querySelector(".moretext")
        .classList.toggle("accordion-item-new");
      document.querySelector(".see-more-faq").classList.toggle("see-more-new");
      document
        .querySelector(".see-more-btn")
        .classList.toggle("see-more-btn-new");
    } else {
      document.querySelector(".see-more-btn").textContent = "See More";
      document
        .querySelector(".moretext")
        .classList.toggle("accordion-item-new");
      document.querySelector(".see-more-faq").classList.toggle("see-more-new");
      document
        .querySelector(".see-more-btn")
        .classList.toggle("see-more-btn-new");
    }
  };

  handleWooCustomer = (e, customer_type, plan_code, i) => {
    if (i !== undefined) {
      this.setState({
        ["loader" + i]: "d-block",
        ["btnVal" + i]: "",
      });
    } else {
      this.setState({
        loader_remove: "d-block",
        submitval_confirmpop: "",
      });
    }
    this.setState({
      tempFilter: true,
    });

    if (customer_type === 4) {
      const params = {
        price_id: plan_code,
        type: 1, ///1 for plan AND 2 for addon
        workspace_id: parseInt(localStorage.getItem("workspaceId")), //either this or workspace_id

        return_url:
          window.location.protocol + "//" + window.location.host + "/transaction-successful",
      };
      wooChangePlan(this, params);
    }
  };

  render() {
    const accordionData2 = [
      {
        title: "Does AppMySite support websites made on all platforms?",
        content: `AppMySite supports all platforms with the ‘website to app’ plans. If you have an active website, you can connect it to AppMySite and build a mobile app without coding. 
        `,
      },
      {
        title: "Will changes made on the website be reflected in the app?",
        content: `AppMySite will automatically render your mobile website’s pages in the app. Any changes you make to your website will also be updated in your app automatically. 
        `,
      },
      {
        title: "Will all my website’s functionalities be rendered in the app?",
        content: `Any functionality associated with your website will be rendered in the app as it appears on your mobile site. You can choose to show the webpages you’d like to show in the app.`,
      },
      {
        title:
          "Can I choose a ‘Website to app’ plan if I have a WordPress website?",
        content: `‘Website to app’ plans are available for all users, including WordPress website owners. You’re not required to choose a WordPress or WooCommerce plan if you have a WordPress website. However, ‘website to app’ plans do not include native integration with WordPress and WooCommerce. 
        `,
      },
      {
        title: "How can I get features outside my pricing plan?",
        content: `You can always upgrade to a higher pricing plan in order to avail features outside your subscription. Before choosing a plan, you can take a look at all the features included in every plan and take a call accordingly.`,
      },
      {
        title: "Are the prices including taxes?",
        content: `All AppMySite plans are inclusive of taxes. There are no hidden charges. 
        `,
      },
      {
        title: "Will my app continue working if I stop paying my subscription?",
        content: `Your app will be downgraded to the free Preview plan when you stop paying your monthly or annual subscription. In this case, your app will stop working. If you don’t want to pay on a recurring basis, there is an option to subscribe to a lifetime subscription as well. 
 
        `,
      },
      {
        title: "How many apps can I create in each plan?",
        content: `Every ‘Website to App’ plan is available for a single mobile app. In case you wish to create multiple apps, you can purchase a separate plan for each app or choose to subscribe to ‘Unlimited Workspace’ plans. These plans enable you to build unlimited apps on a single plan. 
        `,
      },
      {
        title: "Will AppMySite publish my app as well?",
        content: `Once you finish building your app on AppMySite, you can download your app build(s) and publish to the app store(s). Furthermore, you can refer to our Help Center for documentation and video tutorials regarding app submission to the app stores. 
        If you require additional support, you can purchase the Upload to App Stores add-on. With this add-on, you can get AppMySite to submit your app to the app store(s).  
        `,
      },
      {
        title: "Does AppMySite offer custom app development services?",
        content: `We do not offer any form of custom development services. AppMySite is a DIY platform where users can sign up and create a mobile app on their own.  
        `,
      },
      {
        title: "Do you have a refund policy?",
        content: `Click here to learn more about our refund policy.   
        `,
      },
      {
        title:
          "Do these plans also include the cost of creating Apple and Google developer accounts?",
        content: `Once you finish creating an app on AppMySite, you can submit your app to Google Play and Apple App Store via developer accounts. 
        You’re required to create an Apple and Google account on your own. Your AppMySite subscription plan doesn’t include the cost of creating Apple and Google developer accounts. Visit our Help Center to learn how to create an Apple and Google developer account.    
        `,
      },
      {
        title: "Can I change my subscription plan(s)?",
        content: `You can easily upgrade or downgrade your subscriptions at any time, on a prorated basis.    
        `,
      },
      {
        title: "What is your cancellation policy?",
        content: `You can cancel your paid subscription and downgrade to the free plan at any time. Once canceled, you will not be charged for any subsequent billing cycles.
        Your app will stop working as soon as you downgrade to a free plan.    
        `,
      },
      {
        title: "What do I get in the Preview plan?",
        content: `We’d like you to try before you buy, which is what you can do with the Preview plan. You can build as many free apps as you like and preview them from within your account before you upgrade and publish.    
        `,
      },
      {
        title: "Do I need a developer account?",
        content: `Yes, depending on your plan you’ll need developer accounts on the Google Play Store and/or the Apple App Store in order to publish your apps.
        Once you build your apps, you’ll be able to download your APK or AAB files (for your Android apps) and an IPA file (for your iOS apps) which then can be uploaded to your developer accounts. You can also request AppMySite to publish your apps for you, by simply purchasing the Upload to App Stores add on.   
        `,
      },
      {
        title: "How much time does it take to publish an app?",
        content: `Once an app is submitted to the relevant app store, it is subject to a review from their end. Both stores generally take 24-48 hours but could take longer depending on various factors. You can find more details on their official websites.   
        `,
      },
    ];

    const accordionData = [
      {
        title: "Will AppMySite work with my WordPress website?",
        content: `AppMySite is deeply integrated with WordPress. Once your app is connected to your site, your pages, posts, categories, and menus will appear within the app automatically. Any changes made to your CMS or blog website will reflect instantly in your app.
        `,
      },
      {
        title: "Will AppMySite be compatible with my website's plugins?",
        content: `Although AppMySite supports a wide variety of third-party WordPress plugins, there are a reasonable number of plugins that will not work. It depends on how the plugin is coded. Plugins integrated with the official WordPress REST APIs are likely to work well with our app. However, if the plugin does not support the WordPress REST APIs, your plugin will struggle. Your best bet is to reach out to the author of the plugin in question for assistance. Alternatively, you could simply turn on our web view feature within the app settings, that will render your mobile site within the app.
        If your website’s plugins create custom post types and taxonomies, you can sync them to your app. AppMySite offers support for custom post types and taxonomies created via third-party plugins and themes. 
        It is important to note that some plugins have the potential to tamper with the core functionality of your WordPress website and as a result will disrupt the connectivity between your website and your app.
        `,
      },
      {
        title: "Which WordPress themes does AppMySite support?",
        content: `Since AppMySite enables you to create a native design aesthetic for your app, your WordPress theme does not affect it. However, if your theme creates certain custom functionality on the website, we cannot guarantee your app will support it.`,
      },
      {
        title: "Does AppMySite support WooCommerce? ",
        content: `AppMySite offers deep integration with WooCommerce. Once you connect your WooCommerce store, your products and categories will be synced to the app automatically. Any change you make to your WooCommerce store will also be reflected in real-time.  
        `,
      },
      {
        title: "Which payment gateways do apps built on AppMySite support?",
        content: `AppMySite supports all payment gateways active on your WooCommerce store. You’re not required to take any additional steps to integrate a payment gateway with your app. AppMySite automatically displays your website’s payment gateway during checkout.`,
      },
      {
        title: "Which shipping methods can I display on the app?",
        content: `AppMySite supports all shipping plugins you’re using on your WooCommerce store. Any shipping method you’ve configured for your website would be synced to the app automatically.   
        `,
      },
      {
        title: "Will my website content be synced with the app automatically? ",
        content: `AppMySite syncs your website and app in real-time, automatically. Any change you make to the website would be reflected to the app in real-time.  `,
      },
      {
        title:
          "What will happen to my app in case my domain or website hosting expires?",
        content: `In case your website stops working for any reason (domain or hosting expiry), your app will stop working too. Your app’s content is sourced directly from your website database. If the app is not able to connect with your website at any point, it will stop working. `,
      },
      {
        title: "Will my website performance be affected by AppMySite's plugin?",
        content: `AppMySite’s plugin has no impact on the speed and performance of your website. It simply connects your website and app and doesn’t affect your site’s speed in any way. All the data that appears in the app is directly sourced from your website's database. Our plugin does not add to the load time.  
        `,
      },
      {
        title:
          "Does AppMySite support WordPress custom post types and taxonomies?",
        content: `If your website’s plugins create custom post types and taxonomies, you can sync them to your app. AppMySite offers support for custom post types and taxonomies created via third-party plugins and themes. 
        `,
      },
      {
        title:
          "How can I get support for a plugin or theme that AppMySite is not compatible with? ",
        content: `If you’re using a plugin or custom theme that AppMySite does not support, you can:
        <b>Try using webviews:</b> You could simply turn on our web views for posts, products, pages and custom post types from within the app settings, which will render your mobile site within the app. 
        <b>Contact the plugin author:</b> Reach out to the author of the plugin(s) in question and get them to add support for the official WordPress APIs. 
        <b>Ask the community:</b>  The AppMySite Community is a great place for our customers to share their wishlist. Feature requests that gain enough traction eventually make their way to our product roadmap.   
        `,
      },
      {
        title: "How can I avail the features outside the pricing plan?",
        content: `You can always upgrade to a higher pricing plan in order to avail features outside your subscription. Before choosing a plan, you can take a look at all the features included in every plan and take a call accordingly.
        `,
      },
      {
        title: "Are the prices including taxes?",
        content: `All AppMySite plans are inclusive of taxes. There are no hidden charges.    
        `,
      },
      {
        title:
          "Will my app continue working if I stop paying my monthly or annual subscription?",
        content: `Your app will be downgraded to the free Preview plan when you stop paying your monthly or annual subscription. In this case, your app will stop working. 
              If you don’t want to pay on a recurring basis, there is an option to subscribe to a lifetime subscription as well. 
        `,
      },
      {
        title: "How many apps can I create in each plan?",
        content: `Every WordPress & WooCommerce plan is available for a single mobile app. In case you wish to create multiple apps, you can purchase a separate plan for each app or choose to subscribe to ‘Unlimited Workspace’ plans. These plans enable you to build unlimited apps on a single plan. 
        `,
      },
      {
        title: "Will AppMySite publish my app as well?",
        content: `Once you finish building your app on AppMySite, you can download your app build(s) and publish to the app store(s). Furthermore, you can refer to our Help Center for documentation and video tutorials regarding app submission to the app stores.
        If you require additional support, you can purchase the Upload to App Stores add-on. With this add-on, you can allow AppMySite to submit your app to the app store(s).     
        `,
      },
      {
        title: "Does AppMySite offer custom app development services?",
        content: `We do not offer any form of custom development services. AppMySite is a DIY platform where users can sign up and create a mobile app on their own. 
        `,
      },
      {
        title: "Do you have a refund policy?",
        content: `Click here to learn more about our refund policy.
        `,
      },
      {
        title:
          "Do these plans also include the cost of creating Apple and Google developer accounts? ",
        content: `Once you finish creating an app on AppMySite, you can submit your app to Google Play and Apple App Store via developer accounts. 
        You’re required to create an Apple and Google account on your own. Your AppMySite subscription plan doesn’t include the cost of creating Apple and Google developer accounts. Visit our Help Center to learn how to create an Apple and Google developer account.  
        `,
      },
      {
        title: "Can I change my subscription plan(s)?",
        content: `You can easily upgrade or downgrade your subscriptions at any time, on a prorated basis.
        `,
      },
      {
        title: "What is your cancellation policy?",
        content: `You can cancel your paid subscription and downgrade to the free plan at any time. Once canceled, you will not be charged for any subsequent billing cycles.
        Your app will stop working as soon as you downgrade back to a free plan.
        `,
      },
      {
        title: "What do I get in the Preview plan?",
        content: `We’d like you to try before you buy, which is what you can do with the Preview plan. You can build as many free apps as you like and preview them from within your account before you upgrade and publish.

        `,
      },
      {
        title: "What is a distribution license?",
        content: `Distribution licenses authorize you to download your app from AppMySite for submission to the app store(s). 
        `,
      },
      {
        title: "Do I need a developer account?",
        content: `Depending on your plan, you’ll need developer accounts for Google Play and the Apple App Store in order to publish your apps.
        Once you build your apps, you’ll be able to download your APK or AAB files (for your Android apps) and an IPA file (for your iOS apps) which then can be uploaded to your developer accounts. 
        `,
      },
      {
        title: "How much time does it take to publish an app?",
        content: `Once an app is submitted to the relevant app store, it is subject to a review from their end. Both stores generally take 24-48 hours but could take longer depending on various factors. You can find more details on their official websites.
        `,
      },
    ];

    if (this.state.appuser_role_id === 3 || this.state.appuser_role_id === 4) {
      this.props.history.push("/app/dashboard");
    }

    const dlink = (
      plan_code,
      recurring_price,
      package_name,
      interval_unit,
      id
    ) => {
      if (
        this.state.appuser_role_id !== 3 &&
        this.state.appuser_role_id !== 4
      ) {
        return (
          "/checkout/" +
          plan_code +
          "/" +
          btoa(recurring_price) +
          "/" +
          btoa(plan_code) +
          "/" +
          btoa(interval_unit) +
          "/" +
          btoa(id) +
          "/" +
          btoa(null) +
          "/" +
          "1"
        );
      }
    };

    return (
      <>
        <div className="">
          <Header customclass={"header-responsive-new"} />
        </div>
        {/* Mobile View */}
        {/* <Mobiletop
          name={"Subscription"}
          imgpath={null}
          monitorMobileClass={"subs-mobile-top"}
        /> */}
        {/* Mobile View End*/}
        <section className="dasboard_page" style={{ background: "none" }}>
          {/* <div className="d-md-none d-block">
            <Leftpanel />
          </div> */}
          {this.state.spinner ? (
            <div className="spinn">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <>
              <div
                className={
                  "transparent_background transparent_background_planlisting upgradepage plans-bottompadding " +
                  styles.makechange
                }
              >
                <div className="subscriptions-top-head">
                  <div
                    className={
                      styles.setbackground +
                      " setbackground-new setbackground-new-mobile"
                    }
                  >
                    <div
                      className={
                        styles.widthset +
                        " card-container-width card-container-width-mobile"
                      }
                    >
                      <div className="mt-md-3 mt-0 pb-3 pb-md-0 d-md-flex d-block justify-content-between subscription-plan-head subscription-plan-head-mobile">
                        <div className={styles.heading}>
                          {parseInt(this.state.checker_web_technology) === 1 ? (
                            <h3 className="subscriptions-new-head subscriptions-new-head-mobile">
                              WordPress Plans
                            </h3>
                          )
                          : parseInt(this.state.checker_web_technology) === 2 ? (
                            <h3 className="subscriptions-new-head subscriptions-new-head-mobile">
                              Website to App Plans
                            </h3>
                          ) : (
                            <h3 className="subscriptions-new-head subscriptions-new-head-mobile">
                              Custom App Plans
                            </h3>
                          )}
                          {/* <div
                            className="deals-page-direct-btn deals-page-mobile subscription-direct-btn d-flex d-md-none"
                            onClick={this.handleDeals}
                          >
                            <a className="deals-btn deals-btn-mobile subscription-deals-btn">
                              Use purchased deal license instead
                              <img
                                src={
                                  require("../assets/images/dashboard/deals-arrow.png")
                                    .default
                                }
                                className="deals-arrow-img"
                              />
                            </a>
                          </div> */}
                        </div>
                        <div
                          className={
                            "text-center subscription-tab-new subscription-plan-list " +
                            styles.tab +
                            " " +
                            styles.new_tab_class
                          }
                        >
                          <ul
                            className={
                              styles.selection +
                              " mt-md-0 mt-2 plan-nav plan-nav-mobile"
                            }
                          >
                            <li className="monthly-spec monthly-spec-new">
                              <div
                                className={
                                  this.state.filter === "monthly"
                                    ? styles.active + " plan-tab-active"
                                    : undefined
                                }
                                onClick={(e) =>
                                  this.state.tempFilter !== true
                                    ? this.monthly("monthly")
                                    : ""
                                }
                              >
                                Monthly
                              </div>
                            </li>
                            <li className="yearly-spec">
                              <div
                                className={
                                  this.state.filter === "yearly"
                                    ? styles.active
                                    : undefined + " plan-tab-inactive"
                                }
                                onClick={(e) =>
                                  this.state.tempFilter !== true
                                    ? this.monthly("yearly")
                                    : ""
                                }
                              >
                                Yearly
                              </div>
                            </li>
                            {this.state.lifetimecheck == 1 ? (
                              <li className="lifetime-spec lifetime-spec-new">
                                <div
                                  className={
                                    this.state.filter === "lifetime"
                                      ? styles.active + " plan-tab-active"
                                      : undefined
                                  }
                                  onClick={(e) =>
                                    this.state.tempFilter !== true
                                      ? this.monthly("lifetime")
                                      : ""
                                  }
                                >
                                  Lifetime
                                </div>
                              </li>
                            ) : (
                              <></>
                            )}
                          </ul>
                          {/* <div
                            className="deals-page-direct-btn deals-page-mobile subscription-direct-btn d-md-flex d-none"
                            onClick={this.handleDeals}
                          >
                            <a className="deals-btn deals-btn-mobile subscription-deals-btn">
                              Use purchased deal license instead
                              <img
                                src={
                                  require("../assets/images/dashboard/deals-arrow.png")
                                    .default
                                }
                                className="deals-arrow-img"
                              />
                            </a>
                          </div> */}
                        </div>
                      </div>
                      {/* Summary start */}
                      <div className="mt-md-5 mt-2 new-dealsub-spacing dealsub-mobile-spacing pricing-plan-cards pricing-plan-cards-mobile subscription-cards-spacing">
                        <div
                          className={
                            styles.summarychart +
                            " summary-chart-mobile summary-chart-new summary-chart-new-mobile "
                          }
                        >
                          {/* Preview */}
                          <div
                            className={
                              styles.summarydetail +
                              " summary-detail-new summary-detail-new-mobile"
                            }
                          >
                            <h2 className="card-heading-first card-heading-first-mobile">
                              {/* Preview */}
                              {this.state.one_time[0].plan_name}
                            </h2>

                            <div className="mt-4 device-icons">
                              <img
                                src={
                                  require("../assets/images/dashboard/device.png")
                                    .default
                                }
                                alt="de"
                                className="mr-3"
                              />
                              <img
                                src={
                                  require("../assets/images/dashboard/android_inactive.png")
                                    .default
                                }
                                alt="de"
                                className="mr-1"
                              />
                              <img
                                src={
                                  require("../assets/images/dashboard/ios_inactive.png")
                                    .default
                                }
                                alt="de"
                              />
                            </div>
                            <div className="plan-desc-data plan-desc-data-mobile">
                              <ul className={styles.listing}>
                                <p className={styles.summarypara}>
                                  {parseInt(
                                    localStorage.getItem(
                                      "webWorkspace_technology"
                                    )
                                  ) == 2 ? (
                                    <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-first unlimited-list">
                                      <li>Website to App</li>

                                      <li>Personalization</li>
                                      <li>Test on Real Device</li>
                                    </ul>
                                  ) : (
                                    <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-first unlimited-list">
                                      <li>Website to App</li>
                                      <li>WordPress Integration</li>
                                      <li>WooCommerce Integration</li>
                                      <li>Personalization</li>
                                      <li>Test on Real Device</li>
                                    </ul>
                                  )}
                                  {
                                    // Object.keys(
                                    //   this.state.one_time[0].description.summary
                                    // )[1]
                                  }
                                </p>
                              </ul>
                            </div>
                            <div className="select-button-spacing-first select-button-repadding select-button-spacing-first-mobile">
                              <div
                                className={
                                  styles.listpricing + " plan-select-new-btn"
                                }
                              >
                                <div className="select-btn-text">
                                  <h4 className="price-fonts">
                                    {/* $0 */}
                                    Free
                                    {/* {this.state.one_time[0].amount.replace(
                                      ".00",
                                      " "
                                    )}{" "} */}
                                  </h4>
                                  {/* {this.state.filter === "monthly" ? (
                                    <span>per month</span>
                                  ) : this.state.filter === "yearly" ? (
                                    <span>per month paid yearly</span>
                                  ) : (
                                    <span>one time payment</span>
                                  )} */}
                                </div>
                                {this.state.one_time[0].is_subscribed === 1 ? (
                                  <Link
                                    to="#"
                                    className={
                                      styles.getstarted +
                                      " unlimitedselect select-button-red"
                                    }
                                  >
                                    Current Plan
                                  </Link>
                                ) : parseInt(
                                    localStorage.getItem("customer_type")
                                  ) === 4 &&
                                  this.state.reseller_licence_id === null ? (
                                  <a
                                    className={
                                      styles.getstartedactive +
                                      " select-button-new select-button-new-workspace unlimited-btn"
                                    }
                                    target="_blank"
                                    href={
                                      process.env.REACT_APP_WOOCOMMERCE_URL +
                                      "my-account/my-subscriptions/"
                                    }
                                  >
                                    Get Started{" "}
                                    <img
                                      src={
                                        require("../assets/images/dashboard/Goto.png")
                                          .default
                                      }
                                      className="gotoappicon appear-spacing"
                                      alt="Goto"
                                    />
                                  </a>
                                ) : (
                                  <Link
                                    to="#"
                                    onClick={(e) => this.removepopup()}
                                    className={
                                      styles.getstartedactive +
                                      " select-button-new select-button-new-workspace unlimited-btn"
                                    }
                                  >
                                    Get Started
                                  </Link>
                                )}
                                {/* <a href="#compare-list">
                                  <h4 className="card-foot-text card-foot-text-mobile unlimited-text">
                                    See all features
                                  </h4>
                                </a> */}
                              </div>
                            </div>
                          </div>

                          {/* Monthly */}
                          {this.state.filter === "monthly" &&
                            this.state.monthlydata.map((res, i) => (
                              <div
                                className={
                                  res.plan_name === "Agency Premium Yearly" ||
                                  res.plan_name === "Agency Premium Monthly" ||
                                  res.plan_name === "Agency Premium Lifetime"
                                    ? styles.summarydetail +
                                      " last-plan-card summary-detail-new summary-detail-new-mobile "
                                    : " summary-detail-new summary-detail-new-mobile " +
                                      styles.summarydetail
                                }
                                key={i}
                              >
                                {/* <h2 className="card-heading-first card-heading-first-mobile">
                                  {res.plan_name}
                                </h2> */}

                                <div className="d-flex align-item-center justify-content-between">
                                  <div>
                                    <h2
                                      className="card-heading-first card-heading-first-mobile"

                                      // {
                                      //   res.plan_name ===
                                      //   "Agency Starter Monthly"
                                      //     ? "card-heading-second card-heading-second-mobile"
                                      //     : res.plan_name ===
                                      //       "Agency Pro Monthly"
                                      //     ? "card-heading-third card-heading-third-mobile"
                                      //     : res.plan_name ===
                                      //       "Agency Premium Monthly"
                                      //     ? "card-heading-fourth card-heading-fourth-mobile"
                                      //     : ""
                                      // }
                                    >
                                      {res.plan}
                                    </h2>
                                  </div>
                                  <div className="plan-savings">
                                    <span className={styles.savetag}>
                                      {Object.keys(res.description.summary)[2]}
                                    </span>
                                  </div>
                                </div>

                                {/* <span className={styles.line}></span> */}
                                <div className="mt-4 device-icons">
                                  <img
                                    src={
                                      require("../assets/images/dashboard/device.png")
                                        .default
                                    }
                                    alt="de"
                                    className="mr-3"
                                  />
                                  {res.description.summary.Platforms.android ===
                                  1 ? (
                                    <img
                                      src={
                                        require("../assets/images/dashboard/android_active.png")
                                          .default
                                      }
                                      alt="de"
                                      className="mr-1"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("../assets/images/dashboard/android_inactive.png")
                                          .default
                                      }
                                      alt="de"
                                      className="mr-1"
                                    />
                                  )}
                                  {res.description.summary.Platforms.ios ===
                                  1 ? (
                                    <img
                                      src={
                                        require("../assets/images/dashboard/ios_active.png")
                                          .default
                                      }
                                      alt="de"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("../assets/images/dashboard/ios_inactive.png")
                                          .default
                                      }
                                      alt="de"
                                    />
                                  )}
                                </div>
                                <div className="plan-desc-data plan-desc-data-mobile">
                                  <ul className={styles.listing}>
                                    <p className={styles.summarypara}>
                                      {res.plan === "starter" ? (
                                        <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-first unlimited-list">
                                          <li>
                                            <strong>
                                              Everything in Preview, plus
                                            </strong>
                                          </li>
                                          <li>
                                            <strong>Unlimited</strong> Android
                                            Apps
                                          </li>
                                          <li>Android Push Notifications</li>
                                          <li>Google Firebase & AdMob</li>
                                          <li>Team Members</li>
                                        </ul>
                                      ) : res.plan === "pro" ? (
                                        <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-first unlimited-list">
                                          <li>
                                            <strong>
                                              Everything in Starter, plus
                                            </strong>
                                          </li>
                                          <li>
                                            <strong>Unlimited</strong> iOS App
                                          </li>
                                          <li>iOS Push Notifications</li>
                                          <li>Apple Login & Signup</li>
                                          <li>User Consent Screen</li>
                                        </ul>
                                      ) : res.plan === "premium" ? (
                                        <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-first unlimited-list">
                                          <li>
                                            <strong>
                                              Everything in Pro, plus
                                            </strong>
                                          </li>
                                          <li>Multisite & Merge Apps</li>
                                          {this.props.match.params.checker !==
                                            "2" && <li>Language Selection</li>}
                                          {parseInt(
                                            this.state.checker_web_technology
                                          ) === 1 && <li>App Analytics</li>}
                                          <li>Auto Publish</li>
                                        </ul>
                                      ) : (
                                        <></>
                                      )}
                                      {/* {Object.keys(res.description.summary)[1]} */}
                                    </p>
                                  </ul>
                                </div>
                                <div className="select-button-spacing-first select-button-repadding select-button-spacing-first-mobile">
                                  <div
                                    className={
                                      styles.listpricing +
                                      " plan-select-new-btn"
                                    }
                                  >
                                    <div className="select-btn-text">
                                      <h4 className="price-fonts">
                                        {res.package_currency.currency_symbol}
                                        {res.amount.replace(".00", " ")}{" "}
                                      </h4>

                                      <span>per month</span>
                                    </div>
                                    {res.is_subscribed === 1 ? (
                                      <Link
                                        to="#"
                                        className={
                                          styles.getstarted + " unlimitedselect"
                                        }
                                      >
                                        Current Plan
                                      </Link>
                                    ) : (
                                      //   res.plan_name.indexOf("Premium") > -1
                                      // 		?
                                      // 		<Link to="#" className={styles.getstarted}>Coming Soon</Link>
                                      // 		:

                                      <Link
                                        to={
                                          this.state.customer_type === 4
                                            ? "#"
                                            : dlink(
                                                res.plan_code,
                                                parseInt(res.amount),
                                                res.plan_name,
                                                res.package_currency
                                                  .currency_symbol,
                                                res.plan_code
                                              )
                                        }
                                        onClick={(e) =>
                                          this.handleWooCustomer(
                                            e,
                                            this.state.customer_type,
                                            res.plan_code,
                                            i
                                          )
                                        }
                                        className={
                                          styles.getstartedactive +
                                          " select-button-new select-button-new-workspace unlimited-btn"
                                        }
                                      >
                                        <div
                                          className={
                                            this.state.filter === "monthly"
                                              ? this.state["loader" + i]
                                              : "d-block"
                                          }
                                        >
                                          <img
                                            src={this.state.loaderimage}
                                            alt="loader"
                                          />
                                        </div>
                                        <div>
                                          {this.state["btnVal" + i]}
                                          {this.state.customer_type === 4 &&
                                          this.state["loader" + i] ===
                                            "d-none" ? (
                                            <img
                                              src={
                                                require("../assets/images/dashboard/Goto.png")
                                                  .default
                                              }
                                              className="gotoappicon appear-spacing"
                                              alt="Goto"
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </Link>
                                    )}
                                    {/* <a href="#compare-list">
                                      <h4 className="card-foot-text card-foot-text-mobile unlimited-text">
                                        See all features
                                      </h4>
                                    </a> */}
                                  </div>
                                </div>
                              </div>
                            ))}
                          {/* Yearly */}
                          {this.state.filter === "yearly" &&
                            this.state.yearlydata.map((res, i) => (
                              <div
                                className={
                                  res.plan_name === "Agency Premium Yearly" ||
                                  res.plan_name === "Agency Premium Monthly" ||
                                  res.plan_name === "Agency Premium Lifetime"
                                    ? styles.summarydetail +
                                      " last-plan-card summary-detail-new summary-detail-new-mobile "
                                    : " summary-detail-new summary-detail-new-mobile " +
                                      styles.summarydetail
                                }
                                key={i}
                              >
                                <div className="d-flex align-item-center justify-content-between">
                                  <div>
                                    <h2
                                      className={
                                        "card-heading-first card-heading-first-mobile"
                                      }
                                    >
                                      {res.plan}
                                    </h2>
                                    {/* <span
                                      className={
                                        styles.line + " card-underline"
                                      }
                                    ></span> */}
                                  </div>
                                  <div className="plan-savings">
                                    <span className={styles.savetag}>
                                   
                                      {Object.keys(res.description.summary)[2]}
                                    </span>
                                  </div>
                                </div>
                                <div className="mt-4 device-icons">
                                  <img
                                    src={
                                      require("../assets/images/dashboard/device.png")
                                        .default
                                    }
                                    alt="de"
                                    className="mr-3"
                                  />
                                  {res.description.summary.Platforms.android ===
                                  1 ? (
                                    <img
                                      src={
                                        require("../assets/images/dashboard/android_active.png")
                                          .default
                                      }
                                      alt="de"
                                      className="mr-1"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("../assets/images/dashboard/android_inactive.png")
                                          .default
                                      }
                                      alt="de"
                                      className="mr-1"
                                    />
                                  )}
                                  {res.description.summary.Platforms.ios ===
                                  1 ? (
                                    <img
                                      src={
                                        require("../assets/images/dashboard/ios_active.png")
                                          .default
                                      }
                                      alt="de"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("../assets/images/dashboard/ios_inactive.png")
                                          .default
                                      }
                                      alt="de"
                                    />
                                  )}
                                </div>
                                <div className="plan-desc-data plan-desc-data-mobile">
                                  <ul className={styles.listing}>
                                    <p className={styles.summarypara}>
                                      {/* {Object.keys(res.description.summary)[1]} */}
                                      {res.plan === "starter" ? (
                                        <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-first unlimited-list">
                                          <li>
                                            <strong>
                                              Everything in Preview, plus
                                            </strong>
                                          </li>
                                          <li>
                                            <strong>Unlimited</strong> Android
                                            Apps
                                          </li>
                                          <li>Android Push Notifications</li>
                                          <li>Google Firebase & AdMob</li>
                                          <li>Team Members</li>
                                        </ul>
                                      ) : res.plan === "pro" ? (
                                        <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-first unlimited-list">
                                          <li>
                                            <strong>
                                              Everything in Starter, plus
                                            </strong>
                                          </li>
                                          <li>
                                            <strong>Unlimited</strong> iOS App
                                          </li>
                                          <li>iOS Push Notifications</li>
                                          <li>Apple Login & Signup</li>
                                          <li>User Consent Screen</li>
                                        </ul>
                                      ) : res.plan === "premium" ? (
                                        <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-first unlimited-list">
                                          <li>
                                            <strong>
                                              Everything in Pro, plus
                                            </strong>
                                          </li>
                                          <li>Multisite & Merge Apps</li>
                                          {this.props.match.params.checker !==
                                            "2" && <li>Language Selection</li>}
                                          {parseInt(
                                            this.state.checker_web_technology
                                          ) === 1 && <li>App Analytics</li>}
                                          <li>Auto Publish</li>
                                          {/* <li>Priority Support</li> */}
                                        </ul>
                                      ) : (
                                        <></>
                                      )}
                                    </p>
                                  </ul>
                                </div>
                                <div
                                  className={
                                    "select-button-spacing-first select-button-repadding select-button-spacing-first-mobile"
                                  }
                                >
                                  <div
                                    className={
                                      styles.listpricing +
                                      " plan-select-new-btn"
                                    }
                                  >
                                    <div className="select-btn-text">
                                      <h4 className="price-fonts">
                                        {res.package_currency.currency_symbol}
                                        {res.amount.replace(".00", " ")}{" "}
                                      </h4>
                                      <span>per month paid yearly</span>
                                    </div>
                                    {res.is_subscribed === 1 ? (
                                      <Link
                                        to="#"
                                        className={
                                          styles.getstarted + " unlimitedselect"
                                        }
                                      >
                                        Current Plan
                                      </Link>
                                    ) : (
                                      //   res.plan_name.indexOf("Premium") > -1
                                      // 		?
                                      // 		<Link to="#" className={styles.getstarted}>Coming Soon</Link>
                                      // 		:
                                      <Link
                                        to={
                                          this.state.customer_type === 4
                                            ? "#"
                                            : dlink(
                                                res.plan_code,
                                                parseInt(res.amount),
                                                res.plan_name,
                                                res.package_currency
                                                  .currency_symbol,
                                                res.plan_code
                                              )
                                        }
                                        onClick={(e) =>
                                          this.handleWooCustomer(
                                            e,
                                            this.state.customer_type,
                                            res.plan_code,
                                            i
                                          )
                                        }
                                        className={
                                          styles.getstartedactive +
                                          " select-button-new select-button-new-workspace unlimited-btn"
                                        }
                                      >
                                        <div
                                          className={
                                            this.state.filter === "yearly"
                                              ? this.state["loader" + i]
                                              : "d-block"
                                          }
                                        >
                                          <img
                                            src={this.state.loaderimage}
                                            alt="loader"
                                          />
                                        </div>
                                        <div>
                                          {this.state["btnVal" + i]}{" "}
                                          {this.state.customer_type === 4 &&
                                          this.state["loader" + i] ===
                                            "d-none" ? (
                                            <img
                                              src={
                                                require("../assets/images/dashboard/Goto.png")
                                                  .default
                                              }
                                              className="gotoappicon appear-spacing"
                                              alt="Goto"
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </Link>
                                    )}
                                    {/* <a href="#compare-list">
                                      <h4 className="card-foot-text card-foot-text-mobile unlimited-text">
                                        See all features
                                      </h4>
                                    </a> */}
                                  </div>
                                </div>
                              </div>
                            ))}

                          {/* Lifetime */}

                          {this.state.filter === "lifetime" &&
                            this.state.lifetimedata.map((res, i) => (
                              <div
                                className={
                                  res.plan_name === "Agency Premium Yearly" ||
                                  res.plan_name === "Agency Premium Monthly" ||
                                  res.plan_name === "Agency Premium Lifetime"
                                    ? styles.summarydetail +
                                      " last-plan-card summary-detail-new summary-detail-new-mobile "
                                    : " summary-detail-new summary-detail-new-mobile " +
                                      styles.summarydetail
                                }
                                key={i}
                              >
                                <div className="d-flex align-item-center justify-content-between">
                                  <div>
                                    <h2
                                      className={
                                        res.plan_name ===
                                        "Agency Starter Lifetime"
                                          ? "card-heading-second card-heading-second-mobile"
                                          : res.plan_name ===
                                            "Agency Pro Lifetime"
                                          ? "card-heading-third card-heading-third-mobile"
                                          : res.plan_name ===
                                            "Agency Premium Lifetime"
                                          ? "card-heading-fourth card-heading-fourth-mobile"
                                          : ""
                                      }
                                    >
                                      {res.plan_name}
                                    </h2>
                                  </div>
                                  <div className="plan-savings">
                                    <span className={styles.savetag}>
                                      {Object.keys(res.description.summary)[2]}
                                    </span>
                                  </div>
                                </div>

                                <div className="mt-4 device-icons">
                                  <img
                                    src={
                                      require("../assets/images/dashboard/device.png")
                                        .default
                                    }
                                    alt="de"
                                    className="mr-3"
                                  />
                                  {res.description.summary.Platforms.android ===
                                  1 ? (
                                    <img
                                      src={
                                        require("../assets/images/dashboard/android_active.png")
                                          .default
                                      }
                                      alt="de"
                                      className="mr-1"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("../assets/images/dashboard/android_inactive.png")
                                          .default
                                      }
                                      alt="de"
                                      className="mr-1"
                                    />
                                  )}
                                  {res.description.summary.Platforms.ios ===
                                  1 ? (
                                    <img
                                      src={
                                        require("../assets/images/dashboard/ios_active.png")
                                          .default
                                      }
                                      alt="de"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("../assets/images/dashboard/ios_inactive.png")
                                          .default
                                      }
                                      alt="de"
                                    />
                                  )}
                                </div>
                                <div className="plan-desc-data plan-desc-data-mobile">
                                  <ul className={styles.listing}>
                                    <p className={styles.summarypara}>
                                      {Object.keys(res.description.summary)[1]}
                                    </p>
                                  </ul>
                                </div>
                                <div className="select-button-spacing-first select-button-spacing-first-mobile">
                                  <div
                                    className={
                                      styles.listpricing +
                                      " plan-select-new-btn"
                                    }
                                  >
                                    <div className="select-btn-text">
                                      <h4 className="price-fonts">
                                        {res.package_currency.currency_symbol}
                                        {res.amount.replace(".00", " ")}{" "}
                                      </h4>
                                      <span>one time payment</span>
                                    </div>
                                    {res.is_subscribed === 1 ? (
                                      <Link
                                        to="#"
                                        className={styles.getstarted}
                                      >
                                        Current Plan
                                      </Link>
                                    ) : (
                                      //   res.plan_name.indexOf("Premium") > -1
                                      // 		?
                                      // 		<Link to="#" className={styles.getstarted}>Coming Soon</Link>
                                      // 		:
                                      <Link
                                        to={dlink(
                                          res.plan_code,
                                          parseInt(res.amount),
                                          res.plan_name,
                                          res.package_currency.currency_symbol,
                                          res.plan_code
                                        )}
                                        className={
                                          styles.getstartedactive +
                                          " select-button-new"
                                        }
                                      >
                                        Get Started
                                      </Link>
                                    )}
                                    <h4 className="card-foot-text card-foot-text-mobile">
                                      See all features
                                    </h4>
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center see-comparison-btn">
                      <a
                        href="#compare-list"
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h4 className="card-foot-text">See full comparison</h4>
                        <img
                          src={
                            require("../assets/images/dashboard/see-comparison-goto.png")
                              .default
                          }
                        />
                      </a>
                    </div>
                  </div>
                  <div className="customer-reviews row row-spec common-container customer-reviews-mobile">
                    <div className="customer-review-text customer-review-text-mobile col-md-6 customer-review-pad">
                      <h3>Here’s what our customers are saying.</h3>
                      <p>
                        More than 200,000 customers love and trust AppMySite.
                        Partner with the best app builder software and reach
                        your business goals faster.
                      </p>
                      <div className="d-flex justify-content-start align-items-center customer-reviews-link">
                        <a
                          href="https://www.appmysite.com/reviews/"
                          className="d-flex justify-content-start align-items-center customer-reviews-link-main"
                          target="_blank"
                        >
                          <h4 className="card-foot-text">See all reviews</h4>
                          <img
                            src={
                              require("../assets/images/dashboard/customer-reviews-goto.png")
                                .default
                            }
                          />
                        </a>
                      </div>
                    </div>
                    <div className="customer-review-stars customer-review-shadow col-md-6">
                      <img
                        src={
                          require("../assets/images/dashboard/pop-ratings.png")
                            .default
                        }
                        className="pop-ratings-img d-md-none d-block"
                      />
                      <div className="review-section-first d-none d-md-block">
                        <div className="trustpilot-review trustpilot-review-mobile">
                          <img
                            className="trustpilot-img"
                            src={
                              require("../assets/images/dashboard/trustpilot-review.png")
                                .default
                            }
                          />
                        </div>
                        <div className="gplay-review gplay-review-mobile">
                          <img
                            className="gplay-img"
                            src={
                              require("../assets/images/dashboard/gplay-review.png")
                                .default
                            }
                          />
                        </div>
                      </div>
                      <div className="review-section-second d-none d-md-block">
                        <div className="capterra-review capterra-review-mobile">
                          <img
                            className="capterra-img"
                            src={
                              require("../assets/images/dashboard/capterra-review.png")
                                .default
                            }
                          />
                        </div>
                        <div className="wp-review wp-review-mobile">
                          <img
                            className="wp-img"
                            src={
                              require("../assets/images/dashboard/wp-review.png")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="awards-container">
                    {/* <h5>We have been recognized by G2</h5> */}
                    <a
                      rel="nofollow"
                      target="_blank"
                      href="https://www.g2.com/products/appmysite/reviews"
                    >
                      {" "}
                      <img
                        src={
                          require("../assets/images/dashboard/awards.png")
                            .default
                        }
                        // width="521px"
                        // height="145px"
                        className="d-none d-md-block"
                      />
                      <img
                        src={
                          require("../assets/images/dashboard/awards-mobile.png")
                            .default
                        }
                        // width="521px"
                        // height="145px"
                        className="d-block d-md-none"
                      />
                    </a>
                  </div>
                  <div className="featured-platforms featured-platforms-mobile">
                    {/* <h5>AppMySite has been featured on</h5> */}
                    <div className="featured-platforms-icons featured-platforms-icons-mobile">
                      {/* <div className="pf-group pf-group-left"> */}
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../assets/images/dashboard/wpengine-icon.png")
                              .default
                          }
                        />
                      </div>
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../assets/images/dashboard/wpexplorer-icon.png")
                              .default
                          }
                        />
                      </div>
                      {/* </div> */}
                      {/* <div className="pf-group pf-group-right"> */}
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../assets/images/dashboard/themeisle-icon.png")
                              .default
                          }
                        />
                      </div>
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../assets/images/dashboard/designrush-icon.png")
                              .default
                          }
                        />
                      </div>
                      {/* </div> */}
                      {/* <div className="pf-group pf-group-left"> */}
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../assets/images/dashboard/astra-icon.png")
                              .default
                          }
                        />
                      </div>
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../assets/images/dashboard/superbthemes-icon.png")
                              .default
                          }
                        />
                      </div>
                      {/* </div> */}
                      {/* <div className="pf-group pf-group-right"> */}
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../assets/images/dashboard/upcity-icon.png")
                              .default
                          }
                        />
                      </div>
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../assets/images/dashboard/cybernews-icon.png")
                              .default
                          }
                        />
                      </div>
                      {/* </div> */}
                      {/* <div className="last-pf-group-mobile"> */}
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../assets/images/dashboard/elegantthemes-icon.png")
                              .default
                          }
                        />
                      </div>
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../assets/images/dashboard/androidheadlines-icon.png")
                              .default
                          }
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  {/* Compare plan */}
                  <div className="compare-plan-main">
                    <div
                      className={
                        styles.widthset + " widthset-new widthset-new-mobile"
                      }
                    >
                      <div id="compare-list">
                        <div
                          className={styles.compareplan + " compare-plan-head"}
                        >
                          <h5>Compare Plans</h5>
                          <p className="compare-plan-subtext-red">
                            Compare our offering, plans and pricing. Pick one
                            that suits you the best.
                          </p>
                        </div>
                      </div>
                      {/* Mobile view */}
                      <div className="d-block d-md-none compare-plan-mobile-view">
                        <div className="plan-container">
                          <div className="tabbed">
                            <ul className={styles.mobilelist + " tabs"}>
                              <li
                                onClick={(e) =>
                                  this.handlemobilefilter("Preview")
                                }
                                className={
                                  this.state.mobilefilter === "Preview"
                                    ? styles.mobileactive + " preview-tab tab"
                                    : undefined
                                }
                              >
                                Preview
                              </li>
                              {this.state.filter === "monthly" &&
                                this.state.monthlydata.map((res, i) => (
                                  <li
                                    key={i}
                                    onClick={(e) =>
                                      this.handlemobilefilter(res.plan_name)
                                    }
                                    className={
                                      this.state.mobilefilter === res.plan_name
                                        ? styles.mobileactive +
                                          " starter-tab tab"
                                        : undefined
                                    }
                                  >
                                    {res.plan}
                                  </li>
                                ))}
                              {this.state.filter === "yearly" &&
                                this.state.yearlydata.map((res, i) => (
                                  <li
                                    key={i}
                                    onClick={(e) =>
                                      this.handlemobilefilter(res.plan_name)
                                    }
                                    className={
                                      this.state.mobilefilter === res.plan_name
                                        ? styles.mobileactive + " pro-tab tab"
                                        : undefined
                                    }
                                  >
                                    {res.plan}
                                  </li>
                                ))}
                              {this.state.filter === "lifetime" &&
                                this.state.lifetimedata.map((res, i) => (
                                  <li
                                    key={i}
                                    onClick={(e) =>
                                      this.handlemobilefilter(res.plan_name)
                                    }
                                    className={
                                      this.state.mobilefilter === res.plan_name
                                        ? styles.mobileactive +
                                          " premium-tab tab"
                                        : undefined
                                    }
                                  >
                                    {res.plan}
                                  </li>
                                ))}
                            </ul>
                            {this.state.tab_check === "Preview" && (
                              <div className="pricing-tab-content">
                                <div className="tab-plan-detail">
                                  <div className="pricesechead">
                                    <h6>Platforms</h6>
                                  </div>

                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Android</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        width="12px"
                                        height="12px"
                                        className="img-fluid"
                                        alt="cross"
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>iOS</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        width="12px"
                                        height="12px"
                                        className="img-fluid"
                                        alt="cross"
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="pricesechead">
                                    <h6>App Stores</h6>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Google Play Store</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        width="12px"
                                        height="12px"
                                        className="img-fluid"
                                        alt="cross"
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Apple App Store</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        width="12px"
                                        height="12px"
                                        className="img-fluid"
                                        alt="cross"
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="pricesechead">
                                    <h6>Integrations</h6>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Web View</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        width="16px"
                                        height="16px"
                                        className="img-fluid"
                                        alt="tick"
                                        src={
                                          require("../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Google Firebase</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Google Admob</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="pricesechead">
                                    <h6>Personalization</h6>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>App Icon Designer</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                        width="16px"
                                        height="16px"
                                        className="lazy img-fluid"
                                        alt="tick"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Launch Screen Designer</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                        width="16px"
                                        height="16px"
                                        className="lazy img-fluid"
                                        alt="tick"
                                      />
                                    </div>
                                  </div>
                                  {parseInt(
                                    localStorage.getItem("website_technology")
                                  ) === 1 && (
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Login Screen Designer</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {parseInt(
                                    localStorage.getItem("website_technology")
                                  ) === 1 && (
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Home Screen Builder</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Theme Customization</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                        width="16px"
                                        height="16px"
                                        className="lazy img-fluid"
                                        alt="tick"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>White Label App</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="pricesechead">
                                    <h6>User Access</h6>
                                  </div>

                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Guest Browsing</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                        width="16px"
                                        height="16px"
                                        className="lazy img-fluid"
                                        alt="tick"
                                      />
                                    </div>
                                  </div>

                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Google Login &amp; Signup</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Apple Login &amp; Signup</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>User Consent Screen</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="pricesechead">
                                    <h6>Notifications</h6>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Unlimited Notifications</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Schedule Notifications</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Device Segmentation</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="pricesechead">
                                    <h6>Team</h6>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Roles &amp; Permissions</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Team Members</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="pricesechead">
                                    <h6>Additional Features</h6>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Simulator</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                        width="16px"
                                        height="16px"
                                        className="lazy img-fluid"
                                        alt="tick"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>App Advertisement</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Merge apps</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      {this.props.match.params.checker !==
                                        "2" && <p>Language Selection</p>}
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      {this.props.match.params.checker !==
                                        "2" && <p>Right-to-left Support</p>}
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Customer Chat</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  {parseInt(
                                    localStorage.getItem("website_technology")
                                  ) === 1 && (
                                    <>
                                      <div className="pricesechead">
                                        <h6>Analytics</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Store Downloads</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Traffic Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Device Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Post Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Page Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Product Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  <div className="pricesechead">
                                    <h6>Auto Publish</h6>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Publish updates to Apple App Store</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>

                                  <div className="pricesechead">
                                    <h6>Support</h6>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Community</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                        width="16px"
                                        height="16px"
                                        className="lazy img-fluid"
                                        alt="tick"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Knowledge Base</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                        width="16px"
                                        height="16px"
                                        className="lazy img-fluid"
                                        alt="tick"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Limited Support</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                        width="16px"
                                        height="16px"
                                        className="lazy img-fluid"
                                        alt="tick"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {parseInt(
                              localStorage.getItem("webWorkspace_technology")
                            ) === 2
                              ? (this.state.tab_check ===
                                  "Web to App Starter Unlimited Monthly" ||
                                  this.state.tab_check ===
                                    "Web to App Starter Unlimited Yearly" ||
                                  this.state.tab_check ===
                                    "Web to App Starter  Lifetime") && (
                                  <div className="pricing-tab-content">
                                    <div className="tab-plan-detail">
                                      <div className="pricesechead">
                                        <h6>Platforms</h6>
                                      </div>

                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Android</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>iOS</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>App Stores</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Play Store</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Apple App Store</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Integrations</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Web View</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>

                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Firebase</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Admob</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Personalization</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Icon Designer</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Launch Screen Designer</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      {localStorage.getItem(
                                        "website_technology"
                                      ) === "1" && (
                                        <>
                                          <div className="allflex">
                                            <div className="pricemajor">
                                              <p>Login Screen Designer</p>
                                            </div>
                                            <div className="priceminor pricetick">
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/roundbluetick.png")
                                                    .default
                                                }
                                                width="16px"
                                                height="16px"
                                                className="lazy img-fluid"
                                                alt="tick"
                                              />
                                            </div>
                                          </div>
                                          <div className="allflex">
                                            <div className="pricemajor">
                                              <p>Home Screen Builder</p>
                                            </div>
                                            <div className="priceminor pricetick">
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/roundbluetick.png")
                                                    .default
                                                }
                                                width="16px"
                                                height="16px"
                                                className="lazy img-fluid"
                                                alt="tick"
                                              />
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Theme Customization</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>White Label App</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>User Access</h6>
                                      </div>

                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Guest Browsing</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>

                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Login &amp; Signup</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Apple Login &amp; Signup</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>User Consent Screen</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Push Notifications</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Unlimited Notifications</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Schedule Notifications</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Device Segmentation</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Team</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Roles &amp; Permissions</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Team Members</p>
                                        </div>
                                        <div className="priceminor team-census">
                                          {/* <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      /> */}
                                          2 users
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Additional Features</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Simulator</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Advertisement</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Merge apps</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          {this.props.match.params.checker !==
                                            "2" && <p>Language Selection</p>}
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          {this.props.match.params.checker !==
                                            "2" && <p>Right-to-left Support</p>}
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Customer Chat</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      {localStorage.getItem(
                                        "website_technology"
                                      ) === "1" && (
                                        <>
                                          <div className="pricesechead">
                                            <h6>Analytics</h6>
                                          </div>
                                          <div className="allflex">
                                            <div className="pricemajor">
                                              <p>App Store Downloads</p>
                                            </div>
                                            <div className="priceminor pricecross">
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/plancross.png")
                                                    .default
                                                }
                                                width="12px"
                                                height="12px"
                                                className="lazy img-fluid"
                                                alt="cross"
                                              />
                                            </div>
                                          </div>
                                          <div className="allflex">
                                            <div className="pricemajor">
                                              <p>Traffic Analytics</p>
                                            </div>
                                            <div className="priceminor pricecross">
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/plancross.png")
                                                    .default
                                                }
                                                width="12px"
                                                height="12px"
                                                className="lazy img-fluid"
                                                alt="cross"
                                              />
                                            </div>
                                          </div>
                                          <div className="allflex">
                                            <div className="pricemajor">
                                              <p>Device Analytics</p>
                                            </div>
                                            <div className="priceminor pricecross">
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/plancross.png")
                                                    .default
                                                }
                                                width="12px"
                                                height="12px"
                                                className="lazy img-fluid"
                                                alt="cross"
                                              />
                                            </div>
                                          </div>
                                          <div className="allflex">
                                            <div className="pricemajor">
                                              <p>Post Analytics</p>
                                            </div>
                                            <div className="priceminor pricecross">
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/plancross.png")
                                                    .default
                                                }
                                                width="12px"
                                                height="12px"
                                                className="lazy img-fluid"
                                                alt="cross"
                                              />
                                            </div>
                                          </div>
                                          <div className="allflex">
                                            <div className="pricemajor">
                                              <p>Page Analytics</p>
                                            </div>
                                            <div className="priceminor pricecross">
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/plancross.png")
                                                    .default
                                                }
                                                width="12px"
                                                height="12px"
                                                className="lazy img-fluid"
                                                alt="cross"
                                              />
                                            </div>
                                          </div>
                                          <div className="allflex">
                                            <div className="pricemajor">
                                              <p>Product Analytics</p>
                                            </div>
                                            <div className="priceminor pricecross">
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/plancross.png")
                                                    .default
                                                }
                                                width="12px"
                                                height="12px"
                                                className="lazy img-fluid"
                                                alt="cross"
                                              />
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      <div className="pricesechead">
                                        <h6>Auto Publish</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>
                                            Publish updates to Apple App Store
                                          </p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>

                                      <div className="pricesechead">
                                        <h6>Support</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Community</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Knowledge Base</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Limited Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              : (this.state.tab_check ===
                                  "WP Starter Unlimited Yearly" ||
                                  this.state.tab_check ===
                                    "WP Starter Unlimited Monthly" ||
                                  this.state.tab_check ===
                                    "WP Starter Unlimited Lifetime") && (
                                  <div className="pricing-tab-content">
                                    <div className="tab-plan-detail">
                                      <div className="pricesechead">
                                        <h6>Platforms</h6>
                                      </div>

                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Android</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>iOS</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>App Stores</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Play Store</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Apple App Store</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Integrations</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Web View</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>WordPress</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>WooCommerce</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Firebase</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Admob</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Personalization</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Icon Designer</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Launch Screen Designer</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Login Screen Designer</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Home Screen Builder</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Theme Customization</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>White Label App</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>

                                      <div className="pricesechead">
                                        <h6>WordPress</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p> Page Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Post Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>User Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Comment Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Custom Post Type Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Custom Taxonomy Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Multisite Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>

                                      <div className="pricesechead">
                                        <h6>WooCommere</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Product Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Currency Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Inventory Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Coupon Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Tax Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Shipping Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Payment Gateway Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Order Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Rating Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Review Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>User Access</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Manage User Login</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Guest Browsing</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>WordPress User Sync</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Login &amp; Signup</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Apple Login &amp; Signup</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>User Consent Screen</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Push Notifications</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Unlimited Notifications</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Schedule Notifications</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Device Segmentation</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Team</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Roles &amp; Permissions</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Team Members</p>
                                        </div>
                                        <div className="priceminor team-census">
                                          {/* <img
                                      src={
                                        require("../assets/images/dashboard/plancross.png")
                                          .default
                                      }
                                      width="12px"
                                      height="12px"
                                      className="lazy img-fluid"
                                      alt="cross"
                                    /> */}
                                          2 users
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Additional Features</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Simulator</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Advertisement</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Merge apps</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          {this.props.match.params.checker !==
                                            "2" && <p>Language Selection</p>}
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          {this.props.match.params.checker !==
                                            "2" && <p>Right-to-left Support</p>}
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Customer Chat</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Analytics</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Store Downloads</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Traffic Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Device Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Post Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Page Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Product Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Auto Publish</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>
                                            Publish updates to Apple App Store
                                          </p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>

                                      <div className="pricesechead">
                                        <h6>Support</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Community</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Knowledge Base</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Limited Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Priority Support</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            {parseInt(
                              localStorage.getItem("webWorkspace_technology")
                            ) === 2
                              ? (this.state.tab_check ===
                                  "Web to App Pro Unlimited Monthly" ||
                                  this.state.tab_check ===
                                    "Web to App Pro Unlimited Yearly" ||
                                  this.state.tab_check ===
                                    "Web to App Pro Unlimited Lifetime") && (
                                  <div className="pricing-tab-content">
                                    <div className="tab-plan-detail">
                                      <div className="pricesechead">
                                        <h6>Platforms</h6>
                                      </div>

                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Android</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>iOS</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>App Stores</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Play Store</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Apple App Store</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Integrations</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Web View</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>

                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Firebase</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Admob</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Personalization</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Icon Designer</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Launch Screen Designer</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      {localStorage.getItem(
                                        "website_technology"
                                      ) === "1" && (
                                        <>
                                          <div className="allflex">
                                            <div className="pricemajor">
                                              <p>Login Screen Designer</p>
                                            </div>
                                            <div className="priceminor pricetick">
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/roundbluetick.png")
                                                    .default
                                                }
                                                width="16px"
                                                height="16px"
                                                className="lazy img-fluid"
                                                alt="tick"
                                              />
                                            </div>
                                          </div>
                                          <div className="allflex">
                                            <div className="pricemajor">
                                              <p>Home Screen Builder</p>
                                            </div>
                                            <div className="priceminor pricetick">
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/roundbluetick.png")
                                                    .default
                                                }
                                                width="16px"
                                                height="16px"
                                                className="lazy img-fluid"
                                                alt="tick"
                                              />
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Theme Customization</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>White Label App</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>User Access</h6>
                                      </div>

                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Guest Browsing</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>

                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Login &amp; Signup</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Apple Login &amp; Signup</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>User Consent Screen</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Notifications</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Unlimited Notifications</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Schedule Notifications</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Device Segmentation</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Team</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Roles &amp; Permissions</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Team Members</p>
                                        </div>
                                        <div className="priceminor team-census">
                                          {/* <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      /> */}
                                          3 users
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Additional Features</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Simulator</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Advertisement</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Merge apps</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          {this.props.match.params.checker !==
                                            "2" && <p>Language Selection</p>}
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          {this.props.match.params.checker !==
                                            "2" && <p>Right-to-left Support</p>}
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Customer Chat</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      {localStorage.getItem(
                                        "website_technology"
                                      ) === "1" && (
                                        <>
                                          <div className="pricesechead">
                                            <h6>Analytics</h6>
                                          </div>
                                          <div className="allflex">
                                            <div className="pricemajor">
                                              <p>App Store Downloads</p>
                                            </div>
                                            <div className="priceminor pricecross">
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/plancross.png")
                                                    .default
                                                }
                                                width="12px"
                                                height="12px"
                                                className="lazy img-fluid"
                                                alt="cross"
                                              />
                                            </div>
                                          </div>
                                          <div className="allflex">
                                            <div className="pricemajor">
                                              <p>Traffic Analytics</p>
                                            </div>
                                            <div className="priceminor pricecross">
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/plancross.png")
                                                    .default
                                                }
                                                width="12px"
                                                height="12px"
                                                className="lazy img-fluid"
                                                alt="cross"
                                              />
                                            </div>
                                          </div>
                                          <div className="allflex">
                                            <div className="pricemajor">
                                              <p>Device Analytics</p>
                                            </div>
                                            <div className="priceminor pricecross">
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/plancross.png")
                                                    .default
                                                }
                                                width="12px"
                                                height="12px"
                                                className="lazy img-fluid"
                                                alt="cross"
                                              />
                                            </div>
                                          </div>
                                          <div className="allflex">
                                            <div className="pricemajor">
                                              <p>Post Analytics</p>
                                            </div>
                                            <div className="priceminor pricecross">
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/plancross.png")
                                                    .default
                                                }
                                                width="12px"
                                                height="12px"
                                                className="lazy img-fluid"
                                                alt="cross"
                                              />
                                            </div>
                                          </div>
                                          <div className="allflex">
                                            <div className="pricemajor">
                                              <p>Page Analytics</p>
                                            </div>
                                            <div className="priceminor pricecross">
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/plancross.png")
                                                    .default
                                                }
                                                width="12px"
                                                height="12px"
                                                className="lazy img-fluid"
                                                alt="cross"
                                              />
                                            </div>
                                          </div>
                                          <div className="allflex">
                                            <div className="pricemajor">
                                              <p>Product Analytics</p>
                                            </div>
                                            <div className="priceminor pricecross">
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/plancross.png")
                                                    .default
                                                }
                                                width="12px"
                                                height="12px"
                                                className="lazy img-fluid"
                                                alt="cross"
                                              />
                                            </div>
                                          </div>
                                        </>
                                      )}
                                      <div className="pricesechead">
                                        <h6>Auto Publish</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>
                                            Publish updates to Apple App Store
                                          </p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>

                                      <div className="pricesechead">
                                        <h6>Support</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Community</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Knowledge Base</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Limited Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              : (this.state.tab_check ===
                                  "WP Pro Unlimited Monthly" ||
                                  this.state.tab_check ===
                                    "WP Pro Unlimited Yearly" ||
                                  this.state.tab_check ===
                                    "WP Pro Unlimited Lifetime") && (
                                  <div className="pricing-tab-content">
                                    <div className="tab-plan-detail">
                                      <div className="pricesechead">
                                        <h6>Platforms</h6>
                                      </div>

                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Android</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>iOS</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>App Stores</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Play Store</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Apple App Store</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Integrations</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Web View</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>WordPress</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>WooCommerce</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Firebase</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Admob</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Personalization</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Icon Designer</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Launch Screen Designer</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Login Screen Designer</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Home Screen Builder</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Theme Customization</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>White Label App</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>

                                      <div className="pricesechead">
                                        <h6>WordPress</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p> Page Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Post Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>User Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Comment Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Custom Post Type Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Custom Taxonomy Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Multisite Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>

                                      <div className="pricesechead">
                                        <h6>WooCommere</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Product Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Currency Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Inventory Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Coupon Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Tax Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Shipping Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Payment Gateway Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Order Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Rating Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Review Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>User Access</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Manage User Login</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Guest Browsing</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>WordPress User Sync</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Login &amp; Signup</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Apple Login &amp; Signup</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>User Consent Screen</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Push Notifications</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Unlimited Notifications</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Schedule Notifications</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Device Segmentation</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Team</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Roles &amp; Permissions</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Team Members</p>
                                        </div>
                                        <div className="priceminor team-census">
                                          {/* <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      /> */}
                                          3 users
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Additional Features</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Simulator</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Advertisement</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Merge apps</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          {this.props.match.params.checker !==
                                            "2" && <p>Language Selection</p>}
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          {this.props.match.params.checker !==
                                            "2" && <p>Right-to-left Support</p>}
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Customer Chat</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Analytics</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Store Downloads</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Traffic Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Device Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Post Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Page Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Product Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Auto Publish</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>
                                            Publish updates to Apple App Store
                                          </p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>

                                      <div className="pricesechead">
                                        <h6>Support</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Community</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Knowledge Base</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Limited Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Priority Support</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            {parseInt(
                              localStorage.getItem("webWorkspace_technology")
                            ) === 2
                              ? (this.state.tab_check ===
                                  "Web to App Premium Unlimited Monthly" ||
                                  this.state.tab_check ===
                                    "Web to App Premium Unlimited Yearly" ||
                                  this.state.tab_check ===
                                    "Web to App Premium Unlimited Lifetime") && (
                                  <div className="pricing-tab-content">
                                    <div className="tab-plan-detail">
                                      <div className="pricesechead">
                                        <h6>Platforms</h6>
                                      </div>

                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Android</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>iOS</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>App Stores</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Play Store</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Apple App Store</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Integrations</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Web View</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>

                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Firebase</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Admob</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Personalization</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Icon Designer</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Launch Screen Designer</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>

                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Theme Customization</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>White Label App</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>User Access</h6>
                                      </div>

                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Guest Browsing</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>

                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Login &amp; Signup</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Apple Login &amp; Signup</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>User Consent Screen</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Notifications</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Unlimited Notifications</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Schedule Notifications</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Device Segmentation</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Team</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Roles &amp; Permissions</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Team Members</p>
                                        </div>
                                        <div className="priceminor team-census">
                                          {/* <img
                                        src={
                                          require("../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      /> */}
                                          5 users
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Additional Features</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Simulator</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Advertisement</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Merge apps</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          {this.props.match.params.checker !==
                                            "2" && <p>Language Selection</p>}
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          {this.props.match.params.checker !==
                                            "2" && <p>Right-to-left Support</p>}
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Customer Chat</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>

                                      <div className="pricesechead">
                                        <h6>Auto Publish</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>
                                            Publish updates to Apple App Store
                                          </p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>

                                      <div className="pricesechead">
                                        <h6>Support</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Community</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Knowledge Base</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Limited Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )
                              : (this.state.tab_check ===
                                  "WP Premium Unlimited Monthly" ||
                                  this.state.tab_check ===
                                    "WP Premium Unlimited Yearly" ||
                                  this.state.tab_check ===
                                    "WP Premium Unlimited Lifetime") && (
                                  <div className="pricing-tab-content">
                                    <div className="tab-plan-detail">
                                      <div className="pricesechead">
                                        <h6>Platforms</h6>
                                      </div>

                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Android</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>iOS</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>App Stores</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Play Store</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Apple App Store</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Integrations</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Web View</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>WordPress</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>WooCommerce</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Firebase</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Admob</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Personalization</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Icon Designer</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Launch Screen Designer</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Login Screen Designer</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Home Screen Builder</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Theme Customization</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>White Label App</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>

                                      <div className="pricesechead">
                                        <h6>WordPress</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p> Page Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Post Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>User Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Comment Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Custom Post Type Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Custom Taxonomy Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Multisite Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>

                                      <div className="pricesechead">
                                        <h6>WooCommere</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Product Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Currency Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Inventory Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Coupon Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Tax Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Shipping Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Payment Gateway Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Order Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Rating Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Review Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>User Access</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Manage User Login</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Guest Browsing</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>WordPress User Sync</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Google Login &amp; Signup</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Apple Login &amp; Signup</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>User Consent Screen</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Push Notifications</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Unlimited Notifications</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Schedule Notifications</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Device Segmentation</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Team</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Roles &amp; Permissions</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Team Members</p>
                                        </div>
                                        <div className="priceminor team-census">
                                          {/* <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                    width="12px"
                                    height="12px"
                                    className="lazy img-fluid"
                                    alt="cross"
                                  /> */}
                                          5 users
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Additional Features</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Simulator</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Advertisement</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Merge apps</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          {this.props.match.params.checker !==
                                            "2" && <p>Language Selection</p>}
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          {this.props.match.params.checker !==
                                            "2" && <p>Right-to-left Support</p>}
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Customer Chat</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Analytics</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Store Downloads</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Traffic Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Device Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Post Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Page Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Product Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Auto Publish</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>
                                            Publish updates to Apple App Store
                                          </p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>

                                      <div className="pricesechead">
                                        <h6>Support</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Community</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Knowledge Base</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Limited Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Priority Support</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                          </div>
                        </div>
                      </div>
                      {/* Mobile view end */}
                      <table className="table compare-plan-container compare-plan-container-mobile">
                        <thead className="head-sub-mobile">
                          <tr>
                            <th scope="col" className="top-plan-row"></th>
                            <th
                              scope="col"
                              className="top-plan-row row-spec-red"
                            >
                              Preview
                            </th>
                            <th
                              scope="col"
                              className="top-plan-row row-spec-red"
                            >
                              Starter
                            </th>
                            <th
                              scope="col"
                              className="top-plan-row row-spec-red"
                            >
                              Pro
                            </th>
                            <th
                              scope="col"
                              className="top-plan-row row-spec-red"
                            >
                              Premium
                            </th>
                          </tr>
                        </thead>
                        <tbody className="comparison-table">
                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              Platforms
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>

                          <tr>
                            <th scope="row">Android</th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">iOS</th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              App Stores
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="row-info-borderless">
                            <th scope="row">Google Play Store</th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Apple App Store</th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              Integrations
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>

                          <tr className="row-info-borderless">
                            <th scope="row">Web View </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>

                          {parseInt(
                            localStorage.getItem("webWorkspace_technology")
                          ) === 1 && (
                            <tr>
                              <th scope="row">WordPress </th>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                            </tr>
                          )}
                          {parseInt(
                            localStorage.getItem("webWorkspace_technology")
                          ) === 1 && (
                            <tr>
                              <th scope="row">WooCommerce </th>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th scope="row">Google Firebase </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Google Admob </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          {localStorage.getItem("webWorkspace_technology") ===
                            "1" && (
                            <>
                              <tr className="row-info-main row-info-borderless">
                                <th scope="row" className="row-spec-red">
                                  WordPress
                                </th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr className="row-info-borderless">
                                <th scope="row">Page Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Post Support </th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">User Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Comment Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Custom Post Type Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Custom Taxonomy Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Multisite Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr className="row-info-main row-info-borderless">
                                <th scope="row" className="row-spec-red">
                                  WooCommerce
                                </th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr className="row-info-borderless">
                                <th scope="row">Product Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Currency Support </th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Inventory Support </th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Coupon Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Tax Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Shipping Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Payment Gateway Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Order Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Rating Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Review Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>
                            </>
                          )}
                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              Personalization
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="row-info-borderless">
                            <th scope="row">App Icon Designer </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Launch Screen Designer </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          {parseInt(
                            localStorage.getItem("webWorkspace_technology")
                          ) === 1 && (
                            <tr>
                              <th scope="row">Login Screen Designer </th>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                            </tr>
                          )}
                          {parseInt(
                            localStorage.getItem("webWorkspace_technology")
                          ) === 1 && (
                            <tr>
                              <th scope="row">Home Screen Builder </th>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th scope="row">Theme Customization </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">White Label App </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr className="row-info-main row-info-borderless">
                            <th scope="row">User Access</th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          {parseInt(
                            localStorage.getItem("webWorkspace_technology")
                          ) === 1 && (
                            <tr className="row-info-borderless">
                              <th scope="row">Manage User Login </th>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th scope="row">Guest Browsing </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          {parseInt(
                            localStorage.getItem("webWorkspace_technology")
                          ) === 1 && (
                            <tr>
                              <th scope="row">WordPress User Sync </th>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                            </tr>
                          )}

                          <tr>
                            <th scope="row">Google Login & Signup </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Apple Login & Signup </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">User Consent Screen </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              Push Notifications
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="row-info-borderless">
                            <th scope="row">Unlimited Notifications </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Schedule Notifications </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Device Segmentation </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              Team{" "}
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="row-info-borderless">
                            <th scope="row">Roles & Permissions </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Team Members </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>2 users</td>
                            <td>3 users</td>
                            <td>5 users</td>
                          </tr>
                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              Additional Features{" "}
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>

                          <tr className="row-info-borderless">
                            <th scope="row">Simulator</th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">App Advertisement </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Merge apps </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          {this.props.match.params.checker !== "2" && (
                            <tr>
                              <th scope="row">Language Selection</th>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/plancross.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/plancross.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/plancross.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                            </tr>
                          )}

                          {this.props.match.params.checker !== "2" && (
                            <tr>
                              <th scope="row">Right-to-left Support </th>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/plancross.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/plancross.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/plancross.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                            </tr>
                          )}

                          <tr>
                            <th scope="row">Customer Chat</th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>

                          {localStorage.getItem("webWorkspace_technology") ===
                            "1" && (
                            <>
                              <tr className="row-info-main row-info-borderless">
                                <th scope="row" className="row-spec-red">
                                  Analytics{" "}
                                </th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr className="row-info-borderless">
                                <th scope="row">App Store Downloads</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Traffic Analytics</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Device Analytics</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Post Analytics</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Page Analytics</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Product Analytics</th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>
                            </>
                          )}

                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              Auto Publish{" "}
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="row-info-borderless">
                            <th scope="row">
                              Publish updates to Apple App Store{" "}
                            </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>

                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              Support{" "}
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="row-info-borderless">
                            <th scope="row">Community </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Knowledge Base </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Limited Support </th>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>

                          {localStorage.getItem("webWorkspace_technology") ===
                            "1" && (
                            <>
                              <tr>
                                <th scope="row">Priority Support </th>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>
                            </>
                          )}

                          <tr className="last-plan-row">
                            <th scope="row"></th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <div className={styles.tableview}>
                        {this.state.one_time
                          .filter((res) =>
                            window.matchMedia("(max-width: 700px)").matches
                              ? res.plan_name === this.state.mobilefilter
                              : res
                          )
                          .map((res, i) => (
                            <div className={styles.tableiteam} key={i}>
                              <div
                                className={
                                  styles.tableheading + " d-none d-md-block"
                                }
                              >
                                <h6>{res.plan_name}</h6>
                              </div>
                              {Object.keys(res.description.features).map(
                                (list, k) => (
                                  <div className={styles.tablelist} key={k}>
                                    <div>
                                      <h5>{list}</h5>
                                      <ul className={styles.listing}>
                                        {Object.keys(
                                          res.description.features[list]
                                        ).map((response, j) => (
                                          <li
                                            key={j}
                                            style={{
                                              color:
                                                res.description.features[list][
                                                  response
                                                ] === 1
                                                  ? "#000000"
                                                  : "#B0B8C9",
                                            }}
                                          >
                                            {res.description.features[list][
                                              response
                                            ] === 1 ? (
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/active_list.png")
                                                    .default
                                                }
                                                alt="de"
                                                className="mr-1"
                                              />
                                            ) : (
                                              <img
                                                src={
                                                  require("../assets/images/dashboard/notlist.png")
                                                    .default
                                                }
                                                alt="de"
                                                className="mr-1 pr-1"
                                              />
                                            )}

                                            {response}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                )
                              )}
                              <div className={styles.pricelst}>
                                <div className={styles.listpricing}>
                                  <div className="d-flex align-items-center">
                                    <h4>
                                      {res.package_currency.currency_symbol}
                                      {res.amount.replace(".00", " ")}{" "}
                                    </h4>
                                    {this.state.filter === "monthly" ? (
                                      <span>per month</span>
                                    ) : this.state.filter === "yearly" ? (
                                      <span>per month paid yearly</span>
                                    ) : (
                                      <span>one time payment</span>
                                    )}
                                  </div>
                                  {res.is_subscribed === 1 ? (
                                    <Link to="#" className={styles.getstarted}>
                                      Current Plan
                                    </Link>
                                  ) : ( */}
                      {/* // res.plan_name.indexOf("Premium") > -1
                                    // ?
                                    // <Link to="#" className={styles.getstarted}>Coming Soon</Link>
                                    // : */}
                      {/* <Link
                                      to="#"
                                      onClick={(e) => this.removepopup()}
                                      className={styles.getstartedactive}
                                    >
                                      Get Started
                                    </Link>
                                  )} */}
                      {/* </div>
                              </div>
                            </div>
                          ))} */}
                      {/* Monthly */}
                      {/* {this.state.filter === "monthly" &&
                          this.state.monthlydata
                            .filter((res) =>
                              window.matchMedia("(max-width: 700px)").matches
                                ? res.plan_name === this.state.mobilefilter
                                : res
                            )
                            .map((res, i) => (
                              <div className={styles.tableiteam} key={i}>
                                <div
                                  className={
                                    styles.tableheading + " d-none d-md-block"
                                  }
                                >
                                  <h6>{res.plan_name}</h6>
                                </div>
                                {Object.keys(res.description.features).map(
                                  (list, k) => (
                                    <div className={styles.tablelist} key={k}>
                                      <div>
                                        <h5>{list}</h5>
                                        <ul className={styles.listing}>
                                          {Object.keys(
                                            res.description.features[list]
                                          ).map((response, j) => (
                                            <li
                                              key={j}
                                              style={{
                                                color:
                                                  res.description.features[
                                                    list
                                                  ][response] === 1
                                                    ? "#000000"
                                                    : "#B0B8C9",
                                              }}
                                            >
                                              {res.description.features[list][
                                                response
                                              ] === 1 ? (
                                                <img
                                                  src={
                                                    require("../assets/images/dashboard/active_list.png")
                                                      .default
                                                  }
                                                  alt="de"
                                                  className="mr-1"
                                                />
                                              ) : (
                                                <img
                                                  src={
                                                    require("../assets/images/dashboard/notlist.png")
                                                      .default
                                                  }
                                                  alt="de"
                                                  className="mr-1 pr-1"
                                                />
                                              )}

                                              {response}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  )
                                )}
                                <div className={styles.pricelst}>
                                  <div className={styles.listpricing}>
                                    <div className="d-flex  align-items-center">
                                      <h4>
                                        {res.package_currency.currency_symbol}
                                        {res.amount.replace(".00", " ")}{" "}
                                      </h4>
                                      <span>per month</span>
                                    </div>

                                    {res.is_subscribed === 1 ? (
                                      <Link
                                        to="#"
                                        className={styles.getstarted}
                                      >
                                        Current Plan
                                      </Link>
                                    ) : ( */}
                      {/* // res.plan_name.indexOf("Premium") > -1
                                      // ?
                                      // <Link to="#" className={styles.getstarted}>Coming Soon</Link>
                                      // : */}
                      {/* <Link
                                        to={dlink(
                                          res.plan_code,
                                          parseInt(res.amount),
                                          res.plan_name,
                                          res.package_currency.currency_symbol,
                                          res.plan_code
                                        )}
                                        className={styles.getstartedactive}
                                      >
                                        Get Started
                                      </Link>
                                    )} */}
                      {/* </div>
                                </div>
                              </div>
                            ))} */}
                      {/* Yearly */}
                      {/* {this.state.filter === "yearly" &&
                          this.state.yearlydata
                            .filter((res) =>
                              window.matchMedia("(max-width: 700px)").matches
                                ? res.plan_name === this.state.mobilefilter
                                : res
                            )
                            .map((res, i) => (
                              <>
                                <div className={styles.tableiteam} key={i}>
                                  <div
                                    className={
                                      styles.tableheading + " d-none d-md-block"
                                    }
                                  >
                                    <h6>{res.plan_name}</h6>
                                  </div>
                                  {Object.keys(res.description.features).map(
                                    (list, k) => (
                                      <div className={styles.tablelist} key={k}>
                                        <div>
                                          <h5>{list}</h5>
                                          <ul className={styles.listing}>
                                            {Object.keys(
                                              res.description.features[list]
                                            ).map((response, j) => (
                                              <li
                                                key={j}
                                                style={{
                                                  color:
                                                    res.description.features[
                                                      list
                                                    ][response] === 1
                                                      ? "#000000"
                                                      : "#B0B8C9",
                                                }}
                                              >
                                                {res.description.features[list][
                                                  response
                                                ] === 1 ? (
                                                  <img
                                                    src={
                                                      require("../assets/images/dashboard/active_list.png")
                                                        .default
                                                    }
                                                    alt="de"
                                                    className="mr-1"
                                                  />
                                                ) : (
                                                  <img
                                                    src={
                                                      require("../assets/images/dashboard/notlist.png")
                                                        .default
                                                    }
                                                    alt="de"
                                                    className="mr-1 pr-1"
                                                  />
                                                )}

                                                {response}
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  )}
                                  <div className={styles.pricelst}>
                                    <div className={styles.listpricing}>
                                      <div className="d-flex align-items-center">
                                        <h4>
                                          {res.package_currency.currency_symbol}
                                          {res.amount.replace(".00", " ")}{" "}
                                        </h4>
                                        <span>per month paid yearly</span>
                                      </div>
                                      {res.is_subscribed === 1 ? (
                                        <Link
                                          to="#"
                                          className={styles.getstarted}
                                        >
                                          Current Plan
                                        </Link>
                                      ) : ( */}
                      {/* // res.plan_name.indexOf("Premium") > -1
                                        // ?
                                        // <Link to="#" className={styles.getstarted}>Coming Soon</Link>
                                        // : */}
                      {/* <Link
                                          to={dlink(
                                            res.plan_code,
                                            parseInt(res.amount),
                                            res.plan_name,
                                            res.package_currency
                                              .currency_symbol,
                                            res.plan_code
                                          )}
                                          className={styles.getstartedactive}
                                        >
                                          Get Started
                                        </Link>
                                      )} */}
                      {/* </div>
                                  </div>
                                </div>
                              </>
                            ))} */}
                      {/* Life Time*/}
                      {/* {this.state.filter === "lifetime" &&
                          this.state.lifetimedata
                            .filter((res) =>
                              window.matchMedia("(max-width: 700px)").matches
                                ? res.plan_name === this.state.mobilefilter
                                : res
                            )
                            .map((res, i) => (
                              <>
                                <div className={styles.tableiteam} key={i}>
                                  <div
                                    className={
                                      styles.tableheading + " d-none d-md-block"
                                    }
                                  >
                                    <h6>{res.plan_name}</h6>
                                  </div>
                                  {Object.keys(res.description.features).map(
                                    (list, k) => (
                                      <div className={styles.tablelist} key={k}>
                                        <div>
                                          <h5>{list}</h5>
                                          <ul className={styles.listing}>
                                            {Object.keys(
                                              res.description.features[list]
                                            ).map((response, j) => (
                                              <li
                                                key={j}
                                                style={{
                                                  color:
                                                    res.description.features[
                                                      list
                                                    ][response] === 1
                                                      ? "#000000"
                                                      : "#B0B8C9",
                                                }}
                                              >
                                                {res.description.features[list][
                                                  response
                                                ] === 1 ? (
                                                  <img
                                                    src={
                                                      require("../assets/images/dashboard/active_list.png")
                                                        .default
                                                    }
                                                    alt="de"
                                                    className="mr-1"
                                                  />
                                                ) : (
                                                  <img
                                                    src={
                                                      require("../assets/images/dashboard/notlist.png")
                                                        .default
                                                    }
                                                    alt="de"
                                                    className="mr-1 pr-1"
                                                  />
                                                )}

                                                {response}
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  )}
                                  <div className={styles.pricelst}>
                                    <div className={styles.listpricing}>
                                      <div className="d-flex  align-items-center">
                                        <h4>
                                          {res.package_currency.currency_symbol}
                                          {res.amount.replace(".00", " ")}
                                        </h4>
                                        <span>one time payment</span>
                                      </div>

                                      {res.is_subscribed === 1 ? (
                                        <Link
                                          to="#"
                                          className={styles.getstarted}
                                        >
                                          Current Plan
                                        </Link>
                                      ) : ( */}
                      {/* // res.plan_name.indexOf("Premium") > -1
                                        // ?
                                        // <Link to="#" className={styles.getstarted}>Coming Soon</Link>
                                        // : */}
                      {/* <Link
                                          to={dlink(
                                            res.plan_code,
                                            parseInt(res.amount),
                                            res.plan_name,
                                            res.package_currency
                                              .currency_symbol,
                                            res.plan_code
                                          )}
                                          className={styles.getstartedactive}
                                        >
                                          Get Started
                                        </Link>
                                      )} */}
                      {/* </div>
                                  </div>
                                </div>
                              </>
                            ))} */}
                      {/* </div> */}
                      {/* <div className={styles.note}>
                   <p>*This feature is work in progress and is launching soon to single star only</p>
				</div> */}
                    </div>
                  </div>
                  {/* Compare plan end*/}

                  {/* Testimonial */}
                  {/* <div className={styles.setbackground + " mt-5"}>
                    <div className={styles.widthset}>
                      <div className={styles.testimonial}>
                        <div className={styles.firstleft}>
                          <h6>100,000+ customers love AppMySite</h6>

                          <p>Reviews on popular platforms:</p>
                          <div>
                            <div className="d-md-flex d-block mb-md-5 mt-4">
                              <div
                                className={
                                  "d-flex w-md-50 w-100 " + styles.mobilerating
                                }
                              >
                                <div className="d-flex align-items-center mr-3 ">
                                  <img
                                    src={
                                      require("../assets/images/dashboard/testimonial/teststar.png")
                                        .default
                                    }
                                    alt="de"
                                    className="mr-1"
                                  />
                                  <p className="m-0 p-0">4.7</p>
                                </div>
                                <div>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/testimonial/trust.png")
                                        .default
                                    }
                                    alt="de"
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <div
                                className={
                                  "d-flex w-md-50 w-100  " +
                                  styles.mobileratingget
                                }
                              >
                                <div className="d-flex align-items-center mr-3">
                                  <img
                                    src={
                                      require("../assets/images/dashboard/testimonial/teststar.png")
                                        .default
                                    }
                                    alt="de"
                                    className="mr-1"
                                  />
                                  <p className="m-0 p-0">4.7</p>
                                </div>
                                <div>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/testimonial/googlepay.png")
                                        .default
                                    }
                                    alt="de"
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="d-md-flex d-block ">
                              <div
                                className={
                                  "d-flex w-md-50 w-100 " + styles.mobilerating
                                }
                              >
                                <div className="d-flex align-items-center mr-3">
                                  <img
                                    src={
                                      require("../assets/images/dashboard/testimonial/teststar.png")
                                        .default
                                    }
                                    alt="de"
                                    className="mr-1"
                                  />
                                  <p className="m-0 p-0">4.7</p>
                                </div>
                                <div>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/testimonial/capterra.png")
                                        .default
                                    }
                                    alt="de"
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <div
                                className={
                                  "d-flex w-md-50 w-100  " +
                                  styles.mobileratingget
                                }
                              >
                                <div className="d-flex align-items-center mr-3">
                                  <img
                                    src={
                                      require("../assets/images/dashboard/testimonial/teststar.png")
                                        .default
                                    }
                                    alt="de"
                                    className="mr-1"
                                  />
                                  <p className="m-0 p-0">4.7</p>
                                </div>
                                <div>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/testimonial/wordpress.png")
                                        .default
                                    }
                                    alt="de"
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.secondhalf}>
                          <div
                            className={
                              "d-flex justify-content-between " + styles.testmob
                            }
                          >
                            <div className={styles.first}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      require("../assets/images/dashboard/testimonial/denil.png")
                                        .default
                                    }
                                    alt="de"
                                    width="80"
                                    height="80"
                                  />
                                  <div className="ml-3">
                                    <h6 className="m-0 p-0">Dave Steel</h6>
                                    <p className="m-0 p-0">Mar 15, 2019</p>
                                  </div>
                                </div>
                                <div>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/testimonial/quote.png")
                                        .default
                                    }
                                    alt="de"
                                    width="45"
                                    height="38"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 ">
                                <img
                                  src={
                                    require("../assets/images/dashboard/testimonial/fivestar.png")
                                      .default
                                  }
                                  alt="de"
                                  className="mb-4"
                                />
                                <p>
                                  I was wondering how to turn my website into an
                                  app and found a solution with AppMySite. Not
                                  only was I able to convert my e-commerce
                                  website into a full-blown app within minutes
                                  but also publish them instantly on App Store
                                  and Play Store. Looking forward to creating
                                  more apps using this amazing tool!
                                </p>
                              </div>
                            </div>
                            <div className={styles.first}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      require("../assets/images/dashboard/testimonial/hal.png")
                                        .default
                                    }
                                    alt="de"
                                    width="80"
                                    height="80"
                                  />
                                  <div className="ml-3">
                                    <h6 className="m-0 p-0">Hallie Parez</h6>
                                    <p className="m-0 p-0">Jan 21, 2019</p>
                                  </div>
                                </div>
                                <div>
                                  <img
                                    src={
                                      require("../assets/images/dashboard/testimonial/quote.png")
                                        .default
                                    }
                                    alt="de"
                                    width="45"
                                    height="38"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 ">
                                <img
                                  src={
                                    require("../assets/images/dashboard/testimonial/fivestar.png")
                                      .default
                                  }
                                  alt="de"
                                  className="mb-4"
                                />
                                <p>
                                  I used AppMySite app builder create an app all
                                  by myself. I have very limited technical
                                  knowledge but their platform is extremely
                                  user-friendly. I built and published an
                                  awesome Android and iOS app without any coding
                                  for my clothing website. Definitely recommend
                                  it!
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* Testimonial end */}
                  <div className="faq-container faq-container-mobile">
                    {/* <div className={styles.widthset}> */}
                    <div className="faq mb-5 faq-bottompadding faq-bottom-mobile">
                      <h2>Frequently Asked Questions</h2>

                      <div className="accordion">
                        {parseInt(
                          localStorage.getItem("webWorkspace_technology")
                        ) === 1
                          ? accordionData
                              .slice(0, 12)
                              .map(({ title, content }) => (
                                <Accordion title={title} content={content} />
                              ))
                          : accordionData2
                              .slice(0, 8)
                              .map(({ title, content }) => (
                                <Accordion title={title} content={content} />
                              ))}
                      </div>

                      <div className="see-more-faq see-more-faq-mobile">
                        <div className="accordion-item-new moretext">
                          {parseInt(
                            localStorage.getItem("webWorkspace_technology")
                          ) === 1
                            ? accordionData
                                .slice(12, 25)
                                .map(({ title, content }) => (
                                  <Accordion title={title} content={content} />
                                ))
                            : accordionData2
                                .slice(8, 17)
                                .map(({ title, content }) => (
                                  <Accordion title={title} content={content} />
                                ))}
                        </div>
                        <p
                          className="see-more-btn"
                          onClick={(e) => this.extend(e)}
                        >
                          See More
                        </p>
                      </div>
                      <div class="haveidea haveidea-redesign allflex">
                        <h4 class="sectionheadings">Have other questions?</h4>
                        <a
                          target="_blank"
                          href="/get-in-touch/"
                          className="d-flex justify-content-between align-items-center contact-us-planlist"
                        >
                          <h5>Contact Us </h5>
                          <svg
                            width="12.894"
                            height="11.061"
                            viewBox="0 0 12.894 11.061"
                            className="contact-us-svg"
                          >
                            <g
                              id="Group_5530"
                              data-name="Group 5530"
                              transform="translate(12 0.53) rotate(90)"
                            >
                              <path
                                id="Path_9581"
                                data-name="Path 9581"
                                d="M0,5,5,0l5,5"
                                transform="translate(0 0.167)"
                                fill="none"
                                stroke="#3064f9"
                                stroke-width="1.5"
                              ></path>
                              <path
                                id="Path_9586"
                                data-name="Path 9586"
                                d="M0,0V12"
                                transform="translate(5)"
                                fill="none"
                                stroke="#3064f9"
                                stroke-width="1.5"
                              ></path>
                            </g>
                          </svg>
                        </a>
                      </div>
                      <div className="plans-new-cta plans-new-cta-mobile d-none">
                        <h1>Have other questions?</h1>
                        <button className="btn btn-outline-light plans-cta-contact-btn plans-cta-contact-btn-mobile">
                          Contact Us{" "}
                          <img
                            src={
                              require("../assets/images/dashboard/deals-arrow.png")
                                .default
                            }
                            className="plans-arrow-img"
                          />
                        </button>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>

              {/* Remove Addon Popup */}
              <Modal
                isOpen={this.state.deluser}
                centered
                toggle={(e) => this.removepopup(e)}
                style={{ maxWidth: "506px", maxHeight: "247px" }}
                className={this.props.className}
                dataclassName="addonpopup"
              >
                <ModalHeader
                  toggle={(e) => this.removepopup(e)}
                  className="modal-xxl-content confirmpopup"
                >
                  <p>Confirm</p>
                  <div
                    className="crossmodal mt-1"
                    onClick={(e) => this.removepopup(e)}
                  ></div>
                </ModalHeader>
                <ModalBody className="">
                  <div>
                    <div className="d-flex mt-2 mb-3">
                      <div className="alertconfirm">
                        <svg width="52" height="44" viewBox="0 0 52 44">
                          <g
                            id="Group_3260"
                            data-name="Group 3260"
                            transform="translate(-748 -456)"
                          >
                            <path
                              id="Polygon_11"
                              data-name="Polygon 11"
                              d="M23.417,4.371a3,3,0,0,1,5.166,0l20.743,35.1A3,3,0,0,1,46.743,44H5.257a3,3,0,0,1-2.583-4.526Z"
                              transform="translate(748 456)"
                              fill="#c8cedb"
                            />
                            <path
                              id="Path_6764"
                              data-name="Path 6764"
                              d="M-.712-.1V13.655"
                              transform="translate(774.5 472.5)"
                              fill="none"
                              stroke="#fff"
                              strokeWidth="3"
                            />
                            <path
                              id="Path_6765"
                              data-name="Path 6765"
                              d="M-.712-.1V3.538"
                              transform="translate(774.5 489.973)"
                              fill="none"
                              stroke="#fff"
                              strokeWidth="3"
                            />
                          </g>
                        </svg>
                      </div>
                      <div className="confirmtext">
                        <h6>
                          Are you sure you want to downgrade to the preview
                          plan?{" "}
                        </h6>
                        <h6>
                          Your plan subscription and all recurring add-on
                          subscriptions will be canceled.{" "}
                        </h6>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end finalselection">
                      <div onClick={(e) => this.removepopup()}>
                        <p>No</p>
                      </div>

                      <div
                        onClick={(e) =>
                          this.state.customer_type === 4
                            ? this.handleWooCustomer(
                                e,
                                this.state.customer_type,
                                this.state.one_time[0].plan_code
                              )
                            : this.previewdowngrade(e)
                        }
                      >
                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                          <p>
                            <div className={this.state.loader_remove}>
                              <img
                                src={this.state.loaderimage_remove}
                                alt="loader"
                              />
                            </div>
                            <div>{this.state.submitval_confirmpop}</div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </>
          )}
          {/* {Foot("pricing-bottomspacing")} */}
        </section>
      </>
    );
  }
}
