import React, { useState } from 'react'
import SupportHeader from './SupportHeader'
import SupportCard from './SupportCard';
import Newfeatureicon from '../../commonIcons/Newfeatureicon';

const SupportSection = ({supportCardArr,planName,planDesc,expireOn,downgradeAtTermEnd}) => {

  return (
    <div className="agency-dashboard-support">
    <div className='agency-dashboard-support-heading-parent'>
    <h3 className="agency-dashboard-support-heading">Support</h3>
    {/* <Newfeatureicon/> */}
    </div>
    <div className="agency-dashboard-support-child">
        <SupportHeader planName={planName} planDesc={planDesc} expireOn={expireOn} downgradeAtTermEnd={downgradeAtTermEnd}/>

        <div className="agency-dashboard-support-body">
            {
                supportCardArr?.map((item ,index)=>
                {
                return (
                <SupportCard 
                 key={index}
                 heading={item.heading}
                 text={item.text}
                 link={item.link}
                 icon={item.icon}
                />
            )
                })
            }
        </div>
    </div>
</div>
  )
}

export default SupportSection