import React from "react";
import Header from "../Header";
import Tooltip from "../Tooltip";
import Leftpanel from "../Leftpanel";
import { Pagesapi, GetPagesapi } from "../../Api/Dashboard/DashboardApi";
import { Spinner, Media } from "reactstrap";
import { InnerFoot } from "../../Dashboard/Foot";
import Amsvideo from "../Reusable/Amsvideos";
import "../../assets/css/pages.css";
import moment from "moment";
import Tooltipicon from "../../Components/commonIcons/Tooltip";
import "../CmsBuilder/pages.css";
import "./../../assets/css/Dashboard.css";
import PageOptions from "../CmsBuilder/PageOptions";
import { pageOrderingData } from "../CmsBuilder/Cmspages";
import SettingIcon from "../../Components/commonIcons/SettingIcon";
export default class Pagestab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bodyspinner: true,
      submitval: "Save",
      loader: "d-none",
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      enable_web_view: true,
      enable_hyperlinks: true,
      enable_form_inputs: true,
      enable_header_in_web_view: true,
      show_additional_information: false,
      lastdate: "",
      appRightPanelExpand:
        localStorage.getItem("appSideBarCollapse") === true ||
        localStorage.getItem("appSideBarCollapse") === "true"
          ? true
          : false,
      enableGeneralSettings: true,
      enablePageLayout: false,
      pageLayout: "layout-0",
      pageOrderingData: pageOrderingData,
      saveSettingsLoading: false,
      pageOptions: {
        background_color_on_pages_listing_page: "#f6f6f6",
        enable_share_feature_on_pages_detail_page: 0,
        image_ratio_on_pages_detail_page: "4:3",
        image_shape_on_pages_detail_page: "sharp_corner",
        section_margin_bottom_on_pages_detail_page: "7.5",
        section_margin_left_on_pages_detail_page: "15",
        section_margin_right_on_pages_detail_page: "15",
        section_margin_top_on_pages_detail_page: "7.5",
        show_center_crop_images_on_pages_detail_page: 1,
        show_media_as_thumbnail_on_pages_detail_page: 1,
        show_media_in_full_screen_on_pages_detail_page: 1,
        show_search_icon_on_pages_listing_page: 0,
        text_color_on_pages_listing_page: "#1a1a1a",
        tile_background_color_on_pages_listing_page: "#ffffff",
      },
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "Page settings | AppMySite";

    // const params = {
    //   app_id: btoa(localStorage.getItem("Appid")),
    // };
    // GetPagesapi(params, this);
  }
  handlewebview = (e) => {
    if (e.target.checked === true) {
      this.setState({
        enable_web_view: e.target.checked,
        enable_hyperlinks: true,
        enable_form_inputs: true,
      });
    } else {
      this.setState({
        enable_web_view: e.target.checked,
      });
    }
  };

  handleadditionalinformation = (e) => {
    this.setState({
      show_additional_information: e.target.checked,
    });
  };

  handleform = (e) => {
    const { name, checked } = e.target;

    this.setState({
      [name]: checked,
    });
  };

  pluginsubmit = (e) => {
    e.preventDefault();
    const params = {
      app_id: localStorage.getItem("Appid"),
      enable_web_view: this.state.enable_web_view === true ? 1 : 0,
      show_additional_information:
        this.state.show_additional_information === true ? 1 : 0,
      background_color_on_pages_listing_page:
        this.state.pageOptions.background_color_on_pages_listing_page,
      enable_share_feature_on_pages_detail_page:
        this.state.pageOptions.enable_share_feature_on_pages_detail_page,
      // image_ratio_on_pages_detail_page:
      //   this.state.pageOptions.image_ratio_on_pages_detail_page,
      // image_shape_on_pages_detail_page:
      //   this.state.pageOptions.image_shape_on_pages_detail_page,
      pages_layout: this.state.pageLayout,
      section_margin_bottom_on_pages_detail_page:
        this.state.pageOptions.section_margin_bottom_on_pages_detail_page || "7.5",
      section_margin_left_on_pages_detail_page:
        this.state.pageOptions.section_margin_left_on_pages_detail_page || "15",
      section_margin_right_on_pages_detail_page:
        this.state.pageOptions.section_margin_right_on_pages_detail_page || "15",
      section_margin_top_on_pages_detail_page:
        this.state.pageOptions.section_margin_top_on_pages_detail_page || "7.5",
      // show_center_crop_images_on_pages_detail_page:
      //   this.state.pageOptions.show_center_crop_images_on_pages_detail_page,
      // show_media_as_thumbnail_on_pages_detail_page:
      //   this.state.pageOptions.show_media_as_thumbnail_on_pages_detail_page,
      // show_media_in_full_screen_on_pages_detail_page:
      //   this.state.pageOptions.show_media_in_full_screen_on_pages_detail_page,
      show_search_icon_on_pages_listing_page: 0,
      text_color_on_pages_listing_page:
        this.state.pageOptions.text_color_on_pages_listing_page,
      tile_background_color_on_pages_listing_page:
        this.state.pageOptions.tile_background_color_on_pages_listing_page,
      //enable_header_in_web_view: this.state.enable_header_in_web_view === true ? 1 : 0,
      enable_hyperlinks: 1,
      enable_form_inputs: 1,
    };
    Pagesapi(params, this);
  };

  handleRightPanelExpand = (value) => {
    this.setState({
      appRightPanelExpand: value,
    });
  };

  setPageLayout = (newLayout) => {
    this.setState({ pageLayout: newLayout });
  };

  setRenderWebView = (e) => {
    this.setState({ enable_web_view: e });
  };

  setPageData = (newPageOptions) => {
    this.setState({
      pageOptions: { ...this.state.pageOptions, ...newPageOptions },
    });
  };

  render() {
    return (
      <>
        <div className="d-md-block">
          <Header customclass={"header-responsive-new"} />
        </div>
        {/* Mobile View */}
        {/* Mobile View End*/}
        <section className="dasboard_page mobile-display mt-0">
          <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
          <div
            className={`right-panel-gap ${
              localStorage.getItem("appSideBarCollapse") !== undefined &&
              localStorage.getItem("appSideBarCollapse") !== null &&
              localStorage.getItem("appSideBarCollapse") === "true"
                ? " right-panel-gap-expand"
                : ""
            }`}
          >
            <>
              <div className="all-pages-head" style={{ marginBottom: "20px" }}>
                <h3>
                  Pages
                  <p className="bottombar-tooltip">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                    >
                      <g
                        id="Group_35001"
                        data-name="Group 35001"
                        transform="translate(-1727 -19)"
                      >
                        <g
                          id="Ellipse_7706"
                          data-name="Ellipse 7706"
                          transform="translate(1727 19)"
                          fill="none"
                          stroke="#bcc1ce"
                          stroke-width="1.5"
                        >
                          <circle cx="9" cy="9" r="9" stroke="none"></circle>
                          <circle cx="9" cy="9" r="8.25" fill="none"></circle>
                        </g>
                        <line
                          id="Line_343"
                          data-name="Line 343"
                          y1="2"
                          transform="translate(1736 23)"
                          fill="none"
                          stroke="#bcc1ce"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        ></line>
                        <path
                          id="Path_98224"
                          data-name="Path 98224"
                          d="M0,2V-4"
                          transform="translate(1736 31)"
                          fill="none"
                          stroke="#bcc1ce"
                          stroke-width="1.5"
                        ></path>
                      </g>
                    </svg>

                    <p className="bottombar-tooltipsubheader">
                    Tailor each page to create engaging content for your app audience.
                    </p>
                  </p>
                </h3>
                {this.state.bodyspinner ? (
                  <></>
                ) : (
                  <>
                    <div className="all-pages-head-right">
                      <div className="hide-detail-web">
                        {this.state.lastdate &&
                        this.state.lastdate !== "" &&
                        this.state.lastdate !== undefined &&
                        !this.state.enablePageLayout ? (
                          <p
                            className="consent-save-date"
                            style={{ marginRight: "0px" }}
                          >
                            Updated{" "}
                            {moment(this.state.lastdate).format(
                              "D MMMM YYYY, HH:mm"
                            )}{" "}
                          </p>
                        ) : null}
                      </div>

                      {
                        <button
                          onClick={() => {
                            this.setState({
                              enablePageLayout: !this.state.enablePageLayout,
                              enableGeneralSettings: !this.state
                                .enablePageLayout
                                ? false
                                : true,
                            });
                          }}
                          className={
                            this.state.enablePageLayout
                              ? " setting-bt-off-hide-web  tagpagelayout-svg"
                              : "setting-bt-on-hide-web  tagpagelayout-svg"
                          }
                        >
                          {this.state.enablePageLayout ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                            >
                              <defs>
                                <clipPath id="clip-path3">
                                  <rect
                                    id="Rectangle_32448"
                                    data-name="Rectangle 32448"
                                    width="26"
                                    height="26"
                                    transform="translate(62 1)"
                                    fill="rgba(0,0,0,0)"
                                  ></rect>
                                </clipPath>
                              </defs>
                              <g
                                id="Mask_Group_34872"
                                data-name="Mask Group 34872"
                                transform="translate(-62 -1)"
                                clip-path="url(#clip-path3)"
                              >
                                <g
                                  id="Group_43107"
                                  data-name="Group 43107"
                                  transform="translate(63.887 2.887)"
                                >
                                  <g
                                    id="Rectangle_32447"
                                    data-name="Rectangle 32447"
                                    transform="translate(3.113 0.113)"
                                    fill="#7782a1"
                                    stroke="#7782a1"
                                    stroke-linejoin="round"
                                    stroke-width="1"
                                  >
                                    <rect
                                      width="16"
                                      height="22"
                                      rx="1.5"
                                      stroke="none"
                                    ></rect>
                                    <rect
                                      x="-0.5"
                                      y="-0.5"
                                      width="17"
                                      height="23"
                                      rx="2"
                                      fill="none"
                                    ></rect>
                                  </g>
                                  <path
                                    id="Union_3151"
                                    data-name="Union 3151"
                                    d="M15359,21365v-2h6v2Zm-4,0v-2h2v2Zm4-6v-2h6v2Zm-4,0v-2h2v2Zm4-6v-2h6v2Zm-4,0v-2h2v2Z"
                                    transform="translate(-15348.887 -21346.887)"
                                    fill="#fff"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                            >
                              <defs>
                                <clipPath id="clip-path4">
                                  <rect
                                    id="Rectangle_29591"
                                    data-name="Rectangle 29591"
                                    width="26"
                                    height="26"
                                    transform="translate(62 1)"
                                    fill="rgba(0,0,0,0)"
                                  ></rect>
                                </clipPath>
                              </defs>
                              <g
                                id="Mask_Group_25287"
                                data-name="Mask Group 25287"
                                transform="translate(-62 -1)"
                                clip-path="url(#clip-path4)"
                              >
                                <g
                                  id="Group_39235"
                                  data-name="Group 39235"
                                  transform="translate(63.887 2.887)"
                                >
                                  <rect
                                    id="Rectangle_29590"
                                    data-name="Rectangle 29590"
                                    width="16"
                                    height="22"
                                    rx="1.5"
                                    transform="translate(3.113 0.113)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                  ></rect>
                                  <path
                                    id="Path_119244"
                                    data-name="Path 119244"
                                    d="M11.113,5.113h6"
                                    transform="translate(-1)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  ></path>
                                  <path
                                    id="Path_119245"
                                    data-name="Path 119245"
                                    d="M11.113,5.113h6"
                                    transform="translate(-1 6)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  ></path>
                                  <path
                                    id="Path_119246"
                                    data-name="Path 119246"
                                    d="M11.113,5.113h6"
                                    transform="translate(-1 12)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  ></path>
                                  <g
                                    id="Group_43104"
                                    data-name="Group 43104"
                                    transform="translate(6.113 4.363)"
                                  >
                                    <path
                                      id="Path_119244-2"
                                      data-name="Path 119244"
                                      d="M11.113,5.113h2"
                                      transform="translate(-11.113 -4.363)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    ></path>
                                    <path
                                      id="Path_119245-2"
                                      data-name="Path 119245"
                                      d="M11.113,5.113h2"
                                      transform="translate(-11.113 1.637)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    ></path>
                                    <path
                                      id="Path_119246-2"
                                      data-name="Path 119246"
                                      d="M11.113,5.113h2"
                                      transform="translate(-11.113 7.637)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          )}
                        </button>
                      }

                      {/* {
                        <button
                          onClick={() => {
                            this.setState({
                              enableGeneralSettings: true,
                              enablePageLayout: false,
                            });
                          }}
                          className={
                            this.state.enableGeneralSettings
                              ? " setting-bt-off-hide-web  tagpagelayout-svg"
                              : "setting-bt-on-hide-web  tagpagelayout-svg"
                          }
                        >
                          {this.state.enableGeneralSettings ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                            >
                              <g
                                id="Group_38340"
                                data-name="Group 38340"
                                transform="translate(-20 -527)"
                              >
                                <rect
                                  id="Rectangle_25572"
                                  data-name="Rectangle 25572"
                                  width="26"
                                  height="26"
                                  transform="translate(20 527)"
                                  fill="none"
                                />
                                <path
                                  className="  productsetting-notselect productSetting-select"
                                  id="Path_99646"
                                  data-name="Path 99646"
                                  d="M11.513,22.5a10.829,10.829,0,0,0,1.266-.074.615.615,0,0,0,.542-.642,2.437,2.437,0,0,1,1.488-2.388,2.278,2.278,0,0,1,.887-.171,2.527,2.527,0,0,1,1.855.788.616.616,0,0,0,.837.067,10.968,10.968,0,0,0,1.739-1.76.615.615,0,0,0-.066-.829,2.432,2.432,0,0,1,1.592-4.231h.056a.73.73,0,0,0,.72-.542,11,11,0,0,0,0-2.5.592.592,0,0,0-.613-.54h-.022A2.543,2.543,0,0,1,19.4,8.19a2.437,2.437,0,0,1,.613-2.744.614.614,0,0,0,.066-.836A10.919,10.919,0,0,0,18.3,2.851a.606.606,0,0,0-.377-.131.615.615,0,0,0-.454.2,2.532,2.532,0,0,1-1.855.785,2.259,2.259,0,0,1-.868-.165A2.449,2.449,0,0,1,13.238,1.2a.614.614,0,0,0-.544-.63,11.023,11.023,0,0,0-2.474,0,.615.615,0,0,0-.539.643A2.443,2.443,0,0,1,8.195,3.6a2.318,2.318,0,0,1-.89.172,2.524,2.524,0,0,1-1.853-.788.615.615,0,0,0-.837-.069,10.968,10.968,0,0,0-1.739,1.76.615.615,0,0,0,.066.829A2.43,2.43,0,0,1,1.279,9.733a.718.718,0,0,0-.708.54,11.037,11.037,0,0,0,0,2.5.617.617,0,0,0,.6.542h.181A2.464,2.464,0,0,1,3.6,14.808a2.438,2.438,0,0,1-.615,2.743.614.614,0,0,0-.066.837A11,11,0,0,0,4.7,20.145a.616.616,0,0,0,.83-.069,2.535,2.535,0,0,1,1.853-.785,2.3,2.3,0,0,1,.87.165A2.443,2.443,0,0,1,9.76,21.8a.614.614,0,0,0,.545.628A11.109,11.109,0,0,0,11.513,22.5Z"
                                  transform="translate(21.501 529.151)"
                                  fill="#7782A1"
                                  stroke="#7782A1"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                />
                                <path
                                  id="Path_99647"
                                  data-name="Path 99647"
                                  d="M9.818,5.846a4,4,0,1,1-4,4A4,4,0,0,1,9.818,5.846Z"
                                  transform="translate(23.181 530.804)"
                                  fill="#ffff"
                                  stroke="#7782A1"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                />
                              </g>
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="23.5"
                              height="23.499"
                              viewBox="0 0 23.5 23.499"
                            >
                              <g
                                id="Group_38368"
                                data-name="Group 38368"
                                transform="translate(-1598.25 -88.901)"
                              >
                                <path
                                  id="Path_99646"
                                  data-name="Path 99646"
                                  d="M11.513,22.5a10.829,10.829,0,0,0,1.266-.074.615.615,0,0,0,.542-.642,2.437,2.437,0,0,1,1.488-2.388,2.278,2.278,0,0,1,.887-.171,2.527,2.527,0,0,1,1.855.788.616.616,0,0,0,.837.067,10.968,10.968,0,0,0,1.739-1.76.615.615,0,0,0-.066-.829,2.432,2.432,0,0,1,1.592-4.231h.056a.73.73,0,0,0,.72-.542,11,11,0,0,0,0-2.5.592.592,0,0,0-.613-.54h-.022A2.543,2.543,0,0,1,19.4,8.19a2.437,2.437,0,0,1,.613-2.744.614.614,0,0,0,.066-.836A10.919,10.919,0,0,0,18.3,2.851a.606.606,0,0,0-.377-.131.615.615,0,0,0-.454.2,2.532,2.532,0,0,1-1.855.785,2.259,2.259,0,0,1-.868-.165A2.449,2.449,0,0,1,13.238,1.2a.614.614,0,0,0-.544-.63,11.023,11.023,0,0,0-2.474,0,.615.615,0,0,0-.539.643A2.443,2.443,0,0,1,8.195,3.6a2.318,2.318,0,0,1-.89.172,2.524,2.524,0,0,1-1.853-.788.615.615,0,0,0-.837-.069,10.968,10.968,0,0,0-1.739,1.76.615.615,0,0,0,.066.829A2.43,2.43,0,0,1,1.279,9.733a.718.718,0,0,0-.708.54,11.037,11.037,0,0,0,0,2.5.617.617,0,0,0,.6.542h.181A2.464,2.464,0,0,1,3.6,14.808a2.438,2.438,0,0,1-.615,2.743.614.614,0,0,0-.066.837A11,11,0,0,0,4.7,20.145a.616.616,0,0,0,.83-.069,2.535,2.535,0,0,1,1.853-.785,2.3,2.3,0,0,1,.87.165A2.443,2.443,0,0,1,9.76,21.8a.614.614,0,0,0,.545.628A11.109,11.109,0,0,0,11.513,22.5Z"
                                  transform="translate(1598.501 89.151)"
                                  fill="#ffff"
                                  stroke="#7782A1"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                />
                                <path
                                  id="Path_99647"
                                  data-name="Path 99647"
                                  d="M9.818,5.846a4,4,0,1,1-4,4A4,4,0,0,1,9.818,5.846Z"
                                  transform="translate(1600.182 90.804)"
                                  fill="#ffff"
                                  stroke="#7782A1"
                                  stroke-linejoin="round"
                                  stroke-width="1.5"
                                />
                              </g>
                            </svg>
                          )}
                        </button>
                      } */}
                       <div className="page-setting-icon-margin-left"
                     onClick={() => {
                      this.setState({
                        enableGeneralSettings: true,
                        enablePageLayout: false,
                      });
                    }}
                    // className={
                    //   this.state.enablesetting == true
                    //     ? "consent-setting consent-setting-clicked"
                    //     : "consent-setting"
                    // }
                  >
                    <SettingIcon
                     
                      isActive={this.state.enableGeneralSettings}
                    />
                  </div>
                      <div
                        className="fixed-button hide-detail-web"
                        style={{ marginLeft: "20px" }}
                      >
                        <button
                          className="next_btn checknew"
                          style={{
                            backgroundColor: localStorage.getItem(
                              "button_background_color"
                            ),
                            border:
                              "1px solid " +
                              localStorage.getItem("button_background_color"),
                            color: localStorage.getItem("button_text_color"),
                            "--custom_color5":
                              localStorage.getItem("agencyid") == 1
                                ? "#134bec"
                                : localStorage.getItem("button_hover_bgcolor"),
                            "--custom_color4":
                              localStorage.getItem("agencyid") == 1
                                ? "white"
                                : localStorage.getItem("button_hover_txtcolor"),
                            "--custom_color6":
                              localStorage.getItem("agencyid") == 1
                                ? "#3064f9"
                                : localStorage.getItem("button_hover_bgcolor"),
                          }}
                          onClick={(e) => this.pluginsubmit(e)}
                        >
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            {this.state.saveSettingsLoading ? (
                              <div className="new-loader"></div>
                            ) : (
                              <div>{this.state.submitval}</div>
                            )}
                          </div>
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* {this.state.bodyspinner ? (
                <div
                  className="generalsettingbg"
                  style={{
                    marginTop: "20px",
                    minHeight: "80vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    style={{
                      margin: "auto",
                      background: "rgb(241, 242, 243,0)",
                      display: "block",
                      shaperendering: "auto",
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                    width="200px"
                    height="200px"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                  >
                    <g
                      transform="translate(20 50)"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <circle
                        cx="0"
                        cy="0"
                        r="6"
                        fill="#e6261f"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <animateTransform
                          attributeName="transform"
                          type="scale"
                          begin="-0.375s"
                          calcMode="spline"
                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                          values="0;1;0"
                          keyTimes="0;0.5;1"
                          dur="1s"
                          repeatCount="indefinite"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        ></animateTransform>
                      </circle>
                    </g>
                    <g
                      transform="translate(40 50)"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <circle
                        cx="0"
                        cy="0"
                        r="6"
                        fill="#f7d038"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <animateTransform
                          attributeName="transform"
                          type="scale"
                          begin="-0.25s"
                          calcMode="spline"
                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                          values="0;1;0"
                          keyTimes="0;0.5;1"
                          dur="1s"
                          repeatCount="indefinite"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        ></animateTransform>
                      </circle>
                    </g>
                    <g
                      transform="translate(60 50)"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <circle
                        cx="0"
                        cy="0"
                        r="6"
                        fill="#49da9a"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <animateTransform
                          attributeName="transform"
                          type="scale"
                          begin="-0.125s"
                          calcMode="spline"
                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                          values="0;1;0"
                          keyTimes="0;0.5;1"
                          dur="1s"
                          repeatCount="indefinite"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        ></animateTransform>
                      </circle>
                    </g>
                    <g
                      transform="translate(80 50)"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <circle
                        cx="0"
                        cy="0"
                        r="6"
                        fill="#4355db"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <animateTransform
                          attributeName="transform"
                          type="scale"
                          begin="0s"
                          calcMode="spline"
                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                          values="0;1;0"
                          keyTimes="0;0.5;1"
                          dur="1s"
                          repeatCount="indefinite"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        ></animateTransform>
                      </circle>
                    </g>
                  </svg>
                </div>
              ) : ( */}
              <>
                <PageOptions
                  pluginsubmit={this.pluginsubmit}
                  from="WP/WOO"
                  GetPagesapi={GetPagesapi}
                  thiss={this}
                  optionsData={{
                    enableGeneralSettings: this.state.enableGeneralSettings,
                    enablePageLayout: this.state.enablePageLayout,
                    pageLayout: this.state.pageLayout,
                    setPageLayout: this.setPageLayout,
                    pageState: pageOrderingData,
                    setPageData: this.setPageData,
                    setRenderWebView: this.setRenderWebView,
                  }}
                />
              </>
              {/* )} */}
            </>
          </div>
        </section>
      </>
    );
  }
}
