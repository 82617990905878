import React from "react";

const BackArrow = ({ handleMobBackBtn }) => {
  return (
    <svg
      onClick={(e) => (handleMobBackBtn ? handleMobBackBtn(e) : null)}
      xmlns="http://www.w3.org/2000/svg"
      width="20.759"
      height="15.408"
      viewBox="0 0 20.759 15.408"
    >
      <g
        id="Group_27465"
        data-name="Group 27465"
        transform="translate(-19.742 -22.295)"
      >
        <path
          id="Path_81245"
          data-name="Path 81245"
          d="M-13783.922-19217h19.346"
          transform="translate(13805.077 19247)"
          fill="none"
          stroke="#7782a1"
          stroke-width="2"
        />
        <path
          id="Path_82232"
          data-name="Path 82232"
          d="M14053.656,19255.426l-7,7,7,7"
          transform="translate(-14025.504 -19232.424)"
          fill="none"
          stroke="#7782a1"
          stroke-width="2"
        />
      </g>
    </svg>
  );
};

export default BackArrow;
