import React from "react";

const Tile = ({
  item,
  isSelected,
  settings,
  selectedGridIconFilter,
  gridIconFilter,
  blackIconItems,
  totalItems,
}) => {
  const {
    bottom_menu_grid_view_selected_tile_background_color: selectedBgColor,
    bottom_menu_grid_view_tile_background_color: bgColor,
    bottom_menu_grid_view_selected_tile_border_color: selectedBorderColor,
    bottom_menu_grid_view_tile_border_color: borderColor,
    bottom_menu_grid_view_tile_shape: tileShape,
    bottom_menu_grid_view_column: columnCount,
    bottom_menu_grid_view_show_icon: showIcon,
    bottom_menu_grid_view_show_text: showText,
    bottom_menu_grid_view_selected_icon_text_color: selectedTextColor,
    bottom_menu_grid_view_icon_text_color: textColor,
  } = settings;

  const tileStyles = {
    background: isSelected ? selectedBgColor : bgColor,
    border: `1px solid ${isSelected ? selectedBorderColor : borderColor}`,
    borderRadius:
      tileShape === "reactangular"
        ? "0px"
        : tileShape === "square"
        ? "20px"
        : "50%",
    width: ["100%", "132px", "85px", "61px"][columnCount - 1] || "61px",
    height: ["275px", "132px", "85px", "61px"][columnCount - 1] || "61px",
  };

  const iconPath =
    {
      light: process.env.REACT_APP_light_path,
      bold: process.env.REACT_APP_bold_path,
      regular: process.env.REACT_APP_regular_path,
      new: `${localStorage.getItem("user_id")}/${localStorage.getItem(
        "Appid"
      )}${process.env.REACT_APP_NEW_ICON_PATH}`,
    }[item.item_icon_type] || process.env.REACT_APP_light_path;

  const iconSrc = item.item_icon
    ? `${process.env.REACT_APP_Image_Path}${iconPath}${item.item_icon}`
    : require("../../../assets/images/dashboard/create-bottom-bar.png").default;

  const imageStyles = {
    maxWidth: ["30px", "26px", "22px", "16px"][columnCount - 1] || "16px",
    maxHeight: ["30px", "26px", "22px", "16px"][columnCount - 1] || "16px",
    filter:
      blackIconItems === totalItems && settings?.bottom_menu_icon_colour_type_for_menu === "black"
        ? isSelected
          ? selectedGridIconFilter
          : gridIconFilter
        : null,
  };

  const textClass =
    [
      "bottombar-onetiles-text",
      "bottombar-twotiles-text",
      "bottombar-threetiles-text",
      "bottombar-fourtiles-text",
    ][columnCount - 1] || "bottombar-fourtiles-text";

  return (
    <div className="bottombar-tile-align" style={tileStyles}>
      <div>
        {showIcon && (
          <img
            className="img-fluid"
            src={iconSrc}
            alt={item.item_name || "tile-icon"}
            style={imageStyles}
          />
        )}
        {showText && (
          <p
            className={textClass}
            style={{ color: isSelected ? selectedTextColor : textColor }}
          >
            {item.item_name}
          </p>
        )}
      </div>
    </div>
  );
};

export default Tile;
