import React, {useState, useEffect} from "react";
import "../Agency/assets/css/common.css";


const ContactAdmin = (props) =>{

    useEffect(()=>
    {

        if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
            {
              document.title = "Contact Administrator | AppMySite";
            }
            else
            {
              document.title = "Contact Administrator |"+ ' ' +localStorage.getItem('agencyname');      
            }
    }, []);

    return (
    <div className="org-module">

        <div className="agency-errorpage">
            <div>
                <h1>Page unavailable</h1>
                <h3>This page is currently unavailable. Please contact your domain administrator for assistance.</h3>
                <img className="agency-error-webimg" src={require("../Agency/assets/images/404web.webp") .default} alt="customers"/>
                <img className="agency-error-mobimg" src={require("../Agency/assets/images/404mobile.webp") .default} alt="customers"/>
            </div>
        </div>

    </div>

    );
}

export default ContactAdmin;