import React from 'react'
import "../../assets/css/commonComponent.css";
const ImgRatio = ({selectedImgRatio,imgRatio,isDisabled,handleRatioChange,tooltipContent}) => {
    const returnClass = () => {
        switch (imgRatio) {
            case "16:9":
               return "sixteen";
               case "4:3":
               return "four";
               case "1:1":
               return "one";
               case "3:4":
               return "three";
               case "9:16":
               return "nine";
               
    }

    }
    let returnClassName = returnClass();
  return (
    <div
    className={`common-img-ratio ${isDisabled ? "common-img-ratio-disabled" : ""}`}
    onClick={(e) => !isDisabled && handleRatioChange(e, imgRatio)}
  >
   
    <div
      className={`common-img-${returnClassName}-ratio ${
        selectedImgRatio === imgRatio
          ? "common-img-ratio-border-selected"
          : ""
      }`}
    ></div>

    <span
      className={
        selectedImgRatio === imgRatio
          ? "common-img-ratio-selected"
          : "common-img-ratio-unselected"
      }
    >
      {imgRatio}
    </span>
  {tooltipContent &&  <span className='common-img-ratio-tooltip'>{tooltipContent}</span>}
 
  </div>
  )
}

export default ImgRatio