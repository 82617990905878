import React, {useState, useEffect} from "react";
import "./../assets/css/common.css";
import Header from "../../Dashboard/Header";
import LeftPanel from "./../LeftPanel";
import { Link, useParams } from "react-router-dom";
import {errortoast} from '../../Dashboard/Toaster';
import { Spinner } from "reactstrap";
import {
    getDownloadPluginDetails,
	saveDownloadPluginDetails
} from "../../Api/Agency/AgencyApi";
import NewSpinner from "./../NewSpinner";
import moment from "moment";

const DownloadPlugin = () =>{

    const [spinner, setSpinner] = useState(true);
    const [btnloader, setBtnLoader] = useState("d-none");
    const [pluginname, setPluginName] = useState("");
    const [plugindesc, setPluginDesc] = useState("");
    const [authorname, setAuthorName] = useState("");
    const [authorurl, setAuthorURL] = useState("");
    const [btnsavelabel, setBtnSaveLabel] = useState("SAVE");
    const [logoimage,setLogoImage] = useState("");
    const [orgname,setOrgName] = useState("");
    const [errorpluginname, setErrorPluginName] = useState("");
    const [errorplugindesc, setErrorPluginDesc] = useState("");
    const [errorauthorname, setErrorAuthorName] = useState("");
    const [errorauthorurl, setErrorAuthorUrl] = useState("");
    const [rightpanelexpand,setRightPanelExpand] = useState(true);
    const [lastdate,setLastDate] = useState("");

    let { agencyID } = useParams("agencyID");

    useEffect(()=>{

        if(agencyID !== '' && agencyID !== undefined && agencyID !== null)
        {
            getDownloadPluginDetails(agencyID, setSpinner, setPluginName, setPluginDesc, setAuthorName, setAuthorURL,setLastDate, setLogoImage, setOrgName);
        }

        document.title = "WordPress Plugin | AppMySite";

    }, []);

    const handleSetPluginName = (event) =>{

        setPluginName(event.target.value);
        setErrorPluginName(false);
    }

    const handleSetPluginDescription = (event) =>{

        setPluginDesc(event.target.value);
        setErrorPluginDesc(false);
    }

    const handleSetAuthorName = (event) =>{

        setAuthorName(event.target.value);
        setErrorAuthorName(false);
    }

    const handleSetAuthorURL = (event) =>{

        setAuthorURL(event.target.value);
        setErrorAuthorUrl(false);
    }

    const handleSaveDownloadPluginDetails = () =>{

        if(pluginname === '')
        {
            //errortoast("Plugin name is required");
            setErrorPluginName("Plugin name is required");
            
        }
        
        if(plugindesc === '')
        {
            //errortoast("Plugin description is required");
            setErrorPluginDesc("Plugin description is required");
            
        }
        
        if(authorname === '')
        {
            //errortoast("Author name is required");
            setErrorAuthorName("Author name is required");
            
        }
        
        if(authorurl === '')
        {
            //errortoast("Author url is required");
            setErrorAuthorUrl("Author url is required");
            
        }

        

        if(pluginname == '' || plugindesc == '' || authorname == '' || authorurl == '')
        {
            return;
        }

        const params = {
                        agency_id: parseInt(atob(agencyID)),
                        plugin_name: pluginname,
                        description: plugindesc,
                        author_name: authorname,
                        author_url: authorurl
                    };
        
        setBtnSaveLabel("");
        setBtnLoader("d-block");
        
        saveDownloadPluginDetails(params, setBtnSaveLabel, setBtnLoader,setLastDate);
    }
    const handleRightPanelExpand = (rightpanelvalue) => {
        setRightPanelExpand(rightpanelvalue);
      }


    
    return(
    <div className="org-module">
        <Header />
        <div className="org-structure">

        <LeftPanel rightpanelbody={handleRightPanelExpand}/>

        <div className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined &&
            localStorage.getItem('sidebaserotate') === 'true') ? "org_background org_background-expand"
            : "org_background "}>
                
                        <div className="org-setting-head">
                            <div>  
                                <div className="agency-headings">
                            <h1>WordPress plugin</h1>
                            <p class="agency-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                <circle cx="9" cy="9" r="9" stroke="none"/>
                                <circle cx="9" cy="9" r="8.25" fill="none"/>
                                </g>
                                <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                                <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                            </g>
                            </svg><p class="agency-tooltipsubheader">Create a white-label WordPress & WooCommerce plugin.</p></p>
                            </div>
                            </div>

                            <div className="agency-web-timestamp-main-div">
                                <h5 className="agency-web-timestamp">
                                    {lastdate ?
                                        'Updated ' + moment(lastdate).format("D MMMM YYYY, HH:mm")
                                    : ''
                                    }
                                
                                </h5>
                                {spinner ? <></>                                
                                :
                                    <>
                                    <div className="leave-org-btn-deactivate">
                                        
                                        <svg id="Page-1" xmlns="http://www.w3.org/2000/svg" width="15.5" height="14.25" viewBox="0 0 15.5 14.25">
                                        <g id="Group_36045" data-name="Group 36045" transform="translate(0.25)">
                                            <g id="Group_36042" data-name="Group 36042">
                                            <g id="Group_36048" data-name="Group 36048">
                                                <line id="Line_368" data-name="Line 368" y1="10" transform="translate(7.5)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1.5"/>
                                            </g>
                                            </g>
                                            <path id="Path_99648" data-name="Path 99648" d="M3.5,6l4.008,4L11.5,6" transform="translate(0 0.2)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1.5"/>
                                            <g id="Group_36044" data-name="Group 36044" transform="translate(0 -0.5)">
                                            <g id="Group_36047" data-name="Group 36047">
                                                <path id="Path_99649" data-name="Path 99649" d="M14.5,10v4H.5V10" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1.5"/>
                                            </g>
                                            </g>
                                        </g>
                                        </svg>Download plugin
                                    </div>
                                    <div className={pluginname != '' || plugindesc != '' && authorname != '' && authorurl != '' ? "save-setting-btn" : "save-setting-btn agency-plugin-btn-deactivate"} onClick={ handleSaveDownloadPluginDetails }>
                                        <div className={btnloader+" new-loader"}></div>
                                        <>
                                    {btnloader == 'd-none' ? 'Save' : ''}
                                    </>
                                    </div>
                                    </>
                                }
                                
                            </div>
                        </div>
                {spinner ?
                    <NewSpinner />
                :

                <>

                    <div className="org-module-shadow">

                        <div className="org-domain-body">
                            <div className="row">
                                <div className="col-md-6">

                                    <div className="org-domin-left">

                                        <label>Plugin name</label>
                                           <>
                                            <input text="type" name="plugin_name" value={pluginname} onChange={handleSetPluginName}  className={errorpluginname ? "errormessages" : ''} autoComplete="off"/>
                                            {errorpluginname ?
                                            <span className="agencyerror"><svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"></path></svg>{errorpluginname}</span>
                                             : ''}
                                          </>
                                        

                                        <label>Plugin description</label>
                                          <>
                                            <textarea text="type" name="plugin_desc" value={plugindesc} onChange={handleSetPluginDescription} className={errorplugindesc ? "errormessages" : ''}  autoComplete="off"/>
                                            {errorplugindesc ?
                                            <span className="agencyerror"><svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"></path></svg>{errorplugindesc}</span>
                                            : ''
                                            }
                                         </>
                                        

                                        <label>Author name</label>
                                          <>
                                            <input text="type" name="author_name" value={authorname} onChange={handleSetAuthorName}  className={errorauthorname ? "errormessages" : ''}  autoComplete="off"/>
                                            {errorauthorname ? 
                                            <span className="agencyerror"><svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"></path></svg>{errorauthorname}</span>
                                            : ''
                                            }
                                        </>
                                        

                                        <label>Author URL</label>
                                          <>
                                            <input text="type" name="author_url" value={authorurl} onChange={handleSetAuthorURL}  className={errorauthorurl ? "errormessages" : ''}  autoComplete="off"/>
                                            {errorauthorurl ?
                                            <span className="agencyerror"><svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"></path></svg>{errorauthorurl}</span>
                                            : ''
                                            }
                                         </>
                                        

                                    </div>

                                </div>

                                <div className="col-md-6">
                                    <div className="org-domin-right">
                                        <div className="org-plugin-preview">
                                            <div className="plugin-preview">
                                                <h4>WordPress preview</h4>
                                                <div className="plugin-preview-box">
                                                    <div className="d-flex align-items-start justify-content-between">
                                                        <div>
                                                            
                                                            <h2>{ pluginname !== '' && pluginname !== undefined ? pluginname : "Your branded plugin" }</h2>
                                                            <img src={require("../assets/images/plugin-rating.png").default} className="img-fluid" alt="plugin-rating"/>
                                                        </div>
                                                            <img src={require("../assets/images/wordpress-icon.png").default} className="img-fluid" alt="wordpress"/>
                                                    </div>

                                                    {plugindesc !== '' && plugindesc !== undefined ? 
                                                        <p>{plugindesc}</p>
                                                    :
                                                        <p>This plugin enables WordPress & WooCommerce users to
                                                        sync their websites with native iOS and Android apps, created
                                                        on www.example.com</p>
                                                    }

                                                <h5><svg xmlns="http://www.w3.org/2000/svg" width="12.666" height="14.527" viewBox="0 0 12.666 14.527">
                                                    <g id="Group_38372" data-name="Group 38372" transform="translate(-1263 -709)">
                                                        <path id="Path_101708" data-name="Path 101708" d="M15.19,2.86c0,1.785-1.2,3.606-2.679,3.606S9.831,4.646,9.831,2.86a2.685,2.685,0,1,1,5.359,0" transform="translate(1256.833 709)" fill="#bbb"/>
                                                        <path id="Path_101709" data-name="Path 101709" d="M12.666,23.238v2.039a18.289,18.289,0,0,1-6.322,1.085A18.313,18.313,0,0,1,0,25.27V23.238a4.278,4.278,0,0,1,2.869-4.264,1.446,1.446,0,0,1,1.4.17l2.068,1.518L8.4,19.145a1.439,1.439,0,0,1,1.384-.175,5.364,5.364,0,0,1,1.025.537,4.217,4.217,0,0,1,1.856,3.731" transform="translate(1263 697.165)" fill="#bbb"/>
                                                    </g>
                                                    </svg>

                                                { (authorurl !== undefined && authorurl !== '' && authorurl !== null) ?
                                                    
                                                        <span>{ authorname !== '' && authorname !== undefined ? authorname : "Author Name" }</span>
                                                :
                                                    <span>{ authorname !== '' && authorname !== undefined ? authorname : "Author Name" }</span>
                                                }
                                                </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="agency-mobile-btns">

                        <div className="agency-mobile-btns-blank leave-org-btn-deactivate">
                        <svg id="Page-1" xmlns="http://www.w3.org/2000/svg" width="15.5" height="14.25" viewBox="0 0 15.5 14.25">
                                    <g id="Group_36045" data-name="Group 36045" transform="translate(0.25)">
                                        <g id="Group_36042" data-name="Group 36042">
                                        <g id="Group_36048" data-name="Group 36048">
                                            <line id="Line_368" data-name="Line 368" y1="10" transform="translate(7.5)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1.5"/>
                                        </g>
                                        </g>
                                        <path id="Path_99648" data-name="Path 99648" d="M3.5,6l4.008,4L11.5,6" transform="translate(0 0.2)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1.5"/>
                                        <g id="Group_36044" data-name="Group 36044" transform="translate(0 -0.5)">
                                        <g id="Group_36047" data-name="Group 36047">
                                            <path id="Path_99649" data-name="Path 99649" d="M14.5,10v4H.5V10" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1.5"/>
                                        </g>
                                        </g>
                                    </g>
                                    </svg>Download plugin                 
                        </div> 

                        {/* <div className="agency-mobile-btns-blue">
                            <p>Save</p>                    
                        </div>  */}
                        <div className={pluginname != '' || plugindesc != '' && authorname != '' && authorurl != '' ? "agency-mobile-btns-blue" : "agency-mobile-btns-blue agency-plugin-btn-deactivate"} onClick={ handleSaveDownloadPluginDetails }>
                                    <div className={btnloader+" new-loader"}></div>
                                    <>
                                   {btnloader == 'd-none' ? 'Save' : ''}
                                   </>
                                </div>

                        <h5> {lastdate ?
                                        'Updated ' + moment(lastdate).format("D MMMM YYYY, HH:mm")
                                    : ''
                                    }</h5>

                    </div>

                </>
                }
            </div>
        </div>
    </div>
    );

}

export default DownloadPlugin;