import React, { useState, useContext, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import "../../../assets/css/Dashboard.css";
import { Link } from "react-router-dom";
import { Limittrigger } from "../../../Dashboard/User/Context";
import CrossIcon from "../../commonIcons/CrossIcon";
export const Limit = () => {
  const context = useContext(Limittrigger);
  const [adduser, setadduser] = useState(false);
  const [userrole, setuserrole] = useState(null);
  const [subscription_plan, setsubscription_plan] = useState(null);
  const [resellerPackageId, setResellerPackageId] = useState(null);
  const [addon_quantity, setaddon_quantity] = useState(null);
  const [plan, setPlan] = useState(null);
  const [addonCode, setAddonCode] = useState("");
  const addusertoggle = () => {
    setadduser(!adduser);
    context.handlelimitstatus();
  };

  useEffect(() => {
    setadduser(context.limitreached_status);
    setuserrole(context.adduserrole);
    setsubscription_plan(context.subscription_plan);
    setResellerPackageId(context.resellerPackageId);
    setaddon_quantity(context.addonQuantity);
    setPlan(context.plan);
    setAddonCode(context.addon_code);
  }, [context]);
  return (
    <Modal
      isOpen={adduser}
      toggle={addusertoggle}
      className="newuser-modal-popup"
      centered
    >
      <ModalBody className="d-flex align-items-center justify-content-center">
        <div className="user-delete-cross-icon">
          <CrossIcon handleCrossClick={() => addusertoggle()} />
        </div>
        <div>
          <div>
            <h4 className="user-delete-modal-title">User limit exceeded</h4>
            {plan && plan === "premium" ? (
              <h5 className="user-delete-modal-para">
                You have reached the maximum number of users allowed for your
                current plan.
              </h5>
            ) : (
              <h5 className="user-delete-modal-para">
                You have reached the maximum number of users allowed for your
                current plan. To add more users, consider upgrading your plan.
              </h5>
            )}
          </div>

          <div className="user-new-modal-footer">
            {(userrole == 3 || userrole == 4) && (
              <button
                style={{
                  fontFamily: "Axiforma-Regular",
                  top: window.matchMedia("(max-width: 1200px)").matches
                    ? "0"
                    : "",
                }}
                className="usermodal-grey-btn"
                onClick={addusertoggle}
              >
                Okay
              </button>
            )}

            {userrole < 3 &&
            ((subscription_plan !== null &&
              subscription_plan.indexOf(process.env.REACT_APP_Lifetime_plan) >
                -1) ||
              resellerPackageId) ? (
              <Link
                to={"/app/addons"}
                className="team-limit-btn"
                style={{
                  width: window.matchMedia("(max-width: 1200px)").matches
                    ? "100%"
                    : "200px",
                  textTransform: "unset",
                  fontFamily: "Axiforma-Regular",
                  top: window.matchMedia("(max-width: 1200px)").matches
                    ? "0"
                    : "",
                }}
              >
                Buy Addon
              </Link>
            ) : userrole < 3 && addon_quantity > 0 ? (
              <Link
                to={"/app/addons"}
                className="team-limit-btn"
                style={{
                  width: window.matchMedia("(max-width: 1200px)").matches
                    ? "100%"
                    : "200px",
                  textTransform: "unset",
                  fontFamily: "Axiforma-Regular",
                  top: window.matchMedia("(max-width: 1200px)").matches
                    ? "0"
                    : "",
                }}
              >
                Buy Addon
              </Link>
            ) : localStorage.getItem("agencyid") &&
              localStorage.getItem("agencyid") != 1 ? null : userrole < 3 &&
              subscription_plan !== null &&
              subscription_plan.indexOf("premium") === -1 ? (
              <div className="limit-modal-upgrade-div">
                {plan !== "premium" && (
                  <div
                    className="team-limit-btn-cancel activate-customer-cancel-btn"
                    onClick={addusertoggle}
                    style={{
                      fontFamily: "Axiforma-Regular",
                    }}
                  >
                    Back
                  </div>
                )}
                {plan !== "premium" ? (
                  <Link
                    to={
                      localStorage.getItem("workspaceId") !== "" &&
                      localStorage.getItem("workspaceId") !== null &&
                      localStorage.getItem("workspaceId") !== undefined &&
                      localStorage.getItem("workspaceId") !== "undefined"
                        ? `/workspace/pricing/${btoa(
                            localStorage.getItem("workspaceId")
                          )}`
                        : parseInt(
                            localStorage.getItem("website_technology")
                          ) === 4
                        ? "/app/wordpress-pricing/" +
                          btoa(localStorage.getItem("Appid"))
                        : parseInt(
                            localStorage.getItem("website_technology")
                          ) === 5
                        ? "/app/woocommerce-pricing/" +
                          btoa(localStorage.getItem("Appid"))
                        : parseInt(
                            localStorage.getItem("website_technology")
                          ) === 3
                        ? "/app/custom-app-pricing/" +
                          btoa(localStorage.getItem("Appid"))
                        : parseInt(
                            localStorage.getItem("website_technology")
                          ) === 2
                        ? "/app/web-to-app-pricing/" +
                          btoa(localStorage.getItem("Appid"))
                        : "/app/pricing"
                    }
                    className="team-limit-btn"
                    style={{
                      height: "44px",
                      textTransform: "unset",
                      lineHeight: "20px",
                      fontFamily: "Axiforma-Regular",
                    }}
                  >
                    Upgrade
                  </Link>
                ) : (
                  <button
                    style={{
                      fontFamily: "Axiforma-Regular",
                      top: window.matchMedia("(max-width: 1200px)").matches
                        ? "0"
                        : "",
                    }}
                    className="team-limit-btn"
                    onClick={addusertoggle}
                  >
                    Okay
                  </button>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
