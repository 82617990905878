import React from "react";
import { Link } from "react-router-dom";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import { Spinner, Modal, ModalHeader, ModalBody } from "reactstrap";
import {
  Getappcredentailapi,
  Validatepluginurl,
  VerifiedBlogApi,
} from "../../Api/Dashboard/DashboardApi";
import {
  generateLicenseKey
} from "../../Api/Connectivity/Connectivity";
import { Foot, InnerFoot } from "../../Dashboard/Foot";
import Amsvideo from "../Reusable/Amsvideos";
import Verifyemailstatus from "../../Verification/Checkverification";
import "./Downloadplugin.css";
import { sucesstoast } from "../Toaster";
import Troubleshooting from "./Troubleshooting";
import { connect } from "react-redux";
import { appDashboardApiSlice } from "../../services/appDashboardApiSlice";
import { generalSettingsApiSlice } from "../../services/generalSettingsApiSlice";
import { bottomApiSlice } from "../../services/bottomApiSlice";
import { menuApiSlice } from "../../services/menuApiSlice";
class Downloadplugin extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      puginbutton: "INSTALL PLUGIN",
      submitval: "Verify",
      loader: "d-none",
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      cs: "",
      ck: "",
      sendwrl: "",
      modal: false,
      finalimg: require("../../assets/images/dashboard/notPlugedimg.png")
        .default,
      storeurl: "",
      realurl: "",
      is_verified: "1",
      allowbutton: false,
      website_type: "",
      modalVideo: false,
      // plugincontent:
      //   "It appears that the AppMySite plugin is not installed on your WordPress website.",
      plugincontent:
      "It appears that the plugin is not installed on your WordPress website.",
      modalopen: false,
      troubleshooturl: "",
      is_connected: null,
      handlerender: true,
      appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
        ? true
        : false,
      generatedLicenseKey:"" ,
      generateSpinner:false,
      updated_at:"",
      modalError:false,
    };
  }
  componentDidMount() {

    window.scrollTo(0, 0);
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
    {
      document.title = "WordPress plugin | AppMySite";
    }
    else 
    {
      document.title = "WordPress plugin |"+ ' ' +localStorage.getItem('agencyname');
    }
    Getappcredentailapi(this, "download");
    const params = {
      app_id:parseInt(localStorage.getItem("Appid")),
      generate_key:0 ,
    }
    generateLicenseKey(this,params);
    this.setState({
      storeurl: localStorage.getItem("store_url"),
      website_type: localStorage.getItem("website_type_select"),
    });
  }

  handleGenerateKey = () =>{
    this.setState({
      generateSpinner:true
    })
    const params = {
      app_id:parseInt(localStorage.getItem("Appid")),
      generate_key:1 ,
    }
    generateLicenseKey(this,params);
  }

  handleCopyLicenseKey = () =>{
    const el = document.createElement("input");
    el.value = this.state.generatedLicenseKey;
  
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    // this.setState({
    //   generateSpinner:true
    // })
   sucesstoast("Copied!")
  }
  
  
  togglemodalVideo = (e) => {
    e.preventDefault();
    this.setState({
      modalVideo: !this.state.modalVideo,
    });
  };
  storeurl = () => {
    const params = {
      domain: this.state.storeurl,
    };
    Validatepluginurl(params, this);
  };
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  togglemodal = (e) => {
    e.preventDefault();
    this.setState({
      modal: !this.state.modal,
    });
  };

  //Verified Api incase of blog and pages
  handlepluginverified = (e) => {
    e.preventDefault();
    const params = {
      appid: localStorage.getItem("Appid"),
      sendwrl: this.state.sendwrl,
    };
    this.state.allowbutton && VerifiedBlogApi(params, this,this.props.refetchSettings,this.props.refetchBottomBarData,this.props.refetchMenuData);
    this.props.refetchItems();
    

  };
  emailModal = () => {
    this.setState({
      modalopen: true,
    });
  };
  modalclose = () => {
    this.setState({
      modalopen: false,
    });
  };
  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }

      // Error modal
  togglemodalError = (e) => {
    e.preventDefault();
    this.setState({
      modalError: !this.state.modalError,
    });
  };

  render() {

    return (
      <>
        <div className="d-md-block">
          <Header customclass={"header-responsive-new"} />
        </div>

        {/* Mobile View */}
        {/* Mobile View End*/}
        <section className="dasboard_page mobile-display mobile-view-consistent tback">
          {this.state.handlerender === true ? (
           <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
          ) : (
            <>
             <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
            </>
          )}
          <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>
            {/* Email Verification */}
            <div className="d-none d-md-flex w-100">
              <Verifyemailstatus />
            </div>
            {/* Email Verification End*/}
            {/* <div className="d-flex justify-content-between showwebstrip align-items-center pl-3 pr-3 flex-wrap mb-3 notificationdiv">
                        <div>
                          <h6 className="m-0 p-0">
                            <img
                              src={
                                require("../../assets/images/dashboard/exclamation.png")
                                  .default
                              }
                              alt="alert"
                              className="mr-1"
                            />
                            It appears your website is slow. Your users may face
                            issues while browsing through your app.
                          </h6>
                        </div>
                        <div className="d-flex align-items-center">
                          <Link className="mr-1 linkfont" to="/performance/web-hosting">
                            Fix this issue
                          </Link>
                          <h5 className="m-0 p-0">
                            &amp; get blazing fast speeds
                          </h5>
                        </div>
                      </div> */}
            {/* {this.state.is_verified === "0" && (
              <div className=" justify-content-between showwebstrip align-items-center pl-3 pr-3 flex-wrap notificationdiv4 dowmloadpluginnotice">
                <div className="notificationdiv1">
                  <h6 className="m-0 p-0">
                    <img
                      src={
                        require("../../assets/images/dashboard/exclamation.png")
                          .default
                      }
                      alt="alert"
                    />
                    Our plugin could not be found on your website.
                  </h6>
                </div>
                
              </div>
            )} */}

            {/* {this.state.is_verified === "0" && (
              <div className=" justify-content-between showwebstrip align-items-center pl-3 pr-3 flex-wrap mb-3 notificationdiv3">
                <div style={{ display: "flex" }}>
                  <div className="mobileimg">
                    <img
                      src={
                        require("../../assets/images/dashboard/exclamation.png")
                          .default
                      }
                      alt="alert"
                      className="mr-1"
                    />
                  </div>

                  <div style={{ marginLeft: "15px" }}>
                    <div className="notificationdiv1">
                      <h6 className="m-0 p-0 notification3">
                        Our plugin could not be found on your website.
                      </h6>
                     
                    </div>
                    
                  </div>
                </div>
              </div>
            )} */}
             <div className='web-connectivity-head'>
              <h1>Connectivity
              <p className="connectivity-tooltip">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
              <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                  <circle cx="9" cy="9" r="9" stroke="none"/>
                  <circle cx="9" cy="9" r="8.25" fill="none"/>
                </g>
                <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
              </g>
            </svg>
              <p className="connectivity-tooltipsubheader">
              Here, you can set up connectivity to your website. These connections enable the seamless transfer of data and content between your app and your data sources.
              </p>
            </p></h1>
            </div>

            <div className="connectivity-mob-head">
              <p>Install plugin</p>
            </div>
            <div className="consent-modal-tab">
              <div style={{ height: "40px" }}>
              {parseInt(localStorage.getItem("website_technology")) !==
                      2 &&
                  <Link className="active"  to={"/app/connect/wp-plugin"}
                  style={{
                    
                    "border-bottom": localStorage.getItem('agencyid') == 1 ?
                   "2px solid #3064f9" : "2px solid "+ localStorage.getItem('agency_primary_color'),

                   "color": localStorage.getItem('agencyid') == 1 ? "#3064f9" : 
                   localStorage.getItem('agency_primary_color'),

                   "--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : 
                   localStorage.getItem('agency_primary_color'),"--custom_color5":
                    localStorage.getItem('agencyid') == 1 ? "#3064f9" : 
                    localStorage.getItem('agency_primary_color')}} 
                    >Install plugin</Link>  }
                
                {/* { parseInt(localStorage.getItem("website_technology")) !== 2 &&
                  parseInt(localStorage.getItem("website_technology")) !== 3 ? */}
                  {parseInt(localStorage.getItem("website_technology")) !==
                      2 &&
                  <Link  to={"/app/connect/wp-grant-access" }
                  style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? 
                  "#3064f9" : localStorage.getItem('agency_primary_color'),
                  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9"
                   : localStorage.getItem('agency_primary_color')}}
                   
                   >Grant access</Link> }


                  <Link to={"/app/connect/troubleshoot-logs"}
                  style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" :
                   localStorage.getItem('agency_primary_color'),
                   "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" 
                   : localStorage.getItem('agency_primary_color')}}>Troubleshoot</Link>

                  <Link className="monitorLink" to={"/app/connect/monitor"}
                  style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" 
                  : localStorage.getItem('agency_primary_color'),
                  "--custom_color5": 
                  localStorage.getItem('agencyid') == 1 ? "#3064f9" :
                   localStorage.getItem('agency_primary_color')}}>Monitor</Link>
                {/* : null
                } */}
              </div>
              <div className='date-and-savediv'>
                {/* {lastdate && lastdate !== '' && lastdate !== undefined ?
                      <p className='consent-save-date'>Updated  {moment(lastdate).format(
                                "D MMMM YYYY, HH:mm"
                              )}{" "}</p>
                    : null
                    }
                <button type="submit" style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor')}} className={upgradeconset != 0 ? "consent-login-save-enable" : "consent-login-save-disable"} onClick={upgradeconset != 0 ? (e) => postConsentData(e) : void(0)}>
                    <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    {saveloader == true ?
                          <div className="new-loader"></div>
                    :
                          <div>Save</div>
                    }
                    </div>
                </button> */}
  {/* <p className="connectionupdatedate">Updated 10 Apr 2023, 11:45</p> */}
<div >
                        <button
                          className="connectivity-btn"
                          onClick={(e) => this.handlepluginverified(e)}
                          style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                        >
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <div className={this.state.loader}>
                            <div className="new-loader"></div>
                            </div>{" "}
                            <div className="varifytext">
                              {this.state.submitval}
                            </div>
                          </div>
                        </button>
                      </div>
              </div>

              

            </div>
            {this.state.spinner ? (
                <div className="connectivityspinn connectivityspinner">
                  <div className="" style={{zIndex: "1"}}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                      width="200px"
                      height="200px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g
                        transform="translate(20 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#e6261f"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.375s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(40 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#f7d038"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(60 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#49da9a"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(80 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#4355db"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                </div>
              ) : (
            <div className="new-div">

           
            <div
              className=" custon_gap2  panel1"
              // style={{ minHeight: "80vh" }}
            >
             
                <>
                  {/* <div
                    className="d-flex d-md-none justify-content-between setting-name ipad-trouble-subtext panel3"
                    style={{ background: "#F1F5FD" }}
                  >
                    <div className="w-100">
                      <div className="app_iconname  d-flex justify-content-between align-items-center">
                        <div className="app-head-ul d-block">
                        
                          <h5 className="d-block mt-2">
                          The plugin will act as a bridge between your WordPress website and your app. After you download and activate the plugin, your website content should start appearing within the app.
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* Email Verification */}

                  {window.matchMedia("(max-width: 700px)").matches && (
                    <div className="d-flex d-md-none w-100">
                      <Verifyemailstatus />
                    </div>
                  )}
                  {/* Email Verification End*/}

                  {/* <div className="d-flex  align-items-center justify-content-between borderr headmargin ">
                    <div className="d-flex justify-content-between w-100 align-items-center panel3">
                      <div className="app_iconname d-md-block d-none installheader">
                        <div className="d-flex  align-items-center">
                        
                           <h3 className="downloadheader">
                           Install Plugin
                          </h3>
                        
                        </div>
                        <h5 className=" ipad-plugin-subtext headerh5">
                          In order to connect your website to your app, you must
                          Install the plugin on your website.{" "}
                        
                        </h5>
                      </div>
                      <div className="fixed-button">
                        <button
                          className="next_btn takefull"
                          onClick={(e) => this.handlepluginverified(e)}
                          style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                        >
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <div className={this.state.loader}>
                              <img src={this.state.loaderimage} alt="loader" />
                            </div>{" "}
                            <div className="varifytext">
                              {this.state.submitval}
                            </div>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div> */}
                  <div
                    className="right_general "
                    style={{
                      opacity: this.state.spinner === true ? 0 : 1,
                      justifyContent: "space-evenly",
                    }}
                  >
                    {/* <div className=" col-md-6 pd-3 show-menu-mobile-preview show-menu-mobile-flex"> */}
                    <div className="rowdiv">
                    
                      <div className="divpanel">
                   

                        <img
                          className="wordpressimage"
                          src={
                            require("../../assets/images/dashboard/connect/wordpress.png")
                              .default
                          }
                          alt="Goto"
                        />
                        <h2 className="pluginheadtext">Wordpress plugin</h2>
                        <h5 className="pluginhead">
                        Our plugin will work as a bridge between your WordPress website and your app. Once installed and activated, your website content will sync with your app.
                        </h5>
                        <div className="pluggedinornot">
                          <div className="plugin_img images">
                            {this.state.is_verified === "0" ? (
                              <img
                                src={
                                  require("../../assets/images/dashboard/redCross.png")
                                    .default
                                }
                                alt="plugin"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../assets/images/dashboard/greentick.png")
                                    .default
                                }
                                alt="plugin"
                                className="img-fluid success-mobile-size"
                              />
                            )}
                          </div>

                          <div className="plugin_content text-center plugin pluginwebsite">
                          {this.state.is_verified === "0" ?
                                <span>Disconnected</span> : <span>Connected</span> }
                            {/* <h4>
                              Plugin{" "}
                              <span className="text-center">
                                {this.state.is_verified === "0" ?
                                <span>Disconnected</span> : <span>Connected</span> 
                                  ? " not found"
                                  : " found"}
                              </span>{" "}
                              on your website.
                            </h4> */}
                          </div>
                        </div>
                        <div className="intallplugin-generate userpasswordFont">
                          <h4>License key
                          <p className="connectivity-tooltip">
                          <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
  <g id="Group_41154" data-name="Group 41154" transform="translate(-1727 -19.132)">
    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
      <circle cx="7" cy="7" r="7" stroke="none"/>
      <circle cx="7" cy="7" r="6.4" fill="none"/>
    </g>
    <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
    <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
  </g>
</svg>
              <p className="connectivity-tooltipsubheader">
              Once you have installed and activated our plugin, simply copy and paste this key on the Plugin dashboard on your WordPress website. The license key helps you unlock in-depth troubleshooting capabilities for better connectivity between your website and app.
              </p>
            </p>
                          </h4>
                          <input placeholder="SPQXV-8A46W-YXND2-JKNGP-LFLV" value={this.state.generatedLicenseKey} disabled/>
                        {
                          this.state.generatedLicenseKey &&
                          <svg className="copysvg" style={{
                            cursor:"pointer"
                          }} onClick={(e)=>this.handleCopyLicenseKey(e)} id="Group_40243" data-name="Group 40243" xmlns="http://www.w3.org/2000/svg" width="14.001" height="14" viewBox="0 0 14.001 14">
                            <g id="Path_9276" data-name="Path 9276" transform="translate(-450.5 -124.5)" fill="none" stroke-miterlimit="10">
                              <path d="M461.5,125.923V128.5h-5.72a1.189,1.189,0,0,0-1.28,1.28v5.72h-2.577a1.419,1.419,0,0,1-1.423-1.423v-8.154a1.419,1.419,0,0,1,1.423-1.423h8.154A1.419,1.419,0,0,1,461.5,125.923Z" stroke="none"/>
                              <path d="M 451.9228515625 125.5000152587891 C 451.6857299804688 125.5000152587891 451.5 125.6857452392578 451.5 125.9228668212891 L 451.5 134.0771636962891 C 451.5 134.3142700195312 451.6857299804688 134.5 451.9228515625 134.5 L 453.5 134.5 L 453.5 129.7800140380859 C 453.5 128.4375762939453 454.4375610351562 127.5 455.7799682617188 127.5 L 460.5 127.5 L 460.5 125.9228668212891 C 460.5 125.6857452392578 460.3142395019531 125.5000152587891 460.0771179199219 125.5000152587891 L 451.9228515625 125.5000152587891 M 451.9228515625 124.5000152587891 L 460.0771179199219 124.5000152587891 C 460.8656616210938 124.5000152587891 461.5 125.1343078613281 461.5 125.9228668212891 L 461.5 128.5 L 455.7799682617188 128.5 C 454.9913940429688 128.5 454.5 128.991455078125 454.5 129.7800140380859 L 454.5 135.5 L 451.9228515625 135.5 C 451.13427734375 135.5 450.5 134.8657531738281 450.5 134.0771636962891 L 450.5 125.9228668212891 C 450.5 125.1343078613281 451.13427734375 124.5000152587891 451.9228515625 124.5000152587891 Z" stroke="none" fill="#bcc1ce"/>
                            </g>
                            <g id="Path_9277" data-name="Path 9277" transform="translate(-452.499 -126.5)" fill="none" stroke-miterlimit="10">
                              <path d="M466.5,130.923v8.154a1.419,1.419,0,0,1-1.423,1.423h-8.154a1.419,1.419,0,0,1-1.423-1.423v-8.154a1.419,1.419,0,0,1,1.423-1.423h8.154A1.419,1.419,0,0,1,466.5,130.923Z" stroke="none"/>
                              <path d="M 456.9228210449219 130.5000152587891 C 456.6856994628906 130.5000152587891 456.4999694824219 130.6857452392578 456.4999694824219 130.9228515625 L 456.4999694824219 139.0771484375 C 456.4999694824219 139.3142700195312 456.6856994628906 139.5000152587891 456.9228210449219 139.5000152587891 L 465.0771179199219 139.5000152587891 C 465.3142395019531 139.5000152587891 465.4999694824219 139.3142700195312 465.4999694824219 139.0771484375 L 465.4999694824219 130.9228515625 C 465.4999694824219 130.6857452392578 465.3142395019531 130.5000152587891 465.0771179199219 130.5000152587891 L 456.9228210449219 130.5000152587891 M 456.9228210449219 129.5000152587891 L 465.0771179199219 129.5000152587891 C 465.8656921386719 129.5000152587891 466.4999694824219 130.1342926025391 466.4999694824219 130.9228515625 L 466.4999694824219 139.0771484375 C 466.4999694824219 139.86572265625 465.8656921386719 140.5000152587891 465.0771179199219 140.5000152587891 L 456.9228210449219 140.5000152587891 C 456.1342163085938 140.5000152587891 455.4999694824219 139.86572265625 455.4999694824219 139.0771484375 L 455.4999694824219 130.9228515625 C 455.4999694824219 130.1342926025391 456.1342163085938 129.5000152587891 456.9228210449219 129.5000152587891 Z" stroke="none" fill="#bcc1ce"/>
                            </g>
                          </svg> }
                          {
                            !this.state.generatedLicenseKey && parseInt(this.state.is_verified) === 1 &&
                          
                          <span 
                            className="ip-generate-section" 
                            onClick={(e)=>this.handleGenerateKey(e)}
                            style={{
                              top:( window.matchMedia("(max-width: 1200px)").matches && !this.state.generateSpinner ) ?0:"",
                              right:( window.matchMedia("(max-width: 1200px)").matches && !this.state.generateSpinner ) ?0:"",
                            }}
                          >
                            {
                              this.state.generateSpinner ?  <div style={{
                                border:"2px solid #3064f9",
                                borderTop: "2px solid transparent",
                              }} className="new-loader"></div> : "Generate"
                            }
                           
                            </span> 
                            }
                           {
                            parseInt(this.state.is_verified) === 0 && !this.state.generatedLicenseKey &&
                           
                            <h5 className="generate-key-inactive">Generate</h5> }
                        </div>
                        
                        {/* <div className="stepsDiv">
                          <div className="step1">
                            <div className="iconImg">
                              <img
                                src={
                                  require("../../assets/images/dashboard/lockicon.png")
                                    .default
                                }
                                alt="Goto"
                              />
                            </div>
                            <label>Step 1</label>
                            <p>Login to your site’s backend admin panel</p>
                          </div>
                          <div className="step1">
                            <div className="iconImg">
                              <img
                                src={
                                  require("../../assets/images/dashboard/cloudDownload.png")
                                    .default
                                }
                                alt="Goto"
                              />
                            </div>
                            <label>Step 2</label>
                            
                            <p>Install & activate the plugin</p>
                          </div>
                          <div className="step2">
                            <div className="iconImg">
                              <img
                                src={
                                  require("../../assets/images/dashboard/plugin.png")
                                    .default
                                }
                                alt="Goto"
                              />
                            </div>
                            <label>Step 3</label>
                            <p>Connect your website with app</p>
                          </div>
                        </div> */}

                        {/* <div className="stepsDiv1">
                          <div className="row">
                            <div className="steps">
                              <img
                                src={
                                  require("../../assets/images/dashboard/lockicon.png")
                                    .default
                                }
                                alt="Goto"
                              />

                              <div className="stepsp">
                                <label>Step 1</label>
                                <p>Login to your site’s backend admin panel</p>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="steps">
                              <img
                                src={
                                  require("../../assets/images/dashboard/cloudDownload.png")
                                    .default
                                }
                                alt="Goto"
                              />

                              <div className="stepsp">
                                <label>Step 2</label>
                               
                                <p>Install & activate the plugin</p>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="steps">
                              <img
                                src={
                                  require("../../assets/images/dashboard/plugin.png")
                                    .default
                                }
                                alt="Goto"
                              />

                              <div className="stepsp">
                                <label>Step 3</label>
                                <p>Connect your website with app</p>
                              </div>
                            </div>
                          </div>
                        </div> */}

                        {/* <div className="downloadButtons">
                        <button className="downloads">Download Plugin<span>  <img
                              src={
                                require("../../assets/images/dashboard/Goto.png")
                                  .default
                              }
                              className="gotoappicon appear-spacing"
                              alt="Goto"
                            /></span></button>
                        <button className="gosite"   href={
                                        this.state.storeurl +
                                        "/wp-admin/plugin-install.php?s=appmysite&tab=search&type=term"
                                      }>Go to my site<span>  <img
                              src={
                                require("../../assets/images/dashboard/Goto.png")
                                  .default
                              }
                              className="gotoappicon appear-spacing"
                              alt="Goto"
                            /></span></button>
                       </div> */}
                        {(localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                             localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                             localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ?
                        <div className="d-flex align-items-center downloadButtons">
                          <a
                            href="https://wordpress.org/plugins/appmysite/"
                            rel="noopener noreferrer"
                            target="_blank"
                            className="  downloads"
                            style={{backgroundColor:localStorage.getItem('button_background_color') && localStorage.getItem('button_background_color') != 'undefined' && localStorage.getItem('button_background_color') != 'null' ? localStorage.getItem('button_background_color') : '',color:localStorage.getItem('button_text_color')}}
                        
                          >
                            Download Plugin{" "}
                            <img
                              src={
                                require("../../assets/images/dashboard/Goto.png")
                                  .default
                              }
                              className="dashboard-gotospace"
                              alt="Goto"
                            />
                          </a>
                          <a
                            className="gosite"
                            target="blank"
                            href={
                              this.state.storeurl +
                              "/wp-admin/plugin-install.php?s=appmysite&tab=search&type=term"
                            }
                          >
                            <div className="">
                            Install plugin{" "}
                              <img
                                src={
                                  require("../../assets/images/dashboard/Goto.png")
                                    .default
                                }
                                className="dashboard-gotospace"
                                alt="Goto"
                              />
                            </div>
                          </a>
                        </div>
                        : <></>
                        }

                        {/* <div className="installed-plugin pluggedinornot">
                          <div className="plugin_img images">
                            {this.state.is_verified === "0" ? (
                              <img
                                src={
                                  require("../../assets/images/dashboard/redCross.png")
                                    .default
                                }
                                alt="plugin"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../assets/images/dashboard/greentick.png")
                                    .default
                                }
                                alt="plugin"
                                className="img-fluid success-mobile-size"
                              />
                            )}
                          </div>

                          <div className="plugin_content text-center plugin pluginwebsite">
                            <h4>
                              Plugin
                              <span className="text-center">
                                {this.state.is_verified === "0"
                                  ? " not found"
                                  : " found"}
                              </span>{" "}
                              on your website.
                            </h4>
                          </div>
                        </div> */}
                      </div>

                      <div className="divpanel1">
                        <div className="installed-plugin">
                          <div className="plugin_img">
                            {this.state.is_verified === "0" ? (
                              <img
                                src={
                                  require("../../assets/images/dashboard/notconnectedimg.png")
                                    .default
                                }
                                alt="plugin"
                                className="img-fluid"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../assets/images/dashboard/stablistconnectimg.png")
                                    .default
                                }
                                alt="plugin"
                                className="img-fluid success-mobile-size"
                              />
                            )}
                          </div>

                          {/* <div className="plugin_content text-center plugin">
                            <h4>
                              Plugin
                              <span className="text-center">
                                {this.state.is_verified === "0"
                                  ? " not found"
                                  : " found"}
                              </span>{" "}
                              on your website.
                            </h4>
                          </div> */}
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </>
        
            </div>
            <div className="mob-connect-varify">
          
                        <button
                          className="connectivity-btn"
                          onClick={(e) => this.handlepluginverified(e)}
                          style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                        >
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <div className={this.state.loader}>
                            <div className="new-loader"></div>
                            </div>{" "}
                            <div className="varifytext">
                              {this.state.submitval}
                            </div>
                          </div>
                        </button>
                
                        {/* <p>Updated 10 Apr 2023, 11:45</p> */}
                      </div>
            <div className="stepsDiv">
                          <div className="step1">
                            <div className="iconImg">
                              <img
                                src={
                                  require("../../assets/images/dashboard/connectstep1.png")
                                    .default
                                }
                                alt="Goto"
                              />
                            </div>
                            <div>
                            <label>Step 1</label>
                            <p>Login to your site’s backend</p>
                            </div>
                          </div>
                          <div className="step1">
                            <div className="iconImg">
                              <img
                                src={
                                  require("../../assets/images/dashboard/connectstep2.png")
                                    .default
                                }
                                alt="Goto"
                              />
                            </div>
                            <div>
                            <label>Step 2</label>
                            {/* <p>Install & activate the AppMySite plugin</p> */}
                            <p>Install & activate the plugin</p>
                            </div>
                            
                          </div>
                          <div className="step1">
                            <div className="iconImg">
                              <img
                                src={
                                  require("../../assets/images/dashboard/connectstep3.png")
                                    .default
                                }
                                alt="Goto"
                              />
                            </div>
                            <div>
                            <label>Step 3</label>
                            <p> Verify your connectivity</p>
                            </div>
                            
                          </div>
                        </div>
                        </div> )}
            {/* {InnerFoot()} */}
          </div>
        </section>
        {/* <Modal
          isOpen={this.state.modal}
          toggle={this.togglemodal}
          style={{ maxWidth: "680px", maxHeight: "247px" }}
          className={this.props.className}
          centered
        >
          <ModalHeader toggle={this.togglemodal} className="modal-xxl-content">
            <div className="crossmodal mt-1" onClick={this.togglemodal}>
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="conecttoapi">
            <div className="text-center mb-5">
              <h2 className="mb-3">We could not connect to your website</h2>
              <h3>
                This step is mandatory to sync your website with the mobile app.
              </h3>
            </div>
            <div className="text-center mb-5">
              <img
                src={
                  require("../../assets/images/dashboard/previewapi.png")
                    .default
                }
                alt="Connecting"
                className="img-fluid"
              />
            </div>
            {this.state.website_type ===
              process.env.REACT_APP_webitetype_first_option ||
            this.state.website_type ===
              process.env.REACT_APP_webitetype_second_option ||
            this.state.website_type ===
              process.env.REACT_APP_webitetype_third_option ? (
              <div className="text-center mb-5">
                <p>
                  {this.state.plugincontent ===
                  "You are currently not logged in."
                    ? "We were unable to connect to your website via Basic Auth."
                    : this.state.plugincontent}
                </p>
              </div>
            ) : (
              <div className="text-center mb-5">
                <p>
                  {this.state.plugincontent ===
                  "You are currently not logged in."
                    ? "We were unable to connect to your website via Basic Auth."
                    : this.state.plugincontent}
                </p>
              </div>
            )}
            <div className="text-center mb-4">
              <Link to="#" onClick={this.togglemodal}>
                OKAY
              </Link>
            </div>
            <div className="text-center underpopup">
              <Troubleshootingwithoutcsck
                storeurl={this.state.troubleshooturl.trim()}
                emailModal={this.emailModal}
              />
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modalVideo}
          toggle={this.togglemodalVideo}
          style={{ width: "100%", height: "500px", maxWidth: "1067px" }}
          className="modal-xl youtubevid"
          centered
        >
          <ModalHeader
            toggle={this.togglemodalVideo}
            className="modal-categories"
          >
            <div
              className="crossmodal mb-2 ml-2"
              onClick={this.togglemodalVideo}
            >
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#fff"
                  stroke="#fff"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="update-woo-plugin p-0">
            <div className="text-center ">
              <iframe
                width="100%"
                height="600px"
                src="https://www.youtube.com/embed/-u2jYkZy-JU?start=490&autoplay=1"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="video"
              ></iframe>
            </div>
          </ModalBody>
        </Modal> */}
         <Modal
          isOpen={this.state.modalError}
          toggle={this.togglemodalError}
          style={{ maxWidth: "480px" }}
          className="module1"
          centered
        >
          <ModalHeader
            toggle={this.togglemodalError}
            className="modal-xxl-content modal-categories modal_toheader"
          >
            <div className="connect-crossmodal mt-1" onClick={this.togglemodalError}>
              <img
                src={require("../../assets/images/dashboard/cross.png").default}
                alt="alert"
              />
            </div>
          </ModalHeader>
         
          <ModalBody className="update-woo-plugin update-woo-plugin1">
            <div className="text-center mb-4 wronguser">
              <h2 className="mb-3 ">{"Unable to establish a connection"}</h2>
              <h3>{
                
                "We couldn't establish a connection with your website.  Please troubleshoot to address the connectivity  issue and try again. "
                } </h3>
            </div>
            <div className="connect-troubleshootdiv">
            <div className="text-center conecttoapi1" onClick={this.togglemodalError}>
              <Link to="#" >
              Cancel
              </Link>
            </div>
            <div className="text-center connect-underpopup">
              <Troubleshooting
                className="linktroubleshoot"
                // cs={this.state.apikey.trim()}
                // ck={this.state.apisecret.trim()}
                // storeurl={this.state.websiteurl.trim()}
                // emailModal={this.emailModal}
              />
            </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  refetchItems: () => dispatch(appDashboardApiSlice.endpoints.appDashboardData.initiate()).refetch(),
  refetchBottomBarData: () =>
    dispatch(bottomApiSlice.endpoints.bottomData.initiate()).refetch(),
  refetchMenuData: () =>
    dispatch(menuApiSlice.endpoints.menuData.initiate()).refetch(),
  refetchSettings: () => dispatch(generalSettingsApiSlice.endpoints.getGeneralSettings.initiate({
    app_id: btoa(localStorage.getItem("Appid")),
    workspace_id:
      localStorage.getItem("workspaceId") &&
      localStorage.getItem("workspaceId") !== "undefined" &&
      localStorage.getItem("workspaceId") !== "null"
        ? btoa(localStorage.getItem("workspaceId"))
        : btoa(null),
  }
)).refetch(),
});

export default connect(mapStateToProps, mapDispatchToProps)(Downloadplugin)