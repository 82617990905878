import React from "react";
import { useMemo } from "react";
import defaultImg from "../../../assets/images/dashboard/homescreen-banner-default.png";

import defaultImgCol1 from "../../../assets/images/dashboard/homescreen/1_column.png";
import defaultImgCol2 from "../../../assets/images/dashboard/homescreen/2_column.png";
import defaultImgCol3 from "../../../assets/images/dashboard/homescreen/3_column.png";
import defaultImgCol4 from "../../../assets/images/dashboard/homescreen/4_column.png";

const CategoryPreview = ({
  blockIndx,
  catPosition,
  catItemType,
  catLayout,
  catEnable,
  catColumn,
  catRow,
  catShape,
  catShowImage,
  catPadding,
  catShowHeading,
  catHeadingColor,
  catHeadingIconColor,
  catHeadingIconBgColor,
  catWholeSectionBgColor,
  catBgColor,
  catTextColor,
  catHeadingLabel,
}) => {
  const sheetCreator = () => {
    if (!window["new" + blockIndx + "sheet"]) {
      window["new" + blockIndx + "sheet"] = document.createElement("style");
      document.body.appendChild(window["new" + blockIndx + "sheet"]);
    }
  };

  useMemo(() => sheetCreator(), []);
  const changingSheetStyle = (imageNo) => {
    window["new" + blockIndx + "sheet"].innerHTML =
      `.applybgcolor${blockIndx}::before {background:` + catBgColor + "}";
  };

  changingSheetStyle();

  const calculateHeight = (width, layout) => {
    let height = (width * 3) / 4;

    switch (true) {
      case layout.image_aspect_ratio === "4:3":
        height = (width * 3) / 4;
        break;
      case layout.image_aspect_ratio === "3:4":
        height = (width * 4) / 3;
        break;
      case layout.image_aspect_ratio === "1:1":
        height = (width * 1) / 1;
        break;
      // case "16:9":
      //   height = (width * 9) / 16;
      //   break;
    }
    return height;
  };
  return (
    <>
      {catShowHeading === 1 && (
        <div className="category-head">
          <h2
            style={{
              color: catHeadingColor,
            }}
          >
            {catItemType === "product_categories"
              ? catHeadingLabel
                ? catHeadingLabel
                : "Product Categories"
              : catHeadingLabel
              ? catHeadingLabel
              : "Blog Categories"}
          </h2>
          <div
            className="seemoresection"
            style={{
              background: catHeadingIconBgColor,
            }}
          >
            <svg
              id="Group_26467"
              data-name="Group 26467"
              width="16"
              height="16"
              viewBox="0 0 16 16"
            >
              {/* <circle
                id="Ellipse_6953"
                data-name="Ellipse 6953"
                cx="8"
                cy="8"
                r="8"
                // style={{
                //   background: blogHeadingIconBgColor,
                // }}
                fill={blogHeadingIconBgColor}
              /> */}
              <path
                id="Path_81302"
                data-name="Path 81302"
                d="M0,0,2.986,2.987,5.973,0"
                transform="translate(6.499 11.473) rotate(-90)"
                fill="none"
                stroke={catHeadingIconColor}
                stroke-width="1.5"
              />
            </svg>
          </div>
        </div>
      )}
      {catColumn === 1 &&
        catShape === "round_corner" &&
        Array.from({
          length: catRow,
        }).map((it, index) => {
          if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "outside_bottom"
          ) {
            return (
              <div className="home-cat-layout1 onexlayout round">
                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      position: "relative",

                      //   backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol1 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(272, catLayout) + "px",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "inside_center"
          ) {
            return (
              <div className="home-cat-layout1 onexlayout round">
                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol1 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(272, catLayout) + "px",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_right"
          ) {
            return (
              <div
                className="home-cat-layout2"
                style={{
                  borderRadius: "10px",
                  position: "relative",
                  background: catBgColor,
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a href=""></a>
                <img
                  className="img-fluid singlecatarrow"
                  src={
                    require("../../../assets/images/dashboard/arrow-grey.png")
                      .default
                  }
                />

                <div
                  style={{
                    // backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    // backgroundColor: "white",
                    // backgroundSize: "cover",
                    backgroundImage:
                      catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    borderRadius: "10px",
                    width: catShowImage === 1 ? "64px" : "0px",
                    // height: "64px",
                    minWidth: catShowImage === 1 ? "64px" : "0px",
                    height: calculateHeight(64, catLayout) + "px",
                  }}
                ></div>

                <p
                  style={{
                    color: catTextColor,
                  }}
                >
                  Category
                </p>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_left"
          ) {
            return (
              <div className="third-cat-layout">
                <div
                  className="home-cat-layout3"
                  style={{
                    borderRadius: "10px",
                    position: "relative",
                    background: catBgColor,
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a href=""></a>

                  <p
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </p>

                  <div
                    style={{
                      // backgroundColor: "white",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "10px",
                      width: catShowImage === 1 ? "64px" : "0px",
                      height: calculateHeight(64, catLayout) + "px",
                      minWidth: catShowImage === 1 ? "64px" : "0px",
                    }}
                  ></div>
                </div>
              </div>
            );
          }
        })}
      {catColumn === 1 &&
        catShape === "circular" &&
        Array.from({
          length: catRow,
        }).map((it, index) => {
          if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "outside_bottom"
          ) {
            return (
              <div className="home-cat-layout1 onexlayout circle">
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol1 + ")" : "",
                      borderRadius: "100%",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "inside_center"
          ) {
            return (
              <div className="home-cat-layout1 onexlayout circle">
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol1 + ")" : "",
                      borderRadius: "100%",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_right"
          ) {
            return (
              <div
                className="home-cat-layout2"
                style={{
                  borderRadius: "10px",
                  position: "relative",
                  background: catBgColor,
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a href=""></a>
                <img
                  className="img-fluid singlecatarrow"
                  src={
                    require("../../../assets/images/dashboard/arrow-grey.png")
                      .default
                  }
                />
                <div
                  style={{
                    // backgroundColor: "white",
                    // backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    // backgroundSize: "cover",
                    backgroundImage:
                      catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    borderRadius: "100%",
                    width: catShowImage === 1 ? "64px" : "0px",
                    height: "64px",
                    minWidth: catShowImage === 1 ? "64px" : "0px",
                  }}
                ></div>

                <p
                  style={{
                    color: catTextColor,
                  }}
                >
                  Category
                </p>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_left"
          ) {
            return (
              <div className="third-cat-layout">
                <div
                  className="home-cat-layout3"
                  style={{
                    borderRadius: "10px",
                    position: "relative",
                    background: catBgColor,
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a href=""></a>

                  <p
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </p>

                  <div
                    style={{
                      // backgroundColor: "white",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "100%",
                      width: catShowImage === 1 ? "64px" : "0px",
                      height: "64px",
                      minWidth: catShowImage === 1 ? "64px" : "0px",
                    }}
                  ></div>
                </div>
              </div>
            );
          }
        })}
      {catColumn === 1 &&
        catShape === "sharp_corner" &&
        Array.from({
          length: catRow,
        }).map((it, index) => {
          if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "outside_bottom"
          ) {
            return (
              <div className="home-cat-layout1 onexlayout sharp">
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol1 + ")" : "",
                      height: calculateHeight(272, catLayout) + "px",
                      // borderRadius: "10px",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "inside_center"
          ) {
            return (
              <div className="home-cat-layout1 onexlayout sharp">
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(272, catLayout) + "px",
                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol1 + ")" : "",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_right"
          ) {
            return (
              <div
                className="home-cat-layout2"
                style={{
                  borderRadius: "10px",
                  position: "relative",
                  background: catBgColor,
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a href=""></a>
                <img
                  className="img-fluid singlecatarrow"
                  src={
                    require("../../../assets/images/dashboard/arrow-grey.png")
                      .default
                  }
                />
                <div
                  style={{
                    // backgroundColor: "white",
                    // backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    // backgroundSize: "cover",
                    backgroundImage:
                      catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    // borderRadius: "10px",
                    width: catShowImage === 1 ? "64px" : "0px",
                    height: calculateHeight(64, catLayout) + "px",
                    minWidth: catShowImage === 1 ? "64px" : "0px",
                  }}
                ></div>

                <p
                  style={{
                    color: catTextColor,
                  }}
                >
                  Category
                </p>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_left"
          ) {
            return (
              <div className="third-cat-layout">
                <div
                  className="home-cat-layout3"
                  style={{
                    borderRadius: "10px",
                    position: "relative",
                    background: catBgColor,
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a href=""></a>

                  <p
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </p>

                  <div
                    style={{
                      // backgroundColor: "white",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      // borderRadius: "10px",
                      width: catShowImage === 1 ? "64px" : "0px",
                      height: calculateHeight(64, catLayout) + "px",
                      minWidth: catShowImage === 1 ? "64px" : "0px",
                    }}
                  ></div>
                </div>
              </div>
            );
          }
        })}

      {/* ------------------------------------------------------------------------------ */}

      {catColumn === 2 &&
        catShape === "round_corner" &&
        Array.from({
          length: catRow,
        }).map((it, index) => {
          if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "outside_bottom"
          ) {
            return (
              <div className="home-cat-layout1 twoxlayout round">
                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol2 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(131, catLayout) + "px",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>

                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol2 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(131, catLayout) + "px",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "inside_center"
          ) {
            return (
              <div className="home-cat-layout1 twoxlayout round">
                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol2 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(131, catLayout) + "px",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>

                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol2 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(131, catLayout) + "px",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_right"
          ) {
            return (
              <div
                className="home-cat-layout2"
                style={{
                  borderRadius: "10px",
                  position: "relative",
                  background: catBgColor,
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a href=""></a>
                <img
                  className="img-fluid singlecatarrow"
                  src={
                    require("../../../assets/images/dashboard/arrow-grey.png")
                      .default
                  }
                />
                <div
                  style={{
                    // backgroundColor: "white",
                    // backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    // backgroundSize: "cover",
                    backgroundImage:
                      catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    borderRadius: "10px",
                    width: catShowImage === 1 ? "64px" : "0px",
                    height: calculateHeight(64, catLayout) + "px",
                    minWidth: catShowImage === 1 ? "64px" : "0px",
                  }}
                ></div>

                <p
                  style={{
                    color: catTextColor,
                  }}
                >
                  Category
                </p>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_left"
          ) {
            return (
              <div className="third-cat-layout">
                <div
                  className="home-cat-layout3"
                  style={{
                    borderRadius: "10px",
                    position: "relative",
                    background: catBgColor,
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a href=""></a>

                  <p
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </p>

                  <div
                    style={{
                      // backgroundColor: "white",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "10px",
                      width: catShowImage === 1 ? "64px" : "0px",
                      height: calculateHeight(64, catLayout) + "px",
                      minWidth: catShowImage === 1 ? "64px" : "0px",
                    }}
                  ></div>
                </div>
              </div>
            );
          }
        })}

      {catColumn === 2 &&
        catShape === "circular" &&
        Array.from({
          length: catRow,
        }).map((it, index) => {
          if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "outside_bottom"
          ) {
            return (
              <div className="home-cat-layout1 twoxlayout circle">
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol2 + ")" : "",
                      borderRadius: "100%",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>

                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol2 + ")" : "",
                      borderRadius: "100%",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "inside_center"
          ) {
            return (
              <div className="home-cat-layout1 twoxlayout circle">
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol2 + ")" : "",
                      borderRadius: "100%",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>

                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol2 + ")" : "",
                      borderRadius: "100%",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_right"
          ) {
            return (
              <div
                className="home-cat-layout2"
                style={{
                  borderRadius: "10px",
                  position: "relative",
                  background: catBgColor,
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a href=""></a>
                <img
                  className="img-fluid singlecatarrow"
                  src={
                    require("../../../assets/images/dashboard/arrow-grey.png")
                      .default
                  }
                />
                <div
                  style={{
                    // backgroundColor: "white",
                    // backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    // backgroundSize: "cover",
                    backgroundImage:
                      catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    borderRadius: "100%",
                    width: catShowImage === 1 ? "64px" : "0px",
                    height: "64px",
                    minWidth: catShowImage === 1 ? "64px" : "0px",
                  }}
                ></div>

                <p
                  style={{
                    color: catTextColor,
                  }}
                >
                  Category
                </p>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_left"
          ) {
            return (
              <div className="third-cat-layout">
                <div
                  className="home-cat-layout3"
                  style={{
                    borderRadius: "10px",
                    position: "relative",
                    background: catBgColor,
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a href=""></a>

                  <p
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </p>

                  <div
                    style={{
                      // backgroundColor: "white",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "100%",
                      width: catShowImage === 1 ? "64px" : "0px",
                      height: "64px",
                      minWidth: catShowImage === 1 ? "64px" : "0px",
                    }}
                  ></div>
                </div>
              </div>
            );
          }
        })}

      {catColumn === 2 &&
        catShape === "sharp_corner" &&
        Array.from({
          length: catRow,
        }).map((it, index) => {
          if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "outside_bottom"
          ) {
            return (
              <div className="home-cat-layout1 twoxlayout sharp">
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(131, catLayout) + "px",
                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol2 + ")" : "",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>

                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(131, catLayout) + "px",
                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol2 + ")" : "",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "inside_center"
          ) {
            return (
              <div className="home-cat-layout1 twoxlayout sharp">
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(131, catLayout) + "px",
                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol2 + ")" : "",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>

                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(131, catLayout) + "px",
                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol2 + ")" : "",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_right"
          ) {
            return (
              <div
                className="home-cat-layout2"
                style={{
                  borderRadius: "10px",
                  position: "relative",
                  background: catBgColor,
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a href=""></a>
                <img
                  className="img-fluid singlecatarrow"
                  src={
                    require("../../../assets/images/dashboard/arrow-grey.png")
                      .default
                  }
                />
                <div
                  style={{
                    // backgroundColor: "white",
                    // backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    // backgroundSize: "cover",
                    backgroundImage:
                      catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    // borderRadius: "10px",
                    width: catShowImage === 1 ? "64px" : "0px",
                    height: calculateHeight(64, catLayout) + "px",
                    minWidth: catShowImage === 1 ? "64px" : "0px",
                  }}
                ></div>

                <p
                  style={{
                    color: catTextColor,
                  }}
                >
                  Category
                </p>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_left"
          ) {
            return (
              <div className="third-cat-layout">
                <div
                  className="home-cat-layout3"
                  style={{
                    borderRadius: "10px",
                    position: "relative",
                    background: catBgColor,
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a href=""></a>

                  <p
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </p>

                  <div
                    style={{
                      // backgroundColor: "white",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      // borderRadius: "10px",
                      width: catShowImage === 1 ? "64px" : "0px",
                      height: calculateHeight(64, catLayout) + "px",
                      minWidth: catShowImage === 1 ? "64px" : "0px",
                    }}
                  ></div>
                </div>
              </div>
            );
          }
        })}

      {/* --------------------------------------------------- */}

      {catColumn === 3 &&
        catShape === "round_corner" &&
        Array.from({
          length: catRow,
        }).map((it, index) => {
          if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "outside_bottom"
          ) {
            return (
              <div className="home-cat-layout1 threexlayout round">
                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(84, catLayout) + "px",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(84, catLayout) + "px",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>

                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(84, catLayout) + "px",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "inside_center"
          ) {
            return (
              <div className="home-cat-layout1 threexlayout round">
                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(84, catLayout) + "px",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(84, catLayout) + "px",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(84, catLayout) + "px",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_right"
          ) {
            return (
              <div
                className="home-cat-layout2"
                style={{
                  borderRadius: "10px",
                  position: "relative",
                  background: catBgColor,
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a href=""></a>
                <img
                  className="img-fluid singlecatarrow"
                  src={
                    require("../../../assets/images/dashboard/arrow-grey.png")
                      .default
                  }
                />
                <div
                  style={{
                    // backgroundColor: "white",
                    // backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    // backgroundSize: "cover",
                    backgroundImage:
                      catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    borderRadius: "10px",
                    width: catShowImage === 1 ? "64px" : "0px",
                    height: calculateHeight(64, catLayout) + "px",
                    minWidth: catShowImage === 1 ? "64px" : "0px",
                  }}
                ></div>

                <p
                  style={{
                    color: catTextColor,
                  }}
                >
                  Category
                </p>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_left"
          ) {
            return (
              <div className="third-cat-layout">
                <div
                  className="home-cat-layout3"
                  style={{
                    borderRadius: "10px",
                    position: "relative",
                    background: catBgColor,
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a href=""></a>

                  <p
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </p>

                  <div
                    style={{
                      // backgroundColor: "white",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "10px",
                      width: catShowImage === 1 ? "64px" : "0px",
                      height: calculateHeight(64, catLayout) + "px",
                      minWidth: catShowImage === 1 ? "64px" : "0px",
                    }}
                  ></div>
                </div>
              </div>
            );
          }
        })}

      {catColumn === 3 &&
        catShape === "circular" &&
        Array.from({
          length: catRow,
        }).map((it, index) => {
          if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "outside_bottom"
          ) {
            return (
              <div className="home-cat-layout1 threexlayout circle">
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                      borderRadius: "100%",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                      borderRadius: "100%",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>

                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                      borderRadius: "100%",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "inside_center"
          ) {
            return (
              <div className="home-cat-layout1 threexlayout circle">
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                      borderRadius: "100%",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                      borderRadius: "100%",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                      borderRadius: "100%",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_right"
          ) {
            return (
              <div
                className="home-cat-layout2"
                style={{
                  borderRadius: "10px",
                  position: "relative",
                  background: catBgColor,
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a href=""></a>
                <img
                  className="img-fluid singlecatarrow"
                  src={
                    require("../../../assets/images/dashboard/arrow-grey.png")
                      .default
                  }
                />
                <div
                  style={{
                    // backgroundColor: "white",
                    // backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    // backgroundSize: "cover",
                    backgroundImage:
                      catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    borderRadius: "100%",
                    width: catShowImage === 1 ? "64px" : "0px",
                    height: "64px",
                    minWidth: catShowImage === 1 ? "64px" : "0px",
                  }}
                ></div>

                <p
                  style={{
                    color: catTextColor,
                  }}
                >
                  Category
                </p>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_left"
          ) {
            return (
              <div className="third-cat-layout">
                <div
                  className="home-cat-layout3"
                  style={{
                    borderRadius: "10px",
                    position: "relative",
                    background: catBgColor,
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a href=""></a>

                  <p
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </p>

                  <div
                    style={{
                      // backgroundColor: "white",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "100%",
                      width: catShowImage === 1 ? "64px" : "0px",
                      height: "64px",
                      minWidth: catShowImage === 1 ? "64px" : "0px",
                    }}
                  ></div>
                </div>
              </div>
            );
          }
        })}

      {catColumn === 3 &&
        catShape === "sharp_corner" &&
        Array.from({
          length: catRow,
        }).map((it, index) => {
          if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "outside_bottom"
          ) {
            return (
              <div className="home-cat-layout1 threexlayout sharp">
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(84, catLayout) + "px",

                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(84, catLayout) + "px",

                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(84, catLayout) + "px",

                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "inside_center"
          ) {
            return (
              <div className="home-cat-layout1 threexlayout sharp">
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(84, catLayout) + "px",

                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(84, catLayout) + "px",

                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(84, catLayout) + "px",

                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol3 + ")" : "",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_right"
          ) {
            return (
              <div
                className="home-cat-layout2"
                style={{
                  borderRadius: "10px",
                  position: "relative",
                  background: catBgColor,
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a href=""></a>
                <img
                  className="img-fluid singlecatarrow"
                  src={
                    require("../../../assets/images/dashboard/arrow-grey.png")
                      .default
                  }
                />
                <div
                  style={{
                    // backgroundColor: "white",
                    // backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    // backgroundSize: "cover",
                    backgroundImage:
                      catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    // borderRadius: "10px",
                    width: catShowImage === 1 ? "64px" : "0px",
                    height: calculateHeight(64, catLayout) + "px",

                    minWidth: catShowImage === 1 ? "64px" : "0px",
                  }}
                ></div>

                <p
                  style={{
                    color: catTextColor,
                  }}
                >
                  Category
                </p>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_left"
          ) {
            return (
              <div className="third-cat-layout">
                <div
                  className="home-cat-layout3"
                  style={{
                    borderRadius: "10px",
                    position: "relative",
                    background: catBgColor,
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a href=""></a>

                  <p
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </p>

                  <div
                    style={{
                      // backgroundColor: "white",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      // borderRadius: "10px",
                      width: catShowImage === 1 ? "64px" : "0px",
                      height: calculateHeight(64, catLayout) + "px",

                      minWidth: catShowImage === 1 ? "64px" : "0px",
                    }}
                  ></div>
                </div>
              </div>
            );
          }
        })}

      {/* --------------------------------------------------------------------------------- */}
      {catColumn === 4 &&
        catShape === "round_corner" &&
        Array.from({
          length: catRow,
        }).map((it, index) => {
          if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "outside_bottom"
          ) {
            return (
              <div className="home-cat-layout1 fourxlayout round">
                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(60, catLayout) + "px",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(60, catLayout) + "px",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(60, catLayout) + "px",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>

                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(60, catLayout) + "px",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "inside_center"
          ) {
            return (
              <div className="home-cat-layout1 fourxlayout round">
                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(60, catLayout) + "px",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(60, catLayout) + "px",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(60, catLayout) + "px",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
                <div className="single-category round">
                  <div
                    className={`applybgcolor${blockIndx} backselectRound`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "10px",
                      height: calculateHeight(60, catLayout) + "px",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_right"
          ) {
            return (
              <div
                className="home-cat-layout2"
                style={{
                  borderRadius: "10px",
                  position: "relative",
                  background: catBgColor,
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a href=""></a>
                <img
                  className="img-fluid singlecatarrow"
                  src={
                    require("../../../assets/images/dashboard/arrow-grey.png")
                      .default
                  }
                />
                <div
                  style={{
                    // backgroundColor: "white",
                    // backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    // backgroundSize: "cover",
                    backgroundImage:
                      catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    borderRadius: "10px",
                    width: catShowImage === 1 ? "64px" : "0px",
                    height: calculateHeight(64, catLayout) + "px",

                    minWidth: catShowImage === 1 ? "64px" : "0px",
                  }}
                ></div>

                <p
                  style={{
                    color: catTextColor,
                  }}
                >
                  Category
                </p>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_left"
          ) {
            return (
              <div className="third-cat-layout">
                <div
                  className="home-cat-layout3"
                  style={{
                    borderRadius: "10px",
                    position: "relative",
                    background: catBgColor,
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a href=""></a>

                  <p
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </p>

                  <div
                    style={{
                      // backgroundColor: "white",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "10px",
                      width: catShowImage === 1 ? "64px" : "0px",
                      height: calculateHeight(64, catLayout) + "px",

                      minWidth: catShowImage === 1 ? "64px" : "0px",
                    }}
                  ></div>
                </div>
              </div>
            );
          }
        })}

      {catColumn === 4 &&
        catShape === "circular" &&
        Array.from({
          length: catRow,
        }).map((it, index) => {
          if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "outside_bottom"
          ) {
            return (
              <div className="home-cat-layout1 fourxlayout circle">
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "100%",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "100%",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "100%",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "100%",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "inside_center"
          ) {
            return (
              <div className="home-cat-layout1 fourxlayout circle">
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "100%",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "100%",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "100%",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
                <div className="single-category circle">
                  <div
                    className={`applybgcolor${blockIndx} backselectCircle`}
                    style={{
                      position: "relative",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "100%",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_right"
          ) {
            return (
              <div
                className="home-cat-layout2"
                style={{
                  borderRadius: "10px",
                  position: "relative",
                  background: catBgColor,
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a href=""></a>
                <img
                  className="img-fluid singlecatarrow"
                  src={
                    require("../../../assets/images/dashboard/arrow-grey.png")
                      .default
                  }
                />
                <div
                  style={{
                    // backgroundColor: "white",
                    // backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    // backgroundSize: "cover",
                    backgroundImage:
                      catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    borderRadius: "100%",
                    width: catShowImage === 1 ? "64px" : "0px",
                    height: "64px",
                    minWidth: catShowImage === 1 ? "64px" : "0px",
                  }}
                ></div>

                <p
                  style={{
                    color: catTextColor,
                  }}
                >
                  Category
                </p>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_left"
          ) {
            return (
              <div className="third-cat-layout">
                <div
                  className="home-cat-layout3"
                  style={{
                    borderRadius: "10px",
                    position: "relative",
                    background: catBgColor,
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a href=""></a>

                  <p
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </p>

                  <div
                    style={{
                      // backgroundColor: "white",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      borderRadius: "100%",
                      width: catShowImage === 1 ? "64px" : "0px",
                      height: "64px",
                      minWidth: catShowImage === 1 ? "64px" : "0px",
                    }}
                  ></div>
                </div>
              </div>
            );
          }
        })}

      {catColumn === 4 &&
        catShape === "sharp_corner" &&
        Array.from({
          length: catRow,
        }).map((it, index) => {
          if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "outside_bottom"
          ) {
            return (
              <div className="home-cat-layout1 fourxlayout sharp">
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(60, catLayout) + "px",

                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(60, catLayout) + "px",

                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(60, catLayout) + "px",

                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(60, catLayout) + "px",

                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    }}
                  ></div>
                  <h4
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </h4>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*2" &&
            catLayout.text_position === "inside_center"
          ) {
            return (
              <div className="home-cat-layout1 fourxlayout sharp">
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(60, catLayout) + "px",

                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(60, catLayout) + "px",

                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(60, catLayout) + "px",

                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
                <div className="single-category sharp">
                  <div
                    className={`applybgcolor${blockIndx} backselectSharp`}
                    style={{
                      position: "relative",
                      height: calculateHeight(60, catLayout) + "px",

                      // borderRadius: "10px",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    }}
                  >
                    <h4
                      style={{
                        color: catTextColor,
                      }}
                    >
                      Category
                    </h4>
                  </div>
                </div>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_right"
          ) {
            return (
              <div
                className="home-cat-layout2"
                style={{
                  borderRadius: "10px",
                  position: "relative",
                  background: catBgColor,
                  padding: "8px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <a href=""></a>
                <img
                  className="img-fluid singlecatarrow"
                  src={
                    require("../../../assets/images/dashboard/arrow-grey.png")
                      .default
                  }
                />
                <div
                  style={{
                    // backgroundColor: "white",
                    // backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    // backgroundSize: "cover",
                    backgroundImage:
                      catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                    // borderRadius: "10px",
                    width: catShowImage === 1 ? "64px" : "0px",
                    height: calculateHeight(64, catLayout) + "px",

                    minWidth: catShowImage === 1 ? "64px" : "0px",
                  }}
                ></div>

                <p
                  style={{
                    color: catTextColor,
                  }}
                >
                  Category
                </p>
              </div>
            );
          } else if (
            catLayout.name === "category_n*1" &&
            catLayout.text_position === "outside_left"
          ) {
            return (
              <div className="third-cat-layout">
                <div
                  className="home-cat-layout3"
                  style={{
                    borderRadius: "10px",
                    position: "relative",
                    background: catBgColor,
                    padding: "8px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <a href=""></a>

                  <p
                    style={{
                      color: catTextColor,
                    }}
                  >
                    Category
                  </p>

                  <div
                    style={{
                      // backgroundColor: "white",
                      //   backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      // backgroundSize: "cover",
                      backgroundImage:
                        catShowImage === 1 ? "url(" + defaultImgCol4 + ")" : "",
                      // borderRadius: "10px",
                      width: catShowImage === 1 ? "64px" : "0px",
                      height: calculateHeight(64, catLayout) + "px",

                      minWidth: catShowImage === 1 ? "64px" : "0px",
                    }}
                  ></div>
                </div>
              </div>
            );
          }
        })}
    </>
  );
};

export default CategoryPreview;
