import React from "react";
import Accountleftpanel from "../Accountleftpanel";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import "./Billings.css";
import Select from "react-select";
import "../../assets/css/newheader.css";
import "../../assets/css/profileleftpanel.css";
import Header from "../Header";
import ProfileLeftPanel from "../ProfileLeftPanel";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  Countrylist,
  GetuserprofileApi,
  BillingApi,
} from "../../Api/Dashboard/DashboardApi";
import { InnerFoot } from "../Foot";
import Billingheader from "./Billingheader";
import moment from "moment";
import ReactSelect from "react-select";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
export default class Billingpayment extends React.Component {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
    spinner: true,
    submitbillival: "Save",
    loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
    billingloader: "d-none",
    countryselectfield: [],
    reactSelectOptionsOfCountry: [],
    disabledsubmitbutton: false,
    //billing code
    first_name: "",
    last_name: "",
    company_name: "",
    billing_address: "",

    billing_city: "",
    billing_state: "",
    billing_zip: "",
    billing_country: "",
    email: "",
    tax_number: "",
    country_code: null,
    customer_type: null,
    customer_billing_type: "",
    right_panel_expand: true,
    first_name_err: false,
    last_name_err: false,
    add_line1_err: false,
    city_err: false,
    state_err: false,
    postal_code_err: false,
    country_err: false,
    updated_at: "",
    openCountryList:false,
    dropdownSearchVal:"",
    phonecodemodal:false ,
    latest_updated_at:null ,
      };
    }
  onLoad = () => {
    this.setState({
      spinner: true,
    });

    window.scroll(0, 0);
    Countrylist(this);
    GetuserprofileApi(this);
  };
  componentDidMount() {
    // document.title = "Billing details | AppMySite";
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
    {
      document.title = "Billing details | AppMySite";
    }
    else
    {
      document.title = "Billing details |"+ ' ' +localStorage.getItem('agencyname');      
    }
    document.addEventListener("mousedown", this.handleClickOutside);
    this.onLoad();
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
 }
 handleClickOutside(event) {
   try{

     if (this.wrapperRef &&  this.wrapperRef.current  && !this.wrapperRef.current.contains(event.target)) {
       
       
       this.setState({
         openCountryList:false
       })
       
     }
   }catch(err){
     // this.setState({
     //   openCountryList:!this.state.openCountryList
     // })
   }
 }
  handleformdata = (e, from) => {

    const { name, value } = from === "selectOptions" ? e === null ?
      {
        value: "",
        label: "Select country",
        country_code: "",
        // id:d.id ,
        name: "billing_country"
      } : e
      : e.target;
    this.setState({
      [name]: value,
    });
    if (name === "first_name" && value.toString().trim() !== "") {
      this.setState({
        first_name_err: false
      });
    }
    else if (name === "last_name" && value.toString().trim() !== "") {
      this.setState({
        last_name_err: false
      });
    }
    else if (name === "billing_address" && value.toString().trim() !== "") {
      this.setState({
        add_line1_err: false
      });
    }
    else if (name === "billing_city" && value.toString().trim() !== "") {
      this.setState({
        city_err: false
      });
    }
    else if (name === "billing_state" && value.toString().trim() !== "") {
      this.setState({
        state_err: false
      });
    }
    else if (name === "billing_zip" && value.toString().trim() !== "") {
      this.setState({
        postal_code_err: false
      });
    }
    else if (name === "billing_country" && value.toString().trim() !== "") {
      this.setState({
        country_err: false
      });
    }
  };
  handleRightPanelExpand = (rightpanelvalue) => {

    this.setState({
      right_panel_expand: rightpanelvalue
    })
  }
  billingsubmit = (e) => {
    e.preventDefault();
    let checkErr=false;
    const param = {
      app_id: "",
      workspace_id: parseInt(localStorage.getItem("workspaceId")),
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      company_name: this.state.company_name,
      billing_address: this.state.billing_address,
      billing_city: this.state.billing_city,
      billing_state: this.state.billing_state,
      billing_zip: this.state.billing_zip,
      billing_country: this.state.billing_country,
      tax_number: this.state.tax_number,
      page: "billing",
    };

    if (!param.first_name.trim()) {
      this.setState({
        first_name_err: true
      })
      checkErr=true;
    }
     if (!param.last_name.trim()) {
      this.setState({
        last_name_err: true
      })
      checkErr=true;
    }
if (!param.billing_address.trim()) {
      this.setState({
        add_line1_err: true
      })
      checkErr=true;
    }
    if (!param.billing_city.trim()) {
      this.setState({
        city_err: true
      })
      checkErr=true;
    }
   if (!param.billing_state.trim()) {
      this.setState({
        state_err: true
      })
      checkErr=true;
    }
   if (!param.billing_zip.trim()) {
      this.setState({
        postal_code_err: true
      })
      checkErr=true;
    }
 if (!param.billing_country) {
      this.setState({
        country_err: true
      })
      checkErr=true;
    }
    if(checkErr===false){
      BillingApi(param, this, () => {});
    }
    
  };

  handleCountryDropdown= (e,id) => {

    this.setState({
      country_err:false,
      billing_country: id,
      openCountryList:window.matchMedia("(max-width: 1200px)").matches?this.state.openCountryList :!this.state.openCountryList,
      phonecodemodal:window.matchMedia("(max-width: 1200px)").matches?!this.state.phonecodemodal :this.state.phonecodemodal
    })
  
  }

  handleOpenCoutryList =( )=>{
    this.setState({
      openCountryList:!this.state.openCountryList
    })
  }

  returnSelectedCountryName = (id) =>{
    let cName="";
    this.state.countryselectfield && this.state.countryselectfield.map((row,i)=>{
      
     if(id===row.id){
     cName=row.country_name
     }
    })
    return cName ;
  }

  handleDropdownSearch =(e)=>{
    this.setState({
      dropdownSearchVal:e.target.value
    })
  }

  togglephonecodemodal = () =>{
    this.setState({
      phonecodemodal:!this.state.phonecodemodal
    })
  }

  render() {
    

    const customStyles = {
      control: (base, state) => ({
        ...base,
        fontFamily: "Axiforma-Regular",
        border: "1px solid #bcc1ce",
        outline: "none!important",
        marginBottom: "15px !important",
        borderRadius: 3,
        // Overwrittes the different states of border
        height: 50,
        minHeight: 50,
        color: "#1b2952",
        paddingLeft: 10,
        boxShadow: state.isFocused ? "none" : "none",
        "&:hover": {
          boxShadow: state.isFocused ? "none" : "none",
        },

      })

    };
    const selectedOption = this.state.countryselectfield && this.state.countryselectfield.find(option => option.id == this.state.billing_country)

    return (
      <>
        
        <Header />

        <section className="portal-body ">
          {/* <Accountleftpanel ipadView={"initial-leftpanel-ipadview"} /> */}
          <ProfileLeftPanel rightpanelbody={this.handleRightPanelExpand} profile={""} />
          {/* <div className="transparent_background my-profile"> */}
          <div className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined &&
           localStorage.getItem('sidebaserotate') === 'true')  ? "right-panel-gap right-panel-gap-expand"
            : "right-panel-gap "}>
            
            <div className="align-items-center billings-head-flex billing-head-redesign billing-head-hideformobile">
              <h1 className="d-none d-md-block">Billing</h1>
              <p className="billing-tooltip">
                <img
                  src={
                    require("../../assets/images/dashboard/Billing/billingexclamation.png")
                      .default
                  }
                  alt="app-icon"
                  className=""
                />
                <p className="billing-tooltipsubheader">
                  View all your subscription and purchase activity here.
                  {/* <p className="billing-tooltip-learnmore">
                    Learn More
                    <img
                      src={
                        require("../../assets/images/dashboard/Goto.png").default
                      }
                      alt="app-icon"
                      className=""
                    />
                  </p> */}
                </p>
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <Billingheader value={this} />
              <div className="row scrollbar-settings">
                <div className="col-md-12 billing-page-padding-r ">
                  <div className="social_pannel bill billing_social_pannel">
                    <div className="d-md-flex d-block align-items-center justify-content-between  billings_bill-pay-version">

                      {/* {this.state.customer_billing_type !== 1 && this.state.customer_billing_type !== 3 &&
                            (localStorage.getItem("workspaceId") === "" ||
                              localStorage.getItem("workspaceId") ===
                                undefined ||
                              localStorage.getItem("workspaceId") ===
                                "undefined" ||
                              localStorage.getItem("workspaceId") === null) ? (
                              <div className="d-flex align-items-center justify-content-center redeemlink redeem-mob-space billing_redeem">
                                <a className="redeemlink-text" href="/redeem">
                                  Redeem your purchase
                                </a>
                              </div>
                            ) : null} */}
                    </div>
                    <div className="text-right profile-detail-btn border-bottom-0 billing-save-button">
                      {this.state.latest_updated_at && <p className="billing-latest-details-time">Updated {moment(
                          this.state.latest_updated_at
                        ).format("D MMMM YYYY, HH:mm")}</p>
                        // :
                        // this.state.updated_at && <p className="billing-latest-details-time">Updated {moment(
                        //   this.state.updated_at
                        // ).format("D MMMM YYYY, HH:mm")}</p>
                      }
                      <button
                        className="billings_next_btn"
                        disabled={this.state.disabledsubmitbutton}
                        type="submit"
                        form="form1"
                      >
                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                          <div className={this.state.billingloader}>
                            <img
                              src={this.state.loaderimage}
                              alt="loader"
                            />
                          </div>{" "}
                          <div>{this.state.submitbillival}</div>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
            className=" right_panel_billing billingdetails-billing_right_panel"
            style={{ minHeight: this.state.spinner ? "75vh": "509px" }}
            >

              <div className="right_general ">
                {this.state.spinner ? (
                  <div className="billingnewspinner">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                      width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                      <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          </animateTransform>
                        </circle>
                      </g>
                      <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          </animateTransform>
                        </circle>
                      </g>
                      <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>

                          </animateTransform>
                        </circle>
                      </g>
                      <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>

                          </animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                ) : (
                  <>


                    <div className="row mob-right-general">
                      <div className="details-mob-page-head">Billing details</div>
                      <div className="col-md-12 billing-mob-back">
                        <form id="form1" onSubmit={this.billingsubmit} className=" billings_subscriptions-redesign">

                          <div className="row mob-form-details">
                            <div className="billingdetail-colname ">
                              <label>First name* </label>

                              <div className="">
                                <input
                                  type="text"
                                  // required
                                  className={`form-control form-name ${this.state.first_name_err ? "billingdetails-validation" : ""}`}
                                  name="first_name"
                                  value={this.state.first_name}
                                  onChange={(e) => this.handleformdata(e)}
                                />
                              </div>
                              {
                                this.state.first_name_err &&


                                <div className="d-flex align-items-center billing-required">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/red-exclamation.png")
                                        .default
                                    }
                                    className="img-fluid"
                                    alt="error"
                                  />
                                  <p>Required</p>
                                </div>
                              }
                            </div>
                            <div className="billingdetail-colname  ">
                              <label>Last name* </label>
                              <div className="">
                                <input
                                  type="text"
                                  // required
                                  className={`form-control form-name ${this.state.last_name_err ? "billingdetails-validation" : ""}`}
                                  name="last_name"
                                  value={this.state.last_name}
                                  onChange={(e) => this.handleformdata(e)}
                                />
                              </div>
                              {
                                this.state.last_name_err &&


                                <div className="d-flex align-items-center billing-required">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/red-exclamation.png")
                                        .default
                                    }
                                    className="img-fluid"
                                    alt="error"
                                  />
                                  <p>Required</p>
                                </div>
                              }
                            </div>

                            <div className="billingdetail-colname ">
                              <label>Company</label>
                              <div className="">
                                <input
                                  type="text"
                                  className="form-control form-name"
                                  maxLength="30"
                                  name="company_name"
                                  value={this.state.company_name}
                                  onChange={(e) => this.handleformdata(e)}
                                />
                              </div>
                            </div>
                            <div className="billingdetail-colname ">
                              <label>Tax ID</label>
                              <div className="">
                                <input
                                  type="text"
                                  className="form-control form-name"
                                  maxLength="30"
                                  name="tax_number"
                                  value={this.state.tax_number}
                                  onChange={(e) => this.handleformdata(e)}
                                />
                              </div>
                            </div>

                            <div className="billingdetail-colname billingdetails-addressdiv">
                              <label>Address*</label>
                              <div className="">
                                <input
                                  type="text"
                                  // required
                                  className={`form-control form-name ${this.state.add_line1_err ? "billingdetails-validation" : ""}`}
                                  name="billing_address"
                                  value={this.state.billing_address}
                                  onChange={(e) => this.handleformdata(e)}
                                />
                              </div>
                              {
                                this.state.add_line1_err &&


                                <div className="d-flex align-items-center billing-required">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/red-exclamation.png")
                                        .default
                                    }
                                    className="img-fluid"
                                    alt="error"
                                  />
                                  <p>Required</p>
                                </div>
                              }
                            </div>

                            {/* <div className="billingdetail-colname ">
                              <label>Address Line 2</label>
                              <div className="">
                                <input
                                  type="text"
                                  
                                  className="form-control form-name"
                                  name="billing_address2"
                                  value={this.state.billing_address2}
                                  onChange={(e) => this.handleformdata(e)}
                                />
                              </div>
                            </div> */}

                            <div className="billingdetail-colname ">
                              <label>City*</label>
                              <div className="">
                                <input
                                  type="text"
                                  className={`form-control form-name ${this.state.city_err ? "billingdetails-validation" : ""}`}
                                  name="billing_city"
                                  value={this.state.billing_city}
                                  // required
                                  onChange={(e) => this.handleformdata(e)}
                                />
                              </div>
                              {
                                this.state.city_err &&


                                <div className="d-flex align-items-center billing-required">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/red-exclamation.png")
                                        .default
                                    }
                                    className="img-fluid"
                                    alt="error"
                                  />
                                  <p>Required</p>
                                </div>
                              }
                            </div>
                            <div className="billingdetail-colname ">
                              <label>State/Region*</label>
                              <div className="">
                                <input
                                  type="text"
                                  className={`form-control form-name ${this.state.state_err ? "billingdetails-validation" : ""}`}
                                  name="billing_state"
                                  value={this.state.billing_state}
                                  // required
                                  onChange={(e) => this.handleformdata(e)}
                                />
                              </div>
                              {
                                this.state.state_err &&


                                <div className="d-flex align-items-center billing-required">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/red-exclamation.png")
                                        .default
                                    }
                                    className="img-fluid"
                                    alt="error"
                                  />
                                  <p>Required</p>
                                </div>
                              }
                            </div>
                            <div className="billingdetail-colname ">
                              <label>Postal code*</label>

                              <div className="">
                                <input
                                  type="text"
                                  // required
                                  className={`form-control form-name ${this.state.postal_code_err ? "billingdetails-validation" : ""}`}
                                  maxLength="10"
                                  name="billing_zip"
                                  value={this.state.billing_zip}
                                  onChange={(e) => this.handleformdata(e)}
                                />
                              </div>
                              {
                                this.state.postal_code_err &&


                                <div className="d-flex align-items-center billing-required">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/red-exclamation.png")
                                        .default
                                    }
                                    className="img-fluid"
                                    alt="error"
                                  />
                                  <p>Required</p>
                                </div>
                              }
                            </div>
                            <div className="billingdetail-colname " ref={ this.wrapperRef}>
                              <label>Country*</label>

                              <div className="form-group billingdetail-selectdiv">

                                {/* <ReactSelect
                                 options={this.state.reactSelectOptionsOfCountry}
                                 styles={customStyles}
                                 required={true}
                                 name="billing_country"
                                 value={selectedOption}
                                 isClearable={false}
                                 placeholder={"Select country"}
                                 onChange={(e) => this.handleformdata(e,"selectOptions",)}
                                /> */}
                                <div className="billingdetails-countrydropdown" onClick={(e)=>window.matchMedia("(max-width: 1200px)").matches?
                                  this.togglephonecodemodal(e):this.handleOpenCoutryList(e)}>
                                    <p>{this.returnSelectedCountryName(this.state.billing_country)}</p>

                                {/* <select value={this.state.billing_country} disabled>
                                <option 
                              value=""
                              style={{ display: "none" }}
                            ></option>
                                  {
                                    this.state.countryselectfield.map((row,i)=>{
                                      return(
                                        <option key={i} value={row.id}>{row.country_name}</option>
                                      )
                                    })
                                  }
                                </select> */}
                                </div>
                               {
                                this.state.openCountryList &&
                                <div className="security-selectflag">
                                  <div className="d-flex security-selectflagtop">
                                    <input className="searchcountryinput" placeholder="Search..."
                                     value={this.state.dropdownSearchVal} onChange={(e)=>this.handleDropdownSearch(e)} />
                                    {this.state.dropdownSearchVal.trim() && <p onClick={(e)=>{
                                      this.setState({
                                        dropdownSearchVal:""
                                      })
                                    }} className="clear-search">Clear</p> }
                                  </div>
                                  <div className="securitycountrydiv">
                                    <div className=" countrytype">
                                      {/* <img
                                    src={
                                      require("../assets/images/dashboard/country-default.png")
                                        .default
                                    }
                                  />
                                  <p>United States (+1)</p> */}
                                      {this.state.countryselectfield
                                        .filter((name) =>
                                          name.country_name
                                            .toLowerCase()
                                            .includes(this.state.dropdownSearchVal.toLowerCase())
                                        )
                                        .map((res, i) => (
                                          <div
                                          onClick={(e) => this.handleCountryDropdown(e,res.id)}
                                            className="d-flex align-items-center justify-content-between country-line-flag"
                                            key={i}
                                          >
                                            <div  className="d-flex align-items-center justify-content-start"  
                                             >
                                              {/* <img
                                                src={
                                                  process.env.REACT_APP_Image_Path +
                                                  process.env.REACT_APP_SMALL_FLAG +
                                                  res.country_code.toLowerCase() +
                                                  ".png"
                                                }
                                                alt={i}
                                              /> */}
                                              <p className="country-name">
                                                {res.country_name} 
                                              </p>
                                            </div>

                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                </div>
                             }
                                {/* <DropdownButton
                                  className="font-dropdown"
                                 
                                  onSelect={(e) => this.handleCountryDropdown(e)}
                                 
                                  
                              alignRight
                              title={selectedOption&&selectedOption.country_name}
                              id="dropdown-menu-align-right"
                             
                              
                              value={this.state.textfontfamily}
                                >
                                 {
                                  this.state.countryselectfield.map((eachCountry,indx)=>{
                                    return(
                                      <Dropdown.Item
                                      
                                      eventKey={eachCountry.id}
                                    >
                                      {eachCountry.country_name}
                                    </Dropdown.Item>
                                     )
                                  })
                                 }  
                                
                                  </DropdownButton> */}
                                {/* <select
                                // className={`${this.state.country_err?"billingdetails-validation":""}`}
                                  required
                                  name="billing_country"
                                  value={this.state.billing_country}
                                  onChange={(e) => this.handleformdata(e)}
                                >
                                  <option value=""> Select Country</option>
                                  {this.state.countryselectfield.map(
                                    (res, i) => (
                                      <option key={i} value={res.id}>
                                        {" "}
                                        {res.country_name}
                                      </option>
                                    )
                                  )}
                                </select> */}
                              </div>
                              {
                                this.state.country_err &&


                                <div className="d-flex align-items-center billing-required">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/red-exclamation.png")
                                        .default
                                    }
                                    className="img-fluid"
                                    alt="error"
                                  />
                                  <p>Required</p>
                                </div>
                              }
                            </div>
                          </div>
                          <div className="text-right profile-detail-btn border-bottom-0 billing-savebtn">
                            <button
                              className="billings_next_btn"
                              disabled={this.state.disabledsubmitbutton}
                              type="submit"
                            >
                              <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                <div className={this.state.billingloader}>
                                  <img
                                    src={this.state.loaderimage}
                                    alt="loader"
                                  />
                                </div>{" "}
                                <div>{this.state.submitbillival}</div>
                              </div>
                            </button>
                            { this.state.latest_updated_at &&  <p className="billing-mob-latest-details-time">Updated {moment(
                                this.state.latest_updated_at
                              ).format("D MMMM YYYY, HH:mm")}</p>
                        // :
                        //       this.state.updated_at && 
                        //       <p className="billing-mob-latest-details-time">Updated {moment(
                        //         this.state.updated_at
                        //       ).format("D MMMM YYYY, HH:mm")}</p>
                        
                            }
                          </div>
                        </form>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* {InnerFoot()} */}
          </div>
        </section>

        {/*<Modal
          isOpen={this.state.isCard}
          centered
          className=" billing-country-model"
          style={{ maxWidth: "480px !important" }}
        >
          <ModalHeader 
             style={{ padding: "19px" }}
            className="modal-xxl-content"
          >
            <div className="crossmodal-delete " onClick={this.togglemodalError}>
              <img
                src={require("../../assets/images/dashboard/cross.png").default}
                alt="alert"
              />
            </div>
          </ModalHeader>
          <ModalBody className=" popup-spec-view ">
            <div>
             
             <p>India</p>
           
            </div>
          
          
          </ModalBody>
         
         </Modal> */}




      <Modal
          isOpen={this.state.phonecodemodal}
          // isOpen= {false}
          toggle={this.togglephonecodemodal}
          style={{ maxWidth: "700px", maxHeight: "350px" }}
          className={this.props.className + " security-code-modal"}
          centered
          dataclass=""
        >
          <ModalHeader
            toggle={this.togglephonecodemodal}
            className=" "
          >
         
            <div className="d-flex align-items-center justify-content-center security-country-search">
              <div className="d-flex align-items-center w-100 ">
                <div className="backaero-svgimg" onClick={this.togglephonecodemodal}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
                    <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
                      <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2" />
                      <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2" />
                    </g>
                  </svg>
                </div>

                <input  className="form-control searchfleginput" placeholder="Search..."
                                     value={this.state.dropdownSearchVal} onChange={(e)=>this.handleDropdownSearch(e)} />
                                    {this.state.dropdownSearchVal.trim() &&
                                     <p onClick={(e)=>{
                                      this.setState({
                                        dropdownSearchVal:""
                                      })
                                    }} className="clear-search">Clear</p> }
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="code-selector p-0">
          
            <div className=" profile-countrycode-flag ">
            {this.state.countryselectfield
                                        .filter((name) =>
                                          name.country_name
                                            .toLowerCase()
                                            .includes(this.state.dropdownSearchVal.toLowerCase())
                                        )
                                        .map((res, i) => (
                                          <div onClick={(e) => this.handleCountryDropdown(e,res.id)}
                                            className="d-flex align-items-center justify-content-between country-line-flag"
                                            key={i}
                                          >
                                            <div className="d-flex align-items-center justify-content-start"  
                                             >
                                              {/* <img
                                                src={
                                                  process.env.REACT_APP_Image_Path +
                                                  process.env.REACT_APP_SMALL_FLAG +
                                                  res.country_code.toLowerCase() +
                                                  ".png"
                                                }
                                                alt={i}
                                              /> */}
                                              <p className="country-name">
                                                {res.country_name} 
                                              </p>
                                            </div>

                                          </div>
                                        ))}
            </div>
          </ModalBody>
        </Modal>
         
      </>
    );
  }
}
