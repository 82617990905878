import React from 'react'

const LockedToggle = () => {
  return (
    <label className=" common-locktoggle-switch">
     <div className=" common-slider-round"></div>
    </label>
  )
}

export default LockedToggle;