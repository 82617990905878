import React, { useContext, useEffect, useState } from "react";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import { Link } from "react-router-dom";
import "./newLogin.css";
import Background from "../../assets/images/dashboard/consentimage.jpg";
import { FormGroup, Label, Input, Modal, ModalBody } from "reactstrap";
import Reactcolorpicker from "./../Appearance/NewReactcolorpicker";
import imagebackground from "./../../assets/images/dashboard/cms/uploadbackground.png";
import NewImageGalleryPopup from "../NewImageGalleryPopup";
import {
  GetconsentData,
  PostConsetData,
} from "./../../Api/Dashboard/DashboardApi";
import moment from "moment";
import VistaPopup from "../Appearance/VistaPopup";
import RebuildRequired from "../Appearance/RebuildRequired";
import SkipBtn from "../../Components/commonIcons/SkipBtn";
import { previewContext } from "../../App";
import PremiumTag from "../../Components/commonIcons/PremiumTag";


const Consent = () => {
  const [headingError, setHeadingError] = useState("");
  const [paragraphError, setParagraphError] = useState("");
  const [buttontextError, setbuttontextError] = useState("");
  const [exitbuttonError, setexitbuttonError] = useState("");
  const [rowOpen, setRowOpen] = useState("");
  const [toggle, setToggle] = useState(false);
  const [layout, setLayout] = useState("center");
  const [primaryColor, setprimaryColor] = useState("#1a1a1a");
  const [backgroundColor, setbackgroundColor] = useState("#1a1a1a");
  const [secondaryColor, setsecondaryColor] = useState("#a1a1a1");
  const [buttontextColor, setbuttontextColor] = useState("#ffffff");
  const [closebackgroundColor, setclosebackgroundColor] = useState(
    "rgba(255,255,255,0.1)"
  );
  const [presetColors, setpresetColors] = useState("");
  const [heading, setHeading] = useState(
    "You need to be 18 years or older to use this app."
  );
  const [paragraph, setParagraph] = useState(
    "Excessive consumption of alcohol may be injurious to health."
  );
  const [buttontext, setbuttontext] = useState("Accept");
  const [exitbutton, setexitbutton] = useState("Close");
  const [spinner, setspinner] = useState(true);
  const [finalfile, setfinalfile] = useState("");
  const [isGalleryPopup, setIsGalleryPopup] = useState(false);
  const [saveloader, setsaveloader] = useState(false);
  const [lastdate, setlastdate] = useState("");
  const [androidbuildversion, setandroidbuildversion] = useState(null);
  const [androidsupportedversion, setandroidsupportedversion] = useState(null);
  const [iosbuildversion, setiosbuildversion] = useState(null);
  const [iossupportedversion, setiossupportedversion] = useState(null);
  const [rebuildpopvalue, setrebuildpopvalue] = useState(false);
  // const [openVistaPopUp, setOpenVistaPopUp] = useState(false);
  // const [openSmallScreenPopUp, setOpenSmallScreenPopUp] = useState(false);
  const [designid, setdesignid] = useState("");
  const [appUserRoleId, setAppUserRoleId] = useState(4);
  const [upgradeconset, setupgradeconset] = useState("0");
  const [appRightPanelExpand, setAppRightPanelExpand] = useState(
    localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
      ? true
      : false
  );
  const [skipActive, setSkipActive] = useState(false);
  const appPlan= localStorage.getItem("app_plan")
  const {toggleActivateModal} = useContext(previewContext)

  const handleRowOpen = (e, from) => {
    if (from === rowOpen) {
      setRowOpen("");
    } else {
      setRowOpen(from);
    }
  };

  const settingYourAppLogodata = (imgName, from) => {
    setdesignid("");
    if (from === "apiImg") {
      setfinalfile(imgName);
    } else {
      setfinalfile(imgName);
    }
  };

  const closeRequireBuildPopup = (e) => {
    setrebuildpopvalue(!rebuildpopvalue);
  };

  const uploadOrgImgFrom3rdParty = (orgImgName) => {
    setfinalfile(orgImgName);
  };

  const handleAppUploadimgPopUp = () => {
    setIsGalleryPopup(true);
  };

  const handleToggle = () => {
    if (toggle) {
      setRowOpen("");
    }
    setToggle(!toggle);
  };

  const yourErrFun = () => {};

  const handleLayout = (value) => {
    setLayout(value);
  };

  const hangleHeading = (e) => {
    // if (e.target.value.length > 150)
    // {
    //   // setHeadingError("150 Characters");
    // }
    // else
    // {
    setHeadingError("");
    setHeading(e.target.value);
    // }
  };

  const handleParagraph = (e) => {
    // if (e.target.value.length > 250) {
    //   // setParagraphError("250 Characters");
    // }
    // else {
    setParagraphError("");
    setParagraph(e.target.value);
    // }
  };

  const handleprimaryColor = (e) => {
    setprimaryColor(e);
  };

  const handlebackgroundColor = (e) => {
    const oldCss = e;
    const newOpacity = "0.1";
    const newCss = oldCss.replace(/[^,]+(?=\))/, newOpacity);
    setclosebackgroundColor(newCss);
    setbackgroundColor(e);
  };

  const handlesecondaryColor = (e) => {
    setsecondaryColor(e);
  };

  const handlebuttontextColor = (e) => {
    setbuttontextColor(e);
  };

  const handleButtonText = (e) => {
    // if (e.target.value.length > 10)
    // {
    //   // setbuttontextError("10 Characters");
    // }
    // else
    // {
    setbuttontextError("");
    setbuttontext(e.target.value);
    // }
  };

  const handleremoveimage = (e) => {
    setfinalfile("");
  };

  const checkVersion = (a, b) => {
    const x = a.split(".").map((e) => parseInt(e, 10));
    const y = b.split(".").map((e) => parseInt(e, 10));

    var a1 = "";
    var b1 = "";
    for (const i in x) {
      if (i == 0) {
        a1 = x[i];
        b1 = y[i];
      } else {
        a1 = a1 + "." + x[i];
        b1 = b1 + "." + y[i];
      }
    }

    return a1 < b1 ? 1 : 0;
  };

  const postConsentData = (e) => {
    if(upgradeconset === 0){
      toggleActivateModal(e)
       return;
      }

    if (!heading) {
      setHeadingError("Required");
      return false;
    }

    if (!paragraph) {
      setParagraphError("Required");
      return false;
    }

    if (!buttontext) {
      setbuttontextError("Required");
      return false;
    }

    if (!exitbutton) {
      setexitbuttonError("Required");
      return false;
    }

    if (androidbuildversion != null || iosbuildversion != null) {
      if (androidbuildversion != null && androidsupportedversion != null) {
        if (checkVersion(androidbuildversion, androidsupportedversion)) {
          setrebuildpopvalue(!rebuildpopvalue);
          return;
        }
      } else if (iosbuildversion != null && iossupportedversion != null) {
        if (checkVersion(iosbuildversion, iossupportedversion)) {
          setrebuildpopvalue(!rebuildpopvalue);
          return;
        }
      }
    }

    setsaveloader(true);

    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      form_bool: toggle ? 1 : 0,
      consent_form_layout: layout,
      consent_form_heading_text: heading,
      consent_form_sub_text: paragraph,
      consent_form_accept_button_text: buttontext,
      consent_form_close_button_text: exitbutton,
      consent_form_primary_text_colour: primaryColor,
      consent_form_secondary_text_colour: secondaryColor,
      consent_form_button_colour: backgroundColor,
      consent_form_button_text_colour: buttontextColor,
      consent_form_bg_image: finalfile.includes("consent.jpg") ? "" : finalfile,
      is_default_image: finalfile.includes("consent.jpg") ? 1 : 0,
      design_id: designid ? designid : "",
    };

    PostConsetData(params, setsaveloader, setlastdate);
  };

  const submitfunction = () => {
    setsaveloader(true);

    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      form_bool: toggle ? 1 : 0,
      consent_form_layout: layout,
      consent_form_heading_text: heading,
      consent_form_sub_text: paragraph,
      consent_form_accept_button_text: buttontext,
      consent_form_close_button_text: exitbutton,
      consent_form_primary_text_colour: primaryColor,
      consent_form_secondary_text_colour: secondaryColor,
      consent_form_button_colour: backgroundColor,
      consent_form_button_text_colour: buttontextColor,
      consent_form_bg_image: finalfile.includes("consent.jpg") ? "" : finalfile,
      is_default_image: finalfile.includes("consent.jpg") ? 1 : 0,
      design_id: designid ? designid : "",
    };

    PostConsetData(params, setsaveloader, setlastdate);
  };

  const handleExitButton = (e) => {
    // if (e.target.value.length > 10)
    // {
    //   // setexitbuttonError("10 Characters");
    // }
    // else
    // {
    setexitbuttonError("");
    setexitbutton(e.target.value);
    // }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      localStorage.getItem("agencyid") &&
      localStorage.getItem("agencyid") == 1
    ) {
      document.title = "Consent | AppMySite";
    } else {
      document.title = "Consent |" + " " + localStorage.getItem("agencyname");
    }

    GetconsentData(
      setspinner,
      setHeading,
      setParagraph,
      setbuttontext,
      setexitbutton,
      setToggle,
      setprimaryColor,
      setsecondaryColor,
      setbackgroundColor,
      setbuttontextColor,
      setfinalfile,
      setLayout,
      setlastdate,
      setdesignid,
      setupgradeconset,
      setandroidbuildversion,
      setandroidsupportedversion,
      setiosbuildversion,
      setiossupportedversion,
      setAppUserRoleId
    );
  }, []);

  const loadpicVistaCreatePopUp = (data) => {
    // setOpenVistaPopUp(false);
    setfinalfile(data.url);
    setdesignid(data.id);
    setIsGalleryPopup(false);
  };

  const closeVistaCreatePopUp = () => {};

  const handleRightPanelExpand = (value) => {
    setAppRightPanelExpand(value);
  };

  return (
    <>
      <div>
        <Header customclass={"header-responsive-new"} />
      </div>

      <section className="new-consent-login">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
        <div
          className={`right-panel-gap ${
            localStorage.getItem("appSideBarCollapse") !== undefined &&
            localStorage.getItem("appSideBarCollapse") !== null &&
            localStorage.getItem("appSideBarCollapse") === "true"
              ? " right-panel-gap-expand"
              : ""
          }`}
        >
          <div className="new-consent-head">
            <div className="web-access-head">
              <h1>
                Access
                <p className=" consent-tooltip">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <g
                      id="Group_35001"
                      data-name="Group 35001"
                      transform="translate(-1727 -19)"
                    >
                      <g
                        id="Ellipse_7706"
                        data-name="Ellipse 7706"
                        transform="translate(1727 19)"
                        fill="none"
                        stroke="#bcc1ce"
                        stroke-width="1.5"
                      >
                        <circle cx="9" cy="9" r="9" stroke="none" />
                        <circle cx="9" cy="9" r="8.25" fill="none" />
                      </g>
                      <line
                        id="Line_343"
                        data-name="Line 343"
                        y1="2"
                        transform="translate(1736 23)"
                        fill="none"
                        stroke="#bcc1ce"
                        stroke-miterlimit="10"
                        stroke-width="1.5"
                      />
                      <path
                        id="Path_98224"
                        data-name="Path 98224"
                        d="M0,2V-4"
                        transform="translate(1736 31)"
                        fill="none"
                        stroke="#bcc1ce"
                        stroke-width="1.5"
                      />
                    </g>
                  </svg>
                  <p className="consent-tooltipsubheader">
                    Define how users enter the app.
                  </p>
                </p>
              </h1>
            </div>

            {/* <div className='consent-mobile-head'>
              <h1>Access
                <p className=" consent-tooltip">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                    <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                      <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                        <circle cx="9" cy="9" r="9" stroke="none" />
                        <circle cx="9" cy="9" r="8.25" fill="none" />
                      </g>
                      <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5" />
                      <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                    </g>
                  </svg>
                  <p className="consent-tooltipsubheader">
                    Define how users enter the app.
                  </p>
                </p>
              </h1>
              <div className='consent-pages-open'>

                <div className='mobile-consent-link'>
                  <p>Consent
                    <svg xmlns="http://www.w3.org/2000/svg" width="7.592" height="13.061" viewBox="0 0 7.592 13.061">
                      <path id="Path_102028" data-name="Path 102028" d="M-12935-18908l6-6,6,6" transform="translate(-18907.473 12935.531) rotate(90)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                    </svg>
                  </p>

                </div>
                <div className='mobile-login-link'>
                  <p>Login
                    <svg xmlns="http://www.w3.org/2000/svg" width="7.592" height="13.061" viewBox="0 0 7.592 13.061">
                      <path id="Path_102028" data-name="Path 102028" d="M-12935-18908l6-6,6,6" transform="translate(-18907.473 12935.531) rotate(90)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                    </svg>
                  </p>

                </div>





              </div>

            </div> */}

            <div className="consent-modal-tab">
              <div style={{ height: "40px" }}>
                <Link
                  className="active"
                  to="/app/consent"
                  style={{
                    "border-bottom":
                      localStorage.getItem("agencyid") == 1
                        ? "2px solid #3064f9"
                        : "2px solid " +
                          localStorage.getItem("agency_primary_color"),
                    color:
                      localStorage.getItem("agencyid") == 1
                        ? "#3064f9"
                        : localStorage.getItem("agency_primary_color"),
                    "--custom_color4":
                      localStorage.getItem("agencyid") == 1
                        ? "#3064f9"
                        : localStorage.getItem("agency_primary_color"),
                    "--custom_color5":
                      localStorage.getItem("agencyid") == 1
                        ? "#3064f9"
                        : localStorage.getItem("agency_primary_color"),
                  }}
                >
                  Consent
                </Link>

                {parseInt(localStorage.getItem("website_technology")) !== 2 ? (
                  <Link
                    to="/app/login"
                    style={{
                      "--custom_color4":
                        localStorage.getItem("agencyid") == 1
                          ? "#3064f9"
                          : localStorage.getItem("agency_primary_color"),
                      "--custom_color5":
                        localStorage.getItem("agencyid") == 1
                          ? "#3064f9"
                          : localStorage.getItem("agency_primary_color"),
                    }}
                  >
                    Login
                  </Link>
                ) : null}
              </div>
              <div className="date-and-savediv">
                {lastdate && lastdate !== "" && lastdate !== undefined ? (
                  <p className="consent-save-date">
                    Updated {moment(lastdate).format("D MMMM YYYY, HH:mm")}{" "}
                  </p>
                ) : null}
                {skipActive && <PremiumTag module={"consent"}/> }
                

                {localStorage.getItem("agencyid") &&
                  localStorage.getItem("agencyid") != 1 &&
                  upgradeconset == 0 &&
                  spinner === false && (
                    <div
                      style={{ marginRight: "20px" }}
                      className="upgrade-feature"
                    >
                      <h4 className="upgrade-unavailable">
                        This feature is currently unavailable
                      </h4>
                      <p className="upgrade-support">
                        Please contact Support for assistance.
                      </p>
                    </div>
                  )}

                <button
                  type="submit"
                  style={{
                    backgroundColor: localStorage.getItem(
                      "button_background_color"
                    ),
                    color: localStorage.getItem("button_text_color"),
                    "--custom_color5":
                      localStorage.getItem("agencyid") == 1
                        ? "#134bec"
                        : localStorage.getItem("button_hover_bgcolor"),
                    "--custom_color4":
                      localStorage.getItem("agencyid") == 1
                        ? "white"
                        : localStorage.getItem("button_hover_txtcolor"),
                        display: upgradeconset != 0 || skipActive?"block":"none"
                  }}
                  className={
                   ( upgradeconset != 0 || skipActive)
                      ? "consent-login-save-enable"
                      : "consent-login-save-disable"
                  }
                  onClick={
                    upgradeconset != 0  || skipActive ? (e) => postConsentData(e) : void 0
                  }
                >
                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    {saveloader == true ? (
                      <div className="new-loader"></div>
                    ) : (
                      <div>Save</div>
                    )}
                  </div>
                </button>
              </div>
            </div>

            <div className="mob-page-consenthead">
              <p>Consent</p>
              {skipActive && <PremiumTag module={"consent"}/> }

              {localStorage.getItem("agencyid") &&
                localStorage.getItem("agencyid") != 1 &&
                upgradeconset == 0 &&
                spinner === false && (
                  <div className="upgrade-feature">
                    <h4 className="upgrade-unavailable">
                      This feature is currently unavailable
                    </h4>
                    <p className="upgrade-support">
                      Please contact Support for assistance.
                    </p>
                  </div>
                )}
            </div>

            {spinner ? (
              <div
                className="app-icon-section access-loader"
                style={{ overflow: "hidden" }}
              >
                <div className="newspinner">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    style={{
                      margin: "auto",
                      background: "rgb(241, 242, 243,0)",
                      display: "block",
                      shaperendering: "auto",
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                    width="200px"
                    height="200px"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                  >
                    <g
                      transform="translate(20 50)"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <circle
                        cx="0"
                        cy="0"
                        r="6"
                        fill="#e6261f"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <animateTransform
                          attributeName="transform"
                          type="scale"
                          begin="-0.375s"
                          calcMode="spline"
                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                          values="0;1;0"
                          keyTimes="0;0.5;1"
                          dur="1s"
                          repeatCount="indefinite"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        ></animateTransform>
                      </circle>
                    </g>
                    <g
                      transform="translate(40 50)"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <circle
                        cx="0"
                        cy="0"
                        r="6"
                        fill="#f7d038"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <animateTransform
                          attributeName="transform"
                          type="scale"
                          begin="-0.25s"
                          calcMode="spline"
                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                          values="0;1;0"
                          keyTimes="0;0.5;1"
                          dur="1s"
                          repeatCount="indefinite"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        ></animateTransform>
                      </circle>
                    </g>
                    <g
                      transform="translate(60 50)"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <circle
                        cx="0"
                        cy="0"
                        r="6"
                        fill="#49da9a"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <animateTransform
                          attributeName="transform"
                          type="scale"
                          begin="-0.125s"
                          calcMode="spline"
                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                          values="0;1;0"
                          keyTimes="0;0.5;1"
                          dur="1s"
                          repeatCount="indefinite"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        ></animateTransform>
                      </circle>
                    </g>
                    <g
                      transform="translate(80 50)"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <circle
                        cx="0"
                        cy="0"
                        r="6"
                        fill="#4355db"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <animateTransform
                          attributeName="transform"
                          type="scale"
                          begin="0s"
                          calcMode="spline"
                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                          values="0;1;0"
                          keyTimes="0;0.5;1"
                          dur="1s"
                          repeatCount="indefinite"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        ></animateTransform>
                      </circle>
                    </g>
                  </svg>
                </div>
              </div>
            ) : (
              <>
                <div className="new-consentpage">
                  {upgradeconset != 0 || skipActive ? (
                    <div className="new-concentpage-row">
                      <div className="new-concentpage-columnone">
                        <div className="column-left-head">
                          <h1 className="column-head-topdiv">
                            Ask for user consent
                            <p className="consent-tooltip">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <g
                                  id="Group_37563"
                                  data-name="Group 37563"
                                  transform="translate(-1727 -19.132)"
                                >
                                  <g
                                    id="Ellipse_7706"
                                    data-name="Ellipse 7706"
                                    transform="translate(1727 19.132)"
                                    fill="#fff"
                                    stroke="#bcc1ce"
                                    stroke-width="1.2"
                                  >
                                    <circle cx="7" cy="7" r="7" stroke="none" />
                                    <circle cx="7" cy="7" r="6.4" fill="none" />
                                  </g>
                                  <path
                                    id="Path_98061"
                                    data-name="Path 98061"
                                    d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                    transform="translate(1725.126 17.539)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-miterlimit="10"
                                    stroke-width="1.2"
                                  />
                                  <line
                                    id="Line_343"
                                    data-name="Line 343"
                                    y2="1.2"
                                    transform="translate(1734 29.132)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-miterlimit="10"
                                    stroke-width="1.2"
                                  />
                                </g>
                              </svg>
                              <p className="consent-tooltipsubheader-notify">
                                Notify and warn users if the app contains mature
                                themes, products, or media, that may be
                                inappropriate for certain audiences.
                              </p>
                            </p>
                          </h1>

                          <label className=" access-switch access-monitor-slider-padding">
                            <input
                              type="checkbox"
                              className="toplogo"
                              checked={toggle}
                              onChange={(e) => handleToggle(e)}
                              id="monitor"
                            />
                            <div className="access-slider round"></div>
                          </label>
                        </div>

                        <div
                          className={
                            toggle
                              ? "accessaccordionhead"
                              : "accessaccordionhead disabled-access"
                          }
                        >
                          <div className="accessaccordian d-grid consent-toggle-div">
                            <div
                              className="consent-Layout-head"
                              onClick={
                                toggle ? (e) => handleRowOpen(e, 1) : void 0
                              }
                            >
                              <p className="consent-para">Layout</p>

                              {rowOpen === 1 ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13.061"
                                  height="7.592"
                                  viewBox="0 0 13.061 7.592"
                                >
                                  <path
                                    id="Path_81302"
                                    data-name="Path 81302"
                                    d="M-12935-18908l6-6,6,6"
                                    transform="translate(12935.531 18915.064)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13.061"
                                  height="7.592"
                                  viewBox="0 0 13.061 7.592"
                                >
                                  <path
                                    id="Path_81302"
                                    data-name="Path 81302"
                                    d="M-12935-18914l6,6,6-6"
                                    transform="translate(12935.531 18914.535)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  />
                                </svg>
                              )}
                            </div>

                            {rowOpen === 1 && (
                              <div className="consent-layout-div accordian-content">
                                <FormGroup
                                  tag="fieldset"
                                  className="access-screen-ratio"
                                >
                                  <FormGroup>
                                    <Label
                                      className="accessimg-ratio"
                                      value="LLLL"
                                    >
                                      <Input
                                        type="radio"
                                        name="radio3"
                                        checked={layout === "up" ? true : false}
                                        onClick={(e) => handleLayout("up")}
                                      />
                                      <div className="image">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="14"
                                          viewBox="0 0 16 14"
                                        >
                                          <g
                                            id="Group_38163"
                                            data-name="Group 38163"
                                            transform="translate(-0.004 1.227)"
                                          >
                                            <path
                                              id="Path_6061"
                                              data-name="Path 6061"
                                              d="M-14517.724-3751.961h16"
                                              transform="translate(14517.728 3751.735)"
                                              fill="none"
                                              stroke=""
                                              stroke-width="2"
                                            />
                                            <rect
                                              id="Rectangle_29209"
                                              data-name="Rectangle 29209"
                                              width="5"
                                              height="12"
                                              transform="translate(2 0.773)"
                                              fill=""
                                              stroke="none"
                                            />
                                            <rect
                                              id="Rectangle_29210"
                                              data-name="Rectangle 29210"
                                              width="5"
                                              height="6"
                                              transform="translate(9 0.773)"
                                              fill=""
                                              stroke="none"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                    </Label>
                                  </FormGroup>

                                  <FormGroup>
                                    <Label
                                      className="accessimg-ratio"
                                      value="LLLLL"
                                    >
                                      <Input
                                        type="radio"
                                        name="radio3"
                                        checked={
                                          layout === "middle" ||
                                          layout === "center"
                                            ? true
                                            : false
                                        }
                                        onClick={(e) => handleLayout("center")}
                                      />
                                      <div className="image">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="14"
                                          viewBox="0 0 16 14"
                                        >
                                          <g
                                            id="Group_38346"
                                            data-name="Group 38346"
                                            transform="translate(-0.004 1.227)"
                                          >
                                            <path
                                              id="Path_6061"
                                              data-name="Path 6061"
                                              d="M-14517.724-3751.961h16"
                                              transform="translate(14517.728 3757.735)"
                                              fill="none"
                                              stroke=""
                                              stroke-width="2"
                                            />
                                            <rect
                                              id="Rectangle_29209"
                                              data-name="Rectangle 29209"
                                              width="5"
                                              height="14"
                                              transform="translate(2 -1.227)"
                                              fill=""
                                              stroke="none"
                                            />
                                            <rect
                                              id="Rectangle_29210"
                                              data-name="Rectangle 29210"
                                              width="5"
                                              height="8"
                                              transform="translate(9 1.773)"
                                              fill=""
                                              stroke="none"
                                            />
                                          </g>
                                        </svg>
                                      </div>
                                    </Label>
                                  </FormGroup>

                                  <FormGroup>
                                    <Label
                                      className="accessimg-ratio"
                                      value="LLLLL"
                                    >
                                      <Input
                                        type="radio"
                                        name="radio3"
                                        checked={
                                          layout === "down" ? true : false
                                        }
                                        onClick={(e) => handleLayout("down")}
                                      />
                                      <div className="image">
                                        <svg
                                          id="Group_38347"
                                          data-name="Group 38347"
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="14"
                                          viewBox="0 0 16 14"
                                        >
                                          <path
                                            id="Path_6061"
                                            data-name="Path 6061"
                                            d="M-14517.724-3751.961h16"
                                            transform="translate(14517.724 3764.961)"
                                            fill="none"
                                            stroke=""
                                            stroke-width="2"
                                          />
                                          <rect
                                            id="Rectangle_29209"
                                            data-name="Rectangle 29209"
                                            width="5"
                                            height="12"
                                            transform="translate(1.996)"
                                            fill=""
                                            stroke="none"
                                          />
                                          <rect
                                            id="Rectangle_29210"
                                            data-name="Rectangle 29210"
                                            width="5"
                                            height="6"
                                            transform="translate(8.996 6)"
                                            fill=""
                                            stroke="none"
                                          />
                                        </svg>
                                      </div>
                                    </Label>
                                  </FormGroup>
                                </FormGroup>
                              </div>
                            )}
                          </div>

                          <div className="accessaccordian d-grid consent-toggle-div">
                            <div
                              className="consent-Layout-head"
                              onClick={
                                toggle ? (e) => handleRowOpen(e, 2) : void 0
                              }
                            >
                              <p className="consent-para">Text</p>
                              {rowOpen === 2 ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13.061"
                                  height="7.592"
                                  viewBox="0 0 13.061 7.592"
                                >
                                  <path
                                    id="Path_81302"
                                    data-name="Path 81302"
                                    d="M-12935-18908l6-6,6,6"
                                    transform="translate(12935.531 18915.064)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13.061"
                                  height="7.592"
                                  viewBox="0 0 13.061 7.592"
                                >
                                  <path
                                    id="Path_81302"
                                    data-name="Path 81302"
                                    d="M-12935-18914l6,6,6-6"
                                    transform="translate(12935.531 18914.535)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  />
                                </svg>
                              )}
                            </div>
                            {rowOpen === 2 && (
                              <div className="form-group consentpage-inputtdiv accordian-content">
                                <div className="consentpage-div">
                                  <label>Heading</label>
                                  <input
                                    value={heading}
                                    maxLength={"150"}
                                    onChange={(e) => hangleHeading(e)}
                                  />

                                  {heading.length === 0 &&
                                  headingError === "Required" ? (
                                    <span className="consent-headingerror">
                                      {headingError}
                                    </span>
                                  ) : (
                                    <span className="consent-headingerror">
                                      {150 - heading.length} characters
                                    </span>
                                  )}
                                </div>
                                <div className="consentpage-divtwo">
                                  <label>Subtext</label>
                                  <input
                                    value={paragraph}
                                    maxLength={"250"}
                                    onChange={(e) => handleParagraph(e)}
                                  />
                                  {/* <span className="consent-headingerror">{paragraphError}</span> */}
                                  {paragraph.length === 0 &&
                                  paragraphError === "Required" ? (
                                    <span className="consent-headingerror">
                                      {paragraphError}
                                    </span>
                                  ) : (
                                    <span className="consent-headingerror">
                                      {250 - paragraph.length} characters
                                    </span>
                                  )}
                                </div>
                                <div className="consentpage-divtwo">
                                  <label>Accept button text</label>
                                  <input
                                    value={buttontext}
                                    maxLength={"10"}
                                    onChange={(e) => handleButtonText(e)}
                                  />
                                  {/* <span className="consent-headingerror">{buttontextError}</span> */}
                                  {buttontext.length === 0 &&
                                  buttontextError === "Required" ? (
                                    <span className="consent-headingerror">
                                      {buttontextError}
                                    </span>
                                  ) : (
                                    <span className="consent-headingerror">
                                      {10 - buttontext.length} characters
                                    </span>
                                  )}
                                </div>
                                <div className="consentpage-divtwo">
                                  <label>Exit button text</label>
                                  <input
                                    value={exitbutton}
                                    maxLength={"10"}
                                    onChange={(e) => handleExitButton(e)}
                                  />
                                  {/* <span className="consent-headingerror">{exitbuttonError}</span> */}
                                  {exitbutton.length === 0 &&
                                  exitbuttonError === "Required" ? (
                                    <span className="consent-headingerror">
                                      {exitbuttonError}
                                    </span>
                                  ) : (
                                    <span className="consent-headingerror">
                                      {10 - exitbutton.length} characters
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}

                            <div></div>
                          </div>

                          <div className="accessaccordian d-grid consent-toggle-div">
                            <div
                              className="consent-Layout-head"
                              onClick={
                                toggle ? (e) => handleRowOpen(e, 3) : void 0
                              }
                            >
                              <p className="consent-para">Background</p>
                              {rowOpen === 3 ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13.061"
                                  height="7.592"
                                  viewBox="0 0 13.061 7.592"
                                >
                                  <path
                                    id="Path_81302"
                                    data-name="Path 81302"
                                    d="M-12935-18908l6-6,6,6"
                                    transform="translate(12935.531 18915.064)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13.061"
                                  height="7.592"
                                  viewBox="0 0 13.061 7.592"
                                >
                                  <path
                                    id="Path_81302"
                                    data-name="Path 81302"
                                    d="M-12935-18914l6,6,6-6"
                                    transform="translate(12935.531 18914.535)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  />
                                </svg>
                              )}
                            </div>
                            {rowOpen === 3 && (
                              <div className="accordian-content">
                                <div className="image-uploader">
                                  <div
                                    className="d-flex align-items-center"
                                    onClick={(e) => handleAppUploadimgPopUp()}
                                  >
                                    <div
                                      className="uploadpixabayimage"
                                      style={{
                                        backgroundImage:
                                          finalfile &&
                                          finalfile != "consent.jpg" &&
                                          finalfile != ""
                                            ? "url(" +
                                              (finalfile.includes("https")
                                                ? finalfile
                                                : process.env
                                                    .REACT_APP_Image_Path +
                                                  localStorage.getItem(
                                                    "user_id"
                                                  ) +
                                                  "/" +
                                                  localStorage.getItem(
                                                    "Appid"
                                                  ) +
                                                  process.env
                                                    .REACT_APP_Consent_Crop +
                                                  finalfile) +
                                              ")"
                                            : finalfile == "consent.jpg"
                                            ? "url(" + Background + ")"
                                            : "url(" + imagebackground + ")",
                                      }}
                                    ></div>

                                    <p style={{ marginBottom: "0px" }}>
                                      {finalfile
                                        ? finalfile.replace(
                                            "https://create.vista.com/downloads/?filePath=%2Fdownloads%2F",
                                            ""
                                          )
                                        : "Upload"}
                                    </p>
                                  </div>
                                  {finalfile ? (
                                    <svg
                                      onClick={(e) => handleremoveimage()}
                                      width="11.06"
                                      height="11.06"
                                      viewBox="0 0 11.06 11.06"
                                    >
                                      <path
                                        id="Union_1696"
                                        data-name="Union 1696"
                                        d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z"
                                        transform="translate(0.53 0.53)"
                                        fill="none"
                                        stroke="#7782a1"
                                        stroke-width="1.5"
                                      />
                                    </svg>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            )}
                          </div>

                          <div className="accessaccordian d-grid consent-toggle-div">
                            <div
                              className="consent-Layout-head"
                              onClick={
                                toggle ? (e) => handleRowOpen(e, 4) : void 0
                              }
                            >
                              <p className="consent-para">Theme</p>
                              {rowOpen === 4 ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13.061"
                                  height="7.592"
                                  viewBox="0 0 13.061 7.592"
                                >
                                  <path
                                    id="Path_81302"
                                    data-name="Path 81302"
                                    d="M-12935-18908l6-6,6,6"
                                    transform="translate(12935.531 18915.064)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  />
                                </svg>
                              ) : (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13.061"
                                  height="7.592"
                                  viewBox="0 0 13.061 7.592"
                                >
                                  <path
                                    id="Path_81302"
                                    data-name="Path 81302"
                                    d="M-12935-18914l6,6,6-6"
                                    transform="translate(12935.531 18914.535)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  />
                                </svg>
                              )}
                            </div>
                            {rowOpen === 4 && (
                              <div className="consent-theme-div accordian-content">
                                <div className="acces-theme">
                                  <div className="acces-theme-options">
                                    <label>
                                      Primary text color
                                      <p className=" consent-tooltip">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="14"
                                          viewBox="0 0 14 14"
                                        >
                                          <g
                                            id="Group_37563"
                                            data-name="Group 37563"
                                            transform="translate(-1727 -19.132)"
                                          >
                                            <g
                                              id="Ellipse_7706"
                                              data-name="Ellipse 7706"
                                              transform="translate(1727 19.132)"
                                              fill="#fff"
                                              stroke="#bcc1ce"
                                              stroke-width="1.2"
                                            >
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="7"
                                                stroke="none"
                                              />
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="6.4"
                                                fill="none"
                                              />
                                            </g>
                                            <path
                                              id="Path_98061"
                                              data-name="Path 98061"
                                              d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                              transform="translate(1725.126 17.539)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            />
                                            <line
                                              id="Line_343"
                                              data-name="Line 343"
                                              y2="1.2"
                                              transform="translate(1734 29.132)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            />
                                          </g>
                                        </svg>
                                        <p className="consent-tooltipsubheader setting-managed">
                                          This sets the color of the primary
                                          text.
                                        </p>
                                      </p>
                                    </label>
                                    <Reactcolorpicker
                                      colorname={primaryColor}
                                      presetColors={presetColors}
                                      onChange={(e) => handleprimaryColor(e)}
                                      valuecontrol={true}
                                      classselection={"accessfixback"}
                                    />
                                  </div>

                                  <div className="acces-theme-options secondary-text-mob">
                                    <label>
                                      Secondary text color
                                      <p className=" consent-tooltip">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="14"
                                          viewBox="0 0 14 14"
                                        >
                                          <g
                                            id="Group_37563"
                                            data-name="Group 37563"
                                            transform="translate(-1727 -19.132)"
                                          >
                                            <g
                                              id="Ellipse_7706"
                                              data-name="Ellipse 7706"
                                              transform="translate(1727 19.132)"
                                              fill="#fff"
                                              stroke="#bcc1ce"
                                              stroke-width="1.2"
                                            >
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="7"
                                                stroke="none"
                                              />
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="6.4"
                                                fill="none"
                                              />
                                            </g>
                                            <path
                                              id="Path_98061"
                                              data-name="Path 98061"
                                              d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                              transform="translate(1725.126 17.539)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            />
                                            <line
                                              id="Line_343"
                                              data-name="Line 343"
                                              y2="1.2"
                                              transform="translate(1734 29.132)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            />
                                          </g>
                                        </svg>
                                        <p className="consent-tooltipsubheader setting-managed">
                                          This sets the color of the secondary
                                          text.
                                        </p>
                                      </p>
                                    </label>
                                    <Reactcolorpicker
                                      colorname={secondaryColor}
                                      presetColors={presetColors}
                                      onChange={(e) => handlesecondaryColor(e)}
                                      valuecontrol={true}
                                      classselection={"accessfixback"}
                                    />
                                  </div>

                                  <div className="acces-theme-options access-mt">
                                    <label>
                                      Button background color
                                      <p className=" consent-tooltip">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="14"
                                          viewBox="0 0 14 14"
                                        >
                                          <g
                                            id="Group_37563"
                                            data-name="Group 37563"
                                            transform="translate(-1727 -19.132)"
                                          >
                                            <g
                                              id="Ellipse_7706"
                                              data-name="Ellipse 7706"
                                              transform="translate(1727 19.132)"
                                              fill="#fff"
                                              stroke="#bcc1ce"
                                              stroke-width="1.2"
                                            >
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="7"
                                                stroke="none"
                                              />
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="6.4"
                                                fill="none"
                                              />
                                            </g>
                                            <path
                                              id="Path_98061"
                                              data-name="Path 98061"
                                              d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                              transform="translate(1725.126 17.539)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            />
                                            <line
                                              id="Line_343"
                                              data-name="Line 343"
                                              y2="1.2"
                                              transform="translate(1734 29.132)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            />
                                          </g>
                                        </svg>
                                        <p className="consent-tooltipsubheader setting-managed">
                                          This sets the background color of the
                                          action buttons.
                                        </p>
                                      </p>
                                    </label>
                                    <Reactcolorpicker
                                      colorname={backgroundColor}
                                      presetColors={presetColors}
                                      onChange={(e) => handlebackgroundColor(e)}
                                      valuecontrol={true}
                                      classselection={"accessfixback"}
                                    />
                                  </div>

                                  <div className="acces-theme-options access-mt">
                                    <label>
                                      Button text color
                                      <p className=" consent-tooltip">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="14"
                                          height="14"
                                          viewBox="0 0 14 14"
                                        >
                                          <g
                                            id="Group_37563"
                                            data-name="Group 37563"
                                            transform="translate(-1727 -19.132)"
                                          >
                                            <g
                                              id="Ellipse_7706"
                                              data-name="Ellipse 7706"
                                              transform="translate(1727 19.132)"
                                              fill="#fff"
                                              stroke="#bcc1ce"
                                              stroke-width="1.2"
                                            >
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="7"
                                                stroke="none"
                                              />
                                              <circle
                                                cx="7"
                                                cy="7"
                                                r="6.4"
                                                fill="none"
                                              />
                                            </g>
                                            <path
                                              id="Path_98061"
                                              data-name="Path 98061"
                                              d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                              transform="translate(1725.126 17.539)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            />
                                            <line
                                              id="Line_343"
                                              data-name="Line 343"
                                              y2="1.2"
                                              transform="translate(1734 29.132)"
                                              fill="none"
                                              stroke="#bcc1ce"
                                              stroke-miterlimit="10"
                                              stroke-width="1.2"
                                            />
                                          </g>
                                        </svg>
                                        <p className="consent-tooltipsubheader setting-managed">
                                          This sets the text color of the action
                                          buttons.
                                        </p>
                                      </p>
                                    </label>
                                    <Reactcolorpicker
                                      colorname={buttontextColor}
                                      presetColors={presetColors}
                                      onChange={(e) => handlebuttontextColor(e)}
                                      valuecontrol={true}
                                      classselection={"accessfixback"}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="new-concentpage-columntwo">
                        <div
                          className={
                            toggle
                              ? "columntwo-mobile-screen columntwo-mobile-screencenter"
                              : "columntwo-mobile-screen columntwo-mobile-screencenter"
                          }
                          style={{
                            backgroundImage:
                              finalfile != "consent.jpg"
                                ? "url(" +
                                  (finalfile && finalfile.includes("https")
                                    ? finalfile
                                    : process.env.REACT_APP_Image_Path +
                                      localStorage.getItem("user_id") +
                                      "/" +
                                      localStorage.getItem("Appid") +
                                      process.env.REACT_APP_Consent_Crop +
                                      finalfile) +
                                  ")"
                                : finalfile == "consent.jpg"
                                ? "url(" + Background + ")"
                                : "",
                          }}
                        >
                          <div
                            className={
                              (layout == "up"
                                ? "top"
                                : layout == "down"
                                ? "down"
                                : "") + " concent-text-div"
                            }
                          >
                            <p
                              className={
                                toggle
                                  ? "concent-headtext"
                                  : "concent-headtext loginopacity"
                              }
                              style={{ color: primaryColor }}
                            >
                              {heading}
                            </p>
                            <p
                              className={
                                toggle
                                  ? "concent-subheadtext"
                                  : "concent-subheadtext loginopacity"
                              }
                              style={{ color: secondaryColor }}
                            >
                              {paragraph}
                            </p>
                            <button
                              className={
                                toggle
                                  ? "concent-acceptbutton"
                                  : "concent-acceptbutton loginopacity"
                              }
                              style={{
                                backgroundColor: backgroundColor,
                                color: buttontextColor,
                              }}
                            >
                              {buttontext}
                            </button>
                            <div className="concent-closediv">
                              <div
                                className={
                                  toggle
                                    ? "concent-closebutton"
                                    : "concent-closebutton loginopacity"
                                }
                                style={{
                                  color: secondaryColor,
                                  backgroundColor: closebackgroundColor,
                                }}
                              >
                                <p style={{ color: secondaryColor }}>
                                  {exitbutton}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="new-consentpage-upgrade">
                      <div className="new-concentpage-columnupgrade">
                        <div className="new-concentupgradetext">
                          <h3 className="">User consent</h3>
                          <p>
                            {" "}
                            Get user consent to control access to content.{" "}
                          </p>
                          <ul>
                            <li>
                              Personalize the consent screen with a unique
                              message.{" "}
                            </li>
                            <li>
                              {/* Separately dispatch notifications to iOS &amp; Android app users.{" "} */}
                              Easily customize the design with point-and-click
                              features.
                            </li>
                            <li>
                              Ensure users open your app only after they
                              consent.{" "}
                            </li>
                            <li>
                              Meet any necessary compliance requirements.{" "}
                            </li>
                          </ul>

                          <div className="upgrade-skip-consent">
                          {localStorage.getItem("agencyid") &&
                          localStorage.getItem("agencyid") != 1
                            ? null
                            : (appUserRoleId == 1 || appUserRoleId == 2) && (
                                <a
                                  href={
                                    localStorage.getItem("workspaceId") !==
                                      "" &&
                                    localStorage.getItem("workspaceId") !==
                                      undefined &&
                                    localStorage.getItem("workspaceId") !==
                                      "undefined" &&
                                    localStorage.getItem("workspaceId") !== null
                                      ? `/workspace/pricing/${btoa(
                                          localStorage.getItem("workspaceId")
                                        )}`
                                      : parseInt(
                                          localStorage.getItem(
                                            "website_technology"
                                          )
                                        ) === 4
                                      ? "/app/wordpress-pricing/" +
                                        btoa(localStorage.getItem("Appid"))
                                      : parseInt(
                                          localStorage.getItem(
                                            "website_technology"
                                          )
                                        ) === 5
                                      ? "/app/woocommerce-pricing/" +
                                        btoa(localStorage.getItem("Appid"))
                                      : parseInt(
                                          localStorage.getItem(
                                            "website_technology"
                                          )
                                        ) === 3
                                      ? "/app/custom-app-pricing/" +
                                        btoa(localStorage.getItem("Appid"))
                                      : parseInt(
                                          localStorage.getItem(
                                            "website_technology"
                                          )
                                        ) === 2
                                      ? "/app/web-to-app-pricing/" +
                                        btoa(localStorage.getItem("Appid"))
                                      : "/app/pricing"
                                  }
                                >
                                  Upgrade
                                </a>
                              )}
                              <div className="skip-btn-head" onClick={() => setSkipActive(true)}>
                              <SkipBtn/>
                              
                              </div>
                            </div>
                            
                        </div>
                      </div>

                      <div className="new-concentpage-colutwomnupgrade">
                        <div className="  ">
                          <img
                            src={
                              require("../../assets/images/dashboard/consentupgrade.png")
                                .default
                            }
                            alt="addmobpre"
                            className="img-fluid web_consentupgrade"
                          />
                          <img
                            src={
                              require("../../assets/images/dashboard/mob_consentupgrade.png")
                                .default
                            }
                            alt="addmobpre"
                            className="img-fluid mob_consentupgrade"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {(upgradeconset != 0 || skipActive) ? (
                  <>
                    <button
                      type="submit"
                      className={
                        upgradeconset != 0  || skipActive
                          ? "mobile-save-button"
                          : "mobile-save-button-disable"
                      }
                      style={{
                        backgroundColor: localStorage.getItem(
                          "button_background_color"
                        ),
                        color: localStorage.getItem("button_text_color"),
                        "--custom_color5":
                          localStorage.getItem("agencyid") == 1
                            ? "#134bec"
                            : localStorage.getItem("button_hover_bgcolor"),
                        "--custom_color4":
                          localStorage.getItem("agencyid") == 1
                            ? "white"
                            : localStorage.getItem("button_hover_txtcolor"),
                      }}
                      onClick={
                        upgradeconset != 0  || skipActive ? (e) => postConsentData(e) : void 0
                      }
                    >
                      <div className="d-flex align-items-center justify-content-center h-100 w-100">
                        {saveloader == true ? (
                          <div className="new-loader"></div>
                        ) : (
                          <div>Save</div>
                        )}
                      </div>
                    </button>
                    {lastdate && lastdate !== "" && lastdate !== undefined ? (
                      <p className="mob-consent-save-date">
                        Updated {moment(lastdate).format("D MMMM YYYY, HH:mm")}{" "}
                      </p>
                    ) : null}
                  </>
                ) : null}
              </>
            )}
          </div>
        </div>
      </section>

      <RebuildRequired
        rebuildpop={rebuildpopvalue}
        btnRebuildCancel={closeRequireBuildPopup}
        submitform={submitfunction}
      />

      <NewImageGalleryPopup
        isGalleryPopup={isGalleryPopup}
        setIsGalleryPopup={setIsGalleryPopup}
        uploadPath={
          localStorage.getItem("user_id") +
          "/" +
          localStorage.getItem("Appid") +
          process.env.REACT_APP_Consent_Crop
        }
        settingYourdata={settingYourAppLogodata}
        yourErrFun={yourErrFun}
        settingOrgImgFrom3rdParty={false}
        hideImageGallery={false}
        widthForRestriction={1242}
        heightForRestriction={2208}
        aspectRatio={"1242:2208"}
        isNotCustomUploadOrgImg={true}
        designid={designid}
        loadpicVistaCreatePopUp={loadpicVistaCreatePopUp}
        closeVistaCreatePopUp={closeVistaCreatePopUp}
      />
    </>
  );
};

export default Consent;
