import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { errortoast } from "../../../Dashboard/Toaster";
import crossIc from "../../../assets/images/signup/cross.png";
import {
  useDeleteNotificationMutation,
  useLazyGetAllPlainNotificationsQuery,
} from "../../../Redux/slices/Notification/basicNotificationSlice";

const DeleteNotifModal = ({
  notificationData,
  setNotificationData,
  buildFilters,
}) => {
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [deleteNotification] = useDeleteNotificationMutation();
  const [getNotifDetails] = useLazyGetAllPlainNotificationsQuery();

  const appId = localStorage.getItem("Appid");

  const handleDeleteNotification = () => {
    setDeleteLoader(true);
    setNotificationData({ spinner: true });

    deleteNotification({
      app_id: parseInt(localStorage.getItem("Appid")),
      notify_id: notificationData.toDeleteNotifId,
    })
      .unwrap()
      .then(() => {
        getNotifDetails({
          appId: btoa(appId),
          page: 1,
          limit: 20,
          finalFilterVal: buildFilters(),
        })
          .unwrap()
          .then((res) => {
            setNotificationData({
              spinner: false,
              multisiteData: res?.multisite,
              isEnableScreen: res?.is_enable_screen,
              androidNotifyId: res?.android_notification_project_id,
              iosNotifyId: res?.ios_notification_project_id,
              hitNextPagination:
                res.data?.length > 0 &&
                res.data?.length === notificationData.limit
                  ? true
                  : false,
              bottomSpinner: false,
              displayTable: res.data || [],
            });
          })
          .catch(() => {})
          .finally(() => {
            setNotificationData({
              toDeleteNotifId: "",
              spinner: false,
              isDeleteNotifModalOpen: false,
            });
            setDeleteLoader(false);
          });
      })
      .catch(() => {
        errortoast("Something went wrong. Try again after sometime.");
      })
      .finally(() => {
        setNotificationData({
          toDeleteNotifId: "",
        });
      });
  };

  return (
    <Modal
      isOpen={notificationData.isDeleteNotifModalOpen}
      centered
      toggle={() => setNotificationData({ isDeleteNotifModalOpen: false })}
      className="deleteNotification-modal"
      dataclass="newnotification"
    >
      <ModalHeader
        toggle={() => setNotificationData({ isDeleteNotifModalOpen: false })}
        className="secondnotificationmodal"
      >
        <div className="d-md-flex new-pus_notification_head align-items-center justify-content-between ">
          <div
            className="newcrossmodaltwo"
            onClick={() =>
              setNotificationData({ isDeleteNotifModalOpen: false })
            }
          >
            <img src={crossIc} alt="cross" />
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="secondnewpush-notification-body">
        <div>
          <div className="d-flex justify-content-center pushnotification-delete">
            <div className="secondmodal-texts">
              <h2>Delete this notification?</h2>

              <p className="pushnotification-longtext">
                Are you sure you want to proceed? Deleting this notification is
                permanent and cannot be undone. This action will remove your
                notification and all associated data.
              </p>
            </div>
          </div>
          <div className="d-flex justify-content-center pushnotification-finalselection">
            <div
              onClick={() =>
                setNotificationData({ isDeleteNotifModalOpen: false })
              }
            >
              <p>Cancel</p>
            </div>
            <div onClick={() => handleDeleteNotification()}>
              <div className="d-flex align-items-center justify-content-center h-100 w-100 abc123">
                <p>
                  {deleteLoader ? (
                    <div>
                      <div className="new-red-loader"></div>
                    </div>
                  ) : (
                    <div>Yes</div>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteNotifModal;
