import React from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { ImagebusketApi } from "../Api/Dashboard/DashboardApi";
export default class Imagecrop extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buttontext: "CROP",
      image: props.imagename,
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: props.cropwidth / props.cropheight,
      croppedImage: null,
      croppedAreaPixels: null,
      modal: props.modal,
    };
  }

  showCroppedImage = async () => {
    this.setState({
      buttontext: (
        <img
          src={require("../assets/images/signup/Loader_GIF.gif").default}
          alt="loading.."
          style={{ marginTop: "-5px" }}
        />
      ),
    });
    try {
      const croppedImage = await getCroppedImg(
        this.state.image,
        this.state.croppedAreaPixels
      );
      const params = {
        data: croppedImage,
        path:
          localStorage.getItem("user_id") +
          "/" +
          localStorage.getItem("Appid") +
          this.props.imagepath,
        original: this.state.image,
        original_path:
          localStorage.getItem("user_id") +
          "/" +
          localStorage.getItem("Appid") +
          this.props.originalpath,
      };

      ImagebusketApi(params, this);
    } catch (e) {
      console.error(e);
    }
  };
  onCropChange = (crop) => {
    this.setState({ crop });
  };
  componentWillUnmount() {
    clearInterval(this.countdown);
  }
  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels: croppedAreaPixels,
      croppedArea: croppedArea,
    });
  };
  photoggle = () => {
    this.setState(
      {
        modal: false,
      },
      () => {
        this.props.whenTogglemodal();
      }
    );
    let checkclass = document
      .querySelector("body")
      .classList.contains("modal-open");
    if (checkclass === true) {
      document.querySelector("body").classList.remove("modal-open");
      document.querySelector("body").removeAttribute("style");
    }
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };
  render() {
    return (
      <>
        <Modal
          isOpen={this.state.modal}
          centered
          className={this.props.className}
          style={{ maxWidth: "40%" }}
        >
          <ModalHeader
            toggle={this.photoggle}
            className="modal-xxl-content croppopup"
          >
            <p>Crop Image</p>
            <div className="crossmodal mt-2" onClick={this.photoggle}>
              <svg width="14.779" height="14.778" viewBox="0 0 14.779 14.778">
                <g
                  id="Group_2926"
                  data-name="Group 2926"
                  transform="translate(0.75 0.75)"
                >
                  <path
                    id="Union_18"
                    data-name="Union 18"
                    d="M6.64,6.638,0,13.277,6.64,6.638,0,0,6.64,6.638,13.279,0,6.64,6.638l6.64,6.639Z"
                    fill="#fff"
                    stroke="#7782a1"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  ></path>
                </g>
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="p-0 cropbody">
            <div
              style={{
                position: "relative",
                width: "97%",
                height: "95%",
                margin: "auto",
                top: "10px",
              }}
              className="crop-save"
            >
              <Cropper
                image={this.state.image}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={this.state.aspect}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
                //  restrictPosition={false}
                minZoom={1}
                maxZoom={1}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="d-flex justify-content-end w-100">
              {/* <div toggle={this.photoggle} className="Cancel">
				Cancel
			</div> */}
              <div
                onClick={this.showCroppedImage}
                className="next_btn text-center butt"
                style={{ width: "150px" }}
              >
                {this.state.buttontext}
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}
