import React from 'react'

const UploadIcon = () => {
  return (
          <svg xmlns="http://www.w3.org/2000/svg" width="33.694" height="24.749" viewBox="0 0 33.694 24.749">
            <g id="_172620_upload_icon" data-name="172620_upload_icon" transform="translate(0 -4.546)">
              <g id="Group_38467" data-name="Group 38467" transform="translate(1 5.546)">
                <path id="Path_102634" data-name="Path 102634" d="M27.477,24.994a5.217,5.217,0,1,0,0-10.435l-.038,0a8.015,8.015,0,0,0-15.473-3.772,3.967,3.967,0,0,0-6.775,2.8c0,.056.007.11.009.166A5.722,5.722,0,0,0,6.725,24.994" transform="translate(-1 -5.546)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" stroke-width="2" />
                <path id="Path_102635" data-name="Path 102635" d="M20,28.419,25.419,23l5.419,5.419" transform="translate(-9.572 -13.456)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
                <line id="Line_394" data-name="Line 394" y1="12.986" transform="translate(15.847 9.763)" fill="none" stroke="#fff" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" />
              </g>
            </g>
          </svg>
  )
}

export default UploadIcon