import React from "react";
import { useSelector } from "react-redux";

const OverviewCard = ({ appData }) => {

  const {appName} = useSelector((state) => state?.userWebsiteData)
  return (
    <div className="dashboard-app-info-one">
      <div className="dashboard-appicon">
        <img
          src={
            process.env.REACT_APP_Image_Path +
            appData?.app_user_id +
            "/" +
            localStorage.getItem("Appid") +
            process.env.REACT_APP_AppIcon_Display +
            appData?.app_icon
          }
          onError={({ currentTarget }) => {
            currentTarget.src =
              require("../../assets/images/dashboard/app-dashboard.png").default;
          }}
          className="img-fluid"
          alt="appicon"
        />
      </div>

      <div>
        <h1 className="appNametype">{appName||appData?.app_name}</h1>
        {appData.app_domain && (
          <a className="appLink" href={appData.app_domain} target="_blank">
            {appData.app_domain}{" "}
            <img
              src={require("../../assets/images/dashboard/Goto.png").default}
              className="img-fluid"
              alt="appicon"
            />
          </a>
        )}
        <p className="appId">ID: {localStorage.getItem("Appid")}</p>
      </div>
    </div>
  );
};

export default OverviewCard;
