export const USERS_LIST = [
    {
      title: "Admin",
      content:
        "Full app access. Can manage user (add/delete) and assign any role.  Cannot delete the app or move it to another Workspace.",
      value: "2",
    },
    {
      title: "Manager",
      content:
        "Full app access except billing and purchasing. Can manage user (add/delete) with Manager and User access. Cannot delete the app or move it to another Workspace.",
      value: "3",
    },
    {
      title: "User",
      content:
        "Full app access except billing and purchasing. Cannot view or manage user. Cannot delete the app or move it to another Workspace.",
      value: "4",
    },
    // {
    //   title: "Viewer",
    //   content:
    //     "Allows view-only access to the app. Does not permit app deletion or movement to another workspace. Also, does not permit user management or access to Billing and purchasing.",
    //   value: "5",
    // },
  ];