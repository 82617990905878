import moment from "moment";
import React, { useEffect, useReducer, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import PremiumModal from "../../Components/commonComponents/Modal";
import ModuleTitleTooltip from "../../Components/commonIcons/ModuleTitleTooltip";
import PremiumTag from "../../Components/commonIcons/PremiumTag";
import Tooltip from "../../Components/commonIcons/Tooltip";
import Header from "../../Dashboard/Header";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
import {
  useActivateAgencyDomainDataMutation,
  useGetAgencyDomainDataQuery,
  useSaveAgencyDomainDataMutation
} from "../../services/Agency/Domain/agencyDomainApiSlice";
import copyIcon from "../assets/images/copyIcon.svg";
import redTriangleerror from "../assets/images/redTriangleerror.svg";
import tickMark from "../assets/images/tickMark.svg";
import tooltipsvgicon from "../assets/images/tooltipsvgicon.svg";
import "./../assets/css/common.css";
import LeftPanel from "./../LeftPanel";
import NewSpinner from "./../NewSpinner";
import erroricon from "../assets/images/error-icon.svg";

const UNVERIFIEDANDINACTIVE = 0;
const VALIDATINGANDINACTIVE = 1;
const VERIFIEDANDINACTIVE = 2;
const VERIFIEDANDACTIVATING = 3;
const VERIFIEDANDACTIVE = 4;

const CreateDomain = () => {
  const [userdelete, setUserDelete] = useState(false);
  const [mdluserdelete, setMdlUserDelete] = useState(false);
  const [rightpanelexpand, setRightPanelExpand] = useState(true);
  const [openPremiumModal, setOpenPremiumModal] = useState(false);
  const [domainError, setDomainError] = useState(false);
  const [domainValidationError, setDomainValidationError] = useState(false);
  const { agencyID } = useParams("agencyID");
  const history = useHistory();
  const [pollingIntervalDuration, setPollingIntervalDuration] = useState(0);

  const {
    data: domainData,
    isLoading: agencyDomainDataLoading,
    isError: agencyDomainDataError,
    refetch: refetchDomainDetails
  } = useGetAgencyDomainDataQuery(
    { agencyID },
    {
      pollingInterval: pollingIntervalDuration
    }
  );
  const [saveDomainDetail, { isLoading: saveDomainLoader }] =
    useSaveAgencyDomainDataMutation();
  const [
    activateDomain,
    { data: activateDomainData, isLoading: activateDomainLoader }
  ] = useActivateAgencyDomainDataMutation();
  const [agencyDomainState, setAgencyDomainState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      domain: "",
      subdomain: "",
      cname_record: "",
      cname_destination: "",
      cname_name: "",
      cname_value: "",
      status: "",
      last_updated_at: "",
      has_paid_agency_subscription: false
    }
  );
  const togglePremiumActivateModal = (e) => {
    e?.preventDefault();
    setOpenPremiumModal((prevState) => !prevState);
  };

  useEffect(() => {
    if (agencyID && domainData?.data) {
      setAgencyDomainState(domainData.data);
    }
    document.title = "Domain | AppMySite";
  }, [domainData?.data, domainData?.data?.status]);

  useEffect(() => {
    if (
      domainData?.data?.status === VALIDATINGANDINACTIVE ||
      domainData?.data?.status === VERIFIEDANDACTIVATING
    ) {
      setPollingIntervalDuration(5 * 60 * 1000);
    } else {
      setPollingIntervalDuration(0);
    }
  }, [domainData?.data?.status, agencyDomainState.status, activateDomainData]);

  const deleteAccount = (e) => {
    e.preventDefault();
    setUserDelete(!userdelete);
    setMdlUserDelete(!mdluserdelete);
  };

  const handleSetDomainName = (event) => {
    setAgencyDomainState({
      domain: event.target.value
        .replace(/^(https?:\/\/)?(www\.)?/i, "")
        .replace(/\/+$/, "")
    });
  };

  const isValidDomain = (domain) => {
    const domainRegex =
      /^(?=.{1,253}$)(?:(?!\d+\.)[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;
    return domainRegex.test(domain);
  };

  const handleSaveDomainDetails = () => {
    if (!agencyDomainState.has_paid_agency_subscription) {
      togglePremiumActivateModal();
      return;
    }
    if (!isValidDomain(agencyDomainState.domain)) {
      setDomainValidationError(true);
      return;
    } 
    setDomainValidationError(false);
    saveDomainDetail({
      agency_id: atob(agencyID),
      domain: agencyDomainState.domain,
      subdomain: agencyDomainState.subdomain
    })
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          refetchDomainDetails();
          // sucesstoast("The record has been updated.");
          setDomainError(false);
        }
      })
      .catch((err) => {
        console.error("Save err:", err);
        if (err.data?.error_code === "INVALID_DOMAIN") {
          setDomainError(true);
        } else if (err.data.code === 409) {
          //domian already in use
          errortoast(err.data?.message);
        } else {
          setDomainError(false);
          errortoast("Something went wrong. Please contact support.");
        }
      });
  };

  const handleVerifyDomainDetails = () => {
    if (!agencyDomainState.has_paid_agency_subscription) {
      togglePremiumActivateModal();
      return;
    }
    activateDomain({ agency_id: atob(agencyID) })
      .unwrap()
      .then((res) => {
        if (res.code === 202 || res.code === 200) {
          sucesstoast("Submitted.");
          refetchDomainDetails();
        }
      })
      .catch((err) => {
        console.error("Verify err:", err);
        if (err.data.code === 400) {
          errortoast(err.data?.message);
        } else {
          errortoast("Something went wrong. Please contact support.");
        }
      });
  };

  const handleRightPanelExpand = (rightpanelvalue) => {
    setRightPanelExpand(rightpanelvalue);
  };

  const handleRedirect = () => {
    history.push(`/agency/pricing/${agencyID}`);
  };

  const handleCopyValues = (text) => {
    navigator.clipboard.writeText(text);
    sucesstoast("Copied");
  };

  if (agencyDomainDataError) {
    errortoast("Something went wrong. Please contact support.");
  }

  return (
    <div className="org-module">
      <Header />
      <div className="org-structure">
        <LeftPanel rightpanelbody={handleRightPanelExpand} />
        <div
          className={
            localStorage.getItem("sidebaserotate") &&
            localStorage.getItem("sidebaserotate") === "true"
              ? "org_background org_background-expand"
              : "org_background "
          }
        >
          <div className="">
            <>
              <div className="org-email-head org-domain-head-new">
                <div>
                  <div className="agency-headings">
                    <ModuleTitleTooltip
                      title={"Domain"}
                      text={"Create a branded URL for your agency business."}
                    />
                  </div>
                </div>

                {agencyDomainDataLoading ? (
                  <></>
                ) : (
                  <div className="agency-web-timestamp-maindiv">
                    <h5 className="agency-web-timestamp">
                      {!agencyDomainState.has_paid_agency_subscription ? (
                        <PremiumTag />
                      ) : agencyDomainState.last_updated_at ? (
                        "Updated " +
                        moment(agencyDomainState.last_updated_at).format(
                          "D MMMM YYYY, HH:mm"
                        )
                      ) : (
                        ""
                      )}
                    </h5>
                    {agencyDomainState.domain &&
                    agencyDomainState.subdomain &&
                    !activateDomainLoader &&
                    domainData?.data?.status !== VALIDATINGANDINACTIVE &&
                    domainData?.data?.status !== VERIFIEDANDACTIVATING ? (
                      <button
                        className="save-org-email"
                        onClick={handleSaveDomainDetails}
                        id="btnsavedomainid"
                      >
                        {saveDomainLoader ? (
                          <div>
                            <div className="new-blue-loader"></div>
                          </div>
                        ) : (
                          "Save"
                        )}
                      </button>
                    ) : (
                      <button
                        className="save-org-email btnsavedisable"
                        id="btnsavedomainid"
                      >
                        Save
                      </button>
                    )}
                    {agencyDomainState.cname_record && (
                      <button
                        onClick={handleVerifyDomainDetails}
                        className={`verify-org-email ${
                          domainData?.data?.status === VALIDATINGANDINACTIVE ||
                          domainData?.data?.status === VERIFIEDANDACTIVATING || 
                          saveDomainLoader
                            ? "btndisable"
                            : ""
                        }`}
                        id="btnverifydomainid"
                        disabled={
                          domainData?.data?.status === VALIDATINGANDINACTIVE ||
                          domainData?.data?.status === VERIFIEDANDACTIVATING || 
                          saveDomainLoader
                        }
                      >
                        {activateDomainLoader ? (
                          <div>
                            <div className="new-loader"></div>
                          </div>
                        ) : (
                          "Activate"
                        )}
                      </button>
                    )}
                  </div>
                )}
              </div>
              {agencyDomainDataLoading ? (
                <NewSpinner />
              ) : (
                <>
                  <div className="org-module-shadow org-domain-body">
                    <div className="row">
                      <div className="col-md-6 left-org-domain-set">
                        <div className="org-domain-left">
                          <div className="org-domain-settings">
                            <h2>1. Add subdomain</h2>
                            <h3>
                              Provide a subdomain where your customers can
                              login.
                            </h3>
                            <div className="mob-subdomain-varify">
                              {agencyDomainState.status ===
                              VERIFIEDANDACTIVE ? (
                                <div className="domain-varified-div">
                                  <img
                                    className="domain-varified-img"
                                    src={tickMark}
                                  />
                                  <p className="domain-varified-text">Active</p>
                                </div>
                              ) : agencyDomainState.status ===
                                VERIFIEDANDACTIVATING ? (
                                <div className="domain-validating-div">
                                  <div className="validating-loader-div">
                                    <div className="validating-loader"></div>
                                  </div>
                                  <p className="domain-validating-text">
                                    Activating
                                  </p>
                                </div>
                              ) : (
                                <div className="domain-unvarified-div">
                                  <img
                                    className="domain-unvarified-img"
                                    src={redTriangleerror}
                                  />
                                  <p className="domain-unvarified-text">
                                    Inactive
                                  </p>
                                </div>
                              )}
                            </div>
                            <h4>
                              Domain{" "}
                              <Tooltip
                                message="Enter your primary web address (e.g.,
                                  www.yourwebsite.com). This will be the main
                                  domain associated with your app."
                              />
                            </h4>
                            <div
                              className={`domain-form-address ${
                                domainValidationError ? "unverified-domain" : ""
                              }`}
                            >
                              <input
                                type="text"
                                name="domain_name"
                                id="domain_name"
                                className=""
                                value={agencyDomainState.domain}
                                onChange={(e) => handleSetDomainName(e)}
                              />
                              {domainValidationError && (
                                <div className="new-domain-error-message-div">
                                  <img src={erroricon} />
                                  <p>Enter a valid domain</p>
                                </div>
                              )}
                            </div>
                            <div className="domain-upgrade">
                              <h4>
                                Subdomain{" "}
                                <Tooltip
                                  message="Enter a subdomain if applicable (e.g.,
                                    subdomain.yourwebsite.com). Subdomains are
                                    used for specific sections or
                                    functionalities within your app."
                                />
                              </h4>
                            </div>
                            <div
                              className={`domain-form-address ${
                                domainError ? "unverified-domain" : ""
                              }`}
                            >
                              <input
                                type="text"
                                name="sub_domain"
                                id="sub_domain"
                                value={agencyDomainState.subdomain}
                                onChange={(e) =>
                                  setAgencyDomainState({
                                    subdomain: e.target.value
                                  })
                                }
                              />
                              {domainError && (
                                <div className="new-domain-error-message-div">
                                  <img src={erroricon} />
                                  <p>Enter a valid subdomain</p>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="org-domain-right">
                          <div className="explain-domain-structure">
                            <img
                              src={
                                require("../assets/images/create-domain-new.png")
                                  .default
                              }
                              alt="create-domain"
                              className="img-fluid"
                            />
                            <p>
                              <span className="subdomain-type">
                                {agencyDomainState.subdomain || "app"}
                              </span>
                              .
                              <span className="domain-type">
                                {agencyDomainState.domain || "digilapp.com"}
                              </span>
                              {/* .<span className="extension-type">{ext}</span> */}
                            </p>
                          </div>
                          <div className="subdomain-varify">
                            {agencyDomainState.status === VERIFIEDANDACTIVE ? (
                              <div className="domain-varified-div">
                                <img
                                  className="domain-varified-img"
                                  src={tickMark}
                                />
                                <p className="domain-varified-text">Active</p>
                              </div>
                            ) : agencyDomainState.status ===
                              VERIFIEDANDACTIVATING ? (
                              <div className="domain-validating-div">
                                <div className="validating-loader-div">
                                  <div className="validating-loader"></div>
                                </div>
                                <p className="domain-validating-text">
                                  Activating
                                </p>
                              </div>
                            ) : (
                              <div className="domain-unvarified-div">
                                <img
                                  className="domain-unvarified-img"
                                  src={redTriangleerror}
                                />
                                <p className="domain-unvarified-text">
                                  Inactive
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {agencyDomainState.cname_record && (
                    <div className="varify-subdomain-div">
                      <div className="varify-subdomain-head">
                        <div className="varify-subdomain-left">
                          <h5 className="varify-subdomain-title">
                            2. Verify subdomain
                          </h5>
                          <h6 className="varify-subdomain-subtitle">
                            Create a new record within your DNS host, to link
                            your subdomain.
                          </h6>
                        </div>
                        <div className="varify-subdomain-right">
                          <p
                            className="varify-domain-question"
                            onClick={deleteAccount}
                          >
                            How to?
                          </p>

                          {agencyDomainState.status === VERIFIEDANDACTIVE ||
                          agencyDomainState.status === VERIFIEDANDINACTIVE ||
                          agencyDomainState.status === VERIFIEDANDACTIVATING ? (
                            <div className="domain-varified-div">
                              <img
                                className="domain-varified-img"
                                src={tickMark}
                              />
                              <p className="domain-varified-text">Verified</p>
                            </div>
                          ) : agencyDomainState.status ===
                            VALIDATINGANDINACTIVE ? (
                            <div className="domain-validating-div">
                              <div className="validating-loader-div">
                                <div className="validating-loader"></div>
                              </div>
                              <p className="domain-validating-text">
                                Validating
                              </p>
                            </div>
                          ) : (
                            <div className="domain-unvarified-div">
                              <img
                                className="domain-unvarified-img"
                                src={redTriangleerror}
                              />
                              <p className="domain-unvarified-text">
                                Unverified
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="varify-subdomain-table-div">
                        <ul className="varify-subdomain-tablehead">
                          <li className="varify-subdomain-first-tablehead">
                            <h4 className="varify-subdomain-first-tablehead-text">
                              CNAME Host{" "}
                              <p class="agency-tooltip">
                                <img src={tooltipsvgicon} />

                                <p class="agency-tooltipsubheader">
                                  This is the subdomain you need to use for
                                  mapping your CNAME record.
                                </p>
                              </p>
                            </h4>
                          </li>
                          <li className="varify-subdomain-second-tablehead">
                            <h4 className="varify-subdomain-first-tablehead-text">
                              CNAME Destination
                              <p class="agency-tooltip">
                                <img src={tooltipsvgicon} />

                                <p class="agency-tooltipsubheader">
                                  This is the web address where your subdomain
                                  should point.
                                </p>
                              </p>
                            </h4>
                          </li>
                          <li className="varify-subdomain-third-tablehead">
                            <h4 className="varify-subdomain-first-tablehead-text">
                              TTL{" "}
                              <p class="agency-tooltip">
                                <img src={tooltipsvgicon} />

                                <p class="agency-tooltipsubheader">
                                  TTL (Time to Live) defines how long DNS
                                  information is cached before refreshing. A
                                  lower value updates changes faster.
                                </p>
                              </p>
                            </h4>
                          </li>
                          <li className="varify-subdomain-fourth-tablehead">
                            <h4 className="varify-subdomain-first-tablehead-text">
                              Proxy status{" "}
                              <p class="agency-tooltip">
                                <img src={tooltipsvgicon} />
                                <p class="agency-tooltipsubheader">
                                  This setting controls whether your CNAME
                                  record is routed directly or through a proxy.
                                  Use "DNS only" for direct routing without
                                  proxy interference.
                                </p>
                              </p>
                            </h4>
                          </li>
                        </ul>
                        <ul className="varify-domain-created-list">
                          <li className="varify-subdomain-first-tablehead">
                            <h4 className="varify-subdomain-first-tablehead-text mob-varify-subdomain-first-tablehead">
                              CNAME Host{" "}
                              <p class="agency-tooltip">
                                <img src={tooltipsvgicon} />

                                <p class="agency-tooltipsubheader">
                                  This is the subdomain you need to use for
                                  mapping your CNAME record.
                                </p>
                              </p>
                            </h4>
                            <p className="domain-list-text">
                              {agencyDomainState.cname_record}
                              <img
                                onClick={() =>
                                  handleCopyValues(
                                    agencyDomainState.cname_record
                                  )
                                }
                                className="domain-list-copy-icon"
                                src={copyIcon}
                              />
                            </p>
                          </li>
                          <li className="varify-subdomain-second-tablehead">
                            <h4 className="varify-subdomain-first-tablehead-text mob-varify-subdomain-first-tablehead">
                              CNAME Destination
                              <p class="agency-tooltip">
                                <img src={tooltipsvgicon} />

                                <p class="agency-tooltipsubheader">
                                  This is the web address where your subdomain
                                  should point.
                                </p>
                              </p>
                            </h4>
                            <p className="domain-list-text">
                              {agencyDomainState.cname_destination}
                              <img
                                onClick={() =>
                                  handleCopyValues(
                                    agencyDomainState.cname_destination
                                  )
                                }
                                className="domain-list-copy-icon"
                                src={copyIcon}
                              />
                            </p>
                          </li>
                          <li className="varify-subdomain-third-tablehead">
                            <h4 className="varify-subdomain-first-tablehead-text mob-varify-subdomain-first-tablehead">
                              TTL{" "}
                              <p class="agency-tooltip">
                                <img src={tooltipsvgicon} />

                                <p class="agency-tooltipsubheader">
                                  TTL (Time to Live) defines how long DNS
                                  information is cached before refreshing. A
                                  lower value updates changes faster.
                                </p>
                              </p>
                            </h4>
                            <p className="domain-list-text">Auto</p>
                          </li>
                          <li className="varify-subdomain-fourth-tablehead">
                            <h4 className="varify-subdomain-first-tablehead-text mob-varify-subdomain-first-tablehead">
                              Proxy status{" "}
                              <p class="agency-tooltip">
                                <img src={tooltipsvgicon} />
                                <p class="agency-tooltipsubheader">
                                  This setting controls whether your CNAME
                                  record is routed directly or through a proxy.
                                  Use "DNS only" for direct routing without
                                  proxy interference.
                                </p>
                              </p>
                            </h4>
                            <p className="domain-list-text">DNS only</p>
                          </li>
                        </ul>
                        <ul className="varify-domain-created-list">
                          <li className="varify-subdomain-first-tablehead">
                            <h4 className="varify-subdomain-first-tablehead-text mob-varify-subdomain-first-tablehead">
                              CNAME Host{" "}
                              <p class="agency-tooltip">
                                <img src={tooltipsvgicon} />

                                <p class="agency-tooltipsubheader">
                                  This is the subdomain you need to use for
                                  mapping your CNAME record.
                                </p>
                              </p>
                            </h4>
                            <p className="domain-list-text">
                              {agencyDomainState.cname_name}
                              <img
                                onClick={() =>
                                  handleCopyValues(agencyDomainState.cname_name)
                                }
                                className="domain-list-copy-icon"
                                src={copyIcon}
                              />
                            </p>
                          </li>
                          <li className="varify-subdomain-second-tablehead">
                            <h4 className="varify-subdomain-first-tablehead-text mob-varify-subdomain-first-tablehead">
                              CNAME Destination
                              <p class="agency-tooltip">
                                <img src={tooltipsvgicon} />

                                <p class="agency-tooltipsubheader">
                                  This is the web address where your subdomain
                                  should point.
                                </p>
                              </p>
                            </h4>
                            <p className="domain-list-text">
                              {agencyDomainState.cname_value}
                              <img
                                onClick={() =>
                                  handleCopyValues(
                                    agencyDomainState.cname_value
                                  )
                                }
                                className="domain-list-copy-icon"
                                src={copyIcon}
                              />
                            </p>
                          </li>
                          <li className="varify-subdomain-third-tablehead">
                            <h4 className="varify-subdomain-first-tablehead-text mob-varify-subdomain-first-tablehead">
                              TTL{" "}
                              <p class="agency-tooltip">
                                <img src={tooltipsvgicon} />

                                <p class="agency-tooltipsubheader">
                                  TTL (Time to Live) defines how long DNS
                                  information is cached before refreshing. A
                                  lower value updates changes faster.
                                </p>
                              </p>
                            </h4>
                            <p className="domain-list-text">Auto</p>
                          </li>
                          <li className="varify-subdomain-fourth-tablehead">
                            <h4 className="varify-subdomain-first-tablehead-text mob-varify-subdomain-first-tablehead">
                              Proxy status{" "}
                              <p class="agency-tooltip">
                                <img src={tooltipsvgicon} />
                                <p class="agency-tooltipsubheader">
                                  This setting controls whether your CNAME
                                  record is routed directly or through a proxy.
                                  Use "DNS only" for direct routing without
                                  proxy interference.
                                </p>
                              </p>
                            </h4>
                            <p className="domain-list-text">DNS only</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  )}
                  <div className="agency-mobile-btns">
                    {agencyDomainState.domain &&
                    agencyDomainState.subdomain &&
                    !activateDomainLoader &&
                    domainData?.data?.status !== VALIDATINGANDINACTIVE &&
                    domainData?.data?.status !== VERIFIEDANDACTIVATING ? (
                      <button
                        className="agency-mobile-btns-blank"
                        onClick={handleSaveDomainDetails}
                        id="btnsavedomainid"
                      >
                        {saveDomainLoader ? (
                          <div>
                            <div className="new-blue-loader"></div>
                          </div>
                        ) : (
                          "Save"
                        )}
                      </button>
                    ) : (
                      <button
                        className="agency-mobile-btns-blank btnsavedisable"
                        id="btnsavedomainid"
                      >
                        Save
                      </button>
                    )}
                    {agencyDomainState.cname_record && (
                      <button
                        onClick={handleVerifyDomainDetails}
                        className={`agency-mobile-btns-blue ${
                          domainData?.data?.status === VALIDATINGANDINACTIVE ||
                          domainData?.data?.status === VERIFIEDANDACTIVATING || 
                          saveDomainLoader
                            ? "btndisable"
                            : ""
                        }`}
                        id="btnverifydomainid"
                        disabled={
                          domainData?.data?.status === VALIDATINGANDINACTIVE ||
                          domainData?.data?.status === VERIFIEDANDACTIVATING || 
                          saveDomainLoader
                        }
                      >
                        {activateDomainLoader ? (
                          <div>
                            <div className="new-loader"></div>
                          </div>
                        ) : (
                          "Activate"
                        )}
                      </button>
                    )}
                    <h5>
                      {agencyDomainState.last_updated_at
                        ? "Updated " +
                          moment(agencyDomainState.last_updated_at).format(
                            "D MMMM YYYY, HH:mm"
                          )
                        : ""}
                    </h5>
                  </div>
                  <div className="domain-steps">
                    <div className="setup-domain">
                      <img
                        src={
                          require("../assets/images/subdomain-ownership.png")
                            .default
                        }
                        alt="cross"
                        className="img-fluid"
                      />
                      <div>
                        <p>Step 1</p>
                        <h3>Choose a subdomain</h3>
                      </div>
                    </div>
                    <div className="setup-domain">
                      <img
                        src={
                          require("../assets/images/dns-records.png").default
                        }
                        alt="cross"
                        className="img-fluid"
                      />
                      <div>
                        <p>Step 2</p>
                        <h3>Update DNS records</h3>
                      </div>
                    </div>
                    <div className="setup-domain">
                      <img
                        src={require("../assets/images/subdomain.png").default}
                        alt="cross"
                        className="img-fluid"
                      />
                      <div>
                        <p>Step 3</p>
                        <h3>Verify subdomain ownership</h3>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </>
          </div>
          <Modal
            isOpen={mdluserdelete}
            toggle={deleteAccount}
            style={{ maxWidth: "760px", maxHeight: "470px" }}
            className="domain-popup"
            centered
          >
            <ModalBody className="domain-popup-body">
              <div
                className="crossmodal crossicon-popup mt-1"
                onClick={deleteAccount}
              >
                <img
                  src={require("../assets/images/cross.png").default}
                  alt="cross"
                  className="img-fluid"
                />
              </div>

              <div>
                <h4>How to add a DNS record?</h4>

                <p>
                  You need to create a new CNAME record in your DNS host’s
                  configuration. Your DNS host is either your domain registrar,
                  your website hosting provider, or a DNS management service.
                </p>

                <ul>
                  <li>Login to your DNS host.</li>

                  <li>Navigate to your DNS configuration.</li>

                  <li>Add a new CNAME record</li>

                  <li>
                    Enter the CNAME Record in the Host/Name field, and the CNAME
                    Destination in the Value/Destination/Target/Points to field.
                  </li>

                  <li>
                    Once added, the new record may take several hours to
                    propagate, depending on your DNS provider
                  </li>
                </ul>

                <div className="domain-button" onClick={deleteAccount}>
                  <span>Okay</span>
                </div>
              </div>
            </ModalBody>
          </Modal>

          {/* <Modal
            isOpen={mdluserdelete}
            toggle={deleteAccount}
            className="domain-error-popup"
            centered
          >
            <ModalBody className="domain-error-popup-body">
              <div className="crossmodal mt-1" onClick={deleteAccount}>
                <img
                  src={require("../assets/images/cross.png").default}
                  alt="cross"
                  className="img-fluid"
                />
              </div>

              <div className="domain-error-message-main-div">
                <div className="domain-error-message-div">
                  <img className="error-icon" src={errorpopupIcon} />
                  <h4 className="domain-error-head-text">
                    Some thing went wrong
                  </h4>
                </div>

                <p className="domain-error-text-message">
                  Please include a case-sensitive header of Authorization: Basic
                  with a valid User Auth key.
                </p>

                <div className="domain-okay-button" onClick={deleteAccount}>
                  <span>Okay</span>
                </div>
              </div>
            </ModalBody>
          </Modal> */}
        </div>
      </div>
      <PremiumModal
        open={openPremiumModal}
        toggle={togglePremiumActivateModal}
        title="Upgrade to unlock premium features"
        description="This is a premium feature. Upgrade now to access all premium features."
        cancelbutton="Cancel"
        submitbutton="Upgrade"
        onSubmit={() => handleRedirect()}
      />
    </div>
  );
};

export default CreateDomain;
