import React from "react";
import Header from "../Header";
import { Link, Redirect } from "react-router-dom";
import Leftpanel from "../Leftpanel";
import { Foot } from "../Foot";
import {
  GetpackageAll,
  DowngradeApi,
  SendpaymentinfotoserverApi,
  Makestrippayment,
  wooChangePlan,
} from "../../Api/Dashboard/DashboardApi";
import { Spinner, Modal, ModalHeader, ModalBody } from "reactstrap";
import styles from "./subscription.module.css";
import Dealselection from "../Billing/Dealselection";
import Accordion from "../Reusable/Accordion";
import "../../assets/css/Subscription.css";
import { paddleChangePlan } from "../../Api/Addons/Addonsapi";
export default class Subscription extends React.PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef(); // Create a ref object
    this.state = {
      loader0: "d-none",
      btnVal0: "Get Started",
      loader1: "d-none",
      btnVal1: "Get Started",
      loader2: "d-none",
      btnVal2: "Get Started",
      tempFilter: false,

      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      monthlydata: [],
      yearlydata: [],
      lifetimedata: [],
      spinner: true,
      filter: "monthly",
      mactive: false,
      yactive: true,
      subscriptionboolen: "",
      currency: "",
      zoho_plan_code: "",
      subscription_id: "",
      appid: "",
      one_time: [],
      user_status: "",
      build_process_status: "",
      discount: 0,
      appuser_role_id: null,
      deluser: false,
      popupdata: null,
      loader_remove: "d-none",
      loaderimage_remove: require("../../assets/images/dashboard/loader.gif")
        .default,
      submitval_confirmpop: "Yes",
      mobilefilter: "Preview",
      payment_methods: [],
      customer_billing_type: null,
      lifetimecheck: 0,
      // handleDealsPage: 0,
      tab_check: "Preview",
      website_technology: 0,
      customer_type: "",
      reseller_licence_id: "",
      appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
			localStorage.getItem("appSideBarCollapse") === "true"
			  ? true
			  : false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "Subscription";
    this.setState({
      appid: this.props.match.params.appid,
    });
    const params = {
      appid: this.props.match.params.appid,
    };

    GetpackageAll(this, params, () => {
      if (this.state.build_process_status > 0) {
        this.props.history.push("/download-app");
      }
      if (this.state.one_time.length > 0) {
        if (
          this.state.one_time[0].plan_code ===
          process.env.REACT_APP_Lifetime_preview
        ) {
          this.props.history.push("/download-app");
        }
      }
      localStorage.setItem("Appid", atob(this.props.match.params.appid));
    });
  }
  removepopup = () => {
    if (this.state.appuser_role_id !== 3 && this.state.appuser_role_id !== 4) {
      this.setState({
        deluser: !this.state.deluser,
      });
    }
  };

  previewdowngrade = (e) => {
    e.preventDefault();
    this.setState({
      loader_remove: "d-block",
      submitval_confirmpop: "",
    });
    const sendparam = {
      app_id: atob(this.props.match.params.appid),
      total: this.state.one_time[0].amount,
      plan_array: [
        {
          zoho_plan_code: this.state.one_time[0].plan_code,
          type: 1,
          amount: this.state.one_time[0].amount,
          quantity: 1,
        },
      ],
      currency: "$",
      order_type: "downgrade",
    };
    const sendtostrip = {
      app_id: parseInt(atob(this.props.match.params.appid)),
      plan_array: [
        {
          price_id: this.state.one_time[0].plan_code,
          quantity: 1,
          type: 1,
        },
      ],
      payment_method_id: "downgrade",
      order_type: 2,
      deal_id: null,
    };
    const paddleParam = {
      workspace_id:
        localStorage.getItem("workspaceId") !== "" &&
        localStorage.getItem("workspaceId") !== "undefined" &&
        localStorage.getItem("workspaceId") !== undefined &&
        localStorage.getItem("workspaceId") !== null
          ? parseInt(localStorage.getItem("workspaceId"))
          : null,
      app_id:
        localStorage.getItem("workspaceId") !== "" &&
        localStorage.getItem("workspaceId") !== "undefined" &&
        localStorage.getItem("workspaceId") !== undefined &&
        localStorage.getItem("workspaceId") !== null
          ? null
          : parseInt(localStorage.getItem("Appid")),
      deal_id: null,
      order_type: 2,
      price_id: this.state.one_time[0].plan_code,
      quantity: 1,
      type: 1,
    };

    //Payment Api
    this.state.customer_billing_type === 2
      ? //strip downgrade
        Makestrippayment(sendtostrip, this)
      : this.state.customer_billing_type === 3
      ? paddleChangePlan(paddleParam, this)
      : SendpaymentinfotoserverApi(sendparam, this, () => {
          const params = {
            app_id: parseInt(atob(this.props.match.params.appid)),
            addon_array: [],
            type: "free",
          };
          DowngradeApi(params, this);
        });
  };

  monthly = (e) => {
    this.setState({
      filter: e,
      mobilefilter: "Preview",
    });
  };
  handlemobilefilter = (plan_name) => {
    this.setState({
      mobilefilter: plan_name,
      tab_check: plan_name,
    });
  };
  handlescroll = () => {
    const { index, selected } = this.props;
    if (index === selected) {
      this.myRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  handleDeals = () => {
    if (this.state.appid) {
      this.props.history.push(`/app/apply-deal/${this.state.appid}`);
    } else {
      this.props.history.push("/deals");
    }
  };

  extend = () => {
    if (
      document
        .querySelector(".moretext")
        .classList.contains(".accordion-item-new")
    ) {
      document
        .querySelector(".moretext")
        .classList.toggle("accordion-item-new");
    }
    if (document.querySelector(".see-more-btn").textContent === "See More") {
      document.querySelector(".see-more-btn").textContent = "See Less";
      document
        .querySelector(".moretext")
        .classList.toggle("accordion-item-new");
      document.querySelector(".see-more-faq").classList.toggle("see-more-new");
      document
        .querySelector(".see-more-btn")
        .classList.toggle("see-more-btn-new");
    } else {
      document.querySelector(".see-more-btn").textContent = "See More";
      document
        .querySelector(".moretext")
        .classList.toggle("accordion-item-new");
      document.querySelector(".see-more-faq").classList.toggle("see-more-new");
      document
        .querySelector(".see-more-btn")
        .classList.toggle("see-more-btn-new");
    }
  };

  handleWooCustomer = (e, customer_type, plan_code, i) => {
    if (i !== undefined) {
      this.setState({
        ["loader" + i]: "d-block",
        ["btnVal" + i]: "",
      });
    } else {
      this.setState({
        loader_remove: "d-block",
        submitval_confirmpop: "",
      });
    }
    this.setState({
      tempFilter: true,
    });
    if (customer_type === 4) {
      const params = {
        price_id: plan_code,
        type: 1, ///1 for plan AND 2 for addon
        app_id: parseInt(atob(this.state.appid)), //either this or workspace_id

        return_url:
          // "https://app.stage.appmysite.com/thankyou",
          window.location.protocol + "//" + window.location.host + "/transaction-successful",
      };
      wooChangePlan(this, params);
    }
  };

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }

  render() {
    const accordionData = [
      {
        title: "Can I change my subscription plan(s)?",
        content: `You can easily upgrade or downgrade your subscriptions at any time, on a prorated basis.`,
      },
      {
        title: "What is your cancellation policy?",
        content: `You can cancel your paid subscription and downgrade to the free plan at any time. Once canceled, you will not be charged for any subsequent billing cycles.

        Your app will stop working as soon as you downgrade back to a free plan.`,
      },
      {
        title: "What is your refund policy?",
        content: `AppMySite does not offer refunds. <a href="#">Click</a> here for complete details of our Refund Policy.`,
      },
      {
        title: "Are there any additional charges?",
        content: `No, there are no additional charges and you get all the benefits that are a part of your subscription plan. However, for added services and features, AppMySite offers several mobile app add-ons that can supercharge your mobile app. You can opt for the add-ons at any time from within your account.`,
      },
      {
        title: "Why subscription based pricing?",
        content: `AppMySite is an online service that allows you to design and build your own mobile application. This state-of-the-art technology is hosted on our servers and is enriched with new features regularly. You benefit from these innovations automatically, as long as you have an active paid subscription.`,
      },
      {
        title: "How many apps can I create?",
        content: `You can create as many apps as you like. However, you can only generate an app build based on the paid subscription plan associated with your app.`,
      },
      {
        title: "What do I get in the Preview plan?",
        content: `We’d like you to try before you buy, which is what you can do with the Preview plan. You can build as many free apps as you like and preview them from within your account before you upgrade and publish.`,
      },
      {
        title: "What is a distribution license?",
        content: `Distribution licenses authorize you to download your app from within your account for uploading them to the app stores.`,
      },
      {
        title: "Will AppMySite work with my WordPress website?",
        content: `Yes! Our mobile app solution is designed to support the native functionality of WordPress. Once your app is connected to your site, your pages, posts, categories, and menus will appear within the app automatically. Any changes made to your CMS or blog website will reflect instantly in your app.`,
      },
      {
        title: "What WordPress plugins do you support?",
        content: `Although AppMySite supports a wide variety of third-party WordPress plugins, there are a reasonable number of plugins that will not work. It depends on how the plugin is coded. Plugins integrated with the official WordPress REST APIs are likely to work well with our app. However, if the plugin does not support the WordPress REST APIs, your plugin will struggle. Your best bet is to reach out to the author of the plugin in question for assistance. Alternatively, you could simply turn on our web view feature within the app settings, that will render your mobile site within the app.
        It is important to note that some plugins have the potential to tamper with the core functionality of your WordPress website and as a result will disrupt the connectivity between your website and your app.
        `,
      },
      {
        title: "Do I need a developer account?",
        content: `Yes, depending on your plan you’ll need developer accounts on the Google Play Store and/or the Apple App Store in order to publish your apps.
        Once you build your apps, you’ll be able to download your APK or AAB files (for your Android apps) and an IPA file (for your iOS apps) which then can be uploaded to your developer accounts. You can also request AppMySite to publish your apps for you, by simply purchasing the Upload to App Stores add on.
        `,
      },
      {
        title: "How much time does it take to publish an app?",
        content: `Once an app is submitted to the relevant app store, it is subject to a review from their end. Both stores generally take 24-48 hours but could take longer depending on various factors. You can find more details on their official websites.`,
      },
    ];

    if (this.state.appuser_role_id === 3 || this.state.appuser_role_id === 4) {
      this.props.history.push("/app/dashboard");
    }

    const dlink = (
      plan_code,
      recurring_price,
      package_name,
      interval_unit,
      id
    ) => {
      if (
        this.state.appuser_role_id !== 3 &&
        this.state.appuser_role_id !== 4
      ) {
        return (
          "/checkout/" +
          plan_code +
          "/" +
          btoa(recurring_price) +
          "/" +
          btoa(plan_code) +
          "/" +
          btoa(interval_unit) +
          "/" +
          btoa(id) +
          "/" +
          btoa(null) +
          "/" +
          "1"
        );
      }
    };

    return (
      <>
        <div className="d-md-block">
          <Header customclass={"header-responsive-new"} />
        </div>
        {/* Mobile View */}
        {/* <Mobiletop
          name={"Subscription"}
          imgpath={null}
          monitorMobileclass={"subs-mobile-top"}
        /> */}
        {/* Mobile View End*/}
        <section className="dasboard_page" style={{ background: "none" }}>
          <div className="d-md-none d-block">
          <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
          </div>
          {this.state.spinner ? (
            <div className="spinn">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <>
              <div
                className={
                  "transparent_background transparent_background_planlisting upgradepage plans-bottompadding " +
                  styles.makechange
                }
              >
                <div className="subscriptions-top-head">
                  <div
                    className={
                      styles.setbackground +
                      " setbackground-new setbackground-new-mobile"
                    }
                  >
                    <div
                      className={
                        styles.widthset +
                        " card-container-width card-container-width-mobile"
                      }
                    >
                      <div className="mt-md-3 mt-0 pb-3 pb-md-0 d-md-flex d-block justify-content-between subscription-plan-head subscription-plan-head-mobile">
                        <div className={styles.heading}>
                          {localStorage.getItem("website_technology") ===
                          "1" ? (
                            <h3 className="subscriptions-new-head subscriptions-new-head-mobile">
                              WordPress Plans
                            </h3>
                          ) : localStorage.getItem("website_technology") ===
                            "2" ? (
                            <h3 className="subscriptions-new-head subscriptions-new-head-mobile">
                              Website to App Plans
                            </h3>
                          ) : (
                            <h3 className="subscriptions-new-head subscriptions-new-head-mobile">
                              Custom App Plans
                            </h3>
                          )}
                          {this.state.customer_type !== 4 && (
                            <div
                              className="deals-page-direct-btn deals-page-mobile subscription-direct-btn d-flex d-md-none align-items-center new-license-update"
                              onClick={this.handleDeals}
                            >
                              <a className="deals-btn deals-btn-mobile subscription-deals-btn">
                                <h5>% Apply deal license</h5>
                                {/* <img
                                  src={
                                    require("../../assets/images/dashboard/deals-arrow.png")
                                      .default
                                  }
                                  className="deals-arrow-img"
                                /> */}
                              </a>
                            </div>
                          )}
                        </div>
                        <div
                          className={
                            "text-center subscription-tab-new subscription-plan-list " +
                            styles.tab +
                            " " +
                            styles.new_tab_class
                          }
                        >
                          <ul
                            className={
                              styles.selection +
                              " mt-md-0 mt-2 plan-nav plan-nav-mobile"
                            }
                          >
                            <li className="monthly-spec monthly-spec-new">
                              <div
                                className={
                                  this.state.filter === "monthly"
                                    ? styles.active + " plan-tab-active"
                                    : undefined
                                }
                                onClick={(e) =>
                                  this.state.tempFilter !== true
                                    ? this.monthly("monthly")
                                    : ""
                                }
                              >
                                Monthly
                              </div>
                            </li>
                            <li className="yearly-spec">
                              <div
                                className={
                                  this.state.filter === "yearly"
                                    ? styles.active
                                    : undefined + " plan-tab-inactive"
                                }
                                onClick={(e) =>
                                  this.state.tempFilter !== true
                                    ? this.monthly("yearly")
                                    : ""
                                }
                              >
                                Yearly
                              </div>
                            </li>
                            {this.state.lifetimecheck == 1 &&
                            this.state.customer_type !== 4 ? (
                              <li className="lifetime-spec lifetime-spec-new">
                                <div
                                  className={
                                    this.state.filter === "lifetime"
                                      ? styles.active + " plan-tab-active"
                                      : undefined
                                  }
                                  onClick={(e) =>
                                    this.state.tempFilter !== true
                                      ? this.monthly("lifetime")
                                      : ""
                                  }
                                >
                                  Lifetime
                                </div>
                              </li>
                            ) : (
                              <></>
                            )}
                          </ul>
                          {this.state.customer_type !== 4 && (
                            <div
                              className="deals-page-direct-btn deals-page-mobile subscription-direct-btn d-md-flex d-none align-items-center new-license-update"
                              onClick={this.handleDeals}
                            >
                              <a className="deals-btn deals-btn-mobile subscription-deals-btn">
                                <h5>% Apply deal license</h5>
                                {/* <img
                                  src={
                                    require("../../assets/images/dashboard/deals-arrow.png")
                                      .default
                                  }
                                  className="deals-arrow-img"
                                /> */}
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                      {/* Summary start */}
                      <div className="mt-md-5 mt-2 new-dealsub-spacing dealsub-mobile-spacing pricing-plan-cards pricing-plan-cards-mobile subscription-cards-spacing">
                        <div
                          className={
                            styles.summarychart +
                            " summary-chart-mobile summary-chart-new summary-chart-new-mobile "
                          }
                        >
                          {/* Preview */}
                          <div
                            className={
                              styles.summarydetail +
                              " summary-detail-new summary-detail-new-mobile"
                            }
                          >
                            <h2 className="card-heading-first card-heading-first-mobile">
                              {this.state.one_time[0].plan_name}
                            </h2>
                            {/* <span
                              className={styles.line + " card-underline"}
                            ></span> */}
                            <div className="mt-4 device-icons">
                              <img
                                src={
                                  require("../../assets/images/dashboard/device.png")
                                    .default
                                }
                                alt="de"
                                className="mr-2"
                              />
                              <img
                                src={
                                  require("../../assets/images/dashboard/android_inactive.png")
                                    .default
                                }
                                alt="de"
                                className="mr-1"
                              />
                              <img
                                src={
                                  require("../../assets/images/dashboard/ios_inactive.png")
                                    .default
                                }
                                alt="de"
                              />
                            </div>
                            <div className="plan-desc-data plan-desc-data-mobile">
                              <ul className={styles.listing}>
                                <p className={styles.summarypara}>
                                  {localStorage.getItem(
                                    "website_technology"
                                  ) === "1" ? (
                                    <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-first">
                                      <li>Website to App</li>
                                      <li>WordPress Integration</li>
                                      <li>WooCommerce Integration</li>
                                      <li>Personalization</li>
                                      <li>Test on Real Device</li>
                                    </ul>
                                  ) : (
                                    <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-first">
                                      <li>Website to App</li>
                                      <li>Personalization</li>

                                      <li>Test on Real Device</li>
                                    </ul>
                                  )}
                                  {/* {
                                    Object.keys(
                                      this.state.one_time[0].description.summary
                                    )[1]
                                  } */}
                                </p>
                              </ul>
                            </div>
                            <div className="select-button-spacing-first select-button-repadding select-button-spacing-first-mobile">
                              <div
                                className={
                                  styles.listpricing + " plan-select-new-btn"
                                }
                              >
                                <div className="select-btn-text">
                                  <h4 className="price-fonts">
                                    Free
                                    {/* {this.state.one_time[0].amount.replace(
                                      ".00",
                                      " "
                                    )}{" "} */}
                                  </h4>
                                </div>
                                {this.state.one_time[0].is_subscribed === 1 ? (
                                  <Link to="#" className={styles.getstarted}>
                                    Current Plan
                                  </Link>
                                ) : this.state.customer_type === 4 &&
                                  this.state.reseller_licence_id === null ? (
                                  <a
                                    className={
                                      styles.getstartedactive +
                                      " select-button-new"
                                    }
                                    target="_blank"
                                    href={
                                      process.env.REACT_APP_WOOCOMMERCE_URL +
                                      "my-account/my-subscriptions/"
                                    }
                                  >
                                    Get Started{" "}
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/Goto.png")
                                          .default
                                      }
                                      className="gotoappicon appear-spacing"
                                      alt="Goto"
                                    />
                                  </a>
                                ) : (
                                  <Link
                                    to="#"
                                    onClick={(e) => this.removepopup()}
                                    className={
                                      styles.getstartedactive +
                                      " select-button-new"
                                    }
                                  >
                                    Get Started
                                    {/* {this.state.customer_type === 4 &&  this.state.reseller_licence_id === null ? 
                                    	(
                                            <img
                                              src={
                                                require("../../assets/images/dashboard/Goto.png")
                                                  .default
                                              }
                                              className="gotoappicon appear-spacing"
                                              alt="Goto"
                                            />
                                          ) : (
                                            <></>
                                          )}
                                      */}
                                  </Link>
                                )}
                                {/* <a href="#compare-list">
                                  <h4 className="card-foot-text card-foot-text-mobile">
                                    See all features
                                  </h4>
                                </a> */}
                              </div>
                            </div>
                          </div>
                          {/* Monthly */}
                          {this.state.filter === "monthly" &&
                            this.state.monthlydata &&
                            this.state.monthlydata.map((res, i) => (
                              <div
                                className={
                                  res.plan_name === "Premium Yearly" ||
                                  res.plan_name === "Premium Monthly" ||
                                  res.plan_name === "Premium Lifetime"
                                    ? styles.summarydetail +
                                      " last-plan-card summary-detail-new summary-detail-new-mobile "
                                    : " summary-detail-new summary-detail-new-mobile " +
                                      styles.summarydetail
                                }
                                key={i}
                              >
                                <h2 className="card-heading-first card-heading-first-mobile">
                                  {res.plan}
                                </h2>
                                {/* <span className={styles.line}></span> */}
                                <div className="mt-4 device-icons">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/device.png")
                                        .default
                                    }
                                    alt="de"
                                    className="mr-2"
                                  />
                                  {res.description.summary.Platforms.android ===
                                  1 ? (
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/android_active.png")
                                          .default
                                      }
                                      alt="de"
                                      className="mr-1"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/android_inactive.png")
                                          .default
                                      }
                                      alt="de"
                                      className="mr-1"
                                    />
                                  )}
                                  {res.description.summary.Platforms.ios ===
                                  1 ? (
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/ios_active.png")
                                          .default
                                      }
                                      alt="de"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/ios_inactive.png")
                                          .default
                                      }
                                      alt="de"
                                    />
                                  )}
                                </div>
                                <div className="plan-desc-data plan-desc-data-mobile">
                                  {res.plan === "starter" ? (
                                    <ul className={styles.listing}>
                                      <p className={styles.summarypara}>
                                        <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-first">
                                          <li>
                                            {/* {" "} */}
                                            <strong>
                                              Everything in Preview, plus
                                            </strong>
                                          </li>
                                          <li>
                                            {localStorage.getItem(
                                              "website_technology"
                                            ) === "1" ? (
                                              <strong>1 x</strong>
                                            ) : (
                                              "1x"
                                            )}{" "}
                                            Android App
                                          </li>
                                          <li>Android Push Notifications</li>
                                          <li>Google Firebase & AdMob</li>
                                          <li>Team Members</li>
                                        </ul>
                                      </p>
                                    </ul>
                                  ) : res.plan === "pro" ? (
                                    <ul className={styles.listing}>
                                      <p className={styles.summarypara}>
                                        <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-first">
                                          <li>
                                            {/* {" "} */}
                                            <strong>
                                              Everything in Starter, plus
                                            </strong>
                                          </li>
                                          <li>
                                            {localStorage.getItem(
                                              "website_technology"
                                            ) === "1" ? (
                                              <strong>1 x</strong>
                                            ) : (
                                              "1x"
                                            )}{" "}
                                            iOS App
                                          </li>
                                          <li>iOS Push Notifications</li>
                                          <li>Apple Login & Signup</li>
                                          <li>User Consent Screen</li>
                                        </ul>
                                      </p>
                                    </ul>
                                  ) : (
                                    <ul className={styles.listing}>
                                      <p className={styles.summarypara}>
                                        <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-first">
                                          <li>
                                            <strong>
                                              Everything in Pro, plus
                                            </strong>
                                          </li>
                                          <li>Multisite & Merge Apps</li>
                                          {localStorage.getItem(
                                            "website_technology"
                                          ) !== "2" && (
                                            <li>Language Selection</li>
                                          )}
                                          {localStorage.getItem(
                                            "website_technology"
                                          ) !== "1" && <li>App Analytics</li>}
                                          <li>Auto Publish</li>
                                          {localStorage.getItem(
                                            "website_technology"
                                          ) === "1" && (
                                            <li>Priority Support</li>
                                          )}
                                        </ul>
                                      </p>
                                    </ul>
                                  )}
                                </div>
                                <div className="select-button-spacing-first select-button-repadding select-button-spacing-first-mobile">
                                  <div
                                    className={
                                      styles.listpricing +
                                      " plan-select-new-btn"
                                    }
                                  >
                                    <div className="select-btn-text">
                                      <h4 className="price-fonts">
                                        {res.package_currency.currency_symbol}
                                        {res.amount.replace(".00", " ")}{" "}
                                      </h4>

                                      <span>per month</span>
                                    </div>
                                    {res.is_subscribed === 1 ? (
                                      <Link
                                        to="#"
                                        className={styles.getstarted}
                                      >
                                        Current Plan
                                      </Link>
                                    ) : (
                                      //   res.plan_name.indexOf("Premium") > -1
                                      //    ?
                                      //    <Link to="#" className={styles.getstarted}>Coming Soon</Link>
                                      //    :

                                      <Link
                                        to={
                                          this.state.customer_type === 4
                                            ? "#"
                                            : dlink(
                                                res.plan_code,
                                                parseInt(res.amount),
                                                res.plan_name,
                                                res.package_currency
                                                  .currency_symbol,
                                                res.plan_code
                                              )
                                        }
                                        onClick={(e) =>
                                          this.handleWooCustomer(
                                            e,
                                            this.state.customer_type,
                                            res.plan_code,
                                            i
                                          )
                                        }
                                        className={
                                          styles.getstartedactive +
                                          " select-button-new"
                                        }
                                      >
                                        <div
                                          className={
                                            this.state.filter === "monthly"
                                              ? this.state["loader" + i]
                                              : "d-block"
                                          }
                                        >
                                          <img
                                            src={this.state.loaderimage}
                                            alt="loader"
                                          />
                                        </div>
                                        <div>
                                          {this.state["btnVal" + i]}
                                          {this.state.customer_type === 4 &&
                                          this.state["loader" + i] ===
                                            "d-none" ? (
                                            <img
                                              src={
                                                require("../../assets/images/dashboard/Goto.png")
                                                  .default
                                              }
                                              className="gotoappicon appear-spacing"
                                              alt="Goto"
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </Link>
                                    )}
                                    {/* <a href="#compare-list">
                                      <h4 className="card-foot-text card-foot-text-mobile">
                                        See all features
                                      </h4>
                                    </a> */}
                                  </div>
                                </div>
                              </div>
                            ))}
                          {/* Yearly */}
                          {this.state.filter === "yearly" &&
                            this.state.yearlydata &&
                            this.state.yearlydata.map((res, i) => (
                              <div
                                className={
                                  res.plan_name === "Premium Yearly" ||
                                  res.plan_name === "Premium Monthly" ||
                                  res.plan_name === "Premium Lifetime"
                                    ? styles.summarydetail +
                                      " last-plan-card summary-detail-new summary-detail-new-mobile "
                                    : " summary-detail-new summary-detail-new-mobile " +
                                      styles.summarydetail
                                }
                                key={i}
                              >
                                <div className="d-flex align-item-center justify-content-between">
                                  <div>
                                    <h2
                                      className={
                                        res.plan_name === "Starter Yearly"
                                          ? "card-heading-second card-heading-second-mobile"
                                          : res.plan_name === "Pro Yearly"
                                          ? "card-heading-third card-heading-third-mobile"
                                          : res.plan_name === "Premium Yearly"
                                          ? "card-heading-fourth card-heading-fourth-mobile"
                                          : "card-heading-first card-heading-first-mobile"
                                      }
                                    >
                                      {res.plan}
                                    </h2>
                                    {/* <span
                                      className={
                                        styles.line + " card-underline"
                                      }
                                    ></span> */}
                                  </div>
                                  <div className="plan-savings">
                                    <span className={styles.savetag}>
                                      {
                                        Object.keys(
                                          this.state.yearlydata[i].description
                                            .summary
                                        )[2]
                                      }
                                    </span>
                                  </div>
                                </div>
                                <div className="mt-4 device-icons">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/device.png")
                                        .default
                                    }
                                    alt="de"
                                    className="mr-2"
                                  />
                                  {res.description.summary.Platforms.android ===
                                  1 ? (
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/android_active.png")
                                          .default
                                      }
                                      alt="de"
                                      className="mr-1"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/android_inactive.png")
                                          .default
                                      }
                                      alt="de"
                                      className="mr-1"
                                    />
                                  )}
                                  {res.description.summary.Platforms.ios ===
                                  1 ? (
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/ios_active.png")
                                          .default
                                      }
                                      alt="de"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/ios_inactive.png")
                                          .default
                                      }
                                      alt="de"
                                    />
                                  )}
                                </div>
                                <div className="plan-desc-data plan-desc-data-mobile">
                                  <ul className={styles.listing}>
                                    <p className={styles.summarypara}>
                                      {/* {Object.keys(res.description.summary)[1]} */}
                                      {res.plan === "starter" ? (
                                        <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-second">
                                          <li>
                                            {/* {" "} */}
                                            <strong>
                                              Everything in Preview, plus
                                            </strong>
                                          </li>
                                          <li>
                                            {localStorage.getItem(
                                              "website_technology"
                                            ) === "1" ? (
                                              <strong>1 x</strong>
                                            ) : (
                                              "1x"
                                            )}{" "}
                                            Android App
                                          </li>
                                          <li>Android Push Notifications</li>
                                          <li>Google Firebase & AdMob</li>
                                          <li>Team Members</li>
                                        </ul>
                                      ) : res.plan === "pro" ? (
                                        <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-third">
                                          <li>
                                            {/* {" "} */}
                                            <strong>
                                              Everything in Starter, plus
                                            </strong>
                                          </li>
                                          <li>
                                            {localStorage.getItem(
                                              "website_technology"
                                            ) === "1" ? (
                                              <strong>1 x</strong>
                                            ) : (
                                              "1x"
                                            )}{" "}
                                            iOS App
                                          </li>
                                          <li>iOS Push Notifications</li>
                                          <li>Apple Login & Signup</li>
                                          <li>User Consent Screen</li>
                                        </ul>
                                      ) : res.plan === "premium" ? (
                                        <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-fourth">
                                          <li>
                                            <strong>
                                              Everything in Pro, plus
                                            </strong>
                                          </li>
                                          <li>Multisite & Merge Apps</li>
                                          {localStorage.getItem(
                                            "website_technology"
                                          ) !== "2" && (
                                            <li>Language Selection</li>
                                          )}
                                          {localStorage.getItem(
                                            "website_technology"
                                          ) !== "1" && <li>App Analytics</li>}
                                          <li>Auto Publish</li>
                                          {localStorage.getItem(
                                            "website_technology"
                                          ) === "1" && (
                                            <li>Priority Support</li>
                                          )}
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </ul>
                                </div>
                                <div
                                  className={
                                    "select-button-spacing-first select-button-repadding select-button-spacing-first-mobile"
                                  }
                                >
                                  <div
                                    className={
                                      styles.listpricing +
                                      " plan-select-new-btn"
                                    }
                                  >
                                    <div className="select-btn-text">
                                      <h4 className="price-fonts">
                                        {res.package_currency.currency_symbol}
                                        {res.amount.replace(".00", " ")}{" "}
                                      </h4>
                                      <span>per month paid yearly</span>
                                    </div>
                                    {res.is_subscribed === 1 ? (
                                      <Link
                                        to="#"
                                        className={
                                          styles.getstarted +
                                          " select-button-new"
                                        }
                                      >
                                        Current Plan
                                      </Link>
                                    ) : (
                                      //   res.plan_name.indexOf("Premium") > -1
                                      //    ?
                                      //    <Link to="#" className={styles.getstarted}>Coming Soon</Link>
                                      //    :
                                      <Link
                                        to={
                                          this.state.customer_type === 4
                                            ? "#"
                                            : dlink(
                                                res.plan_code,
                                                parseInt(res.amount),
                                                res.plan_name,
                                                res.package_currency
                                                  .currency_symbol,
                                                res.plan_code
                                              )
                                        }
                                        onClick={(e) =>
                                          this.handleWooCustomer(
                                            e,
                                            this.state.customer_type,
                                            res.plan_code,
                                            i
                                          )
                                        }
                                        className={
                                          styles.getstartedactive +
                                          " select-button-new"
                                        }
                                      >
                                        <div
                                          className={
                                            this.state.filter === "yearly"
                                              ? this.state["loader" + i]
                                              : "d-block"
                                          }
                                        >
                                          <img
                                            src={this.state.loaderimage}
                                            alt="loader"
                                          />
                                        </div>
                                        <div>
                                          {this.state["btnVal" + i]}
                                          {this.state.customer_type === 4 &&
                                          this.state["loader" + i] ===
                                            "d-none" ? (
                                            <img
                                              src={
                                                require("../../assets/images/dashboard/Goto.png")
                                                  .default
                                              }
                                              className="gotoappicon appear-spacing"
                                              alt="Goto"
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </Link>
                                    )}
                                    {/* <a href="#compare-list">
                                      <h4 className="card-foot-text card-foot-text-mobile">
                                        See all features
                                      </h4>
                                    </a> */}
                                  </div>
                                </div>
                              </div>
                            ))}

                          {/* Lifetime */}

                          {this.state.filter === "lifetime" &&
                            this.state.lifetimedata &&
                            this.state.lifetimedata.map((res, i) => (
                              <div
                                className={
                                  res.plan_name === "Premium Yearly" ||
                                  res.plan_name === "Premium Monthly" ||
                                  res.plan_name === "Premium Lifetime"
                                    ? styles.summarydetail +
                                      " last-plan-card summary-detail-new summary-detail-new-mobile "
                                    : " summary-detail-new summary-detail-new-mobile " +
                                      styles.summarydetail
                                }
                                key={i}
                              >
                                <h2 className="card-heading-first card-heading-first-mobile">
                                  {res.plan}
                                </h2>
                                {/* <span className={styles.line}></span> */}
                                <div className="mt-4 device-icons">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/device.png")
                                        .default
                                    }
                                    alt="de"
                                    className="mr-2"
                                  />
                                  {res.description.summary.Platforms.android ===
                                  1 ? (
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/android_active.png")
                                          .default
                                      }
                                      alt="de"
                                      className="mr-1"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/android_inactive.png")
                                          .default
                                      }
                                      alt="de"
                                      className="mr-1"
                                    />
                                  )}
                                  {res.description.summary.Platforms.ios ===
                                  1 ? (
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/ios_active.png")
                                          .default
                                      }
                                      alt="de"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/ios_inactive.png")
                                          .default
                                      }
                                      alt="de"
                                    />
                                  )}
                                </div>
                                <div className="plan-desc-data plan-desc-data-mobile">
                                  <ul className={styles.listing}>
                                    <p className={styles.summarypara}>
                                      {/* {Object.keys(res.description.summary)[1]} */}
                                      {res.plan === "starter" ? (
                                        <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-second">
                                          <li>
                                            {/* {" "} */}
                                            <strong>
                                              Everything in Preview, plus
                                            </strong>
                                          </li>
                                          <li>Android App</li>
                                          <li>Android Push Notifications</li>
                                          <li>Google Firebase & AdMob</li>
                                          <li>Team Members</li>
                                        </ul>
                                      ) : res.plan === "pro" ? (
                                        <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-third">
                                          <li>
                                            {/* {" "} */}
                                            <strong>
                                              Everything in Starter, plus
                                            </strong>
                                          </li>
                                          <li>iOS App</li>
                                          <li>iOS Push Notifications</li>
                                          <li>Apple Login & Signup</li>
                                          <li>User Consent Screen</li>
                                        </ul>
                                      ) : res.plan === "premium" ? (
                                        <ul className="ml-1 pl-3 plan-detail-list plan-detail-list-mobile plan-fourth">
                                          <li>
                                            <strong>
                                              Everything in Pro, plus
                                            </strong>
                                          </li>
                                          <li>Multisite & Merge Apps</li>
                                          {localStorage.getItem(
                                            "website_technology"
                                          ) !== "2" && (
                                            <li>Language Selection</li>
                                          )}
                                          {localStorage.getItem(
                                            "website_technology"
                                          ) !== "1" && <li>App Analytics</li>}
                                          <li>Auto Publish</li>
                                          {localStorage.getItem(
                                            "website_technology"
                                          ) === "1" && (
                                            <li>Priority Support</li>
                                          )}
                                        </ul>
                                      ) : (
                                        ""
                                      )}
                                    </p>
                                  </ul>
                                </div>
                                <div className="select-button-spacing-first select-button-repadding select-button-spacing-first-mobile">
                                  <div
                                    className={
                                      styles.listpricing +
                                      " plan-select-new-btn"
                                    }
                                  >
                                    <div className="select-btn-text">
                                      <h4 className="price-fonts">
                                        {res.package_currency.currency_symbol}
                                        {res.amount.replace(".00", " ")}{" "}
                                      </h4>
                                      <span>one time payment</span>
                                    </div>
                                    {res.is_subscribed === 1 ? (
                                      <Link
                                        to="#"
                                        className={styles.getstarted}
                                      >
                                        Current Plan
                                      </Link>
                                    ) : (
                                      //   res.plan_name.indexOf("Premium") > -1
                                      //    ?
                                      //    <Link to="#" className={styles.getstarted}>Coming Soon</Link>
                                      //    :
                                      <Link
                                        to={
                                          this.state.customer_type === 4
                                            ? "#"
                                            : dlink(
                                                res.plan_code,
                                                parseInt(res.amount),
                                                res.plan_name,
                                                res.package_currency
                                                  .currency_symbol,
                                                res.plan_code
                                              )
                                        }
                                        onClick={(e) =>
                                          this.handleWooCustomer(
                                            e,
                                            this.state.customer_type,
                                            res.plan_code,
                                            i
                                          )
                                        }
                                        className={
                                          styles.getstartedactive +
                                          " select-button-new"
                                        }
                                      >
                                        <div
                                          className={
                                            this.state.filter === "lifetime"
                                              ? this.state["loader" + i]
                                              : "d-block"
                                          }
                                        >
                                          <img
                                            src={this.state.loaderimage}
                                            alt="loader"
                                          />
                                        </div>
                                        <div>
                                          {this.state["btnVal" + i]}
                                          {this.state.customer_type === 4 &&
                                          this.state["loader" + i] ===
                                            "d-none" ? (
                                            <img
                                              src={
                                                require("../../assets/images/dashboard/Goto.png")
                                                  .default
                                              }
                                              className="gotoappicon appear-spacing"
                                              alt="Goto"
                                            />
                                          ) : (
                                            <></>
                                          )}
                                        </div>
                                      </Link>
                                    )}
                                    {/* <a href="#compare-list">
                                      <h4 className="card-foot-text card-foot-text-mobile">
                                        See all features
                                      </h4>
                                    </a> */}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                      {/* Summary end */}
                      {/* compare */}

                      {/* <div
                        className={styles.seefull}
                        onClick={(e) => this.handlescroll()}
                      >
                        <p>See full comparison table</p>
                        <svg
                          width="11.061"
                          height="12.894"
                          viewBox="0 0 11.061 12.894"
                        >
                          <g
                            id="Group_5530"
                            data-name="Group 5530"
                            transform="translate(-913.47 -927.667)"
                          >
                            <path
                              id="Path_9581"
                              data-name="Path 9581"
                              d="M4364-16011l5,5,5-5"
                              transform="translate(-3450 16945.5)"
                              fill="none"
                              stroke="#3064f9"
                              strokeWidth="1.5"
                            />
                            <path
                              id="Path_9586"
                              data-name="Path 9586"
                              d="M919,938.667v-12"
                              transform="translate(0 1)"
                              fill="none"
                              stroke="#3064f9"
                              strokeWidth="1.5"
                            />
                          </g>
                        </svg>
                      </div> */}
                      {/*Agency Plan */}
                      {/* <div className={styles.looking + " mt-5"}>
                        <div>
                          <h5>Looking for an enterprise solution?</h5>
                          <p ref={this.myRef}>
                            Looking for something else? Get in touch with our
                            enterprise specialists.
                          </p>
                        </div>
                        <div> */}
                      {/* <h4>$249</h4>
                 <span>Per month paid yearly</span> */}
                      {/* <a
                            href="https://appmysite.com/get-in-touch/"
                            className={styles.getintouch}
                            target="_blank"
                            rel="noreferrer"
                          >
                            Get in touch
                          </a>
                        </div>
                      </div> */}
                    </div>
                    <div className="d-flex justify-content-center align-items-center see-comparison-btn">
                      <a
                        href="#compare-list"
                        className="d-flex justify-content-between align-items-center"
                      >
                        <h4 className="card-foot-text">See full comparison</h4>
                        <img
                          src={
                            require("../../assets/images/dashboard/see-comparison-goto.png")
                              .default
                          }
                        />
                      </a>
                    </div>
                  </div>
                  <div className="customer-reviews row row-spec common-container  customer-reviews-mobile">
                    <div className="customer-review-text customer-review-text-mobile col-md-6 customer-review-pad">
                      <h3>Here’s what our customers are saying.</h3>
                      <p>
                        More than 200,000 customers love and trust AppMySite.
                        Partner with the best app builder software and reach
                        your business goals faster.
                      </p>
                      <div className="d-flex justify-content-start align-items-center customer-reviews-link">
                        <a
                          href="https://www.appmysite.com/reviews/"
                          className="d-flex justify-content-start align-items-center customer-reviews-link-main"
                        >
                          <h4 className="card-foot-text">See all reviews</h4>
                          <img
                            src={
                              require("../../assets/images/dashboard/customer-reviews-goto.png")
                                .default
                            }
                          />
                        </a>
                      </div>
                    </div>
                    <div className="customer-review-stars col-md-6">
                      <img
                        src={
                          require("../../assets/images/dashboard/pop-ratings.png")
                            .default
                        }
                        className="pop-ratings-img d-md-none d-block"
                      />
                      <div className="review-section-first d-none d-md-block">
                        <div className="trustpilot-review trustpilot-review-mobile">
                          <img
                            className="trustpilot-img"
                            src={
                              require("../../assets/images/dashboard/trustpilot-review.png")
                                .default
                            }
                          />
                        </div>
                        <div className="gplay-review gplay-review-mobile">
                          <img
                            className="gplay-img"
                            src={
                              require("../../assets/images/dashboard/gplay-review.png")
                                .default
                            }
                          />
                        </div>
                      </div>
                      <div className="review-section-second d-none d-md-block">
                        <div className="capterra-review capterra-review-mobile">
                          <img
                            className="capterra-img"
                            src={
                              require("../../assets/images/dashboard/capterra-review.png")
                                .default
                            }
                          />
                        </div>
                        <div className="wp-review wp-review-mobile">
                          <img
                            className="wp-img"
                            src={
                              require("../../assets/images/dashboard/wp-review.png")
                                .default
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="awards-container">
                    {/* <h5>We have been recognized by G2</h5> */}
                    <a
                      rel="nofollow"
                      target="_blank"
                      href="https://www.g2.com/products/appmysite/reviews"
                    >
                      {" "}
                      <img
                        src={
                          require("../../assets/images/dashboard/awards.png")
                            .default
                        }
                        // width="521px"
                        // height="145px"
                        className="d-none d-md-block"
                      />
                      <img
                        src={
                          require("../../assets/images/dashboard/awards-mobile.png")
                            .default
                        }
                        // width="521px"
                        // height="145px"
                        className="d-block d-md-none"
                      />
                    </a>
                  </div>
                  <div className="featured-platforms featured-platforms-mobile">
                    {/* <h5>AppMySite has been featured on</h5> */}
                    <div className="featured-platforms-icons featured-platforms-icons-mobile">
                      {/* <div className="pf-group pf-group-left"> */}

                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/wpengine-icon.png")
                              .default
                          }
                        />
                      </div>
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/wpexplorer-icon.png")
                              .default
                          }
                        />
                      </div>
                      {/* </div> */}
                      {/* <div className="pf-group pf-group-right"> */}
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/themeisle-icon.png")
                              .default
                          }
                        />
                      </div>
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/designrush-icon.png")
                              .default
                          }
                        />
                      </div>
                      {/* </div> */}
                      {/* <div className="pf-group pf-group-left"> */}
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/astra-icon.png")
                              .default
                          }
                        />
                      </div>
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/superbthemes-icon.png")
                              .default
                          }
                        />
                      </div>
                      {/* </div> */}
                      {/* <div className="pf-group pf-group-right"> */}
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/upcity-icon.png")
                              .default
                          }
                        />
                      </div>
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/cybernews-icon.png")
                              .default
                          }
                        />
                      </div>
                      {/* </div> */}
                      {/* <div className="last-pf-group-mobile"> */}
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/elegantthemes-icon.png")
                              .default
                          }
                        />
                      </div>
                      <div className="pf-new-main">
                        <img
                          src={
                            require("../../assets/images/dashboard/androidheadlines-icon.png")
                              .default
                          }
                        />
                      </div>
                      {/* </div> */}
                    </div>
                  </div>
                  {/* Compare plan */}
                  <div className="compare-plan-main">
                    <div
                      className={
                        styles.widthset + " widthset-new widthset-new-mobile"
                      }
                    >
                      <div id="compare-list">
                        <div
                          className={styles.compareplan + " compare-plan-head"}
                        >
                          <h5>Compare Plans</h5>
                          <p className="compare-plan-subtext-red">
                            Compare our offering, plans and pricing. Pick one
                            that suits you the best.
                          </p>
                        </div>
                      </div>
                      {/* Mobile view */}
                      <div className="d-block d-md-none compare-plan-mobile-view">
                        <div className="plan-container">
                          <div className="tabbed">
                            <ul className={styles.mobilelist + " tabs"}>
                              <li
                                onClick={(e) =>
                                  this.handlemobilefilter("Preview")
                                }
                                className={
                                  this.state.mobilefilter === "Preview"
                                    ? styles.mobileactive + " preview-tab tab"
                                    : undefined
                                }
                              >
                                Preview
                              </li>
                              {this.state.filter === "monthly" &&
                                this.state.monthlydata &&
                                this.state.monthlydata.map((res, i) => (
                                  <li
                                    key={i}
                                    onClick={(e) =>
                                      this.handlemobilefilter(res.plan_name)
                                    }
                                    className={
                                      this.state.mobilefilter === res.plan_name
                                        ? styles.mobileactive +
                                          " starter-tab tab"
                                        : undefined
                                    }
                                  >
                                    {res.plan}
                                  </li>
                                ))}
                              {this.state.filter === "yearly" &&
                                this.state.yearlydata &&
                                this.state.yearlydata.map((res, i) => (
                                  <li
                                    key={i}
                                    onClick={(e) =>
                                      this.handlemobilefilter(res.plan_name)
                                    }
                                    className={
                                      this.state.mobilefilter === res.plan_name
                                        ? styles.mobileactive + " pro-tab tab"
                                        : undefined
                                    }
                                  >
                                    {res.plan}
                                  </li>
                                ))}
                              {this.state.filter === "lifetime" &&
                                this.state.lifetimedata &&
                                this.state.lifetimedata.map((res, i) => (
                                  <li
                                    key={i}
                                    onClick={(e) =>
                                      this.handlemobilefilter(res.plan_name)
                                    }
                                    className={
                                      this.state.mobilefilter === res.plan_name
                                        ? styles.mobileactive +
                                          " premium-tab tab"
                                        : undefined
                                    }
                                  >
                                    {res.plan}
                                  </li>
                                ))}
                            </ul>
                            {this.state.tab_check === "Preview" && (
                              <div className="pricing-tab-content">
                                <div className="tab-plan-detail">
                                  <div className="pricesechead">
                                    <h6>Platforms</h6>
                                  </div>

                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Android</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        width="12px"
                                        height="12px"
                                        className="img-fluid"
                                        alt="cross"
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>iOS</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        width="12px"
                                        height="12px"
                                        className="img-fluid"
                                        alt="cross"
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="pricesechead">
                                    <h6>App Stores</h6>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Google Play Store</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        width="12px"
                                        height="12px"
                                        className="img-fluid"
                                        alt="cross"
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Apple App Store</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        width="12px"
                                        height="12px"
                                        className="img-fluid"
                                        alt="cross"
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="pricesechead">
                                    <h6>Integrations</h6>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Web View</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        width="16px"
                                        height="16px"
                                        className="img-fluid"
                                        alt="tick"
                                        src={
                                          require("../../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>WordPress</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        width="16px"
                                        height="16px"
                                        className="img-fluid"
                                        alt="tick"
                                        src={
                                          require("../../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>WooCommerce</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        width="16px"
                                        height="16px"
                                        className="img-fluid"
                                        alt="tick"
                                        src={
                                          require("../../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Google Firebase</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Google Admob</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="pricesechead">
                                    <h6>Personalization</h6>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>App Icon Designer</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                        width="16px"
                                        height="16px"
                                        className="lazy img-fluid"
                                        alt="tick"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Launch Screen Designer</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                        width="16px"
                                        height="16px"
                                        className="lazy img-fluid"
                                        alt="tick"
                                      />
                                    </div>
                                  </div>
                                  {localStorage.getItem(
                                    "website_technology"
                                  ) === "1" && (
                                    <>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Login Screen Designer</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Home Screen Builder</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="tick"
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Theme Customization</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                        width="16px"
                                        height="16px"
                                        className="lazy img-fluid"
                                        alt="tick"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>White Label App</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="pricesechead">
                                    <h6>User Access</h6>
                                  </div>
                                  {localStorage.getItem(
                                    "website_technology"
                                  ) === "1" && (
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Manage User Login</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Guest Browsing</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                        width="16px"
                                        height="16px"
                                        className="lazy img-fluid"
                                        alt="tick"
                                      />
                                    </div>
                                  </div>
                                  {localStorage.getItem(
                                    "website_technology"
                                  ) === "1" && (
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>WordPress User Sync</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Google Login &amp; Signup</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Apple Login &amp; Signup</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>User Consent Screen</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="pricesechead">
                                    <h6>Notifications</h6>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Unlimited Notifications</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Schedule Notifications</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Device Segmentation</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="pricesechead">
                                    <h6>Team</h6>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Roles &amp; Permissions</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Team Members</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="pricesechead">
                                    <h6>Additional Features</h6>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Simulator</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                        width="16px"
                                        height="16px"
                                        className="lazy img-fluid"
                                        alt="tick"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>App Advertisement</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Merge apps</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  {localStorage.getItem(
                                    "website_technology"
                                  ) !== "2" && (
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Language Selection</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                  )}
                                  {localStorage.getItem(
                                    "website_technology"
                                  ) !== "2" && (
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Right-to-left Support</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Customer Chat</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  {localStorage.getItem(
                                    "website_technology"
                                  ) === "1" && (
                                    <>
                                      <div className="pricesechead">
                                        <h6>Analytics</h6>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>App Store Downloads</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Traffic Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Device Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Post Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Page Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Product Analytics</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                      <div className="pricesechead">
                                        <h6>Auto Publish</h6>
                                      </div>
                                    </>
                                  )}

                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Publish Updates to Apple App Store</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>
                                  <div className="pricesechead">
                                    <h6>Support</h6>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Community</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                        width="16px"
                                        height="16px"
                                        className="lazy img-fluid"
                                        alt="tick"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Knowledge Base</p>
                                    </div>
                                    <div className="priceminor pricetick">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/roundbluetick.png")
                                            .default
                                        }
                                        width="16px"
                                        height="16px"
                                        className="lazy img-fluid"
                                        alt="tick"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Limited Support</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="tick"
                                      />
                                    </div>
                                  </div>
                                  <div className="allflex">
                                    <div className="pricemajor">
                                      <p>Priority Support</p>
                                    </div>
                                    <div className="priceminor pricecross">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      />
                                    </div>
                                  </div>

                                  <div className="pricingflex">
                                    <div className="pricemajor"></div>
                                    <div className="priceminor">
                                      <a
                                        target="_blank"
                                        href="/sign-up/"
                                      >
                                        Get started
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {document.querySelector(".tabs li:nth-child(2)") &&
                              this.state.tab_check.includes("Starter") && (
                                <div className="pricing-tab-content">
                                  <div className="tab-plan-detail">
                                    <div className="pricesechead">
                                      <h6>Platforms</h6>
                                    </div>

                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Android</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>iOS</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>App Stores</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Google Play Store</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Apple App Store</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Integrations</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Web View</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>WordPress</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>WooCommerce</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Google Firebase</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Google Admob</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Personalization</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>App Icon Designer</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Launch Screen Designer</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Login Screen Designer</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Home Screen Builder</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Theme Customization</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>White Label App</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>User Access</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Manage User Login</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Guest Browsing</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>WordPress User Sync</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Google Login &amp; Signup</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Apple Login &amp; Signup</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>User Consent Screen</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Push Notifications</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Unlimited Notifications</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Schedule Notifications</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Device Segmentation</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Team</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Roles &amp; Permissions</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Team Members</p>
                                      </div>
                                      <div className="priceminor team-census">
                                        {/* <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      /> */}
                                        2 users
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Additional Features</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Simulator</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>App Advertisement</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Merge apps</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    {localStorage.getItem(
                                      "website_technology"
                                    ) !== "2" && (
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Language Selection</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {localStorage.getItem(
                                      "website_technology"
                                    ) !== "2" && (
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Right-to-left Support</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Customer Chat</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Analytics</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>App Store Downloads</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Traffic Analytics</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Device Analytics</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Post Analytics</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Page Analytics</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Product Analytics</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Auto Publish</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>
                                          Publish Updates to Apple App Store
                                        </p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>

                                    <div className="pricesechead">
                                      <h6>Support</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Community</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Knowledge Base</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Limited Support</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Priority Support</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>

                                    <div className="pricingflex">
                                      <div className="pricemajor"></div>
                                      <div className="priceminor">
                                        <a
                                          target="_blank"
                                          href="/sign-up/"
                                        >
                                          Get started
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {document.querySelector(".tabs li:nth-child(3)") &&
                              this.state.tab_check.includes("Pro") && (
                                <div className="pricing-tab-content">
                                  <div className="tab-plan-detail">
                                    <div className="pricesechead">
                                      <h6>Platforms</h6>
                                    </div>

                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Android</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>iOS</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>App Stores</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Google Play Store</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Apple App Store</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Integrations</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Web View</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>WordPress</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>WooCommerce</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Google Firebase</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Google Admob</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Personalization</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>App Icon Designer</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Launch Screen Designer</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Login Screen Designer</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Home Screen Builder</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Theme Customization</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>White Label App</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>User Access</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Manage User Login</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Guest Browsing</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>WordPress User Sync</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Google Login &amp; Signup</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Apple Login &amp; Signup</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>User Consent Screen</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Notifications</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Unlimited Notifications</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Schedule Notifications</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Device Segmentation</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Team</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Roles &amp; Permissions</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Team Members</p>
                                      </div>
                                      <div className="priceminor team-census">
                                        {/* <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      /> */}
                                        3 users
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Additional Features</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Simulator</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>App Advertisement</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Merge apps</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    {localStorage.getItem(
                                      "website_technology"
                                    ) !== "2" && (
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Language Selection</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {localStorage.getItem(
                                      "website_technology"
                                    ) !== "2" && (
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Right-to-left Support</p>
                                        </div>
                                        <div className="priceminor pricecross">
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/plancross.png")
                                                .default
                                            }
                                            width="12px"
                                            height="12px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Customer Chat</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Analytics</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>App Store Downloads</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Traffic Analytics</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Device Analytics</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Post Analytics</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Page Analytics</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Product Analytics</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Auto Publish</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Upload app to Apple Store</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>
                                          Publish Updates to Apple App Store
                                        </p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>

                                    <div className="pricesechead">
                                      <h6>Support</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Community</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Knowledge Base</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Limited Support</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Priority Support</p>
                                      </div>
                                      <div className="priceminor pricecross">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/plancross.png")
                                              .default
                                          }
                                          width="12px"
                                          height="12px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>

                                    <div className="pricingflex">
                                      <div className="pricemajor"></div>
                                      <div className="priceminor">
                                        <a
                                          target="_blank"
                                          href="/sign-up/"
                                        >
                                          Get started
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            {document.querySelector(".tabs li:nth-child(4)") &&
                              this.state.tab_check.includes("Premium") && (
                                <div className="pricing-tab-content">
                                  <div className="tab-plan-detail">
                                    <div className="pricesechead">
                                      <h6>Platforms</h6>
                                    </div>

                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Android</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>iOS</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>App Stores</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Google Play Store</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Apple App Store</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Integrations</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Web View</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>WordPress</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>WooCommerce</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Google Firebase</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Google Admob</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Personalization</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>App Icon Designer</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Launch Screen Designer</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Login Screen Designer</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Home Screen Builder</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Theme Customization</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>White Label App</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>User Access</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Manage User Login</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Guest Browsing</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>WordPress User Sync</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Google Login &amp; Signup</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Apple Login &amp; Signup</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>User Consent Screen</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Notifications</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Unlimited Notifications</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Schedule Notifications</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Device Segmentation</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Team</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Roles &amp; Permissions</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Team Members</p>
                                      </div>
                                      <div className="priceminor team-census">
                                        {/* <img
                                        src={
                                          require("../../assets/images/dashboard/plancross.png")
                                            .default
                                        }
                                        width="12px"
                                        height="12px"
                                        className="lazy img-fluid"
                                        alt="cross"
                                      /> */}
                                        5 users
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Additional Features</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Simulator</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>App Advertisement</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Merge apps</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    {localStorage.getItem(
                                      "website_technology"
                                    ) !== "2" && (
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Language Selection</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    {localStorage.getItem(
                                      "website_technology"
                                    ) !== "2" && (
                                      <div className="allflex">
                                        <div className="pricemajor">
                                          <p>Right-to-left Support</p>
                                        </div>
                                        <div className="priceminor pricetick">
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/roundbluetick.png")
                                                .default
                                            }
                                            width="16px"
                                            height="16px"
                                            className="lazy img-fluid"
                                            alt="cross"
                                          />
                                        </div>
                                      </div>
                                    )}
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Customer Chat</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Analytics</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>App Store Downloads</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Traffic Analytics</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Device Analytics</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Post Analytics</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Page Analytics</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Product Analytics</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>
                                    <div className="pricesechead">
                                      <h6>Auto Publish</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>
                                          Publish Updates to Apple App Store
                                        </p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>

                                    <div className="pricesechead">
                                      <h6>Support</h6>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Community</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Knowledge Base</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Limited Support</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="tick"
                                        />
                                      </div>
                                    </div>
                                    <div className="allflex">
                                      <div className="pricemajor">
                                        <p>Priority Support</p>
                                      </div>
                                      <div className="priceminor pricetick">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/roundbluetick.png")
                                              .default
                                          }
                                          width="16px"
                                          height="16px"
                                          className="lazy img-fluid"
                                          alt="cross"
                                        />
                                      </div>
                                    </div>

                                    <div className="pricingflex">
                                      <div className="pricemajor"></div>
                                      <div className="priceminor">
                                        <a
                                          target="_blank"
                                          href="/sign-up/"
                                        >
                                          Get started
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      {/* Mobile view end */}
                      <table className="table compare-plan-container compare-plan-container-mobile">
                        <thead className="head-sub-mobile">
                          <tr>
                            <th scope="col" className="top-plan-row"></th>
                            <th
                              scope="col"
                              className="top-plan-row row-spec-red"
                            >
                              Preview
                            </th>
                            <th
                              scope="col"
                              className="top-plan-row row-spec-red"
                            >
                              Starter
                            </th>
                            <th
                              scope="col"
                              className="top-plan-row row-spec-red"
                            >
                              Pro
                            </th>
                            <th
                              scope="col"
                              className="top-plan-row row-spec-red"
                            >
                              Premium
                            </th>
                          </tr>
                        </thead>
                        <tbody className="comparison-table">
                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              Platforms
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>

                          <tr>
                            <th scope="row">Android</th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">iOS</th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              App Stores
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="row-info-borderless">
                            <th scope="row">Google Play Store</th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Apple App Store</th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              Integrations
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>

                          <tr className="row-info-borderless">
                            <th scope="row">Web View </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>

                          {parseInt(
                            localStorage.getItem("website_technology")
                          ) === 1 && (
                            <tr>
                              <th scope="row">WordPress </th>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                            </tr>
                          )}
                          {parseInt(
                            localStorage.getItem("website_technology")
                          ) === 1 && (
                            <tr>
                              <th scope="row">WooCommerce </th>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th scope="row">Google Firebase </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Google Admob </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          {localStorage.getItem("website_technology") ===
                            "1" && (
                            <>
                              <tr className="row-info-main row-info-borderless">
                                <th scope="row" className="row-spec-red">
                                  WordPress
                                </th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr className="row-info-borderless">
                                <th scope="row">Page Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Post Support </th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">User Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Comment Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Custom Post Type Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Custom Taxonomy Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Multisite Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr className="row-info-main row-info-borderless">
                                <th scope="row" className="row-spec-red">
                                  WooCommerce
                                </th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr className="row-info-borderless">
                                <th scope="row">Product Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Currency Support </th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Inventory Support </th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Coupon Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Tax Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Shipping Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Payment Gateway Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Order Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Rating Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>
                              <tr>
                                <th scope="row">Review Support</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>
                            </>
                          )}
                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              Personalization
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="row-info-borderless">
                            <th scope="row">App Icon Designer </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Launch Screen Designer </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          {parseInt(
                            localStorage.getItem("website_technology")
                          ) === 1 && (
                            <tr>
                              <th scope="row">Login Screen Designer </th>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                            </tr>
                          )}
                          {parseInt(
                            localStorage.getItem("website_technology")
                          ) === 1 && (
                            <tr>
                              <th scope="row">Home Screen Builder </th>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th scope="row">Theme Customization </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">White Label App </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              User Access
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          {parseInt(
                            localStorage.getItem("website_technology")
                          ) === 1 && (
                            <tr className="row-info-borderless">
                              <th scope="row">Manage User Login </th>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th scope="row">Guest Browsing </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          {parseInt(
                            localStorage.getItem("website_technology")
                          ) === 1 && (
                            <tr>
                              <th scope="row">WordPress User Sync </th>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                            </tr>
                          )}

                          <tr>
                            <th scope="row">Google Login & Signup </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Apple Login & Signup </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">User Consent Screen </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              Push Notifications
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="row-info-borderless">
                            <th scope="row">Unlimited Notifications </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Schedule Notifications </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Device Segmentation </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              Team{" "}
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="row-info-borderless">
                            <th scope="row">Roles & Permissions </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Team Members </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>2 users</td>
                            <td>3 users</td>
                            <td>5 users</td>
                          </tr>
                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              Additional Features{" "}
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>

                          <tr className="row-info-borderless">
                            <th scope="row">Simulator</th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">App Advertisement </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Merge apps </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          {localStorage.getItem("website_technology") !==
                            "2" && (
                            <tr>
                              <th scope="row">Language Selection</th>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/plancross.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/plancross.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/plancross.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                            </tr>
                          )}
                          {localStorage.getItem("website_technology") !==
                            "2" && (
                            <tr>
                              <th scope="row">Right-to-left Support </th>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/plancross.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/plancross.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/plancross.png")
                                      .default
                                  }
                                />
                              </td>
                              <td>
                                <img
                                  src={
                                    require("../../assets/images/dashboard/roundbluetick.png")
                                      .default
                                  }
                                />
                              </td>
                            </tr>
                          )}
                          <tr>
                            <th scope="row">Customer Chat</th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>

                          {localStorage.getItem("website_technology") ===
                            "1" && (
                            <>
                              <tr className="row-info-main row-info-borderless">
                                <th scope="row" className="row-spec-red">
                                  Analytics{" "}
                                </th>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>

                              <tr className="row-info-borderless">
                                <th scope="row">App Store Downloads</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Traffic Analytics</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Device Analytics</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Post Analytics</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Page Analytics</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>

                              <tr>
                                <th scope="row">Product Analytics</th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>
                            </>
                          )}

                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              Auto Publish{" "}
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="row-info-borderless">
                            <th scope="row">
                              Publish updates to Apple App Store{" "}
                            </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/plancross.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>

                          <tr className="row-info-main row-info-borderless">
                            <th scope="row" className="row-spec-red">
                              Support{" "}
                            </th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                          <tr className="row-info-borderless">
                            <th scope="row">Community </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Knowledge Base </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Limited Support </th>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                            <td>
                              <img
                                src={
                                  require("../../assets/images/dashboard/roundbluetick.png")
                                    .default
                                }
                              />
                            </td>
                          </tr>

                          {localStorage.getItem("website_technology") ===
                            "1" && (
                            <>
                              <tr>
                                <th scope="row">Priority Support </th>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plancross.png")
                                        .default
                                    }
                                  />
                                </td>
                                <td>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/roundbluetick.png")
                                        .default
                                    }
                                  />
                                </td>
                              </tr>
                            </>
                          )}

                          <tr className="last-plan-row">
                            <th scope="row"></th>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                      {/* <div className={styles.tableview}>
                        {this.state.one_time
                          .filter((res) =>
                            window.matchMedia("(max-width: 700px)").matches
                              ? res.plan_name === this.state.mobilefilter
                              : res
                          )
                          .map((res, i) => (
                            <div className={styles.tableiteam} key={i}>
                              <div
                                className={
                                  styles.tableheading + " d-none d-md-block"
                                }
                              >
                                <h6>{res.plan_name}</h6>
                              </div>
                              {Object.keys(res.description.features).map(
                                (list, k) => (
                                  <div className={styles.tablelist} key={k}>
                                    <div>
                                      <h5>{list}</h5>
                                      <ul className={styles.listing}>
                                        {Object.keys(
                                          res.description.features[list]
                                        ).map((response, j) => (
                                          <li
                                            key={j}
                                            style={{
                                              color:
                                                res.description.features[list][
                                                  response
                                                ] === 1
                                                  ? "#000000"
                                                  : "#B0B8C9",
                                            }}
                                          >
                                            {res.description.features[list][
                                              response
                                            ] === 1 ? (
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/active_list.png")
                                                    .default
                                                }
                                                alt="de"
                                                className="mr-1"
                                              />
                                            ) : (
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/notlist.png")
                                                    .default
                                                }
                                                alt="de"
                                                className="mr-1 pr-1"
                                              />
                                            )}

                                            {response}
                                          </li>
                                        ))}
                                      </ul>
                                    </div>
                                  </div>
                                )
                              )}
                              <div className={styles.pricelst}>
                                <div className={styles.listpricing}>
                                  <div className="d-flex align-items-center">
                                    <h4>
                                      {res.package_currency.currency_symbol}
                                      {res.amount.replace(".00", " ")}{" "}
                                    </h4>
                                    {this.state.filter === "monthly" ? (
                                      <span>per month</span>
                                    ) : this.state.filter === "yearly" ? (
                                      <span>per month paid yearly</span>
                                    ) : (
                                      <span>one time payment</span>
                                    )}
                                  </div>
                                  {res.is_subscribed === 1 ? (
                                    <Link to="#" className={styles.getstarted}>
                                      Current Plan
                                    </Link>
                                  ) : ( */}
                      {/* // res.plan_name.indexOf("Premium") > -1
                                    // ?
                                    // <Link to="#" className={styles.getstarted}>Coming Soon</Link>
                                    // : */}
                      {/* <Link
                                      to="#"
                                      onClick={(e) => this.removepopup()}
                                      className={styles.getstartedactive}
                                    >
                                      Get Started
                                    </Link>
                                  )} */}
                      {/* </div>
                              </div>
                            </div>
                          ))} */}
                      {/* Monthly */}
                      {/* {this.state.filter === "monthly" &&
                          this.state.monthlydata
                            .filter((res) =>
                              window.matchMedia("(max-width: 700px)").matches
                                ? res.plan_name === this.state.mobilefilter
                                : res
                            )
                            .map((res, i) => (
                              <div className={styles.tableiteam} key={i}>
                                <div
                                  className={
                                    styles.tableheading + " d-none d-md-block"
                                  }
                                >
                                  <h6>{res.plan_name}</h6>
                                </div>
                                {Object.keys(res.description.features).map(
                                  (list, k) => (
                                    <div className={styles.tablelist} key={k}>
                                      <div>
                                        <h5>{list}</h5>
                                        <ul className={styles.listing}>
                                          {Object.keys(
                                            res.description.features[list]
                                          ).map((response, j) => (
                                            <li
                                              key={j}
                                              style={{
                                                color:
                                                  res.description.features[
                                                    list
                                                  ][response] === 1
                                                    ? "#000000"
                                                    : "#B0B8C9",
                                              }}
                                            >
                                              {res.description.features[list][
                                                response
                                              ] === 1 ? (
                                                <img
                                                  src={
                                                    require("../../assets/images/dashboard/active_list.png")
                                                      .default
                                                  }
                                                  alt="de"
                                                  className="mr-1"
                                                />
                                              ) : (
                                                <img
                                                  src={
                                                    require("../../assets/images/dashboard/notlist.png")
                                                      .default
                                                  }
                                                  alt="de"
                                                  className="mr-1 pr-1"
                                                />
                                              )}

                                              {response}
                                            </li>
                                          ))}
                                        </ul>
                                      </div>
                                    </div>
                                  )
                                )}
                                <div className={styles.pricelst}>
                                  <div className={styles.listpricing}>
                                    <div className="d-flex  align-items-center">
                                      <h4>
                                        {res.package_currency.currency_symbol}
                                        {res.amount.replace(".00", " ")}{" "}
                                      </h4>
                                      <span>per month</span>
                                    </div>

                                    {res.is_subscribed === 1 ? (
                                      <Link
                                        to="#"
                                        className={styles.getstarted}
                                      >
                                        Current Plan
                                      </Link>
                                    ) : ( */}
                      {/* // res.plan_name.indexOf("Premium") > -1
                                      // ?
                                      // <Link to="#" className={styles.getstarted}>Coming Soon</Link>
                                      // : */}
                      {/* <Link
                                        to={dlink(
                                          res.plan_code,
                                          parseInt(res.amount),
                                          res.plan_name,
                                          res.package_currency.currency_symbol,
                                          res.plan_code
                                        )}
                                        className={styles.getstartedactive}
                                      >
                                        Get Started
                                      </Link>
                                    )} */}
                      {/* </div>
                                </div>
                              </div>
                            ))} */}
                      {/* Yearly */}
                      {/* {this.state.filter === "yearly" &&
                          this.state.yearlydata
                            .filter((res) =>
                              window.matchMedia("(max-width: 700px)").matches
                                ? res.plan_name === this.state.mobilefilter
                                : res
                            )
                            .map((res, i) => (
                              <>
                                <div className={styles.tableiteam} key={i}>
                                  <div
                                    className={
                                      styles.tableheading + " d-none d-md-block"
                                    }
                                  >
                                    <h6>{res.plan_name}</h6>
                                  </div>
                                  {Object.keys(res.description.features).map(
                                    (list, k) => (
                                      <div className={styles.tablelist} key={k}>
                                        <div>
                                          <h5>{list}</h5>
                                          <ul className={styles.listing}>
                                            {Object.keys(
                                              res.description.features[list]
                                            ).map((response, j) => (
                                              <li
                                                key={j}
                                                style={{
                                                  color:
                                                    res.description.features[
                                                      list
                                                    ][response] === 1
                                                      ? "#000000"
                                                      : "#B0B8C9",
                                                }}
                                              >
                                                {res.description.features[list][
                                                  response
                                                ] === 1 ? (
                                                  <img
                                                    src={
                                                      require("../../assets/images/dashboard/active_list.png")
                                                        .default
                                                    }
                                                    alt="de"
                                                    className="mr-1"
                                                  />
                                                ) : (
                                                  <img
                                                    src={
                                                      require("../../assets/images/dashboard/notlist.png")
                                                        .default
                                                    }
                                                    alt="de"
                                                    className="mr-1 pr-1"
                                                  />
                                                )}

                                                {response}
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  )}
                                  <div className={styles.pricelst}>
                                    <div className={styles.listpricing}>
                                      <div className="d-flex align-items-center">
                                        <h4>
                                          {res.package_currency.currency_symbol}
                                          {res.amount.replace(".00", " ")}{" "}
                                        </h4>
                                        <span>per month paid yearly</span>
                                      </div>
                                      {res.is_subscribed === 1 ? (
                                        <Link
                                          to="#"
                                          className={styles.getstarted}
                                        >
                                          Current Plan
                                        </Link>
                                      ) : ( */}
                      {/* // res.plan_name.indexOf("Premium") > -1
                                        // ?
                                        // <Link to="#" className={styles.getstarted}>Coming Soon</Link>
                                        // : */}
                      {/* <Link
                                          to={dlink(
                                            res.plan_code,
                                            parseInt(res.amount),
                                            res.plan_name,
                                            res.package_currency
                                              .currency_symbol,
                                            res.plan_code
                                          )}
                                          className={styles.getstartedactive}
                                        >
                                          Get Started
                                        </Link>
                                      )} */}
                      {/* </div>
                                  </div>
                                </div>
                              </>
                            ))} */}
                      {/* Life Time*/}
                      {/* {this.state.filter === "lifetime" &&
                          this.state.lifetimedata
                            .filter((res) =>
                              window.matchMedia("(max-width: 700px)").matches
                                ? res.plan_name === this.state.mobilefilter
                                : res
                            )
                            .map((res, i) => (
                              <>
                                <div className={styles.tableiteam} key={i}>
                                  <div
                                    className={
                                      styles.tableheading + " d-none d-md-block"
                                    }
                                  >
                                    <h6>{res.plan_name}</h6>
                                  </div>
                                  {Object.keys(res.description.features).map(
                                    (list, k) => (
                                      <div className={styles.tablelist} key={k}>
                                        <div>
                                          <h5>{list}</h5>
                                          <ul className={styles.listing}>
                                            {Object.keys(
                                              res.description.features[list]
                                            ).map((response, j) => (
                                              <li
                                                key={j}
                                                style={{
                                                  color:
                                                    res.description.features[
                                                      list
                                                    ][response] === 1
                                                      ? "#000000"
                                                      : "#B0B8C9",
                                                }}
                                              >
                                                {res.description.features[list][
                                                  response
                                                ] === 1 ? (
                                                  <img
                                                    src={
                                                      require("../../assets/images/dashboard/active_list.png")
                                                        .default
                                                    }
                                                    alt="de"
                                                    className="mr-1"
                                                  />
                                                ) : (
                                                  <img
                                                    src={
                                                      require("../../assets/images/dashboard/notlist.png")
                                                        .default
                                                    }
                                                    alt="de"
                                                    className="mr-1 pr-1"
                                                  />
                                                )}

                                                {response}
                                              </li>
                                            ))}
                                          </ul>
                                        </div>
                                      </div>
                                    )
                                  )}
                                  <div className={styles.pricelst}>
                                    <div className={styles.listpricing}>
                                      <div className="d-flex  align-items-center">
                                        <h4>
                                          {res.package_currency.currency_symbol}
                                          {res.amount.replace(".00", " ")}
                                        </h4>
                                        <span>one time payment</span>
                                      </div>

                                      {res.is_subscribed === 1 ? (
                                        <Link
                                          to="#"
                                          className={styles.getstarted}
                                        >
                                          Current Plan
                                        </Link>
                                      ) : ( */}
                      {/* // res.plan_name.indexOf("Premium") > -1
                                        // ?
                                        // <Link to="#" className={styles.getstarted}>Coming Soon</Link>
                                        // : */}
                      {/* <Link
                                          to={dlink(
                                            res.plan_code,
                                            parseInt(res.amount),
                                            res.plan_name,
                                            res.package_currency
                                              .currency_symbol,
                                            res.plan_code
                                          )}
                                          className={styles.getstartedactive}
                                        >
                                          Get Started
                                        </Link>
                                      )} */}
                      {/* </div>
                                  </div>
                                </div>
                              </>
                            ))} */}
                      {/* </div> */}
                      {/* <div className={styles.note}>
                   <p>*This feature is work in progress and is launching soon to single star only</p>
        </div> */}
                    </div>
                  </div>
                  {/* Compare plan end*/}

                  {/* Testimonial */}
                  {/* <div className={styles.setbackground + " mt-5"}>
                    <div className={styles.widthset}>
                      <div className={styles.testimonial}>
                        <div className={styles.firstleft}>
                          <h6>100,000+ customers love AppMySite</h6>

                          <p>Reviews on popular platforms:</p>
                          <div>
                            <div className="d-md-flex d-block mb-md-5 mt-4">
                              <div
                                className={
                                  "d-flex w-md-50 w-100 " + styles.mobilerating
                                }
                              >
                                <div className="d-flex align-items-center mr-3 ">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/testimonial/teststar.png")
                                        .default
                                    }
                                    alt="de"
                                    className="mr-1"
                                  />
                                  <p className="m-0 p-0">4.7</p>
                                </div>
                                <div>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/testimonial/trust.png")
                                        .default
                                    }
                                    alt="de"
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <div
                                className={
                                  "d-flex w-md-50 w-100  " +
                                  styles.mobileratingget
                                }
                              >
                                <div className="d-flex align-items-center mr-3">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/testimonial/teststar.png")
                                        .default
                                    }
                                    alt="de"
                                    className="mr-1"
                                  />
                                  <p className="m-0 p-0">4.7</p>
                                </div>
                                <div>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/testimonial/googlepay.png")
                                        .default
                                    }
                                    alt="de"
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="d-md-flex d-block ">
                              <div
                                className={
                                  "d-flex w-md-50 w-100 " + styles.mobilerating
                                }
                              >
                                <div className="d-flex align-items-center mr-3">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/testimonial/teststar.png")
                                        .default
                                    }
                                    alt="de"
                                    className="mr-1"
                                  />
                                  <p className="m-0 p-0">4.7</p>
                                </div>
                                <div>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/testimonial/capterra.png")
                                        .default
                                    }
                                    alt="de"
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                              <div
                                className={
                                  "d-flex w-md-50 w-100  " +
                                  styles.mobileratingget
                                }
                              >
                                <div className="d-flex align-items-center mr-3">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/testimonial/teststar.png")
                                        .default
                                    }
                                    alt="de"
                                    className="mr-1"
                                  />
                                  <p className="m-0 p-0">4.7</p>
                                </div>
                                <div>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/testimonial/wordpress.png")
                                        .default
                                    }
                                    alt="de"
                                    className="img-fluid"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.secondhalf}>
                          <div
                            className={
                              "d-flex justify-content-between " + styles.testmob
                            }
                          >
                            <div className={styles.first}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/testimonial/denil.png")
                                        .default
                                    }
                                    alt="de"
                                    width="80"
                                    height="80"
                                  />
                                  <div className="ml-3">
                                    <h6 className="m-0 p-0">Dave Steel</h6>
                                    <p className="m-0 p-0">Mar 15, 2019</p>
                                  </div>
                                </div>
                                <div>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/testimonial/quote.png")
                                        .default
                                    }
                                    alt="de"
                                    width="45"
                                    height="38"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 ">
                                <img
                                  src={
                                    require("../../assets/images/dashboard/testimonial/fivestar.png")
                                      .default
                                  }
                                  alt="de"
                                  className="mb-4"
                                />
                                <p>
                                  I was wondering how to turn my website into an
                                  app and found a solution with AppMySite. Not
                                  only was I able to convert my e-commerce
                                  website into a full-blown app within minutes
                                  but also publish them instantly on App Store
                                  and Play Store. Looking forward to creating
                                  more apps using this amazing tool!
                                </p>
                              </div>
                            </div>
                            <div className={styles.first}>
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex align-items-center">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/testimonial/hal.png")
                                        .default
                                    }
                                    alt="de"
                                    width="80"
                                    height="80"
                                  />
                                  <div className="ml-3">
                                    <h6 className="m-0 p-0">Hallie Parez</h6>
                                    <p className="m-0 p-0">Jan 21, 2019</p>
                                  </div>
                                </div>
                                <div>
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/testimonial/quote.png")
                                        .default
                                    }
                                    alt="de"
                                    width="45"
                                    height="38"
                                  />
                                </div>
                              </div>
                              <div className="mt-4 ">
                                <img
                                  src={
                                    require("../../assets/images/dashboard/testimonial/fivestar.png")
                                      .default
                                  }
                                  alt="de"
                                  className="mb-4"
                                />
                                <p>
                                  I used AppMySite app builder create an app all
                                  by myself. I have very limited technical
                                  knowledge but their platform is extremely
                                  user-friendly. I built and published an
                                  awesome Android and iOS app without any coding
                                  for my clothing website. Definitely recommend
                                  it!
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* Testimonial end */}
                  <div className="faq-container faq-container-mobile">
                    {/* <div className={styles.widthset}> */}
                    <div className="faq mb-5 faq-bottompadding faq-bottom-mobile">
                      <h2>Frequently Asked Questions</h2>

                      <div className="accordion">
                        {accordionData.slice(0, 6).map(({ title, content }) => (
                          <Accordion title={title} content={content} />
                        ))}
                      </div>

                      <div className="see-more-faq see-more-faq-mobile">
                        <div className="accordion-item-new moretext">
                          {accordionData
                            .slice(6, 13)
                            .map(({ title, content }) => (
                              <Accordion title={title} content={content} />
                            ))}
                        </div>
                        <p
                          className="see-more-btn"
                          onClick={(e) => this.extend(e)}
                        >
                          See More
                        </p>
                      </div>
                      <div class="haveidea haveidea-redesign allflex">
                        <h4 class="sectionheadings">Have other questions?</h4>
                        <a
                          target="_blank"
                          href="/get-in-touch/"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <h5>Contact Us </h5>
                          <svg
                            width="12.894"
                            height="11.061"
                            viewBox="0 0 12.894 11.061"
                            className="contact-us-svg"
                          >
                            <g
                              id="Group_5530"
                              data-name="Group 5530"
                              transform="translate(12 0.53) rotate(90)"
                            >
                              <path
                                id="Path_9581"
                                data-name="Path 9581"
                                d="M0,5,5,0l5,5"
                                transform="translate(0 0.167)"
                                fill="none"
                                stroke="#3064f9"
                                stroke-width="1.5"
                              ></path>
                              <path
                                id="Path_9586"
                                data-name="Path 9586"
                                d="M0,0V12"
                                transform="translate(5)"
                                fill="none"
                                stroke="#3064f9"
                                stroke-width="1.5"
                              ></path>
                            </g>
                          </svg>
                        </a>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </div>

              {/* Remove Addon Popup */}
              <Modal
                isOpen={this.state.deluser}
                centered
                toggle={(e) => this.removepopup(e)}
                style={{ maxWidth: "506px", maxHeight: "247px" }}
                className={this.props.className}
                dataclassName="addonpopup"
              >
                <ModalHeader
                  toggle={(e) => this.removepopup(e)}
                  className="modal-xxl-content confirmpopup"
                >
                  <p>Confirm</p>
                  <div
                    className="crossmodal mt-1"
                    onClick={(e) => this.removepopup(e)}
                  ></div>
                </ModalHeader>
                <ModalBody className="">
                  <div>
                    <div className="d-flex mt-2 mb-3">
                      <div className="alertconfirm">
                        <svg width="52" height="44" viewBox="0 0 52 44">
                          <g
                            id="Group_3260"
                            data-name="Group 3260"
                            transform="translate(-748 -456)"
                          >
                            <path
                              id="Polygon_11"
                              data-name="Polygon 11"
                              d="M23.417,4.371a3,3,0,0,1,5.166,0l20.743,35.1A3,3,0,0,1,46.743,44H5.257a3,3,0,0,1-2.583-4.526Z"
                              transform="translate(748 456)"
                              fill="#c8cedb"
                            />
                            <path
                              id="Path_6764"
                              data-name="Path 6764"
                              d="M-.712-.1V13.655"
                              transform="translate(774.5 472.5)"
                              fill="none"
                              stroke="#fff"
                              strokeWidth="3"
                            />
                            <path
                              id="Path_6765"
                              data-name="Path 6765"
                              d="M-.712-.1V3.538"
                              transform="translate(774.5 489.973)"
                              fill="none"
                              stroke="#fff"
                              strokeWidth="3"
                            />
                          </g>
                        </svg>
                      </div>
                      <div className="confirmtext">
                        <h6>
                          Are you sure you want to downgrade to the Preview
                          plan?{" "}
                        </h6>
                        <h6>
                          Your subscription will automatically be downgraded at
                          the end of your subscription.{" "}
                        </h6>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end finalselection">
                      <div onClick={(e) => this.removepopup()}>
                        <p>No</p>
                      </div>

                      <div
                        onClick={(e) =>
                          this.state.customer_type === 4
                            ? this.handleWooCustomer(
                                e,
                                this.state.customer_type,
                                this.state.one_time[0].plan_code
                              )
                            : this.previewdowngrade(e)
                        }
                      >
                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                          <p>
                            <div className={this.state.loader_remove}>
                              <img
                                src={this.state.loaderimage_remove}
                                alt="loader"
                              />
                            </div>
                            <div>{this.state.submitval_confirmpop}</div>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </ModalBody>
              </Modal>
            </>
          )}
          {/* {Foot("pricing-bottomspacing")} */}
        </section>
      </>
    );
  }
}
