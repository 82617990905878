import React, { useEffect, useState } from "react";

import CommonProduct from "./CommonProduct";
const HMSfeaturedProducts = ({
  homescreenData,
  setHomescreenData,
  blockIndx,
  handleStatus,
  handleIsRowOpen,
  isRowOpen,
  setIsRowOpen,
  handleDeleteItem,
  eachBlock,
  handleDuplicateItem,
presetColors,
  provided,
}) => {
  return (
    <CommonProduct
      homescreenData={homescreenData}
      setHomescreenData={setHomescreenData}
      blockIndx={blockIndx}
      handleStatus={handleStatus}
      handleIsRowOpen={handleIsRowOpen}
      isRowOpen={isRowOpen}
      setIsRowOpen={setIsRowOpen}
      handleDeleteItem={handleDeleteItem}
      eachBlock={eachBlock}
      handleDuplicateItem={handleDuplicateItem}
      presetColors={presetColors}
      provided={provided}

      from={"Featured"}
    />
  );
};

export default HMSfeaturedProducts;
