import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";
import crossIc from "../../../assets/images/dashboard/cross.png";
const BuildSettingErrorModal = ({ notificationData, setNotificationData }) => {
  const closeModal = () => {
    setNotificationData({
      iosOpenPopUp: false,
      androidOpenPopUp: false,
    });
  };

  return (
    <Modal
      className="publish-secondmodal build-setting-error-modal"
      isOpen={
        notificationData.iosOpenPopUp || notificationData.androidOpenPopUp
      }
      centered
    >
      <ModalHeader>
        <div className="publish-crossmodal" onClick={closeModal}>
          <img src={crossIc} alt="cross" />
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="publish-popup-maindiv">
          <div className="publish-popup-head">
            <h2>Build settings incomplete</h2>
          </div>
          {notificationData.is_android_build_toggle_enable === 0 &&
          notificationData.settings.sendToAndroid &&
          notificationData.androidOpenPopUp ? (
            <p>
              To send push notifications to your Android app users, configure
              you app’s build settings. A rebuild is required each time changes
              are made to the Build settings.
            </p>
          ) : null}

          {notificationData.is_ios_build_toggle_enable === 0 &&
          notificationData.settings.sendToIos &&
          notificationData.iosOpenPopUp ? (
            <p>
              To send push notifications to your iOS app users, configure you
              app’s build settings. A rebuild is required each time changes are
              made to the Build settings.
            </p>
          ) : null}

          <div className="publish-popup-bottom">
            <button className="publish-popup-cancel" onClick={closeModal}>
              Okay
            </button>
            <Link to={"/app/notifications/build-settings?type=plain"}>
              <button className="publish-popup-confirm">Build settings</button>
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default BuildSettingErrorModal;
