import React from "react";

const EyeIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25.284"
      height="20.465"
      viewBox="0 0 25.284 20.465"
    >
      <g
        id="Group_39189"
        data-name="Group 39189"
        transform="translate(0.568 0.353)"
      >
        <path
          id="Path_103079"
          data-name="Path 103079"
          d="M68.716,9.742a13.852,13.852,0,0,1-12.07,7.684A13.876,13.876,0,0,1,44.568,9.742,13.876,13.876,0,0,1,56.646,2.058,13.852,13.852,0,0,1,68.716,9.742Z"
          transform="translate(-44.568 0.138)"
          fill="none"
          stroke=""
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <ellipse
          id="Ellipse_8629"
          data-name="Ellipse 8629"
          cx="3.842"
          cy="3.842"
          rx="3.842"
          ry="3.842"
          transform="translate(8.233 6.038)"
          fill="none"
          stroke=""
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <line
          id="Line_430"
          data-name="Line 430"
          x2="19.758"
          y2="19.758"
          transform="translate(2.196 0.001)"
          fill="none"
          stroke=""
          stroke-miterlimit="10"
          stroke-width="1"
        />
      </g>
    </svg>
  );
};

export default EyeIcon;
