import React, { createContext, Suspense, useEffect, useState } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import "./App.css";
import Signup from "./Signup/Signup";
import Appsumosignup from "./Signup/Appsumosignup";
import Login from "./Signup/Login";
import LoginRedirect from "./Signup/LoginRedirect";
import SignUpRedirect from "./Signup/SignUpRedirect";
import Getstarted from "./Welcome/Getstarted";
import Dashboard from "./Dashboard/Appearance/Dashboard";
import Consent from "./Dashboard/Access/Consent";
import Style from "./Dashboard/Appearance/Style";
import Authenticate from "./Dashboard/Appearance/Authenticate";
import Appsettings from "./Dashboard/Appsettings/Appsettings";
import Downloadplugin from "./Dashboard/Connectivity/Downloadplugin";
import Apikeys from "./Dashboard/Connectivity/Apikeys";
import Myapps from "./Dashboard/Myapps/Myapps";
import Publishapp from "./Dashboard/Appdownload/Publishapp";
import Alladdons from "./Dashboard/Appdownload/Alladdons";
import Subscription from "./Dashboard/Appdownload/Subscription";
import Preview from "./Dashboard/Apppreview/Preview";
import Lambda from "./Dashboard/Apppreview/Lambda";
import { Publishios } from "./Dashboard/Publish/IOS/Publishios";
import Troubleshootingpage from "./Dashboard/Connectivity/Troubleshootingpage";
import Logs from "./Dashboard/Logs/logs";
import EmptyLogs from "./Dashboard/Logs/EmptyLogs";
import EmptyCLogs from "./Dashboard/Logs/EmptyCLogs";
import Pagestab from "./Dashboard/Appsettings/Pages";
import BuildIos from "./Dashboard/Appdownload/BuildIos";
import BuildAndroid from "./Dashboard/Appdownload/BuildAndroid";
import Wpengine from "./Dashboard/Performance/Wpengine";
import Cloudflare from "./Dashboard/Performance/Cloudflare";
import Maintenance from "./Dashboard/Performance/Maintenance";
import Reports from "./Dashboard/Charts/Reports";
import TagManager from "react-gtm-module";
import ContactUs from "./Dashboard/ContactUs/contactUs";
import EnterpriseContactUs from "./Dashboard/ContactUs/EnterpriseContactUs";
import SaleContactUs from "./Dashboard/ContactUs/SaleContactUs";
import Monitor from "./Dashboard/Connectivity/Monitor";
import { Team } from "./Dashboard/Workspace/Team";
import WorkspaceOverview from "./Dashboard/Workspace/WorkspaceOverview";
import PlanListing from "./Dashboard/PlanListing";
import PriceCategories from "./Dashboard/Workspace/PriceCategories";
import Cmspages from "./Dashboard/CmsBuilder/Cmspages";
import Editpage from "./Dashboard/CmsBuilder/Editpage";
import EditPost from "./Dashboard/CmsBuilder/EditPost";
import WorkspaceListing from "./Dashboard/Workspace/WorkspaceListing";
import WorkspaceAddons from "./Dashboard/Workspace/WorkspaceAddons";
import Newhomescreen from "./Dashboard/Appearance/NewHomescreen/Newhomescreen";
import Woocommercesignup from "./Signup/Woocommercesignup";
import WordpressPricing from "./Dashboard/Pricing/WordpressPricing";
import AppPricing from "./Dashboard/Pricing/AppPricing";
import WebtoappPricing from "./Dashboard/Pricing/WebtoappPricing";
import CustomAppPricing from "./Dashboard/Pricing/CustomAppPricing";
import WoocommercePricing from "./Dashboard/Pricing/WoocommercePricing";
import Categorypage from "./Dashboard/CmsBuilder/Categorypage";
import Tagpage from "./Dashboard/CmsBuilder/Tagpage";
import Postlist from "./Dashboard/CmsBuilder/Postlist";
import RichNotificationsList from "./Dashboard/RichNotification/NotificationsList";
import RichNotificationCreate from "./Dashboard/RichNotification/NotificationCreate";
import Segments from "./Dashboard/RichNotification/Segments";
import SegmentCreate from "./Dashboard/RichNotification/SegmentCreate";
import RichUsers from "./Dashboard/RichNotification/Users";
import RichNotificationReport from "./Dashboard/RichNotification/NotificationReport";
import RichNotificationIcon from "./Dashboard/RichNotification/NotificationIcon";
import NotificationConfiguration from "./Dashboard/RichNotification/NotificationConfiguration";

import UnlimitedWebtoappPricing from "./Dashboard/Pricing/UnlimitedWebtoappPricing";
import UnlimitedWordpressPricing from "./Dashboard/Pricing/UnlimitedWordpressPricing";
import UnlimitedWoocommercePricing from "./Dashboard/Pricing/UnlimitedWoocommercePricing";
import unlimitedCMSPricing from "./Dashboard/Pricing/unlimitedCMSPricing";
import unlimitedALLINONEpricing from "./Dashboard/Pricing/unlimitedALLINONEpricing";
import CreateAgency from "./Agency/CreateAgency";
import AgencyDetails from "./Agency/AgencyDetails";
import AgencyList from "./Agency/AgencyList";
import AgencyPricing from "./Agency/AgencyPricing";
import AgencyDomain from "./Agency/Domains/CreateDomain";
import AgencyEmailConfiguration from "./Agency/Emails/EmailConfiguration";
import AgencyDownloadPlugin from "./Agency/Plugins/DownloadPlugin";
import AgencySocialLogin from "./Agency/SocialAuth/SocialLogin";
import AgencyCustomerSupport from "./Agency/Support/CustomerSupport";
import AgencyDashboard from "./Agency/AgencyDashboard";
import AgencyTeams from "./Agency/Teams/AgencyTeams";
import AgencyAddons from "./Agency/Addon/Addon";
import AgencyApps from "./Agency/Apps/Apps";
import AgencyCustomers from "./Agency/Customers/Customers";
import AgencyCustomersAsAdmin from "./Agency/Customers/CustomersAsAdmin";
import AgencySubscriptions from "./Agency/Subscriptions/Subscriptions";
import AgencyEmailVerification from "./Agency/AgencyEmailVerification";
import AgencySMTPVerification from "./Agency/AgencySMTPVerification";
import AgencyTeamInvitation from "./Agency/Teams/AgencyTeamInvitation";
import AgencyDealselection from "./Agency/Deals/Dealselection";
import NewAppIcon from "./Dashboard/Appearance/AppIcon";
import SplashScreen from "./Dashboard/Appearance/SplashScreen";
import Profile from "./Dashboard/Profile";
import Redeem from "./Dashboard/Redeem";
import Security from "./Dashboard/Security";
import Billingpayment from "./Dashboard/Billing/Billingsubscription";
import Dealselection from "./Dashboard/Pricing/PricingDealselection";
import Billingdetail from "./Dashboard/Billing/Billingdetail";
import Invoices from "./Dashboard/Billing/Invoice";
import Deals from "./Dashboard/Billing/Deals";
import Paymentmethod from "./Dashboard/Billing/Paymentmethod";
import Changepassword from "./Dashboard/Changepassword";
import Notvalidateuser from "./Dashboard/Reusable/Notvalideuser";
import AgencyBlock from "./Agency/AgencyBlock";
import ContactAdmin from "./Signup/ContactAdmin";
import ErrorPage from "./Dashboard/ErrorPage";
import Appnamenew from "./Dashboard/Appsettings/Appnamenew";
import NewWorkspace from "./Dashboard/Workspace/NewWorkspace";
import WorkspaceDashboard from "./Dashboard/Workspace/WorkspaceDashboard";
import Workspaceappslist from "./Dashboard/Workspace/Workspaceappslist";
import Workspacesetting from "./Dashboard/Workspace/Workspacesettings";
import Workspaceteams from "./Dashboard/Workspace/Workspaceteams";
import Newdashboard from "./Dashboard/Newdashboard";
import Userdashboard from "./Dashboard/Userdashboard";
import SupportPricing from "./Dashboard/Pricing/SupportPricing";
import SupportCheckout from "./Dashboard/Appdownload/SupportCheckout";
import TroubleshootDetails from "./Dashboard/Connectivity/TroubleshootDetails";
import Product from "./Dashboard/ProductSetting/Product";
import Newchat from "./Dashboard/Chat/Newchat";
import AttributesListing from "./Dashboard/CmsBuilder/AttributesListing";
import AttributesListingChild from "./Dashboard/CmsBuilder/AttributesListingChild";
// import Menubuilder from "./Dashboard/MenuBuilder/newMenubuilder";
import BookACall from "./Dashboard/BookACall";
import { NewUser } from "./Dashboard/User/newUser";
import Download from "./Dashboard/Download/download";
import Android from "./Dashboard/Download/android";
import Ios from "./Dashboard/Download/ios";
import EditAdvertisement from "./Dashboard/Advertisement/EditAdvertisement";
import AdvertisementListing from "./Dashboard/Advertisement/AdvertisementListing";
import AdBuildSetting from "./Dashboard/Advertisement/AdBuildSetting";
import AccessLogin from "./Dashboard/Access/Login";
import LoginBuildSetting from "./Dashboard/Access/LoginBuildSetting";
import AdSetting from "./Dashboard/Advertisement/AdSetting";
import Monetisation from "./Dashboard/AppStoreConnection/Monetisation";
import PublishConnection from "./Dashboard/AppStoreConnection/PublishConnection";
import AnalyticsConnection from "./Dashboard/AppStoreConnection/AnalyticsConnection";
import { NewPublishAndroid } from "./Dashboard/Publish/Android/newPublishAndroid";
import { NewPublishiOS } from "./Dashboard/Publish/IOS/newPublishiOS";
import IAPConnection from "./Dashboard/AppStoreConnection/IAPConnection";
import UnlimitedWorkspace from "./Dashboard/Pricing/UnlimitedWorkspace";
import MergeApps from "./Dashboard/Multisite/MergeApps/MergeApps";
import NewCreateApp from "./Layouts/Pages/CreateApp/CreateAppMain";
import AppDashboard from "./Layouts/Pages/AppDashboard/AppDashboard";
import Customer from "./Layouts/Pages/Customers/Customers";
import MenuBuilder from "./Layouts/Pages/MenuBuilder/MenuBuilder";
import NewBottomBar from "./Layouts/Pages/BottomBar/BottomBar";
import Theme from "./Layouts/Pages/AppTheme/Theme";
import ProductCreation from "./Layouts/Pages/Ecommerce/Products/ProductCreation";
import ProductListing from "./Layouts/Pages/Ecommerce/Products/ProductListing";
import AppTeam from "./Layouts/Pages/Team/AppTeam";
import Addons from "./Layouts/Pages/Addons/Addons";
import GeneralSettings from "./Layouts/Pages/AppSettings/GeneralSettings";
import AppIcon from "./Layouts/Pages/AppIcon/AppIcon";
import Splash from "./Layouts/Pages/SplashScreen/Splash";
import UserProfile from "./Layouts/Pages/Profile/UserProfile";
import Products from "./Layouts/Pages/Products/Products";
import ProtectedRoute from "./Components/commonComponents/ProtectedRoute";
import Pagenotfound from "./Layouts/Pages/404Page/PageNotFound";
import WebView from "./Layouts/Pages/WebView/WebView";
import CreateNotification from "./Layouts/Pages/Notification/CreateNotification";
import PlainNotificationListing from "./Layouts/Pages/Notification/PlainNotificationListing";
import Modal from "./Components/commonComponents/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";


const Categoryselection = React.lazy(() =>
  import("./Dashboard/Appearance/Categoryselection")
);
const Blogcategoryselection = React.lazy(() =>
  import("./Dashboard/Appearance/Blogcategoryselection")
);
const Pageselection = React.lazy(() =>
  import("./Dashboard/Appearance/Pageselection")
);

const Invitation = React.lazy(() => import("./Dashboard/User/Invitation"));
// const InvitationExpired = React.lazy(() => import("./Dashboard/User/InvitationExpired"));
const Forgotpassword = React.lazy(() => import("./Signup/Forgot"));
const Thankyou = React.lazy(() => import("./Dashboard/Thankyoupage"));
const Emailsupportthankyou = React.lazy(() =>
  import("./Dashboard/Emailsupportthankyou")
);
const Maintain = React.lazy(() => import("./Signup/Maintain"));
const Activation = React.lazy(() => import("./Dashboard/Activation"));
const Adminlogin = React.lazy(() => import("./Admin/Adminlogin"));

const TwoFactorAuth = React.lazy(() => import("./Signup/TwoFactorAuth"));
const TwoFactorBackup = React.lazy(() => import("./Signup/TwoFactorBackup"));

const Androidform = React.lazy(() => import("./Dashboard/Androidform"));
const Iosform = React.lazy(() => import("./Dashboard/Iosform"));
const Resetpassword = React.lazy(() => import("./Signup/Reset"));

const Linkexpired = React.lazy(() =>
  import("./Dashboard/Reusable/Linkexpired")
);
const Social = React.lazy(() => import("./Dashboard/Appsettings/Social"));
const SocialSettings = React.lazy(() =>
  import("./Layouts/Pages/AppSettings/SocialSettings")
);
const Emailsupportpage = React.lazy(() =>
  import("./Dashboard/Emailsupportpage")
);
const Emailthankyou = React.lazy(() => import("./Dashboard/Emailthankyou"));
export const previewContext = createContext(null);
function App() {

  const [openModal, setOpenModal] = useState(false);
  const history = useHistory()

  const toggleActivateModal = (e) => {
    e?.preventDefault()
    setOpenModal((prevState) => !prevState);
  };
  

  const handleConfirmAction = () => {
    const workSpaceID =  localStorage.getItem("workspaceId")
      const websiteTechnology = parseInt(
        localStorage.getItem("website_technology")
      );
      if(workSpaceID){
        window.location.href = `/workspace/pricing/${btoa(workSpaceID)}`
        return
      }

      const appId = btoa(localStorage.getItem("Appid"));
      let pricingPath = "";
      switch (websiteTechnology) {
        case 4:
          pricingPath = `/app/wordpress-pricing/${appId}`;
          break;
        case 2:
          pricingPath = `/app/web-to-app-pricing/${appId}`;
          break;
        case 3:
          pricingPath = `/app/custom-app-pricing/${appId}`;
          break;
        default:
          pricingPath = `/app/woocommerce-pricing/${appId}`;
          break;
      }
      window.location.href = pricingPath
  };

  useEffect(() => {
    TagManager.initialize({ gtmId: "GTM-P2XMLGW" });
  }, []);

  return (
    <>
      <previewContext.Provider value={{toggleActivateModal}}>
      <BrowserRouter>
        <Suspense fallback={""}>
          <Switch>
          <Route exact path="/" render={() => <Redirect to="/sign-in" />} />
            <Route  exact path="/sign-in" component={Login} />
            <Route  exact path="/sign-up" component={Signup} />
            <Route  exact path="/sign-in-redirect/" component={LoginRedirect} />
            <Route  exact path="/sign-up-redirect/" component={SignUpRedirect} />
            <Route exact path="/maintenance" component={Maintain} />
            {/* <Redirect  from="/" to="/sign-in" /> */}
            <Route exact path="/performance/web-hosting" component={Cloudflare} />
            <Route exact path="/performance/maintenance" component={Maintenance} />
            <Route exact path="/performance/security" component={Wpengine} />
            <Route exact path="/email-verified" component={Emailthankyou} />
            <Route
              exact path="/signup-appsumo/:email/:product_id"
              component={Appsumosignup}
            />
            <Route exact path="/support-pricing" component={SupportPricing} />
            <Route
              exact path="/app/support-checkout/:planID?"
              component={SupportCheckout}
            />
            <Route exact path="/signup-woocommerce" component={Woocommercesignup} />
            <Route exact path="/get-in-touch" component={ContactUs} />
            <Route exact path="/talk-to-sales" component={EnterpriseContactUs} />
            <Route exact path="/enterprise-agency-sales" component={SaleContactUs} />
            <Route  exact path="/workspace/team" component={Team} />
            {/* <Route exact path="/app/team/:appID?" component={Usermodule} /> */}
            <Route exact path="/app/team/:appID?" component={AppTeam} />
            <Route exact path="/publish/iOS" component={Publishios} />
            <Route exact path="/forgot-password" component={Forgotpassword} />
            <Route exact path="/email-support" component={Emailsupportpage} />
            <Route exact path="/book-a-call" component={BookACall} />
            <Route
              exact path="/transaction-successful/:identifier?"
              component={Thankyou}
            />

            {/* <Route exact path="/app/merge" component={Multisite} /> */}
            <Route exact path="/app/merge" component={MergeApps} />
            <Route exact path="/invitation/:activetoken" component={Invitation} />
            <Route exact path="/link-expired" component={Linkexpired} />
            <Route exact path="/request-submitted" component={Emailsupportthankyou} />
            <Route exact path="/reset-password/:token" component={Resetpassword} />
            <Route exact path="/link-expired" component={Linkexpired} />
            <Route exact path="/getstarted" component={Getstarted} />
            <Route exact path="/app/page-settings" component={Pagestab} />
            <Route exact path="/android-build-config" component={BuildAndroid} />
            <Route exact path="/ios-build-config" component={BuildIos} />
            <Route exact path="/app/create/:workspaceId?" component={NewCreateApp} />
            {/* <Route exact path="/newcreate-app/:workspaceId?" component={NewCreateApp} /> */}
            <Route
              
              exact path="/app/basic-notifications"
              component={PlainNotificationListing}
            />
            <Route
              exact path="/app/appearance/login-screen"
              component={Authenticate}
            />
            {/* <Route exact path="/app/app-icon" component={NewAppIcon} /> */}
            <Route exact path="/app/app-icon" component={AppIcon} />
            {/* <Route exact path="/app/splash" component={SplashScreen} /> */}
            <Route exact path="/app/splash" component={Splash} />
            <Route exact path="/app/home" component={Dashboard} />
            <Route exact path="/app/appearance/home" component={Newhomescreen} />
            {/* <Route exact path="/app/theme" component={Style} /> */}
            <Route exact path="/app/theme" component={Theme} />
            <Route exact path="/app/consent" component={Consent} />
            <Route  exact path="/app/login" component={AccessLogin} />
            <Route
              
              exact path="/app/login/build-settings"
              component={LoginBuildSetting}
            />
            <Route
              
              exact path="/app/settings/app-menu/pages"
              component={Appsettings}
            />
            <Route
              exact path="/app/connect/troubleshoot/:index"
              component={TroubleshootDetails}
            />
            <Route
              exact path="/app/connect/troubleshoot-logs"
              component={Troubleshootingpage}
            />
            <Route
              
              exact path="/app/settings/social"
              component={SocialSettings}
            />
            <Route exact path="/app/connect/wp-plugin" component={Downloadplugin} />
            <Route exact path="/app/connect/wp-grant-access" component={Apikeys} />
            <Route exact path="/download-app" component={Publishapp} />
            <Route exact path="/publish/android" component={Androidform} />
            <Route exact path="/publish/ios" component={Iosform} />
            <Route exact path="/apps/:workspaceId?" component={Myapps} />
            <Route
              exact path="/activation/:activetoken/:oldEmail?"
              component={Activation}
            />
            <Route
              exact path="/adminlogin/:activetoken/:validetoken"
              component={Adminlogin}
            />
            <Route exact path="/profile" component={UserProfile} />
            <Route exact path="/redeem" component={Redeem} />
            <Route exact path="/changepassword" component={Changepassword} />
            {/* <Route exact path="/app/post-settings" component={Posttab} /> */}
            <Route exact path="/app/post-settings" component={Postlist} />
            <Route exact path="/app/web-view-settings/" component={WebView} />
            <Route exact path="/app/product-settings" component={Products} />
            <Route exact path="/app/newchat" component={Newchat} />
            <Route
              exact path="/payment-method/:appOrWorkspaceID?/:diff?"
              component={Paymentmethod}
            />

            <Route  exact path="/app/addons" component={Addons} />
            <Route exact path="/workspace-addons" component={WorkspaceAddons} />
            {/* <Route exact path="/app/dashboard/:workspaceId?" component={Newdashboard} /> */}
            <Route
              exact path="/app/dashboard"
              component={AppDashboard}
            />
            <Route exact path="/app/preview" component={Preview} />
            <Route exact path="/app/lambda" component={Lambda} />
            <Route  exact path="/app/settings" component={GeneralSettings} />
            <Route
              exact path="/app/categoryselection"
              component={Categoryselection}
            />
            <Route
              exact path="/app/blogcategoryselection"
              component={Blogcategoryselection}
            />
            <Route exact path="/app/pageselection" component={Pageselection} />
            {/* <Route  exact path="/app/menu" component={Menubuilder} /> */}
            <Route  exact path="/app/menu" component={MenuBuilder} />
            {/* <Route  exact path="/app/bottombar" component={NewBottomBar} /> */}
            <Route eaxct exact path="/app/bottom-bar" component={NewBottomBar} />
            <Route exact path="/app/logs" component={Logs} />
            <Route exact path="/Logs/EmptyLogs" component={EmptyLogs} />
            <Route exact path="/Logs/EmptyCLogs" component={EmptyCLogs} />
            <Route exact path="/app/analytics" component={Reports} />
            <Route exact path="/app/chat" component={Newchat} />
            <Route exact path="/app/connect/monitor" component={Monitor} />
            <Route exact path="/app/pages" component={Cmspages} />
            <Route exact path="/app/posts" component={Postlist} />
            <Route exact path="/app/page/:pageID?" component={Editpage} />
            <Route exact path="/app/post/:postID?" component={EditPost} />
            <Route exact path="/app/post-attributes" component={AttributesListing} />
            <Route
              exact path="/app/post-attribute-values/:atrId?"
              component={AttributesListingChild}
            />
            {/* <Route exact path="/newMenu" component={Menubuilder} /> */}
            <Route
              exact path="/app/post-categories/:categoryID?"
              component={Categorypage}
            />
            <Route exact path="/app/post-tags" component={Tagpage} />
            <Route exact path="/verify-email" component={Notvalidateuser} />
            <Route exact path="/security" component={Security} />
            <Route exact path="/two-factor" component={TwoFactorAuth} />
            <Route exact path="/two-factor-recovery" component={TwoFactorBackup} />
            <Route
              exact path="/app/create-pushnotification/:pushNotifyID?"
              component={CreateNotification}
            />
            {/* App store connection */}
            <Route exact path="/app/publish/ios" component={NewPublishiOS} />
            <Route exact path="/app/publish/android" component={NewPublishAndroid} />
            <Route path="/app/in-app-purchase" component={Monetisation} />
            <Route
              exact path="/app/app-store-connection/analytics"
              component={AnalyticsConnection}
            />
            <Route
              exact path="/app/app-store-connection/in-app-purchase"
              component={IAPConnection}
            />
            <Route
              exact path="/app/app-store-connection/publish"
              component={PublishConnection}
            />
            {/* App store connection */}

            <Route
              
              exact path="/app/ad-build-settings"
              component={AdBuildSetting}
            />
            <Route  exact path="/app/ad-settings" component={AdSetting} />
            <Route  exact path="/app/ads" component={AdvertisementListing} />
            <Route exact path="/app/ad/:adsId?" component={EditAdvertisement} />
            <Route
              
              exact path="/app/advanced-notifications"
              component={RichNotificationsList}
            />
            <Route
              
              exact path="/app/advanced-notification/create"
              component={RichNotificationCreate}
            />
            <Route
              
              exact path="/app/advanced-notification/:notificationid?"
              component={RichNotificationCreate}
            />
            <Route
              
              exact path="/app/advanced-notifications/subsets"
              component={Segments}
            />
            <Route
              
              exact path="/app/advanced-notifications/subsets/create"
              component={SegmentCreate}
            />
            <Route
              
              exact path="/app/advanced-notifications/subsets/:editsegmentid?"
              component={SegmentCreate}
            />
            <Route
              exact path="/app/advanced-notifications/audience"
              component={RichUsers}
            />
            <Route
              exact path="/app/advanced-notification/report/:notificationid?"
              component={RichNotificationReport}
            />
            <Route
              exact path="/app/rich-notification/icon"
              component={RichNotificationIcon}
            />
            <Route
              
              exact path="/app/notifications/build-settings"
              component={NotificationConfiguration}
            />
            <Route
              exact path="/app/basic-notification/create"
              component={CreateNotification}
            />
            <Route
              exact path="/app/basic-notification/:nfID?"
              component={CreateNotification}
            />
            <Route
              exact path="/agency/customers-as-admin"
              component={AgencyCustomersAsAdmin}
            />
             <ProtectedRoute exact path="/home" component={Userdashboard} />
            <Route exact path="/agency/404" component={AgencyBlock} />
            <Route exact path="/contact-admin" component={ContactAdmin} />
            <Route exact path="/500" component={ErrorPage} />
            <Route  exact path="/app/download" component={Download} />
            <Route
              
              exact path="/app/download/android-build-settings"
              component={Android}
            />
            <Route
              
              exact path="/app/download/ios-build-settings"
              component={Ios}
            />
            <Route exact path="/app/customers" component={Customer} />
            <Route
              exact path="/app/ecommerce/productcreation"
              component={ProductCreation}
            />
            <Route
              exact path="/app/ecommerce/productlisting"
              component={ProductListing}
            />

            <ProtectedRoute
              
              exact path="/workspace/create"
              component={NewWorkspace}
            />
            <ProtectedRoute
              
              exact path="/workspaces"
              component={WorkspaceListing}
            />
            <ProtectedRoute
              exact path="/checkout/:planname/:recurring_price/:package_name/:interval_unit/:id/:quantity/:isPlan/:appOrWpOrAgency?/:website_technology?/:agencyAppCount?/:agencyUserCount?"
              component={Alladdons}
            />
            <ProtectedRoute
              exact path="/workspace/pricing/:id"
              component={UnlimitedWorkspace}
            />
            <ProtectedRoute
              exact path="/app/wordpress-pricing/:appid?/"
              component={WordpressPricing}
            />
            <ProtectedRoute
              exact path="/app/web-to-app-pricing/:appid?/"
              component={WebtoappPricing}
            />
            <ProtectedRoute
              exact path="/app/custom-app-pricing/:appid?/"
              component={CustomAppPricing}
            />
            <ProtectedRoute
              exact path="/app/woocommerce-pricing/:appid?/"
              component={WoocommercePricing}
            />

            <ProtectedRoute
              exact path="/workspace/dashboard/:workspaceID?"
              component={WorkspaceDashboard}
            />
            <ProtectedRoute
              exact path="/workspace/apps/:workspaceID?"
              component={Workspaceappslist}
            />
            <ProtectedRoute
              exact path="/workspace/settings/:workspaceID?"
              component={Workspacesetting}
            />
            <ProtectedRoute
              exact path="/workspace/team/:workspaceID?"
              component={Workspaceteams}
            />

            <ProtectedRoute exact path="/subscriptions" component={Billingpayment} />
            <ProtectedRoute
              exact path="/app/apply-deal/:appid"
              component={Dealselection}
            />
            <ProtectedRoute exact path="/deals" component={Deals} />
            <ProtectedRoute exact path="/billing-details" component={Billingdetail} />
            <ProtectedRoute exact path="/invoices" component={Invoices} />
            <ProtectedRoute
              exact path="/subscription/:appid"
              component={Subscription}
            />

            <ProtectedRoute
              exact path="/plan-listing/:workspaceId/:checker"
              component={PlanListing}
            />
            <ProtectedRoute
              exact path="/workspace/web-to-app-pricing/:workspaceId"
              component={UnlimitedWebtoappPricing}
            />
            <ProtectedRoute
              exact path="/workspace/wordpress-pricing/:workspaceId"
              component={UnlimitedWordpressPricing}
            />
            <ProtectedRoute
              exact path="/workspace/woocommerce-pricing/:workspaceId"
              component={UnlimitedWoocommercePricing}
            />
            <ProtectedRoute
              exact path="/workspace/custom-app-pricing/:workspaceId"
              component={unlimitedCMSPricing}
            />
            <ProtectedRoute
              exact path="/workspace/all-in-one-pricing/:workspaceId"
              component={unlimitedALLINONEpricing}
            />
            <ProtectedRoute exact path="/pricing/:id" component={PriceCategories} />

            <ProtectedRoute exact path="/agency/create" component={CreateAgency} />
            <ProtectedRoute
              exact path="/agency/settings/:agencyID?"
              component={AgencyDetails}
            />
            <ProtectedRoute exact path="/agencies" component={AgencyList} />
            <ProtectedRoute
              exact path="/agency/pricing/:agencyID?"
              component={AgencyPricing}
            />
            <ProtectedRoute
              exact path="/agency/deals/:agencyID?"
              component={AgencyDealselection}
            />
            <ProtectedRoute
              exact path="/agency/dashboard/:agencyID?"
              component={AgencyDashboard}
            />
            <ProtectedRoute
              exact path="/agency/domain/:agencyID?"
              component={AgencyDomain}
            />
            <ProtectedRoute
              exact path="/agency/team/:agencyID?"
              component={AgencyTeams}
            />
            <ProtectedRoute
              exact path="/agency/addon/:agencyID?"
              component={AgencyAddons}
            />
            <ProtectedRoute
              exact path="/agency/email/:agencyID?"
              component={AgencyEmailConfiguration}
            />
            <ProtectedRoute
              exact path="/agency/wordpress-plugin/:agencyID?"
              component={AgencyDownloadPlugin}
            />
            <ProtectedRoute
              exact path="/agency/authentication/:agencyID?"
              component={AgencySocialLogin}
            />
            <ProtectedRoute
              exact path="/agency/support/:agencyID?"
              component={AgencyCustomerSupport}
            />
            <ProtectedRoute
              exact path="/agency/apps/:agencyID?"
              component={AgencyApps}
            />
            <ProtectedRoute
              exact path="/agency/customers/:agencyID?"
              component={AgencyCustomers}
            />
            <ProtectedRoute
              exact path="/agency/subscriptions/:agencyID?"
              component={AgencySubscriptions}
            />
            <ProtectedRoute
              exact path="/agency/emailverification/:activetoken"
              component={AgencyEmailVerification}
            />
            <ProtectedRoute
              exact path="/agency/smtpverification/:activetoken"
              component={AgencySMTPVerification}
            />
            <ProtectedRoute
              exact path="/agency/invitation/:activetoken"
              component={AgencyTeamInvitation}
            />

            <Route path="*" component={Pagenotfound} />
          </Switch>
        </Suspense>
      </BrowserRouter>
      </previewContext.Provider>
      <Modal
        open={openModal}
        toggle={toggleActivateModal}
        title="Upgrade to unlock premium features"
        description="This is a premium feature. Upgrade now to access all premium features."
        cancelbutton="Cancel"
        submitbutton="Upgrade"
        onSubmit={() => handleConfirmAction()}
      />
    </>
  );
}
export default App;
