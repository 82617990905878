import React from "react";

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { DURATION_ARRAY } from "../../../Constants/webviewConstants/WebviewConstants";

const NativeDropdown = ({ webViewState, handleStateChange }) => {
  return (
    <Dropdown
      className="cms-filterby-post search-dropdown webViewdropdown"
      isOpen={
        window.matchMedia("(max-width: 1200px)").matches
          ? false
          : webViewState.openNativeDropdown
      }
      onClick={
        window.matchMedia("(max-width: 1200px)").matches
          ? () => handleStateChange("toggle_openMobNativeDropdown")
          : null
      }
      toggle={() => handleStateChange("toggle_open_native_dropdown")}
    >
      <DropdownToggle caret>
        {webViewState.nativeLoaderDuration
          ? webViewState.nativeLoaderDuration.charAt(0).toUpperCase() +
          webViewState.nativeLoaderDuration.slice(1)
          : "Select"}
      </DropdownToggle>
      <DropdownMenu>
        {DURATION_ARRAY.map((duration, idx) => (
          <DropdownItem
            active={webViewState.nativeLoaderDuration == duration}
            onClick={(e) => handleStateChange("nativeLoaderDuration", duration)}
            key={idx}
          >
            {duration}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default NativeDropdown;
