import React from "react";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import { Link } from "react-router-dom";
import { MobileApppreview } from "../Apppreview";
import { ConsentForm, CreateAccount } from "./AppScreen";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  Media,
} from "reactstrap";
import {
  Pixalbay,
  Pixalbaysearch,
  Pixalbayscroll,
  LoginsignupAPI,
  GetLoginsignupAPI,
  Customlogoupload,
} from "../../Api/Dashboard/DashboardApi";
import Imagecrop from "../../Dashboard/Imagecrop";
import { errortoast } from "../Toaster";
import Reactcolorpicker from "./NewReactcolorpicker";
import classnames from "classnames";
import BottomScrollListener from "react-bottom-scroll-listener";
import Tooltip from "../Tooltip";
import { RemoveApppreview } from "./RemoveApppreview";
import { InnerFoot } from "../../Dashboard/Foot";
import {toPng} from "html-to-image";
import Amsvideo from "../Reusable/Amsvideos";
import NewImageGalleryPopup from "../NewImageGalleryPopup";
const Topsection = () => {
  return (
    <>
      <div className="d-md-flex d-none align-items-center justify-content-between ipad-margin-top top-portion ipad-portion-appearance">
        <div>
          <div className="app_iconname">
            <div className="d-flex align-items-center">
              <h3>Login &amp; Sign Up</h3>
              {/* <Amsvideo
                timespan={88}
                addclass="d-md-flex d-none align-items-center justify-content-between see_video"
              /> */}
            </div>
            <h5>
              Design the login, sign up &amp; forgot password screens.{" "}
              {/* <Media
                href="https://www.appmysite.com/support/design-your-login-sign-up-screens/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More{" "}
                <img
                  src={
                    require("../../assets/images/dashboard/Goto.png").default
                  }
                  className="gotoappicon appear-spacing"
                  alt="Goto"
                />
              </Media> */}
              {" "}
            </h5>
          </div>
        </div>
      </div>
    </>
  );
};

let sectionIndex = ["1"];

const Arrow = ({ direction, clickFunction, glyph }) => (
  <div className={`slide-arrow ${direction}`} onClick={clickFunction}>
    {glyph}
  </div>
);

export default class Authenticate extends React.PureComponent {
  sheet = document.createElement("style");
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.previousSlide = this.previousSlide.bind(this);
    this.state = {
      spinner: true,
      pixelspinner: false,
      submitval: "Save",
      social_shape: "circle",
      loader: "d-none",
      disabledsubmitbutton: false,
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      //Modal State
      modal: false,
      selectpixabayimage: require("../../assets/images/dashboard/small-up.png")
        .default,
      low_resolution_image: "",
      pixalbayimage: [],
      pixalimagenotavailable: false,
      pixalsearchvalue: "",
      pageval: 1,
      uploadcustomdefaultimage:
        require("../../assets/images/dashboard/storage.png").default,
      uploadcustomimage: false,
      uploadcustomdefaultimageApi: null,
      original_bgimg: "",
      submitbuttontext: "Done",

      //Background Color State
      splashBackground: "#3064f9",
      selectbackgroundorcolor: true,
      sendimagedataapi: "",

      //Logo text State
      logotext: "",
      is_logo_image: null,
      app_icon_image: "",
      //Primary Color State
      primaryColor: "#ffffff",
      fontSize: "",

      //Secondary Color State
      secondaryColor: "#94b4ff",
      secondaryborder: "rgba(148,180,255,0.3)",
      //Button Color State
      buttonColor: "#ffffff",
      buttonTextColor: "#000000",
      default: 0,

      //Upload Login Icon State
      defaultimage: require("../../assets/images/dashboard/small-up.png")
        .default,
      iconImage: null,
      checkApplogo: false,

      //Carousel state
      currentImageIndex: 0,
      errorimage: null,
      consent_form_purchased: 0,
      notch: require("../../assets/images/dashboard/speaker.png").default,
      holloimage: require("../../assets/images/dashboard/phoneback.png")
        .default,
      hollowactive: true,
      getimage: true,
      getlogoimage: true,
      checkimage: "",
      checkapp_logo: "",
      imagename: "",

      //App logo states
      activeTab: "1",
      applogotext: "",

      applogocolor: "#000000",
      appfontfamily: "Arial-Black",
      appfontsize: "22px",
      applogodefaultimage: require("../../assets/images/dashboard/storage.png")
        .default,
      //cutomlogo
      customlogo: "",
      customlogotext: "",
      customlogocolor: "",
      customfontfamily: "Arial-Black",
      customfontsize: "22px",
      customlogoalign: "center",
      uploadtext: "",
      splashimagetext: "",

      range: 30,

      oractive: "active_tab",
      andactive: "not_active_tab",
      tabselection: "or",
      tintcolor: "#636161",
      //
      textfontfamily: "Arial-Black",
      welcometextcolor: "#000000",
      welcometext: "Welcome to AppMySite!",
      textfontsize: "22px",
      logo_align: "center",
      website_technology: parseInt(localStorage.getItem("website_technology")),
      presetColors: null,
      isGalleryPopup:false,
      appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
        ? true
        : false,
    };
    this.tabtoggle = this.tabtoggle.bind(this);
  }
  handletextfontsize = (e) => {
    this.setState({
      textfontsize: e.target.value,
    });
  };
  handlewelcometext = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };
  handletextfontfamily = (e) => {
    this.setState({
      textfontfamily: e,
    });
  };
  handletextcolor = (e) => {
    this.setState({
      welcometextcolor: e,
    });
  };
  loadFirst = () => {
    window.scrollTo(0, 0);
    this.setState({
      submitval:
        localStorage.getItem("login_register_icon_count") === "1"
          ? "Save"
          : "Save",
    });
    GetLoginsignupAPI(this, () => {
      if (this.state.tabselection === "and") {
        this.sheet.innerHTML =
          ".backselect::before {background:" +
          this.state.tintcolor +
          ";opacity:" +
          this.state.range +
          "%}";
        document.body.appendChild(this.sheet);
      } else {
        this.sheet.innerHTML =
          ".backselect::before {background:none;opacity:" + 0 + "%}";
        document.body.appendChild(this.sheet);
      }
      // sectionIndex = [
      // 	"1","2","3"
      // ];
      if (this.state.consent_form_purchased === 1) {
        sectionIndex = ["1", "2"];
      } else {
        sectionIndex = ["1"];
      }
    });
  };
  componentDidMount() {
    let numarray = [5, 3, 1, 0, 9];
    let sortarray = [];

    for (var i = 0; i < numarray.length; i++) {
      for (var j = i + 1; j < numarray.length - 1; j++) {
        if (numarray[i] < numarray[j]) {
          sortarray.push(numarray[j]);
        }
      }
    }

    document.title = "Login & Signup";
    this.loadFirst();
    const params = {
      key: "10808991-c5f19bdf65a0c23810c985899",
      orientation: "vertical",
    };

    Pixalbay(params, this);
  }
  //AppLogo
  tabtoggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }
  handlelogotext = (e) => {
    this.setState({
      applogotext: e.target.value.substring(0, 15),
    });
  };
  handlelogocolor = (e) => {
    this.setState({
      applogocolor: e,
    });
  };
  handlelogofontsize = (e) => {
    this.setState({
      appfontsize: e.target.value,
    });
  };
  handlelogofontfamily = (e) => {
    this.setState({
      appfontfamily: e,
    });
  };
  handleappImage = (e) => {
    let self = this;
    let reader = new FileReader();
    let file = e.target.files[0];
    if (
      file.name.indexOf("png") !== -1 ||
      file.name.indexOf("jpeg") !== -1 ||
      file.name.indexOf("jpg") !== -1
    ) {
      if (file.size / (1024 * 1024).toFixed(2) <= 2) {
        reader.onload = function (upload) {
          let img = new Image();
          img.src = upload.target.result;
          img.onload = function () {
            if (this.height >= 500 || this.width >= 500) {
              self.setState({
                applogodefaultimage: upload.target.result,
                iconImage: null,
                checkApplogo: true,
                getlogoimage: false,
              });
            } else {
              errortoast("Image width should be between 500px and 1280px.");
              self.setState({
                disabledsubmitbutton: false,
              });
            }
          };
        };
        reader.readAsDataURL(file);
      } else {
        errortoast("File size should be less than 2MB.");
        this.setState({
          disabledsubmitbutton: false,
        });
      }
    } else {
      errortoast(
        "File format not supported. Upload PNG, JPEG or JPG file only."
      );
      this.setState({
        disabledsubmitbutton: false,
      });
    }
  };
  //App crop
  finallogocrop = (croppedImage, base64, original) => {
    this.setState({
      applogodefaultimage: base64,
      iconImage: croppedImage,
      original_app_icon: original,
      checkApplogo: true,
      getlogoimage: true,
      uploadtext: "Click here to upload an image.",
    });
  };
  whenAppTogglemodal = () => {
    this.setState({
      applogodefaultimage: require("../../assets/images/dashboard/storage.png"),
      iconImage: null,
      original_app_icon: null,
      checkApplogo: false,
      getlogoimage: true,
      uploadtext: "",
    });
  };
  //Applogoend
  previousSlide() {
    const lastIndex = sectionIndex.length - 1;
    const { currentImageIndex } = this.state;
    const shouldResetIndex = currentImageIndex === 0;
    const index = shouldResetIndex ? lastIndex : currentImageIndex - 1;

    this.setState(
      {
        currentImageIndex: index,
      },
      () => {
        this.dotactive();
      }
    );
  }
  dotactive() {
    if (this.state.currentImageIndex === 0) {
      this.state.consent_form_purchased === 1 &&
        document.querySelector(".first").classList.add("active");
      this.state.consent_form_purchased === 1 &&
        document.querySelector(".second").classList.remove("active");
    } else if (this.state.currentImageIndex === 1) {
      this.state.consent_form_purchased === 1 &&
        document.querySelector(".first").classList.remove("active");
      this.state.consent_form_purchased === 1 &&
        document.querySelector(".second").classList.add("active");
    }
  }
  nextSlide() {
    const lastIndex = sectionIndex.length - 1;
    const { currentImageIndex } = this.state;
    const shouldResetIndex = currentImageIndex === lastIndex;
    const index = shouldResetIndex ? 0 : currentImageIndex + 1;

    this.setState(
      {
        currentImageIndex: index,
      },
      () => {
        this.dotactive();
      }
    );
  }
  hitindex = (ind) => {
    this.setState(
      {
        currentImageIndex: parseInt(ind),
      },
      () => {
        this.dotactive();
      }
    );
  };

  //ButtonColor function
  handlebuttonTextColor = (e) => {
    this.setState({
      buttonTextColor: e,
    });
  };
  //ButtonTextColor function
  handlebuttonColor = (e) => {
    this.setState({
      buttonColor: e,
    });
  };
  //PrimaryColor Function
  handleprimaryColor = (e) => {
    this.setState({
      primaryColor: e,
    });
  };
  //SecondaryColor Function
  handlesecondaryColor = (e) => {
    const oldCss = e;
    const newOpacity = "0.3";
    const newCss = oldCss.replace(/[^,]+(?=\))/, newOpacity);

    this.setState({
      secondaryColor: e,
      secondaryborder: newCss,
    });
  };
  switchmobile = (e, data) => {
    e.preventDefault();
    if (data === "android") {
      this.setState({
        holloimage: require("../../assets/images/dashboard/androidapps.png")
          .default,
        notch: require("../../assets/images/dashboard/androidnotch.png")
          .default,
        hollowactive: false,
      });
    } else {
      this.setState({
        holloimage: require("../../assets/images/dashboard/phoneback.png")
          .default,
        notch: require("../../assets/images/dashboard/speaker.png").default,
        hollowactive: true,
      });
    }
  };
  //logotextcolor Function
  handlelogoColor = (e) => {
    this.setState({
      logoColor: e,
    });
  };
  //Upload Background Image Modal
  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }
  //Background color function
  changecolor = (e) => {
    this.setState({
      splashBackground: e,
      selectbackgroundorcolor: true,
      selectpixabayimage: "",
      low_resolution_image: "",
      chooseImage: null,
      imagename: "",
    });
  };

  //Modal Scroll Function
  handleContainerOnBottom = () => {
    this.setState({ pageval: 1 + this.state.pageval });
    const params = {
      pixalsearchvalue: this.state.pixalsearchvalue,
      pageval: this.state.pageval,
      pixalbayimage: this.state.pixalbayimage,
      orientation: "vertical",
    };
    Pixalbayscroll(params, this);
  };
  handlepixabaysearch = (e) => {
    this.setState({
      pixalsearchvalue: e.target.value,
    });
  };
  handlechooseimage = (e) => {
    this.setState({
      chooseImage: e.currentTarget.value,
      submitbuttontext: "Done",
    });
  };
  submitChooseImage = (e) => {
    this.setState({
      splashimagetext: "",
    });

    const chooseImage = this.state.chooseImage;

    if (chooseImage != null) {
      const chooseImageWeb =
        chooseImage.indexOf("blob") === -1
          ? this.state.chooseImage.replace("1280.jpg", "640.jpg")
          : this.state.chooseImage;
      this.setState({
        selectpixabayimage: chooseImage,
        selectbackgroundorcolor: false,
        splashBackground: "",
        imagename: chooseImage.substr(chooseImage.lastIndexOf("/") + 1),
        low_resolution_image: chooseImageWeb,
      });
      this.toggle();
    } else {
      errortoast("Please select image");
      this.setState({
        disabledsubmitbutton: false,
      });
      return;
    }
  };

  //Splash upload
  uploadcustomdefaultimage = (e) => {
    let self = this;
    let reader = new FileReader();
    let file = e.target.files[0];
    if (
      file.name.indexOf("png") !== -1 ||
      file.name.indexOf("jpeg") !== -1 ||
      file.name.indexOf("jpg") !== -1
    ) {
      if (file.size / (1024 * 1024).toFixed(2) < 4) {
        reader.onload = function (upload) {
          let img = new Image();
          img.src = upload.target.result;
          img.onload = function () {
            if (this.height >= 2208 && this.width >= 1242) {
              self.setState({
                uploadcustomdefaultimage: upload.target.result,
                getimage: false,
              });
            } else {
              errortoast("Recommended file size is 1242*2208 px or above.");
              self.setState({
                disabledsubmitbutton: false,
              });
            }
          };
        };
        reader.readAsDataURL(file);
      } else {
        errortoast("File size should not less than 4MB.");
        this.setState({
          disabledsubmitbutton: false,
        });
      }
    } else {
      errortoast(
        "File format not supported. Upload PNG, JPEG or JPG file only."
      );
      this.setState({
        disabledsubmitbutton: false,
      });
    }
  };
  // Final splash Image
  // finalcrop = (croppedImage, base64, original) => {
  //   this.setState({
  //     uploadcustomdefaultimage: base64,
  //     uploadcustomdefaultimageApi: croppedImage,
  //     chooseImage: base64,
  //     submitbuttontext: "Done",
  //     original_bgimg: original,
  //     getimage: true,
  //     splashimagetext: "Click here to upload an image.",
  //   });
  // };
  // whenTogglemodal = () => {
  //   this.setState({
  //     uploadcustomdefaultimage: require("../../assets/images/dashboard/storage.png"),
  //     getimage: true,
  //     uploadcustomdefaultimageApi: null,
  //     chooseImage: null,
  //     original_bgimg: null,
  //     splashimagetext: "",
  //   });
  // };
  // toggleupload = () => {
  //   if (this.state.uploadcustomimage) {
  //     this.setState({
  //       uploadcustomdefaultimage: require("../../assets/images/dashboard/storage.png"),
  //       uploadcustomimage: false,
  //       selectpixabayimage: null,
  //       chooseImage: null,
  //       splashimagetext: "",
  //     });
  //   } else {
  //     this.setState({
  //       uploadcustomimage: true,
  //       selectpixabayimage: null,
  //       chooseImage: null,
  //     });
  //   }
  // };
  // pixalsearch = (e) => {
  //   e.preventDefault();
  //   const params = {
  //     pixalsearchvalue: this.state.pixalsearchvalue,
  //     orientation: "vertical",
  //   };
  //   this.setState({
  //     pageval: 1,
  //   });
  //   // if (this.state.pixalsearchvalue) {
  //   document.querySelector(".modal-body-content").scrollTo(0, 0);
  //   // this.paneDidMount.scrollTo(0, 0);
  //   Pixalbaysearch(params, this);
  //   // } else return;
  // };
  // loginsignupAPI Function
  loginsignupAPI = (e) => {
    e.preventDefault();

    this.setState(
      {
        currentImageIndex: 0,
      },
      () => {
        this.dotactive();
        if (this.state.activeTab === "1") {
          this.setState({
            submitval: "",
            loader: "loader",
            disabledsubmitbutton: true,
          });
          let node =
            this.state.activeTab === "1"
              ? document.getElementById("clockid")
              : document.getElementById("clock");
          let nodeoffsetwidth = node.offsetWidth === 0 ? 214 : node.offsetWidth;
          let nodeoffsetheight =
            node.offsetHeight === 0 ? 100 : node.offsetHeight;
          const scale = 750 / nodeoffsetwidth;
          toPng(node, {
              height: nodeoffsetheight * scale,
              width: nodeoffsetwidth * scale,
              style: {
                transform: "scale(" + scale + ")",
                transformOrigin: "top left",
                width: nodeoffsetwidth + "px",
                height: nodeoffsetheight + "px",
              },
            })
            .then((dataUrl) => {
              const params = {
                data: dataUrl,
                path:
                  localStorage.getItem("user_id") +
                  "/" +
                  localStorage.getItem("Appid") +
                  process.env.REACT_APP_LoginLogo_Crop,
                original: dataUrl,
                original_path:
                  localStorage.getItem("user_id") +
                  "/" +
                  localStorage.getItem("Appid") +
                  process.env.REACT_APP_LoginLogo_Crop,
              };
              //Code to check value changed or not
              let changedarray = [];
              let sendtoapi = "change";
              if (this.state.customfontfamily === this.state.appfontfamily) {
                changedarray.push("same");
              } else {
                changedarray.push("change");
              }
              if (this.state.customlogoalign === this.state.logo_align) {
                changedarray.push("same");
              } else {
                changedarray.push("change");
              }

              if (this.state.customlogotext === this.state.applogotext) {
                changedarray.push("same");
              } else {
                changedarray.push("change");
              }
              if (this.state.customlogocolor === this.state.applogocolor) {
                changedarray.push("same");
              } else {
                changedarray.push("change");
              }
              if (this.state.customfontsize === this.state.appfontsize) {
                changedarray.push("same");
              } else {
                changedarray.push("change");
              }

              if (this.state.customlogo === "") {
                sendtoapi = "change";
              } else {
                if (changedarray.indexOf("change") === -1) {
                  sendtoapi = "same";
                } else {
                  sendtoapi = "change";
                }
              }

              //Code to check value changed or not
              Customlogoupload(
                params,
                sendtoapi,
                this.state.customlogo,
                this.state.applogotext.trim(),
                this.state.applogocolor,
                this,
                () => {
              
                  const params = {
                    app_id: localStorage.getItem("Appid"),
                    bgcolor:
                      this.state.splashBackground === undefined
                        ? ""
                        : this.state.splashBackground,
                    bgimg:
                      this.state.splashBackground !== ""
                        ? this.state.selectpixabayimage.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                         + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,"")
                        : this.state.chooseImage === null
                        ? ""
                        : this.state.chooseImage.indexOf("pixabay") === -1
                        ? this.state.uploadcustomdefaultimageApi.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                        + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,"")
                        : this.state.selectpixabayimage.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                        + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,""),
                    low_resolution_image:
                      this.state.splashBackground !== ""
                        ? this.state.selectpixabayimage.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                        + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,"")
                        : this.state.chooseImage === null
                        ? ""
                        : this.state.chooseImage.indexOf("pixabay") === -1
                        ? this.state.uploadcustomdefaultimageApi.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                        + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,"")
                        : this.state.low_resolution_image.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                        + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,""),
                    original_bg_img:
                      this.state.splashBackground !== ""
                        ? this.state.selectpixabayimage.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                        + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,"")
                        : this.state.chooseImage === null
                        ? ""
                        : this.state.chooseImage.indexOf("pixabay") === -1
                        ? this.state.original_bgimg.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                        + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,"")
                        : this.state.selectpixabayimage.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                        + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,""),

                    //Applogo
                    logo_text:
                      this.state.activeTab === "1"
                        ? this.state.applogotext.trim()
                        : "",
                    logo_text_color:
                      this.state.activeTab === "1"
                        ? this.state.applogocolor
                        : "",
                    logo_text_font:
                      this.state.activeTab === "1"
                        ? this.state.appfontfamily.indexOf("hitmo") > -1
                          ? this.state.appfontfamily.slice(0, 6) +
                            "." +
                            this.state.appfontfamily.slice(6)
                          : this.state.appfontfamily
                        : "",
                    logo_text_fontsize:
                      this.state.activeTab === "1"
                        ? this.state.appfontsize
                        : "",
                    app_icon_image:
                      this.state.activeTab === "1"
                        ? this.state.app_icon_image
                        : this.state.iconImage.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" +
                        localStorage.getItem("Appid")+ process.env.REACT_APP_LoginLogo_Crop ,""),
                    original_app_icon:
                      this.state.activeTab === "1"
                        ? this.state.app_icon_image
                        : this.state.original_app_icon.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" +
                        localStorage.getItem("Appid")+ process.env.REACT_APP_LoginLogo_Crop ,""),
                    //Style
                    prcolor: this.state.primaryColor,
                    second_color: this.state.secondaryColor,
                    button_color: this.state.buttonColor,
                    button_textcolor: this.state.buttonTextColor,

                    //API requirement
                    checkimage: this.state.checkimage,
                    checkapp_logo: this.state.checkapp_logo,
                    opacity_percent:
                      this.state.tabselection === "and"
                        ? parseInt(this.state.range)
                        : "",
                    opacity_color:
                      this.state.tabselection === "and"
                        ? this.state.tintcolor
                        : "",

                    //welcome text
                    login_signup_screen_text: this.state.welcometext,
                    login_signup_screen_text_color: this.state.welcometextcolor,
                    login_signup_screen_text_font_family:
                      this.state.textfontfamily.indexOf("hitmo") > -1
                        ? this.state.textfontfamily.slice(0, 6) +
                          "." +
                          this.state.textfontfamily.slice(6)
                        : this.state.textfontfamily,
                    login_signup_screen_text_font_size: this.state.textfontsize,
                    social_icons_button_shape: this.state.social_shape,
                    login_screen_app_logo_alignment: this.state.logo_align,
                    website_technology: this.state.website_technology,
                  };
                  if (
                    params.bgcolor === "" &&
                    (params.bgimg === "" || params.bgimg === null)
                  ) {
                    errortoast("Select Background");

                    this.setState({
                      submitval: "Save",
                      loader: "d-none",
                      disabledsubmitbutton: false,
                    });
                  } else if(params.bgimg.includes("ams")&&!params.original_bg_img) {
                    this.setState({
                      submitval: "Save",
                      loader: "d-none",
                      disabledsubmitbutton: false,
                    });
                    errortoast("Please wait your image is processing...");
                  }
                  
                  else if(params.app_icon_image.includes("ams")&&!params.original_app_icon) {
                    this.setState({
                      submitval: "Save",
                      loader: "d-none",
                      disabledsubmitbutton: false,
                    });
                    errortoast("Please wait your image is processing...");
                  }

                  else{
                
                    LoginsignupAPI(params, this);
                   
                  }
                }
              );
            })
            .catch(function (error) {
              console.error("oops, something went wrong!", error);
            });
        } else {
          const params = {
            app_id: localStorage.getItem("Appid"),
            bgcolor:
              this.state.splashBackground === undefined
                ? ""
                : this.state.splashBackground,
                bgimg:
                this.state.splashBackground !== ""
                  ? this.state.selectpixabayimage.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                   + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,"")
                  : this.state.chooseImage === null
                  ? ""
                  : this.state.chooseImage.indexOf("pixabay") === -1
                  ? this.state.uploadcustomdefaultimageApi.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                  + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,"")
                  : this.state.selectpixabayimage.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                  + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,""),
              low_resolution_image:
                this.state.splashBackground !== ""
                  ? this.state.selectpixabayimage.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                  + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,"")
                  : this.state.chooseImage === null
                  ? ""
                  : this.state.chooseImage.indexOf("pixabay") === -1
                  ? this.state.uploadcustomdefaultimageApi.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                  + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,"")
                  : this.state.low_resolution_image.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                  + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,""),
              original_bg_img:
                this.state.splashBackground !== ""
                  ? this.state.selectpixabayimage.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                  + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,"")
                  : this.state.chooseImage === null
                  ? ""
                  : this.state.chooseImage.indexOf("pixabay") === -1
                  ? this.state.original_bgimg.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                  + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,"")
                  : this.state.selectpixabayimage.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"
                  + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop,""),
            //Applogo
            logo_text:
              this.state.activeTab === "1" ? this.state.applogotext.trim() : "",
            logo_text_color:
              this.state.activeTab === "1" ? this.state.applogocolor : "",
            logo_text_font:
              this.state.activeTab === "1"
                ? this.state.appfontfamily.indexOf("hitmo") > -1
                  ? this.state.appfontfamily.slice(0, 6) +
                    "." +
                    this.state.appfontfamily.slice(6)
                  : this.state.appfontfamily
                : "",
            logo_text_fontsize:
              this.state.activeTab === "1" ? this.state.appfontsize : "",
            app_icon_image:
              this.state.activeTab === "1"
                ? this.state.app_icon_image
                : this.state.iconImage.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" +
                localStorage.getItem("Appid")+ process.env.REACT_APP_LoginLogo_Crop ,""),
            original_app_icon:
              this.state.activeTab === "1"
                ? this.state.app_icon_image
                : this.state.original_app_icon.replace(process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" +
                localStorage.getItem("Appid")+ process.env.REACT_APP_LoginLogo_Crop ,""),
            //Style
            prcolor: this.state.primaryColor,
            second_color: this.state.secondaryColor,
            button_color: this.state.buttonColor,
            button_textcolor: this.state.buttonTextColor,
            //Tint
            opacity_percent:
              this.state.tabselection === "and"
                ? parseInt(this.state.range)
                : "",
            opacity_color:
              this.state.tabselection === "and" ? this.state.tintcolor : "",
            //API requirement
            checkimage: this.state.checkimage,
            checkapp_logo: this.state.checkapp_logo,

            //welcome text
            login_signup_screen_text: this.state.welcometext,
            login_signup_screen_text_color: this.state.welcometextcolor,
            login_signup_screen_text_font_family:
              this.state.textfontfamily.indexOf("hitmo") > -1
                ? this.state.textfontfamily.slice(0, 6) +
                  "." +
                  this.state.textfontfamily.slice(6)
                : this.state.textfontfamily,
            login_signup_screen_text_font_size: this.state.textfontsize,
            social_icons_button_shape: this.state.social_shape,
            login_screen_app_logo_alignment: this.state.logo_align,
          };
          if (
            params.bgcolor === "" &&
            (params.bgimg === "" || params.bgimg === null)
          ) {
            errortoast("Select Background");
            this.setState({
              disabledsubmitbutton: false,
            });
          }  else if(params.bgimg.includes("ams")&&!params.original_bg_img) {
            errortoast("Please wait your image is processing...");
            this.setState({
              disabledsubmitbutton: false,
            });
          }
          
          else if(params.app_icon_image.includes("ams")&&!params.original_app_icon) {
            errortoast("Please wait your image is processing...");
            this.setState({
              disabledsubmitbutton: false,
            });
          }

          else{
        
            LoginsignupAPI(params, this);
           
          }
        }
      }
    );
  };
  handleselection = (e) => {
    if (e === "or") {
      this.setState({
        oractive: "active_tab",
        andactive: "not_active_tab",
        tabselection: "or",
      });
      this.sheet.innerHTML =
        ".backselect::before {background:none;opacity:" + 0 + "%}";
      document.body.appendChild(this.sheet);
    } else {
      if (
        this.state.selectpixabayimage !== "" &&
        this.state.selectpixabayimage !== null
      ) {
        this.setState({
          oractive: "or_not_active_tab",
          andactive: "active_tab",
          tabselection: "and",
        });
        this.sheet.innerHTML =
          ".backselect::before {background:" +
          this.state.tintcolor +
          ";opacity:" +
          this.state.range +
          "%}";
        document.body.appendChild(this.sheet);
      } else {
        errortoast("Please Select Background Image");
      }
    }
  };
  tintcolor = (e) => {
    this.setState({
      tintcolor: e,
    });
    this.sheet.innerHTML =
      ".backselect::before {background:" +
      e +
      ";opacity:" +
      this.state.range +
      "%}";
    document.body.appendChild(this.sheet);
  };
  handlerange = (e) => {
    this.setState({
      range: e.target.value,
    });
    this.sheet.innerHTML =
      ".backselect::before {background:" +
      this.state.tintcolor +
      ";opacity:" +
      e.target.value +
      "%}";
    document.body.appendChild(this.sheet);
  };

  componentWillUnmount() {
    clearInterval(this.countdown);
  }
  handlesocialshape = (e) => {
    this.setState({
      social_shape: e,
    });
  };
  handlelogoalign = (e) => {
    this.setState({
      logo_align: e,
    });
  };
  logoalign = (align) => {
    if (align === "left") return "flex-start";
    if (align === "center") return "center";
    if (align === "right") return "flex-end";
  };

  // NEw Image Upload Pop up integration

  settingYourdata=(imgName,from)=>{
  
    if(from==="resize"|| from==="apiImg"){
      this.setState({
        uploadcustomdefaultimageApi: process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop + imgName,
        low_resolution_image : process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop + imgName,
        selectpixabayimage : process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop + imgName,
        chooseImage : process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop + imgName,
        imagename: imgName,
        selectbackgroundorcolor: false,
        splashBackground: "",
        original_bgimg:"", 
       })
      }
     else{
      this.setState({
      original_bgimg: process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop +imgName, 
      })
    }
  }
  settingYourAppLogodata=(imgName,from)=>{
    if(from==="resize"|| from==="apiImg"){
         this.setState({
          applogodefaultimage: process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" +
          localStorage.getItem("Appid")+ process.env.REACT_APP_LoginLogo_Crop + imgName,
          iconImage: process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" +
          localStorage.getItem("Appid")+ process.env.REACT_APP_LoginLogo_Crop + imgName,
          checkApplogo: true,
          getlogoimage: true,
          uploadtext: "Click here to upload an image.",
          original_app_icon: "",
       })
    }else{
    this.setState({
      original_app_icon: imgName,
    })
    }
  }
  
  yourErrFun=()=>{

  }

  yourErrFunForAppLogo=()=>{
    
  }


  uploadOrgImgFrom3rdParty=(orgImgName)=>{
    this.setState({
      original_bgimg: process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/" + localStorage.getItem("Appid")+ process.env.REACT_APP_LoginBackground_Crop +orgImgName, 
    })
  }

  handleAppUploadimgPopUp=()=>{
    this.setState({
      isGalleryPopup:!this.state.isGalleryPopup
    })
  }

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }

  render() {
    const Toptip = (indexvalue) => {
      if (indexvalue === 0) {
        return (
          <div className="forgotscreen text-center d-flex align-items-center justify-content-end settophead setnewtophead">
            <svg width="36.151" height="21.088" viewBox="0 0 36.151 21.088">
              <g
                id="Group_6140"
                data-name="Group 6140"
                transform="translate(-1745.685 -254.912)"
              >
                <rect
                  id="Rectangle_4451"
                  data-name="Rectangle 4451"
                  width="36.152"
                  height="21.088"
                  rx="10.544"
                  transform="translate(1745.685 254.912)"
                  fill={this.state.secondaryborder}
                  // opacity="0.1"
                />
                <path
                  id="Union_315"
                  data-name="Union 315"
                  d="M3.766,3.766,0,7.531,3.766,3.766,0,0,3.766,3.766,7.532,0,3.766,3.766,7.532,7.531Z"
                  transform="translate(1760.371 262.067)"
                  fill="none"
                  stroke={this.state.secondaryColor}
                  strokeWidth="1.5"
                />
              </g>
            </svg>
          </div>
        );
      }
    };
    const isLoggedIn = () => {
      if (this.state.currentImageIndex === 0) {
        return CreateAccount(this);
        // if(this.state.consent_form_purchased === 1) { return ConsentForm(this) }else { return SocialLogin(this)}
      } else if (this.state.currentImageIndex === 1) {
        return ConsentForm(this);
        //if(this.state.consent_form_purchased === 1) { return SocialLogin(this) }else { return CreateAccount(this)}
      }
    };
    const showclass = (currentindex) => {
      if (currentindex === 0)
        return "d-flex pb-3 align-items-center justify-content-center movecustomlogo";
      if (currentindex === 1)
        return "d-flex pb-3 align-items-center justify-content-center";
    };


    return (
      <>
   
        <div className="d-md-block">
          <Header customclass={"header-responsive-new"} value={this} />
        </div>
        {/* Mobile View */}
        {/* Mobile View End*/}
        <section className="dasboard_page dasboard_page_style mobile-display">
        <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
          <div className="transparent_background transparent_background_style">
            <div className="right_panel pb-4 mb-md-4" style={{ minHeight: "80vh"}}>
              {this.state.spinner ? (
                <div className="newspinner">
              <svg xmlns="http://www.w3.org/2000/svg" 
        xlink="http://www.w3.org/1999/xlink" 
        style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
        width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>
          </animateTransform>
        </circle>
        </g>
        <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
        style={{animationplaystate: "running", animationdelay: "0s"}}>
        </animateTransform>
        </circle>
        </g>
        <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
        style={{animationplaystate: "running", animationdelay: "0s"}}>

        </animateTransform>
        </circle>
        </g>
        <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
        style={{animationplaystate: "running", animationdelay: "0s"}}>

        </animateTransform>
        </circle>
        </g>
        </svg>
            </div>
              ) : (
                <div
                className="right_general"
                style={{ opacity: this.state.spinner === true ? 0 : 1 }}
              >
                <div className="d-md-flex align-items-center justify-content-between ipad-margin-top top-portion ipad-portion-appearance ipad-special-appearance ipad-login-appearance">
                  <div className="d-none d-md-block">
                    <div className="app_iconname">
                      <div className="d-flex align-items-center">
                        <h3>Login &amp; Sign Up</h3>
                        {/* <Amsvideo
                          timespan={88}
                          addclass="d-md-flex d-none align-items-center justify-content-between see_video"
                        /> */}
                      </div>
                      <h5>
                        Design the login, sign up &amp; forgot password screens.{" "}
                        {/* <Media
                          href="https://www.appmysite.com/support/design-your-login-sign-up-screens/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn More{" "}
                          <img
                            src={
                              require("../../assets/images/dashboard/Goto.png")
                                .default
                            }
                            className="gotoappicon appear-spacing"
                            alt="Goto"
                          />
                        </Media> */}
                        {" "}
                      </h5>
                    </div>
                  </div>
                  <form onSubmit={this.loginsignupAPI}>
                    <div className="d-flex align-items-center justify-content-between fixed-button ">
                      <div className="w-100 custom-100button">
                        <MobileApppreview />
                      </div>
                      <button
                        className="next_btn w-100"
                        disabled={this.state.disabledsubmitbutton}
                      >
                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                          <div className={this.state.loader}>
                            <img src={this.state.loaderimage} alt="loader" />
                          </div>{" "}
                          <div>{this.state.submitval}</div>
                        </div>
                      </button>
                    </div>
                  </form>
                </div>
                <div id="imagetag"></div>
                <div className="d-flex justify-content-between">
                  <div className="customiseappfeatures">
                    {/* Mobile View */}

                    <form
                      onSubmit={this.loginsignupAPI}
                      className="form-login-padding"
                    >
                      {/* Background Section Starts */}
                      <div className="borbot">
                        <div className="d-md-flex align-items-center justify-content-between on-off mb-2 mobspace ipad-top-margin-zero ipad-top-margin-red ipad-login-background  mt-2">
                          {/* Tool tip */}
                          <Tooltip
                            firsttag={"h5"}
                            tagclass={"mb-0 mr-2"}
                            classdata={
                              "module_title justify-content-start align-items-center d-flex"
                            }
                            customclass={"mobviewspacing"}
                            heading={"Background Image"}
                            content={"Set background image for screen design."}
                          />
                          {/* Tool tip */}
                        </div>
                        <div className="upload_files d-flex align-items-center justify-content-between flex-wrap mt-4 mt-md-0 mobile-login-padding ipad-login-padding">
                          <div className="customwidth mb-3 mb-md-0">
                            {/* Tool tip */}
                            <Tooltip
                              firsttag={"h4"}
                              tagclass={"mb-0 mr-2"}
                              classdata={
                                "d-flex justify-content-start align-items-center mb-1"
                              }
                              heading={"Select background image"}
                              content={
                                "Upload background image for screen design."
                              }
                            />
                            {/* Tool tip */}
                            <div className="large-up-back">
                              <label>
                                <div className="up" onClick={this.toggle}>
                                  {this.state.selectpixabayimage === "" ||
                                  this.state.selectpixabayimage === null ? (
                                    <div className="d-flex align-items-center">
                                      <div className="mr-2">
                                        <div
                                          className="selectpixabayimage"
                                          style={{
                                            background:
                                              "url(" +
                                              require("../../assets/images/dashboard/small-up.png")
                                                .default +
                                              ")",
                                          }}
                                        ></div>
                                      </div>
                                      <div>
                                        <p
                                          className="m-0 p-0"
                                          style={{
                                            fontSize: "14px",
                                            color: "#C8CEDB",
                                          }}
                                        >
                                          Minimum size 1242*2208px
                                        </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="d-flex align-items-center">
                                      <div className="mr-2">
                                        <div
                                          className="selectpixabayimage"
                                          style={{
                                            background:
                                              "url(" +
                                              this.state.low_resolution_image +
                                              ")",
                                          }}
                                        >
                                          </div>
                                      </div>
                                      <div className="upload-long-text">
                                        <p
                                          className="m-0 p-0"
                                          style={{
                                            fontSize: "11px",
                                            color: "#1B2952",
                                            width: "250px",
                                          }}
                                        >
                                          {this.state.imagename}
                                        </p>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </label>
                            </div>
                            {this.state.spinner === false &&
                              this.state.selectpixabayimage !== "" &&
                              this.state.selectpixabayimage !== null && (
                                <div className="green_checkmark">
                                  <label className="container1">
                                    <input type="checkbox" disabled checked />{" "}
                                    <span className="checkmark"></span>
                                  </label>
                                </div>
                              )}
                          </div>
                          <div className="customwidthor d-flex justify-content-center align-items-center">
                            <div onClick={(e) => this.handleselection("or")}>
                              <p className={this.state.oractive}>or</p>
                            </div>
                            <div onClick={(e) => this.handleselection("and")}>
                              <p className={this.state.andactive}>and</p>
                            </div>
                          </div>
                          {this.state.tabselection === "or" ? (
                            <div className="customwidth mb-md-0 mb-3">
                              {/* Tool tip */}
                              <Tooltip
                                firsttag={"h4"}
                                tagclass={"mb-0 mr-2"}
                                classdata={
                                  "d-flex justify-content-start align-items-center mb-1"
                                }
                                heading={"Select background color"}
                                content={
                                  "Select background color for screen design."
                                }
                              />
                              {/* Tool tip */}

                              <Reactcolorpicker
                                colorname={this.state.splashBackground}
                                presetColors={this.state.presetColors}
                                onChange={this.changecolor}
                                classselection={"fixback"}
                                valuecontrol={false}
                              />
                            </div>
                          ) : (
                            <div className="customwidth mb-3 mb-md-0">
                              <Tooltip
                                firsttag={"h4"}
                                tagclass={"mb-0 mr-2"}
                                classdata={
                                  "d-flex justify-content-start align-items-center mb-1"
                                }
                                heading={"Select tint color"}
                                content={"Select tint color for screen design."}
                              />
                              <Reactcolorpicker
                                colorname={this.state.tintcolor}
                                presetColors={this.state.presetColors}
                                onChange={this.tintcolor}
                                classselection={"fixback"}
                                valuecontrol={false}
                              />
                            </div>
                          )}
                          {/*
                          {this.state.tabselection === "and" && (
                            <div
                              className="slidecontainer customwidth"
                              style={{ height: "auto" }}
                            >
                              <input
                                type="range"
                                min="0"
                                max="100"
                                className="slidersplash"
                                id="myRange"
                                value={this.state.range}
                                onChange={(e) => this.handlerange(e)}
                              />
                               <div className="d-flex w-100 align-items-center justify-content-between">
                                <p className="mb-0">Image Opacity:</p>
                                <p className="mb-0">
                                  <span id="demo">{this.state.range}</span>%
                                </p>
                              
                              </div>
                            </div>
                            
                          )}
                          */}
                        </div>
                      </div>
                      {/* Background Section Ends */}
                      {/* Logo Section Starts */}
                      <div className="d-flex align-items-center justify-content-between mt-4 mt-md-4 app-logo ipad-custom-padding ipad-custom-padding-red ipad-login-logo">
                        <div className="app-name-padding app-name-padding-red pl-3 pr-3 p-md-0">
                          <div className="">
                            {/* Tool tip */}
                            <Tooltip
                              firsttag={"h5"}
                              tagclass={"mb-0 mr-2"}
                              classdata={
                                "module_title d-flex justify-content-start align-items-center"
                              }
                              heading={"Logo"}
                              content={
                                "Design or upload logo for screen design."
                              }
                            />
                            {/* Tool tip */}
                          </div>
                        </div>
                        <div className="d-md-flex d-block align-items-center justify-content-between">
                          <div className="two-btn-tabs">
                            <div className="text-center">
                              <Nav
                                tabs
                                className="nav  nav-justified createtabs"
                              >
                                <NavItem>
                                  <NavLink
                                    className={classnames({
                                      active: this.state.activeTab === "1",
                                    })}
                                    onClick={() => {
                                      this.tabtoggle("1");
                                    }}
                                  >
                                    {" "}
                                    Create
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    className={classnames({
                                      active: this.state.activeTab === "2",
                                    })}
                                    onClick={() => {
                                      this.tabtoggle("2");
                                    }}
                                  >
                                    {" "}
                                    Upload
                                  </NavLink>
                                </NavItem>
                              </Nav>
                            </div>
                          </div>
                        </div>
                      </div>
                      <TabContent
                        activeTab={this.state.activeTab}
                        className="pb-0"
                      >
                        <TabPane tabId="2">
                          <div className="upload_files mt-4 dash-border dash-border-red">
                            <label htmlFor="getupload" className="w-100  pt-4">
                        
                                <div className="up" onClick={(e)=>this.handleAppUploadimgPopUp(e)}>
                                  {/* <input
                                    type="file"
                                    onChange={(e) => {
                                      this.handleappImage(e);
                                      e.target.value = null;
                                    }}
                                    id="getupload"
                                    className="d-none"
                                  /> */}

                                <NewImageGalleryPopup
                                isGalleryPopup={this.state.isGalleryPopup}
                                setIsGalleryPopup={true}
                                settingYourdata={this.settingYourAppLogodata}
                                yourErrFun={this.yourErrFunForAppLogo}
                                uploadPath={
                                 localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid")+
                                 process.env.REACT_APP_LoginLogo_Crop
                                }
                                settingOrgImgFrom3rdParty={false}
                                hideImageGallery={true}
                                widthForRestriction={500}
                                heightForRestriction={233}
                                aspectRatio={"500:233"}
                                classCompoState={this}
                             
                              />

                                  {this.state.uploadtext !== "" && (
                                    <div className="h-100 w-100 d-flex align-items-center justify-content-center mb-3">
                                      <img
                                        src={this.state.applogodefaultimage}
                                        alt="logoimage"
                                        style={{ width: "230px" }}
                                      />
                                    </div>
                                  )}
                                  <p
                                    style={{
                                      color: "rgb(119, 130, 161)",
                                      display: "block",
                                      textAlign: "center",
                                      marginTop: "10px",
                                      fontSize: "18px",
                                    }}
                                    className="mb-1"
                                  >
                                    {this.state.uploadtext}
                                  </p>

                                  <div
                                    className={
                                      this.state.uploadtext === ""
                                        ? "h-100 w-100 d-flex align-items-center justify-content-center mt-4"
                                        : "h-100 w-100 d-flex align-items-center justify-content-center "
                                    }
                                  >
                                    <div className="text-center generallogo">
                                      {this.state.uploadtext === "" && (
                                        <>
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/cloud.png")
                                                .default
                                            }
                                            alt="cloud"
                                            className="img-fluid"
                                          />
                                          <h6>Upload app logo</h6>
                                        </>
                                      )}
                                      <p>Minimum size 500*233px</p>
                                    </div>
                                  </div>
                                  {this.state.uploadtext !== "" && (
                                    <div className="text-center">
                                      <Media
                                        href={this.state.original_logo}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        tag="a"
                                        className="text-center d-block original_img img-overlay-mobile"
                                      >
                                        See original image{" "}
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/Goto.png")
                                              .default
                                          }
                                          className="gotoappicon gotospacing ml-1"
                                          alt="Goto"
                                        />
                                      </Media>
                                    </div>
                                  )}
                                </div>
                       
                           
                                 {/* <Imagecrop
                                  whenTogglemodal={this.whenAppTogglemodal}
                                  imagename={this.state.applogodefaultimage}
                                  originalpath={
                                    process.env.REACT_APP_LoginLogo_Original
                                  }
                                  imagepath={
                                    process.env.REACT_APP_LoginLogo_Crop
                                  }
                                  cropwidth={2.15}
                                  cropheight={1}
                                  finalcrop={this.finallogocrop}
                                  modal={true}
                                /> */}

                       
                               
                            </label>
                          </div>
                        </TabPane>
                        <TabPane tabId="1">
                          <div className="upload_files d-flex align-items-center justify-content-between flex-wrap mt-4 mt-md-2 ipad-login-padding">
                            <div className="color_picker mb-4">
                              <div className="form-group">
                                {/* Tool tip */}
                                <Tooltip
                                  firsttag={"h4"}
                                  tagclass={"mb-0 mr-2"}
                                  classdata={
                                    "d-flex justify-content-start align-items-center mb-1"
                                  }
                                  heading={"Logo Text"}
                                  content={
                                    "This text will display on the screen. Must be less than 15 characters."
                                  }
                                />
                                {/* Tool tip */}
                                <input
                                  type="text"
                                  maxLength="15"
                                  className="form-control form-name"
                                  value={this.state.applogotext}
                                  onChange={(e) => this.handlelogotext(e)}
                                />
                              </div>
                            </div>
                            <div className="color_picker mb-4">
                              <Tooltip
                                firsttag={"h4"}
                                tagclass={"mb-0 mr-2"}
                                classdata={
                                  "d-flex justify-content-start align-items-center mb-1"
                                }
                                heading={"Select Logo Color"}
                                content={"Select color for your logo."}
                              />

                              <Reactcolorpicker
                                colorname={this.state.applogocolor}
                                presetColors={this.state.presetColors}
                                onChange={this.handlelogocolor}
                                classselection={"fixback"}
                                valuecontrol={true}
                              />
                            </div>
                            <div className="color_picker mb-md-0 mb-4">
                              {/* Tool tip */}
                              <Tooltip
                                firsttag={"h4"}
                                tagclass={"mb-0 mr-2"}
                                classdata={
                                  "d-flex justify-content-start align-items-center mb-1"
                                }
                                heading={"Font"}
                                content={"Select font for app logo."}
                              />
                              {/* Tool tip */}
                              <div className="form-group">
                                <DropdownButton
                                  className="font-dropdown"
                                  alignRight
                                  title={this.state.appfontfamily}
                                  id="dropdown-menu-align-right"
                                  style={{
                                    fontFamily: this.state.appfontfamily,
                                  }}
                                  onSelect={(e) => this.handlelogofontfamily(e)}
                                  value={this.state.appfontfamily}
                                >
                                  <Dropdown.Item
                                    style={{
                                      fontFamily: "Arial",
                                      background:
                                        this.state.appfontfamily == "Arial"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily == "Arial"
                                          ? "white"
                                          : "",
                                    }}
                                    eventKey="Arial"
                                  >
                                    Arial
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    style={{
                                      fontFamily: "Arial-Black",
                                      background:
                                        this.state.appfontfamily ==
                                        "Arial-Black"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "Arial-Black"
                                          ? "white"
                                          : "",
                                    }}
                                    eventKey="Arial-Black"
                                  >
                                    Arial Black
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    style={{
                                      fontFamily: "bainsley-regular",
                                      background:
                                        this.state.appfontfamily ==
                                        "bainsley-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "bainsley-regular"
                                          ? "white"
                                          : "",
                                    }}
                                    eventKey="bainsley-regular"
                                  >
                                    Bainsley-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    style={{
                                      fontFamily: "bainsley-bold",
                                      background:
                                        this.state.appfontfamily ==
                                        "bainsley-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "bainsley-bold"
                                          ? "white"
                                          : "",
                                    }}
                                    eventKey="bainsley-bold"
                                  >
                                    Bainsley Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    style={{
                                      fontFamily: "barracuda-regular",
                                      background:
                                        this.state.appfontfamily ==
                                        "barracuda-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "barracuda-regular"
                                          ? "white"
                                          : "",
                                    }}
                                    eventKey="barracuda-regular"
                                  >
                                    Barracuda Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    style={{
                                      fontFamily: "barracuda-bold",
                                      background:
                                        this.state.appfontfamily ==
                                        "barracuda-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "barracuda-bold"
                                          ? "white"
                                          : "",
                                    }}
                                    eventKey="barracuda-bold"
                                  >
                                    Barracuda Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="betelgeuse-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "betelgeuse-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "betelgeuse-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "betelgeuse-regular",
                                    }}
                                  >
                                    {" "}
                                    Betelgeuse-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="betelgeuse-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "betelgeuse-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "betelgeuse-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "betelgeuse-bold",
                                    }}
                                  >
                                    {" "}
                                    Betelgeuse-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="Bookman"
                                    style={{
                                      background:
                                        this.state.appfontfamily == "Bookman"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily == "Bookman"
                                          ? "white"
                                          : "",
                                      fontFamily: "Bookman",
                                    }}
                                  >
                                    {" "}
                                    Bookman
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="cactron-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "cactron-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "cactron-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "cactron-regular",
                                    }}
                                  >
                                    {" "}
                                    Cactron-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="cactronbold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "cactronbold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "cactronbold"
                                          ? "white"
                                          : "",
                                      fontFamily: "cactronbold",
                                    }}
                                  >
                                    {" "}
                                    Cactron-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="cokanut-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "cokanut-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "cokanut-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "cokanut-regular",
                                    }}
                                  >
                                    {" "}
                                    Cokanut-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="cokanut-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "cokanut-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "cokanut-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "cokanut-bold",
                                    }}
                                  >
                                    {" "}
                                    Cokanut-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="ComicSansMS"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "ComicSansMS"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "ComicSansMS"
                                          ? "white"
                                          : "",
                                      fontFamily: "ComicSansMS",
                                    }}
                                  >
                                    {" "}
                                    Comic Sans MS
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="cormorant-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "cormorant-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "cormorant-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "cormorant-regular",
                                    }}
                                  >
                                    {" "}
                                    Cormorant-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="cormorant-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "cormorant-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "cormorant-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "cormorant-bold",
                                    }}
                                  >
                                    {" "}
                                    Cormorant-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="Courier-New"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "Courier-New"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "Courier-New"
                                          ? "white"
                                          : "",
                                      fontFamily: "Courier-New",
                                    }}
                                  >
                                    {" "}
                                    Courier New
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="dukascf-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "dukascf-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "dukascf-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "dukascf-regular",
                                    }}
                                  >
                                    {" "}
                                    Dukas Cf-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="dukascf-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "dukascf-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "dukascf-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "dukascf-bold",
                                    }}
                                  >
                                    {" "}
                                    Dukas Cf-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="emberly-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "emberly-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "emberly-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "emberly-regular",
                                    }}
                                  >
                                    {" "}
                                    Emberly-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="emberly-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "emberly-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "emberly-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "emberly-bold",
                                    }}
                                  >
                                    {" "}
                                    Emberly-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="epigrafica-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "epigrafica-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "epigrafica-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "epigrafica-regular",
                                    }}
                                  >
                                    {" "}
                                    Epigrafica-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="epigrafica-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "epigrafica-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "epigrafica-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "epigrafica-bold",
                                    }}
                                  >
                                    {" "}
                                    Epigrafica-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="Garamond"
                                    style={{
                                      background:
                                        this.state.appfontfamily == "Garamond"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily == "Garamond"
                                          ? "white"
                                          : "",
                                      fontFamily: "Garamond",
                                    }}
                                  >
                                    {" "}
                                    Garamond
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="Georgia"
                                    style={{
                                      background:
                                        this.state.appfontfamily == "Georgia"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily == "Georgia"
                                          ? "white"
                                          : "",
                                      fontFamily: "Georgia",
                                    }}
                                  >
                                    {" "}
                                    Georgia
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="grenze-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "grenze-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "grenze-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "grenze-regular",
                                    }}
                                  >
                                    {" "}
                                    Grenze-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="grenze-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "grenze-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "grenze-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "grenze-bold",
                                    }}
                                  >
                                    {" "}
                                    Grenze-Bold
                                  </Dropdown.Item>
                                  {/* <Dropdown.Item
                                    eventKey="hitmo20-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "hitmo20-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "hitmo20-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "hitmo20-regular",
                                    }}
                                  >
                                    {" "}
                                    Hitmo-Regular
                                  </Dropdown.Item> */}

                                  {/* <Dropdown.Item
                                    eventKey="hitmo20-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "hitmo20-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "hitmo20-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "hitmo20-bold",
                                    }}
                                  >
                                    Hitmo-Bold
                                  </Dropdown.Item> */}
                                  <Dropdown.Item
                                    eventKey="horizon-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "horizon-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "horizon-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "horizon-regular",
                                    }}
                                  >
                                    {" "}
                                    Horizon Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="horizon-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "horizon-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "horizon-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "horizon-bold",
                                    }}
                                  >
                                    {" "}
                                    Horizon Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="Impact"
                                    style={{
                                      background:
                                        this.state.appfontfamily == "Impact"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily == "Impact"
                                          ? "white"
                                          : "",
                                      fontFamily: "Impact",
                                    }}
                                  >
                                    {" "}
                                    Impact
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="karantina-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "karantina-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "karantina-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "karantina-regular",
                                    }}
                                  >
                                    {" "}
                                    Karantina-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="karantina-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "karantina-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "karantina-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "karantina-bold",
                                    }}
                                  >
                                    {" "}
                                    Karantina-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="kubanpro-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "kubanpro-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "kubanpro-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "kubanpro-regular",
                                    }}
                                  >
                                    {" "}
                                    Kuban Pro Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="kubanpro-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "kubanpro-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "kubanpro-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "kubanpro-bold",
                                    }}
                                  >
                                    {" "}
                                    Kuban Pro Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="kufam-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "kufam-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "kufam-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "kufam-regular",
                                    }}
                                  >
                                    {" "}
                                    Kufam-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="kufam-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily == "kufam-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily == "kufam-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "kufam-bold",
                                    }}
                                  >
                                    {" "}
                                    Kufam-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="mangoGrotesque-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "mangoGrotesque-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "mangoGrotesque-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "mangoGrotesque-regular",
                                    }}
                                  >
                                    {" "}
                                    MangoGrotesque-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="mangoGrotesque-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "mangoGrotesque-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "mangoGrotesque-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "mangoGrotesque-bold",
                                    }}
                                  >
                                    {" "}
                                    MangoGrotesque-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="mesheddisplay-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "mesheddisplay-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "mesheddisplay-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "mesheddisplay-regular",
                                    }}
                                  >
                                    {" "}
                                    Meshed Display-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="mesheddisplay-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "mesheddisplay-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "mesheddisplay-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "mesheddisplay-bold",
                                    }}
                                  >
                                    {" "}
                                    Meshed Display-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="nymeria-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "nymeria-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "nymeria-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "nymeria-regular",
                                    }}
                                  >
                                    {" "}
                                    Nymeria-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="nymeria-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "nymeria-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "nymeria-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "nymeria-bold",
                                    }}
                                  >
                                    {" "}
                                    Nymeria-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="Palatino"
                                    style={{
                                      background:
                                        this.state.appfontfamily == "Palatino"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily == "Palatino"
                                          ? "white"
                                          : "",
                                      fontFamily: "Palatino",
                                    }}
                                  >
                                    {" "}
                                    Palatino
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="piazzolla-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "piazzolla-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "piazzolla-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "piazzolla-regular",
                                    }}
                                  >
                                    {" "}
                                    Piazzolla-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="piazzolla-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "piazzolla-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "piazzolla-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "piazzolla-bold",
                                    }}
                                  >
                                    {" "}
                                    Piazzolla-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="Poppins-SemiBold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "Poppins-SemiBold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "Poppins-SemiBold"
                                          ? "white"
                                          : "",
                                      fontFamily: "Poppins-SemiBold",
                                    }}
                                  >
                                    {" "}
                                    Poppins SemiBold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="radiocanada-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "radiocanada-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "radiocanada-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "radiocanada-regular",
                                    }}
                                  >
                                    {" "}
                                    Radio Canada-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="radiocanada-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "radiocanada-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "radiocanada-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "radiocanada-bold",
                                    }}
                                  >
                                    {" "}
                                    Radio Canada-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="rebeqa-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "rebeqa-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "rebeqa-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "rebeqa-regular",
                                    }}
                                  >
                                    {" "}
                                    Rebeqa Font-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="rebeqa-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "rebeqa-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "rebeqa-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "rebeqa-bold",
                                    }}
                                  >
                                    {" "}
                                    Rebeqa Font-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="rogerex-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "rogerex-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "rogerex-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "rogerex-regular",
                                    }}
                                  >
                                    {" "}
                                    Rogerex-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="rogerex-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "rogerex-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "rogerex-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "rogerex-bold",
                                    }}
                                  >
                                    {" "}
                                    Rogerex-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="RozinaV04Regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "RozinaV04Regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "RozinaV04Regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "RozinaV04Regular",
                                    }}
                                  >
                                    {" "}
                                    Rozina-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="RozinaV06Bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "RozinaV06Bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "RozinaV06Bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "RozinaV06Bold",
                                    }}
                                  >
                                    {" "}
                                    Rozina-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="Sans-Serif"
                                    style={{
                                      background:
                                        this.state.appfontfamily == "Sans-Serif"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily == "Sans-Serif"
                                          ? "white"
                                          : "",
                                      fontFamily: "Sans-Serif",
                                    }}
                                  >
                                    {" "}
                                    Sans Serif
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="TimesNewRomanPSMT"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "TimesNewRomanPSMT"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "TimesNewRomanPSMT"
                                          ? "white"
                                          : "",
                                      fontFamily: "TimesNewRomanPSMT",
                                    }}
                                  >
                                    {" "}
                                    Times New Roman
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="Trebuchet-MS"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "Trebuchet-MS"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "Trebuchet-MS"
                                          ? "white"
                                          : "",
                                      fontFamily: "Trebuchet-MS",
                                    }}
                                  >
                                    {" "}
                                    Trebuchet MS
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="unbounded-regular"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "unbounded-regular"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "unbounded-regular"
                                          ? "white"
                                          : "",
                                      fontFamily: "unbounded-regular",
                                    }}
                                  >
                                    {" "}
                                    Unbounded Polkadot-Regular
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="unbounded-bold"
                                    style={{
                                      background:
                                        this.state.appfontfamily ==
                                        "unbounded-bold"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily ==
                                        "unbounded-bold"
                                          ? "white"
                                          : "",
                                      fontFamily: "unbounded-bold",
                                    }}
                                  >
                                    {" "}
                                    Unbounded Polkadot-Bold
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    eventKey="Verdana"
                                    style={{
                                      background:
                                        this.state.appfontfamily == "Verdana"
                                          ? "#547df2"
                                          : "",
                                      color:
                                        this.state.appfontfamily == "Verdana"
                                          ? "white"
                                          : "",
                                      fontFamily: "Verdana",
                                    }}
                                  >
                                    {" "}
                                    Verdana
                                  </Dropdown.Item>
                                </DropdownButton>
                              </div>
                            </div>
                            <div className="color_picker ipad-margin-top mb-md-0 mb-3">
                              {/* Tool tip */}
                              <Tooltip
                                firsttag={"h4"}
                                tagclass={"mb-0 mr-2"}
                                classdata={
                                  "d-flex justify-content-start align-items-center mb-1"
                                }
                                heading={"Font Size"}
                                content={"Select font size for app logo."}
                              />
                              {/* Tool tip */}
                              <div className="form-group">
                                <select
                                  className="form-control"
                                  onChange={(e) => this.handlelogofontsize(e)}
                                  value={this.state.appfontsize}
                                >
                                  <option value="15px">15</option>
                                  <option value="16px">16</option>
                                  <option value="17px">17</option>
                                  <option value="18px">18</option>
                                  <option value="19px">19</option>
                                  <option value="20px">20</option>
                                  <option value="21px">21</option>
                                  <option value="22px">22</option>
                                  <option value="23px">23</option>
                                  <option value="24px">24</option>
                                  <option value="25px">25</option>
                                  <option value="26px">26</option>
                                  <option value="27px">27</option>
                                  <option value="28px">28</option>
                                  <option value="29px">29</option>
                                  <option value="30px">30</option>
                                </select>
                              </div>
                            </div>
                            <div className="color_picker mb-md-0 mb-4 mt-4">
                              {/* Tool tip */}
                              <Tooltip
                                firsttag={"h4"}
                                tagclass={"mb-0 mr-2"}
                                classdata={
                                  "d-flex justify-content-start align-items-center mb-1"
                                }
                                heading={"Align"}
                                content={"Align the logo"}
                              />
                              {/* Tool tip */}
                              <div className="horizontal-alignment d-flex align-items-center ">
                                <div
                                  onClick={(e) => this.handlelogoalign("left")}
                                  className="aligndiv align-center"
                                >
                                  <div className="d-flex align-items-center justify-content-center align">
                                    <svg
                                      width="14.063"
                                      height="12.045"
                                      viewBox="0 0 14.063 12.045"
                                    >
                                      <g
                                        id="Group_3709"
                                        data-name="Group 3709"
                                        transform="translate(0 0.75)"
                                      >
                                        <path
                                          id="Path_6061"
                                          data-name="Path 6061"
                                          d="M-14517.725-3751.961h14.061"
                                          transform="translate(14517.726 3751.961)"
                                          fill="none"
                                          stroke={
                                            this.state.logo_align === "left"
                                              ? "#7782A1"
                                              : "#bcc1ce"
                                          }
                                          strokeWidth="1.5"
                                        />
                                        <path
                                          id="Path_9224"
                                          data-name="Path 9224"
                                          d="M-14517.727-3751.961h9.844"
                                          transform="translate(14517.727 3755.476)"
                                          fill="none"
                                          stroke={
                                            this.state.logo_align === "left"
                                              ? "#7782A1"
                                              : "#bcc1ce"
                                          }
                                          strokeWidth="1.5"
                                        />
                                        <path
                                          id="Path_9225"
                                          data-name="Path 9225"
                                          d="M-14517.725-3751.961h14.061"
                                          transform="translate(14517.726 3758.991)"
                                          fill="none"
                                          stroke={
                                            this.state.logo_align === "left"
                                              ? "#7782A1"
                                              : "#bcc1ce"
                                          }
                                          strokeWidth="1.5"
                                        />
                                        <path
                                          id="Path_9226"
                                          data-name="Path 9226"
                                          d="M-14517.727-3751.961h9.844"
                                          transform="translate(14517.727 3762.507)"
                                          fill="none"
                                          stroke={
                                            this.state.logo_align === "left"
                                              ? "#7782A1"
                                              : "#bcc1ce"
                                          }
                                          strokeWidth="1.5"
                                        />
                                      </g>
                                    </svg>

                                    <p className="ml-2">Left</p>
                                  </div>
                                </div>
                                <div
                                  onClick={(e) =>
                                    this.handlelogoalign("center")
                                  }
                                  className="aligndiv"
                                >
                                  <div className="d-flex align-items-center justify-content-center align align-center">
                                    <svg
                                      width="14.41"
                                      height="12.045"
                                      viewBox="0 0 14.41 12.045"
                                    >
                                      <g
                                        id="Group_5432"
                                        data-name="Group 5432"
                                        transform="translate(0 0.75)"
                                      >
                                        <path
                                          id="Path_6061"
                                          data-name="Path 6061"
                                          d="M-14517.725-3751.961h14.061"
                                          transform="translate(14517.725 3751.961)"
                                          fill="none"
                                          stroke={
                                            this.state.logo_align === "center"
                                              ? "#7782A1"
                                              : "#bcc1ce"
                                          }
                                          strokeWidth="1.5"
                                        />
                                        <path
                                          id="Path_9224"
                                          data-name="Path 9224"
                                          d="M-14517.727-3751.961h9.844"
                                          transform="translate(14520.187 3755.476)"
                                          fill="none"
                                          stroke={
                                            this.state.logo_align === "center"
                                              ? "#7782A1"
                                              : "#bcc1ce"
                                          }
                                          strokeWidth="1.5"
                                        />
                                        <path
                                          id="Path_9225"
                                          data-name="Path 9225"
                                          d="M-14517.725-3751.961h14.061"
                                          transform="translate(14518.075 3758.991)"
                                          fill="none"
                                          stroke={
                                            this.state.logo_align === "center"
                                              ? "#7782A1"
                                              : "#bcc1ce"
                                          }
                                          strokeWidth="1.5"
                                        />
                                        <path
                                          id="Path_9226"
                                          data-name="Path 9226"
                                          d="M-14517.727-3751.961h9.844"
                                          transform="translate(14520.187 3762.507)"
                                          fill="none"
                                          stroke={
                                            this.state.logo_align === "center"
                                              ? "#7782A1"
                                              : "#bcc1ce"
                                          }
                                          strokeWidth="1.5"
                                        />
                                      </g>
                                    </svg>

                                    <p className="ml-2">Center</p>
                                  </div>
                                </div>
                                <div
                                  onClick={(e) => this.handlelogoalign("right")}
                                  className="aligndiv"
                                >
                                  <div
                                    className="d-flex align-items-center justify-content-center align align-center"
                                    style={{ borderRight: "0px" }}
                                  >
                                    <svg
                                      width="16.009"
                                      height="15.045"
                                      viewBox="0 0 16.009 15.045"
                                    >
                                      <g
                                        id="Group_26427"
                                        data-name="Group 26427"
                                        transform="translate(-0.023 0.748)"
                                      >
                                        <path
                                          id="Path_6061"
                                          data-name="Path 6061"
                                          d="M-14501.7-3751.96h-16"
                                          transform="translate(14517.724 3751.962)"
                                          fill="none"
                                          stroke={
                                            this.state.logo_align === "right"
                                              ? "#7782A1"
                                              : "#bcc1ce"
                                          }
                                          stroke-width="1.5"
                                        />
                                        <path
                                          id="Path_9224"
                                          data-name="Path 9224"
                                          d="M-14505.7-3751.96h-12"
                                          transform="translate(14521.732 3756.233)"
                                          fill="none"
                                          stroke={
                                            this.state.logo_align === "right"
                                              ? "#7782A1"
                                              : "#bcc1ce"
                                          }
                                          stroke-width="1.5"
                                        />
                                        <path
                                          id="Path_9225"
                                          data-name="Path 9225"
                                          d="M-14501.7-3751.96h-16"
                                          transform="translate(14517.724 3760.992)"
                                          fill="none"
                                          stroke={
                                            this.state.logo_align === "right"
                                              ? "#7782A1"
                                              : "#bcc1ce"
                                          }
                                          stroke-width="1.5"
                                        />
                                        <path
                                          id="Path_9226"
                                          data-name="Path 9226"
                                          d="M-14505.7-3751.96h-12"
                                          transform="translate(14521.732 3765.507)"
                                          fill="none"
                                          stroke={
                                            this.state.logo_align === "right"
                                              ? "#7782A1"
                                              : "#bcc1ce"
                                          }
                                          stroke-width="1.5"
                                        />
                                      </g>
                                    </svg>

                                    <p className="ml-2">Right</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </TabPane>
                      </TabContent>

                      {/* Logo Section Ends */}
                      {/* Text */}
                      <div className="d-block d-md-flex align-items-center justify-content-between mt-4 mt-md-4 app-logo ipad-custom-padding ipad-custom-padding-red ipad-login-welcome">
                        <div className="app-name-padding pl-3 pr-3 p-md-0">
                          <div className="">
                            {/* Tool tip */}
                            <Tooltip
                              firsttag={"h5"}
                              tagclass={"mb-0 mr-2"}
                              classdata={
                                "module_title d-flex justify-content-start align-items-center"
                              }
                              heading={"Welcome Text"}
                              content={""}
                            />

                            {/* Tool tip */}
                          </div>
                        </div>
                      </div>
                      <div className="upload_files upload_files_welcome d-flex align-items-center justify-content-between flex-wrap mt-4 mt-md-2">
                        <div className="color_picker mb-4">
                          <div className="form-group">
                            {/* Tool tip */}
                            <Tooltip
                              firsttag={"h4"}
                              tagclass={"mb-0 mr-2"}
                              classdata={
                                "d-flex justify-content-start align-items-center mb-1"
                              }
                              heading={"Welcome Text"}
                              content={
                                "This text will display on the screen. Must be less than 25 characters."
                              }
                            />
                            {/* Tool tip */}

                            <input
                              type="text"
                              maxLength="25"
                              className="form-control form-name"
                              id="welcometext"
                              value={this.state.welcometext}
                              onChange={(e) => this.handlewelcometext(e)}
                            />
                          </div>
                        </div>
                        <div className="color_picker mb-4">
                          <Tooltip
                            firsttag={"h4"}
                            tagclass={"mb-0 mr-2"}
                            classdata={
                              "d-flex justify-content-start align-items-center mb-1"
                            }
                            heading={"Text Color"}
                            content={"Select color for your welcome text."}
                          />
                          <Reactcolorpicker
                            colorname={this.state.welcometextcolor}
                            presetColors={this.state.presetColors}
                            onChange={(e) => this.handletextcolor(e)}
                            classselection={"fixback"}
                            valuecontrol={true}
                          />
                        </div>
                        <div className="color_picker mb-md-0 mb-4">
                          {/* Tool tip */}
                          <Tooltip
                            firsttag={"h4"}
                            tagclass={"mb-0 mr-2"}
                            classdata={
                              "d-flex justify-content-start align-items-center mb-1"
                            }
                            heading={"Font"}
                            content={"Select font for welcome text."}
                          />
                          {/* Tool tip */}
                          <div className="form-group">
                            <DropdownButton
                              className="font-dropdown"
                              alignRight
                              title={this.state.textfontfamily}
                              id="dropdown-menu-align-right"
                              style={{ fontFamily: this.state.textfontfamily }}
                              onSelect={(e) => this.handletextfontfamily(e)}
                              value={this.state.textfontfamily}
                            >
                              <Dropdown.Item
                                style={{
                                  fontFamily: "Arial",
                                  background:
                                    this.state.textfontfamily == "Arial"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "Arial"
                                      ? "white"
                                      : "",
                                }}
                                eventKey="Arial"
                              >
                                Arial
                              </Dropdown.Item>
                              <Dropdown.Item
                                style={{
                                  fontFamily: "Arial-Black",
                                  background:
                                    this.state.textfontfamily == "Arial-Black"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "Arial-Black"
                                      ? "white"
                                      : "",
                                }}
                                eventKey="Arial-Black"
                              >
                                Arial Black
                              </Dropdown.Item>
                              <Dropdown.Item
                                style={{
                                  fontFamily: "bainsley-regular",
                                  background:
                                    this.state.textfontfamily ==
                                    "bainsley-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "bainsley-regular"
                                      ? "white"
                                      : "",
                                }}
                                eventKey="bainsley-regular"
                              >
                                Bainsley-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                style={{
                                  fontFamily: "bainsley-bold",
                                  background:
                                    this.state.textfontfamily == "bainsley-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "bainsley-bold"
                                      ? "white"
                                      : "",
                                }}
                                eventKey="bainsley-bold"
                              >
                                Bainsley Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                style={{
                                  fontFamily: "barracuda-regular",
                                  background:
                                    this.state.textfontfamily ==
                                    "barracuda-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "barracuda-regular"
                                      ? "white"
                                      : "",
                                }}
                                eventKey="barracuda-regular"
                              >
                                Barracuda Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                style={{
                                  fontFamily: "barracuda-bold",
                                  background:
                                    this.state.textfontfamily ==
                                    "barracuda-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "barracuda-bold"
                                      ? "white"
                                      : "",
                                }}
                                eventKey="barracuda-bold"
                              >
                                Barracuda Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="betelgeuse-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "betelgeuse-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "betelgeuse-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "betelgeuse-regular",
                                }}
                              >
                                {" "}
                                Betelgeuse-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="betelgeuse-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "betelgeuse-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "betelgeuse-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "betelgeuse-bold",
                                }}
                              >
                                {" "}
                                Betelgeuse-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Bookman"
                                style={{
                                  background:
                                    this.state.textfontfamily == "Bookman"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "Bookman"
                                      ? "white"
                                      : "",
                                  fontFamily: "Bookman",
                                }}
                              >
                                {" "}
                                Bookman
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="cactron-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "cactron-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "cactron-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "cactron-regular",
                                }}
                              >
                                {" "}
                                Cactron-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="cactronbold"
                                style={{
                                  background:
                                    this.state.textfontfamily == "cactronbold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "cactronbold"
                                      ? "white"
                                      : "",
                                  fontFamily: "cactronbold",
                                }}
                              >
                                {" "}
                                Cactron-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="cokanut-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "cokanut-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "cokanut-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "cokanut-regular",
                                }}
                              >
                                {" "}
                                Cokanut-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="cokanut-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily == "cokanut-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "cokanut-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "cokanut-bold",
                                }}
                              >
                                {" "}
                                Cokanut-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="ComicSansMS"
                                style={{
                                  background:
                                    this.state.textfontfamily == "ComicSansMS"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "ComicSansMS"
                                      ? "white"
                                      : "",
                                  fontFamily: "ComicSansMS",
                                }}
                              >
                                {" "}
                                Comic Sans MS
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="cormorant-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "cormorant-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "cormorant-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "cormorant-regular",
                                }}
                              >
                                {" "}
                                Cormorant-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="cormorant-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "cormorant-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "cormorant-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "cormorant-bold",
                                }}
                              >
                                {" "}
                                Cormorant-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Courier-New"
                                style={{
                                  background:
                                    this.state.textfontfamily == "Courier-New"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "Courier-New"
                                      ? "white"
                                      : "",
                                  fontFamily: "Courier-New",
                                }}
                              >
                                {" "}
                                Courier New
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="dukascf-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "dukascf-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "dukascf-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "dukascf-regular",
                                }}
                              >
                                {" "}
                                Dukas Cf-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="dukascf-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily == "dukascf-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "dukascf-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "dukascf-bold",
                                }}
                              >
                                {" "}
                                Dukas Cf-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="emberly-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "emberly-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "emberly-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "emberly-regular",
                                }}
                              >
                                {" "}
                                Emberly-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="emberly-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily == "emberly-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "emberly-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "emberly-bold",
                                }}
                              >
                                {" "}
                                Emberly-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="epigrafica-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "epigrafica-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "epigrafica-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "epigrafica-regular",
                                }}
                              >
                                {" "}
                                Epigrafica-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="epigrafica-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "epigrafica-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "epigrafica-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "epigrafica-bold",
                                }}
                              >
                                {" "}
                                Epigrafica-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Garamond"
                                style={{
                                  background:
                                    this.state.textfontfamily == "Garamond"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "Garamond"
                                      ? "white"
                                      : "",
                                  fontFamily: "Garamond",
                                }}
                              >
                                {" "}
                                Garamond
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Georgia"
                                style={{
                                  background:
                                    this.state.textfontfamily == "Georgia"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "Georgia"
                                      ? "white"
                                      : "",
                                  fontFamily: "Georgia",
                                }}
                              >
                                {" "}
                                Georgia
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="grenze-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "grenze-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "grenze-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "grenze-regular",
                                }}
                              >
                                {" "}
                                Grenze-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="grenze-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily == "grenze-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "grenze-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "grenze-bold",
                                }}
                              >
                                {" "}
                                Grenze-Bold
                              </Dropdown.Item>
                              {/* <Dropdown.Item
                                eventKey="hitmo20-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "hitmo20-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "hitmo20-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "hitmo20-regular",
                                }}
                              >
                                {" "}
                                Hitmo-Regular
                              </Dropdown.Item> */}

                              {/* <Dropdown.Item
                                eventKey="hitmo20-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily == "hitmo20-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "hitmo20-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "hitmo20-bold",
                                }}
                              >
                                Hitmo-Bold
                              </Dropdown.Item> */}
                              <Dropdown.Item
                                eventKey="horizon-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "horizon-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "horizon-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "horizon-regular",
                                }}
                              >
                                {" "}
                                Horizon Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="horizon-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily == "horizon-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "horizon-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "horizon-bold",
                                }}
                              >
                                {" "}
                                Horizon Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Impact"
                                style={{
                                  background:
                                    this.state.textfontfamily == "Impact"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "Impact"
                                      ? "white"
                                      : "",
                                  fontFamily: "Impact",
                                }}
                              >
                                {" "}
                                Impact
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="karantina-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "karantina-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "karantina-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "karantina-regular",
                                }}
                              >
                                {" "}
                                Karantina-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="karantina-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "karantina-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "karantina-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "karantina-bold",
                                }}
                              >
                                {" "}
                                Karantina-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="kubanpro-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "kubanpro-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "kubanpro-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "kubanpro-regular",
                                }}
                              >
                                {" "}
                                Kuban Pro Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="kubanpro-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily == "kubanpro-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "kubanpro-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "kubanpro-bold",
                                }}
                              >
                                {" "}
                                Kuban Pro Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="kufam-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily == "kufam-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "kufam-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "kufam-regular",
                                }}
                              >
                                {" "}
                                Kufam-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="kufam-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily == "kufam-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "kufam-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "kufam-bold",
                                }}
                              >
                                {" "}
                                Kufam-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="mangoGrotesque-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "mangoGrotesque-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "mangoGrotesque-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "mangoGrotesque-regular",
                                }}
                              >
                                {" "}
                                MangoGrotesque-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="mangoGrotesque-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "mangoGrotesque-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "mangoGrotesque-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "mangoGrotesque-bold",
                                }}
                              >
                                {" "}
                                MangoGrotesque-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="mesheddisplay-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "mesheddisplay-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "mesheddisplay-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "mesheddisplay-regular",
                                }}
                              >
                                {" "}
                                Meshed Display-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="mesheddisplay-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "mesheddisplay-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "mesheddisplay-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "mesheddisplay-bold",
                                }}
                              >
                                {" "}
                                Meshed Display-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="nymeria-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "nymeria-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "nymeria-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "nymeria-regular",
                                }}
                              >
                                {" "}
                                Nymeria-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="nymeria-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily == "nymeria-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "nymeria-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "nymeria-bold",
                                }}
                              >
                                {" "}
                                Nymeria-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Palatino"
                                style={{
                                  background:
                                    this.state.textfontfamily == "Palatino"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "Palatino"
                                      ? "white"
                                      : "",
                                  fontFamily: "Palatino",
                                }}
                              >
                                {" "}
                                Palatino
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="piazzolla-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "piazzolla-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "piazzolla-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "piazzolla-regular",
                                }}
                              >
                                {" "}
                                Piazzolla-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="piazzolla-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "piazzolla-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "piazzolla-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "piazzolla-bold",
                                }}
                              >
                                {" "}
                                Piazzolla-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Poppins-SemiBold"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "Poppins-SemiBold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "Poppins-SemiBold"
                                      ? "white"
                                      : "",
                                  fontFamily: "Poppins-SemiBold",
                                }}
                              >
                                {" "}
                                Poppins SemiBold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="radiocanada-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "radiocanada-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "radiocanada-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "radiocanada-regular",
                                }}
                              >
                                {" "}
                                Radio Canada-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="radiocanada-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "radiocanada-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "radiocanada-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "radiocanada-bold",
                                }}
                              >
                                {" "}
                                Radio Canada-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="rebeqa-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "rebeqa-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "rebeqa-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "rebeqa-regular",
                                }}
                              >
                                {" "}
                                Rebeqa Font-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="rebeqa-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily == "rebeqa-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "rebeqa-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "rebeqa-bold",
                                }}
                              >
                                {" "}
                                Rebeqa Font-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="rogerex-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "rogerex-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "rogerex-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "rogerex-regular",
                                }}
                              >
                                {" "}
                                Rogerex-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="rogerex-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily == "rogerex-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "rogerex-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "rogerex-bold",
                                }}
                              >
                                {" "}
                                Rogerex-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="RozinaV04Regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "RozinaV04Regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "RozinaV04Regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "RozinaV04Regular",
                                }}
                              >
                                {" "}
                                Rozina-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="RozinaV06Bold"
                                style={{
                                  background:
                                    this.state.textfontfamily == "RozinaV06Bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "RozinaV06Bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "RozinaV06Bold",
                                }}
                              >
                                {" "}
                                Rozina-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Sans-Serif"
                                style={{
                                  background:
                                    this.state.textfontfamily == "Sans-Serif"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "Sans-Serif"
                                      ? "white"
                                      : "",
                                  fontFamily: "Sans-Serif",
                                }}
                              >
                                {" "}
                                Sans Serif
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="TimesNewRomanPSMT"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "TimesNewRomanPSMT"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "TimesNewRomanPSMT"
                                      ? "white"
                                      : "",
                                  fontFamily: "TimesNewRomanPSMT",
                                }}
                              >
                                {" "}
                                Times New Roman
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Trebuchet-MS"
                                style={{
                                  background:
                                    this.state.textfontfamily == "Trebuchet-MS"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "Trebuchet-MS"
                                      ? "white"
                                      : "",
                                  fontFamily: "Trebuchet-MS",
                                }}
                              >
                                {" "}
                                Trebuchet MS
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="unbounded-regular"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "unbounded-regular"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "unbounded-regular"
                                      ? "white"
                                      : "",
                                  fontFamily: "unbounded-regular",
                                }}
                              >
                                {" "}
                                Unbounded Polkadot-Regular
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="unbounded-bold"
                                style={{
                                  background:
                                    this.state.textfontfamily ==
                                    "unbounded-bold"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily ==
                                    "unbounded-bold"
                                      ? "white"
                                      : "",
                                  fontFamily: "unbounded-bold",
                                }}
                              >
                                {" "}
                                Unbounded Polkadot-Bold
                              </Dropdown.Item>
                              <Dropdown.Item
                                eventKey="Verdana"
                                style={{
                                  background:
                                    this.state.textfontfamily == "Verdana"
                                      ? "#547df2"
                                      : "",
                                  color:
                                    this.state.textfontfamily == "Verdana"
                                      ? "white"
                                      : "",
                                  fontFamily: "Verdana",
                                }}
                              >
                                {" "}
                                Verdana
                              </Dropdown.Item>
                            </DropdownButton>
                          </div>
                        </div>
                        <div className="color_picker ipad-margin-top mb-md-0 mb-3">
                          {/* Tool tip */}
                          <Tooltip
                            firsttag={"h4"}
                            tagclass={"mb-0 mr-2"}
                            classdata={
                              "d-flex justify-content-start align-items-center mb-1"
                            }
                            heading={"Font Size"}
                            content={"Select font size for welcome text."}
                          />
                          {/* Tool tip */}
                          <div className="form-group">
                            <select
                              className="form-control"
                              onChange={(e) => this.handletextfontsize(e)}
                              value={this.state.textfontsize}
                            >
                              <option value="15px">15</option>
                              <option value="16px">16</option>
                              <option value="17px">17</option>
                              <option value="18px">18</option>
                              <option value="19px">19</option>
                              <option value="20px">20</option>
                              <option value="21px">21</option>
                              <option value="22px">22</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      {/* Text End */}
                      {/* Social */}
                      {/* Text */}
                      <div className="d-block d-md-flex align-items-center justify-content-between mt-4 mt-md-4 app-logo ipad-custom-padding ipad-custom-padding-red ipad-login-social">
                        <div className="app-name-padding pl-3 pr-3 p-md-0">
                          <div className="">
                            {/* Tool tip */}
                            <Tooltip
                              firsttag={"h5"}
                              tagclass={"mb-0 mr-2"}
                              classdata={
                                "module_title d-flex justify-content-start align-items-center"
                              }
                              heading={"Social"}
                              content={"Select shape for your social buttons"}
                            />
                            {/* Tool tip */}
                          </div>
                        </div>
                      </div>
                      <div className="upload_files upload_files_social d-flex justify-content-between flex-wrap mt-4 mt-md-2">
                        <div className="color_picker">
                          <h4 className="mb-0 mr-2 mb-2 pt-1">
                            Social Button Shape
                          </h4>
                          <div className="horizontal-alignment d-flex align-items-center ">
                            <div
                              onClick={(e) => this.handlesocialshape("circle")}
                              className="aligndiv align-center"
                            >
                              <div className="d-flex align-items-center justify-content-center align">
                                <svg width="24" height="24" viewBox="0 0 24 24">
                                  <g
                                    id="Rectangle_4348"
                                    data-name="Rectangle 4348"
                                    fill="#fff"
                                    stroke={
                                      this.state.social_shape === "circle"
                                        ? "#7782A1"
                                        : "#c8cedb"
                                    }
                                    strokeWidth="2"
                                  >
                                    <rect
                                      width="24"
                                      height="24"
                                      rx="12"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="22"
                                      height="22"
                                      rx="11"
                                      fill="none"
                                    />
                                  </g>
                                </svg>
                                <p className="ml-2">Circle</p>
                              </div>
                            </div>
                            <div
                              onClick={(e) => this.handlesocialshape("square")}
                              className="aligndiv"
                            >
                              <div className="d-flex align-items-center justify-content-center align align-center">
                                <svg width="22" height="22" viewBox="0 0 22 22">
                                  <g
                                    id="Rectangle_5192"
                                    data-name="Rectangle 5192"
                                    fill="#fff"
                                    stroke={
                                      this.state.social_shape === "square"
                                        ? "#7782A1"
                                        : "#c8cedb"
                                    }
                                    strokeWidth="2"
                                  >
                                    <rect
                                      width="22"
                                      height="22"
                                      rx="5"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="20"
                                      height="20"
                                      rx="4"
                                      fill="none"
                                    />
                                  </g>
                                </svg>
                                <p className="ml-2">Square</p>
                              </div>
                            </div>
                            <div
                              onClick={(e) =>
                                this.handlesocialshape("rectangle")
                              }
                              className="aligndiv"
                            >
                              <div
                                className="d-flex align-items-center justify-content-center align align-center"
                                style={{ borderRight: "0px" }}
                              >
                                <svg width="32" height="22" viewBox="0 0 32 22">
                                  <g
                                    id="Rectangle_5192"
                                    data-name="Rectangle 5192"
                                    fill="#fff"
                                    stroke={
                                      this.state.social_shape === "rectangle"
                                        ? "#7782A1"
                                        : "#c8cedb"
                                    }
                                    strokeWidth="2"
                                  >
                                    <rect
                                      width="32"
                                      height="22"
                                      rx="4"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="30"
                                      height="20"
                                      rx="3"
                                      fill="none"
                                    />
                                  </g>
                                </svg>

                                <p className="ml-2">Rectangle</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Socialend */}
                      {/* Style Section Starts */}
                      <div className="borbot">
                        <div className="d-flex  align-items-center justify-content-between on-off mt-md-4 mt-3 mb-md-2 ipad-custom-padding-red ipad-login-color mb-0 mb-md-2">
                          {/* Tool tip */}
                          <Tooltip
                            firsttag={"h5"}
                            tagclass={"mb-0 mr-2"}
                            classdata={
                              "module_title d-flex justify-content-start align-items-center"
                            }
                            heading={"Color Theme"}
                            content={"Select style & colors for screen design."}
                          />
                          {/* Tool tip */}
                        </div>
                        <div className="upload_files d-flex align-items-center justify-content-between flex-wrap mt-2 mt-md-0">
                          <div className="color_picker mb-4">
                            {/* Tool tip */}
                            <Tooltip
                              firsttag={"h4"}
                              tagclass={"mb-0 mr-2"}
                              classdata={
                                "d-flex justify-content-start align-items-center mb-1"
                              }
                              heading={"Primary Text Color"}
                              content={
                                "Select primary color for screen design."
                              }
                            />
                            {/* Tool tip */}
                            <Reactcolorpicker
                              colorname={this.state.primaryColor}
                              presetColors={this.state.presetColors}
                              onChange={this.handleprimaryColor}
                              classselection={"fixback topfixback"}
                              valuecontrol={true}
                            />
                          </div>
                          <div className="color_picker mb-4">
                            {/* Tool tip */}
                            <Tooltip
                              firsttag={"h4"}
                              tagclass={"mb-0 mr-2"}
                              classdata={
                                "d-flex justify-content-start align-items-center mb-1"
                              }
                              heading={"Secondary Text Color"}
                              content={
                                "Select secondary color for screen design."
                              }
                            />
                            {/* Tool tip */}
                            <Reactcolorpicker
                              colorname={this.state.secondaryColor}
                              presetColors={this.state.presetColors}
                              onChange={this.handlesecondaryColor}
                              classselection={"fixback topfixback"}
                              valuecontrol={true}
                            />
                          </div>

                          <div className="color_picker mb-md-0 mb-4">
                            {/* Tool tip */}
                            <Tooltip
                              firsttag={"h4"}
                              tagclass={"mb-0 mr-2"}
                              classdata={
                                "d-flex justify-content-start align-items-center mb-1"
                              }
                              heading={"Button Color"}
                              content={"Select color fill for button."}
                            />
                            {/* Tool tip */}

                            <Reactcolorpicker
                              colorname={this.state.buttonColor}
                              presetColors={this.state.presetColors}
                              onChange={this.handlebuttonColor}
                              classselection={"fixback topfixback"}
                              valuecontrol={false}
                            />
                          </div>
                          <div className="color_picker ipad-margin-top">
                            {/* Tool tip */}
                            <Tooltip
                              firsttag={"h4"}
                              tagclass={"mb-0 mr-2"}
                              classdata={
                                "d-flex justify-content-start align-items-center mb-1"
                              }
                              heading={"Button Text Color"}
                              content={"Select text color for button."}
                            />
                            {/* Tool tip */}

                            <Reactcolorpicker
                              colorname={this.state.buttonTextColor}
                              presetColors={this.state.presetColors}
                              onChange={this.handlebuttonTextColor}
                              classselection={"fixback topfixback"}
                              valuecontrol={true}
                            />
                          </div>
                        </div>
                      </div>
                      {/* Style Section Ends */}
                      {/*  Remove Mobile App */}
                      <RemoveApppreview />
                      {/*  Remove Mobile App */}
                    </form>
                  </div>
                  <div className="customiseapp mobilepreview mobilepreviewnew">
                    <div className="two-banner two-btn-tabs tabwrapper">
                      <div className="platswitch newswitch">
                        <ul
                          id="myTabs"
                          className="nav nav-pills nav-justified promotertabs mb-4"
                          role="tablist"
                          data-tabs="tabs"
                        >
                          <li>
                            <Link
                              to="#"
                              onClick={(e) => this.switchmobile(e, "ios")}
                              className={
                                this.state.hollowactive ? "active" : ""
                              }
                              data-toggle="tab"
                              id="year"
                            >
                              iOS
                            </Link>
                          </li>
                          <li>
                            <Link
                              to="#"
                              onClick={(e) => this.switchmobile(e, "android")}
                              className={
                                this.state.hollowactive ? "" : "active"
                              }
                              data-toggle="tab"
                            >
                              Android
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="text-center sign_in">
                        <div className="tab-content mb-2">
                          <div
                            role="tabpanel"
                            className="tab-pane fade in active show"
                            id="ios"
                          >
                            {this.state.consent_form_purchased === 1 && (
                              <Arrow
                                direction="left"
                                clickFunction={this.previousSlide}
                                glyph=""
                              />
                            )}
                            <div
                              className="hollow_phone authenticate"
                              style={{
                                backgroundImage:
                                  "url(" + this.state.holloimage + ")",
                              }}
                            >
                              <div className="mobile-head">
                                <img
                                  src={this.state.notch}
                                  className="img-fluid"
                                  alt="notch"
                                />
                              </div>
                              {Toptip(this.state.currentImageIndex)}

                              <>
                                {this.state.currentImageIndex === 0 && (
                                  <div
                                    id="clock"
                                    className={showclass(
                                      this.state.currentImageIndex
                                    )}
                                    style={{
                                      width:
                                        this.state.activeTab === "1" && "244px",
                                      top: "70px",
                                    }}
                                  >
                                    {this.state.activeTab === "2" ? (
                                      <div
                                        style={{
                                          backgroundImage:
                                            "url(" +
                                            this.state.applogodefaultimage +
                                            ")",
                                          width: "100%",
                                          height: "72px",
                                          marginLeft: "-91px",
                                          backgroundSize: "contain",
                                          borderRadius: "0px",
                                        }}
                                        className="defaultimage"
                                      ></div>
                                    ) : (
                                      <p
                                        className="p-0 m-0"
                                        id="clockid"
                                        style={{
                                          color: this.state.applogocolor,
                                          justifyContent: this.logoalign(
                                            this.state.logo_align
                                          ),
                                          display: "flex",
                                          alignItems: "center",
                                          fontSize: this.state.appfontsize,
                                          fontFamily: this.state.appfontfamily,
                                          width: "214px",
                                          height: "100px",
                                        }}
                                      >
                                        {this.state.applogotext}
                                      </p>
                                    )}
                                  </div>
                                )}
                                {this.state.currentImageIndex === 0 &&
                                  this.state.welcometext !== "" && (
                                    <div className="welcometext">
                                      <p
                                        className="m-0 p-0"
                                        style={{
                                          color: this.state.welcometextcolor,
                                          fontSize: this.state.textfontsize,
                                          fontFamily: this.state.textfontfamily,
                                        }}
                                      >
                                        {this.state.welcometext}
                                      </p>
                                    </div>
                                  )}
                              </>
                              {/* )
														} */}
                              <div
                                className={
                                  this.state.selectbackgroundorcolor
                                    ? "white-mobile-border"
                                    : "white-mobile-border backselect"
                                }
                                id="applogo"
                                style={{
                                  background: this.state.selectbackgroundorcolor
                                    ? this.state.splashBackground
                                    : "url(" +
                                      this.state.low_resolution_image +
                                      ")",
                                }}
                              >
                                <div>
                                  {isLoggedIn(this.state.currentImageIndex)}
                                </div>
                              </div>
                            </div>
                            {this.state.consent_form_purchased === 1 && (
                              <Arrow
                                direction="right"
                                clickFunction={this.nextSlide}
                                glyph=""
                              />
                            )}
                          </div>

                          <div className="w-100 d-flex justify-content-center mt-2 mb-2 authenticate-caraousel">
                            {this.state.consent_form_purchased === 1 && (
                              <div
                                onClick={(e) => this.hitindex("0")}
                                className="ml-1 mr-1 carouseldot first active"
                              ></div>
                            )}
                            {this.state.consent_form_purchased === 1 && (
                              <div
                                onClick={(e) => this.hitindex("1")}
                                className="ml-1 mr-1 carouseldot second"
                              ></div>
                            )}
                            {/* <div onClick={e => this.hitindex("2")} className="ml-1 mr-1 carouseldot third"></div> */}
                            {/* <div onClick={e => this.hitindex("3")} className="ml-1 mr-1 carouseldot fourth"></div>
													<div onClick={e => this.hitindex("4")} className="ml-1 mr-1 carouseldot five"></div>
													{this.state.consent_form_purchased === 1 && (
													<div onClick={e => this.hitindex("5")} className="ml-1 mr-1 carouseldot six"></div>
													)}				  */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              )}
             
            </div>
            {/* {InnerFoot()} */}
          </div>
        </section>

{/* New Image upload Pop Up */}

      <NewImageGalleryPopup
        isGalleryPopup={this.state.modal}
        setIsGalleryPopup={true}
        settingYourdata={this.settingYourdata}
        yourErrFun={this.yourErrFun}
        uploadPath={
         localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid")+
         process.env
         .REACT_APP_LoginBackground_Crop
        }
        settingOrgImgFrom3rdParty={this.uploadOrgImgFrom3rdParty}
        hideImageGallery={false}
        widthForRestriction={1242}
        heightForRestriction={2208}
        aspectRatio={"1242:2208"}
        classCompoState={this}
     
      />

        {/* <Modal
          centered
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          style={{ maxWidth: "80%" }}
        >
          <ModalHeader
            toggle={this.toggle}
            className="modal-xxl-content splash_mo"
          >
            <div className="modal-header splash-modal align-items-center pt-0 pt-md-2 pl-0 pr-0 pb-0">
              <div
                className={
                  this.state.uploadcustomimage
                    ? "d-md-flex d-block align-items-center justify-content-between w-100 choose-from-library"
                    : "d-md-flex d-block align-items-center w-100"
                }
              >
                <div className="modaltext">
                  <h4>
                    {" "}
                    {this.state.uploadcustomimage
                      ? "Upload Image"
                      : "Select Image"}
                  </h4>
                </div>
                {this.state.uploadcustomimage ? (
                  <div></div>
                ) : (
                  <div className="form-group d-flex align-items-center search-box w-100">
                    <form
                      className="d-flex align-items-center w-100"
                      onSubmit={this.pixalsearch}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search image from library..."
                        value={this.state.pixalsearchvalue}
                        onChange={(e) => this.handlepixabaysearch(e)}
                      />
                      <button type="submit">
                        <img
                          src={
                            require("../../assets/images/dashboard/search.png")
                              .default
                          }
                          alt="search"
                          className="img-fluid"
                        />
                      </button>
                    </form>
                  </div>
                )}
                <div className="upload-image ml-3">
                  <div
                    className="d-flex align-items-center justify-content-center"
                    onClick={this.toggleupload}
                  >
                    {this.state.uploadcustomimage ? (
                      "Choose From Library"
                    ) : (
                      <>
                        <img
                          src={
                            require("../../assets/images/dashboard/plus.png")
                              .default
                          }
                          alt="add-icon"
                          className="img-fluid mr-2"
                        />
                        Upload Image
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="p-0 for-mobile">
            <BottomScrollListener
              onBottom={this.handleContainerOnBottom}
              offset={500}
            >
              {(scrollRef) => (
                <div className="modal-body-content" ref={scrollRef}>
                  {this.state.pixelspinner ? (
                    <div className="spinn">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    <></>
                  )} */}
                  {/* Pixabay */}
                  {/* <div className="h-100 w-100">
                    <div className="d-flex flex-wrap align-items-center">
                      <div className="first_app  w-100">
                        <div className="d-flex flex-wrap w-100">
                          {this.state.uploadcustomimage ? (
                            <div className="upload_files mt-4 w-100">
                              <div className="large-up w-100">
                                <label htmlFor="getvale">
                                  {this.state.getimage ? (
                                    <div className="up">
                                      <input
                                        type="file"
                                        onChange={(e) => {
                                          this.uploadcustomdefaultimage(e);
                                          e.target.value = null;
                                        }}
                                        id="getvale"
                                      />
                                      {this.state.splashimagetext !== "" && (
                                        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                                          <img
                                            src={
                                              this.state
                                                .uploadcustomdefaultimage
                                            }
                                            style={{ width: "210px" }}
                                            alt="backimg"
                                          />
                                        </div>
                                      )}
                                      <p
                                        style={{
                                          color: "rgb(119, 130, 161)",
                                          display: "block",
                                          textAlign: "center",
                                          marginTop: "20px",
                                          fontSize: "18px",
                                        }}
                                        className="mb-0"
                                      >
                                        {this.state.splashimagetext}
                                      </p>
                                      <div
                                        className={
                                          this.state.splashimagetext === ""
                                            ? "h-100 w-100 d-flex align-items-center justify-content-center mt-4"
                                            : "h-100 w-100 d-flex align-items-center justify-content-center"
                                        }
                                      >
                                        <div className="text-center generallogo">
                                          {this.state.splashimagetext ===
                                            "" && (
                                            <>
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/largecloud.png")
                                                    .default
                                                }
                                                alt="largecloud"
                                                className="img-fluid"
                                              />
                                              <h6
                                                style={{
                                                  fontSize: "28px",
                                                  marginTop: "15px",
                                                }}
                                              >
                                                Upload image
                                              </h6>
                                            </>
                                          )}
                                          <p
                                            style={{
                                              fontSize:
                                                this.state.splashimagetext ===
                                                ""
                                                  ? "23px"
                                                  : "18px",
                                            }}
                                          >
                                            Minimum size 1242*2208px
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <Imagecrop
                                      whenTogglemodal={this.whenTogglemodal}
                                      originalpath={
                                        process.env
                                          .REACT_APP_LoginBackground_Original
                                      }
                                      imagepath={
                                        process.env
                                          .REACT_APP_LoginBackground_Crop
                                      }
                                      imagename={
                                        this.state.uploadcustomdefaultimage
                                      }
                                      cropwidth={1242}
                                      cropheight={2208}
                                      finalcrop={this.finalcrop}
                                      modal={true}
                                    />
                                  )}
                                </label>
                              </div>
                            </div>
                          ) : this.state.pixalimagenotavailable ? (
                            "Not Found"
                          ) : (
                            this.state.pixalbayimage.map((pixalimages, i) => (
                              <div
                                className="app_dummy"
                                style={{
                                  backgroundImage: "url(" + pixalimages + ")",
                                  height: "313px",
                                }}
                                key={i}
                              >
                                <label htmlFor={i} className="w-100 h-100">
                                  <input
                                    type="radio"
                                    name="pixabayimages"
                                    className="d-none"
                                    id={i}
                                    value={pixalimages}
                                    checked={
                                      this.state.chooseImage === pixalimages
                                    }
                                    onChange={(e) => this.handlechooseimage(e)}
                                  />
                                  <span className="activestock"></span>
                                </label>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* Pixabay End*/}

                  {/* Upload */}

                  {/* Upload End*/}
                {/* </div>
              )}
            </BottomScrollListener>
          </ModalBody>
          <ModalFooter>
            <div className="footer-button  w-100">
              <div className="d-flex align-items-center justify-content-between">
                <button className="back_btn mr-2 ml-md-3" onClick={this.toggle}>
                  Cancel
                </button>
                <button
                  className="next_btn modal-next mr-md-3"
                  onClick={this.submitChooseImage}
                >
                  {this.state.submitbuttontext}
                </button>
              </div>
            </div>
          </ModalFooter>
        </Modal> */}
      </>
    );
  }
}


