import React from "react";
import "../../assets/css/newheader.css";
import "../../assets/css/profileleftpanel.css";
import Header from "../Header";
import ProfileLeftPanel from "../ProfileLeftPanel";
import Accountleftpanel from "../Accountleftpanel";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import Styles from "./payment.module.css";
import moment from "moment";
import Billingheader from "./Billingheader";
import Emptypayments from "./Emptypayments";
import {
  Addcard,
  Listcard,
  Unlink,
  Unlink_Zoho,
  checkParam,
} from "../../Api/Paymentmethod/Paymentapi";
import { InnerFoot } from "../Foot";
import "./Billings.css";
import "./payment.css";
export default class Paymentmethod extends React.Component {
  state = {
    spinner: true,
    data: null,
    removespinner: {},
    addcrdspinner: false,
    customer_billing_type: null,
    customer_type: null,
    right_panel_expand: true,
    is_delete_popup:false,
    delete_btn_spinner : "d-none" ,
    delete_btn_text : "Delete" ,
    selected_card_id_for_delete:"",
    isHover:false,
 
    cardLoaderImage: require("../../assets/images/signup/Loader_GIF.gif")
      .default,
  };
  componentDidMount() {
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
    {
      document.title = "Payment methods | AppMySite";
    }
    else
    {
      document.title = "Payment methods |"+ ' ' +localStorage.getItem('agencyname');      
    }
    if(this.props.match.params.appOrWorkspaceID && parseInt(atob(this.props.match.params.diff))===1){
      Listcard(this,parseInt(atob(this.props.match.params.appOrWorkspaceID)));
    }else{

      Listcard(this);
    }
  }
  handleRightPanelExpand = (rightpanelvalue)=>{

    this.setState({
      right_panel_expand : rightpanelvalue
    })
  }


  handleremovecard = () => {
    this.setState({
      delete_btn_spinner:"d-block",
      delete_btn_text:""
     
    })
    // this.setState({
    //   removespinner: { [i]: true },
    // });
    Unlink({ payment_method_id: this.state.selected_card_id_for_delete }, this);
  };
  handleRemoveZohoCard = () => {
    this.setState({
      delete_btn_spinner:"d-block",
      delete_btn_text:""
      
    })
    // this.setState({
    //   removespinner: { [i]: true },
    // });
    Unlink_Zoho(this.state.selected_card_id_for_delete, this);
  };

  handleIsDeletePopUp=(e,from,id,indx , paymentgateway)=>{
  
    if(from==="add"){
      this.setState({
        
        selected_card_id_for_delete:id
        });
    }


    this.setState({
      is_delete_popup:!this.state.is_delete_popup,
     
      });

   }



  addcardbutton = () => {
    let params;
    if (this.state.addcrdspinner === false) {
      if (
        localStorage.getItem("workspaceId") === "" ||
        localStorage.getItem("workspaceId") === undefined ||
        localStorage.getItem("workspaceId") === null
      ) {
        params = {
          app_id: "",
          // path: window.location.pathname,
          path:this.props.match.params.appOrWorkspaceID ? 
          `/payment-method/${this.props.match.params.appOrWorkspaceID}/${btoa(1)}`
          :window.location.pathname
          ,
        };
      } else {
        params = {
          workspace_id: parseInt(localStorage.getItem("workspaceId")),
          // path: window.location.pathname,

          path:this.props.match.params.appOrWorkspaceID ? 
          `/payment-method/${this.props.match.params.appOrWorkspaceID}/${btoa(1)}`
          :window.location.pathname
          ,
        };
      }
    
      Addcard(params, this);
    }

    this.setState({
      addcrdspinner: true,
    });
  };

  newaddcardbutton = () => {
    let params;
    this.setState({spinner:true});
    if (this.state.addcrdspinner === false) {
      if (
        localStorage.getItem("workspaceId") === "" ||
        localStorage.getItem("workspaceId") === undefined ||
        localStorage.getItem("workspaceId") === null
      ) {
        params = {
          app_id: "",
          // path: window.location.pathname,
          path:this.props.match.params.appOrWorkspaceID ? 
          `/payment-method/${this.props.match.params.appOrWorkspaceID}/${btoa(1)}`
          :window.location.pathname
          ,
        };
      } else {
        params = {
          workspace_id: parseInt(localStorage.getItem("workspaceId")),
          // path: window.location.pathname,

          path:this.props.match.params.appOrWorkspaceID ? 
          `/payment-method/${this.props.match.params.appOrWorkspaceID}/${btoa(1)}`
          :window.location.pathname
          ,
        };
      }
    
      Addcard(params, this);
    }

    this.setState({
      addcrdspinner: true,
    });
  };

  handleChangeLoader=(e)=>{
    this.setState({
      isHover:!this.state.isHover
    })
  }

  render() {
    const comparedate = (expiry_month, expiry_year, classId) => {
      let today = new Date();
      let someday = new Date().setFullYear(expiry_year, expiry_month, 1);

      if (someday < today) {
        return (
          <p style={{ color: "#FF4848" }}>
            Expired {expiry_month}/{expiry_year}
          </p>
        );
      } else {
        return (
          <p className={classId}>
            {expiry_month}/{expiry_year}
          </p>
        );
      }
    };
    return (
      <>
       
        <Header/> 
        <section className="dasboard_page  billing-page-mobile mobile-view-billing ">
          {/* <Accountleftpanel ipadView={"initial-leftpanel-ipadview"} /> */}
          <ProfileLeftPanel rightpanelbody = {this.handleRightPanelExpand} profile={""}/>
          <div className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined &&
           localStorage.getItem('sidebaserotate') === 'true')  ? "right-panel-gap right-panel-gap-expand"
            : "right-panel-gap "}>

          <div className=" align-items-center billings-head-flex billing-head-redesign billing-head-hideformobile">
            <h1 className="d-none d-md-block">Billing</h1>
            <p className="billing-tooltip">
              <img
                src={
                  require("../../assets/images/dashboard/Billing/billingexclamation.png")
                    .default
                }
                alt="app-icon"
                className=""
              />
              <p className="billing-tooltipsubheader">
                View all your subscription and purchase activity here.
                {/* <p className="billing-tooltip-learnmore">
                  Learn More
                  <img
                    src={
                      require("../../assets/images/dashboard/Goto.png").default
                    }
                    alt="app-icon"
                    className=""
                  />
                </p> */}
              </p>
            </p>
          </div> 

          <div className="d-flex justify-content-between">
          <Billingheader value={this} />

          {/* <div className="transparent_background transparent_background_product my-profile"> */}
            {/* <div>
              <h1 className="d-none d-md-block">Billing</h1>
              <div className="app_iconname">
                <h5 className=" mb-4 pl-3 pl-md-0 pr-3 pr-md-0 pt-2">
                  Find all your transactions, payment history &amp; invoice
                  details from AppMySite here.{" "}
                </h5>
              </div>
            </div> */}
            {/* <div className="invoice-dropdown billing_subs-head-redesign">
            
              </div> */}
                 <div className=" scrollbar-settings">
                      <div className=" billing-page-padding-r">
                        <div className="social_pannel bill billing_social_pannel">
                          <div className="d-md-flex d-block align-items-center justify-content-between billings_bill-pay-version">
                         
                          </div>
                        {   (this.state.customer_billing_type === 2 ||
                            (this.state.customer_billing_type === 1 &&
                              localStorage.getItem("workspaceId") !== "" &&
                              localStorage.getItem("workspaceId") !==
                                undefined &&
                              localStorage.getItem("workspaceId") !==
                                null)) &&
                          this.state.data &&
                          <div className="addbutton-card">
                            <div
                              className={`${Styles.addcard}  pay-card-width`}
                              onClick={(e) => this.addcardbutton(e)}
                            >
                             {
                              this.state.addcrdspinner === true ?
                              <Spinner
                              animation="border"
                              variant=""
                              style={{
                                color: "#3064F9",
                                width: "15px",
                                height: "15px",
                                marginRight: "57px",
                                marginBottom: "35px"
                            
                               
                              }}
                            />:
                             <div className="text-center addcardbox" >
                                {/* <div className="iconaddcard">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/newaddcard.png")
                                        .default
                                    }
                                    alt="addcard"
                                  
                                  />
                                </div> */}
                                <div className="iconaddcardplus">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/plus.png")
                                        .default
                                    }
                                    alt="addcard"
                                    className="iconaddcardplus"
                                  />
                                </div>
                                <div className="d-flex addcardtext" >
                               
                                  
                               
                                  <h2 className="mb-0 pb-0 addcardfont" 
                                  >
                                    Add
                                  </h2>
                                  
                              
                                  <img
                                   src={
                                     require("../../assets/images/dashboard/Goto.png")
                                       .default
                                   }
                                   className="gotoappicon linkspec gotospaceinv"
                                   alt="Goto"
                                 />
                                 </div>
                              </div>
  }
                            </div>
                            </div>
                        }
                        </div>
                      </div>
                    </div>
                    </div>
            <div
              className={`${this.state.spinner===true?"deals-billing_right_panel min-height-page":"right_panel_billing"} biling-page billing_right_panel-paymentmethod`}
              // style={{ minHeight: "80vh" }}
            >
              {/* <div className="invoice-dropdown billing_subs-head-redesign">
                <div>
                  <h1 className="d-none d-lg-block ">Billing</h1>
                  <div className=" billing_app_iconname">
                    <h5 className=" pb-3 pl-3 pl-md-0 pr-3 pr-md-0 pt-2 invoice-head-sub border-bottom-0">
                      Find all your transactions, payment history &amp; invoice
                      details from AppMySite here.{" "}
                    </h5>
                  </div>
                </div>
              </div> */}

              <div className="right_general">
                {this.state.spinner ? (
                 <div className="payment-billingnewspinner">
                 <svg
                   xmlns="http://www.w3.org/2000/svg"
                   xlink="http://www.w3.org/1999/xlink"
                   style={{
                     margin: "auto",
                     background: "rgb(241, 242, 243,0)",
                     display: "block",
                     shaperendering: "auto",
                     animationplaystate: "running",
                     animationdelay: "0s",
                   }}
                   width="200px"
                   height="200px"
                   viewBox="0 0 100 100"
                   preserveAspectRatio="xMidYMid"
                 >
                   <g
                     transform="translate(20 50)"
                     style={{
                       animationplaystate: "running",
                       animationdelay: "0s",
                     }}
                   >
                     <circle
                       cx="0"
                       cy="0"
                       r="6"
                       fill="#e6261f"
                       style={{
                         animationplaystate: "running",
                         animationdelay: "0s",
                       }}
                     >
                       <animateTransform
                         attributeName="transform"
                         type="scale"
                         begin="-0.375s"
                         calcMode="spline"
                         keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                         values="0;1;0"
                         keyTimes="0;0.5;1"
                         dur="1s"
                         repeatCount="indefinite"
                         style={{
                           animationplaystate: "running",
                           animationdelay: "0s",
                         }}
                       ></animateTransform>
                     </circle>
                   </g>
                   <g
                     transform="translate(40 50)"
                     style={{
                       animationplaystate: "running",
                       animationdelay: "0s",
                     }}
                   >
                     <circle
                       cx="0"
                       cy="0"
                       r="6"
                       fill="#f7d038"
                       style={{
                         animationplaystate: "running",
                         animationdelay: "0s",
                       }}
                     >
                       <animateTransform
                         attributeName="transform"
                         type="scale"
                         begin="-0.25s"
                         calcMode="spline"
                         keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                         values="0;1;0"
                         keyTimes="0;0.5;1"
                         dur="1s"
                         repeatCount="indefinite"
                         style={{
                           animationplaystate: "running",
                           animationdelay: "0s",
                         }}
                       ></animateTransform>
                     </circle>
                   </g>
                   <g
                     transform="translate(60 50)"
                     style={{
                       animationplaystate: "running",
                       animationdelay: "0s",
                     }}
                   >
                     <circle
                       cx="0"
                       cy="0"
                       r="6"
                       fill="#49da9a"
                       style={{
                         animationplaystate: "running",
                         animationdelay: "0s",
                       }}
                     >
                       <animateTransform
                         attributeName="transform"
                         type="scale"
                         begin="-0.125s"
                         calcMode="spline"
                         keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                         values="0;1;0"
                         keyTimes="0;0.5;1"
                         dur="1s"
                         repeatCount="indefinite"
                         style={{
                           animationplaystate: "running",
                           animationdelay: "0s",
                         }}
                       ></animateTransform>
                     </circle>
                   </g>
                   <g
                     transform="translate(80 50)"
                     style={{
                       animationplaystate: "running",
                       animationdelay: "0s",
                     }}
                   >
                     <circle
                       cx="0"
                       cy="0"
                       r="6"
                       fill="#4355db"
                       style={{
                         animationplaystate: "running",
                         animationdelay: "0s",
                       }}
                     >
                       <animateTransform
                         attributeName="transform"
                         type="scale"
                         begin="0s"
                         calcMode="spline"
                         keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                         values="0;1;0"
                         keyTimes="0;0.5;1"
                         dur="1s"
                         repeatCount="indefinite"
                         style={{
                           animationplaystate: "running",
                           animationdelay: "0s",
                         }}
                       ></animateTransform>
                     </circle>
                   </g>
                 </svg>
               </div>
                ) : (
                  <>
                    {/* <div className="row scrollbar-settings">
                      <div className="col-md-12 billing-page-padding-r">
                        <div className="social_pannel bill billing_social_pannel">
                          <div className="d-md-flex d-block align-items-center justify-content-between bill-pay billings_bill-pay-version">
                            <ul>
                            

                              <li>
                                <Link to="/subscriptions">Subscriptions</Link>
                              </li>
                              <li>
                                <Link to="/invoices">Invoices</Link>
                              </li>
                              <li>
                                <Link to="/billing-details">
                                  Billing{" "}
                                  <span className="d-none d-md-inline">
                                    Details
                                  </span>
                                </Link>
                              </li>
                              <li>
                                <Link to="/payment-method" className="active">
                                  Payment{" "}
                                  <span className="d-none d-md-inline">
                                    Method
                                  </span>
                                </Link>
                              </li>
                              {localStorage.getItem("workspaceId") !== "" &&
                              localStorage.getItem("workspaceId") !==
                                undefined &&
                              localStorage.getItem("workspaceId") !==
                                "undefined" &&
                              localStorage.getItem("workspaceId") !== null ? (
                                <></>
                              ) : (
                                <li>
                                  <Link to="/deals">Deals</Link>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="">
                      <div className="">
                        <div className=" d-flex flex-wrap subs-head-payment billing_nodataimg  paymentmethod-billing_nodataimg card-mob-background">
                        <div className="payment-head">Payment method</div>
                          {(this.state.customer_billing_type === 2 ||
                            (this.state.customer_billing_type === 1 &&
                              localStorage.getItem("workspaceId") !== "" &&
                              localStorage.getItem("workspaceId") !==
                                undefined &&
                              localStorage.getItem("workspaceId") !==
                                null)) && (
                                  this.state.data && 
                                   
                               <div className="addbutton-card1">
                              <div
                              className={`${Styles.addcard}  pay-card-width`}
                              onClick={(e) => this.addcardbutton(e)}

                            >
                             
                              
                              {

                              this.state.addcrdspinner ?
                              
                                <Spinner
                              animation="border"
                              variant="primary"
                              style={{
                                color: "#3064F9",
                                width: "15px",
                                height: "15px",
                               
                              }}
                            />
                         
                            :<div className="text-center addcardbox" >
                              {/* <div className="iconaddcard">
                                <img
                                  src={
                                    require("../../assets/images/dashboard/newaddcard.png")
                                      .default
                                  }
                                  alt="addcard"
                                
                                />
                              </div> */}
                              <div className="iconaddcardplus">
                            
                                <img
                                  src={
                                    require("../../assets/images/dashboard/plus.png")
                                      .default
                                  }
                                  alt="addcard"
                                  className="iconaddcardplus"
                                />
                              </div>
                              <div className="d-flex addcardtext" >
                              
                                <h2 className="mb-0 pb-0 addcardfont" 
                                >
                                  Add
                                </h2>
                                
                            
                                <img
                                 src={
                                   require("../../assets/images/dashboard/Goto.png")
                                     .default
                                 }
                                 className="gotoappicon linkspec gotospaceinv"
                                 alt="Goto"
                               />
                               </div>
                            </div>
                            }
                            </div>
                              
                            </div>
                           
                          )}
                          {
                           ( this.state.data === null || this.state.data === "" || this.state.data === undefined )
                           ?
                           <><Emptypayments value={this.newaddcardbutton} customer_billing_type={this.state.customer_billing_type}/></>
                         : <>
                          {/* Repeat Card */}
                          {this.state.customer_billing_type === 2 ||
                          (this.state.customer_billing_type === 1 &&
                            localStorage.getItem("workspaceId") !== "" &&
                            localStorage.getItem("workspaceId") !== undefined &&
                            localStorage.getItem("workspaceId") !== null) ? (
                            <>
                            
                              {this.state.data !== null &&
                                this.state.data.map((res, i) => (
                                  // <div className="addcarded-mob">
                                  <div
                                    className={`${Styles.addedcard}  pay-card-width  `}
                                    key={i}
                                  >
                                    <div className={`${Styles.carddetail}`}>
                                      {/* <h3>{res.network_type}</h3> */}
                                      
                                        <div>
                    
                                      <p className="m-0 p-0 cardaddeddate">
                                        Added{" "}
                                        {moment(res.added_date)
                                          .format("D MMMM YYYY")
                                          .toString()}
                                      </p>
                                        </div>
                                        <div className="billing-cardchip">
                                       <img
                                      src={require("../../assets/images/dashboard/Billing/cardchipnewimg.png").default}
                                      style={{marginLeft:"-2px"}}
                                  className="nodeals-img  d-block "
                                 alt="no-deals "
                                         />

                                         </div>
                                      <p className="pay-card-red-inner cardnumber">
                                     
                                        xxxx<span>xxxx</span><span>xxxx</span> <span style={{fontSize: "17px"}}>{res.card_last4_digit}</span>
                                      </p>
                                      <div className="d-flex expiredate"><span>Expires</span>
                                        { comparedate( 
                                          res.expiry_month,
                                          res.expiry_year,
                                          ""
                                        )}
                                        {/* <p className="ml-4 ml-4-sm-red">
                                          Linked to{" "}
                                          {res.linked_subscription_count}{" "}
                                          subscriptions
                                        </p> */}
                                      </div>
                                    </div>
                                    <div className={Styles.bottomcard}>
                                    <p className=" ">
                                          Linked to{" "}
                                          {res.linked_subscription_count}{" "}
                                          subscriptions
                                        </p>
                                      {this.state.removespinner[i] === true ? (
                                        <Spinner
                                          animation="border"
                                          variant="primary"
                                          style={{
                                            color: "#FF4848",
                                            width: "15px",
                                            height: "15px",
                                            marginRight: "8px",
                                          }}
                                        />
                                      ) : (
                                        <span
                                         onClick={(e) =>
                                            this.handleIsDeletePopUp(e,"add",res.id, i,"stripe")
                                          }
                                          // onClick={(e) =>
                                          //   this.handleremovecard(res.id, i)
                                          // }
                                        >
                                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
  <g id="Group_5465" data-name="Group 5465" transform="translate(-835.516 -682.992)">
    <g id="Rectangle_2307" data-name="Rectangle 2307" transform="translate(836.515 684.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1">
      <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none"/>
      <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none"/>
    </g>
    <g id="Rectangle_2308" data-name="Rectangle 2308" transform="translate(839.515 682.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1">
      <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none"/>
      <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none"/>
    </g>
    <g id="Group_3245" data-name="Group 3245" transform="translate(841.016 687.993)">
      <line id="Line_110" data-name="Line 110" y2="6" transform="translate(3 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1"/>
      <line id="Line_111" data-name="Line 111" y1="6" transform="translate(0 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1"/>
    </g>
    <line id="Line_112" data-name="Line 112" x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1"/>
  </g>
</svg>

                                           {/* <img
                                      src={require("../../assets/images/dashboard/delete.png").default}
                                  className="nodeals-img ml-auto d-block "
                                 alt="no-deals "
                                         /> */}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                 
                                ))}
                            </>
                          ) : (
                            <>
                          
                              {this.state.data !== null &&
                                this.state.data.map((res, i) => (
                                  <div
                                    className={`${Styles.addedcard} ${Styles.addedcardzoho} mb-4 pay-card-width `}
                                    key={i}
                                  >
                                    <div
                                      className={`${Styles.carddetail} `}
                                    >
                                      <div>
                                        <p className="m-0 p-0 cardaddeddate">
                                        Added{" "}
                                        {moment(res.created_time)
                                          .format("D MMMM YYYY")
                                          .toString()}
                                      </p>
                                      </div>
                                      

                                       <div className="billing-cardchip">
                                       <img
                                      src={require("../../assets/images/dashboard/Billing/cardchipnewimg.png").default}
                                      style={{marginLeft:"-2px"}}
                                  className="nodeals-img  d-block "
                                 alt="no-deals "
                                         />

                                         </div>
                                      {/* <h3>{res.payment_gateway}</h3> */}
                                      <p className="pay-card-red-inner cardnumber">
                                        {/* xxxx xxxx xxxx {res.last_four_digits} */}
                                         xxxx<span>xxxx</span><span>xxxx</span> <span style={{fontSize: "17px"}}>{res.last_four_digits}</span>
                                      </p>
                                      <div className="d-flex expiredate"><span>Expires</span>
                                        {comparedate(
                                          res.expiry_month,
                                          res.expiry_year,
                                          `${Styles.expire}`
                                        )}
                                      </div>
                                    </div>
                                    <div className={Styles.bottomcard}>
                                      <p className="m-0 p-0">
                                        {/* Added{" "}
                                        {moment(res.created_time)
                                          .format("D MMMM YYYY")
                                          .toString()} */}
                                      </p>
                                      {this.state.removespinner[i] === true ? (
                                        <Spinner
                                          animation="border"
                                          variant="primary"
                                          style={{
                                            color: "#FF4848",
                                            width: "15px",
                                            height: "15px",
                                            marginRight: "8px",
                                          }}
                                        />
                                      ) : (
                                        <span
                                        onClick={(e) =>
                                          this.handleIsDeletePopUp(e,"add",res.card_id, i,"zoho")
                                        }
                                          // onClick={(e) =>
                                          //   this.handleRemoveZohoCard(
                                          //     res.card_id,
                                          //     i
                                          //   )
                                          // }
                                        >
                                         <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
  <g id="Group_5465" data-name="Group 5465" transform="translate(-835.516 -682.992)">
    <g id="Rectangle_2307" data-name="Rectangle 2307" transform="translate(836.515 684.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1">
      <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none"/>
      <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none"/>
    </g>
    <g id="Rectangle_2308" data-name="Rectangle 2308" transform="translate(839.515 682.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1">
      <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none"/>
      <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none"/>
    </g>
    <g id="Group_3245" data-name="Group 3245" transform="translate(841.016 687.993)">
      <line id="Line_110" data-name="Line 110" y2="6" transform="translate(3 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1"/>
      <line id="Line_111" data-name="Line 111" y1="6" transform="translate(0 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1"/>
    </g>
    <line id="Line_112" data-name="Line 112" x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1"/>
  </g>
</svg>
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              
                            </>
                          )}
                          </>
                           }
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* {InnerFoot()} */}
          </div>
          <Modal
          isOpen={this.state.is_delete_popup}
          centered
          toggle={ (e)=>this.handleIsDeletePopUp(e,"cancel")}
          className=" billing-upgrade-model"
          style={{ maxWidth: "480px !important" }}
        >
          <ModalHeader
             style={{ padding: "19px" }}
            className="modal-xxl-content"
            toggle={ (e)=>this.handleIsDeletePopUp(e,"cancel")}
          >
            <div className="crossmodal-delete" >
              <img
               onClick={(e)=>this.handleIsDeletePopUp(e,"cancel")}
                src={require("../../assets/images/dashboard/cross.png").default}
                alt="alert"
              />
            </div>
          </ModalHeader>
          <ModalBody className=" popup-spec-view billings-updateappmod"
          toggle={ (e)=>this.handleIsDeletePopUp(e,"cancel")}>
            <div>
           
           <div className="billings-update-popuptext">
           <h6>Delete this payment method?</h6>
            <p>If this card is linked to an active subscription,
               your renewal may be affected.</p>
           </div>
           <div className="billings-cancle-update">
           <button className="billing-web-cancle" onClick={(e)=>this.handleIsDeletePopUp(e,"cancel")}>Cancel</button>
           <button className="billing-mob-update" style={{display:"flex",justifyContent:"center",alignItems:"center"}} onMouseLeave={(e)=>this.handleChangeLoader(e)}  onMouseEnter={(e)=>this.handleChangeLoader(e)} onClick={(e)=>
            this.state.customer_billing_type==1 ? this.handleRemoveZohoCard(e)  : this.handleremovecard(e)
           }>
              <div className={this.state.delete_btn_spinner}>
                {/* <img src={this.state.cardLoaderImage} /> */}
               {
                this.state.isHover ?
                <div className="new-loader"></div> :
                <div className="new-red-loader"></div> 
               }
              </div>
            {this.state.delete_btn_text}</button>
           <button className="billing-mob-cancle" onClick={(e)=>this.handleIsDeletePopUp(e,"cancel")}>Cancel</button>
           </div>

            </div>
          
          
          </ModalBody>
         
         </Modal>
        </section>
      </>
    );
  }
}
