import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../utils/apiEndpoints";

export const bottomApiSlice = createApi({
  reducerPath: "bottom",
  // keepUnusedDataFor:1800,
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.generalUrl,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("amstoken")}`
      );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    bottomData: builder.query({
      query: () => `app/get-bottom-menu/${btoa(localStorage.getItem("Appid"))}`,
    }),
    saveBottomData: builder.mutation({
      query: (newData) => ({
        url: "app/save-bottom-menu",
        method: "POST",
        body: newData,
      }),
    }),
  }),
});

export const { useBottomDataQuery, useSaveBottomDataMutation } = bottomApiSlice;
