import React, { useEffect, useState } from "react";
import Reactcolorpicker from "../../Appearance/NewReactcolorpicker";
import imagebackground from "../../../assets/images/dashboard/cms/uploadbackground.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import IconSelectionPopUp from "../../Reusable/IconSelectionPopUp";

const SettingsTooltip = ({ message }) => {
  return (
    <h5 className="mergeapp-tooltip">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 15 15"
      >
        <g
          id="Group_5792"
          data-name="Group 5792"
          transform="translate(-1057.885 -227.299)"
        >
          <circle
            id="Ellipse_213"
            data-name="Ellipse 213"
            cx="7"
            cy="7"
            r="7"
            transform="translate(1058.385 227.799)"
            fill="none"
            stroke="#7782a1"
            stroke-miterlimit="10"
            stroke-width="1"
          />
          <path
            id="Path_6038"
            data-name="Path 6038"
            d="M1117.679,269.437a2.125,2.125,0,1,1,2.771,2.025.921.921,0,0,0-.646.875v.625"
            transform="translate(-54.219 -36.045)"
            fill="none"
            stroke="#7782a1"
            stroke-miterlimit="10"
            stroke-width="1"
          />
          <line
            id="Line_3"
            data-name="Line 3"
            y2="0.918"
            transform="translate(1065.583 238.03)"
            fill="none"
            stroke="#7782a1"
            stroke-miterlimit="10"
            stroke-width="1"
          />
        </g>
      </svg>
      <h5 className="mergeapp-tooltipsubheader">{message}</h5>
    </h5>
  );
};

const MergeAppNewSettings = ({ settings, setMergeState }) => {
  const [locationModal, setLocationModal] = useState(false);
  const [atrIcon, setAtrIcon] = useState("");
  const [openIconPopUp, setOpenIconPopUp] = useState(false);
  const [isDoneBtnDisabled, setIsDoneBtnDisabled] = useState(false);

  const handleLocationToggle = () => {
    setLocationModal(!locationModal);
  };

  useEffect(() => {
    setAtrIcon(settings.locationMarkerIcon);
  }, [settings.locationMarkerIcon]);

  const handleToggleChange = (key) => {
    const currObj = { ...settings };
    if (key === "showAppIcon") {
      currObj[key] = !currObj[key];
      currObj["showCountryFlag"] = !currObj["showCountryFlag"];
      setMergeState({ settings: currObj });
    } else if (key === "showCountryFlag") {
      currObj[key] = !currObj[key];
      currObj["showAppIcon"] = !currObj["showAppIcon"];
      setMergeState({ settings: currObj });
    // } else if (key === "showOnEveryLaunch") {
    //   currObj[key] = !currObj[key];
    //   // currObj["showOnFirstLaunch"] = true;
    //   setMergeState({ settings: currObj });
    } else {
      currObj[key] = !currObj[key];
      setMergeState({ settings: currObj });
    }
  };

  const handleIconPopUp = () => {
    setOpenIconPopUp(!openIconPopUp);
    setIsDoneBtnDisabled(false);
  };

  const saveIconName = (name, filter) => {
    setAtrIcon(name);
    setOpenIconPopUp(false);
    setIsDoneBtnDisabled(false);
    const currObj = { ...settings };
    currObj.locationMarkerIcon = name;
    currObj.iconFilter = filter === "black" ? 1 : 0;
    setMergeState({ settings: currObj });
  };

  const handleCancelIcon = () => {
    setAtrIcon("");
    const currObj = { ...settings };
    currObj.locationMarkerIcon = "default";
    currObj.iconFilter = 0;
    setMergeState({ settings: currObj });
  };

  return (
    <div className="mergeapp-setting-left">
      <div className="mergeapp-setting-head">
        <h2> Settings</h2>
        <p> Configure the appearance of the app selection screen here.</p>
      </div>

      <div className="mob-mergesetting-pagediv">
        <div className="merge-setting-title">
          <h4>
            Title
            <SettingsTooltip message={"Give your screen a title."} />
          </h4>
          <input
            value={settings.title}
            onChange={(e) => {
              const currObj = { ...settings };
              currObj.title = e.target.value;
              setMergeState({ settings: currObj });
            }}
          />
        </div>
        <div className="merge-setting-toggle">
          <h4>
            Show on first launch
            <SettingsTooltip
              message={
                "Enable to display this app selection screen the first time the app is launched."
              }
            />
          </h4>
          {/* {!settings.showOnEveryLaunch ? ( */}
            <label
              class="switch"
              for=""
              onClick={() => handleToggleChange("showOnFirstLaunch")}
            >
              <input
                type="checkbox"
                id=""
                name=""
                checked={settings.showOnFirstLaunch}
              />
              <div className="slider round"></div>
            </label>
          {/* ) : (
            <div className="download-toggle-lock" style={{ height: "22px" }}>
              <label class="switch" for="">
                <input
                  type="checkbox"
                  id="custompackage"
                  name="custompackage"
                  checked={true}
                  readOnly={true}
                />
                <div
                  class="slider round"
                  style={{
                    backgroundColor: "#678EFF",
                    cursor: "default"
                  }}
                ></div>
              </label>
            </div>
          )} */}
        </div>
        <div className="merge-setting-toggle">
          <h4>
            Show on every launch
            <SettingsTooltip
              message={
                "Enable to display this app selection screen every time the app is launched."
              }
            />
          </h4>
          <label
            class="switch"
            for=""
            onClick={() => handleToggleChange("showOnEveryLaunch")}
          >
            <input
              type="checkbox"
              id=""
              name=""
              checked={settings.showOnEveryLaunch}
            />
            <div className="slider round"></div>
          </label>
        </div>

        <div className="merge-setting-toggle">
          <h4>
            Show locations
            <SettingsTooltip
              message={
                "Enable to display a map above the list of merged apps. The location of each merged app will be obtained from the app’s General settings"
              }
            />
          </h4>
          <label
            class="switch"
            for=""
            onClick={() =>
              !settings.showLocations
                ? handleLocationToggle()
                : handleToggleChange("showLocations")
            }
          >
            <input
              type="checkbox"
              id=""
              name=""
              checked={settings.showLocations}
            />
            <div className="slider round"></div>
          </label>
        </div>

        {settings.showLocations && (
          <>
            <div className="merge-setting-title">
              <h4>
                Location marker
                <SettingsTooltip
                  message={"Select an icon to represent your app location."}
                />
              </h4>
              <div className="mergeapp-icon-field d-flex align-items-center justify-content-between">
                <div
                  className="d-flex align-items-center w-100"
                  onClick={handleIconPopUp}
                >
                  <div className="">
                    <div className="selectpixabayimage 1">
                      <img
                        style={{
                          height: "25px",
                          width: "25px",
                          marginLeft: "8px",
                          marginRight: "10px"
                        }}
                        src={
                          atrIcon &&
                          process.env.REACT_APP_Image_Path +
                            localStorage.getItem("user_id") +
                            "/" +
                            localStorage.getItem("Appid") +
                            process.env.REACT_APP_NEW_ICON_PATH +
                            atrIcon
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.src = imagebackground;
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      width: "calc(100% - 89px)"
                    }}
                  >
                    <p
                      className={" m-0 pr-3 save_img menu-img-name"}
                      style={{
                        fontSize: "13px",
                        color: "#BCC1CE"
                      }}
                    >
                      {atrIcon !== "default"
                        ? atrIcon.replace("%2Bxml", "")
                        : "Upload"}
                    </p>
                  </div>
                </div>

                {atrIcon !== "default" && (
                  <div onClick={handleCancelIcon}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.06"
                      height="11.06"
                      viewBox="0 0 11.06 11.06"
                    >
                      <path
                        id="Union_1695"
                        data-name="Union 1695"
                        d="M13951,4311l-5,5,5-5-5-5,5,5,5-5-5,5,5,5Z"
                        transform="translate(-13945.471 -4305.469)"
                        fill="none"
                        stroke="#7782a1"
                        stroke-width="1.5"
                      />
                    </svg>
                  </div>
                )}
              </div>
            </div>
            <div className="merge-location-markerdiv">
              <div
                className={`${
                  settings.iconFilter
                    ? "merge-setting-title"
                    : "merge-setting-title icon-picker-disabled"
                } marker-color`}
              >
                <h4>
                  Marker color
                  <SettingsTooltip
                    message={
                      "Define the color of the marker icon. This feature only works if the location marker is black and solid."
                    }
                  />
                </h4>

                <Reactcolorpicker
                  classselection={"fixback"}
                  colorname={settings.markerColor}
                  onChange={(color) => {
                    const currObj = { ...settings };
                    currObj.markerColor = color;
                    setMergeState({ settings: currObj });
                  }}
                  pickerShouldNotOpen={!settings.iconFilter}
                  valuecontrol={true}
                ></Reactcolorpicker>
              </div>
              <div
                className={`${
                  settings.iconFilter
                    ? "merge-setting-title"
                    : "merge-setting-title icon-picker-disabled"
                }`}
              >
                <h4>
                  Selected marker color
                  <SettingsTooltip
                    message={
                      "Specify the color of the selected marker. This feature only works if the location marker is black and solid."
                    }
                  />
                </h4>
                <Reactcolorpicker
                  classselection={"fixback"}
                  colorname={settings.selectedMarkerColor}
                  onChange={(color) => {
                    const currObj = { ...settings };
                    currObj.selectedMarkerColor = color;
                    setMergeState({ settings: currObj });
                  }}
                  pickerShouldNotOpen={!settings.iconFilter}
                  valuecontrol={true}
                ></Reactcolorpicker>
              </div>
            </div>
            <div className="merge-setting-toggle">
              <h4>
                Show distance
                <SettingsTooltip
                  message={
                    "  Enable to display the distance between your app user and the location of the merged app."
                  }
                />
              </h4>
              <label
                class="switch"
                for=""
                onClick={() => handleToggleChange("showDistance")}
              >
                <input
                  type="checkbox"
                  id=""
                  name=""
                  checked={settings.showDistance}
                />
                <div className="slider round"></div>
              </label>
            </div>
          </>
        )}
        <div className="merge-setting-toggle">
          <h4>
            App icon
            <SettingsTooltip message={"Enable to display the app icon."} />
          </h4>
          <label
            class="switch"
            for=""
            onClick={() => handleToggleChange("showAppIcon")}
          >
            <input
              type="checkbox"
              id=""
              name=""
              checked={settings.showAppIcon}
            />
            <div className="slider round"></div>
          </label>
        </div>

        <div className="merge-setting-toggle">
          <h4>
            Show app name
            <SettingsTooltip message={"Enable to display the app name."} />
          </h4>
          <label
            class="switch"
            for=""
            onClick={() => handleToggleChange("showAppName")}
          >
            <input
              type="checkbox"
              id=""
              name=""
              checked={settings.showAppName}
            />
            <div className="slider round"></div>
          </label>
        </div>
        <div className="merge-setting-toggle">
          <h4>
            Show website link
            <SettingsTooltip message={" Enable to reveal the domain name."} />
          </h4>
          <label
            class="switch"
            for=""
            onClick={() => handleToggleChange("showWebsiteLink")}
          >
            <input
              type="checkbox"
              id=""
              name=""
              checked={settings.showWebsiteLink}
            />
            <div className="slider round"></div>
          </label>
        </div>

        <div className="merge-setting-toggle">
          <h4>
            Show country flag
            <SettingsTooltip
              message={"Enable to display the associated country flag."}
            />
          </h4>
          <label
            class="switch"
            for=""
            onClick={() => handleToggleChange("showCountryFlag")}
          >
            <input
              type="checkbox"
              id=""
              name=""
              checked={settings.showCountryFlag}
            />
            <div className="slider round"></div>
          </label>
        </div>
        <div className="merge-setting-toggle">
          <h4>
            Show country name
            <SettingsTooltip
              message={"Enable to include the associated country name."}
            />
          </h4>
          <label
            class="switch"
            for=""
            onClick={() => handleToggleChange("showCountryName")}
          >
            <input
              type="checkbox"
              id=""
              name=""
              checked={settings.showCountryName}
            />
            <div className="slider round"></div>
          </label>
        </div>

        {/* <div className="merge-setting-toggle">
          <h4>
            Show language
            <SettingsTooltip
              message={"Enable to include the associated language."}
            />
          </h4>
          <label
            class="switch"
            for=""
            onClick={() => handleToggleChange("showLanguage")}
          >
            <input
              type="checkbox"
              id=""
              name=""
              checked={settings.showLanguage}
            />
            <div className="slider round"></div>
          </label>
        </div> */}
      </div>
      <Modal
        className="notification-conf-secondmodal"
        style={{ maxWidth: "480px", maxHeight: "280px" }}
        isOpen={locationModal}
        toggle={() => handleLocationToggle()}
        centered
      >
        <ModalHeader>
          <div
            className="notification-conf-secondmodal-crossmodal"
            style={{ zIndex: "9" }}
          >
            <img
              onClick={() => handleLocationToggle()}
              src={
                require("../../../assets/images/dashboard/cross.png").default
              }
            />
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="notification-conf-popup-maindiv">
            <div className="notification-conf-popup-head">
              <h2>Location permission required</h2>
            </div>

            <p>
              This feature requires enabling the location permission in your
              Build configuration. Enable location permission in your app build
              settings. Additionally, add map coordinates on the settings page
              to display the primary location of your app.
            </p>

            <div className="notification-conf-popup-bottom">
              {/* <button
                className="notification-conf-popup-cancel"
                onClick={() => handleLocationToggle()}
              >
                Cancel
              </button> */}

              <button
                className="notification-conf-popup-confirm"
                onClick={() => {
                  const currObj = { ...settings };
                  currObj["showLocations"] = !currObj["showLocations"];
                  setMergeState({ settings: currObj });
                  handleLocationToggle();
                }}
              >
                Okay
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <IconSelectionPopUp
        handleIconPopUp={handleIconPopUp}
        saveIconName={saveIconName}
        openIconPopUp={openIconPopUp}
        setOpenIconPopUp={setOpenIconPopUp}
        setIsDoneBtnDisabled={setIsDoneBtnDisabled}
        isDoneBtnDisabled={isDoneBtnDisabled}
        widthForRestriction={128}
        heightForRestriction={128}
        from={"functionalComponent"}
        classComponentState={""}
      />
    </div>
  );
};

export default MergeAppNewSettings;
