import React from "react";
import "../assets/css/newSignin.css";
import { Link, Redirect } from "react-router-dom";
import {
  LoginApi,
  Googleloginin,
  Facebooklogins,
  Countrycode,
  VerifyUserApi,
  DeviceDetectData,
} from "../Api/Authenticate/Api";
import { Versionapi } from "../Api/Dashboard/DashboardApi";
//import { GoogleLogin } from "react-google-login";
import {getAgencyData}  from "../Api/Agency/AgencyApi";
import { GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google';
import {
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import ReCAPTCHA from "react-google-recaptcha";
import { errortoast } from "../Dashboard/Toaster";
import Header from "../Dashboard/Reusable/HeaderOutsidePortal";
const publicIp = require("public-ip");

export default class Login extends React.PureComponent {

    constructor(props) {
        super(props);
        this.helpToggle = this.helpToggle.bind(this);
        this.state = {
            helpDropdownOpen: false,
            modalopen: false,
            email: "",
            password: "",
            submitval: "Sign in",
            loader: "d-none",
            loaderimage: require("../assets/images/signup/Loader_GIF.gif").default,
            emailerror: false,
            passworderror: false,
            captchavalue: "",
            login_ip: "",
            eyeimage: require("../assets/images/signup/secure.png").default,
            passattibutetype: "password",
            passattribute: true,
            user_country: "",
            errormsg: "",
            seterror:false,
            isSignedIn:false,
            emailerrormesg: null,
            passworderrormesg: null,
            user_country: "",
            os_name: "",
            browser: "",
            concode: "",
            conname: "",
            regcode: "",
            regname: "",
            cityname: "",
            signinlink: false,
			timezone: "",
            agency_favicon:"",
            agency_id:"",
            agency_logo:"",
            agency_name:"",
            agency_primary_color:"",
            agency_secondary_color:"",
            button_background_color:"",
            button_text_color:"",
            button_hover_txtcolor:"",
            button_hover_bgcolor:"",
            is_fb_login_enabled:0,
            google_app_id:"",
            facebookid:"",
            is_google_login_enabled:0,
            spinner:true,
			deviceuuid: null,
            captcha_key:"",
            captcha_secret:"",
            is_google_captcha_enabled:0,
            googleSigninLoader: false,
            googleSigninAgencyLoader: false,
        };
    }

    componentDidMount() 
    {
        window.scrollTo(0, 0);
      if(localStorage.getItem("agency_id") && localStorage.getItem("agency_id") == 1)
      {
        document.title = "Sign in | AppMySite";
      }
      else if(localStorage.getItem('agencyname') != undefined && localStorage.getItem('agencyname') != null && localStorage.getItem('agencyname') != 'undefined' && localStorage.getItem('agencyname') != 'null' && localStorage.getItem('agencyname') != '')
      {
        document.title = "Sign in |"+ ' ' +localStorage.getItem('agencyname');        
      }
      else if(this.state.agency_name != undefined && this.state.agency_name != null && this.state.agency_name != 'undefined' && this.state.agency_name != 'null' && this.state.agency_name != '')
      {
        document.title = "Sign in |"+' '+ this.state.agency_name;
      }
      else
      {
        document.title = "Sign in";
      }

        
        Versionapi();
        
        if (localStorage.getItem("buildversion") === null) 
        {
            localStorage.setItem("buildversion", process.env.REACT_APP_BUILD);
        }

        if (localStorage.getItem("buildversion") !== null) 
        {
            if ( localStorage.getItem("buildversion") !== process.env.REACT_APP_BUILD ) 
            {
                window.location.reload(true);
                localStorage.setItem("buildversion", process.env.REACT_APP_BUILD);
            }
        }
           
        var string = window.location.href;
    
        if(string.indexOf("client_id=209790035322-npn2ek1udiuu9c4v8lrkdooi6kgv7tv4.apps.googleusercontent.com") > -1)
        {
            this.setState({'isSignedIn':true});
        }
       
        (async () => {
            this.setState({
                login_ip: await publicIp.v4(),
            });
        })();

        DeviceDetectData(this, () => {});

        const params = {
            "domain_name": window.location.host
        }

        getAgencyData(params, this);
		
		
		
        let searchstr = (this.props).history.location.search;
		let urlParams = (new URLSearchParams(searchstr));
		
		
        //let searchquery = searchstr.replace("?","",  searchstr);
        //let queryparam = searchquery.split("&");
        //let queryparam1 = queryparam[0].split("=");
		let deviceid = urlParams.get('deviceid');

        //
        

        if(deviceid !== undefined && deviceid !== null && deviceid !== "")
        {
            localStorage.setItem('devicdeuuid',deviceid);
						
            this.setState({ deviceuuid: deviceid });
        }
		
		
    }

    helpToggle() 
    {
        this.setState((prevState) => ({
            helpDropdownOpen: !prevState.helpDropdownOpen,
        }));
    }

    onChange = (value) => {
        
        this.setState({
            captchavalue: value,
        });
    };
    
    
    
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
            emailerror: false,
            passworderror: false
        });
    };

    loginForm = (e) => {

        e.preventDefault();

        const params = {
            email: this.state.email.replace(/\s/g, "").toLowerCase(),
            password: this.state.password,
            user_ip: this.state.login_ip,
        };

        if (((parseInt(localStorage.getItem('agency_id')) == 1) || this.state.is_google_captcha_enabled == 1) && this.state.captchavalue === "") 
        {
            errortoast("Captcha is required");
            return;
        }
    
        LoginApi(params, this);
        
    };
      
    verifyLoginForm = (e) =>{
        e.preventDefault();

        if(this.state.email === '' || this.state.email === null)
        {
            this.setState({
                emailerror: true,
                emailerrormesg: "Required"
            });
        }
        else
        {
            this.setState({
                emailerror: false,
                emailerrormesg: null
            });
        }

        if(this.state.password === '' || this.state.password === null)
        {
            this.setState({
                passworderror: true,
                passworderrormesg: "Required"
            });
        }
        else
        {
            this.setState({
                passworderror: false,
                passworderrormesg: null
            });
        }

        if (((parseInt(localStorage.getItem('agency_id')) == 1) || this.state.is_google_captcha_enabled == 1) && this.state.captchavalue === "") 
        {
            errortoast("Captcha is required");
            return;
        }


        if(this.state.email !== '' && this.state.password !== '')
        {

            localStorage.setItem('devicdeuuid',this.state.deviceuuid);

            const params = {
                email: this.state.email.replace(/\s/g, "").toLowerCase(),
                password: btoa(this.state.password),
                user_ip: this.state.login_ip,
                user_country: this.state.user_country,
                os_name: this.state.os_name,
                browser: this.state.browser,
                concode: this.state.concode,
                conname: this.state.conname,
                regcode: this.state.regcode,
                regname: this.state.regname,
                cityname: this.state.cityname,
				timezone: this.state.timezone,
                deviceuuid: this.state.deviceuuid,
                agency_id:localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1

            };

            
            VerifyUserApi(params, this);
            


        }
        
    }
      
    changepass = () => {

        this.setState({
            passattribute: !this.state.passattribute,
        });

        if (this.state.passattribute) 
        {
            this.setState({
                passattibutetype: "text",
                eyeimage: require("../assets/images/signup/securityeyeopen.png").default,
            });
        } 
        else 
        {
            this.setState({
                passattibutetype: "password",
                eyeimage: require("../assets/images/signup/securityeyeclose.png").default,
            });
        }
    };
    
    //Google Login
    responseGoogle = (response) => {
           
        //if(this.state.isSignedIn)
        //{
            
            if (response !== undefined && response.error != undefined) 
            {
                if(response.error == "popup_closed_by_user") 
                {
                    this.setState({seterror:true});
                } 
                else
                {
                    this.setState({seterror:true});
             
                    if(response != undefined && response.details != undefined && response.details != "") 
                    {
                        if(response.error == 'idpiframe_initialization_failed')
                        {
                            this.setState({seterror:true});
                        }
                        else
                        {
                            this.setState({ errormsg: response.details });
                        }
                    }
                    else 
                    {
                        this.setState({ errormsg: response.error });
                    }
                }
            }

           
        //}
    };
      
    //Facebook Login
    responseFacebook = (response) => {
        if(response.picture !== undefined) 
        {
            localStorage.setItem('devicdeuuid',this.state.deviceuuid);
            
            DeviceDetectData(this, () => {
                const params = {
                    email: response.email,
                    imageUrl: response.picture.data.url,
                    name: response.name,
                    id: response.id,
                    agency_id:localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1,
                    access_token: response.accessToken,
                    user_ip: this.state.login_ip,
                    user_country: this.state.user_country,
                    os_name: this.state.os_name,
                    browser: this.state.browser,
                    concode: this.state.concode,
                    conname: this.state.conname,
                    regcode: this.state.regcode,
                    regname: this.state.regname,
                    cityname: this.state.cityname,
					timezone: this.state.timezone,
					deviceuuid: this.state.deviceuuid,
                };
    
                Facebooklogins(params, this);
            });
        }
    };


    render()
    {
		
		let searchstr1 = (this.props).history.location.search;
        let urlParams1 = (new URLSearchParams(searchstr1));
        let deviceid1 = urlParams1.get('deviceid');
        if(deviceid1 !== undefined && deviceid1 !== null && deviceid1 !== "")
        {
            localStorage.clear();
            localStorage.setItem('devicdeuuid',deviceid1);
        }
		
		
        const token = localStorage.getItem("amstoken");

        if (!token || token === "null" || token === "undefined") 
        {
        } 
        else 
        {
            if(localStorage.getItem('agencyid') && localStorage.getItem('agencyid') != 1)
            {
                return <Redirect to="/apps" />;
            }
            else
            {
                return <Redirect to="/home" />;
            }   
        }

        return(
            <>
            {this.state.spinner ? (
                <div className="newspinner">
                  <svg xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    style={{height:"100vh" , margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                    width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                          style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        </animateTransform>
                      </circle>
                    </g>
                    <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                          style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        </animateTransform>
                      </circle>
                    </g>
                    <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                          style={{ animationplaystate: "running", animationdelay: "0s" }}>
  
                        </animateTransform>
                      </circle>
                    </g>
                    <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                          style={{ animationplaystate: "running", animationdelay: "0s" }}>
  
                        </animateTransform>
                      </circle>
                    </g>
                  </svg>
                </div>
              )
                :
                (
                <>
                {localStorage.getItem("agency_id") && localStorage.getItem("agency_id") != 1 ?
                
                    <>
                <Header classNameProp={"contact-us-header-white-bg"} SignInShow={this.state.signinlink} />
                <section className="new-signinpage signinpage-ipad ">
                    <div className="">
                        <div className="">
                            <div className="new-signin-left">
                                <div className=" new-signin">
                                    <div className="">
                                        {/*<Loginform history={this.props.history} />*/}
                                        {this.state.seterror && this.state.is_google_login_enabled == 1 ?
                                            <div className="new-signin-error">
                                                <p>Permission needed. Check your browser settings to make sure third-party cookies are allowed. </p>
                                            </div> 
                                        : null                               
                                        }

                                        <div className="  align-items-center justify-content-between new-signinhead">
                                            <div className="new-signupcontent">
                                                <h3 className="text-left mt-0">Sign In</h3>
                
                                            </div>
                                        {this.state.is_google_login_enabled == 1 || this.state.is_fb_login_enabled == 1 ?
                                            <div className=" d-grid ">
                                                <div className="socialoptionstart-signin">Sign in with</div>
                                                <div className=" new-socialoptions-inner-signin agency-subdomain-login">
                                                    {this.state.seterror && this.state.is_google_login_enabled == 1 ?
                                                        <div className="new-signup-google" >
                                                            {" "}
                                                            <div className="sigin-web-google signup-web-google">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.46" height="20.46" viewBox="0 0 20.46 20.46">
                                                                <g id="logo_googleg_48dp" style={{"mixBlendMode": "luminosity","isolation": "isolate"}}>
                                                                    <path id="Shape" d="M18.821,9.456a11.761,11.761,0,0,0-.186-2.093H9v3.957h5.506a4.706,4.706,0,0,1-2.041,3.088v2.567h3.306a9.977,9.977,0,0,0,3.05-7.519Z" transform="translate(1.23 1.007)" fill="#bcc1ce" fillRule="evenodd"/>
                                                                    <path id="Shape-2" data-name="Shape" d="M10.1,19a9.767,9.767,0,0,0,6.771-2.479l-3.306-2.567A6.172,6.172,0,0,1,4.375,10.71H.957v2.651A10.226,10.226,0,0,0,10.1,19Z" transform="translate(0.131 1.464)" fill="#c1c1c1" fillRule="evenodd"/>
                                                                    <path id="Shape-3" data-name="Shape" d="M4.506,11.5a6.048,6.048,0,0,1,0-3.887V4.958H1.088a10.243,10.243,0,0,0,0,9.189L4.506,11.5Z" transform="translate(0 0.678)" fill="#e6e6e6" fillRule="evenodd"/>
                                                                    <path id="Shape-4" data-name="Shape" d="M10.1,4.069A5.527,5.527,0,0,1,14.01,5.6l2.934-2.934A9.832,9.832,0,0,0,10.1,0,10.226,10.226,0,0,0,.957,5.636L4.375,8.286A6.1,6.1,0,0,1,10.1,4.069Z" transform="translate(0.131)" fill="#bcc1ce" fillRule="evenodd"/>
                                                                    <path id="Shape-5" data-name="Shape" d="M0,0H20.46V20.46H0Z" fill="none" fillRule="evenodd"/>
                                                                </g>
                                                                </svg>
                                                                <p className="disablelogin 1">Google</p>                                                        
                                                            </div>
                                                        </div>
                                                    
                                                    : this.state.is_google_login_enabled == 1 ?
                                                        <a href={(this.state.deviceuuid === null || this.state.deviceuuid === '') ? process.env.REACT_APP_API_URL + "auth/google/" + localStorage.getItem("agency_id") : process.env.REACT_APP_API_URL + "auth/google/" + localStorage.getItem("agency_id") +"/" + this.state.deviceuuid}>
                                                            <div onClick={() => this.setState({googleSigninAgencyLoader: true})} className="new-signup-google" >
                                                                {" "}
                                                                {this.state.googleSigninAgencyLoader ?<div className="login-loader"></div> :<div className="sigin-web-google signup-web-google">
                                                                    <svg id="logo_googleg_48dp" xmlns="http://www.w3.org/2000/svg" width="20.46" height="20.46" viewBox="0 0 20.46 20.46">
                                                                    <path id="Shape" d="M18.821,9.456a11.761,11.761,0,0,0-.186-2.093H9v3.957h5.506a4.706,4.706,0,0,1-2.041,3.088v2.567h3.306a9.977,9.977,0,0,0,3.05-7.519Z" transform="translate(1.23 1.007)" fill="#4285f4" fillRule="evenodd"/>
                                                                    <path id="Shape-2" data-name="Shape" d="M10.1,19a9.767,9.767,0,0,0,6.771-2.479l-3.306-2.567A6.172,6.172,0,0,1,4.375,10.71H.957v2.651A10.226,10.226,0,0,0,10.1,19Z" transform="translate(0.131 1.464)" fill="#34a853" fillRule="evenodd"/>
                                                                    <path id="Shape-3" data-name="Shape" d="M4.506,11.5a6.048,6.048,0,0,1,0-3.887V4.958H1.088a10.243,10.243,0,0,0,0,9.189L4.506,11.5Z" transform="translate(0 0.678)" fill="#fbbc05" fillRule="evenodd"/>
                                                                    <path id="Shape-4" data-name="Shape" d="M10.1,4.069A5.527,5.527,0,0,1,14.01,5.6l2.934-2.934A9.832,9.832,0,0,0,10.1,0,10.226,10.226,0,0,0,.957,5.636L4.375,8.286A6.1,6.1,0,0,1,10.1,4.069Z" transform="translate(0.131)" fill="#ea4335" fillRule="evenodd"/>
                                                                    <path id="Shape-5" data-name="Shape" d="M0,0H20.46V20.46H0Z" fill="none" fillRule="evenodd"/>
                                                                    </svg>
                                                                    <p className="enablelogin">Google</p>                                                        
                                                                </div> }
                                                            </div>
                                                        </a>
                                                    : null
                                                    }
                                                    

                                                     {/* <GoogleOAuthProvider clientId={this.state.google_app_id}>
                                                    <GoogleLogin
                                                         onSuccess={credentialResponse => {
                                                            
                                                          }}
                                                          onError={() => {
                                                            
                                                          }}
                                                    />
                                                     </GoogleOAuthProvider> */}

                                                {this.state.is_fb_login_enabled == 1 ?
                                                    
                                                    // <div className="new-facebooksignup">
                                                    //     <FacebookLogin appId={this.state.facebookid != '' && this.state.facebookid != null && this.state.facebookid != undefined ? this.state.facebookid : ""} autoLoad={false} fields="name,email,picture" callback={this.responseFacebook} render={(renderProps) => (
                                                    //         <div className="d-flex facebookicontext" onClick={renderProps.onClick}>
                                                    //             {" "}
                                                    //             <svg xmlns="http://www.w3.org/2000/svg" width="90.747" height="22.748" viewBox="0 0 90.747 22.748">
                                                    //                 <g id="Group_37936" data-name="Group 37936" transform="translate(-509.591 -280.283)">
                                                    //                     <path id="Path_102206" data-name="Path 102206" d="M47.877,33.115H44.766v11.4H40.054v-11.4H37.812V29.11h2.242V26.519c0-1.853.88-4.756,4.755-4.756l3.491.015v3.888H45.766a.959.959,0,0,0-1,1.092v2.357h3.522Z" transform="translate(471.779 258.52)" fill="#1877f2"/>
                                                    //                     <text id="Facebook" transform="translate(530.338 297.46)" fill="#1b2952" fontSize="14" fontFamily="Axiforma-Regular, Axiforma"><tspan x="0" y="0">Facebook</tspan></text>
                                                    //                 </g>
                                                    //             </svg>
                                                    //         </div>
                                                    //     )} cssClass="fbtn" disableMobileRedirect={true} />
                                                    // </div>
                                                    <a href={(this.state.deviceuuid === null || this.state.deviceuuid === '') ? process.env.REACT_APP_API_URL + "auth/facebook/" + localStorage.getItem("agency_id") : process.env.REACT_APP_API_URL + "auth/facebook/" + localStorage.getItem("agency_id") +"/" + this.state.deviceuuid}>
                                                    <div className="new-facebooksignup">
                                                            <div className="d-flex facebookicontext">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="90.747" height="22.748" viewBox="0 0 90.747 22.748">
                                                                    <g id="Group_37936" data-name="Group 37936" transform="translate(-509.591 -280.283)">
                                                                        <path id="Path_102206" data-name="Path 102206" d="M47.877,33.115H44.766v11.4H40.054v-11.4H37.812V29.11h2.242V26.519c0-1.853.88-4.756,4.755-4.756l3.491.015v3.888H45.766a.959.959,0,0,0-1,1.092v2.357h3.522Z" transform="translate(471.779 258.52)" fill="#1877f2"/>
                                                                        <text id="Facebook" transform="translate(530.338 297.46)" fill="#1b2952" fontSize="14" fontFamily="Axiforma-Regular, Axiforma"><tspan x="0" y="0">Facebook</tspan></text>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                    </div>
                                                    </a>
                                                : null
                                                }
                                                </div>
                                                <div className="signup-ordiv d-flex">
                                                    <hr/>
                                                    <p className="signup-or">OR</p>
                                                    <hr/>
                                                </div>
                                            </div>
                                        :
                                        null
                                        }
                                        </div>

                                        <form onSubmit={this.verifyLoginForm} className="new-signin-form">
                                            <div className="form-group ">
                                                <label>Email*</label>
                                                <input type="email" className={this.state.emailerror ? "form-control newsignup-errorinput" : "form-control"} name="email" placeholder="" value={this.state.email} onChange={(e) => this.handleChange(e)} />
                                                
                                                {this.state.emailerror &&
                                                    <div className="d-flex align-items-center  new-error">
                                                        <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                                            <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"/>
                                                            <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"/>
                                                            <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"/>
                                                        </svg>
                                                        <p>{this.state.emailerrormesg}</p>
                                                    </div>
                                                }
                                            </div>

                                            <div className="form-group ">
                                                <label>Password*</label>
                                                <input type={this.state.passattibutetype} className={ this.state.passworderror ? "form-control eye-close newsignup-errorinput" : "form-control eye-close"} name="password" placeholder="" value={this.state.password} onChange={(e) => this.handleChange(e)} />
                                                
                                                <div className="new-signineye" onClick={this.changepass}>
                                                    <img src={this.state.eyeimage} className="img-fluid" alt="secure" />
                                                </div>

                                                {this.state.passworderror &&
                                                    <div className="d-flex align-items-center new-error">
                                                        <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                                            <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"/>
                                                            <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"/>
                                                            <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"/>
                                                        </svg>
                                                        <p>{this.state.passworderrormesg}</p>
                                                    </div>
                                                }
                                            </div>

                                            <div className="d-flex justify-content-between new-forgot_pw">
                                                <label className="container1 ">
                                                    Remember me
                                                    <input type="checkbox" /> <span className=" checkmark"></span>
                                                </label>

                                                <div className="new-signinforgot_password">
                                                    <Link to="/forgot-password">Forgot password?</Link>
                                                </div>
                                            </div>

                                        {this.state.is_google_captcha_enabled == 1 ?
                                            <ReCAPTCHA sitekey={this.state.captcha_key} onChange={this.onChange} className="customcheck customcheck-red" />
                                        : null
                                        }
                                            <button type="submit" className="sign-inloginbtn" 
                                            style={{
                                                    "--custom_color5": localStorage.getItem('agencyid') == '1' || localStorage.getItem('agencyid') == 1 || localStorage.getItem('agencyid') == undefined || localStorage.getItem('agencyid') == 'undefined' || localStorage.getItem('agencyid') == null || localStorage.getItem('agencyid') == 'null' ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                                    "--custom_color4": localStorage.getItem('agencyid') == '1' || localStorage.getItem('agencyid') == 1 || localStorage.getItem('agencyid') == undefined || localStorage.getItem('agencyid') == 'undefined' || localStorage.getItem('agencyid') == null || localStorage.getItem('agencyid') == 'null'? "white" : localStorage.getItem('button_hover_txtcolor'),
                                                    "--custom_color2": localStorage.getItem('agencyid') == '1' || localStorage.getItem('agencyid') == 1 || localStorage.getItem('agencyid') == undefined || localStorage.getItem('agencyid') == 'undefined' || localStorage.getItem('agencyid') == null || localStorage.getItem('agencyid') == 'null' ? "#3064f9" : localStorage.getItem('button_background_color'),
                                                    "--custom_color1": localStorage.getItem('agencyid') == '1' || localStorage.getItem('agencyid') == 1 || localStorage.getItem('agencyid') == undefined || localStorage.getItem('agencyid') == 'undefined' || localStorage.getItem('agencyid') == null || localStorage.getItem('agencyid') == 'null' ? "white" : localStorage.getItem('button_text_color'),
                                                    }}>
                                                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                                    <div className={this.state.loader}>
                                                        {/*<img src={this.state.loaderimage} alt="loader" />*/}
                                                        <div className="new-loader"></div>
                                                    </div>{" "}
                                                    <div>{this.state.submitval}</div>
                                                </div>
                                            </button>

                                            <p className="login-error-msg-red-color">{this.state.errormsg}</p>
                                        </form>

                                    </div>
                                    <div className="already d-none justify-content-center align-items-center p-md-4">
                                        <h5>
                                            New to AppMySite?
                                            <Link to="/sign-up"> Create an account</Link>{" "}
                                        </h5>
                                    </div>
                                </div>
                            </div>                
                        </div>
                    </div>
                </section>
                </>
                :
                <>
                <Header classNameProp={"contact-us-header-white-bg"} SignInShow={this.state.signinlink} />
                <section className="new-signinpage signinpage-ipad ">
                    <div className="">
                        <div className="">
                            <div className="new-signin-left">
                                <div className=" new-signin">
                                    <div className="">
                                        {/*<Loginform history={this.props.history} />*/}
                                        {this.state.seterror &&
                                            <div className="new-signin-error">
                                                <p>Permission needed. Check your browser settings to make sure third-party cookies are allowed. </p>
                                            </div>                                
                                        }
        
                                        <div className="  align-items-center justify-content-between new-signinhead">
                                            <div className="new-signupcontent">
                                                <h3 className="text-left mt-0">Sign In</h3>
                    
                                            </div>
        
                                            <div className=" d-grid ">
                                                <div className="socialoptionstart-signin">Sign in with</div>
                                                <div className=" new-socialoptions-inner-signin">
                                                    {this.state.seterror ?
                                                        <div className="new-signup-google" >
                                                            {" "}
                                                            <div className="sigin-web-google signup-web-google">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20.46" height="20.46" viewBox="0 0 20.46 20.46">
                                                                <g id="logo_googleg_48dp" style={{"mixBlendMode": "luminosity","isolation": "isolate"}}>
                                                                    <path id="Shape" d="M18.821,9.456a11.761,11.761,0,0,0-.186-2.093H9v3.957h5.506a4.706,4.706,0,0,1-2.041,3.088v2.567h3.306a9.977,9.977,0,0,0,3.05-7.519Z" transform="translate(1.23 1.007)" fill="#bcc1ce" fillRule="evenodd"/>
                                                                    <path id="Shape-2" data-name="Shape" d="M10.1,19a9.767,9.767,0,0,0,6.771-2.479l-3.306-2.567A6.172,6.172,0,0,1,4.375,10.71H.957v2.651A10.226,10.226,0,0,0,10.1,19Z" transform="translate(0.131 1.464)" fill="#c1c1c1" fillRule="evenodd"/>
                                                                    <path id="Shape-3" data-name="Shape" d="M4.506,11.5a6.048,6.048,0,0,1,0-3.887V4.958H1.088a10.243,10.243,0,0,0,0,9.189L4.506,11.5Z" transform="translate(0 0.678)" fill="#e6e6e6" fillRule="evenodd"/>
                                                                    <path id="Shape-4" data-name="Shape" d="M10.1,4.069A5.527,5.527,0,0,1,14.01,5.6l2.934-2.934A9.832,9.832,0,0,0,10.1,0,10.226,10.226,0,0,0,.957,5.636L4.375,8.286A6.1,6.1,0,0,1,10.1,4.069Z" transform="translate(0.131)" fill="#bcc1ce" fillRule="evenodd"/>
                                                                    <path id="Shape-5" data-name="Shape" d="M0,0H20.46V20.46H0Z" fill="none" fillRule="evenodd"/>
                                                                </g>
                                                                </svg>
                                                                <p className="disablelogin">Google</p>                                                        
                                                            </div>
                                                        </div>
                                                    
                                                    :
                                                        <a  href={(this.state.deviceuuid === null || this.state.deviceuuid === '') ? process.env.REACT_APP_API_URL + "auth/google/" + localStorage.getItem("agency_id") : process.env.REACT_APP_API_URL + "auth/google/"+ localStorage.getItem("agency_id") + "/" + this.state.deviceuuid}>
                                                            <div className="new-signup-google" onClick={() => this.setState({googleSigninLoader: true})}>
                                                            {this.state.googleSigninLoader ?<div className="login-loader"></div> :
                                                                <div className="sigin-web-google signup-web-google">
                                                                    <svg id="logo_googleg_48dp" xmlns="http://www.w3.org/2000/svg" width="20.46" height="20.46" viewBox="0 0 20.46 20.46">
                                                                    <path id="Shape" d="M18.821,9.456a11.761,11.761,0,0,0-.186-2.093H9v3.957h5.506a4.706,4.706,0,0,1-2.041,3.088v2.567h3.306a9.977,9.977,0,0,0,3.05-7.519Z" transform="translate(1.23 1.007)" fill="#4285f4" fillRule="evenodd"/>
                                                                    <path id="Shape-2" data-name="Shape" d="M10.1,19a9.767,9.767,0,0,0,6.771-2.479l-3.306-2.567A6.172,6.172,0,0,1,4.375,10.71H.957v2.651A10.226,10.226,0,0,0,10.1,19Z" transform="translate(0.131 1.464)" fill="#34a853" fillRule="evenodd"/>
                                                                    <path id="Shape-3" data-name="Shape" d="M4.506,11.5a6.048,6.048,0,0,1,0-3.887V4.958H1.088a10.243,10.243,0,0,0,0,9.189L4.506,11.5Z" transform="translate(0 0.678)" fill="#fbbc05" fillRule="evenodd"/>
                                                                    <path id="Shape-4" data-name="Shape" d="M10.1,4.069A5.527,5.527,0,0,1,14.01,5.6l2.934-2.934A9.832,9.832,0,0,0,10.1,0,10.226,10.226,0,0,0,.957,5.636L4.375,8.286A6.1,6.1,0,0,1,10.1,4.069Z" transform="translate(0.131)" fill="#ea4335" fillRule="evenodd"/>
                                                                    <path id="Shape-5" data-name="Shape" d="M0,0H20.46V20.46H0Z" fill="none" fillRule="evenodd"/>
                                                                    </svg>
                                                                    <p className="enablelogin">Google</p>                                                        
                                                                </div>}
                                                            </div>
                                                        </a>
                                                    }
                                                    
                                                    {/* <GoogleOAuthProvider clientId="209790035322-npn2ek1udiuu9c4v8lrkdooi6kgv7tv4.apps.googleusercontent.com">
                                                    <GoogleLogin
                                                        onSuccess={credentialResponse => {
                                                            
                                                          }}
                                                          onError={() => {
                                                            
                                                          }}
                                                    />
                                                     </GoogleOAuthProvider> */}
                                                    
                                                    {/* <div className="new-facebooksignin">
                                                        <FacebookLogin appId="454532105165574" autoLoad={false} fields="name,email,picture" callback={this.responseFacebook} render={(renderProps) => (
                                                            <div className="d-flex facebookicontext" onClick={renderProps.onClick}>
                                                                {" "}
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="90.747" height="22.748" viewBox="0 0 90.747 22.748">
                                                                    <g id="Group_37936" data-name="Group 37936" transform="translate(-509.591 -280.283)">
                                                                        <path id="Path_102206" data-name="Path 102206" d="M47.877,33.115H44.766v11.4H40.054v-11.4H37.812V29.11h2.242V26.519c0-1.853.88-4.756,4.755-4.756l3.491.015v3.888H45.766a.959.959,0,0,0-1,1.092v2.357h3.522Z" transform="translate(471.779 258.52)" fill="#1877f2"/>
                                                                        <text id="Facebook" transform="translate(530.338 297.46)" fill="#1b2952" fontSize="14" fontFamily="Axiforma-Regular, Axiforma"><tspan x="0" y="0">Facebook</tspan></text>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                        )} cssClass="fbtn" disableMobileRedirect={true} />
                                                    </div> */}
                                                    <a className="d-none" href={(this.state.deviceuuid === null || this.state.deviceuuid === '') ? process.env.REACT_APP_API_URL + "auth/facebook/" + localStorage.getItem("agency_id") : process.env.REACT_APP_API_URL + "auth/facebook/"+ localStorage.getItem("agency_id") + "/" + this.state.deviceuuid}>
                                                    <div className="new-facebooksignup">
                                                            <div className="d-flex facebookicontext">
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="90.747" height="22.748" viewBox="0 0 90.747 22.748">
                                                                    <g id="Group_37936" data-name="Group 37936" transform="translate(-509.591 -280.283)">
                                                                        <path id="Path_102206" data-name="Path 102206" d="M47.877,33.115H44.766v11.4H40.054v-11.4H37.812V29.11h2.242V26.519c0-1.853.88-4.756,4.755-4.756l3.491.015v3.888H45.766a.959.959,0,0,0-1,1.092v2.357h3.522Z" transform="translate(471.779 258.52)" fill="#1877f2"/>
                                                                        <text id="Facebook" transform="translate(530.338 297.46)" fill="#1b2952" fontSize="14" fontFamily="Axiforma-Regular, Axiforma"><tspan x="0" y="0">Facebook</tspan></text>
                                                                    </g>
                                                                </svg>
                                                            </div>
                                                    </div>
                                                    </a>
                                                    
                                                </div>
                                                <div className="signup-ordiv d-flex">
                                                    <hr/>
                                                    <p className="signup-or">OR</p>
                                                    <hr/>
                                                </div>
                                            </div>
                                        </div>
        
                                        <form onSubmit={this.verifyLoginForm} className="new-signin-form">
                                            <div className="form-group ">
                                                <label>Email*</label>
                                                <input type="email" className={this.state.emailerror ? "form-control newsignup-errorinput" : "form-control"} name="email" placeholder="" value={this.state.email} onChange={(e) => this.handleChange(e)} />
                                                
                                                {this.state.emailerror &&
                                                    <div className="d-flex align-items-center  new-error">
                                                        <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                                            <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"/>
                                                            <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"/>
                                                            <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"/>
                                                        </svg>
                                                        <p>{this.state.emailerrormesg}</p>
                                                    </div>
                                                }
                                            </div>
        
                                            <div className="form-group ">
                                                <label>Password*</label>
                                                <input type={this.state.passattibutetype} className={ this.state.passworderror ? "form-control eye-close newsignup-errorinput" : "form-control eye-close"} name="password" placeholder="" value={this.state.password} onChange={(e) => this.handleChange(e)} />
                                                
                                                <div className="new-signineye" onClick={this.changepass}>
                                                    <img src={this.state.eyeimage} className="img-fluid" alt="secure" />
                                                </div>
        
                                                {this.state.passworderror &&
                                                    <div className="d-flex align-items-center new-error">
                                                        <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
                                                            <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"/>
                                                            <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"/>
                                                            <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"/>
                                                        </svg>
                                                        <p>{this.state.passworderrormesg}</p>
                                                    </div>
                                                }
                                            </div>
        
                                            <div className="d-flex justify-content-between new-forgot_pw">
                                                <label className="container1 ">
                                                    Remember me
                                                    <input type="checkbox" /> <span className=" checkmark"></span>
                                                </label>
        
                                                <div className="new-signinforgot_password">
                                                    <Link to="/forgot-password">Forgot password?</Link>
                                                </div>
                                            </div>
        
                                            <ReCAPTCHA sitekey="6LeOmMAUAAAAAGj7NXf5SlZA25DNAfvL1HwxaAeP" onChange={this.onChange} className="customcheck customcheck-red" />
                
                                            <button type="submit" className="sign-inloginbtn"
                                            style={{
                                                "--custom_color5": localStorage.getItem('agencyid') == '1' || localStorage.getItem('agencyid') == 1 || localStorage.getItem('agencyid') == undefined || localStorage.getItem('agencyid') == 'undefined' || localStorage.getItem('agencyid') == null || localStorage.getItem('agencyid') == 'null' ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                                "--custom_color4": localStorage.getItem('agencyid') == '1' || localStorage.getItem('agencyid') == 1 || localStorage.getItem('agencyid') == undefined || localStorage.getItem('agencyid') == 'undefined' || localStorage.getItem('agencyid') == null || localStorage.getItem('agencyid') == 'null'? "white" : localStorage.getItem('button_hover_txtcolor'),
                                                "--custom_color2": localStorage.getItem('agencyid') == '1' || localStorage.getItem('agencyid') == 1 || localStorage.getItem('agencyid') == undefined || localStorage.getItem('agencyid') == 'undefined' || localStorage.getItem('agencyid') == null || localStorage.getItem('agencyid') == 'null' ? "#3064f9" : localStorage.getItem('button_background_color'),
                                                "--custom_color1": localStorage.getItem('agencyid') == '1' || localStorage.getItem('agencyid') == 1 || localStorage.getItem('agencyid') == undefined || localStorage.getItem('agencyid') == 'undefined' || localStorage.getItem('agencyid') == null || localStorage.getItem('agencyid') == 'null' ? "white" : localStorage.getItem('button_text_color'),
                                                }}>
                                                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                                    <div className={this.state.loader}>
                                                        {/*<img src={this.state.loaderimage} alt="loader" />*/}
                                                        <div className="new-loader"></div>
                                                    </div>{" "}
                                                    <div>{this.state.submitval}</div>
                                                </div>
                                            </button>
        
                                            <p className="login-error-msg-red-color">{this.state.errormsg}</p>
                                        </form>
        
                                    </div>
                                    <div className="already d-none justify-content-center align-items-center p-md-4">
                                        <h5>
                                            New to AppMySite?
                                            <Link to="/sign-up"> Create an account</Link>{" "}
                                        </h5>
                                    </div>
                                </div>
                            </div>                
                        </div>
                    </div>
                </section>
                </>
                }
                </>
                )
            }
        </>
       
        );
    }
}