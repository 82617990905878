import React from "react";
import userdefaultImgOnError from "../../assets/images/dashboard/accountprofile.png";

const UserDetailsCard = ({ userData }) => {
  return (
    <div className="dashboard-app-info-three">
      <div className="userImagediv">
        <img
          src={
            userData.image && userData.image.indexOf("https") === -1
              ? process.env.REACT_APP_Image_Path +
                userData?.id +
                process.env.REACT_APP_Profile +
                userData?.image
              : userData?.image
              ? userData?.image
              : ""
          }
          onError={({ currentTarget }) => {
            currentTarget.src = userdefaultImgOnError;
          }}
          className="img-fluid"
          alt="Profile-img"
        />
      </div>

      <div>
        <h3 className="appUserName">{userData ? userData.profile_name : ""}</h3>
        <h4 className="appUserEmail">{userData ? userData.email : ""}</h4>
        <h5 className="appUserId"> {userData ? "ID: " + userData.id : ""}</h5>
      </div>
    </div>
  );
};

export default UserDetailsCard;
