import axios from "axios";
import React from "react";
import toast from "toasted-notes";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";

const Url = process.env.REACT_APP_API_URL + "api/user/";
const appURL = process.env.REACT_APP_API_URL + "api/app/";
const Loader = (state) => {
  state.setState({
    submitval: "",
    loader: "loader",
  });
};
// const sucesstoast = (message) => {
//   toast.notify(
//     <div
//       style={{
//         borderLeft: "5px solid #01C99B",
//         height: "100px",
//         width: "420px",
//       }}
//       className="mobiletoaster"
//     >
//       <div className="d-flex align-items-center h-100">
//         <div className="mr-3 ml-3">
//           <svg width="35" height="35" viewBox="0 0 35 35">
//             <circle
//               id="Ellipse_1736"
//               data-name="Ellipse 1736"
//               cx="17.5"
//               cy="17.5"
//               r="17.5"
//               fill="#01c99b"
//             />
//             <path
//               id="Path_6845"
//               data-name="Path 6845"
//               d="M801.834,128.881l4.166,3.8,8.015-8.015"
//               transform="translate(-790.333 -111.171)"
//               fill="none"
//               stroke="#fff"
//               strokeWidth="2"
//             />
//           </svg>
//         </div>
//         <div>
//           <h6 className="text-left mb-1">Success</h6>
//           <p className="p-0 m-0 text-left">{message}</p>
//         </div>
//       </div>
//     </div>,

//     {
//       position: "top", // top-left, top, top-right, bottom-left, bottom, bottom-right
//       duration: 6000, // This notification will not automatically close
//     }
//   );
// };

// const errortoast = (message, errormssg) => {
//   const handleSeeMore = (e) => {
//     let seeMoreButton = document.getElementById("seeMoreButton");
//     let showMoreText = document.getElementById("showMoreText");
//     if (showMoreText.style.display === "none") {
//       showMoreText.style.display = "block";
//       seeMoreButton.innerHTML = "See Less";
//     } else {
//       showMoreText.style.display = "none";
//       seeMoreButton.innerHTML = "See More";
//     }
//   };
//   toast.notify(
//     <div
//       style={{
//         borderLeft: "5px solid #E20000",
//         height:
//           errormssg === undefined || errormssg === null || errormssg === ""
//             ? "100px"
//             : "fit-content",
//         // overflow:"auto",
//         width: "420px",
//       }}
//       className="mobiletoaster"
//     >
//       <div className="d-flex align-items-center h-100">
//         <div className="mr-3 ml-3">
//           <svg width="35" height="35" viewBox="0 0 35 35">
//             <circle
//               id="Ellipse_1736"
//               data-name="Ellipse 1736"
//               cx="17.5"
//               cy="17.5"
//               r="17.5"
//               fill="#e20000"
//             />
//             <g
//               id="Group_3401"
//               data-name="Group 3401"
//               transform="translate(-673.175 -268.33)"
//             >
//               <g
//                 id="Polygon_10"
//                 data-name="Polygon 10"
//                 transform="translate(682.175 275.33)"
//                 fill="none"
//               >
//                 <path
//                   d="M6.734,3.325a2,2,0,0,1,3.532,0l5.173,9.737A2,2,0,0,1,13.673,16H3.327a2,2,0,0,1-1.766-2.938Z"
//                   stroke="none"
//                 />
//                 <path
//                   d="M 8.5 2.262979507446289 C 7.804892539978027 2.262979507446289 7.109785079956055 2.616877555847168 6.733770370483398 3.324671745300293 L 1.560979843139648 13.061692237854 C 0.8533000946044922 14.39379215240479 1.81879997253418 16.00000190734863 3.327210426330566 16.00000190734863 L 13.67279052734375 16.00000190734863 C 15.18120002746582 16.00000190734863 16.14669990539551 14.39379215240479 15.43902015686035 13.061692237854 L 10.2662296295166 3.324671745300293 C 9.890214920043945 2.616877555847168 9.195107460021973 2.262979507446289 8.5 2.262979507446289 M 8.5 0.2629814147949219 C 9.983099937438965 0.2629814147949219 11.33664989471436 1.07661247253418 12.03246021270752 2.386362075805664 L 17.20524978637695 12.12338256835938 C 17.86993980407715 13.37456226348877 17.83099937438965 14.8464822769165 17.10108947753906 16.06076240539551 C 16.37117958068848 17.27505302429199 15.08957004547119 18.00000190734863 13.67279052734375 18.00000190734863 L 3.327210426330566 18.00000190734863 C 1.910429954528809 18.00000190734863 0.6288204193115234 17.27505302429199 -0.1010894775390625 16.06076240539551 C -0.8309993743896484 14.8464822769165 -0.8699398040771484 13.37456226348877 -0.2052497863769531 12.12338256835938 L 4.96753978729248 2.386362075805664 C 5.66331958770752 1.076622009277344 7.016889572143555 0.2629814147949219 8.5 0.2629814147949219 Z"
//                   stroke="none"
//                   fill="#fff"
//                 />
//               </g>
//               <g
//                 id="Group_3400"
//                 data-name="Group 3400"
//                 transform="translate(690.675 282.866)"
//               >
//                 <path
//                   id="Path_6846"
//                   data-name="Path 6846"
//                   d="M0-1.077V1.9"
//                   transform="translate(0 0.587)"
//                   fill="none"
//                   stroke="#fff"
//                   strokeLinecap="round"
//                   strokeWidth="2"
//                 />
//                 <path
//                   id="Path_6847"
//                   data-name="Path 6847"
//                   d="M0,.324V.346"
//                   transform="translate(0 5.346)"
//                   fill="none"
//                   stroke="#fff"
//                   strokeLinecap="round"
//                   strokeWidth="2"
//                 />
//               </g>
//             </g>
//           </svg>
//         </div>
//         <div className="content">
//           <h6 className="text-left mb-1 ">Error</h6>
//           <p className="p-0 m-0 text-left">{message}</p>
//           {errormssg === undefined || errormssg === null || errormssg === "" ? (
//             <></>
//           ) : (
//             <button
//               id="seeMoreButton"
//               className="see-more"
//               onClick={(e) => handleSeeMore(e)}
//             >
//               See more
//             </button>
//           )}
//         </div>
//       </div>
//       <p
//         id="showMoreText"
//         style={{ display: "none", transition: "all ease 1s" }}
//       >
//         {errormssg}
//       </p>
//     </div>,

//     {
//       position: "top", // top-left, top, top-right, bottom-left, bottom, bottom-right
//       duration: 5000, // This notification will not automatically close
//     }
//   );
// };

//GetwebsiteData
export const GetwebsiteData = (params, state) => {
  Loader(state);
  const protocol = "https://";
  const headers = {
    "Content-Type": "application/json",
    'X-App-Device-Type':'ios',
    'X-App-Code-Ver':localStorage.getItem("Apiversion"),
  };

  axios
    .get(
      process.env.REACT_APP_cors_api +
        protocol +
        params.websiteurl.replace("www.", "") +
        "wp-json/wc/v3/system_status?consumer_secret=" +
        params.consumer_secret +
        "%26consumer_key=" +
        params.consumer_key +
        "&timestamp=" +
        new Date().getTime(),
      {
        headers: headers,
        data:{},
      }
    )
    .then((res) => {
      if (res.status === 200) {
        if (typeof res.data === "object") {
          state.setState({
            submitval: "Verify",
            loader: "d-none",
            success: null,
          });
          let i = 0;
          let pluginname = [];

          for (i = 0; i < res.data.active_plugins.length; i++) {
            pluginname.push(res.data.active_plugins[i].name);
          }

          if (res.data.environment.version > "2.6.0") {
            if (pluginname.indexOf("AppMySite") === -1) {
              const paramsdata = {
                app_id: parseInt(params.appid),
                authorization_type: params.authorization_type,
                consumer_key: params.consumer_key,
                consumer_secret: params.consumer_secret,
                wp_admin_username: params.wp_admin_username,
                wp_admin_application_password:
                  params.wp_admin_application_password,
                is_verified: "",
                connection_to_establish: params.connection_to_establish,
                is_connected: 1,
              };
              Appcredentailapi(paramsdata, state, "redirect");
            } else {
              const paramsdata = {
                app_id: parseInt(params.appid),
                authorization_type: params.authorization_type,
                consumer_key: params.consumer_key,
                consumer_secret: params.consumer_secret,
                wp_admin_username: params.wp_admin_username,
                wp_admin_application_password:
                  params.wp_admin_application_password,
                is_verified: "",
                connection_to_establish: params.connection_to_establish,
                is_connected: 1,
              };
              Appcredentailapi(paramsdata, state, "samepage");
            }
          } else {
            state.setState({
              modalWooPlugin: true,
              plugincontent:
                "Your WooCommerce store could not connect with the mobile app.",
            });
            // errortoast("Please update your WooCommerce Plugin")
          }
        } else {
          state.setState({
            plugincontent:
              "Your WooCommerce store could not connect with the mobile app.",
          });
          const paramsdata = {
            app_id: parseInt(params.appid),
            authorization_type: params.authorization_type,
            consumer_key: params.consumer_key,
            consumer_secret: params.consumer_secret,
            wp_admin_username: params.wp_admin_username,
            wp_admin_application_password: params.wp_admin_application_password,
            is_verified: "",
            connection_to_establish: params.connection_to_establish,
            is_connected: 0,
          };
          Appcredentailapi(paramsdata, state, "errorcase");
        }
      }
    })
    .catch((error) => {
      if (error.response === undefined) {
        state.setState({
          plugincontent:
            "Either your domain is incorrect, your website did not respond or your server does not allow external domains to connect and CORS must be enabled.",
        });
      } else if (error.response.status === 500) {
        state.setState({
          plugincontent:
            "Either your domain is incorrect, your website did not respond or your server does not allow external domains to connect and CORS must be enabled.",
        });
      } else if (error.response.status === 404) {
        state.setState({
          plugincontent:
            "Your WooCommerce store could not connect with the mobile app.",
        });
      } else {
        state.setState({
          plugincontent:
            "Your WooCommerce store could not connect with the mobile app.",
        });
      }

      const paramsdata = {
        app_id: parseInt(params.appid),
        authorization_type: params.authorization_type,
        consumer_key: params.consumer_key,
        consumer_secret: params.consumer_secret,
        wp_admin_username: params.wp_admin_username,
        wp_admin_application_password: params.wp_admin_application_password,
        is_verified: "",
        connection_to_establish: params.connection_to_establish,
        is_connected: 0,
      };
      Appcredentailapi(paramsdata, state, "errorcase");

      // errortoast("Enter the valid CS and Ck")
    });
};

//Check for admin username and password

export const Getadminstatus = (params, state) => {
  Loader(state);
  const protocol = "https://";

  const headers = {
    "Content-Type": "application/json",
    'X-App-Device-Type':'ios',
    'X-App-Code-Ver':localStorage.getItem("Apiversion"),
    Authorization:
      "Basic " +
      btoa(
        params.wp_admin_username + ":" + params.wp_admin_application_password
      ),
  };

  if (params.authorization_type === 0) {
    const paramsdata = {
      app_id: parseInt(params.appid),
      authorization_type: params.authorization_type,
      consumer_key: params.consumer_key,
      consumer_secret: params.consumer_secret,
      wp_admin_username: params.wp_admin_username,
      wp_admin_application_password: params.wp_admin_application_password,
      is_verified: "",
      connection_to_establish: params.connection_to_establish,
      is_connected: 0,
    };
    Appcredentailapi(paramsdata, state, "both_toggle_off");
  } else {
    axios
      .get(
        process.env.REACT_APP_cors_api +
          protocol +
          params.websiteurl.replace("www.", "") +
          "wp-json/wp/v2/users/me",
        {
          headers: headers,
          data:{},
        }
      )
      .then((res) => {
        if (res.status === 200) {
          if (typeof res.data === "object") {
            state.setState({
              submitval:
                parseInt(localStorage.getItem("website_technology")) === 4
                  ? "Save"
                  : "Verify",
              loader: "d-none",
              success: null,
            });
            const paramsdata = {
              app_id: parseInt(params.appid),
              authorization_type: params.authorization_type,
              consumer_key: params.consumer_key,
              consumer_secret: params.consumer_secret,
              wp_admin_username: params.wp_admin_username,
              wp_admin_application_password:
                params.wp_admin_application_password,
              is_verified: "",
              connection_to_establish: params.connection_to_establish,
              is_connected: 1,
            };
            Appcredentailapi(paramsdata, state, "redirect");
          } else {
            state.setState({
              plugincontent:
                "Your Wordpress could not connect with the mobile app.",
            });
            const paramsdata = {
              app_id: parseInt(params.appid),
              authorization_type: params.authorization_type,
              consumer_key: params.consumer_key,
              consumer_secret: params.consumer_secret,
              wp_admin_username: params.wp_admin_username,
              wp_admin_application_password:
                params.wp_admin_application_password,
              is_verified: "",
              connection_to_establish: params.connection_to_establish,
              is_connected: 0,
            };
            Appcredentailapi(paramsdata, state, "errorcase");
          }
        }
      })
      .catch((error) => {
        if (error.response === undefined) {
          state.setState({
            plugincontent:
              "Either your domain is incorrect, your website did not respond or your server does not allow external domains to connect and CORS must be enabled.",
          });
        } else if (error.response.status === 500) {
          state.setState({
            plugincontent:
              "Either your domain is incorrect, your website did not respond or your server does not allow external domains to connect and CORS must be enabled.",
          });
        } else if (error.response.status === 404) {
          state.setState({
            plugincontent:
              "Your Wordpress  could not connect with the mobile app.",
          });
        } else if (error.response.status === 401) {
          state.setState({
            plugincontent: error.response.data.message,
          });
        } else {
          state.setState({
            plugincontent:
              "Your Wordpress  could not connect with the mobile app.",
          });
        }
        const paramsdata = {
          app_id: parseInt(params.appid),
          authorization_type: params.authorization_type,
          consumer_key: params.consumer_key,
          consumer_secret: params.consumer_secret,
          wp_admin_username: params.wp_admin_username,
          wp_admin_application_password: params.wp_admin_application_password,
          is_verified: "",
          connection_to_establish: params.connection_to_establish,
          is_connected: 0,
        };
        Appcredentailapi(paramsdata, state, "errorcase");
      });
  }
};
//Appcredentailapi Getapi
export const Getappcredentailapi = (state, fromurl, callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "get_credentials/" + btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      let sendwrl = res.data.data.websiteurl;

      if (
        res.data.data.websiteurl[res.data.data.websiteurl.length - 1] === "/"
      ) {
        sendwrl = res.data.data.websiteurl;
      } else {
        sendwrl = res.data.data.websiteurl + "/";
      }

      if (res.data.code === 200) {
        state.setState({
          updated_at:res.data.data.updated_at,
        })
        if (fromurl === "download") {
          state.setState({
            ck: res.data.data.client_key,
            cs: res.data.data.client_secret,
            sendwrl: sendwrl,
            allowbutton: true,
            troubleshooturl: res.data.data.websiteurl,
            is_verified: res.data.data.is_verified.toString(),
            spinner: false,
            submitval:
              res.data.data.is_verified.toString() === "0"
                ? "Verify"
                : "Reverify",
            finalimg:
              res.data.data.is_verified.toString() === "0"
                ? require("../../assets/images/dashboard/firstwordpress.png")
                    .default
                : require("../../assets/images/dashboard/successwordpress.png")
                    .default,
            is_connected: res.data.data.is_connected,
            wp_admin_username:
              res.data.data.wp_username === undefined
                ? ""
                : res.data.data.wp_username,
            wp_admin_application_password:
              res.data.data.wp_application_password === undefined
                ? ""
                : res.data.data.wp_application_password,
            wordpress_display:
              res.data.data.authorization_type === 2 ? true : false,
            woo_display: res.data.data.authorization_type === 1 ? true : false,
            wordpress_display_setting:
              res.data.data.authorization_type === 2 ||
              res.data.data.authorization_type === 0
                ? true
                : false,
            woo_display_setting:
              res.data.data.authorization_type === 1 ? true : false,
          });
        } else {
          state.setState({
            spinner: false,
            check_apisecret_for_image: res.data.data.client_key,
            check_adminpass_for_image:
              res.data.data.wp_application_password === undefined
                ? ""
                : res.data.data.wp_application_password,
            apisecret: res.data.data.client_key,
            apikey: res.data.data.client_secret,
            verifykey: res.data.data.client_key,
            success: res.data.data.is_connected === 1 ? true : false,
            websiteurl: res.data.data.websiteurl,
            securetoggle:
              res.data.data.connection_to_establish === 1 ? true : false,
            wp_admin_username:
              res.data.data.wp_username === undefined
                ? ""
                : res.data.data.wp_username,
            wp_admin_application_password:
              res.data.data.wp_application_password === undefined
                ? ""
                : res.data.data.wp_application_password,
            wordpress_display:
              res.data.data.authorization_type === 2 ? true : false,
            woo_display: res.data.data.authorization_type === 1 ? true : false,
            website_type: res.data.data.website_type,
            is_verified: res.data.data.is_verified,
            is_connected: res.data.data.is_connected,
            wordpress_display_setting:
              res.data.data.authorization_type === 2 ||
              res.data.data.authorization_type === 0
                ? true
                : false,
            woo_display_setting:
              res.data.data.authorization_type === 1 ? true : false,
          });
          callback();
        }
      } else if (res.data.code === 203) {
        if (res.data.status === "Token Expired") {
          state.props.history.push("/");
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
          localStorage.clear();
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
        }
        state.setState({
          spinner: false,
          apikey: "",
          apisecret: "",
        });
      }
    })
    .catch((error) => {
      state.setState({
        spinner: false,
      });
      
      errortoast("Something went wrong. Try again after sometime.");
    });
};
//Appcredentailapi Api
export const Appcredentailapi = (paramsdata, state, direction) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "app_credentials", paramsdata, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          updated_at:res.data.updated_at,
        })
        if (direction === "errorcase") {
          if(state.state.wordpress_display_setting){
            state.setState({
             showRedInputWP:true,
            });
          }else{
            state.setState({
              showRedInputWOO:true,
             });
          }
         
          state.setState({
            submitval: "Verify",
            loader: "d-none",
            success: false,
            modalError: true,
           
            supporterror: true,
            handlerender: false,
            wordpress_display: false,
          });
          localStorage.setItem("plugin_dataactive", parseInt(0));
          localStorage.setItem("is_verified", "0");
          state.getCredential();
        } else if (direction === "both_toggle_off") {
          state.setState({
            submitval: "Verify",
            loader: "d-none",
            success: false,
            //modalError:true,

            handlerender: false,
          });
          localStorage.setItem("plugin_dataactive", parseInt(0));
          localStorage.setItem("is_verified", "0");
        } else if (direction === "redirect") {
          if (parseInt(localStorage.getItem("website_technology")) === 4) {
            if (state.state.is_verified !== 1) {
              state.props.history.push("/app/preview");
            } else {
              sucesstoast("The record has been verified.");

              state.setState({
                submitval: "Verify",
                loader: "d-none",
                success: true,
                handlerender: false,
              });
              state.getCredential();
            }
          } else if (
            parseInt(localStorage.getItem("website_technology")) === 4
          ) {
            if (state.state.is_verified !== 1) {
              state.props.history.push("/app/preview");
            } else {
              sucesstoast("The record has been verified.");
              state.setState({
                submitval: "Verify",
                loader: "d-none",
                success: true,
                handlerender: false,
              });
              state.getCredential();
            }
          } else {
            sucesstoast("The record has been verified.");
            state.setState({
              submitval: "Verify",
              loader: "d-none",
              success: true,
              handlerender: false,
            });
            state.getCredential();
          }

          localStorage.setItem("plugin_dataactive", parseInt(1));
        } else {
          sucesstoast("The record has been verified.");

          state.setState({
            submitval: "Verify",
            loader: "d-none",
          });
          state.getCredential();
        }
      } else if (res.data.code === 400) {
        errortoast(
          "Please enter a valid key or your website is not ssl certified."
        );
        state.setState({
          success: true,
        });
     
      }
    });
};

//New Appcredentailapi Api
export const newAppcredentailapi = (paramsdata, state) => {
  state.setState({
    submitval2: "",
    loader2: "loader",
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "app_credentials", paramsdata, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
      
          sucesstoast("The record has been saved.");

          state.setState({
            submitval2: "Save",
            loader2: "d-none",
            updated_at:res.data.updated_at,
          });
          // state.getCredential();
        
      } else if (res.data.code === 400) {
        errortoast(
          "Please enter a valid key or your website is not ssl certified."
        );
        state.setState({
          success: true,
          submitval2: "Save",
          loader2: "d-none",
        });
    
      }
    });
};

//Validateurl
export const Validateurl = (params, state, hit) => {
  state.setState({
    spinner: true,
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "validate-url", params, {
      headers: headers,
    })
    .then((res) => {
      if (hit === "application") {
        window.open(res.data + "wp-admin/profile.php ", "_blank");
      } else {
        window.open(
          res.data +
            "wp-admin/admin.php?page=wc-settings&tab=advanced&section=keys&create-key=1 ",
          "_blank"
        );
      }
      state.setState({
        spinner: false,
      });
    })
    .catch((error) => {
      errortoast("Something went wrong. Try again after sometime.");
      state.setState({
        spinner: false,
      });
    });
};

//Generate License key
export const generateLicenseKey = (state,params) => {

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(appURL + `get-plugin-licence-key`, params , {
      headers: headers,
    })
    .then((res) => {
      if(res.data.code===200){
        state.setState({
          generateSpinner:false,
          generatedLicenseKey:res.data.licence_key,
        });
      }else{
        // errortoast("Oh no! Something’s not right.");
        state.setState({
          generateSpinner:false,
        });
      }
 
    })
    .catch((error) => {
      errortoast("Oh no! Something’s not right.");
      state.setState({
        generateSpinner:false,
      });
    });
};

export const UserlistForMonitor = (
  params,
  state
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      appURL +
        "user-management/" +
        params.app_id +
        "?page=1&limit=100&search_field=&timestamp=" +
        new Date().getTime()
        // +"&allow_listing_for_all=1"
        ,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        let userDataArr=res.data.data;
        let ownerObj = {
          image:res.data.owner_data.profile_image,
          profile_name:res.data.owner_data.name,
          status:1,
          user_id:res.data.owner_data.id,
          invitation_email:res.data.owner_data.email,
        };
        userDataArr.unshift(ownerObj);
        
        let finalUserCheckedArr=new Array(userDataArr.length).fill(false);
        
        userDataArr && userDataArr.map((res,indx)=>{
          for(let i=0; i<state.state.userSelectionArr.length; i++) {
         if(res.user_id==state.state.userSelectionArr[i].id){
          finalUserCheckedArr[indx]=true;
         }
        }
        })
      
        state.setState({
          usersData:userDataArr,
          userCheckedArr:finalUserCheckedArr,
        })
      } else if (res.data.code === 401) {
      } else {   
            

      }
    })
    .catch((error) => {});
};
