import React from "react";
import "../../assets/css/commonComponent.css";

const SaveButton = ({
  handleBtnClick,
  isBtnLoader,
  btnText,
  isDisable,
  variant,
}) => {
  const buttonStyles = {
    "--BtnBgColor":
      parseInt(localStorage.getItem("agencyid")) === 1
        ? "#3064f9"
        : localStorage.getItem("button_background_color"),
    "--BtnTextColor":
      parseInt(localStorage.getItem("agencyid")) === 1
        ? "#ffffff"
        : localStorage.getItem("button_text_color"),
    "--BtnHoverBgColor":
      parseInt(localStorage.getItem("agencyid")) === 1
        ? "#134bec"
        : localStorage.getItem("button_hover_bgcolor"),
    "--BtnHoverTextColor":
      parseInt(localStorage.getItem("agencyid")) === 1
        ? "#ffffff"
        : localStorage.getItem("button_hover_txtcolor"),
  };

  const buttonClass = `${variant}`;

  return (
    <button
      className={buttonClass}
      onClick={(e) => !isDisable && handleBtnClick(e)}
      disabled={isDisable}
      style={buttonStyles}
    >
      <div className="d-flex align-items-center justify-content-center h-100 w-100">
        {isBtnLoader && <div className="new-loader"></div>}
        {!isBtnLoader && <div>{btnText}</div>}
      </div>
    </button>
  );
};

export default SaveButton;
