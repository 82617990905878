import React from "react";

const ProTipIcon = () => {
  return (
    <div className="app-icon-spark">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        viewBox="0 0 25 25"
      >
        <g
          id="Group_37044"
          data-name="Group 37044"
          transform="translate(-351 -714.999)"
        >
          <g
            id="Group_37045"
            data-name="Group 37045"
            transform="translate(351 715)"
          >
            <path
              id="_211696_bolt_icon_2_"
              data-name="211696_bolt_icon (2)"
              d="M150.3,64,143,74.245h4.382l-1.349,7.318,7.3-10.245h-4.382L150.3,64Z"
              transform="translate(-135.562 -59.883)"
              fill=""
            />
            <g
              id="Ellipse_7748"
              data-name="Ellipse 7748"
              transform="translate(0 -0.001)"
              fill="none"
              stroke=""
              stroke-width="1.5"
            >
              <circle cx="12.5" cy="12.5" r="12.5" stroke="none" />
              <circle cx="12.5" cy="12.5" r="11.75" fill="none" />
            </g>
          </g>
        </g>
      </svg>
      <p className="protiptooltip">Pro tip</p>
    </div>
  );
};

export default ProTipIcon;
