  import React from "react";
  import "./../../assets/css/vistapopup.css";
  
function VistaPopup(props) {


  const api = window.CrelloEditor.init({
                apiKey: process.env.REACT_APP_VISTA_API_KEY,
                customDimensions: {
                  width: props.width,
                  height: props.height,
                  measureUnits: "px"
                },
                user:{
                  externalUserId:localStorage.getItem('user_id'),
                },
                projectName:props.projectname,
                downloadFormat:props.downloadformat,
                onPublishAction : (data) => props.loadpicfunction(data),
                scaleRatio:1,
                sidebarDesignType: props.formatkey,
                exportQuality:1,
                designId:props.designid,
                activeSidebarTab:"designs",
                searchQueryPhotos: "Abstract design",
                sidebarTabs: [
                  "photos",
                  "designs",
                  "text",
                  "styles",
                  "objects",
                  "background",
                  "library",
                ],
          });

      

  return (
<>

</>
  );
}

export default VistaPopup;
