import React, {useState, useEffect} from "react";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
//import Select from "react-select";
import { Link } from "react-router-dom";
import "./newAudience.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
//import { FormGroup, Label, Input } from "reactstrap";
//import { Button } from "reactstrap";
import NewSpinner from "./NewSpinner";
import { 
    getSegmentList,
    DeleteSegment,
    GetNotificationConfigDetails,
} from "../../Api/RichNotification/RichNotificationApi";
import moment from "moment";
import { countrylist } from "./Audience/countriesjson";
import SkipBtn from "../../Components/commonIcons/SkipBtn";
import PremiumTag from "../../Components/commonIcons/PremiumTag";

const Segments = (props) => {

    const [segmentdata, setSegmentData] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [planname, setPlanName] = useState("");
    const [code, setCode] = useState(203);
    const [plan_code, setplan_code] = useState("");
    const [reseller_package_id, setreseller_package_id] = useState(null);
    const [isdelete, setIsDelete] = useState(false);
    const [searchval, setSearchVal] = useState("");
    const [deletepopup, setDeletePopup] = useState(false);
    const [deletesegmentid, setDeleteSegmentID] = useState(null);
    const [ConfigDetails, setConfigDetails] = useState(null);
    const [PopupConfig, setPopupConfig] = useState(false);
    const [delpopupyeslabel, setDelPopupYesLabel] = useState("Yes");
    const [removeloader, setRemoveLoader] = useState("d-none");
    const [deletesegmentpopup, setDeleteSegmentPopup] = useState(false);
    const [confirmpoplabel, setConfirmPopupLabel] = useState("Delete");
    const [removepopuploader, setRemovePopupLoader] = useState("d-none"); 
    const [deletemobsegmentpopup, setDeleteMobSegmentPopup] = useState(false); 
    const [appRightPanelExpand, setAppRightPanelExpand] = useState( localStorage.getItem("appSideBarCollapse") === true ||
	localStorage.getItem("appSideBarCollapse") === "true"
	  ? true
	  : false);
    const remove_loader_image = useState(require( "../../assets/images/dashboard/loader.gif").default);

    const countryoption = countrylist;

    const languageoption = [ { value: "en", label: "English" }, { value: "ar", label: "Arabic" }, { value: "az", label: "Azerbaijani" }, { value: "bs", label: "Bosnian" }, { value: "ca", label: "Catalan" }, { value: "zh-Hans", label: "Chinese (Simplified)" }, { value: "zh-Hant", label: "Chinese  (Traditional)" }, { value: "hr", label: "Croatian" }, { value: "cs", label: "Czech" }, { value: "da", label: "Danish" }, { value: "nl", label: "Dutch" }, { value: "et", label: "Estonian" }, { value: "fi", label: "Finnish" }, { value: "fr", label: "French" }, { value: "ka", label: "Georgian" }, { value: "bg", label: "Bulgarian" }, { value: "de", label: "German" }, { value: "el", label: "Greek" }, { value: "hi", label: "Hindi" }, { value: "he", label: "Hebrew" }, { value: "hu", label: "Hungarian" }, { value: "id", label: "Indonesian" }, { value: "it", label: "Italian" }, { value: "ja", label: "Japanese" }, { value: "ko", label: "Korean" }, { value: "lv", label: "Latvian" }, { value: "lt", label: "Lithuanian" }, { value: "ms", label: "Malay" }, { value: "nb", label: "Norwegian" }, { value: "pl", label: "Polish" }, { value: "fa", label: "Persian" }, { value: "pt", label: "Portuguese" }, { value: "pa", label: "Punjabi" }, { value: "ro", label: "Romanian" }, { value: "ru", label: "Russian" }, { value: "sr", label: "Serbian" }, { value: "sk", label: "Slovak" }, { value: "es", label: "Spanish" }, { value: "sv", label: "Swedish" }, { value: "th", label: "Thai" }, { value: "tr", label: "Turkish" }, { value: "uk", label: "Ukrainian" }, { value: "vi", label: "Vietnamese" } ];
    const [skipButtonActive, setSkipButtonActive] = useState(false);
    const appPlan= localStorage.getItem("app_plan")

    //let { agencyID } = useParams("agencyID");

    useEffect(()=>{
        if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
            {
              document.title = "Notification subsets | AppMySite";
            }
            else 
            {
              document.title = "Notification subsets |"+ ' ' +localStorage.getItem('agencyname');
            }

        getSegmentList(
            setSegmentData,
            setSpinner,
            setPlanName,
            setCode,
            setreseller_package_id,
            setplan_code,
        );

        GetNotificationConfigDetails(setConfigDetails);

        //document.title = "Team";

    }, [isdelete]);


    const handleCreatePage = () =>
    {
        if(skipButtonActive){
            {
                props.history.push("/app/advanced-notifications/subsets/create",{skipButtonActive:skipButtonActive,planCode:code});
            }
        }
       else if(ConfigDetails && ConfigDetails.osandroiddata && ConfigDetails.osandroiddata.jsonresponse == null && ConfigDetails.osandroiddata.status == 1)
        {
            props.history.push("/app/advanced-notifications/subsets/create",{skipButtonActive:skipButtonActive});
        }
        else if(ConfigDetails && ConfigDetails.osiosdata && ConfigDetails.osiosdata.jsonresponse == null && ConfigDetails.osiosdata.status == 1)
        {
            props.history.push("/app/advanced-notifications/subsets/create",{skipButtonActive:skipButtonActive});
        }
        else
        {
            setPopupConfig(true);
        }

    }

    const handleDeleteSegmentPopup = (e) =>{

        //e.preventDefault();

        const params = {
            "segment_id": deletesegmentid,
            "app_id": parseInt(localStorage.getItem("Appid"))
        };

        setConfirmPopupLabel("");
        setRemovePopupLoader("d-block");
        //setSpinner(true);

        DeleteSegment(params, setIsDelete, isdelete, setConfirmPopupLabel, setRemovePopupLoader, setDeleteSegmentPopup, deletesegmentpopup, setSpinner);
    }

    /*const hideSegmentPopupToggle = ()=>{

        setDeletePopup(false);
    }*/

    /*const handleDeleteSegment = (e) =>{
        e.preventDefault();

        const params = {
            "segment_id": deletesegmentid,
            "app_id": localStorage.getItem("Appid")
        };

        DeleteSegment(params, setIsDelete, isdelete, setDeletePopup, deletepopup, setSpinner );
    }*/

    const handleEditNotification = (segid, e) =>{


		props.history.push('/app/advanced-notifications/subsets/'+btoa(segid));

	}

    const handleDeleteSegmentPopupShow = (e, segmentid) =>{

        setDeleteSegmentID(segmentid);
        setDeleteSegmentPopup(!deletesegmentpopup);
        //setDeleteMobSegmentPopup(!deletemobsegmentpopup);
    }

    const handleDeleteSegmentPopupCancel = () =>{
        setDeleteSegmentPopup(false);
        //setDeleteMobSegmentPopup(false);
    }

    const handlePopupConfigCancel = () =>
    {
        setPopupConfig(false);
    }

    const handleBuildConfigPopup = () =>
    {
        props.history.push('/app/notifications/build-settings');
    }

    const handleDeleteMobSegmentPopup = ()=>{

    }

    const handleRedirectPlanPage = () =>{

        let pricelink = "";

        if( localStorage.getItem("workspaceId") !== "" && localStorage.getItem("workspaceId") !== undefined && localStorage.getItem("workspaceId") !== "undefined" && localStorage.getItem("workspaceId") !== null)
        {  
            pricelink =`/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`
        }
        else if(reseller_package_id || (plan_code && plan_code.includes("appsumo") )){
            pricelink = "/app/addons";
        }
        else if(atob(localStorage.getItem("plan_code")) === process.env.REACT_APP_Lifetime_preview)
        {
            pricelink = "/deals";
        }
        else
        {
            pricelink = parseInt(localStorage.getItem('website_technology')) === 4 ? 
            "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
            : parseInt(localStorage.getItem('website_technology')) === 5 ? 
            "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
            : parseInt(localStorage.getItem('website_technology')) === 3 ? 
            "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
            : parseInt(localStorage.getItem('website_technology')) === 2 ? 
             "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
            : 
             "/app/pricing" ;
        }
            
        props.history.push(pricelink);
    }

    const handleRightPanelExpand = (value) => {

		setAppRightPanelExpand(value);
	}

    const handleSkipClick = () => {
        setSkipButtonActive(true);
      };


return (
<>
    <div>
        <Header customclassName={"header-responsive-new"} />
    </div>

    <section className="new-audience-login">
        <Leftpanel  handleRightPanelExpand={handleRightPanelExpand} />
    
        <div
            className={`right-panel-gap aud-icon ${
              localStorage.getItem("appSideBarCollapse") !== undefined &&
              localStorage.getItem("appSideBarCollapse") !== null &&
              localStorage.getItem("appSideBarCollapse") === "true"
                ? " right-panel-gap-expand"
                : ""
            }`}
        >
            <div className="d-flex justify-content-between push-heading">
                {/* <div className="audience-heading d-flex justify-content-between">
                    <h1>
                        Push Notifications
                        <p className="audience-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" >
                                <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)" >
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                        <circle cx="9" cy="9" r="9" stroke="none"></circle>
                                        <circle cx="9" cy="9" r="8.25" fill="none"></circle>
                                    </g>
                                    <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"></line>
                                    <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"></path>
                                </g>
                            </svg>
                            <p className="audience-tooltipsubheader">
                                lorem loremlorem lorem xdvcdsvds ewfgreg safdsdf
                            </p>
                        </p>
                    </h1>
                </div> */}

                <div className=' audience-heading web-notification-head' style={{marginBottom: "0px"}}>
                    <h1>Advanced notifications
                        <p className=" consent-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                        <circle cx="9" cy="9" r="9" stroke="none" />
                                        <circle cx="9" cy="9" r="8.25" fill="none" />
                                    </g>
                                    <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5" />
                                    <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                                </g>
                            </svg>
                            <p className="consent-tooltipsubheader">
                            Send out updates and engaging messages to your app users.{" "}
                            </p>
                        </p>
                    </h1>
                </div>
            </div>

            

            <div className="notification-modal-tab">
                <div className="notificationlink notificationlink-list" style={{ height: "33px" }}>                
                    <Link to="/app/advanced-notifications">Notifications</Link>
                    <Link className="active" to="/app/advanced-notifications/subsets">Group</Link>
                    <Link  to="/app/advanced-notifications/audience">Users</Link>       
                </div>

                {
                      (localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) &&
                      window.matchMedia("(min-width: 1200px)").matches && code !== 200 && spinner === false &&
                    <div style={{marginBottom: "0px"}} className="upgrade-feature">
                      <h4 style={{textAlign: "unset"}} className="upgrade-unavailable">This feature is currently unavailable</h4>
                      <p style={{textAlign: "unset"}} className="upgrade-support">Please contact Support for assistance.</p>
                    </div>
                    }  
                
                { (code === 200 || skipButtonActive) &&
                <div className="audience-social-pannel-right-box d-flex">
                    {/* <div className="consent-setting-clicked" style={{marginRight: "20px",marginLeft:"20px" ,cursor:"default"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                       <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>
                            </div> */}
                                                     {appPlan !== "premium" && skipButtonActive && <PremiumTag/> }


                            <div className="audience-segments">
                                <p className="audience-segments-number">
                                    {segmentdata.length}/10 <span className="audience-segments-info">used</span>
                                </p>
                            </div>

                    <div  style={{marginRight: "20px"}}>
                        <Link to={"/app/notifications/build-settings"} className=" consent-setting pointcursor" style={{border: "unset", margin: "0px"}}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                                                        <g id="Group_43299" data-name="Group 43299" transform="translate(-1718 -125)">
                                                            <rect id="Rectangle_32508" data-name="Rectangle 32508" width="26" height="24" transform="translate(1718 125)" fill="none" />
                                                            <g id="Rectangle_31502" data-name="Rectangle 31502" transform="translate(1722 125)" fill="#f8fafe" stroke="#7782a1" stroke-width="1.5">
                                                                <rect width="15" height="23" rx="3" stroke="none" />
                                                                <rect x="0.75" y="0.75" width="13.5" height="21.5" rx="2.25" fill="none" />
                                                            </g>
                                                            <g id="Group_38341" data-name="Group 38341" transform="translate(1708.245 -393.755)">
                                                                <rect id="Rectangle_25572" data-name="Rectangle 25572" width="16" height="16" transform="translate(19.755 526.755)" fill="none" />
                                                                <path id="Path_99646" data-name="Path 99646" d="M7.234,13.953a6.622,6.622,0,0,0,.774-.045.376.376,0,0,0,.331-.393,1.49,1.49,0,0,1,.91-1.461,1.393,1.393,0,0,1,.542-.1,1.546,1.546,0,0,1,1.134.482.377.377,0,0,0,.512.041A6.708,6.708,0,0,0,12.5,11.4a.376.376,0,0,0-.04-.507A1.487,1.487,0,0,1,13.436,8.3h.034a.446.446,0,0,0,.441-.331,6.727,6.727,0,0,0,0-1.528.362.362,0,0,0-.375-.33H13.52a1.555,1.555,0,0,1-1.464-.91,1.491,1.491,0,0,1,.375-1.678.375.375,0,0,0,.04-.511,6.678,6.678,0,0,0-1.087-1.075.37.37,0,0,0-.23-.08.376.376,0,0,0-.277.125,1.549,1.549,0,0,1-1.134.48,1.381,1.381,0,0,1-.531-.1A1.5,1.5,0,0,1,8.29.928.375.375,0,0,0,7.957.543a6.741,6.741,0,0,0-1.513,0,.376.376,0,0,0-.33.394A1.494,1.494,0,0,1,5.205,2.4a1.418,1.418,0,0,1-.544.105,1.544,1.544,0,0,1-1.134-.482.376.376,0,0,0-.512-.042A6.707,6.707,0,0,0,1.952,3.054a.376.376,0,0,0,.04.507A1.486,1.486,0,0,1,.976,6.146a.439.439,0,0,0-.433.33,6.75,6.75,0,0,0,0,1.53.377.377,0,0,0,.367.331H1.02A1.507,1.507,0,0,1,2.4,9.25a1.491,1.491,0,0,1-.376,1.678.376.376,0,0,0-.04.512,6.73,6.73,0,0,0,1.086,1.074.376.376,0,0,0,.508-.042,1.55,1.55,0,0,1,1.134-.48,1.406,1.406,0,0,1,.532.1,1.494,1.494,0,0,1,.921,1.435.375.375,0,0,0,.333.384A6.794,6.794,0,0,0,7.234,13.953Z" transform="translate(20.669 528.038)" fill="#f8fafe" stroke="#7782a1" stroke-linejoin="round" stroke-width="1.5" />
                                                                <path id="Path_99647" data-name="Path 99647" d="M8.082,5.846A2.264,2.264,0,1,1,5.818,8.111,2.265,2.265,0,0,1,8.082,5.846Z" transform="translate(19.826 527.202)" fill="#f8fafe" stroke="#7782a1" stroke-linejoin="round" stroke-width="1.5" />
                                                            </g>
                                                            <path id="Rectangle_31503" data-name="Rectangle 31503" d="M0,0H5A0,0,0,0,1,5,0V1A1,1,0,0,1,4,2H1A1,1,0,0,1,0,1V0A0,0,0,0,1,0,0Z" transform="translate(1727 126)" fill="#7782a1" />
                                                        </g>
                                                    </svg>
                                                    <span className="build-settings-text">Build Settings</span>        
                        </Link>
                    </div>

                     

                    { segmentdata.length < 10 &&
                    <button className="audience-createpage" onClick={handleCreatePage}>
                        <div className=" d-flex">
                            <div className="d-flex justify-content-center img-plus">
                                <svg id="Group_3055" data-name="Group 3055" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" >
                                    <g id="Group_1664" data-name="Group 1664" transform="translate(0 6.001)" >
                                        <line id="Line_85" data-name="Line 85" y2="12" transform="translate(12) rotate(90)" fill="none" stroke="#fff" stroke-width="1.5" />
                                    </g>
                                    <g id="Group_1665" data-name="Group 1665" transform="translate(5.959) rotate(90)" >
                                        <line id="Line_85-2" data-name="Line 85" y2="12" transform="translate(12) rotate(90)" fill="none" stroke="#fff" stroke-width="1.5" />
                                    </g>
                                </svg>
                                <p>Create</p>
                            </div>
                        </div>
                    </button>
                    }

                    
                </div>
                }
                {/* 
                <div className="notificationcreate-new ">
                    <div className="pushnotification-Search">
                        <form>
                            <button>
                                <svg id="Group_3669" data-name="Group 3669" xmlns="http://www.w3.org/2000/svg" width="16.53" height="16.53" viewBox="0 0 16.53 16.53">
                                    <g id="Ellipse_246" data-name="Ellipse 246" transform="translate(14.675) rotate(90)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                        <ellipse cx="7.337" cy="7.337" rx="7.337" ry="7.337" stroke="none" />
                                        <ellipse cx="7.337" cy="7.337" rx="6.587" ry="6.587" fill="none" />
                                    </g>
                                    <path id="Path_6064" data-name="Path 6064" d="M0,5.347V0" transform="translate(16 16) rotate(135)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                                </svg>
                            </button>
                            <input placeholder="Search"></input>
                        </form>
                    </div>
                </div>
                */}
            </div>
                        
            <>
                { !window.matchMedia("(max-width: 1200px)").matches ?
                    <div className="audience-mobile-view">
                        <h2 className="audiebce-mob-head">Audience</h2>
                    

                        <div className=" " style={{position: "relative"}}>
                            {spinner ?
                                <div  className="audience-spinner">
                                    <NewSpinner />
                                </div>
                            :                                
                                ( code !== 200 && !skipButtonActive ?
                                    <div className="audience-icon-section ">
                                        
                                        <div className="audience-icon-section-width">
                                            <div className="audience-icon-section-one audience-icon-section-one-width">
                                                <h2>Group</h2>
                                                <p>Create targeted lists of users for maximum impact.</p>
                                                <ul>
                                                    <li>Craft segment-focused customer messaging. </li>
                                                    <li>Segment by demographics, user-activity and more.</li>
                                                    <li>Reach your desired audience effectively.</li>
                                                    <li>Enhance user engagement with optimized campaigns.</li>
                                                </ul>
                                                {/*<div className="app-icon-buttons">
                                                    <span>Upgrade</span>
                                                </div>*/}
                                                <div className="upgrade-skip-notification">
                                                { (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ? 
                                                    <Link to={ localStorage.getItem("workspaceId") !== "" && localStorage.getItem("workspaceId") !== undefined 
                                                    && localStorage.getItem("workspaceId") !== "undefined" && localStorage.getItem("workspaceId") !== null ? 
                                                   `/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}` 
                                                    // : atob(localStorage.getItem("plan_code")) === process.env.REACT_APP_Lifetime_preview ? "/deals"  
                                                    : ( reseller_package_id || (plan_code && plan_code.includes("appsumo") ))?"/app/addons"
                                                    : parseInt(localStorage.getItem('website_technology')) === 4 ? "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
                                                    : parseInt(localStorage.getItem('website_technology')) === 5 ? "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
                                                    : parseInt(localStorage.getItem('website_technology')) === 3 ? "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
                                                    : parseInt(localStorage.getItem('website_technology')) === 2 ? "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
                                                    : "/app/pricing" 
                                                    } 
                                                    className="notification_upgradebtn" >
                                                        {
                                ( reseller_package_id || (plan_code && plan_code.includes("appsumo") ))?"Buy addon":"Upgrade"
                              }
                                                    </Link>
                                                : 
                                                    null
                                                }
                                                <div className="skip-btn-head" onClick={handleSkipClick}>
						                              <SkipBtn/>
						                        </div>
                                                </div>
                                                
                                            </div>
                                            <div className="audience-icon-section-two">
                                                <img className="img-fluid" src={ require("../../assets/images/dashboard/Audience.png").default } alt="Audience-icon-default" />
                                            </div>
                                        </div>
                                    </div>
                                :                                    
                                    <div className=" aud-table web-view ">                                        
                                        <div className="audience-whitebg ">
                                            { segmentdata.length <= 0 ?
                                                <div className='nodatintable'>
                                                    <img src={ require("../../assets/images/dashboard/usertablenodata.png").default } alt="addmobpre" className="img-fluid " />
                                                    <p>Nothing here at the moment. Come back later?</p>
                                                </div>
                                            :
                                                <div className="audience-inner-box">
                                                    <div className="table-responsive">
                                                        <table className="table align-middle table-hover" id="web-table" >
                                                            <thead className="table-headings">
                                                                <tr className="table-headings-info">
                                                                    <th scope="col" className="heading user"> Group </th>
                                                                    <th scope="col" className="heading email"> Created </th>
                                                                </tr>
                                                            </thead>

                                                            {
                                                            segmentdata !== null && segmentdata !== '' && segmentdata !== undefined ?
                                                                segmentdata.map((sglist, sgidx)=>{

                                                                const rulesdata = JSON.parse(sglist.rules_json);
                                                                return (
                                                                <tbody key={sgidx}>
                                                                    <tr className="table-headings-content">
                                                                        <td scope="row" className="audience-icon">
                                                                            <div className={ (rulesdata === null  && sglist.segment_name === 'Total Subscriptions') ? "user-profile one-liner" : "user-profile" }>
                                                                                <p className="sub-user">{ sglist.segment_name }</p>
                                                                                { rulesdata === null  && sglist.segment_name === 'Total Subscriptions' &&
                                                                                    <span className="sub-status">Default</span>
                                                                                }
                                                                                {rulesdata === null  && sglist.segment_name === 'Engaged Subscriptions' &&
                                                                                    <>
                                                                                     <div className="sub-user-detail">
                                                                                                        <div className="sub-user-img clockwise">
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" >
                                                                                                                <g id="Group_38919" data-name="Group 38919" transform="translate(16262 5049)" >
                                                                                                                    <path id="Union_2848" data-name="Union 2848" d="M7.991,17.206a8.59,8.59,0,0,1-7.6-9.382A8.409,8.409,0,0,1,9.692.292a8.6,8.6,0,0,1,7.624,7.956l1.44-1.5L20,8.042l-3.621,3.776-3.62-3.776L14,6.746l1.592,1.661A6.847,6.847,0,0,0,9.519,2.013a6.706,6.706,0,0,0-7.407,6,6.8,6.8,0,0,0,11.024,6.008L14.2,15.4a8.43,8.43,0,0,1-6.208,1.811ZM7.87,9.148V4.406H9.626V8.388l3.139,3.274-1.243,1.3Z" transform="translate(-16262 -5048)" fill="#7297ff" />
                                                                                                                    <rect id="Rectangle_29289" data-name="Rectangle 29289" width="20" height="20" transform="translate(-16262 -5049)" fill="none" />
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="sub-user-status">
                                                                                                            <p className="sub-user-status-detail">
                                                                                                            Last visit less than 168 hours ago.
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="sub-user-detail">
                                                                                                            <div className="sub-user-img">
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 25 18.999">
                                                                                                                    <path id="Union_1894" data-name="Union 1894" d="M5480,1140v-3h19v3Zm-6,0v-3h3v3Zm6-8v-3h19v3Zm-6,0v-3h3v3Zm6-8v-3h19v3Zm-6,0v-3h3v3Z" transform="translate(-5474 -1121)" fill="#ffc834"/>
                                                                                                                </svg>
                                                                                                            </div>
                                                                                                            <div className="sub-user-status">
                                                                                                                <p className="sub-user-status-detail">
                                                                                                                    Visit count greater than 4 sessions.
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                    </>
                                                                                }

                                                                                {rulesdata === null  && sglist.segment_name === 'Inactive Subscriptions' &&
                                                                                    <>
                                                                                     <div className="sub-user-detail">
                                                                                                        <div className="sub-user-img clockwise">
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" >
                                                                                                                <g id="Group_38919" data-name="Group 38919" transform="translate(16262 5049)" >
                                                                                                                    <path id="Union_2848" data-name="Union 2848" d="M7.991,17.206a8.59,8.59,0,0,1-7.6-9.382A8.409,8.409,0,0,1,9.692.292a8.6,8.6,0,0,1,7.624,7.956l1.44-1.5L20,8.042l-3.621,3.776-3.62-3.776L14,6.746l1.592,1.661A6.847,6.847,0,0,0,9.519,2.013a6.706,6.706,0,0,0-7.407,6,6.8,6.8,0,0,0,11.024,6.008L14.2,15.4a8.43,8.43,0,0,1-6.208,1.811ZM7.87,9.148V4.406H9.626V8.388l3.139,3.274-1.243,1.3Z" transform="translate(-16262 -5048)" fill="#7297ff" />
                                                                                                                    <rect id="Rectangle_29289" data-name="Rectangle 29289" width="20" height="20" transform="translate(-16262 -5049)" fill="none" />
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="sub-user-status">
                                                                                                            <p className="sub-user-status-detail">
                                                                                                            Last visit greater than 168 hours ago.
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                    </>
                                                                                }

                                                                                {rulesdata === null  && sglist.segment_name === 'Active Subscriptions' &&
                                                                                    <>
                                                                                     <div className="sub-user-detail">
                                                                                                        <div className="sub-user-img clockwise">
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" >
                                                                                                                <g id="Group_38919" data-name="Group 38919" transform="translate(16262 5049)" >
                                                                                                                    <path id="Union_2848" data-name="Union 2848" d="M7.991,17.206a8.59,8.59,0,0,1-7.6-9.382A8.409,8.409,0,0,1,9.692.292a8.6,8.6,0,0,1,7.624,7.956l1.44-1.5L20,8.042l-3.621,3.776-3.62-3.776L14,6.746l1.592,1.661A6.847,6.847,0,0,0,9.519,2.013a6.706,6.706,0,0,0-7.407,6,6.8,6.8,0,0,0,11.024,6.008L14.2,15.4a8.43,8.43,0,0,1-6.208,1.811ZM7.87,9.148V4.406H9.626V8.388l3.139,3.274-1.243,1.3Z" transform="translate(-16262 -5048)" fill="#7297ff" />
                                                                                                                    <rect id="Rectangle_29289" data-name="Rectangle 29289" width="20" height="20" transform="translate(-16262 -5049)" fill="none" />
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="sub-user-status">
                                                                                                            <p className="sub-user-status-detail">
                                                                                                            Last visit less than 168 hours ago.
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                    </>
                                                                                }

                                                                                { rulesdata !== null ?
                                                                                    (
                                                                                    rulesdata.map((rulelist)=>(                                
                                                                                            (rulelist.field === 'first_session') ?
                                                                                            <div className="sub-user-detail">
                                                                                                <div className="sub-user-img clockwise">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 35.61 32.391">
                                                                                                        <g id="Group_6004" data-name="Group 6004" transform="translate(5.927 2.895)">
                                                                                                            <path id="Path_10032" data-name="Path 10032" d="M13.236,0A13.236,13.236,0,1,1,0,13.236,13.38,13.38,0,0,1,3.877,3.877,13.013,13.013,0,0,1,13.236,0Z" transform="translate(0.252 0.065)" fill="none"/>
                                                                                                            <path id="Path_10033" data-name="Path 10033" d="M13.236,0A13.236,13.236,0,1,1,0,13.236,13.38,13.38,0,0,1,3.877,3.877" transform="matrix(-0.105, -0.995, 0.995, -0.105, 1.708, 27.847)" fill="none" stroke="#7297ff" stroke-width="3"/>
                                                                                                            <path id="Path_10034" data-name="Path 10034" d="M7.239,0V7.239H0" transform="translate(0.252 5.548) rotate(45)" fill="none" stroke="#7297ff" stroke-width="3"/>
                                                                                                            <path id="Path_10035" data-name="Path 10035" d="M18.382,18.733l-5.8-5.8V5.8" transform="translate(0.903 0.365)" fill="none" stroke="#7297ff" stroke-width="3"/>
                                                                                                        </g>
                                                                                                    </svg>
                                                                                                </div>
                                                                                                <div className="sub-user-status">
                                                                                                    <p className="sub-user-status-detail">
                                                                                                    First visit {rulelist.relation === '>' ? 'greater than' : 'less than'} {rulelist.hours_ago} hours ago.
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                                ((rulelist.field === 'last_session') ?
                                                                                                    <div className="sub-user-detail">
                                                                                                        <div className="sub-user-img clockwise">
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" >
                                                                                                                <g id="Group_38919" data-name="Group 38919" transform="translate(16262 5049)" >
                                                                                                                    <path id="Union_2848" data-name="Union 2848" d="M7.991,17.206a8.59,8.59,0,0,1-7.6-9.382A8.409,8.409,0,0,1,9.692.292a8.6,8.6,0,0,1,7.624,7.956l1.44-1.5L20,8.042l-3.621,3.776-3.62-3.776L14,6.746l1.592,1.661A6.847,6.847,0,0,0,9.519,2.013a6.706,6.706,0,0,0-7.407,6,6.8,6.8,0,0,0,11.024,6.008L14.2,15.4a8.43,8.43,0,0,1-6.208,1.811ZM7.87,9.148V4.406H9.626V8.388l3.139,3.274-1.243,1.3Z" transform="translate(-16262 -5048)" fill="#7297ff" />
                                                                                                                    <rect id="Rectangle_29289" data-name="Rectangle 29289" width="20" height="20" transform="translate(-16262 -5049)" fill="none" />
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="sub-user-status">
                                                                                                            <p className="sub-user-status-detail">
                                                                                                            Last visit {rulelist.relation === '>' ? 'greater than' : 'less than'} {rulelist.hours_ago} hours ago.
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                :
                                                                                                    ((rulelist.field === 'session_count') ?
                                                                                                        <div className="sub-user-detail">
                                                                                                            <div className="sub-user-img">
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 25 18.999">
                                                                                                                    <path id="Union_1894" data-name="Union 1894" d="M5480,1140v-3h19v3Zm-6,0v-3h3v3Zm6-8v-3h19v3Zm-6,0v-3h3v3Zm6-8v-3h19v3Zm-6,0v-3h3v3Z" transform="translate(-5474 -1121)" fill="#ffc834"/>
                                                                                                                </svg>
                                                                                                            </div>
                                                                                                            <div className="sub-user-status">
                                                                                                                <p className="sub-user-status-detail">
                                                                                                                    Visit count is {rulelist.relation === '>' ? 'greater than' : 'less than'} {rulelist.value} sessions.
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    :
                                                                                                        ((rulelist.field === 'session_time') ?
                                                                                                        <div className="sub-user-detail">
                                                                                                            <div className="sub-user-img clockwise">
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 29.472 29.471">
                                                                                                                    <g id="Group_6005" data-name="Group 6005" transform="translate(0.19 0.188)">
                                                                                                                        <path id="Path_10032" data-name="Path 10032" d="M13.236,0A13.236,13.236,0,1,0,26.472,13.236,13.38,13.38,0,0,0,22.6,3.877,13.013,13.013,0,0,0,13.236,0Z" transform="translate(1.31 1.312)" fill="none" stroke="#ff7db0" stroke-width="3"/>
                                                                                                                        <path id="Path_10035" data-name="Path 10035" d="M18.382,18.733l-5.8-5.8V5.8" transform="translate(2.164 1.612)" fill="none" stroke="#ff7db0" stroke-width="3"/>
                                                                                                                    </g>
                                                                                                                </svg>
                                                                                                            </div>
                                                                                                            <div className="sub-user-status">
                                                                                                                <p className="sub-user-status-detail">
                                                                                                                    Visit duration is {rulelist.relation === '>' ? 'greater than' : 'less than'} {rulelist.value} seconds.
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                            ((rulelist.field === 'language') ?
                                                                                                            <div className="sub-user-detail">
                                                                                                            <div className="sub-user-img clockwise">
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 30 30">
                                                                                                                    <g id="Group_29016" data-name="Group 29016" transform="translate(-973 -226)">
                                                                                                                        <path id="Subtraction_61" data-name="Subtraction 61" d="M13.855,24H2.058L0,18.546H6.349L.089,0H13.855A2.166,2.166,0,0,1,16,2.181V21.817A2.165,2.165,0,0,1,13.855,24Z" transform="translate(987 232.001)" fill="#47c2ff"/>
                                                                                                                        <path id="Path_10055" data-name="Path 10055" d="M2.111,0h8.916L19,23H2.111A2.1,2.1,0,0,1,0,20.909V2.091A2.1,2.1,0,0,1,2.111,0Z" transform="translate(974.5 227.5)" fill="none" stroke="#47c2ff" stroke-linejoin="round" stroke-width="3"/>
                                                                                                                        <path id="Path_10049" data-name="Path 10049" d="M944.186,402.009l3.664-9.746,3.665,9.746" transform="translate(34.349 -157.679)" fill="none" stroke="#47c2ff" stroke-linejoin="round" stroke-width="2"/>
                                                                                                                        <path id="Path_10050" data-name="Path 10050" d="M945.605,396.75h4.016" transform="translate(34.606 -155.684)" fill="none" stroke="#47c2ff" stroke-width="2"/>
                                                                                                                        <path id="Path_10051" data-name="Path 10051" d="M956.132,396.75h9.259" transform="translate(35.343 -156.339)" fill="none" stroke="#fff" stroke-width="2"/>
                                                                                                                        <path id="Path_10052" data-name="Path 10052" d="M959.729,395.6v2.448" transform="translate(35.643 -157.667)" fill="none" stroke="#fff" stroke-width="2"/>
                                                                                                                        <path id="Path_10053" data-name="Path 10053" d="M963.23,396.75a10.889,10.889,0,0,1-6.058,8.4" transform="translate(35.43 -156.339)" fill="none" stroke="#fff" stroke-width="2"/>
                                                                                                                        <path id="Path_10054" data-name="Path 10054" d="M957.881,399.721a27.582,27.582,0,0,0,5.764,6.442" transform="translate(35.016 -156.091)" fill="none" stroke="#fff" stroke-width="2"/>
                                                                                                                    </g>
                                                                                                                </svg>
                                                                                                            </div>
                                                                                                            <div className="sub-user-status">
                                                                                                                <p className="sub-user-status-detail">
                                                                                                                    Language {rulelist.relation === '=' ? 'is ' : 'is not '} 
                                                                                                                    
                                                                                                                    {languageoption.map((langval) => { return (langval.value === rulelist.value) ? langval.label : "" }
                                                                                                                                )}.
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                            :
                                                                                                                ((rulelist.field === 'app_version') ?
                                                                                                                <div className="sub-user-detail">
                                                                                                                    <div className="sub-user-img clockwise">
                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 32">
                                                                                                                            <g id="Group_29017" data-name="Group 29017" transform="translate(-1102 -227)">
                                                                                                                                <circle id="Ellipse_7491" data-name="Ellipse 7491" cx="4.5" cy="4.5" r="4.5" transform="translate(1102 227)" fill="#a678ff"/>
                                                                                                                                <circle id="Ellipse_7493" data-name="Ellipse 7493" cx="4.5" cy="4.5" r="4.5" transform="translate(1102 250)" fill="#a678ff"/>
                                                                                                                                <circle id="Ellipse_7492" data-name="Ellipse 7492" cx="4.5" cy="4.5" r="4.5" transform="translate(1115 227)" fill="#a678ff"/>
                                                                                                                                <rect id="Rectangle_20968" data-name="Rectangle 20968" width="3" height="18" transform="translate(1105 234)" fill="#a678ff"/>
                                                                                                                                <path id="Path_83721" data-name="Path 83721" d="M20.5,8v7.75a4.844,4.844,0,0,1-4.844,4.844H7" transform="translate(1099 224)" fill="none" stroke="#a678ff" stroke-width="3"/>
                                                                                                                            </g>
                                                                                                                        </svg>
                                                                                                                    </div>
                                                                                                                    <div className="sub-user-status">
                                                                                                                        <p className="sub-user-status-detail">
                                                                                                                        App Version{" "}
                                                                                                                        {
                                                                                                                            (rulelist.relation === '>' ? 
                                                                                                                                'is greater than ' 
                                                                                                                            : 
                                                                                                                                (rulelist.relation === '<' ?
                                                                                                                                    'is less than '
                                                                                                                                :
                                                                                                                                    (rulelist.relation === '=' ?
                                                                                                                                        'is equal to '
                                                                                                                                    :
                                                                                                                                        'is not equal to '
                                                                                                                                    )
                                                                                                                                )
                                                                                                                            )

                                                                                                                        }
                                                                                                                        {rulelist.value}.
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                :
                                                                                                                    ((rulelist.field === 'location') ?
                                                                                                                    <div className="sub-user-detail">
                                                                                                                        <div className="sub-user-img clockwise">
                                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.23 30.328">
                                                                                                                                <path id="Path_83752" data-name="Path 83752" d="M20.615,4A10.617,10.617,0,0,0,10,14.615c0,7.961,10.615,19.713,10.615,19.713S31.23,22.576,31.23,14.615A10.617,10.617,0,0,0,20.615,4Zm0,14.406a3.791,3.791,0,1,1,3.791-3.791A3.792,3.792,0,0,1,20.615,18.406Z" transform="translate(-10 -4)" fill="#ff7d7d"/>
                                                                                                                            </svg>
                                                                                                                        </div>
                                                                                                                        <div className="sub-user-status">
                                                                                                                            <p className="sub-user-status-detail">
                                                                                                                            Location is within {rulelist.radius} meters of {rulelist.lat}, {rulelist.long}
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    :
                                                                                                                        ((rulelist.field === 'email') ?
                                                                                                                        <div className="sub-user-detail">
                                                                                                                        <div className="sub-user-img clockwise">
                                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 30 23">
                                                                                                                                <g id="Group_6025" data-name="Group 6025" transform="translate(-1123.036 -583.555)">
                                                                                                                                <path id="Subtraction_247" data-name="Subtraction 247" d="M6282.374,1340.328h-26a2,2,0,0,1-2-2V1320l13.83,12.216a1.5,1.5,0,0,0,1.972.013l14.2-12.234v18.335A2,2,0,0,1,6282.374,1340.328Zm-13.165-11.229h0l-13.272-11.723a2,2,0,0,1,.437-.048h26a2.017,2.017,0,0,1,.439.049l-13.6,11.722Z" transform="translate(-5131.339 -733.772)" fill="#40d1ec"/>
                                                                                                                                </g>
                                                                                                                            </svg>
                                                                                                                        </div>
                                                                                                                        <div className="sub-user-status">
                                                                                                                            <p className="sub-user-status-detail">
                                                                                                                                Email is {rulelist.value}.
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                        :
                                                                                                                            ((rulelist.field === 'country') ?
                                                                                                                            <div className="sub-user-detail">
                                                                                                                            <div className="sub-user-img clockwise">
                                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 29.999 29.98">
                                                                                                                                    <path id="Path_83722" data-name="Path 83722" d="M52.447,45.594a14.837,14.837,0,0,0,1.5-6.532A15.025,15.025,0,0,0,42.815,24.571h-.072a14.967,14.967,0,0,0-13.539,3.1l-.187.173a14.981,14.981,0,0,0,9.935,26.214H39.3A15.039,15.039,0,0,0,52.36,45.782h0Zm-.807-6.532a12.876,12.876,0,0,1-.793,4.427l-6.69-4.109a2.307,2.307,0,0,0-.908-.332l-3.288-.433a2.321,2.321,0,0,0-2.206.98h-1.24l-.548-1.139A2.336,2.336,0,0,0,34.38,37.2l-.952-.2.36-.851a1.168,1.168,0,0,1,1.067-.707h2.321a2.321,2.321,0,0,0,1.11-.288l1.759-.98a2.321,2.321,0,0,0,.433-.3l3.879-3.518a2.264,2.264,0,0,0,.649-2.437A12.689,12.689,0,0,1,51.639,39.063Zm-25.378,0a12.559,12.559,0,0,1,1.37-5.724l1.5,4.023A2.321,2.321,0,0,0,30.8,38.8l.793.173h.014l1.73.375a1.125,1.125,0,0,1,.793.62l.3.634a2.321,2.321,0,0,0,2.076,1.3h.173l-1.11,2.48a2.293,2.293,0,0,0,.4,2.509L38.3,49.4a1.2,1.2,0,0,1,.288.995l-.26,1.341A12.7,12.7,0,0,1,26.262,39.063Z" transform="translate(-23.947 -24.078)" fill="#83d298"/>
                                                                                                                                </svg>
                                                                                                                            </div>
                                                                                                                            <div className="sub-user-status">
                                                                                                                                <p className="sub-user-status-detail">
                                                                                                                                Country {rulelist.relation === '=' ? 'is ' : 'is not '} 
                                                                                                                                
                                                                                                                                {countryoption.map((countryval) => { return (countryval.value === rulelist.value) ? countryval.label : "" }
                                                                                                                                )}
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                            :
                                                                                                                                null
                                                                                                                            )
                                                                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                            )    
                                        )                                    
                                    )
                                                                                    )
                                                                                :
                                                                                    null
                                                                                }

                                                                    
                                                                                
                                                                            </div>
                                                                        </td>
                                                                        <td className="user-date">
                                                                            <p> { moment( sglist.updated_at ).format("D MMMM YYYY, HH:mm") } </p>
                                                                        </td>

                                                                        <td className="edit-img">
                                                                            { ( rulesdata !== null && sglist.segment_name !== 'Total Subscriptions') &&
                                                                            <div className="edit-img-wrapper">
                                                                                <a data-bs-toggle="modal" data-bs-target="#edit-modal" type="button" onClick={(e)=> handleEditNotification(sglist.id, e)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16.314" height="16.314" viewBox="0 0 16.314 16.314" >
                                                                                        <g id="Group_37916" data-name="Group 37916" transform="translate(-999.091 -1409.91) rotate(45)" >
                                                                                            <g id="Path_82084" data-name="Path 82084" transform="translate(1713 281)" fill="none" >
                                                                                                <path d="M0,1H4A1,1,0,0,1,5,2L4.9,14.9,1.814,17.985l-2.9-2.9L-1,2A1,1,0,0,1,0,1Z" stroke="none" />
                                                                                                <path d="M -2.384185791015625e-07 1.999998092651367 L -2.026557922363281e-05 2.006567001342773 L -0.08325338363647461 14.67448616027832 L 1.813512086868286 16.57125854492188 L 3.902721405029297 14.4820556640625 L 3.999999761581421 1.999998092651367 L -2.384185791015625e-07 1.999998092651367 M -2.384185791015625e-07 0.9999980926513672 L 3.999999761581421 0.9999980926513672 C 4.552279949188232 0.9999980926513672 5 1.447717666625977 5 1.999998092651367 L 4.899499893188477 14.89949798583984 L 1.813509702682495 17.98547744750977 L -1.085980415344238 15.08597755432129 L -1 1.999998092651367 C -1 1.447717666625977 -0.5522804260253906 0.9999980926513672 -2.384185791015625e-07 0.9999980926513672 Z" stroke="none" fill="#7782a1" />
                                                                                            </g>
                                                                                            <path id="Path_82085" data-name="Path 82085" d="M-4459-11434.725l5.643-.017" transform="translate(6171 11721)" fill="none" stroke="#7782a1" stroke-width="1" />
                                                                                        </g>
                                                                                    </svg>
                                                                                </a>
                                                                            </div>
                                                                            }
                                                                        </td>

                                                                        <td className="delete-img">
                                                                            { sglist.segment_name !== 'Total Subscriptions' &&
                                                                            <div className="delete-img-wrapper" onClick={ (e) =>handleDeleteSegmentPopupShow(e, sglist.id) }>
                                                                                <a data-bs-toggle="modal" data-bs-target="#remove-modal" type="button" >
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" >
                                                                                        <g transform="translate(-835.516 -682.992)">
                                                                                            <g transform="translate(836.515 684.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" >
                                                                                                <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none" />
                                                                                                <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none" />
                                                                                            </g>
                                                                                            <g transform="translate(839.515 682.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" >
                                                                                                <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none" />
                                                                                                <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none" />
                                                                                            </g>
                                                                                            <g transform="translate(841.016 687.993)">
                                                                                                <line y2="6" transform="translate(3 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                                                                                                <line y1="6" transform="translate(0 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                                                                                            </g>
                                                                                            <line x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                                                                                        </g>
                                                                                    </svg>
                                                                                </a>
                                                                            </div>
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                )})                                                            
                                                            :
                                                                null
                                                            }

                                                            
                                                            
                                                            
                                                        </table>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>                                    
                                )
                            } 
                            
                        </div>
                    </div>
                :
                    <div className="table-mobile-view">
                        <div class="audience-right-box">
                            <div className="audience-heading">
                                <div class="audience-heading-content d-flex justify-content-between">
                                    <div class="tooltip-handler d-flex">
                                        <h1>Audience</h1>
                                    </div>

                                    <div className="d-flex align-items-center">
                                    {code !== 200 && skipButtonActive &&   <PremiumTag/>}
                                  {/*      <Link>
                                    <div className="notification-setting " >
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>
    

                        </div>
                        </Link> */}
                                        {/* { (code === 200 && segmentdata.length < 10) &&
                                        <div className="notifications-create-btn d-flex" onClick={handleCreatePage}>
                                            <span className="notifications-create-btn-svg">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" >
                                                    <g id="Group_3055" data-name="Group 3055" transform="translate(-0.223)" >
                                                        <g id="Group_1664" data-name="Group 1664" transform="translate(0.223 6.999)" >
                                                            <line id="Line_85" data-name="Line 85" y2="14" transform="translate(14) rotate(90)" fill="none" stroke="#3064f9" stroke-width="2" />
                                                        </g>
                                                        <g id="Group_1665" data-name="Group 1665" transform="translate(7.223 0) rotate(90)" >
                                                            <line id="Line_85-2" data-name="Line 85" y2="14" transform="translate(14) rotate(90)" fill="none" stroke="#3064f9" stroke-width="2" />
                                                        </g>
                                                    </g>
                                                </svg>
                                            </span>
                                            <p>Create</p>
                                        </div>
                                        } */}

                                        {( code === 200 || skipButtonActive )&&
                                        <>
                                        {/* <div className="consent-setting-clicked" style={{marginRight: "0px",marginLeft:"0px" ,cursor:"default"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                       <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>
                            </div> */}
                                        <div className="consent-setting" style={{ margin: "0px 0px 0px 20px" }}>

                                            <Link to={"/app/notifications/build-settings"} className="pointcursor" style={{border: "unset", margin: "0px"}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="24" viewBox="0 0 26 24">
                                                        <g id="Group_43299" data-name="Group 43299" transform="translate(-1718 -125)">
                                                            <rect id="Rectangle_32508" data-name="Rectangle 32508" width="26" height="24" transform="translate(1718 125)" fill="none" />
                                                            <g id="Rectangle_31502" data-name="Rectangle 31502" transform="translate(1722 125)" fill="#f8fafe" stroke="#7782a1" stroke-width="1.5">
                                                                <rect width="15" height="23" rx="3" stroke="none" />
                                                                <rect x="0.75" y="0.75" width="13.5" height="21.5" rx="2.25" fill="none" />
                                                            </g>
                                                            <g id="Group_38341" data-name="Group 38341" transform="translate(1708.245 -393.755)">
                                                                <rect id="Rectangle_25572" data-name="Rectangle 25572" width="16" height="16" transform="translate(19.755 526.755)" fill="none" />
                                                                <path id="Path_99646" data-name="Path 99646" d="M7.234,13.953a6.622,6.622,0,0,0,.774-.045.376.376,0,0,0,.331-.393,1.49,1.49,0,0,1,.91-1.461,1.393,1.393,0,0,1,.542-.1,1.546,1.546,0,0,1,1.134.482.377.377,0,0,0,.512.041A6.708,6.708,0,0,0,12.5,11.4a.376.376,0,0,0-.04-.507A1.487,1.487,0,0,1,13.436,8.3h.034a.446.446,0,0,0,.441-.331,6.727,6.727,0,0,0,0-1.528.362.362,0,0,0-.375-.33H13.52a1.555,1.555,0,0,1-1.464-.91,1.491,1.491,0,0,1,.375-1.678.375.375,0,0,0,.04-.511,6.678,6.678,0,0,0-1.087-1.075.37.37,0,0,0-.23-.08.376.376,0,0,0-.277.125,1.549,1.549,0,0,1-1.134.48,1.381,1.381,0,0,1-.531-.1A1.5,1.5,0,0,1,8.29.928.375.375,0,0,0,7.957.543a6.741,6.741,0,0,0-1.513,0,.376.376,0,0,0-.33.394A1.494,1.494,0,0,1,5.205,2.4a1.418,1.418,0,0,1-.544.105,1.544,1.544,0,0,1-1.134-.482.376.376,0,0,0-.512-.042A6.707,6.707,0,0,0,1.952,3.054a.376.376,0,0,0,.04.507A1.486,1.486,0,0,1,.976,6.146a.439.439,0,0,0-.433.33,6.75,6.75,0,0,0,0,1.53.377.377,0,0,0,.367.331H1.02A1.507,1.507,0,0,1,2.4,9.25a1.491,1.491,0,0,1-.376,1.678.376.376,0,0,0-.04.512,6.73,6.73,0,0,0,1.086,1.074.376.376,0,0,0,.508-.042,1.55,1.55,0,0,1,1.134-.48,1.406,1.406,0,0,1,.532.1,1.494,1.494,0,0,1,.921,1.435.375.375,0,0,0,.333.384A6.794,6.794,0,0,0,7.234,13.953Z" transform="translate(20.669 528.038)" fill="#f8fafe" stroke="#7782a1" stroke-linejoin="round" stroke-width="1.5" />
                                                                <path id="Path_99647" data-name="Path 99647" d="M8.082,5.846A2.264,2.264,0,1,1,5.818,8.111,2.265,2.265,0,0,1,8.082,5.846Z" transform="translate(19.826 527.202)" fill="#f8fafe" stroke="#7782a1" stroke-linejoin="round" stroke-width="1.5" />
                                                            </g>
                                                            <path id="Rectangle_31503" data-name="Rectangle 31503" d="M0,0H5A0,0,0,0,1,5,0V1A1,1,0,0,1,4,2H1A1,1,0,0,1,0,1V0A0,0,0,0,1,0,0Z" transform="translate(1727 126)" fill="#7782a1" />
                                                        </g>
                                                    </svg>
                                            </Link>
                                        </div>
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                      (localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) &&
                      window.matchMedia("(max-width: 1200px)").matches && code !== 200 && spinner === false &&
                    <div style={{marginLeft: "0px", marginTop: "15px"}} className="upgrade-feature">
                      <h4 style={{textAlign: "unset"}} className="upgrade-unavailable">This feature is currently unavailable</h4>
                      <p style={{textAlign: "unset"}} className="upgrade-support">Please contact Support for assistance.</p>
                    </div>
                }                    
                          {(code === 200 || skipButtonActive ) &&  <div className="audience-mob-create-btn" onClick={handleCreatePage}>              
                            <img src={ require("../../assets/images/dashboard/plus.png").default } className="img-fluid mr-2 symbolspace" alt="plus" />
                            Create                      
                           </div>}
                            <>
                                { code === 200 &&
                                <div className="pushnotification-Search w-100 audience-search">
                                    <form>
                                        <button>
                                            <svg id="Group_3669" data-name="Group 3669" xmlns="http://www.w3.org/2000/svg" width="16.53" height="16.53" viewBox="0 0 16.53 16.53" >
                                                <g id="Ellipse_246" data-name="Ellipse 246" transform="translate(14.675) rotate(90)" fill="none" stroke="#bcc1ce" stroke-width="1.5" >
                                                    <ellipse cx="7.337" cy="7.337" rx="7.337" ry="7.337" stroke="none" />
                                                    <ellipse cx="7.337" cy="7.337" rx="6.587" ry="6.587" fill="none" />
                                                </g>
                                                <path id="Path_6064" data-name="Path 6064" d="M0,5.347V0" transform="translate(16 16) rotate(135)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                                            </svg>
                                        </button>
                                        <input placeholder="Search"></input>
                                    </form>
                                </div>
                                }

                                
                                
                                <div style={{ "position":"relative" }}>
                                {spinner ?
                                    <div  className="audience-spinner">
                                        <NewSpinner />
                                    </div>
                                :
                                    
                                    ( (code !== 200 && !skipButtonActive) ?
                                        <div className="audience-inner-box">
                                            <div className="audience-icon-section-width">
                                                <div className="audience-icon-section-one audience-icon-section-one-width">
                                                    <h2>Audience</h2>
                                                    <p>Create targeted lists of users for maximum impact. </p>
                                                    <ul>
                                                        <li> Craft segment-focused customer messaging.</li>
                                                        <li>Segment by demographics, user-activity and more.</li>
                                                        <li> Reach your desired audience effectively. </li>
                                                        <li>Enhance user engagement with optimized campaigns. </li>
                                                    </ul>

                                                    {/*<div className="app-icon-buttons">
                                                        <span>{
                                ( reseller_package_id || (plan_code && plan_code.includes("appsumo") ))?"Buy addon":"Upgrade"
                              } plan</span>
                                                    </div>*/}
                                                    <div className="upgrade-audience-mob">
                                                    { (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ?
                                                        <div className="app-icon-buttons" onClick={handleRedirectPlanPage}>
                                                            <span className="notification_upgradebtn">{
                                ( reseller_package_id || (plan_code && plan_code.includes("appsumo") ))?"Buy addon":"Upgrade"
                              }</span>
                                                        </div>
                                                    :
                                                        null
                                                    }
                                                    		<div className="skip-btn-head" onClick={handleSkipClick}>
						                                   <SkipBtn/>
					                                    	</div>
                                                            </div>

                                                </div>
                                                <div className="audience-icon-section-two">
                                                    <img className="img-fluid" src={ require("../../assets/images/dashboard/Audience.png").default } alt="Audience-icon-default" />
                                                </div>
                                            </div>
                                        </div>
                                    :
                                        (segmentdata.length <= 0 ?

                                            <div className='nodatintable'>
                                                <img src={ require("../../assets/images/dashboard/usertablenodata.png").default } alt="addmobpre" className="img-fluid " />
                                                <p>Nothing here at the moment. Come back later?</p>
                                            </div>
                                        :
                                            (segmentdata !== null && segmentdata !== '' && segmentdata !== undefined ?
                                                segmentdata.map((sglist, sgidx)=>{

                                                    const rulesdata = JSON.parse(sglist.rules_json);

                                                    return (
                                                    <div class="audience-inner-box">
                                                        <div class="audience-inner-userid">
                                                            <div className="user-profile">
                                                                <p className="sub-user">{ sglist.segment_name }</p>
                                                                { rulesdata !== null ?
                                                                    (
                                                                        rulesdata.map((rulelist)=>(
                                                                            ((rulelist.field === 'first_session') ?
                                                                            <div className="sub-user-detail">
                                                                                <div className="sub-user-img clockwise">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 35.61 32.391">
                                                                                        <g id="Group_6004" data-name="Group 6004" transform="translate(5.927 2.895)">
                                                                                            <path id="Path_10032" data-name="Path 10032" d="M13.236,0A13.236,13.236,0,1,1,0,13.236,13.38,13.38,0,0,1,3.877,3.877,13.013,13.013,0,0,1,13.236,0Z" transform="translate(0.252 0.065)" fill="none"/>
                                                                                            <path id="Path_10033" data-name="Path 10033" d="M13.236,0A13.236,13.236,0,1,1,0,13.236,13.38,13.38,0,0,1,3.877,3.877" transform="matrix(-0.105, -0.995, 0.995, -0.105, 1.708, 27.847)" fill="none" stroke="#7297ff" stroke-width="3"/>
                                                                                            <path id="Path_10034" data-name="Path 10034" d="M7.239,0V7.239H0" transform="translate(0.252 5.548) rotate(45)" fill="none" stroke="#7297ff" stroke-width="3"/>
                                                                                            <path id="Path_10035" data-name="Path 10035" d="M18.382,18.733l-5.8-5.8V5.8" transform="translate(0.903 0.365)" fill="none" stroke="#7297ff" stroke-width="3"/>
                                                                                        </g>
                                                                                    </svg>
                                                                                </div>
                                                                                <div className="sub-user-status">
                                                                                    <p className="sub-user-status-detail">
                                                                                    First visit {rulelist.relation === '>' ? 'greater than' : 'less than'} {rulelist.hours_ago} hours ago.
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                            :
                                                                                ((rulelist.field === 'last_session') ?
                                                                                <div className="sub-user-detail">
                                                                                    <div className="sub-user-img clockwise">
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" >
                                                                                            <g id="Group_38919" data-name="Group 38919" transform="translate(16262 5049)" >
                                                                                                <path id="Union_2848" data-name="Union 2848" d="M7.991,17.206a8.59,8.59,0,0,1-7.6-9.382A8.409,8.409,0,0,1,9.692.292a8.6,8.6,0,0,1,7.624,7.956l1.44-1.5L20,8.042l-3.621,3.776-3.62-3.776L14,6.746l1.592,1.661A6.847,6.847,0,0,0,9.519,2.013a6.706,6.706,0,0,0-7.407,6,6.8,6.8,0,0,0,11.024,6.008L14.2,15.4a8.43,8.43,0,0,1-6.208,1.811ZM7.87,9.148V4.406H9.626V8.388l3.139,3.274-1.243,1.3Z" transform="translate(-16262 -5048)" fill="#7297ff" />
                                                                                                <rect id="Rectangle_29289" data-name="Rectangle 29289" width="20" height="20" transform="translate(-16262 -5049)" fill="none" />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </div>
                                                                                    <div className="sub-user-status">
                                                                                        <p className="sub-user-status-detail">
                                                                                        Last visit {rulelist.relation === '>' ? 'greater than' : 'less than'} {rulelist.hours_ago} hours ago.
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                                :
                                                                                    ((rulelist.field === 'session_count') ?
                                                                                    <div className="sub-user-detail">
                                                                                        <div className="sub-user-img">
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 25 18.999">
                                                                                                <path id="Union_1894" data-name="Union 1894" d="M5480,1140v-3h19v3Zm-6,0v-3h3v3Zm6-8v-3h19v3Zm-6,0v-3h3v3Zm6-8v-3h19v3Zm-6,0v-3h3v3Z" transform="translate(-5474 -1121)" fill="#ffc834"/>
                                                                                            </svg>
                                                                                        </div>
                                                                                        <div className="sub-user-status">
                                                                                            <p className="sub-user-status-detail">
                                                                                                Visit count is {rulelist.relation === '>' ? 'greater than' : 'less than'} {rulelist.value} sessions.
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    :
                                                                                        ((rulelist.field === 'session_time') ?
                                                                                        <div className="sub-user-detail">
                                                                                            <div className="sub-user-img clockwise">
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 29.472 29.471">
                                                                                                    <g id="Group_6005" data-name="Group 6005" transform="translate(0.19 0.188)">
                                                                                                        <path id="Path_10032" data-name="Path 10032" d="M13.236,0A13.236,13.236,0,1,0,26.472,13.236,13.38,13.38,0,0,0,22.6,3.877,13.013,13.013,0,0,0,13.236,0Z" transform="translate(1.31 1.312)" fill="none" stroke="#ff7db0" stroke-width="3"/>
                                                                                                        <path id="Path_10035" data-name="Path 10035" d="M18.382,18.733l-5.8-5.8V5.8" transform="translate(2.164 1.612)" fill="none" stroke="#ff7db0" stroke-width="3"/>
                                                                                                    </g>
                                                                                                </svg>
                                                                                            </div>
                                                                                            <div className="sub-user-status">
                                                                                                <p className="sub-user-status-detail">
                                                                                                    Visit duration is {rulelist.relation === '>' ? 'greater than' : 'less than'} {rulelist.value} seconds.
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                        :
                                                                                            ((rulelist.field === 'language') ?
                                                                                            <div className="sub-user-detail">
                                                                                                <div className="sub-user-img clockwise">
                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 30 30">
                                                                                                        <g id="Group_29016" data-name="Group 29016" transform="translate(-973 -226)">
                                                                                                            <path id="Subtraction_61" data-name="Subtraction 61" d="M13.855,24H2.058L0,18.546H6.349L.089,0H13.855A2.166,2.166,0,0,1,16,2.181V21.817A2.165,2.165,0,0,1,13.855,24Z" transform="translate(987 232.001)" fill="#47c2ff"/>
                                                                                                            <path id="Path_10055" data-name="Path 10055" d="M2.111,0h8.916L19,23H2.111A2.1,2.1,0,0,1,0,20.909V2.091A2.1,2.1,0,0,1,2.111,0Z" transform="translate(974.5 227.5)" fill="none" stroke="#47c2ff" stroke-linejoin="round" stroke-width="3"/>
                                                                                                            <path id="Path_10049" data-name="Path 10049" d="M944.186,402.009l3.664-9.746,3.665,9.746" transform="translate(34.349 -157.679)" fill="none" stroke="#47c2ff" stroke-linejoin="round" stroke-width="2"/>
                                                                                                            <path id="Path_10050" data-name="Path 10050" d="M945.605,396.75h4.016" transform="translate(34.606 -155.684)" fill="none" stroke="#47c2ff" stroke-width="2"/>
                                                                                                            <path id="Path_10051" data-name="Path 10051" d="M956.132,396.75h9.259" transform="translate(35.343 -156.339)" fill="none" stroke="#fff" stroke-width="2"/>
                                                                                                            <path id="Path_10052" data-name="Path 10052" d="M959.729,395.6v2.448" transform="translate(35.643 -157.667)" fill="none" stroke="#fff" stroke-width="2"/>
                                                                                                            <path id="Path_10053" data-name="Path 10053" d="M963.23,396.75a10.889,10.889,0,0,1-6.058,8.4" transform="translate(35.43 -156.339)" fill="none" stroke="#fff" stroke-width="2"/>
                                                                                                            <path id="Path_10054" data-name="Path 10054" d="M957.881,399.721a27.582,27.582,0,0,0,5.764,6.442" transform="translate(35.016 -156.091)" fill="none" stroke="#fff" stroke-width="2"/>
                                                                                                        </g>
                                                                                                    </svg>
                                                                                                </div>
                                                                                                <div className="sub-user-status">
                                                                                                    <p className="sub-user-status-detail">
                                                                                                        Language {rulelist.relation === '=' ? 'is ' : 'is not '} 
                                                                                                        
                                                                                                        {languageoption.map((langval) => { return (langval.value === rulelist.value) ? langval.label : "" }
                                                                                                                    )}.
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                            :
                                                                                                ((rulelist.field === 'app_version') ?
                                                                                                <div className="sub-user-detail">
                                                                                                    <div className="sub-user-img clockwise">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 22 32">
                                                                                                            <g id="Group_29017" data-name="Group 29017" transform="translate(-1102 -227)">
                                                                                                                <circle id="Ellipse_7491" data-name="Ellipse 7491" cx="4.5" cy="4.5" r="4.5" transform="translate(1102 227)" fill="#a678ff"/>
                                                                                                                <circle id="Ellipse_7493" data-name="Ellipse 7493" cx="4.5" cy="4.5" r="4.5" transform="translate(1102 250)" fill="#a678ff"/>
                                                                                                                <circle id="Ellipse_7492" data-name="Ellipse 7492" cx="4.5" cy="4.5" r="4.5" transform="translate(1115 227)" fill="#a678ff"/>
                                                                                                                <rect id="Rectangle_20968" data-name="Rectangle 20968" width="3" height="18" transform="translate(1105 234)" fill="#a678ff"/>
                                                                                                                <path id="Path_83721" data-name="Path 83721" d="M20.5,8v7.75a4.844,4.844,0,0,1-4.844,4.844H7" transform="translate(1099 224)" fill="none" stroke="#a678ff" stroke-width="3"/>
                                                                                                            </g>
                                                                                                        </svg>
                                                                                                    </div>
                                                                                                    <div className="sub-user-status">
                                                                                                        <p className="sub-user-status-detail">
                                                                                                        App Version 
                                                                                                        {
                                                                                                            (rulelist.relation === '>' ? 
                                                                                                                'is greater than ' 
                                                                                                            : 
                                                                                                                (rulelist.relation === '<' ?
                                                                                                                    'is less than '
                                                                                                                :
                                                                                                                    (rulelist.relation === '=' ?
                                                                                                                        'is equal to '
                                                                                                                    :
                                                                                                                        'is not equal to '
                                                                                                                    )
                                                                                                                )
                                                                                                            )

                                                                                                        }
                                                                                                        {rulelist.value}.
                                                                                                        </p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                :
                                                                                                    ((rulelist.field === 'location') ?
                                                                                                    <div className="sub-user-detail">
                                                                                                        <div className="sub-user-img clockwise">
                                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.23 30.328">
                                                                                                                <path id="Path_83752" data-name="Path 83752" d="M20.615,4A10.617,10.617,0,0,0,10,14.615c0,7.961,10.615,19.713,10.615,19.713S31.23,22.576,31.23,14.615A10.617,10.617,0,0,0,20.615,4Zm0,14.406a3.791,3.791,0,1,1,3.791-3.791A3.792,3.792,0,0,1,20.615,18.406Z" transform="translate(-10 -4)" fill="#ff7d7d"/>
                                                                                                            </svg>
                                                                                                        </div>
                                                                                                        <div className="sub-user-status">
                                                                                                            <p className="sub-user-status-detail">
                                                                                                            Location is within {rulelist.radius} meters of {rulelist.lat}, {rulelist.long}
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    :
                                                                                                        ((rulelist.field === 'email') ?
                                                                                                        <div className="sub-user-detail">
                                                                                                            <div className="sub-user-img clockwise">
                                                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 30 23">
                                                                                                                    <g id="Group_6025" data-name="Group 6025" transform="translate(-1123.036 -583.555)">
                                                                                                                    <path id="Subtraction_247" data-name="Subtraction 247" d="M6282.374,1340.328h-26a2,2,0,0,1-2-2V1320l13.83,12.216a1.5,1.5,0,0,0,1.972.013l14.2-12.234v18.335A2,2,0,0,1,6282.374,1340.328Zm-13.165-11.229h0l-13.272-11.723a2,2,0,0,1,.437-.048h26a2.017,2.017,0,0,1,.439.049l-13.6,11.722Z" transform="translate(-5131.339 -733.772)" fill="#40d1ec"/>
                                                                                                                    </g>
                                                                                                                </svg>
                                                                                                            </div>
                                                                                                            <div className="sub-user-status">
                                                                                                                <p className="sub-user-status-detail">
                                                                                                                    Email is {rulelist.value}.
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        :
                                                                                                            ((rulelist.field === 'country') ?
                                                                                                            <div className="sub-user-detail">
                                                                                                                <div className="sub-user-img clockwise">
                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 29.999 29.98">
                                                                                                                        <path id="Path_83722" data-name="Path 83722" d="M52.447,45.594a14.837,14.837,0,0,0,1.5-6.532A15.025,15.025,0,0,0,42.815,24.571h-.072a14.967,14.967,0,0,0-13.539,3.1l-.187.173a14.981,14.981,0,0,0,9.935,26.214H39.3A15.039,15.039,0,0,0,52.36,45.782h0Zm-.807-6.532a12.876,12.876,0,0,1-.793,4.427l-6.69-4.109a2.307,2.307,0,0,0-.908-.332l-3.288-.433a2.321,2.321,0,0,0-2.206.98h-1.24l-.548-1.139A2.336,2.336,0,0,0,34.38,37.2l-.952-.2.36-.851a1.168,1.168,0,0,1,1.067-.707h2.321a2.321,2.321,0,0,0,1.11-.288l1.759-.98a2.321,2.321,0,0,0,.433-.3l3.879-3.518a2.264,2.264,0,0,0,.649-2.437A12.689,12.689,0,0,1,51.639,39.063Zm-25.378,0a12.559,12.559,0,0,1,1.37-5.724l1.5,4.023A2.321,2.321,0,0,0,30.8,38.8l.793.173h.014l1.73.375a1.125,1.125,0,0,1,.793.62l.3.634a2.321,2.321,0,0,0,2.076,1.3h.173l-1.11,2.48a2.293,2.293,0,0,0,.4,2.509L38.3,49.4a1.2,1.2,0,0,1,.288.995l-.26,1.341A12.7,12.7,0,0,1,26.262,39.063Z" transform="translate(-23.947 -24.078)" fill="#83d298"/>
                                                                                                                    </svg>
                                                                                                                </div>
                                                                                                                <div className="sub-user-status">
                                                                                                                    <p className="sub-user-status-detail">
                                                                                                                    Country {rulelist.relation === '=' ? 'is ' : 'is not '} 
                                                                                                                    
                                                                                                                    {countryoption.map((countryval) => { return (countryval.value === rulelist.value) ? countryval.label : "" }
                                                                                                                    )}
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            :
                                                                                                                null
                                                                                                            )
                                                                                                        )
                                                                                                    )
                                                                                                )
                                                                                            )
                                                                                        )
                                                                                    )
                                                                                )
                                                                            )
                                                                        ))
                                                                    )
                                                                :
                                                                    null
                                                                }
                                                            </div>
                                                            
                                                            { rulesdata === null &&
                                                                ((sglist.segment_name === 'Total Subscriptions') ?
                                                                <div className="default-status">
                                                                    <span class="headings">Default</span>
                                                                </div>
                                                                :
                                                                    null
                                                                )
                                                            }
                                                        </div>

                                                        <div class="audience-inner-status">
                                                            <span class="headings">Created</span>
                                                            <div class="status-wrapper d-flex justify-content-between">
                                                                <div class="status-text">
                                                                    <span class="audience-user-status">{ moment( sglist.updated_at ).format("D MMMM YYYY, HH:mm") }</span>
                                                                </div>
                                                                <div class="status-icon">
                                                                    
                                                                    { (rulesdata !== null && sglist.segment_name !== 'Total Subscriptions') &&
                                                                    <a data-bs-toggle="modal" data-bs-target="#edit-modal" type="button" class="edit" onClick={(e)=> handleEditNotification(sglist.id, e)} >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.314" height="16.314" viewBox="0 0 16.314 16.314" >
                                                                            <g id="Group_37916" data-name="Group 37916" transform="translate(-999.091 -1409.91) rotate(45)" >
                                                                                <g id="Path_82084" data-name="Path 82084" transform="translate(1713 281)" fill="none" >
                                                                                    <path d="M0,1H4A1,1,0,0,1,5,2L4.9,14.9,1.814,17.985l-2.9-2.9L-1,2A1,1,0,0,1,0,1Z"
                                                                                        stroke="none" />
                                                                                    <path d="M -2.384185791015625e-07 1.999998092651367 L -2.026557922363281e-05 2.006567001342773 L -0.08325338363647461 14.67448616027832 L 1.813512086868286 16.57125854492188 L 3.902721405029297 14.4820556640625 L 3.999999761581421 1.999998092651367 L -2.384185791015625e-07 1.999998092651367 M -2.384185791015625e-07 0.9999980926513672 L 3.999999761581421 0.9999980926513672 C 4.552279949188232 0.9999980926513672 5 1.447717666625977 5 1.999998092651367 L 4.899499893188477 14.89949798583984 L 1.813509702682495 17.98547744750977 L -1.085980415344238 15.08597755432129 L -1 1.999998092651367 C -1 1.447717666625977 -0.5522804260253906 0.9999980926513672 -2.384185791015625e-07 0.9999980926513672 Z" stroke="none" fill="#7782a1" />
                                                                                </g>
                                                                                <path id="Path_82085" data-name="Path 82085" d="M-4459-11434.725l5.643-.017" transform="translate(6171 11721)" fill="none" stroke="#7782a1" stroke-width="1" />
                                                                            </g>
                                                                        </svg>
                                                                    </a>
                                                                    }

                                                                    { sglist.segment_name !== 'Total Subscriptions' && 
                                                                    <a data-bs-toggle="modal" type="button" class="edit" onClick={(e) => handleDeleteSegmentPopupShow(e, sglist.id)} >
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" >
                                                                            <g transform="translate(-835.516 -682.992)">
                                                                                <g transform="translate(836.515 684.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" >
                                                                                    <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none" />
                                                                                    <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none" />
                                                                                </g>
                                                                                <g transform="translate(839.515 682.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" >
                                                                                    <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none" />
                                                                                    <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none" />
                                                                                </g>
                                                                                <g transform="translate(841.016 687.993)">
                                                                                    <line y2="6" transform="translate(3 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                                                                                    <line y1="6" transform="translate(0 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                                                                                </g>
                                                                                <line x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                                                                            </g>
                                                                        </svg>
                                                                    </a>
                                                                    }                  
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    )
                                                })
                                            
                                            :
                                                null
                                            )
                                        )
                                    )
                                }
                                </div>                                            
                            </>
                           
                        </div>
                    </div>
                }
            </>
        </div>

        <Modal isOpen={deletesegmentpopup} centered toggle={handleDeleteSegmentPopupCancel} style={{maxWidth:"480px",maxHeight:"270px"}}  dataclass="newnotification">
                                                                                        
            <ModalHeader toggle={handleDeleteSegmentPopupCancel} className="secondnotificationmodal" >
                <div className="d-md-flex new-pus_notification_head align-items-center justify-content-between ">

                    <div className="newcrossmodaltwo" onClick={handleDeleteSegmentPopupCancel} >
                        <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                            <path id="Union_18" data-name="Union 18" d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z" transform="translate(0.529 0.531)" fill="#C8CEDB" stroke="#C8CEDB" strokeWidth="1.5" />
                        </svg>
                    </div>
                </div>
            </ModalHeader>
            <ModalBody className="secondnewpush-notification-body">

                <div>
                    <div className="d-flex justify-content-center pushnotification-delete">
                        <div className="secondmodal-texts">
                            <h2>Delete this group?</h2>

                            <p className="pushnotification-longtext">Are you sure you want to proceed? Deleting this group is permanent and cannot be undone. This action will delete your group but retain your app-user data.</p>
                        </div>
                        
                    </div>

                    <div className=" pushnotification-finalselection">
                        {/*<div className="mob-delete-notification" onClick={handleDeleteSegmentPopup }>
                        
                            <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                <p>
                                    <div className={removepopuploader}>             
                                        <div className="new-loader"></div>
                                    </div>
                                    <div>{confirmpoplabel}</div>
                                </p>
                            </div> 
                        </div>*/}
                        <div onClick={handleDeleteSegmentPopupCancel} >
                            <p>Cancel</p>
                        </div> 
                        
                        <div className="web-delete-notification" onClick = {handleDeleteSegmentPopup } style={{ display: "block"}}>
                        
                            <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                <p>
                                    <div className={removepopuploader}>
                                        
                                        <div className="new-red-loader"></div>
                                    </div>
                                <div>{confirmpoplabel}</div>
                                </p>
                            </div> 
                        </div>
                    </div>
                </div>
            </ModalBody>
        </Modal>

        <Modal isOpen={PopupConfig} centered toggle={handlePopupConfigCancel} style={{maxWidth:"480px",maxHeight:"270px"}}  dataclass="newnotification">
                                                                                        
                <ModalHeader toggle={handlePopupConfigCancel} className="secondnotificationmodal" >
                    <div className="d-md-flex new-pus_notification_head align-items-center justify-content-between ">
    
                        <div className="newcrossmodaltwo" onClick={handlePopupConfigCancel} >
                            <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                                <path id="Union_18" data-name="Union 18" d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z" transform="translate(0.529 0.531)" fill="#C8CEDB" stroke="#C8CEDB" strokeWidth="1.5" />
                            </svg>
                        </div>
                    </div>
                </ModalHeader>
                <ModalBody className="secondnewpush-notification-body">
    
                    <div>
                        <div className="d-flex justify-content-center pushnotification-delete">
                            <div className="secondmodal-texts">
                                <h2>Complete your build settings</h2>
    
                                <p className="pushnotification-longtext">Your notification build settings are incomplete. Please configure and complete your build settings to create and try again.</p>
                            </div>
                            
                        </div>
    
                        <div className="pushnotification-finalselection move-buildsettings">

                            <div className="web-cancel-note" onClick={handlePopupConfigCancel} >
                                <p>Cancel</p>
                            </div> 
                            
                            <div className="web-delete-notification" onClick = {handleBuildConfigPopup } style={{ display: "block"}}>
                            
                                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                    <p>
                                        <div className={removepopuploader}>
                                            
                                            <div className="new-loader"></div>
                                        </div>
                                    <div>Okay</div>
                                    </p>
                                </div> 
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
    </section>
</>
);
}

export default Segments;