import React, { useEffect } from "react";
import "../commonComponents/CommonFilter/commonFilter.css";

const Drawer = ({ id, openId, onClose, children }) => {
  const isOpen = id === openId;

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <>
      <>
        {isOpen && (
          <div className="commonModal-filter-backdrop" onClick={onClose} />
        )}
        <div
          className={`${
            isOpen ? "common-filter common-filter-open" : "common-filter"
          }`}
        >
          {children}
        </div>
      </>
    </>
  );
};

export default Drawer;
