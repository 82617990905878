import React, { Suspense } from "react";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import "../../assets/css/Performance.css";
const Wpenginecontent = React.lazy(() => import("./Wpenginecontent"));
export default class Cloudflare extends React.PureComponent {


  state = {
    appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
    localStorage.getItem("appSideBarCollapse") === "true"
      ? true
      : false,
  };

  componentDidMount() {
    document.title = "Security";
    window.scrollTo(0, 0);
  }

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }

  render() {
    return (
      <>
        <div className="d-md-block">
          <Header customclass={"header-responsive-new"} />
        </div>
        {/* Mobile View */}
        {/* Mobile View End*/}
        <section className="dasboard_page mobile-display mt-4 mt-md-0 mobile-view-wp">
        <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
          <Suspense fallback={""}>
            <Wpenginecontent />
          </Suspense>
        </section>
      </>
    );
  }
}
