import React, { useState, useEffect } from "react";
import ReactColorPicker from "../../../Dashboard/Appearance/NewReactcolorpicker";
import Tooltip from "../../../Components/commonIcons/Tooltip";
import RectangularShape from "../../../Components/commonIcons/RectangularShape";
import SquareShape from "../../../Components/commonIcons/SquareShape";
import CircleShape from "../../../Components/commonIcons/CircleShape";
import IconSelectionPopUp from "../../../Dashboard/Reusable/IconSelectionPopUp";
import CrossIcon from "../../../Components/commonIcons/BottomCrossIcon";
// import CrossIcon from "../../commonIcons/BottomCrossIcon";
import imagebackground from "../../../assets/images/dashboard/cms/uploadbackground.png";
import { countBlackIcons } from "../../../utils/commonUtils";
import {
  BARCOLORSETTINGS,
  MENUCOLORSETTING
} from "../../../Constants/settingConsts/bottomBar";
import Newfeatureicon from "../../../Components/commonIcons/Newfeatureicon.js";
const ColorPickerSetting = ({ setting, value, onChange, presetColors }) => {
  return (
    <div
      className={`bottombar-theme-options bottombar-theme-options-div ${
        setting.isDisabled ? "bottombar-theme-options-disabled" : ""
      } bottombar-gap`}
    >
      <h6 className="bottombar-setting-label">
        {setting.label}
        <Tooltip message={setting.tooltip} />
      </h6>
      <ReactColorPicker
        colorname={value}
        onChange={(color) => onChange(color, setting.key)}
        classselection={"fixback"}
        valuecontrol={setting.hidecontrol}
        presetColors={presetColors}
        pickerShouldNotOpen={setting.isDisabled}
      />
    </div>
  );
};

const ToggleSwitch = ({
  label,
  tooltip,
  checked,
  onChange,
  showNewFeatureIcon
}) => (
  <div className="bottombar-settings-toggle">
    <h6 className="bottombar-setting-toggle-label">
      {label}
      <Tooltip message={tooltip} />
      {/* {showNewFeatureIcon ? <Newfeatureicon /> : null} */}
    </h6>
    <label className="switch">
      <input
        type="checkbox"
        className="toplogo"
        checked={checked}
        onChange={onChange}
      />
      <div className="slider round"></div>
    </label>
  </div>
);

const IconShapeSelector = ({ currentShape, onChange }) => (
  <div className="menu-tile-shape-selectdiv">
    <RectangularShape shape={currentShape} handleShape={onChange} />
    <SquareShape shape={currentShape} handleShape={onChange} />
    <CircleShape shape={currentShape} handleShape={onChange} />
  </div>
);

const BottomSetting = ({ bottomState, setBottomState }) => {
  const [openIconPopUp, setOpenIconPopUp] = useState(false);
  const [barColorSettings, setbarColorSettings] = useState([]);
  const [menuColorSettings, setmenuColorSettings] = useState([]);
  const { blackIconItems, totalItems } = countBlackIcons(
    bottomState.bottom_menu_items
  );

  useEffect(() => {
    const updatedbarColorSettings = BARCOLORSETTINGS.map((setting) => ({
      ...setting,
      isDisabled:
        setting.key === "bottom_menu_icon_colour" ||
        setting.key === "bottom_menu_selected_icon_colour"
          ? blackIconItems !== totalItems ||
            (totalItems > 5 &&
              bottomState.settings.bottom_menu_icon_colour_type_for_menu !==
                "black")
          : setting.isDisabled
    }));

    const updatedmenuColorSettings = MENUCOLORSETTING.map((setting) => ({
      ...setting,
      isDisabled:
        setting.key === "bottom_menu_grid_view_icon_color" ||
        setting.key === "bottom_menu_grid_view_selected_icon_color"
          ? blackIconItems !== totalItems ||
            bottomState.settings.bottom_menu_icon_colour_type_for_menu !==
              "black"
          : setting.isDisabled
    }));

    setbarColorSettings(updatedbarColorSettings);
    setmenuColorSettings(updatedmenuColorSettings);
  }, [blackIconItems, totalItems, setBottomState, bottomState]);

  const handleMenuText = (e, key) => {
    updateSetting(key, e.target.value);
  };

  const handleColumnChange = (e) => {
    const value = Number(e.target.value);
    if (value < 2 || value > 4) {
      alert("Columns must be between 2 and 4.");
      return;
    }
    updateSetting("bottom_menu_grid_view_column", value);
  };

  const updateSetting = (key, value) => {
    setBottomState({
      ...bottomState,
      settings: {
        ...bottomState.settings,
        [key]: value
      }
    });
  };

  const handleColorChange = (color, key) => updateSetting(key, color);
  const handleToggleChange = (e, key) => updateSetting(key, e.target.checked);
  const handleIconPopUp = () => setOpenIconPopUp((prev) => !prev);

  const saveIconName = (uploadedIconName, selectedColorType) => {
    setOpenIconPopUp(false);
    const currsetting = { ...bottomState.settings };
    currsetting["bottom_menu_icon_for_menu"] = uploadedIconName;
    currsetting["bottom_menu_icon_colour_type_for_menu"] = selectedColorType;
    setBottomState({
      settings: currsetting
    });
  };

  const handleItemicon = () => {
    const currsetting = { ...bottomState.settings };
    currsetting["bottom_menu_icon_for_menu"] = "";
    currsetting["bottom_menu_icon_colour_type_for_menu"] = "black";
    setBottomState({
      settings: currsetting
    });
  };

  return (
    <div
      className={
        bottomState.openBottomSetting ? "bottombar-settings" : "d-none"
      }
    >
      <div className="bottombar-settings-head">
        <h3 className="bottombar-settings-title">Settings</h3>
        <h4 className="bottombar-settings-description">
          Customize the appearance of your Bottom bar and Bottom menu to suit
          your preferences.
        </h4>
      </div>

      <div className="bottombar-setting-one">
        <h3 className="bottombar-setting-one-title">Bottom bar</h3>
        <div className="bottombar-theme">
          {barColorSettings.map((setting) => {
            const settingValue = bottomState.settings?.[setting.key] ?? "";
            return (
              <ColorPickerSetting
                key={setting.key}
                setting={setting}
                value={settingValue}
                onChange={(color) => handleColorChange(color, setting.key)}
                presetColors={bottomState.presetColors}
              />
            );
          })}
        </div>
        <ToggleSwitch
          label="Show icon"
          tooltip="Enable to show icons in the Bottom bar."
          checked={bottomState.settings?.bottom_menu_show_icon}
          onChange={(e) => handleToggleChange(e, "bottom_menu_show_icon")}
        />
        <ToggleSwitch
          label="Show text"
          tooltip="Enable to show item names in the Bottom bar."
          checked={bottomState.settings?.bottom_menu_show_text}
          onChange={(e) => handleToggleChange(e, "bottom_menu_show_text")}
        />
      </div>

      {bottomState?.bottom_menu_items.length > 5 && (
        <div className="bottombar-bottom-menu">
          <h3 className="bottombar-setting-menu-title">Bottom menu</h3>
          <div>
            <h6>
              Menu text
              <Tooltip
                message={
                  "The menu text allows app users to launch your Bottom menu."
                }
              />
            </h6>
            <input
              type="text"
              maxLength={10}
              value={bottomState.settings?.bottom_menu_text_for_menu}
              onChange={(e) => handleMenuText(e, "bottom_menu_text_for_menu")}
            />
          </div>

          <div>
            <h6 className="bottombar-gap">
              Menu icon
              <Tooltip
                message={
                  "The menu icon allows app users to locate your Bottom menu."
                }
              />
            </h6>

            <label className="set-full-img">
              <div className="image-uploader">
                <div
                  className="d-flex align-items-center"
                  onClick={(e) => handleIconPopUp()}
                >
                  <div
                    className={
                      bottomState.settings?.bottom_menu_icon_for_menu
                        ? "uploadpixabayimage afterupload"
                        : "uploadpixabayimage"
                    }
                    style={{
                      backgroundImage: bottomState.settings
                        ?.bottom_menu_icon_for_menu
                        ? "url(" +
                          process.env.REACT_APP_Image_Path +
                          localStorage.getItem("user_id") +
                          "/" +
                          localStorage.getItem("Appid") +
                          process.env.REACT_APP_NEW_ICON_PATH +
                          bottomState.settings?.bottom_menu_icon_for_menu +
                          ")"
                        : "url(" + imagebackground + ")"
                    }}
                  ></div>

                  <p
                    style={{
                      color: bottomState.settings?.bottom_menu_icon_for_menu
                        ? ""
                        : "#BCC1CE"
                    }}
                  >
                    {bottomState.settings?.bottom_menu_icon_for_menu
                      ? bottomState.settings?.bottom_menu_icon_for_menu.replace(
                          "%2Bxml",
                          ""
                        )
                      : "Select an icon"}
                  </p>
                </div>
                {bottomState.settings?.bottom_menu_icon_for_menu ? (
                  <CrossIcon handleCrossClick={() => handleItemicon("menu")} />
                ) : null}
              </div>
            </label>
          </div>

          <ToggleSwitch
            label="Show grid view"
            tooltip="Enable to show icons in a grid view, instead of a list."
            checked={bottomState.settings?.bottom_menu_show_grid_view}
            onChange={(e) =>
              handleToggleChange(e, "bottom_menu_show_grid_view")
            }
          />

          <ToggleSwitch
            label="Show icons"
            tooltip="Enable to show icons in the Bottom menu."
            checked={bottomState.settings?.bottom_menu_grid_view_show_icon}
            onChange={(e) =>
              handleToggleChange(e, "bottom_menu_grid_view_show_icon")
            }
          />

          <ToggleSwitch
            label="Show text"
            tooltip="Enable to show item names in the Bottom menu."
            checked={bottomState.settings?.bottom_menu_grid_view_show_text}
            onChange={(e) =>
              handleToggleChange(e, "bottom_menu_grid_view_show_text")
            }
          />

          {!!bottomState.settings?.bottom_menu_show_grid_view && (
            <div className="bottombar-settings-toggle">
              <h6 className="bottombar-setting-toggle-label">
                Tile shape
                <Tooltip
                  message={"Choose your tile shape in the Bottom menu."}
                />
              </h6>
              <div className="menu-tile-shape-selectdiv">
                <IconShapeSelector
                  currentShape={
                    bottomState.settings?.bottom_menu_grid_view_tile_shape
                  }
                  onChange={(shape) =>
                    updateSetting("bottom_menu_grid_view_tile_shape", shape)
                  }
                />
              </div>
            </div>
          )}

          {!!bottomState.settings?.bottom_menu_show_grid_view && (
            <div className="bottombar-gap2">
              <h6>
                Columns
                <Tooltip
                  message={"Specify the number of columns in the Bottom menu."}
                />
              </h6>
              <input
                type="number"
                min={"2"}
                max={"4"}
                maxLength={4}
                minLength={2}
                onChange={(e) => handleColumnChange(e)}
                value={bottomState.settings?.bottom_menu_grid_view_column}
              />
            </div>
          )}

          <div className="bottombar-theme bottombar-gap">
            {menuColorSettings.map((setting) => {
              const settingValue = bottomState.settings?.[setting.key] ?? "";
              return (
                <ColorPickerSetting
                  key={setting.key}
                  setting={setting}
                  value={settingValue}
                  onChange={(color) => handleColorChange(color, setting.key)}
                  presetColors={bottomState.presetColors}
                />
              );
            })}
          </div>
        </div>
      )}
      {openIconPopUp && (
        <IconSelectionPopUp
          handleIconPopUp={handleIconPopUp}
          saveIconName={saveIconName}
          openIconPopUp={openIconPopUp}
          widthForRestriction={128}
          heightForRestriction={128}
        />
      )}
      <div className="bottomBar-haptic-div">
        <ToggleSwitch
          label="Haptic vibration on touch"
          tooltip="Enable haptic vibration when app users interact with the Bottom menu. This creates a subtle vibration, enhancing the tactile experience for app users."
          id={"hapticFeedback"}
          name={"hapticFeedback"}
          showNewFeatureIcon={true}
          checked={bottomState.settings?.enable_haptic_on_touch}
          onChange={(e) => {
            handleToggleChange(e, "enable_haptic_on_touch");
          }}
        />
      </div>
    </div>
  );
};

export default BottomSetting;
