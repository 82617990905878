import React, { useState } from "react";
import appDashImage from "../../../assets/images/dashboard/app-dashboard.png";
import mergeIc from "../../../assets/images/dashboard/download-merge.png";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { useUnlinkAppsMutation } from "../../../Redux/slices/mergeappSlice";
import { errortoast, sucesstoast } from "../../../Dashboard/Toaster";
import crossIc from "../../../assets/images/signup/cross.png";

const MultiSiteAlert = (props) => {
  const appName = localStorage.getItem("Appname");
  const appId = localStorage.getItem("Appid");

  const [unlinkpopupstatus, setUnlinkPopupStatus] = useState(false);
  const [btnunlinkconfirmlabel, setBtnUnlinkConfirmLabel] = useState("Confirm");
  const [unlinkremoveloader, setUnlinkRemoveLoader] = useState("d-none");

  const imageSrc =
    process.env.REACT_APP_Image_Path +
    props.value.parent_app_user_id +
    "/" +
    props.value.parent_app_id +
    process.env.REACT_APP_AppIcon_Display +
    props.value.parent_app_icon;

  const imageAppIc =
    process.env.REACT_APP_Image_Path +
    localStorage.getItem("user_id") +
    "/" +
    localStorage.getItem("Appid") +
    process.env.REACT_APP_AppIcon_Display +
    localStorage.getItem("Appicon");

  const [unlinkApps] = useUnlinkAppsMutation();

  const handleUnlink = () => {
    const body = {
      app_id: parseInt(localStorage.getItem("Appid")),
    };

    setUnlinkRemoveLoader("d-block");
    setBtnUnlinkConfirmLabel("");
    unlinkApps(body)
      .unwrap()
      .then((response) => {
        if (response.code === 200) sucesstoast("App successfully unlinked");
        else errortoast(response.data);
      })
      .catch(() => {});
  };

  const handleUnlinkPopupCancel = () => setUnlinkPopupStatus(false);

  const handleUnlinkConfirmPopup = () =>
    setUnlinkPopupStatus(!unlinkpopupstatus);

  return (
    <>
      <div className="notification-merged">
        <div>
          <div className="notification-grid">
            <div className="notification-parent-app">
              <div className="notification-parent-tag">
                <h3>Primary app</h3>
              </div>

              <div className="notification-appicon">
                <img src={imageSrc} width="160px" alt="Appicon" />
              </div>
              <h4 className="notification-merged-parent-app-name">
                {props.value.parent_app_name}
              </h4>
              <h5 className="notification-merged-parent-app-id">
                ID: {props.value.parent_app_id}
              </h5>
            </div>

            <img src={mergeIc} alt="download-img" className="img-fluid" />
            <div className="notification-child-app">
              <div className="notification-child-tag">
                <h3>Merged app</h3>
              </div>
              <div className="notification-appicon">
                <img
                  src={imageAppIc}
                  onError={({ currentTarget }) => {
                    currentTarget.src = appDashImage;
                  }}
                  alt="download-img"
                  className="img-fluid"
                />
              </div>
              <h4 className="notification-merged-parent-app-name">{appName}</h4>
              <h5 className="notification-merged-parent-app-id">ID: {appId}</h5>
            </div>
          </div>

          <h1>This app is already merged</h1>
          <h2>
            This app appears to be linked to a multisite setup. To create push
            notifications, simply navigate to the parent app where the add-on is
            enabled and linked to this app.
          </h2>
          <div className="notification-merged-btns">
            <a href="/apps">Manage</a>
            <a onClick={handleUnlinkConfirmPopup}>Unlink</a>
          </div>
        </div>
      </div>

      <Modal
        isOpen={unlinkpopupstatus}
        centered
        toggle={handleUnlinkPopupCancel}
        dataclass="newnotification"
        className="unlink-app-popup"
      >
        <ModalHeader
          toggle={handleUnlinkPopupCancel}
          className="secondnotificationmodal"
        >
          <div className="d-md-flex new-pus_notification_head align-items-center justify-content-between ">
            <div className="newcrossmodaltwo" onClick={handleUnlinkPopupCancel}>
              <img src={crossIc} alt="cross" />
            </div>
          </div>
        </ModalHeader>

        <ModalBody className="secondnewpush-notification-body">
          <div>
            <div className="d-flex justify-content-center pushnotification-delete">
              <div className="secondmodal-texts">
                <h2>Are you sure you want to unlink?</h2>

                <p className="pushnotification-longtext">
                  While you can update almost everything about your app once it
                  is built, the App Name, App Icon.
                </p>
              </div>
            </div>

            <div className=" pushnotification-finalselection mobmultialert-reverse">
              <div className="mob-delete-notification" onClick={handleUnlink}>
                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                  <p className="web-confirm-button">
                    <div className={unlinkremoveloader}>
                      <div className="new-loader"></div>
                    </div>
                    <div>{btnunlinkconfirmlabel}</div>
                  </p>
                </div>
              </div>
              <div onClick={handleUnlinkPopupCancel}>
                <p>Cancel</p>
              </div>

              <div className="web-delete-notification" onClick={handleUnlink}>
                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                  <span className="web-confirm-button">
                    <div className={unlinkremoveloader}>
                      <div className="new-loader"></div>
                    </div>
                    <div>{btnunlinkconfirmlabel}</div>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};
export default MultiSiteAlert;
