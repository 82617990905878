import React, { useState } from "react";
import { Link } from "react-router-dom";
import Reactcolorpicker from "../Appearance/NewReactcolorpicker";

const SeparatorBlock = ({
  indx,
  cmsArray,
  setcmsData,
  isRowOpen,
  handleIsRowOpen,
  deleteRow,
  duplicateItem,

  eachBlock,
  provided,
}) => {
  const handleBorder = (e) => {
    let cmsData = [...cmsArray];
    
    if (parseInt(e.target.value) > 100 || parseInt(e.target.value) < 1) {
      cmsData[indx].style.height = "1";
      // return;
    } else {
      cmsData[indx].style.height = e.target.value;
    }
    setcmsData(cmsData);
  };

  const handleColor = (e) => {
    let cmsData = [...cmsArray];
    cmsData[indx].style.color = e;

    //setborderColor(e.hex);
    setcmsData(cmsData);
  };

  const handleStatus = (e) =>{

    let cmsData = [...cmsArray];
    cmsData[indx].status = ( cmsData[indx].status===1 || cmsData[indx].status===undefined || cmsData[indx].status===null)
    ?0:1;
  
    // setbgColor(e.hex);
    setcmsData(cmsData);
  }

  return (
    <div className={`accordionItem ${cmsArray[indx].status===0?"cms-section-hidden":""}`}>
      <h2
        class="accordionItemHeading allflex"
        draggableId={indx.toString()}
        {...provided.dragHandleProps}
      >
        Separator

        <span className="cms-section-visiblity" onClick={(e)=>handleStatus(e)}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25.284" height="20.465" viewBox="0 0 25.284 20.465">
        <g id="Group_39189" data-name="Group 39189" transform="translate(0.568 0.353)">
          <path id="Path_103079" data-name="Path 103079" d="M68.716,9.742a13.852,13.852,0,0,1-12.07,7.684A13.876,13.876,0,0,1,44.568,9.742,13.876,13.876,0,0,1,56.646,2.058,13.852,13.852,0,0,1,68.716,9.742Z" transform="translate(-44.568 0.138)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
          <ellipse id="Ellipse_8629" data-name="Ellipse 8629" cx="3.842" cy="3.842" rx="3.842" ry="3.842" transform="translate(8.233 6.038)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
          <line id="Line_430" data-name="Line 430" x2="19.758" y2="19.758" transform="translate(2.196 0.001)" fill="none" stroke="" stroke-miterlimit="10" stroke-width="1"/>
        </g>
      </svg>
    </span>

    
        <p className="cms-accordian-p"
          onClick={(e) => {
            handleIsRowOpen(e, indx);
          }}
        >
          <img
            src={
              isRowOpen[indx] === true
                ? require("../../assets/images/dashboard/cms/accordian-open.png")
                    .default
                : require("../../assets/images/dashboard/cms/accordian-icon.png")
                    .default
            }
          />
        </p>
      </h2>

      {isRowOpen[indx] === true && (
        <div class="accordionItemContent seprator">
          <h6>Border</h6>
          <div className="the-seprator">
            
            <input
              type="number"
              id="rangeid"
              min="1"
              max="100"
              step="1"
              value={cmsArray[indx].style.height}
              onChange={(e) => {
                handleBorder(e, indx);
              }}
            />
            <Reactcolorpicker
              colorname={cmsArray[indx].style.color}
             
              onChange={(e) => handleColor(e, indx)}
              classselection={"fixback"}
            
              
         
                valuecontrol={false}
            />
          </div>

          <div className="duplicate-delete">
            <div className="cms-dup-del-btn"   onClick={(e) => duplicateItem(e, eachBlock)}>
            <span class="cms-delete-tooltip">
            <img
                src={
                  require("../../assets/images/dashboard/cms/duplicate.png")
                    .default
                }
              />
               <span class="cms-delete-tooltipsubheader">Duplicate</span>
                  </span>
            </div>
            <div className="cms-dup-del-btn"  
              onClick={(e) => {
                deleteRow(e, indx);
              }}
            >
              <span class="cms-delete-tooltip">
            <img
                src={
                  require("../../assets/images/dashboard/cms/delete.png")
                    .default
                }
              />
               <span class="cms-delete-tooltipsubheader">Delete</span>
                  </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SeparatorBlock;
