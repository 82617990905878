import React from 'react'

const TopHeaderPreview = ({colorThemeData,bottomMenuData

}) => {
  return (
    <div className="global-header"
    style={{
      height: "40px",
      padding: "5px 10px",
      display: "flex",
      alignItems: "center",
      background:
        colorThemeData !== null &&
        colorThemeData.header_primary_color_object.portal_data,
    }}
  >
    <svg width="15.5" height="11.5" viewBox="0 0 15.5 11.5">
      <g id="menu" transform="translate(0.25 0.25)" fill="#fff">
        <path
          d="M 15.125 11.125 L 15 11.125 L -8.881784197001252e-16 11.125 L -0.125 11.125 L -0.125 11 L -0.125 10.2324104309082 L -0.125 10.1074104309082 L -8.881784197001252e-16 10.1074104309082 L 15 10.1074104309082 L 15.125 10.1074104309082 L 15.125 10.2324104309082 L 15.125 11 L 15.125 11.125 Z M 15.125 6.008560180664063 L 15 6.008560180664063 L -8.881784197001252e-16 6.008560180664063 L -0.125 6.008560180664063 L -0.125 5.883560180664063 L -0.125 5.116199970245361 L -0.125 4.991199970245361 L -8.881784197001252e-16 4.991199970245361 L 15 4.991199970245361 L 15.125 4.991199970245361 L 15.125 5.116199970245361 L 15.125 5.883560180664063 L 15.125 6.008560180664063 Z M 15.125 0.8923599720001221 L 15 0.8923599720001221 L -8.881784197001252e-16 0.8923599720001221 L -0.125 0.8923599720001221 L -0.125 0.7673599720001221 L -0.125 -8.881784197001252e-16 L -0.125 -0.125 L -8.881784197001252e-16 -0.125 L 15 -0.125 L 15.125 -0.125 L 15.125 -8.881784197001252e-16 L 15.125 0.7673599720001221 L 15.125 0.8923599720001221 Z"
          stroke={colorThemeData.header_secondary_color_object.portal_data}
        />
        <path
          d="M 15 11 L 15 10.2324104309082 L -8.881784197001252e-16 10.2324104309082 L -8.881784197001252e-16 11 L 15 11 M 15 5.883560180664063 L 15 5.116199970245361 L -8.881784197001252e-16 5.116199970245361 L -8.881784197001252e-16 5.883560180664063 L 15 5.883560180664063 M 15 0.7673599720001221 L 15 -8.881784197001252e-16 L -8.881784197001252e-16 -8.881784197001252e-16 L -8.881784197001252e-16 0.7673599720001221 L 15 0.7673599720001221 M 15.25 11.25 L 15 11.25 L -8.881784197001252e-16 11.25 L -0.25 11.25 L -0.25 11 L -0.25 10.2324104309082 L -0.25 9.982410430908203 L -8.881784197001252e-16 9.982410430908203 L 15 9.982410430908203 L 15.25 9.982410430908203 L 15.25 10.2324104309082 L 15.25 11 L 15.25 11.25 Z M 15.25 6.133560180664063 L 15 6.133560180664063 L -8.881784197001252e-16 6.133560180664063 L -0.25 6.133560180664063 L -0.25 5.883560180664063 L -0.25 5.116199970245361 L -0.25 4.866199970245361 L -8.881784197001252e-16 4.866199970245361 L 15 4.866199970245361 L 15.25 4.866199970245361 L 15.25 5.116199970245361 L 15.25 5.883560180664063 L 15.25 6.133560180664063 Z M 15.25 1.017359972000122 L 15 1.017359972000122 L -8.881784197001252e-16 1.017359972000122 L -0.25 1.017359972000122 L -0.25 0.7673599720001221 L -0.25 -8.881784197001252e-16 L -0.25 -0.25 L -8.881784197001252e-16 -0.25 L 15 -0.25 L 15.25 -0.25 L 15.25 -8.881784197001252e-16 L 15.25 0.7673599720001221 L 15.25 1.017359972000122 Z"
          stroke="none"
          fill={colorThemeData.header_secondary_color_object.portal_data}
        />
      </g>
    </svg>
    <span
      style={{
        fontFamily: colorThemeData.header_font_name,
        color: colorThemeData.header_secondary_color_object.portal_data,
      }}
    >
      {bottomMenuData!==null&&bottomMenuData.bottom_menu_items.map((row) => {
        if (row.is_default === 1) {
          return row.item_name;
        }
      })}
    </span>
  </div>
  )
}

export default TopHeaderPreview