export const HEADER_ICONS_ARRAY = [
    {
      name: "Hamburger menu icon",
      item_icon: "theme_menu.png",
      initial_item_icon: "theme_menu.png",
      tooltipMessage: "This icon launches your navigation menu.",
      icon_color:"black",
    },
    {
      name: "Back icon",
      item_icon: "theme_arrow.png",
      initial_item_icon: "theme_arrow.png",
      tooltipMessage:
        "This icon is used to navigate back to the previous screen.",
         icon_color:"black"
    },
    {
      name: "Search icon",
      item_icon: "theme_search.png",
      initial_item_icon: "theme_search.png",
      tooltipMessage: "This icon is used to navigate to the search feature.",
       icon_color:"black"
    },
    {
      name: "Share icon",
      item_icon: "theme_share.png",
      initial_item_icon: "theme_share.png",
      tooltipMessage: "This icon launches the native share widget.",
       icon_color:"black"
    },
    {
      name: "Wishlist icon",
      item_icon: "theme_heart.png",
      initial_item_icon: "theme_heart.png",
      tooltipMessage: "This icon is used to navigate to the wishlist.",
       icon_color:"black"
    },
    {
      name: "Bookmark icon",
      item_icon: "theme_bookmark.png",
      initial_item_icon: "theme_bookmark.png",
      tooltipMessage: "This icon is used to navigate to bookmarked posts.",
       icon_color:"black"
    },
    {
      name: "Notification icon",
      item_icon: "theme_notification.png",
      initial_item_icon: "theme_notification.png",
      tooltipMessage: "This icon is used to navigate to the notifications listing.",
       icon_color:"black"
    },
    {
      name: "Cart icon",
      item_icon: "theme_cart.png",
      initial_item_icon: "theme_cart.png",
      tooltipMessage: "This icon is used to navigate to the shopping cart.",
       icon_color:"black"
    },
    {
      name: "Profile icon",
      item_icon: "theme_profile.png",
      initial_item_icon: "theme_profile.png",
      tooltipMessage: "This icon is used to navigate to the user profile.",
       icon_color:"black"
    },
  ];
  
  export const LISTING_ICONS_ARRAY = [
    {
      name: "Sort icon",
      item_icon: "theme_sort.png",
      initial_item_icon: "theme_sort.png",
      tooltipMessage: "This icon is used to sort results.",
      icon_color:"black"

    },
    {
      name: "Filter icon",
      item_icon: "theme_filter.png",
      initial_item_icon: "theme_filter.png",
      tooltipMessage: "This icon is used to filter results.",
      icon_color:"black"

    },
    {
      name: "List view icon",
      item_icon: "theme_list.png",
      initial_item_icon: "theme_list.png",
      tooltipMessage: "This icon is used to display results in a list view.",
      icon_color:"black"

    },
    {
      name: "Grid view icon",
      item_icon: "theme_grid.png",
      initial_item_icon: "theme_grid.png",
      tooltipMessage: "This icon is used to display results in a grid view.",
      icon_color:"black"

    },
  ];

  export const PROFILE_ICONS_ARRAY = [
    {
      name: "Reward icon",
      item_icon: "theme_reward.png",
      initial_item_icon: "theme_reward.png",
      tooltipMessage: "This icon represents the app user’s reward points.",
      icon_color:"black"

    },
    {
      name: "Bookmark icon",
      item_icon: "theme_profile_bookmark.png",
      initial_item_icon: "theme_profile_bookmark.png",
      tooltipMessage: "This icon is used to navigate to bookmarked posts.",
      icon_color:"black"

    },
    {
      name: "Wishlist icon",
      item_icon: "theme_wishlist.png",
      initial_item_icon: "theme_wishlist.png",
      tooltipMessage: "This icon is used to navigate to the wishlist.",
      icon_color:"black"

    },
    {
      name: "Order history icon",
      item_icon: "theme_order.png",
      initial_item_icon: "theme_order.png",
      tooltipMessage: "This icon is used to navigate to the order history.",
      icon_color:"black"

    },
    {
      name: "Saved address icon",
      item_icon: "theme_address.png",
      initial_item_icon: "theme_address.png",
      tooltipMessage: "This icon is used to navigate to saved addresses.",
      icon_color:"black"

    },
    {
      name: "Change password icon",
      item_icon: "theme_password.png",
      initial_item_icon: "theme_password.png",
      tooltipMessage: "This icon is used to navigate to the reset password screen.",
      icon_color:"black"

    },
    {
      name: "Chat icon",
      item_icon: "theme_chat.png",
      initial_item_icon: "theme_chat.png",
      tooltipMessage: "This icon is used to initiate chat.",
      icon_color:"black"

    },
    {
      name: "Settings icon",
      item_icon: "theme_setting.png",
      initial_item_icon: "theme_setting.png",
      tooltipMessage: "This icon is used to navigate to app settings.",
      icon_color:"black"

    },
    {
      name: "Logout icon",
      item_icon: "theme_logout.png",
      initial_item_icon: "theme_logout.png",
      tooltipMessage: "This icon is used to log out of the app.",
      icon_color:"black"

    },
    {
      name: "Delete account icon",
      item_icon: "theme_delete.png",
      initial_item_icon: "theme_delete.png",
      tooltipMessage: "This icon is used to delete the user profile.",
      icon_color:"black"

    },
  ];
  
  export const SETTINGS_ICONS_ARRAY = [
    {
      name: "Push notifications icon",
      item_icon: "theme_push_notifications.png",
      initial_item_icon: "theme_push_notifications.png",
      tooltipMessage: "This icon represents the push notifications toggle settings.",
      icon_color:"black"

    },
    {
      name: "Appearance icon",
      item_icon: "theme_moon.png",
      initial_item_icon: "theme_moon.png",
      tooltipMessage: "This icon is used to navigate to the app’s display settings.",
        icon_color:"black"
    },
    {
      name: "Offline mode icon",
      item_icon: "theme_offline.png",
      initial_item_icon: "theme_offline.png",
      tooltipMessage: "",
        icon_color:"black"
    },
    {
      name: "Notification icon",
      item_icon: "theme_notification.png",
      initial_item_icon: "theme_notification.png",
      tooltipMessage: "This icon is used to navigate to the notifications listing.",
      icon_color:"black"

    },
    {
      name: "Site icon",
      item_icon: "theme_site.png",
      initial_item_icon: "theme_site.png",
      tooltipMessage: "",
      icon_color:"black"

    },
    {
      name: "Currency icon",
      item_icon: "theme_currency.png",
      initial_item_icon: "theme_currency.png",
      tooltipMessage: "This icon is used to represent the app’s associated currency.",
      icon_color:"black"

    },
    {
      name: "Language icon",
      item_icon: "theme_language.png",
      initial_item_icon: "theme_language.png",
      tooltipMessage: "This icon is used to navigate to the language selection screen.",
      icon_color:"black"

    },
    {
      name: "Terms and conditions icon",
      item_icon: "theme_terms.png",
      initial_item_icon: "theme_terms.png",
      tooltipMessage: "This icon is used to navigate to the app’s terms and conditions.",
      icon_color:"black"
    },
    {
      name: "Chat icon",
      item_icon: "theme_chat.png",
      initial_item_icon: "theme_chat.png",
      tooltipMessage: "This icon is used to initiate chat.",
      icon_color:"black"

    },
    {
      name: "Rate us icon",
      item_icon: "theme_star.png",
      initial_item_icon: "theme_star.png",
      tooltipMessage: "",
      icon_color:"black"}
  ];
  