
import React from "react";

const ChildArrow = ({ strokeColor, handleArrowClick,opacity }) => {
  return (
    <svg
      onClick={(e) => (handleArrowClick ? handleArrowClick(e) : null)}
      className="child-svg"
      xmlns="http://www.w3.org/2000/svg"
      width="15.207"
      height="11.707"
      viewBox="0 0 15.207 11.707"
    >
      <g
        id="Group_42022"
        data-name="Group 42022"
        transform="translate(-1.89 -0.646)"
        opacity={opacity||"1"}
      >
        <path
          id="Path_103411"
          data-name="Path 103411"
          d="M-384.4-3200.5l5.5,5.5-5.5,5.5"
          transform="translate(395.285 3201.5)"
          fill="none"
          stroke={strokeColor}
          stroke-width="1"
        />
        <path
          id="Path_103412"
          data-name="Path 103412"
          d="M-640.5-3185.5h-14"
          transform="translate(656.39 3192.045)"
          fill="none"
          stroke={strokeColor}
          stroke-width="1"
        />
      </g>
    </svg>
  );
};

export default ChildArrow;
