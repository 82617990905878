import React from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import { CACHE_DURATION_ARRAY } from "../../../Constants/webviewConstants/WebviewConstants";

const CacheDaysDropdown = ({
  webViewState,
  handleStateChange,
  setWebViewState,
}) => {
  return (
    <Dropdown
      className="cms-filterby-post search-dropdown webViewdropdown"
      isOpen={
        window.matchMedia("(max-width: 1200px)").matches
          ? false
          : webViewState.openDurationDropdown
      }
      onClick={
        window.matchMedia("(max-width: 1200px)").matches
          ? () => handleStateChange("toggle_openMobNativeDaysDropdown")
          : null
      }
      toggle={() => {
        if (!webViewState.enableForcefulCacheWebpages) return;
        setWebViewState({
          openDurationDropdown: !webViewState.openDurationDropdown,
        });
      }}
    >
      <DropdownToggle caret>
        {webViewState.numberOfDaysToCacheFormat}
      </DropdownToggle>
      <DropdownMenu>
        {CACHE_DURATION_ARRAY.map((duration, idx) => (
          <DropdownItem
            active={webViewState.numberOfDaysToCacheFormat === duration}
            key={idx}
            onClick={() =>
              handleStateChange("numberOfDaysToCacheFormat", duration, {
                openMobNativeDaysDropdown: false,
              })
            }
          >
            {duration}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  );
};

export default CacheDaysDropdown;
