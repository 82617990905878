import React from "react";
import toast from "toasted-notes";
import "toasted-notes/src/styles.css";
import "../assets/css/newtoaster.css"

export const sucesstoast = (message) => {
  toast.notify(
    <div className="toaster-layout" >
      	<div className="d-flex align-items-center h-100">
        	<div className="d-flex">
				<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
  					<g id="Group_38010" data-name="Group 38010" transform="translate(-731 -77)">
    					<g id="Ellipse_7755" data-name="Ellipse 7755" transform="translate(731 77)" fill="none" stroke="#fff" stroke-width="1.5">
      						<circle cx="11" cy="11" r="11" stroke="none"/>
      						<circle cx="11" cy="11" r="10.25" fill="none"/>
    					</g>
    					<path id="Path_102372" data-name="Path 102372" d="M9.326.353l-5.8,5.813L.353,3" transform="translate(737.16 85.161)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.5"/>
  					</g>
				</svg>
        	</div>
        	<div className="toaster-text-margin">
				<p className="p-0  text-left toaster-text">{message}</p>
        	</div>
      	</div>
    </div>,

    {
      position: "top", // top-left, top, top-right, bottom-left, bottom, bottom-right
      duration: 6000, // This notification will not automatically close
    }
  );
};

export const errortoast = (message, errormssg) => {
  const handleSeeMore = (e) => {
    let seeMoreButton = document.getElementById("seeMoreButton");
    let showMoreText = document.getElementById("showMoreText");
    if (showMoreText.style.display === "none") 
	{
		showMoreText.style.display = "block";
		seeMoreButton.innerHTML = "See Less";
    } 
	else 
	{
		showMoreText.style.display = "none";
		seeMoreButton.innerHTML = "See More";
    }
  };
  toast.notify(
    <div className="red-toaster-layout" >
      	<div className="d-flex align-items-center h-100">
        	<div className="d-flex">
				<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
  					<g id="Group_38010" data-name="Group 38010" transform="translate(-731 -157)">
    					<g id="Ellipse_7750" data-name="Ellipse 7750" transform="translate(731 157)" fill="none" stroke="#fff" stroke-width="1.5">
      						<circle cx="11" cy="11" r="11" stroke="none"/>
      						<circle cx="11" cy="11" r="10.25" fill="none"/>
    					</g>
    					<path id="Path_102375" data-name="Path 102375" d="M811,145.263v6" transform="translate(-69 17.737)" fill="none" stroke="#fff" stroke-width="1.5"/>
    					<path id="Path_102376" data-name="Path 102376" d="M811,145.263v2" transform="translate(-69 25.737)" fill="none" stroke="#fff" stroke-width="1.5"/>
  					</g>
				</svg>
			</div>

			<div className="toaster-text-margin">
				<p className="p-0  text-left toaster-text">{message}</p>
				{errormssg === undefined || errormssg === null || errormssg === "" ? 
					(
						<></>
					) 
				: 
					(
						<button id="seeMoreButton" className="see-more" onClick={(e) => handleSeeMore(e)} > See more </button>
					)
				}
			</div>
    	</div>
      	<p id="showMoreText" style={{ display: "none", transition: "all ease 1s" }} > {errormssg} </p>
    </div>,

    {
      position: "top", // top-left, top, top-right, bottom-left, bottom, bottom-right
      duration: 6000, // This notification will not automatically close
    }
  );
};
