import React from "react";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import { Media, Spinner } from "reactstrap";
import { Foot, InnerFoot } from "../../Dashboard/Foot";
import {
  Getallpackageaddon,
  Getappdetailsapi,
  getWorkspaceById,
} from "../../Api/Dashboard/DashboardApi";
import { Notallowedtouser } from "../User/Usernotalllowed";
import Accountleftpanel from "../Accountleftpanel";
export default class WorkspaceAddons extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      heading: "Transfer App",
      spinner: true,
      addondata: [],
      addon_code: "",
      zoho_plan_code: "",
      app_id: "",
      loader: "d-none",
      submitval: "Pay",
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      onetimeaddon: "",
      onetime_addons_purchased_json: null,
      recurring_addons_purchased_json: null,
      filtervalue: {
        option: "",
        is_deprecated: 0,
      },
      appuser_role_id: null,
      auto_addons: [],
      addon_info: [],
      allowed_module: "",
      customer_billing_type: null,
      plan: "",
    };
  }
  componentDidMount() {
    document.title = "Workspace Addons";
    window.scrollTo(0, 0);
    getWorkspaceById(
      this,
      btoa(parseInt(localStorage.getItem("workspaceId"))),
      () => {
        const dataparams = {
          plan_code: this.state.zoho_plan_code.toString(),
        };
        Getallpackageaddon(this, dataparams, "workspace");
      }
    );
  }

  handleaddonpayment = (addon_identifier, e) => {
    
    if (addon_identifier === "PREVENT_SCREENSHOT") {
    } else {
      this.props.history.push("/app/addons");
    }
  };
  handlefilter = (e, m) => {
    this.setState({
      filtervalue: {
        option: e,
        is_deprecated: m,
      },
    });
  };
  handlestatus_text = (addon_type, addon_identifier) => {
    if (addon_type === 1) {
      if (addon_identifier === "PREVENT_SCREENSHOT") {
        return (
          <div className="service_filter comin_soon mt-3 ">
            <p>Coming Soon</p>
          </div>
        );
      } else {
        return (
          <div className="service_filter mt-3 ">
            <p>Professional Service</p>
          </div>
        );
      }
    } else {
      if (addon_identifier === "PREVENT_SCREENSHOT") {
        return (
          <div className="service_filter comin_soon mt-3 ">
            <p>Coming Soon</p>
          </div>
        );
      } else {
        return (
          <div className="service_filter plugin_ mt-3 ">
            <p>Plugin</p>
          </div>
        );
      }
    }
  };
  // Check addon active
  checkActive = (addon_identifier) => {
    let recurring_json = [];
    let onetime_json = [];
    let mergeArray = [];
    if (this.state.customer_billing_type === 2) {
      if (this.state.recurring_addons_purchased_json !== 0) {
        recurring_json = JSON.parse(
          JSON.stringify(this.state.recurring_addons_purchased_json)
        ).map((res) => res.price_id);
      }
      if (this.state.onetime_addons_purchased_json !== 0) {
        onetime_json = JSON.parse(
          JSON.stringify(this.state.onetime_addons_purchased_json)
        ).map((res) => res.addon);
      }
    }
    if (this.state.customer_billing_type === 2) {
      mergeArray = this.state.auto_addons.concat(
        this.state.addon_code,
        this.state.onetimeaddon,
        recurring_json,
        onetime_json
      );
    } else {
      mergeArray = this.state.auto_addons.concat(
        this.state.addon_code,
        this.state.onetimeaddon
      );
    }
    return addon_identifier.some((item) => mergeArray.includes(item));
  };
  render() {
    const Usermanagement = (addon_code) => {
      if (this.state.recurring_addons_purchased_json === 0) {
        if (this.state.addon_code.indexOf(addon_code) > -1) {
          return (
            <div className="d-flex apllied-img align-items-center justify-content-between">
              <img
                src={
                  require("../../assets/images/dashboard/app-verified.png")
                    .default
                }
                alt="Cancel Build"
                className="img-fluid"
              />
              <p
                className="text-active-text"
              >
                Active ( 1 )
              </p>
            </div>
          );
        }
      } else {
        if (
          this.state.recurring_addons_purchased_json[addon_code] !== undefined
        ) {
          if (
            this.state.recurring_addons_purchased_json[addon_code].quantity > 0
          ) {
            return (
              <div className="d-flex apllied-img align-items-center justify-content-between">
                <img
                  src={
                    require("../../assets/images/dashboard/app-verified.png")
                      .default
                  }
                  alt="Cancel Build"
                  className="img-fluid"
                />
                <p
                 className="text-active-text"
                >
                  Active ({" "}
                  {
                    this.state.recurring_addons_purchased_json[addon_code]
                      .quantity
                  }{" "}
                  )
                </p>
              </div>
            );
          }
        }
      }
    };
    const Checkpurchasednumber = (addon_code) => {
      if (this.state.allowed_module !== "preview") {
        if (this.state.customer_billing_type === 2) {
          if (this.state.onetime_addons_purchased_json !== 0) {
            let quantitycheck = this.state.onetime_addons_purchased_json.filter(
              (res) => res.addon === addon_code
            );

            if (quantitycheck.length > 0 && quantitycheck[0].quantity > 0) {
              return (
                <div className="d-flex apllied-img align-items-center justify-content-between">
                  <img
                    src={
                      require("../../assets/images/dashboard/app-verified.png")
                        .default
                    }
                    alt="Cancel Build"
                    className="img-fluid"
                  />
                  <p
                  className="text-active-text"
                  >
                    Purchased ( {quantitycheck[0].quantity} )
                  </p>
                </div>
              );
            }
          }
        } else {
          if (this.state.onetime_addons_purchased_json === 0) {
            if (this.state.onetimeaddon.indexOf(addon_code) > -1) {
              return (
                <div className="d-flex apllied-img align-items-center justify-content-between">
                  <img
                    src={
                      require("../../assets/images/dashboard/app-verified.png")
                        .default
                    }
                    alt="Cancel Build"
                    className="img-fluid"
                  />
                  <p
                   className="text-active-text"
                  >
                    Purchased ( 1 )
                  </p>
                </div>
              );
            }
          } else {
            //check key is availability
            if (
              this.state.onetime_addons_purchased_json[addon_code] !== undefined
            ) {
              if (
                this.state.onetime_addons_purchased_json[addon_code].purchased >
                0
              ) {
                return (
                  <div className="d-flex apllied-img align-items-center justify-content-between">
                    <img
                      src={
                        require("../../assets/images/dashboard/app-verified.png")
                          .default
                      }
                      alt="Cancel Build"
                      className="img-fluid"
                    />
                    <p
                     className="text-active-text"
                    >
                      Purchased ({" "}
                      {
                        this.state.onetime_addons_purchased_json[addon_code]
                          .purchased
                      }{" "}
                      )
                    </p>
                  </div>
                );
              }
            }
          }
        }
      }
    };
    // const Newappversion = () => {
    // 	if (this.state.allowed_module !== "preview") {
    // 		if (this.state.version > 0) {
    // 			return (
    // 				<div className="d-flex apllied-img align-items-center justify-content-between">
    // 					<img src={require("../../assets/images/dashboard/app-verified.png").default} alt="Cancel Build" className="img-fluid" />
    // 					<p >Purchased ( {this.state.version} )</p>
    // 				</div>
    // 			)
    // 		}

    // 	}

    // }
    const Addonimage = (addon_identifier) => {
      if (
        addon_identifier === "USER_MANAGEMENT" ||
        addon_identifier === "DEPRECATED_USER_MANAGEMENT"
      ) {
        return (
          <img
            src={
              require("../../assets/images/dashboard/addon/landing/add_user.png")
                .default
            }
            alt="add_user"
          />
        );
      }

      if (
        addon_identifier === "WHITE_LABEL" ||
        addon_identifier === "DEPRECATED_WHITE_LABEL"
      ) {
        return (
          <img
            src={
              require("../../assets/images/dashboard/addon/landing/whitelabel.png")
                .default
            }
            alt="whitelabel"
          />
        );
      }

      if (addon_identifier === "UPLOAD_TO_APP_STORE") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/addon/landing/publishtoapp.png")
                .default
            }
            alt="appstoreoptimization"
          />
        );
      }
      if (
        addon_identifier === "LANGUAGE_SUPPORT" ||
        addon_identifier === "DEPRECATED_LANGUAGE_SUPPORT"
      ) {
        return (
          <img
            src={
              require("../../assets/images/dashboard/addon/landing/language-support.png")
                .default
            }
            alt="appstoreoptimization"
          />
        );
      }

      if (addon_identifier === "MOBILE_APP_DESIGN") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/addon/landing/customdesign.png")
                .default
            }
            alt="appstoreoptimization"
          />
        );
      }
      if (
        addon_identifier === "CONSENT_FORM" ||
        addon_identifier === "DEPRECATED_CONSENT_FORM"
      ) {
        return (
          <img
            src={
              require("../../assets/images/dashboard/addon/landing/consent.png")
                .default
            }
            alt="consent"
          />
        );
      }
      if (addon_identifier === "PREVENT_SCREENSHOT") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/addon/landing/preventscreenshot.png")
                .default
            }
            alt="preventscreenshot"
          />
        );
      }
      if (addon_identifier === "APP_STORE_DESIGN") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/addon/landing/appstoredesign.png")
                .default
            }
            alt="app_store_design"
          />
        );
      }

      if (addon_identifier === "WORDPRESS_HOSTING") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/addon/landing/webhosting.png")
                .default
            }
            alt="wordpress_website_hosting"
          />
        );
      }
      if (
        addon_identifier === "MULTISITE_SUPPORT" ||
        addon_identifier === "DEPRECATED_MULTISITE_SUPPORT"
      ) {
        return (
          <img
            src={
              require("../../assets/images/dashboard/addon/landing/multisite.png")
                .default
            }
            alt="multisite_support"
          />
        );
      }
      if (addon_identifier === "ANALYTICS") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/addon/landing/analytics.png")
                .default
            }
            alt="analytics"
          />
        );
      }
      if (addon_identifier === "PUBLISHING") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/addon/landing/autopublish.png")
                .default
            }
            alt="autopublish"
          />
        );
      }
      if (addon_identifier === "CHAT_MODULE") {
        return (
          <img
            src={
              require("../../assets/images/dashboard/addon/landing/chat.png")
                .default
            }
            alt="chat-module"
          />
        );
      }
    };
    return (
      <>
        <div className="">
          <Header customclass={"header-responsive-new"} />
        </div>
        <Accountleftpanel
          ipadView={"initial-leftpanel-ipadview"}
          // userCondition={"d-none"}
          // checker={true}
        />
       
        <section className="dasboard_page mobile-display mt-4 mt-md-0">
          {/* <Leftpanel
            ipadView={"initial-leftpanel-ipadview"}
            ipadLeftclass={"ipadview-leftscroll"}
          /> */}
          <div className="transparent_background ipad-addon-view">
            <div
              className="right_panel custon_gap2 ipad-addon-headpadding "
            
            >
              <div className="right_general workspace-addon-general" >
                {this.state.spinner === false &&
                  this.state.appuser_role_id !== 3 &&
                  this.state.appuser_role_id !== 4 && (
                    <div className="app_iconname d-md-flex justify-content-between align-items-start add-portion">
                      <div className="d-none d-md-block ipad-addon-leftpadding">
                        <h3 className="mb-2 mt-2">Add-on products </h3>
                        <h5 className="ipad-grant-subtext">
                          Supercharge your subscription with a range of add-on
                          products. Get additional features and services for
                          your app.{" "}
                          {/* <Media
                            href="https://www.appmysite.com/support/category/professional-services/"
                            target="_blank"
                            rel="noopener noreferrer"
                            tag="a"
                            className="ipad-link-viewspacing"
                          >
                            Learn More{" "}
                            <img
                              src={
                                require("../../assets/images/dashboard/Goto.png")
                                  .default
                              }
                              className="gotoappicon appear-spacing"
                              alt="Goto"
                            />
                          </Media>{" "} */}
                        </h5>
                      </div>

                      <div className="filter_by d-flex align-items-center">
                        {this.state.customer_billing_type !== 2 && (
                          <div
                            className={
                              this.state.filtervalue.option === ""
                                ? "filter_by_active filter_by_child"
                                : "filter_by_child"
                            }
                            onClick={(e) => this.handlefilter("", 0)}
                          >
                            <p>All</p>
                          </div>
                        )}
                        {(this.state.zoho_plan_code
                          .toString()
                          .indexOf(process.env.REACT_APP_Lifetime_plan) > -1 ||
                          this.state.zoho_plan_code
                            .toString()
                            .indexOf(process.env.REACT_APP_Lifetime_preview) >
                            -1) && (
                          <>
                            <div
                              className={
                                this.state.filtervalue.option === 1
                                  ? "filter_by_active filter_by_child"
                                  : "filter_by_child"
                              }
                              onClick={(e) => this.handlefilter(1, 0)}
                            >
                              <p>Professional Service</p>
                            </div>
                            <div
                              className={
                                this.state.filtervalue.option === 2
                                  ? "filter_by_active filter_by_child"
                                  : "filter_by_child"
                              }
                              onClick={(e) => this.handlefilter(2, 0)}
                            >
                              <p>Plugin</p>
                            </div>
                          </>
                        )}
                        {[
                          "consent_form",
                          "language_support",
                          "multisite_support",
                          "add_user",
                          "white_label",
                        ].some((res) =>
                          this.state.onetimeaddon
                            .concat(this.state.addon_code)
                            .includes(res)
                        ) && (
                          <div
                            className={
                              this.state.filtervalue.option === 3
                                ? "filter_by_active filter_by_child"
                                : "filter_by_child"
                            }
                            onClick={(e) => this.handlefilter(3, 1)}
                          >
                            <p>Discontinued</p>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                {this.state.spinner ? (
                  <div className="spinn">
                    <Spinner animation="border" variant="primary" />
                  </div>
                ) : (
                  <>
                    <div className="right_general mt-md-4">
                      {this.state.appuser_role_id === 3 ||
                      this.state.appuser_role_id === 4 ? (
                        Notallowedtouser()
                      ) : (
                        <div className="d-block d-md-flex alignt-items-center flex-wrap app-addons">
                          {this.state.addondata
                            .filter((res) =>
                              this.state.filtervalue.option === "" ||
                              this.state.filtervalue.option === 3
                                ? res &&
                                  res.is_deprecated ===
                                    this.state.filtervalue.is_deprecated
                                : res.addon_type ===
                                    this.state.filtervalue.option &&
                                  res.is_deprecated ===
                                    this.state.filtervalue.is_deprecated
                            )
                            .map((res, i) => (
                              <>
                                {/* Check for appsumoclient */}
                                {this.state.zoho_plan_code
                                  .toString()
                                  .indexOf(
                                    process.env.REACT_APP_Lifetime_plan
                                  ) > -1 ||
                                this.state.zoho_plan_code
                                  .toString()
                                  .indexOf(
                                    process.env.REACT_APP_Lifetime_preview
                                  ) > -1
                                  ? res.addon_identifier !==
                                      "WORDPRESS_HOSTING" &&
                                    res.addon_identifier !==
                                      "NEW_APP_VERSION" &&
                                    (this.state.onetimeaddon.indexOf(
                                      res.addon_code
                                    ) !== -1 ||
                                      this.state.addon_code.indexOf(
                                        res.addon_code
                                      ) !== -1 ||
                                      res.addon_identifier === "ANALYTICS" ||
                                      res.addon_identifier ===
                                        "MOBILE_APP_DESIGN" ||
                                      res.addon_identifier ===
                                        "APP_STORE_DESIGN" ||
                                      res.addon_identifier === "CHAT_MODULE" ||
                                      res.addon_identifier === "CONSENT_FORM" ||
                                      res.addon_identifier ===
                                        "LANGUAGE_SUPPORT" ||
                                      res.addon_identifier ===
                                        "MULTISITE_SUPPORT" ||
                                      res.addon_identifier === "PUBLISHING" ||
                                      res.addon_identifier ===
                                        "UPLOAD_TO_APP_STORE" ||
                                      res.addon_identifier ===
                                        "USER_MANAGEMENT" ||
                                      res.addon_identifier ===
                                        "WHITE_LABEL") && (
                                      <div
                                        className="w-24 update_add_on workspace-update-add-on"
                                        key={i}
                                       
                                      >
                                        <div
                                          className="app-security all-addons text-left h-100"
                                          onClick={(e) =>
                                            this.handleaddonpayment(
                                              res.addon_identifier,
                                              "/app/addons/" +
                                                res.addon_name
                                                  .replaceAll(" ", "_")
                                                  .toLowerCase() +
                                                "/" +
                                                btoa(res.addon_code) +
                                                "/" +
                                                btoa(
                                                  localStorage.getItem(
                                                    "workspaceId"
                                                  )
                                                ) +
                                                "/" +
                                                btoa(
                                                  this.state.zoho_plan_code.toString()
                                                ) +
                                                "/" +
                                                btoa(res.addon_identifier) +
                                                "/" +
                                                "workspace"+"/" +
                                                this.state.website_technology
                                            )
                                          }
                                        >
                                          <div
                                            className="pl-3 pt-4 pr-3 pb-5 workspace-app-security-div"
                                         
                                          >
                                            <div className="app-security-img align-items-center d-flex justify-content-start flex-wrap">
                                              <div>
                                                {Addonimage(
                                                  res.addon_identifier
                                                )}
                                              </div>
                                              <div>
                                                <h3 className="mt-0 ml-2 mb-0">
                                                  {res.addon_name}
                                                </h3>
                                              </div>
                                            </div>
                                            <div className="custom-content-full">
                                              <div className="custom-add pt-3">
                                                <p className="all-addons-description p-0">
                                                  {res.addon_description}
                                                </p>
                                                {this.handlestatus_text(
                                                  res.addon_type,
                                                  res.addon_identifier
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="d-flex pl-3 pr-3 align-items-center justify-content-between workspace-addon-pack-div"
                                           
                                          >
                                            <div className="addonpack ">
                                              <h4 className="mb-0 pb-0 mt-0 pt-0">
                                                {
                                                  res.package_currency
                                                    .currency_symbol
                                                }{" "}
                                                {res.amount.replace(".00", "")}/
                                                <span>
                                                  {res.package_duration.name.toLowerCase()}
                                                </span>
                                              </h4>
                                            </div>
                                            {/* Status */}

                                            {/* {res.addon_code === "update_app" && Newappversion()} */}

                                            {res.addon_identifier ===
                                              "UPLOAD_TO_APP_STORE" &&
                                              Checkpurchasednumber(
                                                res.addon_code
                                              )}
                                            {res.addon_identifier ===
                                              "MOBILE_APP_DESIGN" &&
                                              Checkpurchasednumber(
                                                res.addon_code
                                              )}
                                            {res.addon_identifier ===
                                              "APP_STORE_DESIGN" &&
                                              Checkpurchasednumber(
                                                res.addon_code
                                              )}
                                            {res.addon_identifier !==
                                              "APP_STORE_DESIGN" &&
                                              res.addon_identifier !==
                                                "MOBILE_APP_DESIGN" &&
                                              (res.addon_identifier ===
                                                "USER_MANAGEMENT" ||
                                                res.addon_identifier ===
                                                  "DEPRECATED_USER_MANAGEMENT") &&
                                              Usermanagement(res.addon_code)}
                                            {res.addon_identifier !==
                                              "MOBILE_APP_DESIGN" &&
                                              res.addon_identifier !==
                                                "APP_STORE_DESIGN" &&
                                              res.addon_identifier !==
                                                "UPLOAD_TO_APP_STORE" &&
                                              res.addon_identifier !==
                                                "USER_MANAGEMENT" &&
                                              res.addon_identifier !==
                                                "DEPRECATED_USER_MANAGEMENT" &&
                                              (this.state.onetimeaddon.indexOf(
                                                res.addon_code
                                              ) !== -1 ||
                                              this.state.addon_code.indexOf(
                                                res.addon_code
                                              ) !== -1 ||
                                              (res.addon_identifier ===
                                                "LANGUAGE_SUPPORT" &&
                                                this.state.auto_addons.indexOf(
                                                  "language_support_auto"
                                                ) !== -1) ||
                                              (res.addon_identifier ===
                                                "MULTISITE_SUPPORT" &&
                                                this.state.auto_addons.indexOf(
                                                  "multisite_support_auto"
                                                ) !== -1) ||
                                              (res.addon_identifier ===
                                                "LANGUAGE_SUPPORT" &&
                                                this.state.auto_addons.indexOf(
                                                  "language_support_lifetime"
                                                ) !== -1) ||
                                              (res.addon_identifier ===
                                                "MULTISITE_SUPPORT" &&
                                                this.state.auto_addons.indexOf(
                                                  "multisite_support_lifetime"
                                                ) !== -1) ||
                                              (res.addon_identifier ===
                                                "CONSENT_FORM" &&
                                                this.state.auto_addons.indexOf(
                                                  "consent_form_auto"
                                                ) !== -1) ||
                                              (res.addon_identifier ===
                                                "PUBLISHING" &&
                                                this.state.auto_addons.indexOf(
                                                  "app_publish_auto"
                                                ) !== -1) ||
                                              (res.addon_identifier ===
                                                "ANALYTICS" &&
                                                this.state.auto_addons.indexOf(
                                                  "app_analytics_auto"
                                                ) !== -1) ? (
                                                this.state.allowed_module !==
                                                  "preview" && (
                                                  <div className="d-flex align-items-center apllied-img justify-content-between">
                                                    <img
                                                      src={
                                                        require("../../assets/images/dashboard/app-verified.png")
                                                          .default
                                                      }
                                                      alt="Cancel Build"
                                                      className="img-fluid"
                                                    />
                                                    <p
                                                      className="text-active-text"
                                                    >
                                                      Active
                                                    </p>
                                                  </div>
                                                )
                                              ) : (
                                                <>
                                                  {(this.state.auto_addons.indexOf(
                                                    "white_label_lifetime"
                                                  ) !== -1 ||
                                                    this.state.onetimeaddon.indexOf(
                                                      "white_label_pro"
                                                    ) !== -1 ||
                                                    this.state.auto_addons.indexOf(
                                                      "white_label_auto"
                                                    ) !== -1) &&
                                                  res.addon_identifier ===
                                                    "WHITE_LABEL"
                                                    ? this.state
                                                        .allowed_module !==
                                                        "preview" && (
                                                        <div className="d-flex apllied-img align-items-center justify-content-between">
                                                          <img
                                                            src={
                                                              require("../../assets/images/dashboard/app-verified.png")
                                                                .default
                                                            }
                                                            alt="Cancel Build"
                                                            className="img-fluid"
                                                          />
                                                          <p
                                                            className="text-active-text"
                                                          >
                                                            Active
                                                          </p>
                                                        </div>
                                                      )
                                                    : null}
                                                </>
                                              ))}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  : // For appmysite new and old customer
                                    res.addon_identifier !==
                                      "WORDPRESS_HOSTING" &&
                                    res.addon_identifier !==
                                      "NEW_APP_VERSION" &&
                                    (this.state.onetimeaddon.indexOf(
                                      res.addon_code
                                    ) !== -1 ||
                                      this.state.addon_code.indexOf(
                                        res.addon_code
                                      ) !== -1 ||
                                      res.addon_identifier === "ANALYTICS" ||
                                      res.addon_identifier ===
                                        "MOBILE_APP_DESIGN" ||
                                      res.addon_identifier ===
                                        "APP_STORE_DESIGN" ||
                                      res.addon_identifier === "CHAT_MODULE" ||
                                      res.addon_identifier === "CONSENT_FORM" ||
                                      res.addon_identifier ===
                                        "LANGUAGE_SUPPORT" ||
                                      res.addon_identifier ===
                                        "MULTISITE_SUPPORT" ||
                                      res.addon_identifier === "PUBLISHING" ||
                                      res.addon_identifier ===
                                        "UPLOAD_TO_APP_STORE" ||
                                      res.addon_identifier ===
                                        "USER_MANAGEMENT" ||
                                      res.addon_identifier ===
                                        "WHITE_LABEL") && (
                                      <div
                                        className="w-24 update_add_on workspace-update-add-on"
                                        key={i}
                                      
                                      >
                                        <div
                                          className="app-security all-addons text-left h-100"
                                          onClick={(e) =>
                                            this.handleaddonpayment(
                                              res.addon_identifier,
                                              "/app/addons/" +
                                                res.addon_name
                                                  .replaceAll(" ", "_")
                                                  .toLowerCase() +
                                                "/" +
                                                btoa(res.addon_code) +
                                                "/" +
                                                btoa(
                                                  localStorage.getItem(
                                                    "workspaceId"
                                                  )
                                                ) +
                                                "/" +
                                                btoa(
                                                  this.state.zoho_plan_code.toString()
                                                ) +
                                                "/" +
                                                btoa(res.addon_identifier) +
                                                "/" +
                                                "workspace"+"/" +
                                                this.state.website_technology
                                            )
                                          }
                                        >
                                          <div
                                            className="pl-3 pt-4 pr-3 pb-5 workspace-app-security-div"
                                           
                                          >
                                            <div className="app-security-img align-items-center d-flex justify-content-start flex-wrap">
                                              <div>
                                                {Addonimage(
                                                  res.addon_identifier
                                                )}
                                              </div>
                                              <div>
                                                <h3 className="mt-0 ml-2 mb-0">
                                                  {res.addon_name}
                                                </h3>
                                              </div>
                                            </div>
                                            <div className="custom-content-full">
                                              <div className="custom-add pt-3">
                                                <p className="all-addons-description p-0">
                                                  {res.addon_description}
                                                </p>
                                                {this.handlestatus_text(
                                                  res.addon_type,
                                                  res.addon_identifier
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                          <div
                                            className="d-flex pl-3 pr-3 align-items-center justify-content-between workspace-addon-pack-div"
                                          
                                          >
                                            <div className="addonpack ">
                                              <h4 className="mb-0 pb-0 mt-0 pt-0">
                                                {
                                                  res.package_currency
                                                    .currency_symbol
                                                }{" "}
                                                {res.amount.replace(".00", "")}/
                                                <span>
                                                  {res.package_duration.name.toLowerCase()}
                                                </span>
                                              </h4>
                                            </div>
                                            {/* Status */}

                                            {/* {res.addon_code === "update_app" && Newappversion()} */}
                                            {res.addon_identifier ===
                                              "UPLOAD_TO_APP_STORE" &&
                                              Checkpurchasednumber(
                                                res.addon_code
                                              )}
                                            {res.addon_identifier ===
                                              "MOBILE_APP_DESIGN" &&
                                              Checkpurchasednumber(
                                                res.addon_code
                                              )}
                                            {res.addon_identifier ===
                                              "APP_STORE_DESIGN" &&
                                              Checkpurchasednumber(
                                                res.addon_code
                                              )}
                                            {(res.addon_identifier ===
                                              "USER_MANAGEMENT" ||
                                              res.addon_identifier ===
                                                "DEPRECATED_USER_MANAGEMENT") &&
                                              Usermanagement(res.addon_code)}
                                            {res.addon_identifier !==
                                              "APP_STORE_DESIGN" &&
                                              res.addon_identifier !==
                                                "MOBILE_APP_DESIGN" &&
                                              res.addon_identifier !==
                                                "UPLOAD_TO_APP_STORE" &&
                                              res.addon_identifier !==
                                                "USER_MANAGEMENT" &&
                                              res.addon_identifier !==
                                                "DEPRECATED_USER_MANAGEMENT" &&
                                              (this.checkActive(
                                                this.state.addon_info[
                                                  res.addon_identifier
                                                ].split(",")
                                              )
                                                ? this.state.allowed_module !==
                                                    "preview" && (
                                                    <div className="d-flex align-items-center apllied-img justify-content-between">
                                                      <img
                                                        src={
                                                          require("../../assets/images/dashboard/app-verified.png")
                                                            .default
                                                        }
                                                        alt="Cancel Build"
                                                        className="img-fluid"
                                                      />
                                                      <p
                                                       className="text-active-text"
                                                      >
                                                        Active
                                                      </p>
                                                    </div>
                                                  )
                                                : null)}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                              </>
                            ))}
                        </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* {InnerFoot()} */}
          </div>
        </section>
      </>
    );
  }
}
