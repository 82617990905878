import React from "react";
import { Link } from "react-router-dom";
import infoIc from "../../../assets/images/dashboard/info.svg";
import firebaseIc from "../../../assets/images/dashboard/firebaseIc.svg";
import searchIc from "../../../assets/images/dashboard/search.png";
import filterIc from "../../../assets/images/dashboard/filterIcWithoutDot.svg";
import buildSettingsIc from "../../../assets/images/dashboard/buildSettings.svg";
import buildsettingnotselect from "../../../assets/images/dashboard/buildsetting-notselect.svg";
import whitePlusIc from "../../../assets/images/dashboard/whiteplus.png";
import ModuleTitleTooltip from "../../commonIcons/ModuleTitleTooltip";

const SubHeader = ({
  notificationData,
  setNotificationData,
  agencyId,
  handleNotificationClick,
}) => {
  const BUTTON_STYLE = {
    backgroundColor: localStorage.getItem("button_background_color"),
    border: "1px solid " + localStorage.getItem("button_background_color"),
    color: localStorage.getItem("button_text_color"),
    "--custom_color5":
      +localStorage.getItem("agencyid") === 1
        ? "#134bec"
        : localStorage.getItem("button_hover_bgcolor"),
    "--custom_color4":
      +localStorage.getItem("agencyid") === 1
        ? "white"
        : localStorage.getItem("button_hover_txtcolor"),
    "--custom_color6":
      +localStorage.getItem("agencyid") === 1
        ? "#3064f9"
        : localStorage.getItem("button_hover_bgcolor"),
  };

  return (
    <div className="web-notification-head plian-web-notification-head">
      <ModuleTitleTooltip
        title={"Basic Notifications"}
        text={"Send out updates and engaging messages to your app users."}
      />
      {agencyId &&
      +agencyId !== 1 &&
      window.matchMedia("(max-width: 1200px)").matches &&
      !notificationData.isEnableScreen  &&
      !notificationData.spinner ? (
        <div className="upgrade-feature notification-upgrade-feature">
          <h4 className="upgrade-unavailable notification-upgrade-support">
            This feature is currently unavailable
          </h4>
          <p className="upgrade-support notification-upgrade-support">
            Please contact Support for assistance.
          </p>
        </div>
      ) : null}
      {notificationData.isEnableScreen ? (
        <div className="notificationcreate-new">
          {notificationData.isEnableScreen ? (
            <div className="notificationcreate-new ">
              {notificationData.displayTable &&
              notificationData.displayTable?.length > 0 ? (
                <>
                  <div className="pushnotification-firebase"onClick={() =>
                        setNotificationData({
                          isFirebaseModalOpen:
                            !notificationData.isFirebaseModalOpen,
                        })
                      }>
                    <img
                      src={firebaseIc}
                      alt="firebase"
                      
                    />
                    Firebase
                  </div>

                  <div className="pushnotification-Search">
                    <button>
                      <img src={searchIc} alt="search" />
                    </button>
                    <input
                      placeholder="Search"
                      value={notificationData.searchVal || ""}
                      onChange={(e) =>
                        setNotificationData({ searchVal: e.target.value })
                      }
                    ></input>
                  </div>
                </>
              ) : null}

              <div className="pushnotification-filter">
                <img
                  src={filterIc}
                  alt="filter"
                  onClick={() => handleNotificationClick()}
                />
                {notificationData.redDotOnFilter ? (
                  <div className="filterapplyed-reddot notification-filterapplyed-reddot"></div>
                ) : null}
              </div>

              {notificationData.multisiteData &&
              notificationData.multisiteData?.is_app_link_to_multisite <= 0 ? (
                <>
                  <Link
                    className="consent-setting"
                    to={"/app/notifications/build-settings?type=plain"}
                  >
                    <p className="pointcursor build-settings-btn">
                      <img src={buildsettingnotselect} alt="build settings" />
                    </p>
                    <span className="build-settings-text">Build Settings</span>
                  </Link>

                  <Link
                    to="/app/basic-notification/create"
                    className="notification-createbtn  mobilecreatenew add-notification"
                    style={BUTTON_STYLE}
                  >
                    <img src={whitePlusIc} className="img-fluid " alt="plus" />
                    Create
                  </Link>
                </>
              ) : null}
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

export default SubHeader;
