import React, {useState, useEffect, useRef, useContext} from "react";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import Select from "react-select";
import { useParams,Link } from "react-router-dom";
import "./newAudience.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FormGroup, Label, Input } from "reactstrap";
import { Button } from "reactstrap";
import FirstSessionSegment from "./Audience/FirstSessionSegment";
import LastSessionSegment from "./Audience/LastSessionSegment";
import SessionCountSegment from "./Audience/SessionCountSegment";
import TotalDurationSegment from "./Audience/TotalDurationSegment";
import LanguageSegment from "./Audience/LanguageSegment";
import LocationSegment from "./Audience/LocationSegment";
//import TestUsersSegment from "./Audience/TestUsersSegment";
import EmailSegment from "./Audience/EmailSegment";
import CountrySegment from "./Audience/CountrySegment";
import AppVersionSegment from "./Audience/AppVersionSegment";
import SegmentCategory from "./SegmentCategory";
import SegmentPopup from "./SegmentPopup";
import {
    saveSegmentData,
    getSegmentCount,
    updateSegmentData,
    editSegmentData,
} from "../../Api/RichNotification/RichNotificationApi";
import { errortoast } from "../Toaster";
import NewSpinner from "./NewSpinner";
import { useLocation } from "react-router-dom";
import { previewContext } from "../../App";
import PremiumTag from "../../Components/commonIcons/PremiumTag";

const SegmentCreate = (props) => {

    let { editsegmentid } = useParams("editsegmentid");

    const [title, setTitle] = useState("Audience");
    const [segmentcategory, setSegmentCategory] = useState("");
    const [firstsessionfilter, setFirstSessionFilter] = useState([]);
    const [filtertype, setFilterType] = useState([]);
    const [inputkey, setInputKey] = useState([]);
    const [inputvalue, setInputValue] = useState([]);
    const [inputvalue1, setInputValue1] = useState([]);
    const [segmentrules, setSegmentRules] = useState([]);
    const [segmentname, setSegmentName] = useState("");
    const [isLoader, setIsLoader] = useState("d-block");
    const [btnloader, setBtnLoader] = useState("d-none");
    const [btnsubmitlabel, setSubmitLabel] = useState(editsegmentid?"Save":"Create");
    const [spinner, setSpinner] = useState(false);
    //const [planname, setPlanName] = useState("");
    const [segoperator, setSegOperator] = useState([]);
    //const [isadded, setIsAdded] = useState(false);
    //const [isdelete, setIsDelete] = useState(false);
    const [segmentid, setSegmentID] = useState("");
    const [customblockpopup, setCustomBlockPopup] = useState(false);
    const [issegmentcategorydisplay, setIsSegmentCategoryDisplay] = useState(true);
    const [segmentcount, setSegmentCount] = useState(0);
    const [openrowfirst, setOpenRowFirst] = useState(1);
    const [openrownum, setOpenRowNumber] = useState([]);
    const [inputlabelvalue, setInputLabelValue] = useState([]);
    //const [errfsselect, setErrFSSelect] = useState(false);
    const [errfsrules, setErrFSRules] = useState([]);
    const [errfsvalue, setErrFSValue] = useState([]);
    const [errlsrules, setErrLSRules] = useState([]);
    const [errlsvalue, setErrLSValue] = useState([]);
    const [errscrules, setErrSCRules] = useState([]);
    const [errscvalue, setErrSCValue] = useState([]);
    const [errtdrules, setErrTDRules] = useState([]);
    const [errtdvalue, setErrTDValue] = useState([]);
    const [errlanrules, setErrLanRules] = useState([]);
    const [errlanvalue, setErrLanValue] = useState([]);
    const [erravrules, setErrAVRules] = useState([]);
    const [erravvalue, setErrAVValue] = useState([]);
    const [errlocrules, setErrLocRules] = useState([]);
    const [errlocvalue, setErrLocValue] = useState([]);
    const [errlocvalue1, setErrLocValue1] = useState([]);
    const [erremailrules, setErrEmailRules] = useState([]);
    const [errconrules, setErrConRules] = useState([]);
    const [errconvalue, setErrConValue] = useState([]);
    const [errortitle, setErrorTitle] = useState(false);
    //const [allinputstatus, setAllInputStatus] = useState(false);
    const allinputstatus = useRef(false);
    const [appRightPanelExpand, setAppRightPanelExpand] = useState( localStorage.getItem("appSideBarCollapse") === true || localStorage.getItem("appSideBarCollapse") === "true" ? true : false);
    const appPlan= localStorage.getItem("app_plan")
    const {toggleActivateModal} = useContext(previewContext)
    const location = useLocation();
    const { skipButtonActive,planCode } = location.state || {};

    /*const [ruleserror, setRulesError] = useState(false);
    const [valueerrror, setValueError] = useState(false);
    const [valueerrror1, setValueError1] = useState(false);
    const [openfsrownum, setOpenFSRowNumber] = useState(null);
    const [openlsrownum, setOpenLSRowNumber] = useState(null);
    const [openscrownum, setOpenSCRowNumber] = useState(null);
    const [opentdrownum, setOpenTDRowNumber] = useState(null);
    const [openlanrownum, setOpenLanRowNumber] = useState(null);
    const [openavrownum, setOpenAVRowNumber] = useState(null);
    const [openlocrownum, setOpenLocRowNumber] = useState(null);
    const [openemailrownum, setOpenEmailRowNumber] = useState(null);
    const [openconrownum, setOpenConRowNumber] = useState(null);*/
    
    
    //const [errfsselect, setErrFSSelect] = useState(false);





    const segmenttypes = [
        {name: "first_session", type: 1}, 
        {name: "last_session", type: 2}, 
        {name: "session_count", type: 3}, 
        {name: "session_time", type: 4}, 
        {name: "language", type: 5}, 
        {name: "app_version", type: 6}, 
        {name: "location", type: 7}, 
        {name: "email", type: 8}, 
        {name: "country", type: 9}, 
        {name: "test_users", type: 10}, 
    ];

    useEffect(()=>{

        //getSegmentCount(setSegmentCount);

        if(editsegmentid !== null && editsegmentid !== '' && editsegmentid !== undefined)
        {
            if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
                {
                  document.title = "Edit subset | AppMySite";
                }
                else 
                {
                  document.title = "Edit subset |"+ ' ' +localStorage.getItem('agencyname');
                }

            setSpinner(true);
            
            const params = {
                "segment_id": editsegmentid,
                "app_id": localStorage.getItem("Appid")
            };
    
            //setSubmitButtonValue("Update Segment");
    
            editSegmentData(params, setSegmentRules, setSegmentName, setInputKey, setInputValue, setIsSegmentCategoryDisplay, setInputValue1, setSegmentID, setFilterType, setSpinner, setOpenRowNumber, setSegmentCount);
        }
        else
        {
            if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
                {
                  document.title = "Create subset | AppMySite";
                }
                else 
                {
                  document.title = "Create subset |"+ ' ' +localStorage.getItem('agencyname');
                }
        }

    }, []);

    const handleSegmentName = (event) =>{

        setSegmentName(event.target.value.replaceAll( /  +/g, ' ' ));
    }

    const validateEmail = (email) => 
    {
        return email.match(
          /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const hasExactlyTwoPoints = (input) => 
    {
        // Check if the input is a string
        if (typeof input !== 'string') {
            return false;
        }
        
        // Count the number of decimal points
        const pointCount = (input.match(/\./g) || []).length;
        
        // Ensure the string contains only digits and decimal points
        const validCharacters = /^[0-9.]+$/.test(input);
 
        
        // Check if there are exactly two decimal points and valid characters
        return pointCount === 2 && validCharacters;
    }
     

    const btnCreateSegmentClick = (event) =>{

        if(planCode === 203 ){
            toggleActivateModal(event)
            return;
           }
    
        event.preventDefault();


        

        let filtertypelist = filtertype;
        let inputkeylist = inputkey;
        let inputvaluelist = inputvalue;
        let inputvaluelist1 = inputvalue1;
        let operators = segoperator;     
        let allrules = [];
        let returnstatus = false;
        let fsinputerror = [];
        let lsinputerror = [];
        let scinputerror = [];   
        let tdinputerror = [];
        let laninputerror = [];
        let avinputerror = [];
        let locinputerror = [];
        let emailinputerror = [];
        let coninputerror = [];
        let fsvalueerror = [];
        let lsvalueerror = [];
        let scvalueerror = [];
        let tdvalueerror = [];
        let lanvalueerror = [];
        let avvalueerror = [];
        let locvalueerror = [];
        let locvalue1error = [];
        //let emailvalueerror = [];
        let convalueerror = [];
        let errorstatus = false;


        if(segmentname === '')
        {
            errortoast("Segment name is a required field.");
            //setErrorTitle(true);
            errorstatus = true;
            setOpenRowFirst(1);
            return;
        }
        else
        {
            errorstatus = false;
            setErrorTitle(false);
            setOpenRowFirst(0);
        }

        if(segmentrules.length > 0)
        {
            
            segmentrules.map((item, keyidx) =>{

                
                if(item === 1 || item === 2 || item === 3 || item === 4 || item === 5 || item === 6 || item === 9)
                {
                    //First visit
                    
                    if(inputkeylist.length <= 0 || (inputkeylist[keyidx] === "" || inputkeylist[keyidx] === undefined))
                    {
                        
                        if(item === 1)
                        {
                            
                            //fsinputerror.push(true);
                            errorstatus = true;
                            errortoast("First visit filter value is a required field.");
                            return;
                            //setErrFSRules(fsinputerror);
                            allinputstatus.current = true;
                        }
                        else if(item === 2)
                        {
                            
                            //lsinputerror.push(true);
                            //setErrLSRules(lsinputerror);
                            errorstatus = true;
                            errortoast("Last visit filter value is a required field.");
                            return;
                            allinputstatus.current = true;
                        }
                        else if(item === 3)
                        {
                            //setErrSCRules([...errscrules, true]);
                            //scinputerror.push(true);
                            //setErrSCRules(scinputerror);
                            errorstatus = true;
                            errortoast("Visit count filter value is a required field.");
                            return;
                            allinputstatus.current = true;
                        }
                        else if(item === 4)
                        {
                            
                            //tdinputerror.push(true);
                            //setErrTDRules(tdinputerror);
                            errorstatus = true;
                            errortoast("Visit duration filter value is a required field.");
                            return;

                            allinputstatus.current = true;
                        }
                        else if(item === 5)
                        {
                            //setErrLanRules([...errlanrules, true]);
                            //laninputerror.push(true);
                            //setErrLanRules(laninputerror);
                            errorstatus = true;
                            errortoast("Language filter value is a required field.");
                            return;
                            allinputstatus.current = true;
                        }
                        else if(item === 6)
                        {
                            //setErrAVRules([...erravrules, true]);
                            //avinputerror.push(true);
                            //setErrAVRules(avinputerror);
                            errorstatus = true;
                            errortoast("App version filter value is a required field.");
                            return;
                            allinputstatus.current = true;
                        }
                        else if(item === 9)
                        {
                            
                            //coninputerror.push(true);
                            //setErrConRules(coninputerror);
                            errorstatus = true;
                            errortoast("Country filter value is a required field.");
                            return;
                            allinputstatus.current = true;
                        }
                        //setErrFSRules(true);
                        //setRulesError(true);
                    }
                    else
                    {
                        
                        errorstatus = false;
                        
                        if(item === 6)
                        {
                            if(inputkeylist[keyidx] != "" || inputkeylist[keyidx] != undefined)
                            {
                                
                                if(!(hasExactlyTwoPoints(inputvaluelist[keyidx])))
                                {
                                    errorstatus = true;
                                    errortoast("App version is not valid.");
                                    return;
                                }
                                
                                
                            }    
                        }
                        
                     
                        /*fsinputerror.push(false);
                        setErrFSRules(fsinputerror);

                        lsinputerror.push(false);
                        setErrLSRules(lsinputerror);
                        

                        scinputerror.push(false);
                        setErrSCRules(scinputerror);

                        tdinputerror.push(false);
                        setErrTDRules(tdinputerror);

                        laninputerror.push(false);
                        setErrLanRules([laninputerror]);

                        avinputerror.push(false);
                        setErrAVRules(avinputerror);

                        coninputerror.push(false);
                        setErrConRules(coninputerror);

                        allinputstatus.current = false;*/
                        
                    }
                                        
                    if(inputvaluelist.length <= 0 || (inputvaluelist[keyidx] === "" || inputvaluelist[keyidx] === undefined))
                    {
                        
                        //setErrFSValue(true);
                        //setValueError(true);

                        if(item === 1)
                        {                            
                            //fsvalueerror.push(true);
                            //setErrFSValue(fsvalueerror);
                            errorstatus = true;
                            errortoast("First visit filter value is a required field.");
                            return;
                            
                            allinputstatus.current = true;
                        }
                        else if(item === 2)
                        {
                            //lsvalueerror.push(true);
                            //setErrLSValue(lsvalueerror);
                            errorstatus = true;
                            errortoast("Last visit filter value is a required field.");
                            return;
                            allinputstatus.current = true;
                        }
                        else if(item === 3)
                        {
                            //scvalueerror.push(true);
                            //setErrSCValue(scvalueerror);
                            errorstatus = true;
                            errortoast("Visit count filter value is a required field.");
                            return;
                            allinputstatus.current = true;
                        }
                        else if(item === 4)
                        {
                            //tdvalueerror.push(true);
                            //setErrTDValue(tdvalueerror);
                            errorstatus = true;
                            errortoast("Visit duration filter value is a required field.");
                            return;
                            allinputstatus.current = true;
                        }
                        else if(item === 5)
                        {
                            //lanvalueerror.push(true);
                            //setErrLanValue(lanvalueerror);
                            errorstatus = true;
                            errortoast("Language filter value is a required field.");
                            return;
                            allinputstatus.current = true;
                        }
                        else if(item === 6)
                        {
                            //avvalueerror.push(true);
                            //setErrAVValue(avvalueerror);
                            errorstatus = true;
                            errortoast("App version filter value is a required field.");
                            return;
                            allinputstatus.current = true;
                        }
                        else if(item === 9)
                        {
                            //convalueerror.push(true);
                            //setErrConValue(convalueerror);
                            errorstatus = true;
                            errortoast("Country filter value is a required field.");
                            return;
                            allinputstatus.current = true;
                        }
                    }
                    else
                    {
                        
                        errorstatus = false;
                        /*fsvalueerror.push(false);
                        setErrFSValue(fsvalueerror);

                        lsvalueerror.push(false);
                        setErrLSValue(lsvalueerror);

                        scvalueerror.push(false);
                        setErrSCValue(scvalueerror);

                        tdvalueerror.push(false);
                        setErrTDValue(tdvalueerror);

                        lanvalueerror.push(false);
                        setErrLanValue(lanvalueerror);

                        avvalueerror.push(false);
                        setErrAVValue(avvalueerror);

                        convalueerror.push(false);
                        setErrConValue(convalueerror);

                        allinputstatus.current = false;*/

                        
                    }
                }
                else if(item === 7)
                {
                    //First visit
                    
                    if(inputkeylist.length <= 0 || (inputkeylist[keyidx] === "" || inputkeylist[keyidx] === undefined))
                    {
                        
                        
                        //locinputerror.push(true);
                        //setErrLocRules(locinputerror);
                        errorstatus = true;
                        errortoast("Location filter value is a required field.");
                        return;

                        allinputstatus.current = true;
                    }
                    else
                    {
                        
                        
                        //locinputerror.push(false);
                        //setErrLocRules(locinputerror);
                        errorstatus = false;
                        allinputstatus.current = false;
                    }
                    
                    
                    if(inputvaluelist.length <= 0 || (inputvaluelist[keyidx] === "" || inputvaluelist[keyidx] === undefined))
                    {
                        
                        
                        //locvalueerror.push(true);
                        //setErrLocValue(locvalueerror);
                        errorstatus = true;
                        errortoast("Location filter value is a required field.");
                        return;

                        allinputstatus.current = true;
                    }
                    else
                    {
                        
                        errorstatus = false;
                        //locvalueerror.push(false);
                        //setErrLocValue(locvalueerror);
                        allinputstatus.current = false;
                    }

                    if(inputvaluelist1.length <= 0 || (inputvaluelist1[keyidx] === "" || inputvaluelist1[keyidx] === undefined))
                    {
                        
                        
                        //locvalue1error.push(true);
                        //setErrLocValue1(locvalue1error);
                        errorstatus = true;
                        errortoast("Location filter value is a required field.");
                        return;

                        allinputstatus.current = true;
                    }
                    else
                    {
                        
                        errorstatus = false;
                        //locvalue1error.push(false);
                        //setErrLocValue1(locvalue1error);
                        allinputstatus.current = false;
                    }
                }
                else if(item === 8)
                {
                    
                    
                    if(inputvaluelist.length <= 0 || (inputvaluelist[keyidx] === "" || inputvaluelist[keyidx] === undefined))
                    {
                        
                    
                        errorstatus = true;
                        errortoast("Email filter value is a required field.");
                        return;

                        allinputstatus.current = true;

                    }
                    else
                    {
                        
                        if(validateEmail(inputvaluelist[keyidx]))
                        {
                            errorstatus = false;
                            allinputstatus.current = false;
                        } 
                        else
                        {
                            errorstatus = false;
                            errortoast("Email filter value is invalid.");
                            allinputstatus.current = false;
                            return;
                        }
                        
                        
                        
                        
                    }
                                        
                }

            });
        }

        if(errorstatus === true)
        {
            
            return;
        }
        else
        {
            
            filtertypelist.map((listdata, idx)=>{ 
                var fields = segmenttypes.find(item => item.type === listdata);
                
                if(filtertypelist.length > (idx + 1))
                {
                    
                    if(fields.name === 'location')
                    {
                                                
                        allrules.push({
                            "field" : fields.name,
                            "radius" : inputkeylist[idx],
                            "lat" : inputvaluelist[idx],
                            "long" : inputvaluelist1[idx],
                            "operator" : "AND"
                        })
                        
                    }
                    else if(fields.name === 'session_count' || fields.name === 'language' || fields.name === 'app_version' || fields.name === 'country' || fields.name === 'session_time')
                    {                        
                        
                        allrules.push({
                            "field" : fields.name,
                            "relation" : (
                                            inputkeylist[idx] === 'gt' ? '>' 
                                        :
                                            ( 
                                                inputkeylist[idx] === 'lt' ? '<' 
                                            : 
                                                (
                                                    inputkeylist[idx] === 'eq' ? '='
                                                :
                                                    (
                                                        inputkeylist[idx] === 'neq' ? '!='
                                                    :
                                                        null
                                                    )
                                                )
                                            )
                                    ),
                            "value" : inputvaluelist[idx],
                            "operator" : "AND"
                        })
                        
                    }                
                    else if(fields.name === 'email')
                    {                        
                        allrules.push({
                            "field" : fields.name,
                            "relation" : "=",
                            "value" : inputvaluelist[idx],
                            "operator" : "AND"
                        })
                        
                    }
                    else
                    {
                        
                        allrules.push({
                            "field" : fields.name,
                            "relation" : (inputkeylist[idx] === 'gt' ? '>' : '<'),
                            "hours_ago" : inputvaluelist[idx],
                            "operator" : "AND"
                        })
                        
                    }
                }
                else
                {
                    
                    if(fields.name === 'location')
                    {
                        
                        allrules.push({
                            "field" : fields.name,
                            "radius" : inputkeylist[idx],
                            "lat" : inputvaluelist[idx],
                            "long" : inputvaluelist1[idx]
                        })
                        
                    }
                    else if(fields.name === 'session_count' || fields.name === 'language' || fields.name === 'app_version' || fields.name === 'country' || fields.name === 'session_time')
                    {
                        
                        allrules.push({
                            "field" : fields.name,
                            "relation" : (
                                            inputkeylist[idx] === 'gt' ? '>' 
                                        :
                                            ( 
                                                inputkeylist[idx] === 'lt' ? '<' 
                                            : 
                                                (
                                                    inputkeylist[idx] === 'eq' ? '='
                                                :
                                                    (
                                                        inputkeylist[idx] === 'neq' ? '!='
                                                    :
                                                        null
                                                    )
                                                )
                                            )
                                    ),
                            "value" : inputvaluelist[idx],
                        })
                    }                
                    else if(fields.name === 'email')
                    {
                        
                        allrules.push({
                            "field" : fields.name,
                            "relation" : "=",
                            "value" : inputvaluelist[idx],
                        })
                        
                    }
                    else
                    {
                        
                        
                        allrules.push({
                            "field" : fields.name,
                            "relation" : (inputkeylist[idx] === 'gt' ? '>' : '<'),
                            "hours_ago" : inputvaluelist[idx],
                        })
                        
                    }
                }

                return (allrules);
                        
            });
        }
        /*else
        {
            errortoast("Plese add atleast one filter");
            return;
        }*/

        

        setSubmitLabel("");
        setBtnLoader("d-block");
        

        let params = {};

        if(segmentid !== undefined && segmentid !== null && segmentid !== '')
        {
            
            params = {
                            "app_id": localStorage.getItem("Appid"),
                            "name": segmentname,
                            "filters_array": allrules,
                            "segment_id": segmentid
                        };
            
            
            //updateSegmentData(params, setBtnLoader, setSubmitLabel, redirectToAudience);
            updateSegmentData(params, setBtnLoader, setSubmitLabel, setSegmentName, setSegmentRules, setSegmentID, setFilterType, setInputKey, setInputValue, setInputValue1, redirectToAudience);
        }
        else
        {
            
            params = {
                            "app_id": localStorage.getItem("Appid"),
                            "name": segmentname,
                            "filters_array": allrules 
                            };

            
            saveSegmentData(params, setBtnLoader, setSubmitLabel, redirectToAudience);
        }
        
        

        
        
    }
    const handleAddSegmentFromPopup = (popupcat) =>{

        
        

        setSegmentCount(segmentcount + 1);

        if(parseInt(popupcat) === 1)
        {
            //setFirstSessionFilter([...firstsessionfilter, {selectname: "", inputname: ""} ]);
            setSegmentRules([...segmentrules, 1 ]);
        }
        else if(parseInt(popupcat) === 2)
        {
            //setLastSessionFilter([...lastsessionfilter, {selectname: "", inputname: ""} ]);
            setSegmentRules([...segmentrules, 2 ]);
        }
        else if(parseInt(popupcat) === 3)
        {
            //setSessionCountFilter([...sessioncountfilter, {selectname: "", inputname: ""} ]);
            setSegmentRules([...segmentrules, 3 ]);
        }
        else if(parseInt(popupcat) === 4)
        {
            //setTotalDurationFilter([...totaldurationfilter, {selectname: "", inputname: ""} ]);
            setSegmentRules([...segmentrules, 4 ]);
        }
        else if(parseInt(popupcat) === 5)
        {
            //setLanguageFilter([...languagefilter, {selectname: "", inputname: ""} ]);
            setSegmentRules([...segmentrules, 5 ]);
        }
        else if(parseInt(popupcat) === 6)
        {
            //setAppVersionFilter([...appversionfilter, {selectname: "", inputname: ""} ]);
            setSegmentRules([...segmentrules, 6 ]);
        }
        else if(parseInt(popupcat) === 7)
        {
            //setLocationFilter([...locationfilter, {selectname: "", inputname: ""} ]);
            setSegmentRules([...segmentrules, 7 ]);
        }
        else if(parseInt(popupcat) === 8)
        {
            //setEmailFilter([...emailfilter, {selectname: "", inputname: ""} ]);
            setSegmentRules([...segmentrules, 8 ]);
        }
        else if(parseInt(popupcat) === 9)
        {
            //setCountryFilter([...countryfilter, {selectname: "", inputname: ""} ]);
            setSegmentRules([...segmentrules, 9 ]);
        }
        else if(parseInt(popupcat) === 10)
        {
            //setTestUsersFilter([...testusersfilter, {selectname: "", inputname: ""} ]);
            setSegmentRules([...segmentrules, 10 ]);
        }

        setCustomBlockPopup(false);



        //let getlastindex = (segmentrules.length -1);
        let totalsegmentlen = document.querySelectorAll(".accordionItemHeading");
        
        let getlastindex = (totalsegmentlen.length -1);

        

        let openrownumarr = [];

        for(let z = 0; z < totalsegmentlen.length; z++)
        {
            
            if(z === getlastindex)
            {
                openrownumarr.push(true);
                
            }
            else
            {
                //setOpenRowNumber(null);
                openrownumarr.push(false);
            }
        }

        setOpenRowNumber(openrownumarr);
    }

    const handleCancelPopupSegment = (popupdata1) =>{


        setCustomBlockPopup(false);

    }

    const handleSelectSegmentCategory = (e, catvalue) =>{
        //event.preventDefault();

        setSegmentCategory(catvalue);
        setOpenRowFirst(0);
        setOpenRowNumber(1);

        if(parseInt(catvalue) === 1)
        {
            //setFirstSessionFilter([...firstsessionfilter, {selectname: "fskey1", inputname: "fsvalue1"} ]);
            setSegmentRules([...segmentrules, 1 ]);
        }
        else if(parseInt(catvalue) === 2)
        {
            //setLastSessionFilter([...lastsessionfilter, {selectname: "lskey1", inputname: "lsvalue1"} ]);
            setSegmentRules([...segmentrules, 2 ]);
        }
        else if(parseInt(catvalue) === 3)
        {
            //setSessionCountFilter([...sessioncountfilter, {selectname: "sckey1", inputname: "scvalue1"} ]);
            setSegmentRules([...segmentrules, 3 ]);
        }
        else if(parseInt(catvalue) === 4)
        {
            //setTotalDurationFilter([...totaldurationfilter, {selectname: "tdkey1", inputname: "tdvalue1"} ]);
            setSegmentRules([...segmentrules, 4 ]);
        }
        else if(parseInt(catvalue) === 5)
        {
            //setLanguageFilter([...languagefilter, {selectname: "lankey1", inputname: "lanvalue1"} ]);
            setSegmentRules([...segmentrules, 5 ]);
        }
        else if(parseInt(catvalue) === 6)
        {
            //setAppVersionFilter([...appversionfilter, {selectname: "avkey1", inputname: "avvalue1"} ]);
            setSegmentRules([...segmentrules, 6 ]);
        }
        else if(parseInt(catvalue) === 7)
        {
            //setLocationFilter([...locationfilter, {selectname: "lockey1", inputname: "locvalue1"} ]);
            setSegmentRules([...segmentrules, 7 ]);
        }
        else if(parseInt(catvalue) === 8)
        {
            //setEmailFilter([...emailfilter, {selectname: "emailkey1", inputname: "emailvalue1"} ]);
            setSegmentRules([...segmentrules, 8 ]);
        }
        else if(parseInt(catvalue) === 9)
        {
            //setCountryFilter([...countryfilter, {selectname: "counkey1", inputname: "counvalue1"} ]);
            setSegmentRules([...segmentrules, 9 ]);
        }
        else if(parseInt(catvalue) === 10)
        {
            //setTestUsersFilter([...testusersfilter, {selectname: "tukey1", inputname: "tuvalue1"} ]);
            setSegmentRules([...segmentrules, 10 ]);
        }

        setIsSegmentCategoryDisplay(false);
        setSegmentCategory("");

        
        let segcount1 = segmentcount;
        segcount1 = segcount1 + 1;
        setSegmentCount(segcount1);
        

        let rownumberarr = [...openrownum];
        let rownumnewarray = [];
        rownumberarr.map((rownumval, rownumkey)=>{
            rownumnewarray.push(false);
        });

        //rownumberarr.push(true);
        rownumnewarray.push(true);

        
        setOpenRowNumber(rownumnewarray);



    }

    const handleFSValueRemove = (e, fsremidx)=>{

        
        
        const getfinalfsdata = [...inputvalue];
        getfinalfsdata.splice(fsremidx, 1);
        setInputValue(getfinalfsdata);

        const getfinalfsvalue = [...inputkey];
        getfinalfsvalue.splice(fsremidx, 1);
        setInputKey(getfinalfsvalue);


        const remfsfilter = [...segmentrules];
        remfsfilter.splice(fsremidx,1);
        //setFirstSessionFilter(remfsfilter);
        setSegmentRules(remfsfilter);


        const getfinalfsdata1 = [...inputvalue1];
        getfinalfsdata1.splice(fsremidx, 1);
        setInputValue1(getfinalfsdata1);


        const getfinalfiltertype = [...filtertype];
        getfinalfiltertype.splice(fsremidx, 1);
        setFilterType(getfinalfiltertype);

        

        const getfinaloperator = [...segoperator];
        getfinaloperator.splice(fsremidx, 1);
        setSegOperator(getfinaloperator);

        
        let sgcount = segmentcount;

        sgcount = sgcount - 1;

        setSegmentCount(sgcount);

        

        if(sgcount === 0)
        {
            setIsSegmentCategoryDisplay(true);
        }

        const getarropenrownum = [...openrownum];
        getarropenrownum.splice(fsremidx, 1);
        setOpenRowNumber(getarropenrownum);
    }

    const redirectToAudience = () =>{
        props.history.push("/app/advanced-notifications/subsets");
    }

    const handleRowOpen = (e, rowindex, currindex) =>{

        let rowarraydata = [];
        for(let y=0; y < openrownum.length; y++)
        {
            
            if(y === currindex)
            {
                rowarraydata.push(!openrownum[y]);
                //rowarraydata.push(true);
            }
            else
            {
                rowarraydata.push(false);
            }
        }

        
        setOpenRowNumber(rowarraydata);

        setOpenRowFirst(false);
        
    }

    const handleRowOpenFirst = (e, rowindex) =>{

    
        if(rowindex === openrowfirst)
        {
            setOpenRowFirst(null);
        }
        else
        {
            setOpenRowFirst(rowindex);
        }


        let rowarraydata = [];
        for(let y = 0; y < openrownum.length; y++)
        {
            rowarraydata.push(false);
        }

        
        setOpenRowNumber(rowarraydata);

    }

    const handleAddSegmentBlock = (blockdata) =>{

        
    }

    const handleCustomBlockPopup = ()=> {

        setCustomBlockPopup(true);
    }

    const handleRightPanelExpand = (value) => {

		setAppRightPanelExpand(value);
	}

return (
<>
    <div>
        <Header customclassName={"header-responsive-new"} />
    </div>

    <section className="new-audience-login">
        <Leftpanel  handleRightPanelExpand={handleRightPanelExpand} />
    
        {/* Create Segement starts here */}
        <div
            className={`right-panel-gap ${
              localStorage.getItem("appSideBarCollapse") !== undefined &&
              localStorage.getItem("appSideBarCollapse") !== null &&
              localStorage.getItem("appSideBarCollapse") === "true"
                ? " right-panel-gap-expand"
                : ""
            }`}
            >
            {/* <div className="d-flex justify-content-between"> */}
                <div className="audience-heading d-flex web-notification-head">
                    <h1 className="mob-create-segment">{`${editsegmentid?"Edit":"Create"} group`}
                        <p className=" consent-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                        <circle cx="9" cy="9" r="9" stroke="none" />
                                        <circle cx="9" cy="9" r="8.25" fill="none" />
                                    </g>
                                    <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5" />
                                    <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                                </g>
                            </svg>
                            <p className="consent-tooltipsubheader">
                            Easily define and manage segments based on user behavior and attributes.{" "}
                            </p>
                        </p>
                    </h1>
                    <div
                 style={{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                }}
                >
           {appPlan !== "premium" && skipButtonActive && <PremiumTag/> }

                <div 
                style={{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    marginLeft: "20px",
                }}>

                <Link to={"/app/advanced-notifications/subsets"}>
                    <div className="notification-setting " >
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>
    

                        </div>
                        </Link>
                </div>

                <div>
                    { segmentrules.length > 0 ?
                        <button className="audience-createsegment" onClick={ btnCreateSegmentClick }>
                            <div className="d-flex align-items-center justify-content-center">
                                <div className={btnloader}>
                                    {/* <img src={this.state.loaderimage} alt="loader" /> */}
                                    <div className="new-loader"></div>
                                </div>{" "}
                                <div>{btnsubmitlabel}</div>
                            </div>
                            {/*<p>Create</p>*/}
                        </button>
                    :
                    <button className="audience-createsegment btndisable">                            
                            <p>{editsegmentid?"Save":"Create"}</p>
                        </button>
                    }
                </div>
            </div>
                </div>
              
                
            {/* </div> */}

            <div className="create-segment-section">
                
                <div className="audience-whitebg create-segment">
                    { spinner ?
                        <NewSpinner />
                    :
                        <>
                        <div className="col-md-12 col-xl-6">

                            {/*<div className="pushnotification-title">                                
                                <div className="d-flex align-items-center justify-content-between pushnotification-titlefirstdiv" onClick={ (e) => handleRowOpen(e, 1) }>
                                    
                                    <p >Message</p>

                                    { openrownum === 1 ?
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.061" height="7.592" viewBox="0 0 13.061 7.592">
                                            <path id="Path_81302" data-name="Path 81302" d="M-12935-18908l6-6,6,6" transform="translate(12935.531 18915.064)" fill="none" stroke="#7782a1" stroke-width="1.5" />
                                        </svg>
                                    :
                            
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.061" height="7.592" viewBox="0 0 13.061 7.592">
                                            <path id="Path_81302" data-name="Path 81302" d="M-12935-18914l6,6,6-6" transform="translate(12935.531 18914.535)" fill="none" stroke="#7782a1" stroke-width="1.5" />
                                        </svg>
                                    }
                                </div>
                                                        
                                { openrownum === 1 && 
                                <div className="form-group">
                                    <input type="text" placeholder="Enter name" maxLength={100} />
                                </div>
                                }

                            </div>*/}

                            <div className="accordionItem">
                                <div className="arrow-img" onClick={ (e) => handleRowOpenFirst(e, 1) }>
                                
                                    <h2 className="accordionItemHeading allflex">Segment name</h2>
                                                                
                                    { errortitle ?  
                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.061" height="7.592" viewBox="0 0 13.061 7.592" >
                                            <path id="Path_81302" data-name="Path 81302" d="M-12935-18914l6,6,6-6" transform="translate(12935.531 18914.535)" fill="none" stroke="#7782a1" stroke-width="1.5" />
                                        </svg>
                                    :
                                        ( openrowfirst === 1 ?
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.061" height="7.592" viewBox="0 0 13.061 7.592" >
                                                <path id="Path_81302" data-name="Path 81302" d="M-12935-18908l6-6,6,6" transform="translate(12935.531 18915.064)" fill="none" stroke="#7782a1" stroke-width="1.5" />
                                            </svg>
                                        :
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.061" height="7.592" viewBox="0 0 13.061 7.592" >
                                                <path id="Path_81302" data-name="Path 81302" d="M-12935-18914l6,6,6-6" transform="translate(12935.531 18914.535)" fill="none" stroke="#7782a1" stroke-width="1.5" />
                                            </svg>
                                        )
                                    }
                                </div>

                                { openrowfirst === 1 && 
                                <div className="accordionItemContent">
                                    <input type="text" placeholder="Enter group name here" maxLength={100} name="segmentname" onChange={handleSegmentName} value={segmentname} className={ errortitle ? "segmet-error-input" : "" } />
                                </div>
                                }
                                
                            </div>

                            { !issegmentcategorydisplay &&
                                <>
                                    
                                    { segmentrules.map((type, index)=>(    
                                        
                                        ((type === 1) ?
                                            <FirstSessionSegment key={index} keydata={index} onRemoveFS={handleFSValueRemove} fsKey={inputkey} setFSKey={setInputKey} fsValue={inputvalue} setFSValue={setInputValue} fsFilterType={filtertype} setFSFilterType={setFilterType} fsoperators={segoperator} setFSOperators={setSegOperator} fsRowOpen={handleRowOpen} fsopenrownumber={openrownum} selectruleserror={errfsrules} selectvalueerror={errfsvalue} duplicateFSSegment={handleSelectSegmentCategory} />
                                        :
                                            ((type === 2) ?
                                                <LastSessionSegment key={index} keydata={index} onRemoveLS={handleFSValueRemove} lsKey={inputkey} setLSKey={setInputKey} lsValue={inputvalue} setLSValue={setInputValue} lsFilterType={filtertype} setLSFilterType={setFilterType} lsoperators={segoperator} setLSOperators={setSegOperator} lsRowOpen={handleRowOpen} lsopenrownumber={openrownum} selectruleserror={errlsrules} selectvalueerror={errlsvalue} duplicateLSSegment={handleSelectSegmentCategory} />
                                            :
                                                ((type === 3) ?
                                                    <SessionCountSegment key={index} keydata={index} onRemoveSC={handleFSValueRemove} scKey={inputkey} setSCKey={setInputKey} scValue={inputvalue} setSCValue={setInputValue} scFilterType={filtertype} setSCFilterType={setFilterType} scoperators={segoperator} setSCOperators={setSegOperator} scRowOpen={handleRowOpen} scopenrownumber={openrownum} selectruleserror={errscrules} selectvalueerror={errscvalue} duplicateSCSegment={handleSelectSegmentCategory} />
                                                :
                                                    ((type === 4) ?
                                                        <TotalDurationSegment key={index} keydata={index} onRemoveTD={handleFSValueRemove} tdKey={inputkey} setTDKey={setInputKey} tdValue={inputvalue} setTDValue={setInputValue} tdFilterType={filtertype} setTDFilterType={setFilterType} tdoperators={segoperator} setTDOperators={setSegOperator} tdRowOpen={handleRowOpen} tdopenrownumber={openrownum} selectruleserror={errtdrules} selectvalueerror={errtdvalue} duplicateTDSegment={handleSelectSegmentCategory} />
                                                    :
                                                        ((type === 5) ?
                                                            <LanguageSegment key={index} keydata={index} onRemoveLan={handleFSValueRemove} lanKey={inputkey} setLanKey={setInputKey} lanValue={inputvalue} setLanValue={setInputValue} lanFilterType={filtertype} setLanFilterType={setFilterType} lanoperators={segoperator} setLanOperators={setSegOperator} lanRowOpen={handleRowOpen} lanopenrownumber={openrownum} selectruleserror={errlanrules} selectvalueerror={errlanvalue} duplicateLanSegment={handleSelectSegmentCategory} />
                                                        :
                                                            ((type === 6) ?
                                                                <AppVersionSegment key={index} keydata={index} onRemoveAV={handleFSValueRemove} avKey={inputkey} setAVKey={setInputKey} avValue={inputvalue} setAVValue={setInputValue} avFilterType={filtertype} setAVFilterType={setFilterType} avoperators={segoperator} setAVOperators={setSegOperator} avRowOpen={handleRowOpen} avopenrownumber={openrownum} selectruleserror={erravrules} selectvalueerror={erravvalue} duplicateAVSegment={handleSelectSegmentCategory} />
                                                            :
                                                                ((type === 7) ?
                                                                    <LocationSegment key={index} keydata={index} onRemoveLoc={handleFSValueRemove} locRadius={inputkey} setLocRadius={setInputKey} locLatitude={inputvalue} setLocLatitude={setInputValue} locFilterType={filtertype} setLocFilterType={setFilterType} locLogitude={inputvalue1} setLocLongitude={setInputValue1} locoperators={segoperator} setLocOperators={setSegOperator} locRowOpen={handleRowOpen} locopenrownumber={openrownum} selectruleserror={errlocrules} selectvalueerror={errlocvalue} selectvalueerror1={errlocvalue1} duplicateLocSegment={handleSelectSegmentCategory} />
                                                                :
                                                                    ((type === 8) ?
                                                                        <EmailSegment key={index} keydata={index} onRemoveEmail={handleFSValueRemove} emailKey={inputkey} setEmailKey={setInputKey} emailValue={inputvalue} setEmailValue={setInputValue} emailFilterType={filtertype} setEmailFilterType={setFilterType} emailoperators={segoperator} setEmailOperators={setSegOperator} emailRowOpen={handleRowOpen} emailopenrownumber={openrownum} selectruleserror={erremailrules} duplicateEmailSegment={handleSelectSegmentCategory} />
                                                                    :
                                                                        ((type === 9) ?
                                                                            <CountrySegment key={index} keydata={index} onRemoveCountry={handleFSValueRemove} countryKey={inputkey} setCountryKey={setInputKey} countryValue={inputvalue} setCountryValue={setInputValue} countryFilterType={filtertype} setCountryFilterType={setFilterType} countryoperators={segoperator} setCountryOperators={setSegOperator} countryRowOpen={handleRowOpen} countryopenrownumber={openrownum} selectruleserror={errconrules} selectvalueerror={errconvalue} duplicateCountrySegment={handleSelectSegmentCategory} />
                                                                        :
                                                                            ((type === 10) ?
                                                                                {/*<TestUsersSegment key={index9} keydata={index9} onRemoveTU={handleFSValueRemove} tuKey={inputkey} setTUKey={setInputKey} tuValue={inputvalue} setTUValue={setInputValue} tuFilterType={filtertype} setTUFilterType={setFilterType} />*/}
                                                                            :
                                                                                null
                                                                            )
                                                                        )
                                                                    )
                                                                )
                                                            )
                                                        )
                                                    )
                                                )
                                            )
                                        )

                                    ))}
                                    
                                </>
                            }
                            
                            <SegmentCategory onSelectFilter={handleSelectSegmentCategory} isdisplay={issegmentcategorydisplay} selectedCategory={segmentcategory} />

                            {/*<div className="accordionItem">
                                <div className="heading-wrapper accordionItemHeading allflex">
                                    <svg width="20" height="20" viewBox="0 0 20 20">
                                        <defs>
                                            <clipPath id="a">
                                                <rect width="20" height="20" transform="translate(0.307)" fill="#7297ff" />
                                            </clipPath>
                                        </defs>
                                        <g transform="translate(-0.307)" clip-path="url(#a)">
                                            <path d="M5.485,16.153l1.063-1.375A6.812,6.812,0,0,0,17.572,8.771a6.706,6.706,0,0,0-7.407-6A6.851,6.851,0,0,0,4.133,8.69L6,6.746l1.242,1.3L3.62,11.817,0,8.042l1.242-1.3,1.243,1.3A8.608,8.608,0,0,1,9.992,1.049a8.411,8.411,0,0,1,9.3,7.533A8.534,8.534,0,0,1,5.485,16.153ZM10,9.972V5.232h1.758V9.213L14.9,12.487l-1.243,1.3Z" transform="translate(0.306)" fill="#7297ff" />
                                        </g>
                                    </svg>
                                    <h2 className="heading">First visit</h2>
                                </div>
                                <div className="arrow-img">
                                    
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.061" height="7.592" viewBox="0 0 13.061 7.592">
                                        <path id="Path_81302" data-name="Path 81302" d="M-12935-18908l6-6,6,6" transform="translate(12935.531 18915.064)" fill="none" stroke="#7782a1" stroke-width="1.5" />
                                    </svg>
                                    
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.061" height="7.592" viewBox="0 0 13.061 7.592">
                                        <path id="Path_81302" data-name="Path 81302" d="M-12935-18914l6,6,6-6" transform="translate(12935.531 18914.535)" fill="none" stroke="#7782a1" stroke-width="1.5" />
                                    </svg>
                                </div>
                                <div className="accordian-text-wrapper">
                                    <div className="accordion-text">
                                        <div className="select-wrapper">
                                            <Select classNamePrefix="form-select" placeholder="greater than" value="Default Value" label="Default Label"></Select>
                                                <div className="arrow-icon"></div>
                                        </div>
                                        <div className="category-parent-dropdown">
                                            <input type="text" placeholder="168" class="total-hours-count" />
                                            <span>hours ago</span>
                                        </div>
                                    </div>
                                    <div className="delete-and-copy">
                                        <div className="copy">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="15.001" height="15" viewBox="0 0 15.001 15" >
                                                <g id="Group_26180" data-name="Group 26180" transform="translate(-450.5 -124.5)" >
                                                    <g id="Path_9276" data-name="Path 9276" transform="translate(0 0)" fill="none" stroke-miterlimit="10" >
                                                        <path d="M462.5,125.923V128.5h-6.72a1.189,1.189,0,0,0-1.28,1.28v6.72h-2.577a1.419,1.419,0,0,1-1.423-1.423v-9.154a1.419,1.419,0,0,1,1.423-1.423h9.154A1.419,1.419,0,0,1,462.5,125.923Z" stroke="none" />
                                                        <path d="M 451.9228515625 125.5000152587891 C 451.6857299804688 125.5000152587891 451.5 125.6857452392578 451.5 125.9228668212891 L 451.5 135.0771636962891 C 451.5 135.3142700195312 451.6857299804688 135.5 451.9228515625 135.5 L 453.5 135.5 L 453.5 129.7800140380859 C 453.5 128.4375762939453 454.4375610351562 127.5 455.7799682617188 127.5 L 461.5 127.5 L 461.5 125.9228668212891 C 461.5 125.6857452392578 461.3142395019531 125.5000152587891 461.0771179199219 125.5000152587891 L 451.9228515625 125.5000152587891 M 451.9228515625 124.5000152587891 L 461.0771179199219 124.5000152587891 C 461.8656616210938 124.5000152587891 462.5 125.1343078613281 462.5 125.9228668212891 L 462.5 128.5 L 455.7799682617188 128.5 C 454.9913940429688 128.5 454.5 128.991455078125 454.5 129.7800140380859 L 454.5 136.5 L 451.9228515625 136.5 C 451.13427734375 136.5 450.5 135.8657531738281 450.5 135.0771636962891 L 450.5 125.9228668212891 C 450.5 125.1343078613281 451.13427734375 124.5000152587891 451.9228515625 124.5000152587891 Z" stroke="none" fill="#7782a1" />
                                                    </g>
                                                    <g id="Path_9277" data-name="Path 9277" transform="translate(-1.999 -2)" fill="none" stroke-miterlimit="10" >
                                                        <path d="M467.5,130.923v9.154a1.419,1.419,0,0,1-1.423,1.423h-9.154a1.419,1.419,0,0,1-1.423-1.423v-9.154a1.419,1.419,0,0,1,1.423-1.423h9.154A1.419,1.419,0,0,1,467.5,130.923Z" stroke="none" />
                                                        <path d="M 456.9228210449219 130.5000152587891 C 456.6856994628906 130.5000152587891 456.4999694824219 130.6857452392578 456.4999694824219 130.9228515625 L 456.4999694824219 140.0771484375 C 456.4999694824219 140.3142700195312 456.6856994628906 140.5000152587891 456.9228210449219 140.5000152587891 L 466.0771179199219 140.5000152587891 C 466.3142395019531 140.5000152587891 466.4999694824219 140.3142700195312 466.4999694824219 140.0771484375 L 466.4999694824219 130.9228515625 C 466.4999694824219 130.6857452392578 466.3142395019531 130.5000152587891 466.0771179199219 130.5000152587891 L 456.9228210449219 130.5000152587891 M 456.9228210449219 129.5000152587891 L 466.0771179199219 129.5000152587891 C 466.8656921386719 129.5000152587891 467.4999694824219 130.1342926025391 467.4999694824219 130.9228515625 L 467.4999694824219 140.0771484375 C 467.4999694824219 140.86572265625 466.8656921386719 141.5000152587891 466.0771179199219 141.5000152587891 L 456.9228210449219 141.5000152587891 C 456.1342163085938 141.5000152587891 455.4999694824219 140.86572265625 455.4999694824219 140.0771484375 L 455.4999694824219 130.9228515625 C 455.4999694824219 130.1342926025391 456.1342163085938 129.5000152587891 456.9228210449219 129.5000152587891 Z" stroke="none" fill="#7782a1" />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                        <div className="delete">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999" >
                                                <g id="Group_5465" data-name="Group 5465" transform="translate(-835.516 -682.992)">
                                                    <g id="Rectangle_2307" data-name="Rectangle 2307" transform="translate(836.515 684.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1">
                                                        <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none"/>
                                                        <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none"/>
                                                    </g>
                                                    <g id="Rectangle_2308" data-name="Rectangle 2308" transform="translate(839.515 682.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" >
                                                        <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none" />
                                                        <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none" />
                                                    </g>
                                                    <g id="Group_3245" data-name="Group 3245" transform="translate(841.016 687.993)" >
                                                        <line id="Line_110" data-name="Line 110" y2="6" transform="translate(3 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                                                        <line id="Line_111" data-name="Line 111" y1="6" transform="translate(0 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                                                    </g>
                                                    <line id="Line_112" data-name="Line 112" x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>*/}

                            { !issegmentcategorydisplay &&

                                
                                    <a data-bs-toggle="modal" data-bs-target="#edit-modal" className="add-filters-txt" type="button" onClick={handleCustomBlockPopup} style={{width:'100%'}}>
                                      <div className="add-filters">
                                        <span>
                                            <svg id="Group_3055" data-name="Group 3055" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" >
                                                <g id="Group_1664" data-name="Group 1664" transform="translate(0 6.001)" >
                                                    <line id="Line_85" data-name="Line 85" y2="12" transform="translate(12) rotate(90)" fill="none" stroke="#3064f9" stroke-width="1.5" />
                                                </g>
                                                <g id="Group_1665" data-name="Group 1665" transform="translate(5.959) rotate(90)" >
                                                    <line id="Line_85-2" data-name="Line 85" y2="12" transform="translate(12) rotate(90)" fill="none" stroke="#3064f9" stroke-width="1.5" />
                                                </g>
                                            </svg>
                                        </span>
                                        Add Filter
                                        </div>
                                    </a>
                                
                            }                            

                            <SegmentPopup popupopen={customblockpopup} onChange={handleAddSegmentBlock}  btnPopupClickDone={handleAddSegmentFromPopup} btnPopupClickCancel={handleCancelPopupSegment}   />
                            
                        </div>
                        <div>
                            <div className="mobile-create mobnewdraft_fotter">
                                { segmentrules.length > 0 ?
                                    <button className="create-notification" onClick={ btnCreateSegmentClick }>
                                        <div className={btnloader}>                                            
                                            <div className="new-loader"></div>
                                        </div>{" "}
                                        <div>{btnsubmitlabel}</div>                                        
                                    </button>
                                :
                                    <button className="savedraft-buttondisabled" style={{border:"", color:"", "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : "", "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : ""}}>
                                        <div className="" > {editsegmentid?"Save":"Create"} </div>
                                    </button>
                                }
                            </div>
                        </div>
                        </>
                    }
                </div>
                
            </div>
        </div>        
        {/* Create Segement ends here */}
    </section>
</>
);
}

export default SegmentCreate;