import React from 'react'; 
import { Modal, ModalHeader, ModalBody} from 'reactstrap';
import { Link } from "react-router-dom"; 

export default class Verifypopup extends React.Component{
    
    
    render(){
        return(
            // <Modal isOpen={this.props.value.state.verifypopmodal}   toggle={this.props.value.handleverifymodal} style={{maxWidth:"550px",maxHeight:"247px"}}  centered dataclass="useradd">
            //     <ModalHeader toggle={this.props.value.state.verifypopmodal} className="modal-xxl-content modal-categories" >
			// 		<div className="crossmodal mt-1" onClick={this.props.value.handleverifymodal}>
			// 		<svg  width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
			// 		<path id="Union_18" data-name="Union 18" d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z" transform="translate(0.529 0.531)" fill="#1b2952" stroke="#1b2952" strokeWidth="1.5"/>
			// 		</svg>
			// 		</div>
			// 	</ModalHeader>
            //     <ModalBody className="update-woo-plugin">
			// 			<div className="text-center mb-4">
			// 				<img src={require("../../assets/images/dashboard/verifydomain.png").default} alt="Connecting" className="img-fluid"/>
			// 			</div>                        
			// 			<div className="text-center mb-4">
			// 				<h2 className="mb-3" style={{width:"80%",margin:"auto"}}>Domain verification required</h2>
            //                 <p>It seems your domain has changed. In order to ensure connectivity the new URL needs to be verified. Your app may not function as expected until verified.  </p>
			// 			</div>
			// 			<div className="text-center mb-3 conecttoapi">
			// 				<Link to={this.props.value.state.verifyhiturl}>Verify Now</Link>
			// 			</div>
			// 	</ModalBody>
            // </Modal>
			<Modal
			isOpen={this.props.value.state.verifypopmodal}   
			toggle={this.props.value.handleverifymodal}
			style={{ maxWidth: "460px", maxHeight: "240px" }}
			className="generalstngs2"
			centered
		  >
			<ModalHeader>
			  <div className="crossmodal mt-1" onClick={this.props.value.handleverifymodal}>
				<img src={require("../../assets/images/dashboard/cross.png").default} />
			  </div>
			</ModalHeader>
			
			<ModalBody>
			  <div>
				<h4>Domain verification required</h4>
				<h6>Your app is now linked to a new web address. We strongly recommend conducting thorough testing of your app, including connectivity tests to ensure seamless web-app connectivity.</h6>
				
				<div className="generalstngs2-footer">

				
				  <div className="generalstngs2-ok domain-vrfy" onClick={this.props.value.handleverifymodal}>
				  <p>Okay</p>
				  </div>
				</div>
			  </div>
			</ModalBody>
  
		  </Modal>
                   
        )
    }
}

