import axios from "axios";
import { errortoast } from "../../Dashboard/Toaster";
import { changePaymentStripe } from "../Dashboard/DashboardApi";
const Url = process.env.REACT_APP_API_URL + "api/user/";
//Addcard

export const Addcard = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "link-payment-method", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        window.location.href = res.data.url;

        state.setState({
          addcrdspinner: false,
        });
      } else {
        state.setState({
          addcrdspinner: false,
        });
      }
    });
};
export const Listcard = (state,appOrWorkspaceID) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      Url +
        "list-payment-methods?workspace_id=" +
        btoa(localStorage.getItem("workspaceId")),
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        if( res.data.data.length !== 0 && res.data.data !== null &&appOrWorkspaceID ){
          let param;
          if (
            localStorage.getItem("workspaceId") === "" ||
            localStorage.getItem("workspaceId") === undefined ||
            localStorage.getItem("workspaceId") === null ||  localStorage.getItem("workspaceId") === "null"
          ) {
            param = {
              app_id: appOrWorkspaceID,
              payment_method_id: res.data.data[0].id,
            };
          } else {
            param = {
              workspace_id: appOrWorkspaceID,
              payment_method_id:  res.data.data[0].id,
            };
          }
      
          changePaymentStripe(param, state);
        }
        state.setState({
          customer_billing_type: res.data.customer_billing_type,
          data:
            res.data.data.length === 0 || res.data.data === null
              ? null
              : res.data.data,
          spinner: false,
          removespinner: {},
          customer_type: res.data.customer_type,
          cardSpinner: false,
        });
      }
    })
    .catch((err) => {
      state.setState({
        cardSpinner: false,
      });
      errortoast("Something went wrong");
    });
};
export const Unlink = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "unlink-payment-method", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          spinner:true,
          delete_btn_spinner:"d-none",
          delete_btn_text:"Delete",
          is_delete_popup:false,
        });
        setTimeout(() => {
          Listcard(state);
        }, 2000);
      } else {
        errortoast("Something wrong")
        state.setState({
          removespinner: {},
          delete_btn_spinner:"d-none",
          delete_btn_text:"Delete",
          is_delete_popup:false,
        });
      }
    }).catch((err)=>{
      
      errortoast("Something went wrong")
      state.setState({
        removespinner: {},
        delete_btn_spinner:"d-none",
        delete_btn_text:"Delete",
        is_delete_popup:false,
      });
    });
};

export const Unlink_Zoho = (cardId, state) => {
  const params = { card_id: cardId };
  
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "delete-card", params, {
      headers: headers,
      data: {},
    })
    .then((res) => {
      if (res.data.code === 200) {
        setTimeout(() => {
          state.setState({
            spinner:true,
            delete_btn_spinner:"d-none",
            delete_btn_text:"Delete",
            is_delete_popup:false,
          });
          Listcard(state);
        }, 2000);
      } else {
        errortoast("Something went wrong")
        state.setState({
          removespinner: {},
          
          is_delete_popup:false,
            delete_btn_spinner:"d-none",
            delete_btn_text:"Delete",
         
        });
      }
    }).catch((err)=>{
      errortoast("Something wrong")
      state.setState({
        removespinner: {},
        
        is_delete_popup:false,
          delete_btn_spinner:"d-none",
          delete_btn_text:"Delete",
       
      });
      
    });
};
