import React, { Suspense, useEffect, useMemo, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Button,
  Row,
  Col,
} from "reactstrap";
import { cmsGetPagesApi } from "../../Api/CmsApi/Cmsapi";
import "./../../assets/css/Dashboard.css";
import "./cmsBuilderstyle.css";

export const parentPage = (
  isParentpopup,
  handleParentPop,
  allPage,
  handleParentChange,
  parentId,
  pageID,
  type,
  isHideNoneBtn
) => {
  const handleDash = (level) => {
    switch (level) {
      case 0:
        return <span></span>;
      case 1:
        return <span> - </span>;
      case 2:
        return <span> - - </span>;
      case 3:
        return <span> - - - </span>;
      case 4:
        return <span> - - - - </span>;
      case 5:
        return <span> - - - - - </span>;
      case 6:
        return <span> - - - - - - </span>;
      case 1:
        return <span> - - - - - - - </span>;
      case 7:
        return <span> - - - - - - - - </span>;
      case 8:
        return <span> - - - - - - - - - </span>;
      case 9:
        return <span> - - - - - - - - - - </span>;
    }
  };

  const childParentList = (newArr) => {
    return newArr.map((eachPage, indx) => {
      return (
        ((eachPage.level < 10 && pageID !== eachPage._id) ||
          eachPage.level === undefined) &&
        eachPage.deleted_at === null && (
          <li class="parent-radio-btn page-list" key={eachPage._id}>
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefaultD"
              id={`flexRadioDefault${indx}`}
              checked={eachPage._id === parentId}
              onChange={(e) =>
                handleParentChange(
                  e,
                  eachPage._id,
                  eachPage.name,
                  eachPage.title
                )
              }
            />
            <label
              class="form-check-label form-menu-label"
              for={`flexRadioDefault${indx}`}
            >
              <div class="card-num menu-name">
                <p>
                  {handleDash(eachPage.level)}
                  {type === "Category" || type === "Tags"
                    ? eachPage.name
                    : eachPage.title}
                </p>
              </div>
            </label>
          </li>
        )
      );
    });
  };

  let newArr = [];

  function recurs(arr, check) {
    return arr.map((eachPage, indx) => {
      newArr.push(eachPage);

      if (eachPage.child !== undefined && eachPage.child.length > 0) {
        // if parent has childrens
        recurs(eachPage.child, "child");
      }

      return (
        check === "parent" &&
        indx === allPage.length - 1 &&
        childParentList(newArr)
      );
    });
  }

  return (
    <div className="">
      <Modal
        isOpen={isParentpopup}
        toggle={(e) => handleParentPop(e, "cancel")}
        className="selectcategorypopup modal-dialog-centered"
      >
        <ModalHeader toggle={(e) => handleParentPop(e, "cancel")}>
          <h2>Select {type}</h2>
        </ModalHeader>

        <ModalBody toggle={(e) => handleParentPop(e, "cancel")}>
          <div className="select-parent-page ">
            {allPage.length > 0 && allPage !== undefined && allPage ? (
              <ul>
                {isHideNoneBtn !== true && (
                  <li class="parent-radio-btn page-list">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="flexRadioDefaultNone"
                      id="flexRadioDefaultNone"
                      checked={
                        parentId === 0 || parentId === "" || parentId === null
                      }
                      onChange={(e) => handleParentChange(e, 0, "", "")}
                    />
                    <label
                      class="form-check-label form-menu-label"
                      for="flexRadioDefaultNone"
                    >
                      <div class="card-num menu-name">
                        <p>None</p>
                      </div>
                    </label>
                  </li>
                )}
                {recurs(allPage, "parent")}
              </ul>
            ) : (
              <div className="d-flex align-items-center justify-content-center no-page ">
                <div>
                  <img
                    className="img-fluid"
                    src={
                      require("../../assets/images/dashboard/no-page.png")
                        .default
                    }
                  />
                  <p>No {type} to select yet</p>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={(e) => handleParentPop(e, "cancel")}>CANCEL</Button>
          <Button onClick={(e) => handleParentPop(e, "add")}>DONE</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export const tagsAndCategory = (
  isParentpopup,
  handleParentPop,
  data,
  handleParentChange,
  parentId,
  pageID,
  type,
  isDisable
) => {
  return (
    <div className="">
      <Modal
        isOpen={isParentpopup}
        toggle={(e) => handleParentPop(e, "cancel")}
        className="selectcategorypopup modal-dialog-centered"
      >
        <ModalHeader toggle={(e) => handleParentPop(e, "cancel")}>
          <h2>Select {type}</h2>
        </ModalHeader>

        <ModalBody toggle={(e) => handleParentPop(e, "cancel")}>
          <div className="select-parent-page ">
            {data.length > 0 && data !== undefined && data ? (
              <ul>
                {data &&
                  data.map((row, indx) => {
                    return (
                      <li class="parent-radio-btn page-list" key={indx}>
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefaultD"
                          id={`flexRadioDefault${indx}`}
                          // checked={row._id === parentId}
                          onChange={(e) => handleParentChange(e, row)}
                        />
                        <label
                          class="form-check-label form-menu-label"
                          for={`flexRadioDefault${indx}`}
                        >
                          <div class="card-num menu-name">
                            <p>{row.value}</p>
                          </div>
                        </label>
                      </li>
                    );
                  })}
              </ul>
            ) : (
              <div className="d-flex align-items-center justify-content-center no-page ">
                <div>
                  <img
                    className="img-fluid"
                    src={
                      require("../../assets/images/dashboard/no-page.png")
                        .default
                    }
                  />
                  <p>No {type} to select yet</p>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={(e) => handleParentPop(e, "cancel")}>CANCEL</Button>
          <Button
            disabled={
              data.length === 0 || data === undefined || isDisable === true
            }
            onClick={(e) => handleParentPop(e, "add")}
          >
            DONE
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export const pageAndPostDataPopUp = (
  isShowPopUp,
  handlePopUp,
  data,
  handleValueChange,
  value,
  type,
  isDisable
) => {
  return (
    <div className="">
      <Modal
        isOpen={isShowPopUp}
        toggle={(e) => handlePopUp(e, "cancel")}
        className="selectcategorypopup modal-dialog-centered"
      >
        <ModalHeader toggle={(e) => handlePopUp(e, "cancel")}>
          <h2>Select {type}</h2>
        </ModalHeader>

        <ModalBody toggle={(e) => handlePopUp(e, "cancel")}>
          <div className="select-parent-page ">
            {data.length > 0 && data !== undefined && data ? (
              <ul>
                {data &&
                  data.map((row, indx) => {
                    return (
                      <li class="parent-radio-btn page-list" key={indx}>
                        <input
                          class="form-check-input"
                          type="radio"
                          name="flexRadioDefaultD"
                          id={`flexRadioDefault${indx}`}
                          checked={
                            parseInt(
                              localStorage.getItem("website_technology")
                            ) === 3
                              ? value === row._id
                              : value === row.id.toString()
                          }
                          onChange={(e) => handleValueChange(e, row, type)}
                        />
                        <label
                          class="form-check-label form-menu-label"
                          for={`flexRadioDefault${indx}`}
                        >
                          <div class="card-num menu-name">
                            <p>
                              {parseInt(
                                localStorage.getItem("website_technology")
                              ) === 3
                                ? row.title
                                : row.title.rendered}
                            </p>
                          </div>
                        </label>
                      </li>
                    );
                  })}
              </ul>
            ) : (
              <div className="d-flex align-items-center justify-content-center no-page ">
                <div>
                  <img
                    className="img-fluid"
                    src={
                      require("../../assets/images/dashboard/no-page.png")
                        .default
                    }
                  />
                  <p>No {type} to select yet</p>
                </div>
              </div>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={(e) => handlePopUp(e, "cancel")}>CANCEL</Button>
          <Button
            disabled={data.length === 0 || data === undefined}
            onClick={(e) => handlePopUp(e, "add")}
          >
            DONE
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
