import React from "react";
import Leftpanel from "../Leftpanel";
import Header from "../Header";
import { Link } from "react-router-dom";
import Reactcolorpicker from "./NewReactcolorpicker";
import Tooltip from "../Tooltip";
import { MobileApppreview } from "../Apppreview";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
  Media,
} from "reactstrap";
import {
  Pixalbay,
  Pixalbaysearch,
  Pixalbayscroll,
  DashboardApi,
  Dashboardpost,
} from "../../Api/Dashboard/DashboardApi";
import classnames from "classnames";
import Imagecrop from "../../Dashboard/Imagecrop";
import { errortoast } from "../Toaster";
import BottomScrollListener from "react-bottom-scroll-listener";
import { RemoveApppreview } from "./RemoveApppreview";
import Dragula from "react-dragula";
import RebuildRequired from "./RebuildRequired";

import {
  salesvg,
  newsvg,
  featuredsvg,
  recentsvg,
  incartsvg,
  catemodalsecond,
  blogbannersvg,
  catemodalfirst,
  pagelistsvg,
  blogcategorysvg,
  recentblogsvg,
  popularblogsvg,
} from "../Svg";
import { InnerFoot } from "../../Dashboard/Foot";
import Amsvideo from "../Reusable/Amsvideos";
import { Solver, Color } from "../BottomBar/Iconcolor";
const Topsection = (props) => {
  return (
    <div className="d-md-flex d-none align-items-start justify-content-between mb-3 ipad-margin-top top-portion ipad-portion-appearance">
      <div className="dasboard-page-title">
        <div className="app_iconname">
          <div className="d-flex align-items-center">
            <h3>Home Screen</h3>
            {/* <Amsvideo
              timespan={88}
              addclass="d-md-flex d-none align-items-center justify-content-between see_video"
            /> */}
          </div>
          <h5>
            Select &amp; design sections for your app home screen.{" "}
            {/* <Media
              href="https://www.appmysite.com/support/category/appearance/designing-your-apps-home-screen/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Learn More{" "}
              <img
                src={require("../../assets/images/dashboard/Goto.png").default}
                className="gotoappicon appear-spacing"
                alt="Goto"
              />
            </Media> */}
            {" "}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default class Dashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.mounted = true;
    this.state = {
      web_view_modal: false,
      presetColors: null,
      android_build_version:null,
      android_supported_version:null,
      ios_build_version:null,
      ios_supported_version:null,
      rebuildpopvalue:false,
      spinner: true,
      pixelspinner: false,
      bottommenuColor: "",
      bottommenuTexticoncolor: "",
      bottommenuBordercolor: "",
      primaryColor: "",
      secondaryColor: "",
      background: "",
      appName: "",
      appFontFamily: "",
      popsubmitval: "Save & proceed",
      poploader: "d-none",
      poploaderimage: require("../../assets/images/signup/Loader_GIF.gif")
        .default,
      submitval: "Save",
      loader: "d-none",
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      fontfamilydashboard: "Montserrat-Regular",
      //Main Banner
      show: false,
      mobileref: true,
      activestate: true,
      categoryname: true,
      //Modal State
      modal: false,
      selectpixabayimage: require("../../assets/images/dashboard/small-up.png")
        .default,
      pixalbayimage: [],
      pixalimagenotavailable: false,
      pixalsearchvalue: "",
      pageval: 1,
      uploadcustomdefaultimage:
        require("../../assets/images/dashboard/storage.png").default,
      uploadcustomimage: false,
      uploadcustomdefaultimageApi: null,
      original_banner: "",
      submitbuttontext: "Done",
      //Background Color State
      splashBackground: "#F8FAFE",
      initialSolidColor: "#F8FAFE",
      //BannerText
      bannertext: "",
      bannertextfontfamily: "",
      initialbannertextColor: "#000",
      bannerfontSize: "",
      bannertextColor: "",
      bannertextAlign: "",
      bannerCheckbox: undefined,
      //Category State
      Catshow: false,
      activeTab: "1",
      cattextbackgroundcolor: "#000",
      initialcattextbackgroundcolor: "#000",
      cattextcolor: "#7782A1",
      initialcattextcolor: "#7782A1",
      catbackgroundtextcolor: "#000000",
      initialcatbackgroundtextcolor: "#000000",
      catbackgroundcolor: "#F8D31E",
      initialcatbackgroundcolor: "#F8D31E",
      categoriesCheckbox: undefined,
      imagename: "",

      //Section State
      secshow: false,
      webviewshow: false,
      sectionBackgroundcolor: "#000",
      initialsectionBackgroundcolor: "#000",
      sectionHeadingcolor: "#000",
      initialsectionHeadingcolor: "#000",
      sectionbuttoncolor: "#fff",
      initialsectionbuttoncolor: "#fff",
      sectionbuttontextcolor: "#000000",
      initialsectionbuttontextcolor: "#000000",
      //Saleitems State
      saleitemscheckbox: undefined,
      //New Arrival
      newarrivalscheckbox: undefined,
      //web_view_display_bool
      web_view_display_bool: undefined,
      web_view_target_url: "",
      //Blogs
      blogcheckbox: undefined,
      blog_banner_display: undefined,
      blog_categories_display: undefined,
      sticky_blog_display: undefined,
      Blogshow: false,
      //Blog category
      blog_categories_columns: "3",
      blog_categories_shape: "round_corner",
      blog_categories_bg_color: "#F8D31E",
      blog_categories_text_color: "#000000",
      blog_categories_selection_type: "parent_categories",
      blog_categories_sorting: "name",
      showremainblog: false,
      //Cart
      Cartproductcheckbox: undefined,
      //Featured
      featuredproductcheckbox: undefined,
      //Recent View
      recentviewcheckbox: undefined,
      notch: require("../../assets/images/dashboard/speaker.png").default,
      holloimage: require("../../assets/images/dashboard/phoneback.png")
        .default,
      hollowactive: true,
      getimage: true,
      //order
      sectionorder: [],
      modalBackgroundImage: false,
      modalSolidColor: false,
      dashboardshow: false,
      //overlay
      orderforpreview: [],
      hideoverlay: false,
      uploadtext: "",
      //website type
      typeselection: "",
      //Pages
      new_pages_display: undefined,
      //Scroll image
      scrollimage: require("../../assets/images/dashboard/scrolldown.png")
        .default,
      //store url
      store_url: "",
      buttondata: [],
      categories_sorting: "name",
      categories_selection_type: "",
      categories_shape: "round_corner",
      categories_columns: "3",
      catfont: "10px",
      showremaincate: false,
      categorychange: false,
      hit: false,
      modalredirect: "",
      show_web_view_header_footer_bool: true,
      show_web_view_footer: true,
      show_web_view_header: true,
      //Settngs
      sale_items_settings: false,
      blog_categories_setting: false,
      product_categories_setting: false,
      recently_viewed_products_settings: false,
      featured_products_settings: false,
      in_cart_products: false,
      new_products_settings: false,
      recent_blogs_settings: false,
      blog_banner_setting: false,
      popular_blogs_settings: false,
      //Label
      product_categories_label: "Product categories",
      sale_products_label: "Sale Products",
      sticky_blogs_label: "Popular Blogs",
      recently_viewed_products_label: "Recently Viewed",
      new_products_label: "New Products",
      new_blogs_label: "Recent Blogs",
      incart_products_label: "In Cart Products",
      featured_products_label: "Featured Products",
      blog_categories_label: "Blogs Categories",
      blog_banner_label: "Blog Banner",
      characterlimit: "25",
      show_web_view_feature: 1,
      is_enable_posts_search: false,
      is_enable_products_search: false,
      topheadercheckbox: true,
      get_all_data: [],
      hex_change: "",
      website_technology: parseInt(localStorage.getItem("website_technology")),
      hide_html_element_by_class: "",
      hide_html_element_by_id: "",
      submitdirection:"",
      appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
        ? true
        : false,
    };
  }

  handleClass = (e) => {
    this.setState({
      hide_html_element_by_class: e.target.value,
    });
  };

  handleId = (e) => {
    this.setState({
      hide_html_element_by_id: e.target.value,
    });
  };

  //chnage color

  changeColorr = (hexCode) => {
    if (hexCode !== undefined) {
      const hexToRgb = (hex) => {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;

        hex = hex.replace(shorthandRegex, (m, r, g, b) => {
          return r + r + g + g + b + b;
        });

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
          ? [
              parseInt(result[1], 16),
              parseInt(result[2], 16),
              parseInt(result[3], 16),
            ]
          : null;
      };

      var rgb = hexToRgb(hexCode);

      if (rgb === null && hexCode != "") {
        const colorvalue = hexCode.match(/\d+/g).map(Number);

        rgb = [];
        rgb[0] = colorvalue[0];
        rgb[1] = colorvalue[1];
        rgb[2] = colorvalue[2];
      } else if (rgb.length !== 3) {
        return;
      }
      const color = new Color(rgb[0], rgb[1], rgb[2]);
      const solver = new Solver(color);
      const result = solver.solve();

      return result.filter;
    }
  };

  //App Name Function
  handleappname = (e) => {
    this.setState({
      appName: e.target.value.replace(/^\s+/, ""),
      hit: true,
    });
  };
  errormodal = () => {
    this.setState({
      errormodal: !this.state.errormodal,
    });
  };
  handleweb_view_modal = () => {
    this.setState({
      web_view_modal: !this.state.web_view_modal,
    });
  };
  handlecategorychange = (e, modal) => {
    e.preventDefault();

    if (this.state.hit === true) {
      this.setState({
        categorychange: !this.state.categorychange,
        modalredirect: modal,
      });
    } else {
      if (modal === "blogmodal") {
        this.props.history.push("/app/blogcategoryselection");
      }
      if (modal === "catmodal") {
        this.props.history.push("/app/categoryselection");
      }
      if (modal === "pagemodal") {
        this.props.history.push("/app/pageselection");
      }
    }
  };
  loaddatafromapi = () => {
    window.scrollTo(0, 0);
    document
      .querySelector(".white-mobile-border")
      .addEventListener("scroll", this.divscroll, true);
    if (window.innerWidth < 900) {
      this.setState({
        mobileref: true,
        hit: false,
      });
    } else {
      this.setState({
        mobileref: false,
        hit: false,
      });
    }
    //Main Banner
    const params = {
      orientation: "horizontal",
    };
    //Get APi
    DashboardApi(this, () => {
      this.hidescrollfunction();
      let j = 0;
      let listitems = document.querySelector(".sectionmove");
      for (j = 0; j < this.state.sectionorder.length; j++) {
        //code to show main banner open by default based on condition
        if (this.state.sectionorder[j] === "main_banner") {
          listitems.insertBefore(
            document.querySelector(".banner1"),
            listitems.childNodes[j]
          );
        }
        if (this.state.sectionorder[j] === "recently_viewed_products") {
          listitems.insertBefore(
            document.querySelector(".recently_viewed_products1"),
            listitems.childNodes[j]
          );
        }
        if (this.state.sectionorder[j] === "new_products") {
          listitems.insertBefore(
            document.querySelector(".new_products1"),
            listitems.childNodes[j]
          );
        }
        if (this.state.sectionorder[j] === "web_view") {
          listitems.insertBefore(
            document.querySelector(".web_view1"),
            listitems.childNodes[j]
          );
        }
        if (this.state.sectionorder[j] === "cart") {
          listitems.insertBefore(
            document.querySelector(".cart1"),
            listitems.childNodes[j]
          );
        }
        if (this.state.sectionorder[j] === "featured_products") {
          listitems.insertBefore(
            document.querySelector(".featured_products1"),
            listitems.childNodes[j]
          );
        }
        if (this.state.sectionorder[j] === "new_blogs") {
          listitems.insertBefore(
            document.querySelector(".blogs1"),
            listitems.childNodes[j]
          );
        }
        if (this.state.sectionorder[j] === "sale_products") {
          listitems.insertBefore(
            document.querySelector(".sale_products1"),
            listitems.childNodes[j]
          );
        }
        if (this.state.sectionorder[j] === "product_categories") {
          listitems.insertBefore(
            document.querySelector(".categories1"),
            listitems.childNodes[j]
          );
        }
        if (this.state.sectionorder[j] === "blog_categories") {
          listitems.insertBefore(
            document.querySelector(".blog_categories1"),
            listitems.childNodes[j]
          );
        }
        if (this.state.sectionorder[j] === "sticky_blogs") {
          listitems.insertBefore(
            document.querySelector(".sticky_blogs1"),
            listitems.childNodes[j]
          );
        }

        if (this.state.sectionorder[j] === "blog_banner") {
          listitems.insertBefore(
            document.querySelector(".blogbanner1"),
            listitems.childNodes[j]
          );
        }
      }
      let i = 0;
      let list = document.querySelector(".green_toggle");
      let showsequence = this.state.sectionorder;
      for (i = 0; i < showsequence.length; i++) {
        list.insertBefore(
          document.querySelector("." + this.state.sectionorder[i]),
          list.childNodes[i]
        );
      }
    });
    let sectionindex = [];
    let i = 0;
    for (
      i = 0;
      i < document.querySelectorAll(".dashboard-customizer").length;
      i++
    ) {
      sectionindex.push(
        document
          .querySelectorAll(".dashboard-customizer")
          [i].className.replace("dashboard-customizer ", "")
      );
    }
    this.setState({
      submitval:
        localStorage.getItem("dashboard_icon_count") === "1" ? "Save" : "Save",
      orderforpreview: sectionindex,
    });
    //Get APi End
    Pixalbay(params, this);

    document
      .querySelector(".main_banner")
      .addEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".recently_viewed_products")
      .addEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".new_products")
      .addEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".web_view")
      .addEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".cart")
      .addEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".featured_products")
      .addEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".new_blogs")
      .addEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".sale_products")
      .addEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".product_categories")
      .addEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".sticky_blogs")
      .addEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".blog_categories")
      .addEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".blog_banner")
      .addEventListener("mouseout", this.handleClickOutside);
  };
  //Onload click scroll down function
  hidescrollfunction = () => {
    this.setout = setTimeout(() => {
      let div = document.querySelector(".sectionmove");
      let hasVerticalScrollbar = div.offsetHeight > 507;

      if (hasVerticalScrollbar === false) {
        document.querySelector(".click-to-scroll").style.display = "none";
      } else {
        document.querySelector(".click-to-scroll").style.display = "block";
      }
    }, 1000);
  };
  //Onload click scroll down function End
  componentDidMount() {
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
      {
        document.title = "Home | AppMySite";
  
      }
      else
      {
        document.title = "Home |"+ ' ' +localStorage.getItem('agencyname');
      }
    this.mounted && this.loaddatafromapi();
  }
  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.setout);
    document
      .querySelector(".white-mobile-border")
      .removeEventListener("scroll", this.divscroll);
    document
      .querySelector(".main_banner")
      .removeEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".recently_viewed_products")
      .removeEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".new_products")
      .removeEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".web_view")
      .removeEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".cart")
      .removeEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".featured_products")
      .removeEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".new_blogs")
      .removeEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".sale_products")
      .removeEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".product_categories")
      .removeEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".sticky_blogs")
      .addEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".blog_categories")
      .addEventListener("mouseout", this.handleClickOutside);
    document
      .querySelector(".blog_banner")
      .addEventListener("mouseout", this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };
  handleClickOutside = (event) => {
    let sectionindex = [];
    let i = 0;
    for (
      i = 0;
      i < document.querySelectorAll(".dashboard-customizer").length;
      i++
    ) {
      sectionindex.push(
        document
          .querySelectorAll(".dashboard-customizer")
          [i].className.replace("dashboard-customizer ", "")
      );
    }
    if (
      sectionindex.indexOf("main_banner gu-transit") === -1 &&
      sectionindex.indexOf("recently_viewed_products gu-transit") === -1 &&
      sectionindex.indexOf("new_products gu-transit") === -1 &&
      sectionindex.indexOf("web_view gu-transit") === -1 &&
      sectionindex.indexOf("cart gu-transit") === -1 &&
      sectionindex.indexOf("featured_products gu-transit") === -1 &&
      sectionindex.indexOf("new_blogs gu-transit") === -1 &&
      sectionindex.indexOf("sale_products gu-transit") === -1 &&
      sectionindex.indexOf("product_categories gu-transit") === -1 &&
      sectionindex.indexOf("sticky_blogs gu-transit") === -1 &&
      sectionindex.indexOf("blog_categories gu-transit") === -1 &&
      sectionindex.indexOf("blog_banner gu-transit") === -1
    ) {
      // this.setState({
      // 	hideoverlay:false
      // })
    } else {
      this.setState({
        hideoverlay: true,
      });
      sectionindex = [];
    }
  };
  togglemodalBackgroundImage = (e) => {
    e.preventDefault();
    this.setState({
      modalBackgroundImage: !this.state.modalBackgroundImage,
    });
  };
  togglemodalSolidColor = (e) => {
    e.preventDefault();
    this.setState({
      modalSolidColor: !this.state.modalSolidColor,
    });
  };
  //Main Banner Functions
  changeshow = (e) => {
    let elmnt = document.querySelector(".banner1");
    elmnt.scrollIntoView(false);
    e.preventDefault();
    this.setState({
      show: !this.state.show,
      secshow: false,
      Catshow: false,
      webviewshow: false,
      Blogshow: false,
      sale_items_settings: false,
      recently_viewed_products_settings: false,
      featured_products_settings: false,
      in_cart_products: false,
      new_products_settings: false,
      popular_blogs_settings: false,
      recent_blogs_settings: false,
      blog_banner_setting: false,
      product_categories_setting: false,
      blog_categories_setting: false,
      dashboardshow: false,
    });
  };
  // Blog category slow
  blogchangeshow = (e) => {
    e.preventDefault();
    let elmnt = document.querySelector(".blog_categories1");
    elmnt.scrollIntoView(false);
    this.setState({
      show: false,
      secshow: false,
      Catshow: false,
      sale_items_settings: false,
      webviewshow: false,
      Blogshow: !this.state.Blogshow,
      recently_viewed_products_settings: false,
      featured_products_settings: false,
      in_cart_products: false,
      new_products_settings: false,
      popular_blogs_settings: false,
      recent_blogs_settings: false,
      blog_banner_setting: false,
      product_categories_setting: false,
      blog_categories_setting: false,
      dashboardshow: false,
    });
  };
  //saleitemssetting
  sale_items_settings = (e) => {
    e.preventDefault();
    let elmnt = document.querySelector(".sale_products1");
    elmnt.scrollIntoView(false);
    this.setState({
      sale_items_settings: !this.state.sale_items_settings,

      show: false,
      secshow: false,
      Blogshow: false,
      Catshow: false,
      webviewshow: false,
      recently_viewed_products_settings: false,
      featured_products_settings: false,
      in_cart_products: false,
      new_products_settings: false,
      popular_blogs_settings: false,
      recent_blogs_settings: false,
      blog_banner_setting: false,
      product_categories_setting: false,
      blog_categories_setting: false,
    });
  };
  //Product categories_setting
  product_categories_setting = (e) => {
    e.preventDefault();
    let elmnt = document.querySelector(".categories1");
    elmnt.scrollIntoView(false);
    this.setState({
      product_categories_setting: !this.state.product_categories_setting,
      sale_items_settings: false,
      show: false,
      secshow: false,
      Blogshow: false,
      Catshow: false,
      webviewshow: false,
      recently_viewed_products_settings: false,
      featured_products_settings: false,
      in_cart_products: false,
      new_products_settings: false,
      popular_blogs_settings: false,
      recent_blogs_settings: false,
      blog_banner_setting: false,
      blog_categories_setting: false,
    });
  };
  //blog categories_setting
  blog_categories_setting = (e) => {
    e.preventDefault();
    let elmnt = document.querySelector(".blog_categories1");
    elmnt.scrollIntoView(false);
    this.setState({
      blog_categories_setting: !this.state.blog_categories_setting,
      product_categories_setting: false,
      sale_items_settings: false,
      show: false,
      secshow: false,
      Blogshow: false,
      Catshow: false,
      webviewshow: false,
      recently_viewed_products_settings: false,
      featured_products_settings: false,
      in_cart_products: false,
      new_products_settings: false,
      popular_blogs_settings: false,
      recent_blogs_settings: false,
      blog_banner_setting: false,
    });
  };
  //Recently viewed products settings
  recently_viewed_products_settings = (e) => {
    e.preventDefault();
    let elmnt = document.querySelector(".recently_viewed_products1");
    elmnt.scrollIntoView(false);
    this.setState({
      recently_viewed_products_settings:
        !this.state.recently_viewed_products_settings,
      show: false,
      secshow: false,
      Blogshow: false,
      Catshow: false,
      webviewshow: false,
      sale_items_settings: false,
      featured_products_settings: false,
      in_cart_products: false,
      new_products_settings: false,
      popular_blogs_settings: false,
      recent_blogs_settings: false,
      blog_banner_setting: false,
      product_categories_setting: false,
      blog_categories_setting: false,
    });
  };
  //featured products setting
  featured_products_settings = (e) => {
    e.preventDefault();
    let elmnt = document.querySelector(".featured_products1");
    elmnt.scrollIntoView(false);
    this.setState({
      featured_products_settings: !this.state.featured_products_settings,
      show: false,
      Blogshow: false,
      secshow: false,
      Catshow: false,
      webviewshow: false,
      sale_items_settings: false,
      recently_viewed_products_settings: false,
      in_cart_products: false,
      new_products_settings: false,
      popular_blogs_settings: false,
      recent_blogs_settings: false,
      blog_banner_setting: false,
      product_categories_setting: false,
      blog_categories_setting: false,
    });
  };
  //in Cart products setting
  in_cart_products = (e) => {
    e.preventDefault();
    let elmnt = document.querySelector(".cart1");
    elmnt.scrollIntoView(false);
    this.setState({
      in_cart_products: !this.state.in_cart_products,
      show: false,
      secshow: false,
      Blogshow: false,
      Catshow: false,
      webviewshow: false,
      sale_items_settings: false,
      recently_viewed_products_settings: false,
      featured_products_settings: false,
      new_products_settings: false,
      popular_blogs_settings: false,
      recent_blogs_settings: false,
      blog_banner_setting: false,
      product_categories_setting: false,
      blog_categories_setting: false,
    });
  };
  //new products setting
  new_products_settings = (e) => {
    e.preventDefault();
    let elmnt = document.querySelector(".new_products1");
    elmnt.scrollIntoView(false);
    this.setState({
      new_products_settings: !this.state.new_products_settings,
      show: false,
      secshow: false,
      Blogshow: false,
      Catshow: false,
      webviewshow: false,
      sale_items_settings: false,
      recently_viewed_products_settings: false,
      featured_products_settings: false,
      in_cart_products: false,
      popular_blogs_settings: false,
      recent_blogs_settings: false,
      blog_banner_setting: false,
      product_categories_setting: false,
      blog_categories_setting: false,
    });
  };
  //recent_blogs_settings
  recent_blogs_settings = (e) => {
    e.preventDefault();
    let elmnt = document.querySelector(".blogs1");
    elmnt.scrollIntoView(false);
    this.setState({
      recent_blogs_settings: !this.state.recent_blogs_settings,
      blog_banner_setting: false,
      show: false,
      secshow: false,
      Blogshow: false,
      Catshow: false,
      webviewshow: false,
      sale_items_settings: false,
      recently_viewed_products_settings: false,
      featured_products_settings: false,
      in_cart_products: false,
      popular_blogs_settings: false,
      new_products_settings: false,
      product_categories_setting: false,
      blog_categories_setting: false,
    });
  };
  //blog_banner_setting
  blog_banner_setting = (e) => {
    e.preventDefault();
    let elmnt = document.querySelector(".blogbanner1");
    elmnt.scrollIntoView(false);
    this.setState({
      recent_blogs_settings: false,
      blog_banner_setting: !this.state.blog_banner_setting,
      show: false,
      secshow: false,
      Blogshow: false,
      Catshow: false,
      webviewshow: false,
      sale_items_settings: false,
      recently_viewed_products_settings: false,
      featured_products_settings: false,
      in_cart_products: false,
      popular_blogs_settings: false,
      new_products_settings: false,
      product_categories_setting: false,
      blog_categories_setting: false,
    });
  };
  //popular_blogs_settings
  popular_blogs_settings = (e) => {
    e.preventDefault();
    let elmnt = document.querySelector(".sticky_blogs1");
    elmnt.scrollIntoView(false);
    this.setState({
      popular_blogs_settings: !this.state.popular_blogs_settings,
      show: false,
      secshow: false,
      Catshow: false,
      webviewshow: false,
      Blogshow: false,
      sale_items_settings: false,
      recently_viewed_products_settings: false,
      featured_products_settings: false,
      in_cart_products: false,
      recent_blogs_settings: false,
      blog_banner_setting: false,
      new_products_settings: false,
      product_categories_setting: false,
      blog_categories_setting: false,
    });
  };
  //dashboard header
  changedashboardshow = (e) => {
    e.preventDefault();
    this.setState({
      dashboardshow: !this.state.dashboardshow,
      webviewshow: false,
      secshow: false,
      Catshow: false,
      show: false,
      Blogshow: false,
      sale_items_settings: false,
      recently_viewed_products_settings: false,
      featured_products_settings: false,
      in_cart_products: false,
      new_products_settings: false,
      popular_blogs_settings: false,
      recent_blogs_settings: false,
      blog_banner_setting: false,
      product_categories_setting: false,
      blog_categories_setting: false,
    });
  };
  //Web view

  changewebviewshow = (e) => {
    e.preventDefault();
    this.setState({
      webviewshow: !this.state.webviewshow,
      secshow: false,
      dashboardshow: false,
      Catshow: false,
      show: false,
      Blogshow: false,
      sale_items_settings: false,
      recently_viewed_products_settings: false,
      featured_products_settings: false,
      in_cart_products: false,
      new_products_settings: false,
      popular_blogs_settings: false,
      recent_blogs_settings: false,
      blog_banner_setting: false,
      product_categories_setting: false,
      blog_categories_setting: false,
    });
  };
  //Preview scroll down
  scrollDown = () => {
    let scrolldiv = document.querySelector(".white-mobile-border");
    let sectionheight =
      document.querySelector(".white-mobile-border").scrollHeight - 150;
    if (scrolldiv.scrollTop + scrolldiv.clientHeight >= sectionheight) {
      scrolldiv.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      scrolldiv.scrollBy({
        top: 200,
        behavior: "smooth",
      });
    }
  };
  divscroll = () => {
    let scrolldiv = document.querySelector(".white-mobile-border");
    let sectionheight =
      document.querySelector(".white-mobile-border").scrollHeight - 120;
    if (scrolldiv.scrollTop + scrolldiv.clientHeight >= sectionheight) {
      this.setState({
        scrollimage: require("../../assets/images/dashboard/scrollup.png")
          .default,
      });
    } else {
      this.setState({
        scrollimage: require("../../assets/images/dashboard/scrolldown.png")
          .default,
      });
    }
  };
  //Upload Background Image Modal
  toggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  //Modal Scroll Function
  handleContainerOnBottom = () => {
    this.setState({ pageval: 1 + this.state.pageval });
    const params = {
      pixalsearchvalue: this.state.pixalsearchvalue,
      pageval: this.state.pageval,
      pixalbayimage: this.state.pixalbayimage,
      orientation: "horizontal",
    };
    Pixalbayscroll(params, this);
  };

  handlepixabaysearch = (e) => {
    this.setState({
      pixalsearchvalue: e.target.value,
    });
  };
  handlechooseimage = (e) => {
    this.setState({
      chooseImage: e.currentTarget.value,
      submitbuttontext: "Done",
    });
  };
  submitChooseImage = (e) => {
    this.setState({
      uploadtext: "",
    });
    const chooseImage = this.state.chooseImage;
    if (chooseImage != null) {
      this.setState({
        selectpixabayimage: chooseImage,
        selectbackgroundorcolor: false,
        splashBackground: "",
        imagename: chooseImage.substr(chooseImage.lastIndexOf("/") + 1),
        hit: true,
      });
      this.toggle();
    } else {
      errortoast("Please select Image");
      return;
    }
  };
  switchmobile = (e, data) => {
    e.preventDefault();
    if (data === "android") {
      this.setState({
        holloimage: require("../../assets/images/dashboard/androidapps.png")
          .default,
        notch: require("../../assets/images/dashboard/androidnotch.png")
          .default,
        hollowactive: false,
      });
    } else {
      this.setState({
        holloimage: require("../../assets/images/dashboard/phoneback.png")
          .default,
        notch: require("../../assets/images/dashboard/speaker.png").default,
        hollowactive: true,
      });
    }
  };
  uploadcustomdefaultimage = (e) => {
    let self = this;
    let reader = new FileReader();
    let file = e.target.files[0];
    if (
      file.name.indexOf("png") !== -1 ||
      file.name.indexOf("jpeg") !== -1 ||
      file.name.indexOf("jpg") !== -1
    ) {
      if (file.size / (1024 * 1024).toFixed(2) <= 2) {
        reader.onload = function (upload) {
          let img = new Image();
          img.src = upload.target.result;
          img.onload = function () {
            if (this.height >= 480 && this.width >= 1125) {
              self.setState({
                uploadcustomdefaultimage: upload.target.result,
                getimage: false,
              });
            } else {
              errortoast("Image dimension should be more than 1125*480px");
            }
          };
        };
        reader.readAsDataURL(file);
      } else {
        errortoast("Image size should be less than 2MB");
      }
    } else {
      errortoast(
        "File format not supported. Upload PNG, JPEG or JPG file only."
      );
    }
  };
  finalcrop = (croppedImage, base64, original) => {
    this.setState({
      uploadcustomdefaultimage: base64,
      uploadcustomdefaultimageApi: croppedImage,
      chooseImage: base64,
      submitbuttontext: "Done",
      getimage: true,
      original_banner: original,
      uploadtext: "Click here to upload an image",
    });
  };
  whenTogglemodal = () => {
    this.setState({
      uploadcustomdefaultimage:
        require("../../assets/images/dashboard/storage.png").default,
      getimage: true,
      uploadcustomdefaultimageApi: null,
      chooseImage: null,
      original_banner: null,
      uploadtext: "",
    });
  };
  toggleupload = () => {
    if (this.state.uploadcustomimage) {
      this.setState({
        uploadcustomdefaultimage:
          require("../../assets/images/dashboard/storage.png").default,
        uploadcustomimage: false,
        selectpixabayimage: null,
        chooseImage: null,
        uploadtext: "",
      });
    } else {
      this.setState({
        uploadcustomimage: true,
        selectpixabayimage: null,
        chooseImage: null,
      });
    }
  };
  pixalsearch = (e) => {
    e.preventDefault();
    const params = {
      pixalsearchvalue: this.state.pixalsearchvalue,
      orientation: "horizontal",
      bottom_menu_style: {
        bottom_menu_bg_colour: this.state.background,
        bottom_menu_show_text:
          this.state.bottom_menu_show_text === true ? 1 : 0,
        bottom_menu_icon_and_text_colour: this.state.textcolor,
        bottom_menu_selected_item_colour: this.state.selectedtextcolor,
        bottom_menu_border_colour: this.state.borderColor,
      },
    };
    this.setState({
      pageval: 1,
    });
    // if (this.state.pixalsearchvalue) {
    document.querySelector(".modal-body-content").scrollTo(0, 0);
    Pixalbaysearch(params, this);
    // } else return;
  };
  //Solid color function
  changecolor = (e) => {
    document.querySelector(".main_banner").classList.remove("gu-transit");

    this.setState({
      splashBackground: e,
      selectbackgroundorcolor: true,
      selectpixabayimage: "",
      chooseImage: null,
      imagename: "",
      hit: true,
    });
  };

  //BannerText
  handlebannerText = (e) => {
    this.setState({
      bannertext: e.target.value,
      hit: true,
    });
  };

  //Banner Text Fontfamily
  handlebannertextfontfamily = (e) => {
    this.setState({
      bannertextfontfamily: e,
      hit: true,
    });
  };
  //Banner Text font size
  handlebannerfontsize = (e) => {
    this.setState({
      bannerfontSize: e.target.value,
      hit: true,
    });
  };

  //Banner Text Color
  handlebannertextColor = (e) => {
    document.querySelector(".main_banner").classList.remove("gu-transit");
    this.setState({
      bannertextColor: e,
      hit: true,
    });
  };

  //Banner Text Align
  handlebannertextAlign = (value) => {
    this.setState({
      bannertextAlign: value,
      hit: true,
    });
  };

  //Banner CheckBox
  handleBannerCheckbox = (e) => {
    this.hidescrollfunction();
    this.setState({
      bannerCheckbox: !this.state.bannerCheckbox,
      hit: true,
    });
  };
  //headercheckbox
  handleheaderchecbox = (e) => {
    this.setState({
      topheadercheckbox: e.target.checked,
    });
  };
  //Blog Category
  handleblog_categories_display = (e) => {
    this.hidescrollfunction();
    this.setState({
      blog_categories_display: !this.state.blog_categories_display,
      hit: true,
    });
  };
  //Sticky Blog
  handlesticky_blog_display = (e) => {
    this.hidescrollfunction();
    this.setState({
      sticky_blog_display: !this.state.sticky_blog_display,
      hit: true,
    });
  };
  //new pages
  handlenew_pages_display = (e) => {
    this.hidescrollfunction();
    this.setState({
      new_pages_display: !this.state.new_pages_display,
      hit: true,
    });
  };
  //handlelabel
  handlelabelname = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      hit: true,
    });
  };

  //Category Functions
  catchangeshow = (e) => {
    let elmnt = document.querySelector(".categories1");
    elmnt.scrollIntoView(false);
    e.preventDefault();
    this.setState({
      Catshow: !this.state.Catshow,
      dashboardshow: false,
      show: false,
      secshow: false,
      webviewshow: false,
      Blogshow: false,
      sale_items_settings: false,
      recently_viewed_products_settings: false,
      featured_products_settings: false,
      in_cart_products: false,
      new_products_settings: false,
      popular_blogs_settings: false,
      recent_blogs_settings: false,
      blog_banner_setting: false,
      product_categories_setting: false,
      blog_categories_setting: false,
    });
  };
  tabtoggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
        hit: true,
      });
    }
    if (tab === "1") {
      this.setState({
        catbackgroundcolor: this.state.catbackgroundcolor,
        catbackgroundtextcolor: this.state.catbackgroundtextcolor,
        activestate: true,
      });
    } else {
      this.setState({
        cattextbackgroundcolor: this.state.cattextbackgroundcolor,
        cattextcolor: this.state.cattextcolor,
        activestate: false,
      });
    }
  };

  //Text background Color
  handlecattextbackgroundcolor = (e) => {
    document
      .querySelector(".product_categories")
      .classList.remove("gu-transit");
    this.setState({
      cattextbackgroundcolor: e,
      hit: true,
    });
  };

  //Text  Color
  handlecattextcolor = (e) => {
    document
      .querySelector(".product_categories")
      .classList.remove("gu-transit");
    this.setState({
      cattextcolor: e,
      hit: true,
    });
  };

  //background Color
  handlecatbackgroundcolor = (e) => {
    document
      .querySelector(".product_categories")
      .classList.remove("gu-transit");
    this.setState({
      catbackgroundcolor: e,
      hit: true,
    });
  };
  //Text background Color
  handlecatbackgroundtextcolor = (e) => {
    document
      .querySelector(".product_categories")
      .classList.remove("gu-transit");
    this.setState({
      catbackgroundtextcolor: e,
      hit: true,
    });
  };
  //blog category background color
  handleblog_categories_bg_color = (e) => {
    document.querySelector(".blog_categories").classList.remove("gu-transit");
    this.setState({
      blog_categories_bg_color: e,
      hit: true,
    });
  };
  //blog text category Color
  handleblog_categories_text_colorr = (e) => {
    document.querySelector(".blog_categories").classList.remove("gu-transit");
    this.setState({
      blog_categories_text_color: e,
      hit: true,
    });
  };

  //Categories CheckBox
  handlecategoriesCheckbox = (e) => {
    this.hidescrollfunction();
    this.setState({
      categoriesCheckbox: !this.state.categoriesCheckbox,
      hit: true,
    });
  };
  //Category Function End

  //sectionBackgroundcolor
  handlesectionBackgroundcolor = (e) => {
    this.setState({
      sectionBackgroundcolor: e,
      hit: true,
    });
  };

  secchangeshow = (e) => {
    e.preventDefault();
    this.setState({
      secshow: !this.state.secshow,
      show: false,
      Catshow: false,
      dashboardshow: false,
    });
  };
  //sectionBackgroundcolor
  handlesectionHeadingcolor = (e) => {
    this.setState({
      sectionHeadingcolor: e,
      hit: true,
    });
  };

  //sectionbuttoncolor
  handlesectionbuttoncolor = (e) => {
    this.setState({
      sectionbuttoncolor: e,
      hit: true,
    });
  };

  //sectionbuttontextcolor
  handlesectionbuttontextcolor = (e) => {
    this.setState({
      sectionbuttontextcolor: e,
      hit: true,
    });
  };

  //Section Function End

  //Saleitems Function
  //Saleitems CheckBox
  handlesaleitemscheckbox = (e) => {
    this.hidescrollfunction();
    this.setState({
      saleitemscheckbox: !this.state.saleitemscheckbox,
      hit: true,
    });
  };
  //Saleitems Function End

  //Recently View
  //Recentlyview CheckBox
  handlerecentviewcheckbox = (e) => {
    this.hidescrollfunction();
    this.setState({
      recentviewcheckbox: !this.state.recentviewcheckbox,
      hit: true,
    });
  };
  //Recently View End

  //Featuredproduct CheckBox
  handlefeaturedproductcheckbox = (e) => {
    this.hidescrollfunction();
    this.setState({
      featuredproductcheckbox: !this.state.featuredproductcheckbox,
      hit: true,
    });
  };
  //Cartproduct CheckBox
  handleCartproductcheckbox = (e) => {
    this.hidescrollfunction();
    this.setState({
      Cartproductcheckbox: !this.state.Cartproductcheckbox,
      hit: true,
    });
  };
  //Newarrivals CheckBox
  handlenewarrivalscheckbox = (e) => {
    this.hidescrollfunction();
    this.setState({
      newarrivalscheckbox: !this.state.newarrivalscheckbox,
      hit: true,
    });
  };
  //web_view_display_bool
  handleweb_view_display_bool = (e) => {
    this.hidescrollfunction();
    this.setState({
      web_view_display_bool: !this.state.web_view_display_bool,
      hit: true,
    });
    if (e.target.checked) {
      this.setState({
        web_view_modal: true,
      });
    }
  };
  //webviewurl
  handleinputfocus = (e) => {
    document.getElementById("domainname").focus();
  };
  handleweb_view_target_url = (e) => {
    if (e.target.value !== "") {
      this.setState({
        web_view_target_url: e.target.value.replace("https://", ""),
        hit: true,
      });
    } else {
      this.setState({
        web_view_target_url: "",
        hit: true,
      });
    }
  };
  //Blog CheckBox
  handleblogcheckbox = (e) => {
    this.hidescrollfunction();
    this.setState({
      blogcheckbox: !this.state.blogcheckbox,
    });
  };
  //Blog Banner
  handleblog_banner_display = (e) => {
    this.hidescrollfunction();
    this.setState({
      blog_banner_display: !this.state.blog_banner_display,
      hit: true,
    });
  };
  submitfunction = (e) =>
  {

    this.setState({rebuildpopvalue:!this.state.rebuildpopvalue});
    let sectionindex = [];
    let i = 0;
    for (
      i = 0;
      i < document.querySelectorAll(".dashboard-customizer").length;
      i++
    ) {
      sectionindex.push(
        document
          .querySelectorAll(".dashboard-customizer")
          [i].className.replace("dashboard-customizer ", "")
      );
    }

    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      //Banner
      bottom_menu_style: {
        bottom_menu_bg_colour: this.state.background,
        bottom_menu_show_text:
          this.state.bottom_menu_show_text === true ? 1 : 0,
        bottom_menu_icon_and_text_colour: this.state.textcolor,
        bottom_menu_selected_item_colour: this.state.selectedtextcolor,
        bottom_menu_border_colour: this.state.borderColor,
      },
      main_banner_bool: this.state.bannerCheckbox === true ? 1 : 0,
      banner_solidcolor: this.state.splashBackground,
      banner_text: this.state.bannertext,
      banner_fontname:
        this.state.bannertext === ""
          ? ""
          : this.state.bannertextfontfamily.indexOf("hitmo") > -1
          ? this.state.bannertextfontfamily.slice(0, 6) +
            "." +
            this.state.bannertextfontfamily.slice(6)
          : this.state.bannertextfontfamily,
      banner_fontsize:
        this.state.bannertext === "" ? "" : this.state.bannerfontSize,
      banner_fontcolor:
        this.state.bannertext === "" ? "" : this.state.bannertextColor,
      banner_textalign:
        this.state.bannertext === "" ? "" : this.state.bannertextAlign,
      upload_bannerimg:
        this.state.splashBackground !== ""
          ? this.state.selectpixabayimage
          : this.state.chooseImage === null
          ? ""
          : this.state.chooseImage.indexOf("pixabay") === -1
          ? this.state.uploadcustomdefaultimageApi
          : this.state.selectpixabayimage,
      original_banner:"",
      category_bool: this.state.categoriesCheckbox === true ? 1 : 0,
      category_boolc: this.state.categoriesCheckbox,
      category_tab_bool: parseInt(this.state.activeTab),
      text_background_color:
        this.state.activeTab === "1" ? this.state.cattextbackgroundcolor : "",
      text_color: this.state.activeTab === "1" ? this.state.cattextcolor : "",
      solid_background_color:
        this.state.activeTab === "2" ? this.state.catbackgroundcolor : "",
      solid_text_color:
        this.state.activeTab === "2" ? this.state.catbackgroundtextcolor : "",

      //section
      section_background_color: this.state.sectionBackgroundcolor,
      section_heading_color: this.state.sectionHeadingcolor,
      section_button_color: this.state.sectionbuttoncolor,
      section_buttontext_color: this.state.sectionbuttontextcolor,
      header_appname: this.state.appName,
      //boleen
      sale_items_bool: this.state.saleitemscheckbox === true ? 1 : 0,
      recent_viewed_products_bool:
        this.state.recentviewcheckbox === true ? 1 : 0,
      feature_products_bool:
        this.state.featuredproductcheckbox === true ? 1 : 0,
      incart_products_bool: this.state.Cartproductcheckbox === true ? 1 : 0,
      new_arrivals_bool: this.state.newarrivalscheckbox === true ? 1 : 0,
      web_view_display_bool: this.state.web_view_display_bool === true ? 1 : 0,
      web_view_target_url:
        this.state.web_view_target_url !== "" &&
        this.state.web_view_target_url !== null &&
        this.state.web_view_target_url !== undefined
          ? "https://" +
            this.state.web_view_target_url.replace(/^https?:\/\//i, "")
          : "https://" + this.state.store_url.replace(/^https?:\/\//i, ""),
      blog_display: this.state.blogcheckbox === true ? 1 : 0,
      blog_banner_display: this.state.blog_banner_display === true ? 1 : 0,
      blog_categories_display:
        this.state.blog_categories_display === true ? 1 : 0,
      sticky_blog_display: this.state.sticky_blog_display === true ? 1 : 0,
      new_pages_display: this.state.new_pages_display === true ? 1 : 0,
      position_sequence: sectionindex,
      enddraual: false,
      //category selection
      categories_columns: parseInt(this.state.categories_columns),
      categories_shape: this.state.categories_shape,
      categories_sorting: this.state.categories_sorting,
      categories_selection_type: this.state.categories_selection_type,
      show_product_category_name: this.state.categoryname === true ? 1 : 0,
      //Blog category selection
      blog_categories_columns: parseInt(this.state.blog_categories_columns),
      blog_categories_shape: this.state.blog_categories_shape,
      blog_categories_bg_color: this.state.blog_categories_bg_color,
      blog_categories_text_color: this.state.blog_categories_text_color,
      blog_categories_selection_type: this.state.blog_categories_selection_type,
      blog_categories_sorting: this.state.blog_categories_sorting,
      show_web_view_header_footer_bool:
        this.state.show_web_view_header_footer_bool === true ? 1 : 0,
      show_web_view_header_bool:
        this.state.show_web_view_header === true ? 1 : 0,
      show_web_view_footer_bool:
        this.state.show_web_view_footer === true ? 1 : 0,
      //label
      sale_products_label:
        this.state.sale_products_label === ""
          ? "Sale Products"
          : this.state.sale_products_label,
      recently_viewed_products_label:
        this.state.recently_viewed_products_label === ""
          ? "Recently Viewed Products"
          : this.state.recently_viewed_products_label,
      featured_products_label:
        this.state.featured_products_label === ""
          ? "Featured Products"
          : this.state.featured_products_label,
      incart_products_label:
        this.state.incart_products_label === ""
          ? "Cart"
          : this.state.incart_products_label,
      new_products_label:
        this.state.new_products_label === ""
          ? "New Products"
          : this.state.new_products_label,
      sticky_blogs_label:
        this.state.sticky_blogs_label === ""
          ? "Popular Blogs"
          : this.state.sticky_blogs_label,
      blog_categories_label:
        this.state.blog_categories_label === ""
          ? "Blog Categories"
          : this.state.blog_categories_label,
      new_blogs_label:
        this.state.new_blogs_label === ""
          ? "Recent Blogs"
          : this.state.new_blogs_label,
      product_categories_label:
        this.state.product_categories_label === ""
          ? "Product Categories"
          : this.state.product_categories_label,
      main_banner_label: "Main Banner",
      new_pages_label: "Pages",
      web_view_label: "WebView",
      blog_banner_label:
        this.state.blog_banner_label === ""
          ? "Blog Banner"
          : this.state.blog_banner_label,
      is_enable_posts_search:
        this.state.is_enable_posts_search === true ? 1 : 0,
      is_enable_products_search:
        this.state.is_enable_products_search === true ? 1 : 0,
      is_enable_header: this.state.topheadercheckbox === true ? 1 : 0,
      website_technology: this.state.website_technology,
      hide_html_element_by_class: this.state.hide_html_element_by_class,
      hide_html_element_by_id: this.state.hide_html_element_by_id,
    };
    if (
      (params.upload_bannerimg === "" || params.upload_bannerimg === null) &&
      params.banner_solidcolor === "" &&
      params.main_banner_bool === 1
    ) {
      errortoast("Please Select Main Banner");
    }
    Dashboardpost(
      params,
      this,
      this.state.typeselection,
      this.state.submitdirection,
      this.state.modalredirect
    );
  }
  
  closeRequireBuildPopup = (e) =>
  {

    this.setState({rebuildpopvalue:!this.state.rebuildpopvalue});
  }

  checkVersion(a, b) {
    const x = a.split('.').map(e => parseInt(e, 10));
    const y = b.split('.').map(e => parseInt(e, 10));

    var a1 = '';
    var b1 = '';
    for (const i in x) 
    {
        if(i == 0)
        {
          a1 = x[i] ;
          b1 = y[i] ;
        }
        else
        {
          a1 = a1 + '.'+ x[i] ;
          b1 = b1 + '.'+ y[i] ;
        }

    }
    
    
    return a1 < b1 ? 1 : 0;
}

  dashboardSubmit = (e, submitdirection) => 
  {
    e.preventDefault();

    this.setState({submitdirection:submitdirection});
    
    
    if(this.state.android_build_version != null || this.state.ios_build_version != null)
    {

      if(this.state.android_build_version != null &&  this.state.android_supported_version != null)
      {
        
        if(this.checkVersion(this.state.android_build_version,this.state.android_supported_version))
        {
          this.setState({rebuildpopvalue:!this.state.rebuildpopvalue});
          return ;
        }
      }
      else if(this.state.ios_build_version != null &&  this.state.ios_supported_version != null)
      {

        if(this.checkVersion(this.state.ios_build_version,this.state.ios_supported_version))
        {
          this.setState({rebuildpopvalue:!this.state.rebuildpopvalue});
          return ;
        }

      }

    }

    let sectionindex = [];
    let i = 0;
    for (
      i = 0;
      i < document.querySelectorAll(".dashboard-customizer").length;
      i++
    ) {
      sectionindex.push(
        document
          .querySelectorAll(".dashboard-customizer")
          [i].className.replace("dashboard-customizer ", "")
      );
    }

    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      //Banner
      bottom_menu_style: {
        bottom_menu_bg_colour: this.state.background,
        bottom_menu_show_text:
          this.state.bottom_menu_show_text === true ? 1 : 0,
        bottom_menu_icon_and_text_colour: this.state.textcolor,
        bottom_menu_selected_item_colour: this.state.selectedtextcolor,
        bottom_menu_border_colour: this.state.borderColor,
      },
      main_banner_bool: this.state.bannerCheckbox === true ? 1 : 0,
      banner_solidcolor: this.state.splashBackground,
      banner_text: this.state.bannertext,
      banner_fontname:
        this.state.bannertext === ""
          ? ""
          : this.state.bannertextfontfamily.indexOf("hitmo") > -1
          ? this.state.bannertextfontfamily.slice(0, 6) +
            "." +
            this.state.bannertextfontfamily.slice(6)
          : this.state.bannertextfontfamily,
      banner_fontsize:
        this.state.bannertext === "" ? "" : this.state.bannerfontSize,
      banner_fontcolor:
        this.state.bannertext === "" ? "" : this.state.bannertextColor,
      banner_textalign:
        this.state.bannertext === "" ? "" : this.state.bannertextAlign,
      upload_bannerimg:
        this.state.splashBackground !== ""
          ? this.state.selectpixabayimage
          : this.state.chooseImage === null
          ? ""
          : this.state.chooseImage.indexOf("pixabay") === -1
          ? this.state.uploadcustomdefaultimageApi
          : this.state.selectpixabayimage,
      original_banner:"",
        // this.state.original_banner === ""
        //   ? this.state.selectpixabayimage
        //   : this.state.original_banner,
      //Category
      category_bool: this.state.categoriesCheckbox === true ? 1 : 0,
      category_boolc: this.state.categoriesCheckbox,
      category_tab_bool: parseInt(this.state.activeTab),
      text_background_color:
        this.state.activeTab === "1" ? this.state.cattextbackgroundcolor : "",
      text_color: this.state.activeTab === "1" ? this.state.cattextcolor : "",
      solid_background_color:
        this.state.activeTab === "2" ? this.state.catbackgroundcolor : "",
      solid_text_color:
        this.state.activeTab === "2" ? this.state.catbackgroundtextcolor : "",

      //section
      section_background_color: this.state.sectionBackgroundcolor,
      section_heading_color: this.state.sectionHeadingcolor,
      section_button_color: this.state.sectionbuttoncolor,
      section_buttontext_color: this.state.sectionbuttontextcolor,
      header_appname: this.state.appName,
      //boleen
      sale_items_bool: this.state.saleitemscheckbox === true ? 1 : 0,
      recent_viewed_products_bool:
        this.state.recentviewcheckbox === true ? 1 : 0,
      feature_products_bool:
        this.state.featuredproductcheckbox === true ? 1 : 0,
      incart_products_bool: this.state.Cartproductcheckbox === true ? 1 : 0,
      new_arrivals_bool: this.state.newarrivalscheckbox === true ? 1 : 0,
      web_view_display_bool: this.state.web_view_display_bool === true ? 1 : 0,
      web_view_target_url:
        this.state.web_view_target_url !== "" &&
        this.state.web_view_target_url !== null &&
        this.state.web_view_target_url !== undefined
          ? "https://" +
            this.state.web_view_target_url.replace(/^https?:\/\//i, "")
          : "https://" + this.state.store_url.replace(/^https?:\/\//i, ""),
      blog_display: this.state.blogcheckbox === true ? 1 : 0,
      blog_banner_display: this.state.blog_banner_display === true ? 1 : 0,
      blog_categories_display:
        this.state.blog_categories_display === true ? 1 : 0,
      sticky_blog_display: this.state.sticky_blog_display === true ? 1 : 0,
      new_pages_display: this.state.new_pages_display === true ? 1 : 0,
      position_sequence: sectionindex,
      enddraual: false,
      //category selection
      categories_columns: parseInt(this.state.categories_columns),
      categories_shape: this.state.categories_shape,
      categories_sorting: this.state.categories_sorting,
      categories_selection_type: this.state.categories_selection_type,
      show_product_category_name: this.state.categoryname === true ? 1 : 0,
      //Blog category selection
      blog_categories_columns: parseInt(this.state.blog_categories_columns),
      blog_categories_shape: this.state.blog_categories_shape,
      blog_categories_bg_color: this.state.blog_categories_bg_color,
      blog_categories_text_color: this.state.blog_categories_text_color,
      blog_categories_selection_type: this.state.blog_categories_selection_type,
      blog_categories_sorting: this.state.blog_categories_sorting,
      show_web_view_header_footer_bool:
        this.state.show_web_view_header_footer_bool === true ? 1 : 0,
      show_web_view_header_bool:
        this.state.show_web_view_header === true ? 1 : 0,
      show_web_view_footer_bool:
        this.state.show_web_view_footer === true ? 1 : 0,
      //label
      sale_products_label:
        this.state.sale_products_label === ""
          ? "Sale Products"
          : this.state.sale_products_label,
      recently_viewed_products_label:
        this.state.recently_viewed_products_label === ""
          ? "Recently Viewed Products"
          : this.state.recently_viewed_products_label,
      featured_products_label:
        this.state.featured_products_label === ""
          ? "Featured Products"
          : this.state.featured_products_label,
      incart_products_label:
        this.state.incart_products_label === ""
          ? "Cart"
          : this.state.incart_products_label,
      new_products_label:
        this.state.new_products_label === ""
          ? "New Products"
          : this.state.new_products_label,
      sticky_blogs_label:
        this.state.sticky_blogs_label === ""
          ? "Popular Blogs"
          : this.state.sticky_blogs_label,
      blog_categories_label:
        this.state.blog_categories_label === ""
          ? "Blog Categories"
          : this.state.blog_categories_label,
      new_blogs_label:
        this.state.new_blogs_label === ""
          ? "Recent Blogs"
          : this.state.new_blogs_label,
      product_categories_label:
        this.state.product_categories_label === ""
          ? "Product Categories"
          : this.state.product_categories_label,
      main_banner_label: "Main Banner",
      new_pages_label: "Pages",
      web_view_label: "WebView",
      blog_banner_label:
        this.state.blog_banner_label === ""
          ? "Blog Banner"
          : this.state.blog_banner_label,
      is_enable_posts_search:
        this.state.is_enable_posts_search === true ? 1 : 0,
      is_enable_products_search:
        this.state.is_enable_products_search === true ? 1 : 0,
      is_enable_header: this.state.topheadercheckbox === true ? 1 : 0,
      website_technology: this.state.website_technology,
      hide_html_element_by_class: this.state.hide_html_element_by_class,
      hide_html_element_by_id: this.state.hide_html_element_by_id,
    };

    //Validation Section

    if (
      (params.upload_bannerimg === "" || params.upload_bannerimg === null) &&
      params.banner_solidcolor === "" &&
      params.main_banner_bool === 1
    ) {
      errortoast("Please Select Main Banner");
    }
    //  else if (
    //   /^#([0-9A-F]{3}){1,2}$/i.test(params.banner_solidcolor) === false &&
    //   params.main_banner_bool === 1 &&
    //   params.upload_bannerimg === ""
    // ) {
    //   errortoast("Enter a valid solid color code.");
    // } else if (
    //   params.banner_fontname !== "" &&
    //   /^#([0-9A-F]{3}){1,2}$/i.test(params.banner_fontcolor) === false &&
    //   params.main_banner_bool === 1
    // ) {
    //   errortoast("Enter a valid main banner color code.");
    // } else if (
    //   /^#([0-9A-F]{3}){1,2}$/i.test(this.state.cattextbackgroundcolor) ===
    //     false &&
    //   params.category_bool === 1 &&
    //   params.category_tab_bool === 1
    // ) {
    //   errortoast("Enter a valid category text background  color code.");
    // } else if (
    //   /^#([0-9A-F]{3}){1,2}$/i.test(params.text_color) === false &&
    //   params.category_bool === 1 &&
    //   params.category_tab_bool === 1
    // ) {
    //   errortoast("Enter a valid text color code.");
    // } else if (
    //   /^#([0-9A-F]{3}){1,2}$/i.test(params.solid_background_color) === false &&
    //   params.category_bool === 1 &&
    //   params.category_tab_bool === 2
    // ) {
    //   errortoast("Enter a valid category background color code.");
    // } else if (
    //   /^#([0-9A-F]{3}){1,2}$/i.test(params.solid_text_color) === false &&
    //   params.category_bool === 1 &&
    //   params.category_tab_bool === 2
    // ) {
    //   errortoast("Enter a valid category text color code.");
    // } else if (
    //   /^#([0-9A-F]{3}){1,2}$/i.test(params.section_background_color) === false
    // ) {
    //   errortoast("Enter a valid section background color code.");
    // } else if (
    //   /^#([0-9A-F]{3}){1,2}$/i.test(params.section_button_color) === false
    // ) {
    //   errortoast("Enter a valid section button color code.");
    // } else if (
    //   /^#([0-9A-F]{3}){1,2}$/i.test(params.section_buttontext_color) === false
    // ) {
    //   errortoast("Enter a valid section button text color code.");
    // } else if (
    //   /^#([0-9A-F]{3}){1,2}$/i.test(params.section_heading_color) === false
    // ) {
    //   errortoast("Enter a valid section heading color code.");
    // } else {
    Dashboardpost(
      params,
      this,
      this.state.typeselection,
      submitdirection,
      this.state.modalredirect
    );
    // }
  };
  dragulaDecorator = (componentBackingInstance) => {
    if (componentBackingInstance) {
      let options = {};
      Dragula([componentBackingInstance], options);
    }
  };
  enddraual = () => {
    this.setState({
      enddraual: true,
    });
  };
  handlecategories_sorting = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      hit: true,
    });
  };
  handleblog_sorting = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
      hit: true,
    });
  };
  handlecategories_columns = (e) => {
    this.setState({
      categories_columns: e.target.value,
      categories_shape:
        e.target.value === "1" &&
        this.state.categories_shape === "circular_corner"
          ? "round_corner"
          : this.state.categories_shape,
      hit: true,
    });
  };
  handleblog_columns = (e) => {
    this.setState({
      blog_categories_columns: e.target.value,
      blog_categories_shape:
        e.target.value === "1" &&
        this.state.blog_categories_shape === "circular_corner"
          ? "round_corner"
          : this.state.blog_categories_shape,
      hit: true,
    });
  };
  handleKeyDown = (e) => {
    if (e.keyCode === 38) {
      e.preventDefault();
    }
    if (e.keyCode === 40) {
      e.preventDefault();
    }
  };
  handlecategories_shape = (e) => {
    this.setState({
      categories_shape: e,
      hit: true,
    });
  };
  handleblog_shape = (e) => {
    this.setState({
      blog_categories_shape: e,
      hit: true,
    });
  };
  handlecategoryname = (e) => {
    this.setState({
      categoryname: e.target.checked,
      hit: true,
    });
  };
  handleshow_web_view_header_footer_bool = (e) => {
    this.setState({
      show_web_view_header_footer_bool: e.target.checked,
      hit: true,
    });
  };
  handleshow_web_view_header_bool = (e) => {
    this.setState({
      show_web_view_header: e.target.checked,
      hit: true,
    });
  };
  handleshow_web_view_footer_bool = (e) => {
    this.setState({
      show_web_view_footer: e.target.checked,
      hit: true,
    });
  };
  handleis_enable_posts_search = (e) => {
    this.setState({
      is_enable_posts_search: e.target.checked,
      is_enable_products_search: e.target.checked === true && false,
      hit: true,
    });
  };
  handleis_enable_products_search = (e) => {
    this.setState({
      is_enable_products_search: e.target.checked,
      is_enable_posts_search: e.target.checked === true && false,
      hit: true,
    });
  };

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }

  render() {
    const imagesize = () => {
      let shape = this.state.categories_shape;
      if (shape === "sharp_corner") {
        return { width: " 100%", borderRadius: "0px" };
      } else if (shape === "round_corner") {
        return { width: " 100%", borderRadius: "10px" };
      } else if (shape === "circular_corner") {
        return { width: " 100%", borderRadius: "50%" };
      }
    };
    const colorsize = () => {
      let shape = this.state.categories_shape;
      let column = this.state.categories_columns;
      if (shape === "sharp_corner") {
        if (column === "1") {
          this.setState({
            showremaincate: false,
          });
          return {
            background: this.state.catbackgroundcolor,
            color: this.state.catbackgroundtextcolor,
            width: " 100%",
            borderRadius: "0px",
            height: "40px",
            fontSize: "12px",
          };
        } else if (column === "2") {
          this.setState({
            showremaincate: false,
          });
          return {
            background: this.state.catbackgroundcolor,
            color: this.state.catbackgroundtextcolor,
            width: " 100%",
            borderRadius: "0px",
            fontSize: "12px",
            lineHeight: "18px",
          };
        } else if (column === "3") {
          this.setState({
            showremaincate: false,
          });
          return {
            background: this.state.catbackgroundcolor,
            color: this.state.catbackgroundtextcolor,
            width: " 100%",
            borderRadius: "0px",
            height: "72px",
            lineHeight: "15px",
            fontSize: "10px",
          };
        } else if (column === "4") {
          this.setState({
            showremaincate: true,
          });
          return {
            background: this.state.catbackgroundcolor,
            color: this.state.catbackgroundtextcolor,
            width: " 100%",
            borderRadius: "0px",
            height: "53px",
            fontSize: "8px",
            lineHeight: "11px",
          };
        }
      } else if (shape === "round_corner") {
        if (column === "1") {
          this.setState({
            showremaincate: false,
          });
          return {
            background: this.state.catbackgroundcolor,
            color: this.state.catbackgroundtextcolor,
            width: " 100%",
            borderRadius: "7px",
            height: "40px",
            fontSize: "12px",
          };
        } else if (column === "2") {
          this.setState({
            showremaincate: false,
          });
          return {
            background: this.state.catbackgroundcolor,
            color: this.state.catbackgroundtextcolor,
            width: " 100%",
            borderRadius: "7px",
            fontSize: "12px",
            lineHeight: "18px",
          };
        } else if (column === "3") {
          this.setState({
            showremaincate: false,
          });
          return {
            background: this.state.catbackgroundcolor,
            color: this.state.catbackgroundtextcolor,
            width: " 100%",
            borderRadius: "7px",
            height: "72px",
            lineHeight: "15px",
            fontSize: "10px",
          };
        } else if (column === "4") {
          this.setState({
            showremaincate: true,
          });
          return {
            background: this.state.catbackgroundcolor,
            color: this.state.catbackgroundtextcolor,
            width: " 100%",
            borderRadius: "7px",
            height: "53px",
            fontSize: "8px",
            lineHeight: "11px",
          };
        }
      } else if (shape === "circular_corner") {
        if (column === "1") {
          this.setState({
            catfont: "12px",
          });
          return {
            background: this.state.catbackgroundcolor,
            color: this.state.catbackgroundtextcolor,
            width: " 100%",
            borderRadius: "50%",
            height: "40px",
            fontSize: "12px",
          };
        } else if (column === "2") {
          this.setState({
            showremaincate: false,
          });
          if (window.innerWidth < 900) {
            return {
              background: this.state.catbackgroundcolor,
              color: this.state.catbackgroundtextcolor,
              width: " 100%",
              borderRadius: "50%",
              height: "158px",
              fontSize: "12px",
              lineHeight: "18px",
            };
          } else {
            return {
              background: this.state.catbackgroundcolor,
              color: this.state.catbackgroundtextcolor,
              width: " 100%",
              borderRadius: "50%",
              fontSize: "12px",
              lineHeight: "18px",
            };
          }
        } else if (column === "3") {
          this.setState({
            showremaincate: false,
          });
          if (window.innerWidth < 900) {
            return {
              background: this.state.catbackgroundcolor,
              color: this.state.catbackgroundtextcolor,
              width: " 100%",
              borderRadius: "50%",
              height: "103px",
              lineHeight: "15px",
              fontSize: "10px",
            };
          } else {
            return {
              background: this.state.catbackgroundcolor,
              color: this.state.catbackgroundtextcolor,
              width: " 72px",
              borderRadius: "50%",
              height: "72px",
              lineHeight: "15px",
              fontSize: "10px",
            };
          }
        } else if (column === "4") {
          this.setState({
            showremaincate: true,
          });
          if (window.innerWidth < 900) {
            return {
              background: this.state.catbackgroundcolor,
              color: this.state.catbackgroundtextcolor,
              width: " 100%",
              borderRadius: "50%",
              height: "78px",
              fontSize: "8px",
              lineHeight: "11px",
            };
          } else {
            return {
              background: this.state.catbackgroundcolor,
              color: this.state.catbackgroundtextcolor,
              width: " 53px",
              borderRadius: "50%",
              height: "53px",
              fontSize: "8px",
              lineHeight: "11px",
            };
          }
        }
      }
    };

    const contentSize = () => {
      let column = this.state.categories_columns;

      let finalcolumn;
      if (column === "1") {
        this.setState({
          catfont: "12px",
          showremaincate: false,
        });
        finalcolumn = { width: " 100%" };
      } else if (column === "2") {
        this.setState({
          catfont: "10px",
          showremaincate: false,
        });
        finalcolumn = { width: "46.5%" };
      } else if (column === "3") {
        this.setState({
          catfont: "9px",
          showremaincate: false,
        });
        finalcolumn = { width: " 31%" };
      } else if (column === "4") {
        this.setState({
          catfont: "8px",
          showremaincate: true,
        });
        finalcolumn = { width: " 22.6%" };
      }
      return finalcolumn;
    };
    return (
      <>
        <div className="">
          <Header customclass={"header-responsive-new"} value={this} />
        </div>
        {/* Mobile View */}
        {/* Mobile View End*/}
        <section className="dasboard_page dasboard_page_style mobile-display">
        <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
        <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>
            {/* Email Verification */}

            {/* Email Verification End*/}
            <div className="right_panel">
              {this.state.spinner && (
                <div className="newspinner">
                <svg xmlns="http://www.w3.org/2000/svg" 
          xlink="http://www.w3.org/1999/xlink" 
          style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
          width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
          <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>
            </animateTransform>
          </circle>
          </g>
          <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>
          </animateTransform>
          </circle>
          </g>
          <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>
  
          </animateTransform>
          </circle>
          </g>
          <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>
  
          </animateTransform>
          </circle>
          </g>
          </svg>
              </div>
              )}

              <div
                className="right_general"
                style={{ opacity: this.state.spinner === true ? 0 : 1 }}
              >
                <div className="d-md-flex align-items-center justify-content-between ipad-margin-top top-portion ipad-portion-appearance">
                  <div className="dasboard-page-title d-none d-md-block">
                    <div className="app_iconname">
                      <div className="d-flex align-items-center">
                        <h3>Home Screen</h3>
                        {/* <Amsvideo
                          timespan={88}
                          addclass="d-md-flex d-none align-items-center justify-content-between see_video"
                        /> */}
                      </div>
                      <h5>
                        Select &amp; design sections for your app home screen.{" "}
                        {/* <Media
                          href="https://www.appmysite.com/support/category/designing-your-apps-home-screen/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Learn More{" "}
                          <img
                            src={
                              require("../../assets/images/dashboard/Goto.png")
                                .default
                            }
                            className="gotoappicon appear-spacing"
                            alt="Goto"
                          />
                        </Media>{" "} */}
                      </h5>
                    </div>
                  </div>
                  <form onSubmit={this.pixalsearch}>
                    <div className="d-flex align-items-center justify-content-between fixed-button">
                      <div className="w-100 custom-100button">
                        <MobileApppreview />
                      </div>
                      <button
                        className={
                          this.state.hit === false
                            ? "blockuser next_btn w-100"
                            : "next_btn w-100"
                        }
                        style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                        disabled={this.state.disabledsubmitbutton}
                        onClick={(e) => this.dashboardSubmit(e, "realform")}
                      >
                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                          <div className={this.state.loader}>
                            <img src={this.state.loaderimage} alt="loader" />
                          </div>{" "}
                          <div>{this.state.submitval}</div>
                        </div>
                      </button>
                    </div>
                  </form>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="customiseappfeatures">
                    {/* Mobile View */}

                    {/* Mobile View End*/}

                    <div className="dashboard-content mobile-homescreen-padding">
                      <section className="">
                        {/* dashboard header*/}
                        <div>
                          <div
                            className="d-flex  align-items-center justify-content-between common-height dashboardheadrcss ipad-dashboard-head"
                            style={{ paddingRight: "15px" }}
                          >
                            <div className="module_title  d-flex">
                              {/* Tool tip */}
                              <Tooltip
                                firsttag={"h5"}
                                tagclass={"mb-0 mr-2"}
                                classdata={
                                  "d-flex justify-content-start align-items-center mb-1"
                                }
                                heading={"Home Header"}
                                content={
                                  "Enter your app name for dashboard header."
                                }
                              />
                              {/* Tool tip */}
                            </div>
                            <div className="on_off d-flex  align-items-center">
                              {this.state.dashboardshow ? (
                                <div className="edit-btn edit-btn-new mr-4">
                                  <Link
                                    to="#"
                                    onClick={(e) => this.changedashboardshow(e)}
                                  >
                                    <img
                                      className="mr-1 mr-md-2"
                                      src={
                                        require("../../assets/images/dashboard/gear-icon.png")
                                          .default
                                      }
                                      alt="gear"
                                    />
                                    <span>Settings</span>
                                  </Link>
                                </div>
                              ) : (
                                <div className="edit-btn edit-btn-new mr-4">
                                  <Link
                                    to="#"
                                    onClick={(e) => this.changedashboardshow(e)}
                                  >
                                    <img
                                      className="mr-1 mr-md-2"
                                      src={
                                        require("../../assets/images/dashboard/gear-icon.png")
                                          .default
                                      }
                                      alt="gear"
                                    />
                                    <span>Settings</span>
                                  </Link>
                                </div>
                              )}
                              <label
                                className="switch toggle-align"
                                htmlFor="topheadercheckbox"
                              >
                                <input
                                  type="checkbox"
                                  className="toplogo"
                                  id="topheadercheckbox"
                                  checked={this.state.topheadercheckbox}
                                  onChange={(e) => this.handleheaderchecbox(e)}
                                />
                                <div className="slider round"></div>
                              </label>
                            </div>
                          </div>
                          <div
                            className={
                              this.state.dashboardshow
                                ? "upload_files d-flex align-items-center justify-content-between flex-wrap flex-wrap pt-3 pb-3"
                                : "upload_files d-none align-items-center justify-content-between flex-wrap "
                            }
                          >
                            <div className="color_picker  mb-md-0 w-100 pl-md-3 ">
                              <div className="form-group">
                                <h4>Header Text </h4>
                                <input
                                  type="text"
                                  className="form-control form-name"
                                  value={this.state.appName}
                                  onChange={(e) => this.handleappname(e)}
                                />
                                {parseInt(localStorage.getItem("website_technology")) === 5 || parseInt(localStorage.getItem("website_technology")) === 4 ? (
                                  
                                  <div className="mt-3">
                                  {parseInt(localStorage.getItem("website_technology")) === 5 ?
                                    <>
                                    {(
                                      <div className="color_picker  align-items-center d-flex justify-content-between  w-100 mb-4 mt-1">
                                        <h4 className="m-0 p-0">
                                          Enable product search in header
                                        </h4>
                                        <div className="on_off d-flex  align-items-center">
                                          <label
                                            className="switch toggle-align"
                                            htmlFor="is_enable_products_search"
                                          >
                                            <input
                                              type="checkbox"
                                              className="toplogo"
                                              id="is_enable_products_search"
                                              checked={
                                                this.state
                                                  .is_enable_products_search
                                              }
                                              onChange={(e) =>
                                                this.handleis_enable_products_search(
                                                  e
                                                )
                                              }
                                            />
                                            <div className="slider round"></div>
                                          </label>
                                        </div>
                                      </div>
                                    )}
                                    </>
                                  : null
                                            }

                                    <div className="color_picker  align-items-center d-flex justify-content-between w-100 ">
                                      <h4 className="m-0 p-0">
                                        Enable post search in header
                                      </h4>
                                      <div className="on_off d-flex  align-items-center">
                                        <label
                                          className="switch toggle-align"
                                          htmlFor="is_enable_posts_search"
                                        >
                                          <input
                                            type="checkbox"
                                            className="toplogo"
                                            id="is_enable_posts_search"
                                            checked={
                                              this.state.is_enable_posts_search
                                            }
                                            onChange={(e) =>
                                              this.handleis_enable_posts_search(
                                                e
                                              )
                                            }
                                          />
                                          <div className="slider round"></div>
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* dashboard header end*/}
                        {/* Section Data */}
                        <div>
                          {((parseInt(localStorage.getItem("website_technology"))) === 4 ||  (parseInt(localStorage.getItem("website_technology"))) === 5) && (
                              <div>
                                <div className="d-flex  align-items-center justify-content-between common-height dashboardheadrcss new-gear-padding ipad-gear-padding">
                                  {/* Tool tip */}
                                  <Tooltip
                                    firsttag={"h5"}
                                    tagclass={"mb-0 mr-2"}
                                    classdata={
                                      "module_title d-flex justify-content-start align-items-center"
                                    }
                                    heading={"Section Colors"}
                                    content={
                                      "Select color scheme for app sections."
                                    }
                                  />
                                  {/* Tool tip */}
                                  <div className="on_off d-flex  align-items-center">
                                    {this.state.secshow ? (
                                      <div className="edit-btn edit-btn-new">
                                        <Link
                                          to="#"
                                          onClick={(e) => this.secchangeshow(e)}
                                        >
                                          <img
                                            className="mr-1 mr-md-2 ipad-0"
                                            src={
                                              require("../../assets/images/dashboard/gear-icon.png")
                                                .default
                                            }
                                            alt="gear"
                                          />
                                          <span>Settings</span>
                                        </Link>
                                      </div>
                                    ) : (
                                      <div className="edit-btn edit-btn-new">
                                        <Link
                                          to="#"
                                          onClick={(e) => this.secchangeshow(e)}
                                        >
                                          <img
                                            className="mr-1 mr-md-2 ipad-0"
                                            src={
                                              require("../../assets/images/dashboard/gear-icon.png")
                                                .default
                                            }
                                            alt="gear"
                                          />
                                          <span>Settings</span>
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                </div>
                                <div>
                                  <div
                                    className={
                                      this.state.secshow
                                        ? "upload_files d-flex align-items-center justify-content-between flex-wrap pt-3 pl-md-5"
                                        : "upload_files  d-none"
                                    }
                                  >
                                    <div className="color_picker mb-4">
                                      {/* Tool tip */}
                                      <Tooltip
                                        firsttag={"h4"}
                                        tagclass={"mb-0 mr-2"}
                                        classdata={
                                          "d-flex justify-content-start align-items-center mb-1"
                                        }
                                        heading={"Background Color"}
                                        content={
                                          "Select background color for sections."
                                        }
                                      />
                                      {/* Tool tip */}

                                      <Reactcolorpicker
                                        colorname={
                                          this.state.sectionBackgroundcolor
                                        }
                                        presetColors={this.state.presetColors}
                                        onChange={
                                          this.handlesectionBackgroundcolor
                                        }
                                        classselection={"fixback"}
                                        valuecontrol={false}
                                      />
                                    </div>
                                    <div className="color_picker mb-4">
                                      {/* Tool tip */}
                                      <Tooltip
                                        firsttag={"h4"}
                                        tagclass={"mb-0 mr-2"}
                                        classdata={
                                          "d-flex justify-content-start align-items-center mb-1"
                                        }
                                        heading={"Heading Color"}
                                        content={
                                          "Select font color for section title."
                                        }
                                      />
                                      {/* Tool tip */}
                                      <Reactcolorpicker
                                        colorname={
                                          this.state.sectionHeadingcolor
                                        }
                                        presetColors={this.state.presetColors}
                                        onChange={
                                          this.handlesectionHeadingcolor
                                        }
                                        classselection={"fixback"}
                                        valuecontrol={true}
                                      />
                                    </div>
                                    <div className="color_picker mb-4 mb-md-0">
                                      {/* Tool tip */}
                                      <Tooltip
                                        firsttag={"h4"}
                                        tagclass={"mb-0 mr-2"}
                                        classdata={
                                          "d-flex justify-content-start align-items-center mb-1"
                                        }
                                        heading={"Icon background color"}
                                        content={
                                          "Select color for icon background."
                                        }
                                      />
                                      {/* Tool tip */}
                                      <div className="form-group">
                                        <Reactcolorpicker
                                          colorname={
                                            this.state.sectionbuttoncolor
                                          }
                                          presetColors={this.state.presetColors}
                                          onChange={
                                            this.handlesectionbuttoncolor
                                          }
                                          classselection={"fixback"}
                                          valuecontrol={false}
                                        />
                                      </div>
                                    </div>
                                    <div className="color_picker mb-1 mb-md-0">
                                      {/* Tool tip */}
                                      <Tooltip
                                        firsttag={"h4"}
                                        tagclass={"mb-0 mr-2"}
                                        classdata={
                                          "d-flex justify-content-start align-items-center mb-1"
                                        }
                                        heading={"Icon arrow color"}
                                        content={
                                          "Select  color for icon arrow."
                                        }
                                      />
                                      {/* Tool tip */}
                                      <div className="form-group">
                                        <Reactcolorpicker
                                          colorname={
                                            this.state.sectionbuttontextcolor
                                          }
                                          presetColors={this.state.presetColors}
                                          onChange={
                                            this.handlesectionbuttontextcolor
                                          }
                                          classselection={"fixback"}
                                          valuecontrol={true}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}

                          {/* Section End */}
                        </div>

                        <div
                          className="green_toggle bill-pay-top-light"
                          ref={
                            this.state.mobileref === true
                              ? this.enddraual
                              : this.state.Catshow === false
                              ? this.dragulaDecorator
                              : this.enddraual
                          }
                        >
                          {/* Main Banner Data */}
                          <div
                            className="dashboard-customizer main_banner"
                            ref={this.setWrapperRef}
                          >
                            <div className="d-flex  align-items-center justify-content-between common-height padding-15 p-md-0">
                              {/* Tool tip */}
                              <Tooltip
                                firsttag={"h5"}
                                tagclass={"mb-0 mr-2"}
                                classdata={
                                  "module_title mobleft d-flex justify-content-start align-items-center"
                                }
                                heading={"Main Banner"}
                                content={
                                  "Design main banner & drag to reposition."
                                }
                              />
                              {/* Tool tip */}
                              <div className="on_off d-flex  align-items-center">
                                {this.state.show ? (
                                  <div className="edit-btn edit-btn-new mr-4">
                                    <Link
                                      to="#"
                                      onClick={(e) => this.changeshow(e)}
                                    >
                                      <img
                                        className="mr-1 mr-md-2"
                                        src={
                                          require("../../assets/images/dashboard/gear-icon.png")
                                            .default
                                        }
                                        alt="gear"
                                      />
                                      <span> Settings</span>
                                    </Link>
                                  </div>
                                ) : (
                                  <div className="edit-btn edit-btn-new mr-4">
                                    <Link
                                      to="#"
                                      onClick={(e) => this.changeshow(e)}
                                    >
                                      <img
                                        className="mr-1 mr-md-2"
                                        src={
                                          require("../../assets/images/dashboard/gear-icon.png")
                                            .default
                                        }
                                        alt="gear"
                                      />
                                      <span> Settings</span>
                                    </Link>
                                  </div>
                                )}
                                <label
                                  className="switch toggle-align"
                                  htmlFor="mainBanner"
                                >
                                  <input
                                    type="checkbox"
                                    className="toplogo"
                                    id="mainBanner"
                                    defaultChecked={this.state.bannerCheckbox}
                                    onClick={this.handleBannerCheckbox}
                                  />
                                  <div className="slider round"></div>
                                </label>
                              </div>
                            </div>
                            <div
                              className={
                                this.state.show
                                  ? "upload_files d-flex align-items-center justify-content-between flex-wrap flex-wrap pt-3 pb-3"
                                  : "upload_files d-none align-items-center justify-content-between flex-wrap "
                              }
                            >
                              <div className="customwidth mb-4">
                                {/* Tool tip */}
                                <Tooltip
                                  firsttag={"h4"}
                                  tagclass={"mb-0 mr-2"}
                                  classdata={
                                    "d-flex justify-content-start align-items-center mb-1"
                                  }
                                  heading={"Select Banner Image"}
                                  content={"Select image for main banner."}
                                />
                                {/* Tool tip */}
                                <div className="large-up-back">
                                  <label>
                                    <div className="up" onClick={this.toggle}>
                                      {this.state.selectpixabayimage === "" ||
                                      this.state.selectpixabayimage === null ? (
                                        <div className="d-flex align-items-center">
                                          <div className="mr-2">
                                            <div
                                              className="selectpixabayimage"
                                              style={{
                                                background:
                                                  "url(" +
                                                  require("../../assets/images/dashboard/small-up.png")
                                                    .default +
                                                  ")",
                                              }}
                                            ></div>
                                          </div>
                                          <div>
                                            <p
                                              className="m-0 p-0"
                                              style={{
                                                fontSize: "14px",
                                                color: "#C8CEDB",
                                              }}
                                            >
                                              Minimum size 1125*480px
                                            </p>
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="d-flex align-items-center">
                                          <div className="mr-2">
                                            <div
                                              className="selectpixabayimage"
                                              style={{
                                                background:
                                                  "url(" +
                                                  this.state
                                                    .selectpixabayimage +
                                                  ")",
                                              }}
                                            ></div>
                                          </div>
                                          <div className="upload-long-text">
                                            <p
                                              className="m-0 p-0"
                                              style={{
                                                fontSize: "14px",
                                                color: "#1B2952",
                                              }}
                                            >
                                              {this.state.imagename}
                                            </p>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </label>
                                </div>
                                {this.state.spinner === false &&
                                  this.state.selectpixabayimage !== "" &&
                                  this.state.selectpixabayimage !== null && (
                                    <div className="green_checkmark custommark">
                                      <label className="container1">
                                        <input
                                          type="checkbox"
                                          disabled
                                          checked
                                        />{" "}
                                        <span className="checkmark"></span>
                                      </label>
                                    </div>
                                  )}
                              </div>
                              <div className="customwidthor">
                                <p>Or</p>
                              </div>
                              <div className="customwidth mb-4">
                                {/* Tool tip */}
                                <Tooltip
                                  firsttag={"h4"}
                                  tagclass={"mb-0 mr-2"}
                                  classdata={
                                    "d-flex justify-content-start align-items-center mb-1"
                                  }
                                  heading={"Select solid color"}
                                  content={"Select color fill for main banner."}
                                />
                                {/* Tool tip */}

                                <Reactcolorpicker
                                  colorname={this.state.splashBackground}
                                  presetColors={this.state.presetColors}
                                  onChange={this.changecolor}
                                  classselection={"fixback"}
                                  valuecontrol={false}
                                />
                              </div>
                              <div className="color_picker_full mb-4">
                                <div className="form-group">
                                  {/* Tool tip */}
                                  <Tooltip
                                    firsttag={"h4"}
                                    tagclass={"mb-0 mr-2"}
                                    classdata={
                                      "d-flex justify-content-start align-items-center mb-1"
                                    }
                                    heading={"Banner text"}
                                    content={
                                      "This text appears on banner image."
                                    }
                                  />
                                  {/* Tool tip */}
                                  <input
                                    type="text"
                                    maxLength="30"
                                    className="form-control com-place form-name"
                                    value={this.state.bannertext}
                                    onChange={(e) => this.handlebannerText(e)}
                                  />
                                </div>
                              </div>
                              <div className="color_picker mb-4">
                                {/* Tool tip */}
                                <Tooltip
                                  firsttag={"h4"}
                                  tagclass={"mb-0 mr-2"}
                                  classdata={
                                    "d-flex justify-content-start align-items-center mb-1"
                                  }
                                  heading={"Font"}
                                  content={"Select font for banner text."}
                                />
                                {/* Tool tip */}
                                <div className="form-group">
                                  <DropdownButton
                                    className="font-dropdown"
                                    alignRight
                                    title={this.state.bannertextfontfamily}
                                    id="dropdown-menu-align-right"
                                    style={{
                                      fontFamily:
                                        this.state.bannertextfontfamily,
                                    }}
                                    value={this.state.bannertextfontfamily}
                                    onSelect={(e) =>
                                      this.handlebannertextfontfamily(e)
                                    }
                                  >
                                    <Dropdown.Item
                                      style={{
                                        fontFamily: "Arial",
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "Arial"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "Arial"
                                            ? "white"
                                            : "",
                                      }}
                                      eventKey="Arial"
                                    >
                                      Arial
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      style={{
                                        fontFamily: "Arial-Black",
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "Arial-Black"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "Arial-Black"
                                            ? "white"
                                            : "",
                                      }}
                                      eventKey="Arial-Black"
                                    >
                                      Arial Black
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      style={{
                                        fontFamily: "bainsley-regular",
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "bainsley-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "bainsley-regular"
                                            ? "white"
                                            : "",
                                      }}
                                      eventKey="bainsley-regular"
                                    >
                                      Bainsley-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      style={{
                                        fontFamily: "bainsley-bold",
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "bainsley-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "bainsley-bold"
                                            ? "white"
                                            : "",
                                      }}
                                      eventKey="bainsley-bold"
                                    >
                                      Bainsley Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      style={{
                                        fontFamily: "barracuda-regular",
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "barracuda-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "barracuda-regular"
                                            ? "white"
                                            : "",
                                      }}
                                      eventKey="barracuda-regular"
                                    >
                                      Barracuda Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      style={{
                                        fontFamily: "barracuda-bold",
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "barracuda-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "barracuda-bold"
                                            ? "white"
                                            : "",
                                      }}
                                      eventKey="barracuda-bold"
                                    >
                                      Barracuda Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="betelgeuse-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "betelgeuse-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "betelgeuse-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "betelgeuse-regular",
                                      }}
                                    >
                                      {" "}
                                      Betelgeuse-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="betelgeuse-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "betelgeuse-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "betelgeuse-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "betelgeuse-bold",
                                      }}
                                    >
                                      {" "}
                                      Betelgeuse-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Bookman"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "Bookman"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "Bookman"
                                            ? "white"
                                            : "",
                                        fontFamily: "Bookman",
                                      }}
                                    >
                                      {" "}
                                      Bookman
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="cactron-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "cactron-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "cactron-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "cactron-regular",
                                      }}
                                    >
                                      {" "}
                                      Cactron-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="cactronbold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "cactronbold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "cactronbold"
                                            ? "white"
                                            : "",
                                        fontFamily: "cactronbold",
                                      }}
                                    >
                                      {" "}
                                      Cactron-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="cokanut-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "cokanut-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "cokanut-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "cokanut-regular",
                                      }}
                                    >
                                      {" "}
                                      Cokanut-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="cokanut-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "cokanut-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "cokanut-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "cokanut-bold",
                                      }}
                                    >
                                      {" "}
                                      Cokanut-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="ComicSansMS"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "ComicSansMS"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "ComicSansMS"
                                            ? "white"
                                            : "",
                                        fontFamily: "ComicSansMS",
                                      }}
                                    >
                                      {" "}
                                      Comic Sans MS
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="cormorant-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "cormorant-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "cormorant-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "cormorant-regular",
                                      }}
                                    >
                                      {" "}
                                      Cormorant-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="cormorant-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "cormorant-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "cormorant-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "cormorant-bold",
                                      }}
                                    >
                                      {" "}
                                      Cormorant-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Courier-New"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "Courier-New"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "Courier-New"
                                            ? "white"
                                            : "",
                                        fontFamily: "Courier-New",
                                      }}
                                    >
                                      {" "}
                                      Courier New
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="dukascf-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "dukascf-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "dukascf-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "dukascf-regular",
                                      }}
                                    >
                                      {" "}
                                      Dukas Cf-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="dukascf-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "dukascf-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "dukascf-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "dukascf-bold",
                                      }}
                                    >
                                      {" "}
                                      Dukas Cf-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="emberly-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "emberly-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "emberly-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "emberly-regular",
                                      }}
                                    >
                                      {" "}
                                      Emberly-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="emberly-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "emberly-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "emberly-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "emberly-bold",
                                      }}
                                    >
                                      {" "}
                                      Emberly-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="epigrafica-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "epigrafica-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "epigrafica-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "epigrafica-regular",
                                      }}
                                    >
                                      {" "}
                                      Epigrafica-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="epigrafica-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "epigrafica-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "epigrafica-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "epigrafica-bold",
                                      }}
                                    >
                                      {" "}
                                      Epigrafica-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Garamond"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "Garamond"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "Garamond"
                                            ? "white"
                                            : "",
                                        fontFamily: "Garamond",
                                      }}
                                    >
                                      {" "}
                                      Garamond
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Georgia"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "Georgia"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "Georgia"
                                            ? "white"
                                            : "",
                                        fontFamily: "Georgia",
                                      }}
                                    >
                                      {" "}
                                      Georgia
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="grenze-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "grenze-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "grenze-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "grenze-regular",
                                      }}
                                    >
                                      {" "}
                                      Grenze-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="grenze-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "grenze-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "grenze-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "grenze-bold",
                                      }}
                                    >
                                      {" "}
                                      Grenze-Bold
                                    </Dropdown.Item>
                                    {/* <Dropdown.Item
                                      eventKey="hitmo20-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "hitmo20-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "hitmo20-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "hitmo20-regular",
                                      }}
                                    >
                                      {" "}
                                      Hitmo-Regular
                                    </Dropdown.Item> */}

                                    {/* <Dropdown.Item
                                      eventKey="hitmo20-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "hitmo20-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "hitmo20-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "hitmo20-bold",
                                      }}
                                    >
                                      Hitmo-Bold
                                    </Dropdown.Item> */}
                                    <Dropdown.Item
                                      eventKey="horizon-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "horizon-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "horizon-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "horizon-regular",
                                      }}
                                    >
                                      {" "}
                                      Horizon Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="horizon-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "horizon-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "horizon-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "horizon-bold",
                                      }}
                                    >
                                      {" "}
                                      Horizon Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Impact"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "Impact"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "Impact"
                                            ? "white"
                                            : "",
                                        fontFamily: "Impact",
                                      }}
                                    >
                                      {" "}
                                      Impact
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="karantina-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "karantina-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "karantina-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "karantina-regular",
                                      }}
                                    >
                                      {" "}
                                      Karantina-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="karantina-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "karantina-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "karantina-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "karantina-bold",
                                      }}
                                    >
                                      {" "}
                                      Karantina-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="kubanpro-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "kubanpro-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "kubanpro-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "kubanpro-regular",
                                      }}
                                    >
                                      {" "}
                                      Kuban Pro Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="kubanpro-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "kubanpro-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "kubanpro-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "kubanpro-bold",
                                      }}
                                    >
                                      {" "}
                                      Kuban Pro Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="kufam-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "kufam-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "kufam-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "kufam-regular",
                                      }}
                                    >
                                      {" "}
                                      Kufam-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="kufam-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "kufam-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "kufam-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "kufam-bold",
                                      }}
                                    >
                                      {" "}
                                      Kufam-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="mangoGrotesque-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "mangoGrotesque-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "mangoGrotesque-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "mangoGrotesque-regular",
                                      }}
                                    >
                                      {" "}
                                      MangoGrotesque-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="mangoGrotesque-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "mangoGrotesque-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "mangoGrotesque-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "mangoGrotesque-bold",
                                      }}
                                    >
                                      {" "}
                                      MangoGrotesque-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="mesheddisplay-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "mesheddisplay-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "mesheddisplay-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "mesheddisplay-regular",
                                      }}
                                    >
                                      {" "}
                                      Meshed Display-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="mesheddisplay-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "mesheddisplay-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "mesheddisplay-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "mesheddisplay-bold",
                                      }}
                                    >
                                      {" "}
                                      Meshed Display-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="nymeria-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "nymeria-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "nymeria-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "nymeria-regular",
                                      }}
                                    >
                                      {" "}
                                      Nymeria-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="nymeria-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "nymeria-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "nymeria-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "nymeria-bold",
                                      }}
                                    >
                                      {" "}
                                      Nymeria-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Palatino"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "Palatino"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "Palatino"
                                            ? "white"
                                            : "",
                                        fontFamily: "Palatino",
                                      }}
                                    >
                                      {" "}
                                      Palatino
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="piazzolla-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "piazzolla-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "piazzolla-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "piazzolla-regular",
                                      }}
                                    >
                                      {" "}
                                      Piazzolla-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="piazzolla-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "piazzolla-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "piazzolla-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "piazzolla-bold",
                                      }}
                                    >
                                      {" "}
                                      Piazzolla-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Poppins-SemiBold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "Poppins-SemiBold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "Poppins-SemiBold"
                                            ? "white"
                                            : "",
                                        fontFamily: "Poppins-SemiBold",
                                      }}
                                    >
                                      {" "}
                                      Poppins SemiBold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="radiocanada-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "radiocanada-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "radiocanada-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "radiocanada-regular",
                                      }}
                                    >
                                      {" "}
                                      Radio Canada-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="radiocanada-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "radiocanada-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "radiocanada-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "radiocanada-bold",
                                      }}
                                    >
                                      {" "}
                                      Radio Canada-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="rebeqa-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "rebeqa-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "rebeqa-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "rebeqa-regular",
                                      }}
                                    >
                                      {" "}
                                      Rebeqa Font-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="rebeqa-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "rebeqa-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "rebeqa-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "rebeqa-bold",
                                      }}
                                    >
                                      {" "}
                                      Rebeqa Font-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="rogerex-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "rogerex-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "rogerex-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "rogerex-regular",
                                      }}
                                    >
                                      {" "}
                                      Rogerex-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="rogerex-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "rogerex-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "rogerex-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "rogerex-bold",
                                      }}
                                    >
                                      {" "}
                                      Rogerex-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="RozinaV04Regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "RozinaV04Regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "RozinaV04Regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "RozinaV04Regular",
                                      }}
                                    >
                                      {" "}
                                      Rozina-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="RozinaV06Bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "RozinaV06Bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "RozinaV06Bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "RozinaV06Bold",
                                      }}
                                    >
                                      {" "}
                                      Rozina-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Sans-Serif"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "Sans-Serif"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "Sans-Serif"
                                            ? "white"
                                            : "",
                                        fontFamily: "Sans-Serif",
                                      }}
                                    >
                                      {" "}
                                      Sans Serif
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="TimesNewRomanPSMT"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "TimesNewRomanPSMT"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "TimesNewRomanPSMT"
                                            ? "white"
                                            : "",
                                        fontFamily: "TimesNewRomanPSMT",
                                      }}
                                    >
                                      {" "}
                                      Times New Roman
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Trebuchet-MS"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "Trebuchet-MS"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "Trebuchet-MS"
                                            ? "white"
                                            : "",
                                        fontFamily: "Trebuchet-MS",
                                      }}
                                    >
                                      {" "}
                                      Trebuchet MS
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="unbounded-regular"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "unbounded-regular"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "unbounded-regular"
                                            ? "white"
                                            : "",
                                        fontFamily: "unbounded-regular",
                                      }}
                                    >
                                      {" "}
                                      Unbounded Polkadot-Regular
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="unbounded-bold"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "unbounded-bold"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "unbounded-bold"
                                            ? "white"
                                            : "",
                                        fontFamily: "unbounded-bold",
                                      }}
                                    >
                                      {" "}
                                      Unbounded Polkadot-Bold
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      eventKey="Verdana"
                                      style={{
                                        background:
                                          this.state.bannertextfontfamily ==
                                          "Verdana"
                                            ? "#547df2"
                                            : "",
                                        color:
                                          this.state.bannertextfontfamily ==
                                          "Verdana"
                                            ? "white"
                                            : "",
                                        fontFamily: "Verdana",
                                      }}
                                    >
                                      {" "}
                                      Verdana
                                    </Dropdown.Item>
                                  </DropdownButton>
                                </div>
                              </div>
                              <div className="color_picker mb-4">
                                {/* Tool tip */}
                                <Tooltip
                                  firsttag={"h4"}
                                  tagclass={"mb-0 mr-2"}
                                  classdata={
                                    "d-flex justify-content-start align-items-center mb-1"
                                  }
                                  heading={"Font Size"}
                                  content={"Select font size for banner text."}
                                />
                                {/* Tool tip */}
                                <div className="form-group">
                                  <select
                                    className="form-control"
                                    value={this.state.bannerfontSize}
                                    onChange={(e) =>
                                      this.handlebannerfontsize(e)
                                    }
                                  >
                                    <option value="15px">15</option>
                                    <option value="16px">16</option>
                                    <option value="17px">17</option>
                                    <option value="18px">18</option>
                                    <option value="19px">19</option>
                                    <option value="20px">20</option>
                                    <option value="21px">21</option>
                                    <option value="22px">22</option>
                                    <option value="23px">23</option>
                                    <option value="24px">24</option>
                                  </select>
                                </div>
                              </div>
                              <div className="color_picker mb-4 mb-md-0">
                                {/* Tool tip */}
                                <Tooltip
                                  firsttag={"h4"}
                                  tagclass={"mb-0 mr-2"}
                                  classdata={
                                    "d-flex justify-content-start align-items-center mb-1"
                                  }
                                  heading={"Color"}
                                  content={"Select color for banner text."}
                                />
                                {/* Tool tip */}
                                <Reactcolorpicker
                                  colorname={this.state.bannertextColor}
                                  presetColors={this.state.presetColors}
                                  onChange={this.handlebannertextColor}
                                  classselection={"fixback topfixback"}
                                  valuecontrol={true}
                                />
                              </div>
                              <div className="color_picker mb-2 mb-md-0">
                                {/* Tool tip */}
                                <Tooltip
                                  firsttag={"h4"}
                                  tagclass={"mb-0 mr-2"}
                                  classdata={
                                    "d-flex justify-content-start align-items-center mb-1"
                                  }
                                  heading={"Align"}
                                  content={
                                    "Select alignment for main banner text."
                                  }
                                />
                                {/* Tool tip */}
                                <div className="horizontal-alignment d-flex align-items-center">
                                  <div
                                    onClick={(e) =>
                                      this.handlebannertextAlign("flex-start")
                                    }
                                    className="aligndiv"
                                  >
                                    <div className="d-flex align-items-center justify-content-center align align-left">
                                      <svg
                                        width="16.461"
                                        height="20"
                                        viewBox="0 0 16.461 20"
                                      >
                                        <g
                                          id="Group_2108"
                                          data-name="Group 2108"
                                          transform="translate(16.461) rotate(90)"
                                        >
                                          <rect
                                            id="Rectangle_1490"
                                            data-name="Rectangle 1490"
                                            width="6"
                                            height="13"
                                            transform="translate(2.5)"
                                            fill={
                                              this.state.bannertextAlign ===
                                              "flex-start"
                                                ? "#7782a1"
                                                : "#c8cedb"
                                            }
                                          />
                                          <rect
                                            id="Rectangle_1491"
                                            data-name="Rectangle 1491"
                                            width="6"
                                            height="7"
                                            transform="translate(11.5 6)"
                                            fill={
                                              this.state.bannertextAlign ===
                                              "flex-start"
                                                ? "#7782a1"
                                                : "#c8cedb"
                                            }
                                          />
                                          <path
                                            id="Path_6061"
                                            data-name="Path 6061"
                                            d="M-14517.724-3751.961h20"
                                            transform="translate(14517.724 3767.922)"
                                            fill="none"
                                            stroke={
                                              this.state.bannertextAlign ===
                                              "flex-start"
                                                ? "#7782a1"
                                                : "#c8cedb"
                                            }
                                            strokeWidth="1"
                                          />
                                        </g>
                                      </svg>
                                      <p className="ml-2">Left</p>
                                    </div>
                                  </div>
                                  <div
                                    onClick={(e) =>
                                      this.handlebannertextAlign("center")
                                    }
                                    className="aligndiv"
                                  >
                                    <div className="d-flex align-items-center justify-content-center align align-center">
                                      <svg
                                        width="13"
                                        height="20"
                                        viewBox="0 0 13 20"
                                      >
                                        <g
                                          id="Group_2107"
                                          data-name="Group 2107"
                                          transform="translate(863 -1002.5) rotate(90)"
                                        >
                                          <rect
                                            id="Rectangle_1490"
                                            data-name="Rectangle 1490"
                                            width="6"
                                            height="13"
                                            transform="translate(1005 850)"
                                            fill={
                                              this.state.bannertextAlign ===
                                              "center"
                                                ? "#7782a1"
                                                : "#c8cedb"
                                            }
                                          />
                                          <rect
                                            id="Rectangle_1491"
                                            data-name="Rectangle 1491"
                                            width="6"
                                            height="7"
                                            transform="translate(1014 853)"
                                            fill={
                                              this.state.bannertextAlign ===
                                              "center"
                                                ? "#7782a1"
                                                : "#c8cedb"
                                            }
                                          />
                                          <path
                                            id="Path_6061"
                                            data-name="Path 6061"
                                            d="M-14517.724-3751.961h20"
                                            transform="translate(15520.224 4608.461)"
                                            fill="none"
                                            stroke={
                                              this.state.bannertextAlign ===
                                              "center"
                                                ? "#7782a1"
                                                : "#c8cedb"
                                            }
                                            strokeWidth="1"
                                          />
                                        </g>
                                      </svg>
                                      <p className="ml-2">Center</p>
                                    </div>
                                  </div>
                                  <div
                                    onClick={(e) =>
                                      this.handlebannertextAlign("flex-end")
                                    }
                                    className="aligndiv"
                                  >
                                    <div className="d-flex align-items-center justify-content-center align">
                                      <svg
                                        width="16.461"
                                        height="20"
                                        viewBox="0 0 16.461 20"
                                      >
                                        <g
                                          id="Group_2106"
                                          data-name="Group 2106"
                                          transform="translate(864 -1002.5) rotate(90)"
                                        >
                                          <rect
                                            id="Rectangle_1490"
                                            data-name="Rectangle 1490"
                                            width="6"
                                            height="13"
                                            transform="translate(1005 851)"
                                            fill={
                                              this.state.bannertextAlign ===
                                              "flex-end"
                                                ? "#7782a1"
                                                : "#c8cedb"
                                            }
                                          />
                                          <rect
                                            id="Rectangle_1491"
                                            data-name="Rectangle 1491"
                                            width="6"
                                            height="7"
                                            transform="translate(1014 851)"
                                            fill={
                                              this.state.bannertextAlign ===
                                              "flex-end"
                                                ? "#7782a1"
                                                : "#c8cedb"
                                            }
                                          />
                                          <path
                                            id="Path_6061"
                                            data-name="Path 6061"
                                            d="M-14517.724-3751.961h20"
                                            transform="translate(15520.224 4600)"
                                            fill="none"
                                            stroke={
                                              this.state.bannertextAlign ===
                                              "flex-end"
                                                ? "#7782a1"
                                                : "#c8cedb"
                                            }
                                            strokeWidth="1"
                                          />
                                        </g>
                                      </svg>
                                      <p className="ml-2">Right</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* Main Banner data End */}
                          {
                          parseInt(
                            localStorage.getItem("website_technology")
                          ) === 5  ? (
                            <>
                              {/* Category Data */}

                              <div
                                className="dashboard-customizer product_categories"
                                ref={this.setWrapperRef}
                              >
                                <div className="d-flex  align-items-center justify-content-between common-height padding-15 p-md-0">
                                  <div className="module_title mobleft d-flex justify-content-start align-items-center">
                                    <h5>
                                      Product Categories{" "}
                                      <span className="salebadge">
                                        {this.state.product_categories_label ===
                                          "Product Categories" ||
                                        this.state.product_categories_label ===
                                          ""
                                          ? null
                                          : "(" +
                                            this.state
                                              .product_categories_label +
                                            ")"}
                                      </span>
                                    </h5>
                                    <div
                                      className="ml-2 editlabel"
                                      onClick={(e) =>
                                        this.product_categories_setting(e)
                                      }
                                    >
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/edit.png")
                                            .default
                                        }
                                        alt="edit sale"
                                      />
                                    </div>
                                  </div>

                                  <div className="on_off d-flex  align-items-center">
                                    {this.state.Catshow ? (
                                      <div className="edit-btn edit-btn-new mr-4">
                                        <Link
                                          to="#"
                                          onClick={(e) => this.catchangeshow(e)}
                                        >
                                          <img
                                            className="mr-1 mr-md-2"
                                            src={
                                              require("../../assets/images/dashboard/gear-icon.png")
                                                .default
                                            }
                                            alt="gear"
                                          />
                                          <span> Settings</span>
                                        </Link>
                                      </div>
                                    ) : (
                                      <div className="edit-btn edit-btn-new mr-4">
                                        <Link
                                          to="#"
                                          onClick={(e) => this.catchangeshow(e)}
                                        >
                                          <img
                                            className="mr-1 mr-md-2"
                                            src={
                                              require("../../assets/images/dashboard/gear-icon.png")
                                                .default
                                            }
                                            alt="gear"
                                          />
                                          <span>Settings</span>
                                        </Link>
                                      </div>
                                    )}
                                    <label
                                      className="switch toggle-align"
                                      htmlFor="categories"
                                    >
                                      <input
                                        type="checkbox"
                                        className="toplogo"
                                        id="categories"
                                        defaultChecked={
                                          this.state.categoriesCheckbox
                                        }
                                        onClick={this.handlecategoriesCheckbox}
                                      />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>
                                </div>
                                {/* Label change div */}
                                <div
                                  className={
                                    this.state.product_categories_setting
                                      ? "upload_files pb-3 mt-mb-0 "
                                      : "upload_files  d-none"
                                  }
                                >
                                  <div className=" mb-2">
                                    <h4>Rename</h4>
                                    <div className="form-group ">
                                      <input
                                        type="text"
                                        placeholder="Product Categories"
                                        name="product_categories_label"
                                        className="form-control "
                                        maxLength={this.state.characterlimit}
                                        value={
                                          this.state.product_categories_label
                                        }
                                        onChange={(e) =>
                                          this.handlelabelname(e)
                                        }
                                      />
                                      <span className="label_input">
                                        {this.state.product_categories_label
                                          .length === 0
                                          ? "Rename field cannot be empty"
                                          : null}
                                      </span>
                                    </div>
                                  </div>
                                </div>

                                {/* Label change div end */}
                                <div
                                  className={
                                    this.state.Catshow
                                      ? "upload_files pt-3 pb-3 mt-3 mt-mb-0"
                                      : "upload_files  d-none"
                                  }
                                >
                                  <div>
                                    {/* Show Coulmns */}
                                    <div className="d-flex justify-content-between flex-wrap">
                                      <div className="color_picker mb-4">
                                        <h4>Column</h4>
                                        <div className="form-group">
                                          <select
                                            className="form-control"
                                            onChange={(e) =>
                                              this.handlecategories_columns(e)
                                            }
                                            value={
                                              this.state.categories_columns
                                            }
                                            onKeyDown={(e) =>
                                              this.handleKeyDown(e)
                                            }
                                          >
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                          </select>
                                        </div>
                                      </div>
                                      {/* <div className="color_picker  text-right">
													<h4>Recommended : Version 1.3.2 and above</h4>
													
												</div>	 */}
                                    </div>
                                    <div className="two-btn-tabs">
                                      <div className="text-center">
                                        <Nav
                                          tabs
                                          className="nav nav-pills nav-justified categoryimage"
                                        >
                                          <NavItem>
                                            <NavLink
                                              className={classnames({
                                                active:
                                                  this.state.activeTab === "1",
                                              })}
                                              onClick={() => {
                                                this.tabtoggle("1");
                                              }}
                                            >
                                              <div className="big-tab big-tab-1">
                                                <div className="d-block d-md-flex align-items-center justify-content-start p-md-4 p-3 categories-dashboard">
                                                  <div
                                                    className="small-help"
                                                    onClick={
                                                      this
                                                        .togglemodalBackgroundImage
                                                    }
                                                  >
                                                    <svg
                                                      width="15"
                                                      height="15"
                                                      viewBox="0 0 17 17"
                                                    >
                                                      <g
                                                        id="Group_2842"
                                                        data-name="Group 2842"
                                                        transform="translate(-1057.848 -227.299)"
                                                      >
                                                        <circle
                                                          id="Ellipse_213"
                                                          data-name="Ellipse 213"
                                                          cx="8"
                                                          cy="8"
                                                          r="8"
                                                          transform="translate(1058.348 227.799)"
                                                          fill="none"
                                                          stroke="#7782a1"
                                                          strokeMiterlimit="10"
                                                          strokeWidth="1"
                                                        />
                                                        <path
                                                          id="Path_6038"
                                                          data-name="Path 6038"
                                                          d="M1117.679,269.735a2.423,2.423,0,1,1,3.16,2.309,1.05,1.05,0,0,0-.737,1v.712"
                                                          transform="translate(-53.507 -35.558)"
                                                          fill="none"
                                                          stroke="#7782a1"
                                                          strokeMiterlimit="10"
                                                          strokeWidth="1"
                                                        />
                                                        <line
                                                          id="Line_3"
                                                          data-name="Line 3"
                                                          y2="1.047"
                                                          transform="translate(1066.592 239.465)"
                                                          fill="none"
                                                          stroke="#7782a1"
                                                          strokeMiterlimit="10"
                                                          strokeWidth="1"
                                                        />
                                                      </g>
                                                    </svg>
                                                  </div>
                                                  <div className="cate">
                                                    {/* Category modal */}
                                                    {catemodalfirst()}
                                                    {/* Category modal end*/}
                                                  </div>
                                                  <div className="pl-md-3">
                                                    <h4>
                                                      Categories with image
                                                    </h4>
                                                    <p className="pl-0 pt-1">
                                                      Import categories from
                                                      your online store with
                                                      images.
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </NavLink>
                                          </NavItem>
                                          <NavItem>
                                            <NavLink
                                              className={classnames({
                                                active:
                                                  this.state.activeTab === "2",
                                              })}
                                              onClick={() => {
                                                this.tabtoggle("2");
                                              }}
                                            >
                                              <div className="big-tab big-tab-2">
                                                <div className="d-block d-md-flex align-items-center justify-content-start p-md-4 p-3 categories-dashboard">
                                                  <div
                                                    className="small-help"
                                                    onClick={
                                                      this.togglemodalSolidColor
                                                    }
                                                  >
                                                    <svg
                                                      width="15"
                                                      height="15"
                                                      viewBox="0 0 17 17"
                                                    >
                                                      <g
                                                        id="Group_2842"
                                                        data-name="Group 2842"
                                                        transform="translate(-1057.848 -227.299)"
                                                      >
                                                        <circle
                                                          id="Ellipse_213"
                                                          data-name="Ellipse 213"
                                                          cx="8"
                                                          cy="8"
                                                          r="8"
                                                          transform="translate(1058.348 227.799)"
                                                          fill="none"
                                                          stroke="#7782a1"
                                                          strokeMiterlimit="10"
                                                          strokeWidth="1"
                                                        />
                                                        <path
                                                          id="Path_6038"
                                                          data-name="Path 6038"
                                                          d="M1117.679,269.735a2.423,2.423,0,1,1,3.16,2.309,1.05,1.05,0,0,0-.737,1v.712"
                                                          transform="translate(-53.507 -35.558)"
                                                          fill="none"
                                                          stroke="#7782a1"
                                                          strokeMiterlimit="10"
                                                          strokeWidth="1"
                                                        />
                                                        <line
                                                          id="Line_3"
                                                          data-name="Line 3"
                                                          y2="1.047"
                                                          transform="translate(1066.592 239.465)"
                                                          fill="none"
                                                          stroke="#7782a1"
                                                          strokeMiterlimit="10"
                                                          strokeWidth="1"
                                                        />
                                                      </g>
                                                    </svg>
                                                  </div>
                                                  <div className="cate">
                                                    {/* Category modal */}
                                                    {catemodalsecond()}
                                                    {/* Category modal end*/}
                                                  </div>
                                                  <div className="pl-md-3">
                                                    <h4>
                                                      Categories with Color
                                                    </h4>
                                                    <p className="pl-0 pt-1">
                                                      Style the category display
                                                      for mobile app with
                                                      attractive colors.
                                                    </p>
                                                  </div>
                                                </div>
                                              </div>
                                            </NavLink>
                                          </NavItem>
                                        </Nav>
                                      </div>
                                    </div>
                                    {/* Toggle */}
                                    {this.state.activeTab === "1" && (
                                      <div className="d-flex  align-items-center justify-content-between mt-4 mb-4">
                                        <div className="color_picker  align-items-center d-flex justify-content-between">
                                          <h4 className="m-0 p-0">
                                            Show product category names
                                          </h4>

                                          <div className="on_off d-flex  align-items-center">
                                            <label
                                              className="switch toggle-align"
                                              htmlFor="categoryname"
                                            >
                                              <input
                                                type="checkbox"
                                                className="toplogo"
                                                id="categoryname"
                                                checked={
                                                  this.state.categoryname
                                                }
                                                onChange={(e) =>
                                                  this.handlecategoryname(e)
                                                }
                                              />
                                              <div className="slider round"></div>
                                            </label>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <div className="d-flex justify-content-between flex-wrap mt-1">
                                      <div className="color_picker">
                                        <h4
                                          className={
                                            this.state.activeTab === "1"
                                              ? "mb-0 mr-2 mb-2 pt-1"
                                              : "mb-0 mr-2 mt-4 mb-2 pt-1"
                                          }
                                        >
                                          Shapes
                                        </h4>
                                        <div className="horizontal-alignment d-flex align-items-center">
                                          <div
                                            onClick={(e) =>
                                              this.handlecategories_shape(
                                                "sharp_corner"
                                              )
                                            }
                                            className="aligndiv"
                                          >
                                            <div className="d-flex align-items-center justify-content-center align align-left">
                                              <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                              >
                                                <g
                                                  data-name="Rectangle 4346"
                                                  fill="#fff"
                                                  stroke={
                                                    this.state
                                                      .categories_shape ===
                                                    "sharp_corner"
                                                      ? "#7782A1"
                                                      : "#c8cedb"
                                                  }
                                                  strokeWidth="2"
                                                >
                                                  <rect
                                                    width="24"
                                                    height="24"
                                                    stroke="none"
                                                  />
                                                  <rect
                                                    x="1"
                                                    y="1"
                                                    width="22"
                                                    height="22"
                                                    fill="none"
                                                  />
                                                </g>
                                              </svg>
                                              <p className="ml-2">Sharp</p>
                                            </div>
                                          </div>
                                          <div
                                            onClick={(e) =>
                                              this.handlecategories_shape(
                                                "round_corner"
                                              )
                                            }
                                            className="aligndiv"
                                          >
                                            <div
                                              className="d-flex align-items-center justify-content-center align align-center"
                                              style={{
                                                borderRight:
                                                  this.state
                                                    .categories_columns ===
                                                    "1" && "none",
                                              }}
                                            >
                                              <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                              >
                                                <g
                                                  data-name="Rectangle 4347"
                                                  fill="#fff"
                                                  stroke={
                                                    this.state
                                                      .categories_shape ===
                                                    "round_corner"
                                                      ? "#7782A1"
                                                      : "#c8cedb"
                                                  }
                                                  strokeWidth="2"
                                                >
                                                  <rect
                                                    width="24"
                                                    height="24"
                                                    rx="6"
                                                    stroke="none"
                                                  />
                                                  <rect
                                                    x="1"
                                                    y="1"
                                                    width="22"
                                                    height="22"
                                                    rx="5"
                                                    fill="none"
                                                  />
                                                </g>
                                              </svg>
                                              <p className="ml-2">Round</p>
                                            </div>
                                          </div>
                                          {this.state.categories_columns !==
                                            "1" && (
                                            <div
                                              onClick={(e) =>
                                                this.handlecategories_shape(
                                                  "circular_corner"
                                                )
                                              }
                                              className="aligndiv"
                                            >
                                              <div className="d-flex align-items-center justify-content-center align">
                                                <svg
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <g
                                                    id="Rectangle_4348"
                                                    data-name="Rectangle 4348"
                                                    fill="#fff"
                                                    stroke={
                                                      this.state
                                                        .categories_shape ===
                                                      "circular_corner"
                                                        ? "#7782A1"
                                                        : "#c8cedb"
                                                    }
                                                    strokeWidth="2"
                                                  >
                                                    <rect
                                                      width="24"
                                                      height="24"
                                                      rx="12"
                                                      stroke="none"
                                                    />
                                                    <rect
                                                      x="1"
                                                      y="1"
                                                      width="22"
                                                      height="22"
                                                      rx="11"
                                                      fill="none"
                                                    />
                                                  </g>
                                                </svg>
                                                <p className="ml-2">Circle</p>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>
                                      <TabContent
                                        activeTab={this.state.activeTab}
                                        className="pb-0 w-md-50 color_picker"
                                      >
                                        <TabPane tabId="1">
                                          <div
                                            className={
                                              this.state.activeTab === "1"
                                                ? "mt-3 mt-md-0"
                                                : "mt-4"
                                            }
                                          >
                                            {/* <div className="color_picker mb-4 mb-md-0">
																	
																	<Tooltip firsttag={"h4"} tagclass={"mb-0 mr-2"} classdata={"d-flex justify-content-start align-items-center mb-1"} heading={"Text Background Color"} content={"Select background color for category name."}/>
																
																	<Reactcolorpicker colorname={this.state.cattextbackgroundcolor} presetColors={this.state.presetColors} onChange = {this.handlecattextbackgroundcolor}   valuecontrol={false} classselection={"fixback topfixback"}/>
					                                            </div> */}
                                            <div className="color_picker mb-2 mb-md-0 w-100">
                                              {/* Tool tip */}
                                              <Tooltip
                                                firsttag={"h4"}
                                                tagclass={"mb-0 mr-2"}
                                                classdata={
                                                  "d-flex justify-content-start align-items-center mb-1"
                                                }
                                                heading={"Text color"}
                                                content={
                                                  "Select text color for category name."
                                                }
                                              />
                                              {/* Tool tip */}
                                              <Reactcolorpicker
                                                colorname={
                                                  this.state.cattextcolor
                                                }
                                                presetColors={
                                                  this.state.presetColors
                                                }
                                                onChange={
                                                  this.handlecattextcolor
                                                }
                                                classselection={
                                                  "fixback topfixback"
                                                }
                                                valuecontrol={true}
                                              />
                                            </div>
                                          </div>
                                        </TabPane>
                                        <TabPane tabId="2">
                                          <div className="d-flex align-items-center justify-content-between flex-wrap mt-4">
                                            <div className="color_picker mb-4 mb-md-0">
                                              {/* Tool tip */}
                                              <Tooltip
                                                firsttag={"h4"}
                                                tagclass={"mb-0 mr-2"}
                                                classdata={
                                                  "d-flex justify-content-start align-items-center mb-1"
                                                }
                                                heading={"Background Color"}
                                                content={
                                                  "Select background color for category title."
                                                }
                                              />
                                              {/* Tool tip */}
                                              <Reactcolorpicker
                                                colorname={
                                                  this.state.catbackgroundcolor
                                                }
                                                presetColors={
                                                  this.state.presetColors
                                                }
                                                onChange={
                                                  this.handlecatbackgroundcolor
                                                }
                                                classselection={
                                                  "fixback topfixback"
                                                }
                                                valuecontrol={false}
                                              />
                                            </div>
                                            <div className="color_picker mb-2 mb-md-0">
                                              {/* Tool tip */}
                                              <Tooltip
                                                firsttag={"h4"}
                                                tagclass={"mb-0 mr-2"}
                                                classdata={
                                                  "d-flex justify-content-start align-items-center mb-1"
                                                }
                                                heading={"Text color"}
                                                content={
                                                  "Select text color for category title."
                                                }
                                              />
                                              {/* Tool tip */}
                                              <Reactcolorpicker
                                                colorname={
                                                  this.state
                                                    .catbackgroundtextcolor
                                                }
                                                presetColors={
                                                  this.state.presetColors
                                                }
                                                onChange={
                                                  this
                                                    .handlecatbackgroundtextcolor
                                                }
                                                classselection={
                                                  "fixback topfixback"
                                                }
                                                valuecontrol={true}
                                              />
                                            </div>
                                          </div>
                                        </TabPane>
                                      </TabContent>
                                    </div>
                                    {/* Show category selection */}
                                    <div className="d-flex align-items-center justify-content-between flex-wrap mt-4">
                                      <div className="color_picker mb-4">
                                        <h4>Show Categories</h4>
                                        <div className="form-group">
                                          <select
                                            className="form-control"
                                            value={
                                              this.state
                                                .categories_selection_type
                                            }
                                            name="categories_selection_type"
                                            onChange={(e) =>
                                              this.handlecategories_sorting(e)
                                            }
                                            onKeyDown={(e) =>
                                              this.handleKeyDown(e)
                                            }
                                          >
                                            <option value="parent_categories">
                                              Parent categories
                                            </option>
                                            <option value="populated_categories">
                                              Populated categories
                                            </option>
                                            <option value="selected_categories">
                                              Selected categories
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      {this.state.categories_selection_type !==
                                        "populated_categories" && (
                                        <div className="color_picker mb-4">
                                          <h4>Sort Categories by</h4>
                                          <div className="form-group">
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                this.handlecategories_sorting(e)
                                              }
                                              name="categories_sorting"
                                              value={
                                                this.state.categories_sorting
                                              }
                                              onKeyDown={(e) =>
                                                this.handleKeyDown(e)
                                              }
                                            >
                                              <option value="count">
                                                Most Populated Categories
                                              </option>
                                              <option value="name">
                                                Alphabetical Sorting
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {this.state.categories_selection_type ===
                                      "selected_categories" && (
                                      <div className="d-flex  align-items-center justify-content-start category-setting-dashboard ">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/blue-setting.png")
                                              .default
                                          }
                                          className="img-fluid"
                                          alt="bluesetting"
                                        />

                                        <Link
                                          to="#"
                                          style={{
                                            color: "#3064F9",
                                            fontSize: "15px",
                                          }}
                                          onClick={(e) =>
                                            this.handlecategorychange(
                                              e,
                                              "catmodal"
                                            )
                                          }
                                        >
                                          Select categories
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* Category Data End */}

                              {/* Saleitems Data */}

                              <div
                                className="dashboard-customizer sale_products"
                                ref={this.setWrapperRef}
                              >
                                <div className="d-flex  align-items-center justify-content-between common-height p-3 p-md-0">
                                  <div className="module_title mobleft d-flex justify-content-start align-items-center">
                                    <h5>
                                      Sale Products{" "}
                                      <span className="salebadge">
                                        {this.state.sale_products_label ===
                                          "Sale Products" ||
                                        this.state.sale_products_label === ""
                                          ? null
                                          : "(" +
                                            this.state.sale_products_label +
                                            ")"}
                                      </span>
                                    </h5>
                                    <div
                                      className="ml-2 editlabel"
                                      onClick={(e) =>
                                        this.sale_items_settings(e)
                                      }
                                    >
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/edit.png")
                                            .default
                                        }
                                        alt="edit sale"
                                      />
                                    </div>
                                  </div>
                                  <div className="on_off d-flex  align-items-center">
                                    <label
                                      className="switch toggle-align"
                                      htmlFor="saleitems"
                                    >
                                      <input
                                        type="checkbox"
                                        className="toplogo"
                                        id="saleitems"
                                        defaultChecked={
                                          this.state.saleitemscheckbox
                                        }
                                        onClick={this.handlesaleitemscheckbox}
                                      />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>
                                </div>
                                <div
                                  className={
                                    this.state.sale_items_settings
                                      ? "upload_files pb-3 mt-mb-0 "
                                      : "upload_files  d-none"
                                  }
                                >
                                  <div className=" mb-2">
                                    <h4>Rename</h4>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        placeholder="Sale products"
                                        name="sale_products_label"
                                        className="form-control"
                                        maxLength={this.state.characterlimit}
                                        value={this.state.sale_products_label}
                                        onChange={(e) =>
                                          this.handlelabelname(e)
                                        }
                                      />
                                      <span className="label_input">
                                        {this.state.sale_products_label
                                          .length === 0
                                          ? "Rename field cannot be empty"
                                          : null}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Saleitems End */}
                              {/* Recently View */}

                              <div
                                className="dashboard-customizer recently_viewed_products"
                                ref={this.setWrapperRef}
                              >
                                <div className="d-flex  align-items-center justify-content-between common-height padding-15 p-md-0">
                                  <div className="module_title mobleft  d-flex justify-content-start align-items-center">
                                    <h5>
                                      Recently Viewed Products{" "}
                                      <span className="salebadge">
                                        {this.state
                                          .recently_viewed_products_label ===
                                          "Recently Viewed Products" ||
                                        this.state
                                          .recently_viewed_products_label === ""
                                          ? null
                                          : "(" +
                                            this.state
                                              .recently_viewed_products_label +
                                            ")"}
                                      </span>
                                    </h5>
                                    <div
                                      className="ml-2 editlabel"
                                      onClick={(e) =>
                                        this.recently_viewed_products_settings(
                                          e
                                        )
                                      }
                                    >
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/edit.png")
                                            .default
                                        }
                                        alt="edit review"
                                      />
                                    </div>
                                  </div>
                                  <div className="on_off d-flex  align-items-center">
                                    <label
                                      className="switch toggle-align"
                                      htmlFor="recentlyViewed"
                                    >
                                      <input
                                        type="checkbox"
                                        className="toplogo"
                                        id="recentlyViewed"
                                        defaultChecked={
                                          this.state.recentviewcheckbox
                                        }
                                        onClick={this.handlerecentviewcheckbox}
                                      />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>
                                </div>
                                <div
                                  className={
                                    this.state.recently_viewed_products_settings
                                      ? "upload_files pb-3 mt-mb-0 "
                                      : "upload_files  d-none"
                                  }
                                >
                                  <div className=" mb-2">
                                    <h4>Rename</h4>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        placeholder="Recently Viewed"
                                        name="recently_viewed_products_label"
                                        className="form-control"
                                        value={
                                          this.state
                                            .recently_viewed_products_label
                                        }
                                        maxLength={this.state.characterlimit}
                                        onChange={(e) =>
                                          this.handlelabelname(e)
                                        }
                                      />
                                      <span className="label_input">
                                        {this.state
                                          .recently_viewed_products_label
                                          .length === 0
                                          ? "Rename field cannot be empty"
                                          : null}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Recently View End */}
                              {/* Featured Data */}

                              <div
                                className="dashboard-customizer featured_products"
                                ref={this.setWrapperRef}
                              >
                                <div className="d-flex  align-items-center justify-content-between common-height padding-15 p-md-0">
                                  <div className="module_title mobleft d-flex justify-content-start align-items-center">
                                    <h5>
                                      Featured Products{" "}
                                      <span className="salebadge">
                                        {this.state.featured_products_label ===
                                          "Featured Products" ||
                                        this.state.featured_products_label ===
                                          ""
                                          ? null
                                          : "(" +
                                            this.state.featured_products_label +
                                            ")"}
                                      </span>
                                    </h5>
                                    <div
                                      className="ml-2 editlabel"
                                      onClick={(e) =>
                                        this.featured_products_settings(e)
                                      }
                                    >
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/edit.png")
                                            .default
                                        }
                                        alt="edit feature"
                                      />
                                    </div>
                                  </div>
                                  <div className="on_off d-flex  align-items-center">
                                    <label
                                      className="switch toggle-align"
                                      htmlFor="featuredProducts"
                                    >
                                      <input
                                        type="checkbox"
                                        className="toplogo"
                                        id="featuredProducts"
                                        defaultChecked={
                                          this.state.featuredproductcheckbox
                                        }
                                        onClick={
                                          this.handlefeaturedproductcheckbox
                                        }
                                      />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>
                                </div>
                                <div
                                  className={
                                    this.state.featured_products_settings
                                      ? "upload_files pb-3 mt-mb-0 "
                                      : "upload_files  d-none"
                                  }
                                >
                                  <div className=" mb-2">
                                    <h4>Rename</h4>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        placeholder="Featured Products"
                                        name="featured_products_label"
                                        className="form-control"
                                        value={
                                          this.state.featured_products_label
                                        }
                                        maxLength={this.state.characterlimit}
                                        onChange={(e) =>
                                          this.handlelabelname(e)
                                        }
                                      />
                                      <span className="label_input">
                                        {this.state.featured_products_label
                                          .length === 0
                                          ? "Rename field cannot be empty"
                                          : null}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Featured Data End */}
                              {/* CartProduct Data */}

                              <div
                                className="dashboard-customizer cart"
                                ref={this.setWrapperRef}
                              >
                                <div className="d-flex  align-items-center justify-content-between common-height padding-15 p-md-0">
                                  <div className="module_title mobleft d-flex justify-content-start align-items-center">
                                    <h5>
                                      Cart{" "}
                                      <span className="salebadge">
                                        {this.state.incart_products_label ===
                                          "Cart" ||
                                        this.state.incart_products_label === ""
                                          ? null
                                          : "(" +
                                            this.state.incart_products_label +
                                            ")"}
                                      </span>
                                    </h5>
                                    <div
                                      className="ml-2 editlabel"
                                      onClick={(e) => this.in_cart_products(e)}
                                    >
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/edit.png")
                                            .default
                                        }
                                        alt="cart edit"
                                      />
                                    </div>
                                  </div>
                                  <div className="on_off d-flex  align-items-center">
                                    <label
                                      className="switch toggle-align"
                                      htmlFor="inCart"
                                    >
                                      <input
                                        type="checkbox"
                                        defaultChecked={
                                          this.state.Cartproductcheckbox
                                        }
                                        onClick={this.handleCartproductcheckbox}
                                        className="toplogo"
                                        id="inCart"
                                      />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>
                                </div>
                                <div
                                  className={
                                    this.state.in_cart_products
                                      ? "upload_files pb-3 mt-mb-0"
                                      : "upload_files  d-none"
                                  }
                                >
                                  <div className=" mb-2">
                                    <h4>Rename</h4>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        placeholder="In Cart Products"
                                        name="incart_products_label"
                                        className="form-control"
                                        value={this.state.incart_products_label}
                                        maxLength={this.state.characterlimit}
                                        onChange={(e) =>
                                          this.handlelabelname(e)
                                        }
                                      />
                                      <span className="label_input">
                                        {this.state.incart_products_label
                                          .length === 0
                                          ? "Rename field cannot be empty"
                                          : null}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* CartProduct Data End */}
                              {/* New Arrival Data, Dont remove new_product*/}

                              <div
                                className="dashboard-customizer new_products"
                                ref={this.setWrapperRef}
                              >
                                <div className="d-flex  align-items-center justify-content-between common-height padding-15 p-md-0">
                                  <div className="module_title mobleft d-flex justify-content-start align-items-center">
                                    <h5>
                                      New Products{" "}
                                      <span className="salebadge">
                                        {this.state.new_products_label ===
                                          "New Products" ||
                                        this.state.new_products_label === ""
                                          ? null
                                          : "(" +
                                            this.state.new_products_label +
                                            ")"}
                                      </span>
                                    </h5>
                                    <div
                                      className="ml-2 editlabel"
                                      onClick={(e) =>
                                        this.new_products_settings(e)
                                      }
                                    >
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/edit.png")
                                            .default
                                        }
                                        alt="edit product"
                                      />
                                    </div>
                                  </div>
                                  <div className="on_off d-flex  align-items-center">
                                    <label
                                      className="switch toggle-align"
                                      htmlFor="newArrivals"
                                    >
                                      <input
                                        type="checkbox"
                                        className="toplogo"
                                        defaultChecked={
                                          this.state.newarrivalscheckbox
                                        }
                                        onClick={this.handlenewarrivalscheckbox}
                                        id="newArrivals"
                                      />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>
                                </div>
                                <div
                                  className={
                                    this.state.new_products_settings
                                      ? "upload_files pb-3 mt-mb-0 "
                                      : "upload_files  d-none"
                                  }
                                >
                                  <div className=" mb-2">
                                    <h4>Rename</h4>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        placeholder="New Products"
                                        name="new_products_label"
                                        className="form-control"
                                        value={this.state.new_products_label}
                                        maxLength={this.state.characterlimit}
                                        onChange={(e) =>
                                          this.handlelabelname(e)
                                        }
                                      />
                                      <span className="label_input">
                                        {this.state.new_products_label
                                          .length === 0
                                          ? "Rename field cannot be empty"
                                          : null}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* New Arrival Data End */}
                            </>
                          ) : (
                            <>
                              <div
                                className="dashboard-customizer product_categories"
                                ref={this.setWrapperRef}
                              ></div>
                              <div
                                className="dashboard-customizer sale_products"
                                ref={this.setWrapperRef}
                              ></div>
                              <div
                                className="dashboard-customizer recently_viewed_products"
                                ref={this.setWrapperRef}
                              ></div>
                              <div
                                className="dashboard-customizer featured_products"
                                ref={this.setWrapperRef}
                              ></div>
                              <div
                                className="dashboard-customizer cart"
                                ref={this.setWrapperRef}
                              ></div>
                              <div
                                className="dashboard-customizer new_products"
                                ref={this.setWrapperRef}
                              ></div>
                            </>
                          )}
                          {(parseInt(localStorage.getItem("website_technology")) === 4) || (parseInt(localStorage.getItem("website_technology")) === 5) ? (
                            <>
                              {/* Blogs Data */}

                              <div
                                className="dashboard-customizer new_blogs"
                                ref={this.setWrapperRef}
                              >
                                <div className="d-flex  align-items-center justify-content-between common-height p-3 p-md-0">
                                  <div className="module_title mobleft d-flex justify-content-start align-items-center">
                                    <h5>
                                      Recent Blogs{" "}
                                      <span className="salebadge">
                                        {this.state.new_blogs_label ===
                                          "Recent Blogs" ||
                                        this.state.new_blogs_label === ""
                                          ? null
                                          : "(" +
                                            this.state.new_blogs_label +
                                            ")"}
                                      </span>
                                    </h5>
                                    <div
                                      className="ml-2 editlabel"
                                      onClick={(e) =>
                                        this.recent_blogs_settings(e)
                                      }
                                    >
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/edit.png")
                                            .default
                                        }
                                        alt="edit recent"
                                      />
                                    </div>
                                  </div>
                                  <div className="on_off d-flex  align-items-center">
                                    <label
                                      className="switch toggle-align"
                                      htmlFor="bloglist"
                                    >
                                      <input
                                        type="checkbox"
                                        className="toplogo"
                                        defaultChecked={this.state.blogcheckbox}
                                        onClick={this.handleblogcheckbox}
                                        id="bloglist"
                                      />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>
                                </div>
                                <div
                                  className={
                                    this.state.recent_blogs_settings
                                      ? "upload_files pb-3 mt-mb-0 "
                                      : "upload_files  d-none"
                                  }
                                >
                                  <div className=" mb-2">
                                    <h4>Rename</h4>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        placeholder="Recent Blogs"
                                        name="new_blogs_label"
                                        className="form-control"
                                        value={this.state.new_blogs_label}
                                        maxLength={this.state.characterlimit}
                                        onChange={(e) =>
                                          this.handlelabelname(e)
                                        }
                                      />
                                      <span className="label_input">
                                        {this.state.new_blogs_label.length === 0
                                          ? "Rename field cannot be empty"
                                          : null}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Blogs Data End */}
                              {/* Blogs Banner */}

                              <div
                                className="dashboard-customizer blog_banner"
                                ref={this.setWrapperRef}
                              >
                                <div className="d-flex  align-items-center justify-content-between common-height p-3 p-md-0">
                                  <div className="module_title mobleft d-flex justify-content-start align-items-center">
                                    <h5>
                                      Blog Banner{" "}
                                      <span className="salebadge">
                                        {this.state.blog_banner_label ===
                                          "Blog Banner" ||
                                        this.state.blog_banner_label === ""
                                          ? null
                                          : "(" +
                                            this.state.blog_banner_label +
                                            ")"}
                                      </span>
                                    </h5>
                                    <div
                                      className="ml-2 editlabel"
                                      onClick={(e) =>
                                        this.blog_banner_setting(e)
                                      }
                                    >
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/edit.png")
                                            .default
                                        }
                                        alt="edit recent"
                                      />
                                    </div>
                                  </div>
                                  <div className="on_off d-flex  align-items-center">
                                    <label
                                      className="switch toggle-align"
                                      htmlFor="blogbanner"
                                    >
                                      <input
                                        type="checkbox"
                                        className="toplogo"
                                        defaultChecked={
                                          this.state.blog_banner_display
                                        }
                                        onClick={this.handleblog_banner_display}
                                        id="blogbanner"
                                      />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>
                                </div>
                                <div
                                  className={
                                    this.state.blog_banner_setting
                                      ? "upload_files pb-3 mt-mb-0 "
                                      : "upload_files  d-none"
                                  }
                                >
                                  <div className=" mb-2">
                                    <h4>Rename</h4>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        placeholder="Blog Banner"
                                        name="blog_banner_label"
                                        className="form-control"
                                        value={this.state.blog_banner_label}
                                        maxLength={this.state.characterlimit}
                                        onChange={(e) =>
                                          this.handlelabelname(e)
                                        }
                                      />
                                      <span className="label_input">
                                        {this.state.blog_banner_label.length ===
                                        0
                                          ? "Rename field cannot be empty"
                                          : null}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Blogs Banner End */}
                              {/* Blog category Data */}

                              <div
                                className="dashboard-customizer blog_categories"
                                ref={this.setWrapperRef}
                              >
                                <div className="d-flex  align-items-center justify-content-between common-height padding-15 p-md-0">
                                  <div className="module_title mobleft d-flex">
                                    <h5>
                                      Blog Categories{" "}
                                      <span className="salebadge">
                                        {this.state.blog_categories_label ===
                                          "Blog Categories" ||
                                        this.state.blog_categories_label === ""
                                          ? null
                                          : "(" +
                                            this.state.blog_categories_label +
                                            ")"}
                                      </span>
                                    </h5>
                                    <div
                                      className="ml-2 editlabel"
                                      onClick={(e) =>
                                        this.blog_categories_setting(e)
                                      }
                                    >
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/edit.png")
                                            .default
                                        }
                                        alt="edit recent"
                                      />
                                    </div>
                                  </div>
                                  <div className="on_off d-flex  align-items-center">
                                    {this.state.Blogshow ? (
                                      <div className="edit-btn edit-btn-new mr-4">
                                        <Link
                                          to="#"
                                          onClick={(e) =>
                                            this.blogchangeshow(e)
                                          }
                                        >
                                          <img
                                            className="mr-1 mr-md-2"
                                            src={
                                              require("../../assets/images/dashboard/gear-icon.png")
                                                .default
                                            }
                                            alt="gear"
                                          />
                                          <span> Settings</span>
                                        </Link>
                                      </div>
                                    ) : (
                                      <div className="edit-btn edit-btn-new mr-4">
                                        <Link
                                          to="#"
                                          onClick={(e) =>
                                            this.blogchangeshow(e)
                                          }
                                        >
                                          <img
                                            className="mr-1 mr-md-2"
                                            src={
                                              require("../../assets/images/dashboard/gear-icon.png")
                                                .default
                                            }
                                            alt="gear"
                                          />
                                          <span>Settings</span>
                                        </Link>
                                      </div>
                                    )}
                                    <label
                                      className="switch toggle-align"
                                      htmlFor="blog_categories_display"
                                    >
                                      <input
                                        type="checkbox"
                                        className="toplogo"
                                        defaultChecked={
                                          this.state.blog_categories_display
                                        }
                                        onClick={
                                          this.handleblog_categories_display
                                        }
                                        id="blog_categories_display"
                                      />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>
                                </div>
                                {/* blog categories label */}
                                <div
                                  className={
                                    this.state.blog_categories_setting
                                      ? "upload_files pb-3 mt-mb-0 "
                                      : "upload_files  d-none"
                                  }
                                >
                                  <div className=" mb-2">
                                    <h4>Rename</h4>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        placeholder="Blog Categories"
                                        name="blog_categories_label"
                                        className="form-control mt-2"
                                        value={this.state.blog_categories_label}
                                        maxLength={this.state.characterlimit}
                                        onChange={(e) =>
                                          this.handlelabelname(e)
                                        }
                                      />
                                      <span className="label_input">
                                        {this.state.blog_categories_label
                                          .length === 0
                                          ? "Rename field cannot be empty"
                                          : null}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                {/* blog categories label end */}
                                <div
                                  className={
                                    this.state.Blogshow
                                      ? "upload_files pt-3 pb-3 mt-3 mt-mb-0"
                                      : "upload_files  d-none"
                                  }
                                >
                                  <div>
                                    <div className="d-flex justify-content-between flex-wrap mt-1">
                                      {/* Show Coulmns */}
                                      <div className="color_picker mb-0">
                                        <h4>Column</h4>
                                        <div className="form-group">
                                          <select
                                            className="form-control"
                                            onChange={(e) =>
                                              this.handleblog_columns(e)
                                            }
                                            value={
                                              this.state.blog_categories_columns
                                            }
                                            onKeyDown={(e) =>
                                              this.handleKeyDown(e)
                                            }
                                          >
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                          </select>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-between flex-wrap mt-1">
                                      <div className="color_picker">
                                        <h4 className="mb-0 mr-2 mt-4 mb-2 pt-1">
                                          Shapes
                                        </h4>
                                        <div className="horizontal-alignment d-flex align-items-center">
                                          <div
                                            onClick={(e) =>
                                              this.handleblog_shape(
                                                "sharp_corner"
                                              )
                                            }
                                            className="aligndiv"
                                          >
                                            <div className="d-flex align-items-center justify-content-center align align-left">
                                              <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                              >
                                                <g
                                                  data-name="Rectangle 4346"
                                                  fill="#fff"
                                                  stroke={
                                                    this.state
                                                      .blog_categories_shape ===
                                                    "sharp_corner"
                                                      ? "#7782A1"
                                                      : "#c8cedb"
                                                  }
                                                  strokeWidth="2"
                                                >
                                                  <rect
                                                    width="24"
                                                    height="24"
                                                    stroke="none"
                                                  />
                                                  <rect
                                                    x="1"
                                                    y="1"
                                                    width="22"
                                                    height="22"
                                                    fill="none"
                                                  />
                                                </g>
                                              </svg>
                                              <p className="ml-2">Sharp</p>
                                            </div>
                                          </div>
                                          <div
                                            onClick={(e) =>
                                              this.handleblog_shape(
                                                "round_corner"
                                              )
                                            }
                                            className="aligndiv"
                                          >
                                            <div
                                              className="d-flex align-items-center justify-content-center align align-center"
                                              style={{
                                                borderRight:
                                                  this.state
                                                    .categories_columns ===
                                                    "1" && "none",
                                              }}
                                            >
                                              <svg
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                              >
                                                <g
                                                  data-name="Rectangle 4347"
                                                  fill="#fff"
                                                  stroke={
                                                    this.state
                                                      .blog_categories_shape ===
                                                    "round_corner"
                                                      ? "#7782A1"
                                                      : "#c8cedb"
                                                  }
                                                  strokeWidth="2"
                                                >
                                                  <rect
                                                    width="24"
                                                    height="24"
                                                    rx="6"
                                                    stroke="none"
                                                  />
                                                  <rect
                                                    x="1"
                                                    y="1"
                                                    width="22"
                                                    height="22"
                                                    rx="5"
                                                    fill="none"
                                                  />
                                                </g>
                                              </svg>
                                              <p className="ml-2">Round</p>
                                            </div>
                                          </div>
                                          {this.state
                                            .blog_categories_columns !==
                                            "1" && (
                                            <div
                                              onClick={(e) =>
                                                this.handleblog_shape(
                                                  "circular_corner"
                                                )
                                              }
                                              className="aligndiv"
                                            >
                                              <div className="d-flex align-items-center justify-content-center align">
                                                <svg
                                                  width="24"
                                                  height="24"
                                                  viewBox="0 0 24 24"
                                                >
                                                  <g
                                                    id="Rectangle_4348"
                                                    data-name="Rectangle 4348"
                                                    fill="#fff"
                                                    stroke={
                                                      this.state
                                                        .blog_categories_shape ===
                                                      "circular_corner"
                                                        ? "#7782A1"
                                                        : "#c8cedb"
                                                    }
                                                    strokeWidth="2"
                                                  >
                                                    <rect
                                                      width="24"
                                                      height="24"
                                                      rx="12"
                                                      stroke="none"
                                                    />
                                                    <rect
                                                      x="1"
                                                      y="1"
                                                      width="22"
                                                      height="22"
                                                      rx="11"
                                                      fill="none"
                                                    />
                                                  </g>
                                                </svg>
                                                <p className="ml-2">Circle</p>
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </div>

                                      <div className="d-flex align-items-center justify-content-between flex-wrap mt-4 color_picker">
                                        <div className="color_picker mb-4 mb-md-0">
                                          {/* Tool tip */}
                                          <Tooltip
                                            firsttag={"h4"}
                                            tagclass={"mb-0 mr-2"}
                                            classdata={
                                              "d-flex justify-content-start align-items-center mb-1"
                                            }
                                            heading={"Background Color"}
                                            content={
                                              "Select background color for category title."
                                            }
                                          />
                                          {/* Tool tip */}
                                          <Reactcolorpicker
                                            colorname={
                                              this.state
                                                .blog_categories_bg_color
                                            }
                                            presetColors={
                                              this.state.presetColors
                                            }
                                            onChange={
                                              this
                                                .handleblog_categories_bg_color
                                            }
                                            classselection={
                                              "fixback topfixback"
                                            }
                                            valuecontrol={false}
                                          />
                                        </div>
                                        <div className="color_picker mb-2 mb-md-0">
                                          {/* Tool tip */}
                                          <Tooltip
                                            firsttag={"h4"}
                                            tagclass={"mb-0 mr-2"}
                                            classdata={
                                              "d-flex justify-content-start align-items-center mb-1"
                                            }
                                            heading={"Text color"}
                                            content={
                                              "Select text color for category title."
                                            }
                                          />
                                          {/* Tool tip */}
                                          <Reactcolorpicker
                                            colorname={
                                              this.state
                                                .blog_categories_text_color
                                            }
                                            presetColors={
                                              this.state.presetColors
                                            }
                                            onChange={
                                              this
                                                .handleblog_categories_text_colorr
                                            }
                                            classselection={
                                              "fixback topfixback"
                                            }
                                            valuecontrol={true}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    {/* Show category selection */}
                                    <div className="d-flex align-items-center justify-content-between flex-wrap mt-4">
                                      <div className="color_picker mb-4">
                                        <h4>Show Categories</h4>
                                        <div className="form-group">
                                          <select
                                            className="form-control"
                                            value={
                                              this.state
                                                .blog_categories_selection_type
                                            }
                                            name="blog_categories_selection_type"
                                            onChange={(e) =>
                                              this.handleblog_sorting(e)
                                            }
                                            onKeyDown={(e) =>
                                              this.handleKeyDown(e)
                                            }
                                          >
                                            <option value="parent_categories">
                                              Parent categories
                                            </option>
                                            <option value="populated_categories">
                                              Populated categories
                                            </option>
                                            <option value="selected_categories">
                                              Selected categories
                                            </option>
                                          </select>
                                        </div>
                                      </div>
                                      {this.state
                                        .blog_categories_selection_type !==
                                        "populated_categories" && (
                                        <div className="color_picker mb-4">
                                          <h4>Sort Categories by</h4>
                                          <div className="form-group">
                                            <select
                                              className="form-control"
                                              onChange={(e) =>
                                                this.handleblog_sorting(e)
                                              }
                                              name="blog_categories_sorting"
                                              value={
                                                this.state
                                                  .blog_categories_sorting
                                              }
                                              onKeyDown={(e) =>
                                                this.handleKeyDown(e)
                                              }
                                            >
                                              <option value="count">
                                                Most Populated Categories
                                              </option>
                                              <option value="name">
                                                Alphabetical Sorting
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                    {this.state
                                      .blog_categories_selection_type ===
                                      "selected_categories" && (
                                      <div className="d-flex  align-items-center justify-content-start category-setting-dashboard ">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/blue-setting.png")
                                              .default
                                          }
                                          className="img-fluid"
                                          alt="bluesetting"
                                        />

                                        <Link
                                          to="#"
                                          style={{
                                            color: "#3064F9",
                                            fontSize: "15px",
                                          }}
                                          onClick={(e) =>
                                            this.handlecategorychange(
                                              e,
                                              "blogmodal"
                                            )
                                          }
                                        >
                                          Select categories
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {/* Blog category end */}
                              {/* Sticky Blog */}

                              <div
                                className="dashboard-customizer sticky_blogs"
                                ref={this.setWrapperRef}
                              >
                                <div className="d-flex  align-items-center justify-content-between common-height p-3 p-md-0">
                                  <div className="module_title mobleft d-flex justify-content-start align-items-center">
                                    <h5>
                                      Popular Blogs{" "}
                                      <span className="salebadge">
                                        {this.state.sticky_blogs_label ===
                                          "Popular Blogs" ||
                                        this.state.sticky_blogs_label === ""
                                          ? null
                                          : "(" +
                                            this.state.sticky_blogs_label +
                                            ")"}
                                      </span>
                                    </h5>
                                    <div
                                      className="ml-2 editlabel"
                                      onClick={(e) =>
                                        this.popular_blogs_settings(e)
                                      }
                                    >
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/edit.png")
                                            .default
                                        }
                                        alt="edit popular"
                                      />
                                    </div>
                                  </div>
                                  <div className="on_off d-flex  align-items-center">
                                    <label
                                      className="switch toggle-align"
                                      htmlFor="sticky_blog_display"
                                    >
                                      <input
                                        type="checkbox"
                                        className="toplogo"
                                        defaultChecked={
                                          this.state.sticky_blog_display
                                        }
                                        onClick={this.handlesticky_blog_display}
                                        id="sticky_blog_display"
                                      />
                                      <div className="slider round"></div>
                                    </label>
                                  </div>
                                </div>
                                <div
                                  className={
                                    this.state.popular_blogs_settings
                                      ? "upload_files pb-3 mt-mb-0 "
                                      : "upload_files  d-none"
                                  }
                                >
                                  <div className=" mb-2">
                                    <h4>Rename</h4>
                                    <div className="form-group">
                                      <input
                                        type="text"
                                        placeholder="Popular Blogs"
                                        name="sticky_blogs_label"
                                        className="form-control"
                                        value={this.state.sticky_blogs_label}
                                        maxLength={this.state.characterlimit}
                                        onChange={(e) =>
                                          this.handlelabelname(e)
                                        }
                                      />
                                      <span className="label_input">
                                        {this.state.sticky_blogs_label
                                          .length === 0
                                          ? "Rename field cannot be empty"
                                          : null}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/* Sticky Blog End */}
                            </>
                          ) : (
                            <>
                              <div
                                className="dashboard-customizer new_blogs"
                                ref={this.setWrapperRef}
                              ></div>
                              <div
                                className="dashboard-customizer blog_banner"
                                ref={this.setWrapperRef}
                              ></div>
                              <div
                                className="dashboard-customizer blog_categories"
                                ref={this.setWrapperRef}
                              ></div>
                              <div
                                className="dashboard-customizer sticky_blogs"
                                ref={this.setWrapperRef}
                              ></div>
                            </>
                          )}
                          {/* web_view_display_bool*/}
                          <div
                            className="dashboard-customizer web_view"
                            ref={this.setWrapperRef}
                          >
                            {/* {this.state.show_web_view_feature === 1 && ( */}
                            <>
                              <div className="d-flex  align-items-center justify-content-between common-height padding-15 p-md-0">
                                <div className="module_title mobleft d-flex">
                                  {/* Tool tip */}
                                  <Tooltip
                                    firsttag={"h5"}
                                    tagclass={"mb-0 mr-2"}
                                    classdata={
                                      "d-flex justify-content-start align-items-center mb-1"
                                    }
                                    customclass={"mobile-web-view-tooltext"}
                                    heading={"Web View"}
                                    content={
                                      "Enter the URL of the webpage that you’d like to display on your app dashboard. You can turn off the display of all other sections in case you’d like to display only the webpage"
                                    }
                                  />
                                  {/* Tool tip */}
                                </div>

                                {/* Tool tip */}
                                <div className="on_off d-flex  align-items-center">
                                  {this.state.webviewshow ? (
                                    <div className="edit-btn edit-btn-new mr-4">
                                      <Link
                                        to="#"
                                        onClick={(e) =>
                                          this.changewebviewshow(e)
                                        }
                                      >
                                        <img
                                          className="mr-1 mr-md-2"
                                          src={
                                            require("../../assets/images/dashboard/gear-icon.png")
                                              .default
                                          }
                                          alt="gear"
                                        />
                                        <span> Settings</span>
                                      </Link>
                                    </div>
                                  ) : (
                                    <div className="edit-btn edit-btn-new mr-4">
                                      <Link
                                        to="#"
                                        onClick={(e) =>
                                          this.changewebviewshow(e)
                                        }
                                      >
                                        <img
                                          className="mr-1 mr-md-2"
                                          src={
                                            require("../../assets/images/dashboard/gear-icon.png")
                                              .default
                                          }
                                          alt="gear"
                                        />
                                        <span>Settings</span>
                                      </Link>
                                    </div>
                                  )}
                                  <label
                                    className="switch toggle-align"
                                    htmlFor="web_view_display_bool"
                                  >
                                    <input
                                      type="checkbox"
                                      className="toplogo"
                                      defaultChecked={
                                        this.state.web_view_display_bool
                                      }
                                      onClick={this.handleweb_view_display_bool}
                                      id="web_view_display_bool"
                                    />
                                    <div className="slider round"></div>
                                  </label>
                                </div>
                              </div>
                              <div
                                className={
                                  this.state.webviewshow
                                    ? "upload_files d-flex align-items-center justify-content-between flex-wrap flex-wrap pt-3 pb-3"
                                    : "upload_files d-none align-items-center justify-content-between flex-wrap "
                                }
                              >
                                <div className="color_picker_full com-place mb-4">
                                  <div className="form-group">
                                    <h4>Page URL</h4>
                                    <div className="d-flex align-items-center store_url_div">
                                      <div className="https-link">
                                        <p>https://</p>
                                      </div>
                                      {/*
                                      <div
                                        onClick={(e) =>
                                          this.handleinputfocus(e)
                                        }
                                      >
                                        {this.state.web_view_target_url !==
                                          "" &&
                                        this.state.web_view_target_url !==
                                          null &&
                                        this.state.web_view_target_url !==
                                          undefined
                                          ? ""
                                          : this.state.store_url.replace(
                                              /^https?:\/\//i,
                                              ""
                                            )}
                                          </div> */}

                                      <div style={{ flex: "1" }}>
                                        <input
                                          type="text"
                                          className="form-control form-name pl-0 b-0"
                                          id="domainname2"
                                          value={
                                            this.state.web_view_target_url !==
                                              "" &&
                                            this.state.web_view_target_url !==
                                              null &&
                                            this.state.web_view_target_url !==
                                              undefined
                                              ? this.state.web_view_target_url.replace(
                                                  /^https?:\/\//i,
                                                  ""
                                                )
                                              : this.state.store_url.replace(
                                                  /^https?:\/\//i,
                                                  ""
                                                )
                                          }
                                          onChange={(e) =>
                                            this.handleweb_view_target_url(e)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                { this.state.show_web_view_header_footer_bool === false || this.state.show_web_view_header === false ||
                                  this.state.show_web_view_footer === false || this.state.hide_html_element_by_class !== '' ||
                                  this.state.hide_html_element_by_class !== '' ? 
                                    <>
                                <div className="d-flex  align-items-center justify-content-between mt-1 mb-1 w-100 sm-wrap">
                                  <div className="color_picker  align-items-center d-flex justify-content-between sm-mb-2">
                                    <h4 className="m-0 p-0 sm-pb-1">
                                      Show my website's header in this web view
                                    </h4>

                                     <div className="on_off d-flex  align-items-center">
                                      <label
                                        className="switch toggle-align"
                                        htmlFor="show_web_view_header_footer_bool"
                                      >
                                        <input
                                          type="checkbox"
                                          className="toplogo"
                                          id="show_web_view_header_footer_bool"
                                          checked={
                                            this.state
                                              .show_web_view_header_footer_bool
                                          }
                                          onChange={(e) =>
                                            this.handleshow_web_view_header_footer_bool(
                                              e
                                            )
                                          }
                                        />
                                        <div className="slider round"></div>
                                      </label>
                                    </div> 

                                    <div className="on_off d-flex  align-items-center">
                                      <label
                                        className="switch toggle-align"
                                        htmlFor="show_web_view_header_bool"
                                      >
                                        <input
                                          type="checkbox"
                                          className="toplogo"
                                          id="show_web_view_header_bool"
                                          checked={
                                            this.state.show_web_view_header
                                          }
                                          onChange={(e) =>
                                            this.handleshow_web_view_header_bool(
                                              e
                                            )
                                          }
                                        />
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                  </div>
                                  <div className="color_picker  align-items-center d-flex justify-content-between">
                                    <h4 className="m-0 p-0 sm-pb-1">
                                      Show my website's footer in this web view
                                    </h4>

                                    <div className="on_off d-flex  align-items-center">
                                      <label
                                        className="switch toggle-align"
                                        htmlFor="show_web_view_footer_bool"
                                      >
                                        <input
                                          type="checkbox"
                                          className="toplogo"
                                          id="show_web_view_footer_bool"
                                          checked={
                                            this.state.show_web_view_footer
                                          }
                                          onChange={(e) =>
                                            this.handleshow_web_view_footer_bool(
                                              e
                                            )
                                          }
                                        />
                                        <div className="slider round"></div>
                                      </label>
                                    </div>
                                  </div>
                                </div> 
                                <div className="d-flex color_picker_full align-items-center justify-content-between mt-1 mb-1 w-100">
                                  <div className="color_picker_full mb-4">
                                    <div className="form-group">
                                      <p className="mt-4 mb-2">
                                        Hide HTML element by Class
                                      </p>
                                      <input
                                        className="color_picker_full com-place pl-3"
                                        value={
                                          this.state.hide_html_element_by_class
                                        }
                                        placeholder="Header,Footer"
                                        onChange={(e) => this.handleClass(e)}
                                      />
                                      <p className="mt-4 mb-2">
                                        Hide HTML element by ID
                                      </p>

                                      <input
                                        className="color_picker_full com-place pl-3"
                                        value={
                                          this.state.hide_html_element_by_id
                                        }
                                        placeholder="Sidebar,content"
                                        onChange={(e) => this.handleId(e)}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </>
                              : null
                            }
                              </div>
                            </>
                            {/* )} */}
                          </div>
                          {/* web_view_display_bool*/}
                        </div>
                        {/* New Pages */}
                        {parseInt(
                          localStorage.getItem("website_technology")
                        ) !== 2 ? (
                          <div className="dashboard-padding-15">
                            <div className="d-flex  align-items-center justify-content-between common-height p-3 p-md-0 ipad-last-padding">
                              <div className="module_title d-flex">
                                <h5>Pages</h5>
                              </div>
                              <div className="on_off d-flex  align-items-center">
                                <div className="edit-btn edit-btn-new mr-4">
                                  <Link
                                    to="#"
                                    onClick={(e) =>
                                      this.handlecategorychange(e, "pagemodal")
                                    }
                                  >
                                    <img
                                      className="mr-1 mr-md-1 ipad-1"
                                      src={
                                        require("../../assets/images/dashboard/gear-icon.png")
                                          .default
                                      }
                                      alt="gear"
                                    />{" "}
                                    <span>Select Pages</span>
                                  </Link>
                                </div>
                                <label
                                  className="switch toggle-align"
                                  htmlFor="new_pages_display"
                                >
                                  <input
                                    type="checkbox"
                                    className="toplogo"
                                    defaultChecked={
                                      this.state.new_pages_display
                                    }
                                    onClick={this.handlenew_pages_display}
                                    id="new_pages_display"
                                  />
                                  <div className="slider round"></div>
                                </label>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                        {/* New Pages End */}
                      </section>
                      {/*  Remove Mobile App */}
                      <RemoveApppreview />
                      {/*  Remove Mobile App */}
                    </div>
                  </div>
                  <div className="customiseapp dashboardcust mobilepreview mobilepreviewnew">
                    <div className="two-banner two-btn-tabs tabwrapper">
                      <div className="text-center ">
                        <div className="platswitch altswitch">
                          <ul
                            id="myTabs"
                            className="nav nav-pills nav-justified promotertabs mb-4"
                            role="tablist"
                            data-tabs="tabs"
                          >
                            <li>
                              <Link
                                to="#"
                                onClick={(e) => this.switchmobile(e, "ios")}
                                className={
                                  this.state.hollowactive ? "active" : ""
                                }
                                data-toggle="tab"
                                id="year"
                              >
                                iOS
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                onClick={(e) => this.switchmobile(e, "android")}
                                className={
                                  this.state.hollowactive ? "" : "active"
                                }
                                data-toggle="tab"
                              >
                                Android
                              </Link>
                            </li>
                          </ul>
                        </div>
                        <div className="tab-content mb-2">
                          <div
                            role="tabpanel"
                            className="tab-pane fade in active show"
                            id="ios"
                          >
                            <div
                              className="hollow_phone"
                              style={{
                                backgroundImage:
                                  "url(" + this.state.holloimage + ")",
                              }}
                            >
                              {this.state.hideoverlay === true ? (
                                <div className="overlay-text d-flex align-items-center justify-content-center">
                                  <p>Save and refresh to preview</p>
                                </div>
                              ) : (
                                <></>
                              )}

                              <div className="mobile-head">
                                <img
                                  src={this.state.notch}
                                  className="img-fluid"
                                  alt="notch"
                                />
                              </div>
                              <div className="dashboard-mobile">
                                <div
                                  className="white-mobile-border homescreen-bottom-menuspacing"
                                  id="applogo"
                                >
                                  <div
                                    className="top-header"
                                    style={{
                                      background: this.state.primaryColor,
                                      height:
                                        this.state.topheadercheckbox === false
                                          ? "32px"
                                          : "63px",
                                    }}
                                  >
                                    {this.state.topheadercheckbox === true && (
                                      <div className=" d-flex align-items-center justify-content-between">
                                        <div className="menu-icon d-flex align-items-center justify-content-between">
                                          <div className="pl-md-2">
                                            <svg
                                              width="15.5"
                                              height="11.5"
                                              viewBox="0 0 15.5 11.5"
                                            >
                                              <g
                                                id="menu"
                                                transform="translate(0.25 0.25)"
                                                fill="#fff"
                                              >
                                                <path
                                                  d="M 15.125 11.125 L 15 11.125 L -8.881784197001252e-16 11.125 L -0.125 11.125 L -0.125 11 L -0.125 10.2324104309082 L -0.125 10.1074104309082 L -8.881784197001252e-16 10.1074104309082 L 15 10.1074104309082 L 15.125 10.1074104309082 L 15.125 10.2324104309082 L 15.125 11 L 15.125 11.125 Z M 15.125 6.008560180664063 L 15 6.008560180664063 L -8.881784197001252e-16 6.008560180664063 L -0.125 6.008560180664063 L -0.125 5.883560180664063 L -0.125 5.116199970245361 L -0.125 4.991199970245361 L -8.881784197001252e-16 4.991199970245361 L 15 4.991199970245361 L 15.125 4.991199970245361 L 15.125 5.116199970245361 L 15.125 5.883560180664063 L 15.125 6.008560180664063 Z M 15.125 0.8923599720001221 L 15 0.8923599720001221 L -8.881784197001252e-16 0.8923599720001221 L -0.125 0.8923599720001221 L -0.125 0.7673599720001221 L -0.125 -8.881784197001252e-16 L -0.125 -0.125 L -8.881784197001252e-16 -0.125 L 15 -0.125 L 15.125 -0.125 L 15.125 -8.881784197001252e-16 L 15.125 0.7673599720001221 L 15.125 0.8923599720001221 Z"
                                                  stroke={
                                                    this.state.secondaryColor
                                                  }
                                                />
                                                <path
                                                  d="M 15 11 L 15 10.2324104309082 L -8.881784197001252e-16 10.2324104309082 L -8.881784197001252e-16 11 L 15 11 M 15 5.883560180664063 L 15 5.116199970245361 L -8.881784197001252e-16 5.116199970245361 L -8.881784197001252e-16 5.883560180664063 L 15 5.883560180664063 M 15 0.7673599720001221 L 15 -8.881784197001252e-16 L -8.881784197001252e-16 -8.881784197001252e-16 L -8.881784197001252e-16 0.7673599720001221 L 15 0.7673599720001221 M 15.25 11.25 L 15 11.25 L -8.881784197001252e-16 11.25 L -0.25 11.25 L -0.25 11 L -0.25 10.2324104309082 L -0.25 9.982410430908203 L -8.881784197001252e-16 9.982410430908203 L 15 9.982410430908203 L 15.25 9.982410430908203 L 15.25 10.2324104309082 L 15.25 11 L 15.25 11.25 Z M 15.25 6.133560180664063 L 15 6.133560180664063 L -8.881784197001252e-16 6.133560180664063 L -0.25 6.133560180664063 L -0.25 5.883560180664063 L -0.25 5.116199970245361 L -0.25 4.866199970245361 L -8.881784197001252e-16 4.866199970245361 L 15 4.866199970245361 L 15.25 4.866199970245361 L 15.25 5.116199970245361 L 15.25 5.883560180664063 L 15.25 6.133560180664063 Z M 15.25 1.017359972000122 L 15 1.017359972000122 L -8.881784197001252e-16 1.017359972000122 L -0.25 1.017359972000122 L -0.25 0.7673599720001221 L -0.25 -8.881784197001252e-16 L -0.25 -0.25 L -8.881784197001252e-16 -0.25 L 15 -0.25 L 15.25 -0.25 L 15.25 -8.881784197001252e-16 L 15.25 0.7673599720001221 L 15.25 1.017359972000122 Z"
                                                  stroke="none"
                                                  fill={
                                                    this.state.secondaryColor
                                                  }
                                                />
                                              </g>
                                            </svg>
                                          </div>

                                          <p
                                            style={{
                                              color: this.state.secondaryColor,
                                              fontFamily:
                                                this.state.buttondata
                                                  .header_font_name,
                                              width:
                                                this.state
                                                  .is_enable_posts_search ===
                                                  true ||
                                                this.state
                                                  .is_enable_products_search ===
                                                  true
                                                  ? "133px"
                                                  : "150px",
                                            }}
                                          >
                                            {this.state.appName}
                                          </p>
                                        </div>
                                        <div className="menu-icon d-flex align-items-center justify-content-between">
                                          <div className="pr-2">
                                            <svg
                                              width="13"
                                              height="14"
                                              viewBox="0 0 13 14"
                                            >
                                              <g
                                                id="userd"
                                                transform="translate(0.004 -0.223)"
                                              >
                                                <g
                                                  id="Ellipse_45"
                                                  data-name="Ellipse 45"
                                                  transform="translate(2.996 0.223)"
                                                  fill="none"
                                                  stroke={
                                                    this.state.secondaryColor
                                                  }
                                                  strokeWidth="1.5"
                                                >
                                                  <circle
                                                    cx="3.5"
                                                    cy="3.5"
                                                    r="3.5"
                                                    stroke="none"
                                                  />
                                                  <circle
                                                    cx="3.5"
                                                    cy="3.5"
                                                    r="2.75"
                                                    fill="none"
                                                  />
                                                </g>
                                                <g
                                                  id="Rectangle_150"
                                                  data-name="Rectangle 150"
                                                  transform="translate(-0.004 8.223)"
                                                  fill="none"
                                                  stroke={
                                                    this.state.secondaryColor
                                                  }
                                                  strokeWidth="1.5"
                                                >
                                                  <path
                                                    d="M4,0H9a4,4,0,0,1,4,4V5a1,1,0,0,1-1,1H1A1,1,0,0,1,0,5V4A4,4,0,0,1,4,0Z"
                                                    stroke="none"
                                                  />
                                                  <path
                                                    d="M4,.75H9A3.25,3.25,0,0,1,12.25,4V5a.25.25,0,0,1-.25.25H1A.25.25,0,0,1,.75,5V4A3.25,3.25,0,0,1,4,.75Z"
                                                    fill="none"
                                                  />
                                                </g>
                                              </g>
                                            </svg>
                                          </div>
                                          {(this.state
                                            .is_enable_posts_search === true ||
                                            this.state
                                              .is_enable_products_search ===
                                              true) && (
                                            <div className="pr-2">
                                              <svg
                                                width="14"
                                                height="14"
                                                viewBox="0 0 55.415 55.152"
                                              >
                                                <path
                                                  id="Union_315"
                                                  data-name="Union 315"
                                                  d="M5221.985,1757.716l13.515,13.521Zm-38.485-15.745a22.456,22.456,0,1,1,22.456,22.471A22.465,22.465,0,0,1,5183.5,1741.971Z"
                                                  transform="translate(-5181.5 -1717.499)"
                                                  fill="none"
                                                  stroke={
                                                    this.state.secondaryColor
                                                  }
                                                  strokeWidth="6"
                                                />
                                              </svg>
                                            </div>
                                          )}
                                          {this.state.typeselection !== "5" && this.state.typeselection !=="4" && (
                                              <div className="pr-2">
                                                <svg
                                                  width="18.444"
                                                  height="14.881"
                                                  viewBox="0 0 18.444 14.881"
                                                >
                                                  <g
                                                    id="Group_2127"
                                                    data-name="Group 2127"
                                                    transform="translate(-1753.667 -243)"
                                                  >
                                                    <path
                                                      id="Path_6086"
                                                      data-name="Path 6086"
                                                      d="M1754.417,243.75h3.463l2.059,9.055h9.192l1.99-7.066H1758.6"
                                                      transform="translate(0)"
                                                      fill="none"
                                                      stroke={
                                                        this.state
                                                          .secondaryColor
                                                      }
                                                      strokeLinecap="round"
                                                      strokeWidth="1.5"
                                                    />
                                                    <g
                                                      id="Ellipse_257"
                                                      data-name="Ellipse 257"
                                                      transform="translate(1759.305 254.681)"
                                                      fill="none"
                                                      stroke={
                                                        this.state
                                                          .secondaryColor
                                                      }
                                                      strokeLinecap="round"
                                                      strokeWidth="1.5"
                                                    >
                                                      <circle
                                                        cx="1.6"
                                                        cy="1.6"
                                                        r="1.6"
                                                        stroke="none"
                                                      />
                                                      <circle
                                                        cx="1.6"
                                                        cy="1.6"
                                                        r="0.85"
                                                        fill="none"
                                                      />
                                                    </g>
                                                    <g
                                                      id="Ellipse_258"
                                                      data-name="Ellipse 258"
                                                      transform="translate(1765.704 254.681)"
                                                      fill="none"
                                                      stroke={
                                                        this.state
                                                          .secondaryColor
                                                      }
                                                      strokeLinecap="round"
                                                      strokeWidth="1.5"
                                                    >
                                                      <circle
                                                        cx="1.6"
                                                        cy="1.6"
                                                        r="1.6"
                                                        stroke="none"
                                                      />
                                                      <circle
                                                        cx="1.6"
                                                        cy="1.6"
                                                        r="0.85"
                                                        fill="none"
                                                      />
                                                    </g>
                                                  </g>
                                                </svg>
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    )}
                                  </div>

                                  <div
                                    className="sectionmove"
                                    style={{
                                      marginTop:
                                        this.state.topheadercheckbox === false
                                          ? "32px"
                                          : "63px",
                                    }}
                                  >
                                    <div
                                      className={
                                        this.state.bannerCheckbox
                                          ? "mainbanner banner1 d-flex align-items-center mt-0"
                                          : "mainbanner banner1 d-none mt-0"
                                      }
                                      style={{
                                        background: this.state
                                          .selectbackgroundorcolor
                                          ? this.state.splashBackground
                                          : "url(" +
                                            this.state.selectpixabayimage +
                                            ")",
                                        justifyContent:
                                          this.state.bannertextAlign,
                                      }}
                                    >
                                      {/* Banner Text */}
                                      <p
                                        style={{
                                          fontFamily:
                                            this.state.bannertextfontfamily,
                                          fontSize: this.state.bannerfontSize,
                                          color: this.state.bannertextColor,
                                        }}
                                      >
                                        {this.state.bannertext}
                                      </p>
                                    </div>

                                    <div
                                      className={
                                        this.state.categoriesCheckbox
                                          ? "categoriesset categories1 category-class-four  pt-md-3 pl-md-2 pb-md-2  mb-0"
                                          : "categories1 category-class-four d-none"
                                      }
                                      style={{
                                        background:
                                          this.state.sectionBackgroundcolor,
                                        borderBottom: "1px solid #C1D7E7",
                                      }}
                                    >
                                      <div className="d-flex justify-content-between align-items-center pr-md-2">
                                        <p
                                          style={{
                                            fontFamily:
                                              this.state.fontfamilydashboard,
                                            color:
                                              this.state.sectionHeadingcolor,
                                          }}
                                        >
                                          {this.state.product_categories_label}
                                        </p>
                                        <div
                                          className="seemoresection"
                                          style={{
                                            background:
                                              this.state.sectionbuttoncolor,
                                          }}
                                        >
                                          <svg
                                            width="5.591"
                                            height="9.061"
                                            viewBox="0 0 5.591 9.061"
                                          >
                                            <path
                                              id="Path_84774"
                                              data-name="Path 84774"
                                              d="M990.5,784l4,4-4,4"
                                              transform="translate(-989.969 -783.47)"
                                              fill="none"
                                              stroke={
                                                this.state
                                                  .sectionbuttontextcolor
                                              }
                                              stroke-width="1.5"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                      <div className="category-list flex-wrap d-flex pt-3">
                                        {this.state.activestate ? (
                                          <>
                                            <div
                                              className="cat-product mr-2 mr-md-1 "
                                              style={contentSize()}
                                            >
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/cate-dash.png")
                                                    .default
                                                }
                                                className="img-fluid"
                                                alt="Sale"
                                                style={imagesize()}
                                              />
                                              {this.state.categoryname ===
                                                true && (
                                                <p
                                                  style={{
                                                    color:
                                                      this.state.cattextcolor,
                                                    fontSize:
                                                      this.state.catfont,
                                                  }}
                                                  className="mt-1 mb-1"
                                                >
                                                  Product Category
                                                </p>
                                              )}
                                            </div>
                                            <div
                                              className="cat-product mr-2 mr-md-1 "
                                              style={contentSize()}
                                            >
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/cate-dash.png")
                                                    .default
                                                }
                                                className="img-fluid"
                                                alt="Sale"
                                                style={imagesize()}
                                              />
                                              {this.state.categoryname ===
                                                true && (
                                                <p
                                                  style={{
                                                    color:
                                                      this.state.cattextcolor,
                                                    fontSize:
                                                      this.state.catfont,
                                                  }}
                                                  className="mt-1 mb-1"
                                                >
                                                  Product Category
                                                </p>
                                              )}
                                            </div>
                                            <div
                                              className="cat-product mr-2 mr-md-1 "
                                              style={contentSize()}
                                            >
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/cate-dash.png")
                                                    .default
                                                }
                                                className="img-fluid"
                                                alt="Sale"
                                                style={imagesize()}
                                              />
                                              {this.state.categoryname ===
                                                true && (
                                                <p
                                                  style={{
                                                    color:
                                                      this.state.cattextcolor,
                                                    fontSize:
                                                      this.state.catfont,
                                                  }}
                                                  className="mt-1 mb-1"
                                                >
                                                  Product Category
                                                </p>
                                              )}
                                            </div>
                                            <div
                                              className="cat-product mr-2 mr-md-1 "
                                              style={contentSize()}
                                            >
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/cate-dash.png")
                                                    .default
                                                }
                                                className="img-fluid"
                                                alt="Sale"
                                                style={imagesize()}
                                              />
                                              {this.state.categoryname ===
                                                true && (
                                                <p
                                                  style={{
                                                    color:
                                                      this.state.cattextcolor,
                                                    fontSize:
                                                      this.state.catfont,
                                                  }}
                                                  className="mt-1 mb-1"
                                                >
                                                  Product Category
                                                </p>
                                              )}
                                            </div>
                                            <div
                                              className="cat-product mr-2 mr-md-1 "
                                              style={contentSize()}
                                            >
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/cate-dash.png")
                                                    .default
                                                }
                                                className="img-fluid"
                                                alt="Sale"
                                                style={imagesize()}
                                              />
                                              {this.state.categoryname ===
                                                true && (
                                                <p
                                                  style={{
                                                    color:
                                                      this.state.cattextcolor,
                                                    fontSize:
                                                      this.state.catfont,
                                                  }}
                                                  className="mt-1 mb-1"
                                                >
                                                  Product Category
                                                </p>
                                              )}
                                            </div>
                                            <div
                                              className="cat-product mr-2 mr-md-1 "
                                              style={contentSize()}
                                            >
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/cate-dash.png")
                                                    .default
                                                }
                                                className="img-fluid"
                                                alt="Sale"
                                                style={imagesize()}
                                              />
                                              {this.state.categoryname ===
                                                true && (
                                                <p
                                                  style={{
                                                    color:
                                                      this.state.cattextcolor,
                                                    fontSize:
                                                      this.state.catfont,
                                                  }}
                                                  className="mt-1 mb-1"
                                                >
                                                  Product Category
                                                </p>
                                              )}
                                            </div>
                                            {this.state.showremaincate && (
                                              <>
                                                <div
                                                  className="cat-product mr-2 mr-md-1 "
                                                  style={contentSize()}
                                                >
                                                  <img
                                                    src={
                                                      require("../../assets/images/dashboard/cate-dash.png")
                                                        .default
                                                    }
                                                    className="img-fluid"
                                                    alt="Sale"
                                                    style={imagesize()}
                                                  />
                                                  {this.state.categoryname ===
                                                    true && (
                                                    <p
                                                      style={{
                                                        color:
                                                          this.state
                                                            .cattextcolor,
                                                        fontSize:
                                                          this.state.catfont,
                                                      }}
                                                      className="mt-1 mb-1"
                                                    >
                                                      Product Category
                                                    </p>
                                                  )}
                                                </div>
                                                <div
                                                  className="cat-product mr-2 mr-md-1 "
                                                  style={contentSize()}
                                                >
                                                  <img
                                                    src={
                                                      require("../../assets/images/dashboard/cate-dash.png")
                                                        .default
                                                    }
                                                    className="img-fluid"
                                                    alt="Sale"
                                                    style={imagesize()}
                                                  />
                                                  {this.state.categoryname ===
                                                    true && (
                                                    <p
                                                      style={{
                                                        color:
                                                          this.state
                                                            .cattextcolor,
                                                        fontSize:
                                                          this.state.catfont,
                                                      }}
                                                      className="mt-1 mb-1"
                                                    >
                                                      Product Category
                                                    </p>
                                                  )}
                                                </div>
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            <div
                                              className="cat-product solid-category mr-2 mr-md-1"
                                              style={contentSize()}
                                            >
                                              <p style={colorsize()}>
                                                Product Category
                                              </p>
                                            </div>
                                            <div
                                              className="cat-product solid-category mr-2 mr-md-1"
                                              style={contentSize()}
                                            >
                                              <p style={colorsize()}>
                                                Product Category
                                              </p>
                                            </div>
                                            <div
                                              className="cat-product solid-category mr-2 mr-md-1"
                                              style={contentSize()}
                                            >
                                              <p style={colorsize()}>
                                                Product Category
                                              </p>
                                            </div>
                                            <div
                                              className="cat-product solid-category mr-2 mr-md-1"
                                              style={contentSize()}
                                            >
                                              <p style={colorsize()}>
                                                Product Category
                                              </p>
                                            </div>
                                            <div
                                              className="cat-product solid-category mr-2 mr-md-1"
                                              style={contentSize()}
                                            >
                                              <p style={colorsize()}>
                                                Product Category
                                              </p>
                                            </div>
                                            <div
                                              className="cat-product solid-category mr-2 mr-md-1"
                                              style={contentSize()}
                                            >
                                              <p style={colorsize()}>
                                                Product Category
                                              </p>
                                            </div>
                                            {this.state.showremaincate && (
                                              <>
                                                <div
                                                  className="cat-product solid-category mr-2 mr-md-1"
                                                  style={contentSize()}
                                                >
                                                  <p style={colorsize()}>
                                                    Product Category
                                                  </p>
                                                </div>
                                                <div
                                                  className="cat-product solid-category mr-2 mr-md-1"
                                                  style={contentSize()}
                                                >
                                                  <p style={colorsize()}>
                                                    Product Category
                                                  </p>
                                                </div>
                                              </>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>

                                    <div
                                      className={
                                        this.state.saleitemscheckbox
                                          ? "sale_products1 section-items pt-3 pb-3"
                                          : "sale_products1 d-none"
                                      }
                                      style={{
                                        background:
                                          this.state.sectionBackgroundcolor,
                                        borderBottom: "1px solid #C1D7E7",
                                      }}
                                    >
                                      <div className="d-flex justify-content-between align-items-center pl-md-2 pl-3 pr-3 pr-md-2">
                                        <p
                                          style={{
                                            color:
                                              this.state.sectionHeadingcolor,
                                            fontFamily:
                                              this.state.fontfamilydashboard,
                                          }}
                                        >
                                          {this.state.sale_products_label}
                                        </p>
                                        <div
                                          className="seemoresection"
                                          style={{
                                            background:
                                              this.state.sectionbuttoncolor,
                                          }}
                                        >
                                          <svg
                                            width="5.591"
                                            height="9.061"
                                            viewBox="0 0 5.591 9.061"
                                          >
                                            <path
                                              id="Path_84774"
                                              data-name="Path 84774"
                                              d="M990.5,784l4,4-4,4"
                                              transform="translate(-989.969 -783.47)"
                                              fill="none"
                                              stroke={
                                                this.state
                                                  .sectionbuttontextcolor
                                              }
                                              stroke-width="1.5"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                      <div className="category-list pt-2">
                                        <div className="cat-product">
                                          {salesvg()}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        this.state.recentviewcheckbox
                                          ? "recently-viewed recently_viewed_products1 pb-3 width2"
                                          : "recently_viewed_products1 d-none"
                                      }
                                    >
                                      <p
                                        style={{
                                          fontFamily:
                                            this.state.fontfamilydashboard,
                                          background:
                                            this.state.sectionBackgroundcolor,
                                          color: this.state.sectionHeadingcolor,
                                        }}
                                        className="pt-3 pb-3 pl-md-2 pl-3"
                                      >
                                        {
                                          this.state
                                            .recently_viewed_products_label
                                        }
                                      </p>
                                      <div className="category-list pt-2 pl-3 pl-md-2 recent-dashboard">
                                        <div className="cat-product">
                                          {recentsvg()}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        this.state.featuredproductcheckbox
                                          ? "featured_products1 section-items pt-3 pb-3"
                                          : "featured_products1 d-none"
                                      }
                                      style={{
                                        background:
                                          this.state.sectionBackgroundcolor,
                                        borderBottom: "1px solid #C1D7E7",
                                      }}
                                    >
                                      <div className="d-flex justify-content-between align-items-center pl-md-2 pr-md-2  pl-3 pr-3 pb-2">
                                        <p
                                          style={{
                                            color:
                                              this.state.sectionHeadingcolor,
                                            fontFamily:
                                              this.state.fontfamilydashboard,
                                          }}
                                        >
                                          {this.state.featured_products_label}
                                        </p>
                                        <div
                                          className="seemoresection"
                                          style={{
                                            background:
                                              this.state.sectionbuttoncolor,
                                          }}
                                        >
                                          <svg
                                            width="5.591"
                                            height="9.061"
                                            viewBox="0 0 5.591 9.061"
                                          >
                                            <path
                                              id="Path_84774"
                                              data-name="Path 84774"
                                              d="M990.5,784l4,4-4,4"
                                              transform="translate(-989.969 -783.47)"
                                              fill="none"
                                              stroke={
                                                this.state
                                                  .sectionbuttontextcolor
                                              }
                                              stroke-width="1.5"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                      <div className="category-list">
                                        <div className="cat-product ">
                                          {featuredsvg()}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        this.state.Cartproductcheckbox
                                          ? "in-cart cart1 width2"
                                          : "cart1 d-none"
                                      }
                                    >
                                      <p
                                        style={{
                                          fontFamily:
                                            this.state.fontfamilydashboard,
                                          color: this.state.sectionHeadingcolor,
                                          background:
                                            this.state.sectionBackgroundcolor,
                                        }}
                                        className="pl-md-2 pr-md-2 pt-3 pb-3 pl-3 pr-3"
                                      >
                                        {this.state.incart_products_label}
                                      </p>
                                      <div className="category-list pt-2 pl-3 pl-md-2 recent-dashboard">
                                        <div className="cat-product">
                                          {incartsvg()}
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className={
                                        this.state.newarrivalscheckbox
                                          ? "section-items new_products1 arrival pb-3"
                                          : "new_products1 d-none"
                                      }
                                      style={{
                                        background:
                                          this.state.sectionBackgroundcolor,
                                        borderBottom: "1px solid #C1D7E7",
                                      }}
                                    >
                                      <div className="d-flex justify-content-between align-items-center  pl-md-2 pr-md-2 pt-3 pb-2 pl-3 pr-3">
                                        <p
                                          style={{
                                            color:
                                              this.state.sectionHeadingcolor,
                                            fontFamily:
                                              this.state.fontfamilydashboard,
                                          }}
                                        >
                                          {this.state.new_products_label}
                                        </p>
                                        <div
                                          className="seemoresection"
                                          style={{
                                            background:
                                              this.state.sectionbuttoncolor,
                                          }}
                                        >
                                          <svg
                                            width="5.591"
                                            height="9.061"
                                            viewBox="0 0 5.591 9.061"
                                          >
                                            <path
                                              id="Path_84774"
                                              data-name="Path 84774"
                                              d="M990.5,784l4,4-4,4"
                                              transform="translate(-989.969 -783.47)"
                                              fill="none"
                                              stroke={
                                                this.state
                                                  .sectionbuttontextcolor
                                              }
                                              stroke-width="1.5"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                      <div className="category-list d-flex pl-md-0">
                                        <div className="cat-product">
                                          {newsvg()}
                                        </div>
                                      </div>
                                    </div>
                                    {/* Blog banner */}
                                    <div
                                      className={
                                        this.state.blog_banner_display
                                          ? "blogbanner1 section-items pb-3"
                                          : "blogbanner1 d-none"
                                      }
                                      style={{
                                        borderBottom: "1px solid #C1D7E7",
                                        background:
                                          this.state.sectionBackgroundcolor,
                                      }}
                                    >
                                      <div
                                        className="d-flex justify-content-between align-items-center pl-md-2 pr-md-2 pt-3 pl-3 pr-3 pb-3"
                                        style={{
                                          background:
                                            this.state.sectionBackgroundcolor,
                                        }}
                                      >
                                        <p
                                          style={{
                                            color:
                                              this.state.sectionHeadingcolor,
                                          }}
                                        >
                                          {this.state.blog_banner_label}
                                        </p>
                                        <div
                                          className="seemoresection"
                                          style={{
                                            background:
                                              this.state.sectionbuttoncolor,
                                          }}
                                        >
                                          <svg
                                            width="5.591"
                                            height="9.061"
                                            viewBox="0 0 5.591 9.061"
                                          >
                                            <path
                                              id="Path_84774"
                                              data-name="Path 84774"
                                              d="M990.5,784l4,4-4,4"
                                              transform="translate(-989.969 -783.47)"
                                              fill="none"
                                              stroke={
                                                this.state
                                                  .sectionbuttontextcolor
                                              }
                                              stroke-width="1.5"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                      {blogbannersvg()}
                                    </div>

                                    {/* Blog banner end*/}
                                    {/* Blog Category*/}
                                    <div
                                      className={
                                        this.state.blog_categories_display
                                          ? "section-items blog_categories1"
                                          : "blog_categories1 d-none"
                                      }
                                      style={{
                                        background:
                                          this.state.sectionBackgroundcolor,
                                        borderBottom: "1px solid #C1D7E7",
                                      }}
                                    >
                                      <div className="d-flex justify-content-between align-items-center pl-md-2 pr-md-2 pt-3 pl-3 pr-3">
                                        <p
                                          style={{
                                            color:
                                              this.state.sectionHeadingcolor,
                                          }}
                                        >
                                          {this.state.blog_categories_label}
                                        </p>
                                        <div
                                          className="seemoresection"
                                          style={{
                                            background:
                                              this.state.sectionbuttoncolor,
                                          }}
                                        >
                                          <svg
                                            width="5.591"
                                            height="9.061"
                                            viewBox="0 0 5.591 9.061"
                                          >
                                            <path
                                              id="Path_84774"
                                              data-name="Path 84774"
                                              d="M990.5,784l4,4-4,4"
                                              transform="translate(-989.969 -783.47)"
                                              fill="none"
                                              stroke={
                                                this.state
                                                  .sectionbuttontextcolor
                                              }
                                              stroke-width="1.5"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                      <div className="category-list d-flex">
                                        <div className="cat-product p-2 pb-3">
                                          {blogcategorysvg(this)}
                                        </div>
                                      </div>
                                    </div>
                                    {/* Blog Category end*/}
                                    {/* Sticky blog */}
                                    <div
                                      className={
                                        this.state.sticky_blog_display
                                          ? "sticky_blogs1 section-items pt-3 pb-3"
                                          : "sticky_blogs1 d-none"
                                      }
                                      style={{
                                        background:
                                          this.state.sectionBackgroundcolor,
                                        borderBottom: "1px solid #C1D7E7",
                                      }}
                                    >
                                      <div className="d-flex justify-content-between align-items-center pl-md-2 pl-3 pr-3 pr-md-2">
                                        <p
                                          style={{
                                            color:
                                              this.state.sectionHeadingcolor,
                                            fontFamily:
                                              this.state.fontfamilydashboard,
                                          }}
                                        >
                                          {this.state.sticky_blogs_label}
                                        </p>
                                        <div
                                          className="seemoresection"
                                          style={{
                                            background:
                                              this.state.sectionbuttoncolor,
                                          }}
                                        >
                                          <svg
                                            width="5.591"
                                            height="9.061"
                                            viewBox="0 0 5.591 9.061"
                                          >
                                            <path
                                              id="Path_84774"
                                              data-name="Path 84774"
                                              d="M990.5,784l4,4-4,4"
                                              transform="translate(-989.969 -783.47)"
                                              fill="none"
                                              stroke={
                                                this.state
                                                  .sectionbuttontextcolor
                                              }
                                              stroke-width="1.5"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                      <div className="category-list pt-2">
                                        <div className="cat-product">
                                          {popularblogsvg()}
                                        </div>
                                      </div>
                                    </div>
                                    {/* Sticky blog end */}
                                    {/* Recent Blog*/}
                                    <div
                                      className={
                                        this.state.blogcheckbox
                                          ? "section-items blogs1"
                                          : "blogs1 d-none"
                                      }
                                    >
                                      <div
                                        className="d-flex justify-content-between align-items-center pl-md-2 pr-md-2 pt-3 pb-3 pl-3 pr-3"
                                        style={{
                                          background:
                                            this.state.sectionBackgroundcolor,
                                        }}
                                      >
                                        <p
                                          style={{
                                            color:
                                              this.state.sectionHeadingcolor,
                                          }}
                                        >
                                          {this.state.new_blogs_label}
                                        </p>
                                        <div
                                          className="seemoresection"
                                          style={{
                                            background:
                                              this.state.sectionbuttoncolor,
                                          }}
                                        >
                                          <svg
                                            width="5.591"
                                            height="9.061"
                                            viewBox="0 0 5.591 9.061"
                                          >
                                            <path
                                              id="Path_84774"
                                              data-name="Path 84774"
                                              d="M990.5,784l4,4-4,4"
                                              transform="translate(-989.969 -783.47)"
                                              fill="none"
                                              stroke={
                                                this.state
                                                  .sectionbuttontextcolor
                                              }
                                              stroke-width="1.5"
                                            />
                                          </svg>
                                        </div>
                                      </div>
                                      <div className="category-list d-flex">
                                        <div className="cat-product pl-2 pt-2 pb-2"  style={{
                                          background:
                                            this.state.sectionBackgroundcolor,
                                        }}>
                                          {recentblogsvg()}
                                        </div>
                                      </div>
                                    </div>
                                    {/* Recent Blog end*/}
                                  </div>
                                  {/* Web view */}
                                  <div
                                    className={
                                      this.state.web_view_display_bool
                                        ? "section-items web_view1 "
                                        : "web_view1 d-none"
                                    }
                                  >
                                    <div className="website-view">
                                      <div>
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/web-view.png")
                                              .default
                                          }
                                          alt="Web View"
                                          className="img-fluid"
                                        />
                                        <h6>
                                          You can see web view in real app
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                  {/* Web view end */}

                                  {/* Pages */}
                                  <div
                                    className={
                                      this.state.new_pages_display
                                        ? "pages1 section-items homescreen-new-bottompadding"
                                        : "pages1 d-none"
                                    }
                                  >
                                    {pagelistsvg(this.state.typeselection)}
                                  </div>
                                  <div
                                    className="click-to-scroll"
                                    onClick={this.scrollDown}
                                  >
                                    <img
                                      src={this.state.scrollimage}
                                      className="img-fluid"
                                      alt="nosubscription"
                                    />
                                  </div>
                                  {/* Pages end*/}
                                  <div
                                    className="bottom_menu_bar d-flex align-items-center justify-content-between bottom_menu_bar_responsive bottom-menu-topspacing"
                                    style={{
                                      background: this.state.bottommenuColor,
                                      borderTop:
                                        "1px solid" +
                                        this.state.bottommenuBordercolor,
                                    }}
                                  >
                                    {this.state.get_all_data &&
                                      this.state.get_all_data
                                        .bottom_menu_items &&
                                      this.state.get_all_data.bottom_menu_items.map(
                                        (row) => {
                                          return row.is_default === 1
                                            ? row.status === 1 && (
                                                <div
                                                  className={
                                                    this.state.get_all_data
                                                      .bottom_menu_style
                                                      .bottom_menu_show_text !==
                                                      1 && "bottom-icon-space"
                                                  }
                                                >
                                                  <img
                                                    style={{
                                                      filter: this.changeColorr(
                                                        this.state.get_all_data
                                                          .bottom_menu_style
                                                          .bottom_menu_selected_item_colour
                                                      ),
                                                    }}
                                                    className="img-fluid"
                                                    src={
                                                      process.env
                                                        .REACT_APP_Image_Path +
                                                      (() => {
                                                        switch (
                                                          row.item_icon_type
                                                        ) {
                                                          case "light":
                                                            return process.env
                                                              .REACT_APP_light_path;
                                                          case "bold":
                                                            return process.env
                                                              .REACT_APP_bold_path;
                                                          case "regular":
                                                            return process.env
                                                              .REACT_APP_regular_path;
                                                          default:
                                                            return process.env
                                                              .REACT_APP_light_path;
                                                        }
                                                      })() +
                                                      row.item_icon
                                                    }
                                                    alt="home-item"
                                                    width="20"
                                                  />
                                                  {this.state.get_all_data
                                                    .bottom_menu_style
                                                    .bottom_menu_show_text ===
                                                    1 && (
                                                    <p
                                                      style={{
                                                        color:
                                                          this.state
                                                            .get_all_data
                                                            .bottom_menu_style
                                                            .bottom_menu_selected_item_colour,
                                                      }}
                                                    >
                                                      {row.item_name}
                                                    </p>
                                                  )}
                                                </div>
                                              )
                                            : row.status === 1 && (
                                                <div
                                                  className={
                                                    this.state.get_all_data
                                                      .bottom_menu_style
                                                      .bottom_menu_show_text !==
                                                      1 && "bottom-icon-space"
                                                  }
                                                >
                                                  <img
                                                    style={{
                                                      filter: this.changeColorr(
                                                        this.state.get_all_data
                                                          .bottom_menu_style
                                                          .bottom_menu_icon_and_text_colour
                                                      ),
                                                    }}
                                                    className="img-fluid"
                                                    src={
                                                      process.env
                                                        .REACT_APP_Image_Path +
                                                      (() => {
                                                        switch (
                                                          row.item_icon_type
                                                        ) {
                                                          case "light":
                                                            return process.env
                                                              .REACT_APP_light_path;
                                                          case "bold":
                                                            return process.env
                                                              .REACT_APP_bold_path;
                                                          case "regular":
                                                            return process.env
                                                              .REACT_APP_regular_path;
                                                          default:
                                                            return process.env
                                                              .REACT_APP_light_path;
                                                        }
                                                      })() +
                                                      row.item_icon
                                                    }
                                                    alt="home-item"
                                                    width="20"
                                                  />
                                                  {this.state.get_all_data
                                                    .bottom_menu_style
                                                    .bottom_menu_show_text ===
                                                    1 && (
                                                    <p
                                                      style={{
                                                        color:
                                                          this.state
                                                            .get_all_data
                                                            .bottom_menu_style
                                                            .bottom_menu_icon_and_text_colour,
                                                      }}
                                                    >
                                                      {row.item_name}
                                                    </p>
                                                  )}
                                                </div>
                                              );
                                        }
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* {InnerFoot()} */}
          </div>
        </section>
        <RebuildRequired rebuildpop={this.state.rebuildpopvalue} btnRebuildCancel={this.closeRequireBuildPopup} submitform={this.submitfunction}/>
        <Modal
          centered
          isOpen={this.state.modal}
          toggle={this.toggle}
          className={this.props.className}
          style={{ maxWidth: "80%" }}
        >
          <ModalHeader toggle={this.toggle} className="modal-xxl-content">
            <div className="modal-header splash-modal align-items-center pt-2 pl-0 pr-0 pb-0">
              <div
                className={
                  this.state.uploadcustomimage
                    ? "d-md-flex d-block align-items-center justify-content-between w-100 choose-from-library"
                    : "d-md-flex d-block align-items-center w-100"
                }
              >
                <div className="modaltext">
                  <h4>
                    {" "}
                    {this.state.uploadcustomimage
                      ? "Upload Image"
                      : "Select Image"}
                  </h4>
                </div>
                {this.state.uploadcustomimage ? (
                  <div></div>
                ) : (
                  <div className="form-group d-flex align-items-center search-box w-100">
                    <form
                      className="d-flex align-items-center w-100"
                      onSubmit={this.pixalsearch}
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search image from library..."
                        value={this.state.pixalsearchvalue}
                        onChange={(e) => this.handlepixabaysearch(e)}
                      />
                      <button type="submit">
                        <img
                          src={
                            require("../../assets/images/dashboard/search.png")
                              .default
                          }
                          alt="search"
                          className="img-fluid"
                        />
                      </button>
                    </form>
                  </div>
                )}
                <div className="upload-image ml-3">
                  <div
                    className="d-flex align-items-center justify-content-center"
                    onClick={this.toggleupload}
                  >
                    {this.state.uploadcustomimage ? (
                      "Choose From Library"
                    ) : (
                      <>
                        <img
                          src={
                            require("../../assets/images/dashboard/plus.png")
                              .default
                          }
                          alt="add-icon"
                          className="img-fluid mr-2"
                        />
                        Upload Image
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ModalHeader>
          <ModalBody className="p-0 for-mobile">
            <BottomScrollListener
              onBottom={this.handleContainerOnBottom}
              offset={500}
            >
              {(scrollRef) => (
                <div className="modal-body-content" ref={scrollRef}>
                  {this.state.pixelspinner ? (
                    <div className="spinn">
                      <Spinner animation="border" variant="primary" />
                    </div>
                  ) : (
                    <></>
                  )}
                  {/* Pixabay */}
                  <div className="h-100 w-100">
                    <div className="d-flex flex-wrap align-items-center">
                      <div className="first_app  w-100">
                        <div className=" d-flex flex-wrap w-100">
                          {this.state.uploadcustomimage ? (
                            <div className="upload_files mt-4 w-100">
                              <div className="large-up w-100">
                                <label htmlFor="getvale">
                                  {this.state.getimage ? (
                                    <div className="up">
                                      <input
                                        type="file"
                                        onChange={(e) => {
                                          this.uploadcustomdefaultimage(e);
                                          e.target.value = null;
                                        }}
                                        id="getvale"
                                      />
                                      {this.state.uploadtext !== "" && (
                                        <div className="h-100 w-100 d-flex align-items-center justify-content-center">
                                          <img
                                            src={
                                              this.state
                                                .uploadcustomdefaultimage
                                            }
                                            style={{
                                              width: "500px",
                                              marginBottom: "20px",
                                              marginTop: "30px",
                                            }}
                                            alt="bannerimage"
                                          />
                                        </div>
                                      )}
                                      <p
                                        style={{
                                          color: "rgb(119, 130, 161)",
                                          display: "block",
                                          textAlign: "center",
                                          marginTop: "10px",
                                          fontSize: "19px",
                                        }}
                                        className="mb-1"
                                      >
                                        {this.state.uploadtext}
                                      </p>

                                      <div
                                        className={
                                          this.state.uploadtext === ""
                                            ? "h-100 w-100 d-flex align-items-center justify-content-center mt-4"
                                            : "h-100 w-100 d-flex align-items-center justify-content-center"
                                        }
                                      >
                                        <div className="text-center generallogo">
                                          {this.state.uploadtext === "" && (
                                            <>
                                              <img
                                                src={
                                                  require("../../assets/images/dashboard/largecloud.png")
                                                    .default
                                                }
                                                className="img-fluid"
                                                alt="cloud"
                                              />
                                              <h6
                                                style={{
                                                  fontSize: "28px",
                                                  marginTop: "15px",
                                                }}
                                              >
                                                Upload image
                                              </h6>
                                            </>
                                          )}
                                          <p
                                            style={{
                                              fontSize:
                                                this.state.uploadtext === ""
                                                  ? "23px"
                                                  : "18px",
                                            }}
                                          >
                                            Minimum size 1125*480px
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <Imagecrop
                                      imagename={
                                        this.state.uploadcustomdefaultimage
                                      }
                                      originalpath={
                                        process.env.REACT_APP_Dashboard_Original
                                      }
                                      imagepath={
                                        process.env.REACT_APP_Dashboard_Crop
                                      }
                                      whenTogglemodal={this.whenTogglemodal}
                                      finalcrop={this.finalcrop}
                                      cropwidth={1125}
                                      cropheight={480}
                                      modal={true}
                                    />
                                  )}
                                </label>
                              </div>
                            </div>
                          ) : this.state.pixalimagenotavailable ? (
                            "Not Found"
                          ) : (
                            this.state.pixalbayimage.map((pixalimages, i) => (
                              <div
                                className="app_dummy"
                                style={{
                                  backgroundImage: "url(" + pixalimages + ")",
                                }}
                                key={i}
                              >
                                <label htmlFor={i} className="w-100 h-100">
                                  <input
                                    type="radio"
                                    name="pixabayimages"
                                    className="d-none"
                                    id={i}
                                    value={pixalimages}
                                    checked={
                                      this.state.chooseImage === pixalimages
                                    }
                                    onChange={(e) => this.handlechooseimage(e)}
                                  />
                                  <span className="activestock"></span>
                                </label>
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* Pixabay End*/}

                  {/* Upload */}

                  {/* Upload End*/}
                </div>
              )}
            </BottomScrollListener>
          </ModalBody>
          <ModalFooter>
            <div className="footer-button  w-100">
              <div className="d-flex align-items-center justify-content-between">
                <button className="back_btn mr-2 ml-md-3" onClick={this.toggle}>
                  Cancel
                </button>
                <button
                  className="next_btn modal-next mr-md-3"
                  onClick={this.submitChooseImage}
                >
                  {this.state.submitbuttontext}
                </button>
              </div>
            </div>
          </ModalFooter>
        </Modal>
        {/* Categories modal */}
        <Modal
          isOpen={this.state.modalSolidColor}
          toggle={this.togglemodalSolidColor}
          style={{ maxWidth: "651px", maxHeight: "350px" }}
          className={this.props.className}
          centered
        >
          <ModalHeader
            toggle={this.togglemodalSolidColor}
            className="modal-xxl-content modal-categories"
          >
            <div
              className="crossmodal mt-1"
              onClick={this.togglemodalSolidColor}
            >
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="categories-show">
            <div className="d-flex justify-content-start mb-4 modal-1">
              <div>{catemodalsecond()}</div>
              <div className="ml-3">
                <h2>Categories with Solid Color</h2>
                <h3 className="mt-2">
                  Style the category display for mobile app with attractive
                  colors of your choice. Select a distinct color scheme to
                  define each category.
                </h3>
              </div>
            </div>
            <div className="solid-categories mb-4">
              <p className="mb-0">Categories</p>
              <div className="d-flex justify-content-between align-items-center pb-md-3 pb-2 pt-1">
                <div className="blue-box">
                  <h4>Men Fashion</h4>
                </div>
                <div className="blue-box">
                  <h4>Women Fashion</h4>
                </div>
                <div className="blue-box">
                  <h4>Kids Fashion</h4>
                </div>
                <div className="blue-box d-none d-md-flex">
                  <h4>Beauty</h4>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center ">
                <div className="blue-box">
                  <h4>Sports</h4>
                </div>
                <div className="blue-box">
                  <h4>Tech</h4>
                </div>
                <div className="blue-box">
                  <h4>Accessories</h4>
                </div>
                <div className="blue-box d-none d-md-flex">
                  <h4>Home</h4>
                </div>
              </div>
            </div>
            <div className="text-left mb-4">
              <h5>
                Did not understand how it works?{" "}
                {/* <Media
                  href="https://www.appmysite.com/support/how-to-design-app-home-screen/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More{" "}
                  <img
                    src={
                      require("../../assets/images/dashboard/Goto.png").default
                    }
                    className="gotoappicon appear-spacing"
                    alt="Goto"
                  />
                </Media>{" "} */}
              </h5>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={this.state.modalBackgroundImage}
          toggle={this.togglemodalBackgroundImage}
          style={{ maxWidth: "775px", maxHeight: "350px" }}
          className={this.props.className}
          centered
        >
          <ModalHeader
            toggle={this.togglemodalBackgroundImage}
            className="modal-xxl-content modal-categories"
          >
            <div
              className="crossmodal mt-1"
              onClick={this.togglemodalBackgroundImage}
            >
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="categories-show">
            <div className="d-flex justify-content-start mb-4 modal-1">
              <div>{catemodalfirst()}</div>
              <div className="ml-3">
                <h2>Categories with background image</h2>
                <h3 className="mt-2">
                  Import categories from your online store with images. Make
                  sure that all categories on your website have a relevant image
                  that will reflect on your app.
                </h3>
              </div>
            </div>
            <div className="text-center mb-4">
              <img
                src={
                  require("../../assets/images/dashboard/category-1.png")
                    .default
                }
                alt="Connecting"
                className="img-fluid"
              />
            </div>
            <div className="text-left mb-4">
              <h5>
                Did not understand how it works?{" "}
                {/* <Media
                  href="https://www.appmysite.com/support/how-to-design-app-home-screen/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More{" "}
                  <img
                    src={
                      require("../../assets/images/dashboard/Goto.png").default
                    }
                    className="gotoappicon appear-spacing"
                    alt="Goto"
                  />
                </Media>{" "} */}
              </h5>
            </div>
          </ModalBody>
        </Modal>

        {/* Selected popup */}
        <Modal
          isOpen={this.state.categorychange}
          toggle={this.handlecategorychange}
          style={{ maxWidth: "550px", maxHeight: "350px" }}
          dataclass="categorypop"
          className={this.props.className}
          centered
        >
          <ModalHeader
            toggle={this.handlecategorychange}
            className="modal-xxl-content modal-categories update-type"
          >
            <div
              className="crossmodal mt-1"
              onClick={(e) => this.handlecategorychange(e)}
            >
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="conecttoapi publish-app-modal category-modal">
            <div className="text-center mb-md-3 mb-4">
              <img
                src={
                  require("../../assets/images/dashboard/real-app.png").default
                }
                alt="Connecting"
                className="img-fluid"
              />
            </div>
            <div className="text-center mb-md-3 mb-3">
              <h2>You have unsaved changes on this page.</h2>
            </div>
            <div className="text-center mb-md-4 mb-4">
              <p>Are you sure you want to proceed?</p>
            </div>
            <div className="text-center mb-4 d-flex website-type  align-items-center justify-content-center">
              <div
                className="cancel-btn"
                onClick={(e) => this.handlecategorychange(e)}
              >
                Cancel
              </div>
              <div
                className="update-btn ml-3"
                onClick={(e) => this.dashboardSubmit(e, "popupform")}
              >
                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                  <div className={this.state.poploader}>
                    <img src={this.state.poploaderimage} alt="loader" />
                  </div>{" "}
                  <div>{this.state.popsubmitval}</div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        {/* Web View popup */}
        <Modal
          isOpen={this.state.web_view_modal}
          toggle={this.handleweb_view_modal}
          style={{ maxWidth: "550px", maxHeight: "350px" }}
          dataclass="categorypop"
          className={this.props.className}
          centered
        >
          <ModalHeader
            toggle={this.handleweb_view_modal}
            className="modal-xxl-content modal-categories update-type"
          >
            <div
              className="crossmodal mt-1"
              onClick={(e) => this.handleweb_view_modal(e)}
            >
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="conecttoapi publish-app-modal category-modal">
            <div className="text-center mb-md-3 mb-4">
              <img
                src={
                  require("../../assets/images/dashboard/web_view_modal_alert.png")
                    .default
                }
                alt="Connecting"
                className="img-fluid"
              />
            </div>

            <div className="text-center mb-md-4 mb-4">
              <p>
                If your web view does not appear as expected in the app, we
                recommend turning off all other features on the Home Screen for
                a better browsing experience.{" "}
              </p>
            </div>
            <div className="text-center mb-4 d-flex website-type  align-items-center justify-content-center">
              <div
                className="update-btn ml-3"
                onClick={(e) => this.handleweb_view_modal(e)}
              >
                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                  OKAY
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
