import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import crossIcon from "../../../assets/images/dashboard/cross.png";

const VerifyPopup = ({ settingsState, handleverifymodal }) => {
  return (
    <div>
      {" "}
      <Modal
        isOpen={settingsState.verifypopmodal}
        toggle={settingsState.handleverifymodal}
        className="generalstngs2 varifyPopupupdiv productTechnologyModal"
        centered
      >
        <ModalHeader>
          <div className="crossmodal mt-1" onClick={handleverifymodal}>
            <img src={crossIcon} alt="cross-icon" />
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="productTechnologyModal-body">
            <h4 className="productTechnologyModal-body-head">Domain verification required</h4>
            <h6 className="productTechnologyModal-body-para">
              Your app is now linked to a new web address. We strongly recommend
              conducting thorough testing of your app, including connectivity
              tests to ensure seamless web-app connectivity.
            </h6>

            <div className="generalstngs2-footer">
              <div
                className="generalstngs2-ok domain-vrfy"
                onClick={handleverifymodal}
              >
                <p className="productTechnologyModal-ok-text">Okay</p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default VerifyPopup;
