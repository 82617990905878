import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import goBack from "../../../assets/images/dashboard/goBackicon.png";
import crossIcon from "../../../assets/images/dashboard/cross.png";
import customLogo from "../../../assets/images/dashboard/new-custom.png";
import wooLogo from "../../../assets/images/dashboard/new-woocomerce.png";
import wpLogo from "../../../assets/images/dashboard/new-wordpress.png";
import w2aLogo from "../../../assets/images/dashboard/new-website.png";
const ChangeProductModal = ({
  settingsState,
  handlePaidSubs,
  handletypetab,
  handletypechangepopup,
}) => {
  return (
    <div>
      <Modal
        isOpen={settingsState.isPaid}
        
        className="move-app-popup product-change-modal"
        centered
      >
        <ModalBody>
          <div className="move-app-body">
            <div className="moveapp-header">
              <div className="crossmodal mt-1" onClick={handlePaidSubs}>
                <img src={crossIcon} alt="cross-icon" />
              </div>

              <div className="move-back-arrow" onClick={handlePaidSubs}>
                <img src={goBack} alt="back-icon" />
              </div>
            </div>

            <h1 className="setting-tech-change-head">Change product</h1>
            <h2 className="seting-tech-change-para">
              The process of changing your underlying product technology can be
              intricate, so please proceed with caution.
            </h2>

            <div className="select-move-type">
              <label class="general-setting-radio-button change-active-app">
                <span class="general-setting-radio-button__label">
                  <div className="move-app-view">
                    <img src={w2aLogo} alt="web-to-app-logo" />
                    <div>
                      <h5 className="select-tech-change-head">Web to app</h5>
                    </div>
                  </div>
                </span>
                <input
                  type="radio"
                  class="general-setting-radio-button__input"
                  id="choicetype1-1"
                  name="choicetype"
                  defaultChecked={settingsState.website_technology == 2}
                  onClick={(e) => {
                    handletypetab(2);
                  }}
                />
                <span class="general-setting-radio-button__control"></span>
              </label>

              <label class="general-setting-radio-button">
                <span class="general-setting-radio-button__label">
                  <div className="move-app-view">
                    <img src={wpLogo} alt="wordpress-logo" />
                    <div>
                      <h5 className="select-tech-change-head">
                        WordPress to app
                      </h5>
                    </div>
                  </div>
                </span>
                <input
                  type="radio"
                  class="general-setting-radio-button__input"
                  id="choicetype1-2"
                  name="choicetype"
                  defaultChecked={settingsState.website_technology == 4}
                  onClick={(e) => {
                    handletypetab(4);
                  }}
                />
                <span class="general-setting-radio-button__control"></span>
              </label>

              <label class="general-setting-radio-button">
                <span class="general-setting-radio-button__label">
                  <div className="move-app-view">
                    <img src={wooLogo} alt="woo-logo" />
                    <div>
                      <h5 className="select-tech-change-head">
                        WooCommerce to app
                      </h5>
                    </div>
                  </div>
                </span>
                <input
                  type="radio"
                  class="general-setting-radio-button__input"
                  id="choicetype1-3"
                  name="choicetype"
                  defaultChecked={settingsState.website_technology == 5}
                  onClick={(e) => {
                    handletypetab(5);
                  }}
                />
                <span class="general-setting-radio-button__control"></span>
              </label>

              <label class="general-setting-radio-button">
                <span class="general-setting-radio-button__label">
                  <div className="move-app-view">
                    <img src={customLogo} alt="custom-app-logo" />
                    <div>
                      <h5 className="select-tech-change-head">Custom app</h5>
                    </div>
                  </div>
                </span>
                <input
                  type="radio"
                  class="general-setting-radio-button__input"
                  id="choicetype1-4"
                  name="choicetype"
                  defaultChecked={settingsState.website_technology == 3}
                  onClick={(e) => {
                    handletypetab(3);
                  }}
                />
                <span class="general-setting-radio-button__control"></span>
              </label>
            </div>
          </div>

          <div className="move-app-footer">
            <div className="move-app-cancel">
              <p onClick={handlePaidSubs}>Cancel</p>
            </div>

            <div
              className="move-app-ok"
              style={{
                color:
                  parseInt(settingsState.website_technology) ===
                  parseInt(settingsState.website_technology1)
                    ? "#fff"
                    : "",
                backgroundColor:
                  parseInt(settingsState.website_technology) ===
                  parseInt(settingsState.website_technology1)
                    ? "#bcc1ce"
                    : "",
                cursor:
                  parseInt(settingsState.website_technology) ===
                  parseInt(settingsState.website_technology1)
                    ? "not-allowed"
                    : "",
                border:
                  parseInt(settingsState.website_technology) ===
                  parseInt(settingsState.website_technology1)
                    ? "unset"
                    : "",
              }}
            >
              <p
                onClick={() =>
                  parseInt(settingsState.website_technology) ===
                  parseInt(settingsState.website_technology1)
                    ? null
                    : handletypechangepopup()
                }
              >
                Done
              </p>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ChangeProductModal;
