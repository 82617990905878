import React from 'react'

const SoftImage = ({productState, handleStateChange}) => {
  return (
    <svg
    onClick={(e) =>
      handleStateChange("updateImageShape", "soft_corner")
    }
    className={
      productState.imageShapeOnListingPage ===
      "soft_corner"
        ? "product-shapeselect"
        : "product-shapenotselect"
    }
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <g
      id="Rectangle_18739"
      data-name="Rectangle 18739"
      fill="#fff"
      stroke=""
      stroke-width="2"
    >
      <rect width="18" height="18" rx="5" stroke="none" />
      <rect
        x="1"
        y="1"
        width="16"
        height="16"
        rx="4"
        fill="none"
      />
    </g>
  </svg>
  )
}

export default SoftImage