import React from 'react';
import { Bar } from "react-chartjs-2";
class Histogram extends React.Component{
    
    render(){
        
        return(
        <>
            <Bar data={this.props.value}
            options={{ legend: {
                display: false,
            },maintainAspectRatio: false }}
            />
        </> 
        )
    }
}

export {Histogram as default}