import React from "react";
import fashionimg2 from "../../../assets/images/dashboard/homescreen/2_column.png";

import fashionimg4 from "../../../assets/images/dashboard/homescreen/2_column.png";

import fashionimg3 from "../../../assets/images/dashboard/homescreen/2_column.png";
import fashionimg1 from "../../../assets/images/dashboard/homescreen/1_column.png";
import fashionimg5 from "../../../assets/images/dashboard/homescreen/4_column.png";

const ProductsPreview = ({
  blockIndx,
  productPosition,
  productItemType,
  productLayout,
  productEnable,
  productColumn,
  productRow,
  productShape,

  productPadding,
  productShowHeading,
  productHeadingColor,
  productHeadingIconColor,
  productHeadingIconBgColor,
  productWholeSectionBgColor,
  productHeadingLabel,
}) => {
  const calculateHeight = (width, layout) => {
    let height = (width * 3) / 4;

    switch (true) {
      case layout.image_aspect_ratio === "4:3":
        height = (width * 3) / 4;
        break;
      case layout.image_aspect_ratio === "3:4":
        height = (width * 4) / 3;
        break;
      case layout.image_aspect_ratio === "1:1":
        height = (width * 1) / 1;
        break;
      // case "16:9":
      //   height = (width * 9) / 16;
      //   break;
    }
    return height;
  };

  const PorductHeadingName = () => {
    let pName = "";
    switch (productItemType) {
      case "featured_products":
        pName =
          productHeadingLabel === "" ? "Featured Product" : productHeadingLabel;
        break;
      case "sale_products":
        pName =
          productHeadingLabel === "" ? "Sale Product" : productHeadingLabel;
        break;
      case "new_products":
        pName =
          productHeadingLabel === "" ? "New Product" : productHeadingLabel;
        break;
      case "recently_viewed_products":
        pName =
          productHeadingLabel === ""
            ? "Recently viewed Product"
            : productHeadingLabel;
        break;
      case "cart":
        pName = productHeadingLabel === "" ? "Cart" : productHeadingLabel;
        break;
    }
    return pName;
  };
  return (
    <>
      <div
        className="products-section"
        style={{ background: productWholeSectionBgColor }}
      >
        {productShowHeading === 1 && (
          <div className="category-head" style={{ paddingRight: "0px" }}>
            <h2
              style={{
                color: productHeadingColor,
              }}
            >
              {PorductHeadingName()}
            </h2>
            <div
              className="seemoresection"
              style={{
                background: productHeadingIconBgColor,
              }}
            >
              <svg
                id="Group_26467"
                data-name="Group 26467"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                {/* <circle
                id="Ellipse_6953"
                data-name="Ellipse 6953"
                cx="8"
                cy="8"
                r="8"
                // style={{
                //   background: blogHeadingIconBgColor,
                // }}
                fill={blogHeadingIconBgColor}
              /> */}
                <path
                  id="Path_81302"
                  data-name="Path 81302"
                  d="M0,0,2.986,2.987,5.973,0"
                  transform="translate(6.499 11.473) rotate(-90)"
                  fill="none"
                  stroke={productHeadingIconColor}
                  stroke-width="1.5"
                />
              </svg>
            </div>
          </div>
        )}

        <div className="products-grid">
          {productColumn === 1 &&
            productShape === "sharp_corner" &&
            Array.from({
              length: productRow,
            }).map((it, index) => {
              if (productLayout.name === "product_n*2") {
                return (
                  <div
                    className="single-product-one"
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundImage: "url(" + fashionimg1 + ")",
                        height: calculateHeight(264, productLayout),
                        width: "264px",
                      }}
                    >
                      {productItemType === "sale_products" && (
                        <img
                          className="img-fluid product-discount"
                          src={
                            require("../../../assets/images/dashboard/homescreen/discount.png")
                              .default
                          }
                        />
                      )}
                    </div>
                    <img
                      className="img-fluid"
                      src={
                        require("../../../assets/images/dashboard/homescreen/productname4.png")
                          .default
                      }
                    />
                  </div>
                );
              } else if (productLayout.name === "product_3") {
                return (
                  <div className="single-product-three">
                    <div>
                      <div
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg1 + ")",
                          height: calculateHeight(185, productLayout),
                          width: "185px",
                          position: "relative",
                          marginRight: "10px",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname3.png")
                            .default
                        }
                      />
                    </div>

                    <div>
                      <div>
                        <div
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage: "url(" + fashionimg2 + ")",
                            height: calculateHeight(70, productLayout),
                            width: "70px",
                            position: "relative",
                          }}
                        >
                          {productItemType === "sale_products" && (
                            <img
                              className="img-fluid product-discount"
                              src={
                                require("../../../assets/images/dashboard/homescreen/discount.png")
                                  .default
                              }
                            />
                          )}
                        </div>
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/homescreen/productname5.png")
                              .default
                          }
                        />
                      </div>
                      <div className="mt-3">
                        <div
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage: "url(" + fashionimg3 + ")",
                            height: calculateHeight(70, productLayout),
                            width: "70px",
                            position: "relative",
                          }}
                        >
                          {productItemType === "sale_products" && (
                            <img
                              className="img-fluid product-discount"
                              src={
                                require("../../../assets/images/dashboard/homescreen/discount.png")
                                  .default
                              }
                            />
                          )}
                        </div>
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/homescreen/productname5.png")
                              .default
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              } else if (productLayout.name === "product_1*n") {
                return (
                  <div className="single-product-four">
                    <div className="slider-product-width">
                      <div
                        style={{
                          position: "relative",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg4 + ")",
                          height: calculateHeight(116, productLayout),
                          width: "116px",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname1.png")
                            .default
                        }
                      />
                    </div>

                    {/* <div className="slider-product-width">        
                  <div style={{
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundImage : "url(" + fashionimg7 + ")",
                                height: "141px",
                                width: "116px",
                    }}>
                    </div>
                    <img className="img-fluid" src={require("../../../assets/images/dashboard/homescreen/productname1.png").default}/>
                  </div>

                  <div className="slider-product-width">        
                  <div style={{
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                                backgroundImage : "url(" + fashionimg7 + ")",
                                height: "141px",
                                width: "116px",
                    }}>
                    </div>
                    <img className="img-fluid" src={require("../../../assets/images/dashboard/homescreen/productname1.png").default}/>
                  </div> */}
                  </div>
                );
              } else if (productLayout.name === "product_n*1") {
                return (
                  <div className="single-product-two">
                    <div
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundImage: "url(" + fashionimg5 + ")",
                        height: calculateHeight(80, productLayout),
                        width: "80px",
                        position: "relative",
                        marginRight: "10px",
                      }}
                    >
                      {productItemType === "sale_products" && (
                        <img
                          className="img-fluid product-discount"
                          src={
                            require("../../../assets/images/dashboard/homescreen/discount.png")
                              .default
                          }
                        />
                      )}
                    </div>
                    <img
                      className="img-fluid"
                      src={
                        require("../../../assets/images/dashboard/homescreen/productname3.png")
                          .default
                      }
                    />
                  </div>
                );
              }
            })}

          {productColumn === 1 &&
            productShape === "round_corner" &&
            Array.from({
              length: productRow,
            }).map((it, index) => {
              if (productLayout.name === "product_n*2") {
                return (
                  <div
                    className="single-product-one"
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundImage: "url(" + fashionimg1 + ")",
                        height: calculateHeight(264, productLayout),
                        width: "264",
                        borderRadius: "10px",
                      }}
                    >
                      {productItemType === "sale_products" && (
                        <img
                          className="img-fluid product-discount"
                          src={
                            require("../../../assets/images/dashboard/homescreen/discount.png")
                              .default
                          }
                        />
                      )}
                    </div>
                    <img
                      className="img-fluid"
                      src={
                        require("../../../assets/images/dashboard/homescreen/productname4.png")
                          .default
                      }
                    />
                  </div>
                );
              } else if (productLayout.name === "product_3") {
                return (
                  <div className="single-product-three">
                    <div>
                      <div
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg1 + ")",
                          height: calculateHeight(185, productLayout),
                          width: "185px",
                          position: "relative",
                          marginRight: "10px",
                          borderRadius: "10px",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname3.png")
                            .default
                        }
                      />
                    </div>

                    <div>
                      <div>
                        <div
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage: "url(" + fashionimg2 + ")",
                            height: calculateHeight(70, productLayout),
                            width: "70px",
                            position: "relative",
                            borderRadius: "10px",
                          }}
                        >
                          {productItemType === "sale_products" && (
                            <img
                              className="img-fluid product-discount"
                              src={
                                require("../../../assets/images/dashboard/homescreen/discount.png")
                                  .default
                              }
                            />
                          )}
                        </div>
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/homescreen/productname5.png")
                              .default
                          }
                        />
                      </div>
                      <div className="mt-3">
                        <div
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage: "url(" + fashionimg3 + ")",
                            height: calculateHeight(70, productLayout),
                            width: "70px",
                            position: "relative",
                            borderRadius: "10px",
                          }}
                        >
                          {productItemType === "sale_products" && (
                            <img
                              className="img-fluid product-discount"
                              src={
                                require("../../../assets/images/dashboard/homescreen/discount.png")
                                  .default
                              }
                            />
                          )}
                        </div>
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/homescreen/productname5.png")
                              .default
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              } else if (productLayout.name === "product_1*n") {
                return (
                  <div className="single-product-four">
                    <div className="slider-product-width">
                      <div
                        style={{
                          position: "relative",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg4 + ")",
                          height: calculateHeight(116, productLayout),
                          width: "116px",
                          borderRadius: "10px",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname1.png")
                            .default
                        }
                      />
                    </div>
                  </div>
                );
              } else if (productLayout.name === "product_n*1") {
                return (
                  <div className="single-product-two">
                    <div
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundImage: "url(" + fashionimg5 + ")",
                        height: calculateHeight(80, productLayout),
                        width: "80px",
                        position: "relative",
                        marginRight: "10px",
                        borderRadius: "10px",
                      }}
                    >
                      {productItemType === "sale_products" && (
                        <img
                          className="img-fluid product-discount"
                          src={
                            require("../../../assets/images/dashboard/homescreen/discount.png")
                              .default
                          }
                        />
                      )}
                    </div>
                    <img
                      className="img-fluid"
                      src={
                        require("../../../assets/images/dashboard/homescreen/productname3.png")
                          .default
                      }
                    />
                  </div>
                );
              }
            })}

          {productColumn === 1 &&
            productShape === "circular" &&
            Array.from({
              length: productRow,
            }).map((it, index) => {
              if (productLayout.name === "product_n*2") {
                return (
                  <div
                    className="single-product-one"
                    style={{
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundImage: "url(" + fashionimg1 + ")",
                        height: calculateHeight(264, productLayout),
                        width: "264px",

                        borderRadius: "100%",
                      }}
                    >
                      {productItemType === "sale_products" && (
                        <img
                          className="img-fluid product-discount"
                          src={
                            require("../../../assets/images/dashboard/homescreen/discount.png")
                              .default
                          }
                        />
                      )}
                    </div>
                    <img
                      className="img-fluid"
                      src={
                        require("../../../assets/images/dashboard/homescreen/productname4.png")
                          .default
                      }
                    />
                  </div>
                );
              } else if (productLayout.name === "product_3") {
                return (
                  <div className="single-product-three">
                    <div>
                      <div
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg1 + ")",
                          height: calculateHeight(185, productLayout),
                          width: "185px",
                          position: "relative",
                          marginRight: "10px",
                          borderRadius: "100%",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname3.png")
                            .default
                        }
                      />
                    </div>

                    <div>
                      <div>
                        <div
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage: "url(" + fashionimg2 + ")",
                            height: calculateHeight(70, productLayout),
                            width: "70px",
                            position: "relative",
                            borderRadius: "100%",
                          }}
                        >
                          {productItemType === "sale_products" && (
                            <img
                              className="img-fluid product-discount"
                              src={
                                require("../../../assets/images/dashboard/homescreen/discount.png")
                                  .default
                              }
                            />
                          )}
                        </div>
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/homescreen/productname5.png")
                              .default
                          }
                        />
                      </div>
                      <div className="mt-3">
                        <div
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage: "url(" + fashionimg3 + ")",
                            height: calculateHeight(70, productLayout),
                            width: "70px",
                            position: "relative",
                            borderRadius: "100%",
                          }}
                        >
                          {productItemType === "sale_products" && (
                            <img
                              className="img-fluid product-discount"
                              src={
                                require("../../../assets/images/dashboard/homescreen/discount.png")
                                  .default
                              }
                            />
                          )}
                        </div>
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/homescreen/productname5.png")
                              .default
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              } else if (productLayout.name === "product_1*n") {
                return (
                  <div className="single-product-four">
                    <div className="slider-product-width">
                      <div
                        style={{
                          position: "relative",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg4 + ")",
                          height: calculateHeight(116, productLayout),
                          width: "116px",
                          borderRadius: "100%",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname1.png")
                            .default
                        }
                      />
                    </div>
                  </div>
                );
              } else if (productLayout.name === "product_n*1") {
                return (
                  <div className="single-product-two">
                    <div
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundImage: "url(" + fashionimg5 + ")",
                        height: calculateHeight(80, productLayout),
                        width: "80px",
                        position: "relative",
                        marginRight: "10px",
                        borderRadius: "100%",
                      }}
                    >
                      {productItemType === "sale_products" && (
                        <img
                          className="img-fluid product-discount"
                          src={
                            require("../../../assets/images/dashboard/homescreen/discount.png")
                              .default
                          }
                        />
                      )}
                    </div>
                    <img
                      className="img-fluid"
                      src={
                        require("../../../assets/images/dashboard/homescreen/productname3.png")
                          .default
                      }
                    />
                  </div>
                );
              }
            })}

          {productColumn === 2 &&
            productShape === "sharp_corner" &&
            Array.from({
              length: productRow,
            }).map((it, index) => {
              if (productLayout.name === "product_n*2") {
                return (
                  <>
                    <div className="single-product-one">
                      <div
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg3 + ")",
                          height: calculateHeight(126, productLayout),
                          //   borderRadius: "10px",
                          width: "100%",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname1.png")
                            .default
                        }
                      />
                    </div>

                    <div className="single-product-one">
                      <div
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg4 + ")",
                          height: calculateHeight(126, productLayout),
                          //   borderRadius: "10px",
                          width: "100%",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname1.png")
                            .default
                        }
                      />
                    </div>
                  </>
                );
              } else if (productLayout.name === "product_3") {
                return (
                  <div className="single-product-three">
                    <div>
                      <div
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg1 + ")",
                          height: calculateHeight(185, productLayout),
                          width: "185px",
                          position: "relative",
                          marginRight: "10px",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname3.png")
                            .default
                        }
                      />
                    </div>

                    <div>
                      <div>
                        <div
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage: "url(" + fashionimg2 + ")",
                            height: calculateHeight(70, productLayout),
                            width: "70px",
                            position: "relative",
                          }}
                        >
                          {productItemType === "sale_products" && (
                            <img
                              className="img-fluid product-discount"
                              src={
                                require("../../../assets/images/dashboard/homescreen/discount.png")
                                  .default
                              }
                            />
                          )}
                        </div>
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/homescreen/productname5.png")
                              .default
                          }
                        />
                      </div>
                      <div className="mt-3">
                        <div
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage: "url(" + fashionimg3 + ")",
                            height: calculateHeight(70, productLayout),
                            width: "70px",
                            position: "relative",
                          }}
                        >
                          {productItemType === "sale_products" && (
                            <img
                              className="img-fluid product-discount"
                              src={
                                require("../../../assets/images/dashboard/homescreen/discount.png")
                                  .default
                              }
                            />
                          )}
                        </div>
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/homescreen/productname5.png")
                              .default
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              } else if (productLayout.name === "product_1*n") {
                return (
                  <div className="single-product-four">
                    <div className="slider-product-width">
                      <div
                        style={{
                          position: "relative",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg4 + ")",
                          height: calculateHeight(116, productLayout),
                          width: "116px",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname1.png")
                            .default
                        }
                      />
                    </div>

                    <div className="slider-product-width">
                      <div
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg4 + ")",
                          height: calculateHeight(116, productLayout),
                          width: "116px",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname1.png")
                            .default
                        }
                      />
                    </div>
                  </div>
                );
              } else if (productLayout.name === "product_n*1") {
                return (
                  <div className="single-product-two">
                    <div
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundImage: "url(" + fashionimg5 + ")",
                        height: calculateHeight(80, productLayout),
                        width: "80px",
                        position: "relative",
                        marginRight: "10px",
                      }}
                    >
                      {productItemType === "sale_products" && (
                        <img
                          className="img-fluid product-discount"
                          src={
                            require("../../../assets/images/dashboard/homescreen/discount.png")
                              .default
                          }
                        />
                      )}
                    </div>
                    <img
                      className="img-fluid"
                      src={
                        require("../../../assets/images/dashboard/homescreen/productname3.png")
                          .default
                      }
                    />
                  </div>
                );
              }
            })}
          {productColumn === 2 &&
            productShape === "round_corner" &&
            Array.from({
              length: productRow,
            }).map((it, index) => {
              if (productLayout.name === "product_n*2") {
                return (
                  <>
                    <div className="single-product-one">
                      <div
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg3 + ")",
                          height: calculateHeight(126, productLayout),
                          borderRadius: "10px",
                          width: "100%",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname1.png")
                            .default
                        }
                      />
                    </div>

                    <div className="single-product-one">
                      <div
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg4 + ")",
                          height: calculateHeight(126, productLayout),
                          borderRadius: "10px",

                          width: "100%",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname1.png")
                            .default
                        }
                      />
                    </div>
                  </>
                );
              } else if (productLayout.name === "product_3") {
                return (
                  <div className="single-product-three">
                    <div>
                      <div
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg1 + ")",
                          height: calculateHeight(185, productLayout),
                          borderRadius: "10px",
                          width: "185px",
                          position: "relative",
                          marginRight: "10px",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname3.png")
                            .default
                        }
                      />
                    </div>

                    <div>
                      <div>
                        <div
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage: "url(" + fashionimg2 + ")",
                            height: calculateHeight(70, productLayout),
                            borderRadius: "10px",
                            width: "70px",
                            position: "relative",
                          }}
                        >
                          {productItemType === "sale_products" && (
                            <img
                              className="img-fluid product-discount"
                              src={
                                require("../../../assets/images/dashboard/homescreen/discount.png")
                                  .default
                              }
                            />
                          )}
                        </div>
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/homescreen/productname5.png")
                              .default
                          }
                        />
                      </div>
                      <div className="mt-3">
                        <div
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage: "url(" + fashionimg3 + ")",
                            height: calculateHeight(70, productLayout),
                            borderRadius: "10px",
                            width: "70px",
                            position: "relative",
                          }}
                        >
                          {productItemType === "sale_products" && (
                            <img
                              className="img-fluid product-discount"
                              src={
                                require("../../../assets/images/dashboard/homescreen/discount.png")
                                  .default
                              }
                            />
                          )}
                        </div>
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/homescreen/productname5.png")
                              .default
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              } else if (productLayout.name === "product_1*n") {
                return (
                  <div className="single-product-four">
                    <div className="slider-product-width">
                      <div
                        style={{
                          position: "relative",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg4 + ")",
                          height: calculateHeight(116, productLayout),
                          borderRadius: "10px",
                          width: "116px",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname1.png")
                            .default
                        }
                      />
                    </div>

                    <div className="slider-product-width">
                      <div
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg4 + ")",
                          height: calculateHeight(116, productLayout),
                          borderRadius: "10px",
                          width: "116px",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname1.png")
                            .default
                        }
                      />
                    </div>
                  </div>
                );
              } else if (productLayout.name === "product_n*1") {
                return (
                  <div className="single-product-two">
                    <div
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundImage: "url(" + fashionimg5 + ")",
                        height: calculateHeight(80, productLayout),
                        width: "80px",
                        borderRadius: "10px",
                        position: "relative",
                        marginRight: "10px",
                      }}
                    >
                      {productItemType === "sale_products" && (
                        <img
                          className="img-fluid product-discount"
                          src={
                            require("../../../assets/images/dashboard/homescreen/discount.png")
                              .default
                          }
                        />
                      )}
                    </div>
                    <img
                      className="img-fluid"
                      src={
                        require("../../../assets/images/dashboard/homescreen/productname3.png")
                          .default
                      }
                    />
                  </div>
                );
              }
            })}
          {productColumn === 2 &&
            productShape === "circular" &&
            Array.from({
              length: productRow,
            }).map((it, index) => {
              if (productLayout.name === "product_n*2") {
                return (
                  <>
                    <div className="single-product-one">
                      <div
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg3 + ")",
                          height: calculateHeight(126, productLayout),
                          borderRadius: "100%",
                          width: "100%",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname1.png")
                            .default
                        }
                      />
                    </div>

                    <div className="single-product-one">
                      <div
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg4 + ")",
                          height: calculateHeight(126, productLayout),
                          borderRadius: "100%",

                          width: "100%",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname1.png")
                            .default
                        }
                      />
                    </div>
                  </>
                );
              } else if (productLayout.name === "product_3") {
                return (
                  <div className="single-product-three">
                    <div>
                      <div
                        style={{
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg1 + ")",
                          height: calculateHeight(185, productLayout),
                          borderRadius: "100%",
                          width: "185px",
                          position: "relative",
                          marginRight: "10px",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname3.png")
                            .default
                        }
                      />
                    </div>

                    <div>
                      <div>
                        <div
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage: "url(" + fashionimg2 + ")",
                            height: calculateHeight(70, productLayout),
                            borderRadius: "100%",
                            width: "70px",
                            position: "relative",
                          }}
                        >
                          {productItemType === "sale_products" && (
                            <img
                              className="img-fluid product-discount"
                              src={
                                require("../../../assets/images/dashboard/homescreen/discount.png")
                                  .default
                              }
                            />
                          )}
                        </div>
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/homescreen/productname5.png")
                              .default
                          }
                        />
                      </div>
                      <div className="mt-3">
                        <div
                          style={{
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage: "url(" + fashionimg3 + ")",
                            height: calculateHeight(70, productLayout),
                            borderRadius: "100%",
                            width: "70px",
                            position: "relative",
                          }}
                        >
                          {productItemType === "sale_products" && (
                            <img
                              className="img-fluid product-discount"
                              src={
                                require("../../../assets/images/dashboard/homescreen/discount.png")
                                  .default
                              }
                            />
                          )}
                        </div>
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/homescreen/productname5.png")
                              .default
                          }
                        />
                      </div>
                    </div>
                  </div>
                );
              } else if (productLayout.name === "product_1*n") {
                return (
                  <div className="single-product-four">
                    <div className="slider-product-width">
                      <div
                        style={{
                          position: "relative",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg4 + ")",
                          height: calculateHeight(116, productLayout),
                          borderRadius: "100%",
                          width: "116px",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname1.png")
                            .default
                        }
                      />
                    </div>

                    <div className="slider-product-width">
                      <div
                        style={{
                          position: "relative",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundImage: "url(" + fashionimg4 + ")",
                          height: calculateHeight(116, productLayout),
                          borderRadius: "100%",
                          width: "116px",
                        }}
                      >
                        {productItemType === "sale_products" && (
                          <img
                            className="img-fluid product-discount"
                            src={
                              require("../../../assets/images/dashboard/homescreen/discount.png")
                                .default
                            }
                          />
                        )}
                      </div>
                      <img
                        className="img-fluid"
                        src={
                          require("../../../assets/images/dashboard/homescreen/productname1.png")
                            .default
                        }
                      />
                    </div>
                  </div>
                );
              } else if (productLayout.name === "product_n*1") {
                return (
                  <div className="single-product-two">
                    <div
                      style={{
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundImage: "url(" + fashionimg5 + ")",
                        height: calculateHeight(80, productLayout),
                        borderRadius: "100%",
                        width: "80px",
                        position: "relative",
                        marginRight: "10px",
                      }}
                    >
                      {productItemType === "sale_products" && (
                        <img
                          className="img-fluid product-discount"
                          src={
                            require("../../../assets/images/dashboard/homescreen/discount.png")
                              .default
                          }
                        />
                      )}
                    </div>
                    <img
                      className="img-fluid"
                      src={
                        require("../../../assets/images/dashboard/homescreen/productname3.png")
                          .default
                      }
                    />
                  </div>
                );
              }
            })}

          {productColumn > 2 && productLayout.name === "product_1*n" && (
            <div className="single-product-four">
              <div className="slider-product-width">
                <div
                  style={{
                    position: "relative",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundImage: "url(" + fashionimg4 + ")",
                    height: calculateHeight(116, productLayout),
                    borderRadius:
                      productShape === "circular"
                        ? "100%"
                        : productShape === "round_corner"
                        ? "10px"
                        : "",
                    width: "116px",
                  }}
                >
                  {productItemType === "sale_products" && (
                    <img
                      className="img-fluid product-discount"
                      src={
                        require("../../../assets/images/dashboard/homescreen/discount.png")
                          .default
                      }
                    />
                  )}
                </div>
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/productname1.png")
                      .default
                  }
                />
              </div>

              <div className="slider-product-width">
                <div
                  style={{
                    position: "relative",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundImage: "url(" + fashionimg4 + ")",
                    height: calculateHeight(116, productLayout),
                    borderRadius:
                      productShape === "circular"
                        ? "100%"
                        : productShape === "round_corner"
                        ? "10px"
                        : "",
                    width: "116px",
                  }}
                >
                  {productItemType === "sale_products" && (
                    <img
                      className="img-fluid product-discount"
                      src={
                        require("../../../assets/images/dashboard/homescreen/discount.png")
                          .default
                      }
                    />
                  )}
                </div>
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/productname1.png")
                      .default
                  }
                />
              </div>
              <div className="slider-product-width">
                <div
                  style={{
                    position: "relative",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundImage: "url(" + fashionimg4 + ")",
                    height: calculateHeight(116, productLayout),
                    borderRadius:
                      productShape === "circular"
                        ? "100%"
                        : productShape === "round_corner"
                        ? "10px"
                        : "",
                    width: "116px",
                  }}
                >
                  {productItemType === "sale_products" && (
                    <img
                      className="img-fluid product-discount"
                      src={
                        require("../../../assets/images/dashboard/homescreen/discount.png")
                          .default
                      }
                    />
                  )}
                </div>
                <img
                  className="img-fluid"
                  src={
                    require("../../../assets/images/dashboard/homescreen/productname1.png")
                      .default
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProductsPreview;
