import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../utils/apiEndpoints";
import { prepareAuthHeaders } from "../utils/commonUtils";

export const appIconSlice = createApi({
  reducerPath: "appIcon",
  keepUnusedDataFor: 1800,
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.appUrl,
    prepareHeaders: (headers) => prepareAuthHeaders(headers),
  }),
  tagTypes: ["get-app-icon"],
  endpoints: (builder) => ({
    appIconData: builder.query({
      query: () => `get-app-icon/${btoa(localStorage.getItem("Appid"))}`,
      providesTags: ["get-app-icon"],
    }),
    saveAppIconData: builder.mutation({
      query: (newData) => ({
        url: "save-app-icon",
        method: "POST",
        body: newData,
      }),
      invalidatesTags: ["get-app-icon"],
    }),
  }),
});

export const { useAppIconDataQuery, useSaveAppIconDataMutation } = appIconSlice;
