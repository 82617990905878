import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../utils/apiEndpoints";

export const userWebsiteApiSlice = createApi({
  reducerPath: "userWebsite",
  keepUnusedDataFor: 1800,
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.generalUrl,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set("X-App-Device-Type", "ios");
      headers.set("X-App-Code-Ver", localStorage.getItem("Apiversion"));
      //   headers.set(
      //     "Authorization",
      //     `Bearer ${localStorage.getItem("amstoken")}`
      //   );
      return headers;
    },
  }),
  endpoints: (builder) => ({
    typeData: builder.query({
      query: ({ storeUrl }) =>
        `${apiEndPoints.redirectUrl}${storeUrl.replace("www.", "")}/wp-json/wp/v2/types?page=1%26per_page=99`,
    }),
    taxonomyData: builder.query({
      query: ({ storeUrl }) =>
        `${apiEndPoints.redirectUrl}${storeUrl.replace("www.", "")}/wp-json/wp/v2/taxonomies?page=1%26per_page=99`,
    }),
    websiteMenu: builder.query({
      query: ({ storeUrl }) =>
        `${apiEndPoints.redirectUrl}${storeUrl.replace("www.", "")}/wp-json/wc/v3/ams-menu-names`,
    }),
    nonCMSproductCatData: builder.query({
      query: ({ storeUrl }) =>
        `${apiEndPoints.redirectUrl}${storeUrl.replace("www.", "")}/wp-json/wc/v3/ams-categories`,
    }),
    nonCMSpostCatData: builder.query({
      query: ({ storeUrl }) =>
        `${apiEndPoints.redirectUrl}${storeUrl.replace("www.", "")}/wp-json/wc/v3/ams-post-categories`,
    }),
    nonCMSRestBaseData: builder.query({
      query: ({ storeUrl, rest_base, page }) =>
        `${
          apiEndPoints.redirectUrl
        }${storeUrl.replace("www.", "")}/wp-json/wp/v2/${rest_base}?page=${
          page ? page : 1
        }%26per_page=99`,
    }),
  }),
});

export const {
  useTypeDataQuery,
  useTaxonomyDataQuery,
  useWebsiteMenuQuery,
  useNonCMSproductCatDataQuery,
  useNonCMSpostCatDataQuery,
  useNonCMSRestBaseDataQuery,
} = userWebsiteApiSlice;
