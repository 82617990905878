import React, { useState, useEffect } from "react";
import Header from "../Header";
import Accountleftpanel from "../Accountleftpanel";
import "../User/User.css"
import { Spinner, Modal, ModalHeader, ModalBody } from "reactstrap";
import placeholder from "../../Agency/assets/images/accountprofile.png";
import {
  Changestatus,
  DeleteWorkspaceuser,
  saveUserForWorkspace,
  getUserForWorkspace,
  WorkspaceChangestatus,
  WorkspaceDeactiveChangestatus,
  WorkspaceActiveChangestatus,
} from "../../Api/User/Userapi";
import { Link} from "react-router-dom";
import LeftPanel from "./workspaceLeftpannel";
import { Notaddonactive } from "../User/Notaddonactive";
import { Notallowedtouser } from "../User/Usernotalllowed";
import moment from "moment";
import { Limittrigger } from "../User/Context";

const Workspaceteams = () =>{
    const [submitval, setsubmit] = useState("Invite");
    const [loader, setLoader] = useState("d-none");
    const [rightpanelexpand, setRightPanelExpand] = useState(true);
    const loaderimage =
      require("../../assets/images/signup/Loader_GIF.gif").default;
    const [spinner, setSpinner] = useState(true);
    const [popspinner, setPopSpinner] = useState(false);
    const [data, setData] = useState([]);
    const [allowed_screen, setallowed_screen] = useState("");
    const [userdata, setUserdata] = useState(null);
    const [user_id, setuser_id] = useState("");
    const [userEmail, setuserEmail] = useState("");
    const [role_id, setrole_id] = useState(2);
    const [adduser, setadduser] = useState(false);
    const [droprole, setDroprole] = useState("");
    const [searchsuer, setSearchsuer] = useState("");
    const [user_email, setuser_email] = useState("");
    const [mode, setMode] = useState("adduser");
    const [user_role_id, setuser_role_id] = useState(null);
    const [subscription_plan, setSubscription_plan] = useState(null);
    const [user_count, setuser_count] = useState(null);
    const [limitreached, setlimitreached] = useState(false);
    const [addon_quantity, setaddon_quantity] = useState(null);
    const [allowed_users, setallowed_users] = useState(0);
    const[userid,setuserid] = useState('');
    const [ownerData, setOwnerData] = useState(null);
    //count to call the useEffect function again
    const [count, setCount] = useState(1);
    const [plan, setPlan] = useState(null);
    const [openDeletePopUp,setOpenDeletePopUp] = useState(false);
    const [isLoader,setIsLoader] = useState("d-none");
    const [deleteUserID,setDeleteUserID] = useState(null);
    const [mdlcustomerdeactive, setMdlCustomerDeactive] = useState(false);
    const [btncustomerdeactiveloader, setBtnCustomerDeactiveLoader] = useState("d-none");
    const [btncustomerdeactivelabel, setBtnCustomerDeactiveLabel] = useState("Deactivate");
    const [mdlcustomerlogin, setMdlCustomerLogin] = useState(false);
    const [btncustomeractiveloader, setBtnCustomerActiveLoader] = useState("d-none");
    const [btncustomerloginloader, setBtnCustomerLoginLoader] = useState("d-none");
    const [btncustomeractivelabel, setBtnCustomerActiveLabel] = useState("Activate");
    const [mdlcustomeractive, setMdlCustomerActive] = useState(false);
    const [submitvalDeleteVal, setSubmitvalDeleteVal] = useState("Delete");
    //Send user data to form incase of Edit
    const editForm = (id, email, role_id, mode) => {
      setadduser(true);
      setuser_id(id);
      setuserEmail(email);
      setrole_id(role_id);
      setMode(mode);
      setsubmit("Done");
    };
  
    //Modal open and close
    const addusertoggle = () => {
      setadduser(!adduser);
      setuserEmail("");
      setuser_id("");
      setrole_id(2);
      setLoader("d-none");
      setMode("adduser");
      setsubmit("Invite");
    };
  
    //Add user button
    const adduserbutton = () => {
      // setadduser(!adduser);
      if (allowed_users === "") {
        setadduser(!adduser);
      } else {
        if (user_count >= addon_quantity + allowed_users) 
        {
          setlimitreached(true);
        } 
        else 
        {
          setadduser(!adduser);
        }
      }
    };
  
  
    //call this function after the form submission
    const insCount = () => {
      setCount(count + 1);
      setuserEmail("");
      setLoader("d-none");
      setsubmit("INVITE");
      setMode("adduser");
      setsubmit("INVITE");
    };
  
    // Call table api onload
    useEffect(() => {
      // document.title = "Team | AppMySite";
      if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
      {
        document.title = "Team | AppMySite";
       
      }
      else
      {
        document.title = "Team |"+ ' ' +localStorage.getItem('agencyname');
      }
      setSpinner(true);
  
      const params = {
        workspace_id: btoa(parseInt(localStorage.getItem("workspaceId"))),
      };
  
      getUserForWorkspace(
        params,
        setSpinner,
        setOwnerData,
        setUserdata,
        setuser_role_id,
        setuser_email,
        setData,
        setSubscription_plan,
        setuser_count,
        setaddon_quantity,
        setallowed_users,
        setallowed_screen,
         setPlan
      );
    }, []);
  
    // Form Submission
    const finalForm = (e) => {
      e.preventDefault();
      setLoader("d-block");
      setsubmit("");
      const param = {
        id: user_id,
        // app_id: parseInt(localStorage.getItem("Appid")),
        workspace_id: parseInt(localStorage.getItem("workspaceId")),
        email: userEmail.trim(),
        role_id: parseInt(role_id),
      };
      saveUserForWorkspace(param, setLoader, setadduser, adduser,setsubmit);
    };
  
      //Delete user PopUp
      const handleDeleteUserID = (id) => {
        setDeleteUserID(id);
        setOpenDeletePopUp(!openDeletePopUp);
      };
  
    // Delete user
  
  
    const userdelete = (e,from) => {
      if(from==="add")
      {
        setIsLoader("d-block");
    //   document.getElementById(deleteUserID + "pp").innerHTML = "Removing...";
      const deleteparams = {
        id: deleteUserID,
        app_id: "",
        workspace_id: parseInt(localStorage.getItem("workspaceId")),
      };
      DeleteWorkspaceuser(deleteparams, insCount);
    }
    setTimeout(() => {
        setOpenDeletePopUp(!openDeletePopUp);     
    }, 1000);
   
    };
  
    // Mobile edit user toggle
    const showMenu = (id) => {
      let data = document.getElementById(id);
      data.classList.remove("on-mobile");
    };
    const limitreached_modal = {
      limitreached_status: limitreached,
      subscription_plan: subscription_plan,
      handlelimitstatus: (e) => setlimitreached(false),
      addon_quantity: addon_quantity,
      plan:plan ,
    };

    const handleCustomeDeactivePopup = (userid) =>
    {
        setuserid(userid);
        setMdlCustomerDeactive(!mdlcustomerdeactive);
    }

    const handleCustomerActivePopup = (userid) =>{

        setuserid(userid);
        setMdlCustomerActive(!mdlcustomeractive);

    }

    const btnCancelCustomerDeactivePopup = () =>{
        setuserid('');
        setMdlCustomerDeactive(false);
    }

    const btnCancelCustomerActivePopup = () =>{
        setuserid('');
        setMdlCustomerActive(false);
    }

    const btnCustomerDeactive = (e) =>
    {
        // setBtnCustomerDeactiveLoader('');
        setPopSpinner(true);
        const params = {
            id: userid,
            app_id: "",
            workspace_id: parseInt(localStorage.getItem("workspaceId")),
            status: 2,
          };
        
        WorkspaceDeactiveChangestatus(params,setlimitreached,setMdlCustomerDeactive,setPopSpinner,popspinner,() => 
        {
            let user_status_change_on_error = data.filter((res) => {
                if (res.id === userid) {
                res.status = 2;
                }
                return res;
            });
            setData(user_status_change_on_error);
        });

    }

    const btnCustomerActive = (e) =>{

        setPopSpinner(true);
        
        const params = {
            id: userid,
            app_id: "",
            workspace_id: parseInt(localStorage.getItem("workspaceId")),
            status: 1,
          };
       
        WorkspaceActiveChangestatus(params,setlimitreached,setMdlCustomerActive,setPopSpinner,popspinner,() => 
        {
            let user_status_change_on_error = data.filter((res) => {
                if (res.id === userid) {
                res.status = 1;
                }
                return res;
            });
            setData(user_status_change_on_error);
        });

    }

    const handleRightPanelExpand = (rightpanelvalue) => {

        setRightPanelExpand(rightpanelvalue);
    }

    const closeLimitReachReachPopup = (e) =>{

        setlimitreached(false);
    }

    
  
    return (
      <>
    <div className="workspace-org-module">
        <Header />
        <div className="workspace-org-structure">
        <LeftPanel rightpanelbody={handleRightPanelExpand}/>
            <div className={localStorage.getItem("worksidebaserotate") === 'true' ? "workspace_background" : "workspace_background workspace_background-expand"}>

            
               
                <div className="workspaceapplist-dashboard-head workspaceapplist-team-dashboard-head">

                            <div>  
                                <div className="workspace-headings">
                                <div className="workspace-headings-team-div">
                                <h2>Team</h2>
                                <p class="workspace-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                    <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                    <circle cx="9" cy="9" r="9" stroke="none"/>
                                    <circle cx="9" cy="9" r="8.25" fill="none"/>
                                    </g>
                                    <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                                    <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                </g>
                                </svg><p class="workspace-tooltipsubheader">Invite team members to your Workspace.</p></p>
                                </div>
                                </div>
                            </div>

                            { spinner || popspinner ? <></>
                                
                            : 
                                <>  
                                    {subscription_plan !== null && subscription_plan != 'agency_preview' && subscription_plan != 'paddle_preview' && subscription_plan != process.env.REACT_APP_Lifetime_preview ? 
                                        <>
                                        
                                            <div className="workspace-head-right">
                                                {subscription_plan !== null && allowed_users !== "" &&
                                                parseInt(user_count + 1) <= (subscription_plan !== null && parseInt(addon_quantity) + parseInt(allowed_users + 1)) && 
                                                (
                                                    <p className="mb-0 ">
                                                        {parseInt(user_count + 1)}/{subscription_plan !==null && parseInt(addon_quantity) +
                                                        parseInt(allowed_users) +1}{" "}
                                                        <span>users</span>
                                                    </p>
                                                )}

                                                {user_role_id !== 3 && (
                                                    <div className="workspace-save-team-btn workspace-web-team-btn" onClick={adduserbutton}>
                                                        <img src={require("../../Agency/assets/images//white-plus.png").default} alt="invite"/> 
                                                        { " Invite" }
                                                    </div>
                                                )}
                                            </div>
                                        </>
                                    :
                                        <></>
                                    }
                                </>
                            }
                            </div>
                {spinner || popspinner ? 
                     <div className="app-icon-section access-loader workspace-loader-div">
                     <div className="newspinner">
                     <svg xmlns="http://www.w3.org/2000/svg" 
                         xlink="http://www.w3.org/1999/xlink" 
                         style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
                         width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                         <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                         style={{animationplaystate: "running", animationdelay: "0s"}}>
                         </animateTransform>
                         </circle>
                         </g>
                         <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                         style={{animationplaystate: "running", animationdelay: "0s"}}>
                         </animateTransform>
                         </circle>
                         </g>
                         <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                         style={{animationplaystate: "running", animationdelay: "0s"}}>

                         </animateTransform>
                         </circle>
                         </g>
                         <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                         style={{animationplaystate: "running", animationdelay: "0s"}}>

                         </animateTransform>
                         </circle>
                         </g>
                         </svg>
                   </div>
                 </div>
                :
                    
                                <>
                                    {subscription_plan !== null && 
                                    (
                                        <>
                                            {subscription_plan === process.env.REACT_APP_Lifetime_preview || subscription_plan == 'agency_preview' || subscription_plan == 'paddle_preview' ? 
                                            (
                                                <div className="upgrade-workspace-team">
                                                <div className="workspaceteam-right-box">
                                                    <div className="workspaceteam-heading">
                                                    <div className="workspaceteam-inner-box workspace-box-img">
                                                        <div className="workspaceteam-box-wrapper">
                                                        <div className="workspaceteam-inner-content">
                                                            <div className="workspaceteam-content">
                                                            <div className="workspaceteam-content-details">
                                                                <h3 className="workspaceteam-paragraph">Build better, together</h3>
                                                                <p className="workspaceteam-content-text"> Manage your team in one place. </p>
                                                                <ul>
                                                                <li>Collaborate with team members.</li>
                                                                <li>Delegate responsibilities to others.</li>
                                                                <li>One-click access management. </li>
                                                                <li>Assign roles and limit visibility. </li>
                                                                </ul>
                                                            <Link to={`/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`}>
                                                                <button type="button" className="workspacebtn btn-primary"> Upgrade</button>
                                                            </Link>
                                                            </div>
                                                            </div>
                                                            <div className="workspaceteam-img">
                                                            <img src={require("../../Agency/assets/images/team-image.png").default} alt="team-image" />
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                            ) 
                                            : allowed_screen !== 0 ? 
                                            (
                                                <>
                                                {user_role_id !== 3 && (
                                                <div className="invite-workspace-team-mob">
                                                    <div className="workspace-save-team-btn" onClick={adduserbutton}>
                                                                <img src={require("../../Agency/assets/images/white-plus.png").default} alt="invite"/> 
                                                                { " Invite" }
                                                            </div>
                                                </div>
                                                )}
                                                <div className="workspace-module-shadow workspace-teams-web-view">
                                                    
                                                    <>

                                                    <div className="wprkspace-team-table-head">
                                                            <div>
                                                                <h6>User</h6>
                                                            </div>
                                                            <div>
                                                                <h6>Email</h6>
                                                            </div>
                                                            <div>
                                                                <h6>Role</h6>
                                                            </div>
                                                            <div>
                                                                <h6>Last seen </h6>
                                                            </div>
                                                            <div>
                                                                <h6>Status</h6>
                                                            </div>
                                                        </div>
                                                        

                                                        <div className="workspace-team-body">  
                                                            
                                                            {/*userdata !== null && (droprole === "" || droprole === "1") && (searchsuer === "" || userdata.email.toLowerCase().indexOf(searchsuer.toLowerCase()) !== -1)*/}
                                                            
                                                            { (ownerData !== null && (droprole === '' || droprole === '1') && (searchsuer === '' || ownerData.email.toLowerCase().indexOf(searchsuer.toLowerCase()) !== -1) ) ?  
                                                            
                                                                <div className="workspace-team-member-detail">
                                                                    <div className="workspace-member-img-name">

                                                                    {ownerData.profile_image === null || ownerData.profile_image === "" ||
                                                                     ownerData.profile_image === undefined || ownerData.profile_image === "undefined" ?
                                                                            
                                                                            <div className="workspace-member-img" style={{ backgroundImage: "url(" + placeholder + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} ></div>
                                                                        :

                                                                            ( ownerData.profile_image.indexOf("https") !== -1 ?

                                                                                <div className="workspace-member-img" style={{ backgroundImage: "url(" + ownerData.profile_image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} ></div>
                                                                            :
                                                                                <div className="workspace-member-img" style={{ backgroundImage: "url(" +  process.env.REACT_APP_Image_Path + 
                                                                                                                                (user_role_id === 1 ? 
                                                                                                                                localStorage.getItem("logged_in_user_id") : localStorage.getItem("invited_by_user_id")) +
                                                                                                                            "/profile/" + ownerData.profile_image + ")", 
                                                                                                                        backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} ></div>
                                                                            )
                                                                        }
                                                                        <div>
                                                                            <h2>{ownerData.name}</h2>
                                                                            <p>User ID: {ownerData.id}</p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="workspace-team-member-email">
                                                                        <p>{ownerData.email}</p>
                                                                    </div>

                                                                    <div className="team-member-role">
                                                                        <p>Owner</p>
                                                                    </div>
                                                                    <div className="worksapce-team-member-time">
                                                                        <h3>{ moment( ownerData.last_login ).format("DD MMM YYYY hh:mm") }</h3>
                                                                        <h5>IP {ownerData.last_login_IP}</h5>
                                                                    </div>
                                                                    <div className="workspace-teams-status">    
                                                                        <div className="workspace-team-member-status">
                                                                            <h6 className="teamstatusgreen">Active</h6>
                                                                        </div>
                                                                        <div className="workspace-team-member-edit"></div>
                                                                    </div> 
                                                                    
                                                                </div>
                                                            :
                                                                null
                                                            }
                                                        
                                                        {/*data.length > 0 && 
                                                        data.filter((res) => droprole === "" ? res.role_id : res.role_id === parseInt(droprole) )
                                                        .filter((res) => searchsuer === "" ? res.invitation_email : res.invitation_email.toLowerCase().indexOf( searchsuer.toLowerCase() ) == -1 ) */}   

                                                        { userdata.length > 0 ?
                                                            userdata.filter((res) => droprole === "" ? res.role_id : res.role_id === parseInt(droprole) )
                                                            .filter((res) => searchsuer === '' ? res.invitation_email : res.invitation_email.toLowerCase().indexOf( searchsuer.toLowerCase() ) != -1 )
                                                            .map((userlist, userkey)=>{
                                                                return(
                                                                <div className="workspace-team-member-detail" key={userkey}>
                                                                    <div className="workspace-member-img-name">

                                                                        { userlist.image === '' || userlist.image === null || userlist.image === undefined || userlist.image === 'null' ?
                                                                            
                                                                            <div className="workspace-member-img" style={{ backgroundImage: "url(" + placeholder + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                                                        :
                                                                            ( userlist.image.indexOf("https") !== -1 ?
                                                                                
                                                                                <div className="workspace-member-img" style={{ backgroundImage: "url(" + userlist.image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                                                            
                                                                            :

                                                                                <div className="workspace-member-img" style={{ backgroundImage: "url(" + process.env.REACT_APP_Image_Path + userlist.user_id + process.env.REACT_APP_Profile + userlist.image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                                                            )
                                                                        }

                                                                        <div>
                                                                            <h2>{ userlist.user_name }</h2>
                                                                            {userlist.user_id != '' ?
                                                                            <p>User ID: { userlist.user_id }</p>
                                                                            : <>-</>
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className="workspace-team-member-email">
                                                                        <p>{userlist.email ?  userlist.email :userlist.invitation_email ? userlist.invitation_email : '' }</p>
                                                                    </div>

                                                                    <div className="workspace-team-member-role">
                                                                        <p>
                                                                            { userlist.role_id === 2 ?
                                                                                "Admin"
                                                                            :
                                                                                (userlist.role_id === 3 ?
                                                                                    "Manager"
                                                                                :
                                                                                    (userlist.role_id === 4 ?
                                                                                        "User"
                                                                                    :
                                                                                        null   
                                                                                    )
                                                                                )
                                                                            }
                                                                        </p>
                                                                    </div>

                                                                    <div className="worksapce-team-member-time">
                                                                        { (userlist.last_login !== '' && userlist.last_login !== null && userlist.last_login !== undefined && userlist.last_login !== 'null') ?
                                                                            <h3>{ moment( userlist.last_login ).format("DD MMM YYYY, hh:mm") }</h3>
                                                                        :
                                                                            <h3>-</h3>
                                                                        }

                                                                        { (userlist.last_login_IP !== '' && userlist.last_login_IP !== null && userlist.last_login_IP !== undefined && userlist.last_login_IP !== 'null') ?
                                                                            <h5>IP {userlist.last_login_IP}</h5>
                                                                        : (userlist.registration_IP !== '' && userlist.registration_IP !== null && userlist.registration_IP !== undefined && userlist.registration_IP !== 'null') ?
                                                                            <h5>IP {userlist.registration_IP}</h5>
                                                                        : <></>
                                                                        }
                                                                    </div>

                                                                    <div className="workspace-teams-status">    
                                                                        <div className="workspace-team-member-status">
                                                                            {userlist.status === 0 ?
                                                                                <h6 className="teamstatusgrey">Pending</h6>
                                                                            :
                                                                                (userlist.status === 1 ?
                                                                                  <>
                                                                                    <h6 className="teamstatusgreen">Active</h6>
                                                                                    {user_email !== userlist.invitation_email && userlist.role_id >= user_role_id ?
                                                                                        <span onClick={()=>handleCustomeDeactivePopup(userlist.id)}>Deactivate</span>
                                                                                    :   <></>
                                                                                    }
                                                                                  </>
                                                                                :  userlist.status === 2 ?
                                                                                  <>
                                                                                    <h6 className="teamstatusred">Inactive</h6>
                                                                                    {user_email !== userlist.invitation_email && userlist.role_id >= user_role_id ?
                                                                                        <span onClick={()=>handleCustomerActivePopup(userlist.id)}>Activate</span>
                                                                                    :   <></>
                                                                                    }
                                                                                  </>
                                                                                :
                                                                                    null
                                                                                )
                                                                            }
                                                                        </div>

                                                                        <div className="workspace-team-member-edit">
                                                                        {user_email !== userlist.invitation_email && userlist.role_id >= user_role_id ? 
                                                                        (
                                                                            <>
                                                                            {(userlist.status === 1 || userlist.status === 2) && (
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16.263" height="16.263" viewBox="0 0 16.263 16.263" onClick={ (e)=>editForm( userlist.id,userlist.invitation_email,userlist.role_id,"editmode")} >
                                                                                <g id="Group_27285" data-name="Group 27285" transform="translate(-999.142 -1409.971) rotate(45)">
                                                                                    <g id="Path_82084" data-name="Path 82084" transform="translate(1713 281)" fill="#fff">
                                                                                    <path d="M 2 17.29290008544922 L -0.5 14.79290008544922 L -0.5 2 C -0.5 1.724300026893616 -0.2757000029087067 1.5 0 1.5 L 4 1.5 C 4.275700092315674 1.5 4.5 1.724300026893616 4.5 2 L 4.5 14.79290008544922 L 2 17.29290008544922 Z" stroke="none"/>
                                                                                    <path d="M 0 2 L 0 14.58578014373779 L 2 16.58577919006348 L 4 14.58578014373779 L 4 2 L 0 2 M 0 1 L 4 1 C 4.552279949188232 1 5 1.447719573974609 5 2 L 5 15 L 2 18 L -1 15 L -1 2 C -1 1.447719573974609 -0.5522799491882324 1 0 1 Z" stroke="none" fill="#7782a1"/>
                                                                                    </g>
                                                                                    <path id="Path_82085" data-name="Path 82085" d="M-4459-11434.725h6" transform="translate(6171 11721)" fill="none" stroke="#7782a1" strokeWidth="1"/>
                                                                                </g>
                                                                                </svg>
                                                                            )}
                                                                        

                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999" onClick={ (e)=>handleDeleteUserID(userlist.id) }>
                                                                        <g id="Group_5465" data-name="Group 5465" transform="translate(-835.516 -682.992)">
                                                                            <g id="Rectangle_2307" data-name="Rectangle 2307" transform="translate(836.515 684.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1">
                                                                            <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none"/>
                                                                            <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none"/>
                                                                            </g>
                                                                            <g id="Rectangle_2308" data-name="Rectangle 2308" transform="translate(839.515 682.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1">
                                                                            <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none"/>
                                                                            <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none"/>
                                                                            </g>
                                                                            <g id="Group_3245" data-name="Group 3245" transform="translate(841.016 687.993)">
                                                                            <line id="Line_110" data-name="Line 110" y2="6" transform="translate(3 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1"/>
                                                                            <line id="Line_111" data-name="Line 111" y1="6" transform="translate(0 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1"/>
                                                                            </g>
                                                                            <line id="Line_112" data-name="Line 112" x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1"/>
                                                                        </g>
                                                                        </svg>
                                                                        </>
                                                                            )
                                                                        : <></>
                                                                        }
                                                                            

                                                                        

                                                                        </div>

                                                                    </div>    

                                                                    

                                                                </div>
                                                                )
                                                            })
                                                        :
                                                            null
                                                        }
                                                                
                                                        </div>
                                                    </>
                                                    
                                                </div>

                                                <div className="workspace-teams-mobile">
                                                <>
                                                { (ownerData !== null && (droprole === '' || droprole === '1') && (searchsuer === '' || ownerData.email.toLowerCase().indexOf(searchsuer.toLowerCase()) !== -1) ) ?  
                                                                        <div className="workspace-module-shadow">

                                                                            <div className="workspace-teams-mob-1">
                                                                                <div>
                                                                                    <h2>{ ownerData.name }</h2>
                                                                                    <h3>User ID: { ownerData.id }</h3>
                                                                                </div>

                                                                                { ownerData.profile_image === null || ownerData.profile_image === '' || ownerData.profile_image === undefined ?
                                                                            
                                                                                    <div className="workspace-member-img" style={{ backgroundImage: "url(" + placeholder + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} ></div>
                                                                                :

                                                                                    ( ownerData.profile_image.indexOf("https") !== -1 ?

                                                                                        <div className="workspace-member-img" style={{ backgroundImage: "url(" + ownerData.profile_image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} ></div>
                                                                                    :
                                                                                        <div className="workspace-member-img" style={{ backgroundImage: "url(" +  process.env.REACT_APP_Image_Path + 
                                                                                                                                                        (user_role_id === 1 ? 
                                                                                                                                                        localStorage.getItem("logged_in_user_id") : localStorage.getItem("invited_by_user_id")) +
                                                                                                                                                         "/profile/" + ownerData.profile_image + ")",
                                                                                                                                        backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }} ></div>
                                                                                    )
                                                                                }

                                                                            </div>

                                                                            <div class="workspace-teams-mob-2">
                                                                                <div>
                                                                                    <h6>Email</h6>
                                                                                    <h4>{ownerData.email ?  ownerData.email :ownerData.invitation_email ? ownerData.invitation_email : '' }</h4>
                                                                                </div>
                                                                            </div>

                                                                            <div class="workspace-teams-mob-2">
                                                                                <div>
                                                                                    <h6>Role</h6>
                                                                                    <h4>Owner</h4>
                                                                                </div>
                                                                            </div>

                                                                            <div className="workspace-teams-mob-3">
                                                                                <div>
                                                                                    <h6>Last seen </h6>
                                                                                
                                                                                        <h4>{ moment( ownerData.last_login ).format("DD MMM YYYY hh:mm") }</h4>
                                                                                    
                                                                                        <h5>IP {ownerData.last_login_IP}</h5>
                                                                                    
                                                                                </div>
                                                                            </div>

                                                                            <div className="workspace-teams-mob-4">
                                                                                <div>
                                                                                    <h6>Status</h6>
                                                                                    <h5 className="customerstatusgreen">Active</h5>
                                                                                    
                                                                                </div>
                                                                            
                                                                            </div>

                                                                        </div>
                                                    : null
                                                }
                                                                    </>
                                                { userdata.length > 0  ?
                                                            userdata.filter((res) => droprole === "" ? res.role_id : res.role_id === parseInt(droprole) )
                                                            .filter((res) => searchsuer === '' ? res.invitation_email : res.invitation_email.toLowerCase().indexOf( searchsuer.toLowerCase() ) != -1 )
                                                            .map((userlist, userkey)=>{
                                                                return(
                                                                    <>
                                                                        <div className="workspace-module-shadow">

                                                                            <div className="workspace-teams-mob-1">
                                                                                <div>
                                                                                    <h2>{ userlist.user_name }</h2>
                                                                                    {userlist.user_id != '' ?
                                                                                    <h3>User ID: { userlist.user_id }</h3>
                                                                                    : <>-</>
                                                                                    }
                                                                                </div>

                                                                                { userlist.image === '' || userlist.image === null || userlist.image === undefined || userlist.image === 'null' ?
                                                                            
                                                                            <div className="workspace-member-img" style={{ backgroundImage: "url(" + placeholder + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                                                        :
                                                                            ( userlist.image.indexOf("https") !== -1 ?
                                                                                
                                                                                <div className="workspace-member-img" style={{ backgroundImage: "url(" + userlist.image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                                                            
                                                                            :

                                                                                <div className="workspace-member-img" style={{ backgroundImage: "url(" + process.env.REACT_APP_Image_Path + userlist.user_id + process.env.REACT_APP_Profile + userlist.image + ")", backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}></div>
                                                                            )
                                                                        }

                                                                            </div>

                                                                            <div class="workspace-teams-mob-2">
                                                                                <div>
                                                                                    <h6>Email</h6>
                                                                                    <h4>{userlist.email ?  userlist.email :userlist.invitation_email ? userlist.invitation_email : '' }</h4>
                                                                                </div>
                                                                            </div>

                                                                            <div class="workspace-teams-mob-2">
                                                                                <div>
                                                                                    <h6>Role</h6>
                                                                                    <h4>{ userlist.role_id === 2 ?
                                                                                            "Admin"
                                                                                        :
                                                                                            (userlist.role_id === 3 ?
                                                                                                "Manager"
                                                                                            :
                                                                                                (userlist.role_id === 4 ?
                                                                                                    "User"
                                                                                                :
                                                                                                    null   
                                                                                                )
                                                                                            )
                                                                                        }</h4>
                                                                                </div>
                                                                            </div>

                                                                            <div className="workspace-teams-mob-3">
                                                                                <div className="workspace-teams-mob-3-flex">
                                                                                    <div>
                                                                                    <h6>Last seen </h6>
                                                                                
                                                                                    { (userlist.last_login !== '' && userlist.last_login !== null && userlist.last_login !== undefined && userlist.last_login !== 'null') ?
                                                                                        <h4>{ moment( userlist.last_login ).format("DD MMM YYYY hh:mm") }</h4>
                                                                                    :
                                                                                        <h4>-</h4>
                                                                                    }

                                                                                    { (userlist.last_login_IP !== '' && userlist.last_login_IP !== null && userlist.last_login_IP !== undefined && userlist.last_login_IP !== 'null') ?
                                                                                        <h5>IP {userlist.last_login_IP}</h5>
                                                                                    : (userlist.registration_IP !== '' && userlist.registration_IP !== null && userlist.registration_IP !== undefined && userlist.registration_IP !== 'null') ?
                                                                                        <h5>IP {userlist.registration_IP}</h5>
                                                                                    : <></>
                                                                                    }
                                                                                    </div>
                                                                                    <div className="workspace-team-member-edit">
                                                                                {user_email !== userlist.invitation_email && userlist.role_id >= user_role_id ? 
                                                                                (
                                                                                <>
                                                                                     {(userlist.status === 1 || userlist.status === 2) && (
                                                                                        <span onClick={ (e)=>editForm( userlist.id,userlist.invitation_email,userlist.role_id,"editmode")}>  
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.263" height="16.263" viewBox="0 0 16.263 16.263">
                                                                                        <g id="Group_27285" data-name="Group 27285" transform="translate(-999.142 -1409.971) rotate(45)">
                                                                                            <g id="Path_82084" data-name="Path 82084" transform="translate(1713 281)" fill="#fff">
                                                                                            <path d="M 2 17.29290008544922 L -0.5 14.79290008544922 L -0.5 2 C -0.5 1.724300026893616 -0.2757000029087067 1.5 0 1.5 L 4 1.5 C 4.275700092315674 1.5 4.5 1.724300026893616 4.5 2 L 4.5 14.79290008544922 L 2 17.29290008544922 Z" stroke="none"/>
                                                                                            <path d="M 0 2 L 0 14.58578014373779 L 2 16.58577919006348 L 4 14.58578014373779 L 4 2 L 0 2 M 0 1 L 4 1 C 4.552279949188232 1 5 1.447719573974609 5 2 L 5 15 L 2 18 L -1 15 L -1 2 C -1 1.447719573974609 -0.5522799491882324 1 0 1 Z" stroke="none" fill="#7782a1"/>
                                                                                            </g>
                                                                                            <path id="Path_82085" data-name="Path 82085" d="M-4459-11434.725h6" transform="translate(6171 11721)" fill="none" stroke="#7782a1" strokeWidth="1"/>
                                                                                        </g>
                                                                                        </svg>
                                                                                        </span>
                                                                                     )}

                                                                                        <span onClick={ (e)=>handleDeleteUserID(userlist.id) }> 
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                                                                                <g id="Group_5465" data-name="Group 5465" transform="translate(-835.516 -682.992)">
                                                                                    <g id="Rectangle_2307" data-name="Rectangle 2307" transform="translate(836.515 684.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1">
                                                                                    <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none"/>
                                                                                    <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none"/>
                                                                                    </g>
                                                                                    <g id="Rectangle_2308" data-name="Rectangle 2308" transform="translate(839.515 682.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1">
                                                                                    <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none"/>
                                                                                    <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none"/>
                                                                                    </g>
                                                                                    <g id="Group_3245" data-name="Group 3245" transform="translate(841.016 687.993)">
                                                                                    <line id="Line_110" data-name="Line 110" y2="6" transform="translate(3 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1"/>
                                                                                    <line id="Line_111" data-name="Line 111" y1="6" transform="translate(0 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1"/>
                                                                                    </g>
                                                                                    <line id="Line_112" data-name="Line 112" x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1"/>
                                                                                </g>
                                                                                </svg>
                                                                                    </span> 
                                                                                </>
                                                                                )
                                                                                : <></>
                                                                                }        
                                                                                </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="workspace-teams-mob-4">
                                                                                <div className="w-100">
                                                                                    <h6>Status</h6>
                                                                                    {userlist.status === 0 ?
                                                                                        <h5 className="teamstatusgrey">Pending</h5>
                                                                                    :
                                                                                        (userlist.status === 1 ?
                                                                                            <div className="workspace-teams-mob-3-flex">
                                                                                            <h5 className="teamstatusgreen">Active</h5>
                                                                                            {user_email !== userlist.invitation_email && userlist.role_id >= user_role_id ?
                                                                                                <span onClick={()=>handleCustomeDeactivePopup(userlist.id)} className="mobileactivate">Deactivate</span>
                                                                                            :   <></>
                                                                                            }
                                                                                            </div>
                                                                                        :  userlist.status === 2 ?
                                                                                            <div className="workspace-teams-mob-3-flex">
                                                                                            <h5 className="teamstatusred">Inactive</h5>
                                                                                            {user_email !== userlist.invitation_email && userlist.role_id >= user_role_id ?
                                                                                                <span onClick={()=>handleCustomerActivePopup(userlist.id)} className="mobileactivate">Activate</span>
                                                                                            :   <></>
                                                                                            }
                                                                                            </div>
                                                                                        :
                                                                                            null
                                                                                        )
                                                                                    }
                                                                                
                                                                                </div>
                                                                                {/* <div className="workspace-team-member-edit">
                                                                                {user_email !== userlist.invitation_email && userlist.role_id >= user_role_id ? 
                                                                                (
                                                                                <>
                                                                                     {(userlist.status === 1 || userlist.status === 2) && (
                                                                                        <span onClick={ (e)=>editForm( userlist.id,userlist.invitation_email,userlist.role_id,"editmode")}>  
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.263" height="16.263" viewBox="0 0 16.263 16.263">
                                                                                        <g id="Group_27285" data-name="Group 27285" transform="translate(-999.142 -1409.971) rotate(45)">
                                                                                            <g id="Path_82084" data-name="Path 82084" transform="translate(1713 281)" fill="#fff">
                                                                                            <path d="M 2 17.29290008544922 L -0.5 14.79290008544922 L -0.5 2 C -0.5 1.724300026893616 -0.2757000029087067 1.5 0 1.5 L 4 1.5 C 4.275700092315674 1.5 4.5 1.724300026893616 4.5 2 L 4.5 14.79290008544922 L 2 17.29290008544922 Z" stroke="none"/>
                                                                                            <path d="M 0 2 L 0 14.58578014373779 L 2 16.58577919006348 L 4 14.58578014373779 L 4 2 L 0 2 M 0 1 L 4 1 C 4.552279949188232 1 5 1.447719573974609 5 2 L 5 15 L 2 18 L -1 15 L -1 2 C -1 1.447719573974609 -0.5522799491882324 1 0 1 Z" stroke="none" fill="#7782a1"/>
                                                                                            </g>
                                                                                            <path id="Path_82085" data-name="Path 82085" d="M-4459-11434.725h6" transform="translate(6171 11721)" fill="none" stroke="#7782a1" strokeWidth="1"/>
                                                                                        </g>
                                                                                        </svg>
                                                                                        </span>
                                                                                     )}

                                                                                        <span onClick={ (e)=>handleDeleteUserID(userlist.id) }> 
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999">
                                                                                <g id="Group_5465" data-name="Group 5465" transform="translate(-835.516 -682.992)">
                                                                                    <g id="Rectangle_2307" data-name="Rectangle 2307" transform="translate(836.515 684.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1">
                                                                                    <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none"/>
                                                                                    <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none"/>
                                                                                    </g>
                                                                                    <g id="Rectangle_2308" data-name="Rectangle 2308" transform="translate(839.515 682.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1">
                                                                                    <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none"/>
                                                                                    <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none"/>
                                                                                    </g>
                                                                                    <g id="Group_3245" data-name="Group 3245" transform="translate(841.016 687.993)">
                                                                                    <line id="Line_110" data-name="Line 110" y2="6" transform="translate(3 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1"/>
                                                                                    <line id="Line_111" data-name="Line 111" y1="6" transform="translate(0 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1"/>
                                                                                    </g>
                                                                                    <line id="Line_112" data-name="Line 112" x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#7782a1" stroke-miterlimit="10" strokeWidth="1"/>
                                                                                </g>
                                                                                </svg>
                                                                                    </span> 
                                                                                </>
                                                                                )
                                                                                : <></>
                                                                                }        
                                                                                </div> */}
                                                                            </div>

                                                                        </div>
                                                                    </>
                                                            )
                                                        })
                                                    :
                                                        null
                                                    }
                                                </div>
                                                </>
                                            )  
                                            :   
                                            <>
                                                <div className="upgrade-workspace-team">
                                                <div className="workspaceteam-right-box">
                                                    <div className="workspaceteam-heading">
                                                    <div className="workspaceteam-inner-box workspace-box-img">
                                                        <div className="workspaceteam-box-wrapper">
                                                        <div className="workspaceteam-inner-content">
                                                            <div className="workspaceteam-content">
                                                            <div className="workspaceteam-content-details">
                                                            <h3 className="workspaceteam-paragraph">Build better, together</h3>
                                                                <p className="workspaceteam-content-text"> Manage your team in one place. </p>
                                                                <ul>
                                                                <li>Collaborate with team members.</li>
                                                                <li>Delegate responsibilities to others.</li>
                                                                <li>One-click access management. </li>
                                                                <li>Assign roles and limit visibility. </li>
                                                                </ul>
                                                            <Link to={`/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`}>
                                                                <button type="button" className="workspacebtn btn-primary"> Upgrade</button>
                                                            </Link>
                                                            </div>
                                                            </div>
                                                            <div className="workspaceteam-img">
                                                            <img src={require("../../Agency/assets/images/team-image.png").default} alt="team-image" />
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>                                  
                                            </>
                                            }
                                        </>
                                    )}
                                </>
                }
                            
                
                <Modal isOpen={adduser} toggle={addusertoggle} style={{ maxWidth: "600px", maxHeight: "247px" }} className="modal-dialog workspace-team-popup user-popup-mobile modal-dialog-centered" dataclass="useradd" centered >
                    <ModalHeader className="modal-xxl-content invite-user pb-0" >
                        { mode === "adduser"  ? 
                            <>
                                <p className="user_modalheading">Invite new user</p>
                                <p>Once an invitation is accepted, the user will gain access to your app based on the allocated role and permissions.</p>
                            </>
                        :
                            <>
                                <p className="user_modalheading">Edit user role</p>
                                <p>Select from the options below to change the user role. </p>
                            </>
                        }

                            <div className="crossmodal mt-1" onClick={addusertoggle}>
                                <img
                                    src={require("../../Agency/assets/images/cross.png").default}
                                />
                            </div>
                        
                    </ModalHeader>

                    <ModalBody className="invite-user-body">
                        <div className="form-group ">
                           
                             {mode === "adduser" && (
                                <div className="form-group ">
                                    <label className="workspace-invite-userbody-label">Email*</label>
                                    <input
                                    type="email"
                                    placeholder="Enter email address"
                                    className="w-100"
                                    // value={userEmail}
                                    required
                                    onChange={(e) => setuserEmail(e.target.value)}
                                    />
                                </div>
                                )}
                        </div>

                        <div className=" push_notification ">
                            <div className="color_picker w-100">
                                <label className="container_1 mr-4">
                                    <div>
                                        <h5>Admin</h5>
                                        <p>Best suited for business owners and company administrators. Full access and management privileges.</p>
                                    </div>
                                    
                                    <input type="radio" name="admin" value={2} defaultChecked={role_id === 2 ? true : false} onClick={(e) => setrole_id(e.target.value)} />
                                    
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            <div className="color_picker w-100">
                                <label className="container_1 mr-4">
                                    <div>
                                        <h5>Manager</h5>
                                        <p>Ideal for those who need to manage the app without access to billing or subscription features.{" "} </p>
                                    </div>
                                    
                                    <input type="radio" name="admin" value={3} defaultChecked={role_id === 3 ? true : false} onClick={(e) => setrole_id(e.target.value)} />
                                    
                                    <span className="checkmark"></span>
                                </label>
                            </div>
                            {/* <div className="color_picker w-100 ">
                                <label className="container_1 mr-4">
                                    <div>
                                        <h5>User</h5>
                                        <p>Suited for those who need to manage the app but do not require access to billing, subscription, or app management features.</p>
                                    </div>
                                    
                                    <input type="radio" name="admin" value={4} defaultChecked={role_id === 4 ? true : false} onClick={(e) => setrole_id(e.target.value)} />
                                    
                                    <span className="checkmark"></span>
                                </label>
                            </div> */}
                        </div>
                    </ModalBody>

                    <div className="d-flex justify-content-between align-items-center cancel-user mt-4 workspace-team-popup-footer">
                        <div className="cancel-btn mr-2 mr-md-0" onClick={addusertoggle}> Cancel </div>
                            <button type="submit" className={userEmail != '' ? "workspacenext_btn" : "workspace-team-user-inactive"} form="userform" onClick={userEmail != '' ? (e) => finalForm(e) : void(0)} id="btninviteid">
                                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className={loader}>
                                        <div className="new-loader"></div>
                                    </div>
                                    { submitval }
                                </div>
                            </button>
                        {/* popupmode === 1 ?
                            <button type="submit" className="next_btn" form="userform" onClick={handleInviteNewUser} id="btninviteid">
                                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className={btnloader}>
                                        <div className="new-loader"></div>
                                    </div>
                                    { btnsavelabel }
                                </div>
                            </button>
                        :
                            <button type="submit" className="next_btn" form="userform" onClick={handleUpdateUser} id="btnupdateid">
                                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                    <div className={btnloader}>
                                        <div className="new-loader"></div>
                                    </div>
                                    { btnupdatelabel }
                                </div>
                            </button>
                    */}
                    </div>
                </Modal>

                
                {/* <TeamLimitReach limitreachpop={islimitreachopen} btnCrossLimitReach={setIsLimitReachOpen} plandata={planname} /> */}

                <Modal isOpen={mdlcustomerdeactive} style={{ maxWidth: "480px", maxHeight: "270px" }} className="deactivate-customer-popup" centered>
                                <ModalBody>
                                    <div className="crossmodal mt-1" onClick={btnCancelCustomerDeactivePopup}>
                                        <img
                                            src={require("../../Agency/assets/images/cross.png").default}
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <h4>Deactivate user?</h4>
                                            <h5>Deactivating this user will revoke their access.</h5>                
                                        </div>

                                        <div className="deactivate-customer-footer">

                                            <div className="customer-deactivate-btn-mob" onClick={btnCustomerDeactive} id="btnleaveorgid">
                                                <div className={btncustomerdeactiveloader}>
                                                    <div className="new-red-loader"></div>
                                                </div>
                                                { btncustomerdeactivelabel }
                                            </div>

                                            <div className="customer-cancel-btn" onClick={btnCancelCustomerDeactivePopup}>
                                                Cancel
                                            </div>

                                            <div className="customer-deactivate-btn" onClick={btnCustomerDeactive} id="btnleaveorgid">
                                                <div className={btncustomerdeactiveloader}>
                                                    <div className="new-red-loader"></div>
                                                </div>
                                                {btncustomerdeactiveloader == '' ? '' : btncustomerdeactivelabel }
                                            </div>

                                        </div>
                                    </div>
                                </ModalBody>
                            </Modal>

                            <Modal isOpen={mdlcustomeractive} style={{ maxWidth: "480px", maxHeight: "270px" }} className="activate-customer-popup" centered >
                                <ModalBody>
                                    <div className="crossmodal mt-1" onClick={btnCancelCustomerActivePopup}>
                                        <img
                                            src={require("../../Agency/assets/images/cross.png").default}
                                        />
                                    </div>
                                    <div>
                                        <div className="confirm-org-body">
                                            <h4>Activate user?</h4>
                                            <h5>Activating this user will restore their access.</h5>
                                            
                                        </div>


                                        <div className="deactivate-customer-footer">

                                            <div className="customer-activate-btn-mob" onClick={btnCustomerActive} id="btnleaveorgid">
                                                <div className={btncustomeractiveloader}>
                                                    <div className="new-loader"></div>
                                                </div>
                                                {btncustomeractiveloader == ''? '' : btncustomeractivelabel }
                                            </div>

                                            <div className="activate-customer-cancel-btn" onClick={btnCancelCustomerActivePopup}>
                                                Cancel
                                            </div>

                                            <div className="customer-activate-btn" onClick={btnCustomerActive}  id="btncustomrtactiveid">
                                                <div className={btncustomeractiveloader}>
                                                    <div className="new-loader"></div>
                                                </div>
                                                {btncustomeractiveloader == ''? '' : btncustomeractivelabel }
                                            </div>

                                        </div>

                                    </div>
                                </ModalBody>
                            </Modal>
                
                <Modal isOpen={openDeletePopUp}  toggle={(e) => userdelete(e, "cancel")} style={{ maxWidth: "480px", maxHeight: "270px" }} className="deactivate-customer-popup" centered>
                                <ModalBody>
                                    <div className="crossmodal mt-1" onClick={(e) => userdelete(e, "cancel")}>
                                        <img
                                            src={require("../../Agency/assets/images/cross.png").default}
                                        />
                                    </div>
                                    <div>
                                        <div>
                                            <h4>Delete this user?</h4>
                                            <h5>Are you sure you want to delete this user? Once deleted, the user will lose access.</h5>                
                                        </div>

                                        <div className="deactivate-customer-footer">

                                            <div className="customer-deactivate-btn-mob" onClick={(e) => userdelete(e,"add")} id="btnleaveorgid">
                                                <div className={isLoader}>
                                                    <div className="new-red-loader"></div>
                                                </div>
                                                {isLoader == 'd-block' ? '' : submitvalDeleteVal }
                                            </div>

                                            <div className="customer-cancel-btn" onClick={(e) => userdelete(e, "cancel")}>
                                                Cancel
                                            </div>

                                            <div className="customer-deactivate-btn" onClick={(e) => userdelete(e,"add")} id="btnleaveorgid">
                                                <div className={isLoader}>
                                                    <div className="new-red-loader"></div>
                                                </div>
                                                {isLoader == 'd-block' ? '' : submitvalDeleteVal }
                                            </div>

                                        </div>
                                    </div>
                                </ModalBody>
                            </Modal>

                            <Modal isOpen={limitreached} onClick={closeLimitReachReachPopup} style={{ maxWidth: "480px", maxHeight: "270px" }} className="activate-customer-popup" centered >

                                <ModalBody>

                                <div className="crossmodal mt-1" onClick={closeLimitReachReachPopup} >
                                    <img
                                        src={require("../../Agency/assets/images/cross.png").default}
                                    />
                                </div>

                                <div>
                                    <div className="confirm-org-body">
                                        <h4>User limit exceeded</h4>
                                        <h5>You have reached the maximum number of team members allowed in your current plan.</h5>
                                        
                                    </div>
                                    <div className="deactivate-customer-footer">

                                        <div className="team-limit-btn-cancel activate-customer-cancel-btn" onClick={closeLimitReachReachPopup} >
                                            Cancel
                                        </div>
                                                                    
                                        <a href={`/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`}>
                                        <div className="team-limit-btn" id="btncustomrtactiveid">
                                            Upgrade
                                        </div>
                                        </a> 
                                        
                                    </div>
                                </div>
                                </ModalBody>
                            </Modal>
            </div>
        </div>  
    </div>

    </>
  );
}

export default Workspaceteams;