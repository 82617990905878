import React from 'react'; 
const preview = () => {
    document.querySelector(".customiseapp").style.display="block";
    document.querySelector(".customiseapp").style.opacity=1;
    document.querySelector(".customiseapp").style.position="fixed";
    document.querySelector(".customiseapp").style.top=0;
    document.querySelector(".customiseapp").style.zIndex=1;
    document.querySelector(".preview-red-cross").style.display="block";
    document.querySelector(".preview-red-cross").style.zIndex=99;
    document.querySelector(".phone-menu ").style.display="none";
    // document.querySelector(".fixed-button").style.position="static";
}
export const MobileApppreview = () => {
    return(
<div  className="h-100 border-0 d-flex align-items-center justify-content-center preview-btn d-md-none w-50 ipad-block" onClick={preview}>
                                    <img src={require("../assets/images/dashboard/phone.png").default} alt="Phone" className="mr-2" />App Preview
                                </div>
                            )
}