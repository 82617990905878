import React from "react";

const SortingIcon = ({ strokeWidth }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.028"
      height="19.502"
      viewBox="0 0 23.028 19.502"
    >
      <g
        id="Group_43105"
        data-name="Group 43105"
        transform="translate(-1538.971 -90.25)"
      >
        <path
          id="Path_119247"
          data-name="Path 119247"
          d="M-7016,17782v17.027"
          transform="translate(8559.5 -17690.027)"
          fill="rgba(0,0,0,0)"
          stroke="#7782a1"
          stroke-linejoin="round"
          stroke-width={strokeWidth}
        />
        <path
          id="Path_119249"
          data-name="Path 119249"
          d="M-15809.382-23304.139l4-4,4,4"
          transform="translate(17348.883 23399.139)"
          fill="rgba(0,0,0,0)"
          stroke="#7782a1"
          stroke-linejoin="round"
          stroke-width={strokeWidth}
        />
        <path
          id="Path_119250"
          data-name="Path 119250"
          d="M-15809.382-23308.139l4,4,4-4"
          transform="translate(17348.883 23413.139)"
          fill="rgba(0,0,0,0)"
          stroke="#7782a1"
          stroke-linejoin="round"
          stroke-width={strokeWidth}
        />
        <path
          id="Path_119254"
          data-name="Path 119254"
          d="M-15794.312-23300.7h12"
          transform="translate(17344.311 23400.703)"
          fill="rgba(0,0,0,0)"
          stroke="#7782a1"
          stroke-linejoin="round"
          stroke-width={strokeWidth}
        />
        <path
          id="Path_119252"
          data-name="Path 119252"
          d="M-15794.312-23300.7h12"
          transform="translate(17344.311 23393.703)"
          fill="rgba(0,0,0,0)"
          stroke="#7782a1"
          stroke-linejoin="round"
          stroke-width={strokeWidth}
        />
        <path
          id="Path_119253"
          data-name="Path 119253"
          d="M-15794.312-23300.7h12"
          transform="translate(17344.311 23407.703)"
          fill="rgba(0,0,0,0)"
          stroke="#7782a1"
          stroke-linejoin="round"
          stroke-width={strokeWidth}
        />
      </g>
    </svg>
  );
};

export default SortingIcon;
