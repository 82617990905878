import React, { useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import "../../../assets/css/Dashboard.css";
import "../../../assets/css/profileleftpanel.css";
import "../../../assets/css/splash-screen.css";
import protipImg from "../../../assets/images/dashboard/protip-img.png";
import Header from "../../../Dashboard/Header";
import Leftpanel from "../../../Dashboard/Leftpanel";
import NewImageGalleryPopup from "../../../Dashboard/NewImageGalleryPopup";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ImageDownloadModal from "../../../Components/commonComponents/AppIcon/ImageDownloadModal";
import ProTipIcon from "../../../Components/commonComponents/AppIcon/ProTipIcon";
import ProTipModal from "../../../Components/commonComponents/AppIcon/ProtipModal";
import RebuildRequiredModal from "../../../Components/commonComponents/AppIcon/RebuildRequiredModal";
import ScreenDetectionModal from "../../../Components/commonComponents/AppIcon/ScreenDetectionModal";
import ModuleNavigations from "../../../Components/commonComponents/ModuleNavigation/ModuleNavigations";
import SaveButton from "../../../Components/commonComponents/SaveButton";
import UpdatedAt from "../../../Components/commonComponents/UpdatedAt";
import AmsSpinner from "../../../Components/commonIcons/AmsSpinner";
import ModuleTitleTooltip from "../../../Components/commonIcons/ModuleTitleTooltip";
import Tooltipicon from "../../../Components/commonIcons/Tooltip";
import { errortoast, sucesstoast } from "../../../Dashboard/Toaster";
import {
  useSaveSplashDataMutation,
  useSplashDataQuery,
} from "../../../services/splashApiSlice";
import { closeVistaPopUp, MetaTitle } from "../../../utils/commonUtils";
import SplashPreview from "./SplashPreview";
import { useAppDashboardDataQuery } from "../../../services/appDashboardApiSlice";
import VistaCreatePopUp from "../../../Components/commonComponents/VistaCreatePopUp";

const Splash = () => {
  const { refetch: appDashboardDataRefetch } = useAppDashboardDataQuery();
  const [splashState, setSplashState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),

    {
      openVistaPopup: false,
      openProTipPopUp: false,
      openImgDownloadPopUp: false,
      splashImg: "",
      vistaDesignId: "",
      buildStatus: null,
      hideGrideBtn: true,
      btnLoader: false,
      updatedAt: "",
      originalIcon: "",
      spinner: true,
      isSplashDataDefault: 1,
      openSmallDetectionPopUp: false,
      openRebuildPopUp: false,
      imgUrlToCrop: "",
      linkArr: [
        {
          link: "/app/app-icon",
          name: "App icon",
          is_active: false,
          is_show: true,
        },
        {
          link: "/app/splash",
          name: "Splash",
          is_active: true,
          is_show: true,
        },
        {
          link: "/app/theme",
          name: "Theme",
          is_active: false,
          is_show: true,
        },
      ],
      proTipsArr: [
        {
          text: "Strike a balance between aesthetics and impact.",
        },
        {
          text: "The splash screen will be displayed for about 3 seconds, so keep it simple.",
        },
        {
          text: "Use design elements and color themes consistently throughout your app.",
        },
        {
          text: "Choose images over text.",
        },
      ],
      appRightPanelExpand:
        localStorage.getItem("appSideBarCollapse") === true ||
        localStorage.getItem("appSideBarCollapse") === "true"
          ? true
          : false,
    }
  );

  const { data: splashApiData, isError, isLoading } = useSplashDataQuery();
  const [saveSplashData, { splashSavedApiData }] = useSaveSplashDataMutation();
  const history = useHistory();
  const [openGalleryPopup, setOpenGalleryPopup] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = MetaTitle("Splash");
    settingGetApiData();
  }, [splashApiData]);

  const settingGetApiData = () => {
    if (splashApiData) {
      if (splashApiData.code === 200) {
        setSplashState({
          isSplashDataDefault: splashApiData.is_default,
          vistaDesignId: splashApiData.data.design_id,
          buildStatus: splashApiData.build_status,
          spinner: false,
          updatedAt: splashApiData.data.last_updated_timestamp,
          splashImg:
            splashApiData.data.upload_image ||
            splashApiData.data.splash_screen ||
            splashApiData.data.splash_screen_bg_image,
        });
      } else if (splashApiData.code === 401) {
        if (splashApiData.data === "App does not exists.") {
          history.push("/apps");
          errortoast(splashApiData.data);
        }
      } else {
        errortoast("Oh no! Something’s not right.");
      }
    }
  };

  const saveSplashApi = (img, designId) => {
    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      design_id: designId || "",
      upload_image: img,
      original_image: "",
      is_android_rebuild_required:
        splashState.buildStatus?.android_success_build_count > 0 ? 1 : 0,
      is_ios_rebuild_required:
        splashState.buildStatus?.ios_success_build_count > 0 ? 1 : 0,
      website_technology: splashState.website_technology,
    };
    saveSplashData(params)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          appDashboardDataRefetch();
          setSplashState({
            btnLoader: false,
            updatedAt: res.data.updated_at,
          });
          sucesstoast("The record has been saved.");
        } else {
          setSplashState({
            btnLoader: false,
          });
          errortoast("Oh no! Something’s not right.");
        }
      })
      .catch((err) => {
        console.error(err, "err");
      });
  };

  const handleVistaPopup = (e) => {
    setOpenGalleryPopup(false);
    if (window.matchMedia("(min-width: 1200px)").matches) {
      setSplashState({ openVistaPopup: !splashState.openVistaPopup });
    } else {
      setSplashState({ openVistaPopup: false });
      setSplashState({
        openSmallDetectionPopUp: !splashState.openSmallDetectionPopUp,
      });
    }
  };

  const handleRebuildPopUp = (e) => {
    setSplashState({ openRebuildPopUp: !splashState.openRebuildPopUp });
  };

  const handleProTipPopUp = () => {
    setSplashState({ openVistaPopup: false });
    setSplashState({ openProTipPopUp: !splashState.openProTipPopUp });
  };
  const handleImgDownloadPopUp = () => {
    if (window.matchMedia("(max-width: 1200px)").matches) {
      setSplashState({ openVistaPopup: false });
      setSplashState({
        openImgDownloadPopUp: !splashState.openImgDownloadPopUp,
      });
    }
  };

  const handleMobVistaPopup = (e, from) => {
    if (from === "proceed") {
      setSplashState({ openVistaPopup: true });
    } else {
      setSplashState({ openVistaPopup: false });
      setSplashState({ openSmallDetectionPopUp: false });
    }
  };

  const closeVistaCreatePopUp = () => {
    setSplashState({ openVistaPopup: false });
  };

  const settingYourAppLogodata = (imgName, from) => {
    setSplashState({ openVistaPopup: false, imgUrlToCrop: "" });

    if (from === "resize") {
      setSplashState({
        isSplashDataDefault: 0,
        splashImg: imgName,
        upload_icon: imgName,
        vistaDesignId: "",
      });
    } else {
      setSplashState({
        originalIcon: imgName,
        vistaDesignId: "",
      });
    }
  };

  const handleImgGalleryPopUp = () => {
    setSplashState({ openVistaPopup: false });
    setOpenGalleryPopup(!openGalleryPopup);
  };

  const yourErrFun = () => {
    setSplashState({
      imgUrlToCrop: "",
    });
  };

  const handleVistaPopUpSaveBtn = (data) => {
    let img = new Image();
    img.src = data.url;

    setSplashState({
      openVistaPopup: false,
    });

    img.onload = async function () {
      if (this.height === 1024 && this.width === 1024) {
        setSplashState({
          splashImg: data.url,
          originalIcon: data.url,
          vistaDesignId: data.id,
          isSplashDataDefault: 0,
          imgUrlToCrop: "",
        });
        setOpenGalleryPopup(false);
        if (
          splashState.buildStatus.android_success_build_count > 0 ||
          splashState.buildStatus.ios_success_build_count > 0
        ) {
          setSplashState({
            openRebuildPopUp: !splashState.openRebuildPopUp,
          });
        } else {
          setSplashState({
            btnLoader: true,
          });
          saveSplashApi(data.url, data.id);
        }
      } else {
        setSplashState({
          imgUrlToCrop: data.url,
        });
        setOpenGalleryPopup(true);
      }
    };
    img.onerror = function (err) {
      console.log(err, "err");
    };

    // if (document.querySelector(".crello-closeBtn")) {
    //   document.querySelector(".crello-closeBtn").click();
    // }
  };

  const handleProceedBtn = (e) => {
    setSplashState({ btnLoader: true });
    setSplashState({ openRebuildPopUp: !splashState.openRebuildPopUp });

    saveSplashApi(splashState.splashImg, splashState.vistaDesignId);
  };
  const handleSaveBtn = (e) => {
    setSplashState({ openVistaPopup: false, btnLoader: true });

    if (
      splashState.buildStatus.android_success_build_count > 0 ||
      splashState.buildStatus.ios_success_build_count > 0
    ) {
      setSplashState({
        openVistaPopup: false,
        openRebuildPopUp: !splashState.openRebuildPopUp,
        btnLoader: false,
      });
    } else {
      saveSplashApi(splashState.splashImg, splashState.vistaDesignId);
    }
  };

  const handleRightPanelExpand = (value) => {
    setSplashState({
      appRightPanelExpand: value,
    });
  };

  return (
    <>
      {closeVistaPopUp(splashState.openVistaPopup)}
      <div>
        <Header customclass={"header-responsive-new"} />
      </div>

      {/* Mobile View End*/}
      <section className="dasboard_page mobile-display">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
        <div
          className={`right-panel-gap ${
            localStorage.getItem("appSideBarCollapse") === "true"
              ? " right-panel-gap-expand"
              : ""
          }`}
        >
          <div className="splash-icon-page">
            {window.matchMedia("(min-width: 1200px)").matches && (
              <ModuleTitleTooltip
                title={"Design"}
                text={
                  "Create a visual aesthetic that appeals and conveys meaning to your app users. "
                }
              />
            )}

            <div className="splash-icon-head-mobile">
              <h2 className="splash-screen-mob-head">Splash Screen</h2>

              {/* <div onClick={handleProTipPopUp}>
                <ProTipIcon />
              </div> */}
            </div>

            <div className="splash-subheader">
              <div>
                <ModuleNavigations linkArr={splashState.linkArr} />
              </div>

              <div className="apperance-head">
                {!splashState.spinner ? (
                  <>
                    {splashState.updatedAt ? (
                      <UpdatedAt
                        timestamp={splashState.updatedAt}
                        format={"D MMMM YYYY, HH:mm"}
                      />
                    ) : null}
                    {!splashState.isSplashDataDefault ? (
                      <SaveButton
                        handleBtnClick={(e) => handleSaveBtn(e)}
                        isBtnLoader={splashState.btnLoader}
                        btnText={"Save"}
                        isDisable={!splashState.splashImg}
                        variant={"primary-submit-web-btn"}
                      />
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>

            {splashState.spinner ? (
              <div className="splash-section">
                <AmsSpinner spinWidth={"200px"} spinHeight={"200px"} />
              </div>
            ) : (
              <div className="splash-section">
                <>
                  <div
                    className="splash-screen-spark"
                    onClick={handleProTipPopUp}
                  >
                    <ProTipIcon />
                  </div>

                  <div className="splash-screen-width">
                    <div
                      className={
                        splashState.splashImg &&
                        window.matchMedia("(max-width: 1200px)").matches &&
                        !splashState.isSplashDataDefault
                          ? "splash-section-one d-none"
                          : "splash-section-one"
                      }
                    >
                      <div className="splash-section-one-width">
                        <h2 className="splash-section-one-h2">
                          Create a beautiful splash screen
                        </h2>
                        <p className="splash-section-one-para">
                          Design a stunning screen that keeps users interested
                          during the app launch. The splash screen appears for
                          about 3 seconds each time your app starts.
                        </p>

                        <div className="splash-screen-buttons">
                          {
                            <SaveButton
                              handleBtnClick={(e) => handleVistaPopup(e)}
                              isBtnLoader={false}
                              btnText={
                                splashState.vistaDesignId
                                  ? "Edit design"
                                  : "Start designing"
                              }
                              isDisable={false}
                              variant={"primary-submit-web-btn"}
                            />
                          }

                          <span
                            onClick={() => handleImgGalleryPopUp()}
                            style={{
                              "--custom_color6":
                                localStorage.getItem("agencyid") == 1
                                  ? "#3064f9"
                                  : localStorage.getItem(
                                      "agency_primary_color"
                                    ),
                            }}
                          >
                            or upload an image
                          </span>
                        </div>

                        <div className="splash-screen-options">
                          <span
                            onClick={() => handleImgGalleryPopUp()}
                            style={{
                              "--custom_color6":
                                localStorage.getItem("agencyid") == 1
                                  ? "#3064f9"
                                  : localStorage.getItem(
                                      "agency_primary_color"
                                    ),
                            }}
                          >
                            or upload an image
                          </span>
                          {parseInt(localStorage.getItem("agencyid")) > 1 ? (
                            <></>
                          ) : (
                            <Link to="/app/addons" className="ask-an-expert">
                              <img
                                src={
                                  require("../../../assets/images/dashboard/diamond.png")
                                    .default
                                }
                                alt="diamond"
                              />{" "}
                              Ask an expert
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="splash-section-two"
                      style={{
                        width:
                          !splashState.splashImg ||
                          splashState.isSplashDataDefault
                            ? ""
                            : "50%",
                      }}
                    >
                      <SplashPreview
                        splashState={splashState}
                        setSplashState={setSplashState}
                        handleVistaPopup={handleVistaPopup}
                        handleImgGalleryPopUp={handleImgGalleryPopUp}
                        handleImgDownloadPopUp={handleImgDownloadPopUp}
                      />
                    </div>
                  </div>
                </>
              </div>
            )}
            {/* <div className="splash-setting-section">
              <div className="splash-apply-duration-left-div">
                <div className="splash-apply-duration-head">
                   <h2 className="splash-apply-duration-h2">Settings</h2>
                   <p className="splash-apply-duration-para">Customize the appearance of your splash screen to suit your preference. Customize the appearance of your menu to suit your preference.</p>
                </div>

                <div className="splash-apply-duration-maindiv">
                  <label className="splash-apply-duration-label">Splash screen duration <Tooltipicon message={"Specify the duration (in seconds) of the splash screen. If the default option is selected, the splash screen will be visible until the default landing screen completes loading."} /></label>
                  <div className="splash-apply-duration-input-div">
                  <input className="splash-apply-duration-input" type="number"/>
                  <p className="splash-apply-duration">seconds</p>
                  </div>                 
                </div>
                
              </div>
              <div className="splash-setting-preview-div">
                <div className="splash-setting-preview">
              <SplashPreview
                        splashState={splashState}
                        setSplashState={setSplashState}
                        handleVistaPopup={handleVistaPopup}
                        handleImgGalleryPopUp={handleImgGalleryPopUp}
                        handleImgDownloadPopUp={handleImgDownloadPopUp}
                      />
                      </div>

              </div>
            </div> */}
            <div className="splash-mob-footer-save">
              {!splashState.isSplashDataDefault ? (
                <SaveButton
                  handleBtnClick={(e) => handleSaveBtn(e)}
                  isBtnLoader={splashState.btnLoader}
                  btnText={"Save"}
                  isDisable={!splashState.splashImg}
                  variant={"primary-submit-mob-btn"}
                />
              ) : null}

              {splashState.updatedAt ? (
                <UpdatedAt
                  timestamp={splashState.updatedAt}
                  format={"D MMMM YYYY, HH:mm"}
                />
              ) : null}
            </div>
          </div>
        </div>
      </section>

      <RebuildRequiredModal
        openPopUp={splashState.openRebuildPopUp}
        handlePopUp={handleRebuildPopUp}
        handleProceedBtn={handleProceedBtn}
      />
      <ProTipModal
        handleProTipPopUp={handleProTipPopUp}
        popUpTitle={"Pro tip"}
        PopupDesc={"Level up your creation with these tips and best practices."}
        tipsArr={splashState.proTipsArr}
        openProTipModal={splashState.openProTipPopUp}
        protipImg={protipImg}
      />
      <ScreenDetectionModal
        openPopUp={splashState.openSmallDetectionPopUp}
        handleMobVistaPopup={handleMobVistaPopup}
      />

      <ImageDownloadModal
        openPopUp={splashState.openImgDownloadPopUp}
        handleImgDownloadPopUp={handleImgDownloadPopUp}
        imageUrl={
          splashState.splashImg?.includes("create.vista.com")
            ? splashState.splashImg
            : splashState.splashImg?.includes("ams")
            ? process.env.REACT_APP_Image_Path +
              localStorage.getItem("user_id") +
              "/" +
              localStorage.getItem("Appid") +
              process.env.REACT_APP_NewSplashScreen +
              splashState.splashImg
            : ""
        }
        imgName={"ams_splash"}
      />

      {splashState.openVistaPopup ? (
        <VistaCreatePopUp
          artworkWidth={1500}
          artworkHeight={2688}
          moduleName="splash"
          downloadFormat="png"
          designId={splashState.vistaDesignId}
          formatKey="instagramStorySM"
          submitFn={handleVistaPopUpSaveBtn}
        />
      ) : null}
      {openGalleryPopup && (
        <NewImageGalleryPopup
          isGalleryPopup={openGalleryPopup}
          setIsGalleryPopup={setOpenGalleryPopup}
          uploadPath={
            localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            process.env.REACT_APP_NewSplashScreen.replace(/\/$/, "")
          }
          settingYourdata={settingYourAppLogodata}
          yourErrFun={yourErrFun}
          settingOrgImgFrom3rdParty={false}
          hideImageGallery={true}
          widthForRestriction={1500}
          heightForRestriction={2688}
          aspectRatio={"1500:2688"}
          isNotCustomUploadOrgImg={true}
          handleVistaCreateOpenFn={handleVistaPopup}
          designid={splashState.vistaDesignId}
          loadpicVistaCreatePopUp={handleVistaPopUpSaveBtn}
          closeVistaCreatePopUp={closeVistaCreatePopUp}
          //Image scale keys
          shouldImgScale={true}
          tempUploadPath={
            localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            process.env.REACT_APP_NewSplashScreen.replace(/\/$/, "") +
            "/temp"
          }
          minScalingWidth={94}
          minScalingHeight={168}
          scaleType={"splash_screen"}
          imgUrlToCrop={splashState.imgUrlToCrop}
        />
      )}
    </>
  );
};

export default Splash;
