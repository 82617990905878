import React, { useState, useEffect, Suspense, useContext } from "react";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import { Link, useParams,useLocation } from "react-router-dom";
import imagebackground from "./../../assets/images/dashboard/cms/uploadbackground.png";
import {
    Getnotification,
	DeleteNotification,
	getAllSegmentList,
	sendPushNotificationOneSignal,
	saveDraftPushNotificationOneSignal,
	EditNotification,
    BottoMenuTypeApi,
    BottoMenuTaxonomyApi,
    productCategoriesTreeAPIForBtm,
    restBaseTaxonomyapi,
    cmsGetPostsForBtm,
    cmsGetPagesForBtm,
    cmsgetCategoryForBtm,
    cmsgetTagsForBtm,
} from "../../Api/RichNotification/RichNotificationApi";
import moment from "moment";
import DatePicker from "react-datepicker";
import {
  Spinner,
  FormGroup, Label, Input,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Media,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import Tooltip from "../Tooltip";
import addDays from "date-fns/addDays";
import NewImageGalleryPopup from "./../NewImageGalleryPopup";
import { errortoast } from "../Toaster";
import { SingleSelectionPopUpForNotification } from "../Reusable/SingleSelectionPopUp";
import SettingIcon from "../../Components/commonIcons/SettingIcon";
import { previewContext } from "../../App";
import PremiumTag from "../../Components/commonIcons/PremiumTag";
const NotificationCreate = (props) => {

    const [title, setTitle] = useState("");
    const [message, setMessage] = useState("");
    const [isGalleryPopup, setIsGalleryPopup] = useState(false);
	const [notificationimage, setNotificationImage] = useState("");
	const [prevImgUrl, setprevImgUrl] = useState("");
	const [chkandroid, setChkAndroid] = useState("android");
    const [chkios, setChkIos] = useState("ios");
    const [date, setDate] = useState(new Date());
    const [chkschedulenow, setChkScheduleNow] = useState(true);
    const [deliverysechedule, setDeliverySchedule] = useState(false);
    const [showcalender, setShowCalendar] = useState(false);
    const [spinner, setSpinner] = useState(false);
    const [displaytitle, setDisplayTitle] = useState("Enter a title here");
    const [ios, setIOS] = useState(true);
    const [android, setAndroid] = useState(false);
    const [displaydescription, setDisplayDescription] = useState("Compose your message here");
    const [devicetype, setDeviceType] = useState("android");
    const [submitval, setSubmitVal] = useState("Send");
    const [loader, setLoader] = useState("d-none");
    const [loaderimage, setLoaderImage] = useState(require("../../assets/images/signup/Loader_GIF.gif").default);
    const [draftsubmitval, setDraftSubmitVal] = useState("Save draft");
    const [draftloader, setDraftLoader] = useState("d-none");
    const [draftloaderimage, setDraftLoaderImage] = useState(require("../../assets/images/signup/Loader_GIF.gif").default);
    const [notificationmodal, setNotificationModal] = useState(false);
    const [alertpopup, setAlertPopup] = useState(false);
    const [popuptile, setPopupTile] = useState("");
    const [popupdescription, setPopupDescription] = useState("");
    const [draftspinner, setDraftSpinner] = useState(false);
    const [appicon, setAppIcon] = useState("");
    const [redirectdraft, setRedirectDraft] = useState(false);
	const [dateTimeValue, setDateTimeValue] = useState("");
	const [deliverytype, setDeliveryType] = useState(1);
	const [showapppicon, setShowAppIcon] = useState('');

    const userid = localStorage.getItem("user_id");
	const appid = localStorage.getItem("Appid");

    const notificationimgpath = process.env.REACT_APP_Image_Path + userid + '/' + appid + process.env.REACT_APP_Notification_Media;

	const appname = window.localStorage.getItem('Appname');
	const [isLoader, setIsLoader] = useState("d-none");
    const [linkname, setLinkName] = useState("");
	const [linkurl, setLinkUrl] = useState("");
	const [segmentlist, setSegmentList] = useState([]);
	const [segmentpopup, setSegmentPopup] = useState(false);
	const [txtsegment, setTxtSegment] = useState("");
	const [segmentsubmitval, setSegmentSubmitval] = useState("Done");
	const [includesegmentlist, setIncludeSegmentList] = useState([]);
    const [includesegmentarr, setIncludeSegmentArr] = useState([]);
    const [excludesegmentarr, setExcludeSegmentArr] = useState([]);
	const [excludesegmentlist, setExcludeSegmentList] = useState([]);
	const [segmenttype, setSegmentType] = useState("");
	const [chksendallusers, setChkSendAllUsers] = useState(true);
	const [draftval, setDraftVal] = useState("Save draft");
    const [selectedinsegment, setSelectedInSegment] = useState([]);
	const [selectedexsegment, setSelectedExSegment] = useState([]);
	const [openrownum, setOpenRowNumber] = useState(null);
	const [settingstatus, setSettingStatus] = useState(false);
    const [settinghome, setSettingHome] = useState(true);
	//const [notificationid, setNotificationID] = useState("");
    const [searchincsegment, setSearchIncSegment] = useState("");
    const [isincsegmentdropdown, setIsIncSegmentDropdown] = useState(false);
    const [openmobilesegmentDropdown, setOpenMobileSegmentDropdown] = useState(false);
    const [tagCheckedArr, setTagCheckedArr] = useState([]);

    const [isexcsegmentdropdown, setIsExcSegmentDropdown] = useState(false);
    const [searchincsegment1, setSearchIncSegment1] = useState("");
    const [searchexcsegment, setSearchExcSegment] = useState("");
    const [issegmentdropdown1, setIsSegmentDropdown1] = useState(false);
    const [openmobilesegmentDropdown1, setOpenMobileSegmentDropdown1] = useState(false);
    const [androidopenPopUp, setAndroidOpenPopUp] = useState(false);
    const [iosopenPopUp, setIosOpenPopUp] = useState(false);
    const [tagCheckedArr1, setTagCheckedArr1] = useState([]);
    const [reviewpopup, setReviewPopup] = useState(false);
    const [androidnotificationstatus, setAndroidNotificationStatus] = useState(false);
    const [iosnotificationstatus, setiOSNotificationStatus] = useState(false);
    const [notificationimgname, setNotificationImgName] = useState(null);
    const [openSingleSelectionPopUp, setOpenSingleSelectionPopUp] = useState(false);

    const [spinnerForsubDrop, setSpinnerForsubDrop] = useState(false);

    const [searchValForPopUp, setSearchValForPopUp] = useState("");

    const [itemTypeOptionsForWoocommerce, setItemTypeOptionsForWoocommerce] = useState([

      { item_name:"Cart",item_type:"cart"},

      { item_name:"Chat",item_type:"chat"},

      { item_name:"Home screen",item_type:"home"},

      { item_name:"Menu",item_type:"main_menu"},

      { item_name:"Orders",item_type:"orders"},

      { item_name:"Pages",item_type:"pages"},

      { item_name:"Product categories",item_type:"product_categories"},

      { item_name:"Profile",item_type:"profile"},

      { item_name:"Settings",item_type:"settings"},

      { item_name:"Web view",item_type:"web_view"},

     

     

  

    ]);

    const [itemTypeOptionsForWordpress, setItemTypeOptionsForWordpress] = useState([

        { item_name:"Chat",item_type:"chat"},

        { item_name:"Home screen",item_type:"home"},

        { item_name:"Menu",item_type:"main_menu"},

        { item_name:"Pages",item_type:"pages"},

        { item_name:"Profile",item_type:"profile"},

        { item_name:"Settings",item_type:"settings"},

        { item_name:"Web view",item_type:"web_view"},

      ]);

    const [itemTypeOptionsForWeb2App, setItemTypeOptionsForWeb2App] = useState([

  

        { item_name:"Chat",item_type:"chat"},

        { item_name:"Menu",item_type:"main_menu"},

        { item_name:"Settings",item_type:"settings"},

        { item_name:"Web view",item_type:"web_view"},

  

      ]);

    const [itemTypeOptionsForCustomApp, setItemTypeOptionsForCustomApp] = useState(      

      [

        { item_name:"Chat",item_type:"chat"},

        { item_name:"Menu",item_type:"main_menu"},

        { item_name:"Settings",item_type:"settings"},

        { item_name:"Web view",item_type:"web_view"},

      ]

    );

    const [mergeArray, setMergeArray] = useState([]);
    const [typeArray, setTypeArray] = useState([]);
    const [moduleName, setModuleName] = useState("");
    const [tempSubdata, setTempSubdata] = useState([]);
    const [tempShowL1Options, setTempShowL1Options] = useState(true);
    const [showL1Options, setShowL1Options] = useState(true);
    const [tempBreadcrumbArr, setTempBreadcrumbArr] = useState(false);
    const [tempModuleLabel, setTempModuleLabel] = useState("");
    const [moduleLabel, setModuleLabel] = useState("");
    const [paginationSpinner, setPaginationSpinner] = useState(false);
    const [subdropdownSpinner, setSubdropdownSpinner] = useState(false);
    const [tempSlug, setTempSlug] = useState("");
    const [tempRestBase, setTempRestBase] = useState("");
    const [tempPostType , setTempPostType] = useState("");
    const [postType , setPostType] = useState("");
    const [tempItemName, setTempItemName] = useState("");
    const [itemName, setItemName] = useState("");
    const [slug, setSlug] = useState("");
    const [restBase, setRestBase] = useState("");
    const [tempInvidualSelectedItemId, setTempInvidualSelectedItemId] = useState("");
    const [invidualSelectedItemId, setInvidualSelectedItemId] = useState("");
    const [typesData, setTypesData] = useState([]);
    const [taxonomyData, setTaxonomyData] = useState([]);
    const [pageNo, setPageNo] = useState(1);
    const [isAllowPagination, setIsAllowPagination] = useState(true);
    const [storeUrl, setStoreUrl] = useState(localStorage.getItem("store_url"));
    const [subdropdownData, setSubdropdownData] = useState([]);
    const [subData, setSubData] = useState([]);
    const [breadcrumbArr, setBreadcrumbArr] = useState([]);
    const [itemType, setItemType] = useState("");
    const [tempItemType, setTempItemType] = useState("");
    const [itemId , setItemId] = useState("");
    const [webViewUrl , setWebViewUrl] = useState("");
    const [openLocationPopUp, setOpenLocationPopUp] = useState(false);

    const [isEnableBackgroundLocationPermissionAndroid, setIsEnableBackgroundLocationPermissionAndroid] = useState(1);
    const [isEnableForegroundLocationPermissionAndroid, setIsEnableForegroundLocationPermissionAndroid] = useState(1);
    const [isEnableLocationPermissionIOS, setIsEnableLocationPermissionIOS] = useState(1);

    const [appRightPanelExpand, setAppRightPanelExpand] = useState( localStorage.getItem("appSideBarCollapse") === true ||
	localStorage.getItem("appSideBarCollapse") === "true"
	  ? true
	  : false);
    const appPlan= localStorage.getItem("app_plan")
    const {toggleActivateModal} = useContext(previewContext)
    const location = useLocation();
    const { skipButtonActive,planCode } = location.state || {};

    //let { notifyID } = useParams("notifyID");

	let { notificationid } = useParams("notificationid");

    useEffect(()=>{


		let notification = JSON.parse(window.localStorage.getItem('create_notification_img'));
        var appicon = '';

        if(notification !== undefined && notification !== '' && notification !== null && notification.length > 0)
        {
            if(notification.icon_json.android_notification_icon_48X48 !== '' && notification.icon_json.android_notification_icon_48X48 !== undefined && notification.icon_json.android_notification_icon_48X48 !== null)
			{
                
            	appicon = process.env.REACT_APP_Image_Path + notification.user_id + '/' + notification.app_id + process.env.REACT_APP_Notification_Media + notification.icon_json.android_notification_icon_48X48;
			}
            else if(notification.app_icon_json.notification_icon !== '' && notification.app_icon_json.notification_icon !== undefined && notification.app_icon_json.notification_icon !== null)
			{
                
            	appicon = process.env.REACT_APP_Image_Path + notification.user_id + '/' + notification.app_id + process.env.REACT_APP_Notification_Media + notification.app_icon_json.notification_icon;
			}
            else if(notification.image !== '' && notification.image !== undefined && notification.image !== null)
			{
                
            	appicon = process.env.REACT_APP_Image_Path+notification.user_id+'/'+notification.app_id+'/app_icon/display_image/'+notification.image;
			}
        }
        else
        {
            
            let appiconjson = JSON.parse(window.localStorage.getItem('app_icon_json'));
            
			if(appiconjson !== undefined && appiconjson !== '' && appiconjson !== null)
			{
                
				appicon = process.env.REACT_APP_Image_Path + notification.user_id + '/' + notification.app_id + '/app_icon/display_image/' + appiconjson.app_frontend_display_icon;
			}
            else
            {
                
                let appid = window.localStorage.getItem('Appid');
                let appuserid = window.localStorage.getItem('user_id');
                let appiconlocal = window.localStorage.getItem('Appicon');
                
                appicon = process.env.REACT_APP_Image_Path + appuserid + '/' + appid + '/app_icon/display_image/' + appiconlocal;

                
            }
                                    
        }

        setAppIcon(appicon);

		if(notificationid !== '' && notificationid !== undefined)
		{
            if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
                {
                  document.title = "Edit notification | AppMySite";
                }
                else 
                {
                  document.title = "Edit notification |"+ ' ' +localStorage.getItem('agencyname');
                }

			const params = {"notification_id": notificationid}
			setSpinner(true);
            setRedirectDraft(false);
			EditNotification(
                params,
                setSpinner,
                 setTitle,
                 setMessage, 
                 setNotificationImage,
                 setChkAndroid, 
                 setChkIos,
                 setDeviceType,
                 setChkScheduleNow,
                 setDateTimeValue,
                 setDeliveryType, 
                 setIncludeSegmentList,
                 setExcludeSegmentList, 
                 setChkSendAllUsers, 
                 setLinkName,
                 setLinkUrl,
                 setTagCheckedArr,
                 setSegmentList,
                 setTagCheckedArr1,
                 setNotificationImgName,
                 setAndroidNotificationStatus,
                 setiOSNotificationStatus,
                 setIncludeSegmentArr,
                 setExcludeSegmentArr,
                 setItemType,
                 setTempItemType,
                 setSlug,
                 setTempSlug,
                 setRestBase,
                 setTempRestBase,
                 setPostType,
                 setTempPostType,
                 setTempInvidualSelectedItemId,
                 setInvidualSelectedItemId,
                 setWebViewUrl,
                 setTempItemName,
                 setItemName,
                 setIsEnableBackgroundLocationPermissionAndroid,
                 setIsEnableForegroundLocationPermissionAndroid,
                 setIsEnableLocationPermissionIOS,      
                );
		}
        else
        {
            if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
                {
                  document.title = "Create notification | AppMySite";
                }
                else 
                {
                  document.title = "Create notification |"+ ' ' +localStorage.getItem('agencyname');
                }
            setRedirectDraft(true);
            setSpinner(true);
            getAllSegmentList(setSegmentList, setTagCheckedArr, setTagCheckedArr1, setAndroidNotificationStatus, setiOSNotificationStatus,setSpinner,                 setIsEnableBackgroundLocationPermissionAndroid,
                setIsEnableForegroundLocationPermissionAndroid,
                setIsEnableLocationPermissionIOS,   );
        }

        if (parseInt(localStorage.getItem("website_technology")) === 3) {
            let mergeArr = [  {
                invidual_selected_item_id: "",
                temp_invidual_selected_item_id: "",
                name: "Pages",
                post_type: "",
                rest_base: "",
                slug: "page",
                type: "cms_page",
                isCms: 1,
              },
              {
                invidual_selected_item_id: "",
                temp_invidual_selected_item_id: "",
                name: "Posts",
                post_type: "",
                rest_base: "",
                slug: "post",
                type: "cms_post",
                isCms: 1,
              },
      
              {
                invidual_selected_item_id: "",
                temp_invidual_selected_item_id: "",
                name: "Post Categories",
                post_type: "",
                rest_base: "",
                slug: "category",
                type: "cms_category",
                isCms: 1,
              },
              {
                invidual_selected_item_id: "",
                temp_invidual_selected_item_id: "",
                name: "Post Tags",
                post_type: "",
                rest_base: "",
                slug: "tag",
                type: "cms_tag",
                isCms: 1,
              },
            ];
            setMergeArray(mergeArr);
        } else if(parseInt(localStorage.getItem("website_technology")) === 4 || parseInt(localStorage.getItem("website_technology")) === 5){

          BottoMenuTypeApi(storeUrl,setTypesData,(newTypesData) => {


           let newTypesData1 = newTypesData;


            BottoMenuTaxonomyApi(storeUrl,setTaxonomyData,(newTaxonomyData) => {


                taxonomyDataset(newTaxonomyData,newTypesData1);
          })
        })

      }
      
      if(skipButtonActive){
        setSpinner(false)
       }

	}, []);

    const handleClearLink = (e) => {
        setSearchValForPopUp("");
        setSubdropdownSpinner(false);
        setTempShowL1Options(true); 
        setTempShowL1Options(true);
        setShowL1Options(true);
        setInvidualSelectedItemId("");
        setTempInvidualSelectedItemId("");
        setItemType("");
        setTempItemType("");
        setTempSlug("");
        setTempRestBase("");
        setTempPostType("");
        setTempModuleLabel("");
        setTempItemName("");
        setTempBreadcrumbArr([]);
        setTempSubdata([]);
        setSlug("");
        setRestBase("");
        setPostType("");
        setModuleLabel("");
        setItemName("");
        setBreadcrumbArr([]);
        setSubData([]);
      }

    const convertHTMLEntity = (text) => {

        const span = document.createElement("span");

      

        if (text !== undefined) {

          return text.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {

            span.innerHTML = entity;

            return span.innerText;

          });

        }

      };

    const handleTitle = (e) =>{

        let titledata = e.target.value;
        titledata = titledata.replace('"', '');
		setTitle(titledata);
	}

	const handleMessage = (e) =>{

        let messagedata = e.target.value;
        messagedata = messagedata.replace('"', '');
		setMessage(messagedata);
	}

    // const handleLinkUrlChange = (e) =>{


    //     let linkurlval = (e.target.value);

    //     let linkurldata = linkurlval.replace("http://", "");
    //     linkurldata = linkurldata.replace("https://", "");
	// 	setLinkUrl(linkurldata);
        
	// };

    const submitNotification = (e) =>{

    }

    const toggle = () =>{

    }

    const handleIos = () => {
        setIOS(true);
        setAndroid(false);
        
    };

    const handleAndroid = () => {
        setIOS(false);
        setAndroid(true);

    };

    const handleRowOpen = (e, rowindex) =>{

        if(rowindex === openrownum)
        {
            setOpenRowNumber(null);
        }
        else
        {
            setOpenRowNumber(rowindex);
        }
    if(rowindex === 3 && tempInvidualSelectedItemId){

        let newPage = 1;
        let limit = 99;
        

        setSpinnerForsubDrop(true);    
            if (parseInt(localStorage.getItem("website_technology")) === 3) 
                {
                if (itemType === "cms_post") 
                {
                    cmsGetPostsForBtm(                        
                        restBase,
                        isAllowPagination , 
                        setIsAllowPagination , 
                        setPageNo , 
                        newPage,
                        setPaginationSpinner,
                        storeUrl,
                        subdropdownData,
                        setSubdropdownData,
                        subData,
                        setSubData,
                        setTempSubdata,
                        tempSubdata,
                        setTempBreadcrumbArr,
                        tempBreadcrumbArr,
                        setBreadcrumbArr,
                        breadcrumbArr,
                        setSubdropdownSpinner,
                        subdropdownSpinner,
                        setTempShowL1Options,
                        tempShowL1Options,
                        setShowL1Options,
                        showL1Options,
                        setModuleLabel,
                        moduleLabel,
                        setTempModuleLabel,
                        tempModuleLabel,
                        setTempItemType,
                        itemType,
                        setItemType,
                        itemType,
                        setSpinnerForsubDrop,
                        newPage,
                        invidualSelectedItemId,
                        restBase,
                        slug,
                        postType,
                        mergeArray,
                        limit,
                    );
                } 
                else if (itemType === "cms_page") 
                {
                    cmsGetPagesForBtm(                        
                        restBase,
                        isAllowPagination , 
                        setIsAllowPagination , 
                        setPageNo , 
                        newPage,
                        setPaginationSpinner,
                        storeUrl,
                        subdropdownData,
                        setSubdropdownData,
                        subData,
                        setSubData,
                        setTempSubdata,
                        tempSubdata,
                        setTempBreadcrumbArr,
                        tempBreadcrumbArr,
                        setBreadcrumbArr,
                        breadcrumbArr,
                        setSubdropdownSpinner,
                        subdropdownSpinner,
                        setTempShowL1Options,
                        tempShowL1Options,
                        setShowL1Options,
                        showL1Options,
                        setModuleLabel,
                        moduleLabel,
                        setTempModuleLabel,
                        tempModuleLabel,
                        setTempItemType,
                        itemType,
                        setItemType,
                        itemType,
                        setSpinnerForsubDrop,
                        newPage,
                        invidualSelectedItemId,
                        restBase,
                        slug,
                        postType,
                        mergeArray,
                        limit,
                    );
                } 
                else if (itemType === "cms_category") 
                {
                    cmsgetCategoryForBtm(                        
                        restBase,
                        isAllowPagination , 
                        setIsAllowPagination , 
                        setPageNo , 
                        newPage,
                        setPaginationSpinner,
                        storeUrl,
                        subdropdownData,
                        setSubdropdownData,
                        subData,
                        setSubData,
                        setTempSubdata,
                        tempSubdata,
                        setTempBreadcrumbArr,
                        tempBreadcrumbArr,
                        setBreadcrumbArr,
                        breadcrumbArr,
                        setSubdropdownSpinner,
                        subdropdownSpinner,
                        setTempShowL1Options,
                        tempShowL1Options,
                        setShowL1Options,
                        showL1Options,
                        setModuleLabel,
                        moduleLabel,
                        setTempModuleLabel,
                        tempModuleLabel,
                        setTempItemType,
                        itemType,
                        setItemType,
                        itemType,
                        setSpinnerForsubDrop,
                        newPage,
                        invidualSelectedItemId,
                        restBase,
                        slug,
                        postType,
                        mergeArray,
                        limit,
                    );
                } 
                else if (itemType === "cms_tag") 
                {
                    cmsgetTagsForBtm(                        
                        restBase,
                        isAllowPagination , 
                        setIsAllowPagination , 
                        setPageNo , 
                        newPage,
                        setPaginationSpinner,
                        storeUrl,
                        subdropdownData,
                        setSubdropdownData,
                        subData,
                        setSubData,
                        setTempSubdata,
                        tempSubdata,
                        setTempBreadcrumbArr,
                        tempBreadcrumbArr,
                        setBreadcrumbArr,
                        breadcrumbArr,
                        setSubdropdownSpinner,
                        subdropdownSpinner,
                        setTempShowL1Options,
                        tempShowL1Options,
                        setShowL1Options,
                        showL1Options,
                        setModuleLabel,
                        moduleLabel,
                        setTempModuleLabel,
                        tempModuleLabel,
                        setTempItemType,
                        itemType,
                        setItemType,
                        itemType,
                        setSpinnerForsubDrop,
                        newPage,
                        invidualSelectedItemId,
                        restBase,
                        slug,
                        postType,
                        mergeArray,
                        limit,
                    );
                }
                } 
                else 
                {
                if(restBase==="product_cat"){
                    productCategoriesTreeAPIForBtm(
                        restBase,
                        isAllowPagination , 
                        setIsAllowPagination , 
                        setPageNo , 
                        newPage,
                        setPaginationSpinner,
                        storeUrl,
                        subdropdownData,
                        setSubdropdownData,
                        subData,
                        setSubData,
                        setTempSubdata,
                        tempSubdata,
                        setTempBreadcrumbArr,
                        tempBreadcrumbArr,
                        setBreadcrumbArr,
                        breadcrumbArr,
                        setSubdropdownSpinner,
                        subdropdownSpinner,
                        setTempShowL1Options,
                        tempShowL1Options,
                        setShowL1Options,
                        showL1Options,
                        setModuleLabel,
                        moduleLabel,
                        setTempModuleLabel,
                        tempModuleLabel,
                        setTempItemType,
                        itemType,
                        setItemType,
                        itemType,
                        setSpinnerForsubDrop,
                        newPage,
                        invidualSelectedItemId,
                        restBase,
                        slug,
                        postType,
                        mergeArray,
        
                    );
                }else{
                    restBaseTaxonomyapi(
                        restBase,
                        isAllowPagination , 
                        setIsAllowPagination , 
                        setPageNo , 
                        newPage,
                        setPaginationSpinner,
                        storeUrl,
                        subdropdownData,
                        setSubdropdownData,
                        subData,
                        setSubData,
                        setTempSubdata,
                        tempSubdata,
                        setTempBreadcrumbArr,
                        tempBreadcrumbArr,
                        setBreadcrumbArr,
                        breadcrumbArr,
                        setSubdropdownSpinner,
                        subdropdownSpinner,
                        setTempShowL1Options,
                        tempShowL1Options,
                        setShowL1Options,
                        showL1Options,
                        setModuleLabel,
                        moduleLabel,
                        setTempModuleLabel,
                        tempModuleLabel,
                        setTempItemType,
                        itemType,
                        setItemType,
                        itemType,
                        setSpinnerForsubDrop,
                        newPage,
                        invidualSelectedItemId,
                        restBase,
                        slug,
                        postType,
                        mergeArray,
                        limit,
                    );
                }
                
                }
    }

    }

    const handleSettingStatus = () => {

        setSettingStatus( !settingstatus );
        // setSettingHome( !settinghome );
    }

    const handleSettingHome = () =>
    {
        setSettingHome(true);
        setSettingStatus(false);
    }

    const handleDeviceIos = (e) =>
    {
        
        if(e.target.checked)
        {
            setChkIos("ios");
        }
        else
        {
            setChkIos("");
        }
    }

    const handleDeviceAndroid = (e) =>
    {
        
        

        if(e.target.checked)
        {
            setChkAndroid("android");
            
        }
        else
        {
            setChkAndroid("");
        }
    }

    const handleScheduleNow = (e) =>{

        if(e.target.checked)
        {
            setChkScheduleNow(true);
            setDeliveryType(1);
            setDeliverySchedule(true);
        }
        else
        {
            setChkScheduleNow(false);
            setDeliveryType(2);
            setDeliverySchedule(false);
        }
    }

    const handleSendAllUser = (e) =>{

		//setChkSendAllUsres
		
        if(e.target.checked)
        {            
            setChkSendAllUsers(true);
        }
        else
        {
            setChkSendAllUsers(false);
        }
	}

    const handleDateTimePicker = (dateTime) => 
    {
        
        const currentDate = new Date(dateTime);
        var date = new Date();
        
        if(date > currentDate)
        {
            currentDate.setHours(date.getHours());
            currentDate.setMinutes(date.getMinutes());
        }
        
        setDateTimeValue(currentDate);

        setDeliveryType(2);
    };

    const filterPassedTime = (time) => {
        const currentDate = new Date();
        
        const selectedDate = new Date(time);
    
        return currentDate.getTime() < selectedDate.getTime();
    };

    const handleSegmentPopupClose = () =>{
		setSegmentPopup(false);
	}

	const handleSelectSegmentsData = (e) =>{
		e.preventDefault();
		setSegmentPopup(false);
		setIncludeSegmentList(selectedinsegment);
		setExcludeSegmentList(selectedexsegment);
	}

	const handleIncludeSegmentClick = (e, data) =>
    {
		setIncludeSegmentList(data);

	}

	const handleExcludeSegmentClick = (e) =>{
		
	}

    const handleNotificationImagePopup= () =>{

		setIsGalleryPopup(true);
		setprevImgUrl(notificationimage);
	}

	const handleSegmentSelect = (segname, e) =>{

		if(segmenttype === 1)
		{
			
			const getincludelist = [...includesegmentlist];

			const innextidx = (getincludelist.length);

			getincludelist.push(segname);

			setSelectedInSegment(getincludelist);
			
		}
		else if(segmenttype === 2)
		{
			const getexcludelist = [...excludesegmentlist];
			const exnextidx = (getexcludelist.length);
			getexcludelist.push(segname);
			setSelectedExSegment(getexcludelist);
			
		}

		
		

	}

	const handleDeleteIncludeSegment = (indelidx, insegname, e) =>{

		const getincludesegvalue = [...includesegmentlist];
        getincludesegvalue.splice(indelidx, 1);
		
        setIncludeSegmentList(getincludesegvalue);
		setSelectedInSegment(getincludesegvalue);

	}

	const handleDeleteExcludeSegment = (exdelidx, exsegname, e) =>{

		let getexcludesegvalue = [...excludesegmentlist];
		
		
        getexcludesegvalue.splice(exdelidx, 1);
		
		
        setExcludeSegmentList(getexcludesegvalue);
		setSelectedExSegment(getexcludesegvalue);

	}

    const handleTagDropdown = (e, from) => {
        setIsIncSegmentDropdown(!isincsegmentdropdown);
    };

    const handleMobileTagDropdown = () => {
        setOpenMobileSegmentDropdown(!openmobilesegmentDropdown);
    };

    const handleDelTag = (e, indx,row) => {

        let newArr = [...includesegmentlist];
        newArr.splice(indx, 1);
        setIncludeSegmentList(newArr);
        var array = includesegmentarr;
       const index = includesegmentarr.indexOf(row.segment_name);
        if (index > -1) 
        {                            // only splice array when item is found
            array.splice(index, 1); // 2nd parameter means remove one item only
        }
       setIncludeSegmentArr(array);

       

       let getsegmentarray = [...segmentlist];
       let getincludesegment = [...includesegmentlist];
        // getsegmentarray.push(getincludesegment[indx]);
        // setSegmentList(getsegmentarray);
    
       setTimeout(()=>{
            if(document.getElementById("calculate-ele-height-tag"))
            {
                if(document.getElementById("calculate-ele-height-tag").clientHeight<=53 )
                {
                    document.getElementById("test-height-tag").classList.remove("increase-height1");
                }
                else if(document.getElementById("calculate-ele-height-tag").clientHeight<=91 )
                {
                    document.getElementById("test-height-tag").classList.remove("increase-height2");
                }
                else if(document.getElementById("calculate-ele-height-tag").clientHeight<=130)
                {
                    document.getElementById("test-height-tag").classList.remove("increase-height3");
                }
            }
        });

        e.stopPropagation();
    };

    const handleAddTagDrop = (e,obj, position) => {

        let newArr = [...includesegmentlist];

        if(e.target.checked)
        {
            newArr.push(obj);
            
            const arr = includesegmentarr;
                  arr.push(obj.segment_name);
            setIncludeSegmentArr(arr);
            setIncludeSegmentList(newArr);
            setTimeout(()=>{
                if(document.getElementById("calculate-ele-height-tag"))
                {
                    if(document.getElementById("calculate-ele-height-tag").clientHeight>53 && document.getElementById("calculate-ele-height-tag").clientHeight<=91 )
                    {
                        document.getElementById("test-height-tag").classList.add("increase-height1");
                    }
                    else if(document.getElementById("calculate-ele-height-tag").clientHeight>91 && document.getElementById("calculate-ele-height-tag").clientHeight<=130)
                    {
                        document.getElementById("test-height-tag").classList.add("increase-height2");
                    }
                    else if(document.getElementById("calculate-ele-height-tag").clientHeight>130)
                    {
                        document.getElementById("test-height-tag").classList.add("increase-height3");
                    }
                }
            })
        }
        else
        {
            
            for (let i = 0; i < newArr.length; i++) 
            {
                if(newArr[i]._id===obj._id)
                {
                    newArr.splice(i, 1);
                    break;
                }
            }

            var array = includesegmentarr;
            
            
            const index = includesegmentarr.indexOf(obj.segment_name);
             if (index > -1) 
             {                            // only splice array when item is found
                 array.splice(index, 1); // 2nd parameter means remove one item only
             }
             
            
            setIncludeSegmentArr(array);
            setIncludeSegmentList(newArr);
            setTimeout(()=>{
                if(document.getElementById("calculate-ele-height-tag"))
                {
                    if(document.getElementById("calculate-ele-height-tag").clientHeight<=53 )
                    {
                        document.getElementById("test-height-tag").classList.remove("increase-height1");
                    }
                    else if(document.getElementById("calculate-ele-height-tag").clientHeight<=91 )
                    {
                        document.getElementById("test-height-tag").classList.remove("increase-height2");
                    }
                    else if(document.getElementById("calculate-ele-height-tag").clientHeight<=130)
                    {
                        document.getElementById("test-height-tag").classList.remove("increase-height3");
                    }
                }
            })
        }
       
    };

    const handleTagDropdown1 = (e, from) => {
        setIsExcSegmentDropdown(!isexcsegmentdropdown);
    };

    const handleMobileTagDropdown1 = () => {
        setOpenMobileSegmentDropdown1(!openmobilesegmentDropdown1);
    };

    const handleDelTag1 = (e, indx,row) => {

        let newArr1 = [...excludesegmentlist];
        
        
        newArr1.splice(indx, 1);
        var array = excludesegmentarr;
       const index = excludesegmentarr.indexOf(row.segment_name);
        if (index > -1) { // only splice array when item is found
        array.splice(index, 1); // 2nd parameter means remove one item only
        }
       setExcludeSegmentArr(array);
        
        setExcludeSegmentList(newArr1);
        //setIncludeSegmentList(newArr);
        /*const updatedCheckedState = [...tagCheckedArr1];
        
        for (let j = 0; j < segmentlist.length; j++) 
        {

            if(segmentlist[j].id === row.id)
            {
                updatedCheckedState[j]=false;
                break;
            }
       }
       
       setTagCheckedArr1(updatedCheckedState);*/ 

        let getsegmentarray = [...segmentlist];
        // let getexcludesegment = [...excludesegmentlist];
        // getsegmentarray.push(getexcludesegment[indx]);
        // setSegmentList(getsegmentarray);
    
       setTimeout(()=>{
            if(document.getElementById("calculate-ele-height-tag"))
            {
                if(document.getElementById("calculate-ele-height-tag").clientHeight<=53 )
                {
                    document.getElementById("test-height-tag1").classList.remove("increase-height1");
                }
                else if(document.getElementById("calculate-ele-height-tag").clientHeight<=91 )
                {
                    document.getElementById("test-height-tag1").classList.remove("increase-height2");
                }
                else if(document.getElementById("calculate-ele-height-tag").clientHeight<=130)
                {
                    document.getElementById("test-height-tag1").classList.remove("increase-height3");
                }
            }
        });

        e.stopPropagation();
    };

    const handleAddTagDrop1 = (e,obj, position) => {

        let newArr1 = [...excludesegmentlist];
       
        /*const updatedCheckedState = tagCheckedArr1.map((item, index) =>
            index === position ? !item : item
        );
    
        setTagCheckedArr1(updatedCheckedState);*/ 
       
        if(e.target.checked)
        {
            
            newArr1.push(obj);
            const arr = excludesegmentarr;
            arr.push(obj.segment_name);
            setExcludeSegmentArr(arr);
            setExcludeSegmentList(newArr1);

            // let getsegmentarray = [...segmentlist];
            // getsegmentarray.splice(position, 1);
            // setSegmentList(getsegmentarray);
            
            setTimeout(()=>{
                if(document.getElementById("calculate-ele-height-tag"))
                {
                    if(document.getElementById("calculate-ele-height-tag").clientHeight>53 && document.getElementById("calculate-ele-height-tag").clientHeight<=91 )
                    {
                        document.getElementById("test-height-tag1").classList.add("increase-height1");
                    }
                    else if(document.getElementById("calculate-ele-height-tag").clientHeight>91 && document.getElementById("calculate-ele-height-tag").clientHeight<=130)
                    {
                        document.getElementById("test-height-tag1").classList.add("increase-height2");
                    }
                    else if(document.getElementById("calculate-ele-height-tag").clientHeight>130)
                    {
                        document.getElementById("test-height-tag1").classList.add("increase-height3");
                    }
                }
            })
        }
        else
        {
            
            for (let x = 0; x < newArr1.length; x++) 
            {
                if(newArr1[x]._id===obj._id)
                {
                    newArr1.splice(x, 1);
                    break;
                }
            }

            var array = excludesegmentarr;
            const index = excludesegmentarr.indexOf(obj.segment_name);
             if (index > -1) 
             {                            // only splice array when item is found
                 array.splice(index, 1); // 2nd parameter means remove one item only
             }
            
            setExcludeSegmentArr(array);
            setExcludeSegmentList(newArr1);
            setTimeout(()=>{
                if(document.getElementById("calculate-ele-height-tag"))
                {
                    if(document.getElementById("calculate-ele-height-tag").clientHeight<=53 )
                    {
                        document.getElementById("test-height-tag1").classList.remove("increase-height1");
                    }
                    else if(document.getElementById("calculate-ele-height-tag").clientHeight<=91 )
                    {
                        document.getElementById("test-height-tag1").classList.remove("increase-height2");
                    }
                    else if(document.getElementById("calculate-ele-height-tag").clientHeight<=130)
                    {
                        document.getElementById("test-height-tag1").classList.remove("increase-height3");
                    }
                }
            })
        }


       
    };

    const handleReviewPopup = (e) =>{
      if(planCode === 203){
        toggleActivateModal(e)
        return;
       }

        if(!iosnotificationstatus && chkios === "ios")
        {
                setIosOpenPopUp(true);
        }
        else if(!androidnotificationstatus && chkandroid === "android")
        {
                setAndroidOpenPopUp(true);
        }
        else if(
                checkLocationSegment() && !chksendallusers  &&
            ( 
                (chkios === "ios" && !isEnableLocationPermissionIOS  ) || 
                (chkandroid === "android" && ( !isEnableForegroundLocationPermissionAndroid  || !isEnableBackgroundLocationPermissionAndroid ))
            )
        )
        {
        setOpenLocationPopUp(true);
        }else{

		setReviewPopup (true);
        }

	}

	const btnPopupClose = () =>{
		setReviewPopup (false);
	}

    const handleandroidPopUp = () => {
        setAndroidOpenPopUp(false);
    }

    const toTimestamp = (strDate) => {

        var datum = Date.parse(strDate);

        return datum/1000;

     }

    const handleiosPopUp = () => {
        setIosOpenPopUp(false);
    }

    const btnSendNotification = (e) =>{
		e.preventDefault();
        let sendimage = null;

        let urlcheck = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

        if(notificationimage !== undefined && notificationimage !== "" && notificationimage !== null)
        {
            if( notificationimage.indexOf("https") === -1 )
            {
                sendimage = notificationimgpath + notificationimage;
            }
            else
            {
                sendimage = notificationimage;
            }
        }

        let typedevice = "";
        let recheck = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

        if(itemType === "web_view" && ( !webViewUrl || !webViewUrl.trim() ) ){
         errortoast("Web view URL is required.");
         return;
        }
        if (itemType === "web_view" &&  !recheck.test("https://" + webViewUrl)){
            errortoast("Web view URL is invalid.");
            return;
        }


        if((iosnotificationstatus == false || iosnotificationstatus == 0) && chkios === "ios")
            {
                setIosOpenPopUp(true);
                return;
            }
        
            if(checkLocationSegment() === true && (chksendallusers === false || chksendallusers === 0)  &&
            ( 
                (chkios === "ios" && isEnableLocationPermissionIOS === 0 ) || 
                (
                    chkandroid === "android" && ( isEnableForegroundLocationPermissionAndroid === 0 || isEnableBackgroundLocationPermissionAndroid === 0)
                )
            )
       )
        {
            setOpenLocationPopUp(true);
            return;
        }
    
    
            if((androidnotificationstatus == false || androidnotificationstatus == 0) && chkandroid === "android")
            {
                setAndroidOpenPopUp(true);
                return;
            }
        
        if(chkios === "ios" && iosnotificationstatus && androidnotificationstatus && chkandroid === "android")
        {
            typedevice = "both";
            setDeviceType(typedevice);
        }
        else if(iosnotificationstatus && chkios === "ios")
        {
            typedevice = "ios";
            setDeviceType(typedevice);
        }
        else if(androidnotificationstatus && chkandroid === "android")
        {
            typedevice = "android";
            setDeviceType(typedevice);
        }
        else
        {
            errortoast("Please select your target platform - iOS or Android.");
            return;
        }

        if(chkschedulenow == false)
        {
            if(dateTimeValue != '' && dateTimeValue != null && dateTimeValue != undefined)
            {
                var inputtime = toTimestamp(dateTimeValue);
                var currentime = Math.floor(Date.now() / 1000)

               if(inputtime > currentime)
                {

                }
                else
                {
                    errortoast("Please select a future date and time.");
                    return;                    
                }
            }
            else
            {
                errortoast("Schedule date is a required field.");
                return;
            }
        }

        if(typedevice == '')
        {
            errortoast("Please select your target platform - iOS or Android.");
            
        }
        // else if(linkurl !== "" && linkurl !== "" && linkurl !== undefined)
        // {
        //     if(!urlcheck.test("https://" + linkurl))
        //     {
        //         errortoast("Correct link is required");
        //         return;
        //     }
        // }
        else
        {
            
            const params = {						
                            "app_id": parseInt(localStorage.getItem("Appid")),
                            "title": title,
                            "message": message,
                            "notification_image": sendimage,
                            // "notification_link": linkurl,
                            // "notification_link_name": linkname,
                            "included_segment_name": chksendallusers ? []  : includesegmentlist,
                            "excluded_segment_name": chksendallusers ? []  : excludesegmentlist,
                            "delivery_type": deliverytype,
                            "device_type": typedevice,
                            "delivery_date": (chkschedulenow === true ? "" 
                                           : dateTimeValue != '' && dateTimeValue != null && dateTimeValue != undefined ? moment(dateTimeValue).utc().format("Y-MM-DD HH:mm:ss")
                                           : ''),
                            "status": (chkschedulenow === true ? 1 : 1),
                            "send_to_allusers":chksendallusers,
                            "notification_id": (notificationid !== '' && notificationid !== undefined) ? parseInt(atob(notificationid).trim()) : "",
                            "custom_data":{
                                "item_type":itemType,
                                "item_id":invidualSelectedItemId?invidualSelectedItemId.toString():"",      
                                "item_title":itemName,
                                "web_view_url":itemType==="web_view"?webViewUrl:"",
                                "slug": slug,
                                "rest_base": restBase,
                                "post_type": postType,
                            }
                            
                        };

            
            setIsLoader("d-block");
            setSubmitVal("");
            sendPushNotificationOneSignal(params, redirectToNotificationList, setIsLoader, setSubmitVal);
        }
	}

	const handleSaveDraft = (e) =>{
		e.preventDefault();  
    if(planCode === 203){
      toggleActivateModal(e)
      return;
     }
        
        let recheck = /^(?:(?:https?|ftp):\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;

        if(itemType === "web_view" && ( !webViewUrl || !webViewUrl.trim() ) ){
         errortoast("Web view URL is required.");
         return;
        }
        if (itemType === "web_view" &&  !recheck.test("https://" + webViewUrl)){
            errortoast("Web view URL is invalid.");
            return;
        }

        if((iosnotificationstatus == false || iosnotificationstatus == 0) && chkios === "ios")
            {
                setIosOpenPopUp(true);
                return;
            }

            if((androidnotificationstatus == false || androidnotificationstatus == 0) && chkandroid === "android")
                {
                    setAndroidOpenPopUp(true);
                    return;
                }
        // if(checkLocationSegment() === true && (chksendallusers === false || chksendallusers === 0)  &&
        //         ( 
        //             (chkios === "ios" && isEnableLocationPermissionIOS === 0 ) || 
        //             (
        //                 chkandroid === "android" && ( isEnableForegroundLocationPermissionAndroid === 0 || isEnableBackgroundLocationPermissionAndroid === 0)
        //             )
        //         )
        //    )
        //     {
        //         setOpenLocationPopUp(true);
        //         return;
        //     }
        let typedevice = "";
        if(chkios === "ios" && iosnotificationstatus && androidnotificationstatus && chkandroid === "android")
        {
            typedevice = "both";
            setDeviceType(typedevice);
        }
        else if(iosnotificationstatus && chkios === "ios")
        {
            typedevice = "ios";
            setDeviceType(typedevice);
        }
        else if(androidnotificationstatus && chkandroid === "android")
        {
            typedevice = "android";
            setDeviceType(typedevice);
        }
        else
        {
            typedevice = "";
        }

        let sendimage = null;

        if(notificationimage !== undefined && notificationimage !== "" && notificationimage !== null)
            {
                if( notificationimage.indexOf("https") === -1 )
                {
                    sendimage = notificationimgpath + notificationimage;
                }
                else
                {
                    sendimage = notificationimage;
                }
            }

       

		const params = {
			"app_id": parseInt(localStorage.getItem("Appid")),
			"title": title,
			"message": message,
			"notification_image": sendimage,
			// "notification_link": linkurl,
			// "notification_link_name": linkname,
            "included_segment_name": chksendallusers ? []  : includesegmentlist,
            "excluded_segment_name": chksendallusers ? []  : excludesegmentlist,
			"delivery_type": ((deliverytype === 0) ? 1 : deliverytype),
			"device_type": typedevice,
			"delivery_date" : (chkschedulenow === true ? "" 
                            : dateTimeValue != '' && dateTimeValue != null && dateTimeValue != undefined ? moment(dateTimeValue).utc().format("Y-MM-DD HH:mm:ss")
                            : ''),
			"status": 0,
            "send_to_allusers":chksendallusers,
			"notification_id": (notificationid !== '' && notificationid !== undefined) ? parseInt(atob(notificationid).trim()) : "",
		    "custom_data":{
                "item_type":itemType,
                "item_id":invidualSelectedItemId?invidualSelectedItemId.toString():"",   
                "item_title":itemName,
                "web_view_url":itemType==="web_view"?webViewUrl:"",
                "slug": slug,
                "rest_base": restBase,
                "post_type": postType,

            }
        };        
        setDraftSpinner(true);
        setDraftLoader("d-block");
        setDraftSubmitVal("");
        
		saveDraftPushNotificationOneSignal(params, redirectToNotificationList, setDraftLoader, setDraftSubmitVal, setDraftSpinner,redirectdraft);
	}

   const handleOpenLocationPopUp = () => {
        setOpenLocationPopUp(!openLocationPopUp);
    }

    const handleSetImageData = (imgName, from, orgFileName, url1200, useUrl) =>{

		
		setNotificationImage(imgName);
        setNotificationImgName(imgName);
	}

	const handleImageErrorData = (errimgdata) =>{

		

	}

    const handleDeleteNotificationImage = () =>{

        setNotificationImage('');
    }

    const redirectToNotificationList = () =>{

        props.history.push("/app/advanced-notifications");
    }

    const handleRightPanelExpand = (value) => {

		setAppRightPanelExpand(value);
	}

// Location Pop Up functions
    
const handleOpenSelectionPopUp = (from,indx) => {
    setOpenSingleSelectionPopUp(!openSingleSelectionPopUp);
  if(from === "cancel"){
    setTempInvidualSelectedItemId(invidualSelectedItemId);
    setTempItemType(itemType);
    setTempSlug(slug);
    setTempRestBase(restBase);
    setTempPostType(postType);
    setTempModuleLabel(moduleLabel);
    setTempItemName(itemName);
    setTempBreadcrumbArr(breadcrumbArr);
    setTempSubdata(subData);
    setTempShowL1Options(showL1Options);

  }else{
    if(from==="open")
    {
        setInvidualSelectedItemId(tempInvidualSelectedItemId);
        setItemType(tempItemType);
        setSlug(tempSlug);
        setRestBase(tempRestBase);
        setPostType(tempPostType);
        setModuleLabel(tempModuleLabel);
        setItemName(tempItemName);
        setBreadcrumbArr(tempBreadcrumbArr);
        setSubData(tempSubdata);
        setShowL1Options(tempShowL1Options);
       
    }

  }

}

const handleSubData = (e, res) => {
    let newBreadcrumbArr = [...tempBreadcrumbArr]
    let title1 = "";
    let child = [];
    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      title1 = res.title === undefined ? res.name : res.title;
      child = res.child ? res.child : [];
    } else {
      title1 = convertHTMLEntity(
        res.title === undefined ? res.name : res.title.rendered
      );
      child = res.children ? res.children : [];
    }
    newBreadcrumbArr.push({
      name: title1,
      id: res._id ? res._id : res.term_id.toString(),
    });
    setTempSubdata(child);
    setTempBreadcrumbArr(newBreadcrumbArr);
  };
  const handleHomeBtnClick = (e) => {
    setTempBreadcrumbArr([]);
    setTempShowL1Options(true)
  };

  const handleFirstLabelClick = () => {
    setTempBreadcrumbArr([]);
    setTempSubdata(subdropdownData);
  };

  const handleBreadcrumb = (e, val, indx) => {
    let newData = [];
    let newBreadcrumb = [];

    let breadcrumb = [...tempBreadcrumbArr];

    for (let i = 0; i < breadcrumb.length; i++) {
      if (val.name == breadcrumb[i].name) {
        newBreadcrumb.push(breadcrumb[i]);
        break;
      } else {
        newBreadcrumb.push(breadcrumb[i]);
      }
    }
    setTempBreadcrumbArr(newBreadcrumb);
    function recurs(arr) {
      arr.map((eachCat) => {
        if (eachCat.parent == val.id) {
          newData.push(eachCat);
        }

        if (parseInt(localStorage.getItem("website_technology")) === 3) {
          if (eachCat.child !== undefined && eachCat.child.length > 0) {
            // if parent has childrens
            recurs(eachCat.child);
          }
        } else {
          if (eachCat.children !== undefined && eachCat.children.length > 0) {
            // if parent has childrens
            recurs(eachCat.children);
          }
        }
      });
    }
    recurs(subdropdownData);
    setTempSubdata(newData);

  };

  const handleMobBackBtn = () => {
    let breadcrumb = JSON.parse(JSON.stringify(tempBreadcrumbArr));
    
    if (breadcrumb && breadcrumb.length > 0) {
      let newData = [];
      breadcrumb.splice(-1, 1);
      setTempBreadcrumbArr(breadcrumb);

      
      function recurs(arr) {
        arr.map((eachCat) => {
          if (eachCat.parent == breadcrumb[breadcrumb.length - 1].id) {
            newData.push(eachCat);
          }
          if (parseInt(localStorage.getItem("website_technology")) === 3) {
            if (eachCat.child !== undefined && eachCat.child.length > 0) {
              // if parent has childrens
              recurs(eachCat.child);
            }
          } else {
            if (eachCat.children !== undefined && eachCat.children.length > 0) {
              // if parent has childrens
              recurs(eachCat.children);
            }
          }
        });
      }

      if (breadcrumb && breadcrumb.length > 0) {
        recurs(subdropdownData);
        setTempSubdata(newData);
      } else {
        setTempSubdata(subdropdownData);
      }

    } else {
      if(tempShowL1Options===false){
        setTempShowL1Options(true);
      }else{
        handleOpenSelectionPopUp("cancel");
      }
    }
  };

  const handleSearchVal = (e) => {
    setSearchValForPopUp(e.target.value.trim());
  };

 const handleL1Change = (val) => {
    setTempItemType(val.item_type);
    setTempModuleLabel(val.item_name);
    setTempInvidualSelectedItemId("");
    setTempItemName(val.item_name);
  }
 const handleMergeArrLeftDropdown = (e,res) => 
    {   

      if(res)
      {
        setTempSlug(res.slug);
        setTempRestBase(res.rest_base);
        setTempItemType(res.type);
        setTempInvidualSelectedItemId(res.temp_invidual_selected_item_id);
        setTempModuleLabel(res.name);
        setTempPostType(res.post_type);
        setTempItemName(res.name);
        // newArr[i].isCms = res.isCms;
      }
   
    };

const handleParentClick = (val) => {
setSearchValForPopUp("");
// setTempModuleLabel(val.name);
setTempItemType(val.type);
setTempRestBase(val.rest_base);
setTempPostType(val.post_type);
setTempSlug(val.slug);
setSubdropdownSpinner(true);
setTempShowL1Options(false);  

        let newPage = 1;
        let limit = 99;

    if (parseInt(localStorage.getItem("website_technology")) === 3) 
        {
        if (val.type === "cms_post") 
        {
            cmsGetPostsForBtm(                        
                val.rest_base,
                isAllowPagination , 
                setIsAllowPagination , 
                setPageNo , 
                newPage,
                setPaginationSpinner,
                storeUrl,
                subdropdownData,
                setSubdropdownData,
                subData,
                setSubData,
                setTempSubdata,
                tempSubdata,
                setTempBreadcrumbArr,
                tempBreadcrumbArr,
                setBreadcrumbArr,
                breadcrumbArr,
                setSubdropdownSpinner,
                subdropdownSpinner,
                setTempShowL1Options,
                tempShowL1Options,
                setShowL1Options,
                showL1Options,
                setModuleLabel,
                moduleLabel,
                setTempModuleLabel,
                tempModuleLabel,
                setTempItemType,
                val.type,
                setItemType,
                itemType,
                setSpinnerForsubDrop,
                newPage,
                invidualSelectedItemId,
                val.rest_base,
                val.slug,
                val.post_type,
                mergeArray,
                limit,
            );
        } 
        else if (val.type === "cms_page") 
        {
            cmsGetPagesForBtm(                        
                val.rest_base,
                isAllowPagination , 
                setIsAllowPagination , 
                setPageNo , 
                newPage,
                setPaginationSpinner,
                storeUrl,
                subdropdownData,
                setSubdropdownData,
                subData,
                setSubData,
                setTempSubdata,
                tempSubdata,
                setTempBreadcrumbArr,
                tempBreadcrumbArr,
                setBreadcrumbArr,
                breadcrumbArr,
                setSubdropdownSpinner,
                subdropdownSpinner,
                setTempShowL1Options,
                tempShowL1Options,
                setShowL1Options,
                showL1Options,
                setModuleLabel,
                moduleLabel,
                setTempModuleLabel,
                tempModuleLabel,
                setTempItemType,
                val.type,
                setItemType,
                itemType,
                setSpinnerForsubDrop,
                newPage,
                invidualSelectedItemId,
                val.rest_base,
                val.slug,
                val.post_type,
                mergeArray,
                limit,
            );
        } 
        else if (val.type === "cms_category") 
        {
            cmsgetCategoryForBtm(                        
                val.rest_base,
                isAllowPagination , 
                setIsAllowPagination , 
                setPageNo , 
                newPage,
                setPaginationSpinner,
                storeUrl,
                subdropdownData,
                setSubdropdownData,
                subData,
                setSubData,
                setTempSubdata,
                tempSubdata,
                setTempBreadcrumbArr,
                tempBreadcrumbArr,
                setBreadcrumbArr,
                breadcrumbArr,
                setSubdropdownSpinner,
                subdropdownSpinner,
                setTempShowL1Options,
                tempShowL1Options,
                setShowL1Options,
                showL1Options,
                setModuleLabel,
                moduleLabel,
                setTempModuleLabel,
                tempModuleLabel,
                setTempItemType,
                val.type,
                setItemType,
                itemType,
                setSpinnerForsubDrop,
                newPage,
                invidualSelectedItemId,
                val.rest_base,
                val.slug,
                val.post_type,
                mergeArray,
                limit,
            );
        } 
        else if (val.type === "cms_tag") 
        {
            cmsgetTagsForBtm(                        
                val.rest_base,
                isAllowPagination , 
                setIsAllowPagination , 
                setPageNo , 
                newPage,
                setPaginationSpinner,
                storeUrl,
                subdropdownData,
                setSubdropdownData,
                subData,
                setSubData,
                setTempSubdata,
                tempSubdata,
                setTempBreadcrumbArr,
                tempBreadcrumbArr,
                setBreadcrumbArr,
                breadcrumbArr,
                setSubdropdownSpinner,
                subdropdownSpinner,
                setTempShowL1Options,
                tempShowL1Options,
                setShowL1Options,
                showL1Options,
                setModuleLabel,
                moduleLabel,
                setTempModuleLabel,
                tempModuleLabel,
                setTempItemType,
                val.type,
                setItemType,
                itemType,
                setSpinnerForsubDrop,
                newPage,
                invidualSelectedItemId,
                val.rest_base,
                val.slug,
                val.post_type,
                mergeArray,
                limit,
            );
        }
        } 
        else 
        {
        if(val.rest_base==="product_cat"){
            productCategoriesTreeAPIForBtm(
                val.rest_base,
                isAllowPagination , 
                setIsAllowPagination , 
                setPageNo , 
                newPage,
                setPaginationSpinner,
                storeUrl,
                subdropdownData,
                setSubdropdownData,
                subData,
                setSubData,
                setTempSubdata,
                tempSubdata,
                setTempBreadcrumbArr,
                tempBreadcrumbArr,
                setBreadcrumbArr,
                breadcrumbArr,
                setSubdropdownSpinner,
                subdropdownSpinner,
                setTempShowL1Options,
                tempShowL1Options,
                setShowL1Options,
                showL1Options,
                setModuleLabel,
                moduleLabel,
                setTempModuleLabel,
                tempModuleLabel,
                setTempItemType,
                val.type,
                setItemType,
                itemType,
                setSpinnerForsubDrop,
                newPage,
                invidualSelectedItemId,
                val.rest_base,
                val.slug,
                val.post_type,
                mergeArray,

            );
        }else{
            restBaseTaxonomyapi(
                val.rest_base,
                isAllowPagination , 
                setIsAllowPagination , 
                setPageNo , 
                newPage,
                setPaginationSpinner,
                storeUrl,
                subdropdownData,
                setSubdropdownData,
                subData,
                setSubData,
                setTempSubdata,
                tempSubdata,
                setTempBreadcrumbArr,
                tempBreadcrumbArr,
                setBreadcrumbArr,
                breadcrumbArr,
                setSubdropdownSpinner,
                subdropdownSpinner,
                setTempShowL1Options,
                tempShowL1Options,
                setShowL1Options,
                showL1Options,
                setModuleLabel,
                moduleLabel,
                setTempModuleLabel,
                tempModuleLabel,
                setTempItemType,
                val.type,
                setItemType,
                itemType,
                setSpinnerForsubDrop,
                newPage,
                invidualSelectedItemId,
                val.rest_base,
                val.slug,
                val.post_type,
                mergeArray,
                limit,

            );
        }
        
        }


};
const handleBottomScrollListner = (e,rest_base) => {

            let newPage = pageNo + 1;
            let limit = 99;
            if(!paginationSpinner &&  isAllowPagination){
      
            if( parseInt(localStorage.getItem("website_technology")) === 3){
                if (tempItemType === "cms_post") 
                    {
                        cmsGetPostsForBtm(                        
                            tempRestBase,
                            isAllowPagination , 
                            setIsAllowPagination , 
                            setPageNo , 
                            newPage,
                            setPaginationSpinner,
                            storeUrl,
                            subdropdownData,
                            setSubdropdownData,
                            subData,
                            setSubData,
                            setTempSubdata,
                            tempSubdata,
                            setTempBreadcrumbArr,
                            tempBreadcrumbArr,
                            setBreadcrumbArr,
                            breadcrumbArr,
                            setSubdropdownSpinner,
                            subdropdownSpinner,
                            setTempShowL1Options,
                            tempShowL1Options,
                            setShowL1Options,
                            showL1Options,
                            setModuleLabel,
                            moduleLabel,
                            setTempModuleLabel,
                            tempModuleLabel,
                            setTempItemType,
                            tempItemType,
                            setItemType,
                            itemType,
                            setSpinnerForsubDrop,
                            newPage,
                            invidualSelectedItemId,
                            tempRestBase,
                            tempSlug,
                            tempPostType,
                            mergeArray,
                            limit,
                        );
                    } 
                    else if (tempItemType === "cms_page") 
                    {
                        cmsGetPagesForBtm(                        
                            tempRestBase,
                            isAllowPagination , 
                            setIsAllowPagination , 
                            setPageNo , 
                            newPage,
                            setPaginationSpinner,
                            storeUrl,
                            subdropdownData,
                            setSubdropdownData,
                            subData,
                            setSubData,
                            setTempSubdata,
                            tempSubdata,
                            setTempBreadcrumbArr,
                            tempBreadcrumbArr,
                            setBreadcrumbArr,
                            breadcrumbArr,
                            setSubdropdownSpinner,
                            subdropdownSpinner,
                            setTempShowL1Options,
                            tempShowL1Options,
                            setShowL1Options,
                            showL1Options,
                            setModuleLabel,
                            moduleLabel,
                            setTempModuleLabel,
                            tempModuleLabel,
                            setTempItemType,
                            tempItemType,
                            setItemType,
                            itemType,
                            setSpinnerForsubDrop,
                            newPage,
                            invidualSelectedItemId,
                            tempRestBase,
                            tempSlug,
                            tempPostType,
                            mergeArray,
                            limit,
                        );
                    } 
                    else if (tempItemType === "cms_category") 
                    {
                        cmsgetCategoryForBtm(                        
                            tempRestBase,
                            isAllowPagination , 
                            setIsAllowPagination , 
                            setPageNo , 
                            newPage,
                            setPaginationSpinner,
                            storeUrl,
                            subdropdownData,
                            setSubdropdownData,
                            subData,
                            setSubData,
                            setTempSubdata,
                            tempSubdata,
                            setTempBreadcrumbArr,
                            tempBreadcrumbArr,
                            setBreadcrumbArr,
                            breadcrumbArr,
                            setSubdropdownSpinner,
                            subdropdownSpinner,
                            setTempShowL1Options,
                            tempShowL1Options,
                            setShowL1Options,
                            showL1Options,
                            setModuleLabel,
                            moduleLabel,
                            setTempModuleLabel,
                            tempModuleLabel,
                            setTempItemType,
                            tempItemType,
                            setItemType,
                            itemType,
                            setSpinnerForsubDrop,
                            newPage,
                            invidualSelectedItemId,
                            tempRestBase,
                            tempSlug,
                            tempPostType,
                            mergeArray,
                            limit,
                        );
                    } 
                    else if (tempItemType === "cms_tag") 
                    {
                        cmsgetTagsForBtm(                        
                            tempRestBase,
                            isAllowPagination , 
                            setIsAllowPagination , 
                            setPageNo , 
                            newPage,
                            setPaginationSpinner,
                            storeUrl,
                            subdropdownData,
                            setSubdropdownData,
                            subData,
                            setSubData,
                            setTempSubdata,
                            tempSubdata,
                            setTempBreadcrumbArr,
                            tempBreadcrumbArr,
                            setBreadcrumbArr,
                            breadcrumbArr,
                            setSubdropdownSpinner,
                            subdropdownSpinner,
                            setTempShowL1Options,
                            tempShowL1Options,
                            setShowL1Options,
                            showL1Options,
                            setModuleLabel,
                            moduleLabel,
                            setTempModuleLabel,
                            tempModuleLabel,
                            setTempItemType,
                            tempItemType,
                            setItemType,
                            itemType,
                            setSpinnerForsubDrop,
                            newPage,
                            invidualSelectedItemId,
                            tempRestBase,
                            tempSlug,
                            tempPostType,
                            mergeArray,
                            limit,
                        );
                    }
            }
            else{

              if(rest_base && rest_base !== "product_cat"){
                restBaseTaxonomyapi( 
                rest_base,
                isAllowPagination , 
                setIsAllowPagination , 
                setPageNo , 
                newPage,
                setPaginationSpinner,
                storeUrl,
                subdropdownData,
                setSubdropdownData,
                subData,
                setSubData,
                setTempSubdata,
                tempSubdata,
                setTempBreadcrumbArr,
                tempBreadcrumbArr,
                setBreadcrumbArr,
                breadcrumbArr,
                setSubdropdownSpinner,
                subdropdownSpinner,
                setTempShowL1Options,
                tempShowL1Options,
                setShowL1Options,
                showL1Options,
                setModuleLabel,
                moduleLabel,
                setTempModuleLabel,
                tempModuleLabel,
                setTempItemType,
                tempItemType,
                setItemType,
                itemType,
                setSpinnerForsubDrop,
                newPage,
                invidualSelectedItemId,
                tempRestBase,
                tempSlug,
                mergeArray,
                limit,
                );
              }
            }
          
            }
        
}
const handleSubdropdown = (e, value, item_name) => 
{
  setTempInvidualSelectedItemId(value);
  setTempItemName(item_name);
};

const taxonomyDataset = (newTaxonomyData,newTypesData1) =>
    {
      let restbaseArray = [];
      let mergeArray1 = [];
      let res = [];
      let taxnomyArray = [];
      let typeArray1 = [];
  
      if (parseInt(localStorage.getItem("website_technology")) === 3) 
      {
        mergeArray1 = [
          {
            invidual_selected_item_id: "",
            temp_invidual_selected_item_id: "",
            name: "Pages",
            post_type: "",
            rest_base: "",
            slug: "page",
            type: "cms_page",
            isCms: 1,
          },
          {
            invidual_selected_item_id: "",
            temp_invidual_selected_item_id: "",
            name: "Posts",
            post_type: "",
            rest_base: "",
            slug: "post",
            type: "cms_post",
            isCms: 1,
          },
  
          {
            invidual_selected_item_id: "",
            temp_invidual_selected_item_id: "",
            name: "Post Categories",
            post_type: "",
            rest_base: "",
            slug: "category",
            type: "cms_category",
            isCms: 1,
          },
          {
            invidual_selected_item_id: "",
            temp_invidual_selected_item_id: "",
            name: "Post Tags",
            post_type: "",
            rest_base: "",
            slug: "tag",
            type: "cms_tag",
            isCms: 1,
          },
        ];
  
        // this.setState({mergeArray:mergeArray1,spinner:false,});
        setMergeArray(mergeArray1);
      } 
      else 
      {
        mergeArray1 = [];
        if (
          typeof newTaxonomyData === "object" &&
          newTaxonomyData !== null
        ) {
          taxnomyArray = Object.keys(newTaxonomyData).map(
            (key) => newTaxonomyData[key]
          );
        }
        if (
          typeof newTypesData1 === "object" &&
          newTypesData1 !== null
        ) {
          typeArray1 = Object.keys(newTypesData1).map(
            (key) => newTypesData1[key]
          );
        }
        
  
        for (let i = 0; i < typeArray1.length; i++) 
        {
          res = [];
          res.name = typeArray1[i].name;
          res.slug = typeArray1[i].slug;
          res.rest_base = typeArray1[i].rest_base;
          res.type = "post_type";
          res.post_type = typeArray1[i].rest_base;
          res.invidual_selected_item_id = "";
          res.temp_invidual_selected_item_id= "";
          mergeArray1.push(res);
  
          res = [];
          res.name = typeArray1[i].name + " search";
          res.slug = typeArray1[i].slug;
          res.rest_base = typeArray1[i].rest_base;
          res.type = "search";
          res.post_type = typeArray1[i].rest_base;
          res.invidual_selected_item_id = "";
          res.temp_invidual_selected_item_id= "";
          mergeArray1.push(res);
  
          for (let j = 0; j < typeArray1[i].taxonomies.length; j++) {
            if (taxnomyArray.length !== 0) {
              const index = taxnomyArray
                .map((object) => object.slug)
                .indexOf(typeArray1[i].taxonomies[j]);
              res = [];
              res.name = taxnomyArray[index].name+ (typeArray1[i].taxonomies.length > 1 ?" ("+typeArray1[i].name+")":"");
              res.slug = taxnomyArray[index].slug;
              res.rest_base = taxnomyArray[index].rest_base;
              res.type = "taxonomy";
              res.post_type = typeArray1[i].rest_base;
              res.invidual_selected_item_id = "";
              res.temp_invidual_selected_item_id= "";
              mergeArray1.push(res);
            }
          }
        }
       
        mergeArray1 = mergeArray1.filter((row) =>
        (
          (!(row.name.toLowerCase().includes("search")))
          && (!(row.name.toLowerCase().includes("navigation")))
          && (!(row.name.toLowerCase().includes("pattern")))
          && (!(row.name.toLowerCase().includes("template")))
          && (!(row.name.toLowerCase().includes("font")))
        ));
  
        mergeArray1 = mergeArray1.sort((a, b) => {
          const nameA = a.name.toUpperCase(); 
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        setMergeArray(mergeArray1);
        setTypeArray(typeArray1);
        // this.setState({mergeArray:mergeArray1,typeArray:typeArray1,spinner:false});
      }
    }

const returnSelectedModuleName = () => {

        let label = "";
        if (itemType === "chat") {
          label = "Chat";
        } else if (itemType === "main_menu") {
          label = "Menu";
        } else if (itemType === "settings") {
          label = "Settings";
        } else if (itemType === "web_view") {
          label = "Web view";
        } else if (itemType === "home") {
          label = "Home screen";
        } else if (itemType === "profile") {
          label = "Profile";
        } else if (itemType === "product_categories") {
          label = "Product categories";
        } else if (itemType === "product_search") {
          label = "Product search";
        } else if (itemType === "cart") {
          label = "Cart";
        } else if (itemType === "pages") {
          label = "Pages";
        } else if (itemType === "orders") {
          label = "Orders";
        }
        else if (itemType === "cms_post") {
          label = "Posts";
        }
        else if (itemType === "cms_page") {
          label = "Pages";
        }
        else if (itemType === "cms_category") {
          label = "Post categories";
        }
        else if (itemType === "cms_tag") {
          label = "Post Tags";
        }
        else {
      
          mergeArray && mergeArray.length > 0 &&
          mergeArray.map((value, k) => 
          {
        
            if(itemType + slug + restBase + postType === value.type + value.slug + value.rest_base + value.post_type){
              label = value.name;
            }
                  
          })
        }
      
      
        return label;
      };

const returnSelectedItem = (item,arr)=>{
let name = "";

if(!item || item==="all"){
name = "- All";
}else{

function recurs(arr1){
arr1 && arr1.map((res)=>{
if(parseInt(localStorage.getItem("website_technology"))===3){
  if(res._id==item){
    name = "- " + (res.title?res.title:res.name);
  }
}else{
  if(res.term_id){
    if(res.term_id==item){
      name = "- " + (res.title? res.title.rendered :res.name);
    }
  }else{
    if(res.id==item){
      name = "- " + (res.title? res.title.rendered :res.name);
    }
  }

}

if(parseInt(localStorage.getItem("website_technology"))===3){
  if ( res.child !== undefined && res.child.length > 0) {
    // if parent has childrens
    recurs(res.child);
  }
}else{
  if ( res.children !== undefined && res.children.length > 0) {
    // if parent has childrens
    recurs(res.children);
  }
}

})
}
recurs(arr);
}

if(!name){
    name=item?`- ${item}`:"";
   }
   return convertHTMLEntity(name);
}       

const handleweburl = (e) => 
{
setWebViewUrl(e.target.value.replace("https://", ""));
};

const checkLocationSegment = () => {

    let isLocationSegmentAvailable = false;
    if(includesegmentlist && includesegmentlist.length > 0){
        includesegmentlist.map((segment)=>{
            if(segment.rules_json && JSON.parse(segment.rules_json)[0].field === "location"){
                isLocationSegmentAvailable = true;
            }
        })
    }else{
        isLocationSegmentAvailable = false;
    }

    return isLocationSegmentAvailable;
}
    
    return (
      <>
        <div className="d-md-block">
          <Header customclass={"header-responsive-new"} />
        </div>
        {/* Mobile View */}
        {/* Mobile View End*/}
        <section className="dasboard_page newmobile-display">
          <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />

          <div
            className={`right-panel-gap ${
              localStorage.getItem("appSideBarCollapse") !== undefined &&
              localStorage.getItem("appSideBarCollapse") !== null &&
              localStorage.getItem("appSideBarCollapse") === "true"
                ? " right-panel-gap-expand"
                : ""
            }`}
          >
            <div className="align-items-center justify-content-between newtop-portion">
              <div className="newapp_iconname ">
                <h3 className="">New Notification</h3>
              </div>

              <div
                className="d-flex align-items-center justify-content-between notify-buttons"
                style={{ marginTop: "-5px" }}
              >
                {appPlan !== "premium" && skipButtonActive && <PremiumTag/> }

                {/*<Link to="/app/pushnotification" className="back_btn mr-2 d-none d-md-block" > Back </Link>*/}
                {/* <Link to="/app/advanced-notifications"> */}
                {settingstatus && (
                  <div
                    className="notification-setting "
                    style={{ marginLeft: "20px" }}
                    onClick={handleSettingHome}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      viewBox="0 0 26 26"
                    >
                      <g
                        id="Group_42975"
                        data-name="Group 42975"
                        transform="translate(-20 -527)"
                      >
                        <rect
                          id="Rectangle_25572"
                          data-name="Rectangle 25572"
                          width="26"
                          height="26"
                          transform="translate(20 527)"
                          fill="none"
                        />
                        <path
                          id="Path_119115"
                          data-name="Path 119115"
                          d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z"
                          transform="translate(22.5 529.36)"
                          fill="none"
                          stroke="#7782a1"
                          stroke-miterlimit="10"
                          stroke-width="1.5"
                        />
                      </g>
                    </svg>
                  </div>
                )}
                {/* </Link> */}
                {/*
                        { settingstatus === true || settinghome == false ?
                        <div className="notification-setting " style={{marginRight: "20px"}} onClick={ handleSettingHome}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>
                            </div>
                    :
                        <div className="consent-setting-clicked" style={{marginRight: "20px"}} >
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>
                            </div>
                    }
                    */}

              
                <div onClick={handleSettingStatus} className="richNotification-setting-btn">
                  <SettingIcon isActive={settingstatus} />
                </div>

                <div className=" webnewdraft_fotter">
                  {title.trim() !== "" || message.trim() !== "" ? (
                    <button
                      className="pushnotification-savedraft"
                      onClick={handleSaveDraft}
                      style={{
                        border:
                          "1px solid " +
                          localStorage.getItem("button_background_color"),
                        color: localStorage.getItem("button_text_color"),
                        "--custom_color4":
                          localStorage.getItem("agencyid") == 1
                            ? "white"
                            : localStorage.getItem("button_hover_txtcolor"),
                        "--custom_color6":
                          localStorage.getItem("agencyid") == 1
                            ? "#3064f9"
                            : localStorage.getItem("button_hover_bgcolor"),
                        "--custom_color5":
                          localStorage.getItem("agencyid") == 1
                            ? "#134bec"
                            : "",
                      }}
                    >
                      {draftspinner === true ? (
                        <div style={{ width: "100%" }} className={draftloader}>
                          {/*<Spinner style={{ width: "20px", height: "20px" }} />*/}
                          <div className="new-blue-loader"></div>
                        </div>
                      ) : (
                        <div className=""> {draftsubmitval} </div>
                      )}
                    </button>
                  ) : (
                    <button
                      className="savedraft-buttondisabled"
                      style={{
                        border: "",
                        color: "",
                        "--custom_color4":
                          localStorage.getItem("agencyid") == 1 ? "white" : "",
                        "--custom_color6":
                          localStorage.getItem("agencyid") == 1
                            ? "#3064f9"
                            : "",
                      }}
                    >
                      <div className=""> Save draft </div>
                    </button>
                  )}

                  {title.trim() !== "" && message.trim() !== "" ? (
                    <button
                      className="send-notificationsend"
                      onClick={handleReviewPopup}
                      style={{
                        backgroundColor: localStorage.getItem(
                          "button_background_color"
                        ),
                        border:
                          "1px solid " +
                          localStorage.getItem("button_background_color"),
                        color: localStorage.getItem("button_text_color"),
                        "--custom_color5":
                          localStorage.getItem("agencyid") == 1
                            ? "#134bec"
                            : localStorage.getItem("button_hover_bgcolor"),
                        "--custom_color4":
                          localStorage.getItem("agencyid") == 1
                            ? "white"
                            : localStorage.getItem("button_hover_txtcolor"),
                        "--custom_color6":
                          localStorage.getItem("agencyid") == 1
                            ? "#3064f9"
                            : localStorage.getItem("button_hover_bgcolor"),
                      }}
                    >
                      <div className={isLoader}>
                        <div className="new-loader"></div>
                      </div>

                      {submitval}
                    </button>
                  ) : (
                    <button className="send-buttondisabled">Send</button>
                  )}
                </div>
              </div>
            </div>

            <div className="pushnotification-right_panel mob-notification-create-back">
              {spinner ? (
                <div>
                  <div className="newspinner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                      width="200px"
                      height="200px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g
                        transform="translate(20 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#e6261f"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.375s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(40 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#f7d038"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(60 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#49da9a"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(80 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#4355db"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                </div>
              ) : (
                <>
                  <div className="right_general">
                    <div className="createpushnotification">
                      <div className="createpushnotificationleft">
                        {!settingstatus && (
                          <div className="">
                            <div className=" d-flex align-items-center justify-content-between flex-wrap">
                              <div className="pushnotification-title">
                                {/* Tool tip */}
                                <div
                                  className="d-flex align-items-center justify-content-between pushnotification-titlefirstdiv"
                                  onClick={(e) => handleRowOpen(e, 0)}
                                >
                                  {/*<Tooltip firsttag={"h4"} tagclass={"mb-0 mr-2"} classdata={ "d-flex justify-content-start align-items-center mb-1" } heading={"Notification Title"} content={ " Create a title or header for the push notification." } />*/}
                                  <p>Title</p>

                                  {openrownum === 0 ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="13.061"
                                      height="7.592"
                                      viewBox="0 0 13.061 7.592"
                                    >
                                      <path
                                        id="Path_81302"
                                        data-name="Path 81302"
                                        d="M-12935-18908l6-6,6,6"
                                        transform="translate(12935.531 18915.064)"
                                        fill="none"
                                        stroke="#7782a1"
                                        stroke-width="1.5"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="13.061"
                                      height="7.592"
                                      viewBox="0 0 13.061 7.592"
                                    >
                                      <path
                                        id="Path_81302"
                                        data-name="Path 81302"
                                        d="M-12935-18914l6,6,6-6"
                                        transform="translate(12935.531 18914.535)"
                                        fill="none"
                                        stroke="#7782a1"
                                        stroke-width="1.5"
                                      />
                                    </svg>
                                  )}

                                  {/*<span className="d-block mb-1" style={{ color: "#C8CEDB", fontSize: "12px" }} >
                                                            Maximum 200 word
                                                        </span>*/}
                                </div>
                                {/* Tool tip */}
                                {openrownum === 0 && (
                                  <div className="form-group">
                                    <input
                                      type="text"
                                      placeholder="Enter a title here"
                                      className="form-control form-name"
                                      maxLength="65"
                                      value={title}
                                      onChange={handleTitle}
                                      required
                                    />
                                    <span>{65 - title.length} words</span>
                                  </div>
                                )}
                              </div>

                              <div className="pushnotification-title">
                                <div
                                  className="d-flex align-items-center justify-content-between pushnotification-titlefirstdiv"
                                  onClick={(e) => handleRowOpen(e, 1)}
                                >
                                  {/*<Tooltip firsttag={"h4"} tagclass={"mb-0 mr-2"} classdata={ "d-flex justify-content-start  align-items-center mb-1" } heading={"Message"} content={ "Add a detailed message for your app users. " } />*/}

                                  {/*<span className="d-block mb-1" style={{ color: "#C8CEDB", fontSize: "12px" }} >
                                                            Maximum 1024 word
                                                        </span>*/}
                                  <p>Message</p>

                                  {openrownum === 1 ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="13.061"
                                      height="7.592"
                                      viewBox="0 0 13.061 7.592"
                                    >
                                      <path
                                        id="Path_81302"
                                        data-name="Path 81302"
                                        d="M-12935-18908l6-6,6,6"
                                        transform="translate(12935.531 18915.064)"
                                        fill="none"
                                        stroke="#7782a1"
                                        stroke-width="1.5"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="13.061"
                                      height="7.592"
                                      viewBox="0 0 13.061 7.592"
                                    >
                                      <path
                                        id="Path_81302"
                                        data-name="Path 81302"
                                        d="M-12935-18914l6,6,6-6"
                                        transform="translate(12935.531 18914.535)"
                                        fill="none"
                                        stroke="#7782a1"
                                        stroke-width="1.5"
                                      />
                                    </svg>
                                  )}
                                </div>

                                {openrownum === 1 && (
                                  <div className="form-group">
                                    <textarea
                                      placeholder="Compose your message here "
                                      className="form-control form-name"
                                      rows="5"
                                      maxLength="178"
                                      value={message}
                                      onChange={handleMessage}
                                    ></textarea>
                                    <span className="textarea-count">
                                      {178 - message.length} words
                                    </span>
                                  </div>
                                )}
                              </div>

                              <div className="pushnotification-title">
                                <div
                                  className="d-flex align-items-center justify-content-between pushnotification-titlefirstdiv"
                                  onClick={(e) => handleRowOpen(e, 2)}
                                >
                                  <p>Image</p>

                                  {openrownum === 2 ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="13.061"
                                      height="7.592"
                                      viewBox="0 0 13.061 7.592"
                                    >
                                      <path
                                        id="Path_81302"
                                        data-name="Path 81302"
                                        d="M-12935-18908l6-6,6,6"
                                        transform="translate(12935.531 18915.064)"
                                        fill="none"
                                        stroke="#7782a1"
                                        stroke-width="1.5"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="13.061"
                                      height="7.592"
                                      viewBox="0 0 13.061 7.592"
                                    >
                                      <path
                                        id="Path_81302"
                                        data-name="Path 81302"
                                        d="M-12935-18914l6,6,6-6"
                                        transform="translate(12935.531 18914.535)"
                                        fill="none"
                                        stroke="#7782a1"
                                        stroke-width="1.5"
                                      />
                                    </svg>
                                  )}
                                </div>

                                {openrownum === 2 && (
                                  <div className="form-group">
                                    <div className="popupimage-uploader">
                                      <div
                                        className="d-flex align-items-center"
                                        onClick={handleNotificationImagePopup}
                                      >
                                        {notificationimage != "" &&
                                        notificationimage !== undefined &&
                                        notificationimage.length > 0 &&
                                        notificationimage !== null ? (
                                          notificationimage.indexOf("https") ===
                                          -1 ? (
                                            <div
                                              className="uploadpixabayimage abc1"
                                              style={{
                                                backgroundImage:
                                                  "url(" +
                                                  notificationimgpath +
                                                  notificationimage +
                                                  ")",
                                              }}
                                            >
                                              <NewImageGalleryPopup
                                                isGalleryPopup={isGalleryPopup}
                                                setIsGalleryPopup={
                                                  setIsGalleryPopup
                                                }
                                                settingYourdata={
                                                  handleSetImageData
                                                }
                                                yourErrFun={
                                                  handleImageErrorData
                                                }
                                                uploadPath={
                                                  localStorage.getItem(
                                                    "user_id"
                                                  ) +
                                                  "/" +
                                                  localStorage.getItem(
                                                    "Appid"
                                                  ) +
                                                  process.env
                                                    .REACT_APP_Notification_Media
                                                }
                                                settingOrgImgFrom3rdParty={
                                                  false
                                                }
                                                hideImageGallery={true}
                                                widthForRestriction={512}
                                                heightForRestriction={256}
                                                aspectRatio="2:1"
                                                isNotCustomUploadOrgImg={true}
                                              />
                                            </div>
                                          ) : (
                                            <div
                                              className="uploadpixabayimage abc1"
                                              style={{
                                                backgroundImage:
                                                  "url(" +
                                                  notificationimage +
                                                  ")",
                                              }}
                                            >
                                              <NewImageGalleryPopup
                                                isGalleryPopup={isGalleryPopup}
                                                setIsGalleryPopup={
                                                  setIsGalleryPopup
                                                }
                                                settingYourdata={
                                                  handleSetImageData
                                                }
                                                yourErrFun={
                                                  handleImageErrorData
                                                }
                                                uploadPath={
                                                  localStorage.getItem(
                                                    "user_id"
                                                  ) +
                                                  "/" +
                                                  localStorage.getItem(
                                                    "Appid"
                                                  ) +
                                                  process.env
                                                    .REACT_APP_Notification_Media
                                                }
                                                settingOrgImgFrom3rdParty={
                                                  false
                                                }
                                                hideImageGallery={true}
                                                widthForRestriction={512}
                                                heightForRestriction={256}
                                                aspectRatio="2:1"
                                                isNotCustomUploadOrgImg={true}
                                              />
                                            </div>
                                          )
                                        ) : (
                                          <div
                                            className="uploadpixabayimage abc2"
                                            style={{
                                              backgroundImage:
                                                "url(" + imagebackground + ")",
                                            }}
                                          >
                                            <NewImageGalleryPopup
                                              isGalleryPopup={isGalleryPopup}
                                              setIsGalleryPopup={
                                                setIsGalleryPopup
                                              }
                                              settingYourdata={
                                                handleSetImageData
                                              }
                                              yourErrFun={handleImageErrorData}
                                              uploadPath={
                                                localStorage.getItem(
                                                  "user_id"
                                                ) +
                                                "/" +
                                                localStorage.getItem("Appid") +
                                                process.env
                                                  .REACT_APP_Notification_Media
                                              }
                                              settingOrgImgFrom3rdParty={false}
                                              hideImageGallery={true}
                                              widthForRestriction={512}
                                              heightForRestriction={256}
                                              aspectRatio="2:1"
                                              isNotCustomUploadOrgImg={true}
                                            />
                                          </div>
                                        )}

                                        {notificationimage &&
                                        notificationimage !== undefined &&
                                        notificationimage.length > 0 &&
                                        notificationimage !== null ? (
                                          <p style={{ marginBottom: "0px" }}>
                                            {notificationimgname}{" "}
                                          </p>
                                        ) : (
                                          <p
                                            style={{
                                              marginBottom: "0px",
                                              color: "#BCC1CE",
                                            }}
                                          >
                                            {" "}
                                            Upload
                                          </p>
                                        )}
                                      </div>
                                      {notificationimage &&
                                        notificationimage != "" &&
                                        notificationimage !== undefined &&
                                        notificationimage.length > 0 &&
                                        notificationimage !== null && (
                                          <svg
                                            style={{
                                              position: "absolute",
                                              right: "15px",
                                            }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="11.06"
                                            height="11.06"
                                            viewBox="0 0 11.06 11.06"
                                            onClick={
                                              handleDeleteNotificationImage
                                            }
                                          >
                                            <path
                                              id="Union_1696"
                                              data-name="Union 1696"
                                              d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z"
                                              transform="translate(0.53 0.53)"
                                              fill="none"
                                              stroke="#7782a1"
                                              stroke-width="1.5"
                                            />
                                          </svg>
                                        )}
                                      {/*<svg width="11.06" height="11.06" viewBox="0 0 11.06 11.06" onClick={handleNotificationImagePopup}>
                                                                <path id="Union_1696" data-name="Union 1696" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#7782a1" stroke-width="1.5" />
                                                            </svg>*/}
                                    </div>
                                  </div>
                                )}
                              </div>

                              <div className="pushnotification-title">
                                <div
                                  className="d-flex align-items-center justify-content-between pushnotification-titlefirstdiv"
                                  onClick={(e) => handleRowOpen(e, 3)}
                                >
                                  <p>Link</p>

                                  {openrownum === 3 ? (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="13.061"
                                      height="7.592"
                                      viewBox="0 0 13.061 7.592"
                                    >
                                      <path
                                        id="Path_81302"
                                        data-name="Path 81302"
                                        d="M-12935-18908l6-6,6,6"
                                        transform="translate(12935.531 18915.064)"
                                        fill="none"
                                        stroke="#7782a1"
                                        stroke-width="1.5"
                                      />
                                    </svg>
                                  ) : (
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="13.061"
                                      height="7.592"
                                      viewBox="0 0 13.061 7.592"
                                    >
                                      <path
                                        id="Path_81302"
                                        data-name="Path 81302"
                                        d="M-12935-18914l6,6,6-6"
                                        transform="translate(12935.531 18914.535)"
                                        fill="none"
                                        stroke="#7782a1"
                                        stroke-width="1.5"
                                      />
                                    </svg>
                                  )}
                                </div>

                                {openrownum === 3 && (
                                  <div className="link-form-group">
                                    <div
                                      className="d-flex align-items-center popuplink-uploader"
                                      onClick={() =>
                                        spinnerForsubDrop === false
                                          ? handleOpenSelectionPopUp("open")
                                          : null
                                      }
                                    >
                                      {/* <div className="link-popuphttps" >https://</div>
                                                                <input className="link-popupinput" value={linkurl} onChange={handleLinkUrlChange}></input> */}
                                      {spinnerForsubDrop === true ? (
                                        <img
                                          className="search-dropdown-before"
                                          src={
                                            require("../../assets/images/signup/menuLoader_GIF.gif")
                                              .default
                                          }
                                          style={{
                                            width: "20px",
                                            height: "20px",
                                          }}
                                          alt="loader"
                                        />
                                      ) : (
                                        <p
                                          style={{
                                            fontSize: "13px",
                                            color: "#1B2952",
                                            fontFamily: "Axiforma-Regular",
                                            marginBottom: "0px",
                                          }}
                                        >
                                          {returnSelectedModuleName()}{" "}
                                          {(itemType === "post_type" ||
                                            itemType === "taxonomy" ||
                                            itemType === "search" ||
                                            // cmsRow.isCms===1
                                            (parseInt(
                                              localStorage.getItem(
                                                "website_technology"
                                              )
                                            ) === 3 &&
                                              itemType &&
                                              itemType !== "settings" &&
                                              itemType !== "web_view" &&
                                              itemType !== "main_menu" &&
                                              itemType !== "chat")) &&
                                            returnSelectedItem(
                                              invidualSelectedItemId,
                                              subdropdownData
                                            )}
                                        </p>
                                      )}

                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="19"
                                        viewBox="0 0 20 19"
                                      >
                                        <g
                                          id="Group_42666"
                                          data-name="Group 42666"
                                          transform="translate(-1005 -1029)"
                                        >
                                          <g
                                            id="Rectangle_31915"
                                            data-name="Rectangle 31915"
                                            transform="translate(1005 1029)"
                                            fill="#fff"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          >
                                            <rect
                                              width="20"
                                              height="19"
                                              rx="2"
                                              stroke="none"
                                            />
                                            <rect
                                              x="0.5"
                                              y="0.5"
                                              width="19"
                                              height="18"
                                              rx="1.5"
                                              fill="none"
                                            />
                                          </g>
                                          <path
                                            id="Path_119031"
                                            data-name="Path 119031"
                                            d="M15578.713-3593.554h2"
                                            transform="translate(-14569.713 4628.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119032"
                                            data-name="Path 119032"
                                            d="M15578.713-3593.554h2"
                                            transform="translate(-14569.713 4632.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119033"
                                            data-name="Path 119033"
                                            d="M15578.713-3593.554h2"
                                            transform="translate(-14569.713 4636.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119034"
                                            data-name="Path 119034"
                                            d="M15578.711-3593.554h8"
                                            transform="translate(-14565.713 4628.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119035"
                                            data-name="Path 119035"
                                            d="M15578.711-3593.554h8"
                                            transform="translate(-14565.713 4632.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                          <path
                                            id="Path_119036"
                                            data-name="Path 119036"
                                            d="M15578.711-3593.554h8"
                                            transform="translate(-14565.713 4636.054)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-width="1"
                                          />
                                        </g>
                                      </svg>
                                    </div>
                                    {itemType === "web_view" && (
                                      <>
                                        <div
                                          className="d-flex align-items-center popuplink-uploader"
                                          style={{ marginTop: "20px" }}
                                          onClick={toggle}
                                        >
                                          <div className="link-popuphttps">
                                            https://
                                          </div>
                                          <input
                                            className="link-popupinput"
                                            name="hello"
                                            id="hello"
                                            onChange={(e) => handleweburl(e)}
                                            value={
                                              webViewUrl && webViewUrl != ""
                                                ? webViewUrl.replace(
                                                    /^https?:\/\//i,
                                                    ""
                                                  )
                                                : ""
                                            }
                                          />
                                        </div>
                                      </>
                                    )}
                                    {itemType ? (
                                      <svg
                                        onClick={(e) => handleClearLink(e)}
                                        style={{
                                          position: "absolute",
                                          right: "50px",
                                          top: "20px",
                                          cursor: "pointer",
                                        }}
                                        width="10"
                                        height="10"
                                        viewBox="0 0 12 12"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M6 6L1 11M6 6L1 1M6 6L11 1M6 6L11 11"
                                          stroke="#A1A1A1"
                                          stroke-width="1.5"
                                        />
                                      </svg>
                                    ) : null}
                                    ​
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}

                        {settingstatus && (
                          <div className="setting-notificationpreview">
                            <div className="notificationsetting-div">
                              <h1 className="notificatiosetting-head">
                                Settings
                              </h1>
                              <p className="notificationsetting-para">
                                Manage your notification preferences.
                              </p>
                            </div>
                            <div>
                              <ul className="notificationsetting-ul">
                                <li className="notificationsetting-li">
                                  <p className="notification-setting-toggle-div">
                                    Send to iOS devices
                                    <p className="bbsetting-tooltip ">
                                      <svg
                                        style={{ cursor: "pointer" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                      >
                                        <g
                                          id="Group_40296"
                                          data-name="Group 40296"
                                          transform="translate(-1727 -19.132)"
                                        >
                                          <g
                                            id="Ellipse_7706"
                                            data-name="Ellipse 7706"
                                            transform="translate(1727 19.132)"
                                            fill="#fff"
                                            stroke="#bcc1ce"
                                            stroke-width="1.2"
                                          >
                                            <circle
                                              cx="7"
                                              cy="7"
                                              r="7"
                                              stroke="none"
                                            ></circle>
                                            <circle
                                              cx="7"
                                              cy="7"
                                              r="6.4"
                                              fill="none"
                                            ></circle>
                                          </g>
                                          <path
                                            id="Path_98061"
                                            data-name="Path 98061"
                                            d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                            transform="translate(1725.126 17.539)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-miterlimit="10"
                                            stroke-width="1.2"
                                          ></path>
                                          <line
                                            id="Line_343"
                                            data-name="Line 343"
                                            y2="1.2"
                                            transform="translate(1734 29.132)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-miterlimit="10"
                                            stroke-width="1.2"
                                          ></line>
                                        </g>
                                      </svg>
                                      <p class="bbsetting-tooltipsubheader">
                                        {" "}
                                        Enable to send notifications to iOS
                                        device users.
                                      </p>
                                    </p>
                                  </p>
                                  <label className=" access-switch access-monitor-slider-padding">
                                    <input
                                      type="checkbox"
                                      className="toplogo"
                                      id="sendappleusers"
                                      name="sendappleusers"
                                      onClick={handleDeviceIos}
                                      checked={chkios == "ios" ? true : false}
                                    />

                                    <div className="access-slider round"></div>
                                  </label>
                                </li>
                                <li className="notificationsetting-li">
                                  <p className="notification-setting-toggle-div">
                                    Send to Android devices
                                    <p className="bbsetting-tooltip ">
                                      <svg
                                        style={{ cursor: "pointer" }}
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                      >
                                        <g
                                          id="Group_40296"
                                          data-name="Group 40296"
                                          transform="translate(-1727 -19.132)"
                                        >
                                          <g
                                            id="Ellipse_7706"
                                            data-name="Ellipse 7706"
                                            transform="translate(1727 19.132)"
                                            fill="#fff"
                                            stroke="#bcc1ce"
                                            stroke-width="1.2"
                                          >
                                            <circle
                                              cx="7"
                                              cy="7"
                                              r="7"
                                              stroke="none"
                                            ></circle>
                                            <circle
                                              cx="7"
                                              cy="7"
                                              r="6.4"
                                              fill="none"
                                            ></circle>
                                          </g>
                                          <path
                                            id="Path_98061"
                                            data-name="Path 98061"
                                            d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                            transform="translate(1725.126 17.539)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-miterlimit="10"
                                            stroke-width="1.2"
                                          ></path>
                                          <line
                                            id="Line_343"
                                            data-name="Line 343"
                                            y2="1.2"
                                            transform="translate(1734 29.132)"
                                            fill="none"
                                            stroke="#bcc1ce"
                                            stroke-miterlimit="10"
                                            stroke-width="1.2"
                                          ></line>
                                        </g>
                                      </svg>
                                      <p class="bbsetting-tooltipsubheader">
                                        {" "}
                                        Enable to send notifications to Android
                                        device users.
                                      </p>
                                    </p>
                                  </p>
                                  <label className=" access-switch access-monitor-slider-padding">
                                    <input
                                      type="checkbox"
                                      className="toplogo"
                                      id="sendandroidusers"
                                      name="sendandroidusers"
                                      onClick={handleDeviceAndroid}
                                      checked={
                                        chkandroid == "android" ? true : false
                                      }
                                    />

                                    <div className="access-slider round"></div>
                                  </label>
                                </li>
                                <li className="notification-li">
                                  <div className="pushnotification-lastsetting">
                                    <div className="publish-immediately">
                                      <p className="notification-setting-toggle-div">
                                        Send immediately
                                        <p className="bbsetting-tooltip ">
                                          <svg
                                            style={{ cursor: "pointer" }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                          >
                                            <g
                                              id="Group_40296"
                                              data-name="Group 40296"
                                              transform="translate(-1727 -19.132)"
                                            >
                                              <g
                                                id="Ellipse_7706"
                                                data-name="Ellipse 7706"
                                                transform="translate(1727 19.132)"
                                                fill="#fff"
                                                stroke="#bcc1ce"
                                                stroke-width="1.2"
                                              >
                                                <circle
                                                  cx="7"
                                                  cy="7"
                                                  r="7"
                                                  stroke="none"
                                                ></circle>
                                                <circle
                                                  cx="7"
                                                  cy="7"
                                                  r="6.4"
                                                  fill="none"
                                                ></circle>
                                              </g>
                                              <path
                                                id="Path_98061"
                                                data-name="Path 98061"
                                                d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                                transform="translate(1725.126 17.539)"
                                                fill="none"
                                                stroke="#bcc1ce"
                                                stroke-miterlimit="10"
                                                stroke-width="1.2"
                                              ></path>
                                              <line
                                                id="Line_343"
                                                data-name="Line 343"
                                                y2="1.2"
                                                transform="translate(1734 29.132)"
                                                fill="none"
                                                stroke="#bcc1ce"
                                                stroke-miterlimit="10"
                                                stroke-width="1.2"
                                              ></line>
                                            </g>
                                          </svg>
                                          <p class="bbsetting-tooltipsubheader">
                                            {" "}
                                            Enable to send notifications
                                            immediately.
                                          </p>
                                        </p>
                                      </p>
                                      <label className=" access-switch access-monitor-slider-padding">
                                        <input
                                          type="checkbox"
                                          className="toplogo"
                                          id="notificationtime"
                                          name="notificationtime"
                                          onChange={handleScheduleNow}
                                          checked={
                                            chkschedulenow ? true : false
                                          }
                                        />
                                        <div className="access-slider round"></div>
                                      </label>
                                    </div>

                                    {chkschedulenow === false && (
                                      <div className="newparentsection-selectdate">
                                        <DatePicker
                                          className="date-input-post2"
                                          selected={dateTimeValue}
                                          onChange={handleDateTimePicker}
                                          filterTime={filterPassedTime}
                                          showTimeSelect
                                          timeFormat="p"
                                          timeIntervals={15}
                                          dateFormat="dd MMM yyyy, hh:mm aa"
                                          minDate={new Date()}
                                          maxDate={addDays(new Date(), 30)}
                                        />{" "}
                                        {/* <img src={ require("../../assets/images/dashboard/cms/calendar.png").default } /> */}
                                      </div>
                                    )}
                                  </div>
                                </li>

                                <li className="notification-li sendallmargin-top">
                                  <div className="pushnotification-lastsetSend to iOS usersting">
                                    <div className="publish-immediately">
                                      <p className="notification-setting-toggle-div">
                                        Send to all users
                                        <p className="bbsetting-tooltip ">
                                          <svg
                                            style={{ cursor: "pointer" }}
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                          >
                                            <g
                                              id="Group_40296"
                                              data-name="Group 40296"
                                              transform="translate(-1727 -19.132)"
                                            >
                                              <g
                                                id="Ellipse_7706"
                                                data-name="Ellipse 7706"
                                                transform="translate(1727 19.132)"
                                                fill="#fff"
                                                stroke="#bcc1ce"
                                                stroke-width="1.2"
                                              >
                                                <circle
                                                  cx="7"
                                                  cy="7"
                                                  r="7"
                                                  stroke="none"
                                                ></circle>
                                                <circle
                                                  cx="7"
                                                  cy="7"
                                                  r="6.4"
                                                  fill="none"
                                                ></circle>
                                              </g>
                                              <path
                                                id="Path_98061"
                                                data-name="Path 98061"
                                                d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                                transform="translate(1725.126 17.539)"
                                                fill="none"
                                                stroke="#bcc1ce"
                                                stroke-miterlimit="10"
                                                stroke-width="1.2"
                                              ></path>
                                              <line
                                                id="Line_343"
                                                data-name="Line 343"
                                                y2="1.2"
                                                transform="translate(1734 29.132)"
                                                fill="none"
                                                stroke="#bcc1ce"
                                                stroke-miterlimit="10"
                                                stroke-width="1.2"
                                              ></line>
                                            </g>
                                          </svg>
                                          <p class="bbsetting-tooltipsubheader">
                                            Enable to send notifications to all
                                            your notification subscribers.
                                          </p>
                                        </p>
                                      </p>
                                      <label className=" access-switch access-monitor-slider-padding">
                                        <input
                                          type="checkbox"
                                          className="toplogo"
                                          id="sendtoallusers"
                                          name="sendtoallusers"
                                          onChange={handleSendAllUser}
                                          checked={
                                            chksendallusers ? true : false
                                          }
                                        />
                                        <div className="access-slider round"></div>
                                      </label>
                                    </div>
                                    {/* <input type="datetime-local"/> */}

                                    {chksendallusers == false && (
                                      <>
                                        <div className="notificationsend-all">
                                          <label>
                                            Included groups
                                            <p className="bbsetting-tooltip ">
                                              <svg
                                                style={{ cursor: "pointer" }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                              >
                                                <g
                                                  id="Group_40296"
                                                  data-name="Group 40296"
                                                  transform="translate(-1727 -19.132)"
                                                >
                                                  <g
                                                    id="Ellipse_7706"
                                                    data-name="Ellipse 7706"
                                                    transform="translate(1727 19.132)"
                                                    fill="#fff"
                                                    stroke="#bcc1ce"
                                                    stroke-width="1.2"
                                                  >
                                                    <circle
                                                      cx="7"
                                                      cy="7"
                                                      r="7"
                                                      stroke="none"
                                                    ></circle>
                                                    <circle
                                                      cx="7"
                                                      cy="7"
                                                      r="6.4"
                                                      fill="none"
                                                    ></circle>
                                                  </g>
                                                  <path
                                                    id="Path_98061"
                                                    data-name="Path 98061"
                                                    d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                                    transform="translate(1725.126 17.539)"
                                                    fill="none"
                                                    stroke="#bcc1ce"
                                                    stroke-miterlimit="10"
                                                    stroke-width="1.2"
                                                  ></path>
                                                  <line
                                                    id="Line_343"
                                                    data-name="Line 343"
                                                    y2="1.2"
                                                    transform="translate(1734 29.132)"
                                                    fill="none"
                                                    stroke="#bcc1ce"
                                                    stroke-miterlimit="10"
                                                    stroke-width="1.2"
                                                  ></line>
                                                </g>
                                              </svg>
                                              <p class="bbsetting-tooltipsubheader">
                                                Select groups segments you’ve
                                                created to specify who will
                                                receive this notification. Users
                                                in multiple segments will
                                                receive the notification once.
                                              </p>
                                            </p>
                                          </label>
                                          <div className="categogy-chip-section connect-categogy-chip-section">
                                            <Dropdown
                                              isOpen={
                                                window.matchMedia(
                                                  "(max-width: 1200px)"
                                                ).matches
                                                  ? false
                                                  : isincsegmentdropdown
                                              }
                                              onClick={
                                                window.matchMedia(
                                                  "(max-width: 1200px)"
                                                ).matches
                                                  ? handleMobileTagDropdown
                                                  : null
                                              }
                                              toggle={handleTagDropdown}
                                            >
                                              <DropdownToggle caret>
                                                <span className="search-dropdown-before">
                                                  {includesegmentlist.length ===
                                                  0 ? (
                                                    "Select"
                                                  ) : (
                                                    <div
                                                      onClick={(e) =>
                                                        handleTagDropdown(e)
                                                      }
                                                    >
                                                      {segmentlist &&
                                                        segmentlist
                                                          .filter(
                                                            (row) =>
                                                              includesegmentarr.includes(
                                                                row.segment_name
                                                              ) &&
                                                              !excludesegmentarr.includes(
                                                                row.segment_name
                                                              )
                                                          )
                                                          .map((res, indx) => {
                                                            return (
                                                              <div>
                                                                <p>
                                                                  {res.segment_name !==
                                                                    null &&
                                                                  res.segment_name !==
                                                                    "" &&
                                                                  res.segment_name !==
                                                                    undefined
                                                                    ? res.segment_name
                                                                    : res}{" "}
                                                                  <span
                                                                    onClick={(
                                                                      e
                                                                    ) =>
                                                                      handleDelTag(
                                                                        e,
                                                                        indx,
                                                                        res
                                                                      )
                                                                    }
                                                                  >
                                                                    <img
                                                                      className="img-fluid"
                                                                      src={
                                                                        require("../../assets/images/dashboard/remove-tag.png")
                                                                          .default
                                                                      }
                                                                      alt="Remove"
                                                                    />
                                                                  </span>
                                                                </p>
                                                              </div>
                                                            );
                                                          })}
                                                    </div>
                                                  )}
                                                </span>
                                              </DropdownToggle>

                                              <DropdownMenu id="test-height-tag">
                                                {segmentlist.length === 0 && (
                                                  <div className="no-app-dropdown">
                                                    <img
                                                      className="img-fluid"
                                                      src={
                                                        require("../../assets/images/dashboard/no-tag-available.png")
                                                          .default
                                                      }
                                                      alt="No App Found"
                                                    />
                                                  </div>
                                                )}

                                                {segmentlist.length > 0 && (
                                                  <FormGroup>
                                                    <div className="dropdown-search">
                                                      <input
                                                        placeholder="Search"
                                                        type="text"
                                                        value={searchincsegment}
                                                        onChange={(e) => {
                                                          setSearchIncSegment(
                                                            e.target.value
                                                          );
                                                        }}
                                                      ></input>

                                                      {searchincsegment.trim() && (
                                                        <p
                                                          onClick={(e) => {
                                                            setSearchIncSegment(
                                                              ""
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          Clear{" "}
                                                        </p>
                                                      )}
                                                    </div>
                                                  </FormGroup>
                                                )}

                                                {segmentlist &&
                                                  segmentlist
                                                    .filter(
                                                      (row) =>
                                                        row.segment_name
                                                          .toLowerCase()
                                                          .includes(
                                                            searchincsegment.toLowerCase()
                                                          ) &&
                                                        !excludesegmentarr.includes(
                                                          row.segment_name
                                                        )
                                                    )
                                                    .map((res, indx) => {
                                                      return (
                                                        <div
                                                          className="category-created"
                                                          key={indx}
                                                        >
                                                          <label
                                                            className="category-list-container notification-category-list-container"
                                                            for={`tag${indx}`}
                                                          >
                                                            <input
                                                              type="checkbox"
                                                              id={`tag${indx}`}
                                                              name={`tag${indx}`}
                                                              onChange={(e) =>
                                                                handleAddTagDrop(
                                                                  e,
                                                                  res,
                                                                  indx
                                                                )
                                                              }
                                                              checked={includesegmentarr.includes(
                                                                res.segment_name
                                                              )}
                                                            />
                                                            <span className="category-checkmark"></span>

                                                            {res.segment_name}
                                                          </label>
                                                        </div>
                                                      );
                                                    })}
                                              </DropdownMenu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                        <div className="notificationsend-all">
                                          <label>
                                            Excluded groups
                                            <p className="bbsetting-tooltip ">
                                              <svg
                                                style={{ cursor: "pointer" }}
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="14"
                                                height="14"
                                                viewBox="0 0 14 14"
                                              >
                                                <g
                                                  id="Group_40296"
                                                  data-name="Group 40296"
                                                  transform="translate(-1727 -19.132)"
                                                >
                                                  <g
                                                    id="Ellipse_7706"
                                                    data-name="Ellipse 7706"
                                                    transform="translate(1727 19.132)"
                                                    fill="#fff"
                                                    stroke="#bcc1ce"
                                                    stroke-width="1.2"
                                                  >
                                                    <circle
                                                      cx="7"
                                                      cy="7"
                                                      r="7"
                                                      stroke="none"
                                                    ></circle>
                                                    <circle
                                                      cx="7"
                                                      cy="7"
                                                      r="6.4"
                                                      fill="none"
                                                    ></circle>
                                                  </g>
                                                  <path
                                                    id="Path_98061"
                                                    data-name="Path 98061"
                                                    d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                                    transform="translate(1725.126 17.539)"
                                                    fill="none"
                                                    stroke="#bcc1ce"
                                                    stroke-miterlimit="10"
                                                    stroke-width="1.2"
                                                  ></path>
                                                  <line
                                                    id="Line_343"
                                                    data-name="Line 343"
                                                    y2="1.2"
                                                    transform="translate(1734 29.132)"
                                                    fill="none"
                                                    stroke="#bcc1ce"
                                                    stroke-miterlimit="10"
                                                    stroke-width="1.2"
                                                  ></line>
                                                </g>
                                              </svg>
                                              <p class="bbsetting-tooltipsubheader">
                                                Select from the group
                                                segments you’ve created to
                                                specify who must not receive
                                                this notification.
                                              </p>
                                            </p>
                                          </label>
                                          <div className="categogy-chip-section connect-categogy-chip-section">
                                            <Dropdown
                                              isOpen={
                                                window.matchMedia(
                                                  "(max-width: 1200px)"
                                                ).matches
                                                  ? false
                                                  : isexcsegmentdropdown
                                              }
                                              onClick={
                                                window.matchMedia(
                                                  "(max-width: 1200px)"
                                                ).matches
                                                  ? handleMobileTagDropdown1
                                                  : null
                                              }
                                              toggle={handleTagDropdown1}
                                            >
                                              <DropdownToggle caret>
                                                <span className="search-dropdown-before">
                                                  {excludesegmentlist.length ===
                                                  0 ? (
                                                    "Select"
                                                  ) : (
                                                    <div
                                                      onClick={(e) =>
                                                        handleTagDropdown1(e)
                                                      }
                                                    >
                                                      {segmentlist &&
                                                        segmentlist
                                                          .filter(
                                                            (row) =>
                                                              excludesegmentarr.includes(
                                                                row.segment_name
                                                              ) &&
                                                              !includesegmentarr.includes(
                                                                row.segment_name
                                                              )
                                                          )
                                                          .map((res, indx) => {
                                                            return (
                                                              <div>
                                                                <p>
                                                                  {
                                                                    res.segment_name
                                                                  }{" "}
                                                                  <span
                                                                    onClick={(
                                                                      e
                                                                    ) =>
                                                                      handleDelTag1(
                                                                        e,
                                                                        indx,
                                                                        res
                                                                      )
                                                                    }
                                                                  >
                                                                    <img
                                                                      className="img-fluid"
                                                                      src={
                                                                        require("../../assets/images/dashboard/remove-tag.png")
                                                                          .default
                                                                      }
                                                                      alt="Remove"
                                                                    />
                                                                  </span>
                                                                </p>
                                                              </div>
                                                            );
                                                          })}
                                                    </div>
                                                  )}
                                                </span>
                                              </DropdownToggle>

                                              <DropdownMenu id="test-height-tag1">
                                                {segmentlist.length === 0 && (
                                                  <div className="no-app-dropdown">
                                                    <img
                                                      className="img-fluid"
                                                      src={
                                                        require("../../assets/images/dashboard/no-tag-available.png")
                                                          .default
                                                      }
                                                      alt="No App Found"
                                                    />
                                                  </div>
                                                )}

                                                {segmentlist.length > 0 && (
                                                  <FormGroup>
                                                    <div className="dropdown-search">
                                                      <input
                                                        placeholder="Search"
                                                        type="text"
                                                        value={searchexcsegment}
                                                        onChange={(e) => {
                                                          setSearchExcSegment(
                                                            e.target.value
                                                          );
                                                        }}
                                                      ></input>

                                                      {searchexcsegment.trim() && (
                                                        <p
                                                          onClick={(e) => {
                                                            setSearchExcSegment(
                                                              ""
                                                            );
                                                          }}
                                                        >
                                                          {" "}
                                                          Clear{" "}
                                                        </p>
                                                      )}
                                                    </div>
                                                  </FormGroup>
                                                )}

                                                {segmentlist &&
                                                  segmentlist
                                                    .filter(
                                                      (row1) =>
                                                        row1.segment_name
                                                          .toLowerCase()
                                                          .includes(
                                                            searchexcsegment.toLowerCase()
                                                          ) &&
                                                        !includesegmentarr.includes(
                                                          row1.segment_name
                                                        )
                                                    )
                                                    .map((res1, indx1) => {
                                                      return (
                                                        <div
                                                          className="category-created"
                                                          key={indx1}
                                                        >
                                                          <label
                                                            className="category-list-container notification-category-list-container"
                                                            for={`tag1${indx1}`}
                                                          >
                                                            <input
                                                              type="checkbox"
                                                              id={`tag1${indx1}`}
                                                              name={`tag1${indx1}`}
                                                              onChange={(e) =>
                                                                handleAddTagDrop1(
                                                                  e,
                                                                  res1,
                                                                  indx1
                                                                )
                                                              }
                                                              checked={excludesegmentarr.includes(
                                                                res1.segment_name
                                                              )}
                                                            />
                                                            <span className="category-checkmark"></span>

                                                            {res1.segment_name}
                                                          </label>
                                                        </div>
                                                      );
                                                    })}
                                              </DropdownMenu>
                                            </Dropdown>
                                          </div>
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="createpushnotificationright">
                        <div className=" two-btn-tabs newtabwrapper">
                          <div className="text-center">
                            <div className="tab-content push-notify">
                              <div
                                role="tabpanel"
                                className="tab-pane fade in active show newmobile_bg"
                                id="ios"
                              >
                                <div
                                  className={
                                    ios ? " notimobimg" : " notimobimg"
                                  }
                                >
                                  {ios ? (
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/newapplemobilepreview.png")
                                          .default
                                      }
                                      className="img-fluid"
                                      alt="speaker"
                                    />
                                  ) : (
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/newandroidmobilepreview.png")
                                          .default
                                      }
                                      className="img-fluid"
                                      alt="speaker"
                                    />
                                  )}
                                </div>

                                <div
                                  className="push-newnotifications push-newnotificationsandroid"
                                  style={{ top: android ? "128px" : "232px" }}
                                >
                                  <div className="d-flex align-items-center justify-content-between">
                                    {ios ? (
                                      <>
                                        <div className="d-flex align-items-center justify-content-start">
                                          {appicon ? (
                                            <img
                                              className="rounded"
                                              src={appicon}
                                              width="28px"
                                              height="28px"
                                              alt="Appicon"
                                            />
                                          ) : (
                                            <img
                                              className="rounded"
                                              src={
                                                require("../../assets/images/dashboard/appicon.png")
                                                  .default
                                              }
                                              width="28px"
                                              height="28px"
                                              alt="Appicon"
                                            />
                                          )}
                                        </div>

                                        <div className="iosnotify-time">
                                          <div className="newnotification-title">
                                            {title !== "" &&
                                            title !== null &&
                                            title !== undefined ? (
                                              <h2>{title}</h2>
                                            ) : (
                                              <h2>Enter a title here</h2>
                                            )}

                                            {message !== "" &&
                                            message !== null &&
                                            message !== undefined ? (
                                              <p>{message}</p>
                                            ) : (
                                              <p>Compose your message here</p>
                                            )}
                                          </div>

                                          <div className="d-grid text-right">
                                            <p className="schedulestatus">
                                              {deliverytype === 2
                                                ? "scheduled"
                                                : "now"}
                                            </p>

                                            {notificationimage != "" &&
                                            notificationimage !== undefined &&
                                            notificationimage.length > 0 &&
                                            notificationimage !== null ? (
                                              notificationimage.indexOf(
                                                "https"
                                              ) === -1 ? (
                                                <img
                                                  className="message-offerimg"
                                                  src={
                                                    notificationimgpath +
                                                    notificationimage
                                                  }
                                                  alt="Goto"
                                                />
                                              ) : (
                                                <img
                                                  className="message-offerimg"
                                                  src={notificationimage}
                                                  alt="Goto"
                                                />
                                              )
                                            ) : null}
                                          </div>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="d-flex align-items-center justify-content-start android-pushnotificationmsg">
                                          <img
                                            src={appicon}
                                            width="14px"
                                            height="14px"
                                            alt="Appicon"
                                            className="rounded"
                                          />

                                          <h4 className="pushNotification-app">
                                            {localStorage.getItem("Appname")}
                                          </h4>

                                          <svg
                                            width="4"
                                            height="4"
                                            viewBox="0 0 4 4"
                                          >
                                            <circle
                                              id="Ellipse_2776"
                                              data-name="Ellipse 2776"
                                              cx="2"
                                              cy="2"
                                              r="2"
                                              fill="#7782a1"
                                            />
                                          </svg>

                                          <div className="newnotify-time">
                                            <p>
                                              {deliverytype === 2
                                                ? "scheduled"
                                                : "now"}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="pushmsg-aerodown">
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="5.848"
                                            height="3.773"
                                            viewBox="0 0 5.848 3.773"
                                          >
                                            <path
                                              id="Path_107504"
                                              data-name="Path 107504"
                                              d="M-20240.625,1211.547l2.5-2.5,2.5,2.5"
                                              transform="translate(20241.049 -1208.199)"
                                              fill="none"
                                              stroke="#a1a1a1"
                                              stroke-width="1.2"
                                            />
                                          </svg>
                                        </div>
                                      </>
                                    )}
                                  </div>

                                  {android && (
                                    <div
                                      className="newnotification-title mt-2"
                                      style={{ display: "flex" }}
                                    >
                                      <div
                                        style={{ width: "calc(100% - 36px)" }}
                                      >
                                        {title !== "" &&
                                        title !== null &&
                                        title !== undefined ? (
                                          <h2>{title}</h2>
                                        ) : (
                                          <h2>Enter a title here</h2>
                                        )}

                                        {message !== "" &&
                                        message !== null &&
                                        message !== undefined ? (
                                          <p>{message}</p>
                                        ) : (
                                          <p>Compose your message here</p>
                                        )}
                                      </div>
                                      <div
                                        style={{
                                          width: "36px",
                                          height: "36px",
                                        }}
                                      >
                                        {notificationimage !== undefined &&
                                        notificationimage &&
                                        notificationimage !== null ? (
                                          notificationimage.indexOf("https") ===
                                          -1 ? (
                                            <img
                                              className="message-offerimg"
                                              src={
                                                notificationimgpath +
                                                notificationimage
                                              }
                                              alt="Goto"
                                            />
                                          ) : (
                                            <img
                                              className="message-offerimg"
                                              src={notificationimage}
                                              alt="Goto"
                                            />
                                          )
                                        ) : null}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>

                            <div className="newplatswitch notificationplat">
                              <ul
                                id="myTabs"
                                className="nav nav-pills nav-justified  d-flex justify-content-center"
                                role="tablist"
                                data-tabs="tabs"
                              >
                                <li
                                  className={
                                    ios
                                      ? "ios-icontochange techselect"
                                      : "ios-icontochange technotselect"
                                  }
                                >
                                  <svg
                                    onClick={handleIos}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18.417"
                                    height="22"
                                    viewBox="0 0 18.417 22"
                                  >
                                    <g id="apple-logo" transform="translate(0)">
                                      <g
                                        id="Group_1428"
                                        data-name="Group 1428"
                                        transform="translate(0 0)"
                                      >
                                        <path
                                          id="Path_668"
                                          data-name="Path 668"
                                          d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z"
                                          transform="translate(-38.692 -102.378)"
                                          fill=""
                                        />
                                        <path
                                          id="Path_669"
                                          data-name="Path 669"
                                          d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z"
                                          transform="translate(-224.655 0)"
                                          fill=""
                                        />
                                      </g>
                                    </g>
                                  </svg>
                                </li>

                                <li
                                  className={
                                    android
                                      ? "android-icontochange techselect"
                                      : "android-icontochange technotselect"
                                  }
                                >
                                  <svg
                                    onClick={handleAndroid}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="18.591"
                                    height="22"
                                    viewBox="0 0 18.591 22"
                                  >
                                    <path
                                      id="Path_7126"
                                      data-name="Path 7126"
                                      d="M1.376,15.116A1.3,1.3,0,0,1,0,13.912V8.761A1.3,1.3,0,0,1,1.376,7.556,1.3,1.3,0,0,1,2.753,8.761v5.151A1.3,1.3,0,0,1,1.376,15.116Zm10.411,5.037,1.2,1.2a.379.379,0,0,1-.537.536l-1.48-1.478a7.024,7.024,0,0,1-3.32,0L6.175,21.889a.379.379,0,1,1-.537-.536l1.2-1.193A4.85,4.85,0,0,1,3.45,15.8c0-.023,11.706,0,11.706,0A4.843,4.843,0,0,1,11.788,20.153ZM7.582,17.178a.687.687,0,1,0,.689.687A.687.687,0,0,0,7.582,17.178Zm3.444,0a.687.687,0,1,0,.689.687A.687.687,0,0,0,11.025,17.178Zm6.19-2.062a1.3,1.3,0,0,1-1.377-1.2V8.761a1.389,1.389,0,0,1,2.753,0v5.151A1.3,1.3,0,0,1,17.216,15.116ZM3.45,6.875a2.063,2.063,0,0,1,2.059-2.06V1.205A1.3,1.3,0,0,1,6.886,0,1.3,1.3,0,0,1,8.261,1.205V4.814H10.33V1.205A1.3,1.3,0,0,1,11.706,0a1.3,1.3,0,0,1,1.376,1.205V4.814h.009a2.063,2.063,0,0,1,2.066,2.06v8.243H3.451V6.875Z"
                                      transform="translate(18.591 22) rotate(180)"
                                      fill=""
                                    />
                                  </svg>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>

            {!spinner && (
              <div className="mobnewdraft_fotter mob-notification-footer">
                {title !== "" || message !== "" ? (
                  <button
                    className="pushnotification-savedraft"
                    onClick={handleSaveDraft}
                    style={{
                      border:
                        "1px solid " +
                        localStorage.getItem("button_background_color"),
                      color: localStorage.getItem("button_text_color"),
                      "--custom_color4":
                        localStorage.getItem("agencyid") == 1
                          ? "white"
                          : localStorage.getItem("button_hover_txtcolor"),
                      "--custom_color6":
                        localStorage.getItem("agencyid") == 1
                          ? "#3064f9"
                          : localStorage.getItem("button_hover_bgcolor"),
                    }}
                  >
                    {draftspinner === true ? (
                      <div style={{ width: "100%" }} className={draftloader}>
                        {/*<Spinner style={{ width: "20px", height: "20px" }} />*/}
                        <div className="new-blue-loader"></div>
                      </div>
                    ) : (
                      <div className=""> {draftsubmitval} </div>
                    )}
                  </button>
                ) : (
                  <button
                    className="savedraft-buttondisabled"
                    style={{
                      border: "",
                      color: "",
                      "--custom_color4":
                        localStorage.getItem("agencyid") == 1 ? "white" : "",
                      "--custom_color6":
                        localStorage.getItem("agencyid") == 1 ? "#3064f9" : "",
                    }}
                  >
                    <div className=""> Save draft </div>
                  </button>
                )}

                {title !== "" &&
                title !== null &&
                title !== undefined &&
                message !== "" &&
                message !== null &&
                message !== undefined ? (
                  <button
                    className="send-notificationsend"
                    onClick={handleReviewPopup}
                    style={{
                      backgroundColor: localStorage.getItem(
                        "button_background_color"
                      ),
                      border:
                        "1px solid " +
                        localStorage.getItem("button_background_color"),
                      color: localStorage.getItem("button_text_color"),
                      "--custom_color5":
                        localStorage.getItem("agencyid") == 1
                          ? "#134bec"
                          : localStorage.getItem("button_hover_bgcolor"),
                      "--custom_color4":
                        localStorage.getItem("agencyid") == 1
                          ? "white"
                          : localStorage.getItem("button_hover_txtcolor"),
                      "--custom_color6":
                        localStorage.getItem("agencyid") == 1
                          ? "#3064f9"
                          : localStorage.getItem("button_hover_bgcolor"),
                    }}
                  >
                    <div className={isLoader}>
                      <div className="new-loader"></div>
                    </div>
                    {submitval}
                  </button>
                ) : (
                  <button
                    className="savedraft-buttondisabled"
                    style={{
                      border: "",
                      color: "",
                      "--custom_color4":
                        localStorage.getItem("agencyid") == 1 ? "white" : "",
                      "--custom_color6":
                        localStorage.getItem("agencyid") == 1 ? "#3064f9" : "",
                    }}
                  >
                    <div className=""> Send </div>
                  </button>
                )}
              </div>
            )}
          </div>
        </section>

        <Modal
          isOpen={reviewpopup}
          style={{ maxWidth: "600px", maxHeight: "350px" }}
          className={" createnotificationmodal " + props.className}
          centered
          dataclass="newnotification"
        >
          <ModalHeader className="notificationmodal" toggle={btnPopupClose}>
            <div className="d-md-flex new-pus_notification_head align-items-center justify-content-between ">
              <h2 className="">Review rich notification</h2>
              <div className="newcrossmodal">
                <svg
                  width="13.059"
                  height="13.059"
                  viewBox="0 0 13.059 13.059"
                  onClick={btnPopupClose}
                >
                  <path
                    id="Union_18"
                    data-name="Union 18"
                    d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                    transform="translate(0.529 0.531)"
                    fill="#C8CEDB"
                    stroke="#C8CEDB"
                    strokeWidth="1.5"
                  />
                </svg>
              </div>
            </div>
          </ModalHeader>

          <ModalBody className="newpush-notification-body">
            <div className="round-corner">
              <div>
                <h4>{title}</h4>
                <p>{message} </p>
              </div>

              {notificationimage !== undefined &&
                notificationimage &&
                notificationimage !== null &&
                (notificationimage.indexOf("https") === -1 ? (
                  <div className="d-flex justify-content-center">
                    <img
                      src={notificationimgpath + notificationimage}
                      className=""
                      alt="Goto"
                    />
                  </div>
                ) : (
                  <></>
                ))}
            </div>

            <div className="push-messagesendto">
              <p>Device:</p>
              <div className="deviceSelected">
                {chkandroid && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.591"
                    height="22"
                    viewBox="0 0 18.591 22"
                  >
                    <path
                      id="Path_7126"
                      data-name="Path 7126"
                      d="M1.376,15.116A1.3,1.3,0,0,1,0,13.912V8.761A1.3,1.3,0,0,1,1.376,7.556,1.3,1.3,0,0,1,2.753,8.761v5.151A1.3,1.3,0,0,1,1.376,15.116Zm10.411,5.037,1.2,1.2a.379.379,0,0,1-.537.536l-1.48-1.478a7.024,7.024,0,0,1-3.32,0L6.175,21.889a.379.379,0,1,1-.537-.536l1.2-1.193A4.85,4.85,0,0,1,3.45,15.8c0-.023,11.706,0,11.706,0A4.843,4.843,0,0,1,11.788,20.153ZM7.582,17.178a.687.687,0,1,0,.689.687A.687.687,0,0,0,7.582,17.178Zm3.444,0a.687.687,0,1,0,.689.687A.687.687,0,0,0,11.025,17.178Zm6.19-2.062a1.3,1.3,0,0,1-1.377-1.2V8.761a1.389,1.389,0,0,1,2.753,0v5.151A1.3,1.3,0,0,1,17.216,15.116ZM3.45,6.875a2.063,2.063,0,0,1,2.059-2.06V1.205A1.3,1.3,0,0,1,6.886,0,1.3,1.3,0,0,1,8.261,1.205V4.814H10.33V1.205A1.3,1.3,0,0,1,11.706,0a1.3,1.3,0,0,1,1.376,1.205V4.814h.009a2.063,2.063,0,0,1,2.066,2.06v8.243H3.451V6.875Z"
                      transform="translate(18.591 22) rotate(180)"
                      fill="#3ddb86"
                    />
                  </svg>
                )}

                {chkios && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.417"
                    height="22"
                    viewBox="0 0 18.417 22"
                  >
                    <g id="apple-logo" transform="translate(0)">
                      <g
                        id="Group_1428"
                        data-name="Group 1428"
                        transform="translate(0 0)"
                      >
                        <path
                          id="Path_668"
                          data-name="Path 668"
                          d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z"
                          transform="translate(-38.692 -102.378)"
                          fill="#666"
                        />
                        <path
                          id="Path_669"
                          data-name="Path 669"
                          d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z"
                          transform="translate(-224.655 0)"
                          fill="#666"
                        />
                      </g>
                    </g>
                  </svg>
                )}
              </div>
            </div>

            {deliverytype === 2 && (
              <div className="pushnotification-schedule">
                <h4> Schedule </h4>
                {/* <span>
                        Send immediately
                        </span> */}
                <span>
                  {dateTimeValue != null &&
                  dateTimeValue != "" &&
                  dateTimeValue != undefined
                    ? moment(dateTimeValue)
                        .format("DD MMM yyyy, hh:mm A")
                        .toString()
                    : ""}
                </span>
              </div>
            )}

            {includesegmentlist.length > 0 && (
              <div className="pushnotification-scheduleaudience">
                <h4> Included groups </h4>
                <div>
                  {includesegmentlist.map((incrow, incidx) => {
                    return (
                      <p key={incidx}>
                        <p className="notification-audience-div">
                          {incrow.segment_name !== null &&
                          incrow.segment_name !== "" &&
                          incrow.segment_name !== undefined
                            ? incrow.segment_name
                            : incrow}

                          <svg
                            width="9px"
                            height="9px"
                            viewBox="0 -0.5 21 21"
                            version="1.1"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g
                              id="Page-1"
                              stroke="none"
                              stroke-width="1"
                              fill="none"
                              fill-rule="evenodd"
                            >
                              <g
                                id="Dribbble-Light-Preview"
                                transform="translate(-379.000000, -240.000000)"
                                fill="#7782A1"
                              >
                                <g
                                  id="icons"
                                  transform="translate(56.000000, 160.000000)"
                                >
                                  <polygon
                                    id="plus-[#1512]"
                                    points="344 89 344 91 334.55 91 334.55 100 332.45 100 332.45 91 323 91 323 89 332.45 89 332.45 80 334.55 80 334.55 89"
                                  ></polygon>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </p>
                      </p>
                    );
                  })}
                </div>
              </div>
            )}

            {excludesegmentlist.length > 0 && (
              <div className="pushnotification-scheduleaudience">
                <h4> Excluded groups </h4>
                <div>
                  {excludesegmentlist.map((excrow, excidx) => {
                    return (
                      <p key={excidx}>
                        <p className="notification-audience-div">
                          {" "}
                          {excrow.segment_name !== null &&
                          excrow.segment_name !== "" &&
                          excrow.segment_name !== undefined
                            ? excrow.segment_name
                            : excrow}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="1.5"
                            viewBox="0 0 9 1.5"
                          >
                            <path
                              id="Path_119017"
                              data-name="Path 119017"
                              d="M0,0V9"
                              transform="translate(9 0.75) rotate(90)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-width="1.5"
                            />
                          </svg>
                        </p>
                      </p>
                    );
                  })}
                </div>
              </div>
            )}

            {includesegmentlist.length <= 0 &&
              excludesegmentlist.length <= 0 && (
                <div className="pushnotification-scheduleaudience">
                  <h4> Groups </h4>
                  <p>
                    <p className="notification-audience-div">
                      {" "}
                      Total Subscriptions
                      <svg
                        width="9px"
                        height="9px"
                        viewBox="0 -0.5 21 21"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g
                          id="Page-1"
                          stroke="none"
                          stroke-width="1"
                          fill="none"
                          fill-rule="evenodd"
                        >
                          <g
                            id="Dribbble-Light-Preview"
                            transform="translate(-379.000000, -240.000000)"
                            fill="#7782A1"
                          >
                            <g
                              id="icons"
                              transform="translate(56.000000, 160.000000)"
                            >
                              <polygon
                                id="plus-[#1512]"
                                points="344 89 344 91 334.55 91 334.55 100 332.45 100 332.45 91 323 91 323 89 332.45 89 332.45 80 334.55 80 334.55 89"
                              ></polygon>
                            </g>
                          </g>
                        </g>
                      </svg>
                    </p>
                  </p>
                </div>
              )}

            <div className="mobile-schedulebtn">
              <button
                className="push-notificationsend"
                onClick={btnSendNotification}
              >
                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                  <div className={isLoader}>
                    <div className="new-blue-loader"></div>
                  </div>
                  <div>{submitval}</div>
                </div>
              </button>
              <button
                className=" push-notificationcancel"
                onClick={btnPopupClose}
              >
                {" "}
                Cancel{" "}
              </button>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="w-100 newpus_notification_footer">
              <div className="d-flex align-items-center justify-content-between">
                <button
                  className=" push-notificationcancel"
                  onClick={btnPopupClose}
                >
                  {" "}
                  Cancel{" "}
                </button>
                <button
                  className="push-notificationsend"
                  onClick={btnSendNotification}
                >
                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    <div className={isLoader}>
                      <div className="new-loader"></div>
                    </div>
                    <div>{submitval}</div>
                  </div>
                </button>
              </div>
            </div>
          </ModalFooter>
        </Modal>

        <Modal
          className="protip-popup-message"
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? openmobilesegmentDropdown
              : false
          }
          centered
        >
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobileTagDropdown}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </button>

              {/*categoryData.length > 0 && 
                        <input placeholder="Search" type="text" value={catSearchVal} onChange={(e) => { setCatSearchVal(e.target.value); }} ></input>
                    */}

              {/*catSearchVal.trim() &&
                        <p onClick={(e) => { setCatSearchVal(""); }} >
                            Clear
                        </p>
                */}
            </div>

            <div className="notification-mobilesearchsuggestion mobilesearchsuggestion">
              {/*categoryData.length === 0 &&
                        <div className="no-app-dropdown">
                            <img className="img-fluid" src={ require("../../assets/images/dashboard/no-apps-created.png").default } alt="No App Found" />
                        </div>
                    */}

              {segmentlist &&
                segmentlist
                  .filter(
                    (row) =>
                      row.segment_name
                        .toLowerCase()
                        .includes(searchincsegment.toLowerCase()) &&
                      !excludesegmentarr.includes(row.segment_name)
                  )
                  .map((res, indx) => {
                    return (
                      <div className="category-created">
                        <label
                          className="category-list-container notification-category-list-container"
                          for={`seg${indx}`}
                        >
                          <input
                            type="checkbox"
                            id={`seg${indx}`}
                            name="segment1"
                            onChange={(e) => handleAddTagDrop(e, res, indx)}
                            checked={includesegmentarr.includes(
                              res.segment_name
                            )}
                          />

                          <span className="category-checkmark"></span>
                          {/*res.level < 9 && handleDash( res.level )*/}
                          {res.segment_name}
                        </label>
                      </div>
                    );
                  })}
            </div>
          </ModalBody>
        </Modal>

        <Modal
          className="protip-popup-message"
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? openmobilesegmentDropdown1
              : false
          }
          centered
        >
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobileTagDropdown1}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </button>

              {/*categoryData.length > 0 && 
                        <input placeholder="Search" type="text" value={catSearchVal} onChange={(e) => { setCatSearchVal(e.target.value); }} ></input>
                    */}

              {/*catSearchVal.trim() &&
                        <p onClick={(e) => { setCatSearchVal(""); }} >
                            Clear
                        </p>
                */}
            </div>

            <div className="notification-mobilesearchsuggestion mobilesearchsuggestion">
              {/*categoryData.length === 0 &&
                        <div className="no-app-dropdown">
                            <img className="img-fluid" src={ require("../../assets/images/dashboard/no-apps-created.png").default } alt="No App Found" />
                        </div>
                    */}

              {segmentlist &&
                segmentlist
                  .filter(
                    (row1) =>
                      row1.segment_name
                        .toLowerCase()
                        .includes(searchexcsegment.toLowerCase()) &&
                      !includesegmentarr.includes(row1.segment_name)
                  )
                  .map((res1, indx1) => {
                    return (
                      <div className="category-created">
                        <label
                          className="category-list-container notification-category-list-container"
                          for={`seg1${indx1}`}
                        >
                          <input
                            type="checkbox"
                            id={`seg1${indx1}`}
                            name="segment11"
                            onChange={(e) => handleAddTagDrop1(e, res1, indx1)}
                            checked={excludesegmentarr.includes(
                              res1.segment_name
                            )}
                          />

                          <span className="category-checkmark"></span>
                          {/*res.level < 9 && handleDash( res.level )*/}
                          {res1.segment_name}
                        </label>
                      </div>
                    );
                  })}
            </div>
          </ModalBody>
        </Modal>

        {/* <Modal
        isOpen={notification_modal}
        style={{ maxWidth: "600px", maxHeight: "350px" }}
        className={this.props.className}
        centered
        dataclass="newnotification"
        >
        <ModalHeader
            toggle={this.togglenotification}
            className="secondnotificationmodal"
            >
            <div className="d-md-flex new-pus_notification_head align-items-center justify-content-between ">
                <div
                    className="newcrossmodaltwo"
                    onClick={this.togglenotification}
                    >
                    <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                        <path
                            id="Union_18"
                            data-name="Union 18"
                            d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                            transform="translate(0.529 0.531)"
                            fill="#C8CEDB"
                            stroke="#C8CEDB"
                            strokeWidth="1.5"
                            />
                    </svg>
                </div>
            </div>
        </ModalHeader>
        <ModalBody className="secondnewpush-notification-body">
            <div className="">
                <div className="d-flex align-items-center mb-3">
                    <img
                    src={
                    require("../../assets/images/dashboard/notificationmodal.png")
                    .default
                    }
                    className=""
                    alt="Goto"
                    />
                </div>
            </div>
            <div className="secondmodal-texts">
                <h2>See how your notifications performed</h2>
                <p className="pushnotification-longtext">Get a report of your notification campaigns from Google Firebase.</p>
                <div className="secondmodal-pushbutton">
                    <button className="pushfirstbutton">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.591" height="22" viewBox="0 0 18.591 22">
                            <path id="Path_7126" data-name="Path 7126" d="M1.376,15.116A1.3,1.3,0,0,1,0,13.912V8.761A1.3,1.3,0,0,1,1.376,7.556,1.3,1.3,0,0,1,2.753,8.761v5.151A1.3,1.3,0,0,1,1.376,15.116Zm10.411,5.037,1.2,1.2a.379.379,0,0,1-.537.536l-1.48-1.478a7.024,7.024,0,0,1-3.32,0L6.175,21.889a.379.379,0,1,1-.537-.536l1.2-1.193A4.85,4.85,0,0,1,3.45,15.8c0-.023,11.706,0,11.706,0A4.843,4.843,0,0,1,11.788,20.153ZM7.582,17.178a.687.687,0,1,0,.689.687A.687.687,0,0,0,7.582,17.178Zm3.444,0a.687.687,0,1,0,.689.687A.687.687,0,0,0,11.025,17.178Zm6.19-2.062a1.3,1.3,0,0,1-1.377-1.2V8.761a1.389,1.389,0,0,1,2.753,0v5.151A1.3,1.3,0,0,1,17.216,15.116ZM3.45,6.875a2.063,2.063,0,0,1,2.059-2.06V1.205A1.3,1.3,0,0,1,6.886,0,1.3,1.3,0,0,1,8.261,1.205V4.814H10.33V1.205A1.3,1.3,0,0,1,11.706,0a1.3,1.3,0,0,1,1.376,1.205V4.814h.009a2.063,2.063,0,0,1,2.066,2.06v8.243H3.451V6.875Z" transform="translate(18.591 22) rotate(180)" fill="#3ddb86"/>
                        </svg>
                        <p>Android</p>
                        <img
                        src={
                        require("../../assets/images/dashboard/Goto.png")
                        .default
                        }
                        className="gotoappicon appear-spacing"
                        alt="Goto"
                        />
                    </button>
                    <button className="pushsecondbutton">
                        <svg xmlns="http://www.w3.org/2000/svg" width="18.417" height="22" viewBox="0 0 18.417 22">
                            <g id="apple-logo" transform="translate(0)">
                                <g id="Group_1428" data-name="Group 1428" transform="translate(0 0)">
                                    <path id="Path_668" data-name="Path 668" d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z" transform="translate(-38.692 -102.378)" fill="#666"/>
                                    <path id="Path_669" data-name="Path 669" d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z" transform="translate(-224.655 0)" fill="#666"/>
                                </g>
                            </g>
                        </svg>
                        <p>iOS</p>
                        <img
                        src={
                        require("../../assets/images/dashboard/Goto.png")
                        .default
                        }
                        className="gotoappicon appear-spacing"
                        alt="Goto"
                        />
                    </button>
                </div>
            </div>
        </ModalBody>
        </Modal> */}
        <Modal
          className="publish-secondmodal"
          style={{ maxWidth: "480px", maxHeight: "280px" }}
          isOpen={androidopenPopUp}
          centered
          toggle={(e) => handleandroidPopUp(e)}
        >
          <ModalHeader>
            <div
              className="publish-crossmodal"
              onClick={(e) => handleandroidPopUp(e)}
              style={{
                zIndex: "9",
              }}
            >
              <img
                src={require("../../assets/images/dashboard/cross.png").default}
              />
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="publish-popup-maindiv">
              <div className="publish-popup-head">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <g id="Group_41408" data-name="Group 41408" transform="translate(-324.314 -455.386)">
                      <g id="Ellipse_2829" data-name="Ellipse 2829" transform="translate(324.314 455.386)" fill="#fff1f1" stroke="#ff4848" stroke-width="1">
                        <circle cx="8" cy="8" r="8" stroke="none" />
                        <circle cx="8" cy="8" r="7.5" fill="none" />
                      </g>
                      <path id="Path_8311" data-name="Path 8311" d="M0,3.438,3.438,0" transform="translate(330.187 461.902) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1" />
                      <path id="Path_10113" data-name="Path 10113" d="M0,1.118,1.117,0" transform="translate(331.826 466.634) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1" />
                    </g>
                  </svg> */}
                <h2>Build settings incomplete</h2>
              </div>
              <p>
                To send push notifications to your Android app users, configure
                you app’s build settings. A rebuild is required each time
                changes are made to the Build settings.
              </p>

              <div className="publish-popup-bottom">
                <button
                  className="publish-popup-cancel"
                  onClick={(e) => handleandroidPopUp(e)}
                >
                  Okay
                </button>
                <Link to={"/app/notifications/build-settings"}>
                  <button className="publish-popup-confirm">
                    Build settings
                  </button>
                </Link>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          className="publish-secondmodal"
          style={{ maxWidth: "480px", maxHeight: "280px" }}
          isOpen={iosopenPopUp}
          centered
          toggle={(e) => handleiosPopUp(e)}
        >
          <ModalHeader>
            <div
              className="publish-crossmodal"
              onClick={(e) => handleiosPopUp(e)}
              style={{
                zIndex: "9",
              }}
            >
              <img
                src={require("../../assets/images/dashboard/cross.png").default}
              />
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="publish-popup-maindiv">
              <div className="publish-popup-head">
                {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                    <g id="Group_41408" data-name="Group 41408" transform="translate(-324.314 -455.386)">
                      <g id="Ellipse_2829" data-name="Ellipse 2829" transform="translate(324.314 455.386)" fill="#fff1f1" stroke="#ff4848" stroke-width="1">
                        <circle cx="8" cy="8" r="8" stroke="none" />
                        <circle cx="8" cy="8" r="7.5" fill="none" />
                      </g>
                      <path id="Path_8311" data-name="Path 8311" d="M0,3.438,3.438,0" transform="translate(330.187 461.902) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1" />
                      <path id="Path_10113" data-name="Path 10113" d="M0,1.118,1.117,0" transform="translate(331.826 466.634) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1" />
                    </g>
                  </svg> */}
                <h2>Build settings incomplete</h2>
              </div>
              <p>
                To send push notifications to your iOS app users, configure you
                app’s build settings. A rebuild is required each time changes
                are made to the Build settings.
              </p>

              <div className="publish-popup-bottom">
                <button
                  className="publish-popup-cancel"
                  onClick={(e) => handleiosPopUp(e)}
                >
                  Okay
                </button>
                <Link to={"/app/notifications/build-settings"}>
                  <button className="publish-popup-confirm">
                    Build settings
                  </button>
                </Link>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          className="notification-conf-secondmodal"
          style={{ maxWidth: "480px", maxHeight: "280px" }}
          isOpen={openLocationPopUp}
          toggle={() => handleOpenLocationPopUp()}
          centered
        >
          <ModalHeader>
            <div
              className="notification-conf-secondmodal-crossmodal"
              style={{ zIndex: "9" }}
            >
              <img
                onClick={() => handleOpenLocationPopUp()}
                src={require("../../assets/images/dashboard/cross.png").default}
              />
            </div>
          </ModalHeader>

          <ModalBody>
            <div className="notification-conf-popup-maindiv">
              <div className="notification-conf-popup-head">
                <h2>Location permission required</h2>
              </div>

              <p>
                Sending location based notifications require enabling location
                permission in your Build configuration. Enable location
                permission in your app build settings to proceed.
              </p>

              <div className="notification-conf-popup-bottom">
                <button
                  className="notification-conf-popup-cancel"
                  onClick={() => handleOpenLocationPopUp()}
                >
                  Cancel
                </button>

                <button
                  className="notification-conf-popup-confirm"
                  onClick={() => handleOpenLocationPopUp()}
                >
                  Okay
                </button>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <SingleSelectionPopUpForNotification
          openSingleSelectionPopUp={openSingleSelectionPopUp}
          techOPtions={
            parseInt(localStorage.getItem("website_technology")) === 2
              ? itemTypeOptionsForWeb2App
              : parseInt(localStorage.getItem("website_technology")) === 3
              ? itemTypeOptionsForCustomApp
              : parseInt(localStorage.getItem("website_technology")) === 4
              ? itemTypeOptionsForWordpress
              : parseInt(localStorage.getItem("website_technology")) === 5
              ? itemTypeOptionsForWoocommerce
              : []
          }
          searchValForPopUp={searchValForPopUp}
          setSearchValForPopUp={setSearchValForPopUp}
          mergeArray={mergeArray}
          moduleName={moduleName}
          setModuleName={setModuleName}
          temp_subData={tempSubdata}
          temp_showL1Options={tempShowL1Options}
          handleOpenSelectionPopUp={handleOpenSelectionPopUp}
          handleHomeBtnClick={handleHomeBtnClick}
          temp_breadcrumbArr={tempBreadcrumbArr}
          temp_module_label={tempModuleLabel}
          handleBreadcrumb={handleBreadcrumb}
          handleMobBackBtn={handleMobBackBtn}
          temp_item_type={tempItemType}
          temp_invidual_selected_item_id={tempInvidualSelectedItemId}
          temp_rest_base={tempRestBase}
          temp_slug={tempSlug}
          subdropdownSpinner={subdropdownSpinner}
          paginationSpinner={paginationSpinner}
          handleFirstLabelClick={handleFirstLabelClick}
          handleSearchVal={handleSearchVal}
          handleSubData={handleSubData}
          handleL1Change={handleL1Change}
          handleMergeArrLeftDropdown={handleMergeArrLeftDropdown}
          handleParentClick={handleParentClick}
          handleBottomScrollListner={handleBottomScrollListner}
          handleSubdropdown={handleSubdropdown}
          typeArray={typeArray}
          is_chat_enabled={""}
          keys_data={""}
          temp_post_type={tempPostType}
        />
      </>
    );
}

export { NotificationCreate as default };