import React from "react";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import "./newAdvertisment.css";
import placeholder from "../../Agency/assets/images/accountprofile.png";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ReactSelect from "react-select";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  SaveAdvertisement,
  advertisementTaxonomyApi,
  advertisementTypeApi,
  cmsGetAtrApiForAd,
  cmsGetPagesApiForAd,
  cmsGetPagesApiForAdItemType,
  cmsGetPostsApiForAd,
  cmsGetPostsApiForAdItemType,
  getAdvertisementData,
  getAdvertisementSetting,
  productCategoriesTreeAPIForAds,
  restBaseTaxonomyapiAd,
} from "../../Api/Advertisement/AdvertisementApi";
import CodeMirror from "@uiw/react-codemirror";
import AdsPreview, {
  WordpressWooWebtoAppPreview,
} from "./WordpressWooWebtoAppPreview";
import CustomAppPreview from "./CustomAppPreview";
import Web2AppPreview from "./Web2AppPreview";
import WordpressPreview from "./WordpressPreview";
import WooCommercePreview from "./WooCommercePreview";
import { errortoast } from "../Toaster";
import CustomAdPreview from "./CustomAdPreview";
import { SingleSelectionPopUpForAdvertisements } from "../Reusable/SingleSelectionPopUp";
import moment from "moment";

export default class EditAdvertisement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.postListTopRef = React.createRef();
    this.postListBottomRef = React.createRef();
    this.pageListTopRef = React.createRef();
    this.pageListBottomRef = React.createRef();
    this.postDetailsTopRef = React.createRef();
    this.postDetailsBottomRef = React.createRef();
    this.pageDetailsTopRef = React.createRef();
    this.pageDetailsBottomRef = React.createRef();
    this.state = {
      spinner:true,
        // this.props.match.params.adsId !== undefined &&
        // this.props.match.params.adsId !== "undefined" &&
        // this.props.match.params.adsId !== null &&
        // this.props.match.params.adsId !== "null" &&
        // this.props.match.params.adsId !== "create"
        //   ? true
        //   : false,
      open_mob_filter_pop_up: false,
      mount: true,
      is_enable_screen: 0,
      plan: "",
      openAdvertisementPopUp: false,
      loaderimage1: require("../../assets/images/signup/menuLoader_GIF.gif")
        .default,
      adName: "",
      updatedAt: "",
      oldAdType:"",
      adType: "",
      androidAdUnitId: "",
      iosAdUnitId: "",
      androidAdScript: "",
      iosAdScript: "",
      isLoader: "d-none",
      submitval: "Publish",
      isLoader2: "d-none",
      submitval2: "Save",
      statusOfPost: "",
      listingData: null,
      adId: this.props.match.params.adsId,
      openLocationInnerFeatures: true,
      locationArr: [
        {
          module_name: "web_view",
          item: "",
          ad_position: "top",
          module_label: "Web view",
          subdropdownSpinner: false,
          subdropdownData: [],
          customAppPagedData: [],
          breadcrumbArr:[],
          temp_module_label:"Web view",
          temp_module_name:"web_view",
          temp_item:"",
          temp_subData:[],
          temp_breadcrumbArr:[],
          showL1Options:true,
          temp_showL1Options:true,
          isAllowPagination:true,
          page:1,
        },
      ],
      openParentDropdown: false,
      openChildDropdown: false,
      customPages: [],
      customPosts: [],
      customAtrData: [],
      activeSlide: 0,
      // subdropdownSpinner: false,
      fitBanner: false,
      appOpenTypeAdCount:1,
      storeUrl: localStorage.getItem("store_url"),
      typesData: [],
      taxonomyData: [],
      mergeArray:[],
      deviceTypeForPreview:"android",
      optionsForwebToApp: [{ label: "Web view", value: "web_view" }],
      optionsForCustomApp: [
        { label: "Page details", value: "page_details" },
        { label: "Page list", value: "page_list" },
        { label: "Post details", value: "post_details" },
        { label: "Post list", value: "post_list" },
        { label: "Web view", value: "web_view" },
      ],
      optionsForWordpressApp: [
        // { label: "Home screen", value: "home_screen" },
        // { label: "Blog categories", value: "blog_cat" },
        // { label: "Page list", value: "page_list" },
        // // { label:"Blog list",value:"post_list" },
        // { label: "Page details", value: "page_details" },
        // { label: "Blog details", value: "post_details" },
        // { label: "Web view", value: "web_view" },
        { label: "Post categories", value: "post_cat",rest_base:""  },
        { label: "Post list", value: "post_list" },
        { label: "Home screen", value: "home_screen",rest_base:"" },
        { label: "Page list", value: "page_list" ,rest_base:"" },
        { label: "Web view", value: "web_view" ,rest_base:"" }
      ],
      optionsForWooCommerceApp: [
        // { label: "Home screen", value: "home_screen" },
        // { label: "Product categories", value: "product_cat" },
        // { label: "Product list", value: "product_list" },
        // { label: "Product details", value: "product_details" },
        // { label: "Cart", value: "cart" },
        // { label: "Page list", value: "page_list" },
        // { label: "Page details", value: "page_details" },
        // { label: "Blog details", value: "post_details" },
        // { label: "Web view", value: "web_view" },
        { label: "Cart", value: "cart" ,rest_base:"" },
        { label: "Home screen", value: "home_screen",rest_base:"" },
        { label: "Page list", value: "page_list" ,rest_base:"" },
        { label: "Product categories", value: "product_cat",rest_base:""  },
        { label: "Product list", value: "product_list",rest_base:""  },
  
        { label: "Web view", value: "web_view" ,rest_base:"" }
      ],
      optionsForRatio: [
        { label: "300×250", value: "300×250" },
        { label: "336×280", value: "336×280" },
        { label: "320×50", value: "320×50" },
        { label: "320×100", value: "320×100"},
        { label: "336×336", value: "336×336"},
        { label: "300×600", value: "300×600"},
        { label: "360×300 ", value: "360×300"},
        { label: "360×120", value: "360×120"},
        { label: "320×480", value: "320×480"},
        { label: "320×180", value: "320×180"},
      ],
      adRatio:"50",
      custom_ad_height:"50",
      custom_ad_width:"50",
      eachAdPosition:null,
      openSingleSelectionPopUp:false,
      searchValForPopUp:"",
      selectedLocationIndex:0,
      // pageNo:1,
      paginationSpinner: false,
      // isAllowPagination:true,
      appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
        ? true
        : false,
    };
  }

  componentDidMount() {
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
    {
      document.title =( this.state.adId!==undefined && this.state.adId!==null && this.state.adId!=="create" && this.state.adId!=="" )?"Edit ad | AppMySite": "Create ad | AppMySite";
    }
    else
    {
      document.title =( this.state.adId!==undefined && this.state.adId!==null && this.state.adId!=="create" && this.state.adId!=="" )?"Edit ad |"+ ' ' +localStorage.getItem('agencyname'): "Create ad |"+ ' ' +localStorage.getItem('agencyname');
    }

    // if(
    //   this.state.adId !== undefined &&
    //   this.state.adId !== "undefined" &&
    //   this.state.adId !== null &&
    //   this.state.adId !== "null" &&
    //   this.state.adId !== "create"
    // ){
    // getAdvertisementSetting(this);
    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      cmsGetPostsApiForAd(this);
      cmsGetAtrApiForAd(this);
      cmsGetPagesApiForAd(this);
    }else if (parseInt(localStorage.getItem("website_technology")) === 4 ||
    parseInt(localStorage.getItem("website_technology")) === 5) {
      advertisementTypeApi(this,()=>{
      advertisementTaxonomyApi(this,()=>{
        this.setState({
          spinner:
          this.state.adId !== undefined &&
          this.state.adId !== "undefined" &&
          this.state.adId !== null &&
          this.state.adId !== "null" &&
          this.state.adId !== "create"?
          this.state.spinner :
          false,
        })
     this.taxonomyDataset();
   })
  })
 }
    setTimeout(()=>{
      const paginationParams = {
        limit: 20,
        page: 1,
        advertisementData: this.state.listingData,
      };
    getAdvertisementData(this, "", () => {
      if (
        this.state.adId !== undefined &&
        this.state.adId !== "undefined" &&
        this.state.adId !== null &&
        this.state.adId !== "null" &&
        this.state.adId !== "create"
      ) {
      
        this.state.locationArr.map((res, indx) => {
          this.handleApiCall(indx, res.module_name, "initial");
        });
      }

      
    },paginationParams);
  },3000)
  }
  componentWillUnmount() {
    // mount = false;
  }

  taxonomyDataset = () =>
  {
    let restbaseArray = [];
    let mergeArray1 = [];
    let res = [];
    let taxnomyArray = [];
    let typeArray1 = [];


      mergeArray1 = [];
      if (
        typeof this.state.taxonomyData === "object" &&
        this.state.taxonomyData !== null
      ) {
        taxnomyArray = Object.keys(this.state.taxonomyData).map(
          (key) => this.state.taxonomyData[key]
        );
      }
      if (
        typeof this.state.typesData === "object" &&
        this.state.typesData !== null
      ) {
        typeArray1 = Object.keys(this.state.typesData).map(
          (key) => this.state.typesData[key]
        );
      }

      for (let i = 0; i < typeArray1.length; i++) 
      {
        res = [];
        res.name = typeArray1[i].name;
        res.slug = typeArray1[i].slug;
        res.rest_base = typeArray1[i].rest_base;
        res.type = "post_type";
        res.post_type = typeArray1[i].rest_base;
        res.invidual_selected_item_id = "";
        mergeArray1.push(res);

        res = [];
        res.name = typeArray1[i].name + " search";
        res.slug = typeArray1[i].slug;
        res.rest_base = typeArray1[i].rest_base;
        res.type = "search";
        res.post_type = typeArray1[i].rest_base;
        res.invidual_selected_item_id = "";
        mergeArray1.push(res);

        for (let j = 0; j < typeArray1[i].taxonomies.length; j++) {
          if (taxnomyArray.length !== 0) {
            const index = taxnomyArray
              .map((object) => object.slug)
              .indexOf(typeArray1[i].taxonomies[j]);
            res = [];
            res.name = taxnomyArray[index].name;
            res.slug = taxnomyArray[index].slug;
            res.rest_base = taxnomyArray[index].rest_base;
            res.type = "taxonomy";
            res.post_type = typeArray1[i].rest_base;
            res.invidual_selected_item_id = "";
            mergeArray1.push(res);
          }
        }
      }
      let newArr1=[];
      let finalArr=[];
      // optionsForWordpressApp: [
      //   { label: "Home screen", value: "home_screen" },
      //   { label: "Blog categories", value: "blog_cat" },
      //   { label: "Page list", value: "page_list" },
      //   // { label:"Blog list",value:"post_list" },
      //   { label: "Page details", value: "page_details" },
      //   { label: "Blog details", value: "post_details" },
      //   { label: "Web view", value: "web_view" },
      // ],
      // optionsForWooCommerceApp: [
      //   { label: "Home screen", value: "home_screen" },
      //   { label: "Product categories", value: "product_cat" },
      //   { label: "Product list", value: "product_list" },
      //   { label: "Product details", value: "product_details" },
      //   { label: "Cart", value: "cart" },
      //   { label: "Page list", value: "page_list" },
      //   { label: "Page details", value: "page_details" },
      //   { label: "Blog details", value: "post_details" },
      //   { label: "Web view", value: "web_view" },
      // ],
      mergeArray1 && mergeArray1.map((row)=>{
      let obj={
        label:"",
        value:"",
        rest_base:""
      }
      if(row.slug==="post"&&row.type!=="search"){
      obj.label="Post details";
      obj.value="post_details";
      obj.rest_base=row.rest_base;
      newArr1.push(obj);
      }
     else if(row.slug==="page"&&row.type!=="search"){
        obj.label="Page details";
        obj.value="page_details";
        obj.rest_base=row.rest_base;
        newArr1.push(obj);
        }
      else  if(row.slug==="product"&&row.type!=="search"&&parseInt(localStorage.getItem("website_technology"))===5){
          obj.label="Product details";
          obj.value="product_details";
          obj.rest_base=row.rest_base;
          newArr1.push(obj);
          }
          
      })
      
            if(parseInt(localStorage.getItem("website_technology"))===5){
              finalArr=this.state.optionsForWooCommerceApp.concat(newArr1);
            }else if(parseInt(localStorage.getItem("website_technology"))===4){
              finalArr=this.state.optionsForWordpressApp.concat(newArr1);
            }
            finalArr = finalArr.sort((a, b) => {
              const nameA = a.label.toUpperCase(); 
              const nameB = b.label.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
     
  
      this.setState({
        mergeArray:mergeArray1,
        optionsForWooCommerceApp:finalArr,
        optionsForWordpressApp:finalArr
      });
    }

    handleBottomScrollListner = (e,indx,value,isAllow,page) => {

      let newPage = page + 1;    
      let limit = 99;

      if(!this.state.paginationSpinner && isAllow){
        if(parseInt(localStorage.getItem("website_technology")) ===3){
          if (value === "post_details") {
            cmsGetPostsApiForAdItemType(this, indx,"not-initial",newPage,limit);
          } else if (value === "page_details") {
            cmsGetPagesApiForAdItemType(this, indx,"not-initial",newPage,limit);
          } 
        }else{
          if (
            value === "post_details" ||
            value === "page_details" ||
            value === "product_details"
          ) {
         
            restBaseTaxonomyapiAd(
              value === "post_details"
                ? "posts"
                : value === "page_details"
                ? "pages"
                : "product",
              this,
              indx,
              "not-initial",
              newPage,
            );
          }
        }


      
    
      }
  
    }
  

  handlesliderChange = (e, from) => {
    if (from === "left") {
      if (this.state.activeSlide > 0) {
        this.setState({
          activeSlide: this.state.activeSlide - 1,
        });
      }
    } else {
      if (this.state.activeSlide < this.state.locationArr.length - 1) {
        this.setState({
          activeSlide: this.state.activeSlide + 1,
        });
      }
    }
  };

  handleFilterMobPopUp = () => {
    this.setState({
      open_mob_filter_pop_up: !this.state.open_mob_filter_pop_up,
    });
  };
  handleOpenAdsPopUp = () => {
    this.setState({
      openAdvertisementPopUp: !this.state.openAdvertisementPopUp,
    });
  };

  handleAdNameChange = (e) => {
    this.setState({
      adName: e.target.value,
    });
  };

  handleChooseAdVal = (e, val) => {
    this.setState({
      adType: val,
      openAdvertisementPopUp: !this.state.openAdvertisementPopUp,
    });
  };

  handleAndroidAdUnitId = (e) => {
    this.setState({
      androidAdUnitId: e.target.value,
    });
  };

  handleIosAdUnitId = (e) => {
    this.setState({
      iosAdUnitId: e.target.value,
    });
  };

  handleAndroidAdScript = (e) => {
    this.setState({
      androidAdScript: e,
    });
  };
  handleIosAdScript = (e) => {
    this.setState({
      iosAdScript: e,
    });
  };

  handleAddLocation = () => {
    let arr = [...this.state.locationArr];
    arr.push( {
      module_name: "web_view",
      item: "",
      ad_position: "top",
      module_label: "Web view",
      subdropdownSpinner: false,
      subdropdownData: [],
      customAppPagedData: [],
      breadcrumbArr:[],
      temp_module_label:"Web view",
      temp_module_name:"web_view",
      temp_item:"",
      temp_subData:[],
      temp_breadcrumbArr:[],
      showL1Options:true,
      temp_showL1Options:true,
      isAllowPagination:true,
      page:1,
    },);
    this.setState({
      locationArr: arr,
      // openLocationInnerFeatures: true,
    });
  };

  handleDeleteLocation = (e, indx) => {
    let arr = [...this.state.locationArr];
    arr.splice(indx, 1);
    this.setState({
      locationArr: arr,
      activeSlide: 0,
      selectedLocationIndex:0,
    });
  };

  handleCustomAdHeight = (e) => {
    // const re = /^[0-9\b]+$/;

    // if (e.target.value === '' || re.test(e.target.value)) {
    //    this.setState({custom_ad_height: e.target.value})
    // }
    if (e.target.value === '' || e.target.value > 1 ) {
      this.setState({
        custom_ad_height:e.target.value,
      })

    } else {
      this.setState({
        custom_ad_height:"1",
      })
   
    }

  }

  handleCustomAdWidth = (e) => {
    // const re = /^[0-9\b]+$/;

    // if (e.target.value === '' || re.test(e.target.value)) {
    //    this.setState({custom_ad_width: e.target.value})
    // }

    if (e.target.value === '' || e.target.value > 1) {
      this.setState({
        custom_ad_width:e.target.value,
      })

    } else {
      this.setState({
        custom_ad_width:"1",
      })
   
    }

  }

  

  handleAdAlignment = (e, indx, from) => {
    let arr = [...this.state.locationArr];
    arr[indx].ad_position = from;
    this.setState({
      locationArr: arr,
      activeSlide: indx,
    },()=>{
      if(parseInt(localStorage.getItem("website_technology"))===3){
        // setTimeout(()=>{
    
    
        if(arr[indx].module_name==="page_list"){
          if(arr[indx].ad_position==="top"){
            if(!window.matchMedia("(max-width: 1200px)").matches){
              setTimeout(()=>{
                const itemElement = this.pageListTopRef.current;
                if (itemElement ) {
          
              itemElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                
            }
          })
          }
          }else{
            if(!window.matchMedia("(max-width: 1200px)").matches){
              setTimeout(()=>{
                const itemElement = this.pageListBottomRef.current;
                if (itemElement ) {
          
              itemElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                
            }
          })
          }
          }
      
        }else if(arr[indx].module_name==="post_list"){
          if(arr[indx].ad_position==="top"){
            if(!window.matchMedia("(max-width: 1200px)").matches){
              setTimeout(()=>{
                const itemElement = this.postListTopRef.current;
                if (itemElement ) {
          
              itemElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                
            }
          })
          }
          }else{
            if(!window.matchMedia("(max-width: 1200px)").matches){
              setTimeout(()=>{
                const itemElement = this.postListBottomRef.current;
                if (itemElement ) {
          
              itemElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                
            }
          })
          }
          }
        }
        else if(arr[indx].module_name==="page_details"){
          if(arr[indx].ad_position==="top"){
            if(!window.matchMedia("(max-width: 1200px)").matches){
              setTimeout(()=>{
                const itemElement = this.pageDetailsTopRef.current;
                if (itemElement ) {
          
              itemElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                
            }
          })
          }
          }else{
            if(!window.matchMedia("(max-width: 1200px)").matches){
              setTimeout(()=>{
                const itemElement = this.pageDetailsBottomRef.current;
                if (itemElement ) {
          
              itemElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                
            }
          })
          }
          }
        }
        else if(arr[indx].module_name==="post_details"){
          if(arr[indx].ad_position==="top"){
            if(!window.matchMedia("(max-width: 1200px)").matches){
              setTimeout(()=>{
                const itemElement = this.postDetailsTopRef.current;
                if (itemElement ) {
          
              itemElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                
            }
          })
          }
          }else{
            if(!window.matchMedia("(max-width: 1200px)").matches){
              setTimeout(()=>{
                const itemElement = this.postDetailsBottomRef.current;
                if (itemElement ) {
          
              itemElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
                
            }
          })
          }
          }
        }
      // },200)
      }
    });

    
  };

  handleParentDropdown = () => {
    this.setState({
      openParentDropdown: !this.state.openParentDropdown,
    });
  };

  handleApiCall = (indx, value, from) => {      
    
    let newPage =  1;
    let limit = 99;

    
    let arr = [...this.state.locationArr];

    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      if (value === "post_details") {
        cmsGetPostsApiForAdItemType(this, indx,from,newPage,limit);
      } else if (value === "page_details") {
        cmsGetPagesApiForAdItemType(this, indx,from,newPage,limit);
      } 
    } else {
      if (
        value === "post_details" ||
        value === "page_details" ||
        value === "product_details"
      ) {
        restBaseTaxonomyapiAd(
          value === "post_details"
            ? "posts"
            : value === "page_details"
            ? "pages"
            : "product",
          this,
          indx,
          from,
          newPage,
        );
      } else if( value === "product_cat"){
        productCategoriesTreeAPIForAds(this,indx,from);
      }
      
      else {
      }
    }

    this.setState({
      locationArr: arr,
    });
  };

  handleChildDropdown = () => {
    this.setState({
      openChildDropdown: !this.state.openChildDropdown,
    });
  };

  handleParentClick = (val, indx) => {
    this.setState({
      searchValForPopUp : "",
    })
    let arr = [...this.state.locationArr];
    arr[indx].temp_module_name = val.value;
    arr[indx].temp_module_label = val.label;
    // arr[indx].item = "all";
    arr[indx].subdropdownSpinner = true;
    arr[indx].temp_showL1Options = false;
    this.setState({
      locationArr: arr,
      // activeSlide: indx,
    });

    this.handleApiCall(indx, val.value, "not-initial");
  };


  handleSubDropdownSelection = (val, indx) => {
    let arr = [...this.state.locationArr];
    arr[indx].temp_item = parseInt(localStorage.getItem("website_technology")) === 3 ? 
    val._id :val.id?val.id.toString():val.term_id.toString();
    this.setState({
      locationArr: arr,
      activeSlide: indx,
    });
  };

  handleL1Change = (val,indx) => {
    let arr = [...this.state.locationArr];
    arr[indx].temp_module_name = val.value;
    arr[indx].temp_module_label = val.label;
    
    if(val.value==="page_details"||val.value==="post_details" || val.value==="blog_details" || val.value === "product_details" || val.value === "product_cat"){
      arr[indx].temp_item = "all";
    }else{
      arr[indx].temp_item = "";
    }

    this.setState({
      locationArr: arr,
      activeSlide: indx,
    });
  }



  // returnSelectedModuleName = (value) => {
  //   let label = null;
  //   if (value === "all") {
  //     label = "All";
  //   } else if (value === "home_screen") {
  //     label = "Home screen";
  //   } else if (value === "product_cat") {
  //     label = "Product Categories";
  //   } else if (value === "page_list") {
  //     label = "Page list";
  //   } else if (value === "blog_cat") {
  //     label = "Blog categories";
  //   } else if (value === "post_list") {
  //     label = "Post list";
  //   } else if (value === "product_list") {
  //     label = "Product list";
  //   } else if (value === "product_details") {
  //     label = "Product details";
  //   } else if (value === "cart") {
  //     label = "Cart";
  //   } else if (value === "page_details") {
  //     label = "Page details";
  //   } else if (value === "post_details") {
  //     label = "Post details";
  //   } else if (value === "blog_details") {
  //     label = "Blog details";
  //   } else if (value === "web_view") {
  //     label = "Web view";
  //   }
  //   return label;
  // };

  // Redirect fun
  redirectFun = (ad_id, status) => {
    if (status === "draft") {
      this.props.history.push(`/app/ad/${btoa(ad_id)}`);
      this.setState({
        adId: btoa(ad_id),
      });
    } else {
      this.props.history.push("/app/ads");
    }
  };

  handleSubmitData = (e, from) => {
  if(this.state.appOpenTypeAdCount!==0&& this.state.adType==="app_open" && from === "publish" && this.state.adType!==this.state.oldAdType){
   errortoast("You can't add more than one App open ad"); 
  }else if(!this.state.custom_ad_height || !this.state.custom_ad_width){
    errortoast("Ad size width and Ad size height is a required field.");
  }
  
  else{
      if (from === "publish") {
        this.setState({
          submitval: "",
          isLoader: "d-block",
        });
      } else {
        this.setState({
          submitval2: "",
          isLoader2: "d-block",
        });
      }
      let finalLocationArr = [];
      this.state.locationArr &&
        this.state.locationArr.map((res, indx) => {
          let obj = {
            module_name: res.module_name,
            item: res.item,
            ad_position: res.ad_position,
          };
          finalLocationArr.push(obj);
        });
      const params = {
        app_id: parseInt(localStorage.getItem("Appid")),
  
        id:
          this.state.adId !== undefined &&
          this.state.adId !== "undefined" &&
          this.state.adId !== null &&
          this.state.adId !== "null" &&
          this.state.adId !== "create"
            ? parseInt(atob(this.state.adId))
            : "",
        name: this.state.adName,
        type: this.state.adType,
        android_ad_custom_script:
          this.state.adType === "custom" ? this.state.androidAdScript : "",
        ios_ad_custom_script:
          this.state.adType === "custom" ? this.state.iosAdScript : "",
        android_ad_unit_id:
          this.state.adType === "custom" ? "" : this.state.androidAdUnitId,
        ios_ad_unit_id:
          this.state.adType === "custom" ? "" : this.state.iosAdUnitId,
        ad_status: from === "publish" ? "active" : "draft",
        // custom_ad_size: this.state.adType === "custom" ?this.state.adRatio:"",
        custom_ad_width: this.state.custom_ad_width,
        custom_ad_height: this.state.custom_ad_height,
        location:this.state.adType === "app_open" ? []:finalLocationArr,
        ad_position:   this.state.adId !== undefined &&
        this.state.adId !== "undefined" &&
        this.state.adId !== null &&
        this.state.adId !== "null" &&
        this.state.adId !== "create"
          ? ( this.state.eachAdPosition!==null && this.state.eachAdPosition!==undefined ) ?
            this.state.eachAdPosition :
            this.state.listingData.length+1
        :this.state.listingData.length+1
        ,
      };
  
     SaveAdvertisement(params, this, from);
    }
  };

   handleOpenSelectionPopUp = (from,indx) => {
      this.setState({
        openSingleSelectionPopUp:!this.state.openSingleSelectionPopUp,
        selectedLocationIndex:indx,
        activeSlide:indx,
      })

      let arr = [...this.state.locationArr];
      // let arr = JSON.parse(JSON.stringify(this.state.locationArr));

    if(from === "cancel"){
  
      arr[indx].temp_item = arr[indx].item;
      arr[indx].temp_module_name = arr[indx].module_name;
      arr[indx].temp_module_label =arr[indx].module_label;
      arr[indx].temp_breadcrumbArr=arr[indx].breadcrumbArr;
      arr[indx].temp_subData=arr[indx].subData;
      arr[indx].temp_showL1Options=arr[indx].showL1Options;
    }else{
      if(from==="open")
      {
    
          arr[indx].item = arr[indx].temp_item;
          arr[indx].module_name = arr[indx].temp_module_name;
          arr[indx].module_label = arr[indx].temp_module_label;
          arr[indx].breadcrumbArr=arr[indx].temp_breadcrumbArr;
          arr[indx].subData=arr[indx].temp_subData;
          arr[indx].showL1Options=arr[indx].temp_showL1Options;
      }

    }
    
    this.setState({
      locationArr: arr,
    });
  }
   handleParentChange = (e, pID, title) => {

    // setTempParentId(pID);
    // setParentTempName(title);
    // setOpenMobileAppDropdown(!openMobileAppDropdown)
   
  };
  returnSelectedItem = (item,arr)=>{
    let name = "";

   if(!item){
     name = "";
   }else if(item==="all"){
    name = "- All";
   }else{

   function recurs(arr1){
    arr1 && arr1.map((res)=>{
    if(parseInt(localStorage.getItem("website_technology"))===3){
      if(res._id==item){
        name = "- " + res.title;
      }
    }else{
      if(res.term_id){
        if(res.term_id==item){
          name = "- " + (res.title? res.title.rendered :res.name);
        }
      }else{
        if(res.id==item){
          name = "- " + (res.title? res.title.rendered :res.name);
        }
      }

    }

    if(parseInt(localStorage.getItem("website_technology"))===3){
      if ( res.child !== undefined && res.child.length > 0) {
        // if parent has childrens
        recurs(res.child);
      }
    }else{
      if ( res.children !== undefined && res.children.length > 0) {
        // if parent has childrens
        recurs(res.children);
      }
    }

   })
   }
   recurs(arr);
  }

  if(!name){
    name=item?`- ${item}`:"";
   }

   return this.convertHTMLEntity(name);
  }

  handleMobileDevice = (e,device) => {
    this.setState({
      deviceTypeForPreview : device,
    })
  }

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
  }


  convertHTMLEntity = (text) => {
    const span = document.createElement("span");

    if (text !== undefined) {
      return text.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
        span.innerHTML = entity;
        return span.innerText;
      });
    }
  };

  render() {
    return (
      <>
        <Header customclass={"header-responsive-new"} />
        <section className="newAdvertisment-page">
          <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand}/>
          <div
            className={`right-panel-gap ${
              localStorage.getItem("appSideBarCollapse") !== undefined &&
              localStorage.getItem("appSideBarCollapse") !== null &&
              localStorage.getItem("appSideBarCollapse") === "true"
                ? " right-panel-gap-expand"
                : ""
            }`}
          >
            <div className="newAdvertisment-tophead edit-newAdvertisment-tophead">
              <div>
                <h1 className="newAdvertisment-head">
                  {this.state.adId !== undefined &&
                  this.state.adId !== "undefined" &&
                  this.state.adId !== null &&
                  this.state.adId !== "null" &&
                  this.state.adId !== "create"
                    ? "Edit ad"
                    : "New ad"}
                </h1>
              </div>
              <div className="newAdvertisment-topheadright" style={{marginTop: "-8px"}}>
              {this.state.updatedAt &&
                   
                (
                    <h3 className="ad-save-date-web 1">
                      {" "}
                      Updated{" "}
                      {moment(this.state.updatedAt).format("D MMMM YYYY, HH:mm")}
                    </h3>
                  )}

              {
                   this.state.adType &&  this.state.adType !=="custom" && 
                <div className="ad-header-type">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <g
                      id="Group_5542"
                      data-name="Group 5542"
                      transform="translate(-137.686 -1407)"
                    >
                      <g
                        id="Group_5389"
                        data-name="Group 5389"
                        transform="translate(137.686 1407)"
                      >
                        <path
                          id="Path_9215"
                          data-name="Path 9215"
                          d="M92,8.034C92.179,8,92.884,8,93.052,8A12.172,12.172,0,0,1,104.89,19.491v9.434A3.141,3.141,0,0,1,101.7,32a3.489,3.489,0,0,1-2.975-3.068V19.5a6.07,6.07,0,0,0-5.743-5.5,6.661,6.661,0,0,0-.982.014"
                          transform="translate(-80.89 -7.998)"
                          fill="#fbbc04"
                        />
                        <path
                          id="Path_9216"
                          data-name="Path 9216"
                          d="M146.162,143.083A3.081,3.081,0,1,1,143.08,140,3.083,3.083,0,0,1,146.162,143.083Z"
                          transform="translate(-122.165 -122.165)"
                          fill="#4285f4"
                        />
                        <path
                          id="Path_9217"
                          data-name="Path 9217"
                          d="M19.946,32.1a3.126,3.126,0,0,0,3.2-3.05,3.042,3.042,0,0,0-3.23-2.908h-.034a6.072,6.072,0,0,1-5.666-6.368l0-.057a6.1,6.1,0,0,1,5.731-5.508,3.019,3.019,0,0,0,3.2-2.916,3.128,3.128,0,0,0-3.2-3.051c-.071,0-.143,0-.214,0C14.378,8.42,8.073,12.754,8.01,20.121v.045c0,7.476,6.738,11.93,11.76,11.93h.177Z"
                          transform="translate(-8.01 -8.203)"
                          fill="#ea4335"
                        />
                      </g>
                    </g>
                  </svg>
            
                  
                  <p
                    className="ad-header-type-name"
                  >
                    {" "}
                    Google Admob
                    <a 
                    href="https://admob.google.com/home/"
                    target="_blank"
                    >

                   
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="11.2"
                      height="11.2"
                      viewBox="0 0 11.2 11.2"
                    >
                      <g
                        id="Group_9160"
                        data-name="Group 9160"
                        transform="translate(-695.4 -246.4)"
                      >
                        <path
                          id="Path_10239"
                          data-name="Path 10239"
                          d="M4,1H1A1,1,0,0,0,0,2V9a1,1,0,0,0,1,1H8A1,1,0,0,0,9,9V6"
                          transform="translate(696 247)"
                          fill="none"
                          stroke="#c8cedb"
                          stroke-width="1.2"
                        />
                        <path
                          id="Path_10240"
                          data-name="Path 10240"
                          d="M4,4V0H0"
                          transform="translate(702 247)"
                          fill="none"
                          stroke="#c8cedb"
                          stroke-width="1.2"
                        />
                        <path
                          id="Path_10241"
                          data-name="Path 10241"
                          d="M2144-23303l-6,6"
                          transform="translate(-1438 23550)"
                          fill="none"
                          stroke="#c8cedb"
                          stroke-width="1.2"
                        />
                      </g>
                    </svg>
                    </a>
                  </p>  
                </div> }

               {this.state.spinner === false &&
                <Link to={"/app/ads"}>
                  <div className="notification-setting " style={{marginRight:window.matchMedia("(max-width: 1200px)").matches?
                  "0px":"20px",
                  marginLeft:window.matchMedia("(max-width: 1200px)").matches?
                  "20px":"0px",
                }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>
                </div>
               </Link>
               }

                {this.state.is_enable_screen === 0 ||
                this.state.plan === "preview" ||
                !this.state.plan ? (
                  <></>
                ) : (
                window.matchMedia("(min-width: 1200px)").matches && (
                  <>
                    <div
                      className={
                        ( this.state.adType || this.state.adName.trim() ||
                        this.state.androidAdUnitId.trim() ||
                        this.state.iosAdUnitId.trim()
                        )
                          ? "newAdvertisment-savebtn"
                          : " newAdvertisment-savebtn ad-diasable-save-btn"
                      }
                      onClick={(e) =>
                              ( this.state.adType || this.state.adName.trim() ||
                                this.state.androidAdUnitId.trim() ||
                                this.state.iosAdUnitId.trim()
                               )&& this.state.isLoader2 !=="d-block" && this.state.isLoader!=="d-block"
                          ? this.handleSubmitData(e, "draft")
                          : null
                      }
                      style={{
                        border:
                          "1px solid " +
                          localStorage.getItem("button_background_color"),
                        color: localStorage.getItem("button_text_color"),
                        "--custom_color4":
                          localStorage.getItem("agencyid") == 1
                            ? "white"
                            : localStorage.getItem("button_hover_txtcolor"),
                        "--custom_color6":
                          localStorage.getItem("agencyid") == 1
                            ? "#3064f9"
                            : localStorage.getItem("button_hover_bgcolor"),
                      }}
                    >
                      <div className={this.state.isLoader2}>
                        <div className="new-blue-loader"></div>
                      </div>
                      <div 
                      style={{
                         color:( this.state.adType || this.state.adName.trim() ||
                                this.state.androidAdUnitId.trim() ||
                                this.state.iosAdUnitId.trim()
                               )
                           ? localStorage.getItem("button_background_color")
                           : null ,
                      }}
                      >{this.state.submitval2}</div>
                    </div>

                    <button
                      className={
                        this.state.adType &&
                        (this.state.adType === "custom"
                          ? this.state.androidAdScript.trim() ||
                            this.state.iosAdScript.trim()
                          : this.state.androidAdUnitId.trim() ||
                            this.state.iosAdUnitId.trim())
                          ? "newAdvertisment-invitevtb"
                          : "ad-diasable-btn"
                      }
                      onClick={(e) =>
                        this.state.adType &&
                        (this.state.adType === "custom"
                          ? this.state.androidAdScript.trim() ||
                            this.state.iosAdScript.trim()
                          : this.state.androidAdUnitId.trim() ||
                            this.state.iosAdUnitId.trim()) && this.state.isLoader2 !=="d-block" && this.state.isLoader!=="d-block"
                          ? this.handleSubmitData(e, "publish")
                          : null
                      }
                      disabled={   this.state.adType &&
                        (this.state.adType === "custom"
                          ? this.state.androidAdScript.trim() ||
                            this.state.iosAdScript.trim()
                          : this.state.androidAdUnitId.trim() ||
                            this.state.iosAdUnitId.trim())
                          ? false
                          : true}
                      style={{
                      backgroundColor:localStorage.getItem('button_background_color'), width: "100px",
                      // border:'1px solid '+localStorage.getItem('button_background_color'),
                      color:localStorage.getItem('button_text_color'),
                      "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                      "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                      "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')
                    }}
                    >
                      <div className={this.state.isLoader}>
                        <div className="new-loader"></div>
                      </div>
                      <div>
                        {this.state.statusOfPost === "publish"
                          ? this.state.isLoader === "d-none"
                            ? "Save"
                            : ""
                          : this.state.submitval}
                      </div>
                    </button>
                  </>
                )
                )}
              </div>
            </div>

            <div className="newAdvertisment-maindiv">
              {this.state.spinner ? (
                <div className="newspinner">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    style={{
                      margin: "auto",
                      background: "rgb(241, 242, 243,0)",
                      display: "block",
                      shaperendering: "auto",
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                    width="200px"
                    height="200px"
                    viewBox="0 0 100 100"
                    preserveAspectRatio="xMidYMid"
                  >
                    <g
                      transform="translate(20 50)"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <circle
                        cx="0"
                        cy="0"
                        r="6"
                        fill="#e6261f"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <animateTransform
                          attributeName="transform"
                          type="scale"
                          begin="-0.375s"
                          calcMode="spline"
                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                          values="0;1;0"
                          keyTimes="0;0.5;1"
                          dur="1s"
                          repeatCount="indefinite"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        ></animateTransform>
                      </circle>
                    </g>
                    <g
                      transform="translate(40 50)"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <circle
                        cx="0"
                        cy="0"
                        r="6"
                        fill="#f7d038"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <animateTransform
                          attributeName="transform"
                          type="scale"
                          begin="-0.25s"
                          calcMode="spline"
                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                          values="0;1;0"
                          keyTimes="0;0.5;1"
                          dur="1s"
                          repeatCount="indefinite"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        ></animateTransform>
                      </circle>
                    </g>
                    <g
                      transform="translate(60 50)"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <circle
                        cx="0"
                        cy="0"
                        r="6"
                        fill="#49da9a"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <animateTransform
                          attributeName="transform"
                          type="scale"
                          begin="-0.125s"
                          calcMode="spline"
                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                          values="0;1;0"
                          keyTimes="0;0.5;1"
                          dur="1s"
                          repeatCount="indefinite"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        ></animateTransform>
                      </circle>
                    </g>
                    <g
                      transform="translate(80 50)"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <circle
                        cx="0"
                        cy="0"
                        r="6"
                        fill="#4355db"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <animateTransform
                          attributeName="transform"
                          type="scale"
                          begin="0s"
                          calcMode="spline"
                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                          values="0;1;0"
                          keyTimes="0;0.5;1"
                          dur="1s"
                          repeatCount="indefinite"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        ></animateTransform>
                      </circle>
                    </g>
                  </svg>
                </div>
              ) : (
                <>
                  <div className="create-ad-page">
                    <div className="create-new-add-left">
                      <div className="create-new-add-left-top">
                        {this.state.adId !== undefined &&
                        this.state.adId !== "undefined" &&
                        this.state.adId !== null &&
                        this.state.adId !== "null" &&
                        this.state.adId !== "create" ? (
                          <h3>Edit ad</h3>
                        ) : (
                          <h3>Create new ad</h3>
                        )}

                        <p>
                        Maximize your app's revenue with strategic ad creation.
                        </p>
                      </div>
                      <div className="advertisment-divmain">
                        <div className="advertisment-labeldiv">
                          <label>
                            Name
                            <p className="productsetting-tooltip">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <g
                                  id="Group_37563"
                                  data-name="Group 37563"
                                  transform="translate(-1727 -19.132)"
                                >
                                  <g
                                    id="Ellipse_7706"
                                    data-name="Ellipse 7706"
                                    transform="translate(1727 19.132)"
                                    fill="#fff"
                                    stroke="#bcc1ce"
                                    stroke-width="1.2"
                                  >
                                    <circle cx="7" cy="7" r="7" stroke="none" />
                                    <circle cx="7" cy="7" r="6.4" fill="none" />
                                  </g>
                                  <path
                                    id="Path_98061"
                                    data-name="Path 98061"
                                    d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                    transform="translate(1725.126 17.539)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-miterlimit="10"
                                    stroke-width="1.2"
                                  />
                                  <line
                                    id="Line_343"
                                    data-name="Line 343"
                                    y2="1.2"
                                    transform="translate(1734 29.132)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-miterlimit="10"
                                    stroke-width="1.2"
                                  />
                                </g>
                              </svg>
                              <p className="productsetting-tooltipsubheader setting-managed">
                               Give your ad a name.
                              </p>
                            </p>
                          </label>
                          <input
                            maxLength={200}
                            value={this.state.adName}
                            onChange={(e) => this.handleAdNameChange(e)}
                          />
                        </div>

                        <div className="advertisment-labeldiv">
                          <label>
                            Type{" "}
                            <p className="productsetting-tooltip">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <g
                                  id="Group_37563"
                                  data-name="Group 37563"
                                  transform="translate(-1727 -19.132)"
                                >
                                  <g
                                    id="Ellipse_7706"
                                    data-name="Ellipse 7706"
                                    transform="translate(1727 19.132)"
                                    fill="#fff"
                                    stroke="#bcc1ce"
                                    stroke-width="1.2"
                                  >
                                    <circle cx="7" cy="7" r="7" stroke="none" />
                                    <circle cx="7" cy="7" r="6.4" fill="none" />
                                  </g>
                                  <path
                                    id="Path_98061"
                                    data-name="Path 98061"
                                    d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                    transform="translate(1725.126 17.539)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-miterlimit="10"
                                    stroke-width="1.2"
                                  />
                                  <line
                                    id="Line_343"
                                    data-name="Line 343"
                                    y2="1.2"
                                    transform="translate(1734 29.132)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-miterlimit="10"
                                    stroke-width="1.2"
                                  />
                                </g>
                              </svg>
                              <p className="productsetting-tooltipsubheader setting-managed">
                              Select the type of ad you want to create.
                              </p>
                            </p>
                          </label>
                          <div className="advertisment-labelselect" onClick={(e) => this.handleOpenAdsPopUp(e)}>
                            <p>
                              {this.state.adType
                                ? this.state.adType === "banner"
                                  ? <span className="d-flex"><svg style={{marginRight: "6px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                  <g id="Group_41750" data-name="Group 41750" transform="translate(-137.686 -1407)">
                                    <g id="Group_5389" data-name="Group 5389" transform="translate(137.686 1407)">
                                      <path id="Path_9215" data-name="Path 9215" d="M92,8.022C92.12,8,92.589,8,92.7,8a8.114,8.114,0,0,1,7.892,7.662v6.289A2.094,2.094,0,0,1,98.467,24a2.326,2.326,0,0,1-1.983-2.045V15.664a4.047,4.047,0,0,0-3.829-3.669A4.441,4.441,0,0,0,92,12" transform="translate(-84.594 -7.998)" fill="#fbbc04"/>
                                      <path id="Path_9216" data-name="Path 9216" d="M144.108,142.055A2.054,2.054,0,1,1,142.053,140,2.055,2.055,0,0,1,144.108,142.055Z" transform="translate(-128.11 -128.11)" fill="#4285f4"/>
                                      <path id="Path_9217" data-name="Path 9217" d="M15.967,24.143A2.084,2.084,0,0,0,18.1,22.11a2.028,2.028,0,0,0-2.153-1.939h-.022a4.048,4.048,0,0,1-3.777-4.246l0-.038a4.07,4.07,0,0,1,3.82-3.672A2.012,2.012,0,0,0,18.1,10.272a2.085,2.085,0,0,0-2.132-2.034c-.048,0-.1,0-.143,0A8.106,8.106,0,0,0,8.01,16.159v.03a8.136,8.136,0,0,0,7.84,7.953h.118Z" transform="translate(-8.01 -8.214)" fill="#ea4335"/>
                                    </g>
                                  </g>
                                </svg>Banner ad</span>
                                  : this.state.adType === "interstitial"
                                  ? <span className="d-flex"><svg style={{marginRight: "6px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                  <g id="Group_41750" data-name="Group 41750" transform="translate(-137.686 -1407)">
                                    <g id="Group_5389" data-name="Group 5389" transform="translate(137.686 1407)">
                                      <path id="Path_9215" data-name="Path 9215" d="M92,8.022C92.12,8,92.589,8,92.7,8a8.114,8.114,0,0,1,7.892,7.662v6.289A2.094,2.094,0,0,1,98.467,24a2.326,2.326,0,0,1-1.983-2.045V15.664a4.047,4.047,0,0,0-3.829-3.669A4.441,4.441,0,0,0,92,12" transform="translate(-84.594 -7.998)" fill="#fbbc04"/>
                                      <path id="Path_9216" data-name="Path 9216" d="M144.108,142.055A2.054,2.054,0,1,1,142.053,140,2.055,2.055,0,0,1,144.108,142.055Z" transform="translate(-128.11 -128.11)" fill="#4285f4"/>
                                      <path id="Path_9217" data-name="Path 9217" d="M15.967,24.143A2.084,2.084,0,0,0,18.1,22.11a2.028,2.028,0,0,0-2.153-1.939h-.022a4.048,4.048,0,0,1-3.777-4.246l0-.038a4.07,4.07,0,0,1,3.82-3.672A2.012,2.012,0,0,0,18.1,10.272a2.085,2.085,0,0,0-2.132-2.034c-.048,0-.1,0-.143,0A8.106,8.106,0,0,0,8.01,16.159v.03a8.136,8.136,0,0,0,7.84,7.953h.118Z" transform="translate(-8.01 -8.214)" fill="#ea4335"/>
                                    </g>
                                  </g>
                                </svg>Interstitial ad</span>
                                  : this.state.adType === "custom"
                                  ? <span className="d-flex">
                                   <svg style={{marginRight: "6px"}} xmlns="http://www.w3.org/2000/svg" width="24" height="19" viewBox="0 0 24 19">
  <text id="_" data-name="&lt;/&gt;" transform="translate(12 14)" fill="#3064f9" font-size="13" font-family="Axiforma-Bold, Axiforma" font-weight="700"><tspan x="-11.512" y="0">&lt;/&gt;</tspan></text>
</svg>Custom Ad</span>
                                  : <span className="d-flex"><svg style={{marginRight: "6px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                  <g id="Group_41750" data-name="Group 41750" transform="translate(-137.686 -1407)">
                                    <g id="Group_5389" data-name="Group 5389" transform="translate(137.686 1407)">
                                      <path id="Path_9215" data-name="Path 9215" d="M92,8.022C92.12,8,92.589,8,92.7,8a8.114,8.114,0,0,1,7.892,7.662v6.289A2.094,2.094,0,0,1,98.467,24a2.326,2.326,0,0,1-1.983-2.045V15.664a4.047,4.047,0,0,0-3.829-3.669A4.441,4.441,0,0,0,92,12" transform="translate(-84.594 -7.998)" fill="#fbbc04"/>
                                      <path id="Path_9216" data-name="Path 9216" d="M144.108,142.055A2.054,2.054,0,1,1,142.053,140,2.055,2.055,0,0,1,144.108,142.055Z" transform="translate(-128.11 -128.11)" fill="#4285f4"/>
                                      <path id="Path_9217" data-name="Path 9217" d="M15.967,24.143A2.084,2.084,0,0,0,18.1,22.11a2.028,2.028,0,0,0-2.153-1.939h-.022a4.048,4.048,0,0,1-3.777-4.246l0-.038a4.07,4.07,0,0,1,3.82-3.672A2.012,2.012,0,0,0,18.1,10.272a2.085,2.085,0,0,0-2.132-2.034c-.048,0-.1,0-.143,0A8.106,8.106,0,0,0,8.01,16.159v.03a8.136,8.136,0,0,0,7.84,7.953h.118Z" transform="translate(-8.01 -8.214)" fill="#ea4335"/>
                                    </g>
                                  </g>
                                </svg>App open ad</span>
                                : "-"}
                            </p>
                            <p className="ads-select-ad-text"  onClick={(e) => this.handleOpenAdsPopUp(e)}>
                              {this.state.adType ? "Change" : "Select"}
                            </p>
                          </div>
                        </div>
                        {this.state.adType === "custom" && (
                        <div className="advertisment-labeldiv">
                        <label>Ad size<p className="productsetting-tooltip">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <g
                                  id="Group_37563"
                                  data-name="Group 37563"
                                  transform="translate(-1727 -19.132)"
                                >
                                  <g
                                    id="Ellipse_7706"
                                    data-name="Ellipse 7706"
                                    transform="translate(1727 19.132)"
                                    fill="#fff"
                                    stroke="#bcc1ce"
                                    stroke-width="1.2"
                                  >
                                    <circle cx="7" cy="7" r="7" stroke="none" />
                                    <circle cx="7" cy="7" r="6.4" fill="none" />
                                  </g>
                                  <path
                                    id="Path_98061"
                                    data-name="Path 98061"
                                    d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                    transform="translate(1725.126 17.539)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-miterlimit="10"
                                    stroke-width="1.2"
                                  />
                                  <line
                                    id="Line_343"
                                    data-name="Line 343"
                                    y2="1.2"
                                    transform="translate(1734 29.132)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-miterlimit="10"
                                    stroke-width="1.2"
                                  />
                                </g>
                              </svg>
                              <p className="productsetting-tooltipsubheader setting-managed" style={{zIndex: "3"}}>
                              Enter the width and height in the fields below to customize the dimensions of your ad. Note that the width you specify will determine the aspect ratio of the ad size while the ad will always appear in full width.
                              </p>
                            </p></label>
                            <div className="advertisment-custom-size">
                            <div className="advertisment-custom-size-div" style={{
                              position:"relative"
                            }}>
                              <p className="ad-height-text-right">Width</p>
                            <input
                            
                              type="text"
                              id="spacer-range"
                              min="1"
                              // max="1000"
                              step="1"
                              value={this.state.custom_ad_width}
                              onChange={(e) => {
                                this.handleCustomAdWidth(e);
                              }}
                            />
                          </div>
                          <div className="advertisment-custom-size-div" style={{
                              position:"relative"
                            }}>
                              <p className="ad-height-text-right">Height</p>
                            <input
                            
                              type="text"
                              id="spacer-range"
                              min="1"
                              // max="1000"
                              step="1"
                              value={this.state.custom_ad_height}
                              onChange={(e) => {
                                this.handleCustomAdHeight(e);
                              }}
                            />
                          </div>
                            {/* <ReactSelect
                              options={
                                this.state
                                  .optionsForRatio
                              }
                              onChange={(ratio) =>
                                this.handleRatioSelection(
                                  ratio
                                  
                                )
                              }
                              required={true}
                              classNamePrefix="profile-role-dropdown"
                              value={{ label: this.state.adRatio }}
                              // value={this.state.optionsForRatio.filter(
                              //   function (option) {
                              //     return (
                              //       option.value === 
                                    
                              //     );
                              //   }
                              // )}
                              placeholder="Select...."
                              isSearchable={false}
                              isClearable={false}
                                                  /> */}
                            </div>
                        </div>)}
                        <div className="advertisment-labeldiv">
                          {this.state.adType !== "custom" && (
                            <>
                           <label>Ad unit ID (Android)<p className="productsetting-tooltip">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <g
                                  id="Group_37563"
                                  data-name="Group 37563"
                                  transform="translate(-1727 -19.132)"
                                >
                                  <g
                                    id="Ellipse_7706"
                                    data-name="Ellipse 7706"
                                    transform="translate(1727 19.132)"
                                    fill="#fff"
                                    stroke="#bcc1ce"
                                    stroke-width="1.2"
                                  >
                                    <circle cx="7" cy="7" r="7" stroke="none" />
                                    <circle cx="7" cy="7" r="6.4" fill="none" />
                                  </g>
                                  <path
                                    id="Path_98061"
                                    data-name="Path 98061"
                                    d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                    transform="translate(1725.126 17.539)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-miterlimit="10"
                                    stroke-width="1.2"
                                  />
                                  <line
                                    id="Line_343"
                                    data-name="Line 343"
                                    y2="1.2"
                                    transform="translate(1734 29.132)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-miterlimit="10"
                                    stroke-width="1.2"
                                  />
                                </g>
                              </svg>
                              <p className="productsetting-tooltipsubheader setting-managed">
                              Enter the Ad unit ID created for Android to integrate AdMob ads within your app. You will find this in your Google AdMob account
                              </p>
                            </p></label>
                              <input
                                placeholder="ca-app-pub-39402560999425446300978111"
                                value={this.state.androidAdUnitId}
                                onChange={(e) => this.handleAndroidAdUnitId(e)}
                              />
                            </>
                          )}

                          {this.state.adType === "custom" && (
                            <>
                              <label>Android ad script<p className="productsetting-tooltip">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <g
                                  id="Group_37563"
                                  data-name="Group 37563"
                                  transform="translate(-1727 -19.132)"
                                >
                                  <g
                                    id="Ellipse_7706"
                                    data-name="Ellipse 7706"
                                    transform="translate(1727 19.132)"
                                    fill="#fff"
                                    stroke="#bcc1ce"
                                    stroke-width="1.2"
                                  >
                                    <circle cx="7" cy="7" r="7" stroke="none" />
                                    <circle cx="7" cy="7" r="6.4" fill="none" />
                                  </g>
                                  <path
                                    id="Path_98061"
                                    data-name="Path 98061"
                                    d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                    transform="translate(1725.126 17.539)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-miterlimit="10"
                                    stroke-width="1.2"
                                  />
                                  <line
                                    id="Line_343"
                                    data-name="Line 343"
                                    y2="1.2"
                                    transform="translate(1734 29.132)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-miterlimit="10"
                                    stroke-width="1.2"
                                  />
                                </g>
                              </svg>
                              <p className="productsetting-tooltipsubheader setting-managed">
                              Paste the ad script obtained from your ad platform.
                              </p>
                            </p></label>
                              <CodeMirror
                                value={this.state.androidAdScript}
                                height="200px"
                                theme="dark"
                                onChange={(e) => this.handleAndroidAdScript(e)}
                              />
                            </>
                          )}
                        </div>
                        <div className="advertisment-labeldiv">
                          {this.state.adType !== "custom" && (
                            <>
                           <label>Ad unit ID (iOS)<p className="productsetting-tooltip">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <g
                                  id="Group_37563"
                                  data-name="Group 37563"
                                  transform="translate(-1727 -19.132)"
                                >
                                  <g
                                    id="Ellipse_7706"
                                    data-name="Ellipse 7706"
                                    transform="translate(1727 19.132)"
                                    fill="#fff"
                                    stroke="#bcc1ce"
                                    stroke-width="1.2"
                                  >
                                    <circle cx="7" cy="7" r="7" stroke="none" />
                                    <circle cx="7" cy="7" r="6.4" fill="none" />
                                  </g>
                                  <path
                                    id="Path_98061"
                                    data-name="Path 98061"
                                    d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                    transform="translate(1725.126 17.539)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-miterlimit="10"
                                    stroke-width="1.2"
                                  />
                                  <line
                                    id="Line_343"
                                    data-name="Line 343"
                                    y2="1.2"
                                    transform="translate(1734 29.132)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-miterlimit="10"
                                    stroke-width="1.2"
                                  />
                                </g>
                              </svg>
                              <p className="productsetting-tooltipsubheader setting-managed">
                              Enter the Ad unit ID created for iOS to integrate AdMob ads within your app. You will find this in your Google AdMob account.
                              </p>
                            </p></label>
                              <input
                                placeholder="ca-app-pub-39402560999425446300978111"
                                value={this.state.iosAdUnitId}
                                onChange={(e) => this.handleIosAdUnitId(e)}
                              />
                            </>
                          )}
                          {this.state.adType === "custom" && (
                            <>
                              <label>iOS script<p className="productsetting-tooltip">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                              >
                                <g
                                  id="Group_37563"
                                  data-name="Group 37563"
                                  transform="translate(-1727 -19.132)"
                                >
                                  <g
                                    id="Ellipse_7706"
                                    data-name="Ellipse 7706"
                                    transform="translate(1727 19.132)"
                                    fill="#fff"
                                    stroke="#bcc1ce"
                                    stroke-width="1.2"
                                  >
                                    <circle cx="7" cy="7" r="7" stroke="none" />
                                    <circle cx="7" cy="7" r="6.4" fill="none" />
                                  </g>
                                  <path
                                    id="Path_98061"
                                    data-name="Path 98061"
                                    d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                    transform="translate(1725.126 17.539)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-miterlimit="10"
                                    stroke-width="1.2"
                                  />
                                  <line
                                    id="Line_343"
                                    data-name="Line 343"
                                    y2="1.2"
                                    transform="translate(1734 29.132)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-miterlimit="10"
                                    stroke-width="1.2"
                                  />
                                </g>
                              </svg>
                              <p className="productsetting-tooltipsubheader setting-managed">
                              Paste the ad script obtained from your ad platform.
                            </p></p></label>
                              <CodeMirror
                                value={this.state.iosAdScript}
                                height="200px"
                                theme="dark"
                                onChange={(e) => this.handleIosAdScript(e)}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      {this.state.adType !== "app_open" &&
                        this.state.adType && (
                          <div className="new-ad-locationdiv">
                            <div
                              className="new-ad-locationdiv-head"
                             
                              // onClick={(e) => {
                              //   this.setState({
                              //     openLocationInnerFeatures:
                              //       !this.state.openLocationInnerFeatures,
                              //   });
                              // }}
                            >
                              <p>Placement <p class="productsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p class="productsetting-tooltipsubheader setting-managed">Customize the positioning of your ad within your app to optimize visibility. Use the options below to tailor the ad's location, sub-location, and alignment based on your preferences.</p></p></p>
                              {/* <div>
                                {this.state.openLocationInnerFeatures ? (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13.061"
                                    height="7.592"
                                    viewBox="0 0 13.061 7.592"
                                  >
                                    <path
                                      id="Path_81302"
                                      data-name="Path 81302"
                                      d="M-12935-18908l6-6,6,6"
                                      transform="translate(12935.531 18915.064)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    />
                                  </svg>
                                ) : (
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="13.061"
                                    height="7.592"
                                    viewBox="0 0 13.061 7.592"
                                  >
                                    <path
                                      id="Path_81302"
                                      data-name="Path 81302"
                                      d="M-12935-18914l6,6,6-6"
                                      transform="translate(12935.531 18914.535)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    />
                                  </svg>
                                )}
                              </div> */}
                            </div>
                            {/* {this.state.openLocationInnerFeatures && ( */}
                              <div>
                                {this.state.locationArr &&
                                  this.state.locationArr.map((res, indx) => {
                                    return (
                                      <>
                                        <div className="new-ad-dropdowndiv">
                                          <div>
                                            <label>Location <p class="productsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p class="productsetting-tooltipsubheader setting-managed">Choose the primary section where you want your ad to appear.</p></p></label>
                                            <div className="ad-delete-flexdiv">
                                            <div className="advertisment-parent-input-popup" onClick={()=>this.handleOpenSelectionPopUp("open",indx)}>
                                            <p className="advertisment-popup-data-name">{res.module_label} {this.returnSelectedItem(res.item,res.subdropdownData)}</p>
                                            <svg style={{minWidth: "20px"}} xmlns="http://www.w3.org/2000/svg" width="20" height="19" viewBox="0 0 20 19">
  <g id="Group_42666" data-name="Group 42666" transform="translate(-1005 -1029)">
    <g id="Rectangle_31915" data-name="Rectangle 31915" transform="translate(1005 1029)" fill="#fff" stroke="#bcc1ce" stroke-width="1">
      <rect width="20" height="19" rx="2" stroke="none"/>
      <rect x="0.5" y="0.5" width="19" height="18" rx="1.5" fill="none"/>
    </g>
    <path id="Path_119031" data-name="Path 119031" d="M15578.713-3593.554h2" transform="translate(-14569.713 4628.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
    <path id="Path_119032" data-name="Path 119032" d="M15578.713-3593.554h2" transform="translate(-14569.713 4632.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
    <path id="Path_119033" data-name="Path 119033" d="M15578.713-3593.554h2" transform="translate(-14569.713 4636.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
    <path id="Path_119034" data-name="Path 119034" d="M15578.711-3593.554h8" transform="translate(-14565.713 4628.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
    <path id="Path_119035" data-name="Path 119035" d="M15578.711-3593.554h8" transform="translate(-14565.713 4632.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
    <path id="Path_119036" data-name="Path 119036" d="M15578.711-3593.554h8" transform="translate(-14565.713 4636.054)" fill="none" stroke="#bcc1ce" stroke-width="1"/>
  </g>
</svg>
                                            </div>
                                            {this.state.locationArr.length >
                                                1 && (
                                                <div
                                                  className="ad-delete-btn"
                                                  onClick={(e) =>
                                                    this.handleDeleteLocation(
                                                      e,
                                                      indx
                                                    )
                                                  }
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="15"
                                                    height="14.999"
                                                    viewBox="0 0 15 14.999"
                                                  >
                                                    <g
                                                      id="Group_41679"
                                                      data-name="Group 41679"
                                                      transform="translate(-15 -15)"
                                                    >
                                                      <g
                                                        id="Group_3246"
                                                        data-name="Group 3246"
                                                        transform="translate(15 15.5)"
                                                      >
                                                        <path
                                                          id="Rectangle_2307"
                                                          data-name="Rectangle 2307"
                                                          d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z"
                                                          transform="translate(1.5 1.999)"
                                                          fill="none"
                                                          stroke="#bcc1ce"
                                                          stroke-miterlimit="10"
                                                          stroke-width="1"
                                                        />
                                                        <path
                                                          id="Rectangle_2308"
                                                          data-name="Rectangle 2308"
                                                          d="M1,0H4A1,1,0,0,1,5,1V2A0,0,0,0,1,5,2H0A0,0,0,0,1,0,2V1A1,1,0,0,1,1,0Z"
                                                          transform="translate(5)"
                                                          fill="none"
                                                          stroke="#bcc1ce"
                                                          stroke-miterlimit="10"
                                                          stroke-width="1"
                                                        />
                                                        <g
                                                          id="Group_3245"
                                                          data-name="Group 3245"
                                                          transform="translate(5.5 5.5)"
                                                        >
                                                          <line
                                                            id="Line_110"
                                                            data-name="Line 110"
                                                            y2="5"
                                                            transform="translate(4 0)"
                                                            fill="none"
                                                            stroke="#bcc1ce"
                                                            stroke-miterlimit="10"
                                                            stroke-width="1"
                                                          />
                                                          <line
                                                            id="Line_111"
                                                            data-name="Line 111"
                                                            y1="5"
                                                            transform="translate(0 0)"
                                                            fill="none"
                                                            stroke="#bcc1ce"
                                                            stroke-miterlimit="10"
                                                            stroke-width="1"
                                                          />
                                                        </g>
                                                        <line
                                                          id="Line_112"
                                                          data-name="Line 112"
                                                          x2="15"
                                                          transform="translate(0 1.999)"
                                                          fill="none"
                                                          stroke="#bcc1ce"
                                                          stroke-miterlimit="10"
                                                          stroke-width="1"
                                                        />
                                                      </g>
                                                    </g>
                                                  </svg>
                                                </div>
                                              )}
                                             </div> 
                                           
                                            
                                            {/* <div className="mobile-view-click ad-delete-flexdiv">
                                              <div style={{ width: "100%" }}>
                                                {parseInt(
                                                  localStorage.getItem(
                                                    "website_technology"
                                                  )
                                                ) === 2 ? (
                                                  <ReactSelect
                                                    options={
                                                      this.state
                                                        .optionsForwebToApp
                                                    }
                                                    onChange={(val) =>
                                                      this.handleParentSelection(
                                                        val,
                                                        indx
                                                      )
                                                    }
                                                    required={true}
                                                    classNamePrefix="profile-role-dropdown"
                                                    //  value={{ label: res.module_name }}
                                                    value={this.state.optionsForwebToApp.filter(
                                                      function (option) {
                                                        return (
                                                          option.value ===
                                                          res.module_name
                                                        );
                                                      }
                                                    )}
                                                    placeholder="Select...."
                                                    isSearchable={false}
                                                    isClearable={false}
                                                    //  isDisabled={
                                                    //    window.matchMedia("(max-width: 1200px)")
                                                    //      .matches
                                                    //      ? true
                                                    //      : false
                                                    //  }
                                                    //  styles={customStyles}
                                                  />
                                                ) : parseInt(
                                                    localStorage.getItem(
                                                      "website_technology"
                                                    )
                                                  ) === 3 ? (
                                                  <ReactSelect
                                                    options={
                                                      this.state
                                                        .optionsForCustomApp
                                                    }
                                                    onChange={(val) =>
                                                      this.handleParentSelection(
                                                        val,
                                                        indx
                                                      )
                                                    }
                                                    required={true}
                                                    classNamePrefix="profile-role-dropdown"
                                                    value={this.state.optionsForCustomApp.filter(
                                                      function (option) {
                                                        return (
                                                          option.value ===
                                                          res.module_name
                                                        );
                                                      }
                                                    )}
                                                    placeholder="Select...."
                                                    isSearchable={false}
                                                    isClearable={false}
                                                  />
                                                ) : parseInt(
                                                    localStorage.getItem(
                                                      "website_technology"
                                                    )
                                                  ) === 4 ? (
                                                  <ReactSelect
                                                    options={
                                                      this.state
                                                        .optionsForWordpressApp
                                                    }
                                                    onChange={(val) =>
                                                      this.handleParentSelection(
                                                        val,
                                                        indx
                                                      )
                                                    }
                                                    required={true}
                                                    classNamePrefix="profile-role-dropdown"
                                                    value={this.state.optionsForWordpressApp.filter(
                                                      function (option) {
                                                        return (
                                                          option.value ===
                                                          res.module_name
                                                        );
                                                      }
                                                    )}
                                                    placeholder="Select...."
                                                    isSearchable={false}
                                                    isClearable={false}
                                                  />
                                                ) : (
                                                  <ReactSelect
                                                    options={
                                                      this.state
                                                        .optionsForWooCommerceApp
                                                    }
                                                    onChange={(val) =>
                                                      this.handleParentSelection(
                                                        val,
                                                        indx
                                                      )
                                                    }
                                                    required={true}
                                                    classNamePrefix="profile-role-dropdown"
                                                    value={this.state.optionsForWooCommerceApp.filter(
                                                      function (option) {
                                                        return (
                                                          option.value ===
                                                          res.module_name
                                                        );
                                                      }
                                                    )}
                                                    placeholder="Select...."
                                                    isSearchable={false}
                                                    isClearable={false}
                                                  />
                                                )}
                                              </div>
                                              {this.state.locationArr.length >
                                                1 && (
                                                <div
                                                  className="ad-delete-btn"
                                                  onClick={(e) =>
                                                    this.handleDeleteLocation(
                                                      e,
                                                      indx
                                                    )
                                                  }
                                                >
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="15"
                                                    height="14.999"
                                                    viewBox="0 0 15 14.999"
                                                  >
                                                    <g
                                                      id="Group_41679"
                                                      data-name="Group 41679"
                                                      transform="translate(-15 -15)"
                                                    >
                                                      <g
                                                        id="Group_3246"
                                                        data-name="Group 3246"
                                                        transform="translate(15 15.5)"
                                                      >
                                                        <path
                                                          id="Rectangle_2307"
                                                          data-name="Rectangle 2307"
                                                          d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z"
                                                          transform="translate(1.5 1.999)"
                                                          fill="none"
                                                          stroke="#bcc1ce"
                                                          stroke-miterlimit="10"
                                                          stroke-width="1"
                                                        />
                                                        <path
                                                          id="Rectangle_2308"
                                                          data-name="Rectangle 2308"
                                                          d="M1,0H4A1,1,0,0,1,5,1V2A0,0,0,0,1,5,2H0A0,0,0,0,1,0,2V1A1,1,0,0,1,1,0Z"
                                                          transform="translate(5)"
                                                          fill="none"
                                                          stroke="#bcc1ce"
                                                          stroke-miterlimit="10"
                                                          stroke-width="1"
                                                        />
                                                        <g
                                                          id="Group_3245"
                                                          data-name="Group 3245"
                                                          transform="translate(5.5 5.5)"
                                                        >
                                                          <line
                                                            id="Line_110"
                                                            data-name="Line 110"
                                                            y2="5"
                                                            transform="translate(4 0)"
                                                            fill="none"
                                                            stroke="#bcc1ce"
                                                            stroke-miterlimit="10"
                                                            stroke-width="1"
                                                          />
                                                          <line
                                                            id="Line_111"
                                                            data-name="Line 111"
                                                            y1="5"
                                                            transform="translate(0 0)"
                                                            fill="none"
                                                            stroke="#bcc1ce"
                                                            stroke-miterlimit="10"
                                                            stroke-width="1"
                                                          />
                                                        </g>
                                                        <line
                                                          id="Line_112"
                                                          data-name="Line 112"
                                                          x2="15"
                                                          transform="translate(0 1.999)"
                                                          fill="none"
                                                          stroke="#bcc1ce"
                                                          stroke-miterlimit="10"
                                                          stroke-width="1"
                                                        />
                                                      </g>
                                                    </g>
                                                  </svg>
                                                </div>
                                              )}
                                            </div> */}
                                          </div>
                                          {/* {(parseInt(
                                            localStorage.getItem(
                                              "website_technology"
                                            )
                                          ) === 3 &&
                                            (res.module_name ===
                                              "page_details" ||
                                              res.module_name ===
                                                "post_details")) ||
                                          (parseInt(
                                            localStorage.getItem(
                                              "website_technology"
                                            )
                                          ) === 4 &&
                                            (res.module_name ===
                                              "page_details" ||
                                              res.module_name ===
                                                "post_details" ||
                                              res.module_name ===
                                                "product_details")) ||
                                          (parseInt(
                                            localStorage.getItem(
                                              "website_technology"
                                            )
                                          ) === 5 &&
                                            (res.module_name ===
                                              "page_details" ||
                                              res.module_name ===
                                                "post_details" ||
                                              res.module_name ===
                                                "product_details")) ? (
                                            <div className="mobile-view-click">
                                            
                                            <>

                                                <label>
                                                  {res.module_label.replace(
                                                    "details",
                                                    ""
                                                  )}
                                                </label>
                                                {res.subdropdownSpinner ? (
                                                  <div className="new-blue-loader"></div>
                                                ) : (

                                                  <></>
                                             
                                                )}
                                                {
                                                  res.subdropdownSpinner?
                                                  <ReactSelect

                                                  options={
                                                    []
                                                  }
                                                  // onChange={(val) =>
                                                  //   this.handleSubDropdownSelection(
                                                  //     val,
                                                  //     indx
                                                  //   )
                                                  // }
                                           
                                                  classNamePrefix="profile-role-dropdown"
                                                  
                                                  value={""}
                                                  placeholder=""
                                                  isSearchable={false}
                                                  isClearable={false}
                                                /> :
                                                
                                                <ReactSelect

                                                  options={
                                                    res.subdropdownData
                                                  }
                                                  onChange={(val) =>
                                                    this.handleSubDropdownSelection(
                                                      val,
                                                      indx
                                                    )
                                                  }
                                                  required={true}
                                                  classNamePrefix="profile-role-dropdown"
                                                  //  value={{ label: res.module_name }}
                                                  value={res.subdropdownData.filter(
                                                    function (option) {
                                                      return (
                                                        option.value ===
                                                        res.item
                                                      );
                                                    }
                                                  )}
                                                  placeholder=""
                                                  isSearchable={false}
                                                  isClearable={false}
                                                /> }
                                                </>
                                         
                                            </div>
                                          ) : (
                                            <></>
                                          )} */}
                                          {
                                            this.state.adType!=="interstitial"
                                          &&
                                          <div className="ad-position-div">
                                            <p>Alignment <p class="productsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p class="productsetting-tooltipsubheader setting-managed">Select from the available alignment options to position your ad effectively to suit your design preferences.</p></p></p>
                                            <div className="">
                                              <svg
                                                className={
                                                  res.ad_position === "top"
                                                    ? "selcted-ad-alignment"
                                                    : "ad-alignment"
                                                }
                                                onClick={(e) =>
                                                  this.handleAdAlignment(
                                                    e,
                                                    indx,
                                                    "top"
                                                  )
                                                }
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="14"
                                                viewBox="0 0 16 14"
                                              >
                                                <g
                                                  id="Group_38163"
                                                  data-name="Group 38163"
                                                  transform="translate(-0.004 1.227)"
                                                >
                                                  <path
                                                    id="Path_6061"
                                                    data-name="Path 6061"
                                                    d="M-14517.724-3751.961h16"
                                                    transform="translate(14517.728 3751.735)"
                                                    fill="none"
                                                    stroke={""}
                                                    stroke-width="2"
                                                  />
                                                  <rect
                                                    id="Rectangle_29209"
                                                    data-name="Rectangle 29209"
                                                    width="5"
                                                    height="12"
                                                    transform="translate(2 0.773)"
                                                    fill=""
                                                  />
                                                  <rect
                                                    id="Rectangle_29210"
                                                    data-name="Rectangle 29210"
                                                    width="5"
                                                    height="6"
                                                    transform="translate(9 0.773)"
                                                    fill=""
                                                  />
                                                </g>
                                              </svg>
                                              <svg
                                                className={
                                                  res.ad_position === "bottom"
                                                    ? "selcted-ad-alignment"
                                                    : "ad-alignment"
                                                }
                                                onClick={(e) =>
                                                  this.handleAdAlignment(
                                                    e,
                                                    indx,
                                                    "bottom"
                                                  )
                                                }
                                                id="Group_38347"
                                                data-name="Group 38347"
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="14"
                                                viewBox="0 0 16 14"
                                              >
                                                <path
                                                  id="Path_6061"
                                                  data-name="Path 6061"
                                                  d="M-14517.724-3751.961h16"
                                                  transform="translate(14517.724 3764.961)"
                                                  fill="none"
                                                  stroke={"#e3e6f1"}
                                                  stroke-width="2"
                                                />
                                                <rect
                                                  id="Rectangle_29209"
                                                  data-name="Rectangle 29209"
                                                  width="5"
                                                  height="12"
                                                  transform="translate(1.996)"
                                                  fill="#e3e6f1"
                                                />
                                                <rect
                                                  id="Rectangle_29210"
                                                  data-name="Rectangle 29210"
                                                  width="5"
                                                  height="6"
                                                  transform="translate(8.996 6)"
                                                  fill="#e3e6f1"
                                                />
                                              </svg>
                                            </div>
                                          </div> }
                                        </div>
                                        {indx !==
                                          this.state.locationArr.length - 1 && (
                                          <div className="ad-andiv">AND</div>
                                        )}
                                      </>
                                    );
                                  })}
                              </div>
                            {/* )} */}
                            <div
                              className="ad-addlocation-new"
                              onClick={(e) => this.handleAddLocation(e)}
                            >
                              + Add location
                            </div>
                          </div>
                        )}
                    </div>
                    <div className="create-new-add-right">
                      {this.state.adType === "banner" ||
                      this.state.adType === "custom" ? (
                        <div className="create-new-add-navigate">
                          <div
                            className={`create-new-add-navigate-left ${
                              this.state.activeSlide === 0
                                ? "ad-previewtopbackopacity"
                                : ""
                            }`}
                            onClick={(e) => this.handlesliderChange(e, "left")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="7.017"
                              height="11.914"
                              viewBox="0 0 7.017 11.914"
                            >
                              <path
                                id="Path_101670"
                                data-name="Path 101670"
                                d="M-14099.574-20964l-5.426,5.426,5.426,5.426"
                                transform="translate(14106.061 20964.531)"
                                fill="none"
                                stroke="#7782a1"
                                stroke-width="1.5"
                              />
                            </svg>
                          </div>

                          <div
                            className={`create-new-add-navigate-right  ${
                              this.state.activeSlide ===
                              this.state.locationArr.length - 1
                                ? "ad-previewtopbackopacity"
                                : ""
                            }`}
                            onClick={(e) => this.handlesliderChange(e, "right")}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="7.017"
                              height="11.914"
                              viewBox="0 0 7.017 11.914"
                            >
                              <path
                                id="Path_101670"
                                data-name="Path 101670"
                                d="M-14105-20964l5.426,5.426-5.426,5.426"
                                transform="translate(14105.53 20964.531)"
                                fill="none"
                                stroke="#7782a1"
                                stroke-width="1.5"
                              />
                            </svg>
                          </div>
                        </div>
                      ) : null}
                      {this.state.adType ? (
                        this.state.adType === "interstitial" ||
                        this.state.adType === "app_open" ? (
                          <div className="ad-mob-preview-frame">
                            {this.state.adType === "app_open" && (
                              <div className="first-ad">
                                <div className="first-ad-top">
                                  <div className="first-ad-top-div">
                                    <div className="first-ad-top-div-img"></div>
                                    <p>Continue to app</p>
                                  </div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="5.273"
                                    height="8.849"
                                    viewBox="0 0 5.273 8.849"
                                  >
                                    <path
                                      id="Path_108856"
                                      data-name="Path 108856"
                                      d="M5767.731-7085.358l4,4-4,4"
                                      transform="translate(-5767.307 7085.783)"
                                      fill="none"
                                      stroke="#b0b0b0"
                                      stroke-width="1.2"
                                    />
                                  </svg>
                                </div>
                                <div className="first-ad-last">
                                  <p className="ad-first-open-title">
                                    Advertisement
                                  </p>
                                  <div className="ad-first-open-text-div">
                                    <p className="ad-first-tag">Ad</p>
                                    <p className="ad-first-open-text">
                                      Google app open ad
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}

                            {this.state.adType === "interstitial" && (
                              <div className="second-ad">
                                <div className="second-ad-div-main">
                                  <div className="second-ad-div-cross">
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/cross.png")
                                          .default
                                      }
                                    />
                                  </div>
                                  <div className="second-ad-div-text">
                                    Google interstitial ad
                                  </div>
                                  <div className="second-div-ad">Ad</div>
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>
                          
                            <div className={parseInt(
                                localStorage.getItem("website_technology")
                              ) !== 3 && this.state.adType==="custom"?"" : "ad-mob-preview-frame" }
                            style={{
                              overflow:(parseInt(
                                localStorage.getItem("website_technology")
                              ) !== 3 &&this.state.adType==="custom")? "hidden":""
                            }}
                            >
                              {parseInt(
                                localStorage.getItem("website_technology")
                              ) === 3 ? (
                                <CustomAppPreview value={this} /> // Banner and Custom Ad Preview for Custom App
                              ) : (
                                this.state.adType === "custom"?
                                <CustomAdPreview value={this} /> // Custom Ad Preview for Wordpress , Woo , Web2App
                                :
                                <WordpressWooWebtoAppPreview value={this} /> // Banner Ad Preview for Wordpress , Woo , Web2App
                              )}
                            </div>
                          </div>
                        )
                      ) : (
                        <div></div>
                      )}

                          {
                            ( this.state.adType === "banner" ||
                            this.state.adType === "custom"  ||
                            this.state.adType === "interstitial"  ||
                            this.state.adType === "app_open"  
                          ) ?
                          
                           <div className="newplatswitch notificationplat">
                              <ul
                                id="myTabs"
                                className="nav nav-pills nav-justified  d-flex justify-content-center"
                                role="tablist"
                                data-tabs="tabs"
                              >
                                
                                <li 
                                style={{
                                  marginRight:"16px",
                                }} 
                                className={`android-icontochange ${this.state.deviceTypeForPreview ==="android"?" techselect":"technotselect"}`}>
                                 
                                  <svg  onClick={(e)=>this.handleMobileDevice(e,"android")} xmlns="http://www.w3.org/2000/svg" width="18.591" height="22" viewBox="0 0 18.591 22">
                                    <path id="Path_7126" data-name="Path 7126" d="M1.376,15.116A1.3,1.3,0,0,1,0,13.912V8.761A1.3,1.3,0,0,1,1.376,7.556,1.3,1.3,0,0,1,2.753,8.761v5.151A1.3,1.3,0,0,1,1.376,15.116Zm10.411,5.037,1.2,1.2a.379.379,0,0,1-.537.536l-1.48-1.478a7.024,7.024,0,0,1-3.32,0L6.175,21.889a.379.379,0,1,1-.537-.536l1.2-1.193A4.85,4.85,0,0,1,3.45,15.8c0-.023,11.706,0,11.706,0A4.843,4.843,0,0,1,11.788,20.153ZM7.582,17.178a.687.687,0,1,0,.689.687A.687.687,0,0,0,7.582,17.178Zm3.444,0a.687.687,0,1,0,.689.687A.687.687,0,0,0,11.025,17.178Zm6.19-2.062a1.3,1.3,0,0,1-1.377-1.2V8.761a1.389,1.389,0,0,1,2.753,0v5.151A1.3,1.3,0,0,1,17.216,15.116ZM3.45,6.875a2.063,2.063,0,0,1,2.059-2.06V1.205A1.3,1.3,0,0,1,6.886,0,1.3,1.3,0,0,1,8.261,1.205V4.814H10.33V1.205A1.3,1.3,0,0,1,11.706,0a1.3,1.3,0,0,1,1.376,1.205V4.814h.009a2.063,2.063,0,0,1,2.066,2.06v8.243H3.451V6.875Z" transform="translate(18.591 22) rotate(180)" fill="" />
                                  </svg>
                                </li>

                                <li 
                                 style={{
                                  marginRight:"0px",
                                }} 
                                className={`ios-icontochange ${this.state.deviceTypeForPreview ==="ios" ?" techselect":"technotselect"} `}>
                                 
                                  <svg onClick={(e)=>this.handleMobileDevice(e,"ios")} xmlns="http://www.w3.org/2000/svg" width="18.417" height="22" viewBox="0 0 18.417 22">
                                    <g id="apple-logo" transform="translate(0)">
                                      <g id="Group_1428" data-name="Group 1428" transform="translate(0 0)">
                                        <path id="Path_668" data-name="Path 668" d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z" transform="translate(-38.692 -102.378)" fill="" />
                                        <path id="Path_669" data-name="Path 669" d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z" transform="translate(-224.655 0)" fill="" />
                                      </g>
                                    </g>
                                  </svg>
                                </li>
                              </ul>
                            </div>
                            :
                            <></>
                          }
                    </div>
                    {this.state.is_enable_screen === 0 ||
                    this.state.plan === "preview" ||
                    !this.state.plan ? (
                      <></>
                    ) : (
                    window.matchMedia("(max-width: 1200px)").matches && (
                      <>
                        <button
                          className={
                            ( this.state.adType || this.state.adName.trim() ||
                                this.state.androidAdUnitId.trim() ||
                                this.state.iosAdUnitId.trim()
                               )
                              ? "create-ad-mob-save"
                              : "create-ad-mob-save-disable"
                          }
                          onClick={(e) =>
                            ( this.state.adType || this.state.adName.trim() ||
                                this.state.androidAdUnitId.trim() ||
                                this.state.iosAdUnitId.trim()
                               ) && this.state.isLoader2 !=="d-block" && this.state.isLoader!=="d-block"
                              ? this.handleSubmitData(e, "draft")
                              : null
                          }
                          style={{
                            border:
                              "1px solid " +
                              localStorage.getItem("button_background_color"),
                            color: localStorage.getItem("button_text_color"),
                            "--custom_color4":
                              localStorage.getItem("agencyid") == 1
                                ? "white"
                                : localStorage.getItem("button_hover_txtcolor"),
                            "--custom_color6":
                              localStorage.getItem("agencyid") == 1
                                ? "#3064f9"
                                : localStorage.getItem("button_hover_bgcolor"),
                          }}
                        >
                          <div className={this.state.isLoader2}>
                            <div className="new-blue-loader"></div>
                          </div>
                          <div   style={{
                         color:this.state.adType &&
                         ( this.state.adType || this.state.adName.trim() ||
                         this.state.androidAdUnitId.trim() ||
                         this.state.iosAdUnitId.trim()
                        )
                           ? localStorage.getItem("button_background_color")
                           : null ,
                      }}>{this.state.submitval2}</div>
                        </button>

                        <button
                          className={
                            this.state.adType &&
                            (this.state.adType === "custom"
                              ? this.state.androidAdScript.trim() ||
                                this.state.iosAdScript.trim()
                              : this.state.androidAdUnitId.trim() ||
                                this.state.iosAdUnitId.trim())
                              ? "create-ad-mob-publish"
                              : "create-ad-mob-publish-disable"
                          }
                          onClick={(e) =>
                            this.state.adType &&
                            (this.state.adType === "custom"
                              ? this.state.androidAdScript.trim() ||
                                this.state.iosAdScript.trim()
                              : this.state.androidAdUnitId.trim() ||
                                this.state.iosAdUnitId.trim()) && this.state.isLoader2 !=="d-block" && this.state.isLoader!=="d-block"
                              ? this.handleSubmitData(e, "publish")
                              : null
                          }
                          style={{
                            backgroundColor: localStorage.getItem(
                              "button_background_color"
                            ),
                            border:
                              "1px solid " +
                              localStorage.getItem("button_background_color"),
                            color: localStorage.getItem("button_text_color"),
                            "--custom_color5":
                              localStorage.getItem("agencyid") == 1
                                ? "#134bec"
                                : localStorage.getItem("button_hover_bgcolor"),
                            "--custom_color4":
                              localStorage.getItem("agencyid") == 1
                                ? "white"
                                : localStorage.getItem("button_hover_txtcolor"),
                            "--custom_color6":
                              localStorage.getItem("agencyid") == 1
                                ? "#3064f9"
                                : localStorage.getItem("button_hover_bgcolor"),
                          }}
                        >
                          {this.state.isLoader !== "d-none" && (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <div className="new-loader"></div>
                            </div>
                          )}
                          <div>
                            {this.state.statusOfPost === "publish"
                              ? this.state.isLoader === "d-none"
                                ? "Save"
                                : ""
                              : this.state.submitval}
                          </div>
                        </button>
                        {this.state.updatedAt && (
                                <h3 className="ad-save-date 2">
                                  {" "}
                                  Updated{" "}
                                  {moment(this.state.updatedAt).format(
                                    "D MMMM YYYY, HH:mm"
                                  )}
                                </h3>
                              )}
                      </>
                    )
                    )}
                  </div>
                </>
              )}
            </div>
          </div>

          <Modal
            className="ad-select-modal"
            isOpen={this.state.openAdvertisementPopUp}
            centered
            toggle={(e) => this.handleOpenAdsPopUp(e)}
          >
            <ModalHeader>
              <svg  onClick={(e) => this.handleOpenAdsPopUp(e)}
                xmlns="http://www.w3.org/2000/svg"
                width="20.759"
                height="15.408"
                viewBox="0 0 20.759 15.408"
              >
                <g
                  id="Group_27465"
                  data-name="Group 27465"
                  transform="translate(-19.742 -22.295)"
                >
                  <path
                    id="Path_81245"
                    data-name="Path 81245"
                    d="M-13783.922-19217h19.346"
                    transform="translate(13805.077 19247)"
                    fill="none"
                    stroke="#7782a1"
                    stroke-width="2"
                  />
                  <path
                    id="Path_82232"
                    data-name="Path 82232"
                    d="M14053.656,19255.426l-7,7,7,7"
                    transform="translate(-14025.504 -19232.424)"
                    fill="none"
                    stroke="#7782a1"
                    stroke-width="2"
                  />
                </g>
              </svg>
              <div
                className="ad-crossmodal "
                onClick={(e) => this.handleOpenAdsPopUp(e)}
                style={{
                  zIndex:"9"
                }}
              >
                <img
                  src={
                    require("../../assets/images/dashboard/cross.png").default
                  }
                />
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="">
                <div className="ad-modal-head">
                <h3>Select ad type</h3>
                  <p>
                  Select the type of ad you wish to create.
                  </p>
                </div>

                <div className="ad-type-select-main">
                  <div
                    className={`ad-select-banner-type ${this.state.adType==="banner"?"ad-selected-banner":""}`}
                    onClick={(e) => this.handleChooseAdVal(e, "banner")}
                  >
                    <div className="ad-select-tooltip">
                      <p className="productsetting-tooltip">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                        >
                          <g
                            id="Group_37563"
                            data-name="Group 37563"
                            transform="translate(-1727 -19.132)"
                          >
                            <g
                              id="Ellipse_7706"
                              data-name="Ellipse 7706"
                              transform="translate(1727 19.132)"
                              fill="#fff"
                              stroke="#bcc1ce"
                              stroke-width="1.2"
                            >
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path
                              id="Path_98061"
                              data-name="Path 98061"
                              d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                              transform="translate(1725.126 17.539)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-miterlimit="10"
                              stroke-width="1.2"
                            />
                            <line
                              id="Line_343"
                              data-name="Line 343"
                              y2="1.2"
                              transform="translate(1734 29.132)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-miterlimit="10"
                              stroke-width="1.2"
                            />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader advertisment-popup-tooltip">
                        Banner ads are rectangular advertisements that integrate within your app content. They’re the simplest ad format to implement.
                        </p>
                      </p>
                    </div>
                    <div className="modal-ad-image-text">
                      <img
                        src={
                          require("../../assets/images/dashboard/Banner-ad.png")
                            .default
                        }
                        className=""
                        alt=""
                      />
                      <div className="ad-select-title-type">
                      <h2>Banner Ad</h2>
                        <p>Google AdMob</p>
                      </div>
                    </div>
                  </div>
                  <div
                   className={`ad-select-banner-type ad-mob-margin-right  ${this.state.adType==="interstitial"?"ad-selected-banner":""}`}
                    onClick={(e) => this.handleChooseAdVal(e, "interstitial")}
                  >
                    <div className="ad-select-tooltip">
                      {" "}
                      <p className="productsetting-tooltip">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                        >
                          <g
                            id="Group_37563"
                            data-name="Group 37563"
                            transform="translate(-1727 -19.132)"
                          >
                            <g
                              id="Ellipse_7706"
                              data-name="Ellipse 7706"
                              transform="translate(1727 19.132)"
                              fill="#fff"
                              stroke="#bcc1ce"
                              stroke-width="1.2"
                            >
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path
                              id="Path_98061"
                              data-name="Path 98061"
                              d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                              transform="translate(1725.126 17.539)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-miterlimit="10"
                              stroke-width="1.2"
                            />
                            <line
                              id="Line_343"
                              data-name="Line 343"
                              y2="1.2"
                              transform="translate(1734 29.132)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-miterlimit="10"
                              stroke-width="1.2"
                            />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader advertisment-popup-tooltip">
                        Interstitial ads are full-screen advertisements that are triggered by specific user actions. They can be placed at key moments in your app flow.
                        </p>
                      </p>
                    </div>
                    <div className="modal-ad-image-text">
                      <img
                        src={
                          require("../../assets/images/dashboard/Interstitial-ad.png")
                            .default
                        }
                        className=""
                        alt=""
                      />
                      <div className="ad-select-title-type">
                      <h2>Interstitial Ad</h2>
                        <p>Google AdMob</p>
                      </div>
                    </div>
                  </div>

                  <div
                      className={`ad-select-banner-type ${this.state.adType==="app_open"?"ad-selected-banner":""}`}
                  
                    onClick={(e) => this.handleChooseAdVal(e, "app_open")}
                  >
                    <div className="ad-select-tooltip">
                      {" "}
                      <p className="productsetting-tooltip">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                        >
                          <g
                            id="Group_37563"
                            data-name="Group 37563"
                            transform="translate(-1727 -19.132)"
                          >
                            <g
                              id="Ellipse_7706"
                              data-name="Ellipse 7706"
                              transform="translate(1727 19.132)"
                              fill="#fff"
                              stroke="#bcc1ce"
                              stroke-width="1.2"
                            >
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g>
                            <path
                              id="Path_98061"
                              data-name="Path 98061"
                              d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                              transform="translate(1725.126 17.539)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-miterlimit="10"
                              stroke-width="1.2"
                            />
                            <line
                              id="Line_343"
                              data-name="Line 343"
                              y2="1.2"
                              transform="translate(1734 29.132)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-miterlimit="10"
                              stroke-width="1.2"
                            />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader advertisment-popup-tooltip">
                        App open ad units appear when the user launches your app or switches back to your app. They overlay the loading screen.
                        </p>
                      </p>
                    </div>
                    <div className="modal-ad-image-text">
                      <img
                        src={
                          require("../../assets/images/dashboard/app-open-ad.png")
                            .default
                        }
                        className=""
                        alt=""
                      />
                      <div className="ad-select-title-type">
                      <h2>App open Ad</h2>
                        <p>Google AdMob</p>
                      </div>
                    </div>
                  </div>
                  <div
                      className={`ad-select-banner-type ad-mob-margin-right  ${this.state.adType==="custom"?"ad-selected-banner":""}`}

                    onClick={(e) => this.handleChooseAdVal(e, "custom")}
                  >
                    <div className="ad-select-tooltip">
                      {" "}
                      <p className="productsetting-tooltip">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                        >
                          <g
                            id="Group_37563"
                            data-name="Group 37563"
                            transform="translate(-1727 -19.132)"
                          >
                            <g
                              id="Ellipse_7706"
                              data-name="Ellipse 7706"
                              transform="translate(1727 19.132)"
                              fill="#fff"
                              stroke="#bcc1ce"
                              stroke-width="1.2"
                            >
                              <circle cx="7" cy="7" r="7" stroke="none" />
                              <circle cx="7" cy="7" r="6.4" fill="none" />
                            </g> 
                            <path
                              id="Path_98061"
                              data-name="Path 98061"
                              d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                              transform="translate(1725.126 17.539)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-miterlimit="10"
                              stroke-width="1.2"
                            />
                            <line
                              id="Line_343"
                              data-name="Line 343"
                              y2="1.2"
                              transform="translate(1734 29.132)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-miterlimit="10"
                              stroke-width="1.2"
                            />
                          </g>
                        </svg>
                        <p className="productsetting-tooltipsubheader advertisment-popup-tooltip">
                        Integrate any third party ad platform with your app using a custom script to deliver personalised ad content to your users.
                        </p>
                      </p>
                    </div>
                    <div className="modal-ad-image-text">
                      <img
                        src={
                          require("../../assets/images/dashboard/custom-ad.png")
                            .default
                        }
                        className=""
                        alt=""
                      />
                      <div className="ad-select-title-type">
                      <h2>Custom Ad</h2>
                        <p>Custom script</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
          </Modal>
          <SingleSelectionPopUpForAdvertisements  value={this} />
        </section>
      </>
    );
  }
}
