import React from "react";
import Header from "./../Header";
import Leftpanel from "./../Leftpanel";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import moment from "moment";
import { InnerFoot } from "./../Foot";
import Slider from "./.././Reusable/Slider";
import styles from "./pricing.css";
import Accordion from 'react-bootstrap/Accordion';
import {Modal, ModalHeader, ModalBody } from "reactstrap";
import ReactHtmlParser from "react-html-parser";
import {
    GetpackageAll,
    DowngradeApi,
    SendpaymentinfotoserverApi,
    Makestrippayment,
    workspacePlanListing,
    MakestripWorkspacePayment,
    wooChangePlan
  } from "../../Api/Dashboard/DashboardApi";
import { paddleChangePlan } from "../../Api/Addons/Addonsapi";

export default class unlimitedALLINONEpricing extends React.PureComponent {
    constructor(props) {
        super(props);
        this.myRef = React.createRef(); // Create a ref object
        this.state  = {
  	checkedtab:'yearly',
    checkedmobtab:'preview',
    monthlydata: [],
    savemonthlydata:[],
    newmonthlydata: [],
    yearlydata: [],
    saveyearlydata:[],
    newyearlydata: [],
    lifetimedata: [],
    savelifetimedata:[],
    newlifetimedata: [],
    one_time: [],
    savemonthlydatalength:0,
    saveyearlydatalength:0,
    savelifetimedatalength:0,
    loader_remove: "d-none",
    loaderimage_remove: require("../../assets/images/signup/Loader_GIF.gif").default,
    submitval_confirmpop: "plan-upgradebtn",
    spinner: true,
    customer_type: "",
    checker_web_technology:1,
    downgradeplan:false,
    currentplanname:"",
    currentplanduration:"",
    currentplanrpice:"",
    endcycledata:"",
    currency:"",
    downgrade_at_term_end:1,
    selectiontype:4,
    paddlecurreny:"",
    openReactivatePopUp:false,
    reactiveSubmitText:"Confirm",
    reactiveLoader:"d-none",

};
}

  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "All-in-One plans | AppMySite";
    this.stopmounting = true;

    const Paddle = window.Paddle;
    localStorage.removeItem('reload');

      if (
      window.location.host.indexOf("localhost") > -1 ||
      window.location.host.indexOf("test.app") > -1 ||
      window.location.host.indexOf("stage.app") > -1 ||
      window.location.host.indexOf("dev.app") > -1
      ) {
      window.Paddle.Environment.set("sandbox");
      }
      Paddle.Setup({
      vendor: parseInt(process.env.REACT_APP_PADDLE_VENDOR_ID),
      });

    workspacePlanListing(this, this.state.checker_web_technology,() =>{
        if(this.state.customer_billing_type === 3 && this.state.yearlydata !==''&& this.state.yearlydata !== undefined)
        {
            this.state.yearlydata.map((item, i) => 
            {
                window.Paddle.Product.Prices(parseInt(item.plan_code), (prices) =>
                this.settingPaddlePrices(i,prices,'year')
                );
            });
      }
      else{
        this.setState({newyearlydata:''});
        this.setState({newyearlydata:this.state.yearlydata});
      }

        if(this.state.customer_billing_type === 3 && this.state.monthlydata !==''&&this.state.monthlydata !== undefined)
        {
            this.state.monthlydata.map((item, i) => 
            {
                window.Paddle.Product.Prices(parseInt(item.plan_code), (prices) =>
                this.settingPaddlePrices(i,prices,'month')
                );
            });
        }
        else
        {
            this.setState({newmonthlydata:''});
            this.setState({newmonthlydata:this.state.monthlydata});

        }

        if(this.state.customer_billing_type === 3)
        {
            this.state.lifetimedata.map((item, i) => 
            {
                window.Paddle.Product.Prices(parseInt(item.plan_code), (prices) =>
                this.settingPaddlePrices(i,prices,'lifetime')
                );
            });
        }
        else{
            this.setState({newlifetimedata:''});
            this.setState({newlifetimedata:this.state.lifetimedata});
        }
       
    });
   
     
  }

  newcurrencyFormater = (number, country_name) => {
    let formatedNum = new Intl.NumberFormat(country_name).format(number);
    return formatedNum;
  };

  settingPaddlePrices = (i,prices,type) => {

    this.setState({currency:prices.price.gross.slice(0,1)});
    if(type == 'year' && this.state.saveyearlydatalength < this.state.yearlydata.length )
    {
        var numb = prices.price.gross.replaceAll(',','');
        numb = numb.replace(/^\D+/g, '');

        var currency = (prices.price.gross.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
        currency = currency.replace("US", " ");

        var values = this.newcurrencyFormater((parseFloat(parseFloat(numb)/12).toFixed(2)), "en-"+ prices.country);

      let decimalValue = prices.price.gross.toString().indexOf(".");
      let result = '.'+prices.price.gross.toString().substring(decimalValue+1);


        this.state.yearlydata[i].paddleprice =  `<h4>`+currency + values +`<span class='pricing-decimal'>`+result+`</span></h4>`;
        this.state.saveyearlydata.push(this.state.yearlydata[i]);
        this.setState({saveyearlydatalength:this.state.saveyearlydatalength+1,paddlecurreny:currency});
        if(this.state.saveyearlydatalength == this.state.yearlydata.length)
        {
            var results1 = this.state.saveyearlydata;
            this.setState({newyearlydata:''});
            this.setState({newyearlydata:results1});
        }
    }

    
    if(type == 'month' && this.state.savemonthlydatalength < this.state.monthlydata.length )
    {
      var numb = prices.price.gross.replaceAll(',','');
      numb = numb.replace(/^\D+/g, '');

      var currency = (prices.price.gross.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
      currency = currency.replace("US", " ");

      var values = this.newcurrencyFormater((parseFloat(parseFloat(numb))), "en-"+ prices.country);

      let decimalValue = prices.price.gross.toString().indexOf(".");
      let result = '.'+prices.price.gross.toString().substring(decimalValue+1);
      
      this.state.monthlydata[i].paddleprice = `<h4>`+currency + values +`<span class='pricing-decimal'>`+result+`</span></h4>`;
      
      this.state.savemonthlydata.push(this.state.monthlydata[i]);
      this.setState({savemonthlydatalength:this.state.savemonthlydatalength+1,paddlecurreny:currency});
      if(this.state.savemonthlydatalength == this.state.monthlydata.length)
        {
            var results = this.state.savemonthlydata;
        this.setState({newmonthlydata:''});
        this.setState({newmonthlydata:results});
        }

    }
    

    if(type == 'lifetime' && this.state.savelifetimedatalength < this.state.lifetimedata.length)
    {
        
      var numb = prices.price.gross.replaceAll(',','');
      numb = numb.replace(/^\D+/g, '');

      var currency = (prices.price.gross.replace(/[0-9]/g, '')).replace(/[`~!@#%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
      currency = currency.replace("US", " ");

      var values =  this.newcurrencyFormater((parseFloat(parseFloat(numb))), "en-"+ prices.country);

      let decimalValue = prices.price.gross.toString().indexOf(".");
      let result = '.'+prices.price.gross.toString().substring(decimalValue+1);
      
      this.state.lifetimedata[i].paddleprice = `<h4>`+currency + values +`<span class='pricing-decimal'>`+result+`</span></h4>`;
        this.state.savelifetimedata.push(this.state.lifetimedata[i]);
        this.setState({savelifetimedatalength:this.state.savelifetimedatalength+1,paddlecurreny:currency});
        if(this.state.savelifetimedatalength == this.state.lifetimedata.length)
        {
            var results2 = this.state.savelifetimedata;
            this.setState({newlifetimedata:''});
            this.setState({newlifetimedata:results2});
        }
    }
   
    
  };

  previewdowngrade = (e) => {
    // e.preventDefault();

    this.setState({
      loader_remove: "d-block plan-upgradebtn",
      submitval_confirmpop: "d-none",
    });
    const workspaceParam = {
      workspace_id: parseInt(localStorage.getItem("workspaceId")),
      plan_array: [
        {
          price_id: this.state.one_time[0].plan_code,

          quantity: 1,
          type: 1,
        },
      ],
      payment_method_id: "downgrade",
      order_type: 2,
      deal_id: "",
      downgrade_at_term_end:this.state.downgrade_at_term_end === 0 ? 1 : 0,
      // action: this.state.payment_method_id,
    };

    const paddleParam = {
      workspace_id:
        localStorage.getItem("workspaceId") !== "" &&
        localStorage.getItem("workspaceId") !== "undefined" &&
        localStorage.getItem("workspaceId") !== undefined &&
        localStorage.getItem("workspaceId") !== null
          ? parseInt(localStorage.getItem("workspaceId"))
          : null,
      app_id:
        localStorage.getItem("workspaceId") !== "" &&
        localStorage.getItem("workspaceId") !== "undefined" &&
        localStorage.getItem("workspaceId") !== undefined &&
        localStorage.getItem("workspaceId") !== null
          ? null
          : parseInt(localStorage.getItem("Appid")),
      deal_id: null,
      order_type: 2,
      price_id: this.state.one_time[0].plan_code,
      quantity: 1,
      type: 1,
      downgrade_at_term_end:this.state.downgrade_at_term_end === 0 ? 1 : 0,
    };

    //Payment Api
    // ? //strip downgrade
    this.state.customer_billing_type === 3
      ? paddleChangePlan(paddleParam, this)
      : MakestripWorkspacePayment(workspaceParam, this);
  };


  //Button Color
  handlechecktab = (e) => 
  {
  	
    this.setState({checkedtab: e});
  };

  handletypetab = (e) => 
  {
    this.setState({selectiontype: e});
  };

  handlecheckmoobtab = (e) => 
  {
  	
    this.setState({checkedmobtab: e});
  };

  handleReactivePopUp = (e,from) => {
    if(from==="cancel"){
        this.setState({
            openReactivatePopUp : !this.state.openReactivatePopUp  
        })
    }else{
    this.setState({
     reactiveLoader:"d-block",
     reactiveSubmitText:""
    });
    this.previewdowngrade(e);
    }

  }

  handleDeals = () => {
    if (this.state.appid) {
      this.props.history.push(`/app/apply-deal/${this.state.appid}`);
    } else {
      this.props.history.push("/deals");
    }
  };

  seemorefunction = () => {
    var element = document.getElementById("showfaq");
    element.classList.remove("hiddenfaq");
    document.getElementById("seemorelink").classList.add("d-none");
    document.getElementById("seelesslink").classList.remove("d-none");    
  }
  
  seelessfunction = () => 
  {
    var element = document.getElementById("showfaq");
    element.classList.add("hiddenfaq"); 
    document.getElementById("seemorelink").classList.remove("d-none");
    document.getElementById("seelesslink").classList.add("d-none");    
  }
  
  downgradepopupdeal = () => {
    if (this.state.appuser_role_id !== 3 && this.state.appuser_role_id !== 4) 
    {
      this.setState({
        downgradeuser: !this.state.downgradeuser,
      });
    }
  };

  removepopup = () => {
    if (this.state.appuser_role_id !== 3 && this.state.appuser_role_id !== 4) {
      this.setState({
        deluser: !this.state.deluser,
      });
    }
  };

  dlink = (
    plan_code,
    recurring_price,
    package_name,
    interval_unit,
    id
  ) => {
    if (
      this.state.appuser_role_id !== 3 &&
      this.state.appuser_role_id !== 4
    ) {
      return (
        "/checkout/" +
        plan_code +
        "/" +
        btoa(recurring_price) +
        "/" +
        btoa(plan_code) +
        "/" +
        btoa(interval_unit) +
        "/" +
        btoa(id) +
        "/" +
        btoa(null) +
        "/" +
        "1"
      );
    }
  };

  handleWooCustomer = (e, customer_type, plan_code) => 
  {
    
      this.setState({
        loader_remove: "d-block plan-upgradebtn",
      submitval_confirmpop: "d-none",
      });
      this.setState({
        tempFilter: true,
        });
    if (customer_type === 4) 
    {
      const params = {
        price_id: plan_code,
        type: 1,
        app_id: parseInt(atob(this.state.appid)), //either this or workspace_id

        return_url:
          window.location.protocol + "//" + window.location.host + "/thank-you",
      };
      wooChangePlan(this, params);
    }
  };
 

  handleAccordianToggle = (e) => {
    let accItem = document.getElementsByClassName('accordionItem');
    let accHD = document.getElementsByClassName('accordionItemHeading allflex');

    let itemClass = e.target.parentElement.className;
    for (let i = 0; i < accItem.length; i++) {
        accItem[i].className = 'accordionItem close accordion-item';
        accItem[i].children[0].children[0].src = require('../../assets/images/pricing/faqplus.webp').default;
        accItem[i].children[0].children[0].setAttribute('data-src', require('../../assets/images/pricing/faqplus.webp').default);
    }
    if (itemClass == 'accordionItem close accordion-item') {
        e.target.parentElement.className = 'accordionItem open';
        e.target.children[0].src = require('../../assets/images/pricing/faqminus.webp').default;
        e.target.children[0].setAttribute('data-src', require('../../assets/images/pricing/faqminus.webp').default);
        
        
    }
    else
    {
        e.target.children[0].src = require('../../assets/images/pricing/faqplus.webp').default;
        e.target.children[0].setAttribute('data-src', require('../../assets/images/pricing/faqplus.webp').default);
    }

}


  render() {
    
    return (
      <>
     
      <Header customclass={"header-responsive-new"} />
      
        <div style={{ minHeight: "100vh" }}>

        {this.state.spinner ? (
           <div className="newspinner">
           <svg xmlns="http://www.w3.org/2000/svg" 
     xlink="http://www.w3.org/1999/xlink" 
     style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
     width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
     <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
     <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
       <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
       style={{animationplaystate: "running", animationdelay: "0s"}}>
       </animateTransform>
     </circle>
     </g>
     <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
     <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
     <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
     style={{animationplaystate: "running", animationdelay: "0s"}}>
     </animateTransform>
     </circle>
     </g>
     <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
     <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
     <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
     style={{animationplaystate: "running", animationdelay: "0s"}}>

     </animateTransform>
     </circle>
     </g>
     <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
     <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
     <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
     style={{animationplaystate: "running", animationdelay: "0s"}}>

     </animateTransform>
     </circle>
     </g>
     </svg>
         </div>
          ) : ((this.state.customer_billing_type !== 3) || (this.state.monthlydata !== '' && this.state.monthlydata !== undefined && this.state.savemonthlydatalength == this.state.monthlydata.length ) 
          || ((this.state.monthlydata === '' || this.state.monthlydata === undefined) &&this.state.yearlydata !== '' && this.state.yearlydata !== undefined && this.state.saveyearlydatalength == this.state.newyearlydata.length )
          || ((this.state.monthlydata === '' || this.state.monthlydata === undefined ) && (this.state.yearlydata === '' || this.state.yearlydata === undefined) && this.state.lifetimedata !== '' && this.state.lifetimedata !== undefined && this.state.savelifetimedatalength == this.state.lifetimedata.length )) ?  
          (
            <>
          <section className="blueback teampricing paddingtop160 paddingbottom100">
              <div className="container">
                <div>
                <h1>All-In-One Plans</h1>
                </div>    
                {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                <div className="discount-percent discount-percent-all-mob">
                <svg xmlns="http://www.w3.org/2000/svg" width="19.246" height="16" viewBox="0 0 19.246 16">
                <g id="_3847911_arrow_left_icon" data-name="3847911_arrow_left_icon" transform="translate(0 16) rotate(-90)">
                    <path id="Path_29096" data-name="Path 29096" d="M.466,5.531a13.393,13.393,0,0,1,5.923,5.291c.656,1.241,2.552.14,1.873-1.1A11.858,11.858,0,0,0,5.593,6.491c3.184-.375,7.024-.281,7.562,3.324a37.167,37.167,0,0,1,.328,4.846c.023,1.4-.281,2.973.468,4.214a.718.718,0,0,0,1.147.14c1.217-1.545.866-4.12.819-6.017s.047-4.261-1.007-5.946C12.8,3.658,8,3.822,4.305,4.244A22.891,22.891,0,0,0,9.2.568c.258-.258-.07-.726-.4-.515A29.761,29.761,0,0,1,.7,3.729,1.007,1.007,0,0,0,.466,5.531Z" transform="translate(0)" fill="#bcc1ce"/>
                </g>
                </svg>
                <p>Save up to 20%</p>
            </div>
                
                : null
            }
            
            <div className="all-in-one-filter">

                <div className={this.state.selectiontype !== '' && this.state.selectiontype === 4 ? "all-active" : ''}  onClick={() => {this.handletypetab(4)}}>
                {this.state.selectiontype !== '' && this.state.selectiontype === 4 ?
                  <>
                    <img src={ require("../../assets/images/pricing/all-in-one-wordpress.png") .default }alt="wordpress" className="webshow"/>
                    <img src={ require("../../assets/images/pricing/pricing-type-2.png") .default }alt="wordpress" className="mobileshow"/>
                  </>
                :
                  <>
                    <img src={ require("../../assets/images/pricing/all-in-one-grey.png") .default } onClick={() => {this.handletypetab(4)}} alt="wordpress" className="webshow"/>
                    <img src={ require("../../assets/images/pricing/pricing-type-2-grey.png") .default } onClick={() => {this.handletypetab(4)}} alt="wordpress" className="mobileshow"/>
                  </>
                }
                <p>WordPress</p>
                </div>

                <div className={this.state.selectiontype !== '' && this.state.selectiontype === 5 ? "all-active" : ''}  onClick={() => {this.handletypetab(5)}}>
                {this.state.selectiontype !== '' && this.state.selectiontype === 5 ?
                  <>
                    <img src={ require("../../assets/images/pricing/all-in-one-woocommerce.png") .default }alt="woocommerce" className="webshow"/>
                    <img src={ require("../../assets/images/pricing/pricing-type-3.png") .default }alt="woocommerce" className="mobileshow"/>
                    </>
                :
                  <>
                    <img src={ require("../../assets/images/pricing/all-in-one-woocommerce-grey.png") .default } onClick={() => {this.handletypetab(5)}} alt="woocommerce" className="webshow"/>
                    <img src={ require("../../assets/images/pricing/pricing-type-3-grey.png") .default } onClick={() => {this.handletypetab(5)}} alt="woocommerce" className="mobileshow"/>
                  </>
                }
                    <p>WooCommerce</p>
                </div>

                <div  className={this.state.selectiontype !== '' && this.state.selectiontype === 2 ?"all-active" : ''}  onClick={() => {this.handletypetab(2)}}>
                {this.state.selectiontype !== '' && this.state.selectiontype === 2 ?
                  <>
                    <img src={ require("../../assets/images/pricing/all-in-one-webtoapp.png") .default } alt="webtoapp" className="webshow"/>
                    <img src={ require("../../assets/images/pricing/pricing-type-1.png") .default } onClick={() => {this.handletypetab(2)}} alt="webtoapp" className="mobileshow"/>
                  </>
                :
                  <>
                    <img src={ require("../../assets/images/pricing/all-in-one-webtoapp-grey.png") .default }  onClick={() => {this.handletypetab(2)}} alt="webtoapp" className="webshow"/>
                    <img src={ require("../../assets/images/pricing/pricing-type-1-grey.png") .default }  onClick={() => {this.handletypetab(2)}} alt="webtoapp" className="mobileshow"/>
                  </>
                }
                    <p>Web To App</p>
                </div>

                <div  className={this.state.selectiontype !== '' && this.state.selectiontype === 3 ?"all-active" : ''}  onClick={() => {this.handletypetab(3)}}>
                {this.state.selectiontype !== '' && this.state.selectiontype === 3 ?
                  <>
                    <img src={ require("../../assets/images/pricing/cmscolor.png") .default }alt="cms" className="webshow"/>
                    <img src={ require("../../assets/images/pricing/pricing-type-4.png") .default } onClick={() => {this.handletypetab(3)}} alt="webtoapp" className="mobileshow"/>

                  </>
                :
                  <>
                  <img src={ require("../../assets/images/pricing/all-in-one-cms-unavailable.png") .default }  onClick={() => {this.handletypetab(3)}} alt="greycms" className="webshow"/>
                  <img src={ require("../../assets/images/pricing/pricing-type-4-grey.png") .default }  onClick={() => {this.handletypetab(3)}} alt="greycms" className="mobileshow"/>
                  </>
                }
                    <p>Custom</p>
                </div>

                <div className="not-all-active">
                    <img src={ require("../../assets/images/pricing/all-in-one-shopify-unavailable.png") .default }alt="shopify" className="webshow"/>

                    <img src={ require("../../assets/images/pricing/pricing-type-5-grey.png") .default }alt="shopify" className="mobileshow"/>
                    <p>Shopify</p>
                </div>

            </div>
          
            {this.state.selectiontype !== '' && this.state.selectiontype === 4 ?
            <div className="tabbed">
                {this.state.monthlydata !== '' && this.state.monthlydata !== undefined ?
                        <input type="radio" id="tab1" name="css-tabs"  onChange={() => {this.handlechecktab('monthly')}}  defaultChecked={this.state.checkedtab == 'monthly' ? true : '' } />
                    : null
                    }
                    {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                        <input type="radio" id="tab2" name="css-tabs" onChange={() => {this.handlechecktab('yearly')}}  defaultChecked={this.state.checkedtab == 'yearly' ? true : '' } />
                    : null
                    }
                    {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                        <input type="radio" id="tab3" name="css-tabs" onChange={() => {this.handlechecktab('lifetime')}}  defaultChecked={this.state.checkedtab == 'lifetime' ? true : '' } />
                    : null
                    }
                
                <ul className="tabs">
                {this.state.monthlydata !== '' && this.state.monthlydata !== undefined ?
                    <li className="tab"><label for="tab1">Monthly</label></li>
                : null
                }
                {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                    <li className="tab"><label for="tab2">Yearly</label></li>
                : null
                }
                {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                    <li className="tab"><label for="tab3">Lifetime</label></li>
                : null
                }
                </ul>
                 

                <div className={"tab-content " + (this.state.checkedtab === 'monthly' ? '' : 'd-none')}>

<div class="pricingplans allflex">

<div class="pricebox">
    
    <div class="price-first-half">
        
        <div class="borderblue"></div>
        
    <div class="allflex">
        
        <h2>Free</h2>
        
        <div class="platforms">
        <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>

        <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
        </div>
        
    </div>  
    
    <h4>{this.state.customer_billing_type == 3 
                                ? this.state.paddlecurreny + '0'
                                : '$0'}<span className="pricing-decimal">.00</span></h4>
    <h5></h5>
    
    { 
        this.state.one_time[0] !== undefined && this.state.one_time[0].is_subscribed === 1 ? 
        (
           <Link to="#" className="currentplan">
             Current
           </Link>
         ) 
         : this.state.is_deal_plan == 1 ?
        (
            <Link to="#"  onClick={(e) => this.downgradepopupdeal()}>
                Select
            </Link> 
        )
        : this.state.currentplanduration === 4 ?
        (
            <Link to="#" className="lowerplan">
                Unavailable
            </Link> 
        )
         : this.state.downgrade_at_term_end === 1 ?
         (
             <Link to="#" className="lowerplan">
                Unavailable
             </Link>
       )
         : this.state.one_time[0] !== undefined && this.state.one_time[0].show_plan === 0 ? 
         (
            <Link to="#" className="lowerplan">
               Unavailable
            </Link>
         )
        : parseInt(
            localStorage.getItem("customer_type")
          ) === 4 &&
          this.state.reseller_licence_id === null ? 
        (
          <a
            target="_blank"
            href={
              process.env.REACT_APP_WOOCOMMERCE_URL +
              "my-account/my-subscriptions/"
            }
          >
            Select{" "}
            <img
              src={
                require("../../assets/images/dashboard/Goto.png")
                  .default
              }
              alt="Goto"
            />
          </a>
        ) : 
        (
          <Link
            to="#"
            onClick={(e) => this.removepopup()}
          >
            Select
          </Link>
        )
    }
    </div>
    <div className="pricedowngrade">
    {this.state.downgrade_at_term_end === 1 && this.state.endcycledata !== null? 
                            
                            'Downgrades on '  + moment(this.state.endcycledata).format("Do MMM, YYYY")
                         :  this.state.downgrade_at_term_end === 1 && this.state.endcycledata === null ?
                           'Downgrade scheduled'
                         : ''}
     {this.state.downgrade_at_term_end === 1 ? 
    <img onClick={(e) =>
                  this.state.customer_type === 4 ? 
                  this.handleWooCustomer(e,this.state.customer_type,this.state.one_time[0].plan_code)
                //  : this.previewdowngrade(e)
                : this.handleReactivePopUp(e,"cancel")
                } 
    src={require("../../assets/images/pricing/red-cross.png").default} alt="red-cross"/>
    : ''}
              
    </div>
    
    <div class="mobi-tab">
        <input type="checkbox" id="chck5" />
        <label class="mobi-tab-label" for="chck5">View Features</label>
    <div class="mobi-tab-content">
    <div class="price-second-half">
    
    <div className="featurebox wordpress-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>    
                                
                            </div>
    
    <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    </div>
        </div>
    </div>
</div>
{
    (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'starter' ) ||
    (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'starter' ) ||
    (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'starter' ) 
?     
<div class="pricebox">
    
    <div class="price-first-half">
    
    <div class="borderyellow"></div>
    <div class="allflex">
        
        <h2>Starter</h2>
        
        <div class="platforms">
        <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
        
        <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
    </div>
        
    </div>  
    
    {this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0] !== null ?
 <>
    {this.state.customer_billing_type !== 3 ?
                                <>
                                    <h4>
                                    {
                                    this.state.newmonthlydata[0].plan == 'starter' ?
                                    this.state.newmonthlydata[0].package_currency.currency_symbol +
                                    this.state.newmonthlydata[0].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[1].plan == 'starter' ?
                                    this.state.newmonthlydata[1].package_currency.currency_symbol +
                                    this.state.newmonthlydata[1].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[2].plan == 'starter' ?
                                    this.state.newmonthlydata[2].package_currency.currency_symbol +
                                    this.state.newmonthlydata[2].amount.replace(".00", " ")
                                    :
                                    '$ 29'
                                    }
                                    </h4>
                                    </>
                                :
                                <>
                                {
                                  this.state.newmonthlydata[0].plan == 'starter' ?
                                  ReactHtmlParser(this.state.newmonthlydata[0].paddleprice)
                                 :
                                  this.state.newmonthlydata[1].plan == 'starter' ?
                                  ReactHtmlParser(this.state.newmonthlydata[1].paddleprice)
                                 :
                                  this.state.newmonthlydata[2].plan == 'starter' ?
                                  ReactHtmlParser(this.state.newmonthlydata[2].paddleprice)
                                 :
                                  '$ 29'
                                }
                                </>
                                }
    <h5>per month</h5>
  
{
(this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'starter' && this.state.newmonthlydata[0].is_subscribed === 1) ||
(this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'starter' && this.state.newmonthlydata[1].is_subscribed === 1) ||
(this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'starter' && this.state.newmonthlydata[2].is_subscribed === 1) 
? 
    (
        <Link to="#" className="currentplan">
            Current
        </Link>
    ) 
: 
    (this.state.newmonthlydata[0].plan == 'starter' && this.state.newmonthlydata[0].show_plan === 0) ||
    (this.state.newmonthlydata[1].plan == 'starter' && this.state.newmonthlydata[1].show_plan === 0) ||
    (this.state.newmonthlydata[2].plan == 'starter' && this.state.newmonthlydata[2].show_plan === 0) 
?   
(
    <Link to="#"  className="lowerplan">
       Unavailable
    </Link>
)
:   (
           <Link to=
                {
                    this.state.customer_type === 4
                    ? "#"
                    : this.state.newmonthlydata[0].plan == 'starter' ?
                      this.dlink(
                        this.state.newmonthlydata[0].plan_code,
                        parseInt(this.state.newmonthlydata[0].amount),
                        this.state.newmonthlydata[0].plan_name,
                        this.state.newmonthlydata[0].package_currency.currency_symbol,
                        this.state.newmonthlydata[0].plan_code
                      )
                    : this.state.newmonthlydata[1].plan == 'starter' ?
                      this.dlink(
                        this.state.newmonthlydata[1].plan_code,
                        parseInt(this.state.newmonthlydata[1].amount),
                        this.state.newmonthlydata[1].plan_name,
                        this.state.newmonthlydata[1].package_currency.currency_symbol,
                        this.state.newmonthlydata[1].plan_code
                      )
                    : this.state.newmonthlydata[2].plan == 'starter' ?
                      this.dlink(
                        this.state.newmonthlydata[2].plan_code,
                        parseInt(this.state.newmonthlydata[1].amount),
                        this.state.newmonthlydata[2].plan_name,
                        this.state.newmonthlydata[2].package_currency.currency_symbol,
                        this.state.newmonthlydata[2].plan_code
                      )
                    :
                     this.dlink(
                        this.state.newmonthlydata[0].plan_code,
                        parseInt(this.state.newmonthlydata[0].amount),
                        this.state.newmonthlydata[0].plan_name,
                        this.state.newmonthlydata[0].package_currency.currency_symbol,
                        this.state.newmonthlydata[0].plan_code
                      )


                }

                onClick={ 
                    this.state.newmonthlydata[0].plan == 'starter' ?
                    (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newmonthlydata[0].plan_code,
                      0)
                    : this.state.newmonthlydata[1].plan == 'starter' ?
                      (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newmonthlydata[1].plan_code,
                      0)
                    : this.state.newmonthlydata[2].plan == 'starter' ?
                      (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newmonthlydata[2].plan_code,
                      0)
                    :
                    (e) => this.handleWooCustomer(e,
                    this.state.customer_type,
                    this.state.newmonthlydata[0].plan_code,
                    0)
                   }

            >
            Select
        </Link>
    )}
 </>
: null
}
    
    </div>
    
    <div className="pricedowngrade"></div>
        
    <div class="mobi-tab">
        <input type="checkbox" id="chck6" />
        <label class="mobi-tab-label" for="chck6">View Features</label>
    <div class="mobi-tab-content">
    <div class="price-second-half">
    
    <div className="featurebox wordpress-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>    

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                            </div>    
    
    <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    </div> 
        </div>
    </div>
        
</div>
 : null
} 
 {
    (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'pro' ) ||
    (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'pro' ) ||
    (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'pro' ) 
?
<div class="pricebox">
    
    <div class="price-first-half">
    
    <div class="borderorange"></div>
    
    <div class="allflex">
        
        <h2>Pro</h2>
        
        <div class="platforms">
        <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
        
        <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
        
        <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
    </div>
        
    </div>  
    
    {this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0] !== null ?
 <>
    {this.state.customer_billing_type !== 3 ?
                                <>
                                    <h4>
                                    {
                                    this.state.newmonthlydata[0].plan == 'pro' ?
                                    this.state.newmonthlydata[0].package_currency.currency_symbol +
                                    this.state.newmonthlydata[0].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[1].plan == 'pro' ?
                                    this.state.newmonthlydata[1].package_currency.currency_symbol +
                                    this.state.newmonthlydata[1].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[2].plan == 'pro' ?
                                    this.state.newmonthlydata[2].package_currency.currency_symbol +
                                    this.state.newmonthlydata[2].amount.replace(".00", " ")
                                    :
                                    '$ 29'
                                    }
                                    </h4>
                                    </>
                                :
                                <>
                                {
                                  this.state.newmonthlydata[0].plan == 'pro' ?
                                  ReactHtmlParser(this.state.newmonthlydata[0].paddleprice)
                                 :
                                  this.state.newmonthlydata[1].plan == 'pro' ?
                                  ReactHtmlParser(this.state.newmonthlydata[1].paddleprice)
                                 :
                                  this.state.newmonthlydata[2].plan == 'pro' ?
                                  ReactHtmlParser(this.state.newmonthlydata[2].paddleprice)
                                 :
                                  '$ 29'
                                }
                                </>
                                }
    <h5>per month</h5>
    {
 (this.state.newmonthlydata[0].plan == 'pro' && this.state.newmonthlydata[0].is_subscribed === 1) ||
 (this.state.newmonthlydata[1].plan == 'pro' && this.state.newmonthlydata[1].is_subscribed === 1) ||
 (this.state.newmonthlydata[2].plan == 'pro' && this.state.newmonthlydata[2].is_subscribed === 1) 
 ? 
     (
         <Link to="#" className="currentplan">
             Current
         </Link>
     ) 
 : 
     (this.state.newmonthlydata[0].plan == 'pro' && this.state.newmonthlydata[0].show_plan === 0) ||
     (this.state.newmonthlydata[1].plan == 'pro' && this.state.newmonthlydata[1].show_plan === 0) ||
     (this.state.newmonthlydata[2].plan == 'pro' && this.state.newmonthlydata[2].show_plan === 0) 
 ?   
 (
     <Link to="#"  className="lowerplan">
         Unavailable
     </Link>
 ) 
:   (
           <Link to=
                {
                    this.state.customer_type === 4
                    ? "#"
                    : this.state.newmonthlydata[0].plan == 'pro' ?
                      this.dlink(
                        this.state.newmonthlydata[0].plan_code,
                        parseInt(this.state.newmonthlydata[0].amount),
                        this.state.newmonthlydata[0].plan_name,
                        this.state.newmonthlydata[0].package_currency.currency_symbol,
                        this.state.newmonthlydata[0].plan_code
                      )
                    : this.state.newmonthlydata[1].plan == 'pro' ?
                      this.dlink(
                        this.state.newmonthlydata[1].plan_code,
                        parseInt(this.state.newmonthlydata[1].amount),
                        this.state.newmonthlydata[1].plan_name,
                        this.state.newmonthlydata[1].package_currency.currency_symbol,
                        this.state.newmonthlydata[1].plan_code
                      )
                    : this.state.newmonthlydata[2].plan == 'pro' ?
                      this.dlink(
                        this.state.newmonthlydata[2].plan_code,
                        parseInt(this.state.newmonthlydata[1].amount),
                        this.state.newmonthlydata[2].plan_name,
                        this.state.newmonthlydata[2].package_currency.currency_symbol,
                        this.state.newmonthlydata[2].plan_code
                      )
                    :
                     this.dlink(
                        this.state.newmonthlydata[1].plan_code,
                        parseInt(this.state.newmonthlydata[1].amount),
                        this.state.newmonthlydata[1].plan_name,
                        this.state.newmonthlydata[1].package_currency.currency_symbol,
                        this.state.newmonthlydata[1].plan_code
                      )


                }
                onClick={ 
                    this.state.newmonthlydata[0].plan == 'pro' ?
                    (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newmonthlydata[0].plan_code,
                      1)
                    : this.state.newmonthlydata[1].plan == 'pro' ?
                      (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newmonthlydata[1].plan_code,
                      1)
                    : this.state.newmonthlydata[2].plan == 'pro' ?
                      (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newmonthlydata[2].plan_code,
                      1)
                    :
                    (e) => this.handleWooCustomer(e,
                    this.state.customer_type,
                    this.state.newmonthlydata[1].plan_code,
                    1)
                   }

            >
            Select
        </Link>
    )}
 </>
: null
}

    </div>   
    
    <div className="pricedowngrade"></div>
        
    <div class="mobi-tab">
        <input type="checkbox" id="chck7" />
        <label class="mobi-tab-label" for="chck7">View Features</label>
    <div class="mobi-tab-content">
    <div class="price-second-half">
    
    <div className="featurebox wordpress-featurebox">
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                                
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                            </div>    
    
    <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    </div>
        </div>
    </div>
        
</div>
: null
}
{
    (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'premium' ) ||
    (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'premium' ) ||
    (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'premium' ) 
?
<div class="pricebox">
    
    <div class="price-first-half">
    
    <div class="bordergreen"></div>
    
    <div class="allflex">
        
        <h2>Premium</h2>
        
        <div class="platforms">
        <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
        
        <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
        
        <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
    </div>
        
    </div> 
    
    {this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0] !== null ?
 <>
    {this.state.customer_billing_type !== 3 ?
                                <>
                                    <h4>
                                    {
                                    this.state.newmonthlydata[0].plan == 'premium' ?
                                    this.state.newmonthlydata[0].package_currency.currency_symbol +
                                    this.state.newmonthlydata[0].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[1].plan == 'premium' ?
                                    this.state.newmonthlydata[1].package_currency.currency_symbol +
                                    this.state.newmonthlydata[1].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[2].plan == 'premium' ?
                                    this.state.newmonthlydata[2].package_currency.currency_symbol +
                                    this.state.newmonthlydata[2].amount.replace(".00", " ")
                                    :
                                    '$ 29'
                                    }
                                    </h4>
                                    </>
                                :
                                <>
                                {
                                  this.state.newmonthlydata[0].plan == 'premium' ?
                                  ReactHtmlParser(this.state.newmonthlydata[0].paddleprice)
                                 :
                                  this.state.newmonthlydata[1].plan == 'premium' ?
                                  ReactHtmlParser(this.state.newmonthlydata[1].paddleprice)
                                 :
                                  this.state.newmonthlydata[2].plan == 'premium' ?
                                  ReactHtmlParser(this.state.newmonthlydata[2].paddleprice)
                                 :
                                  '$ 29'
                                }
                                </>
                                }
    <h5>per month</h5>
{
(this.state.newmonthlydata[0].plan == 'premium' && this.state.newmonthlydata[0].is_subscribed === 1) ||
(this.state.newmonthlydata[1].plan == 'premium' && this.state.newmonthlydata[1].is_subscribed === 1) ||
(this.state.newmonthlydata[2].plan == 'premium' && this.state.newmonthlydata[2].is_subscribed === 1) 
? 
    (
        <Link to="#" className="currentplan">
            Current
        </Link>
    ) 
: 
    (this.state.newmonthlydata[0].plan == 'premium' && this.state.newmonthlydata[0].show_plan === 0) ||
    (this.state.newmonthlydata[1].plan == 'premium' && this.state.newmonthlydata[1].show_plan === 0) ||
    (this.state.newmonthlydata[2].plan == 'premium' && this.state.newmonthlydata[2].show_plan === 0) 
?   
(
    <Link to="#"  className="lowerplan">
        Unavailable
    </Link>
) 
    :   (
        <Link to=
             {
                 this.state.customer_type === 4
                 ? "#"
                 : this.state.newmonthlydata[0].plan == 'premium' ?
                   this.dlink(
                     this.state.newmonthlydata[0].plan_code,
                     parseInt(this.state.newmonthlydata[0].amount),
                     this.state.newmonthlydata[0].plan_name,
                     this.state.newmonthlydata[0].package_currency.currency_symbol,
                     this.state.newmonthlydata[0].plan_code
                   )
                 : this.state.newmonthlydata[1].plan == 'premium' ?
                   this.dlink(
                     this.state.newmonthlydata[1].plan_code,
                     parseInt(this.state.newmonthlydata[1].amount),
                     this.state.newmonthlydata[1].plan_name,
                     this.state.newmonthlydata[1].package_currency.currency_symbol,
                     this.state.newmonthlydata[1].plan_code
                   )
                 : this.state.newmonthlydata[2].plan == 'premium' ?
                   this.dlink(
                     this.state.newmonthlydata[2].plan_code,
                     parseInt(this.state.newmonthlydata[2].amount),
                     this.state.newmonthlydata[2].plan_name,
                     this.state.newmonthlydata[2].package_currency.currency_symbol,
                     this.state.newmonthlydata[2].plan_code
                   )
                 :
                  this.dlink(
                     this.state.newmonthlydata[2].plan_code,
                     parseInt(this.state.newmonthlydata[2].amount),
                     this.state.newmonthlydata[2].plan_name,
                     this.state.newmonthlydata[2].package_currency.currency_symbol,
                     this.state.newmonthlydata[2].plan_code
                   )


             }
             onClick={ 
                this.state.newmonthlydata[0].plan == 'premium' ?
                (e) => this.handleWooCustomer(e,
                  this.state.customer_type,
                  this.state.newmonthlydata[0].plan_code,
                  2)
                : this.state.newmonthlydata[1].plan == 'premium' ?
                  (e) => this.handleWooCustomer(e,
                  this.state.customer_type,
                  this.state.newmonthlydata[1].plan_code,
                  2)
                : this.state.newmonthlydata[2].plan == 'premium' ?
                  (e) => this.handleWooCustomer(e,
                  this.state.customer_type,
                  this.state.newmonthlydata[2].plan_code,
                  2)
                :
                (e) => this.handleWooCustomer(e,
                this.state.customer_type,
                this.state.newmonthlydata[2].plan_code,
                2)
               }

         >
         Select
     </Link>
 )}
 </>
: null
}
    
    </div>    
    <div className="pricedowngrade"></div>
        
    <div class="mobi-tab">
        <input type="checkbox" id="chck8" />
        <label class="mobi-tab-label" for="chck8">View Features</label>
    <div class="mobi-tab-content">
    <div class="price-second-half">
    
    <div className="featurebox wordpress-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                                
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Merge apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Language selection</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>App analytics</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Easy publish</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                                
                            </div>    
    
    <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
        
    </div> 
        </div>
    </div>
    
</div>
: null
}

</div>

</div>    

<div className={"tab-content " + (this.state.checkedtab === 'yearly' ? '' : 'd-none')}>

<div class="panel2">
<div class="pricingplans allflex">

<div class="pricebox">
    
    <div class="price-first-half">
        
        <div class="borderblue"></div>
        
    <div class="allflex">
        
        <h2>Free</h2>
        
        <div class="platforms">
        <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>

        <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
        </div>
        
    </div>  
    
    <h4>{this.state.customer_billing_type == 3 
                                ? this.state.paddlecurreny + '0'
                                : '$0'}<span className="pricing-decimal">.00</span></h4>
    <h5></h5>
    
    { 
         this.state.one_time[0] !== undefined && this.state.one_time[0].is_subscribed === 1 ? 
         (
            <Link to="#" className="currentplan">
              Current
            </Link>
          )
          : this.state.is_deal_plan == 1 ?
        (
            <Link to="#"  onClick={(e) => this.downgradepopupdeal()}>
                Select
            </Link> 
        )
        : this.state.currentplanduration === 4 ?
        (
            <Link to="#" className="lowerplan">
                Unavailable
            </Link> 
        ) 
          : this.state.downgrade_at_term_end === 1 ?
          (
              <Link to="#" className="lowerplan">
                 Unavailable
              </Link>
        )
          : this.state.one_time[0] !== undefined && this.state.one_time[0].show_plan === 0 ? 
          (
             <Link to="#" className="lowerplan">
                Unavailable
             </Link>
          ) 
        : this.state.customer_type === 4 &&
          this.state.reseller_licence_id === null ? 
        (
          <a
            target="_blank"
            href={
              process.env.REACT_APP_WOOCOMMERCE_URL +
              "my-account/my-subscriptions/"
            }
          >
            Select{" "}
            <img
              src={
                require("../../assets/images/dashboard/Goto.png")
                  .default
              }
              alt="Goto"
            />
          </a>
        ) : 
        (
          <Link
            to="#"
            onClick={(e) => this.removepopup()}
          >
            Select
          </Link>
        )
    }
    </div>
    <div className="pricedowngrade">
    {this.state.downgrade_at_term_end === 1 && this.state.endcycledata !== null? 
                            
                            'Downgrades on '  + moment(this.state.endcycledata).format("Do MMM, YYYY")
                         :  this.state.downgrade_at_term_end === 1 && this.state.endcycledata === null ?
                           'Downgrade scheduled'
                         : ''}
     {this.state.downgrade_at_term_end === 1 ? 
    <img onClick={(e) =>
                  this.state.customer_type === 4 ? 
                  this.handleWooCustomer(e,this.state.customer_type,this.state.one_time[0].plan_code)
                //  : this.previewdowngrade(e)
                : this.handleReactivePopUp(e,"cancel")
                } 
    src={require("../../assets/images/pricing/red-cross.png").default} alt="red-cross"/>
    : ''}
              
    </div>
    <div class="mobi-tab">
        <input type="checkbox" id="chck13" />
        <label class="mobi-tab-label" for="chck13">View Features</label>
    <div class="mobi-tab-content">
    <div class="price-second-half">
    
    <div className="featurebox wordpress-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>    
                                
                            </div>
    
    <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    </div>
        </div>
    </div>
</div>
{
    (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'starter' ) ||
    (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'starter' ) ||
    (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'starter' ) 
? 

<div class="pricebox">
    
    <div class="price-first-half">
    
    <div class="borderyellow"></div>
    <div class="allflex">
        
        <h2>Starter</h2>
        
        <div class="platforms">
        <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
        
        <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
    </div>
        
    </div>  
    
    {this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0] !== null ?
 <>
    {this.state.customer_billing_type !== 3 ?
                                <>
                             <h4>
                            {
                              this.state.newyearlydata[0].plan == 'starter' ?
                              this.state.newyearlydata[0].package_currency.currency_symbol +
                              this.state.newyearlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[1].plan == 'starter' ?
                              this.state.newyearlydata[1].package_currency.currency_symbol +
                              this.state.newyearlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[2].plan == 'starter' ?
                              this.state.newyearlydata[2].package_currency.currency_symbol +
                              this.state.newyearlydata[2].amount.replace(".00", " ")
                             :
                              '$ 19'
                            }
                            </h4>
                        </>
                        :
                        <>
                        {
                          this.state.newyearlydata[0].plan == 'starter' ?
                          ReactHtmlParser(this.state.newyearlydata[0].paddleprice)
                         :
                          this.state.newyearlydata[1].plan == 'starter' ?
                          ReactHtmlParser(this.state.newyearlydata[1].paddleprice)
                         :
                          this.state.newyearlydata[2].plan == 'starter' ?
                          ReactHtmlParser(this.state.newyearlydata[2].paddleprice)
                         :
                          '$ 19'
                        }
                        </>
                        }
    <h5>per month paid yearly</h5>
  {
    (this.state.newyearlydata[0].plan == 'starter' && this.state.newyearlydata[0].is_subscribed === 1) ||
    (this.state.newyearlydata[1].plan == 'starter' && this.state.newyearlydata[1].is_subscribed === 1) ||
    (this.state.newyearlydata[2].plan == 'starter' && this.state.newyearlydata[2].is_subscribed === 1) 
    ? 
        (
            <Link to="#" className="currentplan">
                Current
            </Link>
        ) 
    : 
        (this.state.newyearlydata[0].plan == 'starter' && this.state.newyearlydata[0].show_plan === 0) ||
        (this.state.newyearlydata[1].plan == 'starter' && this.state.newyearlydata[1].show_plan === 0) ||
        (this.state.newyearlydata[2].plan == 'starter' && this.state.newyearlydata[2].show_plan === 0) 
    ?   
    (
        <Link to="#"  class="lowerplan">
            Unavailable
        </Link>
    ) 
    :   (
        <Link to=
             {
                 this.state.customer_type === 4
                 ? "#"
                 : this.state.newyearlydata[0].plan == 'starter' ?
                   this.dlink(
                     this.state.newyearlydata[0].plan_code,
                     parseInt(this.state.newyearlydata[0].amount),
                     this.state.newyearlydata[0].plan_name,
                     this.state.newyearlydata[0].package_currency.currency_symbol,
                     this.state.newyearlydata[0].plan_code
                   )
                 : this.state.newyearlydata[1].plan == 'starter' ?
                   this.dlink(
                     this.state.newyearlydata[1].plan_code,
                     parseInt(this.state.newyearlydata[1].amount),
                     this.state.newyearlydata[1].plan_name,
                     this.state.newyearlydata[1].package_currency.currency_symbol,
                     this.state.newyearlydata[1].plan_code
                   )
                 : this.state.newyearlydata[2].plan == 'starter' ?
                   this.dlink(
                     this.state.newyearlydata[2].plan_code,
                     parseInt(this.state.newyearlydata[2].amount),
                     this.state.newyearlydata[2].plan_name,
                     this.state.newyearlydata[2].package_currency.currency_symbol,
                     this.state.newyearlydata[2].plan_code
                   )
                 :
                  this.dlink(
                     this.state.newyearlydata[0].plan_code,
                     parseInt(this.state.newyearlydata[0].amount),
                     this.state.newyearlydata[0].plan_name,
                     this.state.newyearlydata[0].package_currency.currency_symbol,
                     this.state.newyearlydata[0].plan_code
                   )


             }
             onClick={ 
                this.state.newyearlydata[0].plan == 'starter' ?
                (e) => this.handleWooCustomer(e,
                  this.state.customer_type,
                  this.state.newyearlydata[0].plan_code,
                  0)
                : this.state.newyearlydata[1].plan == 'starter' ?
                  (e) => this.handleWooCustomer(e,
                  this.state.customer_type,
                  this.state.newyearlydata[1].plan_code,
                  0)
                : this.state.newyearlydata[2].plan == 'starter' ?
                  (e) => this.handleWooCustomer(e,
                  this.state.customer_type,
                  this.state.newyearlydata[2].plan_code,
                  0)
                :
                (e) => this.handleWooCustomer(e,
                this.state.customer_type,
                this.state.newyearlydata[0].plan_code,
                0)
               }

         >
         Select
     </Link>
 )}
 </>
: null
}
    
    </div>
    <div className="pricedowngrade"></div>
        
    <div class="mobi-tab">
        <input type="checkbox" id="chck14" />
        <label class="mobi-tab-label" for="chck14">View Features</label>
    <div class="mobi-tab-content">
    <div class="price-second-half">
    
    <div className="featurebox wordpress-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>   

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div> 
                                
                            </div>    
    
    <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    </div> 
        </div>
    </div>
        
</div>
: null
}

{
(this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'pro' ) ||
(this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'pro' ) ||
(this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'pro' ) 
?

<div class="pricebox">
    
    <div class="price-first-half">
    
    <div class="borderorange"></div>
    
    <div class="allflex">
        
        <h2>Pro</h2>
        
        <div class="platforms">
        <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
        
        <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
        
        <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
    </div>
        
    </div>  
    
    {this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0] !== null ?
 <>
   {this.state.customer_billing_type !== 3 ?
                                <>
                             <h4>
                            {
                              this.state.newyearlydata[0].plan == 'pro' ?
                              this.state.newyearlydata[0].package_currency.currency_symbol +
                              this.state.newyearlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[1].plan == 'pro' ?
                              this.state.newyearlydata[1].package_currency.currency_symbol +
                              this.state.newyearlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[2].plan == 'pro' ?
                              this.state.newyearlydata[2].package_currency.currency_symbol +
                              this.state.newyearlydata[2].amount.replace(".00", " ")
                             :
                              '$ 19'
                            }
                            </h4>
                        </>
                        :
                        <>
                        {
                          this.state.newyearlydata[0].plan == 'pro' ?
                          ReactHtmlParser(this.state.newyearlydata[0].paddleprice)
                         :
                          this.state.newyearlydata[1].plan == 'pro' ?
                          ReactHtmlParser(this.state.newyearlydata[1].paddleprice)
                         :
                          this.state.newyearlydata[2].plan == 'pro' ?
                          ReactHtmlParser(this.state.newyearlydata[2].paddleprice)
                         :
                          '$ 19'
                        }
                        </>
                        }
    <h5>per month paid yearly</h5>
    {
    (this.state.newyearlydata[0].plan == 'pro' && this.state.newyearlydata[0].is_subscribed === 1) ||
    (this.state.newyearlydata[1].plan == 'pro' && this.state.newyearlydata[1].is_subscribed === 1) ||
    (this.state.newyearlydata[2].plan == 'pro' && this.state.newyearlydata[2].is_subscribed === 1) 
    ? 
        (
            <Link to="#" className="currentplan">
                Current
            </Link>
        )
        : 
        (this.state.newyearlydata[0].plan == 'pro' && this.state.newyearlydata[0].show_plan === 0) ||
        (this.state.newyearlydata[1].plan == 'pro' && this.state.newyearlydata[1].show_plan === 0) ||
        (this.state.newyearlydata[2].plan == 'pro' && this.state.newyearlydata[2].show_plan === 0) 
    ?   
    (
        <Link to="#"  class="lowerplan">
        Unavailable
    </Link>
    )  
    :   (
            <Link to=
                 {
                     this.state.customer_type === 4
                     ? "#"
                     : this.state.newyearlydata[0].plan == 'pro' ?
                       this.dlink(
                         this.state.newyearlydata[0].plan_code,
                         parseInt(this.state.newyearlydata[0].amount),
                         this.state.newyearlydata[0].plan_name,
                         this.state.newyearlydata[0].package_currency.currency_symbol,
                         this.state.newyearlydata[0].plan_code
                       )
                     : this.state.newyearlydata[1].plan == 'pro' ?
                       this.dlink(
                         this.state.newyearlydata[1].plan_code,
                         parseInt(this.state.newyearlydata[1].amount),
                         this.state.newyearlydata[1].plan_name,
                         this.state.newyearlydata[1].package_currency.currency_symbol,
                         this.state.newyearlydata[1].plan_code
                       )
                     : this.state.newyearlydata[2].plan == 'pro' ?
                       this.dlink(
                         this.state.newyearlydata[2].plan_code,
                         parseInt(this.state.newyearlydata[2].amount),
                         this.state.newyearlydata[2].plan_name,
                         this.state.newyearlydata[2].package_currency.currency_symbol,
                         this.state.newyearlydata[2].plan_code
                       )
                     :
                      this.dlink(
                         this.state.newyearlydata[1].plan_code,
                         parseInt(this.state.newyearlydata[1].amount),
                         this.state.newyearlydata[1].plan_name,
                         this.state.newyearlydata[1].package_currency.currency_symbol,
                         this.state.newyearlydata[1].plan_code
                       )


                 }
                 onClick={ 
                    this.state.newyearlydata[0].plan == 'pro' ?
                    (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newyearlydata[0].plan_code,
                      1)
                    : this.state.newyearlydata[1].plan == 'pro' ?
                      (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newyearlydata[1].plan_code,
                      1)
                    : this.state.newyearlydata[2].plan == 'pro' ?
                      (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newyearlydata[2].plan_code,
                      1)
                    :
                    (e) => this.handleWooCustomer(e,
                    this.state.customer_type,
                    this.state.newyearlydata[1].plan_code,
                    1)
                   }

             >
             Select
         </Link>
     )}
 </>
: null
}

    </div>  
    <div className="pricedowngrade"></div>  
        
    <div class="mobi-tab">
        <input type="checkbox" id="chck15" />
        <label class="mobi-tab-label" for="chck15">View Features</label>
    <div class="mobi-tab-content">
    <div class="price-second-half">
    
    <div className="featurebox wordpress-featurebox">
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                                
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                            </div>    
    
    <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    </div> 
        </div>
    </div>
        
</div>
: null
}

{
(this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'premium' ) ||
(this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'premium' ) ||
(this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'premium' ) 
?

<div class="pricebox">
    
    <div class="price-first-half">
    
    <div class="bordergreen"></div>
    
    <div class="allflex">
        
        <h2>Premium</h2>
        
        <div class="platforms">
        <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
        
        <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
        
        <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
    </div>
        
    </div> 
    
   {this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0] !== null ?
 <>
    {this.state.customer_billing_type !== 3 ?
                                <>
                             <h4>
                            {
                              this.state.newyearlydata[0].plan == 'premium' ?
                              this.state.newyearlydata[0].package_currency.currency_symbol +
                              this.state.newyearlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[1].plan == 'premium' ?
                              this.state.newyearlydata[1].package_currency.currency_symbol +
                              this.state.newyearlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[2].plan == 'premium' ?
                              this.state.newyearlydata[2].package_currency.currency_symbol +
                              this.state.newyearlydata[2].amount.replace(".00", " ")
                             :
                              '$ 19'
                            }
                            </h4>
                        </>
                        :
                        <>
                        {
                          this.state.newyearlydata[0].plan == 'premium' ?
                          ReactHtmlParser(this.state.newyearlydata[0].paddleprice)
                         :
                          this.state.newyearlydata[1].plan == 'premium' ?
                          ReactHtmlParser(this.state.newyearlydata[1].paddleprice)
                         :
                          this.state.newyearlydata[2].plan == 'premium' ?
                          ReactHtmlParser(this.state.newyearlydata[2].paddleprice)
                         :
                          '$ 19'
                        }
                        </>
                        }
    <h5>per month paid yearly</h5>
    {
        (this.state.newyearlydata[0].plan == 'premium' && this.state.newyearlydata[0].is_subscribed === 1) ||
        (this.state.newyearlydata[1].plan == 'premium' && this.state.newyearlydata[1].is_subscribed === 1) ||
        (this.state.newyearlydata[2].plan == 'premium' && this.state.newyearlydata[2].is_subscribed === 1) 
        ? 
            (
                <Link to="#" className="currentplan">
                    Current
                </Link>
            )
            : 
            (this.state.newyearlydata[0].plan == 'premium' && this.state.newyearlydata[0].show_plan === 0) ||
            (this.state.newyearlydata[1].plan == 'premium' && this.state.newyearlydata[1].show_plan === 0) ||
            (this.state.newyearlydata[2].plan == 'premium' && this.state.newyearlydata[2].show_plan === 0) 
        ?   
        (
            <Link to="#"  class="lowerplan">
            Unavailable
        </Link>
        ) 
        :   (
            <Link to=
                 {
                     this.state.customer_type === 4
                     ? "#"
                     : this.state.newyearlydata[0].plan == 'premium' ?
                       this.dlink(
                         this.state.newyearlydata[0].plan_code,
                         parseInt(this.state.newyearlydata[0].amount),
                         this.state.newyearlydata[0].plan_name,
                         this.state.newyearlydata[0].package_currency.currency_symbol,
                         this.state.newyearlydata[0].plan_code
                       )
                     : this.state.newyearlydata[1].plan == 'premium' ?
                       this.dlink(
                         this.state.newyearlydata[1].plan_code,
                         parseInt(this.state.newyearlydata[1].amount),
                         this.state.newyearlydata[1].plan_name,
                         this.state.newyearlydata[1].package_currency.currency_symbol,
                         this.state.newyearlydata[1].plan_code
                       )
                     : this.state.newyearlydata[2].plan == 'premium' ?
                       this.dlink(
                         this.state.newyearlydata[2].plan_code,
                         parseInt(this.state.newyearlydata[2].amount),
                         this.state.newyearlydata[2].plan_name,
                         this.state.newyearlydata[2].package_currency.currency_symbol,
                         this.state.newyearlydata[2].plan_code
                       )
                     :
                      this.dlink(
                         this.state.newyearlydata[2].plan_code,
                         parseInt(this.state.newyearlydata[2].amount),
                         this.state.newyearlydata[2].plan_name,
                         this.state.newyearlydata[2].package_currency.currency_symbol,
                         this.state.newyearlydata[2].plan_code
                       )


                 }
                 onClick={ 
                    this.state.newyearlydata[0].plan == 'premium' ?
                    (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newyearlydata[0].plan_code,
                      2)
                    : this.state.newyearlydata[1].plan == 'premium' ?
                      (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newyearlydata[1].plan_code,
                      2)
                    : this.state.newyearlydata[2].plan == 'premium' ?
                      (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newyearlydata[2].plan_code,
                      2)
                    :
                    (e) => this.handleWooCustomer(e,
                    this.state.customer_type,
                    this.state.newyearlydata[2].plan_code,
                    2)
                   }

             >
             Select
         </Link>
     )}
 </>
: null
}
    
    </div>   
    <div className="pricedowngrade"></div> 
    
    <div class="mobi-tab">
        <input type="checkbox" id="chck16" />
        <label class="mobi-tab-label" for="chck16">View Features</label>
    <div class="mobi-tab-content">
    <div class="price-second-half">
    
    <div className="featurebox wordpress-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                                
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Merge apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Language selection</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>App analytics</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Easy publish</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                                
                            </div>    
    
    <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
        
    </div>
        </div>
    </div>
    
</div>
: null
}

</div>
</div>  

</div>

<div className={"tab-content " + (this.state.checkedtab === 'lifetime' ? '' : 'd-none')}>

<div class="pricingplans allflex">

<div class="pricebox">
    
    <div class="price-first-half">
        
        <div class="borderblue"></div>
        
    <div class="allflex">
        
        <h2>Free</h2>
        
        <div class="platforms">
        <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>

        <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
        </div>
        
    </div>  
    
    <h4>{this.state.customer_billing_type == 3 
                                ? this.state.paddlecurreny + '0'
                                : '$0'}<span className="pricing-decimal">.00</span></h4>
    <h5></h5>
    
    { 
      this.state.one_time[0] !== undefined && this.state.one_time[0].is_subscribed === 1 ? 
      (
         <Link to="#" className="currentplan">
           Current
         </Link>
       )
       : this.state.is_deal_plan == 1 ?
        (
            <Link to="#"  onClick={(e) => this.downgradepopupdeal()}>
                Select
            </Link> 
        )
        : this.state.currentplanduration === 4 ?
        (
            <Link to="#" className="lowerplan">
                Unavailable
            </Link> 
        ) 
       : this.state.downgrade_at_term_end === 1 ?
       (
           <Link to="#" className="lowerplan">
              Unavailable
           </Link>
     )
     : this.state.one_time[0] !== undefined && this.state.one_time[0].show_plan === 0 ? 
       (
          <Link to="#" className="lowerplan">
             Unavailable
          </Link>
        )
        : this.state.customer_type === 4 &&
          this.state.reseller_licence_id === null ? 
        (
          <a
            target="_blank"
            href={
              process.env.REACT_APP_WOOCOMMERCE_URL +
              "my-account/my-subscriptions/"
            }
          >
            Select{" "}
            <img
              src={
                require("../../assets/images/dashboard/Goto.png")
                  .default
              }
              alt="Goto"
            />
          </a>
        ) : 
        (
          <Link
            to="#"
            onClick={(e) => this.removepopup()}
          >
            Select
          </Link>
        )
    }
    </div>
    <div className="pricedowngrade">
    {this.state.downgrade_at_term_end === 1 && this.state.endcycledata !== null? 
                            
                            'Downgrades on '  + moment(this.state.endcycledata).format("Do MMM, YYYY")
                         :  this.state.downgrade_at_term_end === 1 && this.state.endcycledata === null ?
                           'Downgrade scheduled'
                         : ''}
     {this.state.downgrade_at_term_end === 1 ? 
    <img onClick={(e) =>
                  this.state.customer_type === 4 ? 
                  this.handleWooCustomer(e,this.state.customer_type,this.state.one_time[0].plan_code)
                //  : this.previewdowngrade(e)
                : this.handleReactivePopUp(e,"cancel")
                } 
    src={require("../../assets/images/pricing/red-cross.png").default} alt="red-cross"/>
    : ''}
              
    </div>
    
    <div class="mobi-tab">
        <input type="checkbox" id="chck21" />
        <label class="mobi-tab-label" for="chck21">View Features</label>
    <div class="mobi-tab-content">
    <div class="price-second-half">
    
    <div className="featurebox wordpress-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>    
                                
                            </div>
    
    <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    </div>
        </div>
    </div>
</div>
{
    (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'starter' ) ||
    (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'starter' ) ||
    (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'starter' ) 
?
<div class="pricebox">
    
    <div class="price-first-half">
    
    <div class="borderyellow"></div>
    <div class="allflex">
        
        <h2>Starter</h2>
        
        <div class="platforms">
        <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
        
        <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
    </div>
        
    </div>  
    
    {this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0] !== null ?
 <>
                      {this.state.customer_billing_type !== 3 ?
                           <h4>
                           {
                             this.state.newlifetimedata[0].plan == 'starter' ?
                             this.state.newlifetimedata[0].package_currency.currency_symbol +
                             this.state.newlifetimedata[0].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[1].plan == 'starter' ?
                             this.state.newlifetimedata[1].package_currency.currency_symbol +
                             this.state.newlifetimedata[1].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[2].plan == 'starter' ?
                             this.state.newlifetimedata[2].package_currency.currency_symbol +
                             this.state.newlifetimedata[2].amount.replace(".00", " ")
                            :
                             '$ 79'
                           }
                           </h4>
                         :
                         <>
                         {
                           this.state.newlifetimedata[0].plan == 'starter' ?
                           ReactHtmlParser(this.state.newlifetimedata[0].paddleprice)
                          :
                           this.state.newlifetimedata[1].plan == 'starter' ?
                           ReactHtmlParser(this.state.newlifetimedata[1].paddleprice)
                          :
                           this.state.newlifetimedata[2].plan == 'starter' ?
                           ReactHtmlParser(this.state.newlifetimedata[2].paddleprice)
                          :
                           '$ 499'
                         }
                         </>
                        }
    <h5>one time payment</h5>
    {
    (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'starter' && this.state.newlifetimedata[0].is_subscribed === 1) ||
    (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'starter' && this.state.newlifetimedata[1].is_subscribed === 1) ||
    (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'starter' && this.state.newlifetimedata[2].is_subscribed === 1) 
    ? 
        (
            <Link to="#" className="currentplan">
                Current
            </Link>
        )
    :   (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'starter' && this.state.newlifetimedata[0].show_plan === 0) ||
        (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'starter' && this.state.newlifetimedata[1].show_plan === 0) ||
        (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'starter' && this.state.newlifetimedata[2].show_plan === 0) 
    ? 
        (
            <Link to="#" className="lowerplan">
                    Unavailable
            </Link>
        ) 
    :   (
        <Link to=
             {
                 this.state.customer_type === 4
                 ? "#"
                 : this.state.newlifetimedata[0].plan == 'starter' ?
                   this.dlink(
                     this.state.newlifetimedata[0].plan_code,
                     parseInt(this.state.newlifetimedata[0].amount),
                     this.state.newlifetimedata[0].plan_name,
                     this.state.newlifetimedata[0].package_currency.currency_symbol,
                     this.state.newlifetimedata[0].plan_code
                   )
                 : this.state.newlifetimedata[1].plan == 'starter' ?
                   this.dlink(
                     this.state.newlifetimedata[1].plan_code,
                     parseInt(this.state.newlifetimedata[1].amount),
                     this.state.newlifetimedata[1].plan_name,
                     this.state.newlifetimedata[1].package_currency.currency_symbol,
                     this.state.newlifetimedata[1].plan_code
                   )
                 : this.state.newlifetimedata[2].plan == 'starter' ?
                   this.dlink(
                     this.state.newlifetimedata[2].plan_code,
                     parseInt(this.state.newlifetimedata[2].amount),
                     this.state.newlifetimedata[2].plan_name,
                     this.state.newlifetimedata[2].package_currency.currency_symbol,
                     this.state.newlifetimedata[2].plan_code
                   )
                 :
                  this.dlink(
                     this.state.newlifetimedata[0].plan_code,
                     parseInt(this.state.newlifetimedata[0].amount),
                     this.state.newlifetimedata[0].plan_name,
                     this.state.newlifetimedata[0].package_currency.currency_symbol,
                     this.state.newlifetimedata[0].plan_code
                   )
             }
             onClick={ 
                this.state.newlifetimedata[0].plan == 'starter' ?
                (e) => this.handleWooCustomer(e,
                  this.state.customer_type,
                  this.state.newlifetimedata[0].plan_code,
                  0)
                : this.state.newlifetimedata[1].plan == 'starter' ?
                  (e) => this.handleWooCustomer(e,
                  this.state.customer_type,
                  this.state.newlifetimedata[1].plan_code,
                  0)
                : this.state.newlifetimedata[2].plan == 'starter' ?
                  (e) => this.handleWooCustomer(e,
                  this.state.customer_type,
                  this.state.newlifetimedata[2].plan_code,
                  0)
                :
                (e) => this.handleWooCustomer(e,
                this.state.customer_type,
                this.state.newlifetimedata[0].plan_code,
                0)
               }

         >
         Select
     </Link>
    )}
 </>
: null
}
    
    </div>
    <div className="pricedowngrade"></div>
        
    <div class="mobi-tab">
        <input type="checkbox" id="chck22" />
        <label class="mobi-tab-label" for="chck22">View Features</label>
    <div class="mobi-tab-content">
    <div class="price-second-half">
    
    <div className="featurebox wordpress-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div> 

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>   
                                
                            </div>    
    
    <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    </div> 
        </div>
    </div>
        
</div>
: null
}
{
(this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'pro' ) ||
(this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'pro' ) ||
(this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'pro' ) 
?

<div class="pricebox">
    
    <div class="price-first-half">
    
    <div class="borderorange"></div>
    
    <div class="allflex">
        
        <h2>Pro</h2>
        
        <div class="platforms">
        <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
        
        <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
        
        <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
    </div>
        
    </div>  
    
    {this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0] !== null ?
 <>
     {this.state.customer_billing_type !== 3 ?
                           <h4>
                           {
                             this.state.newlifetimedata[0].plan == 'pro' ?
                             this.state.newlifetimedata[0].package_currency.currency_symbol +
                             this.state.newlifetimedata[0].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[1].plan == 'pro' ?
                             this.state.newlifetimedata[1].package_currency.currency_symbol +
                             this.state.newlifetimedata[1].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[2].plan == 'pro' ?
                             this.state.newlifetimedata[2].package_currency.currency_symbol +
                             this.state.newlifetimedata[2].amount.replace(".00", " ")
                            :
                             '$ 79'
                           }
                           </h4>
                         :
                         <>
                         {
                           this.state.newlifetimedata[0].plan == 'pro' ?
                           ReactHtmlParser(this.state.newlifetimedata[0].paddleprice)
                          :
                           this.state.newlifetimedata[1].plan == 'pro' ?
                           ReactHtmlParser(this.state.newlifetimedata[1].paddleprice)
                          :
                           this.state.newlifetimedata[2].plan == 'pro' ?
                           ReactHtmlParser(this.state.newlifetimedata[2].paddleprice)
                          :
                           '$ 499'
                         }
                         </>
                        }
    <h5>one time payment</h5>
    {
     (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'pro' && this.state.newlifetimedata[0].is_subscribed === 1) ||
     (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'pro' && this.state.newlifetimedata[1].is_subscribed === 1) ||
     (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'pro' && this.state.newlifetimedata[2].is_subscribed === 1) 
     ? 
         (
             <Link to="#" className="currentplan">
                 Current
             </Link>
         )
     :   (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'pro' && this.state.newlifetimedata[0].show_plan === 0) ||
         (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'pro' && this.state.newlifetimedata[1].show_plan === 0) ||
         (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'pro' && this.state.newlifetimedata[2].show_plan === 0) 
     ? 
         (
             <Link to="#" className="lowerplan">
                 Unavailable
             </Link>
         )  
        :   (
            <Link to=
                 {
                     this.state.customer_type === 4
                     ? "#"
                     : this.state.newlifetimedata[0].plan == 'pro' ?
                       this.dlink(
                         this.state.newlifetimedata[0].plan_code,
                         parseInt(this.state.newlifetimedata[0].amount),
                         this.state.newlifetimedata[0].plan_name,
                         this.state.newlifetimedata[0].package_currency.currency_symbol,
                         this.state.newlifetimedata[0].plan_code
                       )
                     : this.state.newlifetimedata[1].plan == 'pro' ?
                       this.dlink(
                         this.state.newlifetimedata[1].plan_code,
                         parseInt(this.state.newlifetimedata[1].amount),
                         this.state.newlifetimedata[1].plan_name,
                         this.state.newlifetimedata[1].package_currency.currency_symbol,
                         this.state.newlifetimedata[1].plan_code
                       )
                     : this.state.newlifetimedata[2].plan == 'pro' ?
                       this.dlink(
                         this.state.newlifetimedata[2].plan_code,
                         parseInt(this.state.newlifetimedata[2].amount),
                         this.state.newlifetimedata[2].plan_name,
                         this.state.newlifetimedata[2].package_currency.currency_symbol,
                         this.state.newlifetimedata[2].plan_code
                       )
                     :
                      this.dlink(
                         this.state.newlifetimedata[1].plan_code,
                         parseInt(this.state.newlifetimedata[1].amount),
                         this.state.newlifetimedata[1].plan_name,
                         this.state.newlifetimedata[1].package_currency.currency_symbol,
                         this.state.newlifetimedata[1].plan_code
                       )
                 }
                 onClick={ 
                    this.state.newlifetimedata[0].plan == 'pro' ?
                    (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newlifetimedata[0].plan_code,
                      1)
                    : this.state.newlifetimedata[1].plan == 'pro' ?
                      (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newlifetimedata[1].plan_code,
                      1)
                    : this.state.newlifetimedata[2].plan == 'pro' ?
                      (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newlifetimedata[2].plan_code,
                      1)
                    :
                    (e) => this.handleWooCustomer(e,
                    this.state.customer_type,
                    this.state.newlifetimedata[1].plan_code,
                    1)
                   }


             >
             Select
         </Link>
        )}
 </>
: null
}

    </div>  
    <div className="pricedowngrade"></div>  
        
    <div class="mobi-tab">
        <input type="checkbox" id="chck23" />
        <label class="mobi-tab-label" for="chck23">View Features</label>
    <div class="mobi-tab-content">
    <div class="price-second-half">
    
    <div className="featurebox wordpress-featurebox">
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                                
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                            </div>    
    
    <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    </div>  
        </div>
    </div>
        
</div>
: null
}
{
(this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'premium' ) ||
(this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'premium' ) ||
(this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'premium' ) 
?

<div class="pricebox">
    
    <div class="price-first-half">
    
    <div class="bordergreen"></div>
    
    <div class="allflex">
        
        <h2>Premium</h2>
        
        <div class="platforms">
        <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
        
        <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
        
        <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
    </div>
        
    </div> 
    
    {this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0] !== null ?
 <>
    {this.state.customer_billing_type !== 3 ?
                           <h4>
                           {
                             this.state.newlifetimedata[0].plan == 'premium' ?
                             this.state.newlifetimedata[0].package_currency.currency_symbol +
                             this.state.newlifetimedata[0].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[1].plan == 'premium' ?
                             this.state.newlifetimedata[1].package_currency.currency_symbol +
                             this.state.newlifetimedata[1].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[2].plan == 'premium' ?
                             this.state.newlifetimedata[2].package_currency.currency_symbol +
                             this.state.newlifetimedata[2].amount.replace(".00", " ")
                            :
                             '$ 79'
                           }
                           </h4>
                         :
                         <>
                         {
                           this.state.newlifetimedata[0].plan == 'premium' ?
                           ReactHtmlParser(this.state.newlifetimedata[0].paddleprice)
                          :
                           this.state.newlifetimedata[1].plan == 'premium' ?
                           ReactHtmlParser(this.state.newlifetimedata[1].paddleprice)
                          :
                           this.state.newlifetimedata[2].plan == 'premium' ?
                           ReactHtmlParser(this.state.newlifetimedata[2].paddleprice)
                          :
                           '$ 499'
                         }
                         </>
                        }
    <h5>one time payment</h5>

    {
    (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'premium' && this.state.newlifetimedata[0].is_subscribed === 1) ||
    (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'premium' && this.state.newlifetimedata[1].is_subscribed === 1) ||
    (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'premium' && this.state.newlifetimedata[2].is_subscribed === 1) 
    ? 
        (
            <Link to="#" className="currentplan">
                Current
            </Link>
        ) 
    :   (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'premium' && this.state.newlifetimedata[0].show_plan === 0) ||
        (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'premium' && this.state.newlifetimedata[1].show_plan === 0) ||
        (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'premium' && this.state.newlifetimedata[2].show_plan === 0) 
    ? 
        (
            <Link to="#" className="lowerplan">
                Unavailable
            </Link>
        ) 
        :   (
            <Link to=
                 {
                     this.state.customer_type === 4
                     ? "#"
                     : this.state.newlifetimedata[0].plan == 'premium' ?
                       this.dlink(
                         this.state.newlifetimedata[0].plan_code,
                         parseInt(this.state.newlifetimedata[0].amount),
                         this.state.newlifetimedata[0].plan_name,
                         this.state.newlifetimedata[0].package_currency.currency_symbol,
                         this.state.newlifetimedata[0].plan_code
                       )
                     : this.state.newlifetimedata[1].plan == 'premium' ?
                       this.dlink(
                         this.state.newlifetimedata[1].plan_code,
                         parseInt(this.state.newlifetimedata[1].amount),
                         this.state.newlifetimedata[1].plan_name,
                         this.state.newlifetimedata[1].package_currency.currency_symbol,
                         this.state.newlifetimedata[1].plan_code
                       )
                     : this.state.newlifetimedata[2].plan == 'premium' ?
                       this.dlink(
                         this.state.newlifetimedata[2].plan_code,
                         parseInt(this.state.newlifetimedata[2].amount),
                         this.state.newlifetimedata[2].plan_name,
                         this.state.newlifetimedata[2].package_currency.currency_symbol,
                         this.state.newlifetimedata[2].plan_code
                       )
                     :
                      this.dlink(
                         this.state.newlifetimedata[2].plan_code,
                         parseInt(this.state.newlifetimedata[2].amount),
                         this.state.newlifetimedata[2].plan_name,
                         this.state.newlifetimedata[2].package_currency.currency_symbol,
                         this.state.newlifetimedata[2].plan_code
                       )
                 }
                 onClick={ 
                    this.state.newlifetimedata[0].plan == 'premium' ?
                    (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newlifetimedata[0].plan_code,
                      2)
                    : this.state.newlifetimedata[1].plan == 'premium' ?
                      (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newlifetimedata[1].plan_code,
                      2)
                    : this.state.newlifetimedata[2].plan == 'premium' ?
                      (e) => this.handleWooCustomer(e,
                      this.state.customer_type,
                      this.state.newlifetimedata[2].plan_code,
                      2)
                    :
                    (e) => this.handleWooCustomer(e,
                    this.state.customer_type,
                    this.state.newlifetimedata[2].plan_code,
                    2)
                   }

             >
             Select
         </Link>
        )}
 </>
: null
}
    
    </div>  
    <div className="pricedowngrade"></div>  
        
    <div class="mobi-tab">
        <input type="checkbox" id="chck24" />
        <label class="mobi-tab-label" for="chck24">View Features</label>
    <div class="mobi-tab-content">
    <div class="price-second-half">
    
    <div className="featurebox wordpress-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                                
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Merge apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Language selection</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>App analytics</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Easy publish</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                                
                            </div>    
    
    <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
    
    <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
        
    </div>
        </div>
    </div>
    
</div>
: null
}

</div> 

</div>  
                   
            </div>
            : this.state.selectiontype !== '' && this.state.selectiontype === 5 ?
            <div className="tabbed">
              {this.state.monthlydata !== '' && this.state.monthlydata !== undefined ?
                        <input type="radio" id="tab1" name="css-tabs"  onChange={() => {this.handlechecktab('monthly')}}  defaultChecked={this.state.checkedtab == 'monthly' ? true : '' } />
                    : null
                    }
                    {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                        <input type="radio" id="tab2" name="css-tabs" onChange={() => {this.handlechecktab('yearly')}}  defaultChecked={this.state.checkedtab == 'yearly' ? true : '' } />
                    : null
                    }
                    {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                        <input type="radio" id="tab3" name="css-tabs" onChange={() => {this.handlechecktab('lifetime')}}  defaultChecked={this.state.checkedtab == 'lifetime' ? true : '' } />
                    : null
                    }
                
                <ul className="tabs">
                {this.state.monthlydata !== '' && this.state.monthlydata !== undefined ?
                    <li className="tab"><label for="tab1">Monthly</label></li>
                : null
                }
                {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                    <li className="tab"><label for="tab2">Yearly</label></li>
                : null
                }
                {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                    <li className="tab"><label for="tab3">Lifetime</label></li>
                : null
                }
                </ul>
                 
                 
            <div className={"tab-content " + (this.state.checkedtab === 'monthly' ? '' : 'd-none')}>
                  
                  <div class="pricingplans allflex">
                  
                  <div class="pricebox">
                      
                      <div class="price-first-half">
                          
                          <div class="borderblue"></div>
                          
                      <div class="allflex">
                          
                          <h2>Free</h2>
                          
                          <div class="platforms">
                          <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>

                          <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                          </div>
                          
                      </div>  
                      
                      <h4>{this.state.customer_billing_type == 3 
                                ? this.state.paddlecurreny + '0'
                                : '$0'}<span className="pricing-decimal">.00</span></h4>
                      <h5></h5>
                      
                      { 
                         this.state.one_time[0] !== undefined && this.state.one_time[0].is_subscribed === 1 ? 
                         (
                            <Link to="#" className="currentplan">
                              Current
                            </Link>
                          ) 
                          : this.state.is_deal_plan == 1 ?
                          (
                              <Link to="#"  onClick={(e) => this.downgradepopupdeal()}>
                                  Select
                              </Link> 
                          )
                          : this.state.currentplanduration === 4 ?
                          (
                              <Link to="#" className="lowerplan">
                                  Unavailable
                              </Link> 
                          )
                          : this.state.downgrade_at_term_end === 1 ?
                          (
                              <Link to="#" className="lowerplan">
                                 Unavailable
                              </Link>
                        )
                          : this.state.one_time[0] !== undefined && this.state.one_time[0].show_plan === 0 ? 
                          (
                             <Link to="#" className="lowerplan">
                                Unavailable
                             </Link>
                          ) 
                          : parseInt(
                              localStorage.getItem("customer_type")
                            ) === 4 &&
                            this.state.reseller_licence_id === null ? 
                          (
                            <a
                              target="_blank"
                              href={
                                process.env.REACT_APP_WOOCOMMERCE_URL +
                                "my-account/my-subscriptions/"
                              }
                            >
                              Select{" "}
                              <img
                                src={
                                  require("../../assets/images/dashboard/Goto.png")
                                    .default
                                }
                                alt="Goto"
                              />
                            </a>
                          ) : 
                          (
                            <Link
                              to="#"
                              onClick={(e) => this.removepopup()}
                            >
                              Select
                            </Link>
                          )
                      }
                      </div>
                      <div className="pricedowngrade">
                      {this.state.downgrade_at_term_end === 1 && this.state.endcycledata !== null? 
                            
                            'Downgrades on '  + moment(this.state.endcycledata).format("Do MMM, YYYY")
                         :  this.state.downgrade_at_term_end === 1 && this.state.endcycledata === null ?
                           'Downgrade scheduled'
                         : ''}
                       {this.state.downgrade_at_term_end === 1 ? 
                      <img onClick={(e) =>
                                    this.state.customer_type === 4 ? 
                                    this.handleWooCustomer(e,this.state.customer_type,this.state.one_time[0].plan_code)
                                  //  : this.previewdowngrade(e)
                                  : this.handleReactivePopUp(e,"cancel")
                                  } 
                      src={require("../../assets/images/pricing/red-cross.png").default} alt="red-cross"/>
                      : ''}
                                
                      </div>
                      
                      <div class="mobi-tab">
                                <input type="checkbox" id="chck5" />
                                <label class="mobi-tab-label" for="chck5">View Features</label>
                            <div class="mobi-tab-content">
                            <div class="price-second-half">
                            
                            <div className="featurebox woocommerce-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WooCommerce to app</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>
                                
                                
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>    
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div> 
                                
                            </div>
                            
                            <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            </div>
                                </div>
                            </div>
                  </div>
                  {
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'starter' ) ||
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'starter' ) ||
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'starter' ) 
                        ?
                        
                        <div class="pricebox">
                            
                            <div class="price-first-half">
                            
                            <div class="borderyellow"></div>
                            <div class="allflex">
                                
                                <h2>Starter</h2>
                                
                                <div class="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            </div>
                                
                            </div>  
                            
                            {this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0] !== null ?
                         <>
                             {this.state.customer_billing_type !== 3 ?
                                <>
                                    <h4>
                                    {
                                    this.state.newmonthlydata[0].plan == 'starter' ?
                                    this.state.newmonthlydata[0].package_currency.currency_symbol +
                                    this.state.newmonthlydata[0].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[1].plan == 'starter' ?
                                    this.state.newmonthlydata[1].package_currency.currency_symbol +
                                    this.state.newmonthlydata[1].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[2].plan == 'starter' ?
                                    this.state.newmonthlydata[2].package_currency.currency_symbol +
                                    this.state.newmonthlydata[2].amount.replace(".00", " ")
                                    :
                                    '$ 29'
                                    }
                                    </h4>
                                    </>
                                :
                                <>
                                {
                                  this.state.newmonthlydata[0].plan == 'starter' ?
                                  ReactHtmlParser(this.state.newmonthlydata[0].paddleprice)
                                 :
                                  this.state.newmonthlydata[1].plan == 'starter' ?
                                  ReactHtmlParser(this.state.newmonthlydata[1].paddleprice)
                                 :
                                  this.state.newmonthlydata[2].plan == 'starter' ?
                                  ReactHtmlParser(this.state.newmonthlydata[2].paddleprice)
                                 :
                                  '$ 29'
                                }
                                </>
                                } 
                            <h5>per month</h5>
                          
                        {
                         (this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'starter' && this.state.newmonthlydata[0].is_subscribed === 1) ||
                         (this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'starter' && this.state.newmonthlydata[1].is_subscribed === 1) ||
                         (this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'starter' && this.state.newmonthlydata[2].is_subscribed === 1) 
                         ? 
                             (
                                 <Link to="#" className="currentplan">
                                     Current
                                 </Link>
                             ) 
                         : 
                             (this.state.newmonthlydata[0].plan == 'starter' && this.state.newmonthlydata[0].show_plan === 0) ||
                             (this.state.newmonthlydata[1].plan == 'starter' && this.state.newmonthlydata[1].show_plan === 0) ||
                             (this.state.newmonthlydata[2].plan == 'starter' && this.state.newmonthlydata[2].show_plan === 0) 
                         ?   
                         (
                             <Link to="#"  className="lowerplan">
                                Unavailable
                             </Link>
                         )
                        :   (
                                   <Link to=
                                        {
                                            this.state.customer_type === 4
                                            ? "#"
                                            : this.state.newmonthlydata[0].plan == 'starter' ?
                                              this.dlink(
                                                this.state.newmonthlydata[0].plan_code,
                                                parseInt(this.state.newmonthlydata[0].amount),
                                                this.state.newmonthlydata[0].plan_name,
                                                this.state.newmonthlydata[0].package_currency.currency_symbol,
                                                this.state.newmonthlydata[0].plan_code
                                              )
                                            : this.state.newmonthlydata[1].plan == 'starter' ?
                                              this.dlink(
                                                this.state.newmonthlydata[1].plan_code,
                                                parseInt(this.state.newmonthlydata[1].amount),
                                                this.state.newmonthlydata[1].plan_name,
                                                this.state.newmonthlydata[1].package_currency.currency_symbol,
                                                this.state.newmonthlydata[1].plan_code
                                              )
                                            : this.state.newmonthlydata[2].plan == 'starter' ?
                                              this.dlink(
                                                this.state.newmonthlydata[2].plan_code,
                                                parseInt(this.state.newmonthlydata[1].amount),
                                                this.state.newmonthlydata[2].plan_name,
                                                this.state.newmonthlydata[2].package_currency.currency_symbol,
                                                this.state.newmonthlydata[2].plan_code
                                              )
                                            :
                                             this.dlink(
                                                this.state.newmonthlydata[0].plan_code,
                                                parseInt(this.state.newmonthlydata[0].amount),
                                                this.state.newmonthlydata[0].plan_name,
                                                this.state.newmonthlydata[0].package_currency.currency_symbol,
                                                this.state.newmonthlydata[0].plan_code
                                              )


                                        }

                                        onClick={ 
                                            this.state.newmonthlydata[0].plan == 'starter' ?
                                            (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newmonthlydata[0].plan_code,
                                              0)
                                            : this.state.newmonthlydata[1].plan == 'starter' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newmonthlydata[1].plan_code,
                                              0)
                                            : this.state.newmonthlydata[2].plan == 'starter' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newmonthlydata[2].plan_code,
                                              0)
                                            :
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newmonthlydata[0].plan_code,
                                            0)
                                           }

                                    >
                                    Select
                                </Link>
                            )}
                         </>
                        : null
                        }
                            
                            </div>
                            <div className="pricedowngrade"></div>
                                
                            <div class="mobi-tab">
                                <input type="checkbox" id="chck6" />
                                <label class="mobi-tab-label" for="chck6">View Features</label>
                            <div class="mobi-tab-content">
                            <div class="price-second-half">
                            
                            <div className="featurebox woocommerce-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WooCommerce to app</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>
                                
                                
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>    
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div> 

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                            </div>    
                            
                            <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            </div> 
                                </div>
                            </div>
                                
                        </div>
                        : null
                    } 
                     {
                        (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'pro' ) ||
                        (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'pro' ) ||
                        (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'pro' ) 
                    ?
                        
                        <div class="pricebox">
                            
                            <div class="price-first-half">
                            
                            <div class="borderorange"></div>
                            
                            <div class="allflex">
                                
                                <h2>Pro</h2>
                                
                                <div class="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                
                                <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                            </div>
                                
                            </div>  
                            
                            {this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0] !== null ?
                         <>
                            {this.state.customer_billing_type !== 3 ?
                                <>
                                    <h4>
                                    {
                                    this.state.newmonthlydata[0].plan == 'pro' ?
                                    this.state.newmonthlydata[0].package_currency.currency_symbol +
                                    this.state.newmonthlydata[0].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[1].plan == 'pro' ?
                                    this.state.newmonthlydata[1].package_currency.currency_symbol +
                                    this.state.newmonthlydata[1].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[2].plan == 'pro' ?
                                    this.state.newmonthlydata[2].package_currency.currency_symbol +
                                    this.state.newmonthlydata[2].amount.replace(".00", " ")
                                    :
                                    '$ 29'
                                    }
                                    </h4>
                                    </>
                                :
                                <>
                                {
                                  this.state.newmonthlydata[0].plan == 'pro' ?
                                  ReactHtmlParser(this.state.newmonthlydata[0].paddleprice)
                                 :
                                  this.state.newmonthlydata[1].plan == 'pro' ?
                                  ReactHtmlParser(this.state.newmonthlydata[1].paddleprice)
                                 :
                                  this.state.newmonthlydata[2].plan == 'pro' ?
                                  ReactHtmlParser(this.state.newmonthlydata[2].paddleprice)
                                 :
                                  '$ 29'
                                }
                                </>
                                } 
                            <h5>per month</h5>
                            {
                        (this.state.newmonthlydata[0].plan == 'pro' && this.state.newmonthlydata[0].is_subscribed === 1) ||
                        (this.state.newmonthlydata[1].plan == 'pro' && this.state.newmonthlydata[1].is_subscribed === 1) ||
                        (this.state.newmonthlydata[2].plan == 'pro' && this.state.newmonthlydata[2].is_subscribed === 1) 
                        ? 
                            (
                                <Link to="#" className="currentplan">
                                    Current
                                </Link>
                            ) 
                        : 
                            (this.state.newmonthlydata[0].plan == 'pro' && this.state.newmonthlydata[0].show_plan === 0) ||
                            (this.state.newmonthlydata[1].plan == 'pro' && this.state.newmonthlydata[1].show_plan === 0) ||
                            (this.state.newmonthlydata[2].plan == 'pro' && this.state.newmonthlydata[2].show_plan === 0) 
                        ?   
                        (
                            <Link to="#"  className="lowerplan">
                                Unavailable
                            </Link>
                        )  
                        :   (
                                   <Link to=
                                        {
                                            this.state.customer_type === 4
                                            ? "#"
                                            : this.state.newmonthlydata[0].plan == 'pro' ?
                                              this.dlink(
                                                this.state.newmonthlydata[0].plan_code,
                                                parseInt(this.state.newmonthlydata[0].amount),
                                                this.state.newmonthlydata[0].plan_name,
                                                this.state.newmonthlydata[0].package_currency.currency_symbol,
                                                this.state.newmonthlydata[0].plan_code
                                              )
                                            : this.state.newmonthlydata[1].plan == 'pro' ?
                                              this.dlink(
                                                this.state.newmonthlydata[1].plan_code,
                                                parseInt(this.state.newmonthlydata[1].amount),
                                                this.state.newmonthlydata[1].plan_name,
                                                this.state.newmonthlydata[1].package_currency.currency_symbol,
                                                this.state.newmonthlydata[1].plan_code
                                              )
                                            : this.state.newmonthlydata[2].plan == 'pro' ?
                                              this.dlink(
                                                this.state.newmonthlydata[2].plan_code,
                                                parseInt(this.state.newmonthlydata[1].amount),
                                                this.state.newmonthlydata[2].plan_name,
                                                this.state.newmonthlydata[2].package_currency.currency_symbol,
                                                this.state.newmonthlydata[2].plan_code
                                              )
                                            :
                                             this.dlink(
                                                this.state.newmonthlydata[1].plan_code,
                                                parseInt(this.state.newmonthlydata[1].amount),
                                                this.state.newmonthlydata[1].plan_name,
                                                this.state.newmonthlydata[1].package_currency.currency_symbol,
                                                this.state.newmonthlydata[1].plan_code
                                              )


                                        }
                                        onClick={ 
                                            this.state.newmonthlydata[0].plan == 'pro' ?
                                            (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newmonthlydata[0].plan_code,
                                              1)
                                            : this.state.newmonthlydata[1].plan == 'pro' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newmonthlydata[1].plan_code,
                                              1)
                                            : this.state.newmonthlydata[2].plan == 'pro' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newmonthlydata[2].plan_code,
                                              1)
                                            :
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newmonthlydata[1].plan_code,
                                            1)
                                           }

                                    >
                                    Select
                                </Link>
                            )}
                         </>
                        : null
                        }
                  
                            </div>  
                            <div className="pricedowngrade"></div>  
                                
                            <div class="mobi-tab">
                                <input type="checkbox" id="chck7" />
                                <label class="mobi-tab-label" for="chck7">View Features</label>
                            <div class="mobi-tab-content">
                            <div class="price-second-half">
                            
                            <div className="featurebox woocommerce-featurebox">
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WooCommerce to app</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                            </div>    
                            
                            <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            </div>
                                </div>
                            </div>
                                
                        </div>
                        : null
                    }
                     {
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'premium' ) ||
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'premium' ) ||
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'premium' ) 
                        ?
                        
                        <div class="pricebox">
                            
                            <div class="price-first-half">
                            
                            <div class="bordergreen"></div>
                            
                            <div class="allflex">
                                
                                <h2>Premium</h2>
                                
                                <div class="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                
                                <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                            </div>
                                
                            </div> 
                            
                            {this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0] !== null ?
                         <>
                             {this.state.customer_billing_type !== 3 ?
                                <>
                                    <h4>
                                    {
                                    this.state.newmonthlydata[0].plan == 'premium' ?
                                    this.state.newmonthlydata[0].package_currency.currency_symbol +
                                    this.state.newmonthlydata[0].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[1].plan == 'premium' ?
                                    this.state.newmonthlydata[1].package_currency.currency_symbol +
                                    this.state.newmonthlydata[1].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[2].plan == 'premium' ?
                                    this.state.newmonthlydata[2].package_currency.currency_symbol +
                                    this.state.newmonthlydata[2].amount.replace(".00", " ")
                                    :
                                    '$ 29'
                                    }
                                    </h4>
                                    </>
                                :
                                <>
                                {
                                  this.state.newmonthlydata[0].plan == 'premium' ?
                                  ReactHtmlParser(this.state.newmonthlydata[0].paddleprice)
                                 :
                                  this.state.newmonthlydata[1].plan == 'premium' ?
                                  ReactHtmlParser(this.state.newmonthlydata[1].paddleprice)
                                 :
                                  this.state.newmonthlydata[2].plan == 'premium' ?
                                  ReactHtmlParser(this.state.newmonthlydata[2].paddleprice)
                                 :
                                  '$ 29'
                                }
                                </>
                                } 
                            <h5>per month</h5>
                        {
                        (this.state.newmonthlydata[0].plan == 'premium' && this.state.newmonthlydata[0].is_subscribed === 1) ||
                        (this.state.newmonthlydata[1].plan == 'premium' && this.state.newmonthlydata[1].is_subscribed === 1) ||
                        (this.state.newmonthlydata[2].plan == 'premium' && this.state.newmonthlydata[2].is_subscribed === 1) 
                        ? 
                            (
                                <Link to="#" className="currentplan">
                                    Current
                                </Link>
                            ) 
                        : 
                            (this.state.newmonthlydata[0].plan == 'premium' && this.state.newmonthlydata[0].show_plan === 0) ||
                            (this.state.newmonthlydata[1].plan == 'premium' && this.state.newmonthlydata[1].show_plan === 0) ||
                            (this.state.newmonthlydata[2].plan == 'premium' && this.state.newmonthlydata[2].show_plan === 0) 
                        ?   
                        (
                            <Link to="#"  className="lowerplan">
                                Unavailable
                            </Link>
                        )  
                            :   (
                                <Link to=
                                     {
                                         this.state.customer_type === 4
                                         ? "#"
                                         : this.state.newmonthlydata[0].plan == 'premium' ?
                                           this.dlink(
                                             this.state.newmonthlydata[0].plan_code,
                                             parseInt(this.state.newmonthlydata[0].amount),
                                             this.state.newmonthlydata[0].plan_name,
                                             this.state.newmonthlydata[0].package_currency.currency_symbol,
                                             this.state.newmonthlydata[0].plan_code
                                           )
                                         : this.state.newmonthlydata[1].plan == 'premium' ?
                                           this.dlink(
                                             this.state.newmonthlydata[1].plan_code,
                                             parseInt(this.state.newmonthlydata[1].amount),
                                             this.state.newmonthlydata[1].plan_name,
                                             this.state.newmonthlydata[1].package_currency.currency_symbol,
                                             this.state.newmonthlydata[1].plan_code
                                           )
                                         : this.state.newmonthlydata[2].plan == 'premium' ?
                                           this.dlink(
                                             this.state.newmonthlydata[2].plan_code,
                                             parseInt(this.state.newmonthlydata[2].amount),
                                             this.state.newmonthlydata[2].plan_name,
                                             this.state.newmonthlydata[2].package_currency.currency_symbol,
                                             this.state.newmonthlydata[2].plan_code
                                           )
                                         :
                                          this.dlink(
                                             this.state.newmonthlydata[2].plan_code,
                                             parseInt(this.state.newmonthlydata[2].amount),
                                             this.state.newmonthlydata[2].plan_name,
                                             this.state.newmonthlydata[2].package_currency.currency_symbol,
                                             this.state.newmonthlydata[2].plan_code
                                           )


                                     }
                                     onClick={ 
                                        this.state.newmonthlydata[0].plan == 'premium' ?
                                        (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newmonthlydata[0].plan_code,
                                          2)
                                        : this.state.newmonthlydata[1].plan == 'premium' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newmonthlydata[1].plan_code,
                                          2)
                                        : this.state.newmonthlydata[2].plan == 'premium' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newmonthlydata[2].plan_code,
                                          2)
                                        :
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newmonthlydata[2].plan_code,
                                        2)
                                       }

                                 >
                                 Select
                             </Link>
                         )}
                         </>
                        : null
                        }
                            
                            </div> 
                            <div className="pricedowngrade"></div>   
                                
                            <div class="mobi-tab">
                                <input type="checkbox" id="chck8" />
                                <label class="mobi-tab-label" for="chck8">View Features</label>
                            <div class="mobi-tab-content">
                            <div class="price-second-half">
                            
                            <div className="featurebox woocommerce-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WooCommerce to app</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Merge apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Language selection</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>App analytics</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Easy publish</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                                
                            </div>    
                            
                            <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                                
                            </div> 
                                </div>
                            </div>
                            
                        </div>
                        : null
                    }
                  
              </div>
              
          </div>    
              
          <div className={"tab-content " + (this.state.checkedtab === 'yearly' ? '' : 'd-none')}>
                
                  <div class="pricingplans allflex">
                  
                  <div class="pricebox">
                      
                      <div class="price-first-half">
                          
                          <div class="borderblue"></div>
                          
                      <div class="allflex">
                          
                          <h2>Free</h2>
                          
                          <div class="platforms">
                          <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>

                          <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                          </div>
                          
                      </div>  
                      
                      <h4>{this.state.customer_billing_type == 3 
                                ? this.state.paddlecurreny + '0'
                                : '$0'}<span className="pricing-decimal">.00</span></h4>
                      <h5></h5>
                      
                      { 
                          this.state.one_time[0] !== undefined && this.state.one_time[0].is_subscribed === 1 ? 
                          (
                             <Link to="#" className="currentplan">
                               Current
                             </Link>
                           )
                           : this.state.is_deal_plan == 1 ?
                          (
                              <Link to="#"  onClick={(e) => this.downgradepopupdeal()}>
                                  Select
                              </Link> 
                          )
                          : this.state.currentplanduration === 4 ?
                          (
                              <Link to="#" className="lowerplan">
                                  Unavailable
                              </Link> 
                          ) 
                           : this.state.downgrade_at_term_end === 1 ?
                           (
                               <Link to="#" className="lowerplan">
                                  Unavailable
                               </Link>
                         )
                           : this.state.one_time[0] !== undefined && this.state.one_time[0].show_plan === 0 ? 
                           (
                              <Link to="#" className="lowerplan">
                                 Unavailable
                              </Link>
                           )  
                          : this.state.customer_type === 4 &&
                            this.state.reseller_licence_id === null ? 
                          (
                            <a
                              target="_blank"
                              href={
                                process.env.REACT_APP_WOOCOMMERCE_URL +
                                "my-account/my-subscriptions/"
                              }
                            >
                              Select{" "}
                              <img
                                src={
                                  require("../../assets/images/dashboard/Goto.png")
                                    .default
                                }
                                alt="Goto"
                              />
                            </a>
                          ) : 
                          (
                            <Link
                              to="#"
                              onClick={(e) => this.removepopup()}
                            >
                              Select
                            </Link>
                          )
                      }
                      </div>
                      <div className="pricedowngrade">
                      {this.state.downgrade_at_term_end === 1 && this.state.endcycledata !== null? 
                            
                            'Downgrades on '  + moment(this.state.endcycledata).format("Do MMM, YYYY")
                         :  this.state.downgrade_at_term_end === 1 && this.state.endcycledata === null ?
                           'Downgrade scheduled'
                         : ''}
                       {this.state.downgrade_at_term_end === 1 ? 
                      <img onClick={(e) =>
                                    this.state.customer_type === 4 ? 
                                    this.handleWooCustomer(e,this.state.customer_type,this.state.one_time[0].plan_code)
                                  //  : this.previewdowngrade(e)
                                  : this.handleReactivePopUp(e,"cancel")
                                  } 
                      src={require("../../assets/images/pricing/red-cross.png").default} alt="red-cross"/>
                      : ''}
                                
                      </div>
                      
                      <div class="mobi-tab">
                                <input type="checkbox" id="chck13" />
                                <label class="mobi-tab-label" for="chck13">View Features</label>
                            <div class="mobi-tab-content">
                            <div class="price-second-half">
                            
                            <div className="featurebox woocommerce-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WooCommerce to app</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>
                                
                                
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>    
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div> 
                                
                            </div>
                            
                            <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            </div>
                                </div>
                            </div>
                  </div>
                  {
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'starter' ) ||
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'starter' ) ||
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'starter' ) 
                        ? 
                        <div class="pricebox">
                            
                            <div class="price-first-half">
                            
                            <div class="borderyellow"></div>
                            <div class="allflex">
                                
                                <h2>Starter</h2>
                                
                                <div class="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            </div>
                                
                            </div>  
                            
                            {this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0] !== null ?
                         <>
                            {this.state.customer_billing_type !== 3 ?
                                <>
                             <h4>
                            {
                              this.state.newyearlydata[0].plan == 'starter' ?
                              this.state.newyearlydata[0].package_currency.currency_symbol +
                              this.state.newyearlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[1].plan == 'starter' ?
                              this.state.newyearlydata[1].package_currency.currency_symbol +
                              this.state.newyearlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[2].plan == 'starter' ?
                              this.state.newyearlydata[2].package_currency.currency_symbol +
                              this.state.newyearlydata[2].amount.replace(".00", " ")
                             :
                              '$ 19'
                            }
                            </h4>
                        </>
                        :
                        <>
                        {
                          this.state.newyearlydata[0].plan == 'starter' ?
                          ReactHtmlParser(this.state.newyearlydata[0].paddleprice)
                         :
                          this.state.newyearlydata[1].plan == 'starter' ?
                          ReactHtmlParser(this.state.newyearlydata[1].paddleprice)
                         :
                          this.state.newyearlydata[2].plan == 'starter' ?
                          ReactHtmlParser(this.state.newyearlydata[2].paddleprice)
                         :
                          '$ 19'
                        }
                        </>
                        }
                            <h5>per month paid yearly</h5>
                          {
                             (this.state.newyearlydata[0].plan == 'starter' && this.state.newyearlydata[0].is_subscribed === 1) ||
                             (this.state.newyearlydata[1].plan == 'starter' && this.state.newyearlydata[1].is_subscribed === 1) ||
                             (this.state.newyearlydata[2].plan == 'starter' && this.state.newyearlydata[2].is_subscribed === 1) 
                             ? 
                                 (
                                     <Link to="#" className="currentplan">
                                         Current
                                     </Link>
                                 ) 
                             : 
                                 (this.state.newyearlydata[0].plan == 'starter' && this.state.newyearlydata[0].show_plan === 0) ||
                                 (this.state.newyearlydata[1].plan == 'starter' && this.state.newyearlydata[1].show_plan === 0) ||
                                 (this.state.newyearlydata[2].plan == 'starter' && this.state.newyearlydata[2].show_plan === 0) 
                             ?   
                             (
                                 <Link to="#"  class="lowerplan">
                                     Unavailable
                                 </Link>
                             ) 
                            :   (
                                <Link to=
                                     {
                                         this.state.customer_type === 4
                                         ? "#"
                                         : this.state.newyearlydata[0].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newyearlydata[0].plan_code,
                                             parseInt(this.state.newyearlydata[0].amount),
                                             this.state.newyearlydata[0].plan_name,
                                             this.state.newyearlydata[0].package_currency.currency_symbol,
                                             this.state.newyearlydata[0].plan_code
                                           )
                                         : this.state.newyearlydata[1].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newyearlydata[1].plan_code,
                                             parseInt(this.state.newyearlydata[1].amount),
                                             this.state.newyearlydata[1].plan_name,
                                             this.state.newyearlydata[1].package_currency.currency_symbol,
                                             this.state.newyearlydata[1].plan_code
                                           )
                                         : this.state.newyearlydata[2].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newyearlydata[2].plan_code,
                                             parseInt(this.state.newyearlydata[2].amount),
                                             this.state.newyearlydata[2].plan_name,
                                             this.state.newyearlydata[2].package_currency.currency_symbol,
                                             this.state.newyearlydata[2].plan_code
                                           )
                                         :
                                          this.dlink(
                                             this.state.newyearlydata[0].plan_code,
                                             parseInt(this.state.newyearlydata[0].amount),
                                             this.state.newyearlydata[0].plan_name,
                                             this.state.newyearlydata[0].package_currency.currency_symbol,
                                             this.state.newyearlydata[0].plan_code
                                           )


                                     }
                                     onClick={ 
                                        this.state.newyearlydata[0].plan == 'starter' ?
                                        (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newyearlydata[0].plan_code,
                                          0)
                                        : this.state.newyearlydata[1].plan == 'starter' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newyearlydata[1].plan_code,
                                          0)
                                        : this.state.newyearlydata[2].plan == 'starter' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newyearlydata[2].plan_code,
                                          0)
                                        :
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newyearlydata[0].plan_code,
                                        0)
                                       }

                                 >
                                 Select
                             </Link>
                         )}
                         </>
                        : null
                        }
                            
                            </div>
                            <div className="pricedowngrade"></div> 
                                
                            <div class="mobi-tab">
                                <input type="checkbox" id="chck14" />
                                <label class="mobi-tab-label" for="chck14">View Features</label>
                            <div class="mobi-tab-content">
                            <div class="price-second-half">
                            
                            <div className="featurebox woocommerce-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WooCommerce to app</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>
                                
                                
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>    
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div> 

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                            </div>    
                            
                            <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            </div> 
                                </div>
                            </div>
                                
                        </div>
                        : null
                    }

                    {
                        (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'pro' ) ||
                        (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'pro' ) ||
                        (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'pro' ) 
                    ?
                        
                        
                        <div class="pricebox">
                            
                            <div class="price-first-half">
                            
                            <div class="borderorange"></div>
                            
                            <div class="allflex">
                                
                                <h2>Pro</h2>
                                
                                <div class="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                
                                <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                            </div>
                                
                            </div>  
                            
                            {this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0] !== null ?
                         <>
                           {this.state.customer_billing_type !== 3 ?
                                <>
                             <h4>
                            {
                              this.state.newyearlydata[0].plan == 'pro' ?
                              this.state.newyearlydata[0].package_currency.currency_symbol +
                              this.state.newyearlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[1].plan == 'pro' ?
                              this.state.newyearlydata[1].package_currency.currency_symbol +
                              this.state.newyearlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[2].plan == 'pro' ?
                              this.state.newyearlydata[2].package_currency.currency_symbol +
                              this.state.newyearlydata[2].amount.replace(".00", " ")
                             :
                              '$ 19'
                            }
                            </h4>
                        </>
                        :
                        <>
                        {
                          this.state.newyearlydata[0].plan == 'pro' ?
                          ReactHtmlParser(this.state.newyearlydata[0].paddleprice)
                         :
                          this.state.newyearlydata[1].plan == 'pro' ?
                          ReactHtmlParser(this.state.newyearlydata[1].paddleprice)
                         :
                          this.state.newyearlydata[2].plan == 'pro' ?
                          ReactHtmlParser(this.state.newyearlydata[2].paddleprice)
                         :
                          '$ 19'
                        }
                        </>
                        }
                            <h5>per month paid yearly</h5>
                            {
                            (this.state.newyearlydata[0].plan == 'pro' && this.state.newyearlydata[0].is_subscribed === 1) ||
                            (this.state.newyearlydata[1].plan == 'pro' && this.state.newyearlydata[1].is_subscribed === 1) ||
                            (this.state.newyearlydata[2].plan == 'pro' && this.state.newyearlydata[2].is_subscribed === 1) 
                            ? 
                                (
                                    <Link to="#" className="currentplan">
                                        Current
                                    </Link>
                                )
                                : 
                                (this.state.newyearlydata[0].plan == 'pro' && this.state.newyearlydata[0].show_plan === 0) ||
                                (this.state.newyearlydata[1].plan == 'pro' && this.state.newyearlydata[1].show_plan === 0) ||
                                (this.state.newyearlydata[2].plan == 'pro' && this.state.newyearlydata[2].show_plan === 0) 
                            ?   
                            (
                                <Link to="#"  class="lowerplan">
                                Unavailable
                            </Link>
                            )  
                            :   (
                                    <Link to=
                                         {
                                             this.state.customer_type === 4
                                             ? "#"
                                             : this.state.newyearlydata[0].plan == 'pro' ?
                                               this.dlink(
                                                 this.state.newyearlydata[0].plan_code,
                                                 parseInt(this.state.newyearlydata[0].amount),
                                                 this.state.newyearlydata[0].plan_name,
                                                 this.state.newyearlydata[0].package_currency.currency_symbol,
                                                 this.state.newyearlydata[0].plan_code
                                               )
                                             : this.state.newyearlydata[1].plan == 'pro' ?
                                               this.dlink(
                                                 this.state.newyearlydata[1].plan_code,
                                                 parseInt(this.state.newyearlydata[1].amount),
                                                 this.state.newyearlydata[1].plan_name,
                                                 this.state.newyearlydata[1].package_currency.currency_symbol,
                                                 this.state.newyearlydata[1].plan_code
                                               )
                                             : this.state.newyearlydata[2].plan == 'pro' ?
                                               this.dlink(
                                                 this.state.newyearlydata[2].plan_code,
                                                 parseInt(this.state.newyearlydata[2].amount),
                                                 this.state.newyearlydata[2].plan_name,
                                                 this.state.newyearlydata[2].package_currency.currency_symbol,
                                                 this.state.newyearlydata[2].plan_code
                                               )
                                             :
                                              this.dlink(
                                                 this.state.newyearlydata[1].plan_code,
                                                 parseInt(this.state.newyearlydata[1].amount),
                                                 this.state.newyearlydata[1].plan_name,
                                                 this.state.newyearlydata[1].package_currency.currency_symbol,
                                                 this.state.newyearlydata[1].plan_code
                                               )
    
    
                                         }
                                         onClick={ 
                                            this.state.newyearlydata[0].plan == 'pro' ?
                                            (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[0].plan_code,
                                              1)
                                            : this.state.newyearlydata[1].plan == 'pro' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[1].plan_code,
                                              1)
                                            : this.state.newyearlydata[2].plan == 'pro' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[2].plan_code,
                                              1)
                                            :
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newyearlydata[1].plan_code,
                                            1)
                                           }
    
                                     >
                                     Select
                                 </Link>
                             )}
                         </>
                        : null
                        }
                  
                            </div>  
                            <div className="pricedowngrade"></div>   
                                
                            <div class="mobi-tab">
                                <input type="checkbox" id="chck15" />
                                <label class="mobi-tab-label" for="chck15">View Features</label>
                            <div class="mobi-tab-content">
                            <div class="price-second-half">
                            
                            <div className="featurebox woocommerce-featurebox">
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WooCommerce to app</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                            </div>    
                            
                            <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            </div> 
                                </div>
                            </div>
                                
                        </div>
                    : null
                }

                {
                    (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'premium' ) ||
                    (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'premium' ) ||
                    (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'premium' ) 
                ?
                        
                        <div class="pricebox">
                            
                            <div class="price-first-half">
                            
                            <div class="bordergreen"></div>
                            
                            <div class="allflex">
                                
                                <h2>Premium</h2>
                                
                                <div class="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                
                                <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                            </div>
                                
                            </div> 
                            
                            {this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0] !== null ?
                         <>
                           {this.state.customer_billing_type !== 3 ?
                                <>
                             <h4>
                            {
                              this.state.newyearlydata[0].plan == 'premium' ?
                              this.state.newyearlydata[0].package_currency.currency_symbol +
                              this.state.newyearlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[1].plan == 'premium' ?
                              this.state.newyearlydata[1].package_currency.currency_symbol +
                              this.state.newyearlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[2].plan == 'premium' ?
                              this.state.newyearlydata[2].package_currency.currency_symbol +
                              this.state.newyearlydata[2].amount.replace(".00", " ")
                             :
                              '$ 19'
                            }
                            </h4>
                        </>
                        :
                        <>
                        {
                          this.state.newyearlydata[0].plan == 'premium' ?
                          ReactHtmlParser(this.state.newyearlydata[0].paddleprice)
                         :
                          this.state.newyearlydata[1].plan == 'premium' ?
                          ReactHtmlParser(this.state.newyearlydata[1].paddleprice)
                         :
                          this.state.newyearlydata[2].plan == 'premium' ?
                          ReactHtmlParser(this.state.newyearlydata[2].paddleprice)
                         :
                          '$ 19'
                        }
                        </>
                        }
                            <h5>per month paid yearly</h5>
                            {
                             (this.state.newyearlydata[0].plan == 'premium' && this.state.newyearlydata[0].is_subscribed === 1) ||
                             (this.state.newyearlydata[1].plan == 'premium' && this.state.newyearlydata[1].is_subscribed === 1) ||
                             (this.state.newyearlydata[2].plan == 'premium' && this.state.newyearlydata[2].is_subscribed === 1) 
                             ? 
                                 (
                                     <Link to="#" className="currentplan">
                                         Current
                                     </Link>
                                 )
                                 : 
                                 (this.state.newyearlydata[0].plan == 'premium' && this.state.newyearlydata[0].show_plan === 0) ||
                                 (this.state.newyearlydata[1].plan == 'premium' && this.state.newyearlydata[1].show_plan === 0) ||
                                 (this.state.newyearlydata[2].plan == 'premium' && this.state.newyearlydata[2].show_plan === 0) 
                             ?   
                             (
                                 <Link to="#"  class="lowerplan">
                                 Unavailable
                             </Link>
                             ) 
                                :   (
                                    <Link to=
                                         {
                                             this.state.customer_type === 4
                                             ? "#"
                                             : this.state.newyearlydata[0].plan == 'premium' ?
                                               this.dlink(
                                                 this.state.newyearlydata[0].plan_code,
                                                 parseInt(this.state.newyearlydata[0].amount),
                                                 this.state.newyearlydata[0].plan_name,
                                                 this.state.newyearlydata[0].package_currency.currency_symbol,
                                                 this.state.newyearlydata[0].plan_code
                                               )
                                             : this.state.newyearlydata[1].plan == 'premium' ?
                                               this.dlink(
                                                 this.state.newyearlydata[1].plan_code,
                                                 parseInt(this.state.newyearlydata[1].amount),
                                                 this.state.newyearlydata[1].plan_name,
                                                 this.state.newyearlydata[1].package_currency.currency_symbol,
                                                 this.state.newyearlydata[1].plan_code
                                               )
                                             : this.state.newyearlydata[2].plan == 'premium' ?
                                               this.dlink(
                                                 this.state.newyearlydata[2].plan_code,
                                                 parseInt(this.state.newyearlydata[2].amount),
                                                 this.state.newyearlydata[2].plan_name,
                                                 this.state.newyearlydata[2].package_currency.currency_symbol,
                                                 this.state.newyearlydata[2].plan_code
                                               )
                                             :
                                              this.dlink(
                                                 this.state.newyearlydata[2].plan_code,
                                                 parseInt(this.state.newyearlydata[2].amount),
                                                 this.state.newyearlydata[2].plan_name,
                                                 this.state.newyearlydata[2].package_currency.currency_symbol,
                                                 this.state.newyearlydata[2].plan_code
                                               )
    
    
                                         }
                                         onClick={ 
                                            this.state.newyearlydata[0].plan == 'premium' ?
                                            (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[0].plan_code,
                                              2)
                                            : this.state.newyearlydata[1].plan == 'premium' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[1].plan_code,
                                              2)
                                            : this.state.newyearlydata[2].plan == 'premium' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[2].plan_code,
                                              2)
                                            :
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newyearlydata[2].plan_code,
                                            2)
                                           }
    
                                     >
                                     Select
                                 </Link>
                             )}
                         </>
                        : null
                        }
                            
                            </div>   
                            <div className="pricedowngrade"></div>  
                                
                            <div class="mobi-tab">
                                <input type="checkbox" id="chck16" />
                                <label class="mobi-tab-label" for="chck16">View Features</label>
                            <div class="mobi-tab-content">
                            <div class="price-second-half">
                            
                            <div className="featurebox woocommerce-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WooCommerce to app</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Merge apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Language selection</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>App analytics</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Easy publish</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                                
                            </div>  
                            
                            <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                                
                            </div>
                                </div>
                            </div>
                            
                        </div>
                : null
            }
         
                  
              </div>
              
          </div>
              
          <div className={"tab-content " + (this.state.checkedtab === 'lifetime' ? '' : 'd-none')}>
              
                  <div class="pricingplans allflex">
                  
                  <div class="pricebox">
                      
                      <div class="price-first-half">
                          
                          <div class="borderblue"></div>
                          
                      <div class="allflex">
                          
                          <h2>Free</h2>
                          
                          <div class="platforms">
                          <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                          </div>
                          
                      </div>  
                      
                      <h4>{this.state.customer_billing_type == 3 
                                ? this.state.paddlecurreny + '0'
                                : '$0'}<span className="pricing-decimal">.00</span></h4>
                      <h5></h5>
                      
                      { 
                         this.state.one_time[0] !== undefined && this.state.one_time[0].is_subscribed === 1 ? 
                         (
                            <Link to="#" className="currentplan">
                              Current
                            </Link>
                          )
                          : this.state.is_deal_plan == 1 ?
                          (
                              <Link to="#"  onClick={(e) => this.downgradepopupdeal()}>
                                  Select
                              </Link> 
                          )
                          : this.state.currentplanduration === 4 ?
                          (
                              <Link to="#" className="lowerplan">
                                  Unavailable
                              </Link> 
                          ) 
                          : this.state.downgrade_at_term_end === 1 ?
                          (
                              <Link to="#" className="lowerplan">
                                 Unavailable
                              </Link>
                        )
                        : this.state.one_time[0] !== undefined && this.state.one_time[0].show_plan === 0 ? 
                          (
                             <Link to="#" className="lowerplan">
                                Unavailable
                             </Link>
                           ) 
                          : this.state.customer_type === 4 &&
                            this.state.reseller_licence_id === null ? 
                          (
                            <a
                              target="_blank"
                              href={
                                process.env.REACT_APP_WOOCOMMERCE_URL +
                                "my-account/my-subscriptions/"
                              }
                            >
                              Select{" "}
                              <img
                                src={
                                  require("../../assets/images/dashboard/Goto.png")
                                    .default
                                }
                                alt="Goto"
                              />
                            </a>
                          ) : 
                          (
                            <Link
                              to="#"
                              onClick={(e) => this.removepopup()}
                            >
                              Select
                            </Link>
                          )
                      }
                      </div>
                      <div className="pricedowngrade">
                      {this.state.downgrade_at_term_end === 1 && this.state.endcycledata !== null? 
                            
                            'Downgrades on '  + moment(this.state.endcycledata).format("Do MMM, YYYY")
                         :  this.state.downgrade_at_term_end === 1 && this.state.endcycledata === null ?
                           'Downgrade scheduled'
                         : ''}
                       {this.state.downgrade_at_term_end === 1 ? 
                      <img onClick={(e) =>
                                    this.state.customer_type === 4 ? 
                                    this.handleWooCustomer(e,this.state.customer_type,this.state.one_time[0].plan_code)
                                  //  : this.previewdowngrade(e)
                                  : this.handleReactivePopUp(e,"cancel")
                                  } 
                      src={require("../../assets/images/pricing/red-cross.png").default} alt="red-cross"/>
                      : ''}
                                
                      </div>
                      
                      <div class="mobi-tab">
                                <input type="checkbox" id="chck21" />
                                <label class="mobi-tab-label" for="chck21">View Features</label>
                            <div class="mobi-tab-content">
                            <div class="price-second-half">
                            
                            <div className="featurebox woocommerce-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WooCommerce to app</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>
                                
                                
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>    
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div> 
                                
                            </div>
                            
                            <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            </div>
                                </div>
                            </div>
                  </div>
                  {
                            (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'starter' ) ||
                            (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'starter' ) ||
                            (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'starter' ) 
                        ?
                        
                        <div class="pricebox">
                            
                            <div class="price-first-half">
                            
                            <div class="borderyellow"></div>
                            <div class="allflex">
                                
                                <h2>Starter</h2>
                                
                                <div class="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            </div>
                                
                            </div>  
                            
                            {this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0] !== null ?
                         <>
                           {this.state.customer_billing_type !== 3 ?
                           <h4>
                           {
                             this.state.newlifetimedata[0].plan == 'starter' ?
                             this.state.newlifetimedata[0].package_currency.currency_symbol +
                             this.state.newlifetimedata[0].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[1].plan == 'starter' ?
                             this.state.newlifetimedata[1].package_currency.currency_symbol +
                             this.state.newlifetimedata[1].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[2].plan == 'starter' ?
                             this.state.newlifetimedata[2].package_currency.currency_symbol +
                             this.state.newlifetimedata[2].amount.replace(".00", " ")
                            :
                             '$ 79'
                           }
                           </h4>
                         :
                         <>
                         {
                           this.state.newlifetimedata[0].plan == 'starter' ?
                           ReactHtmlParser(this.state.newlifetimedata[0].paddleprice)
                          :
                           this.state.newlifetimedata[1].plan == 'starter' ?
                           ReactHtmlParser(this.state.newlifetimedata[1].paddleprice)
                          :
                           this.state.newlifetimedata[2].plan == 'starter' ?
                           ReactHtmlParser(this.state.newlifetimedata[2].paddleprice)
                          :
                           '$ 499'
                         }
                         </>
                        }
                            <h5>one time payment</h5>
                            {
                            (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'starter' && this.state.newlifetimedata[0].is_subscribed === 1) ||
                            (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'starter' && this.state.newlifetimedata[1].is_subscribed === 1) ||
                            (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'starter' && this.state.newlifetimedata[2].is_subscribed === 1) 
                            ? 
                                (
                                    <Link to="#" className="currentplan">
                                        Current
                                    </Link>
                                )
                            :   (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'starter' && this.state.newlifetimedata[0].show_plan === 0) ||
                                (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'starter' && this.state.newlifetimedata[1].show_plan === 0) ||
                                (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'starter' && this.state.newlifetimedata[2].show_plan === 0) 
                            ? 
                                (
                                    <Link to="#" className="lowerplan">
                                            Unavailable
                                    </Link>
                                )
                            :   (
                                <Link to=
                                     {
                                         this.state.customer_type === 4
                                         ? "#"
                                         : this.state.newlifetimedata[0].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newlifetimedata[0].plan_code,
                                             parseInt(this.state.newlifetimedata[0].amount),
                                             this.state.newlifetimedata[0].plan_name,
                                             this.state.newlifetimedata[0].package_currency.currency_symbol,
                                             this.state.newlifetimedata[0].plan_code
                                           )
                                         : this.state.newlifetimedata[1].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newlifetimedata[1].plan_code,
                                             parseInt(this.state.newlifetimedata[1].amount),
                                             this.state.newlifetimedata[1].plan_name,
                                             this.state.newlifetimedata[1].package_currency.currency_symbol,
                                             this.state.newlifetimedata[1].plan_code
                                           )
                                         : this.state.newlifetimedata[2].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newlifetimedata[2].plan_code,
                                             parseInt(this.state.newlifetimedata[2].amount),
                                             this.state.newlifetimedata[2].plan_name,
                                             this.state.newlifetimedata[2].package_currency.currency_symbol,
                                             this.state.newlifetimedata[2].plan_code
                                           )
                                         :
                                          this.dlink(
                                             this.state.newlifetimedata[0].plan_code,
                                             parseInt(this.state.newlifetimedata[0].amount),
                                             this.state.newlifetimedata[0].plan_name,
                                             this.state.newlifetimedata[0].package_currency.currency_symbol,
                                             this.state.newlifetimedata[0].plan_code
                                           )
                                     }
                                     onClick={ 
                                        this.state.newlifetimedata[0].plan == 'starter' ?
                                        (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newlifetimedata[0].plan_code,
                                          0)
                                        : this.state.newlifetimedata[1].plan == 'starter' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newlifetimedata[1].plan_code,
                                          0)
                                        : this.state.newlifetimedata[2].plan == 'starter' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newlifetimedata[2].plan_code,
                                          0)
                                        :
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newlifetimedata[0].plan_code,
                                        0)
                                       }

                                 >
                                 Select
                             </Link>
                            )}
                         </>
                        : null
                        }
                            
                            </div>
                            <div className="pricedowngrade"></div>
                                
                            <div class="mobi-tab">
                                <input type="checkbox" id="chck22" />
                                <label class="mobi-tab-label" for="chck22">View Features</label>
                            <div class="mobi-tab-content">
                            <div class="price-second-half">
                            
                            <div className="featurebox woocommerce-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WooCommerce to app</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>
                                
                                
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>    
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div> 
                                
                            </div>    
                            
                            <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            </div>    
                                </div>
                            </div>
                        </div>
                         : null
                        }
                         {
                            (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'pro' ) ||
                            (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'pro' ) ||
                            (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'pro' ) 
                        ?
                        <div class="pricebox">
                            
                            <div class="price-first-half">
                            
                            <div class="borderorange"></div>
                            
                            <div class="allflex">
                                
                                <h2>Pro</h2>
                                
                                <div class="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                
                                <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                            </div>
                                
                            </div>  
                            
                            {this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0] !== null ?
                         <>
                           {this.state.customer_billing_type !== 3 ?
                           <h4>
                           {
                             this.state.newlifetimedata[0].plan == 'pro' ?
                             this.state.newlifetimedata[0].package_currency.currency_symbol +
                             this.state.newlifetimedata[0].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[1].plan == 'pro' ?
                             this.state.newlifetimedata[1].package_currency.currency_symbol +
                             this.state.newlifetimedata[1].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[2].plan == 'pro' ?
                             this.state.newlifetimedata[2].package_currency.currency_symbol +
                             this.state.newlifetimedata[2].amount.replace(".00", " ")
                            :
                             '$ 79'
                           }
                           </h4>
                         :
                         <>
                         {
                           this.state.newlifetimedata[0].plan == 'pro' ?
                           ReactHtmlParser(this.state.newlifetimedata[0].paddleprice)
                          :
                           this.state.newlifetimedata[1].plan == 'pro' ?
                           ReactHtmlParser(this.state.newlifetimedata[1].paddleprice)
                          :
                           this.state.newlifetimedata[2].plan == 'pro' ?
                           ReactHtmlParser(this.state.newlifetimedata[2].paddleprice)
                          :
                           '$ 499'
                         }
                         </>
                        }
                            <h5>one time payment</h5>
                            {
                            (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'pro' && this.state.newlifetimedata[0].is_subscribed === 1) ||
                            (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'pro' && this.state.newlifetimedata[1].is_subscribed === 1) ||
                            (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'pro' && this.state.newlifetimedata[2].is_subscribed === 1) 
                            ? 
                                (
                                    <Link to="#" className="currentplan">
                                        Current
                                    </Link>
                                )
                            :   (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'pro' && this.state.newlifetimedata[0].show_plan === 0) ||
                                (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'pro' && this.state.newlifetimedata[1].show_plan === 0) ||
                                (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'pro' && this.state.newlifetimedata[2].show_plan === 0) 
                            ? 
                                (
                                    <Link to="#" className="lowerplan">
                                        Unavailable
                                    </Link>
                                )  
                                :   (
                                    <Link to=
                                         {
                                             this.state.customer_type === 4
                                             ? "#"
                                             : this.state.newlifetimedata[0].plan == 'pro' ?
                                               this.dlink(
                                                 this.state.newlifetimedata[0].plan_code,
                                                 parseInt(this.state.newlifetimedata[0].amount),
                                                 this.state.newlifetimedata[0].plan_name,
                                                 this.state.newlifetimedata[0].package_currency.currency_symbol,
                                                 this.state.newlifetimedata[0].plan_code
                                               )
                                             : this.state.newlifetimedata[1].plan == 'pro' ?
                                               this.dlink(
                                                 this.state.newlifetimedata[1].plan_code,
                                                 parseInt(this.state.newlifetimedata[1].amount),
                                                 this.state.newlifetimedata[1].plan_name,
                                                 this.state.newlifetimedata[1].package_currency.currency_symbol,
                                                 this.state.newlifetimedata[1].plan_code
                                               )
                                             : this.state.newlifetimedata[2].plan == 'pro' ?
                                               this.dlink(
                                                 this.state.newlifetimedata[2].plan_code,
                                                 parseInt(this.state.newlifetimedata[2].amount),
                                                 this.state.newlifetimedata[2].plan_name,
                                                 this.state.newlifetimedata[2].package_currency.currency_symbol,
                                                 this.state.newlifetimedata[2].plan_code
                                               )
                                             :
                                              this.dlink(
                                                 this.state.newlifetimedata[1].plan_code,
                                                 parseInt(this.state.newlifetimedata[1].amount),
                                                 this.state.newlifetimedata[1].plan_name,
                                                 this.state.newlifetimedata[1].package_currency.currency_symbol,
                                                 this.state.newlifetimedata[1].plan_code
                                               )
                                         }
                                         onClick={ 
                                            this.state.newlifetimedata[0].plan == 'pro' ?
                                            (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newlifetimedata[0].plan_code,
                                              1)
                                            : this.state.newlifetimedata[1].plan == 'pro' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newlifetimedata[1].plan_code,
                                              1)
                                            : this.state.newlifetimedata[2].plan == 'pro' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newlifetimedata[2].plan_code,
                                              1)
                                            :
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newlifetimedata[1].plan_code,
                                            1)
                                           }
    
    
                                     >
                                     Select
                                 </Link>
                                )}
                         </>
                        : null
                        }
                  
                            </div>    
                            <div className="pricedowngrade"></div>  
                                
                            <div class="mobi-tab">
                                <input type="checkbox" id="chck23" />
                                <label class="mobi-tab-label" for="chck23">View Features</label>
                            <div class="mobi-tab-content">
                            <div class="price-second-half">
                            
                            <div className="featurebox woocommerce-featurebox">
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WooCommerce to app</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                            </div>    
                            
                            <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            </div> 
                                </div>
                            </div>
                                
                        </div>
                        : null
                    }
                     {
                        (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'premium' ) ||
                        (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'premium' ) ||
                        (this.state.newlifetimedata !== '' && this.state.newlifetimedata !== undefined && this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'premium' ) 
                    ?
                        
                        <div class="pricebox">
                            
                            <div class="price-first-half">
                            
                            <div class="bordergreen"></div>
                            
                            <div class="allflex">
                                
                                <h2>Premium</h2>
                                
                                <div class="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                
                                <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                            </div>
                                
                            </div> 
                            
                            {this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0] !== null ?
                         <>
                            {this.state.customer_billing_type !== 3 ?
                           <h4>
                           {
                             this.state.newlifetimedata[0].plan == 'premium' ?
                             this.state.newlifetimedata[0].package_currency.currency_symbol +
                             this.state.newlifetimedata[0].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[1].plan == 'premium' ?
                             this.state.newlifetimedata[1].package_currency.currency_symbol +
                             this.state.newlifetimedata[1].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[2].plan == 'premium' ?
                             this.state.newlifetimedata[2].package_currency.currency_symbol +
                             this.state.newlifetimedata[2].amount.replace(".00", " ")
                            :
                             '$ 79'
                           }
                           </h4>
                         :
                         <>
                         {
                           this.state.newlifetimedata[0].plan == 'premium' ?
                           ReactHtmlParser(this.state.newlifetimedata[0].paddleprice)
                          :
                           this.state.newlifetimedata[1].plan == 'premium' ?
                           ReactHtmlParser(this.state.newlifetimedata[1].paddleprice)
                          :
                           this.state.newlifetimedata[2].plan == 'premium' ?
                           ReactHtmlParser(this.state.newlifetimedata[2].paddleprice)
                          :
                           '$ 499'
                         }
                         </>
                        }
                            <h5>one time payment</h5>
                        
                            {
                             (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'premium' && this.state.newlifetimedata[0].is_subscribed === 1) ||
                             (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'premium' && this.state.newlifetimedata[1].is_subscribed === 1) ||
                             (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'premium' && this.state.newlifetimedata[2].is_subscribed === 1) 
                             ? 
                                 (
                                     <Link to="#" className="currentplan">
                                         Current
                                     </Link>
                                 ) 
                             :   (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'premium' && this.state.newlifetimedata[0].show_plan === 0) ||
                                 (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'premium' && this.state.newlifetimedata[1].show_plan === 0) ||
                                 (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'premium' && this.state.newlifetimedata[2].show_plan === 0) 
                             ? 
                                 (
                                     <Link to="#" className="lowerplan">
                                         Unavailable
                                     </Link>
                                 ) 
                                :   (
                                    <Link to=
                                         {
                                             this.state.customer_type === 4
                                             ? "#"
                                             : this.state.newlifetimedata[0].plan == 'premium' ?
                                               this.dlink(
                                                 this.state.newlifetimedata[0].plan_code,
                                                 parseInt(this.state.newlifetimedata[0].amount),
                                                 this.state.newlifetimedata[0].plan_name,
                                                 this.state.newlifetimedata[0].package_currency.currency_symbol,
                                                 this.state.newlifetimedata[0].plan_code
                                               )
                                             : this.state.newlifetimedata[1].plan == 'premium' ?
                                               this.dlink(
                                                 this.state.newlifetimedata[1].plan_code,
                                                 parseInt(this.state.newlifetimedata[1].amount),
                                                 this.state.newlifetimedata[1].plan_name,
                                                 this.state.newlifetimedata[1].package_currency.currency_symbol,
                                                 this.state.newlifetimedata[1].plan_code
                                               )
                                             : this.state.newlifetimedata[2].plan == 'premium' ?
                                               this.dlink(
                                                 this.state.newlifetimedata[2].plan_code,
                                                 parseInt(this.state.newlifetimedata[2].amount),
                                                 this.state.newlifetimedata[2].plan_name,
                                                 this.state.newlifetimedata[2].package_currency.currency_symbol,
                                                 this.state.newlifetimedata[2].plan_code
                                               )
                                             :
                                              this.dlink(
                                                 this.state.newlifetimedata[2].plan_code,
                                                 parseInt(this.state.newlifetimedata[2].amount),
                                                 this.state.newlifetimedata[2].plan_name,
                                                 this.state.newlifetimedata[2].package_currency.currency_symbol,
                                                 this.state.newlifetimedata[2].plan_code
                                               )
                                         }
                                         onClick={ 
                                            this.state.newlifetimedata[0].plan == 'premium' ?
                                            (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newlifetimedata[0].plan_code,
                                              2)
                                            : this.state.newlifetimedata[1].plan == 'premium' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newlifetimedata[1].plan_code,
                                              2)
                                            : this.state.newlifetimedata[2].plan == 'premium' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newlifetimedata[2].plan_code,
                                              2)
                                            :
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newlifetimedata[2].plan_code,
                                            2)
                                           }
    
                                     >
                                     Select
                                 </Link>
                                )}
                         </>
                        : null
                        }
                            
                            </div> 
                            <div className="pricedowngrade"></div>    
                                
                            <div class="mobi-tab">
                                <input type="checkbox" id="chck24" />
                                <label class="mobi-tab-label" for="chck24">View Features</label>
                            <div class="mobi-tab-content">
                            <div class="price-second-half">
                            
                            <div className="featurebox woocommerce-featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WooCommerce to app</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>WordPress to app</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Merge apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Language selection</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>App analytics</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Easy publish</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                                
                            </div>    
                            
                            <a class="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                            
                            <a class="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="blue-arrow"/></a>
                                
                            </div>
                                </div>
                            </div>
                            
                        </div>
                     : null
                    }
                   
              </div> 
                
          </div> 
             
                   
            </div>
            : this.state.selectiontype !== '' && this.state.selectiontype === 2 ?
            <div className="tabbed">
              {this.state.monthlydata !== '' && this.state.monthlydata !== undefined ?
                        <input type="radio" id="tab1" name="css-tabs"  onChange={() => {this.handlechecktab('monthly')}}  defaultChecked={this.state.checkedtab == 'monthly' ? true : '' } />
                    : null
                    }
                    {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                        <input type="radio" id="tab2" name="css-tabs" onChange={() => {this.handlechecktab('yearly')}}  defaultChecked={this.state.checkedtab == 'yearly' ? true : '' } />
                    : null
                    }
                    {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                        <input type="radio" id="tab3" name="css-tabs" onChange={() => {this.handlechecktab('lifetime')}}  defaultChecked={this.state.checkedtab == 'lifetime' ? true : '' } />
                    : null
                    }
                
                <ul className="tabs">
                {this.state.monthlydata !== '' && this.state.monthlydata !== undefined ?
                    <li className="tab"><label for="tab1">Monthly</label></li>
                : null
                }
                {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                    <li className="tab"><label for="tab2">Yearly</label></li>
                : null
                }
                {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                    <li className="tab"><label for="tab3">Lifetime</label></li>
                : null
                }
                </ul>
                 
                 
            <div className={"tab-content " + (this.state.checkedtab === 'monthly' ? '' : 'd-none')}>
                    
                    <div className="pricingplans allflex">
                    
                    <div className="pricebox">
                        
                        <div className="price-first-half">
                            
                            <div className="borderblue"></div>
                            
                        <div className="allflex">
                            
                            <h2>Free</h2>
                            
                            <div className="platforms">
                            <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>

                            <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            </div>
                            
                        </div>  
                        
                        <h4>{this.state.customer_billing_type == 3 
                                ? this.state.paddlecurreny + '0'
                                : '$0'}<span className="pricing-decimal">.00</span></h4>
                        <h5></h5>
                        
                        { 
                           this.state.one_time[0] !== undefined && this.state.one_time[0].is_subscribed === 1 ? 
                           (
                              <Link to="#" className="currentplan">
                                Current
                              </Link>
                            ) 
                            : this.state.is_deal_plan == 1 ?
                            (
                                <Link to="#"  onClick={(e) => this.downgradepopupdeal()}>
                                    Select
                                </Link> 
                            )
                            : this.state.currentplanduration === 4 ?
                            (
                                <Link to="#" className="lowerplan">
                                    Unavailable
                                </Link> 
                            )
                            : this.state.downgrade_at_term_end === 1 ?
                            (
                                <Link to="#" className="lowerplan">
                                   Unavailable
                                </Link>
                          )
                            : this.state.one_time[0] !== undefined && this.state.one_time[0].show_plan === 0 ? 
                            (
                               <Link to="#" className="lowerplan">
                                  Unavailable
                               </Link>
                            ) 
                            : parseInt(
                                localStorage.getItem("customer_type")
                              ) === 4 &&
                              this.state.reseller_licence_id === null ? 
                            (
                              <a
                                target="_blank"
                                href={
                                  process.env.REACT_APP_WOOCOMMERCE_URL +
                                  "my-account/my-subscriptions/"
                                }
                              >
                                Select{" "}
                                <img
                                  src={
                                    require("../../assets/images/dashboard/Goto.png")
                                      .default
                                  }
                                  alt="Goto"
                                />
                              </a>
                            ) : 
                            (
                              <Link
                                to="#"
                                onClick={(e) => this.removepopup()}
                              >
                                Select
                              </Link>
                            )
                        }
                        </div>
                        <div className="pricedowngrade">
                        {this.state.downgrade_at_term_end === 1 && this.state.endcycledata !== null? 
                            
                            'Downgrades on '  + moment(this.state.endcycledata).format("Do MMM, YYYY")
                         :  this.state.downgrade_at_term_end === 1 && this.state.endcycledata === null ?
                           'Downgrade scheduled'
                         : ''}
                         {this.state.downgrade_at_term_end === 1 ? 
                        <img onClick={(e) =>
                                      this.state.customer_type === 4 ? 
                                      this.handleWooCustomer(e,this.state.customer_type,this.state.one_time[0].plan_code)
                                    //  : this.previewdowngrade(e)
                                    : this.handleReactivePopUp(e,"cancel")
                                    } 
                        src={require("../../assets/images/pricing/red-cross.png").default} alt="red-cross"/>
                        : ''}
                                  
                        </div>
                        
                        <div className="mobi-tab">
                                <input type="checkbox" id="chck5" />
                                <label className="mobi-tab-label" for="chck5">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox">
                                    
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Unlimited Android apps</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Convert any website</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>In-app browser</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Push notifications</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Monetization features</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Team collaboration</p>
                                    </div>    
                                </div>    
                                    
                                </div>
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            </div>
                                </div>
                            </div>
                    </div>
                    {
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'starter' ) ||
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'starter' ) ||
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'starter' ) 
                        ?
                            <div className="pricebox">
                                
                                <div className="price-first-half">
                                
                                <div className="borderyellow"></div>
                                <div className="allflex">
                                    
                                    <h2>Starter</h2>
                                    
                                    <div className="platforms">
                                    <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                    
                                    <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                </div>
                                    
                                </div>  
                                
                                {this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0] !== null ?
                            <>
                                {this.state.customer_billing_type !== 3 ?
                                <>
                                    <h4>
                                    {
                                    this.state.newmonthlydata[0].plan == 'starter' ?
                                    this.state.newmonthlydata[0].package_currency.currency_symbol +
                                    this.state.newmonthlydata[0].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[1].plan == 'starter' ?
                                    this.state.newmonthlydata[1].package_currency.currency_symbol +
                                    this.state.newmonthlydata[1].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[2].plan == 'starter' ?
                                    this.state.newmonthlydata[2].package_currency.currency_symbol +
                                    this.state.newmonthlydata[2].amount.replace(".00", " ")
                                    :
                                    '$ 29'
                                    }
                                    </h4>
                                    </>
                                :
                                <>
                                {
                                  this.state.newmonthlydata[0].plan == 'starter' ?
                                  ReactHtmlParser(this.state.newmonthlydata[0].paddleprice)
                                 :
                                  this.state.newmonthlydata[1].plan == 'starter' ?
                                  ReactHtmlParser(this.state.newmonthlydata[1].paddleprice)
                                 :
                                  this.state.newmonthlydata[2].plan == 'starter' ?
                                  ReactHtmlParser(this.state.newmonthlydata[2].paddleprice)
                                 :
                                  '$ 29'
                                }
                                </>
                                } 
                                <h5>per month</h5>
                            
                            {
                             (this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'starter' && this.state.newmonthlydata[0].is_subscribed === 1) ||
                             (this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'starter' && this.state.newmonthlydata[1].is_subscribed === 1) ||
                             (this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'starter' && this.state.newmonthlydata[2].is_subscribed === 1) 
                             ? 
                                 (
                                     <Link to="#" className="currentplan">
                                         Current
                                     </Link>
                                 ) 
                             : 
                                 (this.state.newmonthlydata[0].plan == 'starter' && this.state.newmonthlydata[0].show_plan === 0) ||
                                 (this.state.newmonthlydata[1].plan == 'starter' && this.state.newmonthlydata[1].show_plan === 0) ||
                                 (this.state.newmonthlydata[2].plan == 'starter' && this.state.newmonthlydata[2].show_plan === 0) 
                             ?   
                             (
                                 <Link to="#"  className="lowerplan">
                                    Unavailable
                                 </Link>
                             ) 
                            :   (
                                    <Link to=
                                            {
                                                this.state.customer_type === 4
                                                ? "#"
                                                : this.state.newmonthlydata[0].plan == 'starter' ?
                                                this.dlink(
                                                    this.state.newmonthlydata[0].plan_code,
                                                    parseInt(this.state.newmonthlydata[0].amount),
                                                    this.state.newmonthlydata[0].plan_name,
                                                    this.state.newmonthlydata[0].package_currency.currency_symbol,
                                                    this.state.newmonthlydata[0].plan_code
                                                )
                                                : this.state.newmonthlydata[1].plan == 'starter' ?
                                                this.dlink(
                                                    this.state.newmonthlydata[1].plan_code,
                                                    parseInt(this.state.newmonthlydata[1].amount),
                                                    this.state.newmonthlydata[1].plan_name,
                                                    this.state.newmonthlydata[1].package_currency.currency_symbol,
                                                    this.state.newmonthlydata[1].plan_code
                                                )
                                                : this.state.newmonthlydata[2].plan == 'starter' ?
                                                this.dlink(
                                                    this.state.newmonthlydata[2].plan_code,
                                                    parseInt(this.state.newmonthlydata[1].amount),
                                                    this.state.newmonthlydata[2].plan_name,
                                                    this.state.newmonthlydata[2].package_currency.currency_symbol,
                                                    this.state.newmonthlydata[2].plan_code
                                                )
                                                :
                                                this.dlink(
                                                    this.state.newmonthlydata[0].plan_code,
                                                    parseInt(this.state.newmonthlydata[0].amount),
                                                    this.state.newmonthlydata[0].plan_name,
                                                    this.state.newmonthlydata[0].package_currency.currency_symbol,
                                                    this.state.newmonthlydata[0].plan_code
                                                )


                                            }

                                            onClick={ 
                                                this.state.newmonthlydata[0].plan == 'starter' ?
                                                (e) => this.handleWooCustomer(e,
                                                this.state.customer_type,
                                                this.state.newmonthlydata[0].plan_code,
                                                0)
                                                : this.state.newmonthlydata[1].plan == 'starter' ?
                                                (e) => this.handleWooCustomer(e,
                                                this.state.customer_type,
                                                this.state.newmonthlydata[1].plan_code,
                                                0)
                                                : this.state.newmonthlydata[2].plan == 'starter' ?
                                                (e) => this.handleWooCustomer(e,
                                                this.state.customer_type,
                                                this.state.newmonthlydata[2].plan_code,
                                                0)
                                                :
                                                (e) => this.handleWooCustomer(e,
                                                this.state.customer_type,
                                                this.state.newmonthlydata[0].plan_code,
                                                0)
                                            }

                                        >
                                        Select
                                    </Link>
                                )}
                            </>
                            : null
                            }
                                
                                </div>
                                <div className="pricedowngrade"></div>
                                    
                                <div className="mobi-tab">
                                    <input type="checkbox" id="chck6" />
                                    <label className="mobi-tab-label" for="chck6">View Features</label>
                                <div className="mobi-tab-content">
                                <div className="price-second-half">
                                
                                <div className="featurebox">
                                    
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Unlimited Android apps</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Convert any website</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>In-app browser</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Push notifications</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Monetization features</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Team collaboration</p>
                                    </div>    
                                </div> 

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>  
                                    
                                </div>    
                                
                                <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                                
                                <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                                
                                </div>
                                    </div>
                                </div>
                                    
                            </div>
                        : null
                        } 
                        {
                           (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'pro' ) ||
                           (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'pro' ) ||
                           (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'pro' ) 
                        ?
                        
                        <div className="pricebox">
                            
                            <div className="price-first-half">
                            
                            <div className="borderorange"></div>
                            
                            <div className="allflex">
                                
                                <h2>Pro</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                
                                <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                            </div>
                                
                            </div>  
                            
                            {this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0] !== null ?
                         <>
                            {this.state.customer_billing_type !== 3 ?
                                <>
                                    <h4>
                                    {
                                    this.state.newmonthlydata[0].plan == 'pro' ?
                                    this.state.newmonthlydata[0].package_currency.currency_symbol +
                                    this.state.newmonthlydata[0].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[1].plan == 'pro' ?
                                    this.state.newmonthlydata[1].package_currency.currency_symbol +
                                    this.state.newmonthlydata[1].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[2].plan == 'pro' ?
                                    this.state.newmonthlydata[2].package_currency.currency_symbol +
                                    this.state.newmonthlydata[2].amount.replace(".00", " ")
                                    :
                                    '$ 29'
                                    }
                                    </h4>
                                    </>
                                :
                                <>
                                {
                                  this.state.newmonthlydata[0].plan == 'pro' ?
                                  ReactHtmlParser(this.state.newmonthlydata[0].paddleprice)
                                 :
                                  this.state.newmonthlydata[1].plan == 'pro' ?
                                  ReactHtmlParser(this.state.newmonthlydata[1].paddleprice)
                                 :
                                  this.state.newmonthlydata[2].plan == 'pro' ?
                                  ReactHtmlParser(this.state.newmonthlydata[2].paddleprice)
                                 :
                                  '$ 29'
                                }
                                </>
                                } 
                            <h5>per month</h5>
                            {
                         (this.state.newmonthlydata[0].plan == 'pro' && this.state.newmonthlydata[0].is_subscribed === 1) ||
                         (this.state.newmonthlydata[1].plan == 'pro' && this.state.newmonthlydata[1].is_subscribed === 1) ||
                         (this.state.newmonthlydata[2].plan == 'pro' && this.state.newmonthlydata[2].is_subscribed === 1) 
                         ? 
                             (
                                 <Link to="#" className="currentplan">
                                     Current
                                 </Link>
                             ) 
                         : 
                             (this.state.newmonthlydata[0].plan == 'pro' && this.state.newmonthlydata[0].show_plan === 0) ||
                             (this.state.newmonthlydata[1].plan == 'pro' && this.state.newmonthlydata[1].show_plan === 0) ||
                             (this.state.newmonthlydata[2].plan == 'pro' && this.state.newmonthlydata[2].show_plan === 0) 
                         ?   
                         (
                             <Link to="#"  className="lowerplan">
                                 Unavailable
                             </Link>
                         )  
                        :   (
                                   <Link to=
                                        {
                                            this.state.customer_type === 4
                                            ? "#"
                                            : this.state.newmonthlydata[0].plan == 'pro' ?
                                              this.dlink(
                                                this.state.newmonthlydata[0].plan_code,
                                                parseInt(this.state.newmonthlydata[0].amount),
                                                this.state.newmonthlydata[0].plan_name,
                                                this.state.newmonthlydata[0].package_currency.currency_symbol,
                                                this.state.newmonthlydata[0].plan_code
                                              )
                                            : this.state.newmonthlydata[1].plan == 'pro' ?
                                              this.dlink(
                                                this.state.newmonthlydata[1].plan_code,
                                                parseInt(this.state.newmonthlydata[1].amount),
                                                this.state.newmonthlydata[1].plan_name,
                                                this.state.newmonthlydata[1].package_currency.currency_symbol,
                                                this.state.newmonthlydata[1].plan_code
                                              )
                                            : this.state.newmonthlydata[2].plan == 'pro' ?
                                              this.dlink(
                                                this.state.newmonthlydata[2].plan_code,
                                                parseInt(this.state.newmonthlydata[1].amount),
                                                this.state.newmonthlydata[2].plan_name,
                                                this.state.newmonthlydata[2].package_currency.currency_symbol,
                                                this.state.newmonthlydata[2].plan_code
                                              )
                                            :
                                             this.dlink(
                                                this.state.newmonthlydata[1].plan_code,
                                                parseInt(this.state.newmonthlydata[1].amount),
                                                this.state.newmonthlydata[1].plan_name,
                                                this.state.newmonthlydata[1].package_currency.currency_symbol,
                                                this.state.newmonthlydata[1].plan_code
                                              )


                                        }
                                        onClick={ 
                                            this.state.newmonthlydata[0].plan == 'pro' ?
                                            (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newmonthlydata[0].plan_code,
                                              1)
                                            : this.state.newmonthlydata[1].plan == 'pro' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newmonthlydata[1].plan_code,
                                              1)
                                            : this.state.newmonthlydata[2].plan == 'pro' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newmonthlydata[2].plan_code,
                                              1)
                                            :
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newmonthlydata[1].plan_code,
                                            1)
                                           }

                                    >
                                    Select
                                </Link>
                            )}
                         </>
                        : null
                        }
                  
                            </div>  
                            <div className="pricedowngrade"></div>    
                                
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck7" />
                                <label className="mobi-tab-label" for="chck7">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox">
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Convert any website</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                            </div>    
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            </div> 
                                </div>
                            </div>
                                
                        </div>
                        : null
                        }
                        {
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'premium' ) ||
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'premium' ) ||
                            (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'premium' ) 
                        ?
                        
                            <div className="pricebox">
                                
                                <div className="price-first-half">
                                
                                <div className="bordergreen"></div>
                                
                                <div className="allflex">
                                    
                                    <h2>Premium</h2>
                                    
                                    <div className="platforms">
                                    <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                    
                                    <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                    
                                    <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                                </div>
                                    
                                </div> 
                                
                                {this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0] !== null ?
                            <>
                                {this.state.customer_billing_type !== 3 ?
                                <>
                                    <h4>
                                    {
                                    this.state.newmonthlydata[0].plan == 'premium' ?
                                    this.state.newmonthlydata[0].package_currency.currency_symbol +
                                    this.state.newmonthlydata[0].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[1].plan == 'premium' ?
                                    this.state.newmonthlydata[1].package_currency.currency_symbol +
                                    this.state.newmonthlydata[1].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[2].plan == 'premium' ?
                                    this.state.newmonthlydata[2].package_currency.currency_symbol +
                                    this.state.newmonthlydata[2].amount.replace(".00", " ")
                                    :
                                    '$ 29'
                                    }
                                    </h4>
                                    </>
                                :
                                <>
                                {
                                  this.state.newmonthlydata[0].plan == 'premium' ?
                                  ReactHtmlParser(this.state.newmonthlydata[0].paddleprice)
                                 :
                                  this.state.newmonthlydata[1].plan == 'premium' ?
                                  ReactHtmlParser(this.state.newmonthlydata[1].paddleprice)
                                 :
                                  this.state.newmonthlydata[2].plan == 'premium' ?
                                  ReactHtmlParser(this.state.newmonthlydata[2].paddleprice)
                                 :
                                  '$ 29'
                                }
                                </>
                                } 
                                <h5>per month</h5>
                            {
                            (this.state.newmonthlydata[0].plan == 'premium' && this.state.newmonthlydata[0].is_subscribed === 1) ||
                            (this.state.newmonthlydata[1].plan == 'premium' && this.state.newmonthlydata[1].is_subscribed === 1) ||
                            (this.state.newmonthlydata[2].plan == 'premium' && this.state.newmonthlydata[2].is_subscribed === 1) 
                            ? 
                                (
                                    <Link to="#" className="currentplan">
                                        Current
                                    </Link>
                                ) 
                            : 
                                (this.state.newmonthlydata[0].plan == 'premium' && this.state.newmonthlydata[0].show_plan === 0) ||
                                (this.state.newmonthlydata[1].plan == 'premium' && this.state.newmonthlydata[1].show_plan === 0) ||
                                (this.state.newmonthlydata[2].plan == 'premium' && this.state.newmonthlydata[2].show_plan === 0) 
                            ?   
                            (
                                <Link to="#"  className="lowerplan">
                                    Unavailable
                                </Link>
                            )  
                                :   (
                                    <Link to=
                                        {
                                            this.state.customer_type === 4
                                            ? "#"
                                            : this.state.newmonthlydata[0].plan == 'premium' ?
                                            this.dlink(
                                                this.state.newmonthlydata[0].plan_code,
                                                parseInt(this.state.newmonthlydata[0].amount),
                                                this.state.newmonthlydata[0].plan_name,
                                                this.state.newmonthlydata[0].package_currency.currency_symbol,
                                                this.state.newmonthlydata[0].plan_code
                                            )
                                            : this.state.newmonthlydata[1].plan == 'premium' ?
                                            this.dlink(
                                                this.state.newmonthlydata[1].plan_code,
                                                parseInt(this.state.newmonthlydata[1].amount),
                                                this.state.newmonthlydata[1].plan_name,
                                                this.state.newmonthlydata[1].package_currency.currency_symbol,
                                                this.state.newmonthlydata[1].plan_code
                                            )
                                            : this.state.newmonthlydata[2].plan == 'premium' ?
                                            this.dlink(
                                                this.state.newmonthlydata[2].plan_code,
                                                parseInt(this.state.newmonthlydata[2].amount),
                                                this.state.newmonthlydata[2].plan_name,
                                                this.state.newmonthlydata[2].package_currency.currency_symbol,
                                                this.state.newmonthlydata[2].plan_code
                                            )
                                            :
                                            this.dlink(
                                                this.state.newmonthlydata[2].plan_code,
                                                parseInt(this.state.newmonthlydata[2].amount),
                                                this.state.newmonthlydata[2].plan_name,
                                                this.state.newmonthlydata[2].package_currency.currency_symbol,
                                                this.state.newmonthlydata[2].plan_code
                                            )


                                        }
                                        onClick={ 
                                            this.state.newmonthlydata[0].plan == 'premium' ?
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newmonthlydata[0].plan_code,
                                            2)
                                            : this.state.newmonthlydata[1].plan == 'premium' ?
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newmonthlydata[1].plan_code,
                                            2)
                                            : this.state.newmonthlydata[2].plan == 'premium' ?
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newmonthlydata[2].plan_code,
                                            2)
                                            :
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newmonthlydata[2].plan_code,
                                            2)
                                        }

                                    >
                                    Select
                                </Link>
                            )}
                            </>
                            : null
                            }
                                
                                </div> 
                                <div className="pricedowngrade"></div>      
                                    
                                <div className="mobi-tab">
                                    <input type="checkbox" id="chck8" />
                                    <label className="mobi-tab-label" for="chck8">View Features</label>
                                <div className="mobi-tab-content">
                                <div className="price-second-half">
                                
                                <div className="featurebox">
                                    
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Unlimited Android & iOS apps</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Convert any website</p>
                                </div>    
                            </div>
                            
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Merge apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>App analytics</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Easy publish</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                    
                                    
                                </div>    
                                
                                <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                                
                                <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                                    
                                </div>
                                    </div>
                                </div>
                                
                            </div>
                        : null
                        }
                    
                </div>    

            </div>    
                 
            <div className={"tab-content " + (this.state.checkedtab === 'yearly' ? '' : 'd-none')}>

                    <div className="pricingplans allflex">
                    
                    <div className="pricebox">
                        
                        <div className="price-first-half">
                            
                            <div className="borderblue"></div>
                            
                        <div className="allflex">
                            
                            <h2>Free</h2>
                            
                            <div className="platforms">
                            <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>

                            <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            </div>
                            
                        </div>  
                        
                        <h4>{this.state.customer_billing_type == 3 
                                ? this.state.paddlecurreny + '0'
                                : '$0'}<span className="pricing-decimal">.00</span></h4>
                        <h5></h5>
                        
                        { 
                           this.state.one_time[0] !== undefined && this.state.one_time[0].is_subscribed === 1 ? 
                           (
                              <Link to="#" className="currentplan">
                                Current
                              </Link>
                            )
                            : this.state.is_deal_plan == 1 ?
                            (
                                <Link to="#"  onClick={(e) => this.downgradepopupdeal()}>
                                    Select
                                </Link> 
                            )
                            : this.state.currentplanduration === 4 ?
                            (
                                <Link to="#" className="lowerplan">
                                    Unavailable
                                </Link> 
                            ) 
                            : this.state.downgrade_at_term_end === 1 ?
                            (
                                <Link to="#" className="lowerplan">
                                   Unavailable
                                </Link>
                          )
                            : this.state.one_time[0] !== undefined && this.state.one_time[0].show_plan === 0 ? 
                            (
                               <Link to="#" className="lowerplan">
                                  Unavailable
                               </Link>
                            )  
                            : this.state.customer_type === 4 &&
                              this.state.reseller_licence_id === null ? 
                            (
                              <a
                                target="_blank"
                                href={
                                  process.env.REACT_APP_WOOCOMMERCE_URL +
                                  "my-account/my-subscriptions/"
                                }
                              >
                                Select{" "}
                                <img
                                  src={
                                    require("../../assets/images/dashboard/Goto.png")
                                      .default
                                  }
                                  alt="Goto"
                                />
                              </a>
                            ) : 
                            (
                              <Link
                                to="#"
                                onClick={(e) => this.removepopup()}
                              >
                                Select
                              </Link>
                            )
                        }
                        </div>
                        <div className="pricedowngrade">
                        {this.state.downgrade_at_term_end === 1 && this.state.endcycledata !== null? 
                            
                            'Downgrades on '  + moment(this.state.endcycledata).format("Do MMM, YYYY")
                         :  this.state.downgrade_at_term_end === 1 && this.state.endcycledata === null ?
                           'Downgrade scheduled'
                         : ''}
                         {this.state.downgrade_at_term_end === 1 ? 
                        <img onClick={(e) =>
                                      this.state.customer_type === 4 ? 
                                      this.handleWooCustomer(e,this.state.customer_type,this.state.one_time[0].plan_code)
                                    //  : this.previewdowngrade(e)
                                    : this.handleReactivePopUp(e,"cancel")
                                    } 
                        src={require("../../assets/images/pricing/red-cross.png").default} alt="red-cross"/>
                        : ''}
                                  
                        </div>
                        
                        <div className="mobi-tab">
                                <input type="checkbox" id="chck13" />
                                <label className="mobi-tab-label" for="chck13">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Convert any website</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>In-app browser</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Push notifications</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Monetization features</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Team collaboration</p>
                                    </div>    
                                </div>    
                                
                            </div>
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            </div>
                                </div>
                            </div>
                    </div>
                    
                    {(this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'starter' ) ||
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'starter' ) ||
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'starter' ) 
                        ?
                            <div className="pricebox">
                            
                            <div className="price-first-half">
                            
                            <div className="borderyellow"></div>
                            <div className="allflex">
                                
                                <h2>Starter</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            </div>
                                
                            </div>  
                            
                            {this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0] !== null ?
                         <>
                            {this.state.customer_billing_type !== 3 ?
                                <>
                             <h4>
                            {
                              this.state.newyearlydata[0].plan == 'starter' ?
                              this.state.newyearlydata[0].package_currency.currency_symbol +
                              this.state.newyearlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[1].plan == 'starter' ?
                              this.state.newyearlydata[1].package_currency.currency_symbol +
                              this.state.newyearlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[2].plan == 'starter' ?
                              this.state.newyearlydata[2].package_currency.currency_symbol +
                              this.state.newyearlydata[2].amount.replace(".00", " ")
                             :
                              '$ 19'
                            }
                            </h4>
                        </>
                        :
                        <>
                        {
                          this.state.newyearlydata[0].plan == 'starter' ?
                          ReactHtmlParser(this.state.newyearlydata[0].paddleprice)
                         :
                          this.state.newyearlydata[1].plan == 'starter' ?
                          ReactHtmlParser(this.state.newyearlydata[1].paddleprice)
                         :
                          this.state.newyearlydata[2].plan == 'starter' ?
                          ReactHtmlParser(this.state.newyearlydata[2].paddleprice)
                         :
                          '$ 19'
                        }
                        </>
                        }
                            <h5>per month paid yearly</h5>
                          {
                            (this.state.newyearlydata[0].plan == 'starter' && this.state.newyearlydata[0].is_subscribed === 1) ||
                            (this.state.newyearlydata[1].plan == 'starter' && this.state.newyearlydata[1].is_subscribed === 1) ||
                            (this.state.newyearlydata[2].plan == 'starter' && this.state.newyearlydata[2].is_subscribed === 1) 
                            ? 
                                (
                                    <Link to="#" className="currentplan">
                                        Current
                                    </Link>
                                ) 
                            : 
                                (this.state.newyearlydata[0].plan == 'starter' && this.state.newyearlydata[0].show_plan === 0) ||
                                (this.state.newyearlydata[1].plan == 'starter' && this.state.newyearlydata[1].show_plan === 0) ||
                                (this.state.newyearlydata[2].plan == 'starter' && this.state.newyearlydata[2].show_plan === 0) 
                            ?   
                            (
                                <Link to="#"  class="lowerplan">
                                    Unavailable
                                </Link>
                            )  
                            :   (
                                <Link to=
                                     {
                                         this.state.customer_type === 4
                                         ? "#"
                                         : this.state.newyearlydata[0].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newyearlydata[0].plan_code,
                                             parseInt(this.state.newyearlydata[0].amount),
                                             this.state.newyearlydata[0].plan_name,
                                             this.state.newyearlydata[0].package_currency.currency_symbol,
                                             this.state.newyearlydata[0].plan_code
                                           )
                                         : this.state.newyearlydata[1].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newyearlydata[1].plan_code,
                                             parseInt(this.state.newyearlydata[1].amount),
                                             this.state.newyearlydata[1].plan_name,
                                             this.state.newyearlydata[1].package_currency.currency_symbol,
                                             this.state.newyearlydata[1].plan_code
                                           )
                                         : this.state.newyearlydata[2].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newyearlydata[2].plan_code,
                                             parseInt(this.state.newyearlydata[2].amount),
                                             this.state.newyearlydata[2].plan_name,
                                             this.state.newyearlydata[2].package_currency.currency_symbol,
                                             this.state.newyearlydata[2].plan_code
                                           )
                                         :
                                          this.dlink(
                                             this.state.newyearlydata[0].plan_code,
                                             parseInt(this.state.newyearlydata[0].amount),
                                             this.state.newyearlydata[0].plan_name,
                                             this.state.newyearlydata[0].package_currency.currency_symbol,
                                             this.state.newyearlydata[0].plan_code
                                           )


                                     }
                                     onClick={ 
                                        this.state.newyearlydata[0].plan == 'starter' ?
                                        (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newyearlydata[0].plan_code,
                                          0)
                                        : this.state.newyearlydata[1].plan == 'starter' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newyearlydata[1].plan_code,
                                          0)
                                        : this.state.newyearlydata[2].plan == 'starter' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newyearlydata[2].plan_code,
                                          0)
                                        :
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newyearlydata[0].plan_code,
                                        0)
                                       }

                                 >
                                 Select
                             </Link>
                         )}
                         </>
                        : null
                        }
                            
                            </div>
                            <div className="pricedowngrade"></div> 
                                
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck14" />
                                <label className="mobi-tab-label" for="chck14">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Convert any website</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>In-app browser</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Push notifications</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Monetization features</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Team collaboration</p>
                                    </div>    
                                </div>  

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>  
                                
                            </div>    
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            </div>
                                </div>
                            </div>
                                
                            </div>
                        : null
                        }

                        {
                          (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'pro' ) ||
                          (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'pro' ) ||
                          (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'pro' ) 
                        ?
                        <div className="pricebox">
                            
                            <div className="price-first-half">
                            
                            <div className="borderorange"></div>
                            
                            <div className="allflex">
                                
                                <h2>Pro</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                
                                <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                            </div>
                                
                            </div>  
                            
                            {this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0] !== null ?
                         <>
                           {this.state.customer_billing_type !== 3 ?
                                <>
                             <h4>
                            {
                              this.state.newyearlydata[0].plan == 'pro' ?
                              this.state.newyearlydata[0].package_currency.currency_symbol +
                              this.state.newyearlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[1].plan == 'pro' ?
                              this.state.newyearlydata[1].package_currency.currency_symbol +
                              this.state.newyearlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[2].plan == 'pro' ?
                              this.state.newyearlydata[2].package_currency.currency_symbol +
                              this.state.newyearlydata[2].amount.replace(".00", " ")
                             :
                              '$ 19'
                            }
                            </h4>
                        </>
                        :
                        <>
                        {
                          this.state.newyearlydata[0].plan == 'pro' ?
                          ReactHtmlParser(this.state.newyearlydata[0].paddleprice)
                         :
                          this.state.newyearlydata[1].plan == 'pro' ?
                          ReactHtmlParser(this.state.newyearlydata[1].paddleprice)
                         :
                          this.state.newyearlydata[2].plan == 'pro' ?
                          ReactHtmlParser(this.state.newyearlydata[2].paddleprice)
                         :
                          '$ 19'
                        }
                        </>
                        }
                            <h5>per month paid yearly</h5>
                            {
                             (this.state.newyearlydata[0].plan == 'pro' && this.state.newyearlydata[0].is_subscribed === 1) ||
                             (this.state.newyearlydata[1].plan == 'pro' && this.state.newyearlydata[1].is_subscribed === 1) ||
                             (this.state.newyearlydata[2].plan == 'pro' && this.state.newyearlydata[2].is_subscribed === 1) 
                             ? 
                                 (
                                     <Link to="#" className="currentplan">
                                         Current
                                     </Link>
                                 )
                                 : 
                                 (this.state.newyearlydata[0].plan == 'pro' && this.state.newyearlydata[0].show_plan === 0) ||
                                 (this.state.newyearlydata[1].plan == 'pro' && this.state.newyearlydata[1].show_plan === 0) ||
                                 (this.state.newyearlydata[2].plan == 'pro' && this.state.newyearlydata[2].show_plan === 0) 
                             ?   
                             (
                                 <Link to="#"  class="lowerplan">
                                 Unavailable
                             </Link>
                             )   
                            :   (
                                    <Link to=
                                         {
                                             this.state.customer_type === 4
                                             ? "#"
                                             : this.state.newyearlydata[0].plan == 'pro' ?
                                               this.dlink(
                                                 this.state.newyearlydata[0].plan_code,
                                                 parseInt(this.state.newyearlydata[0].amount),
                                                 this.state.newyearlydata[0].plan_name,
                                                 this.state.newyearlydata[0].package_currency.currency_symbol,
                                                 this.state.newyearlydata[0].plan_code
                                               )
                                             : this.state.newyearlydata[1].plan == 'pro' ?
                                               this.dlink(
                                                 this.state.newyearlydata[1].plan_code,
                                                 parseInt(this.state.newyearlydata[1].amount),
                                                 this.state.newyearlydata[1].plan_name,
                                                 this.state.newyearlydata[1].package_currency.currency_symbol,
                                                 this.state.newyearlydata[1].plan_code
                                               )
                                             : this.state.newyearlydata[2].plan == 'pro' ?
                                               this.dlink(
                                                 this.state.newyearlydata[2].plan_code,
                                                 parseInt(this.state.newyearlydata[2].amount),
                                                 this.state.newyearlydata[2].plan_name,
                                                 this.state.newyearlydata[2].package_currency.currency_symbol,
                                                 this.state.newyearlydata[2].plan_code
                                               )
                                             :
                                              this.dlink(
                                                 this.state.newyearlydata[1].plan_code,
                                                 parseInt(this.state.newyearlydata[1].amount),
                                                 this.state.newyearlydata[1].plan_name,
                                                 this.state.newyearlydata[1].package_currency.currency_symbol,
                                                 this.state.newyearlydata[1].plan_code
                                               )
    
    
                                         }
                                         onClick={ 
                                            this.state.newyearlydata[0].plan == 'pro' ?
                                            (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[0].plan_code,
                                              1)
                                            : this.state.newyearlydata[1].plan == 'pro' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[1].plan_code,
                                              1)
                                            : this.state.newyearlydata[2].plan == 'pro' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[2].plan_code,
                                              1)
                                            :
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newyearlydata[1].plan_code,
                                            1)
                                           }
    
                                     >
                                     Select
                                 </Link>
                             )}
                         </>
                        : null
                        }
                  
                            </div> 
                            <div className="pricedowngrade"></div>    
                                
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck15" />
                                <label className="mobi-tab-label" for="chck15">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox">
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Convert any website</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                            </div>    
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            </div>
                                </div>
                            </div>
                                
                        </div>
                        : null
                        }

                        {
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0].plan == 'premium' ) ||
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[1] !== undefined && this.state.newyearlydata[1].plan == 'premium' ) ||
                            (this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[2] !== undefined && this.state.newyearlydata[2].plan == 'premium' ) 
                        ?
                        <div className="pricebox">
                            
                            <div className="price-first-half">
                            
                            <div className="bordergreen"></div>
                            
                            <div className="allflex">
                                
                                <h2>Premium</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                
                                <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                            </div>
                                
                            </div> 
                            
                            {this.state.newyearlydata !== '' && this.state.newyearlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0] !== null ?
                         <>
                            {this.state.customer_billing_type !== 3 ?
                                <>
                             <h4>
                            {
                              this.state.newyearlydata[0].plan == 'premium' ?
                              this.state.newyearlydata[0].package_currency.currency_symbol +
                              this.state.newyearlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[1].plan == 'premium' ?
                              this.state.newyearlydata[1].package_currency.currency_symbol +
                              this.state.newyearlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[2].plan == 'premium' ?
                              this.state.newyearlydata[2].package_currency.currency_symbol +
                              this.state.newyearlydata[2].amount.replace(".00", " ")
                             :
                              '$ 19'
                            }
                            </h4>
                        </>
                        :
                        <>
                        {
                          this.state.newyearlydata[0].plan == 'premium' ?
                          ReactHtmlParser(this.state.newyearlydata[0].paddleprice)
                         :
                          this.state.newyearlydata[1].plan == 'premium' ?
                          ReactHtmlParser(this.state.newyearlydata[1].paddleprice)
                         :
                          this.state.newyearlydata[2].plan == 'premium' ?
                          ReactHtmlParser(this.state.newyearlydata[2].paddleprice)
                         :
                          '$ 19'
                        }
                        </>
                        }
                            <h5>per month paid yearly</h5>
                            {
                                (this.state.newyearlydata[0].plan == 'premium' && this.state.newyearlydata[0].is_subscribed === 1) ||
                                (this.state.newyearlydata[1].plan == 'premium' && this.state.newyearlydata[1].is_subscribed === 1) ||
                                (this.state.newyearlydata[2].plan == 'premium' && this.state.newyearlydata[2].is_subscribed === 1) 
                                ? 
                                    (
                                        <Link to="#" className="currentplan">
                                            Current
                                        </Link>
                                    )
                                : 
                                (this.state.newyearlydata[0].plan == 'premium' && this.state.newyearlydata[0].show_plan === 0) ||
                                (this.state.newyearlydata[1].plan == 'premium' && this.state.newyearlydata[1].show_plan === 0) ||
                                (this.state.newyearlydata[2].plan == 'premium' && this.state.newyearlydata[2].show_plan === 0) 
                                ? 
                                (
                                    <Link to="#"  class="lowerplan">
                                    Unavailable
                                </Link>
                                )
                                :   (
                                    <Link to=
                                         {
                                             this.state.customer_type === 4
                                             ? "#"
                                             : this.state.newyearlydata[0].plan == 'premium' ?
                                               this.dlink(
                                                 this.state.newyearlydata[0].plan_code,
                                                 parseInt(this.state.newyearlydata[0].amount),
                                                 this.state.newyearlydata[0].plan_name,
                                                 this.state.newyearlydata[0].package_currency.currency_symbol,
                                                 this.state.newyearlydata[0].plan_code
                                               )
                                             : this.state.newyearlydata[1].plan == 'premium' ?
                                               this.dlink(
                                                 this.state.newyearlydata[1].plan_code,
                                                 parseInt(this.state.newyearlydata[1].amount),
                                                 this.state.newyearlydata[1].plan_name,
                                                 this.state.newyearlydata[1].package_currency.currency_symbol,
                                                 this.state.newyearlydata[1].plan_code
                                               )
                                             : this.state.newyearlydata[2].plan == 'premium' ?
                                               this.dlink(
                                                 this.state.newyearlydata[2].plan_code,
                                                 parseInt(this.state.newyearlydata[2].amount),
                                                 this.state.newyearlydata[2].plan_name,
                                                 this.state.newyearlydata[2].package_currency.currency_symbol,
                                                 this.state.newyearlydata[2].plan_code
                                               )
                                             :
                                              this.dlink(
                                                 this.state.newyearlydata[2].plan_code,
                                                 parseInt(this.state.newyearlydata[2].amount),
                                                 this.state.newyearlydata[2].plan_name,
                                                 this.state.newyearlydata[2].package_currency.currency_symbol,
                                                 this.state.newyearlydata[2].plan_code
                                               )
    
    
                                         }
                                         onClick={ 
                                            this.state.newyearlydata[0].plan == 'premium' ?
                                            (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[0].plan_code,
                                              2)
                                            : this.state.newyearlydata[1].plan == 'premium' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[1].plan_code,
                                              2)
                                            : this.state.newyearlydata[2].plan == 'premium' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newyearlydata[2].plan_code,
                                              2)
                                            :
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newyearlydata[2].plan_code,
                                            2)
                                           }
    
                                     >
                                     Select
                                 </Link>
                             )}
                         </>
                        : null
                        }
                            
                            </div> 
                            <div className="pricedowngrade"></div>    
                                
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck16" />
                                <label className="mobi-tab-label" for="chck16">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Convert any website</p>
                                </div>    
                            </div>
                            
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Merge apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>App analytics</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Easy publish</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                                
                            </div>    
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                                
                            </div>
                                </div>
                            </div>
                            
                        </div>
                        : null
                        }
                    
                </div>  

            </div>
                
            <div className={"tab-content " + (this.state.checkedtab === 'lifetime' ? '' : 'd-none')}>

                    <div className="pricingplans allflex">
                    
                    <div className="pricebox">
                        
                        <div className="price-first-half">
                            
                            <div className="borderblue"></div>
                            
                        <div className="allflex">
                            
                            <h2>Free</h2>
                            
                            <div className="platforms">
                            <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>

                            <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            </div>
                            
                        </div>  
                        
                        <h4>{this.state.customer_billing_type == 3 
                                ? this.state.paddlecurreny + '0'
                                : '$0'}<span className="pricing-decimal">.00</span></h4>
                        <h5></h5>
                        
                        { 
                           this.state.one_time[0] !== undefined && this.state.one_time[0].is_subscribed === 1 ? 
                           (
                              <Link to="#" className="currentplan">
                                Current
                              </Link>
                            )
                            : this.state.is_deal_plan == 1 ?
                            (
                                <Link to="#"  onClick={(e) => this.downgradepopupdeal()}>
                                    Select
                                </Link> 
                            )
                            : this.state.currentplanduration === 4 ?
                            (
                                <Link to="#" className="lowerplan">
                                    Unavailable
                                </Link> 
                            ) 
                            : this.state.downgrade_at_term_end === 1 ?
                            (
                                <Link to="#" className="lowerplan">
                                   Unavailable
                                </Link>
                          )
                          : this.state.one_time[0] !== undefined && this.state.one_time[0].show_plan === 0 ? 
                            (
                               <Link to="#" className="lowerplan">
                                  Unavailable
                               </Link>
                             ) 
                            : this.state.customer_type === 4 &&
                              this.state.reseller_licence_id === null ? 
                            (
                              <a
                                target="_blank"
                                href={
                                  process.env.REACT_APP_WOOCOMMERCE_URL +
                                  "my-account/my-subscriptions/"
                                }
                              >
                                Select{" "}
                                <img
                                  src={
                                    require("../../assets/images/dashboard/Goto.png")
                                      .default
                                  }
                                  alt="Goto"
                                />
                              </a>
                            ) : 
                            (
                              <Link
                                to="#"
                                onClick={(e) => this.removepopup()}
                              >
                                Select
                              </Link>
                            )
                        }
                        
                        </div>
                        <div className="pricedowngrade"></div>
                        
                        <div className="mobi-tab">
                                <input type="checkbox" id="chck21" />
                                <label className="mobi-tab-label" for="chck21">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Convert any website</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>In-app browser</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Push notifications</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Monetization features</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Team collaboration</p>
                                    </div>    
                                </div>    
                                
                            </div>
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            </div>
                                </div>
                            </div>
                    </div>
                    
                    <div className="pricebox">
                            
                            <div className="price-first-half">
                            
                            <div className="borderyellow"></div>
                            <div className="allflex">
                                
                                <h2>Starter</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            </div>
                                
                            </div>  
                            
                            {this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0] !== null ?
                         <>
                            {this.state.customer_billing_type !== 3 ?
                           <h4>
                           {
                             this.state.newlifetimedata[0].plan == 'starter' ?
                             this.state.newlifetimedata[0].package_currency.currency_symbol +
                             this.state.newlifetimedata[0].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[1].plan == 'starter' ?
                             this.state.newlifetimedata[1].package_currency.currency_symbol +
                             this.state.newlifetimedata[1].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[2].plan == 'starter' ?
                             this.state.newlifetimedata[2].package_currency.currency_symbol +
                             this.state.newlifetimedata[2].amount.replace(".00", " ")
                            :
                             '$ 79'
                           }
                           </h4>
                         :
                         <>
                         {
                           this.state.newlifetimedata[0].plan == 'starter' ?
                           ReactHtmlParser(this.state.newlifetimedata[0].paddleprice)
                          :
                           this.state.newlifetimedata[1].plan == 'starter' ?
                           ReactHtmlParser(this.state.newlifetimedata[1].paddleprice)
                          :
                           this.state.newlifetimedata[2].plan == 'starter' ?
                           ReactHtmlParser(this.state.newlifetimedata[2].paddleprice)
                          :
                           '$ 499'
                         }
                         </>
                        }
                            <h5>one time payment</h5>
                            {
                            (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'starter' && this.state.newlifetimedata[0].is_subscribed === 1) ||
                            (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'starter' && this.state.newlifetimedata[1].is_subscribed === 1) ||
                            (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'starter' && this.state.newlifetimedata[2].is_subscribed === 1) 
                            ? 
                                (
                                    <Link to="#" className="currentplan">
                                        Current
                                    </Link>
                                )
                            :   (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'starter' && this.state.newlifetimedata[0].show_plan === 0) ||
                                (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'starter' && this.state.newlifetimedata[1].show_plan === 0) ||
                                (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'starter' && this.state.newlifetimedata[2].show_plan === 0) 
                            ? 
                                (
                                    <Link to="#" className="lowerplan">
                                            Unavailable
                                    </Link>
                                )
                            :   (
                                <Link to=
                                     {
                                         this.state.customer_type === 4
                                         ? "#"
                                         : this.state.newlifetimedata[0].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newlifetimedata[0].plan_code,
                                             parseInt(this.state.newlifetimedata[0].amount),
                                             this.state.newlifetimedata[0].plan_name,
                                             this.state.newlifetimedata[0].package_currency.currency_symbol,
                                             this.state.newlifetimedata[0].plan_code
                                           )
                                         : this.state.newlifetimedata[1].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newlifetimedata[1].plan_code,
                                             parseInt(this.state.newlifetimedata[1].amount),
                                             this.state.newlifetimedata[1].plan_name,
                                             this.state.newlifetimedata[1].package_currency.currency_symbol,
                                             this.state.newlifetimedata[1].plan_code
                                           )
                                         : this.state.newlifetimedata[2].plan == 'starter' ?
                                           this.dlink(
                                             this.state.newlifetimedata[2].plan_code,
                                             parseInt(this.state.newlifetimedata[2].amount),
                                             this.state.newlifetimedata[2].plan_name,
                                             this.state.newlifetimedata[2].package_currency.currency_symbol,
                                             this.state.newlifetimedata[2].plan_code
                                           )
                                         :
                                          this.dlink(
                                             this.state.newlifetimedata[0].plan_code,
                                             parseInt(this.state.newlifetimedata[0].amount),
                                             this.state.newlifetimedata[0].plan_name,
                                             this.state.newlifetimedata[0].package_currency.currency_symbol,
                                             this.state.newlifetimedata[0].plan_code
                                           )
                                     }
                                     onClick={ 
                                        this.state.newlifetimedata[0].plan == 'starter' ?
                                        (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newlifetimedata[0].plan_code,
                                          0)
                                        : this.state.newlifetimedata[1].plan == 'starter' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newlifetimedata[1].plan_code,
                                          0)
                                        : this.state.newlifetimedata[2].plan == 'starter' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newlifetimedata[2].plan_code,
                                          0)
                                        :
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newlifetimedata[0].plan_code,
                                        0)
                                       }

                                 >
                                 Select
                             </Link>
                            )}
                         </>
                        : null
                        }
                            
                            </div>
                            <div className="pricedowngrade"></div>
                                
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck22" />
                                <label className="mobi-tab-label" for="chck22">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Convert any website</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>In-app browser</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Push notifications</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Monetization features</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Team collaboration</p>
                                    </div>    
                                </div>   

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div> 
                                
                            </div>    
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            </div> 
                                </div>
                            </div>
                                
                        </div>
                        
                        <div className="pricebox">
                            
                            <div className="price-first-half">
                            
                            <div className="borderorange"></div>
                            
                            <div className="allflex">
                                
                                <h2>Pro</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                
                                <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                            </div>
                                
                            </div>  
                            
                            {this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0] !== null ?
                         <>
                            {this.state.customer_billing_type !== 3 ?
                           <h4>
                           {
                             this.state.newlifetimedata[0].plan == 'pro' ?
                             this.state.newlifetimedata[0].package_currency.currency_symbol +
                             this.state.newlifetimedata[0].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[1].plan == 'pro' ?
                             this.state.newlifetimedata[1].package_currency.currency_symbol +
                             this.state.newlifetimedata[1].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[2].plan == 'pro' ?
                             this.state.newlifetimedata[2].package_currency.currency_symbol +
                             this.state.newlifetimedata[2].amount.replace(".00", " ")
                            :
                             '$ 79'
                           }
                           </h4>
                         :
                         <>
                         {
                           this.state.newlifetimedata[0].plan == 'pro' ?
                           ReactHtmlParser(this.state.newlifetimedata[0].paddleprice)
                          :
                           this.state.newlifetimedata[1].plan == 'pro' ?
                           ReactHtmlParser(this.state.newlifetimedata[1].paddleprice)
                          :
                           this.state.newlifetimedata[2].plan == 'pro' ?
                           ReactHtmlParser(this.state.newlifetimedata[2].paddleprice)
                          :
                           '$ 499'
                         }
                         </>
                        }
                            <h5>one time payment</h5>
                            {
                            (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'pro' && this.state.newlifetimedata[0].is_subscribed === 1) ||
                            (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'pro' && this.state.newlifetimedata[1].is_subscribed === 1) ||
                            (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'pro' && this.state.newlifetimedata[2].is_subscribed === 1) 
                            ? 
                                (
                                    <Link to="#" className="currentplan">
                                        Current
                                    </Link>
                                )
                            :   (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'pro' && this.state.newlifetimedata[0].show_plan === 0) ||
                                (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'pro' && this.state.newlifetimedata[1].show_plan === 0) ||
                                (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'pro' && this.state.newlifetimedata[2].show_plan === 0) 
                            ? 
                                (
                                    <Link to="#" className="lowerplan">
                                        Unavailable
                                    </Link>
                                ) 
                                :   (
                                    <Link to=
                                         {
                                             this.state.customer_type === 4
                                             ? "#"
                                             : this.state.newlifetimedata[0].plan == 'pro' ?
                                               this.dlink(
                                                 this.state.newlifetimedata[0].plan_code,
                                                 parseInt(this.state.newlifetimedata[0].amount),
                                                 this.state.newlifetimedata[0].plan_name,
                                                 this.state.newlifetimedata[0].package_currency.currency_symbol,
                                                 this.state.newlifetimedata[0].plan_code
                                               )
                                             : this.state.newlifetimedata[1].plan == 'pro' ?
                                               this.dlink(
                                                 this.state.newlifetimedata[1].plan_code,
                                                 parseInt(this.state.newlifetimedata[1].amount),
                                                 this.state.newlifetimedata[1].plan_name,
                                                 this.state.newlifetimedata[1].package_currency.currency_symbol,
                                                 this.state.newlifetimedata[1].plan_code
                                               )
                                             : this.state.newlifetimedata[2].plan == 'pro' ?
                                               this.dlink(
                                                 this.state.newlifetimedata[2].plan_code,
                                                 parseInt(this.state.newlifetimedata[2].amount),
                                                 this.state.newlifetimedata[2].plan_name,
                                                 this.state.newlifetimedata[2].package_currency.currency_symbol,
                                                 this.state.newlifetimedata[2].plan_code
                                               )
                                             :
                                              this.dlink(
                                                 this.state.newlifetimedata[1].plan_code,
                                                 parseInt(this.state.newlifetimedata[1].amount),
                                                 this.state.newlifetimedata[1].plan_name,
                                                 this.state.newlifetimedata[1].package_currency.currency_symbol,
                                                 this.state.newlifetimedata[1].plan_code
                                               )
                                         }
                                         onClick={ 
                                            this.state.newlifetimedata[0].plan == 'pro' ?
                                            (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newlifetimedata[0].plan_code,
                                              1)
                                            : this.state.newlifetimedata[1].plan == 'pro' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newlifetimedata[1].plan_code,
                                              1)
                                            : this.state.newlifetimedata[2].plan == 'pro' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newlifetimedata[2].plan_code,
                                              1)
                                            :
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newlifetimedata[1].plan_code,
                                            1)
                                           }
    
    
                                     >
                                     Select
                                 </Link>
                                )}
                         </>
                        : null
                        }
                  
                            </div>   
                            <div className="pricedowngrade"></div>   
                                
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck23" />
                                <label className="mobi-tab-label" for="chck23">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox">
                                
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Convert any website</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                            </div>    
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            </div>
                                </div>
                            </div>
                                
                        </div>
                        
                        <div className="pricebox">
                            
                            <div className="price-first-half">
                            
                            <div className="bordergreen"></div>
                            
                            <div className="allflex">
                                
                                <h2>Premium</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                
                                <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                            </div>
                                
                            </div> 
                            
                            {this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0] !== null ?
                         <>
                             {this.state.customer_billing_type !== 3 ?
                           <h4>
                           {
                             this.state.newlifetimedata[0].plan == 'premium' ?
                             this.state.newlifetimedata[0].package_currency.currency_symbol +
                             this.state.newlifetimedata[0].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[1].plan == 'premium' ?
                             this.state.newlifetimedata[1].package_currency.currency_symbol +
                             this.state.newlifetimedata[1].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[2].plan == 'premium' ?
                             this.state.newlifetimedata[2].package_currency.currency_symbol +
                             this.state.newlifetimedata[2].amount.replace(".00", " ")
                            :
                             '$ 79'
                           }
                           </h4>
                         :
                         <>
                         {
                           this.state.newlifetimedata[0].plan == 'premium' ?
                           ReactHtmlParser(this.state.newlifetimedata[0].paddleprice)
                          :
                           this.state.newlifetimedata[1].plan == 'premium' ?
                           ReactHtmlParser(this.state.newlifetimedata[1].paddleprice)
                          :
                           this.state.newlifetimedata[2].plan == 'premium' ?
                           ReactHtmlParser(this.state.newlifetimedata[2].paddleprice)
                          :
                           '$ 499'
                         }
                         </>
                        }
                            <h5>one time payment</h5>
                        
                            {
                             (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'premium' && this.state.newlifetimedata[0].is_subscribed === 1) ||
                             (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'premium' && this.state.newlifetimedata[1].is_subscribed === 1) ||
                             (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'premium' && this.state.newlifetimedata[2].is_subscribed === 1) 
                             ? 
                                 (
                                     <Link to="#" className="currentplan">
                                         Current
                                     </Link>
                                 ) 
                             :   (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'premium' && this.state.newlifetimedata[0].show_plan === 0) ||
                                 (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'premium' && this.state.newlifetimedata[1].show_plan === 0) ||
                                 (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'premium' && this.state.newlifetimedata[2].show_plan === 0) 
                             ? 
                                 (
                                     <Link to="#" className="lowerplan">
                                         Unavailable
                                     </Link>
                                 )  
                                :   (
                                    <Link to=
                                         {
                                             this.state.customer_type === 4
                                             ? "#"
                                             : this.state.newlifetimedata[0].plan == 'premium' ?
                                               this.dlink(
                                                 this.state.newlifetimedata[0].plan_code,
                                                 parseInt(this.state.newlifetimedata[0].amount),
                                                 this.state.newlifetimedata[0].plan_name,
                                                 this.state.newlifetimedata[0].package_currency.currency_symbol,
                                                 this.state.newlifetimedata[0].plan_code
                                               )
                                             : this.state.newlifetimedata[1].plan == 'premium' ?
                                               this.dlink(
                                                 this.state.newlifetimedata[1].plan_code,
                                                 parseInt(this.state.newlifetimedata[1].amount),
                                                 this.state.newlifetimedata[1].plan_name,
                                                 this.state.newlifetimedata[1].package_currency.currency_symbol,
                                                 this.state.newlifetimedata[1].plan_code
                                               )
                                             : this.state.newlifetimedata[2].plan == 'premium' ?
                                               this.dlink(
                                                 this.state.newlifetimedata[2].plan_code,
                                                 parseInt(this.state.newlifetimedata[2].amount),
                                                 this.state.newlifetimedata[2].plan_name,
                                                 this.state.newlifetimedata[2].package_currency.currency_symbol,
                                                 this.state.newlifetimedata[2].plan_code
                                               )
                                             :
                                              this.dlink(
                                                 this.state.newlifetimedata[2].plan_code,
                                                 parseInt(this.state.newlifetimedata[2].amount),
                                                 this.state.newlifetimedata[2].plan_name,
                                                 this.state.newlifetimedata[2].package_currency.currency_symbol,
                                                 this.state.newlifetimedata[2].plan_code
                                               )
                                         }
                                         onClick={ 
                                            this.state.newlifetimedata[0].plan == 'premium' ?
                                            (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newlifetimedata[0].plan_code,
                                              2)
                                            : this.state.newlifetimedata[1].plan == 'premium' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newlifetimedata[1].plan_code,
                                              2)
                                            : this.state.newlifetimedata[2].plan == 'premium' ?
                                              (e) => this.handleWooCustomer(e,
                                              this.state.customer_type,
                                              this.state.newlifetimedata[2].plan_code,
                                              2)
                                            :
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newlifetimedata[2].plan_code,
                                            2)
                                           }
    
                                     >
                                     Select
                                 </Link>
                                )}
                         </>
                        : null
                        }
                            
                            </div>    
                            <div className="pricedowngrade"></div> 
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck24" />
                                <label className="mobi-tab-label" for="chck24">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div className="featurebox">
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Convert any website</p>
                                </div>    
                            </div>
                            
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Real device testing</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Merge apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>App analytics</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Easy publish</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                                
                            </div>    
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                                
                            </div>
                                </div>
                            </div>
                            
                        </div>
                    
                </div>

            </div>   
                   
            </div>
            : this.state.selectiontype !== '' && this.state.selectiontype === 3 ?
            <div className="tabbed">
              {this.state.monthlydata !== '' && this.state.monthlydata !== undefined ?
                        <input type="radio" id="tab1" name="css-tabs"  onChange={() => {this.handlechecktab('monthly')}}  defaultChecked={this.state.checkedtab == 'monthly' ? true : '' } />
                    : null
                    }
                    {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                        <input type="radio" id="tab2" name="css-tabs" onChange={() => {this.handlechecktab('yearly')}}  defaultChecked={this.state.checkedtab == 'yearly' ? true : '' } />
                    : null
                    }
                    {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                        <input type="radio" id="tab3" name="css-tabs" onChange={() => {this.handlechecktab('lifetime')}}  defaultChecked={this.state.checkedtab == 'lifetime' ? true : '' } />
                    : null
                    }
                
                <ul className="tabs">
                {this.state.monthlydata !== '' && this.state.monthlydata !== undefined ?
                    <li className="tab"><label for="tab1">Monthly</label></li>
                : null
                }
                {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                    <li className="tab"><label for="tab2">Yearly</label></li>
                : null
                }
                {this.state.yearlydata !== '' && this.state.yearlydata !== undefined ?
                    <li className="tab"><label for="tab3">Lifetime</label></li>
                : null
                }
                </ul>
                 
                 
            <div className={"tab-content " + (this.state.checkedtab === 'monthly' ? '' : 'd-none')}>
                    
                    <div className="pricingplans allflex">
                    
                    <div className="pricebox">
                        
                        <div className="price-first-half">
                            
                            <div className="borderblue"></div>
                            
                        <div className="allflex">
                            
                            <h2>Free</h2>
                            
                            <div className="platforms">
                            <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>

                            <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            </div>
                            
                        </div>  
                        
                        <h4>{this.state.customer_billing_type == 3 
                                ? this.state.paddlecurreny + '0'
                                : '$0'}<span className="pricing-decimal">.00</span></h4>
                        <h5></h5>
                        
                        { 
                           this.state.one_time[0] !== undefined && this.state.one_time[0].is_subscribed === 1 ? 
                           (
                              <Link to="#" className="currentplan">
                                Current
                              </Link>
                            ) 
                            : this.state.is_deal_plan == 1 ?
                            (
                                <Link to="#"  onClick={(e) => this.downgradepopupdeal()}>
                                    Select
                                </Link> 
                            )
                            : this.state.currentplanduration === 4 ?
                            (
                                <Link to="#" className="lowerplan">
                                    Unavailable
                                </Link> 
                            )
                            : this.state.downgrade_at_term_end === 1 ?
                            (
                                <Link to="#" className="lowerplan">
                                   Unavailable
                                </Link>
                          )
                            : this.state.one_time[0] !== undefined && this.state.one_time[0].show_plan === 0 ? 
                            (
                               <Link to="#" className="lowerplan">
                                  Unavailable
                               </Link>
                            ) 
                            : parseInt(
                                localStorage.getItem("customer_type")
                              ) === 4 &&
                              this.state.reseller_licence_id === null ? 
                            (
                              <a
                                target="_blank"
                                href={
                                  process.env.REACT_APP_WOOCOMMERCE_URL +
                                  "my-account/my-subscriptions/"
                                }
                              >
                                Select{" "}
                                <img
                                  src={
                                    require("../../assets/images/dashboard/Goto.png")
                                      .default
                                  }
                                  alt="Goto"
                                />
                              </a>
                            ) : 
                            (
                              <Link
                                to="#"
                                onClick={(e) => this.removepopup()}
                              >
                                Select
                              </Link>
                            )
                        }
                        </div>
                        <div className="pricedowngrade">
                        {this.state.downgrade_at_term_end === 1 && this.state.endcycledata !== null? 
                            
                            'Downgrades on '  + moment(this.state.endcycledata).format("Do MMM, YYYY")
                         :  this.state.downgrade_at_term_end === 1 && this.state.endcycledata === null ?
                           'Downgrade scheduled'
                         : ''}
                         {this.state.downgrade_at_term_end === 1 ? 
                        <img onClick={(e) =>
                                      this.state.customer_type === 4 ? 
                                      this.handleWooCustomer(e,this.state.customer_type,this.state.one_time[0].plan_code)
                                    //  : this.previewdowngrade(e)
                                    : this.handleReactivePopUp(e,"cancel")
                                    } 
                        src={require("../../assets/images/pricing/red-cross.png").default} alt="red-cross"/>
                        : ''}
                                  
                        </div>
                        
                        <div className="mobi-tab">
                            <input type="checkbox" id="chck5" />
                            <label className="mobi-tab-label" for="chck5">View Features</label>
                        <div className="mobi-tab-content">
                        <div className="price-second-half">
                        
                        <div class="featurebox wordpress-featurebox">
                                
                                <div class="pricewithfeature">
                                <div class="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No website required</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>In-app browser</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Customizable design</p>
                                    </div>    
                                </div>
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Push notifications</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Monetization features</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Team collaboration</p>
                                    </div>    
                                </div>    
                                
                            </div>
                        
                        <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        </div>
                            </div>
                        </div>
                    </div>
                    {
                        (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'starter' ) ||
                        (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'starter' ) ||
                        (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'starter' ) 
                    ?
                        <div className="pricebox">
                            
                            <div className="price-first-half">
                            
                            <div className="borderyellow"></div>
                            <div className="allflex">
                                
                                <h2>Starter</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            </div>
                                
                            </div>  
                            
                            {this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0] !== null ?
                        <>
                           {this.state.customer_billing_type !== 3 ?
                                <>
                                    <h4>
                                    {
                                    this.state.newmonthlydata[0].plan == 'starter' ?
                                    this.state.newmonthlydata[0].package_currency.currency_symbol +
                                    this.state.newmonthlydata[0].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[1].plan == 'starter' ?
                                    this.state.newmonthlydata[1].package_currency.currency_symbol +
                                    this.state.newmonthlydata[1].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[2].plan == 'starter' ?
                                    this.state.newmonthlydata[2].package_currency.currency_symbol +
                                    this.state.newmonthlydata[2].amount.replace(".00", " ")
                                    :
                                    '$ 29'
                                    }
                                    </h4>
                                    </>
                                :
                                <>
                                {
                                  this.state.newmonthlydata[0].plan == 'starter' ?
                                  ReactHtmlParser(this.state.newmonthlydata[0].paddleprice)
                                 :
                                  this.state.newmonthlydata[1].plan == 'starter' ?
                                  ReactHtmlParser(this.state.newmonthlydata[1].paddleprice)
                                 :
                                  this.state.newmonthlydata[2].plan == 'starter' ?
                                  ReactHtmlParser(this.state.newmonthlydata[2].paddleprice)
                                 :
                                  '$ 29'
                                }
                                </>
                                }
                            <h5>per month</h5>
                        
                        {
                         (this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'starter' && this.state.newmonthlydata[0].is_subscribed === 1) ||
                         (this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'starter' && this.state.newmonthlydata[1].is_subscribed === 1) ||
                         (this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'starter' && this.state.newmonthlydata[2].is_subscribed === 1) 
                         ? 
                             (
                                 <Link to="#" className="currentplan">
                                     Current
                                 </Link>
                             ) 
                         : 
                             (this.state.newmonthlydata[0].plan == 'starter' && this.state.newmonthlydata[0].show_plan === 0) ||
                             (this.state.newmonthlydata[1].plan == 'starter' && this.state.newmonthlydata[1].show_plan === 0) ||
                             (this.state.newmonthlydata[2].plan == 'starter' && this.state.newmonthlydata[2].show_plan === 0) 
                         ?   
                         (
                             <Link to="#"  className="lowerplan">
                                Unavailable
                             </Link>
                         ) 
                        :   (
                                <Link to=
                                        {
                                            this.state.customer_type === 4
                                            ? "#"
                                            : this.state.newmonthlydata[0].plan == 'starter' ?
                                            this.dlink(
                                                this.state.newmonthlydata[0].plan_code,
                                                parseInt(this.state.newmonthlydata[0].amount),
                                                this.state.newmonthlydata[0].plan_name,
                                                this.state.newmonthlydata[0].package_currency.currency_symbol,
                                                this.state.newmonthlydata[0].plan_code
                                            )
                                            : this.state.newmonthlydata[1].plan == 'starter' ?
                                            this.dlink(
                                                this.state.newmonthlydata[1].plan_code,
                                                parseInt(this.state.newmonthlydata[1].amount),
                                                this.state.newmonthlydata[1].plan_name,
                                                this.state.newmonthlydata[1].package_currency.currency_symbol,
                                                this.state.newmonthlydata[1].plan_code
                                            )
                                            : this.state.newmonthlydata[2].plan == 'starter' ?
                                            this.dlink(
                                                this.state.newmonthlydata[2].plan_code,
                                                parseInt(this.state.newmonthlydata[1].amount),
                                                this.state.newmonthlydata[2].plan_name,
                                                this.state.newmonthlydata[2].package_currency.currency_symbol,
                                                this.state.newmonthlydata[2].plan_code
                                            )
                                            :
                                            this.dlink(
                                                this.state.newmonthlydata[0].plan_code,
                                                parseInt(this.state.newmonthlydata[0].amount),
                                                this.state.newmonthlydata[0].plan_name,
                                                this.state.newmonthlydata[0].package_currency.currency_symbol,
                                                this.state.newmonthlydata[0].plan_code
                                            )


                                        }

                                        onClick={ 
                                            this.state.newmonthlydata[0].plan == 'starter' ?
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newmonthlydata[0].plan_code,
                                            0)
                                            : this.state.newmonthlydata[1].plan == 'starter' ?
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newmonthlydata[1].plan_code,
                                            0)
                                            : this.state.newmonthlydata[2].plan == 'starter' ?
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newmonthlydata[2].plan_code,
                                            0)
                                            :
                                            (e) => this.handleWooCustomer(e,
                                            this.state.customer_type,
                                            this.state.newmonthlydata[0].plan_code,
                                            0)
                                        }

                                    >
                                    Select
                                </Link>
                            )}
                        </>
                        : null
                        }
                            
                            </div>
                            <div className="pricedowngrade"></div>
                                
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck6" />
                                <label className="mobi-tab-label" for="chck6">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div class="featurebox wordpress-featurebox">
                                
                                <div class="pricewithfeature">
                                <div class="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No website required</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>In-app browser</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Customizable design</p>
                                    </div>    
                                </div>
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Push notifications</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Monetization features</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Team collaboration</p>
                                    </div>    
                                </div>   

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div> 
                                
                            </div>    
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            </div>
                                </div>
                            </div>
                                
                        </div>
                    : null
                    } 
                    {
                       (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'pro' ) ||
                       (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'pro' ) ||
                       (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'pro' ) 
                    ?
                    
                    <div className="pricebox">
                        
                        <div className="price-first-half">
                        
                        <div className="borderorange"></div>
                        
                        <div className="allflex">
                            
                            <h2>Pro</h2>
                            
                            <div className="platforms">
                            <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                            
                            <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            
                            <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                        </div>
                            
                        </div>  
                        
                        {this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0] !== null ?
                     <>
                        {this.state.customer_billing_type !== 3 ?
                                <>
                                    <h4>
                                    {
                                    this.state.newmonthlydata[0].plan == 'pro' ?
                                    this.state.newmonthlydata[0].package_currency.currency_symbol +
                                    this.state.newmonthlydata[0].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[1].plan == 'pro' ?
                                    this.state.newmonthlydata[1].package_currency.currency_symbol +
                                    this.state.newmonthlydata[1].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[2].plan == 'pro' ?
                                    this.state.newmonthlydata[2].package_currency.currency_symbol +
                                    this.state.newmonthlydata[2].amount.replace(".00", " ")
                                    :
                                    '$ 29'
                                    }
                                    </h4>
                                    </>
                                :
                                <>
                                {
                                  this.state.newmonthlydata[0].plan == 'pro' ?
                                  ReactHtmlParser(this.state.newmonthlydata[0].paddleprice)
                                 :
                                  this.state.newmonthlydata[1].plan == 'pro' ?
                                  ReactHtmlParser(this.state.newmonthlydata[1].paddleprice)
                                 :
                                  this.state.newmonthlydata[2].plan == 'pro' ?
                                  ReactHtmlParser(this.state.newmonthlydata[2].paddleprice)
                                 :
                                  '$ 29'
                                }
                                </>
                                }
                        <h5>per month</h5>
                        {
                     (this.state.newmonthlydata[0].plan == 'pro' && this.state.newmonthlydata[0].is_subscribed === 1) ||
                     (this.state.newmonthlydata[1].plan == 'pro' && this.state.newmonthlydata[1].is_subscribed === 1) ||
                     (this.state.newmonthlydata[2].plan == 'pro' && this.state.newmonthlydata[2].is_subscribed === 1) 
                     ? 
                         (
                             <Link to="#" className="currentplan">
                                 Current
                             </Link>
                         ) 
                     : 
                         (this.state.newmonthlydata[0].plan == 'pro' && this.state.newmonthlydata[0].show_plan === 0) ||
                         (this.state.newmonthlydata[1].plan == 'pro' && this.state.newmonthlydata[1].show_plan === 0) ||
                         (this.state.newmonthlydata[2].plan == 'pro' && this.state.newmonthlydata[2].show_plan === 0) 
                     ?   
                     (
                         <Link to="#"  className="lowerplan">
                             Unavailable
                         </Link>
                     )  
                    :   (
                               <Link to=
                                    {
                                        this.state.customer_type === 4
                                        ? "#"
                                        : this.state.newmonthlydata[0].plan == 'pro' ?
                                          this.dlink(
                                            this.state.newmonthlydata[0].plan_code,
                                            parseInt(this.state.newmonthlydata[0].amount),
                                            this.state.newmonthlydata[0].plan_name,
                                            this.state.newmonthlydata[0].package_currency.currency_symbol,
                                            this.state.newmonthlydata[0].plan_code
                                          )
                                        : this.state.newmonthlydata[1].plan == 'pro' ?
                                          this.dlink(
                                            this.state.newmonthlydata[1].plan_code,
                                            parseInt(this.state.newmonthlydata[1].amount),
                                            this.state.newmonthlydata[1].plan_name,
                                            this.state.newmonthlydata[1].package_currency.currency_symbol,
                                            this.state.newmonthlydata[1].plan_code
                                          )
                                        : this.state.newmonthlydata[2].plan == 'pro' ?
                                          this.dlink(
                                            this.state.newmonthlydata[2].plan_code,
                                            parseInt(this.state.newmonthlydata[1].amount),
                                            this.state.newmonthlydata[2].plan_name,
                                            this.state.newmonthlydata[2].package_currency.currency_symbol,
                                            this.state.newmonthlydata[2].plan_code
                                          )
                                        :
                                         this.dlink(
                                            this.state.newmonthlydata[1].plan_code,
                                            parseInt(this.state.newmonthlydata[1].amount),
                                            this.state.newmonthlydata[1].plan_name,
                                            this.state.newmonthlydata[1].package_currency.currency_symbol,
                                            this.state.newmonthlydata[1].plan_code
                                          )


                                    }
                                    onClick={ 
                                        this.state.newmonthlydata[0].plan == 'pro' ?
                                        (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newmonthlydata[0].plan_code,
                                          1)
                                        : this.state.newmonthlydata[1].plan == 'pro' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newmonthlydata[1].plan_code,
                                          1)
                                        : this.state.newmonthlydata[2].plan == 'pro' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newmonthlydata[2].plan_code,
                                          1)
                                        :
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newmonthlydata[1].plan_code,
                                        1)
                                       }

                                >
                                Select
                            </Link>
                        )}
                     </>
                    : null
                    }
              
                        </div>  
                        <div className="pricedowngrade"></div>    
                            
                        <div className="mobi-tab">
                            <input type="checkbox" id="chck7" />
                            <label className="mobi-tab-label" for="chck7">View Features</label>
                        <div className="mobi-tab-content">
                        <div className="price-second-half">
                        
                        <div class="featurebox wordpress-featurebox">
                                
                                
                                <div class="pricewithfeature">
                                <div class="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No website required</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Customizable design</p>
                                    </div>    
                                </div>
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                            </div>    
                        
                        <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        </div> 
                            </div>
                        </div>
                            
                    </div>
                    : null
                    }
                    {
                        (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0].plan == 'premium' ) ||
                        (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[1] !== undefined && this.state.newmonthlydata[1].plan == 'premium' ) ||
                        (this.state.newmonthlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newmonthlydata[2] !== undefined && this.state.newmonthlydata[2].plan == 'premium' ) 
                    ?
                    
                        <div className="pricebox">
                            
                            <div className="price-first-half">
                            
                            <div className="bordergreen"></div>
                            
                            <div className="allflex">
                                
                                <h2>Premium</h2>
                                
                                <div className="platforms">
                                <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                                
                                <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                                
                                <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                            </div>
                                
                            </div> 
                            
                            {this.state.newmonthlydata[0] !== undefined && this.state.newmonthlydata[0] !== null ?
                        <>
                            {this.state.customer_billing_type !== 3 ?
                                <>
                                    <h4>
                                    {
                                    this.state.newmonthlydata[0].plan == 'premium' ?
                                    this.state.newmonthlydata[0].package_currency.currency_symbol +
                                    this.state.newmonthlydata[0].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[1].plan == 'premium' ?
                                    this.state.newmonthlydata[1].package_currency.currency_symbol +
                                    this.state.newmonthlydata[1].amount.replace(".00", " ")
                                    :
                                    this.state.newmonthlydata[2].plan == 'premium' ?
                                    this.state.newmonthlydata[2].package_currency.currency_symbol +
                                    this.state.newmonthlydata[2].amount.replace(".00", " ")
                                    :
                                    '$ 29'
                                    }
                                    </h4>
                                    </>
                                :
                                <>
                                {
                                  this.state.newmonthlydata[0].plan == 'premium' ?
                                  ReactHtmlParser(this.state.newmonthlydata[0].paddleprice)
                                 :
                                  this.state.newmonthlydata[1].plan == 'premium' ?
                                  ReactHtmlParser(this.state.newmonthlydata[1].paddleprice)
                                 :
                                  this.state.newmonthlydata[2].plan == 'premium' ?
                                  ReactHtmlParser(this.state.newmonthlydata[2].paddleprice)
                                 :
                                  '$ 29'
                                }
                                </>
                                }
                            <h5>per month</h5>
                        {
                        (this.state.newmonthlydata[0].plan == 'premium' && this.state.newmonthlydata[0].is_subscribed === 1) ||
                        (this.state.newmonthlydata[1].plan == 'premium' && this.state.newmonthlydata[1].is_subscribed === 1) ||
                        (this.state.newmonthlydata[2].plan == 'premium' && this.state.newmonthlydata[2].is_subscribed === 1) 
                        ? 
                            (
                                <Link to="#" className="currentplan">
                                    Current
                                </Link>
                            ) 
                        : 
                            (this.state.newmonthlydata[0].plan == 'premium' && this.state.newmonthlydata[0].show_plan === 0) ||
                            (this.state.newmonthlydata[1].plan == 'premium' && this.state.newmonthlydata[1].show_plan === 0) ||
                            (this.state.newmonthlydata[2].plan == 'premium' && this.state.newmonthlydata[2].show_plan === 0) 
                        ?   
                        (
                            <Link to="#"  className="lowerplan">
                                Unavailable
                            </Link>
                        )  
                            :   (
                                <Link to=
                                    {
                                        this.state.customer_type === 4
                                        ? "#"
                                        : this.state.newmonthlydata[0].plan == 'premium' ?
                                        this.dlink(
                                            this.state.newmonthlydata[0].plan_code,
                                            parseInt(this.state.newmonthlydata[0].amount),
                                            this.state.newmonthlydata[0].plan_name,
                                            this.state.newmonthlydata[0].package_currency.currency_symbol,
                                            this.state.newmonthlydata[0].plan_code
                                        )
                                        : this.state.newmonthlydata[1].plan == 'premium' ?
                                        this.dlink(
                                            this.state.newmonthlydata[1].plan_code,
                                            parseInt(this.state.newmonthlydata[1].amount),
                                            this.state.newmonthlydata[1].plan_name,
                                            this.state.newmonthlydata[1].package_currency.currency_symbol,
                                            this.state.newmonthlydata[1].plan_code
                                        )
                                        : this.state.newmonthlydata[2].plan == 'premium' ?
                                        this.dlink(
                                            this.state.newmonthlydata[2].plan_code,
                                            parseInt(this.state.newmonthlydata[2].amount),
                                            this.state.newmonthlydata[2].plan_name,
                                            this.state.newmonthlydata[2].package_currency.currency_symbol,
                                            this.state.newmonthlydata[2].plan_code
                                        )
                                        :
                                        this.dlink(
                                            this.state.newmonthlydata[2].plan_code,
                                            parseInt(this.state.newmonthlydata[2].amount),
                                            this.state.newmonthlydata[2].plan_name,
                                            this.state.newmonthlydata[2].package_currency.currency_symbol,
                                            this.state.newmonthlydata[2].plan_code
                                        )


                                    }
                                    onClick={ 
                                        this.state.newmonthlydata[0].plan == 'premium' ?
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newmonthlydata[0].plan_code,
                                        2)
                                        : this.state.newmonthlydata[1].plan == 'premium' ?
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newmonthlydata[1].plan_code,
                                        2)
                                        : this.state.newmonthlydata[2].plan == 'premium' ?
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newmonthlydata[2].plan_code,
                                        2)
                                        :
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newmonthlydata[2].plan_code,
                                        2)
                                    }

                                >
                                Select
                            </Link>
                        )}
                        </>
                        : null
                        }
                            
                            </div> 
                            <div className="pricedowngrade"></div>      
                                
                            <div className="mobi-tab">
                                <input type="checkbox" id="chck8" />
                                <label className="mobi-tab-label" for="chck8">View Features</label>
                            <div className="mobi-tab-content">
                            <div className="price-second-half">
                            
                            <div class="featurebox wordpress-featurebox">
                                
                                <div class="pricewithfeature">
                                <div class="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No website required</p>
                                </div>    
                            </div>
                            
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Merge apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Language selection</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>App analytics</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Easy publish</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                                
                            </div>    
                            
                            <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                            <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                                
                            </div>
                                </div>
                            </div>
                            
                        </div>
                    : null
                    }
                    
                </div>    

            </div>    
                 
            <div className={"tab-content " + (this.state.checkedtab === 'yearly' ? '' : 'd-none')}>

                    <div className="pricingplans allflex">
                    
                    <div className="pricebox">
                        
                        <div className="price-first-half">
                            
                            <div className="borderblue"></div>
                            
                        <div className="allflex">
                            
                            <h2>Free</h2>
                            
                            <div className="platforms">
                            <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>

                            <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            </div>
                            
                        </div>  
                        
                        <h4>{this.state.customer_billing_type == 3 
                                ? this.state.paddlecurreny + '0'
                                : '$0'}<span className="pricing-decimal">.00</span></h4>
                        <h5></h5>
                        
                        { 
                           this.state.one_time[0] !== undefined && this.state.one_time[0].is_subscribed === 1 ? 
                           (
                              <Link to="#" className="currentplan">
                                Current
                              </Link>
                            )
                            : this.state.is_deal_plan == 1 ?
                            (
                                <Link to="#"  onClick={(e) => this.downgradepopupdeal()}>
                                    Select
                                </Link> 
                            )
                            : this.state.currentplanduration === 4 ?
                            (
                                <Link to="#" className="lowerplan">
                                    Unavailable
                                </Link> 
                            ) 
                            : this.state.downgrade_at_term_end === 1 ?
                            (
                                <Link to="#" className="lowerplan">
                                   Unavailable
                                </Link>
                          )
                            : this.state.one_time[0] !== undefined && this.state.one_time[0].show_plan === 0 ? 
                            (
                               <Link to="#" className="lowerplan">
                                  Unavailable
                               </Link>
                            )  
                            : this.state.customer_type === 4 &&
                              this.state.reseller_licence_id === null ? 
                            (
                              <a
                                target="_blank"
                                href={
                                  process.env.REACT_APP_WOOCOMMERCE_URL +
                                  "my-account/my-subscriptions/"
                                }
                              >
                                Select{" "}
                                <img
                                  src={
                                    require("../../assets/images/dashboard/Goto.png")
                                      .default
                                  }
                                  alt="Goto"
                                />
                              </a>
                            ) : 
                            (
                              <Link
                                to="#"
                                onClick={(e) => this.removepopup()}
                              >
                                Select
                              </Link>
                            )
                        }
                        </div>
                        <div className="pricedowngrade">
                        {this.state.downgrade_at_term_end === 1 && this.state.endcycledata !== null? 
                            
                            'Downgrades on '  + moment(this.state.endcycledata).format("Do MMM, YYYY")
                         :  this.state.downgrade_at_term_end === 1 && this.state.endcycledata === null ?
                           'Downgrade scheduled'
                         : ''}
                         {this.state.downgrade_at_term_end === 1 ? 
                        <img onClick={(e) =>
                                      this.state.customer_type === 4 ? 
                                      this.handleWooCustomer(e,this.state.customer_type,this.state.one_time[0].plan_code)
                                    //  : this.previewdowngrade(e)
                                    : this.handleReactivePopUp(e,"cancel")
                                    } 
                        src={require("../../assets/images/pricing/red-cross.png").default} alt="red-cross"/>
                        : ''}
                                  
                        </div>
                        
                        <div className="mobi-tab">
                            <input type="checkbox" id="chck13" />
                            <label className="mobi-tab-label" for="chck13">View Features</label>
                        <div className="mobi-tab-content">
                        <div className="price-second-half">
                        
                        <div class="featurebox wordpress-featurebox">
                                
                                <div class="pricewithfeature">
                                <div class="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No website required</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>In-app browser</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Customizable design</p>
                                    </div>    
                                </div>
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Push notifications</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Monetization features</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Team collaboration</p>
                                    </div>    
                                </div>    
                                
                            </div>
                        
                        <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="pricebox">
                        
                        <div className="price-first-half">
                        
                        <div className="borderyellow"></div>
                        <div className="allflex">
                            
                            <h2>Starter</h2>
                            
                            <div className="platforms">
                            <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                            
                            <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                        </div>
                            
                        </div>  
                        
                        {this.state.newyearlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0] !== null ?
                     <>
                        {this.state.customer_billing_type !== 3 ?
                                <>
                             <h4>
                            {
                              this.state.newyearlydata[0].plan == 'starter' ?
                              this.state.newyearlydata[0].package_currency.currency_symbol +
                              this.state.newyearlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[1].plan == 'starter' ?
                              this.state.newyearlydata[1].package_currency.currency_symbol +
                              this.state.newyearlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[2].plan == 'starter' ?
                              this.state.newyearlydata[2].package_currency.currency_symbol +
                              this.state.newyearlydata[2].amount.replace(".00", " ")
                             :
                              '$ 19'
                            }
                            </h4>
                        </>
                        :
                        <>
                        {
                          this.state.newyearlydata[0].plan == 'starter' ?
                          ReactHtmlParser(this.state.newyearlydata[0].paddleprice)
                         :
                          this.state.newyearlydata[1].plan == 'starter' ?
                          ReactHtmlParser(this.state.newyearlydata[1].paddleprice)
                         :
                          this.state.newyearlydata[2].plan == 'starter' ?
                          ReactHtmlParser(this.state.newyearlydata[2].paddleprice)
                         :
                          '$ 19'
                        }
                        </>
                        }
                        <h5>per month paid yearly</h5>
                      {
                        (this.state.newyearlydata[0].plan == 'starter' && this.state.newyearlydata[0].is_subscribed === 1) ||
                        (this.state.newyearlydata[1].plan == 'starter' && this.state.newyearlydata[1].is_subscribed === 1) ||
                        (this.state.newyearlydata[2].plan == 'starter' && this.state.newyearlydata[2].is_subscribed === 1) 
                        ? 
                            (
                                <Link to="#" className="currentplan">
                                    Current
                                </Link>
                            ) 
                        : 
                            (this.state.newyearlydata[0].plan == 'starter' && this.state.newyearlydata[0].show_plan === 0) ||
                            (this.state.newyearlydata[1].plan == 'starter' && this.state.newyearlydata[1].show_plan === 0) ||
                            (this.state.newyearlydata[2].plan == 'starter' && this.state.newyearlydata[2].show_plan === 0) 
                        ?   
                        (
                            <Link to="#"  class="lowerplan">
                                Select
                            </Link>
                        )  
                        :   (
                            <Link to=
                                 {
                                     this.state.customer_type === 4
                                     ? "#"
                                     : this.state.newyearlydata[0].plan == 'starter' ?
                                       this.dlink(
                                         this.state.newyearlydata[0].plan_code,
                                         parseInt(this.state.newyearlydata[0].amount),
                                         this.state.newyearlydata[0].plan_name,
                                         this.state.newyearlydata[0].package_currency.currency_symbol,
                                         this.state.newyearlydata[0].plan_code
                                       )
                                     : this.state.newyearlydata[1].plan == 'starter' ?
                                       this.dlink(
                                         this.state.newyearlydata[1].plan_code,
                                         parseInt(this.state.newyearlydata[1].amount),
                                         this.state.newyearlydata[1].plan_name,
                                         this.state.newyearlydata[1].package_currency.currency_symbol,
                                         this.state.newyearlydata[1].plan_code
                                       )
                                     : this.state.newyearlydata[2].plan == 'starter' ?
                                       this.dlink(
                                         this.state.newyearlydata[2].plan_code,
                                         parseInt(this.state.newyearlydata[2].amount),
                                         this.state.newyearlydata[2].plan_name,
                                         this.state.newyearlydata[2].package_currency.currency_symbol,
                                         this.state.newyearlydata[2].plan_code
                                       )
                                     :
                                      this.dlink(
                                         this.state.newyearlydata[0].plan_code,
                                         parseInt(this.state.newyearlydata[0].amount),
                                         this.state.newyearlydata[0].plan_name,
                                         this.state.newyearlydata[0].package_currency.currency_symbol,
                                         this.state.newyearlydata[0].plan_code
                                       )


                                 }
                                 onClick={ 
                                    this.state.newyearlydata[0].plan == 'starter' ?
                                    (e) => this.handleWooCustomer(e,
                                      this.state.customer_type,
                                      this.state.newyearlydata[0].plan_code,
                                      0)
                                    : this.state.newyearlydata[1].plan == 'starter' ?
                                      (e) => this.handleWooCustomer(e,
                                      this.state.customer_type,
                                      this.state.newyearlydata[1].plan_code,
                                      0)
                                    : this.state.newyearlydata[2].plan == 'starter' ?
                                      (e) => this.handleWooCustomer(e,
                                      this.state.customer_type,
                                      this.state.newyearlydata[2].plan_code,
                                      0)
                                    :
                                    (e) => this.handleWooCustomer(e,
                                    this.state.customer_type,
                                    this.state.newyearlydata[0].plan_code,
                                    0)
                                   }

                             >
                             Select
                         </Link>
                     )}
                     </>
                    : null
                    }
                        
                        </div>
                        <div className="pricedowngrade"></div> 
                            
                        <div className="mobi-tab">
                            <input type="checkbox" id="chck14" />
                            <label className="mobi-tab-label" for="chck14">View Features</label>
                        <div className="mobi-tab-content">
                        <div className="price-second-half">
                        
                        <div class="featurebox wordpress-featurebox">
                                
                                <div class="pricewithfeature">
                                <div class="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No website required</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>In-app browser</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Customizable design</p>
                                    </div>    
                                </div>
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Push notifications</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Monetization features</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Team collaboration</p>
                                    </div>    
                                </div>  

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>  
                                
                            </div>    
                        
                        <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        </div>
                            </div>
                        </div>
                            
                    </div>
                    
                    <div className="pricebox">
                        
                        <div className="price-first-half">
                        
                        <div className="borderorange"></div>
                        
                        <div className="allflex">
                            
                            <h2>Pro</h2>
                            
                            <div className="platforms">
                            <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                            
                            <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            
                            <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                        </div>
                            
                        </div>  
                        
                        {this.state.newyearlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0] !== null ?
                     <>
                        {this.state.customer_billing_type !== 3 ?
                                <>
                             <h4>
                            {
                              this.state.newyearlydata[0].plan == 'pro' ?
                              this.state.newyearlydata[0].package_currency.currency_symbol +
                              this.state.newyearlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[1].plan == 'pro' ?
                              this.state.newyearlydata[1].package_currency.currency_symbol +
                              this.state.newyearlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[2].plan == 'pro' ?
                              this.state.newyearlydata[2].package_currency.currency_symbol +
                              this.state.newyearlydata[2].amount.replace(".00", " ")
                             :
                              '$ 19'
                            }
                            </h4>
                        </>
                        :
                        <>
                        {
                          this.state.newyearlydata[0].plan == 'pro' ?
                          ReactHtmlParser(this.state.newyearlydata[0].paddleprice)
                         :
                          this.state.newyearlydata[1].plan == 'pro' ?
                          ReactHtmlParser(this.state.newyearlydata[1].paddleprice)
                         :
                          this.state.newyearlydata[2].plan == 'pro' ?
                          ReactHtmlParser(this.state.newyearlydata[2].paddleprice)
                         :
                          '$ 19'
                        }
                        </>
                        }
                        <h5>per month paid yearly</h5>
                        {
                         (this.state.newyearlydata[0].plan == 'pro' && this.state.newyearlydata[0].is_subscribed === 1) ||
                         (this.state.newyearlydata[1].plan == 'pro' && this.state.newyearlydata[1].is_subscribed === 1) ||
                         (this.state.newyearlydata[2].plan == 'pro' && this.state.newyearlydata[2].is_subscribed === 1) 
                         ? 
                             (
                                 <Link to="#" className="currentplan">
                                     Current
                                 </Link>
                             )
                             : 
                             (this.state.newyearlydata[0].plan == 'pro' && this.state.newyearlydata[0].show_plan === 0) ||
                             (this.state.newyearlydata[1].plan == 'pro' && this.state.newyearlydata[1].show_plan === 0) ||
                             (this.state.newyearlydata[2].plan == 'pro' && this.state.newyearlydata[2].show_plan === 0) 
                         ?   
                         (
                             <Link to="#"  class="lowerplan">
                             Select
                         </Link>
                         )   
                        :   (
                                <Link to=
                                     {
                                         this.state.customer_type === 4
                                         ? "#"
                                         : this.state.newyearlydata[0].plan == 'pro' ?
                                           this.dlink(
                                             this.state.newyearlydata[0].plan_code,
                                             parseInt(this.state.newyearlydata[0].amount),
                                             this.state.newyearlydata[0].plan_name,
                                             this.state.newyearlydata[0].package_currency.currency_symbol,
                                             this.state.newyearlydata[0].plan_code
                                           )
                                         : this.state.newyearlydata[1].plan == 'pro' ?
                                           this.dlink(
                                             this.state.newyearlydata[1].plan_code,
                                             parseInt(this.state.newyearlydata[1].amount),
                                             this.state.newyearlydata[1].plan_name,
                                             this.state.newyearlydata[1].package_currency.currency_symbol,
                                             this.state.newyearlydata[1].plan_code
                                           )
                                         : this.state.newyearlydata[2].plan == 'pro' ?
                                           this.dlink(
                                             this.state.newyearlydata[2].plan_code,
                                             parseInt(this.state.newyearlydata[2].amount),
                                             this.state.newyearlydata[2].plan_name,
                                             this.state.newyearlydata[2].package_currency.currency_symbol,
                                             this.state.newyearlydata[2].plan_code
                                           )
                                         :
                                          this.dlink(
                                             this.state.newyearlydata[1].plan_code,
                                             parseInt(this.state.newyearlydata[1].amount),
                                             this.state.newyearlydata[1].plan_name,
                                             this.state.newyearlydata[1].package_currency.currency_symbol,
                                             this.state.newyearlydata[1].plan_code
                                           )


                                     }
                                     onClick={ 
                                        this.state.newyearlydata[0].plan == 'pro' ?
                                        (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newyearlydata[0].plan_code,
                                          1)
                                        : this.state.newyearlydata[1].plan == 'pro' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newyearlydata[1].plan_code,
                                          1)
                                        : this.state.newyearlydata[2].plan == 'pro' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newyearlydata[2].plan_code,
                                          1)
                                        :
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newyearlydata[1].plan_code,
                                        1)
                                       }

                                 >
                                 Select
                             </Link>
                         )}
                     </>
                    : null
                    }
              
                        </div> 
                        <div className="pricedowngrade"></div>    
                            
                        <div className="mobi-tab">
                            <input type="checkbox" id="chck15" />
                            <label className="mobi-tab-label" for="chck15">View Features</label>
                        <div className="mobi-tab-content">
                        <div className="price-second-half">
                        
                        <div class="featurebox wordpress-featurebox">
                                
                                
                                <div class="pricewithfeature">
                                <div class="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No website required</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Customizable design</p>
                                    </div>    
                                </div>
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                            </div>    
                        
                        <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        </div>
                            </div>
                        </div>
                            
                    </div>
                    
                    <div className="pricebox">
                        
                        <div className="price-first-half">
                        
                        <div className="bordergreen"></div>
                        
                        <div className="allflex">
                            
                            <h2>Premium</h2>
                            
                            <div className="platforms">
                            <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                            
                            <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            
                            <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                        </div>
                            
                        </div> 
                        
                        {this.state.newyearlydata !== '' && this.state.newmonthlydata !== undefined && this.state.newyearlydata[0] !== undefined && this.state.newyearlydata[0] !== null ?
                     <>
                        {this.state.customer_billing_type !== 3 ?
                                <>
                             <h4>
                            {
                              this.state.newyearlydata[0].plan == 'premium' ?
                              this.state.newyearlydata[0].package_currency.currency_symbol +
                              this.state.newyearlydata[0].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[1].plan == 'premium' ?
                              this.state.newyearlydata[1].package_currency.currency_symbol +
                              this.state.newyearlydata[1].amount.replace(".00", " ")
                             :
                              this.state.newyearlydata[2].plan == 'premium' ?
                              this.state.newyearlydata[2].package_currency.currency_symbol +
                              this.state.newyearlydata[2].amount.replace(".00", " ")
                             :
                              '$ 19'
                            }
                            </h4>
                        </>
                        :
                        <>
                        {
                          this.state.newyearlydata[0].plan == 'premium' ?
                          ReactHtmlParser(this.state.newyearlydata[0].paddleprice)
                         :
                          this.state.newyearlydata[1].plan == 'premium' ?
                          ReactHtmlParser(this.state.newyearlydata[1].paddleprice)
                         :
                          this.state.newyearlydata[2].plan == 'premium' ?
                          ReactHtmlParser(this.state.newyearlydata[2].paddleprice)
                         :
                          '$ 19'
                        }
                        </>
                        }
                        <h5>per month paid yearly</h5>
                        {
                            (this.state.newyearlydata[0].plan == 'premium' && this.state.newyearlydata[0].is_subscribed === 1) ||
                            (this.state.newyearlydata[1].plan == 'premium' && this.state.newyearlydata[1].is_subscribed === 1) ||
                            (this.state.newyearlydata[2].plan == 'premium' && this.state.newyearlydata[2].is_subscribed === 1) 
                            ? 
                                (
                                    <Link to="#" className="currentplan">
                                        Current
                                    </Link>
                                )
                            : 
                            (this.state.newyearlydata[0].plan == 'premium' && this.state.newyearlydata[0].show_plan === 0) ||
                            (this.state.newyearlydata[1].plan == 'premium' && this.state.newyearlydata[1].show_plan === 0) ||
                            (this.state.newyearlydata[2].plan == 'premium' && this.state.newyearlydata[2].show_plan === 0) 
                            ? 
                            (
                                <Link to="#"  class="lowerplan">
                                Select
                            </Link>
                            )
                            :   (
                                <Link to=
                                     {
                                         this.state.customer_type === 4
                                         ? "#"
                                         : this.state.newyearlydata[0].plan == 'premium' ?
                                           this.dlink(
                                             this.state.newyearlydata[0].plan_code,
                                             parseInt(this.state.newyearlydata[0].amount),
                                             this.state.newyearlydata[0].plan_name,
                                             this.state.newyearlydata[0].package_currency.currency_symbol,
                                             this.state.newyearlydata[0].plan_code
                                           )
                                         : this.state.newyearlydata[1].plan == 'premium' ?
                                           this.dlink(
                                             this.state.newyearlydata[1].plan_code,
                                             parseInt(this.state.newyearlydata[1].amount),
                                             this.state.newyearlydata[1].plan_name,
                                             this.state.newyearlydata[1].package_currency.currency_symbol,
                                             this.state.newyearlydata[1].plan_code
                                           )
                                         : this.state.newyearlydata[2].plan == 'premium' ?
                                           this.dlink(
                                             this.state.newyearlydata[2].plan_code,
                                             parseInt(this.state.newyearlydata[2].amount),
                                             this.state.newyearlydata[2].plan_name,
                                             this.state.newyearlydata[2].package_currency.currency_symbol,
                                             this.state.newyearlydata[2].plan_code
                                           )
                                         :
                                          this.dlink(
                                             this.state.newyearlydata[2].plan_code,
                                             parseInt(this.state.newyearlydata[2].amount),
                                             this.state.newyearlydata[2].plan_name,
                                             this.state.newyearlydata[2].package_currency.currency_symbol,
                                             this.state.newyearlydata[2].plan_code
                                           )


                                     }
                                     onClick={ 
                                        this.state.newyearlydata[0].plan == 'premium' ?
                                        (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newyearlydata[0].plan_code,
                                          2)
                                        : this.state.newyearlydata[1].plan == 'premium' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newyearlydata[1].plan_code,
                                          2)
                                        : this.state.newyearlydata[2].plan == 'premium' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newyearlydata[2].plan_code,
                                          2)
                                        :
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newyearlydata[2].plan_code,
                                        2)
                                       }

                                 >
                                 Select
                             </Link>
                         )}
                     </>
                    : null
                    }
                        
                        </div> 
                        <div className="pricedowngrade"></div>    
                            
                        <div className="mobi-tab">
                            <input type="checkbox" id="chck16" />
                            <label className="mobi-tab-label" for="chck16">View Features</label>
                        <div className="mobi-tab-content">
                        <div className="price-second-half">
                        
                        <div class="featurebox wordpress-featurebox">
                                
                                <div class="pricewithfeature">
                                <div class="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No website required</p>
                                </div>    
                            </div>
                            
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Merge apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Language selection</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>App analytics</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Easy publish</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                                
                            </div>    
                        
                        <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                        </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>  

            </div>
                
            <div className={"tab-content " + (this.state.checkedtab === 'lifetime' ? '' : 'd-none')}>

                    <div className="pricingplans allflex">
                    
                    <div className="pricebox">
                        
                        <div className="price-first-half">
                            
                            <div className="borderblue"></div>
                            
                        <div className="allflex">
                            
                            <h2>Free</h2>
                            
                            <div className="platforms">
                            <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>

                            <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            </div>
                            
                        </div>  
                        
                        <h4>{this.state.customer_billing_type == 3 
                                ? this.state.paddlecurreny + '0'
                                : '$0'}<span className="pricing-decimal">.00</span></h4>
                        <h5></h5>
                        
                        { 
                           this.state.one_time[0] !== undefined && this.state.one_time[0].is_subscribed === 1 ? 
                           (
                              <Link to="#" className="currentplan">
                                Current
                              </Link>
                            )
                            : this.state.is_deal_plan == 1 ?
                            (
                                <Link to="#"  onClick={(e) => this.downgradepopupdeal()}>
                                    Select
                                </Link> 
                            )
                            : this.state.currentplanduration === 4 ?
                            (
                                <Link to="#" className="lowerplan">
                                    Unavailable
                                </Link> 
                            ) 
                            : this.state.downgrade_at_term_end === 1 ?
                            (
                                <Link to="#" className="lowerplan">
                                   Unavailable
                                </Link>
                          )
                          : this.state.one_time[0] !== undefined && this.state.one_time[0].show_plan === 0 ? 
                            (
                               <Link to="#" className="lowerplan">
                                  Unavailable
                               </Link>
                             ) 
                            : this.state.customer_type === 4 &&
                              this.state.reseller_licence_id === null ? 
                            (
                              <a
                                target="_blank"
                                href={
                                  process.env.REACT_APP_WOOCOMMERCE_URL +
                                  "my-account/my-subscriptions/"
                                }
                              >
                                Select{" "}
                                <img
                                  src={
                                    require("../../assets/images/dashboard/Goto.png")
                                      .default
                                  }
                                  alt="Goto"
                                />
                              </a>
                            ) : 
                            (
                              <Link
                                to="#"
                                onClick={(e) => this.removepopup()}
                              >
                                Select
                              </Link>
                            )
                        }
                        
                        </div>
                        <div className="pricedowngrade"></div>
                        
                        <div className="mobi-tab">
                            <input type="checkbox" id="chck21" />
                            <label className="mobi-tab-label" for="chck21">View Features</label>
                        <div className="mobi-tab-content">
                        <div className="price-second-half">
                        
                        <div class="featurebox wordpress-featurebox">
                                
                                <div class="pricewithfeature">
                                <div class="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No website required</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>In-app browser</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Customizable design</p>
                                    </div>    
                                </div>
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Push notifications</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Monetization features</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Team collaboration</p>
                                    </div>    
                                </div>    
                                
                            </div>
                        
                        <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="pricebox">
                        
                        <div className="price-first-half">
                        
                        <div className="borderyellow"></div>
                        <div className="allflex">
                            
                            <h2>Starter</h2>
                            
                            <div className="platforms">
                            <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                            
                            <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                        </div>
                            
                        </div>  
                        
                        {this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0] !== null ?
                     <>
                        {this.state.customer_billing_type !== 3 ?
                           <h4>
                           {
                             this.state.newlifetimedata[0].plan == 'starter' ?
                             this.state.newlifetimedata[0].package_currency.currency_symbol +
                             this.state.newlifetimedata[0].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[1].plan == 'starter' ?
                             this.state.newlifetimedata[1].package_currency.currency_symbol +
                             this.state.newlifetimedata[1].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[2].plan == 'starter' ?
                             this.state.newlifetimedata[2].package_currency.currency_symbol +
                             this.state.newlifetimedata[2].amount.replace(".00", " ")
                            :
                             '$ 79'
                           }
                           </h4>
                         :
                         <>
                         {
                           this.state.newlifetimedata[0].plan == 'starter' ?
                           ReactHtmlParser(this.state.newlifetimedata[0].paddleprice)
                          :
                           this.state.newlifetimedata[1].plan == 'starter' ?
                           ReactHtmlParser(this.state.newlifetimedata[1].paddleprice)
                          :
                           this.state.newlifetimedata[2].plan == 'starter' ?
                           ReactHtmlParser(this.state.newlifetimedata[2].paddleprice)
                          :
                           '$ 499'
                         }
                         </>
                        }
                        <h5>one time payment</h5>
                        {
                        (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'starter' && this.state.newlifetimedata[0].is_subscribed === 1) ||
                        (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'starter' && this.state.newlifetimedata[1].is_subscribed === 1) ||
                        (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'starter' && this.state.newlifetimedata[2].is_subscribed === 1) 
                        ? 
                            (
                                <Link to="#" className="currentplan">
                                    Current
                                </Link>
                            )
                        :   (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'starter' && this.state.newlifetimedata[0].show_plan === 0) ||
                            (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'starter' && this.state.newlifetimedata[1].show_plan === 0) ||
                            (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'starter' && this.state.newlifetimedata[2].show_plan === 0) 
                        ? 
                            (
                                <Link to="#" className="lowerplan">
                                        Unavailable
                                </Link>
                            )
                        :   (
                            <Link to=
                                 {
                                     this.state.customer_type === 4
                                     ? "#"
                                     : this.state.newlifetimedata[0].plan == 'starter' ?
                                       this.dlink(
                                         this.state.newlifetimedata[0].plan_code,
                                         parseInt(this.state.newlifetimedata[0].amount),
                                         this.state.newlifetimedata[0].plan_name,
                                         this.state.newlifetimedata[0].package_currency.currency_symbol,
                                         this.state.newlifetimedata[0].plan_code
                                       )
                                     : this.state.newlifetimedata[1].plan == 'starter' ?
                                       this.dlink(
                                         this.state.newlifetimedata[1].plan_code,
                                         parseInt(this.state.newlifetimedata[1].amount),
                                         this.state.newlifetimedata[1].plan_name,
                                         this.state.newlifetimedata[1].package_currency.currency_symbol,
                                         this.state.newlifetimedata[1].plan_code
                                       )
                                     : this.state.newlifetimedata[2].plan == 'starter' ?
                                       this.dlink(
                                         this.state.newlifetimedata[2].plan_code,
                                         parseInt(this.state.newlifetimedata[2].amount),
                                         this.state.newlifetimedata[2].plan_name,
                                         this.state.newlifetimedata[2].package_currency.currency_symbol,
                                         this.state.newlifetimedata[2].plan_code
                                       )
                                     :
                                      this.dlink(
                                         this.state.newlifetimedata[0].plan_code,
                                         parseInt(this.state.newlifetimedata[0].amount),
                                         this.state.newlifetimedata[0].plan_name,
                                         this.state.newlifetimedata[0].package_currency.currency_symbol,
                                         this.state.newlifetimedata[0].plan_code
                                       )
                                 }
                                 onClick={ 
                                    this.state.newlifetimedata[0].plan == 'starter' ?
                                    (e) => this.handleWooCustomer(e,
                                      this.state.customer_type,
                                      this.state.newlifetimedata[0].plan_code,
                                      0)
                                    : this.state.newlifetimedata[1].plan == 'starter' ?
                                      (e) => this.handleWooCustomer(e,
                                      this.state.customer_type,
                                      this.state.newlifetimedata[1].plan_code,
                                      0)
                                    : this.state.newlifetimedata[2].plan == 'starter' ?
                                      (e) => this.handleWooCustomer(e,
                                      this.state.customer_type,
                                      this.state.newlifetimedata[2].plan_code,
                                      0)
                                    :
                                    (e) => this.handleWooCustomer(e,
                                    this.state.customer_type,
                                    this.state.newlifetimedata[0].plan_code,
                                    0)
                                   }

                             >
                             Select
                         </Link>
                        )}
                     </>
                    : null
                    }
                        
                        </div>
                        <div className="pricedowngrade"></div>
                            
                        <div className="mobi-tab">
                            <input type="checkbox" id="chck22" />
                            <label className="mobi-tab-label" for="chck22">View Features</label>
                        <div className="mobi-tab-content">
                        <div className="price-second-half">
                        
                        <div class="featurebox wordpress-featurebox">
                                
                                <div class="pricewithfeature">
                                <div class="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No website required</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>In-app browser</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Customizable design</p>
                                    </div>    
                                </div>
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                    <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Push notifications</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Monetization features</p>
                                    </div>    
                                </div>
                                    
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Team collaboration</p>
                                    </div>    
                                </div>   

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div> 
                                
                            </div>    
                        
                        <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        </div> 
                            </div>
                        </div>
                            
                    </div>
                    
                    <div className="pricebox">
                        
                        <div className="price-first-half">
                        
                        <div className="borderorange"></div>
                        
                        <div className="allflex">
                            
                            <h2>Pro</h2>
                            
                            <div className="platforms">
                            <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                            
                            <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            
                            <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                        </div>
                            
                        </div>  
                        
                        {this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0] !== null ?
                     <>
                       {this.state.customer_billing_type !== 3 ?
                           <h4>
                           {
                             this.state.newlifetimedata[0].plan == 'pro' ?
                             this.state.newlifetimedata[0].package_currency.currency_symbol +
                             this.state.newlifetimedata[0].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[1].plan == 'pro' ?
                             this.state.newlifetimedata[1].package_currency.currency_symbol +
                             this.state.newlifetimedata[1].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[2].plan == 'pro' ?
                             this.state.newlifetimedata[2].package_currency.currency_symbol +
                             this.state.newlifetimedata[2].amount.replace(".00", " ")
                            :
                             '$ 79'
                           }
                           </h4>
                         :
                         <>
                         {
                           this.state.newlifetimedata[0].plan == 'pro' ?
                           ReactHtmlParser(this.state.newlifetimedata[0].paddleprice)
                          :
                           this.state.newlifetimedata[1].plan == 'pro' ?
                           ReactHtmlParser(this.state.newlifetimedata[1].paddleprice)
                          :
                           this.state.newlifetimedata[2].plan == 'pro' ?
                           ReactHtmlParser(this.state.newlifetimedata[2].paddleprice)
                          :
                           '$ 499'
                         }
                         </>
                        }
                        <h5>one time payment</h5>
                        {
                        (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'pro' && this.state.newlifetimedata[0].is_subscribed === 1) ||
                        (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'pro' && this.state.newlifetimedata[1].is_subscribed === 1) ||
                        (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'pro' && this.state.newlifetimedata[2].is_subscribed === 1) 
                        ? 
                            (
                                <Link to="#" className="currentplan">
                                    Current
                                </Link>
                            )
                        :   (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'pro' && this.state.newlifetimedata[0].show_plan === 0) ||
                            (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'pro' && this.state.newlifetimedata[1].show_plan === 0) ||
                            (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'pro' && this.state.newlifetimedata[2].show_plan === 0) 
                        ? 
                            (
                                <Link to="#" className="lowerplan">
                                    Unavailable
                                </Link>
                            ) 
                            :   (
                                <Link to=
                                     {
                                         this.state.customer_type === 4
                                         ? "#"
                                         : this.state.newlifetimedata[0].plan == 'pro' ?
                                           this.dlink(
                                             this.state.newlifetimedata[0].plan_code,
                                             parseInt(this.state.newlifetimedata[0].amount),
                                             this.state.newlifetimedata[0].plan_name,
                                             this.state.newlifetimedata[0].package_currency.currency_symbol,
                                             this.state.newlifetimedata[0].plan_code
                                           )
                                         : this.state.newlifetimedata[1].plan == 'pro' ?
                                           this.dlink(
                                             this.state.newlifetimedata[1].plan_code,
                                             parseInt(this.state.newlifetimedata[1].amount),
                                             this.state.newlifetimedata[1].plan_name,
                                             this.state.newlifetimedata[1].package_currency.currency_symbol,
                                             this.state.newlifetimedata[1].plan_code
                                           )
                                         : this.state.newlifetimedata[2].plan == 'pro' ?
                                           this.dlink(
                                             this.state.newlifetimedata[2].plan_code,
                                             parseInt(this.state.newlifetimedata[2].amount),
                                             this.state.newlifetimedata[2].plan_name,
                                             this.state.newlifetimedata[2].package_currency.currency_symbol,
                                             this.state.newlifetimedata[2].plan_code
                                           )
                                         :
                                          this.dlink(
                                             this.state.newlifetimedata[1].plan_code,
                                             parseInt(this.state.newlifetimedata[1].amount),
                                             this.state.newlifetimedata[1].plan_name,
                                             this.state.newlifetimedata[1].package_currency.currency_symbol,
                                             this.state.newlifetimedata[1].plan_code
                                           )
                                     }
                                     onClick={ 
                                        this.state.newlifetimedata[0].plan == 'pro' ?
                                        (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newlifetimedata[0].plan_code,
                                          1)
                                        : this.state.newlifetimedata[1].plan == 'pro' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newlifetimedata[1].plan_code,
                                          1)
                                        : this.state.newlifetimedata[2].plan == 'pro' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newlifetimedata[2].plan_code,
                                          1)
                                        :
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newlifetimedata[1].plan_code,
                                        1)
                                       }


                                 >
                                 Select
                             </Link>
                            )}
                     </>
                    : null
                    }
              
                        </div>   
                        <div className="pricedowngrade"></div>   
                            
                        <div className="mobi-tab">
                            <input type="checkbox" id="chck23" />
                            <label className="mobi-tab-label" for="chck23">View Features</label>
                        <div className="mobi-tab-content">
                        <div className="price-second-half">
                        
                        <div class="featurebox wordpress-featurebox">
                                
                                
                                <div class="pricewithfeature">
                                <div class="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No website required</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Customizable design</p>
                                    </div>    
                                </div>
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                            </div>    
                        
                        <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        </div>
                            </div>
                        </div>
                            
                    </div>
                    
                    <div className="pricebox">
                        
                        <div className="price-first-half">
                        
                        <div className="bordergreen"></div>
                        
                        <div className="allflex">
                            
                            <h2>Premium</h2>
                            
                            <div className="platforms">
                            <img src={ require("../../assets/images/pricing/mobilewhite.png") .default }alt="Mobile icon"/>
                            
                            <img src={ require("../../assets/images/pricing/androidwhite.png") .default }alt="Android White Icon"/>
                            
                            <img src={ require("../../assets/images/pricing/ioswhite.png") .default }alt="iOS grey icon"/>
                        </div>
                            
                        </div> 
                        
                        {this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0] !== null ?
                     <>
                        
                        {this.state.customer_billing_type !== 3 ?
                           <h4>
                           {
                             this.state.newlifetimedata[0].plan == 'premium' ?
                             this.state.newlifetimedata[0].package_currency.currency_symbol +
                             this.state.newlifetimedata[0].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[1].plan == 'premium' ?
                             this.state.newlifetimedata[1].package_currency.currency_symbol +
                             this.state.newlifetimedata[1].amount.replace(".00", " ")
                            :
                             this.state.newlifetimedata[2].plan == 'premium' ?
                             this.state.newlifetimedata[2].package_currency.currency_symbol +
                             this.state.newlifetimedata[2].amount.replace(".00", " ")
                            :
                             '$ 79'
                           }
                           </h4>
                         :
                         <>
                         {
                           this.state.newlifetimedata[0].plan == 'premium' ?
                           ReactHtmlParser(this.state.newlifetimedata[0].paddleprice)
                          :
                           this.state.newlifetimedata[1].plan == 'premium' ?
                           ReactHtmlParser(this.state.newlifetimedata[1].paddleprice)
                          :
                           this.state.newlifetimedata[2].plan == 'premium' ?
                           ReactHtmlParser(this.state.newlifetimedata[2].paddleprice)
                          :
                           '$ 499'
                         }
                         </>
                        }
                        <h5>one time payment</h5>
                    
                        {
                         (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'premium' && this.state.newlifetimedata[0].is_subscribed === 1) ||
                         (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'premium' && this.state.newlifetimedata[1].is_subscribed === 1) ||
                         (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'premium' && this.state.newlifetimedata[2].is_subscribed === 1) 
                         ? 
                             (
                                 <Link to="#" className="currentplan">
                                     Current
                                 </Link>
                             ) 
                         :   (this.state.newlifetimedata[0] !== undefined && this.state.newlifetimedata[0].plan == 'premium' && this.state.newlifetimedata[0].show_plan === 0) ||
                             (this.state.newlifetimedata[1] !== undefined && this.state.newlifetimedata[1].plan == 'premium' && this.state.newlifetimedata[1].show_plan === 0) ||
                             (this.state.newlifetimedata[2] !== undefined && this.state.newlifetimedata[2].plan == 'premium' && this.state.newlifetimedata[2].show_plan === 0) 
                         ? 
                             (
                                 <Link to="#" className="lowerplan">
                                     Unavailable
                                 </Link>
                             )  
                            :   (
                                <Link to=
                                     {
                                         this.state.customer_type === 4
                                         ? "#"
                                         : this.state.newlifetimedata[0].plan == 'premium' ?
                                           this.dlink(
                                             this.state.newlifetimedata[0].plan_code,
                                             parseInt(this.state.newlifetimedata[0].amount),
                                             this.state.newlifetimedata[0].plan_name,
                                             this.state.newlifetimedata[0].package_currency.currency_symbol,
                                             this.state.newlifetimedata[0].plan_code
                                           )
                                         : this.state.newlifetimedata[1].plan == 'premium' ?
                                           this.dlink(
                                             this.state.newlifetimedata[1].plan_code,
                                             parseInt(this.state.newlifetimedata[1].amount),
                                             this.state.newlifetimedata[1].plan_name,
                                             this.state.newlifetimedata[1].package_currency.currency_symbol,
                                             this.state.newlifetimedata[1].plan_code
                                           )
                                         : this.state.newlifetimedata[2].plan == 'premium' ?
                                           this.dlink(
                                             this.state.newlifetimedata[2].plan_code,
                                             parseInt(this.state.newlifetimedata[2].amount),
                                             this.state.newlifetimedata[2].plan_name,
                                             this.state.newlifetimedata[2].package_currency.currency_symbol,
                                             this.state.newlifetimedata[2].plan_code
                                           )
                                         :
                                          this.dlink(
                                             this.state.newlifetimedata[2].plan_code,
                                             parseInt(this.state.newlifetimedata[2].amount),
                                             this.state.newlifetimedata[2].plan_name,
                                             this.state.newlifetimedata[2].package_currency.currency_symbol,
                                             this.state.newlifetimedata[2].plan_code
                                           )
                                     }
                                     onClick={ 
                                        this.state.newlifetimedata[0].plan == 'premium' ?
                                        (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newlifetimedata[0].plan_code,
                                          2)
                                        : this.state.newlifetimedata[1].plan == 'premium' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newlifetimedata[1].plan_code,
                                          2)
                                        : this.state.newlifetimedata[2].plan == 'premium' ?
                                          (e) => this.handleWooCustomer(e,
                                          this.state.customer_type,
                                          this.state.newlifetimedata[2].plan_code,
                                          2)
                                        :
                                        (e) => this.handleWooCustomer(e,
                                        this.state.customer_type,
                                        this.state.newlifetimedata[2].plan_code,
                                        2)
                                       }

                                 >
                                 Select
                             </Link>
                            )}
                     </>
                    : null
                    }
                        
                        </div>    
                        <div className="pricedowngrade"></div> 
                        <div className="mobi-tab">
                            <input type="checkbox" id="chck24" />
                            <label className="mobi-tab-label" for="chck24">View Features</label>
                        <div className="mobi-tab-content">
                        <div className="price-second-half">
                        
                        <div class="featurebox wordpress-featurebox">
                                
                                <div class="pricewithfeature">
                                <div class="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Unlimited Android & iOS apps</p>
                                </div>    
                            </div>
                                
                            <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>No website required</p>
                                </div>    
                            </div>
                            
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>In-app browser</p>
                                </div>    
                            </div>
                            
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Customizable design</p>
                                </div>    
                            </div>
                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Real device testing</p>
                                    </div>    
                                </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Push notifications</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Monetization features</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Team collaboration</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>User consent</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Merge apps</p>
                                </div>    
                            </div>
                                
                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Language selection</p>
                                </div>    
                            </div>

                            <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>App analytics</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>Easy publish</p>
                                    </div>    
                                </div>

                                <div className="pricewithfeature">
                                <div className="pricelist">
                                <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                  <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                </svg></span>
                                <p>Accelerated app builds
</p>
                                </div>    
                            </div> 

<div className="pricewithfeature">
                                    <div className="pricelist">
                                    <span><svg width="11.424" height="8.789" viewBox="0 0 11.424 8.789">
                                    <path id="Path_9482" data-name="Path 9482" d="M3723-16485.164h0l3.342,3.342,6.668-6.668" transform="translate(-3722.293 16489.197)" fill="none" stroke="#B8BFD0" stroke-width="2"/>
                                    </svg></span>
                                    <p>No AppMySite branding</p>
                                    </div>    
                                </div>
                                
                                
                            </div>    
                        
                        <a className="webshow compareplan" href="#seecomparison">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                        
                        <a className="mobileshow compareplan" href="#seecomparisonmobile">Compare plans <img src={ require("../../assets/images/pricing/blue-arrow.png") .default }alt="Mobile icon"/></a>
                            
                        </div>
                            </div>
                        </div>
                        
                    </div>
                    
                </div>

            </div>   
                   
            </div>
            : null
            }
            
            <div className="enterprise-solution">

                <div>
                    <h3>Need help choosing the right plan for you?</h3>
                    <p>Feel free to reach out to us with any questions about our plans.</p>
                </div>
                <a href="/get-in-touch/">Get in touch</a>
            </div>
            
            <div className="enterprise-solution-mobile">

                <div>
                    <img src={ require("../../assets/images/pricing/enterprise.png") .default }alt="enterprise"/>   
                    <h3>Need help choosing the right plan for you?</h3>
                </div>
                <p>Feel free to reach out to us with any questions about our plans.</p>
                <a href="/get-in-touch/">Get in touch</a>
            </div>
            
            <div className="agencylink">
                <a className="webshow" href="#seecomparison">See full comparison <img src={ require("../../assets/images/pricing/more-arrow.png") .default }alt="arrow"/> </a>
                <a className="mobileshow" href="#seecomparisonmobile">See full comparison <img src={ require("../../assets/images/pricing/more-arrow.png") .default }alt="arrow"/></a>
            </div>    
        </div> 
          </section>

          <section className="paddingtop100 paddingbottom120 webcomparison" id="seecomparison">
        
        <div className="container">
            <h3 className="sectionheadings text-center">Compare Plans</h3>
            <h6 className="subheadings1 subheadingtop text-center">Compare our offering, plans and pricing. Pick one that suits you the best.</h6>
            
            <div className="all-in-one-filter">

            <div className={this.state.selectiontype !== '' && this.state.selectiontype === 4 ? "all-active" : ''}  onClick={() => {this.handletypetab(4)}}>
                {this.state.selectiontype !== '' && this.state.selectiontype === 4 ?
                  <>
                    <img src={ require("../../assets/images/pricing/all-in-one-wordpress.png") .default }alt="wordpress" className="webshow"/>
                    <img src={ require("../../assets/images/pricing/pricing-type-2.png") .default }alt="wordpress" className="mobileshow"/>
                  </>
                :
                  <>
                    <img src={ require("../../assets/images/pricing/all-in-one-grey.png") .default } onClick={() => {this.handletypetab(4)}} alt="wordpress" className="webshow"/>
                    <img src={ require("../../assets/images/pricing/pricing-type-2-grey.png") .default } onClick={() => {this.handletypetab(4)}} alt="wordpress" className="mobileshow"/>
                  </>
                }
                <p>WordPress</p>
                </div>

                <div className={this.state.selectiontype !== '' && this.state.selectiontype === 5 ? "all-active" : ''}  onClick={() => {this.handletypetab(5)}}>
                {this.state.selectiontype !== '' && this.state.selectiontype === 5 ?
                  <>
                    <img src={ require("../../assets/images/pricing/all-in-one-woocommerce.png") .default }alt="woocommerce" className="webshow"/>
                    <img src={ require("../../assets/images/pricing/pricing-type-3.png") .default }alt="woocommerce" className="mobileshow"/>
                    </>
                :
                  <>
                    <img src={ require("../../assets/images/pricing/all-in-one-woocommerce-grey.png") .default } onClick={() => {this.handletypetab(5)}} alt="woocommerce" className="webshow"/>
                    <img src={ require("../../assets/images/pricing/pricing-type-3-grey.png") .default } onClick={() => {this.handletypetab(5)}} alt="woocommerce" className="mobileshow"/>
                  </>
                }
                    <p>WooCommerce</p>
                </div>

                <div  className={this.state.selectiontype !== '' && this.state.selectiontype === 2 ?"all-active" : ''}  onClick={() => {this.handletypetab(2)}}>
                {this.state.selectiontype !== '' && this.state.selectiontype === 2 ?
                  <>
                    <img src={ require("../../assets/images/pricing/all-in-one-webtoapp.png") .default } alt="webtoapp" className="webshow"/>
                    <img src={ require("../../assets/images/pricing/pricing-type-1.png") .default } onClick={() => {this.handletypetab(2)}} alt="webtoapp" className="mobileshow"/>
                  </>
                :
                  <>
                    <img src={ require("../../assets/images/pricing/all-in-one-webtoapp-grey.png") .default }  onClick={() => {this.handletypetab(2)}} alt="webtoapp" className="webshow"/>
                    <img src={ require("../../assets/images/pricing/pricing-type-1-grey.png") .default }  onClick={() => {this.handletypetab(2)}} alt="webtoapp" className="mobileshow"/>
                  </>
                }
                    <p>Web To App</p>
                </div>

                <div className={this.state.selectiontype !== '' && this.state.selectiontype === 3 ?"all-active" : ''}  onClick={() => {this.handletypetab(3)}}>
                   
                {this.state.selectiontype !== '' && this.state.selectiontype === 3 ?
                  <>
                    <img src={ require("../../assets/images/pricing/cmscolor.png") .default } alt="cms" className="webshow"/>
                    <img src={ require("../../assets/images/pricing/pricing-type-4.png") .default } onClick={() => {this.handletypetab(3)}} alt="cms" className="mobileshow"/>
                  </>
                :
                  <>
                    <img src={ require("../../assets/images/pricing/all-in-one-cms-unavailable.png") .default } onClick={() => {this.handletypetab(3)}} alt="greycms" className="webshow"/>
                    <img src={ require("../../assets/images/pricing/pricing-type-4-grey.png") .default }  onClick={() => {this.handletypetab(3)}} alt="greycms" className="mobileshow"/>
                  </>
                }
                    <p>Custom</p>
                </div>

                <div className="not-all-active">
                    <img src={ require("../../assets/images/pricing/all-in-one-shopify-unavailable.png") .default }alt="shopify" className="webshow"/>

                    <img src={ require("../../assets/images/pricing/pricing-type-5-grey.png") .default }alt="shopify" className="mobileshow"/>
                    <p>Shopify</p>
                </div>

            </div>
            {this.state.selectiontype !== '' && this.state.selectiontype === 4 ?
            <div className="plandetail">
                
            <div className="allflex">
                <div className="pricemajor">

                </div>

                <div className="priceminor">
                    <h5>Free</h5>
                </div>

                <div className="priceminor">
                    <h5>Starter</h5>
                </div>

                <div className="priceminor">
                    <h5>Pro</h5>
                </div>

                <div className="priceminor">
                    <h5>Premium</h5>
                </div>
            </div>
            
            
            <div className="pricesechead">
                <h6>Supported devices</h6>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Android</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/> 
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>iOS</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            
            <div className="pricesechead">
                <h6>App stores</h6>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Google Play</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Apple App Store</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>




            <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>



            <div className="pricesechead">
                <h6>Capacities</h6>
            </div>
            

            <div className="allflex">
                <div className="pricemajor">
                    <p>Unlimited app downloads</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>

            <div className="allflex">
                <div className="pricemajor">
                    <p>Unlimited app users</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>

            <div className="allflex">
                <div className="pricemajor">
                    <p>Unlimited push notifications</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>

            <div className="allflex">
                <div className="pricemajor">
                    <p>Unlimited app page views</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>

            <div className="allflex">
                <div className="pricemajor">
                    <p>Unlimited pages</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>

            <div className="allflex">
                <div className="pricemajor">
                    <p>Unlimited posts</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>

            <div className="allflex">
                <div className="pricemajor">
                    <p>Unlimited data sync</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>

            <div className="allflex">
                <div className="pricemajor">
                    <p>Unlimited storage</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            
            
            
            <div className="pricesechead">
                <h6>WordPress sync</h6>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Pages</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Posts</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Categories</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Tags</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Users</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Media</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Comments</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Custom post types</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Custom taxonomies</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Multisite networks </p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            
            
            <div className="pricesechead">
                <h6>In-app browser</h6>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Any domain</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Any web page</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Media support</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Session management</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
    
    <div className="allflex">
                <div className="pricemajor">
                    <p>Cookie management</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Custom CSS</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Auto login</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            
            
            <div className="pricesechead">
                <h6>User access</h6>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Access management</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>WordPress user sync</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Guest browsing</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
    
    <div className="allflex">
                <div className="pricemajor">
                    <p>Login with email</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Login with Google</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
    
    <div className="allflex">
                <div className="pricemajor">
                    <p>Login with Facebook</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Login with Apple</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            
            
            <div className="pricesechead">
                <h6>Teams</h6>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Roles and permissions</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Team collaboration</p>
                </div>

                <div className="priceminor">
                    <p>2 users</p>
                </div>

                <div className="priceminor">
                    <p>2 users</p>
                </div>

                <div className="priceminor">
                    <p>3 users</p>
                </div>

                <div className="priceminor">
                    <p>5 users</p>
                </div>
            </div>
            
            
                            
            <div className="pricesechead">
                <h6>Personalization</h6>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Design templates</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
    
    <div className="allflex">
                <div className="pricemajor">
                    <p>App Icon designer</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Splash screen designer</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Login screen designer</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>
            
            <div className="allflex">
                <div className="pricemajor">
                    <p>Home screen builder</p>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>

                <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                </div>
            </div>                
        
        <div className="allflex">
            <div className="pricemajor">
                <p>No AppMySite branding</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>User consent designer</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        
        
        
        <div className="pricesechead">
            <h6>Notifications</h6>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>Unlimited notifications</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>Notification scheduling</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>Device targeting</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>Firebase reporting</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>Rich push notifications</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
            <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>Audience segmentation</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
            <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>User analytics</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
            <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>Advanced analytics</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
            <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>




        <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>



        
        
        
        <div className="pricesechead">
            <h6>Advanced features</h6>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>App simulator</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>Merge apps</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>Language selection</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>Right-to-left support</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>Customer chat</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        




        <div className="pricesechead">
            <h6>Analytics</h6>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>App downloads</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>

        <div className="allflex">
            <div className="pricemajor">
                <p>User analytics</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>

        <div className="allflex">
            <div className="pricemajor">
                <p>Device analytics</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>

        <div className="allflex">
            <div className="pricemajor">
                <p>Content analytics</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        
        
        
        
        <div className="pricesechead">
            <h6>Easy publish</h6>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>Easy publish to Apple Store</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>Easy publish to Google Play</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
            </div>

            <div className="priceminor">
                <p>Coming soon</p>
            </div>
        </div>
        
        
        <div className="pricesechead">
            <h6>Support</h6>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>Community</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>
        
        <div className="allflex">
            <div className="pricemajor">
                <p>Knowledge base</p>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>

            <div className="priceminor">
                <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
            </div>
        </div>

        
  
        
    </div>
        : this.state.selectiontype !== '' && this.state.selectiontype === 5 ?
        <div className="plandetail">
                
                <div className="allflex">
                    <div className="pricemajor">

                    </div>

                    <div className="priceminor">
                        <h5>Free</h5>
                    </div>

                    <div className="priceminor">
                        <h5>Starter</h5>
                    </div>

                    <div className="priceminor">
                        <h5>Pro</h5>
                    </div>

                    <div className="priceminor">
                        <h5>Premium</h5>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>



                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>


                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited products</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited payments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                
                
                
                <div className="pricesechead">
                    <h6>WooCommerce sync</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Products</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Inventory</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Currency</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Shipping</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tax</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Coupons</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Payment gateways</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Orders</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ratings</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Reviews</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>WordPress sync</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Comments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom post types</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom taxonomies</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Multisite networks </p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>User access</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Access management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>WordPress user sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Guest browsing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with email</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Google</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Facebook</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Apple</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                    <div className="priceminor">
                        <p>3 users</p>
                    </div>

                    <div className="priceminor">
                        <p>5 users</p>
                    </div>
                </div>
                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
           
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>




                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>





                
                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <p>Coming soon</p>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                

                
                
                
            </div>
        : this.state.selectiontype !== '' && this.state.selectiontype === 2 ?
        <div className="plandetail">
                
                <div className="allflex">
                    <div className="pricemajor">

                    </div>

                    <div className="priceminor">
                        <h5>Free</h5>
                    </div>

                    <div className="priceminor">
                        <h5>Starter</h5>
                    </div>

                    <div className="priceminor">
                        <h5>Pro</h5>
                    </div>

                    <div className="priceminor">
                        <h5>Premium</h5>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>




                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>





                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited payments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>


                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                    <div className="priceminor">
                        <p>3 users</p>
                    </div>

                    <div className="priceminor">
                        <p>5 users</p>
                    </div>
                </div>
                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>



                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>



                
                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>



                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <p>Coming soon</p>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                
                
                
            </div>
        : this.state.selectiontype !== '' && this.state.selectiontype === 3 ?
        <div className="plandetail">
                
                <div className="allflex">
                    <div className="pricemajor">

                    </div>

                    <div className="priceminor">
                        <h5>Free</h5>
                    </div>

                    <div className="priceminor">
                        <h5>Starter</h5>
                    </div>

                    <div className="priceminor">
                        <h5>Pro</h5>
                    </div>

                    <div className="priceminor">
                        <h5>Premium</h5>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>



                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>




                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>





                <div className="pricesechead">
                    <h6>Custom content</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Attributes</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                    <div className="priceminor">
                        <p>3 users</p>
                    </div>

                    <div className="priceminor">
                        <p>5 users</p>
                    </div>
                </div>
                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                


                

                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>





                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
               
                
                
                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                    <div className="priceminor">
                        <p>Coming soon</p>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                
                
            </div>
        : null
        }
            
        </div>
            </section>  
  
          <section className="mobilecomparison" id="seecomparisonmobile">

          <div className="all-in-one-filter">
        <div className="container">

        <div className={this.state.selectiontype !== '' && this.state.selectiontype === 4 ? "all-active" : ''}  onClick={() => {this.handletypetab(4)}}>
          {this.state.selectiontype !== '' && this.state.selectiontype === 4 ?
            <>
            <img src={ require("../../assets/images/pricing/all-in-one-wordpress.png") .default }alt="wordpress" className="webshow"/>
            <img src={ require("../../assets/images/pricing/pricing-type-2.png") .default }alt="wordpress" className="mobileshow"/>
            </>
          :
          <>
            <img src={ require("../../assets/images/pricing/all-in-one-grey.png") .default }alt="wordpress" className="webshow"/>
            <img src={ require("../../assets/images/pricing/pricing-type-2-grey.png") .default }alt="wordpress" className="mobileshow"/>
          </>
          }
            <p>WordPress</p>
          
        </div>

        <div className={this.state.selectiontype !== '' && this.state.selectiontype === 5 ? "all-active" : ''}  onClick={() => {this.handletypetab(5)}}>
        {this.state.selectiontype !== '' && this.state.selectiontype === 5 ?
            <>
            <img src={ require("../../assets/images/pricing/all-in-one-woocommerce.png") .default }alt="woocommerce" className="webshow"/>
            <img src={ require("../../assets/images/pricing/pricing-type-3.png") .default }alt="woocommerce" className="mobileshow"/>
            </>
          :
          <>
            <img src={ require("../../assets/images/pricing/all-in-one-woocommerce-grey.png") .default }alt="woocommerce" className="webshow"/>
            <img src={ require("../../assets/images/pricing/pricing-type-3-grey.png") .default }alt="woocommerce" className="mobileshow"/>
            </>
          }
            <p>WooCommerce</p>
        </div>

        <div className={this.state.selectiontype !== '' && this.state.selectiontype === 2 ? "all-active" : ''}  onClick={() => {this.handletypetab(2)}}>
        {this.state.selectiontype !== '' && this.state.selectiontype === 2 ?
            <>
            <img src={ require("../../assets/images/pricing/all-in-one-webtoapp.png") .default }alt="webtoapp" className="webshow"/>
            <img src={ require("../../assets/images/pricing/pricing-type-1.png") .default }alt="webtoapp" className="mobileshow"/>
            </>
          :
          <>
            <img src={ require("../../assets/images/pricing/all-in-one-webtoapp-grey.png") .default }alt="webtoapp" className="webshow"/>
            <img src={ require("../../assets/images/pricing/pricing-type-1-grey.png") .default }alt="webtoapp" className="mobileshow"/>
            </>
          }
            <p>Web To App</p>
        </div>

        <div className={this.state.selectiontype !== '' && this.state.selectiontype === 3 ?"all-active" : ''}  onClick={() => {this.handletypetab(3)}}>
                   
                   {this.state.selectiontype !== '' && this.state.selectiontype === 3 ?
                     <>
                       <img src={ require("../../assets/images/pricing/cmscolor.png") .default } alt="cms" className="webshow"/>
                       <img src={ require("../../assets/images/pricing/pricing-type-4.png") .default } onClick={() => {this.handletypetab(3)}} alt="cms" className="mobileshow"/>
                     </>
                   :
                     <>
                       <img src={ require("../../assets/images/pricing/all-in-one-cms-unavailable.png") .default } onClick={() => {this.handletypetab(3)}} alt="greycms" className="webshow"/>
                       <img src={ require("../../assets/images/pricing/pricing-type-4-grey.png") .default }  onClick={() => {this.handletypetab(2)}} alt="greycms" className="mobileshow"/>
                     </>
                   }
                       <p>Custom</p>
                   </div>

        <div className="not-all-active">
            <img src={ require("../../assets/images/pricing/all-in-one-shopify-unavailable.png") .default }alt="shopify" className="webshow"/>

            <img src={ require("../../assets/images/pricing/pricing-type-5-grey.png") .default }alt="shopify" className="mobileshow"/>
            <p>Shopify</p>
        </div>
      </div>

            </div>

            <div className="container">
            {this.state.selectiontype !== '' && this.state.selectiontype === 4 ?
           <div className="tabbed">
           <input type="radio" id="tab31" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('preview')}} defaultChecked={this.state.checkedmobtab == 'preview' ? true : '' }/>
           <input type="radio" id="tab32" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('starter')}} defaultChecked={this.state.checkedmobtab == 'starter' ? true : '' } />
           <input type="radio" id="tab33" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('pro')}} defaultChecked={this.state.checkedmobtab == 'pro' ? true : '' }/>
           <input type="radio" id="tab34" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('premium')}} defaultChecked={this.state.checkedmobtab == 'premium' ? true : '' } />
       
       <ul className="tabs">
           <li className="tab"><label for="tab31">Free</label></li>
           <li className="tab"><label for="tab32">Starter</label></li>
           <li className="tab"><label for="tab33">Pro</label></li>
           <li className="tab"><label for="tab34">Premium</label></li>
       </ul>
       
       <div className={"tab-content " + (this.state.checkedmobtab === 'preview' ? '' : 'd-none')}>
           
       <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                

                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>


                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>WordPress sync</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Comments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom post types</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom Taxonomies</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Multisite networks </p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>User access</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Access management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>WordPress user sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Guest browsing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with email</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Google</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Facebook</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Apple</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                </div>
                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>


                
                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                
                
                
                
            </div>
           
       </div>
       
       <div className={"tab-content " + (this.state.checkedmobtab === 'starter' ? '' : 'd-none')}>
           
       <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>




                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>




                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>WordPress sync</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Comments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom post types</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom Taxonomies</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Multisite networks </p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>User access</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Access management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>WordPress user sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Guest browsing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with email</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Google</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Facebook</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Apple</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                </div>
                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>                
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                
                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                

                
            </div>
           
       </div>
       
       <div className={"tab-content " + (this.state.checkedmobtab === 'pro' ? '' : 'd-none')}>
           
       <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>




                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>




                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>WordPress sync</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Comments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom post types</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom taxonomies</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Multisite networks </p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>User access</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Access management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>WordPress user sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Guest browsing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with email</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Google</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Facebook</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Apple</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>3 users</p>
                    </div>

                </div>
                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>




                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                
                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>


                
                
                
            </div>
           
       </div>
       
       <div className={"tab-content " + (this.state.checkedmobtab === 'premium' ? '' : 'd-none')}>
           
       <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>




                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>





                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                
                
                
                <div className="pricesechead">
                    <h6>WordPress sync</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Comments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom post types</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom taxonomies</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Multisite networks </p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>User access</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Access management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>WordPress user sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Guest browsing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with email</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Google</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Facebook</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Apple</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>5 users</p>
                    </div>
                </div>
                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                

                
                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>







                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <p>Coming soon</p>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                
                
                
            </div>
       </div>
       
   </div>
            : this.state.selectiontype !== '' && this.state.selectiontype === 5 ?

            <div className="tabbed">
            <input type="radio" id="tab31" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('preview')}} defaultChecked={this.state.checkedmobtab == 'preview' ? true : '' }/>
            <input type="radio" id="tab32" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('starter')}} defaultChecked={this.state.checkedmobtab == 'starter' ? true : '' } />
            <input type="radio" id="tab33" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('pro')}} defaultChecked={this.state.checkedmobtab == 'pro' ? true : '' }/>
            <input type="radio" id="tab34" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('premium')}} defaultChecked={this.state.checkedmobtab == 'premium' ? true : '' } />
        
        <ul className="tabs">
            <li className="tab"><label for="tab31">Free</label></li>
            <li className="tab"><label for="tab32">Starter</label></li>
            <li className="tab"><label for="tab33">Pro</label></li>
            <li className="tab"><label for="tab34">Premium</label></li>
        </ul>
        
        
        <div className={"tab-content " + (this.state.checkedmobtab === 'preview' ? '' : 'd-none')}>
            
        <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>




                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>




                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>    
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited products</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited payments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                
                
                
                <div className="pricesechead">
                    <h6>WooCommerce sync</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Products</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Inventory</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Currency</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Shipping</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tax</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Coupons</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Payment gateways</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Order Support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ratings</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Reviews</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>WordPress sync</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Comments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom post types</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom taxonomies</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Multisite networks </p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>User access</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Access management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>WordPress user sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Guest browsing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with email</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Google</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Facebook</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Apple</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                </div>
                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
               
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>




                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>




                
                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                
                
                
                
            </div>
            
        </div>
        
        <div className={"tab-content " + (this.state.checkedmobtab === 'starter' ? '' : 'd-none')}>
            
        <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited products</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited payments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>WooCommerce sync</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Products</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Inventory</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Currency</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Shipping</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tax</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Coupons</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Payment gateways</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Order Support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ratings</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Reviews</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>WordPress sync</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Comments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom post types</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom taxonomies</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Multisite networks </p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>User access</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Access management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>WordPress user sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Guest browsing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with email</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Google</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Facebook</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Apple</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                </div>
                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
               
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>




                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                
                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                
                
                
                
            </div>
            
        </div>
        
        <div className={"tab-content " + (this.state.checkedmobtab === 'pro' ? '' : 'd-none')}>
            
        <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited products</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited payments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>WooCommerce sync</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Products</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Inventory</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Currency</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Shipping</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tax</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Coupons</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Payment gateways</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Order Support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ratings</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Reviews</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>WordPress sync</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Comments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom post types</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom taxonomies</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Multisite networks </p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>User access</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Access management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>WordPress user sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Guest browsing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with email</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Google</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Facebook</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Apple</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>3 users</p>
                    </div>

                </div>
                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
               
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>




                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>




                

                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                
                
                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                

                
                
                
            </div>
            
        </div>
        
        <div className={"tab-content " + (this.state.checkedmobtab === 'premium' ? '' : 'd-none')}>
            
        <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>


                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                




                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited products</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited payments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>




                
                <div className="pricesechead">
                    <h6>WooCommerce sync</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Products</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Inventory</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Currency</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Shipping</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tax</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Coupons</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Payment gateways</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Order Support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ratings</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Reviews</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>WordPress sync</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Comments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom post types</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom taxonomies</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Multisite networks </p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>User access</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Access management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>WordPress user sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Guest browsing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with email</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Google</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Facebook</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login with Apple</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>5 users</p>
                    </div>
                </div>
                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Login screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
              
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>






                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>



                
                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App monetization</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <p>Coming soon</p>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                
                
                
                
                
            </div>
        </div>
        
        
    </div>
             : this.state.selectiontype !== '' && this.state.selectiontype === 2 ?
             <div className="tabbed">
             <input type="radio" id="tab31" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('preview')}} defaultChecked={this.state.checkedmobtab == 'preview' ? true : '' }/>
             <input type="radio" id="tab32" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('starter')}} defaultChecked={this.state.checkedmobtab == 'starter' ? true : '' } />
             <input type="radio" id="tab33" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('pro')}} defaultChecked={this.state.checkedmobtab == 'pro' ? true : '' }/>
             <input type="radio" id="tab34" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('premium')}} defaultChecked={this.state.checkedmobtab == 'premium' ? true : '' } />
         
         <ul className="tabs">
             <li className="tab"><label for="tab31">Free</label></li>
             <li className="tab"><label for="tab32">Starter</label></li>
             <li className="tab"><label for="tab33">Pro</label></li>
             <li className="tab"><label for="tab34">Premium</label></li>
         </ul>
         
         <div className={"tab-content " + (this.state.checkedmobtab === 'preview' ? '' : 'd-none')}>
             
         <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                

                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>


                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited payments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>


                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                </div>
                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                
                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
            </div>
             
         </div>
         
         <div className={"tab-content " + (this.state.checkedmobtab === 'starter' ? '' : 'd-none')}>
             
         <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>
            
                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>
            
                </div>




                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
            
            
            
                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                
            
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
            
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
            
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
            
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
            
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited payments</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
            
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
            
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                
            
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
            
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>
            
                    <div className="priceminor">
                        <p>2 users</p>
                    </div>
            
                </div>
                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
            
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>
            
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>
            
                </div>
            
            
            
                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>


                
                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>
            
                </div>
            
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>
            
                </div>
            
            
            
                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>
            
                </div>
            
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>
            
                </div>
            
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>
            
                </div>
            
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>
            
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>
            
                </div>
            
            
                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>
            
                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
            
                </div>
                
            </div>
             
         </div>
         
         <div className={"tab-content " + (this.state.checkedmobtab === 'pro' ? '' : 'd-none')}>
             
         <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited payments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>3 users</p>
                    </div>

                </div>
                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>


                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>


                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                
                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>


                
            </div>
             
         </div>
         
         <div className={"tab-content " + (this.state.checkedmobtab === 'premium' ? '' : 'd-none')}>
             
         <div className="plandetail">
                
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>




                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited payments</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>


                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>5 users</p>
                    </div>
                </div>
                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>




                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>



                
                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                

                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>



                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <p>Coming soon</p>
                    </div>
                </div>
                
                

                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                
            </div>
             
         </div>
         
     </div>
     : this.state.selectiontype !== '' && this.state.selectiontype === 3 ?
     <div className="tabbed">
     <input type="radio" id="tab31" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('preview')}} defaultChecked={this.state.checkedmobtab == 'preview' ? true : '' }/>
     <input type="radio" id="tab32" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('starter')}} defaultChecked={this.state.checkedmobtab == 'starter' ? true : '' } />
     <input type="radio" id="tab33" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('pro')}} defaultChecked={this.state.checkedmobtab == 'pro' ? true : '' }/>
     <input type="radio" id="tab34" name="css-tabs5" onChange={() => {this.handlecheckmoobtab('premium')}} defaultChecked={this.state.checkedmobtab == 'premium' ? true : '' } />
 
 <ul className="tabs">
     <li className="tab"><label for="tab31">Free</label></li>
     <li className="tab"><label for="tab32">Starter</label></li>
     <li className="tab"><label for="tab33">Pro</label></li>
     <li className="tab"><label for="tab34">Premium</label></li>
 </ul>
 
 <div className={"tab-content " + (this.state.checkedmobtab === 'preview' ? '' : 'd-none')}>
     
 <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>




                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>





                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>








                <div className="pricesechead">
                    <h6>Custom content</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Attributes</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                


                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                </div>
                



                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                

                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                
                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>





                 
                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

               

                
                
                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>





                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>


                
                
                
            </div>
     
 </div>
 
 <div className={"tab-content " + (this.state.checkedmobtab === 'starter' ? '' : 'd-none')}>
     
 <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>






                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>






                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>






                <div className="pricesechead">
                    <h6>Custom content</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Attributes</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>




                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>2 users</p>
                    </div>

                </div>




                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>






                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                

                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>





                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>





                
                
                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>


                
                
            </div>
     
 </div>
 
 <div className={"tab-content " + (this.state.checkedmobtab === 'pro' ? '' : 'd-none')}>
     
 <div className="plandetail">
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>


                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>




                <div className="pricesechead">
                    <h6>Custom content</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Attributes</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>


                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>



                
                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>3 users</p>
                    </div>

                </div>



                
                
                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>


                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>


                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>


                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                

                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>







                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>



                
                
                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricecross.png") .default }alt="cross"/>
                    </div>

                </div>





                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>


                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>


                
                
                
                
            </div>
     
 </div>
 
 <div className={"tab-content " + (this.state.checkedmobtab === 'premium' ? '' : 'd-none')}>
     
 <div className="plandetail">
                
                
                <div className="pricesechead">
                    <h6>Supported devices</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Android</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>iOS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                <div className="pricesechead">
                    <h6>App stores</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Google Play</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Apple App Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>





                <div className="pricesechead">
                    <h6>App builds</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app builds</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Instant build processing</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Accelerated build generation</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>



                <div className="pricesechead">
                    <h6>Capacities</h6>
                </div>
                

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app users</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited app page views</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited push notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited data sync</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited storage</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>





                <div className="pricesechead">
                    <h6>Custom content</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Pages</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Posts</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Categories</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Tags</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Attributes</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                
                
                <div className="pricesechead">
                    <h6>In-app browser</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any domain</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Any web page</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Media support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Session management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Cookie management</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Custom CSS</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Auto login</p>
                    </div>


                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                


                
                
                <div className="pricesechead">
                    <h6>Teams</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Roles and permissions</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Team collaboration</p>
                    </div>

                    <div className="priceminor">
                        <p>5 users</p>
                    </div>
                </div>
                
                



                                
                <div className="pricesechead">
                    <h6>Personalization</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Design templates</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App Icon designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Splash screen designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Home screen builder</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>No AppMySite branding</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User consent designer</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>



                
                
                
                <div className="pricesechead">
                    <h6>Notifications</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Unlimited notifications</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Notification scheduling</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device targeting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Firebase reporting</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Rich push notifications</p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Audience segmentation</p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Advanced analytics</p>
                    </div>

                    <div className="priceminor">
                    <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>



                

                <div className="pricesechead">
                    <h6>Monetization</h6>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Ad free</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app ads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>In-app purchases</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>




                
                
                <div className="pricesechead">
                    <h6>Advanced features</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App simulator</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Merge apps</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Language selection</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Right-to-left support</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>

                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Customer chat</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>





                <div className="pricesechead">
                    <h6>Analytics</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>App downloads</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>User analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Device analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                <div className="allflex">
                    <div className="pricemajor">
                        <p>Content analytics</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                
                


                
                <div className="pricesechead">
                    <h6>Easy publish</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Apple Store</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Easy publish to Google Play</p>
                    </div>

                    <div className="priceminor">
                        <p>Coming soon</p>
                    </div>
                </div>





                
                
                <div className="pricesechead">
                    <h6>Support</h6>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Community</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>
                
                <div className="allflex">
                    <div className="pricemajor">
                        <p>Knowledge base</p>
                    </div>

                    <div className="priceminor">
                        <img src={ require("../../assets/images/pricing/pricetick.png") .default }alt="tick"/>
                    </div>
                </div>

                
                
            </div>
     
 </div>
 
</div>
            : null
            }

            </div> 

          </section>

          <section className="blueback paddingtop100 paddingbottom100 pricingfaq">
          
          {this.state.selectiontype !== '' && this.state.selectiontype === 4 ?

          <div className="container">
          <h3 class="sectionheadings text-center">Frequently Asked Questions</h3>

          <div class="accordionWrapper">

            <div class="accordionItem close accordion-item" eventKey="0">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will AppMySite work with my WordPress website?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>AppMySite is deeply integrated with WordPress. Once your app is connected to your site, your pages, posts, categories, and menus will appear within the app automatically. Any changes made to your CMS or blog website will reflect instantly in your app.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="1">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will AppMySite be compatible with my website's plugins?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Although AppMySite supports a wide variety of third-party WordPress plugins, there are a reasonable number of plugins that will not work. It depends on how the plugin is coded. Plugins integrated with the official WordPress REST APIs are likely to work well with our app. However, if the plugin does not support the WordPress REST APIs, your plugin will struggle. Your best bet is to reach out to the author of the plugin in question for assistance. Alternatively, you could simply turn on our web view feature within the app settings, that will render your mobile site within the app.</p>

                <p>If your website’s plugins create custom post types and taxonomies, you can sync them to your app. AppMySite offers support for custom post types and taxonomies created via third-party plugins and themes.</p>
                  
                <p>It is important to note that some plugins have the potential to tamper with the core functionality of your WordPress website and as a result will disrupt the connectivity between your website and your app.</p> 
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="2">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Which WordPress themes does AppMySite support?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Since AppMySite enables you to create a native design aesthetic for your app, your WordPress theme does not affect it. However, if your theme creates certain custom functionality on the website, we cannot guarantee your app will support it.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="3">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Does AppMySite support WooCommerce?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>AppMySite offers deep integration with WooCommerce. Once you connect your WooCommerce store, your products and categories will be synced to the app automatically. Any change you make to your WooCommerce store will also be reflected in real-time.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="4">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Which payment gateways do apps built on AppMySite support?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>AppMySite supports all payment gateways active on your WooCommerce store. You’re not required to take any additional steps to integrate a payment gateway with your app. AppMySite automatically displays your website’s payment gateway during checkout.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="5">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Which shipping methods can I display on the app?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>AppMySite supports all shipping plugins you’re using on your WooCommerce store. Any shipping method you’ve configured for your website would be synced to the app automatically.</p>
                </div>
            </div>

            <div className="hiddenfaq" id="showfaq">

            <div class="accordionItem close accordion-item" eventKey="6">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will my website content be synced with the app automatically?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>AppMySite syncs your website and app in real-time, automatically. Any change you make to the website would be reflected to the app in real-time.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="7">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What will happen to my app in case my domain or website hosting expires?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>In case your website stops working for any reason (domain or hosting expiry), your app will stop working too. Your app’s content is sourced directly from your website database. If the app is not able to connect with your website at any point, it will stop working.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="8">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will my website performance be affected by AppMySite's plugin?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>AppMySite’s plugin has no impact on the speed and performance of your website. It simply connects your website and app and doesn’t affect your site’s speed in any way. All the data that appears in the app is directly sourced from your website's database. Our plugin does not add to the load time.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="9">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Does AppMySite support WordPress custom post types and taxonomies?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>If your website’s plugins create custom post types and taxonomies, you can sync them to your app. AppMySite offers support for custom post types and taxonomies created via third-party plugins and themes.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="10">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How can I get support for a plugin or theme that AppMySite is not compatible with?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>If you’re using a plugin or custom theme that AppMySite does not support, you can:</p>
                <ul>
                    <li><span>Try using webviews :</span> You could simply turn on our web views for posts, products, pages and custom post types from within the app settings, which will render your mobile site within the app.</li>
                    
                    <li><span>Contact the plugin author :</span> Reach out to the author of the plugin(s) in question and get them to add support for the official WordPress APIs.</li>
                    
                    <li><span>Ask the community :</span> The <a target="_blank" href="https://community.appmysite.com/">AppMySite Community</a> is a great place for our customers to share their wishlist. Feature requests that gain enough traction eventually make their way to our product roadmap.</li>
                </ul>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="11">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How can I avail the features outside the pricing plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>You can always upgrade to a higher pricing plan in order to avail features outside your subscription. Before choosing a plan, you can take a look at all the features included in every plan and take a call accordingly.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="12">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Are the prices including taxes?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>All AppMySite plans are inclusive of taxes. There are no hidden charges.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="13">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will my app continue working if I stop paying my monthly or annual subscription?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Your app will be downgraded to the free Preview plan when you stop paying your monthly or annual subscription. In this case, your app will stop working.</p>
                <p>If you don’t want to pay on a recurring basis, there is an option to subscribe to a lifetime subscription as well.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="14">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How many apps can I create in each plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Every <span>‘WordPress to app’</span> plan is available for a single mobile app. In case you wish to create multiple apps, you can purchase a separate plan for each app or choose to subscribe to a <span>‘Unlimited apps’</span> plan. These plans enable you to build unlimited apps on a single plan.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="15">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will AppMySite publish my app as well?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Once you finish building your app on AppMySite, you can download you app build(s) and publish to the app store(s). Furthermore, you can refer to our <a target="_blank" href="https://www.appmysite.com/support/">Help Center</a> for documentation and video tutorials regarding app submission to the app stores.</p>
                
                <p>If you require additional support, you can purchase the <a target="_blank" href="https://www.appmysite.com/upload-to-app-stores/">Upload to App</a> Stores add-on. With this add-on, you can allow AppMySite to submit your app to the app store(s). </p> 
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="16">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Does AppMySite offer custom app development services?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>We do not offer any form of custom development services. AppMySite is a DIY platform where users can sign up and create a mobile app on their own.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="17">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Do you have a refund policy?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Click <a target="_blank" href="https://www.appmysite.com/refund-policy/">here</a> to learn more about our refund policy.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="18">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Do these plans also include the cost of creating Apple and Google developer accounts?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Once you finish creating an app on AppMySite, you can submit your app to Google Play and Apple App Store via developer accounts.</p>
                  
                <p>You’re required to create an Apple and Google account on your own. Your AppMySite subscription plan doesn’t include the cost of creating Apple and Google developer accounts. Visit our <a target="_blank" href="https://www.appmysite.com/support/">Help Center</a> to learn how to create an Apple and Google developer account.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="19">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Can I change my subscription plan(s)?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>You can easily upgrade or downgrade your subscriptions at any time, on a prorated basis.</p>
                </div>
            </div>
            
            <div class="accordionItem close accordion-item" eventKey="20">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What is your cancellation policy?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>You can cancel your paid subscription and downgrade to the free plan at any time. Once canceled, you will not be charged for any subsequent billing cycles.</p>
                
                <p>Your app will stop working as soon as you downgrade back to a free plan.</p> 
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="21">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What do I get in the Preview plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>We’d like you to try before you buy, which is what you can do with the Preview plan. You can build as many free apps as you like and preview them from within your account before you upgrade and publish.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="22">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What is a distribution license?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Distribution licenses authorize you to download your app from AppMySite for submission to the app store(s).</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="23">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Do I need a developer account?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Depending on your plan, you’ll need developer accounts for Google Play and the Apple App Store in order to publish your apps.</p>
                  
                <p>Once you build your apps, you’ll be able to download your APK or AAB files (for your Android apps) and an IPA file (for your iOS apps) which then can be uploaded to your developer accounts.</p> 
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="24">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How much time does it take to publish an app?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Once an app is submitted to the relevant app store, it is subject to a review from their end. Both stores generally take 24-48 hours but could take longer depending on various factors. You can find more details on their official websites.</p>
                </div>
            </div>
            </div>

            <h5 id="seemorelink" onClick={this.seemorefunction} >See More</h5> 
                
            <h5 id="seelesslink" class="d-none" onClick={this.seelessfunction}>See Less</h5>

            </div>
            
                    <div className="haveidea allflex">
                        <h4 className="sectionheadings">Have other questions?</h4>
                        <a target="_blank" href="/get-in-touch/">Contact Us <svg width="12.894" height="11.061" viewBox="0 0 12.894 11.061">
                        <g id="Group_5530" data-name="Group 5530" transform="translate(12 0.53) rotate(90)">
                            <path id="Path_9581" data-name="Path 9581" d="M0,5,5,0l5,5" transform="translate(0 0.167)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                            <path id="Path_9586" data-name="Path 9586" d="M0,0V12" transform="translate(5)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                        </g>
                        </svg></a>
                    </div>
                </div>
          : this.state.selectiontype !== '' && this.state.selectiontype === 5 ?

                <div className="container">
          <h3 class="sectionheadings text-center">Frequently Asked Questions</h3>

          <div class="accordionWrapper">

            <div class="accordionItem close accordion-item" eventKey="0">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will AppMySite work with my WordPress website?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>AppMySite is deeply integrated with WordPress. Once your app is connected to your site, your pages, posts, categories, and menus will appear within the app automatically. Any changes made to your CMS or blog website will reflect instantly in your app.</p> 
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="1">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will AppMySite be compatible with my website's plugins?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Although AppMySite supports a wide variety of third-party WordPress plugins, there are a reasonable number of plugins that will not work. It depends on how the plugin is coded. Plugins integrated with the official WordPress REST APIs are likely to work well with our app. However, if the plugin does not support the WordPress REST APIs, your plugin will struggle. Your best bet is to reach out to the author of the plugin in question for assistance. Alternatively, you could simply turn on our web view feature within the app settings, that will render your mobile site within the app.</p> 
                  
                <p>If your website’s plugins create custom post types and taxonomies, you can sync them to your app. AppMySite offers support for custom post types and taxonomies created via third-party plugins and themes.</p>
                  
                <p>It is important to note that some plugins have the potential to tamper with the core functionality of your WordPress website and as a result will disrupt the connectivity between your website and your app.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="2">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Which WordPress themes does AppMySite support?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Since AppMySite enables you to create a native design aesthetic for your app, your WordPress theme does not affect it. However, if your theme creates certain custom functionality on the website, we cannot guarantee your app will support it.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="3">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Does AppMySite support WooCommerce?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>AppMySite offers deep integration with WooCommerce. Once you connect your WooCommerce store, your products and categories will be synced to the app automatically. Any change you make to your WooCommerce store will also be reflected in real-time.</p> 
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="4">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Which payment gateways do apps built on AppMySite support?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>AppMySite supports all payment gateways active on your WooCommerce store. You’re not required to take any additional steps to integrate a payment gateway with your app. AppMySite automatically displays your website’s payment gateway during checkout.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="5">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Which shipping methods can I display on the app?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>AppMySite supports all shipping plugins you’re using on your WooCommerce store. Any shipping method you’ve configured for your website would be synced to the app automatically.</p>
                </div>
            </div>

            <div className="hiddenfaq" id="showfaq">

            <div class="accordionItem close accordion-item" eventKey="6">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will my website content be synced with the app automatically?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>AppMySite syncs your website and app in real-time, automatically. Any change you make to the website would be reflected to the app in real-time.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="7">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What will happen to my app in case my domain or website hosting expires?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>In case your website stops working for any reason (domain or hosting expiry), your app will stop working too. Your app’s content is sourced directly from your website database. If the app is not able to connect with your website at any point, it will stop working.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="8">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will my website performance be affected by AppMySite's plugin?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>AppMySite’s plugin has no impact on the speed and performance of your website. It simply connects your website and app and doesn’t affect your site’s speed in any way. All the data that appears in the app is directly sourced from your website's database. Our plugin does not add to the load time.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="9">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Does AppMySite support WordPress custom post types and taxonomies?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>If your website’s plugins create custom post types and taxonomies, you can sync them to your app. AppMySite offers support for custom post types and taxonomies created via third-party plugins and themes.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="10">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How can I get support for a plugin or theme that AppMySite is not compatible with?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>If you’re using a plugin or custom theme that AppMySite does not support, you can:</p>
                <ul>
                    <li><span>Try using webviews :</span> You could simply turn on our web views for posts, products, pages and custom post types from within the app settings, which will render your mobile site within the app.</li>
                    
                    <li><span>Contact the plugin author :</span> Reach out to the author of the plugin(s) in question and get them to add support for the official WordPress APIs.</li>
                    
                    <li><span>Ask the community :</span> The <a target="_blank" href="https://community.appmysite.com/">AppMySite Community</a> is a great place for our customers to share their wishlist. Feature requests that gain enough traction eventually make their way to our product roadmap.</li>
                </ul>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="11">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How can I avail the features outside the pricing plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>You can always upgrade to a higher pricing plan in order to avail features outside your subscription. Before choosing a plan, you can take a look at all the features included in every plan and take a call accordingly.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="12">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Are the prices including taxes?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>All AppMySite plans are inclusive of taxes. There are no hidden charges.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="13">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will my app continue working if I stop paying my monthly or annual subscription?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Your app will be downgraded to the free Preview plan when you stop paying your monthly or annual subscription. In this case, your app will stop working.</p>
                  
                <p>If you don’t want to pay on a recurring basis, there is an option to subscribe to a lifetime subscription as well.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="14">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How many apps can I create in each plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Every <span>WordPress & WooCommerce</span> plan is available for a single mobile app. In case you wish to create multiple apps, you can purchase a separate plan for each app or choose to subscribe to <span>‘Unlimited Workspace’</span> plans. These plans enable you to build unlimited apps on a single plan.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="15">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will AppMySite publish my app as well?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Once you finish building your app on AppMySite, you can download you app build(s) and publish to the app store(s). Furthermore, you can refer to our <a target="_blank" href="https://www.appmysite.com/support/">Help Center</a> for documentation and video tutorials regarding app submission to the app stores.</p>
                
                <p>If you require additional support, you can purchase the <a target="_blank" href="https://www.appmysite.com/upload-to-app-stores/">Upload to App</a> Stores add-on. With this add-on, you can allow AppMySite to submit your app to the app store(s). </p> 
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="16">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Does AppMySite offer custom app development services?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>We do not offer any form of custom development services. AppMySite is a DIY platform where users can sign up and create a mobile app on their own.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="17">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Do you have a refund policy?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Click <a target="_blank" href="https://www.appmysite.com/refund-policy/">here</a> to learn more about our refund policy.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="18">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Do these plans also include the cost of creating Apple and Google developer accounts?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Once you finish creating an app on AppMySite, you can submit your app to Google Play and Apple App Store via developer accounts.</p>
                  
                <p>You’re required to create an Apple and Google account on your own. Your AppMySite subscription plan doesn’t include the cost of creating Apple and Google developer accounts. Visit our <a target="_blank" href="https://www.appmysite.com/support/">Help Center</a> to learn how to create an Apple and Google developer account.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="19">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Can I change my subscription plan(s)?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>You can easily upgrade or downgrade your subscriptions at any time, on a prorated basis.</p>
                </div>
            </div>
            
            <div class="accordionItem close accordion-item" eventKey="20">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What is your cancellation policy?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>You can cancel your paid subscription and downgrade to the free plan at any time. Once canceled, you will not be charged for any subsequent billing cycles.</p>
                
                <p>Your app will stop working as soon as you downgrade back to a free plan.</p> 
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="21">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What do I get in the Preview plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>We’d like you to try before you buy, which is what you can do with the Preview plan. You can build as many free apps as you like and preview them from within your account before you upgrade and publish.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="22">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What is a distribution license?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Distribution licenses authorize you to download your app from AppMySite for submission to the app store(s).</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="23">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Do I need a developer account?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Depending on your plan, you’ll need developer accounts for Google Play and the Apple App Store in order to publish your apps.</p>
                  
                <p>Once you build your apps, you’ll be able to download your APK or AAB files (for your Android apps) and an IPA file (for your iOS apps) which then can be uploaded to your developer accounts.</p> 
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="24">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How much time does it take to publish an app?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Once an app is submitted to the relevant app store, it is subject to a review from their end. Both stores generally take 24-48 hours but could take longer depending on various factors. You can find more details on their official websites.</p>
                </div>
            </div>
            </div>

            <h5 id="seemorelink" onclick="seemorefunction()">See More</h5> 
                
            <h5 id="seelesslink" class="d-none" onclick="seelessfunction()">See Less</h5>

            </div>
            
                    <div className="haveidea allflex">
                        <h4 className="sectionheadings">Have other questions?</h4>
                        <a target="_blank" href="/get-in-touch/">Contact Us <svg width="12.894" height="11.061" viewBox="0 0 12.894 11.061">
                        <g id="Group_5530" data-name="Group 5530" transform="translate(12 0.53) rotate(90)">
                            <path id="Path_9581" data-name="Path 9581" d="M0,5,5,0l5,5" transform="translate(0 0.167)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                            <path id="Path_9586" data-name="Path 9586" d="M0,0V12" transform="translate(5)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                        </g>
                        </svg></a>
                    </div>
                </div>      
          : this.state.selectiontype !== '' && this.state.selectiontype === 2 ?
                <div className="container">
          <h3 class="sectionheadings text-center">Frequently Asked Questions</h3>

          <div class="accordionWrapper">

            <div class="accordionItem close accordion-item" eventKey="0">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Does AppMySite support websites made on all platforms?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>AppMySite supports all platforms with the ‘website to app’ plans. If you have an active website, you can connect it to AppMySite and build a mobile app without coding.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="1">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will changes made on the website be reflected in the app?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>AppMySite will automatically render your mobile website’s pages in the app. Any changes you make to your website will also be updated in your app automatically.</p> 
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="2">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will all my website’s functionalities be rendered in the app?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Any functionality associated with your website will be rendered in the app as it appears on your mobile site. You can choose to show the webpages you’d like to show in the app.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="3">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Can I choose a ‘Website to app’ plan if I have a WordPress website?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>‘Website to app’ plans are available for all users, including WordPress website owners. You’re not required to choose a WordPress or WooCommerce plan if you have a WordPress website. However, ‘website to app’ plans do not include native integration with WordPress and WooCommerce.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="4">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How can I get features outside my pricing plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>You can always upgrade to a higher pricing plan in order to avail features outside your subscription. Before choosing a plan, you can take a look at all the features included in every plan and take a call accordingly.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="5">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Are the prices including taxes?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>All AppMySite plans are inclusive of taxes. There are no hidden charges.</p>
                </div>
            </div>

            <div className="hiddenfaq" id="showfaq">

            <div class="accordionItem close accordion-item" eventKey="6">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will my app continue working if I stop paying my subscription?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Your app will be downgraded to the free Preview plan when you stop paying your monthly or annual subscription. In this case, your app will stop working.</p>
                <p>If you don’t want to pay on a recurring basis, there is an option to subscribe to a lifetime subscription as well.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="7">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How many apps can I create in each plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Every <span>‘Website to App’</span> plan is available for a single mobile app. In case you wish to create multiple apps, you can purchase a separate plan for each app or choose to subscribe to a <span>‘Unlimited apps’</span> plan. These plans enable you to build unlimited apps on a single plan.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="8">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will AppMySite publish my app as well?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Once you finish building your app on AppMySite, you can download your app build(s) and publish to the app store(s). Furthermore, you can refer to our <a target="_blank" href="https://www.appmysite.com/support/">Help Center</a>  for documentation and video tutorials regarding app submission to the app stores. </p>
                  
                <p>If you require additional support, you can purchase the <a target="_blank" href="/add-ons/upload-to-app-stores/">Upload to App Stores</a> add-on. With this add-on, you can get AppMySite to submit your app to the app store(s).</p> 
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="9">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Does AppMySite offer custom app development services?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>We do not offer any form of custom development services. AppMySite is a DIY platform where users can sign up and create a mobile app on their own.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="10">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Do you have a refund policy?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Click <a target="_blank" href="https://www.appmysite.com/refund-policy/">here</a> to learn more about our refund policy.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="11">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Do these plans also include the cost of creating Apple and Google developer accounts?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Once you finish creating an app on AppMySite, you can submit your app to Google Play and Apple App Store via developer accounts.</p>
                
                <p>You’re required to create an Apple and Google account on your own. Your AppMySite subscription plan doesn’t include the cost of creating Apple and Google developer accounts. Visit our <a target="_blank" href="https://www.appmysite.com/support/">Help Center</a> to learn how to create an Apple and Google developer account.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="12">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Can I change my subscription plan(s)?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>You can easily upgrade or downgrade your subscriptions at any time, on a prorated basis.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="13">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What is your cancellation policy?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>You can cancel your paid subscription and downgrade to the free plan at any time. Once canceled, you will not be charged for any subsequent billing cycles.</p>
                
                <p>Your app will stop working as soon as you downgrade to a free plan.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="14">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What do I get in the Preview plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>We’d like you to try before you buy, which is what you can do with the Preview plan. You can build as many free apps as you like and preview them from within your account before you upgrade and publish.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="15">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What is a distribution license?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Distribution licenses authorize you to download your app from within your account for uploading them to the app stores.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="16">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Do I need a developer account?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Yes, depending on your plan you’ll need developer accounts on the Google Play Store and/or the Apple App Store in order to publish your apps.</p>
                
                <p>Once you build your apps, you’ll be able to download your APK or AAB files (for your Android apps) and an IPA file (for your iOS apps) which then can be uploaded to your developer accounts. You can also request AppMySite to publish your apps for you, by simply purchasing the Upload to App Stores add on.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item" eventKey="17">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How much time does it take to publish an app?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Once an app is submitted to the relevant app store, it is subject to a review from their end. Both stores generally take 24-48 hours but could take longer depending on various factors. You can find more details on their official websites.</p>
                </div>
            </div>

            </div>

            <h5 id="seemorelink" onclick="seemorefunction()">See More</h5> 
                
            <h5 id="seelesslink" class="d-none" onclick="seelessfunction()">See Less</h5>

            </div>
            
                    <div className="haveidea allflex">
                        <h4 className="sectionheadings">Have other questions?</h4>
                        <a target="_blank" href="/get-in-touch/">Contact Us <svg width="12.894" height="11.061" viewBox="0 0 12.894 11.061">
                        <g id="Group_5530" data-name="Group 5530" transform="translate(12 0.53) rotate(90)">
                            <path id="Path_9581" data-name="Path 9581" d="M0,5,5,0l5,5" transform="translate(0 0.167)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                            <path id="Path_9586" data-name="Path 9586" d="M0,0V12" transform="translate(5)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                        </g>
                        </svg></a>
                    </div>
                </div>
          : this.state.selectiontype !== '' && this.state.selectiontype === 3 ?
          <div className="container">
          <h3 class="sectionheadings text-center">Frequently Asked Questions</h3>

          <div class="accordionWrapper">

            <div class="accordionItem close accordion-item"  eventKey="0">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Is a website required to build an app with the Custom app solution?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>AppMySite’s Custom app solution does not require a website to build a native mobile app. You can create any kind of app from the ground up and launch it on the Google Play and Apple App Stores.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="1">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What kind of apps can be created with the Custom app solution?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>The Custom app solution enables you to create apps for all kinds of industries. You can build apps for blogs, grocery stores, education, social networking, bakery, real estate, directory listings, business portfolios, and more.</p> 
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="2">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will all the published content sync to the app automatically?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Any time you add a new page or post, it will be synced automatically to the app. Similarly, any time you update an existing page or post, the app will reflect the changes.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="3">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What types of content can be added to the app?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>With AppMySite, you can create apps with diverse content. You can add multiple posts and pages within your app and build them with images, videos, galleries, text blocks, code snippets and more.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="4">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What number of posts and pages can be published within the app?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>There is no limit to the number of pages and posts you can add within the app.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="5">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Is it possible to switch to a ‘WordPress’ or ‘Web to app’ plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>You can switch to WordPress, WooCommerce, or Web to app plans at any point. Once you switch from Custom app to another app type, your existing posts and pages will not appear in the app. All your app’s content will be synced directly from your website.</p>
                </div>
            </div>

            <div className="hiddenfaq" id="showfaq">

            <div class="accordionItem close accordion-item"  eventKey="6">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How can I get features outside my pricing plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>You can always upgrade to a higher pricing plan in order to avail features outside your subscription. Before choosing a plan, you can take a look at all the features included in every plan and take a call accordingly.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="7">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Are the prices including taxes?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>All AppMySite plans are inclusive of taxes. There are no hidden charges.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="8">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will my app continue working if I stop paying my subscription?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Your app will be downgraded to the free Preview plan when you stop paying your monthly or annual subscription. In this case, your app will stop working.</p>
                  
                <p>If you don’t want to pay on a recurring basis, there is an option to subscribe to a lifetime subscription as well.</p> 
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="9">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How many apps can I create in each plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Every ‘Custom app’ plan is available for a single mobile app. In case you wish to create multiple apps, you can purchase a separate plan for each app or choose to subscribe to an ‘Unlimited apps’ plan. This will enable you to build unlimited apps on a single plan.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="10">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Will AppMySite publish my app as well?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Once you finish building your app on AppMySite, you can download your app build(s) and publish to the app store(s). Furthermore, you can refer to our Help Center for documentation and video tutorials regarding app submission to the app stores.</p>
                <p>If you require additional support, you can purchase the Upload to App Stores add-on. With this add-on, you can get AppMySite to submit your app to the app store(s).</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="11">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Does AppMySite offer app development services?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>We do not offer any form of app development services. AppMySite is a DIY platform where users can sign up and create a mobile app on their own.</p>
                
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="12">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Do you have a refund policy?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Click here to learn more about our refund policy.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="13">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Do these plans also include the cost of creating Apple and Google developer accounts?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Once you finish creating an app on AppMySite, you can submit your app to Google Play and Apple App Store via developer accounts.</p>
                
                <p>You’re required to create an Apple and Google account on your own. Your AppMySite subscription plan doesn’t include the cost of creating Apple and Google developer accounts. Visit our Help Center to learn how to create an Apple and Google developer account.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="14">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What is your cancellation policy?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>You can cancel your paid subscription and downgrade to the free plan. Once canceled, you will not be charged for any subsequent billing cycles.</p>
                <p>Your app will stop working at the end of your billing cycle.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="15">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What do I get in the Preview plan?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>We’d like you to try before you buy, which is what you can do with the Preview plan. You can build as many free apps as you like and preview them from within your account before you upgrade and publish.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="16">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>What is a distribution license?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Distribution licenses authorize you to download your app from within your account for uploading them to the app stores.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="17">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>Do I need a developer account?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Yes, depending on your plan you’ll need developer accounts on the Google Play Store and/or the Apple App Store in order to publish your apps.</p>
                <p>Once you build your apps, you’ll be able to download your APK or AAB files (for your Android apps) and an IPA file (for your iOS apps) which can then be uploaded to your developer accounts. You can also request AppMySite to publish your apps for you, by simply purchasing the Upload to App Stores add-on.</p>
                </div>
            </div>

            <div class="accordionItem close accordion-item"  eventKey="18">
                <h2 class="accordionItemHeading allflex accordion-header" onClick={this.handleAccordianToggle}>How much time does it take to publish an app?<img src={ require("../../assets/images/pricing/faqplus.webp") .default }alt="pricing-type" className="img-fluid"/></h2>
                <div class="accordionItemContent">
                <p>Once an app is submitted to the relevant app store, it is subject to a review from their end. Both stores generally take 24-48 hours but could take longer depending on various factors. You can find more details on their official websites.</p>
                </div>
            </div>

            </div>

            <h5 id="seemorelink" onClick={this.seemorefunction} >See More</h5> 
                
            <h5 id="seelesslink" class="d-none" onClick={this.seelessfunction}>See Less</h5>

            </div>
            
                    <div className="haveidea allflex">
                        <h4 className="sectionheadings">Have other questions?</h4>
                        <a target="_blank" href="/get-in-touch/">Contact Us <svg width="12.894" height="11.061" viewBox="0 0 12.894 11.061">
                        <g id="Group_5530" data-name="Group 5530" transform="translate(12 0.53) rotate(90)">
                            <path id="Path_9581" data-name="Path 9581" d="M0,5,5,0l5,5" transform="translate(0 0.167)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                            <path id="Path_9586" data-name="Path 9586" d="M0,0V12" transform="translate(5)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                        </g>
                        </svg></a>
                    </div>
                </div>
          : null
          }

            </section>
       
            </>
            )
        :
            <div className="newspinner">
            <svg xmlns="http://www.w3.org/2000/svg" 
            xlink="http://www.w3.org/1999/xlink" 
            style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
            width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
            <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                style={{animationplaystate: "running", animationdelay: "0s"}}>
                </animateTransform>
            </circle>
            </g>
            <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>
            </animateTransform>
            </circle>
            </g>
            <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>

            </animateTransform>
            </circle>
            </g>
            <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
            <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
            style={{animationplaystate: "running", animationdelay: "0s"}}>

            </animateTransform>
            </circle>
            </g>
            </svg>
                </div>
        }
    </div>
            {/* Remove Addon Popup */}
          
              <Modal
                    isOpen={this.state.deluser}
                    centered
                    toggle={(e) => this.removepopup(e)}
                    style={{ maxWidth: "480px", maxHeight: "296px" }}
                    className="plan-cancel-popup"
                    dataclassName="addonpopup"
                >
                    <div className="crossmodal mt-1" onClick={(e) => this.removepopup()}>
                    <img src={ require("../../assets/images/dashboard/cross.png") .default }alt="cross"/>
              </div>
                    <ModalBody className="">
                    <div>
                        <div className="d-flex">
                        
                        <div className="confirmtext">
                            <h4>Plan change confirmation</h4>
                            { this.state.customer_type === 4 ?
                                <h6>
                                Your current will change to Free Plan at the end of your billing cycle, on <span>{moment(this.state.endcycledata).format(
                                    "Do MMM, YYYY")}</span>{" "}
                                </h6>
                            :
                            <>
                                {moment().format('YYYY-MM-DD') == moment(this.state.endcycledata).format("YYYY-MM-DD") ?
                                    <>
                                    <h6>Your plan renewal is being processed today.</h6>
                                    <h6>If you proceed, your current will change to Free Plan at the end of your next billing cycle.</h6>
                                    </>
                                :   
                                    <h6>
                                    Your current will change to Free Plan at the end of your billing cycle, on <span>{moment(this.state.endcycledata).format(
                                        "Do MMM, YYYY")}</span>{" "}
                                    </h6>
                                }
                            </>
                            }
                        </div>
                        </div>
                        <div className="plan-cancel-popup-footer d-flex justify-content-center">
                        <div className="plan-cancelbtn" onClick={(e) => this.removepopup()}>
                            Cancel
                        </div>

                        <div
                            onClick={(e) =>
                            this.state.customer_type === 4
                                ? this.handleWooCustomer(
                                    e,
                                    this.state.customer_type,
                                    this.state.one_time[0].plan_code
                                )
                                : this.previewdowngrade(e)
                            }
                        >
                             <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <p>
                            <div className={this.state.loader_remove}>
                              <img
                                src={this.state.loaderimage_remove}
                                alt="loader"
                              />
                            </div>
                            <div className={this.state.submitval_confirmpop}>Confirm</div>
                          </p>
                            </div>
                        </div>

                        </div>
                    </div>
                    </ModalBody>
                </Modal>
                <Modal
                    isOpen={this.state.downgradeuser}
                    centered
                    toggle={(e) => this.downgradepopupdeal(e)}
                    style={{ maxWidth: "480px", maxHeight: "296px" }}
                    className="plan-cancel-popup"
                    dataclassName="addonpopup"
                >
                    <div className="crossmodal mt-1" onClick={(e) => this.downgradepopupdeal()}>
                    <img src={ require("../../assets/images/dashboard/cross.png") .default }alt="cross"/>
              </div>
                    <ModalBody className="">
                    <div>
                        <div className="d-flex">
                        
                        <div className="confirmtext">
                            <h6>
                          Are you sure you want to downgrade to the Preview
                          plan?{" "}
                        </h6>
                        <h6>
                          Your subscription will automatically be downgraded at
                          the end of your subscription.{" "}
                        </h6>
                        </div>
                        </div>
                        <div className="plan-cancel-popup-footer d-flex justify-content-center">
                        <div className="plan-cancelbtn" onClick={(e) => this.downgradepopupdeal()}>
                            Cancel
                        </div>

                        <div
                            onClick={(e) =>
                            this.state.customer_type === 4
                                ? this.handleWooCustomer(
                                    e,
                                    this.state.customer_type,
                                    this.state.one_time[0].plan_code
                                )
                                : this.previewdowngrade(e)
                            }
                        >
                            <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <p>
                            <div className={this.state.loader_remove}>
                              <img
                                src={this.state.loaderimage_remove}
                                alt="loader"
                              />
                            </div>
                            <div className={this.state.submitval_confirmpop}>Okay</div>
                          </p>
                            </div>
                        </div>

                        </div>
                    </div>
                    </ModalBody>
                </Modal>
                                    {/* Reactivate PopUp */}
                                    <Modal
          isOpen={this.state.openReactivatePopUp}
          style={{ maxWidth: "460px", maxHeight: "240px" }}
          className="generalstngs2"
          centered
        >
          <ModalHeader>
            <div className="crossmodal mt-1" onClick={(e) => this.handleReactivePopUp(e,"cancel")}>
              <img src={require("../../assets/images/dashboard/cross.png").default} />
            </div>
          </ModalHeader>
          
          <ModalBody>
            <div>
              <h4>Stay on current plan?</h4>
              <h6>Are you sure you want to cancel your plan change request and retain your current plan?</h6>
              
              <div className="generalstngs2-footer">

              <div className="generalstngs2-cancel" onClick={(e) => this.handleReactivePopUp(e,"cancel")}>
                <p>Cancel</p>
                </div>
              
                <div className="generalstngs2-ok" onClick={(e) => this.handleReactivePopUp(e,"done")}>
                <div className={this.state.reactiveLoader}>
                  <div className="new-loader"></div>
                </div>
                <p>{this.state.reactiveSubmitText}</p>
                </div>
              </div>
            </div>
          </ModalBody>

        </Modal>
          </>
    )
  }
}
