import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { ADDONCARDDATA } from "../../../Constants/addonConstant/addon";
import {
  handlePaddleCurrency,
  handlePaddlePrices,
} from "../../../utils/commonUtils";
import MinusIcon from "./MinusIcon";
import PlusIcon from "./PlusIcon";
import CardFooter from "./CardFooter";
import Newfeatureicon from "../../commonIcons/Newfeatureicon";

const AddonCard = ({
  addon,
  customerBillingType,
  paddleAddonPriceList,
  downgradeAtTermEnd,
  customerType,
  appPlanName,
  appId,
  handleUpgradePopUp,
  handleWooCustomer,
  handleAddonCancelationPopUp,
  handleReactivateAddonPopup,
  addonIndx,
  newAddUserQuantity,
  currAddonQuantity,
  handleTeamAddonQuantity,
  allowedModule,
  oneTimeAddonsPurchasedJSON,
  handle,
  addonsState,
}) => {
  const paddlePrice = paddleAddonPriceList[addon.addon_code]
    ?.toString()
    .split(".")[0];
  const finalPaddlePrice =
    addon.addon_type === 1
      ? parseInt(handlePaddlePrices(paddlePrice) || "0")
      : parseInt(handlePaddlePrices(paddlePrice) || "0") / 12;

  const paddleCurrency = handlePaddleCurrency(
    paddleAddonPriceList[addon.addon_code]?.toString().split(".")[0]
  );

  const generateCheckoutURL = () => {
    return `/checkout/${addon.addon_code}/${btoa(addon.amount)}/${btoa(
      addon.addon_code
    )}/${btoa("$")}/${btoa(appId)}/${btoa(
      addon.addon_type !== 1 && addon.addon_identifier === "USER_MANAGEMENT"
        ? newAddUserQuantity || 1
        : 1
    )}/0/${"undefined"}/${"undefined"}`;
  };

  const renderBuyButton = () => {
    if (
      downgradeAtTermEnd === 1 ||
      appPlanName === "preview" ||
      appPlanName === process.env.REACT_APP_Lifetime_preview ||
      appPlanName === process.env.REACT_APP_STRIPE_PREVIEW ||
      appPlanName === process.env.REACT_APP_zoho_preview ||
      appPlanName === process.env.REACT_APP_PADDLE_PREVIEW
    ) {
      return (
        <button
          onClick={(e) => handleUpgradePopUp(e)}
          className="addon-buy-btn"
        >
          Buy
        </button>
      );
    }

    if (!addon.is_deprecated) {
      if (
        addon.addon_type === 1 ||
        (addon.addon_type !== 1 &&
          addon.is_active !== 1 &&
          addon.addon_identifier !== "USER_MANAGEMENT")
      ) {
        return customerType === 4 ? (
          <button
            className="addon-buy-btn"
            onClick={(e) => handleWooCustomer(e, addon.addon_code)}
          >
            Buy
          </button>
        ) : (
          <Link
            to={{ pathname: generateCheckoutURL(), state: addon }}
            className="addon-buy-btn"
          >
            Buy
          </Link>
        );
      }

      if (
        addon.addon_type !== 1 &&
        addon.addon_identifier === "USER_MANAGEMENT"
      ) {
        return renderUserManagementAddon();
      }
    }

    return null;
  };

  const renderUserManagementAddon = () => (
    <div className="d-flex justify-content-end">
      <div className="addon-plus-minus">
        <div
          className="addon-minus-icon"
          onClick={(e) => handleTeamAddonQuantity("minus")}
        >
          <MinusIcon />
        </div>
        <div className="addon-numberdiv">{newAddUserQuantity}</div>
        <div onClick={(e) => handleTeamAddonQuantity("add")}>
          <PlusIcon />
        </div>
      </div>
      {customerType === 4 ? (
        <button
          className="addon-buy-btn"
          onClick={(e) => handleWooCustomer(e, addon.addon_code)}
        >
          Add
        </button>
      ) : (
        <Link
          to={
            currAddonQuantity !== newAddUserQuantity
              ? { pathname: generateCheckoutURL(), state: addon }
              : "#"
          }
          className={
            currAddonQuantity === newAddUserQuantity
              ? "addon-add-btn-disable"
              : "addon-add-btn"
          }
        >
          Add
        </Link>
      )}
    </div>
  );

  const checkServiceAddonPurchased = (addon_code) => {
    if (allowedModule !== "preview") {
      if (oneTimeAddonsPurchasedJSON) {
        const quantityCheck = oneTimeAddonsPurchasedJSON.filter(
          (jsonRes) => jsonRes.addon.toString() === addon_code.toString()
        );

        if (quantityCheck.length > 0 && quantityCheck[0].quantity > 0) {
          return true;
        }
      }
    }
    return false;
  };
  let isServiceAddonPurchased = checkServiceAddonPurchased(addon.addon_code);
  let addonName = ADDONCARDDATA[addon.addon_identifier]?.name;
  let addonDesc = ADDONCARDDATA[addon.addon_identifier]?.desc;
  let addonStripeColor = ADDONCARDDATA[addon.addon_identifier]?.strip_color;
  let addonWebsiteLink = ADDONCARDDATA[addon.addon_identifier]?.link;
  return (
    <div className="addon-card-main-div">
      <div
        className="addon-strip-color"
        style={{
          backgroundColor: addonStripeColor,
        }}
      ></div>

      <div className="addon-card-body">
        <div>
          <div className="addons-tickets-head">
            <div className="addons-ticket-name">
            <h1 className="addons-pluginhead-name">{addonName}</h1>
            {/* {(addon.addon_identifier.includes("DEDICATED_SERVER")&& ( localStorage.getItem("workspaceId") &&
              localStorage.getItem("workspaceId") !== "undefined" &&
              localStorage.getItem("workspaceId") !== "null" )) ?<Newfeatureicon/>:null} */}
            </div>
            <img
              src={ADDONCARDDATA[addon.addon_identifier]?.src}
              alt={ADDONCARDDATA[addon.addon_identifier]?.name}
            />
          </div>
          <div className="addon-ticket-description">
            <p className="addons-description-para">
              {addonDesc}
              {addon.addon_identifier.includes("BUILD_AND_PUBLISH_APP") ||
              addon.addon_identifier.includes("WHITE_LABEL") ||
              addon.addon_identifier.includes("CONSENT_FORM") ||
              addon.addon_identifier.includes("RICH_NOTIFICATIONS") ||
              addon.addon_identifier.includes("IN_APP_PURCHASE") ||
              addon.addon_identifier.includes("DEDICATED_SERVER") ? (
                <></>
              ) : (
                <a
                  className="addons-website-link"
                  href={addonWebsiteLink}
                  target="_blank"
                >
                  Learn more
                </a>
              )}
            </p>
          </div>
          <p className="addons-services">
            {addon.addon_type === 1 ? "Service" : "Feature"}
          </p>
        </div>

        <CardFooter
          addon={addon}
          customerBillingType={customerBillingType}
          addonIndx={addonIndx}
          handleAddonCancelationPopUp={handleAddonCancelationPopUp}
          handleReactivateAddonPopup={handleReactivateAddonPopup}
          renderBuyButton={renderBuyButton}
          paddleAddonPriceList={paddleAddonPriceList}
          paddleCurrency={paddleCurrency}
          finalPaddlePrice={finalPaddlePrice}
          isServiceAddonPurchased={isServiceAddonPurchased}
          addonsState={addonsState}
        />
      </div>
    </div>
  );
};

export default AddonCard;
