import React from "react";
import Header from "../../Dashboard/Header";
import PricingDealList from "./PricingDeal-list";
import PricingMobileDealList from "./PricingMobile-Deal-list";
import { useParams } from "react-router-dom";
function Dealselection(props) {
  let { agencyID } = useParams("agencyID");
document.title="Agency Apply deal | AppMySite";
  return (
    <>
        <div className='pricing-deal-body'>
    
        <Header customclass={"header-responsive-new"} />
    
        <div className='web-pricing-deals'>
            <PricingDealList btnCheck={1} agencyID={agencyID} props={props} />
        </div>
    
        <PricingMobileDealList btnCheck={1} agencyID={agencyID} props={props} />
    
    </div>
    </>
         
           
      );
    }

export default Dealselection;
