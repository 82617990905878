import React, { Component } from "react";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";

export default class DemoCarousel extends React.PureComponent {
  render() {
    return (
      <Carousel showArrows={true} swipeable={this} showThumbs={false}>
        {this.props.value &&
          this.props.value.length > 0 &&
          this.props.value.map((res) => {
            return (
              <div>
                <h4>{res.title}</h4>
                <h6>
                  {res.description}{" "}
                  <Link to={res.hyperlink}>{res.call_to_action}</Link>
                </h6>
              </div>
            );
          })}
      </Carousel>
    );
  }
}
