import axios from "axios";
import S3 from 'react-aws-s3';
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
const Url = process.env.REACT_APP_API_URL + "api/app/";




export const getAnalyticsConnectionApi= (        
  setSpinner,
  setkey_id,
  setissue_id,
  setVendorId,
  setKeyFileName,
  setKeyFileS3Name,
  setPlan,
  setappuser_role_id,
  setCode,
  setreseller_package_id,
  setaddon_code,
  setData,
  setUpdatedAt,
  setAppstoreConnectionStatus,
  ) => {
  
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "get-ios-analytics-connection-data/" +btoa(parseInt(localStorage.getItem("Appid"))),{
      headers: headers
        }).then(res => {
          if(res.data.code === 200){
            setPlan(res.data.plan);
             setSpinner(false);
              if(res.data.data !== null){
                setkey_id(res.data.data.key_id);
                setissue_id(res.data.data.issuer_id);
                setVendorId(res.data.data.vendor_id);
                setKeyFileName(res.data.data.key_file);
                setKeyFileS3Name(res.data.data.key_file);
                setData(res.data.data);
                setUpdatedAt(res.data.data.updated_at);
              }
              setappuser_role_id(res.data.appuser_role_id);
            
          }
          else if(res.data.code === 203){
             setPlan(res.data.plan);
              setappuser_role_id(res.data.appuser_role_id);
              setCode(res.data.code);
              setreseller_package_id(res.data.reseller_package_id);
              setSpinner(false);
              setaddon_code(res.data.addon_code);
          }
        })
       
   
}

export const CreateConnection = (params,setSaveBtnText,setVerifyBtnText,setLoaderForSaveBtn,setLoaderForVerifyBtn,setUpdatedAt,setOpenNotConnectedPopUp,from) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "save-ios-analytics-connection-data",params,{
      headers: headers
        }).then(res => {
          if(res.data.code === 200){
            setUpdatedAt(res.data.updated_at);
            if(from==="save"){
     
              setSaveBtnText("Save");
              setLoaderForSaveBtn("d-none");
              sucesstoast("The record has been saved.");
              
            }else{
              setVerifyBtnText("Verify");
              setLoaderForVerifyBtn("d-none");
              sucesstoast("The record has been verified.");   
            }
            
          }else{

            if(from==="save"){
              errortoast("Oh no! Something’s not right.");
              setSaveBtnText("Save");
              setLoaderForSaveBtn("d-none");
            
              
            }else{
              setVerifyBtnText("Verify");
              setLoaderForVerifyBtn("d-none");
              setOpenNotConnectedPopUp(true);
              
            }
          }
       }).catch((error) => {
        
        if(from==="save"){

          setSaveBtnText("Save");
          setLoaderForSaveBtn("d-none");
          
          
        }else{
          setVerifyBtnText("Verify");
          setLoaderForVerifyBtn("d-none");

          
        }
        errortoast("Oh no! Something’s not right.");
    })
   
}

export const trackDataSaveApi = (params,state) => {
  
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': "Bearer " + localStorage.getItem("amstoken"),
  }
  
  axios.post(Url + "save-analytics-information",params,{
    headers: headers
      }).then(res => {
        if(res.data.code === 200){
          state.setState({
            enableDisableLoader:"d-none",
            enableDisableBtnText:params.record_analytics_data===1?"Enable":"Disable",
            openTrackDataPopUp:false,
            trackDataFinalVal:params.record_analytics_data===1?true:false,
          })

            sucesstoast("The record has been saved.");
    
          
        }else{
          errortoast("Oh no! Something’s not right.");
          state.setState({
            enableDisableLoader:"d-none",
            enableDisableBtnText:params.record_analytics_data===1?"Enable":"Disable",
            openTrackDataPopUp:true,
            trackDataFinalVal:params.record_analytics_data===1?false:true,
          })
          

        }
     }).catch((error) => {
      
      state.setState({
        enableDisableLoader:"d-none",
        enableDisableBtnText:params.record_analytics_data===1?"Enable":"Disable",
        openTrackDataPopUp:true,
        trackDataFinalVal:params.record_analytics_data===1?false:true,
      })
      errortoast("Oh no! Something’s not right.");
    })
 
}
