import React from "react";
import "../assets/css/Dashboard.css";
import Header from "./Header";
import "../assets/css/profileleftpanel.css";
import "../assets/css/userdashboard.css";
import { GetUserData, GetReviewData } from "../Api/Dashboard/DashboardApi";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import OutsideClickHandler from "react-outside-click-handler";
export default class Userdashboard extends React.PureComponent {
  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.state = {
      appRightPanelExpand:
        localStorage.getItem("appSideBarCollapse") === true ||
        localStorage.getItem("appSideBarCollapse") === "true"
          ? true
          : false,
      apps_count: 0,
      apps: [],
      workspaces_count: 0,
      workspaces: [],
      agencies: [],
      agencycount: 0,
      invited_apps_count: 0,
      invited_apps: [],
      invited_workspaces_count: 0,
      invited_workspaces: [],
      invited_agencies: [],
      invited_agency_count: 0,
      userinfo: "",
      g2reviewstate: "",
      spinner: true,
      feedbackPoint:
        localStorage.getItem("g2Rating") !== null &&
        localStorage.getItem("g2Rating") !== "null" &&
        localStorage.getItem("g2Rating") !== undefined &&
        localStorage.getItem("g2Rating") !== "undefined"
          ? parseInt(localStorage.getItem("g2Rating"))
          : 0,
      openG2popUp: false,
      email: "",
      showSubmitBtn: false,
      submitBtnSpin: false,
      isLoader: "d-none",
      g2Timestamp: "",
      showMobSingleGreenSvg: true,
      isHover: false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "Home | AppMySite ";
    document.body.classList.remove("opec");


    GetUserData(this, () => {
    
    });

    localStorage.setItem("workspaceId", "");
    localStorage.setItem("logged_in_user_role_id", 1);
    localStorage.setItem("workspaceId", "");
    localStorage.setItem("workspaceName", "");
    localStorage.setItem("workspaceImage", "");
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (
      this.wrapperRef &&
      this.wrapperRef.current &&
      !this.wrapperRef.current.contains(event.target)
    ) {
      document.getElementById("item-2").checked = true;
    }
  }

  handleRightPanelExpand = (value) => {
    this.setState({
      appRightPanelExpand: value,
    });
  };

  handleG2popUp = () => {
    this.setState({
      // g2reviewstate:'',
      openG2popUp: !this.state.openG2popUp,
    });
  };

  // handleSubmitBtn = () => {
  //   this.setState({
  //     submitBtnSpin: !this.state.submitBtnSpin,
  //     isLoader: "d-block",
  //   });
  //   const params = {
  //     agency_id: btoa(localStorage.getItem("agencyid")),
  //     user_id: parseInt(localStorage.getItem("user_id")),
  //     g2rating: this.state.feedbackPoint,
  //   };

  // };

  handleFeedbackPoints = (e, point) => {
    localStorage.setItem("g2Rating", point);
    this.setState({
      feedbackPoint: point,
      showSubmitBtn: true,
    });
  };

  handleReturnMobSelectedSvg = () => {
    if (this.state.feedbackPoint == 0) {
      return (
        <svg
          className="g2greensvg"
          id="_9025890_smiley_sad_icon"
          data-name="9025890_smiley_sad_icon"
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
        >
          <rect
            id="Rectangle_31264"
            data-name="Rectangle 31264"
            width="36"
            height="36"
            fill="none"
          />
          <circle
            className="greensvgback"
            id="Ellipse_8287"
            data-name="Ellipse 8287"
            cx="15"
            cy="15"
            r="15"
            transform="translate(3 3)"
            fill=""
            stroke=""
            stroke-miterlimit="10"
            stroke-width="2"
          />
          <circle
            className="greensvgeye"
            id="Ellipse_8290"
            data-name="Ellipse 8290"
            cx="1.5"
            cy="1.5"
            r="1.5"
            transform="translate(22 14)"
            fill=""
          />
          <circle
            className="greensvgeye"
            id="Ellipse_8291"
            data-name="Ellipse 8291"
            cx="1.5"
            cy="1.5"
            r="1.5"
            transform="translate(11 14)"
            fill=""
          />
          <path
            className="greensvgsmile"
            id="Path_104595"
            data-name="Path 104595"
            d="M96.4,152.046a5.667,5.667,0,0,1-10,0"
            transform="translate(-73.4 -129.085)"
            fill="none"
            stroke="#52cb86"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </svg>
      );
    } else if (this.state.feedbackPoint == 1) {
      return (
        <svg
          // onClick={(e)=>this.handleFeedbackPoints(e,1)}
          className={`g2redsvg ${
            this.state.feedbackPoint == 1 ? "activeRedG2icon" : ""
          }`}
          id="_9025890_smiley_sad_icon"
          data-name="9025890_smiley_sad_icon"
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
        >
          <rect
            id="Rectangle_31264"
            data-name="Rectangle 31264"
            width="36"
            height="36"
            fill="none"
          />
          <circle
            className="redsvgback"
            id="Ellipse_8287"
            data-name="Ellipse 8287"
            cx="15"
            cy="15"
            r="15"
            transform="translate(3 3)"
            fill=""
            stroke=""
            stroke-miterlimit="10"
            stroke-width="2"
          />
          <circle
            className="redsvgeye"
            id="Ellipse_8290"
            data-name="Ellipse 8290"
            cx="1.5"
            cy="1.5"
            r="1.5"
            transform="translate(22 14)"
            fill=""
          />
          <circle
            className="redsvgeye"
            id="Ellipse_8291"
            data-name="Ellipse 8291"
            cx="1.5"
            cy="1.5"
            r="1.5"
            transform="translate(11 14)"
            fill=""
          />
          <path
            className="redsvgsmile"
            id="Path_104595"
            data-name="Path 104595"
            d="M96.4,155.046a5.667,5.667,0,0,0-10,0"
            transform="translate(-73.4 -130.294)"
            fill=""
            stroke=""
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </svg>
      );
    } else if (this.state.feedbackPoint == 2) {
      return (
        <svg
          //  onClick={(e)=>this.handleFeedbackPoints(e,2)}
          className={`g2yellowsvg ${
            this.state.feedbackPoint == 2 ? "activeYellowG2icon" : ""
          }`}
          id="_9025890_smiley_sad_icon"
          data-name="9025890_smiley_sad_icon"
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
        >
          <rect
            id="Rectangle_31264"
            data-name="Rectangle 31264"
            width="36"
            height="36"
            fill="none"
          />
          <circle
            className="yellowsvgback"
            id="Ellipse_8287"
            data-name="Ellipse 8287"
            cx="15"
            cy="15"
            r="15"
            transform="translate(3 3)"
            fill=""
            stroke=""
            stroke-miterlimit="10"
            stroke-width="2"
          />
          <circle
            className="yellowsvgeye"
            id="Ellipse_8290"
            data-name="Ellipse 8290"
            cx="1.5"
            cy="1.5"
            r="1.5"
            transform="translate(22 14)"
            fill=""
          />
          <circle
            className="yellowsvgeye"
            id="Ellipse_8291"
            data-name="Ellipse 8291"
            cx="1.5"
            cy="1.5"
            r="1.5"
            transform="translate(11 14)"
            fill=""
          />
          <path
            className="yellowsvgsmile"
            id="Path_104597"
            data-name="Path 104597"
            d="M11.291,19.5h8"
            transform="translate(2.709 3.18)"
            fill=""
            stroke=""
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </svg>
      );
    } else if (this.state.feedbackPoint == 3) {
      return (
        <svg
          // onClick={(e)=>this.handleFeedbackPoints(e,3)}
          className={`g2greensvg ${
            this.state.feedbackPoint == 3 ? "activeGreenG2icon" : ""
          }`}
          id="_9025890_smiley_sad_icon"
          data-name="9025890_smiley_sad_icon"
          xmlns="http://www.w3.org/2000/svg"
          width="36"
          height="36"
          viewBox="0 0 36 36"
        >
          <rect
            id="Rectangle_31264"
            data-name="Rectangle 31264"
            width="36"
            height="36"
            fill="none"
          />
          <circle
            // className={this.state.feedbackPoint===3?"greensvgback":"greensvgbacknotactive"}
            className={"greensvgback"}
            id="Ellipse_8287"
            data-name="Ellipse 8287"
            cx="15"
            cy="15"
            r="15"
            transform="translate(3 3)"
            fill=""
            stroke=""
            stroke-miterlimit="10"
            stroke-width="2"
          />
          <circle
            className="greensvgeye"
            id="Ellipse_8290"
            data-name="Ellipse 8290"
            cx="1.5"
            cy="1.5"
            r="1.5"
            transform="translate(22 14)"
            fill=""
          />
          <circle
            className="greensvgeye"
            id="Ellipse_8291"
            data-name="Ellipse 8291"
            cx="1.5"
            cy="1.5"
            r="1.5"
            transform="translate(11 14)"
            fill=""
          />
          <path
            className="greensvgsmile"
            id="Path_104595"
            data-name="Path 104595"
            d="M96.4,152.046a5.667,5.667,0,0,1-10,0"
            transform="translate(-73.4 -129.085)"
            fill="none"
            stroke="#52cb86"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </svg>
      );
    }
  };
  handleSubmitBtnOutsideClick = () => {
    this.setState({
      showSubmitBtn: false,
    });
  };
  // handleSingleEmoji = (e,from) =>{
  //   let element1 = document.getElementById("single-emoji-g2-id");
  //   let element2 = document.getElementById("g2submitnotextract-id");
  //   if(from==="single"){
  //     if(element1){
  //     element1.style.display="none";}
  //   }else{
  //     if(element2){
  //       element2.style.display="none";}
  //   }
  // }

  handleReturnSelectedSvgScenerio = () => {
    if (this.state.feedbackPoint == 0) {
      return (
        <>
          <svg
            onClick={(e) => this.handleFeedbackPoints(e, 3)}
            className={`g2greensvg `}
            id="_9025890_smiley_sad_icon"
            data-name="9025890_smiley_sad_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
          >
            <rect
              id="Rectangle_31264"
              data-name="Rectangle 31264"
              width="36"
              height="36"
              fill="none"
            />
            <circle
              className={"greensvgback"}
              id="Ellipse_8287"
              data-name="Ellipse 8287"
              cx="15"
              cy="15"
              r="15"
              transform="translate(3 3)"
              fill=""
              stroke=""
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <circle
              className="greensvgeye"
              id="Ellipse_8290"
              data-name="Ellipse 8290"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(22 14)"
              fill=""
            />
            <circle
              className="greensvgeye"
              id="Ellipse_8291"
              data-name="Ellipse 8291"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(11 14)"
              fill=""
            />
            <path
              className="greensvgsmile"
              id="Path_104595"
              data-name="Path 104595"
              d="M96.4,152.046a5.667,5.667,0,0,1-10,0"
              transform="translate(-73.4 -129.085)"
              fill="none"
              stroke="#52cb86"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>

          <svg
            onClick={(e) => this.handleFeedbackPoints(e, 2)}
            className={`g2yellowsvg`}
            id="_9025890_smiley_sad_icon"
            data-name="9025890_smiley_sad_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
          >
            <rect
              id="Rectangle_31264"
              data-name="Rectangle 31264"
              width="36"
              height="36"
              fill="none"
            />
            <circle
              className="yellowsvgback"
              id="Ellipse_8287"
              data-name="Ellipse 8287"
              cx="15"
              cy="15"
              r="15"
              transform="translate(3 3)"
              fill=""
              stroke=""
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <circle
              className="yellowsvgeye"
              id="Ellipse_8290"
              data-name="Ellipse 8290"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(22 14)"
              fill=""
            />
            <circle
              className="yellowsvgeye"
              id="Ellipse_8291"
              data-name="Ellipse 8291"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(11 14)"
              fill=""
            />
            <path
              className="yellowsvgsmile"
              id="Path_104597"
              data-name="Path 104597"
              d="M11.291,19.5h8"
              transform="translate(2.709 3.18)"
              fill=""
              stroke=""
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
          <svg
            onClick={(e) => this.handleFeedbackPoints(e, 1)}
            className={`g2redsvg `}
            id="_9025890_smiley_sad_icon"
            data-name="9025890_smiley_sad_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
          >
            <rect
              id="Rectangle_31264"
              data-name="Rectangle 31264"
              width="36"
              height="36"
              fill="none"
            />
            <circle
              className="redsvgback"
              id="Ellipse_8287"
              data-name="Ellipse 8287"
              cx="15"
              cy="15"
              r="15"
              transform="translate(3 3)"
              fill=""
              stroke=""
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <circle
              className="redsvgeye"
              id="Ellipse_8290"
              data-name="Ellipse 8290"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(22 14)"
              fill=""
            />
            <circle
              className="redsvgeye"
              id="Ellipse_8291"
              data-name="Ellipse 8291"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(11 14)"
              fill=""
            />
            <path
              className="redsvgsmile"
              id="Path_104595"
              data-name="Path 104595"
              d="M96.4,155.046a5.667,5.667,0,0,0-10,0"
              transform="translate(-73.4 -130.294)"
              fill=""
              stroke=""
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
        </>
      );
    } else if (this.state.feedbackPoint == 1) {
      return (
        <>
          <svg
            onClick={(e) => this.handleFeedbackPoints(e, 1)}
            className={`g2redsvg ${
              this.state.feedbackPoint == 1 ? "activeRedG2icon" : ""
            }`}
            id="_9025890_smiley_sad_icon"
            data-name="9025890_smiley_sad_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
          >
            <rect
              id="Rectangle_31264"
              data-name="Rectangle 31264"
              width="36"
              height="36"
              fill="none"
            />
            <circle
              className="redsvgback"
              id="Ellipse_8287"
              data-name="Ellipse 8287"
              cx="15"
              cy="15"
              r="15"
              transform="translate(3 3)"
              fill=""
              stroke=""
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <circle
              className="redsvgeye"
              id="Ellipse_8290"
              data-name="Ellipse 8290"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(22 14)"
              fill=""
            />
            <circle
              className="redsvgeye"
              id="Ellipse_8291"
              data-name="Ellipse 8291"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(11 14)"
              fill=""
            />
            <path
              className="redsvgsmile"
              id="Path_104595"
              data-name="Path 104595"
              d="M96.4,155.046a5.667,5.667,0,0,0-10,0"
              transform="translate(-73.4 -130.294)"
              fill=""
              stroke=""
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>

          <svg
            onClick={(e) => this.handleFeedbackPoints(e, 2)}
            className={`g2yellowsvg ${
              this.state.feedbackPoint == 2 ? "activeYellowG2icon" : ""
            }`}
            id="_9025890_smiley_sad_icon"
            data-name="9025890_smiley_sad_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
          >
            <rect
              id="Rectangle_31264"
              data-name="Rectangle 31264"
              width="36"
              height="36"
              fill="none"
            />
            <circle
              className="yellowsvgback"
              id="Ellipse_8287"
              data-name="Ellipse 8287"
              cx="15"
              cy="15"
              r="15"
              transform="translate(3 3)"
              fill=""
              stroke=""
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <circle
              className="yellowsvgeye"
              id="Ellipse_8290"
              data-name="Ellipse 8290"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(22 14)"
              fill=""
            />
            <circle
              className="yellowsvgeye"
              id="Ellipse_8291"
              data-name="Ellipse 8291"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(11 14)"
              fill=""
            />
            <path
              className="yellowsvgsmile"
              id="Path_104597"
              data-name="Path 104597"
              d="M11.291,19.5h8"
              transform="translate(2.709 3.18)"
              fill=""
              stroke=""
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>

          <svg
            onClick={(e) => this.handleFeedbackPoints(e, 3)}
            className={`g2greensvg ${
              this.state.feedbackPoint == 3 ? "activeGreenG2icon" : ""
            }`}
            id="_9025890_smiley_sad_icon"
            data-name="9025890_smiley_sad_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
          >
            <rect
              id="Rectangle_31264"
              data-name="Rectangle 31264"
              width="36"
              height="36"
              fill="none"
            />
            <circle
              // className={this.state.feedbackPoint===3?"greensvgback":"greensvgbacknotactive"}
              className={"greensvgback"}
              id="Ellipse_8287"
              data-name="Ellipse 8287"
              cx="15"
              cy="15"
              r="15"
              transform="translate(3 3)"
              fill=""
              stroke=""
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <circle
              className="greensvgeye"
              id="Ellipse_8290"
              data-name="Ellipse 8290"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(22 14)"
              fill=""
            />
            <circle
              className="greensvgeye"
              id="Ellipse_8291"
              data-name="Ellipse 8291"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(11 14)"
              fill=""
            />
            <path
              className="greensvgsmile"
              id="Path_104595"
              data-name="Path 104595"
              d="M96.4,152.046a5.667,5.667,0,0,1-10,0"
              transform="translate(-73.4 -129.085)"
              fill="none"
              stroke="#52cb86"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
        </>
      );
    } else if (this.state.feedbackPoint == 2) {
      return (
        <>
          <svg
            onClick={(e) => this.handleFeedbackPoints(e, 2)}
            className={`g2yellowsvg ${
              this.state.feedbackPoint == 2 ? "activeYellowG2icon" : ""
            }`}
            id="_9025890_smiley_sad_icon"
            data-name="9025890_smiley_sad_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
          >
            <rect
              id="Rectangle_31264"
              data-name="Rectangle 31264"
              width="36"
              height="36"
              fill="none"
            />
            <circle
              className="yellowsvgback"
              id="Ellipse_8287"
              data-name="Ellipse 8287"
              cx="15"
              cy="15"
              r="15"
              transform="translate(3 3)"
              fill=""
              stroke=""
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <circle
              className="yellowsvgeye"
              id="Ellipse_8290"
              data-name="Ellipse 8290"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(22 14)"
              fill=""
            />
            <circle
              className="yellowsvgeye"
              id="Ellipse_8291"
              data-name="Ellipse 8291"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(11 14)"
              fill=""
            />
            <path
              className="yellowsvgsmile"
              id="Path_104597"
              data-name="Path 104597"
              d="M11.291,19.5h8"
              transform="translate(2.709 3.18)"
              fill=""
              stroke=""
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
          <svg
            onClick={(e) => this.handleFeedbackPoints(e, 3)}
            className={`g2greensvg ${
              this.state.feedbackPoint == 3 ? "activeGreenG2icon" : ""
            }`}
            id="_9025890_smiley_sad_icon"
            data-name="9025890_smiley_sad_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
          >
            <rect
              id="Rectangle_31264"
              data-name="Rectangle 31264"
              width="36"
              height="36"
              fill="none"
            />
            <circle
              className={"greensvgback"}
              id="Ellipse_8287"
              data-name="Ellipse 8287"
              cx="15"
              cy="15"
              r="15"
              transform="translate(3 3)"
              fill=""
              stroke=""
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <circle
              className="greensvgeye"
              id="Ellipse_8290"
              data-name="Ellipse 8290"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(22 14)"
              fill=""
            />
            <circle
              className="greensvgeye"
              id="Ellipse_8291"
              data-name="Ellipse 8291"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(11 14)"
              fill=""
            />
            <path
              className="greensvgsmile"
              id="Path_104595"
              data-name="Path 104595"
              d="M96.4,152.046a5.667,5.667,0,0,1-10,0"
              transform="translate(-73.4 -129.085)"
              fill="none"
              stroke="#52cb86"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>

          <svg
            onClick={(e) => this.handleFeedbackPoints(e, 1)}
            className={`g2redsvg ${
              this.state.feedbackPoint == 1 ? "activeRedG2icon" : ""
            }`}
            id="_9025890_smiley_sad_icon"
            data-name="9025890_smiley_sad_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
          >
            <rect
              id="Rectangle_31264"
              data-name="Rectangle 31264"
              width="36"
              height="36"
              fill="none"
            />
            <circle
              className="redsvgback"
              id="Ellipse_8287"
              data-name="Ellipse 8287"
              cx="15"
              cy="15"
              r="15"
              transform="translate(3 3)"
              fill=""
              stroke=""
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <circle
              className="redsvgeye"
              id="Ellipse_8290"
              data-name="Ellipse 8290"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(22 14)"
              fill=""
            />
            <circle
              className="redsvgeye"
              id="Ellipse_8291"
              data-name="Ellipse 8291"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(11 14)"
              fill=""
            />
            <path
              className="redsvgsmile"
              id="Path_104595"
              data-name="Path 104595"
              d="M96.4,155.046a5.667,5.667,0,0,0-10,0"
              transform="translate(-73.4 -130.294)"
              fill=""
              stroke=""
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
        </>
      );
    } else if (this.state.feedbackPoint == 3) {
      return (
        <>
          <svg
            onClick={(e) => this.handleFeedbackPoints(e, 3)}
            className={`g2greensvg ${
              this.state.feedbackPoint == 3 ? "activeGreenG2icon" : ""
            }`}
            id="_9025890_smiley_sad_icon"
            data-name="9025890_smiley_sad_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
          >
            <rect
              id="Rectangle_31264"
              data-name="Rectangle 31264"
              width="36"
              height="36"
              fill="none"
            />
            <circle
              // className={this.state.feedbackPoint===3?"greensvgback":"greensvgbacknotactive"}
              className={"greensvgback"}
              id="Ellipse_8287"
              data-name="Ellipse 8287"
              cx="15"
              cy="15"
              r="15"
              transform="translate(3 3)"
              fill=""
              stroke=""
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <circle
              className="greensvgeye"
              id="Ellipse_8290"
              data-name="Ellipse 8290"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(22 14)"
              fill=""
            />
            <circle
              className="greensvgeye"
              id="Ellipse_8291"
              data-name="Ellipse 8291"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(11 14)"
              fill=""
            />
            <path
              className="greensvgsmile"
              id="Path_104595"
              data-name="Path 104595"
              d="M96.4,152.046a5.667,5.667,0,0,1-10,0"
              transform="translate(-73.4 -129.085)"
              fill="none"
              stroke="#52cb86"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>

          <svg
            onClick={(e) => this.handleFeedbackPoints(e, 2)}
            className={`g2yellowsvg ${
              this.state.feedbackPoint == 2 ? "activeYellowG2icon" : ""
            }`}
            id="_9025890_smiley_sad_icon"
            data-name="9025890_smiley_sad_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
          >
            <rect
              id="Rectangle_31264"
              data-name="Rectangle 31264"
              width="36"
              height="36"
              fill="none"
            />
            <circle
              className="yellowsvgback"
              id="Ellipse_8287"
              data-name="Ellipse 8287"
              cx="15"
              cy="15"
              r="15"
              transform="translate(3 3)"
              fill=""
              stroke=""
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <circle
              className="yellowsvgeye"
              id="Ellipse_8290"
              data-name="Ellipse 8290"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(22 14)"
              fill=""
            />
            <circle
              className="yellowsvgeye"
              id="Ellipse_8291"
              data-name="Ellipse 8291"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(11 14)"
              fill=""
            />
            <path
              className="yellowsvgsmile"
              id="Path_104597"
              data-name="Path 104597"
              d="M11.291,19.5h8"
              transform="translate(2.709 3.18)"
              fill=""
              stroke=""
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
          <svg
            onClick={(e) => this.handleFeedbackPoints(e, 1)}
            className={`g2redsvg ${
              this.state.feedbackPoint == 1 ? "activeRedG2icon" : ""
            }`}
            id="_9025890_smiley_sad_icon"
            data-name="9025890_smiley_sad_icon"
            xmlns="http://www.w3.org/2000/svg"
            width="36"
            height="36"
            viewBox="0 0 36 36"
          >
            <rect
              id="Rectangle_31264"
              data-name="Rectangle 31264"
              width="36"
              height="36"
              fill="none"
            />
            <circle
              className="redsvgback"
              id="Ellipse_8287"
              data-name="Ellipse 8287"
              cx="15"
              cy="15"
              r="15"
              transform="translate(3 3)"
              fill=""
              stroke=""
              stroke-miterlimit="10"
              stroke-width="2"
            />
            <circle
              className="redsvgeye"
              id="Ellipse_8290"
              data-name="Ellipse 8290"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(22 14)"
              fill=""
            />
            <circle
              className="redsvgeye"
              id="Ellipse_8291"
              data-name="Ellipse 8291"
              cx="1.5"
              cy="1.5"
              r="1.5"
              transform="translate(11 14)"
              fill=""
            />
            <path
              className="redsvgsmile"
              id="Path_104595"
              data-name="Path 104595"
              d="M96.4,155.046a5.667,5.667,0,0,0-10,0"
              transform="translate(-73.4 -130.294)"
              fill=""
              stroke=""
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </svg>
        </>
      );
    }
  };

  render() {
    
    return (
      <div className="user-dashboard-page">
        <Header customclass={"header-responsive-new"} />

        {/* <div className="app-icon-section access-loader" style={{ overflow: "hidden",minHeight:"100vh"}}>
                     <div className="newspinner">
                     <svg xmlns="http://www.w3.org/2000/svg" 
                         xlink="http://www.w3.org/1999/xlink" 
                         style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
                         width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                         <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                         style={{animationplaystate: "running", animationdelay: "0s"}}>
                         </animateTransform>
                         </circle>
                         </g>
                         <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                         style={{animationplaystate: "running", animationdelay: "0s"}}>
                         </animateTransform>
                         </circle>
                         </g>
                         <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                         style={{animationplaystate: "running", animationdelay: "0s"}}>

                         </animateTransform>
                         </circle>
                         </g>
                         <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                         <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                         style={{animationplaystate: "running", animationdelay: "0s"}}>

                         </animateTransform>
                         </circle>
                         </g>
                         </svg>
                   </div>
                 </div> */}

        <>
        <h1>{this.state.userinfo && this.state.userinfo != '' ?
                      <>
                      <span>Welcome {this.state.userinfo.name}!</span>
                      </>
                      : ''}
                      </h1>
          <div className="three-products">
            <div className="each-single-product">
              <a href="/apps"></a>
              <img
                src={
                  require("../assets/images/dashboard/single-app-product.png")
                    .default
                }
                className="img-fluid"
                alt="Pay-per-app"
              />
              <h2>Pay-per-app</h2>
              <h4>Ideal for creating and managing one app at a time.</h4>
              { this.state.spinner ? 
                               <></>
                              :
                                <h6><span>{parseInt(this.state.apps_count)+parseInt(this.state.invited_apps_count)} apps</span></h6>
                              }
            </div>

            <div className="each-single-product">
              <a href="/workspaces"></a>
              <img
                src={
                  require("../assets/images/dashboard/workspace-product.png")
                    .default
                }
                className="img-fluid"
                alt="Unlimited apps"
              />
              <h2>Unlimited workspace</h2>
              <h4>Manage unlimited apps in one workspace.</h4>
              { this.state.spinner ? 
                               <></>
                              :
                                <h6><span>{parseInt(this.state.workspaces_count)+parseInt(this.state.invited_workspaces_count)} workspace</span></h6>
                              }
            </div>

            <div className="each-single-product">
              <a href="/agencies"></a>
              <img
                src={
                  require("../assets/images/dashboard/agency-product.png")
                    .default
                }
                className="img-fluid"
                alt="Agency"
              />
              <h2>Agency white-label</h2>
              <h4>Launch your white-label app development business.</h4>
              { this.state.spinner ? 
                               <></>
                              :
                                <h6><span>{parseInt(this.state.agencycount)+parseInt(this.state.invited_agency_count)} white-label</span></h6>
                              }
            </div>
          </div>
          {/* <div className="g2submitreview-maindiv">
            {this.state.showMobSingleGreenSvg == true && (
              <div
                className="mobileg2appearscreen"
                onClick={(e) => {
                  this.setState({
                    showMobSingleGreenSvg: false,
                  });
                }}
              >
                {this.handleReturnMobSelectedSvg()}
              </div>
            )}

            {(this.state.showMobSingleGreenSvg === false &&
              window.matchMedia("(max-width: 1200px)").matches === true) ||
            window.matchMedia("(min-width: 1200px)").matches === true ? (
              <>
                {this.state.showSubmitBtn === true ? (
                  <OutsideClickHandler
                    onOutsideClick={(e) => this.handleSubmitBtnOutsideClick(e)}
                  >
                    <div
                      className="g2submitreviewsubmit"
                      onClick={(e) => this.handleSubmitBtn(e)}
                    >
                      <div className={this.state.isLoader}>
                        <div className="new-blue-loader"></div>
                      </div>
                      {this.state.isLoader === "d-block" ? "" : "Submit"}
                    </div>
                  </OutsideClickHandler>
                ) : (
                  <>
                    <div
                      className="g2submitreview g2submitnotextract"
                      id="g2submitnotextract-id"
                 
                    >
                      {this.handleReturnSelectedSvgScenerio()}
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div> */}

          {/* <Modal
            isOpen={this.state.openG2popUp}
            style={{ maxWidth: "720px", maxHeight: "600px" }}
            className="generalstngs2 mainpopupgtwo"
            centered
            toggle={(e) => this.handleG2popUp(e)}
          >
            <ModalHeader>
              <div
                className="crossmodal "
                onClick={(e) => this.handleG2popUp(e)}
              >
                {window.matchMedia("(min-width: 1200px)").matches === true ? (
                  <img
                    src={
                      require("../assets/images/dashboard/crossgallery.png")
                        .default
                    }
                  />
                ) : (
                  <img
                    src={
                      require("../assets/images/dashboard/cross.png").default
                    }
                  />
                )}
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="g2reviewiframe">
                <iframe
                  src={`https://www.g2.com/partnerships/AppMySite/users/login.embed?state=${this.state.g2reviewstate}&email=${this.state.email}`}
                  // style={{width:"100%",height:"600px"}}
                  style={{
                    width: "100%",
                    height: "100%",
                    border: "none",
                    borderRadius:
                      window.matchMedia("(max-width: 1200px)").matches === true
                        ? "0px"
                        : "0px",
                  }}
                ></iframe>
              </div>
            </ModalBody>
          </Modal> */}
   
        </>
      </div>
    );
  }
}
