import React, {useState, useEffect} from "react";
import "./../assets/css/common.css";
import Header from "../../Dashboard/Header";
import LeftPanel from "./../LeftPanel";
import { Link, useParams } from "react-router-dom";
import {errortoast} from '../../Dashboard/Toaster';
import {
    getCustomers,receivedCustomerData,activatecustomer,deactivatecustomer,allowAdminAsClient,getsearchdata,getAgencyDomainStatus
} from "../../Api/Agency/AgencyApi";
import imagebackground from "./../assets/images/accountprofile.png";
import imagebackground2 from "./../assets/images/alexandergrey.jpg";
import BottomScrollListener from "react-bottom-scroll-listener";
import NewSpinner from "./../NewSpinner";
import moment from "moment";
import {
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";


const Customers = () =>{

    const [rightpanelexpand,setRightPanelExpand] = useState(true);
    const [customers, setCustomers] = useState([]);
    const [spinner, setSpinner] = useState(true);
    const [searcheable, setsearcheable] = useState(false);
    const [searchtext, setSearchText] = useState("");

    const [mdlcustomerdeactive, setMdlCustomerDeactive] = useState(false);
    const [btncustomerdeactiveloader, setBtnCustomerDeactiveLoader] = useState("d-none");
    const [btncustomerdeactivelabel, setBtnCustomerDeactiveLabel] = useState("Deactivate");

    const [mdlcustomeractive, setMdlCustomerActive] = useState(false);
    const [mdlcustomerlogin, setMdlCustomerLogin] = useState(false);
    const [btncustomeractiveloader, setBtnCustomerActiveLoader] = useState("d-none");
    const [btncustomerloginloader, setBtnCustomerLoginLoader] = useState("d-none");
    const [btncustomeractivelabel, setBtnCustomerActiveLabel] = useState("Activate");
    const [btncustomerloginlabel, setBtnCustomerLoginLabel] = useState("Login");
    const [currentPage, setcurrentPage] = useState(1);
    const [spinner1, setSpinner1] = useState(false);
    const [loggedspin, setloggedspin] = useState(false);
    const[stoplength,setstoplength] = useState(0);
    const[userid,setuserid] = useState('');
    const[domain,setDomain] = useState('');
    const[domainstatus,setDomainStatus] = useState('');
    const[domainname,setDomainName] = useState('');

    let { agencyID } = useParams("agencyID");

    useEffect(()=>{
        document.title = "Customers | AppMySite";

        if(agencyID !== '' && agencyID !== undefined)
		{
            getCustomers(agencyID, setSpinner, setCustomers,setDomain);
            getAgencyDomainStatus(agencyID,setDomainName,setDomainStatus);
        }

    }, []);

    const handleTxtSearch = (e) =>
    {
        setSearchText(e.target.value);
    }
    const searchdata = (e) =>
    {

        if(e.keyCode == 13)
        {
            getsearchdata(searchtext,agencyID, setSpinner, setCustomers,setDomain,setsearcheable);
        }
    }

    const handleRightPanelExpand = (rightpanelvalue) => {
        setRightPanelExpand(rightpanelvalue);
    }

    const btnCancelCustomerDeactivePopup = () =>{
        setuserid('');
        setMdlCustomerDeactive(false);
    }

    const btnCancelCustomerActivePopup = () =>{
        setuserid('');
        setMdlCustomerActive(false);
    }

    const btnCancelCustomerLoginPopup = () => {
        setuserid('');
        setMdlCustomerLogin(false); 
    }

    const handleCustomeDeactivePopup = (userid) =>
    {
        setuserid(userid);
        setMdlCustomerDeactive(!mdlcustomerdeactive);
    }

    const handleCustomeLoginPopup = (userid) =>
    {
        setuserid(userid);
        setMdlCustomerLogin(!mdlcustomerlogin);
    }

    const handleCustomerActivePopup = (userid) =>{

        setuserid(userid);
        setMdlCustomerActive(!mdlcustomeractive);

    }

    const btnCustomerDeactive = (e) =>
    {
        // setBtnCustomerDeactiveLoader('');
        const params ={
            "agency_id": parseInt(atob(agencyID)),
            "user_id":    userid
        }
        setSpinner(true);
        deactivatecustomer(params,setMdlCustomerDeactive,setSpinner,() => { getCustomers(agencyID, setSpinner, setCustomers,setDomain)})

    }

    const btnCustomerActive = (e) =>{
        // setBtnCustomerActiveLoader('');
        const params ={
            "agency_id": parseInt(atob(agencyID)),
            "user_id":    userid
        }
        setSpinner(true);
        activatecustomer(params,setMdlCustomerActive,setSpinner,() => { getCustomers(agencyID, setSpinner, setCustomers,setDomain)});

    }

    const btnCustomerLogin = (e) => 
    {
        setBtnCustomerLoginLoader('');
        setSpinner(true);
        allowAdminAsClient(userid,parseInt(atob(agencyID)),domain,setSpinner,setMdlCustomerLogin,setBtnCustomerLoginLoader);
    }

    const AgenctAsCustomreLogin = (userid,agencyID,domain) =>
    {  
        setSpinner(true);
        allowAdminAsClient(userid,parseInt(atob(agencyID)),domain,setSpinner);

    }

     // bottom scroll listner
     const handleBottomScrollListner = () => 
        {
        
            if(stoplength != 1 && spinner1 == false)
            {
                setSpinner1(true);
                const selectedPage = currentPage+1;
                setcurrentPage(selectedPage);
;
                receivedCustomerData(selectedPage,agencyID, setSpinner1, customers,setCustomers,setstoplength);
            }
        
      };


    return(
        <>
        <BottomScrollListener onBottom={handleBottomScrollListner}>
        {(scrollRef) => (
        <>
            <div className="org-module org-module-customer-div" ref={scrollRef}>
                <Header />
                <div className="org-structure">

                    <LeftPanel rightpanelbody={handleRightPanelExpand}/>
                    
                        <div className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined && localStorage.getItem('sidebaserotate') === 'true') ? "org_background org_background-expand" : "org_background "}>
                    
                                <div className="org-customer-head">

                                    <div className="agency-headings">
                                    <h1>Customers</h1>
                                    <p class="agency-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                        <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                        <circle cx="9" cy="9" r="9" stroke="none"/>
                                        <circle cx="9" cy="9" r="8.25" fill="none"/>
                                        </g>
                                        <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                                        <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                    </g>
                                    </svg><p class="agency-tooltipsubheader">Track customers and their activity here.</p></p>
                                    </div>

                                    { customers.length > 0 || searcheable ?
                                    <div className="agency-search">
                                        <svg id="Group_37943" data-name="Group 37943" xmlns="http://www.w3.org/2000/svg" width="16.53" height="16.53" viewBox="0 0 16.53 16.53">
                                            <g id="Ellipse_246" data-name="Ellipse 246" transform="translate(14.675) rotate(90)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                                <ellipse cx="7.337" cy="7.337" rx="7.337" ry="7.337" stroke="none"/>
                                                <ellipse cx="7.337" cy="7.337" rx="6.587" ry="6.587" fill="none"/>
                                            </g>
                                            <path id="Path_6064" data-name="Path 6064" d="M0,5.347V0" transform="translate(16 16) rotate(135)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>

                                        <input type="text" placeholder="Search customers" name="txtsearch" id="txtsearch" onKeyDown={(e) => searchdata(e)} onChange={(e)=>handleTxtSearch(e)} value={searchtext} />
                                    </div>
                                    : null
                                    }
                                </div>
                            {spinner ?
                                <NewSpinner />
                            :
                            <>

                                { customers.length <= 0 &&
                                <div className="org-module-shadow">
                                    <div className="no-customer">
                                        <div>
                                            <img src={require("../assets/images/no-customer.png").default} alt="no-customer"/>
                                            <p>Nothing here at the moment. Come back later?</p>
                                            {domainstatus == 2 ?
                                                <a href={domainname} target="_blank">Go to my domain <svg xmlns="http://www.w3.org/2000/svg" width="11.2" height="11.2" viewBox="0 0 11.2 11.2">
                                                <g id="Group_9160" data-name="Group 9160" transform="translate(-695.4 -246.4)">
                                                <path id="Path_10239" data-name="Path 10239" d="M4,1H1A1,1,0,0,0,0,2V9a1,1,0,0,0,1,1H8A1,1,0,0,0,9,9V6" transform="translate(696 247)" fill="none" stroke="#c8cedb" stroke-width="1.2"/>
                                                <path id="Path_10240" data-name="Path 10240" d="M4,4V0H0" transform="translate(702 247)" fill="none" stroke="#c8cedb" stroke-width="1.2"/>
                                                <path id="Path_10241" data-name="Path 10241" d="M2144-23303l-6,6" transform="translate(-1438 23550)" fill="none" stroke="#c8cedb" stroke-width="1.2"/>
                                                </g>
                                            </svg></a>
                                                : <a href={`/agency/domain/`+(agencyID)}>Activate domain</a>
                                            }
                                        
                                        </div>
                                    </div>      
                                </div>
                                }
                                
                                { customers.length > 0 &&
                                <>
                                <div className="org-module-shadow agency-customer-web">
                                    <div className="agency-customer-list">
                                        <div className="agency-customer-head">
                                            <div>
                                                <h6>Customer</h6>
                                            </div>
                                            <div>
                                                <h6>Email</h6>
                                            </div>
                                            <div>
                                                <h6>Apps</h6>
                                            </div>
                                            <div>
                                                <h6>Signed-up</h6>
                                            </div>
                                            <div>
                                                <h6>Last login</h6>
                                            </div>
                                            <div>
                                                <h6>Status</h6>
                                            </div>
                                        </div>
                                        
                                        { customers.map((userlist, userkey)=>{
                                            return(
                                            <div className="agency-customer-body">

                                                <div className="customer-body-row">

                                                    <div className="customer-profile customer-entry">
                                                        <img  className="customer-image"  
                                                        src=
                                                        {   userlist.profile_image !== '' && 
                                                            userlist.profile_image !== null && 
                                                            userlist.profile_image.indexOf("https") === -1 ? 
                                                            (process.env.REACT_APP_Image_Path + userlist.id + process.env.REACT_APP_Profile + userlist.profile_image) 
                                                            : userlist.profile_image !== '' && userlist.profile_image !== null ? 
                                                            userlist.profile_image 
                                                            : imagebackground
                                                        }
                                                        onError={({ currentTarget }) => {currentTarget.src =imagebackground;}}
                                                        alt={ userlist.profile_image}
                                                        />
                                                        <div>
                                                            <h2>{ (userlist.name).charAt(0).toUpperCase() + (userlist.name).slice(1) }</h2>
                                                            <h3>User ID: {userlist.id}</h3>
                                                        </div>
                                                    </div>

                                                    <div className="customer-entry">
                                                        <h4>{ (userlist.email) }</h4>
                                                    </div>

                                                    <div className="customer-entry">
                                                        <h4>{userlist.user_app_detail_count}</h4>
                                                    </div>

                                                    <div className="customer-entry">
                                                        <h4>{ moment( userlist.created_at ).format("D MMMM YYYY, HH:mm") }</h4>
                                                    </div>

                                                    <div className="customer-entry">
                                                        <h4>{ moment( userlist.last_login_datetime ).format("D MMMM YYYY, HH:mm") }</h4>
                                                    </div>

                                                    <div className="customer-status customer-entry">
                                                        { userlist.deleted_at === null  ?
                                                            <>
                                                            <div>
                                                                <h5 className="customerstatusgreen">Active</h5>
                                                                <span onClick={()=>handleCustomeDeactivePopup(userlist.id)}>Deactivate</span>
                                                            </div>

                                                            <p class="customer-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="15.13" height="15.13" viewBox="0 0 24 24" onClick={()=>handleCustomeLoginPopup(userlist.id)} >
                                                            <g id="Group_37035" data-name="Group 37035" transform="translate(-1649 -18)">
                                                                <rect id="Rectangle_27263" data-name="Rectangle 27263" width="15.13" height="15.13" transform="translate(1649 18)" fill="none"/>
                                                                <g id="Group_37036" data-name="Group 37036">
                                                                <path id="Path_98261" data-name="Path 98261" d="M7.205,21.372a7.205,7.205,0,1,1,.01-14.411,7.268,7.268,0,0,1,1.993.277L16.447,0h4.925V4.925L19.313,6.984,17.7,7.2l-.281,2.036L15.4,9.509l-.222,1.615-1.039,1.039a7.24,7.24,0,0,1-1.833,7.1A7.157,7.157,0,0,1,7.205,21.372Zm.01-12.411a5.205,5.205,0,1,0,3.671,8.887,5.244,5.244,0,0,0,1.17-5.6l-.234-.607,1.46-1.46.338-2.457,2.028-.272.281-2.036,2.446-.329,1-1V2h-2.1L9.726,9.549l-.607-.234A5.267,5.267,0,0,0,7.216,8.961Z" transform="translate(1650 20)" fill="#7782A1"/>
                                                                <path id="Path_98262" data-name="Path 98262" d="M5.581,14.334a1.24,1.24,0,1,0,.878.362,1.241,1.241,0,0,0-.878-.362" transform="translate(1650 20)" fill="#7782A1"/>
                                                                </g>
                                                            </g>
                                                            </svg><p class="customer-tooltipsubheader">Login as customer</p></p>

                                                            </>
                                                        :
                                                        <div>
                                                            <h5 className="customerstatusred" >Inactive</h5>
                                                            <span onClick={()=>handleCustomerActivePopup(userlist.id)}>Activate</span>
                                                        </div>

                                                        }
                                                    </div>

                                                </div>

                                                
                                            </div>
                                            )})
                                        }
                                        {spinner1 ?
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                                                        <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                            <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                                <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                                </animateTransform>
                                                                                            </circle>
                                                                                        </g>
                                                                                        <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                            <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                                <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                                </animateTransform>
                                                                                            </circle>
                                                                                        </g>
                                                                                        <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                            <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                                <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                                </animateTransform>
                                                                                            </circle>
                                                                                        </g>
                                                                                        <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                            <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                                <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                                </animateTransform>
                                                                                            </circle>
                                                                                        </g>
                                                                                    </svg>
                                                                                    :
                                                                                        null
                                                                                    }                               

                                    </div>
                                
                                </div>
                                <div className="agency-customer-mob">
                                    { customers.filter((res) => searchtext === '' ? res.name : res.name.indexOf( searchtext.toLowerCase() ) != -1 )
                                        .map((userlist, userkey)=>{
                                        return(
                                        <div className="org-module-shadow">

                                            <div className="customer-sub-mob-1">
                                                <div>
                                                    <h2>{ (userlist.name).charAt(0).toUpperCase() + (userlist.name).slice(1) }</h2>
                                                    <h3>User ID: {userlist.id}</h3>
                                                </div>

                                                <div className="customer-image-mobile" 
                                                style={{backgroundImage: userlist.profile_image !== '' && 
                                                userlist.profile_image !== null && 
                                                userlist.profile_image.indexOf("https") === -1 ? 
                                                "url(" +(process.env.REACT_APP_Image_Path + userlist.id + process.env.REACT_APP_Profile + userlist.profile_image)+ ")" 
                                                : userlist.profile_image !== '' && userlist.profile_image !== null ? 
                                                "url(" + userlist.profile_image + ")"
                                                : "url(" + imagebackground + ")"
                                                }}
                                                ></div>
                                            </div>

                                            <div className="customer-sub-mob-2">
                                                <div>
                                                    <h6>Email</h6>
                                                    <h4>{ (userlist.email) }</h4>
                                                </div>
                                            </div>

                                            <div className="customer-sub-mob-2">
                                                <div>
                                                    <h6>Apps</h6>
                                                    <h4>{userlist.user_app_detail_count}</h4>
                                                </div>
                                            </div>

                                            <div className="customer-sub-mob-2">
                                                <div>
                                                    <h6>Signed-up</h6>
                                                    <h4>{ moment( userlist.created_at ).format("D MMMM YYYY, HH:mm") }</h4>
                                                </div>
                                            </div>

                                            <div className="customer-sub-mob-2">
                                                <div>
                                                    <h6>Last login</h6>
                                                    <h4>{ moment( userlist.last_login_datetime ).format("D MMMM YYYY, HH:mm") }</h4>
                                                </div>

                                                <svg xmlns="http://www.w3.org/2000/svg" width="22.81" height="22.81" viewBox="0 0 24 24" onClick={()=>handleCustomeLoginPopup(userlist.id)}>
                                                    <g id="Group_37035" data-name="Group 37035" transform="translate(-1649 -18)">
                                                        <rect id="Rectangle_27263" data-name="Rectangle 27263" width="22.81" height="22.81" transform="translate(1649 18)" fill="none"/>
                                                        <g id="Group_37036" data-name="Group 37036">
                                                        <path id="Path_98261" data-name="Path 98261" d="M7.205,21.372a7.205,7.205,0,1,1,.01-14.411,7.268,7.268,0,0,1,1.993.277L16.447,0h4.925V4.925L19.313,6.984,17.7,7.2l-.281,2.036L15.4,9.509l-.222,1.615-1.039,1.039a7.24,7.24,0,0,1-1.833,7.1A7.157,7.157,0,0,1,7.205,21.372Zm.01-12.411a5.205,5.205,0,1,0,3.671,8.887,5.244,5.244,0,0,0,1.17-5.6l-.234-.607,1.46-1.46.338-2.457,2.028-.272.281-2.036,2.446-.329,1-1V2h-2.1L9.726,9.549l-.607-.234A5.267,5.267,0,0,0,7.216,8.961Z" transform="translate(1650 20)" fill="#7782A1"/>
                                                        <path id="Path_98262" data-name="Path 98262" d="M5.581,14.334a1.24,1.24,0,1,0,.878.362,1.241,1.241,0,0,0-.878-.362" transform="translate(1650 20)" fill="#7782A1"/>
                                                        </g>
                                                    </g>
                                                    </svg>

                                            </div>

                                            <div className="customer-sub-mob-3">
                                                { userlist.deleted_at === null  ?
                                                        <>
                                                                    <div>           
                                                                        <h6>Status</h6>
                                                                        <h5 className="customerstatusgreen">Active</h5>
                                                                        
                                                                    </div>
                                                        <span onClick={()=>handleCustomeDeactivePopup(userlist.id)}>Deactivate</span>
                                                        </>
                                                
                                                :
                                                    <>
                                                        <div>
                                                                    <h6>Status</h6>
                                                                    <h5 className="customerstatusred" >Inactive</h5>
                                                                    
                                                        </div>
                                                        <span onClick={()=>handleCustomerActivePopup(userlist.id)}>Activate</span>
                                                        </>
                                                }
                                            </div>

                                            

                                        </div>
                                        )})
                                    }
                                     {spinner1 ?
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                                                        <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                            <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                                <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                                </animateTransform>
                                                                                            </circle>
                                                                                        </g>
                                                                                        <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                            <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                                <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                                </animateTransform>
                                                                                            </circle>
                                                                                        </g>
                                                                                        <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                            <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                                <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                                </animateTransform>
                                                                                            </circle>
                                                                                        </g>
                                                                                        <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                            <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                                <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                                                                                </animateTransform>
                                                                                            </circle>
                                                                                        </g>
                                                                                    </svg>
                                                                                    :
                                                                                        null
                                                                                    }                               

                                    </div>
                                    <Modal isOpen={mdlcustomerdeactive} style={{ maxWidth: "480px", maxHeight: "270px" }} className="deactivate-customer-popup" centered>
                                        <ModalBody>
                                            <div className="crossmodal mt-1" onClick={btnCancelCustomerDeactivePopup}>
                                                <img
                                                    src={require("../assets/images/cross.png").default}
                                                />
                                            </div>
                                            <div>
                                                <div>
                                                    <h4>Deactivate this customer?</h4>
                                                    <h5>Deactivating a customer will not delete their data. The customer can be reactivated later.</h5>                
                                                </div>

                                                <div className="deactivate-customer-footer">

                                                    <div className="customer-deactivate-btn-mob" onClick={btnCustomerDeactive} id="btnleaveorgid">
                                                        <div className={btncustomerdeactiveloader}>
                                                            <div className="new-red-loader"></div>
                                                        </div>
                                                        { btncustomerdeactivelabel }
                                                    </div>

                                                    <div className="customer-cancel-btn" onClick={btnCancelCustomerDeactivePopup}>
                                                        Cancel
                                                    </div>

                                                    <div className="customer-deactivate-btn" onClick={btnCustomerDeactive} id="btnleaveorgid">
                                                        <div className={btncustomerdeactiveloader}>
                                                            <div className="new-red-loader"></div>
                                                        </div>
                                                        {btncustomerdeactiveloader == '' ? '' : btncustomerdeactivelabel }
                                                    </div>

                                                </div>
                                            </div>
                                        </ModalBody>
                                    </Modal>

                                    <Modal isOpen={mdlcustomeractive} style={{ maxWidth: "480px", maxHeight: "270px" }} className="activate-customer-popup" centered >
                                        <ModalBody>
                                            <div className="crossmodal mt-1" onClick={btnCancelCustomerActivePopup}>
                                                <img
                                                    src={require("../assets/images/cross.png").default}
                                                />
                                            </div>
                                            <div>
                                                <div className="confirm-org-body">
                                                    <h4>Activate this customer?</h4>
                                                    <h5>This customer will be able to log in again and access their data as soon as they are activated.</h5>
                                                    
                                                </div>


                                                <div className="deactivate-customer-footer">

                                                    <div className="customer-activate-btn-mob" onClick={btnCustomerActive} id="btnleaveorgid">
                                                        <div className={btncustomeractiveloader}>
                                                            <div className="new-loader"></div>
                                                        </div>
                                                        {btncustomeractiveloader == ''? '' : btncustomeractivelabel }
                                                    </div>

                                                    <div className="activate-customer-cancel-btn" onClick={btnCancelCustomerActivePopup}>
                                                        Cancel
                                                    </div>

                                                    <div className="customer-activate-btn" onClick={btnCustomerActive}  id="btncustomrtactiveid">
                                                        <div className={btncustomeractiveloader}>
                                                            <div className="new-loader"></div>
                                                        </div>
                                                        {btncustomeractiveloader == ''? '' : btncustomeractivelabel }
                                                    </div>

                                                </div>

                                            </div>
                                        </ModalBody>
                                    </Modal>

                                    <Modal isOpen={mdlcustomerlogin} style={{ maxWidth: "480px", maxHeight: "270px" }} className="activate-customer-popup" centered >

                                    <div style={{zIndex: "1"}} className="crossmodal mt-1" onClick={btnCancelCustomerLoginPopup}>
                                                <img
                                                    src={require("../assets/images/cross.png").default}
                                                />
                                            </div>

                                        <ModalBody>
                                            
                                            <div style={{margin : "auto"}}>
                                                <div className="confirm-org-body">
                                                    <h4>Login as customer</h4>
                                                    <h5>Effortlessly assist your customers by accessing their accounts.</h5>
                                                    
                                                </div>


                                                <div className="deactivate-customer-footer">

                                                    <div className="customer-activate-btn-mob" onClick={btnCustomerLogin} id="btnleaveorgid">
                                                        <div className={btncustomerloginloader}>
                                                            <div className="new-loader"></div>
                                                        </div>
                                                        {btncustomerloginloader == ''? '' : btncustomerloginlabel }
                                                        { btncustomerloginloader == '' ? ''
                                                        :  <img style={{marginLeft: "5px"}} src={ require("./../assets/images/Goto.png") .default} alt="Goto"/>
                                                        }
                                                    </div>

                                                    <div className="activate-customer-cancel-btn" onClick={btnCancelCustomerLoginPopup}>
                                                        Cancel
                                                    </div>

                                                    <div className="customer-activate-btn" onClick={btnCustomerLogin}  id="btncustomrtactiveid">
                                                        <div className={btncustomerloginloader}>
                                                            <div className="new-loader"></div>
                                                        </div>
                                                        { btncustomerloginloader == '' ? '' 
                                                        : 
                                                        btncustomerloginlabel + "  "
                                                        }
                                                        { btncustomerloginloader == '' ? ''
                                                        :  <img style={{marginLeft: "5px"}} src={ require("./../assets/images/Goto.png") .default} alt="Goto"/>
                                                        }
                                                    </div>

                                                </div>

                                            </div>
                                        </ModalBody>
                                    </Modal>
                                </>
                                }
                            </>
                            }
                            
                        </div>
                
                </div>
            </div>
            </>
        )}
        </BottomScrollListener>
        {/* <LimitReach limitreachpop={islimitreachopen} btnCrossLimitReach={setIsLimitReachOpen}/> */}
        </>
);


    
}

export default Customers;