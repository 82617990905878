import React, { useEffect, useState } from "react";
import {
  BottoMenuTaxonomyApi,
  BottoMenuTypeApi,
  cmsgetCategoryForBtm,
  cmsGetPagesForBtm,
  cmsGetPostsForBtm,
  cmsgetTagsForBtm,
  productCategoriesTreeAPIForBtm,
  restBaseTaxonomyapi,
} from "../../Api/RichNotification/RichNotificationApi";
import { SingleSelectionPopUpForNotification } from "../Reusable/SingleSelectionPopUp";
import linkIc from "../../Agency/assets/images/linklist.svg";
import crossIc from "../../assets/images/dashboard/cross.png";
import loadGif from "../../assets/images/signup/menuLoader_GIF.gif";
import {
  CUSTOM_OPTIONS,
  WEBTOAPP,
  WOO_OPTIONS,
  WORDPRESS_OPTIONS,
} from "../../Constants/notificationConsts";

const LinkSection = ({
  savingLinkPopUpData,
  handleWebViewUrl,
  parentDataObj,
}) => {
  const [openSingleSelectionPopUp, setOpenSingleSelectionPopUp] =
    useState(false);

  const [spinnerForsubDrop, setSpinnerForsubDrop] = useState(false);

  const [searchValForPopUp, setSearchValForPopUp] = useState("");

  const [itemTypeOptionsForWoocommerce, setItemTypeOptionsForWoocommerce] =
    useState(WOO_OPTIONS);

  const [itemTypeOptionsForWordpress, setItemTypeOptionsForWordpress] =
    useState(WORDPRESS_OPTIONS);

  const [itemTypeOptionsForWeb2App, setItemTypeOptionsForWeb2App] =
    useState(WEBTOAPP);

  const [itemTypeOptionsForCustomApp, setItemTypeOptionsForCustomApp] =
    useState(CUSTOM_OPTIONS);

  const [mergeArray, setMergeArray] = useState(parentDataObj?.mergeArray);
  // const [typeArray, setTypeArray] = useState([]);
  const [moduleName, setModuleName] = useState("");
  const [tempSubdata, setTempSubdata] = useState([]);
  const [tempShowL1Options, setTempShowL1Options] = useState(true);
  const [showL1Options, setShowL1Options] = useState(true);
  const [tempBreadcrumbArr, setTempBreadcrumbArr] = useState(false);
  const [tempModuleLabel, setTempModuleLabel] = useState("");
  const [moduleLabel, setModuleLabel] = useState("");
  const [paginationSpinner, setPaginationSpinner] = useState(false);
  const [subdropdownSpinner, setSubdropdownSpinner] = useState(false);
  const [tempSlug, setTempSlug] = useState(parentDataObj?.slug);
  const [tempRestBase, setTempRestBase] = useState(parentDataObj?.restBase);
  const [tempPostType, setTempPostType] = useState(parentDataObj?.postType);
  const [postType, setPostType] = useState(parentDataObj?.postType);
  const [tempItemName, setTempItemName] = useState(parentDataObj?.itemName);
  const [itemName, setItemName] = useState(parentDataObj?.itemName);
  const [slug, setSlug] = useState(parentDataObj?.slug);
  const [restBase, setRestBase] = useState(parentDataObj?.restBase);
  const [tempInvidualSelectedItemId, setTempInvidualSelectedItemId] = useState(
    parentDataObj?.invidualSelectedItemId
  );
  const [invidualSelectedItemId, setInvidualSelectedItemId] = useState(
    parentDataObj?.invidualSelectedItemId
  );
  const [typesData, setTypesData] = useState([]);
  const [taxonomyData, setTaxonomyData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [isAllowPagination, setIsAllowPagination] = useState(true);
  const [storeUrl, setStoreUrl] = useState(localStorage.getItem("store_url"));
  const [subdropdownData, setSubdropdownData] = useState([]);
  const [subData, setSubData] = useState([]);
  const [breadcrumbArr, setBreadcrumbArr] = useState([]);
  const [itemType, setItemType] = useState(parentDataObj?.itemType);
  const [tempItemType, setTempItemType] = useState(parentDataObj?.itemType);

  useEffect(() => {
    handleRowOpen();
  }, []);

  const handleClearLink = (e) => {
    setTempShowL1Options(true);
    setShowL1Options(true);
    setInvidualSelectedItemId("");
    setTempInvidualSelectedItemId("");
    setItemType("");
    setTempItemType("");
    setTempSlug("");
    setTempRestBase("");
    setTempPostType("");
    setTempModuleLabel("");
    setTempItemName("");
    setTempBreadcrumbArr([]);
    setTempSubdata([]);
    setSlug("");
    setRestBase("");
    setPostType("");
    setModuleLabel("");
    setItemName("");
    setBreadcrumbArr([]);
    setSubData([]);

    savingLinkPopUpData("", "", "", "", "", "");
  };

  const handleRowOpen = (e) => {
    if (tempInvidualSelectedItemId) {
      let newPage = 1;
      let limit = 99;

      setSpinnerForsubDrop(true);
      if (parseInt(localStorage.getItem("website_technology")) === 3) {
        if (itemType === "cms_post") {
          cmsGetPostsForBtm(
            restBase,
            isAllowPagination,
            setIsAllowPagination,
            setPageNo,
            newPage,
            setPaginationSpinner,
            storeUrl,
            subdropdownData,
            setSubdropdownData,
            subData,
            setSubData,
            setTempSubdata,
            tempSubdata,
            setTempBreadcrumbArr,
            tempBreadcrumbArr,
            setBreadcrumbArr,
            breadcrumbArr,
            setSubdropdownSpinner,
            subdropdownSpinner,
            setTempShowL1Options,
            tempShowL1Options,
            setShowL1Options,
            showL1Options,
            setModuleLabel,
            moduleLabel,
            setTempModuleLabel,
            tempModuleLabel,
            setTempItemType,
            itemType,
            setItemType,
            itemType,
            setSpinnerForsubDrop,
            newPage,
            invidualSelectedItemId,
            restBase,
            slug,
            postType,
            mergeArray,
            limit
          );
        } else if (itemType === "cms_page") {
          cmsGetPagesForBtm(
            restBase,
            isAllowPagination,
            setIsAllowPagination,
            setPageNo,
            newPage,
            setPaginationSpinner,
            storeUrl,
            subdropdownData,
            setSubdropdownData,
            subData,
            setSubData,
            setTempSubdata,
            tempSubdata,
            setTempBreadcrumbArr,
            tempBreadcrumbArr,
            setBreadcrumbArr,
            breadcrumbArr,
            setSubdropdownSpinner,
            subdropdownSpinner,
            setTempShowL1Options,
            tempShowL1Options,
            setShowL1Options,
            showL1Options,
            setModuleLabel,
            moduleLabel,
            setTempModuleLabel,
            tempModuleLabel,
            setTempItemType,
            itemType,
            setItemType,
            itemType,
            setSpinnerForsubDrop,
            newPage,
            invidualSelectedItemId,
            restBase,
            slug,
            postType,
            mergeArray,
            limit
          );
        } else if (itemType === "cms_category") {
          cmsgetCategoryForBtm(
            restBase,
            isAllowPagination,
            setIsAllowPagination,
            setPageNo,
            newPage,
            setPaginationSpinner,
            storeUrl,
            subdropdownData,
            setSubdropdownData,
            subData,
            setSubData,
            setTempSubdata,
            tempSubdata,
            setTempBreadcrumbArr,
            tempBreadcrumbArr,
            setBreadcrumbArr,
            breadcrumbArr,
            setSubdropdownSpinner,
            subdropdownSpinner,
            setTempShowL1Options,
            tempShowL1Options,
            setShowL1Options,
            showL1Options,
            setModuleLabel,
            moduleLabel,
            setTempModuleLabel,
            tempModuleLabel,
            setTempItemType,
            itemType,
            setItemType,
            itemType,
            setSpinnerForsubDrop,
            newPage,
            invidualSelectedItemId,
            restBase,
            slug,
            postType,
            mergeArray,
            limit
          );
        } else if (itemType === "cms_tag") {
          cmsgetTagsForBtm(
            restBase,
            isAllowPagination,
            setIsAllowPagination,
            setPageNo,
            newPage,
            setPaginationSpinner,
            storeUrl,
            subdropdownData,
            setSubdropdownData,
            subData,
            setSubData,
            setTempSubdata,
            tempSubdata,
            setTempBreadcrumbArr,
            tempBreadcrumbArr,
            setBreadcrumbArr,
            breadcrumbArr,
            setSubdropdownSpinner,
            subdropdownSpinner,
            setTempShowL1Options,
            tempShowL1Options,
            setShowL1Options,
            showL1Options,
            setModuleLabel,
            moduleLabel,
            setTempModuleLabel,
            tempModuleLabel,
            setTempItemType,
            itemType,
            setItemType,
            itemType,
            setSpinnerForsubDrop,
            newPage,
            invidualSelectedItemId,
            restBase,
            slug,
            postType,
            mergeArray,
            limit
          );
        }
      } else {
        if (restBase === "product_cat") {
          productCategoriesTreeAPIForBtm(
            restBase,
            isAllowPagination,
            setIsAllowPagination,
            setPageNo,
            newPage,
            setPaginationSpinner,
            storeUrl,
            subdropdownData,
            setSubdropdownData,
            subData,
            setSubData,
            setTempSubdata,
            tempSubdata,
            setTempBreadcrumbArr,
            tempBreadcrumbArr,
            setBreadcrumbArr,
            breadcrumbArr,
            setSubdropdownSpinner,
            subdropdownSpinner,
            setTempShowL1Options,
            tempShowL1Options,
            setShowL1Options,
            showL1Options,
            setModuleLabel,
            moduleLabel,
            setTempModuleLabel,
            tempModuleLabel,
            setTempItemType,
            itemType,
            setItemType,
            itemType,
            setSpinnerForsubDrop,
            newPage,
            invidualSelectedItemId,
            restBase,
            slug,
            postType,
            mergeArray
          );
        } else {
          restBaseTaxonomyapi(
            restBase,
            isAllowPagination,
            setIsAllowPagination,
            setPageNo,
            newPage,
            setPaginationSpinner,
            storeUrl,
            subdropdownData,
            setSubdropdownData,
            subData,
            setSubData,
            setTempSubdata,
            tempSubdata,
            setTempBreadcrumbArr,
            tempBreadcrumbArr,
            setBreadcrumbArr,
            breadcrumbArr,
            setSubdropdownSpinner,
            subdropdownSpinner,
            setTempShowL1Options,
            tempShowL1Options,
            setShowL1Options,
            showL1Options,
            setModuleLabel,
            moduleLabel,
            setTempModuleLabel,
            tempModuleLabel,
            setTempItemType,
            itemType,
            setItemType,
            itemType,
            setSpinnerForsubDrop,
            newPage,
            invidualSelectedItemId,
            restBase,
            slug,
            postType,
            mergeArray,
            limit
          );
        }
      }
    }
  };

  const convertHTMLEntity = (text) => {
    const span = document.createElement("span");

    if (text !== undefined) {
      return text.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
        span.innerHTML = entity;

        return span.innerText;
      });
    }
  };

  const handleOpenSelectionPopUp = (from, indx) => {
    setOpenSingleSelectionPopUp(!openSingleSelectionPopUp);
    if (from === "cancel") {
      setTempInvidualSelectedItemId(invidualSelectedItemId);
      setTempItemType(itemType);
      setTempSlug(slug);
      setTempRestBase(restBase);
      setTempPostType(postType);
      setTempModuleLabel(moduleLabel);
      setTempItemName(itemName);
      setTempBreadcrumbArr(breadcrumbArr);
      setTempSubdata(subData);
      setTempShowL1Options(showL1Options);

      savingLinkPopUpData(
        invidualSelectedItemId,
        itemType,
        slug,
        restBase,
        postType,
        itemName
      );
    } else {
      if (from === "open") {
        setInvidualSelectedItemId(tempInvidualSelectedItemId);
        setItemType(tempItemType);
        setSlug(tempSlug);
        setRestBase(tempRestBase);
        setPostType(tempPostType);
        setModuleLabel(tempModuleLabel);
        setItemName(tempItemName);
        setBreadcrumbArr(tempBreadcrumbArr);
        setSubData(tempSubdata);
        setShowL1Options(tempShowL1Options);

        savingLinkPopUpData(
          tempInvidualSelectedItemId,
          tempItemType,
          tempSlug,
          tempRestBase,
          tempPostType,
          tempItemName
        );
      }
    }
  };

  const handleSubData = (e, res) => {
    let newBreadcrumbArr = [...tempBreadcrumbArr];
    let title1 = "";
    let child = [];
    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      title1 = res.title === undefined ? res.name : res.title;
      child = res.child ? res.child : [];
    } else {
      title1 = convertHTMLEntity(
        res.title === undefined ? res.name : res.title.rendered
      );
      child = res.children ? res.children : [];
    }
    newBreadcrumbArr.push({
      name: title1,
      id: res._id ? res._id : res.term_id.toString(),
    });
    setTempSubdata(child);
    setTempBreadcrumbArr(newBreadcrumbArr);
  };
  const handleHomeBtnClick = (e) => {
    setTempBreadcrumbArr([]);
    setTempShowL1Options(true);
  };

  const handleFirstLabelClick = () => {
    setTempBreadcrumbArr([]);
    setTempSubdata(subdropdownData);
  };

  const handleBreadcrumb = (e, val, indx) => {
    let newData = [];
    let newBreadcrumb = [];

    let breadcrumb = [...tempBreadcrumbArr];

    for (let i = 0; i < breadcrumb.length; i++) {
      if (val.name == breadcrumb[i].name) {
        newBreadcrumb.push(breadcrumb[i]);
        break;
      } else {
        newBreadcrumb.push(breadcrumb[i]);
      }
    }
    setTempBreadcrumbArr(newBreadcrumb);
    function recurs(arr) {
      arr.map((eachCat) => {
        if (eachCat.parent == val.id) {
          newData.push(eachCat);
        }

        if (parseInt(localStorage.getItem("website_technology")) === 3) {
          if (eachCat.child !== undefined && eachCat.child.length > 0) {
            // if parent has childrens
            recurs(eachCat.child);
          }
        } else {
          if (eachCat.children !== undefined && eachCat.children.length > 0) {
            // if parent has childrens
            recurs(eachCat.children);
          }
        }
      });
    }
    recurs(subdropdownData);
    setTempSubdata(newData);
  };

  const handleMobBackBtn = () => {
    let breadcrumb = JSON.parse(JSON.stringify(tempBreadcrumbArr));

    if (breadcrumb && breadcrumb.length > 0) {
      let newData = [];
      breadcrumb.splice(-1, 1);
      setTempBreadcrumbArr(breadcrumb);

      function recurs(arr) {
        arr.map((eachCat) => {
          if (eachCat.parent == breadcrumb[breadcrumb.length - 1].id) {
            newData.push(eachCat);
          }
          if (parseInt(localStorage.getItem("website_technology")) === 3) {
            if (eachCat.child !== undefined && eachCat.child.length > 0) {
              // if parent has childrens
              recurs(eachCat.child);
            }
          } else {
            if (eachCat.children !== undefined && eachCat.children.length > 0) {
              // if parent has childrens
              recurs(eachCat.children);
            }
          }
        });
      }

      if (breadcrumb && breadcrumb.length > 0) {
        recurs(subdropdownData);
        setTempSubdata(newData);
      } else {
        setTempSubdata(subdropdownData);
      }
    } else {
      if (tempShowL1Options === false) {
        setTempShowL1Options(true);
      } else {
        handleOpenSelectionPopUp("cancel");
      }
    }
  };

  const handleSearchVal = (e) => {
    setSearchValForPopUp(e.target.value);
  };

  const handleL1Change = (val) => {
    setTempItemType(val.item_type);
    setTempModuleLabel(val.item_name);
    setTempInvidualSelectedItemId("");
    setTempItemName(val.item_name);
  };
  const handleMergeArrLeftDropdown = (e, res) => {
    if (res) {
      setTempSlug(res.slug);
      setTempRestBase(res.rest_base);
      setTempItemType(res.type);
      setTempInvidualSelectedItemId(res.temp_invidual_selected_item_id);
      setTempModuleLabel(res.name);
      setTempPostType(res.post_type);
      setTempItemName(res.name);
      // newArr[i].isCms = res.isCms;
    }
  };

  const handleParentClick = (val) => {
    setSearchValForPopUp("");
    // setTempModuleLabel(val.name);
    setTempItemType(val.type);
    setTempRestBase(val.rest_base);
    setTempPostType(val.post_type);
    setTempSlug(val.slug);
    setSubdropdownSpinner(true);
    setTempShowL1Options(false);

    let newPage = 1;
    let limit = 99;

    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      if (val.type === "cms_post") {
        cmsGetPostsForBtm(
          val.rest_base,
          isAllowPagination,
          setIsAllowPagination,
          setPageNo,
          newPage,
          setPaginationSpinner,
          storeUrl,
          subdropdownData,
          setSubdropdownData,
          subData,
          setSubData,
          setTempSubdata,
          tempSubdata,
          setTempBreadcrumbArr,
          tempBreadcrumbArr,
          setBreadcrumbArr,
          breadcrumbArr,
          setSubdropdownSpinner,
          subdropdownSpinner,
          setTempShowL1Options,
          tempShowL1Options,
          setShowL1Options,
          showL1Options,
          setModuleLabel,
          moduleLabel,
          setTempModuleLabel,
          tempModuleLabel,
          setTempItemType,
          val.type,
          setItemType,
          itemType,
          setSpinnerForsubDrop,
          newPage,
          invidualSelectedItemId,
          val.rest_base,
          val.slug,
          val.post_type,
          mergeArray,
          limit
        );
      } else if (val.type === "cms_page") {
        cmsGetPagesForBtm(
          val.rest_base,
          isAllowPagination,
          setIsAllowPagination,
          setPageNo,
          newPage,
          setPaginationSpinner,
          storeUrl,
          subdropdownData,
          setSubdropdownData,
          subData,
          setSubData,
          setTempSubdata,
          tempSubdata,
          setTempBreadcrumbArr,
          tempBreadcrumbArr,
          setBreadcrumbArr,
          breadcrumbArr,
          setSubdropdownSpinner,
          subdropdownSpinner,
          setTempShowL1Options,
          tempShowL1Options,
          setShowL1Options,
          showL1Options,
          setModuleLabel,
          moduleLabel,
          setTempModuleLabel,
          tempModuleLabel,
          setTempItemType,
          val.type,
          setItemType,
          itemType,
          setSpinnerForsubDrop,
          newPage,
          invidualSelectedItemId,
          val.rest_base,
          val.slug,
          val.post_type,
          mergeArray,
          limit
        );
      } else if (val.type === "cms_category") {
        cmsgetCategoryForBtm(
          val.rest_base,
          isAllowPagination,
          setIsAllowPagination,
          setPageNo,
          newPage,
          setPaginationSpinner,
          storeUrl,
          subdropdownData,
          setSubdropdownData,
          subData,
          setSubData,
          setTempSubdata,
          tempSubdata,
          setTempBreadcrumbArr,
          tempBreadcrumbArr,
          setBreadcrumbArr,
          breadcrumbArr,
          setSubdropdownSpinner,
          subdropdownSpinner,
          setTempShowL1Options,
          tempShowL1Options,
          setShowL1Options,
          showL1Options,
          setModuleLabel,
          moduleLabel,
          setTempModuleLabel,
          tempModuleLabel,
          setTempItemType,
          val.type,
          setItemType,
          itemType,
          setSpinnerForsubDrop,
          newPage,
          invidualSelectedItemId,
          val.rest_base,
          val.slug,
          val.post_type,
          mergeArray,
          limit
        );
      } else if (val.type === "cms_tag") {
        cmsgetTagsForBtm(
          val.rest_base,
          isAllowPagination,
          setIsAllowPagination,
          setPageNo,
          newPage,
          setPaginationSpinner,
          storeUrl,
          subdropdownData,
          setSubdropdownData,
          subData,
          setSubData,
          setTempSubdata,
          tempSubdata,
          setTempBreadcrumbArr,
          tempBreadcrumbArr,
          setBreadcrumbArr,
          breadcrumbArr,
          setSubdropdownSpinner,
          subdropdownSpinner,
          setTempShowL1Options,
          tempShowL1Options,
          setShowL1Options,
          showL1Options,
          setModuleLabel,
          moduleLabel,
          setTempModuleLabel,
          tempModuleLabel,
          setTempItemType,
          val.type,
          setItemType,
          itemType,
          setSpinnerForsubDrop,
          newPage,
          invidualSelectedItemId,
          val.rest_base,
          val.slug,
          val.post_type,
          mergeArray,
          limit
        );
      }
    } else {
      if (val.rest_base === "product_cat") {
        productCategoriesTreeAPIForBtm(
          val.rest_base,
          isAllowPagination,
          setIsAllowPagination,
          setPageNo,
          newPage,
          setPaginationSpinner,
          storeUrl,
          subdropdownData,
          setSubdropdownData,
          subData,
          setSubData,
          setTempSubdata,
          tempSubdata,
          setTempBreadcrumbArr,
          tempBreadcrumbArr,
          setBreadcrumbArr,
          breadcrumbArr,
          setSubdropdownSpinner,
          subdropdownSpinner,
          setTempShowL1Options,
          tempShowL1Options,
          setShowL1Options,
          showL1Options,
          setModuleLabel,
          moduleLabel,
          setTempModuleLabel,
          tempModuleLabel,
          setTempItemType,
          val.type,
          setItemType,
          itemType,
          setSpinnerForsubDrop,
          newPage,
          invidualSelectedItemId,
          val.rest_base,
          val.slug,
          val.post_type,
          mergeArray
        );
      } else {
        restBaseTaxonomyapi(
          val.rest_base,
          isAllowPagination,
          setIsAllowPagination,
          setPageNo,
          newPage,
          setPaginationSpinner,
          storeUrl,
          subdropdownData,
          setSubdropdownData,
          subData,
          setSubData,
          setTempSubdata,
          tempSubdata,
          setTempBreadcrumbArr,
          tempBreadcrumbArr,
          setBreadcrumbArr,
          breadcrumbArr,
          setSubdropdownSpinner,
          subdropdownSpinner,
          setTempShowL1Options,
          tempShowL1Options,
          setShowL1Options,
          showL1Options,
          setModuleLabel,
          moduleLabel,
          setTempModuleLabel,
          tempModuleLabel,
          setTempItemType,
          val.type,
          setItemType,
          itemType,
          setSpinnerForsubDrop,
          newPage,
          invidualSelectedItemId,
          val.rest_base,
          val.slug,
          val.post_type,
          mergeArray,
          limit
        );
      }
    }
  };
  const handleBottomScrollListner = (e, rest_base) => {
    let newPage = pageNo + 1;
    let limit = 99;
    if (!paginationSpinner && isAllowPagination) {
      if (parseInt(localStorage.getItem("website_technology")) === 3) {
        if (tempItemType === "cms_post") {
          cmsGetPostsForBtm(
            tempRestBase,
            isAllowPagination,
            setIsAllowPagination,
            setPageNo,
            newPage,
            setPaginationSpinner,
            storeUrl,
            subdropdownData,
            setSubdropdownData,
            subData,
            setSubData,
            setTempSubdata,
            tempSubdata,
            setTempBreadcrumbArr,
            tempBreadcrumbArr,
            setBreadcrumbArr,
            breadcrumbArr,
            setSubdropdownSpinner,
            subdropdownSpinner,
            setTempShowL1Options,
            tempShowL1Options,
            setShowL1Options,
            showL1Options,
            setModuleLabel,
            moduleLabel,
            setTempModuleLabel,
            tempModuleLabel,
            setTempItemType,
            tempItemType,
            setItemType,
            itemType,
            setSpinnerForsubDrop,
            newPage,
            invidualSelectedItemId,
            tempRestBase,
            tempSlug,
            tempPostType,
            mergeArray,
            limit
          );
        } else if (tempItemType === "cms_page") {
          cmsGetPagesForBtm(
            tempRestBase,
            isAllowPagination,
            setIsAllowPagination,
            setPageNo,
            newPage,
            setPaginationSpinner,
            storeUrl,
            subdropdownData,
            setSubdropdownData,
            subData,
            setSubData,
            setTempSubdata,
            tempSubdata,
            setTempBreadcrumbArr,
            tempBreadcrumbArr,
            setBreadcrumbArr,
            breadcrumbArr,
            setSubdropdownSpinner,
            subdropdownSpinner,
            setTempShowL1Options,
            tempShowL1Options,
            setShowL1Options,
            showL1Options,
            setModuleLabel,
            moduleLabel,
            setTempModuleLabel,
            tempModuleLabel,
            setTempItemType,
            tempItemType,
            setItemType,
            itemType,
            setSpinnerForsubDrop,
            newPage,
            invidualSelectedItemId,
            tempRestBase,
            tempSlug,
            tempPostType,
            mergeArray,
            limit
          );
        } else if (tempItemType === "cms_category") {
          cmsgetCategoryForBtm(
            tempRestBase,
            isAllowPagination,
            setIsAllowPagination,
            setPageNo,
            newPage,
            setPaginationSpinner,
            storeUrl,
            subdropdownData,
            setSubdropdownData,
            subData,
            setSubData,
            setTempSubdata,
            tempSubdata,
            setTempBreadcrumbArr,
            tempBreadcrumbArr,
            setBreadcrumbArr,
            breadcrumbArr,
            setSubdropdownSpinner,
            subdropdownSpinner,
            setTempShowL1Options,
            tempShowL1Options,
            setShowL1Options,
            showL1Options,
            setModuleLabel,
            moduleLabel,
            setTempModuleLabel,
            tempModuleLabel,
            setTempItemType,
            tempItemType,
            setItemType,
            itemType,
            setSpinnerForsubDrop,
            newPage,
            invidualSelectedItemId,
            tempRestBase,
            tempSlug,
            tempPostType,
            mergeArray,
            limit
          );
        } else if (tempItemType === "cms_tag") {
          cmsgetTagsForBtm(
            tempRestBase,
            isAllowPagination,
            setIsAllowPagination,
            setPageNo,
            newPage,
            setPaginationSpinner,
            storeUrl,
            subdropdownData,
            setSubdropdownData,
            subData,
            setSubData,
            setTempSubdata,
            tempSubdata,
            setTempBreadcrumbArr,
            tempBreadcrumbArr,
            setBreadcrumbArr,
            breadcrumbArr,
            setSubdropdownSpinner,
            subdropdownSpinner,
            setTempShowL1Options,
            tempShowL1Options,
            setShowL1Options,
            showL1Options,
            setModuleLabel,
            moduleLabel,
            setTempModuleLabel,
            tempModuleLabel,
            setTempItemType,
            tempItemType,
            setItemType,
            itemType,
            setSpinnerForsubDrop,
            newPage,
            invidualSelectedItemId,
            tempRestBase,
            tempSlug,
            tempPostType,
            mergeArray,
            limit
          );
        }
      } else {
        if (rest_base && rest_base !== "product_cat") {
          restBaseTaxonomyapi(
            rest_base,
            isAllowPagination,
            setIsAllowPagination,
            setPageNo,
            newPage,
            setPaginationSpinner,
            storeUrl,
            subdropdownData,
            setSubdropdownData,
            subData,
            setSubData,
            setTempSubdata,
            tempSubdata,
            setTempBreadcrumbArr,
            tempBreadcrumbArr,
            setBreadcrumbArr,
            breadcrumbArr,
            setSubdropdownSpinner,
            subdropdownSpinner,
            setTempShowL1Options,
            tempShowL1Options,
            setShowL1Options,
            showL1Options,
            setModuleLabel,
            moduleLabel,
            setTempModuleLabel,
            tempModuleLabel,
            setTempItemType,
            tempItemType,
            setItemType,
            itemType,
            setSpinnerForsubDrop,
            newPage,
            invidualSelectedItemId,
            tempRestBase,
            tempSlug,
            mergeArray,
            limit
          );
        }
      }
    }
  };
  const handleSubdropdown = (e, value, item_name) => {
    setTempInvidualSelectedItemId(value);
    setTempItemName(item_name);
  };

  const returnSelectedModuleName = () => {
    let label = "";
    if (itemType === "chat") {
      label = "Chat";
    } else if (itemType === "main_menu") {
      label = "Menu";
    } else if (itemType === "settings") {
      label = "Settings";
    } else if (itemType === "web_view") {
      label = "Web view";
    } else if (itemType === "home") {
      label = "Home screen";
    } else if (itemType === "profile") {
      label = "Profile";
    } else if (itemType === "product_categories") {
      label = "Product categories";
    } else if (itemType === "product_search") {
      label = "Product search";
    } else if (itemType === "cart") {
      label = "Cart";
    } else if (itemType === "pages") {
      label = "Pages";
    } else if (itemType === "orders") {
      label = "Orders";
    } else if (itemType === "cms_post") {
      label = "Posts";
    } else if (itemType === "cms_page") {
      label = "Pages";
    } else if (itemType === "cms_category") {
      label = "Post categories";
    } else if (itemType === "cms_tag") {
      label = "Post Tags";
    } else {
      mergeArray &&
        mergeArray.length > 0 &&
        mergeArray.map((value, k) => {
          if (
            itemType + slug + restBase + postType ===
            value.type + value.slug + value.rest_base + value.post_type
          ) {
            label = value.name;
          }
        });
    }

    return label;
  };

  const returnSelectedItem = (item, arr) => {
    let name = "";

    if (!item || item === "all") {
      name = "- All";
    } else {
      function recurs(arr1) {
        arr1 &&
          arr1.map((res) => {
            if (parseInt(localStorage.getItem("website_technology")) === 3) {
              if (res._id == item) {
                name = "- " + (res.title ? res.title : res.name);
              }
            } else {
              if (res.term_id) {
                if (res.term_id == item) {
                  name = "- " + (res.title ? res.title.rendered : res.name);
                }
              } else {
                if (res.id == item) {
                  name = "- " + (res.title ? res.title.rendered : res.name);
                }
              }
            }

            if (parseInt(localStorage.getItem("website_technology")) === 3) {
              if (res.child !== undefined && res.child.length > 0) {
                // if parent has childrens
                recurs(res.child);
              }
            } else {
              if (res.children !== undefined && res.children.length > 0) {
                // if parent has childrens
                recurs(res.children);
              }
            }
          });
      }
      recurs(arr);
    }

    if (!name) {
      name = item ? `- ${item}` : "";
    }
    return convertHTMLEntity(name);
  };

  return (
    <div>
      <div className="link-form-group position-relative">
        <div
          className="d-flex align-items-center popuplink-uploader"
          onClick={() =>
            spinnerForsubDrop === false
              ? handleOpenSelectionPopUp("open")
              : null
          }
        >
          {spinnerForsubDrop ? (
            <img
              className="search-dropdown-before"
              src={loadGif}
              style={{ width: "20px", height: "20px" }}
              alt="loader"
            />
          ) : (
            <p className="listselected-frompopup">
              {returnSelectedModuleName()}{" "}
              {(itemType === "post_type" ||
                itemType === "taxonomy" ||
                itemType === "search" ||
                // cmsRow.isCms===1
                (parseInt(localStorage.getItem("website_technology")) === 3 &&
                  itemType &&
                  itemType !== "settings" &&
                  itemType !== "web_view" &&
                  itemType !== "main_menu" &&
                  itemType !== "chat")) &&
                returnSelectedItem(invidualSelectedItemId, subdropdownData)}
            </p>
          )}

          <img src={linkIc} alt="link" />
        </div>
        {itemType === "web_view" && (
          <>
            <div className="d-flex align-items-center popuplink-uploader popupLink-url-input">
              <div className="link-popuphttps">https://</div>
              <input
                className="link-popupinput"
                name="hello"
                id="hello"
                onChange={(e) => handleWebViewUrl(e)}
                value={
                  parentDataObj.webViewUrl
                    ? parentDataObj.webViewUrl.replace(/^https?:\/\//i, "")
                    : ""
                }
              />
            </div>
          </>
        )}

        {tempItemType && !spinnerForsubDrop && (
          <div
            onClick={(e) => handleClearLink(e)}
            className="notif-cross-ic"
          >
            <img src={crossIc} alt="cross" />
          </div>
        )}
      </div>
      <SingleSelectionPopUpForNotification
        openSingleSelectionPopUp={openSingleSelectionPopUp}
        techOPtions={
          parseInt(localStorage.getItem("website_technology")) === 2
            ? itemTypeOptionsForWeb2App
            : parseInt(localStorage.getItem("website_technology")) === 3
            ? itemTypeOptionsForCustomApp
            : parseInt(localStorage.getItem("website_technology")) === 4
            ? itemTypeOptionsForWordpress
            : parseInt(localStorage.getItem("website_technology")) === 5
            ? itemTypeOptionsForWoocommerce
            : []
        }
        searchValForPopUp={searchValForPopUp}
        setSearchValForPopUp={setSearchValForPopUp}
        mergeArray={mergeArray}
        moduleName={moduleName}
        setModuleName={setModuleName}
        temp_subData={tempSubdata}
        temp_showL1Options={tempShowL1Options}
        handleOpenSelectionPopUp={handleOpenSelectionPopUp}
        handleHomeBtnClick={handleHomeBtnClick}
        temp_breadcrumbArr={tempBreadcrumbArr}
        temp_module_label={tempModuleLabel}
        handleBreadcrumb={handleBreadcrumb}
        handleMobBackBtn={handleMobBackBtn}
        temp_item_type={tempItemType}
        temp_invidual_selected_item_id={tempInvidualSelectedItemId}
        temp_rest_base={tempRestBase}
        temp_slug={tempSlug}
        subdropdownSpinner={subdropdownSpinner}
        paginationSpinner={paginationSpinner}
        handleFirstLabelClick={handleFirstLabelClick}
        handleSearchVal={handleSearchVal}
        handleSubData={handleSubData}
        handleL1Change={handleL1Change}
        handleMergeArrLeftDropdown={handleMergeArrLeftDropdown}
        handleParentClick={handleParentClick}
        handleBottomScrollListner={handleBottomScrollListner}
        handleSubdropdown={handleSubdropdown}
        typeArray={parentDataObj?.typeArray}
        is_chat_enabled={""}
        keys_data={""}
        temp_post_type={tempPostType}
      />
    </div>
  );
};

export default LinkSection;
