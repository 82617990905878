import React from "react";
import "../../assets/css/appdashboard.css";

const ModuleTitleTooltip = ({ title, text }) => {
  return (
    <div className="app-dashboard-head">
    <h1 className="title">
      {title}{" "}
      <p className="appdash-tooltip">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <g
            id="Group_35001"
            data-name="Group 35001"
            transform="translate(-1727 -19)"
          >
            <g
              id="Ellipse_7706"
              data-name="Ellipse 7706"
              transform="translate(1727 19)"
              fill="none"
              stroke="#bcc1ce"
              stroke-width="1.5"
            >
              <circle cx="9" cy="9" r="9" stroke="none"></circle>
              <circle cx="9" cy="9" r="8.25" fill="none"></circle>
            </g>
            <line
              id="Line_343"
              data-name="Line 343"
              y1="2"
              transform="translate(1736 23)"
              fill="none"
              stroke="#bcc1ce"
              stroke-miterlimit="10"
              stroke-width="1.5"
            ></line>
            <path
              id="Path_98224"
              data-name="Path 98224"
              d="M0,2V-4"
              transform="translate(1736 31)"
              fill="none"
              stroke="#bcc1ce"
              stroke-width="1.5"
            ></path>
          </g>
        </svg>
        <span className="appdash-tooltipsubheader">{text}</span>
      </p>
    </h1>
    </div>
  );
};

export default ModuleTitleTooltip;
