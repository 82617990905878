import axios from "axios";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
const Url = process.env.REACT_APP_API_URL + "api/user/";
const Newversion = process.env.REACT_APP_API_URL + "api/";
const newUrlForPaddle = process.env.REACT_APP_API_URL + "api/paddle/";
const newUrlForStripe = process.env.REACT_APP_API_URL + "api/stripe/";
const BuildUrl = process.env.REACT_APP_API_URL + "api/automation/bitbucket/";
const Cancelurl = process.env.REACT_APP_API_URL + "api/automation/bitrise/";
const dashboardurl = process.env.REACT_APP_API_URL + "api/";
const appsumourl = process.env.REACT_APP_API_URL;
const RedirectUrl = process.env.REACT_APP_cors_api;
const Errorurl = process.env.REACT_APP_API_URL + "api/frontend-log";
let clientid = "";
let awspath = "";
let appicon = "";
let applogo = "";
let splashimg = "";
let loginbackground = "";
let loginlogo = "";
let dashboard_banner = "";

const changeurl = () => {
  awspath =
    process.env.REACT_APP_Image_Path + clientid + process.env.REACT_APP_Profile;
  appicon = process.env.REACT_APP_Image_Path + clientid + "/";
  applogo = process.env.REACT_APP_Image_Path + clientid + "/";
  splashimg = process.env.REACT_APP_Image_Path + clientid + "/";
  loginbackground = process.env.REACT_APP_Image_Path + clientid + "/";
  loginlogo = process.env.REACT_APP_Image_Path + clientid + "/";
  dashboard_banner = process.env.REACT_APP_Image_Path + clientid + "/";
};

export const GetSupportPlanApi = (state,callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Newversion + "get-support-plans", {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) 
      {
        
        state.setState({
          spinner:false,
          monthdata:res.data.data.month,
          renewal_date:res.data.data.renewal_date,
          customer_billing_type:res.data.data.customer_billing_type,
          downgrade_at_term_end:res.data.data.downgrade_at_term_end,
          stripe_customer_id:res.data.data?.stripe_customer_id,
          subscription_id:res.data.data?.subscription_id,
          payment_methods:
              res.data.data.payment_methods,
              gateway_subscription_id:
              res.data.data.subscription_plan && 
              res.data.data.subscription_plan != '' 
              ? res.data.data.subscription_plan
              : '',
          pm_link:
              res.data.data.pm_link && 
              res.data.data.pm_link != '' 
              ? res.data.data.pm_link
              : '',
          payment_method_id:
          res.data.data.payment_methods &&
          res.data.data.payment_methods.length === 0
            ? []
            : parseInt(res.data.data.payment_methods[0].id),
        });
        localStorage.setItem("support_subscription_id", res.data.data?.subscription_id);
        callback();

      } 
      else
       {
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((error) => 
    {
      
      errortoast("Oh no! Something’s not right.");
    });
};

export const submitstripepayment = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  params.success_path = `/transaction-successful/support`;
  params.cancel_path = state.props?.location?.pathname;

  axios
    .post(newUrlForStripe + "buy-support-plan", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
          localStorage.setItem('checkoutpageamount',res.data.total);
          localStorage.setItem('checkoutsubscriptionid',res.data.subscription_id);
          localStorage.setItem('checkoutcustomerid',res.data.user_id);

          const userinfo  = JSON.parse(localStorage.getItem("user_info"));

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'checkout_successful',
        'user_email': userinfo.email,
        'user_name' : userinfo.name,
        'user_id': userinfo.id,
        'agency_id':localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1,
        'transactionDetails': [{
              'product_type': 'plan',
              'product_action':'upgrade',
              'plan_type': 'support',       
              'price': res.data.total ,
              'currency': 'USD'
        }]
        });
          
          var url = `/transaction-successful/support`;

          window.open(url,"_self");

      } else if (+res.data.code === 204 && res?.data?.url) {
        window.location.href = res?.data?.url;
      } else {
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((error) => 
    {
      
      errortoast("Oh no! Something’s not right.");
    });
};

export const submitpaddlepayment = (params,state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  

  axios
    .post(newUrlForPaddle + "buy-support-plan", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) 
      {
        localStorage.setItem('checkoutpageamount',res.data.total);
        localStorage.setItem('checkoutsubscriptionid',res.data.subscription_id);
        localStorage.setItem('checkoutcustomerid',res.data.user_id);
        
        var url = `/transaction-successful/support`;

        window.open(url,"_self");

      } 
      else if(res.data.code === 201)
      {
        state.openPaddleCheckout(res.data.data, res.data.ams_order_id);
        state.setState({
          submitval: "Make Payment",
          loader: "d-none",
          formbuttondisabled: false,
          ams_order_id: res.data.ams_order_id,
        });
      }
      else
       {
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((error) => 
    {
      
      errortoast("Oh no! Something’s not right.");
    });
};

export const updatepayment = (params,state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  

  axios
    .post(Newversion + "upgrade-support-plan", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) 
      {
        
          localStorage.setItem('checkoutpageamount',res.data.total);
          localStorage.setItem('checkoutsubscriptionid',res.data.subscription_id);
          localStorage.setItem('checkoutcustomerid',res.data.user_id);
          
          var url = `/transaction-successful/support`;

          window.open(url,"_self");

      } 
      else
       {
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((error) => 
    {
      
      errortoast("Oh no! Something’s not right.");
    });
};

export const downgradeplan = (params,state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  

  axios
    .post(Newversion + "upgrade-support-plan", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) 
      {
        
          localStorage.setItem('checkoutpageamount',res.data.total);
          localStorage.setItem('checkoutsubscriptionid',res.data.subscription_id);
          localStorage.setItem('checkoutcustomerid',res.data.user_id);
          
          var url = `/transaction-successful/support`;

          window.open(url,"_self");

      } 
      else
       {
        errortoast("Oh no! Something’s not right.");
        state.setState({
          deluser: false,
          loader_remove: "d-none",
          submitval_confirmpop: "plan-upgradebtn",
      });
      }
    })
    .catch((error) => 
    {
      
      errortoast("Oh no! Something’s not right.");
    });
};

export const canceldowngradeplan = (params,state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  

  axios
    .post(Newversion + "upgrade-support-plan", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) 
      {

        //   localStorage.setItem('checkoutpageamount',res.data.total);
        //   localStorage.setItem('checkoutsubscriptionid',res.data.subscription_id);
        //   localStorage.setItem('checkoutcustomerid',res.data.user_id);
          
        window.location.reload();

      } 
      else
       {
        errortoast("Oh no! Something’s not right.");
        state.setState({
          deluser: false,
          loader_remove: "d-none",
          submitval_confirmpop: "plan-upgradebtn",
      });
      }
    })
    .catch((error) => 
    {
      
      errortoast("Oh no! Something’s not right.");
    });
};

//show user profileAPi
export const GetSupportprofileApi = (state) => 
{
  clientid =
    localStorage.getItem("roleid") === "1"
      ? localStorage.getItem("originalid")
      : localStorage.getItem("user_id");

  changeurl();

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "user_profile", {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) 
      {
        let proimage = "";
        if (res.data.data.profile_image !== "") {
          if (
            res.data.data.profile_image === "null" ||
            res.data.data.profile_image === null ||
            res.data.data.profile_image === ""
          ) {
            proimage = "";
          } else if (res.data.data.profile_image.indexOf("https") === -1) {
            proimage = awspath + res.data.data.profile_image;
          } else {
            proimage = res.data.data.profile_image;
          }
        }
        state.setState({
          fb_spinner:false,
          google_spinner:false,
          updated_at:res.data.data.updated_at,
          customer_type: res.data.data.customer_type,
          latest_updated_at:res.data.data.billing_updated_date?res.data.data.billing_updated_date:null,
          name: res.data.data.name,
          email: res.data.data.email,
          oldemail: res.data.data.email,
          profile_image: proimage,
          spinner: false,
          status: res.data.data.status,
          role_id:
            res.data.data.role_id !== undefined ? res.data.data.role_id : 2,
          //billing
          first_name: res.data.data.billing_first_name,
          last_name: res.data.data.billing_last_name,
          company_name: res.data.data.company_name,
          billing_address: res.data.data.billing_address,
          billing_zip: res.data.data.billing_zip_code,
          billing_city: res.data.data.billing_city,
          billing_country:
            res.data.data.country_id === null ? "" : res.data.data.country_id,
         
          billing_state: res.data.data.billing_state,
          tax_number: res.data.data.tax_number,
          apps_count: res.data.apps_count,
          total_app_count: res.data.total_app_count,
          country_code: res.data.data.country_code === "IN" ? "IN" : "USD",
          customer_billing_type: res.data.data.customer_billing_type,
          user_type: res.data.data.owner,
          linked_google: res.data.linked_social_logins.google,
          linked_facebook: res.data.linked_social_logins.facebook,
          linked_apple: res.data.linked_social_logins.apple,
          user_roles: res.data.data.user_job_role,
          user_industry: res.data.data.user_industry,
          company_size: res.data.data.user_company_size,
          updated_at:res.data.data.updated_at,
          checkspinner:false,
        });
      } else if (res.data.code === 203) {
        errortoast("Session expired ! login again");
        setTimeout(() => {
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
           let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
          // return (window.location.href = "/");
        }, 2000);
      }
      else if (res.data.code === 401) {
        errortoast("Session expired ! login again");
        setTimeout(() => {
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
           let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
          return (window.location.href = "/");
        }, 2000);
      }
      else{
        setTimeout(() => {
          let myItem = localStorage.getItem("buildversion");
          let myItemversion = localStorage.getItem("Apiversion");
           let previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", myItem);
          localStorage.setItem("Apiversion", myItemversion);
          return (window.location.href = "/");
        }, 2000);
      }
    })
    .catch((error) =>{});
};