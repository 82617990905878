import axios from "axios";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";

const dashboardurl = process.env.REACT_APP_API_URL + "api/";

export const AppCloneApi = (params, state) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
  
    axios
      .post(dashboardurl + "clone-app", params, {
        headers: headers,
      })
      .then((res) => {
        if (res.data.code === 200) {
          state.handleRefreshAfterClone();
        } else {
            state.setState({
                cloneBtnText:"Clone",
                cloneBtnLoader: "d-none",
              });
          errortoast("Oops! Something went wrong.");
        }
      })
      .catch((error) => 
      {
        state.setState({
            cloneBtnText:"Clone",
            cloneBtnLoader: "d-none",
          });
        errortoast("Oops! Something went wrong.");
      });
  };