import React,{ useState, useContext, useEffect } from 'react'; 
import Tooltip from '../../Tooltip';
import styles from './publish.module.css';
import { Modal, ModalHeader, ModalBody, Media } from 'reactstrap';
import { Creation } from "./Context";
import { Certificate, CreateConnection } from '../../../Api/Publishapp/Publishappapi';
import Errormessage from '../../Reusable/Hookerror';
import { certificate } from '../../../Api/Dashboard/DashboardApi';
const Storeconnection = () => {
  
    const context = useContext(Creation);
    const [submitval, setsubmit] = useState("Save");
   
  	const [loader,setLoader] = useState("d-none");
	  const [loaderimage] = useState(require( "../../../assets/images/signup/Loader_GIF.gif").default);
    const [adduser, setadduser] = useState(false);
    const [apple_id, setapple_id] = useState("");
    const [issue_id, setissue_id] = useState("");
    const [key_id, setkey_id] = useState("");
    const [errormodal, setErrormodal] = useState(false);
    const [title, settitle] = useState("");
    const [errormessage, seterrormessage] = useState("");
    const [certificatefilename, setcertificatefilename] = useState(".p8 file supported");
    const [certificates3name, setcertificates3name] =  useState("");
    const [disabledsubmitbutton, setdisabledsubmitbutton] = useState(false)
  
    

    //Modal open and close
    const addusertoggle = () => { 
        setadduser(!adduser);			
    };
    const handleapple_id = (e) => {
      setapple_id(e.target.value)
    }
    const handleissuer_id = (e) => {
      setissue_id(e.target.value)
    }
    const handlekey_id = (e) => {
      setkey_id(e.target.value)
    }
    const handlecertificate = (e) => {
          // let self = this;
          let reader = new FileReader();
          let file = e.target.files[0];
        
          if(file !== undefined){
         
            if (file.name.indexOf("p8") !== -1) {
              setcertificatefilename(file.name.split(".").slice(0, -1).join("."))
              
              reader.onload = function (upload) {
               // setcertificate(upload.target.result);
                formcertificate(upload.target.result, file.name.split(".").slice(0, -1).join("."))
               
              };
              reader.readAsDataURL(file);
            } else {
              settitle("File format");
              setErrormodal(true);
              seterrormessage("File format not supported. Upload p8 file only.")
            }
         
          }
    }
   
    const formcertificate = (result, filename) => {
      setdisabledsubmitbutton(true);
      const params = {
        original_path:localStorage.getItem("user_id") + "/" + localStorage.getItem("Appid") + "/ios/p8",
        original: result,
        imgname: filename,
      };
      Certificate(params, setcertificates3name, setdisabledsubmitbutton);
    };

    const connectionsubmit = (e) => {
      e.preventDefault();
      
      const params = {
        action:"step1",
        app_id:parseInt(localStorage.getItem("Appid")),
        apple_id:apple_id,
        key_id:key_id,
        issuer_id:issue_id,
        key_file:certificates3name
      }
      if(params.key_file === ""){
          settitle("Key File");
          setErrormodal(true);
          seterrormessage("Please upload key file")
      }else{
        setLoader("d-block");
         setsubmit("");
        CreateConnection(params,setLoader,setsubmit, context)
      }
     
    }
    const Errortext = {
      title : title,
      errormessage : errormessage,
      errormodal: errormodal,
      setErrormodal: setErrormodal
    }
    useEffect(() => {
      
      setapple_id(context.data === null ? "" : context.data.apple_id);
      setissue_id(context.data === null ? "" : context.data.issuer_id);
      setkey_id(context.data === null ? "" : context.data.key_id);
      setcertificatefilename(context.data === null ? ".p8 file supported" : context.data.key_file);
      setcertificates3name(context.data === null ? "" : context.data.key_file);
    }, [context.data])
   
    return(
      <>
        { errormodal === true && <Errormessage value={Errortext} />}
        <Modal isOpen={context.opencreationpopup}   toggle={e => context.setPopup(!context.opencreationpopup)} className="publishpopup" style={{maxWidth:"620px"}} centered dataclass="useradd">
        <ModalHeader toggle={addusertoggle} className="modal-xxl-content invite-user pb-0" >
            <p className="user_modalheading">Apple app store connection</p>
            <p>
            In your App Store Connect account, navigate to Users and Access &gt; Keys. Click on the + icon, enter a key Name, choose Admin in the Access dropdown, and hit Generate.
            </p>
        </ModalHeader>
        <ModalBody className="invite-user-body" >
        <form id="userform" onSubmit={e => connectionsubmit(e)}>
          
            <div className={styles.create_connection}>
                
                <div className="color_picker w-100 mb-3">
                    <div className="form-group">
                        {/* Tool tip */}
                            <Tooltip firsttag={"h4"} tagclass={"mb-0 mr-2"} classdata={"d-flex justify-content-start align-items-center mb-1"} heading={"Apple ID*"} content={"Enter your Apple ID. You can find this on your Profile screen."}/>
                        {/* Tool tip */}
                        <input type="text" required className="form-control form-name"  placeholder="xyz@icloud.com"  value={apple_id}  onChange={e => handleapple_id(e)}/>
                    </div>
                </div>
                <div className="color_picker w-100 mb-3">
                    <div className="form-group">
                        {/* Tool tip */}
                            <Tooltip firsttag={"h4"} tagclass={"mb-0 mr-2"} classdata={"d-flex justify-content-start align-items-center mb-1"} heading={"Issuer ID*"} content={"Copy the Issuer ID located at the top of the Keys page."}/>
                        {/* Tool tip */}
                        <input type="text" required className="form-control form-name" placeholder="720e51c1-a094-sd6e-868d-8bc890b5b68e" value={issue_id}   onChange={e => handleissuer_id(e)}/>
                    </div>
                </div>
                <div className="color_picker w-100 mb-3">
                    <div className="form-group">
                        {/* Tool tip */}
                            <Tooltip firsttag={"h4"} tagclass={"mb-0 mr-2"} classdata={"d-flex justify-content-start align-items-center mb-1"} heading={"Key ID*"} content={"Copy the Key ID of the key you just created, on the Keys page. "}/>
                        {/* Tool tip */}
                        <input type="text" required className="form-control form-name" placeholder="ZMTJM7IU5R"  value={key_id} onChange={e => handlekey_id(e)}/>
                    </div>
                </div>
                <div className="color_picker w-100 mb-3">
                    <div className="form-group">
                        {/* Tool tip */}
                            <Tooltip firsttag={"h4"} tagclass={"mb-0 mr-2"} classdata={"d-flex justify-content-start align-items-center mb-1"} heading={"Key file"} content={"Download and upload the API key. You may have to refresh your screen to see the “Download API Key” link."}/>
                        {/* Tool tip */}
                        <div className={"d-flex align-items-center justify-content-between mt-1 " + styles.uploadp8}>
                          <input
                            type="file"
                            id="certificate"
                            onChange={(e) => handlecertificate(e)}
                            style={{display:"none"}}
                           
                          />
                          <label
                            htmlFor="certificate"
                            className="notuploaded mb-0"
                          >
                          
                              <svg
                                className="mr-2"
                                style={{ marginTop: "-4px" }}
                                width="9.944"
                                height="13.214"
                                viewBox="0 0 9.944 13.214"
                              >
                                <g
                                  id="Group_2921"
                                  data-name="Group 2921"
                                  transform="translate(0.53 1.061)"
                                >
                                  <path
                                    id="Path_6311"
                                    data-name="Path 6311"
                                    d="M14161.5-3240.188v-12.1"
                                    transform="translate(-14157.059 3252.341)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeWidth="1.5"
                                  />
                                  <path
                                    id="Path_6312"
                                    data-name="Path 6312"
                                    d="M6.281,6.281V0H0"
                                    transform="translate(0 4.441) rotate(-45)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeWidth="1.5"
                                  />
                                </g>
                              </svg>
                            {certificatefilename === ".p8 file supported" ? "Upload" : "Uploaded"}
                            
                          </label>
                          
                          {certificates3name !== "" ? (<div className="text-center">
														<Media href={process.env.REACT_APP_Image_Path + localStorage.getItem("user_id") + "/" + localStorage.getItem("Appid") + "/" + process.env.REACT_APP_ios_publish_certificate + certificates3name + (certificatefilename.indexOf(".p8") !=0 ? "" : "p8") } target="_blank" rel="noopener noreferrer" tag='a' className="text-center d-block original_img">
                              <p className="file-supported" style={{color : certificatefilename === ".p8 file supported" ? "#1B2952" : "#3064F9"}}>
                              {certificates3name}  <img src={require("../../../assets/images/dashboard/Goto.png").default} className="gotoappicon authkey-spacing" alt="Goto"/>
                            </p></Media>
													</div>
													) : <p className="file-supported">
                          {certificatefilename + (certificatefilename.indexOf(".p8") !=0 ? ".p8" : "")}
                        </p>}
                          {/*<div className="d-flex justify-content-end align-items-center uploaded-file ">
                            <p className="file-supported">
                              {certificatefilename}
                            </p>
                            {/* {certificatefilename.indexOf(
                              "supported"
                            ) === -1 && (
                              <label htmlFor="certificate" className="labcur" onChange={(e) => handlecertificate(e)}>
                                <img
                                  src={require("../../../assets/images/dashboard/red-cross.png").default}
                                  alt="step"
                                  className="ml-2"
                                  width="8px"
                                  height="8px"
                                />
                              </label>
                            )} */}
                          {/*</div>*/}
                        </div>
                    </div>
                </div>
               
        </div>			
        </form>
        </ModalBody>	
        <div className="d-flex justify-content-between align-items-center cancel-user mt-4">	
        <div  className="cancel-btn mr-2 mr-md-0" onClick={e => context.setPopup(!context.opencreationpopup)}>Cancel</div>	
            <button type="submit" className="next_btn" form="userform" disabled={disabledsubmitbutton} style={{background: disabledsubmitbutton === true ? "rgb(112, 155, 250)" : "#3064F9", borderColor: disabledsubmitbutton === true ? "rgb(112, 155, 250)" : "#3064F9"}}>
                <div className="d-flex align-items-center justify-content-center h-100 w-100"> 
                   <div className={loader}><img src={loaderimage} alt="loader"/></div> <div>{submitval}</div>
                 </div>				
            </button>		
        </div>				
    </Modal> 
      </>
    )
}

export default Storeconnection