import React, {useState, useEffect} from "react";
import "./../assets/css/common.css";
import Header from "../../Dashboard/Header";
import LeftPanel from "./../LeftPanel";
import { FormGroup, Label, Input } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import {errortoast} from '../../Dashboard/Toaster';
import {
    getEmailConfiguration,
	saveEmailConfiguration,
    verifyEmailConfiguration
} from "../../Api/Agency/AgencyApi";
import NewSpinner from "./../NewSpinner";
import DomainVerifiedStrip from "./../Domains/DomainVerifiedStrip";
import emailValidator from "email-validator";
import moment from "moment";
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
} from "reactstrap";
import {
    Modal,
    ModalBody,
} from "reactstrap";


const EmailConfiguration = () =>{

    const [spinner, setSpinner] = useState(true);
    const [fromname, setFromName] = useState("");
    const [fromemail, setFromEmail] = useState("");
    const [issmtpactive, setIsSMTPActive] = useState(false);
    const [smtphost, setSMTPHost] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [connectiontype, setConnectionType] = useState("3");
    const [connectionport, setConnectionPort] = useState("25");
    const [btnloader, setBtnLoader] = useState("d-none");
    const [btnsavelabel, setBtnSaveLabel] = useState("Save");
    const [btnverifylabel, setVerifyLabel] = useState("Verify");
    const [btnverifyloader, setBtnVerifyLoader] = useState("d-none");
    const [domainstatus, setDomainStatus] = useState(false);
    const [fromemailstatus, setFromEmailStatus] = useState(false);
    const [smtpconnectionstatus, setSMTPConnectionStatus] = useState(false);
    const [isbtnverify, setIsBtnVerify] = useState(false);
    const [isadd, setIsAdd] = useState(false);
    const [errorfromname, setErrorFromName] = useState(false);
    const [errorfromemail, setErrorFromEmail] = useState('');
    const [errorsmtphost, setErrorSMTPHost] = useState(false);
    const [errorsmtpusername, setErrorSMTPUsername] = useState(false);
    const [errorsmtppassword, setErrorSMTPPassword] = useState(false);
    const [errorsmtpport, setErrorSMTPPort] = useState(false);
    const [rightpanelexpand,setRightPanelExpand] = useState(true);
    const [btnverifyactive, setBtnVerifyActive] = useState(false);
    const [lastdate,setLastDate] = useState("");
    const [openstatDropdown,setOpenStatDropdown] = useState(false);
    const [SmtpError,setSmtpError] = useState('');
    const [mdlcustomeractive, setMdlCustomerActive] = useState(false);
    

    let { agencyID } = useParams("agencyID");

    useEffect(()=>{

        if(agencyID !== null && agencyID !== undefined && agencyID !== '')
        {
            getEmailConfiguration(setSpinner, agencyID, setFromName, setFromEmail, setIsSMTPActive, setSMTPHost, setUsername, setPassword, setConnectionType, setConnectionPort, setDomainStatus, setFromEmailStatus, setSMTPConnectionStatus, setIsBtnVerify,setLastDate);
        }

        document.title = "Email | AppMySite";

        

    }, [isadd]);

    const handleFromName = (e) =>{

        setFromName(e.target.value);
        setErrorFromName(false);
    }

    const handleFromEmail = (e) =>{

        setFromEmail(e.target.value);
        setErrorFromEmail('');
    }

    const handleStatdropdown = (rightpanelvalue) => {
        setOpenStatDropdown(!openstatDropdown);
    }

    const handleSMTPStatus = (e) =>{

        if(e.target.checked)
        {

            setIsSMTPActive(true);
            
        }
        else
        {

            setIsSMTPActive(false);

        }
    }

    const handleServerName = (e) =>{

        setSMTPHost(e.target.value);
        setErrorSMTPHost(false);
    }

    const handleSMTPUsername = (e) =>{

        setUsername(e.target.value);
        setErrorSMTPUsername(false);
    }

    const handleSMTPPassword = (e) =>{

        setPassword(e.target.value);
        setErrorSMTPPassword(false);
    }

    const handleConnectionType = (e, data) =>{

        if(data === 3)
        {
            setConnectionType(data);
            setConnectionPort(25);
        }
        else if(data === 1)
        {
            setConnectionType(data);
            setConnectionPort(465);
        }
        else if(data === 2)
        {
            setConnectionType(data);
            setConnectionPort(587);
        }
        
    }

    const handleConnectionPort = (value) =>{

        setConnectionPort(value);
        setErrorSMTPPort(false);
    }

    const handleBtnVerify = () =>{        

        
        if(fromemail != '' && fromemail != null && !emailValidator.validate(fromemail.trim()))
        {
            setErrorFromEmail('Enter valid From address!');
            return;
        }

        
            if(smtphost === '' || smtphost === null)
            {
                errortoast('Required field.');
                setErrorSMTPHost(true);
                return;
            }
            else if(username === '' || username === null)
            {
                setErrorSMTPUsername(true);
                errortoast('Required field.');
                return;
            }
            else if(password === '' || password === null)
            {
                errortoast('Required field.');
                setErrorSMTPPassword(true);
                return;
            }
            else if(connectionport === '' || connectionport === null)
            {
                errortoast('Required field.');
                setErrorSMTPPort(true);
                return;
            }
        
            const params = {
                            agency_id: atob(agencyID),
                            name: fromname,
                            address: fromemail,
                            username: username,
                            password: btoa(password.replaceAll(/\s/g,'')),
                            connection_type: connectiontype,
                            host: smtphost,
                            port: connectionport,
                            smtpstatus: 1
                        };

            setVerifyLabel("");
            setBtnVerifyLoader("d-block");

            let verifyemailbtndiv = document.getElementById("btnverifyemailid");
            verifyemailbtndiv.classList.add("btnloader");

            setSmtpError('');
            verifyEmailConfiguration(params, setVerifyLabel, setBtnVerifyLoader, setFromEmailStatus, setSMTPConnectionStatus, setIsAdd, setLastDate,isadd,setSmtpError);
        
    }

    const handleBtnSave = (e) =>{

        let smtpenable = "";
        let errorbool = false;
        
            if(fromemail != '' && fromemail != null && !emailValidator.validate(fromemail.trim()))
            {
             
                //errortoast('Enter valid From address!');
                errorbool = true;
                setErrorFromEmail('Enter valid From address!');
                return;
            }
           
        if(errorbool === false)
        {

            const params = {
                        agency_id: parseInt(atob(agencyID)),
                        from_name: fromname,
                        from_email: fromemail,
                        is_smtp_enabled: smtpenable,
                        smtp_username: username,
                        smtp_password: btoa(password.replaceAll(/\s/g,'')),
                        smtp_host: smtphost,
                        smtp_port: connectionport,
                        connection_type:  connectiontype
                        };

            setBtnSaveLabel("");
            setBtnLoader("d-block");
     
            
            let saveemailbtndiv = document.getElementById("btnsaveemailid");
            saveemailbtndiv.classList.add("btnloader");
            let saveemailbtndiv1 = document.getElementById("btnsaveemailid1");
            saveemailbtndiv1.classList.add("btnloader");
            

            saveEmailConfiguration(params, setBtnSaveLabel, setBtnLoader, setIsBtnVerify,setLastDate,setFromEmailStatus, setSMTPConnectionStatus);
        }
    }

    
    const handleRightPanelExpand = (rightpanelvalue) => {
        setRightPanelExpand(rightpanelvalue);
    }

    const handlesmtperror  = (smtperror) =>
    {
        setMdlCustomerActive(true);
    }

    const btnCancelCustomerActivePopup = () =>{
        setMdlCustomerActive(false);
    }

    
    return(
    <div className="org-module">
        <Header />
        <div className="org-structure">
            <LeftPanel rightpanelbody={handleRightPanelExpand}/>         
            <div className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined &&
            localStorage.getItem('sidebaserotate') === 'true') ? "org_background org_background-expand"
            : "org_background "}>

                
                        <div className="org-email-head">
                            <div>  
                                <div className="agency-headings">
                                    <h1>Email</h1>
                                    <p class="agency-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                    <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                        <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                        <circle cx="9" cy="9" r="9" stroke="none"/>
                                        <circle cx="9" cy="9" r="8.25" fill="none"/>
                                        </g>
                                        <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                                        <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                    </g>
                                    </svg><p class="agency-tooltipsubheader">Send emails from your domain and email server.</p></p>
                                </div>
                            </div>
                            { spinner ? <></>
                            :
                                <div className="agency-web-timestamp-main-div">

                                <h5 className="agency-web-timestamp">
                                    {lastdate ?
                                        'Updated ' + moment(lastdate).format("D MMMM YYYY, HH:mm")
                                    : ''
                                    }    
                                </h5>
                                    
                                { (fromname !== '' && fromname !== null && fromname !== undefined) || (fromemail !== '' && fromemail !== null && fromemail !== undefined) ?
                                    <div className="save-org-email" onClick={handleBtnSave} id="btnsaveemailid">
                                        <div className={btnloader}>
                                            <div className="new-blue-loader"></div>
                                        </div>
                                        { btnsavelabel }
                                        
                                    </div>
                                :
                                    <div className="save-org-email btndisable-blank">
                                        { btnsavelabel }
                                    </div>
                                }

                                { ((fromname !== '' && fromname !== null && fromname !== undefined) && (fromemail !== '' && fromemail !== null && fromemail !== undefined) && (smtphost !== '' && smtphost !== null && smtphost !== undefined) && (username !== '' && username !== null && username !== undefined) && (password !== '' && password !== null && password !== undefined)) ?
                                
                                    <div className="verify-org-email" onClick={handleBtnVerify} id="btnverifyemailid">
                                        <div className={btnverifyloader}>
                                            <div className="new-loader"></div>
                                        </div>
                                        
                                        { btnverifylabel }
                                    </div>
                                :
                                    <div className="verify-org-email btndisable" id="btnverifyemailid">
                                        <div className={btnverifyloader}>
                                            <div className="new-loader"></div>
                                        </div>
                                        
                                        { btnverifylabel }
                                    </div>
                                }
                                    
                                </div>
                            }
                        </div>
                    
                        { spinner ?
                            <NewSpinner />
                        :
                        <>
                            <div className="org-module-shadow">
                                
                                <div className="org-email-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="org-email-left">
                                                <div className="org-email-settings">

                                                    <h1>1.  Add email address</h1>
                                                    <h2>Provide a name and email address your customers will receive emails from. </h2>

                                                    <div className="agency-label-tooltips">
                                                    <h4>From name</h4>
                                                    <p class="agency-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                    <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                                        <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                                        <circle cx="7" cy="7" r="7" stroke="none"/>
                                                        <circle cx="7" cy="7" r="6.4" fill="none"/>
                                                        </g>
                                                        <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                                        <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                                    </g>
                                                    </svg>
                                                    <p class="agency-tooltipsubheader">This will appear as the sender’s name on all emails sent.</p></p>
                                                    </div>


                                                    {errorfromname ?
                                                        <input type="text" name="from_name" id="from_name" value={fromname || ''} onChange={handleFromName}  classname="errormessages" />
                                                    :
                                                        <input type="text" name="from_name" id="from_name" value={fromname || ''} onChange={handleFromName} />
                                                    }

                                                    <div className="agency-label-tooltips">
                                                    <h4>From address</h4>
                                                    <p class="agency-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                                                    <g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)">
                                                        <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2">
                                                        <circle cx="7" cy="7" r="7" stroke="none"/>
                                                        <circle cx="7" cy="7" r="6.4" fill="none"/>
                                                        </g>
                                                        <path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                                        <line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"/>
                                                    </g>
                                                    </svg>
                                                    <p class="agency-tooltipsubheader">This will appear as the sender’s email on all emails sent.</p></p>
                                                    </div>


                                                    <div className={fromemailstatus ? 'email-form-address' : errorfromemail ? "email-form-address unverified-domain" : (fromemail !== undefined && fromemail !== null && fromemail !== '') ? "email-form-address unverified-domain" : "email-form-address"}>
                                                        
                                                        <input type="email" name="from_email" id="from_email" value={fromemail || ''} onChange={handleFromEmail}  className={ errorfromemail ? "errormessages" :""} />
                                                        

                                                        { (fromemail !== undefined && fromemail !== null && fromemail !== '') && 
                                                            (fromemailstatus  ?                                                    
                                                                <svg id="Group_27505" data-name="Group 27505" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                                                                    <circle id="Ellipse_2306" data-name="Ellipse 2306" cx="9" cy="9" r="9" fill="#47ce70"/>
                                                                    <path id="Path_7161" data-name="Path 7161" d="M-19252.924-4211.877l3.17,3.169,5.965-5.966" transform="translate(19257.359 4220.691)" fill="none" stroke="#fff" stroke-width="2"/>
                                                                </svg>
                                                            :
                                                                <p>Unverified</p>
                                                            )
                                                        }

                                                    </div>
                                                    {errorfromemail ?
                                                            <span className="agencyerror"><svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"></path></svg>{errorfromemail}</span>
                                                    : ''
                                                    }

                                                </div>                                            
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="org-email-right">
                                                <div className="emailpreview">
                                                    <p>
                                                        
                                                        { (fromname !== '' && fromname !== null && fromname !== undefined) ?
                                                            fromname
                                                        :
                                                            ( "example support" )
                                                        }
                                                        <span>&nbsp;&nbsp;&#60;</span>
                                                        { fromemail !== '' && fromemail !== null && fromemail !== undefined ?
                                                            <span>{fromemail}</span>
                                                        :
                                                            "support@example.com"
                                                        }
                                                        <span>&#62;</span>
                                                    </p>
                                                    <img src={require("../assets/images/emailpreview.png").default} alt="email-preview" className="img-fluid"/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                    
                            </div>
                        
                            <div className="org-module-shadow mt-20">

                            <div className="org-email-body">

                            <div className="smtpcredentials-head">

                                <div className="org-email-settings">

                                    <h1>2. Provide SMTP credentials</h1>
                                    <h2>Provide SMTP credentials to send emails from your own email server.</h2>

                                </div>

                                <div>
                                { smtpconnectionstatus  ?                                                    
                                    <p className="customerstatusgreen">Verified</p>
                                :
                                    <>
                                        <p className="customerstatusred">Unverified
                                        <>
                                            {SmtpError != '' ?
                                                <span className="smtp-view-error-btn" onClick={() => handlesmtperror(SmtpError)}>View error</span>
                                            : <></>
                                            }
                                        </>
                                        </p>
                                    </>
                                }

                                
                                </div>

                            </div>

                            <div className="smtpcredentials">
                                <div className="row">

                                        <div className="col-md-6">
                                            <div className="org-email-left">
                                                
                                            <div>
                                                <h4>Server name</h4>
                                                {errorsmtphost ?
                                                    <input type="text" name="server_name" id="server_name" value={smtphost || ''} onChange={handleServerName}  className="errormessages" />
                                                :
                                                    <input type="text" name="server_name" id="server_name" value={smtphost || ''} onChange={handleServerName} />
                                                }

                                                <h4>SMTP password</h4>
                                                {errorsmtppassword ?
                                                    <input type="password" name="smtppassword" id="smtppassword" value={password || ''} onChange={handleSMTPPassword} className="errormessages" />
                                                :
                                                    <input type="password" name="smtppassword" id="smtppassword" value={password || ''} onChange={handleSMTPPassword} />
                                                }

                                            </div>
                                                                                                
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                        <h4>SMTP username</h4>
                                            {errorsmtpusername ?
                                                <input type="text" name="smtpusername" id="smtpusername" value={username || ''} onChange={handleSMTPUsername}  className="errormessages" />
                                            :
                                                <input type="text" name="smtpusername" id="smtpusername" value={username || ''} onChange={handleSMTPUsername} />
                                            }

                                            

                                            <div className="row">

                                                <div className="col-md-9">
                                                <h4>Connect type</h4>
                                                    <FormGroup tag="fieldset" className="select-img-ratio">
                                                        <FormGroup>
                                                            <Label className="img-ratio">
                                                                <Input type="radio" name="connectiontype" onChange={(e)=>handleConnectionType(e, 3)} value="plain" checked={ connectiontype == 3 ? true: ""} />
                                                                <div className="image">
                                                                    <p>Plain</p>
                                                                </div>
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label className="img-ratio">
                                                                <Input type="radio" name="connectiontype" onChange={(e)=>handleConnectionType(e, 1)} value="ssl"  checked={connectiontype === 1 ? true: ""} />
                                                                <div className="image">
                                                                    <p>SSL</p>
                                                                </div>
                                                            </Label>
                                                        </FormGroup>
                                                        <FormGroup>
                                                            <Label className="img-ratio" value="LLL">
                                                                <Input type="radio" name="connectiontype" onChange={(e)=>handleConnectionType(e, 2)} value="tls" checked={connectiontype === 2 ? true: ""} />
                                                                <div className="image">
                                                                    <p>TLS</p>
                                                                </div>
                                                            </Label>
                                                        </FormGroup>
                                                    </FormGroup>
                                                </div>
                                                
                                                <div className="col-md-3">
                                                
                                                <h4>Port</h4>
                                                <div className="search-dropdown">
                                                    {/* {errorsmtpport ?
                                                        <select name="connectionport" id="connectionport" onChange={handleConnectionPort} value={5} className="errormessages"  disabled>
                                                            
                                                            <option value="25">25</option>
                                                            <option value="2525">2525</option>
                                                            <option value="465">465</option>
                                                            <option value="587">587</option>
                                                        </select>
                                                    : */}
                                                        {/* <select name="connectionport" id="connectionport" onChange={handleConnectionPort} value={connectionport}>
                                                            <option value="25">25</option>
                                                            <option value="2525">2525</option>
                                                            <option value="465">465</option>
                                                            <option value="587">587</option>
                                                        </select> */}
                                                        <Dropdown isOpen={window.matchMedia("(max-width: 1200px)").matches?false : openstatDropdown}
                                                            toggle={handleStatdropdown}
                                                        >
                                                            <DropdownToggle caret>
                                                                {connectionport}
                                                            </DropdownToggle>
                                                            <DropdownMenu >
                                                                    
                                                                <DropdownItem onClick={() => handleConnectionPort("25")}>25</DropdownItem> 
                                                                <DropdownItem onClick={() => handleConnectionPort("2525")}>2525</DropdownItem> 
                                                                <DropdownItem onClick={() => handleConnectionPort("465")}>465</DropdownItem>
                                                                <DropdownItem onClick={() => handleConnectionPort("587")}>587</DropdownItem> 
                                                                
                                                            </DropdownMenu>
                                                        </Dropdown>
                                                    {/* } */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        {/* {SmtpError != '' ?
                            <p style={{color:"red"}}>{SmtpError}</p>
                        : <></>
                        } */}
                                </div>
                            </div>

                            <div className="agency-mobile-btns">

                                

                                { (fromname !== '' && fromname !== null && fromname !== undefined) || (fromemail !== '' && fromemail !== null && fromemail !== undefined) ?
                                    <div className="agency-mobile-btns-blank" onClick={handleBtnSave} id="btnsaveemailid1">
                                        <div className={btnloader}>
                                            <div className="new-grey-loader"></div>
                                        </div>
                                        { btnsavelabel }
                                        
                                    </div>
                                :
                                    <div  className="agency-mobile-btns-blank btndisable-blank">
                                        { btnsavelabel }
                                        
                                    </div>
                                }


                                { ((fromname !== '' && fromname !== null && fromname !== undefined) && (fromemail !== '' && fromemail !== null && fromemail !== undefined) && (smtphost !== '' && smtphost !== null && smtphost !== undefined) && (username !== '' && username !== null && username !== undefined) && (password !== '' && password !== null && password !== undefined)) ?

                                    <div className="agency-mobile-btns-blue" onClick={handleBtnVerify} id="btnverifyemailid">
                                        <div className={btnverifyloader}>
                                            <div className="new-loader"></div>
                                        </div>
                                        
                                        { btnverifylabel }
                                    </div>
                                :
                                    <div className="agency-mobile-btns-blue btndisable" id="btnverifyemailid">
                                        <div className={btnverifyloader}>
                                            <div className="new-loader"></div>
                                        </div>
                                        
                                        { btnverifylabel }
                                    </div>
                                } 

                                <h5>
                                {lastdate ?
                                'Updated ' + moment(lastdate).format("D MMMM YYYY, HH:mm") 
                                : ''
                                }
                                </h5>
                                </div>

                                <div className="domain-steps">

                                <div className="setup-domain">
                                <img src={require("../assets/images/emailaddress.png").default} alt="emailaddress" className="img-fluid"/>
                                <div>
                                <p>Step 1</p>
                                <h3>Add email address</h3>
                                </div>
                                </div>

                                <div className="setup-domain">
                                <img src={require("../assets/images/smtpcredentials.png").default} alt="smtpcredentials" className="img-fluid"/>
                                <div>
                                <p>Step 2</p>
                                <h3>Provide SMTP credentials</h3>
                                </div>
                                </div>

                                <div className="setup-domain">
                                <img src={require("../assets/images/emailsmtp.png").default} alt="emailsmtp" className="img-fluid"/>
                                <div>
                                <p>Step 3</p>
                                <h3>Verify email & SMTP</h3>
                                </div>
                                </div>

                                </div>
                        </>
                        }

                       
                    
            </div>
        </div>
                        <Modal isOpen={mdlcustomeractive} style={{ maxWidth: "480px", maxHeight: "270px" }} className="activate-customer-popup smtperror-popup" centered >
                                <ModalBody>
                                    <div className="crossmodal mt-1" onClick={btnCancelCustomerActivePopup}>
                                        <img
                                            src={require("../assets/images/cross.png").default}
                                        />
                                    </div>
                                    <div>
                                        <div className="confirm-org-body">
                                            <h4><svg style={{position: "relative" , top: "-2px"}} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                                <g id="Group_41408" data-name="Group 41408" transform="translate(-324.314 -455.386)">
                                                    <g id="Ellipse_2829" data-name="Ellipse 2829" transform="translate(324.314 455.386)" fill="#fff1f1" stroke="#ff4848" stroke-width="1">
                                                    <circle cx="8" cy="8" r="8" stroke="none"/>
                                                    <circle cx="8" cy="8" r="7.5" fill="none"/>
                                                    </g>
                                                    <path id="Path_8311" data-name="Path 8311" d="M0,3.438,3.438,0" transform="translate(330.187 461.902) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1"/>
                                                    <path id="Path_10113" data-name="Path 10113" d="M0,1.118,1.117,0" transform="translate(331.826 466.634) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1"/>
                                                </g>
                                                </svg> SMTP error details
                                            </h4>
                                            <h6>{SmtpError}</h6>
                                        </div>


                                        <div className="deactivate-customer-footer">

                                            <div className="customer-activate-btn" onClick={btnCancelCustomerActivePopup}  id="btncustomrtactiveid">
                                               Okay
                                            </div>

                                        </div>

                                    </div>
                                </ModalBody>
                            </Modal>
    </div>
    );

}

export default EmailConfiguration;