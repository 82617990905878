import React, { useCallback } from "react";
import { Color, Solver } from "../../BottomBar/Iconcolor";
import { languagesArray } from "../../../utils/Languages";

const MergeAppSidePreview = ({ mergeState }) => {
  const { settings } = mergeState;
  const hexToRgb = (hex) => {
    if (hex !== undefined) {
      const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, (m, r, g, b) => {
        return r + r + g + g + b + b;
      });
      const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? [
            parseInt(result[1], 16),
            parseInt(result[2], 16),
            parseInt(result[3], 16),
          ]
        : null;
    }
  };

  const LocationMarker = ({ markerType }) => {
    return (
      <div className="d-flex align-items-center">
        {settings.locationMarkerIcon !== "default" ? (
          <img
            style={{
              filter:
                settings.iconFilter &&
                getFilter(
                  markerType === "selected"
                    ? settings.selectedMarkerColor
                    : settings.markerColor
                ),
              width: "25px",
            }}
            src={
              process.env.REACT_APP_Image_Path +
              localStorage.getItem("user_id") +
              "/" +
              localStorage.getItem("Appid") +
              process.env.REACT_APP_NEW_ICON_PATH +
              settings.locationMarkerIcon
            }
            alt="location-icon"
            onError={({ currentTarget }) => {
              currentTarget.outerHTML = `<svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="26"
            viewBox="0 0 20 26"
          >
            <path
              id="Subtraction_317"
              data-name="Subtraction 317"
              d="M10,26h0c-.006,0-.643-.443-1.562-1.215A31.615,31.615,0,0,1,5,21.42a23.187,23.187,0,0,1-3.437-5.093A14.679,14.679,0,0,1,0,9.927a9.86,9.86,0,0,1,2.929-7.02,10.039,10.039,0,0,1,14.142,0A9.86,9.86,0,0,1,20,9.927a14.139,14.139,0,0,1-1.562,6.2A24.638,24.638,0,0,1,15,21.243,33.715,33.715,0,0,1,10,26ZM10,6.229a3.792,3.792,0,1,0,3.784,3.792A3.792,3.792,0,0,0,10,6.229Z"
              fill={
                markerType === "selected"
                  ? settings.selectedMarkerColor
                  : settings.markerColor
              }
              opacity="0.5"
            />
          </svg>`;
            }}
          />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="26"
            viewBox="0 0 20 26"
          >
            <path
              id="Subtraction_317"
              data-name="Subtraction 317"
              d="M10,26h0c-.006,0-.643-.443-1.562-1.215A31.615,31.615,0,0,1,5,21.42a23.187,23.187,0,0,1-3.437-5.093A14.679,14.679,0,0,1,0,9.927a9.86,9.86,0,0,1,2.929-7.02,10.039,10.039,0,0,1,14.142,0A9.86,9.86,0,0,1,20,9.927a14.139,14.139,0,0,1-1.562,6.2A24.638,24.638,0,0,1,15,21.243,33.715,33.715,0,0,1,10,26ZM10,6.229a3.792,3.792,0,1,0,3.784,3.792A3.792,3.792,0,0,0,10,6.229Z"
              fill={
                settings.iconFilter
                  ? {
                      color:
                        markerType === "selected"
                          ? settings.selectedMarkerColor
                          : settings.markerColor,
                    }
                  : {}
              }
              opacity="0.5"
            />
          </svg>
        )}
        <div
          style={
            settings.iconFilter
              ? {
                  color:
                    markerType === "selected"
                      ? settings.selectedMarkerColor
                      : settings.markerColor,
                }
              : {
                  color: markerType === "selected" ? "#FF4848" : "#838383",
                }
          }
        >
          <p>App name</p>
          <p>Australia</p>
        </div>
      </div>
    );
  };

  const getFilter = useCallback(
    (colorValue) => {
      if (colorValue) {
        const [r, g, b, a] = colorValue.includes("rgb")
          ? colorValue.match(/\d+/g).map(Number)
          : hexToRgb(colorValue);
        const color = new Color(r, g, b);
        const solver = new Solver(color);
        const filter = solver.solve()?.filter;
        return filter;
      }
    },
    [settings.markerColor, settings.selectedMarkerColor]
  );

  return (
    <div className="newmergeapp-list-preview-div">
      {settings.title && <h1>{settings.title}</h1>}
      {settings.showLocations && (
        <div style={{ position: "relative" }}>
          <div className="merged-app-location">
            <div className="app-location-zoom">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
              >
                <g
                  id="Group_43031"
                  data-name="Group 43031"
                  transform="translate(0.901 1.331)"
                >
                  <g
                    id="Group_42925"
                    data-name="Group 42925"
                    transform="translate(0 0)"
                  >
                    <circle
                      id="Ellipse_9483"
                      data-name="Ellipse 9483"
                      cx="9"
                      cy="9"
                      r="9"
                      transform="translate(1.099 0.669)"
                      fill="rgba(0,0,0,0)"
                      stroke="#6f6f6f"
                      stroke-width="1.2"
                    />
                    <circle
                      id="Ellipse_9484"
                      data-name="Ellipse 9484"
                      cx="3"
                      cy="3"
                      r="3"
                      transform="translate(7.099 6.684)"
                      fill="rgba(0,0,0,0)"
                      stroke="#6f6f6f"
                      stroke-width="1.2"
                    />
                    <line
                      id="Line_446"
                      data-name="Line 446"
                      y2="4"
                      transform="translate(10.099 -1.331)"
                      fill="none"
                      stroke="#6f6f6f"
                      stroke-width="1.2"
                    />
                    <line
                      id="Line_526"
                      data-name="Line 526"
                      y2="4"
                      transform="translate(10.099 16.669)"
                      fill="none"
                      stroke="#6f6f6f"
                      stroke-width="1.2"
                    />
                    <line
                      id="Line_527"
                      data-name="Line 527"
                      y2="4"
                      transform="translate(21.099 9.669) rotate(90)"
                      fill="none"
                      stroke="#6f6f6f"
                      stroke-width="1.2"
                    />
                    <line
                      id="Line_528"
                      data-name="Line 528"
                      y2="4"
                      transform="translate(3.099 9.669) rotate(90)"
                      fill="none"
                      stroke="#6f6f6f"
                      stroke-width="1.2"
                    />
                  </g>
                </g>
              </svg>
            </div>
          </div>
          <div className="firstapp-location">
            <LocationMarker />
          </div>

          <div className="secondapp-location ">
            <LocationMarker />
          </div>
          <div className="thirdapp-location selected-app">
            <LocationMarker markerType={"selected"} />
          </div>

          <div className="current-location">
            <img
              src={
                require("../../../assets/images/dashboard/currentlocation.png")
                  .default
              }
              alt="publishUpgrade-image"
            />
          </div>
        </div>
      )}
      {/* Merged app first layout */}
      <div>
        {mergeState.tableData?.length > 1 &&
          [
            mergeState.tableData.find(
              (item) =>
                parseInt(localStorage.getItem("Appid")) === item.child_app_id
            ),
            ...mergeState.tableData.filter(
              (item) =>
                parseInt(localStorage.getItem("Appid")) !== item.child_app_id
            ),
          ].map((app, idx) => (
            app && <div
              key={`previewlist_${idx}`}
              className={`maerged-app-first-layout d-flex gy-2 ${
                mergeState.selectedLayout === "layout-0"
                  ? ""
                  : "justify-content-between flex-row-reverse"
              } `}
            >
              <img
                src={
                  settings.showAppIcon
                    ? process.env.REACT_APP_Image_Path +
                      app.user_id +
                      "/" +
                      app.child_app_id +
                      process.env.REACT_APP_AppIcon_Display +
                      app.image
                    : process.env.REACT_APP_Image_Path +
                      process.env.REACT_APP_100_FLAG +
                      app.country_iso.toLowerCase() +
                      ".png"
                }
                onError={({ currentTarget }) => {
                  currentTarget.src = settings.showAppIcon
                    ? require("../../../assets/images/dashboard/the-icon.png")
                        .default
                    : process.env.REACT_APP_Image_Path +
                      process.env.REACT_APP_100_FLAG +
                      "us.png";
                }}
                className={`${
                  mergeState.selectedLayout === "layout-0"
                    ? "merged-app-icon"
                    : "merged-app-icon mr-0"
                } `}
              ></img>
              <div className="app-location-distance">
                <div className="merged-app-details ">
                  {settings.showAppName && <h2>{app.app_name}</h2>}
                  {settings.showWebsiteLink && (
                    <p className="merged-app-url">{app.app_url}</p>
                  )}
                  {settings.showCountryName && (
                    <p className="merged-app-country">{app.country_name}</p>
                  )}
                  {/* {settings.showLanguage && (
                    <p className="merged-app-language">
                      {languagesArray.find((e) => e.code === app.language)
                        ?.name || "English"}
                    </p>
                  )} */}
                </div>
                {settings.showDistance && settings.showLocations && (
                  <p className="location-distance"> 200m</p>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default MergeAppSidePreview;
