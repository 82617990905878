import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { formatedDateTime } from '../../../utils/commonUtils';

const SupportHeader = ({planName,planDesc,expireOn,downgradeAtTermEnd}) => {
  return (
    <div className="agency-dashboard-support-header">
    <div className="agency-dashboard-support-header-left">
        <h4 className="agency-dashboard-support-header-heading">{planName}<span className="agency-dashboard-support-plan-name">Support plan</span></h4>
        <h6 className="agency-dashboard-support-header-subheading">{planDesc}</h6>
    </div>

    <div className="agency-dashboard-support-header-right">
        { downgradeAtTermEnd ? 
           <p className="agency-dashboard-time">{expireOn ? "Cancellation on": "Downgrade scheduled"} {formatedDateTime(expireOn ,"D MMMM YYYY")}</p> :
          null
        }
        <Link to={"/support-pricing"} className="agency-dashboard-support-header-button">Upgrade</Link>
    </div>
</div>
  )
}

export default SupportHeader