import React, { useState } from 'react';
import Header from '../Header';
import Leftpanel from '../Leftpanel';
import { Error } from './errormodal';
import {Errorlog} from './errorlog'
import "./errorLogs.css"
import "./Emptylogs.css"
const EmptyCLogs = () => {

    const [appRightPanelExpand, setAppRightPanelExpand] = useState( localStorage.getItem("appSideBarCollapse") === true ||
	localStorage.getItem("appSideBarCollapse") === "true"
	  ? true
	  : false);
    const handleRightPanelExpand = (value) => {

		setAppRightPanelExpand(value);
	  }

    return(
		<>

        <div className="d-md-block">
            <Header />
        </div>
        {/*Mobile View*/}
        {/*Mobile View End*/}
        <section className="dasboard_page mobile-display mt-0">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
		<div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>
				<div className="right_panel custon_gap2 pb-4" style={{minHeight:"97%"}}>
				    <div className="right_general" style={{minHeight:"350px"}}>
                    	<div className="d-md-flex align-items-center justify-content-between border-bottom pt-md-4 pb-md-3 submenu">
                     		<div className="app_iconname minor">
								<div className="d-flex align-items-center">
									<h3>Connectivity Logs</h3>
									{/* <Amsvideo timespan = {572} addclass = "d-md-flex d-none align-items-center justify-content-between see_video" /> */}
								</div>
								<h5>Everything you need to know about your account can be found here.  </h5>
							</div>
						</div>		
						<div className="d-flex flex-column align-items-center justify-content-center mx-auto errorsection">
            <img className="logimage" src={require("../../assets/images/dashboard/logs/nologs.png").default} />
            <p className="logtext">No connectivity logs to show</p>
            <p className="log-subtext">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut</p>
            </div>		
						</div>
					</div>
				</div>
            </section>
            </>
    )
}

export default EmptyCLogs