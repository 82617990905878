import React from "react";
import { Modal, ModalBody } from "reactstrap";
const CancellationModal = ({
  openPopUp,
  handlePopUp,
  handleDoneBtn,
  addonToBeRemoved,
  addonPriceToBeRemoved,
  addonDurationToBeRemoved,
  isLoader,
  addonsState,
}) => {
  return (
    <Modal
      isOpen={openPopUp}
      centered
      className="delete-popup addons-delete-popup"
      toggle={(e) => handlePopUp(e)}
      dataclassName="addonpopup"
    >
      <ModalBody className="">
        <div className="crossmodal mt-1" onClick={(e) => handlePopUp(e)}>
          <img
            src={require("../../../assets/images/dashboard/cross.png").default}
            alt="cross"
          />
        </div>
        <div
          style={{
            padding: window.matchMedia("(max-width: 1200px)").matches
              ? "15px"
              : "",
          }}
        >
          <h5>Confirm add-on cancellation?</h5>
          {addonsState.isLegacyLogic ? (
            <h6>
              Are you sure you want to cancel this add-on subscription?
              Cancellation will take effect immediately.
            </h6>
          ) : (
            <h6>
              Are you sure you want to cancel this add-on subscription? Your
              current plan will remain active until the end of your billing
              cycle.
            </h6>
          )}

          <div className="d-flex align-items-center justify-content-center cms-delete-popup-footer">
            <div
              className="d-none d-xl-block grey-btn"
              onClick={(e) => handlePopUp(e)}
            >
              <p>Back</p>
            </div>

            <div
              onClick={(e) =>
                handleDoneBtn(
                  addonToBeRemoved,
                  addonPriceToBeRemoved,
                  addonDurationToBeRemoved
                )
              }
            >
              <div className="d-flex align-items-center justify-content-center h-100 w-100 red-btn">
                <p className="addon-delete-btn">
                  <div className={isLoader}>
                    <div className="new-red-loader"></div>
                  </div>
                  <div>{isLoader === "loader" ? "" : "Confirm"}</div>
                </p>
              </div>
            </div>

            <div
              className="d-block d-xl-none grey-btn"
              onClick={(e) => handlePopUp(e)}
            >
              <p className="addon-back-btn">Back</p>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CancellationModal;
