import React from "react";
import { Link } from "react-router-dom";
import SkipBtn from "../../Components/commonIcons/SkipBtn";
export const UpgradeNotification = ({reseller_package_id,plan_code,handleSkipClick}) => {
  	return (
		<div className="">
			<div className="pushnotification-platform osnotification-upgrade-page">
				<div className="pushnot-upgradetext richpushnot-upgradetext">
					<div >
						<h3 className="">Advanced notifications</h3>
						<p>
							{" "}
							Captivate your audience and elevate user engagement with advanced notifications.{" "}
						</p>
						<ul>
							<li>Boost user interaction with visually enriched notifications.</li>
							<li>
								{/* Separately dispatch notifications to iOS &amp; Android app users.{" "} */}
								Enhance user experience with images that complement your content.
							</li>
							<li>Include links in your notification for a hassle-free user journey.</li>
							<li>Review the performance of your notifications with detailed analytics.</li>
						</ul>
						{/* <Link to={ localStorage.getItem("workspaceId") !== "" && localStorage.getItem("workspaceId") !== undefined && localStorage.getItem("workspaceId") !== "undefined" && localStorage.getItem("workspaceId") !== null ? "/pricing/" + btoa(localStorage.getItem("workspaceId")) : atob(localStorage.getItem("plan_code")) === process.env.REACT_APP_Lifetime_preview ? "/deals"
						: "/subscription/" + btoa(localStorage.getItem("Appid")) } className="next_btn d-block" >
						Upgrade Plan
						</Link> */}
						<div className="upgrade-skip-notification">
						{ (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ? 
							<Link to={ localStorage.getItem("workspaceId") !== "" && localStorage.getItem("workspaceId") !== undefined && localStorage.getItem("workspaceId") !== "undefined" && localStorage.getItem("workspaceId") !== null ? 
							`/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}` : 
							// atob(localStorage.getItem("plan_code")) === process.env.REACT_APP_Lifetime_preview ? "/deals" 
							 ( reseller_package_id || (plan_code && plan_code.includes("appsumo") ))?"/app/addons"
							: parseInt(localStorage.getItem('website_technology')) === 4 ? 
							 "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
							: parseInt(localStorage.getItem('website_technology')) === 5 ? 
							 "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
							: parseInt(localStorage.getItem('website_technology')) === 3 ? 
							 "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
							: parseInt(localStorage.getItem('website_technology')) === 2 ? 
							  "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
							: 
							  "/app/pricing"} className="notification_upgradebtn" >
							{
                                ( reseller_package_id || (plan_code && plan_code.includes("appsumo") ))?"Buy addon":"Upgrade"
                              }
						</Link>
						: 
							null
						}
						<div className="skip-btn-head" onClick={handleSkipClick}>
						<SkipBtn/>
						</div>
						</div>
						
					</div>
				</div>
				<div className="w-100 pushnot-upgradeimg push-desktopview richpushnot-upgradeimg">
					<img src={ require("../../assets/images/dashboard/osnotificationupgrade.png").default } alt="addmobpre" className="img-fluid" />
				</div>
				<div className=" richpushnot-mobupgradeimg">
					<img src={ require("../../assets/images/dashboard/osnotificationupgrade.png").default } alt="addmobpre" className="img-fluid " />
				</div>
			</div>
			{/* 
			<div className="">
				<div className="w-100 text-center pt-md-5 pt-3">
					<img src={ require("../../assets/images/dashboard/updatenotification.png").default } alt="addmobpre" className="img-fluid mb-3 mb-md-0" />
				</div>
			</div>
			*/}
		</div>
  	);
};
