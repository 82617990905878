import React from "react";

const HMSWebView = ({
  homescreenData,
  setHomescreenData,
  blockIndx,
  handleStatus,
  handleIsRowOpen,
  isRowOpen,
  handleDeleteItem,
  eachBlock,
  handleDuplicateItem,

  provided,
}) => {
  const handleWebViewUrl = (e) => {
    let newArr = [...homescreenData];
    newArr[blockIndx].value.web_view_url = e.target.value.replace(
      /^https?:\/\//i,
      ""
    );

    setHomescreenData(newArr);
  };
  // const handleWebsiteHeader = (e) => {
  //   let newArr = [...homescreenData];
  //   newArr[blockIndx].value.show_website_header = e.target.checked ? 1 : 0;

  //   setHomescreenData(newArr);
  // };

  // const handleWebsiteFooter = (e) => {
  //   let newArr = [...homescreenData];
  //   newArr[blockIndx].value.show_website_footer = e.target.checked ? 1 : 0;

  //   setHomescreenData(newArr);
  // };
  // const handleHideHTMLByClass = (e) => {
  //   let newArr = [...homescreenData];
  //   newArr[blockIndx].value.hide_html_by_class = e.target.value.replace(
  //     /[ ,]+/g,
  //     ","
  //   );

  //   setHomescreenData(newArr);
  // };
  // const handleHideHTMLById = (e) => {
  //   let newArr = [...homescreenData];
  //   newArr[blockIndx].value.hide_html_by_id = e.target.value.replace(
  //     /[ ,]+/g,
  //     ","
  //   );

  //   setHomescreenData(newArr);
  // };

  return (
    <div className="accordionItem ">
      <h2
        className="accordionItemHeading allflex"
        draggableId={blockIndx.toString()}
        {...provided.dragHandleProps}
        onClick={(e) => handleIsRowOpen(e, blockIndx)}
      >
        Web View
        <div className="homescreensection">
          {/* <img
            className="mr-3"
            src={
              require("../../../assets/images/dashboard/wordpress-grey.png")
                .default
            }
          /> */}
          <label className="switch" htmlFor={`checkstatus${blockIndx}`}>
            <input
              type="checkbox"
              id={`checkstatus${blockIndx}`}
              onChange={(e) => handleStatus(e, blockIndx)}
              checked={homescreenData[blockIndx].enable === 1 ? true : false}
            ></input>
            <span className="slider round"></span>
          </label>
        </div>
        <img
          src={
            isRowOpen[blockIndx] === true
              ? require("../../../assets/images/dashboard/cms/accordian-open.png")
                  .default
              : require("../../../assets/images/dashboard/cms/accordian-icon.png")
                  .default
          }
        />
      </h2>
      {isRowOpen[blockIndx] === true && (
        <div className="accordionItemContent category-customize homescreen-webview">
          <div className="">
            <div className="guest-login-toggle flex-grow-1 mr-2 d-lg-block pcview">
              <h6>Page URL</h6>
            </div>

            <div>
              <input
                id="classhtmlelement"
                placeholder="www.example.com"
                value={homescreenData[blockIndx].value.web_view_url}
                onChange={(e) => handleWebViewUrl(e)}
              ></input>
            </div>
          </div>
          {/* <div className="mt-30">
            <label class="input-box-checkbox">
              <p>Show my website's header in this web view</p>
              <input
                type="checkbox"
                onChange={(e) => handleWebsiteHeader(e)}
                checked={
                  homescreenData[blockIndx].value.show_website_header === 1
                    ? true
                    : false
                }
              />
              <span class="homescreen-checkmark"></span>
            </label>
          </div>
          <div className="mt-30">
            <label class="input-box-checkbox">
              <p>Show my website footer in the web view</p>
              <input
                type="checkbox"
                onChange={(e) => handleWebsiteFooter(e)}
                checked={
                  homescreenData[blockIndx].value.show_website_footer === 1
                    ? true
                    : false
                }
              />
              <span class="homescreen-checkmark"></span>
            </label>
          </div>
          <div className="mt-30">
            <div className="guest-login-toggle flex-grow-1 mr-2 d-lg-block pcview">
              <h6>Hide HTML Element by Class</h6>
            </div>

            <div>
              <input
                id="classhtmlelement"
                placeholder="header,footer"
                onChange={(e) => handleHideHTMLByClass(e)}
                value={homescreenData[blockIndx].value.hide_html_by_class}
              ></input>
            </div>
          </div>
          <div className="mt-30">
            <div className="guest-login-toggle flex-grow-1 mr-2 d-lg-block pcview">
              <h6>Hide HTML Element by ID</h6>
            </div>

            <div>
              <input
                id="idhtmlelement"
                placeholder="sidebar,content"
                onChange={(e) => handleHideHTMLById(e)}
                value={homescreenData[blockIndx].value.hide_html_by_id}
              ></input>
            </div>
          </div>{" "} */}
          <div className="homescreen-delete-option">
            <p onClick={(e) => handleDuplicateItem(e, eachBlock)}>
              <img
                src={
                  require("../../../assets/images/dashboard/cms/duplicate.png")
                    .default
                }
              />
              Duplicate
            </p>
            <p onClick={(e) => handleDeleteItem(e, blockIndx)}>
              <img
                src={
                  require("../../../assets/images/dashboard/cms/delete.png")
                    .default
                }
              />
              Delete
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default HMSWebView;
