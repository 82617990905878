import React from "react";
import './User.css'
export const Notallowedtouser = () => {
  return (
    <>
      <div className="col-md-12">
        <div className="w-100 text-center restrictuser pt-5 pb-5" >
          <img
            src={require("../../assets/images/dashboard/restrict-user.png").default}
            alt="Changes not allowed"
            className="mb-4 img-fluid"
          />
          <h3>You currently do not have permission to access this feature. Please contact your administrator if you require access</h3>
          <h4 >You currently do not have permission to access this feature.</h4>
          <h4 > Please contact your administrator if you require access</h4>
        </div>
      </div>
    </>
  );
};
