import React from 'react'

const EmailSupport = () => {
  return (
    <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                            >
                              <g
                                id="Group_41130"
                                data-name="Group 41130"
                                transform="translate(-1549 -255)"
                              >
                                <rect
                                  id="Rectangle_25576"
                                  data-name="Rectangle 25576"
                                  width="24"
                                  height="24"
                                  transform="translate(1549 255)"
                                  fill="none"
                                />
                                <g
                                  id="Group_34944"
                                  data-name="Group 34944"
                                  transform="translate(8.5 42.5)"
                                >
                                  <g
                                    id="Path_98183"
                                    data-name="Path 98183"
                                    transform="translate(1542.5 216.5)"
                                    fill="none"
                                  >
                                    <path
                                      d="M1.644,0H18.356A1.644,1.644,0,0,1,20,1.644V13.357A1.643,1.643,0,0,1,18.357,15H1.644A1.644,1.644,0,0,1,0,13.356V1.644A1.644,1.644,0,0,1,1.644,0Z"
                                      stroke="none"
                                    />
                                    <path
                                      d="M 1.643999099731445 0.9999990463256836 C 1.288900375366211 0.9999990463256836 1 1.288899421691895 1 1.643999099731445 L 1 13.35599899291992 C 1 13.71109867095947 1.288900375366211 13.99999904632568 1.643999099731445 13.99999904632568 L 18.35700035095215 13.99999904632568 C 18.71154975891113 13.99999904632568 19 13.71154880523682 19 13.35699939727783 L 19 1.643999099731445 C 19 1.288899421691895 18.71109962463379 0.9999990463256836 18.35599899291992 0.9999990463256836 L 1.643999099731445 0.9999990463256836 M 1.643999099731445 -9.5367431640625e-07 L 18.35599899291992 -9.5367431640625e-07 C 19.26395988464355 -9.5367431640625e-07 20 0.7360391616821289 20 1.643999099731445 L 20 13.35699939727783 C 20 14.2643985748291 19.26440048217773 14.99999904632568 18.35700035095215 14.99999904632568 L 1.643999099731445 14.99999904632568 C 0.7360401153564453 14.99999904632568 0 14.26395893096924 0 13.35599899291992 L 0 1.643999099731445 C 0 0.7360391616821289 0.7360401153564453 -9.5367431640625e-07 1.643999099731445 -9.5367431640625e-07 Z"
                                      stroke="none"
                                      fill="#1b2952"
                                    />
                                  </g>
                                  <path
                                    id="Path_98182"
                                    data-name="Path 98182"
                                    d="M45.23,3.32l8.122,8.093a1.3,1.3,0,0,0,1.81.01L63.421,3.4"
                                    transform="translate(1498.174 214.187)"
                                    fill="none"
                                    stroke="#1b2952"
                                    stroke-miterlimit="10"
                                    stroke-width="1"
                                  />
                                </g>
                              </g>
                            </svg>
  )
}

export default EmailSupport