import React, { Suspense } from "react";
import Header from "../Header";
import Styles from "./connecntivity.module.css";
import { Link } from "react-router-dom";
import Leftpanel from "../Leftpanel";
import { Foot, InnerFoot } from "../../Dashboard/Foot";
import { Spinner, Media, ModalBody, Modal } from "reactstrap";
import Verifyemailstatus from "../../Verification/Checkverification";
import Tooltip from "../Tooltip";
import ReactSelect from "react-select";
import Select from "react-select";
import {

  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import {
  Troubleshootingapi,
  Troubleshootingapiwithoutcsck,
  Getappcredentailapi,
  Connectivity_History,
  Slowwebsiteapi,
} from "../../Api/Connectivity/Troubleshootingapi";
import "./monitor.css";
import { Apidata } from "./Context";
// import { Error } from "../Reusable/Connectivityerror";
import Amsvideo from "../Reusable/Amsvideos";
import {
  connectivityMonitor,
  getConnectivityMonitor,
} from "../../Api/Dashboard/DashboardApi";
import { leftgetAgencyName } from "../../Api/Agency/AgencyApi";
import { UserlistForMonitor } from "../../Api/Connectivity/Connectivity";
import imagebackground from "../../assets/images/dashboard/my-account.png";
import { errortoast } from "../Toaster";
import moment from "moment";


const History = React.lazy(() => import("./History"));

export default class Monitor extends React.PureComponent {
  state = {
    spinner: true,
    newSpinner: true,
    ck: "",
    cs: "",
    sendwrl: "",
    showstatus: [],
    website_type: "",
    successcount: [],
    dayselection: false,
    loader: (
      <svg xmlns="" width="24" height="24" viewBox="0 0 24 24">
        <g
          id="Group_4474"
          data-name="Group 4474"
          transform="translate(-311 -254)"
        >
          <circle
            id="Ellipse_2803"
            data-name="Ellipse 2803"
            cx="12"
            cy="12"
            r="12"
            transform="translate(311 254)"
            fill="#c8cedb"
          />
          <path
            id="Path_8281"
            data-name="Path 8281"
            d="M-19252.922-4211.958l3.078,3.078,5.791-5.794"
            transform="translate(19571.58 4478.115)"
            fill="none"
            stroke="#fff"
            strokeWidth="2.5"
          />
        </g>
      </svg>
    ),
    result: "-",
    hit: false,
    buttontext: "SAVE",
    disabledbutton: false,
    showstrip: false,
    plan_code: "",
    connectivity_history: "",
    is_connected: null,
    wp_username: null,
    wp_application_password: null,
    authorization_type: null,
    connection_to_establish: 0,
    is_trigger_slow_website_email: 1,
    is_verified: null,
    clientID: null,
    frequency_type: "",
    dropDownVal: "Weekly",
    loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
    loader2: "d-none",
    submitval: "Save",
    day: 0,
    date: ["1"],
    toggle: 0,
    updated_at:"",
    appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
    localStorage.getItem("appSideBarCollapse") === "true"
      ? true
      : false,
    frequencyOptions:[
      {label:"Daily" , value : "Daily"},
      {label:"Weekly" , value : "Weekly"},
      {label:"Monthly" , value : "Monthly"},
    ] ,
    dayOptions : [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ],
    timeOptions : [
      {label:"1:00 AM",value:"1"},
      {label:"2:00 AM",value:"2"},
      {label:"3:00 AM",value:"3"},
      {label:"4:00 AM",value:"4"},
      {label:"5:00 AM",value:"5"},
      {label:"6:00 AM",value:"6"},
      {label:"7:00 AM",value:"7"},
      {label:"8:00 AM",value:"8"},
      {label:"9:00 AM",value:"9"},
      {label:"10:00 AM",value:"10"},
      {label:"11:00 AM",value:"11"},
      {label:"12:00 PM",value:"12"},
      {label:"1:00 PM",value:"13"},
      {label:"2:00 PM",value:"14"},
      {label:"3:00 PM",value:"15"},
      {label:"4:00 PM",value:"16"},
      {label:"5:00 PM",value:"17"},
      {label:"6:00 PM",value:"18"},
      {label:"7:00 PM",value:"19"},
      {label:"8:00 PM",value:"20"},
      {label:"9:00 PM",value:"21"},
      {label:"10:00 PM",value:"22"},
      {label:"11:00 PM",value:"23"},
      {label:"12:00 AM",value:"0"},
    ],
    dateOptions : [
      {label:"1st",value:"1"},
      {label:"2nd",value:"2"},
      {label:"3rd",value:"3"},
      {label:"4th",value:"4"},
      {label:"5th",value:"5"},
      {label:"6th",value:"6"},
      {label:"7th",value:"7"},
      {label:"8th",value:"8"},
      {label:"9th",value:"9"},
      {label:"10th",value:"10"},
      {label:"11th",value:"11"},
      {label:"12th",value:"12"},
      {label:"13th",value:"13"},
      {label:"14th",value:"14"},
      {label:"15th",value:"15"},
      {label:"16th",value:"16"},
      {label:"17th",value:"17"},
      {label:"18th",value:"18"},
      {label:"19th",value:"19"},
      {label:"20th",value:"20"},
      {label:"21st",value:"21"},
      {label:"22nd",value:"22"},
      {label:"23rd",value:"23"},
      {label:"24th",value:"24"},
      {label:"25th",value:"25"},
      {label:"26th",value:"26"},
      {label:"27th",value:"27"},
      {label:"28th",value:"28"},
      // "29th",
      // "30th",
      // "31st",
    ],
    openDayDropdown:false ,
    openTimeDropdown:false ,
    openDateDropdown:false ,
    openUsersDropdown:false ,
    usersData:null ,
    openFreqDropdown:false,
    openMobFreqDropdown:false,
    openMobDayDropdown:false ,
    openMobTimeDropdown:false ,
    openMobDateDropdown:false ,
    openMobUsersDropdown:false ,
    userSearchVal:"",
    isSelectedUser:false ,
    isEmail:false ,
    timeVal:"",
    dayVal:"",
    monthVal:"",
    timeSelectionArr:[{label:"9:00 AM",value:"9"}],
    timeCheckedArr:[],
    daySelectionArr:["Monday"],
    dayCheckedArr:[true,false,false,false,false,false,false],
    userSelectionArr:[],
    userCheckedArr:[],
    selectedDate:""
  };
  // handleFrequency = () => {

  // }

  returnSelectedGMTHours= (selectedHour)=>{
   
    const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
    const gmtHour = (selectedHour + timezoneOffsetInMinutes / 60 + 24) % 24;
    // gmtHour = parseInt(gmtHour < 10)?"0" + gmtHour : gmtHour;
    return Math.floor(gmtHour).toString();
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    // document.title = "Monitor";
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
    {
      document.title = "Monitor  | AppMySite";
    }
    else 
    {
      document.title = "Monitor  |"+ ' ' +localStorage.getItem('agencyname');
    }
    // this.setState({
    // timeCheckedArr:new Array(this.state.timeOptions.length).fill(false),
    // dayCheckedArr:new Array(this.state.dayOptions.length).fill(false),
    // })
    const params = {
      app_id:btoa(localStorage.getItem("Appid"))
    }

    
    getConnectivityMonitor(this, btoa(localStorage.getItem("Appid")),()=>{
      UserlistForMonitor(params,this)
    });
    
    Getappcredentailapi(this, () => {
      let websitetype = this.state.website_type;
      if (parseInt(
        localStorage.getItem("website_technology")
        ) === 5) {
        if (this.state.authorization_type === 0) {
          this.setState({
            showstatus: [
              {
                is_plugin_installed: "",
                alias: "Plugin Installed",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Plugin Activated",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Wordpress Connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "SSL Verification",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Permalink Setting",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Pages Connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Post Connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
            ],
          });
        } else {
          this.setState({
            showstatus: [
              {
                is_plugin_installed: "",
                alias: "Plugin Installed",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Plugin Activated",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "WooCommerce Connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "SSL Verification",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Permalink Setting",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias:
                  this.state.authorization_type === 2
                    ? "API Details Validation"
                    : "CS & CK Validation",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias:
                  this.state.authorization_type === 2
                    ? "API Details Authorisation"
                    : "CS & CK Authorisation",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },

              {
                is_plugin_installed: "",
                alias: "HTTP Authorisation Enabled",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Pages Connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Post Connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Product Connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Product Category Connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
            ],
          });
        }
      } else {
        if (this.state.authorization_type === 2) {
          this.setState({
            showstatus: [
              {
                is_plugin_installed: "",
                alias: "Plugin Installed",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Plugin Activated",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Wordpress Connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "SSL Verification",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Permalink Setting",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Pages Connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Post Connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "API Details Validation",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "API Details Authorisation",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
            ],
          });
        } else {
          this.setState({
            showstatus: [
              {
                is_plugin_installed: "",
                alias: "Plugin Installed",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Plugin Activated",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Wordpress Connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "SSL Verification",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Permalink Setting",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Pages Connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Post Connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
            ],
          });
        }
      }
    });
  }




handledayselectionDropdown = (e) => {
  if(window.matchMedia("(max-width: 1200px)").matches){
    this.setState({
      // dayselection: !this.state.dayselection,
      openMobDayDropdown:!this.state.openMobDayDropdown
    });
  }
  else{
  this.setState({
    // dayselection: !this.state.dayselection,
    openDayDropdown:!this.state.openDayDropdown
  });
}
}

handleTimeSelectionDropdown = (e) => {
  if(window.matchMedia("(max-width: 1200px)").matches){
    this.setState({
      openMobTimeDropdown:!this.state.openMobTimeDropdown
     });
  }else{
    this.setState({
     openTimeDropdown:!this.state.openTimeDropdown
    });
   }
}

handleDateSelectionDropdown = (e) => {
  if(window.matchMedia("(max-width: 1200px)").matches){
    this.setState({
      openMobDateDropdown:!this.state.openMobDateDropdown
     });
  }else{
  this.setState({
   openDateDropdown:!this.state.openDateDropdown
  });
 }
}

handleUsersSelectionDropdown = (e) => {
  if(window.matchMedia("(max-width: 1200px)").matches){
    this.setState({
      openMobUsersDropdown:!this.state.openMobUsersDropdown
    });
  }
  else{

  this.setState({
    openUsersDropdown:!this.state.openUsersDropdown
  });
 }
}
handleOpenMobFreqDrop = (e) => {
  this.setState({
    openMobFreqDropdown:!this.state.openMobFreqDropdown
  });
}

handleFreqDropdown = (e) => {
  this.setState({
    openFreqDropdown:!this.state.openFreqDropdown
  });
}


  handleDropdown = (e) => {
  
    this.setState({
      dropDownVal: e.value,
      date: ["1"],
      day: 0,
      openMobFreqDropdown:false ,
    });
  };

  handleOnlyMeandUser = (e,from) => {
   this.setState({
    isSelectedUser:from==="user"?true:false,
    });
  }
  handleEmailSend = (e) =>{
    this.setState({
      isEmail:e.target.checked,
      });
  }


  handleSave = (e) => {
    e.preventDefault();
  

    let finalTimeArr = [] ;

    let finalDaySelectionArr = [] ;
    finalDaySelectionArr = this.state.daySelectionArr && this.state.daySelectionArr.map((row)=>{
      switch (row) {
      
        case "Monday":
          return 0;
        case "Tuesday":
          return 1;
        case "Wednesday":
          return 2;
        case "Thursday":
          return 3;
        case "Friday":
          return 4;
        case "Saturday":
          return 5;
        case "Sunday":
          return 6;
      
      }
    });
    finalTimeArr = this.state.timeSelectionArr && this.state.timeSelectionArr.map((row)=>{
      // let finalVal="";
  
      // if(row.includes("PM")){
      //   finalVal = parseInt(row.replace(":00 PM",""))+12;
      // }else{
      //   finalVal = parseInt(row.replace(":00 AM",""));
      // }
      return this.returnSelectedGMTHours(parseInt(row.value));
    });
    
    let finalUserSelectionArr = [] ;
    finalUserSelectionArr = this.state.userSelectionArr && this.state.userSelectionArr.map((row)=>{
       return row.id;
    });

    const param = {
      app_id: parseInt(localStorage.getItem("Appid")),
      frequency: this.state.dropDownVal === "Daily" ?0:this.state.dropDownVal === "Weekly"?1 : 2,
      // day:
      //   this.state.dropDownVal === "Weekly"
      //     ? parseInt(this.state.day)
      //     : parseInt(this.state.date),
      time:finalTimeArr,
      day:this.state.dropDownVal === "Daily"?[]:this.state.dropDownVal === "Weekly"?finalDaySelectionArr :Array.isArray(this.state.date)?this.state.date : [(this.state.date.toString())],
      notify_failures_on_email:this.state.isEmail ? 1 : 0,
      notify_user_id_list:(this.state.isEmail === false || this.state.isSelectedUser===false )?[]:finalUserSelectionArr,
      status: this.state.toggle,
      notify_failures_to_user_check:this.state.isSelectedUser?1:0,
    };
    // if(param.time===null||param.time.length===0){
    //  errortoast("Time selection is required.");
    // }else 
    // if(param.notify_user_id_list.length===0 && (this.state.isSelectedUser || this.state.isEmail)){
    //   errortoast("User selection field is required.");
    // }else{
      this.setState({
        loader2: "",
        submitval: "",
      });
      connectivityMonitor(param, this);
    // }
  };

  handleDate = (e,date) => {
    e.preventDefault();
    let newArr=[date];
    this.setState({
      date: newArr,
      openMobDateDropdown:false,
    });
  };

  handleWeek = (e) => {
    e.preventDefault();
    this.setState({
      day: e.target.value,
    });
  };

  handleToggle = (e) => {
    this.setState({
      toggle: e.target.checked === true ? 1 : 0,
    });
  };

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }

  handleConstructTimeArr = (e,obj, position) => {
  
    let newArr = [...this.state.timeSelectionArr];
   
    const updatedCheckedState = this.state.timeCheckedArr.map((item, index) =>
    index === position ? !item : item
    );
    // this.setState({
      
    //   timeCheckedArr:updatedCheckedState,
    // })
    this.setState({
      timeCheckedArr:!e.target.checked&&newArr.length<2?this.state.timeCheckedArr:updatedCheckedState
     })
  //  setTagCheckedArr(updatedCheckedState); 
   
   if(e.target.checked){
    newArr.push(obj);
    this.setState({
      timeSelectionArr:newArr,
    })
    // setTimeout(()=>{
    //   if(document.getElementById("calculate-ele-height-tag")){
  
   
        
    //     if(document.getElementById("calculate-ele-height-tag").clientHeight>53 && document.getElementById("calculate-ele-height-tag").clientHeight<=91 ){
        
    //       document.getElementById("test-height-tag").classList.add("increase-height1");
    //     }else if(document.getElementById("calculate-ele-height-tag").clientHeight>91 && document.getElementById("calculate-ele-height-tag").clientHeight<=130){
    //       document.getElementById("test-height-tag").classList.add("increase-height2");
         
    //     }
    //     else if(document.getElementById("calculate-ele-height-tag").clientHeight>130){
      
    //       document.getElementById("test-height-tag").classList.add("increase-height3");
    //     }
    //   }
    //   })
   }else{
    if(newArr.length>1){
    for (let i = 0; i < newArr.length; i++) {

      if(newArr[i].value===obj.value){
        newArr.splice(i, 1);
        break;
      }
   }

   this.setState({
    timeSelectionArr:newArr
  })
}
  //  setTimeout(()=>{
  //   if(document.getElementById("calculate-ele-height-tag")){

    
      
  //     if(document.getElementById("calculate-ele-height-tag").clientHeight<=53 ){
        
  //       document.getElementById("test-height-tag").classList.remove("increase-height1");
  //     }else if(document.getElementById("calculate-ele-height-tag").clientHeight<=91 ){
  //       document.getElementById("test-height-tag").classList.remove("increase-height2");
  //     }
  //     else if(document.getElementById("calculate-ele-height-tag").clientHeight<=130){
       
     
  //       document.getElementById("test-height-tag").classList.remove("increase-height3");
  //     }
  //   }
  //   })
   }


  


   
  };
 handleDelTimeArr = (e, indx,row) => {
  if( this.state.toggle === 1 ){
    let newArr = [...this.state.timeSelectionArr];
    newArr.splice(indx, 1);
    this.setState({
      timeSelectionArr:newArr
    })
    const updatedCheckedState = [...this.state.timeCheckedArr];
    for (let i = 0; i < this.state.timeOptions.length; i++) {

      if(this.state.timeOptions[i].value===row.value){
        updatedCheckedState[i]=false;
        break;
      }
   }
   this.setState({
    timeCheckedArr:updatedCheckedState
  })

  //  setTagCheckedArr(updatedCheckedState); 
  
   


  //  setTimeout(()=>{
  //   if(document.getElementById("calculate-ele-height-tag")){

    
      
  //     if(document.getElementById("calculate-ele-height-tag").clientHeight<=53 ){
        
  //       document.getElementById("test-height-tag").classList.remove("increase-height1");
  //     }else if(document.getElementById("calculate-ele-height-tag").clientHeight<=91 ){
  //       document.getElementById("test-height-tag").classList.remove("increase-height2");
  //     }
  //     else if(document.getElementById("calculate-ele-height-tag").clientHeight<=130){
       
     
  //       document.getElementById("test-height-tag").classList.remove("increase-height3");
  //     }
  //   }
  //   })
    e.stopPropagation();
    } 
  };

  handleConstructDayArr = (e,obj, position) => {

    let newArr = [...this.state.daySelectionArr];
   
    const updatedCheckedState = this.state.dayCheckedArr.map((item, index) =>
    index === position  ? !item : item
    );
   
    this.setState({
     dayCheckedArr:!e.target.checked&&newArr.length<2?this.state.dayCheckedArr:updatedCheckedState
    })
 
   
   if(e.target.checked){
    newArr.push(obj);
    this.setState({
      daySelectionArr:newArr,
    })
    // setTimeout(()=>{
    //   if(document.getElementById("calculate-ele-height-tag")){
  
   
        
    //     if(document.getElementById("calculate-ele-height-tag").clientHeight>53 && document.getElementById("calculate-ele-height-tag").clientHeight<=91 ){
        
    //       document.getElementById("test-height-tag").classList.add("increase-height1");
    //     }else if(document.getElementById("calculate-ele-height-tag").clientHeight>91 && document.getElementById("calculate-ele-height-tag").clientHeight<=130){
    //       document.getElementById("test-height-tag").classList.add("increase-height2");
         
    //     }
    //     else if(document.getElementById("calculate-ele-height-tag").clientHeight>130){
      
    //       document.getElementById("test-height-tag").classList.add("increase-height3");
    //     }
    //   }
    //   })
   }else{
    if(newArr.length>1){

    
    for (let i = 0; i < newArr.length; i++) {

      if(newArr[i]===obj){
        newArr.splice(i, 1);
        break;
      }
   }

   this.setState({
    daySelectionArr:newArr
  })
}
  //  setTimeout(()=>{
  //   if(document.getElementById("calculate-ele-height-tag")){

    
      
  //     if(document.getElementById("calculate-ele-height-tag").clientHeight<=53 ){
        
  //       document.getElementById("test-height-tag").classList.remove("increase-height1");
  //     }else if(document.getElementById("calculate-ele-height-tag").clientHeight<=91 ){
  //       document.getElementById("test-height-tag").classList.remove("increase-height2");
  //     }
  //     else if(document.getElementById("calculate-ele-height-tag").clientHeight<=130){
       
     
  //       document.getElementById("test-height-tag").classList.remove("increase-height3");
  //     }
  //   }
  //   })
   }


  


   
  };
 handleDelDayArr = (e, indx,row) => {

  if( this.state.toggle === 1 ){
    let newArr = [...this.state.daySelectionArr];
    newArr.splice(indx, 1);
    this.setState({
      daySelectionArr:newArr
    })
    const updatedCheckedState = [...this.state.dayCheckedArr];
    for (let i = 0; i < this.state.dayOptions.length; i++) {

      if(this.state.dayOptions[i]===row){
        updatedCheckedState[i]=false;
        break;
      }
   }
   this.setState({
    dayCheckedArr:updatedCheckedState
  })

  
  
   


  //  setTimeout(()=>{
  //   if(document.getElementById("calculate-ele-height-tag")){

    
      
  //     if(document.getElementById("calculate-ele-height-tag").clientHeight<=53 ){
        
  //       document.getElementById("test-height-tag").classList.remove("increase-height1");
  //     }else if(document.getElementById("calculate-ele-height-tag").clientHeight<=91 ){
  //       document.getElementById("test-height-tag").classList.remove("increase-height2");
  //     }
  //     else if(document.getElementById("calculate-ele-height-tag").clientHeight<=130){
       
     
  //       document.getElementById("test-height-tag").classList.remove("increase-height3");
  //     }
  //   }
  //   })
    e.stopPropagation();
    }
};

  handleConstructUserArr = (e,obj, position) => {

    let newArr = [...this.state.userSelectionArr];
   
    const updatedCheckedState = this.state.userCheckedArr.map((item, index) =>
    index === position ? !item : item
    );

    this.setState({
      userCheckedArr:!e.target.checked&&newArr.length<2?this.state.userCheckedArr:updatedCheckedState,
      // userCheckedArr:updatedCheckedState,
    })
  
   
   if(e.target.checked){
    let newObj={
      profile_name: obj.profile_name, id: obj.user_id, profile_image: ''
    }
    newArr.push(newObj);
    this.setState({
      userSelectionArr:newArr,
    })
    // setTimeout(()=>{
    //   if(document.getElementById("calculate-ele-height-tag")){
  
   
        
    //     if(document.getElementById("calculate-ele-height-tag").clientHeight>53 && document.getElementById("calculate-ele-height-tag").clientHeight<=91 ){
        
    //       document.getElementById("test-height-tag").classList.add("increase-height1");
    //     }else if(document.getElementById("calculate-ele-height-tag").clientHeight>91 && document.getElementById("calculate-ele-height-tag").clientHeight<=130){
    //       document.getElementById("test-height-tag").classList.add("increase-height2");
         
    //     }
    //     else if(document.getElementById("calculate-ele-height-tag").clientHeight>130){
      
    //       document.getElementById("test-height-tag").classList.add("increase-height3");
    //     }
    //   }
    //   })
   }else{
    if(newArr.length>1){
    for (let i = 0; i < newArr.length; i++) {

      if(newArr[i].id===obj.user_id){
        newArr.splice(i, 1);
        break;
      }
   }}

   this.setState({
    userSelectionArr:newArr
  })

  //  setTimeout(()=>{
  //   if(document.getElementById("calculate-ele-height-tag")){

    
      
  //     if(document.getElementById("calculate-ele-height-tag").clientHeight<=53 ){
        
  //       document.getElementById("test-height-tag").classList.remove("increase-height1");
  //     }else if(document.getElementById("calculate-ele-height-tag").clientHeight<=91 ){
  //       document.getElementById("test-height-tag").classList.remove("increase-height2");
  //     }
  //     else if(document.getElementById("calculate-ele-height-tag").clientHeight<=130){
       
     
  //       document.getElementById("test-height-tag").classList.remove("increase-height3");
  //     }
  //   }
  //   })
   }


  


   
  };
 handleDelUserArr = (e, indx,row) => {
  if( this.state.toggle === 1 ){
    let newArr = [...this.state.userSelectionArr];
    newArr.splice(indx, 1);
    this.setState({
      userSelectionArr:newArr
    })
    const updatedCheckedState = [...this.state.userCheckedArr];
    for (let i = 0; i < this.state.usersData.length; i++) {

      if(this.state.usersData[i].user_id===row.id){
        updatedCheckedState[i]=false;
        break;
      }
   }
   this.setState({
    userCheckedArr:updatedCheckedState
  })

  
  
   


  //  setTimeout(()=>{
  //   if(document.getElementById("calculate-ele-height-tag")){

    
      
  //     if(document.getElementById("calculate-ele-height-tag").clientHeight<=53 ){
        
  //       document.getElementById("test-height-tag").classList.remove("increase-height1");
  //     }else if(document.getElementById("calculate-ele-height-tag").clientHeight<=91 ){
  //       document.getElementById("test-height-tag").classList.remove("increase-height2");
  //     }
  //     else if(document.getElementById("calculate-ele-height-tag").clientHeight<=130){
       
     
  //       document.getElementById("test-height-tag").classList.remove("increase-height3");
  //     }
  //   }
  //   })
    e.stopPropagation();
   }
  };

  returnSelectedDate = (date) =>{
    let finalVal="";
  this.state.dateOptions.map((eachDate)=>{
    if(eachDate.value==date){
      finalVal=eachDate.label
    }
  })
  return finalVal;
  }

  render() {

   let showNoDataImage = 0 ; 

    const customStyles = {
      // control: (base, state) => ({
      //   ...base,
      //   // match with the menu
      //   borderRadius: 3,
      //   // Overwrittes the different states of border
      //   borderColor: state.isFocused ? "#7782a1" : "#C8CEDB",
      //   backgroundColor: state.isDisabled ? "#ffffff" : "",
      //   height: 50,
      //   minHeight: 50,
      //   fontSize: 14,
      //   color: "#7782A1 !important",
      //   paddingLeft: 10,
      //   boxShadow: state.isFocused ? "none" : "none",
      //   "&:hover": {
      //     boxShadow: state.isFocused ? "none" : "none",
      //   },
      // }),
      valueContainer: (base) => ({
        ...base,
        height: 50,
        minHeight: 50,
        fontSize: 14,
        color: "#7782A1 !important",
      }),
      menu: (base) => ({
        ...base,
        fontSize: "14px",
      }),
      input: (base) => ({
        ...base,
        margin: 0,
        padding: 0,
      }),
      indicatorSeparator: (base) => ({
        ...base,
        display: "none",
      }),
      dropdownIndicator: (base) => ({
        ...base,
        // color: "#1B2952", // Custom colour
        // marginRight: 20,
      }),
    };


    const dayOfWeek = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday",
    ];

    const dateOfMonth = [
      1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
      22, 23, 24, 25, 26, 27, 28,
    ];
    return (
      <>
      
        <div className="d-md-block">
          <Header customclass={"header-responsive-new"} />
        </div>

        {/* Mobile View End*/}
        <section className="dasboard_page mobile-display mt-1 mt-md-0 topspace topp">
        <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
        <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>
            {/* Email Verification */}
            <div className="d-none d-md-flex w-100">
              <Verifyemailstatus />
            </div>
            {/* Email Verification End*/}
            <div className='web-connectivity-head'>
              <h1>Connectivity
              <p className="connectivity-tooltip">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
              <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                  <circle cx="9" cy="9" r="9" stroke="none"/>
                  <circle cx="9" cy="9" r="8.25" fill="none"/>
                </g>
                <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
              </g>
            </svg>
            <p className="connectivity-tooltipsubheader">
              Here, you can set up connectivity to your website. These connections enable the seamless transfer of data and content between your app and your data sources.
            </p>
            </p></h1>
            </div>

            <div className="connectivity-mob-head">
              <p>Monitor</p>
            </div>
            <div className="consent-modal-tab">
              <div style={{ height: "40px" }}>
                
                              {parseInt(localStorage.getItem("website_technology")) !==
                      2 &&
                  <Link to={"/app/connect/wp-plugin"} style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? 
                  "#3064f9" : localStorage.getItem('agency_primary_color'),
                  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9"
                   : localStorage.getItem('agency_primary_color')}}
                    >Install plugin</Link>  }
                
                {/* { parseInt(localStorage.getItem("website_technology")) !== 2 &&
                  parseInt(localStorage.getItem("website_technology")) !== 3 ? */}
                  {parseInt(localStorage.getItem("website_technology")) !==
                      2 &&
                  <Link to={"/app/connect/wp-grant-access" }
                  style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? 
                  "#3064f9" : localStorage.getItem('agency_primary_color'),
                  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9"
                   : localStorage.getItem('agency_primary_color')}}>Grant access</Link> }


                  <Link to={"/app/connect/troubleshoot-logs"}
                  style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" :
                   localStorage.getItem('agency_primary_color'),
                   "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" 
                   : localStorage.getItem('agency_primary_color')}}>Troubleshoot</Link>

                  <Link to={"/app/connect/monitor"}
                 className="active monitorLink" 
                 style={{"border-bottom": localStorage.getItem('agencyid') == 1 ?
                  "2px solid #3064f9" : "2px solid "+ localStorage.getItem('agency_primary_color'),
                  "color": localStorage.getItem('agencyid') == 1 ? "#3064f9" : 
                  localStorage.getItem('agency_primary_color'),
                  "--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : 
                  localStorage.getItem('agency_primary_color'),"--custom_color5":
                   localStorage.getItem('agencyid') == 1 ? "#3064f9" : 
                   localStorage.getItem('agency_primary_color')}} >Monitor</Link>
                {/* : null
                } */}
              </div>
              <div className='date-and-savediv'>
                {/* {lastdate && lastdate !== '' && lastdate !== undefined ?
                      <p className='consent-save-date'>Updated  {moment(lastdate).format(
                                "D MMMM YYYY, HH:mm"
                              )}{" "}</p>
                    : null
                    }
                <button type="submit" style={{backgroundColor:localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor')}} className={upgradeconset != 0 ? "consent-login-save-enable" : "consent-login-save-disable"} onClick={upgradeconset != 0 ? (e) => postConsentData(e) : void(0)}>
                    <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    {saveloader == true ?
                          <div className="new-loader"></div>
                    :
                          <div>Save</div>
                    }
                    </div>
                </button> */}
                {
                  this.state.updated_at && 
                
           <p className="connectionupdatedate">Updated {moment(
            this.state.updated_at
          ).format("D MMMM YYYY, HH:mm")}</p> }
           <div className="trouble-tasks d-md-flex  align-items-center justify-content-between monitorbutton">
                                {this.state.hit && (
                                  <p className="mb-0 mr-4 d-none d-md-block">
                                    <span>
                                      {this.state.successcount.length}/
                                      {this.state.showstatus.length}{" "}
                                    </span>
                                    Tests Passed
                                  </p>
                                )}
                                <div className="">
                                  <button
                                    type="submit"
                                    style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                    // className={this.state.toggle === 1 ?"connectivity-btn":"send-buttondisabled"}
                                    className={"connectivity-btn"}
                                    // disabled={this.state.toggle===0}
                                   
                                    onClick={(e) => this.handleSave(e)}
                                  >
                                    <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                      <div className={this.state.loader2}>
                                      <div className="new-loader"></div>
                                      </div>
                                      <div>{this.state.submitval}</div>
                                    </div>
                                  </button>
                                </div>
                                {this.state.hit && (
                                  <p className="mb-0 d-md-none pb-2 text-right">
                                    <span>
                                      {this.state.successcount.length}/
                                      {this.state.showstatus.length}
                                    </span>
                                    Tests Passed
                                  </p>
                                )}
                              </div>
              </div>

              

            </div>
            {this.state.newSpinner ? (
                <div className="connectivityspinn connectivityspinner">
                 <div className="">
              <svg xmlns="http://www.w3.org/2000/svg" 
        xlink="http://www.w3.org/1999/xlink" 
        style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
        width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>
          </animateTransform>
        </circle>
        </g>
        <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
        style={{animationplaystate: "running", animationdelay: "0s"}}>
        </animateTransform>
        </circle>
        </g>
        <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
        style={{animationplaystate: "running", animationdelay: "0s"}}>

        </animateTransform>
        </circle>
        </g>
        <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
        style={{animationplaystate: "running", animationdelay: "0s"}}>

        </animateTransform>
        </circle>
        </g>
        </svg>
            </div>
                </div>
              ) : (
            <div className="new-div">

         
            <div
              className="monitor-headPannel"
              // style={{ minHeight: "80vh" }}
            >
            
                <>
                  <div className="right_general  troubleshoot-portion">
                    {/* {this.state.showstrip && (
                      <div className="d-flex justify-content-between showwebstrip align-items-center pl-3 pr-3 flex-wrap mb-3">
                        <div>
                          <h6 className="m-0 p-0">
                            <img
                              src={
                                require("../../assets/images/dashboard/alertstrip.png")
                                  .default
                              }
                              alt="alert"
                              className="mr-1"
                            />
                            It appears your website is slow. Your users may face
                            issues while browsing through your app.
                          </h6>
                        </div>
                        {
                            parseInt(localStorage.getItem("website_technology")) !== 2 &&
                        <div className="d-flex align-items-center">
                          <Link className="mr-1" to="/performance/web-hosting">
                            Fix this issue
                          </Link>
                          <h5 className="m-0 p-0">
                            &amp; get blazing fast speeds
                          </h5>
                        </div>
                        }
                      </div>
                    )} */}

                    <div className="d-flex justify-content-between">
                      <div className="go_live w-100">
                        <div className="customiseappfeatures pt-0 pb-0">
                          <div>
                            
                            <div>
                              <div className=" ">
                                <div className="connectmainapidiv">
                                  <div className="firstcol">
                                   
                                    <div
                                      className="firstcolback"
                                   
                                    >
                               <h2>Monitor connectivity</h2>

                                      <div className="monitor-mainmenu">
                                      
                                        <div style={{marginTop: "30px"}} className="d-flex align-items-center apidescription justify-content-between">
                                          <h3 className="mt-0 pt-0 d-md-block mb-0 monitortoggle">Monitor connectivity{" "}<p class="connectivity-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_41154" data-name="Group 41154" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p class="connectivity-tooltipsubheader">Enable to receive email notifications when your app connection is interrupted.</p></p></h3>
                                          

                                          <label className=" switch ml-3 monitor-slider-padding">
                                            <input
                                                type="checkbox"
                                                className="toplogo"
                                                checked={
                                                  this.state.toggle === 1
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e) =>
                                                  this.handleToggle(e)
                                                }
                                                id="monitor"
                                              />
                                      <div className="slider round"></div>
                                    </label>
                                    
                                        </div>
                                        <div className="color_picker  monitor-dropdown-heading formselect">
                                          <h3>Frequency</h3>

                                          <div className="form-group  mobile-view-click"    
                                             onClick={(e) =>window.matchMedia("(max-width: 1200px)").matches ?
                                             this.handleOpenMobFreqDrop(e) :null
                                              }>
                                            
                                           
                                    <Dropdown 
                                         className={`${this.state.toggle===0?"monitor-disable-dropdown ":""} cms-filterby-post search-dropdown connectivity-search-dropdown`}
                                         isOpen={window.matchMedia("(max-width: 1200px)").matches?false :this.state.openFreqDropdown}
                                         disabled={this.state.toggle===0}
                                        //  style={{
                                        //   backgroundColor:this.state.toggle===0? "#e9ecef":"",
                                        //   opacity:this.state.toggle===0? 1:"",
                                        //   // cursor:this.state.toggle===0?"unset":""
                                        // }}
                                          toggle={this.handleFreqDropdown}
                                         >
                                    <DropdownToggle caret><span className="search-dropdown-before" style={{
                                    textTransform:"capitalize",
                                    color:this.state.toggle===0?"#7782A1":""
                                    }}>{
                                      this.state.dropDownVal?this.state.dropDownVal:"Select"}</span></DropdownToggle>
                                    <DropdownMenu >
                               
                                    
                                     
                                 
                                  {
                                    this.state.frequencyOptions &&  this.state.frequencyOptions.map((res)=>{
                                      return(
                                        <DropdownItem  
                                        onClick={()=>this.handleDropdown(res)}
                                        active={this.state.dropDownVal===res.value}
                                        >{res.value}
                                        </DropdownItem>
                                      )
                                    })
                                  }
                               
                                 
                                    </DropdownMenu>
                                  </Dropdown>
                                          </div>
                                        </div>
                                        {this.state.dropDownVal === "Weekly" ? (
                                          <div style={{
                                            marginBottom:"20px"
                                          }} className="color_picker  monitor-dropdown-heading monitor-dropdown-day"
                                      
                                          
                                          >
                                            <h3>Day</h3>

                                            <div className="form-group  mobile-view-click">
                                              
                                              <div className="categogy-chip-section connect-categogy-chip-section">
                                          
                                          <Dropdown
                                          className={`${this.state.toggle===0?"monitor-disable-dropdown ":""} `}
                                            isOpen={
                                              window.matchMedia(
                                                "(max-width: 1200px)"
                                              ).matches
                                                ? false
                                                : this.state.openDayDropdown
                                            }
                                            
                                            disabled={this.state.toggle===0}
                                            toggle={this.handledayselectionDropdown}
                                          >
                                      
                                            

                                         
                                            <DropdownToggle caret>
                                            <span className="search-dropdown-before">
                                            <div>
                                            {
                                                    this.state.daySelectionArr && this.state.daySelectionArr.map((name,ind)=>{
                                                      return(
                                                <div>
                                     
                                                    <p style={{marginBottom: "0px"}}>
                                                     <span
                                                     
                                                    
                                                    >{name}
                                                    {
                                                      this.state.daySelectionArr.length > 1 && 
                                                    
                                                      <img  onClick={(e) =>
                                                        this.handleDelDayArr(e, ind,name)
                                                      } className="img-fluid" 
                                                      src={require("../../assets/images/dashboard/remove-tag.png").default}
                                                      alt="Remove"/> }
                                                    </span>
                                                  </p>
                                                     
                                                  
                                                </div> )
                                                    })
                                                  }
                                                </div>
                                            </span>
                                            </DropdownToggle>
                                          
                                          
                                            <DropdownMenu id="test-height-tag">
                                              
                                            
                                              {this.state.dayOptions &&
                                                this.state.dayOptions
                                              
                                                  .map((res,indx) => {
                                                    return (
                                                      <div
                                                      className="category-created mob-category-created"
                                                       
                                                      >
                                                        <label className="category-list-container mob-category-list" for={"daySelection"+indx}>
                                                        <input type="checkbox" id={"daySelection"+indx} name="tag1" 
                                                         onChange={(e) =>
                                                          this.handleConstructDayArr(e,res,indx)
                                                        }
                                                      
                                                        checked={this.state.dayCheckedArr[indx]}
                                                        />
                                                        <span className="category-checkmark"></span>
                                                       
                                                          {res}
                                                        </label>
                                                      </div>
                                                    );
                                                  })}
                                                 
                                                    

                                            </DropdownMenu>
                                          </Dropdown>
                                        </div>
                                            </div>
                                          </div>
                                        ) : (
                                          this.state.dropDownVal !== "Daily" &&
                                          <div className="color_picker monitor-dropdown-heading monitor-dropdown-date">
                                            <h3>Date</h3>

                                            <div className="form-group">
                                              
                                                  <Dropdown disabled={this.state.toggle===0} 
                                                  className={`${this.state.toggle===0?"monitor-disable-dropdown ":""} cms-filterby-post search-dropdown connectivity-search-dropdown`}
                                                  
                                                  
                                                   isOpen={window.matchMedia("(max-width: 1200px)").matches?false :this.state.openDateDropdown}
                                        
                                          toggle={this.handleDateSelectionDropdown}
                                         >
                                    <DropdownToggle caret><span className="search-dropdown-before" style={{
                                    textTransform:"capitalize",
                                    color:this.state.toggle===0?"#7782A1":""
                                    }}>{
                                      this.state.date?this.returnSelectedDate(this.state.date[0]):"Select"}</span></DropdownToggle>
                                    <DropdownMenu >
                               
                                    
                                     
                               
                                  {
                                    this.state.dateOptions &&  this.state.dateOptions.map((date)=>{
                                      return(
                                        <DropdownItem  
                                        onClick={(e)=>this.handleDate(e,date.value)}
                                        active={this.state.date[0]==date.value}
                                        >{date.label}
                                        </DropdownItem>
                                      )
                                    })
                                  }
                               
                                 
                                    </DropdownMenu>
                                  </Dropdown>
                                            </div>
                                          </div>
                                        )}
                                        
                                        <div className="color_picker  monitor-dropdown-heading secondselect monitor-dropdown-day">
                                          <h3>Time</h3>

                                          <div className="form-group  mobile-view-click">
                                            
                                            <div className="categogy-chip-section connect-categogy-chip-section">
                                        
                                        <Dropdown
                                                className={`${this.state.toggle===0?"monitor-disable-dropdown ":""} `}
                                        disabled={this.state.toggle===0}
                                          isOpen={
                                            window.matchMedia(
                                              "(max-width: 1200px)"
                                            ).matches
                                              ? false
                                              : this.state.openTimeDropdown
                                          }
                                          
                                          // onClick={
                                          //   window.matchMedia(
                                          //     "(max-width: 1200px)"
                                          //   ).matches
                                          //     ? handleMobileTagDropdown
                                          //     : null
                                          // }
                                          toggle={this.handleTimeSelectionDropdown}
                                        >
                                    
                                          

                                       
                                          <DropdownToggle caret>
                                          <span className="search-dropdown-before">
                                            <div>
                                              
                                            {
                                                    this.state.timeSelectionArr && this.state.timeSelectionArr.map((name,ind)=>{
                                                      return(
                                                       
                                                <div>
                                     
                                                    <p style={{marginBottom: "0px"}}>
                                                     <span
                                                      
                                                      style={{marginLeft: "8px"}}
                                                    >{name.label}
                                                    {
                                                       this.state.timeSelectionArr.length > 1 &&
                                                      <img onClick={(e) =>
                                                        this.handleDelTimeArr(e, ind,name)
                                                      }  className="img-fluid" 
                                                      src={require("../../assets/images/dashboard/remove-tag.png").default}
                                                      alt="Remove"/>
                                                    }
                                                    </span>
                                                  </p>
                                                     
                                                  
                                                </div> )
                                                    })
                                                  }
                                                </div>
                                            </span>
                                          </DropdownToggle>
                                        
                                        
                                          <DropdownMenu id="test-height-tag">
                                            
                                          
                                            {this.state.timeOptions &&
                                              this.state.timeOptions
                                                // .filter((row) =>
                                                //   row.name
                                                //     .toLowerCase()
                                                //     .includes(
                                                //       tagSearchVal.toLowerCase()
                                                //     )
                                                // )
                                                .map((res,indx) => {
                                                  return (
                                                    <div
                                                    className="category-created mob-category-created"
                                                     
                                                    >
                                                      <label className="category-list-container" for={"timeSelection"+indx}>
                                                      <input type="checkbox" id={"timeSelection"+indx} name="tag1" 
                                                       onChange={(e) =>
                                                        this.handleConstructTimeArr(e,res,indx)
                                                      }
                                                      // value={res}
                                                      checked={this.state.timeCheckedArr[indx]}
                                                      />
                                                      <span className="category-checkmark"></span>
                                                     
                                                        {res.label}
                                                      </label>
                                                    </div>
                                                  );
                                                })}
                                               
                                                  

                                          </DropdownMenu>
                                        </Dropdown>
                                      </div>
                                          </div>
                                        </div>

                                        <div className="">
                                          <div className=" checkboxdiv">
                                            <div
                                              className=""
                                              style={{ display: "flex" }}
                                            >
                                             

                                              <label className="checkboxmainbox">
                                                <input
                                                  type="checkbox"
                                                  id="secureconnection"
                                                  checked={
                                                    this.state.isEmail
                                                  }
                                                  onClick={(e)=> this.handleEmailSend(e)}
                                                />
                                                <span className="checkmark"></span>
                                              </label>
                                              <p className="monitor-ticktoggle">Email me if connectivity fails</p>
                                            </div>

                                           
                                          </div>
                                         
                                        </div>
                                     
                                        {
                                          this.state.isEmail && 
  
                                        <div className="monitor-lastrow">
                                            <label class="monitor-radio-button monitor-radio-firstbtn">
                                          
                                              <input type="radio" class="monitor-radio-button__input" id="choice1-1" name="choice1"
                                              onChange={(e)=>this.handleOnlyMeandUser(e,"onlyMe")}
                                              checked={!this.state.isSelectedUser}
                                              />
                                              <span class="monitor-radio-button__control"></span>
                                              <span class="monitor-radio-button__label">Only me</span>
                                            </label>

                                            <label class="monitor-radio-button">
                                            
                                              <input type="radio" class="monitor-radio-button__input" id="choice1-2" name="choice2"
                                              onChange={(e)=>this.handleOnlyMeandUser(e,"user")}
                                              checked={this.state.isSelectedUser}
                                              />
                                              <span class="monitor-radio-button__control"></span>
                                              <span class="monitor-radio-button__label">Selected users</span>
                                            </label>
                                           {
                                            this.state.isSelectedUser &&
                                           <div className="categogy-chip-section connect-email-chip-section">
                                            <Dropdown
                                                    className={`${this.state.toggle===0?"monitor-disable-dropdown ":""} `}
                                            isOpen={
                                              window.matchMedia(
                                                "(max-width: 1200px)"
                                              ).matches
                                                ? false
                                                : this.state.openUsersDropdown
                                            }
                                           
                                            disabled={this.state.toggle===0}
                                            toggle={this.handleUsersSelectionDropdown}
                                          >
                                      
                                            

                                         
                                            <DropdownToggle caret>
                                            <span className="search-dropdown-before">
                                            <div>
                                             
                                            {
                                                    this.state.userSelectionArr!==null && this.state.userSelectionArr.length!==0  ? this.state.userSelectionArr.map((name,ind)=>{
                                                      return(
                                                <div>
                                     
                                                    <p style={{marginBottom: "0px"}}>
                                                     <span

                                                      // style={{marginLeft: "8px"}}
                                                    >{name.profile_name}
                                                     
                                                     {
                                                      this.state.userSelectionArr.length > 1 &&  
                                                      <img onClick={(e) =>this.handleDelUserArr(e, ind,name)}  
                                                      className="img-fluid" 
                                                      src={require("../../assets/images/dashboard/remove-tag.png").default} alt="Remove"
                                                      /> }
                                                    </span>
                                                  </p>
                                                     
                                                  
                                                </div> )
                                                    })
                                                    :
                                                    "Email addresses"
                                                  }
                                                </div>
                                            </span>
                                            </DropdownToggle>
                                          
                                          
                                            <DropdownMenu id="test-height-tag">
                                              { (this.state.usersData===null || this.state.usersData.length === 0 
                                              // || showNoDataImage === 0
                                              ) && (
                                                <div className="no-app-dropdown">
                                                  <img
                                                    className="img-fluid"
                                                    src={
                                                      require("../../assets/images/dashboard/no-tag-available.png")
                                                        .default
                                                    }
                                                    alt="No App Found"
                                                  />
                                                </div>
                                              )}
                                            
                                              
                                                  
                                                <FormGroup>
                                                {
                                                this.state.usersData && this.state.usersData.length > 0 && 
                                                 
                                                
                                                  <div className="dropdown-search">
                                                    <input
                                                      placeholder="Search"
                                                      type="text"
                                                      value={this.state.userSearchVal}
                                                      onChange={(e) => {
                                                        this.setState({
                                                          userSearchVal:e.target.value
                                                        })
                                                      }}
                                                    ></input>
                                                    {this.state.userSearchVal.trim() && (
                                                      <p
                                                        onClick={(e) => {
                                                          this.setState({
                                                            userSearchVal:""
                                                          })
                                                        }}
                                                      >
                                                        Clear
                                                      </p>
                                                   )} 
                                                  </div>
                                                  }
                                                </FormGroup>
                                        
                                              {
                                                // this.state.usersData && this.state.usersData.filter((row)=>
                                                // row.profile_name.toLowerCase().includes(this.state.userSearchVal.toLocaleLowerCase())).
                                                
                                                this.state.usersData && this.state.usersData.map((user,indx)=>{
                                                  if(user.profile_name.toLowerCase().includes(this.state.userSearchVal.toLocaleLowerCase())){
                                                    // showNoDataImage = showNoDataImage+1;
                                                
                                                    return(
                                                     user.status===1 &&
                                                      <div
                                                       className="category-created mob-category-created"
                                                        
                                                       >
                                                         <label className="category-list-container user-category-list mob-category-list" for={"user2Selection"+indx}>
                                                         <input type="checkbox" id={"user2Selection"+indx} name="tag15" 
                                                          onChange={(e) =>
                                                           this.handleConstructUserArr(e,user,indx)
                                                         }
                                                         // value={user.id}
                                                         checked={this.state.userCheckedArr[indx]}
                                                         />
                                                        
                                                        {
                                                          user.image && user.image.indexOf("https")>-1 ?
                                                          <img style={{
                                                            width: "40px",
                                                            borderRadius: "50%",
                                                            height: "40px",
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundSize: "cover",
                                                            marginRight: "8px",
                                                          }} className="img-fluid" src={user.image}  alt="profile-img"
                                                            onError={({ currentTarget }) => {
                                                              currentTarget.onerror = null;
                                                              currentTarget.src =imagebackground;
                                                            }}
                                                            />
                                                        :
                                                          <img style={{
                                                            width: "40px",
                                                            borderRadius: "50%",
                                                            height: "40px",
                                                            backgroundRepeat: "no-repeat",
                                                            backgroundSize: "cover",
                                                            marginRight: "8px",
                                                          }} className="img-fluid" src={process.env.REACT_APP_Image_Path+user.user_id+
                                                        process.env.REACT_APP_Profile+user.image}  alt="profile-img" 
                                                        onError={({ currentTarget }) => {
                                                          currentTarget.onerror = null;
                                                          currentTarget.src =imagebackground;
                                                        }}
                                                        />
                                                      }
                                                         <span className="category-checkmark "></span>
                                                         <div className="monitor-emaildropdown">
                                                        <span className="monitor-name">{user.profile_name}</span>
                                                        <span className="monitor-email">{user.invitation_email}</span>
                                                         </div>
                                                         
                                                      
                                                         </label>
                                                       </div>
                                                     )
                                                  }
                                                  
                                                  
                                                  
                                               
                                                })
                                              }
                                                  
                                                    
                                            </DropdownMenu>
                                          </Dropdown>
                                          </div> }
                                          </div>  
                                          }
                                          
                                      </div>
                                    </div>
                                  </div>
                                  <div className="firstcol1">
                                    <div className=" rightsid" >
                                      <div className="monitor-plugin_img">
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/monitorimg.png")
                                              .default
                                          }
                                          alt="plugin"
                                          className="img-fluid monitor-img-mobile-view"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* } */}
                  </div>
                  {/* History Table */}

                  {/* <Apidata.Provider value={this.state.connectivity_history}>
                    <Suspense fallback={""}>
                      <History
                        value={this.state.is_trigger_slow_website_email}
                        monitorClass={"d-none"}
                      />
                    </Suspense>
                  </Apidata.Provider> */}

                  {/* History Table End*/}
                </>
         
            </div>
            <div className="mob-connect-varify">
                   
                        <button
                                    type="submit"
                                   
                                    style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                    // className={this.state.toggle===1?"connectivity-btn":"send-buttondisabled"}
                                    className={"connectivity-btn"}
                                    // disabled={this.state.toggle===0}
                                   
                                    onClick={(e) => this.handleSave(e)}
                                  >
                                    <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                      <div className={this.state.loader2}>
                                      <div className="new-loader"></div>
                                      </div>
                                      <div>{this.state.submitval}</div>
                                    </div>
                                  </button>
                                  {
                                    this.state.updated_at && 
                                  
                        <p>Updated {moment(
                          this.state.updated_at
                        ).format("D MMMM YYYY, HH:mm")}</p> }
                      </div>
                      <div className="stepsDiv">
                          <div className="step1">
                            <div className="iconImg">
                              <img
                                src={
                                  require("../../assets/images/dashboard/connectstep1.png")
                                    .default
                                }
                                alt="Goto"
                              />
                            </div>
                            <div>
                            <label>Step 1</label>
                            <p>Activate connectivity monitoring</p>
                            </div>
                          </div>
                          <div className="step1">
                            <div className="iconImg">
                              <img
                                src={
                                  require("../../assets/images/dashboard/connectstep2.png")
                                    .default
                                }
                                alt="Goto"
                              />
                            </div>
                            <div>
                            <label>Step 2</label>
                            {/* <p>Install & activate the AppMySite plugin</p> */}
                            <p>Configure monitoring schedule</p>
                            </div>
                            
                          </div>
                          <div className="step1">
                            <div className="iconImg">
                              <img
                                src={
                                  require("../../assets/images/dashboard/connectstep4.png")
                                    .default
                                }
                                alt="Goto"
                              />
                            </div>
                            <div>
                            <label>Step 3</label>
                            <p>Select users to be notified</p>

                            </div>
                            
                          </div>
                        </div>
                        </div>)}
            {/* {InnerFoot()} */}
          </div>
             {/* Frequency Mobile Dropdown */}
       <Modal className="protip-popup-message" isOpen={window.matchMedia("(max-width: 1200px)").matches?this.state.openMobFreqDropdown:false} centered>
          
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={this.handleOpenMobFreqDrop}><svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
            <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
              <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
              <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
            </g>
          </svg></button>
          
          </div>

          <div className="mobilesearchsuggestion">
            
         
            
        {
          this.state.frequencyOptions &&  this.state.frequencyOptions.map((option)=>{
            return(
              <p  className="mobilesearchsuggestion-para"
                onClick={()=>this.handleDropdown(option)}
                // active={postRelatedTo==="authors"}
                >{option.value}
                </p>
            )
          })
        }
            
            </div>
          </ModalBody>
      </Modal>
       {/* Time Mobile Dropdown */}
       <Modal className="protip-popup-message" isOpen={window.matchMedia("(max-width: 1200px)").matches?this.state.openMobTimeDropdown:false} centered>
          
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={this.handleTimeSelectionDropdown}><svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
            <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
              <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
              <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
            </g>
          </svg></button>
          
          </div>

          <div className="mobilesearchsuggestion">
            
         
            
        {
          this.state.timeOptions &&  this.state.timeOptions.map((res,indx)=>{
            return(
             
              <div
              className="category-created mob-category-created"
              
              >
                <label className="category-list-container mob-category-list" for={`time${indx}`}>
                <input type="checkbox" id={`time${indx}`} name="timeMobile1" 
                
                onChange={(e) =>
                  this.handleConstructTimeArr(e,res,indx)
                }
               
                checked={this.state.timeCheckedArr[indx]}
                />
                <span className="category-checkmark"></span>
                
                  {res.label}
                </label>
              </div>

              // <p  style={{	
              //   fontSize:"16px",	
              //   color: "#1B2952",	
              //    fontFamily: "Axiforma-Regular",	
              //   margin: "0px 20px 40px 20px",	
              //    display: "block",	
              // }}
              // onClick={(e) =>
              //   this.handleConstructTimeArr(e,res,indx)
              // }
              //   // active={postRelatedTo==="authors"}
              //   >{res}
              //   </p>
            )
          })
        }
            
            </div>
          </ModalBody>
      </Modal>
          {/* Date Mobile Dropdown */}
          <Modal className="protip-popup-message" isOpen={window.matchMedia("(max-width: 1200px)").matches?this.state.openMobDateDropdown:false} centered>
          
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={this.handleDateSelectionDropdown}><svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
            <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
              <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
              <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
            </g>
          </svg></button>
          
          </div>

          <div className="mobilesearchsuggestion">
            
         
            
        {
          this.state.dateOptions &&  this.state.dateOptions.map((option)=>{
            return(
              <p  className="mobilesearchsuggestion-para"
              onClick={(e)=>this.handleDate(e,option.value)}
                // active={postRelatedTo==="authors"}
                >{option.label}
                </p>
            )
          })
        }
            
            </div>
          </ModalBody>
      </Modal>

         {/* Day Mobile Dropdown */}
         <Modal className="protip-popup-message" isOpen={window.matchMedia("(max-width: 1200px)").matches?this.state.openMobDayDropdown:false} centered>
          
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={this.handledayselectionDropdown}><svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
            <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
              <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
              <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
            </g>
          </svg></button>
          
          </div>

          <div className="mobilesearchsuggestion">
            
          {    this.state.dayOptions && this.state.dayOptions.map((res,indx) => {
                return (
                  <div
                  className="category-created mob-category-created"
                  
                  >
                    <label className="mob-category-list category-list-container" for={`tagMobile${indx}`}>
                    <input type="checkbox" id={`tagMobile${indx}`} name="tagMobile1" 
                    onChange={(e) =>
                      this.handleConstructDayArr(e,res,indx)
                    }
                   
                    checked={this.state.dayCheckedArr[indx]}
                    />
                    <span className="category-checkmark"></span>
                    
                      {res}
                    </label>
                  </div>
                );
              })}
            
       
            
            </div>
          </ModalBody>
      </Modal>

           {/* Users Mobile Dropdown */}
           <Modal className="protip-popup-message" isOpen={window.matchMedia("(max-width: 1200px)").matches?this.state.openMobUsersDropdown:false} centered>
          
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={this.handleUsersSelectionDropdown}><svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408">
            <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)">
              <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2"/>
              <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2"/>
            </g>
          </svg></button>
          {

this.state.usersData &&   this.state.usersData.length > 0 &&
          <input placeholder="Search" type="text" 
          value={this.state.userSearchVal}
          onChange={(e)=>{
            this.setState({
              userSearchVal:e.target.value
            })
            }}
            ></input>
          }
              {this.state.userSearchVal.trim() && <p onClick={(e)=>{
                                  this.setState({
                                    userSearchVal:""
                                  })
                                }}>Clear</p> }
          </div>

          <div className="mobilesearchsuggestion">
            {/* {
            this.state.usersData && this.state.usersData.filter((row)=>
            row.profile_name.toLowerCase().includes(this.state.userSearchVal.toLocaleLowerCase())).map((res)=>{
              return(
               
              <div className="cms-author-mobile">
                
                  <div>
                    {
                      res.image && res.image.indexOf("https")>-1 ?
                      <img className="img-fluid" src={res.image}  alt="profile-img"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src =imagebackground;
                        }}
                        />
                    :
                      <img className="img-fluid" src={process.env.REACT_APP_Image_Path+res.user_id+
                    process.env.REACT_APP_Profile+res.image}  alt="profile-img" 
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src =imagebackground;
                    }}
                    />
                  }

                  </div>

                  <p 
                  // onClick={(e)=>handleAuthorChange(e,res.user_id)}
                  >{res.profile_name}</p>
                </div>
              )
             }) 
            } */}
         
         {
                                                this.state.usersData && this.state.usersData.filter((row)=>
                                                row.profile_name.toLowerCase().includes(this.state.userSearchVal.toLocaleLowerCase()))
                                                .map((user,indx)=>{
                                                  return(
                                                    user.status===1 && <div
                                                    className="category-created mob-category-created"
                                                     
                                                    >
                                                      <label className="category-list-container user-mob-category-list" for={"user2Selection"+indx}>
                                                      <input type="checkbox" id={"user2Selection"+indx} name="tag15" 
                                                       onChange={(e) =>
                                                        this.handleConstructUserArr(e,user,indx)
                                                      }
                                                      // value={user.id}
                                                      checked={this.state.userCheckedArr[indx]}
                                                      />
                                                     
                                                     {
                      user.image && user.image.indexOf("https")>-1 ?
                      <img style={{
                        width: "40px",
                        borderRadius: "50%",
                        height: "40px",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        marginRight: "8px",
                      }} className="img-fluid" src={user.image}  alt="profile-img"
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src =imagebackground;
                        }}
                        />
                    :
                      <img style={{
                        width: "40px",
                        borderRadius: "50%",
                        height: "40px",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                        marginRight: "8px",
                      }} className="img-fluid" src={process.env.REACT_APP_Image_Path+user.user_id+
                    process.env.REACT_APP_Profile+user.image}  alt="profile-img" 
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null;
                      currentTarget.src =imagebackground;
                    }}
                    />
                  }
                                                      <span className="category-checkmark "></span>
                                                      <div className="monitor-emaildropdown">
                                                     <span className="monitor-name">{user.profile_name}</span>
                                                     <span className="monitor-email">{user.invitation_email}</span>
                                                      </div>
                                                      
                                                   
                                                      </label>
                                                    </div>
                                                  )
                                                })
                                              }
        

          

            </div>
          </ModalBody>
      </Modal>
        </section>
      </>
    );
  }
}
