import axios from "axios";
import React from "react";
import toast from "toasted-notes";
import S3 from "react-aws-s3";

import "toasted-notes/src/styles.css";
import "../../assets/css/newtoaster.css";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";

import ntc from "ntcjs";
import { createClient } from "pexels";
import AccordionBody from "react-bootstrap/esm/AccordionBody";
import { CustomS3Client } from "../../utils/S3utils";
let pexelsClient = createClient(process.env.REACT_APP_PEXELS_API_SECRET_KEY);
const Url = process.env.REACT_APP_API_URL + "api/user/";
const saveceritificate = process.env.REACT_APP_API_URL + "api/app/";
const cmsUrl = process.env.REACT_APP_API_URL + "api/cms/";

const BuildUrl = process.env.REACT_APP_API_URL + "api/automation/bitbucket/";
const Cancelurl = process.env.REACT_APP_API_URL + "api/automation/bitrise/";
const dashboardurl = process.env.REACT_APP_API_URL + "api/";
const appsumourl = process.env.REACT_APP_API_URL;
const RedirectUrl = process.env.REACT_APP_cors_api;

let clientid = "";
let awspath = "";
let appicon = "";
let applogo = "";
let splashimg = "";
let loginbackground = "";
let loginlogo = "";
let dashboard_banner = "";

const changeurl = () => {
  awspath =
    process.env.REACT_APP_Image_Path + clientid + process.env.REACT_APP_Profile;
  appicon = process.env.REACT_APP_Image_Path + clientid + "/";
  applogo = process.env.REACT_APP_Image_Path + clientid + "/";
  splashimg = process.env.REACT_APP_Image_Path + clientid + "/";
  loginbackground = process.env.REACT_APP_Image_Path + clientid + "/";
  loginlogo = process.env.REACT_APP_Image_Path + clientid + "/";
  dashboard_banner = process.env.REACT_APP_Image_Path + clientid + "/";
};

// success toast
/*const sucesstoast = (message) => {
  toast.notify(
    <div
      style={{
        borderLeft: "5px solid #01C99B",
        height: "100px",
        width: "420px",
      }}
      className="mobiletoaster"
    >
      <div className="d-flex align-items-center h-100">
        <div className="mr-3 ml-3">
          <svg width="35" height="35" viewBox="0 0 35 35">
            <circle
              id="Ellipse_1736"
              data-name="Ellipse 1736"
              cx="17.5"
              cy="17.5"
              r="17.5"
              fill="#01c99b"
            />
            <path
              id="Path_6845"
              data-name="Path 6845"
              d="M801.834,128.881l4.166,3.8,8.015-8.015"
              transform="translate(-790.333 -111.171)"
              fill="none"
              stroke="#fff"
              strokeWidth="2"
            />
          </svg>
        </div>
        <div>
          <h6 className="text-left mb-1">Success</h6>
          <p className="p-0 m-0 text-left">{message}</p>
        </div>
      </div>
    </div>,

    {
      position: "top", // top-left, top, top-right, bottom-left, bottom, bottom-right
      duration: 6000, // This notification will not automatically close
    }
  );
};

//Error toast
const errortoast = (message, errormssg) => {
  const handleSeeMore = (e) => {
    let seeMoreButton = document.getElementById("seeMoreButton");
    let showMoreText = document.getElementById("showMoreText");
    if (showMoreText.style.display === "none") {
      showMoreText.style.display = "block";
      seeMoreButton.innerHTML = "See Less";
    } else {
      showMoreText.style.display = "none";
      seeMoreButton.innerHTML = "See More";
    }
  };
  toast.notify(
    <div
      style={{
        borderLeft: "5px solid #E20000",
        height:
          errormssg === undefined || errormssg === null || errormssg === ""
            ? "100px"
            : "fit-content",
        // overflow:"auto",
        width: "420px",
      }}
      className="mobiletoaster"
    >
      <div className="d-flex align-items-center h-100">
        <div className="mr-3 ml-3">
          <svg width="35" height="35" viewBox="0 0 35 35">
            <circle
              id="Ellipse_1736"
              data-name="Ellipse 1736"
              cx="17.5"
              cy="17.5"
              r="17.5"
              fill="#e20000"
            />
            <g
              id="Group_3401"
              data-name="Group 3401"
              transform="translate(-673.175 -268.33)"
            >
              <g
                id="Polygon_10"
                data-name="Polygon 10"
                transform="translate(682.175 275.33)"
                fill="none"
              >
                <path
                  d="M6.734,3.325a2,2,0,0,1,3.532,0l5.173,9.737A2,2,0,0,1,13.673,16H3.327a2,2,0,0,1-1.766-2.938Z"
                  stroke="none"
                />
                <path
                  d="M 8.5 2.262979507446289 C 7.804892539978027 2.262979507446289 7.109785079956055 2.616877555847168 6.733770370483398 3.324671745300293 L 1.560979843139648 13.061692237854 C 0.8533000946044922 14.39379215240479 1.81879997253418 16.00000190734863 3.327210426330566 16.00000190734863 L 13.67279052734375 16.00000190734863 C 15.18120002746582 16.00000190734863 16.14669990539551 14.39379215240479 15.43902015686035 13.061692237854 L 10.2662296295166 3.324671745300293 C 9.890214920043945 2.616877555847168 9.195107460021973 2.262979507446289 8.5 2.262979507446289 M 8.5 0.2629814147949219 C 9.983099937438965 0.2629814147949219 11.33664989471436 1.07661247253418 12.03246021270752 2.386362075805664 L 17.20524978637695 12.12338256835938 C 17.86993980407715 13.37456226348877 17.83099937438965 14.8464822769165 17.10108947753906 16.06076240539551 C 16.37117958068848 17.27505302429199 15.08957004547119 18.00000190734863 13.67279052734375 18.00000190734863 L 3.327210426330566 18.00000190734863 C 1.910429954528809 18.00000190734863 0.6288204193115234 17.27505302429199 -0.1010894775390625 16.06076240539551 C -0.8309993743896484 14.8464822769165 -0.8699398040771484 13.37456226348877 -0.2052497863769531 12.12338256835938 L 4.96753978729248 2.386362075805664 C 5.66331958770752 1.076622009277344 7.016889572143555 0.2629814147949219 8.5 0.2629814147949219 Z"
                  stroke="none"
                  fill="#fff"
                />
              </g>
              <g
                id="Group_3400"
                data-name="Group 3400"
                transform="translate(690.675 282.866)"
              >
                <path
                  id="Path_6846"
                  data-name="Path 6846"
                  d="M0-1.077V1.9"
                  transform="translate(0 0.587)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
                <path
                  id="Path_6847"
                  data-name="Path 6847"
                  d="M0,.324V.346"
                  transform="translate(0 5.346)"
                  fill="none"
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeWidth="2"
                />
              </g>
            </g>
          </svg>
        </div>
        <div className="content">
          <h6 className="text-left mb-1 ">Error</h6>
          <p className="p-0 m-0 text-left">{message}</p>
          {errormssg === undefined || errormssg === null || errormssg === "" ? (
            <></>
          ) : (
            <button
              id="seeMoreButton"
              className="see-more"
              onClick={(e) => handleSeeMore(e)}
            >
              See more
            </button>
          )}
        </div>
      </div>
      <p
        id="showMoreText"
        style={{ display: "none", transition: "all ease 1s" }}
      >
        {errormssg}
      </p>
    </div>,

    {
      position: "top", // top-left, top, top-right, bottom-left, bottom, bottom-right
      duration: 6000, // This notification will not automatically close
    }
  );
};*/

//API on Load
// export const ImageGalleryPopupGetAPI = (
//   params,
//   setImages,
//   images,
//   setImagenotavailable,
//   setImageSpinner
// ) => {

//   // setImageSpinner(true);
//   const photos = [];
//   //   const perpage = params.orientation === "vertical" ? "14" : "28";
//   Promise.all([
//     axios.get(
//       `https://api.unsplash.com/photos?client_id=${process.env.REACT_APP_UNSPLASH_API_SECRET_KEY}&order_by=latest&page=${params.page}&per_page=40`
//     ),
//     pexelsClient.photos.curated({ page: params.page }),
//   ])
//     .then(([unsplashImg, pexelsImg]) => {
//       // let unsplashImg=await unsplashImg.data;
//       // let pexelsImg=await pexelsImg.photos;
//       unsplashImg.data.map((number) => {
//         let unsplashObj = {
//           showUrl: number.urls.small,
//           useUrl: number.urls.full,
//         };
//         photos.push(unsplashObj);
//       });
//       pexelsImg.photos.map((number) => {
//         let pexelsObj = {
//           showUrl: number.src.large,
//           useUrl: number.src.landscape,
//         };
//         photos.push(pexelsObj);
//       });

//       if (photos.length > 0) {
//         setImages([...images, ...photos]);
//         setImagenotavailable(false);
//         setImageSpinner(false);
//       } else {
//         setImages([]);
//         setImagenotavailable(true);
//         setImageSpinner(false);
//       }
//     })
//     .catch((error) => {
//       setImages([]);
//       setImagenotavailable(true);
//       setImageSpinner(false);

//     });
// };

// API on Search
export const ImageGalleryPopupSearchAPI = (
  params,
  setImages,
  images,
  setImagenotavailable,
  setImageSpinner,
  setIsPopUpScrollable
) => {
  let allowColor = false;

  const photos = [];
  const colorName = ntc.name(params.color);
  const finalColorName =
    params.color !== "" && params.color !== undefined && params.color !== null
      ? colorName[1].toLowerCase().replace(" ", "_")
      : "";

  switch (finalColorName) {
    case "black":
      allowColor = true;
      break;
    case "white":
      allowColor = true;
      break;
    case "yellow":
      allowColor = true;
      break;
    case "orange":
      allowColor = true;
      break;
    case "red":
      allowColor = true;
      break;
    case "purple":
      allowColor = true;
      break;
    case "magenta":
      allowColor = true;
      break;
    case "green":
      allowColor = true;
      break;
    case "teal":
      allowColor = true;
      break;
    case "blue":
      allowColor = true;
      break;
    case "black_and_white":
      allowColor = true;
      break;
  }
  
  // setImageSpinner(true);
  // setImages([]);
  // const perpage = params.orientation === "vertical" ? "14" : "28";
  Promise.all([
    (allowColor === true || params.color === "") &&
      axios.get(
        `https://api.unsplash.com/search/photos?query=${
          params.searchVal === "" ? "nature" : params.searchVal.replace(/\s+/g, '_')
        }&client_id=${process.env.REACT_APP_UNSPLASH_API_SECRET_KEY}&page=${
          params.page
        }&per_page=15${
          finalColorName !== "" ? `&color=${finalColorName}` : ""
        }${params.orientation==="allFilter"?"":`&orientation=${params.orientation}`}`
      ),
    pexelsClient.photos.search({
      query: params.searchVal === "" ? "nature" : params.searchVal.replace(/\s+/g, '_'),
      per_page: allowColor === true ? 15 : 30,
    
      orientation:
        params.orientation === "squarish" ? "square" : params.orientation==="allFilter"? "" :params.orientation,
      color: params.color.replace("#", ""),
      page: params.page,
    }),
  ])

    .then(([unsplashImg, pexelsImg]) => {
      if (
        unsplashImg.data !== undefined &&
        unsplashImg.data.results !== undefined &&
        unsplashImg.data.results !== null &&
        unsplashImg.data.results.length > 0 &&
        (allowColor === true || params.color === "")
      ) {
        unsplashImg.data.results.map((number) => {
          // unsplash.photos.trackDownload({
          //   downloadLocation: number.links.download_location,
          // });
          let unsplashObj = {
            showUrl: number.urls.thumb,
            useUrl: number.urls.full,
            url_600: number.urls.full + "&w=600",
            url_1200: number.urls.full + "&w=1200",
            credit_to: number.user.name,
            photographer_url: number.user.links.html,
            download_location: number.links.download_location,
          };
          photos.push(unsplashObj);
        });
      }
      if (pexelsImg.photos !== undefined && pexelsImg.photos.length > 0) {
        // pexelsImg.photos.map((number) => photos.push(number.src.large));
        pexelsImg.photos.map((number) => {
          let pexelsObj = {
            showUrl:params.orientation!=="allFilter" ?number.src.tiny : number.src.original + "?auto=compress&cs=tinysrgb&w=280"  ,
            useUrl: number.src.original,
            url_600: number.src.original + "?auto=compress&cs=tinysrgb&w=600",
            url_1200: number.src.original + "?auto=compress&cs=tinysrgb&w=1200",
            credit_to: number.photographer,
            photographer_url: number.photographer_url,
            download_location: null,
          };
          photos.push(pexelsObj);
        });
      }

      if (photos.length > 0) {
        setImages([...images, ...photos]);
        setImagenotavailable(false);
        setImageSpinner(false);
        if (photos.length < 30) {
        
          setIsPopUpScrollable(false);
        }
      } else {
        setImages([]);
        setImagenotavailable(true);
        setImageSpinner(false);
      }
    })
    .catch((error) => {

      setImages([]);
      setImagenotavailable(true);
      setImageSpinner(false);
    });
};

//API on scroll

// export const ImageGalleryPopupScrollPI = async (
//   params,
//   setImages,
//   setImagenotavailable,
//   setImageSpinner
// ) => {
//   setImageSpinner(true);
//   const pixabayimage = [];
//   const perpage = params.orientation === "vertical" ? "14" : "28";
//   axios
//     .get(
//       `https://api.unsplash.com/search/photos?query=${
//         params.searchVal
//       }&client_id=NwNPcaYu_1Z4l3_cffryZ0v1lxpNQha9hRlMmlEg7W8&page=1${
//         params.color !== "" ? `&color=${params.color}` : ""
//       }&orientation=${params.orientation}`
//     )
//     .then((res) => {
//       if (res.data.results.length > 0) {
//         res.data.results.map((number) => pixabayimage.push(number.urls.small));
//         setImages(pixabayimage);
//         setImagenotavailable(false);
//         setImageSpinner(false);
//       } else {
//         setImagenotavailable(true);
//         setImageSpinner(false);
//       }
//     })
//     .catch((error) => {})
// };

//image upload on S3

export const popUpImageUpload = (
  params,
  from,
  setLoader,
  setBtnVal,
  settingImgtoArray,
  errorHandlingFun,
  orgFileName,

) => {
  //convert into image(base64 image to file object)
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      // mime = arr[0].match(/:(.*?);/)[1],
      mime = "image/png",
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  //Usage example:
  let file = dataURLtoFile(params.data, "hello.png");

  //send to s3
  const config = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
  };
  const customS3Client = new CustomS3Client(config);

  const newFileName = orgFileName;

  customS3Client.uploadFile(file, newFileName)
    .then((data) => {
      if (data.status === 204) {
        settingImgtoArray(newFileName + ".png", "original");
      }
    })
    .catch((err) => {
      errorHandlingFun(from);
      setLoader("d-none");
      
      setBtnVal("Done");
      errortoast("Something went wrong. Try again after sometime.");
    });
};

// Resize and cropped image saved on S3
export const resizeAndCroppedImgUpload = (
  params,

  setLoader,
  setBtnVal,
  setIsGalleryPopup,

  settingImgtoArray,
  errorHandlingFun,
  orgFileName,
  classCompoState,
  isCms,
  croppedImageForCms,
  setTempListingThumb,
  setListingThumb,
  listingThumb,
  location,
  cmsArray,
  fromState,
  setThumbnailActive,
  callback,
) => {
  let file = params.data;

  //send to s3
  const config = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
  };
  const customS3Client = new CustomS3Client(config);

  const newFileName = ( isCms?"ams_thumb_": "ams_resize_" ) +
    new Date().getTime() +
    "_" +
    Math.floor(1000 + Math.random() * 9000);

  customS3Client.uploadFile(file, newFileName)
    .then((data) => {
      if (data.status === 204) {
        
        setLoader("d-none");
        setBtnVal("Done");
        if(classCompoState){
          classCompoState.setState({
            modal:false,
            isGalleryPopup:false
          })
        }
        else{
  
          setIsGalleryPopup(false);
        }

        settingImgtoArray(newFileName + ".png", "resize", orgFileName,croppedImageForCms);
        // if (!listingThumb && location.pathname?.includes("create")) {
        //   if(fromState==="thumbnail"){
        //     setThumbnailActive(true)
        //   }
        //   setTempListingThumb(cmsArray?.find((row) => row.item_type === "image")?.portal_value?.source_file || "");
        //   setListingThumb(cmsArray?.find((row) => row.item_type === "image")?.portal_value?.source_file || "")
        // }
        callback !== undefined && callback();
      }
    })
    .catch((err) => {
      errorHandlingFun("resize");
      setLoader("d-none");
      
      setBtnVal("Done");
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

export const unsplashDownloadCredit = (url) => {
  axios
    .get(`${url}&client_id=${process.env.REACT_APP_UNSPLASH_API_SECRET_KEY}`)

    .then((data) => {})
    .catch((err) => {
      
    });
};

// Homescreen Main API

// Save HMS Data
export const homescreenSaveAPI = (
  params,
  setIsLoader,
  setSpinner,
  setSubmitval,
  isHomescreenReload,
  setIsHomescreenReload
) => {
  let finalParam = JSON.parse(JSON.stringify(params.home_screen_array));

  for (let i = 0; i < finalParam.length; i++) {
    if (finalParam[i].item_type === "main_banner") {
      delete finalParam[i]["homescreen_selected_image_index"];
      delete finalParam[i]["homescreen_prev_image_index"];
      finalParam[i].value.main_banner.map((eachBanner) => {
        delete eachBanner["is_data_change"];
      });
    }
  }
  params.home_screen_array = finalParam;

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(saveceritificate + "save-home-screen", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setIsHomescreenReload(!isHomescreenReload);
        setIsLoader("d-none");
        // setSpinner(true);
        setSubmitval("SAVE");
        // if (localStorage.getItem("dashboard_icon_count") === "0") {
        //   setSubmitval("NEXT");
        // } else {
        //   setSubmitval("SAVE");
        // }

        sucesstoast("Your changes have been saved");
      } else {
        errortoast("Something went wrong. Try again after sometime.");
      }
    })
    .catch((err) => {
      
      errortoast("Something went wrong. Try again after sometime.");
    });
};

// Get HMS Data
export const homescreenGetAPI = (
  setSpinner,
  setHomescreenData,
  setBottomMenuData,
  setColorThemeData
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(
      saveceritificate +
        `get-home-screen/${btoa(localStorage.getItem("Appid"))}`,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        let finalRes =
          res.data.home_screen_data === null ? [] : res.data.home_screen_data;
        if (finalRes !== null && finalRes.length !== undefined) {
          finalRes &&
            finalRes.map((eachBlock, blckIndx) => {
              if (eachBlock.item_type === "main_banner") {
                eachBlock.homescreen_selected_image_index = 0;
                eachBlock.homescreen_prev_image_index = 0;
                eachBlock.value.main_banner.map((eachBanner) => {
                  eachBanner.is_data_change = false;
                });
              }
            });
        } else {
          let oldData = res.data.home_screen_data;
          let newCreatedObj = {};
          let syncData = [];
          let dashboardItemsSequence = [];
          if (parseInt(localStorage.getItem("website_technology")) === 4) {
            dashboardItemsSequence = [
              "main_banner",
              "blog_banner",
              "blog_categories",
              "sticky_blogs",
              "new_blogs",
              "web_view",
            ];
          } else {
            dashboardItemsSequence = [
              "main_banner",
              "blog_banner",
              "blog_categories",
              "sticky_blogs",
              "new_blogs",
              "product_categories",
              "sale_products",
              "recently_viewed_products",
              "featured_products",
              "cart",
              "new_products",
              "web_view",
            ];
          }
          dashboardItemsSequence &&
            dashboardItemsSequence.map((eachItem, indx) => {
              switch (eachItem) {
                case "main_banner":
                  newCreatedObj = {
                    homescreen_selected_image_index: 0,
                    homescreen_prev_image_index: 0,
                    position: indx,
                    item_type: "main_banner",
                    enable: oldData.main_banner_display_bool,
                    value: {
                      main_banner: [
                        {
                          is_data_change: true,

                          original_image: oldData.main_banner_image
                            ? process.env.REACT_APP_Image_Path +
                              localStorage.getItem("user_id") +
                              "/" +
                              localStorage.getItem("Appid") +
                              process.env.REACT_APP_Dashboard_Crop +
                              oldData.main_banner_image
                            : "",
                          image: oldData.main_banner_image
                            ? process.env.REACT_APP_Image_Path +
                              localStorage.getItem("user_id") +
                              "/" +
                              localStorage.getItem("Appid") +
                              process.env.REACT_APP_Dashboard_Crop +
                              oldData.main_banner_image
                            : "",
                          thumb_image: oldData.main_banner_image
                            ? process.env.REACT_APP_Image_Path +
                              localStorage.getItem("user_id") +
                              "/" +
                              localStorage.getItem("Appid") +
                              process.env.REACT_APP_Dashboard_Crop +
                              oldData.main_banner_image
                            : "",
                          original_path: "",
                          path: "",
                          hyperlink_option: "url",
                          hyperlink_value: "www.test.com",
                          hyperlink_alias: "",
                          style: {
                            color:
                              oldData.main_banner_solid_color_object
                                .portal_data,
                            text: oldData.main_banner_text,
                            text_color:
                              oldData.main_banner_font_color_object.portal_data,
                            font_size: oldData.main_banner_font_size,
                            font_family: oldData.main_banner_font_name,
                            text_align:
                              oldData.main_banner_text_align === "flex-start"
                                ? "left"
                                : oldData.main_banner_text_align === "flex-end"
                                ? "right"
                                : "center",
                            vertical_text_position: "center",
                            horizontal_text_position: "center",
                            text_style: "",
                            is_bold: 1,
                            is_italic: 1,
                            is_underline: 0,
                          },
                        },
                      ],
                    },
                    style: {
                      layout: {
                        name: "",
                        text_alignment: "",
                        text_position: "",
                        image_aspect_ratio: "4:3",
                      },
                    },
                  };
                  break;
                case "blog_banner":
                  newCreatedObj = {
                    position: indx,
                    item_type: "blog_banner",
                    enable: oldData.blog_banner_display_bool,
                    value: {},
                    style: {
                      layout: {
                        name: "",
                        text_alignment: "left",
                        text_position: "outside_bottom",
                        image_aspect_ratio: "4:3",
                      },
                      column: 2,
                    },
                  };
                  break;
                case "product_categories":
                  let selectedCatArr = [];
                  oldData.product_categories_selection_list.map((row) => {
                    selectedCatArr.push(row.toString());
                  });
                  newCreatedObj = {
                    position: indx,
                    apply_to_all_section_bool: 0,
                    item_type: "product_categories",
                    enable: oldData.product_categories_display_bool,
                    value: {
                      label: oldData.product_categories_label,
                      sorting_order: "alphabetical",

                      selection_array: selectedCatArr,
                    },
                    style: {
                      layout: {
                        name: "category_n*2",
                        text_alignment: "center",
                        text_position: "outside_bottom",
                        image_aspect_ratio: "4:3",
                      },
                      column: oldData.product_categories_columns,
                      row: 2,
                      shape:
                        oldData.product_categories_shape === "circular_corner"
                          ? "circular"
                          : oldData.product_categories_shape,
                      show_image:
                        parseInt(oldData.product_categories_type) === 1 ? 1 : 0,
                      show_heading: 1,
                      heading_color:
                        oldData.section_heading_color_object.portal_data,
                      heading_icon_color:
                        oldData.section_button_text_color_object.portal_data,
                      heading_icon_background_color:
                        oldData.section_button_color_object.portal_data,
                      section_background_color:
                        oldData.section_bg_color_object.portal_data,
                      background_color:
                        oldData.categories_solid_bg_color_object.portal_data,
                      text_color:
                        oldData.categories_text_font_color_object.portal_data,
                    },
                  };
                  break;
                case "blog_categories":
                  let selectedBlogCatArr = [];
                  oldData.blog_categories_selection_list.map((row) => {
                    selectedBlogCatArr.push(row.toString());
                  });

                  newCreatedObj = {
                    position: indx,
                    apply_to_all_section_bool: 0,
                    item_type: "blog_categories",
                    enable: oldData.blog_categories_display_bool,
                    value: {
                      label: oldData.blog_categories_label,
                      sorting_order:
                        oldData.blog_categories_sorting === "name"
                          ? "alphabetical"
                          : "most_popular",
                      selection_array: selectedBlogCatArr,
                    },
                    style: {
                      layout: {
                        name: "category_n*2",
                        text_alignment: "center",
                        text_position: "inside_center",
                        image_aspect_ratio: "4:3",
                      },
                      column: oldData.blog_categories_columns,
                      row: 2,
                      shape:
                        oldData.blog_categories_shape === "circular_corner"
                          ? "circular"
                          : oldData.blog_categories_shape,
                      show_image: 0,
                      show_heading: 1,
                      heading_color:
                        oldData.section_heading_color_object.portal_data,
                      heading_icon_color:
                        oldData.section_button_text_color_object.portal_data,
                      heading_icon_background_color:
                        oldData.section_button_color_object.portal_data,
                      section_background_color:
                        oldData.section_bg_color_object.portal_data,
                      background_color:
                        oldData.blog_categories_bg_color_object.portal_data,
                      text_color:
                        oldData.blog_categories_text_color_object.portal_data,
                    },
                  };
                  break;
                case "sale_products":
                  newCreatedObj = {
                    position: indx,
                    apply_to_all_section_bool: 0,
                    item_type: "sale_products",
                    enable: oldData.sale_products_display_bool,
                    value: {
                      label: oldData.sale_products_label,
                    },
                    style: {
                      layout: {
                        name: "product_n*2",
                        text_alignment: "left",
                        text_position: "outside_bottom",
                        image_aspect_ratio: "4:3",
                      },
                      column: 2,
                      row: 2,
                      shape: "sharp_corner",

                      show_heading: 1,
                      heading_color:
                        oldData.section_heading_color_object.portal_data,
                      heading_icon_color:
                        oldData.section_button_text_color_object.portal_data,
                      heading_icon_background_color:
                        oldData.section_button_color_object.portal_data,
                      section_background_color:
                        oldData.section_bg_color_object.portal_data,
                    },
                  };
                  break;
                case "recently_viewed_products":
                  newCreatedObj = {
                    position: indx,
                    apply_to_all_section_bool: 0,
                    item_type: "recently_viewed_products",
                    enable: oldData.recently_viewed_products_display_bool,
                    value: { label: oldData.recently_viewed_products_label },
                    style: {
                      layout: {
                        name: "product_n*2",
                        text_alignment: "left",
                        text_position: "outside_bottom",
                        image_aspect_ratio: "4:3",
                      },
                      column: 2,
                      row: 2,
                      shape: "sharp_corner",

                      show_heading: 1,
                      heading_color:
                        oldData.section_heading_color_object.portal_data,
                      heading_icon_color:
                        oldData.section_button_text_color_object.portal_data,
                      heading_icon_background_color:
                        oldData.section_button_color_object.portal_data,
                      section_background_color:
                        oldData.section_bg_color_object.portal_data,
                    },
                  };
                  break;
                case "featured_products":
                  newCreatedObj = {
                    position: indx,
                    apply_to_all_section_bool: 0,
                    item_type: "featured_products",
                    enable: oldData.featured_products_display_bool,
                    value: {
                      label: oldData.featured_products_label,
                    },
                    style: {
                      layout: {
                        name: "product_n*2",
                        text_alignment: "left",
                        text_position: "outside_bottom",
                        image_aspect_ratio: "4:3",
                      },
                      column: 2,
                      row: 2,
                      shape: "sharp_corner",

                      show_heading: 1,
                      heading_color:
                        oldData.section_heading_color_object.portal_data,
                      heading_icon_color:
                        oldData.section_button_text_color_object.portal_data,
                      heading_icon_background_color:
                        oldData.section_button_color_object.portal_data,
                      section_background_color:
                        oldData.section_bg_color_object.portal_data,
                    },
                  };
                  break;

                case "new_products":
                  newCreatedObj = {
                    position: indx,
                    apply_to_all_section_bool: 0,
                    item_type: "new_products",
                    enable: oldData.new_products_display_bool,
                    value: { label: oldData.new_products_label },
                    style: {
                      layout: {
                        name: "product_n*2",
                        text_alignment: "left",
                        text_position: "outside_bottom",
                        image_aspect_ratio: "4:3",
                      },
                      column: 2,
                      row: 2,
                      shape: "sharp_corner",

                      show_heading: 1,
                      heading_color:
                        oldData.section_heading_color_object.portal_data,
                      heading_icon_color:
                        oldData.section_button_text_color_object.portal_data,
                      heading_icon_background_color:
                        oldData.section_button_color_object.portal_data,
                      section_background_color:
                        oldData.section_bg_color_object.portal_data,
                    },
                  };
                  break;
                case "cart":
                  newCreatedObj = {
                    position: indx,
                    apply_to_all_section_bool: 0,
                    item_type: "cart",
                    enable: oldData.incart_products_display_bool,
                    value: { label: oldData.incart_products_label },
                    style: {
                      layout: {
                        name: "product_n*2",
                        text_alignment: "left",
                        text_position: "outside_bottom",
                        image_aspect_ratio: "4:3",
                      },
                      column: 2,
                      row: 2,
                      shape: "sharp_corner",

                      show_heading: 1,
                      heading_color:
                        oldData.section_heading_color_object.portal_data,
                      heading_icon_color:
                        oldData.section_button_text_color_object.portal_data,
                      heading_icon_background_color:
                        oldData.section_button_color_object.portal_data,
                      section_background_color:
                        oldData.section_bg_color_object.portal_data,
                    },
                  };
                  break;
                case "new_blogs":
                  newCreatedObj = {
                    position: indx,
                    apply_to_all_section_bool: 0,
                    item_type: "new_blogs",
                    enable: oldData.new_blogs_display_bool,
                    value: { label: oldData.new_blogs_label },
                    style: {
                      layout: {
                        name: "blog_n*2",
                        text_alignment: "left",
                        text_position: "outside_bottom",
                        image_aspect_ratio: "4:3",
                      },
                      column: 2,
                      row: 2,
                      shape: "sharp_corner",

                      show_heading: 1,
                      heading_color:
                        oldData.section_heading_color_object.portal_data,
                      heading_icon_color:
                        oldData.section_button_text_color_object.portal_data,
                      heading_icon_background_color:
                        oldData.section_button_color_object.portal_data,
                      section_background_color:
                        oldData.section_bg_color_object.portal_data,
                    },
                  };
                  break;

                case "sticky_blogs":
                  newCreatedObj = {
                    position: indx,
                    apply_to_all_section_bool: 0,
                    item_type: "sticky_blogs",
                    enable: oldData.sticky_blogs_display_bool,
                    value: { label: oldData.sticky_blogs_label },
                    style: {
                      layout: {
                        name: "blog_n*2",
                        text_alignment: "left",
                        text_position: "outside_bottom",
                        image_aspect_ratio: "1:1",
                      },
                      column: 2,
                      row: 2,
                      shape: "sharp_corner",
                      show_heading: 1,
                      heading_color:
                        oldData.section_heading_color_object.portal_data,
                      heading_icon_color:
                        oldData.section_button_text_color_object.portal_data,
                      heading_icon_background_color:
                        oldData.section_button_color_object.portal_data,
                      section_background_color:
                        oldData.section_bg_color_object.portal_data,
                    },
                  };
                  break;
                case "web_view":
                  newCreatedObj = {
                    position: indx,
                    item_type: "web_view",
                    enable: oldData.web_view_display_bool,
                    value: {
                      web_view_url: oldData.web_view_target_url,
                      // show_website_header: 1,
                      // show_website_footer: 1,
                      // hide_html_by_id: 1,
                      // hide_html_by_class: 1,
                    },
                    style: [],
                  };
                  break;
              }
              syncData.push(newCreatedObj);
            });
          let selectedPageArr = [];
          if (oldData.new_pages_selection_list.length === 0) {
            selectedPageArr = ["all"];
          } else {
            oldData.new_pages_selection_list.map((row) => {
              selectedPageArr.push(row.toString());
            });
          }
          syncData.push({
            position: syncData.length,

            item_type: "pages",

            enable: oldData.new_pages_display_bool,

            value: {
              selection_array: selectedPageArr,
              label: "",
            },
            style: {
              row: 2,
              shape: "sharp_corner",

              show_heading: 1,
              text_color: "#050000",
              icon_color: "#050000",
              // setting keys
              heading_color: "#050000",
              heading_icon_color: "#ffffff",
              heading_icon_background_color: "#c0c0c0",
              section_background_color: "#ffffff",
              // setting keys
            },
          });

          finalRes = syncData;
        }
        setHomescreenData(finalRes);
        setSpinner(false);

        setBottomMenuData(res.data.bottom_menu);
        setColorThemeData(res.data.style_data);
      }
    })
    .catch((err) => {
      
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

// Original Homescreen Banner image upload on S3 via backnd

export const originalImgUploadOnS3 = (params, callbackFn, useUrl) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(dashboardurl + "uploadImage", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data) {
        callbackFn(res.data);
      }
    })
    .catch((err) => {
      
      errortoast("Something went wrong. Try again after sometime.");
    });
};

//Final homescreen banner customize image upload on s3 for APP

export const finalImgUploadOnS3 = (params, callbackFn) => {
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      // mime = arr[0].match(/:(.*?);/)[1],
      mime = "image/png",
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  //Usage example:
  let file = dataURLtoFile(params.data, "hello.png");

  //send to s3
  const config = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
  };
  const customS3Client = new CustomS3Client(config);

  customS3Client.uploadFile(file, params.fileName)
    .then((data) => {
      if (data.status === 204) {
      }
    })
    .catch((err) => {
      errortoast("Something went wrong. Try again after sometime.");
    });
};

// Pages , Post and Blog Category in case of Wordpress and woocommerce
export const pagesBlogCatPostForWPandWC = (rest_base, setData) => {
  // let subdropdowndata=[];
  const headers = {
    "Content-Type": "application/json",
    // Authorization: "Bearer " + localStorage.getItem("amstoken"),
    // "Access-Control-Allow-Origin": "*",
  };

  axios
    .get(
      `${RedirectUrl}${localStorage.getItem(
        "store_url"
      ).replace("www.", "")}/wp-json/wp/v2/${rest_base}?page=1%26per_page=99`,

      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.status === 200) {
        setData(res.data);
      }
    })
    .catch((err) => {
      
    });
};

// Post Category
export const postCategoriesApi = (setData, setSpinner, storeUrl) => {
  // let subdropdowndata=[];
  const headers = {
    "Content-Type": "application/json",
    // Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(`${storeUrl}/wp-json/wc/v3/ams-post-categories`, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
        setData(res.data);
        setSpinner(false);
        //   let mapData = res.data;
        //   function recurs(mapData) {
        //     mapData.map((row) => {
        //       row["status"] = 0;
        //       if (row.children.length > 0) {
        //         mapData = row.children;
        //         recurs(mapData);
        //       }
        //     });
        //   }
        //   recurs(mapData);
        //   state.setState({
        //     L2Data: res.data,
        //     sortedData: mapData,
        //     sortedData2: res.data,
        //     spinnerForL2: false,
        //     breadcrumbReserveData: [],
        //   });
        // }
      }
    })
    .catch((err) => {
      
    });
};

// Product Category

export const productCategoriesApi = (setData, setSpinner, storeUrl) => {
  // let subdropdowndata=[];
  const headers = {
    "Content-Type": "application/json",
    // Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(`${storeUrl}/wp-json/wc/v3/ams-categories`, {
      headers: headers,
    })
    .then((res) => {
      if (res.status === 200) {
        setData(res.data);
        setSpinner(false);
      }
    })
    .catch((err) => {
      
    });
};

export const blogCategoriesApiForCMS = (setCategoryData, appID, setSpinner) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(cmsUrl + "getCategories/" + appID, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setSpinner(false);

        setCategoryData(res.data.data);
      }
    })
    .catch((err) => {
      
    });
};


// Upload any image to s3
export const uploadAnyBase64Imagetos3 = (
  params,
  orgFileName,

) => {
  //convert into image(base64 image to file object)
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      // mime = arr[0].match(/:(.*?);/)[1],
      mime = "image/png",
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  //Usage example:
  let file = dataURLtoFile(params.data, "hello.png");

  

  //send to s3
  const config = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
  };
  const customS3Client = new CustomS3Client(config);

  const newFileName = orgFileName;

  customS3Client.uploadFile(file, newFileName)
    .then((data) => {
      if (data.status === 204) {
        // settingImgtoArray(newFileName + ".png", "original");
      }
    })
    .catch((err) => {
     
      
   
      // errortoast("Something went wrong. Try again after sometime.");
    });
};

export const imgScalingApi = (
  params,
  callBack
) => {
    
    const headers = {
      "Accept": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
    axios
      .post(saveceritificate+'post-image-upscaler', params, {
        headers: headers,
      })
      .then((res) => {
        if(res.data.code === 200){
          callBack(res.data.data?.image);
        }else{
          callBack("");
        }
        
      })
      .catch((error) => {
        console.error(error);
        callBack("");
      });


};

export const getscalerapi = (id,scalerimage,getscalerimage,setImageProcess,setApiTimeOut,apitimeout,setFooterErr) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(saveceritificate+'get-freepik-image-upscaler/'+btoa(localStorage.getItem("Appid"))+'?upscalar_id='+id,{
        headers: headers
      })
      .then((response) => {

        if(response.data?.data?.generated && response.data?.data?.generated.length >0)
        {
            scalerimage(response.data.data.generated);
        }
        else
        {
            apitimeout = apitimeout +1;
            setApiTimeOut(apitimeout);
            getscalerimage(response.data.data.task_id,true,apitimeout);
        }
      })
      .catch((error) => {
         setFooterErr("minimumCriteriaErr");
         setImageProcess(false);
      });
};


export const uploadTempImgToS3ForScaling = (
  params,
  afterimageupload,
) => {

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      // mime = arr[0].match(/:(.*?);/)[1],
      mime = "image/png",
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  let file = dataURLtoFile(params.data, "hello.png");
  
  //send to s3
  const config = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
  };
  const customS3Client = new CustomS3Client(config);

  customS3Client.uploadFile(file, params.fileName)
    .then((data) => {
      if (data.status === 204) {
        afterimageupload(params.fileName+'.png');
      }
    })
    .catch((err) => {
      errortoast("Something went wrong. Try again after sometime.");
      console.log(err);
      
    });
};

