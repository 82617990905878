import React from "react";

const SettingIcon = ({isActive }) => {
  return (
    <div
                      className={`common-navigation-setting-btn ${
                        isActive
                          ? "common-navigation-setting-btn-clicked"
                          : ""
                      }`}
                      
                    >
    <svg className="setting-btn-svg-icon"
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 26 26"
    >
      <g
        id="Group_38340"
        data-name="Group 38340"
        transform="translate(-20 -527)"
      >
        <rect
          id="Rectangle_25572"
          data-name="Rectangle 25572"
          width="26"
          height="26"
          transform="translate(20 527)"
          fill="none"
        />
        <path
          id="Path_99646"
          data-name="Path 99646"
          d="M11.513,22.5a10.829,10.829,0,0,0,1.266-.074.615.615,0,0,0,.542-.642,2.437,2.437,0,0,1,1.488-2.388,2.278,2.278,0,0,1,.887-.171,2.527,2.527,0,0,1,1.855.788.616.616,0,0,0,.837.067,10.968,10.968,0,0,0,1.739-1.76.615.615,0,0,0-.066-.829,2.432,2.432,0,0,1,1.592-4.231h.056a.73.73,0,0,0,.72-.542,11,11,0,0,0,0-2.5.592.592,0,0,0-.613-.54h-.022A2.543,2.543,0,0,1,19.4,8.19a2.437,2.437,0,0,1,.613-2.744.614.614,0,0,0,.066-.836A10.919,10.919,0,0,0,18.3,2.851a.606.606,0,0,0-.377-.131.615.615,0,0,0-.454.2,2.532,2.532,0,0,1-1.855.785,2.259,2.259,0,0,1-.868-.165A2.449,2.449,0,0,1,13.238,1.2a.614.614,0,0,0-.544-.63,11.023,11.023,0,0,0-2.474,0,.615.615,0,0,0-.539.643A2.443,2.443,0,0,1,8.195,3.6a2.318,2.318,0,0,1-.89.172,2.524,2.524,0,0,1-1.853-.788.615.615,0,0,0-.837-.069,10.968,10.968,0,0,0-1.739,1.76.615.615,0,0,0,.066.829A2.43,2.43,0,0,1,1.279,9.733a.718.718,0,0,0-.708.54,11.037,11.037,0,0,0,0,2.5.617.617,0,0,0,.6.542h.181A2.464,2.464,0,0,1,3.6,14.808a2.438,2.438,0,0,1-.615,2.743.614.614,0,0,0-.066.837A11,11,0,0,0,4.7,20.145a.616.616,0,0,0,.83-.069,2.535,2.535,0,0,1,1.853-.785,2.3,2.3,0,0,1,.87.165A2.443,2.443,0,0,1,9.76,21.8a.614.614,0,0,0,.545.628A11.109,11.109,0,0,0,11.513,22.5Z"
          transform="translate(21.501 529.151)"
          fill={isActive ? "#7782A1" : "white"}
          stroke="#7782a1"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Path_99647"
          data-name="Path 99647"
          d="M9.818,5.846a4,4,0,1,1-4,4A4,4,0,0,1,9.818,5.846Z"
          transform="translate(23.181 530.804)"
          fill="#ffff"
          stroke="#7782A1"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
      </g>
    </svg>
    <span className="web-setting-btn-text">Settings</span>
    </div>
  );
};

export default SettingIcon;
