import React, { useState, useEffect } from "react";
import "./assets/css/common.css";
import Header from "./../Dashboard/Header";
import { Link } from "react-router-dom";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import {
  agencyPricingApi,
  paddleAgencyChangePlan,
} from "../Api/Agency/AgencyApi";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import AgencySpinner from "./AgencySpinner";
import moment from "moment";
import { Modal, ModalBody } from "reactstrap";

const AgencyPricing = (props) => {
  const [apps, setapps] = useState(1);
  const [users, setusers] = useState(1);
  const [appprice, setappprice] = useState(99);
  const [userprice, setuserprice] = useState(49);
  const [agencyPlanCode, setAgencyPlanCode] = useState("");
  const [agencyPlanName, setAgencyPlanName] = useState("");
  const [currencySymbol, setCurrencySymbol] = useState("");
  const [spinner, setSpinner] = useState(true);
  const [openSelectedIndx, setOpenSelectedIndx] = useState(null);
  const [isBtnDisable, setIsBtnDisable] = useState(false);
  const [selectedApiApps, setSelectedApiApps] = useState(0);
  const [selectedApiUsers, setSelectedApiUsers] = useState(0);
  const [agencySubscriptionPlan, setAgencySubscriptionPlan] = useState("");

  const [openCancelSubscriptionPopUp, setOpenCancelSubscriptionPopUp] =
    useState(false);
  const [endCycleDate, setendCycleDate] = useState("");
  const [submitValConfirmPopUp, setSubmitValConfirmPopUp] = useState("Confirm");
  const [isLoader, setIsLoader] = useState("d-none");

  const [downgradeAtTermEnd, setDowngradeAtTermEnd] = useState(0);
  const [paidSubscriptionApps, setPaidSubscriptionApps] = useState(0);
  const [paidSubscriptionUsers, setPaidSubscriptionUsers] = useState(0);
  const [openDowngradeAlertPopUp, setOpenDowngradeAlertPopUp] = useState(false);

  const [openReactivatePopUp, setOpenReactivatePopUp] = useState(false);
  const [submitValReactivate, setSubmitValReactivate] = useState("Confirm");
  const [reactivateLoader, setReactivateLoader] = useState("d-none");
  const [selectedfaqIndex, setSelectedfaqIndex] = useState(null);
  const [tabs, setTabs] = useState("yearly");
  const [yearlyData, setYearlyData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [licenceData, setLicenceData] = useState('');
  const [CurrentPackageStatus, setCurrentPackageStatus] = useState(null);
  const [isDealPlan, setisDealPlan] = useState(null);

  const handleAccordianToggle = (e, indx) => {
    if (indx === selectedfaqIndex) {
      setSelectedfaqIndex(null);
    } else {
      setSelectedfaqIndex(indx);
    }
  };

  const seemorefunction = () => {
    var element = document.getElementById("showfaq");
    element.classList.remove("hiddenfaq");
    document.getElementById("seemorelink").classList.add("d-none");
    document.getElementById("seelesslink").classList.remove("d-none");
  };

  const seelessfunction = () => {
    var element = document.getElementById("showfaq");
    element.classList.add("hiddenfaq");
    document.getElementById("seemorelink").classList.remove("d-none");
    document.getElementById("seelesslink").classList.add("d-none");
  };

  let { agencyID } = useParams("agencyID");

  const sliderStyle = (app, user) => {
    let element = document.getElementsByClassName("range-slider__thumb");
    if (element && element[1] && element[3]) {
      if (app < 100) {
        // element[1].classList.add('agency-translate1');
      } else {
        element[1].classList.add("agency-translate2");
      }
      if (user < 10) {
        element[3].classList.add("agency-translate1");
      } else {
        element[3].classList.add("agency-translate2");
      }
    }
  };
  useEffect(() => {
    document.title = "Pricing | AppMySite";
    
    localStorage.setItem("AgencyForThankyouPage", "true");
    localStorage.setItem("agency_id", atob(agencyID));
    localStorage.removeItem("workspaceId");
    localStorage.removeItem("workspaceName");
    localStorage.removeItem("workspaceImage");
    
    const params = { agency_id: agencyID };
    agencyPricingApi(
      params,
      setSpinner,
      setAgencyPlanCode,
      setAgencyPlanName,
      setCurrencySymbol,
      setapps,
      setusers,
      setuserprice,
      setappprice,
      setIsBtnDisable,
      setSelectedApiApps,
      setSelectedApiUsers,
      sliderStyle,
      setAgencySubscriptionPlan,
      setendCycleDate,
      setDowngradeAtTermEnd,
      setPaidSubscriptionApps,
      setPaidSubscriptionUsers,
      setYearlyData,
      setMonthlyData,
      setTabs,
      setLicenceData,
      setCurrentPackageStatus,
      setisDealPlan
    );
    // sliderStyle();
  }, []);

  const changesliderapps = (event) => {
    if (event[1] !== 0) {
      setapps(parseInt(event[1]));
      setappprice(parseInt(99) * parseInt(event[1]));
      let element = document.getElementsByClassName("range-slider__thumb");

      if (event[1] == 0 && element && element[1]) {
        element[1].classList.add("agency-translate1");
        element[1].classList.remove("agency-translate2");
      } else if (event[1] === 100 && element && element[1]) {
        element[1].classList.remove("agency-translate1");
        element[1].classList.add("agency-translate2");
      } else {
        if (element && element[1]) {
          element[1].classList.remove("agency-translate1");
          element[1].classList.remove("agency-translate2");
        }
      }
      if (
        parseInt(event[1]) === selectedApiApps &&
        users === selectedApiUsers
      ) {
        setIsBtnDisable(true);
      } else {
        setIsBtnDisable(false);
      }
    }
  };

  const changesliderusers = (event) => {
    if (event[1] !== 0) {
      setusers(parseInt(event[1]));
      setuserprice(parseInt(49) * parseInt(event[1]));
      let element = document.getElementsByClassName("range-slider__thumb");

      if (event[1] == 0 && element && element[3]) {
        element[3].classList.add("agency-translate1");
        element[3].classList.remove("agency-translate2");
      } else if (event[1] === 10 && element && element[3]) {
        element[3].classList.add("agency-translate2");
        element[3].classList.remove("agency-translate1");
      } else {
        if (element && element[3]) {
          element[3].classList.remove("agency-translate1");
          element[3].classList.remove("agency-translate2");
        }
      }
      if (parseInt(event[1]) === selectedApiUsers && apps === selectedApiApps) {
        setIsBtnDisable(true);
      } else {
        setIsBtnDisable(false);
      }
    }
  };

  const handleAccordianRow = (e, indx) => {
    if (indx === openSelectedIndx) {
      setOpenSelectedIndx(null);
    } else {
      setOpenSelectedIndx(indx);
    }
  };

  const downgradeAgencySubscription = () => {
    const paddleParam = {
      workspace_id: null,
      app_id: null,
      agency_id: parseInt(atob(agencyID)),
      deal_id: null,
      order_type: 2,
      price_id: "agency_preview",
      quantity: 1, //Optional
      type: 1,
      downgrade_at_term_end: 1,
      app_count: 0,
      user_count: 0,
    };
    setIsLoader("d-block");
    setSubmitValConfirmPopUp("");

    paddleAgencyChangePlan(
      paddleParam,
      redirectFun,
      setIsLoader,
      setSubmitValConfirmPopUp,
      setOpenCancelSubscriptionPopUp
    );
  };

  const handleCancelSubscriptionPopUp = () => {
    setOpenCancelSubscriptionPopUp(!openCancelSubscriptionPopUp);
  };
  const handleReactivateSubscription = () => {
    const paddleParam = {
      workspace_id: null,
      app_id: null,
      agency_id: parseInt(atob(agencyID)),
      deal_id: null, //Optional
      order_type: 2, //Optional
      price_id: "agency_preview",
      quantity: 1, //Optional
      type: 1, //Optional
      downgrade_at_term_end: 0,
      app_count: 0, //Optional
      user_count: 0, //Optional
    };

    setReactivateLoader("d-block");
    setSubmitValReactivate("");
    paddleAgencyChangePlan(
      paddleParam,
      redirectFun,
      setReactivateLoader,
      setSubmitValReactivate,
      setOpenReactivatePopUp
    );
  };

  // Redirect fun
  const redirectFun = () => {
    window.open("/transaction-successful", "_self");
  };

  const handleAlertDowngradePopUp = () => {
    setOpenDowngradeAlertPopUp(!openDowngradeAlertPopUp);
  };

  const handleReactivatePopUp = () => {
    setOpenReactivatePopUp(!openReactivatePopUp);
  };

  const handleTabs = (tab) => {
    setTabs(tab);
  };

  return (
    <div className="org-module">
      <Header />

      {/* {spinner ? */}
      <div className={!spinner ? "d-none" : ""}>
        <AgencySpinner />
      </div>

      <div className={spinner ? "d-none" : ""}>
        <section className="agency-pricing-page">
        
            
          
          <div className="container">
          {CurrentPackageStatus === 0 && isDealPlan === 0 && 
                    agencySubscriptionPlan !=="paddle_preview" 
                    && agencySubscriptionPlan !=="agency_preview"
                    && agencySubscriptionPlan !== process.env.REACT_APP_Lifetime_preview
                    && agencySubscriptionPlan !== process.env.REACT_APP_zoho_preview
                    && agencySubscriptionPlan !== process.env.REACT_APP_STRIPE_PREVIEW && 
                    <div className="pricing-notice">
                <h3 className="pricing-notice-header">Your current plan has been discontinued.</h3>
                <p className="pricing-notice-para">Your ongoing subscription and renewals remain unaffected by the price update.</p>
            </div>}
            <div className="agency-pricing-heading">
              <h1>Agency white-label pricing</h1>

              <div className="agency-pricing-tabs">
                <Link to={"/agency/deals/"+agencyID}>
                    <div className="apply-agency-deal">
                      <p><img
                          src={require("./assets/images/agency-deals.png").default}
                          alt="agency-deals"
                        />Apply deal</p>
                    </div>
                </Link>

                <div className="all-discount-percent">
                  <p>Save up to 20%</p>
                  <img
                    src={require("./assets/images/pricing-arrow.png").default}
                    alt="white-plus"
                  />
                </div>

                <div className="tabbed price-main-tab">
                  <input
                    type="radio"
                    id="tab1"
                    name="css-tabs"
                    checked={tabs === "monthly"}
                  />

                  <input
                    type="radio"
                    id="tab2"
                    name="css-tabs"
                    checked={tabs === "yearly"}
                  />
                  <ul className="tabs">
                    <li className="tab">
                      <label onClick={() => handleTabs("monthly")} for="tab1">
                        Monthly
                      </label>
                    </li>

                    <li className="tab">
                      <label onClick={() => handleTabs("yearly")} for="tab2">
                        Yearly
                      </label>
                    </li>
                  </ul>
                </div>

                </div>
            </div>

          {licenceData != '' ?
            <div className="deal-applied">
            { licenceData.customer_type == 2 ? 
            ( // for 2 AppSumo for 3 stackcommerce //
              <img src={require("./assets/images/appsumo.png").default} alt="appsumo-logo"/>
            )
            : licenceData.customer_type == 3 ? 
            ( // for 2 AppSumo for 3 stackcommerce //
              <img src={require("./assets/images/stack-deal.png").default} alt="stack-logo"/>
            ) 
            : 
            (
              <img src={require("./assets/images/woo-deal.png").default} alt="woo-logo"/>
            )}
            
            <h5>{licenceData.plan_id} deal selected</h5>      
            </div>
          : <></>
          }

            <div className="agency-pricing">
              <img
                src={require("./assets/images/agency-pricing.webp").default}
                alt="white-plus"
              />

              {tabs === "monthly" ? (
                <>
                  {monthlyData && monthlyData.length > 0 && (
                    <div className="agency-pricing-details">
                      <h3>What’s included?</h3>
                      <h4>
                        Get access to unlimited customers, apps, and team
                        members.
                      </h4>
                      <h5>
                        ${monthlyData[0].amount.split('.')[0]}.<span>00</span> <span>per month</span>
                      </h5>
                      <div className="agency-pricing-footer">
                        {monthlyData[0].show_plan === 0 ? (
                          <a
                            href
                            className="agency-pricing-buy disabled agency-pricing-disabled"
                          
                          >
                            Unavailable
                          </a>
                        ) : monthlyData[0].is_subscribed === 1 ? (
                          <a
                            href
                            className="agency-pricing-buy disabled agency-pricing-disabled"
                          
                          >
                            Current plan
                          </a>
                        ) : downgradeAtTermEnd === 1 ? (
                          <a
                            href
                            className="agency-pricing-buy disabled agency-pricing-disabled"
                          
                          >
                            Continue
                          </a>
                        ) : (
                          <Link
                            className="agency-pricing-buy"
                            to={
                              "/checkout/" +
                              monthlyData[0].plan_code +
                              "/" +
                              btoa(parseInt(appprice) + parseInt(userprice)) +
                              "/" +
                              btoa(monthlyData[0].plan_code) +
                              "/" +
                              btoa(
                                monthlyData[0].package_currency.currency_symbol
                              ) +
                              "/" +
                              btoa(monthlyData[0].plan_code) +
                              "/" +
                              btoa(null) +
                              "/" +
                              "1" +
                              "/" +
                              "agency" +
                              "/" +
                              undefined +
                              "/" +
                              apps +
                              "/" +
                              users
                            }
                          >
                            {" "}
                            Continue
                          </Link>
                        )}
                        {monthlyData[0].is_subscribed === 1 ? (
                          downgradeAtTermEnd === 0 ? (
                            <a
                              href
                              className="cancel-agency-subscription"
                              onClick={(e) => handleCancelSubscriptionPopUp(e)}
                            >
                              Cancel subscription
                            </a>
                          ) : (
                            <div className="agency-precing-reactivate">
                              <p className="agency-cancel-reactive">
                              Cancellation on{" "}
                                {moment(endCycleDate).format("D MMMM YYYY")}{" "}
                              </p>
                              <a onClick={(e) => handleReactivatePopUp(e)}>
                                Reactivate
                              </a>
                            </div>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {yearlyData && yearlyData.length > 0 && (
                    <div className="agency-pricing-details">
                      <h3>What’s included?</h3>
                      <h4>
                        Get access to unlimited customers, apps, and team
                        members.
                      </h4>
                      <h5>
                        ${yearlyData[0].amount.split('.')[0]}.<span>00</span> <span>per month paid yearly</span>
                      </h5>
                      <div className="agency-pricing-footer">
                        {yearlyData[0].show_plan === 0 ? (
                          <a
                            href
                            className="agency-pricing-buy disabled agency-pricing-disabled"
                            
                          >
                            Unavailable
                          </a>
                        ) : yearlyData[0].is_subscribed === 1 ? (
                          <a
                            href
                            className="agency-pricing-buy disabled agency-pricing-disabled"
                           
                          >
                            Current plan
                          </a>
                        ) : downgradeAtTermEnd === 1 ? (
                          <a
                            href
                            className="agency-pricing-buy disabled agency-pricing-disabled"
                          
                          >
                            Continue
                          </a>
                        ) : (
                          <Link
                            className="agency-pricing-buy"
                            to={
                              "/checkout/" +
                              yearlyData[0].plan_code +
                              "/" +
                              btoa(parseInt(appprice) + parseInt(userprice)) +
                              "/" +
                              btoa(yearlyData[0].plan_code) +
                              "/" +
                              btoa(
                                yearlyData[0].package_currency.currency_symbol
                              ) +
                              "/" +
                              btoa(yearlyData[0].plan_code) +
                              "/" +
                              btoa(null) +
                              "/" +
                              "1" +
                              "/" +
                              "agency" +
                              "/" +
                              undefined +
                              "/" +
                              apps +
                              "/" +
                              users
                            }
                          >
                            {" "}
                            Continue
                          </Link>
                        )}
                        {yearlyData[0].is_subscribed === 1 ? (
                          downgradeAtTermEnd === 0 ? (
                            <a
                              href
                              className="cancel-agency-subscription"
                              onClick={(e) => handleCancelSubscriptionPopUp(e)}
                            >
                              Cancel subscription
                            </a>
                          ) : (
                            <div className="agency-precing-reactivate">
                              <p className="agency-cancel-reactive">
                              Cancellation on{" "}
                                {moment(endCycleDate).format("D MMMM YYYY")}{" "}
                              </p>
                              <a onClick={(e) => handleReactivatePopUp(e)}>
                                Reactivate
                              </a>
                            </div>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            {/* <div className="agency-price-manage d-none">
                    <div className="agency-price-left">

                        <div className="agency-price-breakup">
                            <div className="agency-price-breakup-head">
                                <h2>Paid Apps<p class="agency-price-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p class="agency-price-tooltipsubheader">Select the maximum number of paid app licenses you require to upgrade your customers’ apps from preview (free) plans to paid plans.</p></p></h2>
                                <h3>${appprice+'.00'}/month</h3>
                            </div>

                            <div className="agency-pricing-dynamic-stats">
                                <p>$99 per app</p>
                                <p>{apps} {apps===1?"app":"apps"}</p>
                            </div>

                            
                            <div className="agency-range-slider">
                                <RangeSlider
                                    id="range-slider-ab1"
                                    className="single-thumb"
                                    defaultValue={[0, 100]}
                                    min={0}
                                    value={[0, apps]}
                                    thumbsDisabled={[true, false]}
                                    rangeSlideDisabled={true}
                                    onInput={changesliderapps}
                                />

                                <div className="agency-range-dots">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>

                            </div>

                            <div className="agency-dynamic-range">
                                <div className="agency-range-start">
                                <svg xmlns="http://www.w3.org/2000/svg" width="2" height="14" viewBox="0 0 2 14">
                                <rect id="Rectangle_29041" data-name="Rectangle 29041" width="2" height="14" fill="#bcc1ce"/>
                                </svg>

                                    <p>0</p>
                                </div>    
                                <div className="agency-range-end">
                                <svg xmlns="http://www.w3.org/2000/svg" width="2" height="14" viewBox="0 0 2 14">
                                <rect id="Rectangle_29041" data-name="Rectangle 29041" width="2" height="14" fill="#bcc1ce"/>
                                </svg>
                                    <p>100</p>
                                </div>
                            </div>

                            
                        </div>

                        <div className="agency-price-breakup">
                            <div className="agency-price-breakup-head">
                                <h2>Team members<p class="agency-price-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_37563" data-name="Group 37563" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p class="agency-price-tooltipsubheader">Select the maximum number of team members to manage your white-label.</p></p></h2>
                                <h3>${userprice+'.00'}/month</h3>
                            </div>
                            <div className="agency-pricing-dynamic-stats">
                                <p>$49 per user</p>
                                <p>{users} {users===1?"user":"users"}</p>
                            </div>

                            <div className="agency-range-slider">
                                <RangeSlider
                                    id="range-slider-ab"
                                    className="single-thumb"
                                    defaultValue={[0, 10]}
                                    min={0}
                                    max={10}
                                    value={[0, users]}
                                    thumbsDisabled={[true, false]}
                                    rangeSlideDisabled={true}
                                    onInput={changesliderusers}
                                />

                                <div className="agency-range-dots">
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                            </div>

                            <div className="agency-dynamic-range">
                                <div className="agency-range-start">
                                <svg xmlns="http://www.w3.org/2000/svg" width="2" height="14" viewBox="0 0 2 14">
                                <rect id="Rectangle_29041" data-name="Rectangle 29041" width="2" height="14" fill="#bcc1ce"/>
                                </svg>
                                    <p>0</p>
                                </div>    
                                <div className="agency-range-end">
                                <svg xmlns="http://www.w3.org/2000/svg" width="2" height="14" viewBox="0 0 2 14">
                                <rect id="Rectangle_29041" data-name="Rectangle 29041" width="2" height="14" fill="#bcc1ce"/>
                                </svg>
                                    <p>10</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="agency-price-right">
                        <h2>Your plan</h2>
                        <h3>${(parseInt(appprice)+parseInt(userprice))}<span>.00</span></h3>
                        <h4>per month</h4>
                        {
                            isBtnDisable ? <div className="agency-price-btn agency-pricing-currentplan" style={{
                                cursor:"not-allowed"
                            }}>Current plan</div> :
                           ( apps < paidSubscriptionApps || users < paidSubscriptionUsers )?
                             <div className={`agency-price-btn "  ${downgradeAtTermEnd===1 ?" agency-pricing-currentplan": ""}`} style={{
                            cursor:downgradeAtTermEnd===1 ?"not-allowed": "pointer"
                              }}  onClick={(e)=>downgradeAtTermEnd===1 ?null: handleAlertDowngradePopUp (e)}>Continue</div>
                               :
                              downgradeAtTermEnd===1 ? <div className="agency-price-btn agency-pricing-currentplan" style={{
                                cursor:"not-allowed"
                            }}>Continue</div> :
                            <Link className="agency-price-btn" to={"/checkout/" +
                            agencyPlanCode +
                            "/" +
                            btoa((parseInt(appprice)+parseInt(userprice))) +
                            "/" +
                            btoa(agencyPlanCode) +
                            "/" +
                            btoa(currencySymbol) +
                            "/" +
                            btoa(agencyPlanCode) +
                            "/" +
                            btoa(null) +
                            "/" +
                            "1"+"/"+
                            "agency"+"/"+undefined+"/"+ apps +"/"+users
                            }>Continue</Link>
                        }
                      
          


                            <h5>Apps <span>${appprice}</span></h5>

                        <div className="agency-price-right-final">
                            <h6>{apps} {apps===1?"Paid app":"Paid apps"}</h6>
                            <h6>per month</h6>
                        </div>

                            <h5>Team members <span>${userprice}</span></h5>

                        <div className="agency-price-right-final">
                            <h6>{users} {users===1?"member":"members"}</h6>
                            <h6>per month</h6>
                        </div>
                        {
                        agencySubscriptionPlan!=="paddle_preview" && agencySubscriptionPlan!=="agency_preview" && agencySubscriptionPlan
                        ?
                            downgradeAtTermEnd===0 ?
                        
                            <p className="agency-cancelsubscription" style={{
                                cursor:"pointer"
                            }} onClick={(e)=>handleCancelSubscriptionPopUp(e)}>Cancel subscription</p>
                            :
                            <p className="agency-cancel-reactive" >Cancellation on {moment(endCycleDate).format("D MMMM YYYY")}
                            <span onClick={(e)=>handleReactivatePopUp(e)}>Reactivate</span>
                           </p>
                        :<></>
                       
                        }
                    </div>
                </div> */}
          </div>
        </section>

        <section className="paddingbottom100">
          <div className="container">
            <div className="custom-quote">
              <div>
                <h2>Need help?</h2>
                <h3>
                  Feel free to contact us for any queries about our Agency
                  white-label product.
                </h3>
              </div>

              <a href="https://app.appmysite.com/enterprise-agency-sales">Get in touch</a>
            </div>
          </div>
        </section>

        <section className="agency-table">
          <div className="container">
            <h2>Feature highlights</h2>
            <h3>Explore the details of our Agency product offerings.</h3>

            <div className="agency-table-detail">
              <div className="agency-table-detail-list">
                <h4>Products</h4>

                <div className="agency-table-detail-feature">
                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>
                      <a
                        target="_blank"
                        href="https://www.appmysite.com/wordpress-pricing/"
                      >
                        WordPress App
                        <img
                          src={require("./assets/images/link.png").default}
                          alt="link"
                        />
                      </a>
                    </p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>
                      <a
                        target="_blank"
                        href="https://www.appmysite.com/woocommerce-pricing/"
                      >
                        WooCommerce App
                        <img
                          src={require("./assets/images/link.png").default}
                          alt="link"
                        />
                      </a>
                    </p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>
                      <a
                        target="_blank"
                        href="https://www.appmysite.com/web-to-app-pricing/"
                      >
                        Web-to-App
                        <img
                          src={require("./assets/images/link.png").default}
                          alt="link"
                        />
                      </a>
                    </p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>
                      <a
                        target="_blank"
                        href="https://www.appmysite.com/custom-app-pricing/"
                      >
                        Custom App{" "}
                        <img
                          src={require("./assets/images/link.png").default}
                          alt="link"
                        />
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <div className="agency-table-detail-list">
                <h4>Plans</h4>

                <div className="agency-table-detail-feature">
                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Free</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Starter</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Pro</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Premium</p>
                  </div>
                </div>
              </div>

              <div className="agency-table-detail-list">
                <h4>Devices</h4>

                <div className="agency-table-detail-feature">
                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Android</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>iOS</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>PWA (Coming soon)</p>
                  </div>
                </div>
              </div>

              <div className="agency-table-detail-list">
                <h4>Features</h4>

                <div className="agency-table-detail-feature">
                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Host on your domain</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Personalize builder interface</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Configure white-label settings</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Manage customer subscriptions</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Seamlessly manage customer apps</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Access app usage analytics</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Enable social logins</p>
                  </div>
                </div>
              </div>

              <div className="agency-table-detail-list">
                <h4>Capacities</h4>

                <div className="agency-table-detail-feature">
                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Unlimited customers</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Unlimited apps</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Unlimited team members</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Unlimited app builds</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Unlimited app downloads</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Unlimited app users</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Unlimited app page views</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Unlimited push notifications</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Unlimited pages</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Unlimited posts</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Unlimited products</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Unlimited payments</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>Unlimited data sync</p>
                  </div>

                  <div className="agency-feature-entry">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                    >
                      <g
                        id="Group_8367"
                        data-name="Group 8367"
                        transform="translate(-170 -1511)"
                      >
                        <circle
                          id="Ellipse_4121"
                          data-name="Ellipse 4121"
                          cx="8"
                          cy="8"
                          r="8"
                          transform="translate(170 1511)"
                          fill="#3064f9"
                        />
                        <path
                          id="Path_9596"
                          data-name="Path 9596"
                          d="M3723-16486.068h0l2.433,2.432,4.854-4.854"
                          transform="translate(-3548.643 18005.063)"
                          fill="none"
                          stroke="#fff"
                          stroke-width="2"
                        />
                      </g>
                    </svg>
                    <p>100 GB Storage</p>
                  </div>
                </div>
              </div>

              {/* <div className="agency-table-detail-list">

                    <h4>Support (Basic plan)</h4>

                    <div className="agency-table-detail-feature">

                        <div className="agency-feature-entry">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <g id="Group_8367" data-name="Group 8367" transform="translate(-170 -1511)">
                                <circle id="Ellipse_4121" data-name="Ellipse 4121" cx="8" cy="8" r="8" transform="translate(170 1511)" fill="#3064f9"/>
                                <path id="Path_9596" data-name="Path 9596" d="M3723-16486.068h0l2.433,2.432,4.854-4.854" transform="translate(-3548.643 18005.063)" fill="none" stroke="#fff" stroke-width="2"/>
                            </g>
                            </svg>
                            <p>Email support</p>
                        </div>

                        <div className="agency-feature-entry">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <g id="Group_8367" data-name="Group 8367" transform="translate(-170 -1511)">
                                <circle id="Ellipse_4121" data-name="Ellipse 4121" cx="8" cy="8" r="8" transform="translate(170 1511)" fill="#3064f9"/>
                                <path id="Path_9596" data-name="Path 9596" d="M3723-16486.068h0l2.433,2.432,4.854-4.854" transform="translate(-3548.643 18005.063)" fill="none" stroke="#fff" stroke-width="2"/>
                            </g>
                            </svg>
                            <p>Chat support</p>
                        </div>

                        <div className="agency-feature-entry">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <g id="Group_8367" data-name="Group 8367" transform="translate(-170 -1511)">
                                <circle id="Ellipse_4121" data-name="Ellipse 4121" cx="8" cy="8" r="8" transform="translate(170 1511)" fill="#3064f9"/>
                                <path id="Path_9596" data-name="Path 9596" d="M3723-16486.068h0l2.433,2.432,4.854-4.854" transform="translate(-3548.643 18005.063)" fill="none" stroke="#fff" stroke-width="2"/>
                            </g>
                            </svg>
                            <p>Phone support</p>
                        </div>

                        <div className="agency-feature-entry">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <g id="Group_8367" data-name="Group 8367" transform="translate(-170 -1511)">
                                <circle id="Ellipse_4121" data-name="Ellipse 4121" cx="8" cy="8" r="8" transform="translate(170 1511)" fill="#3064f9"/>
                                <path id="Path_9596" data-name="Path 9596" d="M3723-16486.068h0l2.433,2.432,4.854-4.854" transform="translate(-3548.643 18005.063)" fill="none" stroke="#fff" stroke-width="2"/>
                            </g>
                            </svg>
                            <p>Community support</p>
                        </div>

                        

                        </div>

                    </div> */}
            </div>
          </div>
        </section>

        <section className="agencypricing paddingtop100 paddingbottom100 pricingfaq newagencypricingfaq">
          <div className="container">
            <h3 class="sectionheadings text-center">
              Frequently asked questions
            </h3>

            <div class="accordionWrapper">
              <div
                class={`accordionItem subscriptionbottom-space ${
                  selectedfaqIndex === 0 ? "open" : "close accordion-item"
                }`}
                eventKey="0"
               
              >
                <h2
                  class="accordionItemHeading allflex accordion-header"
                  onClick={(e) => handleAccordianToggle(e, 0)}
                >
                  Is this a monthly subscription?
                  {selectedfaqIndex === 0 ? (
                    <img
                      src={require("./assets/images/faqminus.webp").default}
                      alt="pricing-type"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src={require("./assets/images/faqplus.webp").default}
                      alt="pricing-type"
                      className="img-fluid"
                    />
                  )}
                </h2>
                {selectedfaqIndex === 0 && (
                  <div class="accordionItemContent">
                    <p>
                      Yes! Agencies get access to unlimited apps, customers, and
                      team members for a monthly subscription fee. The pricing
                      structure empowers agencies to scale limitlessly at a
                      fixed monthly fee.
                    </p>
                  </div>
                )}
              </div>

              <div
                class={`accordionItem subscriptionbottom-space ${
                  selectedfaqIndex === 1 ? "open" : "close accordion-item"
                }`}
                eventKey="1"
           
              >
                <h2
                  class="accordionItemHeading allflex accordion-header"
                  onClick={(e) => handleAccordianToggle(e, 1)}
                >
                  How does the Agency product work?
                  {selectedfaqIndex === 1 ? (
                    <img
                      src={require("./assets/images/faqminus.webp").default}
                      alt="pricing-type"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src={require("./assets/images/faqplus.webp").default}
                      alt="pricing-type"
                      className="img-fluid"
                    />
                  )}
                </h2>
                {selectedfaqIndex === 1 && (
                  <div class="accordionItemContent">
                    <p>
                      You can white-label AppMySite's app-building platform, and
                      extend app-development services to your customers. Your
                      customers won’t even realize that they are building their
                      apps using AppMySite’s platform.
                    </p>
                  </div>
                )}
              </div>

              <div
                class={`accordionItem subscriptionbottom-space ${
                  selectedfaqIndex === 2 ? "open" : "close accordion-item"
                }`}
                eventKey="2"
               
              >
                <h2
                  class="accordionItemHeading allflex accordion-header"
                  onClick={(e) => handleAccordianToggle(e, 2)}
                >
                  Are the Agency offerings truly unlimited?
                  {selectedfaqIndex === 2 ? (
                    <img
                      src={require("./assets/images/faqminus.webp").default}
                      alt="pricing-type"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src={require("./assets/images/faqplus.webp").default}
                      alt="pricing-type"
                      className="img-fluid"
                    />
                  )}
                </h2>
                {selectedfaqIndex === 2 && (
                  <div class="accordionItemContent">
                    <p>
                      Yes! There is no limit to the number of customers you can
                      onboard, the number of apps they can create, or team
                      members you can collaborate with. Keep in mind that each
                      Agency subscription has a storage limit and an average app
                      uses about 500 MB of storage space.
                    </p>
                  </div>
                )}
              </div>

              <div
                class={`accordionItem subscriptionbottom-space ${
                  selectedfaqIndex === 3 ? "open" : "close accordion-item"
                }`}
                eventKey="3"
               
              >
                <h2
                  class="accordionItemHeading allflex accordion-header"
                  onClick={(e) => handleAccordianToggle(e, 3)}
                >
                  How many customers can I onboard?
                  {selectedfaqIndex === 3 ? (
                    <img
                      src={require("./assets/images/faqminus.webp").default}
                      alt="pricing-type"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src={require("./assets/images/faqplus.webp").default}
                      alt="pricing-type"
                      className="img-fluid"
                    />
                  )}
                </h2>
                {selectedfaqIndex === 3 && (
                  <div class="accordionItemContent">
                    <p>
                      There’s no restriction on the number of customers who can
                      register on your white-labeled platform.
                    </p>
                  </div>
                )}
              </div>

              <div
                class={`accordionItem subscriptionbottom-space ${
                  selectedfaqIndex === 4 ? "open" : "close accordion-item"
                }`}
                eventKey="4"
               
              >
                <h2
                  class="accordionItemHeading allflex accordion-header"
                  onClick={(e) => handleAccordianToggle(e, 4)}
                >
                  What features and products will my customers be able to
                  access?
                  {selectedfaqIndex === 4 ? (
                    <img
                      src={require("./assets/images/faqminus.webp").default}
                      alt="pricing-type"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src={require("./assets/images/faqplus.webp").default}
                      alt="pricing-type"
                      className="img-fluid"
                    />
                  )}
                </h2>
                {selectedfaqIndex === 4 && (
                  <div class="accordionItemContent">
                    <p>
                      For a detailed breakdown of features covered under each
                      plan, please refer to our{" "}
                      <Link to="/app/pricing">pricing page</Link>.
                    </p>

                    <b>Plans supported</b>
                    <p>Preview, Starter, Pro and Premium</p>

                    <b>Products supported</b>
                    <p>
                      WordPress to app, WooCommerce to app, Web to app, and
                      Custom app.
                    </p>

                    <p>
                      The features accessible to your customers depend on the
                      product they subscribe to and the plan you assign to them.
                    </p>
                  </div>
                )}
              </div>

              <div
                class={`accordionItem subscriptionbottom-space ${
                  selectedfaqIndex === 5 ? "open" : "close accordion-item"
                }`}
                eventKey="5"
              
              >
                <h2
                  class="accordionItemHeading allflex accordion-header"
                  onClick={(e) => handleAccordianToggle(e, 5)}
                >
                  How should my Agency charge my customers?
                  {selectedfaqIndex === 5 ? (
                    <img
                      src={require("./assets/images/faqminus.webp").default}
                      alt="pricing-type"
                      className="img-fluid"
                    />
                  ) : (
                    <img
                      src={require("./assets/images/faqplus.webp").default}
                      alt="pricing-type"
                      className="img-fluid"
                    />
                  )}
                </h2>
                {selectedfaqIndex === 5 && (
                  <div class="accordionItemContent">
                    <p>
                      You have the flexibility to set your pricing structure and
                      you can continue to bill your customers directly/offline.
                      However, it's advisable to consider industry standards.
                      Many successful agencies opt for one-time payments to
                      provide their customers with convenience.
                    </p>

                    <p>
                      Here are some advantages of charging a one-time payment:
                    </p>

                    <p>
                      Instant revenue: You receive the full payment upfront,
                      ensuring immediate revenue for your services.
                    </p>

                    <p>
                      Simplicity: Billing is straightforward for both you and
                      your customers, eliminating recurring billing concerns.
                    </p>

                    <p>
                      Lower churn: Typically, lower customer churn as they've
                      invested in the app.
                    </p>

                    <p>
                      Charging in alignment with industry norms can enhance the
                      perceived value of your services and simplify the billing
                      process for both you and your customers. However, it is
                      prudent to consider that an average consumer spent
                      approximately $50,000 to develop a mobile app in 2022
                    </p>

                    <p>
                      Ultimately, the choice of how to charge your customers
                      should be based on your specific business model, goals,
                      and your understanding of your customers' preferences.
                    </p>
                  </div>
                )}
              </div>

              <div className="hiddenfaq" id="showfaq">
                <div
                  class={`accordionItem subscriptionbottom-space ${
                    selectedfaqIndex === 6 ? "open" : "close accordion-item"
                  }`}
                  eventKey="6"
                
                >
                  <h2
                    class="accordionItemHeading allflex accordion-header"
                    onClick={(e) => handleAccordianToggle(e, 6)}
                  >
                    How do I manage my customer’s app subscription plan?
                    {selectedfaqIndex === 6 ? (
                      <img
                        src={require("./assets/images/faqminus.webp").default}
                        alt="pricing-type"
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        src={require("./assets/images/faqplus.webp").default}
                        alt="pricing-type"
                        className="img-fluid"
                      />
                    )}
                  </h2>
                  {selectedfaqIndex === 6 && (
                    <div class="accordionItemContent">
                      <p>
                        You’ll have access to a dedicated management interface
                        to manage your customers, their apps and subscriptions.
                        By default, each app created by your customers will be
                        on the Preview plan (free plan). You can easily manage
                        their subscription, allowing you to upgrade or downgrade
                        them as needed.
                      </p>
                      <p>
                        Keep in mind that in order to manage your customers’
                        subscriptions, you'll need to ensure you have a paid
                        Agency plan.
                      </p>
                    </div>
                  )}
                </div>

                <div
                  class={`accordionItem subscriptionbottom-space ${
                    selectedfaqIndex === 7 ? "open" : "close accordion-item"
                  }`}
                  eventKey="7"
                
                >
                  <h2
                    class="accordionItemHeading allflex accordion-header"
                    onClick={(e) => handleAccordianToggle(e, 7)}
                  >
                    How will I personalize my white-label?
                    {selectedfaqIndex === 7 ? (
                      <img
                        src={require("./assets/images/faqminus.webp").default}
                        alt="pricing-type"
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        src={require("./assets/images/faqplus.webp").default}
                        alt="pricing-type"
                        className="img-fluid"
                      />
                    )}
                  </h2>
                  {selectedfaqIndex === 7 && (
                    <div class="accordionItemContent">
                      <p>
                        As part of the white-label Agency solution, you gain
                        access to the following rebranding features.
                      </p>

                      <b>White label:</b>
                      <p>
                        Customize the platform with your own branding, making it
                        appear as your own.
                      </p>

                      <b>Host on your domain:</b>
                      <p>Allow your customers to log in from your domain.</p>

                      <b>Connect your email server:</b>
                      <p>
                        Send emails from your own email address, ensuring all
                        communication with customers is sent out from your
                        agency.
                      </p>

                      <b>Provide direct support:</b>
                      <p>
                        Provide relevant contact details and resource links,
                        allowing customers to contact you directly for support
                        and guidance.
                      </p>

                      <b>Rebrand WordPress plugin:</b>
                      <p>
                        Get access to a white-labeled WordPress plugin, allowing
                        customers to connect their website and app using your
                        private-labeled plugin.
                      </p>

                      <b>Manage customers:</b>
                      <p>
                        Efficiently manage customers from your agency dashboard
                        while gaining insights such as the number of apps, app
                        installs, last login, and more.
                      </p>
                    </div>
                  )}
                </div>

                <div
                  class={`accordionItem subscriptionbottom-space ${
                    selectedfaqIndex === 8 ? "open" : "close accordion-item"
                  }`}
                  eventKey="8"
                
                >
                  <h2
                    class="accordionItemHeading allflex accordion-header"
                    onClick={(e) => handleAccordianToggle(e, 8)}
                  >
                    What happens to my customer’s apps when I stop paying?
                    {selectedfaqIndex === 8 ? (
                      <img
                        src={require("./assets/images/faqminus.webp").default}
                        alt="pricing-type"
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        src={require("./assets/images/faqplus.webp").default}
                        alt="pricing-type"
                        className="img-fluid"
                      />
                    )}
                  </h2>
                  {selectedfaqIndex === 8 && (
                    <div class="accordionItemContent">
                      <p>
                        Once an agency discontinues payment, their plan will
                        automatically be downgraded at the end of the current
                        subscription cycle. Any customer apps created or
                        published will cease to function.
                      </p>
                    </div>
                  )}
                </div>

                <div
                  class={`accordionItem subscriptionbottom-space ${
                    selectedfaqIndex === 9 ? "open" : "close accordion-item"
                  }`}
                  eventKey="9"
                
                >
                  <h2
                    class="accordionItemHeading allflex accordion-header"
                    onClick={(e) => handleAccordianToggle(e, 9)}
                  >
                    Do you provide any support or training material for my
                    agency’s customers?
                    {selectedfaqIndex === 9 ? (
                      <img
                        src={require("./assets/images/faqminus.webp").default}
                        alt="pricing-type"
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        src={require("./assets/images/faqplus.webp").default}
                        alt="pricing-type"
                        className="img-fluid"
                      />
                    )}
                  </h2>
                  {selectedfaqIndex === 9 && (
                    <div class="accordionItemContent">
                      <p>
                        At this moment, we do not provide specific support or
                        training material for your agency's customers. However,
                        our team is committed to continually improving our
                        services to achieve our ultimate goal of simplifying app
                        development for everyone and supporting our agency
                        partners in delivering greater value to their clients.
                        We are actively working to enhance our offerings. Watch
                        this space for any new support or training materials
                        that may become available in the future.
                      </p>
                    </div>
                  )}
                </div>

                <div
                  class={`accordionItem subscriptionbottom-space ${
                    selectedfaqIndex === 10 ? "open" : "close accordion-item"
                  }`}
                  eventKey="10"
               
                >
                  <h2
                    class="accordionItemHeading allflex accordion-header"
                    onClick={(e) => handleAccordianToggle(e, 10)}
                  >
                    Is it possible to migrate live apps to my Agency
                    white-label?
                    {selectedfaqIndex === 10 ? (
                      <img
                        src={require("./assets/images/faqminus.webp").default}
                        alt="pricing-type"
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        src={require("./assets/images/faqplus.webp").default}
                        alt="pricing-type"
                        className="img-fluid"
                      />
                    )}
                  </h2>
                  {selectedfaqIndex === 10 && (
                    <div class="accordionItemContent">
                      <p>
                        Yes, it's possible. AppMySite supports the migration of
                        live apps published on the App Store and the Google Play
                        Store to your Agency white-label. No action will be
                        required from app users.
                      </p>
                    </div>
                  )}
                </div>

                <div
                  class={`accordionItem subscriptionbottom-space ${
                    selectedfaqIndex === 11 ? "open" : "close accordion-item"
                  }`}
                  eventKey="11"
                  
                >
                  <h2
                    class="accordionItemHeading allflex accordion-header"
                    onClick={(e) => handleAccordianToggle(e, 11)}
                  >
                    Do you provide custom development services?
                    {selectedfaqIndex === 11 ? (
                      <img
                        src={require("./assets/images/faqminus.webp").default}
                        alt="pricing-type"
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        src={require("./assets/images/faqplus.webp").default}
                        alt="pricing-type"
                        className="img-fluid"
                      />
                    )}
                  </h2>
                  {selectedfaqIndex === 11 && (
                    <div class="accordionItemContent">
                      <p>
                        Unfortunately no! We prioritize development that cater
                        to the broader needs of the masses rather than
                        individual requirements.
                      </p>
                    </div>
                  )}
                </div>

                <div
                  class={`accordionItem  subscriptionbottom-space ${
                    selectedfaqIndex === 12 ? "open" : "close accordion-item"
                  }`}
                  eventKey="12"
                
                >
                  <h2
                    class="accordionItemHeading allflex accordion-header"
                    onClick={(e) => handleAccordianToggle(e, 12)}
                  >
                    How can I ensure that my client's application is a success?
                    {selectedfaqIndex === 12 ? (
                      <img
                        src={require("./assets/images/faqminus.webp").default}
                        alt="pricing-type"
                        className="img-fluid"
                      />
                    ) : (
                      <img
                        src={require("./assets/images/faqplus.webp").default}
                        alt="pricing-type"
                        className="img-fluid"
                      />
                    )}
                  </h2>
                  {selectedfaqIndex === 12 && (
                    <div class="accordionItemContent">
                      <p>
                        To make your client's app a success, follow these
                        suggestions:
                      </p>

                      <ul>
                        <li>Understand user needs.</li>
                        <li>Ensure a good user experience (UX).</li>
                        <li>Offer useful features.</li>
                        <li>Optimize for mobile devices.</li>
                        <li>Market the app.</li>
                        <li>Collect user feedback.</li>
                        <li>Measure results using analytics.</li>
                      </ul>

                      <p>
                        In summary, understanding user needs, providing a
                        quality user experience, advertising the app, collecting
                        user feedback, and measuring results are crucial for the
                        success of your client's app.
                      </p>
                    </div>
                  )}
                </div>
              </div>

              <h5 id="seemorelink" onClick={seemorefunction}>
                See More
              </h5>

              <h5 id="seelesslink" class="d-none" onClick={seelessfunction}>
                See Less
              </h5>
            </div>
          </div>
        </section>
      </div>

      {/* } */}

      <Modal
        isOpen={openCancelSubscriptionPopUp}
        centered
        toggle={(e) => handleCancelSubscriptionPopUp(e)}
        style={{ maxWidth: "480px", maxHeight: "296px" }}
        className="plan-cancel-popup"
        dataclassName="addonpopup"
      >
        <div
          className="crossmodal mt-1"
          onClick={(e) => handleCancelSubscriptionPopUp(e)}
        >
          <img
            src={require("../assets/images/dashboard/cross.png").default}
            alt="cross"
          />
        </div>
        <ModalBody className="">
          <div>
            <div className="d-flex justify-content-center">
              <div className="confirmtext">
                <h4>Plan change confirmation</h4>
                {/* { this.state.customer_type === 4 ?
                                <h6>
                                Your current plan will change to Free Plan at the end of your billing cycle, on <span>{moment(this.state.endcycledata).format(
                                    "Do MMM, YYYY")}</span>{" "}
                                </h6>
                            : */}
                <>
                  {moment().format("YYYY-MM-DD") ==
                  moment(endCycleDate).format("YYYY-MM-DD") ? (
                    <>
                      <h6>Your plan renewal is being processed today.</h6>
                      <h6>
                        If you proceed, your current plan will change at the end
                        of your next billing cycle.
                      </h6>
                    </>
                  ) : (
                    <h6>
                      Your current plan will change at the end of your billing
                      cycle, on{" "}
                      <span>{moment(endCycleDate).format("D MMMM YYYY")}</span>{" "}
                    </h6>
                  )}
                </>
                {/* } */}
              </div>
            </div>
            <div className="plan-cancel-popup-footer d-flex justify-content-center">
              <div
                className="plan-cancelbtn"
                onClick={(e) => handleCancelSubscriptionPopUp()}
              >
                Cancel
              </div>

              <div
                onClick={(e) =>
                  // this.state.customer_type === 4
                  //     ? this.handleWooCustomer(
                  //         e,
                  //         this.state.customer_type,
                  //         this.state.one_time[0].plan_code
                  //     )
                  //     :
                  downgradeAgencySubscription(e)
                }
              >
                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                  <div className="plan-cacel-confirm d-flex align-items-center justify-content-center">
                    <div className={isLoader}>
                      <div className="new-red-loader"></div>
                    </div>
                    {submitValConfirmPopUp}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={openDowngradeAlertPopUp}
        centered
        toggle={(e) => handleAlertDowngradePopUp(e)}
        style={{ maxWidth: "480px", maxHeight: "296px" }}
        className="plan-cancel-popup"
        dataclassName="addonpopup"
      >
        <div
          className="crossmodal mt-1"
          onClick={(e) => handleAlertDowngradePopUp(e)}
        >
          <img
            src={require("../assets/images/dashboard/cross.png").default}
            alt="cross"
          />
        </div>
        <ModalBody className="">
          <div>
            <div className="d-flex">
              <div className="confirmtext">
                <h4>Current usage exceeds plan selection</h4>
                <h6>
                  Your current usage exceeds plan limits. In order to proceed,
                  please review and adjust the selected apps and users.
                </h6>
              </div>
            </div>
            <div className="plan-cancel-popup-footer d-flex justify-content-center">
              <div onClick={(e) => handleAlertDowngradePopUp(e)}>
                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                  <div className="plan-upgradebtn">Okay</div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={openReactivatePopUp}
        centered
        toggle={(e) => handleReactivatePopUp(e)}
        style={{ maxWidth: "480px", maxHeight: "296px" }}
        className="plan-cancel-popup"
        dataclassName="addonpopup"
      >
        <div
          className="crossmodal mt-1"
          onClick={(e) => handleReactivatePopUp(e)}
        >
          <img
            src={require("../assets/images/dashboard/cross.png").default}
            alt="cross"
          />
        </div>
        <ModalBody className="">
          <div>
            <div className="d-flex justify-content-center">
              <div className="confirmtext agency-cancelpopup">
                <h4>Plan reactivation confirmation </h4>

                <h6>Are you sure you want to reactivate your current plan?</h6>
              </div>
            </div>
            <div className="plan-cancel-popup-footer d-flex justify-content-center">
              <div
                className="plan-cancelbtn"
                onClick={(e) => handleReactivatePopUp(e)}
              >
                Cancel
              </div>

              <div onClick={(e) => handleReactivateSubscription(e)}>
                <div className="d-flex align-items-center justify-content-center h-100 w-100">
                  <div className="plan-upgradebtn">
                    <div className={reactivateLoader}>
                      <img
                        src={
                          require("../assets/images/signup/Loader_GIF.gif")
                            .default
                        }
                        alt="loader"
                      />
                    </div>
                    {submitValReactivate}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AgencyPricing;
