import React, { useState, useEffect } from "react";
import "./../assets/css/common.css";
import Header from "../../Dashboard/Header";
import LeftPanel from "./../LeftPanel";
import { useParams } from "react-router-dom";
import {
  AddonListingApi,
  AgencyPaddleDowngradeApi,
} from "../../Api/Agency/AgencyApi";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import moment from "moment";
import ModalComponent from "../../Components/commonComponents/Modal";
import { useReactivateAddonPlanMutation } from "../../services/addonsApiSlice";
import { errortoast } from "../../Dashboard/Toaster";

const Addon = () => {
  const [reactivateAddonApi, { reactivateAddonData }] =
  useReactivateAddonPlanMutation();

  const [rightpanelexpand, setRightPanelExpand] = useState(true);
  const [agencyPlanCode, setAgencyPlanCode] = useState("");
  const [agencyPlanName, setAgencyPlanName] = useState("");
  const [addonListingData, setAddonListingData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [downgradeAtTermEnd, setDowngradeAtTermEnd] = useState("");
  const [removeAddonName, setRemoveAddonName] = useState("");
  const [removeAddonPrice, setRemoveAddonPrice] = useState("");
  const [removeAddonDuration, setRemoveAddonDuration] = useState("");
  const [removeHitlocation, setRemoveHitlocation] = useState("");
  const [openCancelSubsPopUp, setOpenCancelSubsPopUp] = useState(false);
  const [loaderRemove, setLoaderRemove] = useState("d-none");
  const [submitvalConfirmpop, setSubmitvalConfirmpop] = useState("Confirm");
  const [openUpgradePlanPopUp, setOpenUpgradePlanPopUp] = useState(false);
  const [openReactivateAddonModal,setOpenReactivateAddonModal]= useState(false)
  const [getAddonListingData, setGetAddonListingData] = useState(false);

  let { agencyID } = useParams("agencyID");

  useEffect(() => {
    document.title = "Addon | AppMySite";
    const param = { plan_code: null };

    localStorage.setItem("AgencyForThankyouPage", "true");
    localStorage.removeItem("workspaceId");
    localStorage.removeItem("workspaceName");
    localStorage.removeItem("workspaceImage");

    AddonListingApi(
      param,
      "agency",
      agencyID,
      setSpinner,
      setAddonListingData,
      setDowngradeAtTermEnd,
      setAgencyPlanName,
      setOpenReactivateAddonModal,
      setLoaderRemove
    );
  }, [getAddonListingData]);

  const handleRightPanelExpand = (rightpanelvalue) => {
    setRightPanelExpand(rightpanelvalue);
  };
  const handleReturnDescription = (addon_identifier) => {
    let content = "";
    if (addon_identifier.includes("APP_STORE_DESIGN")) {
      content =
        "Get a designer to create app store artwork that resonates with your audience. ";
    } else if (addon_identifier.includes("MOBILE_APP_DESIGN")) {
      content =
        "Get professionally created design elements to make your app stand out. ";
    } else if (addon_identifier.includes("UPLOAD_TO_APP_STORE")) {
      content =
        "Get Google play and Apple app store specialists to help you publish your app. ";
    } else if (addon_identifier.includes("ANALYTICS")) {
      content =
        "Get content insights into user behavior that help you make informed decisions. ";
    } else if (addon_identifier.includes("PUBLISHING")) {
      content =
        "Upload and deploy your app to the app store directly from within your account. ";
    } else if (addon_identifier.includes("CHAT_MODULE")) {
      content =
        "Enable the chat feature in your app and address customer concerns promptly. ";
    } else if (addon_identifier.includes("LANGUAGE_SUPPORT")) {
      content =
        "Allow your app users to easily choose the language of their choice. ";
    } else if (addon_identifier.includes("MULTISITE_SUPPORT")) {
      content =
        "Publish one consolidated mobile app by combining multiple apps into one. ";
    } else if (addon_identifier.includes("USER_MANAGEMENT")) {
      content =
        "Collaborate effortlessly with additional team members, colleagues and clients. ";
    } else if (addon_identifier.includes("WHITE_LABEL")) {
      content =
        "Remove our watermark and logo from your app and rebrand it as your own. ";
    } else if (addon_identifier.includes("CONSENT_FORM")) {
      content =
        "Prompt users with a consent message to control access to your app’s content. ";
    } else if (addon_identifier.includes("BUILD_AND_PUBLISH_APP")) {
      content =
        "Let our experts handle everything from app creation to deployment to the app stores. ";
    } else if (addon_identifier.includes("RICH_NOTIFICATIONS")) {
      content =
        "Deliver targeted messaging to your app users, with rich media based notifications. ";
    } else if (addon_identifier.includes("IN_APP_PURCHASE")) {
      content =
        "Monetize your app by offering subscriptions, in-game currencies, or premium content  and collect payments without a payment gateway. ";
    } else if (addon_identifier.includes("AGENCY_PREMIUM_FEATURES")) {
      content =
        "Activate premium features across all apps in your Agency white-label account with this add-on.";
    }
    return content;
  };

  const handleReturnColor = (addon_identifier) => {
    let color = "";
    if (addon_identifier.includes("APP_STORE_DESIGN")) {
      color = "#FC8855";
    } else if (addon_identifier.includes("MOBILE_APP_DESIGN")) {
      color = "#83D298";
    } else if (addon_identifier.includes("UPLOAD_TO_APP_STORE")) {
      color = "#FF5858";
    } else if (addon_identifier.includes("ANALYTICS")) {
      color = "#7297FF";
    } else if (addon_identifier.includes("PUBLISHING")) {
      color = "#FFC834";
    } else if (addon_identifier.includes("CHAT_MODULE")) {
      color = "#FC8855";
    } else if (addon_identifier.includes("LANGUAGE_SUPPORT")) {
      color = "#83D298";
    } else if (addon_identifier.includes("MULTISITE_SUPPORT")) {
      color = "#FF5858";
    } else if (addon_identifier.includes("USER_MANAGEMENT")) {
      color = "#47C2FF";
    } else if (addon_identifier.includes("WHITE_LABEL")) {
      color = "#FFC834";
    } else if (addon_identifier.includes("CONSENT_FORM")) {
      color = "#FFC834";
    } else if (addon_identifier.includes("BUILD_AND_PUBLISH_APP")) {
      color = "#47C2FF";
    } else if (addon_identifier.includes("RICH_NOTIFICATIONS")) {
      color = "#83D298";
    } else if (addon_identifier.includes("IN_APP_PURCHASE")) {
      color = "#FC8855";
    } else if (addon_identifier.includes("AGENCY_PREMIUM_FEATURES")) {
      color = "#FC8855";
    }

    return color;
  };

  const handleReturnAddonHeading = (addon_identifier) => {
    let heading = "";
    if (addon_identifier.includes("APP_STORE_DESIGN")) {
      heading = "App store artwork";
    } else if (addon_identifier.includes("MOBILE_APP_DESIGN")) {
      heading = "Mobile app design";
    } else if (addon_identifier.includes("UPLOAD_TO_APP_STORE")) {
      heading = "Upload to App stores";
    } else if (addon_identifier.includes("ANALYTICS")) {
      heading = "Analytics";
    } else if (addon_identifier.includes("PUBLISHING")) {
      heading = "1-click publish";
    } else if (addon_identifier.includes("CHAT_MODULE")) {
      heading = "Chat";
    } else if (addon_identifier.includes("LANGUAGE_SUPPORT")) {
      heading = "Multilingual";
    } else if (addon_identifier.includes("MULTISITE_SUPPORT")) {
      heading = "Merge";
    } else if (addon_identifier.includes("USER_MANAGEMENT")) {
      heading = "Team";
    } else if (addon_identifier.includes("WHITE_LABEL")) {
      //  heading="White-label";
      heading = "Remove AppMySite branding";
    } else if (addon_identifier.includes("CONSENT_FORM")) {
      heading = "Consent";
    } else if (addon_identifier.includes("BUILD_AND_PUBLISH_APP")) {
      heading = "Build & upload my app";
    } else if (addon_identifier.includes("RICH_NOTIFICATIONS")) {
      heading = "Rich notifications";
    } else if (addon_identifier.includes("IN_APP_PURCHASE")) {
      heading = "In-App Purchase";
    } else if (addon_identifier.includes("AGENCY_PREMIUM_FEATURES")) {
      heading = "Premium features";
    }

    return heading;
  };

  const handleReturnAddonLink = (addon_identifier) => {
    let link = "";
    if (addon_identifier.includes("APP_STORE_DESIGN")) {
      link = "https://www.appmysite.com/app-store-design/";
    } else if (addon_identifier.includes("MOBILE_APP_DESIGN")) {
      link = "https://www.appmysite.com/mobile-app-design/";
    } else if (addon_identifier.includes("UPLOAD_TO_APP_STORE")) {
      link = "https://www.appmysite.com/upload-to-app-stores/";
    } else if (addon_identifier.includes("ANALYTICS")) {
      link = "https://www.appmysite.com/analytics/";
    } else if (addon_identifier.includes("PUBLISHING")) {
      link = "https://www.appmysite.com/publish/";
    } else if (addon_identifier.includes("CHAT_MODULE")) {
      link = "https://www.appmysite.com/chat/";
    } else if (addon_identifier.includes("LANGUAGE_SUPPORT")) {
      link = "https://www.appmysite.com/language-support/";
    } else if (addon_identifier.includes("MULTISITE_SUPPORT")) {
      link = "https://www.appmysite.com/merge-apps/";
    } else if (addon_identifier.includes("USER_MANAGEMENT")) {
      link = "https://www.appmysite.com/user-management/";
    } else if (addon_identifier.includes("WHITE_LABEL")) {
      link = "";
    } else if (addon_identifier.includes("CONSENT_FORM")) {
      link = "";
    } else if (addon_identifier.includes("BUILD_AND_PUBLISH_APP")) {
      link = "";
    } else if (addon_identifier.includes("RICH_NOTIFICATIONS")) {
      link = "";
    }
    return link;
  };

  const Addonimage = (addon_identifier) => {
    if (
      addon_identifier === "USER_MANAGEMENT" ||
      addon_identifier === "DEPRECATED_USER_MANAGEMENT"
    ) {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/addonsusers.png")
              .default
          }
          alt="add_user"
        />
      );
    }

    if (addon_identifier === "AGENCY_PREMIUM_FEATURES") {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/permiumAgencyAddon.png")
              .default
          }
          alt="add_user"
        />
      );
    }

    if (
      addon_identifier === "WHITE_LABEL" ||
      addon_identifier === "DEPRECATED_WHITE_LABEL"
    ) {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/addonswhitelabel.png")
              .default
          }
          alt="whitelabel"
        />
      );
    }

    if (addon_identifier === "UPLOAD_TO_APP_STORE") {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/uploadappstore.png")
              .default
          }
          alt="appstoreoptimization"
        />
      );
    }
    if (
      addon_identifier === "LANGUAGE_SUPPORT" ||
      addon_identifier === "DEPRECATED_LANGUAGE_SUPPORT"
    ) {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/addonlanguage.png")
              .default
          }
          alt="appstoreoptimization"
        />
      );
    }

    if (addon_identifier === "MOBILE_APP_DESIGN") {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/mobappdesign.png")
              .default
          }
          alt="appstoreoptimization"
        />
      );
    }
    if (
      addon_identifier === "CONSENT_FORM" ||
      addon_identifier === "DEPRECATED_CONSENT_FORM"
    ) {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/addonsconsent.png")
              .default
          }
          alt="consent"
        />
      );
    }
    if (addon_identifier === "PREVENT_SCREENSHOT") {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/landing/preventscreenshot.png")
              .default
          }
          alt="preventscreenshot"
        />
      );
    }
    if (addon_identifier === "APP_STORE_DESIGN") {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/addonsappstoredesign.png")
              .default
          }
          alt="app_store_design"
        />
      );
    }

    if (addon_identifier === "WORDPRESS_HOSTING") {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/landing/webhosting.png")
              .default
          }
          alt="wordpress_website_hosting"
        />
      );
    }
    if (
      addon_identifier === "MULTISITE_SUPPORT" ||
      addon_identifier === "DEPRECATED_MULTISITE_SUPPORT"
    ) {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/addmultisite.png")
              .default
          }
          alt="multisite_support"
        />
      );
    }
    if (addon_identifier === "ANALYTICS") {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/addAnalytics.png")
              .default
          }
          alt="analytics"
        />
      );
    }
    if (addon_identifier === "PUBLISHING") {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/autopublish.png")
              .default
          }
          alt="autopublish"
        />
      );
    }
    if (addon_identifier === "CHAT_MODULE") {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/addcustomerchat.png")
              .default
          }
          alt="chat-module"
        />
      );
    }
    if (addon_identifier === "BUILD_AND_PUBLISH_APP") {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/build&publish.png")
              .default
          }
          alt="chat-module"
        />
      );
    }
    if (addon_identifier === "IN_APP_PURCHASE") {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/Inapppurchaseaddon.png")
              .default
          }
          alt="chat-module"
        />
      );
    }
    if (addon_identifier === "RICH_NOTIFICATIONS") {
      return (
        <img
          src={
            require("../../assets/images/dashboard/addon/RichNotificationaddon.png")
              .default
          }
          alt="chat-module"
        />
      );
    }
  };

  const handleaddon_downgrade_popup = (
    addonname,
    price,
    duration,
    hitlocation
  ) => {
    setOpenCancelSubsPopUp(!openCancelSubsPopUp);
    setRemoveAddonName(addonname);
    setRemoveAddonPrice(price);
    setRemoveAddonDuration(duration);
    setRemoveHitlocation(hitlocation);
  };

  //Downgrade addon
  const handleaddon_downgrade = (addonname, price, duration, hitlocation) => {
    setSubmitvalConfirmpop("");
    setLoaderRemove("loader");

    const downgradeParam = {
      app_id: null,
      workspace_id: null,
      agency_id: parseInt(atob(agencyID)),
      addon_code: addonname,
    };
    AgencyPaddleDowngradeApi(
      downgradeParam,
      setLoaderRemove,
      setSubmitvalConfirmpop,
      redirectFun
    );
  };

  const redirectFun = () => {
    window.open(
      `/transaction-successful/${btoa(
        "paddle"
      )}?product=addon&action=downgrade`,
      "_self"
    );
  };

  const handleUpgradePopUp = () => {
    setOpenUpgradePlanPopUp(!openUpgradePlanPopUp);
  };

  const handleReactivateAddonPopup = (addonname, price, duration) => {
    setLoaderRemove("d-none");
    setOpenReactivateAddonModal(!openReactivateAddonModal)
    setRemoveAddonName(addonname)
  };
  const handleReactivate = (addonname, price, duration) => {
 
    setLoaderRemove("loader");

    const downgrade_param = {
      app_id: null,
      workspace_id: null,
      agency_id : parseInt(localStorage.getItem("agency_id")),
      addon_array: [
        {
          addon_code: addonname,
          quantity_to_remove: 1,
        },
      ],
      type: "unsubscribe",
    };
    reactivateAddonApi(downgrade_param)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          setGetAddonListingData(!getAddonListingData)
          // setAddonsState({
          //   // openReactivateAddonModal: !addonsState.openReactivateAddonModal,
          //   removeModalLoader: "d-none",
          //   reactivateLoader: false,
          // });
          // refetchAddonApiData();
        }
      })
      .catch(() => {
        errortoast("Oops! Something went wrong.");
      });
  };

  return (
    <div className="org-module org-module-customer-div">
      <Header />

      <div className="org-structure">
        <LeftPanel rightpanelbody={handleRightPanelExpand} />

        <div
          className={
            localStorage.getItem("sidebaserotate") !== null &&
            localStorage.getItem("sidebaserotate") !== undefined &&
            localStorage.getItem("sidebaserotate") === "true"
              ? "org_background org_background-expand"
              : "org_background "
          }
        >
          {localStorage.getItem("isAgencyReseller") !== "undefined" &&
          localStorage.getItem("isAgencyReseller") !== "null" &&
          localStorage.getItem("isAgencyReseller") !== "" &&
          localStorage.getItem("isAgencyReseller") !== undefined &&
          localStorage.getItem("isAgencyReseller") !== null ? (
            <div className="new-addons-spinner-div">
              {spinner ? (
                <>
                  <div className="addon-head">
                    <h1 className="addons-pluginhead">
                      Premium Features card
                      <p className="bottombar-tooltip">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                        >
                          <g
                            id="Group_35001"
                            data-name="Group 35001"
                            transform="translate(-1727 -19)"
                          >
                            <g
                              id="Ellipse_7706"
                              data-name="Ellipse 7706"
                              transform="translate(1727 19)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-width="1.5"
                            >
                              <circle cx="9" cy="9" r="9" stroke="none" />
                              <circle cx="9" cy="9" r="8.25" fill="none" />
                            </g>
                            <line
                              id="Line_343"
                              data-name="Line 343"
                              y1="2"
                              transform="translate(1736 23)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <path
                              id="Path_98224"
                              data-name="Path 98224"
                              d="M0,2V-4"
                              transform="translate(1736 31)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-width="1.5"
                            />
                          </g>
                        </svg>
                        <p className="bottombar-tooltipsubheader">
                          Unlock additional features to enhance the
                          functionality of your app.
                        </p>
                      </p>
                    </h1>
                  </div>
                  {/* <div> */}
                  <div className="newspinner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                      width="200px"
                      height="200px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g
                        transform="translate(20 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#e6261f"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.375s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(40 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#f7d038"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(60 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#49da9a"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(80 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#4355db"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                  {/* </div> */}
                </>
              ) : (
                <>
                  <div className="org-customer-head">
                    <div className="agency-headings">
                      <h1>Premium Features card</h1>
                      <p class="agency-tooltip">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                        >
                          <g
                            id="Group_35001"
                            data-name="Group 35001"
                            transform="translate(-1727 -19)"
                          >
                            <g
                              id="Ellipse_7706"
                              data-name="Ellipse 7706"
                              transform="translate(1727 19)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-width="1.5"
                            >
                              <circle cx="9" cy="9" r="9" stroke="none" />
                              <circle cx="9" cy="9" r="8.25" fill="none" />
                            </g>
                            <line
                              id="Line_343"
                              data-name="Line 343"
                              y1="2"
                              transform="translate(1736 23)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            />
                            <path
                              id="Path_98224"
                              data-name="Path 98224"
                              d="M0,2V-4"
                              transform="translate(1736 31)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-width="1.5"
                            />
                          </g>
                        </svg>
                        <p class="agency-tooltipsubheader">
                          Unlock additional features to enhance the
                          functionality of your app.
                        </p>
                      </p>
                    </div>
                  </div>

                  <div className="addons-ticket-div">
                    {addonListingData &&
                      addonListingData.map((res, addonIndx) => {
                        return (
                          <div className="addons-ticket">
                            <div
                              className="addon-strip-color"
                              style={{
                                backgroundColor: handleReturnColor(
                                  res.addon_identifier
                                ),
                              }}
                            ></div>
                            <div className="addon-card-body">
                              <div>
                                <div className="addons-tickets-head">
                                  <h1 className="addons-pluginhead-name">
                                    {handleReturnAddonHeading(
                                      res.addon_identifier
                                    )}
                                  </h1>
                                  {Addonimage(res.addon_identifier)}
                                </div>
                                <div className="addon-ticket-description">
                                  <p className="addons-description-para">
                                    {handleReturnDescription(
                                      res.addon_identifier
                                    )}
                                  </p>
                                </div>
                                <p class="addons-services">Feature</p>
                              </div>

                              <div className="addons-price-div">
                                <p className="addons-price">
                                  <>
                                    <>
                                      {res.package_currency.currency_symbol}
                                      {
                                        parseInt(
                                          res.amount.toString().split(".")[0]
                                        )
                                        // /12
                                      }
                                    </>
                                    {/* {this.state
                                                  .customer_billing_type !==
                                                3 ? (
                                                  <>
                                                    {
                                                      res.package_currency
                                                        .currency_symbol
                                                    }
                                                    {
                                                      parseInt(res.amount
                                                        .toString()
                                                        .split(".")[0])/12
                                                   
                                                    }
                                                   
                                                  </>
                                                ) : (
                                                  <>
                                                
                                                 {
                                                   this.handlePaddleCurrency(this.state.paddleaddonprice[
                                                    res.addon_code
                                                  ]
                                                    .toString()
                                                    .split(".")[0])
                                                 }
                                                 {
                                                  ((this.handlePaddlePrices( this.state.paddleaddonprice[
                                                    res.addon_code
                                                  ]
                                                    .toString()
                                                    .split(".")[0])
                                                  )/12).toLocaleString()
                                                 }
                                                  </>
                                                )} */}
                                  </>

                                  <span className="addons-price-afterdot">
                                    .
                                    {
                                      // this.state
                                      //   .customer_billing_type === 3
                                      //   ? this.state.paddleaddonprice[
                                      //       res.addon_code
                                      //     ] &&
                                      //     this.state.paddleaddonprice[
                                      //       res.addon_code
                                      //     ]
                                      //       .toString()
                                      //       .split(".")[1]
                                      //   :
                                      "00"
                                    }
                                  </span>
                                  <span className="addons-price-type">
                                    {
                                      //   res.addon_identifier ==="USER_MANAGEMENT" ?
                                      //   "per user per month paid yearly"
                                      //   :
                                      " per month"
                                    }
                                  </span>
                                </p>
                                {/* Status Start*/}

                                <div className="">
                                  {res.is_active === 1 &&
                                    (res?.downgrade_at_term_end === 1  ? (
                                      <>
                                        <p className="cancellation-addons-date">
                                          Cancellation on{" "}
                                          {moment(res.billing_date).format(
                                            "D MMMM YYYY"
                                          )}
                                        </p>
                                        <p
                                          className="addon-reactivation"
                                          onClick={() =>
                                            handleReactivateAddonPopup(
                                              res.active_addon?.length > 0
                                                ? res.active_addon[0]
                                                : res.addon_code,
                                                res.amount,
                                                res.package_duration.name.toLowerCase(),
                                              "cancel"
                                            )
                                          }
                                        >
                                          Reactivate
                                        </p>
                                      </>
                                    ) : (
                                      <>
                                        <p
                                          id={`check-active-fn${addonIndx}`}
                                          className="active-addons"
                                        >
                                          Active
                                        </p>
                                        {res.is_auto_addon !== 1 && (
                                          <p
                                            className="addon-cancel agency-single-link-cursor"
                                            onClick={() =>
                                              handleaddon_downgrade_popup(
                                                res.addon_code,
                                                res.amount,
                                                res.package_duration.name.toLowerCase(),
                                                "cancel"
                                              )
                                            }
                                          >
                                            Cancel subscription
                                          </p>
                                        )}
                                      </>
                                    ))}

                                  {/* Buy Button  */}
                                  {downgradeAtTermEnd === 1 ||
                                  agencyPlanName === "preview" ? (
                                    <Link
                                      to={"#"}
                                      onClick={(e) => handleUpgradePopUp(e)}
                                      className="addon-buy-btn"
                                    >
                                      Buy
                                    </Link>
                                  ) : res.is_deprecated === 0 ? (
                                    res.is_active !== 1 && (
                                      <Link
                                        to={{
                                          pathname: `/checkout/${
                                            res.addon_code
                                          }/${btoa(res.amount)}/${btoa(
                                            res.addon_code
                                          )}/${btoa("$")}/${btoa(null)}/${btoa(
                                            1
                                          )}/0/${"agency"}/${"undefined"}/${1}/${1}`,
                                          state: res,
                                        }}
                                        className="addon-buy-btn"
                                      >
                                        Buy
                                      </Link>
                                    )
                                  ) : null}
                                  {/* Buy Button End */}
                                </div>
                                {/* Status End*/}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* Cancel subscription Modal */}
      <Modal
        isOpen={openCancelSubsPopUp}
        centered
        className="delete-popup addons-delete-popup"
        toggle={(e) => handleaddon_downgrade_popup(e)}
        dataclassName="addonpopup"
      >
        <ModalBody className="">
          <div
            className="crossmodal mt-1"
            onClick={(e) => handleaddon_downgrade_popup(e)}
          >
            <img
              src={require("../../assets/images/dashboard/cross.png").default}
              alt="cross"
            />
          </div>
          <div
            style={{
              padding: window.matchMedia("(max-width: 1200px)").matches
                ? "15px"
                : "",
            }}
          >
            <h5>Confirm add-on cancellation?</h5>
            <h6>
            Are you sure you want to cancel this add-on subscription? Your current plan will remain active until the end of your billing cycle.
            </h6>

            <div className="d-flex align-items-center justify-content-center cms-delete-popup-footer">
              <div
                className="d-none d-xl-block grey-btn addon-popUpback-btn"
                onClick={(e) => handleaddon_downgrade_popup(e)}
              >
                <p>Back</p>
              </div>

              <div
                onClick={(e) =>
                  handleaddon_downgrade(
                    removeAddonName,
                    removeAddonPrice,
                    removeAddonDuration,
                    removeHitlocation
                  )
                }
              >
                <div className="d-flex align-items-center justify-content-center h-100 w-100 red-btn">
                  <p className="addon-delete-btn">
                    <div className={loaderRemove}>
                      <div className="new-red-loader"></div>
                    </div>
                    <div>{submitvalConfirmpop}</div>
                  </p>
                </div>
              </div>

              <div
                className="d-block d-xl-none grey-btn"
                onClick={(e) => handleaddon_downgrade_popup(e)}
              >
                <p className="addon-back-btn">Back</p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* Upgrade Pop Up */}
      <Modal
        isOpen={openUpgradePlanPopUp}
        centered
        toggle={(e) => handleUpgradePopUp(e)}
        style={{ maxWidth: "480px", maxHeight: "296px" }}
        className="plan-cancel-popup"
        dataclassName="addonpopup"
      >
        <div className="crossmodal mt-1" onClick={(e) => handleUpgradePopUp(e)}>
          <img
            src={require("../../assets/images/dashboard/cross.png").default}
            alt="cross"
          />
        </div>
        <ModalBody className="">
          <div>
            <div className="d-flex">
              <div className="confirmtext">
                <h4>Upgrade to a paid plan</h4>

                <h6>
                  To purchase add-ons, you must be on a paid plan. Upgrade your
                  plan and try again.
                </h6>
              </div>
            </div>
            <div className="plan-cancel-popup-footer d-flex justify-content-center">
              <div
                className="plan-cancelbtn cancel-btn-fontfamily"
                onClick={(e) => handleUpgradePopUp(e)}
              >
                Cancel
              </div>

              <Link
                className="addon-upgrade-btn"
                to={`/agency/pricing/${agencyID}`}
              >
                Upgrade
              </Link>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <ModalComponent
        open={openReactivateAddonModal}
        toggle={(e) => handleReactivateAddonPopup(e)}
        title="Stay on current plan?"
        description="Are you sure you want to cancel your plan change request and retain your current plan?"
        cancelbutton="Cancel"
        submitbutton="Confirm"
        onSubmit={() => handleReactivate(removeAddonName)}
        isLoading={loaderRemove !== "d-none" || spinner}
      />
    </div>
  );
};

export default Addon;
