import React from "react";

const BottomMenuItem = ({
  item,
  settings,
  isSelected,
  iconFilter,
  selectedIconFilter,
  blackIconItems,
  totalItems
}) => {
  const { item_icon = "", item_icon_type = "", item_name = "" } = item;

  const iconPath =
    item_icon_type === "light"
      ? process.env.REACT_APP_light_path
      : item_icon_type === "bold"
      ? process.env.REACT_APP_bold_path
      : item_icon_type === "regular"
      ? process.env.REACT_APP_regular_path
      : item_icon_type === "new"
      ? `${localStorage.getItem("user_id")}/${localStorage.getItem("Appid")}${
          process.env.REACT_APP_NEW_ICON_PATH
        }`
      : process.env.REACT_APP_light_path;

  const iconSrc =
    item_icon && item_icon !== ""
      ? `${process.env.REACT_APP_Image_Path}${iconPath}${item_icon}`
      : require("../../../assets/images/dashboard/create-bottom-bar.png")
          .default;

  const textColor = isSelected
    ? settings?.bottom_menu_selected_text_colour
    : settings?.bottom_menu_text_colour;

  return (
    <div className="bottombar-options">
      <div>
        {settings?.bottom_menu_show_icon && (
          <img
            className="img-fluid"
            src={iconSrc}
            alt={item_icon || "menu-item"}
            style={{
              filter:
                blackIconItems === totalItems &&
                (totalItems <= 5 ||
                  settings?.bottom_menu_icon_colour_type_for_menu === "black")
                  ? isSelected
                    ? selectedIconFilter
                    : iconFilter
                  : null
            }}
          />
        )}
        {settings?.bottom_menu_show_text && (
          <p className="bottombar-bottom-text" style={{ color: textColor }}>
            {item_name}
          </p>
        )}
      </div>
    </div>
  );
};

export default BottomMenuItem;
