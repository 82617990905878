import React from "react";
import blogplaceholder from "../../../assets/images/dashboard/homescreen/1_column.png";
import fashionimg5 from "../../../assets/images/dashboard/homescreen/4_column.png";

const BlogsPreview = ({
  blockIndx,
  blogPosition,
  blogItemType,
  blogLayout,
  blogEnable,
  blogColumn,
  blogRow,
  blogShape,
  blogPadding,
  blogShowHeading,
  blogHeadingColor,
  blogHeadingIconColor,
  blogHeadingIconBgColor,
  blogWholeSectionBgColor,
  blogHeadingLabel,
}) => {
  const calculateHeight = (width, layout) => {
    let height = (width * 3) / 4;

    switch (true) {
      case layout.image_aspect_ratio === "4:3":
        height = (width * 3) / 4;
        break;
      case layout.image_aspect_ratio === "3:4":
        height = (width * 4) / 3;
        break;
      case layout.image_aspect_ratio === "1:1":
        height = (width * 1) / 1;
        break;
      // case "16:9":
      //   height = (width * 9) / 16;
      //   break;
    }
    return height;
  };

  return (
    <>
      <div
        className="blogs-section"
        style={{ background: blogWholeSectionBgColor }}
      >
        {blogShowHeading === 1 && (
          <div className="category-head" style={{ paddingRight: "0px" }}>
            <h2
              style={{
                color: blogHeadingColor,
                fontSize: "12px",
                fontFamily: "Poppins-Semibold",
              }}
            >
              {blogItemType === "sticky_blogs"
                ? blogHeadingLabel === ""
                  ? "Popular Blog"
                  : blogHeadingLabel
                : blogHeadingLabel === ""
                ? "Recent Blog"
                : blogHeadingLabel}
            </h2>
            <div
              className="seemoresection"
              style={{
                background: blogHeadingIconBgColor,
              }}
            >
              <svg
                id="Group_26467"
                data-name="Group 26467"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                {/* <circle
                id="Ellipse_6953"
                data-name="Ellipse 6953"
                cx="8"
                cy="8"
                r="8"
                // style={{
                //   background: blogHeadingIconBgColor,
                // }}
                fill={blogHeadingIconBgColor}
              /> */}
                <path
                  id="Path_81302"
                  data-name="Path 81302"
                  d="M0,0,2.986,2.987,5.973,0"
                  transform="translate(6.499 11.473) rotate(-90)"
                  fill="none"
                  stroke={blogHeadingIconColor}
                  stroke-width="1.5"
                />
              </svg>
            </div>
          </div>
        )}

        <div className="blog-grid">
          {blogColumn === 1 &&
            blogShape === "sharp_corner" &&
            Array.from({
              length: blogRow,
            }).map((it, index) => {
              if (blogLayout.name === "blog_n*2") {
                return (
                  <div className="blog-layout-one" style={{ width: "100%" }}>
                    <div
                      style={{
                        backgroundImage: "url(" + blogplaceholder + ")",
                        width: "100%",

                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: calculateHeight(264, blogLayout),
                        // borderRadius: "10px",
                      }}
                    ></div>
                    <img
                      src={
                        require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                          .default
                      }
                    />
                  </div>
                );
              } else if (
                blogLayout.name === "blog_n*1" &&
                blogLayout.text_position === "outside_left"
              ) {
                return (
                  <div className="blog-layout-three">
                    <img
                      src={
                        require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                          .default
                      }
                    />

                    <div
                      style={{
                        backgroundImage: "url(" + fashionimg5 + ")",
                        width: "60px",

                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: calculateHeight(60, blogLayout),
                        // borderRadius: "4px",
                      }}
                    ></div>
                  </div>
                );
              } else if (blogLayout.name === "blog_1*n") {
                return (
                  <div className="blog-layout-four">
                    <div className="slider-product-width">
                      <div
                        style={{
                          backgroundImage: "url(" + blogplaceholder + ")",
                          width: "140px",
                          height: calculateHeight(140, blogLayout),
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          //   borderRadius: "4px",
                        }}
                      ></div>

                      <img
                        src={
                          require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                            .default
                        }
                      />
                    </div>
                  </div>
                );
              } else if (
                blogLayout.name === "blog_n*1" &&
                blogLayout.text_position === "outside_right"
              ) {
                return (
                  <div className="blog-layout-two">
                    <div
                      style={{
                        backgroundImage: "url(" + fashionimg5 + ")",
                        width: "60px",
                        height: calculateHeight(60, blogLayout),
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        // borderRadius: "4px",
                      }}
                    ></div>
                    <img
                      src={
                        require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                          .default
                      }
                    />
                  </div>
                );
              }
            })}

          {blogColumn === 1 &&
            blogShape === "round_corner" &&
            Array.from({
              length: blogRow,
            }).map((it, index) => {
              if (blogLayout.name === "blog_n*2") {
                return (
                  <div className="blog-layout-one" style={{ width: "100%" }}>
                    <div
                      style={{
                        backgroundImage: "url(" + blogplaceholder + ")",
                        width: "100%",

                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: calculateHeight(264, blogLayout),
                        borderRadius: "10px",
                      }}
                    ></div>
                    <img
                      src={
                        require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                          .default
                      }
                    />
                  </div>
                );
              } else if (
                blogLayout.name === "blog_n*1" &&
                blogLayout.text_position === "outside_left"
              ) {
                return (
                  <div className="blog-layout-three">
                    <img
                      src={
                        require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                          .default
                      }
                    />

                    <div
                      style={{
                        backgroundImage: "url(" + fashionimg5 + ")",
                        width: "60px",

                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: calculateHeight(60, blogLayout),
                        borderRadius: "10px",
                      }}
                    ></div>
                  </div>
                );
              } else if (blogLayout.name === "blog_1*n") {
                return (
                  <div className="blog-layout-four">
                    <div className="slider-product-width">
                      <div
                        style={{
                          backgroundImage: "url(" + blogplaceholder + ")",
                          width: "140px",
                          height: calculateHeight(140, blogLayout),
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "10px",
                        }}
                      ></div>

                      <img
                        src={
                          require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                            .default
                        }
                      />
                    </div>
                  </div>
                );
              } else if (
                blogLayout.name === "blog_n*1" &&
                blogLayout.text_position === "outside_right"
              ) {
                return (
                  <div className="blog-layout-two">
                    <div
                      style={{
                        backgroundImage: "url(" + fashionimg5 + ")",
                        width: "60px",
                        height: calculateHeight(60, blogLayout),
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "10px",
                      }}
                    ></div>
                    <img
                      src={
                        require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                          .default
                      }
                    />
                  </div>
                );
              }
            })}

          {blogColumn === 1 &&
            blogShape === "circular" &&
            Array.from({
              length: blogRow,
            }).map((it, index) => {
              if (blogLayout.name === "blog_n*2") {
                return (
                  <div className="blog-layout-one" style={{ width: "100%" }}>
                    <div
                      style={{
                        backgroundImage: "url(" + blogplaceholder + ")",
                        width: "100%",

                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: calculateHeight(264, blogLayout),
                        borderRadius: "100%",
                      }}
                    ></div>
                    <img
                      src={
                        require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                          .default
                      }
                    />
                  </div>
                );
              } else if (
                blogLayout.name === "blog_n*1" &&
                blogLayout.text_position === "outside_left"
              ) {
                return (
                  <div className="blog-layout-three">
                    <img
                      src={
                        require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                          .default
                      }
                    />

                    <div
                      style={{
                        backgroundImage: "url(" + fashionimg5 + ")",
                        width: "60px",

                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        height: calculateHeight(60, blogLayout),
                        borderRadius: "100%",
                      }}
                    ></div>
                  </div>
                );
              } else if (blogLayout.name === "blog_1*n") {
                return (
                  <div className="blog-layout-four">
                    <div className="slider-product-width">
                      <div
                        style={{
                          backgroundImage: "url(" + blogplaceholder + ")",
                          width: "140px",
                          height: calculateHeight(140, blogLayout),
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "100%",
                        }}
                      ></div>

                      <img
                        src={
                          require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                            .default
                        }
                      />
                    </div>
                  </div>
                );
              } else if (
                blogLayout.name === "blog_n*1" &&
                blogLayout.text_position === "outside_right"
              ) {
                return (
                  <div className="blog-layout-two">
                    <div
                      style={{
                        backgroundImage: "url(" + fashionimg5 + ")",
                        width: "60px",
                        height: calculateHeight(60, blogLayout),
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "100%",
                      }}
                    ></div>
                    <img
                      src={
                        require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                          .default
                      }
                    />
                  </div>
                );
              }
            })}

          {blogColumn === 2 &&
            blogShape === "sharp_corner" &&
            Array.from({
              length: blogRow,
            }).map((it, index) => {
              if (blogLayout.name === "blog_n*2") {
                return (
                  <>
                    <div className="blog-layout-one">
                      <div
                        style={{
                          backgroundImage: "url(" + blogplaceholder + ")",
                          width: "100%",
                          height: calculateHeight(126, blogLayout),
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          // borderRadius: "4px",
                        }}
                      ></div>
                      <img
                        src={
                          require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                            .default
                        }
                      />
                    </div>

                    <div className="blog-layout-one">
                      <div
                        style={{
                          backgroundImage: "url(" + blogplaceholder + ")",
                          width: "100%",
                          height: calculateHeight(126, blogLayout),
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          // borderRadius: "4px",
                        }}
                      ></div>
                      <img
                        src={
                          require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                            .default
                        }
                      />
                    </div>
                  </>
                );
              } else if (
                blogLayout.name === "blog_n*1" &&
                blogLayout.text_position === "outside_left"
              ) {
                return (
                  <div className="blog-layout-three">
                    <img
                      src={
                        require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                          .default
                      }
                    />

                    <div
                      style={{
                        backgroundImage: "url(" + fashionimg5 + ")",
                        width: "60px",
                        height: calculateHeight(60, blogLayout),
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        // borderRadius: "4px",
                      }}
                    ></div>
                  </div>
                );
              } else if (blogLayout.name === "blog_1*n") {
                return (
                  <div className="blog-layout-four">
                    <div className="slider-product-width">
                      <div
                        style={{
                          backgroundImage: "url(" + blogplaceholder + ")",
                          width: "140px",
                          height: calculateHeight(140, blogLayout),
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          //   borderRadius: "4px",
                        }}
                      ></div>

                      <img
                        src={
                          require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                            .default
                        }
                      />
                    </div>

                    <div className="slider-product-width">
                      <div
                        style={{
                          backgroundImage: "url(" + blogplaceholder + ")",
                          width: "140px",
                          height: calculateHeight(140, blogLayout),
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          //   borderRadius: "4px",
                        }}
                      ></div>

                      <img
                        src={
                          require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                            .default
                        }
                      />
                    </div>
                  </div>
                );
              } else if (
                blogLayout.name === "blog_n*1" &&
                blogLayout.text_position === "outside_right"
              ) {
                return (
                  <div className="blog-layout-two">
                    <div
                      style={{
                        backgroundImage: "url(" + fashionimg5 + ")",
                        width: "60px",
                        height: calculateHeight(60, blogLayout),
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        // borderRadius: "4px",
                      }}
                    ></div>
                    <img
                      src={
                        require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                          .default
                      }
                    />
                  </div>
                );
              }
            })}
          {blogColumn === 2 &&
            blogShape === "round_corner" &&
            Array.from({
              length: blogRow,
            }).map((it, index) => {
              if (blogLayout.name === "blog_n*2") {
                return (
                  <>
                    <div className="blog-layout-one">
                      <div
                        style={{
                          backgroundImage: "url(" + blogplaceholder + ")",
                          width: "100%",
                          height: calculateHeight(126, blogLayout),
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "10px",
                        }}
                      ></div>
                      <img
                        src={
                          require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                            .default
                        }
                      />
                    </div>

                    <div className="blog-layout-one">
                      <div
                        style={{
                          backgroundImage: "url(" + blogplaceholder + ")",
                          width: "100%",
                          height: calculateHeight(126, blogLayout),
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "10px",
                        }}
                      ></div>
                      <img
                        src={
                          require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                            .default
                        }
                      />
                    </div>
                  </>
                );
              } else if (
                blogLayout.name === "blog_n*1" &&
                blogLayout.text_position === "outside_left"
              ) {
                return (
                  <div className="blog-layout-three">
                    <img
                      src={
                        require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                          .default
                      }
                    />

                    <div
                      style={{
                        backgroundImage: "url(" + fashionimg5 + ")",
                        width: "60px",
                        height: calculateHeight(60, blogLayout),
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "10px",
                      }}
                    ></div>
                  </div>
                );
              } else if (blogLayout.name === "blog_1*n") {
                return (
                  <div className="blog-layout-four">
                    <div className="slider-product-width">
                      <div
                        style={{
                          backgroundImage: "url(" + blogplaceholder + ")",
                          width: "140px",
                          height: calculateHeight(140, blogLayout),
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "10px",
                        }}
                      ></div>

                      <img
                        src={
                          require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                            .default
                        }
                      />
                    </div>

                    <div className="slider-product-width">
                      <div
                        style={{
                          backgroundImage: "url(" + blogplaceholder + ")",
                          width: "140px",
                          height: calculateHeight(140, blogLayout),
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "10px",
                        }}
                      ></div>

                      <img
                        src={
                          require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                            .default
                        }
                      />
                    </div>
                  </div>
                );
              } else if (
                blogLayout.name === "blog_n*1" &&
                blogLayout.text_position === "outside_right"
              ) {
                return (
                  <div className="blog-layout-two">
                    <div
                      style={{
                        backgroundImage: "url(" + fashionimg5 + ")",
                        width: "60px",
                        height: calculateHeight(60, blogLayout),
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "10px",
                      }}
                    ></div>
                    <img
                      src={
                        require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                          .default
                      }
                    />
                  </div>
                );
              }
            })}
          {blogColumn === 2 &&
            blogShape === "circular" &&
            Array.from({
              length: blogRow,
            }).map((it, index) => {
              if (blogLayout.name === "blog_n*2") {
                return (
                  <>
                    <div className="blog-layout-one">
                      <div
                        style={{
                          backgroundImage: "url(" + blogplaceholder + ")",
                          width: "100%",
                          height: calculateHeight(126, blogLayout),
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "100%",
                        }}
                      ></div>
                      <img
                        src={
                          require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                            .default
                        }
                      />
                    </div>

                    <div className="blog-layout-one">
                      <div
                        style={{
                          backgroundImage: "url(" + blogplaceholder + ")",
                          width: "100%",
                          height: calculateHeight(126, blogLayout),
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "100%",
                        }}
                      ></div>
                      <img
                        src={
                          require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                            .default
                        }
                      />
                    </div>
                  </>
                );
              } else if (
                blogLayout.name === "blog_n*1" &&
                blogLayout.text_position === "outside_left"
              ) {
                return (
                  <div className="blog-layout-three">
                    <img
                      src={
                        require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                          .default
                      }
                    />

                    <div
                      style={{
                        backgroundImage: "url(" + fashionimg5 + ")",
                        width: "60px",
                        height: calculateHeight(60, blogLayout),
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "100%",
                      }}
                    ></div>
                  </div>
                );
              } else if (blogLayout.name === "blog_1*n") {
                return (
                  <div className="blog-layout-four">
                    <div className="slider-product-width">
                      <div
                        style={{
                          backgroundImage: "url(" + blogplaceholder + ")",
                          width: "140px",
                          height: calculateHeight(140, blogLayout),
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "100%",
                        }}
                      ></div>

                      <img
                        src={
                          require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                            .default
                        }
                      />
                    </div>

                    <div className="slider-product-width">
                      <div
                        style={{
                          backgroundImage: "url(" + blogplaceholder + ")",
                          width: "140px",
                          height: calculateHeight(140, blogLayout),
                          backgroundPosition: "center",
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          borderRadius: "100%",
                        }}
                      ></div>

                      <img
                        src={
                          require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                            .default
                        }
                      />
                    </div>
                  </div>
                );
              } else if (
                blogLayout.name === "blog_n*1" &&
                blogLayout.text_position === "outside_right"
              ) {
                return (
                  <div className="blog-layout-two">
                    <div
                      style={{
                        backgroundImage: "url(" + fashionimg5 + ")",
                        width: "60px",
                        height: calculateHeight(60, blogLayout),
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat",
                        borderRadius: "100%",
                      }}
                    ></div>
                    <img
                      src={
                        require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                          .default
                      }
                    />
                  </div>
                );
              }
            })}

          {blogColumn > 2 && blogLayout.name === "blog_1*n" && (
            // <div className="blog-layout-four">
            //   <div className="slider-product-width">
            //     <div
            //       style={{
            //         backgroundImage: "url(" + blogplaceholder + ")",
            //         width: "140px",
            //         height: calculateHeight(140, blogLayout),
            //         backgroundPosition: "center",
            //         backgroundSize: "cover",
            //         backgroundRepeat: "no-repeat",
            //         borderRadius:
            //           blogShape === "circle"
            //             ? "100%"
            //             : blogShape === "round"
            //             ? "10px"
            //             : "",
            //       }}
            //     ></div>

            //     <img
            //       src={
            //         require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
            //           .default
            //       }
            //     />
            //   </div>

            //   <div className="slider-product-width">
            //     <div
            //       style={{
            //         backgroundImage: "url(" + blogplaceholder + ")",
            //         width: "140px",
            //         height: calculateHeight(140, blogLayout),
            //         backgroundPosition: "center",
            //         backgroundSize: "cover",
            //         backgroundRepeat: "no-repeat",
            //         borderRadius:
            //           blogShape === "circle"
            //             ? "100%"
            //             : blogShape === "round"
            //             ? "10px"
            //             : "",
            //         width: "116px",
            //       }}
            //     ></div>

            //     <img
            //       src={
            //         require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
            //           .default
            //       }
            //     />
            //   </div>
            // </div>
            <div className="blog-layout-four">
              <div className="slider-product-width">
                <div
                  style={{
                    backgroundImage: "url(" + blogplaceholder + ")",
                    width: "140px",
                    height: calculateHeight(140, blogLayout),
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    borderRadius:
                      blogShape === "circular"
                        ? "100%"
                        : blogShape === "round_corner"
                        ? "10px"
                        : "",
                  }}
                ></div>

                <img
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                      .default
                  }
                />
              </div>

              <div className="slider-product-width">
                <div
                  style={{
                    backgroundImage: "url(" + blogplaceholder + ")",
                    width: "140px",
                    height: calculateHeight(140, blogLayout),
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    backgroundRepeat: "no-repeat",
                    borderRadius:
                      blogShape === "circular"
                        ? "100%"
                        : blogShape === "round_corner"
                        ? "10px"
                        : "",
                  }}
                ></div>

                <img
                  src={
                    require("../../../assets/images/dashboard/homescreen/blog-data-one.png")
                      .default
                  }
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogsPreview;
