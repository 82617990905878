import React from "react";
import { Modal, ModalBody } from "reactstrap";
import backIc from "../../../assets/images/signup/back.png";
import { DEVICE_TYPE } from "../../../Constants/notificationConsts";

const MobDeviceFilterModal = ({ notificationData, setNotificationData }) => {
  const handleDeviceFilterChange = (val) =>
    setNotificationData({
      deviceFilterVal: val,
      openMobDeviceFilter: false,
    });

  return (
    <Modal
      className="protip-popup-message"
      isOpen={
        window.matchMedia("(max-width: 1200px)").matches
          ? notificationData.openMobDeviceFilter
          : false
      }
      centered
    >
      <ModalBody>
        <div className="mobileglobalsearch">
          <button
            onClick={() =>
              setNotificationData({
                openMobDeviceFilter: !notificationData.openMobDeviceFilter,
              })
            }
          >
            <img src={backIc} alt="back" />
          </button>
        </div>

        <div className="mobilesearchsuggestion">
          <p
            className="mobilesearchsuggestion-para"
            onClick={() => handleDeviceFilterChange(DEVICE_TYPE.android)}
          >
            Android
          </p>
          <p
            className="mobilesearchsuggestion-para"
            onClick={() => handleDeviceFilterChange(DEVICE_TYPE.ios)}
          >
            iOS
          </p>

          <p
            className="mobilesearchsuggestion-para"
            onClick={() => handleDeviceFilterChange(DEVICE_TYPE.both)}
          >
            Both
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MobDeviceFilterModal;
