import React, { useEffect, useMemo, useState } from "react";
import { Markup } from "interweave";
import postscribe from "postscribe";
import { useRef } from "react";
import gallery1 from "./../../assets/images/dashboard/cms/gallery1.png";
import imagebackground from "./../../assets/images/dashboard/my-account.png";

import imagebackground2 from "./../../assets/images/dashboard/cms/no-post-img.png";

import imagebackground3 from "./../../assets/images/dashboard/cms/uploadbackground.png";

import moment from "moment";
import related1 from "./../../assets/images/dashboard/cms/related1.png";
import related2 from "./../../assets/images/dashboard/cms/related2.png";
import related3 from "./../../assets/images/dashboard/cms/related3.png";
import related4 from "./../../assets/images/dashboard/cms/related4.png";
import userdefaultImgOnError from "../../assets/images/dashboard/accountprofile.png";
import defaultImg from "../../assets/images/dashboard/cms/no-post-img.png";

import CmsEditor from "./CmsEditor";
import { cmsGetPostsApi } from "../../Api/CmsApi/Cmsapi";
import ReactPlayer from "react-player";

const Preview = ({
  cmsData,
  title,
  openItemIndex,
  diff,
  publishImmediate,
  dateTimeValue,
  rightSideRef,
  showAuthor,
  showPublishDate,
  showCat,
  showTag,
  showRelatedPosts,
  selectedCat,
  selectedTag,
  authorName,
  authorImg,
  showAuthorRef,
  showCatRef,
  showTagRef,
  showPublishDateRef,
  showRelatedPostsRef,
  postId,
  atrListingData,
  adsData,
  selectedDevice,
  sectionTopMargin,
  sectionBottomMargin,
  sectionLeftMargin,
  sectionRightMargin,
  imageShape,
  imgCenterForDetails,
  imgCenterForListing,
  imgShapeForListing,
  imgRatioForListing,
  postState,
}) => {
  const messageRef = useRef();
  const [postData, setPostData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [categoryFilter, setCategoryFilter] = useState(null);
  const [page, setPage] = useState(1);
  const [paginationSpinner, setPaginationSpinner] = useState(false);
  const [hitNextPagination, setHitNextPagination] = useState(false);
  const [subData, setSubData] = useState([]);
  const [galleryContainerWidth, setGalleryContainerWidth] = useState(0);
  const [imageContainerWidth, setImageContainerWidth] = useState(0);

  const imageContainerRef = useRef(null);
  const galleryContainerRef = useRef(null);

  useEffect(() => {
    if (diff === "post") {
      let limit = 100;
      let pageNo = 1;
      cmsGetPostsApi(
        setPostData,
        btoa(localStorage.getItem("Appid")),
        setSpinner,
        categoryFilter,
        limit,
        pageNo,
        postData,
        setSubData,
        setHitNextPagination,
        setPaginationSpinner
      );
    }
  }, []);




  useEffect(() => {
    const updateImageWidth = () => {
      if (imageContainerRef.current) {
        setImageContainerWidth(imageContainerRef.current.offsetWidth);
      }
    };

    updateImageWidth();

    window.addEventListener("resize", updateImageWidth);

    return () => {
      window.removeEventListener("resize", updateImageWidth);
    };
  }, [cmsData.length]);


  useEffect(() => {
    const updateWidth = () => {
      if (galleryContainerRef.current) {
        setGalleryContainerWidth(galleryContainerRef.current.offsetWidth);
      }
    };

    updateWidth();

    window.addEventListener("resize", updateWidth);

    return () => {
      window.removeEventListener("resize", updateWidth);
    };
  }, [cmsData.length]);


  

  const calculateHeight = (ratio, width) => {
    let height = (width * 3) / 4;
    switch (ratio) {
      case "4:3":
        height = (width * 3) / 4;
        break;
      case "3:4":
        height = (width * 4) / 3;
        break;
      case "1:1":
        height = (width * 1) / 1;
        break;
      case "16:9":
        height = (width * 9) / 16;
        break;
      case "9:16":
          height = (width * 16) / 9;
          break;
    }
    return height;
  };

  const returnSelectedScript = (adId) => {
    let script = "";
    adsData &&
      adsData.map((ad) => {
        if (adId === ad.id) {
          script =
            selectedDevice === "android"
              ? ad.android_ad_custom_script
              : ad.ios_ad_custom_script;
        }
      });
    return script;
  };

  const calculateHeightFinalHeight = (adId) => {
    let finalHeight = 10;
    let customWidth = 50;
    let customHeight = 50;
    // let mobWidth=this.props.value.state.fitBanner?302:286;
    adsData &&
      adsData.map((ad) => {
        if (adId === ad.id) {
          customWidth = ad.custom_ad_width;
          customHeight = ad.custom_ad_height;
        }
      });
    let mobWidth = 290;
    finalHeight = (mobWidth * customHeight) / customWidth;
    return finalHeight;
  };



  return (
    <div className="page-preview-screen">
      {diff === "post" && (
        <div className="preview-header">
          <img
            className="img-fluid"
            src={require("../../assets/images/dashboard/leftarrow.png").default}
          />
        </div>
      )}
      <div style={{ height: "100%" }} ref={rightSideRef}>
        {cmsData &&
          cmsData.map((cmsRow, indx) => {
            switch (cmsRow.item_type) {
              case "title":
                return (
                  <div
                    key={indx}
                    style={{
                      display: cmsRow.status === 0 ? "none" : "",
                    }}
                  >
                    <div
                      // ref={openItemIndex === indx ? messageRef : null}
                      className={diff === "page" ? "preview-header" : ""}
                    >
                      {diff === "page" && (
                        <div className="cms-pages-main-head">
                          <div className="cms-pages-head-div">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="15.733"
                              height="14.433"
                              viewBox="0 0 15.733 14.433"
                            >
                              <g
                                id="Group_42221"
                                data-name="Group 42221"
                                transform="translate(0.707 1.364)"
                              >
                                <path
                                  id="Path_118871"
                                  data-name="Path 118871"
                                  d="M-377.533-3200.5l-6.863,6.863,6.863,6.863"
                                  transform="translate(384.396 3199.49)"
                                  fill="none"
                                  stroke="#1a1a1a"
                                  stroke-width="1"
                                />
                                <path
                                  id="Path_118872"
                                  data-name="Path 118872"
                                  d="M-654.5-3185.5h15.026"
                                  transform="translate(654.5 3191.336)"
                                  fill="none"
                                  stroke="#1a1a1a"
                                  stroke-width="1"
                                />
                              </g>
                            </svg>
                            {cmsRow.value.title.text ? (
                              <h1>{cmsRow.value.title.text}</h1>
                            ) : (
                              <h1>Enter your bold and clear title here.</h1>
                            )}
                          </div>
{/* 
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15.395 15"
                          >
                            <g
                              id="Group_42147"
                              data-name="Group 42147"
                              transform="translate(0.5 0.618)"
                            >
                              <path
                                id="Path_118839"
                                data-name="Path 118839"
                                d="M6.827,23.414a2.364,2.364,0,0,1-.207.974,2.4,2.4,0,1,1,.207-.974Z"
                                transform="translate(-2 -15.812)"
                                fill="none"
                                stroke="#1a1a1a"
                                stroke-linejoin="round"
                                stroke-width="1"
                              />
                              <circle
                                id="Ellipse_9345"
                                data-name="Ellipse 9345"
                                cx="2.5"
                                cy="2.5"
                                r="2.5"
                                transform="translate(9.395 -0.118)"
                                fill="none"
                                stroke="#1a1a1a"
                                stroke-linejoin="round"
                                stroke-width="1"
                              />
                              <circle
                                id="Ellipse_9346"
                                data-name="Ellipse 9346"
                                cx="2.5"
                                cy="2.5"
                                r="2.5"
                                transform="translate(9.395 8.882)"
                                fill="none"
                                stroke="#1a1a1a"
                                stroke-linejoin="round"
                                stroke-width="1"
                              />
                              <line
                                id="Line_436"
                                data-name="Line 436"
                                x1="4.704"
                                y1="2.075"
                                transform="translate(4.621 8.575)"
                                fill="none"
                                stroke="#1a1a1a"
                                stroke-linejoin="round"
                                stroke-width="1"
                              />
                              <line
                                id="Line_437"
                                data-name="Line 437"
                                x1="4.907"
                                y2="2.741"
                                transform="translate(4.519 3.683)"
                                fill="none"
                                stroke="#1a1a1a"
                                stroke-linejoin="round"
                                stroke-width="1"
                              />
                            </g>
                          </svg> */}
                        </div>
                      )}
                    </div>
                    {diff === "post" && (
                      <>
                        {/* <div className="preview-header">
            <img
              className="img-fluid"
              src={
                require("../../assets/images/dashboard/leftarrow.png").default
              }
            />
          </div> */}

                        <div
                          className="create-post-title"
                          style={{
                            margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                          }}
                        >
                          <div ref={showCatRef}>
                            {showCat && (
                              <div className="post-detail-category-preview">
                                {selectedCat &&
                                  selectedCat.map((eachCat) => {
                                    return (
                                      <h6>
                                        {eachCat.name}
                                        <span>|</span>
                                      </h6>
                                    );
                                  })}
                              </div>
                            )}
                          </div>
                          <h1> {cmsRow.value.title.text}</h1>

                          {(dateTimeValue === "" &&
                            publishImmediate !== true) ||
                          title === "" ? (
                            <>
                              {!cmsRow.value.title.text && (
                                <h1
                                // style={{color: "#CECECE"}}
                                >
                                  Enter title here
                                </h1>
                              )}
                              {showPublishDate && (
                                <h4 style={{ color: "#A1A1A1" }}>
                                  12 June 2022
                                </h4>
                              )}
                            </>
                          ) : (
                            <div className="post-detail-author">
                              {showAuthor && (
                                <img
                                  className="img-fluid"
                                  src={authorImg}
                                  style={{
                                    width: "26px",
                                    height: "26px",
                                    borderRadius: "100%",
                                  }}
                                  alt="profile-img"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null;
                                    currentTarget.src = imagebackground;
                                  }}
                                />
                              )}
                              <div
                                style={{
                                  width: !showAuthor ? "unset" : "",
                                  height: !showAuthor ? "unset" : "",
                                }}
                              >
                                {showAuthor && <h4>{authorName}</h4>}
                                {showPublishDate && (
                                  <h5>
                                    {publishImmediate === true
                                      ? moment().format("DD MMMM YYYY")
                                      : moment(dateTimeValue).format(
                                          "DD MMMM YYYY"
                                        )}
                                  </h5>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                );
              case "image":
                return (
                  <div
                    key={indx}
                    style={{
                      display: cmsRow.status === 0 ? "none" : "",
                     margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                    }}
                  >
                    <div id="imgae-sec-main-margin-div"
                    ref={imageContainerRef}
                    //  ref={openItemIndex === indx ? messageRef : null}
                    >
                      <div
                        className="cms-banner-img"
                        style={{
                          height:
                          calculateHeight(cmsRow.style.aspect_ratio, imageContainerWidth) +
                            "px",
                          width: "100%",
                          backgroundColor:
                            cmsRow.portal_value.source_file !== "" &&
                            cmsRow.portal_value.source_file !== undefined &&
                            cmsRow.portal_value.source_file !== null
                              ? ""
                              : "#EBEBEB",
                        }}
                      >
                        {cmsRow.portal_value.source_file !== "" &&
                        cmsRow.portal_value.source_file !== undefined &&
                        cmsRow.portal_value.source_file !== null ? (
                          <div
                            className="fullwidthbanner"
                            style={{
                              borderRadius:
                                imageShape === "soft_corner" ? "6px" : "",
                              height: "100%",
                              //   calculateHeight(cmsRow.style.aspect_ratio,  274) +
                              //   "px",
                              width: "100%",
                              backgroundRepeat: "no-repeat",
                              backgroundPosition: "center",
                              backgroundSize: imgCenterForDetails
                                ? "cover"
                                : "contain",
                              backgroundImage:
                                "url(" +
                                //   cmsRow.portal_value.source_file ===
                                //   "rainbow-wall.jpg" ||
                                // cmsRow.portal_value.source_file ===
                                //   "floating-unicorn.jpg"
                                //   ? process.env.REACT_APP_Image_Path +
                                //     process.env.REACT_APP_DEFAULT_CMS_IMG +
                                //     cmsRow.portal_value.source_file
                                //   :
                                (cmsRow.portal_value.source_file.indexOf(
                                  "https"
                                ) > -1
                                  ? cmsRow.portal_value.source_file
                                  : process.env.REACT_APP_Image_Path +
                                    localStorage.getItem("user_id") +
                                    "/" +
                                    localStorage.getItem("Appid") +
                                    process.env.REACT_APP_CMS_Media +
                                    cmsRow.portal_value.source_file) +
                                ")",
                            }}
                          ></div>
                        ) : (
                          <img
                            className="img-fluid"
                            src={
                              require("../../assets/images/dashboard/default-img.png")
                                .default
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                );
              case "heading":
                return (
                  <div
                    key={indx}
                    style={{
                      display: cmsRow.status === 0 ? "none" : "",
                     margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                    }}
                  >
                    <div
                      // ref={openItemIndex === indx ? messageRef : null}
                      className="page-body"
                    >
                      {cmsRow.value.heading.text ? (
                        <h2
                          // className={
                          //   `headings ${
                          //     cmsRow.style.bold_text ? "heading-bold" : ""
                          //   } ` +
                          //   `${
                          //     cmsRow.style.italic_text ? "heading-italiq" : ""
                          //   } ` +
                          //   `${
                          //     cmsRow.style.underline ? "heading-underline" : ""
                          //   } ` +
                          //   `${cmsRow.style.text_align}`
                          // }
                          className={`headings heading-bold text-${cmsRow.style.text_align}`}
                        >
                          {cmsRow.value.heading.text}
                        </h2>
                      ) : (
                        <h2
                          // style={{color: "#CECECE"}}
                          className={`headings heading-bold 
                     
                      `}
                        >
                          Enter your bold and clear heading here.
                        </h2>
                      )}
                    </div>
                  </div>
                );
              case "paragraph":
                return (
                  <div
                    key={indx}
                    style={{
                      display: cmsRow.status === 0 ? "none" : "",
                     margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                    }}
                  >
                    <div
                      // ref={openItemIndex === indx ? messageRef : null}
                      className="page-body"
                    >
                      {cmsRow.value.paragraph.text !== undefined &&
                      cmsRow.value.paragraph.text !== null &&
                      cmsRow.value.paragraph.text !== "" &&
                      cmsRow.value.paragraph.text !== "<p><br></p>" ? (
                        <>
                          {cmsRow.value.paragraph !== undefined &&
                            cmsRow.value.paragraph.text !== undefined && (
                              <Markup content={cmsRow.value.paragraph.text} />
                            )}
                        </>
                      ) : (
                        <p style={{ color: "#CECECE" }}>Paragraph</p>
                      )}
                    </div>
                  </div>
                );
              case "code":
                return (
                  <div
                    key={indx}
                    style={{
                      display: cmsRow.status === 0 ? "none" : "",
                        margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                    }}
                  >
                    <div
                      // ref={openItemIndex === indx ? messageRef : null}
                      className="page-body"
                    >
                      <iframe
                        srcDoc={cmsRow.value.code.text}
                        style={{
                          width: "100%",
                          minHeight: "198px",
                          border: "1px solid #dadada",
                        }}
                      />
                      {/* <CmsEditor value={cmsRow.value.code.text}/> */}
                    </div>
                  </div>
                );
              case "spacer":
                return (
                  <div
                    key={indx}
                    style={{
                      display: cmsRow.status === 0 ? "none" : "",
                        margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                    }}
                  >
                    <div
                      // ref={openItemIndex === indx ? messageRef : null}
                      className="Spacer"
                      style={{ height: `${cmsRow.style.height}px` }}
                    ></div>
                  </div>
                );
              case "separator":
                return (
                  <div
                    key={indx}
                    style={{
                      display: cmsRow.status === 0 ? "none" : "",
                       margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                    }}
                  >
                    <div
                      // ref={openItemIndex === indx ? messageRef : null}
                      className=""
                      style={{
                        height: cmsData[indx].style.height + "px",
                        background: cmsData[indx].style.color,
                        // border:
                        //   `${cmsData[indx].style.height}px` +
                        //   " solid " +
                        //   `${cmsData[indx].style.color}`,
                      }}
                    ></div>
                  </div>
                );
              case "button":
                return (
                  <div
                    style={{
                       margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                    }}
                  >
                    <div
                      className="managebutton"
                      key={indx}
                      style={{
                        display: cmsRow.status === 0 ? "none" : "",
                      }}
                    >
                      <a
                        // ref={openItemIndex === indx ? messageRef : null}
                        className="linkbutton"
                        // target="_blank"
                        // href={`${cmsData[indx].value.button.target}`}

                        style={{
                          color: `${cmsData[indx].style.text_color}`,
                          background: `${cmsData[indx].style.button_color}`,
                          borderRadius: "3px",
                        }}
                      >
                        {cmsData[indx].value.button.text}
                      </a>
                    </div>
                  </div>
                );
              case "gallery":
                return (
                  <div
                    key={indx}
                    style={{
                      display: cmsRow.status === 0 ? "none" : "",
                      margin:`0px ${(sectionRightMargin==0?"-4":sectionRightMargin)+"px"} 0px ${(sectionLeftMargin==0?"-4":sectionLeftMargin)+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                    }}
                  >
                    <div
                    // ref={openItemIndex === indx ? messageRef : null}
                    >
                      <div className="page-body ">
                        {/* GalleryLayouts */}
                        <div style={{ margin: "-4px" }}>
                          <div className="gallerygrid" id="gallery-main-margin-div" ref={galleryContainerRef}>
                            {cmsRow.portal_value &&
                              cmsRow.showOnlyArr &&
                              cmsRow.showOnlyArr.map((eachImage, i) => {
                                return (
                                  <div
                                    // className={`col-md-${calculateRowAndCol(
                                    //   cmsRow.style.row
                                    // )} `}
                                    className={`custom-gallery ${
                                      (i + 1) % cmsRow.style.row === 0
                                        ? "not-padding-class"
                                        : "padding-class"
                                    }`}
                                    style={{
                                      width: `${100 / cmsRow.style.column}%`,
                                      height:
                                      calculateHeight(
                                        cmsRow.style.aspect_ratio,
                                        (galleryContainerWidth / cmsRow.style.column )
                                      ) + "px",
                                    }}
                                  >
                                    {cmsRow.portal_value[i].source_file !==
                                      "" &&
                                    cmsRow.portal_value[i].source_file !==
                                      undefined &&
                                    cmsRow.portal_value[i].source_file !==
                                      null ? (
                                      <div
                                        className="img-fluid"
                                        style={{
                                          borderRadius:
                                            imageShape === "soft_corner"
                                              ? "6px"
                                              : "",
                                          backgroundRepeat: "no-repeat",
                                          backgroundPosition: "center",
                                          backgroundSize: imgCenterForDetails
                                            ? "cover"
                                            : "contain",
                                          width: "100%",
                                          height: "100%",
                                          backgroundImage:
                                            "url(" +
                                            (cmsRow.portal_value[
                                              i
                                            ].source_file.indexOf("https") > -1
                                              ? cmsRow.portal_value[i]
                                                  .source_file
                                              : process.env
                                                  .REACT_APP_Image_Path +
                                                localStorage.getItem(
                                                  "user_id"
                                                ) +
                                                "/" +
                                                localStorage.getItem("Appid") +
                                                process.env
                                                  .REACT_APP_CMS_Media +
                                                cmsRow.portal_value[i]
                                                  .source_file) +
                                            ")",
                                        }}
                                      />
                                    ) : (
                                      <div className="custom-gallery-default-img">
                                      <img
                                        className="img-fluid"
                                        src={
                                          require("../../assets/images/dashboard/default-img.png")
                                            .default
                                        }

                                        // src=
                                      />
                                      </div>
                                    )}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              case "custom_video":
                return (
                  <div
                    key={indx}
                    style={{
                      display: cmsRow.status === 0 ? "none" : "",
                       margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                    }}
                  >
                    <div className="page-body">
                      {cmsRow.portal_value.source_file ? (
                        <ReactPlayer
                          controls={true}
                          url={
                            cmsRow.portal_value.source_file.indexOf("http") > -1
                              ? cmsRow.portal_value.source_file
                              : process.env.REACT_APP_Image_Path +
                                localStorage.getItem("user_id") +
                                "/" +
                                localStorage.getItem("Appid") +
                                process.env.REACT_APP_CMS_Media +
                                cmsRow.portal_value.source_file
                          }
                          width={"100%"}
                          height={"100%"}
                          light={
                            cmsRow.portal_value.thumbnail ? (
                              <img
                                style={{
                                  width: "100%",
                                  height: "100%",
                                }}
                                src={
                                  // require(
                                  process.env.REACT_APP_Image_Path +
                                  localStorage.getItem("user_id") +
                                  "/" +
                                  localStorage.getItem("Appid") +
                                  process.env.REACT_APP_CMS_Media +
                                  cmsRow.portal_value.thumbnail
                                  // )
                                  //   .default
                                }
                                alt="Thumbnail"
                              />
                            ) : (
                              false
                            )
                          }
                        />
                      ) : (
                        <img
                          className="img-fluid"
                          src={
                            require("../../assets/images/dashboard/video-placeholder-new.png")
                              .default
                          }
                        />
                      )}
                    </div>
                  </div>
                );
              case "vimeo_video":
                return (
                  <div
                    key={indx}
                    style={{
                      display: cmsRow.status === 0 ? "none" : "",
                       margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                    }}
                  >
                    <div className="page-body">
                      {cmsRow.portal_value.link &&
                      cmsRow.portal_value.is_link_err === false ? (
                        <ReactPlayer
                          controls={true}
                          url={cmsRow.portal_value.link}
                          width={"100%"}
                          height={"100%"}
                        />
                      ) : (
                        <img
                          className="img-fluid"
                          src={
                            require("../../assets/images/dashboard/vimeo-placeholder.png")
                              .default
                          }
                        />
                      )}
                    </div>
                  </div>
                );
              case "facebook_video":
                return (
                  <div
                    key={indx}
                    style={{
                      display: cmsRow.status === 0 ? "none" : "",
                      margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                    }}
                  >
                    <div className="page-body">
                      {cmsRow.portal_value.link &&
                      cmsRow.portal_value.is_link_err === false ? (
                        <ReactPlayer
                          controls={true}
                          url={cmsRow.portal_value.link}
                          width={"100%"}
                          height={"100%"}
                        />
                      ) : (
                        <img
                          className="img-fluid"
                          src={
                            require("../../assets/images/dashboard/facebook-placeholder.png")
                              .default
                          }
                        />
                      )}
                    </div>
                  </div>
                );
              case "youtube_video":
                return (
                  <div
                    key={indx}
                    style={{
                      display: cmsRow.status === 0 ? "none" : "",
                      margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                    }}
                  >
                    <div className="page-body">
                      {cmsRow.portal_value.link &&
                      cmsRow.portal_value.is_link_err === false ? (
                        <ReactPlayer
                          controls={true}
                          url={cmsRow.portal_value.link}
                          width={"100%"}
                          height={"100%"}
                          style={{ marginBottom: "-6px" }}
                        />
                      ) : (
                        <img
                          className="img-fluid"
                          src={
                            require("../../assets/images/dashboard/youtube-placeholder.png")
                              .default
                          }
                        />
                      )}
                    </div>
                  </div>
                );

              case "attribute":
                return (
                  <div
                    key={indx}
                    style={{
                      display: cmsRow.status === 0 ? "none" : "",
                       margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                    }}
                  >
                    <div className="attributes-preview">
                      <div className="page-body">
                        {atrListingData &&
                          atrListingData.map((atr) => {
                            return (
                              atr.is_checked === true && (
                                <div className="attributes-segment">
                                  <h4>
                                    {atr.attribute_icon_name ? (
                                      <img
                                        className="img-fluid"
                                        src={
                                          process.env.REACT_APP_Image_Path +
                                          localStorage.getItem("user_id") +
                                          "/" +
                                          localStorage.getItem("Appid") +
                                          process.env.REACT_APP_NEW_ICON_PATH +
                                          atr.attribute_icon_name
                                        }
                                        alt="icon"
                                      />
                                    ) : (
                                      <img
                                        src={imagebackground3}
                                        className="img-fluid"
                                      />
                                    )}

                                    {atr.attribute_name}
                                  </h4>
                                  {atr.attribute_terms &&
                                    atr.attribute_terms.length > 0 && (
                                      <div>
                                        {atr.attribute_terms &&
                                          atr.attribute_terms.map((atrVal) => {
                                            return (
                                              atrVal.is_checked === true && (
                                                <p>
                                                  {atrVal.attribute_term_name}
                                                </p>
                                              )
                                            );
                                          })}
                                      </div>
                                    )}
                                </div>
                              )
                            );
                          })}
                      </div>
                    </div>
                  </div>
                );
              case "banner_ad":
                return (
                  <div
                    style={{
                      display: cmsRow.status === 0 ? "none" : "",
                      margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                    }}
                  >
                    <div className="banner-ad-block">
                      <p>Google Admob banner</p>
                    </div>
                  </div>
                );
              case "custom_ad":
                return (
                  <>
                    {cmsRow.value.ad_id !== undefined &&
                    cmsRow.value.ad_id !== null &&
                    cmsRow.value.ad_id !== "" ? (
                      <div
                        style={{
                          height: calculateHeightFinalHeight(
                            cmsRow.value.ad_id
                          ),
                          display: cmsRow.status === 0 ? "none" : "",
                           margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                        }}
                      >
                        <iframe
                          srcDoc={returnSelectedScript(cmsRow.value.ad_id)}
                          style={{
                            width: "100%",
                            height: "100%",
                            margin: "0",
                            border: "1px solid #e3e6f1",
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        style={{
                          display: cmsRow.status === 0 ? "none" : "",
                           margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                        }}
                      >
                        <div className="custom-ad-block">
                          <p>Custom ad</p>
                        </div>
                      </div>
                    )}
                  </>
                );
            }
          })}

        {diff === "post" && (
          <div ref={showTagRef}>
            {showTag && (
              <div
                style={{
                   margin:`0px ${sectionRightMargin+"px"} 0px ${sectionLeftMargin+"px"}`,
                   padding:`${sectionTopMargin+"px"} 0px ${sectionBottomMargin+"px"} 0px`
                }}
                className="post-detail-tag-preview"
              >
                {selectedTag &&
                  selectedTag.map((eachTag, i) => {
                    return <h5>{eachTag.name}</h5>;
                  })}
              </div>
            )}
          </div>
        )}

        {diff === "post" &&
          postData &&
          postData.length > 0 &&
          showRelatedPosts && <hr></hr>}

        <div ref={showRelatedPostsRef}>
          {diff === "post" && (
            <div
              style={{
                display: showRelatedPosts ? "" : "none",
              }}
              className="post-listing-style-two"
            >
                          {postData && postData.length > 0 && (
     postData
       .map((eachPost, indx) => {
        return((eachPost.show_in_results===1 ||eachPost.show_in_results===undefined ) && eachPost._id !== postId && 
        <div className="post-list-type-two">

        <div className="post-type-two-data" style={{textAlign:"left"}}>
          {
            eachPost.title && eachPost.title.length>=70 ?
          
          <h3 className="post-two-heading" style={{
          fontSize:"9px"
          }}>{eachPost.title.substring(0, 70) + '...' }</h3>
          :
          <h3 className="post-two-heading" style={{
          fontSize:"9px"
          }}>{eachPost.title}</h3>
    }

{
postState.settings.showExcerptForListing &&
 eachPost.excerpt &&

<h5 className="post-two-excerpt">{eachPost.excerpt.length >= 70 ? eachPost.title.substring(0, 70) + '...' :eachPost.excerpt}</h5> }
     
     <div className="post-list-author" >
 {
   postState.settings.showAuthorOnList && 
   <div className="post-author-grid-lt">
                             {eachPost.author_info  ? (

                     !eachPost.author_info?.profile_image ? (
                       <img
                         src={userdefaultImgOnError}
                         className="img-fluid"
                         alt="profile logo"
                       />
                     ) : (
                       <>
                         {eachPost.author_info?.profile_image?.indexOf(
                           "https"
                         ) === -1 ? (
                           <img
                             className="header-profil-img"
                             src={
                               process.env.REACT_APP_Image_Path +
                               eachPost.author_info.id +
                               "/" +
                               "profile" +
                               "/" +
                               eachPost.author_info?.profile_image
                             }
                         
                             onError={({ currentTarget }) => {
                               currentTarget.onerror = null;
                               currentTarget.src = userdefaultImgOnError;
                             }}
                           />
                         ) : (
                           <img
                             className="header-profil-img"
                             src={eachPost.author_info?.profile_image}
                           
                             onError={({ currentTarget }) => {
                               currentTarget.onerror = null;
                               currentTarget.src = userdefaultImgOnError;
                             }}
                           />
                         )}
                       </>
                     )
                 ) :
                 <img
                         
                         src={userdefaultImgOnError}
                         className="img-fluid"
                         alt="profile logo"
                       /> }
                             </div>

               }




               
 
   {
   postState.settings.showAuthorOnList &&   <h4 className="post-two-author">{eachPost.author_info !== undefined &&
     eachPost.author_info !== null
       ? eachPost.author_info.name
       : eachPost.owner_user
       ? eachPost.owner_user.name
       : localStorage.getItem("user_name")}</h4> } 
  {
   postState.settings.showAuthorOnList &&  <div className="author-seprator"></div> } 
   { postState.settings.showPublishDateForListing  && 
          <h6 className="post-two-timestamp">    {moment(eachPost.created_at).format(
                  "D MMMM YYYY, HH:mm"
                )}
                
                </h6>  
        } 
</div>


        </div>
        <div
                className="post-type-two-thumbnail"
                style={{
                 height:calculateHeight(imgRatioForListing,70),
                 borderRadius:imgShapeForListing==="soft_corner"?"10px":imgShapeForListing==="circle"?"100%":"0px",
                 // backgroundPosition: (!eachPost.source_file || imgCenterForListing) ? "": "unset",
                 backgroundSize:imgCenterForListing?"cover": "contain",
                  backgroundImage:
                    "url(" +
                    (!eachPost.source_file
                      ? defaultImg
                      : 
                        eachPost.source_file.indexOf("https") > -1 ? 
                        eachPost.source_file
                        :
                        process.env.REACT_APP_Image_Path +
                        localStorage.getItem("user_id") +
                        "/" +
                        localStorage.getItem("Appid") +
                        process.env.REACT_APP_CMS_Media +
                        eachPost.source_file) +
                    ")",
                }}
              ></div>
  

        </div>
           )}))
            }
       
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Preview;
