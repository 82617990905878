import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import goBack from "../../../assets/images/dashboard/goBackicon.png";

const PhoneCodeModal = ({
  settingsState,
  togglephonecodemodal,
  setSettingsState,
  handleDropdownSearch,
  handleCountryDropdown
}) => {
  return (
    <Modal
      isOpen={settingsState.phonecodemodal}
      toggle={togglephonecodemodal}
      centered
      dataclass="countrycode"
      className="country-dropdown-modal"
    >
      <ModalHeader toggle={togglephonecodemodal} className=" ">
        <div className=" country-search-div">
          <div className="d-flex align-items-center w-100 ">
            <div className="flag-backaero-popup" onClick={togglephonecodemodal}>
              <img src={goBack} alt="back-icon" />
            </div>

            <input
              type="text"
              className="form-control mob-flagsearch-input"
              placeholder="Search..."
              value={settingsState.dropdownSearchVal}
              onChange={(e) => handleDropdownSearch(e)}
            />
            {settingsState.dropdownSearchVal.trim() && (
              <p
                onClick={(e) =>
                  setSettingsState({
                    dropdownSearchVal: "",
                  })
                }
                className="cleartext"
              >
                Clear
              </p>
            )}
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="flag-code-selector">
        <div className=" countrycode-flag-div ">
          {settingsState.countryselectfield
            .filter((name) =>
              name.country_name
                .toLowerCase()
                .includes(settingsState.dropdownSearchVal.toLowerCase())
            )
            .map((res, i) => (
              <div
                className=" country-line-flag-name-div"
                key={i}
                onClick={(e) => handleCountryDropdown(e, res)}
              >
                <div className=" mob-country-flag-name-div">
                  <img
                    src={
                      process.env.REACT_APP_Image_Path +
                      process.env.REACT_APP_SMALL_FLAG +
                      res.country_code.toLowerCase() +
                      ".png"
                    }
                    alt={`flag${i}`}
                  />
                  <p className="country-name">{res.country_name}</p>
                </div>
              </div>
            ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PhoneCodeModal;
