import React, {useEffect, useState} from "react";
import "./assets/css/common.css";
import Header from "./../Dashboard/Header";
import LeftPanel from "./LeftPanel";
import { Link, useParams } from "react-router-dom";
import {errortoast} from '../Dashboard/Toaster';
import ReactColorPicker from "./NewReactcolorpicker";
import imagebackground from "./assets/images/uploadbackground.png";
import moment from "moment";
import {
    Spinner,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import {
    getAgencyName,
	saveAgencyDetails,
	DeleteAgency,
	LeaveAgency
} from "../Api/Agency/AgencyApi";
import UploadFaviconPopup from "./../Dashboard/NewImageGalleryPopup";
import UploadLogoPopup from "./../Dashboard/NewImageGalleryPopup";
import NewSpinner from "./NewSpinner";
import imagebackground2 from "./assets/images/your-logo.png";

const AgencyDetails = (props) =>{

	const [mdluserleave, setMdlUserLeave] = useState(false);

    const [mdluserdelete, setMdlUserDelete] = useState(false);
	const [mdllimitdelete, setMdlLimitDelete] = useState(false);
	const [spinner, setSpinner] = useState(true);
	const [orgname, setOrgName] = useState("");
	const [primarycolor, setPrimaryColor] = useState("#3064f9");
	const [buttoncolor, setButtonColor] = useState("#3064f9");
	const [buttontextcolor, setButtonTextColor] = useState("#ffffff");
	const [buttonhovercolor, setButtonHoverColor] = useState("#ffffff");
	const [buttonhovertextcolor, setButtonHoverTextColor] = useState("#3064f9");
	const [secondarycolor, setSecondaryColor] = useState("#3064f9");

	const [preprimarycolor, setPrePrimaryColor] = useState(null);	
	const [presecondarycolor, setPreSecondaryColor] = useState(null);
	
	const [faviconupdtxt, setFaviconUpdTxt] = useState("Upload");
	const [prevfavicon, setPrevFavicon] = useState("");
	const [isfaviconpopup, setIsFaviconPopup] = useState(false);
	const [faviconimage, setFaviconImage] = useState(null);
	const [faviconimgerror, setFaviconImgError] = useState("");
	
	const [prevlogo, setPrevLogo] = useState("");
	const [erroragencyname, setErrorAgencyName] = useState("");
	const [erroragencyfavicon, setErrorAgencyFavicon] = useState("");
	const [islogopopup, setIsLogoPopup] = useState(false);
	const [logoimage, setLogoImage] = useState(null);
	const [logoimgerror, setLogoImgError] = useState("");

	const [btnsavelabel, setBtnSaveLabel] = useState("SAVE");
	const [btnloader, setBtnLoader] = useState("d-none");
	const [logouploadresp, setLogoUploadResp] = useState(null);
	const [orgnameconfirm, setOrgNameConfirm] = useState("");

	const [userid, setUserID] = useState("");

	const [btndeleteloader, setBtnDeleteLoader] = useState("d-none");
	const [btndeletelabel, setDeleteLabel] = useState("Delete");
	const [btnlimitlabel, setLimitLabel] = useState("Okay");


	const [btnleaveloader, setBtnLeaveLoader] = useState("d-none");
	const [btnleavelabel, setLeaveLabel] = useState("Leave");
	const [isadd, setIsAdd] = useState(false);
	const [rightpanelexpand,setRightPanelExpand] = useState(true);
	const [lastdate,setLastUpdatedAt] = useState("");
	const [appscount,setappscount] = useState(0);

	//const [localuserid, setLocalUserId] = useState(localStorage.getItem("user_id"));
	const localuserid = localStorage.getItem("user_id");
	//const agencyimgpath = process.env.REACT_APP_Image_Path+localuserid+'/'+process.env.REACT_APP_Agency_Profile;
	const agencyimgpath = process.env.REACT_APP_Image_Path + "agencies/" + localStorage.getItem("agency_id") + process.env.REACT_APP_Agency_Profile;	


	let { agencyID } = useParams("agencyID");

	useEffect(()=>{
		
		if(agencyID !== '' && agencyID !== undefined)
		{
			getAgencyName(agencyID, setOrgName, setSpinner, setFaviconImage, setLogoImage, setUserID, setPrimaryColor, setSecondaryColor, setFaviconUpdTxt,setButtonColor,setButtonTextColor,setLastUpdatedAt,setappscount,setButtonHoverColor,setButtonHoverTextColor);
		}

		document.title = "Settings | AppMySite";

		
	}, []);

	const handleAgencyName = (e) =>{

		setOrgName(e.target.value);
		setErrorAgencyName('');
	}

	const handlePrimaryColor = (e) =>{

		setPrimaryColor(e);

	}

	const handleSecondaryColor = (e) =>{

		setSecondaryColor(e);

	}

	const handleButtonColor = (e) =>{

		setButtonColor(e);

	}

	const handleButtonTextColor = (e) =>{

		setButtonTextColor(e);

	}

	const handleButtonHoverColor = (e) =>{

		setButtonHoverColor(e);

	}

	const handleButtonHoverTextColor = (e) =>{

		setButtonHoverTextColor(e);

	}

	const handleUploadLogoPopup = () =>{

		setIsLogoPopup(true);
		setPrevLogo(logoimage);
	}
	const handleLogoUpload = (imgName, from, orgFileName, url1200, useUrl) =>{


		if(from === 'resize')
		{
			setLogoImage(imgName);
			localStorage.setItem("agencylogo", imgName);
		}
		else
		{
			//setLogoImage(imgName); //original
		}
		
		
		
	}
	const handleLogoErrorData = (errimgdata) =>{


	}

	const handleUploadFaviconPopup = () =>{

		setIsFaviconPopup(true);
		setPrevFavicon(faviconimage);
		setErrorAgencyFavicon('');

	}
	const handleFaviconUpload = (imgName, from, orgFileName, url1200, useUrl) =>{


		if(from === 'resize')
		{
			setFaviconImage(imgName);
			setFaviconUpdTxt(imgName);
		}
		else
		{
			//setFaviconImage(imgName); //original
		}
		

	}
	const handleFaviconErrorData = (errimgdata) =>{


	} 

	const handleSaveOrgDetails= (e) =>{

		e.preventDefault();
		if(orgname === '' || orgname === null)
        {
            setErrorAgencyName("Agency name is required");
        }

		// if(faviconimage === '' || faviconimage === null)
        // {
        //     setErrorAgencyFavicon("Agency Favicon is required");
        // }

		// if(orgname == '' || orgname == null || faviconimage == '' || faviconimage == null)
        // {
        //     return;
        // }

		if(orgname == '' || orgname == null)
        {
            return;
        }

		const params = {

			agency_id: parseInt(atob(agencyID)),
			agency_name: orgname,
			agency_logo: logoimage,
			brand_primary_color: rgbaToHex(primarycolor),
			brand_secondary_color: rgbaToHex(secondarycolor),
			button_background_color: rgbaToHex(buttoncolor),
			button_text_color: rgbaToHex(buttontextcolor),
			button_hover_bgcolor: rgbaToHex(buttonhovercolor),
			button_hover_txtcolor: rgbaToHex(buttonhovertextcolor),
			favicon: faviconimage
		};


		setBtnLoader("d-block");

		setBtnSaveLabel("");

		saveAgencyDetails(params, setBtnSaveLabel, setBtnLoader, redirectToOrgListPage,setLastUpdatedAt);
	}
    
	/*const btnCloseLeavePopup = (e) => {
        e.preventDefault();
        setUserDelete(!userdelete);
        setMdlUserDelete(!mdluserdelete);
	};
      
	const deleteAccount1 = (e) => {
		e.preventDefault();
		setUserDelete1(!userdelete1);
		setMdlUserDelete1(!mdluserdelete1);
	};*/

	const redirectToOrgListPage = (redirecturl) =>{

        props.history.push(redirecturl);
    }

	const handleDeleteAgencyPopup = () =>
	{
		if(appscount > 0)
		{
			setMdlLimitDelete(true);	
		}
		else
		{
			setMdlUserDelete(true);
		}

	}
	const btnCancelOrgPopup = () =>{
		setMdlUserDelete(false);
	}
	const btnCancelLimitPopup = () =>
	{
		setMdlLimitDelete(false);
	}
	const btnDeleteAgency = (event) =>{
		
		event.preventDefault();

		if(orgnameconfirm === '')
		{
			errortoast("Required field.");
			return;
		}
		else if(orgnameconfirm === 'Permanently delete')
		{
			const params = {
						agency_id: parseInt(atob(agencyID))
					};

			setBtnDeleteLoader("d-block");
			setDeleteLabel("");

			let delbtndiv = document.getElementById("btndeleteorgid");
			delbtndiv.classList.add("btnloader");

			DeleteAgency(params, redirectToOrgListPage, setBtnDeleteLoader, setDeleteLabel);
		}
		else
		{
			errortoast("Required field.");
			return;
		}
	}

	const handleLeaveAgencyPopup = () =>{
		setMdlUserLeave(true);
	}
	const btnCancelLeaveOrgPopup = () =>{
		setMdlUserLeave(false);
	}

	const btnLeaveAgency = (event) =>{

		event.preventDefault();
		
		const params = {
						agency_id: parseInt(atob(agencyID))
					};

		setBtnLeaveLoader("d-block");
		setLeaveLabel("");

		let leavebtndiv = document.getElementById("btnleaveorgid");
		leavebtndiv.classList.add("btnloader");
		
		LeaveAgency(params, redirectToOrgListPage, setBtnLeaveLoader, setLeaveLabel);
	}

	const handleSetOrgNameConfirm = (event) =>{

		setOrgNameConfirm(event.target.value);
	}

	const handleRemoveFavicon = () =>{

		setFaviconImage(null);	
		setFaviconUpdTxt("Upload");	
	}
	const handleRightPanelExpand = (rightpanelvalue) => {
        setRightPanelExpand(rightpanelvalue);
	}

	const handleLogoImage = () =>{
		setIsLogoPopup(!islogopopup);
	}

	const handleRemoveLogo = () =>
	{
		setLogoImage('');
	}
	
	const rgbaToHex = (color) => {
		if (/^rgb/.test(color)) 
		{
		  	const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
		  	// rgb to hex
		  	// eslint-disable-next-line no-bitwise
		  	let hex = `#${(
							(1 << 24) +
							(parseInt(rgba[0], 10) << 16) +
							(parseInt(rgba[1], 10) << 8) +
							parseInt(rgba[2], 10)
		  				)
						.toString(16)
						.slice(1)}`;
	
		  	return hex;
		}
	
		return color;
	};


    return (
        <div className="org-module">
            <Header />

            <div>
        		<div className="org-structure">
				<LeftPanel rightpanelbody={handleRightPanelExpand}/>

				<div className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined &&
					localStorage.getItem('sidebaserotate') === 'true') ? "org_background org_background-expand"
					: "org_background "}>

						
							<div className="org-setting-head">
								
								<div className="agency-headings">
                            <h1>Settings</h1>
                            <p class="agency-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                <circle cx="9" cy="9" r="9" stroke="none"/>
                                <circle cx="9" cy="9" r="8.25" fill="none"/>
                                </g>
                                <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                                <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                            </g>
                            </svg><p class="agency-tooltipsubheader">Personalize your agency to give your customers a consistent brand experience.</p></p>
                            </div>

							{ spinner ?  <></>
							:
								<div className="agency-dashboard-header-div" >
									
									<h5 className="agency-web-timestamp">
                                    {lastdate ?
                                        'Updated ' + moment(lastdate).format("D MMMM YYYY, HH:mm")
                                    : ''
                                    }
                                	</h5>

									<div className={orgname ? "save-setting-btn" : "save-setting-btn save-social-btn-deactivate"}  onClick={ handleSaveOrgDetails }>
										<div className={btnloader+" new-loader"}></div>
										{btnloader == 'd-none' ? 'Save' : ''}
									</div>
									
								</div>
							}
							</div>

						{ spinner ?
							<NewSpinner />
						:

						<>
							<div className="org-module-shadow">
							
								<>

									<div className="org-setting-body">
									
										<div className="agency-logo">

											{ (logoimage === "" || logoimage === null || logoimage === "null" || logoimage === undefined || logoimage === "undefined" || !logoimage) ?
												<img src={require("./assets/images/your-logo.png").default} alt="agency logo" data-step="step1" />
											:
												( logoimage.indexOf("https") === -1 ?
													<img src={ agencyimgpath + logoimage } alt="agency logo"  data-step="step2"/>
												:
													<img src={ logoimage } alt="agency logo"  data-step="step3"/>
												)
											}
										
											<div className="agency-logo-shadow" onClick={handleLogoImage}>
												<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20">
												<g id="_8666672_camera_icon" data-name="8666672_camera_icon" transform="translate(0 -2)">
													<path id="Path_98235" data-name="Path 98235" d="M23,19a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V8A2,2,0,0,1,3,6H7L9,3h6l2,3h4a2,2,0,0,1,2,2Z" fill="none" stroke="#bcc1ce" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
													<circle id="Ellipse_7718" data-name="Ellipse 7718" cx="4" cy="4" r="4" transform="translate(8 9)" fill="none" stroke="#bcc1ce" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
												</g>
												</svg>
											</div>

											<div className="agency-logo-edit">
												<p class="profilepic-upload" onClick={handleLogoImage}>Upload </p>

												{ (logoimage !== undefined && logoimage !== null && logoimage !== '') &&
													<p className="profilepic-remove" onClick={handleRemoveLogo}> Remove</p>
												}
											</div>

											<UploadLogoPopup
          										isGalleryPopup={islogopopup}
												setIsGalleryPopup={setIsLogoPopup}
												settingYourdata={handleLogoUpload}
												yourErrFun={handleLogoErrorData}
												uploadPath={"agencies/" + localStorage.getItem("agency_id") + process.env.REACT_APP_Agency_Profile}
												settingOrgImgFrom3rdParty={false}
												hideImageGallery={true}
												widthForRestriction={160}
												heightForRestriction={50}
												aspectRatio={"16:5"}
												isNotCustomUploadOrgImg={true}
											/>

										</div>

										

										<div className="row">
											<div className="col-md-6">
												<label>Agency name*</label>
												<input type="text" name="agency_name" className={erroragencyname ? 'errormessages' : ''} value={orgname} onChange={handleAgencyName}></input>
												{erroragencyname ?
												<span className="agencyerror"><svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"></path></svg>{erroragencyname}</span>
												:''}
											</div>
											<div className="col-md-6">
												<label>Favicon</label>
												<div className={erroragencyfavicon ? 'errormessages org-image-uploader' : 'org-image-uploader'} >
													<div className="d-flex align-items-center agency-favicon-min" onClick={handleUploadFaviconPopup}>
														
														{ (faviconimage !== undefined && faviconimage !== null && faviconimage !== '' && faviconimage.length > 0) ?
															<div className="uploadpixabayimage" style={{backgroundImage : "url(" + agencyimgpath + faviconimage + ")"}}>
																<UploadFaviconPopup
																	isGalleryPopup={isfaviconpopup}
																	setIsGalleryPopup={setIsFaviconPopup}
																	settingYourdata={handleFaviconUpload}
																	yourErrFun={handleFaviconErrorData}
																	uploadPath={ "agencies/" + localStorage.getItem("agency_id") + process.env.REACT_APP_Agency_Profile}
																	settingOrgImgFrom3rdParty={false}
																	hideImageGallery={true}
																	widthForRestriction={64}
																	heightForRestriction={64}
																	aspectRatio="1:1"
																	isNotCustomUploadOrgImg={true}
																/>
															</div>
														:
															<div className="uploadpixabayimage" style={{backgroundImage : "url(" + imagebackground + ")"}}>
																<UploadFaviconPopup
																	isGalleryPopup={isfaviconpopup}
																	setIsGalleryPopup={setIsFaviconPopup}
																	settingYourdata={handleFaviconUpload}
																	yourErrFun={handleFaviconErrorData}
																	uploadPath={ "agencies/" + localStorage.getItem("agency_id") + process.env.REACT_APP_Agency_Profile}
																	settingOrgImgFrom3rdParty={false}
																	hideImageGallery={true}
																	widthForRestriction={64}
																	heightForRestriction={64}
																	aspectRatio="1:1"
																	isNotCustomUploadOrgImg={true}
																/>
															</div>
														}
														
														<p>{faviconupdtxt}</p>													
													</div>

													{ (faviconimage !== undefined && faviconimage !== null && faviconimage !== '' && faviconimage.length > 0) && (
														<svg width="11.06" height="11.06" viewBox="0 0 11.06 11.06" onClick={handleRemoveFavicon}>
															<path id="Union_1874" data-name="Union 1874" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" strokeWidth="1.5"/>
														</svg>
													)}

												</div>
												{erroragencyfavicon ?
												<span className="agencyerror"><svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"></path></svg>{erroragencyfavicon}</span>
												:''
												}
											</div>
										</div>
									</div>
								</>
							
						    </div>

							<div className="org-module-shadow mt-20">
								
									<>

										<div className="org-setting-body">
										<h3>Brand colors</h3>
										<h4>Select brand colors customers will see when they login.</h4>
											<div className="row">
												<div className="col-md-6">
													<label>Primary theme color</label>
													<div className="org-color-input">
													<ReactColorPicker
														colorname={primarycolor}
														presetColors={preprimarycolor}
														onChange={handlePrimaryColor}
														classselection={"fixback new-overlay-mobile"}
														valuecontrol={true}
													/>
													</div>
												</div>
												<div className="col-md-6">
													<label>Secondary theme color</label>
													<div className="org-color-input">
														<ReactColorPicker
															colorname={secondarycolor}
															presetColors={presecondarycolor}
															onChange={handleSecondaryColor}
															classselection={"fixback new-overlay-mobile"}
															valuecontrol={true}
															
														/>
													</div>
												</div>
											</div>

											<div className="row mt-20">
												<div className="col-md-6">
													<label>Button background color</label>
													<div className="org-color-input">
													<ReactColorPicker
														colorname={buttoncolor}
														presetColors={preprimarycolor}
														onChange={handleButtonColor}
														classselection={"fixback new-overlay-mobile"}
														valuecontrol={true}
													/>
													</div>
												</div>
												<div className="col-md-6">
													<label>Button text color</label>
													<div className="org-color-input">
														<ReactColorPicker
															colorname={buttontextcolor}
															presetColors={presecondarycolor}
															onChange={handleButtonTextColor}
															classselection={"fixback new-overlay-mobile"}
															valuecontrol={true}
															
														/>
													</div>
												</div>
											</div>

											<div className="row mt-20">
												<div className="col-md-6">
													<label>Button hover background color</label>
													<div className="org-color-input">
													<ReactColorPicker
														colorname={buttonhovercolor}
														presetColors={preprimarycolor}
														onChange={handleButtonHoverColor}
														classselection={"fixback new-overlay-mobile"}
														valuecontrol={true}
													/>
													</div>
												</div>
												<div className="col-md-6">
													<label>Button hover text color</label>
													<div className="org-color-input">
														<ReactColorPicker
															colorname={buttonhovertextcolor}
															presetColors={presecondarycolor}
															onChange={handleButtonHoverTextColor}
															classselection={"fixback new-overlay-mobile"}
															valuecontrol={true}
															
														/>
													</div>
												</div>
											</div>
										</div>
									</>
								
							</div>

							<div className="agency-mobile-btns">

								{/* <div className="agency-mobile-btns-blue">
									<p>Save</p>                    
								</div>  */}
								<div className={orgname ? "agency-mobile-btns-blue" : "agency-mobile-btns-blue save-social-btn-deactivate"}  onClick={ handleSaveOrgDetails }>
										<div className={btnloader+" new-loader"}></div>
										{btnloader == 'd-none' ? 'Save' : ''}
									</div>

								<h5>{lastdate ?
											'Updated ' + moment(lastdate).format("D MMMM YYYY, HH:mm")
										: ''
										}</h5>

							</div>

						</>
						}

						{ (parseInt(userid) !== parseInt(localStorage.getItem("logged_in_user_id"))) &&
							<div className="org-module-shadow  mt-20">
								<div className="delete-org">
									<div>
									<h3>Leave agency</h3>
									<h4>Leave this agency. Careful, this action cannot be undone.</h4>
									</div>
									<button className="del-work-btn" onClick={handleLeaveAgencyPopup}>Leave agency</button>
								</div>
							</div>
						}

						{ (parseInt(userid) === parseInt(localStorage.getItem("logged_in_user_id"))) ?
						<div className="org-module-shadow  mt-20">
							<div className="delete-org">
								<div>
								<h3>Delete agency</h3>
								<h4>Delete this agency. Careful, this action cannot be undone.</h4>
								</div>
								<button className="delete-agency-btn del-work-btn" onClick={handleDeleteAgencyPopup}>Delete agency</button>
							</div>
						</div>
						:
							null
						}
					</div>
        		</div>
								
        		

        		<Modal isOpen={mdluserleave} toggle={btnCancelLeaveOrgPopup} style={{ maxWidth: "480px", maxHeight: "320px" }} className="delete-org-popups" centered>
          			<ModalBody>
						<div className="crossmodal mt-1" onClick={btnCancelLeaveOrgPopup}>
              				<img
                				src={require("../assets/images/dashboard/cross.png").default}
              				/>
            			</div>
            			<div>
              				<div className="delete-org-body">
                				<h4>Leave this agency?</h4>
                				<h5>Once you leave, you will not be able to access it.</h5>                
              				</div>

              				<div className="delete-org-footer">
              					<button onClick={btnCancelLeaveOrgPopup}>Cancel</button>
              					<button onClick={btnLeaveAgency}  id="btnleaveorgid">
								  <div className={btnleaveloader}>
										<div className="new-red-loader"></div>
									</div>
									{ btnleavelabel }
								</button>
              				</div>
            			</div>
          			</ModalBody>
        		</Modal>

        		<Modal isOpen={mdluserdelete} toggle={btnCancelOrgPopup} style={{ maxWidth: "600px", maxHeight: "340px" }} className="confirm-org-popups" centered >
          			<ModalBody>
            			<div className="crossmodal mt-1" onClick={btnCancelOrgPopup}>
              				<img
                				src={require("../assets/images/dashboard/cross.png").default}
              				/>
            			</div>
            			<div>
              				<div className="confirm-org-body">
                				<h4>Delete this agency?</h4>
                				<h5>This will permanently delete the agency and will prevent your customers from accessing their account.</h5>

								<h6>Please type ‘<b className="text-type-delete">Permanently delete</b>’ to confirm.</h6>
                				
								<input type="text" id="orgnameconfirm" name="orgnameconfirm" value={orgnameconfirm} autoComplete="off" onPaste={(e) => { e.preventDefault(); return false;}} onChange={handleSetOrgNameConfirm} />
              				</div>

              				<div className="confirm-org-footer">                
                				<button className="confirm-org-footer-button-cancel" onClick={btnCancelOrgPopup}>Cancel</button>
                				<button className="confirm-org-footer-button-delete" onClick={btnDeleteAgency}  id="btndeleteorgid">
									<div className={btndeleteloader}>
										<div className="new-red-loader"></div>
									</div>
									{ btndeletelabel }									
								</button>
              				</div>
            			</div>
          			</ModalBody>
        		</Modal>
				<Modal isOpen={mdllimitdelete} toggle={btnCancelLimitPopup} style={{ maxWidth: "460px", maxHeight: "240px" }} className="confirm-org-popups" centered >
				<ModalHeader toggle={btnCancelLimitPopup} className="modal-xxl-content">
					<div className="profile-crossmodal " onClick={btnCancelLimitPopup}>
					<img
						src={require("../assets/images/dashboard/cross.png").default}
					/>
					</div>
				</ModalHeader>
				<ModalBody className="new-before-delete-step">
					<div>
					<h4>Active products found</h4>
					<h6>Prior to deletion, ensure all apps are removed. Also, downgrade to the Preview plan to proceed.{" "}</h6>
					
					<div className="before-delete-footer">
					
						<div className="new-before-delete-ok">
						<p onClick={btnCancelLimitPopup}>Okay</p>
						</div>
					</div>
						</div>
				</ModalBody>
        		</Modal>
            </div>
        </div>
    );
}

export default AgencyDetails;