import React from "react";

const MoreMenu = ({
  settings,
  menuiconcolor,
  iconFilter,
  blackIconItems,
  totalItems,
}) => (
  <div className="bottombar-options">
    <div id="more">
      {settings?.bottom_menu_show_icon && (
        <img
          className="img-fluid"
          src={
            settings?.bottom_menu_icon_for_menu
              ? `${process.env.REACT_APP_Image_Path}${localStorage.getItem(
                  "user_id"
                )}/${localStorage.getItem("Appid")}${
                  process.env.REACT_APP_NEW_ICON_PATH
                }${settings.bottom_menu_icon_for_menu}`
              : require("../../../assets/images/dashboard/bottombar-more-black.png")
                  .default
          }
          alt={settings.bottom_menu_icon_for_menu || "menu-item"}
          style={{
            filter:
              (menuiconcolor === "black") &&
              blackIconItems === totalItems
                ? iconFilter
                : null,
          }}
        />
      )}
      {settings?.bottom_menu_show_text && (
        <p
          className="bottombar-bottom-text"
          style={{ color: settings?.bottom_menu_text_colour }}
        >
          {settings?.bottom_menu_text_for_menu}
        </p>
      )}
    </div>
  </div>
);

export default MoreMenu;
