import React from "react";
import { Progress } from "reactstrap";
import ChecklistComplete from "../commonIcons/checklistComplete";
import ChecklistWarning from "../commonIcons/checklistWrning";
import { Link } from "react-router-dom";

const Checklist = ({ checklistTotal, moduleArr }) => {
  const handlepointcheck = () => {
    let checkedPoint = 0;
    moduleArr &&
      moduleArr.map((module) => {
        if (module.count) {
          checkedPoint = checkedPoint + 1;
        }
      });
    return checkedPoint;
  };

  return (
    <div
      className={` ${
        handlepointcheck() === checklistTotal
          ? "no-checklist-shadow"
          : "app-steps-right"
      }`}
    >
      <div
        className={`app-total-steps ${
          handlepointcheck() === checklistTotal ? "" : "unfinished-steps"
        }`}
      >
        <div className="dashboard-progress">
          <h2>
            {handlepointcheck() === checklistTotal
              ? "You’re all set"
              : "Let’s set up your app"}
              {handlepointcheck() === checklistTotal ?
              <img className="success-checklist-img" src={require("../../assets/images/dashboard/success-set.png").default} alt="arrow" />
              :<></>
            }
          </h2>

          <div className="dashboard-progress-bar">
            <div>
              <p>
                {handlepointcheck()}/{checklistTotal}
              </p>
            </div>
            <Progress value={handlepointcheck() * (100 / checklistTotal)} />
          </div>
        </div>

        <div className="app-seven-steps">
          {moduleArr &&
            moduleArr.map((module) => {
              return (
                <div key={module.link} className="app-steps">
                  <Link to={module.link}></Link>
                  {module.count > 0 ? (
                    <div>
                      <ChecklistComplete />
                    </div>
                  ) : (
                    <div>
                      <ChecklistWarning />
                    </div>
                  )}

                  <div>
                    <h4>{module.name}</h4>
                    <p>{module.desc}</p>
                  </div>
                </div>
              );
            })}

          <div className="app-steps-animation"></div>
        </div>
      </div>
    </div>
  );
};

export default Checklist;
