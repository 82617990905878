import React from "react";
import { Modal, ModalBody } from "reactstrap";
import crossIconImg from "../../../Agency/assets/images/cross.png";

const DeactivateUserModal = ({
  teamState,
  btnCancelCustomerDeactivePopup,
  handleUserStatus,
  updateUserStatusLoading,
}) => {
  return (
    <Modal
      isOpen={teamState.openDeactivateUserPopup}
      className="newuser-modal-popup"
      centered
    >
      <ModalBody className="d-flex align-items-center justify-content-center ">
        <div
          className="crossmodal mt-1"
          onClick={btnCancelCustomerDeactivePopup}
        >
          <img src={crossIconImg} alt="crossIcon" />
        </div>
        <div>
          <div>
            <h4 className="user-delete-modal-title">Deactivate user?</h4>
            <h5 className="user-delete-modal-para">
              Deactivating this user will revoke their access.
            </h5>
          </div>

          <div className="user-new-modal-footer">
            {/* <div
              className="customer-deactivate-btn-mob pay-perapp-team-btn-mob"
              onClick={(e) => handleUserStatus(e, 2, "deactivate")}
              id="btnleaveorgid"
            >
              {updateUserStatusLoading ? (
                <div className="new-red-loader"></div>
              ) : (
                "Deactivate"
              )}
            </div> */}

            <div
              className="usermodal-grey-btn"
              onClick={btnCancelCustomerDeactivePopup}
            >
              Cancel
            </div>

            <button
              className="usermodal-red-btn"
              onClick={(e) => handleUserStatus(e, 2, "deactivate")}
              id="btnleaveorgid"
              disabled={updateUserStatusLoading}
            >
              {updateUserStatusLoading ? (
                <div className="new-red-loader"></div>
              ) : (
                "Deactivate"
              )}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeactivateUserModal;
