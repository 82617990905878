import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../utils/apiEndpoints";

export const appDashboardApiSlice = createApi({
  reducerPath: "appDashboard",
  keepUnusedDataFor: 20 * 60,
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.generalUrl,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("amstoken")}`
      );
      return headers;
    },
  }),
  refetchOnFocus: true,
  endpoints: (builder) => ({
    appDashboardData: builder.query({
      query: () => `app/dashboard/${btoa(localStorage.getItem("Appid"))}`,
      // keepUnusedDataFor:5,
    }),
    analyticsTokenData: builder.query({
      query: () => `app/analytics/${btoa(localStorage.getItem("Appid"))}`,
    }),
    createDefaultsData: builder.mutation({
      query: (newData) => ({
        url: "user/save-defaults",
        method: "POST",
        body: newData,
      }),
    }),
    extractColorData: builder.mutation({
      query: (newData) => ({
        url: "app/extract-website-colors",
        method: "POST",
        body: newData,
      }),
    }),
  }),
});

export const {
  useAppDashboardDataQuery,
  useAnalyticsTokenDataQuery,
  useCreateDefaultsDataMutation,
  useExtractColorDataMutation,
} = appDashboardApiSlice;
