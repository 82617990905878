import React from "react";
import Header from "./Header";
import "../assets/css/calendly.css";

export default class BookACall extends React.Component {
  constructor(props) {
    super(props);

  }

  isJSON = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return JSON.parse(str);
  }
  
  componentDidMount() 
  {
    window.scrollTo(0, 0);

    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
      {
        document.title = "Speak to support | AppMySite";
      }
      else
      {
        document.title = "Speak to support |"+ ' ' +localStorage.getItem('agencyname');        
      }

      if(localStorage.getItem('supportplan') == '' || localStorage.getItem('supportplan') == null || localStorage.getItem('supportplan') == 'null')
      {
         window.location.href= "/support-pricing";
      }
      else if(localStorage.getItem('supportplan') && this.isJSON((localStorage.getItem('supportplan'))))
      {
        
        if( ((this.isJSON((localStorage.getItem('supportplan')))).plan_name == 'Enterprise') || ((this.isJSON((localStorage.getItem('supportplan')))).plan_name == 'Business') )
        {
          // window.location.href= "/home";
        }
        else
        {
          window.location.href= "/support-pricing";
        }
        
      }
      else
      {
        window.location.href= "/support-pricing";
      }

    document.addEventListener("mousedown", this.handleClickOutside);
    
  }


  componentWillUnmount() 
  {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) 
  {
    try {

      if (this.wrapperRef && this.wrapperRef.current && !this.wrapperRef.current.contains(event.target)) {


        this.setState({
          openCountryList: false
        })

      }
    } catch (err) {
      

    }
  }



  render() {

    return (
      <>

      <Header
        classNameProp={"contact-us-header-white-bg"}
        SignInShow={true}
      />
        <div style={{display:"flex",justifyContent:'center'}} className="bookacalldiv">
          
          <div class="calendly-inline-widget" data-url="https://calendly.com/d/4zr-twf-jt5" style={{width:"1250px",display:"inline-block",textAlign: "center"}}></div>
        </div>     
      </>
    );
  }
}