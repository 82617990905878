import React, { useState, useEffect} from "react";

import { Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import Select from "react-select";

const LanguageSegment = ({keydata, lanKey, setLanKey, lanValue, setLanValue, lanFilterType, setLanFilterType, lanoperators, setLanOperators, onRemoveLan, lanRowOpen, lanopenrownumber, selectruleserror, selectvalueerror, duplicateLanSegment}) =>{

	const [lankeydropdownopen, setLanKeyDropDownOpen] = useState(false);
	const [lanvaluedropdownopen, setLanValueDropDownOpen] = useState(false);
		
    const rulesoption = [
        { value: "eq", label: "is" },
        { value: "neq", label: "is not" },
    ];

    const languageoption = [
                    { value: "en", label: "English" },
                    { value: "ar", label: "Arabic" },
                    { value: "az", label: "Azerbaijani" },
                    { value: "bs", label: "Bosnian" },
                    { value: "ca", label: "Catalan" },
                    { value: "zh-Hans", label: "Chinese (Simplified)" },
                    { value: "zh-Hant", label: "Chinese (Traditional)" },
                    { value: "hr", label: "Croatian" },
                    { value: "cs", label: "Czech" },
                    { value: "da", label: "Danish" },
                    { value: "nl", label: "Dutch" },
                    { value: "et", label: "Estonian" },
                    { value: "fi", label: "Finnish" },
                    { value: "fr", label: "French" },
                    { value: "ka", label: "Georgian" },
                    { value: "bg", label: "Bulgarian" },
                    { value: "de", label: "German" },
                    { value: "el", label: "Greek" },
                    { value: "hi", label: "Hindi" },
                    { value: "he", label: "Hebrew" },
                    { value: "hu", label: "Hungarian" },
                    { value: "id", label: "Indonesian" },
                    { value: "it", label: "Italian" },
                    { value: "ja", label: "Japanese" },
                    { value: "ko", label: "Korean" },
                    { value: "lv", label: "Latvian" },
                    { value: "lt", label: "Lithuanian" },
                    { value: "ms", label: "Malay" },
                    { value: "nb", label: "Norwegian" },
                    { value: "pl", label: "Polish" },
                    { value: "fa", label: "Persian" },
                    { value: "pt", label: "Portuguese" },
                    { value: "pa", label: "Punjabi" },
                    { value: "ro", label: "Romanian" },
                    { value: "ru", label: "Russian" },
                    { value: "sr", label: "Serbian" },
                    { value: "sk", label: "Slovak" },
                    { value: "es", label: "Spanish" },
                    { value: "sv", label: "Swedish" },
                    { value: "th", label: "Thai" },
                    { value: "tr", label: "Turkish" },
                    { value: "uk", label: "Ukrainian" },
                    { value: "vi", label: "Vietnamese" },
                ];

    
    

    const handleLSelectChange = (e) =>{
        //e.preventDefault();
        
        //setLanKey(e.target.value);

        let getlankey = [...lanKey];
        //getlankey[keydata] = e.target.value;
        getlankey[keydata] = e.value;
        setLanKey(getlankey);

        let getfiltertype = [...lanFilterType];
        getfiltertype[keydata] = 5;
        setLanFilterType(getfiltertype);

        let getoperator = [...lanoperators];
        //
        let filterdivcount = document.querySelectorAll('.define-filters').length;
        //
        if(filterdivcount > 1 && (filterdivcount - 1) !== keydata)
        {
            
            getoperator[keydata]  = "AND";
            setLanOperators(getoperator);
        }
		
		setLanKeyDropDownOpen(false);
    }

    const handleLanValueChange = (e) =>{
        //e.preventDefault();
        
        //setLanValue(e.target.value);

        let getlanvalue = [...lanValue];
        //getlanvalue[keydata] = e.target.value;
        getlanvalue[keydata] = e.value;
        setLanValue(getlanvalue);
		
		setLanValueDropDownOpen(false);

    }
	
	const handleMobileLanKeyDropdown = () =>{

        setLanKeyDropDownOpen(!lankeydropdownopen);
    }
	
	const handleMobileLanValueDropdown = () =>{

        setLanValueDropDownOpen(!lanvaluedropdownopen);
    }

    /*const handleLangData = (opt) =>{

        const allOptionsSelected = opt.length === languageoption.length;

        setChecked(allOptionsSelected ? true : false);

        setArrValues(opt);

       
    }*/


    return(
        <div className="accordionItem">
            <div onClick={ (e) => lanRowOpen(e, 5, keydata) }>
                <div className="heading-wrapper accordionItemHeading allflex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 30 30">
                        <g id="Group_29016" data-name="Group 29016" transform="translate(-973 -226)">
                            <path id="Subtraction_61" data-name="Subtraction 61" d="M13.855,24H2.058L0,18.546H6.349L.089,0H13.855A2.166,2.166,0,0,1,16,2.181V21.817A2.165,2.165,0,0,1,13.855,24Z" transform="translate(987 232.001)" fill="#47c2ff"/>
                            <path id="Path_10055" data-name="Path 10055" d="M2.111,0h8.916L19,23H2.111A2.1,2.1,0,0,1,0,20.909V2.091A2.1,2.1,0,0,1,2.111,0Z" transform="translate(974.5 227.5)" fill="none" stroke="#47c2ff" stroke-linejoin="round" stroke-width="3"/>
                            <path id="Path_10049" data-name="Path 10049" d="M944.186,402.009l3.664-9.746,3.665,9.746" transform="translate(34.349 -157.679)" fill="none" stroke="#47c2ff" stroke-linejoin="round" stroke-width="2"/>
                            <path id="Path_10050" data-name="Path 10050" d="M945.605,396.75h4.016" transform="translate(34.606 -155.684)" fill="none" stroke="#47c2ff" stroke-width="2"/>
                            <path id="Path_10051" data-name="Path 10051" d="M956.132,396.75h9.259" transform="translate(35.343 -156.339)" fill="none" stroke="#fff" stroke-width="2"/>
                            <path id="Path_10052" data-name="Path 10052" d="M959.729,395.6v2.448" transform="translate(35.643 -157.667)" fill="none" stroke="#fff" stroke-width="2"/>
                            <path id="Path_10053" data-name="Path 10053" d="M963.23,396.75a10.889,10.889,0,0,1-6.058,8.4" transform="translate(35.43 -156.339)" fill="none" stroke="#fff" stroke-width="2"/>
                            <path id="Path_10054" data-name="Path 10054" d="M957.881,399.721a27.582,27.582,0,0,0,5.764,6.442" transform="translate(35.016 -156.091)" fill="none" stroke="#fff" stroke-width="2"/>
                        </g>
                    </svg>
                    <h2 className="heading">Language</h2>
                </div>
                <div className="arrow-img">
                    { lanopenrownumber[keydata] === true ?   
                        <svg xmlns="http://www.w3.org/2000/svg" width="13.061" height="7.592" viewBox="0 0 13.061 7.592">
                            <path id="Path_81302" data-name="Path 81302" d="M-12935-18908l6-6,6,6" transform="translate(12935.531 18915.064)" fill="none" stroke="#7782a1" stroke-width="1.5" />
                        </svg>
                    :
                        <svg xmlns="http://www.w3.org/2000/svg" width="13.061" height="7.592" viewBox="0 0 13.061 7.592">
                            <path id="Path_81302" data-name="Path 81302" d="M-12935-18914l6,6,6-6" transform="translate(12935.531 18914.535)" fill="none" stroke="#7782a1" stroke-width="1.5" />
                        </svg>
                    }
                </div>
            </div>

            { lanopenrownumber && lanopenrownumber[keydata] === true &&
            <div className="accordian-text-wrapper">
                <div className="accordion-text">
                    <div className="select-wrapper">
                        <div className={ selectruleserror[keydata] ?  "mobile-view-click notification-selecterror" : "mobile-view-click" } onClick={ window.matchMedia("(max-width: 1200px)").matches ? handleMobileLanKeyDropdown : null }>
                            <Select options={rulesoption} onChange={handleLSelectChange } value={ rulesoption.filter(function (option) {
                                      return (option.value === lanKey[keydata]); }) } isSearchable={false} isClearable={false} isDisabled={ window.matchMedia("(max-width: 1200px)").matches ? true : false } classNamePrefix="profile-role-dropdown" />
                        </div>

                      
                    </div>
                    <div className="audience-category-parent-dropdown">
                        <div className={ selectvalueerror[keydata] ?  "mobile-view-click notification-selecterror" : "mobile-view-click" } onClick={ window.matchMedia("(max-width: 1200px)").matches ? handleMobileLanValueDropdown : null }>
                            <Select options={languageoption} onChange={handleLanValueChange } value={ languageoption.filter(function (option1) {
                                      return (option1.value === lanValue[keydata]); }) } isSearchable={false} isClearable={false} isDisabled={ window.matchMedia("(max-width: 1200px)").matches ? true : false } classNamePrefix="profile-role-dropdown" />
                            
                        </div>
                        
                    </div>
                </div>
                <div className="delete-and-copy">
                    {/* <div className="copy" onClick={(e) =>duplicateLanSegment(e, 5)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.001" height="15" viewBox="0 0 15.001 15" >
                            <g id="Group_26180" data-name="Group 26180" transform="translate(-450.5 -124.5)" >
                                <g id="Path_9276" data-name="Path 9276" transform="translate(0 0)" fill="none" stroke-miterlimit="10" >
                                    <path d="M462.5,125.923V128.5h-6.72a1.189,1.189,0,0,0-1.28,1.28v6.72h-2.577a1.419,1.419,0,0,1-1.423-1.423v-9.154a1.419,1.419,0,0,1,1.423-1.423h9.154A1.419,1.419,0,0,1,462.5,125.923Z" stroke="none" />
                                    <path d="M 451.9228515625 125.5000152587891 C 451.6857299804688 125.5000152587891 451.5 125.6857452392578 451.5 125.9228668212891 L 451.5 135.0771636962891 C 451.5 135.3142700195312 451.6857299804688 135.5 451.9228515625 135.5 L 453.5 135.5 L 453.5 129.7800140380859 C 453.5 128.4375762939453 454.4375610351562 127.5 455.7799682617188 127.5 L 461.5 127.5 L 461.5 125.9228668212891 C 461.5 125.6857452392578 461.3142395019531 125.5000152587891 461.0771179199219 125.5000152587891 L 451.9228515625 125.5000152587891 M 451.9228515625 124.5000152587891 L 461.0771179199219 124.5000152587891 C 461.8656616210938 124.5000152587891 462.5 125.1343078613281 462.5 125.9228668212891 L 462.5 128.5 L 455.7799682617188 128.5 C 454.9913940429688 128.5 454.5 128.991455078125 454.5 129.7800140380859 L 454.5 136.5 L 451.9228515625 136.5 C 451.13427734375 136.5 450.5 135.8657531738281 450.5 135.0771636962891 L 450.5 125.9228668212891 C 450.5 125.1343078613281 451.13427734375 124.5000152587891 451.9228515625 124.5000152587891 Z" stroke="none" fill="#7782a1" />
                                </g>
                                <g id="Path_9277" data-name="Path 9277" transform="translate(-1.999 -2)" fill="none" stroke-miterlimit="10" >
                                    <path d="M467.5,130.923v9.154a1.419,1.419,0,0,1-1.423,1.423h-9.154a1.419,1.419,0,0,1-1.423-1.423v-9.154a1.419,1.419,0,0,1,1.423-1.423h9.154A1.419,1.419,0,0,1,467.5,130.923Z" stroke="none" />
                                    <path d="M 456.9228210449219 130.5000152587891 C 456.6856994628906 130.5000152587891 456.4999694824219 130.6857452392578 456.4999694824219 130.9228515625 L 456.4999694824219 140.0771484375 C 456.4999694824219 140.3142700195312 456.6856994628906 140.5000152587891 456.9228210449219 140.5000152587891 L 466.0771179199219 140.5000152587891 C 466.3142395019531 140.5000152587891 466.4999694824219 140.3142700195312 466.4999694824219 140.0771484375 L 466.4999694824219 130.9228515625 C 466.4999694824219 130.6857452392578 466.3142395019531 130.5000152587891 466.0771179199219 130.5000152587891 L 456.9228210449219 130.5000152587891 M 456.9228210449219 129.5000152587891 L 466.0771179199219 129.5000152587891 C 466.8656921386719 129.5000152587891 467.4999694824219 130.1342926025391 467.4999694824219 130.9228515625 L 467.4999694824219 140.0771484375 C 467.4999694824219 140.86572265625 466.8656921386719 141.5000152587891 466.0771179199219 141.5000152587891 L 456.9228210449219 141.5000152587891 C 456.1342163085938 141.5000152587891 455.4999694824219 140.86572265625 455.4999694824219 140.0771484375 L 455.4999694824219 130.9228515625 C 455.4999694824219 130.1342926025391 456.1342163085938 129.5000152587891 456.9228210449219 129.5000152587891 Z" stroke="none" fill="#7782a1" />
                                </g>
                            </g>
                        </svg>
                    </div> */}
                    <div className="delete">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999" onClick = { (e)=>onRemoveLan(e, keydata) } >
                            <g id="Group_5465" data-name="Group 5465" transform="translate(-835.516 -682.992)">
                                <g id="Rectangle_2307" data-name="Rectangle 2307" transform="translate(836.515 684.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1">
                                    <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none"/>
                                    <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none"/>
                                </g>
                                <g id="Rectangle_2308" data-name="Rectangle 2308" transform="translate(839.515 682.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" >
                                    <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none" />
                                    <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none" />
                                </g>
                                <g id="Group_3245" data-name="Group 3245" transform="translate(841.016 687.993)" >
                                    <line id="Line_110" data-name="Line 110" y2="6" transform="translate(3 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                                    <line id="Line_111" data-name="Line 111" y1="6" transform="translate(0 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                                </g>
                                <line id="Line_112" data-name="Line 112" x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                            </g>
                        </svg>
                    </div>
                </div>
				
				<Modal isOpen={ window.matchMedia("(max-width: 1200px)").matches ? lankeydropdownopen : false } style={{ maxWidth: "700px", maxHeight: "350px" }} className={" profile-code-modal"} centered dataclass="countrycode" >
                    <ModalHeader className=" ">
                        <div className="d-flex align-items-center search-box justify-content-center profile-country-search">
                            <div className="d-flex align-items-center w-100 ">
                                <div className="backaero-svgimg" onClick={handleMobileLanKeyDropdown} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408" >
                                        <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)" >
                                            <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2" />
                                            <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody className="code-selector p-0">
                        <div className="mob-popup-company-size">
                            {rulesoption.map((res, i) => (
                            <div className="d-flex align-items-center justify-content-between" key={i} >
                                <div className="d-flex align-items-center justify-content-start" onClick={() => handleLSelectChange(res) } >
                                    <p className="mob-company-size">{res.label}</p>
                                </div>
                            </div>
                            ))}
                        </div>

                    </ModalBody>
                </Modal>
				
				<Modal isOpen={ window.matchMedia("(max-width: 1200px)").matches ? lanvaluedropdownopen : false } style={{ maxWidth: "700px", maxHeight: "350px" }} className={" profile-code-modal"} centered dataclass="countrycode" >
                    <ModalHeader className=" ">
                        <div className="d-flex align-items-center search-box justify-content-center profile-country-search">
                            <div className="d-flex align-items-center w-100 ">
                                <div className="backaero-svgimg" onClick={handleMobileLanValueDropdown} >
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.759" height="15.408" viewBox="0 0 20.759 15.408" >
                                        <g id="Group_27465" data-name="Group 27465" transform="translate(-19.742 -22.295)" >
                                            <path id="Path_81245" data-name="Path 81245" d="M-13783.922-19217h19.346" transform="translate(13805.077 19247)" fill="none" stroke="#7782a1" stroke-width="2" />
                                            <path id="Path_82232" data-name="Path 82232" d="M14053.656,19255.426l-7,7,7,7" transform="translate(-14025.504 -19232.424)" fill="none" stroke="#7782a1" stroke-width="2" />
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </ModalHeader>
                    <ModalBody className="code-selector p-0">
                        <div className="mob-popup-company-size">
                            {languageoption.map((res1, j) => (
                            <div className="d-flex align-items-center justify-content-between" key={j} >
                                <div className="d-flex align-items-center justify-content-start" onClick={() => handleLanValueChange(res1) } >
                                    <p className="mob-company-size">{res1.label}</p>
                                </div>
                            </div>
                            ))}
                        </div>

                    </ModalBody>
                </Modal>
            </div>
            }
        </div>
    );
}

export default LanguageSegment;