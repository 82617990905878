import React from "react";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import googleIcon from  "../../../assets/images/dashboard/login/google.png";
import errorIcon from  "../../../assets/images/dashboard/error-icon.svg";
const SocialConnect = ({profileData, removeGoogleLogins}) => {
  return (
    <div className="new-profile-social">
      <h4 className="social-account-add">Social accounts</h4>
      <h5 className="social-account-text">Seamlessly login to your account using your social profiles.</h5>
      <div className="row">
        {localStorage.getItem("agencyid") == 1 ? (
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <div className="profile-social-login">
               
                <img src={googleIcon} alt="" />
              </div>
              <div className="social-parent-div">
                <p className="newp-profile">{profileData.txtgoogle}</p>

                <div className="d-flex new-profileconnect">
                  {profileData.linked_google === 1 && (
                    <p className="newprofile-afterconnect">Connected</p>
                  )}
                  {profileData.linked_google === 1 ? (
                    <a className="remove" onClick={removeGoogleLogins}>
                      Remove
                    </a>
                  ) : (
                    <a
                      href={
                        process.env.REACT_APP_API_URL +
                        "auth/google/profile/" +
                        localStorage.getItem("agency_id") +
                        "/" +
                        localStorage.getItem("user_id")
                      }
                    >
                      Connect
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : localStorage.getItem("agencyid") != 1 &&
          profileData.is_google_login_enabled == 1 ? (
          <div className="col-md-6">
            <div className="d-flex align-items-center">
              <div className="profile-social-login">
               =
                <img src={googleIcon} alt="" />
              </div>
              <div className="social-parent-div">
                <p className="newp-profile">{profileData.txtgoogle}</p>

                <div className="d-flex new-profileconnect">
                  {profileData.linked_google === 1 && (
                    <p className="newprofile-afterconnect">Connected</p>
                  )}
                  {profileData.linked_google === 1 ? (
                    <a className="remove" onClick={removeGoogleLogins}>
                      Remove
                    </a>
                  ) : (
                    <a
                      href={
                        process.env.REACT_APP_API_URL +
                        "auth/google/profile/" +
                        localStorage.getItem("agency_id") +
                        "/" +
                        localStorage.getItem("user_id")
                      }
                    >
                      Connect
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {profileData.errcookies !== "" && profileData.errcookies !== null ? (
        <div className="new-social-error">
          <div className="errordiv">
            <p>
              Permission needed. Check your browser settings to make sure
              third-party cookies are allowed.{" "}
            </p>
          </div>
          <div>
            <img src={errorIcon} />
            {/* <svg
              id="Group_29966"
              data-name="Group 29966"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <path
                id="Path_82474"
                data-name="Path 82474"
                d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z"
                fill="#ff4848"
              />
              <path
                id="Path_7077"
                data-name="Path 7077"
                d="M10991-4358.209v6"
                transform="translate(-10981 4363.207)"
                fill="none"
                stroke="#fff"
                stroke-width="2"
              />
              <path
                id="Path_7078"
                data-name="Path 7078"
                d="M10991-4347.192v2"
                transform="translate(-10981 4360.192)"
                fill="none"
                stroke="#fff"
                stroke-width="2"
              />
            </svg> */}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default SocialConnect;
