import React from 'react'

const ListView = ({productState,handleStateChange}) => {
  return (
    <svg
    onClick={(e, from) =>
      handleStateChange("toggleListGridView", "list")
    }
    className={
      !productState.tempShowGridView
        ? "toggle-product-svg"
        : "nottoggle-product-svg"
    }
    style={{ marginRight: "20px" }}
    id="Group_38251"
    data-name="Group 38251"
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g
      id="Rectangle_4591"
      data-name="Rectangle 4591"
      fill="#fff"
      stroke=""
      stroke-width="2"
    >
      <rect width="9" height="9" rx="1" stroke="none" />
      <rect
        x="1"
        y="1"
        width="7"
        height="7"
        fill="none"
      />
    </g>
    <g
      id="Rectangle_4596"
      data-name="Rectangle 4596"
      transform="translate(0 11)"
      fill="#fff"
      stroke=""
      stroke-width="2"
    >
      <rect width="9" height="9" rx="1" stroke="none" />
      <rect
        x="1"
        y="1"
        width="7"
        height="7"
        fill="none"
      />
    </g>
    <path
      id="Path_9130"
      data-name="Path 9130"
      d="M954.017,177.785h9"
      transform="translate(-943.016 -175.434)"
      fill="#fff"
      stroke=""
      stroke-width="2"
    />
    <path
      id="Path_9133"
      data-name="Path 9133"
      d="M954.017,177.785h9"
      transform="translate(-943.016 -163.775)"
      fill="#fff"
      stroke=""
      stroke-width="2"
    />
    <path
      id="Path_9131"
      data-name="Path 9131"
      d="M954.017,177.785h6"
      transform="translate(-943.016 -171.364)"
      fill="#fff"
      stroke=""
      stroke-width="2"
    />
    <path
      id="Path_9132"
      data-name="Path 9132"
      d="M954.017,177.785h6"
      transform="translate(-943.016 -159.706)"
      fill="#fff"
      stroke=""
      stroke-width="2"
    />
  </svg>
  )
}

export default ListView