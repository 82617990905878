import React from 'react'

const SharpImage = ({productState, handleStateChange}) => {
  return (
    <svg
    onClick={(e) =>
      handleStateChange(
        "updateImageShape",
        "sharp_corner"
      )
    }
    className={
      productState.imageShapeOnListingPage ===
      "sharp_corner"
        ? "product-shapeselect"
        : "product-shapenotselect"
    }
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
  >
    <g
      id="Rectangle_18739"
      data-name="Rectangle 18739"
      fill="#fff"
      stroke=""
      stroke-width="2"
    >
      <rect width="18" height="18" stroke="none" />
      <rect
        x="1"
        y="1"
        width="16"
        height="16"
        fill="none"
      />
    </g>
  </svg>
  )
}

export default SharpImage