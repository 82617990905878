import React from "react";
import Tile from "./Tile";

const TileContainer = ({
  items,
  settings,
  bottomState,
  selectedGridIconFilter,
  gridIconFilter,
  blackIconItems,
  totalItems,
  showexpand,
}) => {
  if (!items || items.length === 0) return null;

  return (
    <div className="bottombar-tiles">
      {showexpand &&
        items.map((item, index) => {
          if (index >= bottomState.setvalue && item.status === 1) {
            return (
              <Tile
                key={index}
                item={item}
                isSelected={item.is_default === 1}
                settings={settings}
                selectedGridIconFilter={selectedGridIconFilter}
                gridIconFilter={gridIconFilter}
                blackIconItems={blackIconItems}
                totalItems={totalItems}
              />
            );
          }
          return null;
        })}
    </div>
  );
};

export default TileContainer;
