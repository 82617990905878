import React from "react";
import "../assets/css/Dashboard.css";
import Header from "./Header";
import Leftpanel from "./Leftpanel";
import "../assets/css/profileleftpanel.css";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import moment from "moment";
import "../assets/css/appdashboard.css";
import styles from "./Charts/dashboardchart.module.css";
import Histogram from "./Charts/DashboardHistogram";
import userdefaultImgOnError from "../assets/images/dashboard/accountprofile.png";
import {
  DownloadDashboardBuildApi,
} from "../Api/Dashboard/DashboardApi";
import {
  GetpackageAll,
  wooChangePlan,
  Makestrippayment,
  SendpaymentinfotoserverApi,
  DowngradeApi
} from "../Api/Dashboard/DashboardApi";
import { paddleChangePlan } from "../Api/Addons/Addonsapi";
import {
  Progress,
} from "reactstrap";
import imagebackground from "../assets/images/dashboard/fashion.png";
import {Analyticsdata,allowAdminAsClient,ExtractColor,Savedefaultdata,} from "../Api/Dashboard/DashboardApi";
import { Getanalytics, Reportdata } from "../Api/Reports/ReportsApi";
import Profitwell from "profitwell-component";
import {

  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
export default class Newdashboard extends React.PureComponent {
  state = {
    spinner: true,
    dashboardspinner:true,
    appearance_selection: true,
    connectivity_selection: false,
    dashboard_selection: false,
    onetime_addons_purchased_json: null,
    recurring_addons_purchased_json: null,
    data: [],
    appearance_active_state: [],
    auto_addons: [],
    app_analytics: null,
    android_bundle_identifier: "",
    token: "",
    protip: false,
    reportdata: [],
    activeandroidspinner: true,
    activeuserspinner: true,
    statuscode: "",
    android_build: "",
    android_latest: "",
    ios_build: "",
    ios_latest: "",
    stripe_customer_id: "",
    workspace_customer_id: "",
    website_technology: "",
    website_type: "",
    customer_billing_type: "",
    customer_id : "",
    android_build_version:null,
    ios_build_version:null,
    android_build_info:null,
    ios_build_info:null,
    downloadbuild: false,
    buildurl: "",
    downloadiosbuild: false,
    buildiosurl: "",
    checklisttotal:parseInt(localStorage.getItem("website_technology")) === 2 || parseInt(localStorage.getItem("website_technology")) === 4  ? 6 : 7,
    pointcheck:0,
    appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
    localStorage.getItem("appSideBarCollapse") === "true"
      ? true
      : false,
    selectfilter: "7d",
    histogramspinner:true ,
    intervalFilterVal:"day",
    appuser_role_id:4,
    openUpgradePopUp:false,
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
		{
      document.title = "Dashboard | AppMySite";

		}
		else
		{
      document.title = "Dashboard |"+ ' ' +localStorage.getItem('agencyname');
		}
    
    this.stopmounting = true;

    const params = {
      default_app_id: parseInt(localStorage.getItem("Appid")),
      default_workspace_id: null,
      default_agency_id: null,
    };
   
    Savedefaultdata(params);

    const buildparams = {app_id: localStorage.getItem("Appid")};
    this.setState({appid: localStorage.getItem("Appid")});
    DownloadDashboardBuildApi(buildparams, this);

    Reportdata(this, "dashboard", () => {
      if(parseInt(localStorage.getItem("website_technology")) === 2){
        Getanalytics(this.state.token, this, "non-wp-analytics");
      }else if (parseInt(localStorage.getItem("website_technology")) === 3) {
          Getanalytics(this.state.token, this, "cms-analytics");
      }else if (parseInt(localStorage.getItem("website_technology")) === 4) {
          Getanalytics(this.state.token, this, "wordpress-analytics");
      }else if(parseInt(localStorage.getItem("website_technology")) === 5) {
          Getanalytics(this.state.token, this, "woocommerce-analytics");
      }
      else
      {
        this.setState({
          dashboardspinner: false});
      }
      
    });
  
  }

  versiontable = () => {
    this.analyticsapi();
  };
  handleOpenUpgradePopUp = () => {
    this.setState({ openUpgradePopUp: !this.state.openUpgradePopUp });
  }
  handlepointcheck = () =>
  {

    var pointcheck = 0;
    
    if(this.state.data.app_icon_screen_count > 0 )
    {
        pointcheck = pointcheck+1;
    }
    if(this.state.data.splash_screen_count > 0 )
    {
        pointcheck = pointcheck+1;
    }
    if(this.state.data.bottom_menu > 0)
    {
       pointcheck = pointcheck+1;
    }
    if(this.state.data.notification_screen_count > 0)
    {
      pointcheck = pointcheck+1;
    }
    if(this.state.data.is_build_created > 0)
      {
        pointcheck = pointcheck+1;
      }
    if(parseInt(localStorage.getItem("website_technology")) === 2 && this.state.data.web_view_settings_screen_count > 0)
    {
       pointcheck = pointcheck+1;
    }

    if(parseInt(localStorage.getItem("website_technology")) === 3 && this.state.data.page_count > 0)
    {
       pointcheck = pointcheck+1;
    }

    if(parseInt(localStorage.getItem("website_technology")) === 3 && this.state.data.post_count > 0)
    {
       pointcheck = pointcheck+1;
    }

    if(parseInt(localStorage.getItem("website_technology")) === 4 && this.state.data.plugin_screen_count > 0)
    {
       pointcheck = pointcheck+1;
    }

    if(parseInt(localStorage.getItem("website_technology")) === 5 && this.state.data.plugin_screen_count > 0)
    {
       pointcheck = pointcheck+1;
    }

    if(parseInt(localStorage.getItem("website_technology")) === 5 && this.state.data.cs_ck_screen_count > 0)
    {
       pointcheck = pointcheck+1;
    }

         

         return pointcheck;
     
  }

  analyticsapi ()
  {
    Analyticsdata(this, () => {
      
      this.setState({
        appearance_active_state: [
          this.state.data.app_icon_screen_count,
          this.state.data.splash_screen_count,
          this.state.data.login_register_screen_count,
          this.state.data.dashboard_screen_count,
          this.state.data.style_screen_count,
        ],
      });
      if (parseInt(localStorage.getItem("website_technology")) !== 3) {
        ExtractColor(this);
      }

       if (this.state.customer_billing_type == 2) 
      {
        setTimeout(
          () =>
              <Profitwell
              authToken="6a38f3c4608428fa86da4844e532b6b6"
              user_id={this.state.customer_id}
            />,
          3000
        );
      } 
      else if (this.state.customer_billing_type == 3) 
      {
        setTimeout(
          () =>
              <Profitwell
              authToken="894a549ee73eccb67e82477e6276abec"
              user_id={this.state.customer_id}
            />,
          3000
        );
      }

    });
  }

  componentWillUnmount() {
    this.stopmounting = false;
  }

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
  }

  handleLogin = (e) => {
    allowAdminAsClient(this, localStorage.getItem("Appid"));
  };

  handlenavselection = (selection) => {
    if (selection === "appearance") {
      this.setState({
        appearance_selection: true,
        connectivity_selection: false,
        dashboard_selection: false,
        appearance_active_state: [
          this.state.data.app_icon_screen_count,
          this.state.data.splash_screen_count,
          this.state.data.login_register_screen_count,
          this.state.data.dashboard_screen_count,
          this.state.data.style_screen_count,
        ],
      });
    }
    if (selection === "connectivity") {
      this.setState({
        appearance_selection: false,
        connectivity_selection: true,
        dashboard_selection: false,
      });
      if (parseInt(localStorage.getItem("website_technology")) === 5) {
        this.setState({
          appearance_active_state: [
            this.state.data.cs_ck_screen_count,
            this.state.data.plugin_screen_count,
          ],
        });
      } else {
        this.setState({
          appearance_active_state: [this.state.data.plugin_screen_count],
        });
      }
    }
    if (selection === "downloadapp") {
      this.setState({
        appearance_selection: false,
        connectivity_selection: false,
        dashboard_selection: true,
      });
      if (this.state.data.subscription.device_type === "android") {
        let androidactive =
          this.state.android_build_version !== null ? 1 : 0;
        this.setState({
          appearance_active_state: [androidactive],
        });
      } else if (this.state.data.subscription.device_type === "all") {
        let androidactive =
          this.state.android_build_version !== null ? 1 : 0;
        let iosactive = this.state.ios_build_version !== null ? 1 : 0;
        this.setState({
          appearance_active_state: [androidactive, iosactive],
        });
      } else if (this.state.data.subscription.device_type === "all") {
        let androidactive =
          this.state.android_build_version !== null ? 1 : 0;
        let iosactive = this.state.ios_build_version !== null ? 1 : 0;
        this.setState({
          appearance_active_state: [androidactive, iosactive],
        });
      } else {
        this.setState({
          appearance_active_state: [0, 0],
        });
      }
    }
  };
  Downloadstatus = () => {
    if (this.state.data.subscription.device_type === "android") {
      if (this.state.android_build_version !== null) {
        return (
          <>
            <img
              src={require("../assets/images/newdashboard/verify.png").default}
              alt="verify"
              className="mr-2"
            />
            <span
              style={{
                color:
                  this.state.dashboard_selection === true
                    ? "#3064F9"
                    : "#1B2952",
              }}
            >
              Download App
            </span>
          </>
        );
      } else {
        return (
          <>
            <img
              src={
                require("../assets/images/newdashboard/greyquestion.png")
                  .default
              }
              alt="notverify"
              className="mr-2"
            />
            <span
              style={{
                color:
                  this.state.dashboard_selection === true
                    ? "#3064F9"
                    : "#7782A1",
              }}
            >
              Download App
            </span>
          </>
        );
      }
    }
    if (this.state.data.subscription.device_type === "all") {
      if (
        this.state.android_build_version !== null &&
        this.state.ios_build_version !== null
      ) {
        return (
          <>
            <img
              src={require("../assets/images/newdashboard/verify.png").default}
              alt="verify"
              className="mr-2"
            />
            <span
              style={{
                color:
                  this.state.dashboard_selection === true
                    ? "#3064F9"
                    : "#1B2952",
              }}
            >
              Download App
            </span>
          </>
        );
      } else {
        return (
          <>
            <img
              src={
                require("../assets/images/newdashboard/greyquestion.png")
                  .default
              }
              alt="notverify"
              className="mr-2"
            />
            <span
              style={{
                color:
                  this.state.dashboard_selection === true
                    ? "#3064F9"
                    : "#7782A1",
              }}
            >
              Download App
            </span>
          </>
        );
      }
    }
    if (this.state.data.subscription.device_type === "preview") {
      return (
        <>
          <img
            src={
              require("../assets/images/newdashboard/greyquestion.png").default
            }
            alt="notverify"
            className="mr-2"
          />
          <span
            style={{
              color:
                this.state.dashboard_selection === true ? "#3064F9" : "#7782A1",
            }}
          >
            Download App
          </span>
        </>
      );
    }
  };
  Buildstatus = () => {
    if (this.state.data.subscription.device_type === "preview") {
      return (
        <>
          <li className="mb-4">
            <Link to="/app/download">
              <img
                src={
                  require("../assets/images/newdashboard/greyquestion.png")
                    .default
                }
                alt="notverify"
                className="mr-2"
                width="21px"
                height="21px"
              />{" "}
              Android App
            </Link>
          </li>
          <li className="mb-4">
            <Link to="/app/download">
              <img
                src={
                  require("../assets/images/newdashboard/greyquestion.png")
                    .default
                }
                alt="notverify"
                className="mr-2"
                width="21px"
                height="21px"
              />{" "}
              iOS App
            </Link>
          </li>
        </>
      );
    }
    if (this.state.data.subscription.device_type === "android") {
      return (
        <>
          <li className="mb-4">
            <Link
              to="/app/download"
              style={{
                textDecoration:
                  this.state.android_build_version !== null
                    ? "line-through"
                    : "none",
              }}
            >
              {this.state.android_build_version !== null ? (
                <img
                  src={
                    require("../assets/images/newdashboard/verify.png").default
                  }
                  alt="verify"
                  className="mr-2"
                  width="21px"
                  height="21px"
                />
              ) : (
                <img
                  src={
                    require("../assets/images/newdashboard/greyquestion.png")
                      .default
                  }
                  alt="notverify"
                  className="mr-2"
                  width="21px"
                  height="21px"
                />
              )}
              Android App
            </Link>
          </li>
        </>
      );
    }
    if (this.state.data.subscription.device_type === "all") {
      return (
        <>
          <li className="mb-4">
            <Link
              to="/app/download"
              style={{
                textDecoration:
                  this.state.android_build_version !== null
                    ? "line-through"
                    : "none",
              }}
            >
              {this.state.android_build_version !== null ? (
                <img
                  src={
                    require("../assets/images/newdashboard/verify.png").default
                  }
                  alt="verify"
                  className="mr-2"
                  width="21px"
                  height="21px"
                />
              ) : (
                <img
                  src={
                    require("../assets/images/newdashboard/greyquestion.png")
                      .default
                  }
                  alt="notverify"
                  className="mr-2"
                  width="21px"
                  height="21px"
                />
              )}
              Android App
            </Link>
          </li>
          <li className="mb-4">
            <Link
              to="/app/download"
              style={{
                textDecoration:
                  this.state.ios_build_version !== null
                    ? "line-through"
                    : "none",
              }}
            >
              {this.state.ios_build_version !== null ? (
                <img
                  src={
                    require("../assets/images/newdashboard/verify.png").default
                  }
                  alt="verify"
                  className="mr-2"
                  width="21px"
                  height="21px"
                />
              ) : (
                <img
                  src={
                    require("../assets/images/newdashboard/greyquestion.png")
                      .default
                  }
                  alt="notverify"
                  className="mr-2"
                  width="21px"
                  height="21px"
                />
              )}
              iOS App
            </Link>
          </li>
        </>
      );
    }
  };
  handleaddonpayment = (addoncode, e) => {
    if (addoncode === "feature_my_app" || addoncode === "prevent_screenshot") {
    } else {
      this.props.history.push(e);
    }
  };
  handlestatus_text = (addon_type, addon_code) => {
    if (addon_type === 1) {
      if (
        addon_code === "feature_my_app" ||
        addon_code === "prevent_screenshot"
      ) {
        return (
          <div className="service_filter comin_soon mt-3 ">
            <p>Coming Soon</p>
          </div>
        );
      } else {
        return (
          <div className="service_filter mt-3 ">
            <p>Professional Service</p>
          </div>
        );
      }
    } else {
      if (
        addon_code === "feature_my_app" ||
        addon_code === "prevent_screenshot"
      ) {
        return (
          <div className="service_filter comin_soon mt-3 ">
            <p>Coming Soon</p>
          </div>
        );
      } else {
        return (
          <div className="service_filter plugin_ mt-3 ">
            <p>Plugin</p>
          </div>
        );
      }
    }
  };

  Last7Days = (checklength) => {
    var result = [];
    for (var i = 0; i < checklength; i++) {
      var d = new Date();
      d.setDate(d.getDate() - i);
      result.push(d.toDateString());
    }

    return { result };
  };
  
  checkdate = (e) => {
    let newObject = [];
    let a = 0;
    let newdate = this.Last7Days(
      parseInt(this.state.selectfilter.replace("d", ""))
    );
    for (a; a < newdate.result.length; a++) {
      newObject.push(
        `${newdate.result[a].split(" ")[2]} ${newdate.result[a].split(" ")[1]}`
      );
    }
    let finalobj = [...newObject].reverse();
    return finalobj.map((resv) => resv);
  };

  checkdata = (e) => {
    let newObject = [];
    let newdate = this.Last7Days(
      parseInt(this.state.selectfilter.replace("d", ""))
    );
    
    let a = 0;
    for (a; a < newdate.result.length; a++) {
      if (
        e.some(
          (e) => newdate.result[a] === new Date(e.key_as_string).toDateString()
        ) === true
      ) {
        newObject.push(
          e.filter(
            (e) =>
              newdate.result[a] === new Date(e.key_as_string).toDateString()
          )[0]
        );
      } else {
        newObject.push({ doc_count: 0 });
      }
    }
    let finalobj = [...newObject].reverse();
    return finalobj.map((resv) => resv.doc_count);
  };

//   previewdowngrade = (e) => {
//     // e.preventDefault();
//     this.setState({
//         loader_remove: "d-block plan-upgradebtn",
//         submitval_confirmpop: "d-none",
//     });
//     const sendparam = {
//       app_id: parseInt(localStorage.getItem("Appid")),
//       total: this.state.one_time[0].amount,
//       plan_array: [
//         {
//           zoho_plan_code: this.state.one_time[0].plan_code,
//           type: 1,
//           amount: this.state.one_time[0].amount,
//           quantity: 1,
//         },
//       ],
//       currency: "$",
//       order_type: "downgrade",
//       downgrade_at_term_end:this.state.downgrade_at_term_end === 0 ? 1 : 0,
//     };
//     const sendtostrip = {
//       app_id: parseInt(localStorage.getItem("Appid")),
//       plan_array: [
//         {
//           price_id: this.state.one_time[0].plan_code,
//           quantity: 1,
//           type: 1,
//         },
//       ],
//       payment_method_id: "downgrade",
//       order_type: 2,
//       deal_id: null,
//       downgrade_at_term_end:this.state.downgrade_at_term_end === 0 ? 1 : 0,
//     };
//     const paddleParam = {
//       workspace_id:
//         localStorage.getItem("workspaceId") !== "" &&
//         localStorage.getItem("workspaceId") !== "undefined" &&
//         localStorage.getItem("workspaceId") !== undefined &&
//         localStorage.getItem("workspaceId") !== null
//           ? parseInt(localStorage.getItem("workspaceId"))
//           : null,
//       app_id:
//         localStorage.getItem("workspaceId") !== "" &&
//         localStorage.getItem("workspaceId") !== "undefined" &&
//         localStorage.getItem("workspaceId") !== undefined &&
//         localStorage.getItem("workspaceId") !== null
//           ? null
//           : parseInt(localStorage.getItem("Appid")),
//       deal_id: null,
//       order_type: 2,
//       price_id: this.state.one_time[0].plan_code,
//       quantity: 1,
//       type: 1,
//       downgrade_at_term_end:this.state.downgrade_at_term_end === 0 ? 1 : 0,
//     };

//      //Payment Api
//         if(this.state.customer_billing_type === 2)
//         {

//             
//             Makestrippayment(sendtostrip, this ,'' ,() =>
//             {
//                 if(this.state.code == 501 || this.state.code == 204)
//                 {
//                     this.setState({
//                         loader_remove: "d-none",
//                         submitval_confirmpop: "plan-upgradebtn",
//                     });
//                     this.setState({
//                         downgradeuser: false,
//                       });
//                     this.paidsubscriptionpopup();
//                 }
//             }
//             )
//         }
//         else if(this.state.customer_billing_type === 3)
//         {
//             
//             paddleChangePlan(paddleParam, this, () =>
//             {
//                 
//                 if(this.state.code == 501 || this.state.code == 204)
//                 {
//                     this.setState({
//                         loader_remove: "d-none",
//                         submitval_confirmpop: "plan-upgradebtn",
//                     });
//                     this.setState({
//                         downgradeuser: false,
//                       });
//                     this.paidsubscriptionpopup();
//                 }
//             }
//             )
//         }
//         else
//         {
//             
//             SendpaymentinfotoserverApi(sendparam, this, () => 
//             {
//                 if(this.state.code == 501 || this.state.code == 204)
//                 {
//                     this.setState({
//                         loader_remove: "d-none",
//                         submitval_confirmpop: "plan-upgradebtn",
//                     });
//                     this.setState({
//                         downgradeuser: false,
//                       });
//                     this.paidsubscriptionpopup();
//                 }
//                 else
//                 {
//                     const params = {
//                         app_id: parseInt(localStorage.getItem("Appid")),
//                         addon_array: [],
//                         type: "free",
//                         downgrade_at_term_end:this.state.downgrade_at_term_end === 0 ? 1 : 0,
//                         };
//                         DowngradeApi(params, this);
//                 }
                
//             });
//         }
//  };

  render() {
    
    let count = 0;
    let updatecount = 0;

    // Update version condition

    const versioniosCompare = (v1, v2, options) => {
      var lexicographical = options && options.lexicographical,
        zeroExtend = options && options.zeroExtend,
        v1parts = v1.split("."),
        v2parts = v2.split(".");

      function isValidPart(x) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
      }

      if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN;
      }

      if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push("0");
        while (v2parts.length < v1parts.length) v2parts.push("0");
      }

      if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
      }

      for (var i = 0; i < v1parts.length; ++i) {
        if (v2parts.length === i) {
          return 1;
        }

        if (v1parts[i] === v2parts[i]) {
          continue;
        } else if (v1parts[i] > v2parts[i]) {
          return ++updatecount;
        }
      }

      if (v1parts.length !== v2parts.length) {
        return <></>;
      }
    };
    {
      this.state.ios_build !== null && this.state.ios_latest &&
        versioniosCompare(this.state.ios_latest, this.state.ios_build);
    }

    // Compare android version

    const versionCompare = (v1, v2, options) => {
      var lexicographical = options && options.lexicographical,
        zeroExtend = options && options.zeroExtend,
        v1parts = v1.split("."),
        v2parts = v2.split(".");

      function isValidPart(x) {
        return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
      }

      if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
        return NaN;
      }

      if (zeroExtend) {
        while (v1parts.length < v2parts.length) v1parts.push("0");
        while (v2parts.length < v1parts.length) v2parts.push("0");
      }

      if (!lexicographical) {
        v1parts = v1parts.map(Number);
        v2parts = v2parts.map(Number);
      }

      for (var i = 0; i < v1parts.length; ++i) {
        {
        }
        if (v2parts.length === i) {
          return 1;
        }

        if (v1parts[i] === v2parts[i]) {
          continue;
        } else if (v1parts[i] > v2parts[i]) {
          return ++updatecount;
        }
      }

      if (v1parts.length !== v2parts.length) {
        return <></>;
      }
    };

    if (this.state.android_build !== null && this.state.android_latest !== null) {
      
      versionCompare(this.state.android_latest, this.state.android_build);
    }

    const AppearanceDetail = () => {
      return (
        this.state.appearance_selection && (
          <div className="pb-md-0 pb-4">
            <div className="d-md-flex justify-content-between align-items-center">
              <div>
                <p>
                  Bring your app to life. Cover the following steps to perfect
                  your app design, layout and appearance.
                </p>
              </div>
            </div>
            <div>
              <ul className="list-unstyled">
                <li className="mb-4">
                  <Link
                    to={
                      this.state.data.app_icon_screen_count > 0
                        ? "/app/app-icon"
                        : "#"
                    }
                    style={{
                      textDecoration:
                        this.state.data.app_icon_screen_count > 0
                          ? "line-through"
                          : "none",
                    }}
                  >
                    {this.state.data.app_icon_screen_count > 0 ? (
                      <img
                        src={
                          require("../assets/images/newdashboard/verify.png")
                            .default
                        }
                        alt="verify"
                        className="mr-2"
                        width="21px"
                        height="21px"
                      />
                    ) : (
                      <img
                        src={
                          require("../assets/images/newdashboard/greyquestion.png")
                            .default
                        }
                        alt="notverify"
                        className="mr-2"
                        width="21px"
                        height="21px"
                      />
                    )}
                    App Icon
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to={
                      this.state.data.splash_screen_count > 0
                        ? "/app/splash"
                        : "#"
                    }
                    style={{
                      textDecoration:
                        this.state.data.splash_screen_count > 0
                          ? "line-through"
                          : "none",
                    }}
                  >
                    {this.state.data.splash_screen_count > 0 ? (
                      <img
                        src={
                          require("../assets/images/newdashboard/verify.png")
                            .default
                        }
                        alt="verify"
                        className="mr-2"
                        width="21px"
                        height="21px"
                      />
                    ) : (
                      <img
                        src={
                          require("../assets/images/newdashboard/greyquestion.png")
                            .default
                        }
                        alt="notverify"
                        className="mr-2"
                        width="21px"
                        height="21px"
                      />
                    )}
                    Launch Screen
                  </Link>
                </li>
                {parseInt(localStorage.getItem("website_technology")) !== 2 &&
                parseInt(localStorage.getItem("website_technology")) !== 3 ? (
                  <li className="mb-4">
                    <Link
                      to={
                        this.state.data.login_register_screen_count === 1
                          ? "/app/login"
                          : "#"
                      }
                      style={{
                        textDecoration:
                          this.state.data.login_register_screen_count === 1
                            ? "line-through"
                            : "none",
                      }}
                    >
                      {this.state.data.login_register_screen_count === 1 ? (
                        <img
                          src={
                            require("../assets/images/newdashboard/verify.png")
                              .default
                          }
                          alt="verify"
                          className="mr-2"
                          width="21px"
                          height="21px"
                        />
                      ) : (
                        <img
                          src={
                            require("../assets/images/newdashboard/greyquestion.png")
                              .default
                          }
                          alt="notverify"
                          className="mr-2"
                          width="21px"
                          height="21px"
                        />
                      )}
                      Login &amp; Sign Up
                    </Link>
                  </li>
                ) : (
                  <></>
                )}
                <li className="mb-4">
                  <Link
                    to={
                      this.state.data.style_screen_count === 1
                        ? "/app/theme"
                        : "#"
                    }
                    style={{
                      textDecoration:
                        this.state.data.style_screen_count === 1
                          ? "line-through"
                          : "none",
                    }}
                  >
                    {this.state.data.style_screen_count === 1 ? (
                      <img
                        src={
                          require("../assets/images/newdashboard/verify.png")
                            .default
                        }
                        alt="verify"
                        className="mr-2"
                        width="21px"
                        height="21px"
                      />
                    ) : (
                      <img
                        src={
                          require("../assets/images/newdashboard/greyquestion.png")
                            .default
                        }
                        alt="notverify"
                        className="mr-2"
                        width="21px"
                        height="21px"
                      />
                    )}
                    Color Theme
                  </Link>
                </li>
                {parseInt(localStorage.getItem("website_technology")) !== 2 
                && parseInt(localStorage.getItem("website_technology")) !== 3 && (
                  <li className="mb-0">
                    <Link
                      to={
                        this.state.data.dashboard_screen_count === 1
                          ? "/app/home"
                          : "#"
                      }
                      style={{
                        textDecoration:
                          this.state.data.dashboard_screen_count === 1
                            ? "line-through"
                            : "none",
                      }}
                    >
                      {this.state.data.dashboard_screen_count === 1 ? (
                        <img
                          src={
                            require("../assets/images/newdashboard/verify.png")
                              .default
                          }
                          alt="verify"
                          className="mr-2"
                          width="21px"
                          height="21px"
                        />
                      ) : (
                        <img
                          src={
                            require("../assets/images/newdashboard/greyquestion.png")
                              .default
                          }
                          alt="notverify"
                          className="mr-2"
                          width="21px"
                          height="21px"
                        />
                      )}
                      Home Screen
                    </Link>
                  </li>
                )}
              </ul>
            </div>
            <div></div>
          </div>
        )
      );
    };
    const DownloadAppdetail = () => {
      return (
        this.state.dashboard_selection && (
          <div>
            <div className="d-md-flex justify-content-between align-items-center">
              <div>
                <p>
                  Generate the iOS and Android builds of your app. Complete the
                  following steps to publish your app on the Google Play and App
                  Store.
                </p>
              </div>
            </div>
            <div>
              <ul className="list-unstyled">{this.Buildstatus()}</ul>
            </div>
          </div>
        )
      );
    };
    const SiteConnectivityDetail = () => {
      return (
        this.state.connectivity_selection && (
          <div>
            <div className="d-md-flex justify-content-between align-items-center">
              <div>
                <p>
                  Automatically populate your website data in your app. Go
                  through the checklist below to effortlessly integrate your
                  website and app.{" "}
                </p>
              </div>
            </div>
            <div>
              <ul className="list-unstyled">
                <li className="mb-4">
                  <Link
                    to="/app/connect/wp-plugin"
                    style={{
                      textDecoration:
                        this.state.data.plugin_screen_count > 0
                          ? "line-through"
                          : "none",
                    }}
                  >
                    {this.state.data.plugin_screen_count > 0 ? (
                      <img
                        src={
                          require("../assets/images/newdashboard/verify.png")
                            .default
                        }
                        alt="verify"
                        className="mr-2"
                        width="21px"
                        height="21px"
                      />
                    ) : (
                      <img
                        src={
                          require("../assets/images/newdashboard/greyquestion.png")
                            .default
                        }
                        alt="notverify"
                        className="mr-2"
                        width="21px"
                        height="21px"
                      />
                    )}
                    Install plugin
                  </Link>
                </li>
                <li className="mb-4">
                  <Link
                    to="/app/connect/wp-grant-access"
                    // {
                    //   this.state.data.cs_ck_screen_count === 1
                    //     ? "/app/connect/wp-grant-access"
                    //     : "#"
                    // }
                    style={{
                      textDecoration:
                        this.state.data.cs_ck_screen_count > 0
                          ? "line-through"
                          : "none",
                    }}
                  >
                    {this.state.data.cs_ck_screen_count > 0 ? (
                      <img
                        src={
                          require("../assets/images/newdashboard/verify.png")
                            .default
                        }
                        alt="verify"
                        className="mr-2"
                        width="21px"
                        height="21px"
                      />
                    ) : (
                      <img
                        src={
                          require("../assets/images/newdashboard/greyquestion.png")
                            .default
                        }
                        alt="notverify"
                        className="mr-2"
                        width="21px"
                        height="21px"
                      />
                    )}
                    Access
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        )
      );
    };

    return (
      <>
      <div>
        <Header customclass={"header-responsive-new"} />
        {this.state.customer_billing_type == 2 ? (
            <Profitwell
              authToken="6a38f3c4608428fa86da4844e532b6b6"
              user_id={this.state.customer_id}
            />
          ) : this.state.customer_billing_type == 3 ? (
            <Profitwell
              authToken="894a549ee73eccb67e82477e6276abec"
              user_id={this.state.customer_id}
            />
          ) : null}
        <section className="dasboard_page bottombar-module mobile-display mt-4 mt-md-0">
        <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
        <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
          localStorage.getItem("appSideBarCollapse") !== null&&
          localStorage.getItem("appSideBarCollapse") === "true") ?
              " right-panel-gap-expand": ""}`}>
            
            
            <div className="app-dashboard-head">
              <h1>Dashboard <p className="appdash-tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5"><circle cx="9" cy="9" r="9" stroke="none"></circle><circle cx="9" cy="9" r="8.25" fill="none"></circle></g><line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"></line><path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"></path></g></svg>
                <p className="appdash-tooltipsubheader">Welcome to your dashboard, the central hub for managing your app and its components.</p>
                </p>
              </h1>
              {/* <p>Updated 10 Apr 2023, 11:45</p> */}
            </div>
            {this.state.dashboardspinner ? (
              <div className="app-icon-section access-loader" style={{ overflow: "hidden",minHeight:"80vh",marginTop:"10px"}}>
              <div className="newspinner">
              <svg xmlns="http://www.w3.org/2000/svg" 
                  xlink="http://www.w3.org/1999/xlink" 
                  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
                  width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                  <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                  <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                  <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                  style={{animationplaystate: "running", animationdelay: "0s"}}>
                  </animateTransform>
                  </circle>
                  </g>
                  <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                  <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                  <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                  style={{animationplaystate: "running", animationdelay: "0s"}}>
                  </animateTransform>
                  </circle>
                  </g>
                  <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                  <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                  <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                  style={{animationplaystate: "running", animationdelay: "0s"}}>

                  </animateTransform>
                  </circle>
                  </g>
                  <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                  <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                  <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                  style={{animationplaystate: "running", animationdelay: "0s"}}>

                  </animateTransform>
                  </circle>
                  </g>
                  </svg>
            </div>
          </div>
            ) : (
              <>
            <div className="app-dashboard-full">

              <div className="app-dashboard-left">

                <div className="dashboard-app-info">

                  <div className="dashboard-app-info-one">
                    <div className="dashboard-appicon">
                      <img src={
                                process.env.REACT_APP_Image_Path +
                                this.state.data.app_user_id +
                                "/" +
                                localStorage.getItem("Appid") +
                                process.env.REACT_APP_AppIcon_Display +
                                this.state.data.app_icon
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.src =
                                  require("../assets/images/dashboard/app-dashboard.png").default;
                              }} className="img-fluid" alt="appicon"/>
                    </div>

                    <div>
                      <h1>{this.state.data.app_name}</h1>
                      {this.state.data.app_domain && this.state.data.app_domain !== null && (
                        <a href={this.state.data.app_domain} target="_blank">{this.state.data.app_domain} <img src={require("../assets/images/dashboard/Goto.png").default} className="img-fluid" alt="appicon"/></a>
                      )}
                      <p>ID: {localStorage.getItem("Appid")}</p>
                    </div>
                  </div>

                  <div className="dashboard-app-info-two">
                    <div>
                    { parseInt(this.state.data.website_technology) === 4 ? 
                      
                      <img src={require("../assets/images/dashboard/wordpress-dashboard.png").default} className="img-fluid" alt="wordpress-icon"/>
                    : parseInt(this.state.data.website_technology) === 5 ? 
                      <img src={require("../assets/images/dashboard/woocomerce-dashboard.png").default} className="img-fluid" alt="woocomerce-icon"/>
                    : parseInt(this.state.data.website_technology) === 2 ? 
                      <img src={require("../assets/images/dashboard/webtoapp-dashboard.png").default} className="img-fluid" alt="webtoapp-icon"/>
                    : parseInt(this.state.data.website_technology) === 3 ?
                      <img src={require("../assets/images/dashboard/customapp-dashboard.png").default} className="img-fluid" alt="customapp-icon"/>
                    : <></>
                    }
                    </div>

                    <div>
                      <h2>{ parseInt(this.state.data.website_technology) === 4 ? 
                      
                       "Wordpress app"
                    : parseInt(this.state.data.website_technology) === 5 ? 
                      "Woocommerce app"
                    : parseInt(this.state.data.website_technology) === 2 ? 
                      "Web to app"
                    : parseInt(this.state.data.website_technology) === 3 ?
                      "Custom app"
                    : <></>
                    }</h2>
                      <p> {moment(this.state.data.app_created_date).format("D MMMM YYYY, h:mm a")}{" "}</p>
                    </div>
                  </div>

                  
                          {(this.state.appuser_role_id === 1 || this.state.appuser_role_id === 2) && localStorage.getItem("workspaceId")  && <div className="app-plan-detail app-mob-plans">
                            <div className="app-plan-alignment">
                              <div className="app-plan-detail-one">
                                <h3 style={{ textTransform: "capitalize" }}>Subscription</h3>
                                <p style={{ width: "100%" }}>This app is managed by your workspace.</p>
                                <h6>Plan: <span style={{textTransform:"capitalize"}}>{this.state.data.subscription?.plan_name ?? ''}</span></h6>
                                {/* {this.state.data.subscription && this.state.data.subscription?.gateway_subscription_id ? 
                                  <h6>ID: {this.state.data.subscription.gateway_subscription_id}</h6> : ''} */}
                              </div>
                              <div className="app-plan-detail-two">
                                <img src={require("../assets/images/dashboard/plan-pro.png").default} className="img-fluid" alt="plan-pro" />
                              </div>
                            </div>
                          </div>}
                          


                {
                  ((localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1) && (
                    localStorage.getItem("workspaceId") === "undefined" || localStorage.getItem("workspaceId") === undefined ||
                      localStorage.getItem("workspaceId") === "" || localStorage.getItem("workspaceId") === null
                  ) ) && this.state.appuser_role_id !==3 && this.state.appuser_role_id !==4 &&
                  <div className="app-plan-detail app-mob-plans">

                  <div className="app-plan-alignment">
                  <div className="app-plan-detail-one">
                    <h3 style={{textTransform:"capitalize"}}>
                      { this.state.data.subscription && this.state.data.subscription != '' ? 
                        this.state.data.subscription.plan_name == 'Free' || this.state.data.subscription.plan_name == 'free' || this.state.data.subscription.plan_name == 'preview' || this.state.data.subscription.plan_name == 'Preview' 
                        ? 'Free'
                        : this.state.data.subscription.plan 
                      : ''
                      }</h3>
                    { this.state.data.subscription.plan == 'preview' || this.state.data.subscription.plan_name == 'Free' || this.state.data.subscription.plan_name == 'free' || this.state.data.subscription.plan_name == 'preview' || this.state.data.subscription.plan_name == 'Preview' ?  
                      <p>You’re on the free plan. Please upgrade to access advanced features.</p>
                      : ( this.state.data.subscription.downgrade_at_term_end == 1 || this.state.data.subscription.reseller_package_id )&&  this.state.data.subscription.package_duration_id != 4 ?
                      <p>Your existing plan is scheduled for automated cancellation on  {moment( this.state.data.subscription.reseller_package_id ?this.state.data.subscription.reseller_package_expiry_date  : this.state.data.subscription.billing_date ).format("D MMMM YYYY")}.</p>
                      : this.state.data.subscription.package_duration_id != 4 ?
                      <p>Your existing plan is scheduled for automated renewal on {moment( this.state.data.renewal_date ).format("D MMMM YYYY")}.</p>
                      : this.state.data.subscription.package_duration_id == 4 ?
                      <p>You’re on the Lifetime plan. Enjoy updates and support without any recurring charges.</p>
                      : <></>
                    }
                    <h6>Plan: <span style={{textTransform:"capitalize"}}>{this.state.data.subscription && this.state.data.subscription != '' ? this.state.data.subscription.plan_name : ''}</span></h6>
                    {this.state.data.subscription && this.state.data.subscription != '' && this.state.data.subscription.gateway_subscription_id != '' ?
                    <h6>ID: {this.state.data.subscription.gateway_subscription_id}</h6>
                    : ''}
                  </div>

                  <div className="app-plan-detail-two">
                  { this.state.data.subscription.plan == 'preview' ?
                    <img src={require("../assets/images/dashboard/plan-preview.png").default} className="img-fluid" alt="plan-preview"/>
                  : this.state.data.subscription.plan == 'starter' ?
                    <img src={require("../assets/images/dashboard/plan-starter.png").default} className="img-fluid" alt="plan-starter"/>
                  : this.state.data.subscription.plan == 'pro' ?
                    <img src={require("../assets/images/dashboard/plan-pro.png").default} className="img-fluid" alt="plan-pro"/>
                  :
                    <img src={require("../assets/images/dashboard/plan-premium.png").default} className="img-fluid" alt="plan-premium"/>
                  }
                  </div>

                </div>

                <div className="app-status-upgrade">
                  <p style={{textTransform:"capitalize"}}>{this.state.data.subscription && this.state.data.subscription != '' ? this.state.data.subscription.status : ''}</p>
                  {(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) ?  
                    null
                  :
                    <>
                    { localStorage.getItem("workspaceId") === "undefined" || localStorage.getItem("workspaceId") === undefined ||
                      localStorage.getItem("workspaceId") === "" || localStorage.getItem("workspaceId") === null ? 
                      (
                        <a 
                        style={{
                          color:this.state.data.subscription.downgrade_at_term_end === 1?"#BCC1CE":"",
                          cursor:this.state.data.subscription.downgrade_at_term_end === 1?"pointer":"",
                          fontSize:this.state.data.subscription.downgrade_at_term_end === 1?"13px":"",
                      }}
                        href={
                          parseInt(localStorage.getItem('website_technology')) === 4 ? "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
                        : parseInt(localStorage.getItem('website_technology')) === 5 ? "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
                        : parseInt(localStorage.getItem('website_technology')) === 3 ? "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
                        : parseInt(localStorage.getItem('website_technology')) === 2 ? "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
                        : "/app/pricing"
                        }>
                          {this.state.data.subscription.downgrade_at_term_end === 1?"Reactivate":"Upgrade"}
                          {/* Upgrade */}
                        
                        </a>
                      )
                    : <></>
                    }
                    </>
                  }
                  </div>
                {this.state.data.subscription && this.state.data.subscription != '' && this.state.data.subscription.package_duration_id == 1 ?
                  <div className="dash-app-upgrade">
                  {parseInt(this.state.data.website_technology) == 5 ?
                    <p>Save up to  34% <span>on annual plan.</span></p>
                  :
                    <p>Save up to  53% <span>on annual plan.</span></p>
                  }
                    
                  </div>
                : <></>
                }

                  </div>}

                  <div className="dashboard-app-info-three">
                    <div>
                      <img src={this.state.data && this.state.data.owner_data.image !== '' && 
                               this.state.data.owner_data.image !== null && 
                               this.state.data.owner_data.image.indexOf("https") === -1 ? 
                               (process.env.REACT_APP_Image_Path + this.state.data.owner_data.id + process.env.REACT_APP_Profile + this.state.data.owner_data.image) 
                               : this.state.data && this.state.data.owner_data.image !== '' && this.state.data && this.state.data.owner_data.image !== null ? 
                                 this.state.data.owner_data.image 
                               : ''}

                               onError={({ currentTarget }) => {
                                currentTarget.src =
                                userdefaultImgOnError;
                              }}
                            className="img-fluid" alt="Profile-img"/>
                    </div>

                    <div>
                      <h3>{this.state.data && this.state.data.owner_data ? this.state.data.owner_data.profile_name : ''}</h3>
                      <h4>{this.state.data && this.state.data.owner_data ? this.state.data.owner_data.email : ''}</h4>
                      <h5> {this.state.data && this.state.data.owner_data ? "ID: "+this.state.data.owner_data.id : ''}</h5>
                      
                    </div>
                  </div>

                </div>

                <div className="dashboard-app-connectivity">
                
                { parseInt(localStorage.getItem("website_technology")) === 4 ||
                  parseInt(localStorage.getItem("website_technology")) === 5 ?
                  <>
                    <div className="dashboard-app-connectivity-one">
                      <a href="/app/connect/wp-plugin"></a>
                      <div>
                        <h3>Plugin</h3>
                        {this.state.data.plugin_screen_count > 0 ?
                          <h4>Connected</h4>
                        : <h4>Disconnected</h4>
                        }
                      </div>

                      <div>
                      {this.state.data.plugin_screen_count > 0 ?
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                        <g id="Group_41545" data-name="Group 41545" transform="translate(-578 -338)">
                          <g id="Group_41463" data-name="Group 41463" transform="translate(598.393 342.948) rotate(45)">
                            <path id="Path_108568" data-name="Path 108568" d="M3.945,12.933H5.26A4.1,4.1,0,0,0,9.205,8.685V4.248A4.105,4.105,0,0,0,5.26,0H3.945A4.1,4.1,0,0,0,0,4.248V7.2" transform="translate(0 0)" fill="none" stroke="#52cb86" stroke-width="3"/>
                            <path id="Path_108569" data-name="Path 108569" d="M5.26,0H3.945A4.105,4.105,0,0,0,0,4.249V8.686a4.106,4.106,0,0,0,3.945,4.249H5.26A4.105,4.105,0,0,0,9.205,8.686V5.734" transform="translate(0 8.911)" fill="none" stroke="#52cb86" stroke-width="3"/>
                          </g>
                          <rect id="Rectangle_31141" data-name="Rectangle 31141" width="32" height="32" transform="translate(578 338)" fill="none"/>
                        </g>
                      </svg>
                    :
                        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32.195" viewBox="0 0 32 32.195">
                        <g id="Group_41544" data-name="Group 41544" transform="translate(-578 -338)">
                          <g id="Group_41061" data-name="Group 41061" transform="translate(576.285 343.536) rotate(-21)">
                            <path id="Path_98020" data-name="Path 98020" d="M5.475,14.365A5.64,5.64,0,0,1,.045,10.225L0,10.063l3.086-.847.045.162a2.433,2.433,0,0,0,2.987,1.7L10.2,9.956A2.433,2.433,0,0,0,11.9,6.97l-.545-1.983a2.433,2.433,0,0,0-2.987-1.7l-.746.2L6.773.407,7.52.2a5.637,5.637,0,0,1,6.92,3.938l.545,1.983a5.636,5.636,0,0,1-3.938,6.92L6.965,14.163A5.619,5.619,0,0,1,5.475,14.365Z" transform="translate(10.809 10.107)" fill="#ff4848"/>
                            <path id="Path_98021" data-name="Path 98021" d="M7.084,15.7A5.612,5.612,0,0,1,3.1,14.055L1.649,12.6a5.63,5.63,0,0,1,0-7.962L4.642,1.646a5.636,5.636,0,0,1,7.962,0l.247.247L10.589,4.156l-.247-.247a2.433,2.433,0,0,0-3.437,0L3.912,6.9a2.43,2.43,0,0,0,0,3.436l1.454,1.454a2.433,2.433,0,0,0,3.436,0l.547-.547,2.263,2.263-.547.547A5.613,5.613,0,0,1,7.084,15.7Z" transform="translate(0 12.853)" fill="#ff4848"/>
                            <path id="Line_340" data-name="Line 340" d="M1.741,6.131,0,1.035,3.028,0,4.769,5.1Z" transform="translate(8.63 0) rotate(3)" fill="#ff4848"/>
                            <path id="Line_341" data-name="Line 341" d="M2.873,5.125,0,3.716,1.823,0,4.7,1.409Z" transform="translate(16.313 1.913) rotate(3)" fill="#ff4848"/>
                            <path id="Line_342" data-name="Line 342" d="M3.715,4.7,0,2.873,1.41,0,5.125,1.823Z" transform="translate(2.419 5.205) rotate(3)" fill="#ff4848"/>
                          </g>
                          <rect id="Rectangle_30876" data-name="Rectangle 30876" width="32" height="32" transform="translate(578 338)" fill="none"/>
                        </g>
                      </svg>
                    }
                    </div>

                    </div>
                    <div className="dashboard-app-connectivity-one">
                    <a href="/app/connect/wp-grant-access"></a>
                      <div>
                        <h3>Access</h3>
                        {this.state.data.cs_ck_screen_count > 0 ?
                          <h4>Connected</h4>
                        : <h4>Disconnected</h4>
                        }
                      </div>

                      <div>
                    
                      {this.state.data.cs_ck_screen_count > 0 ?
                          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                          <g id="Group_41546" data-name="Group 41546" transform="translate(-998 -338)">
                            <g id="Group_41464" data-name="Group 41464" transform="translate(1006.37 342.304)">
                              <path id="Union_3000" data-name="Union 3000" d="M7.68,8V4.529s0,0,0,0V4.432a2.258,2.258,0,0,0-.465-1.276A2.725,2.725,0,0,0,5.476,2.137h0l-.06-.009H5.406L5.347,2.12H5.34l-.054-.005-.014,0a.386.386,0,0,1-.053,0H5.2l-.051,0H4.847l-.051,0H4.781a.375.375,0,0,1-.053,0l-.014,0-.054.005H4.653l-.059.008H4.587l-.059.009H4.522a2.527,2.527,0,0,0-2.2,2.306c0,.023,0,.076,0,.082V8H0V4.525A4.788,4.788,0,0,1,5,0a4.788,4.788,0,0,1,5,4.525V8Z" transform="translate(2.631 0.696)" fill="#52cb86"/>
                              <rect id="Rectangle_31142" data-name="Rectangle 31142" width="16" height="14" rx="2" transform="translate(-0.369 8.696)" fill="#52cb86"/>
                            </g>
                            <rect id="Rectangle_31143" data-name="Rectangle 31143" width="32" height="32" transform="translate(998 338)" fill="none"/>
                          </g>
                        </svg>

                    :
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                      <g id="Group_41543" data-name="Group 41543" transform="translate(-998 -338)">
                        <g id="Group_41464" data-name="Group 41464" transform="translate(1006.37 342.304)">
                          <path id="Union_3000" data-name="Union 3000" d="M10,8H7.679V4.526A2.565,2.565,0,0,0,5,2.1,2.577,2.577,0,0,0,2.322,4.433c0,.01,0,.083,0,.092A21.485,21.485,0,0,1,0,4.526,4.785,4.785,0,0,1,5,0a4.785,4.785,0,0,1,5,4.525Z" transform="translate(2.631 0.696)" fill="#ff4848"/>
                          <rect id="Rectangle_31142" data-name="Rectangle 31142" width="16" height="14" rx="2" transform="translate(-0.369 8.696)" fill="#ff4848"/>
                        </g>
                        <rect id="Rectangle_31143" data-name="Rectangle 31143" width="32" height="32" transform="translate(998 338)" fill="none"/>
                      </g>
                    </svg>

                    }
                    </div>

                    </div>

                    <div className="dashboard-app-connectivity-one">
                  <a href="/app/connectivity/troubleshoot"></a>
                    <div>
                      <h3>Troubleshoot</h3>
                      <h4>Start troubleshooting</h4>
                    </div>

                    <div>
                    {(this.state.data.latest_connectivity && this.state.data.latest_connectivity.result_json && (this.state.data.latest_connectivity.result_json.length > 0)) ?
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                      <g id="Group_41547" data-name="Group 41547" transform="translate(-1418 -338)">
                        <path id="_8542527_tools_settings_icon_1_" data-name="8542527_tools_settings_icon (1)" d="M21.482,17,16.451,11.97a3.19,3.19,0,0,0-3.669-.6L8.2,6.793V4.125L2.7,0-.048,2.75l4.125,5.5H6.745l4.58,4.58a3.2,3.2,0,0,0,.6,3.669l5.031,5.031a1.6,1.6,0,0,0,2.264,0l2.264-2.264a1.605,1.605,0,0,0,0-2.264ZM14.2,9.667A4.52,4.52,0,0,1,17.422,11l.834.834a6.082,6.082,0,0,0,1.882-1.267,6.179,6.179,0,0,0,1.628-5.873.514.514,0,0,0-.864-.236l-3.2,3.2-2.917-.485L14.3,4.249l3.2-3.2a.519.519,0,0,0-.245-.868,6.189,6.189,0,0,0-5.869,1.628,6.089,6.089,0,0,0-1.77,4.451l3.527,3.527A4.681,4.681,0,0,1,14.2,9.667ZM9.739,13.19,7.3,10.754.756,17.306a2.749,2.749,0,0,0,3.888,3.888l5.31-5.31a4.614,4.614,0,0,1-.215-2.694ZM2.7,20.279a1.031,1.031,0,1,1,1.031-1.031A1.034,1.034,0,0,1,2.7,20.279Z" transform="translate(1423.05 343)" fill="#6e94ff"/>
                        <rect id="Rectangle_31144" data-name="Rectangle 31144" width="32" height="32" transform="translate(1418 338)" fill="none"/>
                      </g>
                    </svg>
                    :
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
                      <g id="Group_41544" data-name="Group 41544" transform="translate(-1418 -338)">
                        <path id="_8542527_tools_settings_icon_1_" data-name="8542527_tools_settings_icon (1)" d="M21.482,17,16.451,11.97a3.19,3.19,0,0,0-3.669-.6L8.2,6.793V4.125L2.7,0-.048,2.75l4.125,5.5H6.745l4.58,4.58a3.2,3.2,0,0,0,.6,3.669l5.031,5.031a1.6,1.6,0,0,0,2.264,0l2.264-2.264a1.605,1.605,0,0,0,0-2.264ZM14.2,9.667A4.52,4.52,0,0,1,17.422,11l.834.834a6.082,6.082,0,0,0,1.882-1.267,6.179,6.179,0,0,0,1.628-5.873.514.514,0,0,0-.864-.236l-3.2,3.2-2.917-.485L14.3,4.249l3.2-3.2a.519.519,0,0,0-.245-.868,6.189,6.189,0,0,0-5.869,1.628,6.089,6.089,0,0,0-1.77,4.451l3.527,3.527A4.681,4.681,0,0,1,14.2,9.667ZM9.739,13.19,7.3,10.754.756,17.306a2.749,2.749,0,0,0,3.888,3.888l5.31-5.31a4.614,4.614,0,0,1-.215-2.694ZM2.7,20.279a1.031,1.031,0,1,1,1.031-1.031A1.034,1.034,0,0,1,2.7,20.279Z" transform="translate(1423.05 343)" fill="#bcc1ce"/>
                        <rect id="Rectangle_31144" data-name="Rectangle 31144" width="32" height="32" transform="translate(1418 338)" fill="none"/>
                      </g>
                    </svg>

                    }

                  </div>

                  </div>
                  </>
                  : <></>
                }



                </div>

                <div className="app-downloads">

                  <div className="app-dashbuild-info">

                    <div className="app-build-info">
                    {this.state.android_build_version !== null ?
                   
                     <svg  xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                     <g id="Group_41544" data-name="Group 41544" transform="translate(-338 -464)">
                       <rect id="Rectangle_31083" data-name="Rectangle 31083" width="80" height="80" transform="translate(338 464)" fill="#fff"/>
                       <path id="Union_2992" data-name="Union 2992" d="M18991.043-14249.807v-8.4h-4.814v8.4a3.025,3.025,0,0,1-3.205,2.807,3.021,3.021,0,0,1-3.2-2.807v-8.4h-.021a4.8,4.8,0,0,1-4.811-4.8v-19.187h27.248V-14263a4.8,4.8,0,0,1-4.793,4.8v8.4a3.023,3.023,0,0,1-3.2,2.807A3.023,3.023,0,0,1,18991.043-14249.807Zm12.824-17.583v-11.994a3.02,3.02,0,0,1,3.2-2.8,3.022,3.022,0,0,1,3.2,2.8v11.994a3.023,3.023,0,0,1-3.2,2.8A3.024,3.024,0,0,1,19003.867-14267.39Zm-36.865,0v-11.994a3.022,3.022,0,0,1,3.2-2.8,3.021,3.021,0,0,1,3.205,2.8v11.994a3.022,3.022,0,0,1-3.205,2.8A3.019,3.019,0,0,1,18967-14267.39Zm7.957-16.254a13.42,13.42,0,0,1,6.967-10.754l-2.277-3.934a.487.487,0,0,1,.2-.594.486.486,0,0,1,.617.112l2.307,4a14.27,14.27,0,0,1,11.705,0l2.3-4a.464.464,0,0,1,.289-.169.471.471,0,0,1,.455.169.485.485,0,0,1,.078.482l-2.279,3.942a13.446,13.446,0,0,1,6.975,10.754Zm18.814-5.033a1.147,1.147,0,0,0,1.141,1.142,1.137,1.137,0,0,0,1.139-1.142,1.139,1.139,0,0,0-1.143-1.141A1.144,1.144,0,0,0,18993.773-14288.677Zm-12.25-.806a1.145,1.145,0,0,0-.244,1.241,1.153,1.153,0,0,0,1.051.706,1.137,1.137,0,0,0,1.1-.844,1.141,1.141,0,0,0-.527-1.282,1.165,1.165,0,0,0-.574-.152A1.129,1.129,0,0,0,18981.523-14289.482Z" transform="translate(-18611.002 14777)" fill="#00de7a"/>
                     </g>
                   </svg>
                    :
                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                    <g id="Group_41543" data-name="Group 41543" transform="translate(-338 -464)">
                      <rect id="Rectangle_31083" data-name="Rectangle 31083" width="80" height="80" transform="translate(338 464)" fill="#fff"/>
                      <path id="Union_2992" data-name="Union 2992" d="M18991.043-14249.807v-8.4h-4.814v8.4a3.025,3.025,0,0,1-3.205,2.807,3.021,3.021,0,0,1-3.2-2.807v-8.4h-.021a4.8,4.8,0,0,1-4.811-4.8v-19.187h27.248V-14263a4.8,4.8,0,0,1-4.793,4.8v8.4a3.023,3.023,0,0,1-3.2,2.807A3.023,3.023,0,0,1,18991.043-14249.807Zm12.824-17.583v-11.994a3.02,3.02,0,0,1,3.2-2.8,3.022,3.022,0,0,1,3.2,2.8v11.994a3.023,3.023,0,0,1-3.2,2.8A3.024,3.024,0,0,1,19003.867-14267.39Zm-36.865,0v-11.994a3.022,3.022,0,0,1,3.2-2.8,3.021,3.021,0,0,1,3.205,2.8v11.994a3.022,3.022,0,0,1-3.205,2.8A3.019,3.019,0,0,1,18967-14267.39Zm7.957-16.254a13.42,13.42,0,0,1,6.967-10.754l-2.277-3.934a.487.487,0,0,1,.2-.594.486.486,0,0,1,.617.112l2.307,4a14.27,14.27,0,0,1,11.705,0l2.3-4a.464.464,0,0,1,.289-.169.471.471,0,0,1,.455.169.485.485,0,0,1,.078.482l-2.279,3.942a13.446,13.446,0,0,1,6.975,10.754Zm18.814-5.033a1.147,1.147,0,0,0,1.141,1.142,1.137,1.137,0,0,0,1.139-1.142,1.139,1.139,0,0,0-1.143-1.141A1.144,1.144,0,0,0,18993.773-14288.677Zm-12.25-.806a1.145,1.145,0,0,0-.244,1.241,1.153,1.153,0,0,0,1.051.706,1.137,1.137,0,0,0,1.1-.844,1.141,1.141,0,0,0-.527-1.282,1.165,1.165,0,0,0-.574-.152A1.129,1.129,0,0,0,18981.523-14289.482Z" transform="translate(-18611.002 14777)" fill="#bcc1ce"/>
                    </g>
                    </svg>
                     
                    }

                      <h3><span>Android</span> <span> 
                        {this.state.android_build_version !== null
                        ? this.state.android_build_version
                        : ""}</span></h3>
                    {this.state.android_build_info && this.state.android_build_info.app_in_process == 1 ?
                       <h4 style={{color: "#BCC1CE"}}>Build processing</h4>
                    : this.state.android_build_info && this.state.android_build_info.app_in_process == 0 ?  
                      (
                          this.state.android_build_info.build_status == 0 || this.state.android_build_info.build_status == 3 ?
                          <>
                            <h4  style={{color: "#BCC1CE"}}>Draft</h4>
                            <h5>Build your Android app and launch it on the Play Store.</h5>
                            <a className="buildapp-dashboard" href="/app/download" style={{cursor:"pointer",color:"#3064F9"}}>Build</a>
                          </>
                        : this.state.android_build_info.build_status == 1 || this.state.android_build_info.build_status == 4 ?
                          <>
                            <h4  style={{color: "#52CB86"}}>Build completed</h4>
                            
                          {this.state.android_build_version !== null && this.state.android_latest !== null && (versionCompare(this.state.android_latest, this.state.android_build) == 1)?
                            <h5  style={{color: "#1B2952" , fontFamily: "Axiforma-Bold"}}>New version available <a href="/app/download">click here</a></h5>
                          :
                            <h5 >Your last build was successful.</h5>
                          }
                            { this.state.downloadbuild === true ?
                              <a className="rebuildapp-dashboard" href="/app/download">Download</a>
                            :
                              <a className="buildapp-dashboard" href="/app/download" style={{cursor:"pointer",color:"#3064F9"}}>Build</a>
                            }
                          </>
                        : this.state.android_build_info.build_status == 2 ?
                          <>
                            <h4  style={{color: "#FF4848"}}>Your last build failed</h4>
                            <a className="rebuildapp-dashboard" href="/app/download" style={{cursor:"pointer"}}>Rebuild</a>
                          </>
                        :
                          <>
                            <h4  style={{color: "#BCC1CE"}}>Draft</h4>
                            <h5>Build your Android app and launch it on the Play Store.</h5>
                            <a className="buildapp-dashboard" href="/app/download" style={{cursor:"pointer",color:"#3064F9"}}>Build</a>
                          </>
                        
                      )
                    :
                        <>
                          <h4  style={{color: "#BCC1CE"}}>Draft</h4>
                          <h5>Build your Android app and launch it on the Play Store.</h5>
                          <a className="buildapp-dashboard" href="/app/download" style={{cursor:"pointer",color:"#3064F9"}}>Build</a>
                        </>
                    }
                       

                    </div>

                    <div className="app-build-info">
                    {this.state.ios_build_version === null ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                    <g id="Group_41543" data-name="Group 41543" transform="translate(-653 -464)">
                      <rect id="Rectangle_31084" data-name="Rectangle 31084" width="80" height="80" transform="translate(653 464)" fill="#fff"/>
                      <g id="apple-logo" transform="translate(673.466 479.507)">
                        <g id="Group_41407" data-name="Group 41407">
                          <path id="Path_108509" data-name="Path 108509" d="M73.884,127.666a10.85,10.85,0,0,1-1.966-6.354,10.154,10.154,0,0,1,1.852-5.9,18.465,18.465,0,0,1,3.249-3.362,14.7,14.7,0,0,0-2.964-2.849,10.419,10.419,0,0,0-6.07-1.852,16.081,16.081,0,0,0-4.929.969,14.27,14.27,0,0,1-4.075.969,18.331,18.331,0,0,1-3.933-.855A18.958,18.958,0,0,0,50,107.578a9.838,9.838,0,0,0-8.093,4.1,16.907,16.907,0,0,0-3.22,10.658,29.214,29.214,0,0,0,4.189,14.333q4.246,7.381,8.577,7.381a10.3,10.3,0,0,0,3.761-.969,10.983,10.983,0,0,1,4.047-.94,12.6,12.6,0,0,1,4.3.912,13.175,13.175,0,0,0,3.989.911q3.647,0,7.323-5.585a27.507,27.507,0,0,0,3.5-7.124A8.668,8.668,0,0,1,73.884,127.666Z" transform="translate(-38.692 -96.635)" fill="#bcc1ce"/>
                          <path id="Path_108510" data-name="Path 108510" d="M239.726,9a11.7,11.7,0,0,0,2.651-3.932A10.871,10.871,0,0,0,243.2,1.2c0-.076,0-.171-.014-.285s-.014-.209-.014-.285A2.752,2.752,0,0,1,243.1.313,2.646,2.646,0,0,0,243.032,0q-5.015,1.169-7.124,4.218a13,13,0,0,0-2.223,7.323,15.924,15.924,0,0,0,2.963-.484A8.075,8.075,0,0,0,239.726,9Z" transform="translate(-214.223 0)" fill="#bcc1ce"/>
                        </g>
                      </g>
                      <rect id="Rectangle_31081" data-name="Rectangle 31081" width="52" height="52" transform="translate(667 478)" fill="none"/>
                    </g>
                  </svg>
                  :

                    <svg xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80">
                        <g id="Group_41543" data-name="Group 41543" transform="translate(-653 -464)">
                          <rect id="Rectangle_31084" data-name="Rectangle 31084" width="80" height="80" transform="translate(653 464)" fill="#fff"/>
                          <g id="apple-logo" transform="translate(673.466 479.506)">
                            <g id="Group_41407" data-name="Group 41407">
                              <path id="Path_108509" data-name="Path 108509" d="M73.884,127.666a10.85,10.85,0,0,1-1.966-6.354,10.154,10.154,0,0,1,1.852-5.9,18.465,18.465,0,0,1,3.249-3.362,14.7,14.7,0,0,0-2.964-2.849,10.419,10.419,0,0,0-6.07-1.852,16.081,16.081,0,0,0-4.929.969,14.27,14.27,0,0,1-4.075.969,18.331,18.331,0,0,1-3.933-.855A18.958,18.958,0,0,0,50,107.578a9.838,9.838,0,0,0-8.093,4.1,16.907,16.907,0,0,0-3.22,10.658,29.214,29.214,0,0,0,4.189,14.333q4.246,7.381,8.577,7.381a10.3,10.3,0,0,0,3.761-.969,10.983,10.983,0,0,1,4.047-.94,12.6,12.6,0,0,1,4.3.912,13.175,13.175,0,0,0,3.989.911q3.647,0,7.323-5.585a27.507,27.507,0,0,0,3.5-7.124A8.668,8.668,0,0,1,73.884,127.666Z" transform="translate(-38.692 -96.635)" fill="#666"/>
                              <path id="Path_108510" data-name="Path 108510" d="M239.726,9a11.7,11.7,0,0,0,2.651-3.932A10.871,10.871,0,0,0,243.2,1.2c0-.076,0-.171-.014-.285s-.014-.209-.014-.285A2.752,2.752,0,0,1,243.1.313,2.646,2.646,0,0,0,243.032,0q-5.015,1.169-7.124,4.218a13,13,0,0,0-2.223,7.323,15.924,15.924,0,0,0,2.963-.484A8.075,8.075,0,0,0,239.726,9Z" transform="translate(-214.223 0)" fill="#666"/>
                            </g>
                          </g>
                          <rect id="Rectangle_31081" data-name="Rectangle 31081" width="52" height="52" transform="translate(667 478)" fill="none"/>
                        </g>
                    </svg>
                  }

                      <h3><span>iOS</span> <span>{this.state.ios_build_version !== null ? this.state.ios_build_version: ""}</span></h3>
                    
                    {this.state.ios_build_info && this.state.ios_build_info.app_in_process == 1 ?
                       <h4 style={{color: "#BCC1CE"}}>Build processing</h4>
                    : this.state.ios_build_info && this.state.ios_build_info.app_in_process == 0 ?  
                      (
                          this.state.ios_build_info.build_status == 0 || this.state.ios_build_info.build_status == 3 ?
                          <>
                            <h4  style={{color: "#BCC1CE"}}>Draft</h4>
                            <h5>Build your iOS app and launch it on the App Store</h5>
                            <a className="buildapp-dashboard" href="/app/download" style={{cursor:"pointer",color:"#3064F9"}}>Build</a>
                          </>
                        : this.state.ios_build_info.build_status == 1 || this.state.ios_build_info.build_status == 4 ?
                          <>
                            <h4  style={{color: "#52CB86"}}>Build completed</h4>
                            
                          {this.state.ios_build !== null && this.state.ios_latest !== null && (versionCompare(this.state.ios_latest, this.state.ios_build) == 1)?
                            <h5  style={{color: "#1B2952" , fontFamily: "Axiforma-Bold"}}>New version available <a href="">click here</a></h5>
                          :
                            <h5 >Your last build was successful.</h5>
                          }
                           { this.state.downloadiosbuild === true ?
                              <a className="rebuildapp-dashboard" href="/app/download">Download</a>
                            :
                              <a className="buildapp-dashboard" href="/app/download" style={{cursor:"pointer",color:"#3064F9"}}>Build</a>
                            }
                          </>
                        : this.state.ios_build_info.build_status == 2 ?
                          <>
                            <h4  style={{color: "#FF4848"}}>Your last build failed</h4>
                            <a className="rebuildapp-dashboard" href="/app/download" style={{cursor:"pointer"}}>Rebuild</a>
                          </>
                        :
                          <>
                            <h4  style={{color: "#BCC1CE"}}>Draft</h4>
                            <h5>Build your iOS app and launch it on the App Store.</h5>
                            <a className="buildapp-dashboard" href="/app/download" style={{cursor:"pointer",color:"#3064F9"}}>Build</a>
                          </>
                        
                      )
                    :
                        <>
                          <h4  style={{color: "#BCC1CE"}}>Draft</h4>
                          <h5>Build your iOS app and launch it on the App Store.</h5>
                          <a className="buildapp-dashboard" href="/app/download" style={{cursor:"pointer",color:"#3064F9"}}>Build</a>
                        </>
                    }


                    </div>

                  </div>
                  {
                    this.state.data.subscription.plan != 'premium' && 
                    this.state.data.is_analytics_enabled != 1 
                    // && parseInt(this.state.website_technology) !== 2
                    
                    ?
                  
                   <div className="app-graph-upgrade">
                    { (
                     (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                 localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                 localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) &&
                                 this.state.appuser_role_id !==4 && this.state.appuser_role_id !==3 )
                    &&
                      <Link to={localStorage.getItem("workspaceId") === "undefined" || localStorage.getItem("workspaceId") === undefined ||
                      localStorage.getItem("workspaceId") === "" || localStorage.getItem("workspaceId") === null ? 
                      (this.state.data.subscription.reseller_package_id || 
                        this.state.data.subscription.plan_code.includes("appsumo")) ? "/app/addons" :
                        parseInt(localStorage.getItem('website_technology')) === 4 ? "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
                      : parseInt(localStorage.getItem('website_technology')) === 5 ? "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
                      : parseInt(localStorage.getItem('website_technology')) === 3 ? "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
                      : parseInt(localStorage.getItem('website_technology')) === 2 ? "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
                      : "/app/pricing"
                    : `/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`
                    } 
                    className="graph-link-upgrade"></Link>
                  }
                     {
                      ( (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                  localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                  localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) &&
                                  this.state.appuser_role_id !==4 && this.state.appuser_role_id !==3 )
                     &&
                      <div className="app-dashboard-graph-upgrade">
                        <h3>Upgrade</h3>
                        <p>to track your app analytics.</p>
                      </div>  }

                      <div className="dashboard-analytics">
                        <h3>App traffic</h3>
                        <Link to={"/app/analytics"} style={{
                          zIndex:"3"
                        }}>
                        <svg id="Group_41541" data-name="Group 41541" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <circle id="Ellipse_8285" data-name="Ellipse 8285" cx="10" cy="10" r="10" fill="#e2eaff"/>
                        <path id="Path_102452" data-name="Path 102452" d="M0,4,4,0,8,4" transform="translate(12.5 6) rotate(90)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                        </svg>
                        </Link> 
                      </div>
                      
                      <div className="app-graph-upgrade-link">
                        
                        <img src={require("../assets/images/dashboard/dashboard-dummy1.png").default} className="analytics2k img-fluid" alt="dashboard-dummy"/>
                        <img src={require("../assets/images/dashboard/dashboard-dummy2.png").default} className="analyticslaptop img-fluid" alt="dashboard-dummy"/>
                      </div>
                    </div>
                    :
                    <>
                   {this.state.data.record_analytics_data === 0 ?
                   <div className="app-graph-upgrade web-to-app-graph">
                      <div className="dashboard-analytics">
                        <h3>App traffic</h3>
                        <Link to={"/app/analytics"} style={{
                          zIndex:"3"
                        }}>
                        <svg id="Group_41541" data-name="Group 41541" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <circle id="Ellipse_8285" data-name="Ellipse 8285" cx="10" cy="10" r="10" fill="#e2eaff"/>
                        <path id="Path_102452" data-name="Path 102452" d="M0,4,4,0,8,4" transform="translate(12.5 6) rotate(90)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                        </svg>
                        </Link> 
                      </div>
                        <img src={require("../assets/images/dashboard/analytics-disable.png").default} className="img-fluid" alt="dashboard-dummy"/>
                   </div>
                   : 
                    <div className="app-download-info" style={{
                      position:"relative"
                    }}>
                      <div className="dashboard-analytics">
                        <h3>App traffic</h3>
                        <Link to={"/app/analytics"}>
                        <svg id="Group_41541" data-name="Group 41541" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <circle id="Ellipse_8285" data-name="Ellipse 8285" cx="10" cy="10" r="10" fill="#e2eaff"/>
                        <path id="Path_102452" data-name="Path 102452" d="M0,4,4,0,8,4" transform="translate(12.5 6) rotate(90)" fill="none" stroke="#3064f9" stroke-width="1.5"/>
                        </svg>
                        </Link>
                      </div>

                        {this.state.histogramspinner === true ? 
                        (
                                // <div className={styles.histogramspinner}>
                                //   <Spinner animation="border" variant="primary" />
                                // </div>
                                <div className="analytics-spin" style={{ background: "none" }}>
                         
                               <svg xmlns="http://www.w3.org/2000/svg" 
               xlink="http://www.w3.org/1999/xlink" 
               style={{margin: "auto", background: "rgba(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
               width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
               <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                 <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                 style={{animationplaystate: "running", animationdelay: "0s"}}>
                 </animateTransform>
               </circle>
               </g>
               <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
               style={{animationplaystate: "running", animationdelay: "0s"}}>
               </animateTransform>
               </circle>
               </g>
               <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
               style={{animationplaystate: "running", animationdelay: "0s"}}>
     
               </animateTransform>
               </circle>
               </g>
               <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
               <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
               style={{animationplaystate: "running", animationdelay: "0s"}}>
     
               </animateTransform>
               </circle>
               </g>
                               </svg>
                             </div>
                        )
                        : this.state.reportdata.length == 0 ?
                          <>
                            { localStorage.getItem("workspaceId") === "undefined" || localStorage.getItem("workspaceId") === undefined ||
                              localStorage.getItem("workspaceId") === "" || localStorage.getItem("workspaceId") === null ? 
                              (
                                <Link to={"/app/analytics"}><img src={require("../assets/images/dashboard/dashboard-dummy2.png").default} className="img-fluid" alt="dashboard-dummy"/></Link>
                              )
                            : <></>
                            }
                          </>
                          
                        : 
                        (

                              this.state.reportdata.map((res) => res.item_type === "app_api_requests_histogram" &&
                                  (Object.keys(res.value).length === 0 || Object.keys(res.value.aggregations.aggs_alias.buckets).length === 0 ? 
                                  (
                                    <div className={styles.adjustbargraphheight +" new-analytics-barheight"}>
                                      <div className={styles.adjustimg}>
                                        {/* <img src={require("../assets/images/dashboard/analytics-nodata-web.png").default}
                                          className="d-md-block d-none analytics-bar-img Report_nodata_img"
                                          alt=" "
                                          style={{height:"420px",width:"540px"}}
                                        /> */}
                                         <img src={require("../assets/images/dashboard/no-data1.png").default}
                                          className="analytics-graph2k img-fluid"
                                          alt=" "
                                          
                                        />
                                        <img src={require("../assets/images/dashboard/no-data2.png").default}
                                          className="analytics-graph-laptop img-fluid"
                                          alt=" "
                                          
                                        />
                                        <img src={require("../assets/images/dashboard/no-data-mob.png").default}
                                          className="analytics-graph-mob img-fluid"
                                          alt=" "
                                          
                                        />
                                        {/* <img src={require("../assets/images/dashboard/analytics-nodata-mobile.png").default}
                                          className="d-md-none d-block analytics-nodata-img Report_mobile_img"
                                          alt=" "
                                          style={{height:"420px",width:"540px"}}
                                        /> */}
                                      </div>
                                    </div>
                                  ) : (
                                    res.value.aggregations !== undefined && res.value.aggregations !== null && (
                                      <>
                                        {
                                          <>
                                            {res.value.aggregations.aggs_alias.buckets[0].key_as_string !==undefined && (
                                              <>
                                                <Histogram
                                                  value={{maintainAspectRatio: true,responsive: true,
                                                          labels: this.checkdate(
                                                          res.value.aggregations.aggs_alias.buckets),
                                                          datasets: [
                                                            {
                                                              label: "Traffic",
                                                              responsive: true,
                                                              maintainAspectRatio: true,
                                                              data: this.checkdata(
                                                                res.value.aggregations
                                                                  .aggs_alias.buckets
                                                              ),
                                                              backgroundColor: "#A7BEFF",
                                                              borderColor: "#A7BEFF",
                                                              fill: true,
                                                              padding: "20px",
                                                            },
                                                          ],
                                                        }}
                                                />
                                              </>
                                            )}
                                          </>
                                        }
                                      </>
                                    )
                                  ))
                              )
                            )}

                    </div>
                   }
                   </> }
                </div>

                <div className="app-total-steps app-dash-steps-mob">

                  <div className="dashboard-progress">
                        {this.handlepointcheck() < this.state.checklisttotal ?
                          <h2>Let’s set up your app</h2>
                        :
                          <h2>You’re all set</h2>
                        }
                      <div className="dashboard-progress-bar">
                          <div>
                              <p>{this.handlepointcheck()}/{this.state.checklisttotal}</p>
                          </div>
                          <Progress value={this.handlepointcheck()*(100/this.state.checklisttotal)}  />
                      </div>    
                  </div>

                    <div className="app-seven-steps">

                    <div className="app-steps">

                        <a href="/app/app-icon"></a>
                        {this.state.data.app_icon_screen_count > 0 ?
                        <>                     
                          <>                     
                        {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                    <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                    <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                </g>
                            </svg>
    
                        </div>
                        </>
                        </>
                      :
                      <>
                        {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                        <div>
                        <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                        </svg>


                        </div>
                      </>
                      }
                                  
                        <div>
                            <h4>App icon</h4>
                            <p>Create a personalised app icon image.</p>
                        </div>
                    </div>
                    <div className="app-steps">

                        <a href="/app/splash"></a>
                        {this.state.data.splash_screen_count > 0 ?
                        <>                     
                          <>                     
                        {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                    <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                    <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                </g>
                            </svg>
    
                        </div>
                        </>
                        </>
                      :
                      <>
                        {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                        <div>
                        <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                        </svg>

                        </div>
                      </>
                      }
                                  
                        <div>
                            <h4>Splash screen</h4>
                            <p>Upload an impactful splash screen image.</p>
                        </div>
                    </div>

                      {parseInt(this.state.data.website_technology) == 4 || parseInt(this.state.data.website_technology) == 5  ?
                           <div className="app-steps">

                           <a href="/app/connect/wp-plugin"></a>
                           {this.state.data.plugin_screen_count > 0 ?
                           <>                     
                             <>                     
                           {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                           <div>
                               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                   <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                       <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                       <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                   </g>
                               </svg>
     
                           </div>
                           </>
                           </>
                           :
                           <>
                           {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                           <div>
                           <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                        </svg>
     
                           </div>
                           </>
                           }
                                     
                           <div>
                               <h4>Install plugin</h4>
                               <p>Upload and activate our WordPress plugin.</p>
                           </div>
                           </div>
                      : parseInt(this.state.data.website_technology) == 2 ?
                      <div className="app-steps">

                      <a href="/app/web-view-settings"></a>
                      {this.state.data.web_view_settings_screen_count > 0 ?
                      <>                     
                        <>                     
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                  <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                  <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                              </g>
                          </svg>

                      </div>
                      </>
                      </>
                      :
                      <>
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                      <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                        </svg>

                      </div>
                      </>
                      }
                                
                      <div>
                          <h4>Web view</h4>
                          <p>Manage the settings of your in-app browser.</p>
                      </div>
                      </div>
                      : parseInt(this.state.data.website_technology) == 3 ?
                      <>
                      <div className="app-steps">

                      <a href="/app/pages"></a>
                      {this.state.data.page_count > 0 ?
                      <>                     
                        <>                     
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                  <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                  <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                              </g>
                          </svg>

                      </div>
                      </>
                      </>
                      :
                      <>
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                      <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                        </svg>

                      </div>
                      </>
                      }
                                
                      <div>
                          <h4>Pages</h4>
                          <p>Create and customize your app’s first page.</p>
                      </div>
                      </div>
                      <div className="app-steps">

                      <a href="/app/posts"></a>
                      {this.state.data.post_count > 0 ?
                      <>                     
                        <>                     
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                  <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                  <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                              </g>
                          </svg>

                      </div>
                      </>
                      </>
                      :
                      <>
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                      <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                        </svg>

                      </div>
                      </>
                      }
                                
                      <div>
                          <h4>Post</h4>
                          <p>Create and customize your app’s first post.</p>
                      </div>
                      </div>
                      </>
                      : <></>
                      }
                      {parseInt(this.state.data.website_technology) == 5 ?
                      <div className="app-steps">

                      <a href="/app/connect/wp-grant-access"></a>
                      { (this.state.data?.cs_ck_screen_count > 0 && this.state.data?.connectivity_toggle_status > 0 ) ?
                      <>                     
                        <>                     
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                  <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                  <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                              </g>
                          </svg>

                      </div>
                      </>
                      </>
                      :
                      <>
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                      <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                        </svg>

                      </div>
                      </>
                      }
                                
                      <div>
                          <h4>Grant access</h4>
                          <p>Provide access to your WordPress website.</p>
                      </div>
                      </div>
                      : <></>
                      }
                      <div className="app-steps">

                          <a href="/app/bottom-bar"></a>
                          {this.state.data.bottom_menu > 0 ?
                          <>                     
                            <>                     
                          {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                          <div>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                  <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                      <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                      <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                  </g>
                              </svg>

                          </div>
                          </>
                          </>
                          :
                          <>
                          {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                          <div>
                          <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                              <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                              <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                            </svg>

                          </div>
                          </>
                          }
                                    
                          <div>
                              <h4>Bottom bar</h4>
                              <p>Add atleast one item to your Bottom bar.</p>
                          </div>
                          </div>

                      <div className="app-steps">

                        <a href="/app/advanced-notifications"></a>
                        {this.state.data.notification_screen_count > 0 ?
                        <>                     
                          <>                     
                        {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                    <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                    <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                </g>
                            </svg>

                        </div>
                        </>
                        </>
                        :
                        <>
                        {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                        <div>
                        <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                        </svg>

                        </div>
                        </>
                        }
                                  
                        <div>
                            <h4>Notification</h4>
                            <p>Send your app’s first push notification.</p>
                        </div>
                      </div>
                        <div className="app-steps">

                          <a href="/app/download"></a>
                          {this.state.data.is_build_created > 0 ?
                          <>                     
                            <>                     
                          {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                          <div>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                  <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                      <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                      <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                  </g>
                              </svg>

                          </div>
                          </>
                          </>
                          :
                          <>
                          {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                          <div>
                          <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                            <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                            <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                            <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                          </svg>

                          </div>
                          </>
                          }
                                    
                          <div>
                              <h4>Download</h4>
                              <p>Configure and download your app build.</p>
                          </div>
                          </div>
                    

                      <div className="app-steps-animation"></div>

                      </div>
                  </div>
                 {
                  this.state.appuser_role_id !== 4
                 &&
                <div className="app-team app-dash-team-mob">

                    <h3>Team</h3>

                    <div className="app-teamlist">
                   
                        <>
                            <div className="teammember">
                            {this.state.data.owner_data.image === "" ||
                                  this.state.data.owner_data.image === null ||
                                  this.state.data.owner_data.image === "null" ||
                                  this.state.data.owner_data.image === undefined ||
                                  this.state.data.owner_data.image === "undefined" ||
                                  !this.state.data.owner_data.image ? 
                              (
                                <div className="app-dashboard-img" style={{backgroundImage : "url("+userdefaultImgOnError+")"}}></div>
                              )
                            : this.state.data.owner_data.image.indexOf("https") === -1 ? 
                              (
                                <div className="app-dashboard-img" style={{backgroundImage : "url(" +
                                process.env.REACT_APP_Image_Path +
                                this.state.data.owner_data.user_id +
                                process.env.REACT_APP_Profile +
                                this.state.data.owner_data.image +
                                ")"}}></div>
                              )
                            :
                              (
                                <div className="app-dashboard-img" style={{backgroundImage :  "url(" +this.state.data.owner_data.image +")"}}></div>
                              )
                            }
                              <div className="online"></div>
                            </div>

                            {this.state.data.management_users !== null &&
                                  this.state.data.management_users.length > 0 &&
                                  this.state.data.management_users.map(
                                    (res) => {
                                      return (
                                        <div className="teammember">
                                          {res.image === "" ||
                                          res.image === null ||
                                          res.image === "null" ||
                                          res.image === undefined ||
                                          res.image === "undefined" ||
                                          !res.image ? (
                                            <div className="app-dashboard-img" style={{backgroundImage : "url("+userdefaultImgOnError+")"}}></div>
                                          ) : res.image.indexOf("https") ===
                                            -1 ? (
                                            <div
                                              className="app-dashboard-img"
                                              style={{
                                                background:
                                                  "url(" +
                                                  process.env
                                                    .REACT_APP_Image_Path +
                                                  res.user_id +
                                                  process.env
                                                    .REACT_APP_Profile +
                                                  res.image +
                                                  ")",
                                              }}
                                              title={`${res.profile_name}`}
                                            ></div>
                                          ) : (
                                            <div
                                              className="app-dashboard-img"
                                              style={{
                                                background:
                                                  "url(" + res.image + ")",
                                              }}
                                              title={`${res.profile_name}`}
                                            ></div>
                                          )}
                                          {res.status === 1 ? (
                                            <div className="online"></div>
                                          ) : (
                                            <div className="offonline"></div>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                        </>
                    
                    </div>

                    </div>  }
                {(localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                      localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                      localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ?
                  <div className="the-app-support">
                    <h3>Support</h3>

                    <div className="app-support-links">

                      <div className="app-support-each-links">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <g id="Group_41131" data-name="Group 41131" transform="translate(-1549 -169)">
                            <rect id="Rectangle_25577" data-name="Rectangle 25577" width="24" height="24" transform="translate(1549 169)" fill="none"/>
                            <g id="Group_35093" data-name="Group 35093">
                              <path id="Path_98228" data-name="Path 98228" d="M10.5,17.783l-.228-.189A12.707,12.707,0,0,0,.5,14.783V.61a12.707,12.707,0,0,1,9.772,2.811l.228.189Z" transform="translate(1551 172)" fill="none" stroke="#1b2952" stroke-linejoin="round" stroke-width="1"/>
                              <path id="Path_98229" data-name="Path 98229" d="M10.5,17.783l.228-.189A12.707,12.707,0,0,1,20.5,14.783V.61a12.707,12.707,0,0,0-9.772,2.811L10.5,3.61Z" transform="translate(1551 172)" fill="none" stroke="#1b2952" stroke-linejoin="round" stroke-width="1"/>
                            </g>
                          </g>
                        </svg>

                        <div>
                          <h5>Knowledge base</h5>
                          <p>Find articles that answer your questions.</p>
                        </div>

                        {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                        <a href="https://appmysite.com/support/" target="_blank"></a>
                      </div>

                      <div className="app-support-each-links">

                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g id="Group_41130" data-name="Group 41130" transform="translate(-1549 -255)">
                          <rect id="Rectangle_25576" data-name="Rectangle 25576" width="24" height="24" transform="translate(1549 255)" fill="none"/>
                          <g id="Group_34944" data-name="Group 34944" transform="translate(8.5 42.5)">
                            <g id="Path_98183" data-name="Path 98183" transform="translate(1542.5 216.5)" fill="none">
                              <path d="M1.644,0H18.356A1.644,1.644,0,0,1,20,1.644V13.357A1.643,1.643,0,0,1,18.357,15H1.644A1.644,1.644,0,0,1,0,13.356V1.644A1.644,1.644,0,0,1,1.644,0Z" stroke="none"/>
                              <path d="M 1.643999099731445 0.9999990463256836 C 1.288900375366211 0.9999990463256836 1 1.288899421691895 1 1.643999099731445 L 1 13.35599899291992 C 1 13.71109867095947 1.288900375366211 13.99999904632568 1.643999099731445 13.99999904632568 L 18.35700035095215 13.99999904632568 C 18.71154975891113 13.99999904632568 19 13.71154880523682 19 13.35699939727783 L 19 1.643999099731445 C 19 1.288899421691895 18.71109962463379 0.9999990463256836 18.35599899291992 0.9999990463256836 L 1.643999099731445 0.9999990463256836 M 1.643999099731445 -9.5367431640625e-07 L 18.35599899291992 -9.5367431640625e-07 C 19.26395988464355 -9.5367431640625e-07 20 0.7360391616821289 20 1.643999099731445 L 20 13.35699939727783 C 20 14.2643985748291 19.26440048217773 14.99999904632568 18.35700035095215 14.99999904632568 L 1.643999099731445 14.99999904632568 C 0.7360401153564453 14.99999904632568 0 14.26395893096924 0 13.35599899291992 L 0 1.643999099731445 C 0 0.7360391616821289 0.7360401153564453 -9.5367431640625e-07 1.643999099731445 -9.5367431640625e-07 Z" stroke="none" fill="#1b2952"/>
                            </g>
                            <path id="Path_98182" data-name="Path 98182" d="M45.23,3.32l8.122,8.093a1.3,1.3,0,0,0,1.81.01L63.421,3.4" transform="translate(1498.174 214.187)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                          </g>
                        </g>
                      </svg>


                        <div>
                          <h5>Email support</h5>
                          <p>Submit a request and get help from experts.</p>
                        </div>

                        {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                        <Link to={"/email-support"} target="_blank"></Link>
                      </div>

                      <div className="app-support-each-links">
                        
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="19.611" viewBox="0 0 20 19.611">
                        <g id="Group_35094" data-name="Group 35094" transform="translate(-1551 -342.389)">
                          <path id="Path_98187" data-name="Path 98187" d="M9,6.611v5H1.936A.937.937,0,0,1,1,10.675V7.547a.937.937,0,0,1,.936-.936Zm1-1H1.936A1.936,1.936,0,0,0,0,7.547v3.128a1.936,1.936,0,0,0,1.936,1.936H10Z" transform="translate(1551 342.389)" fill="#1b2952"/>
                          <path id="Path_98188" data-name="Path 98188" d="M19,1.3V16.927l-9-4.909V6.2ZM19.431,0a.568.568,0,0,0-.272.07L9,5.611v7l10.159,5.542a.568.568,0,0,0,.841-.5V.57A.569.569,0,0,0,19.431,0" transform="translate(1551 342.389)" fill="#1b2952"/>
                          <path id="Path_98189" data-name="Path 98189" d="M5.81,12.611l.75,6H4.576l-.75-6Zm.883-1h-4l.945,7.556a.506.506,0,0,0,.5.444H7.119a.506.506,0,0,0,.5-.569Z" transform="translate(1551.307 342.389)" fill="#1b2952"/>
                          <line id="Line_350" data-name="Line 350" y1="2.673" x2="4.812" transform="translate(1563.094 346.664)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                        </g>
                      </svg>



                        <div>
                          <h5>What’s new</h5>
                          <p>Stay up to date with what’s keeping us busy.</p>
                        </div>

                        {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                        <a href="https://updates.appmysite.com/en/" target="_blank"></a>
                      </div>

                      <div className="app-support-each-links">
                        
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g id="Group_41128" data-name="Group 41128" transform="translate(-1549 -427)">
                        <rect id="Rectangle_25574" data-name="Rectangle 25574" width="24" height="24" transform="translate(1549 427)" fill="none"/>
                        <g id="Group_35095" data-name="Group 35095">
                          <path id="Path_98205" data-name="Path 98205" d="M19.28,3.72A11.056,11.056,0,1,1,16.49,1.7" transform="translate(1549 427)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                          <path id="Path_98206" data-name="Path 98206" d="M16.45,6.55a6.958,6.958,0,1,1-1.77-1.29" transform="translate(1549 427)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                          <path id="Path_98207" data-name="Path 98207" d="M11.5,9.5a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-1a3,3,0,1,0,3,3,3,3,0,0,0-3-3" transform="translate(1549 427)" fill="#1b2952"/>
                          <line id="Line_359" data-name="Line 359" x1="8" y2="8" transform="translate(1560.5 430.5)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                          <path id="Path_98208" data-name="Path 98208" d="M22,4H19V1" transform="translate(1549.5 426.5)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                        </g>
                      </g>
                    </svg>

                        <div>
                          <h5>Product roadmap</h5>
                          <p>A sneak peak into the future of mobile apps.</p>
                        </div>

                        {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                        <a
                              href="https://updates.appmysite.com/roadmap/en/"
                              target="_blank"
                            ></a>
                      </div>

                      <div className="app-support-each-links">
                        
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                        <g id="Group_41127" data-name="Group 41127" transform="translate(-1549 -510)">
                          <rect id="Rectangle_25573" data-name="Rectangle 25573" width="24" height="24" transform="translate(1549 510)" fill="none"/>
                          <g id="Group_35096" data-name="Group 35096">
                            <line id="Line_351" data-name="Line 351" x2="7" transform="translate(1557.5 529.713)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                            <line id="Line_352" data-name="Line 352" y2="2" transform="translate(1561 510)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                            <line id="Line_353" data-name="Line 353" x2="1.414" y2="1.414" transform="translate(1552.293 512.793)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                            <line id="Line_354" data-name="Line 354" x1="1.414" y2="1.414" transform="translate(1568.293 512.793)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                            <line id="Line_355" data-name="Line 355" x2="2" transform="translate(1571 521)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                            <line id="Line_356" data-name="Line 356" x2="2" transform="translate(1549 521)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                            <line id="Line_357" data-name="Line 357" y1="6.801" transform="translate(1561.23 522.912)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                            <path id="Path_98198" data-name="Path 98198" d="M10.668,12l1.538,1.538L13.743,12" transform="translate(1549 510)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                            <path id="Path_98199" data-name="Path 98199" d="M12.151,4.81h0a7.562,7.562,0,0,1,.834.047,7.15,7.15,0,0,1,3.19,13.013,4.065,4.065,0,0,0-1.715,3.385v1.4a.344.344,0,0,1-.344.344H9.8a.344.344,0,0,1-.344-.344V20.974a4.169,4.169,0,0,0-1.625-3.312A7.151,7.151,0,0,1,12.151,4.81m0-1A8.151,8.151,0,0,0,7.231,18.459,3.15,3.15,0,0,1,8.46,20.974v1.682A1.344,1.344,0,0,0,9.8,24h4.312a1.344,1.344,0,0,0,1.344-1.344v-1.4a3.083,3.083,0,0,1,1.279-2.56A8.15,8.15,0,0,0,13.1,3.863a8.7,8.7,0,0,0-.947-.053" transform="translate(1549 510)" fill="#1b2952"/>
                          </g>
                        </g>
                      </svg>


                        <div>
                          <h5>Request a feature</h5>
                          <p>Request improvements and new features.</p>
                        </div>

                        {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                        <a target="_blank" href="https://updates.appmysite.com/requests/en/"></a>
                      </div>

                      <div className="app-support-each-links">
                        
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                      <g id="Group_41126" data-name="Group 41126" transform="translate(-1549 -598)">
                        <rect id="Rectangle_25572" data-name="Rectangle 25572" width="24" height="24" transform="translate(1549 598)" fill="none"/>
                        <g id="Group_35097" data-name="Group 35097">
                          <g id="Path_98190" data-name="Path 98190" transform="translate(1549.736 598.5)" fill="none" stroke-miterlimit="10">
                            <path d="M15.264,4a3.085,3.085,0,0,1-.056.63,3.5,3.5,0,0,1-6.887,0A3.085,3.085,0,0,1,8.264,4a3.5,3.5,0,0,1,7,0Z" stroke="none"/>
                            <path d="M 11.76453971862793 1.499999523162842 C 10.38574028015137 1.499999523162842 9.263999938964844 2.621249675750732 9.263999938964844 3.999459505081177 C 9.263999938964844 4.227429389953613 9.285189628601074 4.359789848327637 9.297809600830078 4.418399810791016 L 9.304490089416504 4.45220947265625 C 9.517560005187988 5.63815975189209 10.55216026306152 6.498919486999512 11.76453971862793 6.498919486999512 C 12.97630023956299 6.498919486999512 14.0104398727417 5.63815975189209 14.22350978851318 4.45220947265625 L 14.23019027709961 4.418399810791016 C 14.24281024932861 4.359789848327637 14.26399993896484 4.227429389953613 14.26399993896484 3.999459505081177 C 14.26399993896484 2.621249675750732 13.14274978637695 1.499999523162842 11.76453971862793 1.499999523162842 M 11.76453971862793 0.4999995231628418 C 13.6965503692627 0.4999995231628418 15.26399993896484 2.067459583282471 15.26399993896484 3.999459505081177 C 15.26399993896484 4.209319591522217 15.24993991851807 4.433239459991455 15.20775032043457 4.629039764404297 C 14.9135103225708 6.266809463500977 13.48559951782227 7.498919486999512 11.76453971862793 7.498919486999512 C 10.04240036010742 7.498919486999512 8.614489555358887 6.266809463500977 8.320249557495117 4.629039764404297 C 8.278059959411621 4.433239459991455 8.263999938964844 4.209319591522217 8.263999938964844 3.999459505081177 C 8.263999938964844 2.067459583282471 9.832539558410645 0.4999995231628418 11.76453971862793 0.4999995231628418 Z" stroke="none" fill="#1b2952"/>
                          </g>
                          <g id="Path_98196" data-name="Path 98196" transform="translate(1541.736 612.5)" fill="none" stroke-miterlimit="10">
                            <path d="M15.264,4a3.085,3.085,0,0,1-.056.63,3.5,3.5,0,0,1-6.887,0A3.085,3.085,0,0,1,8.264,4a3.5,3.5,0,0,1,7,0Z" stroke="none"/>
                            <path d="M 11.76453971862793 1.499999523162842 C 10.38574028015137 1.499999523162842 9.263999938964844 2.621249675750732 9.263999938964844 3.999459505081177 C 9.263999938964844 4.227429389953613 9.285189628601074 4.359789848327637 9.297809600830078 4.418399810791016 L 9.304490089416504 4.45220947265625 C 9.517560005187988 5.63815975189209 10.55216026306152 6.498919486999512 11.76453971862793 6.498919486999512 C 12.97630023956299 6.498919486999512 14.0104398727417 5.63815975189209 14.22350978851318 4.45220947265625 L 14.23019027709961 4.418399810791016 C 14.24281024932861 4.359789848327637 14.26399993896484 4.227429389953613 14.26399993896484 3.999459505081177 C 14.26399993896484 2.621249675750732 13.14274978637695 1.499999523162842 11.76453971862793 1.499999523162842 M 11.76453971862793 0.4999995231628418 C 13.6965503692627 0.4999995231628418 15.26399993896484 2.067459583282471 15.26399993896484 3.999459505081177 C 15.26399993896484 4.209319591522217 15.24993991851807 4.433239459991455 15.20775032043457 4.629039764404297 C 14.9135103225708 6.266809463500977 13.48559951782227 7.498919486999512 11.76453971862793 7.498919486999512 C 10.04240036010742 7.498919486999512 8.614489555358887 6.266809463500977 8.320249557495117 4.629039764404297 C 8.278059959411621 4.433239459991455 8.263999938964844 4.209319591522217 8.263999938964844 3.999459505081177 C 8.263999938964844 2.067459583282471 9.832539558410645 0.4999995231628418 11.76453971862793 0.4999995231628418 Z" stroke="none" fill="#1b2952"/>
                          </g>
                          <g id="Path_98197" data-name="Path 98197" transform="translate(1557.736 612.5)" fill="none" stroke-miterlimit="10">
                            <path d="M15.264,4a3.085,3.085,0,0,1-.056.63,3.5,3.5,0,0,1-6.887,0A3.085,3.085,0,0,1,8.264,4a3.5,3.5,0,0,1,7,0Z" stroke="none"/>
                            <path d="M 11.76453971862793 1.499999523162842 C 10.38574028015137 1.499999523162842 9.263999938964844 2.621249675750732 9.263999938964844 3.999459505081177 C 9.263999938964844 4.227429389953613 9.285189628601074 4.359789848327637 9.297809600830078 4.418399810791016 L 9.304490089416504 4.45220947265625 C 9.517560005187988 5.63815975189209 10.55216026306152 6.498919486999512 11.76453971862793 6.498919486999512 C 12.97630023956299 6.498919486999512 14.0104398727417 5.63815975189209 14.22350978851318 4.45220947265625 L 14.23019027709961 4.418399810791016 C 14.24281024932861 4.359789848327637 14.26399993896484 4.227429389953613 14.26399993896484 3.999459505081177 C 14.26399993896484 2.621249675750732 13.14274978637695 1.499999523162842 11.76453971862793 1.499999523162842 M 11.76453971862793 0.4999995231628418 C 13.6965503692627 0.4999995231628418 15.26399993896484 2.067459583282471 15.26399993896484 3.999459505081177 C 15.26399993896484 4.209319591522217 15.24993991851807 4.433239459991455 15.20775032043457 4.629039764404297 C 14.9135103225708 6.266809463500977 13.48559951782227 7.498919486999512 11.76453971862793 7.498919486999512 C 10.04240036010742 7.498919486999512 8.614489555358887 6.266809463500977 8.320249557495117 4.629039764404297 C 8.278059959411621 4.433239459991455 8.263999938964844 4.209319591522217 8.263999938964844 3.999459505081177 C 8.263999938964844 2.067459583282471 9.832539558410645 0.4999995231628418 11.76453971862793 0.4999995231628418 Z" stroke="none" fill="#1b2952"/>
                          </g>
                          <path id="Path_98193" data-name="Path 98193" d="M14.269,21.42a8.982,8.982,0,0,1-2.769.433c-2.006,0-4.623-.887-5.812-2.373" transform="translate(1550 599)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                          <path id="Path_98194" data-name="Path 98194" d="M17.234,5.78a9.025,9.025,0,0,1,3.325,7.014A8.72,8.72,0,0,1,20.3,14.9" transform="translate(1550 599)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                          <path id="Path_98195" data-name="Path 98195" d="M2.469,12.076A9.03,9.03,0,0,1,8.316,4.318" transform="translate(1550 599)" fill="none" stroke="#1b2952" stroke-miterlimit="10" stroke-width="1"/>
                        </g>
                      </g>
                    </svg>


                        <div>
                          <h5>Community</h5>
                          <p>Engage with other app builders like yourself.</p>
                        </div>

                        {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                        <a href="https://community.appmysite.com/"
                              target="_blank"
                            ></a>
                      </div>

                    </div>
                  </div>
                : <></>
                }

              </div>

              <div className="app-dashboard-right">
              { (this.state.appuser_role_id === 1 || this.state.appuser_role_id === 2) && localStorage.getItem("workspaceId") && 
              <div className="app-plan-detail">
                <div className="app-plan-alignment">
                  <div className="app-plan-detail-one">
                    <h3 style={{textTransform:"capitalize"}}>Subscription</h3>
                      <p style={{width: "100%"}}>This app is managed by your workspace.</p>
                      <h6>Plan: <span style={{textTransform:"capitalize"}}>{this.state.data.subscription?.plan_name ?? ''}</span></h6>
                      {/* {this.state.data.subscription && this.state.data.subscription?.gateway_subscription_id ? 
                        <h6>ID: {this.state.data.subscription.gateway_subscription_id}</h6> : ''} */}
                  </div>
                  <div className="app-plan-detail-two">
                    <img src={require("../assets/images/dashboard/plan-pro.png").default} className="img-fluid" alt="plan-pro"/>
                  </div>
                </div>
              </div>}

              {
                  ((localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1) && (
                    localStorage.getItem("workspaceId") === "undefined" || localStorage.getItem("workspaceId") === undefined ||
                      localStorage.getItem("workspaceId") === "" || localStorage.getItem("workspaceId") === null
                  ) ) && this.state.appuser_role_id !==3 && this.state.appuser_role_id !==4 &&
                <div className="app-plan-detail">

                  <div className="app-plan-alignment">
                  <div className="app-plan-detail-one">
                   
                    <h3 style={{textTransform:"capitalize"}}>
                      { this.state.data.subscription && this.state.data.subscription != '' 
                      ? this.state.data.subscription.plan_name == 'Free' || this.state.data.subscription.plan_name == 'free' || this.state.data.subscription.plan_name == 'preview' || this.state.data.subscription.plan_name == 'Preview' 
                      ? 'Free'
                      : this.state.data.subscription.plan 
                      : ''
                      }</h3>
                    { this.state.data.subscription.plan_name == 'Free' || this.state.data.subscription.plan_name == 'free' || this.state.data.subscription.plan == 'preview' || this.state.data.subscription.plan_name == 'preview' || this.state.data.subscription.plan_name == 'Preview' ?  
                      <p>You’re on the free plan. Please upgrade to access advanced features.</p>
                      : ( this.state.data.subscription.downgrade_at_term_end == 1 || this.state.data.subscription.reseller_package_id )&&  this.state.data.subscription.package_duration_id != 4 ?
                      <p>Your existing plan is scheduled for automated cancellation on  {moment( this.state.data.subscription.reseller_package_id ?this.state.data.subscription.reseller_package_expiry_date  : this.state.data.subscription.billing_date ).format("D MMMM YYYY")}.</p>
                      : this.state.data.subscription.package_duration_id != 4 ?
                      <p>Your existing plan is scheduled for automated renewal on {moment( this.state.data.renewal_date ).format("D MMMM YYYY")}.</p>
                      : this.state.data.subscription.package_duration_id == 4 ?
                      <p>You’re on the Lifetime plan. Enjoy updates and support without any recurring charges.</p>
                      : <></>
                    }
                    <h6>Plan: <span style={{textTransform:"capitalize"}}>{this.state.data.subscription && this.state.data.subscription != '' ? this.state.data.subscription.plan_name : ''}</span></h6>
                    {this.state.data.subscription && this.state.data.subscription != '' && this.state.data.subscription.gateway_subscription_id != '' ?
                    <h6>ID: {this.state.data.subscription.gateway_subscription_id}</h6>
                    : ''}
                  </div>


                  <div className="app-plan-detail-two">
                  { this.state.data.subscription.plan == 'preview' ?
                    <img src={require("../assets/images/dashboard/plan-preview.png").default} className="img-fluid" alt="plan-preview"/>
                  : this.state.data.subscription.plan == 'starter' ?
                    <img src={require("../assets/images/dashboard/plan-starter.png").default} className="img-fluid" alt="plan-starter"/>
                  : this.state.data.subscription.plan == 'pro' ?
                    <img src={require("../assets/images/dashboard/plan-pro.png").default} className="img-fluid" alt="plan-pro"/>
                  :
                    <img src={require("../assets/images/dashboard/plan-premium.png").default} className="img-fluid" alt="plan-premium"/>
                  }
                  </div>

                </div>

                <div className="app-status-upgrade">
                <p style={{textTransform:"capitalize"}}>{this.state.data.subscription && this.state.data.subscription != '' ? this.state.data.subscription.status : ''}</p>
                  {(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) ?  
                    null
                  :
                    <>
                    { localStorage.getItem("workspaceId") === "undefined" || localStorage.getItem("workspaceId") === undefined ||
                      localStorage.getItem("workspaceId") === "" || localStorage.getItem("workspaceId") === null ? 
                      (
                        <>
                        <a 
                        style={{
                          color:this.state.data.subscription.downgrade_at_term_end === 1?"#BCC1CE":"",
                          cursor:this.state.data.subscription.downgrade_at_term_end === 1?"pointer":"",
                          fontSize:this.state.data.subscription.downgrade_at_term_end === 1?"13px":"",
                      }}
                        href={ parseInt(localStorage.getItem('website_technology')) === 4 ? "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
                        : parseInt(localStorage.getItem('website_technology')) === 5 ? "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
                        : parseInt(localStorage.getItem('website_technology')) === 3 ? "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
                        : parseInt(localStorage.getItem('website_technology')) === 2 ? "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
                        : "/app/pricing"}>
                          {this.state.data.subscription.downgrade_at_term_end === 1?"Reactivate":"Upgrade"}
                          {/* Upgrade */}
                          </a>
                        </>
                      )
                    : <></>
                    }
                    </>
                  }
                  </div>

                  {this.state.data.subscription && this.state.data.subscription != '' && this.state.data.subscription.package_duration_id == 1 ?
                    <div className="dash-app-upgrade">
                    {parseInt(this.state.data.website_technology) == 5 ?
                      <p>Save up to  34% <span>on annual plan.</span></p>
                    :
                      <p>Save up to  53% <span>on annual plan.</span></p>
                    }
                      
                    </div>
                  : <></>
                  }

                </div> }

                <div className="app-total-steps">

                <div className="dashboard-progress">
                        {this.handlepointcheck() < this.state.checklisttotal ?
                          <h2>Let’s set up your app</h2>
                        :
                          <h2>You’re all set</h2>
                        }
                      <div className="dashboard-progress-bar">
                          <div>
                              <p>{this.handlepointcheck()}/{this.state.checklisttotal}</p>
                          </div>
                          <Progress value={this.handlepointcheck()*(100/this.state.checklisttotal)}  />
                      </div>    
                  </div>

                  <div className="app-seven-steps">

                  <div className="app-steps">

                      <a href="/app/app-icon"></a>
                      {this.state.data.app_icon_screen_count > 0 ?
                      <>                     
                        <>                     
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                  <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                  <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                              </g>
                          </svg>

                      </div>
                      </>
                      </>
                      :
                      <>
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                      <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                        </svg>

                      </div>
                      </>
                      }
                                
                      <div>
                          <h4>App icon</h4>
                          <p>Create a personalised app icon image.</p>
                      </div>
                  </div>
                  <div className="app-steps">

                      <a href="/app/splash"></a>
                      {this.state.data.splash_screen_count > 0 ?
                      <>                     
                        <>                     
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                  <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                  <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                              </g>
                          </svg>

                      </div>
                      </>
                      </>
                      :
                      <>
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                      <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                        </svg>

                      </div>
                      </>
                      }
                                
                      <div>
                          <h4>Splash screen</h4>
                          <p>Upload an impactful splash screen image.</p>
                      </div>
                  </div>

                      {parseInt(this.state.data.website_technology) == 4 || parseInt(this.state.data.website_technology) == 5  ?
                           <div className="app-steps">

                           <a href="/app/connect/wp-plugin"></a>
                           {this.state.data.plugin_screen_count > 0 ?
                           <>                     
                             <>                     
                           {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                           <div>
                               <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                   <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                       <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                       <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                   </g>
                               </svg>
     
                           </div>
                           </>
                           </>
                           :
                           <>
                           {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                           <div>
                           <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                        </svg>
     
                           </div>
                           </>
                           }
                                     
                           <div>
                               <h4>Install plugin</h4>
                               <p>Upload and activate our WordPress plugin.</p>
                           </div>
                           </div>
                      : parseInt(this.state.data.website_technology) == 2 ?
                        <div className="app-steps">

                        <a href="/app/web-view-settings"></a>
                        {this.state.data.web_view_settings_screen_count > 0 ?
                        <>                     
                          <>                     
                        {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                    <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                    <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                </g>
                            </svg>

                        </div>
                        </>
                        </>
                        :
                        <>
                        {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                        <div>
                        <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                        </svg>

                        </div>
                        </>
                        }
                                  
                        <div>
                            <h4>Web view</h4>
                            <p>Manage the settings of your in-app browser.</p>
                        </div>
                        </div>
                      : parseInt(this.state.data.website_technology) == 3 ?
                      <>
                      <div className="app-steps">

                      <a href="/app/pages"></a>
                      {this.state.data.page_count > 0 ?
                      <>                     
                        <>                     
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                  <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                  <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                              </g>
                          </svg>

                      </div>
                      </>
                      </>
                      :
                      <>
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                      <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                        </svg>

                      </div>
                      </>
                      }
                                
                      <div>
                          <h4>Pages</h4>
                          <p>Create and customize your app’s first page.</p>
                      </div>
                      </div>
                      <div className="app-steps">

                      <a href="/app/posts"></a>
                      {this.state.data.post_count > 0 ?
                      <>                     
                        <>                     
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                  <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                  <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                              </g>
                          </svg>

                      </div>
                      </>
                      </>
                      :
                      <>
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                      <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                        </svg>

                      </div>
                      </>
                      }
                                
                      <div>
                          <h4>Post</h4>
                          <p>Create and customize your app’s first post.</p>
                      </div>
                      </div>
                      </>
                    : <></>
                      }
                       {parseInt(this.state.data.website_technology) == 5 ?
                      <div className="app-steps">

                      <a href="/app/connect/wp-grant-access"></a>
                      { ( this.state.data?.cs_ck_screen_count > 0 && this.state.data?.connectivity_toggle_status > 0 )  ?
                      <>                     
                        <>                     
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                  <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                  <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                              </g>
                          </svg>

                      </div>
                      </>
                      </>
                      :
                      <>
                      {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                      <div>
                      <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                        </svg>

                      </div>
                      </>
                      }
                                
                      <div>
                          <h4>Grant access</h4>
                          <p>Provide access to your WordPress website.</p>
                      </div>
                      </div>
                      : <></>
                      }
                      <div className="app-steps">

                          <a href="/app/bottom-bar"></a>
                          {this.state.data.bottom_menu > 0 ?
                          <>                     
                            <>                     
                          {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                          <div>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                  <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                      <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                      <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                  </g>
                              </svg>

                          </div>
                          </>
                          </>
                          :
                          <>
                          {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                          <div>
                          <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                              <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                              <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                              <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                            </svg>

                          </div>
                          </>
                          }
                                    
                          <div>
                              <h4>Bottom bar</h4>
                              <p>Add atleast one item to your Bottom bar.</p>
                          </div>
                          </div>

                      <div className="app-steps">

                        <a href="/app/advanced-notifications"></a>
                        {this.state.data.notification_screen_count > 0 ?
                        <>                     
                          <>                     
                        {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                    <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                    <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                </g>
                            </svg>

                        </div>
                        </>
                        </>
                        :
                        <>
                        {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                        <div>
                        <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                          <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                          <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                          <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                        </svg>

                        </div>
                        </>
                        }
                                  
                        <div>
                            <h4>Notification</h4>
                            <p>Send your app’s first push notification.</p>
                        </div>
                        </div>
                        <div className="app-steps">
                          <a href="/app/download"></a>
                          {this.state.data.is_build_created > 0 ?
                          <>                     
                            <>                     
                          {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                          <div>
                              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                  <g id="Group_37699" data-name="Group 37699" transform="translate(-1510 -164)">
                                      <circle id="Ellipse_7739" data-name="Ellipse 7739" cx="10" cy="10" r="10" transform="translate(1510 164)" fill="#52cb86"/>
                                      <path id="Path_101776" data-name="Path 101776" d="M-16065.254,2565.891l2.584,2.584,5.416-5.416" transform="translate(17581.254 -2392.059)" fill="none" stroke="#fff" stroke-width="2"/>
                                  </g>
                              </svg>

                          </div>
                          </>
                          </>
                          :
                          <>
                          {/* <img src={require("../assets/images/dashboard/arrow-grey.png") .default} alt="arrow"/> */}
                          <div>
                          <svg id="Group_42776" data-name="Group 42776" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                            <path id="Path_82474" data-name="Path 82474" d="M10,0A10,10,0,1,1,0,10,10,10,0,0,1,10,0Z" fill="#ffb300"/>
                            <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v6" transform="translate(-10981 4363.209)" fill="none" stroke="#fff" stroke-width="2"/>
                            <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v2" transform="translate(-10981 4360.192)" fill="none" stroke="#fff" stroke-width="2"/>
                          </svg>

                          </div>
                          </>
                          }
                                    
                          <div>
                              <h4>Download</h4>
                              <p>Configure and download your app build.</p>
                          </div>
                          </div>

                      <div className="app-steps-animation"></div>

                      </div>
                  </div>
                  {
                  this.state.appuser_role_id !== 4
                 &&
                  <div className="app-team">

                      <h3>Team</h3>

                      <div className="app-teamlist">
                     
                          <>
                              <div className="teammember">
                              {this.state.data.owner_data.image === "" ||
                                    this.state.data.owner_data.image === null ||
                                    this.state.data.owner_data.image === "null" ||
                                    this.state.data.owner_data.image === undefined ||
                                    this.state.data.owner_data.image === "undefined" ||
                                    !this.state.data.owner_data.image ? 
                                (
                                  <div className="app-dashboard-img" style={{backgroundImage : "url("+userdefaultImgOnError+")"}}></div>
                                )
                              : this.state.data.owner_data.image.indexOf("https") === -1 ? 
                                (
                                  <div className="app-dashboard-img" style={{backgroundImage : "url(" +
                                  process.env.REACT_APP_Image_Path +
                                  this.state.data.owner_data.user_id +
                                  process.env.REACT_APP_Profile +
                                  this.state.data.owner_data.image +
                                  ")"}}></div>
                                )
                              :
                                (
                                  <div className="app-dashboard-img" style={{backgroundImage :  "url(" +this.state.data.owner_data.image +")"}}></div>
                                )
                              }
                                <div className="online"></div>
                              </div>

                              {this.state.data.management_users !== null &&
                                    this.state.data.management_users.length > 0 &&
                                    this.state.data.management_users.map(
                                      (res) => {
                                        return (
                                          <div className="teammember">
                                            {res.image === "" ||
                                            res.image === null ||
                                            res.image === "null" ||
                                            res.image === undefined ||
                                            res.image === "undefined" ||
                                            !res.image ? (
                                              <div className="app-dashboard-img" style={{backgroundImage : "url("+userdefaultImgOnError+")"}}></div>
                                            ) : res.image.indexOf("https") ===
                                              -1 ? (
                                              <div
                                                className="app-dashboard-img"
                                                style={{
                                                  background:
                                                    "url(" +
                                                    process.env
                                                      .REACT_APP_Image_Path +
                                                    res.user_id +
                                                    process.env
                                                      .REACT_APP_Profile +
                                                    res.image +
                                                    ")",
                                                }}
                                                title={`${res.profile_name}`}
                                              ></div>
                                            ) : (
                                              <div
                                                className="app-dashboard-img"
                                                style={{
                                                  background:
                                                    "url(" + res.image + ")",
                                                }}
                                                title={`${res.profile_name}`}
                                              ></div>
                                            )}
                                            {res.status === 1 ? (
                                              <div className="online"></div>
                                            ) : (
                                              <div className="offonline"></div>
                                            )}
                                          </div>
                                        );
                                      }
                                    )}
                          </>
                     
                      </div>

                  </div>
                  }

              </div>

            </div>
            </>
            )}

         <Modal 
         isOpen={this.state.openUpgradePopUp}
         className="protip-popup-message upgrade-protio-popup" 
        //  toggle={(e)=> this.handleOpenUpgradePopUp(e)} 
         centered
         >
          <ModalBody>

             <div className="protip-popup"> 

                <div className="protip-popup-one">           
                  <h4>Upgrade your app</h4>
                  <h6>Unlock advanced features and enhance your app’s user experience.</h6>
                  <div className="upgrade-popuptext-div">
                  <ul className="upgrade-popuptext-first-ul">
                    <li>Ad-free browsing</li>  
                    <li>iOS app builds</li>
                    <li>Accelerated app builds</li>
                    <li>Merge multiple apps</li>
                    <li>Language selection</li>
                    <li>App usage analytics</li>      
                  </ul>
                  <ul>
                    <li>No AppMySite branding</li>  
                    <li>Unlimited app builds</li>
                    <li>Targeted advanced notifications</li>
                    <li>In-app purchases</li>
                    <li>Customer chat</li>
                    <li>One click publish</li>
                   
                  </ul>
                  </div>
                  {
                  (
                     (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                 localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                 localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) &&
                                 this.state.appuser_role_id !==4 && this.state.appuser_role_id !==3 ) &&
                  <Link to={parseInt(localStorage.getItem('website_technology')) === 4 ? "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
                        : parseInt(localStorage.getItem('website_technology')) === 5 ? "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
                        : parseInt(localStorage.getItem('website_technology')) === 3 ? "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
                        : parseInt(localStorage.getItem('website_technology')) === 2 ? "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
                        : "/app/pricing"}>Upgrade</Link>
                  }
                </div>  

                <div className="protip-popup-two">
                <img src={require("../assets/images/dashboard/freeupgrade.png").default} className="img-fluid" alt="icon-preview"/>
                </div>

              </div>

            <div className="crossmodal mt-1">
                <img onClick={(e) => this.handleOpenUpgradePopUp(e)} src={ require("../assets/images/dashboard/cross.png").default }/>
            </div>

          </ModalBody>
      </Modal>
          
          </div>
        </section>
      </div>
      </>
    );
  }
}
