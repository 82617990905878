import React from "react";
import styles from "./chart.module.css";
import ReactHtmlParser from "react-html-parser";
class Datatable extends React.Component {

  returnImagePathForSelectedTech = (tech) => {
    let finalPath="";
    switch(tech){
    case "wordpress":
      finalPath="wp-analytics-icon.png";
      break;
    case "woocommerce":
      finalPath="woo-analytics-icon.png";
      break;
    case "custom-app":
      finalPath="cms-analytics-icon.png";
       break;
    case "non-wp":
      finalPath="w2App-analytics-icon.png";
      break;
    default :
    finalPath="woo-analytics-icon.png";
    
    }
  
    return finalPath;
    }
  render() {

  

    return (
      <>
        {this.props.value.state.reportdata.map((res, i) => (
         ( res.item_type &&
          res.item_type !== "app_user_count" &&
          res.item_type !== "android_vs_ios_users_count" &&
          res.item_type !== "app_api_requests_histogram" ) ?
          <React.Fragment key={i}>
            {
            res.value &&
            res.value.length > 0 ? (
                <>
                  {
                    typeof res.value[0].key.toString() ===
                    "string" ? (
                      <div
                        className={
                          styles.data_tables +
                          "  chart-data-tables-redesigns " +
                          styles.analytics_table_start
                        }
                      >
                        <div>
                          <div className={styles.analytics_table}>
                            <div
                              className={
                                "d-flex align-items-center justify-content-between " +
                                styles.analytics_table_head
                              }
                            >
                              <h5>
                                
                                {res.item_sub_type ? (
                                    <img
                                    style={{
                                      width:"24px",
                                      height:"24px",
                                    }}
                                      src={
                                        require(`../../assets/images/dashboard/${this.returnImagePathForSelectedTech(res.item_sub_type)}`)
                                          .default
                                      }
                                      alt="product-img1"
                                    />
                                ) : (
                                  "Blank"
                                )}
                                <h2>
                                  {ReactHtmlParser(
                                    res.label.replaceAll("_", " ")
                                  )}
                                </h2>
                              </h5>

                              <h5>Count</h5>
                            </div>
                            <div className={styles.analytics_scroll_table}>
                              {res.value?.map(
                                (response, j) =>
                                  typeof response.key.toString() ===
                                    "string" && (
                                    <>
                                      <div
                                        key={j}
                                        className={styles.analytics_table_data}
                                      >
                                        <div className="d-flex align-items-center justify-content-between ">
                                          <div
                                            className={
                                              styles.analytics_image +
                                              " d-flex align-items-center justify-content-start"
                                            }
                                          >{
                                            ( parseInt(localStorage.getItem("website_technology")) === 3 || parseInt(localStorage.getItem("website_technology")) === 2 )?
                                            <h5>
                                            {res.label === "Popular Requests"
                                              ? ReactHtmlParser(
                                                    response.key
                                                      .toString()
                                                      .replaceAll(
                                                        "ams_custom_app_get_",
                                                        ""
                                                      )
                                                      .replaceAll("_", " ")
                                                  )
                                                
                                              : response.key
                                                  .toString()
                                                  .replaceAll("-", " ") !== ""
                                              ? ReactHtmlParser(
                                                  response.key
                                                    .toString()
                                                    .replaceAll("-", " ")
                                                )
                                              : ReactHtmlParser(
                                                  res.label.replaceAll(
                                                    "_",
                                                    " "
                                                  )
                                                ) === "Post Categories" ||
                                                ReactHtmlParser(
                                                  res.label.replaceAll(
                                                    "_",
                                                    " "
                                                  )
                                                ) === "Product Categories"
                                              ? "Uncategorized"
                                              : "Blank"}
                                          </h5>
                                          :
                                            <h5>
                                              {res.label === "Popular Requests"
                                                ? response.key
                                                    .toString()
                                                    .includes("ams_wp_app_get_")
                                                  ? ReactHtmlParser(
                                                      response.key
                                                        .toString()
                                                        .replaceAll(
                                                          "ams_wp_app_get_",
                                                          ""
                                                        )
                                                        .replaceAll("_", " ")
                                                    )
                                                  : ReactHtmlParser(
                                                      response.key
                                                        .toString()
                                                        .replaceAll(
                                                          "ams_wp_app_post_",
                                                          ""
                                                        )
                                                        .replaceAll("_", " ")
                                                    )
                                                : response.key
                                                    .toString()
                                                    .replaceAll("-", " ") !== ""
                                                ? ReactHtmlParser(
                                                    response.key
                                                      .toString()
                                                      .replaceAll("-", " ")
                                                  )
                                                : ReactHtmlParser(
                                                    res.label.replaceAll(
                                                      "_",
                                                      " "
                                                    )
                                                  ) === "Post Categories" ||
                                                  ReactHtmlParser(
                                                    res.label.replaceAll(
                                                      "_",
                                                      " "
                                                    )
                                                  ) === "Product Categories"
                                                ? "Uncategorized"
                                                : "Blank"}
                                            </h5>
                                          }
                                          </div>
                                          <div className={styles.total_views}>
                                            <h5>{(response.doc_count).toLocaleString("en-US")}</h5>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                    </>

              )
            : 
              <>
                {
                    <div
                      className={
                        styles.data_tables +
                        " chart-data-tables-redesign " +
                        styles.analytics_table_start
                      }
                    >
                      <div className="d-flex align-items-center analytics-wp-woo">
                        
 
                      <img  style={{
                                      width:"24px",
                                      height:"24px",
                                    }}
                       src={
                                        require(`../../assets/images/dashboard/${this.returnImagePathForSelectedTech(res.item_sub_type)}`)
                                          .default
                                      }
                                      alt="product-img3"
                                    />
                        <h2>
                          {ReactHtmlParser(res.label.replaceAll("_", " "))}
                        </h2>
                      </div>
                      <div>
                        <div className={styles.analytics_table}>
                          <div
                            className={
                              "d-flex align-items-center justify-content-between " +
                              styles.analytics_table_head
                            }
                          >
                          </div>
                          <div className={styles.analytics_scroll_table}>
                            <div>
                              <div className={styles.shownodata}>
                                <h5>No Data found</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                }
              </>
            }
          </React.Fragment>
          :
          <></>
        ))}
      </>
    );
  }
}

export { Datatable as default };
