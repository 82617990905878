import React, { useEffect, useReducer, useState } from "react";
import "../../../assets/css/Dashboard.css";
import "../../../assets/css/profileleftpanel.css";
import "../../../assets/css/appicon.css";
import { Link } from "react-router-dom";
import Header from "../../../Dashboard/Header";
import Leftpanel from "../../../Dashboard/Leftpanel";
import {
  useAppIconDataQuery,
  useSaveAppIconDataMutation,
} from "../../../services/appIconApiSlice";
import {
  closeVistaPopUp,
  getBase64FromUrl,
  MetaTitle,
} from "../../../utils/commonUtils";
import { errortoast, sucesstoast } from "../../../Dashboard/Toaster";
import { CustomS3Client } from "../../../utils/S3utils";
import {
  useAppDashboardDataQuery,
  useExtractColorDataMutation,
} from "../../../services/appDashboardApiSlice";
import ModuleTitleTooltip from "../../../Components/commonIcons/ModuleTitleTooltip";
import ProTipModal from "../../../Components/commonComponents/AppIcon/ProtipModal";
import protipImg from "../../../assets/images/dashboard/protip-img.png";
import ProTipIcon from "../../../Components/commonComponents/AppIcon/ProTipIcon";
import ScreenDetectionModal from "../../../Components/commonComponents/AppIcon/ScreenDetectionModal";
import NewImageGalleryPopup from "../../../Dashboard/NewImageGalleryPopup";
import ImageDownloadModal from "../../../Components/commonComponents/AppIcon/ImageDownloadModal";
import RebuildRequiredModal from "../../../Components/commonComponents/AppIcon/RebuildRequiredModal";
import ModuleNavigations from "../../../Components/commonComponents/ModuleNavigation/ModuleNavigations";
import AmsSpinner from "../../../Components/commonIcons/AmsSpinner";
import AppIconPreview from "./AppIconPreview";
import UpdatedAt from "../../../Components/commonComponents/UpdatedAt";
import SaveButton from "../../../Components/commonComponents/SaveButton";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import VistaCreatePopUp from "../../../Components/commonComponents/VistaCreatePopUp";

const AppIcon = () => {
  const { refetch: appDashboardDataRefetch } = useAppDashboardDataQuery();
  const [appIconState, setAppIconState] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      openVistaPopup: false,
      openProTipPopUp: false,
      openImgDownloadPopUp: false,
      appIcon: "",
      vistaDesignId: "",
      buildStatus: null,
      hideGrideBtn: true,
      btnLoader: false,
      spinner: true,
      originalIcon: "",
      isAppIconDefault: 1,
      updatedAt: "",
      openSmallDetectionPopUp: false,
      openRebuildPopUp: false,
      imgUrlToCrop: "",
      linkArr: [
        {
          link: "/app/app-icon",
          name: "App icon",
          is_active: true,
          is_show: true,
        },
        {
          link: "/app/splash",
          name: "Splash",
          is_active: false,
          is_show: true,
        },
        {
          link: "/app/theme",
          name: "Theme",
          is_active: false,
          is_show: true,
        },
      ],
      proTipsArr: [
        {
          text: "Choose a design that’s easy to recognize and stands out on your user’s mobile device.",
        },
        {
          text: "Think of your app icon as an icon with a background, not a picture.",
        },
        {
          text: "Text within your icon can be too small to read.",
        },
        {
          text: "Avoid including your app’s name in your icon since your app name is displayed below.",
        },
        {
          text: "Create a design that depicts your brand’s purpose or function.",
        },
        {
          text: "Avoid getting too close to the edges of the icon.",
        },
        {
          text: "Brands evolve, and icons evolve with them. Make gradual changes so that users continue to relate to your app.",
        },
      ],
      appRightPanelExpand:
        localStorage.getItem("appSideBarCollapse") === true ||
        localStorage.getItem("appSideBarCollapse") === "true"
          ? true
          : false,
    }
  );
  const [openGalleryPopup, setOpenGalleryPopup] = useState(false);
  const { data: appIconApiData, isError, isLoading } = useAppIconDataQuery();
  const [saveAppIconData, { appIconSavedApiData }] =
    useSaveAppIconDataMutation();
  const [extractedColor, { extractColorData }] = useExtractColorDataMutation();

  const history = useHistory();

  const extractColor = () => {
    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      force_update: 0,
    };
    extractedColor(params)
      .unwrap()
      .then((res) => {})
      .catch((err) => {
        console.error(err, "err");
      });
  };

  const updatingAppIconStateOnError = () => {
    const { data } = appIconApiData;
    const {
      upload_icon,
      app_icon,
      app_frontend_display_icon,
      app_original_icon,
      original_icon,
      design_id,
      last_updated_timestamp,
    } = data;

    const appIcon = upload_icon || app_icon || app_frontend_display_icon || "";
    const originalIcon =
      app_original_icon ||
      original_icon ||
      (app_frontend_display_icon && original_icon) ||
      upload_icon;
    // setImgScalingStatus("In Progress");
    setAppIconState({
      isAppIconDefault: appIconApiData.is_default,
      appIcon,
      originalIcon,
      vistaDesignId: design_id,
      spinner: false,
      buildStatus: appIconApiData.build_status,
      updatedAt: last_updated_timestamp,
    });
  };

  const settingGetApiData = async () => {
    if (appIconApiData) {
      if (appIconApiData.code === 200) {
        if (!appIconApiData.data.upload_icon && !appIconApiData.data.app_icon) {
          var urlToFile =
            process.env.REACT_APP_Image_Path +
            localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            "/app_icon/iOS/" +
            appIconApiData.cropped_app_icon;
          var uploadPath =
            localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            process.env.REACT_APP_AppIcon_Crop;

          const filename =
            "ams_" +
            new Date().getTime() +
            "_" +
            Math.floor(1000 + Math.random() * 9000);

          try {
            //send to s3
            let base64Image = await getBase64FromUrl(urlToFile);

            let arr = base64Image.split(","),
              mime = "image/png",
              bstr = atob(arr[1]),
              n = bstr.length,
              u8arr = new Uint8Array(n);

            while (n--) {
              u8arr[n] = bstr.charCodeAt(n);
            }
            var imagefiledatavalue = new Blob([u8arr], { type: mime });

            const config = {
              bucketName: process.env.REACT_APP_bucketName,
              dirName: uploadPath.replace(/\/$/, ""),
              region: process.env.REACT_APP_region,
              accessKeyId: process.env.REACT_APP_accessKeyId,
              secretAccessKey: process.env.REACT_APP_secretAccessKey,
              s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, ""),
            };
            const customS3Client = new CustomS3Client(config);
            customS3Client
              .uploadFile(imagefiledatavalue, filename)
              .then((data) => {
                if (data.status === 204) {
                  const originalIcon =
                    appIconApiData.data.app_original_icon ||
                    appIconApiData.data.original_icon ||
                    (appIconApiData.data.app_frontend_display_icon &&
                      appIconApiData.data.original_icon) ||
                    appIconApiData.data.upload_icon;
                  setAppIconState({
                    isAppIconDefault: appIconApiData.is_default,
                    appIcon: filename + ".png",
                    originalIcon: originalIcon,
                    vistaDesignId: appIconApiData.data.design_id,
                    spinner: false,
                    buildStatus: appIconApiData.build_status,
                    updatedAt: appIconApiData.data.last_updated_timestamp,
                  });
                }
              })
              .catch((err) => {
                console.error(err);
                updatingAppIconStateOnError();
              });
          } catch (error) {
            console.error(error);
            updatingAppIconStateOnError();
          }
        } else {
          updatingAppIconStateOnError();
        }
      } else if (appIconApiData.code === 401) {
        if (appIconApiData.data === "App does not exists.") {
          history.push("/apps");
          errortoast(appIconApiData.data);
          setAppIconState({
            spinner: false,
          });
        }
      } else {
        errortoast("Oh no! Something’s not right.");
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = MetaTitle("App Icon");
    settingGetApiData();
    if (parseInt(localStorage.getItem("website_technology")) !== 3) {
      extractColor();
    }
  }, [appIconApiData]);

  const saveAppIconApi = (icon, designId) => {
    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      design_id: designId || "",
      upload_icon: icon,
      // original_icon: appIconState.originalIcon,
      original_icon: "",
      is_android_rebuild_required:
        appIconState.buildStatus?.android_success_build_count > 0 ? 1 : 0,
      is_ios_rebuild_required:
        appIconState.buildStatus?.ios_success_build_count > 0 ? 1 : 0,
      website_technology: appIconState.website_technology,
    };
    saveAppIconData(params)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          appDashboardDataRefetch();
          setAppIconState({
            btnLoader: false,
            updatedAt: res.data.updated_at,
          });
          sucesstoast("The record has been saved.");
        } else {
          setAppIconState({
            btnLoader: false,
          });
          errortoast("Oh no! Something’s not right.");
        }
      })
      .catch((err) => {
        console.error(err, "err");
      });
  };

  const handleRebuildPopUp = (e) => {
    setAppIconState({ openRebuildPopUp: !appIconState.openRebuildPopUp });
  };

  const handleVistaPopup = (e) => {
    if (window.matchMedia("(min-width: 1200px)").matches) {
      setAppIconState({ openVistaPopup: !appIconState.openVistaPopup });
    } else {
      setAppIconState({ openVistaPopup: false });
      setAppIconState({
        openSmallDetectionPopUp: !appIconState.openSmallDetectionPopUp,
      });
    }
  };

  const handleProTipPopUp = () => {
    setAppIconState({ openVistaPopup: false });
    setAppIconState({ openProTipPopUp: !appIconState.openProTipPopUp });
  };
  const handleImgDownloadPopUp = () => {
    if (window.matchMedia("(max-width: 1200px)").matches) {
      setAppIconState({ openVistaPopup: false });
      setAppIconState({
        openImgDownloadPopUp: !appIconState.openImgDownloadPopUp,
      });
    }
  };

  const handleMobVistaPopup = (e, from) => {
    if (from === "proceed") {
      setAppIconState({ openVistaPopup: true });
    } else {
      setAppIconState({ openVistaPopup: false });
      setAppIconState({ openSmallDetectionPopUp: false });
    }
  };

  const closeVistaCreatePopUp = () => {
    setAppIconState({ openVistaPopup: false });
  };

  const settingYourAppLogodata = (imgName, from) => {
    setAppIconState({ openVistaPopup: false, imgUrlToCrop: "" });

    if (from === "resize") {
      setAppIconState({
        isAppIconDefault: 0,
        appIcon: imgName,
        upload_icon: imgName,
        vistaDesignId: "",
      });
    } else {
      setAppIconState({
        originalIcon: imgName,
        vistaDesignId: "",
        // appIcon: imgName,
      });
    }
  };

  const handleImgGalleryPopUp = () => {
    setAppIconState({ openVistaPopup: false });
    setOpenGalleryPopup(!openGalleryPopup);
  };

  const yourErrFun = () => {
    setAppIconState({
      imgUrlToCrop: "",
    });
  };

  const handleRightPanelExpand = (value) => {
    setAppIconState({
      appRightPanelExpand: value,
    });
  };

  const handleSaveBtn = (e) => {
    setAppIconState({ openVistaPopup: false });
    setAppIconState({ btnLoader: true });
    if (
      appIconState.buildStatus?.android_success_build_count > 0 ||
      appIconState.buildStatus?.ios_success_build_count > 0
    ) {
      setAppIconState({ openVistaPopup: false });
      setAppIconState({ btnLoader: false });
      setAppIconState({ openRebuildPopUp: !appIconState.openRebuildPopUp });
    } else {
      saveAppIconApi(appIconState.appIcon, appIconState.vistaDesignId);
    }
  };

  const handleProceedBtn = (e) => {
    setAppIconState({ btnLoader: true });
    setAppIconState({ openRebuildPopUp: !appIconState.openRebuildPopUp });
    saveAppIconApi(appIconState.appIcon, appIconState.vistaDesignId);
  };

  const handleVistaPopUpSaveBtn = (data) => {
    let img = new Image();
    img.src = data.url;

    setAppIconState({
      openVistaPopup: false,
    });

    img.onload = async function () {
      if (this.height === 1024 && this.width === 1024) {
        setAppIconState({
          appIcon: data.url,
          vistaDesignId: data.id,
          isAppIconDefault: 0,
          imgUrlToCrop: "",
        });
        setOpenGalleryPopup(false);
        if (
          appIconState.buildStatus?.android_success_build_count > 0 ||
          appIconState.buildStatus?.ios_success_build_count > 0
        ) {
          setAppIconState({ openRebuildPopUp: !appIconState.openRebuildPopUp });
        } else {
          setAppIconState({
            btnLoader: true,
          });
          saveAppIconApi(data.url, data.id);
        }
      } else {
        setAppIconState({
          imgUrlToCrop: data.url,
        });
        setOpenGalleryPopup(true);
      }
    };
    img.onerror = function (err) {
      console.log(err, "err");
    };

    // if (document.querySelector(".crello-closeBtn")) {
    //   document.querySelector(".crello-closeBtn").click();
    // }
  };

  return (
    <>
      {closeVistaPopUp(appIconState.openVistaPopup)}
      <div>
        <Header customclass={"header-responsive-new"} />
      </div>

      <section className="dasboard_page mobile-display">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
        <div
          className={`right-panel-gap ${
            localStorage.getItem("appSideBarCollapse") === "true"
              ? " right-panel-gap-expand"
              : ""
          }`}
        >
          <div className="app-icon-page">
            {window.matchMedia("(min-width: 1200px)").matches && (
              <ModuleTitleTooltip
                title={"Design"}
                text={
                  "Create a visual aesthetic that appeals and conveys meaning to your app users. "
                }
              />
            )}
            <div className="app-icon-head-mobile">
              <h2 className="app-icon-head-mobile-text">App icon</h2>

              <div onClick={handleProTipPopUp}>
                <ProTipIcon />
              </div>
            </div>

            <div className="app-icon-subheader">
              <div className="app-icon-navigation-modal-div">
                <ModuleNavigations linkArr={appIconState.linkArr} />
              </div>

              <div className="appIcon-head-right">
                {!appIconState.spinner ? (
                  <>
                    {appIconState.updatedAt ? (
                      <UpdatedAt
                        timestamp={appIconState.updatedAt}
                        format={"D MMMM YYYY, HH:mm"}
                      />
                    ) : null}
                    {!appIconState.isAppIconDefault ? (
                      <SaveButton
                        handleBtnClick={(e) => handleSaveBtn(e)}
                        isBtnLoader={appIconState.btnLoader}
                        btnText={"Save"}
                        isDisable={!appIconState.appIcon}
                        variant={"primary-submit-web-btn"}
                      />
                    ) : null}
                  </>
                ) : null}
              </div>
            </div>

            {appIconState.spinner ? (
              <div className="app-icon-section-loader">
                <AmsSpinner spinWidth={"200px"} spinHeight={"200px"} />
              </div>
            ) : (
              <>
                <div className="app-icon-section">
                  <>
                    <div onClick={handleProTipPopUp}>
                      <ProTipIcon />
                    </div>

                    <div className="app-icon-section-width">
                      <div
                        className={
                          appIconState.isAppIconDefault
                            ? "app-icon-section-one"
                            : appIconState.appIcon &&
                              window.matchMedia("(max-width: 1200px)").matches
                            ? "app-icon-section-one d-none"
                            : "app-icon-section-one"
                        }
                      >
                        <div className="app-icon-section-one-width">
                          <h2 className="app-icon-section-one-h2">
                            Create a memorable app icon
                          </h2>
                          <p className="app-icon-section-one-para">
                            First impressions matter. Design an elegant and
                            recognizable icon that stands out, and clearly
                            represents your app’s purpose.
                          </p>
                          <p className="app-icon-section-one-para">
                            Your app users will click on your app icon to launch
                            your app from their mobile devices.
                          </p>

                          <div className="app-icon-buttons">
                            <SaveButton
                              handleBtnClick={(e) => handleVistaPopup(e)}
                              isBtnLoader={false}
                              btnText={
                                appIconState.vistaDesignId
                                  ? "Edit design"
                                  : "Start designing"
                              }
                              isDisable={false}
                              variant={"primary-submit-web-btn"}
                            />

                            <span
                              onClick={() => handleImgGalleryPopUp()}
                              style={{
                                "--custom_color6":
                                  parseInt(localStorage.getItem("agencyid")) > 1
                                    ? localStorage.getItem(
                                        "agency_primary_color"
                                      )
                                    : "#3064f9",
                              }}
                            >
                              or upload an image
                            </span>
                          </div>

                          <div className="app-icon-options">
                            <span onClick={() => handleImgGalleryPopUp()}>
                              or upload an image
                            </span>

                            {parseInt(localStorage.getItem("agencyid")) > 1 ? (
                              <></>
                            ) : (
                              <Link
                                to="/app/addons"
                                className="appicon-ask-an-expert"
                              >
                                <img
                                  src={
                                    require("../../../assets/images/dashboard/diamond.png")
                                      .default
                                  }
                                  alt="diamond"
                                />{" "}
                                Ask an expert
                              </Link>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="app-icon-section-two">
                        <AppIconPreview
                          appIconState={appIconState}
                          setAppIconState={setAppIconState}
                          handleImgDownloadPopUp={handleImgDownloadPopUp}
                          handleVistaPopup={handleVistaPopup}
                          handleImgGalleryPopUp={handleImgGalleryPopUp}
                        />
                      </div>
                    </div>
                  </>
                </div>

                <div className="app-icon-footer-save">
                  {!appIconState.isAppIconDefault ? (
                    <SaveButton
                      handleBtnClick={(e) => handleSaveBtn(e)}
                      isBtnLoader={appIconState.btnLoader}
                      btnText={"Save"}
                      isDisable={!appIconState.appIcon}
                      variant={"primary-submit-mob-btn"}
                    />
                  ) : null}

                  {appIconState.updatedAt ? (
                    <UpdatedAt
                      timestamp={appIconState.updatedAt}
                      format={"D MMMM YYYY, HH:mm"}
                    />
                  ) : null}
                </div>
              </>
            )}
          </div>
        </div>
      </section>

      <RebuildRequiredModal
        openPopUp={appIconState.openRebuildPopUp}
        handlePopUp={handleRebuildPopUp}
        handleProceedBtn={handleProceedBtn}
      />
      <ProTipModal
        handleProTipPopUp={handleProTipPopUp}
        popUpTitle={"Pro tip"}
        PopupDesc={"Level-up your creation with these tips and best practices."}
        tipsArr={appIconState.proTipsArr}
        openProTipModal={appIconState.openProTipPopUp}
        protipImg={protipImg}
      />
      <ScreenDetectionModal
        openPopUp={appIconState.openSmallDetectionPopUp}
        handleMobVistaPopup={handleMobVistaPopup}
      />

      <ImageDownloadModal
        openPopUp={appIconState.openImgDownloadPopUp}
        handleImgDownloadPopUp={handleImgDownloadPopUp}
        imageUrl={
          appIconState.appIcon?.includes("create.vista.com")
            ? appIconState.appIcon
            : appIconState.appIcon?.includes("ams")
            ? process.env.REACT_APP_Image_Path +
              localStorage.getItem("user_id") +
              "/" +
              localStorage.getItem("Appid") +
              process.env.REACT_APP_AppIcon_Crop +
              appIconState.appIcon
            : ""
        }
        imgName={"ams_app_icon"}
      />

      {appIconState.openVistaPopup ? (
        <VistaCreatePopUp
          artworkWidth={1024}
          artworkHeight={1024}
          moduleName="App icon"
          downloadFormat="jpg"
          designId={appIconState.vistaDesignId}
          formatKey="logoMM"
          submitFn={handleVistaPopUpSaveBtn}
        />
      ) : null}

      {openGalleryPopup && (
        <NewImageGalleryPopup
          isGalleryPopup={openGalleryPopup}
          setIsGalleryPopup={setOpenGalleryPopup}
          uploadPath={
            localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            process.env.REACT_APP_AppIcon_Crop.replace(/\/$/, "")
          }
          settingYourdata={settingYourAppLogodata}
          yourErrFun={yourErrFun}
          settingOrgImgFrom3rdParty={false}
          hideImageGallery={true}
          widthForRestriction={1024}
          heightForRestriction={1024}
          aspectRatio={"1024:1024"}
          isNotCustomUploadOrgImg={true}
          designid={appIconState.vistaDesignId}
          loadpicVistaCreatePopUp={handleVistaPopUpSaveBtn}
          closeVistaCreatePopUp={closeVistaCreatePopUp}
          //Image scale keys
          shouldImgScale={true}
          tempUploadPath={
            localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            process.env.REACT_APP_AppIcon_Crop.replace(/\/$/, "") +
            "/temp"
          }
          minScalingWidth={64}
          minScalingHeight={64}
          scaleType={"app_icon"}
          imgUrlToCrop={appIconState.imgUrlToCrop}
        />
      )}
    </>
  );
};

export default AppIcon;
