import React, { useState, useEffect, useReducer } from "react";
import Header from "../../../Dashboard/Header";
import Leftpanel from "../../../Dashboard/Leftpanel";
import "./customers.css";
import {
  useShowCustomerQuery,
  useBlockUnblockCustomerMutation
} from "../../../services/customCustomerApiSlice";
import ModuleTitleTooltip from "../../../Components/commonIcons/ModuleTitleTooltip";
import NoCustomerFound from "../../../Components/commonComponents/NoCustomerFound";
import CustomerEntry from "../../../Components/Customer/CustomerEntry";
import MobileCustomerEntry from "../../../Components/Customer/MobileCustomerEntry";
import AmsSpinner from "../../../Components/commonIcons/AmsSpinner";
import PageSpinner from "../../../Components/commonComponents/PageSpinner";
import BottomScrollListener from "react-bottom-scroll-listener";
import BottomMobileScrollListener from "react-bottom-scroll-listener";
import moment from "moment";
import { errortoast } from "../../../Dashboard/Toaster";
import DeactivateConfirmModal from "../../../Components/commonComponents/Modal";
import ActivateConfirmModal from "../../../Components/commonComponents/Modal";
import FilterIcon from "../../../assets/images/dashboard/FilterIcon.svg";
import Drawer from "../../../Components/commonComponents/Drawer";
import CustomFilter from "./customFilter";
import { MetaTitle } from "../../../utils/commonUtils.js";
import OutsideClickHandler from "react-outside-click-handler";

const Customers = () => {
  const [showData, setshowData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      spinner: true,
      customers: [],
      paginationSpinner: false,
      page: 1,
      limit: 20,
      hasMoreData: true,
      appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === "true"
    }
  );
  const [blockUnblockCustomerApi] = useBlockUnblockCustomerMutation();
  const [isActivateModalOpen, setIsActivateModalOpen] = useState(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [openDrawerId, setOpenDrawerId] = useState(null);
  const [filterData, setFilterData] = useState(null);
  const [searchInput, setsearchInput] = useState(null);
  const [searchInputValue, setsearchInputValue] = useState(null);
  const [searchInputDelete, setsearchInputDelete] = useState(false);
  const [sort, setSort] = useState({
    key: "created_at",
    value: 2
  });

  // Toggle functions
  const toggleActivateModal = () => {
    setIsActivateModalOpen((prevState) => !prevState);
  };
  const toggleDeactivateModal = () =>
    setIsDeactivateModalOpen((prevState) => !prevState);

  const handleRightPanelExpand = (value) => {
    setshowData({ appRightPanelExpand: value });
  };

  const {
    data: ShowCustomData,
    isError,
    isLoading
  } = useShowCustomerQuery({
    page: showData.page,
    limit: showData.limit,
    ...(filterData && { filters: filterData }),
    ...(searchInput && { searchInput }),
    ...(sort?.key && { sorting_key: sort.key, sorting_value: sort.value }),
    timestamp: showData.timestamp
  });

  useEffect(() => {
    document.title = MetaTitle("Customers");
  }, []); // Runs only once on mount

  useEffect(() => {
    // Handle API data and error states
    if (ShowCustomData?.code === 200) {
      setshowData({
        customers: [...showData.customers, ...ShowCustomData.data],
        spinner: false,
        paginationSpinner: false,
        mobilepaginationSpinner: false,
        hasMoreData: ShowCustomData.data.length > 0
      });
    }
    if (isError) {
      setshowData({ spinner: false, paginationSpinner: false });
      errortoast("Failed to load customer data. Try again later.");
    }
  }, [ShowCustomData, isError]); // Only re-run when ShowCustomData or isError changes

  const handleContainerOnBottom = () => {
    if (showData.hasMoreData && !showData.paginationSpinner) {
      setshowData({
        page: showData.page + 1,
        paginationSpinner: true
      });
    }
  };

  const handleMobileContainerOnBottom = () => {
    if (showData.hasMoreData && !showData.mobilepaginationSpinner) {
      setshowData({
        page: showData.page + 1,
        mobilepaginationSpinner: true
      });
    }
  };

  const formatLastLogin = (timestamp) =>
    timestamp ? moment(parseInt(timestamp)).format("D MMMM YYYY, H:mm") : null;

  const openActivateModal = (customerId) => {
    setSelectedCustomerId(customerId);
    setIsActivateModalOpen(true);
  };

  const openDeactivateModal = (customerId) => {
    setSelectedCustomerId(customerId);
    setIsDeactivateModalOpen(true);
  };

  const handleConfirmAction = async (status) => {
    try {
      await blockUnblockCustomerApi({
        customer_id: selectedCustomerId,
        status,
        app_id: parseInt(localStorage.getItem("Appid")) // replace with the actual app_id or make it dynamic
      });

      // Update the customer's status directly in the state
      setshowData({
        customers: showData.customers.map((customer) =>
          customer._id === selectedCustomerId
            ? { ...customer, status }
            : customer
        )
      });

      // Close the modals
      setIsActivateModalOpen(false);
      setIsDeactivateModalOpen(false);
    } catch (error) {
      errortoast("Failed to update customer status.");
    }
  };

  const openDrawer = (id) => {
    setsearchInputValue("");
    setsearchInput("");
    setOpenDrawerId(id);
  };
  const closeDrawer = () => setOpenDrawerId(null);

  const handleSearch = (newFilterData) => {
    // Only update state and trigger spinner if filterData actually changes
    setshowData({
      page: 1,
      spinner: true,
      customers: [],
      timestamp: Date.now()
    });

    if (
      newFilterData &&
      Object.keys(newFilterData).length === 0 &&
      newFilterData.constructor === Object
    ) {
      newFilterData = null; // Set the object to null if it is blank
    }

    setFilterData(newFilterData);
    closeDrawer();
  };

  const handlesearchinput = (e) => {
    const value = e.target.value.replace(/\s+/g, " ");
    setsearchInputValue(value);
    setsearchInputDelete(false);
  };

  const searchDelete = (e) => {
    setsearchInputValue("");
    setsearchInput("");
    setsearchInputDelete(true);
    setshowData({
      page: 1,
      spinner: true,
      customers: [],
      timestamp: Date.now()
    });
  };

  const handleKeyDown = (e) => {
    setsearchInputDelete(false);
    const regex = /[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]/;
    if (e.key === "Enter" && regex.test(searchInputValue)) {
      setshowData({
        page: 1,
        spinner: true,
        customers: [],
        timestamp: Date.now()
      });
      setsearchInput(searchInputValue);
    }
  };

  const handleSearchIcon = () => {
    setsearchInputDelete(false);
    const regex = /[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]/;
    if (regex.test(searchInputValue)) {
      setshowData({
        page: 1,
        spinner: true,
        customers: [],
        timestamp: Date.now()
      });
      setsearchInput(searchInputValue);
    }
  };

  const handleSort = (key) => {
    setshowData({
      page: 1,
      spinner: true,
      customers: [],
      timestamp: Date.now()
    });

    setSort((prevSort) => {
      if (prevSort.key === key) {
        // Toggle between ascending (1) and descending (2) for the same key
        return { key, value: prevSort.value === 1 ? 2 : 1 };
      } else {
        // Switch to the new key with descending order (2) by default
        return { key, value: 2 };
      }
    });

    setshowData((prevState) => ({
      ...prevState,
      timestamp: Date.now()
    }));
  };

  const renderSortIcons = (label, heading, key) => {
    const isCurrentSortKey = sort.key === key;
    const iconStyle = isCurrentSortKey
      ? "customer-sorting-visible"
      : "customer-sorting-aero";

    return (
      <>
        <div
          key={label}
          className={`customer-table-head-${label}`}
          onClick={() => handleSort(key)}
        >
          <h3 className="customer-table-heading">
            {heading}
            {/* Ascending Sort Icon */}
            {key != "status" ? (
              <>
                {isCurrentSortKey && sort.value === 1 ? (
                  <img
                    className={iconStyle}
                    src={
                      require("../../../assets/images/dashboard/cms/sort-up-arrow.png")
                        .default
                    }
                    alt="Sort Ascending"
                  />
                ) : /* Descending Sort Icon */
                isCurrentSortKey && sort.value === 2 ? (
                  <img
                    className={iconStyle}
                    src={
                      require("../../../assets/images/dashboard/cms/sort-down-arrow.png")
                        .default
                    }
                    alt="Sort Descending"
                  />
                ) : (
                  /* Default Down Arrow Icon */
                  <img
                    className="customer-sorting-aero"
                    src={
                      require("../../../assets/images/dashboard/cms/sort-down-arrow.png")
                        .default
                    }
                    alt="Default Sort Down"
                  />
                )}
              </>
            ) : (
              <></>
            )}
          </h3>
        </div>
      </>
    );
  };

  return (
    <>
      <Header customclass={"header-responsive-new"} />
      <section className="customer-listing">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
        <BottomMobileScrollListener
          onBottom={handleMobileContainerOnBottom}
          offset={100}
        >
          {(scrollRef) => (
            <div
              className={`right-panel-gap ${
                showData.appRightPanelExpand ? "right-panel-gap-expand" : ""
              }`}
              style={{ height: "calc(100vh - 60px)", overflowY: "auto" }}
              ref={scrollRef}
            >
              <OutsideClickHandler
                onOutsideClick={() => {
                  closeDrawer();
                }}
              >
                <div className="customer-head">
                  <ModuleTitleTooltip
                    title="Customers"
                    text="Add and manage customer information in one place."
                  />
                  {showData.customers.length ||
                  searchInput ||
                  searchInputDelete ||
                  filterData ? (
                    <>
                      <div className="customers-head-right">
                        <div className="customer-page-search">
                          <input
                            className="customer-page-search-input"
                            placeholder="Search..."
                            type="text"
                            value={searchInputValue || ""}
                            onChange={handlesearchinput}
                            onKeyDown={handleKeyDown} // Detect Enter key
                          />
                          <img
                            className="customer-page-search-icon"
                            src={
                              require("../../../assets/images/dashboard/cms/lens.png")
                                .default
                            }
                            onClick={handleSearchIcon}
                          />
                          {searchInput ? (
                            <img
                              className="customer-page-search-clear"
                              src={
                                require("../../../assets/images/dashboard/cross.png")
                                  .default
                              }
                              onClick={searchDelete} // Clear search input
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div
                          className={`customer-filter-icon ${
                            openDrawerId ? "customer-filter-icon-select" : ""
                          }`}
                          onClick={() => openDrawer("customer-listing-filter")}
                        >
                          <img src={FilterIcon} />
                          {filterData && (
                            <div className="customer-filterapplyed-reddot"></div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : null}
                </div>

                {showData.spinner ? (
                  <div
                    className={`customer-screen-loader ${
                      filterData || searchInput
                        ? "customer-serach-filter-screenloader"
                        : ""
                    }`}
                  >
                    <AmsSpinner spinWidth="200px" spinHeight="200px" />
                  </div>
                ) : (
                  <>
                    {showData.customers.length ? (
                      <>
                        <BottomScrollListener
                          onBottom={handleContainerOnBottom}
                          offset={100}
                        >
                          {(scrollRef) => (
                            <div className="customer-table" ref={scrollRef}>
                              <div>
                                <div className="customer-table-head">
                                  {[
                                    {
                                      label: "one",
                                      filter: "first_name",
                                      heading: "Customers"
                                    },
                                    {
                                      label: "two",
                                      filter: "email",
                                      heading: "Email"
                                    },
                                    {
                                      label: "three",
                                      filter: "last_login",
                                      heading: "Last login"
                                    },
                                    {
                                      label: "four",
                                      filter: "created_at",
                                      heading: "Created"
                                    },
                                    {
                                      label: "five",
                                      filter: "status",
                                      heading: "Status"
                                    }
                                  ].map(({ label, heading, filter }) => (
                                    <>
                                      {renderSortIcons(label, heading, filter)}
                                    </>
                                  ))}
                                </div>
                                <div className="customer-table-body">
                                  {showData.customers.map((customer, index) => (
                                    <CustomerEntry
                                      key={index}
                                      first={customer.first_name}
                                      second={customer.last_name}
                                      third={customer._id}
                                      fourth={customer.email_address}
                                      fifth={customer.created_at}
                                      sixth={formatLastLogin(
                                        customer?.last_login_timestamp?.$date
                                          ?.$numberLong
                                      )}
                                      status={customer.status}
                                      openActivateModal={openActivateModal}
                                      openDeactivateModal={openDeactivateModal}
                                    />
                                  ))}
                                </div>
                                {showData.paginationSpinner && (
                                  <div className="pagination-spinner">
                                    <PageSpinner spinHeight="200px" />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                        </BottomScrollListener>
                        <div className="customer-table-mobile">
                          {showData.customers.map((customer, index) => (
                            <MobileCustomerEntry
                              key={index}
                              first={customer.first_name}
                              second={customer.last_name}
                              third={customer._id}
                              fourth={customer.email_address}
                              fifth={customer.created_at}
                              sixth={formatLastLogin(
                                customer?.last_login_timestamp?.$date
                                  ?.$numberLong
                              )}
                              status={customer.status}
                              openActivateModal={openActivateModal}
                              openDeactivateModal={openDeactivateModal}
                            />
                          ))}
                        </div>
                        {showData.mobilepaginationSpinner && (
                          <div className="pagination-spinner">
                            <PageSpinner />
                          </div>
                        )}
                      </>
                    ) : (
                      <NoCustomerFound
                        showfilterData={filterData}
                        showsearchInput={searchInput}
                      />
                    )}
                  </>
                )}
                <Drawer
                  id="customer-listing-filter"
                  openId={openDrawerId}
                  onClose={closeDrawer}
                >
                  <CustomFilter onClose={closeDrawer} onSearch={handleSearch} />
                </Drawer>
              </OutsideClickHandler>
            </div>
          )}
        </BottomMobileScrollListener>
      </section>

      {/* Activation and Deactivation Modals */}
      <ActivateConfirmModal
        open={isActivateModalOpen}
        toggle={toggleActivateModal}
        title="Unblock this customer?"
        description="Are you sure you want to unblock this customer?"
        cancelbutton="Cancel"
        submitbutton="Unblock"
        onSubmit={() => handleConfirmAction(0)}
      />
      <DeactivateConfirmModal
        open={isDeactivateModalOpen}
        toggle={toggleDeactivateModal}
        title="Block this customer?"
        description="Are you sure you want to block this customer?"
        cancelbutton="Cancel"
        submitbutton="Block"
        onSubmit={() => handleConfirmAction(1)}
      />
    </>
  );
};

export default Customers;
