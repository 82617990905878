import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { CACHE_DURATION_ARRAY } from "../../../Constants/webviewConstants/WebviewConstants";
import BackArrow from "../../commonIcons/BackArrow";

const CacheDurationModal = ({ handleStateChange, webViewState }) => {
  return (
    <Modal
      className="protip-popup-message"
      isOpen={
        window.matchMedia("(max-width: 1200px)").matches
          ? webViewState.openMobNativeDaysDropdown
          : false
      }
      centered
    >
      <ModalBody>
        <div className="mobileglobalsearch">
          <button
            onClick={() =>
              handleStateChange("toggle_openMobNativeDaysDropdown")
            }
          >
            <BackArrow />
          </button>
        </div>

        <div className="mobilesearchsuggestion">
          {CACHE_DURATION_ARRAY.map((duration, idx) => (
            <p
              className="mobilesearchsuggestion-para"
              onClick={() =>
                handleStateChange("numberOfDaysToCacheFormat", duration, {
                  openMobNativeDaysDropdown: false,
                })
              }
              key={idx}
            >
              {duration}
            </p>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CacheDurationModal;
