import React, { useEffect } from "react";
import { Carousel } from "react-responsive-carousel";

const BlogBannerPreview = ({ homescreenData, eachBlock, blockIndx }) => {
  const handlesliderChange = (e) => {
    // setActiveTab(e);
  };

  useEffect(() => {
    let element = document.getElementById(`bannertest${blockIndx}`)
      .childNodes[0].childNodes[0].childNodes[0];

    if (eachBlock.style.layout.text_position === "outside_bottom") {
      element.classList.add("below-banner-dots");
    } else {
      element.classList.remove("below-banner-dots");
    }
  }, [eachBlock.style.layout.text_position]);

  const calculateHeight = (width, layout) => {
    let height = (width * 3) / 4;

    switch (true) {
      case layout.image_aspect_ratio === "4:3":
        height = (width * 3) / 4;
        break;
      case layout.image_aspect_ratio === "3:4":
        height = (width * 4) / 3;
        break;
      case layout.image_aspect_ratio === "1:1":
        height = (width * 1) / 1;
        break;
      case layout.image_aspect_ratio === "16:9":
        height = (width * 9) / 16;
        break;
    }
    return height;
  };
  return (
    <Carousel
      selectedItem={0}
      onChange={(e) => handlesliderChange(e)}
      showArrows={false}
      swipeable={true}
      showThumbs={false}
    >
      {Array.from({
        length: eachBlock.style.column,
      }).map((it, index) => {
        return (
          <div
            className={`homescreen-slide`}
            style={{
              height: calculateHeight(296, eachBlock.style.layout),
              // marginBottom:
              //   eachBlock.style.layout.text_position === "outside_bottom"
              //     ? "20px"
              //     : "0px",
            }}
          >
            <div
              className={`homescreen-screen image-cover-center`}
              style={{
                width: "100%",

                background:
                  "url(" +
                  require("../../../assets/images/dashboard/blog-banner-default.png")
                    .default +
                  ")",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            ></div>

            <div
              className={`homescreen-blog-meta ${
                eachBlock.style.layout.text_position === "outside_bottom"
                  ? "below-banner-text"
                  : ""
              }`}
            >
              <h4>
                Your blog title goes here what you have entered while making
              </h4>
              <p>12 September 2022</p>
            </div>
          </div>
        );
      })}
    </Carousel>
  );
};

export default BlogBannerPreview;
