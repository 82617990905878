import React, { useEffect, useState } from "react";
import Header from "../../../Dashboard/Header";
import Leftpanel from "../../../Dashboard/Leftpanel";
import "./Theme.css";
import ModuleTitleTooltip from "../../../Components/commonIcons/ModuleTitleTooltip";
import homeIcon from "../../../assets/images/dashboard/homeIcon.svg";
import ThemeIcons from "./ThemeIcons";
import moment from "moment";
import { Color, Solver } from "../../../Dashboard/BottomBar/Iconcolor";
import {
  countBlackIcons,
  hexToRgb,
  MetaTitle,
} from "../../../utils/commonUtils";
import {
  useGetDesignThemeDataQuery,
  useSaveDesignThemeDataMutation,
} from "../../../services/design-theme/designThemeSlice";
import { errortoast, sucesstoast } from "../../../Dashboard/Toaster";
import Spinner from "../../../Components/commonComponents/Spinner";
import iconPageImg from "../../../assets/images/dashboard/iconpage.png";
import iconPageImgClicked from "../../../assets/images/dashboard/iconpageclicked.png";
import ThemeSettings from "./ThemeSettings";
import ModuleNavigations from "../../../Components/commonComponents/ModuleNavigation/ModuleNavigations";
import { THEME_HEADER_LINKS } from "../../../Constants/AppTheme/themeHeaderLinks";
import Newfeatureicon from "../../../Components/commonIcons/Newfeatureicon";
import "@lottiefiles/lottie-player";
import animation from "../../../assets/json/BlueStar.json";
const Theme = () => {
  const {
    data,
    isLoading: isThemeDataLoading,
    isError: isThemeDataError,
  } = useGetDesignThemeDataQuery();
  const [saveDesignThemeData] = useSaveDesignThemeDataMutation();

  const [headerBackgroundColor, setHeaderBackgroundColor] = useState(
    "linear-gradient(90deg, rgba(50,167,255,1) 0%, RGBA(90,111,251,1) 51%, rgba(119,87,255,1) 100%)"
  );
  const [seperatorColor, setSeperatorColor] = useState("#DEDEDE");
  const [textColor, setTextColor] = useState("#C4D8FF");
  const [iconColor, setIconColor] = useState("#FFFFFF");
  const [selectedShape, setSelectedShape] = useState("midround");
  const [buttonTextColor, setButtonTextColor] = useState("#FFFFFF");
  const [buttonBackgroundColor, setButtonBackgroundColor] = useState("#4E7FFC");
  const [updatedDate, setUpdatedDate] = useState("");
  const [enableIconPage, setEnableIconPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveButtonLoading, setSaveButtonLoading] = useState(false);
  const [iconData, setIconData] = useState();
  const [updatedHeaderPayload, setUpdatedHeaderPayload] = useState(null);
  const [updatedListingPayload, setUpdatedListingPayload] = useState();
  const [updatedProfilePayload, setUpdatedProfilePayload] = useState();
  const [updatedSettingsPayload, setUpdatedSettingsPayload] = useState();
  const [iconFilter, setIconFilter] = useState("");
  const [allBlack, setAllBlack] = useState(true);
  const [appFontFamily, setAppFontFamily] = useState("Axiforma-Regular");
  const [rightPanelExpand, setRightPanelExpand] = useState(
    localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
      ? true
      : false
  );
  const [openFontDropdown, setOpenFontDropdown] = useState(false);

  const handleChange = (e, type) => {
    if (type == "headerBackgroundColor") {
      setHeaderBackgroundColor(e);
    } else if (type == "seperatorColor") {
      setSeperatorColor(e);
    } else if (type == "textColor") {
      setTextColor(e);
    } else if (type == "iconColor") {
      setIconColor(e);
      setIconFilter(applyingFilter(e));
    } else if (type == "buttonBackgroundColor") {
      setButtonBackgroundColor(e);
    } else if (type == "buttonTextColor") {
      setButtonTextColor(e);
    }
  };

  const handleClick = (shape) => {
    setSelectedShape(shape);
  };

  const generatePayload = (prefix, icons) => {
    return icons.reduce((payload, icon) => {
      const baseKey = icon.name
        .toLowerCase()
        .replace(/ /g, "_")
        .replace(/_icon$/, "");

      payload[`${prefix}_${baseKey}_icon`] = {
        title: icon.name,
        name: icon.item_icon,
        is_default: icon.item_icon.includes("ams") ? 0 : 1,
        is_black: icon.icon_color == "black" ? 1 : 0,
      };

      return payload;
    }, {});
  };

  const handleSave = () => {
    setSaveButtonLoading(true);
    const params = {
      app_id: localStorage.getItem("Appid"),
      header_background_color: headerBackgroundColor,
      header_seperator_color: seperatorColor,
      header_text_color: textColor,
      header_icon_color: iconColor,
      button_background_color: buttonBackgroundColor,
      button_text_color: buttonTextColor,
      button_shape: selectedShape,
      header_font_name:appFontFamily,
      ...updatedHeaderPayload,
      ...updatedListingPayload,
      ...updatedProfilePayload,
      ...updatedSettingsPayload,
    };
    saveDesignThemeData(params)
      .unwrap()
      .then((res) => {
        if (res.code === 200) {
          setSaveButtonLoading(false);
          setUpdatedDate(res.data.updated_at);
          sucesstoast("The record has been updated.");
        }
      })
      .catch((error) => {
        setSaveButtonLoading(false);
        errortoast("Oops! Something went wrong.");
      });
  };

  const applyingFilter = (iconColor) => {
    let rgb = hexToRgb(iconColor);

    if (rgb !== undefined && iconColor) {
      const colorvalue = iconColor.match(/[\d\.]+/g).map(Number);

      rgb = [];
      rgb[0] = colorvalue[0];
      rgb[1] = colorvalue[1];
      rgb[2] = colorvalue[2];

      const alpha = colorvalue[3] !== undefined ? colorvalue[3] : 1;

      let filter = "";

      if (rgb && rgb.length === 3 && iconColor) {
        const color = new Color(rgb[0], rgb[1], rgb[2]);
        const solver = new Solver(color);
        const result = solver.solve();
        if (alpha < 1) {
          const opacityPercentage = Math.round(alpha * 100);
          filter = `${result.filter} opacity(${opacityPercentage}%)`;
        } else {
          filter = result.filter;
        }

        return filter;
      }
    }

    return "invert(100%) sepia(100%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%)";
  };

  const handleRightPanelExpand = (value) => {
    setRightPanelExpand(value);
  };

  const countBlackIcons = (data, totalItems = 0, blackIconItems = 0) => {
    data &&
      data.forEach((item) => {
        blackIconItems++;
        if (item.is_black === 1) {
          totalItems++;
        }
      });
    if (totalItems === blackIconItems) {
      setAllBlack(true);
    } else {
      setAllBlack(false);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = MetaTitle("Theme");
    setAppFontFamily("Axiforma-Regular");
    if (data && data.code === 200) {
      setLoading(false);
      if (data.data) {
        setHeaderBackgroundColor(
          data.data.header_primary_color_object?.portal_data ||
            data.data.header_background_color_object?.portal_data
        );
        setSeperatorColor(
          data.data.header_seperator_color_object?.portal_data || "#DEDEDE"
        );
        setTextColor(
          data.data.header_secondary_color_object?.portal_data ||
            data.data.header_text_color_object?.portal_data
        );
        setIconColor(
          data.data.header_icon_color_object?.portal_data || "#1a1a1a"
        );
        setIconFilter(
          applyingFilter(data.data.header_icon_color_object?.portal_data)
        );

        setButtonTextColor(data.data.button_text_color_object?.portal_data);
        setButtonBackgroundColor(
          data.data.button_color_object?.portal_data ||
            data.data.button_background_color_object?.portal_data
        );
        setSelectedShape(data.data?.button_shape || "midround");
        setAppFontFamily(data.data?.header_font_name|| "Axiforma-Regular");
        setIconData(data.data);
        setUpdatedDate(data.updated_at);
        setUpdatedHeaderPayload({
          header_hamburger_menu_icon: data.data?.header_hamburger_menu_icon,
          header_back_icon: data.data?.header_back_icon,
          header_search_icon: data.data?.header_search_icon,
          header_share_icon: data.data?.header_share_icon,
          header_wishlist_icon: data.data?.header_wishlist_icon,
          header_bookmark_icon: data.data?.header_bookmark_icon,
          header_cart_icon: data.data?.header_cart_icon,
          header_profile_icon: data.data?.header_profile_icon,
          header_notification_icon: data.data?.header_notification_icon,
        });
        setUpdatedListingPayload({
          listing_sort_icon: data.data?.listing_sort_icon,
          listing_filter_icon: data.data?.listing_filter_icon,
          listing_list_view_icon: data.data?.listing_list_view_icon,
          listing_grid_view_icon: data.data?.listing_grid_view_icon,
        });
        setUpdatedProfilePayload({
          profile_reward_icon: data.data?.profile_reward_icon,
          profile_bookmark_icon: data.data?.profile_bookmark_icon,
          profile_wishlist_icon: data.data?.profile_wishlist_icon,
          profile_order_history_icon: data.data?.profile_order_history_icon,
          profile_saved_address_icon: data.data?.profile_saved_address_icon,
          profile_change_password_icon: data.data?.profile_change_password_icon,
          profile_chat_icon: data.data?.profile_chat_icon,
          profile_settings_icon: data.data?.profile_settings_icon,
          profile_logout_icon: data.data?.profile_logout_icon,
          profile_delete_account_icon: data.data?.profile_delete_account_icon,
        });
        setUpdatedSettingsPayload({
          setting_push_notifications_icon:
            data.data?.setting_push_notifications_icon,
          setting_notification_icon: data.data?.setting_notification_icon,
          setting_appearance_icon: data.data?.setting_appearance_icon,
          setting_site_icon: data.data?.setting_site_icon,
          setting_currency_icon: data.data?.setting_currency_icon,
          setting_language_icon: data.data?.setting_language_icon,
          setting_terms_and_conditions_icon:
            data.data?.setting_terms_and_conditions_icon,
          setting_chat_icon: data.data?.setting_chat_icon,
          setting_offline_mode_icon: data.data?.setting_offline_mode_icon,
          setting_rate_us_icon: data.data?.setting_rate_us_icon,
        });
      } else {
        setLoading(false);
      }
    }
    if (isThemeDataError) {
      errortoast("Oops! Something went wrong.");
      return;
    }
  }, [data]);

  const convertObjectToArray = (obj) => {
    return Object.keys(obj).map((key) => ({
      id: key,
      ...obj[key], // Spread operator to copy all properties
    }));
  };

  useEffect(() => {
    if (updatedHeaderPayload) {
      const resultArray = convertObjectToArray(updatedHeaderPayload);
      countBlackIcons(resultArray);
    }
  }, [updatedHeaderPayload]);

 

  return (
    <>
      <Header customclass={"header-responsive-new"} />
      <section className="theme-page-section">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />

        <div
          className={`right-panel-gap ${
            localStorage.getItem("appSideBarCollapse") &&
            localStorage.getItem("appSideBarCollapse") === "true"
              ? " right-panel-gap-expand"
              : ""
          }`}
        >
          <div className="themeHead">
            <div className="theme-main-head">
              <ModuleTitleTooltip
                title={"Design"}
                text={
                  "Create a visual aesthetic that appeals and conveys meaning to your app users."
                }
              />
              <h2 className="themeHead-title">Theme</h2>

              <div className="theme-buttonright-div mob-themebuttonright">
                {enableIconPage && (
                  <>
                    <div className="notification-setting ">
                      <img
                        onClick={() =>
                          enableIconPage ? setEnableIconPage(false) : ""
                        }
                        src={homeIcon}
                      />
                    </div>
                  </>
                )}
                  {/* {!enableIconPage ? 
                    <div className="theme-icon-star-tag">
                    <lottie-player
                      src={JSON.stringify(animation)}
                      background="transparent"
                      speed="1"
                      autoplay
                      ></lottie-player>
                  </div>
                      :
                      <></>
                  } */}
                <div
                  onClick={() =>
                    !enableIconPage ? setEnableIconPage(true) : ""
                  }
                  className={
                    enableIconPage
                      ? "consent-setting-clicked"
                      : "consent-setting"
                  }
                >
                  {!enableIconPage ? (
                    <img src={iconPageImg} />
                  ) : (
                    <img src={iconPageImgClicked} />
                  )}
                </div>
              </div>
            </div>
            <div className=" themeHead-module-tabs ">
              <div className="themeHead-div">
                <ModuleNavigations linkArr={THEME_HEADER_LINKS} />
              </div>
              {!isThemeDataLoading ? (
                <div className="theme-buttonright-div">
                  {enableIconPage ? (
                    <>
                      <p className="icon_last_updated-date">
                        Updated{" "}
                        {moment(updatedDate).format("D MMMM YYYY, HH:mm")}
                      </p>
                      <div className="notification-setting ">
                        <img
                          onClick={() =>
                            enableIconPage ? setEnableIconPage(false) : ""
                          }
                          src={homeIcon}
                        />
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                   {/* {!enableIconPage ? 
                    <div className="theme-icon-star-tag">
                      <lottie-player
                        src={JSON.stringify(animation)}
                        background="transparent"
                        speed="1"
                        autoplay
                        ></lottie-player>
                    </div>
                      :
                      <></>
                    } */}
                  <div
                    onClick={() => setEnableIconPage(!enableIconPage)}
                    className={
                      enableIconPage
                        ? "consent-setting-clicked"
                        : "consent-setting"
                    }
                  >  
                    {!enableIconPage ? (
                      <img src={iconPageImg} />
                    ) : (
                      <img src={iconPageImgClicked} />
                    )}
                  </div>
                  <button onClick={handleSave} className="themeHead-savebtn" disabled={saveButtonLoading}>
                    {saveButtonLoading ? (
                      <div className="d-flex align-items-center justify-content-center">
                        <div className="new-loader"></div>
                      </div>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
          {isThemeDataLoading ? (
            <div
              className="theme-icon-page-section "             
            >
              <div className="newspinner">
                <Spinner />
              </div>
            </div>
          ) : (
            <>
              {" "}
              {!enableIconPage ? (
                <ThemeSettings
                  headerBackgroundColor={headerBackgroundColor}
                  textColor={textColor}
                  seperatorColor={seperatorColor}
                  handleChange={handleChange}
                  allBlack={allBlack}
                  buttonBackgroundColor={buttonBackgroundColor}
                  buttonTextColor={buttonTextColor}
                  handleClick={handleClick}
                  selectedShape={selectedShape}
                  iconColor={iconColor}
                  enableIconPage={enableIconPage}
                  appFontFamily={appFontFamily}
                  setAppFontFamily={setAppFontFamily}
                  openFontDropdown={openFontDropdown}
                  setOpenFontDropdown={setOpenFontDropdown}
                />
              ) : (
                <ThemeIcons
                  iconData={iconData}
                  setUpdatedHeaderPayload={setUpdatedHeaderPayload}
                  setUpdatedListingPayload={setUpdatedListingPayload}
                  setUpdatedProfilePayload={setUpdatedProfilePayload}
                  setUpdatedSettingsPayload={setUpdatedSettingsPayload}
                  generatePayload={generatePayload}
                  headerBackgroundColor={headerBackgroundColor}
                  textColor={textColor}
                  seperatorColor={seperatorColor}
                  iconFilter={iconFilter}
                  allBlack={allBlack}
                  setAllBlack={setAllBlack}
                />
              )}
            </>
          )}
          {!isThemeDataLoading && <div className="mob-save-button-div">
            <button disabled={saveButtonLoading} onClick={handleSave} className="mob-save-button">
              {" "}
              {saveButtonLoading ? (
                <div className="d-flex align-items-center justify-content-center">
                  <div className="new-loader"></div>
                </div>
              ) : (
                "Save"
              )}
            </button>
            <p className="mob-save-button-date">
              Updated {moment(updatedDate).format("D MMMM YYYY, HH:mm")}
            </p>
          </div>}
          
        </div>
      </section>
    </>
  );
};

export default Theme;
