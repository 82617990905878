import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./download.css";
import Header from "./../Header";
import Leftpanel from "./../Leftpanel";
import {Unlinkapp} from '../../Api/Multisite/Multisiteapi';
import Tooltip from "../../Components/commonIcons/Tooltip.js";
import { 
	Spinner, 
	UncontrolledDropdown,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
} from "reactstrap";
import moment from "moment";
import { Analyticsapi, 
         GetDownloadappdetailsapi,
         Savesubscriptionapi,
         NewDownloadBuildApi,
         ScrollAnalyticsapi,
         cancelBuild, } from "../../Api/Dashboard/DashboardApi";
import BottomScrollListener from "react-bottom-scroll-listener";
import TableSpinner from "../../Components/commonComponents/TableSpinner";
   
export default class Download extends React.PureComponent 
{
    constructor(props) {
        super(props);
        this.state = {
        spinner: true,
        data: [],
        versiondata: [],
        plan_code: "preview",
        zoho_plan_code: "preview",
        modalandroid: false,
        versionhistory: true,
        buildhistory: false,
        ios_bundle_id: "",
        iosstatus: undefined,
        urlvar:   localStorage.getItem("workspaceId") !== null &&
                  localStorage.getItem("workspaceId") !== undefined &&
                  localStorage.getItem("workspaceId") !== ""
                ? `/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`
                : parseInt(localStorage.getItem('website_technology')) === 4 ? 
                  "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
                : parseInt(localStorage.getItem('website_technology')) === 5 ? 
                  "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
                : parseInt(localStorage.getItem('website_technology')) === 3 ? 
                  "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
                : parseInt(localStorage.getItem('website_technology')) === 2 ? 
                  "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
                : "/app/pricing",
        hitheader: "0",
        multisite: 0,
        builder_status: null,
        allowed_module: "preview",
        allowed_module1: "basic",
        openDownloadPopup:false,
        viewerror:false,
        builderrordescription:"",
        androidstatus: null,
        buildiosstatus: null,
        hideandroidbuildappbutton: false,
        hideiosbuildappbutton:false,
        buildapp: false,
        pointsmodal: false,
        user_status: 0,
        rebuildapp: false,
        downloadbuild: false,
        addonrebuildapp: false,
        iosbuildspinner: false,
        triggerautomatic: true,
        androidbuildspinner: false,
        andorid_step_number:null,
        ios_step_number:null,
        is_android_build_on_hold: "",
        android_bitrise_build_slug: "",
        is_ios_build_on_hold: "",
        ios_bitrise_build_slug: "",
        unlinkpopup:false,
        iostriggerautomatic: true,
        cancelplatform:"",
        cancelmodal:false,
        build_trigger_count:0,
        Initialising_step_number:0,
        IOS_Initialising_step_number:0,
        submitval_confirmpop:"d-block",
        loader_remove:"d-none",
        submitval_confirmpop1:"d-block",
        loader_remove1:"d-none",
        mergeappname:"",
        mergeappurl:"",
        mergeappimage:"",
        setIsPushed:true,
        page:1,
        total_build_count:0,
        success_build_count:0,
        rebuildpopup:false,
        nextpageicon_spinner:false,
        appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
                localStorage.getItem("appSideBarCollapse") === "true"
                ? true
                : false,
        };

    }

    componentDidMount() 
    {
        window.scrollTo(0, 0);
        if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
        {
          document.title = "Download | AppMySite";

        }
        else
        {
          document.title = "Download |"+ ' ' +localStorage.getItem('agencyname');
        }
        // this.versionTable()
        const buildparams = 
          {
            app_id: localStorage.getItem("Appid"),
            page:1,
          };
          this.setState({ appid: localStorage.getItem("Appid")});
        NewDownloadBuildApi(buildparams, this);

        this.setState({
          urlvar : 
          localStorage.getItem("workspaceId") !== null &&
          localStorage.getItem("workspaceId") !== undefined &&
          localStorage.getItem("workspaceId") !== ""
          ?`/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`
          :  parseInt(localStorage.getItem('website_technology')) === 4 ? 
          "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
        : parseInt(localStorage.getItem('website_technology')) === 5 ? 
          "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
        : parseInt(localStorage.getItem('website_technology')) === 3 ? 
          "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
        : parseInt(localStorage.getItem('website_technology')) === 2 ? 
          "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
        : "/app/pricing",
          subscription: localStorage.getItem("subscriptionactive"),
      });

          if (this.props.location !== "") 
          {
            
            if(this.props.location.pathname != '')
            {
                let parts = this.props.location.pathname.split("/");
                let answer = parts[parts.length - 1];
        
                let params = '';
                
          
                if(answer != 'download')
                {
                    params = {app_id: atob(answer)};

                    Savesubscriptionapi(this, params, () => 
                    {
                      

                        GetDownloadappdetailsapi(this, () => 
                        {
                          
                        this.spinnerdown();
                        this.iosspinnerdown();
                        });
                        
                        
                        this.setState({
                        urlvar:
                            localStorage.getItem("workspaceId") !== null &&
                            localStorage.getItem("workspaceId") !== undefined &&
                            localStorage.getItem("workspaceId") !== ""
                            ? `/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`
                          :  parseInt(localStorage.getItem('website_technology')) === 4 ? 
                            "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
                          : parseInt(localStorage.getItem('website_technology')) === 5 ? 
                            "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
                          : parseInt(localStorage.getItem('website_technology')) === 3 ? 
                            "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
                          : parseInt(localStorage.getItem('website_technology')) === 2 ? 
                            "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
                          : "/app/pricing",
                        subscription: localStorage.getItem("subscriptionactive"),
                        });
                    });
                }
                else
                {
                GetDownloadappdetailsapi(this, () => {
                    this.spinnerdown();
                    this.iosspinnerdown();
                });
                this.setState({
                    urlvar : 
                    localStorage.getItem("workspaceId") !== null &&
                    localStorage.getItem("workspaceId") !== undefined &&
                    localStorage.getItem("workspaceId") !== ""
                    ?`/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`
                    :  parseInt(localStorage.getItem('website_technology')) === 4 ? 
                    "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
                  : parseInt(localStorage.getItem('website_technology')) === 5 ? 
                    "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
                  : parseInt(localStorage.getItem('website_technology')) === 3 ? 
                    "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
                  : parseInt(localStorage.getItem('website_technology')) === 2 ? 
                    "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
                  : "/app/pricing",
                    subscription: localStorage.getItem("subscriptionactive"),
                });
                }
            }
            else
            {
              this.setState({
                urlvar : 
                localStorage.getItem("workspaceId") !== null &&
                localStorage.getItem("workspaceId") !== undefined &&
                localStorage.getItem("workspaceId") !== ""
                ?`/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`
                :  parseInt(localStorage.getItem('website_technology')) === 4 ? 
                "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
              : parseInt(localStorage.getItem('website_technology')) === 5 ? 
                "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
              : parseInt(localStorage.getItem('website_technology')) === 3 ? 
                "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
              : parseInt(localStorage.getItem('website_technology')) === 2 ? 
                "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
              : "/app/pricing",
                subscription: localStorage.getItem("subscriptionactive"),
            });
            }
              
          } 
          else 
          {
            GetDownloadappdetailsapi(this, () => {
              this.spinnerdown();
              this.iosspinnerdown();
            });
            this.setState({
              urlvar : 
              localStorage.getItem("workspaceId") !== null &&
              localStorage.getItem("workspaceId") !== undefined &&
              localStorage.getItem("workspaceId") !== ""
                ?`/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`
                :  parseInt(localStorage.getItem('website_technology')) === 4 ? 
                "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid'))
              : parseInt(localStorage.getItem('website_technology')) === 5 ? 
                "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
              : parseInt(localStorage.getItem('website_technology')) === 3 ? 
                "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
              : parseInt(localStorage.getItem('website_technology')) === 2 ? 
                "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
              : "/app/pricing",
              subscription: localStorage.getItem("subscriptionactive"),
            });
          }
    }
    versionHistory = () => {
        this.setState({
        versionhistory: true,
        buildhistory: false,
        });
    };
    buildHistory = () => {
        this.setState({
        versionhistory: false,
        buildhistory: true,
        });
    };
    versionTable = () => {
        const params = {
        type: "all",
        };
        Analyticsapi(params, this);
    };
    handlespinner = () => {
        this.setState({
        spinner: false,
        });
    };
    handleandroidpopup = () => {
        this.setState({
        modalandroid: !this.state.modalandroid,
        });
    };
    handleloader = () => {
        this.setState({
        spinner: true,
        });
    };
    handlestoploader = () => {
        this.setState({
        spinner: false,
        });
    };
    handlestoreiosdata = (ios_bundle_id, iosstatus) => {
        this.setState({
        ios_bundle_id: ios_bundle_id,
        iosstatus: iosstatus,
        });
    };
    hitheader = () => {
        let hitval = parseInt(this.state.hitheader) + 1;
        this.setState({
        hitheader: hitval.toString(),
        });
    };

    handlespinner = () => {
      this.setState({
        spinner: false,
      });
    };
      
    handleRightPanelExpand = (value) => 
    {
    
        this.setState({
        appRightPanelExpand: value
        })
    }

    spinnerdown = () =>
    {
      if(this.state.Initialising_step_number < 11)
      {
        setTimeout(() => {
          this.setState({Initialising_step_number:this.state.Initialising_step_number+1});
          this.spinnerdown();
        }, 4000);
      }
      else
      {
        this.setState({Initialising_step_number:this.state.Initialising_step_number});
      }

    }
    
    iosspinnerdown = () =>
    {
      if(this.state.IOS_Initialising_step_number < 11)
      {
        setTimeout(() => {
          this.setState({IOS_Initialising_step_number:this.state.IOS_Initialising_step_number+1});
          this.iosspinnerdown();
        }, 4000);
      }
      else
      {
        this.setState({IOS_Initialising_step_number:this.state.IOS_Initialising_step_number});
      }


    }
        
    handleAttributePopUp = () =>
    {
        this.setState({openDownloadPopup: !this.state.openDownloadPopup})
    }

    handleErrorPopUp = (description) =>
    {
      this.setState({viewerror: !this.state.viewerror,builderrordescription:description})
    }

    pointsmodalclick = (e) => {
      e.preventDefault();
      this.setState({
        pointsmodal: !this.state.pointsmodal,
      });
    };

    cancelmodalclick = () => {
      this.setState({
        cancelmodal: false,
      });
    }

    versionCompare = (v1, v2, options) => 
      {
        if(v1 && v2)
        {
          var lexicographical = options && options.lexicographical,
            zeroExtend = options && options.zeroExtend,
            v1parts = v1.split("."),
            v2parts = v2.split(".");
    
          function isValidPart(x) {
            return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
          }
    
          if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
            return NaN;
          }
    
          if (zeroExtend) {
            while (v1parts.length < v2parts.length) v1parts.push("0");
            while (v2parts.length < v1parts.length) v2parts.push("0");
          }
    
          if (!lexicographical) {
            v1parts = v1parts.map(Number);
            v2parts = v2parts.map(Number);
          }
    
          for (var i = 0; i < v1parts.length; ++i) {
            if (v2parts.length === i) 
            {
              return 1;
            }
    
            if (v1parts[i] === v2parts[i]) 
            {
              continue;
            }
            if (v1parts[i] < v2parts[i]) 
            {
              continue;
            }
            else if (v1parts[i] > v2parts[i]) 
            {
              return (
                <>
                  <div
                    className="updateversion m-0 p-0 version-spacing"
                    style={{ background: "none" }}
                  ><p  className="p-0 m-0 d-md-block"
                  style={{ color: "#1B2952", fontSize: "14px" }}>
                    New version available
                  </p>
                    {/* <p>
                      An update is now available. To upgrade to code version{" "}
                      {this.state.android_latest_version}
                      <span
                        onClick={(e) => this.createbuild("android")}
                        className="ml-1"
                      >
                        click here
                      </span>
                    </p> */}
                  </div>
                </>
              );
            } 
            else {
              return (
                <>
                  <h5>
                    New version available.
                  </h5>
                </>
              );
            }
          }
    
          if (v1parts.length !== v2parts.length) {
            return <></>;
          }
    
        }
      };

      versioniosCompare = (v1, v2, options) => 
        {
          if(v1 && v2)
          {
            var lexicographical = options && options.lexicographical,
              zeroExtend = options && options.zeroExtend,
              v1parts = v1.split("."),
              v2parts = v2.split(".");
      
            function isValidPart(x) {
              return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
            }
      
            if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
              return NaN;
            }
      
            if (zeroExtend) {
              while (v1parts.length < v2parts.length) v1parts.push("0");
              while (v2parts.length < v1parts.length) v2parts.push("0");
            }
      
            if (!lexicographical) {
              v1parts = v1parts.map(Number);
              v2parts = v2parts.map(Number);
            }
      
            for (var i = 0; i < v1parts.length; ++i) {
              if (v2parts.length === i) {
                return 1;
              }
      
              if (v1parts[i] === v2parts[i]) {
                continue;
              }
              else if (v1parts[i] < v2parts[i]) {
                continue;
              } 
              else if (v1parts[i] > v2parts[i]) {
                return (
                  <>
                    <div
                      className="updateversion m-0 p-0 version-spacing"
                      style={{ background: "none" }}
                    ><p  className="p-0 m-0 d-md-block"
                    style={{ color: "#1B2952", fontSize: "14px" }}>

                      New version available
                    </p>
                      {/* <p
                        className="p-0 m-0 d-md-block"
                        style={{ color: "#1B2952", fontSize: "14px" }}
                      >
                        An update is now available. To upgrade to code version{" "}
                        {this.state.ios_latest_version}
                        <span
                          onClick={(e) => this.createbuild("ios")}
                          className="ml-1"
                        >
                          click here
                        </span>
                      </p> */}
                    </div>
                  </>
                );
              } else {
                return (
                  <>
                    <h5>
                      New version available.
                    </h5>
                  </>
                );
              }
            }
      
            if (v1parts.length !== v2parts.length) {
              return <></>;
            }
      
          }
        };

    Checkandroidstatus = (androidstatus) => 
    {
            if (androidstatus === null || androidstatus == undefined) 
            {
              return (<>Draft</>);
            }
            else
            {

                if (androidstatus.app_in_process === 1) 
                {
                return (<>-</>);
                }
                if (androidstatus.app_in_process === 0) 
                {
                    if (androidstatus.build_status === "0") 
                    {
                        return (<>Draft</>);
                    }
                    if (androidstatus.build_status === "3") 
                    {
                        return (<>Draft</>);
                    }
                    if (androidstatus.build_status === "1") 
                    {
                        return (<>Build completed</>);
                    }
                    if (androidstatus.build_status === "4") 
                    {
                        return (<>Build completed</>);
                    }
                    if (androidstatus.build_status === "2") 
                    {
                        return (<>Build Failed</>);
                    }
                }
            }
    };

    Checkiosstatus = (iosstatus) => {
      if (iosstatus === null) {
        return (
          <>
            Draft
            
          </>
        );
      }
      if (iosstatus.app_in_process === 1) {
        return (
          <>
             -
            
          </>
        );
      }
      if (iosstatus.app_in_process === 0) {
        if (iosstatus.build_status === "0") {
          //Draft condition after payment
          return (
            <>
             
                Draft
             
            </>
          );
        }
        if (iosstatus.build_status === "3") {
          //Draft condition after payment
          return (
            <>
              
                Draft
             
            </>
          );
        }
        if (iosstatus.build_status === "1") {
          //When App is available to Download
          return (
            <>
              
                Build completed
              
              
            </>
          );
        }
        if (iosstatus.build_status === "4") {
          //When App is available to Download
          return (
            <>
              
                Build completed
              
            </>
          );
        }
        if (iosstatus.build_status === "2") {
          //Rebuild
          return (
            <>
              
                Build Failed
              
              {/* <p
                style={{ color: "#7782A1", fontSize: "14px" }}
                className="d-none d-md-block pr-2"
              >
                {this.state.error_description}
              </p> */}
            </>
          );
        }
      }
    };

    Checkstatus = (res) => {
        if (res.app_in_process === 1) {
          return "Processing";
        }
        if (res.app_in_process === 0) {
          if (
            res.build_status === "1" &&
            res.bitrise_success_step === "success" &&
            res.bitbucket_success_step === "success"
          ) {
            //When App is available to Download
            return "Completed";
          }
          if (
            res.build_status === "4" &&
            res.bitrise_success_step === "success" &&
            res.bitbucket_success_step === "success"
          ) {
            //When App is available to Download
            return "Completed";
          }
          if (res.build_status === "2") {
            //Rebuild
            return "Failed";
          }
          if (res.build_status === "0") {
            //Rebuild
            return "Draft";
          }
          if (res.build_status === "3") {
            //Rebuild
            return "Draft";
          }
        }
      };

        //Build create function
  createbuild = (buildplatform) => 
  {

    if (
      buildplatform === "android" &&
      parseInt(this.state.builder_status.status) !== 1 &&
      parseInt(this.state.builder_status.status) !== 3
    ) {
      //technology check
      if (parseInt(localStorage.getItem("website_technology")) === 4 || parseInt(localStorage.getItem("website_technology")) === 5) 
      {
        
       
        if (this.state.user_status === 1) 
        {
          if (this.state.android_build_version === null) 
          {
            this.setState({
              buildapp: true,
              buildplatform: buildplatform,
              is_android_build_on_hold: "",
            });
          } 
          else 
          {
            this.props.history.push("/app/download/android-build-settings");
          }
        } 
        else
        {
          this.setState({
            pointsmodal: true,
          });
        }
      } //technology check
      else 
      {
        if (
          this.state.user_status === 1 
         
        ) 
        {
          if (this.state.android_build_version === null) {
            this.setState({
              buildapp: true,
              buildplatform: buildplatform,
              is_android_build_on_hold: "",
            });
          } else {
            this.props.history.push("/app/download/android-build-settings");
          }
        }
         else
          {
          this.setState({
            pointsmodal: true,
          });
        }
      }
    }
    if (
      buildplatform === "ios" &&
      parseInt(this.state.builder_status.status) !== 2 &&
      parseInt(this.state.builder_status.status) !== 3
    ) {
      //technology check
      if (parseInt(localStorage.getItem("website_technology")) === 4 || parseInt(localStorage.getItem("website_technology")) === 5) {
        if (
          this.state.user_status === 1 
        ) {
          if (this.state.android_build_version === null) {
            this.setState({
              buildapp: true,
              buildplatform: buildplatform,
              is_android_build_on_hold: "",
            });
          } else {
            this.props.history.push("/app/download/ios-build-settings");
          }
        } else {
          this.setState({
            pointsmodal: true,
          });
        }
      } //technology check
      else {
        if (
          this.state.user_status === 1 
        ) {
          if (this.state.android_build_version === null) {
            this.setState({
              buildapp: true,
              buildplatform: buildplatform,
              is_android_build_on_hold: "",
            });
          } else {
            this.props.history.push("/app/download/ios-build-settings");
          }
        } else {
          this.setState({
            pointsmodal: true,
          });
        }
      }
    }
  };

  togglebuildapp = (e) => {
    e.preventDefault();
    this.setState({
      buildapp: !this.state.buildapp,
    });
  };

  iosdataform = (e) => {
    e.preventDefault();
    this.props.history.push("/app/download/ios-build-settings");
  };

  handleandroidpopup = (e) => {
    e.preventDefault();
    this.props.history.push("/app/download/android-build-settings");
  };

  handleunlink = () => {
    this.setState({unlinkpopup:!this.state.unlinkpopup});
  }

  unlinkapp = () =>
  {
    const params = {
      app_id:parseInt(localStorage.getItem("Appid"))
    }
    this.setState({loader_remove1:'new-loader',submitval_confirmpop1:'d-none'});
    Unlinkapp(params)
  }

  unlinkmodalclick = () =>
  {
    this.setState({unlinkpopup:false});
  }

  hanldebuildcancel = (platform) =>
  {
    this.setState({cancelplatform:platform,cancelmodal:!this.state.cancelmodal});
  }

  handlecancelbuild = () => 
  {
    const cancelbuildparam = {
      app_id: localStorage.getItem("Appid"),
      platform: this.state.cancelplatform,
    };

    this.setState({loader_remove:'new-loader',submitval_confirmpop:'d-none'});

    cancelBuild(cancelbuildparam, this);
  };

  waitfunction = () => {
    const buildparams = {
      app_id: localStorage.getItem("Appid"),
    };

   setTimeout(() => {
      NewDownloadBuildApi(buildparams, this);
      this.hitheader();
    }, 30000);
  };


  handleBottomScrollListner = () => {
    
    if (this.state.setIsPushed && (!this.state.nextpageicon_spinner)) 
    {
      var pagenumber = this.state.page + 1;
      this.setState({ page: pagenumber, nextpageicon_spinner: true });
     
      const buildparams = {app_id: localStorage.getItem("Appid"),page:pagenumber};


  
      ScrollAnalyticsapi(buildparams, this);
    }
  }

  androidredirect = () => {
    if(
        ((localStorage.getItem("plan_name") !== "preview") && ((localStorage.getItem("plan_name")) !== "paddle_preview")
        && ((localStorage.getItem("plan_name")) !== "Free") && ((localStorage.getItem("plan_name")) !== "free") 
        )
      )
      {
        window.location.href = "/app/download/android-build-settings";
      }
      else
      {
          if(this.state.success_build_count < this.state.total_build_count)
          {
            window.location.href = "/app/download/android-build-settings";
          } 
          else
          {
            this.setState({rebuildpopup:true});
          }
         
      }
  }

  handleRebuildPopup = () => 
  {
    this.setState({rebuildpopup:!this.state.rebuildpopup});
  }

    
      
render() {

return (
    <>
        <div>
        <Header customclass={"header-responsive-new"} />
        </div>

        <section className="dasboard_page mobile-display mt-4 mt-md-0 cms-module">
      <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
      <BottomScrollListener
          onBottom={this.handleBottomScrollListner}
        offset={100}
      >
        {(scrollRef) => (
        <div
        ref={scrollRef}
        style={{
          overflowY:"scroll",
          minHeight:"calc(100vh - 90px)",
          height:"calc(100vh - 60px)"
        }}
        className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>


        <div className="download-head"
        style={{
          display:"flex",
          justifyContent:"space-between"
        }}
        >
            <h1>Download <p class="download-tooltip">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5"><circle cx="9" cy="9" r="9" stroke="none"></circle><circle cx="9" cy="9" r="8.25" fill="none"></circle></g><line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"></line><path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"></path></g></svg>
                <p class="download-tooltipsubheader">Configure and download your app build.</p>
                </p>
                {this.state.allowed_module !== "preview" && (
                    <span  className={styles.operational}
                    style={{
                    color:
                        this.state.builder_status.colour,
                    }}>{this.state.builder_status.title}</span>
                )}
            </h1>
            {
                      (localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) && 
                      this.state.allowed_module1 === "preview" &&
                      window.matchMedia("(min-width: 1200px)").matches && this.state.spinner === false &&
                    <div style={{marginLeft: "13px"}} className="upgrade-feature">
                      <h4 style={{textAlign: "unset"}} className="upgrade-unavailable">This feature is currently unavailable</h4>
                      <p style={{textAlign: "unset"}} className="upgrade-support">Please contact Support for assistance.</p>
                    </div>
                    }
        </div>
        {
                      (localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) && 
                      this.state.allowed_module1 === "preview" &&
                      window.matchMedia("(max-width: 1200px)").matches && this.state.spinner === false &&
                    <div style={{marginLeft: "13px"}} className="upgrade-feature">
                      <h4 style={{textAlign: "unset"}} className="upgrade-unavailable">This feature is currently unavailable</h4>
                      <p style={{textAlign: "unset"}} className="upgrade-support">Please contact Support for assistance.</p>
                    </div>
                    }

              {this.state.spinner ? (
          <div className="chat-main-topdiv" style={{ overflow: "hidden",minHeight:"80vh",marginTop:"10px"}}>
               <div className="newspinner">
               <svg xmlns="http://www.w3.org/2000/svg" 
                  xlink="http://www.w3.org/1999/xlink" 
                  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
                  width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                  <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                  <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                    style={{animationplaystate: "running", animationdelay: "0s"}}>
                    </animateTransform>
                  </circle>
                  </g>
                  <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                  <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                  <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                  style={{animationplaystate: "running", animationdelay: "0s"}}>
                  </animateTransform>
                  </circle>
                  </g>
                  <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                  <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                  <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                  style={{animationplaystate: "running", animationdelay: "0s"}}>
          
                  </animateTransform>
                  </circle>
                  </g>
                  <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                  <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                  <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                  style={{animationplaystate: "running", animationdelay: "0s"}}>
          
                  </animateTransform>
                  </circle>
                  </g>
                  </svg>
             </div>
             </div>
              ) 
              : 
              (
               <>
                  {this.state.multisite.is_app_link_to_multisite > 0 
                  ? 
                    <>
                      <div className="download-merged ">
                          <div>
                              <div className="download-grid">

                                  <div className="parent-app">
                                      <div className="download-parent-tag">
                                          <h3>Primary app</h3>
                                      </div>
                                      <div className="download-appicon">
                                          <img src={
                                                    process.env.REACT_APP_Image_Path +
                                                    this.state.multisite.parent_app_user_id +
                                                    "/" +
                                                    this.state.multisite.parent_app_id +
                                                    process.env.REACT_APP_AppIcon_Display +
                                                    this.state.multisite.parent_app_icon
                                                  } alt="download-img" className="img-fluid" />
                                      </div>
                                      <h4>{this.state.multisite.parent_app_name}</h4>
                                      <h5>ID: {this.state.multisite.parent_app_id
                                      }</h5>
                                  </div>

                                  <img src={require("../../assets/images/dashboard/download-merge.png").default} alt="download-img" className="img-fluid" />
                                  <div className="child-app">
                                      <div className="download-child-tag">
                                          <h3>Merged app</h3>
                                      </div>                                     
                                      <div className="download-appicon">
                                          <img src={
                                                    process.env.REACT_APP_Image_Path +
                                                    localStorage.getItem("user_id") +
                                                    "/" +
                                                    localStorage.getItem("Appid") +
                                                    process.env.REACT_APP_AppIcon_Display +
                                                    localStorage.getItem("Appicon")
                                                  }
                                                  onError={({ currentTarget }) => {
                                                    currentTarget.src =
                                                      require("../../assets/images/dashboard/app-dashboard.png").default;
                                                  }} 
                                            alt="download-img" className="img-fluid" />
                                      </div>
                                      <h4>{localStorage.getItem('Appname')}</h4>
                                      <h5>ID: {localStorage.getItem('Appid')}</h5>
                                  </div>

                              </div>

                              <h1>This app is already merged</h1>
                              <h2>This app appears to be merged with another app.  To manage this feature, navigate to the primary app where this feature was enabled. Alternatively, click Unlink to disconnect this app.</h2>
                              <div className="download-merged-btns">
                                  <a href="/apps">My App</a>
                                  <a href={void(0)} onClick={this.handleunlink}>Unlink</a>
                              </div>
                          </div>
                      </div> 
                    </>
                  :
                    <>

                          <div className="download-build">

                              <div className="download-build-section download-build-left">
                              <svg className="the-download-svg" xmlns="http://www.w3.org/2000/svg" width="43.271" height="52" viewBox="0 0 43.271 52">
                                      <path id="Union_2846" data-name="Union 2846" d="M18991.043-14249.807v-8.4h-4.814v8.4a3.025,3.025,0,0,1-3.205,2.807,3.021,3.021,0,0,1-3.2-2.807v-8.4h-.021a4.8,4.8,0,0,1-4.811-4.8v-19.187h27.248V-14263a4.8,4.8,0,0,1-4.793,4.8v8.4a3.023,3.023,0,0,1-3.2,2.807A3.023,3.023,0,0,1,18991.043-14249.807Zm12.824-17.583v-11.994a3.02,3.02,0,0,1,3.2-2.8,3.022,3.022,0,0,1,3.2,2.8v11.994a3.023,3.023,0,0,1-3.2,2.8A3.024,3.024,0,0,1,19003.867-14267.39Zm-36.865,0v-11.994a3.022,3.022,0,0,1,3.2-2.8,3.021,3.021,0,0,1,3.205,2.8v11.994a3.022,3.022,0,0,1-3.205,2.8A3.019,3.019,0,0,1,18967-14267.39Zm7.957-16.254a13.42,13.42,0,0,1,6.967-10.754l-2.277-3.934a.487.487,0,0,1,.2-.594.486.486,0,0,1,.617.112l2.307,4a14.27,14.27,0,0,1,11.705,0l2.3-4a.464.464,0,0,1,.289-.169.471.471,0,0,1,.455.169.485.485,0,0,1,.078.482l-2.279,3.942a13.446,13.446,0,0,1,6.975,10.754Zm18.814-5.033a1.147,1.147,0,0,0,1.141,1.142,1.137,1.137,0,0,0,1.139-1.142,1.139,1.139,0,0,0-1.143-1.141A1.144,1.144,0,0,0,18993.773-14288.677Zm-12.25-.806a1.145,1.145,0,0,0-.244,1.241,1.153,1.153,0,0,0,1.051.706,1.137,1.137,0,0,0,1.1-.844,1.141,1.141,0,0,0-.527-1.282,1.165,1.165,0,0,0-.574-.152A1.129,1.129,0,0,0,18981.523-14289.482Z" transform="translate(-18967.002 14299)" fill="#00de7a"/>
                                      </svg>
                                  <div className="download-build-section-one">
                                  <div>
                                      <h2>Android app</h2>
                                      {this.state.hideandroidbuildappbutton === false &&
                                            (this.state.allowed_module1 === "all" || this.state.allowed_module1 === "android" ? 
                                              (
                                                <>
                                                { this.state.builder_status.status === 1 || this.state.builder_status.status === 3 ?
                                                <h3>Complete your build configuration and download your app build.</h3>
                                                :
                                                <h3>Complete your build configuration and download your app build.</h3>
                                                }
                                                </>
                                              )
                                              : (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                              localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                              localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ? 
                                              (
                                                <h3>You are currently on the Preview plan. Upgrade your plan to configure and download your app build.</h3>
                                              )
                                              : null
                                            )
                                      }
                                       {this.state.downloadbuild === true && (
                                              <h3>Access your most recent build here or rebuild if required.</h3>
                                          )}
                                        {this.state.androidbuildspinner &&
                                        (
                                          <h3>Complete your build configuration and download your app build.</h3>
                                        )}
                                        {this.state.androidstatus && (this.state.androidstatus.build_status == 2) &&
                                         (
                                          <h3>Complete your build configuration and download your app build.</h3>
                                        )}
                                      
                                  </div>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="43.271" height="52" viewBox="0 0 43.271 52">
                                      <path id="Union_2846" data-name="Union 2846" d="M18991.043-14249.807v-8.4h-4.814v8.4a3.025,3.025,0,0,1-3.205,2.807,3.021,3.021,0,0,1-3.2-2.807v-8.4h-.021a4.8,4.8,0,0,1-4.811-4.8v-19.187h27.248V-14263a4.8,4.8,0,0,1-4.793,4.8v8.4a3.023,3.023,0,0,1-3.2,2.807A3.023,3.023,0,0,1,18991.043-14249.807Zm12.824-17.583v-11.994a3.02,3.02,0,0,1,3.2-2.8,3.022,3.022,0,0,1,3.2,2.8v11.994a3.023,3.023,0,0,1-3.2,2.8A3.024,3.024,0,0,1,19003.867-14267.39Zm-36.865,0v-11.994a3.022,3.022,0,0,1,3.2-2.8,3.021,3.021,0,0,1,3.205,2.8v11.994a3.022,3.022,0,0,1-3.205,2.8A3.019,3.019,0,0,1,18967-14267.39Zm7.957-16.254a13.42,13.42,0,0,1,6.967-10.754l-2.277-3.934a.487.487,0,0,1,.2-.594.486.486,0,0,1,.617.112l2.307,4a14.27,14.27,0,0,1,11.705,0l2.3-4a.464.464,0,0,1,.289-.169.471.471,0,0,1,.455.169.485.485,0,0,1,.078.482l-2.279,3.942a13.446,13.446,0,0,1,6.975,10.754Zm18.814-5.033a1.147,1.147,0,0,0,1.141,1.142,1.137,1.137,0,0,0,1.139-1.142,1.139,1.139,0,0,0-1.143-1.141A1.144,1.144,0,0,0,18993.773-14288.677Zm-12.25-.806a1.145,1.145,0,0,0-.244,1.241,1.153,1.153,0,0,0,1.051.706,1.137,1.137,0,0,0,1.1-.844,1.141,1.141,0,0,0-.527-1.282,1.165,1.165,0,0,0-.574-.152A1.129,1.129,0,0,0,18981.523-14289.482Z" transform="translate(-18967.002 14299)" fill="#00de7a"/>
                                      </svg>
                                  </div>
                                
                                  <div className="download-build-section-two">
                                    {this.state.androidbuildspinner === false ?
                                    <>
                                      {this.state.androidstatus && (this.state.androidstatus.build_status == 2) &&
                                      (
                                        <>
                                            {((localStorage.getItem("plan_name") !== "preview") && ((localStorage.getItem("plan_name")) !== "paddle_preview")
                                              && ((localStorage.getItem("plan_name")) !== "Free") && ((localStorage.getItem("plan_name")) !== "free") 
                                            ) ?
                                              <Link
                                              to="/app/download/android-build-settings"
                                              className={"download-apk"}
                                              style={{
                                                backgroundColor:localStorage.getItem('button_background_color'),
                                                color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                              }}
                                            >
                                              <>Build app</>
                                              </Link>
                                            : (
                                              (this.state.success_build_count <  this.state.total_build_count) &&
                                              (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                              localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                              localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1)
                                              ) ?
                                                <Link
                                                to="/app/download/android-build-settings"
                                                className={"download-apk"}
                                                style={{
                                                  backgroundColor:localStorage.getItem('button_background_color'),
                                                  color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                                }}
                                              >
                                                <>Build app</>
                                                </Link>
                                            : <></>
                                            }
                                        </>
                                      )}
                                      
                                      {this.state.hideandroidbuildappbutton === false &&
                                            (this.state.allowed_module1 === "all" || this.state.allowed_module1 === "android" ? 
                                              (
                                                <>
                                                { this.state.builder_status.status === 1 || this.state.builder_status.status === 3 ?

                                                  <>
                                                  {((localStorage.getItem("plan_name") !== "preview") && ((localStorage.getItem("plan_name")) !== "paddle_preview")
                                                      && ((localStorage.getItem("plan_name")) !== "Free") && ((localStorage.getItem("plan_name")) !== "free") 
                                                    ) 
                                                  ?
                                                    <Link
                                                      to="/app/download/android-build-settings"
                                                      className="download-apk"
                                                      style={{
                                                        backgroundColor:localStorage.getItem('button_background_color'),
                                                        // border:'1px solid '+localStorage.getItem('button_background_color'),
                                                        color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                                      }}
                                                      // onClick={(e) => this.createbuild("android")}
                                                    >
                                                      {this.state.builder_status.status === 1 ||
                                                      this.state.builder_status.status === 3 ? (
                                                        <>
                                                          Build app
                                                          <span
                                                            className={
                                                              "new-hover-element-text custom-hover-btn"
                                                            }
                                                          >
                                                            {this.state.builder_status.title}
                                                            <p>
                                                              {this.state.builder_status.description}
                                                            </p>
                                                          </span>
                                                        </>
                                                      ) : (
                                                        <>Build app</>
                                                      )}
                                                    </Link>
                                                  : (
                                                    (this.state.success_build_count <  this.state.total_build_count) &&
                                                    (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                                    localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                                    localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1)
                                                  ) ?
                                                      <Link
                                                      to="/app/download/android-build-settings"
                                                      className="download-apk"
                                                      style={{
                                                        backgroundColor:localStorage.getItem('button_background_color'),
                                                        // border:'1px solid '+localStorage.getItem('button_background_color'),
                                                        color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                                      }}
                                                      // onClick={(e) => this.createbuild("android")}
                                                    >
                                                      {this.state.builder_status.status === 1 ||
                                                      this.state.builder_status.status === 3 ? (
                                                        <>
                                                          Build app
                                                          <span
                                                            className={
                                                              "new-hover-element-text custom-hover-btn"
                                                            }
                                                          >
                                                            {this.state.builder_status.title}
                                                            <p>
                                                              {this.state.builder_status.description}
                                                            </p>
                                                          </span>
                                                        </>
                                                      ) : (
                                                        <>Build app</>
                                                      )}
                                                    </Link>

                                                  : <></>
                                                  }
                                                  </>
                                                :
                                                  <>
                                                    {((localStorage.getItem("plan_name") !== "preview") && ((localStorage.getItem("plan_name")) !== "paddle_preview")
                                                        && ((localStorage.getItem("plan_name")) !== "Free") && ((localStorage.getItem("plan_name")) !== "free") 
                                                      ) 
                                                    ?
                                                      <Link
                                                        to="/app/download/android-build-settings"
                                                        className={"download-apk"}
                                                        style={{backgroundColor:localStorage.getItem('button_background_color'),
                                                        // border:'1px solid '+localStorage.getItem('button_background_color'),
                                                        color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                                        // onClick={(e) => this.createbuild("android")}
                                                      >
                                                        {this.state.builder_status.status === 1 ||
                                                        this.state.builder_status.status === 3 ? (
                                                          <>
                                                            Build app
                                                            <span
                                                              className={
                                                                "new-hover-element-text custom-hover-btn"
                                                              }
                                                            >
                                                              {this.state.builder_status.title}
                                                              <p>
                                                                {this.state.builder_status.description}
                                                              </p>
                                                            </span>
                                                          </>
                                                        ) : (
                                                          <>Build app</>
                                                        )}
                                                      </Link>
                                                    : (
                                                        (this.state.success_build_count <  this.state.total_build_count) &&
                                                        (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                                        localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                                        localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1)
                                                      ) ?
                                                        <Link
                                                        to="/app/download/android-build-settings"
                                                        className={"download-apk"}
                                                        style={{backgroundColor:localStorage.getItem('button_background_color'),
                                                        // border:'1px solid '+localStorage.getItem('button_background_color'),
                                                        color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                                        // onClick={(e) => this.createbuild("android")}
                                                      >
                                                        {this.state.builder_status.status === 1 ||
                                                        this.state.builder_status.status === 3 ? (
                                                          <>
                                                            Build app
                                                            <span
                                                              className={
                                                                "new-hover-element-text custom-hover-btn"
                                                              }
                                                            >
                                                              {this.state.builder_status.title}
                                                              <p>
                                                                {this.state.builder_status.description}
                                                              </p>
                                                            </span>
                                                          </>
                                                        ) : (
                                                          <>Build app</>
                                                        )}
                                                      </Link>
                                                    : <></>
                                                    }
                                                  </>
                                                }
                                                </>
                                              )
                                              : (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                              localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                              localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ? 
                                              (
                                                <Link
                                                  to={
                                                    this.state.zoho_plan_code.toString() ===
                                                    process.env.REACT_APP_Lifetime_preview
                                                      ? "/deals"
                                                      : this.state.urlvar
                                                  }
                                                  style={{
                                                    backgroundColor:localStorage.getItem('button_background_color'),
                                                    // border:'1px solid '+localStorage.getItem('button_background_color'),
                                                    color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                                  }}
                                                  className="download-apk"
                                                >
                                                  Upgrade
                                                </Link>
                                              )
                                              : null
                                            )
                                      }
                                      {/* <a className="download-apk d-none" href="">Download APK</a> */}
                                          {this.state.downloadbuild === true && (
                                              <a
                                                href={process.env.REACT_APP_Image_Path + this.state.buildurl}
                                                style={{backgroundColor:localStorage.getItem('button_background_color'),
                                                // border:'1px solid '+localStorage.getItem('button_background_color'),
                                                color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                                className={styles.resizebutton + " download-apk"} download
                                              >
                                                Download APK
                                              </a>
                                          )}
                                      {/* <a className="download-aab" href="">Download AAB</a> */}
                                      {/* Android App bundle */}
                                      {this.state.downloadbuild === true && (
                                              <>
                                                {this.state.aabfile !== null && (
                                                  <>
                                                      <a href={process.env.REACT_APP_Image_Path + this.state.aabfile}
                                                        className="download-aab"
                                                        download
                                                        // style={{ width: "168px" }}
                                                        style={{width: "168px",
                                                          // backgroundColor:localStorage.getItem('button_background_color'),
                                                          border:'1px solid '+localStorage.getItem('button_background_color'),
                                                          color:localStorage.getItem('button_background_color'),
                                                          "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                                          "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                                                          "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                                          }}
                                                      >
                                                        Download AAB
                                                      </a>
                                                  
                                                  </>
                                                )}
                                              </>
                                            
                                      )}
                                    </>
                                    : <></>
                                    }
                                  </div>

                                
                                  {this.state.androidbuildspinner && 
                                  (
                                    <div className="download-build-section-four">
                                    {this.state.triggerautomatic === true ?
                                      <>
                                        {this.state.Initialising_step_number == 1 ?
                                          <>
                                          <h4 attr="1">Saving build settings...</h4>
                                          {/* <progress id="file" max="100" value="0" ></progress> */}
                                          <div class="our-progress">
    <progress id="file" max="100" value="0" ></progress>
    <div class="shimmer" style={{width: "0%"}}></div>
</div>
                                          <div>
                                            <h5>10 minutes estimated</h5>
                                            <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                            </svg>
                                            Cancel
                                          </h6>
                                        </div>
                                        </>
                                        : this.state.Initialising_step_number == 2 ?
                                        <>
                                        <h4 attr="1">Validating app subscription...</h4>
                                        {/* <progress id="file" max="100" value="5" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="5" ></progress>
    <div class="shimmer" style={{width: "5%"}}></div>
</div>
                                        <div>
                                          <h5>10 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                            </svg>
                                            Cancel
                                          </h6>
                                        </div>
                                        </>
                                        : this.state.Initialising_step_number == 3 ?
                                        <>
                                        <h4 attr="1">Validating bundle information...</h4>
                                        <progress id="file" max="100" value="8" className="shimmer"></progress>
                                        <div class="our-progress">
    <progress id="file" max="100" value="8" ></progress>
    <div class="shimmer" style={{width: "8%"}}></div>
</div>
                                        <div>
                                          <h5>10 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                            </svg>
                                            Cancel
                                          </h6>
                                        </div>
                                        </>
                                        : this.state.Initialising_step_number == 4 ?
                                        <>
                                        <h4 attr="1">Fetching latest app code...</h4>
                                        {/* <progress id="file" max="100" value="10" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="10" ></progress>
    <div class="shimmer" style={{width: "10%"}}></div>
</div>
                                        <div>
                                          <h5>9 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                            </svg>
                                            Cancel
                                          </h6>
                                        </div>
                                        </>
                                        : this.state.Initialising_step_number == 5 ?
                                        <>
                                        <h4 attr="1">Cloning app code...</h4>
                                        {/* <progress id="file" max="100" value="13" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="13" ></progress>
    <div class="shimmer" style={{width: "13%"}}></div>
</div>
                                        <div>
                                          <h5>9 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                            </svg>
                                            Cancel
                                          </h6>
                                        </div>
                                        </>
                                        : this.state.Initialising_step_number == 6 ?
                                        <>
                                        <h4 attr="1">Cloning app code...</h4>
                                        {/* <progress id="file" max="100" value="16" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="16" ></progress>
    <div class="shimmer" style={{width: "16%"}}></div>
</div>
                                        <div>
                                          <h5>9 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                            </svg>
                                            Cancel
                                          </h6>
                                        </div>
                                        </>
                                        : this.state.Initialising_step_number == 7 ?
                                        <>
                                        <h4 attr="1">Cloning app code...</h4>
                                        {/* <progress id="file" max="100" value="18" className="shimmer" ></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="18" ></progress>
    <div class="shimmer" style={{width: "18%"}}></div>
</div>
                                        <div>
                                          <h5>9 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                            </svg>
                                            Cancel
                                          </h6>
                                        </div>
                                        </>
                                        : this.state.Initialising_step_number == 8 ?
                                        <>
                                        <h4 attr="1">Validating build configurations...</h4>
                                        {/* <progress id="file" max="100" value="20" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="20" ></progress>
    <div class="shimmer" style={{width: "20%"}}></div>
</div>
                                        <div>
                                          <h5>8 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                            </svg>
                                            Cancel
                                          </h6>
                                        </div>
                                        </>
                                        : this.state.Initialising_step_number == 9 ?
                                        <>
                                        <h4 attr="1">Applying app configurations...</h4>

                                        <div class="our-progress">
    <progress id="file" max="100" value="22" ></progress>
    <div class="shimmer" style={{width: "22%"}}></div>
</div>

                                        {/* <progress id="file" max="100" value="22" className=""></progress> */}
                                        <div>
                                          <h5>7 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                            </svg>
                                            Cancel
                                          </h6>
                                        </div>
                                        </>
                                        : this.state.Initialising_step_number == 10 ?
                                        <>
                                        <h4 attr="1">Initiating app build...</h4>
                                        {/* <progress id="file" max="100" value="25" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="25" ></progress>
    <div class="shimmer" style={{width: "25%"}}></div>
</div>
                                        <div>
                                          <h5>7 minutes estimated </h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                            </svg>
                                            Cancel
                                          </h6>
                                        </div>
                                        </>
                                        : this.state.Initialising_step_number == 11 ?
                                        <>
                                        <h4 attr="1">Initiating app build...</h4>
                                        <div class="our-progress">
    <progress id="file" max="100" value="25" ></progress>
    <div class="shimmer" style={{width: "25%"}}></div>
</div>
                                        {/* <progress id="file" max="100" value="25" className="shimmer"></progress> */}
                                        <div>
                                          <h5>7 minutes estimated </h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                            </svg>
                                            Cancel
                                          </h6>
                                        </div>
                                        </>
                                        :
                                          <>
                                          <h4 attr="1">Saving build settings...</h4>
                                          {/* <progress id="file" max="100" value="0"></progress> */}
                                          <div class="our-progress">
    <progress id="file" max="100" value="0" ></progress>
    <div class="shimmer" style={{width: "0%"}}></div>
</div>
                                          <div> 
                                          <h5>10 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                              <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                              </svg>
                                              Cancel
                                            </h6>
                                          </div>
                                          </>
                                        }
                                      </>
                                        
                                        
                                      
                                    : ( this.state.is_android_build_on_hold === 0 &&
                                        this.state.android_bitrise_build_slug !== "") ? 
                                      <> 
                                        {this.state.andorid_step_number == 1 ?
                                          <>
                                          <h4 attr="2">Initiating app build...</h4>
                                          {/* <progress id="file" max="100" value="30" className="shimmer"></progress> */}
                                          <div class="our-progress">
    <progress id="file" max="100" value="30" ></progress>
    <div class="shimmer" style={{width: "30%"}}></div>
</div>
                                          <div>
                                          <h5>7 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.andorid_step_number == 2 ?
                                        <>
                                        <h4 attr="2">Preparing app build...</h4>
                                        {/* <progress id="file" max="100" value="35" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="35" ></progress>
    <div class="shimmer" style={{width: "35%"}}></div>
</div>
                                        <div>
                                          <h5>6 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.andorid_step_number == 3 ?
                                        <>
                                        <h4 attr="2">Preparing app build...</h4>
                                        {/* <progress id="file" max="100" value="40" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="40" ></progress>
    <div class="shimmer" style={{width: "40%"}}></div>
</div>
                                        <div>
                                          <h5>6 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.andorid_step_number == 4 ?
                                        <>
                                        <h4 attr="2">Preparing app build...</h4>
                                        {/* <progress id="file" max="100" value="45" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="45" ></progress>
    <div class="shimmer" style={{width: "45%"}}></div>
</div>
                                        <div>
                                          <h5>6 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.andorid_step_number == 5 ?
                                        <>
                                        <h4 attr="2">Preparing app build...</h4>
                                        {/* <progress id="file" max="100" value="50" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="50" ></progress>
    <div class="shimmer" style={{width: "50%"}}></div>
</div>
                                        <div>
                                          <h5>6 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.andorid_step_number == 6 ?
                                        <>
                                        <h4 attr="2">Processing app build...</h4>
                                        {/* <progress id="file" max="100" value="60" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="60" ></progress>
    <div class="shimmer" style={{width: "60%"}}></div>
</div>
                                        <div>
                                          <h5>5 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.andorid_step_number == 7 ?
                                        <>
                                        <h4 attr="2">Processing app build...</h4>
                                        {/* <progress id="file" max="100" value="65" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="65" ></progress>
    <div class="shimmer" style={{width: "65%"}}></div>
</div>
                                        <div>
                                          <h5>5 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.andorid_step_number == 8 ?
                                        <>
                                        <h4 attr="2">Processing app build...</h4>
                                        {/* <progress id="file" max="100" value="70" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="70" ></progress>
    <div class="shimmer" style={{width: "70%"}}></div>
</div>
                                        <div>
                                          <h5>5 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.andorid_step_number == 9 ?
                                        <>
                                        <h4 attr="2">Completing app build...</h4>
                                        {/* <progress id="file" max="100" value="72" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="72" ></progress>
    <div class="shimmer" style={{width: "72%"}}></div>
</div>
                                        <div>
                                          <h5>2 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.andorid_step_number == 10 ?
                                        <>
                                        <h4 attr="2">Completing app build...</h4>
                                        {/* <progress id="file" max="100" value="75" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="75" ></progress>
    <div class="shimmer" style={{width: "75%"}}></div>
</div>
                                        <div>
                                          <h5>2 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : <h5></h5>
                                        } 
                                      </>
                                    : ( this.state.is_android_build_on_hold === 1 &&
                                        this.state.android_bitrise_build_slug !== "") ?
                                        <>
                                        <h4 attr="3">Your build has entered the queue...</h4> 
                                        {/* <progress id="file" max="100" value="80" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="80" ></progress>
    <div class="shimmer" style={{width: "80%"}}></div>
</div>
                                        <div>
                                        <h5>4 minutes estimated</h5>
                                        <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                    : 
                                    <>
                                    {this.state.Initialising_step_number == 1 ?
                                      <>
                                      <h4 attr="1">Saving build settings...</h4>
                                      {/* <progress id="file" max="100" value="0"></progress> */}
                                      <div class="our-progress">
    <progress id="file" max="100" value="0" ></progress>
    <div class="shimmer" style={{width: "0%"}}></div>
</div>
                                      <div>
                                        <h5>10 minutes estimated</h5>
                                        <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.Initialising_step_number == 2 ?
                                    <>
                                    <h4 attr="1">Validating app subscription...</h4>
                                    {/* <progress id="file" max="100" value="5" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="5" ></progress>
    <div class="shimmer" style={{width: "5%"}}></div>
</div>
                                    <div>
                                      <h5>10 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.Initialising_step_number == 3 ?
                                    <>
                                    <h4 attr="1">Validating bundle information...</h4>
                                    {/* <progress id="file" max="100" value="8" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="8" ></progress>
    <div class="shimmer" style={{width: "8%"}}></div>
</div>
                                    <div>
                                      <h5>10 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.Initialising_step_number == 4 ?
                                    <>
                                    <h4 attr="1">Fetching latest app code...</h4>
                                    {/* <progress id="file" max="100" value="10" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="10" ></progress>
    <div class="shimmer" style={{width: "10%"}}></div>
</div>
                                    <div>
                                      <h5>9 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.Initialising_step_number == 5 ?
                                    <>
                                    <h4 attr="1">Cloning app code...</h4>
                                    {/* <progress id="file" max="100" value="13" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="13" ></progress>
    <div class="shimmer" style={{width: "13%"}}></div>
</div>
                                    <div>
                                      <h5>9 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.Initialising_step_number == 6 ?
                                    <>
                                    <h4 attr="1">Cloning app code...</h4>
                                    {/* <progress id="file" max="100" value="16" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="16" ></progress>
    <div class="shimmer" style={{width: "16%"}}></div>
</div>
                                    <div>
                                      <h5>9 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.Initialising_step_number == 7 ?
                                    <>
                                    <h4 attr="1">Cloning app code...</h4>
                                    {/* <progress id="file" max="100" value="18" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="18" ></progress>
    <div class="shimmer" style={{width: "18%"}}></div>
</div>
                                    <div>
                                      <h5>9 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.Initialising_step_number == 8 ?
                                    <>
                                    <h4 attr="1">Validating build configurations...</h4>
                                    {/* <progress id="file" max="100" value="20" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="20" ></progress>
    <div class="shimmer" style={{width: "20%"}}></div>
</div>
                                    <div>
                                      <h5>8 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.Initialising_step_number == 9 ?
                                    <>
                                    <h4 attr="1">Applying app configurations...</h4>
                                    {/* <progress id="file" max="100" value="22" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="22" ></progress>
    <div class="shimmer" style={{width: "22%"}}></div>
</div>
                                    <div>
                                      <h5>7 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.Initialising_step_number == 10 ?
                                    <>
                                    <h4 attr="1">Initiating app build...</h4>
                                    {/* <progress id="file" max="100" value="25" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="25" ></progress>
    <div class="shimmer" style={{width: "25%"}}></div>
</div>
                                    <div>
                                      <h5>7 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.Initialising_step_number == 11 ?
                                    <>
                                    <h4 attr="1">Initiating app build...</h4>
                                    {/* <progress id="file" max="100" value="25" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="25" ></progress>
    <div class="shimmer" style={{width: "25%"}}></div>
</div>
                                    <div>
                                      <h5>7 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    :
                                      <>
                                      <h4 attr="1">Initialising...</h4>
                                      {/* <progress id="file" max="100" value="0" ></progress> */}
                                      <div class="our-progress">
    <progress id="file" max="100" value="0" ></progress>
    <div class="shimmer" style={{width: "0%"}}></div>
</div>
                                      <div> 
                                      <h5>10 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("android")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                      </>
                                    }
                                  </>
                                    
                                    
                                    }
                                      
                                     
                                    </div>
                                  )}

                                  <div className="download-build-section-three">
                                      <div className="build-section-one">
                                          <div>
                                              <h3>Version:</h3>
                                              {this.state.android_build_version != '' && this.state.android_build_version !== undefined && this.state.android_build_version !== null ? (
                                              <h4>{this.state.android_build_version}</h4>
                                              ) 
                                              : 
                                              (
                                                  <h4 >-</h4>
                                              )}
                                          </div>
                                          {this.state.allowed_module1 === "all" || this.state.allowed_module1 === "android" 
                                          ? 
                                              (this.state.android_latest_version !== null && this.state.android_build_version !== null 
                                                  ? (this.versionCompare(this.state.android_latest_version,this.state.android_build_version)) 
                                                  : this.state.android_build_version !== null 
                                                  ? ( <h5>New version available</h5> ) 
                                                  : ( <h5></h5> )
                                              ) 
                                          :   (
                                                  "-"
                                              )}
                                      </div>
                                      <div className="build-section-one build-section-two 1">
                                          <div>
                                              <h3>Build:</h3>
                                              <h4 className={this.state.androidstatus && (this.state.androidstatus.build_status == 1 || this.state.androidstatus.build_status == 4) 
                                                            ? "build-complete" 
                                                            : this.state.androidstatus && (this.state.androidstatus.build_status == 2)
                                                            ? "build-incomplete"
                                                            : "build-draft" }>{this.Checkandroidstatus(this.state.androidstatus)}
                                                              {this.state.android_error_description && this.state.android_error_description != '' 
                                                              ? <span onClick={() => this.handleErrorPopUp(this.state.android_error_description)}>View error</span> 
                                                              : '' }
                                              </h4>
                                          </div>

                                           <div className="buildlimit">
                                                  {
                                                  (
                                                    (localStorage.getItem("plan_name") !== "preview") && ((localStorage.getItem("plan_name")) !== "paddle_preview")
                                                    && ((localStorage.getItem("plan_name")) !== "Free") && ((localStorage.getItem("plan_name")) !== "free") 
                                                  ) ?
                                                    <></>
                                                  : (this.state.success_build_count >  this.state.total_build_count) &&
                                                    (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                                    localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                                    localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ?
                                                    <p className="builds-used-web">Build limit reached</p>
                                                  : (this.state.success_build_count <=  this.state.total_build_count) && 
                                                    (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                                     localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                                     localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ?
                                                    <p className="builds-used-web">{this.state.success_build_count+'/'+this.state.total_build_count} <span>builds used</span></p>
                                                  : <></>
                                                  }

                                                 
                                                  {this.state.allowed_module1 === "all" || this.state.allowed_module1 === "android" ? 
                                                  (
                                                    this.state.downloadbuild === true && (
                                                      <>
                                                    <div> 
                                                      <Link
                                                      to="#"
                                                      onClick={this.androidredirect}
                                                      style={{
                                                        // backgroundColor:localStorage.getItem('button_background_color'),
                                                        border:'1px solid '+localStorage.getItem('button_background_color'),
                                                        color:localStorage.getItem('button_background_color'),
                                                        "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                                        "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                                                        "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                                        }}
                                                        className={ this.state.builder_status.status === 1 ||
                                                                    this.state.builder_status.status === 3
                                                                  ? "rebuild_new_disable"
                                                                  : "d-md-block rebuild_new 3"
                                                                  }
                                                        // onClick={(e) => this.createbuild("android")}
                                                      >
                                                        
                                                      { this.state.builder_status.status === 1 ||
                                                        this.state.builder_status.status === 3 ? (
                                                          <div className="mb-1 new-hover-element" >
                                                            Rebuild
                                                            <span
                                                              className={"new-hover-element-text"}
                                                            >
                                                              {this.state.builder_status.title}
                                                              <p>
                                                                {
                                                                  this.state.builder_status
                                                                    .description
                                                                }
                                                              </p>
                                                            </span>
                                                          </div>
                                                        ) 
                                                      : 
                                                      (
                                                          <>Rebuild</>
                                                      )}
                                                      </Link></div>
                                                      </>
                                                    )
                                                  ) 
                                                  : 
                                                  (
                                                    <>
                                                    
                                                    </>
                                                  )}

{this.state.rebuildapp === true ? 
                                              (
                                                <Link to={"#"} onClick={this.androidredirect}
                                                style={{
                                                  // backgroundColor:localStorage.getItem('button_background_color'),
                                                  border:'1px solid '+localStorage.getItem('button_background_color'),
                                                  color:localStorage.getItem('button_background_color'),
                                                  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                                  "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                                                  "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                                  }}
                                                     className={this.state.builder_status.status === 1 ||
                                                                this.state.builder_status.status === 3
                                                                  ? "rebuild_new_disable"
                                                                  : "d-md-block rebuild_new 1"
                                                                }
                                                      // onClick={(e) => this.createbuild("android")}
                                                >
                                                  {this.state.builder_status.status === 1 || this.state.builder_status.status === 3 
                                                      ?
                                                      (
                                                        <div className="mb-1 new-hover-element"    >
                                                          Rebuild
                                                          <span className={"new-hover-element-text"}>
                                                            {this.state.builder_status.title}
                                                            <p>
                                                              {this.state.builder_status.description}
                                                            </p>
                                                          </span>
                                                        </div>
                                                      ) 
                                                      : 
                                                      (
                                                        "Rebuild"
                                                      )
                                                      }
                                                </Link>
                                              )
                                            : <>
                                             
                                            </>
                                          }

                                          {this.state.addonrebuildapp === true && 
                                              (
                                                  <Link  
                                                  style={{
                                                    // backgroundColor:localStorage.getItem('button_background_color'),
                                                    border:'1px solid '+localStorage.getItem('button_background_color'),
                                                    color:localStorage.getItem('button_background_color'),
                                                    "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                                    "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                                                    "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                                    }}
                                                  to="#" onClick={this.androidredirect}
                                                        className={this.state.builder_status.status === 1 || this.state.builder_status.status === 3
                                                                ? "rebuild_new_disable"
                                                                : "d-md-block rebuild_new 2"
                                                                }
                                                      // onClick={(e) => this.createbuild("android")}
                                                  >
                                                  {this.state.builder_status.status === 1 || this.state.builder_status.status === 3 
                                                  ? 
                                                  (
                                                    <div className="mb-1 new-hover-element"    >
                                                      Rebuild
                                                      <span className={"new-hover-element-text"}>
                                                        {this.state.builder_status.title}
                                                        <p>
                                                          {this.state.builder_status.description}
                                                        </p>
                                                      </span>
                                                    </div>
                                                  ) 
                                                  : 
                                                  (
                                                    <>Rebuild</>
                                                  )}
                                                </Link>
                                              )}
                                              </div>



                                             

                                              {
                                                  (
                                                    (localStorage.getItem("plan_name") !== "preview") && ((localStorage.getItem("plan_name")) !== "paddle_preview")
                                                    && ((localStorage.getItem("plan_name")) !== "Free") && ((localStorage.getItem("plan_name")) !== "free") 
                                                  ) ?
                                                    <></>
                                                  : (this.state.success_build_count >  this.state.total_build_count) &&
                                                    (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                                    localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                                    localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ?
                                                    <p className="builds-used-mobile">Build limit reached</p>
                                                  :  (this.state.success_build_count <=  this.state.total_build_count) && 
                                                     (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                                     localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                                     localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) 
                                                  ?
                                                     <p className="builds-used-mobile">{this.state.success_build_count+'/'+this.state.total_build_count} <span>builds used</span></p>
                                                  : <></>
                                              } 
                                      </div>

                                     
                                  </div>

                              </div>

                              <div className="download-build-section download-build-right">

                              <svg className="the-download-svg" id="apple-logo" xmlns="http://www.w3.org/2000/svg" width="39.694" height="47.418" viewBox="0 0 39.694 47.418">
                                  <g id="Group_40146" data-name="Group 40146">
                                      <path id="Path_104606" data-name="Path 104606" d="M73.884,127.666a10.85,10.85,0,0,1-1.966-6.354,10.154,10.154,0,0,1,1.852-5.9,18.465,18.465,0,0,1,3.249-3.362,14.7,14.7,0,0,0-2.964-2.849,10.419,10.419,0,0,0-6.07-1.852,16.081,16.081,0,0,0-4.929.969,14.27,14.27,0,0,1-4.075.969,18.331,18.331,0,0,1-3.933-.855A18.958,18.958,0,0,0,50,107.578a9.838,9.838,0,0,0-8.093,4.1,16.907,16.907,0,0,0-3.22,10.658,29.214,29.214,0,0,0,4.189,14.333q4.246,7.381,8.577,7.381a10.3,10.3,0,0,0,3.761-.969,10.983,10.983,0,0,1,4.047-.94,12.6,12.6,0,0,1,4.3.912,13.175,13.175,0,0,0,3.989.911q3.647,0,7.323-5.585a27.507,27.507,0,0,0,3.5-7.124A8.668,8.668,0,0,1,73.884,127.666Z" transform="translate(-38.692 -96.635)" fill="#666"/>
                                      <path id="Path_104607" data-name="Path 104607" d="M239.726,9a11.7,11.7,0,0,0,2.651-3.932A10.871,10.871,0,0,0,243.2,1.2c0-.076,0-.171-.014-.285s-.014-.209-.014-.285A2.752,2.752,0,0,1,243.1.313,2.646,2.646,0,0,0,243.032,0q-5.015,1.169-7.124,4.218a13,13,0,0,0-2.223,7.323,15.924,15.924,0,0,0,2.963-.484A8.075,8.075,0,0,0,239.726,9Z" transform="translate(-214.223 0)" fill="#666"/>
                                  </g>
                                  </svg>

                              <div className="download-build-section-one">
                                  <div>
                                      <h2>iOS app</h2>
                                      { this.state.iosbuildspinner === false && 
                                    this.state.hideiosbuildappbutton === false &&
                                  (this.state.allowed_module1 === "all" ? 
                                    
                                    (
                                     
                                    <>
                                    {this.state.builder_status.status === 2 || this.state.builder_status.status === 3 ?
                                      <h3>Complete your build configuration and download your app build.</h3>
                                    :
                                    <h3>Complete your build configuration and download your app build.</h3>
                                    }
                                    </>
                                   
                                    ) 
                                    : (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                    localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                    localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ? 
                                    (
                                      <>
                                      <h3>You are currently on the Preview plan. Upgrade your plan to configure and download your app build.</h3>
                                      </>
                                    )
                                    : null
                                  )
                                  }
                                      {/* <a className="download-apk d-none" href="">Download IPA</a> */}
                                      {this.state.downloadiosbuild === true && (
                                        <>
                                           <h3>Access your most recent build here or rebuild if required.</h3>
                                          
                                        </>
                                      )}

                                        {this.state.iosbuildspinner && (
                                        <h3>Complete your build configuration and download your app build.</h3>
                                        )}
                                        {this.state.buildiosstatus && (this.state.buildiosstatus.build_status === "2") &&
                                         (
                                          <h3>Complete your build configuration and download your app build.</h3>
                                        )}
                                  </div>
                                  <svg id="apple-logo" xmlns="http://www.w3.org/2000/svg" width="39.694" height="47.418" viewBox="0 0 39.694 47.418">
                                  <g id="Group_40146" data-name="Group 40146">
                                      <path id="Path_104606" data-name="Path 104606" d="M73.884,127.666a10.85,10.85,0,0,1-1.966-6.354,10.154,10.154,0,0,1,1.852-5.9,18.465,18.465,0,0,1,3.249-3.362,14.7,14.7,0,0,0-2.964-2.849,10.419,10.419,0,0,0-6.07-1.852,16.081,16.081,0,0,0-4.929.969,14.27,14.27,0,0,1-4.075.969,18.331,18.331,0,0,1-3.933-.855A18.958,18.958,0,0,0,50,107.578a9.838,9.838,0,0,0-8.093,4.1,16.907,16.907,0,0,0-3.22,10.658,29.214,29.214,0,0,0,4.189,14.333q4.246,7.381,8.577,7.381a10.3,10.3,0,0,0,3.761-.969,10.983,10.983,0,0,1,4.047-.94,12.6,12.6,0,0,1,4.3.912,13.175,13.175,0,0,0,3.989.911q3.647,0,7.323-5.585a27.507,27.507,0,0,0,3.5-7.124A8.668,8.668,0,0,1,73.884,127.666Z" transform="translate(-38.692 -96.635)" fill="#666"/>
                                      <path id="Path_104607" data-name="Path 104607" d="M239.726,9a11.7,11.7,0,0,0,2.651-3.932A10.871,10.871,0,0,0,243.2,1.2c0-.076,0-.171-.014-.285s-.014-.209-.014-.285A2.752,2.752,0,0,1,243.1.313,2.646,2.646,0,0,0,243.032,0q-5.015,1.169-7.124,4.218a13,13,0,0,0-2.223,7.323,15.924,15.924,0,0,0,2.963-.484A8.075,8.075,0,0,0,239.726,9Z" transform="translate(-214.223 0)" fill="#666"/>
                                  </g>
                                  </svg>

                                  </div>
                                  <div className="download-build-section-two">
                                      {this.state.buildiosstatus && (this.state.buildiosstatus.build_status === "2") &&
                                          (
                                            
                                              <Link
                                            to="/app/download/ios-build-settings"
                                            className={"download-apk"}
                                            style={{
                                              backgroundColor:localStorage.getItem('button_background_color'),
                                              color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                            }}
                                          >
                                            <>Build app</>
                                            
                                          </Link>
                                         
                                          )
                                      }
                                
                                      { this.state.iosbuildspinner === false && 
                                        this.state.hideiosbuildappbutton === false &&
                                      ( this.state.allowed_module1 === "all" ? 
                                        
                                        (
                                        <>
                                      
                                        {this.state.builder_status.status === 2 || this.state.builder_status.status === 3 ?
                                          <Link
                                            to="/app/download/ios-build-settings"
                                            className={"download-apk"}
                                            style={{
                                              backgroundColor:localStorage.getItem('button_background_color'),
                                              color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                            }}
                                          >
                                            {this.state.builder_status.status === 2 ||
                                            this.state.builder_status.status === 3 ? (
                                              <>
                                                Build app
                                                <span
                                                  className={
                                                    "new-hover-element-text custom-hover-btn"
                                                  }
                                                >
                                                  {this.state.builder_status.title}
                                                  <p>
                                                    {this.state.builder_status.description}
                                                  </p>
                                                </span>
                                              </>
                                            ) : (
                                              <>Build app</>
                                            )}
                                          </Link>
                                        :
                                        <Link
                                            to="/app/download/ios-build-settings"
                                            className="download-apk"
                                            style={{
                                              backgroundColor:localStorage.getItem('button_background_color'),
                                              color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                            }}
                                          >
                                            {this.state.builder_status.status === 2 ||
                                            this.state.builder_status.status === 3 ? (
                                              <>
                                                Build app
                                                <span
                                                  className={
                                                    "new-hover-element-text custom-hover-btn"
                                                  }
                                                >
                                                  {this.state.builder_status.title}
                                                  <p>
                                                    {this.state.builder_status.description}
                                                  </p>
                                                </span>
                                              </>
                                            ) : (
                                              <>Build app</>
                                            )}
                                          </Link>
                                        }
                                        </>
                                        ) 
                                      : (localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
                                        localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
                                        localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ? 
                                        (
                                        
                                          <Link
                                          style={{
                                            backgroundColor:localStorage.getItem('button_background_color'),
                                            // border:'1px solid '+localStorage.getItem('button_background_color'),
                                            color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                          }}
                                            to={ this.state.zoho_plan_code.toString() === process.env.REACT_APP_Lifetime_preview
                                                ? "/deals"
                                                : this.state.urlvar
                                              }
                                            className="download-apk 1"
                                          >
                                            Upgrade
                                          </Link>
                                        )
                                        : null
                                      )
                                      }

                                      {this.state.downloadiosbuild === true && (
                                        <>
                                          <a
                                            href={
                                              process.env.REACT_APP_Image_Path +
                                              this.state.buildiosurl
                                            }
                                            style={{backgroundColor:localStorage.getItem('button_background_color'),
                                            // border:'1px solid '+localStorage.getItem('button_background_color'),
                                            color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                                            className="download-apk"
                                            download
                                          >
                                            Download IPA
                                          </a>
                                          
                                        </>
                                        
                                      )}
                                  </div>
                                   
                                  {this.state.iosbuildspinner && (
                                <>
                                  <div className="download-build-section-four">
                                    {this.state.iostriggerautomatic === true ?
                                      
                                       <>
                                       {this.state.IOS_Initialising_step_number == 1 ?
                                         <>
                                         <h4 attr="1">Saving build settings...</h4>
                                         {/* <progress id="file" max="100" value="0"></progress> */}
                                         <div class="our-progress">
    <progress id="file" max="100" value="0" ></progress>
    <div class="shimmer" style={{width: "0%"}}></div>
</div>
                                         <div>
                                           <h5>10 minutes estimated</h5>
                                           <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                           <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                           </svg>
                                           Cancel
                                         </h6>
                                       </div>
                                       </>
                                       : this.state.IOS_Initialising_step_number == 2 ?
                                       <>
                                       <h4 attr="1">Validating app subscription...</h4>
                                       {/* <progress id="file" max="100" value="5" className="shimmer" ></progress> */}
                                       <div class="our-progress">
    <progress id="file" max="100" value="5" ></progress>
    <div class="shimmer" style={{width: "5%"}}></div>
</div>
                                       <div>
                                         <h5>10 minutes estimated</h5>
                                         <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                           <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                           </svg>
                                           Cancel
                                         </h6>
                                       </div>
                                       </>
                                       : this.state.IOS_Initialising_step_number == 3 ?
                                       <>
                                       <h4 attr="1">Validating bundle information...</h4>
                                       {/* <progress id="file" max="100" value="8" className="shimmer"></progress> */}
                                       <div class="our-progress">
    <progress id="file" max="100" value="8" ></progress>
    <div class="shimmer" style={{width: "8%"}}></div>
</div>
                                       <div>
                                         <h5>10 minutes estimated</h5>
                                         <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                           <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                           </svg>
                                           Cancel
                                         </h6>
                                       </div>
                                       </>
                                       : this.state.IOS_Initialising_step_number == 4 ?
                                       <>
                                       <h4 attr="1">Fetching latest app code...</h4>
                                       {/* <progress id="file" max="100" value="10" className="shimmer"></progress> */}
                                       <div class="our-progress">
    <progress id="file" max="100" value="10" ></progress>
    <div class="shimmer" style={{width: "10%"}}></div>
</div>
                                       <div>
                                         <h5>9 minutes estimated</h5>
                                         <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                           <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                           </svg>
                                           Cancel
                                         </h6>
                                       </div>
                                       </>
                                       : this.state.IOS_Initialising_step_number == 5 ?
                                       <>
                                       <h4 attr="1">Cloning latest app code...</h4>
                                       {/* <progress id="file" max="100" value="13" className="shimmer"></progress> */}
                                       <div class="our-progress">
    <progress id="file" max="100" value="13" ></progress>
    <div class="shimmer" style={{width: "13%"}}></div>
</div>
                                       <div>
                                         <h5>12 minutes estimated</h5>
                                         <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                           <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                           </svg>
                                           Cancel
                                         </h6>
                                       </div>
                                       </>
                                       : this.state.IOS_Initialising_step_number == 6 ?
                                       <>
                                       <h4 attr="1">Cloning latest app code...</h4>
                                       {/* <progress id="file" max="100" value="16" className="shimmer"></progress> */}
                                       <div class="our-progress">
    <progress id="file" max="100" value="16" ></progress>
    <div class="shimmer" style={{width: "16%"}}></div>
</div>
                                       <div>
                                         <h5>12 minutes estimated</h5>
                                         <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                           <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                           </svg>
                                           Cancel
                                         </h6>
                                       </div>
                                       </>
                                       : this.state.IOS_Initialising_step_number == 7 ?
                                       <>
                                       <h4 attr="1">Cloning latest app code...</h4>
                                       <progress id="file" max="100" value="18" className="shimmer"></progress>
                                       <div class="our-progress">
    <progress id="file" max="100" value="18" ></progress>
    <div class="shimmer" style={{width: "18%"}}></div>
</div>
                                       <div>
                                         <h5>11 minutes estimated</h5>
                                         <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                           <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                           </svg>
                                           Cancel
                                         </h6>
                                       </div>
                                       </>
                                       : this.state.IOS_Initialising_step_number == 8 ?
                                       <>
                                       <h4 attr="1">Cloning latest app code...</h4>
                                       {/* <progress id="file" max="100" value="20" className="shimmer"></progress> */}
                                       <div class="our-progress">
    <progress id="file" max="100" value="20" ></progress>
    <div class="shimmer" style={{width: "20%"}}></div>
</div>
                                       <div>
                                         <h5>11 minutes estimated</h5>
                                         <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                           <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                           </svg>
                                           Cancel
                                         </h6>
                                       </div>
                                       </>
                                       : this.state.IOS_Initialising_step_number == 9 ?
                                       <>
                                       <h4 attr="1">Validating build configurations...</h4>
                                       {/* <progress id="file" max="100" value="22" className="shimmer"></progress> */}
                                       <div class="our-progress">
    <progress id="file" max="100" value="22" ></progress>
    <div class="shimmer" style={{width: "22%"}}></div>
</div>
                                       <div>
                                         <h5>8 minutes estimated</h5>
                                         <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                           <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                           </svg>
                                           Cancel
                                         </h6>
                                       </div>
                                       </>
                                       : this.state.IOS_Initialising_step_number == 10 ?
                                       <>
                                       <h4 attr="1">Applying app configurations...</h4>
                                       {/* <progress id="file" max="100" value="24" className="shimmer"></progress> */}
                                       <div class="our-progress">
    <progress id="file" max="100" value="24" ></progress>
    <div class="shimmer" style={{width: "24%"}}></div>
</div>
                                       <div>
                                         <h5>7 minutes estimated</h5>
                                         <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                           <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                           </svg>
                                           Cancel
                                         </h6>
                                       </div>
                                       </>
                                       : this.state.IOS_Initialising_step_number == 11 ?
                                       <>
                                       <h4 attr="1">Initiating app build...</h4>
                                       {/* <progress id="file" max="100" value="26" className="shimmer"></progress> */}
                                       <div class="our-progress">
    <progress id="file" max="100" value="26" ></progress>
    <div class="shimmer" style={{width: "26%"}}></div>
</div>
                                       <div>
                                         <h5>7 minutes estimated</h5>
                                         <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                           <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                           </svg>
                                           Cancel
                                         </h6>
                                       </div>
                                       </>
                                       :
                                         <>
                                         <h4 attr="1">Saving build settings...</h4>
                                         {/* <progress id="file" max="100" value="0"></progress> */}
                                         <div class="our-progress">
    <progress id="file" max="100" value="0" ></progress>
    <div class="shimmer" style={{width: "0%"}}></div>
</div>
                                         <div> 
                                         <h5>10 minutes estimated</h5>
                                         <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                             <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                             </svg>
                                             Cancel
                                           </h6>
                                         </div>
                                         </>
                                       }
                                     </>
                                       
                                       
                                    : ( this.state.is_ios_build_on_hold === 0 &&
                                      this.state.ios_bitrise_build_slug !== "") ? 
                                       <>
                                        {this.state.ios_step_number == 1 ?
                                          <>
                                          <h4 attr="2">Initiating app build...</h4>
                                          {/* <progress id="file" max="100" value="26" className="shimmer"></progress> */}
                                          <div class="our-progress">
    <progress id="file" max="100" value="26" ></progress>
    <div class="shimmer" style={{width: "26%"}}></div>
</div>
                                          <div>
                                          <h5>7 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.ios_step_number == 2 ?
                                        <>
                                        <h4 attr="2">Preparing app build...</h4>
                                        {/* <progress id="file" max="100" value="30" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="30" ></progress>
    <div class="shimmer" style={{width: "30%"}}></div>
</div>
                                        <div>
                                          <h5>6 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.ios_step_number == 3 ?
                                        <>
                                        <h4 attr="2">Preparing app build...</h4>
                                        {/* <progress id="file" max="100" value="35" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="35" ></progress>
    <div class="shimmer" style={{width: "35%"}}></div>
</div>
                                        <div>
                                          <h5>6 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.ios_step_number == 4 ?
                                        <>
                                        <h4 attr="2">Preparing app build...</h4>
                                        {/* <progress id="file" max="100" value="40" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="40" ></progress>
    <div class="shimmer" style={{width: "40%"}}></div>
</div>
                                        <div>
                                          <h5>6 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.ios_step_number == 5 ?
                                        <>
                                        <h4 attr="2">Preparing app build...</h4>
                                        {/* <progress id="file" max="100" value="45" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="45" ></progress>
    <div class="shimmer" style={{width: "45%"}}></div>
</div>
                                        <div>
                                          <h5>6 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.ios_step_number == 6 ?
                                        <>
                                        <h4 attr="2">Processing app build...</h4>
                                        {/* <progress id="file" max="100" value="50" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="50" ></progress>
    <div class="shimmer" style={{width: "50%"}}></div>
</div>
                                        <div>
                                          <h5>5 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.ios_step_number == 7 ?
                                        <>
                                        <h4 attr="2">Processing app build...</h4>
                                        {/* <progress id="file" max="100" value="55" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="55" ></progress>
    <div class="shimmer" style={{width: "55%"}}></div>
</div>
                                        <div>
                                          <h5>5 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.ios_step_number == 8 ?
                                        <>
                                        <h4 attr="2">Processing app build...</h4>
                                        {/* <progress id="file" max="100" value="60" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="60" ></progress>
    <div class="shimmer" style={{width: "60%"}}></div>
</div>
                                        <div>
                                          <h5>5 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.ios_step_number == 9 ?
                                        <>
                                        <h4 attr="2">Completing app build...</h4>
                                        {/* <progress id="file" max="100" value="65" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="65" ></progress>
    <div class="shimmer" style={{width: "65%"}}></div>
</div>
                                        <div>
                                          <h5>2 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : this.state.ios_step_number == 10 ?
                                        <>
                                        <h4 attr="2">Completing app build...</h4>
                                        {/* <progress id="file" max="100" value="70" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="70" ></progress>
    <div class="shimmer" style={{width: "70%"}}></div>
</div>
                                        <div>
                                          <h5>2 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        : 
                                        <>
                                        <h4 attr="2">Saving build settings...</h4>
                                        {/* <progress id="file" max="100" value="0" ></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="0" ></progress>
    <div class="shimmer" style={{width: "0%"}}></div>
</div>
                                        <div>
                                        <h5>10 minutes estimated</h5>
                                        <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                        }
                                      </>
                                        
                                    : ( this.state.is_ios_build_on_hold === 1 &&
                                      this.state.ios_bitrise_build_slug !== "") ?
                                        <>
                                        <h4 attr="3">Your build has entered the queue...</h4> 
                                        {/* <progress id="file" max="100" value="80" className="shimmer"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="80" ></progress>
    <div class="shimmer" style={{width: "80%"}}></div>
</div>
                                        <div>
                                        <h5>8 minutes estimated</h5>
                                        <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                        </>
                                    : (this.state.is_ios_build_on_hold === 1 &&
                                      this.state.ios_bitrise_build_slug === "" )?
                                      <>
                                      {this.state.IOS_Initialising_step_number == 1 ?
                                        <>
                                        <h4 attr="1">Saving build settings...</h4>
                                        {/* <progress id="file" max="100" value="0"></progress> */}
                                        <div class="our-progress">
    <progress id="file" max="100" value="0" ></progress>
    <div class="shimmer" style={{width: "0%"}}></div>
</div>
                                        <div>
                                          <h5>10 minutes estimated</h5>
                                          <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                      </>
                                      : this.state.IOS_Initialising_step_number == 2 ?
                                      <>
                                      <h4 attr="1">Validating app subscription...</h4>
                                      {/* <progress id="file" max="100" value="5" className="shimmer"></progress> */}
                                      <div class="our-progress">
    <progress id="file" max="100" value="5" ></progress>
    <div class="shimmer" style={{width: "5%"}}></div>
</div>
                                      <div>
                                        <h5>10 minutes estimated</h5>
                                        <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                      </>
                                      : this.state.IOS_Initialising_step_number == 3 ?
                                      <>
                                      <h4 attr="1">Validating bundle information...</h4>
                                      {/* <progress id="file" max="100" value="8" className="shimmer"></progress> */}
                                      <div class="our-progress">
    <progress id="file" max="100" value="8" ></progress>
    <div class="shimmer" style={{width: "8%"}}></div>
</div>
                                      <div>
                                        <h5>10 minutes estimated</h5>
                                        <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                      </>
                                      : this.state.IOS_Initialising_step_number == 4 ?
                                      <>
                                      <h4 attr="1">Fetching latest app code...</h4>
                                      {/* <progress id="file" max="100" value="10" className="shimmer"></progress> */}
                                      <div class="our-progress">
    <progress id="file" max="100" value="10" ></progress>
    <div class="shimmer" style={{width: "10%"}}></div>
</div>
                                      <div>
                                        <h5>9 minutes estimated</h5>
                                        <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                      </>
                                      : this.state.IOS_Initialising_step_number == 5 ?
                                      <>
                                      <h4 attr="1">Cloning latest app code...</h4>
                                      {/* <progress id="file" max="100" value="13" className="shimmer"></progress> */}
                                      <div class="our-progress">
    <progress id="file" max="100" value="13" ></progress>
    <div class="shimmer" style={{width: "13%"}}></div>
</div>
                                      <div>
                                        <h5>12 minutes estimated</h5>
                                        <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                      </>
                                      : this.state.IOS_Initialising_step_number == 6 ?
                                      <>
                                      <h4 attr="1">Cloning latest app code...</h4>
                                      {/* <progress id="file" max="100" value="16"className="shimmer" ></progress> */}
                                      <div class="our-progress">
    <progress id="file" max="100" value="16" ></progress>
    <div class="shimmer" style={{width: "16%"}}></div>
</div>
                                      <div>
                                        <h5>11 minutes estimated</h5>
                                        <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                      </>
                                      : this.state.IOS_Initialising_step_number == 7 ?
                                      <>
                                      <h4 attr="1">Cloning latest app code...</h4>
                                      {/* <progress id="file" max="100" value="18" className="shimmer"></progress> */}
                                      <div class="our-progress">
    <progress id="file" max="100" value="18" ></progress>
    <div class="shimmer" style={{width: "18%"}}></div>
</div>
                                      <div>
                                        <h5>11 minutes estimated</h5>
                                        <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                      </>
                                      : this.state.IOS_Initialising_step_number == 8 ?
                                      <>
                                      <h4 attr="1">Cloning latest app code...</h4>
                                      {/* <progress id="file" max="100" value="20" className="shimmer"></progress> */}
                                      <div class="our-progress">
    <progress id="file" max="100" value="20" ></progress>
    <div class="shimmer" style={{width: "20%"}}></div>
</div>
                                      <div>
                                        <h5>10 minutes estimated</h5>
                                        <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                      </>
                                      : this.state.IOS_Initialising_step_number == 9 ?
                                      <>
                                      <h4 attr="1">Validating build configurations...</h4>
                                      {/* <progress id="file" max="100" value="22" className="shimmer"></progress> */}
                                      <div class="our-progress">
    <progress id="file" max="100" value="22" ></progress>
    <div class="shimmer" style={{width: "22%"}}></div>
</div>
                                      <div>
                                        <h5>8 minutes estimated</h5>
                                        <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                      </>
                                      : this.state.IOS_Initialising_step_number == 10 ?
                                      <>
                                      <h4 attr="1">Applying app configurations...</h4>
                                      {/* <progress id="file" max="100" value="24" className="shimmer"></progress> */}
                                      <div class="our-progress">
    <progress id="file" max="100" value="24" ></progress>
    <div class="shimmer" style={{width: "24%"}}></div>
</div>
                                      <div>
                                        <h5>7 minutes estimated</h5>
                                        <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                      </>
                                      : this.state.IOS_Initialising_step_number == 11 ?
                                      <>
                                      <h4 attr="1">Initiating app build...</h4>
                                      {/* <progress id="file" max="100" value="26" className="shimmer"></progress> */}
                                      <div class="our-progress">
    <progress id="file" max="100" value="26" ></progress>
    <div class="shimmer" style={{width: "26%"}}></div>
</div>
                                      <div>
                                        <h5>7 minutes estimated</h5>
                                        <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                      </>
                                      :
                                        <>
                                        <h4 attr="1">Initiating app build...</h4>
                                        <div class="our-progress">
    <progress id="file" max="100" value="0" ></progress>
    <div class="shimmer" style={{width: "0%"}}></div>
</div>
                                        <div> 
                                        <h5>7 minutes estimated</h5>
                                        <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                            <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                            </svg>
                                            Cancel
                                          </h6>
                                        </div>
                                        </>
                                      }
                                    </>
                                    : 
                                    <>
                                    {this.state.IOS_Initialising_step_number == 1 ?
                                      <>
                                      <h4 attr="1">Saving build settings...</h4>
                                      {/* <progress id="file" max="100" value="0"></progress> */}
                                      <div class="our-progress">
    <progress id="file" max="100" value="0" ></progress>
    <div class="shimmer" style={{width: "0%"}}></div>
</div>
                                      <div>
                                        <h5>10 minutes estimated</h5>
                                        <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.IOS_Initialising_step_number == 2 ?
                                    <>
                                    <h4 attr="1">Validating app subscription...</h4>
                                    {/* <progress id="file" max="100" value="5" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="5" ></progress>
    <div class="shimmer" style={{width: "5%"}}></div>
</div>
                                    <div>
                                      <h5>10 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.IOS_Initialising_step_number == 3 ?
                                    <>
                                    <h4 attr="1">Validating bundle information...</h4>
                                    {/* <progress id="file" max="100" value="8" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="8" ></progress>
    <div class="shimmer" style={{width: "8%"}}></div>
</div>
                                    <div>
                                      <h5>10 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.IOS_Initialising_step_number == 4 ?
                                    <>
                                    <h4 attr="1">Fetching latest app code...</h4>
                                    {/* <progress id="file" max="100" value="10" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="10" ></progress>
    <div class="shimmer" style={{width: "10%"}}></div>
</div>
                                    <div>
                                      <h5>9 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.IOS_Initialising_step_number == 5 ?
                                    <>
                                    <h4 attr="1">Cloning latest app code...</h4>
                                    {/* <progress id="file" max="100" value="13" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="13" ></progress>
    <div class="shimmer" style={{width: "13%"}}></div>
</div>
                                    <div>
                                      <h5>12 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.IOS_Initialising_step_number == 6 ?
                                    <>
                                    <h4 attr="1">Cloning latest app code...</h4>
                                    {/* <progress id="file" max="100" value="16" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="16" ></progress>
    <div class="shimmer" style={{width: "16%"}}></div>
</div>
                                    <div>
                                      <h5>12 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.IOS_Initialising_step_number == 7 ?
                                    <>
                                    <h4 attr="1">Cloning latest app code...</h4>
                                    {/* <progress id="file" max="100" value="18" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="18" ></progress>
    <div class="shimmer" style={{width: "18%"}}></div>
</div>
                                    <div>
                                      <h5>11 minutes estimated </h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.IOS_Initialising_step_number == 8 ?
                                    <>
                                    <h4 attr="1">Cloning latest app code...</h4>
                                    {/* <progress id="file" max="100" value="20" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="20" ></progress>
    <div class="shimmer" style={{width: "20%"}}></div>
</div>
                                    <div>
                                      <h5>11 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.IOS_Initialising_step_number == 9 ?
                                    <>
                                    <h4 attr="1">Validating build configurations...</h4>
                                    {/* <progress id="file" max="100" value="22" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="22" ></progress>
    <div class="shimmer" style={{width: "22%"}}></div>
</div>
                                    <div>
                                      <h5>8 minutes estimated </h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.IOS_Initialising_step_number == 10 ?
                                    <>
                                    <h4 attr="1">Applying app configurations...</h4>
                                    {/* <progress id="file" max="100" value="24" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="24" ></progress>
    <div class="shimmer" style={{width: "24%"}}></div>
</div>
                                    <div>
                                      <h5>7 minutes estimated </h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    : this.state.IOS_Initialising_step_number == 11 ?
                                    <>
                                    <h4 attr="1">Initiating app build...</h4>
                                    {/* <progress id="file" max="100" value="26" className="shimmer"></progress> */}
                                    <div class="our-progress">
    <progress id="file" max="100" value="26" ></progress>
    <div class="shimmer" style={{width: "26%"}}></div>
</div>
                                    <div>
                                      <h5>7 minutes estimated </h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                        <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                        </svg>
                                        Cancel
                                      </h6>
                                    </div>
                                    </>
                                    :
                                      <>
                                      <h4 attr="1">Initialising...</h4>
                                      {/* <progress id="file" max="100" value="0"></progress> */}
                                      <div class="our-progress">
    <progress id="file" max="100" value="0" ></progress>
    <div class="shimmer" style={{width: "0%"}}></div>
</div>
                                      <div> 
                                      <h5>10 minutes estimated</h5>
                                      <h6  onClick={(e) => this.hanldebuildcancel("ios")}>
                                          <svg  xmlns="http://www.w3.org/2000/svg" width="9.061" height="9.061" viewBox="0 0 9.061 9.061"><path id="Union_3101" data-name="Union 3101" d="M4,4,0,8,4,4,0,0,4,4,8,0,4,4,8,8Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                          Cancel
                                        </h6>
                                      </div>
                                      </>
                                    }
                                  </>
                                    
                                    }
                                      
                                    </div>
                                </>
                                )}

                                  <div className="download-build-section-three">
                                      <div className="build-section-one">
                                          <div>
                                              <h3>Version:</h3>
                                              <h4>{this.state.ios_build_version !=  undefined && this.state.ios_build_version != '' && this.state.ios_build_version !== null ? 
                                                (
                                                  <>
                                                    {this.state.ios_build_version}
                                                  </>
                                                ) 
                                                : 
                                                (
                                                  "-"
                                                )}
                                              </h4>
                                          </div>
                                          {this.state.allowed_module1 === "all" ? (
                                        <>
                                          {this.state.ios_latest_version !== null &&
                                          this.state.ios_build_version !== null ? 
                                          (
                                            this.versioniosCompare(
                                              this.state.ios_latest_version,
                                              this.state.ios_build_version
                                            )
                                          ) : (
                                            <>
                                              {this.state.ios_build_version !== null ? (
                                                <h5>
                                                  Your last build was created using the
                                                  latest AppMySite Code Version.
                                                </h5>
                                              ) : (
                                                <p
                                                  className="p-0 m-0"
                                                  style={{ fontSize: "12px" }}
                                                ></p>
                                              )}
                                            </>
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                      </div>
                                      <div className="build-section-one build-section-two 2">
                                          <div>
                                              <h3>Build:</h3>
                                              <h4  className={this.state.buildiosstatus && (this.state.buildiosstatus.build_status === "1" || this.state.buildiosstatus.build_status === "4" )
                                                              ? "build-complete" 
                                                              : this.state.buildiosstatus && (this.state.buildiosstatus.build_status === "2")
                                                              ? "build-incomplete"
                                                              : "build-draft"}>
                                                              {this.Checkiosstatus(this.state.buildiosstatus)} 
                                                              {this.state.error_description && this.state.error_description != '' 
                                                              ? <span onClick={() =>this.handleErrorPopUp(this.state.error_description)}>View error</span> 
                                                              : '' }
                                              </h4>
                                          </div>
                                          {/* <a href="">Rebuild</a> */}
                                            <>
                                                {this.state.rebuildiosapp === true && (
                                                  <Link
                                                    to="/app/download/ios-build-settings"
                                                    className={
                                                      this.state.builder_status.status === 2 ||
                                                      this.state.builder_status.status === 3
                                                        ? "rebuild_new_disable"
                                                        : "d-md-block rebuild_new"
                                                    }
                                                    style={{
                                                      // backgroundColor:localStorage.getItem('button_background_color'),
                                                      border:'1px solid '+localStorage.getItem('button_background_color'),
                                                      color:localStorage.getItem('button_background_color'),
                                                      "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                                      "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                                                      "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                                      }}
                                                    // onClick={(e) => this.createbuild("ios")}
                                                  >
                                                   
                                                    {this.state.builder_status.status === 2 ||
                                                    this.state.builder_status.status === 3 ? (
                                                      <>
                                                        Rebuild
                                                        <span className={"new-hover-element-text"}>
                                                          {this.state.builder_status.title}
                                                          <p>
                                                            {this.state.builder_status.description}
                                                          </p>
                                                        </span>
                                                      </>
                                                    ) : (
                                                      <>Rebuild</>
                                                    )}
                                                  </Link>
                                                )}
                                                {/* Build after after addon */}

                                              {this.state.addoniosbuild === true && (
                                                <Link
                                                to="/app/download/ios-build-settings"
                                                style={{
                                                  // backgroundColor:localStorage.getItem('button_background_color'),
                                                  border:'1px solid '+localStorage.getItem('button_background_color'),
                                                  color:localStorage.getItem('button_background_color'),
                                                  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                                  "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                                                  "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                                  }}
                                                  className={
                                                    this.state.builder_status.status === 2 ||
                                                    this.state.builder_status.status === 3
                                                      ? "rebuild_new_disable"
                                                      : "d-md-block rebuild_new"
                                                  }
                                                  // onClick={(e) => this.createbuild("ios")}
                                                >
                                                 
                                                  {this.state.builder_status.status === 2 ||
                                                  this.state.builder_status.status === 3 ? (
                                                    <>
                                                      Rebuild
                                                      <span className={"new-hover-element-text"}>
                                                        {this.state.builder_status.title}
                                                        <p>
                                                          {this.state.builder_status.description}
                                                        </p>
                                                      </span>
                                                    </>
                                                  ) : (
                                                    <>Rebuild</>
                                                  )}
                                                </Link>
                                              )}
                                              {/* Temporary rebuild button */}
                                              {this.state.allowed_module1 === "all" ? (
                                                this.state.downloadiosbuild === true && (
                                                  <Link
                                                   to="/app/download/ios-build-settings"
                                                    style={{
                                                      // backgroundColor:localStorage.getItem('button_background_color'),
                                                      border:'1px solid '+localStorage.getItem('button_background_color'),
                                                      color:localStorage.getItem('button_background_color'),
                                                      "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                                      "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                                                      "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                                      }}
                                                    className={
                                                      this.state.builder_status.status === 2 ||
                                                      this.state.builder_status.status === 3
                                                        ? "rebuild_new_disable"
                                                        : "d-md-block rebuild_new"
                                                    }
                                                    // onClick={(e) => this.createbuild("ios")}
                                                  >
                                                    
                                                    {this.state.builder_status.status === 2 ||
                                                    this.state.builder_status.status === 3 ? (
                                                      <>
                                                        Rebuild
                                                        <span className={"new-hover-element-text"}>
                                                          {this.state.builder_status.title}
                                                          <p>{this.state.builder_status.description}</p>
                                                        </span>
                                                      </>
                                                    ) : (
                                                      <>Rebuild</>
                                                    )}
                                                  </Link>
                                                )
                                              ) : (
                                                <></>
                                              )}
                                            </>
                                        </div>
                                      </div>
                                  </div>

                              </div>

                        {this.state.data.length > 0 && this.state.build_trigger_count != 0 ?

                            <div className="download-history">
                                <h3>Build history</h3>
                              
                              <div
                              className="history-table">
                                    <div className="download-history-head">
                                        <div>
                                            <h6>Date 
                                             <Tooltip
                                                message={"Date and time when the app build was created."}
                                              /> 
                                            </h6>
                                        </div>
                                        <div>
                                            <h6>Product 
                                              <Tooltip
                                                message={"Product technology associated with the app."}
                                              />
                                            </h6>
                                        </div>
                                        <div>
                                            <h6>Source version 
                                              <Tooltip
                                                message={"Version of the source code used to build the app."}
                                              />
                                            </h6>
                                        </div>
                                        <div>
                                            <h6>App version 
                                              <Tooltip
                                                message={"Version number assigned to the app."}
                                              /></h6>
                                        </div>
                                        <div>
                                            <h6>App identifier
                                              <Tooltip
                                                message={"Unique ID of the app within the app distribution ecosystem."}
                                              /></h6>
                                        </div>
                                        <div>
                                            <h6>Status <Tooltip
                                                message={"Status of your app build attempt."}
                                              /></h6>
                                        </div>
                                        <div>
                                            <h6 className="download-build-header">Download <Tooltip
                                                message={"Download the build for publishing."}
                                              /></h6>
                                        </div>
                                    </div>

                                    <div className="download-history-body">
                                    {this.state.data.map((res, i) => (
                                        <div className="download-history-body-row" key={i}>
                                            <div className="download-history-entry">
                                                <h4><img src={res.technology === "ios" ? require("../../assets/images/dashboard/apple-logs.png").default : require("../../assets/images/dashboard/android-logs.png").default} alt="app-tech" className="img-fluid" /> {moment(res.date).format("D MMMM YYYY, HH:mm")}</h4>
                                            </div>
                                            <div className="download-history-entry">
                                                            { res.website_technology === 2 ?
                                                                <img src={require("../../Agency/assets/images/webtoapp.png").default} alt="webtoapp" className="img-fluid"/>
                                                              :
                                                                  (res.website_technology === 3 ?
                                                                      <img src={require("../../Agency/assets/images/customapp.png").default} alt="customapps" className="img-fluid"/>   
                                                                  :
                                                                      (res.website_technology === 4 ?
                                                                        <img src={require("../../Agency/assets/images/wordpress.png").default} alt="wordpress" className="img-fluid"/>
                                                                      :
                                                                          (res.website_technology === 5 ?
                                                                            <img src={require("../../Agency/assets/images/woocomerce.png").default} alt="woocommerce" className="img-fluid"/>
                                                                          :
                                                                            "-"
                                                                          )
                                                                      )
                                                                  )
                                                              }
                                            </div>
                                            <div className="download-history-entry">
                                                <h4>{res.app_in_process === 0 && (res.build_status === "0" || res.build_status === "3") ? 
                                                        (  <>-</> ) 
                                                    : res.app_code_version === "" ? 
                                                        (<span>-</span> ) : 
                                                        (res.app_code_version )}
                                                </h4>
                                            </div>
                                            <div className="download-history-entry">
                                                <h4>{res.technology === "ios" ? 
                                                    (
                                                        <>
                                                          {res.app_in_process === 0 &&
                                                            (res.build_status === "0" ||
                                                              res.build_status === "3") ? (
                                                              <>-</>
                                                            ) : (
                                                              <>
                                                                {res.build_release === "" ? (
                                                                  <span>-</span>
                                                                ) : (
                                                                  res.build_release
                                                                )}
                                                                <span>
                                                                  {" "}
                                                                  (
                                                                  {res.version === "" ? (
                                                                    <span>-</span>
                                                                  ) : (
                                                                    res.version
                                                                  )}
                                                                  )
                                                                </span>
                                                              </>
                                                            )}
                                                        </>
                                                    ) 
                                                    : 
                                                    (
                                                        <>    
                                                            {res.app_in_process === 0 &&
                                                            (res.build_status === "0" ||
                                                            res.build_status === "3") ? (
                                                            <>-</>
                                                            ) : (
                                                            <>
                                                                {res.build_release === "" ? (
                                                                <span>-</span>
                                                                ) : (
                                                                res.build_release
                                                                )}
                                                                <span>
                                                                {" "}
                                                                (
                                                                {res.version === "" ? (
                                                                    <span>-</span>
                                                                ) : (
                                                                    res.version
                                                                )}
                                                                )
                                                                </span>
                                                            </>
                                                            )}
                                                        </>
                                                          
                                                    )}</h4>
                                            </div>
                                            <div className="download-history-entry">
                                                <h4>{res.app_in_process === 0 &&
                                                        ( res.build_status === "1" || res.build_status === "4") &&
                                                          res.bundle_id === null ? (
                                                              <>
                                                                com.appname.app
                                                                {localStorage.getItem("Appid")}
                                                              </>
                                                            ) : res.bundle_id === null ? (
                                                              <span>-</span>
                                                            ) : (
                                                              res.bundle_id
                                                            )}
                                                </h4>
                                            </div>
                                            <div className="download-history-entry 1">
                                                <h5 className={
                                                              res.build_status == 1 || res.build_status == 4 
                                                              ? "build-complete"
                                                              : res.build_status == 2
                                                              ? "build-incomplete"
                                                              : res.app_in_process == 2
                                                              ? "build-incomplete"
                                                              : "build-draft" } > {this.Checkstatus(res)} 
                                                                </h5>
                                                                {res.description && res.description != '' 
                                                                ? <h6 onClick={() =>this.handleErrorPopUp(res.description)}>View error</h6> 
                                                                : '' }
                                            </div>
                                            <div className="download-history-entry">
                                                <div>
                                              
                                                        {res.technology === "android" ? 
                                                        (
                                                            <>
                                                              {
                                                                (res.app_in_process === 0 && res.build_status === "1" &&
                                                                    res.bitrise_success_step === "success" && res.bitbucket_success_step === "success") ||
                                                                (res.app_in_process === 0 && res.build_status === "4" &&
                                                                    res.bitrise_success_step === "success" && res.bitbucket_success_step === "success") ? 
                                                                    (
                                                                    <a href={process.env.REACT_APP_Image_Path +res.build_url} download>
                                                                        <svg style={{marginRight: "10px"}} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                                            <g id="Group_41879" data-name="Group 41879" transform="translate(-1780 -739)">
                                                                                <g id="Rectangle_31274" data-name="Rectangle 31274" transform="translate(1780 739)" fill="#fff" stroke="#8eabff" stroke-width="1">
                                                                                <rect width="40" height="40" rx="3" stroke="none"/>
                                                                                <rect x="0.5" y="0.5" width="39" height="39" rx="2.5" fill="none"/>
                                                                                </g>
                                                                                <g id="Group_41605" data-name="Group 41605" transform="translate(55.5 93)">
                                                                                <path id="Path_118655" data-name="Path 118655" d="M-3504.5,477v12" transform="translate(5249 176)" fill="none" stroke="#3064f9" stroke-width="1.2"/>
                                                                                <path id="Path_118656" data-name="Path 118656" d="M-3516.578,512.841l5,5,5-5" transform="translate(5256.078 147.159)" fill="none" stroke="#3064f9" stroke-width="1.2"/>
                                                                                </g>
                                                                                <text id="apk" transform="translate(1800 769)" fill="#3064f9" font-size="10" font-family="Axiforma-Regular, Axiforma"><tspan x="-9.4" y="0">apk</tspan></text>
                                                                            </g>
                                                                        </svg>
                                                                    </a>
                                                                ) : 
                                                                (
                                                                    "-"
                                                                )
                                                              }
                                                              {(res.app_in_process === 0 && res.build_status === "1" &&
                                                                res.bitrise_success_step === "success" && res.bitbucket_success_step ==="success") ||
                                                              (res.app_in_process === 0 && res.build_status === "4" &&
                                                                res.bitrise_success_step === "success" && res.bitbucket_success_step === "success") ? (
                                                                <a  href={process.env.REACT_APP_Image_Path +res.zip_path} download >
                                                                    <svg style={{marginRight: "10px"}} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                                        <g id="Group_41879" data-name="Group 41879" transform="translate(-1780 -739)">
                                                                            <g id="Rectangle_31274" data-name="Rectangle 31274" transform="translate(1780 739)" fill="#fff" stroke="#8eabff" stroke-width="1">
                                                                            <rect width="40" height="40" rx="3" stroke="none"/>
                                                                            <rect x="0.5" y="0.5" width="39" height="39" rx="2.5" fill="none"/>
                                                                            </g>
                                                                            <g id="Group_41605" data-name="Group 41605" transform="translate(55.5 93)">
                                                                            <path id="Path_118655" data-name="Path 118655" d="M-3504.5,477v12" transform="translate(5249 176)" fill="none" stroke="#3064f9" stroke-width="1.2"/>
                                                                            <path id="Path_118656" data-name="Path 118656" d="M-3516.578,512.841l5,5,5-5" transform="translate(5256.078 147.159)" fill="none" stroke="#3064f9" stroke-width="1.2"/>
                                                                            </g>
                                                                            <text id="apk" transform="translate(1800 769)" fill="#3064f9" font-size="10" font-family="Axiforma-Regular, Axiforma"><tspan x="-9.4" y="0">aab</tspan></text>
                                                                        </g>
                                                                    </svg>
                                                                </a>
                                                              ) : 
                                                              (
                                                                "-"
                                                              )}
                                                            </>
                                                        ) 
                                                        : 
                                                        (
                                                            <>
                                                              {(res.app_in_process === 0 && res.build_status === "1" &&
                                                                res.bitrise_success_step === "success" && res.bitbucket_success_step === "success") ||
                                                              (res.app_in_process === 0 && res.build_status === "4" &&
                                                                res.bitrise_success_step === "success" && res.bitbucket_success_step === "success") ? 
                                                            (
                                                                <a href={process.env.REACT_APP_Image_Path +res.build_url} download>
                                                                  <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                                    <g id="Group_41881" data-name="Group 41881" transform="translate(-1830 -739)">
                                                                        <g id="Rectangle_31273" data-name="Rectangle 31273" transform="translate(1830 739)" fill="#fff" stroke="#8eabff" stroke-width="1">
                                                                        <rect width="40" height="40" rx="3" stroke="none"/>
                                                                        <rect x="0.5" y="0.5" width="39" height="39" rx="2.5" fill="none"/>
                                                                        </g>
                                                                        <g id="Group_41604" data-name="Group 41604" transform="translate(105.5 93)">
                                                                        <path id="Path_118655" data-name="Path 118655" d="M-3504.5,477v12" transform="translate(5249 176)" fill="none" stroke="#3064f9" stroke-width="1.2"/>
                                                                        <path id="Path_118656" data-name="Path 118656" d="M-3516.578,512.841l5,5,5-5" transform="translate(5256.078 147.159)" fill="none" stroke="#3064f9" stroke-width="1.2"/>
                                                                        </g>
                                                                        <text id="aab" transform="translate(1850 769)" fill="#3064f9" font-size="10" font-family="Axiforma-Regular, Axiforma"><tspan x="-9.99" y="0">ipa</tspan></text>
                                                                    </g>
                                                                    </svg>
                                                                </a>
                                                              ) : (
                                                                "-"
                                                              )}
                                                            </>
                                                          )}
                                                    </div>
                                            </div>
                                        </div>
                                    ))}
                                         {this.state.nextpageicon_spinner ? 
                                            <TableSpinner />
                                        : <></>
                                        }
                              
                                        

                                    </div>
                                </div>
                         

                                <div className="download-history-mob">
                                {this.state.data.map((res, i) => (
                                    <div className="download-history-entry-mob">

                                        <div className="history-entry-one">
                                            <div>
                                                <h6>Date</h6>
                                                <h5>{moment(res.date).format("D MMMM YYYY, HH:mm")}</h5>
                                            </div>
                                            <img src={res.technology === "ios" ? require("../../assets/images/dashboard/apple-logs.png").default : require("../../assets/images/dashboard/android-logs.png").default} alt="app-tech" className="img-fluid" />
                                        </div>

                                        <div className="history-entry-two">
                                            <div>
                                                <h6>Product</h6>
                                                { res.website_technology === 2 ?
                                                  <img src={require("../../Agency/assets/images/webtoapp.png").default} alt="webtoapp" className="img-fluid"/>
                                                :
                                                    (res.website_technology === 3 ?
                                                        <img src={require("../../Agency/assets/images/customapp.png").default} alt="customapps" className="img-fluid"/>   
                                                    :
                                                        (res.website_technology === 4 ?
                                                          <img src={require("../../Agency/assets/images/wordpress.png").default} alt="wordpress" className="img-fluid"/>
                                                        :
                                                            (res.website_technology === 5 ?
                                                              <img src={require("../../Agency/assets/images/woocomerce.png").default} alt="woocommerce" className="img-fluid"/>
                                                            :
                                                              "-"
                                                            )
                                                        )
                                                    )
                                                }
                                            </div>
                                        </div>

                                        <div className="history-entry-three">
                                            <div>
                                                <h6>Source version</h6>
                                                <h5>{res.app_in_process === 0 && (res.build_status === "0" || res.build_status === "3") ? 
                                                        (  <>-</> ) 
                                                    : res.app_code_version === "" ? 
                                                        (<span>-</span> ) : 
                                                        (res.app_code_version )}</h5>
                                            </div>
                                        </div>

                                        <div className="history-entry-four">
                                            <div>
                                                <h6>App version</h6>
                                                <h4>{res.technology === "ios" ? 
                                                    (
                                                        <>
                                                          {res.app_in_process === 0 &&
                                                            (res.build_status === "0" ||
                                                              res.build_status === "3") ? (
                                                              <>-</>
                                                            ) : (
                                                              <>
                                                                {res.build_release === "" ? (
                                                                  <span>-</span>
                                                                ) : (
                                                                  res.build_release
                                                                )}
                                                                <span>
                                                                  {" "}
                                                                  (
                                                                  {res.version === "" ? (
                                                                    <span>-</span>
                                                                  ) : (
                                                                    res.version
                                                                  )}
                                                                  )
                                                                </span>
                                                              </>
                                                            )}
                                                        </>
                                                    ) 
                                                    : 
                                                    (
                                                        <>    
                                                            {res.app_in_process === 0 &&
                                                            (res.build_status === "0" ||
                                                            res.build_status === "3") ? (
                                                            <>-</>
                                                            ) : (
                                                            <>
                                                                {res.build_release === "" ? (
                                                                <span>-</span>
                                                                ) : (
                                                                res.build_release
                                                                )}
                                                                <span>
                                                                {" "}
                                                                (
                                                                {res.version === "" ? (
                                                                    <span>-</span>
                                                                ) : (
                                                                    res.version
                                                                )}
                                                                )
                                                                </span>
                                                            </>
                                                            )}
                                                        </>
                                                          
                                                    )}</h4>
                                            </div>
                                        </div>

                                        <div className="history-entry-five">
                                            <div>
                                                <h6>App identifier</h6>
                                                <h5>{res.app_in_process === 0 &&
                                                        ( res.build_status === "1" || res.build_status === "4") &&
                                                          res.bundle_id === null ? (
                                                              <>
                                                                com.appname.app
                                                                {localStorage.getItem("Appid")}
                                                              </>
                                                            ) : res.bundle_id === null ? (
                                                              <span>-</span>
                                                            ) : (
                                                              res.bundle_id
                                                            )}</h5>
                                            </div>
                                        </div>

                                        <div className="history-entry-six">
                                            <div>
                                                <h6>Status</h6>
                                                <h3 className={
                                                              res.build_status == 1 || res.build_status == 4 
                                                              ? "build-complete"
                                                              : res.build_status == 2
                                                              ? "build-incomplete"
                                                              : res.app_in_process == 2
                                                              ? "build-incomplete"
                                                              : "build-draft" }>{this.Checkstatus(res)}</h3>
                                                              {res.description && res.description != '' 
                                                                ? <p onClick={() =>this.handleErrorPopUp(res.description)}>View error</p> 
                                                                : '' }
                                                {/* <p onClick={this.handleAttributePopUp}>View Error</p> */}
                                            </div>

                                            <div>
                                            {res.technology === "android" ? 
                                                        (
                                                            <>
                                                              {
                                                                (res.app_in_process === 0 && res.build_status === "1" &&
                                                                    res.bitrise_success_step === "success" && res.bitbucket_success_step === "success") ||
                                                                (res.app_in_process === 0 && res.build_status === "4" &&
                                                                    res.bitrise_success_step === "success" && res.bitbucket_success_step === "success") ? 
                                                                    (
                                                                    <a href={process.env.REACT_APP_Image_Path +res.build_url} download>
                                                                        <svg style={{marginRight: "10px"}} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                                            <g id="Group_41879" data-name="Group 41879" transform="translate(-1780 -739)">
                                                                                <g id="Rectangle_31274" data-name="Rectangle 31274" transform="translate(1780 739)" fill="#fff" stroke="#8eabff" stroke-width="1">
                                                                                <rect width="40" height="40" rx="3" stroke="none"/>
                                                                                <rect x="0.5" y="0.5" width="39" height="39" rx="2.5" fill="none"/>
                                                                                </g>
                                                                                <g id="Group_41605" data-name="Group 41605" transform="translate(55.5 93)">
                                                                                <path id="Path_118655" data-name="Path 118655" d="M-3504.5,477v12" transform="translate(5249 176)" fill="none" stroke="#3064f9" stroke-width="1.2"/>
                                                                                <path id="Path_118656" data-name="Path 118656" d="M-3516.578,512.841l5,5,5-5" transform="translate(5256.078 147.159)" fill="none" stroke="#3064f9" stroke-width="1.2"/>
                                                                                </g>
                                                                                <text id="apk" transform="translate(1800 769)" fill="#3064f9" font-size="10" font-family="Axiforma-Regular, Axiforma"><tspan x="-9.4" y="0">apk</tspan></text>
                                                                            </g>
                                                                        </svg>
                                                                    </a>
                                                                ) : 
                                                                (
                                                                    "-"
                                                                )
                                                              }
                                                              {(res.app_in_process === 0 && res.build_status === "1" &&
                                                                res.bitrise_success_step === "success" && res.bitbucket_success_step ==="success") ||
                                                              (res.app_in_process === 0 && res.build_status === "4" &&
                                                                res.bitrise_success_step === "success" && res.bitbucket_success_step === "success") ? (
                                                                <a  href={process.env.REACT_APP_Image_Path +res.zip_path} download >
                                                                    <svg style={{marginRight: "5px"}} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                                        <g id="Group_41879" data-name="Group 41879" transform="translate(-1780 -739)">
                                                                            <g id="Rectangle_31274" data-name="Rectangle 31274" transform="translate(1780 739)" fill="#fff" stroke="#8eabff" stroke-width="1">
                                                                            <rect width="40" height="40" rx="3" stroke="none"/>
                                                                            <rect x="0.5" y="0.5" width="39" height="39" rx="2.5" fill="none"/>
                                                                            </g>
                                                                            <g id="Group_41605" data-name="Group 41605" transform="translate(55.5 93)">
                                                                            <path id="Path_118655" data-name="Path 118655" d="M-3504.5,477v12" transform="translate(5249 176)" fill="none" stroke="#3064f9" stroke-width="1.2"/>
                                                                            <path id="Path_118656" data-name="Path 118656" d="M-3516.578,512.841l5,5,5-5" transform="translate(5256.078 147.159)" fill="none" stroke="#3064f9" stroke-width="1.2"/>
                                                                            </g>
                                                                            <text id="apk" transform="translate(1800 769)" fill="#3064f9" font-size="10" font-family="Axiforma-Regular, Axiforma"><tspan x="-9.4" y="0">aab</tspan></text>
                                                                        </g>
                                                                    </svg>
                                                                </a>
                                                              ) : 
                                                              (
                                                                "-"
                                                              )}
                                                            </>
                                                        ) 
                                                        : 
                                                        (
                                                            <>
                                                              {(res.app_in_process === 0 && res.build_status === "1" &&
                                                                res.bitrise_success_step === "success" && res.bitbucket_success_step === "success") ||
                                                              (res.app_in_process === 0 && res.build_status === "4" &&
                                                                res.bitrise_success_step === "success" && res.bitbucket_success_step === "success") ? 
                                                            (
                                                                <a href={process.env.REACT_APP_Image_Path +res.build_url} download>
                                                                  <svg style={{marginRight: "5px"}} xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                                                                    <g id="Group_41881" data-name="Group 41881" transform="translate(-1830 -739)">
                                                                        <g id="Rectangle_31273" data-name="Rectangle 31273" transform="translate(1830 739)" fill="#fff" stroke="#8eabff" stroke-width="1">
                                                                        <rect width="40" height="40" rx="3" stroke="none"/>
                                                                        <rect x="0.5" y="0.5" width="39" height="39" rx="2.5" fill="none"/>
                                                                        </g>
                                                                        <g id="Group_41604" data-name="Group 41604" transform="translate(105.5 93)">
                                                                        <path id="Path_118655" data-name="Path 118655" d="M-3504.5,477v12" transform="translate(5249 176)" fill="none" stroke="#3064f9" stroke-width="1.2"/>
                                                                        <path id="Path_118656" data-name="Path 118656" d="M-3516.578,512.841l5,5,5-5" transform="translate(5256.078 147.159)" fill="none" stroke="#3064f9" stroke-width="1.2"/>
                                                                        </g>
                                                                        <text id="aab" transform="translate(1850 769)" fill="#3064f9" font-size="10" font-family="Axiforma-Regular, Axiforma"><tspan x="-9.99" y="0">ipa</tspan></text>
                                                                    </g>
                                                                    </svg>
                                                                </a>
                                                              ) : (
                                                                "-"
                                                              )}
                                                            </>
                                                          )}

                                            </div>
                                        </div>

                                    </div>
                                  ))
                                }
                                {this.state.nextpageicon_spinner ? 
                              <TableSpinner />
                          : <></>
                          }

                                </div>
                            </div>
                        : 
                            <div className="download-steps">

                              <div className="setup-download">
                                  <img src={require("../../assets/images/dashboard/download/Group_42588.png").default} alt="download-img" className="img-fluid" />
                                  <div>
                                      <p>Step 1</p>
                                      <h3>Configure your app.</h3>
                                  </div>
                              </div>

                              <div className="setup-download">
                                  <img src={require("../../assets/images/dashboard/download/Group_42589.png").default} alt="download-img" className="img-fluid" />
                                  <div>
                                      <p>Step 2</p>
                                      <h3>Download your app.</h3>
                                  </div>
                              </div>

                              <div className="setup-download">
                                  <img src={require("../../assets/images/dashboard/download/Group_42586.png").default} alt="download-img" className="img-fluid" />
                                  <div>
                                      <p>Step 3</p>
                                      <h3>Publish your app.</h3>
                                  </div>
                              </div>

                            </div>
                        }
                    </>
                  }
              </>
               
              )} 
        


        </div>
      )}
       </BottomScrollListener>

            
            <Modal isOpen={this.state.viewerror} centered className="download-error-popup">
                <ModalHeader >												
                    <div className="security-crossmodal " onClick={() => this.handleErrorPopUp('')}>
                        <img src={require("../../assets/images/dashboard/cross.png").default} />
                    </div>
                </ModalHeader>
                <ModalBody>

                        <div className="download-error-body">
                            <h4><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <g id="Group_41408" data-name="Group 41408" transform="translate(-324.314 -455.386)">
                            <g id="Ellipse_2829" data-name="Ellipse 2829" transform="translate(324.314 455.386)" fill="#fff1f1" stroke="#ff4848" stroke-width="1">
                            <circle cx="8" cy="8" r="8" stroke="none"/>
                            <circle cx="8" cy="8" r="7.5" fill="none"/>
                            </g>
                            <path id="Path_8311" data-name="Path 8311" d="M0,3.438,3.438,0" transform="translate(330.187 461.902) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1"/>
                            <path id="Path_10113" data-name="Path 10113" d="M0,1.118,1.117,0" transform="translate(331.826 466.634) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1"/>
                            </g></svg>Your last build failed</h4>
                            <h5>
                              It seems your app build has encountered an issue.<br/>
                              {this.state.builderrordescription}
                            </h5>
                            <button onClick={() => this.handleErrorPopUp('')}>Okay</button>
                        </div>											
                </ModalBody>
            </Modal>

            <Modal isOpen={this.state.openDownloadPopup} centered className="download-error-popup">
                <ModalHeader >												
                    <div className="security-crossmodal " onClick={this.handleAttributePopUp}>
                        <img src={require("../../assets/images/dashboard/cross.png").default} />
                    </div>
                </ModalHeader>
                <ModalBody>

                        <div className="download-error-body">
                            <h4><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                            <g id="Group_41408" data-name="Group 41408" transform="translate(-324.314 -455.386)">
                            <g id="Ellipse_2829" data-name="Ellipse 2829" transform="translate(324.314 455.386)" fill="#fff1f1" stroke="#ff4848" stroke-width="1">
                            <circle cx="8" cy="8" r="8" stroke="none"/>
                            <circle cx="8" cy="8" r="7.5" fill="none"/>
                            </g>
                            <path id="Path_8311" data-name="Path 8311" d="M0,3.438,3.438,0" transform="translate(330.187 461.902) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1"/>
                            <path id="Path_10113" data-name="Path 10113" d="M0,1.118,1.117,0" transform="translate(331.826 466.634) rotate(-45)" fill="none" stroke="#ff4848" stroke-width="1"/>
                            </g></svg>Your last build failed</h4>
                            <h5>It seems your app build has encountered an issue.</h5>
                            <button onClick={this.handleAttributePopUp}>Okay</button>
                        </div>											
                </ModalBody>
            </Modal>

            {/* <Modal
          isOpen={this.state.buildapp}
          toggle={this.togglebuildapp}
          style={{ maxWidth: "680px", maxHeight: "350px" }}
          className="download-error-popup"
          centered
        >
          <ModalHeader
            toggle={this.togglebuildapp}
            className="modal-xxl-content modal-categories update-type"
          >
            <div className="crossmodal mt-1" onClick={this.togglebuildapp}>
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="conecttoapi publish-app-modal">
            <div className="text-center mb-md-5 mb-3">
              <h2>Ready to build your app? </h2>
            </div>
            <div className="text-center mb-md-5 mb-4">
              <img
                src={
                  require("../../assets/images/dashboard/build-app.png").default
                }
                alt="Connecting"
                className="img-fluid"
              />
            </div>
            <div className="text-center mb-md-5 mb-4">
              <p>
                While you can update almost everything about your app once it is
                built, the App Name, App Icon and Launch Screen will be locked.
                Don’t worry, you can still change these later, but a new app
                version will be required with a new build.
              </p>
            </div>
            <div className="text-center mb-4 d-flex website-type align-items-center justify-content-center">
              <div
                onClick={(e) => this.togglebuildapp(e)}
                className="cancel-btn"
              >
                Cancel
              </div>

              {this.state.buildplatform === "ios" ? (
                <div
                  onClick={(e) => this.iosdataform(e)}
                  className="update-btn ml-3"
                >
                  Confirm
                </div>
              ) : (
                <div
                  onClick={(e) => this.handleandroidpopup(e)}
                  className="update-btn ml-3"
                >
                  Confirm
                </div>
              )}
            </div>
          </ModalBody>
        </Modal> */}

        <Modal
          isOpen={this.state.pointsmodal}
          toggle={this.pointsmodalclick}
          style={{ maxWidth: "680px", maxHeight: "247px" }}
          className="download-error-popup"
          centered
        >
          <ModalHeader
            toggle={this.pointsmodalclick}
            className="modal-xxl-content"
          >
            <div className="crossmodal mt-1" onClick={this.pointsmodalclick}>
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="completesteps">
            <h4>Complete all the steps to download your app</h4>
            <img
              src={
                require("../../assets/images/dashboard/completesteps.png")
                  .default
              }
              alt="step"
            />
            <p>
              Designing your app and connecting it with your website is
              mandatory to download it.
            </p>
            <div className="buttonbox">
              <p onClick={this.pointsmodalclick}>Complete all Steps</p>
            </div>
          </ModalBody>
        </Modal>

        <Modal isOpen={this.state.cancelmodal} centered className="download-reset-popup">
                <ModalHeader >												
                    <div className="security-crossmodal " onClick={this.cancelmodalclick}>
                        <img src={require("../../assets/images/dashboard/cross.png").default} />
                    </div>
                </ModalHeader>
                <ModalBody>

                        <div className="download-reset-body">
                            <h4>Are you sure you want to cancel this build?</h4>
                            <h5>Cancelling will result in the loss of any progress made on your app build.</h5>
                            <div className="download-reset-footer">
                                <button onClick={this.cancelmodalclick}
                                  style={{
                                    // backgroundColor:localStorage.getItem('button_background_color'),
                                    border:'1px solid '+localStorage.getItem('button_background_color'),
                                    color:localStorage.getItem('button_background_color'),
                                    "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
                                    "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),
                                    "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                    }}
                                >Cancel</button>
                                <button  onClick={this.handlecancelbuild}
                                 style={{
                                  backgroundColor:localStorage.getItem('button_background_color'),
                                  // border:'1px solid '+localStorage.getItem('button_background_color'),
                                  color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                }}
                                
                                ><div className={this.state.loader_remove}></div><span className={this.state.submitval_confirmpop}>Confirm</span></button>
                            </div>
                        </div>											
                </ModalBody>
            </Modal>
        
            <Modal isOpen={this.state.unlinkpopup} centered className="download-reset-popup">
                <ModalHeader >												
                    <div className="security-crossmodal " onClick={this.unlinkmodalclick}>
                        <img src={require("../../assets/images/dashboard/cross.png").default} />
                    </div>
                </ModalHeader>
                <ModalBody>

                        <div className="download-reset-body">
                            <h4>Are you sure you want to unlink?</h4>
                            <h5>This action will disconnect the merged apps.</h5>
                            {/* <h5>Build take to 10 minutes to 15 minutes.</h5> */}
                            <div className="download-reset-footer">
                                <button onClick={this.unlinkmodalclick}>Cancel</button>
                                <button style={{
                                            backgroundColor:localStorage.getItem('button_background_color'),
                                            color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                          }} onClick={this.unlinkapp}><div className={this.state.loader_remove1}></div><span className={this.state.submitval_confirmpop1}>Confirm</span></button>
                            </div>
                        </div>											
                </ModalBody>
            </Modal>

        <Modal
          isOpen={this.state.rebuildpopup}
          style={{ maxWidth: "460px", maxHeight: "240px" }}
          className="generalstngs1"
          centered
        >
          <ModalHeader>
          <div className="crossmodal mt-1" onClick={(e) => this.handleRebuildPopup(e)}>
              <img src={require("../../assets/images/dashboard/cross.png").default} />
            </div>
				</ModalHeader>

          <ModalBody>
            <div>
              <h4>Build limit reached</h4>
              <h6>You have exceeded the number of builds allowed on your plan. Upgrade now to continue creating more app builds.</h6>
            
              <div className="generalstngs1-footer">

                <div className="generalstngs1-ok-grey">
                <p onClick={(e) => this.handleRebuildPopup(e)}>Cancel</p>
                </div>
                <Link to={(parseInt(localStorage.getItem('website_technology')) === 4) ?
                          "/app/wordpress-pricing/"+btoa(localStorage.getItem('Appid')) 
                        : (parseInt(localStorage.getItem('website_technology')) === 5) ?
                          "/app/woocommerce-pricing/"+btoa(localStorage.getItem('Appid'))
                        : (parseInt(localStorage.getItem('website_technology')) === 2) ?
                          "/app/web-to-app-pricing/"+btoa(localStorage.getItem('Appid'))
                        : (parseInt(localStorage.getItem('website_technology')) === 3) ?
                          "/app/custom-app-pricing/"+btoa(localStorage.getItem('Appid'))
                        : "#"} 
                      className="generalstngs1-ok">
                <p>Upgrade</p>
                </Link>
              </div>
            </div>
          </ModalBody>
        </Modal>


            </section>
    </>
    );
  }
}
