import axios from "axios";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
import { Redirect, useHistory } from "react-router-dom";
import moment from "moment";
import oldS3 from "react-aws-s3";
import {S3} from 'aws-sdk';
import { CustomS3Client } from "../../utils/S3utils";

const Url = process.env.REACT_APP_API_URL + "api/cms/";
const dashboardUrl = process.env.REACT_APP_API_URL + "api/app/";
const NewIconUrl = process.env.REACT_APP_API_URL + "api/newlist-icons";
const userUrl = process.env.REACT_APP_API_URL + "api/user/";
const freePickIconUrl = process.env.REACT_APP_API_URL + "api/app/get-icons";

//Image upload API

export const cmsImageUpload = (param, setImageIdfun) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "mediaUpload", param, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setImageIdfun(res.data.media_id);
        // sucesstoast("Image upload successfully");
      } else {
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
      
    });
};

//image upload by API URL(PEXELS,UNSPLASH)
export const thirdPartyImgUpload = async (
  param,
  setBtnVal,
  setLoader,
  setIsGalleryPopup,
  settingImgtoArray,
  classCompoState,
  setTempListingThumb,
  setListingThumb,
  listingThumb,
  location,
  cmsArray,
  fromState,
  setThumbnailActive
) => {
  const newFileName ="ams_icon_" +new Date().getTime() +"_" +Math.floor(1000 + Math.random() * 9000);
  const config = {
    dirName: param.path.replace(/\/$/, "")
  };
  const response = await fetch('https://'+param.filename);
  if (!response.ok) {
    throw new Error(`Failed to fetch image from URL: ${response.statusText}`);
  }
  const file = await response.blob();
  const customS3Client = new CustomS3Client(config);
  customS3Client.uploadFile(file, newFileName)
  .then((res) => {
    if (res.status === 204) {
      setBtnVal("Done");
      setLoader("d-none");
      // setTempListingThumb(newFileName)
      if(classCompoState){
        classCompoState.setState({
          modal:false,
          isGalleryPopup:false
        })
      }
      else{

        setIsGalleryPopup(false);
      }
      settingImgtoArray(`${newFileName}.${file.type.split("/")[1]}`, "apiImg");
      // if (!listingThumb && location.pathname?.includes("create")) {
      //   if(fromState==="thumbnail"){
      //     setThumbnailActive(true)
      //   }
      //   setTempListingThumb(cmsArray?.find((row) => row.item_type === "image")?.portal_value?.source_file || "");
      //   setListingThumb(cmsArray?.find((row) => row.item_type === "image")?.portal_value?.source_file || "")
      // }
     
    } else {
      setBtnVal("Done");
      setLoader("d-none");
      errortoast("Oh no! Something’s not right.");
    }
  })
  .catch((err) => {
    setBtnVal("Done");
    setLoader("d-none");
    // errortoast("Oh no! Something’s not right.");
    
  });
};

//image upload by API URL(PEXELS,UNSPLASH) 2nd variant
export const thirdPartyImgUploadViaURL = (
  param,
  successFn
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "uploaddataonamazon", param, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
      
       
        successFn(res.data.data.name);
      } 
    })
    .catch((err) => {
      
      
    });
};
// CMS PAGES API Start =====>

//CMS Get All Pages API
export const cmsGetPagesApi = (
  setPagesData,
  appID,
  setSpinner,
  callBack,
  limit,
  page,
  pagesData,
  setHitNextPagination,
  setPaginationSpinner,
  setPagesDataCopy,
  ) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + `getPages/${appID}?page=${page?page:1}&limit=${limit?limit:20}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setSpinner(false);
        if(setHitNextPagination){

          setHitNextPagination((res.data.data.length > 0 && res.data.data.length===limit) ?true:false);
        }

        if(setPaginationSpinner){
          setPaginationSpinner(false);
        }

            let ntfMergeData = [];
            if(page && page > 1){
              ntfMergeData = pagesData.concat(res.data.data);
              setPagesData(ntfMergeData);
              setPagesDataCopy && setPagesDataCopy(res.data.data);
            }else{
                setPagesData(res.data.data);
                setPagesDataCopy && setPagesDataCopy(res.data.data);
            }
      if(callBack){
        callBack(res.data.data,ntfMergeData);
      }
        
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      
    });
};

//CMS Saving Page Data
export const cmsSavePageApi = (
  params,
  setIsLoader,
  setSubmitval,
  setIsLoader2,
  setSubmitval2,
  redirectFun,
  status,
  setUpdatedAt
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "insertPages", params, {
      headers: headers,
    })
    .then((res) => {
      setIsLoader("d-none");
      setSubmitval("Publish");
      setIsLoader2("d-none");
      setSubmitval2("Save draft");
      if (res.data.code === 200) {
        sucesstoast("Your changes have been saved");
        setUpdatedAt(res.data.data.updated_at);
        redirectFun(res.data.data._id, status);
      } else if (res.data.code === 201) {
        errortoast("Please choose other parent");
      } else {
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
      
      setIsLoader("d-none");
      setSubmitval("Publish");
      setIsLoader2("d-none");
      setSubmitval2("Save draft");
    });
};

//CMS Saving Page Data from listing screen
export const cmsSavePageApiForListing = (
  params,
 setBottomSpinner,
 callBack
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "insertPages", params, {
      headers: headers,
    })
    .then((res) => {
      
      if (res.data.code === 200) {
       
        callBack();
       
      } else {
        setBottomSpinner(false);
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((err) => {
      setBottomSpinner(false);
      errortoast("Oh no! Something’s not right.");
      
     
    });
};

//API for Each Page
export const cmsGetEachPageApi = (
  setPagesData,
  pageID,
  setparentId,
  setTempParentId,
  setTitle,
  setSpinner,
  setParentTempName,
  setparentName ,
  setStatusOfPage ,
  setUpdatedAt,
  setAuthorId,
  allPage,
  setBreadcrumbArr,
  setSubData,
  setOldSubData,
  setOldBreadcrumbArr,
) => {


  let newBreadcrumb=[];
  let parentId="";

  function recursNew(arr) {
    arr.map((eachCat) => {
      let obj = {
        name:"",
        id:"",
      }
    
      if(eachCat._id === parentId){
            
        parentId = eachCat.parent;
        obj.id = eachCat._id;
        obj.name = eachCat.title;
        newBreadcrumb.push(obj);
      }

     if (eachCat.child !== undefined && eachCat.child.length > 0) {
       // if parent has childrens
       recursNew(eachCat.child);
     }

     // return arr;
   });
 }

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "getPage/" +btoa(localStorage.getItem("Appid"))+"/"+pageID, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
             
        if(res.data.data.content){
          let updatedPagesData=res.data.data.content;
          updatedPagesData && updatedPagesData.map((item,indx)=>{
            if(item.status===undefined &&item.status===null ){
            item.status=1;
            }
          })
          setPagesData(updatedPagesData);
        }else{
          setPagesData(res.data.data.content);
        }
     
        setUpdatedAt(res.data.data.updated_at)
     
        setStatusOfPage(res.data.data.status);
       
        setparentId(res.data.data.parent);
        setTempParentId(res.data.data.parent);
        setparentName(res.data.data.parent_name);
        setParentTempName(res.data.data.parent_name);
        setTitle(res.data.data.title);
        setSpinner(false);
        setAuthorId(res.data.data.author_user_id?res.data.data.author_user_id:res.data.data.user_id)
        let newSubData = [];
        let level = 0 ;
        parentId=res.data.data.parent;


        function recurs(arr) {
          arr && arr.map((eachCat)=>{
           recursNew(allPage);
           if (eachCat.child !== undefined && eachCat.child.length > 0) {
             recurs(eachCat.child);
           }
          })
       }
      recurs(allPage);

      newBreadcrumb.splice(0,1)
      newBreadcrumb.reverse();
      setBreadcrumbArr(newBreadcrumb);
      setOldBreadcrumbArr(newBreadcrumb);

      function recursData(arr) {
        arr.map((eachCat) => {
        
         if(newBreadcrumb.length > 0 && eachCat.parent===newBreadcrumb[newBreadcrumb.length-1].id){
       
          newSubData.push(eachCat);
         }
   
         if (eachCat.child !== undefined && eachCat.child.length > 0) {
           // if parent has childrens
           recursData(eachCat.child);
         }
   
         // return arr;
       });
     }
     recursData(allPage);

      setSubData(newSubData);
      setOldSubData(newSubData);


      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      
    });
};

//CMS deleting Page Data
export const cmsDeletePageApi = (params, setisDeleted, isDeleted) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "deletePage", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("Page deleted successfully")
        setisDeleted(!isDeleted);
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
      
      setisDeleted(!isDeleted);
    });
};

//If page id exist : Get parent list (Edit case)
export const cmsGetParentApi = (setAllPage, pageID) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "getPageParentLists/"+     btoa(localStorage.getItem("Appid"))+"/" + pageID, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setAllPage(res.data.data);
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      
    });
};

// CMS PAGES API End =====>

// CMS POSTS API Start ====>

//CMS Get All Post API
export const cmsGetPostsApi = (
  setAllPost,
  appID,
  setSpinner,
  finalFilterVal ,
  limit,
  page,
  pagesData,
  setSubData,
  setHitNextPagination,
  setPaginationSpinner,
  setPostDataCopy,
) => {

 
  // ${`?tag_filter=`+"64c251665676b18ed3044c14&category_filter=64b6b1bd00b9aa311c0e7542&status_filter=publish"}

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
 
  axios
    .get(Url + `getPosts/${appID}?page=${page?page:1}&limit=${limit?limit:20}${finalFilterVal?finalFilterVal:""}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setSpinner(false);
        if(setHitNextPagination){

          setHitNextPagination((res.data.data.length > 0 && res.data.data.length===limit) ?true:false);
        }

        if(setPaginationSpinner){
          setPaginationSpinner(false);
        }

            let ntfMergeData = [];
            if(page && page > 1){
              ntfMergeData = pagesData.concat(res.data.data);
              setAllPost(ntfMergeData);
              setPostDataCopy && setPostDataCopy(ntfMergeData);
              if(setSubData){
                setSubData(ntfMergeData);
              }
            }else{
              setAllPost(res.data.data);
              setPostDataCopy && setPostDataCopy(res.data.data);
              if(setSubData){
                setSubData(res.data.data);
              }
            }
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      
    });
};

//CMS Saving Post Data
export const cmsSavePostApi = (
  params,
  setIsLoader,
  setIsLoader2,
  setSubmitval,
  setSubmitval2,
  redirectFun,
  status,
  setUpdatedAt
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "insertPosts", params, {
      headers: headers,
    })
    .then((res) => {
      setIsLoader("d-none");
      setIsLoader2("d-none");
      setSubmitval("Publish");
      setSubmitval2("Save draft");
      if (res.data.code === 200) {
        sucesstoast("The record has been updated.");
        setUpdatedAt(res.data.data.updated_at);
        redirectFun(res.data.data._id, status);
      } else {
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
      
      setIsLoader("d-none");
      setIsLoader2("d-none");
      setSubmitval("Publish");
      setSubmitval2("Save draft");
    });
};

//CMS Saving Post Data
export const cmsSavePostApiForListing = (
  params,
  setBottomSpinner,
  callBack
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "insertPosts", params, {
      headers: headers,
    })
    .then((res) => {
     
      if (res.data.code === 200) {
        callBack();
      } else {
        setBottomSpinner(false);
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((err) => {
      setBottomSpinner(false);
      errortoast("Oh no! Something’s not right.");
      
    
    });
};


//API for Each Post
export const cmsGetEachPostApi = (
  setPagesData,
  postID,
  setExcerpt,
  setIsFeatured,
  setTitle,
  setCatArrForPost,
  setTagArrForPost,
  setSpinner,
  apiCall,
  setDateTimeValue,
  setPublishImmediate,
  setShowCat,
  setShowTag,
  setStatusOfPost ,
  setUpdatedAt,
  setShowAuthor ,
  setPostRelatedTo,
  setAuthorId ,
  setShowPublishDate , 
  setShowRelatedPosts ,
  setOverrideMasterSettings ,
  setTempListingThumb,
  setListingThumb ,
  setSelectedAtrIdsArr,
  setCatSelection,
  setTempCatSelection,
  setAtrSelection,
  setTempAtrSelection,
  setTagSelection,
  setTempTagSelection,
  setMasterSetting,
  setSectionTopMargin,
  setSectionBottomMargin,
  setSectionLeftMargin,
  setSectionRightMargin,
  setImgShapeForDetails,
  setImgCenterForDetails,
  setImgShapeForListing,
  setImgRatioForListing,
  setImgCenterForListing,
  postState,
  setPostState,
  setThumbnailActive
) => {

  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "getPost/" +    btoa(localStorage.getItem("Appid")) + "/"+postID, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        let newCatSelection=[];
        let newAtrSelection=[];
        let newTagSelection=[];
        let newArrOfIds=[];
        setMasterSetting(res.data.master_settings?JSON.parse(res.data.master_settings):null);

        let masterSettings = res.data.master_settings?JSON.parse(res.data.master_settings):null;

        let newPostSettings = {...postState.settings};
        newPostSettings.showExcerptForListing = masterSettings?masterSettings.show_excerpt?true:false:false;
        newPostSettings.showPublishDateForListing = masterSettings?masterSettings.show_publish_date_for_listing?true:false:false;
        setPostState({
          settings:newPostSettings
        });

        setImgCenterForListing( 
          (masterSettings?.show_center_crop_images_on_post_listing_page!==undefined && masterSettings?.show_center_crop_images_on_post_listing_page!==null )?
           masterSettings?.show_center_crop_images_on_post_listing_page === 1 ? true : false :true );
        setImgRatioForListing(masterSettings?.image_ratio_on_post_listing_page?masterSettings?.image_ratio_on_post_listing_page:"4:3");
        setImgShapeForListing(masterSettings?.image_shape_on_post_listing_page?masterSettings?.image_shape_on_post_listing_page:"soft_corner");





        setImgCenterForDetails(
          (
            res.data.master_settings && 
            JSON.parse(res.data.master_settings).show_center_crop_images_on_post_detail_page!==undefined && 
            JSON.parse(res.data.master_settings).show_center_crop_images_on_post_detail_page!==null && 
            JSON.parse(res.data.master_settings).show_center_crop_images_on_post_detail_page!==""
            )? 
            JSON.parse(res.data.master_settings).show_center_crop_images_on_post_detail_page === 1 ? true :false
            :true
        )

        setImgShapeForDetails(
        (
        res.data.master_settings && 
        JSON.parse(res.data.master_settings).image_shape_on_post_detail_page!==undefined && 
        JSON.parse(res.data.master_settings).image_shape_on_post_detail_page!==null && 
        JSON.parse(res.data.master_settings).image_shape_on_post_detail_page!==""
        )? 
        JSON.parse(res.data.master_settings).image_shape_on_post_detail_page
        :"soft_corner"
      )

        
        setSectionTopMargin(
          (
          res.data.master_settings && 
          JSON.parse(res.data.master_settings).section_top_margin_on_post_detail_page!==undefined && 
          JSON.parse(res.data.master_settings).section_top_margin_on_post_detail_page!==null && 
          JSON.parse(res.data.master_settings).section_top_margin_on_post_detail_page!=="" 
          )?
          JSON.parse(res.data.master_settings).section_top_margin_on_post_detail_page:
          "7.5"
        )

        setSectionBottomMargin(
          (
          res.data.master_settings && 
          JSON.parse(res.data.master_settings).section_bottom_margin_on_post_detail_page!==undefined && 
          JSON.parse(res.data.master_settings).section_bottom_margin_on_post_detail_page!==null && 
          JSON.parse(res.data.master_settings).section_bottom_margin_on_post_detail_page!=="" 
          )?
          JSON.parse(res.data.master_settings).section_bottom_margin_on_post_detail_page:
          "7.5"
        )
        setSectionLeftMargin(
          (
          res.data.master_settings && 
          JSON.parse(res.data.master_settings).section_left_margin_on_post_detail_page!==undefined && 
          JSON.parse(res.data.master_settings).section_left_margin_on_post_detail_page!==null && 
          JSON.parse(res.data.master_settings).section_left_margin_on_post_detail_page!=="" 
          )?
          JSON.parse(res.data.master_settings).section_left_margin_on_post_detail_page:
          "15"
        )
        setSectionRightMargin(
          (
          res.data.master_settings && 
          JSON.parse(res.data.master_settings).section_right_margin_on_post_detail_page!==undefined && 
          JSON.parse(res.data.master_settings).section_right_margin_on_post_detail_page!==null && 
          JSON.parse(res.data.master_settings).section_right_margin_on_post_detail_page!=="" 
          )?
          JSON.parse(res.data.master_settings).section_right_margin_on_post_detail_page:
          "15"
        )

        setUpdatedAt(res.data.data.updated_at);
        setStatusOfPost(res.data.data.status);
        setTempListingThumb(res.data.data.source_file);
        setListingThumb(res.data.data.source_file);
        setThumbnailActive(res.data.data.source_file ? true : false);
        if(res.data.data.category_info){
          setCatArrForPost(res.data.data.category_info);
          res.data.data.category_info.map((row1)=>{
            newCatSelection.push(row1._id);
          });
          setCatSelection(newCatSelection);
          setTempCatSelection(newCatSelection);

        }else{
          setCatArrForPost([]);
          setCatSelection([]);
          setTempCatSelection([]);
        }
        if(res.data.data.tags_info){
          setTagArrForPost(res.data.data.tags_info);
          res.data.data.tags_info.map((row1)=>{
            newTagSelection.push(row1._id);
          });
          setTagSelection(newTagSelection);
          setTempTagSelection(newTagSelection);
        }else{
          setTagArrForPost([]);
          setTagSelection([]);
          setTempTagSelection([]);
        }


      
        if(res.data.data.content){
          let updatedPagesData=res.data.data.content;
          updatedPagesData && updatedPagesData.map((item,indx)=>{
            if(item.status===undefined &&item.status===null ){
            item.status=1;
            }
            if( (item.item_type==="custom_ad" || item.item_type==="banner_ad") && Array.isArray(item.value)){
             item.value={ad_id:"",ad_name:""}
            }
          })
          setPagesData(updatedPagesData);
        }else{
          setPagesData(res.data.data.content);
        }
        setExcerpt(res.data.data.excerpt);
        setIsFeatured(res.data.data.is_featured);
        setSpinner(false);
        setTitle(res.data.data.title);
        
        
        
        setPublishImmediate(res.data.data.published_immediate);
        setShowCat( (res.data.data.show_categories!==null && res.data.data.show_categories!==undefined  ) ? 
        res.data.data.show_categories===1 ? true:false 
        : true ) ;
        setShowTag( (res.data.data.show_tags!==null && res.data.data.show_tags!==undefined  ) ? 
        res.data.data.show_tags===1 ? true:false 
        : true );
        setShowAuthor( (res.data.data.show_author!==null && res.data.data.show_author!==undefined  ) ? 
        res.data.data.show_author===1 ? true:false 
        : true );
        setShowPublishDate( (res.data.data.show_publish_date!==null && res.data.data.show_publish_date!==undefined  ) ? 
        res.data.data.show_publish_date===1 ? true:false 
        : true );
        setShowRelatedPosts( (res.data.data.show_related_posts!==null && res.data.data.show_related_posts!==undefined  ) ? 
        res.data.data.show_related_posts===1 ? true:false 
        : true );
        setPostRelatedTo(res.data.data.related_to?res.data.data.related_to:"tags");
        setAuthorId(res.data.data.author_user_id?res.data.data.author_user_id:res.data.data.user_id);
        setOverrideMasterSettings(res.data.data.override_post_master_settings?res.data.data.override_post_master_settings : 0)
        if (
          res.data.data.published_at !== null &&
          res.data.data.published_at !== "" &&
          res.data.data.published_at !== undefined
        ) {
          // let text = res.data.data.published_at;
          // const myArray = text.split(" ");
          // let finalStr = myArray[0] + "T" + myArray[1] + "z";
       
          const dateObj = new Date(
            parseInt(res.data.data.published_at.$date.$numberLong)
          );
          setDateTimeValue(dateObj);
         
        }
        if(res.data.data.attributes_data!==undefined&&res.data.data.attributes_data!==null&&res.data.data.attributes_data.length>0){
          
          
          res.data.data.attributes_data.map((atr)=>{
          newArrOfIds.push(atr.id);
          newAtrSelection.push(atr.id);
          atr.terms && atr.terms.length>0&&atr.terms.map((val)=>{
          newArrOfIds.push(val.id);
          newAtrSelection.push(val.id);
          })
          
          
          })
          setSelectedAtrIdsArr(newArrOfIds);
          setAtrSelection(newAtrSelection);
          setTempAtrSelection(newAtrSelection);
        }else{
          setSelectedAtrIdsArr([]);
        }
       
        apiCall(res.data.data.tags_info, res.data.data.category_info,newArrOfIds);
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
    });
};



//CMS deleting Post Data
export const cmsDeletePostApi = (params, setIsDeleted, isDeleted) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "deletePost", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("Post deleted successfully")
        setIsDeleted(!isDeleted);
        // sucesstoast("Page deleted successfully");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
      
      setIsDeleted(!isDeleted);
    });
};

//change featured
export const isFeaturedPostApi = (params, postData, setPostData, indx) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "isFeaturedPost", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        //  setisDeleted(!isDeleted)
      } else {
        errortoast("Oh no! Something’s not right.");
        let newArr = [...postData];
        newArr[indx].is_featured = !params.is_featured;
        setPostData(newArr);
      }
    })
    .catch((err) => {
      let newArr = [...postData];
      newArr[indx].is_featured = !params.is_featured;
      setPostData(newArr);
      errortoast("Oh no! Something’s not right.");
      
      // setisDeleted(!isDeleted)
    });
};

// Forcely delete Post

export const forcelyDeletePost = (params, setisDeleted, isDeleted) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "forceDeletePosts", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setisDeleted(!isDeleted);
        // sucesstoast("Page deleted successfully");
        // setTimeout(() => {
        //   window.location.reload();
        // }, 2000);
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
      
      setisDeleted(!isDeleted);
    });
};

// Forcely delete Post

export const recyclePostApi = (params, setisDeleted, isDeleted) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "recyclePosts", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setisDeleted(!isDeleted);
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
      
      setisDeleted(!isDeleted);
    });
};

// Category API =========>

//CMS Saving Category Data
export const cmsSaveCategoryApi = (
  params,
  setSubmitval,
  setIsLoader,
  setisSaved,
  isSaved,
  setEachCatData,
  setSpinner,
  setName,
  setContent,
  setparentID,
  setCategoryID,
  setOpenCatPopUp,
  setBottomSpinner
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "insertCategory", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        // setSpinner(true);
        setBottomSpinner(true);
        setOpenCatPopUp(false)
        const obj = {
          name: "",
          image: "",
          portal_value: {
            type: 1,
            source_file: "",
            image_link: "",
            resize_image_link: "",
          },
          parent: 0,
          content: "",
        };
        let arr = [];
        arr.push(obj);
        setIsLoader("d-none");
        setSubmitval("Create");
        setisSaved(!isSaved);
        setEachCatData(arr);
        setName("");
        setContent("");
        setparentID(0);
        sucesstoast("Your changes have been saved");
        setCategoryID("");
      } else if (res.data.code === 201) {
        errortoast("Please choose other parent");
        setIsLoader("d-none");
        setSubmitval("Create");
      } else if (res.data.code === 402) {
        errortoast("Category name already exist");
        setIsLoader("d-none");
        setSubmitval("Create");
      } else {
        setIsLoader("d-none");
        setSubmitval("Create");
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
      
      setIsLoader("d-none");
      setSubmitval("Create");
    });
};

//Get all categories
export const cmsgetCategoryApi = (
  setCategoryData,
  appID,
  setSpinner,
  setBottomSpinner,
  callBack,
  setCatCheckedArr,
  apiCatData,
  setCatDataWithoutTreeStr,
  limit,
  page,
  categoryData,
  setHitNextPagination,
  setPaginationSpinner,
  setSubData,
  
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + `getCategories/${appID}?page=${page?page:1}&limit=${limit?limit:20}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        let maindata = [];
        let newArr = [];

        
        if(setHitNextPagination){

          setHitNextPagination((res.data.data.length > 0 && res.data.data.length===limit) ?true:false);
        }

        if(setPaginationSpinner){
          setPaginationSpinner(false);
        }

            let ntfMergeData = [];
            if(page && page > 1){
              ntfMergeData = categoryData.concat(res.data.data);
              maindata=ntfMergeData;
            }else{
              maindata=res.data.data;
            }

        function recurs(arr) {
          arr = arr.map((eachCat, indx) => {
            
            newArr.push(eachCat);

            if (eachCat.child !== undefined && eachCat.child.length > 0) {
              // if parent has childrens
              recurs(eachCat.child);
            }

            return arr;
          });
        }
        recurs(maindata);

        setSpinner(false);
        if(setBottomSpinner){

          setBottomSpinner(false);
        }
          if (callBack !== undefined && callBack !== null && callBack !== false) {

            setCategoryData(newArr);
            
            let falseArr =[];
          
            for (let i = 0; i < newArr.length; i++) {
              let isEqual=false;
              for (let j = 0; j < apiCatData.length; j++) {
                if (newArr[i]._id === apiCatData[j]._id) {
                  
                  isEqual=true;
                  break;
                //  return ;
                }
              }
            
              falseArr[i]=(isEqual);
            }
          
            setCatCheckedArr(falseArr);
          
        } else {
          if(setCatDataWithoutTreeStr){
            setCatDataWithoutTreeStr(newArr);
          }
          setCategoryData(maindata);

          if(setSubData){
            setSubData(maindata);
          }
        }

      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      
    });
};


//Get all categories for EDIT POST
export const cmsgetCatForEditPost = (
  setCategoryData,
  appID,
  setSpinner,
  callBack,
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "getCategories/" + appID, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setSpinner(false);
        setCategoryData(res.data.data);
        callBack(res.data.data);
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      
    });
};


//Get Category by category id
export const cmsGetEachCategory = (
  catID,
  setEachCatData,
  setName,
  setSpinner2,
  spinner2,
  setparentID,
  setTempParentID,
  setParentName,
  setTempParentName,
  breadcrumbArr,
  setBreadcrumbArr,
  categoryData,
  setSubData,
) => {
  
  let newBreadcrumb=[];
  let parentId="";

  function recursNew(arr) {
    arr.map((eachCat) => {
      let obj = {
        name:"",
        id:"",
      }
    
      if(eachCat._id === parentId){
            
        parentId = eachCat.parent;
        obj.id = eachCat._id;
        obj.name = eachCat.name;
        newBreadcrumb.push(obj);
      }

     if (eachCat.child !== undefined && eachCat.child.length > 0) {
       // if parent has childrens
       recursNew(eachCat.child);
     }

     // return arr;
   });
 }






  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "getCategory/" +     btoa(localStorage.getItem("Appid")) +"/"+catID, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setSpinner2(!spinner2);
        const obj = {
          name: res.data.data.name,
          image: res.data.data.image,
          portal_value: res.data.data.content.portal_value,
          parent: res.data.data.parent,

          content: res.data.data.content,
        };
        let arr = [];
        let newSubData = [];
        let level = 0 ;
        arr[0] = obj;
        setparentID(res.data.data.parent);
        setTempParentID(res.data.data.parent);
        setParentName(res.data.data.parent_name);
        setTempParentName(res.data.data.parent_name);
        setEachCatData(arr);
        setName(res.data.data.name);
        parentId=res.data.data.parent;


        function recurs(arr) {
          arr && arr.map((eachCat)=>{
           recursNew(categoryData);
           if (eachCat.child !== undefined && eachCat.child.length > 0) {
             recurs(eachCat.child);
           }
          })
       }
      recurs(categoryData);

      newBreadcrumb.splice(0,1)
      newBreadcrumb.reverse();
      setBreadcrumbArr(newBreadcrumb);

      function recursData(arr) {
        arr.map((eachCat) => {
        
         if(newBreadcrumb.length > 0 && eachCat.parent===newBreadcrumb[newBreadcrumb.length-1].id){
       
          newSubData.push(eachCat);
         }
   
         if (eachCat.child !== undefined && eachCat.child.length > 0) {
           // if parent has childrens
           recursData(eachCat.child);
         }
   
         // return arr;
       });
     }
     recursData(categoryData);

      setSubData(newSubData);

      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      
      setSpinner2(!spinner2);
    });
};

//Delete category
export const cmsDeleteCategory = (params, setisDeleted, isDeleted) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "deleteCategory", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("Category deleted successfully")
        setisDeleted(!isDeleted);
      }else{
        errortoast("Oh no! Something’s not right.");
        setisDeleted(!isDeleted);
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
      setisDeleted(!isDeleted);
      
    });
};

//Tags Api =============>

//CMS Saving Tag Data
export const cmsSaveTagApi = (
  params,
  setIsLoader,
  setSubmitval,
  setMdlTags,
  setisSaved,
  isSaved,
  setSpinner,
  setBottomSpinner ,
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "insertTags", params, {
      headers: headers,
    })
    .then((res) => {
		if (res.data.code === 200) 
		{
			sucesstoast("Your changes have been saved");
			setIsLoader("d-none");
			setSubmitval("Create");
      setBottomSpinner(true) ;
			// if (params.tag_id === "" || params.tag_id === undefined) {
			//setName("");
			//setTagID("");
			// }
			setisSaved(!isSaved);
			// setSpinner(true);
			setMdlTags(false);
			
		} 
		else if (res.data.code === 402) 
		{
			errortoast("Tag name already exist");
			setIsLoader("d-none");
			setSubmitval("Create");
			//setSpinner(true);
		} 
		else 
		{
			errortoast("Oh no! Something’s not right.");
			setIsLoader("d-none");
			setSubmitval("Create");
			//setSpinner(true);
		}
    })
    .catch((err) => {
		errortoast("Oh no! Something’s not right.");
		
		setIsLoader("d-none");
		setSubmitval("Create");
		//setSpinner(true);
    });
};

//Get all Tags
export const cmsgetTagsApi = (
  setTagsData,
  appID,
  setSpinner,
  setBottomSpinner,
  setTagCheckedArr,
  apiTagData,
  limit,
  page,
  tagsData,
  setHitNextPagination,
  setPaginationSpinner,
  setTagSubData,
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + `getTags/${appID}?page=${page?page:1}&limit=${limit?limit:20}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setSpinner(false);
        if(setBottomSpinner){
          setBottomSpinner(false);
        }
        if(setTagCheckedArr){
          let falseArr =[];
         
          for (let i = 0; i < res.data.data.length; i++) {
            let isEqual=false;
            for (let j = 0; j < apiTagData.length; j++) {
              if (res.data.data[i]._id === apiTagData[j]._id) {
                
                isEqual=true;
                break;
              //  return ;
              }
            }
           
            falseArr[i]=(isEqual);
          }
         
          setTagCheckedArr(falseArr);
        }
        if(setHitNextPagination){

          setHitNextPagination((res.data.data.length > 0 && res.data.data.length===limit) ?true:false);
        }

        if(setPaginationSpinner){
          setPaginationSpinner(false);
        }

            let ntfMergeData = [];
            if(page && page > 1){
              ntfMergeData = tagsData.concat(res.data.data);
              setTagsData(ntfMergeData);
              if(setTagSubData){
                setTagSubData(ntfMergeData);
              }
            }else{
              if(setTagSubData){
                setTagSubData(res.data.data);
              }
              setTagsData(res.data.data);
            }
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      
    });
};

//Get Tag by Tag id
export const cmsGetEachTag = (
  tagID,
  setEachTag,
  setName,
  setSpinner2
  // spinner2
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "getTag/" +    btoa(localStorage.getItem("Appid")) +"/"+tagID, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setName(res.data.data.name);
        setSpinner2(false);
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      
    });
};

//Delete Tag
export const cmsDeleteTag = (params, setisDeleted, isDeleted) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "deleteTag", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setisDeleted(!isDeleted);
        sucesstoast("Tag deleted successfully");
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
      
      setisDeleted(!isDeleted);
    });
};


// Team data

export const teamApiForCMS = (
  params,
  setMergeUserOwnerData,
  
 
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      dashboardUrl +
        "user-management/" +
        params.app_id +
        "?page=1&limit=100&search_field=&timestamp=" +
        new Date().getTime()+"&allow_listing_for_all="+1,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        let ownerDataArr = [];
        let ownerObj={profile_image:res.data.owner_data.profile_image , user_id:res.data.owner_data.id,
           role_id:1, name:res.data.owner_data.name, status:1};
        
           ownerDataArr.push(ownerObj);
           let userDataArr = [];
        if(res.data.data!==undefined && res.data.data!==null && res.data.data.length > 0 ){
         
            res.data.data.map((res)=>{
              let userObj={};
              userObj.profile_image=res.image;
              userObj.user_id=res.user_id;
              userObj.role_id=res.role_id;
              userObj.name=res.profile_name;
              userObj.status=res.status;
              userDataArr.push(userObj);
            })
          
        }
        let mergeUserOwnerData=ownerDataArr.concat(userDataArr) ;
        setMergeUserOwnerData(mergeUserOwnerData);
        // setTeamOwnerData(res.data.owner_data);
        // setTeamInvitedUserData(res.data.data);
      
        
        

   
   
 
      } else if (res.data.code === 401) {
      
   
      } else {
    
      }
    })
    .catch((error) => {});
};


//post setting
export const GetpostapiForFunctionalCompo = (
  params ,
  setShowSearch ,
  setShowGridView , 
  setShowAuthor , 
  setShowPublishDate ,
  setShowCategories ,
  setShowTags , 
  setShowRelatedPosts ,
  setPostRelatedTo ,
  setResetSpinner ,
  setIsWebView ,
  setShowAddInfo ,
  setShowPublishDateForListing , 
  setShowAttribute,
  setShowAttributeAsaList,

  setImgCenterForDetails,
  setImgCenterForListing,
  setImgRatioForDetails,
  setImgRatioForListing,
  setImgShapeForDetails,
  setImgShapeForListing,
  setSectionTopMargin,
  setSectionBottomMargin,
  setSectionLeftMargin,
  setSectionRightMargin,

  postState,
  setPostState,
  ) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(dashboardUrl + "posts-toggle/" + params.app_id, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if (res.data.data) {
          let newPostSettings = {...postState.settings};
           newPostSettings.showExcerptForListing = (res.data.data.show_excerpt !== undefined && res.data.data.show_excerpt !== null )
           ? res.data.data.show_excerpt === 1 ? true : false 
           : false
           newPostSettings.settingUpdatedAt = res.data.data.updated_at||"";
           newPostSettings.showExcerptForListing = res.data.data?res.data.data.show_excerpt?true:false:false;
           newPostSettings.showPublishDateForListing = res.data.data?res.data.data.show_publish_date_for_listing?true:false:false;

           newPostSettings.showFilterIconOnList = res.data.data?res.data.data.show_filter_on_post_listing_page?true:false:false;
           newPostSettings.showSortIconOnList = res.data.data?res.data.data.show_sorting_on_post_listing_page?true:false:false;

           newPostSettings.showTotalCountOnList = res.data.data?res.data.data.show_total_count_on_post_listing_page?true:false:false;
           newPostSettings.showAuthorOnList = res.data.data?res.data.data.show_author_on_post_listing_page?true:false:false;
           newPostSettings.showShareIconOnDetails = res.data.data?res.data.data.enable_share_on_post_detail_page?true:false:false;
           newPostSettings.showMediaInFullScreenOnDetails = res.data.data?res.data.data.show_media_in_full_screen_on_post_detail_page?true:false:false;
           newPostSettings.showMediaAsThumbnailOnDetails = parseInt(localStorage.getItem("website_technology")) !== 3 ? false : res.data.data?res.data.data.show_media_as_thumbnail_on_post_detail_page?true:false:false;
           newPostSettings.showBookmarkIconInHeader = 
           parseInt(localStorage.getItem("website_technology")) === 3 ? 
           (res.data.data?.show_bookmark_icon_on_post_listing_page!==undefined && res.data.data?.show_bookmark_icon_on_post_listing_page!==null )?
           res.data.data.show_bookmark_icon_on_post_listing_page === 1 ? true : false :true:false;
           newPostSettings.enableBookmarkingOnList =
           parseInt(localStorage.getItem("website_technology")) === 3 ? 
           (res.data.data.enable_bookmarking!==undefined && res.data.data.enable_bookmarking!==null )?
           res.data.data.enable_bookmarking === 1 ? true : false :true :false;

          setPostState({settings:newPostSettings});


          setImgCenterForDetails( 
            (res.data.data.show_center_crop_images_on_post_detail_page!==undefined && res.data.data.show_center_crop_images_on_post_detail_page!==null )?
             res.data.data.show_center_crop_images_on_post_detail_page === 1 ? true : false :true );
          setImgCenterForListing( 
            (res.data.data.show_center_crop_images_on_post_listing_page!==undefined && res.data.data.show_center_crop_images_on_post_listing_page!==null )?
             res.data.data.show_center_crop_images_on_post_listing_page === 1 ? true : false :true );
          setImgRatioForDetails(res.data.data.image_ratio_on_post_detail_page?res.data.data.image_ratio_on_post_detail_page:"4:3");
          setImgRatioForListing(res.data.data.image_ratio_on_post_listing_page?res.data.data.image_ratio_on_post_listing_page:"4:3");
          setImgShapeForDetails(res.data.data.image_shape_on_post_detail_page?res.data.data.image_shape_on_post_detail_page:"soft_corner");
          setImgShapeForListing(res.data.data.image_shape_on_post_listing_page?res.data.data.image_shape_on_post_listing_page:"soft_corner");
          setSectionTopMargin(res.data.data.section_top_margin_on_post_detail_page?res.data.data.section_top_margin_on_post_detail_page:"7.5");
          setSectionBottomMargin(res.data.data.section_bottom_margin_on_post_detail_page?res.data.data.section_bottom_margin_on_post_detail_page:"7.5");
          setSectionLeftMargin(res.data.data.section_left_margin_on_post_detail_page?res.data.data.section_left_margin_on_post_detail_page:"15");
          setSectionRightMargin(res.data.data.section_right_margin_on_post_detail_page?res.data.data.section_right_margin_on_post_detail_page:"15");

          if(setResetSpinner){
            setResetSpinner(false);
          }
          if(setShowAttribute){
            setShowAttribute(
              ( res.data.data.show_attributes_on_detail_page===undefined ||
                 res.data.data.show_attributes_on_detail_page===null ) ? true
              :
              res.data.data.show_attributes_on_detail_page===1?true:false
              
              );
          }
          if(setShowAttributeAsaList){
            setShowAttributeAsaList( ( res.data.data.show_attributes_terms_as_list_on_detail_page===undefined ||
              res.data.data.show_attributes_terms_as_list_on_detail_page===null ) ? true
           :
           res.data.data.show_attributes_terms_as_list_on_detail_page===1?true:false);
          }

          setShowSearch(res.data.data.enable_post_search===1 ? true : false);
           setShowGridView (res.data.data.posts_view_style==="grid" ? true : false); 
           setShowAuthor (res.data.data.show_author===1 ? true : false); 
           setShowPublishDate (res.data.data.show_publish_date===1 ? true : false);
           setShowCategories (res.data.data.show_categories===1 ? true : false);
          setShowTags (res.data.data.show_tags===1 ? true : false); 
          setShowRelatedPosts (res.data.data.show_related_posts===1 ? true : false); 
          setPostRelatedTo (res.data.data.related_to?res.data.data.related_to:"tags");
          if(setIsWebView){
            setIsWebView(
              parseInt(localStorage.getItem("website_technology")) === 3 ? false :
              res.data.data.enable_web_view_interface_bool === 1 ? true : false)
          }
          if(setShowAddInfo){
            setShowAddInfo(res.data.data.show_additional_information_bool === 1 ? true : false)
          }
          if(setShowPublishDateForListing!==undefined &&setShowPublishDateForListing!==null && setShowPublishDateForListing!==false){
            setShowPublishDateForListing(res.data.data.show_publish_date_for_listing===1 ? true :false
        )
          }
           
      
        }else{
          if(setResetSpinner){
            setResetSpinner(false);
          }
          setShowSearch(true);
           setShowGridView (true); 
           setShowAuthor (true); 
           setShowPublishDate (true);
           setShowCategories (true);
          setShowTags (true); 
          setShowRelatedPosts ( true); 
          setPostRelatedTo ("tags");
          if(setIsWebView){
            setIsWebView(
              parseInt(localStorage.getItem("website_technology")) === 3 ? false : true)
          }
          if(setShowAddInfo){
            setShowAddInfo(true)
          }
        }
      }else{
        if(setResetSpinner){
          setResetSpinner(false);
        }
      }
    })
    .catch((error) =>
    {
       
       if(setResetSpinner){
        setResetSpinner(false);
      }
    }
    );
};

export const PostapiForFunctionalCompo = (params, setSettingSaveBtnLoader ,setSettingSaveBtnVal,postState,setPostState) => {
  
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(dashboardUrl + "save-posts-toggle", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        
        let newPostSettings = {...postState.settings};
        newPostSettings.settingUpdatedAt = res.data.data.updated_at||"";
        setPostState({settings:newPostSettings});

        sucesstoast("Your changes have been saved.");
        setSettingSaveBtnLoader("d-none");
        setSettingSaveBtnVal("Save");
      } else {
        setSettingSaveBtnLoader("d-none");
        setSettingSaveBtnVal("Save");
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((error) => {
      
      setSettingSaveBtnLoader("d-none");
      setSettingSaveBtnVal("Save");
      errortoast("Oh no! Something’s not right.");
     
    });
};


// Upload any video to s3
// export const uploadAnyVideotos3 = (
//   params,
//   settingYourdata
// ) => {


//   //Usage example:
//   let file = params.file;

  

//   //send to s3
//   const config = {
//     bucketName: process.env.REACT_APP_bucketName,
//     dirName: params.path.replace(/\/$/, "") /* optional */,
//     region: process.env.REACT_APP_region,
//     accessKeyId: process.env.REACT_APP_accessKeyId,
//     secretAccessKey: process.env.REACT_APP_secretAccessKey,
//     s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
    
//   };
//   const ReactS3Client = new S3(config);
//   const newFileName = params.filename.split('.')[0];


//   ReactS3Client.uploadFile(file,newFileName)
//     .then((data) => {
//       if (data.status === 204) {
//         // sucesstoast("Video uploaded")
//         settingYourdata(newFileName+"."+file.type.replace("video/",""));

//       }
//     })
//     .catch((err) => {
     

   
//     });
// };

// Upload any video to s3
export const uploadAnyVideotos3 = async (
  params,
  settingYourdata,
  setIsStartUploading,
  setProgress ,
  setBtnVal ,
  setIsBtnDisable ,
  uploadVideoHeight ,
  uploadVideoWidth ,
  setVideoFileName,
  videoFileExtension
) => {
  setIsStartUploading(true);
  setIsBtnDisable(true);
  //Usage example:
  let file = params.file;
  const newFileName = params.filename.split('.')[0];
    //send to s3
    const config = {
      bucketName: process.env.REACT_APP_bucketName,
      region: process.env.REACT_APP_region,
      accessKeyId: process.env.REACT_APP_accessKeyId,
      secretAccessKey: process.env.REACT_APP_secretAccessKey,
      dirName: params.path.replace(/\/$/, "") /* optional */,
      s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
      onProgressSetter : setProgress
    };
  

  const bucketName = process.env.REACT_APP_bucketName;
  const region = process.env.REACT_APP_region;
  const accessKeyId = process.env.REACT_APP_accessKeyId;
  const secretAccessKey = process.env.REACT_APP_secretAccessKey;
  const dirName = params.path.replace(/\/$/, "");
  const s3Url = process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "");
  
  

  const s3 = new S3({
    region,
    accessKeyId,
    secretAccessKey,
  });
  


  const param1 = {
    Bucket: bucketName,
    Key:  `${params.path.replace(/\/$/, "")}/${newFileName}.${file.type ? file.type.replace("video/",""):videoFileExtension}`,
    Body: file,
    ContentType:"video/mp4"
  };
  
  // const request = s3.upload(param1);

  // request.on('httpUploadProgress', (progressData) => {
  //   const percentCompleted = Math.round((progressData.loaded / progressData.total) * 100);
  //   setProgress(percentCompleted);
  //   setIsStartUploading(true);
  //   setIsBtnDisable(true);
   
  // });

  setIsStartUploading(true);
  const customS3Client = new CustomS3Client(config);
  customS3Client.uploadFile(file, newFileName)
  .then((res) => {
    settingYourdata(newFileName+"."+ (file.type ? file.type.replace("video/",""):videoFileExtension),uploadVideoHeight,uploadVideoWidth);
    setVideoFileName(newFileName+"."+(file.type ? file.type.replace("video/",""):videoFileExtension));
    setIsStartUploading(false);
    setIsBtnDisable(true);
  }).catch((error) => {
    errortoast("Oh no! Something’s not right.")
    setBtnVal("Upload");
    setIsBtnDisable(false);
    setIsStartUploading(false);
    
  })

  // try {
  //   const result = await request.promise();
  //   settingYourdata(newFileName+"."+ (file.type ? file.type.replace("video/",""):videoFileExtension),uploadVideoHeight,uploadVideoWidth);
  //   setVideoFileName(newFileName+"."+(file.type ? file.type.replace("video/",""):videoFileExtension));
  
  //   setIsStartUploading(false);
  //   setIsBtnDisable(true);
   
  // } catch (error) {
  //   errortoast("Oh no! Something’s not right.")
  //   setBtnVal("Upload");
  //   setIsBtnDisable(false);
  //   setIsStartUploading(false);
    
  // }
};

// Hide / Unhide Api

export const hideUnhidePostApi = (params,postData,setPostData,indx) => {
  
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "post/update-display-status", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
       
        // sucesstoast("Your changes have been saved.");
        // setSettingSaveBtnLoader("d-none");
        // setSettingSaveBtnVal("Save");
      } else {
        let newArr = [...postData];
        newArr[indx].show_in_results = params.show_in_results===1||params.show_in_results===undefined||params.show_in_results===null?0:1;
         setPostData(newArr);
        
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((error) => {
      
      let newArr = [...postData];
      newArr[indx].show_in_results = params.show_in_results===1||params.show_in_results===undefined||params.show_in_results===null?0:1;
       setPostData(newArr);
      
      errortoast("Oh no! Something’s not right.");
     
    });
};

export const hideUnhidePageApi = (params, pagesData ,setPagesData,indx) => {
  
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "page/update-display-status", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
       
        // sucesstoast("Your changes have been saved.");
        // setSettingSaveBtnLoader("d-none");
        // setSettingSaveBtnVal("Save");
      } else {
        let newArr = [...pagesData];
        newArr[indx].show_in_results = params.show_in_results===1||params.show_in_results===undefined||params.show_in_results===null?0:1;
         setPagesData(newArr);
        
        errortoast("Oh no! Something’s not right.");
      }
    })
    .catch((error) => {
      
      let newArr = [...pagesData];
      newArr[indx].show_in_results = params.show_in_results===1||params.show_in_results===undefined||params.show_in_results===null?0:1;
      setPagesData(newArr);
      
      errortoast("Oh no! Something’s not right.");
     
    });
};

// Icon List API
export const getFlatIcons = (shape,color,searchicon,page,setIcon_spinner,setSelectediconitem,setPage,setIsPushed,setSelecticonshapefilter
  ,setSelecticoncolorfilter,iconlist,setIconlist,setNextpageicon_spinner
  ) => {
  if(page == 1)
  {
    setIcon_spinner(true);
    setSelectediconitem("");
    setPage(1);
    setNextpageicon_spinner(false);
    setIsPushed(true);
    setIconlist([]);
   
  }
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  if((searchicon == '' && color == 'black' && shape == 'hand-drawn'))
  {
    searchicon = '';
  }
  else if((searchicon == '' && color == 'black' &&  shape == '') || (searchicon == '' && color == '' && shape == ''))
  {
      searchicon = 'solid-black';
  }
  else if(searchicon == '')
  {
    searchicon = '';
  }
  
  

  

  axios
  .get(freePickIconUrl+"/"+btoa(localStorage.getItem("Appid"))+"?term="+ searchicon +"&slug="+ searchicon +"&color="+ color+"&shape="+ shape+"&page="+page , {
    headers: headers,
  })
    .then((res) => {
      if(res.data.code === 200) 
      {
        if(page == 1)
        {
          setIconlist(res.data.data);
          setIcon_spinner(false);
          setSelecticonshapefilter(shape);
          setSelecticoncolorfilter(color);
          setIsPushed(true);
          setNextpageicon_spinner(false);
          
        }
        else
        {
         
          setSelecticonshapefilter(shape);
          setIconlist(iconlist.concat(res.data.data));
          setSelecticoncolorfilter(color);
          setIsPushed(true);
          setNextpageicon_spinner(false);
        
        }
      }
    })
    .catch(() => {
      setIcon_spinner(false);
     
    });
};


//New Icon List API
// export const getFlatIcons = (shape,color,searchicon,page,setIcon_spinner,setSelectediconitem,setPage,setIsPushed,setSelecticonshapefilter
//   ,setSelecticoncolorfilter,iconlist,setIconlist,setNextpageicon_spinner
//   ) => {
//   if(page == 1)
//   {
//     setIcon_spinner(true);
//     setSelectediconitem("");
//     setPage(1);
//     setNextpageicon_spinner(false);
//     setIsPushed(true);
//     setIconlist([]);
   
//   }
//   const headers = {
//     "Content-Type": "application/json",
//     Authorization: "Bearer " + localStorage.getItem("amstoken"),
//   };

//   if(searchicon == '')
//   {
//       searchicon = 'shape';
//   }

  

//   axios
//     .get(NewIconUrl+"/"+ shape+"/"+ color+"/"+ searchicon+"/"+page , {
//       headers: headers,
//     })
//     .then((res) => {
//       if(res.data.code === 200) 
//       {
//         if(page == 1)
//         {
//           setIconlist(res.data.data.data);
//           setIcon_spinner(false);
//           setSelecticonshapefilter(shape);
//           setSelecticoncolorfilter(color);
//           setIsPushed(true);
//           setNextpageicon_spinner(false);
          
//         }
//         else
//         {
         
//           setSelecticonshapefilter(shape);
//           setIconlist(iconlist.concat(res.data.data.data));
//           setSelecticoncolorfilter(color);
//           setIsPushed(true);
//           setNextpageicon_spinner(false);
        
//         }
//       }
//     })
//     .catch(() => {
//       setIcon_spinner(false);
     
//     });
// };


export const uploadAnyBase64Imagetos3Icon = (
  params,
  orgFileName,
  callback,
  selectediconcolor,
  setIsUploadBtnLoader

) => {
  //convert into image(base64 image to file object)
  function base64dataToFile(dataurl) {
    var arr = dataurl.split(","),
      // mime = arr[0].match(/:(.*?);/)[1],
      // mime = "image/png",
      mime =params.filetype.includes("svg")?'image/svg+xml':"image/png",
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new Blob([u8arr], { type: mime });
  }

  //Usage example:
  let file = base64dataToFile(params.data);

  //send to s3
  const config = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.path.replace(/\/$/, "") /* optional */,
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, "") /* optional */,
    acl: '' 
  };

  // const ReactS3Client = new oldS3(config);
  const customS3Client = new CustomS3Client(config);
  const newFileName = orgFileName;
  customS3Client.uploadFile(file, newFileName)
    .then((data) => {
      if (data.status === 204) {
        // settingImgtoArray(newFileName + ".png", "original");
            callback(newFileName + (params.filetype.includes("svg")?".svg%2Bxml":".png"),selectediconcolor);
      }
    })
    .catch((err) => {
            errortoast("Oops! Something went wrong.");
            setIsUploadBtnLoader(false)
    });
};

// Attribute APIs
export const cmsSaveAtrApi = (
  params,
  setAtrCreateBtntext,
  setAtrIsLoader,
  setOpenAttributePopup,
  setBottomSpinner,
  setIsSaved,
  isSaved,
  setAtrName,
  setAtrIcon,
  setAtrValArr,
  setAtrId,
  atrId,
  ) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "save-attribute",params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setOpenAttributePopup(false);
        setIsSaved(!isSaved);
        if(atrId){
          sucesstoast("The record has been saved.");
          
        }else{

          sucesstoast("The record has been created.");
        }
        setAtrCreateBtntext("Create");
        setAtrIsLoader("d-none");
        setBottomSpinner(true);
        setAtrName("");
        setAtrIcon("");
        setAtrValArr([]);
        setAtrId("");
      }else{
        errortoast("Oh no! Something’s not right.");
        setAtrCreateBtntext("Create");
        setAtrIsLoader("d-none")
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
      
      setAtrCreateBtntext("Create");
      setAtrIsLoader("d-none")
    });
};
export const cmsGetAtrApi = (
  setSpinner,
  setAtrListingData,
  setBottomSpinner,
  from,
  selectedAtrIdsArr,
  selectedAtrData,
  limit,
  page,
  atrListingData,
  setHitNextPagination,
  setPaginationSpinner,
  setAtrSubData,
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + `get-attributes/${btoa(localStorage.getItem("Appid"))}?page=${page?page:1}&limit=${limit?limit:20}`, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setSpinner(false);
        setBottomSpinner(false);

        let maindata = [];
        if(setHitNextPagination){

          setHitNextPagination((res.data.data.length > 0 && res.data.data.length===limit) ?true:false);
        }

        if(setPaginationSpinner){
          setPaginationSpinner(false);
        }

            let ntfMergeData = [];
            if(page && page > 1){
              ntfMergeData = atrListingData.concat(res.data.data);
              maindata = ntfMergeData;             
            }else{
              maindata = res.data.data;
            }
            if(setAtrSubData){
              setAtrSubData(maindata);
            }
           setAtrListingData(maindata);

        if(from==="edit-post"){
          let newArr=[...maindata];
            newArr&&newArr.map((elem)=>{
              selectedAtrIdsArr && selectedAtrIdsArr.map((atrId)=>{
                if(elem._id===atrId){
                 elem.is_checked=true;
 
                }
            elem.attribute_terms && elem.attribute_terms.map((val)=>{
             if(val._id===atrId){
                val.is_checked=true;
                
               }
            })
            })
           
          });
          setAtrListingData(newArr);
        }
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      

    });
};
export const cmsGetSpecificAtrApi = (atrId,setAtrName,setAtrIcon,setAtrValArr,setCreateAtrPopUpSpin,setOpenAtrValPopup) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "get-attribute/"+btoa(localStorage.getItem("Appid"))+"/"+atrId, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if(setOpenAtrValPopup){
          setOpenAtrValPopup(false);
        }
      setCreateAtrPopUpSpin(false);
      setAtrName(res.data.data.attribute_name);
      setAtrIcon(res.data.data.attribute_icon_name);
      // let finalArr = [];
      // if(res.data.data.attribute_terms&&res.data.data.attribute_terms.length>0){
       
      //   finalArr = res.data.data.attribute_terms.map((row)=>{
      //   let obj =  {name:row.attribute_term_name,id:row._id}
      //   return obj;
      //   })
      // }
      
      setAtrValArr(res.data.data.attribute_terms);
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      

    });
};

export const cmsDeleteAtrApi = (params,setisDeleted,isDeleted) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "delete-attribute",params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("The record has been deleted.");
        setisDeleted(!isDeleted);
      }else{
        errortoast("Oh no! Something’s not right.");
   
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
      
   
    });
};


export const cmsSaveAtrValApi = (
  params,
  setAtrCreateBtntext,
  setAtrIsLoader,
  setOpenAtrValPopup,
  setBottomSpinner,
  setIsSaved,
  isSaved,
  setAtrValId,
  setAtrValTobeAdd,
  atrValId,
  ) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "save-attribute-term",params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        setOpenAtrValPopup(false);
        setIsSaved(!isSaved);
        if(atrValId){
          sucesstoast("The record has been saved.");
          
        }else{

          sucesstoast("The record has been created.");
        }
        setAtrCreateBtntext("Create");
        setAtrIsLoader("d-none");
        setBottomSpinner(true);
        
        setAtrValTobeAdd([]);
        setAtrValId("");
      }else{
        errortoast("Oh no! Something’s not right.");
        setAtrCreateBtntext("Create");
        setAtrIsLoader("d-none")
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
      
      setAtrCreateBtntext("Create");
      setAtrIsLoader("d-none")
    });
};



export const cmsDeleteAtrValApi = (params,setisDeleted,isDeleted) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "delete-attribute-term",params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("The record has been deleted.");
        setisDeleted(!isDeleted);
      }else{
        errortoast("Oh no! Something’s not right.");
   
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
      
   
    });
};

//TAG and CATEGORY settings API
export const saveTagSettingsApi = (params,setSettingSaveBtnLoader,setSettingSaveBtnVal,setSettingUpdatedAt) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "save-tags-settings",params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("The record has been saved.");
        setSettingSaveBtnLoader("d-none");
        setSettingSaveBtnVal("Save");
        setSettingUpdatedAt(res.data.updated_at);
      }else{
        errortoast("Oh no! Something’s not right.");
        setSettingSaveBtnLoader("d-none");
        setSettingSaveBtnVal("Save");
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
      
      setSettingSaveBtnLoader("d-none");
      setSettingSaveBtnVal("Save");
    });
};



export const saveCategorySettingsApi = (params,setSettingSaveBtnLoader,setSettingSaveBtnVal,setSettingUpdatedAt) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "save-category-settings",params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        sucesstoast("The record has been saved.");
        setSettingSaveBtnLoader("d-none");
        setSettingSaveBtnVal("Save");
        setSettingUpdatedAt(res.data.updated_at);
      }else{
        errortoast("Oh no! Something’s not right.");
        setSettingSaveBtnLoader("d-none");
        setSettingSaveBtnVal("Save");
   
      }
    })
    .catch((err) => {
      errortoast("Oh no! Something’s not right.");
      
      setSettingSaveBtnLoader("d-none");
      setSettingSaveBtnVal("Save");
   
    });
};

export const getTagSettingsApi = (
  setShowSearchInHeader,
  setBgColor,
  setTextColor,
  setTextBgColor,
  setTagLayout,
  setSettingUpdatedAt,
  ) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "get-tags-settings/"+btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if(res.data.settings){
          setShowSearchInHeader(res.data.settings.show_search_in_header===1?true:false);
          setTagLayout(res.data.settings.layout);
          setBgColor(res.data.settings.background_color_object.portal_data);
          setTextBgColor(res.data.settings.text_bg_color_object.portal_data);
          setTextColor(res.data.settings.text_color_object.portal_data);
          setSettingUpdatedAt(res.data.updated_at);
        }

      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      

    });
};

export const getCategorySettingsApi = (
  setShowSearchInHeader,
  setShowBookmarkInHeader,
  setColumn,
  setBgColor,
  setTextColor,
  setCatImgOverlayColor,
  setShowCatImg,
  setCatImgRatio,
  setCatImgShape,
  setCatLayout,
  setSettingUpdatedAt,
  setCatTileBgColor,
  setShowCenterCropImages,
  ) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .get(Url + "get-category-settings/"+btoa(localStorage.getItem("Appid")), {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        if(res.data.settings){
        setShowSearchInHeader(res.data.settings.show_search_in_header?true:false);
        setShowBookmarkInHeader(res.data.settings.show_bookmark_in_header?true:false);
        setColumn(res.data.settings.column_count.toString());
        setBgColor(res.data.settings.background_color_object.portal_data);
        setTextColor(res.data.settings.text_color_object.portal_data);
        setCatImgOverlayColor(res.data.settings.image_overlay_color_object.portal_data);
        setShowCatImg(res.data.settings.show_category_image?true:false);
        setCatImgRatio(res.data.settings.image_ratio);
        setCatImgShape(res.data.settings.image_shape);
        setCatLayout(res.data.settings.layout);
        setSettingUpdatedAt(res.data.updated_at);
        setCatTileBgColor(res.data.settings.tile_background_color_object.portal_data);
        setShowCenterCropImages(
          (res.data.settings.show_center_crop_images!==undefined && res.data.settings.show_center_crop_images!==null )?
          (res.data.settings.show_center_crop_images === 1 ? true : false ) :
          true
        );
        }
      }
    })
    .catch((err) => {
      // errortoast("Oh no! Something’s not right.");
      

    });
};

// Pages setting API

export const getPagesSettingApi = (
  params,
  setBodyspinner,
  setSubmitval,
  setLoader,
  setEnable_web_view,
  setEnable_hyperlinks,
  setEnable_form_inputs,
  setEnable_header_in_web_view,
  setShow_additional_information,
  setLastdate,
) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(userUrl + "pages-toggle/" + params.app_id, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) 
      {
        setLastdate(res.data.updated_at);
        if (res.data.data === null) 
          {
          setBodyspinner(false);
          setEnable_web_view(true);
          setEnable_hyperlinks(true);
          setEnable_form_inputs(true);
          setEnable_header_in_web_view(true);
          setShow_additional_information(true);
        } else {
          setBodyspinner(false);
          setEnable_web_view(res.data.data.enable_web_view_interface_bool === 1 ? true : false);
          setEnable_hyperlinks(res.data.data.enable_hyperlinks_on_web_view_bool === 1
            ? true
            : false);
          setEnable_form_inputs( res.data.data.enable_form_inputs_on_web_view_bool === 1
            ? true
            : false);
          setEnable_header_in_web_view( res.data.data.enable_header_in_web_view === 1 ? true : false);
          setShow_additional_information(res.data.data.show_additional_information_bool === 1 ? true : false);
        }
      } 
    })
    .catch((error) => {});
};
export const savePagesSettingApi = (params,setLastdate,setSubmitval,setLoader) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(userUrl + "save-pages-toggle", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) 
      {
        setLastdate(res.data.getdata.updated_at);
        setSubmitval("Save");
        setLoader("d-none");
        sucesstoast("The record has been saved.");
      } else {
        errortoast("Oops! Something went wrong.");
      }
    })
    .catch((error) => {
      errortoast("Oops! Something went wrong.");
      setSubmitval("Save");
      setLoader("d-none");
    });
};

// Get Api Advertisement
export const getAdvertisementDataForCMS = (setAdsData) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      dashboardUrl +
        `get-app-monetization-data/${btoa(localStorage.getItem("Appid"))}?page=1&limit=99`,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        setAdsData(res.data.data?res.data.data:[]);
      }
    });
};


export const bulkPostUpdate = async (data) => {
  try{
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
    const res = await  axios
    .post(
      Url +
        `posts/bulk-update`, data,
      {
        headers: headers,
      }
    )
    return res
  } catch(err) {
    errortoast(err.message)
  }
}

export const bulkPageUpdate = async (data) => {
//   app_id:
// page_ids:[]
// author_user_id:
// parent:
// status:
  try{
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
    const res = await  axios
    .post(
      Url +
        `pages/bulk-update`, data,
      {
        headers: headers,
      }
    )
    return res
  } catch(err) {
    errortoast(err.message)
  }
}