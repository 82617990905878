import React, { useEffect, useRef, useState } from "react";
import "./Contact.css";
import {
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import axios from "axios";
import { Link, useHistory } from "react-router-dom";
import emailValidator from "email-validator";
import ReCAPTCHA from "react-google-recaptcha";
import { errortoast } from "../Toaster";
import toast from "toasted-notes";

import HeaderOutsidePortal from "../Reusable/HeaderOutsidePortal";
import Header from "../Header";

const ContactUs = () => {
  const ref1 = useRef();
  const [helpDropdownOpen, setHelpDropdownOpen] = useState(false);
  const [supportmenushow, setSupportMenuShow] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [message, setMessage] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [urlError, setUrlError] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [mssgError, setMssgError] = useState(false);
  const [captchaErr, setCaptchaErr] = useState(false);
  const [loader, setLoader] = useState("d-none");
  const [loaderimage, setLoaderimage] = useState(
    require("../../assets/images/signup/Loader_GIF.gif").default
  );
  const [submitval, setSubmitval] = useState("Send");

  const url = process.env.REACT_APP_API_URL;
  const history = useHistory();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    if (e.target.value.trim() !== "") {
      setEmailError(false);
    }
  };
  const handleRecaptcha = (value) => {
    setCaptcha(value);
    setCaptchaErr(false);
  };
  const handleNameChange = (e) => {
    setName(e.target.value);
    if (e.target.value.trim() !== "") {
      setNameError(false);
    }
  };

  const handleWebsiteChange = (e) => {
    setWebsite(e.target.value);
    if (e.target.value.trim() !== "") {
      setUrlError(false);
    }
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
    if (e.target.value.trim() !== "") {
      setMssgError(false);
    }
  };
  const helpToggle = () => {
    setHelpDropdownOpen(!helpDropdownOpen);
  };

  useEffect(() => {
    document.title = "Get in touch | AppMySite";
  }, []);

  const sucesstoast = (message) => {
    toast.notify(
      <div
        style={{
          borderLeft: "5px solid #01C99B",
          height: "100px",
          width: "420px",
        }}
        className="mobiletoaster"
      >
        <div className="d-flex align-items-center h-100">
          <div className="mr-3 ml-3">
            <svg width="35" height="35" viewBox="0 0 35 35">
              <circle
                id="Ellipse_1736"
                data-name="Ellipse 1736"
                cx="17.5"
                cy="17.5"
                r="17.5"
                fill="#01c99b"
              />
              <path
                id="Path_6845"
                data-name="Path 6845"
                d="M801.834,128.881l4.166,3.8,8.015-8.015"
                transform="translate(-790.333 -111.171)"
                fill="none"
                stroke="#fff"
                strokeWidth="2"
              />
            </svg>
          </div>
          <div>
            <h6 className="text-left mb-1">Success</h6>
            <p className="p-0 m-0 text-left">{message}</p>
          </div>
        </div>
      </div>,

      {
        position: "top", // top-left, top, top-right, bottom-left, bottom, bottom-right
        duration: 6000, // This notification will not automatically close
      }
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Submit Loader

    const Loader = () => {
      setSubmitval("");
      setLoader("loader mr-2");
    };
    const erroLoader = () => {
      setSubmitval("Send");
      setLoader("d-none");
    };
    Loader();
    let isErrforCheck = false;
    let params = {
      name: name.trim(),
      email: email.trim(),
      website_url: website.trim(),
      message: message.trim(),
      // is_enterprise_support: 1,
    };
    let email_err_temp = emailError;

    const isUrl =
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
    if (!params.name) {
      setNameError(true);
      erroLoader();
      isErrforCheck = true;
    }

    if (!params.email) {
      erroLoader();
      email_err_temp = "Required";
      setEmailError("Required");
      isErrforCheck = true;
    }
    if (
      !emailValidator.validate(params.email) &&
      email_err_temp !== "Required"
    ) {
      erroLoader();
      email_err_temp = true;
      setEmailError(true);
      isErrforCheck = true;
    }

    // if (!params.website_url) {
    //   setUrlError(true);
    //   erroLoader();
    //   isErrforCheck = true;
    // }
    if (!params.message) {
      setMssgError(true);
      erroLoader();
      isErrforCheck = true;
    }
    if (captcha === "" || captcha === null) {
      erroLoader();
      setCaptchaErr(true);
      isErrforCheck = true;
    }

    if (isErrforCheck === false) {
      const headers = {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + localStorage.getItem("amstoken"),
      };
      axios
        .post(url + "api/contact-us", params, {
          headers: headers,
        })
        .then((res) => {
          if (res.data.code === 200) {
            sucesstoast("Your details have been submitted. ");
            erroLoader();
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } else if (res.data.code === 400) {
            errortoast("Oh no! Something’s not right.");
            erroLoader();
          } else {
            erroLoader();
            errortoast("Oh no! Something’s not right.");
          }
        })
        .catch((err) => {
          erroLoader();
          errortoast("Oh no! Something’s not right.");
        });
    }
  };

  return (
    <div>
      {localStorage.getItem("amstoken") ? (
        <Header
        customclass={"header-responsive-new"}
          classNameProp={"contact-us-header-white-bg"}
        />
      ) : (
        <HeaderOutsidePortal
          classNameProp={"contact-us-header-white-bg"}
          SignInShow={true}
        />
      )}

      <div className={localStorage.getItem("amstoken") ? "contactus-page newcontactUs newcontactUsWithInsideHeader":"contactus-page newcontactUs"}>
        <div
          className="custom-container custom-container-responsive border-1"
          style={{ position: "relative" }}
        >
          <div className="d-flex ">
            <div className="right-sec rightsecdata  ">
              <div className="">
                <div className=" ">
                  <h5 className="new-contect-us-head">Get in touch</h5>
                  <p className="new-contect-us-para">
                    Have questions about our product?
                  </p>
                </div>
              </div>

              <form onSubmit={(e) => handleSubmit(e)} className="contactUsform">
                <div className="form-group">
                  <label>Name*</label>
                  <input
                    // required
                    type="text"
                    className={`form-control ${
                      nameError ? "contactus-errorinput" : ""
                    }`}
                    name="subject"
                    // placeholder="Name*"
                    value={name}
                    onChange={(e) => handleNameChange(e)}
                    maxLength="200"
                    autocomplete="off"
                  />
                  <div className="d-flex align-items-center contact-errormessages">
                    <div>
                      {nameError ? (
                        <div className="d-flex align-items-center contact-us-error">
                          {/* <img
                            src={
                              require("../../assets/images/signup/error.png")
                                .default
                            }
                            className="img-fluid"
                            alt="error"
                          /> */}
                          <svg
                            id="Group_29966"
                            data-name="Group 29966"
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                          >
                            <path
                              id="Path_82474"
                              data-name="Path 82474"
                              d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z"
                              fill="#ff4848"
                            />
                            <path
                              id="Path_7077"
                              data-name="Path 7077"
                              d="M10991-4358.209v4"
                              transform="translate(-10984.499 4361.208)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="1"
                            />
                            <path
                              id="Path_7078"
                              data-name="Path 7078"
                              d="M10991-4347.192v1.229"
                              transform="translate(-10984.499 4356.1)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="1"
                            />
                          </svg>
                          <p>Required field.</p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>Email*</label>
                  <input
                    type="text"
                    className={`form-control ${
                      emailError ? "contactus-errorinput" : ""
                    }`}
                    name="email"
                    value={email}
                    onChange={(e) => handleEmailChange(e)}
                    // placeholder="Email*"
                    // required
                    maxLength="200"
                    autocomplete="off"
                  />
                  <div className="d-flex align-items-center contact-errormessages ">
                    <div>
                      {emailError ? (
                        <div className="d-flex align-items-center contact-us-error">
                          {/* <img
                            src={
                              require("../../assets/images/signup/error.png")
                                .default
                            }
                            className="img-fluid"
                            alt="error"
                          /> */}
                          <svg
                            id="Group_29966"
                            data-name="Group 29966"
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                          >
                            <path
                              id="Path_82474"
                              data-name="Path 82474"
                              d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z"
                              fill="#ff4848"
                            />
                            <path
                              id="Path_7077"
                              data-name="Path 7077"
                              d="M10991-4358.209v4"
                              transform="translate(-10984.499 4361.208)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="1"
                            />
                            <path
                              id="Path_7078"
                              data-name="Path 7078"
                              d="M10991-4347.192v1.229"
                              transform="translate(-10984.499 4356.1)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="1"
                            />
                          </svg>
                          <p>
                            {emailError === "Required"
                              ? "Required field."
                              : "Enter a valid email"}
                          </p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group">
                  <label>Website</label>
                  <input
                    type="text"
                    // required
                    className="form-control"
                    name="website"
                    // placeholder="Website*"
                    value={website}
                    onChange={(e) => handleWebsiteChange(e)}
                    maxLength="200"
                    autocomplete="off"
                  />
                  {/* <div className="d-flex align-items-center contact-errormessages">
                    <div>
                      {urlError ? (
                        <div className="d-flex align-items-center contact-us-error">
                          
                             <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
  <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"/>
  <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"/>
  <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"/>
</svg>
<p>Required</p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div> */}
                </div>
                <div className="form-group">
                  <label>Message*</label>
                  <textarea
                    // required
                    className={`form-control ${
                      mssgError ? "contactus-errorinput" : ""
                    }`}
                    rows="4"
                    name="message"
                    value={message}
                    onChange={(e) => handleMessageChange(e)}
                  ></textarea>
                  <div className="d-flex align-items-center contact-errormessages">
                    <div>
                      {mssgError ? (
                        <div className="d-flex align-items-center contact-us-error">
                          {/* <img
                            src={
                              require("../../assets/images/signup/error.png")
                                .default
                            }
                            className="img-fluid"
                            alt="error"
                          /> */}
                          <svg
                            id="Group_29966"
                            data-name="Group 29966"
                            xmlns="http://www.w3.org/2000/svg"
                            width="13"
                            height="13"
                            viewBox="0 0 13 13"
                          >
                            <path
                              id="Path_82474"
                              data-name="Path 82474"
                              d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z"
                              fill="#ff4848"
                            />
                            <path
                              id="Path_7077"
                              data-name="Path 7077"
                              d="M10991-4358.209v4"
                              transform="translate(-10984.499 4361.208)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="1"
                            />
                            <path
                              id="Path_7078"
                              data-name="Path 7078"
                              d="M10991-4347.192v1.229"
                              transform="translate(-10984.499 4356.1)"
                              fill="none"
                              stroke="#fff"
                              stroke-width="1"
                            />
                          </svg>
                          <p>Required field.</p>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                </div>
                <div className="contact-captcha">
                  <ReCAPTCHA
                    sitekey="6LeOmMAUAAAAAGj7NXf5SlZA25DNAfvL1HwxaAeP"
                    onChange={handleRecaptcha}
                  />
                </div>
                <div className="d-flex align-items-center contact-errormessages">
                  <div>
                    {captchaErr ? (
                      <div className="d-flex align-items-center contact-us-error">
                        {/* <img
                            src={
                              require("../../assets/images/signup/error.png")
                                .default
                            }
                            className="img-fluid"
                            alt="error"
                          /> */}
                        <svg
                          id="Group_29966"
                          data-name="Group 29966"
                          xmlns="http://www.w3.org/2000/svg"
                          width="13"
                          height="13"
                          viewBox="0 0 13 13"
                        >
                          <path
                            id="Path_82474"
                            data-name="Path 82474"
                            d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z"
                            fill="#ff4848"
                          />
                          <path
                            id="Path_7077"
                            data-name="Path 7077"
                            d="M10991-4358.209v4"
                            transform="translate(-10984.499 4361.208)"
                            fill="none"
                            stroke="#fff"
                            stroke-width="1"
                          />
                          <path
                            id="Path_7078"
                            data-name="Path 7078"
                            d="M10991-4347.192v1.229"
                            transform="translate(-10984.499 4356.1)"
                            fill="none"
                            stroke="#fff"
                            stroke-width="1"
                          />
                        </svg>
                        <p>Required field.</p>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <button type="submit" className="save-contact">
                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    <div className={loader}>
                      {/* <img src={loaderimage} alt="loader" /> */}
                      <div className="new-loader"></div>
                    </div>{" "}
                    <div>{submitval}</div>
                  </div>
                </button>
              </form>
              <div className="contact-us-enterprise d-flex">
                <p>Are you an Enterprise or an Agency?</p>
                <Link to={"/enterprise-agency-sales"}>
                  Talk to a specialist.
                </Link>
              </div>
              {/* <div className="bottom-mob-menu bottom-mobile-menu-responsive contactHelplinks">
                <div className=" w-100  d-sm-block">
                  <div className="bottom-menu-mobile-view">
                    <ul>
                      <li>
                        <a
                          className="contact-link-mob-spacing"
                          href="https://www.appmysite.com/support/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Help Center
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://community.appmysite.com/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Community
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.appmysite.com/support/roadmap/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Roadmap
                        </a>
                      </li>
                      <li>
                        <a
                          className="contact-link-mob-spacing"
                          href="https://www.appmysite.com/support/news-category/release-notes/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Release Notes
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className="talk1">
                    <p>
                      Interested in Enterprise?{" "}
                      <Link to={"/talk-to-sales"}>Talk to sales.</Link>
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <div className=" sociallaccount-mob mb-social">
            <a
              target="_blank"
              href="https://www.facebook.com/AppMySiteOfficial/"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="8.302"
                height="18.009"
                viewBox="0 0 8.302 18.009"
              >
                <path
                  id="Path_550"
                  data-name="Path 550"
                  d="M45.78,30.75H43.317v9.022H39.586V30.75H37.812V27.579h1.774V25.528a3.5,3.5,0,0,1,3.764-3.765l2.764.012v3.078H44.109a.759.759,0,0,0-.791.864v1.866h2.788Z"
                  transform="translate(-37.812 -21.763)"
                  fill="#bcc1ce"
                />
              </svg>
            </a>
            <a
              target="_blank"
              href="https://www.youtube.com/channel/UCI_mJos_doMXl1XOfViSjWg"
            >
              <svg
                id="black"
                xmlns="http://www.w3.org/2000/svg"
                width="17.229"
                height="12.118"
                viewBox="0 0 17.229 12.118"
              >
                <g id="youtube" transform="translate(0 0)">
                  <path
                    id="Fill-195"
                    d="M17.883,3.557a3.729,3.729,0,0,0-.685-1.71,2.464,2.464,0,0,0-1.726-.73C13.061.943,9.444.943,9.444.943H9.436s-3.616,0-6.027.174a2.464,2.464,0,0,0-1.726.73A3.726,3.726,0,0,0,1,3.557,26.057,26.057,0,0,0,.826,6.345V7.653A26.055,26.055,0,0,0,1,10.441a3.726,3.726,0,0,0,.685,1.71,2.92,2.92,0,0,0,1.9.736c1.378.132,5.858.173,5.858.173s3.62-.006,6.031-.18a2.463,2.463,0,0,0,1.726-.73,3.729,3.729,0,0,0,.685-1.71,26.115,26.115,0,0,0,.172-2.788V6.345A26.116,26.116,0,0,0,17.883,3.557ZM7.662,9.237V4.4l4.655,2.429Z"
                    transform="translate(-0.826 -0.943)"
                    fill="#bcc1ce"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
            </a>

            <a
              target="_blank"
              href="https://www.linkedin.com/authwall?trk=ripf&trkInfo=AQG2-pmDoPjzWQAAAYAjBqZAALbz89_67lPgJJ1_1QUzU3CX5TIZZFmVJ7YC5wtH_iHzjrWWc3Sogj2RMeV0u1VWR8KZ3n6DNqb61VCokgWkoYk5M3RSqqPyjoFDg_DkReI2qxA=&originalReferer=https://appmysite.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fapp-my-site%2F"
            >
              <svg
                id="black"
                xmlns="http://www.w3.org/2000/svg"
                width="16.011"
                height="15.939"
                viewBox="0 0 16.011 15.939"
              >
                <g id="linkedin" transform="translate(0 0)">
                  <path
                    id="Path_6398"
                    data-name="Path 6398"
                    d="M3.412,15.094V4.552H.153V15.094Zm0-14.278A1.6,1.6,0,0,0,1.742-.846,1.607,1.607,0,0,0,.053.816,1.6,1.6,0,0,0,1.7,2.477h.019A1.608,1.608,0,0,0,3.412.816ZM8.727,15.094V9.088a2.444,2.444,0,0,1,.118-.872,1.957,1.957,0,0,1,1.834-1.308,1.944,1.944,0,0,1,2.105,2.18v6.006h3.28V8.927c0-3.3-1.764-4.841-4.116-4.841A3.56,3.56,0,0,0,8.7,5.9l.024-1.41H5.452c.047,1.009,0,10.605,0,10.605Z"
                    transform="translate(-0.053 0.846)"
                    fill="#bcc1ce"
                    fill-rule="evenodd"
                  />
                </g>
              </svg>
            </a>
            <a
              target="_blank"
              href="https://www.instagram.com/accounts/login/?next=%2Fappmysite_official%2F"
            >
              <svg
                id="instagram-logo"
                xmlns="http://www.w3.org/2000/svg"
                width="16.325"
                height="16.324"
                viewBox="0 0 16.325 16.324"
              >
                <path
                  id="Path_551"
                  data-name="Path 551"
                  d="M11.82,0H4.5A4.51,4.51,0,0,0,0,4.505V11.82a4.51,4.51,0,0,0,4.5,4.505H11.82a4.51,4.51,0,0,0,4.505-4.505V4.505A4.51,4.51,0,0,0,11.82,0Zm3.057,11.82a3.06,3.06,0,0,1-3.057,3.057H4.5A3.06,3.06,0,0,1,1.448,11.82V4.505A3.06,3.06,0,0,1,4.5,1.448H11.82a3.06,3.06,0,0,1,3.057,3.057V11.82Z"
                  transform="translate(0)"
                  fill="#bcc1ce"
                />
                <path
                  id="Path_552"
                  data-name="Path 552"
                  d="M45.174,40.97a4.206,4.206,0,1,0,4.206,4.206A4.211,4.211,0,0,0,45.174,40.97Zm0,6.964a2.758,2.758,0,1,1,2.758-2.758A2.761,2.761,0,0,1,45.174,47.934Z"
                  transform="translate(-37.012 -37.014)"
                  fill="#bcc1ce"
                />
                <path
                  id="Path_553"
                  data-name="Path 553"
                  d="M119.983,28.251a1.062,1.062,0,1,0,.751.311A1.066,1.066,0,0,0,119.983,28.251Z"
                  transform="translate(-107.438 -25.523)"
                  fill="#bcc1ce"
                />
              </svg>
            </a>
            <a target="_blank" href="https://twitter.com/appmysite_live/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="19.133"
                height="15.545"
                viewBox="0 0 19.133 15.545"
              >
                <path
                  id="twitter"
                  d="M19.133,49.84a8.178,8.178,0,0,1-2.26.619A3.9,3.9,0,0,0,18.6,48.292a7.839,7.839,0,0,1-2.487.949,3.922,3.922,0,0,0-6.785,2.682,4.039,4.039,0,0,0,.091.894,11.1,11.1,0,0,1-8.085-4.1,3.923,3.923,0,0,0,1.205,5.242,3.874,3.874,0,0,1-1.772-.483v.043a3.94,3.94,0,0,0,3.143,3.854,3.914,3.914,0,0,1-1.028.129,3.468,3.468,0,0,1-.743-.067A3.96,3.96,0,0,0,5.8,60.166,7.881,7.881,0,0,1,.939,61.839,7.348,7.348,0,0,1,0,61.785a11.043,11.043,0,0,0,6.017,1.76A11.087,11.087,0,0,0,17.181,52.384c0-.173-.006-.341-.014-.507A7.825,7.825,0,0,0,19.133,49.84Z"
                  transform="translate(0 -48)"
                  fill="#bcc1ce"
                />
              </svg>
            </a>
            <a
              target="_blank"
              href="https://www.producthunt.com/products/appmysite#appmysite"
            >
              <svg
                id="product-hunt"
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
              >
                <path
                  id="Path_41879"
                  data-name="Path 41879"
                  d="M10,20A10,10,0,1,0,0,10,10,10,0,0,0,10,20Z"
                  fill="#bcc1ce"
                  fill-rule="evenodd"
                />
                <path
                  id="Path_41880"
                  data-name="Path 41880"
                  d="M96,76.8v7.594h1.424V82.021h2.155a2.646,2.646,0,0,0,2.591-2.61,2.6,2.6,0,0,0-2.591-2.61Zm3.6,3.8H97.424V78.224H99.6a1.187,1.187,0,0,1,0,2.373Z"
                  transform="translate(-88.317 -70.597)"
                  fill="#fff"
                  fill-rule="evenodd"
                />
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div className=" sociallaccount">
        <a target="_blank" href="https://www.facebook.com/AppMySiteOfficial/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="8.302"
            height="18.009"
            viewBox="0 0 8.302 18.009"
          >
            <path
              id="Path_550"
              data-name="Path 550"
              d="M45.78,30.75H43.317v9.022H39.586V30.75H37.812V27.579h1.774V25.528a3.5,3.5,0,0,1,3.764-3.765l2.764.012v3.078H44.109a.759.759,0,0,0-.791.864v1.866h2.788Z"
              transform="translate(-37.812 -21.763)"
              fill="#bcc1ce"
            />
          </svg>
        </a>
        <a
          target="_blank"
          href="https://www.youtube.com/channel/UCI_mJos_doMXl1XOfViSjWg"
        >
          <svg
            id="black"
            xmlns="http://www.w3.org/2000/svg"
            width="17.229"
            height="12.118"
            viewBox="0 0 17.229 12.118"
          >
            <g id="youtube" transform="translate(0 0)">
              <path
                id="Fill-195"
                d="M17.883,3.557a3.729,3.729,0,0,0-.685-1.71,2.464,2.464,0,0,0-1.726-.73C13.061.943,9.444.943,9.444.943H9.436s-3.616,0-6.027.174a2.464,2.464,0,0,0-1.726.73A3.726,3.726,0,0,0,1,3.557,26.057,26.057,0,0,0,.826,6.345V7.653A26.055,26.055,0,0,0,1,10.441a3.726,3.726,0,0,0,.685,1.71,2.92,2.92,0,0,0,1.9.736c1.378.132,5.858.173,5.858.173s3.62-.006,6.031-.18a2.463,2.463,0,0,0,1.726-.73,3.729,3.729,0,0,0,.685-1.71,26.115,26.115,0,0,0,.172-2.788V6.345A26.116,26.116,0,0,0,17.883,3.557ZM7.662,9.237V4.4l4.655,2.429Z"
                transform="translate(-0.826 -0.943)"
                fill="#bcc1ce"
                fill-rule="evenodd"
              />
            </g>
          </svg>
        </a>

        <a
          target="_blank"
          href="https://www.linkedin.com/authwall?trk=ripf&trkInfo=AQG2-pmDoPjzWQAAAYAjBqZAALbz89_67lPgJJ1_1QUzU3CX5TIZZFmVJ7YC5wtH_iHzjrWWc3Sogj2RMeV0u1VWR8KZ3n6DNqb61VCokgWkoYk5M3RSqqPyjoFDg_DkReI2qxA=&originalReferer=https://appmysite.com/&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Fapp-my-site%2F"
        >
          <svg
            id="black"
            xmlns="http://www.w3.org/2000/svg"
            width="16.011"
            height="15.939"
            viewBox="0 0 16.011 15.939"
          >
            <g id="linkedin" transform="translate(0 0)">
              <path
                id="Path_6398"
                data-name="Path 6398"
                d="M3.412,15.094V4.552H.153V15.094Zm0-14.278A1.6,1.6,0,0,0,1.742-.846,1.607,1.607,0,0,0,.053.816,1.6,1.6,0,0,0,1.7,2.477h.019A1.608,1.608,0,0,0,3.412.816ZM8.727,15.094V9.088a2.444,2.444,0,0,1,.118-.872,1.957,1.957,0,0,1,1.834-1.308,1.944,1.944,0,0,1,2.105,2.18v6.006h3.28V8.927c0-3.3-1.764-4.841-4.116-4.841A3.56,3.56,0,0,0,8.7,5.9l.024-1.41H5.452c.047,1.009,0,10.605,0,10.605Z"
                transform="translate(-0.053 0.846)"
                fill="#bcc1ce"
                fill-rule="evenodd"
              />
            </g>
          </svg>
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/accounts/login/?next=%2Fappmysite_official%2F"
        >
          <svg
            id="instagram-logo"
            xmlns="http://www.w3.org/2000/svg"
            width="16.325"
            height="16.324"
            viewBox="0 0 16.325 16.324"
          >
            <path
              id="Path_551"
              data-name="Path 551"
              d="M11.82,0H4.5A4.51,4.51,0,0,0,0,4.505V11.82a4.51,4.51,0,0,0,4.5,4.505H11.82a4.51,4.51,0,0,0,4.505-4.505V4.505A4.51,4.51,0,0,0,11.82,0Zm3.057,11.82a3.06,3.06,0,0,1-3.057,3.057H4.5A3.06,3.06,0,0,1,1.448,11.82V4.505A3.06,3.06,0,0,1,4.5,1.448H11.82a3.06,3.06,0,0,1,3.057,3.057V11.82Z"
              transform="translate(0)"
              fill="#bcc1ce"
            />
            <path
              id="Path_552"
              data-name="Path 552"
              d="M45.174,40.97a4.206,4.206,0,1,0,4.206,4.206A4.211,4.211,0,0,0,45.174,40.97Zm0,6.964a2.758,2.758,0,1,1,2.758-2.758A2.761,2.761,0,0,1,45.174,47.934Z"
              transform="translate(-37.012 -37.014)"
              fill="#bcc1ce"
            />
            <path
              id="Path_553"
              data-name="Path 553"
              d="M119.983,28.251a1.062,1.062,0,1,0,.751.311A1.066,1.066,0,0,0,119.983,28.251Z"
              transform="translate(-107.438 -25.523)"
              fill="#bcc1ce"
            />
          </svg>
        </a>
        <a target="_blank" href="https://twitter.com/appmysite_live/">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19.133"
            height="15.545"
            viewBox="0 0 19.133 15.545"
          >
            <path
              id="twitter"
              d="M19.133,49.84a8.178,8.178,0,0,1-2.26.619A3.9,3.9,0,0,0,18.6,48.292a7.839,7.839,0,0,1-2.487.949,3.922,3.922,0,0,0-6.785,2.682,4.039,4.039,0,0,0,.091.894,11.1,11.1,0,0,1-8.085-4.1,3.923,3.923,0,0,0,1.205,5.242,3.874,3.874,0,0,1-1.772-.483v.043a3.94,3.94,0,0,0,3.143,3.854,3.914,3.914,0,0,1-1.028.129,3.468,3.468,0,0,1-.743-.067A3.96,3.96,0,0,0,5.8,60.166,7.881,7.881,0,0,1,.939,61.839,7.348,7.348,0,0,1,0,61.785a11.043,11.043,0,0,0,6.017,1.76A11.087,11.087,0,0,0,17.181,52.384c0-.173-.006-.341-.014-.507A7.825,7.825,0,0,0,19.133,49.84Z"
              transform="translate(0 -48)"
              fill="#bcc1ce"
            />
          </svg>
        </a>
        <a
          target="_blank"
          href="https://www.producthunt.com/products/appmysite#appmysite"
        >
          <svg
            id="product-hunt"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
          >
            <path
              id="Path_41879"
              data-name="Path 41879"
              d="M10,20A10,10,0,1,0,0,10,10,10,0,0,0,10,20Z"
              fill="#bcc1ce"
              fill-rule="evenodd"
            />
            <path
              id="Path_41880"
              data-name="Path 41880"
              d="M96,76.8v7.594h1.424V82.021h2.155a2.646,2.646,0,0,0,2.591-2.61,2.6,2.6,0,0,0-2.591-2.61Zm3.6,3.8H97.424V78.224H99.6a1.187,1.187,0,0,1,0,2.373Z"
              transform="translate(-88.317 -70.597)"
              fill="#fff"
              fill-rule="evenodd"
            />
          </svg>
        </a>
      </div>
    </div>
  );
};

export default ContactUs;
