import React from "react";
import "../../assets/css/Dashboard.css";
import Header from "../Header";
// import { Deletesvg } from "../Svg";
import Accountleftpanel from "../Accountleftpanel";
import {
  GetuserprofileApi,
  ProfileApi,
  ProfileImagebusketApi,
  Countrylist,
  DeleteUser,
  updateWorkspace,
  deleteWorkspace,
  getWorkspaceById,
  leaveWorkspace
} from "../../Api/Dashboard/DashboardApi";
import {
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "../cropImage";
import Tooltip from "../../Dashboard/Tooltip";
import { Foot } from "../../Dashboard/Foot";
import { errortoast } from "../../Dashboard/Toaster";
import { Link } from "react-router-dom";
import { Deleteusers } from "../Svg";
import NewImageGalleryPopup from "../NewImageGalleryPopup";

export default class WorkspaceOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      phonecodemodal: false,

      buttontext: "CROP",
      email: "",
      oldemail: "",
      phone_code: "",
      phone: "",
      gender: "",
      spinner: true,
      profile_image: "",
      date: "",
      profile: localStorage.getItem("workspaceImage"),
      submitval: "Save",
      submitval1: "Okay",
      deleteLoader: "d-none",
      deleteLoaderimage:
        require("../../assets/images/dashboard/workspace/error-popup-loader.gif")
          .default,
      loader: "d-none",
      loader1: "d-none",
      billingloader: "d-none",
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      phonecodesearch: "",
      check: "",
      image: "",
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 2 / 2,
      croppedImage: null,
      croppedAreaPixels: null,
      rotation: 0,
      role_id: 2,
      workspaceroleid:1,
      countryselectfield: [],
      apps_count: undefined,
      delete_app: false,
      status: 0,
      customer_type: null,
      workspaceName: "",
      deleteworkspaceName:"",
      workspaceId: localStorage.getItem("workspaceId"),
      workspaceImage: localStorage.getItem("workspaceImage"),
      profilepath:
        process.env.REACT_APP_Image_Path +
        (
          (parseInt(localStorage.getItem("logged_in_user_role_id")) === 1 ||
          parseInt(localStorage.getItem("logged_in_user_role_id")) === 2)
          ? localStorage.getItem("logged_in_user_id")
          : localStorage.getItem("invited_by_user_id")) +
        process.env.REACT_APP_Workspace_Profile +
        localStorage.getItem("workspaceId") +
        "/",
      childState: "",
      WorkspaceDataById: "",
      workspace_app_count: "",
      allowdelete: false,
      workspace_leave: false,
    };
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "Overview | AppMySite";
    GetuserprofileApi(this);
    // Countrylist(this);
    
    // if (
    //   this.state.workspaceId === "" ||
    //   this.state.workspaceId === null ||
    //   this.state.workspaceId === undefined ||
    //   this.state.workspaceId === "undefined"
    // ) 
    // {
      var workspaceid = atob(window.location.pathname.split("/").pop());
     
      if(workspaceid !== null && workspaceid !== undefined && parseInt(workspaceid) !== null && parseInt(workspaceid) !== undefined)
      {
        getWorkspaceById(this, btoa(parseInt(workspaceid)));
        this.setState({workspaceid:workspaceid});
        localStorage.setItem('workspaceId',parseInt(workspaceid));
      }
      else
      {
        getWorkspaceById(this, btoa(parseInt(this.state.workspaceId)));
      }
      // window.location.href = "/myapps";
    // }
    // else{
    //   getWorkspaceById(this, btoa(parseInt(this.state.workspaceId)));
    // }
  }
  handlephonecodesearch = (e) => {
    this.setState({
      phonecodesearch: e.target.value,
    });
  };
  photoggle = () => {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  };
  showCroppedImage = async () => {
    this.setState({
      buttontext: (
        <img
          src={require("../../assets/images/signup/Loader_GIF.gif").default}
          alt="loading.."
         className="workspace-loader-img"
        />
      ),
    });
    try {
      const croppedImage = await getCroppedImg(
        this.state.image,
        this.state.croppedAreaPixels
      );
      const params = {
        data: croppedImage,
        path:
            parseInt(localStorage.getItem("logged_in_user_role_id")) === 1
            ? localStorage.getItem("originalid") +
              process.env.REACT_APP_Workspace_Profile +
              localStorage.getItem("workspaceId")
            : parseInt(localStorage.getItem("logged_in_user_role_id")) === 2
              ? localStorage.getItem("invited_by_user_id") +
                process.env.REACT_APP_Workspace_Profile +
                localStorage.getItem("workspaceId")
            : localStorage.getItem("logged_in_user_id") +
              process.env.REACT_APP_Workspace_Profile +
              localStorage.getItem("workspaceId"),
      };
      ProfileImagebusketApi(params, this);
    } catch (e) {
      console.error(e);
    }
  };
  onCropChange = (crop) => {
    this.setState({ crop });
    
  };

  onCropComplete = (croppedArea, croppedAreaPixels) => {
    this.setState({
      croppedAreaPixels: croppedAreaPixels,
      croppedArea: croppedArea,
    });
  };

  onZoomChange = (zoom) => {
    this.setState({ zoom });
  };

  workspaceUpdate = (e) => {
    e.preventDefault();

    if (this.state.workspaceName.trim() === "") {
      errortoast("Enter valid name");
    } else {
      this.setState({
        loader: "d-block",
        submitval: "",
      });
      const param = {
        workspace_id: parseInt(this.state.workspaceId),
        workspace_name: this.state.workspaceName,
        profile: this.state.profile,
      };
      updateWorkspace(param, this);
    }
  };

  workspaceleavedone = (e) => 
  {
    e.preventDefault();

      this.setState({
        loader1: "d-block",
        submitval1: "",
      });
      const param = {
        workspace_id: parseInt(this.state.workspaceId),
      };

      leaveWorkspace(param, this);
  };

  togglephonecodemodal = () => {
    this.setState({
      phonecodemodal: !this.state.phonecodemodal,
    });
  };
  workspaceleave = (e) => {
    e.preventDefault();
    this.setState({
      workspace_leave: !this.state.workspace_leave,
    });
  };
  handleworkspaceName = (e) => {
    this.setState({
      workspaceName: e.target.value,
    });
  };

    handledeleteworkspaceName = (e) => {

    
    this.setState({
      deleteworkspaceName: e.target.value,
    });
   

  };
  handleemail = (e) => {
    this.setState({
      email: e.target.value,
    });
  };
  handlephone = (e) => {
    this.setState({
      phone: e.target.value.replace(/[^0-9]/g, ""),
    });
  };

  handleOptionChange = (changeEvent) => {
    this.setState({
      gender: changeEvent.target.value,
    });
  };

  handleprofileimage = (e, state) => {
    this.setState({
      modal: true,
      childState:state
    });
    // var self = this;
    // var reader = new FileReader();
    // var file = e.target.files[0];

    // if (
    //   file.name.indexOf("png") !== -1 ||
    //   file.name.indexOf("jpeg") !== -1 ||
    //   file.name.indexOf("jpg") !== -1
    // ) {
    //   reader.onload = function (upload) {
    //     self.setState({
    //       errorimage: "",
    //       modal: true,
    //       profile: upload.target.result,
    //       profile_image: upload.target.result,
    //       image: upload.target.result,
    //     });
    //   };

    //   reader.readAsDataURL(file);
    // } else {
    //   errortoast(
    //     "File format not supported. Upload PNG, JPEG or JPG file only."
    //   );
    // }
  };

  onChange = (date) => {
    this.setState({ date });
  };
  removeimage = (e, state) => {
    this.setState({
      profile: "",
      // profile_image: "",
      workspaceImage: "",
      check: 2,
    });
    if (state !== undefined) {
      state.setState({
        profile: "",
      });
    }
  };

  handleseachradio = (e) => {
    this.setState({
      phone_code: e.target.value,
      phonecodemodal: false,
    });
  };
  delete_accountmodal = (e) => {
    e.preventDefault();
    this.setState({
      userdeletemodal: !this.state.userdeletemodal,
    });
  };
  //Is workspace ready for delete or not
  isDeleteWorkspace = (e) => {
    e.preventDefault();
    if (this.state.workspace_app_count > 0) {
      this.setState({
        delete_app: !this.state.delete_app,
      });
    } else {
      this.setState({
        userdeletemodal: !this.state.userdeletemodal,
      });
    }
  };
  //Delete Worskspace
  deleteWorkspace = (e) => 
  {
     e.preventDefault();
   
    if(this.state.deleteworkspaceName ===  this.state.workspaceName)
    {
      this.setState({
        deleteLoader: "d-block",
      });
      const params = { workspace_id: btoa(parseInt(this.state.workspaceId)) };
      deleteWorkspace(params, this);
    }
    else
    {
      errortoast("Enter valid workspace name.");
    }
  };

  deleteapps = (e) => {
    e.preventDefault();
    this.setState({
      delete_app: !this.state.delete_app,
    });
  };

  handleDeleteuser = () => {
    this.setState({
      allowdelete: !this.state.allowdelete,
    });
  };

  // NEw Image Upload Pop up integration

  settingYourdata=(imgName,from)=>{
   
    if(from!=="original"){
     if(this.state.childState){
      this.state.childState.setState({
       profile:imgName,
      })
     }
      this.setState({
        profile:imgName,
 
      })
    }
  }

  yourErrFun=()=>{

  }

  loadpicVistaCreatePopUp = (data) =>{
   
    if(this.state.childState){
      this.state.childState.setState({
       profile:data.url,
      })
     }
      this.setState({
        profile:data.url,
        modal:false,
      })
  
   }


     
   closeVistaCreatePopUp = () =>{
    
   }

  render() {
    return (
      <>
        <Header customclass={"header-responsive-new"} />
        {this.state.allowdelete === true && (
          <Deleteusers
            allowpopup={(e) => this.handleDeleteuser()}
            permission={true}
            type="You currently do not have permissions to access this feature. Please contact your administrator if you require access."
          />
        )}
        <section className="dasboard_page mobile-display mt-0 mobile-view-profile">
          <Accountleftpanel
            value={this}
            ipadView={"initial-leftpanel-ipadview"}
          />

          <div className="transparent_background transparent_background_overview my-profile">
            

            <div
              className="right_panel right_panel_overview pl-0 pr-0 pb-0 workspace-right-pannel"
            
            >
              {this.state.spinner ? (
               <div className="newspinner">
               <svg xmlns="http://www.w3.org/2000/svg" 
         xlink="http://www.w3.org/1999/xlink" 
         style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
         width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
         <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
           <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
           style={{animationplaystate: "running", animationdelay: "0s"}}>
           </animateTransform>
         </circle>
         </g>
         <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
         style={{animationplaystate: "running", animationdelay: "0s"}}>
         </animateTransform>
         </circle>
         </g>
         <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
         style={{animationplaystate: "running", animationdelay: "0s"}}>
 
         </animateTransform>
         </circle>
         </g>
         <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
         <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
         style={{animationplaystate: "running", animationdelay: "0s"}}>
 
         </animateTransform>
         </circle>
         </g>
         </svg>
             </div>
              ) : (
                <></>
              )}
              <div className="right_general d-md-flex d-block">
                <div className="your-info your-info-overview mt-4 mt-md-0 your-info-mobile ">
                  <div className="customiseappfeatures customiseappfeatures-workspace">
                    <div className="overview-intro-section">
                  <div className="d-flex justify-content-between align-items-center overview-head">
                    <div className="overview-heading-main">
                    <h1 className="d-none d-md-block mb-0">Overview</h1>
                    <p className=" d-none d-md-block mb-4 pl-3 pl-md-0 pr-3 pr-md-0 overview-head-subtext">
              A workspace is where you can create and manage a group of apps
              away from your personal account.{" "}
              {/* <a
                href="https://support.appmysite.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Learn More{" "}
                <img
                  src={
                    require("../../assets/images/dashboard/Goto.png").default
                  }
                  className="gotoappicon appear-spacing"
                  alt="Goto"
                />
              </a> */}
              ​{" "}
            </p>
            </div>
                  
                        
                  
                   
                    <div className="mb-3 profile-detail-btn-mobile workspace-save-web d-flex justify-content-center align-items-center">
                      {this.state.workspaceroleid !== 1 && this.state.workspaceroleid !== null && this.state.workspaceroleid !== undefined ?
                        <div className="leave-workspace d-flex align-items-center justify-content-center" onClick={(e) => this.workspaceleave(e)}>                            
                            <button><svg width="13.811" height="15.5" viewBox="0 0 13.811 15.5">
                          <g id="Group_30022" data-name="Group 30022" transform="translate(-1428.25 -106.25)">
                            <path id="Path_84774" data-name="Path 84774" d="M9,1.99V0H0V14H9V11.979" transform="translate(1429 107)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                            <path id="Path_84775" data-name="Path 84775" d="M-14778,15255.055l3,3-3,3" transform="translate(16216 -15144)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                            <path id="Path_84776" data-name="Path 84776" d="M-14784.06,15258.055h8.872" transform="translate(16216 -15144)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                          </g>
                        </svg> Leave Workspace</button>
                        </div>
                        
                      : null
                      }
                      {/* <button onClick={(e) => this.workspaceleave(e)}>Leave workspace button</button> */}

                          <button
                            type="submit"
                            className="next_btn next_btn_overview"
                            form="form1"
                            onClick={(e) => this.workspaceUpdate(e)}
                          >
                            <div className="d-flex align-items-center justify-content-center h-100 w-100">
                              <div className={this.state.loader}>
                                <img
                                  src={this.state.loaderimage}
                                  alt="loader"
                                />
                              </div>{" "}
                              <div>{this.state.submitval}</div>
                            </div>
                          </button>
                        </div>
                    </div>
                    <div className="profile-image-workspace profile-img-mob-new d-md-none">
                      <label className="label-profile-pic" htmlFor="profilepic">
                        {this.state.profile === "" ||
                        this.state.profile === null ||
                        this.state.profile === "null" ||
                        this.state.profile === undefined ||
                        this.state.profile === "undefined" ||
                        !this.state.profile ? (
                          <img
                           
                            src={
                              require("../../assets/images/dashboard/workspace/workspace-default-img.png")
                                .default
                            }
                            className="proimg profile-pic-img workspace-profile-pic-img"
                            alt="profile logo"
                          />
                        ) : (
                          <>
                            {this.state.profile.indexOf("https") === -1 ? (
                              <div
                                style={{
                                  background:
                                    "url(" +
                                    this.state.profilepath +
                                    this.state.profile +
                                    ")",
    
                                }}
                                className="proimg profile-pic-img workspace-profile-pic-img"
                              ></div>
                            ) : (
                              <div
                                style={{
                                  background: "url(" + this.state.profile + ")",
                                }}
                                className="proimg profile-pic-img workspace-profile-pic-img"
                              ></div>
                            )}
                          </>
                        )}

                        {
                        (parseInt(localStorage.getItem("logged_in_user_role_id")) === 1 ||
                        parseInt(localStorage.getItem("logged_in_user_role_id")) === 2) 
                        && (
                          <input
                                // type="file"
                                onClick={(e)=>this.handleprofileimage(e)}
                                id="profilepic"
                                className="d-none"
                                
                              />
                        )}
                      </label>

                      {/* <img
                        src={
                          require("../assets/images/dashboard/workspace/workspace-default-img.png")
                            .default
                        }
                        className="proimg"
                        width="82px"
                        height="82px"
                        alt="profileimg"
                      /> */}
                      <div>
                        {this.state.profile === "" ||
                        this.state.profile === null ||
                        this.state.profile === "null" ||
                        this.state.profile === undefined ||
                        this.state.profile === "undefined" ||
                        !this.state.profile ||
                        (parseInt(
                          localStorage.getItem("logged_in_user_role_id")
                        ) !== 1 && parseInt(
                          localStorage.getItem("logged_in_user_role_id")
                        ) !== 2) ? (
                          <></>
                        ) : (
                          <p
                            className="remove-profile-btn mt-0"
                            onClick={(e) => this.removeimage(e)}
                          >
                            Remove
                          </p>
                        )}
                        <p className="d-block">
                          {localStorage.getItem("workspaceName")}
                        </p>
                      </div>
                    </div>
                    <div className="workspace-info-spacing-mobile workspace-info-mobile-main overview-mid">
                      {/* <div className="d-flex align-items-center justify-content-between">
                        <div>
                          <div className="app_iconname">
                            <h3 className="mb-2">Workspace Information</h3>
                          </div>
                        </div>
                      </div> */}

                      {/* Personal Information */}
                      <div className="mt-2">
                        <div className="d-flex align-items-center justify-content-between flex-wrap">
                          <div className="color_picker mb-4 color-picker-workspace">
                            {/* Tool tip */}
                            <Tooltip
                              firsttag={"h4"}
                              tagclass={"mb-0 mr-2"}
                              classdata={
                                "d-flex justify-content-start align-items-center mb-1 name-input-box-spacing"
                              }
                              heading={"Workspace name"}
                              content={"Enter your full name."}
                            />
                            {/* Tool tip */}
                            <div className="form-group">
                              <input
                                type="text"
                                required
                                className="form-control form-name work-name-input"
                                form="form1"
                                value={this.state.workspaceName}
                                onChange={(e) => this.handleworkspaceName(e)}
                              />
                            </div>
                            <div className="profile-detail-btn profile-detail-btn-mobile workspace-save-mobile">
                          <button
                            type="submit"
                            className="next_btn next_btn_overview"
                            form="form1"
                            onClick={(e) => this.workspaceUpdate(e)}
                          >
                            <div className="d-flex align-items-center justify-content-center h-100 w-100">
                              <div className={this.state.loader}>
                                <img
                                  src={this.state.loaderimage}
                                  alt="loader"
                                />
                              </div>{" "}
                              <div>{this.state.submitval}</div>
                            </div>
                          </button>
                        </div>
                          </div>
                        </div>

                        
                      </div>
                    </div>
                    </div>
                    <div className="workspace-info-spacing-mobile overview-bottom">
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div className="billing-heading billing-heading-overview billing-heading-new w-100">
                          <div className="app_iconname app_iconname_overview w-100 app-iconname-workspace-mobile app-iconname-overview">
                            <h3>Delete Workspace</h3>
                            <h5>
                              Delete all apps in your Workspace before deleting
                              the Workspace itself. Also downgrade to the
                              Preview plan prior to deletion.
                            </h5>
                          </div>
                        </div>
                      </div>
                      {parseInt(
                        localStorage.getItem("logged_in_user_role_id")
                      ) === 3 ||
                      parseInt(
                        localStorage.getItem("logged_in_user_role_id")
                      ) === 4 ? (
                        <div
                          className="del del-overview"
                          
                        >
                          <div
                            onClick={(e) => this.handleDeleteuser()}
                            className="delete-btn delete-workspace-btn"
                          >
                            Delete
                          </div>
                        </div>
                      ) : (
                        <div
                          className="del del-overview"
                          
                        >
                          <div
                            onClick={(e) => this.isDeleteWorkspace(e)}
                            className="delete-btn delete-workspace-btn"
                          >
                            Delete
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {Foot()}
          </div>
        </section>


       {/* New Image upload Pop Up */}

       <NewImageGalleryPopup
        isGalleryPopup={this.state.modal}
        setIsGalleryPopup={true}
        settingYourdata={this.settingYourdata}
        yourErrFun={this.yourErrFun}
        uploadPath={
          parseInt(localStorage.getItem("logged_in_user_role_id")) === 1
          ? localStorage.getItem("originalid") +
            process.env.REACT_APP_Workspace_Profile +
            localStorage.getItem("workspaceId")
          : parseInt(localStorage.getItem("logged_in_user_role_id")) === 2
            ? localStorage.getItem("invited_by_user_id") +
              process.env.REACT_APP_Workspace_Profile +
              localStorage.getItem("workspaceId")
          : localStorage.getItem("logged_in_user_id") +
            process.env.REACT_APP_Workspace_Profile +
            localStorage.getItem("workspaceId")
        }
        settingOrgImgFrom3rdParty={false}
        hideImageGallery={true}
        widthForRestriction={120}
        heightForRestriction={120}
        aspectRatio={"1:1"}
        classCompoState={this}
        cropShape={"round"}
        isNotCustomUploadOrgImg={true}
        // designid={""}
        // loadpicVistaCreatePopUp={this.loadpicVistaCreatePopUp}
        // closeVistaCreatePopUp={this.closeVistaCreatePopUp}
      />


        {/* <Modal
          isOpen={this.state.modal}
          centered
          className={this.props.className}
          style={{ maxWidth: "40%" }}
        >
          <ModalHeader
            toggle={this.photoggle}
            className="modal-xxl-content croppopup"
          >
            <p>Crop Image</p>
            <div className="crossmodal mt-2" onClick={this.photoggle}>
              <svg width="14.779" height="14.778" viewBox="0 0 14.779 14.778">
                <g
                  id="Group_2926"
                  data-name="Group 2926"
                  transform="translate(0.75 0.75)"
                >
                  <path
                    id="Union_18"
                    data-name="Union 18"
                    d="M6.64,6.638,0,13.277,6.64,6.638,0,0,6.64,6.638,13.279,0,6.64,6.638l6.64,6.639Z"
                    fill="#fff"
                    stroke="#7782a1"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                  ></path>
                </g>
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="p-0 cropbody">
            <div
              style={{
                position: "relative",
                width: "95%",
                height: "95%",
                margin: "auto",
                top: "10px",
              }}
            >
              <Cropper
                image={this.state.image}
                crop={this.state.crop}
                zoom={this.state.zoom}
                aspect={this.state.aspect}
                cropShape="round"
                showGrid={false}
                onCropChange={this.onCropChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="d-flex justify-content-end w-100">
              
              <div
                onClick={this.showCroppedImage}
                className="next_btn text-center butt"
                style={{ width: "150px" }}
              >
                {this.state.buttontext}
              </div>
            </div>
          </ModalFooter>
        </Modal> */}

        {/* Country code  */}
        <Modal
          isOpen={this.state.phonecodemodal}
          toggle={this.togglephonecodemodal}
          style={{ maxWidth: "700px", maxHeight: "350px" }}
          className={this.props.className}
          centered
          dataclass="countrycode"
        >
          <ModalHeader
            toggle={this.togglephonecodemodal}
            className="modal-xxl-content "
          >
            <p className="modal-title">Country Code</p>
            <div
              className="crossmodal mt-1"
              onClick={this.togglephonecodemodal}
            >
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="code-selector p-0">
            <div className="d-flex align-items-center search-box justify-content-center country-line">
              <div className="d-flex align-items-center w-100 ">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search Country.."
                  value={this.state.phonecodesearch}
                  onChange={(e) => this.handlephonecodesearch(e)}
                />
              </div>
            </div>
            <div className="myradio pt-md-3 mt-2 mt-md-0">
              {this.state.countryselectfield
                .filter((name) =>
                  name.country_name
                    .toLowerCase()
                    .includes(this.state.phonecodesearch.toLowerCase())
                )
                .map((res, i) => (
                  <div
                    className="d-flex align-items-center justify-content-between country-line"
                    key={i}
                  >
                    <div className="d-flex align-items-center justify-content-start">
                      <img
                        src={
                          process.env.REACT_APP_Image_Path +
                          process.env.REACT_APP_SMALL_FLAG +
                          res.country_code.toLowerCase() +
                          ".png"
                        }
                        alt={i}
                      />
                      <p className="country-name">
                        {res.country_name} ({"+" + res.phone_code})
                      </p>
                    </div>
                    <label className="container1 mt-md-2 mb-md-4">
                      <input
                        type="radio"
                        name="radio"
                        value={res.phone_code}
                        onClick={(e) => this.handleseachradio(e)}
                      />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                ))}
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.userdeletemodal}
          centered
          toggle={this.delete_accountmodal}
          style={{ maxWidth: "600px", maxHeight: "340px" }}
          className={this.props.className + " workspace-delete-modal"}
        >
          <ModalHeader
            toggle={this.delete_accountmodal}
            className="modal-xxl-content confirmpopup confirmpopup-workspace"
          >
            <p className="confirm-over-red">Delete Workspace</p>
            <div className="crossmodal crossmodal-over mt-1" onClick={this.delete_accountmodal}>
              <img src={require("../../assets/images/dashboard/overview-new-cross.png").default } />
            </div>
          </ModalHeader>
          <ModalBody className="overview-modal-body" >
            <div>
              <div className="d-flex flex-wrap overview-flex-main mt-2 mb-3">
                <h5 className="workspace-warning-text1">Deleting an app is permanent and can't be undone. This 
                  will permanently delete the {this.state.workspaceName} workspace, along 
                  with all its users, notifications, templates,
                  and settings. Are you sure you want to remove your app?
                  </h5>
                  <h5 className="workspace-warning-text2">Please type the name of workspace to confirm.</h5>
                {/* <div className="alertconfirm">
                  <svg width="52" height="44" viewBox="0 0 52 44">
                    <g
                      id="Group_3260"
                      data-name="Group 3260"
                      transform="translate(-748 -456)"
                    >
                      <path
                        id="Polygon_11"
                        data-name="Polygon 11"
                        d="M23.417,4.371a3,3,0,0,1,5.166,0l20.743,35.1A3,3,0,0,1,46.743,44H5.257a3,3,0,0,1-2.583-4.526Z"
                        transform="translate(748 456)"
                        fill="#c8cedb"
                      />
                      <path
                        id="Path_6764"
                        data-name="Path 6764"
                        d="M-.712-.1V13.655"
                        transform="translate(774.5 472.5)"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="3"
                      />
                      <path
                        id="Path_6765"
                        data-name="Path 6765"
                        d="M-.712-.1V3.538"
                        transform="translate(774.5 489.973)"
                        fill="none"
                        stroke="#fff"
                        strokeWidth="3"
                      />
                    </g>
                  </svg>
                </div>
                <div className="confirmtext">
                  <h6>Are you sure you want to delete this Account?</h6>
                  <h6>You can't undo this action.</h6>
                </div> */}
              </div>
              <div className="work-over-main">
              <input type="text" className="work-over-red" onChange={(e) => this.handledeleteworkspaceName(e)} placeholder="Workspace" />
              </div>
              <div className="d-flex justify-content-between finalselection finalselection-overview">
                <div onClick={this.delete_accountmodal}>
                  <p className="refusal-overview">Cancel</p>
                </div>
                <div onClick={(e) => this.deleteWorkspace(e)}>
                  <p className={this.state.deleteLoader}>
                    <img
                      className="delete-loader"
                      // height="28px"
                      src={this.state.deleteLoaderimage}
                      alt="step"
                    />
                  </p>
                  {this.state.deleteLoader !== "d-block" && <p className="approval-overview">Delete</p>}
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.delete_app}
          toggle={this.deleteapps}
          style={{ maxWidth: "600px", maxHeight: "247px" }}
          className={this.props.className}
          centered
          dataclass="countrycode"
        >
          <ModalHeader toggle={this.deleteapps} className="modal-xxl-content">
            <div className="crossmodal mt-1" onClick={this.deleteapps}>
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="completesteps delete_apps">
            <img
              src={
                require("../../assets/images/dashboard/delete-apps.png").default
              }
              alt="step"
            />
            <h4>Action required prior to deletion</h4>
            <p className="mt-2">
              Delete all apps in your Workspace before deleting the Workspace
              itself. Also downgrade to the Preview plan prior to deletion.
            </p>
            <div className="buttonbox">
              <p onClick={this.deleteapps}>OKAY</p>
            </div>
          </ModalBody>
        </Modal>

        <Modal
          isOpen={this.state.workspace_leave}
          toggle={this.workspaceleave}
          style={{ maxWidth: "480px", maxHeight: "320px" }}
          className="leaveworkspacemodal"
          centered
          dataclass="countrycode"
        >
          <ModalHeader toggle={this.workspaceleave} className="modal-xxl-content">
            <div className="crossmodal mt-1" onClick={this.workspaceleave}>
              <img
                src={require("../../assets/images/dashboard/cross.png").default}
              />
            </div>
          </ModalHeader>
          <ModalBody className="before-delete-step">
            <h4>Are you sure you wante to leave this Workspace?</h4>
            <h6>
            Leaving the Workspace is permanent and cannot be undone. You will also lose access to all apps associated with this Workspace.{" "}
            </h6>
            <div className="leave-workspace-footer">
              <div className="leave-workspace-cancel">
                <p onClick={this.workspaceleave}>Cancel</p>
              </div>
              <div className="leave-workspace-ok">
                <>
                  <p className={this.state.loader1}><div className="redloader"></div></p>
                  <p onClick={this.workspaceleavedone}>{this.state.submitval1}</p>
                </>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
