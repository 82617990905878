import React from "react";

import Slider from "../../Reusable/ImageSlider";

import CategoryPreview from "./CategoryPreview";
import { useEffect } from "react";
import { useRef } from "react";
import ProductsPreview from "./ProductsPreview";
import BlogsPreview from "./BlogsPreview";
import BlogBannerPreview from "./BlogBannerPreview";
import WebViewPreview from "./WebViewPreview";
import PagesPreview from "./PagesPreview";
import { Markup } from "interweave";

import JoditEditor from "jodit-react";
import moment from "moment";
import { useState } from "react";
import BottomBarPreview from "./BottomBarPreview";
import TopHeaderPreview from "./TopHeaderPreview";

const HMSpreview = ({
  homescreenData,
  setActiveTab,
  activeTab,
  handleClick,
  openBlockIndx,
  bottomMenuData,
  colorThemeData,
}) => {
  const calculateHeight = (ratio, width) => {
    let height = (width * 3) / 4;
    switch (ratio) {
      case "4:3":
        height = (width * 3) / 4;
        break;
      case "3:4":
        height = (width * 4) / 3;
        break;
      case "1:1":
        height = (width * 1) / 1;
        break;
      case "16:9":
        height = (width * 9) / 16;
        break;
    }
    return height;
  };

  const scrollRef = useRef();
  const editor = useRef(null);
  const config = {
    readonly: true,
    height: "auto",

    // width: "100%",
    toolbarAdaptive: false,
    toolbarButtonSize: "middle",
    colorPickerDefaultTab: "background",
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    defaultActionOnPaste: "insert_clear_html",
    textIcons: false,
    buttons: [
      // "fontsize",
      // "bold",
      // "italic",
      // "underline",
      // "align",
      // "ul",
      // "ol",
      // "link",
    ],
    // controls: {
    //   fontsize: {
    //     list: {
    //       9: "9",
    //       10: "10",
    //       11: "11",
    //       12: "12",
    //       13: "13",
    //       14: "14",
    //       15: "15",
    //       16: "16",
    //       17: "17",
    //       18: "18",
    //       19: "19",
    //       20: "20",
    //     },
    //   },
    // },
    // uploader: { insertImageAsBase64URI: true },
  };
  useEffect(() => {
    if (scrollRef.current) {
      // scrollRef.current.scroll({
      //   top: 10,
      //   left: 10,
      //   behavior: "smooth",
      // });
      scrollRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [openBlockIndx]);

  return (
    <div className="homescreen-preview-layout">
    <div>
      {colorThemeData!==null&&
      <TopHeaderPreview colorThemeData={colorThemeData} bottomMenuData={bottomMenuData}/>
     }
    </div>
    <div className="home-preview-screen">
     
      {homescreenData &&
        homescreenData.map((eachBlock, blockIndx) => {
          switch (eachBlock.item_type) {
            case "main_banner":
              return (
                <div
                  className={`sliderbanner main-banner-preview${blockIndx}`}
                  ref={openBlockIndx === blockIndx ? scrollRef : null}
                >
                  {eachBlock.enable === 1 && (
                    <Slider
                      eachBlock={eachBlock}
                      bannerLayout={eachBlock.style.layout}
                      setActiveTab={setActiveTab}
                      activeTab={activeTab}
                      handleClick={handleClick}
                      blockIndx={blockIndx}
                      homescreenData={homescreenData}
                    />
                  )}
                </div>
              );
            case "product_categories":
             
              return (
                <div
                  ref={openBlockIndx === blockIndx ? scrollRef : null}
                  className={`home-category product-categories-preview${blockIndx}`}
                  style={{
                    background: eachBlock.style.section_background_color,
                  }}
                >
                  {eachBlock.enable === 1 && (
                    <CategoryPreview
                      blockIndx={blockIndx}
                      catPosition={eachBlock.position}
                      catItemType={eachBlock.item_type}
                      catLayout={eachBlock.style.layout}
                      catEnable={eachBlock.enable}
                      catColumn={eachBlock.style.column}
                      catRow={eachBlock.style.row}
                      catShape={eachBlock.style.shape}
                      catShowImage={eachBlock.style.show_image}
                      catPadding={eachBlock.style.padding}
                      catShowHeading={eachBlock.style.show_heading}
                      catHeadingColor={eachBlock.style.heading_color}
                      catHeadingIconColor={eachBlock.style.heading_icon_color}
                      catHeadingIconBgColor={
                        eachBlock.style.heading_icon_background_color
                      }
                      catWholeSectionBgColor={
                        eachBlock.style.section_background_color
                      }
                      catBgColor={eachBlock.style.background_color}
                      catTextColor={eachBlock.style.text_color}
                      catHeadingLabel={eachBlock.value.label}
                    />
                  )}
                </div>
              );

            case "blog_categories":
             
              return (
                <div
                  ref={openBlockIndx === blockIndx ? scrollRef : null}
                  className={`home-category blog-categories-preview${blockIndx}`}
                  style={{
                    background: eachBlock.style.section_background_color,
                  }}
                >
                  {eachBlock.enable === 1 && (
                    <CategoryPreview
                      blockIndx={blockIndx}
                      catPosition={eachBlock.position}
                      catItemType={eachBlock.item_type}
                      catLayout={eachBlock.style.layout}
                      catEnable={eachBlock.enable}
                      catColumn={eachBlock.style.column}
                      catRow={eachBlock.style.row}
                      catShape={eachBlock.style.shape}
                      catShowImage={eachBlock.style.show_image}
                      catPadding={eachBlock.style.padding}
                      catShowHeading={eachBlock.style.show_heading}
                      catHeadingColor={eachBlock.style.heading_color}
                      catHeadingIconColor={eachBlock.style.heading_icon_color}
                      catHeadingIconBgColor={
                        eachBlock.style.heading_icon_background_color
                      }
                      catWholeSectionBgColor={
                        eachBlock.style.section_background_color
                      }
                      catBgColor={eachBlock.style.background_color}
                      catTextColor={eachBlock.style.text_color}
                      catHeadingLabel={eachBlock.value.label}
                    />
                  )}
                </div>
              );
            case "featured_products":
              return (
                <div
                  ref={openBlockIndx === blockIndx ? scrollRef : null}
                  className="featured-product-preview"
                >
                  {eachBlock.enable === 1 && (
                    <ProductsPreview
                      blockIndx={blockIndx}
                      productPosition={eachBlock.position}
                      productItemType={eachBlock.item_type}
                      productLayout={eachBlock.style.layout}
                      productEnable={eachBlock.enable}
                      productColumn={eachBlock.style.column}
                      productRow={eachBlock.style.row}
                      productShape={eachBlock.style.shape}
                      productPadding={eachBlock.style.padding}
                      productShowHeading={eachBlock.style.show_heading}
                      productHeadingColor={eachBlock.style.heading_color}
                      productHeadingIconColor={
                        eachBlock.style.heading_icon_color
                      }
                      productHeadingIconBgColor={
                        eachBlock.style.heading_icon_background_color
                      }
                      productWholeSectionBgColor={
                        eachBlock.style.section_background_color
                      }
                      productHeadingLabel={eachBlock.value.label}
                    />
                  )}
                </div>
              );
            case "new_products":
              return (
                <div
                  ref={openBlockIndx === blockIndx ? scrollRef : null}
                  className="new-product-preview"
                >
                  {eachBlock.enable === 1 && (
                    <ProductsPreview
                      blockIndx={blockIndx}
                      productPosition={eachBlock.position}
                      productItemType={eachBlock.item_type}
                      productLayout={eachBlock.style.layout}
                      productEnable={eachBlock.enable}
                      productColumn={eachBlock.style.column}
                      productRow={eachBlock.style.row}
                      productShape={eachBlock.style.shape}
                      productPadding={eachBlock.style.padding}
                      productShowHeading={eachBlock.style.show_heading}
                      productHeadingColor={eachBlock.style.heading_color}
                      productHeadingIconColor={
                        eachBlock.style.heading_icon_color
                      }
                      productHeadingIconBgColor={
                        eachBlock.style.heading_icon_background_color
                      }
                      productWholeSectionBgColor={
                        eachBlock.style.section_background_color
                      }
                      productHeadingLabel={eachBlock.value.label}
                    />
                  )}
                </div>
              );
            case "sale_products":
              return (
                <div
                  ref={openBlockIndx === blockIndx ? scrollRef : null}
                  className="sale-product-preview"
                >
                  {eachBlock.enable === 1 && (
                    <ProductsPreview
                      blockIndx={blockIndx}
                      productPosition={eachBlock.position}
                      productItemType={eachBlock.item_type}
                      productLayout={eachBlock.style.layout}
                      productEnable={eachBlock.enable}
                      productColumn={eachBlock.style.column}
                      productRow={eachBlock.style.row}
                      productShape={eachBlock.style.shape}
                      productPadding={eachBlock.style.padding}
                      productShowHeading={eachBlock.style.show_heading}
                      productHeadingColor={eachBlock.style.heading_color}
                      productHeadingIconColor={
                        eachBlock.style.heading_icon_color
                      }
                      productHeadingIconBgColor={
                        eachBlock.style.heading_icon_background_color
                      }
                      productWholeSectionBgColor={
                        eachBlock.style.section_background_color
                      }
                      productHeadingLabel={eachBlock.value.label}
                    />
                  )}
                </div>
              );
            case "recently_viewed_products":
              return (
                <div
                  ref={openBlockIndx === blockIndx ? scrollRef : null}
                  className="recently-product-preview"
                >
                  {eachBlock.enable === 1 && (
                    <ProductsPreview
                      blockIndx={blockIndx}
                      productPosition={eachBlock.position}
                      productItemType={eachBlock.item_type}
                      productLayout={eachBlock.style.layout}
                      productEnable={eachBlock.enable}
                      productColumn={eachBlock.style.column}
                      productRow={eachBlock.style.row}
                      productShape={eachBlock.style.shape}
                      productPadding={eachBlock.style.padding}
                      productShowHeading={eachBlock.style.show_heading}
                      productHeadingColor={eachBlock.style.heading_color}
                      productHeadingIconColor={
                        eachBlock.style.heading_icon_color
                      }
                      productHeadingIconBgColor={
                        eachBlock.style.heading_icon_background_color
                      }
                      productWholeSectionBgColor={
                        eachBlock.style.section_background_color
                      }
                      productHeadingLabel={eachBlock.value.label}
                    />
                  )}
                </div>
              );
            case "cart":
              return (
                <div
                  ref={openBlockIndx === blockIndx ? scrollRef : null}
                  className="cart-preview"
                >
                  {eachBlock.enable === 1 && (
                    <ProductsPreview
                      blockIndx={blockIndx}
                      productPosition={eachBlock.position}
                      productItemType={eachBlock.item_type}
                      productLayout={eachBlock.style.layout}
                      productEnable={eachBlock.enable}
                      productColumn={eachBlock.style.column}
                      productRow={eachBlock.style.row}
                      productShape={eachBlock.style.shape}
                      productPadding={eachBlock.style.padding}
                      productShowHeading={eachBlock.style.show_heading}
                      productHeadingColor={eachBlock.style.heading_color}
                      productHeadingIconColor={
                        eachBlock.style.heading_icon_color
                      }
                      productHeadingIconBgColor={
                        eachBlock.style.heading_icon_background_color
                      }
                      productWholeSectionBgColor={
                        eachBlock.style.section_background_color
                      }
                      productHeadingLabel={eachBlock.value.label}
                    />
                  )}
                </div>
              );
            case "sticky_blogs":
              return (
                <div
                  ref={openBlockIndx === blockIndx ? scrollRef : null}
                  className="blog-blog-preview"
                >
                  {eachBlock.enable === 1 && (
                    <BlogsPreview
                      blockIndx={blockIndx}
                      blogPosition={eachBlock.position}
                      blogItemType={eachBlock.item_type}
                      blogLayout={eachBlock.style.layout}
                      blogEnable={eachBlock.enable}
                      blogColumn={eachBlock.style.column}
                      blogRow={eachBlock.style.row}
                      blogShape={eachBlock.style.shape}
                      blogPadding={eachBlock.style.padding}
                      blogShowHeading={eachBlock.style.show_heading}
                      blogHeadingColor={eachBlock.style.heading_color}
                      blogHeadingIconColor={eachBlock.style.heading_icon_color}
                      blogHeadingIconBgColor={
                        eachBlock.style.heading_icon_background_color
                      }
                      blogWholeSectionBgColor={
                        eachBlock.style.section_background_color
                      }
                      blogHeadingLabel={eachBlock.value.label}
                    />
                  )}
                </div>
              );
            case "new_blogs":
              return (
                <div
                  ref={openBlockIndx === blockIndx ? scrollRef : null}
                  className="recent-blog-preview"
                >
                  {eachBlock.enable === 1 && (
                    <BlogsPreview
                      blockIndx={blockIndx}
                      blogPosition={eachBlock.position}
                      blogItemType={eachBlock.item_type}
                      blogLayout={eachBlock.style.layout}
                      blogEnable={eachBlock.enable}
                      blogColumn={eachBlock.style.column}
                      blogRow={eachBlock.style.row}
                      blogShape={eachBlock.style.shape}
                      blogPadding={eachBlock.style.padding}
                      blogShowHeading={eachBlock.style.show_heading}
                      blogHeadingColor={eachBlock.style.heading_color}
                      blogHeadingIconColor={eachBlock.style.heading_icon_color}
                      blogHeadingIconBgColor={
                        eachBlock.style.heading_icon_background_color
                      }
                      blogWholeSectionBgColor={
                        eachBlock.style.section_background_color
                      }
                      blogHeadingLabel={eachBlock.value.label}
                    />
                  )}
                </div>
              );
            case "blog_banner":
              return (
                <div
                  ref={openBlockIndx === blockIndx ? scrollRef : null}
                  className="blog-banner-preview"
                  id={`bannertest${blockIndx}`}
                  style={{
                    marginBottom:
                      eachBlock.style.layout.text_position === "outside_bottom"
                        ? "50px"
                        : "0px",
                  }}
                >
                  {eachBlock.enable === 1 && (
                    <BlogBannerPreview
                      homescreenData={homescreenData}
                      eachBlock={eachBlock}
                      blockIndx={blockIndx}
                    />
                  )}
                </div>
              );
            case "web_view":
              return (
                <div
                  ref={openBlockIndx === blockIndx ? scrollRef : null}
                  className="web-view-preview"
                >
                  {eachBlock.enable === 1 && <WebViewPreview />}
                </div>
              );
            case "pages":
              return (
                <div
                  ref={openBlockIndx === blockIndx ? scrollRef : null}
                  className="pages-preview"
                >
                  {eachBlock.enable === 1 && (
                    <PagesPreview eachBlock={eachBlock} />
                  )}
                </div>
              );
            // case "title":
            //   return (
            //     <div
            //       ref={openBlockIndx === blockIndx ? scrollRef : null}
            //       className={"preview-header"}
            //     >
            //       <h1>{eachBlock.value.title.text} </h1>
            //     </div>
            //   );
            case "image":
              return (
                <div>
                  <div ref={openBlockIndx === blockIndx ? scrollRef : null}>
                    <div
                      className="custom-gallery"
                      style={{
                        height:
                          calculateHeight(eachBlock.style.aspect_ratio, 296) +
                          "px",
                        width: "100%",
                      }}
                    >
                      {eachBlock.portal_value.source_file !== "" &&
                      eachBlock.portal_value.source_file !== undefined &&
                      eachBlock.portal_value.source_file !== null ? (
                        <div
                          className="fullwidthbanner"
                          style={{
                            height: "100%",
                            //   calculateHeight(eachBlock.style.aspect_ratio, 296) +
                            //   "px",
                            width: "100%",
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            backgroundSize: "cover",
                            backgroundImage:
                              "url(" +
                              //   eachBlock.portal_value.source_file ===
                              //   "rainbow-wall.jpg" ||
                              // eachBlock.portal_value.source_file ===
                              //   "floating-unicorn.jpg"
                              //   ? process.env.REACT_APP_Image_Path +
                              //     process.env.REACT_APP_DEFAULT_CMS_IMG +
                              //     eachBlock.portal_value.source_file
                              //   :
                              process.env.REACT_APP_Image_Path +
                              localStorage.getItem("user_id") +
                              "/" +
                              localStorage.getItem("Appid") +
                              process.env.REACT_APP_CMS_Media +
                              eachBlock.portal_value.source_file +
                              ")",
                          }}
                        ></div>
                      ) : (
                        <img
                          className="img-fluid"
                          src={
                            require("../../../assets/images/dashboard/homescreen-banner-default.png")
                              .default
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            case "heading":
              return (
                <div>
                  <div
                    ref={openBlockIndx === blockIndx ? scrollRef : null}
                    className="page-body"
                  >
                    <h2
                      // className={
                      //   `headings ${
                      //     eachBlock.style.bold_text ? "heading-bold" : ""
                      //   } ` +
                      //   `${
                      //     eachBlock.style.italic_text ? "heading-italiq" : ""
                      //   } ` +
                      //   `${
                      //     eachBlock.style.underline ? "heading-underline" : ""
                      //   } ` +
                      //   `${eachBlock.style.text_align}`
                      // }
                      className={`headings heading-bold text-${eachBlock.style.text_align}`}
                    >
                      {eachBlock.value.heading.text}
                    </h2>
                  </div>
                </div>
              );
            case "paragraph":
              return (
                <div>
                  <div
                    ref={openBlockIndx === blockIndx ? scrollRef : null}
                    className="page-body"
                  >
                    {eachBlock.value.paragraph !== undefined &&
                      eachBlock.value.paragraph.text !== undefined && (
                        <Markup content={eachBlock.value.paragraph.text} />
                      )}
                  </div>
                </div>
              );
            case "code":
              return (
                <div>
                  <div
                    ref={openBlockIndx === blockIndx ? scrollRef : null}
                    className="page-body"
                  >
                    <JoditEditor
                      value={eachBlock.value.code.text}
                      ref={editor}
                      config={config}
                    />
                    {/* {handleAppend(eachBlock.value[0].code)} */}
                  </div>
                </div>
              );
            case "spacer":
              return (
                <div>
                  <div
                    ref={openBlockIndx === blockIndx ? scrollRef : null}
                    className="Spacer"
                    style={{ height: `${eachBlock.style.height}px` }}
                  ></div>
                </div>
              );
            case "separator":
              return (
                <div>
                  <div
                    ref={openBlockIndx === blockIndx ? scrollRef : null}
                    className="Separator"
                    style={{
                      border:
                        `${homescreenData[blockIndx].style.height}px` +
                        " solid " +
                        `${homescreenData[blockIndx].style.color}`,
                    }}
                  ></div>
                </div>
              );
            case "button":
              return (
                <div className="managebutton">
                  <a
                    ref={openBlockIndx === blockIndx ? scrollRef : null}
                    className="linkbutton"
                    // target="_blank"
                    // href={`${homescreenData[blockIndx].value.button.target}`}

                    style={{
                      color: `${homescreenData[blockIndx].style.text_color}`,
                      backgroundColor: `${homescreenData[blockIndx].style.button_color}`,
                      borderRadius: "3px",
                    }}
                  >
                    {homescreenData[blockIndx].value.button.text}
                  </a>
                </div>
              );
            case "gallery":
              return (
                <div>
                  <div ref={openBlockIndx === blockIndx ? scrollRef : null}>
                    <div className="page-body ">
                      {/* GalleryLayouts */}
                      <div className="gallerygrid">
                        {eachBlock.portal_value &&
                          eachBlock.showOnlyArr &&
                          eachBlock.showOnlyArr.map((eachImage, i) => {
                            return (
                              <div
                                // className={`col-md-${calculateRowAndCol(
                                //   eachBlock.style.row
                                // )} `}
                                className={`custom-gallery ${
                                  (i + 1) % eachBlock.style.row === 0
                                    ? "not-padding-class"
                                    : "padding-class"
                                }`}
                                style={{
                                  width: `${100 / eachBlock.style.row}%`,
                                  height:
                                    calculateHeight(
                                      eachBlock.style.aspect_ratio,
                                      296 / eachBlock.style.row
                                    ) + "px",
                                }}
                              >
                                {eachBlock.portal_value[i].source_file !== "" &&
                                eachBlock.portal_value[i].source_file !==
                                  undefined &&
                                eachBlock.portal_value[i].source_file !==
                                  null ? (
                                  <div
                                    className="img-fluid"
                                    style={{
                                      backgroundRepeat: "no-repeat",
                                      backgroundPosition: "center",
                                      backgroundSize: "cover",
                                      width: "100%",
                                      height: "100%",
                                      backgroundImage:
                                        "url(" +
                                        process.env.REACT_APP_Image_Path +
                                        localStorage.getItem("user_id") +
                                        "/" +
                                        localStorage.getItem("Appid") +
                                        process.env.REACT_APP_CMS_Media +
                                        eachBlock.portal_value[i].source_file +
                                        ")",
                                    }}
                                  />
                                ) : (
                                  <img
                                    className="img-fluid"
                                    src={
                                      require("../../../assets/images/dashboard/homescreen-banner-default.png")
                                        .default
                                    }

                                    // src=
                                  />
                                )}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              );
          }
        })}
      
    </div>
    <div>
      {bottomMenuData !== null && (
        <BottomBarPreview bottomMenuData={bottomMenuData} />
      )}
    </div>
    </div>
  );
};

export default HMSpreview;
