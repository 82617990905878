import React from "react";

const AndroidIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18.591"
      height="22"
      viewBox="0 0 18.591 22"
    >
      <path
        id="Path_7126"
        data-name="Path 7126"
        d="M1.376,15.116A1.3,1.3,0,0,1,0,13.912V8.761A1.3,1.3,0,0,1,1.376,7.556,1.3,1.3,0,0,1,2.753,8.761v5.151A1.3,1.3,0,0,1,1.376,15.116Zm10.411,5.037,1.2,1.2a.379.379,0,0,1-.537.536l-1.48-1.478a7.024,7.024,0,0,1-3.32,0L6.175,21.889a.379.379,0,1,1-.537-.536l1.2-1.193A4.85,4.85,0,0,1,3.45,15.8c0-.023,11.706,0,11.706,0A4.843,4.843,0,0,1,11.788,20.153ZM7.582,17.178a.687.687,0,1,0,.689.687A.687.687,0,0,0,7.582,17.178Zm3.444,0a.687.687,0,1,0,.689.687A.687.687,0,0,0,11.025,17.178Zm6.19-2.062a1.3,1.3,0,0,1-1.377-1.2V8.761a1.389,1.389,0,0,1,2.753,0v5.151A1.3,1.3,0,0,1,17.216,15.116ZM3.45,6.875a2.063,2.063,0,0,1,2.059-2.06V1.205A1.3,1.3,0,0,1,6.886,0,1.3,1.3,0,0,1,8.261,1.205V4.814H10.33V1.205A1.3,1.3,0,0,1,11.706,0a1.3,1.3,0,0,1,1.376,1.205V4.814h.009a2.063,2.063,0,0,1,2.066,2.06v8.243H3.451V6.875Z"
        transform="translate(18.591 22) rotate(180)"
        fill=""
      />
    </svg>
  );
};

export default AndroidIcon;
