import React from "react";
import Accountleftpanel from "../Accountleftpanel";
import { Link } from "react-router-dom";
import "./Invoicess.css";
import "../../assets/css/newheader.css";
import "../../assets/css/profileleftpanel.css";
import Header from "../Header";
import ProfileLeftPanel from "../ProfileLeftPanel";
import newDefaultImg from "../../assets/images/dashboard/sub-overview-img.png";
import {
  NewBillinghistoryApi,
  Invoicelink,
  getAgencies,
  getWorkspace,
} from "../../Api/Dashboard/DashboardApi";
import {
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { Spinner } from "reactstrap";
import BottomScrollListener from "react-bottom-scroll-listener";
import { transform } from "@babel/core";
import { InnerFoot } from "../Foot";
import ReactSelect from "react-select";
import userdefaultImgOnError from "../../assets/images/dashboard/accountprofile.png";
import wpdefaultImgOnError from "../../assets/images/dashboard/workspace/workspace-default-img.png";
import moment from "moment";
import Billingheader from "./Billingheader";
import "./Billings.css";
export default class Billinghistory extends React.Component {
  state = {
    billinghistory: [],
    customer_type: null,
    appnames: [],
    spinner: true,
    mainSpinner: true,
    pageval: 1,
    datanumber: "",
    bottomspinner: false,
    customer_billing_type: "",
    menuData: [],
    userInfo: {},
    invitedWorkspaceData: [],
    user_role: "",
    wpIDforDrop: localStorage.getItem("workspaceId"),
    mergeArray: [],
    right_panel_expand: true,
    mobileBillingDropdown: false,
    mergeAgencies: [],
    typedata:[],
    seachapp_id:'',
    seachworkspace_id:'',
    seachagency_id:'',
    searchAppVal:'',
    openAppDropdown:false,
    searchname:'All',
    mobileBillingDropdownTwo: false,
    hitNextPagination:false,
    totalmergeArray:[{value:1,label:'Pay-per-app'},{value:2,label:'Unlimited apps'},{value:3,label:'Agency'},{value:4,label:'Support'}]
  };
  componentDidMount() {
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
    {
      document.title = "Invoices | AppMySite";
    }
    else
    {
      document.title = "Invoices |"+ ' ' +localStorage.getItem('agencyname');      
    }
    
    let searchstr = (this.props).history.location.search;
    let urlParams = (new URLSearchParams(searchstr));
    let type = urlParams.get('type');
    let id = urlParams.get('id');

    
    

    if(type !== undefined && type !== null && type !== "")
    {
      if(type == 'agency')
      {
        const params = {
          limit: 20,
          page: 1,
          type:3,
          workspace_id:"",
          agency_id:id,
          app_id:""
        };
    
        NewBillinghistoryApi(params, this);

        localStorage.setItem('selectedbilling',3);
        
        this.setState({selectedbillingtype:3});
      }
      if(type == 'workspace')
      {
        const params = {
          limit: 20,
          page: 1,
          type:2,
          workspace_id:id,
          agency_id:"",
          app_id:""
        };
    
        NewBillinghistoryApi(params, this);

        localStorage.setItem('selectedbilling',2);
        
        this.setState({selectedbillingtype:2});
      }
      if(type == 'apps')
      {
        const params = {
          limit: 20,
          page: 1,
          type:1,
          workspace_id:"",
          agency_id:"",
          app_id:id
        };
    
        NewBillinghistoryApi(params, this);

        localStorage.setItem('selectedbilling',1);
        
        this.setState({selectedbillingtype:1});
      }
        
    }
    else
    {


      if(localStorage.getItem("selectedbilling") && localStorage.getItem("selectedbilling") != '' && localStorage.getItem("selectedbilling") != null && localStorage.getItem("selectedbilling") != undefined && localStorage.getItem("selectedbilling") != 'null' && localStorage.getItem("selectedbilling") != 'undefined')
      {
        
      const params = {
        limit: 20,
        page: 1,
        type:parseInt(localStorage.getItem("selectedbilling")),
        workspace_id:"",
        agency_id:"",
        app_id:""
      };
  
      NewBillinghistoryApi(params, this);
        this.setState({selectedbillingtype:parseInt(localStorage.getItem("selectedbilling"))});
        this.setState({selectedbillingname:parseInt(localStorage.getItem("selectedbilling")) == 1 ? 'Pay-per-app': parseInt(localStorage.getItem("selectedbilling")) == 2 ? 'Unlimited apps' :'White-label'})
      }
      else
      {
        
      const params = {
        limit: 20,
        page: 1,
        type:1,
        workspace_id:"",
        agency_id:"",
        app_id:""
      };
  
      NewBillinghistoryApi(params, this);
        localStorage.setItem("selectedbilling",1);
      }
    }
  }

  handleAppdropdown = () => {
    this.setState({
      openAppDropdown: !this.state.openAppDropdown,
    });
  };

  handleSubsSearch = (e) => {
    this.setState({
      subsSeacrch: e.target.value,
    });
  };
  handlepagination = (pagination) => 
  {
    NewBillinghistoryApi(pagination, this);
  };
  handleRightPanelExpand = (rightpanelvalue) => {

    this.setState({
      right_panel_expand: rightpanelvalue,
    });
  };
  handleBillingChangeTotal = (workspaceData, from) => 
  {
      localStorage.setItem("selectedbilling", workspaceData.value);
      this.setState({selectedbillingtype:workspaceData.value,selectedbillingname:workspaceData.label,mobileBillingDropdown:false,mobileBillingDropdownTwo:false});
      const params = 
      {
        limit: 20,
        page: 1,
        workspace_id: "",
        agency_id: "",
        app_id: "",
        type : workspaceData.value,
        
      };
      
      this.setState({mainSpinner:true,searchname:"All"});
      
      NewBillinghistoryApi(params, this);
  };

  newhandleBillingChange = (workspaceData, from) => 
  {
    this.setState({typedata:[]});
    if (parseInt(localStorage.getItem("selectedbilling")) == 1) 
    {
      if(workspaceData != '')
      {
        var idapp= btoa(workspaceData.app_id);
        var idname = workspaceData.app_name;
      }
      else
      {
        var idapp= '';
        var idname = 'All';
      }
      const params = 
      {
        limit: 20,
        page: 1,
        workspace_id: "",
        agency_id: "",
        app_id:idapp ,
        type : 1,
      };
      this.setState({seachapp_id:workspaceData.app_id,searchname:idname,typedata:[],mainSpinner:true,mobileBillingDropdown:false,mobileBillingDropdownTwo:false}, () => {NewBillinghistoryApi(params, this)});
     
    } 
    else if (parseInt(localStorage.getItem("selectedbilling")) == 2) 
    {
      if(workspaceData != '')
      {
        var idapp= btoa(workspaceData.id);
        var idname = workspaceData.workspace_name;
      }
      else
      {
        var idapp= '';
        var idname = 'All';
      }
      const params = 
      {
        limit: 20,
        page: 1,
        workspace_id: idapp,
        agency_id: "",
        app_id: "",
        type : 2,
      };
      this.setState({seachworkspace_id:workspaceData.id,searchname:idname,typedata:[],mainSpinner:true,mobileBillingDropdown:false,mobileBillingDropdownTwo:false}, () => {NewBillinghistoryApi(params, this)});
    } 
    else if (parseInt(localStorage.getItem("selectedbilling")) == 3) 
    {
      
      if(workspaceData != '')
      {
        var idapp= btoa(workspaceData.id);
        var idname = workspaceData.agency_name;
      }
      else
      {
        var idapp= '';
        var idname = 'All';
      }
      const params = 
      {
        limit: 20,
        page: 1,
        workspace_id: "",
        agency_id: idapp,
        app_id: "",
        type : 3,
      };
      this.setState({seachagency_id:workspaceData.id,searchname:idname,typedata:[],mainSpinner:true,mobileBillingDropdown:false,mobileBillingDropdownTwo:false}, () => {NewBillinghistoryApi(params, this)});
      
    } 
    else 
    {
      this.setState({typedata:[]});
    }
    // window.location.href = `/subscriptions`;
  };
  handleredirection = (e, app_id, id) => {
    e.preventDefault();
    const params = {
      workspace_id:
        localStorage.getItem("workspaceId") !== "" &&
        localStorage.getItem("workspaceId") !== null &&
        localStorage.getItem("workspaceId") !== undefined
          ? localStorage.getItem("workspaceId")
          : "",
      app_id:
        localStorage.getItem("workspaceId") !== "" &&
        localStorage.getItem("workspaceId") !== null &&
        localStorage.getItem("workspaceId") !== undefined
          ? ""
          : app_id,
      agency_id:
          localStorage.getItem("agency_id") === "" ||
          localStorage.getItem("agency_id") === undefined ||
          localStorage.getItem("agency_id") === null ||
          localStorage.getItem("agency_id") == '1'
            ? ""
            : btoa(parseInt(localStorage.getItem("agency_id"))),
      invoice_id: parseInt(id),
    };
    Invoicelink(params, this);
  };

  handlehrefredirection = (e, link) => {
    window.open(
      link,
      "_blank" // <- This is what makes it open in a new window.
    );
  };

  handleContainerOnBottom = () => {
    if (this.state.bottomspinner === false) {
      if (this.state.hitNextPagination) 
      {
     
        this.setState({ pageval: 1 + this.state.pageval, bottomspinner: true });
        
        const params = {
          limit: 20,
          page: this.state.pageval,
          billinghistory: this.state.billinghistory,
          type:parseInt(localStorage.getItem("selectedbilling")),
          workspace_id:parseInt(localStorage.getItem("selectedbilling")) == 2
                      ? this.state.seachworkspace_id
                      : "",
          agency_id:parseInt(localStorage.getItem("selectedbilling")) == 3 
                    ? this.state.seachagency_id
                    : "",
          app_id: parseInt(localStorage.getItem("selectedbilling")) == 1 
              ? this.state.seachapp_id
              : "",
        };
    
        NewBillinghistoryApi(params, this);
        // this.handlepagination(pagination);
      }
    }
  };
  //changeoption = (e) => {
  // let getval = e.target.value;
  //}

  ordinal_suffix_of(i) {
    let j = i % 10,
      k = i % 100;
    if (j == 1 && k != 11) {
      return i + "st";
    }
    if (j == 2 && k != 12) {
      return i + "nd";
    }
    if (j == 3 && k != 13) {
      return i + "rd";
    }
    return i + "th";
  }

  handleTime = (date) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const orignalDate = new Date(date).toLocaleTimeString([], {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
    // const orignalDate = new Date(date).toLocaleString();
    const seprateTimeAndDate = orignalDate.split(",");
    const formatDate = seprateTimeAndDate[0].split("/");
    const month = monthNames[formatDate[0] - 1];
    const day =
      /*this.ordinal_suffix_of*/
      parseInt(formatDate[1]);
    const year = formatDate[2];
    const time = seprateTimeAndDate[1];

    return (
      <>
        <p>
          {day} {month} {year}, {time}
          {/* <span
            style={{
              textTransform: "lowercase",
              fontSize: "13px",
              color: "#7782A1",
            }}
          >
            {time}
          </span> */}
        </p>
      </>
    );
  };

  handlepayment = (e, id) => {
    e.preventDefault();
  };

  handleBillingChange = (workspaceData, from) => {
    this.setState({
      mainSpinner: true,
      wpIDforDrop: workspaceData.id,
    });

    if (workspaceData.type === "user") 
    {
      localStorage.setItem("workspaceId", "");
      localStorage.setItem("workspaceName", "");
      localStorage.setItem("workspaceImage", "");
      localStorage.setItem("logged_in_user_role_id", 1);
    } 
    else if(workspaceData.agency_name != undefined)
    {
      localStorage.setItem("workspaceId", "");
      localStorage.setItem("workspaceName", "");
      localStorage.setItem("workspaceImage", "");
      localStorage.setItem("logged_in_user_role_id", 1);
      localStorage.setItem("agency_id", workspaceData.id);

    } 
    else 
    {
      if (
        workspaceData.role_id !== undefined &&
        workspaceData.role_id !== null &&
        workspaceData.role_id !== ""
      ) {
        localStorage.setItem("logged_in_user_role_id", workspaceData.role_id);

        localStorage.setItem("invited_by_user_id", workspaceData.user_id);
      } else {
        localStorage.setItem("logged_in_user_role_id", 1);
        // localStorage.setItem("invited_by_user_id", "");
      }
      localStorage.setItem("workspaceId", workspaceData.id);
      localStorage.setItem("workspaceName", workspaceData.workspace_name);
      localStorage.setItem("agency_id", "");

      localStorage.setItem(
        "workspaceImage",
        workspaceData.profile_image === null ? "" : workspaceData.profile_image
      );
      // const params = {
      //   limit: 20,
      //   page: 1,
      //   workspace_id: btoa(workspaceData.id),
      // };
      // Subscriptionlist(params, this);
    }
    window.location.href = `/invoices`;
  };

  handleMobileBillingDropdown = () => {
    this.setState({
      mobileBillingDropdown: !this.state.mobileBillingDropdown,
    });
  };

  handleMobileBillingDropdowntwo = () => {
    this.setState({
      mobileBillingDropdownTwo: !this.state.mobileBillingDropdownTwo,
      mobileBillingDropdown:false,
    });
  };

  render() {
    const customStyles = {
      control: (base, state) => ({
        ...base,
        background: "#ffffff",
      }),
    };

    const invoice_status = {
      width: "auto",
      padding: "3px 10px 3px 10px",
      lineHeight: "12px",
      height: "auto",
      textTransform: "capitalize",
    };
    const statusColor = (color) => {
      if (color === "void") {
        return "inactive-grey";
      } else if (color === "paid") {
        return "active-green";
      } else if (color === "unpaid" || color === "uncollectible") {
        return "inactive-red";
      } else {
        return "inactive-red";
      }
    };
    const timestyle = {
      textTransform: "lowercase",
      fontSize: "13px",
      color: "#7782A1",
    };
    return (
      <>
       
       
        <Header />
        <section className="portal-body ">
          {/* <Accountleftpanel ipadView={"initial-leftpanel-ipadview"} /> */}
          <ProfileLeftPanel rightpanelbody={this.handleRightPanelExpand} profile={""} />
           <BottomScrollListener
          onBottom={this.handleContainerOnBottom}
          offset={300}
        >
          {(scrollRef) => (
            <div
            style={{
              height: "calc(100vh - 60px)",
              overflowY: "auto",
            }}
            ref={scrollRef}
            className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined &&
            localStorage.getItem('sidebaserotate') === 'true')  ? "right-panel-gap right-panel-gap-expand"
             : "right-panel-gap "}
          >
           <div className="justify-content-between billings-head-flex billing-head-redesign">
            <h1 className="d-none d-md-block">Billing <p className="billing-tooltip">
              <img
                src={
                  require("../../assets/images/dashboard/Billing/billingexclamation.png")
                    .default
                }
                alt="app-icon"
                className=""
              />
              <p className="billing-tooltipsubheader">
                View all your subscription and purchase activity here.
              </p>
            </p></h1>
            {this.state.mainSpinner ?
             <></>
            :
              <div style={{display: "flex", alignItems: "center"}}>
                  <div className="d-flex  billing_billingsub" style={{marginRight:"10px"}}>
                    {(window.location.pathname === "/subscriptions" ||
                      window.location.pathname === "/invoices") && 
                    (
                      <ReactSelect
                        className="billing-react-select-dropdown"
                        classNamePrefix="react-select22"
                        options={this.state.totalmergeArray}
                        onChange={this.handleBillingChangeTotal}
                        isSearchable={false}
                        formatOptionLabel={(res) => 
                        (
                          <div className="d-flex test">
                            <div className="billing-options">
                              <span className="billing-options-workspace-name">
                              {res.label}
                              </span>
                            <>
                              {res.value == 1 ?
                              <>
                              <p className="billing-options-workspace-type">
                                {(parseInt(localStorage.getItem('personal_apps_count')) + parseInt(localStorage.getItem('invited_apps_count'))) + " Apps"}
                              </p>
                              </>
                              : res.value == 2 ?
                                <>
                                <p className="billing-options-workspace-type">
                                {(parseInt(localStorage.getItem('workspace_count')) + parseInt(localStorage.getItem('invited_workspace_count'))) + " Workspaces"}
                                </p>
                                </>
                              : res.value == 3 ?
                              <>
                              <p className="billing-options-workspace-type">
                              {(parseInt(localStorage.getItem('personal_agency_count')) + parseInt(localStorage.getItem('invited_agency_count'))) + " White-label"}
                              </p>
                              </>
                              : res.value == 4 ?
                              <>
                              <p className="billing-options-workspace-type">
                              Support
                              </p>
                              </>
                              : <></>
                              }
                            </>
                            </div>
                          </div>
                        )}
                        value={this.state.totalmergeArray.filter(function (option) {
                          return (option.value ===parseInt(localStorage.getItem("selectedbilling")));
                        })}
                        isOptionDisabled={(option) => 
                          {   return (option.value === parseInt(localStorage.getItem("selectedbilling")));
                          }}
                      />
                    )}
                  </div>
                  <div className="d-flex  billing_billingsub billings-dropdown" style={{marginRight:"10px"}}>
                    {(window.location.pathname === "/subscriptions" ||
                      window.location.pathname === "/invoices") && (
                    <>
                      
                      <Dropdown disabled={localStorage.getItem("selectedbilling")==4}   isOpen={this.state.openAppDropdown} toggle={this.handleAppdropdown} style={{width:'100%'}}>
                        <DropdownToggle style={{                 
                        cursor: localStorage.getItem("selectedbilling") == 4 ? 'not-allowed' : 'pointer',
                        }}  caret>

                          <div className="billings-all-option">
                          <span  style={{ 
                          color: localStorage.getItem("selectedbilling") == 4 ? "#BCC1CE" : '#000',
                          }} className={`search-dropdown-before `}>{this.state.searchname}</span>
                          {this.state.searchname != 'All' ?
                                          <svg style={{position: "relative", top: "-2px"}} xmlns="http://www.w3.org/2000/svg" width="11.06" height="11.06" viewBox="0 0 11.06 11.06" onClick={(e) => this.newhandleBillingChange('')}>
                                            <path id="Union_2970" data-name="Union 2970" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                          </svg>
                                        : <svg xmlns="http://www.w3.org/2000/svg" width="11.063" height="6.591" viewBox="0 0 11.063 6.591">
                                        <path id="Path_108406" data-name="Path 108406" d="M-17299.426-4409.885l5,5,5-5" transform="translate(17299.957 4410.416)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                      </svg>
                                      
                                        }
                          </div>
                          
                        </DropdownToggle>
                        <DropdownMenu>
                          {this.state.typedata && this.state.typedata.length === 0 && 
                          (
                            <div className="no-app-dropdown">
                              <img className="img-fluid" src={require("../../assets/images/dashboard/no-apps-created.png").default} alt="No App Found"/>
                            </div>
                          )}
                          {this.state.typedata && this.state.typedata.length > 0 && 
                          (
                            <>
                            <FormGroup>
                              <div className="dropdown-search">
                                <input placeholder="Search..." type="text" value={this.state.searchAppVal} onChange={(e) => {this.setState({searchAppVal: e.target.value});}}
                                ></input>
                                {this.state.searchAppVal.trim() && (
                                  <p onClick={(e) => {this.setState({searchAppVal: ""})}}>
                                    Clear
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                            {/* <DropdownItem onClick={(e) => this.newhandleBillingChange('')}>All</DropdownItem> */}
                            </>
                          )}
                        
                          {this.state.typedata && this.state.typedata.map((res) => 
                            {
                              if(this.state.searchAppVal == '' || 
                                  (parseInt(localStorage.getItem("selectedbilling")) == 1 && res.app_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase())) ||
                                  (parseInt(localStorage.getItem("selectedbilling")) == 2 && res.workspace_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase())) ||
                                  (parseInt(localStorage.getItem("selectedbilling")) == 3 && res.agency_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase())) 
                                )
                              {
                                return(
                                        <DropdownItem onClick={(e) => this.newhandleBillingChange(res)}>
                                          <div className="billing-new-dropdown">
                                            <>
                                                {this.state.typedata.length > 0 && parseInt(localStorage.getItem("selectedbilling")) == 1 ? 
                                                  <>
                                                    {this.state.searchAppVal == '' || res.app_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase()) ?
                                                      <>
                                                        <div>
                                                        {res.app_icon === "" ? 
                                                          (
                                                            <img
                                                              style={{
                                                                width: "40px",
                                                                height: "40px",
                                                                backgroundRepeat: "no-repeat",
                                                                backgroundSize: "cover",
                                                                marginRight: "10px",
                                                              }}
                                                              src={require("../../assets/images/dashboard/my-account.png").default}
                                                              className="img-fluid"
                                                              alt="profile logo"
                                                            />
                                                          ) 
                                                        : 
                                                          (
                                                              <>
                                                                  <img
                                                                    className="headers-profil-img"
                                                                    alt=""
                                                                    style={{
                                                                      width: "40px",
                                                                      borderRadius: "14px",
                                                                      height: "40px",
                                                                      backgroundSize: "cover",
                                                                      marginRight: "10px",
                                                                    }}
                                                                    src={
                                                                      process.env.REACT_APP_Image_Path +
                                                                      res.user_id +
                                                                      "/" +
                                                                      res.app_id +
                                                                      process.env.REACT_APP_AppIcon_Display +
                                                                      res.app_icon
                                                                    }
                                                                    onError={({ currentTarget }) => {
                                                                      currentTarget.onerror = null;
                                                                      currentTarget.src =
                                                                        res.type==="user"
                                                                        ? userdefaultImgOnError :wpdefaultImgOnError
                                                                    }}
                                                                  />
                                                              </> 
                                                          )
                                                        }
                                                        </div>
                                                          <div className="billing-options">
                                                            <h5>
                                                            {res.app_name}
                                                            </h5>
                                                            <h6>
                                                              {"Pay-per-app"}
                                                            </h6>
                                                          </div>
                                                      </>
                                                    : <></>
                                                    }
                                                  </>
                                                : this.state.typedata.length > 0 &&  parseInt(localStorage.getItem("selectedbilling")) == 2 ? 
                                                    <>
                                                      {this.state.searchAppVal == '' || res.workspace_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase()) ?
                                                      <>
                                                          <div>
                                                          <>
                                                            {res.profile_image === "" ||
                                                            res.profile_image === null ||
                                                            res.profile_image === "null" ||
                                                            res.profile_image === undefined ? 
                                                            (
                                                              <div className="biiling-iconsize">
                                                                <img src={require("../../assets/images/dashboard/sub-overview-img.png").default} className=""/>
                                                              </div>
                                                            ) 
                                                            : 
                                                            (
                                                              <div className="biiling-iconsize">
                                                              <img src={process.env.REACT_APP_Image_Path + res.user_id +
                                                                        process.env.REACT_APP_Workspace_Profile + res.id +
                                                                        "/" + res.profile_image
                                                                      }
                                                                    onError={({currentTarget,}) => {
                                                                    currentTarget.onerror = null; currentTarget.src =newDefaultImg;
                                                                    }}
                                                                className=""
                                                                alt="workspace"
                                                              />
                                                              </div>
                                                            )}
                                                          </>
                                                          </div>
                                                          <div className="billing-options">
                                                            <h5>
                                                            {res.workspace_name}
                                                            </h5>
                                                            <h6>
                                                              {"Unlimited-apps"}
                                                            </h6>
                                                          </div>
                                                      </>
                                                      : <></>
                                                      }
                                                    </>
                                                : this.state.typedata.length > 0 && parseInt(localStorage.getItem("selectedbilling")) == 3 ? 
                                                  <>
                                                    {this.state.searchAppVal == '' || res.agency_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase()) ?
                                                      <>
                                                          <div>
                                                          <>
                                                            {res.favicon === "" ||
                                                            res.favicon === null ||
                                                            res.favicon === "null" ||
                                                            res.favicon === undefined ? 
                                                            (
                                                              <div className="biiling-iconsize">
                                                                <img src={require("../../assets/images/dashboard/sub-overview-img.png").default} className=""/>
                                                              </div>
                                                            ) 
                                                            : 
                                                            (
                                                              <div className="biiling-iconsize">
                                                              <img src={process.env.REACT_APP_Image_Path + "agencies/" + res.id + process.env.REACT_APP_Agency_Profile + res.favicon}
                                                                    onError={({currentTarget,}) => {
                                                                    currentTarget.onerror = null; currentTarget.src =newDefaultImg;
                                                                    }}
                                                                className=""
                                                              />
                                                              </div>
                                                            )}
                                                          </>
                                                          </div>
                                                            <div className="billing-options">
                                                              <h5>
                                                              {res.agency_name}
                                                              </h5>
                                                              <h6>
                                                                {"White-label"}
                                                              </h6>
                                                            </div>
                                                      </>
                                                    : <></>
                                                    }
                                                  </>
                                                : <></>
                                                }
                                            </>
                                          </div>
                                        </DropdownItem>
                                      );
                              }
                              
                          })}
                        </DropdownMenu>
                      </Dropdown>
                    </>
                    )}
                  </div>
              </div>
            }
          </div>
          <Billingheader value={this} />

            <div className="row scrollbar-settings">
              <div className="col-md-12 billing-page-padding-r">
                <div className=" billing_social_pannel">
                  <div className="d-md-flex d-block align-items-center justify-content-between billings_bill-pay-version">

                  </div>
                </div>
              </div>
            </div>
            <div className=" right_panel_billing biling-page  billing_right_panel min-height-page">
              <div className="right_general">
                {this.state.mainSpinner ? (
                  <div className="billingnewspinner" >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                      width="200px"
                      height="200px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g
                        transform="translate(20 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#e6261f"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.375s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(40 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#f7d038"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(60 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#49da9a"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(80 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#4355db"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                ) : (
                  <>
                    
                    <div className="row">
                      <div className="col-md-12 billings-invoice-mob">
                        {window.matchMedia("(min-width: 1201px)").matches && (
                          <section className="  ">
                            {this.state.billinghistory.length === 0 ? (
                              <>
                                <div className="text-center billing_nodataimg w-100">
                                  <img
                                    src={
                                      require("../../assets/images/dashboard/NoInvoice.png")
                                        .default
                                    }
                                    className="img-fluid noInvoice"
                                    alt="nosubscription"
                                  />
                                </div>
                                <div className="billing_nodatatext">
                                  
                                  <span className="custom-size text-center not-subscribed span1">
                                    There is nothing here at the moment. Come
                                    back later?
                                  </span>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="billings_subscriptions d-flex align-items-center justify-content-between invoice_table-top table-top-bill">
                                  <div className="subscriptions-type invoice-subscriptions-type">
                                    <p>Date</p>
                                  </div>
                                  <div className="product">
                                    <p>Invoice</p>
                                  </div>
                                  <div className="next-payments">
                                    <p>Applies to</p>
                                  </div>
                                  
                                  <div className="payment-method invoice-payment-method">
                                    <p>Amount</p>
                                  </div>

                                  <div className="payment-status">
                                    <p>Status</p>
                                  </div>
                                  {this.state.customer_billing_type === 2 && 
                                    (this.state.customer_billing_type != 3 &&
                                      (
                                      localStorage.getItem("agency_id") == "" ||
                                      localStorage.getItem("agency_id") == undefined ||
                                      localStorage.getItem("agency_id") == "undefined" ||
                                      localStorage.getItem("agency_id") == null ||
                                      localStorage.getItem("agency_id") == "null"
                                      )
                                    )
                                  ?
                                    <div className="action">
                                      <p></p>
                                    </div>
                                  :<></>
                                  }
                                </div>

                                {this.state.billinghistory.map((res, i) => (
                                  <div
                                    className="billings_subscriptions d-flex align-items-center justify-content-between invoice-common-height common-height-invoice invoice_table"
                                    key={i}
                                  >
                                    <div className="subscriptions-type invoice-subscriptions-type">
                                      {/* this.handleTime(res.created_at) */}
                                      <p className="invoice-datetime">
                                        {moment(res.created_at).format(
                                         "D MMMM YYYY, HH:mm"
                                        )}{" "}
                                      </p>
                                    </div>
                                    <div className="product 1 billing_invoices">
                                      {res.is_link_expired === 0 || this.state.customer_billing_type == 3 ? (
                                        <>
                                          <a
                                            onClick={(e) =>
                                              this.handlehrefredirection(
                                                e,
                                                res.link
                                              )
                                            }
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            // style={{ color: "#3064F9" }}
                                          >
                                            #{res.invoice_number}{" "}
                                            <img
                                              src={
                                                require("../../assets/images/dashboard/Goto.png")
                                                  .default
                                              }
                                              className="gotoappicon linkspec gotospaceinv"
                                              alt="Goto"
                                            />
                                          </a>{" "}
                                        </>
                                      ) : (
                                        <Link
                                          to={"#"}
                                          onClick={(e) =>
                                            this.handleredirection(
                                              e,
                                              res.app_id,
                                              res.id
                                            )
                                          }
                                          style={{ color: "#3064F9" }}
                                        >
                                          #{res.invoice_number}{" "}
                                          <img
                                            src={
                                              require("../../assets/images/dashboard/Goto.png")
                                                .default
                                            }
                                            className="gotoappicon linkspec gotospaceinv"
                                            alt="Goto"
                                          />
                                        </Link>
                                      )}
                                    </div>
                                    <div className="next-payments billings-bill-app">
                                      <p className="invoice-id-name">
                                          {
                                               parseInt(localStorage.getItem("selectedbilling")) == 2
                                              ? res.workspace_name
                                              :  parseInt(localStorage.getItem("selectedbilling")) == 3
                                              ? res.agency_name
                                              :  parseInt(localStorage.getItem("selectedbilling")) == 4
                                              ? "Support"
                                              : res.app_name}
                                      </p>
                                      {localStorage.getItem("workspaceId") ===
                                        "" ||
                                      localStorage.getItem("workspaceId") ===
                                        "undefined" ||
                                      localStorage.getItem("workspaceId") ===
                                        undefined ||
                                      localStorage.getItem("workspaceId") ===
                                        null ? (
                                        <p className="billings-bill-url">
                                          {res.store_url &&
                                            res.store_url.replace(
                                              "https://",
                                              ""
                                            )}
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                    </div>
                                    {/*<div className="payment-method">
												              <p>Credit Card ******8763</p>
											               </div>*/}
                                    <div className="payment-method invoice-payment-method">
                                      <p>
                                        {res.currency}{res.total_amount}
                                      </p>
                                    </div>

                                    <div className="bill-download bill-download-invoice payment-status">
                                      <p
                                        className={statusColor(res.status)}
                                        style={invoice_status}
                                      >
                                        {res.status==="open"?"Draft": (res.status==="unpaid"||res.status==="uncollectible")?"Unpaid":res.status}
                                      </p>
                                    </div>

                                    {this.state.customer_billing_type === 2 && 
                                    (this.state.customer_billing_type != 3 &&
                                      (
                                      localStorage.getItem("agency_id") == "" ||
                                      localStorage.getItem("agency_id") == undefined ||
                                      localStorage.getItem("agency_id") == "undefined" ||
                                      localStorage.getItem("agency_id") == null ||
                                      localStorage.getItem("agency_id") == "null"
                                      )
                                    ) ?
                                    
                                      <div className="action">
                                        <p className="invoicess-para">
                                          {res.status === "open" ? (
                                            <a
                                              href={res.payment_link + "#"}
                                              target="_blank"
                                              rel="noreferrer"
                                            >
                                              {" "}
                                              Pay Now
                                            </a>
                                          ) : (
                                            ""
                                          )}
                                        </p>
                                      </div>
                                    :<></>
                                    }
                                  </div>
                                ))}
                              </>
                            )}
                          </section>
                        )}
                        {/* Mobile View */}
                        {window.matchMedia("(max-width: 1200px)").matches && (
                          <section className="d-block   mt-3">
                            <div className="subscription-mob-page-head">
                              Invoices
                            </div>
                            <div className="billing-mob-drop-section">

                                <div className="billing-mob-select-option billing-mob-drop-one"
                                  onClick={(e) =>
                                    this.handleMobileBillingDropdown(e)
                                  }
                                >
                                  <ReactSelect
                                    styles={customStyles}
                                    className="billing-react-select-dropdown"
                                    isSearchable={false}
                                    isDisabled={true}
                                    value={this.state.totalmergeArray.filter(function (option) {
                                      return (option.value ===parseInt(localStorage.getItem("selectedbilling")));
                                    })}
                                    
                                    formatOptionLabel={(res) => 
                                      (
                                        <div className="d-flex test">
                                          <div className="billing-options">
                                            <span className="billing-options-workspace-name">
                                            {res.label}
                                            </span>
                                          <>
                                            {res.value == 1 ?
                                            <>
                                            <p className="billing-options-workspace-type">
                                              {(parseInt(localStorage.getItem('personal_apps_count')) + parseInt(localStorage.getItem('invited_apps_count'))) + " Apps"}
                                            </p>
                                            </>
                                            : res.value == 2 ?
                                              <>
                                              <p className="billing-options-workspace-type">
                                              {(parseInt(localStorage.getItem('workspace_count')) + parseInt(localStorage.getItem('invited_workspace_count'))) + " Unlimited apps"}
                                              </p>
                                              </>
                                            : res.value == 3 ?
                                              <>
                                              <p className="billing-options-workspace-type">
                                              {(parseInt(localStorage.getItem('personal_agency_count')) + parseInt(localStorage.getItem('invited_agency_count'))) + " White label"}
                                              </p>
                                              </>
                                            : <></>
                                            }
                                          </>
                                          </div>
                                        </div>
                                      )}
                                  />
                                
                                </div>

                                <div   style={{                 
                                  cursor: localStorage.getItem("selectedbilling") == 4 ? 'not-allowed' : 'pointer',
                                  }} className="billing-mob-select-option billing-mob-drop-two"   onClick={(e) => localStorage.getItem("selectedbilling") == 4 ? null : this. handleMobileBillingDropdowntwo(e) }  >
                                  <div>
                                    {/* <img src={require("../../assets/images/dashboard/accountprofile.png") .default} className="img-fluid"/> */}
                                    <p style={{                 
                                     color: localStorage.getItem("selectedbilling") == 4 ? "#BCC1CE" : '#000',
                                       }}>{this.state.searchname}</p>
                                  </div>
                                {this.state.searchname != 'All' ?
                                  <svg xmlns="http://www.w3.org/2000/svg" width="11.06" height="11.06" viewBox="0 0 11.06 11.06" onClick={(e) => this.newhandleBillingChange('')}>
                                    <path id="Union_2970" data-name="Union 2970" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                  </svg>
                                : <svg xmlns="http://www.w3.org/2000/svg" width="11.063" height="6.591" viewBox="0 0 11.063 6.591">
                                <path id="Path_108406" data-name="Path 108406" d="M-17299.426-4409.885l5,5,5-5" transform="translate(17299.957 4410.416)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                                </svg>

                                }

                                </div>

                                </div>

                            <div
                              className={
                                this.state.mobileBillingDropdown
                                  ? "modal-mob-select-div"
                                  : ""
                              }
                            >
                              {this.state.mobileBillingDropdown && (
                                <div className="billing-mob-select-div">
                                   <div className="billing-mob-select-divtwo">
                                  {this.state.mergeArray &&
                                    this.state.mergeArray.map((res, indx) => {
                                      return (
                                        <div 
                                       
                                        className={`
                                        ${
                                          localStorage.getItem(
                                            "workspaceId"
                                          ) !== "" &&
                                          localStorage.getItem(
                                            "workspaceId"
                                          ) !== undefined &&
                                          localStorage.getItem(
                                            "workspaceId"
                                          ) !== null &&
                                          localStorage.getItem(
                                            "workspaceId"
                                          ) !== "undefined" &&
                                          localStorage.getItem(
                                            "workspaceId"
                                          ) !== "null"
                                            ? res.id ===
                                                parseInt(
                                                  localStorage.getItem(
                                                    "workspaceId"
                                                  )
                                                ) && res.type !== "user"
                                              ? "disable-class-mobile-dropdown"
                                              : // (res.id === parseInt(localStorage.getItem("user_id")) && res.type ==="user" )  ?
                                                ""
                                            : res.id ===
                                                parseInt(
                                                  localStorage.getItem(
                                                    "user_id"
                                                  )
                                                ) && res.type === "user"
                                            ? "disable-class-mobile-dropdown"
                                            : ""
                                        } d-flex align-items-center billing-mob-select-div-user`}


                                          onClick={(e) => localStorage.getItem("workspaceId") !== "" &&
                                                          localStorage.getItem("workspaceId") !== undefined &&
                                                          localStorage.getItem("workspaceId") !== null &&
                                                          localStorage.getItem("workspaceId") !== "undefined" &&
                                                          localStorage.getItem("workspaceId") !== "null"
                                                        ? res.id === parseInt(localStorage.getItem("workspaceId")) && res.type !== "user"
                                                        ? null
                                                        : this.handleBillingChange(res)
                                                        : res.id === parseInt(localStorage.getItem("user_id")) && res.type === "user"
                                                        ? null
                                                        : this.handleBillingChange(res)
                                                  }
                                          >
                                          <div>
                                            {res.profile_image === "" ||
                                            res.profile_image === null ||
                                            res.profile_image === "null" ||
                                            res.profile_image === undefined ||
                                            res.profile_image === "undefined" ||
                                            !res.profile_image ? (
                                              <img
                                                style={{
                                                  width: "48px",
                                                  borderRadius: "50%",
                                                  height: "48px",
                                                  backgroundSize: "cover",
                                                 
                                                  marginRight: "10px",
                                                }}
                                                src={
                                                  res.type === "user"
                                                    ? require("../../assets/images/dashboard/my-account.png")
                                                        .default
                                                    : require("../../assets/images/dashboard/workspace/workspace-default-img.png")
                                                        .default
                                                }
                                                className="img-fluid"
                                                alt="profile logo"
                                              />
                                            ) : (
                                              <>
                                                {res.profile_image.indexOf(
                                                  "https"
                                                ) === -1 ? (
                                                  <img
                                                    className=""
                                                    alt=""
                                                    style={{
                                                      width: "48px",
                                                      borderRadius: "50%",
                                                      height: "48px",
                                                      backgroundSize: "cover",
                                                      
                                                      marginRight: "10px",
                                                    }}
                                                    src={
                                                      process.env.REACT_APP_Image_Path +
                                                      (res.type === undefined ||
                                                      res.type === null ||
                                                      res.type === "" ?
                                                        res.user_id
                                                        : res.id
                                                        ) +
                                                      (res.type === undefined ||
                                                      res.type === null ||
                                                      res.type === ""
                                                        ? process.env.REACT_APP_Workspace_Profile
                                                        : process.env.REACT_APP_Profile) +
                                                      (res.type === undefined ||
                                                      res.type === null ||
                                                      res.type === ""
                                                        ? res.id + "/"
                                                        : "") +
                                                      // res.id +
                                                      // "/" +
                                                      res.profile_image
                                                    }
                                                    onError={({ currentTarget }) => {
                                                      currentTarget.onerror = null;
                                                      currentTarget.src =
                                                        res.type==="user"
                                                        ? userdefaultImgOnError :wpdefaultImgOnError
                                                    }}
                                                  />
                                                ) : (
                                                  <div
                                                    className="header-profil-img"
                                                    style={{
                                                      background:
                                                        "url(" +
                                                        res.profile_image +
                                                        ")",
                                                      width: "48px",
                                                      borderRadius: "50%",
                                                      height: "48px",
                                                      backgroundSize: "cover",
                                                    
                                                      marginRight: "10px",
                                                    }}
                                                  ></div>
                                                )}
                                              </>
                                            )}
                                          </div>

                                          <div className="d-grid justify-content-center align-items-center">
                                            <p className="billing-select-name">
                                            {res.agency_name !== undefined ? res.agency_name : res.workspace_name}
                                            </p>
                                            <p className="billing-select-type">
                                            {res.agency_name !== undefined ? "Agency" : res.type === "user" ? "Pay-per-app" : "Unlimited apps"}
                                            </p>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                                </div>
                              )}
                            </div>

                            {/* <div className="modal-mob-select-div">
                              <div className="billing-mob-select-div" >
                                   
                              <div className="d-flex align-items-center billing-mob-select-div-user">
                                    <img
                                      className=""
                                      alt=""
                                      style={{
                                        width: "48px",
                                        borderRadius: "50%",
                                        height: "48px",
                                        backgroundSize: "cover",
                                        backgroundColor: "gray",
                                        marginRight: "10px"
                                      }}

                                    />
                                    <div className="d-grid justify-content-center align-items-center">
                                    <p className="billing-select-name">John Smith </p>
                                    <p className="billing-select-type">Personal</p>
                                    </div>
                                    
                                  </div>




                              </div>
                              </div> */}
                               <div
                                  className={
                                    this.state.mobileBillingDropdown
                                      ? "modal-mob-select-div"
                                      : ""
                                  }
                                >
                                  {this.state.mobileBillingDropdown && (
                                    <div className="billing-mob-select-div billing-mob-one">
                                       <div className="billing-mob-select-divtwo">
                                      {this.state.totalmergeArray && this.state.totalmergeArray.map(
                                          (res, indx) => {
                                            return (
                                              <div className="d-flex align-items-center billing-mob-select-div-user"  onClick={()=>this.handleBillingChangeTotal(res,'')}>
                                                
                                                <div className="d-grid justify-content-center align-items-center">
                                                  <p className="billing-select-name">
                                                  {res.label}
                                                  </p>
                                                  <>
                                                    {res.value == 1 ?
                                                    <>
                                                    <p className="billing-select-type">
                                                      {(parseInt(localStorage.getItem('personal_apps_count')) + parseInt(localStorage.getItem('invited_apps_count'))) + " Apps"}
                                                    </p>
                                                    </>
                                                    : res.value == 2 ?
                                                      <>
                                                      <p className="billing-select-type">
                                                      {(parseInt(localStorage.getItem('workspace_count')) + parseInt(localStorage.getItem('invited_workspace_count'))) + " Unlimited apps"}
                                                      </p>
                                                      </>
                                                    : res.value == 3 ?
                                                      <>
                                                      <p className="billing-select-type">
                                                      {(parseInt(localStorage.getItem('personal_agency_count')) + parseInt(localStorage.getItem('invited_agency_count'))) + " White label"}
                                                      </p>
                                                      </>
                                                    : <></>
                                                    }
                                                  </>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                        </div>
                                    </div>
                                  )}
                                </div>

                              <div>
                                  <div className={this.state.mobileBillingDropdownTwo ? "modal-mob-select-div" : ""}>
                                  {this.state.mobileBillingDropdownTwo && (
                                    <div className="billing-mob-select-div">
                                        <div className="billing-mob-select-divtwo billing-new-dropbdown-mob">

                                          <div className="billing-dropdown-two-search">
                                            <input placeholder="Search..." type="text" value={this.state.searchAppVal} onChange={(e) => {this.setState({searchAppVal: e.target.value});}}/>
                                            {this.state.searchAppVal.trim() && (
                                              <p onClick={(e) => {this.setState({searchAppVal: ""})}}>
                                                Clear
                                              </p>
                                            )}
                                          </div>
                                          {this.state.typedata && this.state.typedata.length === 0 ?
                                          (
                                            <div className="text-center billing_nodataimg" style={{margin: "70px 0px"}}>
                                            <img src={ require("../../assets/images/dashboard/NoInvoice.png") .default }
                                              className="img-fluid"
                                              alt="nosubscription"
                                            />

                                            <span className=" billing-mobblank-screen span1">
                                              There is nothing here at the moment.
                                              Come back later?
                                            </span>
                                          </div>
                                          )
                                        :
                                          <div className="billing-dropdown-two-list">
                                          {this.state.typedata && this.state.typedata.map((res) => 
                                          {
                                            if(this.state.searchAppVal == '' || 
                                                (parseInt(localStorage.getItem("selectedbilling")) == 1 && res.app_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase())) ||
                                                (parseInt(localStorage.getItem("selectedbilling")) == 2 && res.workspace_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase())) ||
                                                (parseInt(localStorage.getItem("selectedbilling")) == 3 && res.agency_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase())) 
                                              )
                                            {
                                              return(
                                                <>
                                                {this.state.typedata.length > 0 && parseInt(localStorage.getItem("selectedbilling")) == 1 ? 
                                                  <>
                                                    {this.state.searchAppVal == '' || res.app_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase()) ?
                                                      <>
                                                         <div className="billing-dropdown-two-entry" onClick={(e) => this.newhandleBillingChange(res)}>
                                                              {res.app_icon === "" ? 
                                                                (
                                                                  <img
                                                                    src={require("../../assets/images/dashboard/my-account.png").default}
                                                                    className="img-fluid"
                                                                    alt="profile logo"
                                                                  />
                                                                ) 
                                                              : 
                                                                (
                                                                    <>
                                                                        <img
                                                                          className="img-fluid"
                                                                          alt=""
                                                                          src={
                                                                            process.env.REACT_APP_Image_Path +
                                                                            res.user_id +
                                                                            "/" +
                                                                            res.app_id +
                                                                            process.env.REACT_APP_AppIcon_Display +
                                                                            res.app_icon
                                                                          }
                                                                          onError={({ currentTarget }) => {
                                                                            currentTarget.onerror = null;
                                                                            currentTarget.src =
                                                                              res.type==="user"
                                                                              ? userdefaultImgOnError :wpdefaultImgOnError
                                                                          }}
                                                                        />
                                                                    </> 
                                                                )
                                                              }

                                                            <div>
                                                              <h5>{res.app_name}</h5>
                                                              <h6>{"ID: " +res.app_id}</h6>
                                                            </div>
                                                        </div>
                                                      </>
                                                    : <></>
                                                    }
                                                  </>
                                                : this.state.typedata.length > 0 &&  parseInt(localStorage.getItem("selectedbilling")) == 2 ? 
                                                    <>
                                                      {this.state.searchAppVal == '' || res.workspace_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase()) ?
                                                      <>
                                                          <div className="billing-dropdown-two-entry" onClick={(e) => this.newhandleBillingChange(res)}>
                                                          <>
                                                            {res.profile_image === "" ||
                                                            res.profile_image === null ||
                                                            res.profile_image === "null" ||
                                                            res.profile_image === undefined ? 
                                                            (
                                                                <img src={require("../../assets/images/dashboard/sub-overview-img.png").default}  className="img-fluid"/>
                                                            ) 
                                                            : 
                                                            (
                                                              <img src={process.env.REACT_APP_Image_Path + res.user_id +
                                                                        process.env.REACT_APP_Workspace_Profile + res.workspace_id +
                                                                        "/" + res.profile_image
                                                                      }
                                                                    onError={({currentTarget,}) => {
                                                                    currentTarget.onerror = null; currentTarget.src =newDefaultImg;
                                                                    }}
                                                                className="img-fluid"
                                                              />
                                                            )}
                                                          </>

                                                          <div>
                                                            <h5>
                                                            {res.workspace_name}
                                                            </h5>
                                                            <h6>
                                                              {"Unlimited-apps"}
                                                            </h6>
                                                          </div>
                                                        </div>
                                                      </>
                                                      : <></>
                                                      }
                                                    </>
                                                : this.state.typedata.length > 0 && parseInt(localStorage.getItem("selectedbilling")) == 3 ? 
                                                  <>
                                                    {this.state.searchAppVal == '' || res.agency_name.toLowerCase().includes(this.state.searchAppVal.toLowerCase()) ?
                                                      <>
                                                          <div className="billing-dropdown-two-entry" onClick={(e) => this.newhandleBillingChange(res)}>
                                                          <>
                                                            {res.favicon === "" ||
                                                            res.favicon === null ||
                                                            res.favicon === "null" ||
                                                            res.favicon === undefined ? 
                                                            (
                                                                <img src={require("../../assets/images/dashboard/sub-overview-img.png").default} className="img-fluid"/>
                                                            ) 
                                                            : 
                                                            (
                                                              <img src={process.env.REACT_APP_Image_Path + "agencies/" + res.id + process.env.REACT_APP_Agency_Profile + res.favicon}
                                                                    onError={({currentTarget,}) => {
                                                                    currentTarget.onerror = null; currentTarget.src =newDefaultImg;
                                                                    }}
                                                                className="img-fluid"
                                                              />
                                                            )}
                                                          </>

                                                            <div>
                                                              <h5>
                                                              {res.agency_name}
                                                              </h5>
                                                              <h6>
                                                                {"White-label"}
                                                              </h6>
                                                            </div>
                                                        </div>
                                                      </>
                                                    : <></>
                                                    }
                                                  </>
                                                : <></>
                                                }
                                            </>
                                              )}
                                          })}


                                          </div>
                                        }
                                        </div>
                                    </div>
                                  )}
                                  </div>
                                </div>
                            
                            <div>
                              {this.state.billinghistory.length === 0 ? (
                                <>
                                  <div className="text-center billing_nodataimg w-100">
                                    <img
                                      src={
                                        require("../../assets/images/dashboard/NoInvoice.png")
                                          .default
                                      }
                                      className="img-fluid noInvoice"
                                      alt="nosubscription"
                                    />
                                  </div>
                                  <div className="billing_nodatatext">
                                    {/* <h5 className="text-center">No deals are available now</h5> */}

                                    <span className="billing-mobblank-screen span1">
                                      There is nothing here at the moment. Come
                                      back later?
                                    </span>
                                    {/* <span className="custom-size text-center not-subscribed span2">Come back later?</span>  */}
                                  </div>
                                  {/* <Link
                                    to="/apps"
                                    className="next_btn d-block text-center m-auto"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    style={{ width: "122px" }}
                                  >
                                    My Apps
                                  </Link> */}
                                </>
                              ) : (
                                <>
                                  {this.state.billinghistory.map((res, i) => (
                                    <div className=" mob-invoice  billings-mob-invoice ">
                                      <div className="d-flex  justify-content-between invoice-mob-firstdiv">
                                        <div className=" invoicess-plan-start">
                                          <div className=" invoicess-date-time cus-time ">
                                            {this.handleTime(res.created_at)}
                                          </div>
                                          <a
                                            href={res.link + "#"}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="bill-invoice  "
                                          >
                                            {res.invoice_number}
                                            <img
                                              src={
                                                require("../../assets/images/dashboard/Goto.png")
                                                  .default
                                              }
                                              className="bill-invoicegoto"
                                              alt="Goto"
                                            />
                                          </a>

                                          {/* <p className="app-name">
                                            {res.app_name}
                                          </p> */}
                                          {/* <p>Payment Method :<span> {res.status === "paid" ? "Credit Card" : "-"}</span></p> */}
                                        </div>
                                        <div className="payment-status invoice-status ">
                                          <p
                                            style={{
                                              textTransform: "capitalize",
                                            }}
                                            className={
                                              statusColor(res.status) + " mt-0"
                                            }
                                          >
                                             {res.status==="open"?"Draft": (res.status==="unpaid"||res.status==="uncollectible")?"Unpaid":res.status}
                                          </p>
                                        </div>
                                      </div>
                                      {/* <div className="d-flex justify-content-between w-100 mb-3">
                                        <div className="invoice-text ">
                                          <p>Date</p>
                                        </div>
                                        <div className="invoice-date-time cus-time ">
                                          {this.handleTime(res.created_at)}
                                        </div>
                                      </div> */}
                                      <div className="d-grid justify-content-between w-100 billings-mob-product">
                                        <div className="invoice-text invoicess-text">
                                          <p>Applies to</p>
                                        </div>
                                        <div className="invoice-date-time ">
                                          <p className="sm-hidden2 invoicess-nametexturl">
                                          {
                                               parseInt(localStorage.getItem("selectedbilling")) == 2
                                              ? res.workspace_name
                                              :  parseInt(localStorage.getItem("selectedbilling")) == 3
                                              ? res.agency_name
                                              :  parseInt(localStorage.getItem("selectedbilling")) == 4
                                              ? "Support"
                                              : res.app_name}
                                          </p>
                                        </div>
                                        <p className="billings-bill-url invoicess-nameurl">
                                          {res.store_url &&
                                            res.store_url.replace(
                                              "https://",
                                              ""
                                            )}
                                        </p>
                                      </div>
                                      <div className="d-flex justify-content-between w-100 invoice-mob-paymentmethod">
                                        <div className="d-grid">
                                          <div className="invoice-text invoicess-text">
                                            <p>Amount</p>
                                          </div>
                                          <div className="  invoicess-date-time-payment">
                                            <p>
                                              {" "}
                                              {res.currency}{res.total_amount}
                                            </p>
                                          </div>
                                        </div>
                                        {this.state.customer_billing_type === 2 && 
                                          (this.state.customer_billing_type != 3 &&
                                            (
                                            localStorage.getItem("agency_id") == "" ||
                                            localStorage.getItem("agency_id") == undefined ||
                                            localStorage.getItem("agency_id") == "undefined" ||
                                            localStorage.getItem("agency_id") == null ||
                                            localStorage.getItem("agency_id") == "null"
                                            )
                                          ) ?
                                        
                                          <div className="bill-payment-method invoicess-bill-payment">
                                            <p className="invoicess-para">
                                              {res.status === "open" ? (
                                                <a
                                                  href={res.payment_link + "#"}
                                                  target="_blank"
                                                  rel="noreferrer"
                                                >
                                                  {" "}
                                                  Pay Now
                                                </a>
                                              ) : (
                                                ""
                                              )}
                                            </p>
                                          </div>
                                        :<></>
                                        }
                                      </div>
                                    </div>
                                  ))}
                                </>
                              )}
                            </div>
                          </section>
                        )}
                        {/* Mobile View End */}
                      </div>
                      {this.state.bottomspinner && (
                        <div className={`form-group`} style={{ background: "none", margin: "auto" }}>

                        <svg xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                          width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                          <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                            <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                              <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                style={{ animationplaystate: "running", animationdelay: "0s" }}>
                              </animateTransform>
                            </circle>
                          </g>
                          <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                            <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                              <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                style={{ animationplaystate: "running", animationdelay: "0s" }}>
                              </animateTransform>
                            </circle>
                          </g>
                          <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                            <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                              <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                style={{ animationplaystate: "running", animationdelay: "0s" }}>

                              </animateTransform>
                            </circle>
                          </g>
                          <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                            <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                              <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                style={{ animationplaystate: "running", animationdelay: "0s" }}>

                              </animateTransform>
                            </circle>
                          </g>
                        </svg>
                      </div>
                        // <div className="text-center w-100 m-3">
                        //   <Spinner animation="grow" variant="primary" />
                        // </div>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* {InnerFoot()} */}
          </div>)}
          </BottomScrollListener>
        </section>
      </>
    );
  }
}
