import React from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./appPreview.css";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Spinner,
  Button,
  ModalFooter,
} from "reactstrap";
import { iframeApi, Verifyapistatus } from "../../Api/Preview/PreviewApi";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
import moment from "moment";
import { Errorstatuscon } from "./Context";
import { Error } from "../Reusable/Connectivityerror";
import { userLogout } from "../../Api/Dashboard/DashboardApi";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Input,
  FormGroup,
} from "reactstrap";

class DownApp extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        modal: false,
        // copiedLeft: false,
        // copiedRight: false,
      };
      // this.toggle = this.toggle.bind(this);
    }
  
    mobiletoggle = () => {
      this.setState({
        modal: !this.state.modal,
      });
    };
  
    render() {
      return (
        <>
          <div onClick={this.props.togglemodalapp}>
            {/* <div className="preview-red-cross mobile-cross">
              <img
                src={
                  require("../../assets/images/dashboard/preview-blue-cross.png")
                    .default
                }
                className="img-fluid"
                alt="error"
              />
            </div> */}
          </div>
          {/* <div className="d-flex d-md-none two-test-button">
          {(localStorage.getItem("agencyid") == '' || localStorage.getItem("agencyid") == undefined || 
            localStorage.getItem("agencyid") == null || localStorage.getItem("agencyid") == 'undefined' || 
            localStorage.getItem("agencyid") == 'null' || localStorage.getItem("agencyid") == 1) ?
            <div
              className="installedpluginlink mr-2 "
              onClick={this.mobiletoggle}
            >
              Test on real device
            </div>
          : <></>
          }
             
            <Link
              to="/download-app"
              className="takethere"
              style={{
                height: "45px",
                width: "100%",
                lineHeight: "42px",
              backgroundColor:localStorage.getItem('agencyid') == 1 ? "#3064F9" : localStorage.getItem('button_background_color'),
              color:localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_text_color'),
              "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),
              "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor')}}
            >
              Download app
            </Link>
          </div> */}
          <Modal
            isOpen={this.state.modal}
            fade={false}
            toggle={this.mobiletoggle}
            className={
              "modal-dialog-centered modal-dialog-scrollable testmodal-dialog mobile-layout"
            }
          >
            <ModalHeader
              toggle={this.mobiletoggle}
              className={"testmodal-header"}
            >
            
            </ModalHeader>
            <ModalBody>
              <div className="test-header">
                <h5>Download the app</h5>
                <p>
                  Test your app on a real device before publishing on the app
                  stores.
                </p>
              </div>
              <div className="test-popup-links d-flex flex-column align-items-center justify-content-center">
                <a
                  target="_blank"
                  href={localStorage.getItem("website_technology") == 3 
                      ? "https://testflight.apple.com/join/DKgriDVM"
                      : localStorage.getItem("website_technology") == 2 
                      ? "https://testflight.apple.com/join/1cvKgAXc"
                      : localStorage.getItem("website_technology") == 4
                      ? "https://testflight.apple.com/join/OLPs2xOB"
                      : localStorage.getItem("website_technology") == 5
                      ? "https://testflight.apple.com/join/cq3qO8y7"
                      : ""
                      }
                >
                  <div className="apple-test-link">
                    <img
                      src={
                        require("../../assets/images/dashboard/apple-test-logo.png")
                          .default
                      }
                      className="apple-test-img"
                      alt="ios-store-icon"
                    ></img>
                    <div className="apple-test-linktext">
                      <p>Download in</p>
                      <h5 className="apple-link-subtext">TestFlight</h5>
                    </div>
                  </div>
                </a>
                <a
                  target="_blank"
                  href=
                  { localStorage.getItem("website_technology") == 3 
                  ? "https://play.google.com/store/apps/details?id=com.appmysite.custom"
                  : localStorage.getItem("website_technology") == 2 
                  ? "https://play.google.com/store/apps/details?id=com.appmysite.web"
                  : localStorage.getItem("website_technology") == 4 
                  ? "https://play.google.com/store/apps/details?id=com.appmysite.wordpress"
                  : localStorage.getItem("website_technology") == 5 
                  ? "https://play.google.com/store/apps/details?id=com.appmysite.shop"
                  : ""
                  }
                >
                  <div className="apple-test-link gplay-test-link">
                    <img
                      src={
                        require("../../assets/images/dashboard/gplay-test-logo.png")
                          .default
                      }
                      className="apple-test-img"
                      alt="gplay-store-icon"
                    ></img>
                    <div className="apple-test-linktext gplay-test-linktext">
                      <p>Get it on</p>
                      <h5 className="gplay-link-subtext">Google Play</h5>
                    </div>
                  </div>
                </a>
              </div>
            </ModalBody>
          </Modal>
          {/* ) */}
        </>
      );
    }
  }

  export { DownApp };