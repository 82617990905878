import React from 'react'

const ProductRoadmap = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      id="Group_41128"
      data-name="Group 41128"
      transform="translate(-1549 -427)"
    >
      <rect
        id="Rectangle_25574"
        data-name="Rectangle 25574"
        width="24"
        height="24"
        transform="translate(1549 427)"
        fill="none"
      />
      <g id="Group_35095" data-name="Group 35095">
        <path
          id="Path_98205"
          data-name="Path 98205"
          d="M19.28,3.72A11.056,11.056,0,1,1,16.49,1.7"
          transform="translate(1549 427)"
          fill="none"
          stroke="#1b2952"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_98206"
          data-name="Path 98206"
          d="M16.45,6.55a6.958,6.958,0,1,1-1.77-1.29"
          transform="translate(1549 427)"
          fill="none"
          stroke="#1b2952"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_98207"
          data-name="Path 98207"
          d="M11.5,9.5a2,2,0,1,1-2,2,2,2,0,0,1,2-2m0-1a3,3,0,1,0,3,3,3,3,0,0,0-3-3"
          transform="translate(1549 427)"
          fill="#1b2952"
        />
        <line
          id="Line_359"
          data-name="Line 359"
          x1="8"
          y2="8"
          transform="translate(1560.5 430.5)"
          fill="none"
          stroke="#1b2952"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_98208"
          data-name="Path 98208"
          d="M22,4H19V1"
          transform="translate(1549.5 426.5)"
          fill="none"
          stroke="#1b2952"
          stroke-miterlimit="10"
          stroke-width="1"
        />
      </g>
    </g>
  </svg>
  )
}

export default ProductRoadmap