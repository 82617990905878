import React from "react";
import { Bar } from "react-chartjs-2";
class DashboardHistogram extends React.Component {
  render() {
    return (
      <>
        <Bar
          options={{ maintainAspectRatio: false, legend: { display: false } }}
          data={this.props.value}
        />
      </>
    );
  }
}

export { DashboardHistogram as default };
