import React, { useEffect, useState } from "react";
import disbaledMenuImg from "../../../assets/images/dashboard/new-menubuilder-disable.png";
import MenuPreviewList from "../../../Components/commonComponents/MenuBuilder/MenuPreviewList";
import MenuHeaderCrossIcon from "../../../Components/commonIcons/MenuHeaderCrossIcon";
import { countBlackIcons, hexToRgb } from "../../../utils/commonUtils";
import { Color, Solver } from "../../../Dashboard/BottomBar/Iconcolor";

const MenuPreview = ({ menuState }) => {
  const [primaryMenuiItemsIconFilter, setPrimaryMenuiItemsIconFilter] =
    useState("");
  const [secondaryMenuiItemsIconFilter, setSecondaryMenuiItemsIconFilter] =
    useState("");

  const applyingFilter = (iconColor) => {
    let rgb = hexToRgb(iconColor);

    // Explicitly checking for null or undefined
    if (rgb !== undefined && iconColor) {
      const colorvalue = iconColor.match(/[\d\.]+/g).map(Number);

      rgb = [];
      rgb[0] = colorvalue[0];
      rgb[1] = colorvalue[1];
      rgb[2] = colorvalue[2];

      const alpha = colorvalue[3] !== undefined ? colorvalue[3] : 1;

      let filter = "";

      if (rgb && rgb.length === 3 && iconColor) {
        const color = new Color(rgb[0], rgb[1], rgb[2]);
        const solver = new Solver(color);
        const result = solver.solve();
        if (alpha < 1) {
          const opacityPercentage = Math.round(alpha * 100);
          filter = `${result.filter} opacity(${opacityPercentage}%)`;
        } else {
          filter = result.filter;
        }

        return filter;
      }
    }

    return "invert(32%) sepia(25%) saturate(4935%) hue-rotate(210deg) brightness(105%) contrast(96%)";
  };

  const HexCode = (color) => {
    if (color) {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
      const hex = `#${(
        (1 << 24) +
        (parseInt(rgba[0]) << 16) +
        (parseInt(rgba[1]) << 8) +
        parseInt(rgba[2])
      )
        .toString(16)
        .slice(1)}`;

      return hex;
    } else {
      return "#fff";
    }
  };

  const backgroundHexcode = (color) => {
    if (color) {
      const rgba = color.replace(/^rgba?\(|\s+|\)$/g, "").split(",");
      const opacity = "0.07";
      const hex =
        `rgba(` +
        parseInt(rgba[0]) +
        `,` +
        parseInt(rgba[1]) +
        `,` +
        parseInt(rgba[2]) +
        `,` +
        opacity +
        `)`;

      return hex;
    } else {
      return color;
    }
  };

  useEffect(() => {
    setPrimaryMenuiItemsIconFilter(
      applyingFilter(menuState.settings.primaryMenuItemsIconColor)
    );
  }, [menuState.settings.primaryMenuItemsIconColor]);

  useEffect(() => {
    setSecondaryMenuiItemsIconFilter(
      applyingFilter(menuState.settings.secondaryMenuItemsIconColor)
    );
  }, [menuState.settings.secondaryMenuItemsIconColor]);

  return (
    <div className="menubuilder-right">
      <div
        className="menubar-preview-screen"
        style={{
          backgroundImage: menuState.isMainMenuEnabled
            ? ""
            : `url(${disbaledMenuImg})`,
        }}
      >
        {menuState.isMainMenuEnabled ? (
          <div
            className={"menubuild-preview"}
            style={{ background: menuState.settings.backgroundColor }}
          >
            <div className="menubuilder-previewhead">
              <div className="menu-preview-head-logo">
                {menuState.settings.useLogoOrText === "text" ? (
                  <p style={{ color: menuState.settings.headerTextColor }}>
                    {menuState.settings.headerTitle}
                  </p>
                ) : menuState.settings.useLogoOrText === "logo" ? (
                  <>
                    {!menuState.settings.headerLogo ? (
                      <></>
                    ) : menuState.settings.headerLogo.indexOf(
                        "cdn-icons-png.flaticon.com"
                      ) > -1 ? (
                      <img
                        className="menubuilder-head-icons"
                        alt=""
                        src={menuState.settings.headerLogo}
                       
                      />
                    ) : (
                      <img
                        className="menubuilder-head-icons"
                        alt=""
                        src={
                          process.env.REACT_APP_Image_Path +
                          parseInt(localStorage.getItem("user_id")) +
                          "/" +
                          parseInt(localStorage.getItem("Appid")) +
                          process.env.REACT_APP_NEW_ICON_PATH +
                          menuState.settings.headerLogo
                        }
                       
                      />
                    )}
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div
                className="menu-builderhead-cross"
                style={{
                  backgroundColor: backgroundHexcode(
                    menuState.settings.headerTextColor
                  ),
                }}
              >
                <MenuHeaderCrossIcon
                  strokeColor={HexCode(menuState.settings.headerTextColor)}
                />
              </div>
            </div>
            <div className="promary-menu-preview-list ">
              <MenuPreviewList
                menuType={"Primary"}
                isPrimaryMenuEnable={menuState.isPrimaryMenuEnabled}
                isSecondaryMenuEnable={menuState.isSecondaryMenuEnabled}
                syncWebsiteToggleVal={menuState.isPrimaryMenuSyncWithWP}
                showAsGrid={menuState.settings.showPrimaryMenuItemsAsGrid}
                menuListingData={menuState.primaryMenuListingData}
                menuSeparatorColor={menuState.settings.separatorColor}
                iconBorderColor={
                  menuState.settings.primaryMenuItemsIconBorderColor
                }
                showIcon={menuState.settings.showPrimaryMenuItemsIcon}
                iconColor={menuState.settings.primaryMenuItemsIconColor}
                showText={menuState.settings.showPrimaryMenuItemsText}
                itemTextColor={menuState.settings.primaryMenuItemsTextColor}
                iconBGcolor={menuState.settings.primaryMenuItemsIconBGcolor}
                iconShape={menuState.settings.primaryMenuItemsShape}
                gridColumn={parseInt(
                  menuState.settings.primaryMenuItemsGridCol
                )}
                iconFilter={primaryMenuiItemsIconFilter}
              />

              <MenuPreviewList
                menuType={"Secondary"}
                isPrimaryMenuEnable={menuState.isPrimaryMenuEnabled}
                isSecondaryMenuEnable={menuState.isSecondaryMenuEnabled}
                syncWebsiteToggleVal={menuState.isSecondaryMenuSyncWithWP}
                showAsGrid={menuState.settings.showSecondaryMenuItemsAsGrid}
                menuListingData={menuState.secondaryMenuListingData}
                menuSeparatorColor={menuState.settings.separatorColor}
                iconBorderColor={
                  menuState.settings.secondaryMenuItemsIconBorderColor
                }
                showIcon={menuState.settings.showSecondaryMenuItemsIcon}
                iconColor={menuState.settings.secondaryMenuItemsIconColor}
                showText={menuState.settings.showSecondaryMenuItemsText}
                itemTextColor={menuState.settings.secondaryMenuItemsTextColor}
                iconBGcolor={menuState.settings.secondaryMenuItemsIconBGcolor}
                iconShape={menuState.settings.secondaryMenuItemsShape}
                gridColumn={parseInt(
                  menuState.settings.secondaryMenuItemsGridCol
                )}
                iconFilter={secondaryMenuiItemsIconFilter}
              />
            </div>

            <div
              className={
                menuState.settings.showPrimaryMenuItemsAsGrid
                  ? "menuversion-page-center menuversion-page"
                  : "menuversion-page"
              }
              style={{
                color: HexCode(menuState.settings.primaryMenuItemsTextColor),
                opacity: "0.4",
              }}
            >
              Version 3.2.1
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default MenuPreview;
