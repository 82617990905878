import React from 'react';
import emptydetails from "../../assets/images/dashboard/Billing/newemptydetails.png";
import './Emptypage.css';


function Emptypayments(props) {
    
    return (
        <div style={{flexFlow: "column",width: "100%"}} className="d-flex align-items-center justify-content-center ">
            <img className="mainimage noDeals" src={emptydetails} />
            <p className="maintext">No payment method added.</p>
          {
            (props.customer_billing_type === 2 ||
                (props.customer_billing_type === 1 &&
                  localStorage.getItem("workspaceId") !== "" &&
                  localStorage.getItem("workspaceId") !==
                    undefined &&
                  localStorage.getItem("workspaceId") !==
                    null)) &&
            <p className="smalltext" onClick={(e)=> props.value(e)}>Add payment method
            <img
                                   src={
                                     require("../../assets/images/dashboard/Goto.png")
                                       .default
                                   }
                                   className=" No-payment"
                                   alt="Goto"
                                 />
            </p>
}
        </div>
    )
}

export default Emptypayments
