import emailValidator from "email-validator";
import moment from "moment";
import { useEffect, useReducer, useRef } from "react";
import { Link } from "react-router-dom";
import ReactSelect from "react-select";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Verifyemailaccount } from "../../../Api/Authenticate/Api";
import {
  DeleteUser,
  ProfileErrorlogs,
  RemoveSocialProfile
} from "../../../Api/Dashboard/DashboardApi";
import "../../../assets/css/Dashboard.css";
import "../../../assets/css/profileleftpanel.css";
import userdefaultImgOnError from "../../../assets/images/dashboard/accountprofile.png";
import wpdefaultImgOnError from "../../../assets/images/dashboard/workspace/workspace-default-img.png";
import RequiredError from "../../../Components/commonComponents/Profile/RequiredError";
import ModuleTitleTooltip from "../../../Components/commonIcons/ModuleTitleTooltip.js";
import Tooltip from "../../../Components/commonIcons/Tooltip";
import {
  companySizeOptions,
  industryOptions,
  roleOptions
} from "../../../Constants/profile/profileConsts";
import Header from "../../../Dashboard/Header";
import NewImageGalleryPopup from "../../../Dashboard/NewImageGalleryPopup";
import ProfileLeftPanel from "../../../Dashboard/ProfileLeftPanel";
import { errortoast, sucesstoast } from "../../../Dashboard/Toaster";
import {
  useGetAgencyLoginDataQuery,
  useGetCountryDataQuery,
  useGetIPDataQuery,
  useGetProfileDataQuery,
  useSaveProfileDataMutation
} from "../../../services/ProfileSlice/profileSlice";
import DeleteAccount from "./DeleteAccount";
import SocialConnect from "./SocialConnect";
import OptionsSelectionModal from "../../../Components/commonComponents/Profile/OptionsSelectionModal";
import validationicon from "../../../assets/images/dashboard/validationicon.svg";
import AmsSpinner from "../../../Components/commonIcons/AmsSpinner.js";
const UserProfile = (props) => {
  const { data: profileReduxData, isLoading } = useGetProfileDataQuery();
  const { data: countryList } = useGetCountryDataQuery();
  const { data: IPData } = useGetIPDataQuery();
  const { data: AgencyLoginData } = useGetAgencyLoginDataQuery({
    domain_name: window.location.host
  });
  const wrapperRef = useRef(null);

  const [editProfile, { isLoading: editProfileLoading }] =
    useSaveProfileDataMutation();

  const [profileData, setProfileData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      modal: false,
      phonecodemodal: false,
      name: "",
      email: "",
      oldemail: "",
      phone_code: "91",
      country_name_prefix: "IN",
      phone: "",
      gender: "",
      spinner: true,
      profile_image: "",
      date: "",
      profile: localStorage.getItem("user_profile"),
      deleteLoader: false,
      verifyEmailLoader: false,
      check: "",
      role_id: 2,
      apps_count: undefined,
      delete_app: false,
      status: 1,
      customer_type: null,
      user_type: "",
      childState: "",
      linked_google: "",
      linked_facebook: "",
      user_roles: "",
      user_industry: "",
      company_size: "",
      google_spinner: false,
      fb_spinner: false,
      txtgoogle: "Google account",
      txtfacebook: "Facebook account",
      errcookies: "",
      right_panel_expand: true,
      openCountryList: false,
      dropdownSearchVal: "",
      updated_at: "",
      delete_email_err: false,
      open_profile_menu: false,
      optionsForRole: roleOptions,
      optionsForCompanySize: companySizeOptions,
      optionsForIndustory: industryOptions,
      name_err: false,
      email_err: false,
      role_err: false,
      company_err: false,
      industory_err: false,
      is_fb_login_enabled: 0,
      google_app_id: "",
      facebookid: "",
      is_google_login_enabled: 0,
      captcha_key: "",
      captcha_secret: "",
      is_google_captcha_enabled: 0,
      is_fb_login_enabled: 0,
      openMobRoleDropdown: false,
      openMobCompanySizeDropdown: false,
      openMobOperateDropdown: false
    }
  );

  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderRadius: 3,
      borderColor: state.isFocused ? "#7782a1" : "#C8CEDB",
      backgroundColor: state.isDisabled ? "#ffffff" : "",
      height: 50,
      minHeight: 50,
      fontSize: 14,
      color: "#7782A1",
      paddingLeft: 10,
      boxShadow: state.isFocused ? "none" : "none",
      "&:hover": {
        boxShadow: state.isFocused ? "none" : "none"
      }
    }),
    valueContainer: (base) => ({
      ...base,
      height: 50,
      minHeight: 50,
      fontSize: 14,
      color: "#7782A1"
    }),
    menu: (base) => ({
      ...base,
      fontSize: "14px"
    }),
    input: (base) => ({
      ...base,
      margin: 0,
      padding: 0
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none"
    }),
    dropdownIndicator: (base) => ({
      ...base
    })
  };

  const handleClickOutside = (e) => {
    if (
      wrapperRef &&
      wrapperRef.current &&
      !wrapperRef.current.contains(e.target)
    ) {
      setProfileData({
        openCountryList: false
      });
    }
  };

  const profiledata = (e) => {
    e.preventDefault();
    let isErrforCheck = false;
    let email_err_temp = profileData.email_err;
    let today = profileData.date;

    if (profileData.date === "" || profileData.date === null) {
      today = null;
    } else {
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();

      today = yyyy + "-" + mm + "-" + dd;
    }
    let phonenumber = profileData.phone;
    if (!profileData.phone) {
      phonenumber = null;
    }
    const params = {
      name: profileData.name.trim(),
      email: profileData.email.trim(),
      gender: profileData.gender,
      phonecode: profileData.phone_code,
      phone: phonenumber,
      dob: "",
      profile: profileData.profile,
      check: profileData.check,
      owner: profileData.user_type,
      user_job_role: profileData.user_roles,
      user_industry: profileData.user_industry,
      user_company_size: profileData.company_size,
      phone_code_country_iso: profileData.country_name_prefix,
      agency_id: localStorage.getItem("agency_id")
        ? parseInt(localStorage.getItem("agency_id"))
        : 1
    };

    if (!params.name) {
      setProfileData({
        name_err: true
      });
      isErrforCheck = true;
    }
    if (!params.email) {
      email_err_temp = "Required";
      setProfileData({
        email_err: "Required"
      });
      isErrforCheck = true;
    }
    if (
      !emailValidator.validate(params.email) &&
      email_err_temp !== "Required"
    ) {
      email_err_temp = true;
      setProfileData({
        email_err: true
      });
      isErrforCheck = true;
    }

    if (!params.user_job_role) {
      setProfileData({
        role_err: true
      });
      isErrforCheck = true;
    }
    if (!params.user_company_size) {
      setProfileData({
        company_err: true
      });
      isErrforCheck = true;
    }
    if (!params.user_industry) {
      setProfileData({
        industory_err: true
      });
      isErrforCheck = true;
    }
    if (isErrforCheck === false) {
      editProfile(params)
        .unwrap()
        .then((res) => {
          if (res.code === 200) {
            localStorage.setItem("user_profile", res.data.profile_image);
            localStorage.setItem("user_name", res.data.name);
            setProfileData({
              updated_at: res.data.updated_at
            });

            let previous_location = localStorage.getItem("Previouslocation");
            if (res.is_change_in_email === 1) {
              sucesstoast("Profile successfully updated, Please login again.");
              localStorage.clear();
              props.history.push("/");
            } else {
              sucesstoast("Profile successfully updated.");
            }
            localStorage.setItem("Previouslocation", previous_location);
          } else if (res.code === 400) {
            const fields = {
              phone: res.data.phone?.[0],
              email: res.data.email?.[0],
              phonecode: res.data.phone?.[0],
              user_job_role: "You missed job role field.",
              user_industry: "You missed industry type field.",
              user_company_size: "You missed company size field."
            };
            Object.keys(fields).forEach((key) => {
              if (Object.keys(res.data).includes(key)) {
                errortoast(fields[key]);
              }
            });
          } else if (res.code === 403) {
            errortoast(res.data);
          }
        });
    }
  };
  const togglephonecodemodal = () => {
    setProfileData({
      phonecodemodal: !profileData.phonecodemodal
    });
  };
  const handlename = (e) => {
    var setvalue = e.target.value.replace(
      /[`@#$%^&*+~!()_|?=;:'",.<>\{\}\[\]\\\/]/gi,
      ""
    );
    setProfileData({
      name: setvalue
    });
    if (setvalue.trim()) {
      setProfileData({
        name_err: false
      });
    }
  };

  const handleEmailChange = (e) => {
    setProfileData({
      email: e.target.value,
      email_err: false
    });
  };
  const handlephone = (e) => {
    setProfileData({
      phone: e.target.value.replace(/[^0-9]/g, "")
    });
  };

  const handleprofileimage = (e, state) => {
    setProfileData({
      modal: true,
      childState: state
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (
      localStorage.getItem("agencyid") &&
      localStorage.getItem("agencyid") == 1
    ) {
      document.title = "Profile | AppMySite";
    } else {
      document.title = "Profile |" + " " + localStorage.getItem("agencyname");
    }
    document.addEventListener("mousedown", handleClickOutside);
    localStorage.setItem("workspaceId", "");
    localStorage.setItem("workspaceName", "");
    localStorage.setItem("workspaceImage", "");
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  const removeimage = () => {
    setProfileData({
      profile: "",
      profile_image: "",
      check: 2
    });
  };

  const delete_accountmodal = (e) => {
    e.preventDefault();
    setProfileData({
      userdeletemodal: !profileData.userdeletemodal,
      delete_email_err: false
    });
  };

  //Delete User
  const Deleteuser = (e) => {
    const emailconfirm = document
      .getElementById("emailconfirm")
      ?.value?.trim()
      ?.toLowerCase();
    const profileEmail = profileData.email?.trim()?.toLowerCase();
    if (emailconfirm === profileEmail) {
      setProfileData({
        deleteLoader: true
      });
      DeleteUser({}, { setState: setProfileData, props });
    } else if (emailconfirm !== profileEmail) {
      errortoast("Your email address must match your profile email address");
    } else {
      setProfileData({
        delete_email_err: true
      });
    }
    e.preventDefault();
  };
  const deleteapps = (e) => {
    e.preventDefault();
    setProfileData({
      delete_app: !profileData.delete_app
    });
  };

  const removeGoogleLogins = (e) => {
    e.preventDefault();
    setProfileData({ google_spinner: true });
    const params = {
      platform: "google"
    };
    RemoveSocialProfile(params, {
      setState: setProfileData
    });
  };

  const handleUserRoleChange = (e) => {
    setProfileData({
      user_roles: e.value,
      role_err: false,
      openMobRoleDropdown: false
    });
  };

  const handleUserIndustryChange = (e) => {
    setProfileData({
      user_industry: e.value,
      industory_err: false,
      openMobOperateDropdown: false
    });
  };

  const handleCompanySizeChange = (e) => {
    setProfileData({
      company_size: e.value,
      company_err: false,
      openMobCompanySizeDropdown: false
    });
  };

  const settingYourdata = (imgName, from) => {
    if (from !== "original") {
      if (profileData.childState) {
        profileData.childState.setProfileData({
          profile: imgName
        });
      }
      setProfileData({
        profile: imgName
      });
    }
  };

  const handleRightPanelExpand = (rightpanelvalue) => {
    setProfileData({
      right_panel_expand: rightpanelvalue
    });
  };

  const handleCountryDropdown = (e, res) => {
    setProfileData({
      phone_code: res.phone_code,
      country_name_prefix: res.country_code,
      openCountryList: window.matchMedia("(max-width: 1200px)").matches
        ? profileData.openCountryList
        : !profileData.openCountryList,
      phonecodemodal: window.matchMedia("(max-width: 1200px)").matches
        ? !profileData.phonecodemodal
        : profileData.phonecodemodal
    });
  };

  const handlesend = () => {
    const params = {
      token: localStorage.getItem("amstoken"),
      check: "check"
    };
    setProfileData({
      verifyEmailLoader: true
    });
    Verifyemailaccount(params, {
      setState: setProfileData,
      props
    });
  };

  const handleDropdownToggle = (key) => {
    setProfileData({ [key]: !profileData[key] });
  };

  const openProfileMenus = (e) => {
    setProfileData({
      open_profile_menu: !profileData.open_profile_menu
    });
  };

  useEffect(() => {
    if (profileReduxData) {
      if (profileReduxData?.code === 200) {
        const { data, linked_social_logins } = profileReduxData;
        const proimage = data.profile_image
          ? data.profile_image.indexOf("https") === -1
            ? `${process.env.REACT_APP_Image_Path}${process.env.REACT_APP_Profile}${data.profile_image}`
            : data.profile_image
          : "";

        setProfileData({
          fb_spinner: false,
          google_spinner: false,
          updated_at: data.updated_at,
          customer_type: data.customer_type,
          latest_updated_at: data.billing_updated_date || null,
          name: data.name,
          email: data.email,
          oldemail: data.email,
          profile_image: proimage,
          spinner: false,
          status: data.status,
          role_id: data.role_id ?? 2,
          first_name: data.billing_first_name,
          last_name: data.billing_last_name,
          company_name: data.company_name,
          billing_address: data.billing_address,
          billing_zip: data.billing_zip_code,
          billing_city: data.billing_city,
          billing_country: data.country_id || "",
          billing_state: data.billing_state,
          tax_number: data.tax_number,
          apps_count: profileReduxData.apps_count,
          total_app_count: profileReduxData.total_app_count,
          country_code: data.country_code === "IN" ? "IN" : "USD",
          customer_billing_type: data.customer_billing_type,
          user_type: data.owner,
          linked_google: profileReduxData.linked_social_logins.google,
          linked_facebook: profileReduxData.linked_social_logins.facebook,
          user_roles: data.user_job_role,
          user_industry: data.user_industry,
          company_size: data.user_company_size,
          gender: data.gender ?? "M",
          phone: data.phone || "",
          phone_code: data.phone_code || "91",
          country_name_prefix: data.phone_code_country_iso || "IN",
          date: data.dateofbirth
            ? new Date(...data.dateofbirth.split("-").reverse())
            : new Date(Date.now() - 864e5),
          txtgoogle:
            profileReduxData.linked_social_logins.google === 1
              ? data.email
              : "Google account",
          txtfacebook:
            profileReduxData.linked_social_logins.facebook === 1
              ? data.email
              : "Facebook account"
        });

        const phoneCode =
          data.phone_code && data.phone_code_country_iso
            ? {
                phone_code: data.phone_code,
                country_name_prefix: data.phone_code_country_iso
              }
            : {};

        const date =
          data.dateofbirth === null
            ? new Date(Date.now() - 864e5)
            : new Date(...data.dateofbirth.split("-").reverse());

        const gender = data.gender === null ? "M" : data.gender;

        const phone = data.phone === null ? "" : data.phone;

        const txtgoogle =
          linked_social_logins.google === 1 ? data.email : "Google account";

        const txtfacebook =
          linked_social_logins.facebook === 1 ? data.email : "Facebook account";

        setProfileData({
          ...phoneCode,
          date,
          gender,
          phone,
          txtgoogle,
          txtfacebook
        });
      } else {
        errortoast("Session expired ! login again");
        setTimeout(() => {
          const buildversion = localStorage.getItem("buildversion");
          const Apiversion = localStorage.getItem("Apiversion");
          const previous_location = localStorage.getItem("Previouslocation");
          localStorage.clear();
          localStorage.setItem("Previouslocation", previous_location);
          localStorage.setItem("buildversion", buildversion);
          localStorage.setItem("Apiversion", Apiversion);
          window.location.href = "/";
        }, 2000);
      }
    }
  }, [profileReduxData]);

  useEffect(() => {
    if (AgencyLoginData?.code === 200) {
      if (
        window.location.host == "localhost:3000" ||
        window.location.host == "dev.app.appmysite.com" ||
        window.location.host == "test.app.appmysite.com" ||
        window.location.host == "stage.app.appmysite.com" ||
        window.location.host == "app.appmysite.com"
      ) {
        setProfileData({ agency_id: 1, spinner: false });
        localStorage.setItem("agencyid", 1);
        localStorage.setItem("agency_id", 1);
      } else {
        if (
          AgencyLoginData.data.agency_name == "undefined" ||
          AgencyLoginData.data.agency_name == undefined
        ) {
          window.location.href = "/contact-admin";
        }

        if (
          AgencyLoginData.data.agency_subscription !== "paddle_preview" &&
          AgencyLoginData.data.agency_subscription !== "agency_preview" &&
          AgencyLoginData.data.agency_subscription !==
            process.env.REACT_APP_Lifetime_preview &&
          AgencyLoginData.data.agency_subscription !==
            process.env.REACT_APP_zoho_preview
        ) {
          setProfileData({
            agency_favicon: AgencyLoginData.data.agency_favicon,
            agency_logo: AgencyLoginData.data.agency_logo,
            agency_name: AgencyLoginData.data.agency_name,
            agency_id: AgencyLoginData.data.agency_id,
            agency_logo:
              process.env.REACT_APP_Image_Path +
              "agencies/" +
              AgencyLoginData.data.agency_id +
              process.env.REACT_APP_Agency_Profile +
              AgencyLoginData.data.agency_logo,
            is_google_login_enabled:
              AgencyLoginData.data.is_google_login_enabled,
            is_fb_login_enabled: AgencyLoginData.data.is_fb_login_enabled,
            is_google_captcha_enabled:
              AgencyLoginData.data.is_google_captcha_enabled,
            google_app_id: AgencyLoginData.data.google_app_id,
            captcha_key: AgencyLoginData.data.captcha_key,
            captcha_secret: AgencyLoginData.data.captcha_secret,
            facebookid: AgencyLoginData.data.fb_app_id,
            agency_primary_color: AgencyLoginData.data.agency_primary_color,
            agency_secondary_color: AgencyLoginData.data.agency_secondary_color,
            button_background_color:
              AgencyLoginData.data.button_background_color,
            button_text_color: AgencyLoginData.data.button_text_color,
            button_hover_txtcolor: AgencyLoginData.data.button_hover_txtcolor,
            button_hover_bgcolor: AgencyLoginData.data.button_hover_bgcolor
          });

          localStorage.setItem("agencyid", AgencyLoginData.data.agency_id);
          localStorage.setItem("agency_id", AgencyLoginData.data.agency_id);
          localStorage.setItem("agencyname", AgencyLoginData.data.agency_name);
          localStorage.setItem("agencylogo", AgencyLoginData.data.agency_logo);
          localStorage.setItem(
            "agency_primary_color",
            AgencyLoginData.data.agency_primary_color
          );
          localStorage.setItem(
            "agency_secondary_color",
            AgencyLoginData.data.agency_secondary_color
          );
          localStorage.setItem(
            "button_background_color",
            AgencyLoginData.data.button_background_color
          );
          localStorage.setItem(
            "button_text_color",
            AgencyLoginData.data.button_text_color
          );
          localStorage.setItem(
            "button_hover_txtcolor",
            AgencyLoginData.data.button_hover_txtcolor
          );
          localStorage.setItem(
            "button_hover_bgcolor",
            AgencyLoginData.data.button_hover_bgcolor
          );
          localStorage.setItem(
            "community_url",
            AgencyLoginData.data.community_url
          );
          localStorage.setItem(
            "knowledge_url",
            AgencyLoginData.data.knowledge_url
          );
          localStorage.setItem(
            "is_customer_support_enable",
            AgencyLoginData.data.is_customer_support_enable
          );
          localStorage.setItem("phone", AgencyLoginData.data.phone);
          localStorage.setItem(
            "phoneno",
            AgencyLoginData.data.phone_country_code
          );
          localStorage.setItem(
            "support_email",
            AgencyLoginData.data.support_email
          );
          localStorage.setItem("product_url", AgencyLoginData.data.product_url);
          localStorage.setItem(
            "agencyfavicon",
            process.env.REACT_APP_Image_Path +
              "agencies/" +
              AgencyLoginData.data.agency_id +
              process.env.REACT_APP_Agency_Profile +
              AgencyLoginData.data.agency_favicon
          );

          setTimeout(() => {
            setProfileData({ spinner: false });
          }, 2000);
        } else {
          window.location.href = "/contact-admin";
        }
      }
    } else {
      if (
        window.location.host == "localhost:3000" ||
        window.location.host == "dev.app.appmysite.com" ||
        window.location.host == "test.app.appmysite.com" ||
        window.location.host == "stage.app.appmysite.com" ||
        window.location.host == "app.appmysite.com"
      ) {
        setProfileData({ agency_id: 1, spinner: false });
        localStorage.setItem("agency_id", 1);
        localStorage.setItem("agencyid", 1);
      } else {
        window.location.href = "/contact-admin";
      }
    }
  }, [AgencyLoginData]);

  useEffect(() => {
    if (IPData) {
      if (!IPData.error) {
        setProfileData({
          user_country: IPData.country_code,
          login_ip: IPData.ip,
          phone_code: IPData.location.calling_code,
          country_name_prefix: IPData.country_code.toLowerCase()
        });
      } else {
        setProfileData({
          user_country: "",
          login_ip: "",
          calling_Code: ""
        });
        const params = {
          code: IPData.error.code,
          type: IPData.error.type,
          info: IPData.error.info
        };
        ProfileErrorlogs(params);
      }
    }
  }, [IPData]);

  return (
    <>
      <Header openProfileMenus={openProfileMenus} />
      <section className="portal-body">
        <ProfileLeftPanel
          rightpanelbody={handleRightPanelExpand}
          profile={profileData.profile}
        />
        <div
          className={
            localStorage.getItem("sidebaserotate") &&
            localStorage.getItem("sidebaserotate") === "true"
              ? "right-panel-gap right-panel-gap-expand"
              : "right-panel-gap "
          }
        >
          <div className="right_panel_profile">
            <div className="right_general ">
              <div className="new-my-profile-page ">
                <div className="div-my-profile-page ">
                  <div className="new-my-profile-head d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <ModuleTitleTooltip
                        title={"My profile"}
                        text={
                          "Add your personal details to update your account."
                        }
                      />
                    </div>
                    <div className=" profile-lastupdate-button">
                      {profileData.updated_at && (
                        <p className="profile-latest-update">
                          Updated{" "}
                          {moment(profileData.updated_at).format(
                            "D MMMM YYYY, HH:mm"
                          )}
                        </p>
                      )}
                      <button
                        type="submit"
                        className="newprofile-save-btn d-none d-xl-block"
                        form="form1"
                        style={{
                          backgroundColor: localStorage.getItem(
                            "button_background_color"
                          ),
                          color: localStorage.getItem("button_text_color"),
                          border:
                            "1px solid " +
                            localStorage.getItem("button_background_color"),
                          "--custom_color5":
                            localStorage.getItem("agencyid") == 1
                              ? "#134bec"
                              : localStorage.getItem("button_hover_bgcolor"),
                          "--custom_color6":
                            localStorage.getItem("agencyid") == 1
                              ? "#3064f9"
                              : localStorage.getItem("button_hover_bgcolor"),
                          "--custom_color4":
                            localStorage.getItem("agencyid") == 1
                              ? "white"
                              : localStorage.getItem("button_hover_txtcolor")
                        }}
                      >
                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                          {editProfileLoading ? (
                            <div className="new-loader"></div>
                          ) : (
                            "Save"
                          )}
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" profile-my-profile-shadow">
                  {isLoading ? (
                    <div className="profile-spinner">
                      <AmsSpinner spinWidth={"200px"} spinHeight={"200px"} />
                    </div>
                  ) : (
                    <div className="div-my-profile-page ">
                      <div className="new-my-profile-shadow">
                        {/* Personal Information */}
                        <div className="new-my-profile-personal">
                          <div className=" profile-img-mobile ">
                            <form
                              id="form1"
                              onSubmit={profiledata}
                              encType="multipart/form-data"
                            >
                              <p className="myprofileimage-tooltip">
                                <div className="d-flex align-items-center profile-pic-container-new">
                                  <label
                                    className="label-profile-pic-new "
                                    htmlFor="profilepic"
                                    onClick={(e) => handleprofileimage(e)}
                                  >
                                    {profileData.profile === "null" ||
                                    profileData.profile === "undefined" ||
                                    !profileData.profile ? (
                                      <img
                                        src={
                                          require("../../../assets/images/dashboard/my-account.png")
                                            .default
                                        }
                                        className="proimg profile-pic-img"
                                        alt="profile logo"
                                      />
                                    ) : (
                                      <>
                                        {profileData.profile.indexOf(
                                          "https"
                                        ) === -1 ? (
                                          <img
                                            src={
                                              process.env.REACT_APP_Image_Path +
                                              localStorage.getItem(
                                                "logged_in_user_id"
                                              ) +
                                              process.env.REACT_APP_Profile +
                                              profileData.profile
                                            }
                                            alt=""
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src =
                                                localStorage.getItem(
                                                  "workspaceId"
                                                ) !== "" &&
                                                localStorage.getItem(
                                                  "workspaceId"
                                                ) !== "undefined" &&
                                                localStorage.getItem(
                                                  "workspaceId"
                                                ) !== undefined &&
                                                localStorage.getItem(
                                                  "workspaceId"
                                                ) !== "null" &&
                                                localStorage.getItem(
                                                  "workspaceId"
                                                ) !== null
                                                  ? wpdefaultImgOnError
                                                  : userdefaultImgOnError;
                                            }}
                                            className="proimg profile-pic-img"
                                          />
                                        ) : (
                                          <img
                                            src={profileData.profile}
                                            alt=""
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src =
                                                localStorage.getItem(
                                                  "workspaceId"
                                                ) &&
                                                localStorage.getItem(
                                                  "workspaceId"
                                                ) !== "undefined" &&
                                                localStorage.getItem(
                                                  "workspaceId"
                                                ) !== "null"
                                                  ? wpdefaultImgOnError
                                                  : userdefaultImgOnError;
                                            }}
                                            className="proimg profile-pic-img"
                                          />
                                        )}
                                      </>
                                    )}
                                  </label>
                                  <div className="mob-imageupload-remove">
                                    <p
                                      className="newimg-upload"
                                      onClick={(e) => handleprofileimage(e)}
                                    >
                                      Upload
                                    </p>
                                    {profileData.profile &&
                                      profileData.profile !== "null" &&
                                      profileData.profile !== "undefined" && (
                                        <p
                                          className="old-imgremove"
                                          onClick={(e) => removeimage(e)}
                                        >
                                          Remove
                                        </p>
                                      )}
                                  </div>
                                  <div className="pl-3 abcd"></div>
                                </div>
                                <div className="myprofile-tooltipupload ">
                                  <p
                                    className="profilepic-upload"
                                    onClick={(e) => handleprofileimage(e)}
                                  >
                                    Upload{" "}
                                  </p>
                                  {profileData.profile &&
                                    profileData.profile !== "null" &&
                                    profileData.profile !== "undefined" && (
                                      <p
                                        className="profilepic-remove"
                                        onClick={(e) => removeimage(e)}
                                      >
                                        {" "}
                                        Remove
                                      </p>
                                    )}
                                </div>
                              </p>
                            </form>
                          </div>
                          <form className="d-flex  justify-content-between flex-wrap">
                            <div className="color_picker inputtypeone">
                              <h4 className="userProfile-name-label">
                                Full name*
                                {/* <Tooltip
                              message={
                                "Set the background color of the app header."
                              }
                            /> */}
                              </h4>
                              <div className="form-group">
                                <input
                                  type="text"
                                  // required
                                  className={`form-control form-nameone userprofile-input ${
                                    profileData.name_err
                                      ? "profile-validate-border"
                                      : ""
                                  }`}
                                  form="form1"
                                  value={profileData.name}
                                  onChange={(e) => handlename(e)}
                                  maxLength="70"
                                />
                              </div>
                              {profileData.name_err && <RequiredError />}
                            </div>

                            <div className="color_picker inputtypetwo">
                              <h4 className="userProfile-name-label">
                                Email*
                                {/* <Tooltip
                              message={
                                "Set the background color of the app header."
                              }
                            /> */}
                              </h4>
                              <div className="form-group position-relative">
                                <input
                                  type="text"
                                  // required
                                  className="form-control form-nametwo userprofile-email-input"
                                  form="form1"
                                  value={profileData.email}
                                  onChange={(e) => handleEmailChange(e)}
                                />
                                <Link
                                  to={profileData.status === 1 ? "#" : "#"}
                                  style={{
                                    cursor:
                                      profileData.status === 1
                                        ? "inherit"
                                        : "cursor",
                                    alignItems: "center"
                                  }}
                                  className="d-flex align-items-center profile-email-input-status"
                                >
                                  {profileData.status === 1 ? (
                                    <img
                                      src={
                                        require("../../../assets/images/dashboard/app-verified.png")
                                          .default
                                      }
                                      className=""
                                      alt="Verified"
                                    />
                                  ) : profileData.verifyEmailLoader ? (
                                    <div className="new-red-loader"></div>
                                  ) : (
                                    <p onClick={(e) => handlesend(e)}>
                                      Verify email
                                    </p>
                                  )}
                                </Link>
                              </div>
                              {profileData.email_err && (
                                <div className="d-flex profile-validation">
                                  <img src={validationicon} />
                                  <p className="profile-redinput">
                                    {profileData.email_err === "Required"
                                      ? "Required"
                                      : "Invalid email"}
                                  </p>
                                </div>
                              )}
                              {profileData.customer_type === 2 &&
                                profileData.oldemail.trim() !==
                                  profileData.email.trim() && (
                                  <p className="appsumo-user-text">
                                    If you are an AppSumo user, please ensure
                                    your email address on your AppSumo account
                                    matches your AppMySite email.
                                  </p>
                                )}
                            </div>

                            <div className="color_picker inputtypeone">
                              <h4 className="userProfile-name-label">
                                Phone
                                {/* <Tooltip
                              message={
                                "Set the background color of the app header."
                              }
                            /> */}
                              </h4>
                              <div>
                                <div className="form-group select-input d-flex align-items-center">
                                  <div
                                    className="phonecodeselects"
                                    ref={wrapperRef}
                                  >
                                    {profileData.openCountryList && (
                                      <div className="profile-selectflag">
                                        <div className="d-flex profile-selectflagtop">
                                          <input
                                            className="searchfleginput"
                                            placeholder="Search..."
                                            value={
                                              profileData.dropdownSearchVal
                                            }
                                            onChange={(e) => {
                                              setProfileData({
                                                dropdownSearchVal:
                                                  e.target.value
                                              });
                                            }}
                                          />
                                          {profileData.dropdownSearchVal.trim() && (
                                            <p
                                              onClick={(e) => {
                                                setProfileData({
                                                  dropdownSearchVal: ""
                                                });
                                              }}
                                              className="clear-search"
                                            >
                                              Clear
                                            </p>
                                          )}
                                        </div>
                                        <div className="countrydiv">
                                          <div className=" countryflagtype">
                                            {countryList &&
                                              countryList.length > 0 &&
                                              countryList
                                                .filter((name) =>
                                                  name.country_name
                                                    .toLowerCase()
                                                    .includes(
                                                      profileData.dropdownSearchVal.toLowerCase()
                                                    )
                                                )
                                                .map((res, i) => (
                                                  <div
                                                    className="d-flex align-items-center justify-content-between country-line-flag"
                                                    key={i}
                                                    onClick={(e) =>
                                                      handleCountryDropdown(
                                                        e,
                                                        res
                                                      )
                                                    }
                                                  >
                                                    <div className="d-flex align-items-center justify-content-start">
                                                      <img
                                                        src={
                                                          process.env
                                                            .REACT_APP_Image_Path +
                                                          process.env
                                                            .REACT_APP_SMALL_FLAG +
                                                          res.country_code.toLowerCase() +
                                                          ".png"
                                                        }
                                                        alt={i}
                                                      />
                                                      <p className="country-name">
                                                        {res.country_name} (
                                                        {"+" + res.phone_code})
                                                      </p>
                                                    </div>
                                                  </div>
                                                ))}
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <div
                                      className="phonecodemodal-div"
                                      onClick={(e) =>
                                        window.matchMedia("(max-width: 1200px)")
                                          .matches
                                          ? togglephonecodemodal(e)
                                          : handleDropdownToggle(
                                              "openCountryList"
                                            )
                                      }
                                    >
                                      <img
                                        src={
                                          process.env.REACT_APP_Image_Path +
                                          process.env.REACT_APP_SMALL_FLAG +
                                          profileData.country_name_prefix?.toLowerCase() +
                                          ".png"
                                        }
                                        alt="country-flag"
                                      />
                                      {"+" + profileData.phone_code}
                                    </div>
                                  </div>

                                  <input
                                    type="text"
                                    className="form-control form-namethree phone-inputs phone-input-radius"
                                    form="form1"
                                    value={profileData.phone}
                                    onChange={(e) => handlephone(e)}
                                  />
                                </div>

                                {/* </Modal> */}
                              </div>
                            </div>
                            <div className="color_picker inputtypetwo">
                              <h4 className="userProfile-name-label">
                                What role best describes you?*
                                {/* <Tooltip
                              message={
                                "Set the background color of the app header."
                              }
                            /> */}
                              </h4>
                              <div
                                className="mobile-view-click"
                                onClick={
                                  window.matchMedia("(max-width: 1200px)")
                                    .matches
                                    ? () =>
                                        handleDropdownToggle(
                                          "openMobRoleDropdown"
                                        )
                                    : null
                                }
                              >
                                <ReactSelect
                                  options={profileData.optionsForRole}
                                  onChange={(e) => handleUserRoleChange(e)}
                                  required={true}
                                  classNamePrefix="profile-role-dropdown"
                                  value={{ label: profileData.user_roles }}
                                  placeholder="Select...."
                                  isSearchable={false}
                                  isClearable={false}
                                  isDisabled={
                                    window.matchMedia("(max-width: 1200px)")
                                      .matches
                                      ? true
                                      : false
                                  }
                                  styles={customStyles}
                                />
                              </div>

                              {profileData.role_err && <RequiredError />}
                            </div>
                            <div className="color_picker inputtypeone">
                              <h4 className="userProfile-name-label">
                                What is your company size?*
                                {/* <Tooltip
                              message={
                                "Set the background color of the app header."
                              }
                            /> */}
                              </h4>
                              <div
                                className="mobile-view-click"
                                onClick={
                                  window.matchMedia("(max-width: 1200px)")
                                    .matches
                                    ? () =>
                                        handleDropdownToggle(
                                          "openMobCompanySizeDropdown"
                                        )
                                    : null
                                }
                              >
                                <ReactSelect
                                  options={profileData.optionsForCompanySize}
                                  onChange={(e) => handleCompanySizeChange(e)}
                                  required={true}
                                  classNamePrefix="profile-role-dropdown"
                                  value={{ label: profileData.company_size }}
                                  placeholder="Select..."
                                  isSearchable={false}
                                  isClearable={false}
                                  isDisabled={
                                    window.matchMedia("(max-width: 1200px)")
                                      .matches
                                      ? true
                                      : false
                                  }
                                  styles={customStyles}
                                />
                              </div>

                              {profileData.company_err && <RequiredError />}
                            </div>
                            <div className="color_picker inputtypetwo">
                              <h4 className="userProfile-name-label">
                                What industry do you operate in?*
                                {/* <Tooltip
                              message={
                                "Set the background color of the app header."
                              }
                            /> */}
                              </h4>
                              <div
                                className="mobile-view-click"
                                onClick={
                                  window.matchMedia("(max-width: 1200px)")
                                    .matches
                                    ? () =>
                                        handleDropdownToggle(
                                          "openMobOperateDropdown"
                                        )
                                    : null
                                }
                              >
                                <ReactSelect
                                  options={profileData.optionsForIndustory}
                                  onChange={(e) => handleUserIndustryChange(e)}
                                  required={true}
                                  classNamePrefix="profile-role-dropdown"
                                  value={{ label: profileData.user_industry }}
                                  placeholder="Select..."
                                  isSearchable={false}
                                  isClearable={false}
                                  isDisabled={
                                    window.matchMedia("(max-width: 1200px)")
                                      .matches
                                      ? true
                                      : false
                                  }
                                  styles={customStyles}
                                />
                              </div>

                              {profileData.industory_err && <RequiredError />}
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="profile-mob-save-btn">
                        <button
                          type="submit"
                          className="newprofile-save-mob-btn  "
                          form="form1"
                        >
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            {editProfileLoading ? (
                              <div className="new-loader"></div>
                            ) : (
                              "Save"
                            )}
                          </div>
                        </button>{" "}
                        {profileData.updated_at && (
                          <p className="mob-profile-update">
                            Updated{" "}
                            {moment(profileData.updated_at).format(
                              "D MMMM YYYY, HH:mm"
                            )}
                          </p>
                        )}
                      </div>
                      {profileData.is_google_login_enabled == 1 ||
                      profileData.is_fb_login_enabled == 1 ||
                      localStorage.getItem("agencyid") == 1 ? (
                        <div className="new-my-profile-shadowsecond">
                          {profileData.google_spinner ||
                          profileData.fb_spinner ? (
                            <div className="test-spinner userprofile-spinnerdiv">
                              <AmsSpinner
                                spinWidth={"200px"}
                                spinHeight={"200px"}
                              />
                            </div>
                          ) : profileData.is_google_login_enabled == 1 ||
                            profileData.is_fb_login_enabled == 1 ||
                            localStorage.getItem("agencyid") == 1 ? (
                            <SocialConnect
                              profileData={profileData}
                              removeGoogleLogins={removeGoogleLogins}
                            />
                          ) : null}
                        </div>
                      ) : (
                        <></>
                      )}
                      <DeleteAccount
                        profileData={profileData}
                        deleteapps={deleteapps}
                        delete_accountmodal={delete_accountmodal}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* New Image upload Pop Up */}
      <NewImageGalleryPopup
        isGalleryPopup={profileData.modal}
        setIsGalleryPopup={true}
        settingYourdata={settingYourdata}
        yourErrFun={() => {}}
        uploadPath={
          localStorage.getItem("originalid") + process.env.REACT_APP_Profile
        }
        settingOrgImgFrom3rdParty={false}
        hideImageGallery={true}
        widthForRestriction={120}
        heightForRestriction={120}
        aspectRatio={"1:1"}
        classCompoState={{ setState: setProfileData }}
        cropShape={"round"}
        isNotCustomUploadOrgImg={true}
      />
      <OptionsSelectionModal
        isOpen={profileData.phonecodemodal}
        options={countryList}
        onClose={() => togglephonecodemodal}
        onOptionSelect={handleCountryDropdown}
        enableSearch={true}
        profileData={profileData}
        setProfileData={setProfileData}
        value={profileData.dropdownSearchVal}
        onChange={(e) => {
          setProfileData({
            dropdownSearchVal: e.target.value
          });
        }}
      />
      <Modal
        isOpen={profileData.userdeletemodal}
        toggle={delete_accountmodal}
        centered
        className="deletemodaltype userdeleteModal"
      >
        <ModalHeader toggle={deleteapps} className="modal-xxl-content">
          <div className="profile-crossmodal " onClick={delete_accountmodal}>
            <img
              src={
                require("../../../assets/images/dashboard/cross.png").default
              }
            />
          </div>
        </ModalHeader>
        <ModalBody className="new-delete-my-account">
          <div>
            <div className="new-delete-account-popup">
              <h4 className="delete-account-head">Delete Account</h4>
              {localStorage.getItem("agencyid") &&
              localStorage.getItem("agencyid") == 1 ? (
                <h5 className="delete-account-text-para">
                  All your data, including but not limited to Apps, Workspaces
                  and Organisations will be permanently deleted. This action
                  cannot be undone. Are you sure you want to delete this
                  account?
                </h5>
              ) : (
                <h5 className="delete-account-text-para">
                  All your data including your apps will be permanently deleted.
                  This action cannot be undone. Are you sure you want to delete
                  this account?
                </h5>
              )}
              <h6 className="delete-account-text-input-text">
                Please type in your email below to confirm*.
              </h6>
              <div className="confirm-email-validation">
                <input
                  placeholder="Email"
                  type="email"
                  id="emailconfirm"
                  autoComplete="off"
                  className="delete-account-input"
                  onPaste={(e) => {
                    e.preventDefault();
                    return false;
                  }}
                />
                {profileData.delete_email_err && <RequiredError />}
              </div>
            </div>

            <div className="new-delete-account-footer">
              <div onClick={delete_accountmodal}>
                <p>Cancel</p>
              </div>
              <div onClick={(e) => Deleteuser(e)}>
                {profileData.deleteLoader ? (
                  <p>
                    <div className="new-red-loader"></div>
                  </p>
                ) : (
                  <p>Delete</p>
                )}
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={profileData.delete_app}
        toggle={deleteapps}
        className="deletemodaltype confirmation-app-delete"
        centered
        dataclass="countrycode mob-contrycode-modal"
      >
        <ModalHeader toggle={deleteapps} className="modal-xxl-content">
          <div className="profile-crossmodal " onClick={deleteapps}>
            <img
              src={
                require("../../../assets/images/dashboard/cross.png").default
              }
            />
          </div>
        </ModalHeader>
        <ModalBody className="new-before-delete-step">
          <div>
            <h4 className="profile-detele-step-active">
              Active products found
            </h4>
            {localStorage.getItem("agencyid") &&
            localStorage.getItem("agencyid") != 1 ? (
              <h6 className="profile-detele-step-active-text">
                Before deleting your profile you must delete all your Apps.{" "}
              </h6>
            ) : (
              <h6 className="profile-detele-step-active-text">
                Before deleting your profile you must delete all your Apps,
                Workspaces and Organizations.{" "}
              </h6>
            )}

            <div className="before-delete-footer">
              <div className="new-before-delete-ok">
                <p onClick={deleteapps}>Okay</p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      <OptionsSelectionModal
        isOpen={
          window.matchMedia("(max-width: 1200px)").matches
            ? profileData.openMobRoleDropdown
            : false
        }
        options={profileData.optionsForRole}
        onClose={() => handleDropdownToggle("openMobRoleDropdown")}
        onOptionSelect={handleUserRoleChange}
      />
      <OptionsSelectionModal
        isOpen={
          window.matchMedia("(max-width: 1200px)").matches
            ? profileData.openMobOperateDropdown
            : false
        }
        options={profileData.optionsForIndustory}
        onClose={() => handleDropdownToggle("openMobOperateDropdown")}
        onOptionSelect={handleUserIndustryChange}
      />
      <OptionsSelectionModal
        isOpen={
          window.matchMedia("(max-width: 1200px)").matches
            ? profileData.openMobCompanySizeDropdown
            : false
        }
        options={profileData.optionsForCompanySize}
        onClose={() => handleDropdownToggle("openMobCompanySizeDropdown")}
        onOptionSelect={handleCompanySizeChange}
      />
    </>
  );
};

export default UserProfile;
