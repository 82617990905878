import React, { useState, useEffect } from "react";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import { Link, useParams } from "react-router-dom";
import { Bar, Doughnut } from 'react-chartjs-2';

import {
    GetNotificationReport,
} from "../../Api/RichNotification/RichNotificationApi";
import moment from "moment";


import NewSpinner from "./NewSpinner";

const NotificationReport = () => {

	const [delivercount, setDeliverCount]  = useState(0);
    const [ctrvalue, setCTRValue]  = useState(0);
    const [influencevalue, setInfluenceValue]  = useState(0);
    const [timevalue, setTimeValue]  = useState("1h");
    const [plateformvalue, setPlateformValue]  = useState("");
    const [clickedcount, setClickedCount]  = useState(0);
    const [notclickedcount, setNotClickedCount]  = useState(0);
    const [confirmdeliverycount, setConfirmDeliveryCount]  = useState(0);
    const [notconfirmdeliverycount, setNotConfirmDeliveryCount]  = useState(0);
	const [confirmcount, setconfirmcount] = useState(0);
    const [outcomelabel, setOutcomeLabel] = useState([]);
    const [notificationcompleted, setNotificationCompleted] = useState([]);
    const [failedcount, setFailedCount] = useState(0);
    const [remainingcount, setRemainingCount] = useState(0);
    const [erroredcount, setErroredCount] = useState(0);
    const [androidcount, setAndroidCount] = useState(0);
    const [ioscount, setIosCount] = useState(0);
    const [title, setTitle] = useState("");
    const [status, setStatus] = useState(0);
    const [totalsent, setTotalSent] = useState(0);
    const [totaldelivered, setTotalDelivered] = useState(0);
    const [spinner, setSpinner] = useState(true);
	const [appRightPanelExpand, setAppRightPanelExpand] = useState( localStorage.getItem("appSideBarCollapse") === true ||
	localStorage.getItem("appSideBarCollapse") === "true"
	  ? true
	  : false);

	let { notificationid } = useParams("notificationid");

	useEffect(()=>{

		if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
            {
              document.title = "Notification report | AppMySite";
            }
            else 
            {
              document.title = "Notification report |"+ ' ' +localStorage.getItem('agencyname');
            }

        GetNotificationReport(notificationid, setDeliverCount, setCTRValue, setInfluenceValue, setClickedCount, setNotClickedCount, setConfirmDeliveryCount, setNotConfirmDeliveryCount, setNotificationCompleted, timevalue, setFailedCount, setRemainingCount, setErroredCount, setAndroidCount, setIosCount, setTitle, setStatus, setTotalSent, setTotalDelivered, setSpinner,setconfirmcount);


    }, []);

	const handleRightPanelExpand = (value) => {

		setAppRightPanelExpand(value);
	}

  

    return (
	<>
        {/*<Alertpopupdata.Provider value={this}>
          	{this.state.alertpopup && <Alertpopup />}
		</Alertpopupdata.Provider>*/}

        <div className="d-md-block">
          	<Header customclass={"header-responsive-new"} />
        </div>
       
        <section className="dasboard_page newmobile-display">

          	<Leftpanel  handleRightPanelExpand={handleRightPanelExpand} />

			<div className="os-report-maindiv">
          		<div className="right-panel-gap pushNotification-right-panel">
					
					{ spinner ?
						<div className="notification-report-spinner">
							<NewSpinner />
						</div>
					:
						<>
							<div className="pushreporthead pushreport-title-div">
								<div className="">
								<h3>{title}</h3>
								
								
								<p>
									
									{ status === 1 ?
										<span  style={{ color: "#52CB86", marginLeft: "0px" }}> Sent </span>
									:
										(status === 2 ?
											"Scheduled"
										:
											(status === 3 ?
												<span  style={{ color: "#e20000", marginLeft: "0px" }}> Failed </span>
											:
												null
											)
										)
										
									}
									<span>{moment(notificationcompleted).format("D MMMM YYYY, HH:mm")}</span>
								</p>
								</div>
								<Link to="/app/advanced-notifications">
								<div className="notification-setting " style={{marginRight: "0px"}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>
                            </div>
							</Link>
							
							</div>

							<div>
									
								<div className="pushreport-firstdiv">
									<div className="deliveredreport">        
										<h2> { delivercount === null ? 0 : delivercount } </h2>
										<p>delivered</p>            
									</div>

									<div className="deliveredreport uniquepara">
										<div>
											<h2>{ ctrvalue === null ? 0 : parseFloat(ctrvalue).toFixed(2) }%</h2>
											<p>clickthrough rate</p>
										</div>
										{/* <p className="unique-data"> 5.2k unique</p> */}
									</div>

									<div className="opensreport uniquepara">
										<div>
											<h2>{ influencevalue === null ? 0 : influencevalue }%</h2>
											<p>influenced opens</p>
										</div>
										{/* <p className="unique-data">10.4k unique</p> */}
									</div>

								</div>

								<div className="pushreport-seconddiv">
									<div className="reportstatistics">
										<div>
											<h2>Delivery Statistics</h2>                   
										</div>

										<div className=" notificationusersreport">
											<div>
												<div className="deliverydata">
													<h3 className="ioscolor"></h3>

													<p className="deliverystatus">{delivercount === null ? 0 : delivercount} <span>delivered</span></p>
												</div>
												<div className="deliverydata" style={{position:"relative"}}>
													<h3 className="notification-confirmdata">
														<svg xmlns="http://www.w3.org/2000/svg" width="10.5" height="34.5" viewBox="0 0 10.5 34.5">
															<path id="Path_83755" data-name="Path 83755" d="M266.648,426v34h10" transform="translate(-266.148 -426)" fill="none" stroke="#bcc1ce" stroke-width="1" stroke-dasharray="2"/>
														</svg>
													</h3>
													<p className="deliverystatus" style={{marginLeft: "17px"}}>{confirmcount} <span>confirmed</span></p>
												</div>
												<div className="deliverydata">
													<h3 className="failedunsubscription"></h3>
													<p className="deliverystatus">{failedcount} <span>failed (unsubscribed)</span></p>
												</div>
												<div className="deliverydata">
													<h3 className="failederror"></h3>
													<p className="deliverystatus">{erroredcount} <span>failed (errored)</span></p>
												</div>
												<div className="deliverydata">
													<h3 className="remaining"></h3>
													<p className="deliverystatus">{remainingcount} <span>remaining</span></p>
												</div>
							
											</div>
								
											<div className="pushreportdonut">
												<div className="pushnotificationdata">
													<h3>{totalsent}</h3>
													<p>Total</p>
												</div>
												<Doughnut data={{ 
																labels: ['Delivered', 'Unsubscribed', 'Errored', 'Remaining'],
																datasets: [
																	{
																		label: 'Plateform',
																		data: [delivercount, failedcount, erroredcount, remainingcount],
																		backgroundColor: ["#6E94FF", "#FC8855", "#FF5858", "#BCC1CE"],
																		fill:true,
																	},
																]
															}}
															options={{
																maintainAspectRatio: false,
																cutoutPercentage: 60,
																responsive: true,
																plugins:{ legend:{ display: false } }
															}} 
														/>
											</div>
										</div>
						
									</div>

									<div className="platformstatistics">
										<div>
											<h2>Device statistics</h2>
										</div>

										<div className=" notificationusersreport">
											<div>
												<div className="deliverydata">
													<h3 className="ioscolor"></h3>
													<p className="deliverystatus">{ioscount} <span>iOS</span></p>
												</div>

												<div className="deliverydata">
													<h3 className="androidcolor"></h3>
													<p className="deliverystatus">{androidcount} <span>Android</span></p>
												</div>
											</div>

											<div className="pushreportdonut">
												<div className="pushnotificationdata">
													<h3>{parseInt(ioscount) + parseInt(androidcount)}</h3>
													<p>Total delivered</p>
												</div>
												<Doughnut data={{
																labels: ['Andoid', 'iOS'],
																datasets: [
																	{
																		label: 'Plateform',
																		data: [androidcount, ioscount],
																		backgroundColor: ["#6E94FF", "#A2BBFF"],
																		fill:true,
																	},
																]
															}}
															// style={{width: "240px", height: "240px"}}
															// height={320 + "px"}
															options={{
																maintainAspectRatio: false,
																cutoutPercentage: 60,
																responsive: true,
																plugins:{ legend:{ display: false } }
															}} 
														/>
											</div>
										</div>
							
									</div>
								</div>

								<div className=" pushreport-thirddiv">
									<div className=" deliveredreport">
										<h2> {clickedcount} </h2>
										<p>clicked</p>
									</div>

									<div className=" deliveredreport">
										<h2> {notclickedcount} </h2>
										<p>did not click</p>
									</div>

									<div className=" deliveredreport">

										<h2> { confirmdeliverycount === null ? 0 : confirmdeliverycount } </h2>
										<p>delivery confirmed</p>
									</div>

									<div className=" opensreport">
										<h2> { notconfirmdeliverycount === null ? 0 : notconfirmdeliverycount } </h2>
										<p>delivery unconfirmed</p>
									</div>
								</div>
									
								
							</div>
						</>
					}
					

          			<div> </div>

          		</div>
			</div>

			{/*<Leftpanel />*/}

        </section>
      
	</>
    );
}

export default NotificationReport;