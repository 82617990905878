import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./cmsBuilderstyle.css";
import Header from "./../Header";
import Leftpanel from "./../Leftpanel";
import imagebackground from "./../../assets/images/dashboard/cms/uploadbackground.png";
import { 
	Spinner, 
	UncontrolledDropdown,
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
    Button,
    Label,
    Input,
    FormGroup,
} from "reactstrap";
import ReactTags from 'react-tag-autocomplete';
import BottomScrollListener from "react-bottom-scroll-listener";
import { cmsDeleteAtrApi, cmsGetAtrApi, cmsGetSpecificAtrApi, cmsSaveAtrApi, getFlatIcons } from "../../Api/CmsApi/Cmsapi";
import IconSelectionPopUp from "../Reusable/IconSelectionPopUp";


const AttributesListing = ({
  
}) => {

    const [openAttributePopup, setOpenAttributePopup] = useState(false);
  

    const [appRightPanelExpand, setAppRightPanelExpand] = useState( localStorage.getItem("appSideBarCollapse") === true ||
    localStorage.getItem("appSideBarCollapse") === "true"
    ? true
    : false);
    const [atrValArr, setAtrValArr] = useState([]);
    const [arrtagids, setArrTagIds] = useState([]);
    const [atrName, setAtrName] = useState("");
    const [atrImg, setAtrImg] = useState("");
    const [isTrashed, setIsTrashed] = useState(false);
    const [spinner, setSpinner] = useState(true);
    const [isLoader, setIsLoader] = useState("d-none");
   
    const [loaderimage, setLoaderimage] = useState( require("../../assets/images/signup/Loader_GIF.gif").default );
    const [deletedIdForAtr, setDeleteIdForAtr] = useState("");
    const [atrCreateBtntext, setAtrCreateBtntext] = useState("Create");
    const [atrIsLoader, setAtrIsLoader] = useState("d-none");
    const [searchVal, setSearchVal] = useState("");
    const [createAtrPopUpSpin, setCreateAtrPopUpSpin] = useState(false);
    const [atrId, setAtrId] = useState("");
    // Flat Icons keys
    const [openIconPopUp, setOpenIconPopUp] = useState(false);
    const [atrIcon, setAtrIcon] = useState("");
    const [isDoneBtnDisabled, setIsDoneBtnDisabled] = useState(false);
    // Flat Icons keys
    const [atrListingData,setAtrListingData] = useState([]);
    const [bottomSpinner, setBottomSpinner] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);

    const [pageForAtr, setPageForAtr] = useState(1);
    const [paginationSpinnerForAtr , setPaginationSpinnerForAtr] = useState(false);
    const [hitNextPaginationForAtr , setHitNextPaginationForAtr] = useState(false);
    const [atrSubData,setAtrSubData] = useState([]);

    useEffect(() => {

      if ( parseInt(localStorage.getItem("website_technology")) !== 3 && window.location.pathname.includes("custom")) {
        window.location.href="/app/dashboard"
      
         }

      if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
      {
      document.title = "Post attributes | AppMySite";
        
      }
      else
      {
      document.title = "Post attributes |"+ ' ' +localStorage.getItem('agencyname');
      }
      if ( parseInt(localStorage.getItem("website_technology")) !== 3 && window.location.pathname.includes("custom")) {
        window.location.href="/app/dashboard"
  
       }

       let limit = 20;
       let pageNo = 1;
       setPageForAtr(pageNo);

      cmsGetAtrApi(
       setSpinner,
       setAtrListingData,
       setBottomSpinner,
       "atr",
       false,
       false,
       limit,
       pageNo,
       atrListingData,
       setHitNextPaginationForAtr,
       setPaginationSpinnerForAtr,
       setAtrSubData,
      );

    }, [isDeleted,isSaved])
    
    const handlePagination = () => {
      if (paginationSpinnerForAtr === false && bottomSpinner ===false) {
        if (hitNextPaginationForAtr) 
        {
          let limit = 20;
          let pageNo = pageForAtr + 1;
          setPageForAtr(pageNo);
          setPaginationSpinnerForAtr(true);
  
          cmsGetAtrApi(
            setSpinner,
            setAtrListingData,
            setBottomSpinner,
            "atr",
            false,
            false,
            limit,
            pageNo,
            atrListingData,
            setHitNextPaginationForAtr,
            setPaginationSpinnerForAtr,
            setAtrSubData,
           );
        }
      }
    };
  
    const handleNotHitOnBottom = () => {
  
    }
    const handleIconPopUp = () =>{
        setOpenIconPopUp(!openIconPopUp);
        setIsDoneBtnDisabled(false);
    }

    const saveIconName = (name) =>{
      setAtrIcon(name);
      setOpenIconPopUp(false);
      setIsDoneBtnDisabled(false);
  }


   const handleCancelIcon =() =>{
        setAtrIcon("");
    }
   
    const reactTags = useRef();

    const handleAttributePopUp = (e,from) =>{
      if(from==="cancel"){
        setAtrId("");
       setAtrName("");
       setAtrIcon("");
       setAtrValArr([]);
      }
        setOpenAttributePopup(!openAttributePopup)
    }

    const handleEditAttributePopUp = (e,atrId) =>{
      setAtrId(atrId);
      setOpenAttributePopup(!openAttributePopup);
      setCreateAtrPopUpSpin(true);
     
    
    cmsGetSpecificAtrApi(atrId,setAtrName,setAtrIcon,setAtrValArr,setCreateAtrPopUpSpin);
    }

  
    const handleRightPanelExpand = (value) => {

        setAppRightPanelExpand(value);
    }

    const handleAttValDelete =  (i) =>{
		
		

		const newVallArr = [...atrValArr];

    	newVallArr.splice(i, 1);

		setAtrValArr(newVallArr);    	

	}
	
	const  handleAttValAdd = (valData) => {
	
	
    if(valData.name.trim()){
	
		setAtrValArr(() => 
		{
			// let newVallArr = { "name": (valData.name).charAt(0).toUpperCase() + (valData.name).slice(1),"id":null};
      let newVallArr = { "name":valData.name.substring(0,60),"id":null};
			return [...atrValArr, newVallArr];
		});

		setArrTagIds(() => 
		{
			return [...arrtagids, ""];
		});
  }
		
	}

   const handleChangeAtrName = (e) =>{
    setAtrName(e.target.value);
    }


const handleOpenDeleteAtrPopUp = (e, from, id) => {
    setIsTrashed(!isTrashed);
    if (from === "add") {
        setDeleteIdForAtr(id);
    }
};

//Trash Post
const handleTrashedAtr = (e) => {
    setIsTrashed(!isTrashed);
    setSpinner(!spinner);
    const params = { attribute_id: deletedIdForAtr ,   app_id :   parseInt(localStorage.getItem("Appid"))
    };
    cmsDeleteAtrApi(params,setIsDeleted,isDeleted);
   

    

};

const handleCreateAtr = (e) =>{
    setAtrCreateBtntext("");
    setAtrIsLoader("d-block");
      let finalArr = [];
      if(atrValArr&&atrValArr.length>0){
        finalArr = atrValArr.map((row)=>{
        let obj = atrId ? {name:row.attribute_term_name,id:row._id}:{name:row.name,id:null};
        return obj;
        })
      }
    const params = {
        app_id: parseInt(localStorage.getItem("Appid")),
        attribute_id:atrId?atrId: null, //for edit , otherwise null
        name: atrName,
        icon_name: atrIcon,
        attribute_terms: finalArr,
    }
    cmsSaveAtrApi(params,
      setAtrCreateBtntext,
      setAtrIsLoader,
      setOpenAttributePopup,
      setBottomSpinner,
      setIsSaved,
      isSaved,
      setAtrName,
      setAtrIcon,
      setAtrValArr,
      setAtrId,
      atrId,
      );
}

// const returnNoOFValues = (string) =>{
//  let no=1;
//  let parts = string.split(",");
//  no=parts.length;
//  return no;
// }
const handleSearchVal = (e) =>{
setSearchVal(e.target.value);
}
  return (
    <>
        <div>
        <Header customclass={"header-responsive-new"} />
        </div>

        <section className="dasboard_page mobile-display mt-4 mt-md-0 cms-module">
      <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
		  <BottomScrollListener
              onBottom={window.matchMedia("(max-width: 1200px)").matches?handlePagination:handleNotHitOnBottom}
              offset={100}
            >
              {(scrollRef) => (
                <div
                style={{
                  height: "calc(100vh - 60px)",
                  overflowY: "auto",
                }}
                ref={scrollRef}
      
      className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>


            <div className="post-heading d-none d-xl-flex">
                <h1>Posts <p class="cms-posts-tooltip">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5"><circle cx="9" cy="9" r="9" stroke="none"></circle><circle cx="9" cy="9" r="8.25" fill="none"></circle></g><line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"></line><path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"></path></g></svg>
                  <p class="cms-posts-tooltipsubheader">A post is a time-stamped article organized through categories and tags. Most recent posts will appear first.</p>
                  </p></h1>
                
            </div>

            <div className="post-heading category-heading">
                <h1>Attributes</h1>

                <div  style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                }}   onClick={(e)=>handleAttributePopUp(e,"cancel")}> <img className="mr-2" src={require("../../assets/images/dashboard/plus.png").default}/>{" "}
                    <p style={{
                    marginBottom:"0px",
                    marginTop:"0px",
                    fontSize:"15px",
                    color:"#007bff"
                }} >Create</p>
                </div>
                
            </div>

            <div className="pageshead">
              
              <div className="post-sublinks">
                <Link to="/app/posts" style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color')}}>Posts</Link>
                <Link to="/app/post-categories" style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color')}}>Categories</Link>
                <Link to="/app/post-tags" style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color')}}>Tags</Link>
                <Link className="active" to="/app/post-attributes" style={{"border-bottom": localStorage.getItem('agencyid') == 1 ? "2px solid #3064f9" : "2px solid "+ localStorage.getItem('agency_primary_color'),"color": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('agency_primary_color')}}>Attributes</Link>
              </div>


              <div className="createpage allpages">
                {
                  atrListingData && atrListingData.length>0 &&

                <div className="cms-pages-search">
                  <input placeholder="Search" type="text" value={searchVal} onChange={(e)=>handleSearchVal(e)}></input>
                  
                    <img src={require("../../assets/images/dashboard/cms/lens.png").default} />
                </div> }


                
                <div style={{
                  cursor:"pointer"
                }}>
                  <p onClick={(e)=>handleAttributePopUp(e,"cancel")}
                    className="createpage d-none d-xl-flex"
                     style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                  >
                    <img
                      className="mr-2"
                      src={
                        require("../../assets/images/dashboard/cms/plus.png")
                          .default
                      }
                    />{" "}
                    
                    <span>
                      Create
                      </span>
                  </p>

                </div>
              </div>
            </div>

           

            <div className="attributes-width" style={{position : "relative"}}>
            {spinner === true ? 
										(
											<div className="newspinner">
												<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
													<g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
												</svg>
											</div>
										) :
              <>
              
              <BottomScrollListener
              onBottom={window.matchMedia("(min-width: 1200px)").matches?handlePagination:handleNotHitOnBottom}
              offset={100}
            >
              {(scrollRef) => (
                <div
                ref={scrollRef}
                  className="attributes-listing-left" 
                  style={{
                    width:atrListingData && atrListingData.length > 0?"50%":"100%",
                    height: "calc(100vh - 250px)",
                    overflowY: "auto",
                    overflowX: "hidden",
                  }}
                >
                {
                        atrListingData &&atrListingData.length > 0 &&
                    <div className="attributes-list-title">
                        <h2>Attributes</h2>
                        <h5><span>Posts</span> <span>Edit, Delete</span></h5>
                    </div> }
                    
                    {
                        atrListingData &&atrListingData.length > 0 ?
                        <>
                        <>
                        {
                        atrListingData.filter((res) => res.attribute_name.toLowerCase().includes(searchVal.toLowerCase()) ).map((row,indx)=>{
                        return(
                            <div className="attribute-entry">
                    {/* <a href="javascript:void(0)"></a> */}
                    <Link to={`/app/post-attribute-values/${row._id}`}></Link>
                      <div className="attribute-summry">
                        <div className="attribute-icon">
                          {
                            row.attribute_icon_name? <img className="img-fluid"
                            src={process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid")+process.env.REACT_APP_NEW_ICON_PATH +row.attribute_icon_name} alt="icon"/>
                            :
                            <img src={imagebackground} className="img-fluid"/>
                          }
                           
                            {/* <img src={require("../../assets/images/dashboard/cms/unsplash-mobile.png").default} className="img-fluid"/> */}
                        </div>
                        <div className="attribute">
                            <h2>{row.attribute_name}</h2>
                            {/* <h3>{row.terms?returnNoOFValues(row.terms):0} values</h3> */}
                            <h3>{row.attribute_terms.length} {row.attribute_terms.length<=1?"value":"values"}</h3>
                        </div>
                      </div>

                      <div className="attribute-sub">
                        <div className="attribute-related-post">
                            <p>{row.post_count}</p>
                        </div>

                        <div className="attribute-action">
                            <img onClick={(e)=>handleEditAttributePopUp(e,row._id)} src={require("../../assets/images/dashboard/cms/edit.png").default} className="img-fluid"/>
                            <img onClick={(e)=>handleOpenDeleteAtrPopUp(e,"add",row._id)} src={require("../../assets/images/dashboard/cms/delete.png").default} className="img-fluid"/>
                        </div>
                      </div>

                    </div>
                        )
                        })}
                        </>
                          {bottomSpinner && (

                            <div className="form-group" style={{ background: "none" , margin:"auto" }}>
                                          
                            <svg xmlns="http://www.w3.org/2000/svg" 
                              xlink="http://www.w3.org/1999/xlink" 
                              style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
                              width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                              <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                              style={{animationplaystate: "running", animationdelay: "0s"}}>
                              </animateTransform>
                              </circle>
                              </g>
                              <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                              style={{animationplaystate: "running", animationdelay: "0s"}}>
                              </animateTransform>
                              </circle>
                              </g>
                              <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                              style={{animationplaystate: "running", animationdelay: "0s"}}>

                              </animateTransform>
                              </circle>
                              </g>
                              <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                              style={{animationplaystate: "running", animationdelay: "0s"}}>

                              </animateTransform>
                              </circle>
                              </g>
                            </svg>
                            </div>
                          )}
                          {paginationSpinnerForAtr && window.matchMedia("(min-width: 1200px)").matches && (
													<div className={`form-group`} style={{ background: "none", margin: "auto" }}>

													<svg xmlns="http://www.w3.org/2000/svg"
													xlink="http://www.w3.org/1999/xlink"
													style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
													width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
													<g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
															style={{ animationplaystate: "running", animationdelay: "0s" }}>
														</animateTransform>
														</circle>
													</g>
													<g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
															style={{ animationplaystate: "running", animationdelay: "0s" }}>
														</animateTransform>
														</circle>
													</g>
													<g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
															style={{ animationplaystate: "running", animationdelay: "0s" }}>

														</animateTransform>
														</circle>
													</g>
													<g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
															style={{ animationplaystate: "running", animationdelay: "0s" }}>

														</animateTransform>
														</circle>
													</g>
													</svg>
													</div>
												)}
                          
                         </>
                          : 
                          bottomSpinner?
                          <div className="newspinner">
                          <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                            <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                </animateTransform>
                              </circle>
                            </g>
                            <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                </animateTransform>
                              </circle>
                            </g>
                            <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                </animateTransform>
                              </circle>
                            </g>
                            <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                              <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
                                </animateTransform>
                              </circle>
                            </g>
                          </svg>
                          </div>             :
                            
                            <div className="no-attributes">
                            <div>
                            <img src={require("../../assets/images/dashboard/cms/no-attributes.png").default} />
                            <p>Nothing here at the moment. Come back later?</p>
                            </div>
                        </div>
                            }
                    
                    

                   

                </div>
              )}
              </BottomScrollListener>
               {
                atrListingData && atrListingData.length > 0  &&
                <div className="attributes-listing-right">
                    {/* <div className="attributes-preview-screen">

                      <div className="attributes-preview-header">
                        <div className="attributes-preview-header-left">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.705" height="12.513" viewBox="0 0 14.705 12.513">
                            <g id="Group_38608" data-name="Group 38608" transform="translate(0.707 0.354)">
                            <g id="Group_2097" data-name="Group 2097" transform="translate(0)">
                            <path id="Path_6037" data-name="Path 6037" d="M0,5.9,5.9,0l5.9,5.9" transform="translate(0 11.806) rotate(-90)" fill="none" stroke="#333" stroke-width="1"/>
                            </g>
                            <path id="Path_9120" data-name="Path 9120" d="M-13838.828-23465.541h14" transform="translate(13838.826 23471.443)" fill="none" stroke="#333" stroke-width="1"/>
                            </g>
                            </svg>
                            <p>Filter</p>
                        </div>
                        <div className="attributes-preview-header-right">
                            <p>Clear</p>
                        </div>
                      </div>

                      <div className="attribute-preview-list">
                          {
                            atrListingData && atrListingData.filter((res) => res.attribute_name.toLowerCase().includes(searchVal.toLowerCase()) ).map((row,indx)=>{
                            return(
                              <div>
                              <h4>{row.attribute_name}</h4>
                              <svg xmlns="http://www.w3.org/2000/svg" width="11.571" height="10.371" viewBox="0 0 11.571 10.371">
                              <g id="Group_5370" data-name="Group 5370" transform="translate(0 0.354)">
                                  <path id="Path_8624" data-name="Path 8624" d="M0,0,4.832,4.832,9.664,0" transform="translate(6.032 9.664) rotate(-90)" fill="rgba(0,0,0,0)" stroke="#a1a1a1" stroke-width="1"/>
                                  <path id="Path_9125" data-name="Path 9125" d="M-11679.105-23265.5h-10.863" transform="translate(11689.969 23270.332)" fill="none" stroke="#a1a1a1" stroke-width="1"/>
                              </g>
                              </svg>
                          </div>
                            )
                            })
                          }
                         


                            

                        </div>

                        <a href="javascript:void(0)">Apply</a>

                    </div> */}

                    <div className="attributes-preview-screen">
                    <div className="attributes-preview-header">
                        <div className="attributes-preview-header-left">
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.705" height="12.513" viewBox="0 0 14.705 12.513">
                            <g id="Group_38608" data-name="Group 38608" transform="translate(0.707 0.354)">
                            <g id="Group_2097" data-name="Group 2097" transform="translate(0)">
                            <path id="Path_6037" data-name="Path 6037" d="M0,5.9,5.9,0l5.9,5.9" transform="translate(0 11.806) rotate(-90)" fill="none" stroke="#333" stroke-width="1"/>
                            </g>
                            <path id="Path_9120" data-name="Path 9120" d="M-13838.828-23465.541h14" transform="translate(13838.826 23471.443)" fill="none" stroke="#333" stroke-width="1"/>
                            </g>
                            </svg>
                            <p>Filter</p>
                        </div>
                      
                      </div>
                      <div className="attribute-filter-body">
                        <div className="attribute-filter-body-left">
                          {
                           ( atrListingData && atrListingData.length > 0 )
                           && <h1>{atrListingData[0].attribute_name}</h1>
                          }
                          
                          <div className="attribute-filter-body-left-parent">
                          {
                            atrListingData && atrListingData.length > 0  &&
                             atrListingData.map((row,indx)=>{
                            return(
                              indx > 0 &&
                            <p>{row.attribute_name}</p>
                            )})}
                          </div>
                        </div>
                        <div className="attribute-filter-body-right">
                        {
                           atrListingData && atrListingData.length > 0 && 
                           atrListingData[0].attribute_terms &&
                           atrListingData[0].attribute_terms.length > 0 &&
                          <div  className="attribute-filter-body-right-search">
                          <svg id="Group_39925" data-name="Group 39925" xmlns="http://www.w3.org/2000/svg" width="12.075" height="12.075" viewBox="0 0 12.075 12.075">
                            <path id="Path_103441" data-name="Path 103441" d="M5.1,1.073A4.025,4.025,0,1,1,1.073,5.1,4.029,4.029,0,0,1,5.1,1.073M5.1,0a5.1,5.1,0,1,0,5.1,5.1A5.1,5.1,0,0,0,5.1,0" fill="#b0b0b0"/>
                            <path id="Line_144" data-name="Line 144" d="M1.891,2.611-1.414-.695l.719-.719L2.611,1.891Z" transform="translate(9.464 9.464)" fill="#b0b0b0"/>
                          </svg> <span>Search</span>
                          </div>
                        }
                          <div className="attribute-filter-body-right-child">

                          {
                           ( atrListingData && atrListingData.length > 0 )
                           && 
                           <>
                           {
                            atrListingData[0].attribute_terms && atrListingData[0].attribute_terms.length > 0 &&
                            atrListingData[0].attribute_terms.map((term)=>{
                             return(
                              <p>
                              <svg xmlns="http://www.w3.org/2000/svg" width="10.166" height="7.609" viewBox="0 0 10.166 7.609">
                                <path id="Path_106216" data-name="Path 106216" d="M-10008.486,1863.6l2.905,2.8,6.218-6.027" transform="translate(10009.007 -1859.83)" fill="none" stroke="#b0b0b0" stroke-width="1.5"/>
                              </svg><span>{term.attribute_term_name}</span>
                              </p>
                             )
                            })
                           }
                          
                           </>
                          }
                            
                           
                            
                          </div>
                        </div>
                      </div>
                      <div className="attribute-filter-body-bottom">
                        <button className="attribute-filter-body-clear">Clear</button>
                        <button className="attribute-filter-body-apply">Apply</button>
                      </div>
                </div>
                </div> }

                
              </>
              
              }
            </div>

            <div className="attributes-mobile">
            {spinner === true ? 
										(
											<div className="newspinner">
												<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
													<g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
													<g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
														<circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
															<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
															</animateTransform>
														</circle>
													</g>
												</svg>
											</div>
										) :
              <>
            {
                        atrListingData && atrListingData.length>0 ?
                        <>
                        <>{
                        atrListingData.filter((res) => res.attribute_name.toLowerCase().includes(searchVal.toLowerCase()) ).map((row,indx)=>{
                          return(
                <div className="attributes-mobile-entry">
                    <h3>Attribute</h3>
                    <Link to={`/app/post-attribute-values/${row._id}`}>
                    <div className="attributes-mobile-entry-first">
                        <div>
                            <h4>{row.attribute_name}</h4>
                            {/* <h5>{row.terms?returnNoOFValues(row.terms):0} values</h5> */}
                            <h5>{row.attribute_terms.length} {row.attribute_terms.length<=1?"value":"values"}</h5>
                        </div>
                        {
                            row.attribute_icon_name?
                        <img className="img-fluid"
                            src={process.env.REACT_APP_Image_Path+localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid")+process.env.REACT_APP_NEW_ICON_PATH +row.attribute_icon_name} alt="icon"/>
                            :
                            <img src={imagebackground} className="img-fluid"/>
                          }
                        {/* <img src={require("../../assets/images/dashboard/cms/unsplash-mobile.png").default} className="img-fluid"/> */}
                    </div>
                    </Link>

                    <div className="attributes-mobile-entry-second">

                      <div>
                        <h4>Posts</h4>
                        <h5>{row.post_count}</h5>
                      </div>

                      <div className="attribute-action">
                            <img onClick={(e)=>handleEditAttributePopUp(e,row._id)} src={require("../../assets/images/dashboard/cms/edit.png").default} className="img-fluid"/>
                            <img onClick={(e)=>handleOpenDeleteAtrPopUp(e,"add",row._id)} src={require("../../assets/images/dashboard/cms/delete.png").default} className="img-fluid"/>
                        </div>

                    </div>

                </div>
                 )})
                 
                 }
                </>
                {bottomSpinner && (

                  <div className="form-group" style={{ background: "none" , margin:"auto" }}>
                                
                  <svg xmlns="http://www.w3.org/2000/svg" 
                    xlink="http://www.w3.org/1999/xlink" 
                    style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
                    width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                    style={{animationplaystate: "running", animationdelay: "0s"}}>
                    </animateTransform>
                    </circle>
                    </g>
                    <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                    style={{animationplaystate: "running", animationdelay: "0s"}}>
                    </animateTransform>
                    </circle>
                    </g>
                    <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                    style={{animationplaystate: "running", animationdelay: "0s"}}>

                    </animateTransform>
                    </circle>
                    </g>
                    <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
                    <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
                    style={{animationplaystate: "running", animationdelay: "0s"}}>

                    </animateTransform>
                    </circle>
                    </g>
                  </svg>
                  </div>
                            )}
                   {paginationSpinnerForAtr && window.matchMedia("(max-width: 1200px)").matches && (
													<div className={`form-group`} style={{ background: "none", margin: "auto" }}>

													<svg xmlns="http://www.w3.org/2000/svg"
													xlink="http://www.w3.org/1999/xlink"
													style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
													width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
													<g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
															style={{ animationplaystate: "running", animationdelay: "0s" }}>
														</animateTransform>
														</circle>
													</g>
													<g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
															style={{ animationplaystate: "running", animationdelay: "0s" }}>
														</animateTransform>
														</circle>
													</g>
													<g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
															style={{ animationplaystate: "running", animationdelay: "0s" }}>

														</animateTransform>
														</circle>
													</g>
													<g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
														<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
															style={{ animationplaystate: "running", animationdelay: "0s" }}>

														</animateTransform>
														</circle>
													</g>
													</svg>
													</div>
												)}
                            </>
                :
					bottomSpinner?
					<div className="newspinner">
					<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink"  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
						<g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
							<circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
								<animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
								</animateTransform>
							</circle>
						</g>
						<g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
							<circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
								<animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
								</animateTransform>
							</circle>
						</g>
						<g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
							<circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
								<animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
								</animateTransform>
							</circle>
						</g>
						<g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
							<circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
								<animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{animationplaystate: "running", animationdelay: "0s"}}>
								</animateTransform>
							</circle>
						</g>
					</svg>
				</div>             :
						
            <div className="no-attributes">
            <div>
            <img src={require("../../assets/images/dashboard/cms/no-attributes.png").default} />
            <p>Nothing here at the moment. Come back later?</p>
            </div>
        </div>
                    }
                    </>}
          


            </div>




        </div>)}
        </BottomScrollListener>

        


            <Modal isOpen={openAttributePopup} centered className="add-attribute-popup">
                <ModalHeader >												
                    <div className="security-crossmodal " onClick={(e)=>handleAttributePopUp(e,"add")}>
                        <img src={require("../../assets/images/dashboard/cross.png").default} />
                    </div>
                </ModalHeader>
                <ModalBody>
                {createAtrPopUpSpin === true ? (
                  <div className="newspinner">
                    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }} width="100px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
  <g transform="translate(20 50)" style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}>
    <circle cx="0" cy="0" r="6" fill="#e6261f" style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}>
      <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}></animateTransform>
    </circle>
  </g>
  <g transform="translate(40 50)" style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}>
    <circle cx="0" cy="0" r="6" fill="#f7d038" style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}>
      <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}></animateTransform>
    </circle>
  </g>
  <g transform="translate(60 50)" style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}>
    <circle cx="0" cy="0" r="6" fill="#49da9a" style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}>
      <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}></animateTransform>
    </circle>
  </g>
  <g transform="translate(80 50)" style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}>
    <circle cx="0" cy="0" r="6" fill="#4355db" style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}>
      <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}></animateTransform>
    </circle>
  </g>
                    </svg>
                  </div>
                ) : (
                    <div >
                        <div className="add-attribute-body">

                            <h2>{atrId?"Edit attribute":"New attribute"}</h2>
                            <h3>Attributes assist your app users in filtering posts effectively.</h3>

                            <h4>Name*<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p className="gsetting-tooltipsubheader">Give your attribute a descriptive name. For example, if you’re creating a property listing app, your attribute’s name can be ‘number of bedrooms’.</p></p></h4>
                            <input className="add-attribute-input" type="text" value={atrName} maxLength={"60"} onChange={(e)=>handleChangeAtrName(e)}/>

                            <h4>Icon<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p className="gsetting-tooltipsubheader">Select an icon to represent your attribute visually and make it stand out.</p></p></h4>
                            <label className="set-full-img">
                            <div className="image-uploader">
                                <div className="d-flex align-items-center" onClick={(e)=>handleIconPopUp(e)}>
                                <div className="attribute-icon-background">
                                    <div className={`uploadpixabayimage ${atrIcon?"cmsafterupload" : ""}`} style={{ backgroundImage: "url(" + ( atrIcon?process.env.REACT_APP_Image_Path+
                                                                       localStorage.getItem("user_id") +"/"+localStorage.getItem("Appid")+process.env.REACT_APP_NEW_ICON_PATH +
                                                                       atrIcon:imagebackground ) + ")", }}></div></div>
                                
                             
                                <p style={{marginBottom: "0px" , color: atrIcon? "" : "#BCC1CE"}}>{atrIcon?atrIcon.replace("%2Bxml",""):"Upload an icon for the attribute"}</p>
                                </div>
                                {
                                    atrIcon && 
                                
                                <svg onClick={(e)=>handleCancelIcon(e)} width="11.06" height="11.06" viewBox="0 0 11.06 11.06" >
                                <path id="Union_1696" data-name="Union 1696" d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z" transform="translate(0.53 0.53)" fill="none" stroke="#7782a1" stroke-width="1.5" /> </svg>
                              }
                            </div>
                            </label>
                            {
                              !atrId &&
                            
                            <h4>Values<p className="gsetting-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14"><g id="Group_40296" data-name="Group 40296" transform="translate(-1727 -19.132)"><g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19.132)" fill="#fff" stroke="#bcc1ce" stroke-width="1.2"><circle cx="7" cy="7" r="7" stroke="none"></circle><circle cx="7" cy="7" r="6.4" fill="none"></circle></g><path id="Path_98061" data-name="Path 98061" d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074" transform="translate(1725.126 17.539)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></path><line id="Line_343" data-name="Line 343" y2="1.2" transform="translate(1734 29.132)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.2"></line></g></svg><p className="gsetting-tooltipsubheader">Enter values that you want users to use as filters. For example, if your attribute is ‘number of bedrooms’, you can input values such as ‘2 bedrooms’, ‘3 bedroom’, ‘4 bedrooms’.</p></p></h4> }
                            	  {
                              !atrId &&															
                            <ReactTags tags={atrValArr} onDelete={handleAttValDelete} onAddition={handleAttValAdd} ref={reactTags} allowNew addOnBlur={true} 
                            placeholderText = ""
                             /> }
                            
                                
                        </div>
                        <div className="add-attribute-footer">
                            <button className="add-attribute-footer-btn1" onClick={(e)=>handleAttributePopUp(e,"add")}>Cancel</button>
                            <button className={`add-attribute-footer-btn2  ${!atrName.trim()?"disabled-attribute-btn":""}`}
                            onClick={(e)=>handleCreateAtr(e)}
                            disabled={!atrName.trim()?true:false} 
                            style={{
                                cursor:!atrName.trim()?"not-allowed":"",
                                backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),

                            }}>
                                <div className={atrIsLoader}>
                                    <div className="new-loader"></div>
                                </div>
                                {atrId ?atrIsLoader==="d-none"? "Save":"" : atrCreateBtntext}
                            
                            </button>
                        </div>
                    </div>)}										
                </ModalBody>
            </Modal>

         

          
		<Modal
          isOpen={isTrashed}
          centered
          className="delete-popup"
          toggle={(e) => handleOpenDeleteAtrPopUp(e, "cancel")}
         
          dataclassName="addonpopup"
        >
										
          <ModalBody className="">
          <div className="security-crossmodal " onClick={(e) => handleOpenDeleteAtrPopUp(e, "cancel")}>
              <img src={require("../../assets/images/dashboard/cross.png").default} />
          </div>


            <div>
              <h5>Delete this attribute?</h5>
              <h6>This action will permanently delete this attribute.</h6>

              <div className="d-flex align-items-center justify-content-between cms-delete-popup-footer">

                <div className="d-none d-xl-block grey-btn" onClick={(e) => handleOpenDeleteAtrPopUp(e, "cancel")}>
                  <p>Cancel</p>
                </div>

                <div onClick={(e) => handleTrashedAtr(e)}>
                  <div className="d-flex align-items-center justify-content-center h-100 w-100 red-btn">
                    <p>
                      <div className={isLoader}>
                        <img src={loaderimage} alt="loader" />
                      </div>
                      <div>Delete</div>
                    </p>
                  </div>
                </div>

				<div className="d-block d-xl-none grey-btn" onClick={(e) => handleOpenDeleteAtrPopUp(e, "cancel")}>
                  <p>Cancel</p>
                </div>

              </div>
            </div>
          </ModalBody>
        </Modal>




        {/* FlatICon Pop Up */}

        
         <IconSelectionPopUp 
          handleIconPopUp={handleIconPopUp} 
          saveIconName={saveIconName}
          openIconPopUp={openIconPopUp}
          widthForRestriction={128}
          heightForRestriction={128}
         />
        {/* Flat Icon Pop Up */}
            </section>
    </>


                    
  );
};

export default AttributesListing;
