import React from "react";
import "./appsettingModal.css";
import PhoneCodeModal from "./PhoneCodeModal";
const CountryDropdown = ({
  wrapperRef,
  settingsState,
  setSettingsState,
  handleCountryDropdown,
  togglephonecodemodal,
  handleOpenCoutryList,
  handleDropdownSearch,
  clearAllBeacons,
}) => {
  return (
    <div>
      <div
        className="form-group select-input align-items-center"
        ref={wrapperRef}
      >
        <div className="app-setting-phonecode">
          {settingsState.openCountryList && (
            <div className="dropdown-selectflag">
              <div className=" dropdown-selectflagtop">
                <input
                  className="dropdown-select-searchfleginput"
                  placeholder="Search..."
                  value={settingsState.dropdownSearchVal}
                  onChange={(e) => handleDropdownSearch(e)}
                />
                {settingsState.dropdownSearchVal.trim() && (
                  <p
                    onClick={(e) => {
                      setSettingsState({
                        dropdownSearchVal: "",
                      });
                    }}
                    className="clear-search"
                  >
                    Clear
                  </p>
                )}
              </div>
              <div className="common-countrydiv">
                <div className=" common-countryflagtype">
                  {settingsState.countryselectfield
                    .filter((name) =>
                      name.country_name
                        .toLowerCase()
                        .includes(settingsState.dropdownSearchVal.toLowerCase())
                    )
                    .map((res, i) => (
                      <div
                        className="country-line-flag-name-div"
                        key={i}
                        onClick={(e) => handleCountryDropdown(e, res)}
                      >
                        <div className="country-flag-and-name">
                          <img
                            src={
                              process.env.REACT_APP_Image_Path +
                              process.env.REACT_APP_SMALL_FLAG +
                              res.country_code.toLowerCase() +
                              ".png"
                            }
                            alt={`flag${i}`}
                          />
                          <p className="country-name">{res.country_name}</p>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          )}
          <div
            className="app-setting-flag-div"
            onClick={(e) => {
              clearAllBeacons();
              setSettingsState({
                promptOnExitDrawerOpen: false,
                forceAppUpdateModalOpen: false,
              });
              window.matchMedia("(max-width: 1200px)").matches
                ? togglephonecodemodal(e)
                : handleOpenCoutryList(e);
            }}
          >
            {settingsState.app_country ? (
              <img
                className="app-setting-country-flag"
                src={
                  process.env.REACT_APP_Image_Path +
                  process.env.REACT_APP_SMALL_FLAG +
                  settingsState.app_country.toLowerCase() +
                  ".png"
                }
                alt="country-flag"
              />
            ) : (
              <img
                className="app-setting-country-flag"
                src={
                  process.env.REACT_APP_Image_Path +
                  process.env.REACT_APP_SMALL_FLAG +
                  settingsState.user_country.toLowerCase() +
                  ".png"
                }
                alt="country-flag"
              />
            )}
            {settingsState.app_country ? (
              <>
                {settingsState.countryselectfield
                  .filter((name) => name.country_name.toLowerCase())
                  .map((res, i) =>
                    res.country_code === settingsState.app_country
                      ? res.country_name
                      : ""
                  )}
              </>
            ) : (
              <>
                {settingsState.countryselectfield
                  .filter((name) => name.country_name.toLowerCase())
                  .map((res, i) =>
                    res.country_code === settingsState.user_country
                      ? res.country_name
                      : ""
                  )}
              </>
            )}
          </div>
        </div>
      </div>
      <PhoneCodeModal
        settingsState={settingsState}
        togglephonecodemodal={togglephonecodemodal}
        setSettingsState={setSettingsState}
        handleDropdownSearch={handleDropdownSearch}
        handleCountryDropdown={handleCountryDropdown}
      />
    </div>
  );
};

export default CountryDropdown;
