import React, { Suspense, useContext } from "react";
import Header from "../Header";
import { Link } from "react-router-dom";
import Leftpanel from "../Leftpanel";
import { InnerFoot } from "../../Dashboard/Foot";
import { Spinner, Media } from "reactstrap";
import Verifyemailstatus from "../../Verification/Checkverification";
import { Apidata } from "./Context";
import {
  Troubleshootingapi,
  Troubleshootingapiwithoutcsck,
  Getappcredentailapi,
  Connectivity_History,
  Slowwebsiteapi,
  SpeedSlow,
} from "../../Api/Connectivity/Troubleshootingapi";
import "./troubleshoot.css";

// import { Error } from "../Reusable/Connectivityerror";
import Amsvideo from "../Reusable/Amsvideos";
import moment from "moment";
const History = React.lazy(() => import("./History"));

export default class Troubleshootingpage extends React.PureComponent {

  state = {
    spinner: true,
    historySpinner: false,
    ck: "",
    cs: "",
    sendwrl: "",
    showstatus: [],
    website_type: "",
    successcount: [],
    store_api_version: "",
    // loader: (
    //   <svg xmlns="" width="20" height="20" viewBox="0 0 24 24">
    //     <g
    //       id="Group_4474"
    //       data-name="Group 4474"
    //       transform="translate(-311 -254)"
    //     >
    //       <circle
    //         id="Ellipse_2803"
    //         data-name="Ellipse 2803"
    //         cx="12"
    //         cy="12"
    //         r="12"
    //         transform="translate(311 254)"
    //         fill="#c8cedb"
    //       />
    //       <path
    //         id="Path_8281"
    //         data-name="Path 8281"
    //         d="M-19252.922-4211.958l3.078,3.078,5.791-5.794"
    //         transform="translate(19571.58 4478.115)"
    //         fill="none"
    //         stroke="#fff"
    //         strokeWidth="2.5"
    //       />
    //     </g>
    //   </svg>
    // ),
    troubleshootloader: "d-none",
    loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
    result: "-",
    hit: false,
    buttontext: "Troubleshoot",
    disabledbutton: false,
    showstrip: false,
    plan_code: "",
    connectivity_history: "",
    is_connected: null,
    wp_username: null,
    wp_application_password: null,
    authorization_type: null,
    connection_to_establish: 0,
    is_trigger_slow_website_email: 1,
    is_verified: null,
    clientID: null,
    startTroubleshoot: false,
    tab: "results",
    mobileShowTroubleshoot: false,
    showTroubleshoot: false,
    historyDataForPreview: [],
    allHistoryData: {},
    // eachHistory
    selectedIndx: 0,
    isTextChange: false,
    appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
      ? true
      : false,
    failedVal:0,
    showFailedVal:false ,
  };
  troubleShootingFunction = () => {
    this.setState({
      showTroubleshoot:true ,
      startTroubleshoot: true,
      mobileShowTroubleshoot: true,
      troubleshootloader: "d-block",
      selectedIndx: null,
      tab: "results",
      isTextChange: true,
      showFailedVal:false ,
    })
    let statusobj = [];
    if (
      parseInt(localStorage.getItem("website_technology")) === 2
    ) {
      statusobj = [
        {
          is_plugin_installed: "",
          alias: "DNS Health",
          message: "-",
          response_time: "-",
          error_code: "",
          help_url: "",
          required: "",
        },
        {
          is_plugin_installed: "",
          alias: "HTTP Status",
          message: "-",
          response_time: "-",
          error_code: "",
          help_url: "",
          required: "",
        },
        {
          is_plugin_installed: "",
          alias: "Response Time",
          message: "-",
          response_time: "-",
          error_code: "",
          help_url: "",
          required: "",
        },
        {
          is_plugin_installed: "",
          alias: "Secure connectivity",
          message: "-",
          response_time: "-",
          error_code: "",
          help_url: "",
          required: "",
        },
      ];
    } else {
      parseInt(
        localStorage.getItem("website_technology")
      ) === 5
        ? this.state.authorization_type === 0
          ? (statusobj = [
            {
              is_plugin_installed: "",
              alias: "WordPress plugin installed",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "WordPress plugin activated",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "WordPress connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Secure connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Pretty links",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Page connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Post connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Post type connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Taxonomy connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
          ])
          : (statusobj = [
            {
              is_plugin_installed: "",
              alias: "WordPress plugin installed",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "WordPress plugin activated",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "WooCommerce connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Secure connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Pretty links",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias:
                // this.state.authorization_type === 2
                //   ?
                   "Access validation" ,
                  // : "CS & CK Validation",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias:
                // this.state.authorization_type === 2
                //   ? 
                  "Privilege verification",
                  // : "CS & CK Authorisation",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "HTTP authorisation",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Page connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Post connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Product connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Product category connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Post type connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Taxonomy connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "General settings connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Account settings connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
          ])
        : this.state.authorization_type === 2
          ? (statusobj = [
            {
              is_plugin_installed: "",
              alias: "WordPress plugin installed",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "WordPress plugin activated",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "WordPress connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Secure connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Pretty links",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Page connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Post connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Access validation",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Privilege verification",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Post type connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Taxonomy connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
          ])
          : (statusobj = [
            {
              is_plugin_installed: "",
              alias: "WordPress plugin installed",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "WordPress plugin activated",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "WordPress connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Secure connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Pretty links",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Page connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Post connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Post type connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Taxonomy connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
          ]);
    }

    this.setState({
      showstatus: statusobj,
      historyDataForPreview: statusobj,
      loader: (
        <Spinner
          className="trouble-spinner"
          animation="border"
          variant="primary"
          style={{
            height: "20px",
            width: "20px",
            color: "#3064F9",
            marginRight: "10px",
          }}
        />
      ),
      result: "Testing...",
      hit: false,
      disabledbutton: true,
      buttontext: "",
      showstrip: false,
    });
    Getappcredentailapi(this, () => {
      let websitetype = this.state.website_type;
      // this.setState({
      //   // isTextChange: false
      // })

      if (parseInt(
        localStorage.getItem("website_technology")
      ) === 5) {
        let params = {};
        if (this.state.authorization_type === 1) {
          params = {
            consumer_secret: this.state.cs,
            consumer_key: this.state.ck,
            store_authorization: 1,
            store_url: this.state.sendwrl,
            store_api_version: this.state.store_api_version,
          };
        }
        if (this.state.authorization_type === 2) {
          params = {
            store_wp_admin_username: this.state.wp_username,
            store_wp_admin_application_password:
              this.state.wp_application_password,
            store_authorization: this.state.authorization_type,
            store_url: this.state.sendwrl,
            store_api_version: this.state.store_api_version,
          };
        }
        if (this.state.authorization_type === 0) {
          params = {
            store_authorization: 0,
            store_url: this.state.sendwrl,
            store_api_version: this.state.store_api_version,
          };
        }
        if (this.state.authorization_type !== 0) {
          Troubleshootingapi(params, this, () => {
            window.scroll(0, 0);
            //Save connectivity hsitory

            const params = {
              app_id: parseInt(localStorage.getItem("Appid")),
              authorization_type: this.state.authorization_type,
              consumer_key:
                this.state.authorization_type === 1 ? this.state.ck : "",
              consumer_secret:
                this.state.authorization_type === 1 ? this.state.cs : "",
              wp_username:
                this.state.authorization_type === 2
                  ? this.state.wp_username
                  : "",
              wp_application_password:
                this.state.authorization_type === 2
                  ? this.state.wp_application_password
                  : "",
              connection_to_establish: this.state.connection_to_establish,
              result_summary: this.state.successcount.length,
              result_history: this.state.showstatus,
            };
            Connectivity_History(params, this);

            let newarray = this.state.showstatus
              .filter((res) => res.response_time > 3)
              .map((res) => {
                return res.response_time;
              });

            // const lessthanThreeCheck = (element) => element > 3;
            // const greatthanThree = newarray.some(lessthanThreeCheck);

            // Low speed strip bar condition
            if (newarray.length > 0) {
              this.setState({
                showstrip: true,
                //is_trigger_slow_website_email: 0,
              });
            }

            //  Email to notify slow speed

            if (
              newarray.length > 0 &&
              this.state.is_trigger_slow_website_email === false
            ) {
              const slowparam = {
                app_id: parseInt(localStorage.getItem("Appid")),
              };

              // Slowwebsiteapi(slowparam);
            }
          });
        } else {
          Troubleshootingapiwithoutcsck(params, this, () => {
            window.scroll(0, 0);

            //Save connectivity history in case of wordpress
            const params = {
              app_id: parseInt(localStorage.getItem("Appid")),
              authorization_type: this.state.authorization_type,
              consumer_key: "",
              consumer_secret: "",
              wp_username:
                this.state.authorization_type === 2
                  ? this.state.wp_username
                  : "",
              wp_application_password:
                this.state.authorization_type === 2
                  ? this.state.wp_application_password
                  : "",
              connection_to_establish: this.state.connection_to_establish,
              result_summary: this.state.successcount.length,
              result_history: this.state.showstatus,
              store_api_version: this.state.store_api_version,
            };
            Connectivity_History(params, this);

            let newarray = this.state.showstatus
              .filter((res) => res.response_time > 3)
              .map((res) => {
                return res.response_time;
              });

            // const lessthanThreeCheck = (element) => element > 3;
            // const greatthanThree = newarray.some(lessthanThreeCheck);

            // Low speed strip bar condition
            if (newarray.length > 0) {
              this.setState({
                showstrip: true,
                // is_trigger_slow_website_email: 0,
              });
            }

            //  Email to notify slow speed

            if (
              newarray.length > 0 &&
              this.state.is_trigger_slow_website_email === false
            ) {
              const slowparam = {
                app_id: parseInt(localStorage.getItem("Appid")),
              };
              // Slowwebsiteapi(slowparam);
            }
          });
        }
      } else {
        let params = {};
        if (this.state.authorization_type === 0) {
          params = {
            store_authorization: 0,
            store_url: this.state.sendwrl,
            store_api_version: this.state.store_api_version,
          };
        } else {
          params = {
            store_wp_admin_username: this.state.wp_username,
            store_wp_admin_application_password:
              this.state.wp_application_password,
            store_authorization: 2,
            store_url: this.state.sendwrl,
            store_api_version: this.state.store_api_version,
          };
        }

        Troubleshootingapiwithoutcsck(params, this, () => {
          window.scroll(0, 0);

          //Save connectivity history in case of wordpress
          const params = {
            app_id: parseInt(localStorage.getItem("Appid")),
            authorization_type: this.state.authorization_type,
            consumer_key: "",
            consumer_secret: "",
            wp_username:
              this.state.authorization_type === 2 ? this.state.wp_username : "",
            wp_application_password:
              this.state.authorization_type === 2
                ? this.state.wp_application_password
                : "",
            connection_to_establish: this.state.connection_to_establish,
            result_summary: this.state.successcount.length,
            result_history: this.state.showstatus,
          };
          Connectivity_History(params, this);

          let newarray = this.state.showstatus
            .filter((res) => res.response_time > 3)
            .map((res) => {
              return res.response_time;
            });

          // const lessthanThreeCheck = (element) => element > 3;
          // const greatthanThree = newarray.some(lessthanThreeCheck);

          // Low speed strip bar condition
          if (newarray.length > 0) {
            this.setState({
              showstrip: true,
              //is_trigger_slow_website_email: 0,
            });
          }
          if (
            newarray.length > 0 &&
            this.state.is_trigger_slow_website_email === false
          ) {
            const slowparam = {
              app_id: parseInt(localStorage.getItem("Appid")),
            };
            // Slowwebsiteapi(slowparam);
          }
        });
      }
    });

  };


  handleHistoryData = (res, i,failedVal) => {
  
    this.setState({
      historyDataForPreview: res.result_json,
      allHistoryData: res,
      mobileShowTroubleshoot: true,
      selectedIndx: i,
      showTroubleshoot:true ,
      failedVal:failedVal,
      showFailedVal:true,
    })
  }

  handlespeed = (e) => {
    this.setState({
      is_trigger_slow_website_email: e.target.checked
    })

    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      is_trigger_slow_website_email: e.target.checked === true ? 0 : 1,
    };
    // SpeedSlow(params);
  };

  handleMobileShowHistory = (e) => {

    this.setState({
      mobileShowTroubleshoot: false
    })
  }

  handleTabChange = (e, from) => {
    this.setState({
      tab: from
    })
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    // document.title = "Troubleshooting";
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
    {
      document.title = "Troubleshoot Logs | AppMySite";
    }
    else 
    {
      document.title = "Troubleshoot Logs |"+ ' ' +localStorage.getItem('agencyname');
    }
   
    this.loadingFun()

  }

  loadingFun = () => {
    Getappcredentailapi(this, () => {
      let websitetype = this.state.website_type;
   
      this.setState({
        historyDataForPreview: this.state.connectivity_history.length > 0 && this.state.connectivity_history[0].result_json,
        allHistoryData: this.state.connectivity_history.length > 0 && this.state.connectivity_history[0],
        isTextChange: false,
        failedVal:this.state.connectivity_history.length > 0?
        parseInt(this.state.connectivity_history[0].result_json.length)-parseInt(this.state.connectivity_history[0].result_summary)
        :0 ,
        showFailedVal:true ,
      })
      if (
        parseInt(localStorage.getItem("website_technology")) === 2
      ) {
        this.setState({
          showstatus: [
            {
              is_plugin_installed: "",
              alias: "DNS Health",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "HTTP Status",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Response Time",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
            {
              is_plugin_installed: "",
              alias: "Secure connectivity",
              message: "-",
              response_time: "-",
              error_code: "",
              help_url: "",
              required: "",
            },
          ],
        });
      } else if (
        parseInt(
          localStorage.getItem("website_technology")
        ) === 5
      ) {
        if (this.state.authorization_type === 0) {
          this.setState({
            showstatus: [
              {
                is_plugin_installed: "",
                alias: "WordPress plugin installed",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "WordPress plugin activated",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "WordPress connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Secure connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Pretty links",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Page connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Post connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Post type connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Taxonomy connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
            ],
          });
        } else {
          this.setState({
            showstatus: [
              {
                is_plugin_installed: "",
                alias: "WordPress plugin installed",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "WordPress plugin activated",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "WooCommerce connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Secure connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Pretty links",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias:
                  // this.state.authorization_type === 2
                  //   ?
                     "Access validation" ,
                    // : "CS & CK Validation",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias:
                  // this.state.authorization_type === 2
                  //   ? 
                    "Privilege verification",
                    // : "CS & CK Authorisation",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },

              {
                is_plugin_installed: "",
                alias: "HTTP authorisation",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Page connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Post connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Product connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Product category connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Post type connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Taxonomy connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
            ],
          });
        }
      } else {
        if (this.state.authorization_type === 2) {
          this.setState({
            showstatus: [
              {
                is_plugin_installed: "",
                alias: "WordPress plugin installed",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "WordPress plugin activated",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "WordPress connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Secure connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Pretty links",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Page connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Post connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Access validation",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Privilege verification",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Post type connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Taxonomy connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
            ],
          });
        } else {
          this.setState({
            showstatus: [
              {
                is_plugin_installed: "",
                alias: "WordPress plugin installed",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "WordPress plugin activated",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "WordPress connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Secure connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Pretty links",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Page connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Post connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },

              {
                is_plugin_installed: "",
                alias: "Post type connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
              {
                is_plugin_installed: "",
                alias: "Taxonomy connectivity",
                message: "-",
                response_time: "-",
                error_code: "",
                help_url: "",
                required: "",
              },
            ],
          });
        }
      }
    });
  }

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
  }

  render() {
    return (
      <>
        <div className="d-md-block">
          <Header customclass={"header-responsive-new"} />
        </div>

        {/* Mobile View */}

        {/* Mobile View End*/}
        <section className="dasboard_page mobile-display mt-md-0">


          <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
          <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
            localStorage.getItem("appSideBarCollapse") !== null &&
            localStorage.getItem("appSideBarCollapse") === "true") ?
            " right-panel-gap-expand" : ""}`}>
            {/* Email Verification */}
            <div className="d-none d-md-flex w-100">
              <Verifyemailstatus />
            </div>
            {/* Email Verification End*/}

            {


              this.state.mobileShowTroubleshoot === true &&
              <div className=" justify-content-between  align-items-center pl-3 pr-3 flex-wrap  notificationdivss mobilenotificationdiv" >
                <div >
                  <h6 className="m-0 p-0" onClick={(e) => this.handleMobileShowHistory(e)}>


                    <img

                      src={
                        require("../../assets/images/dashboard/goBackicon.png")
                          .default
                      }
                      alt="alert"
                      className="mr-2"
                    />
                    {
                      this.state.selectedIndx !== null && <span>{moment(this.state.allHistoryData.updated_at).format("D MMMM YYYY, HH:mm")} 
                      </span>}

                  </h6>
                </div>
                {
                  this.state.selectedIndx !== null && this.state.allHistoryData &&
                  Object.keys(this.state.allHistoryData).length === 0 &&
                  this.state.allHistoryData.constructor === Object &&
                  <div className="d-flex align-items-center">

                    <h5 className="m-0 p-0">
                      {this.state.allHistoryData.result_summary}

                      <span> /{this.state.allHistoryData.result_json.length} passed</span>
                    </h5>
                  </div>}
              </div>
            }

            {/* {
              this.state.showstrip === true &&
              <div className=" justify-content-between  align-items-center pl-3 pr-3 flex-wrap mb-3 notificationdiv4">
                <div className="notificationdiv1">
                  <h6 className="m-0 p-0">
                    <img
                      src={
                        require("../../assets/images/dashboard/exclamation.png")
                          .default
                      }
                      alt="alert"


                    />
                    It appears your website is slow. Your users may face
                    issues while browsing through your app.
                  </h6>
                </div>
                
              </div>
            } */}
            {/* {
              this.state.showstrip === true &&
              <div className=" justify-content-between  align-items-center pl-3 pr-3 flex-wrap mb-3 notificationdiv3 notification3padding">
                <div style={{ display: "flex" }}>
                  <div className="mobileimg">
                    <img
                      src={
                        require("../../assets/images/dashboard/exclamation.png")
                          .default
                      }

                      alt="alert"

                    />
                  </div>

                  
                </div>


              </div>

            } */}
            {
              !this.state.showTroubleshoot && 
            
            <div className='web-connectivity-head' style={{marginBottom: "5px" }}>
              <h1>Connectivity
              <p className="connectivity-tooltip">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
              <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                  <circle cx="9" cy="9" r="9" stroke="none"/>
                  <circle cx="9" cy="9" r="8.25" fill="none"/>
                </g>
                <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
              </g>
            </svg>
              <p className="connectivity-tooltipsubheader">
              Here, you can set up connectivity to your website. These connections enable the seamless transfer of data and content between your app and your data sources.
              </p>
            </p></h1>

            
            </div>
            }

        
            {/* //   this.state.showTroubleshoot &&  */}
            
            <div className="connectivity-mob-head">
            
            { !this.state.showTroubleshoot &&  <p>Troubleshoot</p> }
              {
                this.state.showTroubleshoot && 
              
              <div className="troubleshoot-filed">
              <p>Troubleshoot connectivity</p>
              {
                this.state.showFailedVal && 
              
              <p className="mob-troubleshoot-filed" style={{
                color:this.state.failedVal==0? "#1B2952":""
              }}>{this.state.failedVal} failed</p>
                } 
              </div>   
               }
              {
                ((this.state.connectivity_history === "" || this.state.connectivity_history.length === 0) &&
                window.matchMedia("(max-width: 1200px)").matches
                ) ?
                <></>
              :
              <div className=" troublebutton">
                
                <button
                  className="altbutspac"
                  // className={
                  //   this.state.disabledbutton === false
                  //     ? "takethere altbutspac"
                  //     : " altbutspac troubleshootingtime"
                  // }
                  onClick={(e) =>
                    this.troubleShootingFunction()
                  }
                  style={{ backgroundColor: localStorage.getItem('button_background_color'), border: '1px solid ' + localStorage.getItem('button_background_color'), color: localStorage.getItem('button_text_color'), "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'), "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'), "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor') }}

                // disabled={this.state.disabledbutton}
                >
                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    <div className={this.state.troubleshootloader}>
                    <div className="new-loader"></div>
                    </div>{" "}
                    <div className="varifytext">{this.state.buttontext}</div>
                  </div>

                </button>
              </div> }
            </div>
            
           
            <div className="consent-modal-tab" style={{marginTop: "0px", alignItems: "unset"}}>
            {
              this.state.showTroubleshoot &&    
              <h1>Troubleshoot connectivity</h1>
            }
            {
              !this.state.showTroubleshoot &&   
              <div style={{ height: "40px" }}>
              

                            {parseInt(localStorage.getItem("website_technology")) !==
                      2 &&
                  <Link 
                  to={"/app/connect/wp-plugin"}
                  style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? 
                  "#3064f9" : localStorage.getItem('agency_primary_color'),
                  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9"
                   : localStorage.getItem('agency_primary_color')}}
                    >Install plugin</Link>  }
                
                {/* { parseInt(localStorage.getItem("website_technology")) !== 2 &&
                  parseInt(localStorage.getItem("website_technology")) !== 3 ? */}
                  {parseInt(localStorage.getItem("website_technology")) !==
                      2 &&
                  <Link to={"/app/connect/wp-grant-access" }
                  style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? 
                  "#3064f9" : localStorage.getItem('agency_primary_color'),
                  "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#3064f9"
                   : localStorage.getItem('agency_primary_color')}}>Grant access</Link> }


                  <Link to={"/app/connect/troubleshoot-logs"}
                  className="active" 
                  style={{"border-bottom": localStorage.getItem('agencyid') == 1 ?
                   "2px solid #3064f9" : "2px solid "+ localStorage.getItem('agency_primary_color'),
                   "color": localStorage.getItem('agencyid') == 1 ? "#3064f9" : 
                   localStorage.getItem('agency_primary_color'),
                   "--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" : 
                   localStorage.getItem('agency_primary_color'),"--custom_color5":
                    localStorage.getItem('agencyid') == 1 ? "#3064f9" : 
                    localStorage.getItem('agency_primary_color')}} >Troubleshoot</Link>

                  <Link className="monitorLink" to={"/app/connect/monitor"}
                  style={{"--custom_color4": localStorage.getItem('agencyid') == 1 ? "#3064f9" 
                  : localStorage.getItem('agency_primary_color'),
                  "--custom_color5": 
                  localStorage.getItem('agencyid') == 1 ? "#3064f9" :
                   localStorage.getItem('agency_primary_color')}}>Monitor</Link>
                {/* : null
                } */}
              </div>
              }
              <div className="troublebutton">
              {this.state.showTroubleshoot &&  this.state.showFailedVal &&
      
              <p style={{
                color:this.state.failedVal==0? "#1B2952":""
              }}>{this.state.failedVal} failed</p> }
                <button
                  className="altbutspac"
                  // className={
                  //   this.state.disabledbutton === false
                  //     ? "takethere altbutspac"
                  //     : " altbutspac troubleshootingtime"
                  // }
                  onClick={(e) =>
                    this.troubleShootingFunction()
                  }
                  style={{ backgroundColor: localStorage.getItem('button_background_color'), border: '1px solid ' + localStorage.getItem('button_background_color'), color: localStorage.getItem('button_text_color'), "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'), "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'), "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor') }}

                // disabled={this.state.disabledbutton}
                >
                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    <div className={this.state.troubleshootloader}>
                    <div className="new-loader"></div>
                    </div>{" "}
                    <div className="varifytext">{this.state.buttontext}</div>
                  </div>

                </button>
              </div>



            </div>
         
            <div
              className=" headPannel"
            // style={{ minHeight: "850px" }}
            >
              {this.state.spinner || this.state.showTroubleshoot ? (
                <div className="connectivityspinn">
                  {/* <Spinner animation="border" variant="primary" /> */}
                  <div className="">
                    <svg xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                      width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                      <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          </animateTransform>
                        </circle>
                      </g>
                      <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          </animateTransform>
                        </circle>
                      </g>
                      <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>

                          </animateTransform>
                        </circle>
                      </g>
                      <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                          <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>

                          </animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                </div>
              ) : (


                <>


                  <div className="right_general  troubleshoot-portion pagetop">
                    {/* {this.state.showstrip && (
                      <div className="d-flex justify-content-between showwebstrip align-items-center pl-3 pr-3 flex-wrap mb-3">
                        <div>
                          <h6 className="m-0 p-0">
                            <img
                              src={
                                require("../../assets/images/dashboard/alertstrip.png")
                                  .default
                              }
                              alt="alert"
                              className="mr-1"
                            />
                            It appears your website is slow. Your users may face
                            issues while browsing through your app.
                          </h6>
                        </div>
                        <div className="d-flex align-items-center">
                          <Link className="mr-1" to="/performance/web-hosting">
                            Fix this issue
                          </Link>
                          <h5 className="m-0 p-0">
                            &amp; get blazing fast speeds
                          </h5>
                        </div>
                      </div>
                    )} */}

                    <div className="d-flex justify-content-between">
                      <div className="go_live w-100">
                        <div className="customiseappfeatures pt-0 pb-0">
                          <div>
                            {/* <div className="d-flex  align-items-center justify-content-between troublespac api-head headborder mobile-trouble-view ipad-view-troublehead ipad-connec-padding headingdiv">
                             

                              <div className="app_iconname d-md-block d-none installheader">
                        <div className="d-flex  align-items-center">
                          <h3 className="downloadheader">Troubleshoot connectivity</h3>
                       
                        </div>
                        <h5 className=" ipad-plugin-subtext headerh5">
                        Troubleshoot the connectivity between your
                         website and app to identify any issues.{" "}
                        
                        </h5>
                      </div>

                              <div className="trouble-tasks d-md-flex  align-items-center justify-content-between">
                              
                                <div className="fixed-button troublebutton">
                                  <button
                                  className="takethere altbutspac"
                                    // className={
                                    //   this.state.disabledbutton === false
                                    //     ? "takethere altbutspac"
                                    //     : " altbutspac troubleshootingtime"
                                    // }
                                    onClick={(e) =>
                                      this.troubleShootingFunction()
                                    }
                                    style={{backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor')}}
                        
                                    // disabled={this.state.disabledbutton}
                                  >
                                    <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <div className={this.state.troubleshootloader}>
                              <img src={this.state.loaderimage} alt="loader" />
                            </div>{" "}
                            <div className="varifytext">{this.state.buttontext}</div>
                          </div>
                                    
                                  </button>
                                </div>
                            
                              </div>
                            </div> */}

                            {
                              ((this.state.connectivity_history === "" || this.state.connectivity_history.length === 0) && this.state.startTroubleshoot === false)
                                //    ? <div className="Starttroubleshoot">
                                //        <div >

                                //         <img
                                //              src={
                                //                   require("../../assets/images/dashboard/troubleshootStart.png")
                                //               .default
                                //             }

                                //              alt="alert"

                                //              />
                                //        </div>
                                //   <div>
                                //    <h3 className="stratheader">Troubleshoot your app connectivity</h3>
                                //    <p className="startpara">Troubleshoot the connectivity between your website and app to identify any issues.</p>
                                //  </div>
                                //     </div>
                                ? <div className="troubleshoot-not-connected">
                                  <div className="conecttoapi nottrouble">
                                    <div className="text-center ">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/troubleshootnotactive.png")
                                            .default
                                        }

                                        alt="alert"

                                      />
                                    </div>

                                    {/* <div className="text-center  ">
                                    <p className="nottroubleheader">
                                    Troubleshoot your app connectivity
                                    </p>
                                  </div> */}

                                    <div className="text-center">
                                      <p className="nottroublepara">
                                        Nothing here at the moment. Come back later?
                                      </p>
                                    </div>

                                  </div>
                                  {
                                 window.matchMedia("(max-width: 1200px)").matches &&
                                  <div className=" troublebutton">
                
      
                                  <button
                                    className="altbutspac mob-troubleshoot-btn"
                                    // className={
                                    //   this.state.disabledbutton === false
                                    //     ? "takethere altbutspac"
                                    //     : " altbutspac troubleshootingtime"
                                    // }
                                    onClick={(e) =>
                                      this.troubleShootingFunction()
                                    }
                                    style={{ backgroundColor: localStorage.getItem('button_background_color'), border: '1px solid ' + localStorage.getItem('button_background_color'), color: localStorage.getItem('button_text_color'), "--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'), "--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'), "--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor') }}

                                  // disabled={this.state.disabledbutton}
                                  >
                                    <div className="d-flex align-items-center justify-content-center h-100 w-100">
                                      <div className={this.state.troubleshootloader}>
                                      <div className="new-loader"></div>
                                      </div>{" "}
                                      <div className="varifytext">{this.state.buttontext}</div>
                                    </div>

                                  </button>  
                                </div>  }

                                </div>

                                : <>
                                  {
                                    window.matchMedia("(max-width: 1200px)").matches && this.state.mobileShowTroubleshoot === true ? <></> :

                                      //  <div className="d-md-flex align-items-center justify-content-between troubleshoot-table trouble-head buttonspadding">
                                      //   <li className="d-flex test-item  align-items-center buttonsdivpadding">
                                      //     <h5 className={this.state.tab==="results"?"result":"resultnotclicked"} onClick={(e)=>this.handleTabChange(e,"results")}>Results</h5>

                                      //   </li>

                                      // </div>
                                      <></>
                                  }

                                  {


                                    this.state.mobileShowTroubleshoot === true &&
                                    <div className=" justify-content-between  align-items-center pr-3 flex-wrap  notificationdivss tabdatebox" >
                                      <div>
                                        <h6 className="m-0 p-0" onClick={(e) => this.handleMobileShowHistory(e)}>


                                          <img

                                            src={
                                              require("../../assets/images/dashboard/goBackicon.png")
                                                .default
                                            }
                                            alt="alert"
                                            className="mr-2"
                                          />
                                          {
                                            this.state.selectedIndx !== null && <span> {moment(this.state.allHistoryData.updated_at).format("D MMMM YYYY, HH:mm")} 
                                            </span>}

                                        </h6>
                                      </div>
                                      {
                                        this.state.selectedIndx !== null && this.state.allHistoryData &&
                                        Object.keys(this.state.allHistoryData).length === 0 &&
                                        this.state.allHistoryData.constructor === Object &&
                                        <div className="d-flex align-items-center">

                                          <h5 className="m-0 p-0">
                                            {this.state.allHistoryData.result_summary}

                                            <span> /{this.state.allHistoryData.result_json.length} passed</span>
                                          </h5>
                                        </div>}
                                    </div>
                                  }

                                  {
                                    this.state.tab === "settings" ?

                                      <div className=" justify-content-between  align-items-center  flex-wrap  settingpage" >
                                        <div>
                                          <h6 className="m-0 p-0">
                                            {/* <img
                              src={
                                require("../../assets/images/dashboard/goBackicon.png")
                                  .default
                              }
                              alt="alert"
                              className="mr-2"
                            /> */}
                                            If you don't want to be notified by email if your website is slow.
                                          </h6>
                                        </div>
                                        <div className="d-flex align-items-center settingtoggle">

                                          <h5 className="m-0 p-0">
                                            <label className="switch mobile-switch">
                                              <input
                                                type="checkbox"
                                                onChange={(e) => this.handlespeed(e)}
                                                checked={this.state.is_trigger_slow_website_email}
                                              />
                                              <div className="slider round"></div>
                                            </label>
                                          </h5>
                                        </div>
                                      </div>
                                      :

                                      <div className="troubleshootrow">
                                        {window.matchMedia("(max-width: 1200px)").matches && this.state.mobileShowTroubleshoot === true ? <></> :
                                          !this.state.showTroubleshoot &&
                                          <div className="col1">
                                            {this.state.historySpinner ? (
                                              <div className="spinn">
                                                <div className="newspinner">
                                                  <svg xmlns="http://www.w3.org/2000/svg"
                                                    xlink="http://www.w3.org/1999/xlink"
                                                    style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                                                    width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                                                    <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                      <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                        <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                                          style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                        </animateTransform>
                                                      </circle>
                                                    </g>
                                                    <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                      <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                                          style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                        </animateTransform>
                                                      </circle>
                                                    </g>
                                                    <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                      <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                                          style={{ animationplaystate: "running", animationdelay: "0s" }}>

                                                        </animateTransform>
                                                      </circle>
                                                    </g>
                                                    <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                      <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                                                        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                                                          style={{ animationplaystate: "running", animationdelay: "0s" }}>

                                                        </animateTransform>
                                                      </circle>
                                                    </g>
                                                  </svg>
                                                </div>
                                              </div>
                                            ) :
                                              <Apidata.Provider value={this.state.connectivity_history}>
                                                <Suspense fallback={""}>
                                                  <History
                                                    value={this.state.is_trigger_slow_website_email}
                                                    historyData={this.handleHistoryData}
                                                    selectedIndx={this.state.selectedIndx}
                                                  />
                                                </Suspense>
                                              </Apidata.Provider>}
                                          </div>}
                                        {
                                          window.matchMedia("(max-width: 1200px)").matches && this.state.mobileShowTroubleshoot === false ? <></> :
                                          this.state.showTroubleshoot &&  
                                          <div className="col2">

                                              <div className="trobleshoot-test align-items-center justify-content-between">
                                                <h3 className=""style={{width:"25%"}}>Test</h3>
                                                <h3 className="" style={{width:"35%"}}>Response</h3>
                                                <h3 className="" style={{width:"10.2%"}}>Response time</h3>
                                                <h3 className="" style={{width:"21.7%"}}>Result</h3>

                                              </div>
                                              {this.state.historyDataForPreview &&
                                                this.state.historyDataForPreview.map((res, i) => (
                                                  <div
                                                    className="tabletroubleshoot"
                                                    key={i}
                                                  >
                                                    <li className="d-flex itemfont align-items-center mobscreencol">
                                                      <div className="mobscreen">
                                                        <h3>Test item</h3>
                                                        <div >
                                                          <div className="parent-spinner-div mainspinner">
                                                            {Object.values(res)[0] === "" ? (
                                                              this.state.loader
                                                            ) : Object.values(res)[0] === "success" ? (
                                                              <>
                                                                <svg
                                                                  width="20"
                                                                  height="20"
                                                                  viewBox="0 0 22 22"
                                                                >
                                                                  <g
                                                                    id="Group_3556"
                                                                    data-name="Group 3556"
                                                                    transform="translate(-541 -339)"
                                                                  >
                                                                    <circle
                                                                      id="Ellipse_214"
                                                                      data-name="Ellipse 214"
                                                                      cx="11"
                                                                      cy="11"
                                                                      r="11"
                                                                      transform="translate(541 339)"
                                                                      fill="#47CE70"
                                                                    />
                                                                    <path
                                                                      id="Path_6039"
                                                                      data-name="Path 6039"
                                                                      d="M-19252.924-4211.612l3.471,3.469,6.529-6.531"
                                                                      transform="translate(19800.424 4561.173)"
                                                                      fill="none"
                                                                      stroke="#fff"
                                                                      strokeWidth="2.5"
                                                                    />
                                                                  </g>
                                                                </svg>
                                                              </>
                                                            ) : (
                                                              <>
                                                                {Object.values(res)[0] === "fail" ? (
                                                                  <svg
                                                                    width="20"
                                                                    height="20"
                                                                    viewBox="0 0 22 22"
                                                                  >
                                                                    <g
                                                                      id="Group_3558"
                                                                      data-name="Group 3558"
                                                                      transform="translate(-421 -394)"
                                                                    >
                                                                      <g
                                                                        id="Group_3557"
                                                                        data-name="Group 3557"
                                                                      >
                                                                        <g
                                                                          id="Ellipse_2258"
                                                                          data-name="Ellipse 2258"
                                                                          transform="translate(421 394)"
                                                                          fill="#fff1f1"
                                                                          stroke="#ff4848"
                                                                          strokeWidth="1.5"
                                                                        >
                                                                          <circle
                                                                            cx="11"
                                                                            cy="11"
                                                                            r="11"
                                                                            stroke="none"
                                                                          />
                                                                          <circle
                                                                            cx="11"
                                                                            cy="11"
                                                                            r="10.25"
                                                                            fill="none"
                                                                          />
                                                                        </g>
                                                                        <path
                                                                          id="Path_6876"
                                                                          data-name="Path 6876"
                                                                          d="M-19250.451-4207.143l7.529-7.531"
                                                                          transform="translate(19678.951 4616.174)"
                                                                          fill="none"
                                                                          stroke="#ff4848"
                                                                          strokeWidth="2"
                                                                        />
                                                                      </g>
                                                                      <path
                                                                        id="Path_7209"
                                                                        data-name="Path 7209"
                                                                        d="M-19242.924-4207.143l-7.529-7.531"
                                                                        transform="translate(19678.953 4616.174)"
                                                                        fill="none"
                                                                        stroke="#ff4848"
                                                                        strokeWidth="2"
                                                                      />
                                                                    </g>
                                                                  </svg>
                                                                ) : Object.values(res)[0] ===
                                                                  "timeout" ? (
                                                                  <svg
                                                                    width="20"
                                                                    height="20"
                                                                    viewBox="0 0 22 22"
                                                                  >
                                                                    <g
                                                                      id="Group_3558"
                                                                      data-name="Group 3558"
                                                                      transform="translate(-421 -394)"
                                                                    >
                                                                      <g
                                                                        id="Group_3557"
                                                                        data-name="Group 3557"
                                                                      >
                                                                        <g
                                                                          id="Ellipse_2258"
                                                                          data-name="Ellipse 2258"
                                                                          transform="translate(421 394)"
                                                                          fill="#fff1f1"
                                                                          stroke="#ff4848"
                                                                          strokeWidth="1.5"
                                                                        >
                                                                          <circle
                                                                            cx="11"
                                                                            cy="11"
                                                                            r="11"
                                                                            stroke="none"
                                                                          />
                                                                          <circle
                                                                            cx="11"
                                                                            cy="11"
                                                                            r="10.25"
                                                                            fill="none"
                                                                          />
                                                                        </g>
                                                                        <path
                                                                          id="Path_6876"
                                                                          data-name="Path 6876"
                                                                          d="M-19250.451-4207.143l7.529-7.531"
                                                                          transform="translate(19678.951 4616.174)"
                                                                          fill="none"
                                                                          stroke="#ff4848"
                                                                          strokeWidth="2"
                                                                        />
                                                                      </g>
                                                                      <path
                                                                        id="Path_7209"
                                                                        data-name="Path 7209"
                                                                        d="M-19242.924-4207.143l-7.529-7.531"
                                                                        transform="translate(19678.953 4616.174)"
                                                                        fill="none"
                                                                        stroke="#ff4848"
                                                                        strokeWidth="2"
                                                                      />
                                                                    </g>
                                                                  </svg>
                                                                ) : (
                                                                  <img
                                                                    src={
                                                                      require("../../assets/images/dashboard/warning.png")
                                                                        .default
                                                                    }
                                                                    alt="warning"
                                                                    className="mr-2"
                                                                    style={{ paddingRight: "2px" }}
                                                                  />
                                                                )}
                                                              </>
                                                            )}
                                                            <span className={this.state.isTextChange === false ? "before-troubleshoot" : "after-troubleshoot"}>{res.alias}</span>
                                                          </div>





                                                        
                                                          <div className="timediv">
                                                            <div>
                                                              {res.response_time === "-" ? (
                                                                <li className="d-md-flex  align-items-center col-2  timefont" style={{
                                                                  color: "#BCC1CE"
                                                                }}>
                                                                  -
                                                                </li>
                                                              ) : (
                                                                <>
                                                                  {parseFloat(res.response_time).toFixed(
                                                                    2
                                                                  ) > 3 ? (
                                                                    <li
                                                                      className=" d-md-flex  align-items-center timefont"
                                                                      style={{ color: "red" }}
                                                                    >
                                                                      {parseFloat(
                                                                        res.response_time
                                                                      ).toFixed(2)}{" "}
                                                                      s
                                                                    </li>
                                                                  ) : (
                                                                    <li
                                                                      className=" d-md-flex  align-items-center timefont"
                                                                      style={{ color: "#7782A1" }}
                                                                    >
                                                                      {parseFloat(
                                                                        res.response_time
                                                                      ).toFixed(2)}{" "}
                                                                      s
                                                                    </li>
                                                                  )}
                                                                </>
                                                              )}
                                                            </div>


                                                            <li className="d-flex  text-center resulttext " style={{ justifyContent: "end" }}>

                                                              {Object.values(res)[0] === "" ? (
                                                                <p className=" d-md-block testingtype" style={{ fontSize: "12px !important", color: "#BCC1CE" }}>
                                                                  {this.state.result}
                                                                </p>
                                                              ) : (
                                                                <>
                                                                  {Object.values(res)[0] ===
                                                                    "success" && (
                                                                      <>
                                                                        {" "}
                                                                        <p className="d-md-block successfont">
                                                                          {Object.values(res)[0]}
                                                                        </p>
                                                                        {res.help_url_alias &&
                                                                          <Media
                                                                            href={res.help_url}
                                                                            target="_blank"
                                                                            rel="noopener noreferrer"
                                                                            className="media1"
                                                                          >
                                                                            {res.help_url_alias}
                                                                          </Media>
                                                                        }
                                                                      </>
                                                                    )}
                                                                  {Object.values(res)[0] === "fail" && (
                                                                    <>
                                                                      <p className="  d-md-block failedfont">
                                                                        {Object.values(res)[0]}
                                                                      </p>
                                                                      <Media
                                                                        href={res.help_url}
                                                                        target="_blank"
                                                                        rel="noopener noreferrer"
                                                                        className="media1"
                                                                      >
                                                                        {res.help_url_alias}
                                                                      </Media>
                                                                    </>
                                                                  )}
                                                                  {Object.values(res)[0] ===
                                                                    "warning" && (
                                                                      <>
                                                                        <p
                                                                          className="d-md-block successfont"
                                                                          style={{
                                                                            color: "#ffc53a",
                                                                            borderColor: "#ffc53a",
                                                                          }}
                                                                        >
                                                                          {Object.values(res)[0]}
                                                                        </p>
                                                                        <Media
                                                                          href={res.help_url}
                                                                          target="_blank"
                                                                          rel="noopener noreferrer"
                                                                          className="media1"
                                                                        >
                                                                          {res.help_url_alias}
                                                                        </Media>
                                                                      </>
                                                                    )}
                                                                  {Object.values(res)[0] ===
                                                                    "timeout" && (
                                                                      <>
                                                                        <p className="d-md-block successfont">
                                                                          {Object.values(res)[0]}
                                                                        </p>
                                                                        <Media
                                                                          href={res.help_url}
                                                                          target="_blank"
                                                                          rel="noopener noreferrer"
                                                                          className="media1"
                                                                        >
                                                                          {res.help_url_alias}
                                                                        </Media>
                                                                      </>
                                                                    )}
                                                                </>
                                                              )}
                                                            </li>

                                                          </div>

                                                        </div>


                                                      </div>

                                                    </li>
                                                    <div className="messagemobile">
                                                      <h3>Message</h3>
                                                      {res.message === "-" ? (
                                                        window.matchMedia("(max-width: 1200px)").matches ?
                                                          <li className="d-md-flex   messagefont" > -
                                                            
                                                          </li>
                                                          :
                                                          <li className="d-flex   messagefont" >
                                                             -
                                                          </li>
                                                      ) : (
                                                        <li className="d-flex   messagefont " >
                                                          {res.message}
                                                        </li>
                                                      )}

                                                    </div>
                                                    <div className="mob-responsetimediv">
                                                      <h3>Response time</h3>
                                                    {res.response_time === "-" ? (
                                                      <li className=" timefont time1" >
                                                        -
                                                      </li>
                                                    ) : (
                                                      <>
                                                        {parseFloat(res.response_time).toFixed(
                                                          2
                                                        ) > 3 ? (
                                                          <li
                                                            className="  timefont"
                                                            style={{ color: "red" }}
                                                          >
                                                            {parseFloat(
                                                              res.response_time
                                                            ).toFixed(2)}{" "}
                                                            s
                                                          </li>
                                                        ) : (
                                                          <li
                                                            className="  timefont"
                                                            style={{ color: "#7782A1" }}
                                                          >
                                                            {parseFloat(
                                                              res.response_time
                                                            ).toFixed(2)}{" "}
                                                            s
                                                          </li>
                                                        )}
                                                      </>
                                                    )}
                                                </div>
                                                <div className="mob-resultdiv">
                                                <h3>Result</h3>
                                                    <li className={` text-center  ${this.state.isTextChange === false ? "successfailmessage" : "successfailmessagebe"}  `} >
                                                     
                                                      {Object.values(res)[0] === "" ? (
                                                        <p className=" successfontt" >
                                                          {this.state.result}
                                                        </p>
                                                      ) : (
                                                        <>
                                                          {Object.values(res)[0] ===
                                                            "success" && (
                                                              <>
                                                                {" "}
                                                                <p className=" successfont">
                                                                  {Object.values(res)[0]}
                                                                </p>
                                                                {/* {res.help_url_alias &&  */}
                                                                <Media
                                                                  href={res.help_url}
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                                  className="medias"
                                                                >
                                                                  {res.help_url_alias}
                                                                </Media>
                                                                {/* // } */}
                                                              </>
                                                            )}
                                                          {Object.values(res)[0] === "fail" && (
                                                            <>
                                                              <p className="  failedfont">
                                                                {Object.values(res)[0]}
                                                              </p>
                                                              <Media
                                                                href={res.help_url}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                className="medias"
                                                              >
                                                                {res.help_url_alias}
                                                              </Media>
                                                            </>
                                                          )}
                                                          {Object.values(res)[0] ===
                                                            "warning" && (
                                                              <>
                                                                <p
                                                                  className="app-version-draft-code  successfont"
                                                                  style={{
                                                                    color: "#ffc53a",
                                                                    borderColor: "#ffc53a",
                                                                  }}
                                                                >
                                                                  {Object.values(res)[0]}
                                                                </p>
                                                                <Media
                                                                  href={res.help_url}
                                                                  target="_blank"
                                                                  rel="noopener noreferrer"
                                                                  className="medias"
                                                                >
                                                                  {res.help_url_alias}
                                                                </Media>
                                                              </>
                                                            )}
                                                          {Object.values(res)[0] ===
                                                            "timeout" && (
                                                              <>
                                                                <p className=" successfont">
                                                                  {Object.values(res)[0]}
                                                                </p>
                                                                <div>
                                                                  <Media
                                                                    href={res.help_url}
                                                                    target="_blank"
                                                                    rel="noopener noreferrer"
                                                                    className="medias"
                                                                  >
                                                                    {res.help_url_alias}
                                                                  </Media>
                                                                </div>
                                                              </>
                                                            )}
                                                        </>
                                                      )}
                                                    </li>
                                                    </div>
                                                  </div>
                                                ))}
                                            </div>
                                        }
                                      </div>
                                  }

                                </>}
                          </div>

                        </div>
                      </div>
                    </div>
                    {/* } */}
                  </div>
                  {/* History Table */}



                  {/* History Table End*/}
                </>
              )}
            </div>
            {/* {InnerFoot()} */}
          </div>
        </section>
      </>
    );
  }
}
