import axios from "axios";
import S3 from 'react-aws-s3';
import {errortoast} from '../../Dashboard/Toaster';
import { CustomS3Client } from "../../utils/S3utils";
const Url = process.env.REACT_APP_API_URL + "api/app/";


export const Publishget= (params, setData, setAppid, setSpinner, setlast_successful_build, setis_build_process, setpublishing_logs, setCode, setplan_code, setaddon_code , setreseller_package_id, setnew, load, setLoader, setsubmit, setEnableScreen, setDeviceType, setsubmitreview, setsubmitreviewloader) => {
  
  const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    axios.get(Url + "get-ios-publishing-data/" + params.app_id + "&timestamp=" + new Date().getTime(),{
      headers: headers
        }).then(res => {
          if(res.data.code === 200){
           
              setData(res.data.data);
              setAppid(res.data.data === null ? "" : res.data.data.apple_id);
              setlast_successful_build(res.data.last_successful_build);
              setis_build_process(res.data.is_build_process);
              setpublishing_logs(res.data.publishing_logs);
              setCode(res.data.code);
              setnew(res.data.data === null ? "" : res.data.data.whats_new === null ? "" : res.data.data.whats_new);
              setSpinner(false);
              setLoader("d-none");
              setsubmitreviewloader("d-none");
              setsubmit("Submit");
              setsubmitreview("Submit for review");
              
              setEnableScreen(res.data.is_enable_screen);
              setDeviceType(res.data.device_type);
              if(res.data.data !== null){
                if(res.data.data.status === 1 || res.data.data.status === 2){
                  load(true);
                }
              }
            
          }
          else if(res.data.code === 203){
           
              setCode(res.data.code);
              setplan_code(res.data.plan_code);
              setreseller_package_id(res.data.reseller_package_id);
              setSpinner(false);
              setaddon_code(res.data.addon_code);
          }
        })
       
   
}

export const CreateConnection = (params, setLoader, setsubmit, context) => {
  
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': "Bearer " + localStorage.getItem("amstoken"),
    }
    
    axios.post(Url + "ios-publishing-data",params,{
      headers: headers
        }).then(res => {
          if(res.data.code === 200){
            setLoader("d-none");
            setsubmit("Save");
            context.setPopup(!context.opencreationpopup);
            context.load()
          }else{
          }
       }).catch(error => {
       
          errortoast("Something went wrong. Try again after sometime.")
    })
   
}
export const Publishform = (params, setLoader, setsubmit, load, setsubmitreview, setsubmitreviewloader) => {
  if(params.submit_for_review === 0){
    setLoader("d-block");
    setsubmit("");
  }else{
    setsubmitreviewloader("d-block");
    setsubmitreview("");
  }
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': "Bearer " + localStorage.getItem("amstoken"),
  }
  
  axios.post(Url + "ios-publishing-data",params,{
    headers: headers
      }).then(res => {
        if(res.data.code === 200){
          
          //hit get api
          load(false);
          
        }else{
          if(params.submit_for_review === 0){
            setLoader("d-none");
            setsubmit("Submit");
          }else{
            setsubmitreviewloader("d-none");
            setsubmitreview("Submit for review");
          }
          
        }
     }).catch(error => {
     
        errortoast("Something went wrong. Try again after sometime.")
  })
 
}

export const Certificate= (params, setcertificates3name, setdisabledsubmitbutton) => {
  
  function dataURLtoFileoriginal(dataurl, filename) {
    var arr = dataurl.split(','),
        //mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new Blob([u8arr], { type: "application/p8" });
  }
  let originalfile = dataURLtoFileoriginal(params.original,'hello.p8');
  const oriconfig = {
    bucketName: process.env.REACT_APP_bucketName,
    dirName: params.original_path.replace(/\/$/, ""), /* optional */
    region: process.env.REACT_APP_region,
    accessKeyId: process.env.REACT_APP_accessKeyId,
    secretAccessKey: process.env.REACT_APP_secretAccessKey,
    s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, ""), /* optional */
  }
  // const ReactS3original = new S3(oriconfig);
  const customS3Client = new CustomS3Client(oriconfig);
  const originalFileName = params.imgname;
  customS3Client.uploadFile(originalfile,originalFileName).then(data => {
        if(data.status === 204){
          setcertificates3name(data.key.split("/").pop());
          setdisabledsubmitbutton(false)
        }
  }
  ).catch(err => {
        errortoast("Something went wrong. Try again after sometime.")
      })
}