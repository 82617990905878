import React from 'react'

const Error = () => {
  return (
    <svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13">
      <path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848" />
      <path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1" />
      <path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1" />
    </svg>
  )
}

export default Error;