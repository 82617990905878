import React from 'react'

const GridView = ({productState, handleStateChange}) => {
  return (
    <svg
    onClick={(e, from) =>
      handleStateChange("toggleListGridView", "grid")
    }
    className={
      productState.tempShowGridView
        ? "toggle-product-svg"
        : "nottoggle-product-svg"
    }
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g
      id="Group_38250"
      data-name="Group 38250"
      transform="translate(0.222)"
    >
      <g
        id="Rectangle_4592"
        data-name="Rectangle 4592"
        transform="translate(-0.222)"
        fill="#fff"
        stroke=""
        stroke-width="2"
      >
        <rect width="9" height="9" rx="1" stroke="none" />
        <rect
          x="1"
          y="1"
          width="7"
          height="7"
          fill="none"
        />
      </g>
      <g
        id="Rectangle_4595"
        data-name="Rectangle 4595"
        transform="translate(-0.222 11)"
        fill="#fff"
        stroke=""
        stroke-width="2"
      >
        <rect width="9" height="9" rx="1" stroke="none" />
        <rect
          x="1"
          y="1"
          width="7"
          height="7"
          fill="none"
        />
      </g>
      <g
        id="Rectangle_4593"
        data-name="Rectangle 4593"
        transform="translate(10.778)"
        fill="#fff"
        stroke=""
        stroke-width="2"
      >
        <rect width="9" height="9" rx="1" stroke="none" />
        <rect
          x="1"
          y="1"
          width="7"
          height="7"
          fill="none"
        />
      </g>
      <g
        id="Rectangle_4594"
        data-name="Rectangle 4594"
        transform="translate(10.778 11)"
        fill="#fff"
        stroke=""
        stroke-width="2"
      >
        <rect width="9" height="9" rx="1" stroke="none" />
        <rect
          x="1"
          y="1"
          width="7"
          height="7"
          fill="none"
        />
      </g>
    </g>
  </svg>
  )
}

export default GridView