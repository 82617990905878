import React from "react";

function CategoryPreview({ previewData }) {
  return (
    <div
      className="tagsetting-preview"
      style={{
        background: previewData.bgColor,
      }}
    >
      <div
        className="catsetting-preview-header"
        style={{
          background: "#ffffff",
        }}
      >
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14.706"
            height="12.513"
            viewBox="0 0 14.706 12.513"
          >
            <g
              id="Group_38211"
              data-name="Group 38211"
              transform="translate(0.707 0.354)"
            >
              <g
                id="Group_2097"
                data-name="Group 2097"
                transform="translate(0)"
              >
                <path
                  id="Path_6037"
                  data-name="Path 6037"
                  d="M0,5.9,5.9,0l5.9,5.9"
                  transform="translate(0 11.806) rotate(-90)"
                  fill="none"
                  stroke="#333"
                  stroke-width="1"
                />
              </g>
              <path
                id="Path_9120"
                data-name="Path 9120"
                d="M-13838.828-23465.541h14"
                transform="translate(13838.826 23471.443)"
                fill="none"
                stroke="#333"
                stroke-width="1"
              />
            </g>
          </svg>
          <h4>Categories</h4>
        </div>

        <div>
          {previewData.showSearchInHeader ? (
            <svg
              id="Group_39221"
              data-name="Group 39221"
              xmlns="http://www.w3.org/2000/svg"
              width="13.822"
              height="13.822"
              viewBox="0 0 13.822 13.822"
            >
              <g
                id="Ellipse_246"
                data-name="Ellipse 246"
                transform="translate(12.351) rotate(90)"
                fill="none"
                stroke="#323232"
                stroke-width="1"
              >
                <ellipse
                  cx="6.175"
                  cy="6.176"
                  rx="6.175"
                  ry="6.176"
                  stroke="none"
                />
                <ellipse
                  cx="6.175"
                  cy="6.176"
                  rx="5.675"
                  ry="5.676"
                  fill="none"
                />
              </g>
              <path
                id="Path_6064"
                data-name="Path 6064"
                d="M0,4.508V0"
                transform="translate(13.468 13.468) rotate(135)"
                fill="none"
                stroke="#323232"
                stroke-width="1"
              />
            </svg>
          ) : (
            <></>
          )}
          {previewData.showBookmarkInHeader ? (
            <svg
              style={{ marginLeft: "16px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="12.252"
              height="15.293"
              viewBox="0 0 12.252 15.293"
            >
              <path
                id="Path_106343"
                data-name="Path 106343"
                d="M9.794,13.793a.932.932,0,0,1-.614-.235L4.626,9.566.072,13.558a.932.932,0,0,1-.614.235.951.951,0,0,1-.67-.283A1,1,0,0,1-1.5,12.8V.5a1.953,1.953,0,0,1,1.9-2H8.857a1.953,1.953,0,0,1,1.9,2V12.8A.979.979,0,0,1,9.794,13.793ZM4.626,8.124l5.053,4.429V.5a.87.87,0,0,0-.822-.91H.4A.87.87,0,0,0-.427.5V12.553Z"
                transform="translate(1.5 1.5)"
                fill="#212121"
              />
            </svg>
          ) : (
            <></>
          )}
        </div>
      </div>
      {previewData.catLayout === "layout-1" ? (
        <>
          {previewData.categoryData &&
            previewData.categoryData.map((data1, indx) => {
              return previewData.catImgShape === "sharp_corner" ? (
                <div className="category-layout-one">
                  <div className="cat-thumb-one">
                    {previewData.showCatImg ? (
                      <div
                        className="cat-setting-img-cover"
                        style={{
                          background: previewData.showCatImg
                            ? `url(${
                                data1.content.portal_value.source_file
                                  ? data1.content.portal_value.source_file.indexOf(
                                      "https"
                                    ) > -1
                                    ? data1.content.portal_value.source_file
                                    : process.env.REACT_APP_Image_Path +
                                      localStorage.getItem("user_id") +
                                      "/" +
                                      localStorage.getItem("Appid") +
                                      process.env.REACT_APP_CMS_Media +
                                      data1.content.portal_value.source_file
                                  : previewData.noCatImg
                              })`
                            : previewData.bgColor,
                          backgroundSize: previewData.showCenterCropImages
                            ? "cover"
                            : "contain",
                          height: previewData.calculateHeight(
                            previewData.catImgRatio,
                            48
                          ),
                          maxHeight: previewData.calculateHeight(
                            previewData.catImgRatio,
                            48
                          ),
                          minHeight: previewData.calculateHeight(
                            previewData.catImgRatio,
                            48
                          ),
                        }}
                      ></div>
                    ) : (
                      <></>
                    )}

                    <p style={{ color: previewData.textColor }}>{data1.name}</p>
                  </div>
                  {data1.child && data1.child.length > 0 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.259"
                      height="10.367"
                      viewBox="0 0 12.259 10.367"
                    >
                      <g
                        id="Group_42223"
                        data-name="Group 42223"
                        transform="translate(0 0.354)"
                        opacity="0.5"
                      >
                        <path
                          id="Path_118878"
                          data-name="Path 118878"
                          d="M-384.4-3200.5l4.83,4.83-4.83,4.83"
                          transform="translate(391.118 3200.5)"
                          fill="none"
                          // stroke="#1a1a1a"
                          stroke={previewData.textColor}
                          stroke-width="1"
                        />
                        <path
                          id="Path_118879"
                          data-name="Path 118879"
                          d="M-643.23-3185.5H-654.5"
                          transform="translate(654.5 3190.33)"
                          fill="none"
                          // stroke="#1a1a1a"
                          stroke={previewData.textColor}
                          stroke-width="1"
                        />
                      </g>
                    </svg>
                  )}
                </div>
              ) : previewData.catImgShape === "rounded_corner" ? (
                <div className="category-layout-one">
                  <div className="cat-thumb-one">
                    {previewData.showCatImg ? (
                      <div
                        className="cat-setting-img-cover"
                        style={{
                          background: previewData.showCatImg
                            ? `url(${
                                data1.content.portal_value.source_file
                                  ? data1.content.portal_value.source_file.indexOf(
                                      "https"
                                    ) > -1
                                    ? data1.content.portal_value.source_file
                                    : process.env.REACT_APP_Image_Path +
                                      localStorage.getItem("user_id") +
                                      "/" +
                                      localStorage.getItem("Appid") +
                                      process.env.REACT_APP_CMS_Media +
                                      data1.content.portal_value.source_file
                                  : previewData.noCatImg
                              })`
                            : previewData.bgColor,
                          backgroundSize: previewData.showCenterCropImages
                            ? "cover"
                            : "contain",
                          borderRadius: "8px",
                          height: previewData.calculateHeight(
                            previewData.catImgRatio,
                            48
                          ),
                          maxHeight: previewData.calculateHeight(
                            previewData.catImgRatio,
                            48
                          ),
                          minHeight: previewData.calculateHeight(
                            previewData.catImgRatio,
                            48
                          ),
                        }}
                      ></div>
                    ) : (
                      <></>
                    )}
                    <p style={{ color: previewData.textColor }}>{data1.name}</p>
                  </div>
                  {data1.child && data1.child.length > 0 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.259"
                      height="10.367"
                      viewBox="0 0 12.259 10.367"
                    >
                      <g
                        id="Group_42223"
                        data-name="Group 42223"
                        transform="translate(0 0.354)"
                        opacity="0.5"
                      >
                        <path
                          id="Path_118878"
                          data-name="Path 118878"
                          d="M-384.4-3200.5l4.83,4.83-4.83,4.83"
                          transform="translate(391.118 3200.5)"
                          fill="none"
                          // stroke="#1a1a1a"
                          stroke={previewData.textColor}
                          stroke-width="1"
                        />
                        <path
                          id="Path_118879"
                          data-name="Path 118879"
                          d="M-643.23-3185.5H-654.5"
                          transform="translate(654.5 3190.33)"
                          fill="none"
                          // stroke="#1a1a1a"
                          stroke={previewData.textColor}
                          stroke-width="1"
                        />
                      </g>
                    </svg>
                  )}
                </div>
              ) : (
                <div className="category-layout-one">
                  <div className="cat-thumb-one">
                    {previewData.showCatImg ? (
                      <div
                        className="cat-setting-img-cover"
                        style={{
                          background: previewData.showCatImg
                            ? `url(${
                                data1.content.portal_value.source_file
                                  ? data1.content.portal_value.source_file.indexOf(
                                      "https"
                                    ) > -1
                                    ? data1.content.portal_value.source_file
                                    : process.env.REACT_APP_Image_Path +
                                      localStorage.getItem("user_id") +
                                      "/" +
                                      localStorage.getItem("Appid") +
                                      process.env.REACT_APP_CMS_Media +
                                      data1.content.portal_value.source_file
                                  : previewData.noCatImg
                              })`
                            : previewData.bgColor,
                          backgroundSize: previewData.showCenterCropImages
                            ? "cover"
                            : "contain",
                          borderRadius: "100%",
                          height: previewData.calculateHeight(
                            previewData.catImgRatio,
                            48
                          ),
                          maxHeight: previewData.calculateHeight(
                            previewData.catImgRatio,
                            48
                          ),
                          minHeight: previewData.calculateHeight(
                            previewData.catImgRatio,
                            48
                          ),
                        }}
                      ></div>
                    ) : (
                      <></>
                    )}
                    <p style={{ color: previewData.textColor }}>{data1.name}</p>
                  </div>
                  {data1.child && data1.child.length > 0 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12.259"
                      height="10.367"
                      viewBox="0 0 12.259 10.367"
                    >
                      <g
                        id="Group_42223"
                        data-name="Group 42223"
                        transform="translate(0 0.354)"
                        opacity="0.5"
                      >
                        <path
                          id="Path_118878"
                          data-name="Path 118878"
                          d="M-384.4-3200.5l4.83,4.83-4.83,4.83"
                          transform="translate(391.118 3200.5)"
                          fill="none"
                          // stroke="#1a1a1a"
                          stroke={previewData.textColor}
                          stroke-width="1"
                        />
                        <path
                          id="Path_118879"
                          data-name="Path 118879"
                          d="M-643.23-3185.5H-654.5"
                          transform="translate(654.5 3190.33)"
                          fill="none"
                          // stroke="#1a1a1a"
                          stroke={previewData.textColor}
                          stroke-width="1"
                        />
                      </g>
                    </svg>
                  )}
                </div>
              );
            })}
        </>
      ) : (
        <></>
      )}

      {previewData.catLayout === "layout-2" ? (
        <div className="category-layout-two">
          {previewData.categoryData &&
            previewData.categoryData.map((data1) => {
              return parseInt(previewData.column) === 2 ? (
                <div
                  className="cat-thumb-two"
                  style={{
                    width: "131px",
                  }}
                >
                  {previewData.showCatImg ? (
                    <div
                      className="cat-setting-img-cover"
                      style={{
                        background: previewData.showCatImg
                          ? `url(${
                              data1.content.portal_value.source_file
                                ? data1.content.portal_value.source_file.indexOf(
                                    "https"
                                  ) > -1
                                  ? data1.content.portal_value.source_file
                                  : process.env.REACT_APP_Image_Path +
                                    localStorage.getItem("user_id") +
                                    "/" +
                                    localStorage.getItem("Appid") +
                                    process.env.REACT_APP_CMS_Media +
                                    data1.content.portal_value.source_file
                                : previewData.noCatImg
                            })`
                          : previewData.bgColor,
                        backgroundSize: previewData.showCenterCropImages
                          ? "cover"
                          : "contain",
                        borderRadius:
                          previewData.catImgShape === "sharp_corner"
                            ? "0px"
                            : previewData.catImgShape === "rounded_corner"
                            ? "8px"
                            : "100%",
                        width: "131px",
                        height: previewData.calculateHeight(
                          previewData.catImgRatio,
                          131
                        ),
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                  <p style={{ color: previewData.textColor }}>{data1.name}</p>
                </div>
              ) : parseInt(previewData.column) === 3 ? (
                <div
                  className="cat-thumb-two"
                  style={{
                    width: "84px",
                  }}
                >
                  {previewData.showCatImg ? (
                    <div
                      className="cat-setting-img-cover"
                      style={{
                        background: previewData.showCatImg
                          ? `url(${
                              data1.content.portal_value.source_file
                                ? data1.content.portal_value.source_file.indexOf(
                                    "https"
                                  ) > -1
                                  ? data1.content.portal_value.source_file
                                  : process.env.REACT_APP_Image_Path +
                                    localStorage.getItem("user_id") +
                                    "/" +
                                    localStorage.getItem("Appid") +
                                    process.env.REACT_APP_CMS_Media +
                                    data1.content.portal_value.source_file
                                : previewData.noCatImg
                            })`
                          : previewData.bgColor,
                        backgroundSize: previewData.showCenterCropImages
                          ? "cover"
                          : "contain",
                        borderRadius:
                          previewData.catImgShape === "sharp_corner"
                            ? "0px"
                            : previewData.catImgShape === "rounded_corner"
                            ? "8px"
                            : "100%",
                        width: "84px",
                        height: previewData.calculateHeight(
                          previewData.catImgRatio,
                          84
                        ),
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                  <p style={{ color: previewData.textColor }}>{data1.name}</p>
                </div>
              ) : (
                <div
                  className="cat-thumb-two"
                  style={{
                    width: "60px",
                  }}
                >
                  {previewData.showCatImg ? (
                    <div
                      className="cat-setting-img-cover"
                      style={{
                        background: previewData.showCatImg
                          ? `url(${
                              data1.content.portal_value.source_file
                                ? data1.content.portal_value.source_file.indexOf(
                                    "https"
                                  ) > -1
                                  ? data1.content.portal_value.source_file
                                  : process.env.REACT_APP_Image_Path +
                                    localStorage.getItem("user_id") +
                                    "/" +
                                    localStorage.getItem("Appid") +
                                    process.env.REACT_APP_CMS_Media +
                                    data1.content.portal_value.source_file
                                : previewData.noCatImg
                            })`
                          : previewData.bgColor,
                        backgroundSize: previewData.showCenterCropImages
                          ? "cover"
                          : "contain",
                        borderRadius:
                          previewData.catImgShape === "sharp_corner"
                            ? "0px"
                            : previewData.catImgShape === "rounded_corner"
                            ? "8px"
                            : "100%",
                        width: "60px",
                        height: previewData.calculateHeight(
                          previewData.catImgRatio,
                          60
                        ),
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                  <p style={{ color: previewData.textColor }}>{data1.name}</p>
                </div>
              );
            })}
        </div>
      ) : (
        <></>
      )}

      {previewData.catLayout === "layout-3" ? (
        <div className="category-layout-three"
        style={{
          alignItems:(previewData.showCatImg||previewData.catImgOverlayColor)?"center":""
        }}
        
        >
          {previewData.categoryData &&
            previewData.categoryData.map((data1) => {
              return parseInt(previewData.column) === 2 ? (
                <div 
                className="cat-thumb-three cat-heights-one"
                style={{
                  width: "131px",
                  height: (previewData.showCatImg||previewData.catImgOverlayColor)?"":"max-content",
                }}
                >
                  {
                    (previewData.showCatImg||previewData.catImgOverlayColor)?
                  
                  <div
                    className="cat-setting-img-cover"
                    style={{
                      background: previewData.showCatImg
                        ? `url(${
                            data1.content.portal_value.source_file
                              ? data1.content.portal_value.source_file.indexOf(
                                  "https"
                                ) > -1
                                ? data1.content.portal_value.source_file
                                : process.env.REACT_APP_Image_Path +
                                  localStorage.getItem("user_id") +
                                  "/" +
                                  localStorage.getItem("Appid") +
                                  process.env.REACT_APP_CMS_Media +
                                  data1.content.portal_value.source_file
                              : previewData.noCatImg
                          })`
                        : "",
          
                      borderRadius:
                        previewData.catImgShape === "sharp_corner"
                          ? "0px"
                          : previewData.catImgShape === "rounded_corner"
                          ? "8px"
                          : "100%",
                      height: previewData.calculateHeight(
                        previewData.catImgRatio,
                        131
                      ),
                      backgroundSize:previewData.showCatImg 
                      ? previewData.showCenterCropImages
                      ? "cover"
                      : "contain"
                      :"",
                    }}
                  >
                    {previewData.catImgOverlayColor && (
                      <div
                        className="cat-layout-tint"
                        style={{
                          background: previewData.catImgOverlayColor,
                          borderRadius:
                            previewData.catImgShape === "sharp_corner"
                              ? "0px"
                              : previewData.catImgShape === "rounded_corner"
                              ? "8px"
                              : "100%",
                        }}
                      ></div>
                    )}
                  </div>
                  :
                  <></>
            }

                  <p style={{ color: previewData.textColor,position:(previewData.showCatImg||previewData.catImgOverlayColor)?"absolute":"relative" }}>{data1.name}</p>
                </div>
              ) : parseInt(previewData.column) === 3 ? (
                <div className="cat-thumb-three cat-heights-two"
                style={{
                  width: "84px",
                  height: (previewData.showCatImg||previewData.catImgOverlayColor)?"":"max-content",
                }}
                >
                        {
                    (previewData.showCatImg||previewData.catImgOverlayColor)?
                  <div
                    className="cat-setting-img-cover 33333"
                    style={{
                      background: previewData.showCatImg
                        ? `url(${
                            data1.content.portal_value.source_file
                              ? data1.content.portal_value.source_file.indexOf(
                                  "https"
                                ) > -1
                                ? data1.content.portal_value.source_file
                                : process.env.REACT_APP_Image_Path +
                                  localStorage.getItem("user_id") +
                                  "/" +
                                  localStorage.getItem("Appid") +
                                  process.env.REACT_APP_CMS_Media +
                                  data1.content.portal_value.source_file
                              : previewData.noCatImg
                          })`
                        : "",
                      
                      borderRadius:
                        previewData.catImgShape === "sharp_corner"
                          ? "0px"
                          : previewData.catImgShape === "rounded_corner"
                          ? "8px"
                          : "100%",
                      height: previewData.calculateHeight(
                        previewData.catImgRatio,
                        84
                      ),
                      backgroundSize:previewData.showCatImg 
                      ? previewData.showCenterCropImages
                      ? "cover"
                      : "contain"
                      :"",
                    }}
                  >
                    {previewData.catImgOverlayColor && (
                      <div
                        className="cat-layout-tint"
                        style={{
                          background: previewData.catImgOverlayColor,
                          borderRadius:
                            previewData.catImgShape === "sharp_corner"
                              ? "0px"
                              : previewData.catImgShape === "rounded_corner"
                              ? "8px"
                              : "100%",
                        }}
                      ></div>
                    )}
                  </div>
                  :
                  <></>
            }

                  <p style={{ color: previewData.textColor,position:(previewData.showCatImg||previewData.catImgOverlayColor)?"absolute":"relative" }}>{data1.name}</p>
                </div>
              ) : (
                <div className="cat-thumb-three cat-heights-three"
                style={{
                  width: "60px",
                  height: (previewData.showCatImg||previewData.catImgOverlayColor)?"":"max-content",
                }}
                >
                        {
                    (previewData.showCatImg||previewData.catImgOverlayColor)?
                  <div
                    className="cat-setting-img-cover"
                    style={{
                      background: previewData.showCatImg
                        ? `url(${
                            data1.content.portal_value.source_file
                              ? data1.content.portal_value.source_file.indexOf(
                                  "https"
                                ) > -1
                                ? data1.content.portal_value.source_file
                                : process.env.REACT_APP_Image_Path +
                                  localStorage.getItem("user_id") +
                                  "/" +
                                  localStorage.getItem("Appid") +
                                  process.env.REACT_APP_CMS_Media +
                                  data1.content.portal_value.source_file
                              : previewData.noCatImg
                          })`
                        : "",
                      borderRadius:
                        previewData.catImgShape === "sharp_corner"
                          ? "0px"
                          : previewData.catImgShape === "rounded_corner"
                          ? "8px"
                          : "100%",
   
                      height: previewData.calculateHeight(
                        previewData.catImgRatio,
                        60
                      ),
                      backgroundSize:previewData.showCatImg 
                      ? previewData.showCenterCropImages
                      ? "cover"
                      : "contain"
                      :"",
                    }}
                  >
                    {previewData.catImgOverlayColor && (
                      <div
                        className="cat-layout-tint"
                        style={{
                          background: previewData.catImgOverlayColor,
                          borderRadius:
                            previewData.catImgShape === "sharp_corner"
                              ? "0px"
                              : previewData.catImgShape === "rounded_corner"
                              ? "8px"
                              : "100%",
                        }}
                      ></div>
                    )}{" "}
                  </div>
                  :
                  <></>
            }

                  <p style={{ color: previewData.textColor,position:(previewData.showCatImg||previewData.catImgOverlayColor)?"absolute":"relative" }}>{data1.name}</p>
                </div>
              );
            })}
        </div>
      ) : (
        <></>
      )}

      {previewData.catLayout === "layout-4" ? (
        <>
          {previewData.categoryData &&
            previewData.categoryData.map((data1) => {
              return previewData.catImgShape === "sharp_corner" ? (
                <div
                  className="category-layout-four"
                  style={{
                    background: previewData.catTileBgColor,
                  }}
                >
                  <div>
                    {previewData.showCatImg ? (
                      <div
                        className="cat-setting-img-cover"
                        style={{
                          background: previewData.showCatImg
                            ? `url(${
                                data1.content.portal_value.source_file
                                  ? data1.content.portal_value.source_file.indexOf(
                                      "https"
                                    ) > -1
                                    ? data1.content.portal_value.source_file
                                    : process.env.REACT_APP_Image_Path +
                                      localStorage.getItem("user_id") +
                                      "/" +
                                      localStorage.getItem("Appid") +
                                      process.env.REACT_APP_CMS_Media +
                                      data1.content.portal_value.source_file
                                  : previewData.noCatImg
                              })`
                            : previewData.bgColor,
                          backgroundSize: previewData.showCenterCropImages
                            ? "cover"
                            : "contain",
                          borderRadius: "0px",
                          width: "48px",
                          height: previewData.calculateHeight(
                            previewData.catImgRatio,
                            48
                          ),
                          position: "relative",
                        }}
                      ></div>
                    ) : (
                      <></>
                    )}
                    <p style={{ color: previewData.textColor }}>{data1.name}</p>
                  </div>
                  {data1.child && data1.child.length > 0 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9.687"
                      height="8.404"
                      viewBox="0 0 9.687 8.404"
                    >
                      <g
                        id="Group_42003"
                        data-name="Group 42003"
                        transform="translate(0 0.354)"
                        opacity="0.5"
                      >
                        <path
                          id="Path_103411"
                          data-name="Path 103411"
                          d="M-384.4-3200.5l3.848,3.848-3.848,3.848"
                          transform="translate(389.527 3200.5)"
                          fill="none"
                          // stroke="#1a1a1a"
                          stroke={previewData.textColor}
                          stroke-width="1"
                        />
                        <path
                          id="Path_103412"
                          data-name="Path 103412"
                          d="M-645.52-3185.5h-8.98"
                          transform="translate(654.5 3189.348)"
                          fill="none"
                          // stroke="#1a1a1a"
                          stroke={previewData.textColor}
                          stroke-width="1"
                        />
                      </g>
                    </svg>
                  )}
                </div>
              ) : previewData.catImgShape === "rounded_corner" ? (
                <div
                  className="category-layout-four"
                  style={{
                    background: previewData.catTileBgColor,
                  }}
                >
                  <div>
                    {previewData.showCatImg ? (
                      <div
                        className="cat-setting-img-cover"
                        style={{
                          background: previewData.showCatImg
                            ? `url(${
                                data1.content.portal_value.source_file
                                  ? data1.content.portal_value.source_file.indexOf(
                                      "https"
                                    ) > -1
                                    ? data1.content.portal_value.source_file
                                    : process.env.REACT_APP_Image_Path +
                                      localStorage.getItem("user_id") +
                                      "/" +
                                      localStorage.getItem("Appid") +
                                      process.env.REACT_APP_CMS_Media +
                                      data1.content.portal_value.source_file
                                  : previewData.noCatImg
                              })`
                            : previewData.bgColor,
                          backgroundSize: previewData.showCenterCropImages
                            ? "cover"
                            : "contain",
                          borderRadius: "8px",
                          width: "48px",
                          height: previewData.calculateHeight(
                            previewData.catImgRatio,
                            48
                          ),
                        }}
                      ></div>
                    ) : (
                      <></>
                    )}
                    <p style={{ color: previewData.textColor }}>{data1.name}</p>
                  </div>
                  {data1.child && data1.child.length > 0 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9.687"
                      height="8.404"
                      viewBox="0 0 9.687 8.404"
                    >
                      <g
                        id="Group_42003"
                        data-name="Group 42003"
                        transform="translate(0 0.354)"
                        opacity="0.5"
                      >
                        <path
                          id="Path_103411"
                          data-name="Path 103411"
                          d="M-384.4-3200.5l3.848,3.848-3.848,3.848"
                          transform="translate(389.527 3200.5)"
                          fill="none"
                          // stroke="#1a1a1a"
                          stroke={previewData.textColor}
                          stroke-width="1"
                        />
                        <path
                          id="Path_103412"
                          data-name="Path 103412"
                          d="M-645.52-3185.5h-8.98"
                          transform="translate(654.5 3189.348)"
                          fill="none"
                          // stroke="#1a1a1a"
                          stroke={previewData.textColor}
                          stroke-width="1"
                        />
                      </g>
                    </svg>
                  )}
                </div>
              ) : (
                <div
                  className="category-layout-four"
                  style={{
                    background: previewData.catTileBgColor,
                  }}
                >
                  <div>
                    {previewData.showCatImg ? (
                      <div
                        className="cat-setting-img-cover"
                        style={{
                          background: previewData.showCatImg
                            ? `url(${
                                data1.content.portal_value.source_file
                                  ? data1.content.portal_value.source_file.indexOf(
                                      "https"
                                    ) > -1
                                    ? data1.content.portal_value.source_file
                                    : process.env.REACT_APP_Image_Path +
                                      localStorage.getItem("user_id") +
                                      "/" +
                                      localStorage.getItem("Appid") +
                                      process.env.REACT_APP_CMS_Media +
                                      data1.content.portal_value.source_file
                                  : previewData.noCatImg
                              })`
                            : previewData.bgColor,
                          backgroundSize: previewData.showCenterCropImages
                            ? "cover"
                            : "contain",
                          borderRadius: "100%",
                          width: "48px",
                          height: previewData.calculateHeight(
                            previewData.catImgRatio,
                            48
                          ),
                          position: "relative",
                        }}
                      ></div>
                    ) : (
                      <></>
                    )}
                    <p style={{ color: previewData.textColor }}>{data1.name}</p>
                  </div>
                  {data1.child && data1.child.length > 0 && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="9.687"
                      height="8.404"
                      viewBox="0 0 9.687 8.404"
                    >
                      <g
                        id="Group_42003"
                        data-name="Group 42003"
                        transform="translate(0 0.354)"
                        opacity="0.5"
                      >
                        <path
                          id="Path_103411"
                          data-name="Path 103411"
                          d="M-384.4-3200.5l3.848,3.848-3.848,3.848"
                          transform="translate(389.527 3200.5)"
                          fill="none"
                          // stroke="#1a1a1a"
                          stroke={previewData.textColor}
                          stroke-width="1"
                        />
                        <path
                          id="Path_103412"
                          data-name="Path 103412"
                          d="M-645.52-3185.5h-8.98"
                          transform="translate(654.5 3189.348)"
                          fill="none"
                          // stroke="#1a1a1a"
                          stroke={previewData.textColor}
                          stroke-width="1"
                        />
                      </g>
                    </svg>
                  )}
                </div>
              );
            })}
        </>
      ) : (
        <></>
      )}

      {previewData.catLayout === "layout-5" ? (
        <>
          {previewData.categoryData &&
            previewData.categoryData.map((data1) => {
              return previewData.catImgShape === "sharp_corner" ? (
                <div
                  className="category-layout-five"
                  style={{
                    background: previewData.catTileBgColor,
                  }}
                >
                  <p style={{ color: previewData.textColor }}>{data1.name}</p>

                  {previewData.showCatImg ? (
                    <div
                      className="cat-setting-img-cover"
                      style={{
                        background: previewData.showCatImg
                          ? `url(${
                              data1.content.portal_value.source_file
                                ? data1.content.portal_value.source_file.indexOf(
                                    "https"
                                  ) > -1
                                  ? data1.content.portal_value.source_file
                                  : process.env.REACT_APP_Image_Path +
                                    localStorage.getItem("user_id") +
                                    "/" +
                                    localStorage.getItem("Appid") +
                                    process.env.REACT_APP_CMS_Media +
                                    data1.content.portal_value.source_file
                                : previewData.noCatImg
                            })`
                          : previewData.bgColor,
                        backgroundSize: previewData.showCenterCropImages
                          ? "cover"
                          : "contain",
                        borderRadius: "0px",
                        width: "48px",
                        height: previewData.calculateHeight(
                          previewData.catImgRatio,
                          48
                        ),
                        position: "relative",
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : previewData.catImgShape === "rounded_corner" ? (
                <div
                  className="category-layout-five"
                  style={{
                    background: previewData.catTileBgColor,
                  }}
                >
                  <p style={{ color: previewData.textColor }}>{data1.name}</p>

                  {previewData.showCatImg ? (
                    <div
                      className="cat-setting-img-cover"
                      style={{
                        background: previewData.showCatImg
                          ? `url(${
                              data1.content.portal_value.source_file
                                ? data1.content.portal_value.source_file.indexOf(
                                    "https"
                                  ) > -1
                                  ? data1.content.portal_value.source_file
                                  : process.env.REACT_APP_Image_Path +
                                    localStorage.getItem("user_id") +
                                    "/" +
                                    localStorage.getItem("Appid") +
                                    process.env.REACT_APP_CMS_Media +
                                    data1.content.portal_value.source_file
                                : previewData.noCatImg
                            })`
                          : previewData.bgColor,
                        backgroundSize: previewData.showCenterCropImages
                          ? "cover"
                          : "contain",
                        borderRadius: "8px",
                        width: "48px",
                        height: previewData.calculateHeight(
                          previewData.catImgRatio,
                          48
                        ),
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                </div>
              ) : (
                <div
                  className="category-layout-five"
                  style={{
                    background: previewData.catTileBgColor,
                  }}
                >
                  <p style={{ color: previewData.textColor }}>{data1.name}</p>

                  {previewData.showCatImg ? (
                    <div
                      className="cat-setting-img-cover"
                      style={{
                        background: previewData.showCatImg
                          ? `url(${
                              data1.content.portal_value.source_file
                                ? data1.content.portal_value.source_file.indexOf(
                                    "https"
                                  ) > -1
                                  ? data1.content.portal_value.source_file
                                  : process.env.REACT_APP_Image_Path +
                                    localStorage.getItem("user_id") +
                                    "/" +
                                    localStorage.getItem("Appid") +
                                    process.env.REACT_APP_CMS_Media +
                                    data1.content.portal_value.source_file
                                : previewData.noCatImg
                            })`
                          : previewData.bgColor,
                        backgroundSize: previewData.showCenterCropImages
                          ? "cover"
                          : "contain",
                        borderRadius: "100%",
                        width: "48px",
                        height: previewData.calculateHeight(
                          previewData.catImgRatio,
                          48
                        ),
                        position: "relative",
                      }}
                    ></div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default CategoryPreview;
