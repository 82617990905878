import React from "react";
import "../assets/css/Dashboard.css";
import "./Billing/Billings.css";
import "../assets/css/newheader.css";
import "../assets/css/profileleftpanel.css";
import Header from "./Header";
import ProfileLeftPanel from "./ProfileLeftPanel";
import Accountleftpanel from "./Accountleftpanel";
import { Redeemget } from "../Api/Redeem/RedeemApi";
import {
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Tooltip from "../Dashboard/Tooltip";
import { Foot } from "../Dashboard/Foot";
import { errortoast } from "../Dashboard/Toaster";
import { Link } from "react-router-dom";
import Billingheader from "./Billing/Billingheader";

export default class Redeem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      spinner: true,
      licence_id: "",
      licence_code: "",
      date: "",
      profile: "",
      loader: "d-none",
      billingloader: "d-none",
      loaderimage: require("../assets/images/signup/Loader_GIF.gif").default,
      check: "",
      image: "",
      submitval: "Redeem",
      rotation: 0,
      role_id: 2,
      apps_count: undefined,
      delete_app: false,
      status: 0,
      customer_type: null,
      right_panel_expand: true,
    };
  }
  async componentDidMount() {
    window.scrollTo(0, 0);
     if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
      {
        document.title = "Redeem | AppMySite";

      }
      else
      {
        document.title = "Redeem |"+ ' ' +localStorage.getItem('agencyname');        
      }
    
  }
  redeemformsubmit = (e) => {
    e.preventDefault();
    const params = {
      licence_id: this.state.licence_code.trim(),
    };
    Redeemget(params, this);
  };
  // handlephonecodesearch = (e) => {
  //     this.setState({
  //         phonecodesearch:e.target.value
  //     })
  // }
  // photoggle = () => {
  //     this.setState(prevState => ({
  //       modal: !prevState.modal
  //     }));
  //     }
  // showCroppedImage = async() => {
  //     this.setState({
  //         buttontext : <img src={require( "../assets/images/signup/Loader_GIF.gif").default} alt="loading.." style={{marginTop: "-5px"}}/>,
  //       })
  // 	try {
  // 	  const croppedImage =  await getCroppedImg(
  // 		this.state.image,
  // 		this.state.croppedAreaPixels,

  //       )
  //       const params = {
  //         data:croppedImage,
  //         path:this.state.role_id === 1 ? localStorage.getItem("originalid") + process.env.REACT_APP_Profile : localStorage.getItem("user_id") + process.env.REACT_APP_Profile,
  //       }
  //       ProfileImagebusketApi(params,this);

  // 	} catch (e) {
  // 	  console.error(e)
  // 	}
  //   }
  //   onCropChange = crop => {
  // 	this.setState({ crop })
  //   }

  //   onCropComplete = (croppedArea, croppedAreaPixels) => {
  // 	  this.setState({
  // 		croppedAreaPixels:croppedAreaPixels,
  // 		croppedArea:croppedArea,

  // 	  })

  //   }

  //   onZoomChange = zoom => {
  // 	this.setState({ zoom })
  //   }
  // profiledata = (e) => {
  //     e.preventDefault();

  //     let today = this.state.date;

  //     if(this.state.date === "" || this.state.date === null){
  //         today=null

  //     }else{
  //         let dd = String(today.getDate()).padStart(2, '0');
  //         let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  //         let yyyy = today.getFullYear();

  //         today = yyyy + '-' + mm + '-' + dd;
  //     }
  //     let phonenumber = this.state.phone;
  //     if(!this.state.phone){
  //         phonenumber = null
  //     }
  //     const params = {
  //           name:this.state.name.trim(),
  //           email:this.state.email.trim(),
  //           gender:this.state.gender,
  //           phonecode:this.state.phone_code,
  //           phone:phonenumber,
  //         //   dob:today,
  //           dob:"",
  //           profile:this.state.profile,
  //           check:this.state.check,

  //     }

  //    ProfileApi(params,this)

  // }
  // togglephonecodemodal = () => {
  //     this.setState({
  //         phonecodemodal:!this.state.phonecodemodal
  //     })
  // }
  handleRightPanelExpand = (rightpanelvalue) => {
    this.setState({
      right_panel_expand: rightpanelvalue,
    });
  };
  handlecode = (e) => {
    this.setState({
      licence_code: e.target.value,
    });
  };
  // handleemail = (e) => {
  // 	this.setState({
  // 		email:e.target.value
  // 	})
  // }
  // handlephone = (e) => {
  // 	this.setState({
  // 		phone:e.target.value.replace(/[^0-9]/g, "")
  // 	})
  // }

  // handleOptionChange = (changeEvent) => {
  // 	this.setState({
  // 		gender:changeEvent.target.value
  // 	})
  // }

  // handleprofileimage = (e) => {
  //     var self = this;
  //     var reader = new FileReader();
  //     var file = e.target.files[0];

  //     if(file.name.indexOf("png") !== -1 || file.name.indexOf("jpeg") !== -1 || file.name.indexOf("jpg") !== -1 ){
  //     reader.onload = function(upload) {
  //         self.setState({
  //             errorimage:"",
  // 				modal:true,
  //             profile: upload.target.result,
  //             profile_image: upload.target.result,
  //             image:upload.target.result,
  //         });

  //     };

  //     reader.readAsDataURL(file);
  //     }else{
  //         errortoast("File format not supported. Upload PNG, JPEG or JPG file only.")
  //     }
  // }
  // onChange = date => {
  //      this.setState({ date })
  // }

  render() {
    return (
      <>
        {/* <Header customclass={"header-responsive-new"} /> */}
        <Header />
        <section className="dasboard_page  billing-dashboard-page mt-0">
          <ProfileLeftPanel
            rightpanelbody={this.handleRightPanelExpand}
            profile={""}
          />

          <div
            // className={
            //   this.state.right_panel_expand
            //     ? "right-panel-gap"
            //     : "right-panel-gap right-panel-gap-expand"
            // }
            className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined &&
           localStorage.getItem('sidebaserotate') === 'true')  ? "right-panel-gap right-panel-gap-expand"
            : "right-panel-gap "}
          >
            <Billingheader value={this} />
            <div className=" w-100 billing-right-panel">
              {/* <div className=" jsutify-space-between billing-mainheader">
                <div>
                  <h4>Redeem</h4>
                  <p>
                    Find all your transactions, payment history & invoice
                    details from AppMySite here.
                  </p>
                </div>
              </div> */}
              <div className=" align-items-center justify-content-between  billings-redeemsmallspace mobilemain row">
                <div className="col-md-6 redeem_img">
                  <img
                    src={
                      require("../assets/images/dashboard/redeeminagenew.png")
                        .default
                    }
                    className="redeemsmall  "
                    alt="redeemimage"
                  />
                </div>
                <div className="redeemtext redeemspacing1 redeemspacing2 mobilesub col-md-6 redeem_input">
                  <h3 className="billing-redeemheading">Redeem code</h3>
                  <h5 className="redeemsubheading mobileminor mobilemajor billings-redeemsubheader">
                    Enter your code to activate your deal
                  </h5>
                  {/* <h5 className="redeemminor codeinput-space">Deal Code</h5> */}
                  <div className="form-group billing-mob-form">
                    <form
                      id="formredeem"
                      className="d-grid mobileform"
                      onSubmit={this.redeemformsubmit}
                    >
                      <input
                        type="text"
                        required
                        className="form-control form-name formspace billings-formspace mobileform-sub inputform-width"
                        placeholder="23vfdbrui5673hbsic44ub04078375bxuys90f7"
                        onChange={(e) => this.handlecode(e)}
                      />
                      <div className="mobileform-button redeem-buttons">
                        <button
                          type="submit"
                          className=" button-new-width billing-redeem-button"
                          form="formredeem"
                        >
                          <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <div className={this.state.loader}>
                              <img src={this.state.loaderimage} alt="loader" />
                            </div>
                            <div>{this.state.submitval}</div>
                          </div>
                        </button>
                        <Link to={"/deals"}>Cancel</Link>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}
