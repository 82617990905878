import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import GridlinesTooltip from "../../../Components/commonComponents/AppIcon/GridlinesTooltip";
import ImageDownloadIcon from "../../../Components/commonComponents/AppIcon/ImageDownloadIcon";
import { downloadFile } from "../../../utils/commonUtils";
import "@lottiefiles/lottie-player";
import animation from "../../../assets/json/AI_loader.json";
const SplashPreview = ({
  splashState,
  setSplashState,
  handleImgDownloadPopUp,
  handleVistaPopup,
  handleImgGalleryPopUp,
}) => {
 
  const splashImgUrl = splashState.splashImg?.includes("create.vista.com")
    ? splashState.splashImg
    : splashState.splashImg?.includes("ams")
    ? process.env.REACT_APP_Image_Path +
      localStorage.getItem("user_id") +
      "/" +
      localStorage.getItem("Appid") +
      process.env.REACT_APP_NewSplashScreen +
      splashState.splashImg
    : "";

  const splashImgErrorUrl =
    process.env.REACT_APP_Image_Path +
    process.env.REACT_APP_Image_Path +
    localStorage.getItem("user_id") +
    "/" +
    localStorage.getItem("Appid") +
    process.env.REACT_APP_SplashScreen_Crop +
    splashState.splashImg;

  const handlegridbutton = () => {
    setSplashState({ openVistaPopup: false });
    setSplashState({
      hideGrideBtn: !splashState.hideGrideBtn,
    });
  };

  return (
    <>
      {!splashState.splashImg || splashState.isSplashDataDefault ? (
        <>
          <img
            className="img-fluid web-splash-img"
            src={
              require("../../../assets/images/dashboard/splash-screen-default.png")
                .default
            }
            alt="splash-screen-default"
          />

          <img
            className="img-fluid mob-splash-img"
            src={
              require("../../../assets/images/dashboard/mob-splash-screen-default.png")
                .default
            }
            alt="splash-screen-default"
          />
        </>
      ) : (
        <div className="splash-preview">
          

           <div
            className="splash-preview-child"
            // onClick={(e) => handleImgDownloadPopUp()}
            onClick={() => {
              if (splashState.hideGrideBtn) {
                if (window.matchMedia("(max-width: 1200px)").matches) {
                  handleImgDownloadPopUp();
                } else {
                  downloadFile(splashImgUrl, "ams_splash");
                }
              }
            }}
           
          >
            <img
              className="img-fluid"
              src={splashImgUrl}
              onError={({ currentTarget }) => {
                currentTarget.src = splashImgErrorUrl;
              }}
              alt="App-icon-default"
            />

            {splashState.hideGrideBtn ? (
              <ImageDownloadIcon />
            ) : (
              <>
                <div className="splash-icon-grid">
                  <img
                    src={
                      require("../../../assets/images/dashboard/splash-grid.png")
                        .default
                    }
                    className="img-fluid"
                  />
                </div>
              </>
            )}
          </div> 

      
          <div>
            {splashState.hideGrideBtn ? (
              <>
                {!splashState.isSplashDataDefault ? (
                  <div
                    className="splash-the-tooltip"
                    onClick={() => handlegridbutton()}
                  >
                    <p className="spalsh-grid-line-icon-show">Show gridlines</p>
                    <GridlinesTooltip
                      text={
                        "Gridlines keep your design centered and aligned. They also make sure your design does not spill out upon rendering."
                      }
                    />
                  </div>
                ) : null}
              </>
            ) : (
              <div
                className="splash-the-tooltip"
                onClick={() => handlegridbutton()}
              >
                <p className="slash-grid-line-icon-hide">Hide gridlines</p>
                <GridlinesTooltip
                  text={
                    "Gridlines keep your design centered and aligned. They also make sure your design does not spill out upon rendering."
                  }
                />
              </div>
            )}
          </div>

          <p
            className="splash-start-and-edit-design"
            onClick={() => handleVistaPopup()}
          >
            {splashState.vistaDesignId ? "Edit design" : "Start designing"}
          </p>

          <div className="app-icon-options d-flex d-xl-none">

            <span onClick={() => handleImgGalleryPopUp()}>
              or upload an image
            </span>

            {parseInt(localStorage.getItem("agencyid")) > 1 ? (
              <></>
            ) : (
              <Link to="/app/addons" className="ask-an-expert">
                <img
                  src={
                    require("../../../assets/images/dashboard/diamond.png")
                      .default
                  }
                  alt="diamond"
                />{" "}
                Ask an expert
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SplashPreview;
