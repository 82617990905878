import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import goBack from "../../../assets/images/dashboard/goBackicon.png";
import userdefaultImgOnError from "../../../assets/images/dashboard/my-account.png";
import crossIcon from "../../../assets/images/dashboard/cross.png";
import usedWorkspaceIcon from "../../../assets/images/dashboard/workspace/used-workspace-icon.png";

const MoveAppModal = ({
  settingsState,
  cancelpopup,
  handleTransfer,
  photoggle,
}) => {
  return (
    <div>
      <Modal
        isOpen={settingsState.websitetype}
     
        className="move-app-popup product-change-modal"
        centered
      >
        <ModalBody>
          <div className="move-app-body">
            <div className="moveapp-header">
              <div className="crossmodal mt-1" onClick={cancelpopup}>
                <img src={crossIcon} alt="cross-icon" />
              </div>

              <div className="move-back-arrow" onClick={cancelpopup}>
                <img src={goBack} alt="back-icon" />
              </div>
            </div>

            <h1 className="setting-tech-change-head">Move app</h1>
            <h2 className="seting-tech-change-para">
              Move your app between your Pay-per-app, Workspace and Agency
              subscriptions.
            </h2>

            <div className="move-app-list">
              <h6>Pay-per-app</h6>
              <label class="general-setting-radio-button">
                <span class="general-setting-radio-button__label">
                  <div className="move-app-view">
                    <>
                      {settingsState.userInfo &&
                      settingsState.userInfo.profile_image &&
                      settingsState.userInfo.profile_image.indexOf("https") ===
                        -1 ? (
                        <img
                          alt="profile-image"
                          src={
                            process.env.REACT_APP_Image_Path +
                            (localStorage.getItem("invited_by_user_id") ===
                              undefined ||
                            localStorage.getItem("invited_by_user_id") ===
                              null ||
                            localStorage.getItem("invited_by_user_id") === ""
                              ? localStorage.getItem("user_id")
                              : localStorage.getItem("invited_by_user_id")) +
                            process.env.REACT_APP_Profile +
                            settingsState.userInfo.profile_image
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = userdefaultImgOnError;
                          }}
                        />
                      ) : (
                        <img
                          alt="profile-image"
                          src={
                            settingsState.userInfo &&
                            settingsState.userInfo.profile_image
                              ? settingsState.userInfo.profile_image
                              : ""
                          }
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = userdefaultImgOnError;
                          }}
                        />
                      )}
                    </>
                    <div>
                      <h5 className="select-tech-change-head">
                        {settingsState.userInfo && settingsState.userInfo.name
                          ? settingsState.userInfo.name
                          : ""}
                      </h5>
                      <p className="setting-popup-app-count">
                        {parseInt(localStorage.getItem("personal_apps_count"))}{" "}
                        {parseInt(
                          localStorage.getItem("personal_apps_count")
                        ) == 1
                          ? "app"
                          : " apps"}
                      </p>
                    </div>
                  </div>
                </span>
                <input
                  type="radio"
                  class="general-setting-radio-button__input"
                  name="choice2"
                  defaultChecked={
                    !localStorage.getItem("workspaceId") ||
                    localStorage.getItem("workspaceId") === "null"
                      ? true
                      : false
                  }
                  onChange={() =>
                    handleTransfer(settingsState.userInfo, "user")
                  }
                />
                <span class="general-setting-radio-button__control"></span>
              </label>
              <h6>Unlimited Workspace</h6>

              {settingsState.mergeArray1.map((value, k) => {
                return (
                  <>
                    <label class="general-setting-radio-button ">
                      <span class="general-setting-radio-button__label">
                        <div className="move-app-view">
                          <img
                            alt="profile-image"
                            src={
                              process.env.REACT_APP_Image_Path +
                              value.user_id +
                              process.env.REACT_APP_Workspace_Profile +
                              value.id +
                              "/" +
                              value.profile_image
                            }
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = null;
                              currentTarget.src = usedWorkspaceIcon;
                            }}
                          />
                          <div>
                            <h5 className="select-tech-change-head">
                              {value.workspace_name}
                            </h5>
                            <p className="setting-popup-app-count">
                              {value.app_count}{" "}
                              {value.app_count == 1 ? "app" : " apps"}
                            </p>
                          </div>
                        </div>
                      </span>
                      <input
                        type="radio"
                        class="general-setting-radio-button__input"
                        name="choice2"
                        defaultChecked={
                          localStorage.getItem("workspaceId") !== null &&
                          localStorage.getItem("workspaceId") !== undefined &&
                          localStorage.getItem("workspaceId") !== "" &&
                          localStorage.getItem("workspaceId") !== "null" &&
                          value.id ==
                            parseInt(localStorage.getItem("workspaceId"))
                            ? true
                            : false
                        }
                        onChange={() => handleTransfer(value, "workspace")}
                      />
                      <span class="general-setting-radio-button__control"></span>
                    </label>
                  </>
                );
              })}
            </div>
          </div>

          <div className="move-app-footer">
            <div className="move-app-cancel" onClick={cancelpopup}>
              <p>Cancel</p>
            </div>

            <div
              className="move-app-ok"
              style={{
                backgroundColor: settingsState.sameselected ? "#BCC1CE" : "",
                borderColor: settingsState.sameselected ? "#BCC1CE" : "",
                cursor: settingsState.sameselected ? "not-allowed" : "pointer",
              }}
              onClick={
                settingsState.sameselected ? void 0 : (e) => photoggle(e)
              }
            >
              <p>Move</p>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default MoveAppModal;
