import React from "react";

export default class Tooltip extends React.PureComponent {
  render() {
    return (
      <>
        <div className={this.props.classdata}>
          <this.props.firsttag className={this.props.tagclass}>
            {this.props.heading}
          </this.props.firsttag>
          {this.props.content !== "" && (
            <div className="tooltip-container mb-1">
              <span className={"tooltip1 " + this.props.customclass}>
                {this.props.content}
              </span>
              <svg
                width="15"
                height="15"
                viewBox="0 0 15 15"
                className={this.props.newclass}
              >
                <g
                  id="Group_4588"
                  data-name="Group 4588"
                  transform="translate(-1057.885 -227.299)"
                >
                  <circle
                    id="Ellipse_213"
                    data-name="Ellipse 213"
                    cx="7"
                    cy="7"
                    r="7"
                    transform="translate(1058.385 227.799)"
                    fill="none"
                    stroke="#bcc1ce"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                  <path
                    id="Path_6038"
                    data-name="Path 6038"
                    d="M1117.68,269.437a2.125,2.125,0,1,1,2.771,2.025.921.921,0,0,0-.646.875v.625"
                    transform="translate(-54.219 -36.045)"
                    fill="none"
                    stroke="#bcc1ce"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                  <line
                    id="Line_3"
                    data-name="Line 3"
                    y2="0.918"
                    transform="translate(1065.583 238.03)"
                    fill="none"
                    stroke="#bcc1ce"
                    stroke-miterlimit="10"
                    stroke-width="1"
                  />
                </g>
              </svg>

              {/* <svg width="15" height="15" viewBox="0 0 15 15" className={this.props.newclass}>
										<g id="Group_5521" data-name="Group 5521" transform="translate(-1057.848 -227.299)">
											<circle id="Ellipse_213" data-name="Ellipse 213" cx="7" cy="7" r="7" transform="translate(1058.348 227.799)" fill="none" stroke="#c8cedb" stroke-miterlimit="10" stroke-width="1"/>
											<path id="Path_6038" data-name="Path 6038" d="M1117.68,269.432a2.12,2.12,0,1,1,2.765,2.02.919.919,0,0,0-.645.873v.623" transform="translate(-54.235 -36.053)" fill="none" stroke="#c8cedb" stroke-miterlimit="10" stroke-width="1"/>
											<line id="Line_3" data-name="Line 3" y2="0.916" transform="translate(1065.562 238.007)" fill="none" stroke="#c8cedb" stroke-miterlimit="10" stroke-width="1"/>
										</g>
										</svg> */}
            </div>
          )}
        </div>
      </>
    );
  }
}
