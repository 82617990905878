
import axios from "axios";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";

const Url = process.env.REACT_APP_API_URL + "api/user/";
const saveceritificate = process.env.REACT_APP_API_URL + "api/app/";

const BuildUrl = process.env.REACT_APP_API_URL + "api/automation/bitbucket/";
const Cancelurl = process.env.REACT_APP_API_URL + "api/automation/bitrise/";
const dashboardurl = process.env.REACT_APP_API_URL + "api/";
const appsumourl = process.env.REACT_APP_API_URL;
const RedirectUrl = process.env.REACT_APP_cors_api;
const Errorurl = process.env.REACT_APP_API_URL + "api/frontend-log";

// workspace Plan listing

export const workspacePlanDetailsApi = (state, web_technology, callback) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + localStorage.getItem("amstoken"),
    };
    var isPlan = state.state.isPlan;
    axios
      .get(
        dashboardurl +
          `workspace-packages/${state.state.workspaceIdFromUrl}/${btoa(
            web_technology !== undefined &&
              web_technology !== "" &&
              web_technology !== null
              ? web_technology
              : null
          )}/${isPlan}`,
        {
          headers: headers,
        }
      )
      .then((res) => {
        if (res.data.code === 200) {
          state.setState({
            checkspinner: false,
            spinner: false,
            monthlydata: res.data.data.month,
            yearlydata: res.data.data.year,
            one_time: res.data.data.one_time,
            lifetimedata: res.data.data.lifetime,
            lifetimecheck: res.data.data.lifetime ? 1 : 0,
            subscription_id: res.data.data.subscription,
            subscription_plan: res.data.data.subscription_plan,
            customer_billing_type: res.data.data.customer_billing_type,
            payment_methods: res.data.data.payment_methods,
            plan_switching: "ok",
            customer_type: res.data.data.customer_type,
            customer_billing_type: res.data.data.customer_billing_type,
            reseller_licence_id: res.data.data.reseller_licence_id,
            checkedtab : res.data.data.package_duration === 1 ? 'monthly' 
            : res.data.data.package_duration === 2 ? 'yearly'
            // : res.data.data.package_duration === 4 ? 'lifetime'
            : 'yearly',
            currentplanname : res.data.data.plan_name,
            currentplanrpice : res.data.data.package_duration === 1 ? res.data.data.total : res.data.data.package_duration === 2 ? res.data.data.total/12 : res.data.data.total,
            currency:res.data.data.currency,
            endcycledata:res.data.data.billing_date,
            currentplanduration:res.data.data.package_duration,
            downgrade_at_term_end:res.data.data.downgrade_at_term_end,
            is_deal_plan:res.data.data.is_deal_plan,
            appuser_role_id:res.data.data.workspace_role_id,
            stripe_customer_id:res.data.data?.stripe_customer_id,
            current_package_status:res.data.data?.current_package_status,
          });
          if (callback !== undefined) {
            callback();
          }
        } else if (res.data.code === 502) {
          state.setState({
            allowPopUp: true,
          });
        }
      })
      .catch((err) => {
        
        errortoast("Something went wrong");
      });
  };