import React, { useState, useEffect } from "react";
import "./../assets/css/common.css";
import { useLocation } from "react-router-dom";
import NewSpinner from "./../NewSpinner";
import { saveonlocalhost } from "../../Api/Agency/AgencyApi";

const CustomersAsAdmin = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token"); // Extract query param

  let response = null;

  try {
    if (!token) {
      throw new Error("Token query parameter is missing");
    }
    const decodedToken = decodeURIComponent(token);
    const base64Decoded = atob(decodedToken);

    response = JSON.parse(base64Decoded);

    console.log("Parsed Response:", response);
  } catch (error) {}

  const [rightpanelexpand, setRightPanelExpand] = useState(true);

  useEffect(() => {
    document.title = "Agency Login As Admin";
    if (response) {
      saveonlocalhost(response);
    }
  }, []);
  const handleRightPanelExpand = (rightpanelvalue) => {
    setRightPanelExpand(rightpanelvalue);
  };

  return (
    <div className="org-module">
      <NewSpinner />
    </div>
  );
};

export default CustomersAsAdmin;
