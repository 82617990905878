import React from "react";
import Newfeatureicon from "../../Components/commonIcons/Newfeatureicon";

const SkipBtn = () => {
  const shouldSkipBeVisible = () => {
    return (
      parseInt(localStorage.getItem("appuser_role_id")) <= 2 &&
      (!localStorage.getItem("agencyid") ||
        localStorage.getItem("agencyid") === "undefined" ||
        localStorage.getItem("agencyid") === "null" ||
        parseInt(localStorage.getItem("agencyid")) === 1)
    );
  };

  return (
    <button className={`skip-btn-ui ${shouldSkipBeVisible() ? "" : "d-none"}`}>
      Skip
      {/* <Newfeatureicon /> */}
    </button>
  );
};

export default SkipBtn;
