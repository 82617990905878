import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import { getWorkspace } from "../../Api/Dashboard/DashboardApi";
import "../../assets/css/NewWorkspace.css";
import Header from "../Header";

export default class WorkspaceListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: true,
      submitval: "SAVE PASSWORD",
      loader: "d-none",
      loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
      menuData: [],
      userInfo: {},
      invitedWorkspaceData: [],
      role_id: "",
      user_role: "",
      serachVal: "",
    };
  }
  componentDidMount() {
    document.title = "Workspaces | AppMySite";
    getWorkspace(this, "", "", "all");
  }

  handleSearch = (e) => {
    this.setState({
      serachVal: e.target.value,
    });
  };
  handleUpgradePersonalWp = (e, row, from) => {
    e.preventDefault();
    if (from === "invitedWorkspace") {
      localStorage.setItem("logged_in_user_role_id", row.role_id);
      localStorage.setItem("invited_by_user_id", row.user_id);
    } else {
      localStorage.setItem("logged_in_user_role_id", 1);
      // localStorage.setItem("invited_by_user_id", "");
    }
    localStorage.setItem("workspaceId", row.id);
    localStorage.setItem("workspaceName", row.workspace_name);

    localStorage.setItem(
      "workspaceImage",
      row.profile_image === null ? "" : row.profile_image
    );
    // window.location.href = `/pricing/${btoa(
    //   parseInt(localStorage.getItem("workspaceId"))
    // )}`;
    window.location.href =`/workspace/pricing/${btoa(localStorage.getItem("workspaceId"))}`;
  };

  handleWorkspaceList = (e, row, from, nextForm) => {
    e.preventDefault();

    if (from === "invitedWorkspace") {
      localStorage.setItem("logged_in_user_role_id", row.role_id);

      localStorage.setItem("invited_by_user_id", row.user_id);
    } else {
      localStorage.setItem("logged_in_user_role_id", 1);
      // localStorage.setItem("invited_by_user_id", "");
    }
    localStorage.setItem("workspaceId", row.id);
    localStorage.setItem("workspaceName", row.workspace_name);

    localStorage.setItem(
      "workspaceImage",
      row.profile_image === null ? "" : row.profile_image
    );
    window.location.href = nextForm === "setting-btn" ? `/workspace/dashboard/${btoa(
      row.id
    )}` : `/apps/${btoa(
      parseInt(localStorage.getItem("workspaceId"))
    )}`;
  };

  render() {
    
    return (
      <>
        <Header customclass={"unlimited-workspaces-display"} />
        <section className={
              this.state.menuData !== null &&
              this.state.menuData.length > 0 
            ? "newcontainer-workspace d-flex " 
            : "newcontainer-workspace d-flex removecontainerimage"} >
          <div className="newadd-workspace-inner">
            <div className="newadd-workspace-header">
              <div className="newadd-workspace-head">
                <h5>Unlimited apps</h5>
                <p>Workspaces</p>
              </div>
              {/* <div className="workspace-search d-flex justify-content-between align-items-center">
                <div className="text-search-bar">
                  <input
                    placeholder="Search"
                    value={this.state.serachVal}
                    onChange={(e) => this.handleSearch(e)}
                  />
                 
                </div>
                <div className="work-search-icon">
                  <img
                    src={
                      require("../../assets/images/dashboard/workspace/add-workspace-search.png")
                        .default
                    }
                  />
                </div>
              </div> */}
            {this.state.menuData !== null &&
              this.state.menuData.length > 0 ?
              <div className="workspace-create-add-btn">
                <Link
                  to="/workspace/create"
                  className="btn d-flex justify-content-between align-items-center"
                >


                  <img src={require("../../Agency/assets/images//white-plus.png").default} className="workspace-create-web-plus" alt="invite" />
                  <img
                    src={
                      require("../../assets/images/dashboard/workspace/add-work.png")
                        .default
                    }
                    className="workspace-create-mob-plus"
                    alt="invite"
                  />{" "}
                  <h5 className="mob-workspace-createtext">Create</h5>
                  <h5 className="web-workspace-createtext">Create workspace</h5>
                </Link>
              </div>
            : <></>
            }
            </div>


            {this.state.spinner === true ? (
              <div className="newspinner">
                <svg xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                  width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                  <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                    <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                        style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      </animateTransform>
                    </circle>
                  </g>
                  <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                    <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                        style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      </animateTransform>
                    </circle>
                  </g>
                  <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                    <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                        style={{ animationplaystate: "running", animationdelay: "0s" }}>

                      </animateTransform>
                    </circle>
                  </g>
                  <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                    <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                      <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                        style={{ animationplaystate: "running", animationdelay: "0s" }}>

                      </animateTransform>
                    </circle>
                  </g>
                </svg>
              </div>
            ) : 
            (
              <>
            { (this.state.menuData && this.state.menuData.length == 0) && (this.state.invitedWorkspaceData && this.state.invitedWorkspaceData.length == 0) ?
              <div className="no-worksapce-created">
              <img
                src={
                  require("../../assets/images/dashboard/workspace/no-workspace.png")
                    .default
                }
                className=""
                alt="workspace logo"
              />
              <h2>
                Create your first Workspace
              </h2>
              <p>Achieve cost-effective app development by creating unlimited apps with simplified billing.</p>
              <a href="/workspace/create"><button>Get started</button></a>
            </div>
            :
              this.state.menuData !== null &&
              this.state.menuData
                .filter((res) =>
                  res.workspace_name
                    .toLowerCase()
                    .includes(this.state.serachVal.trim().toLowerCase())
                )
                .map((row) => {

                  return (
                    <div className="newworkspace-det-tile" onClick={(e) =>
                      this.handleWorkspaceList(e, row, "personalworkspace", "setting-btn")
                    }>

                      {/* <Link className="workspace-setting-link upgrade-plan-link-index" onClick={(e) =>
                          this.handleWorkspaceList(e, row, "personalworkspace","setting-btn")
                        } to={"#"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_37519" data-name="Group 37519" transform="translate(-1803 -18)">
    <rect id="Rectangle_27185" data-name="Rectangle 27185" width="24" height="24" transform="translate(1803 18)" fill="none"/>
    <g id="Settings_Copy" data-name="Settings Copy" transform="translate(1805 20)">
      <path id="Shape" d="M10.01,20a10.006,10.006,0,0,1-1.1-.06.56.56,0,0,1-.5-.572A2.224,2.224,0,0,0,7.05,17.233a2.074,2.074,0,0,0-.789-.149,2.3,2.3,0,0,0-1.687.713.558.558,0,0,1-.754.063,9.92,9.92,0,0,1-1.618-1.6.557.557,0,0,1,.059-.76,2.216,2.216,0,0,0,.56-2.5A2.314,2.314,0,0,0,.644,11.654H.621a.54.54,0,0,1-.555-.491,10.063,10.063,0,0,1,0-2.274A.665.665,0,0,1,.718,8.4H.769A2.2,2.2,0,0,0,2.776,7.027a2.222,2.222,0,0,0-.559-2.472A.559.559,0,0,1,2.155,3.8,10,10,0,0,1,3.738,2.2a.557.557,0,0,1,.761.06,2.3,2.3,0,0,0,1.686.717,2.075,2.075,0,0,0,.807-.156A2.217,2.217,0,0,0,8.345.652.555.555,0,0,1,8.836.068,9.875,9.875,0,0,1,9.988,0a10,10,0,0,1,1.1.061.56.56,0,0,1,.5.572,2.222,2.222,0,0,0,1.368,2.133,2.085,2.085,0,0,0,.792.15A2.3,2.3,0,0,0,15.426,2.2a.559.559,0,0,1,.754-.064A9.978,9.978,0,0,1,17.8,3.74a.554.554,0,0,1-.06.76,2.215,2.215,0,0,0-.56,2.495,2.24,2.24,0,0,0,2.046,1.355l.123,0,.04,0a.561.561,0,0,1,.546.492,10.015,10.015,0,0,1,0,2.274.657.657,0,0,1-.643.492,2.208,2.208,0,0,0-1.511,3.843.557.557,0,0,1,.06.754,9.912,9.912,0,0,1-1.584,1.6.556.556,0,0,1-.76-.062,2.3,2.3,0,0,0-1.686-.716,2.087,2.087,0,0,0-.808.156,2.22,2.22,0,0,0-1.35,2.171.557.557,0,0,1-.491.584A10.085,10.085,0,0,1,10.01,20ZM10,7a3,3,0,1,0,3,3A3,3,0,0,0,10,7Z" transform="translate(20 20) rotate(180)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
    </g>
  </g>
</svg></Link> */}


                      <a
                        className="workspace-anchor"
                        
                      />
                      {row.profile_image === "" ||
                        row.profile_image === null ||
                        row.profile_image === "null" ||
                        row.profile_image === undefined ||
                        row.profile_image === "undefined" ||
                        !row.profile_image ? (
                        <img
                         
                          src={
                            require("../../assets/images/dashboard/workspace/workspace-logo.png")
                              .default
                          }
                          className="profile-pic-img  profile-pic-dummy workspace-profile-pic-dummy"
                          alt="profile logo"
                        />
                      ) : (
                        <>
                          {row.profile_image.indexOf("https") === -1 ? (
                            <div
                              style={{
                                background:
                                  "url(" +
                                  process.env.REACT_APP_Image_Path +
                                  row.user_id +
                                  process.env.REACT_APP_Workspace_Profile +
                                  row.id +
                                  "/" +
                                  row.profile_image +
                                  ")",
                              }}
                              className="profile-pic-img workspace-profile-pic-row"
                            ></div>
                          ) : (
                            <div
                              style={{
                                background: "url(" + row.profile_image + ")",
                               
                              }}
                              className="profile-pic-img  workspace-profile-pic-rowsecond"
                            ></div>
                          )}
                        </>
                      )}
                      <div className="workspacelist-det">
                      <div className="workspacelisting-type">
                        <div>
                          <h3>{row.workspace_name}</h3>
                          {/* <div className="d-flex align-items-center workspace-det-inner workspace-det-inner-mobile">
                          <h5 className="listing-plan-name">
                            {" "}
                            {
                            row.subscription.package_info != '' && row.subscription.package_info != undefined && row.subscription.package_info.plan_name != undefined ?
                             row.subscription.package_info.plan_name
                            : ''
                            }
                          </h5>
                          <p
                            className={
                              row.subscription.status !== "free" ?
                              "viewstatus-responsive viewstatus listing-status" : "d-none"
                             
                            }
                            style={{
                              textTransform:"capitalize",
                              color:
                                  (  row.subscription.status === "live" || row.subscription.status === "active" )
                                  ? "#47CE70"
                                 
                                  :( row.subscription.status === "free" || row.subscription.status === "paused" ||

                                  row.subscription.status === "cancelled" || row.subscription.status === "canceled" ||
                                  row.subscription.status === "deleted" )
                                 
                                  ? "#7782A1"
                                  : "#E20000",
                              border:
                              (  row.subscription.status === "live" || row.subscription.status === "active" )
                                  ? "1px solid #47CE70"
                                 
                                  : ( row.subscription.status === "free" || row.subscription.status === "paused" ||

                                  row.subscription.status === "cancelled" || row.subscription.status === "canceled" ||
                                  row.subscription.status === "deleted" )
                                  
                                  ? "1px solid #7782A1"
                                  : "1px solid #E20000",
                            }}
                          >
                            {row.subscription.status === "free" ? "" : (  row.subscription.status === "live" || row.subscription.status === "active" )
                                  ? "Active"
                                 
                                  : 

                                  row.subscription.status === "cancelled"  
                                  
                                  ? "Canceled"
                                  : row.subscription.status === "past_due"  ?
                                "Past Due" : row.subscription.status
                                }
                          </p>
                          {(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1) ?  
                             null
                          :
                              <Link
                              style={{
                                zIndex:"1"
                              }}
                                onClick={(e) =>
                                  this.handleUpgradePersonalWp(e, row, "personal")
                                }
                                className="upgrade-plan-link upgrade-plan-link-index"
                              
                              >
                                Upgrade
                              </Link>
                          }

                        </div> */}
                          <p>ID: {row.id}</p>
                        </div>
<div className="workspaceicontype">

                       <div className="newworkspace-type">
                         
                              <img src={ require("../../assets/images/dashboard/new-website.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 2 ? 'show' : 'd-none')}/>
                              
                              <img src={ require("../../assets/images/dashboard/new-wordpress.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 4 || row.subscription.package_info.website_technology === 5  ? 'show' : 'd-none')}/>

                              <img src={ require("../../assets/images/dashboard/new-woocomerce.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 5 ? 'show' : 'd-none')}/>

                              <img src={ require("../../assets/images/dashboard/new-custom.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 3 ? 'show' : 'd-none')}/>
                              
                              <img src={ require("../../assets/images/dashboard/workspace/new-allinone.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 1 || row.subscription.package_info.website_technology === 0  ? 'show' : 'd-none')}/>
                             
                        </div>
                        {/* <div className="workspace-user-det">
                          <h5>{row.user_count} users</h5>
                        </div>
                        <div className="workspace-app-det">
                          <h5>{row.app_count} apps</h5>
                        </div> */}
                    
                      
                        <div className="newcreation-date">
                          <h5>

                            {moment(row.created_at).format("DD MMMM YYYY")}
                          </h5>
                        </div>
                        </div>
                        </div>

                        {/* <div className="workspace-role">
                          <h4>Owner</h4>
                        </div>
                         */}
                        <div className="mob-workspace-type">
                        <div className="newworkspace-type">
                        
                          <img src={ require("../../assets/images/dashboard/new-website.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 2 ? 'show' : 'd-none')}/>
                          
                          <img src={ require("../../assets/images/dashboard/new-wordpress.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 4 || row.subscription.package_info.website_technology === 5 ? 'show' : 'hidden')}/>

                          <img src={ require("../../assets/images/dashboard/new-woocomerce.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 5 ? 'show' : 'd-none')}/>

                          <img src={ require("../../assets/images/dashboard/new-custom.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 3 ? 'show' : 'd-none')}/>
                          
                          <img src={ require("../../assets/images/dashboard/workspace/new-allinone.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 1 || row.subscription.package_info.website_technology === 0 ? 'show' : 'd-none')}/>
                          
                        </div>

                          {/* <div className="workspace-user-det">
                            <h5>{row.user_count} users</h5>
                          </div>
                          <div className="workspace-app-det">
                            <h5>{row.app_count} apps</h5>
                          </div> */}
                        </div>
                      </div>
                      {/* <div className="d-md-flex align-items-center d-none">
                       <div className="workspace-type">
                          { row.subscription.plan !== 'preview' ?
                          <>
                              <img src={ require("../../assets/images/dashboard/new-website.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 2 ? 'show' : 'd-none')}/>
                              
                              <img src={ require("../../assets/images/dashboard/new-wordpress.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 4 || row.subscription.package_info.website_technology === 5  ? 'show' : 'd-none')}/>

                              <img src={ require("../../assets/images/dashboard/new-woocomerce.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 5 ? 'show' : 'd-none')}/>

                              <img src={ require("../../assets/images/dashboard/new-custom.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 3 ? 'show' : 'd-none')}/>
                              
                              <img src={ require("../../assets/images/pricing/pricing-type-7.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 1 ? 'show' : 'd-none')}/>
                              </>
                            : null
                            } 
                        </div>
                      
                      </div> */}
                    </div>
                  );
                })
            }
            </>
            )}
            {this.state.invitedWorkspaceData.length > 0 &&
              this.state.invitedWorkspaceData &&
              this.state.invitedWorkspaceData
                .filter((res) =>
                  res.workspace_name
                    .toLowerCase()
                    .includes(this.state.serachVal.trim().toLowerCase())
                )
                .map((row) => {
                  return (
                    <div className="newworkspace-det-tile" onClick={(e) =>
                      this.handleWorkspaceList(e, row, "invitedWorkspace", "setting-btn")
                    }>

                      {/* <Link className="workspace-setting-link upgrade-plan-link-index" onClick={(e) =>
                          this.handleWorkspaceList(e, row, "invitedWorkspace","setting-btn")
                        } to={"#"}><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="Group_37519" data-name="Group 37519" transform="translate(-1803 -18)">
    <rect id="Rectangle_27185" data-name="Rectangle 27185" width="24" height="24" transform="translate(1803 18)" fill="none"/>
    <g id="Settings_Copy" data-name="Settings Copy" transform="translate(1805 20)">
      <path id="Shape" d="M10.01,20a10.006,10.006,0,0,1-1.1-.06.56.56,0,0,1-.5-.572A2.224,2.224,0,0,0,7.05,17.233a2.074,2.074,0,0,0-.789-.149,2.3,2.3,0,0,0-1.687.713.558.558,0,0,1-.754.063,9.92,9.92,0,0,1-1.618-1.6.557.557,0,0,1,.059-.76,2.216,2.216,0,0,0,.56-2.5A2.314,2.314,0,0,0,.644,11.654H.621a.54.54,0,0,1-.555-.491,10.063,10.063,0,0,1,0-2.274A.665.665,0,0,1,.718,8.4H.769A2.2,2.2,0,0,0,2.776,7.027a2.222,2.222,0,0,0-.559-2.472A.559.559,0,0,1,2.155,3.8,10,10,0,0,1,3.738,2.2a.557.557,0,0,1,.761.06,2.3,2.3,0,0,0,1.686.717,2.075,2.075,0,0,0,.807-.156A2.217,2.217,0,0,0,8.345.652.555.555,0,0,1,8.836.068,9.875,9.875,0,0,1,9.988,0a10,10,0,0,1,1.1.061.56.56,0,0,1,.5.572,2.222,2.222,0,0,0,1.368,2.133,2.085,2.085,0,0,0,.792.15A2.3,2.3,0,0,0,15.426,2.2a.559.559,0,0,1,.754-.064A9.978,9.978,0,0,1,17.8,3.74a.554.554,0,0,1-.06.76,2.215,2.215,0,0,0-.56,2.495,2.24,2.24,0,0,0,2.046,1.355l.123,0,.04,0a.561.561,0,0,1,.546.492,10.015,10.015,0,0,1,0,2.274.657.657,0,0,1-.643.492,2.208,2.208,0,0,0-1.511,3.843.557.557,0,0,1,.06.754,9.912,9.912,0,0,1-1.584,1.6.556.556,0,0,1-.76-.062,2.3,2.3,0,0,0-1.686-.716,2.087,2.087,0,0,0-.808.156,2.22,2.22,0,0,0-1.35,2.171.557.557,0,0,1-.491.584A10.085,10.085,0,0,1,10.01,20ZM10,7a3,3,0,1,0,3,3A3,3,0,0,0,10,7Z" transform="translate(20 20) rotate(180)" fill="none" stroke="#bcc1ce" stroke-width="2"/>
    </g>
  </g>
</svg></Link> */}

                      <a
                        className="workspace-anchor"
                      />
                      {row.profile_image === "" ||
                        row.profile_image === null ||
                        row.profile_image === "null" ||
                        row.profile_image === undefined ||
                        row.profile_image === "undefined" ||
                        !row.profile_image ? (
                        <img
                         
                          src={
                            require("../../assets/images/dashboard/workspace/workspace-logo.png")
                              .default
                          }
                          className="profile-pic-img  profile-pic-dummy workspace-profile-pic-dummy"
                          alt="profile logo"
                        />
                      ) : (
                        <>
                          {row.profile_image.indexOf("https") === -1 ? (
                            <div
                              style={{
                                background:
                                  "url(" +
                                  process.env.REACT_APP_Image_Path +
                                  row.user_id +
                                  process.env.REACT_APP_Workspace_Profile +
                                  row.id +
                                  "/" +
                                  row.profile_image +
                                  ")",
                               
                              }}
                              className="profile-pic-img  workspace-profile-pic-rowsecond"
                            ></div>
                          ) : (
                            <div
                              style={{
                                background: "url(" + row.profile_image + ")",
                              
                              }}
                              className="profile-pic-img workspace-profile-pic-rowsecond "
                            ></div>
                          )}
                        </>
                      )}
                      <div className="workspacelist-det ">
                        <div className="workspacelisting-type">
                        <div>
                          <h3>{row.workspace_name}</h3>
                          {/* <div className="d-flex align-items-center workspace-det-inner workspace-det-inner-mobile">
                        {
                          row.role_id < 3 &&  <h5 className="listing-plan-name">
                            {row.subscription.package_info.plan_name}
                          </h5>
                         }
                         {
                          row.role_id < 3 &&
                          <p
                          className={
                            row.subscription.status !== "free" ?
                            "viewstatus-responsive viewstatus listing-status" : "d-none"
                           
                          }
                          style={{
                            textTransform:"capitalize",
                            color:
                                (  row.subscription.status === "live" || row.subscription.status === "active" )
                                ? "#47CE70"
                               
                                :( row.subscription.status === "free" || row.subscription.status === "paused" ||

                                row.subscription.status === "cancelled" || row.subscription.status === "canceled" ||
                                row.subscription.status === "deleted" )
                               
                                ? "#7782A1"
                                : "#E20000",
                            border:
                            (  row.subscription.status === "live" || row.subscription.status === "active" )
                                ? "1px solid #47CE70"
                               
                                : ( row.subscription.status === "free" || row.subscription.status === "paused" ||

                                row.subscription.status === "cancelled" || row.subscription.status === "canceled" ||
                                row.subscription.status === "deleted" )
                                
                                ? "1px solid #7782A1"
                                : "1px solid #E20000",
                          }}
                          >
                           {row.subscription.status === "free" ? "" : (  row.subscription.status === "live" || row.subscription.status === "active" )
                                  ? "Active"
                                 
                                  : 

                                  row.subscription.status === "cancelled"  
                                  
                                  ? "Canceled"
                                  : row.subscription.status === "past_due"  ?
                                "Past Due" : row.subscription.status
                                }
                          </p>
                         }
                          {row.role_id <3 ? 
                            <Link
                            style={{
                              zIndex:"1"
                            }}
                              onClick={(e) =>
                                this.handleUpgradePersonalWp(
                                  e,
                                  row,
                                  "invitedWorkspace"
                                )
                              }
                              className="upgrade-plan-link upgrade-plan-link-index"
                            >
                              Upgrade
                            </Link> :
                            <></>
                          }
                        </div> */}
                          <p>ID: {row.id}</p>
                        </div>
<div className="workspaceicontype">

                       <div className="newworkspace-type">
                          
                              <img src={ require("../../assets/images/dashboard/new-website.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 2 ? 'show' : 'd-none')}/>
                              
                              <img src={ require("../../assets/images/dashboard/new-wordpress.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 4 || row.subscription.package_info.website_technology === 5 ? 'show' : 'd-none')}/>

                              <img src={ require("../../assets/images/dashboard/new-woocomerce.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 5 ? 'show' : 'd-none')}/>

                              <img src={ require("../../assets/images/dashboard/new-custom.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 3 ? 'show' : 'd-none')}/>

                              <img src={ require("../../assets/images/dashboard/workspace/new-allinone.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 1 || row.subscription.package_info.website_technology === 0 ? 'show' : 'd-none')}/>

                           
                        </div>
                        {/* <div className="workspace-user-det">
                          <h5>{row.user_count} users</h5>
                        </div>
                        <div className="workspace-app-det">
                          <h5>{row.app_count} apps</h5>
                        </div> */}
                     
                        <div className="newcreation-date">
                          <h5 >
                            {moment(row.created_at).format("DD MMMM YYYY")}
                          </h5>
                        </div>
                        </div>
                        </div>
                        {/* 
                        <div className="workspace-role">
                          <h4>{row.role_id === 2 ? 'Admin' :row.role_id === 3 ? 'Manager' :row.role_id === 4 ? 'User' : 'Owner'}</h4>
                        </div> */}

                        <div className="mob-workspace-type">
                        <div className="newworkspace-type">
                          <>
                          <img src={ require("../../assets/images/dashboard/new-website.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 2 ? 'show' : 'd-none')}/>
                          
                          <img src={ require("../../assets/images/dashboard/new-wordpress.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 4 || row.subscription.package_info.website_technology === 5? 'show' : 'hidden')}/>

                          <img src={ require("../../assets/images/dashboard/new-woocomerce.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 5 ? 'show' : 'd-none')}/>

                          <img src={ require("../../assets/images/dashboard/new-custom.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 3 ? 'show' : 'd-none')}/>

                          <img src={ require("../../assets/images/dashboard/workspace/new-allinone.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 1 || row.subscription.package_info.website_technology === 0 ? 'show' : 'd-none')}/>
                          </>
                          
                        </div>

                          {/* <div className="workspace-user-det">
                            <h5>{row.user_count} users</h5>
                          </div>
                          <div className="workspace-app-det">
                            <h5>{row.app_count} apps</h5>
                          </div> */}
                        </div>
                      </div>
                    
                      {/* <div className="d-md-flex d-none">
                        <div className="workspace-user-det">
                          <h5>{row.user_count} users</h5>
                        </div>
                        <div className="workspace-app-det">
                          <h5>{row.app_count} apps</h5>
                        </div>
                            </div> */}
                      {/* <div className="d-md-flex align-items-center d-none">
                       <div className="workspace-type">
                          { row.subscription.plan !== 'preview' ?
                          <>
                              <img src={ require("../../assets/images/dashboard/new-website.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 2 ? 'show' : 'd-none')}/>
                              
                              <img src={ require("../../assets/images/dashboard/new-wordpress.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 4 || row.subscription.package_info.website_technology === 5 ? 'show' : 'd-none')}/>

                              <img src={ require("../../assets/images/dashboard/new-woocomerce.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 5 ? 'show' : 'd-none')}/>

                              <img src={ require("../../assets/images/dashboard/new-custom.png") .default } alt="Mobile icon" className={"img-fluid " + (row.subscription.package_info.website_technology === 3 ? 'show' : 'd-none')}/>
                              </>
                            : null
                            } 
                        </div>
                      
                      </div> */}
                    </div>
                  );
                })}
          </div>
        </section>
      </>
    );
  }
}
