import React from "react";
import "../../assets/css/Dashboard.css";
import Header from "../Header";
import "./workspacecommon.css";
import {
	GetuserprofileApi,
	ProfileImagebusketApi,
	updateWorkspace,
	deleteWorkspace,
	getWorkspaceById,
	leaveWorkspace
  } from "../../Api/Dashboard/DashboardApi";
  import LeftPanel from "./workspaceLeftpannel";
  import {
	Modal,
	ModalHeader,
	ModalBody,
	ModalFooter,
  } from "reactstrap";
  import getCroppedImg from "../cropImage";
  import { errortoast } from "../../Dashboard/Toaster";
  import { Link} from "react-router-dom";
  import { withRouter } from "react-router";
  import moment from "moment";
  import UploadLogoPopup from "../NewImageGalleryPopup";

export default class Workspacesettings extends React.Component {
	
	constructor(props) {
		super(props);
		this.state = {
		  modal: false,
		  phonecodemodal: false,
	
		  buttontext: "CROP",
		  email: "",
		  oldemail: "",
		  phone_code: "",
		  phone: "",
		  gender: "",
		  settingspinner: true,
		  profile_image: "",
		  date: "",
		  profile: localStorage.getItem("workspaceImage"),
		  submitval: "Save",
		  submitval1: "Leave",
		  deleteLoader: "d-none",
		  deleteLoaderimage:"d-none",
		  loader: "d-none",
		  loader1: "d-none",
		  billingloader: "d-none",
		  loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
		  phonecodesearch: "",
		  check: "",
		  image: "",
		  crop: { x: 0, y: 0 },
		  zoom: 1,
		  aspect: 2 / 2,
		  croppedImage: null,
		  croppedAreaPixels: null,
		  rotation: 0,
		  role_id: 2,
		  workspaceroleid:1,
		  countryselectfield: [],
		  apps_count: undefined,
		  delete_app: false,
		  status: 0,
		  customer_type: null,
		  workspaceName: "",
		  deleteworkspaceName:"",
		  workspacenameconfirm:"",
		  rightpanelexpand:false,
		  mdllimitdelete:false,
		  logoimage:null,
		  islogopopup:false,
		  workspaceId: atob(this.props.match.params.workspaceID),
		  workspaceImage: localStorage.getItem("workspaceImage"),
		  profilepath:
			process.env.REACT_APP_Image_Path +
			(
			  (parseInt(localStorage.getItem("logged_in_user_role_id")) === 1)
			  ? localStorage.getItem("originalid") 
			  : localStorage.getItem("invited_by_user_id")) +
				process.env.REACT_APP_Workspace_Profile +
				localStorage.getItem("workspaceId") +
				"/",
		  childState: "",
		  WorkspaceDataById: "",
		  workspace_app_count: "",
		  allowdelete: false,
		  workspace_leave: false,
		  lastdate:"",
		  erroragencyname:"",
		  rightpanelexpand:true,
		};
	  }
	  
	componentDidMount() {
		window.scrollTo(0, 0);
		document.title = "Settings | AppMySite";
		GetuserprofileApi(this);
		
		  
		  
		  if(this.state.workspaceId !== null && this.state.workspaceId !== undefined && this.state.workspaceId !== "null" && this.state.workspaceId !== "undefined")
		  {
			getWorkspaceById(this, btoa(this.state.workspaceId));
			this.setState({workspaceid:this.state.workspaceId});
		  }
		  else
		  {
			getWorkspaceById(this, localStorage.getItem("workspaceId"));
		  }
	  }
	  handlephonecodesearch = (e) => {
		this.setState({
		  phonecodesearch: e.target.value,
		});
	  };
	  photoggle = () => {
		this.setState((prevState) => ({
		  modal: !prevState.modal,
		}));
	  };
	  showCroppedImage = async () => {
		this.setState({
		  buttontext: (
			<img
			  src={require("../../assets/images/signup/Loader_GIF.gif").default}
			  alt="loading.."
			  className="workspace-loader-img"
			/>
		  ),
		});
		try {
		  const croppedImage = await getCroppedImg(
			this.state.image,
			this.state.croppedAreaPixels
		  );
		  const params = {
			data: croppedImage,
			path:
				parseInt(localStorage.getItem("logged_in_user_role_id")) === 1
				? localStorage.getItem("originalid") +
				  process.env.REACT_APP_Workspace_Profile +
				  localStorage.getItem("workspaceId")
				: parseInt(localStorage.getItem("logged_in_user_role_id")) === 2
				  ? localStorage.getItem("invited_by_user_id") +
					process.env.REACT_APP_Workspace_Profile +
					localStorage.getItem("workspaceId")
				: localStorage.getItem("logged_in_user_id") +
				  process.env.REACT_APP_Workspace_Profile +
				  localStorage.getItem("workspaceId"),
		  };
		  ProfileImagebusketApi(params, this);
		} catch (e) {
		  console.error(e);
		}
	  };
	  onCropChange = (crop) => {
		this.setState({ crop });
		
	  };
	
	  onCropComplete = (croppedArea, croppedAreaPixels) => {
		this.setState({
		  croppedAreaPixels: croppedAreaPixels,
		  croppedArea: croppedArea,
		});
	  };
	
	  onZoomChange = (zoom) => {
		this.setState({ zoom });
	  };

	 handleRightPanelExpand = (rightpanelvalue) => 
	 {
        this.setState({rightpanelexpand:rightpanelvalue});
	 }
	
	  workspaceUpdate = (e) => {
		e.preventDefault();
	
		if (this.state.workspaceName.trim() === "") 
		{
		  errortoast("Enter valid name.");
		} 
		else 
		{
		  this.setState({
			loader: "new-loader",
			submitval: "",
		  });
		  const param = {
			workspace_id: parseInt(this.state.workspaceId),
			workspace_name: this.state.workspaceName,
			profile: this.state.profile,
		  };
		  updateWorkspace(param, this);
		}
	  };
	
	  workspaceleavedone = (e) => 
	  {
		e.preventDefault();
	
		  this.setState({
			loader1: "d-block",
			submitval1: "",
		  });
		  const param = {
			workspace_id: parseInt(this.state.workspaceId),
		  };
	
		  leaveWorkspace(param, this);
	  };
	
	  togglephonecodemodal = () => {
		this.setState({
		  phonecodemodal: !this.state.phonecodemodal,
		});
	  };
	  workspaceleave = (e) => {
		e.preventDefault();
		this.setState({
		  workspace_leave: !this.state.workspace_leave,
		});
	  };
	  handleworkspaceName = (e) => 
	  {
		this.setState({
		  workspaceName: e.target.value,
		  erroragencyname: ""
		});
	  };
	
		handledeleteworkspaceName = (e) => {
	
		
		this.setState({
		  deleteworkspaceName: e.target.value,
		});
	   
	
	  };
	  handleemail = (e) => {
		this.setState({
		  email: e.target.value,
		});
	  };
	  handlephone = (e) => {
		this.setState({
		  phone: e.target.value.replace(/[^0-9]/g, ""),
		});
	  };
	
	  handleOptionChange = (changeEvent) => {
		this.setState({
		  gender: changeEvent.target.value,
		});
	  };
	
	  handleprofileimage = (e, state) => {
		this.setState({
		  modal: true,
		  childState:state
		});
		
	  };
	
	  onChange = (date) => {
		this.setState({ date });
	  };
	  removeimage = (e, state) => {
		this.setState({
		  profile: "",
		  // profile_image: "",
		  workspaceImage: "",
		  check: 2,
		});
		if (state !== undefined) {
		  state.setState({
			profile: "",
		  });
		}
	  };
	
	  handleseachradio = (e) => {
		this.setState({
		  phone_code: e.target.value,
		  phonecodemodal: false,
		});
	  };
	  delete_accountmodal = (e) => 
	  {
		e.preventDefault();
		this.setState({
		  userdeletemodal: !this.state.userdeletemodal,
		});
	  };
	  //Is workspace ready for delete or not
	  isDeleteWorkspace = (e) => 
	  {
		e.preventDefault();
		if (this.state.workspace_app_count > 0) 
		{
		  this.setState({
			delete_app: !this.state.delete_app,
		  });
		} 
		else 
		{
		  this.setState({
			userdeletemodal: !this.state.userdeletemodal,
		  });
		}
	  };
	  //Delete Worskspace
	  deleteWorkspace = (e) => 
	  {
		 e.preventDefault();
	   
		if(this.state.deleteworkspaceName ==  'Permanently delete')
		{
		  this.setState({
			deleteLoader: "d-block",
			deleteLoaderimage:"d-block",
		  });
		  const params = { workspace_id: btoa(parseInt(this.state.workspaceId)) };
		  deleteWorkspace(params, this);
		}
		else
		{
		  errortoast("Please enter valid input.");
		}
	  };
	
	  deleteapps = (e) => {
		e.preventDefault();
		this.setState({
		  delete_app: !this.state.delete_app,
		});
	  };
	
	  handleDeleteuser = () => {
		
		this.setState({
		  allowdelete: !this.state.allowdelete,
		});
	  };
	
	  // NEw Image Upload Pop up integration
	
	  settingYourdata=(imgName,from)=>{
	   
		if(from!=="original"){
		 if(this.state.childState){
		  this.state.childState.setState({
		   profile:imgName,
		  })
		 }
		  this.setState({
			profile:imgName,
	 
		  })
		}
	  }
	
	  yourErrFun=()=>{
	
	  }

	handleLogoUpload = (imgName, from, orgFileName, url1200, useUrl) =>
	{

		
		
		
		if(from === 'resize')
		{
			this.state({logoimage:imgName});
			localStorage.setItem("agencylogo", imgName);
		}
		else
		{
			//setLogoImage(imgName); //original
		}
		
		
		
	}

	
	  loadpicVistaCreatePopUp = (data) =>{
	   
		if(this.state.childState){
		  this.state.childState.setState({
		   profile:data.url,
		  })
		 }
		  this.setState({
			profile:data.url,
			modal:false,
		  })
	  
	   }

	   btnCancelLimitPopup = () =>
	   {
		 this.setState({mdllimitdelete:false});
	   }
	
	
		 
	   closeVistaCreatePopUp = () =>{
		
	   }
	
	  render() {
		
		return (
		  <>
        <div className="workspace-org-module">
			
            <Header />

            <div>
        		<div className="workspace-org-structure">
				<LeftPanel rightpanelbody={this.handleRightPanelExpand}/>

				<div className={localStorage.getItem("worksidebaserotate") === 'true' ? "workspace_background" : "workspace_background workspace_background-expand"}>

						<>
							<div className="workspace-setting-head">
								
								<div className="workspace-headings workspace-setting-headings">
                            <h1>Settings</h1>
                            <p class="workspace-tooltip"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                            <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                <circle cx="9" cy="9" r="9" stroke="none"/>
                                <circle cx="9" cy="9" r="8.25" fill="none"/>
                                </g>
                                <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5"/>
                                <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
                            </g>
                            </svg><p class="workspace-tooltipsubheader">Manage your Workspace effortlessly with key settings for optimal organization and control.</p></p>
                            </div>

							{this.state.settingspinner ?  
							<>

							</>
							:
								<div className="workspacesetting-save">
									
									<h5 className="workspace-web-timestamp">
                                    {this.state.lastdate ?
                                        'Updated ' + moment(this.state.lastdate).format("D MMMM YYYY, HH:mm")
                                    : ''
                                    }
                                	</h5>

									<div className={this.state.workspaceName ? "workspace-save-setting-btn" : "workspace-save-setting-btn workspace-save-social-btn-deactivate"}  onClick={(e) => this.workspaceUpdate(e)}>
										<div className={this.state.loader}></div>
										{this.state.submitval}
									</div>
									
								</div>
							}
							</div>

							{ this.state.settingspinner ? 
														  <div className="app-icon-section access-loader workspace-loader-div">
														  <div className="newspinner">
														  <svg xmlns="http://www.w3.org/2000/svg" 
															  xlink="http://www.w3.org/1999/xlink" 
															  style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
															  width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
															  <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
															  <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
															  <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
															  style={{animationplaystate: "running", animationdelay: "0s"}}>
															  </animateTransform>
															  </circle>
															  </g>
															  <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
															  <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
															  <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
															  style={{animationplaystate: "running", animationdelay: "0s"}}>
															  </animateTransform>
															  </circle>
															  </g>
															  <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
															  <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
															  <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
															  style={{animationplaystate: "running", animationdelay: "0s"}}>
						  
															  </animateTransform>
															  </circle>
															  </g>
															  <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
															  <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
															  <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
															  style={{animationplaystate: "running", animationdelay: "0s"}}>
						  
															  </animateTransform>
															  </circle>
															  </g>
															  </svg>
														</div>
													  </div>
							:
							<>
							<>
								<div className="workspace-module-shadow mob-workspace-mod-shadow">
								
									<>

										<div className="workspace-setting-body">
										
											<div className="workspace-logo">

											{
												this.state.profile === "" ||
												this.state.profile === null ||
												this.state.profile === "null" ||
												this.state.profile === undefined ||
												this.state.profile === "undefined" ||
												!this.state.profile ? 
												(
													<img src={require("../../assets/images/dashboard/workspace/workspace-logo.png").default} alt="profile logo" />
												) 
											: 
												(
												<>
													{this.state.profile.indexOf("https") === -1 ? 
														(
															<img src={this.state.profilepath +this.state.profile} alt="profile logo" />
														) 
													: 
														(
															<img src={this.state.profile} alt="profile logo" />
														)
													}
												</>
												)
											}
											
												{/* <div className="workspace-logo-shadow" 
													 onClick=
													 		{
																(parseInt(localStorage.getItem("logged_in_user_role_id")) === 1 ||
													  			 parseInt(localStorage.getItem("logged_in_user_role_id")) === 2) 
																? (e)=>this.handleprofileimage(e,this) 
																: void(0)
															}
												>
													{(parseInt(localStorage.getItem("logged_in_user_role_id")) === 1 ||
													  parseInt(localStorage.getItem("logged_in_user_role_id")) === 2) 
													&& 
													(
													<svg xmlns="http://www.w3.org/2000/svg" width="24" height="20" viewBox="0 0 24 20">
													<g id="_8666672_camera_icon" data-name="8666672_camera_icon" transform="translate(0 -2)">
														<path id="Path_98235" data-name="Path 98235" d="M23,19a2,2,0,0,1-2,2H3a2,2,0,0,1-2-2V8A2,2,0,0,1,3,6H7L9,3h6l2,3h4a2,2,0,0,1,2,2Z" fill="none" stroke="#bcc1ce" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
														<circle id="Ellipse_7718" data-name="Ellipse 7718" cx="4" cy="4" r="4" transform="translate(8 9)" fill="none" stroke="#bcc1ce" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
													</g>
													</svg>
													)}
												</div> */}

												{
													(parseInt(localStorage.getItem("logged_in_user_role_id")) === 1 ||
													parseInt(localStorage.getItem("logged_in_user_role_id")) === 2) 
													&& 
													(
														<div className="workspace-logo-edit">
															<p class="profilepic-upload" onClick={(e)=>this.handleprofileimage(e,this)}>Upload </p>
                                                         {
															this.state.profile ?
														 
															<p className="profilepic-remove" onClick={(e) => this.removeimage(e)}> Remove</p>
															:
															<></>
														 }
															
														</div>
													)}

												<UploadLogoPopup
													isGalleryPopup={this.state.modal}
													setIsGalleryPopup={true}
													settingYourdata={this.settingYourdata}
													yourErrFun={this.yourErrFun}
													uploadPath=
													{
													  parseInt(localStorage.getItem("logged_in_user_role_id")) === 1
													  ? localStorage.getItem("originalid") + process.env.REACT_APP_Workspace_Profile + localStorage.getItem("workspaceId")
													  : parseInt(localStorage.getItem("logged_in_user_role_id")) === 2
													  ? localStorage.getItem("invited_by_user_id") + process.env.REACT_APP_Workspace_Profile + localStorage.getItem("workspaceId")
													  : localStorage.getItem("invited_by_user_id") + process.env.REACT_APP_Workspace_Profile + localStorage.getItem("workspaceId")
													}
													settingOrgImgFrom3rdParty={false}
													hideImageGallery={true}
													widthForRestriction={120}
													heightForRestriction={120}
													aspectRatio={"1:1"}
													classCompoState={this}
													cropShape={"round"}
													isNotCustomUploadOrgImg={true}
												/>

											</div>

											

											<div className="">
												<div className="workspace-setting-input">
													<label>Workspace name*</label>
													<input type="text" required className={this.state.erroragencyname ? 'errormessages' : ''} form="form1" value={this.state.workspaceName} onChange={(e) => this.handleworkspaceName(e)}></input>
													{this.state.erroragencyname ?
													<span className="agencyerror"><svg id="Group_29966" data-name="Group 29966" xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13"><path id="Path_82474" data-name="Path 82474" d="M6.5,0A6.5,6.5,0,1,1,0,6.5,6.5,6.5,0,0,1,6.5,0Z" fill="#ff4848"></path><path id="Path_7077" data-name="Path 7077" d="M10991-4358.209v4" transform="translate(-10984.499 4361.208)" fill="none" stroke="#fff" stroke-width="1"></path><path id="Path_7078" data-name="Path 7078" d="M10991-4347.192v1.229" transform="translate(-10984.499 4356.1)" fill="none" stroke="#fff" stroke-width="1"></path></svg>{this.state.erroragencyname}</span>
													:''}
												</div>

											</div>
										</div>
									</>
								
								</div>

								<div className="workspace-mobile-btns">

									<div className={this.state.workspaceName ? "workspace-mobile-btns-blue" : "workspace-mobile-btns-blue workspace-save-social-btn-deactivate"} onClick={(e) => this.workspaceUpdate(e)}>
									<div className={this.state.loader}></div>
										{this.state.submitval}
										</div>

									<h5>{this.state.lastdate ?
												'Updated ' + moment(this.state.lastdate).format("D MMMM YYYY, HH:mm")
											: ''
											}</h5>

								</div>

							</>
							{this.state.workspaceroleid !== 1 && this.state.workspaceroleid !== null && this.state.workspaceroleid !== undefined ?
								<div className="workspace-module-shadow mob-workspace-mod-shadow mt-20">
									<div className="workspace-delete-org">
										<div>
										<h3>Leave Workspace</h3>
										<h4>Leave this Workspace. Careful, this action cannot be undone.</h4>
										</div>
										<button className="delete-agency-btn del-work-btn" onClick={(e) => this.workspaceleave(e)}>Leave workspace</button>
									</div>
								</div>
							: 
								<div className="workspace-module-shadow mob-workspace-mod-shadow mt-20">
									<div className="workspace-delete-org">
										<div>
										<h3>Delete Workspace</h3>
										<h4>Delete this Workspace. Careful, this action cannot be undone.</h4>
										</div>
											<button className="delete-agency-btn del-work-btn" onClick={(e) => this.isDeleteWorkspace(e)}>Delete workspace</button>
									</div>
								</div>
	  						}
							</>
	  						}
						</>
						
					</div>
        		</div>
				
            </div>
        </div>

			<Modal isOpen={this.state.allowdelete}  style={{ maxWidth: "460px", maxHeight: "240px" }} className="confirm-workspace-popups" centered >
				<ModalHeader className="modal-xxl-content">
					<div className="profile-crossmodal " onClick={() =>this.handleDeleteuser()}>
					<img
						src={require("../../assets/images/dashboard/cross.png").default}
					/>
					</div>
				</ModalHeader>
				<ModalBody className="new-before-delete-step">
					<div>
					{/* <h4>Active products found</h4> */}
					<h6>You currently do not have permissions to access this feature. Please contact your administrator if you require access.{" "}</h6>
					
					<div className="before-delete-footer">
						<div className="new-before-delete-ok">
						<p onClick={() => this.handleDeleteuser()}>Okay</p>
						</div>
					</div>
						</div>
				</ModalBody>
        	</Modal>
			<Modal isOpen={this.state.workspace_leave} style={{ maxWidth: "460px", maxHeight: "240px" }} className="confirm-workspace-popups" centered >
				<ModalHeader className="modal-xxl-content">
					<div className="profile-crossmodal " onClick={(e) =>this.workspaceleave(e)}>
					<img
						src={require("../../assets/images/dashboard/cross.png").default}
					/>
					</div>
				</ModalHeader>
				<ModalBody className="new-before-delete-step">
				 <div className="workspace-leave-popup">
				    <h4>Leave this Workspace?</h4>
					<h6>Once you leave, you will not be able to access it.{" "}</h6>
				
							<div className="workspace-before-delete-footer">                
                				<button className="confirm-workspace-footer-button-cancel workspacecancle" onClick={this.workspaceleave}>Cancel</button>
                				<button className="confirm-workspace-footer-button-delete workspacedokaybtn" onClick={this.workspaceleavedone}  id="btndeleteorgid">
									<div className={this.state.loader1}>
										<div className="new-red-loader"></div>
									</div>
									{this.state.submitval1}									
								</button>
              				</div>
					
				 </div>
				</ModalBody>
				</Modal>

		       <Modal toggle={this.delete_accountmodal} isOpen={this.state.userdeletemodal} style={{ maxWidth: "600px", maxHeight: "340px" }} className="confirm-workspace-popups" centered>
					<ModalBody toggle={this.delete_accountmodal}> 
            			<div className="crossmodal mt-1" onClick={this.delete_accountmodal}>
              				<img
                				src={require("../../assets/images/dashboard/cross.png").default}
              				/>
            			</div>
            			<div>
              				<div className="confirm-workspace-body">
                				<h4>Delete this Workspace?</h4>
                				<h5>This will permanently delete the Workspace.</h5>

								<h6>Please type <span className="permanently-delete">‘Permanently delete’</span> to confirm.</h6>
                				
								<input type="text" id="deleteworkspaceName" name="deleteworkspaceName" value={this.state.deleteworkspaceName} onChange={(e) => this.handledeleteworkspaceName(e)} />
              				</div>

              				<div className="confirm-workspace-footer">                
                				<button className="confirm-workspace-footer-button-cancel" onClick={this.delete_accountmodal}>Cancel</button>
                				<button className="confirm-workspace-footer-button-delete" onClick={(e) => this.deleteWorkspace(e)}  id="btndeleteorgid">
									<div className={this.state.deleteLoaderimage}>
										<div className="new-red-loader"></div>
									</div>
									{this.state.deleteLoader !== "d-block" && <p className="newapproval-overview">Delete</p>}									
								</button>
              				</div>
            			</div>
          			</ModalBody>
				</Modal>

				<Modal
          isOpen={this.state.delete_app}
          toggle={this.deleteapps}
          style={{ maxWidth: "480px", maxHeight: "247px" }}
          className={this.props.className}
          centered
          dataclass="countrycode"
        >
          <ModalHeader toggle={this.deleteapps} className="modal-xxl-content">
            <div className="crossmodal mt-1" onClick={this.deleteapps}>
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="workspace-completesteps delete_apps">
            {/* <img
              src={
                require("../../assets/images/dashboard/delete-apps.png").default
              }
              alt="step"
            /> */}
            <h4>Active products found</h4>
            <p className="">
			Prior to deletion, ensure all apps are removed. Also, downgrade to the Preview plan to proceed.
            </p>
            <div className="workspace-buttonbox">
              <p onClick={this.deleteapps}>Okay</p>
            </div>
          </ModalBody>
        </Modal>
		</>
    );
  }
}