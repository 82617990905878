import React from "react";
import { Modal, ModalBody } from "reactstrap";
import CrossIcon from "../../commonIcons/CrossIcon";
const DeleteUserModal = ({
  teamState,
  deleteUserFunction,
  deleteUserLoading,
}) => {
  return (
    <Modal
      isOpen={teamState.openDeletePopUp}
      centered
      className=" newuser-modal-popup"
      toggle={(e) => deleteUserFunction(e, "cancel")}
      dataclassName="addonpopup"
    >
      <ModalBody className="d-flex align-items-center justify-content-center ">
        <div className="user-delete-cross-icon">
          <CrossIcon
            handleCrossClick={(e) => deleteUserFunction(e, "cancel")}
          />
        </div>
        <div>
          <h5 className="user-delete-modal-title">Delete this user?</h5>
          <h6 className="user-delete-modal-para">
            Are you sure you want to delete this user? Once deleted, the user
            will lose access to the app.
          </h6>

          <div className="user-new-modal-footer">
            <div
              className="usermodal-grey-btn"
              onClick={(e) => deleteUserFunction(e, "cancel")}
            >
              Cancel
            </div>

            <button
              onClick={(e) => deleteUserFunction(e, "add")}
              className="usermodal-red-btn"
              disabled={deleteUserLoading}
            >
              {window.matchMedia("(min-width: 1200px)").matches &&
              deleteUserLoading ? (
                <div className="new-red-loader"></div>
              ) : deleteUserLoading ? (
                <div className="new-loader"></div>
              ) : (
                <div>Delete</div>
              )}
            </button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default DeleteUserModal;
