import React from "react";
import Amsvideo from "../Reusable/Amsvideos";
import { Link } from "react-router-dom";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import { Pagenameapi, SetpageApi } from "../../Api/Dashboard/DashboardApi";
import { Spinner, Media } from "reactstrap";
import { errortoast } from "../Toaster";
import ReactHtmlParser from "react-html-parser";
import { Foot } from "../../Dashboard/Foot";
// import {Navigation} from './Topnavigation'
import BottomScrollListener from "react-bottom-scroll-listener";
export default class Appsettings extends React.Component {
  state = {
    pageData: [],
    spinner: true,
    finalvalue: [],
    saved_pages: [],
    submitval: "Save",
    loader: "d-none",
    loaderimage: require("../../assets/images/signup/Loader_GIF.gif").default,
    selectall: true,
    nottoshow: true,
    errorstatus: false,
    errordata: "",
    pageval: 1,
    datanumber: "",
    bottomspinner: false,
    appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
    localStorage.getItem("appSideBarCollapse") === "true"
      ? true
      : false,
  };

  componentDidMount() {
    document.title = "App Pages";
    window.scrollTo(0, 0);
    const params = {
      page: this.state.pageval,
      limit: 20,
      app_id: btoa(localStorage.getItem("Appid")),
    };
    Pagenameapi(params, this);
  }
  handlepagination = (pagination) => {
    Pagenameapi(pagination, this);
  };
  handleContainerOnBottom = () => {
    if (this.state.bottomspinner === false) {
      if (this.state.datanumber < 20) {
      } else {
        this.setState({ pageval: 1 + this.state.pageval, bottomspinner: true });
        const pagination = {
          page: this.state.pageval,
          limit: 20,
          app_id: btoa(localStorage.getItem("Appid")),
          pageData: this.state.pageData,
        };
        this.handlepagination(pagination);
      }
    }
  };
  handlestatus = (e) => {
    //var x = document.getElementById(e.target.id).checked ? 1 : 0;
    // alert(x)
    // if(x === 1){
    // this.state.finalvalue.push(e.target.id);
    //  }else{
    // 	const index = this.state.finalvalue.indexOf(e.target.id)
    // 	if (index > -1) {
    // 		this.state.finalvalue.splice(index, 1);
    // 	  }
    //  }
  };
  selectall = (e) => {
    this.setState(
      {
        selectall: !this.state.selectall,
      },
      () => {
        if (this.state.selectall === true) {
          this.setState({
            nottoshow: true,
          });
        } else {
          this.setState({
            nottoshow: false,
          });
        }
      }
    );
  };
  handleFormSubmit = (e) => {
    e.preventDefault();
    let i = 0;

    if (this.state.selectall === false) {
      for (i = 0; i < this.state.pageData.length; i++) {
        var x = document.getElementById(this.state.pageData[i].page_id).checked
          ? 1
          : 0;

        if (x === 1) {
          this.state.finalvalue.push(this.state.pageData[i].page_id);
        } else {
          const index = this.state.finalvalue.indexOf(
            this.state.pageData[i].page_id
          );
          if (index > -1) {
            this.state.finalvalue.splice(index, 1);
          }
        }
      }
    } else {
      for (i = 0; i < this.state.pageData.length; i++) {
        this.state.finalvalue.push(this.state.pageData[i].page_id);
      }
    }

    const params = {
      app_id: localStorage.getItem("Appid"),
      data: this.state.finalvalue,
      select_all: this.state.selectall === true ? 1 : 0,
      type: 1, //1 for pages,2 for category,3 for blog
    };
    if (params.data.length === 0) {
      errortoast("Please select atleast one Menu page to proceed");
    } else {
      SetpageApi(params, this);
      this.setState({
        finalvalue: [],
      });
    }
  };

  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }

  render() {
    const urlname = () => {
      let store_url = "";
      if (
        localStorage.getItem("store_url")[
          localStorage.getItem("store_url").length - 1
        ] === "/"
      ) {
        store_url = localStorage.getItem("store_url");
      } else {
        store_url = localStorage.getItem("store_url") + "/";
      }
      return store_url + "wp-json/wp/v2/pages?per_page=20";
    };
    return (
      <>
        <BottomScrollListener
          onBottom={this.handleContainerOnBottom}
          offset={300}
        />
        <div className="d-md-block">
          <Header customclass={"header-responsive-new"} />
        </div>
        {/* Mobile View */}
        {/* <Mobiletop
          name={"App Menu"}
          imgpath={
            require("../../assets/images/dashboard/Settings.png").default
          }
        /> */}
        {/* Mobile View End*/}
        <section className="dasboard_page mobile-display mobile-view-download">
        <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
          {/* <div className="transparent_background ipad-menupage-scrollview"> */}
          <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>
            <div
              className="right_panel custon_gap2 pb-4"
              style={{ minHeight: "auto" }}
            >
              {this.state.spinner ? (
                <div className="spinn">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <></>
              )}
              <div className="d-md-flex d-none  align-items-center justify-content-between app_description mb-2 custom-pages social-accounts altpad">
                <div className="app_iconname pt-4 ipad-menupage-padding">
                  <div className="d-flex align-items-center">
                    <h3 className="ipad-menu-view">App Menu Page Selection</h3>
                    {/* <Amsvideo
                      timespan={572}
                      addclass="d-md-flex d-none align-items-center justify-content-between see_video"
                    /> */}
                  </div>
                  <h5 className="ipad-bottom-subtext">
                    Select all the pages you would like to display in the main
                    menu of your app.{" "}
                    {/* <Media
                      href="https://www.appmysite.com/support/menu-settings/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn More{" "}
                      <img
                        src={
                          require("../../assets/images/dashboard/Goto.png")
                            .default
                        }
                        className="gotoappicon appear-spacing"
                        alt="Goto"
                      />
                    </Media>{" "} */}
                  </h5>
                </div>
                <form onSubmit={this.handleFormSubmit}>
                  <div className="d-flex align-items-center justify-content-between pt-4 fixed-button bill-pay-top new-menu-border">
                    <Link to="/app/menu" className="back_btn mr-2">
                      Back
                    </Link>
                    <button
                      className="next_btn ml-2"
                      disabled={
                        this.state.bottomspinner === true ? true : false
                      }
                    >
                      <div className="d-flex align-items-center justify-content-center h-100 w-100">
                        <div className={this.state.loader}>
                          <img src={this.state.loaderimage} alt="loader" />
                        </div>{" "}
                        <div>{this.state.submitval}</div>
                      </div>
                    </button>
                  </div>
                </form>
              </div>
              <div className="right_general app-settings">
                {/* {Navigation("appmenu")} */}
                <div className="row">
                  <div className="col-md-12 pl-0 pr-0 pl-md-3 pr-md-3">
                    <div className="social_pannel">
                      <form onSubmit={this.handleFormSubmit}>
                        <section className="socila-pages webpages ">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="d-flex  align-items-center justify-content-between custom-page-head">
                                <div className="module_title">
                                  <h5>Select Pages</h5>
                                </div>
                                <div className="on_off  d-flex align-items-center">
                                  <span className="custom-pages">Show All</span>
                                  <label
                                    className="switch ml-2"
                                    htmlFor="cutompages"
                                  >
                                    <input
                                      type="checkbox"
                                      id="cutompages"
                                      checked={this.state.selectall}
                                      onChange={this.selectall}
                                    />
                                    <div className="slider round"></div>
                                  </label>
                                </div>
                              </div>
                            </div>
                            {this.state.errorstatus && (
                              <div className="trouble-shoot-steps text-center w-100">
                                <img
                                  src={
                                    require("../../assets/images/dashboard/statuserror.png")
                                      .default
                                  }
                                  alt="cross"
                                  className="img-fluid"
                                />
                                <h6 className="mt-5">{this.state.errordata}</h6>
                                <Media
                                  href={urlname()}
                                  target="_blank"
                                  rel="nofollow noopener noreferrer"
                                  className="mt-4 text-center d-block"
                                  style={{ color: "#3064F9" }}
                                >
                                  View this error on your website
                                </Media>
                              </div>
                            )}
                            {this.state.pageData.map((response, i) => (
                              <div className="col-md-12" key={i}>
                                <div className="d-flex  align-items-center justify-content-between common-height ">
                                  <div className="module_title">
                                    <h5>{ReactHtmlParser(response.title)}</h5>
                                  </div>
                                  <div className="on_off">
                                    {this.state.nottoshow === true ? (
                                      <></>
                                    ) : (
                                      <label
                                        className="switch"
                                        htmlFor={response.page_id}
                                      >
                                        {this.state.saved_pages.indexOf(
                                          response.page_id.toString()
                                        ) === -1 ? (
                                          <>
                                            <input
                                              type="checkbox"
                                              id={response.page_id}
                                              defaultChecked={false}
                                              onClick={this.handlestatus}
                                            />
                                            <div className="slider round"></div>
                                          </>
                                        ) : (
                                          <>
                                            <input
                                              type="checkbox"
                                              id={response.page_id}
                                              defaultChecked={true}
                                              onClick={this.handlestatus}
                                            />
                                            <div className="slider round"></div>
                                          </>
                                        )}
                                      </label>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                            {this.state.bottomspinner && (
                              <div className="text-center w-100 mt-3 mb-0">
                                <Spinner animation="grow" variant="primary" />
                              </div>
                            )}
                          </div>
                        </section>
                        <div className="d-md-none d-flex align-items-center justify-content-between mt-5 pt-md-4 pt-2 fixed-button bill-pay-top">
                          <Link
                            to="/app/menu"
                            className="back_btn"
                          >
                            Back
                          </Link>
                          <button
                            className="next_btn"
                            disabled={
                              this.state.bottomspinner === true ? true : false
                            }
                          >
                            <div className="d-flex align-items-center justify-content-center h-100 w-100">
                              <div className={this.state.loader}>
                                <img
                                  src={this.state.loaderimage}
                                  alt="loader"
                                />
                              </div>{" "}
                              <div>{this.state.submitval}</div>
                            </div>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {Foot()}
          </div>
        </section>
      </>
    );
  }
}
