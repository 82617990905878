import React, {useEffect, useState} from "react";
import "./assets/css/common.css";
import Header from "./../Dashboard/Header";
//import LeftPanel from "./LeftPanel";
import { errortoast, sucesstoast } from "../Dashboard/Toaster";
import {
    saveAgency,
    getlicenceDeals,
} from "../Api/Agency/AgencyApi";
import { Carousel } from 'react-responsive-carousel';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from 'reactstrap';

const CreateAgency = (props) =>{

    const [orgname, setOrgName] = useState("");
    const [btnsubmitnamne, setBtnSubmitName] = useState("Create");
    const [btnloader, setBtnLoader] = useState("d-none");
    const [errname, setErrName] = useState(false);
    const [sliderStatus, setSliderStatus] = useState(true);
    const [licence_id, Setlicenceid] = useState("");
    const [licence_planname, Setlicenceplanname] = useState("Select");
    const [openAppDropdown,SetopenAppDropdown] = useState(false);
    const [openMobileAppDropdown,SetopenMobileAppDropdown] = useState(false);
    const [licence_type,Setlicence_type] = useState([]);
    const [licence,Setlicence] = useState([]);
    const [spinner,Setspinner] = useState(true);

    document.title = "Create Agency | AppMySite";

    document.body.classList.remove('opec3');
    document.body.classList.remove('opec4');

    useEffect(()=>{
		getlicenceDeals(Setlicence,Setspinner);
    }, []);

    const handleOrgName = (e) =>{

        setOrgName(e.target.value);
        setErrName(false);
    }

    const handleCreateAgency = (e) =>{
        e.preventDefault();

        if(orgname === '' )
        {
            setErrName(true);
            return;
        }

        const params = {
                        "name": orgname,
                        "agency_id": "",
                        "licence_id":licence_id,
                        };

        setBtnLoader("d-block");
        setBtnSubmitName("");

        let createbtndiv = document.getElementById("btncreateorgid");
		createbtndiv.classList.add("btnloader");

        saveAgency(params, setBtnLoader, setBtnSubmitName, redirectToOrgDetailPage);

    }

    const redirectToOrgDetailPage = (redirecturl) =>{

        props.history.push(redirecturl);
    }

    const handlelicence_id = (e,planame) => 
    {
          Setlicenceid(e === "" ? "" : e);
          Setlicenceplanname(planame == "" ? '' : planame);
    };

    const handleAppdropdown = () => {
   
        SetopenAppDropdown(!openAppDropdown);
        
      };
    
    const handleMobileAppDropdown=()=>{
   
        SetopenMobileAppDropdown(!openMobileAppDropdown);
    }

    return (
        <div className="org-module">
            <Header />
            {spinner ? (
                <div className="newspinner createagency-spinner">
                <svg xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                    width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                    <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                        style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        </animateTransform>
                    </circle>
                    </g>
                    <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                    <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                        style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        </animateTransform>
                    </circle>
                    </g>
                    <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                    <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                        style={{ animationplaystate: "running", animationdelay: "0s" }}>

                        </animateTransform>
                    </circle>
                    </g>
                    <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                    <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                        style={{ animationplaystate: "running", animationdelay: "0s" }}>

                        </animateTransform>
                    </circle>
                    </g>
                </svg>
                </div>
            ) 
            : 
            (
                <section>

                <div className="create-agency-main">
                    
                    <div className="create-agency-left">
                        <div className="create-agency-left-position">
                            <h1>Create an agency</h1>
                            <h2>Set up your white-labeled app development business in minutes.</h2>
                            <label>Agency name*</label>

                            { errname ?
                                <input type="text" autocomplete="off" name="orgname" value={orgname} onChange={ handleOrgName } className="errormessages"></input>
                            :
                                <input type="text" autocomplete="off" name="orgname" value={orgname} onChange={ handleOrgName }></input>
                            }

                            {licence.length > 0 &&
                            (
                            <div className="form-group createapp-lastinput">
                                <p className="new-web-type-url">Licence type</p>

                                <div className="createapp-dropdown">
                                    <Dropdown isOpen={openAppDropdown}
                                            onClick={handleMobileAppDropdown}
                                            toggle={handleAppdropdown}>
                                    <DropdownToggle caret>{licence_planname}</DropdownToggle>
                                    <DropdownMenu >
                                    <DropdownItem active={licence_id == ""}  onClick={(e) => handlelicence_id('','Select')}>
                                    Select
                                    </DropdownItem>

                                    {licence.length > 0 && licence.map((res, i) => 
                                        {
                                            return  res.used_agency_licence === res.total_agency_licence ? 
                                            (
                                                <DropdownItem className="expireddeal" value={res.licence_uuid} key={i}>
                                                <span>{res.plan_data.plan_name}</span>
                                                <span>{res.used_agency_licence + (res.total_agency_licence === "" ? "" : "/") +res.total_agency_licence} used</span> 
                                                </DropdownItem>
                                            )
                                            : res.status == "inactive" ? 
                                            (
                                                <DropdownItem className="expireddeal" value={res.licence_uuid} key={i}>
                                                <span>{res.plan_data.plan_name}</span> 
                                                <span>Expired</span>
                                                </DropdownItem>
                                            )
                                            : res.is_agency_deal == 1 ? 
                                            (
                                                <DropdownItem active={res.licence_uuid == licence_id} onClick={(e) => handlelicence_id(res.licence_uuid,res.plan_data.plan_name)} value={res.licence_uuid} key={i}>
                                                <span>{res.plan_data.plan_name}</span>
                                                {res.total_agency_licence != '' ?
                                                    <span>{res.used_agency_licence + (res.total_agency_licence === "" ? "" : "/") +res.total_agency_licence} used</span>
                                                :
                                                    <span>Unlimited</span>
                                                } 
                                                
                                                </DropdownItem>
                                            )
                                            :
                                            (
                                                <DropdownItem className="disableddeal" value={res.licence_uuid} key={i}>
                                                    <span>{res.plan_data.plan_name}</span> 
                                                    <span>Not applicable</span>
                                                </DropdownItem>
                                            );
                                        }
                                    )}
                                
                                    </DropdownMenu>
                                    </Dropdown>
                                </div>
                            </div>
                            )}
                            <button onClick={ handleCreateAgency }  id="btncreateorgid">
                                <div className={btnloader}>
                                    <div className="new-loader"></div>
                                </div>
                                { btnsubmitnamne }
                                
                            </button>

                            <div className="agency-disclamier">
                            <h5>By creating an Agency white-label, you agree to our <a target="_blank" href="https://www.appmysite.com/terms-conditions/">Terms & Conditions</a> & <a target="_blank" href="https://www.appmysite.com/privacy-policy/">Privacy Policy</a>. As an Agency white-label user, you are liable for any and all activities occurring within your account, by yourself or by customers, with or without your permission.</h5>
                        </div>
                            
                        </div>
                    </div>

                    <div className="create-agency-right">
                        <div className="create-agency-right-position">
                            <img className="img-fluid" src={ require("./assets/images/create-agency.png").default } alt="create-agency" />

                            <div className="signup-crousel">
                                    <Carousel autoPlay={sliderStatus} infiniteLoop={sliderStatus} classNamePrefix="signup-carousel">
                
                                    <div >
                                        <h2>Built for agencies</h2>
                                        <h3>Manage all your customers and apps in one place.</h3>
                                        </div>
                                        <div className="">
                                        <h2>Custom branded app builder</h2>
                                        <h3>An app development platform with your brand on it.</h3>
                                        </div>
                                        <div className="">
                                        <h2>No coding required</h2>
                                        <h3>Plenty of drag and drop customizations to choose from.</h3>
                                        </div>
                                        <div className="">
                                        <h2>Create apps at scale</h2>
                                        <h3>Design and publish unlimited apps to the app stores.</h3>
                                        </div> 
                                    </Carousel>
                                    
                                </div>

                        </div>
                    </div>
                </div>

                </section>
            )
        }
        </div>
        
    );
}

export default CreateAgency;