import React, { useState, useEffect} from "react";

import { Label, Input } from "reactstrap";

import Select from "react-select";

const LocationSegment = ({keydata, locRadius, setLocRadius, locLatitude, setLocLatitude, locFilterType, setLocFilterType, locLogitude, setLocLongitude, 
    locoperators, setLocOperators, onRemoveLoc, locRowOpen, locopenrownumber, selectruleserror, selectvalueerror, selectvalueerror1, duplicateLocSegment}) =>{

		
        const rulesoption = [
            { value: "long", label: "longtiude" },
            { value: "lat", label: "latitude" },
        ];


        useEffect(()=>{
    
            let getlocfiltertype = [...locFilterType];
            getlocfiltertype[keydata] = 7;
            setLocFilterType(getlocfiltertype);
    
            let getoperator = [...locoperators];
            
            let filterdivcount = document.querySelectorAll('.define-filters').length;
            
            if(filterdivcount > 1 && (filterdivcount - 1) !== keydata)
            {
                
                getoperator[keydata]  = "AND";
                setLocOperators(getoperator);
            }
    
        }, []);
    
    
        const handleLRadiusChange = (e) =>
        {
            e.preventDefault();
            //setLocRadiusKey(e.target.value);
            if(e.target.value >= 0)
            {
                let getlockey = [...locRadius];
                getlockey[keydata] = e.target.value;
                setLocRadius(getlockey);
            }
            else
            {
                let getlockey = [...locRadius];
                getlockey[keydata] = "";
                setLocRadius(getlockey);
            }
            
        }
    
        const handleLLatInputChange = (e) =>
        {
            e.preventDefault();
            
            //setLocLatValue(e.target.value);
            if(e.target.value >= -90 && e.target.value <= 90)
            {
                let getlocvalue = [...locLatitude];
                getlocvalue[keydata] = e.target.value;
                setLocLatitude(getlocvalue);
            }
            else if(e.target.value == "-" || e.target.value == "+")
            {
                let getlocvalue = [...locLatitude];
                getlocvalue[keydata] = e.target.value;
                setLocLatitude(getlocvalue);
            }
            else
            {
                let getlocvalue = [...locLatitude];
                getlocvalue[keydata] = "";
                setLocLatitude(getlocvalue);
            }
        }
    
        const handleLLongInputChnge = (e) =>{
            e.preventDefault();
            
            //setLocLongValue(e.target.value);
            if(e.target.value >= -180 && e.target.value <= 180)
            {
                let getlocvalue1 = [...locLogitude];
                getlocvalue1[keydata] = e.target.value;
                setLocLongitude(getlocvalue1);
            }
            else if(e.target.value == "-" || e.target.value == "+")
            {
                let getlocvalue1 = [...locLogitude];
                getlocvalue1[keydata] = e.target.value;
                setLocLongitude(getlocvalue1);
            }
            else
            {
                let getlocvalue1 = [...locLogitude];
                getlocvalue1[keydata] = "";
                setLocLongitude(getlocvalue1);
            }
        }


    return(
        <div className="accordionItem">
            <div onClick={ (e) => locRowOpen(e, 7, keydata) }>
                <div className="heading-wrapper accordionItemHeading allflex">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.23 30.328">
                        <path id="Path_83752" data-name="Path 83752" d="M20.615,4A10.617,10.617,0,0,0,10,14.615c0,7.961,10.615,19.713,10.615,19.713S31.23,22.576,31.23,14.615A10.617,10.617,0,0,0,20.615,4Zm0,14.406a3.791,3.791,0,1,1,3.791-3.791A3.792,3.792,0,0,1,20.615,18.406Z" transform="translate(-10 -4)" fill="#ff7d7d"/>
                    </svg>
                    <h2 className="heading">Location</h2>
                </div>
                <div className="arrow-img">
                    { locopenrownumber[keydata] === true ?   
                        <svg xmlns="http://www.w3.org/2000/svg" width="13.061" height="7.592" viewBox="0 0 13.061 7.592">
                            <path id="Path_81302" data-name="Path 81302" d="M-12935-18908l6-6,6,6" transform="translate(12935.531 18915.064)" fill="none" stroke="#7782a1" stroke-width="1.5" />
                        </svg>
                    :
                        <svg xmlns="http://www.w3.org/2000/svg" width="13.061" height="7.592" viewBox="0 0 13.061 7.592">
                            <path id="Path_81302" data-name="Path 81302" d="M-12935-18914l6,6,6-6" transform="translate(12935.531 18914.535)" fill="none" stroke="#7782a1" stroke-width="1.5" />
                        </svg>
                    }
                </div>
            </div>

            { locopenrownumber[keydata] === true &&
            <div className="accordian-text-wrapper">
                <div className="accordion-text">
                    <div className="select-wrapper location-select-wrapper">

                        <Input type="number" name="radius" onChange={handleLRadiusChange} value={ locRadius[keydata] } placeholder="Radius" className={`segment-input-full ${selectruleserror[keydata] ? "segmet-error-input" : ""} `} />
                        {/*<div className="mobile-view-click">
                            <Select options={rulesoption} onChange={handleLSSelectChange } defaultValue={lsKey[keydata] } isSearchable={false} isClearable={false} isDisabled={ window.matchMedia("(max-width: 1200px)").matches ? true : false } classNamePrefix="profile-role-dropdown" />
                        </div>*/}

                      
                    </div>
                    <div className="audience-category-parent-dropdown location-select-wrapper">

                        <Input type="number" name="latitude" onChange={handleLLatInputChange} value={locLatitude[keydata]} placeholder="Latitude" className={`segment-input-full ${selectvalueerror[keydata] ? "segmet-error-input" : ""} `} />
                        {/*<span>hours ago</span>*/}
                    </div>

                  

                    <div className="location-select-lat">
                        <Input type="number" name="longtiude" onChange={handleLLongInputChnge} value={locLogitude[keydata]} placeholder="Longitude" className={ selectvalueerror1[keydata] ? "segmet-error-input" : "" } />
                    </div>
                </div>
                <div className="delete-and-copy">
                    {/* <div className="copy" onClick={(e) =>duplicateLocSegment(e, 7)}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="15.001" height="15" viewBox="0 0 15.001 15" >
                            <g id="Group_26180" data-name="Group 26180" transform="translate(-450.5 -124.5)" >
                                <g id="Path_9276" data-name="Path 9276" transform="translate(0 0)" fill="none" stroke-miterlimit="10" >
                                    <path d="M462.5,125.923V128.5h-6.72a1.189,1.189,0,0,0-1.28,1.28v6.72h-2.577a1.419,1.419,0,0,1-1.423-1.423v-9.154a1.419,1.419,0,0,1,1.423-1.423h9.154A1.419,1.419,0,0,1,462.5,125.923Z" stroke="none" />
                                    <path d="M 451.9228515625 125.5000152587891 C 451.6857299804688 125.5000152587891 451.5 125.6857452392578 451.5 125.9228668212891 L 451.5 135.0771636962891 C 451.5 135.3142700195312 451.6857299804688 135.5 451.9228515625 135.5 L 453.5 135.5 L 453.5 129.7800140380859 C 453.5 128.4375762939453 454.4375610351562 127.5 455.7799682617188 127.5 L 461.5 127.5 L 461.5 125.9228668212891 C 461.5 125.6857452392578 461.3142395019531 125.5000152587891 461.0771179199219 125.5000152587891 L 451.9228515625 125.5000152587891 M 451.9228515625 124.5000152587891 L 461.0771179199219 124.5000152587891 C 461.8656616210938 124.5000152587891 462.5 125.1343078613281 462.5 125.9228668212891 L 462.5 128.5 L 455.7799682617188 128.5 C 454.9913940429688 128.5 454.5 128.991455078125 454.5 129.7800140380859 L 454.5 136.5 L 451.9228515625 136.5 C 451.13427734375 136.5 450.5 135.8657531738281 450.5 135.0771636962891 L 450.5 125.9228668212891 C 450.5 125.1343078613281 451.13427734375 124.5000152587891 451.9228515625 124.5000152587891 Z" stroke="none" fill="#7782a1" />
                                </g>
                                <g id="Path_9277" data-name="Path 9277" transform="translate(-1.999 -2)" fill="none" stroke-miterlimit="10" >
                                    <path d="M467.5,130.923v9.154a1.419,1.419,0,0,1-1.423,1.423h-9.154a1.419,1.419,0,0,1-1.423-1.423v-9.154a1.419,1.419,0,0,1,1.423-1.423h9.154A1.419,1.419,0,0,1,467.5,130.923Z" stroke="none" />
                                    <path d="M 456.9228210449219 130.5000152587891 C 456.6856994628906 130.5000152587891 456.4999694824219 130.6857452392578 456.4999694824219 130.9228515625 L 456.4999694824219 140.0771484375 C 456.4999694824219 140.3142700195312 456.6856994628906 140.5000152587891 456.9228210449219 140.5000152587891 L 466.0771179199219 140.5000152587891 C 466.3142395019531 140.5000152587891 466.4999694824219 140.3142700195312 466.4999694824219 140.0771484375 L 466.4999694824219 130.9228515625 C 466.4999694824219 130.6857452392578 466.3142395019531 130.5000152587891 466.0771179199219 130.5000152587891 L 456.9228210449219 130.5000152587891 M 456.9228210449219 129.5000152587891 L 466.0771179199219 129.5000152587891 C 466.8656921386719 129.5000152587891 467.4999694824219 130.1342926025391 467.4999694824219 130.9228515625 L 467.4999694824219 140.0771484375 C 467.4999694824219 140.86572265625 466.8656921386719 141.5000152587891 466.0771179199219 141.5000152587891 L 456.9228210449219 141.5000152587891 C 456.1342163085938 141.5000152587891 455.4999694824219 140.86572265625 455.4999694824219 140.0771484375 L 455.4999694824219 130.9228515625 C 455.4999694824219 130.1342926025391 456.1342163085938 129.5000152587891 456.9228210449219 129.5000152587891 Z" stroke="none" fill="#7782a1" />
                                </g>
                            </g>
                        </svg>
                    </div> */}
                    <div className="delete">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.999" viewBox="0 0 14 13.999" onClick = { (e)=>onRemoveLoc(e, keydata) } >
                            <g id="Group_5465" data-name="Group 5465" transform="translate(-835.516 -682.992)">
                                <g id="Rectangle_2307" data-name="Rectangle 2307" transform="translate(836.515 684.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1">
                                    <path d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z" stroke="none"/>
                                    <path d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z" fill="none"/>
                                </g>
                                <g id="Rectangle_2308" data-name="Rectangle 2308" transform="translate(839.515 682.992)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" >
                                    <path d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z" stroke="none" />
                                    <path d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z" fill="none" />
                                </g>
                                <g id="Group_3245" data-name="Group 3245" transform="translate(841.016 687.993)" >
                                    <line id="Line_110" data-name="Line 110" y2="6" transform="translate(3 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                                    <line id="Line_111" data-name="Line 111" y1="6" transform="translate(0 0)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                                </g>
                                <line id="Line_112" data-name="Line 112" x2="14" transform="translate(835.516 685.492)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1" />
                            </g>
                        </svg>
                    </div>
                </div>
            </div>
            }
        </div>
    );
}

export default LocationSegment;