import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { ParentFilterModal } from "../../../Dashboard/CmsBuilder/ParentFilterModal";
import deleteIc from "../../../assets/images/dashboard/delete-icon.png";

import plusIc from "../../../assets/images/dashboard/plus.png";
import Select from "react-select";
import DatePicker from "react-datepicker";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {
  DEFAULT_FILTER_OPTIONS,
  DEFAULT_FILTER_VALUE,
  DEVICE_TYPE,
  FILTER_CUSTOM_STYLES,
  FILTER_STATUS,
} from "../../../Constants/notificationConsts";

const FilterDrawer = ({
  notificationData,
  setNotificationData,
  handleFilterSubmit,
  handleResetFilter,
}) => {
  const handleDrawerClose = () => {
    if (window.matchMedia("(min-width: 1200px)").matches)
      setNotificationData({ isNotifFilterOpen: false });
    document.body.classList.remove("opec");
  };

  const handleOpenParentDropdown = (index) => {
    if (index === 0) {
      setNotificationData({
        openMobParentFilter1: !notificationData.openMobParentFilter1,
        openMobParentFilter2: false,
        openMobParentFilter3: false,
      });
    } else if (index === 1) {
      setNotificationData({
        openMobParentFilter2: !notificationData.openMobParentFilter2,
        openMobParentFilter1: false,
        openMobParentFilter3: false,
      });
    } else if (index === 2) {
      setNotificationData({
        openMobParentFilter3: !notificationData.openMobParentFilter3,
        openMobParentFilter1: false,
        openMobParentFilter2: false,
      });
    }
  };

  const handleMainFilterChange = (option, index) => {
    let isMatch = false;
    let newArr = [...notificationData.filterSelectionArr];
    let obj = {
      index: index,
      value: option.value,
    };
    newArr &&
      newArr.forEach((row, indx) => {
        if (row.index === index) {
          row.value = option.value;
          isMatch = true;
        }
      });
    if (!isMatch) newArr.push(obj);

    setNotificationData({
      filterSelectionArr: newArr,
    });
    handleOpenParentDropdown(index);
  };

  const handleFilterReset = () => {
    setTimeout(() => {
      handleResetFilter();
    }, 500);
  };

  const handleAddField = () => {
    let newArr = [...notificationData.filterSelectionArr];
    let obj = {
      index: notificationData.filterSelectionArr?.length,
      value: "",
    };
    newArr.push(obj);
    setNotificationData({ filterSelectionArr: newArr });
  };

  const returnSelectedStatusLabel = (status) => {
    let label = null;
    if (status === "all") {
      label = "All";
    } else if (status === FILTER_STATUS.SENT) {
      label = "Sent";
    } else if (status === FILTER_STATUS.DRAFT) {
      label = "Draft";
    } else if (status === FILTER_STATUS.SCHEDULED) {
      label = "Scheduled";
    }
    return label;
  };
  const returnSelectedDeviceLabel = (device) => {
    let label = null;
    if (device === DEVICE_TYPE.android) {
      label = "Android";
    } else if (device === DEVICE_TYPE.ios) {
      label = "iOS";
    } else if (device === DEVICE_TYPE.both) {
      label = "Both";
    }
    return label;
  };

  const handleStatusFilterChange = (val) =>
    setNotificationData({
      statusFilterVal: val,
      openMobStatusFilter: false,
    });

  const handleDeviceFilterChange = (val) =>
    setNotificationData({
      deviceFilterVal: val,
      openMobDeviceFilter: false,
    });

  const handleFilterDateChange = (dates) => {
    const [start, end] = dates;

    setNotificationData({
      startDate: start,
      endDate: end,
    });
  };

  const handleDeleteFilter = (val, key, from) => {
    if (val === DEFAULT_FILTER_OPTIONS[1].value) {
      setNotificationData({
        startDate: new Date(),
        endDate: null,
      });
    }
    if (val === DEFAULT_FILTER_OPTIONS[0].value) {
      setNotificationData({
        statusFilterVal: null,
      });
    }
    if (val === DEFAULT_FILTER_OPTIONS[2].value) {
      setNotificationData({
        deviceFilterVal: null,
      });
    }
    let newArr = [...notificationData.filterSelectionArr];
    newArr.splice(key, 1);
    newArr &&
      newArr.map((row, indx) => {
        row.index = indx;
      });
    setNotificationData({ filterSelectionArr: newArr });
  };
  const handleStatusFilter = () => {
    if (window.matchMedia("(max-width: 1200px)").matches)
      setNotificationData({
        openMobStatusFilter: !notificationData.openMobStatusFilter,
      });
  };

  const handleDeviceFilter = () => {
    if (window.matchMedia("(max-width: 1200px)").matches)
      setNotificationData({
        openMobDeviceFilter: !notificationData.openMobDeviceFilter,
      });
  };

  return (
    <OutsideClickHandler onOutsideClick={() => handleDrawerClose()}>
      <div
        className={`${
          notificationData.isNotifFilterOpen
            ? "pushnotification-filteropen"
            : "pushnotification-filterclose"
        }`}
      >
        <div className="d-flex justify-content-between ">
          <h4 className="push-filterhead">Filter</h4>
          <p className="push-resetbtn" onClick={() => handleFilterReset()}>
            Reset
          </p>
        </div>

        <div className="myapps-filter-seconddiv">
          {notificationData.filterSelectionArr.map((item, index) => {
            return (
              <div key={index}>
                {index > 0 && <h2 className="andpushnotificaion">AND</h2>}
                <div className="post-filter-dropdown notification-filter-dropdown">
                  <div
                    className="mobile-view-click notification-post-filter"
                    onClick={
                      window.matchMedia("(max-width: 1200px)").matches
                        ? () => handleOpenParentDropdown(index)
                        : null
                    }
                  >
                    <Select
                      options={notificationData.optionForMainFilter}
                      classNamePrefix="post-filter"
                      onChange={(option) =>
                        handleMainFilterChange(option, index)
                      }
                      required={true}
                      placeholder={DEFAULT_FILTER_VALUE}
                      styles={FILTER_CUSTOM_STYLES}
                      isClearable={false}
                      isSearchable={false}
                      value={notificationData.optionForMainFilter.filter(
                        (option) => option.value === item.value
                      )}
                      isDisabled={
                        window.matchMedia("(max-width: 1200px)").matches
                          ? true
                          : false
                      }
                      isOptionDisabled={(option) => {
                        if (notificationData.filterSelectionArr.length > 0) {
                          return notificationData.filterSelectionArr.find(
                            (row) => row.value === option.value
                          );
                        } else {
                          return false;
                        }
                      }}
                    />
                  </div>
                  <ParentFilterModal
                    key={index}
                    handleMainFilterChange={handleMainFilterChange}
                    handleOpenParentDropdown={handleOpenParentDropdown}
                    openMobParentFilter={
                      index === 0
                        ? notificationData.openMobParentFilter1
                        : index === 1
                        ? notificationData.openMobParentFilter2
                        : notificationData.openMobParentFilter3
                    }
                    optionForMainFilter={notificationData.optionForMainFilter}
                    filterSelectionArr={notificationData.filterSelectionArr}
                    index={index}
                    value={item.value}
                  />
                </div>
                <div className="newdelete-post-filter">
                  {item.value === DEFAULT_FILTER_OPTIONS[0].value ? ( //status
                    <div className="post-filter-dropdown notification-filter-dropdown">
                      <Dropdown
                        isOpen={
                          window.matchMedia("(max-width: 1200px)").matches
                            ? false
                            : notificationData.openStatusFilter
                        }
                        onClick={handleStatusFilter}
                        toggle={() =>
                          setNotificationData({
                            openStatusFilter:
                              !notificationData.openStatusFilter,
                          })
                        }
                      >
                        <DropdownToggle caret>
                          {notificationData.statusFilterVal
                            ? returnSelectedStatusLabel(
                                notificationData.statusFilterVal
                              )
                            : DEFAULT_FILTER_VALUE}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() =>
                              handleStatusFilterChange(FILTER_STATUS.ALL)
                            }
                            active={
                              notificationData.statusFilterVal ===
                              FILTER_STATUS.ALL
                            }
                          >
                            All
                          </DropdownItem>
                          <DropdownItem
                            onClick={() =>
                              handleStatusFilterChange(FILTER_STATUS.DRAFT)
                            }
                            active={
                              notificationData.statusFilterVal ===
                              FILTER_STATUS.DRAFT
                            }
                          >
                            Draft
                          </DropdownItem>

                          <DropdownItem
                            onClick={() =>
                              handleStatusFilterChange(FILTER_STATUS.SCHEDULED)
                            }
                            active={
                              notificationData.statusFilterVal ===
                              FILTER_STATUS.SCHEDULED
                            }
                          >
                            Scheduled
                          </DropdownItem>

                          <DropdownItem
                            onClick={() =>
                              handleStatusFilterChange(FILTER_STATUS.SENT)
                            }
                            active={
                              notificationData.statusFilterVal ===
                              FILTER_STATUS.SENT
                            }
                          >
                            Sent
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  ) : item.value === DEFAULT_FILTER_OPTIONS[2].value ? (
                    <div className="post-filter-dropdown notification-filter-dropdown">
                      <Dropdown
                        isOpen={
                          window.matchMedia("(max-width: 1200px)").matches
                            ? false
                            : notificationData.openDeviceFilter
                        }
                        onClick={handleDeviceFilter}
                        toggle={() =>
                          setNotificationData({
                            openDeviceFilter:
                              !notificationData.openDeviceFilter,
                          })
                        }
                      >
                        <DropdownToggle caret>
                          {notificationData.deviceFilterVal
                            ? returnSelectedDeviceLabel(
                                notificationData.deviceFilterVal
                              )
                            : DEFAULT_FILTER_VALUE}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() =>
                              handleDeviceFilterChange(DEVICE_TYPE.android)
                            }
                            active={
                              notificationData.deviceFilterVal ===
                              DEVICE_TYPE.android
                            }
                          >
                            Android
                          </DropdownItem>

                          <DropdownItem
                            onClick={() =>
                              handleDeviceFilterChange(DEVICE_TYPE.ios)
                            }
                            active={
                              notificationData.deviceFilterVal ===
                              DEVICE_TYPE.ios
                            }
                          >
                            iOS
                          </DropdownItem>

                          <DropdownItem
                            onClick={() =>
                              handleDeviceFilterChange(DEVICE_TYPE.both)
                            }
                            active={
                              notificationData.deviceFilterVal ===
                              DEVICE_TYPE.both
                            }
                          >
                            Both
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  ) : item.value === DEFAULT_FILTER_OPTIONS[1].value ? (
                    <div className="post-filter-dropdown pushnotification-lastsetting notification-filter-dropdown">
                      <div className="common-filter-body-datepicker">
                        <DatePicker
                          className="date-input-post2"
                          selected={notificationData.startDate}
                          onChange={handleFilterDateChange}
                          startDate={notificationData.startDate}
                          endDate={notificationData.endDate}
                          selectsRange
                          dateFormat="d MMM yyyy"
                        />
                      </div>
                    </div>
                  ) : null}

                  {item.value &&
                  notificationData.filterSelectionArr?.length > 1 ? (
                    <span>
                      <button
                        className="delete-post-filter-button filter-delete-pushnotification"
                        onClick={() => handleDeleteFilter(item.value, index)}
                      >
                        <img src={deleteIc} alt="delete" />
                      </button>
                    </span>
                  ) : null}
                </div>
              </div>
            );
          })}
        </div>
        {notificationData.filterSelectionArr?.length < 3 ? (
          <div className="pushNotification-filteradd">
            <img src={plusIc} alt="plus" className="img-fluid" />

            <p className="add-more-field" onClick={() => handleAddField()}>
              Add Field
            </p>
          </div>
        ) : null}

        <div className=" advance-search-button">
          <button
            className={
              (notificationData.startDate && notificationData.endDate) ||
              notificationData.deviceFilterVal ||
              notificationData.statusFilterVal
                ? "filter-buttonenable"
                : "filter-buttondisabled"
            }
            onClick={handleFilterSubmit}
            style={{
              backgroundColor: localStorage.getItem("button_background_color"),
              border:
                "1px solid " + localStorage.getItem("button_background_color"),
              color: localStorage.getItem("button_text_color"),
              "--custom_color5":
                +localStorage.getItem("agencyid") === 1
                  ? "#134bec"
                  : localStorage.getItem("button_hover_bgcolor"),
              "--custom_color4":
                +localStorage.getItem("agencyid") === 1
                  ? "white"
                  : localStorage.getItem("button_hover_txtcolor"),
              "--custom_color6":
                +localStorage.getItem("agencyid") === 1
                  ? "#3064f9"
                  : localStorage.getItem("button_hover_bgcolor"),
            }}
          >
            Apply
          </button>
        </div>
      </div>
    </OutsideClickHandler>
  );
};

export default FilterDrawer;
