import React, { useEffect, useState } from "react";
import "./ItemTypePopUp.css";
import BottomScrollListener from "react-bottom-scroll-listener";

import {
  Modal,
  ModalHeader,
  ModalBody,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";

const convertHTMLEntity = (text) => {
  const span = document.createElement("span");

  if (text !== undefined) {
    return text.replace(/&[#A-Za-z0-9]+;/gi, (entity, position, text) => {
      span.innerHTML = entity;
      return span.innerText;
    });
  }
};

export const SingleSelectionPopUpForCustomApp = ({
  openPopUp,
  handlePopUp,
  data,
  handleInputClick,
  selectedId,
  setSubData,
  subData,
  searchVal,
  setSearchVal,
  breadcrumbArr,
  setBreadcrumbArr,
  from,
  oldBreadcrumbArr,
  oldSubData,
  handleBottomScrollListner,
  paginationSpinner,
  hideSelectOption,
  pageOrPostId,
  defaultOption,
  shouldNotBeSelected
}) => {

  const handleSubData = (e, subData, res) => {
    setSubData(subData);
    let newArr = [...breadcrumbArr];
    newArr.push({
      name: res[from === "page" ? "title" : "name"],
      id: res._id,
    });

    setBreadcrumbArr(newArr);
  };

  const handlePopUpState = (e,from) => {
    setSearchVal("");
    handlePopUp(e, from);
  }

  const handleHomeBtnClick = (e, subData) => {
    setSubData(subData);
    setBreadcrumbArr([]);
  };

  const handleBreadcrumb = (e, val, indx) => {
    let newData = [];
    let newBreadcrumb = [];
    let breadcrumb = [...breadcrumbArr];
    for (let i = 0; i < breadcrumb.length; i++) {
      if (val.name === breadcrumb[i].name) {
        newBreadcrumb.push(breadcrumb[i]);
        break;
      } else {
        newBreadcrumb.push(breadcrumb[i]);
      }
    }
    setBreadcrumbArr(newBreadcrumb);

    function recurs(arr) {
      arr.map((eachCat) => {
        if (eachCat.parent === val.id) {
          newData.push(eachCat);
        }

        if (eachCat.child !== undefined && eachCat.child.length > 0) {
          // if parent has childrens
          recurs(eachCat.child);
        }

        // return arr;
      });
    }
    recurs(data);

    setSubData(newData);
  };

  const handleMobBackBtn = (e) => {
    let breadcrumb = [...breadcrumbArr];
    if (breadcrumb && breadcrumb.length > 0) {
      let newData = [];
      breadcrumb.splice(-1, 1);
      setBreadcrumbArr(breadcrumb);
      function recurs(arr) {
        arr.map((eachCat) => {
          if (eachCat.parent === breadcrumb[breadcrumb.length - 1].id) {
            newData.push(eachCat);
          }

          if (eachCat.child !== undefined && eachCat.child.length > 0) {
            recurs(eachCat.child);
          }
        });
      }

      if (breadcrumb && breadcrumb.length > 0) {
        recurs(data);
        setSubData(newData);
      } else {
        setSubData(data);
      }
    } else {
      handlePopUpState(e, "cancel");
    }
  };

  const handleSearchVal = (e) => {
    setSearchVal(e.target.value);
  };

  const handleReturnTitle = (res) => {
    let title1 = "";
    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      title1 = res.title === undefined ? res.name : res.title;
    } else {
      title1 = convertHTMLEntity(
        res.title === undefined ? res.name : res.title.rendered
      );
    }
    return title1;
  };

  const filterArr =
  subData &&
  subData.filter((row) =>{

    if(    
      ( parseInt( localStorage.getItem("website_technology")) === 3 && row.status !== "draft" && row.show_in_results !==0 && pageOrPostId !== row._id)
     || parseInt( localStorage.getItem("website_technology")) !== 3 
      ){
          if(handleReturnTitle(row)
          .toLowerCase()
          .includes(searchVal.toLowerCase()))
          {
             return true
          } else{
             return false;
          }
      }else{
        return false;
      }
});



  return (
    <div>
      <Modal
        className="Single-select-popup"
        isOpen={openPopUp}
        centered
        toggle={(e) => handlePopUpState(e, "cancel")}
      >
        <ModalHeader>
          <div className="single-select-header">
            <h2>Link</h2>

            <div className="mob-single-select-top">
              {breadcrumbArr && breadcrumbArr.length > 0 && (
                <div className="single-select-bredcrums">
                  <p onClick={(e) => handleHomeBtnClick(e, data)}>Home &gt;</p>
                  {breadcrumbArr &&
                    breadcrumbArr.map((breadcrumb, indx) => {
                      return indx === 0 ? (
                        <p
                          className={
                            indx === breadcrumbArr.length - 1
                              ? "single-select-bredcrumchild"
                              : ""
                          }
                          onClick={(e) =>
                            indx === breadcrumbArr.length - 1
                              ? null
                              : handleBreadcrumb(e, breadcrumb, indx)
                          }
                        >
                          {breadcrumb.name}{" "}
                        </p>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          &gt;
                          <p
                            className={
                              indx === breadcrumbArr.length - 1
                                ? "single-select-bredcrumchild"
                                : ""
                            }
                            onClick={(e) =>
                              indx === breadcrumbArr.length - 1
                                ? null
                                : handleBreadcrumb(e, breadcrumb, indx)
                            }
                          >
                            {breadcrumb.name}
                          </p>
                        </div>
                      );
                    })}
                </div>
              )}

              <div className="mob-single-select-search">
                <svg
                  onClick={(e) => handleMobBackBtn(e)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
                
                <div className="singleselect-serachdiv">
                  <input
                    placeholder="Search"
                    value={searchVal}
                    onChange={(e) => handleSearchVal(e)}
                  />
                  {searchVal && window.matchMedia("(min-width: 1200px)").matches && 
                    <p
                      className="Single-selct-search-clear"
                      onClick={() => {
                        setSearchVal("");
                      }}
                    >
                      Clear
                    </p>
                  }
                 <p className={"mob-popUp-done-enable"}
                 onClick={(e) =>handlePopUpState(e, "open")}>Done</p>
             
                </div>
                <div
                  className="single-select-serach-crossdiv"
                  onClick={(e) => handlePopUpState(e, "cancel")}
                >
                  <img
                    src={
                      require("../../assets/images/dashboard/cross.png").default
                    }
                  />
                </div>
              </div>
            </div>

            <div
              className="publish-crossmodal single-select-web-cross"
              onClick={(e) => handlePopUpState(e, "cancel")}
              style={{
                zIndex: "9",
                cursor: "pointer",
              }}
            >
              <img
                src={require("../../assets/images/dashboard/cross.png").default}
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
           <div>
           <BottomScrollListener
                  onBottom={(e) =>
                    handleBottomScrollListner(
                      e
                    )
                  }
                  offset={100}
                >
                  {(scrollRef) => (
                    <div
                      className="scroll-div single-child-scrolldiv"
                      ref={scrollRef}
                     
                    >
                {(!breadcrumbArr || breadcrumbArr.length === 0) &&
                !searchVal && !hideSelectOption ? (
                  shouldNotBeSelected ? 
                  <label className="Single-select-container">
                    {defaultOption || 'Select'}
                    <input
                      type="radio"
                      checked={selectedId === 0}
                      onChange={(e) => handleInputClick(e, 0, defaultOption)}
                    />
                    <span className="Single-select-checkmark"></span>
                  </label>
                  : <label className="Single-select-container">
                    Select
                    <input
                      type="radio"
                      checked={!selectedId ? true : false}
                      onChange={(e) => handleInputClick(e, 0, "")}
                    />
                    <span className="Single-select-checkmark"></span>
                  </label>
                ) : (
                  <></>
                )}
             

            {
              ( (!filterArr || filterArr.length === 0 ) && searchVal ) ?
                        <div className="select-no-data-found">
                          <img
                            src={
                              require("../../assets/images/dashboard/no-media.png").default
                            }
                          />
                          <p>No results found</p>
                        </div>     
              : 
              <>
              
                {filterArr && filterArr.map((res) => {
                      return (
                        <div className="single-select-maindiv">
                          <label className="Single-select-container">
                            {res[from === "page" ? "title" : "name"]}
                            <input
                              type="radio"
                              checked={res._id === selectedId}
                              onChange={(e) =>
                                handleInputClick(
                                  e,
                                  res._id,
                                  res[from === "page" ? "title" : "name"]
                                )
                              }
                            />
                            <span className="Single-select-checkmark"></span>
                          </label>
                          {res.child && res.child.length > 0 && res.child.some(elem => elem.show_in_results || from === "category") && (
                            <div
                              className="subdata-svg-container"
                              onClick={(e) => handleSubData(e, res.child, res)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="15.474"
                                height="12.374"
                                viewBox="0 0 15.474 12.374"
                              >
                                <g
                                  id="Group_42665"
                                  data-name="Group 42665"
                                  transform="translate(-1180.001 -431.227)"
                                >
                                  <path
                                    id="Path_119037"
                                    data-name="Path 119037"
                                    d="M15743-2762h14"
                                    transform="translate(-14563 3199.5)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-width="1.5"
                                  />
                                  <path
                                    id="Path_119038"
                                    data-name="Path 119038"
                                    d="M0,0H8V8"
                                    transform="translate(1188.758 431.757) rotate(45)"
                                    fill="none"
                                    stroke="#bcc1ce"
                                    stroke-width="1.5"
                                  />
                                </g>
                              </svg>
                            </div>
                          )}
                        </div>
                      );
                    })}
                {paginationSpinner === true ? (
                        <div
                          className={`form-group allFilter`}
                          style={{ background: "none", margin: "auto" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            style={{
                              margin: "auto",
                              background: "rgb(241, 242, 243,0)",
                              display: "block",
                              shaperendering: "auto",
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                            width="200px"
                            height="100px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                          >
                            <g
                              transform="translate(20 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#e6261f"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.375s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(40 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#f7d038"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.25s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(60 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#49da9a"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.125s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(80 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#4355db"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="0s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                          </svg>
                        </div>
                      )
                    :null}
                    </> }
                </div>
              )}

                 
                </BottomScrollListener>
              </div>
           
    

          
        </ModalBody>
        <ModalFooter>
          <button
            className="Single-select-cancel-btn "
            onClick={(e) => handlePopUpState(e, "cancel")}
          >
            Cancel
          </button>
          <div
            className="d-flex"
            style={{ margin: "0px", alignItems: "center" }}
          >
            <p className="single-select-selected">
              {selectedId ? "1 item selected" : ""}
            </p>
            <button
              // checking for null specifically
              className={`${selectedId !== null ? 'Single-select-done-btn' : 'Single-select-done-disable'}`}
              onClick={(e) => handlePopUpState(e, "open")}
              disabled={selectedId === null}
            >
              Done
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export const SingleSelectionPopUpForAdvertisements = ({ value }) => {

  const handlePopUpState = (from,indx) => {
    value.setState({
      searchValForPopUp: "",
    });
    value.handleOpenSelectionPopUp(from,indx);
  }

  const handleSubData = (e, res) => {
    let newLocationArr = JSON.parse(JSON.stringify(value.state.locationArr));
    let newBreadcrumbArr =
      newLocationArr[value.state.selectedLocationIndex].temp_breadcrumbArr;
    let title1 = "";
    let child = [];
    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      title1 = res.title === undefined ? res.name : res.title;
      child = res.child ? res.child : [];
    } else {
      title1 = convertHTMLEntity(
        res.title === undefined ? res.name : res.title.rendered
      );
      child = res.children ? res.children : [];
    }
    newBreadcrumbArr.push({
      name: title1,
      id: res._id ? res._id : res.term_id.toString(),
    });
    newLocationArr[value.state.selectedLocationIndex].temp_subData = child;
    newLocationArr[value.state.selectedLocationIndex].temp_breadcrumbArr =
      newBreadcrumbArr;

    value.setState({
      locationArr: newLocationArr,
    });
  };
  const handleHomeBtnClick = (e) => {
    let newLocationArr = [...value.state.locationArr];
    newLocationArr[value.state.selectedLocationIndex].temp_breadcrumbArr = [];
    newLocationArr[value.state.selectedLocationIndex].temp_showL1Options = true;
    value.setState({
      locationArr: newLocationArr,
    });
  };

  const handleFirstLabelClick = () => {
    let newLocationArr = [...value.state.locationArr];
    newLocationArr[value.state.selectedLocationIndex].temp_breadcrumbArr = [];

    newLocationArr[value.state.selectedLocationIndex].temp_subData =
      newLocationArr[value.state.selectedLocationIndex].subdropdownData;

    value.setState({
      locationArr: newLocationArr,
    });
  };

  const handleBreadcrumb = (e, val, indx) => {
    let newData = [];
    let newBreadcrumb = [];
    let newLocationArr = [...value.state.locationArr];
    let breadcrumb =
      newLocationArr[value.state.selectedLocationIndex].temp_breadcrumbArr;

    for (let i = 0; i < breadcrumb.length; i++) {
      if (val.name == breadcrumb[i].name) {
        newBreadcrumb.push(breadcrumb[i]);
        break;
      } else {
        newBreadcrumb.push(breadcrumb[i]);
      }
    }

    newLocationArr[value.state.selectedLocationIndex].temp_breadcrumbArr =
      newBreadcrumb;

    function recurs(arr) {
      arr.map((eachCat) => {
        if (eachCat.parent == val.id) {
          newData.push(eachCat);
        }

        if (parseInt(localStorage.getItem("website_technology")) === 3) {
          if (eachCat.child !== undefined && eachCat.child.length > 0) {
            // if parent has childrens
            recurs(eachCat.child);
          }
        } else {
          if (eachCat.children !== undefined && eachCat.children.length > 0) {
            // if parent has childrens
            recurs(eachCat.children);
          }
        }
      });
    }
    recurs(
      value.state.locationArr[value.state.selectedLocationIndex].subdropdownData
    );

    // value.setState({
    //   temp_subData:newData,
    // })
    newLocationArr[value.state.selectedLocationIndex].temp_subData = newData;
    value.setState({
      locationArr: newLocationArr,
    });
  };

  const handleMobBackBtn = () => {
    // let newLocationArr = JSON.parse(JSON.stringify(value.state.locationArr));
    let newLocationArr = [...value.state.locationArr];
    let breadcrumb = JSON.parse(JSON.stringify(newLocationArr[value.state.selectedLocationIndex].temp_breadcrumbArr));
    if (breadcrumb && breadcrumb.length > 0) {
      let newData = [];
      breadcrumb.splice(-1, 1);
      newLocationArr[value.state.selectedLocationIndex].temp_breadcrumbArr =
        breadcrumb;


      function recurs(arr) {
        arr.map((eachCat) => {
          if (eachCat.parent == breadcrumb[breadcrumb.length - 1].id) {
            newData.push(eachCat);
          }
          if (parseInt(localStorage.getItem("website_technology")) === 3) {
            if (eachCat.child !== undefined && eachCat.child.length > 0) {
              // if parent has childrens
              recurs(eachCat.child);
            }
          } else {
            if (eachCat.children !== undefined && eachCat.children.length > 0) {
              // if parent has childrens
              recurs(eachCat.children);
            }
          }
        });
      }

      if (breadcrumb && breadcrumb.length > 0) {
        recurs(
          value.state.locationArr[value.state.selectedLocationIndex]
            .subdropdownData
        );
        newLocationArr[value.state.selectedLocationIndex].temp_subData =
          newData;
      } else {
        newLocationArr[value.state.selectedLocationIndex].temp_subData =
          newLocationArr[value.state.selectedLocationIndex].subdropdownData;
      }
      value.setState({
        locationArr: newLocationArr,
      });
    } else {
      if(newLocationArr[value.state.selectedLocationIndex].temp_showL1Options===false){
        newLocationArr[value.state.selectedLocationIndex].temp_showL1Options=true;
      }else{
        handlePopUpState("cancel", value.state.selectedLocationIndex);
      }
      value.setState({
        locationArr: newLocationArr,
      });
    }
  };

  const handleSearchVal = (e) => {
    value.setState({
      searchValForPopUp: e.target.value,
    });
  };


  let techOPtions =
    parseInt(localStorage.getItem("website_technology")) === 2
      ? value.state.optionsForwebToApp
      : parseInt(localStorage.getItem("website_technology")) === 3
      ? value.state.optionsForCustomApp
      : parseInt(localStorage.getItem("website_technology")) === 4
      ? value.state.optionsForWordpressApp
      : parseInt(localStorage.getItem("website_technology")) === 5
      ? value.state.optionsForWooCommerceApp
      : [];

  const handleReturnTitle = (res) => {
    let title1 = "";
    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      title1 = res.title === undefined ? res.name : res.title;
    } else {
      title1 = convertHTMLEntity(
        res.title === undefined ? res.name : res.title.rendered
      );
    }
    return title1;
  };

  const filterArrForDefaultOption =
  techOPtions &&
  techOPtions.filter((row) =>
    row.label
      .toLowerCase()
      .includes(value.state.searchValForPopUp.toLowerCase())
  );
const filterArrForSubData =
  value.state.locationArr &&
  value.state.locationArr.length > 0 &&
  value.state.selectedLocationIndex !== "" &&
  value.state.selectedLocationIndex !== undefined &&
  value.state.selectedLocationIndex !== null && 
  value.state.locationArr[value.state.selectedLocationIndex].temp_subData &&
  value.state.locationArr[value.state.selectedLocationIndex].temp_subData.filter((row) =>
  {
    if(    
      ( parseInt( localStorage.getItem("website_technology")) === 3 && row.status !== "draft" && row.show_in_results !==0 )
     || parseInt( localStorage.getItem("website_technology")) !== 3 
      ){
          if(handleReturnTitle(row)
          .toLowerCase()
          .includes(value.state.searchValForPopUp.toLowerCase()))
          {
             return true
          } else{
             return false;
          }
      }else{
        return false;
      }
  }
  );

  return value.state.locationArr && value.state.locationArr.length > 0 ? (
    <div>
      <Modal
        className="Single-select-popup"
        isOpen={value.state.openSingleSelectionPopUp}
        centered
        toggle={() =>
          handlePopUpState(
            "cancel",
            value.state.selectedLocationIndex
          )
        }
      >
        <ModalHeader>
          <div className="single-select-header">
            <h2>Link</h2>

            <div className="mob-single-select-top">
              <div className="single-select-bredcrums">
                {!value.state.locationArr[value.state.selectedLocationIndex]
                  .temp_showL1Options && (
                  <p onClick={(e) => handleHomeBtnClick(e)}>Home &gt;</p>
                )}
                {!value.state.locationArr[value.state.selectedLocationIndex]
                  .temp_showL1Options && (
                  <p
                    className={
                      value.state.locationArr[value.state.selectedLocationIndex]
                        .temp_breadcrumbArr.length === 0 ||
                      !value.state.locationArr[
                        value.state.selectedLocationIndex
                      ].temp_breadcrumbArr
                        ? "single-select-bredcrumchild"
                        : ""
                    }
                    onClick={(e) =>
                      value.state.locationArr[value.state.selectedLocationIndex]
                        .temp_breadcrumbArr.length > 0
                        ? handleFirstLabelClick(e)
                        : null
                    }
                  >
                    {
                      value.state.locationArr[value.state.selectedLocationIndex]
                        .temp_module_label
                    }{" "}
                  </p>
                )}
                {value.state.locationArr[value.state.selectedLocationIndex]
                  .temp_breadcrumbArr &&
                  value.state.locationArr[
                    value.state.selectedLocationIndex
                  ].temp_breadcrumbArr.map((val, indx) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        &gt;
                        <p
                          className={
                            indx ===
                            value.state.locationArr[
                              value.state.selectedLocationIndex
                            ].temp_breadcrumbArr.length -
                              1
                              ? "single-select-bredcrumchild"
                              : ""
                          }
                          onClick={(e) =>
                            indx ===
                            value.state.locationArr[
                              value.state.selectedLocationIndex
                            ].temp_breadcrumbArr.length -
                              1
                              ? null
                              : handleBreadcrumb(e, val, indx)
                          }
                        >
                          {val.name}
                        </p>
                      </div>
                    );
                  })}
              </div>

              <div className="mob-single-select-search">
                <svg
                  onClick={(e) => handleMobBackBtn(e)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
                <div className="singleselect-serachdiv">
                  <input
                    placeholder="Search"
                    value={value.state.searchValForPopUp}
                    onChange={(e) => handleSearchVal(e)}
                  />
                  {( value.state.locationArr[value.state.selectedLocationIndex]
                .temp_module_name && window.matchMedia("(max-width: 1200px)").matches )?
                <p className={value.state.locationArr[value.state.selectedLocationIndex]
                  .temp_module_name?"mob-popUp-done-enable" : "mob-popUp-done-disable"}
                onClick={() =>
                  value.state.locationArr[value.state.selectedLocationIndex]
                  .temp_module_name?
                  handlePopUpState(
                    "open",
                    value.state.selectedLocationIndex
                  )
                  
                  :null
                }>Done</p>
                :
                value.state.searchValForPopUp && 
                  <p
                    className="Single-selct-search-clear"
                    onClick={() => {
                      value.setState({
                        searchValForPopUp: "",
                      });
                    }}
                  >
                    Clear
                  </p>
                
                }
                 

                </div>
                <div
                  className="single-select-serach-crossdiv"
                  onClick={() =>
                    handlePopUpState(
                      "cancel",
                      value.state.selectedLocationIndex
                    )
                  }
                >
                  <img
                    src={
                      require("../../assets/images/dashboard/cross.png").default
                    }
                  />
                </div>
              </div>
            </div>

            <div
              className="publish-crossmodal single-select-web-cross"
              onClick={() =>
                handlePopUpState(
                  "cancel",
                  value.state.selectedLocationIndex
                )
              }
              style={{
                zIndex: "9",
                cursor: "pointer",
              }}
            >
              <img
                src={require("../../assets/images/dashboard/cross.png").default}
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <div>
              {value.state.locationArr[value.state.selectedLocationIndex]
                .temp_showL1Options ? (
                  (!filterArrForDefaultOption ||
                    filterArrForDefaultOption.length === 0)  ? (
                    <div className="select-no-data-found 22">
                      <img
                        src={
                          require("../../assets/images/dashboard/no-media.png")
                            .default
                        }
                      />
                      <p>No results found</p>
                    </div>
                  ) : 
                <div>
                  {filterArrForDefaultOption &&
                    filterArrForDefaultOption
                      .map((res, indx) => {
                        return (
                          <div className="single-select-maindiv">
                            <label className="Single-select-container">
                              {res.label}
                              <input
                                type="radio"
                                checked={
                                  res.value ===
                                    value.state.locationArr[
                                      value.state.selectedLocationIndex
                                    ].temp_module_name &&
                                  (!value.state.locationArr[
                                    value.state.selectedLocationIndex
                                  ].temp_item ||
                                    value.state.locationArr[
                                      value.state.selectedLocationIndex
                                    ].temp_item === "all")
                                    ? true
                                    : false
                                }
                                // onChange={(e)=>value.handleParentChange(e,res._id,res.title)}
                                onChange={() =>
                                  value.handleL1Change(
                                    res,
                                    value.state.selectedLocationIndex
                                  )
                                }
                              />
                              <span className="Single-select-checkmark"></span>
                            </label>
                            {((parseInt(
                              localStorage.getItem("website_technology")
                            ) === 3 &&
                              (res.value === "page_details" ||
                                res.value === "post_details")) ||
                              (parseInt(
                                localStorage.getItem("website_technology")
                              ) === 4 &&
                                (res.value === "page_details" ||
                                  res.value === "post_details" ||
                                  res.value === "product_details")) ||
                              (parseInt(
                                localStorage.getItem("website_technology")
                              ) === 5 &&
                                (res.value === "page_details" ||
                                  res.value === "post_details" ||
                                  res.value === "product_details" ||
                                  res.value === "product_cat"))) && (
                              <div
                                className="subdata-svg-container"
                                onClick={() =>
                                  value.handleParentClick(
                                    res,
                                    value.state.selectedLocationIndex
                                  )
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.474"
                                  height="12.374"
                                  viewBox="0 0 15.474 12.374"
                                >
                                  <g
                                    id="Group_42665"
                                    data-name="Group 42665"
                                    transform="translate(-1180.001 -431.227)"
                                  >
                                    <path
                                      id="Path_119037"
                                      data-name="Path 119037"
                                      d="M15743-2762h14"
                                      transform="translate(-14563 3199.5)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                    <path
                                      id="Path_119038"
                                      data-name="Path 119038"
                                      d="M0,0H8V8"
                                      transform="translate(1188.758 431.757) rotate(45)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                  </g>
                                </svg>
                              </div>
                            )}
                          </div>
                        );
                      })}
                </div>
              ) : value.state.locationArr[value.state.selectedLocationIndex]
                  .subdropdownSpinner ? (
                <div className="single-select-popup-spin">
                  <div className="newspinner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                      width="200px"
                      height="200px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g
                        transform="translate(20 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#e6261f"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.375s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(40 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#f7d038"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(60 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#49da9a"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(80 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#4355db"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                </div>
              ) : (
                
                  !filterArrForSubData || filterArrForSubData.length === 0 ? (
                    <div className="select-no-data-found 22">
                      <img
                        src={
                          require("../../assets/images/dashboard/no-media.png")
                            .default
                        }
                      />
                      <p>No results found</p>
                    </div>
                  ) : 
                <BottomScrollListener
                onBottom={(e) =>
                  value.handleBottomScrollListner(
                    e,
                    value.state.selectedLocationIndex,
                    value.state.locationArr[value.state.selectedLocationIndex].temp_module_name,
                    value.state.locationArr[value.state.selectedLocationIndex].isAllowPagination,
                    value.state.locationArr[value.state.selectedLocationIndex].page,
                  )
                }
                offset={100}
              >
                {(scrollRef) => (
                  <div
                    className="scroll-div single-child-scrolldiv"
                    ref={scrollRef}
                    
                  >
             
                  {filterArrForSubData && filterArrForSubData
                  .map((res) => {
                        return (
                          (res.title?.rendered || res.name || (parseInt(localStorage.getItem("website_technology")) === 3 && res.title)) &&
                          <div className="single-select-maindiv">
                            <label className="Single-select-container">
                              {parseInt(
                                localStorage.getItem("website_technology")
                              ) === 3
                                ? res.title === undefined
                                  ? res.name
                                  : res.title
                                : convertHTMLEntity(
                                    res.title === undefined
                                      ? res.name
                                      : res.title.rendered
                                  )}

                              <input
                                type="radio"
                                checked={
                                  parseInt(
                                    localStorage.getItem("website_technology")
                                  ) === 3
                                    ? res._id ==
                                      value.state.locationArr[
                                        value.state.selectedLocationIndex
                                      ].temp_item
                                    : res.id
                                    ? res.id ==
                                      value.state.locationArr[
                                        value.state.selectedLocationIndex
                                      ].temp_item
                                    : res.term_id ==
                                      value.state.locationArr[
                                        value.state.selectedLocationIndex
                                      ].temp_item
                                }
                                onChange={() =>
                                  value.handleSubDropdownSelection(
                                    res,
                                    value.state.selectedLocationIndex
                                  )
                                }
                              />
                              <span className="Single-select-checkmark"></span>
                            </label>
                            {((parseInt(
                              localStorage.getItem("website_technology")
                            ) === 3 &&
                              res.child &&
                              res.child.length > 0) ||
                              (parseInt(
                                localStorage.getItem("website_technology")
                              ) !== 3 &&
                                res.children &&
                                res.children.length > 0)) && (
                              <div
                                className="subdata-svg-container"
                                onClick={(e) => handleSubData(e, res)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.474"
                                  height="12.374"
                                  viewBox="0 0 15.474 12.374"
                                >
                                  <g
                                    id="Group_42665"
                                    data-name="Group 42665"
                                    transform="translate(-1180.001 -431.227)"
                                  >
                                    <path
                                      id="Path_119037"
                                      data-name="Path 119037"
                                      d="M15743-2762h14"
                                      transform="translate(-14563 3199.5)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                    <path
                                      id="Path_119038"
                                      data-name="Path 119038"
                                      d="M0,0H8V8"
                                      transform="translate(1188.758 431.757) rotate(45)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                  </g>
                                </svg>
                              </div>
                            )}
                          </div>
                        );
                  })}
                 {value.state.locationArr[value.state.selectedLocationIndex]
                  .subdropdownSpinner !== true &&
                      value.state.paginationSpinner === true ? (
                        <div
                          className={`form-group allFilter`}
                          style={{ background: "none", margin: "auto" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            style={{
                              margin: "auto",
                              background: "rgb(241, 242, 243,0)",
                              display: "block",
                              shaperendering: "auto",
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                            width="200px"
                            height="100px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                          >
                            <g
                              transform="translate(20 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#e6261f"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.375s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(40 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#f7d038"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.25s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(60 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#49da9a"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.125s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(80 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#4355db"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="0s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                          </svg>
                        </div>
                      ) : null}
                </div>)}
                </BottomScrollListener> 
              )}
            </div>
          </div>

        </ModalBody>
        <ModalFooter>
          <button
            className="Single-select-cancel-btn "
            onClick={() =>
              handlePopUpState(
                "cancel",
                value.state.selectedLocationIndex
              )
            }
          >
            Cancel
          </button>
          <div
            className="d-flex"
            style={{ margin: "0px", alignItems: "center" }}
          >
            <p className="single-select-selected">
              {value.state.locationArr[value.state.selectedLocationIndex]
                .temp_module_name
                ? "1 item selected"
                : ""}
            </p>
            <button
              className="Single-select-done-btn"
              onClick={() =>
                handlePopUpState(
                  "open",
                  value.state.selectedLocationIndex
                )
              }
            >
              Done
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  ) : (
    <></>
  );
};

export const SingleSelectionPopUpForBottomBar = ({ value }) => {

  const handlePopUpState = (from,indx) => {
    value.setState({
      searchValForPopUp: "",
    });
    value.handleOpenSelectionPopUp(from,indx);
  }

  const handleSubData = (e, res) => {
    let newLocationArr = JSON.parse(
      JSON.stringify(value.state.bottom_menu_items)
    );
    let newBreadcrumbArr =
      newLocationArr[value.state.OpenItemIndex].temp_breadcrumbArr;
    let title1 = "";
    let child = [];
    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      title1 = res.title === undefined ? res.name : res.title;
      child = res.child ? res.child : [];
    } else {
      title1 = convertHTMLEntity(
        res.title === undefined ? res.name : res.title.rendered
      );
      child = res.children ? res.children : [];
    }
    newBreadcrumbArr.push({
      name: title1,
      id: res._id ? res._id : res.term_id.toString(),
    });
    newLocationArr[value.state.OpenItemIndex].temp_subData = child;
    newLocationArr[value.state.OpenItemIndex].temp_breadcrumbArr =
      newBreadcrumbArr;

    value.setState({
      bottom_menu_items: newLocationArr,
    });
  };
  const handleHomeBtnClick = (e) => {
    let newLocationArr = [...value.state.bottom_menu_items];
    newLocationArr[value.state.OpenItemIndex].temp_breadcrumbArr = [];
    newLocationArr[value.state.OpenItemIndex].temp_showL1Options = true;
    value.setState({
      bottom_menu_items: newLocationArr,
    });
  };

  const handleFirstLabelClick = () => {
    let newLocationArr = [...value.state.bottom_menu_items];
    newLocationArr[value.state.OpenItemIndex].temp_breadcrumbArr = [];

    newLocationArr[value.state.OpenItemIndex].temp_subData =
      newLocationArr[value.state.OpenItemIndex].subdropdownData;

    value.setState({
      bottom_menu_items: newLocationArr,
    });
  };

  const handleBreadcrumb = (e, val, indx) => {
    let newData = [];
    let newBreadcrumb = [];
    let newLocationArr = [...value.state.bottom_menu_items];
    let breadcrumb =
      newLocationArr[value.state.OpenItemIndex].temp_breadcrumbArr;

    for (let i = 0; i < breadcrumb.length; i++) {
      if (val.name == breadcrumb[i].name) {
        newBreadcrumb.push(breadcrumb[i]);
        break;
      } else {
        newBreadcrumb.push(breadcrumb[i]);
      }
    }

    newLocationArr[value.state.OpenItemIndex].temp_breadcrumbArr =
      newBreadcrumb;

    function recurs(arr) {
      arr.map((eachCat) => {
        if (eachCat.parent == val.id) {
          newData.push(eachCat);
        }

        if (parseInt(localStorage.getItem("website_technology")) === 3) {
          if (eachCat.child !== undefined && eachCat.child.length > 0) {
            // if parent has childrens
            recurs(eachCat.child);
          }
        } else {
          if (eachCat.children !== undefined && eachCat.children.length > 0) {
            // if parent has childrens
            recurs(eachCat.children);
          }
        }
      });
    }
    recurs(
      value.state.bottom_menu_items[value.state.OpenItemIndex].subdropdownData
    );

    // value.setState({
    //   temp_subData:newData,
    // })
    newLocationArr[value.state.OpenItemIndex].temp_subData = newData;
    value.setState({
      bottom_menu_items: newLocationArr,
    });
  };

  const handleMobBackBtn = () => {
    let newLocationArr = [...value.state.bottom_menu_items];

    let breadcrumb = JSON.parse(JSON.stringify(newLocationArr[value.state.OpenItemIndex].temp_breadcrumbArr));

    if (breadcrumb && breadcrumb.length > 0) {
      let newData = [];
      breadcrumb.splice(-1, 1);
      newLocationArr[value.state.OpenItemIndex].temp_breadcrumbArr = breadcrumb;
      
      function recurs(arr) {
        arr.map((eachCat) => {
          if (eachCat.parent == breadcrumb[breadcrumb.length - 1].id) {
            newData.push(eachCat);
          }
          if (parseInt(localStorage.getItem("website_technology")) === 3) {
            if (eachCat.child !== undefined && eachCat.child.length > 0) {
              // if parent has childrens
              recurs(eachCat.child);
            }
          } else {
            if (eachCat.children !== undefined && eachCat.children.length > 0) {
              // if parent has childrens
              recurs(eachCat.children);
            }
          }
        });
      }

      if (breadcrumb && breadcrumb.length > 0) {
        recurs(value.state.bottom_menu_items[value.state.OpenItemIndex].subdropdownData);
        newLocationArr[value.state.OpenItemIndex].temp_subData = newData;
      } else {
        newLocationArr[value.state.OpenItemIndex].temp_subData = newLocationArr[value.state.OpenItemIndex].subdropdownData;
      }
      value.setState({
        bottom_menu_items: newLocationArr,
      });
    } else {
      if(newLocationArr[value.state.OpenItemIndex].temp_showL1Options===false){
        newLocationArr[value.state.OpenItemIndex].temp_showL1Options=true;
      }else{
        handlePopUpState("cancel", value.state.OpenItemIndex);
      }
      value.setState({
        bottom_menu_items: newLocationArr,
      });
    }
  };

  const handleSearchVal = (e) => {
    value.setState({
      searchValForPopUp: e.target.value,
    });
  };


  let techOPtions =
    parseInt(localStorage.getItem("website_technology")) === 2
      ? value.state.itemTypeOptionsForWeb2App
      : parseInt(localStorage.getItem("website_technology")) === 3
      ? value.state.itemTypeOptionsForCustomApp
      : parseInt(localStorage.getItem("website_technology")) === 4
      ? value.state.itemTypeOptionsForWordpress
      : parseInt(localStorage.getItem("website_technology")) === 5
      ? value.state.itemTypeOptionsForWoocommerce
      : [];

  const handleReturnTitle = (res) => {
    let title1 = "";
    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      title1 = res.title === undefined ? res.name : res.title;
    } else {
      title1 = convertHTMLEntity(
        res.title === undefined ? res.name : res.title.rendered
      );
    }
    return title1;
  };

  const filterArrForDefaultOption =
    techOPtions &&
    techOPtions.filter((row) =>
      (row.item_name ? row.item_name : row.name)
        .toLowerCase()
        .includes(value.state.searchValForPopUp.toLowerCase())
    );
  const filterArrForMergeArr =
    value.state.mergeArray &&
    value.state.mergeArray.filter((row) =>
      (
        (row.name
        .toLowerCase()
        .includes(value.state.searchValForPopUp.toLowerCase()))
        && (!(row.name.toLowerCase().includes("search")))
        && (!(row.name.toLowerCase().includes("navigation")))
        && (!(row.name.toLowerCase().includes("pattern")))
        && (!(row.name.toLowerCase().includes("template")))
        && (!(row.name.toLowerCase().includes("font")))
      
      )
    );

    // (
    //   ( parseInt( localStorage.getItem("website_technology")) === 3 && res.status !== "draft" && res.show_in_results !==0 )
    //  || parseInt( localStorage.getItem("website_technology")) !== 3 
    // )  

  const filterArrForSubData =
    value.state.bottom_menu_items &&
    value.state.bottom_menu_items.length > 0 &&
    value.state.OpenItemIndex !== "" &&
    value.state.OpenItemIndex !== undefined &&
    value.state.OpenItemIndex !== null && 
    value.state.bottom_menu_items[ value.state.OpenItemIndex].temp_subData &&
    value.state.bottom_menu_items[value.state.OpenItemIndex].temp_subData.filter((row) =>
    {
      if(    
        ( parseInt( localStorage.getItem("website_technology")) === 3 && row.status !== "draft" && row.show_in_results !==0 )
       || parseInt( localStorage.getItem("website_technology")) !== 3 
        ){
            if(handleReturnTitle(row)
            .toLowerCase()
            .includes(value.state.searchValForPopUp.toLowerCase()))
            {
               return true
            } else{
               return false;
            }
        }else{
          return false;
        }
    }

    );

  return value.state.bottom_menu_items &&
    value.state.bottom_menu_items.length > 0 &&
    value.state.OpenItemIndex !== "" &&
    value.state.OpenItemIndex !== undefined &&
    value.state.OpenItemIndex !== null
    ? (
    <div>
      <Modal
        className="Single-select-popup"
        isOpen={value.state.openSingleSelectionPopUp}
        centered
        toggle={() =>
          handlePopUpState("cancel", value.state.OpenItemIndex)
        }
      >
        <ModalHeader>
          <div className="single-select-header">
            <h2>Link</h2>

            <div className="mob-single-select-top">
              <div className="single-select-bredcrums">
                {!value.state.bottom_menu_items[value.state.OpenItemIndex]
                  .temp_showL1Options && (
                  <p onClick={(e) => handleHomeBtnClick(e)}>Home &gt;</p>
                )}
                {!value.state.bottom_menu_items[value.state.OpenItemIndex]
                  .temp_showL1Options && (
                  <p
                    className={
                      value.state.bottom_menu_items[value.state.OpenItemIndex]
                        .temp_breadcrumbArr.length === 0 ||
                      !value.state.bottom_menu_items[value.state.OpenItemIndex]
                        .temp_breadcrumbArr
                        ? "single-select-bredcrumchild"
                        : ""
                    }
                    onClick={(e) =>
                      value.state.bottom_menu_items[value.state.OpenItemIndex]
                        .temp_breadcrumbArr.length > 0
                        ? handleFirstLabelClick(e)
                        : null
                    }
                  >
                    {
                      value.state.bottom_menu_items[value.state.OpenItemIndex]
                        .temp_module_label
                    }{" "}
                  </p>
                )}
                {value.state.bottom_menu_items[value.state.OpenItemIndex]
                  .temp_breadcrumbArr &&
                  value.state.bottom_menu_items[
                    value.state.OpenItemIndex
                  ].temp_breadcrumbArr.map((val, indx) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        &gt;
                        <p
                          className={
                            indx ===
                            value.state.bottom_menu_items[
                              value.state.OpenItemIndex
                            ].temp_breadcrumbArr.length -
                              1
                              ? "single-select-bredcrumchild"
                              : ""
                          }
                          onClick={(e) =>
                            indx ===
                            value.state.bottom_menu_items[
                              value.state.OpenItemIndex
                            ].temp_breadcrumbArr.length -
                              1
                              ? null
                              : handleBreadcrumb(e, val, indx)
                          }
                        >
                          {val.name}
                        </p>
                      </div>
                    );
                  })}
              </div>

              <div className="mob-single-select-search">
                <svg
                  onClick={(e) => handleMobBackBtn(e)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
                <div className="singleselect-serachdiv">
                  <input
                    placeholder="Search"
                    value={value.state.searchValForPopUp}
                    onChange={(e) => handleSearchVal(e)}
                  />

               {( value.state.bottom_menu_items[value.state.OpenItemIndex]
                .temp_item_type && window.matchMedia("(max-width: 1200px)").matches ) ?
                <p className={value.state.bottom_menu_items[value.state.OpenItemIndex]
                  .temp_item_type?"mob-popUp-done-enable" : "mob-popUp-done-disable"}
                onClick={() =>
                  value.state.bottom_menu_items[value.state.OpenItemIndex]
                .temp_item_type?
                  handlePopUpState(
                    "open",
                    value.state.OpenItemIndex
                  )
                  
                  :null
                }>Done</p>
                :
                  value.state.searchValForPopUp && (
                    <p
                      className="Single-selct-search-clear"
                      onClick={() => {
                        value.setState({
                          searchValForPopUp: "",
                        });
                      }}
                    >
                      Clear
                    </p>
                  )}
                </div>
                <div
                  className="single-select-serach-crossdiv"
                  onClick={() =>
                    handlePopUpState(
                      "cancel",
                      value.state.OpenItemIndex
                    )
                  }
                >
                  <img
                    src={
                      require("../../assets/images/dashboard/cross.png").default
                    }
                  />
                </div>
              </div>
            </div>

            <div
              className="publish-crossmodal single-select-web-cross"
              onClick={() =>
                handlePopUpState(
                  "cancel",
                  value.state.OpenItemIndex
                )
              }
              style={{
                zIndex: "9",
                cursor: "pointer",
              }}
            >
              <img
                src={require("../../assets/images/dashboard/cross.png").default}
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <div>
              {value.state.bottom_menu_items[value.state.OpenItemIndex]
                .temp_showL1Options ? (
                  (!filterArrForDefaultOption ||
                    filterArrForDefaultOption.length === 0) &&
                  (!filterArrForMergeArr || filterArrForMergeArr.length === 0) ? (
                    <div className="select-no-data-found 22">
                      <img
                        src={
                          require("../../assets/images/dashboard/no-media.png")
                            .default
                        }
                      />
                      <p>No results found</p>
                    </div>
                  ) : (
                <div>
                  {!value.state.searchValForPopUp && (
                    <h2 className="single-select-body-header">
                      Default options
                    </h2>
                  )}
                  {filterArrForDefaultOption && filterArrForDefaultOption
                      .map((res, indx) => {
                   
                        return ((res.item_type === "product_categories" ||
                          res.item_type === "pages" ||
                          res.item_type === "product_search") &&
                          value.state.typeArray &&
                          value.state.typeArray.length > 0) ||
                          (value.state.is_chat_enabled !== 1 &&
                            res.item_type === "chat") ||
                          (parseInt(
                            localStorage.getItem("website_technology")
                          ) === 4 &&
                            (value.state.keys_data === null ||
                              value.state.keys_data.is_connected !== 1 ||
                              value.state.keys_data.authorization_type !== 2) &&
                            res.item_type === "profile") ? (
                          <></>
                        ) : (
                          <div className="single-select-maindiv">
                            <label className="Single-select-container">
                              {res.item_name}
                              <input
                                type="radio"
                                checked={
                                  res.item_type ===
                                    value.state.bottom_menu_items[
                                      value.state.OpenItemIndex
                                    ].temp_item_type &&
                                  (!value.state.bottom_menu_items[
                                    value.state.OpenItemIndex
                                  ].temp_invidual_selected_item_id ||
                                    value.state.bottom_menu_items[
                                      value.state.OpenItemIndex
                                    ].temp_invidual_selected_item_id === "all")
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  value.handleL1Change(
                                    res,
                                    value.state.OpenItemIndex
                                  )
                                }
                              />
                              <span className="Single-select-checkmark"></span>
                            </label>
                          </div>
                        );
                      })}

                  {value.state.mergeArray &&
                    value.state.mergeArray.length > 0 &&
                    !value.state.searchValForPopUp && (
                      <h2 className="single-select-second-header">
                        Post & taxonomies
                      </h2>
                    )}

                  {filterArrForMergeArr &&
                    filterArrForMergeArr
                      .map((res, k) => {
                     
                        return (
                          <div className="single-select-maindiv">
                            <label className="Single-select-container">
                              {res.name}
                              <input
                                type="radio"
                                checked={
                                  res.type + res.slug + res.rest_base + res.post_type ===
                                    value.state.bottom_menu_items[
                                      value.state.OpenItemIndex
                                    ].temp_item_type +
                                      value.state.bottom_menu_items[
                                        value.state.OpenItemIndex
                                      ].temp_slug +
                                      value.state.bottom_menu_items[
                                        value.state.OpenItemIndex
                                      ].temp_rest_base + value.state.bottom_menu_items[
                                        value.state.OpenItemIndex
                                      ].temp_post_type &&
                                  (!value.state.bottom_menu_items[
                                    value.state.OpenItemIndex
                                  ].temp_invidual_selected_item_id ||
                                    value.state.bottom_menu_items[
                                      value.state.OpenItemIndex
                                    ].temp_invidual_selected_item_id === "all")
                                    ? true
                                    : false
                                }
                                //  onChange={()=>value.handleL1Change(res,value.state.OpenItemIndex)}
                                onChange={(e) =>
                                  value.handleMergeArrLeftDropdown(
                                    e,
                                    value.state.OpenItemIndex,
                                    res
                                  )
                                }
                              />
                              <span className="Single-select-checkmark"></span>
                            </label>
                            {((res.type !== "search" &&
                              res.rest_base !== "" &&
                              res.rest_base !== undefined) ||
                              res.isCms === 1) && (
                              <div
                                className="subdata-svg-container"
                                onClick={(e) =>
                                  value.handleLeftDropdown(
                                    e,
                                    "notaddITEM",
                                    k,
                                    value.state.OpenItemIndex,
                                    filterArrForMergeArr
                                  )
                                }
                                //  onClick={()=>value.handleParentClick(res,value.state.OpenItemIndex)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.474"
                                  height="12.374"
                                  viewBox="0 0 15.474 12.374"
                                >
                                  <g
                                    id="Group_42665"
                                    data-name="Group 42665"
                                    transform="translate(-1180.001 -431.227)"
                                  >
                                    <path
                                      id="Path_119037"
                                      data-name="Path 119037"
                                      d="M15743-2762h14"
                                      transform="translate(-14563 3199.5)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                    <path
                                      id="Path_119038"
                                      data-name="Path 119038"
                                      d="M0,0H8V8"
                                      transform="translate(1188.758 431.757) rotate(45)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                  </g>
                                </svg>
                              </div>
                            )}
                          </div>
                        );
                      })}
                </div>)
              ) : value.state.bottom_menu_items[value.state.OpenItemIndex]
                  .subdropdownSpinner ? (
                <div className="single-select-popup-spin">
                  <div className="newspinner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                      width="200px"
                      height="200px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g
                        transform="translate(20 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#e6261f"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.375s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(40 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#f7d038"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(60 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#49da9a"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(80 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#4355db"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                </div>
              ) : (
                !filterArrForSubData || filterArrForSubData.length === 0 ? (
                  <div className="select-no-data-found 22">
                    <img
                      src={
                        require("../../assets/images/dashboard/no-media.png")
                          .default
                      }
                    />
                    <p>No results found</p>
                  </div>
                ) : (
                  <BottomScrollListener
                  onBottom={(e) =>
                    value.handleBottomScrollListner(
                      e,
                      value.state.OpenItemIndex,
                      (parseInt(localStorage.getItem("website_technology")) === 3?
                      value.state.bottom_menu_items[value.state.OpenItemIndex].temp_item_type :
                       value.state.bottom_menu_items[value.state.OpenItemIndex].temp_rest_base),
                    )
                  }
                  offset={100}
                >
                  {(scrollRef) => (
                    <div
                      className="scroll-div single-child-scrolldiv"
                      ref={scrollRef}
                     
                    >
               
                  {filterArrForSubData && filterArrForSubData  
                    .map((res) => {
                       
                        return (
                          <div className="single-select-maindiv">
                            <label className="Single-select-container">
                              {parseInt(
                                localStorage.getItem("website_technology")
                              ) === 3
                                ? res.title === undefined
                                  ? res.name
                                  : res.title
                                : convertHTMLEntity(
                                    res.title === undefined
                                      ? res.name
                                      : res.title.rendered
                                  )}

                              <input
                                type="radio"
                                checked={
                                  parseInt(
                                    localStorage.getItem("website_technology")
                                  ) === 3
                                    ? res._id ==
                                      value.state.bottom_menu_items[
                                        value.state.OpenItemIndex
                                      ].temp_invidual_selected_item_id
                                    : res.id
                                    ? res.id ==
                                      value.state.bottom_menu_items[
                                        value.state.OpenItemIndex
                                      ].temp_invidual_selected_item_id
                                    : res.term_id ==
                                      value.state.bottom_menu_items[
                                        value.state.OpenItemIndex
                                      ].temp_invidual_selected_item_id
                                }
                                // onChange={()=>value.handleSubDropdownSelection(res,value.state.OpenItemIndex)}
                                onChange={
                                  parseInt(
                                    localStorage.getItem("website_technology")
                                  ) === 3
                                    ? (e) =>
                                        value.handleSubdropdown(
                                          e,
                                          res._id,
                                          value.state.OpenItemIndex
                                        )
                                    : (e) =>
                                        value.handleSubdropdown(
                                          e,
                                          parseInt(
                                            res.id ? res.id : res.term_id
                                          ),
                                          value.state.OpenItemIndex
                                        )
                                }
                              />
                              <span className="Single-select-checkmark"></span>
                            </label>
                            {((parseInt(
                              localStorage.getItem("website_technology")
                            ) === 3 &&
                              res.child &&
                              res.child.length > 0) ||
                              (parseInt(
                                localStorage.getItem("website_technology")
                              ) !== 3 &&
                                res.children &&
                                res.children.length > 0)) && (
                              <div
                                className="subdata-svg-container"
                                onClick={(e) => handleSubData(e, res)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.474"
                                  height="12.374"
                                  viewBox="0 0 15.474 12.374"
                                >
                                  <g
                                    id="Group_42665"
                                    data-name="Group 42665"
                                    transform="translate(-1180.001 -431.227)"
                                  >
                                    <path
                                      id="Path_119037"
                                      data-name="Path 119037"
                                      d="M15743-2762h14"
                                      transform="translate(-14563 3199.5)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                    <path
                                      id="Path_119038"
                                      data-name="Path 119038"
                                      d="M0,0H8V8"
                                      transform="translate(1188.758 431.757) rotate(45)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                  </g>
                                </svg>
                              </div>
                            )}
                          </div>
                        );
                      })}
                       {value.state.bottom_menu_items[value.state.OpenItemIndex]
                  .subdropdownSpinner !== true &&
                      value.state.paginationSpinner === true ? (
                        <div
                          className={`form-group allFilter`}
                          style={{ background: "none", margin: "auto" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            style={{
                              margin: "auto",
                              background: "rgb(241, 242, 243,0)",
                              display: "block",
                              shaperendering: "auto",
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                            width="200px"
                            height="100px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                          >
                            <g
                              transform="translate(20 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#e6261f"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.375s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(40 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#f7d038"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.25s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(60 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#49da9a"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.125s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(80 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#4355db"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="0s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                          </svg>
                        </div>
                      ) : null}
                </div>)}
                </BottomScrollListener>
                )
              )}
            </div>
          </div>

        </ModalBody>
        <ModalFooter>
          <button
            className="Single-select-cancel-btn "
            onClick={() =>
              handlePopUpState(
                "cancel",
                value.state.OpenItemIndex
              )
            }
          >
            Cancel
          </button>
          <div
            className="d-flex"
            style={{ margin: "0px", alignItems: "center" }}
          >
            <p className="single-select-selected">
              {value.state.bottom_menu_items[value.state.OpenItemIndex]
                .temp_item_type
                ? "1 item selected"
                : ""}
            </p>
            <button
              className="Single-select-done-btn"
              onClick={() =>
                handlePopUpState(
                  "open",
                  value.state.OpenItemIndex
                )
              }
            >
              Done
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  ) : (
    <></>
  );
};

export const SingleSelectionPopUpForMenu = ({
  value,
  arrayWithoutChildStr,
}) => {

  const handlePopUpState = (from,indx,id,nextFrom, uid) => {
    value.setState({
      searchValForPopUp: "",
    });
    value.handleOpenSelectionPopUp(from,indx,id,nextFrom, uid);
  }

  const handleSubData = (e, res, menuId) => {
    let newArr = JSON.parse(JSON.stringify(value.state.childrendata));
    let newBreadcrumbArr = [];

    let title1 = "";
    let child = [];

    function recursForMenuData(arr) {
      arr.map((eachPage) => {
        if (eachPage.id == menuId) {
          newBreadcrumbArr = eachPage.temp_breadcrumbArr;

          if (parseInt(localStorage.getItem("website_technology")) === 3) {
            title1 = res.title === undefined ? res.name : res.title;
            child = res.child ? res.child : [];
          } else {
            title1 = convertHTMLEntity(
              res.title === undefined ? res.name : res.title.rendered
            );
            child = res.children ? res.children : [];
          }
          newBreadcrumbArr.push({
            name: title1,
            id: res._id ? res._id : res.term_id.toString(),
          });
          eachPage.temp_subData = child;
          eachPage.temp_breadcrumbArr = newBreadcrumbArr;
        }

        if (eachPage.children !== undefined && eachPage.children.length > 0) {
          recursForMenuData(eachPage.children);
        }

        // return arr;
      });
    }

    recursForMenuData(newArr);

    value.setState({
      childrendata: newArr,
    });
  };
  const handleHomeBtnClick = (e, menuId) => {
    let newArr = [...value.state.childrendata];

    function recursForMenuData(arr) {
      arr.map((eachPage) => {
        if (eachPage.id == menuId) {
          eachPage.temp_breadcrumbArr = [];
          eachPage.temp_showL1Options = true;
        }

        if (eachPage.children !== undefined && eachPage.children.length > 0) {
          recursForMenuData(eachPage.children);
        }

        // return arr;
      });
    }

    recursForMenuData(newArr);

    value.setState({
      childrendata: newArr,
    });
  };

  const handleFirstLabelClick = (e, menuId) => {
    let newArr = [...value.state.childrendata];

    function recursForMenuData(arr) {
      arr.map((eachPage) => {
        if (eachPage.id == menuId) {
          eachPage.temp_breadcrumbArr = [];

          eachPage.temp_subData = eachPage.subdropdownData;
        }

        if (eachPage.children !== undefined && eachPage.children.length > 0) {
          recursForMenuData(eachPage.children);
        }

        // return arr;
      });
    }

    recursForMenuData(newArr);

    value.setState({
      childrendata: newArr,
    });
  };

  const handleBreadcrumb = (e, val, indx, menuId) => {
    function recurs(arr) {
      arr.map((eachCat) => {
        if (eachCat.parent == val.id) {
          newData.push(eachCat);
        }

        if (parseInt(localStorage.getItem("website_technology")) === 3) {
          if (eachCat.child !== undefined && eachCat.child.length > 0) {
            // if parent has childrens
            recurs(eachCat.child);
          }
        } else {
          if (eachCat.children !== undefined && eachCat.children.length > 0) {
            // if parent has childrens
            recurs(eachCat.children);
          }
        }
      });
    }

    let newArr = [...value.state.childrendata];
    let newData = [];
    let newBreadcrumb = [];
    let breadcrumb = [];

    function recursForMenuData(arr) {
      arr.map((eachPage) => {
        if (eachPage.id == menuId) {
          breadcrumb = eachPage.temp_breadcrumbArr;

          for (let i = 0; i < breadcrumb.length; i++) {
            if (val.name == breadcrumb[i].name) {
              newBreadcrumb.push(breadcrumb[i]);
              break;
            } else {
              newBreadcrumb.push(breadcrumb[i]);
            }
          }

          eachPage.temp_breadcrumbArr = newBreadcrumb;
          recurs(eachPage.subdropdownData);

          eachPage.temp_subData = newData;
        }

        if (eachPage.children !== undefined && eachPage.children.length > 0) {
          recursForMenuData(eachPage.children);
        }

        // return arr;
      });
    }

    recursForMenuData(newArr);

    value.setState({
      childrendata: newArr,
    });
  };

  const handleMobBackBtn = (e, menuId) => {
    let newArr = [...value.state.childrendata];
    let breadcrumb = [];
    function recursForMenuData(arr) {
      arr.map((eachPage) => {
        if (eachPage.id == menuId) {
          breadcrumb = JSON.parse(JSON.stringify(eachPage.temp_breadcrumbArr));

          if (breadcrumb && breadcrumb.length > 0) {
            let newData = [];
            breadcrumb.splice(-1, 1);
            eachPage.temp_breadcrumbArr = breadcrumb;
            function recurs(arr) {
              arr.map((eachCat) => {
                if (eachCat.parent == breadcrumb[breadcrumb.length - 1].id) {
                  newData.push(eachCat);
                }
                if (
                  parseInt(localStorage.getItem("website_technology")) === 3
                ) {
                  if (eachCat.child !== undefined && eachCat.child.length > 0) {
                    // if parent has childrens
                    recurs(eachCat.child);
                  }
                } else {
                  if (
                    eachCat.children !== undefined &&
                    eachCat.children.length > 0
                  ) {
                    // if parent has childrens
                    recurs(eachCat.children);
                  }
                }
              });
            }

            if (breadcrumb && breadcrumb.length > 0) {
              recurs(eachPage.subdropdownData);
              eachPage.temp_subData = newData;
            } else {
              eachPage.temp_subData = eachPage.subdropdownData;
            }
          } else {
            if(eachPage.temp_showL1Options===false){
              eachPage.temp_showL1Options=true;
            }else{
              handlePopUpState(
                "cancel",
                value.state.OpenItemIndex,
                arrayWithoutChildStr[value.state.OpenItemIndex].id,
                "pre"
              );
            }

          }
        }

        if (eachPage.children !== undefined && eachPage.children.length > 0) {
          recursForMenuData(eachPage.children);
        }

        // return arr;
      });
    }

    recursForMenuData(newArr);

    value.setState({
      childrendata: newArr,
    });
  };

  const handleSearchVal = (e) => {
    value.setState({
      searchValForPopUp: e.target.value,
    });
  };


  const handleReturnTitle = (res) => {
    let title1 = "";
    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      title1 = res.title === undefined ? res.name : res.title;
    } else {
      title1 = convertHTMLEntity(
        res.title === undefined ? res.name : res.title.rendered
      );
    }
    return title1;
  };

  const filterArrForDefaultOption =
    value.state.defaultOptionData &&
    value.state.defaultOptionData.filter((row) =>
      (row.item_name ? row.item_name : row.name)
        .toLowerCase()
        .includes(value.state.searchValForPopUp.toLowerCase())
    );
  const filterArrForMergeArr =
    value.state.mergeArray &&
    value.state.mergeArray.filter((row) =>
      (
        (row.name
        .toLowerCase()
        .includes(value.state.searchValForPopUp.toLowerCase()))
        && (!(row.name.toLowerCase().includes("search")))
        && (!(row.name.toLowerCase().includes("navigation")))
        && (!(row.name.toLowerCase().includes("pattern")))
        && (!(row.name.toLowerCase().includes("template")))
        && (!(row.name.toLowerCase().includes("font")))
      
      )
    );


  const filterArrForSubData =
    arrayWithoutChildStr &&
    arrayWithoutChildStr.length > 0 &&
    value.state.OpenItemIndex !== "" &&
    value.state.OpenItemIndex !== undefined &&
    value.state.OpenItemIndex !== null &&
    arrayWithoutChildStr[value.state.OpenItemIndex].temp_subData &&
    Array.isArray(arrayWithoutChildStr[value.state.OpenItemIndex].temp_subData) && 
    arrayWithoutChildStr[value.state.OpenItemIndex].temp_subData.filter((row) =>
    {
      if(    
        ( parseInt( localStorage.getItem("website_technology")) === 3 && row.status !== "draft" && row.show_in_results !==0 )
       || parseInt( localStorage.getItem("website_technology")) !== 3 
        ){
            if(handleReturnTitle(row)
            .toLowerCase()
            .includes(value.state.searchValForPopUp.toLowerCase()))
            {
               return true
            } else{
               return false;
            }
        }else{
          return false;
        }
    }
    );
  return arrayWithoutChildStr &&
    arrayWithoutChildStr.length > 0 &&
    value.state.OpenItemIndex !== "" &&
    value.state.OpenItemIndex !== undefined &&
    value.state.OpenItemIndex !== null ? (
    <div>
      <Modal
        className="Single-select-popup"
        isOpen={value.state.openSingleSelectionPopUp}
        centered
        toggle={() =>
          handlePopUpState(
            "cancel",
            value.state.OpenItemIndex,
            arrayWithoutChildStr[value.state.OpenItemIndex].id,
            "pre"
          )
        }
      >
        <ModalHeader>
          <div className="single-select-header">
            <h2>Link</h2>

            <div className="mob-single-select-top">
              <div className="single-select-bredcrums">
                {!arrayWithoutChildStr[value.state.OpenItemIndex]
                  .temp_showL1Options && (
                  <p onClick={(e) =>
                    handleHomeBtnClick(
                      e,
                      arrayWithoutChildStr[value.state.OpenItemIndex].id
                    )
                  }>Home &gt;</p>
                )}
                {!arrayWithoutChildStr[value.state.OpenItemIndex]
                  .temp_showL1Options && (
                  <p
                    className={
                      arrayWithoutChildStr[value.state.OpenItemIndex]
                        .temp_breadcrumbArr.length === 0 ||
                      !arrayWithoutChildStr[value.state.OpenItemIndex]
                        .temp_breadcrumbArr
                        ? "single-select-bredcrumchild"
                        : ""
                    }
                    onClick={(e) =>
                      arrayWithoutChildStr[value.state.OpenItemIndex]
                        .temp_breadcrumbArr.length > 0
                        ? handleFirstLabelClick(
                            e,
                            arrayWithoutChildStr[value.state.OpenItemIndex].id
                          )
                        : null
                    }
                  >
                    {
                      arrayWithoutChildStr[value.state.OpenItemIndex]
                        .temp_module_label
                    }{" "}
                  </p>
                )}
                {arrayWithoutChildStr[value.state.OpenItemIndex]
                  .temp_breadcrumbArr &&
                  arrayWithoutChildStr[
                    value.state.OpenItemIndex
                  ].temp_breadcrumbArr.map((val, indx) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        &gt;
                        <p
                          className={
                            indx ===
                            arrayWithoutChildStr[value.state.OpenItemIndex]
                              .temp_breadcrumbArr.length -
                              1
                              ? "single-select-bredcrumchild"
                              : ""
                          }
                          onClick={(e) =>
                            indx ===
                            arrayWithoutChildStr[value.state.OpenItemIndex]
                              .temp_breadcrumbArr.length -
                              1
                              ? null
                              : handleBreadcrumb(
                                  e,
                                  val,
                                  indx,
                                  arrayWithoutChildStr[
                                    value.state.OpenItemIndex
                                  ].id
                                )
                          }
                        >
                          {val.name}
                        </p>
                      </div>
                    );
                  })}
              </div>

              <div className="mob-single-select-search">
                <svg
                  onClick={(e) =>
                    handleMobBackBtn(
                      e,
                      arrayWithoutChildStr[value.state.OpenItemIndex].id
                    )
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
                <div className="singleselect-serachdiv">
                  <input
                    placeholder="Search"
                    value={value.state.searchValForPopUp}
                    onChange={(e) => handleSearchVal(e)}
                  />
              { (arrayWithoutChildStr[value.state.OpenItemIndex].temp_item_type && window.matchMedia("(max-width: 1200px)").matches ) ?
                <p className={arrayWithoutChildStr[value.state.OpenItemIndex].temp_item_type?
                  "mob-popUp-done-enable" : "mob-popUp-done-disable"}
                onClick={() =>
                  arrayWithoutChildStr[value.state.OpenItemIndex].temp_item_type?
                  handlePopUpState(
                    "open",
                    value.state.OpenItemIndex,
                    arrayWithoutChildStr[value.state.OpenItemIndex].id,
                    "pre"
                    ,arrayWithoutChildStr[value.state.OpenItemIndex].uniqueId
                  )
                  
                  :null
                }>Done</p>
                :
                  value.state.searchValForPopUp && (
                    <p
                      className="Single-selct-search-clear"
                      onClick={() => {
                        value.setState({
                          searchValForPopUp: "",
                        });
                      }}
                    >
                      Clear
                    </p>
                  )}
                </div>
                <div
                  className="single-select-serach-crossdiv"
                  onClick={() =>
                    handlePopUpState(
                      "cancel",
                      value.state.OpenItemIndex,
                      arrayWithoutChildStr[value.state.OpenItemIndex].id,
                      "pre"
                    )
                  }
                >
                  <img
                    src={
                      require("../../assets/images/dashboard/cross.png").default
                    }
                  />
                </div>
              </div>
            </div>

            <div
              className="publish-crossmodal single-select-web-cross"
              onClick={() =>
                handlePopUpState(
                  "cancel",
                  value.state.OpenItemIndex,
                  arrayWithoutChildStr[value.state.OpenItemIndex].id,
                  "pre"
                )
              }
              style={{
                zIndex: "9",
                cursor: "pointer",
              }}
            >
              <img
                src={require("../../assets/images/dashboard/cross.png").default}
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <div>
              {arrayWithoutChildStr[value.state.OpenItemIndex]
                .temp_showL1Options ? (
                (!filterArrForDefaultOption ||
                  filterArrForDefaultOption.length === 0) &&
                (!filterArrForMergeArr || filterArrForMergeArr.length === 0) ? (
                  <div className="select-no-data-found 22">
                    <img
                      src={
                        require("../../assets/images/dashboard/no-media.png")
                          .default
                      }
                    />
                    <p>No results found</p>
                  </div>
                ) : (
                  <div>
                    {!value.state.searchValForPopUp && (
                      <h2 className="single-select-body-header">
                        Default options
                      </h2>
                    )}
                    {filterArrForDefaultOption &&
                      filterArrForDefaultOption.map((res, indx) => {
                        return ((res.item_type === "product_categories" ||
                          res.item_type === "pages" ||
                          res.item_type === "product_search") &&
                          value.state.typeArray &&
                          value.state.typeArray.length > 0) ||
                          (value.state.is_chat_enabled !== 1 &&
                            res.item_type === "chat") ? (
                          // ||
                          // (parseInt(localStorage.getItem("website_technology")) === 4 &&
                          // (value.state.keys_data === null || value.state.keys_data.is_connected !== 1 || value.state.keys_data.authorization_type !== 2)
                          //  && res.item_type==="profile")

                          <></>
                        ) : (
                          <div className="single-select-maindiv">
                            <label className="Single-select-container">
                              {res.item_name ? res.item_name : res.name}
                              <input
                                type="radio"
                                checked={
                                  res[
                                    parseInt(
                                      localStorage.getItem("website_technology")
                                    ) === 3
                                      ? "type"
                                      : "item_type"
                                  ] ===
                                    arrayWithoutChildStr[
                                      value.state.OpenItemIndex
                                    ].temp_item_type &&
                                  (!arrayWithoutChildStr[
                                    value.state.OpenItemIndex
                                  ].temp_individual_selected_item_id ||
                                    arrayWithoutChildStr[
                                      value.state.OpenItemIndex
                                    ].temp_individual_selected_item_id ===
                                      "all")
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  value.handleL1Change(
                                    res,
                                    value.state.OpenItemIndex,
                                    arrayWithoutChildStr[
                                      value.state.OpenItemIndex
                                    ].id,
                                    "pre"
                                  )
                                }
                              />
                              <span className="Single-select-checkmark"></span>
                            </label>
                            {res.type !== undefined &&
                              res.type.indexOf("cms_") > -1 && (
                                <div
                                  className="subdata-svg-container"
                                  onClick={(e) =>
                                    res.type !== undefined &&
                                    res.type.indexOf("cms_") > -1
                                      ? value.handleL1Selection(
                                          e,
                                          arrayWithoutChildStr[
                                            value.state.OpenItemIndex
                                          ].id,
                                          res,
                                          "dynamic",
                                          "pre"
                                        )
                                      : null
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15.474"
                                    height="12.374"
                                    viewBox="0 0 15.474 12.374"
                                  >
                                    <g
                                      id="Group_42665"
                                      data-name="Group 42665"
                                      transform="translate(-1180.001 -431.227)"
                                    >
                                      <path
                                        id="Path_119037"
                                        data-name="Path 119037"
                                        d="M15743-2762h14"
                                        transform="translate(-14563 3199.5)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-width="1.5"
                                      />
                                      <path
                                        id="Path_119038"
                                        data-name="Path 119038"
                                        d="M0,0H8V8"
                                        transform="translate(1188.758 431.757) rotate(45)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-width="1.5"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              )}
                          </div>
                        );
                      })}

                    {value.state.mergeArray &&
                      value.state.mergeArray.length > 0 &&
                      !value.state.searchValForPopUp && (
                        <h2 className="single-select-second-header">
                          Post & taxonomies
                        </h2>
                      )}

                    {filterArrForMergeArr &&
                      filterArrForMergeArr.map((res, k) => {
                        return (
                          <div className="single-select-maindiv">
                            <label className="Single-select-container">
                              {res.name}
                              <input
                                type="radio"
                                checked={
                                  res.type + res.slug + res.rest_base + res.post_type ===
                                    arrayWithoutChildStr[
                                      value.state.OpenItemIndex
                                    ].temp_item_type +
                                      arrayWithoutChildStr[
                                        value.state.OpenItemIndex
                                      ].temp_slug +
                                      arrayWithoutChildStr[
                                        value.state.OpenItemIndex
                                      ].temp_rest_base + arrayWithoutChildStr[
                                        value.state.OpenItemIndex
                                      ].temp_post_type   
                                      &&
                                  (!arrayWithoutChildStr[
                                    value.state.OpenItemIndex
                                  ].temp_individual_selected_item_id ||
                                    arrayWithoutChildStr[
                                      value.state.OpenItemIndex
                                    ].temp_individual_selected_item_id ===
                                      "all")
                                    ? true
                                    : false
                                }
                                //  onChange={()=>value.handleL1Change(res,value.state.OpenItemIndex)}
                                onChange={(e) =>
                                  value.handleMergeArrLeftDropdown(
                                    e,
                                    arrayWithoutChildStr[
                                      value.state.OpenItemIndex
                                    ].id,
                                    "pre",
                                    res,
                                  )
                                }
                              />
                              <span className="Single-select-checkmark"></span>
                            </label>
                            {((res.type !== "search" &&
                              res.rest_base !== "" &&
                              res.rest_base !== undefined) ||
                              res.isCms === 1) && (
                              <div
                                className="subdata-svg-container"
                                onClick={
                                  (e) =>
                                    value.handleL1Selection(
                                      e,
                                      arrayWithoutChildStr[
                                        value.state.OpenItemIndex
                                      ].id,
                                      res,
                                      "dynamic",
                                      "pre"
                                    )

                                  // res.type !== undefined
                                  //   ?
                                  //     res.type.indexOf("cms_") > -1
                                  //     ? value.handleL1Selection(e,arrayWithoutChildStr[value.state.OpenItemIndex].id,res,"dynamic",'pre')
                                  //     : value.handleL1Selection(e,arrayWithoutChildStr[value.state.OpenItemIndex].id,res,"default",'pre')
                                  //   : value.handleL1Selection(e,arrayWithoutChildStr[value.state.OpenItemIndex].id,res,"default",'pre')
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.474"
                                  height="12.374"
                                  viewBox="0 0 15.474 12.374"
                                >
                                  <g
                                    id="Group_42665"
                                    data-name="Group 42665"
                                    transform="translate(-1180.001 -431.227)"
                                  >
                                    <path
                                      id="Path_119037"
                                      data-name="Path 119037"
                                      d="M15743-2762h14"
                                      transform="translate(-14563 3199.5)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                    <path
                                      id="Path_119038"
                                      data-name="Path 119038"
                                      d="M0,0H8V8"
                                      transform="translate(1188.758 431.757) rotate(45)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                  </g>
                                </svg>
                              </div>
                            )}
                          </div>
                        );
                      })}
                  </div>
                )
              ) : arrayWithoutChildStr[value.state.OpenItemIndex]
                  .subdropdownSpinner ? (
                <div className="single-select-popup-spin">
                  <div className="newspinner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                      width="200px"
                      height="200px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g
                        transform="translate(20 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#e6261f"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.375s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(40 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#f7d038"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(60 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#49da9a"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(80 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#4355db"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                </div>
              ) : !filterArrForSubData || filterArrForSubData.length === 0 ? (
                <div className="select-no-data-found 22">
                  <img
                    src={
                      require("../../assets/images/dashboard/no-media.png")
                        .default
                    }
                  />
                  <p>No results found</p>
                </div>
              ) : (
                <BottomScrollListener
                  onBottom={(e) =>
                    value.handleBottomScrollListner(
                      e,
                      arrayWithoutChildStr[value.state.OpenItemIndex],
                      value.state.OpenItemIndex,
                      "pre"
                    )
                  }
                  offset={100}
                >
                  {(scrollRef) => (
                    <div
                      className="scroll-div single-child-scrolldiv"
                      ref={scrollRef}
                     
                    >
                      {filterArrForSubData &&
                        filterArrForSubData.map((res) => {
                          return (
                            <div className="single-select-maindiv">
                              <label className="Single-select-container">
                                {parseInt(
                                  localStorage.getItem("website_technology")
                                ) === 3
                                  ? res.title === undefined
                                    ? res.name
                                    : res.title
                                  : convertHTMLEntity(
                                      res.title === undefined
                                        ? res.name
                                        : res.title.rendered
                                    )}

                                <input
                                  type="radio"
                                  checked={
                                    parseInt(
                                      localStorage.getItem("website_technology")
                                    ) === 3
                                      ? res._id ==
                                        arrayWithoutChildStr[
                                          value.state.OpenItemIndex
                                        ].temp_individual_selected_item_id
                                      : res.id
                                      ? res.id ==
                                        arrayWithoutChildStr[
                                          value.state.OpenItemIndex
                                        ].temp_individual_selected_item_id
                                      : res.term_id ==
                                        arrayWithoutChildStr[
                                          value.state.OpenItemIndex
                                        ].temp_individual_selected_item_id
                                  }
                                  // onChange={()=>value.handleSubDropdownSelection(res,value.state.OpenItemIndex)}
                                  onChange={
                                    parseInt(
                                      localStorage.getItem("website_technology")
                                    ) === 3
                                      ? (e) =>
                                          value.handleL2Selection(
                                            e,
                                            res,
                                            arrayWithoutChildStr[
                                              value.state.OpenItemIndex
                                            ],
                                            "pre"
                                          )
                                      : (e) =>
                                          value.handleL2Selection(
                                            e,
                                            res,
                                            arrayWithoutChildStr[
                                              value.state.OpenItemIndex
                                            ],
                                            "pre"
                                          )
                                  }
                                />
                                <span className="Single-select-checkmark"></span>
                              </label>
                              {((parseInt(
                                localStorage.getItem("website_technology")
                              ) === 3 &&
                                res.child &&
                                res.child.length > 0) ||
                                (parseInt(
                                  localStorage.getItem("website_technology")
                                ) !== 3 &&
                                  res.children &&
                                  res.children.length > 0)) && (
                                <div
                                  className="subdata-svg-container"
                                  onClick={(e) =>
                                    handleSubData(
                                      e,
                                      res,
                                      arrayWithoutChildStr[
                                        value.state.OpenItemIndex
                                      ].id
                                    )
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15.474"
                                    height="12.374"
                                    viewBox="0 0 15.474 12.374"
                                  >
                                    <g
                                      id="Group_42665"
                                      data-name="Group 42665"
                                      transform="translate(-1180.001 -431.227)"
                                    >
                                      <path
                                        id="Path_119037"
                                        data-name="Path 119037"
                                        d="M15743-2762h14"
                                        transform="translate(-14563 3199.5)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-width="1.5"
                                      />
                                      <path
                                        id="Path_119038"
                                        data-name="Path 119038"
                                        d="M0,0H8V8"
                                        transform="translate(1188.758 431.757) rotate(45)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-width="1.5"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              )}
                            </div>
                          );
                        })}

                      {arrayWithoutChildStr[value.state.OpenItemIndex]
                        .subdropdownSpinner !== true &&
                      value.state.paginationSpinner === true ? (
                        <div
                          className={`form-group allFilter`}
                          style={{ background: "none", margin: "auto" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            style={{
                              margin: "auto",
                              background: "rgb(241, 242, 243,0)",
                              display: "block",
                              shaperendering: "auto",
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                            width="200px"
                            height="100px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                          >
                            <g
                              transform="translate(20 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#e6261f"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.375s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(40 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#f7d038"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.25s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(60 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#49da9a"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.125s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(80 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#4355db"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="0s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                          </svg>
                        </div>
                      ) : null}
                    </div>
                  )}
                </BottomScrollListener>
              )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="Single-select-cancel-btn "
            onClick={() =>
              handlePopUpState(
                "cancel",
                value.state.OpenItemIndex,
                arrayWithoutChildStr[value.state.OpenItemIndex].id,
                "pre"
              )
            }
          >
            Cancel
          </button>
          <div
            className="d-flex"
            style={{ margin: "0px", alignItems: "center" }}
          >
            <p className="single-select-selected">
              {arrayWithoutChildStr[value.state.OpenItemIndex].temp_item_type
                ? "1 item selected"
                : ""}
            </p>
            <button
              className={
                arrayWithoutChildStr[value.state.OpenItemIndex].temp_item_type
                  ? "Single-select-done-btn"
                  : "Single-select-done-disable"
              }
              disabled={
                arrayWithoutChildStr[value.state.OpenItemIndex].temp_item_type
                  ? false
                  : true
              }
              onClick={() =>
                handlePopUpState(
                  "open",
                  value.state.OpenItemIndex,
                  arrayWithoutChildStr[value.state.OpenItemIndex].id,
                  "pre",
                  arrayWithoutChildStr[value.state.OpenItemIndex].uniqueId
                )
              }
            >
              Done
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  ) : (
    <></>
  );
};

export const SingleSelectionPopUpForSecondaryMenu = ({
  value,
  arrayWithoutChildStr,
}) => {

  const handlePopUpState = (from,indx,id,nextFrom, uid) => {
    value.setState({
      searchValForPopUp: "",
    });
    value.handleOpenSelectionPopUp(from,indx,id,nextFrom, uid);
  }

  const handleSubData = (e, res, menuId) => {
    let newArr = JSON.parse(JSON.stringify(value.state.secmenuData));
    let newBreadcrumbArr = [];

    let title1 = "";
    let child = [];

    function recursForMenuData(arr) {
      arr.map((eachPage) => {
        if (eachPage.id == menuId) {
          newBreadcrumbArr = eachPage.temp_breadcrumbArr;

          if (parseInt(localStorage.getItem("website_technology")) === 3) {
            title1 = res.title === undefined ? res.name : res.title;
            child = res.child ? res.child : [];
          } else {
            title1 = convertHTMLEntity(
              res.title === undefined ? res.name : res.title.rendered
            );
            child = res.children ? res.children : [];
          }
          newBreadcrumbArr.push({
            name: title1,
            id: res._id ? res._id : res.term_id.toString(),
          });
          eachPage.temp_subData = child;
          eachPage.temp_breadcrumbArr = newBreadcrumbArr;
        }

        if (eachPage.children !== undefined && eachPage.children.length > 0) {
          recursForMenuData(eachPage.children);
        }

        // return arr;
      });
    }

    recursForMenuData(newArr);

    value.setState({
      secmenuData: newArr,
    });
  };
  const handleHomeBtnClick = (e, menuId) => {
    let newArr = [...value.state.secmenuData];

    function recursForMenuData(arr) {
      arr.map((eachPage) => {
        if (eachPage.id == menuId) {
          eachPage.temp_breadcrumbArr = [];
          eachPage.temp_showL1Options = true;
        }

        if (eachPage.children !== undefined && eachPage.children.length > 0) {
          recursForMenuData(eachPage.children);
        }

        // return arr;
      });
    }

    recursForMenuData(newArr);

    value.setState({
      secmenuData: newArr,
    });
  };

  const handleFirstLabelClick = (e, menuId) => {
    let newArr = [...value.state.secmenuData];

    function recursForMenuData(arr) {
      arr.map((eachPage) => {
        if (eachPage.id == menuId) {
          eachPage.temp_breadcrumbArr = [];

          eachPage.temp_subData = eachPage.subdropdownData;
        }

        if (eachPage.children !== undefined && eachPage.children.length > 0) {
          recursForMenuData(eachPage.children);
        }

        // return arr;
      });
    }

    recursForMenuData(newArr);

    value.setState({
      secmenuData: newArr,
    });
  };

  const handleBreadcrumb = (e, val, indx, menuId) => {
    function recurs(arr) {
      arr.map((eachCat) => {
        if (eachCat.parent == val.id) {
          newData.push(eachCat);
        }

        if (parseInt(localStorage.getItem("website_technology")) === 3) {
          if (eachCat.child !== undefined && eachCat.child.length > 0) {
            // if parent has childrens
            recurs(eachCat.child);
          }
        } else {
          if (eachCat.children !== undefined && eachCat.children.length > 0) {
            // if parent has childrens
            recurs(eachCat.children);
          }
        }
      });
    }

    let newArr = [...value.state.secmenuData];
    let newData = [];
    let newBreadcrumb = [];
    let breadcrumb = [];

    function recursForMenuData(arr) {
      arr.map((eachPage) => {
        if (eachPage.id == menuId) {
          breadcrumb = eachPage.temp_breadcrumbArr;

          for (let i = 0; i < breadcrumb.length; i++) {
            if (val.name == breadcrumb[i].name) {
              newBreadcrumb.push(breadcrumb[i]);
              break;
            } else {
              newBreadcrumb.push(breadcrumb[i]);
            }
          }

          eachPage.temp_breadcrumbArr = newBreadcrumb;
          recurs(eachPage.subdropdownData);

          eachPage.temp_subData = newData;
        }

        if (eachPage.children !== undefined && eachPage.children.length > 0) {
          recursForMenuData(eachPage.children);
        }

        // return arr;
      });
    }

    recursForMenuData(newArr);

    value.setState({
      secmenuData: newArr,
    });
  };

  const handleMobBackBtn = (e, menuId) => {
    let newArr = [...value.state.secmenuData];
    let breadcrumb = [];
    function recursForMenuData(arr) {
      arr.map((eachPage) => {
        if (eachPage.id == menuId) {
          breadcrumb = JSON.parse(JSON.stringify(eachPage.temp_breadcrumbArr));

          if (breadcrumb && breadcrumb.length > 0) {
            let newData = [];
            breadcrumb.splice(-1, 1);
            eachPage.temp_breadcrumbArr = breadcrumb;
            function recurs(arr) {
              arr.map((eachCat) => {
                if (eachCat.parent == breadcrumb[breadcrumb.length - 1].id) {
                  newData.push(eachCat);
                }
                if (
                  parseInt(localStorage.getItem("website_technology")) === 3
                ) {
                  if (eachCat.child !== undefined && eachCat.child.length > 0) {
                    // if parent has childrens
                    recurs(eachCat.child);
                  }
                } else {
                  if (
                    eachCat.children !== undefined &&
                    eachCat.children.length > 0
                  ) {
                    // if parent has childrens
                    recurs(eachCat.children);
                  }
                }
              });
            }

            if (breadcrumb && breadcrumb.length > 0) {
              recurs(eachPage.subdropdownData);
              eachPage.temp_subData = newData;
            } else {
              eachPage.temp_subData = eachPage.subdropdownData;
            }
          } else {
            if(eachPage.temp_showL1Options===false){
              eachPage.temp_showL1Options=true;
            }else{
              handlePopUpState(
                "cancel",
                value.state.OpenSecMenuItemIndex,
                arrayWithoutChildStr[value.state.OpenSecMenuItemIndex].id,
                "sec"
              );
            }

            
          }
        }

        if (eachPage.children !== undefined && eachPage.children.length > 0) {
          recursForMenuData(eachPage.children);
        }

        // return arr;
      });
    }

    recursForMenuData(newArr);

    value.setState({
      secmenuData: newArr,
    });
  };

  const handleSearchVal = (e) => {
    value.setState({
      searchValForPopUp: e.target.value,
    });
  };


  const handleReturnTitle = (res) => {
    let title1 = "";
    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      title1 = res.title === undefined ? res.name : res.title;
    } else {
      title1 = convertHTMLEntity(
        res.title === undefined ? res.name : res.title.rendered
      );
    }
    return title1;
  };

  const filterArrForDefaultOption =
  value.state.defaultOptionData &&
  value.state.defaultOptionData.filter((row) =>
    (row.item_name ? row.item_name : row.name)
      .toLowerCase()
      .includes(value.state.searchValForPopUp.toLowerCase())
  );
const filterArrForMergeArr =
  value.state.mergeArray &&
  value.state.mergeArray.filter((row) =>
      (
        (row.name
        .toLowerCase()
        .includes(value.state.searchValForPopUp.toLowerCase()))
        && (!(row.name.toLowerCase().includes("search")))
        && (!(row.name.toLowerCase().includes("navigation")))
        && (!(row.name.toLowerCase().includes("pattern")))
        && (!(row.name.toLowerCase().includes("template")))
        && (!(row.name.toLowerCase().includes("font")))
      
      )
  );
const filterArrForSubData =
  arrayWithoutChildStr &&
  arrayWithoutChildStr.length > 0 &&
  value.state.OpenSecMenuItemIndex !== "" &&
  value.state.OpenSecMenuItemIndex !== undefined &&
  value.state.OpenSecMenuItemIndex !== null &&
  arrayWithoutChildStr[value.state.OpenSecMenuItemIndex].temp_subData &&
  arrayWithoutChildStr[value.state.OpenSecMenuItemIndex].temp_subData.filter((row) =>
  {
    if(    
      ( parseInt( localStorage.getItem("website_technology")) === 3 && row.status !== "draft" && row.show_in_results !==0 )
     || parseInt( localStorage.getItem("website_technology")) !== 3 
      ){
          if(handleReturnTitle(row)
          .toLowerCase()
          .includes(value.state.searchValForPopUp.toLowerCase()))
          {
             return true
          } else{
             return false;
          }
      }else{
        return false;
      }
  }
  );

  return arrayWithoutChildStr &&
    arrayWithoutChildStr.length > 0 &&
    value.state.OpenSecMenuItemIndex !== "" &&
    value.state.OpenSecMenuItemIndex !== undefined &&
    value.state.OpenSecMenuItemIndex !== null ? (
    <div>
      <Modal
        className="Single-select-popup"
        isOpen={value.state.openSecSingleSelectionPopUp}
        centered
        toggle={() =>
          handlePopUpState(
            "cancel",
            value.state.OpenSecMenuItemIndex,
            arrayWithoutChildStr[value.state.OpenSecMenuItemIndex].id,
            "sec"
          )
        }
      >
        <ModalHeader>
          <div className="single-select-header">
            <h2>Link</h2>

            <div className="mob-single-select-top">
              <div className="single-select-bredcrums">
                {!arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                  .temp_showL1Options && (
                  <p onClick={(e) =>
                    handleHomeBtnClick(
                      e,
                      arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                        .id
                    )
                  }>Home &gt;</p>
                )}
                {!arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                  .temp_showL1Options && (
                  <p
                    className={
                      arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                        .temp_breadcrumbArr.length === 0 ||
                      !arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                        .temp_breadcrumbArr
                        ? "single-select-bredcrumchild"
                        : ""
                    }
                    onClick={(e) =>
                      arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                        .temp_breadcrumbArr.length > 0
                        ? handleFirstLabelClick(
                            e,
                            arrayWithoutChildStr[
                              value.state.OpenSecMenuItemIndex
                            ].id
                          )
                        : null
                    }
                  >
                    {
                      arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                        .temp_module_label
                    }{" "}
                  </p>
                )}
                {arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                  .temp_breadcrumbArr &&
                  arrayWithoutChildStr[
                    value.state.OpenSecMenuItemIndex
                  ].temp_breadcrumbArr.map((val, indx) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        &gt;
                        <p
                          className={
                            indx ===
                            arrayWithoutChildStr[
                              value.state.OpenSecMenuItemIndex
                            ].temp_breadcrumbArr.length -
                              1
                              ? "single-select-bredcrumchild"
                              : ""
                          }
                          onClick={(e) =>
                            indx ===
                            arrayWithoutChildStr[
                              value.state.OpenSecMenuItemIndex
                            ].temp_breadcrumbArr.length -
                              1
                              ? null
                              : handleBreadcrumb(
                                  e,
                                  val,
                                  indx,
                                  arrayWithoutChildStr[
                                    value.state.OpenSecMenuItemIndex
                                  ].id
                                )
                          }
                        >
                          {val.name}
                        </p>
                      </div>
                    );
                  })}
              </div>

              <div className="mob-single-select-search">
                <svg
                  onClick={(e) =>
                    handleMobBackBtn(
                      e,
                      arrayWithoutChildStr[value.state.OpenSecMenuItemIndex].id
                    )
                  }
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
                <div className="singleselect-serachdiv">
                  <input
                    placeholder="Search"
                    value={value.state.searchValForPopUp}
                    onChange={(e) => handleSearchVal(e)}
                  />
             {( arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                  .temp_item_type && window.matchMedia("(max-width: 1200px)").matches )?
                <p className={arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                  .temp_item_type?"mob-popUp-done-enable" : "mob-popUp-done-disable"}
                onClick={() =>
                  arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                  .temp_item_type?
                  handlePopUpState(
                    "open",
                    value.state.OpenSecMenuItemIndex,
                    arrayWithoutChildStr[value.state.OpenSecMenuItemIndex].id,
                    "sec",
                    arrayWithoutChildStr[value.state.OpenSecMenuItemIndex].uniqueId,

                  )
                  
                  :null
                }>Done</p>
                :
                  value.state.searchValForPopUp && (
                    <p
                      className="Single-selct-search-clear"
                      onClick={() => {
                        value.setState({
                          searchValForPopUp: "",
                        });
                      }}
                    >
                      Clear
                    </p>
                  )}
                </div>
                <div
                  className="single-select-serach-crossdiv"
                  onClick={() =>
                    handlePopUpState(
                      "cancel",
                      value.state.OpenSecMenuItemIndex,
                      arrayWithoutChildStr[value.state.OpenSecMenuItemIndex].id,
                      "sec"
                    )
                  }
                >
                  <img
                    src={
                      require("../../assets/images/dashboard/cross.png").default
                    }
                  />
                </div>
              </div>
            </div>

            <div
              className="publish-crossmodal single-select-web-cross"
              onClick={() =>
                handlePopUpState(
                  "cancel",
                  value.state.OpenSecMenuItemIndex,
                  arrayWithoutChildStr[value.state.OpenSecMenuItemIndex].id,
                  "sec"
                )
              }
              style={{
                zIndex: "9",
                cursor: "pointer",
              }}
            >
              <img
                src={require("../../assets/images/dashboard/cross.png").default}
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <div>
              {arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                .temp_showL1Options ? (
                  (!filterArrForDefaultOption ||
                    filterArrForDefaultOption.length === 0) &&
                  (!filterArrForMergeArr || filterArrForMergeArr.length === 0) ? (
                    <div className="select-no-data-found 22">
                      <img
                        src={
                          require("../../assets/images/dashboard/no-media.png")
                            .default
                        }
                      />
                      <p>No results found</p>
                    </div>
                  ) : (
                <div>
                  {!value.state.searchValForPopUp && (
                    <h2 className="single-select-body-header">
                      Default options
                    </h2>
                  )}
                  {filterArrForDefaultOption && filterArrForDefaultOption
                      .map((res, indx) => {
                       
                        return ((res.item_type === "product_categories" ||
                          res.item_type === "pages" ||
                          res.item_type === "product_search") &&
                          value.state.typeArray &&
                          value.state.typeArray.length > 0) ||
                          (value.state.is_chat_enabled !== 1 &&
                            res.item_type === "chat") ? (
                          // ||
                          // (parseInt(localStorage.getItem("website_technology")) === 4 &&
                          // (value.state.keys_data === null || value.state.keys_data.is_connected !== 1 || value.state.keys_data.authorization_type !== 2)
                          //  && res.item_type==="profile")

                          <></>
                        ) : (
                          <div className="single-select-maindiv">
                            <label className="Single-select-container">
                              {res.item_name ? res.item_name : res.name}
                              <input
                                type="radio"
                                checked={
                                  res[
                                    parseInt(
                                      localStorage.getItem("website_technology")
                                    ) === 3
                                      ? "type"
                                      : "item_type"
                                  ] ===
                                    arrayWithoutChildStr[
                                      value.state.OpenSecMenuItemIndex
                                    ].temp_item_type &&
                                  (!arrayWithoutChildStr[
                                    value.state.OpenSecMenuItemIndex
                                  ].temp_individual_selected_item_id ||
                                    arrayWithoutChildStr[
                                      value.state.OpenSecMenuItemIndex
                                    ].temp_individual_selected_item_id ===
                                      "all")
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  value.handleL1Change(
                                    res,
                                    value.state.OpenSecMenuItemIndex,
                                    arrayWithoutChildStr[
                                      value.state.OpenSecMenuItemIndex
                                    ].id,
                                    "sec"
                                  )
                                }
                              />
                              <span className="Single-select-checkmark"></span>
                            </label>
                            {res.type !== undefined &&
                              res.type.indexOf("cms_") > -1 && (
                                <div
                                  className="subdata-svg-container"
                                  onClick={(e) =>
                                    res.type !== undefined &&
                                    res.type.indexOf("cms_") > -1
                                      ? value.handleL1Selection(
                                          e,
                                          arrayWithoutChildStr[
                                            value.state.OpenSecMenuItemIndex
                                          ].id,
                                          res,
                                          "dynamic",
                                          "sec"
                                        )
                                      : null
                                  }
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="15.474"
                                    height="12.374"
                                    viewBox="0 0 15.474 12.374"
                                  >
                                    <g
                                      id="Group_42665"
                                      data-name="Group 42665"
                                      transform="translate(-1180.001 -431.227)"
                                    >
                                      <path
                                        id="Path_119037"
                                        data-name="Path 119037"
                                        d="M15743-2762h14"
                                        transform="translate(-14563 3199.5)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-width="1.5"
                                      />
                                      <path
                                        id="Path_119038"
                                        data-name="Path 119038"
                                        d="M0,0H8V8"
                                        transform="translate(1188.758 431.757) rotate(45)"
                                        fill="none"
                                        stroke="#bcc1ce"
                                        stroke-width="1.5"
                                      />
                                    </g>
                                  </svg>
                                </div>
                              )}
                          </div>
                        );
                      })}

                  {value.state.mergeArray &&
                    value.state.mergeArray.length > 0 &&
                    !value.state.searchValForPopUp && (
                      <h2 className="single-select-second-header">
                        Post & taxonomies
                      </h2>
                    )}

                  {filterArrForMergeArr && filterArrForMergeArr
                      .map((res, k) => {
                        return (
                          <div className="single-select-maindiv">
                            <label className="Single-select-container">
                              {res.name}
                              <input
                                type="radio"
                                checked={
                                  res.type + res.slug + res.rest_base + res.post_type ===
                                    arrayWithoutChildStr[
                                      value.state.OpenSecMenuItemIndex
                                    ].temp_item_type +
                                      arrayWithoutChildStr[
                                        value.state.OpenSecMenuItemIndex
                                      ].temp_slug +
                                      arrayWithoutChildStr[
                                        value.state.OpenSecMenuItemIndex
                                      ].temp_rest_base + arrayWithoutChildStr[
                                        value.state.OpenSecMenuItemIndex
                                      ].temp_post_type &&
                                  (!arrayWithoutChildStr[
                                    value.state.OpenSecMenuItemIndex
                                  ].temp_individual_selected_item_id ||
                                    arrayWithoutChildStr[
                                      value.state.OpenSecMenuItemIndex
                                    ].temp_individual_selected_item_id ===
                                      "all")
                                    ? true
                                    : false
                                }
                                //  onChange={()=>value.handleL1Change(res,value.state.OpenSecMenuItemIndex)}
                                onChange={(e) =>
                                  value.handleMergeArrLeftDropdown(
                                    e,
                                    arrayWithoutChildStr[
                                      value.state.OpenSecMenuItemIndex
                                    ].id,
                                    "sec",
                                    res,
                                  )
                                }
                              />
                              <span className="Single-select-checkmark"></span>
                            </label>
                            {((res.type !== "search" &&
                              res.rest_base !== "" &&
                              res.rest_base !== undefined) ||
                              res.isCms === 1) && (
                              <div
                                className="subdata-svg-container"
                                onClick={
                                  (e) =>
                                    value.handleL1Selection(
                                      e,
                                      arrayWithoutChildStr[
                                        value.state.OpenSecMenuItemIndex
                                      ].id,
                                      res,
                                      "dynamic",
                                      "sec"
                                    )

                                  // res.type !== undefined
                                  //   ?
                                  //     res.type.indexOf("cms_") > -1
                                  //     ? value.handleL1Selection(e,arrayWithoutChildStr[value.state.OpenSecMenuItemIndex].id,res,"dynamic",'pre')
                                  //     : value.handleL1Selection(e,arrayWithoutChildStr[value.state.OpenSecMenuItemIndex].id,res,"default",'pre')
                                  //   : value.handleL1Selection(e,arrayWithoutChildStr[value.state.OpenSecMenuItemIndex].id,res,"default",'pre')
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.474"
                                  height="12.374"
                                  viewBox="0 0 15.474 12.374"
                                >
                                  <g
                                    id="Group_42665"
                                    data-name="Group 42665"
                                    transform="translate(-1180.001 -431.227)"
                                  >
                                    <path
                                      id="Path_119037"
                                      data-name="Path 119037"
                                      d="M15743-2762h14"
                                      transform="translate(-14563 3199.5)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                    <path
                                      id="Path_119038"
                                      data-name="Path 119038"
                                      d="M0,0H8V8"
                                      transform="translate(1188.758 431.757) rotate(45)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                  </g>
                                </svg>
                              </div>
                            )}
                          </div>
                        );
                      })}
                </div>)
              ) : arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                  .subdropdownSpinner ? (
                <div className="single-select-popup-spin">
                  <div className="newspinner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                      width="200px"
                      height="200px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g
                        transform="translate(20 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#e6261f"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.375s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(40 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#f7d038"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(60 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#49da9a"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(80 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#4355db"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                </div>
              ) : (
                !filterArrForSubData || filterArrForSubData.length === 0 ? (
                  <div className="select-no-data-found 22">
                    <img
                      src={
                        require("../../assets/images/dashboard/no-media.png")
                          .default
                      }
                    />
                    <p>No results found</p>
                  </div>
                ) : (
                  <BottomScrollListener
                  onBottom={(e) =>
                    value.handleBottomScrollListner(
                      e,
                      arrayWithoutChildStr[value.state.OpenSecMenuItemIndex],
                      value.state.OpenSecMenuItemIndex,
                      "sec"
                    )
                  }
                  offset={100}
                >
                  {(scrollRef) => (
                    <div
                      className="scroll-div single-child-scrolldiv"
                      ref={scrollRef}
                     
                    >
                  {filterArrForSubData && filterArrForSubData
                      .map((res) => {
                        return (
                          <div className="single-select-maindiv">
                            <label className="Single-select-container">
                              {parseInt(
                                localStorage.getItem("website_technology")
                              ) === 3
                                ? res.title === undefined
                                  ? res.name
                                  : res.title
                                : convertHTMLEntity(
                                    res.title === undefined
                                      ? res.name
                                      : res.title.rendered
                                  )}

                              <input
                                type="radio"
                                checked={
                                  parseInt(
                                    localStorage.getItem("website_technology")
                                  ) === 3
                                    ? res._id ==
                                      arrayWithoutChildStr[
                                        value.state.OpenSecMenuItemIndex
                                      ].temp_individual_selected_item_id
                                    : res.id
                                    ? res.id ==
                                      arrayWithoutChildStr[
                                        value.state.OpenSecMenuItemIndex
                                      ].temp_individual_selected_item_id
                                    : res.term_id ==
                                      arrayWithoutChildStr[
                                        value.state.OpenSecMenuItemIndex
                                      ].temp_individual_selected_item_id
                                }
                                // onChange={()=>value.handleSubDropdownSelection(res,value.state.OpenSecMenuItemIndex)}
                                onChange={
                                  parseInt(
                                    localStorage.getItem("website_technology")
                                  ) === 3
                                    ? (e) =>
                                        value.handleL2Selection(
                                          e,
                                          res,
                                          arrayWithoutChildStr[
                                            value.state.OpenSecMenuItemIndex
                                          ],
                                          "sec"
                                        )
                                    : (e) =>
                                        value.handleL2Selection(
                                          e,
                                          res,
                                          arrayWithoutChildStr[
                                            value.state.OpenSecMenuItemIndex
                                          ],
                                          "sec"
                                        )
                                }
                              />
                              <span className="Single-select-checkmark"></span>
                            </label>
                            {((parseInt(
                              localStorage.getItem("website_technology")
                            ) === 3 &&
                              res.child &&
                              res.child.length > 0) ||
                              (parseInt(
                                localStorage.getItem("website_technology")
                              ) !== 3 &&
                                res.children &&
                                res.children.length > 0)) && (
                              <div
                                className="subdata-svg-container"
                                onClick={(e) =>
                                  handleSubData(
                                    e,
                                    res,
                                    arrayWithoutChildStr[
                                      value.state.OpenSecMenuItemIndex
                                    ].id
                                  )
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.474"
                                  height="12.374"
                                  viewBox="0 0 15.474 12.374"
                                >
                                  <g
                                    id="Group_42665"
                                    data-name="Group 42665"
                                    transform="translate(-1180.001 -431.227)"
                                  >
                                    <path
                                      id="Path_119037"
                                      data-name="Path 119037"
                                      d="M15743-2762h14"
                                      transform="translate(-14563 3199.5)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                    <path
                                      id="Path_119038"
                                      data-name="Path 119038"
                                      d="M0,0H8V8"
                                      transform="translate(1188.758 431.757) rotate(45)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                  </g>
                                </svg>
                              </div>
                            )}
                          </div>
                        );
                      })}

                     {arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                        .subdropdownSpinner !== true &&
                      value.state.paginationSpinner === true ? (
                        <div
                          className={`form-group allFilter`}
                          style={{ background: "none", margin: "auto" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            style={{
                              margin: "auto",
                              background: "rgb(241, 242, 243,0)",
                              display: "block",
                              shaperendering: "auto",
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                            width="200px"
                            height="100px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                          >
                            <g
                              transform="translate(20 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#e6261f"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.375s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(40 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#f7d038"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.25s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(60 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#49da9a"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.125s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(80 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#4355db"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="0s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                          </svg>
                        </div>
                      ) : null}

                      </div>
                  )}
                  </BottomScrollListener>
                ))}
      
              
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button
            className="Single-select-cancel-btn "
            onClick={() =>
              handlePopUpState(
                "cancel",
                value.state.OpenSecMenuItemIndex,
                arrayWithoutChildStr[value.state.OpenSecMenuItemIndex].id,
                "sec"
              )
            }
          >
            Cancel
          </button>
          <div
            className="d-flex"
            style={{ margin: "0px", alignItems: "center" }}
          >
            <p className="single-select-selected">
              {arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                .temp_item_type
                ? "1 item selected"
                : ""}
            </p>
            <button
              className={
                arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                  .temp_item_type
                  ? "Single-select-done-btn"
                  : "Single-select-done-disable"
              }
              disabled={
                arrayWithoutChildStr[value.state.OpenSecMenuItemIndex]
                  .temp_item_type
                  ? false
                  : true
              }
              onClick={() =>
                handlePopUpState(
                  "open",
                  value.state.OpenSecMenuItemIndex,
                  arrayWithoutChildStr[value.state.OpenSecMenuItemIndex].id,
                  "sec",
                  arrayWithoutChildStr[value.state.OpenSecMenuItemIndex].uniqueId,

                )
              }
            >
              Done
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  ) : (
    <></>
  );
};

export const SingleSelectionPopUpForNotification = (
  {
    openSingleSelectionPopUp,
    techOPtions,
    searchValForPopUp,
    setSearchValForPopUp,
    mergeArray,
    moduleName,
    setModuleName,
    temp_subData,
    temp_showL1Options,
    handleOpenSelectionPopUp,
    handleHomeBtnClick,
    temp_breadcrumbArr,
    temp_module_label,
    handleBreadcrumb,
    handleMobBackBtn,
    temp_item_type,
    temp_invidual_selected_item_id,
    temp_rest_base,
    temp_slug,
    subdropdownSpinner,
    paginationSpinner,
    handleFirstLabelClick,
    handleSearchVal,
    handleSubData,
    handleL1Change,
    handleMergeArrLeftDropdown,
    handleParentClick,
    handleBottomScrollListner,
    handleSubdropdown,
    typeArray,
    is_chat_enabled,
    keys_data,
    temp_post_type,

  }) => {

    const handlePopUpState = (from) => {
      setSearchValForPopUp("");
      handleOpenSelectionPopUp(from);
    }

  const handleReturnTitle = (res) => {
    let title1 = "";
    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      title1 = res.title === undefined ? res.name : res.title;
    } else {
      title1 = convertHTMLEntity(
        res.title === undefined ? res.name : res.title.rendered
      );
    }
    return title1;
  };

  const filterArrForDefaultOption =
    techOPtions &&
    techOPtions.filter((row) =>
      (row.item_name ? row.item_name : row.name)
        .toLowerCase()
        .includes(searchValForPopUp.toLowerCase())
    );
  const filterArrForMergeArr =
    mergeArray &&
    mergeArray.filter((row) =>
      (
        (row.name
        .toLowerCase()
        .includes(searchValForPopUp.toLowerCase()))
        && (!(row.name.toLowerCase().includes("search")))
        && (!(row.name.toLowerCase().includes("navigation")))
        && (!(row.name.toLowerCase().includes("pattern")))
        && (!(row.name.toLowerCase().includes("template")))
        && (!(row.name.toLowerCase().includes("font")))
      
      )
    );

  const filterArrForSubData =
    temp_subData &&
    temp_subData.filter((row) =>
    {
      if(    
        ( parseInt( localStorage.getItem("website_technology")) === 3 && row.status !== "draft" && row.show_in_results !==0 )
       || parseInt( localStorage.getItem("website_technology")) !== 3 
        ){
            if(handleReturnTitle(row)
            .toLowerCase()
            .includes(searchValForPopUp.toLowerCase()))
            {
               return true
            } else{
               return false;
            }
        }else{
          return false;
        }
    }

    );

  return (
    <div>
      <Modal
        className="Single-select-popup"
        isOpen={openSingleSelectionPopUp}
        centered
        toggle={() => handlePopUpState("cancel")}
      >
        <ModalHeader>
          <div className="single-select-header">
            <h2>Link</h2>

            <div className="mob-single-select-top">
              <div className="single-select-bredcrums">
                {!temp_showL1Options && (
                  <p onClick={(e) => handleHomeBtnClick(e)}>Home &gt;</p>
                )}
                {!temp_showL1Options && (
                  <p
                    className={
                      temp_breadcrumbArr.length === 0 ||
                      !temp_breadcrumbArr
                        ? "single-select-bredcrumchild"
                        : ""
                    }
                    onClick={(e) =>
                      temp_breadcrumbArr.length > 0
                        ? handleFirstLabelClick(e)
                        : null
                    }
                  >
                    {temp_module_label}{" "}
                  </p>
                )}
                {temp_breadcrumbArr &&
                 temp_breadcrumbArr.map((val, indx) => {
                    return (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        &gt;
                        <p
                          className={
                            indx ===
                            temp_breadcrumbArr.length -
                              1
                              ? "single-select-bredcrumchild"
                              : ""
                          }
                          onClick={(e) =>
                            indx ===
                            temp_breadcrumbArr.length -
                              1
                              ? null
                              : handleBreadcrumb(e, val, indx)
                          }
                        >
                          {val.name}
                        </p>
                      </div>
                    );
                  })}
              </div>

              <div className="mob-single-select-search">
                <svg
                  onClick={(e) => handleMobBackBtn(e)}
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
                <div className="singleselect-serachdiv">
                  <input
                    placeholder="Search"
                    value={searchValForPopUp}
                    onChange={(e) => handleSearchVal(e)}
                  />

               { ( temp_item_type && window.matchMedia("(max-width: 1200px)").matches )?
                <p className={temp_item_type?"mob-popUp-done-enable" : "mob-popUp-done-disable"}
                 onClick={() => temp_item_type?handlePopUpState("open") : null
                }
                >
                Done
                </p>
                :
                  searchValForPopUp && (
                    <p
                      className="Single-selct-search-clear"
                      onClick={() => {
                        setSearchValForPopUp("");
                      }}
                    >
                      Clear
                    </p>
                  )}
                </div>
                <div
                  className="single-select-serach-crossdiv"
                  onClick={() =>
                    handlePopUpState("cancel")
                  }
                >
                  <img
                    src={
                      require("../../assets/images/dashboard/cross.png").default
                    }
                  />
                </div>
              </div>
            </div>

            <div
              className="publish-crossmodal single-select-web-cross"
              onClick={() =>
                handlePopUpState("cancel")
              }
              style={{
                zIndex: "9",
                cursor: "pointer",
              }}
            >
              <img
                src={require("../../assets/images/dashboard/cross.png").default}
              />
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div>
            <div>
              {temp_showL1Options ? (
                  (!filterArrForDefaultOption ||
                    filterArrForDefaultOption.length === 0) &&
                  (!filterArrForMergeArr || filterArrForMergeArr.length === 0) ? (
                    <div className="select-no-data-found 22">
                      <img
                        src={
                          require("../../assets/images/dashboard/no-media.png")
                            .default
                        }
                      />
                      <p>No results found</p>
                    </div>
                  ) : (
                <div>
                  {!searchValForPopUp && (
                    <h2 className="single-select-body-header">
                      Default options
                    </h2>
                  )}
                  {filterArrForDefaultOption && filterArrForDefaultOption
                      .map((res, indx) => {
                   
                        return ((res.item_type === "product_categories" ||
                          res.item_type === "pages" ||
                          res.item_type === "product_search") &&
                          typeArray &&
                          typeArray.length > 0) ||
                          (is_chat_enabled !== 1 &&
                            res.item_type === "chat") ||
                          (parseInt(
                            localStorage.getItem("website_technology")
                          ) === 4 &&
                            (keys_data === null || keys_data === "" ||
                              keys_data.is_connected !== 1 ||
                              keys_data.authorization_type !== 2) &&
                            res.item_type === "profile") ? (
                          <></>
                        ) : (
                          <div className="single-select-maindiv">
                            <label className="Single-select-container">
                              {res.item_name}
                              <input
                                type="radio"
                                checked={
                                  res.item_type === temp_item_type &&
                                  (!temp_invidual_selected_item_id ||
                                    temp_invidual_selected_item_id === "all")
                                    ? true
                                    : false
                                }
                                onChange={() =>
                                  handleL1Change(
                                    res,
                                  )
                                }
                                
                              />
                              <span className="Single-select-checkmark"></span>
                            </label>
                          </div>
                        );
                      })}

                  {mergeArray &&
                    mergeArray.length > 0 &&
                    !searchValForPopUp && (
                      <h2 className="single-select-second-header">
                        Post & taxonomies
                      </h2>
                    )}

                  {filterArrForMergeArr &&
                    filterArrForMergeArr
                      .map((res, k) => {
                     
                        return (
                          <div className="single-select-maindiv">
                            <label className="Single-select-container">
                              {res.name}
                              <input
                                type="radio"
                                checked={
                                  res.type + res.slug + res.rest_base + res.post_type ===
                                   temp_item_type + temp_slug + temp_rest_base + temp_post_type &&
                                  (!temp_invidual_selected_item_id ||
                                    temp_invidual_selected_item_id === "all")
                                    ? true
                                    : false
                                }
                                
                                onChange={(e) =>
                                  handleMergeArrLeftDropdown(
                                    e,
                                    res,
                                  )
                                }
                              />
                              <span className="Single-select-checkmark"></span>
                            </label>
                            {((res.type !== "search" &&
                              res.rest_base !== "" &&
                              res.rest_base !== undefined) ||
                              res.isCms === 1) && (
                              <div
                                className="subdata-svg-container"
                                onClick={() =>
                                  handleParentClick(res)
                                }
                             
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.474"
                                  height="12.374"
                                  viewBox="0 0 15.474 12.374"
                                >
                                  <g
                                    id="Group_42665"
                                    data-name="Group 42665"
                                    transform="translate(-1180.001 -431.227)"
                                  >
                                    <path
                                      id="Path_119037"
                                      data-name="Path 119037"
                                      d="M15743-2762h14"
                                      transform="translate(-14563 3199.5)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                    <path
                                      id="Path_119038"
                                      data-name="Path 119038"
                                      d="M0,0H8V8"
                                      transform="translate(1188.758 431.757) rotate(45)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                  </g>
                                </svg>
                              </div>
                            )}
                          </div>
                        );
                      })}
                </div>)
              ) :subdropdownSpinner ? (
                <div className="single-select-popup-spin">
                  <div className="newspinner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                      width="200px"
                      height="200px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g
                        transform="translate(20 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#e6261f"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.375s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(40 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#f7d038"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(60 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#49da9a"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(80 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#4355db"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                </div>
              ) : (
                !filterArrForSubData || filterArrForSubData.length === 0 ? (
                  <div className="select-no-data-found 22">
                    <img
                      src={
                        require("../../assets/images/dashboard/no-media.png")
                          .default
                      }
                    />
                    <p>No results found</p>
                  </div>
                ) : (
                  <BottomScrollListener
                  onBottom={(e) =>
                    handleBottomScrollListner(
                      e,
                      temp_rest_base,
                    )
                  }
                  offset={100}
                >
                  {(scrollRef) => (
                    <div
                      className="scroll-div single-child-scrolldiv"
                      ref={scrollRef}
                     
                    >
               
                  {filterArrForSubData && filterArrForSubData  
                    .map((res) => {
                       
                        return (
                          (res.title?.rendered || res.name || (parseInt(localStorage.getItem("website_technology")) === 3 && res.title)) &&
                          <div className="single-select-maindiv">
                            <label className="Single-select-container">
                              {parseInt(
                                localStorage.getItem("website_technology")
                              ) === 3
                                ? res.title === undefined
                                  ? res.name
                                  : res.title
                                : convertHTMLEntity(
                                    res.title === undefined
                                      ? res.name
                                      : res.title.rendered
                                  )}

                              <input
                                type="radio"
                                checked={
                                  parseInt(
                                    localStorage.getItem("website_technology")
                                  ) === 3
                                    ? res._id ==
                                    temp_invidual_selected_item_id
                                    : res.id
                                    ? res.id ==
                                     temp_invidual_selected_item_id
                                    : res.term_id ==
                                      temp_invidual_selected_item_id
                                }
                                
                                onChange={
                                  parseInt(
                                    localStorage.getItem("website_technology")
                                  ) === 3
                                    ? (e) =>
                                        handleSubdropdown(
                                          e,
                                          res._id,
                                        res.title === undefined
                                          ? res.name
                                          : res.title
                                        )
                                    : (e) =>
                                        handleSubdropdown(
                                          e,
                                          parseInt(
                                            res.id ? res.id : res.term_id
                                          ),
                                          convertHTMLEntity(
                                            res.title === undefined
                                              ? res.name
                                              : res.title.rendered
                                          ),
                                        )
                                }
                              />
                              <span className="Single-select-checkmark"></span>
                            </label>
                            {((parseInt(
                              localStorage.getItem("website_technology")
                            ) === 3 &&
                              res.child &&
                              res.child.length > 0) ||
                              (parseInt(
                                localStorage.getItem("website_technology")
                              ) !== 3 &&
                                res.children &&
                                res.children.length > 0)) && (
                              <div
                                className="subdata-svg-container"
                                onClick={(e) => handleSubData(e, res)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.474"
                                  height="12.374"
                                  viewBox="0 0 15.474 12.374"
                                >
                                  <g
                                    id="Group_42665"
                                    data-name="Group 42665"
                                    transform="translate(-1180.001 -431.227)"
                                  >
                                    <path
                                      id="Path_119037"
                                      data-name="Path 119037"
                                      d="M15743-2762h14"
                                      transform="translate(-14563 3199.5)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                    <path
                                      id="Path_119038"
                                      data-name="Path 119038"
                                      d="M0,0H8V8"
                                      transform="translate(1188.758 431.757) rotate(45)"
                                      fill="none"
                                      stroke="#bcc1ce"
                                      stroke-width="1.5"
                                    />
                                  </g>
                                </svg>
                              </div>
                            )}
                          </div>
                        );
                      })}
                       {subdropdownSpinner !== true &&
                      paginationSpinner === true ? (
                        <div
                          className={`form-group allFilter`}
                          style={{ background: "none", margin: "auto" }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            style={{
                              margin: "auto",
                              background: "rgb(241, 242, 243,0)",
                              display: "block",
                              shaperendering: "auto",
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                            width="200px"
                            height="100px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                          >
                            <g
                              transform="translate(20 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#e6261f"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.375s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(40 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#f7d038"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.25s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(60 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#49da9a"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.125s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(80 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#4355db"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="0s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                          </svg>
                        </div>
                      ) : null}
                </div>
              )}
                </BottomScrollListener>
                )
              )}
            </div>
          </div>

        </ModalBody>
        <ModalFooter>
          <button
            className="Single-select-cancel-btn "
            onClick={() =>handlePopUpState("cancel")}
          >
            Cancel
          </button>
          <div
            className="d-flex"
            style={{ margin: "0px", alignItems: "center" }}
          >
            <p className="single-select-selected">
              {temp_item_type
                ? "1 item selected"
                : ""}
            </p>
    
            <button
               className={
                temp_item_type
                  ? "Single-select-done-btn"
                  : "Single-select-done-disable"
              }
              disabled={
               temp_item_type
                  ? false
                  : true
              }
              onClick={() =>handlePopUpState("open")
              }
            >
              Done
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};
