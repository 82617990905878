import React, { useEffect, useRef, useState } from "react";
import imagebackground from "../../../assets/images/dashboard/cms/uploadbackground.png";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import "../../../Layouts/Pages/BottomBar/bottombar.css";
import BottomIconField from "./BottomIconField.js";
import Tooltip from "../../commonIcons/Tooltip";
import EyeIcon from "../../commonIcons/EyeIcon";
import BottomLinkField from "./BottomLinkField";
import { useSelector } from "react-redux";
import DefaulLandingScreen from "../../../Components/commonComponents/Modal.js";
import DropArrow from "../../commonIcons/DropArrow.js";

const BottomItemAccordian = ({ bottomState, setBottomState }) => {
  const leftSideRef = useRef(null); // Define the ref using useRef
  const { taxonomyData, typeData } = useSelector(
    (state) => state.userWebsiteData
  );
  const [taxonomyTypeMergeData, setTaxonomyTypeMergeData] = useState([]);

  useEffect(() => {
    if (
      (taxonomyData && taxonomyData.length && typeData && typeData.length) ||
      parseInt(localStorage.getItem("website_technology")) === 3
    ) {
      mergeItemLinkList();
    }
  }, [taxonomyData?.length, typeData?.length]);

  const mergeItemLinkList = () => {
    let currMergeArr = [];
    let res = [];

    if (parseInt(localStorage.getItem("website_technology")) === 3) {
      currMergeArr = [
        {
          name: "Pages",
          type: "cms_page",
          post_type: "",
          rest_base: "",
          slug: "page",
          is_tree: 4,
          individual_selected_item_id: "",
          temp_individual_selected_item_id: "",
        },
        {
          name: "Posts",
          type: "cms_post",
          post_type: "",
          rest_base: "",
          slug: "post",
          is_tree: 5,
          individual_selected_item_id: "",
          temp_individual_selected_item_id: "",
        },
        {
          name: "Post Categories",
          type: "cms_category",
          post_type: "",
          rest_base: "",
          slug: "category",
          is_tree: 6,
          individual_selected_item_id: "",
          temp_individual_selected_item_id: "",
        },
        {
          name: "Post Tags",
          type: "cms_tag",
          post_type: "",
          rest_base: "",
          slug: "tag",
          is_tree: 7,
          individual_selected_item_id: "",
          temp_individual_selected_item_id: "",
        },
      ];
    } else {
      for (let i = 0; i < typeData?.length; i++) {
        res = [];

        res.name = typeData[i].name;
        res.slug = typeData[i].slug;
        res.rest_base = typeData[i].rest_base;
        res.type = "post_type";
        res.post_type = typeData[i].rest_base;
        res.individual_selected_item_id = "";
        res.is_tree = 3;

        currMergeArr.push(res);

        res = [];
        res.name = typeData[i].name + " search";
        res.slug = typeData[i].slug;
        res.rest_base = typeData[i].rest_base;
        res.type = "search";
        res.post_type = typeData[i].rest_base;
        res.individual_selected_item_id = "";
        res.is_tree = 0;
        currMergeArr.push(res);

        for (let j = 0; j < typeData[i]?.taxonomies?.length; j++) {
          if (taxonomyData?.length !== 0) {
            const index = taxonomyData
              .map((object) => object.slug)
              .indexOf(typeData[i].taxonomies[j]);
            res = [];
            res.name =
              taxonomyData[index].name +
              (typeData[i]?.taxonomies?.length > 1
                ? " (" + typeData[i].name + ")"
                : "");
            res.slug = taxonomyData[index].slug;
            res.rest_base = taxonomyData[index].rest_base;
            res.type = "taxonomy";
            res.post_type = typeData[i].rest_base;
            res.individual_selected_item_id = "";

            res.is_tree = 3;

            if (typeData[i].taxonomies[j] === "product_cat") {
              res.is_tree = 1;
            } else if (typeData[i].taxonomies[j] === "post_cat") {
              res.is_tree = 2;
            }

            currMergeArr.push(res);
          }
        }
      }

      currMergeArr = currMergeArr.filter(
        (row) =>
          !row.name.toLowerCase().includes("search") &&
          !row.name.toLowerCase().includes("navigation") &&
          !row.name.toLowerCase().includes("pattern") &&
          !row.name.toLowerCase().includes("template") &&
          !row.name.toLowerCase().includes("font")
      );

      currMergeArr = currMergeArr.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
    }
    setTaxonomyTypeMergeData(currMergeArr);
  };

  const handleDragEnd = (result) => {
    if (
      result.destination === null ||
      result.destination === undefined ||
      result.source === undefined ||
      !bottomState.enable_bottom_menu
    ) {
      return;
    } else {
      setBottomState({
        bottom_menu_items: reorderList(
          bottomState.bottom_menu_items,
          result.source.index,
          result.destination.index
        ),
        pewview_bottom_item: reorderList(
          bottomState.bottom_menu_items,
          result.source.index,
          result.destination.index
        ),
      });
    }
  };

  // Drag an drop
  const reorderList = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    result.map((row, indx) => {
      row.position = indx;
    });
    return result;
  };

  const handleRowStatus = (indx) => {
    let newbottom_menu_items = [...bottomState.bottom_menu_items];
    newbottom_menu_items[indx]["status"] =
      newbottom_menu_items[indx]["status"] == 1 ? 0 : 1;

    commonRowFunction(newbottom_menu_items);
  };

  const updateBottomState = (newArr) => {
    let setvalue;
    let showexpand = false;

    if (newArr.length === 5) {
      setvalue = 5;
    } else if (newArr.length > 5) {
      setvalue = 4;
      showexpand = true;
    } else {
      setvalue = newArr.length + 1;
    }

    setBottomState({
      bottom_menu_items: newArr,
      setvalue,
      pewview_bottom_item: newArr,
      showexpand,
    });
  };

  const commonRowFunction = (newArr) => {
    setBottomState({ isRowOpen: new Array(newArr.length).fill(false) });
    updateBottomState(newArr);
  };

  const commonRow2Function = (newArr) => {
    updateBottomState(newArr);
  };

  const handleIsRowOpen = (e, key, from) => {
    setBottomState({ OpenItemIndex: key });

    let updateRowOpen = [...bottomState.isRowOpen];

    updateRowOpen = updateRowOpen.map((res, j) =>
      key === j ? (from === "linkErr" ? true : !res) : false
    );

    setBottomState({ isRowOpen: updateRowOpen });
  };

  const parentSaveIconFn = (uploadedIconName, selectedColorType, indx) => {
    let newbottom_menu_items = [...bottomState.bottom_menu_items];
    newbottom_menu_items[indx]["item_icon"] = uploadedIconName;
    newbottom_menu_items[indx]["item_icon_newtype"] = "flaticon";
    newbottom_menu_items[indx]["item_icon_type"] = "new";
    newbottom_menu_items[indx]["item_icon_flaticon_color"] = selectedColorType;

    setBottomState({
      bottom_menu_items: newbottom_menu_items,
      selectediconitemclick: false,
    });
  };

  const handleItemName = (e, indx) => {
    e.preventDefault();
    let newbottom_menu_items = [...bottomState.bottom_menu_items];
    newbottom_menu_items[indx]["item_name"] = e.target.value;

    setBottomState({ bottom_menu_items: newbottom_menu_items });
  };

  const defaultcheckboxhandle = (e, indx) => {
    if (e.target.checked && bottomState.is_any_default_item_in_menu) {
      setBottomState({
        openDefaultItemValidationPopUp:
          !bottomState.openDefaultItemValidationPopUp,
      });
    } else {
      settingDefaultItemVal(e.target.checked, indx);
      if(!bottomState.enable_bottom_menu && !e.target.checked){
        setBottomState({ isRowOpen: new Array(bottomState.bottom_menu_items.length).fill(false) });
      }
    }

    setBottomState({ btmIndxForPopUp: indx });
  };

  const handleOpenItemValidationPopUp = (e, from) => {
    if (from === "confirm") {
      settingDefaultItemVal(true, bottomState.btmIndxForPopUp);
    }

    setBottomState({
      openDefaultItemValidationPopUp:
        !bottomState.openDefaultItemValidationPopUp,
    });
  };

  const settingDefaultItemVal = (value, indx) => {
    setBottomState({ is_any_default_item_in_menu: false });

    let newArr = [...bottomState.bottom_menu_items];

    for (let i = 0; i < newArr.length; i++) {
      newArr[i]["is_default"] = 0;
    }

    if (value) {
      newArr[indx]["is_default"] = 1;
      newArr[indx]["status"] = 1;
    } else {
      newArr[indx]["is_default"] = 0;
    }

    commonRow2Function(newArr);
  };

  const copyRow = (e, data) => {
    e.preventDefault();
    const data1 = {
      id: bottomState.bottom_menu_items.length - 1,
      item_name: data.item_name,
      item_icon: data.item_icon,
      item_icon_type: data.item_icon_type,
      item_icon_newtype: data.item_icon_newtype,
      item_position: bottomState.bottom_menu_items.length,
      invidual_selected_item_id: data.invidual_selected_item_id,
      isCms: data.isCms,
      is_default: 0,
      item_icon_flaticon_color: data.item_icon_flaticon_color,
      item_title: data.item_title,
      item_type: data.item_type,
      post_type: data.post_type,
      rest_base: data.rest_base,
      slug: data.slug,
      status: data.status,

      temp_item_type: data.temp_item_type,
      temp_slug: data.slug,
      temp_post_type: data.post_type,
      temp_rest_base: data.rest_base,
      module_label: data.module_label,
      temp_module_label: data.temp_module_label,
      temp_invidual_selected_item_id: data.temp_invidual_selected_item_id,
      temp_subData: data.temp_subData,
      subData: data.subData,
      temp_breadcrumbArr: data.temp_breadcrumbArr,
      breadcrumbArr: data.breadcrumbArr,
      showL1Options: data.showL1Options,
      temp_showL1Options: data.temp_showL1Options,
      subdropdownSpinner: data.subdropdownSpinner,
      subdropdownData: data.subdropdownData,
    };
    let newArr = [...bottomState.bottom_menu_items];
    newArr.push(data1);

    commonRowFunction(newArr);
  };

  const deleteRow = (e, key) => {
    e.preventDefault();
    setBottomState({
      OpenItemIndex: null,
    });
    let newArr = [...bottomState.bottom_menu_items];
    newArr.splice(key, 1);
    newArr.map((row, i) => {
      row.position = i;
    });

    commonRowFunction(newArr);
  };
  return (
    <>
      <div
        className={"accordionWrapper new-bottombar"
          // bottomState.enable_bottom_menu
          //   ? "accordionWrapper new-bottombar"
          //   : "accessaccordionhead disabled-bottombar"
        }
      >
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId="1234567">
            {(provided) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <div className="scroll-bottombar" ref={leftSideRef}>
                  {bottomState.bottom_menu_items &&
                    bottomState.bottom_menu_items.map((Row, indx) => {
                      return indx >= 0 ? (
                        <div 
                        className={(!bottomState.enable_bottom_menu && !Row.is_default)?"accessaccordionhead disabled-bottombar":""}
                         key={indx}>
                          <Draggable
                            draggableId={indx.toString()}
                            key={indx}
                            index={indx}
                          >
                            {(provided) => (
                              <div
                                className="draggable-container"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <div
                                  className={
                                    Row.status != 1
                                      ? `accordionItem bottombar-section-hidden`
                                      : `accordionItem `
                                  }
                                >
                                  <h2
                                    class="accordionItemHeading allflex"
                                    draggableId={indx.toString()}
                                    {...provided.dragHandleProps}
                                  >
                                    <div className="bb-icon">
                                      {Row.item_icon_newtype &&
                                      Row.item_icon_newtype === "flaticon" ? (
                                        <img
                                          className="img-fluid"
                                          src={
                                            Row.item_icon
                                              ? process.env
                                                  .REACT_APP_Image_Path +
                                                localStorage.getItem(
                                                  "user_id"
                                                ) +
                                                "/" +
                                                localStorage.getItem("Appid") +
                                                process.env
                                                  .REACT_APP_NEW_ICON_PATH +
                                                Row.item_icon
                                              : imagebackground
                                          }
                                        />
                                      ) : Row.item_icon &&
                                        Row.item_icon != "" ? (
                                        <img
                                          className="img-fluid"
                                          src={
                                            process.env.REACT_APP_Image_Path +
                                            (Row.item_icon_type === "light"
                                              ? process.env.REACT_APP_light_path
                                              : Row.item_icon_type === "bold"
                                              ? process.env.REACT_APP_bold_path
                                              : Row.item_icon_type === "regular"
                                              ? process.env
                                                  .REACT_APP_regular_path
                                              : Row.item_icon_type === "new"
                                              ? `${parseInt(
                                                  localStorage.getItem(
                                                    "user_id"
                                                  )
                                                )}/${parseInt(
                                                  localStorage.getItem("Appid")
                                                )}${
                                                  process.env
                                                    .REACT_APP_NEW_ICON_PATH
                                                }`
                                              : process.env
                                                  .REACT_APP_light_path) +
                                            Row.item_icon
                                          }
                                          alt="home-item"
                                          width="25"
                                        />
                                      ) : (
                                        <img
                                          className="img-fluid"
                                          src={imagebackground}
                                          alt="home-item"
                                          width="25"
                                        />
                                      )}
                                    </div>
                                    {Row.item_name}
                                    <div className="bottombar-default">
                                      {Row.is_default ? <p>Default</p> : ""}
                                    </div>
                                    {Row.is_default ||
                                    !bottomState.enable_bottom_menu ? (
                                      <></>
                                    ) : (
                                      <div
                                        className="bottombar-visiblity"
                                        onClick={() => handleRowStatus(indx)}
                                      >
                                        <EyeIcon />
                                      </div>
                                    )}
                                    <p
                                      onClick={(e) => {
                                        if (bottomState.enable_bottom_menu || Row.is_default) {
                                          handleIsRowOpen(e, indx);
                                        }
                                      }}
                                      className={"accordian-icon-div"}
                                    > <span className={`accordian-icon-div-img 
                                    ${bottomState.isRowOpen[indx] ? "accordian-icon-div-img-rotate-up" : "accordian-icon-div-img-rotate-down"}`}>
                                      <DropArrow strokeColor={!bottomState.enable_bottom_menu && !Row.is_default?"#BCC1CE":"#7782A1"}/>
                                    </span>
                                      
                                      {/* <img
                                        className="accordian-icon-div-img"
                                        src={
                                          bottomState.isRowOpen[indx] === true
                                            ? require("../../../assets/images/dashboard/cms/accordian-open.png")
                                                .default
                                            : require("../../../assets/images/dashboard/cms/accordian-icon.png")
                                                .default
                                        }
                                      /> */}
                                    </p>
                                  </h2>

                                  {bottomState.isRowOpen[indx] === true && (
                                    <div class="accordionItemContent">
                                      <h6 className="accordian-item-name">
                                        Item name
                                      </h6>
                                      <input
                                        className="accordian-item-name-input"
                                        type="text"
                                        onChange={(e) =>
                                          handleItemName(e, indx)
                                        }
                                        value={Row.item_name}
                                        maxLength={10}
                                      />

                                      <BottomIconField
                                        item={Row}
                                        parentSaveIconFn={parentSaveIconFn}
                                        indx={indx}
                                      />
                                      <BottomLinkField
                                        item={Row}
                                        itemListingData={
                                          bottomState.bottom_menu_items
                                        }
                                        itemListingArrName={"bottom_menu_items"}
                                        setAccordianState={setBottomState}
                                        is_chat_enabled={
                                          bottomState.is_chat_enabled
                                        }
                                        defaultOptionData={
                                          bottomState.defaultOptionData
                                        }
                                        taxonomyTypeMergeData={
                                          taxonomyTypeMergeData
                                        }
                                        bottomindx={indx}
                                        keys_data={bottomState.keys_data}
                                      />
                                      <div className="bottombar-footer">
                                        <div className="bottombar-gap d-md-flex justify-content-between agree-terms">
                                          <label className="container1 container1-red">
                                            <h4 className="d-block email-support-checkbox set-default-text">
                                              Set as default
                                              <Tooltip
                                                message={
                                                  "This item will appear as soon as your app is launched. Use this feature to provide quick access to your most important feature."
                                                }
                                              />
                                            </h4>
                                            <input
                                              type="checkbox"
                                              style={{
                                                left: "0",
                                                height: "inherit",
                                                width: "inherit",
                                                top: "0",
                                              }}
                                              checked={
                                                Row.is_default ? true : false
                                              }
                                              onChange={(e) =>
                                                defaultcheckboxhandle(e, indx)
                                              }
                                            />{" "}
                                            <span className="checkmark"></span>
                                          </label>
                                        </div>
                                        <div className="duplicate-delete">
                                          <span
                                            onClick={(e) => {
                                              copyRow(e, Row);
                                            }}
                                          >
                                            <img
                                              src={
                                                require("../../../assets/images/dashboard/cms/duplicate.png")
                                                  .default
                                              }
                                            />
                                          </span>
                                          <span
                                            onClick={(e) => {
                                              deleteRow(e, indx);
                                            }}
                                          >
                                            <img
                                              src={
                                                require("../../../assets/images/dashboard/cms/delete.png")
                                                  .default
                                              }
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </Draggable>
                        </div>
                      ) : (
                        <div key={indx}>
                          <div className="draggable-container"></div>
                        </div>
                      );
                    })}
                </div>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>

      <DefaulLandingScreen
        open={bottomState.openDefaultItemValidationPopUp}
        toggle={(e) => handleOpenItemValidationPopUp(e, "cancel")}
        title="Change default landing screen?"
        description="You are about to change the default app landing screen. This
                will update the screen that currently appears when the app
                launches. Are you sure you want to proceed?"
        cancelbutton="Cancel"
        submitbutton="Confirm"
        onSubmit={(e) => handleOpenItemValidationPopUp(e, "confirm")}
      />
    </>
  );
};

export default BottomItemAccordian;
