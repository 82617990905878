import React from "react";
import ReactSelect from "react-select";
import { getWorkspace, Showcredit,getAgencies } from "../../Api/Dashboard/DashboardApi";
import userdefaultImgOnError from "../../assets/images/dashboard/accountprofile.png";
import wpdefaultImgOnError from "../../assets/images/dashboard/workspace/workspace-default-img.png";
import "./Billings.css";
import {
  Subscriptionlist,
} from "../../Api/Dashboard/DashboardApi";
import { Link } from "react-router-dom";
export default class Billingheader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      creditdata: "",
      menuData: [],
      userInfo: {},
      invitedWorkspaceData: [],
      user_role: "",
      mergeArray: [],
      mergeAgencies: [],
      typedata:[],
      seachapp_id:'',
      selectedbillingtype:1,
      selectedbillingname:'Pay-per-app',

    };
  }
  componentDidMount() 
  {
    Showcredit(this);
    // getAgencies(this , () => { getWorkspace(this, "", "", "all", "billingDropdown",this.state.mergeAgencies)});
  }

  

  render() {
    
    return (
      <>
        <div className="billing_social_pannel">
          <div className="billings_bill-pay-version">
            <ul>
              <li>
                <Link
                  to="/subscriptions"
                  className={
                    window.location.pathname === "/subscriptions"
                      ? "active"
                      : ""
                  }
                >
                  Subscriptions
                </Link>
              </li>
              <li>
                <Link
                  to="/invoices"
                  className={
                    window.location.pathname === "/invoices" ? "active" : ""
                  }
                >
                  Invoices
                </Link>
              </li>
              <li>
                <Link
                  to="/billing-details"
                  className={
                    window.location.pathname === "/billing-details"
                      ? "active"
                      : ""
                  }
                >
                  Billing <span className="d-none d-md-inline">details</span>
                </Link>
              </li>
              {parseInt(localStorage.getItem("customer_billing_type")) != 3 ? (
                <li>
                  <Link
                    to="/payment-method"
                    className={
                      window.location.pathname.includes("/payment-method")
                        ? "active"
                        : ""
                    }
                  >
                    Payment <span className="d-none d-md-inline">method</span>
                  </Link>
                </li>
              ) : null}
              {/* {parseInt(localStorage.getItem("customer_billing_type")) != 3 ? ( */}
                <>
                  {localStorage.getItem("workspaceId") !== "" &&
                  localStorage.getItem("workspaceId") !== undefined &&
                  localStorage.getItem("workspaceId") !== "undefined" &&
                  localStorage.getItem("workspaceId") !== null ? (
                    <></>
                  ) : (
                    <li>             
                      <Link
                        to="/deals"
                        className={
                          window.location.pathname === "/deals" ||
                          window.location.pathname === "/redeem"
                            ? "active"
                            : ""
                        }
                      >
                        Deals
                      </Link>
                    </li>
                  )}
                </>
              {/* ) : null} */}
            </ul>
          </div>
        </div>
      </>
    );
  }
}
