import React from "react";

const PagesPreview = ({ eachBlock }) => {
  
  const setAlpha = (color) => {
    const current_color = color;
    
    // Getting alpha value from color
    let alpha = parseFloat(current_color.split(',')[3]);
    isNaN(alpha)?alpha=1: alpha=alpha; 

    // Resetting alpha value from color by divide 2
    const match = /rgba?\((\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(,\s*\d+[\.\d+]*)*\)/g.exec(current_color);


    if(match){

      return "rgba(" + [match[1],match[2],match[3],alpha/2].join(',') +")";
    }else{
      return  current_color;
    }
    }
 
 


  return (
    eachBlock.value.selection_array.length > 0 && (
      <div
        className="homescreen-pages-preview"
        style={{ background: eachBlock.style.section_background_color }}
      >
        {eachBlock.style.show_heading === 1 && (
          <div className="category-head" style={{ paddingInline: "10px" }}>
            <h2
              style={{
                color: eachBlock.style.heading_color,
                fontSize: "12px",
                fontFamily: "Poppins-Semibold",
              }}
            >
              {eachBlock.value.label === "" ? "Pages" : eachBlock.value.label}
            </h2>
            <div
              className="seemoresection"
              style={{
                background: eachBlock.style.heading_icon_background_color,
              }}
            >
              <svg
                id="Group_26467"
                data-name="Group 26467"
                width="16"
                height="16"
                viewBox="0 0 16 16"
              >
                {/* <circle
                id="Ellipse_6953"
                data-name="Ellipse 6953"
                cx="8"
                cy="8"
                r="8"
                // style={{
                //   background: blogHeadingIconBgColor,
                // }}
                fill={blogHeadingIconBgColor}
              /> */}
                <path
                  id="Path_81302"
                  data-name="Path 81302"
                  d="M0,0,2.986,2.987,5.973,0"
                  transform="translate(6.499 11.473) rotate(-90)"
                  fill="none"
                  stroke={eachBlock.style.heading_icon_color}
                  stroke-width="1.5"
                />
              </svg>
            </div>
          </div>
        )}
        {Array.from({
          length:
            (eachBlock.value.selection_array.length > 5 ||  eachBlock.value.selection_array[0]==="all")
              ? 5
              : eachBlock.value.selection_array.length,
        }).map((it, index) => {
          return (
            <h4 
              style={{
                color: eachBlock.style.text_color,
                borderBottom: `1px solid ${setAlpha(eachBlock.style.icon_color)}`,
               
              }}
            >
              
             
              Page {index + 1}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="5.591"
                height="9.061"
                viewBox="0 0 5.591 9.061"
              >
                <path
                  id="Path_81999"
                  data-name="Path 81999"
                  d="M990.5,784l4,4-4,4"
                  transform="translate(-989.97 -783.47)"
                  
                  fill="none"
                  stroke={eachBlock.style.icon_color}
                  stroke-width="1.5"
                  opacity="0.5"
                />
              </svg>
            </h4>
          );
        })}

        {(eachBlock.value.selection_array.length > 5 ||eachBlock.value.selection_array[0]==="all"  ) && (
          <h5
            style={{
              color: eachBlock.style.text_color,
              borderBottom: `1px solid ${setAlpha(eachBlock.style.icon_color)}`,
            }}
          >
            See All pages{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="5.591"
              height="9.061"
              viewBox="0 0 5.591 9.061"
            >
              <path
                id="Path_81999"
                data-name="Path 81999"
                d="M990.5,784l4,4-4,4"
                transform="translate(-989.97 -783.47)"
                fill="none"
                stroke={eachBlock.style.icon_color}
                stroke-width="1.5"
                opacity="0.5"
              />
            </svg>
          </h5>
        )}
      </div>
    )
  );
};

export default PagesPreview;
