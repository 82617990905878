import React from "react";
import userdefaultImgOnError from "../../assets/images/dashboard/accountprofile.png";

const TeamCard = ({ ownerData, teamData }) => {
  return (
    <div className="app-team">
      <h3>Team</h3>

      <div className="app-teamlist">
        <>
          <div className="teammember">
            {!ownerData.image ? (
             
              <img
              src={userdefaultImgOnError}
              className="app-dashboard-img"
              alt="appicon"
            />
            ) : ownerData.image.indexOf("https") === -1 ? (
             
              <img
                    src={process.env.REACT_APP_Image_Path +
                      ownerData.user_id +
                      process.env.REACT_APP_Profile +
                      ownerData.image}
                    className="app-dashboard-img"
                    onError={({ currentTarget }) => {
                      currentTarget.src = userdefaultImgOnError}}
                    alt="appicon"
                  />
            ) : (
              
              <img
                    src={ownerData.image}
                    className="app-dashboard-img"
                    alt="appicon"
                  />
            )}
            <div className="online"></div>
          </div>

          {(teamData &&
            teamData.length) ?
            teamData.map((res) => {
              return (
                <div className="teammember" key={res.id}>
                  {!res.image ? (
                    <img
                    src={userdefaultImgOnError}
                    className="app-dashboard-img"
                    alt="appicon"
                  />
                  ) : res.image.indexOf("https") === -1 ? (
                   
                    <img
                    src={process.env.REACT_APP_Image_Path +
                             res.user_id +
                            process.env.REACT_APP_Profile +
                            res.image}
                    className="app-dashboard-img"
                    title={`${res.profile_name}`}
                    onError={({ currentTarget }) => {
                      currentTarget.src = userdefaultImgOnError}}
                    alt="appicon"
                  />
                  ) : (
                  
                    <img
                    src={res.image}
                    className="app-dashboard-img"
                    title={`${res.profile_name}`}
                    onError={({ currentTarget }) => {
                      currentTarget.src = userdefaultImgOnError}}
                    alt="appicon"
                  />
                  )}
                  {res.status === 1 ? (
                    <div className="online"></div>
                  ) : (
                    <div className="offonline"></div>
                  )}
                </div>
              );
            }): <></>}
        </>
      </div>
    </div>
  );
};

export default TeamCard;
