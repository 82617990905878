import React, { Component } from "react";
import imagebackground from "./../../assets/images/dashboard/cms/no-post-img.png";
import imagebackground2 from "./../../assets/images/dashboard/cms/no-post-img.png";
import imagebackground3 from "./../../assets/images/dashboard/cms/uploadbackground.png";
import userdefaultImgOnError from "../../assets/images/dashboard/accountprofile.png";
import moment from "moment";
import { Carousel } from "react-responsive-carousel";
import { Markup } from "interweave";
import ReactPlayer from "react-player";

export class CustomAppPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // activeSlide: 0,
    };
  }

  calculateHeightFinalHeight = (width,height) => {

    let finalHeight=10;
    let mobWidth=this.props.value.state.fitBanner?302:286;
    finalHeight = ((mobWidth*height)/width );
    return finalHeight;

  };

  returnSelectedModuleImg = (module_name, ad_position) => {
    if (
      module_name === "post_details" ||
      module_name === "blog_details"
     ) {
      return(
        <div style={{
          height:"643px"
        }}>
          <div className="customad-bottomad">
          {ad_position === "bottom" && (
            <div className="third-ad-textdiv ">
              <div
                className={`${this.props.value.state.adType === "banner"
                                ? "third-ad-text third-ad-text-withbottom-fixed"
                                : "fourth-ad-text fourth-ad-text-withoutabsolute"
                              } ${this.props.value.state.fitBanner === true
                                ? ""
                                : "ad-with-padding"
                              }`}
                style={{
                  // top:"unset",
                  // bottom:  "20px" ,
                  // marginBottom: "10px",
                  height:
                  this.props.value.state.adType === "custom"?
                  this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height):
                  "",
                }}
              >
                {
                  this.props.value.state.adType === "custom"?
                
                 <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                         :
                   <p>Google Admob banner</p>
                }
                
              </div>
            </div>
          )}
        </div>
        <div
        className=""
      >
       
        <div>
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/postpagehead.png")
                .default
            }
            className=""
            alt=""
          />
        </div>
        <div>
        {ad_position === "top" && (
            <div className="third-ad-textdiv " style={{backgroundColor: "white"}}>
              <div
              className={`${this.props.value.state.adType === "banner"
              ? "third-ad-text third-ad-text-withoutabsolute"
              : "fourth-ad-text fourth-ad-text-withoutabsolute"
            } ${this.props.value.state.fitBanner === true
              ? ""
              : "ad-with-padding"
            }`}
                style={{
                  top:this.props.value.state.fitBanner === true? "44px": "54px",
                     
                  bottom: "unset",
                  marginTop:
                    this.props.value.state.fitBanner === true ? "0px" : "",
                    height:
                              this.props.value.state.adType === "custom"?
                              this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height):
                              "",
                }}
              >
                 {
                  this.props.value.state.adType === "custom"?
                
                 <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                         :
                   <p>Google Admob banner</p>
                }
           
              </div>
            </div>
          )}
        </div>
        <div className="customad-previewbody" style={{backgroundColor: "white"}}>
          {
            ad_position==="top"?
          
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/postpageadtop.png")
                .default
            }
            className=""
            alt=""
          />:
          <img
          src={
            require("../../assets/images/dashboard/advertisement/new-image/postpageadbottom.png")
              .default
          }
          className=""
          alt=""
        />
    }
        </div>
       
        </div>
        </div> )
    } else if (
      module_name === "page_details"
    ) {
      return(
        <div style={{
          height:"643px"
        }}>
          <div className="customad-bottomad">
          {ad_position === "bottom" && (
            <div className="third-ad-textdiv " style={{backgroundColor: "white"}}>
              <div
                className={`${this.props.value.state.adType === "banner"
                ? "third-ad-text third-ad-text-withbottom-fixed"
                : "fourth-ad-text fourth-ad-text-withoutabsolute"
              } ${this.props.value.state.fitBanner === true
                ? ""
                : "ad-with-padding"
              }`}
                style={{
                  // top:"unset",
                  // bottom:  "20px" ,
                  // marginBottom: "10px",
                  height:
                  this.props.value.state.adType === "custom"?
                  this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height):
                  "",
                }}
              >
                 {
                  this.props.value.state.adType === "custom"?
                
                 <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                         :
                   <p>Google Admob banner</p>
                }
                
              </div>
            </div>
          )}
        </div>
        <div
        className=""

      >
       
        <div>
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/postdetailshead.png")
                .default
            }
            className=""
            alt=""
          />
        </div>
        <div>
        {ad_position === "top" && (
            <div className="third-ad-textdiv " style={{backgroundColor: "white"}}>
              <div
               className={`${this.props.value.state.adType === "banner"
               ? "third-ad-text third-ad-text-withoutabsolute"
               : "fourth-ad-text fourth-ad-text-withoutabsolute"
             } ${this.props.value.state.fitBanner === true
               ? ""
               : "ad-with-padding"
             }`}
                style={{
                  top:this.props.value.state.fitBanner === true? "44px": "54px",
                     
                  bottom: "unset",
                  marginTop:
                    this.props.value.state.fitBanner === true ? "0px" : "",
                    height:
                    this.props.value.state.adType === "custom"?
                    this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height):
                    "",
                }}
              >
                  {
                  this.props.value.state.adType === "custom"?
                
                 <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                         :
                   <p>Google Admob banner</p>
                }
           
              </div>
            </div>
          )}
        </div>
        <div className="customad-previewbody" style={{backgroundColor: "white"}}>
          {
            ad_position==="top"?
          
          <img
            src={
              require("../../assets/images/dashboard/advertisement/new-image/postdetailsbody.png")
                .default
            }
            className=""
            alt=""
          />:
          <img
          src={
            require("../../assets/images/dashboard/advertisement/new-image/pagebottombody.png")
              .default
          }
          className=""
          alt=""
        />
    }
        </div>
       
        </div>
        </div> )
    }
  };

  handleDash = (level) => {
    switch (level) {
      case 0:
        return <span></span>;
      case 1:
        return <span> - </span>;
      case 2:
        return <span> - - </span>;
      case 3:
        return <span> - - - </span>;
      case 4:
        return <span> - - - - </span>;
      case 5:
        return <span> - - - - - </span>;
      case 6:
        return <span> - - - - - - </span>;
      case 1:
        return <span> - - - - - - - </span>;
      case 7:
        return <span> - - - - - - - - </span>;
      case 8:
        return <span> - - - - - - - - - </span>;
      case 9:
        return <span> - - - - - - - - - - </span>;
    }
  };

  calculateHeight = (ratio, width) => {
    let height = (width * 3) / 4;
    switch (ratio) {
      case "4:3":
        height = (width * 3) / 4;
        break;
      case "3:4":
        height = (width * 4) / 3;
        break;
      case "1:1":
        height = (width * 1) / 1;
        break;
      case "16:9":
        height = (width * 9) / 16;
        break;
    }
    return height;
  };



  render() {
    return (
      <Carousel
        selectedItem={this.props.value.state.activeSlide}
        // onChange={(e) => this.props.value.handlesliderChange(e)}
        showArrows={false}
        swipeable={true}
        showThumbs={false}
      >
        {this.props.value.state.locationArr &&
          this.props.value.state.locationArr.map((res) => {
            if (res.module_name === "post_list") {
              return (
                <div className="ad-slide-1">
                  {this.props.value.state.customPosts.length > 0 && (
                    <div className="ad-post-list">
                      <div className="posts-preview-list-box">
                      <div className="post-listing-head">
                                    <div className="post-listing-head-left">
                                      <img
                                        src={
                                          require("../../assets/images/dashboard/header-back-arrow.svg")
                                            .default
                                        }
                                      />

                                      <h3 className="post-listing-heading">
                                        Post list
                                      </h3>
                                    </div>

                                    <div className="post-listing-head-right">
                                      
                                        <img
                                          src={
                                            require("../../assets/images/dashboard/search-icon.svg")
                                              .default
                                          }
                                        />

                                        <img
                                          className="post-list-bookmark"
                                          src={
                                            require("../../assets/images/dashboard/bookmark-icon.svg")
                                              .default
                                          }
                                        />
                                    </div>
                                  </div>
                        {/* {
                            previewListingView==="grid" ? */}
                            <div className="banner-scroll-div post-listing-top" ref={this.props.value.postListTopRef}>
                            {
                              res.ad_position==="top" &&
                            
                        <div className="third-ad-textdiv ">
                          <div
                            className={`${this.props.value.state.adType === "banner"
                                ? "third-ad-text third-ad-text-withoutabsolute"
                                : "fourth-ad-text fourth-ad-text-withoutabsolute"
                              } ${this.props.value.state.fitBanner === true
                                ? ""
                                : "ad-with-padding"
                              }`}
                            style={{
                              top:
                                res.ad_position === "top"
                                  ? this.props.value.state.fitBanner === true
                                    ? "44px"
                                    : "54px"
                                  : "unset",
                              bottom:
                                res.ad_position === "bottom" ? "20px" : "unset",
                              marginTop:this.props.value.state.fitBanner === true?"0px":"",
                              height:
                              this.props.value.state.adType === "custom"?
                              this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height):
                              "",
                              // height:this.props.value.state.adType === "custom"?"88px":"44px"
                            }}
                          >
                            {this.props.value.state.adType === "custom" ? (
                              // <p>Custom ad</p>
                              <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                 
                            ) : (
                              <p>Google Admob banner</p>
                            )}
                          </div>
                        </div> }
                        </div>

                        
                        <div className="post-listing-filters" style={{marginTop: "0px"}}>
                                    <div className="post-listing-filters-left">
                                      
                                    </div>
                                    <div className="post-listing-filters-right">
                                      
                                        <img
                                          style={{
                                            width: "16px",
                                            height: "16px",
                                          }}
                                          src={
                                            require("../../assets/images/dashboard/list-icon.svg")
                                              .default
                                          }
                                        />
                                      
                                    </div>
                                  </div>


                        <div
                          className="post-listing-style-one"
                          // style={{
                          //   marginTop: res.ad_position === "top" ? "70px" : "",
                          // }}
                        >
                          {this.props.value.state.customPosts &&
                            this.props.value.state.customPosts.length > 0 ? (
                            this.props.value.state.customPosts
                              // .filter((row, ind) =>
                              //   row.title
                              //     .toLowerCase()
                              //     .includes(searchVal.toLowerCase())
                              // )
                              .map((eachPost, indx) => {
                                return (
                                  (eachPost.show_in_results === 1 ||
                                    eachPost.show_in_results === undefined) && (
                                    <div className="post-list-type-one">
                                      <div
                                        className="post-type-one-thumbnail"
                                        style={{
                                          position: "relative",
                                          backgroundImage:
                                            "url(" +
                                            (eachPost.source_file === null ||
                                              eachPost.source_file ===
                                              undefined ||
                                              eachPost.source_file === ""
                                              ? imagebackground
                                              : // : eachPost.source_file ===
                                              //   "floating-unicorn.jpg"
                                              // ? process.env.REACT_APP_Image_Path +
                                              //   process.env
                                              //     .REACT_APP_DEFAULT_CMS_IMG +
                                              //   eachPost.source_file
                                              eachPost.source_file.indexOf(
                                                "https"
                                              ) > -1
                                                ? eachPost.source_file
                                                : process.env
                                                  .REACT_APP_Image_Path +
                                                localStorage.getItem(
                                                  "user_id"
                                                ) +
                                                "/" +
                                                localStorage.getItem("Appid") +
                                                process.env
                                                  .REACT_APP_CMS_Media +
                                                eachPost.source_file) +
                                            ")",
                                        }}
                                      >
                                        
                                      </div>

                                      
                                                  <div className="post-list-author">
                                                    <div className="post-author-grid-lt">
                                                      {eachPost.author_info ? (
                                                        !eachPost.author_info
                                                          ?.profile_image ? (
                                                          <img
                                                            src={
                                                              userdefaultImgOnError
                                                            }
                                                            className="img-fluid"
                                                            alt="profile logo"
                                                          />
                                                        ) : (
                                                          <>
                                                            {eachPost.author_info?.profile_image?.indexOf(
                                                              "https"
                                                            ) === -1 ? (
                                                              <img
                                                                className="header-profil-img"
                                                                src={
                                                                  process.env
                                                                    .REACT_APP_Image_Path +
                                                                  eachPost
                                                                    .author_info
                                                                    .id +
                                                                  "/" +
                                                                  "profile" +
                                                                  "/" +
                                                                  eachPost
                                                                    .author_info
                                                                    ?.profile_image
                                                                }
                                                                onError={({
                                                                  currentTarget,
                                                                }) => {
                                                                  currentTarget.onerror =
                                                                    null;
                                                                  currentTarget.src =
                                                                    userdefaultImgOnError;
                                                                }}
                                                              />
                                                            ) : (
                                                              <img
                                                                className="header-profil-img"
                                                                src={
                                                                  eachPost
                                                                    .author_info
                                                                    ?.profile_image
                                                                }
                                                                onError={({
                                                                  currentTarget,
                                                                }) => {
                                                                  currentTarget.onerror =
                                                                    null;
                                                                  currentTarget.src =
                                                                    userdefaultImgOnError;
                                                                }}
                                                              />
                                                            )}
                                                          </>
                                                        )
                                                      ) : (
                                                        <img
                                                          src={
                                                            userdefaultImgOnError
                                                          }
                                                          className="img-fluid"
                                                          alt="profile logo"
                                                        />
                                                      )}
                                                    </div>

                                                    <div>
                                                      <h5 className="post-one-author">
                                                        {" "}
                                                        {eachPost.author_info !==
                                                          undefined &&
                                                        eachPost.author_info !==
                                                          null
                                                          ? eachPost.author_info
                                                              .name
                                                          : eachPost.owner_user
                                                          ? eachPost.owner_user
                                                              .name
                                                          : localStorage.getItem(
                                                              "user_name"
                                                            )}
                                                      </h5>
                                                    </div>
                                                  </div>
                                                

                                      {eachPost.title &&
                                        eachPost.title.length >= 50 ? (
                                          <h4
                                          className="post-one-title"
                                          style={{
                                            fontSize: "9px",
                                          }}
                                        >
                                          {eachPost.title.substring(
                                            0,
                                            50
                                          ) + "..."}
                                        </h4>
                                      ) : (
                                        <h4
                                          className="post-one-title"
                                          style={{
                                            fontSize: "9px",
                                          }}
                                        >
                                          {eachPost.title}
                                        </h4>
                                      )}

                                        { eachPost.excerpt && (
                                                    <p
                                                      className="post-one-excerpt"
                                                      style={{
                                                        marginBottom: "7px",
                                                      }}
                                                    >
                                                      {eachPost.excerpt
                                                        .length >= 70
                                                        ? eachPost.excerpt.substring(
                                                            0,
                                                            70
                                                          ) + "..."
                                                        : eachPost.excerpt}
                                                    </p>
                                                  )}

                                                
                                                  <h6 className="post-one-timestamp">
                                                    {" "}
                                                    {moment(
                                                      eachPost.created_at
                                                    ).format(
                                                      "D MMMM YYYY, HH:mm"
                                                    )}
                                                    {eachPost.is_featured && (
                                                      <svg
                                                        style={{
                                                          marginLeft: "4px",
                                                          position: "relative",
                                                          top: "-2px",
                                                        }}
                                                        id="iconfinder_star_216411_2_"
                                                        data-name="iconfinder_star_216411 (2)"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="8.051"
                                                        height="7.666"
                                                        viewBox="0 0 8.051 7.666"
                                                      >
                                                        <g
                                                          id="Group_42888"
                                                          data-name="Group 42888"
                                                          transform="translate(0 0)"
                                                        >
                                                          <path
                                                            id="Path_119118"
                                                            data-name="Path 119118"
                                                            d="M6.4,6.5l-2.539.281a.243.243,0,0,0-.2.165.238.238,0,0,0,.067.251c.755.688,1.889,1.717,1.889,1.717s-.311,1.5-.518,2.5a.242.242,0,0,0,.094.243.24.24,0,0,0,.259.013c.889-.5,2.22-1.263,2.22-1.263l2.219,1.263a.24.24,0,0,0,.355-.257c-.206-1-.516-2.5-.516-2.5L11.619,7.2a.24.24,0,0,0-.135-.416L8.945,6.5,7.893,4.175a.244.244,0,0,0-.22-.141.24.24,0,0,0-.218.141Z"
                                                            transform="translate(-3.649 -4.034)"
                                                            fill="#6f6f6f"
                                                          />
                                                        </g>
                                                      </svg>
                                                    )}
                                                  </h6>
                                                
                                    </div>
                                  )
                                );
                              })
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="banner-scroll-div bottomadd-div post-listing-bottom" ref={this.props.value.postListBottomRef}>
                        {
                              res.ad_position==="bottom" &&
                        <div className="third-ad-textdiv ">
                          <div
                            className={`${this.props.value.state.adType === "banner"
                                ? "third-ad-text third-ad-text-withbottom-fixed"
                                : "fourth-ad-text fourth-ad-text-withoutabsolute"
                              } ${this.props.value.state.fitBanner === true
                                ? ""
                                : "ad-with-padding"
                              }`}
                            style={{
                              top:
                                res.ad_position === "top"
                                  ? this.props.value.state.fitBanner === true
                                    ? "44px"
                                    : "54px"
                                  : "unset",
                              bottom:
                                res.ad_position === "bottom" ? "0px" : "unset",
                              marginTop:this.props.value.state.fitBanner === true?"0px":"",
                               height:
                              this.props.value.state.adType === "custom"?
                              this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height):
                              "",
                              // height:this.props.value.state.adType === "custom"?"88px":"44px"
                            }}
                          >
                            {this.props.value.state.adType === "custom" ? (
                            // <p>Custom ad</p>
                            <iframe 
                            srcDoc={
                              this.props.value.state.deviceTypeForPreview === "ios" ?
                              this.props.value.state.iosAdScript :
                              this.props.value.state.androidAdScript
                            } 
                           style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                           />
                            ) : (
                              <p>Google Admob banner</p>
                            )}
                          </div>
                        </div> }
                        </div>
                        {/* :
                          <div className="post-listing-style-two">
                          {this.props.value.state.customPosts && this.props.value.state.customPosts.length > 0 ? (
                            this.props.value.state.customPosts
                              .filter((row, ind) =>
                                row.title
                                  .toLowerCase()
                                  .includes(searchVal.toLowerCase())
                              )
                              .map((eachPost, indx) => {
                                
                                return(  (eachPost.show_in_results===1 ||eachPost.show_in_results===undefined ) &&
                                  <div className="post-list-type-two">

                                  <div className="post-type-two-data">
                                    {
                                      eachPost.title && eachPost.title.length>=70 ?
                                    
                                    <h3>{eachPost.title.substring(0, 70) + '...' }</h3>
                                    :
                                    <h3>{eachPost.title}</h3>
                              }
                                    <h6>    {moment(eachPost.created_at).format(
                                            "D MMMM YYYY, HH:mm"
                                          )}</h6>
                                  </div>
                                  <div
                                          className="post-type-two-thumbnail"
                                          style={{
                                            backgroundImage:
                                              "url(" +
                                              (eachPost.source_file === null ||
                                              eachPost.source_file === undefined ||
                                              eachPost.source_file === ""
                                                ? imagebackground
                                                : // : eachPost.source_file ===
                                                  //   "floating-unicorn.jpg"
                                                  // ? process.env.REACT_APP_Image_Path +
                                                  //   process.env
                                                  //     .REACT_APP_DEFAULT_CMS_IMG +
                                                  //   eachPost.source_file
                                                  eachPost.source_file.indexOf("https") > -1 ? 
                                                  eachPost.source_file
                                                  :
                                                  process.env.REACT_APP_Image_Path +
                                                  localStorage.getItem("user_id") +
                                                  "/" +
                                                  localStorage.getItem("Appid") +
                                                  process.env.REACT_APP_CMS_Media +
                                                  eachPost.source_file) +
                                              ")",
                                          }}
                                        ></div>
                            
          
                                  </div>
                                )}))
                                :
                                <></>
                              }
                            

                              

                          </div>
                          }  */}
                      </div>

                      {/* <div className="change-post-view">
                      <svg 
                      className={previewListingView==="list"?"active":""} 
                      onClick={(e)=>handleListingView(e,"list")} 
                      id="Group_38251" data-name="Group 38251" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="Rectangle_4591" data-name="Rectangle 4591" fill="#fff" stroke="" stroke-width="2">
                          <rect width="9" height="9" rx="1" stroke="none"/>
                          <rect x="1" y="1" width="7" height="7" fill="none"/>
                        </g>
                        <g id="Rectangle_4596" data-name="Rectangle 4596" transform="translate(0 11)" fill="#fff" stroke="" stroke-width="2">
                          <rect width="9" height="9" rx="1" stroke="none"/>
                          <rect x="1" y="1" width="7" height="7" fill="none"/>
                        </g>
                        <path id="Path_9130" data-name="Path 9130" d="M954.017,177.785h9" transform="translate(-943.016 -175.434)" fill="#fff" stroke="" stroke-width="2"/>
                        <path id="Path_9133" data-name="Path 9133" d="M954.017,177.785h9" transform="translate(-943.016 -163.775)" fill="#fff" stroke="" stroke-width="2"/>
                        <path id="Path_9131" data-name="Path 9131" d="M954.017,177.785h6" transform="translate(-943.016 -171.364)" fill="#fff" stroke="" stroke-width="2"/>
                        <path id="Path_9132" data-name="Path 9132" d="M954.017,177.785h6" transform="translate(-943.016 -159.706)" fill="#fff" stroke="" stroke-width="2"/>
                      </svg>

                      <svg 
                      className={previewListingView==="grid"?"active":""} 
                      onClick={(e)=>handleListingView(e,"grid")} 
                      xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                        <g id="Group_38250" data-name="Group 38250" transform="translate(0.222)">
                          <g id="Rectangle_4592" data-name="Rectangle 4592" transform="translate(-0.222)" fill="#fff" stroke="" stroke-width="2">
                            <rect width="9" height="9" rx="1" stroke="none"/>
                            <rect x="1" y="1" width="7" height="7" fill="none"/>
                          </g>
                          <g id="Rectangle_4595" data-name="Rectangle 4595" transform="translate(-0.222 11)" fill="#fff" stroke="" stroke-width="2">
                            <rect width="9" height="9" rx="1" stroke="none"/>
                            <rect x="1" y="1" width="7" height="7" fill="none"/>
                          </g>
                          <g id="Rectangle_4593" data-name="Rectangle 4593" transform="translate(10.778)" fill="#fff" stroke="" stroke-width="2">
                            <rect width="9" height="9" rx="1" stroke="none"/>
                            <rect x="1" y="1" width="7" height="7" fill="none"/>
                          </g>
                          <g id="Rectangle_4594" data-name="Rectangle 4594" transform="translate(10.778 11)" fill="#fff" stroke="" stroke-width="2">
                            <rect width="9" height="9" rx="1" stroke="none"/>
                            <rect x="1" y="1" width="7" height="7" fill="none"/>
                          </g>
                        </g>
                      </svg>
                    </div> */}
                    </div>
                  )}
                  {/* <div className="ad-mob-preview-frame">
              {this.props.value.state.adType === "app_open" && (
                <div className="first-ad">
                  <div className="first-ad-top">
                    <div className="first-ad-top-div">
                      <div className="first-ad-top-div-img"></div>
                      <p>Continue to app</p>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="5.273"
                      height="8.849"
                      viewBox="0 0 5.273 8.849"
                    >
                      <path
                        id="Path_108856"
                        data-name="Path 108856"
                        d="M5767.731-7085.358l4,4-4,4"
                        transform="translate(-5767.307 7085.783)"
                        fill="none"
                        stroke="#b0b0b0"
                        stroke-width="1.2"
                      />
                    </svg>
                  </div>
                  <div className="first-ad-last">
                    <p className="ad-first-open-title">
                      Advertisement
                    </p>
                    <div className="ad-first-open-text-div">
                      <p className="ad-first-tag">Ad</p>
                      <p className="ad-first-open-text">
                        Google app open ad
                      </p>
                    </div>
                  </div>
                </div>
              )}

              {this.props.value.state.adType === "interstitial" && (
                <div className="second-ad">
                  <div className="second-ad-div-main">
                    <div className="second-ad-div-cross">
                      <img
                        src={
                          require("../../assets/images/dashboard/cross.png")
                            .default
                        }
                      />
                    </div>
                    <div className="second-ad-div-text">
                      Google interstitial ad
                    </div>
                    <div className="second-div-ad">Ad</div>
                  </div>
                </div>
              )}

              {this.props.value.state.adType === "banner" && (
                <div className="third-ad-div">
                  <div className="third-ad-textdiv">
                    <div className="third-ad-text ad-with-padding">
                      <p>Google Admob banner</p>
                    </div>
                  </div>
                </div>
              )}

              {this.props.value.state.adType === "custom" && (
                <div className="fourth-ad-div ">
                  <div className="fourth-ad-textdiv">
                    <div className="fourth-ad-text ad-with-padding">
                      // <p>Custom ad</p>
                              <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                    </div>
                  </div>
                </div>
              )}
            </div> */}
                </div>
              );
            } else if (res.module_name === "page_list") {
              return (
                <div className="ad-slide-2">
                  <div className="pages-preview-list-box">
                    <div className="page-listing-head">
                      <h3
                        style={{
                          display: "flex",
                          textAlign: "left",
                        }}
                      >
                        <img
                          style={{
                            width: "14px",
                            height: "11px",
                          }}
                          src={
                            require("../../assets/images/dashboard/cms/hamburger.png")
                              .default
                          }
                        />{" "}
                        Pages
                      </h3>
                    </div>
                    <div className="banner-scroll-div page-list-top" ref={this.props.value.pageListTopRef}>
                    {
                              res.ad_position==="top" &&
                    <div className="third-ad-textdiv">
                      <div
                        className={`${this.props.value.state.adType === "banner"
                            ? "third-ad-text third-ad-text-withoutabsolute"
                            : "fourth-ad-text fourth-ad-text-withoutabsolute"
                          } ${this.props.value.state.fitBanner === true
                            ? ""
                            : "ad-with-padding"
                          }`}
                        style={{
                          top:
                            res.ad_position === "top"
                              ? this.props.value.state.fitBanner === true
                                ? "44px"
                                : "54px"
                              : "unset",
                          bottom:
                            res.ad_position === "bottom" ? "20px" : "unset",
                          marginTop:this.props.value.state.fitBanner === true?"0px":"",
                           height:
                              this.props.value.state.adType === "custom"?
                              this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height):
                              "",
                          // height:this.props.value.state.adType === "custom"?"88px":"44px"
                        }}
                      >
                        {this.props.value.state.adType === "custom" ? (
                          // <p>Custom ad</p>
                          <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                        ) : (
                          <p>Google Admob banner</p>
                        )}
                      </div>
                    </div> }
                    </div>
                    <div
                      className="page-listing-body"
                      // style={{
                      //   marginTop: res.ad_position === "top" ? "40px" : "",
                      // }}
                    >
                      {this.props.value.state.customPages &&
                        this.props.value.state.customPages.map((eachPage, indx) => {
                          return (
                            eachPage.deleted_at === null &&
                            (eachPage.show_in_results === 1 ||
                              eachPage.show_in_results === undefined) && (
                              <p
                                style={{
                                  textAlign: "left",
                                }}
                              >
                                {eachPage.level > 0
                                  ? this.handleDash(eachPage.level)
                                  : ""}
                                {eachPage.title}
                              </p>
                            )
                          );
                        })}
                         
                    </div>
                    <div className="banner-scroll-div page-list-bottom" ref={this.props.value.pageListBottomRef}>
                    {
                              res.ad_position==="bottom" &&
                    <div className="third-ad-textdiv">
                      <div
                        className={`${this.props.value.state.adType === "banner"
                            ? "third-ad-text third-ad-text-withbottom-fixed"
                            : "fourth-ad-text fourth-ad-text-withoutabsolute"
                          } ${this.props.value.state.fitBanner === true
                            ? ""
                            : "ad-with-padding"
                          }`}
                        style={{
                          top:
                            res.ad_position === "top"
                              ? this.props.value.state.fitBanner === true
                                ? "44px"
                                : "54px"
                              : "unset",
                          bottom:
                            res.ad_position === "bottom" ? "0px" : "unset",
                          marginTop:this.props.value.state.fitBanner === true?"0px":"",
                           height:
                              this.props.value.state.adType === "custom"?
                              this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height):
                              "",
                          // height:this.props.value.state.adType === "custom"?"88px":"44px"
                        }}
                      >
                        {this.props.value.state.adType === "custom" ? (
                          // <p>Custom ad</p>
                          <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                          style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                        ) : (
                          <p>Google Admob banner</p>
                        )}
                      </div>
                    </div> }
                    </div>
                  </div>
                </div>
              );
            } else if (res.module_name === "post_details") {
              return (
                <div className="ad-slide-4">
                 
                  {res.item === "all" || res.item === "" ? (
                    <div
                      className={``}
                      style={{
                        height: "649px",
                      }}
                    >
                      <div className="">
                        {/* <div className="third-ad-textdiv">
                          <div
                            className={`${this.props.value.state.adType === "banner"
                                ? "third-ad-text"
                                : "fourth-ad-text"
                              } ${this.props.value.state.fitBanner === true
                                ? ""
                                : "ad-with-padding"
                              }`}
                            style={{
                              top:
                                res.ad_position === "top"
                                  ? this.props.value.state.fitBanner === true
                                    ? "44px"
                                    : "54px"
                                  : "unset",
                              bottom:
                                res.ad_position === "bottom" ? "20px" : "unset",
                                 height:
                              this.props.value.state.adType === "custom"?
                              this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height):
                              "",
                              // height:this.props.value.state.adType === "custom"?"88px":"44px"
                            }}
                          >
                            {this.props.value.state.adType === "custom" ? (
                              // <p>Custom ad</p>
                              <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                            ) : (
                              <p>Google Admob banner</p>
                            )}
                          </div>
                        </div> */}
                        {this.returnSelectedModuleImg(
                          res.module_name,
                          res.ad_position
                        )}
                      </div>
                    </div>
                  ) : (
                    this.props.value.state.customPosts.map((post) => {
                      return (
                        post._id === res.item && (
                          <div className="page-preview-screen">
                            <div
                              className="preview-header"
                              // style={{
                              //   marginBottom:
                              //     res.ad_position === "top" ? "55px" : "",
                              // }}
                            >
                              <img
                                className="img-fluid"
                                src={
                                  require("../../assets/images/dashboard/leftarrow.png")
                                    .default
                                }
                              />
                            </div>
                            <div className="banner-scroll-div post-details-top" ref={this.props.value.postDetailsTopRef}>
                            {
                              res.ad_position==="top"&&
                            
                            <div className="third-ad-textdiv">
                            <div
                              className={`${this.props.value.state.adType === "banner"
                                  ? "third-ad-text third-ad-text-withoutabsolute"
                                  : "fourth-ad-text fourth-ad-text-withoutabsolute"
                                } ${this.props.value.state.fitBanner === true
                                  ? ""
                                  : "ad-with-padding"
                                }`}
                              style={{
                                top:
                                  res.ad_position === "top"
                                    ? this.props.value.state.fitBanner === true
                                      ? "44px"
                                      : "54px"
                                    : "unset",
                                bottom:
                                  res.ad_position === "bottom"
                                    ? "20px"
                                    : "unset",
                                marginTop:this.props.value.state.fitBanner === true?"0px":"",
                                 height:
                              this.props.value.state.adType === "custom"?
                              this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height):
                              "",
                                // height:this.props.value.state.adType === "custom"?"88px":"44px"
                              }}
                            >
                              {this.props.value.state.adType === "custom" ? (
                                // <p>Custom ad</p>
                              <iframe 
                              srcDoc={
                                this.props.value.state.deviceTypeForPreview === "ios" ?
                                this.props.value.state.iosAdScript :
                                this.props.value.state.androidAdScript
                              } 
                             style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                             />
                              ) : (
                                <p>Google Admob banner</p>
                              )}
                            </div>
                             </div> }
                             </div>
                            <div style={{ height: "100%" }}>
                              {post.content &&
                                post.content.map((cmsRow, indx) => {
                                  switch (cmsRow.item_type) {
                                    case "title":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div className={""}></div>

                                          <>
                                            <div className="create-post-title" style={{margin: "0px 15px" , padding: "10px 0px"}}>
                                              <div>
                                                {post.show_categories === 1 && (
                                                  <div className="post-detail-category-preview">
                                                    {post.category_info && post.category_info.map((eachCat) => {
                                                      return (
                                                        <h6>{eachCat}<span>|</span></h6>
                                                      )
                                                    })}
                                                  </div>
                                                )}
                                              </div>

                                              <h1>
                                                {" "}
                                                {cmsRow.value.title.text}
                                              </h1>

                                              <div className="post-detail-author">
                                                {post.show_author === 1 && (
                                                  <img
                                                    className="img-fluid"
                                                    src={
                                                      post.author_info
                                                        .profile_image &&
                                                        post.author_info.profile_image.indexOf(
                                                          "https"
                                                        ) > -1
                                                        ? post.author_info
                                                          .profile_image
                                                        : process.env
                                                          .REACT_APP_Image_Path +
                                                        post.author_info.id +
                                                        process.env
                                                          .REACT_APP_Profile +
                                                        post.author_info
                                                          .profile_image
                                                    }
                                                    style={{
                                                      width: "26px",
                                                      height: "26px",
                                                      borderRadius: "100%",
                                                    }}
                                                    alt="profile-img"
                                                    onError={({
                                                      currentTarget,
                                                    }) => {
                                                      currentTarget.onerror =
                                                        null;
                                                      currentTarget.src =
                                                        imagebackground;
                                                    }}
                                                  />
                                                )}
                                                <div
                                                  style={{
                                                    width:
                                                      post.show_author !== 1
                                                        ? "unset"
                                                        : "",
                                                    height:
                                                      post.show_author !== 1
                                                        ? "unset"
                                                        : "",
                                                  }}
                                                >
                                                  {post.show_author === 1 && (
                                                    <h4>
                                                      {post.author_info.name}
                                                    </h4>
                                                  )}
                                                  {post.show_publish_date ===
                                                    1 && (
                                                      <h5>
                                                        {post.published_immediate ===
                                                          1 || post.published_immediate ===
                                                          true
                                                          ? moment().format(
                                                            "DD MMMM YYYY"
                                                          )
                                                          :post.published_at && moment(
                                                            new Date(
                                                              parseInt(
                                                                post
                                                                  .published_at
                                                                  .$date
                                                                  .$numberLong
                                                              )
                                                            )
                                                          ).format(
                                                            "DD MMMM YYYY"
                                                          )}
                                                      </h5>
                                                    )}
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        </div>
                                      );
                                    case "image":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div>
                                            <div
                                              className="cms-banner-img"
                                              style={{
                                                padding: "7.5px 15px",
                                                height:
                                                  this.calculateHeight(
                                                    cmsRow.style.aspect_ratio,
                                                    302
                                                  ) + "px",
                                                width: "100%",
                                                backgroundColor:
                                                  cmsRow.portal_value
                                                    .source_file !== "" &&
                                                    cmsRow.portal_value
                                                      .source_file !==
                                                    undefined &&
                                                    cmsRow.portal_value
                                                      .source_file !== null
                                                    ? ""
                                                    : "#EBEBEB",
                                              }}
                                            >
                                              {cmsRow.portal_value
                                                .source_file !== "" &&
                                                cmsRow.portal_value
                                                  .source_file !== undefined &&
                                                cmsRow.portal_value
                                                  .source_file !== null ? (
                                                <div
                                                  className="fullwidthbanner"
                                                  style={{
                                                    height: "100%",

                                                    width: "100%",
                                                    backgroundRepeat:
                                                      "no-repeat",
                                                    backgroundPosition:
                                                      "center",
                                                    backgroundSize: "cover",
                                                    backgroundImage:
                                                      "url(" +
                                                      (cmsRow.portal_value.source_file.indexOf(
                                                        "https"
                                                      ) > -1
                                                        ? cmsRow.portal_value
                                                          .source_file
                                                        : process.env
                                                          .REACT_APP_Image_Path +
                                                        localStorage.getItem(
                                                          "user_id"
                                                        ) +
                                                        "/" +
                                                        localStorage.getItem(
                                                          "Appid"
                                                        ) +
                                                        process.env
                                                          .REACT_APP_CMS_Media +
                                                        cmsRow.portal_value
                                                          .source_file) +
                                                      ")",
                                                  }}
                                                ></div>
                                              ) : (
                                                <img
                                                  className="img-fluid"
                                                  src={
                                                    require("../../assets/images/dashboard/default-img.png")
                                                      .default
                                                  }
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    case "heading":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div className="page-body" style={{margin: "0px 15px" , padding: "10px 0px"}}>
                                            {cmsRow.value.heading.text ? (
                                              <h2
                                                className={`headings heading-bold text-${cmsRow.style.text_align}`}
                                              >
                                                {cmsRow.value.heading.text}
                                              </h2>
                                            ) : (
                                              <h2
                                                className={`headings heading-bold 
              
               `}
                                              >
                                                Enter your bold and clear
                                                heading here.
                                              </h2>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    case "paragraph":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div className="page-body" style={{margin: "0px 15px" , padding: "10px 0px"}}>
                                            {cmsRow.value.paragraph.text !==
                                              undefined &&
                                              cmsRow.value.paragraph.text !==
                                              null &&
                                              cmsRow.value.paragraph.text !==
                                              "" &&
                                              cmsRow.value.paragraph.text !==
                                              "<p><br></p>" ? (
                                              <>
                                                {cmsRow.value.paragraph !==
                                                  undefined &&
                                                  cmsRow.value.paragraph
                                                    .text !== undefined && (
                                                    <Markup
                                                      content={
                                                        cmsRow.value.paragraph
                                                          .text
                                                      }
                                                    />
                                                  )}
                                              </>
                                            ) : (
                                              <p style={{ color: "#CECECE" }}>
                                                Paragraph
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    case "code":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div className="page-body ad-page-body"  style={{margin: "7.5px 15px"}}>
                                            <iframe
                                              srcDoc={cmsRow.value.code.text}
                                              style={{
                                                width: "100%",
                                                minHeight: "198px",
                                                border: "1px solid #dadada",
                                              }}
                                            />
                                          </div>
                                        </div>
                                      );
                                    case "spacer":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div
                                            className="Spacer"
                                            style={{
                                              height: `${cmsRow.style.height}px`,
                                            }}
                                          ></div>
                                        </div>
                                      );
                                    case "separator":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div
                                            className=""
                                            style={{
                                              height:
                                                cmsRow.style.height + "px",
                                              background: cmsRow.style.color,
                                            }}
                                          ></div>
                                        </div>
                                      );
                                    case "button":
                                      return (
                                        <div

                                          className="managebutton"
                                          key={indx}
                                          style={{
                                            margin: "7.5px 15px 15px",
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <a
                                            className="linkbutton"
                                            style={{
                                              color: `${cmsRow.style.text_color}`,
                                              background: `${cmsRow.style.button_color}`,
                                              borderRadius: "3px",
                                            }}
                                          >
                                            {cmsRow.value.button.text}
                                          </a>
                                        </div>
                                      );
                                    case "gallery":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div>
                                            <div className="page-body ad-page-body">
                                              {/* GalleryLayouts */}
                                              <div className="gallerygrid"
                                               style={{margin: "7.5px 15px"}}>
                                                {cmsRow.portal_value &&
                                                  cmsRow.showOnlyArr &&
                                                  cmsRow.showOnlyArr.map(
                                                    (eachImage, i) => {
                                                      return (
                                                        <div
                                                          className={`custom-gallery ${(i + 1) %
                                                              cmsRow.style
                                                                .row ===
                                                              0
                                                              ? "not-padding-class ad-no-width-class"
                                                              : "padding-class ad-no-width-class"
                                                            }`}
                                                          style={{
                                                            width: `${100 /
                                                              cmsRow.style
                                                                .column
                                                              }%`,
                                                            height:
                                                              this.calculateHeight(
                                                                cmsRow.style
                                                                  .aspect_ratio,
                                                                282 /
                                                                cmsRow.style
                                                                  .column
                                                              ) + "px",
                                                          }}
                                                        >
                                                          {cmsRow.portal_value[
                                                            i
                                                          ].source_file !==
                                                            "" &&
                                                            cmsRow.portal_value[i]
                                                              .source_file !==
                                                            undefined &&
                                                            cmsRow.portal_value[i]
                                                              .source_file !==
                                                            null ? (
                                                            <div
                                                              className="img-fluid"
                                                              style={{
                                                                backgroundRepeat:
                                                                  "no-repeat",
                                                                backgroundPosition:
                                                                  "center",
                                                                backgroundSize:
                                                                  "cover",
                                                                width: "100%",
                                                                height: "100%",
                                                                backgroundImage:
                                                                  "url(" +
                                                                  (cmsRow.portal_value[
                                                                    i
                                                                  ].source_file.indexOf(
                                                                    "https"
                                                                  ) > -1
                                                                    ? cmsRow
                                                                      .portal_value[
                                                                      i
                                                                    ]
                                                                      .source_file
                                                                    : process
                                                                      .env
                                                                      .REACT_APP_Image_Path +
                                                                    localStorage.getItem(
                                                                      "user_id"
                                                                    ) +
                                                                    "/" +
                                                                    localStorage.getItem(
                                                                      "Appid"
                                                                    ) +
                                                                    process
                                                                      .env
                                                                      .REACT_APP_CMS_Media +
                                                                    cmsRow
                                                                      .portal_value[
                                                                      i
                                                                    ]
                                                                      .source_file) +
                                                                  ")",
                                                              }}
                                                            />
                                                          ) : (
                                                            <div className="custom-gallery-default-img">
                                                            <img
                                                              className="img-fluid"
                                                              src={
                                                                require("../../assets/images/dashboard/default-img.png")
                                                                  .default
                                                              }

                                                            // src=
                                                            />
                                                            </div>
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    case "custom_video":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div className="page-body ad-page-body">
                                            {cmsRow.portal_value.source_file ? (
                                              <ReactPlayer
                                                controls={true}
                                                url={
                                                  cmsRow.portal_value.source_file.indexOf(
                                                    "http"
                                                  ) > -1
                                                    ? cmsRow.portal_value
                                                      .source_file
                                                    : process.env
                                                      .REACT_APP_Image_Path +
                                                    localStorage.getItem(
                                                      "user_id"
                                                    ) +
                                                    "/" +
                                                    localStorage.getItem(
                                                      "Appid"
                                                    ) +
                                                    process.env
                                                      .REACT_APP_CMS_Media +
                                                    cmsRow.portal_value
                                                      .source_file
                                                }
                                                width={"100%"}
                                                height={"100%"}
                                                light={
                                                  cmsRow.portal_value
                                                    .thumbnail ? (
                                                    <img
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                      }}
                                                      src={
                                                        process.env
                                                          .REACT_APP_Image_Path +
                                                        localStorage.getItem(
                                                          "user_id"
                                                        ) +
                                                        "/" +
                                                        localStorage.getItem(
                                                          "Appid"
                                                        ) +
                                                        process.env
                                                          .REACT_APP_CMS_Media +
                                                        cmsRow.portal_value
                                                          .thumbnail
                                                      }
                                                      alt="Thumbnail"
                                                    />
                                                  ) : (
                                                    false
                                                  )
                                                }
                                              />
                                            ) : (
                                              <img
                                                className="img-fluid"
                                                src={
                                                  require("../../assets/images/dashboard/video-placeholder-new.png")
                                                    .default
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                    case "vimeo_video":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div className="page-body ad-page-body">
                                            {cmsRow.portal_value.link &&
                                              cmsRow.portal_value.is_link_err ===
                                              false ? (
                                              <ReactPlayer
                                                controls={true}
                                                url={cmsRow.portal_value.link}
                                                width={"100%"}
                                                height={"100%"}
                                              />
                                            ) : (
                                              <img
                                                className="img-fluid"
                                                alt=""
                                                src={
                                                  require("../../assets/images/dashboard/vimeo-placeholder.png")
                                                    .default
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                    case "facebook_video":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div className="page-body ad-page-body">
                                            {cmsRow.portal_value.link &&
                                              cmsRow.portal_value.is_link_err ===
                                              false ? (
                                              <ReactPlayer
                                                controls={true}
                                                url={cmsRow.portal_value.link}
                                                width={"100%"}
                                                height={"100%"}
                                              />
                                            ) : (
                                              <img
                                                className="img-fluid"
                                                src={
                                                  require("../../assets/images/dashboard/facebook-placeholder.png")
                                                    .default
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                    case "youtube_video":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div className="page-body ad-page-body">
                                            {cmsRow.portal_value.link &&
                                              cmsRow.portal_value.is_link_err ===
                                              false ? (
                                              <ReactPlayer
                                                controls={true}
                                                url={cmsRow.portal_value.link}
                                                width={"100%"}
                                                height={"100%"}
                                              />
                                            ) : (
                                              <img
                                                className="img-fluid"
                                                src={
                                                  require("../../assets/images/dashboard/youtube-placeholder.png")
                                                    .default
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );

                                    case "attribute":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div className="attributes-preview">
                                            <div className="page-body ad-page-body">
                                              {post.attributes_data &&
                                                post.attributes_data.map(
                                                  (atr) => {
                                                    return (

                                                      <div className="attributes-segment">
                                                        <h4>
                                                          {atr.attribute_icon_name ? (
                                                            <img
                                                              // alt=""
                                                              className="img-fluid"
                                                              src={
                                                                process.env
                                                                  .REACT_APP_Image_Path +
                                                                localStorage.getItem(
                                                                  "user_id"
                                                                ) +
                                                                "/" +
                                                                localStorage.getItem(
                                                                  "Appid"
                                                                ) +
                                                                process.env
                                                                  .REACT_APP_NEW_ICON_PATH +
                                                                atr.attribute_icon_name
                                                              }
                                                              alt="icon"
                                                            />
                                                          ) : (
                                                            <img
                                                              src={
                                                                imagebackground3
                                                              }
                                                              alt=""
                                                              className="img-fluid"
                                                            />
                                                          )}

                                                          {atr.label}
                                                        </h4>
                                                        {atr.terms &&
                                                          atr.terms
                                                            .length > 0 && (
                                                            <div>
                                                              {atr.terms &&
                                                                atr.terms.map(
                                                                  (
                                                                    atrVal
                                                                  ) => {
                                                                    return (

                                                                      <p>
                                                                        {
                                                                          atrVal.label
                                                                        }
                                                                      </p>

                                                                    );
                                                                  }
                                                                )}
                                                            </div>
                                                          )}
                                                      </div>

                                                    );
                                                  }
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                  }
                                })}

                              <div>
                                {post.show_tags === 1 && (
                                  <div className="post-detail-tag-preview">
                                    {post.tagsdetails &&
                                      post.tagsdetails.map((eachTag, i) => {
                                        return <h5>{eachTag}</h5>;
                                      })}
                                  </div>
                                )}
                              </div>

                              {this.props.value.state.customPosts &&
                                this.props.value.state.customPosts.length > 0 &&
                                post.show_related_posts === 1 && <hr></hr>}

                              <div>
                                <div
                                  style={{
                                    display:
                                      post.show_related_posts === 1
                                        ? ""
                                        : "none",
                                  }}
                                  className="post-listing-style-two"
                                >
                                  {this.props.value.state.customPosts &&
                                    this.props.value.state.customPosts.map(
                                      (eachPost) => {
                                        return (
                                          eachPost._id !== res.item
                                          // eachPost._id !==
                                          // "64a28d4a2d4d9ab836006e84" 
                                          && (
                                            <div className="post-list-type-two">
                                              <div className="post-type-two-data">
                                                <h3 className="post-two-heading">{eachPost.title}</h3>
                                                <h6>
                                                  {moment(
                                                    eachPost.created_at
                                                  ).format(
                                                    "D MMMM YYYY, HH:mm"
                                                  )}
                                                </h6>
                                              </div>
                                              <div
                                                className="post-type-two-thumbnail"
                                                style={{
                                                  position: "relative",
                                                  backgroundImage:
                                                    "url(" +
                                                    (eachPost.source_file ===
                                                      null ||
                                                      eachPost.source_file ===
                                                      undefined ||
                                                      eachPost.source_file === ""
                                                      ? imagebackground2
                                                      : eachPost.source_file.indexOf(
                                                        "https"
                                                      ) > -1
                                                        ? eachPost.source_file
                                                        : process.env
                                                          .REACT_APP_Image_Path +
                                                        localStorage.getItem(
                                                          "user_id"
                                                        ) +
                                                        "/" +
                                                        localStorage.getItem(
                                                          "Appid"
                                                        ) +
                                                        process.env
                                                          .REACT_APP_CMS_Media +
                                                        eachPost.source_file) +
                                                    ")",
                                                }}
                                              ></div>
                                            </div>
                                          )
                                        );
                                      }
                                    )}
                                </div>
                                <div className="banner-scroll-div post-details-bottom" ref={this.props.value.postDetailsBottomRef}>
                                {
                                  res.ad_position === "bottom" &&
                                
                                <div className="third-ad-textdiv">
                                  <div
                                    className={`${this.props.value.state.adType === "banner"
                                        ? "third-ad-text third-ad-text-withbottom-fixed"
                                        : "fourth-ad-text fourth-ad-text-withoutabsolute"
                                      } ${this.props.value.state.fitBanner === true
                                        ? ""
                                        : "ad-with-padding"
                                      }`}
                                    style={{
                                      top:
                                        res.ad_position === "top"
                                          ? this.props.value.state.fitBanner === true
                                            ? "44px"
                                            : "54px"
                                          : "unset",
                                      bottom:
                                        res.ad_position === "bottom" ? "0px" : "unset",
                                      marginTop:this.props.value.state.fitBanner === true?"0px":"",
                                       height:
                              this.props.value.state.adType === "custom"?
                              this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height):
                              "",
                                      // height:this.props.value.state.adType === "custom"?"88px":"44px"
                                    }}
                                  >
                                    {this.props.value.state.adType === "custom" ? (
                                     // <p>Custom ad</p>
                              <iframe 
                              srcDoc={
                                this.props.value.state.deviceTypeForPreview === "ios" ?
                                this.props.value.state.iosAdScript :
                                this.props.value.state.androidAdScript
                              } 
                             style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                             />
                                    ) : (
                                      <p>Google Admob banner</p>
                                    )}
                                  </div>
                                  </div> }
                                  </div>
                              </div>
                            </div>
                          </div>
                        )
                      );
                    })
                  )}
                </div>

                
              );
            } else if (res.module_name === "page_details") {
              return (
                <div className="ad-slide-3">
                  {res.item === "all" || res.item === "" ? (
                    <div
                      className={``}
                      style={{
                        height: "649px",
                      }}
                    >
                      <div className="">
                        {/* <div className="third-ad-textdiv">
                          <div
                            className={`${this.props.value.state.adType === "banner"
                                ? "third-ad-text"
                                : "fourth-ad-text"
                              } ${this.props.value.state.fitBanner === true
                                ? ""
                                : "ad-with-padding"
                              }`}
                            style={{
                              top:
                                res.ad_position === "top"
                                  ? this.props.value.state.fitBanner === true
                                    ? "44px"
                                    : "54px"
                                  : "unset",
                              bottom:
                                res.ad_position === "bottom" ? "20px" : "unset",
                                 height:
                              this.props.value.state.adType === "custom"?
                              this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height):
                              "unset",
                              // height:this.props.value.state.adType === "custom"?"88px":"44px"
                            }}
                          >
                            {this.props.value.state.adType === "custom" ? (
                             // <p>Custom ad</p>
                              <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                            ) : (
                              <p>Google Admob banner</p>
                            )}
                          </div>
                        </div> */}
                        {this.returnSelectedModuleImg(
                          res.module_name,
                          res.ad_position
                        )}
                      </div>
                    </div>
                  ) : (
                    res.customAppPagedData &&
                    res.customAppPagedData.map((page, indx) => {
                      return (
                        page._id === res.item && (
                          <div className="page-preview-screen">
                            <div 
                            // style={{ height: "100%" }}
                            
                            >
                              {page.content &&
                                page.content.map((cmsRow, indx) => {
                                  switch (cmsRow.item_type) {
                                    case "title":
                                      return (
                                        <>
                                          <div
                                            key={indx}
                                            style={{
                                              display:
                                                cmsRow.status === 0
                                                  ? "none"
                                                  : "",
                                              marginBottom:
                                                res.ad_position === "top"
                                                  // ? "75px"
                                                  ? ""
                                                  : "",
                                            }}
                                          >
                                            <div className={"preview-header"}>
                                              <>
                                                {cmsRow.value.title.text ? (
                                                  <h1 style={{
                                                    textAlign:"left",
                                                  }}>
                                                    {cmsRow.value.title.text}
                                                  </h1>
                                                ) : (
                                                  <h1>
                                                    Enter your bold and clear
                                                    title here.
                                                  </h1>
                                                )}
                                              </>
                                            </div>
                                          </div>
                                          <div className="banner-scroll-div page-details-top" ref={this.props.value.pageDetailsTopRef}>
                                          {
                                           res.ad_position==="top"&&
                                          <div className="third-ad-textdiv">
                                            <div
                                              className={`${this.props.value.state
                                                  .adType === "banner"
                                                  ? "third-ad-text third-ad-text-withoutabsolute"
                                                  : "fourth-ad-text fourth-ad-text-withoutabsolute"
                                                } ${this.props.value.state
                                                  .fitBanner === true
                                                  ? ""
                                                  : "ad-with-padding"
                                                }`}
                                              style={{
                                                top:
                                                  res.ad_position === "top"
                                                    ? this.props.value.state
                                                      .fitBanner === true
                                                      ? "44px"
                                                      : "54px"
                                                    : "unset",
                                                bottom:
                                                  res.ad_position === "bottom"
                                                    ? "20px"
                                                    : "unset",
                                                marginTop:this.props.value.state.fitBanner === true?"0px":"",
                                                 height:
                              this.props.value.state.adType === "custom"?
                              this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height):
                              "",
                                                // height:this.props.value.state.adType === "custom"?"88px":"44px"
                                              }}
                                            >
                                              {this.props.value.state.adType ===
                                                "custom" ? (
                                               // <p>Custom ad</p>
                              <iframe 
                              srcDoc={
                                this.props.value.state.deviceTypeForPreview === "ios" ?
                                this.props.value.state.iosAdScript :
                                this.props.value.state.androidAdScript
                              } 
                             style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                             />
                                              ) : (
                                                <p>Google Admob banner</p>
                                              )}
                                            </div>
                                          </div> }
                                          </div>
                                        </>
                                      );
                                    case "image":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div>
                                            <div
                                              className="cms-banner-img"
                                              style={{
                                                padding: "7.5px 15px",
                                                height:
                                                  this.calculateHeight(
                                                    cmsRow.style.aspect_ratio,
                                                    302
                                                  ) + "px",
                                                width: "100%",
                                                backgroundColor:
                                                  cmsRow.portal_value
                                                    .source_file !== "" &&
                                                    cmsRow.portal_value
                                                      .source_file !==
                                                    undefined &&
                                                    cmsRow.portal_value
                                                      .source_file !== null
                                                    ? ""
                                                    : "#EBEBEB",
                                              }}
                                            >
                                              {cmsRow.portal_value
                                                .source_file !== "" &&
                                                cmsRow.portal_value
                                                  .source_file !== undefined &&
                                                cmsRow.portal_value
                                                  .source_file !== null ? (
                                                <div
                                                  className="fullwidthbanner"
                                                  style={{
                                                    height: "100%",

                                                    width: "100%",
                                                    backgroundRepeat:
                                                      "no-repeat",
                                                    backgroundPosition:
                                                      "center",
                                                    backgroundSize: "cover",
                                                    backgroundImage:
                                                      "url(" +
                                                      //   cmsRow.portal_value.source_file ===
                                                      //   "rainbow-wall.jpg" ||
                                                      // cmsRow.portal_value.source_file ===
                                                      //   "floating-unicorn.jpg"
                                                      //   ? process.env.REACT_APP_Image_Path +
                                                      //     process.env.REACT_APP_DEFAULT_CMS_IMG +
                                                      //     cmsRow.portal_value.source_file
                                                      //   :
                                                      (cmsRow.portal_value.source_file.indexOf(
                                                        "https"
                                                      ) > -1
                                                        ? cmsRow.portal_value
                                                          .source_file
                                                        : process.env
                                                          .REACT_APP_Image_Path +
                                                        localStorage.getItem(
                                                          "user_id"
                                                        ) +
                                                        "/" +
                                                        localStorage.getItem(
                                                          "Appid"
                                                        ) +
                                                        process.env
                                                          .REACT_APP_CMS_Media +
                                                        cmsRow.portal_value
                                                          .source_file) +
                                                      ")",
                                                  }}
                                                ></div>
                                              ) : (
                                                <img
                                                  className="img-fluid"
                                                  src={
                                                    require("../../assets/images/dashboard/default-img.png")
                                                      .default
                                                  }
                                                />
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    case "heading":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div className="page-body ad-page-body">
                                            {cmsRow.value.heading.text ? (
                                              <h2
                                              style={{margin: "7.5px 15px"}}
                                                // className={
                                                //   `headings ${
                                                //     cmsRow.style.bold_text ? "heading-bold" : ""
                                                //   } ` +
                                                //   `${
                                                //     cmsRow.style.italic_text ? "heading-italiq" : ""
                                                //   } ` +
                                                //   `${
                                                //     cmsRow.style.underline ? "heading-underline" : ""
                                                //   } ` +
                                                //   `${cmsRow.style.text_align}`
                                                // }
                                                className={`headings heading-bold text-${cmsRow.style.text_align}`}
                                              >
                                                {cmsRow.value.heading.text}
                                              </h2>
                                            ) : (
                                              <h2
                                                // style={{color: "#CECECE"}}
                                                className={`headings heading-bold 
            
             `}
                                              >
                                                Enter your bold and clear
                                                heading here.
                                              </h2>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    case "paragraph":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div className="page-body" style={{margin: "0px 15px" , padding: "10px 0px"}}>
                                            {cmsRow.value.paragraph.text !==
                                              undefined &&
                                              cmsRow.value.paragraph.text !==
                                              null &&
                                              cmsRow.value.paragraph.text !==
                                              "" &&
                                              cmsRow.value.paragraph.text !==
                                              "<p><br></p>" ? (
                                              <>
                                                {cmsRow.value.paragraph !==
                                                  undefined &&
                                                  cmsRow.value.paragraph
                                                    .text !== undefined && (
                                                    <Markup
                                                      content={
                                                        cmsRow.value.paragraph
                                                          .text
                                                      }
                                                    />
                                                  )}
                                              </>
                                            ) : (
                                              <p style={{ color: "#CECECE" }}>
                                                Paragraph
                                              </p>
                                            )}
                                          </div>
                                        </div>
                                      );
                                    case "code":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div className="page-body ad-page-body"  style={{margin: "7.5px 15px"}}>
                                            <iframe
                                              srcDoc={cmsRow.value.code.text}
                                              style={{
                                                width: "100%",
                                                minHeight: "198px",
                                                border: "1px solid #dadada",
                                              }}
                                            />
                                            {/* <CmsEditor value={cmsRow.value.code.text}/> */}
                                          </div>
                                        </div>
                                      );
                                    case "spacer":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div
                                            className="Spacer"
                                            style={{
                                              height: `${cmsRow.style.height}px`,
                                            }}
                                          ></div>
                                        </div>
                                      );
                                    case "separator":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div
                                            className=""
                                            style={{
                                              height:
                                                cmsRow.style.height + "px",
                                              background: cmsRow.style.color,
                                            }}
                                          ></div>
                                        </div>
                                      );
                                    case "button":
                                      return (
                                        <div
                                          className="managebutton"
                                          key={indx}
                                          style={{
                                            margin: "7.5px 15px 15px",
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <a
                                            className="linkbutton"
                                            style={{
                                              color: `${cmsRow.style.text_color}`,
                                              background: `${cmsRow.style.button_color}`,
                                              borderRadius: "3px",
                                            }}
                                          >
                                            {cmsRow.value.button.text}
                                          </a>
                                        </div>
                                      );
                                    case "gallery":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div>
                                            <div className="page-body ">
                                              {/* GalleryLayouts */}
                                              <div className="gallerygrid"
                                               style={{margin: "7.5px 15px"}}>
                                                {cmsRow.portal_value &&
                                                  cmsRow.showOnlyArr &&
                                                  cmsRow.showOnlyArr.map(
                                                    (eachImage, i) => {
                                                      return (
                                                        <div
                                                          className={`custom-gallery ${(i + 1) %
                                                              cmsRow.style
                                                                .row ===
                                                              0
                                                              ? "not-padding-class ad-no-width-class"
                                                              : "padding-class ad-no-width-class"
                                                            }`}
                                                          style={{
                                                            width: `${100 /
                                                              cmsRow.style
                                                                .column
                                                              }%`,
                                                            height:
                                                              this.calculateHeight(
                                                                cmsRow.style
                                                                  .aspect_ratio,
                                                                282 /
                                                                cmsRow.style
                                                                  .column
                                                              ) + "px",
                                                          }}
                                                        >
                                                          {cmsRow.portal_value[
                                                            i
                                                          ].source_file !==
                                                            "" &&
                                                            cmsRow.portal_value[i]
                                                              .source_file !==
                                                            undefined &&
                                                            cmsRow.portal_value[i]
                                                              .source_file !==
                                                            null ? (
                                                            <div
                                                              className="img-fluid"
                                                              style={{
                                                                backgroundRepeat:
                                                                  "no-repeat",
                                                                backgroundPosition:
                                                                  "center",
                                                                backgroundSize:
                                                                  "cover",
                                                                width: "100%",
                                                                height: "100%",
                                                                backgroundImage:
                                                                  "url(" +
                                                                  (cmsRow.portal_value[
                                                                    i
                                                                  ].source_file.indexOf(
                                                                    "https"
                                                                  ) > -1
                                                                    ? cmsRow
                                                                      .portal_value[
                                                                      i
                                                                    ]
                                                                      .source_file
                                                                    : process
                                                                      .env
                                                                      .REACT_APP_Image_Path +
                                                                    localStorage.getItem(
                                                                      "user_id"
                                                                    ) +
                                                                    "/" +
                                                                    localStorage.getItem(
                                                                      "Appid"
                                                                    ) +
                                                                    process
                                                                      .env
                                                                      .REACT_APP_CMS_Media +
                                                                    cmsRow
                                                                      .portal_value[
                                                                      i
                                                                    ]
                                                                      .source_file) +
                                                                  ")",
                                                              }}
                                                            />
                                                          ) : (
                                                            <div className="custom-gallery-default-img">
                                                            <img
                                                              className="img-fluid"
                                                              src={
                                                                require("../../assets/images/dashboard/default-img.png")
                                                                  .default
                                                              }

                                                            // src=
                                                            />
                                                            </div>
                                                          )}
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    case "custom_video":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div className="page-body ad-page-body"  style={{margin: "7.5px 15px"}}>
                                            {cmsRow.portal_value.source_file ? (
                                              <ReactPlayer
                                                controls={true}
                                                url={
                                                  cmsRow.portal_value.source_file.indexOf(
                                                    "http"
                                                  ) > -1
                                                    ? cmsRow.portal_value
                                                      .source_file
                                                    : process.env
                                                      .REACT_APP_Image_Path +
                                                    localStorage.getItem(
                                                      "user_id"
                                                    ) +
                                                    "/" +
                                                    localStorage.getItem(
                                                      "Appid"
                                                    ) +
                                                    process.env
                                                      .REACT_APP_CMS_Media +
                                                    cmsRow.portal_value
                                                      .source_file
                                                }
                                                width={"100%"}
                                                height={"100%"}
                                                light={
                                                  cmsRow.portal_value
                                                    .thumbnail ? (
                                                    <img
                                                      style={{
                                                        width: "100%",
                                                        height: "100%",
                                                      }}
                                                      src={
                                                        // require(
                                                        process.env
                                                          .REACT_APP_Image_Path +
                                                        localStorage.getItem(
                                                          "user_id"
                                                        ) +
                                                        "/" +
                                                        localStorage.getItem(
                                                          "Appid"
                                                        ) +
                                                        process.env
                                                          .REACT_APP_CMS_Media +
                                                        cmsRow.portal_value
                                                          .thumbnail
                                                        // )
                                                        //   .default
                                                      }
                                                      alt="Thumbnail"
                                                    />
                                                  ) : (
                                                    false
                                                  )
                                                }
                                              />
                                            ) : (
                                              <img
                                                className="img-fluid"
                                                src={
                                                  require("../../assets/images/dashboard/video-placeholder-new.png")
                                                    .default
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                    case "vimeo_video":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div className="page-body ad-page-body"  style={{margin: "7.5px 15px"}}>
                                            {cmsRow.portal_value.link &&
                                              cmsRow.portal_value.is_link_err ===
                                              false ? (
                                              <ReactPlayer
                                                controls={true}
                                                url={cmsRow.portal_value.link}
                                                width={"100%"}
                                                height={"100%"}
                                              />
                                            ) : (
                                              <img
                                                className="img-fluid"
                                                src={
                                                  require("../../assets/images/dashboard/vimeo-placeholder.png")
                                                    .default
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                    case "facebook_video":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div className="page-body ad-page-body"  style={{margin: "7.5px 15px"}}>
                                            {cmsRow.portal_value.link &&
                                              cmsRow.portal_value.is_link_err ===
                                              false ? (
                                              <ReactPlayer
                                                controls={true}
                                                url={cmsRow.portal_value.link}
                                                width={"100%"}
                                                height={"100%"}
                                              />
                                            ) : (
                                              <img
                                                className="img-fluid"
                                                src={
                                                  require("../../assets/images/dashboard/facebook-placeholder.png")
                                                    .default
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                    case "youtube_video":
                                      return (
                                        <div
                                          key={indx}
                                          style={{
                                            display:
                                              cmsRow.status === 0 ? "none" : "",
                                          }}
                                        >
                                          <div className="page-body ad-page-body"  style={{margin: "7.5px 15px"}}>
                                            {cmsRow.portal_value.link &&
                                              cmsRow.portal_value.is_link_err ===
                                              false ? (
                                              <ReactPlayer
                                                controls={true}
                                                url={cmsRow.portal_value.link}
                                                width={"100%"}
                                                height={"100%"}
                                              />
                                            ) : (
                                              <img
                                                className="img-fluid"
                                                src={
                                                  require("../../assets/images/dashboard/youtube-placeholder.png")
                                                    .default
                                                }
                                              />
                                            )}
                                          </div>
                                        </div>
                                      );
                                  }
                                })}
                            </div>
                            <div className="banner-scroll-div page-details-bottom" ref={this.props.value.pageDetailsBottomRef}>
                            {
                              res.ad_position==="bottom" &&
                    <div className="third-ad-textdiv">
                      <div
                        className={`${this.props.value.state.adType === "banner"
                            ? "third-ad-text third-ad-text-withbottom-fixed"
                            : "fourth-ad-text fourth-ad-text-withoutabsolute"
                          } ${this.props.value.state.fitBanner === true
                            ? ""
                            : "ad-with-padding"
                          }`}
                        style={{
                          top:
                            res.ad_position === "top"
                              ? this.props.value.state.fitBanner === true
                                ? "44px"
                                : "54px"
                              : "unset",
                          bottom:
                            res.ad_position === "bottom" ? "0px" : "unset",
                          marginTop:this.props.value.state.fitBanner === true?"0px":"",
                           height:
                              this.props.value.state.adType === "custom"?
                              this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height):
                              "",
                          // height:this.props.value.state.adType === "custom"?"88px":"44px"
                        }}
                      >
                        {this.props.value.state.adType === "custom" ? (
                          // <p>Custom ad</p>
                          <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                        ) : (
                          <p>Google Admob banner</p>
                        )}
                      </div>
                    </div> }
                          </div>
                          </div>
                        )
                      );
                    })
                  )}
                </div>
              );
            } else if (res.module_name === "web_view") {
              return (
                <div
                  className="ad-slide-5"
                  style={{
                    height: "649px",
                  }}
                >
                  <div className="every-ad-img">
                    <div className="third-ad-textdiv">
                      <div
                        className={`${this.props.value.state.adType === "banner"
                            ? "third-ad-text"
                            : "fourth-ad-text"
                          } ${this.props.value.state.fitBanner === true
                            ? ""
                            : "ad-with-padding"
                          }`}
                        style={{
                          top:
                            res.ad_position === "top"
                              ? this.props.value.state.fitBanner === true
                                ? "44px"
                                : "54px"
                              : "unset",
                          bottom:
                            res.ad_position === "bottom" ? "15px" : "unset",
                             height:
                              this.props.value.state.adType === "custom"?
                              this.calculateHeightFinalHeight(this.props.value.state.custom_ad_width,this.props.value.state.custom_ad_height):
                              "",
                          // height:this.props.value.state.adType === "custom"?"88px":"44px"
                        }}
                      >
                        {this.props.value.state.adType === "custom" ? (
                          // <p>Custom ad</p>
                          <iframe 
                          srcDoc={
                            this.props.value.state.deviceTypeForPreview === "ios" ?
                            this.props.value.state.iosAdScript :
                            this.props.value.state.androidAdScript
                          } 
                         style={{width: "100%", height: "100%", margin:"0",border:"1px solid #e3e6f1"}}
                         />
                        ) : (
                          <p>Google Admob banner</p>
                        )}
                      </div>
                    </div>
                    <img
                      src={
                        require("../../assets/images/dashboard/advertisement/webviewadd.png")
                          .default
                      }
                    />
                  </div>
                </div>
              );
            }
          })}
      </Carousel>
    );
  }
}

export default CustomAppPreview;
