import React, { useState, useEffect } from "react";
import styles from "./logs.module.css";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import { Errorsvg } from "./errorsvg.jsx";
import { LogsApi, getData } from "../../Api/Logs/LogsApi";
import EmptyLogs from "./EmptyLogs";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap";
import { Error } from "./errormodal";
import BottomScrollListener from "react-bottom-scroll-listener";
import moment from "moment";
import ReactSelect from "react-select";
import "./errorLogs.css";
import { InnerFoot } from "../Foot";
import firebaseIc from "../../assets/images/dashboard/firebaseIc.svg";
const Logs = () => {
  useEffect(() => {
    if (
      localStorage.getItem("agencyid") &&
      localStorage.getItem("agencyid") == 1
    ) {
      document.title = "Logs | AppMySite";
    } else {
      document.title = "Logs | " + localStorage.getItem("agencyname");
    }
  }, []);
  const [logdetail, setlogdetail] = useState(false);
  const [errorLog, setErrorLog] = useState("");
  const [token, setToken] = useState(null);
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [filter, setFilter] = useState("7d");
  const [pagination, setPagination] = useState({ size: 20, from: 0 });
  const [bottomSpinner, setBottomSpinner] = useState(false);
  const [endData, setEndData] = useState(false);
  const [checkFilter, setCheckFilter] = useState(false);
  const [increamentData, setIncreamentData] = useState(true);
  const [iosnotifyid, setIOSnotifyid] = useState(null);
  const [androidnotifyid, setANDROIDnotifyid] = useState(null);
  const [appRightPanelExpand, setAppRightPanelExpand] = useState( localStorage.getItem("appSideBarCollapse") === true ||
	localStorage.getItem("appSideBarCollapse") === "true"
	  ? true
	  : false);
  const [openWebRangeDrop, setOpenWebRangeDrop] = useState(false);
  const [openFirebasePopUp, setOpenFirebasePopUp] = useState(false);
  const [openMobRangeFilter, setOpenMobRangeFilter] = useState(false);
  

  const handleFilter = (e,val) => {
    setIsLoading(true);
    setFilter(e);
    setOpenMobRangeFilter(false);
    setCheckFilter(true);
    setPagination({ size: pagination.size, from: 0 });
    setIncreamentData(false);
  };

  const handleContainerOnBottom = () => {
    if (endData === false && !bottomSpinner) {
      setPagination({ size: pagination.size, from: pagination.from + 1 });
      updateData(token);
      setBottomSpinner(true);
      if (pagination.from === 0) {
        window.scroll(0, window.pageYOffset - 0.5);
      }
      setIncreamentData(true);
    }
  };

  const handleError = (e, data) => {
    e.preventDefault();
    setErrorLog(data);
    setlogdetail(true);
  };

  const updateData = (token) => {
    getData(
      token,
      setData,
      setIsLoading,
      filter,
      pagination,
      setBottomSpinner,
      data,
      setEndData,
      setCheckFilter,
      checkFilter,
      increamentData
    );
  };

 const handleOpenRangeDrop = () =>{
    setOpenWebRangeDrop(!openWebRangeDrop);
  }
  const handleFirebasePopUp = () =>{
    setOpenFirebasePopUp(!openFirebasePopUp);
  }
  const handleMobRangeFilter = () =>{
    setOpenMobRangeFilter(!openMobRangeFilter);
  }
  

  useEffect(() => {
    const fetchData = async () => {
      const response = await LogsApi(setToken,setIOSnotifyid,setANDROIDnotifyid);
      return response;
    };
    fetchData().then((res) => {
      updateData(res);
    });
  }, [filter]);

  const HeaderData = () => {
    return (
      <div >
      <div className="d-flex  justify-content-between ">
        <div className="">
          <div className="d-flex align-items-center ">
          <h1 className='logs-head'>Logs
                <p className=" productsetting-tooltip">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                    <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                      <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                        <circle cx="9" cy="9" r="9" stroke="none" />
                        <circle cx="9" cy="9" r="8.25" fill="none" />
                      </g>
                      <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5" />
                      <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                    </g>
                  </svg>
                  <p className="productsetting-tooltipsubheader">
                  Track and troubleshoot errors here. Access error details, including date, time, device type, app code version, and the specific error encountered.
                  </p>
                </p>
                </h1>
            {/* <Amsvideo timespan = {572} addclass = "d-md-flex d-none align-items-center justify-content-between see_video" /> */}
          </div>
          {/* <h5 className="ipad-grant-subtext">
            Keep track of errors that occur in your apps.
          </h5> */}
        </div>
        

               
        <div className="d-flex new-heading">
         {/* <div className="" style={{ zIndex: "1" }}>
              {androidnotifyid !== null ?
                
                  <p><svg width="15.688" height="21.536" viewBox="0 0 15.688 21.536">
                      <g id="firebase-1" transform="translate(0 0)">
                        <path id="Path_9361" data-name="Path 9361" d="M0,17.383.13,17.2,6.3,5.5l.013-.124L3.592.268a.5.5,0,0,0-.944.159Z" transform="translate(0 0)" fill="#ffc24a"/>
                        <path id="Path_9362" data-name="Path 9362" d="M1.253,18.465l.1-.192,6.1-11.58L4.742,1.566a.458.458,0,0,0-.872.158Z" transform="translate(-1.176 -1.221)" fill="#ffa712"/>
                        <path id="Path_9364" data-name="Path 9364" d="M104.422,55.439l2.024-2.073L104.421,49.5a.522.522,0,0,0-.919,0l-1.082,2.063v.176l2,3.7Z" transform="translate(-96.129 -46.202)" fill="#f4bd62"/>
                        <path id="Path_9365" data-name="Path 9365" d="M104.685,56.548l1.968-2.015-1.968-3.747a.453.453,0,0,0-.826-.036l-1.088,2.1-.033.107Z" transform="translate(-96.428 -47.397)" fill="#ffa50e"/>
                        <path id="Path_9367" data-name="Path 9367" d="M0,99.138l.059-.059.215-.087,7.892-7.862.1-.272L6.3,87.1Z" transform="translate(0 -81.754)" fill="#f6820c"/>
                        <path id="Path_9368" data-name="Path 9368" d="M8.545,88.483,15.688,84.5l-2.04-12.561a.488.488,0,0,0-.827-.267L0,84.518l7.1,3.965a1.482,1.482,0,0,0,1.444,0" transform="translate(0 -67.135)" fill="#fde068"/>
                        <path id="Path_9369" data-name="Path 9369" d="M16.834,85.382,14.81,72.916a.429.429,0,0,0-.743-.272L1.29,85.409l7.023,3.926a1.471,1.471,0,0,0,1.433,0l7.088-3.953Z" transform="translate(-1.211 -68.05)" fill="#fcca3f"/>
                        <path id="Path_9370" data-name="Path 9370" d="M8.546,284.925a1.482,1.482,0,0,1-1.444,0L.058,281.017l-.057.06,7.1,3.965a1.482,1.482,0,0,0,1.444,0l7.142-3.983-.018-.108-7.125,3.973Z" transform="translate(-0.001 -263.695)" fill="#eeab37"/>
                      </g>
                    </svg>
                    Reports
                  </p>  
                : iosnotifyid !== null ?
                  <p><svg width="15.688" height="21.536" viewBox="0 0 15.688 21.536">
                      <g id="firebase-1" transform="translate(0 0)">
                        <path id="Path_9361" data-name="Path 9361" d="M0,17.383.13,17.2,6.3,5.5l.013-.124L3.592.268a.5.5,0,0,0-.944.159Z" transform="translate(0 0)" fill="#ffc24a"/>
                        <path id="Path_9362" data-name="Path 9362" d="M1.253,18.465l.1-.192,6.1-11.58L4.742,1.566a.458.458,0,0,0-.872.158Z" transform="translate(-1.176 -1.221)" fill="#ffa712"/>
                        <path id="Path_9364" data-name="Path 9364" d="M104.422,55.439l2.024-2.073L104.421,49.5a.522.522,0,0,0-.919,0l-1.082,2.063v.176l2,3.7Z" transform="translate(-96.129 -46.202)" fill="#f4bd62"/>
                        <path id="Path_9365" data-name="Path 9365" d="M104.685,56.548l1.968-2.015-1.968-3.747a.453.453,0,0,0-.826-.036l-1.088,2.1-.033.107Z" transform="translate(-96.428 -47.397)" fill="#ffa50e"/>
                        <path id="Path_9367" data-name="Path 9367" d="M0,99.138l.059-.059.215-.087,7.892-7.862.1-.272L6.3,87.1Z" transform="translate(0 -81.754)" fill="#f6820c"/>
                        <path id="Path_9368" data-name="Path 9368" d="M8.545,88.483,15.688,84.5l-2.04-12.561a.488.488,0,0,0-.827-.267L0,84.518l7.1,3.965a1.482,1.482,0,0,0,1.444,0" transform="translate(0 -67.135)" fill="#fde068"/>
                        <path id="Path_9369" data-name="Path 9369" d="M16.834,85.382,14.81,72.916a.429.429,0,0,0-.743-.272L1.29,85.409l7.023,3.926a1.471,1.471,0,0,0,1.433,0l7.088-3.953Z" transform="translate(-1.211 -68.05)" fill="#fcca3f"/>
                        <path id="Path_9370" data-name="Path 9370" d="M8.546,284.925a1.482,1.482,0,0,1-1.444,0L.058,281.017l-.057.06,7.1,3.965a1.482,1.482,0,0,0,1.444,0l7.142-3.983-.018-.108-7.125,3.973Z" transform="translate(-0.001 -263.695)" fill="#eeab37"/>
                      </g>
                    </svg>
                    Reports
                  </p>
                : null
              }
          <div>
                {androidnotifyid !== null ?
                  <a target="_blank" href={`https://console.firebase.google.com/u/0/project/${androidnotifyid}/crashlytics`}>Android <img
                        src={
                          require("../../assets/images/dashboard/Goto.png").default
                        }
                        className="dashboard-gotospace"
                        alt="Goto"
                      /></a>
                : null
              }

              {iosnotifyid !== null ?  
                  <a target="_blank" href={`https://console.firebase.google.com/u/0/project/${iosnotifyid}/crashlytics`}> iOS <img
                        src={
                          require("../../assets/images/dashboard/Goto.png").default
                        }
                        className="dashboard-gotospace"
                        alt="Goto"
                      /></a>
               : null
              }
                
            </div>
          </div> */}
          {/*<div><span className="second-heading">Error Logs</span></div>*/}
          <div className="smalldropdown">
            {/* <select
              onChange={(e) => handleFilter(e)}
              value={filter}
              className="btn text-left btn-sm btn-light bg-transparent border border-4 logs-select-dropdown"
            >
              <option value="1d">Last 24 hours</option>
              <option value="7d">Last 7 days</option>
              <option value="14d">Last 14 days</option>
              <option value="30d">Last 30 days</option>
              <option value="60d">Last 60 days</option>
            </select> */}
            <div className="logfirebasesvg" onClick={(e)=>handleFirebasePopUp(e)}>
            <img
                      src={firebaseIc}
                      alt="firebase"
                      
                    />
                    <p className="logfirebase-text">Firebase</p>
            </div>


                  
                                {
                                  window.matchMedia("(max-width: 1200px)").matches?<></>:
                                  <Dropdown
                                  className={"cms-filterby-post search-dropdown connectivity-search-dropdown"}
                                    isOpen={openWebRangeDrop}
                                    // onClick={
                                    //   window.matchMedia("(max-width: 1200px)")
                                    //     .matches
                                    //     ? this.handleMobileAppDropdown
                                    //     : null
                                    // }
                                    toggle={(e)=>handleOpenRangeDrop(e)}
                                    >
                                    <DropdownToggle caret>
                                      <span>{filter == '1d' ? 'Last 24 hr'
                                            :filter == '7d' ? 'Last 7 days'
                                            :filter == '14d' ? 'Last 14 days'
                                            :filter == '30d' ? 'Last 30 days'
                                            :filter == '60d' ? 'Last 60 days'
                                            : '7 Days'
                                            }
                                      </span>
                                    </DropdownToggle>
                                    <DropdownMenu end={true}>
                                      
                                      <DropdownItem active={filter=="1d"} onClick={() =>handleFilter('1d')}>Last 24 hr</DropdownItem>
                                      <DropdownItem active={filter=="7d"}  onClick={() =>handleFilter('7d')}>Last 7 days</DropdownItem>
                                      <DropdownItem active={filter=="14d"}  onClick={() =>handleFilter('14d')}>Last 14 days</DropdownItem>
                                      <DropdownItem active={filter=="30d"}  onClick={() =>handleFilter('30d')}>Last 30 days</DropdownItem>
                                      <DropdownItem active={filter=="60d"}  onClick={() =>handleFilter('60d')}>Last 60 days</DropdownItem>
                                      
                                    </DropdownMenu>
                                  </Dropdown>}
                        
         
          </div>
        </div> 

       
      </div>

      <div className="mob-logdropdown" >
        {/* <p onClick={(e)=>handleMobRangeFilter(e)}>Mob drop</p> */}
        <Dropdown
                                  className={"cms-filterby-post search-dropdown connectivity-search-dropdown"}
                                    isOpen={false}
                                    // disbled={
                                    //   window.matchMedia("(max-width: 1200px)")
                                    //     .matches
                                    //     ? this.handleMobileAppDropdown
                                    //     : null
                                    // }
                                    toggle={(e)=>handleMobRangeFilter(e)}
                                    >
                                    <DropdownToggle caret>
                                      <span>{filter == '1d' ? 'Last 24 hr'
                                            :filter == '7d' ? 'Last 7 days'
                                            :filter == '14d' ? 'Last 14 days'
                                            :filter == '30d' ? 'Last 30 days'
                                            :filter == '60d' ? 'Last 60 days'
                                            : '7 Days'
                                            }
                                      </span>
                                    </DropdownToggle>
                                   
                                  </Dropdown>

{/* Mobile Dropdown */}
<div
                                  className={
                                    openMobRangeFilter
                                      ? "modal-mob-select-div mob-dropdown-log"
                                      : ""
                                  }
                                >
                                  {openMobRangeFilter && (
                                    <div className="billing-mob-select-div billing-mob-one">
                                       <div className="billing-mob-select-divtwo">
                                     
                                              <div className="d-flex align-items-center billing-mob-select-div-user"  
                                              onClick={()=>handleFilter("1d")}
                                              >
                                                
                                               Last 24 hr
                                              </div>
                                             
                                              <div className="d-flex align-items-center billing-mob-select-div-user"  
                                              onClick={() =>handleFilter('7d')}
                                              >
                                                
                                               Last 7 days
                                              </div>
                                              <div className="d-flex align-items-center billing-mob-select-div-user"  
                                              onClick={() =>handleFilter('14d')}
                                              >
                                                
                                               Last 14 days
                                              </div>
                                              <div className="d-flex align-items-center billing-mob-select-div-user"  
                                              onClick={() =>handleFilter('30d')}
                                              >
                                                
                                               Last 30 days
                                              </div>
                                              <div className="d-flex align-items-center billing-mob-select-div-user"  
                                              onClick={() =>handleFilter('60d')}
                                              >
                                                
                                               Last 60 days
                                              </div>
                                          
                                        </div>
                                    </div>
                                  )}
                                </div>

</div>
      </div>
      
    );
  };

  const TableData = () => {
    return (
      <>
        {logdetail && Error(logdetail, setlogdetail, errorLog)}
        {isLoading !== true ? (
          <>
            {/* <HeaderData /> */}
            {data !== null && data !== undefined && data.hits.length > 0 ? (
              <>
              <div className="">
                <table className="table table-borderless logsTable">
                  <thead>
                    <tr>
                      <th scope="col">Date</th>
                      <th scope="col">Device</th>
                      <th scope="col">App code version</th>
                      <th scope="col">Error</th>
                      <th scope="col">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.hits.map((errorList, index) => (
                      <React.Fragment key={index}>
                        {errorList._source.response !== undefined && (
                          <tr>
                            <>
                              <td>
                              <div className="logmob-header-td">
                             Date
                                </div> 
                                <div >
                                {Errorsvg()}{" "}
                                <span className="log-datediv">
                                  {moment(errorList._source.timestamp).format(
                                    "D MMMM YYYY, HH:mm"
                                  )}
                                </span>
                                {/* ,{" "}
                                <span>
                                  {moment(errorList._source.timestamp).format(
                                    "h:mm a"
                                  )}
                                </span> */}
                                </div> 
                              </td>
                              <td>
                                {errorList._source.app_device_type === "Ios"
                                  // ? "iOS"
                                  // : errorList._source.app_device_type}
                                  ? <img src={require("../../assets/images/dashboard/apple-logs.png").default } />
                                  : <img src={require("../../assets/images/dashboard/android-logs.png").default } /> }
                              </td>
                              <td>
                              <div className="logmob-header-td">
                              App code version
                                </div> 
                                <div>
                                {errorList._source.app_code_version ===
                                  undefined ||
                                errorList._source.app_code_version === "" ||
                                errorList._source.app_code_version === null
                                  ? "-"
                                  : errorList._source.app_code_version}
                                  </div>
                              </td>
                              <td className={styles.textwidth}>

                              <div className="logmob-header-td">
                              Error
                                </div> 
                                <div>
                                {errorList._source.response?.message !==
                                undefined
                                  ? errorList._source.response?.message
                                  : "..."}
                                  </div>
                              </td>
                              <td>
                                {errorList._source.response?.error !==
                                undefined ? (
                                  <a
                                    onClick={(e) => handleError(e, errorList)}
                                    style={{ color: "#3064F9" }}
                                    href="#"
                                  >
                                    Details
                                  </a>
                                ) : null}
                              </td>
                            </>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
                </div>
                {bottomSpinner && (
                     <div className={`form-group`} style={{ background: "none", margin: "auto" }}>

                     <svg xmlns="http://www.w3.org/2000/svg"
                       xlink="http://www.w3.org/1999/xlink"
                       style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                       width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                       <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                         <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                           <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                             style={{ animationplaystate: "running", animationdelay: "0s" }}>
                           </animateTransform>
                         </circle>
                       </g>
                       <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                         <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                           <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                             style={{ animationplaystate: "running", animationdelay: "0s" }}>
                           </animateTransform>
                         </circle>
                       </g>
                       <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                         <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                           <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                             style={{ animationplaystate: "running", animationdelay: "0s" }}>

                           </animateTransform>
                         </circle>
                       </g>
                       <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                         <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                           <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                             style={{ animationplaystate: "running", animationdelay: "0s" }}>

                           </animateTransform>
                         </circle>
                       </g>
                     </svg>
                   </div>
                )}
              </>
            ) : (
              <EmptyLogs />
            )}
          </>
        ) : (
          ""
        )}
      </>
    );
  };

  const Spin = () => {
    return (
      <div className="newspinner log-spinner">
              <svg xmlns="http://www.w3.org/2000/svg" 
        xlink="http://www.w3.org/1999/xlink" 
        style={{margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s"}}
        width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <g transform="translate(20 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <circle cx="0" cy="0" r="6" fill="#e6261f" style={{animationplaystate: "running", animationdelay: "0s"}}>
          <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
          style={{animationplaystate: "running", animationdelay: "0s"}}>
          </animateTransform>
        </circle>
        </g>
        <g transform="translate(40 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <circle cx="0" cy="0" r="6" fill="#f7d038" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
        style={{animationplaystate: "running", animationdelay: "0s"}}>
        </animateTransform>
        </circle>
        </g>
        <g transform="translate(60 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <circle cx="0" cy="0" r="6" fill="#49da9a" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
        style={{animationplaystate: "running", animationdelay: "0s"}}>

        </animateTransform>
        </circle>
        </g>
        <g transform="translate(80 50)" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <circle cx="0" cy="0" r="6" fill="#4355db" style={{animationplaystate: "running", animationdelay: "0s"}}>
        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite" 
        style={{animationplaystate: "running", animationdelay: "0s"}}>

        </animateTransform>
        </circle>
        </g>
        </svg>
            </div>
    );
  };


  const handleRightPanelExpand = (value) => {

		setAppRightPanelExpand(value);
	  }

  return (
    <>
     
      <div className="d-md-block">
        <Header customclass={"header-logs"} />
      </div>
      
      <section className="dasboard_page mobile-display mt-0">
      <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
      <BottomScrollListener
        onBottom={(e) => handleContainerOnBottom()}
        offset={300}
      >
      {(scrollRef) => (
            <div
            ref={scrollRef}
      
      className={`right-panel-gap mob-log-padding ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`} 
       style={{
        overflow: openMobRangeFilter ? "hidden" : "",
         height: "calc(100vh - 60px)",
        overflowY: "auto",
        }}>
                             <div>
            <HeaderData />
            </div>
          <div
            className="log-main-page"
          >
           
            <div
              className="right_general"
              // style={{
              //   minHeight: "350px",
              //   margin: "0 auto",
              //   paddingBottom: 40,
              // }}
            >
              {isLoading ? <Spin /> : <TableData />}
            </div>
          </div>
          {/* {InnerFoot()} */}
        </div>)}
        </BottomScrollListener>
        <Modal
          isOpen={openFirebasePopUp}
          style={{ maxWidth: "600px", maxHeight: "350px" }}
          // className={this.props.className}
          centered
          dataclass="newnotification"
          toggle={handleFirebasePopUp}
        >
          <ModalHeader
            toggle={handleFirebasePopUp}
            className="secondnotificationmodal"
          >
            <div className="d-md-flex new-pus_notification_head align-items-center justify-content-between ">

              <div
                className="newcrossmodaltwo"
                onClick={handleFirebasePopUp}
              >
                <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                  <path
                    id="Union_18"
                    data-name="Union 18"
                    d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                    transform="translate(0.529 0.531)"
                    fill="#C8CEDB"
                    stroke="#C8CEDB"
                    strokeWidth="1.5"
                  />
                </svg>
              </div>
            </div>
          </ModalHeader>
          <ModalBody toggle={handleFirebasePopUp} className="secondnewpush-notification-body">
            <div className="">
              <div className="d-flex align-items-center justify-content-center mb-3">

                <img
                  src={
                    require("../../assets/images/dashboard/notificationmodal.png")
                      .default
                  }
                  className=""
                  alt="Goto"
                />
              </div>

            </div>
            <div className="secondmodal-texts">
              <h2>Access app crash insights</h2>

              <p className="pushnotification-longtext">Access realtime reporting about app crashes with detailed and actionable insights into errors that affect your app quality.</p>


              <div className="secondmodal-pushbutton">
                {
                  androidnotifyid !== null ?

                  <a target="_blank" href={`https://console.firebase.google.com/u/0/project/${androidnotifyid}/crashlytics`} className="pushfirstbutton">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.591" height="22" viewBox="0 0 18.591 22">
                      <path id="Path_7126" data-name="Path 7126" d="M1.376,15.116A1.3,1.3,0,0,1,0,13.912V8.761A1.3,1.3,0,0,1,1.376,7.556,1.3,1.3,0,0,1,2.753,8.761v5.151A1.3,1.3,0,0,1,1.376,15.116Zm10.411,5.037,1.2,1.2a.379.379,0,0,1-.537.536l-1.48-1.478a7.024,7.024,0,0,1-3.32,0L6.175,21.889a.379.379,0,1,1-.537-.536l1.2-1.193A4.85,4.85,0,0,1,3.45,15.8c0-.023,11.706,0,11.706,0A4.843,4.843,0,0,1,11.788,20.153ZM7.582,17.178a.687.687,0,1,0,.689.687A.687.687,0,0,0,7.582,17.178Zm3.444,0a.687.687,0,1,0,.689.687A.687.687,0,0,0,11.025,17.178Zm6.19-2.062a1.3,1.3,0,0,1-1.377-1.2V8.761a1.389,1.389,0,0,1,2.753,0v5.151A1.3,1.3,0,0,1,17.216,15.116ZM3.45,6.875a2.063,2.063,0,0,1,2.059-2.06V1.205A1.3,1.3,0,0,1,6.886,0,1.3,1.3,0,0,1,8.261,1.205V4.814H10.33V1.205A1.3,1.3,0,0,1,11.706,0a1.3,1.3,0,0,1,1.376,1.205V4.814h.009a2.063,2.063,0,0,1,2.066,2.06v8.243H3.451V6.875Z" transform="translate(18.591 22) rotate(180)" fill="#3ddb86" />
                    </svg>
                    <p>Android</p>
                    <img
                      src={
                        require("../../assets/images/dashboard/Goto.png")
                          .default
                      }
                      className="gotoappicon appear-spacing"
                      alt="Goto"
                    />
                  </a>
                  :   <a className="firebase_button_disable">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18.591" height="22" viewBox="0 0 18.591 22">
                                    <path id="Path_7126" data-name="Path 7126" d="M1.376,15.116A1.3,1.3,0,0,1,0,13.912V8.761A1.3,1.3,0,0,1,1.376,7.556,1.3,1.3,0,0,1,2.753,8.761v5.151A1.3,1.3,0,0,1,1.376,15.116Zm10.411,5.037,1.2,1.2a.379.379,0,0,1-.537.536l-1.48-1.478a7.024,7.024,0,0,1-3.32,0L6.175,21.889a.379.379,0,1,1-.537-.536l1.2-1.193A4.85,4.85,0,0,1,3.45,15.8c0-.023,11.706,0,11.706,0A4.843,4.843,0,0,1,11.788,20.153ZM7.582,17.178a.687.687,0,1,0,.689.687A.687.687,0,0,0,7.582,17.178Zm3.444,0a.687.687,0,1,0,.689.687A.687.687,0,0,0,11.025,17.178Zm6.19-2.062a1.3,1.3,0,0,1-1.377-1.2V8.761a1.389,1.389,0,0,1,2.753,0v5.151A1.3,1.3,0,0,1,17.216,15.116ZM3.45,6.875a2.063,2.063,0,0,1,2.059-2.06V1.205A1.3,1.3,0,0,1,6.886,0,1.3,1.3,0,0,1,8.261,1.205V4.814H10.33V1.205A1.3,1.3,0,0,1,11.706,0a1.3,1.3,0,0,1,1.376,1.205V4.814h.009a2.063,2.063,0,0,1,2.066,2.06v8.243H3.451V6.875Z" transform="translate(18.591 22) rotate(180)" fill="" />
                                  </svg>
                  <p>Android</p>
                
                </a> }
                {
                  iosnotifyid !== null ?

                  <a target="_blank" href={`https://console.firebase.google.com/u/0/project/${iosnotifyid}/crashlytics`} className="pushsecondbutton">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18.417" height="22" viewBox="0 0 18.417 22">
                      <g id="apple-logo" transform="translate(0)">
                        <g id="Group_1428" data-name="Group 1428" transform="translate(0 0)">
                          <path id="Path_668" data-name="Path 668" d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z" transform="translate(-38.692 -102.378)" fill="#666" />
                          <path id="Path_669" data-name="Path 669" d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z" transform="translate(-224.655 0)" fill="#666" />
                        </g>
                      </g>
                    </svg>
                    <p>iOS</p>
                    <img
                      src={
                        require("../../assets/images/dashboard/Goto.png")
                          .default
                      }
                      className="gotoappicon appear-spacing"
                      alt="Goto"
                    />

                  </a>
                  :  <a className="firebase_button_disable">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18.417" height="22" viewBox="0 0 18.417 22">
                                    <g id="apple-logo" transform="translate(0)">
                                      <g id="Group_1428" data-name="Group 1428" transform="translate(0 0)">
                                        <path id="Path_668" data-name="Path 668" d="M55.02,116.775a5.034,5.034,0,0,1-.912-2.948,4.711,4.711,0,0,1,.859-2.737,8.568,8.568,0,0,1,1.507-1.56,6.818,6.818,0,0,0-1.375-1.322,4.834,4.834,0,0,0-2.816-.859A7.461,7.461,0,0,0,50,107.8a6.621,6.621,0,0,1-1.891.45,8.505,8.505,0,0,1-1.825-.4,8.8,8.8,0,0,0-2.34-.4,4.564,4.564,0,0,0-3.755,1.9,7.844,7.844,0,0,0-1.494,4.945,13.554,13.554,0,0,0,1.944,6.65q1.97,3.424,3.979,3.424a4.779,4.779,0,0,0,1.745-.45,5.1,5.1,0,0,1,1.878-.436,5.846,5.846,0,0,1,2,.423,6.112,6.112,0,0,0,1.851.423q1.692,0,3.4-2.591a12.762,12.762,0,0,0,1.626-3.305A4.022,4.022,0,0,1,55.02,116.775Z" transform="translate(-38.692 -102.378)" fill="" />
                                        <path id="Path_669" data-name="Path 669" d="M236.488,4.178a5.427,5.427,0,0,0,1.23-1.824,5.044,5.044,0,0,0,.383-1.8c0-.035,0-.079-.007-.132s-.006-.1-.006-.132a1.28,1.28,0,0,1-.033-.145A1.224,1.224,0,0,0,238.021,0a5.269,5.269,0,0,0-3.305,1.957,6.03,6.03,0,0,0-1.031,3.4,7.388,7.388,0,0,0,1.375-.225A3.747,3.747,0,0,0,236.488,4.178Z" transform="translate(-224.655 0)" fill="" />
                                      </g>
                                    </g>
                                  </svg>
                  <p>iOS</p>
                 

                </a>}
              </div>
            </div>

          </ModalBody>

        </Modal>
      </section>
    </>
  );
};
export default Logs;
