import { createSlice } from "@reduxjs/toolkit";

// for android Rich & Plain notification build configs uploads & Login build settings file upload
const initialState = {
  isPlainConfig: "",
  isPlainSa: "",
  isLoginConfig: "",
};

export const buildConfigSlice = createSlice({
  name: "buildConfiguration",
  initialState,
  reducers: {
    setPlainConfig: (state, action) => {
      state.isPlainConfig = action.payload;
    },
    setPlainSa: (state, action) => {
      state.isPlainSa = action.payload;
    },
    setLoginConfig: (state, action) => {
      state.isLoginConfig = action.payload;
    },
  },
});

export const { setPlainConfig, setPlainSa, setLoginConfig } =
  buildConfigSlice.actions;

export const selectCount = (state) => state.counter.value;

export default buildConfigSlice.reducer;
