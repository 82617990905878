import axios from "axios";
import React from "react";
//import toast from "toasted-notes";
import S3 from "react-aws-s3";

import "toasted-notes/src/styles.css";
import "../../assets/css/newtoaster.css";

import { errortoast, sucesstoast } from "../../Dashboard/Toaster";

const Url = process.env.REACT_APP_API_URL;

const errorHandling = (error, state) => {
  if (error.response === undefined) {
    state.setState({
      plugincontent:
        "The code you entered is invalid. Please check your information and try again.",
    });
  } else if (error.response.status === 500) {
    state.setState({
      plugincontent:
        "The code you entered is not valid. Please check your information and try again.",
    });
  } else if (error.response.status === 404) {
    if (error.response.data.code === "rest_no_route") {
      state.setState({
        plugincontent:
          "The code you entered is not working. Please check your information and try again.",
      });
    } else {
      state.setState({
        plugincontent:
          "Invalid code. Please check your information and try again.",
      });
    }
  } else {
    state.setState({
      plugincontent:
        "Wrong code entered. Please check your information and try again.",
    });
  }
};

const Loader = (state) => {
  state.setState({
    submitval: "",
    loader: "loader",
  });
};

/*const sucesstoast = (message) => {
  toast.notify(
    <div className="toaster-layout">
      <div className="d-flex align-items-center h-100">
        <div className="d-flex">
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
            <g id="Group_38010" data-name="Group 38010" transform="translate(-731 -77)">
              <g id="Ellipse_7755" data-name="Ellipse 7755" transform="translate(731 77)" fill="none" stroke="#fff" stroke-width="1.5">
                <circle cx="11" cy="11" r="11" stroke="none"/>
                <circle cx="11" cy="11" r="10.25" fill="none"/>
              </g>
              <path id="Path_102372" data-name="Path 102372" d="M9.326.353l-5.8,5.813L.353,3" transform="translate(737.16 85.161)" fill="none" stroke="#fff" stroke-miterlimit="10" stroke-width="1.5"/>
            </g>
          </svg>
        </div>
        <div className="toaster-text-margin">          
          <p className="p-0 m-0 text-left toaster-text">{message}</p>
        </div>
      </div>
    </div>,

    {
      position: "top", // top-left, top, top-right, bottom-left, bottom, bottom-right
      duration: 6000, // This notification will not automatically close
    }
  );
};

const errortoast = (message, errormssg) => {
  const handleSeeMore = (e) => {
    let seeMoreButton = document.getElementById("seeMoreButton");
    let showMoreText = document.getElementById("showMoreText");
    if (showMoreText.style.display === "none") {
      showMoreText.style.display = "block";
      seeMoreButton.innerHTML = "See Less";
    } else {
      showMoreText.style.display = "none";
      seeMoreButton.innerHTML = "See More";
    }
  };
  toast.notify(
    <div className="red-toaster-layout">
      <div className="d-flex align-items-center h-100">
        <div className="d-flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
            <g id="Group_38010" data-name="Group 38010" transform="translate(-731 -157)">
              <g id="Ellipse_7750" data-name="Ellipse 7750" transform="translate(731 157)" fill="none" stroke="#fff" stroke-width="1.5">
                <circle cx="11" cy="11" r="11" stroke="none"/>
                <circle cx="11" cy="11" r="10.25" fill="none"/>
              </g>
              <path id="Path_102375" data-name="Path 102375" d="M811,145.263v6" transform="translate(-69 17.737)" fill="none" stroke="#fff" stroke-width="1.5"/>
              <path id="Path_102376" data-name="Path 102376" d="M811,145.263v2" transform="translate(-69 25.737)" fill="none" stroke="#fff" stroke-width="1.5"/>
            </g>
          </svg>
        </div>
        <div className="toaster-text-margin">
          <p className="p-0 m-0 text-left toaster-text">{message}</p>
          {errormssg === undefined || errormssg === null || errormssg === "" ? 
            (
              <></>
            ) 
          : 
            (
              <button id="seeMoreButton" className="see-more" onClick={(e) => handleSeeMore(e)} > See more </button>
            )
          }
        </div>
      </div>
      <p id="showMoreText" style={{ display: "none", transition: "all ease 1s" }} > {errormssg} </p>
    </div>,

    {
      position: "top", // top-left, top, top-right, bottom-left, bottom, bottom-right
      duration: 6000, // This notification will not automatically close
    }
  );
};*/

const profileloader = (state) => {
  state.setState({
    submitval: "REDEEM",
    loader: "d-none",
  });
};

export const Redeemget = (params, state) => {
  Loader(state);
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .post(Url + "api/redeem-licence", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 201) {
        profileloader(state);
        sucesstoast("Your deal has been activated.");
        setTimeout(function () {
          state.props.history.push("/deals");
        }, 5000);
      } else if (res.data.code === 501) {
        profileloader(state);
        errortoast("Your deal code has been used.");
      } else if (res.data.code === 500) {
        profileloader(state);
        errortoast("Your deal code is invalid.");
      } else if (res.data.code === 502) {
        profileloader(state);
        errortoast("You cannot redeem this purchase on this account.");
      } else {
        profileloader(state);
        errortoast("Something went wrong!");
      }
    });
};

// export const CreateConnection = (params, setLoader, setsubmit, context) => {

//     const headers = {
//       'Content-Type': 'application/json',
//       'Authorization': "Bearer " + localStorage.getItem("amstoken"),
//     }

//     axios.post(Url + "ios-publishing-data",params,{
//       headers: headers
//         }).then(res => {
//           if(res.data.code === 200){
//             setLoader("d-none");
//             setsubmit("Save");
//             context.setPopup(!context.opencreationpopup);
//             context.load()
//           }else{
//           }
//        }).catch(error => {

//           errortoast("Something went wrong. Try again after sometime.")
//     })

// }
// export const Redeemform = (params, setLoader, setsubmit, load, setsubmitreview, setsubmitreviewloader) => {
//   if(params.submit_for_review === 0){
//     setLoader("d-block");
//     setsubmit("");
//   }else{
//     setsubmitreviewloader("d-block");
//     setsubmitreview("");
//   }
//   const headers = {
//     'Content-Type': 'application/json',
//     'Authorization': "Bearer " + localStorage.getItem("amstoken"),
//   }

//   axios.post(Url + "ios-publishing-data",params,{
//     headers: headers
//       }).then(res => {
//         if(res.data.code === 200){

//           //hit get api
//           load(false);

//         }else{
//           if(params.submit_for_review === 0){
//             setLoader("d-none");
//             setsubmit("Submit");
//           }else{
//             setsubmitreviewloader("d-none");
//             setsubmitreview("Submit for review");
//           }

//         }
//      }).catch(error => {

//         errortoast("Something went wrong. Try again after sometime.")
//   })

// }

// export const Certificate= (params, setcertificates3name, setdisabledsubmitbutton) => {

//   function dataURLtoFileoriginal(dataurl, filename) {
//     var arr = dataurl.split(','),
//         //mime = arr[0].match(/:(.*?);/)[1],
//         bstr = atob(arr[1]),
//         n = bstr.length,
//         u8arr = new Uint8Array(n);

//     while(n--){
//         u8arr[n] = bstr.charCodeAt(n);
//     }
//     return new Blob([u8arr], { type: "application/p8" });
//   }
//   let originalfile = dataURLtoFileoriginal(params.original,'hello.p8');
//   const oriconfig = {
//     bucketName: process.env.REACT_APP_bucketName,
//     dirName: params.original_path.replace(/\/$/, ""), /* optional */
//     region: process.env.REACT_APP_region,
//     accessKeyId: process.env.REACT_APP_accessKeyId,
//     secretAccessKey: process.env.REACT_APP_secretAccessKey,
//     s3Url: process.env.REACT_APP_UPLOAD_URL?.replace(/\/$/, ""), /* optional */
//   }
//   const ReactS3original = new S3(oriconfig);
//   const originalFileName = params.imgname;
//   ReactS3original.uploadFile(originalfile,originalFileName).then(data => {
//         if(data.status === 204){
//           setcertificates3name(data.key.split("/").pop());
//           setdisabledsubmitbutton(false)
//         }
//   }
//   ).catch(err => {
//         errortoast("Something went wrong. Try again after sometime.")
//       })
// }
