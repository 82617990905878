import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import crossIcon from "../../../assets/images/dashboard/cross.png";

const ProductTechnologyModal = ({
  settingsState,
  handletypechangepopupclose,
  handletypechangepopupsubmit,
}) => {
  return (
    <div>
      <Modal
        isOpen={settingsState.changetypemodal}
       
        className=" productTechnologyModal technology-change-modal"
        centered
      >
        <ModalHeader>
          <div
            className="crossmodal mt-1"
            onClick={(e) => handletypechangepopupclose(e)}
          >
            <img src={crossIcon} alt="cross-icon" />
          </div>
        </ModalHeader>

        <ModalBody>
          <div className="productTechnologyModal-body">
            <h4 className="productTechnologyModal-body-head">
              Change product technology?
            </h4>
            <h6 className="productTechnologyModal-body-para">
              Changing your selected product technology will reset a majority of
              your app’s settings. We strongly recommend that you republish your
              apps to the app stores after making this change. Beware that your
              customers’ apps may experience temporary disruptions until they
              download the republished version of the app. This process can be
              intricate, so please proceed with caution.
            </h6>

            <div className="productTechnologyModal-footer">
              <div
                className="productTechnologyModal-cancel"
                onClick={(e) => handletypechangepopupclose(e)}
              >
                <p className="productTechnologyModal-cancel-text">Cancel</p>
              </div>

              <div
                className="productTechnologyModal-ok"
                onClick={(e) => handletypechangepopupsubmit(e)}
              >
                <p className="productTechnologyModal-ok-text">Proceed</p>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ProductTechnologyModal;
