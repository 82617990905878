import React, { Component, useEffect, useMemo, useRef } from "react";
import ReactDOM from "react-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";
import sliderfirstimg from "./../../assets/images/dashboard/home-slider.png";
import sliderbannerimg from "./../../assets/images/dashboard/cms/bannerimg.jpg";
// import htmlToImage from "html-to-image";
// import html2canvas from "html2canvas";
const ImageSlider = ({
  eachBlock,
  bannerLayout,
  setActiveTab,
  activeTab,
  blockIndx,
  homescreenData,
}) => {
  const captureRef = useRef(null);
  const sheetCreator = () => {
    eachBlock.value.main_banner &&
      eachBlock.value.main_banner.map((image, imageNo) => {
        if (!window["new" + blockIndx + "sheet" + imageNo]) {
          window["new" + blockIndx + "sheet" + imageNo] =
            document.createElement("style");
          document.body.appendChild(
            window["new" + blockIndx + "sheet" + imageNo]
          );
        }
      });
  };
  useMemo(() => sheetCreator(), [eachBlock.value.main_banner.length]);

  const changingSheetStyle = (imageNo) => {
    // window["new" + blockIndx + "sheet" + imageNo] =
    //   document.createElement("style");
    if (window["new" + blockIndx + "sheet" + imageNo]) {
      window["new" + blockIndx + "sheet" + imageNo].innerHTML =
        `.back${blockIndx}select${imageNo}::before {background:` +
        homescreenData[blockIndx].value.main_banner[imageNo].style.color +
        "}";
      // ";opacity:" +
      // 20% +
    }
  };

  eachBlock.value.main_banner &&
    eachBlock.value.main_banner.map((image, imageNo) => {
      changingSheetStyle(imageNo);
    });

  const handlesliderChange = (e) => {
    setActiveTab(e);
  };

  const calculateHeight = (width) => {
    let height = (width * 3) / 4;
    switch (bannerLayout.image_aspect_ratio) {
      case "4:3":
        height = (width * 3) / 4;
        break;
      case "3:4":
        height = (width * 4) / 3;
        break;
      case "1:1":
        height = (width * 1) / 1;
        break;
      case "16:9":
        height = (width * 9) / 16;
        break;
    }
    return height;
  };

  return (
    <Carousel
      selectedItem={eachBlock.homescreen_selected_image_index}
      onChange={(e) => handlesliderChange(e)}
      showArrows={false}
      swipeable={true}
      showThumbs={false}
    >
      {eachBlock.value.main_banner &&
        eachBlock.value.main_banner.map((imageUrl, indx) => {
          return (
            <>
              <div
                className={`homescreen-slide`}
                ref={captureRef}
                id={`clocksel${blockIndx}ABCD${indx}`}
                style={{
                  height: calculateHeight(296),
                }}
              >
                <div
                  className={`homescreen-screen image-cover-center backselect back${blockIndx}select${indx}`}
                  style={{
                    width: "100%",

                    background:
                      imageUrl.thumb_image !== "" &&
                      imageUrl.thumb_image !== undefined &&
                      imageUrl.thumb_image !== null
                        ? "url(" +
                          (imageUrl.thumb_image.indexOf("http") > -1 ||
                          imageUrl.thumb_image.indexOf("base64") > -1
                            ? imageUrl.thumb_image
                            : process.env.REACT_APP_Image_Path +
                              localStorage.getItem("user_id") +
                              "/" +
                              localStorage.getItem("Appid") +
                              process.env.REACT_APP_MAIN_BANNER_IMG +
                              imageUrl.thumb_image) +
                          ")"
                        : imageUrl.style.color,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                ></div>
                <div
                  className="homescreen-screen"
                  style={{
                    width: "100%",

                    // background: imageUrl.main_banner_solid_color,
                  }}
                >
                  <h4
                    style={{
                      fontFamily: imageUrl.style.font_family,
                      textDecoration:
                        imageUrl.style.is_underline === 1 ? "underline" : "",
                      fontStyle: imageUrl.style.is_italic === 1 ? "italic" : "",
                      fontWeight: imageUrl.style.is_bold === 1 ? "bold" : "",
                      fontSize: parseInt(imageUrl.style.font_size) + "px",
                      top:
                        imageUrl.style.vertical_text_position === "top"
                          ? "20px"
                          : imageUrl.style.vertical_text_position === "center"
                          ? "20px"
                          : null,
                      bottom:
                        imageUrl.style.vertical_text_position === "top"
                          ? imageUrl.style.vertical_text_position === "center"
                            ? "20px"
                            : null
                          : "20px",
                      right:
                        imageUrl.style.horizontal_text_position === "right"
                          ? "20px"
                          : imageUrl.style.horizontal_text_position === "center"
                          ? "20px"
                          : null,
                      left:
                        imageUrl.style.horizontal_text_position === "right"
                          ? imageUrl.style.horizontal_text_position === "center"
                            ? "20px"
                            : null
                          : "20px",
                      color: imageUrl.style.text_color,
                      textAlign: imageUrl.style.text_align,
                    }}
                  >
                    {imageUrl.style.text}
                  </h4>
                </div>
              </div>
              {/* Screenshot */}
              <div
                style={{
                  height: "1px",
                  width: "1px",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                <div
                  className={`homescreen-slide`}
                  // ref={captureRef}
                  id={`clocksel${blockIndx}Original${indx}`}
                  style={{
                    width: "1125px",
                    height: calculateHeight(1125),
                  }}
                >
                  <div
                    className={`homescreen-screen image-cover-center backselect back${blockIndx}select${indx}`}
                    style={{
                      background:
                        imageUrl.original_image !== "" &&
                        imageUrl.original_image !== undefined &&
                        imageUrl.original_image !== null
                          ? "url(" +
                            (imageUrl.original_image.indexOf("http") > -1 ||
                            imageUrl.original_image.indexOf("base64") > -1
                              ? imageUrl.original_image
                              : process.env.REACT_APP_Image_Path +
                                localStorage.getItem("user_id") +
                                "/" +
                                localStorage.getItem("Appid") +
                                process.env.REACT_APP_MAIN_BANNER_IMG +
                                imageUrl.original_image) +
                            ")"
                          : imageUrl.style.color,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  ></div>
                  <div
                    className="homescreen-screen"
                    style={{
                      width: "100%",

                      // background: imageUrl.main_banner_solid_color,
                    }}
                  >
                    <h4
                      style={{
                        fontFamily: imageUrl.style.font_family,
                        //1px= 3.80067567568px (1125/296)
                        fontSize:
                          parseInt(imageUrl.style.font_size) * 3.8 + "px",
                        top:
                          imageUrl.style.vertical_text_position === "top"
                            ? "76px"
                            : imageUrl.style.vertical_text_position === "center"
                            ? "76px"
                            : null,
                        bottom:
                          imageUrl.style.vertical_text_position === "top"
                            ? imageUrl.style.vertical_text_position === "center"
                              ? "76px"
                              : null
                            : "76px",
                        right:
                          imageUrl.style.horizontal_text_position === "right"
                            ? "76px"
                            : imageUrl.style.horizontal_text_position ===
                              "center"
                            ? "76px"
                            : null,
                        left:
                          imageUrl.style.horizontal_text_position === "right"
                            ? imageUrl.style.horizontal_text_position ===
                              "center"
                              ? "76px"
                              : null
                            : "76px",
                        color: imageUrl.style.text_color,
                        textAlign: imageUrl.style.text_align,
                        textDecoration:
                          imageUrl.style.is_underline === 1 ? "underline" : "",
                        fontStyle:
                          imageUrl.style.is_italic === 1 ? "italic" : "",
                        fontWeight: imageUrl.style.is_bold === 1 ? "bold" : "",
                      }}
                    >
                      {imageUrl.style.text}
                    </h4>
                  </div>
                </div>
              </div>

              {/* end screenshote */}
            </>
          );
        })}
    </Carousel>
  );
};
export default ImageSlider;
