import React from 'react'

const RequestAFeature = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
  >
    <g
      id="Group_41127"
      data-name="Group 41127"
      transform="translate(-1549 -510)"
    >
      <rect
        id="Rectangle_25573"
        data-name="Rectangle 25573"
        width="24"
        height="24"
        transform="translate(1549 510)"
        fill="none"
      />
      <g id="Group_35096" data-name="Group 35096">
        <line
          id="Line_351"
          data-name="Line 351"
          x2="7"
          transform="translate(1557.5 529.713)"
          fill="none"
          stroke="#1b2952"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <line
          id="Line_352"
          data-name="Line 352"
          y2="2"
          transform="translate(1561 510)"
          fill="none"
          stroke="#1b2952"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <line
          id="Line_353"
          data-name="Line 353"
          x2="1.414"
          y2="1.414"
          transform="translate(1552.293 512.793)"
          fill="none"
          stroke="#1b2952"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <line
          id="Line_354"
          data-name="Line 354"
          x1="1.414"
          y2="1.414"
          transform="translate(1568.293 512.793)"
          fill="none"
          stroke="#1b2952"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <line
          id="Line_355"
          data-name="Line 355"
          x2="2"
          transform="translate(1571 521)"
          fill="none"
          stroke="#1b2952"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <line
          id="Line_356"
          data-name="Line 356"
          x2="2"
          transform="translate(1549 521)"
          fill="none"
          stroke="#1b2952"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <line
          id="Line_357"
          data-name="Line 357"
          y1="6.801"
          transform="translate(1561.23 522.912)"
          fill="none"
          stroke="#1b2952"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_98198"
          data-name="Path 98198"
          d="M10.668,12l1.538,1.538L13.743,12"
          transform="translate(1549 510)"
          fill="none"
          stroke="#1b2952"
          stroke-miterlimit="10"
          stroke-width="1"
        />
        <path
          id="Path_98199"
          data-name="Path 98199"
          d="M12.151,4.81h0a7.562,7.562,0,0,1,.834.047,7.15,7.15,0,0,1,3.19,13.013,4.065,4.065,0,0,0-1.715,3.385v1.4a.344.344,0,0,1-.344.344H9.8a.344.344,0,0,1-.344-.344V20.974a4.169,4.169,0,0,0-1.625-3.312A7.151,7.151,0,0,1,12.151,4.81m0-1A8.151,8.151,0,0,0,7.231,18.459,3.15,3.15,0,0,1,8.46,20.974v1.682A1.344,1.344,0,0,0,9.8,24h4.312a1.344,1.344,0,0,0,1.344-1.344v-1.4a3.083,3.083,0,0,1,1.279-2.56A8.15,8.15,0,0,0,13.1,3.863a8.7,8.7,0,0,0-.947-.053"
          transform="translate(1549 510)"
          fill="#1b2952"
        />
      </g>
    </g>
  </svg>
  )
}

export default RequestAFeature