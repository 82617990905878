import React from 'react'

const Skip = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6.591" height="11.061" viewBox="0 0 6.591 11.061">
    <path id="Path_102421" data-name="Path 102421" d="M0,5,5,0l5,5" transform="translate(5.53 0.53) rotate(90)" fill="none" stroke="#7782a1" stroke-width="1.5" />
  </svg>
  )
}

export default Skip;