import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import crossIcon from "../../../assets/images/dashboard/cross.png";

const DeleteAppModal = ({
  settingsState,
  handleDeleteAppname,
  deleteapps,
  deleteAppDataLoading,
  deletapp,
}) => {
  return (
    <div>
      <Modal
        isOpen={settingsState.delete_app}
        toggle={deleteapps}
      
        className="confirm-org-popups confirm-app-delete-modal"
        centered
      >
        <ModalBody>
          <div className="crossmodal mt-1" onClick={deleteapps}>
            <img src={crossIcon} alt="cross-icon" />
          </div>

          <div>
            <div className="confirm-org-body">
              <h4>Delete app</h4>
              <h5>
                Are you sure you want to proceed? Deleting your app is permanent
                and cannot be undone. This action will remove your app and all
                associated data.
              </h5>

              <h6>Please type in the app name to confirm.</h6>
              <input
                type="text"
                id="orgnameconfirm"
                name="orgnameconfirm"
                placeholder="App name"
                maxLength="30"
                minLength="3"
                autoComplete="off"
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
                value={settingsState.deleteappname}
                onChange={(e) => handleDeleteAppname(e)}
              />
            </div>

            <div className="confirm-org-footer">
              <button
                className="confirm-org-footer-button-cancel"
                onClick={deleteapps}
              >
                Cancel
              </button>
              <button
                className="confirm-org-footer-button-delete"
                id="btndeleteorgid"
                onClick={(e) => deletapp(e, settingsState.appnameid)}
                disabled={deleteAppDataLoading}
              >
                {deleteAppDataLoading &&
                window.matchMedia("(max-width: 1200px)").matches ? (
                  <div className="d-flex align-items-center justify-content-center h-100 w-100">
                    <div className="new-loader"></div>
                  </div>
                ) : deleteAppDataLoading ? (
                  <div className="new-red-loader"></div>
                ) : (
                  "Delete"
                )}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DeleteAppModal;
