import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import crossIcon from "../../../assets/images/dashboard/cross.png";

const TransferModal = ({
  settingsState,
  photoggle,
  updateTransfer,
  moveAppDataLoading,
}) => {
  return (
    <div>
      <Modal
        isOpen={settingsState.transfermodal}
        
        className="generalstngs2 transfer-modal-div"
        centered
      >
        <ModalHeader>
          <div className="crossmodal mt-1" onClick={(e) => photoggle(e, "")}>
            <img src={crossIcon} alt="cross-icon" />
          </div>
        </ModalHeader>

        <ModalBody>
          <div>
            <h4 className="move-modal-confirm-head">Confirm move?</h4>
            <h6 className="move-modal-confirm-para">
              Are you sure you want to move this app to the selected
              subscription?
            </h6>

            <div className="generalstngs2-footer">
              <div
                className="generalstngs2-cancel"
                onClick={(e) => photoggle(e, "")}
              >
                <p className="btn-text">Cancel</p>
              </div>

              <button
                className="generalstngs2-ok"
                onClick={updateTransfer}
                disabled={moveAppDataLoading}
              >
                {moveAppDataLoading ? (
                  <div className="new-loader"></div>
                ) : (
                  <p className="btn-text">{"Move"}</p>
                )}
              </button>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default TransferModal;
