import React, { useState, useEffect, useReducer } from "react";
import Header from "../../../../Dashboard/Header";
import Leftpanel from "../../../../Dashboard/Leftpanel";
import "./ProductListing.css";
import ModuleTitleTooltip from "../../../../Components/commonIcons/ModuleTitleTooltip";


const ProductListing = () => {
  const [showData, setshowData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      appRightPanelExpand:
        localStorage.getItem("appSideBarCollapse") === true ||
        localStorage.getItem("appSideBarCollapse") === "true"
          ? true
          : false,
    }
  );

  const handleRightPanelExpand = (value) => {
    setshowData({ appRightPanelExpand: value });
  };




  return (
    <>
      <Header customclass={"header-responsive-new"} />
      <section className="create-product">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
          
            <div
              className={`right-panel-gap ${
                localStorage.getItem("appSideBarCollapse") &&
                localStorage.getItem("appSideBarCollapse") === "true"
                  ? " right-panel-gap-expand"
                  : ""
              }`}
            >
              <ModuleTitleTooltip
                title={"Customers"}
                text={"Add and manage customer information in one place."}
              />
              

            </div>

      </section>
    </>
  );
};

export default ProductListing;
