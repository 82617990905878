import React from "react";
import AndroidGreenIcon from "../../../../src/assets/svg/androidGreenIcon.svg";
import AndroidGreyIcon from "../../../../src/assets/svg/androidGreyIcon.svg";
import iOSGreenIcon from "../../../../src/assets/svg/iOSGreenIcon.svg";
import iOSGreyIcon from "../../../../src/assets/svg/iOSGreyIcon.svg";

const DownloadCard = ({
  componentFrom,
  build,
  build_version,
  build_info,
  latest,
  downloadbuild,
}) => {
  let count = 0;
  let updatecount = 0;

  // Update version condition

  const versioniosCompare = (v1, v2, options) => {
    var lexicographical = options && options.lexicographical,
      zeroExtend = options && options.zeroExtend,
      v1parts = v1.split("."),
      v2parts = v2.split(".");

    function isValidPart(x) {
      return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
      return NaN;
    }

    if (zeroExtend) {
      while (v1parts.length < v2parts.length) v1parts.push("0");
      while (v2parts.length < v1parts.length) v2parts.push("0");
    }

    if (!lexicographical) {
      v1parts = v1parts.map(Number);
      v2parts = v2parts.map(Number);
    }

    for (var i = 0; i < v1parts.length; ++i) {
      if (v2parts.length === i) {
        return 1;
      }

      if (v1parts[i] === v2parts[i]) {
        continue;
      } else if (v1parts[i] > v2parts[i]) {
        return ++updatecount;
      }
    }

    if (v1parts.length !== v2parts.length) {
      return <></>;
    }
  };
  {
    build !== null && latest && versioniosCompare(latest, build);
  }

  // Compare android version

  const versionCompare = (v1, v2, options) => {
    var lexicographical = options && options.lexicographical,
      zeroExtend = options && options.zeroExtend,
      v1parts = v1.split("."),
      v2parts = v2.split(".");

    function isValidPart(x) {
      return (lexicographical ? /^\d+[A-Za-z]*$/ : /^\d+$/).test(x);
    }

    if (!v1parts.every(isValidPart) || !v2parts.every(isValidPart)) {
      return NaN;
    }

    if (zeroExtend) {
      while (v1parts.length < v2parts.length) v1parts.push("0");
      while (v2parts.length < v1parts.length) v2parts.push("0");
    }

    if (!lexicographical) {
      v1parts = v1parts.map(Number);
      v2parts = v2parts.map(Number);
    }

    for (var i = 0; i < v1parts.length; ++i) {
      {
      }
      if (v2parts.length === i) {
        return 1;
      }

      if (v1parts[i] === v2parts[i]) {
        continue;
      } else if (v1parts[i] > v2parts[i]) {
        return ++updatecount;
      }
    }

    if (v1parts.length !== v2parts.length) {
      return <></>;
    }
  };

  if (build !== null && latest !== null) {
    versionCompare(latest, build);
  }

  const BuildStatus = () => {
    let buildStatus = "Draft";
    if (build_info?.app_in_process === 1) {
      buildStatus = "Build processing";
    } else {
      if (
        parseInt(build_info?.build_status) === 0 ||
        parseInt(build_info?.build_status) === 3
      ) {
        buildStatus = "Draft";
      } else if (
        parseInt(build_info?.build_status) === 1 ||
        parseInt(build_info?.build_status) === 4
      ) {
        buildStatus = "Build completed";
      } else if (parseInt(build_info?.build_status) === 2) {
        buildStatus = "Build failed";
      }
    }
    return buildStatus;
  };

  const classNameForBuildStatus = () => {
    let className = "dasboard-build-draft";
    if (build_info?.app_in_process === 1) {
      className = "dasboard-build-draft";
    } else {
      if (
        parseInt(build_info?.build_status) === 0 ||
        parseInt(build_info?.build_status) === 3
      ) {
        className = "dasboard-build-draft";
      } else if (
        parseInt(build_info?.build_status) === 1 ||
        parseInt(build_info?.build_status) === 4
      ) {
        className = "dasboard-build-complete";
      } else if (parseInt(build_info?.build_status) === 2) {
        className = "dasboard-build-failed";
      }
    }
    return className;
  };

  const BuildStatusText = () => {
    let buildText =
      componentFrom === "Android"
        ? "Build your Android app and launch it on the Play Store"
        : "Build your iOS app and launch it on the App Store";
    if (build_info?.app_in_process === 1) {
      buildText = "";
    } else {
      if (
        parseInt(build_info?.build_status) === 0 ||
        parseInt(build_info?.build_status) === 3
      ) {
        if (componentFrom === "Android") {
          buildText = "Build your Android app and launch it on the Play Store";
        } else {
          buildText = "Build your iOS app and launch it on the App Store";
        }
      } else if (
        parseInt(build_info?.build_status) === 1 ||
        parseInt(build_info?.build_status) === 4
      ) {
        buildText = "Your last build was successful";
      } else if (parseInt(build_info?.build_status) === 2) {
        buildText = "Your last build failed";
      }
    }
    return buildText;
  };

  return (
    <>
      <div className="app-build-info">
        <div className="dashboard-app-build-left">
          <div className="dashboard-app-build-icon">
            {componentFrom === "Android" ? (
              <>
                {build_version ? (
                  <img src={AndroidGreenIcon} alt="android-green-icon" />
                ) : (
                  <img src={AndroidGreyIcon} alt="android-grey-icon" />
                )}
              </>
            ) : (
              <>
                {build_version ? (
                  <img src={iOSGreenIcon} alt="ios-green-icon" />
                ) : (
                  <img src={iOSGreyIcon} alt="ios-grey-icon" />
                )}
              </>
            )}
          </div>

          <div>
            <h3 className="build-info-left-one">
              {componentFrom} {build_version || ""}
            </h3>

            {BuildStatusText() ? (
              <h5 className="build-info-left-two">{BuildStatusText()}</h5>
            ) : null}
            {build_version && latest && versionCompare(latest, build) == 1 && (
              <h6 className="version-available-text">New version available</h6>
            )}
          </div>
        </div>

        <div>
          <h4
            className={`build-info-left-status  ${classNameForBuildStatus()}`}
          >
            {BuildStatus()}
          </h4>
        </div>
      </div>
    </>
  );
};

export default DownloadCard;
