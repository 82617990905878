import React from "react";
import "../../assets/css/Dashboard.css";
import "../../assets/css/Splash.css";
import Header from "../Header";
import Leftpanel from "../Leftpanel";
import { LambdaSplash } from "./LambdaSplash";
import { Foot, InnerFoot } from "../../Dashboard/Foot";
import ModalDemoApp from "../../Dashboard/Svg";
import "./appPreview.css";
import { Errorstatuscon } from "./Context";
import styles from "../Appdownload/Downloadapp.module.css";

import {
  Spinner,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
export default class Lambda extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: true,
      modal: false,
      protip: false,
      connectivity_data: null,
      is_connected: null,
      is_verified: null,
      website_type: null,
      simulator_status: null,
      appRightPanelExpand: localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
        ? true
        : false,
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    document.title = "Lambda Preview";
  }
  handlefun = (e) => {
    this.setState({
      error: e,
    });
  };
  handleresult = (
    e,
    is_verified,
    is_connected,
    website_type,
    simulator_status
  ) => {
    this.setState({
      connectivity_data: e,
      is_verified: is_verified,
      is_connected: is_connected,
      website_type: website_type,
      simulator_status: simulator_status,
    });
  };
  toggle = () => {
    this.setState({ modal: !this.state.modal });
    this.setState({
      copiedLeft: false,
      copiedRight: false,
    });
  }
  protip = () => {
    this.setState({ mdlprotip: !this.state.mdlprotip });
  }

  handleBtnDone =() =>
  {
    this.setState({ mdlprotip: false });
  }
  handleRightPanelExpand = (value) => {

    this.setState({
      appRightPanelExpand: value
    })
    }
    copyios = () => {
      this.setState({
        copiedLeft: true,
        copiedRight: false,
      });
      const el = document.createElement("input");
      if(localStorage.getItem("website_technology") == 3)
      {
        el.value = "https://testflight.apple.com/join/DKgriDVM";
      }
      else if(localStorage.getItem("website_technology") == 2)
      {
        el.value = "https://testflight.apple.com/join/1cvKgAXc";
      }
      else if(localStorage.getItem("website_technology") == 4)
      {
        el.value = "https://testflight.apple.com/join/OLPs2xOB";
      }
      else if(localStorage.getItem("website_technology") == 5)
      {
        el.value = "https://testflight.apple.com/join/cq3qO8y7"
      }  
      else
      {
        el.value = "";
      }                  
      // el.value = "https://testflight.apple.com/join/I7Xx3gMA";
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    };
    copygplay = () => {
      this.setState({
        copiedRight: true,
        copiedLeft: false,
      });
      const el = document.createElement("input");
      // el.value =
      //   "https://play.google.com/store/apps/details?id=com.appmysite.shop";
      if(localStorage.getItem("website_technology") == 3)
      {
        el.value = "https://play.google.com/store/apps/details?id=com.appmysite.custom";
      }
      else if(localStorage.getItem("website_technology") == 2 )
      {
        el.value = "https://play.google.com/store/apps/details?id=com.appmysite.web";
      }
      else if(localStorage.getItem("website_technology") == 4)
      {
        el.value = "https://play.google.com/store/apps/details?id=com.appmysite.wordpress";
      }
      else if(localStorage.getItem("website_technology") == 5)
      {
        el.value = "https://play.google.com/store/apps/details?id=com.appmysite.shop";
      }
      else 
      {
        el.value = "";
      }
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    };
    
  render() {
    const handleerrorstatus = {
      state: this.state,
      handlefunerror: this.handlefun,
      handleresult: this.handleresult,
    };
    return (
      <>
        <div className="d-md-block">
          <Header customclass={"header-responsive-new"} />
        </div>
        
        {/*Mobile View*/}
        {/*Mobile View*/}
        <section className="dasboard_page mobile-display mobile-view-preview previewapp">
       
        <Leftpanel handleRightPanelExpand={this.handleRightPanelExpand} />
        <div className={`right-panel-gap ${(localStorage.getItem("appSideBarCollapse") !== undefined &&
                      localStorage.getItem("appSideBarCollapse") !== null&&
                      localStorage.getItem("appSideBarCollapse") === "true") ?
                          " right-panel-gap-expand": ""}`}>
              {  ((this.state.is_verified === 0 || this.state.is_connected === 0) && parseInt(localStorage.getItem("website_technology")) === 5) 
              || ((this.state.is_verified === 0) && parseInt(localStorage.getItem("website_technology")) === 4)
              ?
                    <></>
              : null
              }
              <div className="preview-main">
                <div className="d-flex">
                          <h1 className="preview-head">Preview<p className="bottombar-tooltip">
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                              <g id="Group_35001" data-name="Group 35001" transform="translate(-1727 -19)">
                                <g id="Ellipse_7706" data-name="Ellipse 7706" transform="translate(1727 19)" fill="none" stroke="#bcc1ce" stroke-width="1.5">
                                  <circle cx="9" cy="9" r="9" stroke="none" />
                                  <circle cx="9" cy="9" r="8.25" fill="none" />
                                </g>
                                <line id="Line_343" data-name="Line 343" y1="2" transform="translate(1736 23)" fill="none" stroke="#bcc1ce" stroke-miterlimit="10" stroke-width="1.5" />
                                <path id="Path_98224" data-name="Path 98224" d="M0,2V-4" transform="translate(1736 31)" fill="none" stroke="#bcc1ce" stroke-width="1.5" />
                              </g>
                            </svg>
                            <p className="bottombar-tooltipsubheader">
                            Test your app to see how it functions in a simulated environment. Great for checking the overall app flow and functionality before publishing.
                            </p>
                          </p></h1>
                          {this.state.simulator_status !== undefined &&
                           this.state.simulator_status !== null && (
                            <div className="previewstatus">
                          <p  className={styles.builder_status +" " +styles.preview_status_alignment}><span 
                              className={styles.operational} style={{color: this.state.simulator_status.colour}}>{this.state.simulator_status.title}</span></p>
                              </div>
                           )}

                          </div>
                          {this.state.simulator_status !== undefined &&
                           this.state.simulator_status !== null && (
                            <>
                            
                              <div className="review-headright ">
                              {parseInt(localStorage.getItem("agencyid")) == 1 ?
                                    <button 
                                    
                                    className='save-preview-btn' onClick={()=> this.toggle()}
                                    style={{
                                      backgroundColor:localStorage.getItem('button_background_color'),
                                      color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
                                    }}
                                    >Test app on real device</button>
                              : <></>
                              }
                                    <div className="preview-mob-spark-protip">
                                    <div className="app-icon-spark">
              <svg  onClick={()=> this.protip()} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                <g id="Group_37044" data-name="Group 37044" transform="translate(-351 -714.999)">
                  <g id="Group_37045" data-name="Group 37045" transform="translate(351 715)">
                    <path id="_211696_bolt_icon_2_" data-name="211696_bolt_icon (2)" d="M150.3,64,143,74.245h4.382l-1.349,7.318,7.3-10.245h-4.382L150.3,64Z" transform="translate(-135.562 -59.883)" fill=""/>
                    <g id="Ellipse_7748" data-name="Ellipse 7748" transform="translate(0 -0.001)" fill="none" stroke="" stroke-width="1.5">
                      <circle cx="12.5" cy="12.5" r="12.5" stroke="none"/>
                      <circle cx="12.5" cy="12.5" r="11.75" fill="none"/>
                    </g>
                  </g>
                </g>
              </svg>
              <p className="protiptooltip">Pro tip</p>
              </div>
              </div>
                              </div>
                            
                           
                                {this.state.simulator_status !== null &&
                                  this.state.simulator_status.description !== "" &&
                                  parseInt(this.state.simulator_status.status) !== 0 && (
                                    <div className="w-100 mt-2">
                                      <p
                                        className={
                                          styles.description + " p-0 m-0  mr-4 mr-md-0 text-left"
                                        }
                                        style={{
                                          color: this.state.simulator_status.colour,
                                        }}
                                      >
                                        {this.state.simulator_status.description}
                                      </p>
                                    </div>
                                  )}
                              </>
                            )}

              </div>
            
            {parseInt(localStorage.getItem("agencyid")) == 1 ?
              <button className='mobsave-preview-btn'
               style={{
                backgroundColor:localStorage.getItem('button_background_color'),border:'1px solid '+localStorage.getItem('button_background_color'),color:localStorage.getItem('button_text_color'),"--custom_color5": localStorage.getItem('agencyid') == 1 ? "#134bec" : localStorage.getItem('button_hover_bgcolor'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor'),"--custom_color6": localStorage.getItem('agencyid') == 1 ? "#3064f9" : localStorage.getItem('button_hover_bgcolor'),
              }}
              onClick={()=> this.toggle()}>Test app on real device</button>
            : <></>
            }

            <Errorstatuscon.Provider value={handleerrorstatus}>
              <div className="preview-main-page">

                <div className="app-icon-spark">
              <svg  onClick={()=> this.protip()} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
                <g id="Group_37044" data-name="Group 37044" transform="translate(-351 -714.999)">
                  <g id="Group_37045" data-name="Group 37045" transform="translate(351 715)">
                    <path id="_211696_bolt_icon_2_" data-name="211696_bolt_icon (2)" d="M150.3,64,143,74.245h4.382l-1.349,7.318,7.3-10.245h-4.382L150.3,64Z" transform="translate(-135.562 -59.883)" fill=""/>
                    <g id="Ellipse_7748" data-name="Ellipse 7748" transform="translate(0 -0.001)" fill="none" stroke="" stroke-width="1.5">
                      <circle cx="12.5" cy="12.5" r="12.5" stroke="none"/>
                      <circle cx="12.5" cy="12.5" r="11.75" fill="none"/>
                    </g>
                  </g>
                </g>
              </svg>
              <p className="protiptooltip">Pro tip</p>
              </div>
                {(
                  ((parseInt(localStorage.getItem("website_technology")) === 4 ||
                  parseInt(localStorage.getItem("website_technology")) === 5)) ||
                  parseInt(localStorage.getItem("website_technology")) === 2 ||
                  parseInt(localStorage.getItem("website_technology")) === 3) && (
                  // <div className="d-none d-md-block">
                  <div >
                    {/* <ModalDemoApp /> */}
                  </div>
                )}
                <div className="">
                  <div
                    className={
                      ((parseInt(localStorage.getItem("website_technology")) ===4 || parseInt(localStorage.getItem("website_technology")) ===5)) 
                        ||
                      parseInt(localStorage.getItem("website_technology")) === 2 
                        ||
                      parseInt(localStorage.getItem("website_technology")) === 3
                        ? "rightsidepreview"
                        : "w-100"
                    }
                  >
                    <LambdaSplash />
                  </div>
                </div>
                
              </div>
            </Errorstatuscon.Provider>
            <Modal
            isOpen={this.state.modal}
            fade={false}
            toggle={()=> this.toggle()}
            className={
              "modal-dialog-centered modal-dialog-scrollable apppreview-testmodal-dialog"
            }
          >
            <ModalHeader toggle={()=> this.toggle()} className={"testmodal-header"}>
             
            </ModalHeader>
            <ModalBody>
              <div className="preview-test-header">
                <h5>Download the app</h5>
                <p>
                Test your app on a real device before publishing on the app stores. 
                </p>
              </div>
             
              <div className=" justify-content-around align-items-center preview-qrcode">
                <div className="preview-apple-qr">
                 
                  { localStorage.getItem("website_technology") == 3 
                  ? 
                      <img
                      src={
                        require("../../assets/images/dashboard/download/customios.png")
                          .default
                      }
                      className="preview-apple-qr-code"
                      alt="gplay-store-icon"
                    ></img>
                  : localStorage.getItem("website_technology") == 2 
                  ? 
                      <img
                      src={
                        require("../../assets/images/dashboard/download/webtoappios.png")
                          .default
                      }
                      className="preview-apple-qr-code"
                      alt="gplay-store-icon"
                    ></img>
                  : localStorage.getItem("website_technology") == 4 
                  ?  <img
                        src={
                          require("../../assets/images/dashboard/download/wordpressios.png")
                            .default
                        }
                        className="preview-apple-qr-code"
                        alt="gplay-store-icon"
                      ></img>
                  : localStorage.getItem("website_technology") == 5 
                  ?  <img
                        src={
                          require("../../assets/images/dashboard/download/woocomerceios.png")
                            .default
                        }
                        className="preview-apple-qr-code"
                        alt="gplay-store-icon"
                      ></img>
                        : ""
                  }
                </div>
                <div className="preview-gplay-qr">
                { localStorage.getItem("website_technology") == 3 
                                                    ? 
                                                    <img
                                                    src={
                                                      require("../../assets/images/dashboard/download/customandroid.png")
                                                        .default
                                                    }
                                                    className="preview-gplay-qr-code"
                                                    alt="gplay-store-icon"
                                                  ></img>
                                                    : localStorage.getItem("website_technology") == 2 
                                                    ? 
                                                    <img
                                                    src={
                                                      require("../../assets/images/dashboard/download/webtoappandroid.png")
                                                        .default
                                                    }
                                                    className="preview-gplay-qr-code"
                                                    alt="gplay-store-icon"
                                                  ></img>
                                                    : localStorage.getItem("website_technology") == 4 
                                                    ?  <img
                                                    src={
                                                      require("../../assets/images/dashboard/download/wordpressandroid.png")
                                                        .default
                                                    }
                                                    className="preview-gplay-qr-code"
                                                    alt="gplay-store-icon"
                                                  ></img>
                                                    : localStorage.getItem("website_technology") == 5 
                                                    ?  <img
                                                    src={
                                                      require("../../assets/images/dashboard/download/woocomerceandroid.png")
                                                        .default
                                                    }
                                                    className="preview-gplay-qr-code"
                                                    alt="gplay-store-icon"
                                                  ></img>
                                                    : ""
                                                    }
                </div>
              </div>
              <div className="  justify-content-around align-items-center preview-qrcode">
                <div className="preview-left-link-copy">
                  <button className="preview-btn" onClick={this.copyios}>
                    {!this.state.copiedLeft ? (
                      <>
                        {" "}
                        {/* <img
                          className="ml-2 copy-redirect-link"
                          src={
                            require("../../assets/images/dashboard/config/build/copy.png")
                              .default
                          }
                          alt="copy"
                        /> */}
                        <svg id="Group_26180" data-name="Group 26180" xmlns="http://www.w3.org/2000/svg" width="14.001" height="14" viewBox="0 0 14.001 14">
  <g id="Path_9276" data-name="Path 9276" transform="translate(-450.5 -124.5)" fill="none" stroke-miterlimit="10">
    <path d="M461.5,125.923V128.5h-5.72a1.189,1.189,0,0,0-1.28,1.28v5.72h-2.577a1.419,1.419,0,0,1-1.423-1.423v-8.154a1.419,1.419,0,0,1,1.423-1.423h8.154A1.419,1.419,0,0,1,461.5,125.923Z" stroke="none"/>
    <path d="M 451.9228515625 125.5000152587891 C 451.6857299804688 125.5000152587891 451.5 125.6857452392578 451.5 125.9228668212891 L 451.5 134.0771636962891 C 451.5 134.3142700195312 451.6857299804688 134.5 451.9228515625 134.5 L 453.5 134.5 L 453.5 129.7800140380859 C 453.5 128.4375762939453 454.4375610351562 127.5 455.7799682617188 127.5 L 460.5 127.5 L 460.5 125.9228668212891 C 460.5 125.6857452392578 460.3142395019531 125.5000152587891 460.0771179199219 125.5000152587891 L 451.9228515625 125.5000152587891 M 451.9228515625 124.5000152587891 L 460.0771179199219 124.5000152587891 C 460.8656616210938 124.5000152587891 461.5 125.1343078613281 461.5 125.9228668212891 L 461.5 128.5 L 455.7799682617188 128.5 C 454.9913940429688 128.5 454.5 128.991455078125 454.5 129.7800140380859 L 454.5 135.5 L 451.9228515625 135.5 C 451.13427734375 135.5 450.5 134.8657531738281 450.5 134.0771636962891 L 450.5 125.9228668212891 C 450.5 125.1343078613281 451.13427734375 124.5000152587891 451.9228515625 124.5000152587891 Z" stroke="none" fill="#bcc1ce"/>
  </g>
  <g id="Path_9277" data-name="Path 9277" transform="translate(-452.499 -126.5)" fill="none" stroke-miterlimit="10">
    <path d="M466.5,130.923v8.154a1.419,1.419,0,0,1-1.423,1.423h-8.154a1.419,1.419,0,0,1-1.423-1.423v-8.154a1.419,1.419,0,0,1,1.423-1.423h8.154A1.419,1.419,0,0,1,466.5,130.923Z" stroke="none"/>
    <path d="M 456.9228210449219 130.5000152587891 C 456.6856994628906 130.5000152587891 456.4999694824219 130.6857452392578 456.4999694824219 130.9228515625 L 456.4999694824219 139.0771484375 C 456.4999694824219 139.3142700195312 456.6856994628906 139.5000152587891 456.9228210449219 139.5000152587891 L 465.0771179199219 139.5000152587891 C 465.3142395019531 139.5000152587891 465.4999694824219 139.3142700195312 465.4999694824219 139.0771484375 L 465.4999694824219 130.9228515625 C 465.4999694824219 130.6857452392578 465.3142395019531 130.5000152587891 465.0771179199219 130.5000152587891 L 456.9228210449219 130.5000152587891 M 456.9228210449219 129.5000152587891 L 465.0771179199219 129.5000152587891 C 465.8656921386719 129.5000152587891 466.4999694824219 130.1342926025391 466.4999694824219 130.9228515625 L 466.4999694824219 139.0771484375 C 466.4999694824219 139.86572265625 465.8656921386719 140.5000152587891 465.0771179199219 140.5000152587891 L 456.9228210449219 140.5000152587891 C 456.1342163085938 140.5000152587891 455.4999694824219 139.86572265625 455.4999694824219 139.0771484375 L 455.4999694824219 130.9228515625 C 455.4999694824219 130.1342926025391 456.1342163085938 129.5000152587891 456.9228210449219 129.5000152587891 Z" stroke="none" fill="#bcc1ce"/>
  </g>
</svg>
                        <p className="preview-copy-redirect-linktext">Copy link</p>{" "}
                        {" "}
                      </>
                    ) : (
                      <p className="preview-copy-redirect-linktext">Copied!</p>
                    )}
                  </button>
                </div>
                <div className="preview-right-link-copy">
                  <button className="preview-btn" onClick={this.copygplay}>
                    {!this.state.copiedRight ? (
                      <>
                       {/* <img
                          className="ml-2 copy-redirect-link"
                          src={
                            require("../../assets/images/dashboard/config/build/copy.png")
                              .default
                          }
                          alt="copy"
                        /> */}
                        <svg id="Group_26180" data-name="Group 26180" xmlns="http://www.w3.org/2000/svg" width="14.001" height="14" viewBox="0 0 14.001 14">
  <g id="Path_9276" data-name="Path 9276" transform="translate(-450.5 -124.5)" fill="none" stroke-miterlimit="10">
    <path d="M461.5,125.923V128.5h-5.72a1.189,1.189,0,0,0-1.28,1.28v5.72h-2.577a1.419,1.419,0,0,1-1.423-1.423v-8.154a1.419,1.419,0,0,1,1.423-1.423h8.154A1.419,1.419,0,0,1,461.5,125.923Z" stroke="none"/>
    <path d="M 451.9228515625 125.5000152587891 C 451.6857299804688 125.5000152587891 451.5 125.6857452392578 451.5 125.9228668212891 L 451.5 134.0771636962891 C 451.5 134.3142700195312 451.6857299804688 134.5 451.9228515625 134.5 L 453.5 134.5 L 453.5 129.7800140380859 C 453.5 128.4375762939453 454.4375610351562 127.5 455.7799682617188 127.5 L 460.5 127.5 L 460.5 125.9228668212891 C 460.5 125.6857452392578 460.3142395019531 125.5000152587891 460.0771179199219 125.5000152587891 L 451.9228515625 125.5000152587891 M 451.9228515625 124.5000152587891 L 460.0771179199219 124.5000152587891 C 460.8656616210938 124.5000152587891 461.5 125.1343078613281 461.5 125.9228668212891 L 461.5 128.5 L 455.7799682617188 128.5 C 454.9913940429688 128.5 454.5 128.991455078125 454.5 129.7800140380859 L 454.5 135.5 L 451.9228515625 135.5 C 451.13427734375 135.5 450.5 134.8657531738281 450.5 134.0771636962891 L 450.5 125.9228668212891 C 450.5 125.1343078613281 451.13427734375 124.5000152587891 451.9228515625 124.5000152587891 Z" stroke="none" fill="#bcc1ce"/>
  </g>
  <g id="Path_9277" data-name="Path 9277" transform="translate(-452.499 -126.5)" fill="none" stroke-miterlimit="10">
    <path d="M466.5,130.923v8.154a1.419,1.419,0,0,1-1.423,1.423h-8.154a1.419,1.419,0,0,1-1.423-1.423v-8.154a1.419,1.419,0,0,1,1.423-1.423h8.154A1.419,1.419,0,0,1,466.5,130.923Z" stroke="none"/>
    <path d="M 456.9228210449219 130.5000152587891 C 456.6856994628906 130.5000152587891 456.4999694824219 130.6857452392578 456.4999694824219 130.9228515625 L 456.4999694824219 139.0771484375 C 456.4999694824219 139.3142700195312 456.6856994628906 139.5000152587891 456.9228210449219 139.5000152587891 L 465.0771179199219 139.5000152587891 C 465.3142395019531 139.5000152587891 465.4999694824219 139.3142700195312 465.4999694824219 139.0771484375 L 465.4999694824219 130.9228515625 C 465.4999694824219 130.6857452392578 465.3142395019531 130.5000152587891 465.0771179199219 130.5000152587891 L 456.9228210449219 130.5000152587891 M 456.9228210449219 129.5000152587891 L 465.0771179199219 129.5000152587891 C 465.8656921386719 129.5000152587891 466.4999694824219 130.1342926025391 466.4999694824219 130.9228515625 L 466.4999694824219 139.0771484375 C 466.4999694824219 139.86572265625 465.8656921386719 140.5000152587891 465.0771179199219 140.5000152587891 L 456.9228210449219 140.5000152587891 C 456.1342163085938 140.5000152587891 455.4999694824219 139.86572265625 455.4999694824219 139.0771484375 L 455.4999694824219 130.9228515625 C 455.4999694824219 130.1342926025391 456.1342163085938 129.5000152587891 456.9228210449219 129.5000152587891 Z" stroke="none" fill="#bcc1ce"/>
  </g>
</svg>
                        <p className="preview-copy-redirect-linktext">Copy link</p>{" "}
                       
                      </>
                    ) : (
                      <p className="preview-copy-redirect-linktext">Copied!</p>
                    )}
                  </button>
                </div>
              </div>
              <div className="preview-test-popup-links d-flex">
                <a
                  target="_blank"
                  href={localStorage.getItem("website_technology") == 3 
                  ? "https://testflight.apple.com/join/DKgriDVM"
                  : localStorage.getItem("website_technology") == 2 
                  ? "https://testflight.apple.com/join/1cvKgAXc"
                  : localStorage.getItem("website_technology") == 4
                  ? "https://testflight.apple.com/join/OLPs2xOB"
                  : localStorage.getItem("website_technology") == 5
                  ? "https://testflight.apple.com/join/cq3qO8y7"
                  : ""
                  }
                >
                  <div className="preview-apple-test-link">
                    <img
                      src={
                        require("../../assets/images/dashboard/Appletestflight.png")
                          .default
                      }
                      
                      className="webapplestore"
                      alt="ios-store-icon "
                    ></img>
                      <img
                      src={
                        require("../../assets/images/dashboard/mob-applestore.png")
                          .default
                      }
                      
                      className="mobapplestore"
                      alt="ios-store-icon "
                    ></img>
                    <div className="">
                      <p>Download in</p>
                      <h5 className="apple-link-subtext">TestFlight</h5>
                    </div>
                  </div>
                </a>
                <a
                  target="_blank"
                  href={ localStorage.getItem("website_technology") == 3 
                  ? "https://play.google.com/store/apps/details?id=com.appmysite.custom"
                  : localStorage.getItem("website_technology") == 2 
                  ? "https://play.google.com/store/apps/details?id=com.appmysite.web"
                  : localStorage.getItem("website_technology") == 4 
                  ? "https://play.google.com/store/apps/details?id=com.appmysite.wordpress"
                  : localStorage.getItem("website_technology") == 5 
                  ? "https://play.google.com/store/apps/details?id=com.appmysite.shop"
                  : ""
                  }
                >
                  <div className="preview-apple-test-link preview-gplay-test-link">
                    <img
                      src={
                        require("../../assets/images/dashboard/previewplaystore.png")
                          .default
                      }
                      className="webapplestore"
                      alt="gplay-store-icon "
                    ></img>
                     <img
                      src={
                        require("../../assets/images/dashboard/mob-playstore.png")
                          .default
                      }
                      className="mobapplestore"
                      alt="gplay-store-icon "
                    ></img>
                    <div className=" gplay-test-linktext">
                      <p>Get it on</p>
                      <h5 className="gplay-link-subtext">Google Play</h5>
                    </div>
                  </div>
                </a>
              </div>
            </ModalBody>
          </Modal>
         <Modal className="protip-popup-message" isOpen={this.state.mdlprotip} toggle={()=> this.protip()} centered>
          
          <ModalBody>

             <div className="protip-popup"> 

                <div className="protip-popup-one">           
                  <h4>Pro tip</h4>
                  <h6>Certain elements like the app icon, splash screen, notifications, advertisement and some other features may not be previewed until you install your app on a real device. </h6>
                  <h2 className="protip-publish">Before you publish:</h2>
                  <ul>
                    <li>Review your content and scrutinize all text, images, and media to guarantee accuracy.</li>  

                    <li>Run a functionality check to ensure every feature and interactive element works as intended.</li>

                    <li>Ensure that your app's layout and design are optimized for both Android and iOS.</li>

                    <li>Verify the  navigation flow to ensure that users can intuitively move through your app with ease. </li>

                    <li>Also test the app on a real device using the “Test on real device” feature.</li>

                    {/* <li>Avoid getting too close to the edges of the icon.</li>

                    <li>Brands evolve, and icons evolve with them. Make gradual changes so that users continue to relate to your app.</li> */}
                  </ul>

                  <button onClick={this.handleBtnDone}>Okay</button>
                </div>  

                <div className="protip-popup-two">
                <img src={require("./../../assets/images/dashboard/protip-img.png").default} className="img-fluid" alt="icon-preview"/>
                </div>

              </div>

            <div className="crossmodal mt-1">
                <img onClick={this.handleBtnDone} src={ require("./../../assets/images/dashboard/cross.png").default }/>
            </div>

          </ModalBody>
      </Modal>
            {/* {InnerFoot()} */}
          </div>
        </section>
      </>
    );
  }
}
