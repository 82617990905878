import React from "react";

const MergeAppNewLayout = ({selectedLayout, setMergeState}) => {
  return (
    <div className="mergelayout-maindiv">
      <div className="mergelayot-head">
        <h2>Layout</h2>
        <p>
          Choose a template to enlist the appearance of children apps within the
          newly converged app.
        </p>
      </div>
      <div className="merge-layout-type">
        <div className={`${selectedLayout === "layout-0" ? "layout-selected" : ""} merge-layouts`}
          onClick={() => setMergeState({selectedLayout: "layout-0"})}
        >
          <img
            src={
              require("../../../assets/images/dashboard/newmergelayoutleft.png")
                .default
            }
            alt="publishUpgrade-image"
          />
        </div>
        <div className={`${selectedLayout === "layout-1" ? "layout-selected" : ""} merge-layouts`}
          onClick={() => setMergeState({selectedLayout: "layout-1"})}
          >
          <img
            src={
              require("../../../assets/images/dashboard/newmergelayoutright.png")
                .default
            }
            alt="publishUpgrade-image"
          />
        </div>
      </div>
    </div>
  );
};

export default MergeAppNewLayout;
