import React from "react";

const WebViewPreview = () => {
  return (
    <img
      className=""
      src={
        require("../../../assets/images/dashboard/web-view-preview.png").default
      }
    />
  );
};

export default WebViewPreview;
