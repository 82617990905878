import React from "react";
import { FormGroup, Label, Input } from "reactstrap";
import LeftAlignment from "../../commonIcons/LeftAlignment";
import MiddleAlignment from "../../commonIcons/MiddleAlignment";

const TitleAlignmentInput = ({ handleStateChange, webViewState }) => {
  return (
    <div>
      <FormGroup
        tag="fieldset"
        className="bottombar-shape select-headings-ratio"
      >
        <FormGroup>
          <Label className="galleryimg-ratio" value="LLLL">
            <Input
              type="radio"
              name="radio3left"
              value="left"
              onChange={(e) =>
                handleStateChange("titleAlignment", e.target.value)
              }
              checked={webViewState.titleAlignment === "left"}
            />
            <div className="image">
              <LeftAlignment />
            </div>
          </Label>
        </FormGroup>

        <FormGroup>
          <Label className="galleryimg-ratio" value="LLLLL">
            <Input
              type="radio"
              name="radio3center"
              value="center"
              onChange={(e) =>
                handleStateChange("titleAlignment", e.target.value)
              }
              checked={webViewState.titleAlignment === "center"}
            />

            <div className="image">
              <MiddleAlignment />
            </div>
          </Label>
        </FormGroup>
      </FormGroup>
    </div>
  );
};

export default TitleAlignmentInput;
