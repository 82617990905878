import React from "react";
import ReactSelect from "react-select";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { customStyles } from "../../../utils/customStyle";
import goBack from "../../../assets/images/dashboard/goBackicon.png";

const LanguageDropdown = ({
  handleOpenMobDefaultLanguageDropdown,
  handleDefaultLanguageChange,
  settingsState,
}) => {
  return (
    <>
      <div
        className="mobile-view-click"
        onClick={() => handleOpenMobDefaultLanguageDropdown()}
      >
        <ReactSelect
          options={settingsState.optionsForDefaultLanguage}
          onChange={(e) => handleDefaultLanguageChange(e)}
          required={true}
          classNamePrefix="common-language-dropdown"
          value={{
            label: settingsState.defaultlanguage,
          }}
          placeholder="Select..."
          isSearchable={true}
          isClearable={false}
          isDisabled={
            window.matchMedia("(max-width: 1200px)").matches ? true : false
          }
          styles={customStyles}
        />
      </div>
      <Modal
        isOpen={
          window.matchMedia("(max-width: 1200px)").matches
            ? settingsState.openMobOperateDropdown
            : false
        }
        className={" profile-code-modal common-language-modal"}
        centered
        dataclass="countrycode"
      >
        <ModalHeader className=" ">
          <div className=" search-box  language-country-search">
            <div className="d-flex align-items-center w-100 ">
              <div
                className="backaero-svgimg"
                onClick={handleOpenMobDefaultLanguageDropdown}
              >
                <img src={goBack} alt="back-icon" />
              </div>
            </div>
          </div>
        </ModalHeader>
        <ModalBody className="language-code-selector">
          <div className="mob-popup-language-name-div">
            {settingsState.optionsForDefaultLanguage.map((res, i) => (
              <div
                className="d-flex align-items-center justify-content-between"
                key={i}
                onClick={() => handleDefaultLanguageChange(res)}
              >
                <div className="d-flex align-items-center justify-content-start">
                  <p className="mob-language-name">{res.label}</p>
                </div>
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default LanguageDropdown;
