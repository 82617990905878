import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Media, Modal, ModalHeader, ModalBody } from "reactstrap";
import { Errorstatuscon } from "./Apppreview/Context";
import Amsvideo from "./Reusable/Amsvideos";
import moment from "moment";
import styles from "./Appdownload/Downloadapp.module.css";
import { render } from "react-dom";
export const PublishApp = () => {
  return (
    <Link to="/download-app" className="next_btn publish_app_btn">
      Download App
    </Link>
  );
};
export const salesvg = () => {
  return (
    <img
      src={require("../assets/images/dashboard/sale-dashboard.png").default}
      className="img-fluid"
      alt="Sale"
    />
  );
};
export const popularblogsvg = () => {
  return (
    <img
      src={
        require("../assets/images/dashboard/popularblog-dashboard.png").default
      }
      className="img-fluid"
      alt="Popularblog"
    />
  );
};
export const featuredsvg = () => {
  return (
    <img
      src={require("../assets/images/dashboard/featured-dashboard.png").default}
      className="img-fluid"
      alt="Featured"
    />
  );
};

export const newsvg = () => {
  return (
    <img
      src={require("../assets/images/dashboard/new-dashboard.png").default}
      className="img-fluid"
      alt="Newproduct"
    />
  );
};

export const blogsvg = () => {
  return (
    <svg width="360" height="206" viewBox="0 0 360 206">
      <defs>
        <clipPath id="clipPath">
          <rect
            id="Rectangle_1501"
            data-name="Rectangle 1501"
            width="360"
            height="206"
            transform="translate(36 5781)"
            fill="#fff"
          />
        </clipPath>
        <filter
          id="Rounded_Rectangle_2_copy_18"
          x="-7.382"
          y="-6.712"
          width="164.934"
          height="220.145"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="6" result="blur" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Rounded_Rectangle_2_copy_18-2"
          x="130.003"
          y="-6.712"
          width="164.934"
          height="220.145"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="6" result="blur-2" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-2" />
          <feComposite in="SourceGraphic" />
        </filter>
        <filter
          id="Rounded_Rectangle_2_copy_20"
          x="267.388"
          y="-6.712"
          width="164.934"
          height="220.145"
          filterUnits="userSpaceOnUse"
        >
          <feOffset dy="1" input="SourceAlpha" />
          <feGaussianBlur stdDeviation="6" result="blur-3" />
          <feFlood floodOpacity="0.161" />
          <feComposite operator="in" in2="blur-3" />
          <feComposite in="SourceGraphic" />
        </filter>
      </defs>
      <g
        id="Mask_Group_1"
        data-name="Mask Group 1"
        transform="translate(-36 -5781)"
        clipPath="url(#clipPath)"
      >
        <g
          id="Group_3313"
          data-name="Group 3313"
          transform="translate(46.618 5791.288)"
        >
          <g
            transform="matrix(1, 0, 0, 1, -10.62, -10.29)"
            filter="url(#Rounded_Rectangle_2_copy_18)"
          >
            <rect
              id="Rounded_Rectangle_2_copy_18-3"
              data-name="Rounded Rectangle 2 copy 18"
              width="128.934"
              height="184.145"
              rx="3"
              transform="translate(10.62 10.29)"
              fill="#fff"
            />
          </g>
          <text
            id="Black_track_pants_with_white_stripe"
            data-name="Black track pants 
with white stripe"
            transform="translate(7.894 137.559)"
            fontSize="9"
            fontFamily="Montserrat-SemiBold, Montserrat"
            fontWeight="600"
          >
            <tspan x="0" y="0">
              Black track pants{" "}
            </tspan>
            <tspan x="0" y="10">
              with white stripe
            </tspan>
          </text>

          <g
            transform="matrix(1, 0, 0, 1, -10.62, -10.29)"
            filter="url(#Rounded_Rectangle_2_copy_18-2)"
          >
            <rect
              id="Rounded_Rectangle_2_copy_18-4"
              data-name="Rounded Rectangle 2 copy 18"
              width="128.934"
              height="184.145"
              rx="3"
              transform="translate(148 10.29)"
              fill="#fff"
            />
          </g>
          <text
            id="Light_blue_flip_flops"
            data-name="Light blue flip flops"
            transform="translate(144.949 136.559)"
            fontSize="8"
            fontFamily="Montserrat-SemiBold, Montserrat"
            fontWeight="600"
          >
            <tspan x="0" y="0">
              Light blue flip flops
            </tspan>
          </text>

          <g
            transform="matrix(1, 0, 0, 1, -10.62, -10.29)"
            filter="url(#Rounded_Rectangle_2_copy_20)"
          >
            <rect
              id="Rounded_Rectangle_2_copy_20-2"
              data-name="Rounded Rectangle 2 copy 20"
              width="128.934"
              height="184.145"
              rx="3"
              transform="translate(285.39 10.29)"
              fill="#fff"
            />
          </g>
          <text
            id="Grey_woman_woollen_cap"
            data-name="Grey woman woollen cap"
            transform="translate(282.285 136.559)"
            fontSize="8"
            fontFamily="Montserrat-SemiBold, Montserrat"
            fontWeight="600"
          >
            <tspan x="0" y="0">
              Grey woman woollen cap
            </tspan>
          </text>

          <rect
            id="flat-lay-fashion-set-white-sandal-shoes-with-and-P89G85W"
            width="112.012"
            height="112.631"
            transform="translate(8.664 9.642)"
            fill="#fff"
          />
          <rect
            id="blue-flip-flops-on-blue-background-64HFYZD"
            width="111.393"
            height="112.631"
            rx="3"
            transform="translate(146.049 9.642)"
            fill="#fff"
          />
          <rect
            id="hipster-autumn-CUAMNBZ"
            width="111.629"
            height="112.421"
            rx="3"
            transform="translate(283.295 9.74)"
            fill="#fff"
          />
          <g
            id="Group_3298"
            data-name="Group 3298"
            transform="translate(184.108 52.342)"
          >
            <rect
              id="Rectangle_1500"
              data-name="Rectangle 1500"
              width="34.656"
              height="27.229"
              rx="2"
              transform="translate(0 0)"
              fill="none"
              stroke="#eff2f9"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              id="Path_6087"
              data-name="Path 6087"
              d="M346,692.3l6.526-7.688,6.359,7.491,6.951,8.189"
              transform="translate(-345.899 -673.204)"
              fill="none"
              stroke="#eff2f9"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              id="Path_6088"
              data-name="Path 6088"
              d="M425.21,654.262l12.6-14.842,9.177,10.71"
              transform="translate(-412.224 -635.365)"
              fill="none"
              stroke="#eff2f9"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <circle
              id="Ellipse_259"
              data-name="Ellipse 259"
              cx="2.435"
              cy="2.435"
              r="2.435"
              transform="translate(10.192 3.26)"
              fill="none"
              stroke="#eff2f9"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </g>
          <g
            id="Group_3297"
            data-name="Group 3297"
            transform="translate(47.033 52.342)"
          >
            <rect
              id="Rectangle_1500-2"
              data-name="Rectangle 1500"
              width="34.656"
              height="27.229"
              rx="2"
              transform="translate(0 0)"
              fill="none"
              stroke="#eff2f9"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              id="Path_6087-2"
              data-name="Path 6087"
              d="M346,692.3l6.526-7.688,6.359,7.491,6.951,8.189"
              transform="translate(-345.899 -673.204)"
              fill="none"
              stroke="#eff2f9"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              id="Path_6088-2"
              data-name="Path 6088"
              d="M425.21,654.262l12.6-14.842,9.177,10.71"
              transform="translate(-412.224 -635.365)"
              fill="none"
              stroke="#eff2f9"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <circle
              id="Ellipse_259-2"
              data-name="Ellipse 259"
              cx="2.435"
              cy="2.435"
              r="2.435"
              transform="translate(10.192 3.26)"
              fill="none"
              stroke="#eff2f9"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </g>
          <g
            id="Group_3299"
            data-name="Group 3299"
            transform="translate(321.802 52.342)"
          >
            <rect
              id="Rectangle_1500-3"
              data-name="Rectangle 1500"
              width="34.656"
              height="27.229"
              rx="2"
              transform="translate(0 0)"
              fill="none"
              stroke="#eff2f9"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              id="Path_6087-3"
              data-name="Path 6087"
              d="M346,692.3l6.526-7.688,6.359,7.491,6.951,8.189"
              transform="translate(-345.899 -673.204)"
              fill="none"
              stroke="#eff2f9"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <path
              id="Path_6088-3"
              data-name="Path 6088"
              d="M425.21,654.262l12.6-14.842,9.177,10.71"
              transform="translate(-412.224 -635.365)"
              fill="none"
              stroke="#eff2f9"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
            <circle
              id="Ellipse_259-3"
              data-name="Ellipse 259"
              cx="2.435"
              cy="2.435"
              r="2.435"
              transform="translate(10.192 3.26)"
              fill="none"
              stroke="#eff2f9"
              strokeMiterlimit="10"
              strokeWidth="2"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};
const blogcontentSize = (state) => {
  let column = state.state.blog_categories_columns;

  let finalcolumn;
  if (column === "1") {
    state.setState({
      showremainblog: false,
    });
    finalcolumn = { width: " 100%" };
  } else if (column === "2") {
    state.setState({
      showremainblog: false,
    });
    finalcolumn = { width: "46.5%" };
  } else if (column === "3") {
    state.setState({
      showremainblog: false,
    });
    finalcolumn = { width: " 31%" };
  } else if (column === "4") {
    state.setState({
      showremainblog: true,
    });
    finalcolumn = { width: " 22.6%" };
  }
  return finalcolumn;
};
const blogcolorsize = (state) => {
  let shape = state.state.blog_categories_shape;
  let column = state.state.blog_categories_columns;
  if (shape === "sharp_corner") {
    if (column === "1") {
      state.setState({
        showremainblog: false,
      });
      return {
        background: state.state.blog_categories_bg_color,
        color: state.state.blog_categories_text_color,
        width: " 100%",
        borderRadius: "0px",
        height: "40px",
        fontSize: "12px",
      };
    } else if (column === "2") {
      state.setState({
        showremainblog: false,
      });
      return {
        background: state.state.blog_categories_bg_color,
        color: state.state.blog_categories_text_color,
        width: " 100%",
        borderRadius: "0px",
        fontSize: "11px",
      };
    } else if (column === "3") {
      state.setState({
        showremainblog: false,
      });
      return {
        background: state.state.blog_categories_bg_color,
        color: state.state.blog_categories_text_color,
        width: " 100%",
        borderRadius: "0px",
        height: "72px",
        lineHeight: "15px",
        fontSize: "10px",
      };
    } else if (column === "4") {
      state.setState({
        showremainblog: true,
      });
      return {
        background: state.state.blog_categories_bg_color,
        color: state.state.blog_categories_text_color,
        width: " 100%",
        borderRadius: "0px",
        height: "53px",
        fontSize: "8px",
        lineHeight: "11px",
      };
    }
  } else if (shape === "round_corner") {
    if (column === "1") {
      state.setState({
        showremainblog: false,
      });
      return {
        background: state.state.blog_categories_bg_color,
        color: state.state.blog_categories_text_color,
        width: " 100%",
        borderRadius: "7px",
        height: "40px",
        fontSize: "12px",
      };
    } else if (column === "2") {
      state.setState({
        showremainblog: false,
      });
      return {
        background: state.state.blog_categories_bg_color,
        color: state.state.blog_categories_text_color,
        width: " 100%",
        borderRadius: "7px",
        fontSize: "11px",
      };
    } else if (column === "3") {
      state.setState({
        showremainblog: false,
      });
      return {
        background: state.state.blog_categories_bg_color,
        color: state.state.blog_categories_text_color,
        width: " 100%",
        borderRadius: "7px",
        height: "72px",
        lineHeight: "15px",
        fontSize: "10px",
      };
    } else if (column === "4") {
      state.setState({
        showremainblog: true,
      });
      return {
        background: state.state.blog_categories_bg_color,
        color: state.state.blog_categories_text_color,
        width: " 100%",
        borderRadius: "7px",
        height: "53px",
        fontSize: "8px",
        lineHeight: "11px",
      };
    }
  } else if (shape === "circular_corner") {
    if (column === "1") {
      state.setState({
        catfont: "12px",
      });
      return {
        background: state.state.blog_categories_bg_color,
        color: state.state.blog_categories_text_color,
        width: " 100%",
        borderRadius: "50%",
        height: "40px",
        fontSize: "12px",
      };
    } else if (column === "2") {
      state.setState({
        showremainblog: false,
      });
      if (window.innerWidth < 900) {
        return {
          background: state.state.blog_categories_bg_color,
          color: state.state.blog_categories_text_color,
          width: " 100%",
          borderRadius: "50%",
          height: "158px",
          fontSize: "11px",
        };
      } else {
        return {
          background: state.state.blog_categories_bg_color,
          color: state.state.blog_categories_text_color,
          width: " 100%",
          borderRadius: "50%",
          fontSize: "11px",
        };
      }
    } else if (column === "3") {
      state.setState({
        showremainblog: false,
      });
      if (window.innerWidth < 900) {
        return {
          background: state.state.blog_categories_bg_color,
          color: state.state.blog_categories_text_color,
          width: " 100%",
          borderRadius: "50%",
          height: "103px",
          lineHeight: "15px",
          fontSize: "10px",
        };
      } else {
        return {
          background: state.state.blog_categories_bg_color,
          color: state.state.blog_categories_text_color,
          width: " 69px",
          borderRadius: "50%",
          height: "69px",
          lineHeight: "15px",
          fontSize: "10px",
        };
      }
    } else if (column === "4") {
      state.setState({
        showremainblog: true,
      });
      if (window.innerWidth < 900) {
        return {
          background: state.state.blog_categories_bg_color,
          color: state.state.blog_categories_text_color,
          width: " 100%",
          borderRadius: "50%",
          height: "78px",
          fontSize: "8px",
          lineHeight: "11px",
        };
      } else {
        return {
          background: state.state.blog_categories_bg_color,
          color: state.state.blog_categories_text_color,
          width: " 50px",
          borderRadius: "50%",
          height: "50px",
          fontSize: "8px",
          lineHeight: "11px",
        };
      }
    }
  }
};
export const blogcategorysvg = (state) => {
  return (
    <>
      <div className="category-list flex-wrap d-flex pt-2">
        <div
          className="cat-product solid-category mr-2 mr-md-1"
          style={blogcontentSize(state)}
        >
          <p style={blogcolorsize(state)}>Blog Category</p>
        </div>
        <div
          className="cat-product solid-category mr-2 mr-md-1"
          style={blogcontentSize(state)}
        >
          <p style={blogcolorsize(state)}>Blog Category</p>
        </div>
        <div
          className="cat-product solid-category mr-2 mr-md-1"
          style={blogcontentSize(state)}
        >
          <p style={blogcolorsize(state)}>Blog Category</p>
        </div>
        <div
          className="cat-product solid-category mr-2 mr-md-1"
          style={blogcontentSize(state)}
        >
          <p style={blogcolorsize(state)}>Blog Category</p>
        </div>
        <div
          className="cat-product solid-category mr-2 mr-md-1"
          style={blogcontentSize(state)}
        >
          <p style={blogcolorsize(state)}>Blog Category</p>
        </div>
        <div
          className="cat-product solid-category mr-2 mr-md-1"
          style={blogcontentSize(state)}
        >
          <p style={blogcolorsize(state)}>Blog Category</p>
        </div>
        {state.state.showremainblog && (
          <>
            <div
              className="cat-product solid-category mr-2 mr-md-1"
              style={blogcontentSize(state)}
            >
              <p style={blogcolorsize(state)}>Blog Category</p>
            </div>
            <div
              className="cat-product solid-category mr-2 mr-md-1"
              style={blogcontentSize(state)}
            >
              <p style={blogcolorsize(state)}>Blog Category</p>
            </div>
          </>
        )}
      </div>
    </>
  );
};
export const blogcategorysvgforadmob = () => {
  return (
    <>
      <div className="category-list flex-wrap d-flex pt-2">
        <div
          className="cat-product solid-category mr-2 mr-md-1"
          style={{ width: "31%" }}
        >
          <p
            style={{
              backgroundColor: "rgb(248, 250, 254)",
              color: "rgb(0, 0, 0)",
              width: "100%",
              borderRadius: "7px",
              height: "72px",
              lineHeight: "15px",
              fontSize: "10px",
            }}
          >
            Blog Category
          </p>
        </div>
        <div
          className="cat-product solid-category mr-2 mr-md-1"
          style={{ width: "31%" }}
        >
          <p
            style={{
              backgroundColor: "rgb(248, 250, 254)",
              color: "rgb(0, 0, 0)",
              width: "100%",
              borderRadius: "7px",
              height: "72px",
              lineHeight: "15px",
              fontSize: "10px",
            }}
          >
            Blog Category
          </p>
        </div>
        <div
          className="cat-product solid-category mr-2 mr-md-1"
          style={{ width: "31%" }}
        >
          <p
            style={{
              backgroundColor: "rgb(248, 250, 254)",
              color: "rgb(0, 0, 0)",
              width: "100%",
              borderRadius: "7px",
              height: "72px",
              lineHeight: "15px",
              fontSize: "10px",
            }}
          >
            Blog Category
          </p>
        </div>
        <div
          className="cat-product solid-category mr-2 mr-md-1"
          style={{ width: "31%" }}
        >
          <p
            style={{
              backgroundColor: "rgb(248, 250, 254)",
              color: "rgb(0, 0, 0)",
              width: "100%",
              borderRadius: "7px",
              height: "72px",
              lineHeight: "15px",
              fontSize: "10px",
            }}
          >
            Blog Category
          </p>
        </div>
      </div>
    </>
  );
};
export const recentblogsvg = () => {
  return (
    <img
      src={
        require("../assets/images/dashboard/recentblog-dashboard.png").default
      }
      className="img-fluid"
      alt="blog-banner"
    />
  );
};
export const blogbannersvg = () => {
  return (
    <div className="">
      <img
        src={require("../assets/images/dashboard/latest-blog.png").default}
        className="img-fluid"
        alt="blog-banner"
      />
      <div className="latestblog-heading">
        <h3>Latest blog heading goes here</h3>
        <p>Date Published</p>
      </div>
    </div>
  );
};
export const recentsvg = () => {
  return (
    <img
      src={require("../assets/images/dashboard/recent-dashboard.png").default}
      className="img-fluid"
      alt="Recent"
      style={{width:"100%"}}
    />
  );
};

export const incartsvg = () => {
  return (
    <img
      src={require("../assets/images/dashboard/incart-dashboard.png").default}
      className="img-fluid"
      alt="Incart"
      style={{width:"100%"}}
    />
  );
};

export const catemodalsecond = () => {
  return (
    <svg width="91" height="103" viewBox="0 0 91 103">
      <g
        id="Group_2879"
        data-name="Group 2879"
        transform="translate(-352.108 -458.799)"
      >
        <g
          id="Group_2876"
          data-name="Group 2876"
          transform="translate(353 459)"
        >
          <rect
            id="Rounded_Rectangle_2_copy_12"
            data-name="Rounded Rectangle 2 copy 12"
            width="90"
            height="102"
            rx="3"
            transform="translate(-0.392 0.299)"
            fill="#f8fafe"
            stroke="#d4dbe9"
            strokeWidth="1"
            strokeDasharray="4 4"
          />
        </g>
        <text
          id="Shoes"
          transform="translate(397.608 530.299)"
          fill="#7782a1"
          fontSize="11"
          fontFamily="SegoeUI-Bold, Segoe UI"
          fontWeight="700"
          x="-15.152"
          y="0"
        >
          Shoes
        </text>
        <g
          id="Group_2163"
          data-name="Group 2163"
          transform="translate(-350.792 -263.701)"
          opacity="0.5"
        >
          <path
            id="Path_6110"
            data-name="Path 6110"
            d="M758.879,765.462a10.24,10.24,0,0,1-20.479,0c0-7.637,10.24-14.462,10.24-14.462S758.879,758.435,758.879,765.462Z"
            fill="none"
            stroke="#c8cedb"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Path_6111"
            data-name="Path 6111"
            d="M811.16,775.7V751h0"
            transform="translate(-62.52)"
            fill="none"
            stroke="#c8cedb"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <line
            id="Line_6"
            data-name="Line 6"
            y1="3.555"
            x2="4.931"
            transform="translate(748.64 755.395)"
            fill="none"
            stroke="#c8cedb"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <line
            id="Line_7"
            data-name="Line 7"
            x1="8.558"
            y2="6.17"
            transform="translate(748.64 760.094)"
            fill="none"
            stroke="#c8cedb"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <line
            id="Line_8"
            data-name="Line 8"
            x1="10.24"
            y2="7.383"
            transform="translate(748.64 765.433)"
            fill="none"
            stroke="#c8cedb"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <line
            id="Line_9"
            data-name="Line 9"
            y1="6.17"
            x2="8.558"
            transform="translate(748.64 760.094)"
            fill="none"
            stroke="#c8cedb"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export const catemodalfirst = () => {
  return (
    <svg width="91" height="103" viewBox="0 0 91 103">
      <g
        id="Group_2877"
        data-name="Group 2877"
        transform="translate(-352.108 -458.799)"
      >
        <g
          id="Group_2876"
          data-name="Group 2876"
          transform="translate(353 459)"
        >
          <rect
            id="Rounded_Rectangle_2_copy_12"
            data-name="Rounded Rectangle 2 copy 12"
            width="90"
            height="102"
            rx="3"
            transform="translate(-0.392 0.299)"
            fill="#f8fafe"
            stroke="#d4dbe9"
            strokeWidth="1"
            strokeDasharray="4 4"
          />
        </g>
        <path
          id="Rounded_Rectangle_2_copy_12-2"
          data-name="Rounded Rectangle 2 copy 12"
          d="M0,0H90a0,0,0,0,1,0,0V15a3,3,0,0,1-3,3H3a3,3,0,0,1-3-3V0A0,0,0,0,1,0,0Z"
          transform="translate(352.608 543.299)"
          fill="#c8cedb"
        />
        <text
          id="Shoes"
          transform="translate(396.608 555.299)"
          fill="#fff"
          fontSize="11"
          fontFamily="SegoeUI-Bold, Segoe UI"
          fontWeight="700"
        >
          <tspan x="-15.152" y="0">
            Shoes
          </tspan>
        </text>
        <g
          id="Group_2161"
          data-name="Group 2161"
          transform="translate(39.609 -122.338)"
          opacity="0.5"
        >
          <rect
            id="Rectangle_1500"
            data-name="Rectangle 1500"
            width="23.925"
            height="19.44"
            rx="2"
            transform="translate(345.999 613.637)"
            fill="none"
            stroke="#c8cedb"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Path_6087"
            data-name="Path 6087"
            d="M346,689.963l4.544-5.353,4.427,5.215,4.84,5.7"
            transform="translate(0 -62.81)"
            fill="none"
            stroke="#c8cedb"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <path
            id="Path_6088"
            data-name="Path 6088"
            d="M425.21,649.754l8.771-10.334,6.39,7.457"
            transform="translate(-70.371 -22.668)"
            fill="none"
            stroke="#c8cedb"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
          <circle
            id="Ellipse_259"
            data-name="Ellipse 259"
            cx="1.695"
            cy="1.695"
            r="1.695"
            transform="translate(352.98 616.235)"
            fill="none"
            stroke="#c8cedb"
            strokeMiterlimit="10"
            strokeWidth="1.5"
          />
        </g>
      </g>
    </svg>
  );
};

export const stylecountsvg = () => {
  return (
    <svg width="66" height="18" viewBox="0 0 46 12">
      <g
        id="Group_3389"
        data-name="Group 3389"
        transform="translate(-211.705 -82.592)"
      >
        <g
          id="Group_3390"
          data-name="Group 3390"
          transform="translate(211.705 82.592)"
        >
          <g
            id="Rounded_Rectangle_1_copy_5"
            data-name="Rounded Rectangle 1 copy 5"
            transform="translate(0)"
            fill="#fff"
            stroke="#000"
            strokeLinejoin="round"
            strokeWidth="0.5"
          >
            <rect width="12" height="12" rx="2" stroke="none" />
            <rect
              x="0.25"
              y="0.25"
              width="11.5"
              height="11.5"
              rx="1.75"
              fill="none"
            />
          </g>
        </g>
        <path
          id="Rectangle_4_copy_3"
          data-name="Rectangle 4 copy 3"
          d="M2.742,6V3.493H0v-.75H2.742V0h.751V2.743H6v.75H3.493V6Z"
          transform="translate(214.705 85.592)"
        />
        <g
          id="Group_3391"
          data-name="Group 3391"
          transform="translate(233.022 82.592)"
        >
          <g
            id="Rounded_Rectangle_1_copy_9"
            data-name="Rounded Rectangle 1 copy 9"
            transform="translate(12.683)"
            fill="#fff"
            stroke="#000"
            strokeLinejoin="round"
            strokeWidth="0.5"
          >
            <rect width="12" height="12" rx="2" stroke="none" />
            <rect
              x="0.25"
              y="0.25"
              width="11.5"
              height="11.5"
              rx="1.75"
              fill="none"
            />
          </g>
          <rect
            id="Rectangle_4_copy_7"
            data-name="Rectangle 4 copy 7"
            width="6"
            height="1"
            transform="translate(15.683 6)"
          />
          <text
            id="_1"
            data-name="1"
            transform="translate(-0.317 7)"
            fill="#333"
            fontSize="6"
            fontFamily="Montserrat-Regular, Montserrat"
          >
            <tspan x="0" y="0">
              1
            </tspan>
          </text>
        </g>
      </g>
    </svg>
  );
};

export const pagelistsvg = (typeselection) => {
  return (
    <div
      className="category-list"
      style={{ borderTop: "1px solid #D8DDEE", backgroundColor: "#fff" }}
    >
      <div className="cat-product  pl-2 pr-2">
        <div className="pages-list">
          <ul>
            <li>
              <Link to="#">Home</Link>
            </li>
            <li>
              <Link to="#">About us</Link>
            </li>
            <li>
              <Link to="#">Services</Link>
            </li>
            <li>
              <Link to="#">Portfolio</Link>
            </li>
            <li>
              <Link to="#">Contact us</Link>
            </li>

            {typeselection !== "5" && (
              <li className="see-all-pages">
                <Link to="#">See All Pages</Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export const previewblogcategories = () => {
  return (
    <div>
      <div className="d-flex align-items-center justify-content-around mt-1">
        <div className="blog-cate">
          <Link to="#"></Link>
          <p>Fashion</p>
        </div>
        <div className="blog-cate">
          <Link to="#"></Link>
          <p>Technology</p>
        </div>
        <div className="blog-cate">
          <Link to="#"></Link>
          <p>Politics</p>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-around mt-2">
        <div className="blog-cate">
          <Link to="#"></Link>
          <p>Automobile</p>
        </div>
        <div className="blog-cate">
          <Link to="#"></Link>
          <p>Entertainment</p>
        </div>
        <div className="blog-cate">
          <Link to="#"></Link>
          <p>Economy</p>
        </div>
      </div>
    </div>
  );
};

export class PreviewHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      downloadsampleapp: false,
      untilpreview: false,
    };
  }
  toggledownloadsampleapp = (e) => {
    e.preventDefault();
    this.setState({
      downloadsampleapp: !this.state.downloadsampleapp,
    });
  };
  toggleuntilpreview = (e) => {
    e.preventDefault();
    this.setState({
      untilpreview: !this.state.untilpreview,
    });
  };
  static contextType = Errorstatuscon;

  render() {
    return (
      <div className="d-md-flex align-items-center justify-content-between mb-md-3 top-portion pb-md-3 show_border">
        {/* <div className="app_iconname"> */}
        <div className="app_iconname d-none d-md-block">
          <div className="d-flex align-items-center">
            <h3>Preview</h3>
            {/* <Amsvideo
              timespan={773}
              addclass="d-md-flex d-none align-items-center justify-content-between see_video"
            /> */}
          </div>
          {(this.context.state.is_verified === 1 &&
            parseInt(localStorage.getItem("website_technology")) === 5 || 
            parseInt(localStorage.getItem("website_technology")) === 4 ||
            parseInt(localStorage.getItem("website_technology")) === 2) && (
            <>
              <h5>
                Congratulations! You’ve successfully set up your app. Give it a
                spin here.{" "}
                {/* <Media
                  href="https://www.appmysite.com/support/app-preview-2/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Learn More{" "}
                  <img
                    src={
                      require("./../assets/images/dashboard/Goto.png").default
                    }
                    className="gotoappicon appear-spacing"
                    alt="Goto"
                  />
                </Media> */}
                {" "}
              </h5>
            </>
          )}
        </div>
        {this.context.state.simulator_status !== undefined &&
          this.context.state.simulator_status !== null && (
            <>
              <div className="statusspacing statusspace ipad-status-align">
                <p
                  className={
                    styles.builder_status +
                    " " +
                    styles.preview_status_alignment
                  }
                >
                  Preview Status:{" "}
                  <span
                    className={styles.operational}
                    style={{
                      color: this.context.state.simulator_status.colour,
                    }}
                  >
                    {this.context.state.simulator_status.title}
                  </span>
                </p>
              </div>

              {this.context.state.simulator_status !== null &&
                this.context.state.simulator_status.description !== "" &&
                parseInt(this.context.state.simulator_status.status) !== 0 && (
                  <div className="w-100 mt-2">
                    <p
                      className={
                        styles.description + " p-0 m-0  mr-4 mr-md-0 text-left"
                      }
                      style={{
                        color: this.context.state.simulator_status.colour,
                      }}
                    >
                      {this.context.state.simulator_status.description}
                    </p>
                  </div>
                )}
            </>
          )}
      </div>
    );
  }
}

export default class ModalDemoApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      downloadsampleapp: false,
      untilpreview: false,
    };
  }
  toggledownloadsampleapp = (e) => {
    e.preventDefault();
    this.setState({
      downloadsampleapp: !this.state.downloadsampleapp,
    });
  };
  toggleuntilpreview = (e) => {
    e.preventDefault();
    this.setState({
      untilpreview: !this.state.untilpreview,
    });
  };

  static contextType = Errorstatuscon;

  render() {
    return (
      <>
        {/* <div className="d-md-block d-none  justify-content-between app_description "> */}
        <div className="d-md-block justify-content-between app_description ">
          <PreviewHeader />
        </div>
        <Modal
          isOpen={this.state.downloadsampleapp}
          toggle={this.toggledownloadsampleapp}
          style={{ maxWidth: "635px", maxHeight: "247px" }}
          className={this.props.className}
          centered
        >
          <ModalHeader
            toggle={this.toggledownloadsampleapp}
            className="modal-xxl-content"
          >
            <div
              className="crossmodal mt-1"
              onClick={this.toggledownloadsampleapp}
            >
              <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
                <path
                  id="Union_18"
                  data-name="Union 18"
                  d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                  transform="translate(0.529 0.531)"
                  fill="#1b2952"
                  stroke="#1b2952"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
          </ModalHeader>
          <ModalBody className="">
            <div className="text-center mb-4 conecttoapi">
              <h2 className="mb-3">Download the demo app to preview</h2>
              <p>
                To get a first hand experience of what your mobile app functions
                like, download our demo app.
              </p>
            </div>
            <div className="text-center mb-4 d-flex justify-content-center ">
              <div className="demo_app_pop">
                <Media
                  href="https://apps.apple.com/us/app/woocommerce-app-by-appmysite/id1472980267?ls=1"
                  target="_blank"
                  rel="noopener noreferrer"
                  tag="a"
                >
                  <img
                    src={
                      require("../assets/images/dashboard/app-store.png")
                        .default
                    }
                    alt="App Store"
                    className="img-fluid"
                  />
                </Media>
                <p>Demo app only</p>
              </div>
              <div className="demo_app_pop">
                <Media
                  href="https://play.google.com/store/apps/details?id=com.appmysite.shop&hl=en"
                  target="_blank"
                  rel="noopener noreferrer"
                  tag="a"
                >
                  <img
                    src={
                      require("../assets/images/dashboard/google-play.png")
                        .default
                    }
                    alt="Android Store"
                    className="img-fluid"
                  />
                </Media>
                <p>Test your own app</p>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </>
    );
  }
}
export const Leavesvg = () => {
  return (
    <svg width="13.467" height="15.5" viewBox="0 0 13.467 15.5">
  <g id="Group_29539" data-name="Group 29539" transform="translate(-1613.75 -113.75)">
    <path id="Path_84424" data-name="Path 84424" d="M9,2.154V0H0V14H9V11.846" transform="translate(1614.5 114.5)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
    <path id="Path_84422" data-name="Path 84422" d="M0,0H4V4" transform="translate(1623.328 118.672) rotate(45)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
    <path id="Path_84423" data-name="Path 84423" d="M21579.26-24413h-8" transform="translate(-19953.26 24534.5)" fill="none" stroke="#bcc1ce" stroke-width="1.5"/>
  </g>
</svg>

  )
};
export const Deletesvg = () => {
  return (

           <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="13.999"
                            viewBox="0 0 14 13.999"
                          >
                            <g
                              id="Group_38801"
                              data-name="Group 38801"
                              transform="translate(-835.516 -682.992)"
                            >
                              <g
                                id="Rectangle_2307"
                                data-name="Rectangle 2307"
                                transform="translate(836.515 684.992)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1"
                              >
                                <path
                                  d="M0,0H12a0,0,0,0,1,0,0V10.7A1.3,1.3,0,0,1,10.7,12H1.3A1.3,1.3,0,0,1,0,10.7V0A0,0,0,0,1,0,0Z"
                                  stroke="none"
                                />
                                <path
                                  d="M.5.5h11a0,0,0,0,1,0,0V10.7a.8.8,0,0,1-.8.8H1.3a.8.8,0,0,1-.8-.8V.5A0,0,0,0,1,.5.5Z"
                                  fill="none"
                                />
                              </g>
                              <g
                                id="Rectangle_2308"
                                data-name="Rectangle 2308"
                                transform="translate(839.515 682.992)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1"
                              >
                                <path
                                  d="M1,0H5A1,1,0,0,1,6,1V3A0,0,0,0,1,6,3H0A0,0,0,0,1,0,3V1A1,1,0,0,1,1,0Z"
                                  stroke="none"
                                />
                                <path
                                  d="M1,.5H5a.5.5,0,0,1,.5.5V2.5a0,0,0,0,1,0,0H.5a0,0,0,0,1,0,0V1A.5.5,0,0,1,1,.5Z"
                                  fill="none"
                                />
                              </g>
                              <g
                                id="Group_3245"
                                data-name="Group 3245"
                                transform="translate(841.016 687.993)"
                              >
                                <line
                                  id="Line_110"
                                  data-name="Line 110"
                                  y2="6"
                                  transform="translate(3 0)"
                                  fill="none"
                                  stroke="#bcc1ce"
                                  stroke-miterlimit="10"
                                  stroke-width="1"
                                />
                                <line
                                  id="Line_111"
                                  data-name="Line 111"
                                  y1="6"
                                  transform="translate(0 0)"
                                  fill="none"
                                  stroke="#bcc1ce"
                                  stroke-miterlimit="10"
                                  stroke-width="1"
                                />
                              </g>
                              <line
                                id="Line_112"
                                data-name="Line 112"
                                x2="14"
                                transform="translate(835.516 685.492)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1"
                              />
                            </g>
                          </svg>
  );
};


 export const LocationSvg = () => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="18"
    viewBox="0 0 20 26"
  >
    <path
      id="Subtraction_317"
      data-name="Subtraction 317"
      d="M10,26h0c-.006,0-.643-.443-1.562-1.215A31.615,31.615,0,0,1,5,21.42a23.187,23.187,0,0,1-3.437-5.093A14.679,14.679,0,0,1,0,9.927a9.86,9.86,0,0,1,2.929-7.02,10.039,10.039,0,0,1,14.142,0A9.86,9.86,0,0,1,20,9.927a14.139,14.139,0,0,1-1.562,6.2A24.638,24.638,0,0,1,15,21.243,33.715,33.715,0,0,1,10,26ZM10,6.229a3.792,3.792,0,1,0,3.784,3.792A3.792,3.792,0,0,0,10,6.229Z"
      fill="#838383"
      opacity="0.5"
    />
  </svg>
  )
}



export const DashHomesvg = (color) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18">
      <path
        id="iconfinder_Home-01_1976053_1_"
        data-name="iconfinder_Home-01_1976053 (1)"
        d="M20.047,7.834,12.941,2.379a2.5,2.5,0,0,0-3,0h0L2.831,7.833a.975.975,0,0,0-.392.776V18.873a1.036,1.036,0,0,0,1.059,1H8.263a1.036,1.036,0,0,0,1.059-1v-6.5h4.235v6.5a1.036,1.036,0,0,0,1.059,1H19.38a1.036,1.036,0,0,0,1.059-1V8.609A.977.977,0,0,0,20.047,7.834Zm-.668,11.045H14.614V12.372a1.028,1.028,0,0,0-1.059-.995H9.321a1.028,1.028,0,0,0-1.059.995v6.506H3.5V8.609L10.6,3.155a1.39,1.39,0,0,1,1.669,0L19.38,8.609V18.878Z"
        transform="translate(-2.439 -1.878)"
        fill={color}
      />
    </svg>
  );
};

export const LoginSvg = (color) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18">
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_2409"
            data-name="Rectangle 2409"
            width="18"
            height="18"
            transform="translate(0.187 0.236)"
            fill={color}
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g
        id="Group_3284"
        data-name="Group 3284"
        transform="translate(-526 -902)"
      >
        <g
          id="Mask_Group_80"
          data-name="Mask Group 80"
          transform="translate(525.813 901.764)"
          clipPath="url(#clip-path)"
        >
          <g
            id="Group_3284-2"
            data-name="Group 3284"
            transform="translate(2.016)"
          >
            <g
              id="Ellipse_249"
              data-name="Ellipse 249"
              transform="translate(3.171 0.236)"
              fill="none"
              stroke={color}
              strokeWidth="1"
            >
              <circle cx="4" cy="4" r="4" stroke="none" />
              <circle cx="4" cy="4" r="3.5" fill="none" />
            </g>
            <g
              id="Rectangle_2408"
              data-name="Rectangle 2408"
              transform="translate(1.171 9.236)"
            >
              <path
                id="Path_6821"
                data-name="Path 6821"
                d="M5,0H7a5,5,0,0,1,5,5V7a2,2,0,0,1-2,2H2A2,2,0,0,1,0,7V5A5,5,0,0,1,5,0Z"
                fill="none"
              />
              <path
                id="Path_6822"
                data-name="Path 6822"
                d="M5,.5H7A4.5,4.5,0,0,1,11.5,5V7A1.5,1.5,0,0,1,10,8.5H2A1.5,1.5,0,0,1,.5,7V5A4.5,4.5,0,0,1,5,.5Z"
                fill="none"
                stroke={color}
                strokeWidth="1"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export const ShareSvg = (color) => {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18">
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectangle_2410"
            data-name="Rectangle 2410"
            width="18"
            height="18"
            fill="none"
            stroke="#707070"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g
        id="Mask_Group_79"
        data-name="Mask Group 79"
        clipPath="url(#clip-path)"
      >
        <g
          id="Group_3283"
          data-name="Group 3283"
          transform="translate(1.26 0.252)"
        >
          <g
            id="Ellipse_246"
            data-name="Ellipse 246"
            transform="translate(-0.26 5.748)"
            fill="none"
            stroke={color}
            strokeWidth="1"
          >
            <circle cx="3" cy="3" r="3" stroke="none" />
            <circle cx="3" cy="3" r="2.5" fill="none" />
          </g>
          <g
            id="Ellipse_247"
            data-name="Ellipse 247"
            transform="translate(9.74 -0.252)"
            fill="none"
            stroke={color}
            strokeWidth="1"
          >
            <circle cx="3" cy="3" r="3" stroke="none" />
            <circle cx="3" cy="3" r="2.5" fill="none" />
          </g>
          <g
            id="Ellipse_248"
            data-name="Ellipse 248"
            transform="translate(9.74 11.748)"
            fill="none"
            stroke={color}
            strokeWidth="1"
          >
            <circle cx="3" cy="3" r="3" stroke="none" />
            <circle cx="3" cy="3" r="2.5" fill="none" />
          </g>
          <path
            id="Path_6821"
            data-name="Path 6821"
            d="M-176.875,224.563l5.725,3.3"
            transform="translate(182.01 -214.303)"
            fill="none"
            stroke={color}
            strokeWidth="1"
          />
          <path
            id="Path_6822"
            data-name="Path 6822"
            d="M-176.875,227.862l5.725-3.3"
            transform="translate(182.01 -220.21)"
            fill="none"
            stroke={color}
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};

export const Deleteusers = (props) => {
  const [deluser, setdeluser] = useState(props.permission);
  const { className } = props;
  const [type] = useState(props.type);

  const removepopup = () => {
    setdeluser(!deluser);
    props.allowpopup();
  };

  return (
    <Modal
      isOpen={deluser}
      toggle={removepopup}
      style={{ maxWidth: "580px", maxHeight: "247px" }}
      className={className}
      centered
      dataclass="categorypop"
    >
      <ModalHeader toggle={removepopup} className="modal-xxl-content">
        <div className="crossmodal mt-1" onClick={removepopup}>
          <svg width="13.059" height="13.059" viewBox="0 0 13.059 13.059">
            <path
              id="Union_18"
              data-name="Union 18"
              d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
              transform="translate(0.529 0.531)"
              fill="#1b2952"
              stroke="#1b2952"
              strokeWidth="1.5"
            />
          </svg>
        </div>
      </ModalHeader>
      <ModalBody className="">
        <div className="text-center mb-4 d-flex justify-content-center ">
          <img
            src={require("../assets/images/dashboard/restrict-user.png").default}
            alt="User"
            className="img-fluid"
          />
        </div>
        <div className="text-center mb-4 conecttoapi">
          <h2 className="mb-3" style={{ fontWeight: "bold" }}>
            {type}
          </h2>
        </div>
        <div
          className="d-flex justify-content-center align-items-center mb-3"
          onClick={removepopup}
        >
          <div
            type="submit"
            className="next_btn"
            form="userform"
            style={{ width: "auto" }}
          >
            GOT IT!
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
