import React from 'react'

const DropArrow = ({strokeColor}) => {
  return (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L6 6L1 11" stroke={strokeColor} stroke-width="1.5"/>
   </svg>

  )
}

export default DropArrow