import React, { useMemo } from "react";
import "./../../assets/css/Dashboard.css";
import Header from "./../Header";
import Leftpanel from "./../Leftpanel";
import { Link , Redirect} from "react-router-dom";
import {
  FormGroup,
  Modal,
  ModalBody,
  Spinner,
  UncontrolledDropdown,
} from "reactstrap";
import moment from "moment";
import { InnerFoot } from "./../Foot";
import Slider from "./.././Reusable/Slider";
import imagebackground from "./../../assets/images/dashboard/cms/uploadbackground.png";
import noCatImg from "../../assets/images/dashboard/cms/no-cat-img.png";
import styles from "./cmsBuilderstyle.css";
import {
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useEffect } from "react";
import { useState } from "react";
import { parentPage } from "./CommonPopUp";
import NewImageGalleryPopup from "../NewImageGalleryPopup";
import {
  cmsDeleteCategory,
  cmsgetCategoryApi,
  cmsGetEachCategory,
  cmsSaveCategoryApi,
  getCategorySettingsApi,
  saveCategorySettingsApi,
} from "../../Api/CmsApi/Cmsapi";
import { errortoast } from "../Toaster";
import RecursiveLoop from "./RecursiveLoop";
import { uploadAnyBase64Imagetos3 } from "../../Api/NewHomescreen/NewHomescreenApi";
// import imagebackground2 from "./../../assets/images/dashboard/cms/bannerimg.jpg";
import imagebackground2 from "./../../assets/images/dashboard/cms/gallery1.png";
import VistaPopup from "../Appearance/VistaPopup";
import { SingleSelectionPopUpForCustomApp } from "../Reusable/SingleSelectionPopUp";

import Reactcolorpicker from "../Appearance/NewReactcolorpicker";
import { Label, Input } from "reactstrap";
import BottomScrollListener from "react-bottom-scroll-listener";
import CategoryPreview from "./CategoryPreview";
import SettingIcon from "../../Components/commonIcons/SettingIcon";

const Categorypage = () => {
  const [categoryData, setCategoryData] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [bottomSpinner, setBottomSpinner] = useState(false);
  const [openSingleSelectionPopUp, setOpenSingleSelectionPopUp] =
    useState(false);
  const [eachCatData, setEachCatData] = useState([
    {
      name: "",
      image: "",
      portal_value: {
        type: 1,
        source_file: "",
        image_link: "",
        resize_image_link: "",
        base64_image: "",
        base64_org_image: "",
        design_id: "",
      },
      parent: 0,
      content: "",
    },
  ]);
  const [name, setName] = useState("");
  const [content, setContent] = useState("");
  const [image, setImage] = useState("");
  const [parentName, setParentName] = useState("");
  const [parentID, setparentID] = useState(eachCatData[0].parent);
  const [tempParentID, setTempParentID] = useState(0);
  const [tempParentName, setTempParentName] = useState("");
  const [isParentpopup, setIsParentpopup] = useState(false);
  const [isGalleryPopup, setIsGalleryPopup] = useState(false);
  const [prevImgUrl, setprevImgUrl] = useState("");
  const [apiImageUrl, setApiImageUrl] = useState("");
  const [categoryID, setCategoryID] = useState("");
  const [searchVal, setSearchVal] = useState("");
  const [isLoader, setIsLoader] = useState("d-none");
  const [loaderimage, setLoaderimage] = useState(
    require("../../assets/images/signup/Loader_GIF.gif").default
  );
  const [submitval, setSubmitval] = useState("Create");
  const [isDeleted, setisDeleted] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [spinner2, setSpinner2] = useState(false);
  const [openCatPopUp, setOpenCatPopUp] = useState(false);
  const [isParentDropdown, setIsParentDropdown] = useState(false);
  const [isTrashed, setIsTrashed] = useState(false);
  const [catObjForDelete, setCatObjForDelete] = useState("");
  const [isParentMobileDropdown, setIsParentMobileDropdown] = useState(false);
  // const [openVistaPopUp, setOpenVistaPopUp] = useState(false);
  const [openSmallScreenPopUp, setOpenSmallScreenPopUp] = useState(false);
  const [subData, setSubData] = useState([]);
  const [breadcrumbArr, setBreadcrumbArr] = useState([]);
  const [oldSubData, setOldSubData] = useState([]);
  const [oldBreadcrumbArr, setOldBreadcrumbArr] = useState([]);
  const [searchValForPopUp, setSearchValForPopUp] = useState("");

  //setting screen's key
  const [activeSettingTab, setActiveSettingTab] = useState(false);
  const [activeLayoutTab, setActiveLayoutTab] = useState(false);
  const [settingUpdatedAt, setSettingUpdatedAt] = useState("");
  const [showSearchInHeader, setShowSearchInHeader] = useState(true);
  const [showBookmarkInHeader, setShowBookmarkInHeader] = useState(true);
  const [column, setColumn] = useState("3");
  const [bgColor, setBgColor] = useState("#FFFFFF");
  const [textColor, setTextColor] = useState("#333333");
  const [catImgOverlayColor, setCatImgOverlayColor] = useState("#000000");
  const [catTileBgColor, setCatTileBgColor] = useState("#B0B0B0");
  const [showCatImg, setShowCatImg] = useState(true);
  const [catImgRatio, setCatImgRatio] = useState("1:1");
  const [catImgShape, setCatImgShape] = useState("sharp_corner");
  const [catLayout, setCatLayout] = useState("layout-1");
  const [settingSaveBtnVal, setSettingSaveBtnVal] = useState("Save");
  const [settingSaveBtnLoader, setSettingSaveBtnLoader] = useState("d-none");
  const [catCheckedArr, setCatCheckedArr] = useState([]);
  const [showCenterCropImages, setShowCenterCropImages] = useState(true);
  
  const [page, setPage] = useState(1);
	const [paginationSpinner , setPaginationSpinner] = useState(false);
	const [hitNextPagination , setHitNextPagination] = useState(false);

  const [appRightPanelExpand, setAppRightPanelExpand] = useState(
    localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
      ? true
      : false
  );

  // let { categoryID } = useParams("categoryID");
  useEffect(() => {
    window.scrollTo(0, 0);
    if ( parseInt(localStorage.getItem("website_technology")) !== 3 && window.location.pathname.includes("custom")) {
      window.location.href="/app/dashboard"
     }
    if (
      localStorage.getItem("agencyid") &&
      localStorage.getItem("agencyid") == 1
    ) {
      document.title = "Post categories | AppMySite";
    } else {
      document.title =
        "Post categories |" + " " + localStorage.getItem("agencyname");
    }

    let limit = 20;
    let pageNo = 1;
    setPage(pageNo);

    cmsgetCategoryApi(
      setCategoryData,
      btoa(localStorage.getItem("Appid")),
      setSpinner,
      setBottomSpinner,
      false,
      setCatCheckedArr,
      [],
      false,
      limit,
      pageNo,
      categoryData,
      setHitNextPagination,
      setPaginationSpinner,
      setSubData,
    );


    getCategorySettingsApi(
      setShowSearchInHeader,
      setShowBookmarkInHeader,
      setColumn,
      setBgColor,
      setTextColor,
      setCatImgOverlayColor,
      setShowCatImg,
      setCatImgRatio,
      setCatImgShape,
      setCatLayout,
      setSettingUpdatedAt,
      setCatTileBgColor,
      setShowCenterCropImages,
    );
  }, [isDeleted, isSaved]);

  const handleNameChange = (e) => {
    let nameChange = e.target.value
      ? e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1)
      : "";
    setName(nameChange);
    let newArr = [...eachCatData];
    newArr[0].name = nameChange;

    setEachCatData(newArr);
  };

  // const handleParentPop = (e, from) => {
  //   e.preventDefault();
  //   if (from === "add") {
  //     setparentID(tempParentID);
  //     setParentName(tempParentName);
  //     let newArr = [...eachCatData];
  //     newArr[0].parent = tempParentID;
  //   }
  //   setIsParentpopup(!isParentpopup);
  // };

const handleContainerOnBottom = () => {
    if (paginationSpinner === false && bottomSpinner === false) {
        if (hitNextPagination) 
        {
            let limit = 20;
            let pageNo = page + 1;
            setPage(pageNo);
            setPaginationSpinner(true);
            
    cmsgetCategoryApi(
      setCategoryData,
      btoa(localStorage.getItem("Appid")),
      setSpinner,
      setBottomSpinner,
      false,
      setCatCheckedArr,
      [],
      false,
      limit,
      pageNo,
      categoryData,
      setHitNextPagination,
      setPaginationSpinner,
      setSubData,
    );
        }
    }
};

const handleNotHitOnBottom = () => {

}

  const handleSettingIconClick = (e) => {
    setActiveSettingTab(!activeSettingTab);
    setActiveLayoutTab(false);
  };

  const handleLayoutIconClick = (e) => {
    setActiveLayoutTab(!activeLayoutTab);
    setActiveSettingTab(false);
  };

  const handleHomeBtn = (e) => {
    setActiveLayoutTab(false);
    setActiveSettingTab(false);
  };
  

  //Saving Category Id
  const handleParentChange = (e, pID, name) => {
    // setparentID(pID)
    // setParentName(name)
    setTempParentID(pID);
    setTempParentName(name);

    //  setIsParentMobileDropdown(!isParentMobileDropdown)
  };

  // Open Image Gallery Pop Up
  const imageGalleryPopup = (e) => {
    let newArray = [...eachCatData];
    setIsGalleryPopup(!isGalleryPopup);
    setprevImgUrl(newArray[0].portal_value.source_file);
  };

  // Remove Image
  const handleCancelImage = (e) => {
    let newArray = [...eachCatData];

    newArray[0].portal_value.source_file = "";
    newArray[0].portal_value.resize_image_link = "";
    newArray[0].portal_value.image_link = "";
    newArray[0].portal_value.base64_image = "";
    newArray[0].portal_value.base64_org_image = "";

    setEachCatData(newArray);
  };

  //Handle Add Category
  const handleAddCategory = (e) => {
    e.preventDefault();
    setIsLoader("d-block");
    setSubmitval("");
    const params = {
      title: name,
      content: { portal_value: eachCatData[0].portal_value },
      app_id: parseInt(localStorage.getItem("Appid")),
      user_id: parseInt(localStorage.getItem("user_id")),
      author_user_id: parseInt(localStorage.getItem("originalid")),
      image: "", // eachCatData[0].image,
      parent: parentID,
      parent_name: parentName,
      // portal_value: eachCatData[0].portal_value,
      category_id:
        categoryID === undefined ||
        categoryID === "undefined" ||
        categoryID === null ||
        categoryID === ""
          ? ""
          : categoryID,
    };
    if (name === undefined || name === null || name.trim() === "") {
      errortoast("Name can't be empty");
      setIsLoader("d-none");
      setSubmitval("Create");
      return;
    } else if (params.parent === params.category_id) {
      errortoast("A category can't be it's own child");
      setIsLoader("d-none");
      setSubmitval("Create");
    } else {
      if (
        params.content.portal_value.base64_image &&
        params.content.portal_value.type === 2
      ) {
        // Resize image upload
        const paramsForImg = {
          data: params.content.portal_value.base64_image,
          filetype: "png",

          path:
            localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            process.env.REACT_APP_CMS_Media,
        };
        uploadAnyBase64Imagetos3(
          paramsForImg,
          params.content.portal_value.resize_image_link.replace(".png", "")
        );

        params.content.portal_value.base64_image = "";
      }

      // Original image upload
      // if(params.content.portal_value.base64_org_image && params.content.portal_value.type===2){
      //   const paramsForImg = {
      //     data: params.content.portal_value.base64_org_image,
      //     filetype: "png",

      //     path:  localStorage.getItem("user_id") +
      //     "/" +
      //     localStorage.getItem("Appid") +
      //     process.env.REACT_APP_CMS_Media,
      //   };
      //   uploadAnyBase64Imagetos3(paramsForImg,params.content.portal_value.image_link.replace(".png",""))

      //   params.content.portal_value.base64_org_image="";
      // }
      params.content.portal_value.base64_org_image = "";
      cmsSaveCategoryApi(
        params,
        setSubmitval,
        setIsLoader,
        setisSaved,
        isSaved,
        setEachCatData,
        setSpinner,
        setName,
        setContent,
        setparentID,
        setCategoryID,
        setOpenCatPopUp,
        setBottomSpinner
      );
    }
  };

  const handleEditCategory = (e, catID) => {
    if (catID !== categoryID) {
      setSpinner2(!spinner2);
      setCategoryID(catID);
    }
    setOpenCatPopUp(!openCatPopUp);
  };

  const handleSearch = (e) => {
    setSearchVal(e.target.value);
  };

  // Parent child relation

  const childParentList = (newArr) => {
    return newArr
      .filter((row) => row.name.toLowerCase().includes(searchVal.toLowerCase()))
      .map((eachCat) => {
        return (
          <div className="categorydetail">
            <div
              className="category-img"
              onClick={(e) => handleEditCategory(e, eachCat._id)}
            >
              {eachCat.level > 0 && (
                <img
                  style={{
                    marginLeft: window.matchMedia("(max-width: 1200px)").matches
                      ? "0px"
                      : eachCat.level === 1
                      ? "0px"
                      : eachCat.level * 13 + "px",
                  }}
                  className="img-fluid category-child-one"
                  src={
                    require("../../assets/images/dashboard/cms/child-page.png")
                      .default
                  }
                />
              )}
              <div className="cat-thumbnail">
                {eachCat.content.portal_value.source_file === null ||
                eachCat.content.portal_value.source_file === undefined ||
                eachCat.content.portal_value.source_file === "" ? (
                  <img
                    className="img-fluid"
                    src={
                      require("../../assets/images/dashboard/cms/no-cat-img.png")
                        .default
                    }
                  />
                ) : (
                  <div
                    style={{
                      // height: "100%",

                      // width: "100%",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundImage:
                        "url(" +
                        (eachCat.content.portal_value.source_file.indexOf(
                          "https"
                        ) > -1
                          ? eachCat.content.portal_value.source_file
                          : process.env.REACT_APP_Image_Path +
                            localStorage.getItem("user_id") +
                            "/" +
                            localStorage.getItem("Appid") +
                            process.env.REACT_APP_CMS_Media +
                            eachCat.content.portal_value.source_file) +
                        ")",
                    }}
                  ></div>
                )}
              </div>
              <div>
                {/* <h6>
                  {eachCat.level > 0 && (
                    handleDash(eachCat.level)
                  )}
                  {eachCat.name}
                </h6> */}
                <h6>{eachCat.name}</h6>
                <p
                  className="d-block d-xl-none"
                  style={{ marginLeft: "100px" }}
                >
                  {eachCat.post_count}
                </p>
              </div>
            </div>

            <div className="category-blog-no d-none d-xl-block">
              <p>{eachCat.post_count} </p>
            </div>

            <div className="categoryaction d-none d-xl-flex">
              <p
                style={{
                  marginBottom:"0px"
                }}
              >
                <img
                  onClick={(e) => handleEditCategory(e, eachCat._id)}
                  className="img-fluid"
                  src={
                    require("../../assets/images/dashboard/cms/edit.png")
                      .default
                  }
                />
              </p>

              <img
                onClick={(e) => handleTrashedPopUp(e, "add", eachCat)}
                className="img-fluid"
                src={
                  require("../../assets/images/dashboard/cms/delete.png")
                    .default
                }
              />
            </div>

            <div className="d-block d-xl-none pageedit">
              <UncontrolledDropdown className="custom-drop">
                <DropdownToggle caret>
                  <img
                    className="img-fluid"
                    src={
                      require("../../assets/images/dashboard/cms/threedot.png")
                        .default
                    }
                  />
                </DropdownToggle>
                <DropdownMenu flip>
                  <DropdownItem
                    onClick={(e) => handleEditCategory(e, eachCat._id)}
                  >
                    Edit
                  </DropdownItem>

                  <DropdownItem
                    onClick={(e) => handleTrashedPopUp(e, "add", eachCat)}
                  >
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
        );
      });
  };

  let newArr = [];

  function recurs(arr, check) {
    return arr.map((eachCat, indx) => {
      newArr.push(eachCat);

      if (eachCat.child !== undefined && eachCat.child.length > 0) {
        // if parent has childrens
        recurs(eachCat.child, "child");
      }

      return (
        check === "parent" &&
        indx === categoryData.length - 1 &&
        childParentList(newArr)
      );
    });
  }

  const editApi = () => {
    if (categoryID !== "" && categoryID !== undefined && categoryID !== null) {
      cmsGetEachCategory(
        categoryID,
        setEachCatData,
        setName,
        setSpinner2,
        spinner2,
        setparentID,
        setTempParentID,
        setParentName,
        setTempParentName,
        breadcrumbArr,
        setBreadcrumbArr,
        categoryData,
        setSubData
      );
    }
  };

  const blobToBase64 = (blob) => {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  };

  const settingYourdata = async (
    imgName,
    from,
    orgFileName,
    url1200,
    useUrl,
    uploadedFileBase64,
    croppedImageForCms
  ) => {
    let newArr = [...eachCatData];

    const resizeImgName =
      "ams_resize_" +
      new Date().getTime() +
      "_" +
      Math.floor(1000 + Math.random() * 9000) +
      ".png";
    const originalImgName =
      "ams_original_" +
      new Date().getTime() +
      "_" +
      Math.floor(1000 + Math.random() * 9000) +
      ".png";
    if (from === "resize") {
      // newArr[0].portal_value.image_link = originalImgName;
      newArr[0].portal_value.image_link = "";
      newArr[0].portal_value.source_file = imgName;
      newArr[0].portal_value.resize_image_link = resizeImgName;
      newArr[0].portal_value.base64_image = await blobToBase64(
        croppedImageForCms
      );
      newArr[0].portal_value.base64_org_image = "";
      newArr[0].portal_value.design_id = "";
      // newArr[0].portal_value.base64_org_image = uploadedFileBase64;

      newArr[0].portal_value.type = 2;
    } else if (from === "apiImg") {
      newArr[0].portal_value.source_file = imgName;
      newArr[0].portal_value.resize_image_link = url1200;
      // newArr[0].portal_value.image_link = useUrl;
      newArr[0].portal_value.image_link = "";
      newArr[0].portal_value.type = 1;
      newArr[0].portal_value.base64_image = "";
      newArr[0].portal_value.base64_org_image = "";
      newArr[0].portal_value.design_id = "";
    }
    setEachCatData(newArr);
  };
  const yourErrFun = () => {
    let newArr = [...eachCatData];

    newArr[0].portal_value.source_file = prevImgUrl;

    setEachCatData(newArr);
  };

  const handleOpenCatPopUp = (e, from) => {
    if (from === "delete") {
      setSubData(categoryData);
      setparentID(0);
      setCategoryID("");
      setParentName("");
      setTempParentName("");
      setTempParentID(0);
      setName("");
      setBreadcrumbArr([]);
      setOldBreadcrumbArr([]);
      setEachCatData([
        {
          name: "",
          image: "",
          portal_value: {
            type: 1,
            source_file: "",
            image_link: "",
            resize_image_link: "",
            base64_image: "",
            base64_org_image: "",
          },
          parent: 0,
          content: "",
        },
      ]);
    }

    setOpenCatPopUp(!openCatPopUp);
  };
  const handleDash = (level) => {
    switch (level) {
      case 0:
        return <span></span>;
      case 1:
        return <span> - </span>;
      case 2:
        return <span> - - </span>;
      case 3:
        return <span> - - - </span>;
      case 4:
        return <span> - - - - </span>;
      case 5:
        return <span> - - - - - </span>;
      case 6:
        return <span> - - - - - - </span>;
      case 1:
        return <span> - - - - - - - </span>;
      case 7:
        return <span> - - - - - - - - </span>;
      case 8:
        return <span> - - - - - - - - - </span>;
      case 9:
        return <span> - - - - - - - - - - </span>;
    }
  };

  // const handleParentDropdown = () => {
  //   setIsParentDropdown(!isParentDropdown);
  // };
  // const handleMobileCatDropdown = () => {
  //   setIsParentMobileDropdown(!isParentMobileDropdown);
  // };
  // Mobile view dropdown listing

  // const childParentListForMobile = (newArr1) => {
  //   return newArr1
  //     .filter((row1) =>
  //       row1.name.toLowerCase().includes(searchVal.toLowerCase())
  //     )
  //     .map((eachPage, indx) => {
  //       return (
  //         (eachPage.level < 10 || eachPage.level === undefined) &&
  //         eachPage.deleted_at === null && (
  //           <p
  //             style={{
  //               fontSize: "16px",
  //               color: "#1B2952",
  //               fontFamily: "Axiforma-Regular",
  //               margin: "0px 20px 40px 20px",
  //               display: "block",
  //             }}
  //             onClick={(e) =>
  //               handleParentChange(e, eachPage._id, eachPage.name)
  //             }
  //           >
  //             {handleDash(eachPage.level)}
  //             {eachPage.name}
  //           </p>
  //         )
  //       );
  //     });
  // };

  let newArrForMobile = [];

  // function recursForMobile(arr, check) {
  //   return arr.map((eachPage, indx) => {
  //     newArrForMobile.push(eachPage);

  //     if (eachPage.child !== undefined && eachPage.child.length > 0) {
  //       // if parent has childrens
  //       recursForMobile(eachPage.child, "child");
  //     }

  //     return (
  //       check === "parent" &&
  //       indx === categoryData.length - 1 &&
  //       childParentListForMobile(newArrForMobile)
  //     );
  //   });
  // }

  // Mobile view listing

  const childParentListForMobileView = (newArrForMobileView) => {
    return newArrForMobileView
      .filter((row1) =>
        row1.name.toLowerCase().includes(searchVal.toLowerCase())
      )
      .map((eachCat, indx) => {
        return (
          //       ((eachPage.level < 10 ) ||
          //         eachPage.level === undefined) &&
          //       eachPage.deleted_at === null && (
          //  <p style={{
          //   fontSize:"16px",
          //   color: "#1B2952",
          //    fontFamily: "Axiforma-Regular",
          //   margin: "0px 20px 40px 20px",
          //    display: "block",
          // }}
          //  onClick={(e)=>handleParentChange(e,eachPage._id,eachPage.name)}
          //  >{handleDash(eachPage.level)}{eachPage.name}</p>
          //   )
          <div className="category-entry-mob">
            <div className="category-entry-one category-mob-padding">
              <div className="category-info-mob ">
                <h2>Category</h2>
                <h5>
                  {handleDash(eachCat.level)}
                  {eachCat.name}
                </h5>
              </div>

              {eachCat.content.portal_value.source_file === null ||
              eachCat.content.portal_value.source_file === undefined ||
              eachCat.content.portal_value.source_file === "" ? (
                <img
                  className="img-fluid"
                  src={
                    require("../../assets/images/dashboard/cms/no-cat-img.png")
                      .default
                  }
                />
              ) : (
                <div
                  className="category-thumb-mob"
                  style={{
                    backgroundImage:
                      "url(" +
                      (eachCat.content.portal_value.source_file.indexOf(
                        "https"
                      ) > -1
                        ? eachCat.content.portal_value.source_file
                        : process.env.REACT_APP_Image_Path +
                          localStorage.getItem("user_id") +
                          "/" +
                          localStorage.getItem("Appid") +
                          process.env.REACT_APP_CMS_Media +
                          eachCat.content.portal_value.source_file) +
                      ")",
                  }}
                ></div>
              )}
            </div>
            <div className="category-entry-two">
              <div>
                <h3>Posts</h3>
                <h6>{eachCat.post_count}</h6>
              </div>

              <div>
                <img
                  onClick={(e) => handleEditCategory(e, eachCat._id)}
                  className="img-fluid"
                  src={
                    require("../../assets/images/dashboard/cms/edit.png")
                      .default
                  }
                />

                <img
                  onClick={(e) => handleTrashedPopUp(e, "add", eachCat)}
                  className="img-fluid"
                  src={
                    require("../../assets/images/dashboard/cms/delete.png")
                      .default
                  }
                />
              </div>
            </div>
          </div>
        );
      });
  };

  let newArrForMobileView = [];

  function recursForMobileView(arr, check) {
    return arr.map((eachPage, indx) => {
      newArrForMobileView.push(eachPage);

      if (eachPage.child !== undefined && eachPage.child.length > 0) {
        // if parent has childrens
        recursForMobileView(eachPage.child, "child");
      }

      return (
        check === "parent" &&
        indx === categoryData.length - 1 &&
        childParentListForMobileView(newArrForMobileView)
      );
    });
  }

  const handleTrashedPopUp = (e, from, eachCat) => {
    setIsTrashed(!isTrashed);
    if (from === "add") {
      setCatObjForDelete(eachCat);
    }
  };

  const handleTrashedCategory = (e) => {
    setIsTrashed(!isTrashed);
    if (
      catObjForDelete.child === undefined ||
      catObjForDelete.child === null ||
      catObjForDelete.child.length === 0
    ) {
      setSpinner(true);
      const params = {
        category_id: catObjForDelete._id,
        app_id: parseInt(localStorage.getItem("Appid")),
      };
      const obj = {
        name: "",
        image: "",
        portal_value: {
          type: 1,
          source_file: "",
          image_link: "",
          resize_image_link: "",
          base64_image: "",
          base64_org_image: "",
          design_id: "",
        },
        parent: 0,
        content: "",
      };
      let arr = [];
      arr.push(obj);
      setEachCatData(arr);
      setName("");
      setContent("");
      setparentID(0);
      setParentName("");
      cmsDeleteCategory(params, setisDeleted, isDeleted);
    } else {
      errortoast("Please delete it's child first");
    }
  };

  const loadpicVistaCreatePopUp = (data) => {
    //  const params = {
    //   filename: data.url.replace("https://", ""),

    //   path: localStorage.getItem("user_id") +
    //   "/" +
    //   localStorage.getItem("Appid") +
    //   process.env.REACT_APP_CMS_Media,
    // };
    //  thirdPartyImgUploadViaURL(params,successFn)
    // setOpenVistaPopUp(false);
    let newArr = [...eachCatData];
    newArr[0].portal_value.image_link = "";
    newArr[0].portal_value.source_file = data.url;
    newArr[0].portal_value.resize_image_link = data.url;
    newArr[0].portal_value.base64_image = "";
    newArr[0].portal_value.base64_org_image = "";
    newArr[0].portal_value.type = 1;
    newArr[0].portal_value.design_id = data.id;

    setEachCatData(newArr);
    setIsGalleryPopup(false);
    //  if(document.querySelector(".crello-closeBtn")){
    //   document.querySelector(".crello-closeBtn").click();
    //  }
  };

  // const successFn = (imgName) =>{
  //   let newArr = [...cmsArray];
  //   newArr[indx].portal_value.source_file = imgName;
  //      newArr[indx].portal_value.resize_image_link = imgName;

  //      newArr[indx].portal_value.image_link = "";
  //      newArr[indx].portal_value.type = 2;
  //      newArr[indx].portal_value.base64_image = ""
  //      newArr[indx].portal_value.base64_org_image = ""

  //      setOpenVistaPopUp(false);
  //   setcmsData(newArr);
  // }
  const closeVistaCreatePopUp = () => {};

  const handleRightPanelExpand = (value) => {
    setAppRightPanelExpand(value);
  };

  const handleOpenSelectionPopUp = (e, from) => {
    setOpenSingleSelectionPopUp(!openSingleSelectionPopUp);

    if (from === "cancel") {
      setSubData(oldSubData);
      setBreadcrumbArr(oldBreadcrumbArr);
      setTempParentID(parentID);
      setTempParentName(parentName);
    } else {
      if (from === "open") {
        if (!subData || subData.length === 0) {
          setSubData(categoryData);
        }
        setparentID(tempParentID);
        setParentName(tempParentName);
        setOldSubData(subData);
        setOldBreadcrumbArr(breadcrumbArr);
      }
    }
  };

  const handleBottomScrollListner = () => {
    if (paginationSpinner === false) {
      if (hitNextPagination) 
      {
          let limit = 20;
          let pageNo = page + 1;
          setPage(pageNo);
          setPaginationSpinner(true);

          cmsgetCategoryApi(
            setCategoryData,
            btoa(localStorage.getItem("Appid")),
            setSpinner,
            setBottomSpinner,
            false,
            setCatCheckedArr,
            [],
            false,
            limit,
            pageNo,
            categoryData,
            setHitNextPagination,
            setPaginationSpinner,
            setSubData,
          );
      }
  }
 }

  const handleShowSearchToggle = (e) => {
    setShowSearchInHeader(e.target.checked);
  };
  const handleShowBookmarkToggle = (e) => {
    setShowBookmarkInHeader(e.target.checked);
  };
  const handleBgColor = (e) => {
    setBgColor(e);
  };
  const handleTextColor = (e) => {
    setTextColor(e);
  };
  const handleCatImgOverlayBgColor = (e) => {
    
    setCatImgOverlayColor(e);
  };

  const handleTileBgColor = (e) => {
    setCatTileBgColor(e);
  };
  const handleColumnChange = (e) => {
    if (e.target.value > 1 && e.target.value < 5) {
      setColumn(e.target.value);
    } else {
      setColumn(2);
    }
    // let val = e.target.value;

    // if(parseInt(val) <= 0 || val ==="" || val ==="e"){
    //   setColumn(1);
    // }
    // else if(parseInt(val) > 4){
    //   setColumn(4);
    // }
    // else{
    //   setColumn(parseInt(val));
    // }
  };

  const handleShowCatChange = (e) => {
    setShowCatImg(e.target.checked);
  };

  const handleShowCenterCropImages = (e) => {
   setShowCenterCropImages(e.target.checked);
  }
  const handleRatioChange = (e) => {
    if(catImgShape === "circle" && e.target.value !== "1:1"){
    setCatImgShape("sharp_corner");
    }
    setCatImgRatio(e.target.value);
  };
  const handleShapeChange = (e) => {
    setCatImgShape(e.target.value);
  };

  const handleChangeLayout = (e, from) => {
    setCatLayout(from);
  };

  const handleSubmitCatSettingsData = () => {
    setSettingSaveBtnLoader("d-block");
    setSettingSaveBtnVal("");

    const params = {
      app_id: parseInt(localStorage.getItem("Appid")),
      layout: catLayout,
      show_search_in_header: showSearchInHeader ? 1 : 0,
      show_bookmark_in_header: showBookmarkInHeader ? 1 : 0,
      background_color: bgColor,
      text_color: textColor,
      image_overlay_color: catImgOverlayColor,
      show_category_image: showCatImg ? 1 : 0,
      image_ratio: catImgRatio,
      image_shape: catImgShape,
      column_count: parseInt(column),
      tile_background_color:catTileBgColor,
      show_center_crop_images:showCenterCropImages ? 1: 0,
    };
    saveCategorySettingsApi(
      params,
      setSettingSaveBtnLoader,
      setSettingSaveBtnVal,
      setSettingUpdatedAt,
    );
  };

  const calculateHeight = (ratio, width) => {
    let height = (width * 3) / 4;
    // if (catImgShape === "circle") {
    //   height = width;
    // } else {
      switch (ratio) {
        case "4:3":
          height = (width * 3) / 4;
          break;
        case "3:4":
          height = (width * 4) / 3;
          break;
        case "1:1":
          height = (width * 1) / 1;
          break;
        case "16:9":
          height = (width * 9) / 16;
          break;
        case "9:16":
          height = (width * 16) / 9;
          break;
      // }
    }
    return height;
  };

  useMemo(() => editApi(), [categoryID]);

  return (
    <>
      <div>
        <Header customclass={"header-responsive-new"} />
      </div>
      {/* Mobile View */}

      {/* Mobile View End*/}
      <section className="dasboard_page mobile-display mt-4 mt-md-0 cms-module">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
        <BottomScrollListener
              onBottom={window.matchMedia("(max-width: 1200px)").matches?handleContainerOnBottom:handleNotHitOnBottom}
              offset={100}
            >
              {(scrollRef) => (
                <div
                style={{
                  height: "calc(100vh - 60px)",
                  overflowY: "auto",
                }}
                ref={scrollRef}
          className={`right-panel-gap ${
            localStorage.getItem("appSideBarCollapse") !== undefined &&
            localStorage.getItem("appSideBarCollapse") !== null &&
            localStorage.getItem("appSideBarCollapse") === "true"
              ? " right-panel-gap-expand"
              : ""
          }`}
        >
          <div className="post-heading d-none d-xl-flex">
            <h1>
              Posts{" "}
              <p class="cms-posts-tooltip">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <g
                    id="Group_35001"
                    data-name="Group 35001"
                    transform="translate(-1727 -19)"
                  >
                    <g
                      id="Ellipse_7706"
                      data-name="Ellipse 7706"
                      transform="translate(1727 19)"
                      fill="none"
                      stroke="#bcc1ce"
                      stroke-width="1.5"
                    >
                      <circle cx="9" cy="9" r="9" stroke="none"></circle>
                      <circle cx="9" cy="9" r="8.25" fill="none"></circle>
                    </g>
                    <line
                      id="Line_343"
                      data-name="Line 343"
                      y1="2"
                      transform="translate(1736 23)"
                      fill="none"
                      stroke="#bcc1ce"
                      stroke-miterlimit="10"
                      stroke-width="1.5"
                    ></line>
                    <path
                      id="Path_98224"
                      data-name="Path 98224"
                      d="M0,2V-4"
                      transform="translate(1736 31)"
                      fill="none"
                      stroke="#bcc1ce"
                      stroke-width="1.5"
                    ></path>
                  </g>
                </svg>
                <p class="cms-posts-tooltipsubheader">
                  A post is a time stamped article organized through categories
                  and tags. Most recent posts will appear first.
                </p>
              </p>
            </h1>
          </div>
          <div className="post-heading category-heading">
            <h1>Categories</h1>
            {!spinner && (
              
              <div className="category-mobile-header-btns">


                {
                    window.matchMedia("(max-width: 1200px)").matches &&
                   <>
                    {
                      ( activeSettingTab === false && activeLayoutTab === false ) ? 
                    
                //     <div className="consent-setting-clicked" style={{marginRight: "0px",marginLeft:"20px" ,cursor:"default"}}>
                //             <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                //        <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                //     <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                //     <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                //   </g>
                // </svg>
                //             </div>
                <></>
                    :
                  <div className="notification-setting " style={{marginRight: "0px",marginLeft:"20px"}} 
                  onClick={(e) => handleHomeBtn(e)}
                  >
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>
    

                        </div>
                    }
                    </>
                }
                
             {
              categoryData && categoryData.length > 0 &&
              <>
           
              <button
                className={
                  activeLayoutTab === true
                 ? " setting-bt-off custom-layout-gap-mob tagpagelayout-svg"
                 :"setting-bt-on custom-layout-gap-mob tagpagelayout-svg"
                }
                onClick={(e) => handleLayoutIconClick(e)}
              >
                {/* <div className="setting-first-shadow"></div>
                <div className="setting-second-shadow"></div> */}
                { activeLayoutTab===true?
		
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 26 26">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_32448" data-name="Rectangle 32448" width="26" height="26" transform="translate(62 1)" fill="rgba(0,0,0,0)"/>
      </clipPath>
    </defs>
    <g id="Mask_Group_34872" data-name="Mask Group 34872" transform="translate(-62 -1)" clip-path="url(#clip-path)">
      <g id="Group_43107" data-name="Group 43107" transform="translate(63.887 2.887)">
        <g id="Rectangle_32447" data-name="Rectangle 32447" transform="translate(3.113 0.113)" fill="#7782a1" stroke="#7782a1" stroke-linejoin="round" stroke-width="1">
          <rect width="16" height="22" rx="1.5" stroke="none"/>
          <rect x="-0.5" y="-0.5" width="17" height="23" rx="2" fill="none"/>
        </g>
        <path id="Union_3151" data-name="Union 3151" d="M15359,21365v-2h6v2Zm-4,0v-2h2v2Zm4-6v-2h6v2Zm-4,0v-2h2v2Zm4-6v-2h6v2Zm-4,0v-2h2v2Z" transform="translate(-15348.887 -21346.887)" fill="#fff"/>
      </g>
    </g>
  </svg>
    :
<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 26 26">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_29591" data-name="Rectangle 29591" width="26" height="26" transform="translate(62 1)" fill="rgba(0,0,0,0)"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_25287" data-name="Mask Group 25287" transform="translate(-62 -1)" clip-path="url(#clip-path)">
    <g id="Group_39235" data-name="Group 39235" transform="translate(63.887 2.887)">
      <rect id="Rectangle_29590" data-name="Rectangle 29590" width="16" height="22" rx="1.5" transform="translate(3.113 0.113)" fill="none" stroke="#7782a1" stroke-linejoin="round" stroke-width="1.5"/>
      <path id="Path_119244" data-name="Path 119244" d="M11.113,5.113h6" transform="translate(-1)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
      <path id="Path_119245" data-name="Path 119245" d="M11.113,5.113h6" transform="translate(-1 6)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
      <path id="Path_119246" data-name="Path 119246" d="M11.113,5.113h6" transform="translate(-1 12)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
      <g id="Group_43104" data-name="Group 43104" transform="translate(6.113 4.363)">
        <path id="Path_119244-2" data-name="Path 119244" d="M11.113,5.113h2" transform="translate(-11.113 -4.363)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
        <path id="Path_119245-2" data-name="Path 119245" d="M11.113,5.113h2" transform="translate(-11.113 1.637)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
        <path id="Path_119246-2" data-name="Path 119246" d="M11.113,5.113h2" transform="translate(-11.113 7.637)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
      </g>
    </g>
  </g>
</svg>
    }
              </button>

              <button
                className={
                  activeSettingTab === true
                  ? " setting-bt-off custom-layout-gap-mob"
                 :"setting-bt-on custom-layout-gap-mob"
                }
                onClick={(e) => handleSettingIconClick(e)}
              >
                {/* <div className="setting-first-shadow"></div>
                <div className="setting-second-shadow"></div> */}
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23.5"
                  height="23.499"
                  viewBox="0 0 23.5 23.499"
                >
                  <g
                    id="Group_38368"
                    data-name="Group 38368"
                    transform="translate(-1598.25 -88.901)"
                  >
                    <path
                      id="Path_99646"
                      data-name="Path 99646"
                      d="M11.513,22.5a10.829,10.829,0,0,0,1.266-.074.615.615,0,0,0,.542-.642,2.437,2.437,0,0,1,1.488-2.388,2.278,2.278,0,0,1,.887-.171,2.527,2.527,0,0,1,1.855.788.616.616,0,0,0,.837.067,10.968,10.968,0,0,0,1.739-1.76.615.615,0,0,0-.066-.829,2.432,2.432,0,0,1,1.592-4.231h.056a.73.73,0,0,0,.72-.542,11,11,0,0,0,0-2.5.592.592,0,0,0-.613-.54h-.022A2.543,2.543,0,0,1,19.4,8.19a2.437,2.437,0,0,1,.613-2.744.614.614,0,0,0,.066-.836A10.919,10.919,0,0,0,18.3,2.851a.606.606,0,0,0-.377-.131.615.615,0,0,0-.454.2,2.532,2.532,0,0,1-1.855.785,2.259,2.259,0,0,1-.868-.165A2.449,2.449,0,0,1,13.238,1.2a.614.614,0,0,0-.544-.63,11.023,11.023,0,0,0-2.474,0,.615.615,0,0,0-.539.643A2.443,2.443,0,0,1,8.195,3.6a2.318,2.318,0,0,1-.89.172,2.524,2.524,0,0,1-1.853-.788.615.615,0,0,0-.837-.069,10.968,10.968,0,0,0-1.739,1.76.615.615,0,0,0,.066.829A2.43,2.43,0,0,1,1.279,9.733a.718.718,0,0,0-.708.54,11.037,11.037,0,0,0,0,2.5.617.617,0,0,0,.6.542h.181A2.464,2.464,0,0,1,3.6,14.808a2.438,2.438,0,0,1-.615,2.743.614.614,0,0,0-.066.837A11,11,0,0,0,4.7,20.145a.616.616,0,0,0,.83-.069,2.535,2.535,0,0,1,1.853-.785,2.3,2.3,0,0,1,.87.165A2.443,2.443,0,0,1,9.76,21.8a.614.614,0,0,0,.545.628A11.109,11.109,0,0,0,11.513,22.5Z"
                      transform="translate(1598.501 89.151)"
                      fill=""
                      stroke=""
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                    <path
                      id="Path_99647"
                      data-name="Path 99647"
                      d="M9.818,5.846a4,4,0,1,1-4,4A4,4,0,0,1,9.818,5.846Z"
                      transform="translate(1600.182 90.804)"
                      fill="rgba(256,256,256,1)"
                      stroke=""
                      stroke-linejoin="round"
                      stroke-width="1.5"
                    />
                  </g>
                </svg>
              </button>
              </>
             }
              </div>
            
            )}
          </div>
         { window.matchMedia("(max-width: 1200px)").matches && 
         <>
         {
         ( activeLayoutTab === false && activeSettingTab === false ) &&        
          <div className="notification-mob-create-btn postCreate-mob-btn" onClick={(e) => handleOpenCatPopUp(e, "delete")}>
                <p className=" update-mob-upgadelink" 
                style={{
                  marginBottom:"0px"
                }}
                >
                  <img src={ require("../../assets/images/dashboard/plus.png").default } className="img-fluid mr-2 symbolspace" alt="plus" />
                  Create
              </p>
                </div>
         }
         </>
             }

          <div className="pageshead category-pagehead"
          style={{
            padding:(window.matchMedia("(max-width: 1200px)").matches && (activeSettingTab ===true || activeLayoutTab === true ))?"0":""
          }}
          >
            <div className="post-sublinks">
              <Link
                to="/app/posts"
                style={{
                  "--custom_color4":
                    localStorage.getItem("agencyid") == 1
                      ? "#3064f9"
                      : localStorage.getItem("agency_primary_color"),
                  "--custom_color5":
                    localStorage.getItem("agencyid") == 1
                      ? "#3064f9"
                      : localStorage.getItem("agency_primary_color"),
                }}
              >
                Posts
              </Link>
              <Link
                className="active"
                to="/app/post-categories"
                style={{
                  "border-bottom":
                    localStorage.getItem("agencyid") == 1
                      ? "2px solid #3064f9"
                      : "2px solid " +
                        localStorage.getItem("agency_primary_color"),
                  color:
                    localStorage.getItem("agencyid") == 1
                      ? "#3064f9"
                      : localStorage.getItem("agency_primary_color"),
                  "--custom_color4":
                    localStorage.getItem("agencyid") == 1
                      ? "#3064f9"
                      : localStorage.getItem("agency_primary_color"),
                  "--custom_color5":
                    localStorage.getItem("agencyid") == 1
                      ? "#3064f9"
                      : localStorage.getItem("agency_primary_color"),
                }}
              >
                Categories
              </Link>
              <Link
                to="/app/post-tags"
                style={{
                  "--custom_color4":
                    localStorage.getItem("agencyid") == 1
                      ? "#3064f9"
                      : localStorage.getItem("agency_primary_color"),
                  "--custom_color5":
                    localStorage.getItem("agencyid") == 1
                      ? "#3064f9"
                      : localStorage.getItem("agency_primary_color"),
                }}
              >
                Tags
              </Link>
              <Link
                to="/app/post-attributes"
                style={{
                  "--custom_color4":
                    localStorage.getItem("agencyid") == 1
                      ? "#3064f9"
                      : localStorage.getItem("agency_primary_color"),
                  "--custom_color5":
                    localStorage.getItem("agencyid") == 1
                      ? "#3064f9"
                      : localStorage.getItem("agency_primary_color"),
                }}
              >
                Attributes
              </Link>
            </div>
            <div className="createpage allpages">
            {settingUpdatedAt && (activeSettingTab === true || activeLayoutTab === true) &&
              <p style={{
                display:window.matchMedia("(max-width: 1200px)").matches?"none":"block",
                textAlign:"center",
                color:"#bcc1ce",
                fontSize:"12px"
              }}>Updated  {moment(settingUpdatedAt).format("D MMMM YYYY, HH:mm")}{" "}</p>
            }

              {categoryData && categoryData.length > 0 && activeSettingTab ===false && activeLayoutTab === false && (
                <div className="cms-pages-search">
                  <input
                    placeholder="Search..."
                    type="text"
                    value={searchVal}
                    onChange={(e) => handleSearch(e)}
                  ></input>
                  <img
                    src={
                      require("../../assets/images/dashboard/cms/lens.png")
                        .default
                    }
                  />
                </div>
              )}

                  {
                    window.matchMedia("(min-width: 1200px)").matches && !spinner &&
                   <>
                  {
                      ( activeSettingTab === false && activeLayoutTab === false ) ? 
                    
                //     <div className="consent-setting-clicked" style={{marginRight: "0px",marginLeft:"20px" ,cursor:"default"}}>
                //             <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                //        <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                //     <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                //     <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="#7782a1" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                //   </g>
                // </svg>
                //             </div>
                <></>
                    :
                  <div className="notification-setting " style={{marginRight: "0px",marginLeft:"20px"}} 
                  onClick={(e) => handleHomeBtn(e)}
                  >
                            <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26">
                  <g id="Group_42975" data-name="Group 42975" transform="translate(-20 -527)">
                    <rect id="Rectangle_25572" data-name="Rectangle 25572" width="26" height="26" transform="translate(20 527)" fill="none"/>
                    <path id="Path_119115" data-name="Path 119115" d="M20.5,8.64v12h-7V12.656a.016.016,0,0,0-.016-.016H7.516a.016.016,0,0,0-.016.016V20.64H.5v-12l10-8Z" transform="translate(22.5 529.36)" fill="none" stroke="#7782a1" stroke-miterlimit="10" stroke-width="1.5"/>
                  </g>
                </svg>
    

                        </div>
                    }
                    </>
                  } 

               {
              categoryData && categoryData.length > 0 &&
              <>
              <button
                className={
                  activeLayoutTab === true
                 ? " setting-bt-off custom-layout-gap tagpagelayout-svg"
                 :"setting-bt-on custom-layout-gap tagpagelayout-svg"
                }
                onClick={(e) => handleLayoutIconClick(e)}
              >
                {/* <div className="setting-first-shadow"></div>
                <div className="setting-second-shadow"></div> */}
                { activeLayoutTab===true?
		
    <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 26 26">
    <defs>
      <clipPath id="clip-path3">
        <rect id="Rectangle_32448" data-name="Rectangle 32448" width="26" height="26" transform="translate(62 1)" fill="rgba(0,0,0,0)"/>
      </clipPath>
    </defs>
    <g id="Mask_Group_34872" data-name="Mask Group 34872" transform="translate(-62 -1)" clip-path="url(#clip-path3)">
      <g id="Group_43107" data-name="Group 43107" transform="translate(63.887 2.887)">
        <g id="Rectangle_32447" data-name="Rectangle 32447" transform="translate(3.113 0.113)" fill="#7782a1" stroke="#7782a1" stroke-linejoin="round" stroke-width="1">
          <rect width="16" height="22" rx="1.5" stroke="none"/>
          <rect x="-0.5" y="-0.5" width="17" height="23" rx="2" fill="none"/>
        </g>
        <path id="Union_3151" data-name="Union 3151" d="M15359,21365v-2h6v2Zm-4,0v-2h2v2Zm4-6v-2h6v2Zm-4,0v-2h2v2Zm4-6v-2h6v2Zm-4,0v-2h2v2Z" transform="translate(-15348.887 -21346.887)" fill="#fff"/>
      </g>
    </g>
  </svg>
    :
<svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="26" height="26" viewBox="0 0 26 26">
  <defs>
    <clipPath id="clip-path4">
      <rect id="Rectangle_29591" data-name="Rectangle 29591" width="26" height="26" transform="translate(62 1)" fill="rgba(0,0,0,0)"/>
    </clipPath>
  </defs>
  <g id="Mask_Group_25287" data-name="Mask Group 25287" transform="translate(-62 -1)" clip-path="url(#clip-path4)">
    <g id="Group_39235" data-name="Group 39235" transform="translate(63.887 2.887)">
      <rect id="Rectangle_29590" data-name="Rectangle 29590" width="16" height="22" rx="1.5" transform="translate(3.113 0.113)" fill="none" stroke="#7782a1" stroke-linejoin="round" stroke-width="1.5"/>
      <path id="Path_119244" data-name="Path 119244" d="M11.113,5.113h6" transform="translate(-1)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
      <path id="Path_119245" data-name="Path 119245" d="M11.113,5.113h6" transform="translate(-1 6)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
      <path id="Path_119246" data-name="Path 119246" d="M11.113,5.113h6" transform="translate(-1 12)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
      <g id="Group_43104" data-name="Group 43104" transform="translate(6.113 4.363)">
        <path id="Path_119244-2" data-name="Path 119244" d="M11.113,5.113h2" transform="translate(-11.113 -4.363)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
        <path id="Path_119245-2" data-name="Path 119245" d="M11.113,5.113h2" transform="translate(-11.113 1.637)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
        <path id="Path_119246-2" data-name="Path 119246" d="M11.113,5.113h2" transform="translate(-11.113 7.637)" fill="none" stroke="#7782a1" stroke-width="1.5"/>
      </g>
    </g>
  </g>
</svg>
    }
              </button>

            
              <div
                         onClick={(e) => handleSettingIconClick(e)}
                          className="cms-setting-btn web-cms-setting-btn"
                        >
                          <SettingIcon
                           
                            isActive={activeSettingTab}
                          />
                        </div>
              </>
              }

              <div>
                {!spinner && (
                  <>
                    {activeLayoutTab === false && activeSettingTab === false ? (
                      <p 
                        className="createpage d-none d-xl-flex"
                        onClick={(e) => handleOpenCatPopUp(e, "delete")}
                        style={{
                         marginBottom:"0px",
                         cursor:"pointer",
                          backgroundColor: localStorage.getItem(
                            "button_background_color"
                          ),
                          border:
                            "1px solid " +
                            localStorage.getItem("button_background_color"),
                          color: localStorage.getItem("button_text_color"),
                          "--custom_color5":
                            localStorage.getItem("agencyid") == 1
                              ? "#134bec"
                              : localStorage.getItem("button_hover_bgcolor"),
                          "--custom_color4":
                            localStorage.getItem("agencyid") == 1
                              ? "white"
                              : localStorage.getItem("button_hover_txtcolor"),
                          "--custom_color6":
                            localStorage.getItem("agencyid") == 1
                              ? "#3064f9"
                              : localStorage.getItem("button_hover_bgcolor"),
                        }}
                      >
                        <img
                          className="mr-2"
                          src={
                            require("../../assets/images/dashboard/cms/plus.png")
                              .default
                          }
                        />{" "}
                        <span
                          style={{
                            marginTop: "2px",
                          }}
                        >
                          Create 
                        </span>
                      </p>
                    ) : (
                      !window.matchMedia("(max-width: 1200px)").matches &&
                      <button
                        className="createpage"
                        onClick={(e) => handleSubmitCatSettingsData(e)}
                        style={{
                          backgroundColor: localStorage.getItem(
                            "button_background_color"
                          ),
                          width: "100px",
                          border:
                            "1px solid " +
                            localStorage.getItem("button_background_color"),
                          color: localStorage.getItem("button_text_color"),
                          "--custom_color5":
                            localStorage.getItem("agencyid") == 1
                              ? "#134bec"
                              : localStorage.getItem("button_hover_bgcolor"),
                          "--custom_color4":
                            localStorage.getItem("agencyid") == 1
                              ? "white"
                              : localStorage.getItem("button_hover_txtcolor"),
                          "--custom_color6":
                            localStorage.getItem("agencyid") == 1
                              ? "#3064f9"
                              : localStorage.getItem("button_hover_bgcolor"),
                        }}
                      >
                        <div className={settingSaveBtnLoader}>
                          <div className="new-loader"></div>
                        </div>
                        <div>{settingSaveBtnVal}</div>
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>

          </div>

          {activeSettingTab === false && activeLayoutTab === false ? (
            <>
              <div className="whitebg add-category-page">
                {categoryData.length > 0 && categoryData !== null && (
                  <div className="category-list-title">
                    <h2>Category</h2>
                    <h5>Posts</h5>
                  </div>
                )}

                <div
                  // className="cms-category-listing"
                  style={{
                    display:
                      categoryData && categoryData.length > 0 ? "flex" : "",
                  }}
                >
                 <BottomScrollListener
              onBottom={window.matchMedia("(min-width: 1200px)").matches?handleContainerOnBottom:handleNotHitOnBottom}
              offset={100}
            >
              {(scrollRef) => (
                <div
                ref={scrollRef}
                  
                  className="category-tag-view tagpage-main-div">
                 
                <div
                     className="cats-list"
                      style={{
                        position: "relative",
                      }}
                >
                      {spinner === true ? (
                        <div
                          className="cmsnewspinner"
                          style={{
                            position: "absolute",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            style={{
                              margin: "auto",
                              background: "rgb(241, 242, 243,0)",
                              display: "block",
                              shaperendering: "auto",
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                            width="200px"
                            height="200px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                          >
                            <g
                              transform="translate(20 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#e6261f"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.375s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(40 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#f7d038"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.25s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(60 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#49da9a"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.125s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(80 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#4355db"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="0s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                          </svg>
                        </div>
                      ) : categoryData && categoryData.length > 0 ? (
                        <>
                          {recurs(categoryData, "parent")}

                          {bottomSpinner && (
                            <div
                              className="form-group"
                              style={{ background: "none", margin: "auto" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xlink="http://www.w3.org/1999/xlink"
                                style={{
                                  margin: "auto",
                                  background: "rgb(241, 242, 243,0)",
                                  display: "block",
                                  shaperendering: "auto",
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                                width="200px"
                                height="100px"
                                viewBox="0 0 100 100"
                                preserveAspectRatio="xMidYMid"
                              >
                                <g
                                  transform="translate(20 50)"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <circle
                                    cx="0"
                                    cy="0"
                                    r="6"
                                    fill="#e6261f"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      type="scale"
                                      begin="-0.375s"
                                      calcMode="spline"
                                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                      values="0;1;0"
                                      keyTimes="0;0.5;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    ></animateTransform>
                                  </circle>
                                </g>
                                <g
                                  transform="translate(40 50)"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <circle
                                    cx="0"
                                    cy="0"
                                    r="6"
                                    fill="#f7d038"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      type="scale"
                                      begin="-0.25s"
                                      calcMode="spline"
                                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                      values="0;1;0"
                                      keyTimes="0;0.5;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    ></animateTransform>
                                  </circle>
                                </g>
                                <g
                                  transform="translate(60 50)"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <circle
                                    cx="0"
                                    cy="0"
                                    r="6"
                                    fill="#49da9a"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      type="scale"
                                      begin="-0.125s"
                                      calcMode="spline"
                                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                      values="0;1;0"
                                      keyTimes="0;0.5;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    ></animateTransform>
                                  </circle>
                                </g>
                                <g
                                  transform="translate(80 50)"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <circle
                                    cx="0"
                                    cy="0"
                                    r="6"
                                    fill="#4355db"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  >
                                    <animateTransform
                                      attributeName="transform"
                                      type="scale"
                                      begin="0s"
                                      calcMode="spline"
                                      keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                      values="0;1;0"
                                      keyTimes="0;0.5;1"
                                      dur="1s"
                                      repeatCount="indefinite"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    ></animateTransform>
                                  </circle>
                                </g>
                              </svg>
                            </div>
                          )}

                    {paginationSpinner && window.matchMedia("(min-width: 1200px)").matches && (
                     <div className={`form-group`} style={{ background: "none", margin: "auto" }}>

                    <svg xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                    width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        </animateTransform>
                        </circle>
                    </g>
                    <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        </animateTransform>
                        </circle>
                    </g>
                    <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>

                        </animateTransform>
                        </circle>
                    </g>
                    <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>

                        </animateTransform>
                        </circle>
                    </g>
                    </svg>
                     </div>
                    )}


                        </>
                      ) : bottomSpinner ? (
                        <div className="newspinner">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xlink="http://www.w3.org/1999/xlink"
                            style={{
                              margin: "auto",
                              background: "rgb(241, 242, 243,0)",
                              display: "block",
                              shaperendering: "auto",
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                            width="200px"
                            height="200px"
                            viewBox="0 0 100 100"
                            preserveAspectRatio="xMidYMid"
                          >
                            <g
                              transform="translate(20 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#e6261f"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.375s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(40 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#f7d038"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.25s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(60 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#49da9a"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="-0.125s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                            <g
                              transform="translate(80 50)"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <circle
                                cx="0"
                                cy="0"
                                r="6"
                                fill="#4355db"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <animateTransform
                                  attributeName="transform"
                                  type="scale"
                                  begin="0s"
                                  calcMode="spline"
                                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                  values="0;1;0"
                                  keyTimes="0;0.5;1"
                                  dur="1s"
                                  repeatCount="indefinite"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                ></animateTransform>
                              </circle>
                            </g>
                          </svg>
                        </div>
                      ) : (
                        <div className="cat-tag">
                          <div className="no-categorydata">
                            <img
                              src={
                                require("../../assets/images/dashboard/nothing-cat.png")
                                  .default
                              }
                            />
                            <p>Nothing here at the moment. Come back later?</p>
                          </div>
                        </div>
                      )}
                    </div>
             
                  </div>
                   )}
                   </BottomScrollListener>

                  {categoryData !== null && categoryData.length > 0 && (
                    <div className="category-preview-list">
                       <CategoryPreview 
                         previewData={
                          {
                            bgColor:bgColor,
                            showSearchInHeader:showSearchInHeader,
                            showBookmarkInHeader:showBookmarkInHeader,
                            catLayout:catLayout,
                            categoryData:categoryData,
                            catImgShape:catImgShape,
                            showCatImg:showCatImg,
                            noCatImg:noCatImg,
                            calculateHeight:calculateHeight,
                            catImgRatio:catImgRatio,
                            textColor:textColor,
                            column:column,
                            catImgOverlayColor:catImgOverlayColor,
                            catTileBgColor:catTileBgColor,
                            showCenterCropImages:showCenterCropImages,
                          }
                        }/>
                      
                    </div>
                  )}
                </div>
              </div>

              <div className="category-mobile-view">
                {spinner === true ? (
                  <div className="newspinner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                      width="200px"
                      height="200px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g
                        transform="translate(20 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#e6261f"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.375s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(40 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#f7d038"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(60 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#49da9a"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(80 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#4355db"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                ) : categoryData !== null && categoryData.length > 0 ? (
                  <>
                    {recursForMobileView(categoryData, "parent")}

                    {bottomSpinner && (
                      <div
                        className="form-group"
                        style={{ background: "none", margin: "auto" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          style={{
                            margin: "auto",
                            background: "rgb(241, 242, 243,0)",
                            display: "block",
                            shaperendering: "auto",
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                          width="200px"
                          height="100px"
                          viewBox="0 0 100 100"
                          preserveAspectRatio="xMidYMid"
                        >
                          <g
                            transform="translate(20 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#e6261f"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.375s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                          <g
                            transform="translate(40 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#f7d038"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.25s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                          <g
                            transform="translate(60 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#49da9a"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.125s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                          <g
                            transform="translate(80 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#4355db"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="0s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                        </svg>
                      </div>
                    )}

                   {paginationSpinner && window.matchMedia("(max-width: 1200px)").matches &&(
                    <div className={`form-group`} style={{ background: "none", margin: "auto" }}>

                    <svg xmlns="http://www.w3.org/2000/svg"
                    xlink="http://www.w3.org/1999/xlink"
                    style={{ margin: "auto", background: "rgb(241, 242, 243,0)", display: "block", shaperendering: "auto", animationplaystate: "running", animationdelay: "0s" }}
                    width="200px" height="100px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                    <g transform="translate(20 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#e6261f" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="-0.375s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        </animateTransform>
                        </circle>
                    </g>
                    <g transform="translate(40 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#f7d038" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="-0.25s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        </animateTransform>
                        </circle>
                    </g>
                    <g transform="translate(60 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#49da9a" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="-0.125s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>

                        </animateTransform>
                        </circle>
                    </g>
                    <g transform="translate(80 50)" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <circle cx="0" cy="0" r="6" fill="#4355db" style={{ animationplaystate: "running", animationdelay: "0s" }}>
                        <animateTransform attributeName="transform" type="scale" begin="0s" calcMode="spline" keySplines="0.3 0 0.7 1;0.3 0 0.7 1" values="0;1;0" keyTimes="0;0.5;1" dur="1s" repeatCount="indefinite"
                            style={{ animationplaystate: "running", animationdelay: "0s" }}>

                        </animateTransform>
                        </circle>
                    </g>
                    </svg>
                    </div>
                   )}


                  </>
                ) : bottomSpinner ? (
                  <div className="newspinner">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      xlink="http://www.w3.org/1999/xlink"
                      style={{
                        margin: "auto",
                        background: "rgb(241, 242, 243,0)",
                        display: "block",
                        shaperendering: "auto",
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                      width="200px"
                      height="200px"
                      viewBox="0 0 100 100"
                      preserveAspectRatio="xMidYMid"
                    >
                      <g
                        transform="translate(20 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#e6261f"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.375s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(40 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#f7d038"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.25s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(60 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#49da9a"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="-0.125s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                      <g
                        transform="translate(80 50)"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      >
                        <circle
                          cx="0"
                          cy="0"
                          r="6"
                          fill="#4355db"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <animateTransform
                            attributeName="transform"
                            type="scale"
                            begin="0s"
                            calcMode="spline"
                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                            values="0;1;0"
                            keyTimes="0;0.5;1"
                            dur="1s"
                            repeatCount="indefinite"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          ></animateTransform>
                        </circle>
                      </g>
                    </svg>
                  </div>
                ) : (
                  <div className="cat-tag">
                    <div className="no-categorydata">
                      <img
                        src={
                          require("../../assets/images/dashboard/nothing-cat.png")
                            .default
                        }
                      />
                      <p>Nothing here at the moment. Come back later?</p>
                    </div>
                  </div>
                )}
              </div>
            </>
          ) : activeLayoutTab === true ? (
            <div className="tags-layout-preview">
              <div className="taglayoutbg">
                <div className="tag-layout-header">
                  <h1>Layout</h1>
                  <p>
                    Choose a template to style the appearance of categories in
                    your app.
                  </p>
                </div>
                <FormGroup tag="fieldset" className="select-tag-type">
                  <FormGroup>
                    <Label className="tagtype-ratio">
                      <Input
                        type="radio"
                        name="addblockradioCat1"
                        checked={catLayout === "layout-1"}
                        onChange={(e) => handleChangeLayout(e, "layout-1")}
                      />

                      <div className="tag-layout">
                        <img
                          className="img-fluid"
                          src={
                            require("../../assets/images/dashboard/cat-layout-one.png")
                              .default
                          }
                        />
                      </div>
                    </Label>
                  </FormGroup>

                  <FormGroup>
                    <Label className="tagtype-ratio">
                      <Input
                        type="radio"
                        name="addblockradioCat2"
                        checked={catLayout === "layout-2"}
                        onChange={(e) => handleChangeLayout(e, "layout-2")}
                      />
                      <div className="tag-layout">
                        <img
                          className="img-fluid"
                          src={
                            require("../../assets/images/dashboard/cat-layout-two.png")
                              .default
                          }
                        />
                      </div>
                    </Label>
                  </FormGroup>

                  <FormGroup>
                    <Label className="tagtype-ratio">
                      <Input
                        type="radio"
                        name="addblockradioCat3"
                        checked={catLayout === "layout-3"}
                        onChange={(e) => handleChangeLayout(e, "layout-3")}
                      />
                      <div className="tag-layout">
                        <img
                          className="img-fluid"
                          src={
                            require("../../assets/images/dashboard/cat-layout-three.png")
                              .default
                          }
                        />
                      </div>
                    </Label>
                  </FormGroup>

                  <FormGroup>
                    <Label className="tagtype-ratio">
                      <Input
                        type="radio"
                        name="addblockradioCat4"
                        checked={catLayout === "layout-4"}
                        onChange={(e) => handleChangeLayout(e, "layout-4")}
                      />
                      <div className="tag-layout">
                        <img
                          className="img-fluid"
                          src={
                            require("../../assets/images/dashboard/cat-layout-four.png")
                              .default
                          }
                        />
                      </div>
                    </Label>
                  </FormGroup>

                  <FormGroup>
                    <Label className="tagtype-ratio">
                      <Input
                        type="radio"
                        name="addblockradioCat5"
                        checked={catLayout === "layout-5"}
                        onChange={(e) => handleChangeLayout(e, "layout-5")}
                      />
                      <div className="tag-layout">
                        <img
                          className="img-fluid"
                          src={
                            require("../../assets/images/dashboard/cat-layout-five.png")
                              .default
                          }
                        />
                      </div>
                    </Label>
                  </FormGroup>
                </FormGroup>
              </div>
              {window.matchMedia("(max-width: 1200px)").matches &&
              <div className="post-list-setting-btn-mobile">
              <button
                     
                        onClick={(e) => handleSubmitCatSettingsData(e)}
                        style={{
                          // width:"100%",
                          backgroundColor: localStorage.getItem(
                            "button_background_color"
                          ),
                          border:
                            "1px solid " +
                            localStorage.getItem("button_background_color"),
                          color: localStorage.getItem("button_text_color"),
                          "--custom_color5":
                            localStorage.getItem("agencyid") == 1
                              ? "#134bec"
                              : localStorage.getItem("button_hover_bgcolor"),
                          "--custom_color4":
                            localStorage.getItem("agencyid") == 1
                              ? "white"
                              : localStorage.getItem("button_hover_txtcolor"),
                          "--custom_color6":
                            localStorage.getItem("agencyid") == 1
                              ? "#3064f9"
                              : localStorage.getItem("button_hover_bgcolor"),
                        }}
                      >
                        <div className={settingSaveBtnLoader}>
                        <div className="d-flex justify-content-center align-items-center ">

<div className="new-loader"></div>
</div>
                        </div>
                        <div>{settingSaveBtnVal}</div>
                      </button> 
                      </div>
                      }
                         {settingUpdatedAt &&
              <p style={{
                display:window.matchMedia("(max-width: 1200px)").matches?"block":"none",
                textAlign:"center",
                color:"#bcc1ce",
                fontSize:"12px"
              }}>Updated  {moment(settingUpdatedAt).format("D MMMM YYYY, HH:mm")}{" "}</p>
            }
            </div>
          ) : (
            <div className="tags-layout-setting">
              <div className="categorysettingbg">
                <div className="categorysetting-width">
                  <div className="categorysetting-left mob-category-layout">
                    <div className="tagsetting-left-header">
                      <h1>Settings</h1>
                      <p>
                        Customize the appearance of categories in your post.
                      </p>
                    </div>

                    <div className="category-setting-toggle">
                      <h4>
                      Show search icon
                        <h5 className="catsetting-tooltip">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                          >
                            <g
                              id="Group_40296"
                              data-name="Group 40296"
                              transform="translate(-1727 -19.132)"
                            >
                              <g
                                id="Ellipse_7706"
                                data-name="Ellipse 7706"
                                transform="translate(1727 19.132)"
                                fill="#fff"
                                stroke="#bcc1ce"
                                stroke-width="1.2"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="7"
                                  stroke="none"
                                ></circle>
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6.4"
                                  fill="none"
                                ></circle>
                              </g>
                              <path
                                id="Path_98061"
                                data-name="Path 98061"
                                d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                transform="translate(1725.126 17.539)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></path>
                              <line
                                id="Line_343"
                                data-name="Line 343"
                                y2="1.2"
                                transform="translate(1734 29.132)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></line>
                            </g>
                          </svg>
                          <h5 className="catsetting-tooltipsubheader">
                          Enable to display a search icon in header for quicker access to relevant content.
                          </h5>
                        </h5>
                      </h4>
                      <label class="switch" for="show_tag_in_header">
                        <input
                          type="checkbox"
                          id="show_tag_in_header"
                          name="show_tag_in_header1"
                          checked={showSearchInHeader}
                          onChange={(e) => handleShowSearchToggle(e)}
                        />
                        <div className="slider round"></div>
                      </label>
                    </div>

                    <div className="category-setting-toggle" style={{marginBottom: "0px"}}>
                      <h4>
                      Show bookmark icon
                        <h5 className="catsetting-tooltip">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                          >
                            <g
                              id="Group_40296"
                              data-name="Group 40296"
                              transform="translate(-1727 -19.132)"
                            >
                              <g
                                id="Ellipse_7706"
                                data-name="Ellipse 7706"
                                transform="translate(1727 19.132)"
                                fill="#fff"
                                stroke="#bcc1ce"
                                stroke-width="1.2"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="7"
                                  stroke="none"
                                ></circle>
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6.4"
                                  fill="none"
                                ></circle>
                              </g>
                              <path
                                id="Path_98061"
                                data-name="Path 98061"
                                d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                transform="translate(1725.126 17.539)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></path>
                              <line
                                id="Line_343"
                                data-name="Line 343"
                                y2="1.2"
                                transform="translate(1734 29.132)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></line>
                            </g>
                          </svg>
                          <h5 className="catsetting-tooltipsubheader">
                          Enable to display a bookmark icon in the header for simplified navigation to bookmarked posts.
                          </h5>
                        </h5>
                      </h4>
                      <label class="switch" for="show_bookmark">
                        <input
                          type="checkbox"
                          id="show_bookmark"
                          name="show_tag_in_header3"
                          checked={showBookmarkInHeader}
                          onChange={(e) => handleShowBookmarkToggle(e)}
                        />
                        <div className="slider round"></div>
                      </label>
                    </div>
                    {(catLayout === "layout-2" || catLayout === "layout-3") && (
                      <div className="category-column-number" style={{marginTop: "30px"}}>
                        <h6>Columns <h5 className="catsetting-tooltip">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                          >
                            <g
                              id="Group_40296"
                              data-name="Group 40296"
                              transform="translate(-1727 -19.132)"
                            >
                              <g
                                id="Ellipse_7706"
                                data-name="Ellipse 7706"
                                transform="translate(1727 19.132)"
                                fill="#fff"
                                stroke="#bcc1ce"
                                stroke-width="1.2"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="7"
                                  stroke="none"
                                ></circle>
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6.4"
                                  fill="none"
                                ></circle>
                              </g>
                              <path
                                id="Path_98061"
                                data-name="Path 98061"
                                d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                transform="translate(1725.126 17.539)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></path>
                              <line
                                id="Line_343"
                                data-name="Line 343"
                                y2="1.2"
                                transform="translate(1734 29.132)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></line>
                            </g>
                          </svg>
                          <h5 className="catsetting-tooltipsubheader">
                          Specify the number of columns.
                          </h5>
                        </h5></h6>
                        <input
                          type="number"
                          min={"2"}
                          max={"4"}
                          maxLength={4}
                          minLength={2}
                          value={column}
                          onChange={(e) => handleColumnChange(e)}
                        />
                      </div>
                    )}

                    <div className="row" style={{marginTop: "15px"}}>
                      <div className="col-md-6">
                        <h6>Background color <h5 className="catsetting-tooltip">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                          >
                            <g
                              id="Group_40296"
                              data-name="Group 40296"
                              transform="translate(-1727 -19.132)"
                            >
                              <g
                                id="Ellipse_7706"
                                data-name="Ellipse 7706"
                                transform="translate(1727 19.132)"
                                fill="#fff"
                                stroke="#bcc1ce"
                                stroke-width="1.2"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="7"
                                  stroke="none"
                                ></circle>
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6.4"
                                  fill="none"
                                ></circle>
                              </g>
                              <path
                                id="Path_98061"
                                data-name="Path 98061"
                                d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                transform="translate(1725.126 17.539)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></path>
                              <line
                                id="Line_343"
                                data-name="Line 343"
                                y2="1.2"
                                transform="translate(1734 29.132)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></line>
                            </g>
                          </svg>
                          <h5 className="catsetting-tooltipsubheader">
                          Set the background color for the page.
                          </h5>
                        </h5></h6>
                        <Reactcolorpicker
                          colorname={bgColor}
                          onChange={(e) => handleBgColor(e)}
                          classselection={"fixback"}
                          valuecontrol={false}
                        />
                      </div>
                      <div className="col-md-6">
                        <h6>Category text color <h5 className="catsetting-tooltip">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                          >
                            <g
                              id="Group_40296"
                              data-name="Group 40296"
                              transform="translate(-1727 -19.132)"
                            >
                              <g
                                id="Ellipse_7706"
                                data-name="Ellipse 7706"
                                transform="translate(1727 19.132)"
                                fill="#fff"
                                stroke="#bcc1ce"
                                stroke-width="1.2"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="7"
                                  stroke="none"
                                ></circle>
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6.4"
                                  fill="none"
                                ></circle>
                              </g>
                              <path
                                id="Path_98061"
                                data-name="Path 98061"
                                d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                transform="translate(1725.126 17.539)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></path>
                              <line
                                id="Line_343"
                                data-name="Line 343"
                                y2="1.2"
                                transform="translate(1734 29.132)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></line>
                            </g>
                          </svg>
                          <h5 className="catsetting-tooltipsubheader">
                          Define the color of the text within the category tiles.
                          </h5>
                        </h5></h6>
                        <Reactcolorpicker
                          colorname={textColor}
                          onChange={(e) => handleTextColor(e)}
                          classselection={"fixback"}
                          valuecontrol={true}
                        />
                      </div>
                    </div>
                    {
                      catLayout === "layout-3"
                    &&
                    <div className="row">
                      <div className="col-md-6">
                        <h6>Tint<h5 className="catsetting-tooltip">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                          >
                            <g
                              id="Group_40296"
                              data-name="Group 40296"
                              transform="translate(-1727 -19.132)"
                            >
                              <g
                                id="Ellipse_7706"
                                data-name="Ellipse 7706"
                                transform="translate(1727 19.132)"
                                fill="#fff"
                                stroke="#bcc1ce"
                                stroke-width="1.2"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="7"
                                  stroke="none"
                                ></circle>
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6.4"
                                  fill="none"
                                ></circle>
                              </g>
                              <path
                                id="Path_98061"
                                data-name="Path 98061"
                                d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                transform="translate(1725.126 17.539)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></path>
                              <line
                                id="Line_343"
                                data-name="Line 343"
                                y2="1.2"
                                transform="translate(1734 29.132)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></line>
                            </g>
                          </svg>
                          <h5 className="catsetting-tooltipsubheader">
                          Select a tint to overlay your category tiles for a customized look. Apply the tint color to subtly shade your category tiles.
                          </h5>
                        </h5></h6>
                        <div className="color-picker-cross">
                          <Reactcolorpicker
                            colorname={catImgOverlayColor}
                            onChange={(e) => handleCatImgOverlayBgColor(e)}
                            classselection={"fixback"}
                            valuecontrol={true}
                          />
                          {catImgOverlayColor && (
                            <svg
                            style={{
                              cursor:"pointer"
                            }}
                              onClick={(e) => {
                                setCatImgOverlayColor("");
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="11.06"
                              height="11.06"
                              viewBox="0 0 11.06 11.06"
                            >
                              <path
                                id="Union_1696"
                                data-name="Union 1696"
                                d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z"
                                transform="translate(0.53 0.53)"
                                fill="none"
                                stroke="#7782a1"
                                stroke-width="1.5"
                              />
                            </svg>
                          )}
                        </div>
                      </div>
                    </div>
                   }

{
                     ( catLayout === "layout-4" || catLayout === "layout-5"  )
                    &&
                    <div className="row">
                      <div className="col-md-6">
                        <h6>Category tile color <h5 className="catsetting-tooltip">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                          >
                            <g
                              id="Group_40296"
                              data-name="Group 40296"
                              transform="translate(-1727 -19.132)"
                            >
                              <g
                                id="Ellipse_7706"
                                data-name="Ellipse 7706"
                                transform="translate(1727 19.132)"
                                fill="#fff"
                                stroke="#bcc1ce"
                                stroke-width="1.2"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="7"
                                  stroke="none"
                                ></circle>
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6.4"
                                  fill="none"
                                ></circle>
                              </g>
                              <path
                                id="Path_98061"
                                data-name="Path 98061"
                                d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                transform="translate(1725.126 17.539)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></path>
                              <line
                                id="Line_343"
                                data-name="Line 343"
                                y2="1.2"
                                transform="translate(1734 29.132)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></line>
                            </g>
                          </svg>
                          <h5 className="catsetting-tooltipsubheader">
                          Select a color for your category tiles.
                          </h5>
                        </h5></h6>
                        <div className="color-picker-cross">
                          <Reactcolorpicker
                            colorname={catTileBgColor}
                            onChange={(e) => handleTileBgColor(e)}
                            classselection={"fixback"}
                            valuecontrol={false}
                          />
                          {/* {catImgOverlayColor && (
                            <svg
                              onClick={(e) => {
                                setCatImgOverlayColor("");
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              width="11.06"
                              height="11.06"
                              viewBox="0 0 11.06 11.06"
                            >
                              <path
                                id="Union_1696"
                                data-name="Union 1696"
                                d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z"
                                transform="translate(0.53 0.53)"
                                fill="none"
                                stroke="#7782a1"
                                stroke-width="1.5"
                              />
                            </svg>
                          )} */}
                        </div>
                      </div>
                    </div>
                   }

                    <div className="category-setting-toggle">
                      <h4>
                      Show category image
                        <h5 className="catsetting-tooltip">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                          >
                            <g
                              id="Group_40296"
                              data-name="Group 40296"
                              transform="translate(-1727 -19.132)"
                            >
                              <g
                                id="Ellipse_7706"
                                data-name="Ellipse 7706"
                                transform="translate(1727 19.132)"
                                fill="#fff"
                                stroke="#bcc1ce"
                                stroke-width="1.2"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="7"
                                  stroke="none"
                                ></circle>
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6.4"
                                  fill="none"
                                ></circle>
                              </g>
                              <path
                                id="Path_98061"
                                data-name="Path 98061"
                                d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                transform="translate(1725.126 17.539)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></path>
                              <line
                                id="Line_343"
                                data-name="Line 343"
                                y2="1.2"
                                transform="translate(1734 29.132)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></line>
                            </g>
                          </svg>
                          <h5 className="catsetting-tooltipsubheader">
                          Toggle the display of category images
                          </h5>
                        </h5>
                      </h4>
                      <label class="switch" for="show_cat_img">
                        <input
                          type="checkbox"
                          id="show_cat_img"
                          name="show_cat_img1"
                          checked={showCatImg}
                          onChange={(e) => handleShowCatChange(e)}
                        />
                        <div className="slider round"></div>
                      </label>
                    </div>


                    {
                      ( ( catLayout === "layout-3" && catImgOverlayColor ) || showCatImg ) ?
                    

                    <div className="category-image-dimension">
                      <h4>
                      {`${showCatImg?"Image":"Box"} dimensions`}
                        <h5 className="catsetting-tooltip">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                          >
                            <g
                              id="Group_40296"
                              data-name="Group 40296"
                              transform="translate(-1727 -19.132)"
                            >
                              <g
                                id="Ellipse_7706"
                                data-name="Ellipse 7706"
                                transform="translate(1727 19.132)"
                                fill="#fff"
                                stroke="#bcc1ce"
                                stroke-width="1.2"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="7"
                                  stroke="none"
                                ></circle>
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6.4"
                                  fill="none"
                                ></circle>
                              </g>
                              <path
                                id="Path_98061"
                                data-name="Path 98061"
                                d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                transform="translate(1725.126 17.539)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></path>
                              <line
                                id="Line_343"
                                data-name="Line 343"
                                y2="1.2"
                                transform="translate(1734 29.132)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></line>
                            </g>
                          </svg>
                          <h5 className="catsetting-tooltipsubheader">
                          Choose the desired dimensions for category images.
                          </h5>
                        </h5>
                      </h4>

                      <div>
                        <FormGroup
                          tag="fieldset"
                          className="select-cat-dimension"
                        >
                          <FormGroup>
                            <Label
                              className="select-cat-dimension-ratio"
                              htmlFor={"toggle11"}
                            >
                              <Input
                                type="radio"
                                id={"toggle11"}
                                value={"16:9"}
                                checked={catImgRatio === "16:9"}
                                onChange={(e) => handleRatioChange(e)}
                              />

                              <div className="cat-dimension-layout">
                              <span class="cms-icon-tooltip">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="13.5"
                                  viewBox="0 0 24 13.5"
                                >
                                  <g
                                    id="Rectangle_18739"
                                    data-name="Rectangle 18739"
                                    fill="#fff"
                                    stroke=""
                                    stroke-width="2"
                                  >
                                    <rect
                                      width="24"
                                      height="13.5"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="22"
                                      height="11.5"
                                      fill="none"
                                    />
                                  </g>
                                </svg>
                                <p>16:9</p>
                  <span class="cms-icon-tooltipsubheader">Select for a widescreen image format, ideal for panoramic views and cinematic visuals.</span>
                  </span>
                              </div>
                            </Label>
                          </FormGroup>

                          <FormGroup>
                            <Label
                              className="select-cat-dimension-ratio"
                              htmlFor={"toggle22"}
                            >
                              <Input
                                type="radio"
                                id={"toggle22"}
                                value={"4:3"}
                                checked={catImgRatio === "4:3"}
                                onChange={(e) => handleRatioChange(e)}
                              />
                              <div className="cat-dimension-layout">
                              <span class="cms-icon-tooltip">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="20"
                                  height="15"
                                  viewBox="0 0 20 15"
                                >
                                  <g
                                    id="Rectangle_18739"
                                    data-name="Rectangle 18739"
                                    fill="#fff"
                                    stroke=""
                                    stroke-width="2"
                                  >
                                    <rect
                                      width="20"
                                      height="15"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="18"
                                      height="13"
                                      fill="none"
                                    />
                                  </g>
                                </svg>
                                <p>4:3</p>
                                <span class="cms-icon-tooltipsubheader">Opt to maintain a classic image aspect ratio, suitable for balanced compositions.</span>
                  </span>
                              </div>
                            </Label>
                          </FormGroup>

                          <FormGroup>
                            <Label
                              className="select-cat-dimension-ratio"
                              htmlFor={"toggle33"}
                            >
                              <Input
                                type="radio"
                                id={"toggle33"}
                                value={"1:1"}
                                checked={catImgRatio === "1:1"}
                                onChange={(e) => handleRatioChange(e)}
                              />
                              <div className="cat-dimension-layout">
                              <span class="cms-icon-tooltip">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                >
                                  <g
                                    id="Rectangle_18739"
                                    data-name="Rectangle 18739"
                                    fill="#fff"
                                    stroke=""
                                    stroke-width="2"
                                  >
                                    <rect
                                      width="18"
                                      height="18"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="16"
                                      height="16"
                                      fill="none"
                                    />
                                  </g>
                                </svg>
                                <p>1:1</p>
                                <span class="cms-icon-tooltipsubheader">Choose for a square image format, great for symmetrical and uniform visuals.</span>
                              </span>
                              </div>
                            </Label>
                          </FormGroup>

                          <FormGroup>
                            <Label
                              className="select-cat-dimension-ratio"
                              htmlFor={"toggle44"}
                            >
                              <Input
                                type="radio"
                                id={"toggle44"}
                                value={"3:4"}
                                checked={catImgRatio === "3:4"}
                                onChange={(e) => handleRatioChange(e)}
                              />
                              <div className="cat-dimension-layout">
                              <span class="cms-icon-tooltip">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="20"
                                  viewBox="0 0 15 20"
                                >
                                  <g
                                    id="Rectangle_18739"
                                    data-name="Rectangle 18739"
                                    fill="#fff"
                                    stroke=""
                                    stroke-width="2"
                                  >
                                    <rect
                                      width="15"
                                      height="20"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="13"
                                      height="18"
                                      fill="none"
                                    />
                                  </g>
                                </svg>
                                <p>3:4</p>
                                <span class="cms-icon-tooltipsubheader">Pick for a portrait-oriented image format, perfect for capturing vertical scenes and portraits.</span>
                              </span>
                              </div>
                            </Label>
                          </FormGroup>

                          <FormGroup>
                            <Label
                              className="select-cat-dimension-ratio"
                              htmlFor={"toggle55"}
                            >
                              <Input
                                type="radio"
                                id={"toggle55"}
                                value={"9:16"}
                                checked={catImgRatio === "9:16"}
                                onChange={(e) => handleRatioChange(e)}
                              />
                              <div className="cat-dimension-layout">
                              <span class="cms-icon-tooltip">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="13.5"
                                  height="24"
                                  viewBox="0 0 13.5 24"
                                >
                                  <g
                                    id="Rectangle_31434"
                                    data-name="Rectangle 31434"
                                    transform="translate(13.5) rotate(90)"
                                    fill="#fff"
                                    stroke=""
                                    stroke-width="2"
                                  >
                                    <rect
                                      width="24"
                                      height="13.5"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="22"
                                      height="11.5"
                                      fill="none"
                                    />
                                  </g>
                                </svg>
                                <p>9:16</p>
                                <span class="cms-icon-tooltipsubheader">Opt for a vertical image format perfect for capturing tall scenes and portrait-oriented visuals.</span>
                              </span>
                              </div>
                            </Label>
                          </FormGroup>
                        </FormGroup>
                      </div>
                    </div>
                    :
                    <></>
                    }


                   {
                      ( ( catLayout === "layout-3" && catImgOverlayColor ) || showCatImg ) ?
                    <div className="category-image-dimension">
                      <h4>
                      {`${showCatImg?"Image":"Box"} shape`}
                        <h5 className="catsetting-tooltip">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                          >
                            <g
                              id="Group_40296"
                              data-name="Group 40296"
                              transform="translate(-1727 -19.132)"
                            >
                              <g
                                id="Ellipse_7706"
                                data-name="Ellipse 7706"
                                transform="translate(1727 19.132)"
                                fill="#fff"
                                stroke="#bcc1ce"
                                stroke-width="1.2"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="7"
                                  stroke="none"
                                ></circle>
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6.4"
                                  fill="none"
                                ></circle>
                              </g>
                              <path
                                id="Path_98061"
                                data-name="Path 98061"
                                d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                transform="translate(1725.126 17.539)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></path>
                              <line
                                id="Line_343"
                                data-name="Line 343"
                                y2="1.2"
                                transform="translate(1734 29.132)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></line>
                            </g>
                          </svg>
                          <h5 className="catsetting-tooltipsubheader">
                          Select the preferred shape of category images.
                          </h5>
                        </h5>
                      </h4>

                      <div>
                        <FormGroup
                          tag="fieldset"
                          className="select-cat-dimension"
                        >
                          <FormGroup>
                            <Label
                              className="select-cat-dimension-ratio"
                              htmlFor={"toggle77"}
                            >
                              <Input
                                type="radio"
                                id={"toggle77"}
                                value={"sharp_corner"}
                                checked={catImgShape === "sharp_corner"}
                                onChange={(e) => handleShapeChange(e)}
                              />

                              <div className="cat-dimension-layout">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                >
                                  <g
                                    id="Rectangle_18739"
                                    data-name="Rectangle 18739"
                                    fill="#fff"
                                    stroke=""
                                    stroke-width="2"
                                  >
                                    <rect
                                      width="18"
                                      height="18"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="16"
                                      height="16"
                                      fill="none"
                                    />
                                  </g>
                                </svg>
                              </div>
                            </Label>
                          </FormGroup>

                          <FormGroup>
                            <Label
                              className="select-cat-dimension-ratio"
                              htmlFor={"toggle88"}
                            >
                              <Input
                                type="radio"
                                id={"toggle88"}
                                value={"rounded_corner"}
                                checked={catImgShape === "rounded_corner"}
                                onChange={(e) => handleShapeChange(e)}
                              />
                              <div className="cat-dimension-layout">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                >
                                  <g
                                    id="Rectangle_18739"
                                    data-name="Rectangle 18739"
                                    fill="#fff"
                                    stroke=""
                                    stroke-width="2"
                                  >
                                    <rect
                                      width="18"
                                      height="18"
                                      rx="5"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="16"
                                      height="16"
                                      rx="4"
                                      fill="none"
                                    />
                                  </g>
                                </svg>
                              </div>
                            </Label>
                          </FormGroup>


                        {
                          catImgRatio === "1:1" ?
                          <FormGroup>
                            <Label
                              className="select-cat-dimension-ratio"
                              htmlFor={"toggle99"}
                            >
                              <Input
                                type="radio"
                                id={"toggle99"}
                                value={"circle"}
                                checked={catImgShape === "circle"}
                                onChange={(e) => handleShapeChange(e)}
                              />
                              <div className="cat-dimension-layout">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                >
                                  <g
                                    id="Rectangle_29207"
                                    data-name="Rectangle 29207"
                                    fill="#fff"
                                    stroke=""
                                    stroke-width="2"
                                  >
                                    <rect
                                      width="18"
                                      height="18"
                                      rx="9"
                                      stroke="none"
                                    />
                                    <rect
                                      x="1"
                                      y="1"
                                      width="16"
                                      height="16"
                                      rx="8"
                                      fill="none"
                                    />
                                  </g>
                                </svg>
                              </div>
                            </Label>
                          </FormGroup>
                          :
                          <></>
                        }
                        </FormGroup>
                      </div>
                    </div>
                    :
                    <></>
                   }

                {
                  showCatImg ?

                    <div className="category-setting-toggle">
                      <h4>
                      Center crop images
                        <h5 className="catsetting-tooltip">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 14 14"
                          >
                            <g
                              id="Group_40296"
                              data-name="Group 40296"
                              transform="translate(-1727 -19.132)"
                            >
                              <g
                                id="Ellipse_7706"
                                data-name="Ellipse 7706"
                                transform="translate(1727 19.132)"
                                fill="#fff"
                                stroke="#bcc1ce"
                                stroke-width="1.2"
                              >
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="7"
                                  stroke="none"
                                ></circle>
                                <circle
                                  cx="7"
                                  cy="7"
                                  r="6.4"
                                  fill="none"
                                ></circle>
                              </g>
                              <path
                                id="Path_98061"
                                data-name="Path 98061"
                                d="M7,6.621A1.923,1.923,0,1,1,9.636,8.407a1.076,1.076,0,0,0-.713.985v1.074"
                                transform="translate(1725.126 17.539)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></path>
                              <line
                                id="Line_343"
                                data-name="Line 343"
                                y2="1.2"
                                transform="translate(1734 29.132)"
                                fill="none"
                                stroke="#bcc1ce"
                                stroke-miterlimit="10"
                                stroke-width="1.2"
                              ></line>
                            </g>
                          </svg>
                          <h5 className="catsetting-tooltipsubheader">
                          When enabled, your images will be centered within the image frame, for a consistent look. When disabled, images will be displayed to fit the frame while maintaining their original aspect ratios.
                          </h5>
                        </h5>
                      </h4>
                      <label class="switch" for="showCenterCropImages">
                        <input
                          type="checkbox"
                          id="showCenterCropImages"
                          name="showCenterCropImages"
                          checked={showCenterCropImages}
                          onChange={(e) => handleShowCenterCropImages(e)}
                        />
                        <div className="slider round"></div>
                      </label>
                    </div>
                    :
                    <></>
                 } 
                  </div>

                  <div className="categorysetting-right">
                    <CategoryPreview 
                      previewData={
                          {
                            bgColor:bgColor,
                            showSearchInHeader:showSearchInHeader,
                            showBookmarkInHeader:showBookmarkInHeader,
                            catLayout:catLayout,
                            categoryData:categoryData,
                            catImgShape:catImgShape,
                            showCatImg:showCatImg,
                            noCatImg:noCatImg,
                            calculateHeight:calculateHeight,
                            catImgRatio:catImgRatio,
                            textColor:textColor,
                            column:column,
                            catImgOverlayColor:catImgOverlayColor,
                            catTileBgColor:catTileBgColor,
                            showCenterCropImages:showCenterCropImages,
                          }
                        }/>
                  </div>
                </div>
              </div>
              {window.matchMedia("(max-width: 1200px)").matches &&
              <div className="post-list-setting-btn-mobile">
              <button
                    
                        onClick={(e) => handleSubmitCatSettingsData(e)}
                        style={{
                          width:"100%",
                          backgroundColor: localStorage.getItem(
                            "button_background_color"
                          ),
                          border:
                            "1px solid " +
                            localStorage.getItem("button_background_color"),
                          color: localStorage.getItem("button_text_color"),
                          "--custom_color5":
                            localStorage.getItem("agencyid") == 1
                              ? "#134bec"
                              : localStorage.getItem("button_hover_bgcolor"),
                          "--custom_color4":
                            localStorage.getItem("agencyid") == 1
                              ? "white"
                              : localStorage.getItem("button_hover_txtcolor"),
                          "--custom_color6":
                            localStorage.getItem("agencyid") == 1
                              ? "#3064f9"
                              : localStorage.getItem("button_hover_bgcolor"),
                        }}
                      >
                        <div className={settingSaveBtnLoader}>
                        <div className="d-flex justify-content-center align-items-center ">

<div className="new-loader"></div>
</div>
                        </div>
                        <div>{settingSaveBtnVal}</div>
                      </button> 
                      </div>
                      }
              {settingUpdatedAt &&
              <p style={{
                display:window.matchMedia("(max-width: 1200px)").matches?"block":"none",
                textAlign:"center",
                color:"#bcc1ce",
                fontSize:"12px"
              }}>Updated  {moment(settingUpdatedAt).format("D MMMM YYYY, HH:mm")}{" "}</p>
            }
            </div>
          )}
        </div>)}
        </BottomScrollListener>

        <Modal className="cms-category-popup" isOpen={openCatPopUp} centered>
          <ModalBody>
            <div className="crossmodal mt-1">
              <svg
                onClick={(e) => handleOpenCatPopUp(e, "add")}
                xmlns="http://www.w3.org/2000/svg"
                width="10.96"
                height="10.961"
                viewBox="0 0 10.96 10.961"
              >
                <g
                  id="Group_38368"
                  data-name="Group 38368"
                  transform="translate(-1914.917 -169.92)"
                >
                  <path
                    id="Path_82532"
                    data-name="Path 82532"
                    d="M-16354-3830v14"
                    transform="translate(10781.152 14442.694) rotate(45)"
                    fill="none"
                    stroke="#c8cedb"
                    stroke-width="1.5"
                  />
                  <path
                    id="Path_82533"
                    data-name="Path 82533"
                    d="M-16354-3830v14"
                    transform="translate(-12346.896 9036.155) rotate(135)"
                    fill="none"
                    stroke="#c8cedb"
                    stroke-width="1.5"
                  />
                </g>
              </svg>
            </div>

            {spinner2 === true ? (
              <div className="newspinner">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xlink="http://www.w3.org/1999/xlink"
                  style={{
                    margin: "auto",
                    background: "rgb(241, 242, 243,0)",
                    display: "block",
                    shaperendering: "auto",
                    animationplaystate: "running",
                    animationdelay: "0s",
                  }}
                  width="100px"
                  height="100px"
                  viewBox="0 0 100 100"
                  preserveAspectRatio="xMidYMid"
                >
                  <g
                    transform="translate(20 50)"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  >
                    <circle
                      cx="0"
                      cy="0"
                      r="6"
                      fill="#e6261f"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="-0.375s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      ></animateTransform>
                    </circle>
                  </g>
                  <g
                    transform="translate(40 50)"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  >
                    <circle
                      cx="0"
                      cy="0"
                      r="6"
                      fill="#f7d038"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="-0.25s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      ></animateTransform>
                    </circle>
                  </g>
                  <g
                    transform="translate(60 50)"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  >
                    <circle
                      cx="0"
                      cy="0"
                      r="6"
                      fill="#49da9a"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="-0.125s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      ></animateTransform>
                    </circle>
                  </g>
                  <g
                    transform="translate(80 50)"
                    style={{
                      animationplaystate: "running",
                      animationdelay: "0s",
                    }}
                  >
                    <circle
                      cx="0"
                      cy="0"
                      r="6"
                      fill="#4355db"
                      style={{
                        animationplaystate: "running",
                        animationdelay: "0s",
                      }}
                    >
                      <animateTransform
                        attributeName="transform"
                        type="scale"
                        begin="0s"
                        calcMode="spline"
                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                        values="0;1;0"
                        keyTimes="0;0.5;1"
                        dur="1s"
                        repeatCount="indefinite"
                        style={{
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                      ></animateTransform>
                    </circle>
                  </g>
                </svg>
              </div>
            ) : (
              <div className="category-tags-editor">
                <h4>{categoryID ? "Edit category" : "New category"}</h4>
                <h5>Categories help classify posts broadly.</h5>
                <div className="row">
                  <div className="col-md-12">
                    <h6>Name*</h6>
                    <input
                      type="text"
                      value={eachCatData[0].name}
                      onChange={(e) => handleNameChange(e)}
                      placeholder="Enter category name"
                      maxLength={"120"}
                    />
                    <p className="category-character-limit">{120-eachCatData[0].name.length} characters</p>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-20">
                    <div
                      className="category-parent"
                      style={{ cursor: "Pointer" }}
                    >
                      <h6>Parent category</h6>

                      <div
                        className="category-parent-dropdown cat-parent-input-popup"
                        // style={{
                        //   border:"1px solid black",
                        //   height:"50px",
                        //   width:"100%",
                        // }}
                        onClick={(e) => handleOpenSelectionPopUp(e, "open")}
                      >
                        <p style={{color: parentName ? "" : "#BCC1CE"}}>{parentName ? parentName : "Select"}</p>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="19"
                          viewBox="0 0 20 19"
                        >
                          <g
                            id="Group_42666"
                            data-name="Group 42666"
                            transform="translate(-1005 -1029)"
                          >
                            <g
                              id="Rectangle_31915"
                              data-name="Rectangle 31915"
                              transform="translate(1005 1029)"
                              fill="#fff"
                              stroke="#bcc1ce"
                              stroke-width="1"
                            >
                              <rect
                                width="20"
                                height="19"
                                rx="2"
                                stroke="none"
                              />
                              <rect
                                x="0.5"
                                y="0.5"
                                width="19"
                                height="18"
                                rx="1.5"
                                fill="none"
                              />
                            </g>
                            <path
                              id="Path_119031"
                              data-name="Path 119031"
                              d="M15578.713-3593.554h2"
                              transform="translate(-14569.713 4628.054)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-width="1"
                            />
                            <path
                              id="Path_119032"
                              data-name="Path 119032"
                              d="M15578.713-3593.554h2"
                              transform="translate(-14569.713 4632.054)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-width="1"
                            />
                            <path
                              id="Path_119033"
                              data-name="Path 119033"
                              d="M15578.713-3593.554h2"
                              transform="translate(-14569.713 4636.054)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-width="1"
                            />
                            <path
                              id="Path_119034"
                              data-name="Path 119034"
                              d="M15578.711-3593.554h8"
                              transform="translate(-14565.713 4628.054)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-width="1"
                            />
                            <path
                              id="Path_119035"
                              data-name="Path 119035"
                              d="M15578.711-3593.554h8"
                              transform="translate(-14565.713 4632.054)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-width="1"
                            />
                            <path
                              id="Path_119036"
                              data-name="Path 119036"
                              d="M15578.711-3593.554h8"
                              transform="translate(-14565.713 4636.054)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-width="1"
                            />
                          </g>
                        </svg>

                        {/* <Dropdown isOpen={window.matchMedia("(max-width: 1200px)").matches?false :isParentDropdown}
                                  onClick={window.matchMedia("(max-width: 1200px)").matches?handleMobileCatDropdown:null}
                                   toggle={handleParentDropdown}
                                  >
                                    <DropdownToggle caret><span className="search-dropdown-before">{parentName?parentName:"Select"}</span></DropdownToggle>
                                    <DropdownMenu >
                                   {
                                    categoryData.length===0 &&
                                    <div className="no-app-dropdown">
                                      <div>
                                        <img className="img-fluid" src={require("../../assets/images/dashboard/nothing-here.png").default} alt="No App Found" />
                                        <p>Nothing here at the moment. Come back later?</p>
                                    </div>
                                    </div>
                                   }
                                   {
                                    categoryData.length>0 &&
                                    <FormGroup>
                                    <div className="dropdown-search">
                                      <input placeholder="Search..." type="text" value={searchVal}
                                      onChange={(e)=>{
                                        setSearchVal(e.target.value)
                                        
                                      }}
                                      ></input>
                                      {searchVal.trim() && <p onClick={(e)=>{
                                      setSearchVal("")
                                    }}>Clear</p>}
                                    </div>
                                  </FormGroup>
                                 }
                                   {categoryData.length>0 && !searchVal &&<DropdownItem  
                                   onClick={(e)=>handleParentChange(e,0,"")}
                                  >Select</DropdownItem>
                                   }
                                 {categoryData.length>0 && <RecursiveLoop searchVal={searchVal} 
                                 categoryData={categoryData} categoryId={categoryID}
                                  handleParentChange={handleParentChange}/>}
                                     
                                     
                                      
                                    </DropdownMenu>
                          </Dropdown> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mt-20">
                    <h6>Image</h6>
                    <label className="set-full-img">
                      <div className="image-uploader">
                        <div
                          className="d-flex align-items-center"
                          onClick={(e) => imageGalleryPopup(e)}
                        >
                          {eachCatData[0].portal_value.source_file ===
                            undefined ||
                          eachCatData[0].portal_value.source_file === "" ||
                          eachCatData[0].portal_value.source_file === null ? (
                            <div
                              className="uploadpixabayimage"
                              style={{
                                backgroundImage: "url(" + imagebackground + ")",
                              }}
                            ></div>
                          ) : (
                            <div
                              className="uploadpixabayimage"
                              style={{
                                backgroundImage:
                                  "url(" +
                                  (eachCatData[0].portal_value.source_file.indexOf(
                                    "https"
                                  ) > -1
                                    ? eachCatData[0].portal_value.source_file
                                    : process.env.REACT_APP_Image_Path +
                                      localStorage.getItem("user_id") +
                                      "/" +
                                      localStorage.getItem("Appid") +
                                      process.env.REACT_APP_CMS_Media +
                                      eachCatData[0].portal_value.source_file) +
                                  ")",
                              }}
                            ></div>
                          )}
                          <p style={{color : eachCatData[0].portal_value.source_file !== "" &&
                            eachCatData[0].portal_value.source_file !==
                              undefined &&
                            eachCatData[0].portal_value.source_file !== null?"": "#BCC1CE"}}>
                            {eachCatData[0].portal_value.source_file !== "" &&
                            eachCatData[0].portal_value.source_file !==
                              undefined &&
                            eachCatData[0].portal_value.source_file !== null
                              ? eachCatData[0].portal_value.source_file.replace(
                                  "https://create.vista.com/downloads/?filePath=%2Fdownloads%2F",
                                  ""
                                )
                              : "Upload your image here"}
                          </p>
                        </div>
                        {eachCatData[0].portal_value.source_file !== "" &&
                          eachCatData[0].portal_value.source_file !==
                            undefined &&
                          eachCatData[0].portal_value.source_file !== null && (
                            <svg
                              onClick={(e) => handleCancelImage(e)}
                              width="11.06"
                              height="11.06"
                              viewBox="0 0 11.06 11.06"
                            >
                              <path
                                id="Union_1696"
                                data-name="Union 1696"
                                d="M5,5,0,10,5,5,0,0,5,5l5-5L5,5l5,5Z"
                                transform="translate(0.53 0.53)"
                                fill="none"
                                stroke="#7782a1"
                                stroke-width="1.5"
                              />
                            </svg>
                          )}
                      </div>
                    </label>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="category-footer">
                      <button
                        className="add-cat-tag-btn1"
                        onClick={(e) => handleOpenCatPopUp(e, "add")}
                      >
                        Back
                      </button>

                      <button
                        className="add-cat-tag-btn2"
                        onClick={(e) => handleAddCategory(e)}
                        style={{
                          backgroundColor: localStorage.getItem(
                            "button_background_color"
                          ),
                          border:
                            "1px solid " +
                            localStorage.getItem("button_background_color"),
                          color: localStorage.getItem("button_text_color"),
                          "--custom_color5":
                            localStorage.getItem("agencyid") == 1
                              ? "#134bec"
                              : localStorage.getItem("button_hover_bgcolor"),
                          "--custom_color4":
                            localStorage.getItem("agencyid") == 1
                              ? "white"
                              : localStorage.getItem("button_hover_txtcolor"),
                          "--custom_color6":
                            localStorage.getItem("agencyid") == 1
                              ? "#3064f9"
                              : localStorage.getItem("button_hover_bgcolor"),
                        }}
                      >
                        <div className={isLoader}>
                          <div className="new-loader"></div>
                        </div>
                        {categoryID
                          ? isLoader === "d-none"
                            ? "Save"
                            : ""
                          : submitval}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </ModalBody>
        </Modal>

        {/* <Modal
          className="protip-popup-message"
          isOpen={
            window.matchMedia("(max-width: 1200px)").matches
              ? isParentMobileDropdown
              : false
          }
          centered
        >
          <ModalBody>
            <div className="mobileglobalsearch">
              <button onClick={handleMobileCatDropdown}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20.759"
                  height="15.408"
                  viewBox="0 0 20.759 15.408"
                >
                  <g
                    id="Group_27465"
                    data-name="Group 27465"
                    transform="translate(-19.742 -22.295)"
                  >
                    <path
                      id="Path_81245"
                      data-name="Path 81245"
                      d="M-13783.922-19217h19.346"
                      transform="translate(13805.077 19247)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                    <path
                      id="Path_82232"
                      data-name="Path 82232"
                      d="M14053.656,19255.426l-7,7,7,7"
                      transform="translate(-14025.504 -19232.424)"
                      fill="none"
                      stroke="#7782a1"
                      stroke-width="2"
                    />
                  </g>
                </svg>
              </button>
              {categoryData.length > 0 && (
                <input
                  placeholder="Search..."
                  type="text"
                  value={searchVal}
                  onChange={(e) => {
                    setSearchVal(e.target.value);
                  }}
                ></input>
              )}
              {searchVal.trim() && (
                <p
                  onClick={(e) => {
                    setSearchVal("");
                  }}
                >
                  Clear
                </p>
              )}
            </div>

            <div className="mobilesearchsuggestion">
              {categoryData.length > 0 && !searchVal && (
                <p
                  style={{
                    fontSize: "16px",
                    color: "#1B2952",
                    fontFamily: "Axiforma-Regular",
                    margin: "0px 20px 40px 20px",
                    display: "block",
                  }}
                  onClick={(e) => handleParentChange(e, 0, "")}
                >
                  Select
                </p>
              )}
              {categoryData.length > 0 ? (
                recursForMobile(categoryData, "parent")
              ) : (
                <div className="no-app-dropdown">
                  <img
                    className="img-fluid"
                    src={
                      require("../../assets/images/dashboard/no-tag-available.png")
                        .default
                    }
                    alt="No App Found"
                  />
                </div>
              )}
            </div>
          </ModalBody>
        </Modal> */}

        {/* {parentPage(
          isParentpopup,
          handleParentPop,
          categoryData,
          handleParentChange,
          tempParentID,
          categoryID,
          "Category"
        )} */}
        <NewImageGalleryPopup
          isGalleryPopup={isGalleryPopup}
          setIsGalleryPopup={setIsGalleryPopup}
          settingYourdata={settingYourdata}
          yourErrFun={yourErrFun}
          uploadPath={
            localStorage.getItem("user_id") +
            "/" +
            localStorage.getItem("Appid") +
            process.env.REACT_APP_CMS_Media
          }
          settingOrgImgFrom3rdParty={false}
          hideImageGallery={false}
          widthForRestriction={1125}
          heightForRestriction={480}
          aspectRatio={"4:3"}
          isNotCustomUploadOrgImg={true}
          isCms={true}
          designid={
            eachCatData[0].portal_value.design_id
              ? eachCatData[0].portal_value.design_id
              : ""
          }
          loadpicVistaCreatePopUp={loadpicVistaCreatePopUp}
          closeVistaCreatePopUp={closeVistaCreatePopUp}
        />

        <Modal
          isOpen={isTrashed}
          centered
          className="delete-popup"
          toggle={(e) => handleTrashedPopUp(e, "cancel")}
          // className={this.props.className}
          dataclassName="addonpopup"
        >
          <ModalBody className="">
          
          <div className="crossmodal mt-1">
              <svg
                onClick={(e) => handleTrashedPopUp(e, "cancel")}
                xmlns="http://www.w3.org/2000/svg"
                width="10.96"
                height="10.961"
                viewBox="0 0 10.96 10.961"
              >
                <g
                  id="Group_38368"
                  data-name="Group 38368"
                  transform="translate(-1914.917 -169.92)"
                >
                  <path
                    id="Path_82532"
                    data-name="Path 82532"
                    d="M-16354-3830v14"
                    transform="translate(10781.152 14442.694) rotate(45)"
                    fill="none"
                    stroke="#c8cedb"
                    stroke-width="1.5"
                  />
                  <path
                    id="Path_82533"
                    data-name="Path 82533"
                    d="M-16354-3830v14"
                    transform="translate(-12346.896 9036.155) rotate(135)"
                    fill="none"
                    stroke="#c8cedb"
                    stroke-width="1.5"
                  />
                </g>
              </svg>
            </div>

            <div>
              <h5>Delete this category?</h5>
              <h6>This action will permanently delete your category.</h6>

              <div className="d-flex align-items-center justify-content-between cms-delete-popup-footer">
                <div
                  className="d-none d-xl-block grey-btn"
                  onClick={(e) => handleTrashedPopUp(e, "cancel")}
                >
                  <p>Cancel</p>
                </div>

                <div onClick={(e) => handleTrashedCategory(e)}>
                  <div className="d-flex align-items-center justify-content-center h-100 w-100 red-btn">
                    <p>
                      <div className={isLoader}>
                        <img src={loaderimage} alt="loader" />
                      </div>
                      <div>Delete</div>
                    </p>
                  </div>
                </div>

                <div
                  className="d-block d-xl-none grey-btn"
                  onClick={(e) => handleTrashedPopUp(e, "cancel")}
                >
                  <p>Back</p>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/* {
        openSingleSelectionPopUp &&  */}

        <SingleSelectionPopUpForCustomApp
          openPopUp={openSingleSelectionPopUp}
          handlePopUp={handleOpenSelectionPopUp}
          data={categoryData}
          handleInputClick={handleParentChange}
          selectedId={tempParentID}
          subData={subData}
          setSubData={setSubData}
          searchVal={searchValForPopUp}
          setSearchVal={setSearchValForPopUp}
          breadcrumbArr={breadcrumbArr}
          setBreadcrumbArr={setBreadcrumbArr}
          from={"category"}
          oldBreadcrumbArr={oldBreadcrumbArr}
          oldSubData={oldSubData}
          handleBottomScrollListner={handleBottomScrollListner}
          paginationSpinner={paginationSpinner}
        />
        {/* } */}
      </section>
    </>
  );
};
export default Categorypage;
