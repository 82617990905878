import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

const OptionsSelectionModal = ({
  isOpen,
  options,
  onClose,
  onOptionSelect,
  enableSearch,
  value,
  onChange,
  profileData,
  setProfileData
}) => {
  return (
    <Modal
      isOpen={isOpen}
      style={{ maxWidth: "700px", maxHeight: "350px" }}
      className="profile-code-modal"
      centered
      dataclass="countrycode"
    >
      <ModalHeader>
        <div className="d-flex align-items-center search-box justify-content-center profile-country-search">
          <div className="d-flex align-items-center w-100">
            <div className="backaero-svgimg" onClick={onClose}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20.759"
                height="15.408"
                viewBox="0 0 20.759 15.408"
              >
                <g
                  id="Group_27465"
                  data-name="Group 27465"
                  transform="translate(-19.742 -22.295)"
                >
                  <path
                    id="Path_81245"
                    data-name="Path 81245"
                    d="M-13783.922-19217h19.346"
                    transform="translate(13805.077 19247)"
                    fill="none"
                    stroke="#7782a1"
                    strokeWidth="2"
                  />
                  <path
                    id="Path_82232"
                    data-name="Path 82232"
                    d="M14053.656,19255.426l-7,7,7,7"
                    transform="translate(-14025.504 -19232.424)"
                    fill="none"
                    stroke="#7782a1"
                    strokeWidth="2"
                  />
                </g>
              </svg>
            </div>
            {enableSearch && (
              <>
                <input
                  type="text"
                  className="form-control flagsearch-input"
                  placeholder="Search..."
                  value={value}
                  onChange={onChange}
                />
                {profileData?.dropdownSearchVal.trim() && (
                  <p
                    onClick={(e) => {
                      setProfileData({
                        dropdownSearchVal: ""
                      });
                    }}
                    className="cleartext"
                  >
                    Clear
                  </p>
                )}
              </>
            )}
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="code-selector p-0">
        <div className={enableSearch ? 'profile-countrycode-flag'  :"mob-popup-company-size "}>
          {enableSearch
            ? options &&
              options.length > 0 &&
              options
                .filter((name) =>
                  name.country_name
                    .toLowerCase()
                    .includes(profileData.dropdownSearchVal.toLowerCase())
                )
                .map((res, i) => (
                  <div
                    className="d-flex align-items-center justify-content-between country-line-flag"
                    key={i}
                  >
                    <div
                      className="d-flex align-items-center justify-content-start"
                      onClick={(e) => onOptionSelect(e, res)}
                    >
                      <img
                        src={
                          process.env.REACT_APP_Image_Path +
                          process.env.REACT_APP_SMALL_FLAG +
                          res.country_code.toLowerCase() +
                          ".png"
                        }
                        alt={i}
                      />
                      <p className="country-name">
                        {res.country_name} ({"+" + res.phone_code})
                      </p>
                    </div>
                  </div>
                ))
            : options?.map((res, i) => (
                <div
                  className="d-flex align-items-center justify-content-between"
                  key={i}
                >
                  <div
                    className="d-flex align-items-center justify-content-start"
                    onClick={() => onOptionSelect(res)}
                  >
                    <p className="mob-company-size">{res.value}</p>
                  </div>
                </div>
              ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default OptionsSelectionModal;
