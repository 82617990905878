import React, { useMemo, useReducer } from "react";
import "./../../assets/css/Dashboard.css";
import Header from "./../Header";
import Leftpanel from "./../Leftpanel";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import moment from "moment";
import { InnerFoot } from "./../Foot";
import styles from "./cmsBuilderstyle.css";
import "./bulkUpdate.css";
import "./pages.css";
import { useState, useEffect } from "react";
import Select from "react-select";
import {
  bulkPageUpdate,
  cmsDeletePageApi,
  cmsGetPagesApi,
  cmsSavePageApiForListing,
  hideUnhidePageApi,
  teamApiForCMS,
} from "../../Api/CmsApi/Cmsapi";
import { parentPage } from "./CommonPopUp";
import userdefaultImgOnError from "../../assets/images/dashboard/accountprofile.png";
import wpdefaultImgOnError from "../../assets/images/dashboard/workspace/workspace-default-img.png";
import {
  Modal,
  ModalBody,
  Media,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  ModalHeader,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { errortoast, sucesstoast } from "../Toaster";
import BottomScrollListener from "react-bottom-scroll-listener";
import OutsideClickHandler from "react-outside-click-handler";
import SortIcon from "./SortIcon";
import { SingleSelectionPopUpForCustomApp } from "../Reusable/SingleSelectionPopUp";
import DeleteIcon from "../../Components/commonIcons/DeleteIcon";
import Tooltip from "../../Components/commonIcons/Tooltip";
import imagebackground from "./../../assets/images/dashboard/my-account.png";

import { sl } from "date-fns/locale";
import { set } from "date-fns";
import PageOptions from "./PageOptions";
import MenuSortingList from "../../Components/commonComponents/MenuBuilder/MenuSortingList";
import SortingIcon from "../../Components/commonIcons/SortingIcon";
import HomeIcon from "../../Components/commonIcons/HomeIcon";
import { GetPagesapi, Pagesapi } from "../../Api/Dashboard/DashboardApi";
import SettingIcon from "../../Components/commonIcons/SettingIcon";

export const pageOrderingData = [
  {
    children: [],
    id: 1615,
    individual_selected_item_id: "6717481e50b150af110eef9b",
    is_default: 1,
    is_tree: 5,
    item_icon: "BlogMenu.png",
    item_icon_flaticon_color: "black",
    item_icon_newtype: "",
    item_icon_type: "bold",
    item_name: "Home",
    item_position: 0,
    item_title: "Posts",
    item_type: "cms_post",
    last_item: "Posts",
    post_type: "",
    rest_base: "",
    selected_ids: ["6717481e50b150af110eef9b"],
    selected_item_name: "Posts",
    slug: "",
    status: 1,
    text: "Home",
    uniqueId: "cms_post_134",
    item_is_default: 1,
    open_item: false,
    breadcrumbArr: [],
    module_label: "",
    showL1Options: true,
    subData: [],
    subdropdownData: [],
    subdropdownSpinner: false,
    temp_breadcrumbArr: [],
    temp_individual_selected_item_id: "6717481e50b150af110eef9b",
    temp_item_type: "cms_post",
    temp_rest_base: "",
    temp_slug: "",
    temp_post_type: "",
    temp_module_label: "",
    temp_showL1Options: true,
    temp_subData: [],
  },
  {
    id: 1098,
    individual_selected_item_id: "",
    is_default: 1,
    is_tree: 5,
    item_icon: "BlogMenu.png",
    item_icon_flaticon_color: "black",
    item_icon_newtype: "",
    item_icon_type: "bold",
    item_name: "Destinations",
    item_position: 1,
    item_title: "Posts",
    item_type: "cms_post",
    last_item: "Posts",
    post_type: "",
    rest_base: "",
    selected_ids: [],
    selected_item_name: "Posts",
    slug: "",
    status: 1,
    text: "Destinations",
    uniqueId: "cms_post_445",
    children: [],
    item_is_default: 0,
    open_item: false,
    breadcrumbArr: [],
    module_label: "",
    showL1Options: true,
    subData: [],
    subdropdownData: [],
    subdropdownSpinner: false,
    temp_breadcrumbArr: [],
    temp_individual_selected_item_id: "",
    temp_item_type: "cms_post",
    temp_rest_base: "",
    temp_slug: "",
    temp_post_type: "",
    temp_module_label: "",
    temp_showL1Options: true,
    temp_subData: [],
  },
  {
    children: [],
    id: 1839,
    individual_selected_item_id: "",
    is_default: 1,
    is_tree: 4,
    item_icon: "AboutMenu.png",
    item_icon_flaticon_color: "black",
    item_icon_newtype: "",
    item_icon_type: "bold",
    item_name: "About us",
    item_position: 2,
    item_title: "Pages",
    item_type: "cms_page",
    last_item: "Pages",
    post_type: "",
    rest_base: "",
    selected_ids: [],
    selected_item_name: "Pages",
    slug: "",
    status: 1,
    text: "About us",
    item_is_default: 0,
    open_item: false,
    breadcrumbArr: [],
    module_label: "",
    showL1Options: true,
    subData: [],
    subdropdownData: [],
    subdropdownSpinner: false,
    temp_breadcrumbArr: [],
    temp_individual_selected_item_id: "",
    temp_item_type: "cms_page",
    temp_rest_base: "",
    temp_slug: "",
    temp_post_type: "",
    temp_module_label: "",
    temp_showL1Options: true,
    temp_subData: [],
  },
  {
    children: [],
    id: 382,
    individual_selected_item_id: "",
    is_default: 1,
    is_tree: 7,
    item_icon: "TagsMenu.png",
    item_icon_flaticon_color: "black",
    item_icon_newtype: "",
    item_icon_type: "bold",
    item_name: "Packages",
    item_position: 3,
    item_title: "Post Tags",
    item_type: "cms_tag",
    last_item: "Post Tags",
    post_type: "",
    rest_base: "",
    selected_ids: [],
    selected_item_name: "Post Tags",
    slug: "",
    status: 1,
    text: "Packages",
    uniqueId: "cms_tag_844",
    item_is_default: 0,
    open_item: false,
    breadcrumbArr: [],
    module_label: "",
    showL1Options: true,
    subData: [],
    subdropdownData: [],
    subdropdownSpinner: false,
    temp_breadcrumbArr: [],
    temp_individual_selected_item_id: "",
    temp_item_type: "cms_tag",
    temp_rest_base: "",
    temp_slug: "",
    temp_post_type: "",
    temp_module_label: "",
    temp_showL1Options: true,
    temp_subData: [],
  },
  {
    children: [],
    id: 1729,
    individual_selected_item_id: "",
    is_default: 1,
    is_tree: 0,
    item_icon: "PlayMenu.png",
    item_icon_flaticon_color: "black",
    item_icon_newtype: "",
    item_icon_type: "bold",
    item_name: "Contact us",
    item_position: 4,
    item_title: "Videos",
    item_type: "web_view",
    last_item: "Web View",
    post_type: "",
    rest_base: "",
    selected_ids: [],
    selected_item_name: "Web View",
    slug: "",
    status: 1,
    text: "Contact us",
    web_view_settings: {
      hide_html_element_by_class: "",
      hide_html_element_by_id: "",
      show_app_header: 1,
      show_web_view_footer: 1,
      show_web_view_header: 1,
    },
    web_view_url: "youtube.com",
    uniqueId: "web_view_450",
    item_is_default: 0,
    open_item: false,
    breadcrumbArr: [],
    module_label: "",
    showL1Options: true,
    subData: [],
    subdropdownData: [],
    subdropdownSpinner: false,
    temp_breadcrumbArr: [],
    temp_individual_selected_item_id: "",
    temp_item_type: "web_view",
    temp_rest_base: "",
    temp_slug: "",
    temp_post_type: "",
    temp_module_label: "",
    temp_showL1Options: true,
    temp_subData: [],
  },
];

const Cmspages = () => {
  const [spinner, setSpinner] = useState(true);
  const [pagesData, setPagesData] = useState([]);
  const [pagesDataCopy, setPagesDataCopy] = useState([]);
  const [searchVal, setSearch] = useState("");
  const [isDeleted, setisDeleted] = useState(false);
  const [isTrashed, setIsTrashed] = useState(false);
  const [pageObjForDelete, setPageObjForDelete] = useState("");
  const [isLoader, setIsLoader] = useState("d-none");
  const [bottomSpinner, setBottomSpinner] = useState(false);
  const [page, setPage] = useState(1);
  const [paginationSpinner, setPaginationSpinner] = useState(false);
  const [hitNextPagination, setHitNextPagination] = useState(false);
  const [loaderimage, setLoaderimage] = useState(
    require("../../assets/images/signup/Loader_GIF.gif").default
  );
  const [tableView, setTableView] = useState(() => {
    if (localStorage.getItem("pagesTableView")) {
      return localStorage.getItem("pagesTableView") === "true";
    }
    return false;
  });
  const [openDrawer, setOpenDrawer] = useState(false);
  const [bulkDeletePopup, setBulkDeletePopup] = useState(false);
  const [selectedArr, setSelectedArr] = useState([]);
  const [profilepath, setProfilepath] = useState(
    localStorage.getItem("workspaceId") !== "" &&
      localStorage.getItem("workspaceId") !== "undefined" &&
      localStorage.getItem("workspaceId") !== undefined &&
      localStorage.getItem("workspaceId") !== null
      ? process.env.REACT_APP_Image_Path +
          (parseInt(localStorage.getItem("logged_in_user_role_id")) === 1
            ? localStorage.getItem("logged_in_user_id")
            : localStorage.getItem("invited_by_user_id")) +
          process.env.REACT_APP_Workspace_Profile +
          localStorage.getItem("workspaceId") +
          "/"
      : process.env.REACT_APP_Image_Path +
          // (localStorage.getItem("roleid") === "1"
          //   ? localStorage.getItem("originalid")
          //   : localStorage.getItem("user_id")) +
          localStorage.getItem("logged_in_user_id") +
          process.env.REACT_APP_Profile
  );

  const [profileimage, setProfileimgage] = useState(
    localStorage.getItem("workspaceId") !== "" &&
      localStorage.getItem("workspaceId") !== "undefined" &&
      localStorage.getItem("workspaceId") !== undefined &&
      localStorage.getItem("workspaceId") !== null
      ? localStorage.getItem("workspaceImage")
      : localStorage.getItem("user_profile")
  );
  const [appRightPanelExpand, setAppRightPanelExpand] = useState(
    localStorage.getItem("appSideBarCollapse") === true ||
      localStorage.getItem("appSideBarCollapse") === "true"
      ? true
      : false
  );

  const [sortFields, setSortFields] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      title: 0,
      author_info: 0,
      created_at: 0,
      updated_at: 0,
      status: 0,
    }
  );
  const [openSingleSelectionPopUp, setOpenSingleSelectionPopUp] =
    useState(false);
  const [subData, setSubData] = useState([]);
  const [breadcrumbArr, setBreadcrumbArr] = useState([]);
  const [oldSubData, setOldSubData] = useState([]);
  const [oldBreadcrumbArr, setOldBreadcrumbArr] = useState([]);
  const [parentId, setparentId] = useState(null);
  const [parentName, setparentName] = useState("");
  const [parentTempName, setParentTempName] = useState("");
  const [tempParentId, setTempParentId] = useState(null);
  const [searchValForPopUp, setSearchValForPopUp] = useState("");
  const [openMobileAppDropdown, setOpenMobileAppDropdown] = useState(false);
  const [mergeUserOwnerData, setMergeUserOwnerData] = useState([]);
  const [bulkUpdateData, setBulkUpdateData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      author_user_id: null,
      status: null,
    }
  );
  const [bulkUpdateLoader, setBulkUpdateLoader] = useState(false);
  const [bulkDeleteLoader, setBulkDeleteLoader] = useState(false);
  const [enableGeneralSettings, setEnableGeneralSettings] = useState(false);
  const [enablePageLayout, setEnablePageLayout] = useState(false);
  const [enablePageOrdering, setEnablePageOrdering] = useState(false);
  const [pageLayout, setPageLayout] = useState("layout-0");
  const [pageState, setPageState] = useState(pageOrderingData);

  const [state, dispatch] = useReducer(reducer, {
    pageOptions: {
      background_color_on_pages_listing_page: "#f6f6f6",
      enable_share_feature_on_pages_detail_page: 0,
      image_ratio_on_pages_detail_page: "4:3",
      image_shape_on_pages_detail_page: "sharp_corner",
      section_margin_bottom_on_pages_detail_page: "7.5",
      section_margin_left_on_pages_detail_page: "15",
      section_margin_right_on_pages_detail_page: "15",
      section_margin_top_on_pages_detail_page: "7.5",
      show_center_crop_images_on_pages_detail_page: 0,
      show_media_as_thumbnail_on_pages_detail_page: 1,
      show_media_in_full_screen_on_pages_detail_page: 1,
      show_search_icon_on_pages_listing_page: 0,
      text_color_on_pages_listing_page: "#1a1a1a",
      tile_background_color_on_pages_listing_page: "#ffffff",
    },
  });

  const [savePageSettingLoading, setSavePageSettingLoading] = useState(false);
  const [updatedAt, setUpdatedAt] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);

    if (
      parseInt(localStorage.getItem("website_technology")) !== 3 &&
      window.location.pathname.includes("custom")
    ) {
      window.location.href = "/app/dashboard";
    }
    if (
      localStorage.getItem("agencyid") &&
      localStorage.getItem("agencyid") == 1
    ) {
      document.title = "Pages | AppMySite";
    } else {
      document.title = "Pages |" + " " + localStorage.getItem("agencyname");
    }

    let limit = 20;
    let pageNo = 1;
    setPage(pageNo);

    cmsGetPagesApi(
      setPagesData,
      btoa(localStorage.getItem("Appid")),
      setSpinner,
      "",
      limit,
      pageNo,
      pagesData,
      setHitNextPagination,
      setPaginationSpinner,
      setPagesDataCopy
    );
    const params = {
      app_id: btoa(localStorage.getItem("Appid")),
    };

    teamApiForCMS(params, setMergeUserOwnerData);
  }, [isDeleted]);

  useEffect(() => {
    if (!pagesData.length) {
      setTableView(false);
    } else {
      setTableView(localStorage.getItem("pagesTableView") === "true");
    }
  }, [pagesData]);

  const handleContainerOnBottom = () => {
    if (paginationSpinner === false && bottomSpinner === false) {
      if (hitNextPagination) {
        let limit = 20;
        let pageNo = page + 1;
        setPage(pageNo);
        setPaginationSpinner(true);
        cmsGetPagesApi(
          setPagesData,
          btoa(localStorage.getItem("Appid")),
          setSpinner,
          "",
          limit,
          pageNo,
          pagesData,
          setHitNextPagination,
          setPaginationSpinner,
          setPagesDataCopy
        );
      }
    }
  };

  const handleNotHitOnBottom = () => {};

  const handleDeletePage = (e, eachPage) => {
    if (
      eachPage.child === undefined ||
      eachPage.child === null ||
      eachPage.child.length === 0
    ) {
      const params = {
        page_id: eachPage._id,
        app_id: parseInt(localStorage.getItem("Appid")),
      };
      setSpinner(true);
      cmsDeletePageApi(params, setisDeleted, isDeleted);
    } else {
      errortoast("Please delete it's child first");
    }

    // setisDeleted(!isDeleted);
  };

  const handleTrashedPopUp = (e, from, eachPage) => {
    setIsTrashed(!isTrashed);
    if (from === "add") {
      setPageObjForDelete(eachPage);
    }
  };

  const handleTrashedPage = (e) => {
    setIsTrashed(!isTrashed);
    if (
      pageObjForDelete.child === undefined ||
      pageObjForDelete.child === null ||
      pageObjForDelete.child.length === 0
    ) {
      const params = {
        page_id: pageObjForDelete._id,
        app_id: parseInt(localStorage.getItem("Appid")),
      };
      setSpinner(true);
      cmsDeletePageApi(params, setisDeleted, isDeleted);
    } else {
      errortoast("Please delete it's child first");
    }
  };

  const handleDash = (level) => {
    switch (level) {
      case 0:
        return <span></span>;
      case 1:
        return <span> - </span>;
      case 2:
        return <span> - - </span>;
      case 3:
        return <span> - - - </span>;
      case 4:
        return <span> - - - - </span>;
      case 5:
        return <span> - - - - - </span>;
      case 6:
        return <span> - - - - - - </span>;
      case 1:
        return <span> - - - - - - - </span>;
      case 7:
        return <span> - - - - - - - - </span>;
      case 8:
        return <span> - - - - - - - - - </span>;
      case 9:
        return <span> - - - - - - - - - - </span>;
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const handleBulkDeletePopup = (from) => {
    if (from === "delete") {
      handleBulkUpdateSubmit(true);
      return;
    }
    setBulkDeletePopup(!bulkDeletePopup);
  };

  const childParentList = (newArr) => {
    return newArr
      .filter((row, ind) =>
        row.title.toLowerCase().includes(searchVal.toLowerCase())
      )
      .map((eachPage, indx) => {
        return (
          eachPage.deleted_at === null && (
            <>
              <div
                key={`page_${indx}`}
                className={`pagedetail  ${
                  eachPage.show_in_results === 1 ||
                  eachPage.show_in_results === undefined
                    ? ""
                    : "cms-entry-hidden"
                }`}
              >
                <Link
                  className="page-list-link"
                  to={{ pathname: `/app/page/${eachPage._id}` }}
                ></Link>

                <div>
                  <div className="pagedetail-name">
                    {/* {eachPage.level > 0 ? (
                  <img
                    style={{
                      marginLeft: window.matchMedia("(max-width: 1200px)")
                        .matches
                        ? "0px"
                        : eachPage.level * 30 + "px",
                    }}
                    className="img-fluid "
                    src={
                      require("../../assets/images/dashboard/cms/child-page.png")
                        .default
                    }
                  />
                  // handleDash(eachPage.level)
                ) : (
                  <img
                    className="img-fluid"
                    src={
                      require("../../assets/images/dashboard/cms/page-icon.png")
                        .default
                    }
                  />
                )} */}

                    <h2>
                      {eachPage.level > 0 ? handleDash(eachPage.level) : ""}
                      {eachPage.title}
                    </h2>
                  </div>

                  <div className="pagecreator-detail">
                    {eachPage.author_info !== undefined &&
                    eachPage.author_info !== null ? (
                      <div>
                        {eachPage.author_info?.profile_image === "" ||
                        eachPage.author_info?.profile_image === null ||
                        eachPage.author_info?.profile_image === "null" ||
                        eachPage.author_info?.profile_image === undefined ||
                        eachPage.author_info?.profile_image === "undefined" ||
                        !eachPage.author_info?.profile_image ? (
                          <img
                            style={{
                              width: "24px",
                              height: "24px",
                            }}
                            src={userdefaultImgOnError}
                            className="img-fluid"
                            alt="profile logo"
                          />
                        ) : (
                          <>
                            {eachPage.author_info?.profile_image?.indexOf(
                              "https"
                            ) === -1 ? (
                              <img
                                className="header-profil-img"
                                src={
                                  process.env.REACT_APP_Image_Path +
                                  eachPage.author_info.id +
                                  "/" +
                                  "profile" +
                                  "/" +
                                  eachPage.author_info?.profile_image
                                }
                                style={{
                                  width: "24px",
                                  borderRadius: "50%",
                                  height: "24px",
                                  backgroundSize: "cover",
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = userdefaultImgOnError;
                                }}
                              />
                            ) : (
                              <img
                                className="header-profil-img"
                                src={eachPage.author_info?.profile_image}
                                style={{
                                  width: "24px",
                                  borderRadius: "50%",
                                  height: "24px",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = userdefaultImgOnError;
                                }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    ) : eachPage.owner_user ? (
                      <div>
                        {eachPage.owner_user.profile_image === "" ||
                        eachPage.owner_user.profile_image === null ||
                        eachPage.owner_user.profile_image === "null" ||
                        eachPage.owner_user.profile_image === undefined ||
                        eachPage.owner_user.profile_image === "undefined" ||
                        !eachPage.owner_user.profile_image ? (
                          <img
                            style={{
                              width: "24px",
                              height: "24px",
                            }}
                            src={userdefaultImgOnError}
                            className="img-fluid"
                            alt="profile logo"
                          />
                        ) : (
                          <>
                            {eachPage.owner_user?.profile_image?.indexOf(
                              "https"
                            ) === -1 ? (
                              <img
                                className="header-profil-img"
                                src={
                                  process.env.REACT_APP_Image_Path +
                                  eachPage.owner_user.id +
                                  "/" +
                                  "profile" +
                                  "/" +
                                  eachPage.owner_user?.profile_image
                                }
                                style={{
                                  width: "24px",
                                  borderRadius: "50%",
                                  height: "24px",
                                  backgroundSize: "cover",
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = userdefaultImgOnError;
                                }}
                              />
                            ) : (
                              <img
                                className="header-profil-img"
                                src={eachPage.owner_user?.profile_image}
                                style={{
                                  width: "24px",
                                  borderRadius: "50%",
                                  height: "24px",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = userdefaultImgOnError;
                                }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div>
                        {profileimage === "" ||
                        profileimage === null ||
                        profileimage === "null" ||
                        profileimage === undefined ||
                        profileimage === "undefined" ||
                        !profileimage ? (
                          <img
                            style={{
                              width: "24px",
                              //borderRadius: "50%",
                              height: "24px",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }}
                            src={userdefaultImgOnError}
                            className="img-fluid"
                            alt="profile logo"
                          />
                        ) : (
                          <>
                            {profileimage.indexOf("https") === -1 ? (
                              <img
                                className="header-profil-img"
                                style={{
                                  // background:
                                  //   "url(" +
                                  //   profilepath +
                                  //   profileimage +
                                  //   ")",
                                  width: "24px",
                                  borderRadius: "50%",
                                  height: "24px",
                                  backgroundSize: "cover",
                                }}
                                src={profilepath + profileimage}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = userdefaultImgOnError;
                                }}
                              />
                            ) : (
                              <img
                                className="header-profil-img"
                                style={{
                                  // background:
                                  //   "url(" + profileimage + ")",
                                  width: "24px",
                                  borderRadius: "50%",
                                  height: "24px",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                }}
                                src={profileimage}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = userdefaultImgOnError;
                                }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    )}
                    <h2>
                      {eachPage.author_info !== undefined &&
                      eachPage.author_info !== null
                        ? eachPage.author_info.name
                        : eachPage.owner_user
                        ? eachPage.owner_user.name
                        : localStorage.getItem("user_name")}
                    </h2>
                  </div>

                  <div className="pagestatussection">
                    <p
                      className={`pagestatus ${
                        eachPage.status === "publish"
                          ? "statusgreen"
                          : "statusgrey"
                      }`}
                    >
                      {eachPage.status === "publish"
                        ? "published"
                        : eachPage.status}
                    </p>

                    <h4>
                      {moment(eachPage.created_at).format("D MMMM YYYY, HH:mm")}
                    </h4>
                  </div>
                </div>

                <div className="pageaction">
                  <span
                    className="cms-list-visiblity cms-icon-tooltip"
                    onClick={(e) =>
                      handleEyeButton(
                        e,
                        eachPage._id,
                        eachPage.show_in_results,
                        indx,
                        eachPage.child
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25.284"
                      height="20.465"
                      viewBox="0 0 25.284 20.465"
                    >
                      <g
                        id="Group_39189"
                        data-name="Group 39189"
                        transform="translate(0.568 0.353)"
                      >
                        <path
                          id="Path_103079"
                          data-name="Path 103079"
                          d="M68.716,9.742a13.852,13.852,0,0,1-12.07,7.684A13.876,13.876,0,0,1,44.568,9.742,13.876,13.876,0,0,1,56.646,2.058,13.852,13.852,0,0,1,68.716,9.742Z"
                          transform="translate(-44.568 0.138)"
                          fill="none"
                          stroke=""
                          stroke-miterlimit="10"
                          stroke-width="1"
                        />
                        <ellipse
                          id="Ellipse_8629"
                          data-name="Ellipse 8629"
                          cx="3.842"
                          cy="3.842"
                          rx="3.842"
                          ry="3.842"
                          transform="translate(8.233 6.038)"
                          fill="none"
                          stroke=""
                          stroke-miterlimit="10"
                          stroke-width="1"
                        />
                        <line
                          id="Line_430"
                          data-name="Line 430"
                          x2="19.758"
                          y2="19.758"
                          transform="translate(2.196 0.001)"
                          fill="none"
                          stroke=""
                          stroke-miterlimit="10"
                          stroke-width="1"
                        />
                      </g>
                    </svg>
                    <span
                      className="cms-icon-tooltipsubheader"
                      style={{ width: " fit-content", padding: "4px 10px" }}
                    >
                      Hide
                    </span>
                  </span>

                  <span
                    className="cms-icon-tooltip"
                    style={{ margin: "unset" }}
                  >
                    <img
                      onClick={(e) => handleClonePage(e, eachPage)}
                      className="img-fluid"
                      src={
                        require("../../assets/images/dashboard/cms/duplicate.png")
                          .default
                      }
                    />
                    <span
                      className="cms-icon-tooltipsubheader"
                      style={{ width: " fit-content", padding: "4px 10px" }}
                    >
                      Copy
                    </span>
                  </span>

                  <button
                    style={{ position: "relative", top: "-1px" }}
                    className="pages-delete-mob"
                    onClick={(e) => handleTrashedPopUp(e, "add", eachPage)}
                  >
                    <span
                      className="cms-icon-tooltip cms-icon-tooltip-delete"
                      style={{ margin: "unset" }}
                    >
                      <img
                        className="img-fluid"
                        src={
                          require("../../assets/images/dashboard/cms/delete.png")
                            .default
                        }
                      />
                      <span
                        className="cms-icon-tooltipsubheader"
                        style={{ width: " fit-content", padding: "4px 10px" }}
                      >
                        {" "}
                        Delete
                      </span>
                    </span>
                  </button>
                </div>

                <div className="d-block d-xl-none pageedit">
                  <UncontrolledDropdown className="custom-drop">
                    <DropdownToggle caret>
                      <img
                        className="img-fluid"
                        src={
                          require("../../assets/images/dashboard/cms/threedot.png")
                            .default
                        }
                      />
                    </DropdownToggle>
                    <DropdownMenu flip>
                      <DropdownItem>
                        <Link
                          to={{
                            pathname: `/app/page/${eachPage._id}`,
                          }}
                        >
                          Edit
                        </Link>
                      </DropdownItem>

                      <DropdownItem
                        onClick={(e) => handleTrashedPopUp(e, "add", eachPage)}
                      >
                        Delete
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              </div>
            </>
          )
        );
      });
  };

  const getTotalLength = (items) => {
    if (searchVal) {
      let totalLength = items.filter((elem) =>
        elem.title.toLowerCase().includes(searchVal.toLowerCase())
      ).length;
      for (const item of items) {
        if (item.child && item.child.length > 0) {
          totalLength += getTotalLength(item.child);
        }
      }
      return totalLength;
    } else {
      let totalLength = items.length;
      for (const item of items) {
        if (item.child && item.child.length > 0) {
          totalLength += getTotalLength(item.child);
        }
      }
      return totalLength;
    }
  };

  function getAllIds() {
    if (searchVal) {
      const filteredIds = [];
      function collectIds(pages) {
        pages.forEach((page) => {
          if (page.title.toLowerCase().includes(searchVal.toLowerCase())) {
            filteredIds.push(page._id);
          }
          if (page.child && page.child.length > 0) {
            collectIds(page.child);
          }
        });
      }
      collectIds(pagesData);
      return filteredIds;
    } else {
      const pageIds = [];
      const stack = [...pagesData];
      while (stack.length > 0) {
        const elem = stack.pop();
        pageIds.push(elem._id);
        if (elem.child && elem.child.length > 0) {
          stack.push(...elem.child);
        }
      }
      return pageIds;
    }
  }

  const handleSelected = (id) => {
    if (id === "all") {
      selectedArr.length === getTotalLength(pagesData)
        ? setSelectedArr([])
        : setSelectedArr(getAllIds());
    } else {
      const currArr = [...selectedArr];
      if (currArr.includes(id)) {
        setSelectedArr(currArr.filter((elem) => elem !== id));
      } else {
        setSelectedArr([...currArr, id]);
      }
    }
  };

  const childParentTable = (newArr) => {
    return newArr
      .filter((row, ind) =>
        row.title.toLowerCase().includes(searchVal.toLowerCase())
      )
      .map((page, indx) => {
        return (
          page.deleted_at === null && (
            <div
              key={`page_${indx}`}
              className={`${
                page.show_in_results || page.show_in_results === undefined
                  ? "bulk-update-type-div"
                  : "bulk-update-type-div-disable"
              }`}
            >
              <div className="bulk-radio-button">
                <input type="radio" checked={selectedArr.includes(page._id)} />
                <span
                  onClick={() => handleSelected(page._id)}
                  className="bulk-update-select-checkmark"
                ></span>
              </div>

              <Link
                to={{ pathname: `/app/page/${page._id}` }}
                className="bulk-update-clickable"
              >
                <div
                  className="bulkUpdate-page-body-firstdiv"
                  style={{ position: "relative" }}
                >
                  <Link
                    className="page-list-link"
                    to={{ pathname: `/app/page/${page._id}` }}
                  ></Link>
                  <div>
                    {page.level > 0 ? handleDash(page.level) : ""}
                    {page.title}
                  </div>{" "}
                </div>

                <div className="bulkUpdate-page-body-seconddiv">
                  {page.author_info &&
                  page.author_info?.profile_image?.indexOf("https") === -1 ? (
                    <img
                      className="header-profil-img"
                      src={
                        process.env.REACT_APP_Image_Path +
                        page.author_info?.id +
                        "/" +
                        "profile" +
                        "/" +
                        page.author_info?.profile_image
                      }
                      style={{
                        width: "24px",
                        borderRadius: "50%",
                        height: "24px",
                        backgroundSize: "cover",
                      }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = userdefaultImgOnError;
                      }}
                    />
                  ) : (
                    <img
                      className="header-profil-img g-image"
                      src={
                        page.author_info?.profile_image ||
                        page.owner_user?.profile_image ||
                        userdefaultImgOnError
                      }
                      style={{
                        width: "24px",
                        borderRadius: "50%",
                        height: "24px",
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover",
                      }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = userdefaultImgOnError;
                      }}
                    />
                  )}
                  {page.author_info?.name || page.owner_user?.name}
                </div>
                <div className="bulkUpdate-page-body-thirddiv">
                  {page.created_at
                    ? moment(page.created_at).format("D MMMM YYYY, HH:mm")
                    : ""}
                </div>
                <div className="bulkUpdate-page-body-fourthdiv">
                  {page.updated_at
                    ? moment(page.updated_at).format("D MMMM YYYY, HH:mm")
                    : ""}
                </div>
                <div
                  // style={{color:"#7782A1"}}
                  className={`pagestatus ${
                    page.status === "publish" ? "statusgreen" : "statusgrey"
                  } bulkUpdate-page-body-fifthdiv`}
                >
                  {page.status === "publish" ? "published" : page.status}
                </div>
              </Link>

              <div>
                <div className="pageaction bulkUpdate-page-body-sixthdiv">
                  <span
                    className="cms-list-visiblity"
                    onClick={(e) =>
                      handleEyeButton(
                        e,
                        page._id,
                        page.show_in_results,
                        indx,
                        page.child
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25.284"
                      height="20.465"
                      viewBox="0 0 25.284 20.465"
                    >
                      <g
                        id="Group_39189"
                        data-name="Group 39189"
                        transform="translate(0.568 0.353)"
                      >
                        <path
                          id="Path_103079"
                          data-name="Path 103079"
                          d="M68.716,9.742a13.852,13.852,0,0,1-12.07,7.684A13.876,13.876,0,0,1,44.568,9.742,13.876,13.876,0,0,1,56.646,2.058,13.852,13.852,0,0,1,68.716,9.742Z"
                          transform="translate(-44.568 0.138)"
                          fill="none"
                          stroke=""
                          stroke-miterlimit="10"
                          stroke-width="1"
                        />
                        <ellipse
                          id="Ellipse_8629"
                          data-name="Ellipse 8629"
                          cx="3.842"
                          cy="3.842"
                          rx="3.842"
                          ry="3.842"
                          transform="translate(8.233 6.038)"
                          fill="none"
                          stroke=""
                          stroke-miterlimit="10"
                          stroke-width="1"
                        />
                        <line
                          id="Line_430"
                          data-name="Line 430"
                          x2="19.758"
                          y2="19.758"
                          transform="translate(2.196 0.001)"
                          fill="none"
                          stroke=""
                          stroke-miterlimit="10"
                          stroke-width="1"
                        />
                      </g>
                    </svg>
                  </span>

                  <img
                    onClick={(e) => {
                      const listBody = document.querySelector(
                        '[class*="bulkUpdate-page"]'
                      );
                      if (listBody) {
                        listBody?.scrollTo({ top: 0, behavior: "smooth" });
                      }
                      handleClonePage(e, page);
                    }}
                    className="img-fluid"
                    src={
                      require("../../assets/images/dashboard/cms/duplicate.png")
                        .default
                    }
                  />

                  <button
                    style={{ position: "relative", top: "-1px" }}
                    className="pages-delete-mob"
                    onClick={(e) => handleTrashedPopUp(e, "add", page)}
                  >
                    <img
                      className="img-fluid"
                      src={
                        require("../../assets/images/dashboard/cms/delete.png")
                          .default
                      }
                    />
                  </button>
                </div>
              </div>
            </div>
          )
        );
      });
  };

  let newArr = [];

  function recurs(abcd1, check) {
    return abcd1.map((eachPage, indx) => {
      newArr.push(eachPage);

      if (eachPage.child !== undefined && eachPage.child.length > 0) {
        // if parent has childrens
        recurs(eachPage.child, "child");
      }

      return tableView
        ? check === "parent" &&
            indx === pagesData.length - 1 &&
            childParentTable(newArr)
        : check === "parent" &&
            indx === pagesData.length - 1 &&
            childParentList(newArr);
    });
  }

  const mobileChildParentList = (newMobileArr) => {
    return newMobileArr
      .filter((row, ind) =>
        row.title.toLowerCase().includes(searchVal.toLowerCase())
      )
      .map((eachPage, indx) => {
        return (
          eachPage.deleted_at === null && (
            <>
              <div className="page-list-mob-entry">
                <div
                  className="pagelist-mobile-one"
                  style={{
                    justifyContent: "space-between",
                    position: "relative",
                    opacity:
                      eachPage.show_in_results === 1 ||
                      eachPage.show_in_results === undefined
                        ? 1
                        : 0.5,
                  }}
                >
                  <Link
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "100%",
                      top: "0",
                      left: "0",
                    }}
                    className="page-list-link"
                    to={{ pathname: `/app/page/${eachPage._id}` }}
                  ></Link>
                  <h3>
                    {eachPage.level > 0 ? handleDash(eachPage.level) : ""}
                    {eachPage.title}
                  </h3>
                  <div className="pagecreator-detail">
                    {eachPage.author_info !== undefined &&
                    eachPage.author_info !== null ? (
                      <div>
                        {eachPage.author_info?.profile_image === "" ||
                        eachPage.author_info?.profile_image === null ||
                        eachPage.author_info?.profile_image === "null" ||
                        eachPage.author_info?.profile_image === undefined ||
                        eachPage.author_info?.profile_image === "undefined" ||
                        !eachPage.author_info?.profile_image ? (
                          <img
                            style={{
                              width: "24px",
                              height: "24px",
                            }}
                            src={userdefaultImgOnError}
                            className="img-fluid"
                            alt="profile logo"
                          />
                        ) : (
                          <>
                            {eachPage.author_info &&
                            eachPage.author_info?.profile_image?.indexOf(
                              "https"
                            ) === -1 ? (
                              <img
                                className="header-profil-img"
                                src={
                                  process.env.REACT_APP_Image_Path +
                                  eachPage.author_info?.id +
                                  "/" +
                                  "profile" +
                                  "/" +
                                  eachPage.author_info?.profile_image
                                }
                                style={{
                                  width: "24px",
                                  borderRadius: "50%",
                                  height: "24px",
                                  backgroundSize: "cover",
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = userdefaultImgOnError;
                                }}
                              />
                            ) : (
                              <img
                                className="header-profil-img"
                                src={eachPage.author_info?.profile_image}
                                style={{
                                  width: "24px",
                                  borderRadius: "50%",
                                  height: "24px",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = userdefaultImgOnError;
                                }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    ) : eachPage.owner_user ? (
                      <div>
                        {eachPage.owner_user.profile_image === "" ||
                        eachPage.owner_user.profile_image === null ||
                        eachPage.owner_user.profile_image === "null" ||
                        eachPage.owner_user.profile_image === undefined ||
                        eachPage.owner_user.profile_image === "undefined" ||
                        !eachPage.owner_user.profile_image ? (
                          <img
                            style={{
                              width: "24px",
                              height: "24px",
                            }}
                            src={userdefaultImgOnError}
                            className="img-fluid"
                            alt="profile logo"
                          />
                        ) : (
                          <>
                            {eachPage.owner_user?.profile_image?.indexOf(
                              "https"
                            ) === -1 ? (
                              <img
                                className="header-profil-img"
                                src={
                                  process.env.REACT_APP_Image_Path +
                                  eachPage.owner_user.id +
                                  "/" +
                                  "profile" +
                                  "/" +
                                  eachPage.owner_user?.profile_image
                                }
                                style={{
                                  width: "24px",
                                  borderRadius: "50%",
                                  height: "24px",
                                  backgroundSize: "cover",
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = userdefaultImgOnError;
                                }}
                              />
                            ) : (
                              <img
                                className="header-profil-img"
                                src={eachPage.owner_user?.profile_image}
                                style={{
                                  width: "24px",
                                  borderRadius: "50%",
                                  height: "24px",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                }}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = userdefaultImgOnError;
                                }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    ) : (
                      <div>
                        {profileimage === "" ||
                        profileimage === null ||
                        profileimage === "null" ||
                        profileimage === undefined ||
                        profileimage === "undefined" ||
                        !profileimage ? (
                          <img
                            style={{
                              width: "24px",
                              //borderRadius: "50%",
                              height: "24px",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }}
                            src={userdefaultImgOnError}
                            className="img-fluid"
                            alt="profile logo"
                          />
                        ) : (
                          <>
                            {profileimage.indexOf("https") === -1 ? (
                              <img
                                className="header-profil-img"
                                style={{
                                  // background:
                                  //   "url(" +
                                  //   profilepath +
                                  //   profileimage +
                                  //   ")",
                                  width: "24px",
                                  borderRadius: "50%",
                                  height: "24px",
                                  backgroundSize: "cover",
                                }}
                                src={profilepath + profileimage}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = userdefaultImgOnError;
                                }}
                              />
                            ) : (
                              <img
                                className="header-profil-img"
                                style={{
                                  // background:
                                  //   "url(" + profileimage + ")",
                                  width: "24px",
                                  borderRadius: "50%",
                                  height: "24px",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "cover",
                                }}
                                src={profileimage}
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null;
                                  currentTarget.src = userdefaultImgOnError;
                                }}
                              />
                            )}
                          </>
                        )}
                      </div>
                    )}
                    <h2>
                      {eachPage.author_info !== undefined &&
                      eachPage.author_info !== null
                        ? eachPage.author_info.name
                        : eachPage.owner_user
                        ? eachPage.owner_user.name
                        : localStorage.getItem("user_name")}
                    </h2>
                  </div>
                </div>

                <div className="pagelist-mobile-two">
                  <div
                    className="pagestatussection"
                    style={{
                      opacity:
                        eachPage.show_in_results === 1 ||
                        eachPage.show_in_results === undefined
                          ? 1
                          : 0.5,
                    }}
                  >
                    <p
                      className={`pagestatus ${
                        eachPage.status === "publish"
                          ? "statusgreen"
                          : "statusgrey"
                      }`}
                    >
                      {eachPage.status === "publish"
                        ? "published"
                        : eachPage.status}
                    </p>

                    <h4>
                      {moment(eachPage.created_at).format("D MMMM YYYY, HH:mm")}
                    </h4>
                  </div>

                  <div
                    className={`post-actions-mob  ${
                      eachPage.show_in_results === 1 ||
                      eachPage.show_in_results === undefined
                        ? ""
                        : "cms-entry-mob"
                    }`}
                  >
                    <span
                      onClick={(e) =>
                        handleEyeButton(
                          e,
                          eachPage._id,
                          eachPage.show_in_results,
                          indx,
                          eachPage.child
                        )
                      }
                      style={{ position: "relative", top: "-1px" }}
                      className="cms-list-visiblity "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.284"
                        height="18.465"
                        viewBox="0 0 25.284 20.465"
                      >
                        <g
                          id="Group_39189"
                          data-name="Group 39189"
                          transform="translate(0.568 0.353)"
                        >
                          <path
                            id="Path_103079"
                            data-name="Path 103079"
                            d="M68.716,9.742a13.852,13.852,0,0,1-12.07,7.684A13.876,13.876,0,0,1,44.568,9.742,13.876,13.876,0,0,1,56.646,2.058,13.852,13.852,0,0,1,68.716,9.742Z"
                            transform="translate(-44.568 0.138)"
                            fill="none"
                            stroke=""
                            stroke-miterlimit="10"
                            stroke-width="1"
                          />
                          <ellipse
                            id="Ellipse_8629"
                            data-name="Ellipse 8629"
                            cx="3.842"
                            cy="3.842"
                            rx="3.842"
                            ry="3.842"
                            transform="translate(8.233 6.038)"
                            fill="none"
                            stroke=""
                            stroke-miterlimit="10"
                            stroke-width="1"
                          />
                          <line
                            id="Line_430"
                            data-name="Line 430"
                            x2="19.758"
                            y2="19.758"
                            transform="translate(2.196 0.001)"
                            fill="none"
                            stroke=""
                            stroke-miterlimit="10"
                            stroke-width="1"
                          />
                        </g>
                      </svg>
                    </span>

                    <img
                      onClick={(e) => handleClonePage(e, eachPage)}
                      src={
                        require("../../assets/images/dashboard/cms/duplicate.png")
                          .default
                      }
                    />

                    <button
                      style={{ position: "relative", top: "-1px" }}
                      className="pages-delete-mob"
                    >
                      <img
                        onClick={(e) => handleTrashedPopUp(e, "add", eachPage)}
                        className="img-fluid"
                        src={
                          require("../../assets/images/dashboard/cms/delete.png")
                            .default
                        }
                      />
                    </button>
                  </div>
                </div>
              </div>
            </>
          )
        );
      });
  };

  let newMobileArr = [];

  function mobileRecurs(abcd1, check) {
    return abcd1.map((eachPage, indx) => {
      newMobileArr.push(eachPage);

      if (eachPage.child !== undefined && eachPage.child.length > 0) {
        // if parent has childrens
        mobileRecurs(eachPage.child, "child");
      }

      return (
        check === "parent" &&
        indx === pagesData.length - 1 &&
        mobileChildParentList(newMobileArr)
      );
    });
  }

  const previewChildParentList = (prviewListingPages, from) => {
    const uniqueArr = Array.from(
      new Set(prviewListingPages.map((item) => JSON.stringify(item)))
    ).map((item) => JSON.parse(item));
    return uniqueArr.map((eachPage, indx) => {
      return (
        eachPage.deleted_at === null &&
        (eachPage.show_in_results === 1 ||
          eachPage.show_in_results === undefined) &&
        (eachPage.level == 0 ? (
          <p
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: pageLayout == "layout-1" ? "9px" : "44px",
              background:
                pageLayout == "layout-1" && from == "pageOptions"
                  ? `${state?.pageOptions?.tile_background_color_on_pages_listing_page}`
                  : "",
              color:
                from == "pageOptions"
                  ? `${state?.pageOptions?.text_color_on_pages_listing_page}`
                  : "",
            }}
            className={pageLayout == "layout-1" ? "pages-preview-card" : ""}
          >
            {/* {eachPage.level > 0 ? handleDash(eachPage.level) : ""} */}
            {eachPage.title}
            {eachPage?.child.length >= 1 && (
              <img
                className="img-fluid"
                src={
                  require("../../assets/images/dashboard/back-arrow.svg")
                    .default
                }
              />
            )}
          </p>
        ) : (
          ""
        ))
      );
    });
  };

  let prviewListingPages = [];

  function previewRecurs(abcd1, check, from) {
    return abcd1.map((eachPage, indx) => {
      prviewListingPages.push(eachPage);

      if (eachPage.child !== undefined && eachPage.child.length > 0) {
        // if parent has childrens
        previewRecurs(eachPage.child, "child", from);
      }
      return (
        check === "parent" &&
        indx === pagesData.length - 1 &&
        previewChildParentList(prviewListingPages, from)
      );
    });
  }

  const addCopySuffix = (inputString) => {
    if (inputString.includes("clone")) {
      var match = inputString.match(/clone\((\d+)\)/);

      if (match) {
        var existingNumber = parseInt(match[1]);
        var newNumber = existingNumber + 1;
        var newSuffix = "- clone - clone";

        return inputString.replace(/clone\(\d+\)/, newSuffix);
      }
    }

    return inputString + " - clone";
  };

  const handleClonePage = (e, eachPage) => {
    setBottomSpinner(true);

    let newContentArr = JSON.parse(JSON.stringify(eachPage.content));
    newContentArr.map((element) => {
      if (element.item_type === "title") {
        element.value.title.text = addCopySuffix(eachPage.title);
      }
    });

    const params = {
      app_id: eachPage.app_id,
      user_id: parseInt(localStorage.getItem("user_id")),

      author_user_id: parseInt(localStorage.getItem("logged_in_user_id")),
      title: addCopySuffix(eachPage.title),
      description: eachPage.description,
      content: newContentArr,
      parent: eachPage.parent,
      parent_name: eachPage.parent_name,
      featured_media: eachPage.featured_media,
      source_file: eachPage.source_file,
      status: eachPage.status,
      // child:eachPage.child,
      level: eachPage.level ? eachPage.level : 0,
      page_id: "",
      show_in_results:
        eachPage.show_in_results !== undefined &&
        eachPage.show_in_results !== null
          ? eachPage.show_in_results
          : 1,
    };

    cmsSavePageApiForListing(params, setBottomSpinner, () => {
      let limit = 20;
      let pageNo = 1;
      setPage(pageNo);
      cmsGetPagesApi(
        setPagesData,
        btoa(localStorage.getItem("Appid")),
        setBottomSpinner,
        "",
        limit,
        pageNo,
        pagesData,
        setHitNextPagination,
        setPaginationSpinner,
        setPagesDataCopy
      );
    });
  };

  const handleRightPanelExpand = (value) => {
    setAppRightPanelExpand(value);
  };

  function recursiveFn(abcd1, pageID, status) {
    return abcd1.map((eachPage) => {
      const updatedNode = { ...eachPage };

      if (eachPage._id === pageID) {
        updatedNode.show_in_results =
          status === 1 || status === undefined || status === null ? 0 : 1;
      }

      if (eachPage.child !== undefined && eachPage.child.length > 0) {
        // if parent has childrens
        updatedNode.child = recursiveFn(eachPage.child, pageID, status);
        recursiveFn(eachPage.child, pageID, status, "child");
      }
      return updatedNode;
    });
  }

  const handleEyeButton = (e, pageID, status, indx, isChild) => {
    if (isChild === undefined || isChild === null || isChild.length === 0) {
      let newArr = [...pagesData];

      setPagesData(recursiveFn(newArr, pageID, status));

      const params = {
        app_id: parseInt(localStorage.getItem("Appid")),
        page_id: pageID,
        show_in_results:
          status === 1 || status === undefined || status === null ? 0 : 1,
      };
      hideUnhidePageApi(params, pagesData, setPagesData, indx);
    } else {
      errortoast("Parent page can't be hidden.");
    }
  };

  const handlePostFilterOutsideClick = (e) => {
    if (
      e.target?.className === "opec" ||
      (!openSingleSelectionPopUp && !bulkDeletePopup)
    ) {
      setOpenDrawer(false);
      document.body.classList.remove("opec");
    }
  };

  const handleSortTable = (key, type) => {
    type === "reset"
      ? setPagesData(pagesDataCopy)
      : setPagesData(
          [...pagesData].sort(
            (a, b) =>
              ((key === "author_info"
                ? a[key].name?.toLowerCase()
                : a[key]?.toLowerCase()) >
              (key === "author_info"
                ? b[key].name?.toLowerCase()
                : b[key]?.toLowerCase())
                ? 1
                : -1) * (type === "asc" ? 1 : -1)
          )
        );
  };

  function filterPages(pagesData, selectedArr) {
    return pagesData.reduce((acc, elem) => {
      if (selectedArr.includes(elem._id)) return acc;
      let filteredChildren = [];
      if (elem.child && elem.child.length > 0) {
        filteredChildren = filterPages(elem.child, selectedArr);
      }
      acc.push({ ...elem, child: filteredChildren });
      return acc;
    }, []);
  }

  const handleOpenSelectionPopUp = (e, from) => {
    const modalList = filterPages(pagesData, selectedArr);
    setOpenSingleSelectionPopUp(!openSingleSelectionPopUp);

    if (from === "cancel") {
      // setSubData(oldSubData);
      setBreadcrumbArr([]);
      setTempParentId(parentId);
      setParentTempName(parentName);
    } else {
      // if(!subData || subData.length === 0){
      setSubData(modalList);
      // }
      setparentId(tempParentId);
      setparentName(parentTempName);
      setBreadcrumbArr([]);
      setOldSubData(modalList);
    }
  };

  //Saving parent Id
  const handleParentChange = (e, pID, title) => {
    // setparentId(pID)
    // setparentName(title)
    setTempParentId(pID);
    setParentTempName(title);
    setOpenMobileAppDropdown(!openMobileAppDropdown);
  };

  const handleBottomScrollListner = () => {
    if (paginationSpinner === false) {
      if (hitNextPagination) {
        let limit = 99;
        let pageNo = page + 1;
        setPage(pageNo);
        setPaginationSpinner(true);
        cmsGetPagesApi(
          setPagesData,
          btoa(localStorage.getItem("Appid")),
          setSpinner,
          (data, mergeData) => {
            setSubData(mergeData);
          },
          limit,
          pageNo,
          pagesData,
          setHitNextPagination,
          setPaginationSpinner
        );
      }
    }
  };

  const handleDrawerClose = (shouldClose) => {
    if (shouldClose) {
      setOpenDrawer(false);
      document.body.classList.remove("opec");
      setSelectedArr([]);
    }
    setBulkUpdateData({
      author_user_id: null,
      status: null,
    });
    setparentName("");
    setParentTempName("");
    setTempParentId(null);
    setparentId(null);
    setBreadcrumbArr([]);
  };

  const canNotDeletePage = () => {
    const selectedSet = new Set(selectedArr);
    const hasChildAtAnyLevel = (pageObj) => {
      if (selectedSet.has(pageObj._id)) {
        if (pageObj.child && pageObj.child.length > 0) {
          return true;
        }
      }
      if (pageObj.child && Array.isArray(pageObj.child)) {
        return pageObj.child.some((child) => hasChildAtAnyLevel(child));
      }
      return false;
    };
    return pagesData.some((pageObj) => hasChildAtAnyLevel(pageObj));
  };

  const handleBulkUpdateSubmit = async (fromDelete) => {
    if (fromDelete && canNotDeletePage()) {
      errortoast(
        "One of the selected pages has a child page. Delete the child page to proceed."
      );
      return;
    }
    fromDelete ? setBulkDeleteLoader(true) : setBulkUpdateLoader(true);
    const apiResponse = await bulkPageUpdate({
      app_id: parseInt(localStorage.getItem("Appid")),
      author_user_id: bulkUpdateData.author_user_id?.value || "",
      status: bulkUpdateData.status?.value || "",
      is_deleted: fromDelete ? 1 : 0,
      page_ids: selectedArr,
      parent: parentId,
    });
    if (apiResponse?.data?.code === 200) {
      sucesstoast(
        `${selectedArr.length > 1 ? "Pages" : "Page"} ${
          fromDelete ? "deleted" : "updated"
        } successfully`
      );
      setSpinner(true);
      handleDrawerClose(true);
      let limit = 20;
      let pageNo = 1;
      setPage(pageNo);
      cmsGetPagesApi(
        setPagesData,
        btoa(localStorage.getItem("Appid")),
        setSpinner,
        "",
        limit,
        pageNo,
        pagesData,
        setHitNextPagination,
        setPaginationSpinner,
        setPagesDataCopy
      );
    } else {
      errortoast(apiResponse.data.status);
    }
    fromDelete ? setBulkDeleteLoader(false) : setBulkUpdateLoader(false);
    fromDelete && setBulkDeletePopup(false);
  };

  const updatePageOption = (key, value) => {
    dispatch({
      type: "UPDATE_PAGE_OPTION",
      payload: { key, value },
    });
  };

  function reducer(state, action) {
    switch (action.type) {
      case "SET_STATE":
        return { ...state, ...action.payload };
      case "UPDATE_PAGE_OPTION":
        return {
          ...state,
          pageOptions: {
            ...state.pageOptions,
            [action.payload.key]: action.payload.value,
          },
        };
      default:
        return state;
    }
  }

  const savePageSettings = () => {
    const params = {
      app_id: localStorage.getItem("Appid"),
      enable_web_view: 0,
      show_additional_information: 0,
      background_color_on_pages_listing_page:
        state.pageOptions.background_color_on_pages_listing_page,
      // enable_share_feature_on_pages_detail_page:
      //   state.pageOptions.enable_share_feature_on_pages_detail_page,
      // image_ratio_on_pages_detail_page:
      //   state.pageOptions.image_ratio_on_pages_detail_page,
      image_shape_on_pages_detail_page:
        state.pageOptions.image_shape_on_pages_detail_page,
      pages_layout: pageLayout,
      section_margin_bottom_on_pages_detail_page:
        state.pageOptions.section_margin_bottom_on_pages_detail_page || "7.5",
      section_margin_left_on_pages_detail_page:
        state.pageOptions.section_margin_left_on_pages_detail_page || "15",
      section_margin_right_on_pages_detail_page:
        state.pageOptions.section_margin_right_on_pages_detail_page || "15",
      section_margin_top_on_pages_detail_page:
        state.pageOptions.section_margin_top_on_pages_detail_page || "7.5",
      show_center_crop_images_on_pages_detail_page:
        state.pageOptions.show_center_crop_images_on_pages_detail_page,
      show_media_as_thumbnail_on_pages_detail_page:
        state.pageOptions.show_media_as_thumbnail_on_pages_detail_page,
      show_media_in_full_screen_on_pages_detail_page:
        state.pageOptions.show_media_in_full_screen_on_pages_detail_page,
      // show_search_icon_on_pages_listing_page:
      //   state.pageOptions.show_search_icon_on_pages_listing_page,
      text_color_on_pages_listing_page:
        state.pageOptions.text_color_on_pages_listing_page,
      tile_background_color_on_pages_listing_page:
        state.pageOptions.tile_background_color_on_pages_listing_page,
      //enable_header_in_web_view: this.state.enable_header_in_web_view === true ? 1 : 0,
      enable_hyperlinks: 1,
      enable_form_inputs: 1,
    };
    Pagesapi(params, this, setSavePageSettingLoading, setUpdatedAt);
  };

  return (
    <>
      <div className="d-md-block">
        <Header customclass={"header-responsive-new"} />
      </div>
      {/* Mobile View */}
      {/* Mobile View End*/}
      <section className="dasboard_page mobile-display mt-4 mt-md-0">
        <Leftpanel handleRightPanelExpand={handleRightPanelExpand} />
        <BottomScrollListener
          onBottom={
            window.matchMedia("(max-width: 1200px)").matches
              ? handleContainerOnBottom
              : handleNotHitOnBottom
          }
          offset={100}
        >
          {(scrollRef) => (
            <div
              style={{
                height: "calc(100vh - 60px)",
                overflowY: "auto",
              }}
              ref={scrollRef}
              className={`right-panel-gap ${
                localStorage.getItem("appSideBarCollapse") !== undefined &&
                localStorage.getItem("appSideBarCollapse") !== null &&
                localStorage.getItem("appSideBarCollapse") === "true"
                  ? " right-panel-gap-expand"
                  : ""
              }`}
            >
              {/* Bulk Update filter */}
              <OutsideClickHandler
                onOutsideClick={(e) => handlePostFilterOutsideClick(e)}
              >
                <div
                  className={`${
                    openDrawer ? "show-bulkUpdate-filte" : ""
                  } bulkUpdate-filter`}
                >
                  <h2 className="bulkUpdate-filter-head d-flex justify-content-between">
                    <span>
                      Bulk update{" "}
                      <Tooltip
                        message={"Apply changes to multiple pages at once."}
                      />
                    </span>
                    <span
                      className="bulk-update-Reset"
                      style={{ cursor: "pointer" }}
                      onClick={() => handleDrawerClose()}
                    >
                      {/* <img src={require('../../assets/images/dashboard/cross.png').default} alt="" /> */}
                      Reset{" "}
                      <Tooltip
                        message={"Clear all selections and start over."}
                      />
                    </span>
                  </h2>
                  <div className="bulkUpdate-filter-body-parent">
                    <h3>
                      Parent
                      <Tooltip
                        message={
                          "Choose a parent page for selected child pages."
                        }
                      />
                    </h3>
                    <div
                      className="bulkUpdate-filter-body-select"
                      onClick={(e) => handleOpenSelectionPopUp(e, "open")}
                    >
                      {parentName ? (
                        <p>{parentName}</p>
                      ) : (
                        <p style={{ color: "#bcc1ce" }}>Select</p>
                      )}
                      {/* <p>{parentName ? parentName: "Select" }</p> */}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="19"
                        viewBox="0 0 20 19"
                      >
                        <g
                          id="Group_42683"
                          data-name="Group 42683"
                          transform="translate(-1005 -1029)"
                        >
                          <g
                            id="Rectangle_31915"
                            data-name="Rectangle 31915"
                            transform="translate(1005 1029)"
                            fill="#fff"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          >
                            <rect width="20" height="19" rx="2" stroke="none" />
                            <rect
                              x="0.5"
                              y="0.5"
                              width="19"
                              height="18"
                              rx="1.5"
                              fill="none"
                            />
                          </g>
                          <path
                            id="Path_119031"
                            data-name="Path 119031"
                            d="M15578.713-3593.554h2"
                            transform="translate(-14569.713 4628.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119032"
                            data-name="Path 119032"
                            d="M15578.713-3593.554h2"
                            transform="translate(-14569.713 4632.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119033"
                            data-name="Path 119033"
                            d="M15578.713-3593.554h2"
                            transform="translate(-14569.713 4636.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119034"
                            data-name="Path 119034"
                            d="M15578.711-3593.554h8"
                            transform="translate(-14565.713 4628.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119035"
                            data-name="Path 119035"
                            d="M15578.711-3593.554h8"
                            transform="translate(-14565.713 4632.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                          <path
                            id="Path_119036"
                            data-name="Path 119036"
                            d="M15578.711-3593.554h8"
                            transform="translate(-14565.713 4636.054)"
                            fill="none"
                            stroke="#bcc1ce"
                            stroke-width="1"
                          />
                        </g>
                      </svg>
                    </div>
                    <div className="bulkupdate-filter-selectdiv">
                      <h3>
                        Author
                        <Tooltip
                          message={
                            "Assign a specific author to the selected pages."
                          }
                        />
                      </h3>
                      <Select
                        options={mergeUserOwnerData.map((e) => ({
                          label: e.name,
                          value: e.user_id,
                        }))}
                        classNamePrefix="post-filter"
                        onChange={(e) =>
                          setBulkUpdateData({ author_user_id: e })
                        }
                        placeholder="Select"
                        isSearchable={false}
                        value={bulkUpdateData.author_user_id}
                        formatOptionLabel={(option) => {
                          const res = mergeUserOwnerData?.find(
                            (user) => user.name === option.label
                          );
                          return (
                            <div className="cms-author">
                              <div>
                                {res?.profile_image &&
                                res.profile_image?.indexOf("https") > -1 ? (
                                  <img
                                    className="img-fluid"
                                    src={res.profile_image}
                                    alt="profile-img"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = imagebackground;
                                    }}
                                  />
                                ) : (
                                  <img
                                    className="img-fluid"
                                    src={
                                      process.env.REACT_APP_Image_Path +
                                      res.user_id +
                                      process.env.REACT_APP_Profile +
                                      res.profile_image
                                    }
                                    alt="profile-img"
                                    onError={({ currentTarget }) => {
                                      currentTarget.onerror = null;
                                      currentTarget.src = imagebackground;
                                    }}
                                  />
                                )}
                              </div>
                              <p>{res.name}</p>
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className="bulkupdate-filter-selectdiv">
                      <h3>
                        Status
                        <Tooltip
                          message={
                            "Set the publication status for the selected pages."
                          }
                        />
                      </h3>
                      <Select
                        options={[
                          { label: "Draft", value: "draft" },
                          { label: "Published", value: "publish" },
                        ]}
                        classNamePrefix="post-filter"
                        placeholder="Select"
                        onChange={(e) => setBulkUpdateData({ status: e })}
                        isSearchable={false}
                        value={bulkUpdateData.status}
                      />
                    </div>
                    {bulkUpdateLoader ? (
                      <button className="d-flex justify-content-center align-items-center bulkupdate-filter-apply-enable">
                        <div className="new-loader mob-done-loader"></div>
                      </button>
                    ) : (
                      <button
                        className="bulkupdate-filter-apply-enable"
                        disabled={
                          (!bulkUpdateData.author_user_id &&
                            !bulkUpdateData.status &&
                            parentId === null) ||
                          bulkDeleteLoader
                        }
                        onClick={() => handleBulkUpdateSubmit()}
                      >
                        Apply
                      </button>
                    )}

                    <button
                      className="bulkupdate-filter-delete-enable "
                      disabled={bulkUpdateLoader}
                      onClick={() => handleBulkDeletePopup()}
                    >
                      <DeleteIcon
                        color={bulkUpdateLoader ? "#bcc1ce" : "#3064F9"}
                      />
                      Delete selected
                    </button>
                  </div>
                </div>
              </OutsideClickHandler>
              {/* Bulk Update filter end */}

              <div className="pageshead">
                <div className="d-flex align-items-center justify-content-between mob-cms-page-header">
                  <div className="page-mob-head">
                    <h1>
                      Pages{" "}
                      <p class="cms-pages-tooltip">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                        >
                          <g
                            id="Group_35001"
                            data-name="Group 35001"
                            transform="translate(-1727 -19)"
                          >
                            <g
                              id="Ellipse_7706"
                              data-name="Ellipse 7706"
                              transform="translate(1727 19)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-width="1.5"
                            >
                              <circle
                                cx="9"
                                cy="9"
                                r="9"
                                stroke="none"
                              ></circle>
                              <circle
                                cx="9"
                                cy="9"
                                r="8.25"
                                fill="none"
                              ></circle>
                            </g>
                            <line
                              id="Line_343"
                              data-name="Line 343"
                              y1="2"
                              transform="translate(1736 23)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-miterlimit="10"
                              stroke-width="1.5"
                            ></line>
                            <path
                              id="Path_98224"
                              data-name="Path 98224"
                              d="M0,2V-4"
                              transform="translate(1736 31)"
                              fill="none"
                              stroke="#bcc1ce"
                              stroke-width="1.5"
                            ></path>
                          </g>
                        </svg>
                        <p class="cms-pages-tooltipsubheader">
                          A page is an area where you can display content, and
                          can be about anything. Common pages include Home,
                          About, and Contact pages.
                        </p>
                      </p>
                    </h1>
                    <div className="mob-page-head-right">
                      {!spinner &&
                      selectedArr.length === 0 &&
                      pagesData.length > 0 &&
                      !enableGeneralSettings &&
                      !enablePageOrdering &&
                      !enablePageLayout ? (
                        <button
                          onClick={() => {
                            setTableView((view) => {
                              localStorage.setItem("pagesTableView", !view);
                              return !view;
                            });
                            setEnableGeneralSettings(false);
                            setEnablePageLayout(false);
                            setEnablePageOrdering(false);
                          }}
                          className={
                            tableView
                              ? " setting-bt-off-hide-web hide-detail-web tagpagelayout-svg"
                              : "setting-bt-on-hide-web hide-detail-web  tagpagelayout-svg"
                          }
                        >
                          {!tableView ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22.5"
                              height="19.5"
                              viewBox="0 0 22.5 19.5"
                            >
                              <g
                                id="Group_42206"
                                data-name="Group 42206"
                                transform="translate(-1850.25 -196.25)"
                              >
                                <path
                                  id="Path_118853"
                                  data-name="Path 118853"
                                  d="M1.167,0H19.833A1.093,1.093,0,0,1,21,1V17a1.093,1.093,0,0,1-1.167,1H1.167A1.093,1.093,0,0,1,0,17V1A1.093,1.093,0,0,1,1.167,0Z"
                                  transform="translate(1851 197)"
                                  fill="none"
                                  stroke="#7782a1"
                                  stroke-width="1.5"
                                />
                                <line
                                  id="Line_443"
                                  data-name="Line 443"
                                  x2="21"
                                  transform="translate(1851 203)"
                                  fill="none"
                                  stroke="#7782a1"
                                  stroke-miterlimit="10"
                                  stroke-width="1.5"
                                />
                                <line
                                  id="Line_445"
                                  data-name="Line 445"
                                  y1="18"
                                  transform="translate(1858 197)"
                                  fill="none"
                                  stroke="#7782a1"
                                  stroke-miterlimit="10"
                                  stroke-width="1.5"
                                />
                                <line
                                  id="Line_446"
                                  data-name="Line 446"
                                  x2="21"
                                  transform="translate(1851 209)"
                                  fill="none"
                                  stroke="#7782a1"
                                  stroke-miterlimit="10"
                                  stroke-width="1.5"
                                />
                                <line
                                  id="Line_467"
                                  data-name="Line 467"
                                  y1="18"
                                  transform="translate(1865 197)"
                                  fill="none"
                                  stroke="#7782a1"
                                  stroke-miterlimit="10"
                                  stroke-width="1.5"
                                />
                              </g>
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="23"
                              height="20.001"
                              viewBox="0 0 23 20.001"
                            >
                              <path
                                id="Subtraction_287"
                                data-name="Subtraction 287"
                                d="M3924.2,756H3920v-5h6v3.2A1.8,1.8,0,0,1,3924.2,756Zm-6.2,0h-7v-5h7v5Zm-9,0h-4.2a1.8,1.8,0,0,1-1.8-1.8V751h6v5Zm0-7h-6v-5h6v5Zm17,0h-6v-5h6v5Zm-8,0h-7v-5h7v5Zm8-7h-6v-6l4.2,0a1.8,1.8,0,0,1,1.8,1.8V742Zm-8,0h-7v-6l7,0v6Zm-9,0h-6v-4.206a1.8,1.8,0,0,1,1.8-1.8h4.2v6Z"
                                transform="translate(-3903 -735.999)"
                                fill="#7782a1"
                              />
                            </svg>
                          )}
                        </button>
                      ) : (
                        !spinner &&
                        selectedArr.length === 0 &&
                        pagesData.length > 0 && (
                          <button
                            onClick={() => {
                              setTableView((view) => {
                                localStorage.setItem("pagesTableView", false);
                                return false;
                              });
                              setEnableGeneralSettings(false);
                              setEnablePageLayout(false);
                              setEnablePageOrdering(false);
                            }}
                            className={
                              "setting-bt-on-hide-web  tagpagelayout-svg"
                            }
                          >
                            <HomeIcon />
                          </button>
                        )
                      )}
                      {!spinner &&
                        selectedArr.length === 0 &&
                        pagesData.length > 0 && (
                          <button
                            onClick={() => {
                              setTableView(false);
                              setEnableGeneralSettings(false);
                              setEnablePageLayout(!enablePageLayout);
                              setEnablePageOrdering(false);
                            }}
                            className={
                              enablePageLayout
                                ? " setting-bt-off-hide-web  tagpagelayout-svg"
                                : "setting-bt-on-hide-web  tagpagelayout-svg"
                            }
                          >
                            {enablePageLayout ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xlink="http://www.w3.org/1999/xlink"
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                              >
                                <defs>
                                  <clipPath id="clip-path4">
                                    <rect
                                      id="Rectangle_29591"
                                      data-name="Rectangle 29591"
                                      width="26"
                                      height="26"
                                      transform="translate(62 1)"
                                      fill="rgba(0,0,0,0)"
                                    ></rect>
                                  </clipPath>
                                </defs>
                                <g
                                  id="Mask_Group_25287"
                                  data-name="Mask Group 25287"
                                  transform="translate(-62 -1)"
                                  clip-path="url(#clip-path4)"
                                >
                                  <g
                                    id="Group_39235"
                                    data-name="Group 39235"
                                    transform="translate(63.887 2.887)"
                                  >
                                    <rect
                                      id="Rectangle_29590"
                                      data-name="Rectangle 29590"
                                      width="16"
                                      height="22"
                                      rx="1.5"
                                      transform="translate(3.113 0.113)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-linejoin="round"
                                      stroke-width="1.5"
                                    ></rect>
                                    <path
                                      id="Path_119244"
                                      data-name="Path 119244"
                                      d="M11.113,5.113h6"
                                      transform="translate(-1)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    ></path>
                                    <path
                                      id="Path_119245"
                                      data-name="Path 119245"
                                      d="M11.113,5.113h6"
                                      transform="translate(-1 6)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    ></path>
                                    <path
                                      id="Path_119246"
                                      data-name="Path 119246"
                                      d="M11.113,5.113h6"
                                      transform="translate(-1 12)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    ></path>
                                    <g
                                      id="Group_43104"
                                      data-name="Group 43104"
                                      transform="translate(6.113 4.363)"
                                    >
                                      <path
                                        id="Path_119244-2"
                                        data-name="Path 119244"
                                        d="M11.113,5.113h2"
                                        transform="translate(-11.113 -4.363)"
                                        fill="none"
                                        stroke="#7782a1"
                                        stroke-width="1.5"
                                      ></path>
                                      <path
                                        id="Path_119245-2"
                                        data-name="Path 119245"
                                        d="M11.113,5.113h2"
                                        transform="translate(-11.113 1.637)"
                                        fill="none"
                                        stroke="#7782a1"
                                        stroke-width="1.5"
                                      ></path>
                                      <path
                                        id="Path_119246-2"
                                        data-name="Path 119246"
                                        d="M11.113,5.113h2"
                                        transform="translate(-11.113 7.637)"
                                        fill="none"
                                        stroke="#7782a1"
                                        stroke-width="1.5"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xlink="http://www.w3.org/1999/xlink"
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                              >
                                <defs>
                                  <clipPath id="clip-path4">
                                    <rect
                                      id="Rectangle_29591"
                                      data-name="Rectangle 29591"
                                      width="26"
                                      height="26"
                                      transform="translate(62 1)"
                                      fill="rgba(0,0,0,0)"
                                    ></rect>
                                  </clipPath>
                                </defs>
                                <g
                                  id="Mask_Group_25287"
                                  data-name="Mask Group 25287"
                                  transform="translate(-62 -1)"
                                  clip-path="url(#clip-path4)"
                                >
                                  <g
                                    id="Group_39235"
                                    data-name="Group 39235"
                                    transform="translate(63.887 2.887)"
                                  >
                                    <rect
                                      id="Rectangle_29590"
                                      data-name="Rectangle 29590"
                                      width="16"
                                      height="22"
                                      rx="1.5"
                                      transform="translate(3.113 0.113)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-linejoin="round"
                                      stroke-width="1.5"
                                    ></rect>
                                    <path
                                      id="Path_119244"
                                      data-name="Path 119244"
                                      d="M11.113,5.113h6"
                                      transform="translate(-1)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    ></path>
                                    <path
                                      id="Path_119245"
                                      data-name="Path 119245"
                                      d="M11.113,5.113h6"
                                      transform="translate(-1 6)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    ></path>
                                    <path
                                      id="Path_119246"
                                      data-name="Path 119246"
                                      d="M11.113,5.113h6"
                                      transform="translate(-1 12)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    ></path>
                                    <g
                                      id="Group_43104"
                                      data-name="Group 43104"
                                      transform="translate(6.113 4.363)"
                                    >
                                      <path
                                        id="Path_119244-2"
                                        data-name="Path 119244"
                                        d="M11.113,5.113h2"
                                        transform="translate(-11.113 -4.363)"
                                        fill="none"
                                        stroke="#7782a1"
                                        stroke-width="1.5"
                                      ></path>
                                      <path
                                        id="Path_119245-2"
                                        data-name="Path 119245"
                                        d="M11.113,5.113h2"
                                        transform="translate(-11.113 1.637)"
                                        fill="none"
                                        stroke="#7782a1"
                                        stroke-width="1.5"
                                      ></path>
                                      <path
                                        id="Path_119246-2"
                                        data-name="Path 119246"
                                        d="M11.113,5.113h2"
                                        transform="translate(-11.113 7.637)"
                                        fill="none"
                                        stroke="#7782a1"
                                        stroke-width="1.5"
                                      ></path>
                                    </g>
                                  </g>
                                </g>
                              </svg>
                            )}
                          </button>
                        )}

                      {!spinner &&
                        selectedArr.length === 0 &&
                        pagesData.length > 0 && (
                          <button
                            onClick={() => {
                              setTableView(false);
                              setEnableGeneralSettings(!enableGeneralSettings);
                              setEnablePageLayout(false);
                              setEnablePageOrdering(false);
                            }}
                            className={
                              enableGeneralSettings
                                ? " setting-bt-off-hide-web  tagpagelayout-svg"
                                : "setting-bt-on-hide-web  tagpagelayout-svg"
                            }
                          >
                            {enableGeneralSettings ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="26"
                                height="26"
                                viewBox="0 0 26 26"
                              >
                                <g
                                  id="Group_38340"
                                  data-name="Group 38340"
                                  transform="translate(-20 -527)"
                                >
                                  <rect
                                    id="Rectangle_25572"
                                    data-name="Rectangle 25572"
                                    width="26"
                                    height="26"
                                    transform="translate(20 527)"
                                    fill="none"
                                  />
                                  <path
                                    className="  productsetting-notselect productSetting-select"
                                    id="Path_99646"
                                    data-name="Path 99646"
                                    d="M11.513,22.5a10.829,10.829,0,0,0,1.266-.074.615.615,0,0,0,.542-.642,2.437,2.437,0,0,1,1.488-2.388,2.278,2.278,0,0,1,.887-.171,2.527,2.527,0,0,1,1.855.788.616.616,0,0,0,.837.067,10.968,10.968,0,0,0,1.739-1.76.615.615,0,0,0-.066-.829,2.432,2.432,0,0,1,1.592-4.231h.056a.73.73,0,0,0,.72-.542,11,11,0,0,0,0-2.5.592.592,0,0,0-.613-.54h-.022A2.543,2.543,0,0,1,19.4,8.19a2.437,2.437,0,0,1,.613-2.744.614.614,0,0,0,.066-.836A10.919,10.919,0,0,0,18.3,2.851a.606.606,0,0,0-.377-.131.615.615,0,0,0-.454.2,2.532,2.532,0,0,1-1.855.785,2.259,2.259,0,0,1-.868-.165A2.449,2.449,0,0,1,13.238,1.2a.614.614,0,0,0-.544-.63,11.023,11.023,0,0,0-2.474,0,.615.615,0,0,0-.539.643A2.443,2.443,0,0,1,8.195,3.6a2.318,2.318,0,0,1-.89.172,2.524,2.524,0,0,1-1.853-.788.615.615,0,0,0-.837-.069,10.968,10.968,0,0,0-1.739,1.76.615.615,0,0,0,.066.829A2.43,2.43,0,0,1,1.279,9.733a.718.718,0,0,0-.708.54,11.037,11.037,0,0,0,0,2.5.617.617,0,0,0,.6.542h.181A2.464,2.464,0,0,1,3.6,14.808a2.438,2.438,0,0,1-.615,2.743.614.614,0,0,0-.066.837A11,11,0,0,0,4.7,20.145a.616.616,0,0,0,.83-.069,2.535,2.535,0,0,1,1.853-.785,2.3,2.3,0,0,1,.87.165A2.443,2.443,0,0,1,9.76,21.8a.614.614,0,0,0,.545.628A11.109,11.109,0,0,0,11.513,22.5Z"
                                    transform="translate(21.501 529.151)"
                                    fill="#7782A1"
                                    stroke="#7782A1"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                  />
                                  <path
                                    id="Path_99647"
                                    data-name="Path 99647"
                                    d="M9.818,5.846a4,4,0,1,1-4,4A4,4,0,0,1,9.818,5.846Z"
                                    transform="translate(23.181 530.804)"
                                    fill="#ffff"
                                    stroke="#7782A1"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                  />
                                </g>
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="23.5"
                                height="23.499"
                                viewBox="0 0 23.5 23.499"
                              >
                                <g
                                  id="Group_38368"
                                  data-name="Group 38368"
                                  transform="translate(-1598.25 -88.901)"
                                >
                                  <path
                                    id="Path_99646"
                                    data-name="Path 99646"
                                    d="M11.513,22.5a10.829,10.829,0,0,0,1.266-.074.615.615,0,0,0,.542-.642,2.437,2.437,0,0,1,1.488-2.388,2.278,2.278,0,0,1,.887-.171,2.527,2.527,0,0,1,1.855.788.616.616,0,0,0,.837.067,10.968,10.968,0,0,0,1.739-1.76.615.615,0,0,0-.066-.829,2.432,2.432,0,0,1,1.592-4.231h.056a.73.73,0,0,0,.72-.542,11,11,0,0,0,0-2.5.592.592,0,0,0-.613-.54h-.022A2.543,2.543,0,0,1,19.4,8.19a2.437,2.437,0,0,1,.613-2.744.614.614,0,0,0,.066-.836A10.919,10.919,0,0,0,18.3,2.851a.606.606,0,0,0-.377-.131.615.615,0,0,0-.454.2,2.532,2.532,0,0,1-1.855.785,2.259,2.259,0,0,1-.868-.165A2.449,2.449,0,0,1,13.238,1.2a.614.614,0,0,0-.544-.63,11.023,11.023,0,0,0-2.474,0,.615.615,0,0,0-.539.643A2.443,2.443,0,0,1,8.195,3.6a2.318,2.318,0,0,1-.89.172,2.524,2.524,0,0,1-1.853-.788.615.615,0,0,0-.837-.069,10.968,10.968,0,0,0-1.739,1.76.615.615,0,0,0,.066.829A2.43,2.43,0,0,1,1.279,9.733a.718.718,0,0,0-.708.54,11.037,11.037,0,0,0,0,2.5.617.617,0,0,0,.6.542h.181A2.464,2.464,0,0,1,3.6,14.808a2.438,2.438,0,0,1-.615,2.743.614.614,0,0,0-.066.837A11,11,0,0,0,4.7,20.145a.616.616,0,0,0,.83-.069,2.535,2.535,0,0,1,1.853-.785,2.3,2.3,0,0,1,.87.165A2.443,2.443,0,0,1,9.76,21.8a.614.614,0,0,0,.545.628A11.109,11.109,0,0,0,11.513,22.5Z"
                                    transform="translate(1598.501 89.151)"
                                    fill="#ffff"
                                    stroke="#7782A1"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                  />
                                  <path
                                    id="Path_99647"
                                    data-name="Path 99647"
                                    d="M9.818,5.846a4,4,0,1,1-4,4A4,4,0,0,1,9.818,5.846Z"
                                    transform="translate(1600.182 90.804)"
                                    fill="#ffff"
                                    stroke="#7782A1"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                  />
                                </g>
                              </svg>
                            )}
                          </button>
                        )}
                    </div>
                  </div>
                  {!enableGeneralSettings && !enablePageLayout && (
                    <Link
                      className="d-flex d-xl-none align-items-center mob-cms-create-btn"
                      to={{ pathname: "/app/page/create" }}
                    >
                      <img
                        className="mr-2"
                        src={
                          require("../../assets/images/dashboard/plus.png")
                            .default
                        }
                      />{" "}
                      <p>Create</p>
                    </Link>
                  )}
                </div>
                <div className="createpage allpages ">
                  {pagesData &&
                    pagesData.length > 0 &&
                    !enableGeneralSettings &&
                    !enablePageOrdering &&
                    !enablePageLayout && (
                      <div className="cms-pages-search cms-page-mobile">
                        <input
                          placeholder="Search..."
                          type="text"
                          value={searchVal}
                          onChange={(e) => handleSearch(e)}
                        />
                        <img
                          src={
                            require("../../assets/images/dashboard/cms/lens.png")
                              .default
                          }
                        />
                      </div>
                    )}
                  <div className="web-cmsbtns-div">
                    {!spinner &&
                    selectedArr.length === 0 &&
                    pagesData.length > 0 &&
                    !enableGeneralSettings &&
                    !enablePageOrdering &&
                    !enablePageLayout ? (
                      <button
                        onClick={() => {
                          setTableView((view) => {
                            localStorage.setItem("pagesTableView", !view);
                            return !view;
                          });
                          setEnableGeneralSettings(false);
                          setEnablePageLayout(false);
                          setEnablePageOrdering(false);
                        }}
                        className={
                          tableView
                            ? " setting-bt-off-hide-web hide-detail-web tagpagelayout-svg"
                            : "setting-bt-on-hide-web hide-detail-web  tagpagelayout-svg"
                        }
                      >
                        {!tableView ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22.5"
                            height="19.5"
                            viewBox="0 0 22.5 19.5"
                          >
                            <g
                              id="Group_42206"
                              data-name="Group 42206"
                              transform="translate(-1850.25 -196.25)"
                            >
                              <path
                                id="Path_118853"
                                data-name="Path 118853"
                                d="M1.167,0H19.833A1.093,1.093,0,0,1,21,1V17a1.093,1.093,0,0,1-1.167,1H1.167A1.093,1.093,0,0,1,0,17V1A1.093,1.093,0,0,1,1.167,0Z"
                                transform="translate(1851 197)"
                                fill="none"
                                stroke="#7782a1"
                                stroke-width="1.5"
                              />
                              <line
                                id="Line_443"
                                data-name="Line 443"
                                x2="21"
                                transform="translate(1851 203)"
                                fill="none"
                                stroke="#7782a1"
                                stroke-miterlimit="10"
                                stroke-width="1.5"
                              />
                              <line
                                id="Line_445"
                                data-name="Line 445"
                                y1="18"
                                transform="translate(1858 197)"
                                fill="none"
                                stroke="#7782a1"
                                stroke-miterlimit="10"
                                stroke-width="1.5"
                              />
                              <line
                                id="Line_446"
                                data-name="Line 446"
                                x2="21"
                                transform="translate(1851 209)"
                                fill="none"
                                stroke="#7782a1"
                                stroke-miterlimit="10"
                                stroke-width="1.5"
                              />
                              <line
                                id="Line_467"
                                data-name="Line 467"
                                y1="18"
                                transform="translate(1865 197)"
                                fill="none"
                                stroke="#7782a1"
                                stroke-miterlimit="10"
                                stroke-width="1.5"
                              />
                            </g>
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="23"
                            height="20.001"
                            viewBox="0 0 23 20.001"
                          >
                            <path
                              id="Subtraction_287"
                              data-name="Subtraction 287"
                              d="M3924.2,756H3920v-5h6v3.2A1.8,1.8,0,0,1,3924.2,756Zm-6.2,0h-7v-5h7v5Zm-9,0h-4.2a1.8,1.8,0,0,1-1.8-1.8V751h6v5Zm0-7h-6v-5h6v5Zm17,0h-6v-5h6v5Zm-8,0h-7v-5h7v5Zm8-7h-6v-6l4.2,0a1.8,1.8,0,0,1,1.8,1.8V742Zm-8,0h-7v-6l7,0v6Zm-9,0h-6v-4.206a1.8,1.8,0,0,1,1.8-1.8h4.2v6Z"
                              transform="translate(-3903 -735.999)"
                              fill="#7782a1"
                            />
                          </svg>
                        )}
                      </button>
                    ) : (
                      !spinner &&
                      selectedArr.length === 0 &&
                      pagesData.length > 0 && (
                        <button
                          onClick={() => {
                            setTableView((view) => {
                              localStorage.setItem("pagesTableView", false);
                              return false;
                            });
                            setEnableGeneralSettings(false);
                            setEnablePageLayout(false);
                            setEnablePageOrdering(false);
                          }}
                          className={
                            "setting-bt-on-hide-web  tagpagelayout-svg"
                          }
                        >
                          <HomeIcon />
                        </button>
                      )
                    )}
                    {!spinner &&
                      selectedArr.length === 0 &&
                      pagesData.length > 0 && (
                        <button
                          onClick={() => {
                            setTableView(false);
                            setEnableGeneralSettings(false);
                            setEnablePageLayout(!enablePageLayout);
                            setEnablePageOrdering(false);
                          }}
                          className={
                            enablePageLayout
                              ? // ? " setting-bt-off-hide-web  tagpagelayout-svg setting-bt-off"
                                // : "setting-bt-on-hide-web  tagpagelayout-svg"

                                " setting-bt-off custom-layout-gap tagpagelayout-svg"
                              : "setting-bt-on custom-layout-gap tagpagelayout-svg"
                          }
                        >
                          {enablePageLayout ? (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                            >
                              <defs>
                                <clipPath id="clip-path3">
                                  <rect
                                    id="Rectangle_32448"
                                    data-name="Rectangle 32448"
                                    width="26"
                                    height="26"
                                    transform="translate(62 1)"
                                    fill="rgba(0,0,0,0)"
                                  ></rect>
                                </clipPath>
                              </defs>
                              <g
                                id="Mask_Group_34872"
                                data-name="Mask Group 34872"
                                transform="translate(-62 -1)"
                                clip-path="url(#clip-path3)"
                              >
                                <g
                                  id="Group_43107"
                                  data-name="Group 43107"
                                  transform="translate(63.887 2.887)"
                                >
                                  <g
                                    id="Rectangle_32447"
                                    data-name="Rectangle 32447"
                                    transform="translate(3.113 0.113)"
                                    fill="#7782a1"
                                    stroke="#7782a1"
                                    stroke-linejoin="round"
                                    stroke-width="1"
                                  >
                                    <rect
                                      width="16"
                                      height="22"
                                      rx="1.5"
                                      stroke="none"
                                    ></rect>
                                    <rect
                                      x="-0.5"
                                      y="-0.5"
                                      width="17"
                                      height="23"
                                      rx="2"
                                      fill="none"
                                    ></rect>
                                  </g>
                                  <path
                                    id="Union_3151"
                                    data-name="Union 3151"
                                    d="M15359,21365v-2h6v2Zm-4,0v-2h2v2Zm4-6v-2h6v2Zm-4,0v-2h2v2Zm4-6v-2h6v2Zm-4,0v-2h2v2Z"
                                    transform="translate(-15348.887 -21346.887)"
                                    fill="#fff"
                                  ></path>
                                </g>
                              </g>
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              width="26"
                              height="26"
                              viewBox="0 0 26 26"
                            >
                              <defs>
                                <clipPath id="clip-path4">
                                  <rect
                                    id="Rectangle_29591"
                                    data-name="Rectangle 29591"
                                    width="26"
                                    height="26"
                                    transform="translate(62 1)"
                                    fill="rgba(0,0,0,0)"
                                  ></rect>
                                </clipPath>
                              </defs>
                              <g
                                id="Mask_Group_25287"
                                data-name="Mask Group 25287"
                                transform="translate(-62 -1)"
                                clip-path="url(#clip-path4)"
                              >
                                <g
                                  id="Group_39235"
                                  data-name="Group 39235"
                                  transform="translate(63.887 2.887)"
                                >
                                  <rect
                                    id="Rectangle_29590"
                                    data-name="Rectangle 29590"
                                    width="16"
                                    height="22"
                                    rx="1.5"
                                    transform="translate(3.113 0.113)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-linejoin="round"
                                    stroke-width="1.5"
                                  ></rect>
                                  <path
                                    id="Path_119244"
                                    data-name="Path 119244"
                                    d="M11.113,5.113h6"
                                    transform="translate(-1)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  ></path>
                                  <path
                                    id="Path_119245"
                                    data-name="Path 119245"
                                    d="M11.113,5.113h6"
                                    transform="translate(-1 6)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  ></path>
                                  <path
                                    id="Path_119246"
                                    data-name="Path 119246"
                                    d="M11.113,5.113h6"
                                    transform="translate(-1 12)"
                                    fill="none"
                                    stroke="#7782a1"
                                    stroke-width="1.5"
                                  ></path>
                                  <g
                                    id="Group_43104"
                                    data-name="Group 43104"
                                    transform="translate(6.113 4.363)"
                                  >
                                    <path
                                      id="Path_119244-2"
                                      data-name="Path 119244"
                                      d="M11.113,5.113h2"
                                      transform="translate(-11.113 -4.363)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    ></path>
                                    <path
                                      id="Path_119245-2"
                                      data-name="Path 119245"
                                      d="M11.113,5.113h2"
                                      transform="translate(-11.113 1.637)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    ></path>
                                    <path
                                      id="Path_119246-2"
                                      data-name="Path 119246"
                                      d="M11.113,5.113h2"
                                      transform="translate(-11.113 7.637)"
                                      fill="none"
                                      stroke="#7782a1"
                                      stroke-width="1.5"
                                    ></path>
                                  </g>
                                </g>
                              </g>
                            </svg>
                          )}
                        </button>
                      )}

                    {!spinner &&
                      selectedArr.length === 0 &&
                      pagesData.length > 0 && (
                        // <button
                        //   onClick={() => {
                        //     setTableView(false);
                        //     setEnableGeneralSettings(!enableGeneralSettings);
                        //     setEnablePageLayout(false);
                        //     setEnablePageOrdering(false);
                        //   }}
                        //   className={
                        //     enableGeneralSettings
                        //       ? " setting-bt-off-hide-web  tagpagelayout-svg"
                        //       : "setting-bt-on-hide-web  tagpagelayout-svg"
                        //   }
                        // >
                        //   {enableGeneralSettings ? (
                        //     <svg
                        //       xmlns="http://www.w3.org/2000/svg"
                        //       width="26"
                        //       height="26"
                        //       viewBox="0 0 26 26"
                        //     >
                        //       <g
                        //         id="Group_38340"
                        //         data-name="Group 38340"
                        //         transform="translate(-20 -527)"
                        //       >
                        //         <rect
                        //           id="Rectangle_25572"
                        //           data-name="Rectangle 25572"
                        //           width="26"
                        //           height="26"
                        //           transform="translate(20 527)"
                        //           fill="none"
                        //         />
                        //         <path
                        //           className="  productsetting-notselect productSetting-select"
                        //           id="Path_99646"
                        //           data-name="Path 99646"
                        //           d="M11.513,22.5a10.829,10.829,0,0,0,1.266-.074.615.615,0,0,0,.542-.642,2.437,2.437,0,0,1,1.488-2.388,2.278,2.278,0,0,1,.887-.171,2.527,2.527,0,0,1,1.855.788.616.616,0,0,0,.837.067,10.968,10.968,0,0,0,1.739-1.76.615.615,0,0,0-.066-.829,2.432,2.432,0,0,1,1.592-4.231h.056a.73.73,0,0,0,.72-.542,11,11,0,0,0,0-2.5.592.592,0,0,0-.613-.54h-.022A2.543,2.543,0,0,1,19.4,8.19a2.437,2.437,0,0,1,.613-2.744.614.614,0,0,0,.066-.836A10.919,10.919,0,0,0,18.3,2.851a.606.606,0,0,0-.377-.131.615.615,0,0,0-.454.2,2.532,2.532,0,0,1-1.855.785,2.259,2.259,0,0,1-.868-.165A2.449,2.449,0,0,1,13.238,1.2a.614.614,0,0,0-.544-.63,11.023,11.023,0,0,0-2.474,0,.615.615,0,0,0-.539.643A2.443,2.443,0,0,1,8.195,3.6a2.318,2.318,0,0,1-.89.172,2.524,2.524,0,0,1-1.853-.788.615.615,0,0,0-.837-.069,10.968,10.968,0,0,0-1.739,1.76.615.615,0,0,0,.066.829A2.43,2.43,0,0,1,1.279,9.733a.718.718,0,0,0-.708.54,11.037,11.037,0,0,0,0,2.5.617.617,0,0,0,.6.542h.181A2.464,2.464,0,0,1,3.6,14.808a2.438,2.438,0,0,1-.615,2.743.614.614,0,0,0-.066.837A11,11,0,0,0,4.7,20.145a.616.616,0,0,0,.83-.069,2.535,2.535,0,0,1,1.853-.785,2.3,2.3,0,0,1,.87.165A2.443,2.443,0,0,1,9.76,21.8a.614.614,0,0,0,.545.628A11.109,11.109,0,0,0,11.513,22.5Z"
                        //           transform="translate(21.501 529.151)"
                        //           fill="#7782A1"
                        //           stroke="#7782A1"
                        //           stroke-linejoin="round"
                        //           stroke-width="1.5"
                        //         />
                        //         <path
                        //           id="Path_99647"
                        //           data-name="Path 99647"
                        //           d="M9.818,5.846a4,4,0,1,1-4,4A4,4,0,0,1,9.818,5.846Z"
                        //           transform="translate(23.181 530.804)"
                        //           fill="#ffff"
                        //           stroke="#7782A1"
                        //           stroke-linejoin="round"
                        //           stroke-width="1.5"
                        //         />
                        //       </g>
                        //     </svg>
                        //   ) : (
                        //     <svg
                        //       xmlns="http://www.w3.org/2000/svg"
                        //       width="23.5"
                        //       height="23.499"
                        //       viewBox="0 0 23.5 23.499"
                        //     >
                        //       <g
                        //         id="Group_38368"
                        //         data-name="Group 38368"
                        //         transform="translate(-1598.25 -88.901)"
                        //       >
                        //         <path
                        //           id="Path_99646"
                        //           data-name="Path 99646"
                        //           d="M11.513,22.5a10.829,10.829,0,0,0,1.266-.074.615.615,0,0,0,.542-.642,2.437,2.437,0,0,1,1.488-2.388,2.278,2.278,0,0,1,.887-.171,2.527,2.527,0,0,1,1.855.788.616.616,0,0,0,.837.067,10.968,10.968,0,0,0,1.739-1.76.615.615,0,0,0-.066-.829,2.432,2.432,0,0,1,1.592-4.231h.056a.73.73,0,0,0,.72-.542,11,11,0,0,0,0-2.5.592.592,0,0,0-.613-.54h-.022A2.543,2.543,0,0,1,19.4,8.19a2.437,2.437,0,0,1,.613-2.744.614.614,0,0,0,.066-.836A10.919,10.919,0,0,0,18.3,2.851a.606.606,0,0,0-.377-.131.615.615,0,0,0-.454.2,2.532,2.532,0,0,1-1.855.785,2.259,2.259,0,0,1-.868-.165A2.449,2.449,0,0,1,13.238,1.2a.614.614,0,0,0-.544-.63,11.023,11.023,0,0,0-2.474,0,.615.615,0,0,0-.539.643A2.443,2.443,0,0,1,8.195,3.6a2.318,2.318,0,0,1-.89.172,2.524,2.524,0,0,1-1.853-.788.615.615,0,0,0-.837-.069,10.968,10.968,0,0,0-1.739,1.76.615.615,0,0,0,.066.829A2.43,2.43,0,0,1,1.279,9.733a.718.718,0,0,0-.708.54,11.037,11.037,0,0,0,0,2.5.617.617,0,0,0,.6.542h.181A2.464,2.464,0,0,1,3.6,14.808a2.438,2.438,0,0,1-.615,2.743.614.614,0,0,0-.066.837A11,11,0,0,0,4.7,20.145a.616.616,0,0,0,.83-.069,2.535,2.535,0,0,1,1.853-.785,2.3,2.3,0,0,1,.87.165A2.443,2.443,0,0,1,9.76,21.8a.614.614,0,0,0,.545.628A11.109,11.109,0,0,0,11.513,22.5Z"
                        //           transform="translate(1598.501 89.151)"
                        //           fill="#ffff"
                        //           stroke="#7782A1"
                        //           stroke-linejoin="round"
                        //           stroke-width="1.5"
                        //         />
                        //         <path
                        //           id="Path_99647"
                        //           data-name="Path 99647"
                        //           d="M9.818,5.846a4,4,0,1,1-4,4A4,4,0,0,1,9.818,5.846Z"
                        //           transform="translate(1600.182 90.804)"
                        //           fill="#ffff"
                        //           stroke="#7782A1"
                        //           stroke-linejoin="round"
                        //           stroke-width="1.5"
                        //         />
                        //       </g>
                        //     </svg>
                        //   )}
                        // </button>
                          <div
                          onClick={() => {
                            setTableView(false);
                            setEnableGeneralSettings(!enableGeneralSettings);
                            setEnablePageLayout(false);
                            setEnablePageOrdering(false);
                          }}
                          className="cms-setting-btn"
                        >
                          <SettingIcon
                           
                            isActive={enableGeneralSettings}
                          />
                        </div>
                      )}
                  </div>
                  {/* {!spinner &&
                    selectedArr.length === 0 &&
                    pagesData.length > 0 && (
                      <button
                        onClick={() => {
                          setTableView(false);
                          setEnableGeneralSettings(false);
                          setEnablePageLayout(false);
                          setEnablePageOrdering(!enablePageOrdering);
                        }}
                        className={
                          enablePageOrdering
                            ? " setting-bt-off-hide-web setting-btn-web tagpagelayout-svg"
                            : "setting-bt-on-hide-web setting-btn-web tagpagelayout-svg"
                        }
                      >
                        {enablePageOrdering
                          ? <SortingIcon strokeWidth={"3"} />
                          :  <SortingIcon strokeWidth={"1.5"} />}
                      </button>
                    )} */}

                  {!enableGeneralSettings &&
                  !enablePageOrdering &&
                  !enablePageLayout ? (
                    <div className=" d-none d-xl-flex">
                      {selectedArr.length === 0 && (
                        <>
                          <Link
                            className="createpage d-none d-xl-flex"
                            to={{
                              pathname: "/app/page/create",
                              // search: "?sort=name",
                              // hash: "#the-hash",
                              // state: { data: pagesData },
                            }}
                            style={{
                              backgroundColor: localStorage.getItem(
                                "button_background_color"
                              ),
                              border:
                                "1px solid " +
                                localStorage.getItem("button_background_color"),
                              color: localStorage.getItem("button_text_color"),
                              "--custom_color5":
                                localStorage.getItem("agencyid") == 1
                                  ? "#134bec"
                                  : localStorage.getItem(
                                      "button_hover_bgcolor"
                                    ),
                              "--custom_color4":
                                localStorage.getItem("agencyid") == 1
                                  ? "white"
                                  : localStorage.getItem(
                                      "button_hover_txtcolor"
                                    ),
                              "--custom_color6":
                                localStorage.getItem("agencyid") == 1
                                  ? "#3064f9"
                                  : localStorage.getItem(
                                      "button_hover_bgcolor"
                                    ),
                            }}
                          >
                            <img
                              className="mr-2"
                              src={
                                require("../../assets/images/dashboard/cms/plus.png")
                                  .default
                              }
                            />{" "}
                            {/* <p

                  style={{color:localStorage.getItem('button_text_color'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor')}}

                    >Create</p> */}
                            <span
                              style={{
                                marginTop: "2px",
                              }}
                            >
                              Create
                            </span>
                          </Link>

                          <Link
                            className="createpage d-flex d-xl-none"
                            to={{
                              pathname: "/app/page/create",
                              // search: "?sort=name",
                              // hash: "#the-hash",
                              // state: { data: pagesData },
                            }}
                          >
                            <img
                              className="mr-2 d-block d-xl-none"
                              src={
                                require("../../assets/images/dashboard/cms/grey-plus.png")
                                  .default
                              }
                            />{" "}
                            <p>Create</p>
                          </Link>
                        </>
                      )}

                      {tableView && selectedArr.length > 0 && (
                        <button
                          onClick={(e) => {
                            document.body.classList.add("opec");
                            setOpenDrawer(true);
                          }}
                          className="createpage d-none d-xl-flex"
                          style={{
                            backgroundColor: localStorage.getItem(
                              "button_background_color"
                            ),
                            border:
                              "1px solid " +
                              localStorage.getItem("button_background_color"),
                            color: localStorage.getItem("button_text_color"),
                            "--custom_color5":
                              localStorage.getItem("agencyid") == 1
                                ? "#134bec"
                                : localStorage.getItem("button_hover_bgcolor"),
                            "--custom_color4":
                              localStorage.getItem("agencyid") == 1
                                ? "white"
                                : localStorage.getItem("button_hover_txtcolor"),
                            "--custom_color6":
                              localStorage.getItem("agencyid") == 1
                                ? "#3064f9"
                                : localStorage.getItem("button_hover_bgcolor"),
                          }}
                        >
                          <span>Update</span>
                        </button>
                      )}
                    </div>
                  ) : (
                    <Link
                      className="createpage d-none d-xl-flex"
                      onClick={(e) => {
                        savePageSettings();
                      }}
                      to={
                        {
                          // pathname: "/app/page/create",
                          // search: "?sort=name",
                          // hash: "#the-hash",
                          // state: { data: pagesData },
                        }
                      }
                      style={{
                        backgroundColor: localStorage.getItem(
                          "button_background_color"
                        ),
                        border:
                          "1px solid " +
                          localStorage.getItem("button_background_color"),
                        color: localStorage.getItem("button_text_color"),
                        "--custom_color5":
                          localStorage.getItem("agencyid") == 1
                            ? "#134bec"
                            : localStorage.getItem("button_hover_bgcolor"),
                        "--custom_color4":
                          localStorage.getItem("agencyid") == 1
                            ? "white"
                            : localStorage.getItem("button_hover_txtcolor"),
                        "--custom_color6":
                          localStorage.getItem("agencyid") == 1
                            ? "#3064f9"
                            : localStorage.getItem("button_hover_bgcolor"),
                      }}
                    >
                      {/* <p

                  style={{color:localStorage.getItem('button_text_color'),"--custom_color4": localStorage.getItem('agencyid') == 1 ? "white" : localStorage.getItem('button_hover_txtcolor')}}

                    >Create</p> */}
                      {savePageSettingLoading ? (
                        <div className="new-loader"></div>
                      ) : (
                        <span
                          style={{
                            marginTop: "2px",
                          }}
                        >
                          Save
                        </span>
                      )}
                    </Link>
                  )}
                </div>
              </div>
              {!tableView &&
                !enableGeneralSettings &&
                !enablePageLayout &&
                !enablePageOrdering && (
                  <div className="whitebg all-added-pages">
                    {pagesData.length > 0 && (
                      <div className="pages-list-title">
                        <h2>Page</h2>
                      </div>
                    )}
                    {spinner === true ? (
                      <div
                        className="cmsnewspinner"
                        style={{ minHeight: "80vh" }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xlink="http://www.w3.org/1999/xlink"
                          style={{
                            margin: "auto",
                            background: "rgb(241, 242, 243,0)",
                            display: "block",
                            shaperendering: "auto",
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                          width="200px"
                          height="200px"
                          viewBox="0 0 100 100"
                          preserveAspectRatio="xMidYMid"
                        >
                          <g
                            transform="translate(20 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#e6261f"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.375s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                          <g
                            transform="translate(40 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#f7d038"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.25s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                          <g
                            transform="translate(60 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#49da9a"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="-0.125s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                          <g
                            transform="translate(80 50)"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <circle
                              cx="0"
                              cy="0"
                              r="6"
                              fill="#4355db"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            >
                              <animateTransform
                                attributeName="transform"
                                type="scale"
                                begin="0s"
                                calcMode="spline"
                                keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                values="0;1;0"
                                keyTimes="0;0.5;1"
                                dur="1s"
                                repeatCount="indefinite"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              ></animateTransform>
                            </circle>
                          </g>
                        </svg>
                      </div>
                    ) : pagesData !== null && pagesData.length > 0 ? (
                      <div className="cms-pages-listing">
                        <BottomScrollListener
                          onBottom={
                            window.matchMedia("(min-width: 1200px)").matches
                              ? handleContainerOnBottom
                              : handleNotHitOnBottom
                          }
                          offset={100}
                        >
                          {(scrollRef) => (
                            <div ref={scrollRef} className="pageslistdetail">
                              {bottomSpinner && (
                                <div
                                  className="form-group"
                                  style={{ background: "none", margin: "auto" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xlink="http://www.w3.org/1999/xlink"
                                    style={{
                                      margin: "auto",
                                      background: "rgb(241, 242, 243,0)",
                                      display: "block",
                                      shaperendering: "auto",
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                    width="200px"
                                    height="100px"
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="xMidYMid"
                                  >
                                    <g
                                      transform="translate(20 50)"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    >
                                      <circle
                                        cx="0"
                                        cy="0"
                                        r="6"
                                        fill="#e6261f"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <animateTransform
                                          attributeName="transform"
                                          type="scale"
                                          begin="-0.375s"
                                          calcMode="spline"
                                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                          values="0;1;0"
                                          keyTimes="0;0.5;1"
                                          dur="1s"
                                          repeatCount="indefinite"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        ></animateTransform>
                                      </circle>
                                    </g>
                                    <g
                                      transform="translate(40 50)"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    >
                                      <circle
                                        cx="0"
                                        cy="0"
                                        r="6"
                                        fill="#f7d038"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <animateTransform
                                          attributeName="transform"
                                          type="scale"
                                          begin="-0.25s"
                                          calcMode="spline"
                                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                          values="0;1;0"
                                          keyTimes="0;0.5;1"
                                          dur="1s"
                                          repeatCount="indefinite"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        ></animateTransform>
                                      </circle>
                                    </g>
                                    <g
                                      transform="translate(60 50)"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    >
                                      <circle
                                        cx="0"
                                        cy="0"
                                        r="6"
                                        fill="#49da9a"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <animateTransform
                                          attributeName="transform"
                                          type="scale"
                                          begin="-0.125s"
                                          calcMode="spline"
                                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                          values="0;1;0"
                                          keyTimes="0;0.5;1"
                                          dur="1s"
                                          repeatCount="indefinite"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        ></animateTransform>
                                      </circle>
                                    </g>
                                    <g
                                      transform="translate(80 50)"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    >
                                      <circle
                                        cx="0"
                                        cy="0"
                                        r="6"
                                        fill="#4355db"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <animateTransform
                                          attributeName="transform"
                                          type="scale"
                                          begin="0s"
                                          calcMode="spline"
                                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                          values="0;1;0"
                                          keyTimes="0;0.5;1"
                                          dur="1s"
                                          repeatCount="indefinite"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        ></animateTransform>
                                      </circle>
                                    </g>
                                  </svg>
                                </div>
                              )}
                              {recurs(pagesData, "parent")}
                              {paginationSpinner &&
                                window.matchMedia("(min-width: 1200px)")
                                  .matches && (
                                  <div
                                    className={`form-group`}
                                    style={{
                                      background: "none",
                                      margin: "auto",
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xlink="http://www.w3.org/1999/xlink"
                                      style={{
                                        margin: "auto",
                                        background: "rgb(241, 242, 243,0)",
                                        display: "block",
                                        shaperendering: "auto",
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                      width="200px"
                                      height="100px"
                                      viewBox="0 0 100 100"
                                      preserveAspectRatio="xMidYMid"
                                    >
                                      <g
                                        transform="translate(20 50)"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <circle
                                          cx="0"
                                          cy="0"
                                          r="6"
                                          fill="#e6261f"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <animateTransform
                                            attributeName="transform"
                                            type="scale"
                                            begin="-0.375s"
                                            calcMode="spline"
                                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                            values="0;1;0"
                                            keyTimes="0;0.5;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          ></animateTransform>
                                        </circle>
                                      </g>
                                      <g
                                        transform="translate(40 50)"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <circle
                                          cx="0"
                                          cy="0"
                                          r="6"
                                          fill="#f7d038"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <animateTransform
                                            attributeName="transform"
                                            type="scale"
                                            begin="-0.25s"
                                            calcMode="spline"
                                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                            values="0;1;0"
                                            keyTimes="0;0.5;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          ></animateTransform>
                                        </circle>
                                      </g>
                                      <g
                                        transform="translate(60 50)"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <circle
                                          cx="0"
                                          cy="0"
                                          r="6"
                                          fill="#49da9a"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <animateTransform
                                            attributeName="transform"
                                            type="scale"
                                            begin="-0.125s"
                                            calcMode="spline"
                                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                            values="0;1;0"
                                            keyTimes="0;0.5;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          ></animateTransform>
                                        </circle>
                                      </g>
                                      <g
                                        transform="translate(80 50)"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <circle
                                          cx="0"
                                          cy="0"
                                          r="6"
                                          fill="#4355db"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <animateTransform
                                            attributeName="transform"
                                            type="scale"
                                            begin="0s"
                                            calcMode="spline"
                                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                            values="0;1;0"
                                            keyTimes="0;0.5;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          ></animateTransform>
                                        </circle>
                                      </g>
                                    </svg>
                                  </div>
                                )}
                            </div>
                          )}
                        </BottomScrollListener>
                        <div className="pages-preview-list">
                          <div className="pages-preview-list-box">
                            <div className="page-listing-head">
                              <h3>
                                {/* <img
                    src={
                      require("../../assets/images/dashboard/cms/hamburger.png")
                        .default
                    }
                  />  */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15.733"
                                  height="14.433"
                                  viewBox="0 0 15.733 14.433"
                                >
                                  <g
                                    id="Group_42221"
                                    data-name="Group 42221"
                                    transform="translate(0.707 1.364)"
                                  >
                                    <path
                                      id="Path_118871"
                                      data-name="Path 118871"
                                      d="M-377.533-3200.5l-6.863,6.863,6.863,6.863"
                                      transform="translate(384.396 3199.49)"
                                      fill="none"
                                      stroke="#1a1a1a"
                                      stroke-width="1"
                                    />
                                    <path
                                      id="Path_118872"
                                      data-name="Path 118872"
                                      d="M-654.5-3185.5h15.026"
                                      transform="translate(654.5 3191.336)"
                                      fill="none"
                                      stroke="#1a1a1a"
                                      stroke-width="1"
                                    />
                                  </g>
                                </svg>
                                Pages
                              </h3>

                              {state.pageOptions
                                .show_search_icon_on_pages_listing_page ==
                                1 && (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16.207"
                                  height="16.207"
                                  viewBox="0 0 16.207 16.207"
                                >
                                  <path
                                    id="Union_3096"
                                    data-name="Union 3096"
                                    d="M10.768,11.547a6.545,6.545,0,1,1,.779-.779L15,14.221,14.221,15Zm-9.677-5A5.455,5.455,0,1,0,6.546,1.091,5.461,5.461,0,0,0,1.091,6.546Z"
                                    transform="translate(0.5 0.5)"
                                    fill="#1a1a1a"
                                    stroke="rgba(0,0,0,0)"
                                    stroke-miterlimit="10"
                                    stroke-width="1"
                                  />
                                </svg>
                              )}
                            </div>

                            {/* {pageLayout == "layout-1" && (
                      <div className="pages-setting-preview-layout-one">
                          <div
                            style={{ background: "#fff" }}
                            className="pages-preview-card"
                          >
                            <p
                              style={{ color: "black" }}
                              className="card-type-menu"
                            >
                              {previewRecurs(pagesData, "parent")}
                            </p>
                            {item?.children.length >= 1 && (
                                  <img
                                    className="img-fluid"
                                    src={
                                      require("../../assets/images/dashboard/back-arrow.svg")
                                        .default
                                    }
                                  />
                                )}
                          </div>
                      </div>
                    )}
                    {pageLayout == "layout-0" && (
                      <div
                        style={{
                          background: "#F9F9F9",
                        }}
                        className="pages-setting-preview-layout-two"
                      >
                       
                          <div className="pages-preview-type-list">
                            <p
                              style={{ color: "black" }}
                              className="list-type-menu"
                            >
                              {previewRecurs(pagesData, "parent")}
                              </p>
                            {item?.children.length >= 1 && (
                                  <img
                                    className="img-fluid"
                                    src={
                                      require("../../assets/images/dashboard/back-arrow.svg")
                                        .default
                                    }
                                  />
                                )}
                          </div>
                      </div>
                    )} */}

                            <div className="page-listing-body">
                              {previewRecurs(pagesData, "parent")}
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="nopagecreated ">
                        <div>
                          <img
                            src={
                              require("../../assets/images/dashboard/no-page-create.png")
                                .default
                            }
                          />
                          <p>Nothing here at the moment. Come back later?</p>
                        </div>
                      </div>
                    )}
                  </div>
                )}

              {tableView && (
                <div className="bulkUpdatewhitebg">
                  <BottomScrollListener
                    onBottom={
                      window.matchMedia("(min-width: 1200px)").matches
                        ? handleContainerOnBottom
                        : handleNotHitOnBottom
                    }
                  >
                    {(scrollRef) => (
                      <div
                        className="bulkUpdate-page position-relative"
                        ref={scrollRef}
                      >
                        {spinner ? (
                          <div
                            className="newspinner"
                            style={{ height: "100%", borderRadius: "6px" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              style={{
                                margin: "auto",
                                background: "rgb(241, 242, 243,0)",
                                display: "block",
                                shaperendering: "auto",
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                              width="200px"
                              height="200px"
                              viewBox="0 0 100 100"
                              preserveAspectRatio="xMidYMid"
                            >
                              <g
                                transform="translate(20 50)"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#e6261f"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="-0.375s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                              <g
                                transform="translate(40 50)"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#f7d038"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="-0.25s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                              <g
                                transform="translate(60 50)"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#49da9a"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="-0.125s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                              <g
                                transform="translate(80 50)"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#4355db"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="0s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                            </svg>
                          </div>
                        ) : (
                          <>
                            <div className="bulkUpdate-page-head">
                              <div className="bulk-radio-button">
                                <input
                                  type="radio"
                                  checked={
                                    selectedArr.length ===
                                    getTotalLength(pagesData)
                                  }
                                />
                                <span
                                  onClick={() => handleSelected("all")}
                                  className="bulk-update-select-checkmark"
                                ></span>
                              </div>
                              <p className="bulkupdate-head-ptag">
                                <div className="bulkhead-first-div">
                                  <SortIcon
                                    heading={"Page"}
                                    sortFields={sortFields}
                                    setSortFields={setSortFields}
                                    tableKey={"title"}
                                    handleSortTable={handleSortTable}
                                  />
                                </div>
                                <div className="bulkhead-second-div">
                                  <SortIcon
                                    heading={"Author"}
                                    sortFields={sortFields}
                                    setSortFields={setSortFields}
                                    tableKey={"author_info"}
                                    handleSortTable={handleSortTable}
                                  />
                                </div>
                                <div className="bulkhead-third-div">
                                  <SortIcon
                                    heading={"Created"}
                                    sortFields={sortFields}
                                    setSortFields={setSortFields}
                                    tableKey={"created_at"}
                                    handleSortTable={handleSortTable}
                                  />
                                </div>
                                <div className="bulkhead-fourth-div">
                                  <SortIcon
                                    heading={"Updated"}
                                    sortFields={sortFields}
                                    setSortFields={setSortFields}
                                    tableKey={"updated_at"}
                                    handleSortTable={handleSortTable}
                                  />
                                </div>
                                <div className="bulkhead-fifth-div">
                                  <SortIcon
                                    heading={"Status"}
                                    sortFields={sortFields}
                                    setSortFields={setSortFields}
                                    tableKey={"status"}
                                    handleSortTable={handleSortTable}
                                  />
                                </div>
                              </p>

                              <div className="bulkhead-sixth-div"></div>
                            </div>

                            <div className="bulkUpdate-page-body">
                              {bottomSpinner && (
                                <div
                                  className="form-group"
                                  style={{ background: "none", margin: "auto" }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xlink="http://www.w3.org/1999/xlink"
                                    style={{
                                      margin: "auto",
                                      background: "rgb(241, 242, 243,0)",
                                      display: "block",
                                      shaperendering: "auto",
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                    width="200px"
                                    height="100px"
                                    viewBox="0 0 100 100"
                                    preserveAspectRatio="xMidYMid"
                                  >
                                    <g
                                      transform="translate(20 50)"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    >
                                      <circle
                                        cx="0"
                                        cy="0"
                                        r="6"
                                        fill="#e6261f"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <animateTransform
                                          attributeName="transform"
                                          type="scale"
                                          begin="-0.375s"
                                          calcMode="spline"
                                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                          values="0;1;0"
                                          keyTimes="0;0.5;1"
                                          dur="1s"
                                          repeatCount="indefinite"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        ></animateTransform>
                                      </circle>
                                    </g>
                                    <g
                                      transform="translate(40 50)"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    >
                                      <circle
                                        cx="0"
                                        cy="0"
                                        r="6"
                                        fill="#f7d038"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <animateTransform
                                          attributeName="transform"
                                          type="scale"
                                          begin="-0.25s"
                                          calcMode="spline"
                                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                          values="0;1;0"
                                          keyTimes="0;0.5;1"
                                          dur="1s"
                                          repeatCount="indefinite"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        ></animateTransform>
                                      </circle>
                                    </g>
                                    <g
                                      transform="translate(60 50)"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    >
                                      <circle
                                        cx="0"
                                        cy="0"
                                        r="6"
                                        fill="#49da9a"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <animateTransform
                                          attributeName="transform"
                                          type="scale"
                                          begin="-0.125s"
                                          calcMode="spline"
                                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                          values="0;1;0"
                                          keyTimes="0;0.5;1"
                                          dur="1s"
                                          repeatCount="indefinite"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        ></animateTransform>
                                      </circle>
                                    </g>
                                    <g
                                      transform="translate(80 50)"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    >
                                      <circle
                                        cx="0"
                                        cy="0"
                                        r="6"
                                        fill="#4355db"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <animateTransform
                                          attributeName="transform"
                                          type="scale"
                                          begin="0s"
                                          calcMode="spline"
                                          keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                          values="0;1;0"
                                          keyTimes="0;0.5;1"
                                          dur="1s"
                                          repeatCount="indefinite"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        ></animateTransform>
                                      </circle>
                                    </g>
                                  </svg>
                                </div>
                              )}
                              {recurs(pagesData, "parent")}

                              {paginationSpinner &&
                                window.matchMedia("(min-width: 1200px)")
                                  .matches && (
                                  <div
                                    className={`form-group`}
                                    style={{
                                      background: "none",
                                      margin: "auto",
                                    }}
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      xlink="http://www.w3.org/1999/xlink"
                                      style={{
                                        margin: "auto",
                                        background: "rgb(241, 242, 243,0)",
                                        display: "block",
                                        shaperendering: "auto",
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                      width="200px"
                                      height="100px"
                                      viewBox="0 0 100 100"
                                      preserveAspectRatio="xMidYMid"
                                    >
                                      <g
                                        transform="translate(20 50)"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <circle
                                          cx="0"
                                          cy="0"
                                          r="6"
                                          fill="#e6261f"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <animateTransform
                                            attributeName="transform"
                                            type="scale"
                                            begin="-0.375s"
                                            calcMode="spline"
                                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                            values="0;1;0"
                                            keyTimes="0;0.5;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          ></animateTransform>
                                        </circle>
                                      </g>
                                      <g
                                        transform="translate(40 50)"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <circle
                                          cx="0"
                                          cy="0"
                                          r="6"
                                          fill="#f7d038"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <animateTransform
                                            attributeName="transform"
                                            type="scale"
                                            begin="-0.25s"
                                            calcMode="spline"
                                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                            values="0;1;0"
                                            keyTimes="0;0.5;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          ></animateTransform>
                                        </circle>
                                      </g>
                                      <g
                                        transform="translate(60 50)"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <circle
                                          cx="0"
                                          cy="0"
                                          r="6"
                                          fill="#49da9a"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <animateTransform
                                            attributeName="transform"
                                            type="scale"
                                            begin="-0.125s"
                                            calcMode="spline"
                                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                            values="0;1;0"
                                            keyTimes="0;0.5;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          ></animateTransform>
                                        </circle>
                                      </g>
                                      <g
                                        transform="translate(80 50)"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      >
                                        <circle
                                          cx="0"
                                          cy="0"
                                          r="6"
                                          fill="#4355db"
                                          style={{
                                            animationplaystate: "running",
                                            animationdelay: "0s",
                                          }}
                                        >
                                          <animateTransform
                                            attributeName="transform"
                                            type="scale"
                                            begin="0s"
                                            calcMode="spline"
                                            keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                            values="0;1;0"
                                            keyTimes="0;0.5;1"
                                            dur="1s"
                                            repeatCount="indefinite"
                                            style={{
                                              animationplaystate: "running",
                                              animationdelay: "0s",
                                            }}
                                          ></animateTransform>
                                        </circle>
                                      </g>
                                    </svg>
                                  </div>
                                )}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </BottomScrollListener>
                </div>
              )}

              {/* Bulk update list end*/}

              {/* Pages Drag Drop Starts */}
              {/* {enablePageLayout && (
                <div className="pages-layout">
                  <div className="page-layout-header">
                    <h2 className="page-layout-header-heading">Layout</h2>
                    <p className="page-layout-header-subheading">
                      Choose a template to style the appearance of pages in your
                      app.
                    </p>
                  </div>

                  <FormGroup tag="fieldset" className="select-page-type">
                    <FormGroup>
                      <Label className="pagetype-ratio">
                        <Input
                          checked={pageLayout == "layout-0"}
                          onChange={() => setPageLayout("layout-0")}
                          type="radio"
                          name="addblockradioCat1"
                        />

                        <div className="page-layout">
                          <img
                            className="img-fluid"
                            src={
                              require("../../assets/images/dashboard/page-layout-one.png")
                                .default
                            }
                          />
                        </div>
                      </Label>
                    </FormGroup>

                    <FormGroup>
                      <Label className="pagetype-ratio">
                        <Input
                          type="radio"
                          name="addblockradioCat2"
                          checked={pageLayout == "layout-1"}
                          onChange={() => setPageLayout("layout-1")}
                        />
                        <div className="page-layout">
                          <img
                            className="img-fluid"
                            src={
                              require("../../assets/images/dashboard/page-layout-two.png")
                                .default
                            }
                          />
                        </div>
                      </Label>
                    </FormGroup>
                  </FormGroup>
                </div>
              )} */}
              {/* Pages Drag Drop Ends */}

              {/* Pages Layout Selection Starts */}
              {enablePageOrdering && (
                <div className="pages-builder-section">
                  <div className="page-builder">
                    <div className="page-builder-left">
                      <div className="page-builder-header">
                        <h2 className="page-builder-header-heading">
                          Page order
                        </h2>
                        <p className="page-builder-header-subheading">
                          Drag and drop to arrange them in the preferred order.
                        </p>
                      </div>
                      <MenuSortingList
                        menuListingData={pageState}
                        menuListingArrName={"pageOrderingData"}
                        setMenuState={setPageState}
                        menuType={""}
                      />
                    </div>
                    <div className="page-builder-right">
                      <div className="pages-setting-preview">
                        <div className="pages-setting-preview-header">
                          <div className="pages-setting-header-left">
                            <img
                              className="img-fluid"
                              src={
                                require("../../assets/images/dashboard/post-back.svg")
                                  .default
                              }
                            />
                            <h3 className="pages-setting-header-heading">
                              Pages
                            </h3>
                          </div>
                          <div className="pages-setting-header-right">
                            <img
                              className="img-fluid"
                              src={
                                require("../../assets/images/dashboard/search-icon.svg")
                                  .default
                              }
                            />
                          </div>
                        </div>

                        {pageLayout == "layout-1" &&
                          pageState.map((item, idx) => (
                            <div className="pages-setting-preview-layout-one">
                              <div className="pages-preview-card">
                                <p className="card-type-menu">
                                  {" "}
                                  {item?.item_name}
                                </p>
                                {item?.children.length >= 1 && (
                                  <img
                                    className="img-fluid"
                                    src={
                                      require("../../assets/images/dashboard/back-arrow.svg")
                                        .default
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                        {pageLayout == "layout-0" &&
                          pageState.map((item, idx) => (
                            <div
                              key={idx}
                              className="pages-setting-preview-layout-two"
                            >
                              <div className="pages-preview-type-list">
                                <p className="list-type-menu">
                                  {item?.item_name}
                                </p>
                                {item?.children.length >= 1 && (
                                  <img
                                    className="img-fluid"
                                    src={
                                      require("../../assets/images/dashboard/back-arrow.svg")
                                        .default
                                    }
                                  />
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Pages Layout Selection Ends */}

              {/* Pages Settings Starts */}
              {/* {enableGeneralSettings && (
                <div className="pages-settings-section">
                  <div className="pages-settings">
                    <div className="pages-settings-left">
                      <div className="page-setting-header">
                        <h2 className="page-setting-header-heading">
                          Settings
                        </h2>
                        <p className="page-setting-header-subheading">
                          Customize the appearance of your app page.
                        </p>
                      </div>

                      <div className="page-setting-body">
                        <h4 className="page-setting-body-heading">Page list</h4>

                        <div className="page-setting-toggle page-setting-top-30">
                          <h6 className="page-setting-toggle-name">
                            Show search icon
                          </h6>
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked={showSearch}
                              onChange={(e) => {
                                setShowSearch(e.target.checked);
                                setActiveSlide(0);
                              }}
                            />
                            <div class="slider round"></div>
                          </label>
                        </div>

                        <div className="row page-setting-top-30">
                          <div className="col-md-6">
                            <h6 className="page-setting-toggle-name">
                              Background color
                            </h6>
                            <div
                              onClick={() => {
                                setActiveSlide(0);
                              }}
                              className="colorpicker-input page-setting-top-10"
                            >
                              <Reactcolorpicker
                                colorname={pageHeaderBackgroundColor}
                                onChange={(e) => {
                                  setPageHeaderBackgroundColor(e);
                                }}
                                classselection={"fixback new-overlay-mobile"}
                                valuecontrol={false}
                              />

                              <img
                                className="colorpicker-input-cross"
                                src={
                                  require("../../assets/images/dashboard/colorcross-icon.svg")
                                    .default
                                }
                              />
                            </div>
                          </div>

                          <div className="col-md-6">
                            <h6 className="page-setting-toggle-name">
                              Text color
                            </h6>
                            <div
                              onClick={() => {
                                setActiveSlide(0);
                              }}
                              className="colorpicker-input page-setting-top-10"
                            >
                              <Reactcolorpicker
                                colorname={pageTextColor}
                                onChange={(e) => {
                                  setPageTextColor(e);
                                }}
                                classselection={"fixback new-overlay-mobile"}
                                valuecontrol={true}
                              />

                              <img
                                className="colorpicker-input-cross"
                                src={
                                  require("../../assets/images/dashboard/colorcross-icon.svg")
                                    .default
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <div className="row page-setting-top-20">
                          <div className="col-md-6">
                            <h6 className="page-setting-toggle-name">
                              Tile background color
                            </h6>
                            <div
                              onClick={() => {}}
                              className="colorpicker-input page-setting-top-10"
                            >
                              <Reactcolorpicker
                                colorname={tileBackgroundColor}
                                onChange={(e) => {
                                  setActiveSlide(0);
                                  setTileBackgroundColor(e);
                                }}
                                classselection={"fixback new-overlay-mobile"}
                                valuecontrol={true}
                              />

                              <img
                                className="colorpicker-input-cross"
                                src={
                                  require("../../assets/images/dashboard/colorcross-icon.svg")
                                    .default
                                }
                              />
                            </div>
                          </div>
                        </div>

                        <hr className="page-setting-seprator page-setting-top-30" />

                        <h4 className="page-setting-body-heading page-setting-top-30">
                          Page details
                        </h4>

                        <div className="page-setting-toggle page-setting-top-30">
                          <h6 className="page-setting-toggle-name">Share</h6>
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked={showShare}
                              onChange={(e) => {
                                setShowShare(e.target.checked);
                                setActiveSlide(1);
                              }}
                            />
                            <div class="slider round"></div>
                          </label>
                        </div>

                        <div className="page-setting-toggle page-setting-top-30">
                          <h6 className="page-setting-toggle-name">
                            Render as web view
                          </h6>
                          <label class="switch">
                            <input type="checkbox" />
                            <div class="slider round"></div>
                          </label>
                        </div>

                        <div className="page-setting-toggle page-setting-top-30">
                          <h6 className="page-setting-toggle-name">
                            Show media in full screen
                          </h6>
                          <label class="switch">
                            <input type="checkbox" />
                            <div class="slider round"></div>
                          </label>
                        </div>

                        <div className="page-setting-toggle page-setting-top-30">
                          <h6 className="page-setting-toggle-name">
                            Show media as thumbnails
                          </h6>
                          <label class="switch">
                            <input type="checkbox" />
                            <div class="slider round"></div>
                          </label>
                        </div>

                        <h6 className="page-setting-toggle-name page-setting-top-30">
                          Section margins (pixels)
                        </h6>

                        <div className="set-page-margin">
                          <div class="page-section-margin-first page-setting-top-10">
                            <div>
                              <input
                                class="page-section-margin-first-input"
                                type="number"
                                value={sectionMargins.top}
                                min={"0"}
                                max={"30"}
                                minLength={0}
                                maxLength={30}
                                onChange={(e) => handleSectionMargin(e, "top")}
                              />
                              <h4 className="page-section-margin-value">Top</h4>
                            </div>
                            <div>
                              <input
                                class="page-section-margin-first-input"
                                type="number"
                                value={sectionMargins.bottom}
                                min={"0"}
                                max={"30"}
                                minLength={0}
                                maxLength={30}
                                onChange={(e) =>
                                  handleSectionMargin(e, "bottom")
                                }
                              />
                              <h4 className="page-section-margin-value">
                                Bottom
                              </h4>
                            </div>
                          </div>

                          <div className="page-section-margin-second page-setting-top-10">
                            <div>
                              <input
                                className="page-section-margin-second-input"
                                type="number"
                                value={sectionMargins.left}
                                min={"0"}
                                max={"30"}
                                minLength={0}
                                maxLength={30}
                                onChange={(e) => handleSectionMargin(e, "left")}
                              />
                              <h4 className="page-section-margin-value">
                                Left
                              </h4>
                            </div>
                            <div style={{ marginRight: "0px" }}>
                              <input
                                className="page-section-margin-second-input"
                                type="number"
                                value={sectionMargins.right}
                                min={"0"}
                                max={"30"}
                                minLength={0}
                                maxLength={30}
                                onChange={(e) =>
                                  handleSectionMargin(e, "right")
                                }
                              />
                              <h4 className="page-section-margin-value">
                                Right
                              </h4>
                            </div>
                          </div>
                        </div>

                        <div className="pages-image-shape page-setting-top-30">
                          <h6 className="page-setting-toggle-name">
                            Image ratio
                          </h6>
                          <div className="post-box-size">
                            <p onClick={() => handleRatioChange("16:9")}>
                              <svg
                                className={
                                  pageImgRatio == "16:9"
                                    ? "post-shapeselect"
                                    : "post-shapenotselect"
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="14"
                                viewBox="0 0 24 14"
                              >
                                <g
                                  id="Path_118621"
                                  data-name="Path 118621"
                                  fill="#fff"
                                >
                                  <path
                                    d="M 23 13 L 19.25 13 L 1 13 L 1 1 L 23 1 L 23 13 Z"
                                    stroke="none"
                                  />
                                  <path
                                    className={
                                      pageImgRatio == "16:9"
                                        ? "post-shapeselect"
                                        : "post-shapenotselect"
                                    }
                                    d="M 2 2 L 2 12 L 22 12 L 22 2 L 2 2 M 0 0 L 24 0 L 24 14 L 0 14 L 0 0 Z"
                                    stroke="none"
                                    fill=""
                                  />
                                </g>
                              </svg>
                              <span>16:9</span>
                            </p>

                            <p onClick={() => handleRatioChange("4:3")}>
                              <svg
                                className={
                                  pageImgRatio == "4:3"
                                    ? "post-shapeselect"
                                    : "post-shapenotselect"
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="15"
                                viewBox="0 0 20 15"
                              >
                                <g
                                  id="Rectangle_18739"
                                  data-name="Rectangle 18739"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                >
                                  <rect width="20" height="15" stroke="none" />
                                  <rect
                                    x="1"
                                    y="1"
                                    width="18"
                                    height="13"
                                    fill="none"
                                  />
                                </g>
                              </svg>
                              <span>4:3</span>
                            </p>
                            <p onClick={() => handleRatioChange("1:1")}>
                              <svg
                                className={
                                  pageImgRatio == "1:1"
                                    ? "post-shapeselect"
                                    : "post-shapenotselect"
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                              >
                                <g
                                  id="Rectangle_18739"
                                  data-name="Rectangle 18739"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                >
                                  <rect width="18" height="18" stroke="none" />
                                  <rect
                                    x="1"
                                    y="1"
                                    width="16"
                                    height="16"
                                    fill="none"
                                  />
                                </g>
                              </svg>
                              <span className="product-ratio-select">1:1</span>
                            </p>
                            <p onClick={() => handleRatioChange("3:4")}>
                              <svg
                                className={
                                  pageImgRatio == "3:4"
                                    ? "post-shapeselect"
                                    : "post-shapenotselect"
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="20"
                                viewBox="0 0 15 20"
                              >
                                <g
                                  id="Rectangle_18739"
                                  data-name="Rectangle 18739"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                >
                                  <rect width="15" height="20" stroke="none" />
                                  <rect
                                    x="1"
                                    y="1"
                                    width="13"
                                    height="18"
                                    fill="none"
                                  />
                                </g>
                              </svg>
                              <span>3:4</span>
                            </p>
                            <p onClick={() => handleRatioChange("9:16")}>
                              <svg
                                className={
                                  pageImgRatio == "9:16"
                                    ? "post-shapeselect"
                                    : "post-shapenotselect"
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="24"
                                viewBox="0 0 14 24"
                              >
                                <g
                                  id="Rectangle_31455"
                                  data-name="Rectangle 31455"
                                  transform="translate(14) rotate(90)"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                >
                                  <rect width="24" height="14" stroke="none" />
                                  <rect
                                    x="1"
                                    y="1"
                                    width="22"
                                    height="12"
                                    fill="none"
                                  />
                                </g>
                              </svg>
                              <span>9:16</span>
                            </p>
                          </div>
                        </div>

                        <div className="pages-image-shape page-setting-top-30">
                          <h6 className="page-setting-toggle-name">
                            Image shape
                          </h6>
                          <div className="shape-of-post">
                            <svg
                              onClick={() => setImageShape("sharp")}
                              className={
                                imageShape == "sharp"
                                  ? "post-shapeselect"
                                  : "post-shapenotselect"
                              }
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                            >
                              <g
                                id="Rectangle_18739"
                                data-name="Rectangle 18739"
                                fill="#fff"
                                stroke=""
                                stroke-width="2"
                              >
                                <rect width="18" height="18" stroke="none" />
                                <rect
                                  x="1"
                                  y="1"
                                  width="16"
                                  height="16"
                                  fill="none"
                                />
                              </g>
                            </svg>
                            <svg
                              onClick={() => setImageShape("rounded")}
                              className={
                                imageShape == "rounded"
                                  ? "post-shapeselect"
                                  : "post-shapenotselect"
                              }
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                            >
                              <g
                                id="Rectangle_18739"
                                data-name="Rectangle 18739"
                                fill="#fff"
                                stroke=""
                                stroke-width="2"
                              >
                                <rect
                                  width="18"
                                  height="18"
                                  rx="5"
                                  stroke="none"
                                />
                                <rect
                                  x="1"
                                  y="1"
                                  width="16"
                                  height="16"
                                  rx="4"
                                  fill="none"
                                />
                              </g>
                            </svg>
                          </div>
                        </div>

                        <div className="page-setting-toggle page-setting-top-30">
                          <h6 className="page-setting-toggle-name">
                            Center crop images
                          </h6>
                          <label class="switch" for="imgCenterForListing">
                            <input
                              type="checkbox"
                              id="imgCenterForListing"
                              checked={imgCenterForListing}
                              onChange={(e) => handleImgCenterForListing(e)}
                            />
                            <div class="slider round"></div>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="pages-settings-right">
                      <div className="login-mob-previewtop">
                        <div
                          onClick={() => setActiveSlide(0)}
                          className={
                            activeSlide == 0
                              ? "login-mob-previewtopback login-mob-previewtopbackopacity"
                              : "login-mob-previewtopback"
                          }
                        >
                          <img src={goback} />
                        </div>
                        <div
                          onClick={() => setActiveSlide(1)}
                          className={
                            activeSlide == 1
                              ? "login-mob-previewtopnext mob-previewtopnextdiv login-mob-previewtopbackopacity"
                              : "login-mob-previewtopnext mob-previewtopnextdiv"
                          }
                        >
                          <img src={goforward} />
                        </div>
                      </div>

                      <div className="page-settings-carousel">
                        <Carousel
                          selectedItem={activeSlide}
                          showArrows={false}
                          swipeable={true}
                          showThumbs={false}
                          showIndicators={false}
                          showStatus={false}
                        >
                          <div
                            style={{ background: pageHeaderBackgroundColor }}
                            className="pages-setting-preview"
                          >
                            <div className="pages-setting-preview-header">
                              <div className="pages-setting-header-left">
                                <img
                                  className="img-fluid"
                                  src={
                                    require("../../assets/images/dashboard/post-back.svg")
                                      .default
                                  }
                                />
                                <h3 className="pages-setting-header-heading">
                                  Pages
                                </h3>
                              </div>
                              <div className="pages-setting-header-right">
                                {showSearch && (
                                  <img
                                    className="img-fluid"
                                    src={
                                      require("../../assets/images/dashboard/search-icon.svg")
                                        .default
                                    }
                                  />
                                )}
                              </div>
                            </div>
                            {pageLayout == "layout-1" && (
                              <div className="pages-setting-preview-layout-one">
                                {[
                                  "Home",
                                  "Destinations",
                                  "About us",
                                  "Packages",
                                  "Contact us",
                                ].map((item, index) => (
                                  <div
                                    style={{ background: tileBackgroundColor }}
                                    className="pages-preview-card"
                                    key={index}
                                  >
                                    <p
                                      style={{ color: pageTextColor }}
                                      className="card-type-menu"
                                    >
                                      {item}
                                    </p>
                                    <img
                                      className="img-fluid"
                                      src={
                                        require("../../assets/images/dashboard/back-arrow.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                            {pageLayout == "layout-0" && (
                              <div
                                style={{
                                  background: pageHeaderBackgroundColor,
                                }}
                                className="pages-setting-preview-layout-two"
                              >
                                {[
                                  "Home",
                                  "Destinations",
                                  "About us",
                                  "Packages",
                                  "Contact us",
                                ].map((item, index) => (
                                  <div className="pages-preview-type-list">
                                    <p
                                      style={{ color: pageTextColor }}
                                      className="list-type-menu"
                                    >
                                      {item}
                                    </p>
                                    <img
                                      className="img-fluid"
                                      src={
                                        require("../../assets/images/dashboard/back-arrow.svg")
                                          .default
                                      }
                                    />
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>

                          <div className="pages-detail-preview">
                            <div className="pages-setting-preview-header">
                              <div className="pages-setting-header-left">
                                <img
                                  className="img-fluid"
                                  src={
                                    require("../../assets/images/dashboard/post-back.svg")
                                      .default
                                  }
                                />
                                <h3 className="pages-setting-header-heading">
                                  Page details
                                </h3>
                              </div>
                              <div className="pages-setting-header-right">
                                {showShare && (
                                  <img
                                    className="img-fluid"
                                    src={
                                      require("../../assets/images/dashboard/share-icon.svg")
                                        .default
                                    }
                                  />
                                )}
                              </div>
                            </div>

                            <div
                              style={{
                                margin: `0px ${
                                  sectionMargins.right + "px"
                                } 0px ${sectionMargins.left + "px"}`,
                                padding: `${sectionMargins.top + "px"} 0px ${
                                  sectionMargins.bottom + "px"
                                } 0px`,
                              }}
                              className="pages-detail-preview-body"
                            >
                              <div>
                                <div
                                  style={{
                                    margin: "0px 15px",
                                    padding: "15px 0px 0px 0px",
                                  }}
                                >
                                  <h3 className="pages-detail-preview-heading">
                                    Welcome to London
                                  </h3>
                                </div>

                                <div
                                  style={{
                                    margin: "0px 15px",
                                    padding: "15px 0px 0px 0px",
                                  }}
                                >
                                  <p className="pages-detail-preview-text">
                                    One of the world's most visited cities,
                                    London has something for everyone: from
                                    history and culture to fine food and good
                                    times.
                                  </p>
                                </div>
                              </div>

                              <div
                                style={{
                                  margin: `0px ${
                                    sectionMargins.right + "px"
                                  } 0px ${sectionMargins.left + "px"}`,
                                  padding: `${sectionMargins.top + "px"} 0px ${
                                    sectionMargins.bottom + "px"
                                  } 0px`,
                                }}
                              >
                                <div
                                  className="pages-setting-preview-bssanner"
                                  style={{
                                    backgroundImage:
                                      "url(" + imagebackground3 + ")",
                                    backgroundSize: imgCenterForListing
                                      ? "cover"
                                      : "contain",
                                    borderRadius:
                                      imageShape == "rounded" ? "6px" : "0px",
                                    // height: calculateHeight(pageImgRatio, 84),
                                    height: "264px",
                                  }}
                                ></div>
                              </div>

                              <div
                                style={{
                                  margin: `0px ${
                                    sectionMargins.right + "px"
                                  } 0px ${sectionMargins.left + "px"}`,
                                  padding: `${sectionMargins.top + "px"} 0px ${
                                    sectionMargins.bottom + "px"
                                  } 0px`,
                                }}
                              >
                                <p className="pages-detail-preview-text">
                                  Immersed in history, London's rich seams of
                                  eye-opening antiquity are everywhere. The
                                  city's buildings are striking milestones in a
                                  unique and beguiling biography, and a great
                                  many of them – the Tower of London,
                                  Westminster Abbey, Big Ben – are instantly
                                  recognisable landmarks.
                                </p>
                              </div>

                              <div
                                style={{
                                  margin: `0px ${
                                    sectionMargins.right + "px"
                                  } 0px ${sectionMargins.left + "px"}`,
                                  padding: `${sectionMargins.top + "px"} 0px ${
                                    sectionMargins.bottom + "px"
                                  } 0px`,
                                }}
                              >
                                <div className="pages-setting-preview-gallery">
                                  <div
                                    className="pages-setting-preview-gallery-img"
                                    style={{
                                      backgroundImage: "url(" + gallery1 + ")",
                                      height: calculateHeight(pageImgRatio, 84),
                                      backgroundSize: imgCenterForListing
                                        ? "cover"
                                        : "contain",

                                      borderRadius:
                                        imageShape == "rounded" ? "6px" : "0px",
                                    }}
                                  ></div>
                                  <div
                                    className="pages-setting-preview-gallery-img"
                                    style={{
                                      backgroundImage: "url(" + gallery2 + ")",
                                      height: calculateHeight(pageImgRatio, 84),
                                      backgroundSize: imgCenterForListing
                                        ? "cover"
                                        : "contain",

                                      borderRadius:
                                        imageShape == "rounded" ? "6px" : "0px",
                                    }}
                                  ></div>
                                  <div
                                    className="pages-setting-preview-gallery-img"
                                    style={{
                                      backgroundImage: "url(" + gallery3 + ")",
                                      height: calculateHeight(pageImgRatio, 84),
                                      backgroundSize: imgCenterForListing
                                        ? "cover"
                                        : "contain",

                                      borderRadius:
                                        imageShape == "rounded" ? "6px" : "0px",
                                    }}
                                  ></div>
                                  <div
                                    className="pages-setting-preview-gallery-img"
                                    style={{
                                      backgroundImage: "url(" + gallery4 + ")",
                                      height: calculateHeight(pageImgRatio, 84),
                                      backgroundSize: imgCenterForListing
                                        ? "cover"
                                        : "contain",

                                      borderRadius:
                                        imageShape == "rounded" ? "6px" : "0px",
                                    }}
                                  ></div>
                                </div>
                              </div>

                              <div
                                style={{
                                  margin: `0px ${
                                    sectionMargins.right + "px"
                                  } 0px ${sectionMargins.left + "px"}`,
                                  padding: `${sectionMargins.top + "px"} 0px ${
                                    sectionMargins.bottom + "px"
                                  } 0px`,
                                }}
                              >
                                <div className="pages-detail-preview-seprator"></div>
                              </div>

                              <div
                                style={{
                                  margin: `0px ${
                                    sectionMargins.right + "px"
                                  } 0px ${sectionMargins.left + "px"}`,
                                  padding: `${sectionMargins.top + "px"} 0px ${
                                    sectionMargins.bottom + "px"
                                  } 0px`,
                                }}
                              >
                                <h3 className="pages-detail-preview-heading">
                                  Please don't use circular dependencies in
                                  spring boot projects
                                </h3>
                              </div>

                              <div
                                style={{
                                  margin: `0px ${
                                    sectionMargins.right + "px"
                                  } 0px ${sectionMargins.left + "px"}`,
                                  padding: `${sectionMargins.top + "px"} 0px ${
                                    sectionMargins.bottom + "px"
                                  } 0px`,
                                }}
                              >
                                <div className="pages-detail-preview-video">
                                  <img
                                    className="img-fluid pages-detail-preview-code"
                                    src={
                                      require("../../assets/images/dashboard/youtube-icon.png")
                                        .default
                                    }
                                  />
                                </div>
                              </div>

                              <div
                                style={{
                                  margin: "0px 15px",
                                  padding: "15px 0px 0px 0px",
                                }}
                              >
                                <p className="pages-detail-preview-text">
                                  Yellow light wash 5-pocket mid-rise cropped
                                  jeans, clean look with no fade, has a button
                                  and zip closure, waistband with belt loops
                                  Blue light wash 5-pocket mid-rise cropped
                                  jeans, clean look with no fade, has a button
                                  and zip closure, waistband with belt loops.
                                </p>
                              </div>

                              <div
                                style={{
                                  margin: "0px 15px",
                                  padding: "15px 0px 0px 0px",
                                }}
                              >
                                <img
                                  className="img-fluid pages-detail-preview-code"
                                  src={
                                    require("../../assets/images/dashboard/page-map.png")
                                      .default
                                  }
                                />
                              </div>

                              <div
                                style={{
                                  margin: "0px 15px",
                                  padding: "15px 0px 0px 0px",
                                }}
                              >
                                <button
                                  className="pages-detail-preview-button"
                                  style={{
                                    backgroundColor: "#1A1A1A",
                                    color: "#fff",
                                  }}
                                >
                                  Book Package
                                </button>
                              </div>
                            </div>
                          </div>
                        </Carousel>
                      </div>
                    </div>
                  </div>
                  <div className="page-settings-mobile-btns">
                    <button className="page-settings-save-mob">Save</button>
                    <p className="page-settings-mobile-timestamp">
                      Updated 10 April 2023, 11:45
                    </p>
                  </div>
                </div>
              )} */}
              <PageOptions
                from="Custom"
                GetPagesapi={GetPagesapi}
                optionsData={{
                  enableGeneralSettings: enableGeneralSettings,
                  enablePageLayout: enablePageLayout,
                  pageLayout: pageLayout,
                  setPageLayout: setPageLayout,
                  pageState: pageState,
                }}
                updatePageOption={updatePageOption}
                pageOptions={state.pageOptions}
                previewRecurs={previewRecurs}
                pagesData={pagesData}
                savePageSettings={savePageSettings}
                savePageSettingLoading={savePageSettingLoading}
                updatedAt={updatedAt}
                setUpdatedAt={setUpdatedAt}
              />

              {/* Pages Settings Ends */}
              {!enableGeneralSettings && !enablePageLayout && (
                <div className="cms-page-list-mobile">
                  {spinner === true ? (
                    <div className="newspinner" style={{ minHeight: "80vh" }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        xlink="http://www.w3.org/1999/xlink"
                        style={{
                          margin: "auto",
                          background: "rgb(241, 242, 243,0)",
                          display: "block",
                          shaperendering: "auto",
                          animationplaystate: "running",
                          animationdelay: "0s",
                        }}
                        width="200px"
                        height="200px"
                        viewBox="0 0 100 100"
                        preserveAspectRatio="xMidYMid"
                      >
                        <g
                          transform="translate(20 50)"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <circle
                            cx="0"
                            cy="0"
                            r="6"
                            fill="#e6261f"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.375s"
                              calcMode="spline"
                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                              values="0;1;0"
                              keyTimes="0;0.5;1"
                              dur="1s"
                              repeatCount="indefinite"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            ></animateTransform>
                          </circle>
                        </g>
                        <g
                          transform="translate(40 50)"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <circle
                            cx="0"
                            cy="0"
                            r="6"
                            fill="#f7d038"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.25s"
                              calcMode="spline"
                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                              values="0;1;0"
                              keyTimes="0;0.5;1"
                              dur="1s"
                              repeatCount="indefinite"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            ></animateTransform>
                          </circle>
                        </g>
                        <g
                          transform="translate(60 50)"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <circle
                            cx="0"
                            cy="0"
                            r="6"
                            fill="#49da9a"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="-0.125s"
                              calcMode="spline"
                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                              values="0;1;0"
                              keyTimes="0;0.5;1"
                              dur="1s"
                              repeatCount="indefinite"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            ></animateTransform>
                          </circle>
                        </g>
                        <g
                          transform="translate(80 50)"
                          style={{
                            animationplaystate: "running",
                            animationdelay: "0s",
                          }}
                        >
                          <circle
                            cx="0"
                            cy="0"
                            r="6"
                            fill="#4355db"
                            style={{
                              animationplaystate: "running",
                              animationdelay: "0s",
                            }}
                          >
                            <animateTransform
                              attributeName="transform"
                              type="scale"
                              begin="0s"
                              calcMode="spline"
                              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                              values="0;1;0"
                              keyTimes="0;0.5;1"
                              dur="1s"
                              repeatCount="indefinite"
                              style={{
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                            ></animateTransform>
                          </circle>
                        </g>
                      </svg>
                    </div>
                  ) : (
                    <>
                      {bottomSpinner &&
                        window.matchMedia("(max-width: 1200px)").matches && (
                          <div
                            className="form-group"
                            style={{ background: "none", margin: "auto" }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              xlink="http://www.w3.org/1999/xlink"
                              style={{
                                margin: "auto",
                                background: "rgb(241, 242, 243,0)",
                                display: "block",
                                shaperendering: "auto",
                                animationplaystate: "running",
                                animationdelay: "0s",
                              }}
                              width="200px"
                              height="100px"
                              viewBox="0 0 100 100"
                              preserveAspectRatio="xMidYMid"
                            >
                              <g
                                transform="translate(20 50)"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#e6261f"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="-0.375s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                              <g
                                transform="translate(40 50)"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#f7d038"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="-0.25s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                              <g
                                transform="translate(60 50)"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#49da9a"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="-0.125s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                              <g
                                transform="translate(80 50)"
                                style={{
                                  animationplaystate: "running",
                                  animationdelay: "0s",
                                }}
                              >
                                <circle
                                  cx="0"
                                  cy="0"
                                  r="6"
                                  fill="#4355db"
                                  style={{
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                >
                                  <animateTransform
                                    attributeName="transform"
                                    type="scale"
                                    begin="0s"
                                    calcMode="spline"
                                    keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                    values="0;1;0"
                                    keyTimes="0;0.5;1"
                                    dur="1s"
                                    repeatCount="indefinite"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  ></animateTransform>
                                </circle>
                              </g>
                            </svg>
                          </div>
                        )}
                      {pagesData !== null && pagesData.length > 0 ? (
                        <>
                          {mobileRecurs(pagesData, "parent")}
                          {paginationSpinner &&
                            window.matchMedia("(max-width: 1200px)")
                              .matches && (
                              <div
                                className={`form-group`}
                                style={{ background: "none", margin: "auto" }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  xlink="http://www.w3.org/1999/xlink"
                                  style={{
                                    margin: "auto",
                                    background: "rgb(241, 242, 243,0)",
                                    display: "block",
                                    shaperendering: "auto",
                                    animationplaystate: "running",
                                    animationdelay: "0s",
                                  }}
                                  width="200px"
                                  height="100px"
                                  viewBox="0 0 100 100"
                                  preserveAspectRatio="xMidYMid"
                                >
                                  <g
                                    transform="translate(20 50)"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  >
                                    <circle
                                      cx="0"
                                      cy="0"
                                      r="6"
                                      fill="#e6261f"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    >
                                      <animateTransform
                                        attributeName="transform"
                                        type="scale"
                                        begin="-0.375s"
                                        calcMode="spline"
                                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                        values="0;1;0"
                                        keyTimes="0;0.5;1"
                                        dur="1s"
                                        repeatCount="indefinite"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      ></animateTransform>
                                    </circle>
                                  </g>
                                  <g
                                    transform="translate(40 50)"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  >
                                    <circle
                                      cx="0"
                                      cy="0"
                                      r="6"
                                      fill="#f7d038"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    >
                                      <animateTransform
                                        attributeName="transform"
                                        type="scale"
                                        begin="-0.25s"
                                        calcMode="spline"
                                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                        values="0;1;0"
                                        keyTimes="0;0.5;1"
                                        dur="1s"
                                        repeatCount="indefinite"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      ></animateTransform>
                                    </circle>
                                  </g>
                                  <g
                                    transform="translate(60 50)"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  >
                                    <circle
                                      cx="0"
                                      cy="0"
                                      r="6"
                                      fill="#49da9a"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    >
                                      <animateTransform
                                        attributeName="transform"
                                        type="scale"
                                        begin="-0.125s"
                                        calcMode="spline"
                                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                        values="0;1;0"
                                        keyTimes="0;0.5;1"
                                        dur="1s"
                                        repeatCount="indefinite"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      ></animateTransform>
                                    </circle>
                                  </g>
                                  <g
                                    transform="translate(80 50)"
                                    style={{
                                      animationplaystate: "running",
                                      animationdelay: "0s",
                                    }}
                                  >
                                    <circle
                                      cx="0"
                                      cy="0"
                                      r="6"
                                      fill="#4355db"
                                      style={{
                                        animationplaystate: "running",
                                        animationdelay: "0s",
                                      }}
                                    >
                                      <animateTransform
                                        attributeName="transform"
                                        type="scale"
                                        begin="0s"
                                        calcMode="spline"
                                        keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                                        values="0;1;0"
                                        keyTimes="0;0.5;1"
                                        dur="1s"
                                        repeatCount="indefinite"
                                        style={{
                                          animationplaystate: "running",
                                          animationdelay: "0s",
                                        }}
                                      ></animateTransform>
                                    </circle>
                                  </g>
                                </svg>
                              </div>
                            )}
                        </>
                      ) : (
                        window.matchMedia("(max-width: 1200px)").matches && (
                          <div className="nopagecreated ">
                            <div>
                              <img
                                src={
                                  require("../../assets/images/dashboard/no-page-create.png")
                                    .default
                                }
                              />
                              <p>
                                Nothing here at the moment. Come back later?
                              </p>
                            </div>
                          </div>
                        )
                      )}{" "}
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </BottomScrollListener>
        <Modal
          isOpen={isTrashed}
          centered
          className="delete-popup"
          toggle={(e) => handleTrashedPopUp(e, "cancel")}
          // className={this.props.className}
          dataclassName="addonpopup"
        >
          <ModalBody className="">
            <div className="crossmodal mt-1">
              <svg
                onClick={(e) => handleTrashedPopUp(e, "cancel")}
                xmlns="http://www.w3.org/2000/svg"
                width="10.96"
                height="10.961"
                viewBox="0 0 10.96 10.961"
              >
                <g
                  id="Group_38368"
                  data-name="Group 38368"
                  transform="translate(-1914.917 -169.92)"
                >
                  <path
                    id="Path_82532"
                    data-name="Path 82532"
                    d="M-16354-3830v14"
                    transform="translate(10781.152 14442.694) rotate(45)"
                    fill="none"
                    stroke="#c8cedb"
                    stroke-width="1.5"
                  />
                  <path
                    id="Path_82533"
                    data-name="Path 82533"
                    d="M-16354-3830v14"
                    transform="translate(-12346.896 9036.155) rotate(135)"
                    fill="none"
                    stroke="#c8cedb"
                    stroke-width="1.5"
                  />
                </g>
              </svg>
            </div>
            <div>
              <h5>Delete this page?</h5>
              <h6>This action will permanently delete your page.</h6>

              <div className="d-flex align-items-center justify-content-between cms-delete-popup-footer">
                <div
                  className="d-none d-xl-block grey-btn"
                  onClick={(e) => handleTrashedPopUp(e, "cancel")}
                >
                  <p>Cancel</p>
                </div>

                <div onClick={(e) => handleTrashedPage(e)}>
                  <div className="d-flex align-items-center justify-content-center h-100 w-100 red-btn">
                    <p>
                      <div className={isLoader}>
                        <img src={loaderimage} alt="loader" />
                      </div>
                      <div>Delete</div>
                    </p>
                  </div>
                </div>

                <div
                  className="d-block d-xl-none grey-btn"
                  onClick={(e) => handleTrashedPopUp(e, "cancel")}
                >
                  <p>Cancel</p>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <Modal
          isOpen={bulkDeletePopup}
          centered
          className="delete-popup bulk-delete-popup"
          toggle={handleBulkDeletePopup}
          // className={this.props.className}
          dataclassName="addonpopup"
        >
          <ModalHeader
            className="bulk-delete-cross-head"
            toggle={handleBulkDeletePopup}
          >
            <svg
              width="13.059"
              height="13.059"
              viewBox="0 0 13.059 13.059"
              onClick={handleBulkDeletePopup}
            >
              <path
                id="Union_18"
                data-name="Union 18"
                d="M6,6l6,6ZM6,6,0,0,6,6,0,12ZM6,6l6-6Z"
                transform="translate(0.529 0.531)"
                fill="#C8CEDB"
                stroke="#C8CEDB"
                strokeWidth="1.5"
              />
            </svg>
          </ModalHeader>
          <ModalBody className="">
            <div>
              <h5 style={{ marginBottom: "18px" }}>Bulk delete?</h5>
              <h6>This action will permanently delete your selected pages.</h6>
              <div className="d-flex align-items-center justify-content-center cms-delete-popup-footer">
                <div
                  className="d-none d-xl-block grey-btn"
                  onClick={handleBulkDeletePopup}
                >
                  <p>Cancel</p>
                </div>
                <div onClick={() => handleBulkDeletePopup("delete")}>
                  <div className="d-flex align-items-center justify-content-center h-100 w-100 red-btn">
                    <p>
                      {bulkDeleteLoader ? (
                        <div className="d-flex justify-content-center align-items-center h-100">
                          <div className="new-red-loader"></div>
                        </div>
                      ) : (
                        <div>Delete</div>
                      )}
                    </p>
                  </div>
                </div>
                <div
                  className="d-block d-xl-none grey-btn"
                  onClick={handleBulkDeletePopup}
                >
                  <p>Cancel</p>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </section>
      <SingleSelectionPopUpForCustomApp
        openPopUp={openSingleSelectionPopUp}
        handlePopUp={handleOpenSelectionPopUp}
        data={filterPages(pagesData, selectedArr)}
        handleInputClick={handleParentChange}
        selectedId={tempParentId}
        subData={subData}
        setSubData={setSubData}
        searchVal={searchValForPopUp}
        setSearchVal={setSearchValForPopUp}
        breadcrumbArr={breadcrumbArr}
        setBreadcrumbArr={setBreadcrumbArr}
        from={"page"}
        oldBreadcrumbArr={oldBreadcrumbArr}
        oldSubData={oldSubData}
        handleBottomScrollListner={handleBottomScrollListner}
        paginationSpinner={paginationSpinner}
        defaultOption={"No Parent"}
        shouldNotBeSelected={true}
      />
    </>
  );
};
export default Cmspages;
