import React from 'react';

const ProductFeatures = ({ imageSrc, title, featureList }) => (
  <div className="createapp-right-steps">
    <div className="create-app-steps-img">
      <img src={imageSrc} alt={title} />
    </div>
    <div className='create-app-features'>
      <h5>{title}</h5>
      <ul>
        {featureList.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
    </div>
  </div>
);

export default ProductFeatures;
