import React from "react";
import { countBlackIcons } from "../../../utils/commonUtils";
import ChildArrow from "../../commonIcons/ChildArrow";

const MenuPreviewList = ({
  menuType,
  isPrimaryMenuEnable,
  isSecondaryMenuEnable,
  syncWebsiteToggleVal,
  showAsGrid,
  menuListingData,
  menuSeparatorColor,
  iconBorderColor,
  showIcon,
  iconColor,
  showText,
  itemTextColor,
  iconBGcolor,
  iconShape,
  gridColumn,
  iconFilter,
}) => {
  const { blackIconItems, totalItems } = countBlackIcons(menuListingData);

  return (
    <>
      {((isPrimaryMenuEnable && menuType === "Primary") ||
        (isSecondaryMenuEnable && menuType === "Secondary")) &&
      !syncWebsiteToggleVal ? (
        <>
          {!showAsGrid ? (
            <>
              {menuListingData && menuListingData.length ? (
                <ul
                  className="menu-preview-list"
                  style={{
                    borderBottom:
                      isSecondaryMenuEnable && menuType === "Primary"
                        ? "1px solid " + menuSeparatorColor
                        : "",
                    paddingBottom:
                      (isSecondaryMenuEnable && menuType === "Secondary") ||
                      (!isSecondaryMenuEnable && menuType === "Primary")
                        ? "50px"
                        : "",
                  }}
                >
                  {menuListingData && menuListingData.length ? (
                    menuListingData.map((row) => {
                      let checkChildStatus = 0;
                      return (
                        row.status === 1 && (
                          <li key={row.id} className="menu-preview-listType">
                            <div className="d-flex align-items-center">
                              {showIcon ? (
                                <>
                                  {!row.item_icon ? (
                                    <div className="disabledicon-div"></div>
                                  ) : row.item_icon.indexOf(
                                      "cdn-icons-png.flaticon.com"
                                    ) > -1 ? (
                                    <img
                                      className="menubuilder-img-fluid"
                                      src={row.item_icon}
                                      alt="icon"
                                      style={{
                                        width: "16px",
                                        filter:
                                          blackIconItems === totalItems
                                            ? iconFilter
                                            : null,
                                      }}
                                    />
                                  ) : row.item_icon_newtype === "flaticon" ? (
                                    <img
                                      className="menubuilder-img-fluid"
                                      src={
                                        process.env.REACT_APP_Image_Path +
                                        parseInt(
                                          localStorage.getItem("user_id")
                                        ) +
                                        "/" +
                                        parseInt(
                                          localStorage.getItem("Appid")
                                        ) +
                                        process.env.REACT_APP_NEW_ICON_PATH +
                                        row.item_icon
                                      }
                                      alt="icon"
                                      style={{
                                        filter:
                                          blackIconItems === totalItems
                                            ? iconFilter
                                            : null,
                                      }}
                                    />
                                  ) : menuType !== "Primary" ? (
                                    <img
                                      className="menubuilder-img-fluid"
                                      src={
                                        process.env.REACT_APP_Image_Path +
                                        parseInt(
                                          localStorage.getItem("user_id")
                                        ) +
                                        "/" +
                                        parseInt(
                                          localStorage.getItem("Appid")
                                        ) +
                                        process.env.REACT_APP_NEW_ICON_PATH +
                                        row.item_icon
                                      }
                                      alt="icon1"
                                      style={{
                                        width: "16px",
                                        filter:
                                          blackIconItems === totalItems
                                            ? iconFilter
                                            : null,
                                      }}
                                    />
                                  ) : (
                                    <img
                                      className="menubuilder-img-fluid"
                                      src={
                                        process.env.REACT_APP_Image_Path +
                                        (() => {
                                          switch (row.item_icon_type) {
                                            case "light":
                                              return process.env
                                                .REACT_APP_light_path;
                                            case "bold":
                                              return process.env
                                                .REACT_APP_bold_path;
                                            case "regular":
                                              return process.env
                                                .REACT_APP_regular_path;
                                            default:
                                              return process.env
                                                .REACT_APP_light_path;
                                          }
                                        })() +
                                        row.item_icon
                                      }
                                      alt="icon"
                                      style={{
                                        width: "16px",
                                        filter:
                                          blackIconItems === totalItems
                                            ? iconFilter
                                            : null,
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              {showText ? (
                                <span
                                  className="munu-iconType-name"
                                  style={{
                                    color: itemTextColor,
                                    lineHeight: "16px",
                                  }}
                                >
                                  {" "}
                                  {row.item_name}
                                </span>
                              ) : (
                                <></>
                              )}
                            </div>
                            {row.children && row.children.length ? (
                              <>
                                {row.children.map((child) => {
                                  if (child.status === 1) {
                                    checkChildStatus++;
                                  }
                                })}
                                {checkChildStatus > 0 && (
                                  <ChildArrow
                                    strokeColor={itemTextColor}
                                    opacity={"0.4"}
                                  />
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </li>
                        )
                      );
                    })
                  ) : (
                    <></>
                  )}
                </ul>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              {menuListingData && menuListingData.length ? (
                <ul
                  className="menu-grid-ul "
                  style={{
                    borderBottom:
                      isSecondaryMenuEnable && menuType === "Primary"
                        ? "1px solid " + menuSeparatorColor
                        : "",
                    paddingBottom:
                      (isSecondaryMenuEnable && menuType === "Secondary") ||
                      (!isSecondaryMenuEnable && menuType === "Primary")
                        ? "50px"
                        : "",
                  }}
                >
                  {menuListingData && menuListingData.length ? (
                    menuListingData.map((row) => {
                      return (
                        row.status === 1 && (
                          <li key={row.id}>
                            <div
                              className="grid-view-menudiv size-for3"
                              style={{
                                background: iconBGcolor,
                                border: "1px solid " + iconBorderColor,
                                borderRadius:
                                  iconShape === "reactangular"
                                    ? "0px"
                                    : iconShape === "square"
                                    ? "20px"
                                    : "50%",
                                width:
                                  gridColumn === 1
                                    ? "100%"
                                    : gridColumn === 2
                                    ? "134px"
                                    : gridColumn === 3
                                    ? "86px"
                                    : "61px",
                                height:
                                  gridColumn === 1
                                    ? "275px"
                                    : gridColumn === 2
                                    ? "134px"
                                    : gridColumn === 3
                                    ? "86px"
                                    : "61px",
                              }}
                            >
                              {showIcon ? (
                                <>
                                  {!row.item_icon ? (
                                    ""
                                  ) : row.item_icon.indexOf(
                                      "cdn-icons-png.flaticon.com"
                                    ) > -1 ? (
                                    <img
                                      className="menubuilder-img-fluid"
                                      src={row.item_icon}
                                      alt="icon"
                                      style={{
                                        maxWidth:
                                          gridColumn === 1
                                            ? "30px"
                                            : gridColumn === 2
                                            ? "28px"
                                            : gridColumn === 3
                                            ? "20px"
                                            : "16px",
                                        maxHeight:
                                          gridColumn === 1
                                            ? "30px"
                                            : gridColumn === 2
                                            ? "28px"
                                            : gridColumn === 3
                                            ? "20px"
                                            : "16px",
                                        width:
                                          gridColumn === 1
                                            ? "30px"
                                            : gridColumn === 2
                                            ? "28px"
                                            : gridColumn === 3
                                            ? "20px"
                                            : "16px",
                                        height:
                                          gridColumn === 1
                                            ? "30px"
                                            : gridColumn === 2
                                            ? "28px"
                                            : gridColumn === 3
                                            ? "20px"
                                            : "16px",
                                        // marginBottom:
                                        //   gridColumn === 1
                                        //     ? "14px"
                                        //     : gridColumn === 2
                                        //     ? "16px"
                                        //     : gridColumn === 3
                                        //     ? "10px"
                                        //     : "5px",
                                        filter:
                                          blackIconItems === totalItems
                                            ? iconFilter
                                            : null,
                                      }}
                                    />
                                  ) : row.item_icon_newtype === "flaticon" ? (
                                    <img
                                      className="menubuilder-img-fluid"
                                      src={
                                        process.env.REACT_APP_Image_Path +
                                        parseInt(
                                          localStorage.getItem("user_id")
                                        ) +
                                        "/" +
                                        parseInt(
                                          localStorage.getItem("Appid")
                                        ) +
                                        process.env.REACT_APP_NEW_ICON_PATH +
                                        row.item_icon
                                      }
                                      alt="icon"
                                      style={{
                                        maxWidth:
                                          gridColumn === 1
                                            ? "30px"
                                            : gridColumn === 2
                                            ? "28px"
                                            : gridColumn === 3
                                            ? "20px"
                                            : "16px",
                                        maxHeight:
                                          gridColumn === 1
                                            ? "30px"
                                            : gridColumn === 2
                                            ? "28px"
                                            : gridColumn === 3
                                            ? "22px"
                                            : "16px",
                                        width:
                                          gridColumn === 1
                                            ? "30px"
                                            : gridColumn === 2
                                            ? "28px"
                                            : gridColumn === 3
                                            ? "20px"
                                            : "16px",
                                        height:
                                          gridColumn === 1
                                            ? "30px"
                                            : gridColumn === 2
                                            ? "28px"
                                            : gridColumn === 3
                                            ? "22px"
                                            : "16px",
                                        // marginBottom:
                                        //   gridColumn === 1
                                        //     ? "14px"
                                        //     : gridColumn === 2
                                        //     ? "16px"
                                        //     : gridColumn === 3
                                        //     ? "10px"
                                        //     : "5px",
                                        filter:
                                          blackIconItems === totalItems
                                            ? iconFilter
                                            : null,
                                      }}
                                    />
                                  ) : menuType !== "Primary" ? (
                                    <img
                                      className="menubuilder-img-fluid 2"
                                      src={
                                        process.env.REACT_APP_Image_Path +
                                        parseInt(
                                          localStorage.getItem("user_id")
                                        ) +
                                        "/" +
                                        parseInt(
                                          localStorage.getItem("Appid")
                                        ) +
                                        process.env.REACT_APP_NEW_ICON_PATH +
                                        row.item_icon
                                      }
                                      alt="icon"
                                      style={{
                                        maxWidth:
                                          gridColumn === 1
                                            ? "30px"
                                            : gridColumn === 2
                                            ? "26px"
                                            : gridColumn === 3
                                            ? "22px"
                                            : "16px",
                                        maxHeight:
                                          gridColumn === 1
                                            ? "30px"
                                            : gridColumn === 2
                                            ? "26px"
                                            : gridColumn === 3
                                            ? "22px"
                                            : "16px",
                                        width:
                                          gridColumn === 1
                                            ? "30px"
                                            : gridColumn === 2
                                            ? "26px"
                                            : gridColumn === 3
                                            ? "22px"
                                            : "16px",
                                        height:
                                          gridColumn === 1
                                            ? "30px"
                                            : gridColumn === 2
                                            ? "26px"
                                            : gridColumn === 3
                                            ? "22px"
                                            : "16px",
                                        filter:
                                          blackIconItems === totalItems
                                            ? iconFilter
                                            : null,
                                      }}
                                    />
                                  ) : (
                                    <img
                                      className="menubuilder-img-fluid"
                                      src={
                                        process.env.REACT_APP_Image_Path +
                                        (() => {
                                          switch (row.item_icon_type) {
                                            case "light":
                                              return process.env
                                                .REACT_APP_light_path;
                                            case "bold":
                                              return process.env
                                                .REACT_APP_bold_path;
                                            case "regular":
                                              return process.env
                                                .REACT_APP_regular_path;
                                            default:
                                              return process.env
                                                .REACT_APP_light_path;
                                          }
                                        })() +
                                        row.item_icon
                                      }
                                      style={{
                                        maxWidth:
                                          gridColumn === 1
                                            ? "30px"
                                            : gridColumn === 2
                                            ? "28px"
                                            : gridColumn === 3
                                            ? "20px"
                                            : "16px",
                                        maxHeight:
                                          gridColumn === 1
                                            ? "30px"
                                            : gridColumn === 2
                                            ? "28px"
                                            : gridColumn === 3
                                            ? "20px"
                                            : "16px",
                                        width:
                                          gridColumn === 1
                                            ? "30px"
                                            : gridColumn === 2
                                            ? "28px"
                                            : gridColumn === 3
                                            ? "20px"
                                            : "16px",
                                        height:
                                          gridColumn === 1
                                            ? "30px"
                                            : gridColumn === 2
                                            ? "28px"
                                            : gridColumn === 3
                                            ? "20px"
                                            : "16px",
                                        filter:
                                          blackIconItems === totalItems
                                            ? iconFilter
                                            : null,
                                      }}
                                      alt="icon"
                                    />
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                              {showText ? (
                                <span
                                  className="munu-iconType-name"
                                  style={{
                                    color: itemTextColor,
                                    marginTop:
                                      showIcon && row.item_icon
                                        ? gridColumn === 1
                                          ? "14px"
                                          : gridColumn === 2
                                          ? "16px"
                                          : gridColumn === 3
                                          ? "10px"
                                          : "5px"
                                        : "0px",
                                    fontSize:
                                      gridColumn === 1
                                        ? "13px"
                                        : gridColumn === 2
                                        ? "12px"
                                        : gridColumn === 3
                                        ? "10px"
                                        : "8px",
                                  }}
                                >
                                  {" "}
                                  {row.item_name}
                                </span>
                              ) : (
                                <></>
                              )}
                            </div>
                          </li>
                        )
                      );
                    })
                  ) : (
                    <></>
                  )}
                </ul>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      ) : (
        <div
          style={{
            display:
              (isPrimaryMenuEnable && menuType === "Primary") ||
              (isSecondaryMenuEnable && menuType === "Secondary")
                ? "block"
                : "none",
          }}
        >
          <ul
            className="nodata-available"
            style={{
              borderBottom:
                isSecondaryMenuEnable && menuType === "Primary"
                  ? "1px solid " + menuSeparatorColor
                  : "",
            }}
          >
            <li style={{ width: "110px" }}></li>
            <li style={{ width: "90px" }}></li>
            <li style={{ width: "84px" }}></li>
            <li style={{ width: "132px" }}></li>
            <li style={{ width: "77px" }}></li>
            <li style={{ width: "112px" }}></li>
            <li style={{ width: "84px" }}></li>
          </ul>
        </div>
      )}
    </>
  );
};

export default MenuPreviewList;
