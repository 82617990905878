import React from 'react'

const PremiumTag = ({module}) => {
  return (
    <div className="premium-tag">
        <svg className='premium-tag-icon' width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7 0.5L4 5L0 3.5L2 9.5H12L14 3.5L10 5L7 0.5ZM12 12.5V10.5H2V12.5H12Z" fill="#FFB300"/>
        </svg>
        <h4 className='premium-tag-text'>{module === "consent" ? "Premium" :"Premium"}</h4>
        <div className="premium-tooltip">
        {module === "consent" ? "This is premium feature" :"This is premium feature"}
       
        </div>
    </div>
  )
}

export default PremiumTag;


