import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import {
  MarkerF,
  LoadScript,
  GoogleMap,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import crossIcon from "../../../assets/images/dashboard/cross.png";
import currentlocation from "../../../assets/images/dashboard/centerLocation.svg";
import darkbackIcon from "../../../assets/images/dashboard/darkbackIcon.svg";
const LocationModal = ({
  searchRef,
  mapSearchInput,
  setMapSearchInput,
  handleLocationSelected,
  handleLocationClick,
  handleLocationToggle,
  settingsState,
  handleMapClick,
  mapRef,
  inputRef,
  setSettingsState,
}) => {
  return (
    <div>
      <Modal
        isOpen={settingsState.locationModal}
        toggle={() => handleLocationToggle()}
        className="map-coordinate-popup map-modal-popup"
        centered
      >
        <ModalHeader>
          <h2>Map coordinates</h2>
          <img
            onClick={() => handleLocationToggle()}
            src={crossIcon}
            alt="cross-icon"
          />
        </ModalHeader>

        <ModalBody>
          <LoadScript
            googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_API_KEY}
            libraries={["places"]}
          >
            <div
             
              className="popupmap-div location-modal-div-text"
            >
              <StandaloneSearchBox ref={searchRef}>
                <div className="map-popup-head-div">
                  <img src={darkbackIcon} className="map-goback-iconimg" onClick={() => handleLocationToggle()}/>
                 

                  <div className="web-popup-search-div">
                    <input
                      ref={inputRef}
                      type="text"
                      placeholder="Search location"
                      className="form-control flagsearch-input"
                      value={mapSearchInput}
                      onChange={(e) => setMapSearchInput(e.target.value)}
                      onKeyDown={(e) => {
                        setMapSearchInput(e.target.value);
                        if (e.key === "Enter") {
                          handleLocationSelected(e.target.value);
                        }
                      }}
                    />
                    {mapSearchInput.length > 0 && (
                      <p
                        className="cleartextweb"
                        onClick={() => setMapSearchInput("")}
                      >
                        Clear
                      </p>
                    )}
                  </div>
                  <div className="mob-map-done-div">
                    <p
                      className={`${
                        !settingsState.mapSelectedLocation?.lat
                          ? "map-donetextmob-disable"
                          : "map-donetextmob"
                      }`}
                      onClick={() => {
                        setSettingsState({
                          lat: settingsState.mapSelectedLocation.lat,
                          lng: settingsState.mapSelectedLocation.lng,
                        });
                        handleLocationToggle();
                      }}
                    >
                      Done
                    </p>
                  </div>
                </div>
              </StandaloneSearchBox>
              <GoogleMap
                mapContainerStyle={{ width: "100%", height: "100%" }}
                zoom={settingsState.zoom}
                center={
                  settingsState.mapSelectedLocation?.lat
                    ? settingsState.mapSelectedLocation
                    : { lat: 40.7058989699728, lng: -74.0099789190983 }
                }
                onClick={(e) => handleMapClick(e)}
                ref={mapRef}
                streetViewControl={false}
                options={{
                  streetViewControl: false,
                }}
              >
                <MarkerF position={settingsState.mapSelectedLocation} />
                <button
                  onClick={() => handleLocationClick()}
                  className="map-current-location"
                >
                  <img src={currentlocation}/>
                 
                </button>
              </GoogleMap>
            </div>
          </LoadScript>
        </ModalBody>
        <ModalFooter>
          <div className="map-coordinate-popup-footer">
            <button
              className="map-cancel-btn"
              onClick={() => handleLocationToggle()}
            >
              Cancel
            </button>
            <button
              disabled={!settingsState.mapSelectedLocation?.lat}
              className={`${
                !settingsState.mapSelectedLocation?.lat
                  ? "map-done-btn map-done-btn-disabled"
                  : "map-done-btn"
              }`}
              onClick={() => {
                setSettingsState({
                  lat: settingsState.mapSelectedLocation.lat,
                  lng: settingsState.mapSelectedLocation.lng,
                });
                handleLocationToggle();
              }}
            >
              Done
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default LocationModal;
