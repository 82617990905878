import axios from "axios";
import { errortoast, sucesstoast } from "../../Dashboard/Toaster";
const Url = process.env.REACT_APP_API_URL + "api/user/";
const Newversion = process.env.REACT_APP_API_URL + "api/";
const newUrlForPaddle = process.env.REACT_APP_API_URL + "api/paddle/";
const newUrlForStripe = process.env.REACT_APP_API_URL + "api/stripe/";
// Getalladdons get API
export const Getallpackageaddon = (params, state) => {
  let appId;
  let wpId;
  if (
    localStorage.getItem("workspaceId") !== "" &&
    localStorage.getItem("workspaceId") !== null &&
    localStorage.getItem("workspaceId") !== undefined
  ) {
    appId = btoa(null);
    wpId = btoa(localStorage.getItem("workspaceId"));
  } else {
    appId = params.appid;
    wpId = btoa(null);
  }



  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  axios
    .get(
      Url + "get_package_addons/" + appId + "/" + wpId + "/" + btoa(null) + "/" + params.plan_code,
      {
        headers: headers,
      }
    )
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          spinner: false,
          addondata: res.data.data,
          zoho_id: res.data.data.subscription.zoho_subscription_id,
          activeaddon: res.data.data.subscription.one_time_addons,
          addon_code: res.data.data.subscription.addon_codes,
          selected_addon_code:
            res.data.data.subscription === null
              ? []
              : res.data.data.subscription.addon_codes,
          auto_addons:
            res.data.data.subscription === null
              ? []
              : res.data.data.subscription.auto_addons,
          appuser_role_id: res.data.data.appuser_role_id,
          addon_info: res.data.data.addon_info,
          recurring_addons_purchased_json:
            res.data.data.subscription === null ||
            res.data.data.subscription.recurring_addons_purchased_json === "" ||
            res.data.data.subscription.recurring_addons_purchased_json === null
              ? 0
              : JSON.parse(
                  res.data.data.subscription.recurring_addons_purchased_json
                ),
          onetime_addons_purchased_json:
            res.data.data.subscription === null ||
            res.data.data.subscription.onetime_addons_purchased_json === "" ||
            res.data.data.subscription.onetime_addons_purchased_json === null
              ? 0
              : JSON.parse(
                  res.data.data.subscription.onetime_addons_purchased_json
                ),
          add_user_quantity:
            res.data.data.user_data.customer_billing_type !== 2 &&
            (res.data.data.subscription === null ||
            res.data.data.subscription.recurring_addons_purchased_json === "" ||
            res.data.data.subscription.recurring_addons_purchased_json ===
              null ||
            res.data.data.subscription.recurring_addons_purchased_json ===
              "[]" ||
            res.data.data.subscription.recurring_addons_purchased_json[
              "add_user"
            ] === undefined
              ? 1
              : JSON.parse(
                  res.data.data.subscription.recurring_addons_purchased_json
                )["add_user"].quantity === 0
              ? 1
              : JSON.parse(
                  res.data.data.subscription.recurring_addons_purchased_json
                )["add_user"].quantity),
          new_add_user_quantity:
            res.data.data.user_data.customer_billing_type !== 2 ?
            (res.data.data.subscription === null ||
            res.data.data.subscription.recurring_addons_purchased_json === "" ||
            res.data.data.subscription.recurring_addons_purchased_json === null
              ? res.data.data.user_data.customer_billing_type===3 ?res.data.addon_quantity===0?1:res.data.addon_quantity  : 1
              :
              
              
              JSON.parse(
                  res.data.data.subscription.recurring_addons_purchased_json
                )[state.state.addoncode] === undefined
              ? 1
              : JSON.parse(
                  res.data.data.subscription.recurring_addons_purchased_json
                )[state.state.addoncode].quantity === 0
              ? 1
              : JSON.parse(
                  res.data.data.subscription.recurring_addons_purchased_json
                )[state.state.addoncode].quantity)
                
                : res.data.addon_quantity===0?1:res.data.addon_quantity ,
          user_count: res.data.user_count,
          deprecated_addon_quantity:
            res.data.deprecated_addon_quantity === undefined
              ? 0
              : res.data.deprecated_addon_quantity,
          addon_quantity:
            res.data.addon_quantity === undefined ? 0 : res.data.addon_quantity,
          multisite: res.data.data.multisite,
          customer_billing_type: res.data.data.user_data.customer_billing_type,
          payment_methods:
            res.data.data.payment_methods === undefined
              ? null
              : res.data.data.payment_methods,
          is_deal_plan: res.data.data.plan_data.is_deal_plan,
          plan_name: res.data.data.plan_data.plan_name,
          allowed_module: res.data.data.subscription.device_type,
          customer_type: res.data.data.user_data.customer_type,
          downgrade:res.data.data.subscription.downgrade_at_term_end,
         
        });
        localStorage.setItem("emaffia", btoa(res.data.data.user_data.email));
      } else if (res.data.code === 401) {
        errortoast(res.data.data);
        state.props.history.push("/apps");
      }
    });
};

//Downgrade addon
export const Downgrade_addon = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "downgrade-plan", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.props.history.push(`/transaction-successful/${btoa("paddle")}?product=addon&action=downgrade`)
        // sucesstoast("Addon removed successfully.");
        state.setState({
          deletemodal:false,
          submitval_confirmpop: "Yes",
          loader_remove: "d-none",
          submitval_downgrade: "DOWNGRADE",
          loader_downgrade: "d-none",
          submitval_update: "UPDATE",
          loader: "d-none",
          // runInitialLoad: ( state.state.runInitialLoad!==undefined && state.state.runInitialLoad!==null )?!state.state.runInitialLoad:true,
        });
      } else if (res.data.code === 203) {
        state.setState({
          deletemodal:false,
          show_limit_pop: true,
          deletemodal: false,
          submitval_confirmpop: "Yes",
          loader_remove: "d-none",
          submitval_downgrade: "DOWNGRADE",
          loader_downgrade: "d-none",
          submitval_update: "UPDATE",
          loader: "d-none",
        });
      }
    })
    .catch((error) => {});
};

//Downgrade Stack addon
export const Downgrade_stackaddon = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Url + "remove-recurring-addon", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.props.history.push(`/transaction-successful/${btoa("paddle")}?product=addon&action=downgrade`)
        // sucesstoast("Addon removed successfully.");
        state.setState({
          deletemodal:false,
          submitval_confirmpop: "Yes",
          loader_remove: "d-none",
          submitval_downgrade: "DOWNGRADE",
          loader_downgrade: "d-none",
          submitval_update: "UPDATE",
          loader: "d-none",
          // runInitialLoad:( state.state.runInitialLoad!==undefined && state.state.runInitialLoad!==null )?!state.state.runInitialLoad:true,
        });
      } else if (res.data.code === 203) {
        state.setState({
          deletemodal:false,
          show_limit_pop: true,
          deletemodal: false,
          submitval_confirmpop: "Yes",
          loader_remove: "d-none",
          submitval_downgrade: "DOWNGRADE",
          loader_downgrade: "d-none",
          submitval_update: "UPDATE",
          loader: "d-none",
        });
      }
    })
    .catch((error) => {
      state.setState({
        deletemodal:false,
        show_limit_pop: true,
        deletemodal: false,
        submitval_confirmpop: "Yes",
        loader_remove: "d-none",
        submitval_downgrade: "DOWNGRADE",
        loader_downgrade: "d-none",
        submitval_update: "UPDATE",
        loader: "d-none",
      });
    });
};

//New version addon
//Downgrade addon
export const Purchasenewversion = (params, state) => {
  state.setState({
    formbuttondisabled: true,
    submitval: "",
    loader: "load",
  });
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Newversion + "app/create-new-appversion", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.setState({
          formbuttondisabled: false,
          submitval: "Get Free",
          loader: "d-none",
        });
        sucesstoast("Version updated successfully");
        state.props.history.push("/app/dashboard");
      } else {
        state.setState({
          formbuttondisabled: false,
          submitval: "Get Free",
          loader: "d-none",
        });
      }
    })
    .catch((error) => {
      state.setState({
        formbuttondisabled: false,
        submitval: "Get Free",
        loader: "d-none",
      });
    });
};

export const paddleChangePlan = (params, state,callback) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

 params.agency_id = params.agency_id?params.agency_id:null;
 var actionparam = params.downgrade_at_term_end == 1 ? "downgrade":"upgrade";

  axios
    .post(newUrlForPaddle + "update-subscription", params, {
      headers: headers,
    })
    .then((res) => {

      if(res.data.code == 501)
      {
        state.setState(
          {
            code:res.data.code
          },
          () => callback()
        );
      }
      if(res.data.code == 204)
      {
        state.setState(
          {
            code:res.data.code
          },
          () => callback()
        );
      }
      

      if (res.data.code === 200) 
      {
       
        state.setState({
          submitval: "Make Payment",
          loader: "d-none",
          formbuttondisabled: false,
          index_checker: "",
        });
;
          localStorage.setItem('checkoutpageamount',res.data.total);
          localStorage.setItem('checkoutsubscriptionid',res.data.subscription_id);
          localStorage.setItem('checkoutcustomerid',res.data.user_id);

          const userinfo  = JSON.parse(localStorage.getItem("user_info"));
          
          if(localStorage.getItem("workspaceId") !== "" &&
                localStorage.getItem("workspaceId") !== "undefined" &&
                localStorage.getItem("workspaceId") !== undefined &&
                localStorage.getItem("workspaceId") !== null)
                {
                  var type = 'workspace';
                }
                else
                {
                  var type = 'pay-per-app';
                }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
        'event': 'checkout_successful',
        'user_email': userinfo.email,
        'user_name' : userinfo.name,
        'user_id': userinfo.id,
        'agency_id':localStorage.getItem('agency_id') ? parseInt(localStorage.getItem('agency_id')) : 1,
        'transactionDetails': [{
              'product_type': ( params.type == 1 || !params.type ) ?  "plan" :  "addon",
              'product_action':actionparam,
              'plan_type': type,       
              'price': res.data.total ,
              'currency': 'USD'
        }]
        });

          window.open(`/transaction-successful/${btoa("paddle")}?product=${( params.type == 1 || !params.type ) ?  "plan" :  "addon"}&action=${actionparam}`,"_self");

             if(state.props.history)
             {
 
              // state.props.history.push("/transaction-successful");
              // window.open("/transaction-successful","_self");
             }
            else
            {
              // window.open("/transaction-successful","_self");
              //  state.props.props.history.push("/transaction-successful");
            }
      } 
      else 
      {
        if(!window.location.pathname.includes("pricing")){
          errortoast("something went wrong");
          state.setState({
            code:res.data.code,
            submitval: "Make Payment",
            loader: "d-none",
            formbuttondisabled: false,
            index_checker: "",
          });
        }
    
        }
    })
    .catch((error) => {

      errortoast("something went wrong");
   
        
      state.setState({
        submitval: "Make Payment",
        loader: "d-none",
        formbuttondisabled: false,
        index_checker: "",
      });
     
    });
};

export const paddleGeneratePayLink = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(newUrlForPaddle + "generate-payment-link", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 201) {
        state.openPaddleCheckout(res.data.data, res.data.ams_order_id);
        state.setState({
          submitval: "Make Payment",
          loader: "d-none",
          formbuttondisabled: false,
        });
        // state.props.history.push("/transaction-successful");
      } else {
        errortoast("something went wrong");
        state.setState({
          submitval: "Make Payment",
          loader: "d-none",
          formbuttondisabled: false,
        });
      }
    })
    .catch((error) => {
      errortoast("something went wrong");
      state.setState({
        submitval: "Make Payment",
        loader: "d-none",
        formbuttondisabled: false,
      });
    });
};
// Zoho - Addon - Purchase API
export const zohoAddonPurchaseAPI = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(Newversion + "app/buy-recurring-addon", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        window.open(res.data.data.url, "_self");
       
      } else {
        state.setState({
          submitval: "Make payment",
          loader: "d-none",
          submitval_update: "UPDATE",
          formbuttondisabled: false,
        });
      }
    })
    .catch((error) => {});
};


// Zoho - Addon - Purchase API
export const newAddonDowngradeApi = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };

  axios
    .post(newUrlForPaddle + "cancel-addon-subscription", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        state.props.history.push(`/transaction-successful/${btoa("paddle")}?product=addon&action=downgrade`)
        // sucesstoast("Addon removed successfully.");
        state.setState({
          show_limit_pop: true,
          deletemodal: false,
          submitval_confirmpop: "Yes",
          loader_remove: "d-none",
          submitval_downgrade: "DOWNGRADE",
          loader_downgrade: "d-none",
          submitval_update: "UPDATE",
          loader: "d-none",
          // runInitialLoad:( state.state.runInitialLoad!==undefined && state.state.runInitialLoad!==null )?!state.state.runInitialLoad:true,
        });
      } else {
        errortoast("Your Addon removal failed.");
        state.setState({
          show_limit_pop: true,
          deletemodal: false,
          submitval_confirmpop: "Yes",
          loader_remove: "d-none",
          submitval_downgrade: "DOWNGRADE",
          loader_downgrade: "d-none",
          submitval_update: "UPDATE",
          loader: "d-none",
        });
      }
    })
    .catch((error) => {
      errortoast("Your Addon removal failed.");
      state.setState({
        show_limit_pop: true,
        deletemodal: false,
        submitval_confirmpop: "Yes",
        loader_remove: "d-none",
        submitval_downgrade: "DOWNGRADE",
        loader_downgrade: "d-none",
        submitval_update: "UPDATE",
        loader: "d-none",
      });
    });
};

export const newAddonStripeApi = (params, state) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + localStorage.getItem("amstoken"),
  };
  params.success_path = `/transaction-successful/${btoa("paddle")}?product=addon&action=upgrade`
  params.cancel_path = state.props?.location?.pathname;

  axios
    .post(newUrlForStripe + "create-subscription", params, {
      headers: headers,
    })
    .then((res) => {
      if (res.data.code === 200) {
        // sucesstoast("Addon purchased successfully.");
        state.props.history.push(`/transaction-successful/${btoa("paddle")}?product=addon&action=upgrade`)
      } else if(+res.data.code === 204 && res?.data?.url) {
        window.location.href = res?.data?.url;
      } else {
        errortoast("Your Addon purchase was unsuccessful. ");
      }
    })
    .catch((error) => {
      errortoast("Your Addon purchase was unsuccessful. ");
    });
};