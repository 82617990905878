import React from "react";
import Reactcolorpicker from "../../../Dashboard/Appearance/NewReactcolorpicker.js";
import Tooltip from "../../commonIcons/Tooltip.js";
import CrossIcon from "../../commonIcons/CrossIcon.js";
import RectangularShape from "../../commonIcons/RectangularShape.js";
import SquareShape from "../../commonIcons/SquareShape.js";
import CircleShape from "../../commonIcons/CircleShape.js";
import { countBlackIcons } from "../../../utils/commonUtils.js";

const SubMenuSettings = ({
  from,
  syncWithWebsiteMenuToggleVal,
  showIcon,
  showAsGrid,
  showText,
  iconShape,
  gridColumn,
  iconColor,
  itemTextColor,
  iconBGcolor,
  iconBorderColor,
  menuListingData,
  setMenuState,
  settings,
}) => {
  const { blackIconItems, totalItems } = countBlackIcons(menuListingData);

  const handleToggleChange = (e, toggleFrom) => {
    let currSetting = { ...settings };
    currSetting[toggleFrom] = e.target.checked;
    setMenuState({
      settings: currSetting,
    });
  };

  const handleColorChange = (color, key) => {
    let currSetting = { ...settings };
    currSetting[key] = color;
    setMenuState({
      settings: currSetting,
    });
  };

  const handleIconShape = (shape, key) => {
    let currSetting = { ...settings };
    currSetting[key] = shape;
    setMenuState({
      settings: currSetting,
    });
  };

  const handleColumn = (e, key) => {
    let currSetting = { ...settings };
    if (e.target.value > 1 && e.target.value < 5) {
      currSetting[key] = e.target.value;
    } else {
      currSetting[key] = 2;
    }
    setMenuState({
      settings: currSetting,
    });
  };

  return (
    <div className="setting-firstrymenu">
      <p className="setting-menuhead">{from} menu</p>
      <>
        {!syncWithWebsiteMenuToggleVal && (
          <>
            <div className="menusetting-toggle-div">
              <h4 className="menusetting-toggle-div-head">
                Show as grid{" "}
                <Tooltip
                  message={
                    "Enable this toggle to display posts in a grid layout, instead of a list."
                  }
                />
              </h4>

              <label
                className=" common-toogle-switch "
                htmlFor={from + "gridLayout"}
              >
                <input
                  type="checkbox"
                  className="common-switch-input"
                  id={from + "gridLayout"}
                  name={from + "gridLayout"}
                  checked={showAsGrid}
                  onChange={(e) =>
                    handleToggleChange(
                      e,
                      from === "Primary"
                        ? "showPrimaryMenuItemsAsGrid"
                        : "showSecondaryMenuItemsAsGrid"
                    )
                  }
                />
                <div className="switch-slider round"></div>
              </label>
            </div>

            <div className="menusetting-toggle-div">
              <h4 className="menusetting-toggle-div-head">
                Show icon <Tooltip message={"Enable to display icons."} />
              </h4>

              <label
                className=" common-toogle-switch "
                htmlFor={from + "showIcon"}
              >
                <input
                  type="checkbox"
                  className="common-switch-input"
                  id={from + "showIcon"}
                  name={from + "showIcon"}
                  checked={showIcon}
                  onChange={(e) =>
                    handleToggleChange(
                      e,
                      from === "Primary"
                        ? "showPrimaryMenuItemsIcon"
                        : "showSecondaryMenuItemsIcon"
                    )
                  }
                />
                <div className="switch-slider round"></div>
              </label>
            </div>

            <div className="menusetting-toggle-div">
              <h4 className="menusetting-toggle-div-head">
                Show text <Tooltip message={"Enable to display item text."} />
              </h4>

              <label
                className=" common-toogle-switch "
                htmlFor={from + "iconText"}
              >
                <input
                  type="checkbox"
                  className="common-switch-input"
                  id={from + "iconText"}
                  name={from + "iconText"}
                  checked={showText}
                  onChange={(e) =>
                    handleToggleChange(
                      e,
                      from === "Primary"
                        ? "showPrimaryMenuItemsText"
                        : "showSecondaryMenuItemsText"
                    )
                  }
                />
                <div className="switch-slider round"></div>
              </label>
            </div>
          </>
        )}
      </>

      {showAsGrid ? (
        <>
          {!syncWithWebsiteMenuToggleVal && (
            <>
              <div className="menusetting-toggle-div">
                <h4 className="menusetting-toggle-div-head">
                  Tile shape
                  <Tooltip message={"Choose the tile shape."} />
                </h4>

                <div className="menu-tile-shape-selectdiv">
                  <RectangularShape
                    shape={iconShape}
                    handleShape={(shape) =>
                      handleIconShape(
                        shape,
                        from === "Primary"
                          ? "primaryMenuItemsShape"
                          : "secondaryMenuItemsShape"
                      )
                    }
                  />
                  <SquareShape
                    shape={iconShape}
                    handleShape={(shape) =>
                      handleIconShape(
                        shape,
                        from === "Primary"
                          ? "primaryMenuItemsShape"
                          : "secondaryMenuItemsShape"
                      )
                    }
                  />
                  <CircleShape
                    shape={iconShape}
                    handleShape={(shape) =>
                      handleIconShape(
                        shape,
                        from === "Primary"
                          ? "primaryMenuItemsShape"
                          : "secondaryMenuItemsShape"
                      )
                    }
                  />
                </div>
              </div>

              <div className="menu-column-count">
                <h6 className="menuBuilder-column-label">
                  Columns <Tooltip message={"Specify the number of columns."} />
                </h6>
                <input
                  type="number"
                  className="menuBuilder-column-count-input"
                  min={"2"}
                  max={"4"}
                  maxLength={4}
                  minLength={2}
                  value={gridColumn}
                  onChange={(e) =>
                    handleColumn(
                      e,
                      from === "Primary"
                        ? "primaryMenuItemsGridCol"
                        : "secondaryMenuItemsGridCol"
                    )
                  }
                />
              </div>
            </>
          )}
        </>
      ) : (
        <></>
      )}

      <div className="menuBuilder-theme-icon-color">
        {!syncWithWebsiteMenuToggleVal ? (
          <div
            className={`${
              blackIconItems === totalItems && !syncWithWebsiteMenuToggleVal
                ? ""
                : "menuBuilder-theme-options-disabled"
            } menuBuilder-iconcolorUpdate-div`}
          >
            <h6 className="menuBuilder-theme-label">
              Icon color
              <Tooltip
                message={
                  "Choose a color for icons in the Menu. This feature currently only works if a solid black icon is selected from the icon selection pop up."
                }
              />
            </h6>
            <Reactcolorpicker
              colorname={iconColor}
              onChange={(color) =>
                handleColorChange(
                  color,
                  from === "Primary"
                    ? "primaryMenuItemsIconColor"
                    : "secondaryMenuItemsIconColor"
                )
              }
              classselection={"primarymenufixback"}
              valuecontrol={true}
              // presetColors={menuState.presetColors}
              pickerShouldNotOpen={blackIconItems !== totalItems}
            ></Reactcolorpicker>
          </div>
        ) : (
          <></>
        )}

        <div className="menuBuilder-iconcolorUpdate-div">
          <h6 className="menuBuilder-theme-label">
            Text color <Tooltip message={"Select the item text color."} />
          </h6>
          <Reactcolorpicker
            colorname={itemTextColor}
            onChange={(color) =>
              handleColorChange(
                color,
                from === "Primary"
                  ? "primaryMenuItemsTextColor"
                  : "secondaryMenuItemsTextColor"
              )
            }
            classselection={"primarymenufixback"}
            valuecontrol={true}
            // presetColors={menuState.presetColors}
          ></Reactcolorpicker>
        </div>
      </div>
      {showAsGrid ? (
        <>
          {!syncWithWebsiteMenuToggleVal && (
            <>
              <div
                className="menuBuilder-theme-icon-color"
                style={{ marginTop: "20px" }}
              >
                <div className="menuBuilder-iconcolorUpdate-div">
                  <h6 className="menuBuilder-theme-label">
                    Tile background color
                    <Tooltip message={"Set the tile background color."} />
                  </h6>
                  <Reactcolorpicker
                    colorname={iconBGcolor}
                    onChange={(color) =>
                      handleColorChange(
                        color,
                        from === "Primary"
                          ? "primaryMenuItemsIconBGcolor"
                          : "secondaryMenuItemsIconBGcolor"
                      )
                    }
                    classselection={"primarymenufixback"}
                    valuecontrol={false}
                  ></Reactcolorpicker>
                  {iconBGcolor ? (
                    <CrossIcon
                      handleCrossClick={() =>
                        handleColorChange(
                          "",
                          from === "Primary"
                            ? "primaryMenuItemsIconBGcolor"
                            : "secondaryMenuItemsIconBGcolor"
                        )
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
                <div className="menuBuilder-iconcolorUpdate-div">
                  <h6 className="menuBuilder-theme-label">
                    Tile border color
                    <Tooltip message={"Define the tile border color."} />
                  </h6>
                  <Reactcolorpicker
                    colorname={iconBorderColor}
                    onChange={(color) =>
                      handleColorChange(
                        color,
                        from === "Primary"
                          ? "primaryMenuItemsIconBorderColor"
                          : "secondaryMenuItemsIconBorderColor"
                      )
                    }
                    classselection={"primarymenufixback"}
                    valuecontrol={true}
                    // presetColors={menuState.presetColors}
                  ></Reactcolorpicker>
                  {iconBorderColor ? (
                    <CrossIcon
                      handleCrossClick={() =>
                        handleColorChange(
                          "",
                          from === "Primary"
                            ? "primaryMenuItemsIconBorderColor"
                            : "secondaryMenuItemsIconBorderColor"
                        )
                      }
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SubMenuSettings;
