import React, { useEffect, useRef, useState } from "react";
import "./pages.css";
import Reactcolorpicker from "../Appearance/NewReactcolorpicker";
import { FormGroup, Label, Input } from "reactstrap";
import goback from "./../../assets/images/dashboard/goback.svg";
import goforward from "./../../assets/images/dashboard/goforward.svg";
import { Carousel } from "react-responsive-carousel";
import imagebackground3 from "./../../assets/images/dashboard/cms/list-preview-banner.png";
import gallery1 from "./../../assets/images/dashboard/cms/gallery1.png";
import gallery2 from "./../../assets/images/dashboard/cms/gallery2.png";
import gallery3 from "./../../assets/images/dashboard/cms/gallery3.png";
import gallery4 from "./../../assets/images/dashboard/cms/gallery4.png";
import Tooltip from "../../Components/commonIcons/Tooltip";
import { useReducer } from "react";
import moment from "moment";
import LockedToggle from "../../Components/commonComponents/LockedToggle";
import Newfeatureicon from "../../Components/commonIcons/Newfeatureicon.js";
const PageOptions = ({
  pluginsubmit,
  from,
  GetPagesapi,
  thiss,
  optionsData,
  updatePageOption,
  pageOptions,
  previewRecurs,
  pagesData,
  savePageSettings,
  savePageSettingLoading,
  updatedAt,
  setUpdatedAt,
}) => {
  const [pageHeaderBackgroundColor, setPageHeaderBackgroundColor] =
    useState("#f6f6f6");
  const [pageTextColor, setPageTextColor] = useState("#1a1a1a");
  const [tileBackgroundColor, setTileBackgroundColor] = useState("#ffffff");
  const [showShare, setShowShare] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [imageShape, setImageShape] = useState("sharp_corner");
  const [sectionMargins, setSectionMargins] = useState({
    top: "7.5",
    bottom: "7.5",
    right: "15",
    left: "15",
  });
  const [imgCenterForListing, setImgCenterForListing] = useState(false);
  const [pageImgRatio, setPageImgRatio] = useState("4:3");
  const [activeSlide, setActiveSlide] = useState(0);

  const [showMediaFullScreen, setShowMediaFullScreen] = useState(true);
  const [showMediaThumbnails, setShowMediaThumbnails] = useState(true);
  const [enableWebView, setEnableWebView] = useState(false);
  const shareRef = useRef();

  const updateMargin = (side, value) => {
    setSectionMargins((prevMargins) => ({
      ...prevMargins,
      [side]: value,
    }));
  };
  const handleSectionMargin = (e, position) => {
    const regex = /^(\d+(\.5)?)?$|^$/; // Validate input (only numbers and .5 allowed)

    let value = e.target.value;

    const roundToNearestHalf = (_num_) => {
      if (_num_ % 1 !== 0) {
        return Math.floor(_num_) + 0.5; 
      }
      return _num_;
    };

    if (!isNaN(value) && value !== "") {
      value = parseFloat(value);

      value = roundToNearestHalf(value); 

      if (value % 1 === 0) {
        value = value.toFixed(0);
      } else {
        value = value.toFixed(1); 
      }
    }

    setActiveSlide(1);

    // Block any value greater than 30 or invalid decimal inputs
    if (value >= 0 && value <= 30 && regex.test(value)) {
      updateMargin(position, value);
      if (from === "WP/WOO") {
        optionsData.setPageData({
          [`section_margin_${position.toLowerCase()}_on_pages_detail_page`]: `${value}`,
        });
      } else {
        updatePageOption(
          `section_margin_${position.toLowerCase()}_on_pages_detail_page`,
          `${value}`
        );
      }
    } else if (position === "right" || position === "left") {
      updateMargin(position, "15");
      if (from === "WP/WOO") {
        optionsData.setPageData({
          [`section_margin_${position.toLowerCase()}_on_pages_detail_page`]:
            "15",
        });
      } else {
        updatePageOption(
          `section_margin_${position.toLowerCase()}_on_pages_detail_page`,
          "15"
        );
      }
    } else {
      updateMargin(position, "7.5");
      if (from === "WP/WOO") {
        optionsData.setPageData({
          [`section_margin_${position.toLowerCase()}_on_pages_detail_page`]:
            "7.5",
        });
      } else {
        updatePageOption(
          `section_margin_${position.toLowerCase()}_on_pages_detail_page`,
          "7.5"
        );
      }
    }
  };

  const handleSectionMarginOnBlur = (e, position) => {
    let value = e.target.value;

    setActiveSlide(1);
    if (!value && (position === "right" || position === "left")) {
      updateMargin(position, "15");
    } else if (!value && (position === "top" || position === "bottom")) {
      updateMargin(position, "7.5");
    }
  };

  const handleKeyDown = (e) => {
    // Block specific keys like 'e', '+', and '-'
    if (e.key.toLowerCase() === "e" || e.key === "+" || e.key === "-") {
      e.preventDefault(); 
    }
  };

  const handleImgCenterForListing = (e) => {
    setActiveSlide(1);
    if (e.target.checked) {
      setImgCenterForListing(true);
      if (from == "WP/WOO") {
        optionsData.setPageData({
          show_center_crop_images_on_pages_detail_page: 1,
        });
      } else {
        updatePageOption("show_center_crop_images_on_pages_detail_page", 1);
      }
    } else {
      setImgCenterForListing(false);
      if (from == "WP/WOO") {
        optionsData.setPageData({
          show_center_crop_images_on_pages_detail_page: 0,
        });
      } else {
        updatePageOption("show_center_crop_images_on_pages_detail_page", 0);
      }
    }
  };

  const handleRatioChange = (e) => {
    // if (imageShape === "soft_corner" && e !== "1:1") {
    //   setImageShape("sharp_corner");
    // }
    setActiveSlide(1);
    setPageImgRatio(e);
    if (from == "WP/WOO") {
      optionsData.setPageData({
        image_ratio_on_pages_detail_page: e,
      });
    }
  };

  const calculateHeight = (ratio, width) => {
    let height = (width * 3) / 4;
    // if (catImgShape === "circle") {
    //   height = width;
    // } else {
    switch (ratio) {
      case "4:3":
        height = (width * 3) / 4;
        break;
      case "3:4":
        height = (width * 4) / 3;
        break;
      case "1:1":
        height = (width * 1) / 1;
        break;
      case "16:9":
        height = (width * 9) / 16;
        break;
      case "9:16":
        height = (width * 16) / 9;
        break;
      // }
    }
    return height;
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Page settings | AppMySite";

    const params = {
      app_id: btoa(localStorage.getItem("Appid")),
    };

    GetPagesapi(
      params,
      thiss,
      updatePageOption,
      setPageHeaderBackgroundColor,
      setPageTextColor,
      setTileBackgroundColor,
      setShowShare,
      setShowSearch,
      setImageShape,
      setSectionMargins,
      setImgCenterForListing,
      setPageImgRatio,
      setShowMediaFullScreen,
      setShowMediaThumbnails,
      setEnableWebView,
      setUpdatedAt,
      optionsData.setPageLayout,
      from
    );
  }, []);

  return (
    <>
      {thiss?.state && thiss?.state.bodyspinner ? (
        <div
          className="generalsettingbg"
          style={{
            marginTop: "20px",
            minHeight: "80vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            style={{
              margin: "auto",
              background: "rgb(241, 242, 243,0)",
              display: "block",
              shaperendering: "auto",
              animationplaystate: "running",
              animationdelay: "0s",
            }}
            width="200px"
            height="200px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <g
              transform="translate(20 50)"
              style={{
                animationplaystate: "running",
                animationdelay: "0s",
              }}
            >
              <circle
                cx="0"
                cy="0"
                r="6"
                fill="#e6261f"
                style={{
                  animationplaystate: "running",
                  animationdelay: "0s",
                }}
              >
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.375s"
                  calcMode="spline"
                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                  values="0;1;0"
                  keyTimes="0;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                  style={{
                    animationplaystate: "running",
                    animationdelay: "0s",
                  }}
                ></animateTransform>
              </circle>
            </g>
            <g
              transform="translate(40 50)"
              style={{
                animationplaystate: "running",
                animationdelay: "0s",
              }}
            >
              <circle
                cx="0"
                cy="0"
                r="6"
                fill="#f7d038"
                style={{
                  animationplaystate: "running",
                  animationdelay: "0s",
                }}
              >
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.25s"
                  calcMode="spline"
                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                  values="0;1;0"
                  keyTimes="0;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                  style={{
                    animationplaystate: "running",
                    animationdelay: "0s",
                  }}
                ></animateTransform>
              </circle>
            </g>
            <g
              transform="translate(60 50)"
              style={{
                animationplaystate: "running",
                animationdelay: "0s",
              }}
            >
              <circle
                cx="0"
                cy="0"
                r="6"
                fill="#49da9a"
                style={{
                  animationplaystate: "running",
                  animationdelay: "0s",
                }}
              >
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="-0.125s"
                  calcMode="spline"
                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                  values="0;1;0"
                  keyTimes="0;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                  style={{
                    animationplaystate: "running",
                    animationdelay: "0s",
                  }}
                ></animateTransform>
              </circle>
            </g>
            <g
              transform="translate(80 50)"
              style={{
                animationplaystate: "running",
                animationdelay: "0s",
              }}
            >
              <circle
                cx="0"
                cy="0"
                r="6"
                fill="#4355db"
                style={{
                  animationplaystate: "running",
                  animationdelay: "0s",
                }}
              >
                <animateTransform
                  attributeName="transform"
                  type="scale"
                  begin="0s"
                  calcMode="spline"
                  keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
                  values="0;1;0"
                  keyTimes="0;0.5;1"
                  dur="1s"
                  repeatCount="indefinite"
                  style={{
                    animationplaystate: "running",
                    animationdelay: "0s",
                  }}
                ></animateTransform>
              </circle>
            </g>
          </svg>
        </div>
      ) : (
        <div>
          {/* Pages Layout Selection Starts */}
          {optionsData?.enablePageLayout && (
            <div className="pages-layout">
              <div className="page-layout-header">
                <h2 className="page-layout-header-heading">Layout</h2>
                <p className="page-layout-header-subheading">
                  Choose a template to style the appearance of pages in your
                  app.
                </p>
              </div>

              <FormGroup tag="fieldset" className="select-page-type">
                <FormGroup>
                  <Label className="pagetype-ratio">
                    <Input
                      checked={optionsData.pageLayout == "layout-0"}
                      onChange={() => optionsData.setPageLayout("layout-0")}
                      type="radio"
                      name="addblockradioCat1"
                    />

                    <div className="page-layout">
                      <img
                        className="img-fluid"
                        src={
                          require("../../assets/images/dashboard/page-layout-one.png")
                            .default
                        }
                      />
                    </div>
                  </Label>
                </FormGroup>

                <FormGroup>
                  <Label className="pagetype-ratio">
                    <Input
                      type="radio"
                      name="addblockradioCat2"
                      checked={optionsData.pageLayout == "layout-1"}
                      onChange={() => optionsData.setPageLayout("layout-1")}
                    />
                    <div className="page-layout">
                      <img
                        className="img-fluid"
                        src={
                          require("../../assets/images/dashboard/page-layout-two.png")
                            .default
                        }
                      />
                    </div>
                  </Label>
                </FormGroup>
              </FormGroup>
            </div>
          )}
          {/* Pages Layout Selection Ends */}

          {/* Pages Settings Starts */}
          {optionsData?.enableGeneralSettings && (
            <div className="pages-settings-section">
              <div className="pages-settings">
                <div className="pages-settings-left">
                  <div className="page-setting-header">
                    <h2 className="page-setting-header-heading">Settings</h2>
                    <p className="page-setting-header-subheading">
                      {parseInt(localStorage.getItem("website_technology")) == 3
                        ? "Customize the appearance of your app page."
                        : parseInt(
                            localStorage.getItem("website_technology")
                          ) == 4
                        ? "Fine-tune your app's page settings here."
                        : "Customize and organize your pages."}
                    </p>
                  </div>

                  <div className="page-setting-body">
                    <h4 className="page-setting-body-heading">Page list</h4>
                    {/* 
                    {localStorage.getItem("website_technology") == 4 ||
                    localStorage.getItem("website_technology") == 5 ?
                    <div className="page-setting-toggle page-setting-top-30">
                      <h6 className="page-setting-toggle-name">
                        Show search icon
                        <Tooltip
                          message={
                            "Enable to display a search icon in header for quicker access to required content."
                          }
                        />
                      </h6>
                      <label class="switch">
                        <input
                          type="checkbox"
                          checked={showSearch}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setShowSearch(isChecked);

                            if (isChecked && from === "WP/WOO") {
                              optionsData.setPageData({
                                show_search_icon_on_pages_listing_page: 1,
                              });
                            } else if (from === "WP/WOO") {
                              optionsData.setPageData({
                                show_search_icon_on_pages_listing_page: 0,
                              });
                            } else if (isChecked && from === "Custom") {
                              updatePageOption(
                                "show_search_icon_on_pages_listing_page",
                                1
                              );
                            } else {
                              updatePageOption(
                                "show_search_icon_on_pages_listing_page",
                                0
                              );
                            }

                            setActiveSlide(0);
                          }}
                        />
                        <div class="slider round"></div>
                      </label>
                    </div>:<></>} */}

                    <div className="row page-setting-top-30">
                      <div className="col-md-6">
                        <h6 className="page-setting-toggle-name">
                          Background color
                          <Tooltip
                            message={"Set the background color of the page."}
                          />
                          {/* <Newfeatureicon/> */}
                        </h6>
                        <div
                          onClick={() => {
                            setActiveSlide(0);
                          }}
                          className="colorpicker-input page-setting-top-10"
                        >
                          <Reactcolorpicker
                            colorname={pageHeaderBackgroundColor}
                            onChange={(e) => {
                              // Get the value from the input event
                              setPageHeaderBackgroundColor(e);
                              if (from === "WP/WOO") {
                                optionsData.setPageData({
                                  background_color_on_pages_listing_page: e,
                                });
                              } else {
                                updatePageOption(
                                  "background_color_on_pages_listing_page",
                                  e
                                );
                              }
                            }}
                            classselection={"fixback new-overlay-mobile"}
                            valuecontrol={false}
                          />

                          {/* {pageHeaderBackgroundColor == "#f6f6f6" ? "" :
                         <img
                            //  onClick={(e) => {
                            //   // Get the value from the input event
                            //   setPageHeaderBackgroundColor("#f6f6f6");
                            //   if (from === "WP/WOO") {
                            //     optionsData.setPageData({
                            //       background_color_on_pages_listing_page: "#f6f6f6",
                            //     });
                            //   } else {
                            //     updatePageOption(
                            //       "background_color_on_pages_listing_page",
                            //       "#f6f6f6"
                            //     );
                            //   }
                            // }}
                            className="colorpicker-input-cross"
                            src={
                              require("../../assets/images/dashboard/colorcross-icon.svg")
                                .default
                            }
                          />
                           }   */}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <h6 className="page-setting-toggle-name">
                          Text color{" "}
                          <Tooltip message={"Select the item text color."} />
                          {/* <Newfeatureicon/> */}
                        </h6>
                        <div
                          onClick={() => {
                            setActiveSlide(0);
                          }}
                          className="colorpicker-input page-setting-top-10"
                        >
                          <Reactcolorpicker
                            colorname={pageTextColor}
                            onChange={(e) => {
                              const newColor = e; // Get the input value
                              setPageTextColor(newColor);
                              if (from === "WP/WOO") {
                                optionsData.setPageData({
                                  text_color_on_pages_listing_page: newColor,
                                });
                              } else {
                                updatePageOption(
                                  "text_color_on_pages_listing_page",
                                  e
                                );
                              }
                            }}
                            classselection={"fixback new-overlay-mobile"}
                            valuecontrol={true}
                          />

                          {/* <img
                            className="colorpicker-input-cross"
                            src={
                              require("../../assets/images/dashboard/colorcross-icon.svg")
                                .default
                            }
                          /> */}
                        </div>
                      </div>
                    </div>

                    {optionsData.pageLayout == "layout-1" && (
                      <div className="row page-setting-top-20">
                        <div className="col-md-6">
                          <h6 className="page-setting-toggle-name">
                            Tile background color
                            <Tooltip
                              message={"Set the tile background color."}
                            />
                            {/* <Newfeatureicon/> */}
                          </h6>
                          <div
                            onClick={() => {}}
                            className="colorpicker-input page-setting-top-10"
                          >
                            <Reactcolorpicker
                              colorname={tileBackgroundColor}
                              onChange={(e) => {
                                setActiveSlide(0);
                                setTileBackgroundColor(e);
                                if (from === "WP/WOO") {
                                  optionsData.setPageData({
                                    tile_background_color_on_pages_listing_page:
                                      e,
                                  });
                                } else {
                                  updatePageOption(
                                    "tile_background_color_on_pages_listing_page",
                                    e
                                  );
                                }
                              }}
                              classselection={"fixback new-overlay-mobile"}
                              valuecontrol={false}
                            />

                            {/* <img
                              className="colorpicker-input-cross"
                              src={
                                require("../../assets/images/dashboard/colorcross-icon.svg")
                                  .default
                              }
                            /> */}
                          </div>
                        </div>
                      </div>
                    )}

                    <hr className="page-setting-seprator page-setting-top-30" />

                    <h4 className="page-setting-body-heading page-setting-top-30">
                      Page details
                    </h4>

                    {localStorage.getItem("website_technology") == 4 ||
                    localStorage.getItem("website_technology") == 5 ? (
                      <div className="page-setting-toggle page-setting-top-30">
                        <h6 className="page-setting-toggle-name">
                          Render as web view
                          <Tooltip
                            message={
                              "Render the entire page exactly as it appears on your mobile browser."
                            }
                          />
                        </h6>
                        <label class="switch">
                          <input
                            checked={enableWebView}
                            onChange={(e) => {
                              setActiveSlide(1);
                              setEnableWebView(e.target.checked);
                              e.target.checked
                                ? optionsData.setRenderWebView(true)
                                : optionsData.setRenderWebView(false) &&
                                  setActiveSlide(1);
                            }}
                            type="checkbox"
                          />
                          <div class="slider round"></div>
                        </label>
                      </div>
                    ) : (
                      ""
                    )}
                    {localStorage.getItem("website_technology") == 4 ||
                    localStorage.getItem("website_technology") == 5 ? (
                      <div className="page-setting-toggle page-setting-top-30">
                        <h6 className="page-setting-toggle-name">
                          Share{" "}
                          <Tooltip
                            message={
                              " Enable to display a share icon for quick access to sharing options."
                            }
                          />
                          {/* <Newfeatureicon/> */}
                        </h6>
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={showShare}
                            onChange={(e) => {
                              if (enableWebView && from === "WP/WOO") {
                                return null;
                              } else {
                                setShowShare(e.target.checked);
                                setActiveSlide(1);
                                if (e.target.checked && from === "WP/WOO") {
                                  optionsData.setPageData({
                                    enable_share_feature_on_pages_detail_page: 1,
                                  });
                                } else if (from === "WP/WOO") {
                                  optionsData.setPageData({
                                    enable_share_feature_on_pages_detail_page: 0,
                                  });
                                } else if (e.target.checked) {
                                  updatePageOption(
                                    "enable_share_feature_on_pages_detail_page",
                                    1
                                  );
                                } else {
                                  updatePageOption(
                                    "enable_share_feature_on_pages_detail_page",
                                    0
                                  );
                                }
                                // if (!window.matchMedia("(max-width: 1200px)").matches) {
                                //   setTimeout(() => {
                                //     const itemElement = shareRef.current;
                                //     if (itemElement) {
                                //       itemElement.scrollIntoView({ behavior: "smooth", block: "start" });
                                //     }
                                //   });
                                // }
                              }
                            }}
                          />

                          {enableWebView ? (
                            <LockedToggle />
                          ) : (
                            <div class="slider round"></div>
                          )}
                        </label>
                      </div>
                    ) : (
                      ""
                    )}

                    {localStorage.getItem("website_technology") == 3 && (
                      <div className="page-setting-toggle page-setting-top-30">
                        <h6 className="page-setting-toggle-name">
                          Show media in full screen
                          <Tooltip
                            message={
                              "Enable to display media in full-screen mode for a detailed view."
                            }
                          />
                          {/* <Newfeatureicon/> */}
                        </h6>
                        <label class="switch">
                          <input
                            type="checkbox"
                            checked={showMediaFullScreen}
                            onChange={(e) => {
                              setShowMediaFullScreen(e.target.checked);
                              !showMediaFullScreen
                                ? setActiveSlide(2)
                                : setActiveSlide(1);
                              if (e.target.checked && from === "WP/WOO") {
                                optionsData.setPageData({
                                  show_media_in_full_screen_on_pages_detail_page: 1,
                                });
                              } else if (from === "WP/WOO") {
                                optionsData.setPageData({
                                  show_media_in_full_screen_on_pages_detail_page: 0,
                                });
                              } else if (
                                e.target.checked &&
                                from === "Custom"
                              ) {
                                updatePageOption(
                                  "show_media_in_full_screen_on_pages_detail_page",
                                  1
                                );
                              } else {
                                updatePageOption(
                                  "show_media_in_full_screen_on_pages_detail_page",
                                  0
                                );
                              }
                            }}
                          />
                          <div class="slider round"></div>
                        </label>
                      </div>
                    )}
                    {localStorage.getItem("website_technology") == 3 &&
                      showMediaFullScreen && (
                        <div className="page-setting-toggle page-setting-top-30">
                          <h6 className="page-setting-toggle-name">
                            Show media as thumbnails
                            <Tooltip
                              message={
                                "Enable to display media content as thumbnails."
                              }
                            />
                            {/* <Newfeatureicon/> */}
                          </h6>
                          <label class="switch">
                            <input
                              type="checkbox"
                              checked={showMediaThumbnails}
                              onChange={(e) => {
                                setShowMediaThumbnails(e.target.checked);
                                setActiveSlide(2);
                                if (e.target.checked && from === "WP/WOO") {
                                  optionsData.setPageData({
                                    show_media_as_thumbnail_on_pages_detail_page: 1,
                                  });
                                } else if (from === "WP/WOO") {
                                  optionsData.setPageData({
                                    show_media_as_thumbnail_on_pages_detail_page: 0,
                                  });
                                } else if (
                                  e.target.checked &&
                                  from === "Custom"
                                ) {
                                  updatePageOption(
                                    "show_media_as_thumbnail_on_pages_detail_page",
                                    1
                                  );
                                } else {
                                  updatePageOption(
                                    "show_media_as_thumbnail_on_pages_detail_page",
                                    0
                                  );
                                }
                              }}
                            />
                            <div class="slider round"></div>
                          </label>
                        </div>
                      )}
                    <h6 className="page-setting-toggle-name page-setting-top-30">
                      Section margins (pixels)
                      <Tooltip
                        message={
                          "Adjust the spacing around your section for a polished look."
                        }
                      />
                      {/* <Newfeatureicon/> */}
                    </h6>

                    <div
                      className={
                        enableWebView
                          ? "set-page-margin post-section-margin-disable"
                          : "set-page-margin"
                      }
                    >
                      <div class="page-section-margin-first page-setting-top-10">
                        <div>
                          <input
                            class="page-section-margin-first-input"
                            type="number"
                            value={sectionMargins.top}
                            min="0"
                            max="30"
                            step="0.5"
                            minLength={0}
                            maxLength={30}
                            onKeyDown={handleKeyDown}
                            onChange={(e) => {
                              handleSectionMargin(e, "top");
                            }}
                            onBlur={(e) => {
                              handleSectionMarginOnBlur(e, "top");
                            }}
                            style={{
                              cursor: enableWebView ? "not-allowed" : "",
                            }}
                            disabled={enableWebView}
                          />

                          <h4 className="page-section-margin-value">Top</h4>
                        </div>
                        <div>
                          <input
                            class="page-section-margin-first-input"
                            type="number"
                            value={sectionMargins.bottom}
                            min={"0"}
                            max={"30"}
                            minLength={0}
                            maxLength={30}
                            step="0.5"
                            onKeyDown={handleKeyDown}
                            onChange={(e) => {
                              handleSectionMargin(e, "bottom");
                            }}
                            onBlur={(e) => {
                              handleSectionMarginOnBlur(e, "bottom");
                            }}
                            style={{
                              cursor: enableWebView ? "not-allowed" : "",
                            }}
                            disabled={enableWebView}
                          />
                          <h4 className="page-section-margin-value">Bottom</h4>
                        </div>
                      </div>

                      <div
                        style={{ cursor: enableWebView ? "not-allowed" : "" }}
                        className="page-section-margin-second page-setting-top-10"
                      >
                        <div>
                          <input
                            className="page-section-margin-second-input"
                            type="number"
                            value={sectionMargins.left}
                            min={"0"}
                            max={"30"}
                            minLength={0}
                            maxLength={30}
                            step="0.5"
                            onKeyDown={handleKeyDown}
                            onChange={(e) => {
                              handleSectionMargin(e, "left");
                            }}
                            onBlur={(e) => {
                              handleSectionMarginOnBlur(e, "left");
                            }}
                            style={{
                              cursor: enableWebView ? "not-allowed" : "",
                            }}
                            disabled={enableWebView}
                          />
                          <h4 className="page-section-margin-value">Left</h4>
                        </div>
                        <div style={{ marginRight: "0px" }}>
                          <input
                            className="page-section-margin-second-input"
                            type="number"
                            value={sectionMargins.right}
                            min={"0"}
                            max={"30"}
                            minLength={0}
                            maxLength={30}
                            step="0.5"
                            onKeyDown={handleKeyDown}
                            onChange={(e) => {
                              handleSectionMargin(e, "right");
                            }}
                            onBlur={(e) => {
                              handleSectionMarginOnBlur(e, "right");
                            }}
                            style={{
                              cursor: enableWebView ? "not-allowed" : "",
                            }}
                            disabled={enableWebView}
                          />
                          <h4 className="page-section-margin-value">Right</h4>
                        </div>
                      </div>
                    </div>

                    {/* {localStorage.getItem("website_technology") == 4 ||
                    localStorage.getItem("website_technology") == 5 ? (
                      <div className="pages-image-shape page-setting-top-30">
                        <h6 className="page-setting-toggle-name">
                          Image ratio
                          <Tooltip
                            message={
                              "Define the image aspect ratio for your posts. Select a suitable image ratio corresponding to your image."
                            }
                          />
                        </h6>
                        <div className="post-box-size">
                          <div className="common-shape-tooltip-box">
                            <p onClick={() => handleRatioChange("16:9")}>
                              <svg
                                className={
                                  pageImgRatio == "16:9"
                                    ? "post-shapeselect"
                                    : "post-shapenotselect"
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="14"
                                viewBox="0 0 24 14"
                              >
                                <g
                                  id="Path_118621"
                                  data-name="Path 118621"
                                  fill="#fff"
                                >
                                  <path
                                    d="M 23 13 L 19.25 13 L 1 13 L 1 1 L 23 1 L 23 13 Z"
                                    stroke="none"
                                  />
                                  <path
                                    className={
                                      pageImgRatio == "16:9"
                                        ? "post-shapeselect"
                                        : "post-shapenotselect"
                                    }
                                    d="M 2 2 L 2 12 L 22 12 L 22 2 L 2 2 M 0 0 L 24 0 L 24 14 L 0 14 L 0 0 Z"
                                    stroke="none"
                                    fill=""
                                  />
                                </g>
                              </svg>
                              <span>16:9</span>
                            </p>
                            <span className="common-shape-tooltip">
                              Select for a widescreen image format, ideal for
                              panoramic views and cinematic visuals.
                            </span>
                          </div>
                          <div className="common-shape-tooltip-box">
                            <p onClick={() => handleRatioChange("4:3")}>
                              <svg
                                className={
                                  pageImgRatio == "4:3"
                                    ? "post-shapeselect"
                                    : "post-shapenotselect"
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="15"
                                viewBox="0 0 20 15"
                              >
                                <g
                                  id="Rectangle_18739"
                                  data-name="Rectangle 18739"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                >
                                  <rect width="20" height="15" stroke="none" />
                                  <rect
                                    x="1"
                                    y="1"
                                    width="18"
                                    height="13"
                                    fill="none"
                                  />
                                </g>
                              </svg>
                              <span>4:3</span>
                            </p>
                            <span className="common-shape-tooltip">
                              Opt to maintain a classic image aspect ratio,
                              suitable for balanced compositions.
                            </span>
                          </div>
                          <div className="common-shape-tooltip-box">
                            <p onClick={() => handleRatioChange("1:1")}>
                              <svg
                                className={
                                  pageImgRatio == "1:1"
                                    ? "post-shapeselect"
                                    : "post-shapenotselect"
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                              >
                                <g
                                  id="Rectangle_18739"
                                  data-name="Rectangle 18739"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                >
                                  <rect width="18" height="18" stroke="none" />
                                  <rect
                                    x="1"
                                    y="1"
                                    width="16"
                                    height="16"
                                    fill="none"
                                  />
                                </g>
                              </svg>
                              <span className="product-ratio-select">1:1</span>
                            </p>
                            <span className="common-shape-tooltip">
                              Choose for a square image format, great for
                              symmetrical and uniform visuals.
                            </span>
                          </div>
                          <div className="common-shape-tooltip-box">
                            <p onClick={() => handleRatioChange("3:4")}>
                              <svg
                                className={
                                  pageImgRatio == "3:4"
                                    ? "post-shapeselect"
                                    : "post-shapenotselect"
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="15"
                                height="20"
                                viewBox="0 0 15 20"
                              >
                                <g
                                  id="Rectangle_18739"
                                  data-name="Rectangle 18739"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                >
                                  <rect width="15" height="20" stroke="none" />
                                  <rect
                                    x="1"
                                    y="1"
                                    width="13"
                                    height="18"
                                    fill="none"
                                  />
                                </g>
                              </svg>
                              <span>3:4</span>
                            </p>
                            <span className="common-shape-tooltip">
                              Pick for a portrait-oriented image format, perfect
                              for capturing vertical scenes and portraits.
                            </span>
                          </div>
                          <div className="common-shape-tooltip-box">
                            <p onClick={() => handleRatioChange("9:16")}>
                              <svg
                                className={
                                  pageImgRatio == "9:16"
                                    ? "post-shapeselect"
                                    : "post-shapenotselect"
                                }
                                xmlns="http://www.w3.org/2000/svg"
                                width="14"
                                height="24"
                                viewBox="0 0 14 24"
                              >
                                <g
                                  id="Rectangle_31455"
                                  data-name="Rectangle 31455"
                                  transform="translate(14) rotate(90)"
                                  fill="#fff"
                                  stroke=""
                                  stroke-width="2"
                                >
                                  <rect width="24" height="14" stroke="none" />
                                  <rect
                                    x="1"
                                    y="1"
                                    width="22"
                                    height="12"
                                    fill="none"
                                  />
                                </g>
                              </svg>
                              <span>9:16</span>
                            </p>
                            <span className="common-shape-tooltip">
                              {" "}
                              Opt for a vertical image format perfect for
                              capturing tall scenes and portrait-oriented
                              visuals.
                            </span>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )} */}

                    {localStorage.getItem("website_technology") == 3 && (
                      <div className="pages-image-shape page-setting-top-30">
                        <h6 className="page-setting-toggle-name">
                          Image shape{" "}
                          <Tooltip
                            message={
                              "Define the preferred shape of your post images."
                            }
                          />
                          {/* <Newfeatureicon/> */}
                        </h6>
                        <div className="shape-of-post">
                          <svg
                            onClick={() => {
                              setActiveSlide(1);
                              setImageShape("sharp_corner");
                              if (from === "WP/WOO") {
                                optionsData.setPageData({
                                  image_shape_on_pages_detail_page:
                                    "sharp_corner",
                                });
                              } else {
                                updatePageOption(
                                  "image_shape_on_pages_detail_page",
                                  "sharp_corner"
                                );
                              }
                            }}
                            className={
                              imageShape == "sharp_corner"
                                ? "post-shapeselect"
                                : "post-shapenotselect"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                          >
                            <g
                              id="Rectangle_18739"
                              data-name="Rectangle 18739"
                              fill="#fff"
                              stroke=""
                              stroke-width="2"
                            >
                              <rect width="18" height="18" stroke="none" />
                              <rect
                                x="1"
                                y="1"
                                width="16"
                                height="16"
                                fill="none"
                              />
                            </g>
                          </svg>
                          <svg
                            onClick={() => {
                              setActiveSlide(1);
                              setImageShape("soft_corner");
                              if (from === "WP/WOO") {
                                optionsData.setPageData({
                                  image_shape_on_pages_detail_page:
                                    "soft_corner",
                                });
                              } else {
                                updatePageOption(
                                  "image_shape_on_pages_detail_page",
                                  "soft_corner"
                                );
                              }
                            }}
                            className={
                              imageShape == "soft_corner"
                                ? "post-shapeselect"
                                : "post-shapenotselect"
                            }
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                          >
                            <g
                              id="Rectangle_18739"
                              data-name="Rectangle 18739"
                              fill="#fff"
                              stroke=""
                              stroke-width="2"
                            >
                              <rect
                                width="18"
                                height="18"
                                rx="5"
                                stroke="none"
                              />
                              <rect
                                x="1"
                                y="1"
                                width="16"
                                height="16"
                                rx="4"
                                fill="none"
                              />
                            </g>
                          </svg>
                        </div>
                      </div>
                    )}

                    {localStorage.getItem("website_technology") == 3 && (
                      <div className="page-setting-toggle page-setting-top-30">
                        <h6 className="page-setting-toggle-name">
                          Center crop images
                          <Tooltip
                            message={
                              "When enabled, your images will be centered within the image frame, for a consistent look. When disabled, images will be displayed to fit the frame while maintaining their original aspect ratios."
                            }
                          />
                           {/* <Newfeatureicon/> */}
                        </h6>
                        <label class="switch" for="imgCenterForListing">
                          <input
                            type="checkbox"
                            id="imgCenterForListing"
                            checked={imgCenterForListing}
                            onChange={(e) => {
                              handleImgCenterForListing(e);
                            }}
                          />
                          <div class="slider round"></div>
                        </label>
                      </div>
                    )}
                  </div>
                </div>

                <div className="pages-settings-right">
                  <div className="login-mob-previewtop">
                    <div
                      onClick={() => {
                        if (activeSlide > 0) {
                          setActiveSlide(activeSlide - 1);
                        }
                      }}
                      className={
                        activeSlide == 0
                          ? "login-mob-previewtopback login-mob-previewtopbackopacity"
                          : "login-mob-previewtopback"
                      }
                    >
                      <img src={goback} />
                    </div>
                    <div
                      onClick={() => {
                        if (activeSlide < 1 && !showMediaFullScreen) {
                          setActiveSlide(activeSlide + 1);
                        } else if (activeSlide < 2 && showMediaFullScreen) {
                          setActiveSlide(activeSlide + 1);
                        }
                      }}
                      className={
                        (activeSlide == 1 && !showMediaFullScreen) ||
                        (activeSlide == 2 && showMediaFullScreen)
                          ? "login-mob-previewtopnext mob-previewtopnextdiv login-mob-previewtopbackopacity"
                          : "login-mob-previewtopnext mob-previewtopnextdiv"
                      }
                    >
                      <img src={goforward} />
                    </div>
                  </div>

                  <div className="page-settings-carousel">
                    <div className="pages-setting-preview">
                      <Carousel
                        selectedItem={activeSlide}
                        showArrows={false}
                        swipeable={true}
                        showThumbs={false}
                        showIndicators={false}
                        showStatus={false}
                      >
                        <div
                          style={{
                            background: pageHeaderBackgroundColor,
                            height: "659px",
                            width: "300px",
                          }}
                        >
                          <div className="pages-setting-preview-header">
                            <div className="pages-setting-header-left">
                              <img
                                className="img-fluid"
                                src={
                                  require("../../assets/images/dashboard/post-back.svg")
                                    .default
                                }
                              />
                              <h3 className="pages-setting-header-heading">
                                Pages
                              </h3>
                            </div>
                            <div className="pages-setting-header-right">
                              {showSearch && (
                                <img
                                  className="img-fluid"
                                  src={
                                    require("../../assets/images/dashboard/search-icon.svg")
                                      .default
                                  }
                                />
                              )}
                            </div>
                          </div>
                          <div>
                            {from == "WP/WOO" ? (
                              <>
                                {optionsData.pageLayout == "layout-1" && (
                                  <div className="pages-setting-preview-layout-one">
                                    {optionsData?.pageState.map(
                                      (item, index) => (
                                        <div
                                          style={{
                                            background: tileBackgroundColor,
                                          }}
                                          className="pages-preview-card"
                                          key={index}
                                        >
                                          <p
                                            style={{ color: pageTextColor }}
                                            className="card-type-menu"
                                          >
                                            {item?.item_name}
                                          </p>
                                          {item?.children.length >= 1 &&
                                          localStorage.getItem(
                                            "website_technology"
                                          ) == 3 ? (
                                            <img
                                              className="img-fluid"
                                              src={
                                                require("../../assets/images/dashboard/back-arrow.svg")
                                                  .default
                                              }
                                            />
                                          ) : index == 1 ||
                                            index == 2 ||
                                            index == 4 ? (
                                            <img
                                              className="img-fluid"
                                              src={
                                                require("../../assets/images/dashboard/back-arrow.svg")
                                                  .default
                                              }
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                                {optionsData.pageLayout == "layout-0" && (
                                  <div className="pages-setting-preview-layout-two">
                                    {optionsData?.pageState.map(
                                      (item, index) => (
                                        <div className="pages-preview-type-list">
                                          <p
                                            style={{ color: pageTextColor }}
                                            className="list-type-menu"
                                          >
                                            {item?.item_name}
                                          </p>
                                          {item?.children.length >= 1 &&
                                          localStorage.getItem(
                                            "website_technology"
                                          ) == 3 ? (
                                            <img
                                              className="img-fluid"
                                              src={
                                                require("../../assets/images/dashboard/back-arrow.svg")
                                                  .default
                                              }
                                            />
                                          ) : index == 1 ||
                                            index == 2 ||
                                            index == 4 ? (
                                            <img
                                              className="img-fluid"
                                              src={
                                                require("../../assets/images/dashboard/back-arrow.svg")
                                                  .default
                                              }
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      )
                                    )}
                                  </div>
                                )}
                              </>
                            ) : (
                              <div className="page-listing-body">
                                {previewRecurs(
                                  pagesData,
                                  "parent",
                                  "pageOptions"
                                )}
                              </div>
                            )}
                          </div>
                        </div>

                        {enableWebView == false ? (
                          <div className="pages-detail-preview">
                            <div className="pages-setting-preview-header">
                              <div className="pages-setting-header-left">
                                <img
                                  className="img-fluid"
                                  src={
                                    require("../../assets/images/dashboard/post-back.svg")
                                      .default
                                  }
                                />
                                <h3 className="pages-setting-header-heading">
                                  Page details
                                </h3>
                              </div>
                              <div
                                ref={shareRef}
                                className="pages-setting-header-right"
                              >
                                {showShare && (
                                  <img
                                    className="img-fluid"
                                    src={
                                      require("../../assets/images/dashboard/share-icon.svg")
                                        .default
                                    }
                                  />
                                )}
                              </div>
                            </div>

                            <div
                              // style={{
                              //   margin: `0px ${sectionMargins.right + "px"} 0px ${
                              //     sectionMargins.left + "px"
                              //   }`,
                              //   padding: `${sectionMargins.top + "px"} 0px ${
                              //     sectionMargins.bottom + "px"
                              //   } 0px`,
                              // }}
                              className="pages-detail-preview-body"
                            >
                              <div
                                style={{
                                  margin: `0px ${
                                    sectionMargins.right + "px"
                                  } 0px ${sectionMargins.left + "px"}`,
                                  padding: `${sectionMargins.top + "px"} 0px ${
                                    sectionMargins.bottom + "px"
                                  } 0px`,
                                }}
                              >
                                <div>
                                  <h3 className="pages-detail-preview-heading">
                                    Welcome to London
                                  </h3>
                                </div>

                                <div
                                  style={{
                                    padding: `${
                                      sectionMargins.top + "px"
                                    } 0px ${sectionMargins.bottom + "px"} 0px`,
                                  }}
                                >
                                  <p className="pages-detail-preview-text">
                                    One of the world's most visited cities,
                                    London has something for everyone: from
                                    history and culture to fine food and good
                                    times.
                                  </p>
                                </div>
                              </div>

                              <div
                                style={{
                                  margin: `0px ${
                                    sectionMargins.right + "px"
                                  } 0px ${sectionMargins.left + "px"}`,
                                  padding: `${sectionMargins.top + "px"} 0px ${
                                    sectionMargins.bottom + "px"
                                  } 0px`,
                                }}
                              >
                                <div
                                  className="pages-setting-preview-bssanner"
                                  style={{
                                    backgroundImage:
                                      "url(" + imagebackground3 + ")",
                                    backgroundSize: imgCenterForListing
                                      ? "cover"
                                      : "contain",
                                    borderRadius:
                                      imageShape == "soft_corner"
                                        ? "6px"
                                        : "0px",
                                    height: calculateHeight(pageImgRatio, 274),
                                    width: "100%",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                    // height: "264px",
                                  }}
                                ></div>
                              </div>

                              <div
                                style={{
                                  margin: `0px ${
                                    sectionMargins.right + "px"
                                  } 0px ${sectionMargins.left + "px"}`,
                                  padding: `${sectionMargins.top + "px"} 0px ${
                                    sectionMargins.bottom + "px"
                                  } 0px`,
                                }}
                              >
                                <p className="pages-detail-preview-text">
                                  Immersed in history, London's rich seams of
                                  eye-opening antiquity are everywhere. The
                                  city's buildings are striking milestones in a
                                  unique and beguiling biography, and a great
                                  many of them – the Tower of London,
                                  Westminster Abbey, Big Ben – are instantly
                                  recognisable landmarks.
                                </p>
                              </div>

                              <div
                                style={{
                                  margin: `0px ${
                                    sectionMargins.right + "px"
                                  } 0px ${sectionMargins.left + "px"}`,
                                  padding: `${sectionMargins.top + "px"} 0px ${
                                    sectionMargins.bottom + "px"
                                  } 0px`,
                                }}
                              >
                                <div className="pages-setting-preview-gallery">
                                  <div
                                    className="pages-setting-preview-gallery-img"
                                    style={{
                                      backgroundImage: "url(" + gallery1 + ")",
                                      height: calculateHeight(
                                        pageImgRatio,
                                        129
                                      ),
                                      backgroundSize: imgCenterForListing
                                        ? "cover"
                                        : "contain",

                                      borderRadius:
                                        imageShape == "soft_corner"
                                          ? "6px"
                                          : "0px",
                                    }}
                                  ></div>
                                  <div
                                    className="pages-setting-preview-gallery-img"
                                    style={{
                                      backgroundImage: "url(" + gallery2 + ")",
                                      height: calculateHeight(
                                        pageImgRatio,
                                        129
                                      ),
                                      backgroundSize: imgCenterForListing
                                        ? "cover"
                                        : "contain",

                                      borderRadius:
                                        imageShape == "soft_corner"
                                          ? "6px"
                                          : "0px",
                                    }}
                                  ></div>
                                  <div
                                    className="pages-setting-preview-gallery-img"
                                    style={{
                                      backgroundImage: "url(" + gallery3 + ")",
                                      height: calculateHeight(
                                        pageImgRatio,
                                        129
                                      ),
                                      backgroundSize: imgCenterForListing
                                        ? "cover"
                                        : "contain",

                                      borderRadius:
                                        imageShape == "soft_corner"
                                          ? "6px"
                                          : "0px",
                                    }}
                                  ></div>
                                  <div
                                    className="pages-setting-preview-gallery-img"
                                    style={{
                                      backgroundImage: "url(" + gallery4 + ")",
                                      height: calculateHeight(
                                        pageImgRatio,
                                        129
                                      ),
                                      backgroundSize: imgCenterForListing
                                        ? "cover"
                                        : "contain",

                                      borderRadius:
                                        imageShape == "soft_corner"
                                          ? "6px"
                                          : "0px",
                                    }}
                                  ></div>
                                </div>
                              </div>

                              <div
                                style={{
                                  margin: `0px ${
                                    sectionMargins.right + "px"
                                  } 0px ${sectionMargins.left + "px"}`,
                                  padding: `${sectionMargins.top + "px"} 0px ${
                                    sectionMargins.bottom + "px"
                                  } 0px`,
                                }}
                              >
                                <div className="pages-detail-preview-seprator"></div>
                              </div>
                              <div
                                style={{
                                  margin: `0px ${
                                    sectionMargins.right + "px"
                                  } 0px ${sectionMargins.left + "px"}`,
                                  padding: `${sectionMargins.top + "px"} 0px ${
                                    sectionMargins.bottom + "px"
                                  } 0px`,
                                }}
                              >
                                <div>
                                  <h3 className="pages-detail-preview-heading">
                                    Please don't use circular dependencies in
                                    spring boot projects
                                  </h3>
                                </div>

                                <div
                                  style={{
                                    padding: `${
                                      sectionMargins.top + "px"
                                    } 0px ${sectionMargins.bottom + "px"} 0px`,
                                  }}
                                >
                                  <div className="pages-detail-preview-video">
                                    <img
                                      className="img-fluid pages-detail-preview-code"
                                      src={
                                        require("../../assets/images/dashboard/youtube-icon.png")
                                          .default
                                      }
                                    />
                                  </div>
                                </div>

                                <div
                                  style={{
                                    padding: `${
                                      sectionMargins.top + "px"
                                    } 0px ${sectionMargins.bottom + "px"} 0px`,
                                  }}
                                >
                                  <p className="pages-detail-preview-text">
                                    Yellow light wash 5-pocket mid-rise cropped
                                    jeans, clean look with no fade, has a button
                                    and zip closure, waistband with belt loops
                                    Blue light wash 5-pocket mid-rise cropped
                                    jeans, clean look with no fade, has a button
                                    and zip closure, waistband with belt loops.
                                  </p>
                                </div>
                              </div>

                              <div
                                style={{
                                  margin: `0px ${
                                    sectionMargins.right + "px"
                                  } 0px ${sectionMargins.left + "px"}`,
                                  padding: `${sectionMargins.top + "px"} 0px ${
                                    sectionMargins.bottom + "px"
                                  } 0px`,
                                }}
                              >
                                <img
                                  className="img-fluid pages-detail-preview-code"
                                  src={
                                    require("../../assets/images/dashboard/page-map.png")
                                      .default
                                  }
                                />
                              </div>

                              <div
                                style={{
                                  margin: `0px ${
                                    sectionMargins.right + "px"
                                  } 0px ${sectionMargins.left + "px"}`,
                                  padding: `${sectionMargins.top + "px"} 0px ${
                                    sectionMargins.bottom + "px"
                                  } 0px`,
                                }}
                              >
                                <button
                                  className="pages-detail-preview-button"
                                  style={{
                                    backgroundColor: "#1A1A1A",
                                    color: "#fff",
                                  }}
                                >
                                  Book Package
                                </button>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="pages-detail-preview">
                            <div className="pages-setting-preview-header">
                              <div className="pages-setting-header-left">
                                <img
                                  className="img-fluid"
                                  src={
                                    require("../../assets/images/dashboard/post-back.svg")
                                      .default
                                  }
                                />
                                <h3 className="pages-setting-header-heading">
                                  Page details
                                </h3>
                              </div>
                            </div>
                            <div className="webview-body">
                              <img
                                src={
                                  require("../../assets/images/dashboard/webview-icon.png")
                                    .default
                                }
                                alt="webview-icon"
                              />
                            </div>
                          </div>
                        )}

                        {showMediaFullScreen && (
                          <div className="pages-detail-preview">
                            {/* {
         this.props.value.state.show_images_in_gallery ? */}

                            <div
                              className="w-100 "
                              style={{
                                height: "644px",
                              }}
                            >
                              {showMediaThumbnails ? (
                                <div className="previewthumbnail-img"></div>
                              ) : (
                                <div className="previewthumbnail-imagetwo"></div>
                              )}
                            </div>
                          </div>
                        )}
                      </Carousel>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(optionsData?.enableGeneralSettings ||
            optionsData?.enablePageLayout) && (
            <div className="page-settings-mobile-btns">
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "Center",
                }}
                onClick={from == "Custom" ? savePageSettings : pluginsubmit}
                className="page-settings-save-mob"
              >
                {savePageSettingLoading && from == "Custom" ? (
                  <div className="new-loader"></div>
                ) : thiss?.state.saveSettingsLoading && from == "WP/WOO" ? (
                  <div className="new-loader"></div>
                ) : (
                  "Save"
                )}
              </button>
              <p className="page-settings-mobile-timestamp">
                {from == "WP/WOO" &&
                thiss.state.lastdate &&
                thiss.state.lastdate !== "" &&
                thiss.state.lastdate !== undefined ? (
                  <p className="consent-save-date">
                    Updated{" "}
                    {moment(thiss.state.lastdate).format("D MMMM YYYY, HH:mm")}{" "}
                  </p>
                ) : (
                  <p className="consent-save-date">
                    Updated {moment(updatedAt).format("D MMMM YYYY, HH:mm")}{" "}
                  </p>
                )}{" "}
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default PageOptions;
