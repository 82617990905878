import React from "react";

const MinusIcon = () => {
  return (
    <svg
    className="inc-dec-icon"
     
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="2"
      viewBox="0 0 14 2"
    >
      <path
        d="M-7488,8918h-14"
        transform="translate(7502 -8917)"
        fill="none"
        stroke="#7782a1"
        stroke-width="2"
      />
    </svg>
  );
};

export default MinusIcon;
