import React from "react";


import Header from "../Header";
import ProfileLeftPanel from "../ProfileLeftPanel";
import "../../assets/css/newheader.css";
import "../../assets/css/profileleftpanel.css";
import moment from "moment";
import Accountleftpanel from "../Accountleftpanel";
import { Link } from "react-router-dom";
import { Spinner } from "reactstrap";
import { Lifetimepricing } from "../../Api/Dashboard/DashboardApi";
import BottomScrollListener from "react-bottom-scroll-listener";
import Billingheader from "./Billingheader";
import styles from "./payment.module.css";
import DealList from "./Deal-list";
import { InnerFoot } from "../Foot";
import "./Billings.css";
import "./Deals.css";
export default class Deals extends React.Component {
  state = {
    billingdata: [],
    spinner: true,
    pageval: 1,
    datanumber: "",
    bottomspinner: false,
    lifetimedata: [],
    customer_billing_type: "",
    dealsData: [],
    right_panel_expand: true,
  };
  componentDidMount() {
    if(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") == 1)
    {
      document.title = "Deals | AppMySite";
    }
    else
    {
      document.title = "Deals |"+ ' ' +localStorage.getItem('agencyname');      
    }
    
  }
  handleRightPanelExpand = (rightpanelvalue) => {
    this.setState({
      right_panel_expand: rightpanelvalue,
    });
  };
  urlsub = (url, userid) => {
    localStorage.setItem("user_id", userid);
    this.props.history.push("/subscription/" + btoa(url));
  };
  render() {
    let appID = btoa(localStorage.getItem("Appid"));
    const statusColor = (color) => {
      if (color === "Inactive" || color === "past_due") {
        return "canceled-red";
      } else if (color === "active") {
        return "active-green";
      } else if (color === "cancelled") {
        return "canceled-red";
      }
    };

    return (
      <>
    
        <Header />
        <section className="dasboard_page  billing-page-mobile mobile-view-billing ">
          {/* <Accountleftpanel ipadView={"initial-leftpanel-ipadview"} /> */}
          <ProfileLeftPanel rightpanelbody={this.handleRightPanelExpand} profile={""}
          />
          <div
            className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined &&
            localStorage.getItem('sidebaserotate') === 'true')  ? "right-panel-gap right-panel-gap-expand"
             : "right-panel-gap "}
          >
           
              <div className=" align-items-center billings-head-flex billing-head-redesign billing-head-hideformobile">
            <h1 className="d-none d-md-block">Billing</h1>
            <p className="billing-tooltip">
              <img
                src={
                  require("../../assets/images/dashboard/Billing/billingexclamation.png")
                    .default
                }
                alt="app-icon"
                className=""
              />
              <p className="billing-tooltipsubheader">
                View all your subscription and purchase activity here.
                {/* <p className="billing-tooltip-learnmore">
                  Learn More
                  <img
                    src={
                      require("../../assets/images/dashboard/Goto.png").default
                    }
                    alt="app-icon"
                    className=""
                  />
                </p> */}
              </p>
            </p>
          </div> 


            <div className="d-flex justify-content-between">
            <Billingheader value={this} />
            <div className="row scrollbar-settings">
                  <div className="col-md-12 billing-page-padding-r">
                    <div className="social_pannel bill billing_social_pannel">
                      <div className="d-md-flex d-block  justify-content-between w-100">
                        { this.state.dealsData.length > 0  ? (
                          <div className="d-flex align-items-center justify-content-center redeemlink redeem-mob-space billing_redeenlink">
                            <img
                              src={
                                require("../../assets/images/dashboard/plus.png")
                                  .default
                              }
                              alt="addcard"
                              className="iconaddcardplus"
                            />
                            {this.state.spinner === false && (
                              <Link className="" to={"/redeem"}>
                                Redeem
                              </Link>
                            )}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                </div>
            <div className="">
              <>
                <div className="deals-mob-head">Deals</div>
                {this.state.dealsData.length > 0 && (
                  <div className="redeem-mob-button">
                    <img
                      src={
                        require("../../assets/images/dashboard/plus.png")
                          .default
                      }
                      alt="addcard"
                      className="iconaddcardplus"
                    />
                    <Link to={"/redeem"} className=" ">
                      Redeem
                    </Link>
                  </div>
                )}
             
                
                <DealList btnCheck={0} appID="" props={this} />
              </>
            </div>
          </div>
        </section>
      </>
    );
  }
}
