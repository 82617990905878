import React, {useState, useEffect} from "react";
import {Link} from 'react-router-dom';
import imagebackground from "../assets/images/dashboard/my-account.png";
import { Collapse } from "reactstrap";
const ProfileLeftPanel = (props) =>{

const [rotateclass, setRotateClass] = useState((localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined) ? localStorage.getItem('sidebaserotate') : false);
const [openBillingSubMenu , setOpenBillingSubMenu]=useState((window.location.pathname === '/subscriptions' ||
window.location.pathname === '/invoices' || 
window.location.pathname === "/billing-details" || 
window.location.pathname === '/payment-method' || 
window.location.pathname === "/deals" ||  
window.location.pathname === "/redeem" )?true:false);


useEffect(()=>{
  if(window.matchMedia("(max-width: 1200px)").matches){
    setRotateClass(false);

    localStorage.setItem("sidebaserotate", false);
  }
},[])
const handelRotateToggle = () =>{
    setRotateClass(!rotateclass);
    localStorage.setItem('sidebaserotate',!rotateclass);
    props.rightpanelbody(rotateclass);
}

const handleOpenBillingSubSection = () => {
  setOpenBillingSubMenu(!openBillingSubMenu);
}

const handleCloseMobSidebar = () => 
{
  document.querySelector(".showmobileprofilemenu").classList.remove("showmenu");
  document.body.style.overflow = "visible";
};

    return(
      // showmobileprofilemenu
<div className={`thesidebar ${(window.matchMedia("(max-width: 1200px)").matches)?"showmobileprofilemenu":""}    ${(localStorage.getItem('sidebaserotate') !== null &&
 localStorage.getItem('sidebaserotate') !== undefined &&
  localStorage.getItem('sidebaserotate') === 'true')  ? 
  " sidebar--Collapse " : " " }`}>

<div  className={(localStorage.getItem('sidebaserotate') !== null && localStorage.getItem('sidebaserotate') !== undefined && localStorage.getItem('sidebaserotate') === 'true') ? "collapseToggle rotate" : "collapseToggle" } onClick={handelRotateToggle} id="toggleIcon">
    <svg xmlns="http://www.w3.org/2000/svg" width="11.272" height="8.848" viewBox="0 0 11.272 8.848">
    <g id="Group_35098" data-name="Group 35098" transform="translate(-196.151 -33.076)">
      <path id="Path_98098" data-name="Path 98098" d="M-13528.869,11020.146l-4,4,4,4" transform="translate(13735.869 -10986.646)" fill="none" stroke="" stroke-width="1.2"/>
      <path id="Path_98120" data-name="Path 98120" d="M-13528.869,11020.146l-4,4,4,4" transform="translate(13729.869 -10986.646)" fill="none" stroke="" stroke-width="1.2"/>
    </g>
    </svg>
</div>
<div className="mob-sidebar-header">
          <svg onClick={(e)=>handleCloseMobSidebar(e)}
            xmlns="http://www.w3.org/2000/svg"
            width="17.413"
            height="17.413"
            viewBox="0 0 17.413 17.413"
          >
            <path
              id="Union_39"
              data-name="Union 39"
              d="M-1982,1048l-8,8,8-8-8-8,8,8,8-8-8,8,8,8Z"
              transform="translate(1990.707 -1039.292)"
              fill="none"
              stroke="#7782a1"
              stroke-width="2"
            />
          </svg>
        </div>

<div className="profile-panel-head">
      

      <div>
      <img className="profile-panel-img"  src={localStorage.getItem('user_profile').indexOf("https") === -1 ? (process.env.REACT_APP_Image_Path + localStorage.getItem("logged_in_user_id") + process.env.REACT_APP_Profile + localStorage.getItem('user_profile')) : localStorage.getItem('user_profile')}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = imagebackground;
                        }}/>
      </div>
      <div className="profile-panel-info">
        <h2>{localStorage.getItem('user_name')}</h2>
        <h5>User ID: {localStorage.getItem('originalid')}</h5>
      </div>
</div>

<div className="profile-panel-list">

    <div className={window.location.pathname === '/profile' ? "profile-panel-entry profile-panel-entry-active" : "profile-panel-entry"}>
      <Link to="/profile">
        <div>
        <svg style={{
                  "--custom_color4": "#3064f9" ,
                 }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="Group_35077" data-name="Group 35077" transform="translate(-20 -107)">
    <rect id="Rectangle_25377" data-name="Rectangle 25377" width="20" height="20" transform="translate(20 107)" fill="none"/>
    <g id="Group_35076" data-name="Group 35076">
      <path id="Path_98099" data-name="Path 98099" d="M7,1A3,3,0,1,1,4,4,3,3,0,0,1,7,1M7,0a4,4,0,1,0,4,4A4,4,0,0,0,7,0" transform="translate(23 109)" fill=""/>
      <path id="Path_98100" data-name="Path 98100" d="M8.673,11A4.332,4.332,0,0,1,13,15.327v1.188a.486.486,0,0,1-.485.485H1.485A.485.485,0,0,1,1,16.515V15.327A4.332,4.332,0,0,1,5.326,11Zm0-1H5.326A5.327,5.327,0,0,0,0,15.327v1.188A1.485,1.485,0,0,0,1.485,18h11.03A1.485,1.485,0,0,0,14,16.515V15.327A5.327,5.327,0,0,0,8.673,10" transform="translate(23 108)" fill=""/>
    </g>
  </g>
        </svg>
        </div>
        <p style={{
                  "--custom_color4": "#3064f9" ,
                 }}>Profile</p>
        

        <div className="paneltiptext" style={{transform: `translate3d(43px, 0px, 0px)`}}>Profile</div>
        </Link>
    </div>

    <div className={window.location.pathname === '/security' ? "profile-panel-entry profile-panel-entry-active" : "profile-panel-entry"}>
    <Link to="/security">
        <div>
        <svg style={{
                  "--custom_color4": "#3064f9" ,
                 }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
  <g id="Group_35079" data-name="Group 35079" transform="translate(-20 -147)">
    <rect id="Rectangle_25366" data-name="Rectangle 25366" width="20" height="20" transform="translate(20 147)" fill="none"/>
    <g id="Group_35078" data-name="Group 35078">
      <path id="Path_98092" data-name="Path 98092" d="M7,1.149a14.175,14.175,0,0,0,6,1.815V7.73a6.378,6.378,0,0,1-3.206,5.522L7,14.848l-2.793-1.6A6.377,6.377,0,0,1,1,7.73V2.965A14.183,14.183,0,0,0,7,1.149M7,0,6.54.26A13.172,13.172,0,0,1,0,2V7.73a7.369,7.369,0,0,0,3.71,6.39L7,16l3.29-1.88A7.369,7.369,0,0,0,14,7.73V2A13.172,13.172,0,0,1,7.46.26Z" transform="translate(23 149)" fill=""/>
      <path id="Path_98093" data-name="Path 98093" d="M6.094,10.5,3.559,7.968l.707-.707L6.094,9.088,9.733,5.442l.708.706Z" transform="translate(23 149)" fill=""/>
    </g>
  </g>
        </svg>
        </div>
        <p style={{
                  "--custom_color4": "#3064f9" ,
                 }}>Security</p>
       
        <div className="paneltiptext" style={{transform: `translate3d(43px, 0px, 0px)`}}>Security</div>
        </Link>
    </div>

    {(localStorage.getItem("agencyid") && localStorage.getItem("agencyid") != 1)	?
      null
    :
      <div 
      // className={(window.location.pathname === '/subscriptions' ||
      //                  window.location.pathname === '/invoices' || 
      //                  window.location.pathname === "/billing-details" || 
      //                  window.location.pathname === '/payment-method' || 
      //                  window.location.pathname === "/deals" ||  
      //                  window.location.pathname === "/redeem" ) ? 
      //                  "profile-panel-entry profile-panel-entry-active" :
      //                   "profile-panel-entry"}
                        >
          {/* <Link to="/subscriptions">
              <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
        <g id="Group_35081" data-name="Group 35081" transform="translate(-20 -187)">
          <rect id="Rectangle_25370" data-name="Rectangle 25370" width="20" height="20" transform="translate(20 187)" fill="none"/>
          <g id="Group_35080" data-name="Group 35080">
            <path id="Path_98091" data-name="Path 98091" d="M11.748,1A1.253,1.253,0,0,1,13,2.252V13.826l-.682-.585-.651-.558-.651.558L9.333,14.683,7.651,13.241,7,12.683l-.65.558L4.667,14.683,2.985,13.241l-.651-.558-.651.558L1,13.826V2.252A1.254,1.254,0,0,1,2.253,1Zm0-1h-9.5A2.253,2.253,0,0,0,0,2.252V16l2.334-2,2.333,2L7,14l2.333,2,2.334-2L14,16V2.252A2.252,2.252,0,0,0,11.748,0" transform="translate(23 189)" fill=""/>
            <path id="Line_348" data-name="Line 348" d="M4,.5H0v-1H4Z" transform="translate(28 194.5)" fill=""/>
            <path id="Line_349" data-name="Line 349" d="M6,.5H0v-1H6Z" transform="translate(27 197.5)" fill=""/>
          </g>
        </g>
              </svg>
              </div>
              <p>Billing</p>
            
              <div className="paneltiptext">Billing</div>
              </Link> */}
              <div>
            <div
              className={
                ((window.location.pathname === '/subscriptions' ||
                       window.location.pathname === '/invoices' || 
                       window.location.pathname === "/billing-details" || 
                       window.location.pathname === '/payment-method' || 
                       window.location.pathname === "/deals" ||  
                       window.location.pathname === "/redeem" ) &&
                       (localStorage.getItem('sidebaserotate') !== null &&
                        localStorage.getItem('sidebaserotate') !== undefined &&
                         localStorage.getItem('sidebaserotate') === 'true') )
                  ? "profile-panel-entry profile-panel-entry-active"
                  : "profile-panel-entry"
              }
            >
              <div
                className="leftpanel-parent-child-section"
                onClick={(e) => handleOpenBillingSubSection(e)}
              >
                <div>
                  <svg
                  style={{
                    "--custom_color4": "#3064f9" ,
                   }}
                    className={
                      (window.location.pathname === '/subscriptions' ||
                      window.location.pathname === '/invoices' || 
                      window.location.pathname === "/billing-details" || 
                      window.location.pathname === '/payment-method' || 
                      window.location.pathname === "/deals" ||  
                      window.location.pathname === "/redeem" )
                        ? "submenu-active-svg"
                        : ""
                    }
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                  >
                  <g id="Group_35081" data-name="Group 35081" transform="translate(-20 -187)">
          <rect id="Rectangle_25370" data-name="Rectangle 25370" width="20" height="20" transform="translate(20 187)" fill="none"/>
          <g id="Group_35080" data-name="Group 35080">
            <path id="Path_98091" data-name="Path 98091" d="M11.748,1A1.253,1.253,0,0,1,13,2.252V13.826l-.682-.585-.651-.558-.651.558L9.333,14.683,7.651,13.241,7,12.683l-.65.558L4.667,14.683,2.985,13.241l-.651-.558-.651.558L1,13.826V2.252A1.254,1.254,0,0,1,2.253,1Zm0-1h-9.5A2.253,2.253,0,0,0,0,2.252V16l2.334-2,2.333,2L7,14l2.333,2,2.334-2L14,16V2.252A2.252,2.252,0,0,0,11.748,0" transform="translate(23 189)" fill=""/>
            <path id="Line_348" data-name="Line 348" d="M4,.5H0v-1H4Z" transform="translate(28 194.5)" fill=""/>
            <path id="Line_349" data-name="Line 349" d="M6,.5H0v-1H6Z" transform="translate(27 197.5)" fill=""/>
          </g>
        </g>
                  </svg>
                </div>
                <p style={{"--custom_color4":  "#3064f9" ,}}>Billing</p>

                <div className="collapse-submenu" style={{transform: `translate3d(43px, 0px, 0px)`}}>
                  <Link
                    to={"/subscriptions"}
                    style={{"--custom_color4":  "#3064f9" ,}}
                    className={`collapse-submenulink  ${
                      window.location.pathname === "/subscriptions"
                        ? "submenu-active"
                        : ""
                    }`}
                  >
                    Subscriptions
                  </Link>
                  <Link
                    to={"/invoices"}
                    style={{"--custom_color4":  "#3064f9" ,}}
                    className={`collapse-submenulink  ${
                      window.location.pathname ===
                      "/invoices"
                        ? "submenu-active"
                        : ""
                    }`}
                  >
                    Invoices
                  </Link>
                  <Link
                    to={"/billing-details"}
                    style={{"--custom_color4":  "#3064f9" ,}}
                    className={`collapse-submenulink  ${
                      window.location.pathname ===
                      "/billing-details"
                        ? "submenu-active"
                        : ""
                    }`}
                  >
                    Billing details
                  </Link>
                  {parseInt(localStorage.getItem("customer_billing_type")) != 3 && (
                  <Link
                    to={"/payment-method"}
                    style={{"--custom_color4":  "#3064f9" ,}}
                    className={`collapse-submenulink  ${
                      window.location.pathname ===
                      "/payment-method"
                        ? "submenu-active"
                        : ""
                    }`}
                  >
                    Payment method
                  </Link> ) }
                  { (localStorage.getItem("workspaceId") === "" ||
                  localStorage.getItem("workspaceId") === undefined ||
                  localStorage.getItem("workspaceId") === "undefined" ||
                  localStorage.getItem("workspaceId") === null ) && (
                  <Link
                    to={"/deals"}
                    style={{"--custom_color4":  "#3064f9" ,}}
                    className={`collapse-submenulink  ${
                      (window.location.pathname === "/deals" ||window.location.pathname ==="/redeem" )
                        ? "submenu-active"
                        : ""
                    }`}
                  >
                    Deals
                  </Link> ) }
                 
                </div>
              </div>
            </div>
            {( localStorage.getItem('sidebaserotate') !== 'true' && localStorage.getItem('sidebaserotate') !== true ) && (
              <Collapse isOpen={openBillingSubMenu} className="hover-svg">
                <ul class="sidebar-submenu">
                  <Link to={"/subscriptions"}>
                    <li style={{
                  "--custom_color4":  "#3064f9" ,
                 }}
                      className={
                        window.location.pathname === "/subscriptions"
                          ? "submenu-active"
                          : ""
                      }
                    >
                      <p 
                        style={{
                          marginBottom: "0px",
                          "--custom_color4":  "#3064f9" ,
                        }}
                      >
                        Subscriptions{" "}
                      </p>
                    </li>
                  </Link>
                  <Link to={"/invoices"}>
                    <li
                      className={
                        window.location.pathname ===
                        "/invoices"
                          ? "submenu-active"
                          : ""
                      }
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          "--custom_color4":  "#3064f9" ,
                        }}
                      >
                        Invoices
                      </p>
                    </li>
                    </Link>
                    <Link to={"/billing-details"}>
                    <li
                      className={
                        window.location.pathname ===
                        "/billing-details"
                          ? "submenu-active"
                          : ""
                      }
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          "--custom_color4":  "#3064f9" ,
                        }}
                      >
                        Billing details
                      </p>
                    </li>
                    </Link>
                    {parseInt(localStorage.getItem("customer_billing_type")) != 3 && (
                    <Link to={"/payment-method"}>
                    <li
                      className={
                        window.location.pathname ===
                        "/payment-method"
                          ? "submenu-active"
                          : ""
                      }
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          "--custom_color4":  "#3064f9" ,
                        }}
                      >
                        Payment method
                      </p>
                    </li>
                    </Link> ) }
                    { (localStorage.getItem("workspaceId") === "" ||
                  localStorage.getItem("workspaceId") === undefined ||
                  localStorage.getItem("workspaceId") === "undefined" ||
                  localStorage.getItem("workspaceId") === null ) && (
                    <Link to={"/deals"}>
                    <li
                      className={
                        (window.location.pathname === "/deals" ||window.location.pathname ==="/redeem" )
                          ? "submenu-active"
                          : ""
                      }
                    >
                      <p
                        style={{
                          marginBottom: "0px",
                          "--custom_color4":  "#3064f9" ,
                        }}
                      >
                        Deals
                      </p>
                    </li>
                 </Link> ) }
           
                </ul>
              </Collapse>
            )}
          </div>
      </div>
    }

    <div className="profile-panel-animate"></div>
    
</div>

</div>

);

}

export default ProfileLeftPanel;