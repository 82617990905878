const apiEndPoints = {
  generalUrl: process.env.REACT_APP_API_URL + "api/",
  userUrl: process.env.REACT_APP_API_URL + "api/user/",
  appUrl: process.env.REACT_APP_API_URL + "api/app/",
  cmsUrl: process.env.REACT_APP_API_URL + "api/cms/",
  redirectUrl: process.env.REACT_APP_cors_api,
  analyticsUrl: process.env.REACT_APP_Aalytics,
};

export default apiEndPoints;
