import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../utils/apiEndpoints";

export const analyticsApiSlice = createApi({
  reducerPath: "analytics",
  // keepUnusedDataFor:1800,
  baseQuery: fetchBaseQuery({
    baseUrl: ( parseInt(localStorage.getItem("website_technology")) === 3 || parseInt(localStorage.getItem("website_technology")) === 2 ) ? process.env.REACT_APP_CMS_Analytics : apiEndPoints.analyticsUrl,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set("X-App-Device-Type", "administrator");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    analyticsData: builder.query({
      query: ({ token, url, intervalFilterVal, selectfilter }) => {
        return {
          url:`${(url === "cms-analytics" || url === "non-wp-analytics" )?"api":""}/${url}?from=${selectfilter}&timestamp=${new Date().getTime()}&calendar_interval=${intervalFilterVal}`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
      },
    }),
  }),
});

export const { useAnalyticsDataQuery } = analyticsApiSlice;
