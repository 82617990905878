import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import apiEndPoints from "../../../utils/apiEndpoints";
import { prepareAuthHeaders } from "../../../utils/commonUtils";

export const agencyDomainApiSlice = createApi({
  reducerPath: "agencyDomain",
  baseQuery: fetchBaseQuery({
    baseUrl: apiEndPoints.generalUrl,
    prepareHeaders: (headers) => prepareAuthHeaders(headers)
  }),
  // tagTypes: ["domain-details"],
  endpoints: (builder) => ({
    getAgencyDomainData: builder.query({
      query: (params) => "agency/domain/" + params.agencyID,
      // providesTags: ["domain-details"],
      keepUnusedDataFor: 15 * 60
    }),
    saveAgencyDomainData: builder.mutation({
      query: (body) => ({
        url: "agency/domain/create",
        method: "POST",
        body: body
      }),
      // invalidatesTags: ["domain-details"]
    }),
    activateAgencyDomainData: builder.mutation({
      query: (body) => ({
        url: "agency/domain/activate",
        method: "POST",
        body: body
      }),
      // invalidatesTags: ["domain-details"]
    })
  })
});

export const { useGetAgencyDomainDataQuery, useSaveAgencyDomainDataMutation, useActivateAgencyDomainDataMutation } =
agencyDomainApiSlice;
